import React from 'react'
import moment from 'moment'
import { apiSarArchiveSteps, apiSubjectAccessCreate } from '../../../../api/Sar'
import { Navigate } from 'react-router-dom'
import swal from 'sweetalert2'
import CustomNotificationManager from '../../../Common/CustomNotificationManager'
import { connect } from 'react-redux'
import {Spin, Checkbox, Input, Tooltip} from 'antd'
import imgFolderSmallParent from '../../../../assets/smart-folder-medium-parent.svg'
import Loading from '../../../Common/Loading'

class InitialRequestStep extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isSending: false,
      redirectToArchiveSar: false,
      sarData: {},
      loading: false,
      visibleContent: false,
      loadingResend: false,
      valueSelectedBox: '',
      checkOnFocusInput: '',
      startdate: '',
      validateDateFormat: false,
      validateDateNow: false,
      checkDeleteDate: false,
      emailValidate: '',
      checkEmailValidate: false,
      loadingRequestForm: false,
      loadingEndRequestForm: false,
      sendEmailNotification: true,
      sendWebFormLink: true,
      redirectPathDashboard: false
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

  }

  archiveStepClicked(e) {
    e.preventDefault()
    swal.fire({
      text: 'This step will be archived so that your reports will still be accessible.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3DD8DB',
      cancelButtonColor: '#D33D33',
      confirmButtonText: 'Yes, archive it!'
    }).then((result) => {
      if (result.value) {
        this.confirmedActionToArchive(this.props.sar.id)
      } else {
        CustomNotificationManager.info('Action cancelled', 'Aborted')
      }
    })
  }
  confirmedActionToArchive() {
    this.setState({
      isSending: true
    })

    apiSarArchiveSteps(this.props.sar.id)
      .then(() => {
        CustomNotificationManager.success('Archived successfully', 'Success')
        this.setState({
          isSending: false,
          redirectToArchiveSar: true
        })
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
    })
  }

  handleChange(e, field) {
    if (field === 'fullName') {
      const fullName = e.target.value
      const values = fullName.split(' ')
      this.setState({
        sarData: { ...this.state.sarData, forename: values[0], surname: values[1] ? fullName.substr(fullName.indexOf(' ') + 1) : '', fullName: e.target.value, start_date: moment().format('YYYY-MM-DDThh:mm:ss') }
      })
    }
    else
    if (field === 'start_date') {
      const startDate = e.target.value
      if (startDate === '') {
        this.setState({
          startdate: startDate,
          checkDeleteDate: true
        })
      } else {
        this.setState({
          startdate: startDate,
          checkDeleteDate: false
        })
      }
      this.setState({
        sarData: { ...this.state.sarData, [field]: moment().format('YYYY-MM-DDThh:mm:ss') }
      })
    } else if (field === 'email') {
      this.setState({
        sarData: { ...this.state.sarData, [field]: e.target.value, start_date: moment().format('YYYY-MM-DDThh:mm:ss') },
        emailValidate: e.target.value
      })
    } else {
      this.setState({
        sarData: { ...this.state.sarData, [field]: e.target.value, start_date: moment().format('YYYY-MM-DDThh:mm:ss') }
      })
    }
    this.setState({
      validateDateFormat: false,
      //validateDateNow: false,
      checkEmailValidate: false
    })
  }

  handleSubmit(event, status) {
    this.setState({
      loading: true,
      //loadingRequestForm: true
    })
    let { sarData } = this.state
    const { sendEmailNotification, sendWebFormLink } = this.state
    event.preventDefault()
    const { startdate, emailValidate } = this.state
    const regEmail = /^(([^<>()[\]\\.,:\s@"]+(\.[^<>()[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!regEmail.test(emailValidate.trim())) {
      this.setState({
        checkEmailValidate: true
      })
      return false
    }
    const re = /^\d{1,2}\.\d{1,2}\.\d{4}$/
    //const newDate = moment().format('MM.DD.YYYY')
    if (startdate !== '' && !startdate.match(re)) {
      this.setState({
        validateDateFormat: true
      })
      return false
    }
    if (sarData) {
      delete sarData.private_email
    }
    if (status === 'end-request') {
      sarData = { ...this.state.sarData, endRequest: true }
    } else {
      sarData = { ...this.state.sarData, endRequest: false }
    }
    if (status === 'send-request') {
      sarData = { ...this.state.sarData, sendEmailNotification: sendEmailNotification, sendWebFormLink: sendWebFormLink  }
    }
    apiSubjectAccessCreate(sarData).then((response) => {
      this.props.refreshValues(response.data)
      this.setState({
        loading: false,
        loadingRequestForm: false,
        loadingEndRequestForm: false
      })
      CustomNotificationManager.success('Sent successfully', 'Success')
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  componentDidMount() {
    this.setState({
      sarData: { ...this.props.sar, privateEmail: this.props.sar.private_email, fullName: `${ this.props.sar.forename } ${ this.props.sar.surname ? this.props.sar.surname : '' }`, clientId: this.props.clientId },
      emailValidate: this.props.sar.email
    })
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      sarData: { ...nextProps.sar, privateEmail: nextProps.sar.private_email, fullName: `${ nextProps.sar.forename } ${ nextProps.sar.surname ? nextProps.sar.surname : '' }`, clientId: this.props.clientId },
      emailValidate: nextProps.sar.email
    })
  }
  onClickVisibleContent = () => {
    this.setState({
      visibleContent: !this.state.visibleContent
    })
  }
  generateDate(date) {
    return moment(date).format('DD.MM.YYYY')
  }

  autoFocusInput(e, field) {
    this.setState({
      checkOnFocusInput: field
    })
  }
  onBlurInput() {
    this.setState({
      checkOnFocusInput: ''
    })
  }
  onClickNextStep = () => {
    const { current, acknowledgeStep, sar } = this.props
    if (!acknowledgeStep && sar && sar.lastStep === 'COMPLETE') {
      this.props.nextStep(current + 6)
    } else if (!acknowledgeStep) {
      this.props.nextStep(current)
    } else {
      this.props.nextStep(current + 1)
    }
  }
  endRequestForm(e, status) {
    this.setState({
      loadingEndRequestForm: true
    })
    this.handleSubmit(e, status)
  }
  sendRequestForm(e, status) {
    this.setState({
      loadingRequestForm: true
    })
    this.handleSubmit(e, status)
  }
  onChangeEmailNotification = () => {
    this.setState({
      sendEmailNotification: true
    })
  }
  onChangeWebFormLink = () => {
    this.setState({
      sendWebFormLink: true
    })
  }
  onClickBackStep = () => {
    this.setState({
      redirectPathDashboard: true
    })
  }
  render() {
    const { sar, valueAttachBox, acknowledgeStep } = this.props
    //const { acknowledgeSubmitted, linkVisited, emailOpened } = sar
    const { redirectToArchiveSar, sarData, valueSelectedBox, checkOnFocusInput, validateDateFormat, checkDeleteDate, checkEmailValidate, loadingRequestForm,
      loadingEndRequestForm, sendEmailNotification, sendWebFormLink, redirectPathDashboard } = this.state
    const { startdate } = this.state
    const path = `/sar/dashboard`
    if (redirectPathDashboard) {
      return <Navigate to={ path } />
    }
    // let emailStatus
    // if (emailOpened && emailOpened.length > 0) {
    //   emailStatus = 'OPENED'
    // } else {
    //   emailStatus = 'SENT'
    // }
    //
    // let emailLinkOpened
    // if (linkVisited && linkVisited.length > 0) {
    //   emailLinkOpened = 'VISITED'
    // } else {
    //   emailLinkOpened = 'PENDING'
    // }
    //
    // let linkSubmitted
    // if (acknowledgeSubmitted && acknowledgeSubmitted.length > 0) {
    //   linkSubmitted = 'SUBMITTED'
    // } else {
    //   linkSubmitted = 'PENDING'
    // }

    //const isLinkFromEmail = emailOpened || acknowledgeSubmitted || linkVisited
    return (
      <React.Fragment>
        <div className="tracker-header-steps">
          <div className="contain-name-back-step">
            <div className="back-step hover-back-step">
              <span onClick={this.onClickBackStep} className="icon-v10-back"></span>
            </div>
            <div className="tracker-fullname-contain">
              <span className="tracker-surname">{`${ sar.forename } ${ sar.surname }`}</span>
            </div>
          </div>
          <div className="attach-box-contain">
            { (valueAttachBox || this.props.sar.boxName) ?
              <img alt={ valueSelectedBox } src={ imgFolderSmallParent }/>:
              <span className="icon-v10-smart-folder-medium-parent-2"></span>
            }
            <span className="attach-box disabled-attach-box">
              { valueAttachBox ? valueAttachBox : this.props.sar.boxName ? this.props.sar.boxName : 'Attach a box' }
            </span>
          </div>
          <div className="contain-save-last-step">
            <div className="save-close-contain">
              <span className="icon-v10-save"></span>
              <span className="save-and-close">
             Save & Close
              </span>
            </div>
            <div className={ !acknowledgeStep && sar.lastStep !== 'COMPLETE' ? 'next-step next-step-disabled' : 'next-step next-step-hover'}>
              <span onClick={this.onClickNextStep} className="icon-v10-forth"></span>
            </div>
          </div>
        </div>
        {/*<div className="tracker-surname-contain">*/}
        {/*  <span className="tracker-surname">{`${ sar.forename } ${ sar.surname }`}</span>*/}
        {/*</div>*/}
        <div className="tracker-box" style={{ marginTop: 0 }}>
          { redirectToArchiveSar &&
        <Navigate to="/sar/archive" />
          }
          <div className="tracker-form tracker-form-request">
            <header className="tracker-step-header tracker-initial-step">
              <div className="tracker-step-header__top">
                <h1 className="tracker-step-header__top__step">Request form
                  <Tooltip overlayClassName="help-message-locations" title={<div className="content-help-message-locations">
                    <span className="text-information">Enter the subject’s details.</span>
                    <span className="more-info-link" onClick={ () => window.open('https://support.smartbox.ai/support/solutions/articles/47001174700-create-a-new-request', '_blank') }> More info. </span>
                  </div>}>
                    <span className="icon-v14-question"></span>
                  </Tooltip></h1>
              </div>
            </header>
            {/*<div className="tracker-acknowledge-info">*/}
            {/*  <div className="content-pending">*/}
            {/*    <span className={linkSubmitted === 'SUBMITTED' ? 'email-sent' : 'email-opened'}>{linkSubmitted}</span>*/}
            {/*  </div>*/}
            {/*  {isLinkFromEmail &&*/}
            {/*  <div className="content-email-status">*/}
            {/*    <span className="info-email-status">Email Status</span>*/}
            {/*    <span className="contain-email-status">*/}
            {/*      <span*/}
            {/*        className={emailStatus === 'OPENED' ? 'email-opened' : 'email-sent'}>{emailStatus}</span>*/}
            {/*      {*/}
            {/*        emailStatus !== 'OPENED' &&*/}
            {/*        <Icon type="check" className="icon-check"/>*/}
            {/*      }*/}
            {/*    </span>*/}
            {/*  </div>*/}
            {/*  }*/}
            {/*  <div className="content-link-status">*/}
            {/*    <span className="info-link-status">Form Link Status</span>*/}
            {/*    <span className="contain-email-status">*/}
            {/*      <span className={emailLinkOpened === 'VISITED' ? 'email-sent' : 'email-opened'}>{emailLinkOpened}</span>*/}
            {/*      { emailLinkOpened === 'VISITED' &&*/}
            {/*      <Icon type="check" className="icon-check-two"/>*/}
            {/*      }*/}
            {/*    </span>*/}
            {/*  </div>*/}
            {/*  <div className="content-data-status">*/}
            {/*    <span className="info-data-status">Form Data Status</span>*/}
            {/*    <span className="contain-data-status">*/}
            {/*      <span className={linkSubmitted === 'SUBMITTED' ? 'email-sent' : 'email-opened'}>{linkSubmitted}</span>*/}
            {/*      { linkSubmitted === 'SUBMITTED' &&*/}
            {/*    <Icon type="check" className="icon-check"/>*/}
            {/*      }*/}
            {/*    </span>*/}
            {/*  </div>*/}
            {/*  <div className="tracker-content-icons">*/}
            {/*    <span className="tracker-icons-visible-content" onClick={() => this.onClickVisibleContent()}>*/}
            {/*      <i className={ this.state.visibleContent ? 'fa fa-caret-down' : 'fa fa-caret-up' }></i>*/}
            {/*    </span>*/}
            {/*  </div>*/}
            {/*</div>*/}

            {/*{ this.state.visibleContent &&*/}
            {/*<div className="tracker-acknowledge-info-content">*/}
            {/*  <div className="display-email-status">*/}
            {/*    { emailOpened &&*/}
            {/*    <React.Fragment>*/}
            {/*      {*/}
            {/*        emailOpened.map((date, i) => <span*/}
            {/*          key={i}>{this.generateDate(date)}</span>)*/}
            {/*      }*/}
            {/*    </React.Fragment>*/}
            {/*    }*/}
            {/*  </div>*/}
            {/*  <div className="display-form-link-status">*/}
            {/*    {linkVisited &&*/}
            {/*    <React.Fragment>*/}
            {/*      {*/}
            {/*        linkVisited.map((date, i) => <span*/}
            {/*          key={i}>{this.generateDate(date)}</span>)*/}
            {/*      }*/}
            {/*    </React.Fragment>*/}
            {/*    }*/}
            {/*  </div>*/}
            {/*  <div className="display-form-data-status">*/}
            {/*    {*/}
            {/*      acknowledgeSubmitted.map((date, i) => <span*/}
            {/*        key={i}>{this.generateDate(date)}</span>)*/}
            {/*    }*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*}*/}
            {sarData &&
              <form onSubmit={this.handleSubmit}>
                <div className="tracker-step-content tracker-step-content-request">
                  <div className="tracker-form-group tracker-form-group-request">
                    <label htmlFor="subject_name">Subject name *</label>
                    <Input onFocus={(e) => this.autoFocusInput(e, 'fullName')} onBlur={(e) => this.onBlurInput(e)} id='subject_name' type="text" 
                      value={sarData.fullName || ''} onChange={(e) => this.handleChange(e, 'fullName')} required readOnly={ acknowledgeStep }/>
                    <div className={checkOnFocusInput === 'fullName' ? 'edit-input edit-input-focus' : 'edit-input' }>
                      <span className="icon-v10-edit-b icon-edit"></span>
                    </div>
                  </div>
                  <div className="tracker-form-group tracker-form-group-request">
                    <label htmlFor="subject_name">Subject job title</label>
                    <Input onFocus={(e) => this.autoFocusInput(e, 'title')} onBlur={(e) => this.onBlurInput(e)} id='subject_name_title' type="text"
                      value={sarData.title || ''} onChange={(e) => this.handleChange(e, 'title')} readOnly={ acknowledgeStep }/>
                    <div className={checkOnFocusInput === 'title' ? 'edit-input edit-input-focus' : 'edit-input' }>
                      <span className="icon-v10-edit-b"></span>
                    </div>
                  </div>
                  <div className="tracker-form-group tracker-form-group-request">
                    <label htmlFor="email_address">Subject email address *</label>
                    <Input onFocus={(e) => this.autoFocusInput(e, 'email')} onBlur={(e) => this.onBlurInput(e)} id="email" type="text"
                      value={sarData.email || ''} onChange={(e) => this.handleChange(e, 'email')} required readOnly={ acknowledgeStep }/>
                    <div className={checkOnFocusInput === 'email' ? 'edit-input edit-input-focus' : 'edit-input' }>
                      <span className="icon-v10-edit-b"></span>
                    </div>
                    { checkEmailValidate &&
                      <span className="valid-date">Your email does not have a valid format.</span>
                    }
                  </div>
                  {/*<div className="tracker-form-group">*/}
                  {/*  <label htmlFor="email_address">Subject privat email address</label>*/}
                  {/*  <input id="email" type="text" value={sarData.private_email || ''} onChange={(e) => this.handleChange(e, 'email')} required readOnly={!sarData.draft}/>*/}
                  {/*</div>*/}
                  <div className="tracker-form-group tracker-form-group-request">
                    <label htmlFor="phone">Subject work phone number</label>
                    <Input onFocus={(e) => this.autoFocusInput(e, 'telephone')} onBlur={(e) => this.onBlurInput(e)} id="phone" type="text" 
                      value={sarData.telephone || ''} onChange={(e) => this.handleChange(e, 'telephone')} readOnly={ acknowledgeStep }/>
                    <div className={checkOnFocusInput === 'telephone' ? 'edit-input edit-input-focus' : 'edit-input' }>
                      <span className="icon-v10-edit-b"></span>
                    </div>
                  </div>
                  <div className="tracker-form-group tracker-form-group-request">
                    <label htmlFor="phone">Date requested *</label>
                    <Input onFocus={(e) => this.autoFocusInput(e, 'start_date')} onBlur={(e) => this.onBlurInput(e)} id="start_date" type="text"
                      value={ checkDeleteDate ? '' : startdate !== '' ? startdate : sarData.start_date ? this.generateDate(sarData.start_date) : '' }
                      onChange={(e) => this.handleChange(e, 'start_date')} required readOnly={ acknowledgeStep }/>
                    <div className={checkOnFocusInput === 'start_date' ? 'edit-input edit-input-focus' : 'edit-input' }>
                      <span className="icon-v10-edit-b"></span>
                    </div>
                    { validateDateFormat &&
                      <span className="valid-date">Invalid date format. Input valid date format MM.DD.YYYY</span>
                    }
                    {/*{ validateDateNow &&*/}
                    {/*  <span className="valid-date">Please date now</span>*/}
                    {/*}*/}
                  </div>
                  <div className="tracker-form-group tracker-form-group-request">
                    <label htmlFor="notes">Notes</label>
                    <Input onFocus={(e) => this.autoFocusInput(e, 'adminNotes')} onBlur={(e) => this.onBlurInput(e)} className='text_area' id='notes' rows='4'
                      value={sarData.adminNotes || ''} onChange={(e) => this.handleChange(e, 'adminNotes')} readOnly={ acknowledgeStep }/>
                    <div className={checkOnFocusInput === 'adminNotes' ? 'edit-input edit-input-focus' : 'edit-input' }>
                      <span className="icon-v10-edit-b"></span>
                    </div>
                  </div>
                  { !acknowledgeStep && sarData && sarData.lastStep !== 'COMPLETE' &&
                  <div className="tracker-content-links full_width new-tracker-content-links">
                    <div className="tracker-form-link tracker-notification-email">
                      <Checkbox disabled={ true } defaultChecked={sendWebFormLink} onChange={ this.onChangeWebFormLink } className="send-form new-send-form">Send Webform Link</Checkbox>
                    </div>
                    <div className="tracker-form-link tracker-notification-email">
                      <Checkbox disabled={ true } defaultChecked={sendEmailNotification} onChange={ this.onChangeEmailNotification } className="send-form new-send-form">Send Email Notification</Checkbox>
                    </div>
                  </div>
                  }
                  { !acknowledgeStep && sarData && sarData.lastStep !== 'COMPLETE' &&
                    <div className="tracker-form-group form-buttons content-buttons-initial-request">
                      {
                        loadingRequestForm ? <Spin className="float-right spin-send-request" spinning={ loadingRequestForm }
                          indicator={ <div className="checkout-loading"><Loading/></div> }/> :
                          <button disabled={ loadingEndRequestForm } className="button-blue-light float-right"
                            onClick={ (e) => this.sendRequestForm(e, 'send-request') }>Send Request Form
                          </button>
                      }
                      {
                        loadingEndRequestForm ? <Spin className="float-right spin-end-request" spinning={ loadingEndRequestForm }
                          indicator={ <div className="checkout-loading"><Loading/></div> }/> :
                          <button disabled={ loadingRequestForm } className="button-end-request float-right"
                            onClick={ (e) => this.endRequestForm(e, 'end-request') }>End Request
                          </button>
                      }
                    </div>
                  }
                </div>
              </form>
            }
          </div>
        </div>
      </React.Fragment>
    )
  }
}
const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id
})
InitialRequestStep = connect(mapStateToProps)(InitialRequestStep)
export default InitialRequestStep