import React, { Component } from 'react'
import { connect } from 'react-redux'
import './ExternFileFeedbackResponse.scss'
import { Radio, Spin } from 'antd'
import {
    apiSarFeedbackResponse,
    getPreviewSarDocByThumbnailIdFinalized,
    downloadZipExternFeedbackBox,
    downloadFileExternFeedbackData,
    downloadFileExternFeedbackWorkflowData
} from '../../../api/Sar'
import CustomNotificationManager from '../../Common/CustomNotificationManager'
import {fetchUserSarFormFeedback, fetchUserSarFormFeedbackWorkflow} from '../../../actions/sarActions'
import Loading from '../../Common/Loading'
import { generateBlobFromBase64 } from '../../../utils/GenerateBlob'
import PDFViewer from '../../../utils/PDFViewer'
import PDFJSBackend from '../../../utils/backends/PDFJs'
import imgFolderSmallParent from '../../../assets/smart-folder-base-close.png'
import FileDownload from 'js-file-download'
import withRouter from '../../../private-routers/withRouter'
const RadioGroup = Radio.Group

class ExternFileFeedbackWorkflowResponse extends Component {

    constructor(props) {
        super(props)
        this.state = {
            title: 'Your ticket results',
            question: 'Would you like us keep this data or delete it?',
            fileList: [],
            previewImage: '',
            previewVisible: false,
            uploadFileExtern: false,
            loading: false,
            loadingSubmit: false,
            currentSarId: null,
            data: {
                sarId: '',
                //responseFeedback: undefined,
            },
            editMode: false,
            nameBox: '',
            idBox: '',
            sarId: '',
            initialValues: {},
            checkEndRequest: false,
            statusDownload: false
        }
    }

    componentDidMount() {

        if (this.props.params.id) {
            fetchUserSarFormFeedbackWorkflow(this.props.params.id).then((response) => {
                this.setState({
                    //fileList: response.data.thumbnails,
                    editMode: response.data.editMode,
                    nameBox: response.data.boxName,
                    idBox: response.data.boxId,
                    sarId: response.data.sarId,
                    initialValues: response.data
                })
            })
        }
    }

    onSendAnswer = (e) => {
        this.setState({ data: { ...this.state.data, responseFeedback: e.target.value, sarId: this.props.params.id } })
    }

    handleFeedbackResponse = () => {
        if (this.state.editMode) {
            this.setState({
                loadingSubmit: true
            })
            this.setState({ data: { ...this.state.data, sarId: this.props.params.id } }, () => {
                apiSarFeedbackResponse(this.state.data)
                    .then((response) => {
                        const answerResponse = response.data
                        if (answerResponse === 'Successful' && response.status === 200) {
                            CustomNotificationManager.success('Your response was sent.', 'Success')
                            this.setState({
                                loadingSubmit: false,
                                editMode: false
                            })
                            setTimeout(function() {
                                this.props.navigate(-1) || window.close()
                            }, 3000)
                        } else {
                            CustomNotificationManager.info('The SAR is already closed, and cannot be completed anymore.')
                            this.setState({
                                checkEndRequest: true
                            })
                            setTimeout(function() {
                                this.props.navigate(-1) || window.close()
                            }, 4000)
                        }
                    }).catch((error) => {
                    if (error?.response?.status === 504) {
                        CustomNotificationManager.cleanBuffer('warning', '')
                    }else{
                        console.log(error)
                    }
                })
            })
        }
    }

    handlePreview = (file) => {
        if (file) {
            this.setState({
                previewVisible: true,
                loading: true
            }, () => {
                getPreviewSarDocByThumbnailIdFinalized(file.id).then((response) => {
                    switch (response.data.fileType) {
                        case 'image/png':
                        case 'image/jpg':
                        case 'image/jpeg':
                            const imageUrl = `data:${ response.data.fileType };base64, ${ response.data.doc }`
                            this.setState({
                                renderingBlock: <img alt="example" style={{ width: '100%' }} src={imageUrl}/>,
                                loading: false
                            })
                            break
                        case 'application/pdf':
                            const { doc } = response.data
                            this.setState({
                                renderingBlock: <PDFViewer backend={PDFJSBackend} src={ generateBlobFromBase64(doc) }/>,
                                loading: false
                            })

                            break
                        default: break
                    }
                }).catch((error) => {
                    if (error?.response?.status === 504) {
                        CustomNotificationManager.cleanBuffer('warning', '')
                    }else{
                        console.log(error)
                    }
                })
            })
        } else {
            return false
        }

    };

    handleCancel = () => this.setState({ previewVisible: false })

    onDownloadBoxFeedback = () => {
        //const { sarId, nameBox } = this.state
        // downloadFileExternFeedbackBox(sarId).then((response) => {
        //   FileDownload(response.data, `${ nameBox }`)
        // })
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { initialValues } = this.state
      /*  if (initialValues && !initialValues.editMode) {
            this.renderNotificationMessage()
        }*/
        if (initialValues && initialValues.completed) {
            this.renderNotificationMessageClosed()
        }
    }
    renderNotificationMessage() {
        CustomNotificationManager.warning('This form has already been completed.')
        setTimeout(function() {
            window.close()
        }, 4000)
    }
    renderNotificationMessageClosed() {
        CustomNotificationManager.warning('The sar was closed.')
        setTimeout(function() {
            window.close()
        }, 4000)
    }
    onDownloadZipExternFeedback = () => {
        const { nameBox } = this.state
        downloadZipExternFeedbackBox(this.props.params.id).then((response) => {
            FileDownload(response.data, `${ nameBox }.zip`)
        }).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
            }else{
                console.log(error)
            }
        })
    }
    onStatusDownload(status) {
        this.setState({
            statusDownload: true
        })
    }
    render() {

        const { title, question, loadingSubmit, editMode, nameBox, initialValues, checkEndRequest, statusDownload } = this.state
        const { responseFeedback } = this.state.data
        return (
            <div className="sa_page_extern_feedback">
                <div className="externFile-page-feedback">
                    {
                        // (initialValues && initialValues.completed) || checkEndRequest ?
                        // <div className="closed-extern-feedback">
                        //     <span className="message-closed-extern-feedback">The sar was closed.</span>
                        // </div> :
                        // initialValues && !initialValues.editMode ?
                        //     <div className="closed-extern-feedback">
                        //         <span className="message-closed-extern-feedback-update">Request closed</span>
                        //         <span className="message-closed-data-confirmed">Receipt of data has been confirmed.</span>
                        //     </div> :
                            <div className="externFile-box-feedback">
                                <header className="externFile-header-feedback">
                                    <div className="externFile-header__top__feedback">
                                        <h1 className="externFile-title-feedback"> { title } </h1>
                                    </div>
                                </header>

                                <div className="content-feedback-upload-document">
                                    <div className="document-feedback">
                                        <img alt={ imgFolderSmallParent } src={ imgFolderSmallParent }/>
                                        <span className="icon-v10-tool-download-neg"></span>
                                    </div>
                                    <div className="name-document-feedback">
                    <span className="name-document">
                      { nameBox ? nameBox : '' }
                    </span>
                                    </div>

                                </div>
                                <div className="button-download-feedback col-xs-2">
                                    { <a className="button-blue button-download-data"
                                         onClick={(e) => this.onStatusDownload(e)}
                                         href={ downloadFileExternFeedbackWorkflowData(this.props.params.id) }>DOWNLOAD</a>
                                    }
                                </div>
                                <div className="content-message-hyperlink">
                                    <span className="text-information">Trouble downloading? </span>
                                    <span className="more-info-link" onClick={ () => window.open('https://support.smartbox.ai/support/solutions/articles/47001222170-having-trouble-downloading-data-', '_blank') }> Click here. </span>
                                </div>

                                <div className="file-upload-container-feedback">
                                </div>

                                {/*<footer className="row footer-extern-file-feedback">*/}
                                {/*    <div className="button-submit-feedback col-xs-offset-7 col-xs-2">*/}
                                {/*        {*/}
                                {/*            loadingSubmit ? <Spin className="float-right" spinning={ loadingSubmit }*/}
                                {/*                                  indicator={ <div className="checkout-loading"><Loading/></div> }/> :*/}
                                {/*                <button className={ editMode ? "button-blue button-submit" : "btn-submit-update"} onClick={ this.handleFeedbackResponse }*/}
                                {/*                    //disabled={ responseFeedback === undefined || !editMode }*/}
                                {/*                        disabled={ statusDownload === false }*/}
                                {/*                >{ this.state.editMode ? 'I hereby confirm receipt of my data and close my request' : 'Request closed'}</button>*/}
                                {/*        }*/}
                                {/*    </div>*/}
                                {/*</footer>*/}
                            </div>
                    }
                </div>
            </div>
        )
    }
}
ExternFileFeedbackWorkflowResponse = withRouter(ExternFileFeedbackWorkflowResponse)
const mapStateToProps = (state) => ({
    sar: state.sar.sarUserForm
})
export default connect(mapStateToProps)(ExternFileFeedbackWorkflowResponse)
