import React, { Component } from 'react'
import { Select } from 'antd'
import { getTenants, setTenantId } from '../../../api/User'
import { Resources } from '../../Osp/Resource/Resources'
import './Multitenant.scss'
import Spin from 'antd/es/spin'
import withRouter from '../../../private-routers/withRouter'
import {apiSwitchToSubTenant} from "../../../api/SmartBox";

export class Multitenant extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isMultitenant: false,
      tenants: [],
      fetching: false,
      filteredOptions: [],
      valueTenantSelect: ''
    }
  }

  componentDidMount() {
    this.getTenantsForOwner()
    if (this.props.subscription.subscription) {
      this.setState({
        valueTenantSelect: this.props.subscription.subscription.client.name
      })
    }
  }

  async getTenantsForOwner() {
    await getTenants().then((res) => {
      this.setState({
        tenants: res.data,
        filteredOptions: res.data,
        isMultitenant: true
      })
    })
  }

    onFilter = (value) => {
      const filteredOptions = value.length > 0 ?
        this.state.tenants
          .filter((clients) =>
            clients.companyName
              .toLocaleLowerCase()
              .includes(value.toLocaleLowerCase()))
        : this.state.tenants

      this.setState({
        filteredOptions: filteredOptions,
        fetching: true
      })
    }

    handleTenantSearch = (value) => {
      this.setState({
        fetching: false,
      })
      this.onFilter(value)
    };

    setTenantId(tenantId) {
      const data = {
        token: JSON.parse(localStorage.getItem('tokenData'))?.tokenId,
        tenantId
      }
      setTenantId(data).then((res) => {
        const idToken = res.data
        // localStorage.removeItem('accessToken')
        localStorage.removeItem('tokenData')
        var secondsToExp = Math.floor(new Date().getTime() / 1000);
        secondsToExp += (60*60*8)
        var tokenData = {'tokenId': idToken, 'expiresAt': secondsToExp}
        localStorage.setItem('tokenData', JSON.stringify(tokenData))
        // localStorage.setItem('accessToken', idToken)
        this.props.navigate('/smart-box/management')
        window.location.replace('/smart-box/management')
      })
    }
    getCompany(subscription) {
      if (subscription.subscription) {
        return subscription.subscription.client.name
      }
      return ''
    }
    onChangeSetTenantId = (e) => {
      const { user } = this.props
      const switchToSubTenantDTO = {
        userId: user.id,
        clientId: e,
      }
      apiSwitchToSubTenant(switchToSubTenantDTO).then((response) => {
        const idToken = response.data
        this.props.onResetSwitchToSubTenant(idToken)
        // localStorage.removeItem('accessToken')
      })
      this.setState({
        valueTenantSelect: e
      })
    }
    render() {
      const { fetching, filteredOptions, valueTenantSelect } = this.state
      const { subscription, listSubTenants, client, user } = this.props
      return (
        <div className="multitenant">
          {
            user && user.subTenantListActive === true && listSubTenants && listSubTenants.length > 0 ?
                <Select className="multitenant__select"
                        onChange={(e) => this.onChangeSetTenantId(e)}
                        value={ valueTenantSelect }
                    //showSearch
                        placeholder="Switch to client"
                        defaultActiveFirstOption={false}
                        filterOption={false}
                        showArrow={false}
                        notFoundContent={fetching ? <Spin size="small"/> : null}
                    //onSearch={this.handleTenantSearch.bind(this)}
                        dropdownClassName="multitenant-dropdown"
                        getPopupContainer={trigger => trigger.parentNode}
                >
                  {user && user.subTenantListActive === true && listSubTenants && listSubTenants.map((item) => (
                      <Select.Option disabled={ client.id === item.subTenantId || !item.subTenantActive } key={item.subTenantId} value={item.subTenantId} className="multitenant-option">
                        {item.subTenantName}
                      </Select.Option>
                  ))}
                </Select> :
                <div className='multitenant-selected-value-wrapper'>
                  <span className='multitenant-selected-value'>{ valueTenantSelect }</span>
                </div>
          }

          {/*<Select className="multitenant__select"*/}
          {/*  onChange={(e) => this.setTenantId(e)}*/}
          {/*  value={ this.getCompany(subscription) }*/}
          {/*  showSearch*/}
          {/*  placeholder="Switch to client"*/}
          {/*  defaultActiveFirstOption={false}*/}
          {/*  filterOption={false}*/}
          {/*  showArrow={false}*/}
          {/*  notFoundContent={fetching ? <Spin size="small"/> : null}*/}
          {/*  onSearch={this.handleTenantSearch.bind(this)}*/}
          {/*  dropdownClassName="multitenant-dropdown"*/}
          {/*>*/}
          {/*  {filteredOptions && filteredOptions.map((item) => (*/}
          {/*    <Select.Option key={item.id} value={item.id} className="multitenant-option">*/}
          {/*      {item.companyName}*/}
          {/*    </Select.Option>*/}
          {/*  ))}*/}
          {/*</Select>*/}
        </div>
      )
    }
}



export default withRouter(Resources)