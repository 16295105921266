import axios from 'axios'
const isLocalhost = () => {
  return window.location.hostname === 'localhost'
}
// const host = isLocalhost() ? 'https://staging.ospreysmart.com' : ''
const host = isLocalhost() ? 'http://localhost:8080' : ''

export const apiNewSearch= (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/sar/new_search`,
    data: data
  })
}

export const apiStartTimer= (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/osr/start-timer`,
    data: data
  })
}

export const apiFetchByFilter = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/sar/filter-list`,
    data
  })
}

export const apiFetchByFilterArchive = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/sar/filter-list-archived`,
    data
  })
}

export const apiFetchWithPaginationAndFilter = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/sar/send-sar-list`,
    data
  })
}

export const apiSubjectAccessCreate = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/sar/subject-tracker-create`,
    data: data
  })
}

export const apiSubjectAccessSave = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/sar/subject-tracker-create-draft`,
    data: data
  })
}

export const apiSubjectAccessUpdate = (data) => {
  return axios({
    method: 'put',
    url: `${ host }/osprey-0.1.0/v1/sar/subject-access/update-subject-access`,
    data: data
  })
}

export const apiSubjectAccessRemoteCreate = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/sar/subject-access/create-subject-access`,
    data: data
  })
}

/**
 * upload draft documents
 * @param data
 * @returns {AxiosPromise} the document DTO with thumbnail
 */
export const apiExternFileUploadDraft = (data, sarId) => {
  const FormData = require('form-data');
  const obj = new FormData()
  obj.append('file', data)
  obj.append('sarId', sarId)
  obj.append('uid', data.uid)
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/proof-id/draft`,
    data: obj,
    headers: {'Content-Type': 'multipart/form-data',
      // 'User-Agent': 'axios/1.3.3',
      // 'Content-Length': 'NaN',
      // 'Accept-Encoding': 'gzip, compress, deflate, br'
    },
  }).then((res) => {
    console.log(res);
  })
}

export const getPreviewDocByThumbnailId = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/proof-id/by-thumbnail/${ id }`,
  })
}

/**
 * on upload submit form save documents
 * @param data
 * @returns {AxiosPromise}
 */
export const apiExternFileUpload = (data, id) => {
  return axios({
    method: 'put',
    url: `${ host }/osprey-0.1.0/v1/proof-id/${ id }`,
    data
  })
}

export const apiSarStepFileUpload = (data) => {
  return axios({
    method: 'put',
    url: `${ host }/osprey-0.1.0/v1/sar/search-id/`,
    data
  })
}

export const apiFileUploadSarSteps = (data, sarId) => {

  const object = new FormData()
  object.append('file', data)
  object.append('sarId', sarId)
  object.append('uid', data.uid)

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/document/draft`,
    data: object
  })
}

export const getPreviewSarDocByThumbnailId = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/document/by-thumbnail/${ id }`,
  })
}

export const getPreviewSarDocByThumbnailIdFinalized = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/document/by-thumbnail/finalized/${ id }`,
  })
}

export const getPreviewRedactingSarDocByThumbnailId = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/document/thumbnail/pdf-pages/${ id }`
  })
}

export const apiSarSteps = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/sar/${ id }/steps`,
  })
}
export const postCheckId = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/sar/check-id`,
    data
  })
}

export const apiValidateAcknowledgement = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/sar/validate-acknowledgement`,
    data
  })
}

export const apiGetIdUploadFile = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/extern-file-upload/${ id }`
  })
}

export const postCheckRespond = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/sar/validate-respond`,
    data
  })
}

export const apiUpdateInitialStepAcceptEmailTemplate = (data, godAdmin) => {
  const url = godAdmin ? `${ host }/osprey-0.1.0/v1/god-admin/email-template` : `${ host }/osprey-0.1.0/v1/email`
  const method = godAdmin ? 'put' : 'post'
  return axios({
    method: method,
    url,
    data
  })
}

export const apiUpdateDefaultTemplate = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/email/get-default-template`,
    data
  })
}

export const apiGetAllEmailTemplate = (id, clientId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/email/${ id }/${ clientId }`,
  })
}

export const apiSarArchiveSteps = (id) => {

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/sar/archive/${ id }`,
  })
}

export const apiSarUnarchiveSteps = (id) => {

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/sar/unarchive/${ id }`,
  })
}

export const apiGetAllCanvasPoints = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/sar/pdf/canvas/${ id }`
  })
}


export const apiUpdateCanvasPoints = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/sar/pdf/canvas/${ data.idDoc }`,
    data: data
  })
}

export const getPreviewFileCheckThumbnailId = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/proof-id/sar/${ id }`,
  })
}

export const apiGetAnswerCheckId = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/sar/check-proof-id`,
    data
  })
}

export const apiSarAknowledgeResendEmail = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/sar/subject-tracker-resend`,
    data
  })
}

export const apiSarCheckIdSendEmail = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/sar/check-id/send-email`,
    data
  })
}

export const getSarDocByThumbnailId = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/sar/add-documents`,
    data
  })
}

export const getSarDocumentsThumbnailId = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/document/sar/${ id }`
  })
}
export const getSarDocumentsSaved = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/document/sar/get-name-and-size`,
    data
  })
}
export const getSarReviewAndRedact = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/sar/redact-document`,
    data
  })
}

export const apiSarSecurelySendEmail = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/sar/send-securely/`,
    data
  })
}

export const apiSarFeedbackResponse = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/sar/sar-feedback-response`,
    data
  })
}

export const getAnnotationsByClientId = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/annotations/${ id }`
  })
}

export const deleteAnnotationByClientId = (id, annotationId) => {
  return axios({
    method: 'delete',
    url: `${ host }/osprey-0.1.0/v1/annotations/${ id }/${ annotationId }`
  })
}

export const updateAnnotationByClientId = (id, annotationId, data) => {
  return axios({
    method: 'put',
    url: `${ host }/osprey-0.1.0/v1/annotations/${ id }/${ annotationId }`,
    data
  })
}
export const saveAnnotationByClientId = (id, data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/annotations/${ id }`,
    data
  })
}

export const updateDefaultAnnotation = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/annotations/god-admin`,
    data
  })
}

export const uploadCsvOfAnnotations = (id, data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/annotations/csv-annotations/${ id }`,
    data
  })
}

export const uploadCsvOfAnnotationsForGodAdmin = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/annotations/csv-annotations/god-admin}`,
    data
  })
}

export const downloadFileExternFeedback = (id) => {
  return `${ host }/osprey-0.1.0/v1/sar/subject-access/zip/${ id }`
}

// export const downloadFileExternFeedbackBox = (sarId) => {
//   return axios({
//     method: 'get',
//     url: `${ host }/osprey-0.1.0/v1/sar/subject-access/download/${ sarId }`,
//     responseType: 'arraybuffer'
//   })
// }

export const apiSarComplete = (id, data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/sar/complete-sar/${ id }`,
    data
  })
}

export const saveRedactedDocument = (data, docId) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/document/pdf/${ docId }`,
    data
  })
}

export const getMessageForStepSeven = (sarId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/sar/document-status/${ sarId }`
  })
}

export const deleteAllDocuments = (sarId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/document/delete-all/${ sarId }`
  })
}

export const deleteDocumentById = (docId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/document/delete/${ docId }`
  })
}

export const getScanAnnotations = (docId) => {

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/solr/query/${ docId }`,
    data: {
      datamodel: 'documents',
      documentID: '',
      query: ''
    }
  })
}

export const getAnnotationColorsByClientId = (clientId, userId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/annotate-color/get-all/${ clientId }/${ userId }`
  })
}
export const getSolrTotalFilesNr = () => {

  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/solr/total-files-scanned`,
  })
}

export const getCrawlerStatus = () => {

  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/master/crawler-status`,
  })
}

export const getAnnotationColorsCategoryByClientId = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/annotate-color/get-all-categories/${ id }`
  })
}

export const getAnnotationColorsDefault = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/annotate-color/get-all-default`
  })
}

export const updateAnnotationColor = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/annotate-color/update-color`,
    data
  })
}

export const saveAnnotationColor = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/annotate-color/update-color`,
    data
  })
}

export const saveAnnotationColorAsGodAdmin = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/annotate-color/add`,
    data
  })
}

export const removeAnnotationColor = (data) => {
  return axios({
    method: 'delete',
    url: `${ host }/osprey-0.1.0/v1/annotate-color/delete/${ data }`
  })
}

export const getDocNotes = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/document/doc-notes/${ id }`
  })
}

export const saveDocNotes = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/document/doc-notes`,
    data
  })
}

export const getAiTagsStatus = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/sar/ai-tags/${ id }`
  })
}

export const apiGetSarMetadata = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/document/metadata`,
    data: data
  })
}

export const getStatistics = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/sar/get-statistics`
  })
}

export const getYearlyOverview = (year) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/sar/get-yearly-overview/${ year }`
  })
}
export const apiSarStepsImportBox = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/sar/import-box`,
    data: data
  })
}
export const apiSarStepsImportFiles = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/sar/import-files`,
    data: data
  })
}
export const apiSarStepsPaginationFiltersFiles = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/sar/pagination-files`,
    data: data
  })
}
export const apiSarDeleteRequest = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/sar/deletesar`,
    data: data
  })
}
export const apiDeleteProofId = (id) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/sar/check-id/delete-proof-id/${ id }`,
  })
}
export const apiExternFileSaveDraft = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/proof-id/save-draft`,
    data: data
  })
}
export const apiSarCheckImportBox = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/sar/check-import-box`,
    data: data
  })
}

export const apiSarCheckAddDocumentsReady = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/sar/check-add-documents-ready`,
    data: data
  })
}
export const apiSarStartAddDocuments = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/sar/start-add-documents`,
    data: data
  })
}
export const apiSarCheckSendDocuments = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/document/sar/check-documents-name-and-size`,
    data: data
  })
}

export const downloadZipExternFeedbackBox = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/sar/subject-access/zip`,
    responseType: 'arraybuffer',
    data
  })
}

export const sarEndRequestData = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/sar/end-request-tracker`,
    data
  })
}

export const downloadFileExternFeedbackData = (id, name) => {
  return `${ host }/osprey-0.1.0/v1/sar/subject-access/zip/${ id }/${ name }`
}
export const downloadFileExternFeedbackWorkflowData = (encodedId) => {
  return `${ host }/osprey-0.1.0/v1/workflow-builder/download-zip-disclosure/${ encodedId }`
}
export const deleteAnnotation = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/annotations/deleteAnnotation`,
    data
  })
}

export const apiGetEndRequestCheckId = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/sar/reject-sar-check-id`,
    data
  })
}

export const getCheckIdVerificationUploadStatus = (uid) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/proof-id/check-id-verification-upload-status/${ uid }`,
  })
}

export const apiGetEmail = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/get-email-templates-workflow`
  })
}

export const apiGetDefaultEmailTemplatesWorkflow = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/get-default-email-templates-workflow`
  })
}

export const apiGetEmailPage = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/get-email-templates-workflow-page`
  })
}

export const apiEditEmailTemplateWorkflow = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/edit-email-template-workflow`,
    data
  })
}

export const apiSaveEmailTemplateWorkflow = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/save-email-template-workflow`,
    data
  })
}

export const apiDeleteEmailTemplateWorkflow = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/delete-email-template-workflow`,
    data
  })
}

export const apiTestEmailTemplateWorkflow = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/test-email-template-workflow`,
    data
  })
}

export const saveAddCustomTagClientAdmin = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/tag-document/add-custom-tag-client-admin`,
    data
  })
}

export const saveEditCustomTagClientAdmin = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/tag-document/edit-custom-tag-client-admin`,
    data
  })
}

export const deleteCustomTagClientAdmin = (clientId, tagId) => {
  return axios({
    method: 'delete',
    url: `${ host }/osprey-0.1.0/v1/tag-document/delete-tag-document/${clientId}/${tagId}`,
  })
}

export const saveAddDefaultTagGodAdmin = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/tag-document/add-default-tag-god-admin`,
    data
  })
}

export const saveEditDefaultTagGodAdmin = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/tag-document/edit-default-tag-god-admin`,
    data
  })
}

export const deleteTagDocumentGodAdmin = (tagId) => {
  return axios({
    method: 'delete',
    url: `${ host }/osprey-0.1.0/v1/tag-document/delete-tag-document-god-admin/${tagId}`,
  })
}

export const apiGetEmailTemplatesWorkflowVariablesContentDefault = (emailCode) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/get-email-templates-workflow-variables-content-default/${emailCode}`
  })
}

export const apiResetEmailTemplateWorkflow = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/reset-email-template-workflow`,
    data
  })
}

export const apiGetEmailTemplatesPageAvailableVariables = (emailTemplateType) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/get-email-templates-workflow-page-available-variables/${emailTemplateType}`
  })
}

export const apiGetDefaultEmailTemplatesWorkflowPage = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/get-default-email-templates-workflow-page`
  })
}

export const apiSaveDefaultEmailTemplateWorkflow = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/save-default-email-template-workflow`,
    data
  })
}

export const apiEditDefaultEmailTemplateWorkflow = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/edit-default-email-template-workflow`,
    data
  })
}

export const apiDeleteDefaultEmailTemplateWorkflow = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/delete-default-email-template-workflow`,
    data
  })
}