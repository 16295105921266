import React from 'react'
import PropTypes from 'prop-types'

export const Card = ({ title, icon, valueNumber, valueDetails }) => {
  return (
    <div className="card" >
      <p className="card__title">{ title }</p>
      <div className="card__content">
        <span className={`card__content__icon ${ icon }`}/>
        <div className="value">
          <p className="value__number">{ valueNumber ? valueNumber : 0 }</p>
          { valueDetails &&
            <p className="value__details">{ valueDetails ? valueDetails : '23h' }</p>
          }
        </div>
      </div>
    </div>
  )
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  valueNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default Card