import axios from 'axios'

export const apiPrivacyGetObjects = (host, filterQueries) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/getDataModelQuery`,
    data: {
      sessionID: token,
      datamodel: 'documents',
      query: '',
      fieldNames: [''],
      filterQueries,
      offset: 0,
      length: 10,
      sortField: '',
      ascending: true
    }
  })
}

export const apiPrivacyGetDirectories = (host, filterQueries) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/getDataModelStats`,
    data: {
      sessionID: token,
      datamodel: 'documents',
      queryString: '*:*', 
      pivotFields: ['doc_crawlerid'],
      fieldNames: ['doc_folder_path'],
      filterQueries,
      facetOffsets: [0],
      facetLengths: [100]
    }
  })
}

export const apiPrivacyGetFolders = (host, page, filterQueries) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/getDataModelFacetValues`,
    data: { 
      sessionID: token,
      datamodel: 'documents',
      facetNames: ['doc_folder_path'],
      filterQueries: ['doc_dynamic_type:25', ...filterQueries],
      offset: page,
      length: 12,
      prefix: ''
    }
  })
}

export const apiPrivacyGetDatasetForFolder = (host, query) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/getDataModelFacetValues`,
    data: {
      sessionID: token,
      datamodel: 'documents',
      facetNames: ['doc_datasource_name'],
      filterQueries: ['doc_dynamic_type:25', query],
      offset: 0,
      length: 10,
      prefix: ''
    }
  })
}

export const apiPrivacyGetLastDateModifiedForFolder = (host, query) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/getDataModelStats`,
    data: {
      sessionID: token,
      datamodel: 'documents',
      query: '*',
      fieldNames: ['doc_date_modified'],
      filterQueries: ['doc_dynamic_type:25', query],
      pivotFields: ['doc_folder_path'],
      facetOffsets: [0],
      facetLengths: [1]
    }
  })
}

export const apiPrivacyGetFilesFromFolders = (host, page, query, filterQueries) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/getDataModelQuery`,
    data: {
      sessionID: token,
      datamodel: 'documents',
      query: '*',
      offset: page,
      length: 12,
      fieldNames: ['doc_id', 'doc_date_modified', 'doc_datasource_name'],
      filterQueries: [query, ...filterQueries],
      sortField: '',
      ascending: true
    }
  })
}

export const apiPrivacyGetInformationForFiles = (host, id, categoryID) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/getDocumentForCategory`,
    data: {
      sessionID: token,
      categoryID,
      query: '*',
      documentID: id
    }
  })
}