import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { getParameterByName } from '../../utils'
import IconCheck from '../../assets/icon-check.png'
import IconError from '../../assets/icon-error-large.png'

import './Email.css'

class Email extends Component {
  constructor() {
    super()
    
    this.state = {
      status: '',
      message: ''
    }
  }

  componentDidMount() {
    const message = getParameterByName('message')
    this.setState({
      message,
      status: this.isSuccess(message) ? 'success' : 'error'
    })
  }

  isSuccess(message) {
    if (message === 'User account does not exist or verification code is invalid.') return false
    if (message === 'Access expired.') return false

    return true
  }

  render() {
    const { message, status } = this.state

    return (
      <div className="email-page">
        <div className="email-box">
          { status === 'success' &&
            <img src={ IconCheck } alt="Icon for success" />
          }
          { status === 'error' &&
            <img src={ IconError } alt="Icon for error" />
          }
          <h1>{ message }</h1>
          <Link to="/osp/login">Back to login screen</Link>
        </div>
      </div>
    )
  }
}

export default Email
