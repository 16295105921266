import React from "react";
import {connect} from "react-redux";
import './EmailWork.scss';
import EmailWorkTemplate from './EmailWorkTemplate'
import {Input, Tabs, Button, Tooltip} from 'antd'
import {apiGetEmail} from "../../../../api/Sar";
const TabPane = Tabs.TabPane

class EmailWorkVariablesUsed extends React.Component {
    state = {
        emailToTest: {},
        defaultVersionExists: false,
        defaultTemplate: false,
    }
    componentDidMount() {
        const { emailTemplate } = this.state
        this.setState({
            defaultVersionExists: this.props.listEmailTemplate ? this.props.listEmailTemplate.defaultVersionExists : this.props.getEmailsTemplate ? this.props.getEmailsTemplate.defaultVersionExists : this.props.getEmailsTemplate.defaultVersionExists,
            defaultTemplate: this.props.listEmailTemplate ? this.props.listEmailTemplate.defaultTemplate : this.props.getEmailsTemplate ? this.props.getEmailsTemplate.defaultTemplate : this.props.getEmailsTemplate.defaultTemplate,
        })
    }

    onCLickSendEmail = () => {
      this.props.onTestEmailTemplateWorkflow()
    }
    onCLickReset = () => {
       this.props.onResetEmailTemplateWorkflow()
    }
    handleChangeEmail = (e, status, variable) => {
      this.props.handleChangeEmailTestTo(e, status, variable)
        this.props.onStatusActiveEdit(true)
    }
    render() {
        const { variables, emailTestTo, variablesTemplate, emailTemplate, stopTestEmailTemplate } = this.props
        const { defaultVersionExists, defaultTemplate } = this.state
        let checkContent = false
        let checkContents = []
        emailTemplate.variablesTemplate && emailTemplate.variablesTemplate.length > 0 && emailTemplate.variablesTemplate.forEach((item) => {
            if (item.content === '') {
                checkContent = true
            }
            checkContents.push(item.content)

        })
        return (
            <div className="email-workflow-variables-used">
                <div className="header-variables-used">
                    <div className="content-test-your-email">
                    <span className="test-your-email">Test your email variables</span>
                    </div>
                    <div className="email-test-to">
                        <span className="test-title">Send test email</span>
                        <Input className="email-test" placeholder="Email address"
                               value={emailTestTo.email ? emailTestTo.email : emailTemplate.testEmailAddress ? emailTemplate.testEmailAddress : emailTestTo.testEmailAddress}
                               onChange={(e) => this.handleChangeEmail(e, 'email')}/>
                    </div>
                </div>
                {/*<div className="email-variables-used">*/}
                {/*    <span> {'${x}'} Email variables used </span>*/}
                {/*    <Tooltip placement="bottom" title={*/}
                {/*        <span>Email variables, are pieces of text in email templates that are automatically filled with the corresponding data about email recipients when they receive your message.*/}
                {/*          The most common variables include the first name of the recipient or the name of the company.*/}
                {/*          You can include it at {'${First Name}'}.</span>}>*/}
                {/*        <span className="icon-v14-question"></span>*/}
                {/*    </Tooltip>*/}
                {/*</div>*/}

                <div className="email-workflow-variables-used__contain">
                    <div className="variables-contain">
                        { variablesTemplate && variablesTemplate.length > 0 && variablesTemplate.map((variable, index) => {
                            return (
                                <span className="variables-content-email" key={index}>
                                    {variable.name ? `\${${variable.name}}` : variable.variableEmail ? variable.variableEmail.replace('#', '$') : variable.variableEmail}
                                </span>
                            )
                         })
                        }
                    </div>
                    <div className="content-email-to">
                        { variablesTemplate && variablesTemplate.length > 0 && variablesTemplate.map((variable, index) => {
                            return (
                                <div key={index} className="content-input">
                                    <Input className="email-test"
                                           value={variable.variableValue ? variable.variableValue : variable.content}
                                           onChange={(e) => this.handleChangeEmail(e, 'name', variable)}/>
                                </div>
                            )
                        })
                        }
                        {/*<div className="content-input">*/}
                        {/*    <Input className="email-test" placeholder="personal identifier"*/}
                        {/*           defaultValue={emailTestTo.identifier || ''}*/}
                        {/*           onChange={(e) => this.handleChangeEmail(e, 'identifier')}/>*/}
                        {/*</div>*/}
                        {/*<div className="content-input">*/}
                        {/*    <Input className="email-test" placeholder="company"*/}
                        {/*           defaultValue={emailTestTo.company || ''}*/}
                        {/*           onChange={(e) => this.handleChangeEmail(e, 'company')}/>*/}
                        {/*</div>*/}
                        <div className={ defaultVersionExists === true && defaultTemplate === false ? "content-button content-button-update-first" : 'content-button content-button-update' }>
                            { defaultVersionExists === true && defaultTemplate === false &&
                                <Button className="button-reset"
                                        //disabled={!defaultVersionExists && defaultTemplate}
                                        onClick={(e) => this.onCLickReset(e)}>Reset</Button>
                            }
                            <Button className="button-send-email"
                                    disabled={stopTestEmailTemplate || (emailTemplate.emailAddress === '' || emailTemplate.emailAddress === undefined) &&
                                    (emailTemplate.testEmailAddress === '' || emailTemplate.testEmailAddress === undefined || emailTemplate.testEmailAddress === null) || checkContents.includes('')}
                                    onClick={(e) => this.onCLickSendEmail(e)}>Send</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(EmailWorkVariablesUsed)