import React from 'react'
import './AssetRegisterComponents.scss'

const Text = (props) => {
  return (
    <div className="text-component">
      <input type="text" placeholder="Text goes here..." className="text-component__input" readOnly/>
    </div>
  )
}

export default Text