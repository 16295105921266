import React from 'react'
import PropTypes from 'prop-types'
import '../YN.css'

const Completed = ({ question, responseText, showResponse }) => (
  <div>
    <div className="questionTypeYN-group">
      { question.options.map((option) => (
        <div
          className={ getClassName(option.title) }
          key={ option.id }>
          <input
            checked={ option.isSelected }
            disabled={ true }
            id={ option.id }
            name="answer"
            type="radio"
            value={ option.title } />
          <label
            className={ getClassNameChecked(option) }
            htmlFor={ option.id }>
            { option.title }
          </label>
        </div>  
      ))
      }
    </div>

    { showResponse &&
      <Response text={ responseText } />
    }
  </div>
)

const Response = ({ text }) => (
  <div>
    <h2 className="questionTypeYN-messageTitle">Response</h2>
    <p className="questionTypeYN-messageText">{ text }</p>
  </div>
)

const getClassName = (value) => {
  const classes = {
    'yes': 'yes',
    'no': 'no',
    'i don\'t know': 'idontknow'
  }
  return `questionTypeYN-${ classes[value.toLocaleLowerCase()] }`
}

const getClassNameChecked = (option) => {
  const classes = {
    'yes': 'labelYes',
    'no': 'labelNo',
    'i don\'t know': 'labelIdontknow'
  }
  const value = classes[option.title.toLocaleLowerCase()]

  return option.isSelected ? `questionTypeYN-${ value }CheckedDisabled`: `questionTypeYN-${ value }Disabled`
}

Completed.propTypes = {
  question: PropTypes.object.isRequired,
  responseText: PropTypes.string,
  showResponse: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
}

export default Completed
