import React from 'react'
import PropTypes from 'prop-types'

import ConnectorItem from './ConnectorItem'

import './ConnectorList.css'

const ConnectorList = ({ connectors }) => (
  <ul id="connector-list" className="connector-connectorList__wrapper">
    { connectors.map((connector, index) => (
      <ConnectorItem
        connector={ connector }
        key={ connector.name }
      />
    )) }
  </ul>
)

ConnectorList.propTypes = {
  connectors: PropTypes.array.isRequired
}

export default ConnectorList