import React from 'react'
import { Radio, Select, Spin } from 'antd'
import Loading from '../../Common/Loading'
import { Field, reduxForm, submit } from 'redux-form'
import { connect } from 'react-redux'
import { fetchClientData, initializeData } from '../../../actions/adminActions'
import swal from 'sweetalert2'
import CustomNotificationManager from '../../Common/CustomNotificationManager'
import {apiUpdateClientById, apiCreateClient, apiGetClientData} from '../../../api/Admin'
import { apiViewPlan } from '../../../api/Plan'
import './ClientManagement.scss'
import PropTypes from 'prop-types'
import { getErrorMessage } from '../../../utils'
import withRouter from '../../../private-routers/withRouter'
import {apiGetBrandingProfilesSimple, apiGetSubTenantList} from "../../../api/SmartBox";

const { Option } = Select
const RadioGroup = Radio.Group

class AddEditForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      modalVisible: false,
      emailAddress: '',
      homeApplication: 'HOME',
      disabledCreateButton: false,
      applications: [
        //{ applicationId: '', name: '' },
        { applicationId: 'HOME', name: 'Home' },
        { applicationId: 'ASSESSMENTS', name: 'Assessments' },
        { applicationId: 'SAR_TRACKER', name: 'SAR Tracker' },
        { applicationId: 'ASSET_REGISTER', name: 'Asset Register' },
        { applicationId: 'DOC_LOCKER', name: 'Document Locker' },
        { applicationId: 'SMART_BOX', name: 'Smart Box' },
        { applicationId: 'WORKFLOW_APP', name: 'Workflow App' },
        { applicationId: 'DATA_MAPPING_APP', name: 'Data Mapping App' }
      ],
      visibleDataMapping: true,
      listGetBrandingProfilesSimple: [],
      resellerProfile: null,
      plans:[],
      listSubTenant: [],
      subTenants: [],

    }
  }

  componentDidMount() {
    if (this.props) {
      const formType = this.props.action
      if (formType === 'add') {
        this.props.initializeData()
      }
      if (this.props.action === 'edit') {
        this.props.fetchClientData(this.props.params.id)
        if (this.props.collectAdminClient) {
          this.props.initializeData(this.props.collectAdminClient, this.props.action)
        } else if (this.props.initialValues) {
          this.props.initializeData(this.props.initialValues, this.props.action)
        }
        this.setState({
          homeApplication: this.props.collectAdminClient.homeApplication ? this.props.collectAdminClient.homeApplication :
              this.props.initialValues &&this.props.initialValues.homeApplication ? this.props.initialValues.homeApplication : 'HOME',
          resellerProfile: this.props.client.selectedBrandingProfile ? this.props.client.selectedBrandingProfile : this.props.initialValues && this.props.initialValues.resellerProfile ? this.props.initialValues.resellerProfile : null
        })
      }
      if (this.props.listGetBrandingProfilesSimple && this.props.listGetBrandingProfilesSimple.length > 0) {
        this.setState({
          listGetBrandingProfilesSimple: this.props.listGetBrandingProfilesSimple
        })
      }
      if (this.props.plans && this.props.plans.length > 0) {
        this.setState({
          plans: this.props.plans
        })
      }
      //this.onGetBrandingProfilesSimple()
    }
    apiViewPlan().then((res) => {
      this.setState({
        plans: res.data,
        plansUpdate: res.data,
        loading: false
      })
    })
    apiGetBrandingProfilesSimple().then((response) => {
      this.setState({
        listGetBrandingProfilesSimple: response.data
      })
    })

    this.onGetSubTenantList()
  }

  onGetSubTenantList = () => {
    apiGetSubTenantList().then((response) => {
      this.setState({
        listSubTenant: response.data
      })
    })
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { homeApplication } = this.state
    if (prevProps !== this.props && this.props.initialValues && this.props.initialValues.homeApplication) {
      this.props.initializeData(this.props.initialValues, this.props.action)
      if (this.state.listGetBrandingProfilesSimple && this.state.listGetBrandingProfilesSimple.length > 0) {
        this.setState({
          listGetBrandingProfilesSimple: this.state.listGetBrandingProfilesSimple,
          resellerProfile: this.props.client.selectedBrandingProfile ? this.props.client.selectedBrandingProfile : this.props.initialValues && this.props.initialValues.resellerProfile ? this.props.initialValues.resellerProfile : null
        }, () => {
          this.setState({
            listGetBrandingProfilesSimple: this.state.listGetBrandingProfilesSimple,
          })
        })
        this.props.initializeData(this.props.initialValues, this.props.action)
      }
      // this.setState({
      //   resellerProfile: this.props.client.selectedBrandingProfile ? this.props.client.selectedBrandingProfile : this.props.initialValues && this.props.initialValues.resellerProfile ? this.props.initialValues.resellerProfile : null
      // })
      // //this.props.fetchClientData(this.props.params.id)
      // this.setState({
      //   //homeApplication: this.props.initialValues.homeApplication
      // }, () => {
      //   //this.props.initializeData()
      // })
    }
  }

  onGetBrandingProfilesSimple = () => {
    apiGetBrandingProfilesSimple().then((response) => {
      this.setState({
        listGetBrandingProfilesSimple: response.data
      })
    })
  }
  handleSubmit = (formValues) => {
    const domain= this.props.initialValues ? this.props.initialValues.domain : ''
    const tempThis = this
    this.setState({
      loading: true
    }, () => {
      if (domain === 'add' && domain !== formValues.domain) {
        const { numberOfUsers } = this.props.initialValues
        swal.fire({
          text: `Domain was modified, ${ numberOfUsers } users will be modified to new ${ formValues.domain } domain, are you sure? `,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3DD8DB',
          cancelButtonColor: '#D33D33',
          confirmButtonText: 'Yes'
        }).then((result) => {
          if (result.value) {
            tempThis.createOrUpdate(formValues)
          }
          this.setState({ loading: false })
        })
      } else {
        this.createOrUpdate(formValues)
      }
    })
  }

  createOrUpdate = (formValues) => {
    let  homeApplication  = this.state.homeApplication
    let  resellerProfile  = this.state.resellerProfile
    formValues.homeApplication = homeApplication
    //formValues.resellerProfile = resellerProfile
    if (this.props.action === 'edit') {
      const id = this.props.location.pathname.split('/').pop()
      apiUpdateClientById(id,formValues).then((resp) => {
        CustomNotificationManager.success('Client was successfully modified ', 'Success')
        this.setState({
          loading: false,
        }, () => this.props.navigate('/admin/client-data'))
      })
    } else {
      apiCreateClient(formValues).then((response) => {
        if (response.status === 200) {
          this.setState({
            loading: false,
            disabledCreateButton: true
          }, () => {
            CustomNotificationManager.success('Client was successfully created ', 'Success')
            this.props.setNewClientData(response.data)
          })

        }
      }).catch((error) => {
        this.setState({ loading: false })
        let errorToDisplay = getErrorMessage(error).length === 2 ? getErrorMessage(error)[1] : getErrorMessage(error)
        if (errorToDisplay === 'CLIENT_ALREADY_EXISTS_EXCEPTION') {
          errorToDisplay = 'This email is already used.'
          CustomNotificationManager.error(errorToDisplay, 'Error')
        }
      })
    }
  }

  renderError({ error, touched }) {
    if (touched && error) {
      return (
        <span className="error_container">
          <span className="error_item">{error}</span>
        </span>
      )
    }
  }

  renderInput = ({ input, label, type, meta, placeholder }) => {
    return (
      <div className="tracker_formGroup full_width">
        <label htmlFor={label}>{label}:</label>
        <input {...input} type={type} placeholder={placeholder} />
        {this.renderError(meta)}
      </div>
    )
  };

  renderActiveSelect = ({ input, label, meta }) => {
    const defaultValue = this.props.initialValues ? this.props.initialValues.isActive: 1
    return (
      <div className="tracker_formGroup full_width" id={`${ input.name }-container`}>
        <label htmlFor={label}>{label}:</label>
        <Select defaultValue={defaultValue} {...input} getPopupContainer={() => document.querySelector(`#${ input.name }-container`)}>
          <Option value={1}>ACTIVE</Option>
          <Option value={0}>INACTIVE</Option>
        </Select>
        {this.renderError(meta)}
      </div>
    )
  };

  renderPlanSelect = ({ input, label, meta }) => {
    let defaultValue = null
    const { plans } =  {...this.state}
    let initialUpdateValue = {}
    let planName = ''
    if (this.props) {
      initialUpdateValue = this.props.initialValues
      plans && plans.length > 0 && plans.forEach((item) => {
        if ((input.value === item.planId) ) {
          planName = item.name
        }
      })
      defaultValue = input.value ? input.value : planName ? planName : this.props.initialValues ? this.props.initialValues.planId: null
    }
    if (initialUpdateValue) {
      initialUpdateValue.planId = defaultValue
      console.log(initialUpdateValue,'initialUpdateValue')
    }
    return (
      <div className="tracker_formGroup full_width" id={`${ input.name }-container`}>
        { this.props && plans && plans.length > 0 &&
            <React.Fragment>
              <label htmlFor={label}>{label}:</label>
              <Select defaultValue={defaultValue} value={defaultValue} {...input} getPopupContainer={() => document.querySelector(`#${ input.name }-container`)}>
                { plans && plans.map((plan, index) => <Option value={plan.planId} key={index}>{plan.name}</Option>) }
              </Select>
              {this.renderError(meta)}
            </React.Fragment>
        }
      </div>
    )
  };
  updateValuesResellerSelect(e) {
    this.setState({ resellerProfile: e })
  }
  renderResellerSelect = ({ input, label, meta }) => {
    let defaultValue = null
    let initialUpdateValue = {}
    const { listGetBrandingProfilesSimple } = { ...this.state }
    const { resellerProfile } = { ...this.state }
    let resellerProfileName = ''
    if (this.props) {
      initialUpdateValue = this.props.initialValues
      listGetBrandingProfilesSimple && listGetBrandingProfilesSimple.length > 0 && listGetBrandingProfilesSimple.forEach((item) => {
        if ((input.value === item.profileId) || (resellerProfile === item.profileName) || (resellerProfile === item.profileId)) {
          resellerProfileName = item.profileName
        }
      })
      defaultValue = input.value ? input.value : resellerProfileName ? resellerProfileName :
             this.props.initialValues ? this.props.initialValues.resellerProfile:
                  resellerProfile ? resellerProfile : "select"
    }
    if (initialUpdateValue) {
      initialUpdateValue.resellerProfile = defaultValue
    }
    return (
        <div className="tracker_formGroup full_width" id={`${ input.name }-container`}>
          { this.props && listGetBrandingProfilesSimple && listGetBrandingProfilesSimple.length > 0 &&
          <React.Fragment>
            <label htmlFor={label}>{label}:</label>
            <Select value={ defaultValue }
                    defaultValue={ defaultValue }
                    {...input}
                    getPopupContainer={() => document.querySelector(`#${input.name}-container`)}
                    //onChange={(e) => this.updateValuesResellerSelect(e)}
            >

              { listGetBrandingProfilesSimple.map((plan, index) => {
                    return (
                        <Option value={plan.profileId} key={index}>{plan.profileName}</Option>
                    )
                  }
              )
              }
            </Select>
            {this.renderError(meta)}
          </React.Fragment>
          }
        </div>
    )
  }

  renderSubTenant = ({ input, label, meta }) => {
    const { listSubTenant, subTenants } = {...this.state}
    let initialUpdateValue = {}
    if (this.props) {
      initialUpdateValue = this.props.initialValues
    }
    let defaultValue = null
    defaultValue = input.value ? input.value : this.props.initialValues ? this.props.initialValues.subTenants: []
    if (initialUpdateValue) {
      initialUpdateValue.subTenants = defaultValue
    }
    return (
        <div className="tracker_formGroup tracker_formGroup-tenant full_width" id={`${ input.name }-container`}>

          <React.Fragment>
            <label htmlFor={label}>{label}:</label>
            <Select mode="multiple"
                    {...input}
                    value={ defaultValue }
                    placeholder="Please select"
                    getPopupContainer={() => document.querySelector(`#${input.name}-container`)}
                //onChange={(e) => this.updateValuesResellerSelect(e)}
            >
              {listSubTenant && listSubTenant.length > 0 && listSubTenant.map((plan, index) => {
                    return (
                        <Option value={plan.clientId} key={index}>{`${plan.tenantName} | ${plan.domain}`}</Option>
                    )
                  }
              )
              }
            </Select>
            {this.renderError(meta)}
          </React.Fragment>

        </div>
    )
  }

  renderApplicationsSelect = ({ input, label, meta }) => {
    let defaultValue = ''
    if (this.props) {
      defaultValue = this.props.collectAdminClient ?
          this.props.collectAdminClient.homeApplication : this.props.initialValues ? this.props.initialValues.homeApplication : ''
      if (this.props.action === 'add') {
        defaultValue = ''
      }
    }
    const { applications } = { ...this.state }
    return (
      <div className="tracker_formGroup full_width" id={`${ input.name }-container`}>
        { this.props && applications &&
          <React.Fragment>
            <label htmlFor={ label }>{ label }:</label>
            { this.props.action === 'add' ?
              <Select defaultValue={this.state.homeApplication || defaultValue || ''}
                onChange={(e) => this.updateValues(e)}
                getPopupContainer={() => document.querySelector(`#${input.name}-container`)}>
                {applications.map((applicationItem, index) => <Option
                    value={applicationItem.applicationId} key={index}>{applicationItem.name}</Option>)}
              </Select> :
                this.props.action === 'edit' && this.state.homeApplication ?
                    <Select defaultValue={ this.state.homeApplication ? this.state.homeApplication : defaultValue } onChange={(e) => this.updateValues(e)}
                            getPopupContainer={() => document.querySelector(`#${input.name}-container`)}>
                      {applications.map((applicationItem, index) => <Option
                          value={applicationItem.applicationId} key={index}>{applicationItem.name}</Option>)}
                    </Select>
              : this.props.action === 'edit' && this.props.initialValues && this.state.homeApplication ?
                    <Select defaultValue={this.props.initialValues ? this.props.initialValues.homeApplication : defaultValue} onChange={(e) => this.updateValues(e)}
                            getPopupContainer={() => document.querySelector(`#${input.name}-container`)}>
                      {applications.map((applicationItem, index) => <Option
                          value={applicationItem.applicationId} key={index}>{applicationItem.name}</Option>)}
                    </Select> :
                    <Select defaultValue={this.state.homeApplication ? this.state.homeApplication : defaultValue} onChange={(e) => this.updateValues(e)}
                            getPopupContainer={() => document.querySelector(`#${input.name}-container`)}>
                      {applications.map((applicationItem, index) => <Option
                          value={applicationItem.applicationId} key={index}>{applicationItem.name}</Option>)}
                    </Select>
            }
            {this.renderError(meta)}
          </React.Fragment>
        }
      </div>

    )
  };

  renderRadio = ({ input, label, meta }) => {
    const defaultValue = this.props.initialValues ? this.props.initialValues.sarVisible: false
    if (input.name === 'dataMappingAppVisible') {
      this.setState({
        visibleDataMapping: input.value
      })
    }
    return (
      <div className="tracker_formGroup full_width">
        <label htmlFor={label}>{label}:</label>
        <RadioGroup
          defaultValue={defaultValue} {...input} style={{ display: 'flex' }}>
          <span className="custom-radio__yes">
            <Radio value={true}>Yes</Radio>
          </span>
          <span className="custom-radio__no">
            <Radio value={false}>No</Radio>
          </span>
        </RadioGroup>
        {this.renderError(meta)}
      </div>
    )
  };

  updateValues(e) {
    this.setState({ homeApplication: e })
  }

  render() {
    const { loading, disabledCreateButton, plansUpdate, visibleDataMapping, resellerProfile, listGetBrandingProfilesSimple } = this.state
    const { action, plans } = this.props
    return (
      <form className="tracker-form" onSubmit={this.props.handleSubmit(this.handleSubmit)}>
        <header className="tracker-header">
          <div className="tracker-header__top">
            <h1 className="tracker-title">{ action === 'add' ? 'CREATE CLIENT' : 'Modify Client'}</h1>
          </div>
        </header>
        <div className="tracker-content">
          <Field type="text" name="name" label="Company Name" component={this.renderInput}/>
          <Field type="text" name="isActive" label="Client Status" component={this.renderActiveSelect}/>
          <Field type="text" name="domain" label="Client Domain" component={this.renderInput}/>
          <Field type="text" name="smartBoxVisible" label="Smart Box Visible" component={this.renderRadio}/>
          <Field type="text" name="sarVisible" label="Sar Tracker Visible" component={this.renderRadio}/>
          <Field type="text" name="assetRegisterVisible" label="Asset Register Visible" component={this.renderRadio}/>
          <Field type="text" name="docLockerVisible" label="Document Locker Visible" component={this.renderRadio}/>
          <Field type="text" name="dpiaVisible" label="Assessments Visible" component={this.renderRadio}/>
          <Field type="text" name="dataMappingAppVisible" label="Data Mapping Visible" component={this.renderRadio}/>
          { visibleDataMapping && <Field type="text" name="businessProcessesVisible" label="Business processes visible" component={this.renderRadio}/> }
          { visibleDataMapping && <Field type="text" name="sourceMappingVisible" label="Source mapping visible" component={this.renderRadio}/> }
          { visibleDataMapping && <Field type="text" name="connectorsVisible" label="Connectors visible" component={this.renderRadio}/> }
          <Field type="text" name="workflowAppVisible" label="Workflow Visible" component={this.renderRadio}/>
          <Field type="text" name="allowXeroxUpload" label="Allow Xerox Upload" component={this.renderRadio}/>
          <Field type="text" name="additionalEnvironments" label="Allow additional environments" component={this.renderRadio}/>
          { this.state.listSubTenant && this.state.listSubTenant.length > 0 &&
            <Field type="text" name="subTenants" label="Sub-Tenants"
                   options={this.state.listSubTenant} component={this.renderSubTenant}/>
          }
          <Field type="text" name="resellerAccount" label="Reseller" component={this.renderRadio}/>

            <Field type="text" name="resellerProfile" label="Reseller Profile"
                   options={this.state.listGetBrandingProfilesSimple} component={this.renderResellerSelect}/>
          <Field type="text" name="applicationId" label="Home Application" component={this.renderApplicationsSelect}/>
          { ((plans && plans.length > 0) || (plansUpdate && plansUpdate.length > 0))&&
            <Field type="text" name="planId" label="Subscription Plan" component={this.renderPlanSelect}/>
          }
        </div>
        <div className="tracker-footer">
          {
            loading ? <Spin className="float-right" spinning={loading}
              indicator={<div className="checkout-loading"><Loading/></div>}/> :
              <button type="submit" className="btn-blue-light float-right" disabled={disabledCreateButton}>
                { action === 'add' ? 'Create' : 'Modify Client Data'}
              </button>
          }
        </div>
      </form>
    )
  }
}

const validate = (values) => {
  const errors = {}
  if (!values.name) {
    errors.name = 'This field is required'
  }
  if (!Number.isInteger(values.isActive)) {
    errors.isActive = 'This field is required'
  }
  if (!values.domain) {
    errors.domain = 'This field is required'
  } else if (!/^[A-Z0-9.-]+\.[A-Z]{2,14}$/i.test(values.domain)) {
    errors.domain = 'This must be a valid domain like "gravicus.com"'
  }
  if (typeof values.sarVisible !== 'boolean') {
    errors.sarVisible = 'This field is required'
  }
  if (typeof values.assetRegisterVisible !== 'boolean') {
    errors.assetRegisterVisible = 'This field is required'
  }
  if (typeof values.docLockerVisible !== 'boolean') {
    errors.docLockerVisible = 'This field is required'
  }
  if (typeof values.dpiaVisible !== 'boolean') {
    errors.dpiaVisible = 'This field is required'
  }
  if (typeof values.smartBoxVisible !== 'boolean') {
    errors.smartBoxVisible = 'This field is required'
  }
  if (typeof values.dataMappingVisible !== 'boolean') {
    errors.dataMappingVisible = 'This field is required'
  }
  if (typeof values.workflowVisible !== 'boolean') {
    errors.workflowVisible = 'This field is required'
  }
  if (typeof values.additionalEnvironments !== 'boolean') {
    errors.additionalEnvironments = 'This field is required'
  }
  if (!values.planId) {
    errors.planId = 'This field is required'
  }
  return errors
}

AddEditForm.propTypes = {
  action: PropTypes.string.isRequired,
  plans: PropTypes.array,
  applications: PropTypes.array,
  listGetBrandingProfilesSimple: PropTypes.array,
  setNewClientData: PropTypes.func,
}

const stateMap = (state) => ({
  client: state.subscriptionReducer.subscription.subscription.client,
  collectAdminClient: state.smartBox.collectAdminClient,
})

AddEditForm = reduxForm({
  form: 'clientEditForm',
  validate,
  onSubmit: submit,
  enableReinitialize: true
})(AddEditForm)

AddEditForm = connect(
  (state) => ({
    initialValues: state.admin.clientEditForm
  }), { fetchClientData, initializeData }
)(AddEditForm)
AddEditForm = withRouter(AddEditForm)

export default connect(stateMap)(AddEditForm)