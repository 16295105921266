import React from "react";
import {connect} from "react-redux";
import '../BuilderWorkflow.scss';
import {Input, Checkbox, Select} from "antd";
import {apiGetEmail} from "../../../../../api/Sar";
const { Option } = Select
class EmailBuilder extends React.Component {
    state = {
        emailBuilder: {
            selectEmail: '',
        },
        hoverDelete: false,
        listEmails: []
    }
    componentDidMount() {
        this.onGetEmailsTemplate()
    }
    onGetEmailsTemplate = () => {
        apiGetEmail().then((response) => {
            this.setState({
                listEmails: response.data
            })
        })
    }
    onHoverActionComponent = () => {
        this.setState({
            hoverDelete: true
        })
    }
    onLeaveActionComponent = () => {
        this.setState({
            hoverDelete: false
        })
    }
    onSelectEmails = (e) => {
        const { listEmails } = this.state
        let componentName = ''
        listEmails && listEmails.length > 0 && listEmails.forEach((list) => {
             if (list.emailTemplateId === e) {
                 componentName = list.templateName
             }
        })
        this.setState({
            emailBuilder: { ...this.state.emailBuilder, selectEmail: e, componentName: componentName, idComponent: e},
        }, () => {
            this.props.onChangeBuilder(this.state.emailBuilder, this.props.component)
            this.props.onSelectIdEmailTemplate(e)
        })
    }
    render() {
        const { listEmails, hoverDelete, emailBuilder } = this.state
        const { component, onStateProvidedDraggable } = this.props
        return (
            <div className="component-form">
                <div className="component-content">
                    <div className="header-component">
                        <span className="header-title">Email</span>
                    </div>
                    <div className="select-content">
                          <span onClick={ (e) => this.props.showConfirmDeleteSection(e, component) }
                              className={hoverDelete ? 'icon-v19-delete-active' : "icon-v19-delete" }
                              onMouseEnter={ (e) => this.onHoverActionComponent(e)}
                              onMouseLeave={() => this.onLeaveActionComponent()}></span>
                        <Select className="select-form"
                                value={ emailBuilder.selectEmail ? emailBuilder.selectEmail : component.componentName ? component.componentName : 'Select' }
                                onChange={(e) => this.onSelectEmails(e)}
                                dropdownStyle={{border:"1px solid #eda41e"}}
                                placeholder="Select">
                            { listEmails && listEmails.length > 0 && listEmails.map((item,index) => {
                                return  (<Option className="options-select-connector" value={ item.emailTemplateId } key={index}>
                                    { item.templateName }
                                </Option>)
                            })}
                        </Select>
                    </div>
                </div>
                <div className="component-move">
                    <span onMouseEnter={() => this.props.onStateProvidedDraggable(component)} className="icon-v211-grid-view-1"></span>
                </div>
            </div>
        )
    }
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(EmailBuilder)