import React from "react";
import {connect} from "react-redux";
import '../BuilderWorkflow.scss';
import {Input, Checkbox, Select} from "antd";
import {apiGetEmail} from "../../../../../api/Sar";
import CustomNotificationManager from "../../../../Common/CustomNotificationManager";
const { Option } = Select

class DisclosureWorkflowBuilder extends React.Component {
    state = {
        formOptions: {
            valueSelect: '',
            selectEmail: '',
            componentName: '',
            emailDisclosure: '',
            resultUrl: '',
            idComponent: '',
            variablesFormList: [],
            idComponentForm: [],
            variablesFormDisclosure: [],
            workflowTemplateVariableLis: [],
        },
        hoverDelete: false,
        listEmails: [],
        listSelectedEmail: {},
        formVariables: [],
        idComponentForm: null,
        listResultsUrl: [{
            id:1, name: '$ Show results'
        }],
        listEmailsSpecial: [
            {id: 1, name: 'special email'},
            {id: 2, name: 'system url'},
        ],
    }

    componentDidMount() {
        this.onGetEmailsTemplate()
        if (this.props.setVariablesEmailWorkflow && this.props.setVariablesEmailWorkflow.length > 0) {
            this.setState({
                formOptions: { ...this.state.formOptions, variablesFormList: this.props.setVariablesEmailWorkflow, idComponentForm: this.props.component.idComponentForm,
                    resultUrl: this.props.component.resultUrl, emailDisclosure: this.props.component.emailDisclosure, workflowTemplateVariableLis: this.props.component.workflowTemplateVariableLis},
                formVariables: this.props.setVariablesEmailWorkflow,
                idComponentForm: this.props.component.idComponentForm,
            }, () => {
                //this.props.onChangeBuilder(this.state.formOptions, this.props.component)
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props && this.props.setVariablesEmailWorkflow && this.props.setVariablesEmailWorkflow.length > 0) {
            this.setState({
                formVariables: this.props.setVariablesEmailWorkflow,
                formOptions: { ...this.state.formOptions, variablesFormList: this.props.setVariablesEmailWorkflow, idComponentForm: this.props.component.idComponentForm,
                    resultUrl: this.props.component.resultUrl, emailDisclosure: this.props.component.emailDisclosure, workflowTemplateVariableLis: this.props.component.workflowTemplateVariableLis},
                idComponentForm: this.props.component.idComponentForm,
            }, () => {
                //this.props.onChangeBuilder(this.state.formOptions, this.props.component)
            })
            // if (this.props.component.idComponentForm) {
            //     this.setState({
            //         idComponentForm: this.props.component.idComponentForm,
            //     })
            // }
        }
    }
    onGetEmailsTemplate = () => {
        const { listSelectedEmail} = this.state
        apiGetEmail().then((response) => {
            response.data && response.data.length > 0 && response.data.forEach((item) => {
                    item.variables && item.variables.length > 0 && item.variables.forEach((variable) => {
                        variable.formVariable = null
                        this.props.component.workflowTemplateVariableList && this.props.component.workflowTemplateVariableList.length > 0 && this.props.component.workflowTemplateVariableList.forEach((variable3) => {
                            if (variable.id === variable3.email_template_variable_id ) {
                                variable.selectedVariable = variable3.form_variable

                            }
                        })
                    })
                    this.setState({
                        listSelectedEmail: {
                            variables: item.variables
                        }
                    })
            })
            const listEmailsFilter = []
            response.data && response.data.length > 0 && response.data.forEach((email) => {
                if (email && email.emailTemplateType === 'Disclosure emails') {
                    listEmailsFilter.push(email)
                }
            })
            this.setState({
                listEmails: listEmailsFilter
            }, () => {
                if (this.props.component.idComponent) {
                    this.onSelectEmailsMount(this.props.component.idComponent)
                }
            })
        })
    }
    onSelectEmailsMount = (e) => {
        const { listEmails } = this.state
        let componentName = ''
        let selectEmail = {}
        listEmails && listEmails.length > 0 && listEmails.forEach((list) => {
            if (list.emailTemplateId === e) {
                componentName = list.templateName
                selectEmail = list
            }
        })
        this.setState({
            formOptions: { ...this.state.formOptions, selectEmail: e, componentName: componentName, idComponent: e},
            listSelectedEmail: selectEmail
        }, () => {
            this.props.onSelectIdDisclosureEmail(e)
        })
    }
    onHoverActionComponent = () => {
        this.setState({
            hoverDelete: true
        })
    }
    onLeaveActionComponent = () => {
        this.setState({
            hoverDelete: false
        })
    }
    onSelectEmails = (e) => {
        const { listEmails } = this.state
        let componentName = ''
        let selectEmail = {}
        listEmails && listEmails.length > 0 && listEmails.forEach((list) => {
            if (list.emailTemplateId === e) {
                componentName = list.templateName
                selectEmail = list
            }
        })
        this.setState({
            formOptions: { ...this.state.formOptions, selectEmail: e, componentName: componentName, idComponent: e},
            listSelectedEmail: selectEmail
        }, () => {
            this.props.onChangeBuilder(this.state.formOptions, this.props.component)
            this.props.onSelectIdDisclosureEmail(e)
        })
        // if (selectEmail && selectEmail.content && (selectEmail.content.includes('${resultUrl}') || selectEmail.content.includes('#{resultUrl}'))) {
        //     this.setState({
        //         formOptions: { ...this.state.formOptions, selectEmail: e, componentName: componentName, idComponent: e},
        //         listSelectedEmail: selectEmail
        //     }, () => {
        //         this.props.onChangeBuilder(this.state.formOptions, this.props.component)
        //         this.props.onSelectIdDisclosureEmail(e)
        //     })
        // } else {
        //     CustomNotificationManager.info('Variable ${resultUrl} is required to be present in the selected email template. Please put it in the template and try again!', 'Info')
        //     this.setState({
        //         formOptions: { ...this.state.formOptions, selectEmail: '', componentName: '', idComponent: ''},
        //         listSelectedEmail: {}
        //     }, () => {
        //         this.props.onChangeBuilder(this.state.formOptions, this.props.component)
        //         this.props.onSelectIdDisclosureEmail('')
        //     })
        // }
    }
    onSelectEmailVariables = (e, variable) => {
        const { listSelectedEmail } = this.state
        listSelectedEmail.variables && listSelectedEmail.variables.length > 0 && listSelectedEmail.variables.map((item) => {
            if (item.variableEmail === variable.variableEmail) {
                item.formVariable = e
                item.selectedForm = e
                item.selectedVariable = e
            }
        })
        this.setState({
            listSelectedEmail,
            formOptions: { ...this.state.formOptions, variablesFormDisclosure: listSelectedEmail },
        }, () => {
            this.props.onChangeBuilder(this.state.formOptions, this.props.component)
        })
    }
    onSelectEmailsSpecial = (e) => {
        this.setState({
            formOptions: { ...this.state.formOptions, emailDisclosure: e},
        }, () => {
            this.props.onChangeBuilder(this.state.formOptions, this.props.component)
        })
    }
    onSelectResultsUrl = (e) => {
        this.setState({
            formOptions: { ...this.state.formOptions, resultUrl: e},
        }, () => {
            this.props.onChangeBuilder(this.state.formOptions, this.props.component)
        })
    }
    render() {
        const { hoverDelete, listEmails, formOptions, listSelectedEmail, formVariables, listResultsUrl, listEmailsSpecial } = this.state
        const { component, onStateProvidedDraggable, selectWorkflowTemplate } = this.props
        const listVariables = [
            { key: 1, name: '${name}'},
            { key: 2, name: '${PurposeOfProcessing}'},
        ]
        return (
            <div className="component-form">
                <div className="component-content component-content-new">
                    <div className="header-component">
                        <span className="header-title header-title-collector">Disclosure</span>
                    </div>
                    <div className="content-disclosure">
                         <span onClick={ (e) => this.props.showConfirmDeleteSection(e, component) }
                               className={selectWorkflowTemplate.defaultTemplate === true ? 'icon-v19-delete icon-v19-delete-disabled' : hoverDelete ? 'icon-v19-delete-active' : "icon-v19-delete" }
                               onMouseEnter={ (e) => this.onHoverActionComponent(e)}
                               onMouseLeave={() => this.onLeaveActionComponent()}></span>
                        <div className="contain-disclosure">
                           <span className="contain-email-to">
                               <span className="title-contain">Email template:</span>
                                {/*<Select className="select-form"*/}
                                {/*        dropdownClassName={'select-form-dropdown'}*/}
                                {/*        value={ formOptions.emailDisclosure ? formOptions.emailDisclosure : 'Special email with $ for system URL'}*/}
                                {/*        onChange={(e) => this.onSelectEmailsSpecial(e)}*/}
                                {/*        placeholder="Special email with $ for system URL">*/}
                                {/*     { listEmailsSpecial && listEmailsSpecial.length > 0 && listEmailsSpecial.map((item,index) => {*/}
                                {/*         return  (<Option className="options-select-connector" value={ item.name } key={index}>*/}
                                {/*             { item.name }*/}
                                {/*         </Option>)*/}
                                {/*     })}*/}
                                {/*</Select>*/}
                                <Select className="select-form"
                                        dropdownClassName={'select-form-dropdown'}
                                        onChange={(e) => this.onSelectEmails(e)}
                                        value={ formOptions.selectEmail ? formOptions.selectEmail : 'Select email template' }
                                        placeholder="Select email template">
                                     { listEmails && listEmails.length > 0 && listEmails.map((item,index) => {
                                         return  (<Option className="options-select-connector" value={ item.emailTemplateId } key={index}>
                                             { item.templateName }
                                         </Option>)
                                     })}
                                </Select>
                           </span>
                           {/* <span className="contain-result-url">*/}
                           {/*    <span className="title-contain">{'${resultUrl}'}:</span>*/}
                           {/*     <Select className="select-form"*/}
                           {/*             dropdownClassName={'select-form-dropdown'}*/}
                           {/*             value={ formOptions.resultUrl ? formOptions.resultUrl : 'Select results'}*/}
                           {/*             onChange={(e) => this.onSelectResultsUrl(e)}*/}
                           {/*             placeholder="Select results">*/}
                           {/*         { listResultsUrl && listResultsUrl.length > 0 && listResultsUrl.map((item,index) => {*/}
                           {/*             return  (<Option className="options-select-connector" value={ item.name } key={index}>*/}
                           {/*                 { item.name }*/}
                           {/*             </Option>)*/}
                           {/*         })}*/}
                           {/*     </Select>*/}
                           {/*</span>*/}
                            { Object.keys(listSelectedEmail) && Object.keys(listSelectedEmail).length > 0 ?
                                <span className="contain-variables">
                               <span className="default-variables">Variables:</span>
                                <span className="list-emails">
                                {
                                    listSelectedEmail.variables && listSelectedEmail.variables.length > 0 && listSelectedEmail.variables.map((item) => {
                                        return (
                                            <span className="content-select-email" key={item.variableEmail}>
                                                {item.variableEmail !== '#{resultUrl}' ?
                                                        <React.Fragment>
                                                            <span
                                                                className="name-list">{item.variableEmail ? item.variableEmail.replace('#', '$') : item.variableEmail}</span>
                                                            {/*<Select*/}
                                                            {/*    className={item.variableEmail === '#{resultUrl}' ? "select-form-disabled" : 'select-form'}*/}
                                                            {/*    disabled={item.variableEmail === '#{resultUrl}' ? true : false}*/}
                                                            {/*    value={item.selectedVariable}*/}
                                                            {/*    dropdownClassName={'select-form-dropdown'}*/}
                                                            {/*    onChange={(e) => this.onSelectEmailVariables(e, item)}*/}
                                                            {/*    placeholder="Select">*/}
                                                            {/*    {formVariables && formVariables.length > 0 && formVariables.map((item, index) => {*/}
                                                            {/*        return (<Option className="options-select-connector"*/}
                                                            {/*                        value={item} key={index}>*/}
                                                            {/*            {item}*/}
                                                            {/*        </Option>)*/}
                                                            {/*    })}*/}
                                                            {/*</Select>*/}
                                                        </React.Fragment> :
                                                    <span className="content-result-url">
                                                            <span
                                                                className="name-list">{item.variableEmail ? item.variableEmail.replace('#', '$') : item.variableEmail}</span>
                                                        </span>
                                                }
                                                    </span>
                                        )
                                    })
                                }
                                </span>
                               </span> :
                                <span className="contain-variables">
                               <span className="default-variables">Variables:</span>
                                </span>
                            }
                        </div>
                    </div>
                </div>
                <div className="component-move">
                    <span onMouseEnter={() => this.props.onStateProvidedDraggable(component)} className="icon-v211-grid-view-1"></span>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    setVariablesEmailWorkflow: state.smartBox.setVariablesEmailWorkflow,
})

export default connect(mapStateToProps)(DisclosureWorkflowBuilder)