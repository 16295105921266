import React from 'react'
import { Button, Form, Select, Spin } from 'antd'
import {
  generateComplianceDocumentFormAdditionalFields,
  generateComplianceDocumentFormColumns, generateComplianceDocumentFormHistoryUpdate
} from '../../../../utils/AssetRegister/AssetRegisterHelperMethods'
import _ from 'lodash'
import { createComplianceDocument } from '../../../../api/DocLocker'
import Loading from '../../../Common/Loading'

const { Option } = Select
class AddComplianceDocument extends React.Component {
  state = {
    library: {},
    values: {},
    loadingAddDocLocker: false
  }

  onLibrarySelect = (libraryName) => {
    const { libraries } = this.props
    const library = libraries.filter((library) => library.library.name === libraryName)[0]

    this.setState({
      library: library.library
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({
      loadingAddDocLocker: true
    })
    const data = { ...this.state }
    this.props.form.validateFields((err, values) => {
      if (!err) {
        createComplianceDocument(data).then((res) => {
          this.props.addNewValueToTable(res.data, this.state.library)
        })
      }
    })
  };

  onChange = (value, columnId, uploadLabelId) => {
    if (uploadLabelId) {
      const uploadLabel = document.querySelector(`#${ uploadLabelId }`)
      uploadLabel.innerHTML = value.name
    }
    this.setState({
      values: {
        ...this.state.values, [columnId]: value
      }
    })
  }

  render() {
    let { libraries } = this.props
    const { getFieldDecorator } = this.props.form
    const { library, loadingAddDocLocker } = this.state
    libraries = libraries.map((itemLibrary) => itemLibrary.library)

    return (
      <div className="add-compliance-document">
        <div className="add-compliance-document__select-section">
          <span className="title">LIBRARY TYPE</span>
          <Select placeholder="Search By..." onChange={(value) => this.onLibrarySelect(value)}
            className="custom-select">
            {
              libraries && libraries.length > 0 && libraries.map((library, index) =>
                <Option value={library.name} key={index}>{library.name}</Option>
              )
            }
          </Select>
        </div>
        <div className="add-compliance-document__form-section">
          { !_.isEmpty(library) &&
          <Form onSubmit={this.handleSubmit}>
            <div className="columns-section">
              { library.columns &&
                generateComplianceDocumentFormColumns(library.columns, getFieldDecorator, this.onChange)
              }
              <i className="down custom-caret" />
            </div>
            <div className="history-update-section">
              { library.historyUpdate &&
                generateComplianceDocumentFormHistoryUpdate(library.historyUpdate, getFieldDecorator, this.onChange)
              }
              { (library.additionalFields && library.additionalFields.length > 0) && <i className="down custom-caret" /> }
            </div>
            <div className="additional-fields-section">
              { library.additionalFields &&
                generateComplianceDocumentFormAdditionalFields(library.additionalFields, getFieldDecorator, this.onChange)
              }
            </div>
            <Form.Item className='form-item-actions'>
              {
                loadingAddDocLocker && !getFieldDecorator ? <Spin className="float-right" spinning={loadingAddDocLocker}
                  indicator={<div className="asset-register-loading"><Loading/></div>}/> :
                  <Button htmlType="submit" onClick={this.handleSubmit} className="button-green">
                      ADD
                  </Button>
              }
            </Form.Item>
          </Form>
          }

        </div>
      </div>
    )
  }
}

AddComplianceDocument = Form.create({ name: 'add_compliance_document' })(AddComplianceDocument)

export default AddComplianceDocument