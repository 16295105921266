import React from 'react'
//import './annotations.scss'
import './TagsDocuments.scss'
import {Table, Input, Popconfirm, Form, Select, Switch, Popover, Icon} from 'antd'
import {
    deleteAnnotation,
    saveAnnotationByClientId,
    updateDefaultAnnotation,
    saveAddCustomTagClientAdmin,
    saveEditCustomTagClientAdmin, deleteCustomTagClientAdmin
} from '../../../../api/Sar'
import { connect } from 'react-redux'
import CustomNotificationManager from "../../../Common/CustomNotificationManager";
import {customConfirm} from "../../../../utils/AssetRegister/CustomConfirm";
import {CompactPicker} from "react-color";
import {editBusinessList, saveManualTermsColor} from "../../../../api/SmartBox";

const EditableContext = React.createContext()
const { TextArea } = Input
const { Option } = Select
let valueInput = null
let colorPickerModalVisible = true
const listIconsDocuments = [
    {type:"account-book"},
    {type:"alert"},
    {type:"api"},
    {type:"appstore"},
    {type:"audio"},
    {type: "bank"},
    {type:"bell"},
    {type:"book"},
    {type:"box-plot"},
    {type:"bug"},
    {type:"build"},
    {type:"bulb"},
    {type:"calculator"},
    {type:"calendar"},
    {type:"camera"},
    {type:"car"},
    {type:"carry-out"},
    {type:"check-circle"},
    {type:"check-square"},
    {type:"clock-circle"},
    {type:"close-circle"},
    {type:"close-square"},
    {type:"cloud"},
    {type:"code"},
    {type:"compass"},
    {type:"contacts"},
    {type:"container"},
    {type:"control"},
    {type:"copy"},
    {type:"credit-card"},
    {type:"crown"},
    {type:"customer-service"},
    {type:"dashboard"},
    {type:"database"},
    {type:"delete"},
    {type:"diff"},
    {type:"dislike"},
    {type:"dollar"},
    {type:"down-circle"},
    {type:"down-square"},
    {type:"edit"},
    {type:"environment"},
    {type:"exclamation-circle"},
    {type:"experiment"},
    {type:"eye-invisible"},
    {type:"eye"},
    {type:"file-add"},
    {type:"file-excel"},
    {type:"file-exclamation"},
    {type:"file-image"},
    {type:"file-markdown"},
    {type:"file-pdf"},
    {type:"file-ppt"},
    {type:"file-text"},
    {type:"file-unknown"},
    {type:"file-word"},
    {type:"file-zip"},
    {type:"file"},
    {type:"filter"},
    {type:"fire"},
    {type:"flag"},
    {type:"folder-add"},
    {type:"folder-open"},
    {type:"folder"},
    {type:"frown"},
    {type:"fund"},
    {type:"funnel-plot"},
    {type:"gift"},
    {type:"gold"},
    {type:"hdd"},
    {type:"heart"},
    {type:"highlight"},
    {type:"home"},
    {type:"hourglass"},
    {type:"html5"},
    {type:"idcard"},
    {type:"info-circle"},
    {type:"insurance"},
    {type:"interaction"},
    {type:"layout"},
    {type:"left-circle"},
    {type:"left-square"},
    {type:"like"},
    {type:"lock"},
    {type:"mail"},
    {type:"medicine-box"},
    {type:"meh"},
    {type:"message"},
    {type:"minus-circle"},
    {type:"minus-square"},
    {type:"mobile"},
    {type:"money-collect"},
    {type:"notification"},
    {type:"pause-circle"},
    {type:"phone"},
    {type:"picture"},
    {type:"pie-chart"},
    {type:"play-circle"},
    {type:"play-square"},
    {type:"plus-circle"},
    {type:"plus-square"},
    {type:"printer"},
    {type:"profile"},
    {type:"project"},
    {type:"property-safety"},
    {type:"pushpin"},
    {type:"question-circle"},
    {type:"reconciliation"},
    {type:"red-envelope"},
    {type:"rest"},
    {type:"right-circle"},
    {type:"right-square"},
    {type:"rocket"},
    {type:"safety-certificate"},
    {type:"save"},
    {type:"schedule"},
    {type:"security-scan"},
    {type:"setting"},
    {type:"shop"},
    {type:"shopping"},
    {type:"skin"},
    {type:"sliders"},
    {type:"smile"},
    {type:"snippets"},
    {type:"sound"},
    {type:"star"},
    {type:"stop"},
    {type:"switcher"},
    {type:"tablet"},
    {type:"tag"},
    {type:"tags"},
    {type:"thunderbolt"},
    {type:"tool"},
    {type:"trophy"},
    {type:"unlock"},
    {type:"up-circle"},
    {type:"up-square"},
    {type:"usb"},
    {type:"video-camera"},
    {type:"wallet"},
    {type:"warning"},
    {type:"alipay-circle"},
    {type:"aliwangwang"},
    {type:"android"},
    {type:"apple"},
    {type:"backward"},
    {type:"behance-square"},
    {type:"ci"},
    {type:"caret-down"},
    {type:"caret-left"},
    {type:"caret-right"},
    {type:"caret-up"},
    {type:"copyright"},
    {type:"chrome"},
    {type:"codepen-circle"},
    {type:"euro"},
    {type:"dribbble-square"},
    {type:"facebook"},
    {type:"fast-backward"},
    {type:"fast-forward"},
    {type:"forward"},
    {type:"github"},
    {type:"gitlab"},
    {type:"instagram"},
    {type:"linkedin"},
    {type:"pay-circle"},
    {type:"read"},
    {type:"robot"},
    {type:"skype"},
    {type:"slack-square"},
    {type:"step-backward"},
    {type:"step-forward"},
    {type:"taobao-circle"},
    {type:"wechat"},
    {type:"weibo-circle"},
    {type:"weibo-square"},
    {type:"windows"},
    {type:"yahoo"},
    {type:"youtube"},
    {type:"yuque"},
    {type:"alibaba"},
    {type:"align-center"},
    {type:"align-left"},
    {type:"align-right"},
    {type:"alipay"},
    {type:"aliyun"},
    {type:"amazon"},
    {type:"ant-cloud"},
    {type:"ant-design"},
    {type:"apartment"},
    {type:"area-chart"},
    {type:"arrow-down"},
    {type:"arrow-left"},
    {type:"arrow-right"},
    {type:"arrow-up"},
    {type:"arrows-alt"},
    {type:"audit"},
    {type:"bar-chart"},
    {type:"barcode"},
    {type:"bars"},
    {type:"behance"},
    {type:"bg-colors"},
    {type:"block"},
    {type:"bold"},
    {type:"border-bottom"},
    {type:"border-horizontal"},
    {type:"border-inner"},
    {type:"border-left"},
    {type:"border-outer"},
    {type:"border-right"},
    {type:"border-top"},
    {type:"border-verticle"},
    {type:"border"},
    {type:"branches"},
    {type:"check"},
    {type:"close"},
    {type:"cloud-download"},
    {type:"cloud-server"},
    {type:"cloud-sync"},
    {type:"cloud-upload"},
    {type:"cluster"},
    {type:"code-sandbox"},
    {type:"codepen"},
    {type:"coffee"},
    {type:"column-height"},
    {type:"column-width"},
    {type:"dash"},
    {type:"deployment-unit"},
    {type:"desktop"},
    {type:"dingding"},
    {type:"disconnect"},
    {type:"dot-chart"},
    {type:"double-left"},
    {type:"double-right"},
    {type:"down"},
    {type:"download"},
    {type:"drag"},
    {type:"dribbble"},
    {type:"dropbox"},
    {type:"ellipsis"},
    {type:"enter"},
    {type:"exception"},
    {type:"exclamation"},
    {type:"export"},
    {type:"fall"},
    {type:"file-done"},
    {type:"file-jpg"},
    {type:"file-protect"},
    {type:"file-search"},
    {type:"file-sync"},
    {type:"font-colors"},
    {type:"font-size"},
    {type:"fork"},
    {type:"form"},
    {type:"fullscreen-exit"},
    {type:"fullscreen"},
    {type:"gateway"},
    {type:"global"},
    {type:"google-plus"},
    {type:"google"},
    {type:"heat-map"},
    {type:"history"},
    {type:"ie"},
    {type:"import"},
    {type:"inbox"},
    {type:"info"},
    {type:"issues-close"},
    {type:"italic"},
    {type:"key"},
    {type:"laptop"},
    {type:"left"},
    {type:"line-chart"},
    {type:"line-height"},
    {type:"line"},
    {type:"link"},
    {type:"loading-3-quarters"},
    {type:"loading"},
    {type:"login"},
    {type:"logout"},
    {type:"man"},
    {type:"medium-workmark"},
    {type:"medium"},
    {type:"menu-fold"},
    {type:"menu-unfold"},
    {type:"menu"},
    {type:"minus"},
    {type:"monitor"},
    {type:"more"},
    {type:"number"},
    {type:"ordered-list"},
    {type:"paper-clip"},
    {type:"pause"},
    {type:"percentage"},
    {type:"pic-center"},
    {type:"pic-left"},
    {type:"pic-right"},
    {type:"plus"},
    {type:"pound"},
    {type:"poweroff"},
    {type:"pull-request"},
    {type:"qq"},
    {type:"qrcode"},
    {type:"question"},
    {type:"radar-chart"},
    {type:"radius-bottomleft"},
    {type:"radius-bottomright"},
    {type:"radius-setting"},
    {type:"radius-upleft"},
    {type:"radius-upright"},
    {type:"reddit"},
    {type:"redo"},
    {type:"reload"},
    {type:"retweet"},
    {type:"right"},
    {type:"rise"},
    {type:"rollback"},
    {type:"safety"},
    {type:"scan"},
    {type:"scissor"},
    {type:"search"},
    {type:"select"},
    {type:"shake"},
    {type:"share-alt"},
    {type:"shopping-cart"},
    {type:"shrink"},
    {type:"sketch"},
    {type:"slack"},
    {type:"small-dash"},
    {type:"solution"},
    {type:"sort-ascending"},
    {type:"sort-descending"},
    {type:"stock"},
    {type:"strikethrough"},
    {type:"swap-left"},
    {type:"swap-right"},
    {type:"swap"},
    {type:"sync"},
    {type:"table"},
    {type:"taobao"},
    {type:"team"},
    {type:"to-top"},
    {type:"trademark"},
    {type:"transaction"},
    {type:"twitter"},
    {type:"underline"},
    {type:"undo"},
    {type:"unordered-list"},
    {type:"up"},
    {type:"upload"},
    {type:"user-add"},
    {type:"user-delete"},
    {type:"user"},
    {type:"usergroup-add"},
    {type:"usergroup-delete"},
    {type:"vertical-align-bottom"},
    {type:"vertical-align-middle"},
    {type:"vertical-align-top"},
    {type:"vertical-left"},
    {type:"vertical-right"},
    {type:"weibo"},
    {type:"wifi"},
    {type:"woman"},
    {type:"zhihu"},
    {type:"zoom-in"},
    {type:"zoom-out"},
]
class EditableCell extends React.Component {

     onChange(e, record, setFieldsValue) {
         record.active = e
         if(e) {
             setFieldsValue({
                 active: e,
             });
         }

    }
    onSelectStatus(e, record, setFieldsValue) {
        record.icon = e
        if (e) {
            record.color = null
            valueInput = 'aaaa'
           setFieldsValue({
                color: null,
            });
        }
    }
    onChangeColor(e, record, setFieldsValue) {
        valueInput = e.target.value
        record.color = e.target.value
        if (e.target.value) {
            record.icon = null
            setFieldsValue({
                icon: null,
            });
        }
    }

    getInput = (record, setFieldsValue) => {
        if (this.props.dataIndex === 'icon') {
            return <Select
                 onChange={ (e) => this.onSelectStatus(e, record, setFieldsValue) }
                 //value={record.icon}
                 dropdownStyle={{"zIndex":1}}
            className="custom-status-select">
                { listIconsDocuments && listIconsDocuments.length > 0 && listIconsDocuments.map((item, index) => {
                    return (
                        <Option className="item-options-status" key={index} value={item.type}>
                            <span><Icon type={`${item.type}`} /></span>
                        </Option>
                    )
                })

                }
                {/*    <Option className="item-options-status" key={1} value="icon-v211-file-redacted">*/}
                {/*        <span className="icon-v211-file-redacted" />*/}
                {/*    </Option>*/}
                {/*    <Option className="item-options-status" key={2} value="icon-v211-file-approved">*/}
                {/*        <span className="icon-v211-file-approved" />*/}
                {/*    </Option>*/}
                {/*<Option className="item-options-status" key={3} value="icon-v211-mail">*/}
                {/*    <span className="icon-v211-mail" />*/}
                {/*</Option>*/}
                {/*    <Option className="item-options-status" key={4} value="icon-v211-db-clock">*/}
                {/*        <span className="icon-v211-db-clock" />*/}
                {/*    </Option>*/}
            </Select>
        }
        if (this.props.dataIndex === 'active') {
            return <Switch checked={record.active} onChange={(e) => this.onChange(e,record,setFieldsValue)} />
        }
        if (this.props.dataIndex === 'default') {
            return <Input className="input-default" readOnly={true} />
        }
        if (this.props.dataIndex === 'color') {
            const renderColorPicker = (
                <React.Fragment>
                    <div className="business-term-color-picker">
                        <CompactPicker
                            className="color-picker-a"
                            colors={['#e7968b', '#7f00ff', '#365194', '#893843', '#ff7a00', '#100c08', '#ffff00', '#8a7f80', '#ffa8f0', '#a74e7b',
                                '#866c83', '#736978', '#767085', '#6d7978', '#918a8f', '#7c8185', '#8e896e', '#ff00c6', '#4e15c8', '#d420ef',
                                '#aed136', '#5936d1', '#d136ae', '#d05d28', '#971b1b', '#8430c8', '#41006c', '#f66868', '#dd5c76', '#cf68f7',
                                '#669a1b', '#2d8113', '#114b38', '#123449', '#0d0a4b', '#b8acd1', '#e2d5f1', '#c9e8fd', '#526d85', '#4e4466'
                            ]}
                            disableAlpha={true}
                            color={record.color ? record.color : '#736978'}
                            onChange={(color) => this.closeAndSaveColorPicker(color, record, setFieldsValue)}
                        />
                    </div>
                </React.Fragment>
            )
            // return <Input
            //     onChange={ (e) => this.onChangeColor(e, record, setFieldsValue) }
            //               value={record.color}
            // ></Input>
            return  <Popover content={renderColorPicker} trigger="click" visible={ colorPickerModalVisible } zIndex={1}>
                <div className="list-selected-color" style={{ backgroundColor: record.color }} onClick={(e) => this.toggleColorPickerVisible(e, 0)}></div>
            </Popover>
        }
        if (this.props.inputType === 'textarea') {
            return <TextArea autoSize={{ minRows: 3, maxRows: 4 }} />
        }
        return <Input />
    };

    closeAndSaveColorPicker = (color, record, setFieldsValue) => {
        record.color = color.hex
        setFieldsValue({
            color: color.hex,
        });
        if (color.hex) {
            record.icon = null
            setFieldsValue({
                icon: null,
            });
        }
    }
    toggleColorPickerVisible = (e, id, open) => {
        //colorPickerModalVisible = !colorPickerModalVisible
    }
    renderCell = ({ getFieldDecorator, setFieldsValue }) => {
        const {
            editing,
            dataIndex,
            title,
            inputType,
            record,
            index,
            children,
            form,
            ...restProps
        } = this.props
        if (editing && record) {

        }
        return (
            <td {...restProps}>
                {editing ? (
                    <Form>
                    <Form.Item style={{ margin: 0 }}>
                        {/*{*/}
                        {/*    this.getInput(record)*/}
                        {/*}*/}
                        {getFieldDecorator(dataIndex, {
                            // rules: [
                            //     {
                            //         required: true,
                            //         message: `Please Input ${ title }!`,
                            //     },
                            // ],
                            //initialValue: title === 'Color' && record.icon !== '' ? '' : title === 'Icon' ? record.icon : record[dataIndex],
                            //initialValue: (record.icon !== null && title === 'Color') ? 'CCC' : record[dataIndex]
                            initialValue: this.onGetInputValue(record, dataIndex, title, setFieldsValue)
                            //getValueFromEvent: this.getArgumet()
                        })(this.getInput(record, setFieldsValue))}
                    </Form.Item>
                    </Form>
                ) : (
                    children
                )}
            </td>
        )
    };
    onGetInputValue = (record, dataIndex, title) => {
        if (title === 'Color') {
            return record.color
        }
        if (title === 'Icon') {
            return record.icon
        }
        return record[dataIndex]
    }
    render() {
        return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    }
}

class TagsTable extends React.Component {
    constructor(props) {
        super(props)
        this.state = { data: [], editingKey: '', pageNumber: 1, activeEdit: {}, activeDelete: {} }
        this.columns = [
            {
                title: 'Tag name',
                dataIndex: 'tagName',
                width: '10%',
                editable: true,
                align: 'center'
            },
            {
                title: 'Color',
                dataIndex: 'color',
                width: '20%',
                editable: true,
                render: (text, record) => {
                    if(text !== null ) {
                        return (
                            <span style={{backgroundColor: text ? text : 'icon-color'}} className='icon-color'></span>
                        )
                    }
                }
            },
            {
                title: 'Icon',
                dataIndex: 'icon',
                width: '20%',
                editable: true,
                align: 'center',
                render: (text, record) => {
                    return (
                        <React.Fragment>
                            { text && text.includes('v211') ?
                                <span className={text}></span> :
                                <Icon type={`${text}`} />
                            }
                        </React.Fragment>
                    )
                }
            },
            {
                title: 'Active',
                dataIndex: 'active',
                width: '20%',
                editable: true,
                align: 'center',
                render: (text, record) => {
                    return (
                        <span>{text ? 'true' : 'false'}</span>
                    )
                }
            },
            {
                title: 'Default',
                dataIndex: 'default',
                width: '10%',
                editable: true,
                align: 'center',
                render: (text, record) => {
                    return (
                        <span>{text ? 'true' : 'false'}</span>
                    )
                }
            },
            {
                title: 'Operation',
                dataIndex: 'operation',
                width: '10%',
                render: (text, record) => {
                    const { editingKey } = this.state
                    const editable = this.isEditing(record)
                    return editable ? (
                        <span className="content-edit-annotate">
            <span>
              <EditableContext.Consumer>
                {(form) => (
                    <button className="save-button"
                            onClick={() => this.save(form, record, record.idTag)}
                            style={{ marginRight: 8 }}
                            disabled={ this.props.statusPendingSaveCustomTag === true}
                    >
                        Save
                    </button>
                )}
              </EditableContext.Consumer>
              <Popconfirm
                  title="Sure to cancel?"
                  onConfirm={() => this.cancel(record.idTag)}
                  overlayClassName={'annotation-table-pop-confirm'}
                  zIndex={1}>
                <button className="cancel-button">Discard</button>
              </Popconfirm>
            </span>
                <span onClick={(e) => this.showConfirmDeleteTag(e, record)} className={ this.state.activeDelete.idTag === record.idTag ? "icon-v19-delete-active" : "icon-v19-delete"}
                      onMouseEnter={(e) => this.onHoverDeleteAnnotation(e, record)}
                      onMouseLeave={() => this.onLeaveDeleteAnnotation()}></span>
                </span>
                    ) : (
                        <span className={ this.state.activeEdit.idTag === record.idTag ? 'icon-v21-edit-active' : "icon-v21-edit" }
                              onMouseEnter={(e) => this.onHoverTableAnnotation(e, record)}
                              onMouseLeave={(e) => this.onLeaveTableAnnotation(e)}
                              disabled={editingKey !== ''} onClick={() => this.edit(record.idTag)}>
            </span>
                    )
                },
            },
        ]
    }
    componentWillReceiveProps = (nextProps) => {
        if (nextProps !== this.state) {
            //this.setState({ initialValue: nextProps.value, value: nextProps.value })
        }
    }

    onHoverDeleteAnnotation = (e, record) => {
        this.setState ( {
            activeDelete : record
        })
    }
    onLeaveDeleteAnnotation = () => {
        this.setState ({
            activeDelete : {}
        })
    }
    onHoverTableAnnotation = (e, record) => {
        this.setState({
            activeEdit: record
        })
    }
    onLeaveTableAnnotation = () => {
        this.setState({
            activeEdit: {}
        })
    }
    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.tagsList !== this.props.tagsList) {
            this.setState({
                data: nextProps.tagsList
            })
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props && this.props.statusAddTags) {
            this.setState({
                data: this.props.tagsList
            })
            this.props.getStatusAddTags(false)
        }
    }

    onDeleteTag = (e, record) => {
        const { data } = this.state
        deleteCustomTagClientAdmin(this.props.clientId, record.idTag).then((response) => {
            let updateData = []
            if (response.status === 200) {
                if (response.data === 'Annotation does not exist!') {
                    updateData = this.state.data && this.state.data.length > 0 && this.state.data.slice(1)
                } else {
                    updateData = this.state.data && this.state.data.length > 0 && this.state.data.filter((list) => list.idTag !== record.idTag)
                }
                this.setState({
                    data: updateData,
                })
                this.props.onDeleteTagAction(updateData)
                this.props.onGetListTagsDocuments()
                if (response.data.title && response.data.title.toLowerCase() === 'success') {
                    CustomNotificationManager.success(response.data.message, 'Success')
                } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
                    CustomNotificationManager.info(response.data.message, 'Info')
                }
            }
        })
        // deleteAnnotation(record.idTag).then((response) => {
        //     let updateData = []
        //     if (response.status === 200) {
        //         if (response.data === 'Annotation was deleted successfully!') {
        //             CustomNotificationManager.success('Annotation was deleted successfully!', 'Success')
        //             this.setState({
        //                 editingKey: ''
        //             })
        //         }
        //         if (response.data === 'Annotation does not exist!') {
        //             updateData = this.state.data && this.state.data.length > 0 && this.state.data.slice(1)
        //         } else {
        //             updateData = this.state.data && this.state.data.length > 0 && this.state.data.filter((list) => list.idTag !== record.idTag)
        //         }
        //         this.setState({
        //             data: updateData,
        //         })
        //         this.props.onDeleteTagAction(updateData)
        //     }
        // })
    }
    showConfirmDeleteTag = (e, record) => {
        customConfirm({
            confirmAction: () => this.onDeleteTag(e, record) ,
            title: '',
            content:
                <React.Fragment>
                    <div className={"delete-dict-title"}>
                        <div className={"icon-del icon-delete-b"}></div>
                        <div className={"delete-dict-q"}>{'Delete selected tag'}</div>
                    </div>
                </React.Fragment>,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            mask: true,
            maskClosable: false,
            closeHaveLogic: false,
            className: 'delete-dict-modal',
            centered: true,
            cancelClassName: 'cancel-button',
            confirmClassName: 'confirm-button'
        })
    }
    isEditing = (record) => record.idTag === this.state.editingKey;

    cancel = () => {
        this.setState({ editingKey: '', activeEdit: {} }, function () {
            this.props.onGetListTagsDocuments()
        })
    };

    onPageChange = (page, pageSize) => {
        this.cancel()

        this.setState({
            pageNumber: page
        })
    }

    save(form, record, key) {
        form.validateFields((error, row) => {
            if (error) {
                if (error.icon) {
                } else if (error.color) {
                } else {
                    return
                }
            }
            record = { ...record, ...row }
            //record.idTag = 0
            if (!record.clientId && !(this.props.user.user_metadata.roles.osprey === 'god_admin')) {
                record.clientId = this.props.clientId
                record.defaultAnnotation = false
                //record.idTag = 0
            }
            const newData = [...this.state.data]
            const index = newData.findIndex((item) => key === item.idTag)
            if (index > -1) {
                if (this.props.user.user_metadata.roles.osprey === 'god_admin') {
                    updateDefaultAnnotation(record).then((response) => {
                        newData.splice(index, 1, response.data)
                        this.setState({ data: newData, editingKey: '' }, () => {
                            this.props.updateParentTags(newData)
                        })
                    }).catch((error) => {
                        if (error?.response?.status === 504) {
                            CustomNotificationManager.cleanBuffer('warning', '')
                        }else{
                            console.log(error)
                        }
                    })
                } else if (record.action && record.action === 'Add') {
                    const addTagDocumentDTO = {
                        tagName: record.tagName,
                        active: record.active,
                        color: record.color,
                        icon: record.icon,
                        clientId: record.clientId,
                    }
                    this.props.setStatusPendingSaveCustomTag(true)
                    saveAddCustomTagClientAdmin(addTagDocumentDTO).then((response) => {
                        newData.splice(index, 1, response.data)
                        if (response.status === 200) {
                            this.props.setStatusPendingSaveCustomTag(false)
                        }
                        if(response.data.title.toLowerCase() === 'success') {
                            CustomNotificationManager.success(response.data.message, 'Success')
                        } else if(response.data.title.toLowerCase() === 'info') {
                            CustomNotificationManager.info(response.data.message, 'Info')
                        }
                        this.setState({ data: newData, editingKey: '', activeEdit: {} }, () => {
                            this.props.updateParentTags(newData)
                            this.props.onGetListTagsDocuments()
                        })
                    }).catch((error) => {
                        if (error?.response?.status === 504) {
                            CustomNotificationManager.cleanBuffer('warning', '')
                        }else{
                            console.log(error)
                        }
                    })
                    // saveAnnotationByClientId(record.clientId, record).then((response) => {
                    //     newData.splice(index, 1, response.data)
                    //     this.setState({ data: newData, editingKey: '' }, () => {
                    //         this.props.updateParentTags(newData)
                    //     })
                    // }).catch((error) => {
                    //     if (error?.response?.status === 504) {
                    //         CustomNotificationManager.cleanBuffer('warning', '')
                    //     }else{
                    //         console.log(error)
                    //     }
                    // })
                } else {
                    const addTagDocumentDTO = {
                        tagName: record.tagName,
                        active: record.active,
                        color: record.color,
                        icon: record.icon,
                        clientId: record.clientId,
                        tagId: record.idTag,
                    }
                    this.props.setStatusPendingSaveCustomTag(true)
                    saveEditCustomTagClientAdmin(addTagDocumentDTO).then((response) => {
                        newData.splice(index, 1, response.data)
                        if (response.status === 200) {
                            this.props.setStatusPendingSaveCustomTag(false)
                        }
                        if(response.data.title.toLowerCase() === 'success') {
                            CustomNotificationManager.success(response.data.message, 'Success')
                        } else if(response.data.title.toLowerCase() === 'info') {
                            CustomNotificationManager.info(response.data.message, 'Info')
                        }
                        this.setState({ data: newData, editingKey: '', activeEdit: {} }, () => {
                            this.props.updateParentTags(newData)
                            this.props.onGetListTagsDocuments()
                        })
                    }).catch((error) => {
                        if (error?.response?.status === 504) {
                            CustomNotificationManager.cleanBuffer('warning', '')
                        }else{
                            console.log(error)
                        }
                    })
                }
            } else {
                newData.push(row)
                this.setState({ data: newData, editingKey: '' })
            }
        })
    }

    edit(key) {
        if (this.state.editingKey && this.state.editingKey !== key) {
            this.setState({ editingKey: '' }, () => {
                this.setState({ editingKey: key })
            })
        } else {
            this.setState({ editingKey: key })
        }
    }

    showTotalPages = (total, range) => {
        const { pageNumber } = this.state
        return (
            <div className="total-elements">
          <span className="pagination__box__header__total">
            <strong>{total}</strong> results
          </span>
                <span className="pagination__box__header__text">
              Page {pageNumber} of {Math.ceil(total / 5)}
          </span>
            </div>
        )
    }

    render() {
        const components = {
            body: {
                cell: EditableCell,
            },
        }
        const columns = this.columns.map((col) => {
            if (!col.editable) {
                return col
            }
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    inputType: col.dataIndex === 'details' ? 'textarea' : 'text',
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: this.isEditing(record),
                }),
            }
        })
        return (
            <EditableContext.Provider value={this.props.form}>
                <Table
                    components={components}
                    dataSource={this.state.data}
                    columns={columns}
                    rowClassName="editable-row"
                    rowKey = { (record) => { return `rowKey${ record.idTag}` }}
                    pagination={ false }
                    // pagination={{
                    //     onChange: this.onPageChange,
                    //     pageSize: 5,
                    //     showTotal: this.showTotalPages,
                    //     className: 'annotation-pagination',
                    //     showLessItems: true
                    // }}
                    className="tags-table"
                />
            </EditableContext.Provider>
        )
    }
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id,
    user: state.userReducer.user
})

TagsTable = connect(mapStateToProps)(TagsTable)
export default TagsTable = Form.create()(TagsTable)