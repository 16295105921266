import React from "react";
import {Input, Modal, Select, Checkbox, Button} from 'antd'
import './NewTicketSettingsModal.scss'
import moment from 'moment'
import {
    apiCreateWorkflowTicket, apiEditWorkflowTicket,
    getExternalApiTokenListId,
    getTicketWorkflowAvailableUserList,
    getWorkflowTemplateList
} from "../../../../../api/SmartBox";
import withRouter from "../../../../../private-routers/withRouter";
import {connect} from "react-redux";
import {setSarPageLimit} from "../../../../../actions/sarActions";
import {DashboardWorkflow} from "./DashboardWorkflow";
import CustomNotificationManager from "../../../../Common/CustomNotificationManager";
const { Option } = Select;
const { TextArea } = Input

class NewTicketSettingsModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            //listOptionTimeFrame: ['30 days', '90 days', 'none'],
            listOptionTimeFrame: [
                {name: '30 days', nameId: 30},
                {name: '90 days', nameId: 90},
                {name: 'none', nameId: 0},
            ],
            hoverIconDate:false,
            listOptionsTicketOwner:[],
            listOptionsWorkflow:[],
            loadingCancelModal:false,
            loadingOpenTicket:false,
            valueSelectTimeFrame: 30,
            valueSelectTicketOwner:'Select',
            valueTicketName:'',
            valueSelectWorkflow:'Select',
            valueFullName:'',
            valueEmail:'',
            valueRequestDetails:'',
            checkedRequestor:false,
            formNewTickets: {
                valueTicketName:'',
                valueSelectWorkflow:'Select',
                valueFullName:'',
                valueEmail:'',
                valueRequestDetails:'',
                checkedRequestor:false,
                valueSelectTimeFrame: 30,
                valueSelectTicketOwner:'Select',
            }
        }
    }

    componentDidMount() {
        this.onGetWorkflowTemplateList()
        this.onGetTicketWorkflowAvailableUserList()
        if (this.props.statusModalNewTickets === 'edit') {
            if (Object.keys(this.props.selectEditTickets) && Object.keys(this.props.selectEditTickets).length > 0) {
                this.setState({
                    valueSelectTicketOwner: this.props.selectEditTickets.ownerId,
                    valueTicketName: this.props.selectEditTickets.ticketName,
                    valueSelectWorkflow: this.props.selectEditTickets.workflowId,
                    checkedRequestor: this.props.selectEditTickets.ticketRequestorActive,
                    valueFullName: this.props.selectEditTickets.ticketRequestorName,
                    valueEmail: this.props.selectEditTickets.ticketRequestorEmail,
                    valueRequestDetails: this.props.selectEditTickets.ticketRequestorDetails,
                    valueSelectTimeFrame: this.props.selectEditTickets.ticketTimeFrame,
                    ticketId: this.props.selectEditTickets.ticketId,
                    formNewTickets: {...this.state.formNewTickets,
                        valueSelectTicketOwner: this.props.selectEditTickets.ownerId,
                        valueTicketName: this.props.selectEditTickets.ticketName,
                        valueSelectWorkflow: this.props.selectEditTickets.workflowId,
                        checkedRequestor: this.props.selectEditTickets.ticketRequestorActive,
                        valueFullName: this.props.selectEditTickets.ticketRequestorName,
                        valueEmail: this.props.selectEditTickets.ticketRequestorEmail,
                        valueRequestDetails: this.props.selectEditTickets.ticketRequestorDetails,
                        valueSelectTimeFrame: this.props.selectEditTickets.ticketTimeFrame,
                        ticketId: this.props.selectEditTickets.ticketId,
                    }
                })
            }
        }
    }
    onGetWorkflowTemplateList = () => {
       getWorkflowTemplateList(this.props.clientId).then((response) => {
          this.setState({
              listOptionsWorkflow: response.data
          })
       })
   }

   onGetTicketWorkflowAvailableUserList = () => {
       getTicketWorkflowAvailableUserList().then((response) => {
           this.setState({
               listOptionsTicketOwner: response.data
           })
       })
   }
    handleCloseModal = () => {
        if (this.props.onCloseNewTickets) {
            this.props.onCloseNewTickets(false)
        }
        if (this.props.displayTicketCreationForm) {
            this.props.displayTicketCreationForm(false)
        }
    }

    handleCancelModal = () => {
        if (this.props.onCloseNewTickets) {
            this.props.onCloseNewTickets(false)
        }
        this.setState({
            loadingCancelModal: true,
        }, () => {
            if (this.props.displayTicketCreationForm) {
                this.props.displayTicketCreationForm(false)
            }
            this.setState({
                loadingCancelModal: false
            })
        })

    }

    onHoverIconDate = () => {
        this.setState({
            hoverIconDate:true,
        })
    }
    onLeaveIconDate = () => {
        this.setState({
            hoverIconDate:false,
        })
    }

    onChangeSelect = (e, arg) => {
        switch (arg) {
            case 'timeFrame' :
                this.setState({
                    valueSelectTimeFrame: e,
                    formNewTickets: {...this.state.formNewTickets, valueSelectTimeFrame: e}
                })
                break
            case 'ticketOwner':
                this.setState({
                    valueSelectTicketOwner: e,
                    formNewTickets: {...this.state.formNewTickets, valueSelectTicketOwner: e}
                })
                break
            case 'workflow' :
                this.setState({
                    valueSelectWorkflow: e,
                    formNewTickets: {...this.state.formNewTickets, valueSelectWorkflow: e}
                })
                break
            default:
                break
        }
    }

    onChangeInput = (e, arg,) => {
        switch (arg) {
            case 'ticketName':
                this.setState({
                    valueTicketName: e.target.value,
                    formNewTickets: {...this.state.formNewTickets, valueTicketName: e.target.value}
                })
                break
            case 'fullName':
                this.setState({
                    valueFullName: e.target.value,
                    formNewTickets: {...this.state.formNewTickets, valueFullName: e.target.value}
                })
                break
            case 'email':
                this.setState({
                    valueEmail: e.target.value,
                    formNewTickets: {...this.state.formNewTickets, valueEmail: e.target.value}
                })
                break
            case 'details':
                this.setState({
                    valueRequestDetails: e.target.value,
                    formNewTickets: {...this.state.formNewTickets, valueRequestDetails: e.target.value}
                })
                break
            default:
                break
        }
    }

    toggleChecked = (e) => {
        this.setState({
            checkedRequestor: e.target.checked
        })
    }

    onOpenWorkflowTicket = () => {
        const { statusModalNewTickets } = this.props
        const { formNewTickets } = this.state
        if (statusModalNewTickets === 'new') {
            const createWorkflowTicketDTO = {
                ticketName: formNewTickets.valueTicketName,
                ticketRequestorActive: formNewTickets.checkedRequestor,
                ticketTimeFrame: formNewTickets.valueSelectTimeFrame,
                ownerId: formNewTickets.valueSelectTicketOwner,
                workflowId: formNewTickets.valueSelectWorkflow,
                ticketRequestorName: formNewTickets.valueFullName,
                ticketRequestorEmail: formNewTickets.valueEmail,
                ticketRequestorDetails: formNewTickets.valueRequestDetails,
            }
            apiCreateWorkflowTicket(createWorkflowTicketDTO).then((response) => {
                if (this.props.status === 'add') {
                    this.props.onGetTicketWorkflowListDashboard()
                    this.props.onCloseNewTickets(false)
                }
                if (response.data.title && response.data.title.toLowerCase() === 'success') {
                    CustomNotificationManager.success(response.data.message, 'Success')
                } else if (response.data.title && response.data.title.toLowerCase() === 'info') {
                    CustomNotificationManager.info(response.data.message, 'Info')
                }
            })
        } else if (statusModalNewTickets === 'edit') {
            const createWorkflowTicketDTO = {
                ticketId: this.props.selectEditTickets.ticketId,
                ticketName: formNewTickets.valueTicketName,
                ticketRequestorActive: formNewTickets.checkedRequestor,
                ticketTimeFrame: formNewTickets.valueSelectTimeFrame,
                ownerId: formNewTickets.valueSelectTicketOwner,
                workflowId: formNewTickets.valueSelectWorkflow,
                ticketRequestorName: formNewTickets.valueFullName,
                ticketRequestorEmail: formNewTickets.valueEmail,
                ticketRequestorDetails: formNewTickets.valueRequestDetails,
            }
            apiEditWorkflowTicket(createWorkflowTicketDTO).then((response) => {
                if (this.props.status === 'add') {
                    this.props.onGetTicketWorkflowListDashboard()
                    this.props.onCloseNewTickets(false)
                }
                if (response.data.title && response.data.title.toLowerCase() === 'success') {
                    CustomNotificationManager.success(response.data.message, 'Success')
                } else if (response.data.title && response.data.title.toLowerCase() === 'info') {
                    CustomNotificationManager.info(response.data.message, 'Info')
                }
            })
        }
    }
    render () {
        const {listOptionTimeFrame, hoverIconDate, listOptionsTicketOwner, listOptionsWorkflow, loadingCancelModal, loadingOpenTicket, valueSelectTicketOwner,
            valueTicketName, valueSelectWorkflow, valueFullName, valueEmail, valueRequestDetails, checkedRequestor, valueSelectTimeFrame,formNewTickets} = this.state
        const { statusModalNewTickets } = this.props
        const date = moment().format('DD.MM.YYYY')
        return (
            <Modal className='ticket-log-modal'
                   title={ <span className='header-content-ticket-log-modal'>
                       <span>Ticket log</span>
                       { <span className="icon-v14-close-black" onClick={() => this.handleCloseModal()}></span> }
                   </span>}
                   visible={true}
                   footer={null}
                   closable={false }
            >
                <div className='ticket-log-modal-body-content-headline'>
                    <div className='date-request'>
                        <span className='modal-content-headlines'>Opening: </span>
                        <span className='modal-content-body-data'>{date}</span>
                        <span className={ hoverIconDate ? 'icon-v211-date-active':'icon-v211-date'}
                              onMouseEnter={()=>this.onHoverIconDate()}
                              onMouseLeave={() => this.onLeaveIconDate()}
                        ></span>
                    </div>
                    <div className='process-time'>
                        <span className='modal-content-headlines update'>Time frame:</span>
                        <Select
                            value={valueSelectTimeFrame}
                            onChange={(e) => this.onChangeSelect(e, 'timeFrame')}
                            dropdownClassName={'select-per-page-tickets'}
                        >
                            { listOptionTimeFrame && listOptionTimeFrame.length > 0 && listOptionTimeFrame.map((item, index) => {
                                return (<Option className="options-select-connector" value={item.nameId} key={index}>
                                    {item.name}
                                </Option>)
                            })}
                        </Select>
                    </div>
                </div>
                <div className='wrapper-form-fields'>
                    <span className='modal-content-headlines'>Ticket owner*</span>
                    <Select
                        value={formNewTickets.valueSelectTicketOwner}
                        placeholder="Select"
                        className='select-form-fields'
                        dropdownStyle={{border:'1px solid #eda41e'}}
                        onChange={(e) => this.onChangeSelect(e, 'ticketOwner')}
                    >
                        { listOptionsTicketOwner && listOptionsTicketOwner.length > 0 && listOptionsTicketOwner.map((item, index) => {
                            return (<Option className="options-select-connector" value={item.value} key={index}>
                                {item.name ? `${item.name} | ${item.label}` : item.label}
                            </Option>)
                        })}
                    </Select>
                </div>
                <div className='wrapper-form-fields'>
                    <span className='modal-content-headlines'>Ticket name*</span>
                    <Input
                        className='text-form-fields'
                        value={valueTicketName}
                        onChange={(e) => this.onChangeInput(e, 'ticketName')}
                    />
                </div>
                <div className='wrapper-form-fields'>
                    <span className='modal-content-headlines'>Workflow*</span>
                    <Select
                        value={valueSelectWorkflow}
                        placeholder="Select"
                        className='select-form-fields'
                        dropdownStyle={{border:'1px solid #eda41e'}}
                        onChange={(e) => this.onChangeSelect(e, 'workflow')}
                    >
                        { listOptionsWorkflow && listOptionsWorkflow.length > 0 && listOptionsWorkflow.map((item, index) => {
                            return (<Option className="options-select-connector" value={item.workflowId} key={index}>
                                {item.workflowName}
                            </Option>)
                        })}
                    </Select>
                </div>
                <div className='wrapper-form-fields wrapper-form-fields-with-checkbox'>
                    <span className="header-checkbox">
                        <Checkbox
                            checked={checkedRequestor}
                            onChange={(e) => this.toggleChecked(e)}
                            className='modal-content-headlines'>
                            Requestor details
                        </Checkbox>
                        </span>
                </div>
                <div className='wrapper-form-fields'>
                    <span className={checkedRequestor === false ? 'modal-content-headlines modal-content-headlines-disabled' : 'modal-content-headlines' }>Full name*</span>
                    <Input
                        className='text-form-fields'
                        value={valueFullName}
                        disabled={ checkedRequestor === false}
                        onChange={(e) => this.onChangeInput(e, 'fullName')}
                    />
                </div>
                <div className='wrapper-form-fields'>
                    <span className={checkedRequestor === false ? 'modal-content-headlines modal-content-headlines-disabled' : 'modal-content-headlines' }>Email address*</span>
                    <Input className='text-form-fields'
                           disabled={ checkedRequestor === false}
                           value={valueEmail}
                           onChange={(e) => this.onChangeInput(e,'email')}
                    />
                </div>
                <div className='wrapper-form-fields'>
                    <span className={checkedRequestor === false ? 'modal-content-headlines modal-content-headlines-disabled' : 'modal-content-headlines' }>Request details*</span>
                    <TextArea
                        className='request-details'
                        rows={4}
                        disabled={ checkedRequestor === false}
                        value={valueRequestDetails}
                        onChange={(e) => this.onChangeInput(e, 'details') }
                    />
                </div>
                <div className='ticket-log-modal-footer'>
                    <Button
                        onClick={() => this.handleCancelModal()}
                        loading={loadingCancelModal}
                        className={loadingCancelModal ? 'button-cancel-action loading' : 'button-cancel-action'}>Cancel
                    </Button>
                    <Button
                        disabled={valueSelectTicketOwner === '' || valueTicketName === '' || valueSelectWorkflow === ''}
                        loading={loadingOpenTicket}
                        onClick={ () => this.onOpenWorkflowTicket() }
                        className={loadingOpenTicket ? 'button-open-ticket-action button-loading' :'button-open-ticket-action'}>
                        {statusModalNewTickets === 'edit' ? 'Okay' : 'Open'}</Button>
                </div>
            </Modal>
        )
    }
}

NewTicketSettingsModal = withRouter(NewTicketSettingsModal)

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id,
    sarPageLimit: state.sar.sarPageLimit
})

export default connect(mapStateToProps,{
})(NewTicketSettingsModal)