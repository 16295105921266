import { sortValuesByField } from '../utils'

const typeMapping = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB']

export const mappedCrawlers = (response) => {
  if (!response.data) return []
  if (!response.data.value) return []
  if (!response.data.value.crawlers) return []
  if (!response.data.value.crawlers.crawler) return []
  
  const connectors = response.data.value.crawlers.crawler.map((connector) => ({
    ...connector,
    isFetchingInfo: true,
    isFetchingStats: true
  }))
  return sortValuesByField(connectors, 'name')
}

export const mappedConnectorWithInformation = (connectors, id, response) => {
  return connectors.map((connector) => {
    let docSize = 0
    let filesCounter = 0
    
    if (response.data
      && response.data.value
      && response.data.value.stats
      && response.data.value.stats.field
      && response.data.value.stats.field.value) {
      
      if (response.data.value.stats.field.value.nonpivotstats
        && response.data.value.stats.field.value.nonpivotstats.doc_size
        && response.data.value.stats.field.value.nonpivotstats.doc_size.sum) {
        docSize = response.data.value.stats.field.value.nonpivotstats.doc_size.sum
      }
      
      if (response.data.value.stats.field.value.count) {
        filesCounter = response.data.value.stats.field.value.count
      }
    }
    
    if (connector.id === id) return {
      ...connector,
      docSize,
      isFetchingInfo: false,
      filesCounter
    }
    
    return {
      ...connector,
      docSize: connector.docSize || 0,
      filesCounter: connector.filesCounter || 0
    }
  })
}

export const mappedConnectorWithStatus = (connectors, id, response) => {
  return connectors.map((connector) => {
    let isReachable = false
    let isRunning = false
    let found = 0
    let sent = 0
    let speed = 0
    let maxspeed = 0
    
    if (connector.id === id)
    {
      if (response.data.engineStatus.serviceCode === 100)
      {
        isReachable = false
      }
      else
      {
        isReachable = true
        if (response.data.engineStatus.processCodes[connector.id] === 200)
        {
          isRunning = true
          found = response.data.engineStatus.processData[connector.id].status.processors.stack[0].progresswindows.totalSoFar
          
          const finalStack = response.data.engineStatus.processData[connector.id].status.processors.stack.length-1
          sent = response.data.engineStatus.processData[connector.id].status.processors.stack[finalStack].progresswindows.totalSoFar
          
          const finalWindow = response.data.engineStatus.processData[connector.id].status.processors.stack[finalStack].progresswindows.window.length-1
          speed = response.data.engineStatus.processData[connector.id].status.processors.stack[finalStack].progresswindows.window[finalWindow].Success
          
          maxspeed = getMaxValueSuccessWindow(response.data.engineStatus.processData[connector.id].status.processors.stack[finalStack].progresswindows.window)
        }
        else
        {
          isRunning = false
        }
      }
    }
    else
    {
      return {
        ...connector,
        isReachable: connector.isReachable,
        isRunning: connector.isRunning,
        found: connector.found,
        sent: connector.sent,
        speed: connector.speed,
        maxspeed: connector.maxspeed,
        isFetchingStats: false
      }
    }
    return {
      ...connector,
      isReachable: isReachable,
      isRunning: isRunning,
      found: found,
      sent: sent,
      speed: speed,
      maxspeed: maxspeed,
      isFetchingStats: false
    }
  })
}

export const mappedConnectorWithJustStartedStatus = (connectors, id) => {
  return connectors.map((connector) => {
    if (connector.id === id)
    {
      return {
        ...connector,
        isReachable: true,
        isRunning: true,
        found: 0,
        sent: 0,
        speed: 0,
        maxspeed: 0,
        isFetchingStats: false
      }
    }
    
    return {
      ...connector,
      isReachable: connector.isReachable,
      isRunning: connector.isRunning,
      found: connector.found,
      sent: connector.sent,
      speed: connector.speed,
      maxspeed: connector.maxspeed,
      isFetchingStats: false
    }
  })
}

export const mappedConnectorWithJustStoppedStatus = (connectors, id) => {
  return connectors.map((connector) => {
    if (connector.id === id)
    {
      return {
        ...connector,
        isReachable: true,
        isRunning: false,
        found: 0,
        sent: 0,
        speed: 0,
        maxspeed: 0,
        isFetchingStats: false
      }
    }
    
    return {
      ...connector,
      isReachable: connector.isReachable,
      isRunning: connector.isRunning,
      found: connector.found,
      sent: connector.sent,
      speed: connector.speed,
      maxspeed: connector.maxspeed,
      isFetchingStats: false
    }
  })
}

export const getMaxValueSuccessWindow = (arr) => {
  return arr.reduce((max, p) => p.Success > max ? p.Success : max, arr[0].Success)
}

export const mountCrawlerConfig = (name, location) => {
  return {
    'pipeline': [
      {
        'properties': {
          'include_mimetype': {
            'propertyName': 'include_mimetype',
            'values': [
              'false'
            ]
          },
          'include_content': {
            'propertyName': 'include_content',
            'values': [
              'false'
            ]
          },
          'description': {
            'propertyName': 'description',
            'values': [
              name
            ]
          },
          'start_location': {
            'propertyName': 'start_location',
            'values': [
              location
            ]
          },
          'include_metadata': {
            'propertyName': 'include_metadata',
            'values': [
              'false'
            ]
          },
          'include_checksum': {
            'propertyName': 'include_checksum',
            'values': [
              'false'
            ]
          },
          'include_attachments': {
            'propertyName': 'include_attachments',
            'values': [
              'false'
            ]
          },
          'crawlername': {
            'propertyName': 'crawlername',
            'values': [
              name
            ]
          }
        },
        'id': '1000'
      },
      {
        'properties': {
        },
        'id': '2000'
      },
      {
        'properties': {
        },
        'id': '3000'
      },
      {
        'properties': {
          'doc_datasource_type': {
            'propertyName': 'doc_datasource_type',
            'values': [
              'File System'
            ]
          },
          'doc_datasource_name': {
            'propertyName': 'doc_datasource_name',
            'values': [
              name
            ]
          }
        },
        'id': '3003'
      },
      {
        'properties': {
        },
        'id': '4000'
      }
    ],
    'baseConfig': {
      'properties': {
        'name': {
          'propertyName': 'name',
          'values': [
            name
          ]
        }
      }
    }
  }
}

export const calculateStorageString = (dataSize) => {
  let typeMap = 0
  let typeVal = 'PB'
  let value = dataSize
  while (value > 1024 && typeMap < 6)
  {
    value = Math.round(value/1024)
    typeMap++
  }
  
  typeVal = typeMapping[typeMap]
  
  return `${ value } ${ typeVal }`
}

export const findConnector = (connectors, id) => {
  const connector = connectors.filter((connector) => connector.id === id)
  return connector.length > 0 ? connector[0] : null
}