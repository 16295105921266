import React from 'react'
import { Route, Navigate, Outlet } from 'react-router-dom'
import { connect } from 'react-redux'
import CustomNotificationManager from '../components/Common/CustomNotificationManager'

const AssetRegisterPrivateRouter = ({ component: Component, ...rest }) => {
    if (rest.isLoggedIn) {
      const found = rest.assetRegisterVisible
      if (found) {
        return <Outlet {...rest}/>
      }
      // CustomNotificationManager.info('Access restricted', 'Unauthorized')
      return <Navigate to="/smart-box/management"/>
    }
    return <Navigate to="/osp/login"/>
  }


const mapStateToProps = (state) => ({
  isLoggedIn: state.userReducer.isLogged,
  assetRegisterVisible: state.applications.assetRegisterVisible
})

export default connect(mapStateToProps, {})(AssetRegisterPrivateRouter)