import PropTypes from 'prop-types'
import React, { Component } from 'react'
import iconPDF from '../../../assets/icon-pdf.png'
import { getScreenSize } from '../../../utils'
import Button from '../../Common/Button'
import './DashboardOld.scss'
import BoxAvailable from '../../Osp/Dashboard/BoxAvailable.js'
import BoxComing from '../../Osp/Dashboard/BoxComing.js'
import BoxOspreyBuilder from '../../Osp/Dashboard/BoxOspreyBuilder.js'
import BoxOspreyDpia from '../../Osp/Dashboard/BoxOspreyDpia.js'
import withRouter from '../../../private-routers/withRouter'

export class DashboardOld extends Component {
  constructor() {
    super()
    this.navigateToDpiaDashboard = this.navigateToDpiaDashboard.bind(this)
  }
  
  openPdf(url) {
    if (getScreenSize() < 768) {
      window.open(url, '_blank')
    }
  }

  navigateToDpiaDashboard() {
    this.props.navigate('/dpia/dashboard')
  }

  render() {
    const items = [{
      title: 'Read an executive summary for CEOs',
      description: 'A practical outline of activities & measures to be undertaken at board level. Including corporate governance & due diligence during mergers and acquisitions (M&A).',
      url: 'http://resources.ospreysmart.com/Gravicus_whitepaper_GDPR_An_Executive_Summary_for_CEOs.pdf'
    }, {
      title: 'GDPR basics – what you need to know',
      description: 'Frequently asked questions and facts on GDPR for organisations that take data protection seriously and want to be compliant after the May 2018 deadline.',
      url: 'http://resources.ospreysmart.com/Gravicus_whitepaper_GDPR_What_you_need_to_know.pdf'
    }, {
      title: 'GDPR review for small businesses',
      description: 'An easy to understand practical paper aimed at small businesses to help get them GDPR ready managing their own companies data/privacy.',
      url: 'http://resources.ospreysmart.com/Gravicus_whitepaper_GDPR_for_small_businesses.pdf'
    }]
    return (
      <div className="ospDashboard-page">
        <div className="ospDashboard-boxWelcome">
          <p className="ospDashboard-boxText">Thank you for signing-up to Osprey, a growing platform of data management tools that starts with the launch of Osprey Assessments – the first step to compliance for GDPR and beyond. Use this simple and smart tool to gain insights into how your organisation collects, stores, uses, transmits, shares and protects personal information.</p>
        </div>

        <h3 className="ospDashboard__title">Live Osprey cloud applications</h3>

        <div className="ospDashboard__box--split">
          <BoxOspreyDpia />
          <BoxOspreyBuilder />
        </div>

        <h3 className="ospDashboard__title">Osprey onsite installations</h3>

        <div className="ospDashboard__box--split">
          <BoxAvailable type="privacy">
            <p><strong>Osprey Privacy</strong> uses advanced AI and data management capabilities, making it simple for governance and compliance departments to highlight Personally Identifiable Information and produce detailed risk reports.</p>
          </BoxAvailable>

          <BoxAvailable type="mailroom">
            <p><strong>Osprey Mailroom</strong> helps our customers identify PII risk in mail as soon as it comes into the company, so employees can be easily reminded to treat it with care. Osprey's on-the-fly analytics makes seamless integration with existing solutions like Kofax easy.</p>
          </BoxAvailable>
        </div>

        <h3 className="ospDashboard__title">Osprey apps coming soon</h3>

        <div className="ospDashboard__box--split">
          <BoxComing type="subject">
            <p><strong>Osprey Subject Access Request</strong> allows organisations to demonstrate compliance as well as ensure record keeping of Subject Access Requests, be they from customers, employees or other third parties associated with their organisation.</p>
          </BoxComing>

          <BoxComing type="clean">
            <p><strong>Osprey Clean-Up</strong> provides tools for IT departments to analyse and organise Redundant, Obsolete and Trivial (ROT) data and act upon it by either archiving off to less expensive locations or simply deleting it.</p>
          </BoxComing>

          <BoxComing type="records">
            <p><strong>Osprey Records</strong> helps organisations ensure Record Management policies are executed across the organisation. Records Managers use Osprey tools to create highly accurate, dynamic and trainable taxonomies without requiring deep technical knowledge.</p>
          </BoxComing>

          <BoxComing type="migration">
            <p><strong>Osprey Migration</strong> reduces the cost and increases the quality of data migrations. Automatically clean, enrich and organise your data while moving it, keeping the process easy and ensuring that your new repository ends up clean and easy to use.</p>
          </BoxComing>

        </div>

        <h3 className="ospDashboard__title">Osprey Resource Centre</h3>

        <div className="ospDashboard__resources">
          <header className="ospDashboard__resources--header">
            <h2 className="ospDashboard__resources--title">Popular white papers</h2>
            <a href="https://insights.gravicus.com/blog" rel="noopener noreferrer" target="_blank"><strong>Visit our blog @ gravicus.com &#8250;</strong></a>
          </header>
          <div className="ospDashboard__resources--body">
            { items.map((item) => (
              <div
                className="ospDashboard-whitepaper"
                key={item.title}
                onClick={ this.openPdf.bind(this, item.url) }>
                <div className="ospDashboard-content">
                  <img className="ospDashboard-iconPdf" src={ iconPDF } alt="Icon for pdf" />
                  <div className="ospDashboard-wrapper">
                    <div className="ospDashboard-whitepaperInfo">
                      <h1 className="ospDashboard-whitepaperTitle">{ item.title }</h1>
                      <p className="ospDashboard-whitepaperDescription">{ item.description }</p>
                    </div>
                    <a className="ospDashboard-whitepaperLink" href={ item.url }>
                      <div className="ospDashboard-whitepaperButton">
                        <Button>Read paper</Button>
                      </div>
                      <span className="ospDashboard-whitepaperLinkOnMobile"></span>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(DashboardOld)