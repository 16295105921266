import axios from 'axios'

export const apiOspreyChangeCategoryParent = (host, categoryID, parentID) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/categoryChangeParent`,
    data: {
      sessionID: token,
      categoryID,
      parentID
    }
  })
}

export const apiOspreyDeleteCategory = (host, id) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/deleteCategory`,
    data: {
      sessionID: token,
      categoryID: id
    }
  })
}

export const apiOspreyRenameCategory = (host, id, name) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/renameCategory`,
    data: {
      sessionID: token,
      categoryID: id,
      categoryName: name
    }
  })
}

export const apiOspreySaveCategory = (host, query, filterQueries, id) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/setCategoryQuery`,
    data: {
      sessionID: token,
      categoryID: id,
      query,
      filterQueries
    }
  })
}

export const apiOspreyTrainCategory = (host, id) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/trainCategory`,
    data: {
      sessionID: token,
      categoryID: id
    }
  })
}

export const apiOspreyCategoryGetProcessData = (host, id, processID) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/categoryGetProcessData`,
    data: {
      sessionID: token,
      categoryID: id,
      processID: processID
    }
  })
}

export const apiOspreySetCategoryProperty = (host, categoryID, propertyName, propertyValue) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/setCategoryProperty`,
    data: {
      sessionID: token,
      categoryID,
      propertyName,
      propertyValue
    }
  })
}

export const apiOspreyGetCategoryChildren = (host, parent) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/getCategoryChildren`,
    data: {
      sessionID: token,
      categoryID: parent
    }
  })
}
  
export const apiOspreyGetCategory = (host, categoryID) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/getCategory`,
    data: {
      sessionID: token,
      categoryID: categoryID
    }
  })
}

//taxonomyTypes:[100,101,102,103]
export const apiOspreyGetDataModelTaxonomies = (host, datamodel, taxonomyTypes) => {
  const token = sessionStorage.getItem('gravicus_auth_token')
  console.log(host)
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/getDataModelTaxonomies`,
    data: {
      sessionID: token,
      datamodel: datamodel,
      taxonomyTypes: taxonomyTypes
    }
  })
}

export const apiOspreyGetDocumentsForCategory = (host, categoryID, offset, length, fieldNames, filterQueries, sortField, ascending, flatten) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/getDocumentsForCategory`,
    data: {
      sessionID: token,
      categoryID: categoryID,
      offset: offset,
      length: length,
      fieldNames: fieldNames,
      filterQueries: filterQueries,
      sortfield: sortField,
      ascending: ascending,
      flatten: flatten
    }
  })
}

export const apiOspreyGetDocumentForCategory = (host, categoryID, documentID, query) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/getDocumentForCategory`,
    data: {
      sessionID: token,
      categoryID: categoryID,
      documentID: documentID,
      query: query
    }
  })
}

export const apiOspreyCreateCategory = (host, parentID, name) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/addCategory`,
    data: {
      sessionID: token,
      parentID: parentID,
      categoryName: name
    }
  })
}

export const apiOspreyRenameTaxonomy = (host, taxonomyName, taxonomyNewName) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/renameTaxonomy`,
    data: {
      sessionID: token,
      taxonomyName: taxonomyName,
      taxonomyNewName: taxonomyNewName
    }
  })
}

export const apiOspreyCategoryCluster = (host, categoryID, numClusters) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/categoryCluster`,
    data: {
      sessionID: token,
      categoryID: categoryID,
      numClusters: numClusters
    }
  })
}

export const apiOspreyRemoveDocumentFromCategoryID = (host, categoryID, documentID) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/removeDocumentFromCategoryID`,
    data: {
      sessionID: token,
      categoryID: categoryID,
      documentID: documentID
    }
  })
}

export const apiOspreyAddDocumentToCategoryID = (host, categoryID, documentID) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/addDocumentToCategoryID`,
    data: {
      sessionID: token,
      categoryID: categoryID,
      documentID: documentID
    }
  })
}
