import React from 'react'
import PropTypes from 'prop-types'

import Loading from '../../Common/Loading'

import WithDataModels from '../HOC/WithDataModels'

import './AccessControl.css'

class AccessControl extends React.Component {
  state = { accessControl: [] }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.facetValues !== this.props.facetValues) this.updateAccessControl()
  }

  updateAccessControl = () => {
    const accessControl = this.props.facetValues
      .filter((facet) => facet.name === 'doc_security_group_permitted')
      .map((facet) => {
        const value = facet.values.value
        return { ...value, isChecked: isChecked(this.props.values, value) }
      })
    this.setState({ accessControl })
  }

  onChange = (index) => {
    const newAccess = [...this.state.accessControl]
    newAccess[index] = { ...newAccess[index], isChecked: !newAccess[index].isChecked }
    this.setState({
      accessControl: newAccess
    }, () => this.props.selectedAccessControl(this.state.accessControl))
  }
  
  render = () => {
    if (this.state.accessControl.length === 0) return (
      <Loading />
    )

    return (
      <React.Fragment>
        { this.state.accessControl.map((access, index) => (
          <div
            key={ `access-${ index }` }
            className="accessControl__group">
            <div className="accessControl__checkbox">
              <input
                checked={ isChecked(this.props.values, access) }
                id={ `access-${ index }` }
                type="checkbox"
                value=""
                onChange={ this.onChange.bind(this, index) } />
              <label
                className={ isChecked(this.props.values, access) ? 'accessControl__label--checked' : 'accessControl__label' }
                htmlFor={ `access-${ index }` }></label>
            </div>
            <span>{ renderName(access.name) } ({ access.count })</span>
          </div>
        ))}
      </React.Fragment>
    )
  }
}

AccessControl.propTypes = {
  selectedAccessControl: PropTypes.func.isRequired,
  values: PropTypes.array.isRequired
}

const isChecked = (values, access) => {
  const found = values.find((value) => value.name === access.name)
  if (!found) return false
  
  return found.isChecked
}

const renderName = (name) => {
  if (name === 'GRAV_EVERYBODY') return 'Unsecured'
  return name
}

export default WithDataModels(AccessControl)