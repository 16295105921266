import React from 'react'
import { Tabs } from 'antd'
import './Settings.scss'
import AnnotationColor from './Annotation/AnnotationColor'
import AnnotationManagement from './Annotation/AnnotationManagement'
import { TemplateEmail } from './Email/TemplateEmail'
import cookie from 'react-cookies'

class Settings extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      activeTab: 'template'
    }
  }

  componentDidMount() {
    this.getActiveTab()
  }

  getActiveTab = () => {
    const activeTabCookie = cookie.load('activeTab', { path: './' })
    if (activeTabCookie) {
      this.setState({ activeTab: activeTabCookie })
    }
  }

  changeTabHandler = (e) => {
    const activeTabCookieAlreadyExist = cookie.load('activeTab', { path: './' })
    if (activeTabCookieAlreadyExist) {
      cookie.remove('activeTab', { path: './' })
    }
    cookie.save('activeTab', e, { path: './' })
    this.setState({ activeTab: e })
  }

  render() {
    const { activeTab } = this.state
    const { TabPane } = Tabs
    return (
      <div className='sar-settings-page'>
        <div className='sar-settings-page__wrapper'>
          <div className='sar-settings-page__wrapper__insight'>
            <Tabs
              className='sar-settings-tabs'
              activeKey= { activeTab }
              onChange= {(e) => (this.changeTabHandler(e))}
            >
              <TabPane tab="Email templates" key="template">
                <TemplateEmail/>
              </TabPane>
              <TabPane tab="Annotation Management" key="management">
                <AnnotationManagement/>
              </TabPane>
              <TabPane tab="Annotation Colours" key="colours">
                <AnnotationColor/>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    )
  }
}

export default Settings
