import React from 'react'
import PropTypes from 'prop-types'

import './Tabs.css'

class Tab extends React.Component {

  componentDidMount = () => {

  }

  render = () => {

    const isSelected = this.props.isSelected
    const tabNum = this.props.tabNum
    const tabName = this.props.tabName

    return (
      <li  
        className={ isSelected ? 'active' : 'inactive' } 
        onClick={ this.props.clickTab.bind(this, tabNum) }>{tabName}</li>
    )
  }
}

Tab.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  clickTab: PropTypes.func.isRequired,
  tabName: PropTypes.string.isRequired,
  tabNum: PropTypes.number.isRequired
}

export default Tab