import React from 'react'
import './AssetRegisterComponents.scss'

const LocationInput = (props) => {
  return (
    <div className="location-input">
      <input type="text" placeholder="E.g. “E4 9RT’ or “47 Richmon Crescent”" className="location-input__input" readOnly/>
      <span className="icon-untitled-1" />
    </div>
  )
}

export default LocationInput