import axios from 'axios'
import { objectToFormData } from '../utils/FormDataBuilder'

const isLocalhost = () => {
  return window.location.hostname === 'localhost'
}
const host = isLocalhost() ? 'http://localhost:8080' : ''

// const isRedactLocalhost = () => {
//   return window.location.hostname === 'localhost'
// }
const hostRedact = isLocalhost() ? 'http://localhost:8082' : ''

export const uploadDirectories = (data, config) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/multiple-upload`,
    data,
  })
}

export const getSmartBoxFilesFilter = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/search`,
    data
  })
}

export const getSmartBoxFilesFilterForSearch = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/search-content`,
    data
  })
}

export const createSmartDocument = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/create-directory`,
    data
  })
}

export const getSmartBoxFileById = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/search/${ id }`,
  })
}

export const getFolderChildren = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/child-list`,
    data
  })
}

export const deleteSmartDocument = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/delete`,
    data
  })
}

export const detailedTreeSettings = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/detailed-tree-settings`,
    data
  })
}

export const detailedTreeSettingsAll = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/detailed-tree-settings-all`,
    data
  })
}

export const getSuggestionsForAutocomplete = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/type-head-autocomplete`,
    data
  })
}


export const apiSmartBoxPreviewSarDocByThumbnailId = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/by-thumbnail/${ id }`,
  })
}

export const apiUnlockSmartviewDocument = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/unlock-smartview-document/${ id }`,
  })
}

export const apiUpdateLockStatusSmartviewDocument = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/update-lock-status-smartview-document/${ id }`,
  })
}

export const apiSmartBoxPreviewSarDocFinalizedByThumbnailId = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/finalised-document/${ id }`,
  })
}

export const apiSmartBoxGetAllCanvasPoints = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/pdf/canvas/${ id }`,
  })
}

export const apiSmartBoxGetNewQueryStatus = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/solr/query-status`,
    data
  })
}

export const apiSmartBoxGetPreviewRedactingSarDocByThumbnailId = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/pdf-page/${ id }`,
  })
}

export const apiSmartBoxGetAnnotationsByClientId = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/pdf/annotations/${ id }`,
  })
}

export const getSmartBoxScanAnnotations = (docId) => {

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/solr/query`,
    data: {
      datamodel: 'documents',
      documentID: docId,
      query: '',
      //solrIp: '18.157.167.245'
    }
  })
}

export const apiSmartBoxUpdateCanvasPoints = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/pdf/canvas`,
    data: data
  })
}
export const apiSmartBoxUpdateCanvasPointsSaved = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/pdf/save-canvas-button`,
    data: data
  })
}

export const apiSmartBoxRunAnalytic = (idfolder) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/run-analytics/${ idfolder }`
  })
}

export const apiSmartBoxCheckAiFolderStatus = (folderPath) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/folder-has-ai`,
    data: folderPath
  })
}

export const apiSmartBoxCheckPdfFolderStatus = (folderPath) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/folder-converted-to-pdf`,
    data: folderPath
  })
}

export const apiSmartBoxCheckBulkFolderStatus = (folderPath) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/folder-has-bulk`,
    data: folderPath
  })
}

export const getSmartBoxDocNotes = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/doc-notes/${ id }`
  })
}

export const saveSmartBoxDocNotes = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/doc-notes`,
    data
  })
}
export const apiGetSmartBoxMetadata = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/metadata`,
    data: data
  })
}

export const getFolderPath = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/start-bulk`,
    data
  })
}

export const apiGetFolderMetadata = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/metadata-folder-document`,
    data
  })
}

export const getFolderSettingsRedactingTree = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/tree-for-settings`,
    data
  })
}

export const apiSmartBoxSaveRegularExpression = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/regex-folder`,
    data: data
  })
}

export const apiSmartBoxSaveRegularExpressionGlobal = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/save-regex-global`,
    data: data
  })
}

export const getSmartBoxRegexList = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/regex-folder/${ id }`
  })
}

export const getSmartBoxAllRegexList = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-regex-list`
  })
}

export const getManualTermsBuilder = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/manual-terms`,
    data
  })
}

export const getApiManualTermsBuilder = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/manual-terms/${ id }`
  })
}

export const fetchSubFolders = (folderPath) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/sub-folders`,
    data: folderPath
  })
}

export const saveAnalyticsFilter = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/filter-analytics`,
    data
  })
}

export const getAnalyticsFilter = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-filter-analytics/${ id }`
  })
}

export const deleteAnalyticsFilter = (id) => {
  return axios({
    method: 'delete',
    url: `${ host }/osprey-0.1.0/v1/smart-box/delete-filter-analytics/${ id }`
  })
}

export const getAnalyticsByFilter = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/filter-search`,
    data
  })
}

export const getAnalyticsByFilterForOverview = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/filter-search-new`,
    data
  })
}

export const getAnalyticsWithSearch = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/filter-search-search`,
    data
  })
}

export const getIdFinaliseDocument = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/finalised-doc`,
    data
  })
}

export const getRenameFile = (data) => {
  return axios({
    method: 'put',
    url: `${ host }/osprey-0.1.0/v1/smart-box/rename`,
    data
  })
}

export const getOcrFile = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-ocr-file`,
    data: data
  })
}

export const apiSmartBoxTreeForPicture = (documentId) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/tree-for-picture`,
    data: documentId
  })
}

export const apiSmartBoxTreeForPicturePage = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/tree-for-picture-page`,
    data: data
  })
}

export const getAllTerms = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/list-all-terms`,
    data: data
  })
}

export const getDictionaries = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-dictionaries`,
  })
}

export const deleteDictionary = (id) => {
  return axios({
    method: 'delete',
    url: `${ host }/osprey-0.1.0/v1/smart-box/delete-dictionary/${ id }`
  })
}

export const addDictionary = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/add-dictionary`,
    data: data
  })
}

export const saveDictionary = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/save-dictionary`,
    data: data
  })
}

export const saveTerm = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/save-term`,
    data: data
  })
}

export const getProjects = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-projects`,
  })
}

export const saveProject = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/save-project`,
    data: data
  })
}

export const deleteProject = (id) => {
  return axios({
    method: 'delete',
    url: `${ host }/osprey-0.1.0/v1/smart-box/delete-project/${ id }`
  })
}


export const getDictionariesData = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-dictionaries`
  })
}

export const deleteManualTerms = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/delete-multiple-manual-terms`,
    data: data
  })
}

export const deleteDirectories = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/dictionary-subcategory/`,
    data: data
  })
}

export const getNotesFolder = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-notes-folder/${ id }`,
  })
}

export const saveNotesFolder = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/save-notes-folder`,
    data: data
  })
}

export const getAllTermsBySearch = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/list-all-terms-search`,
    data: data
  })
}

export const getCategoriesSum = (idDocument) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/solr/one-category/${idDocument}`
  })
}

export const exportData = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/download/export-csv`,
    data: data
  })
}

export const apiSmartBoxSaveNewQueryStatus = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/solr/save-query-status/`,
    data
  })
}

export const apiSmartBoxFinalisedFolder = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/finalise-folder`,
    data
  })
}

export const apiSmartBoxFinalisedDocument = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/finalised-doc`,
    data
  })
}


export const apiSmartBoxZip = (folderId, folderName) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-documents-zip/${ folderId }/${ folderName }`,
    responseType: 'arraybuffer'
  })
}

export const apiSmartBoxExportDocument = (docId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/export/${ docId }`,
    responseType: 'arraybuffer'
  })
}

export const apiSmartBoxCheckBulkFolderFinalise = (folderId) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/folder-has-finalise`,
    data: folderId
  })
}

export const getDataInsightScanAnnotations = (idDocument) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/solr/query-for-table/${ idDocument }`
  })
}

export const createBusinessList = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/create-business-list`,
    data: data
  })
}

export const importBusinessList = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/import-update-business-list`,
    data: data
  })
}

export const importUpdateBusinessList = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/import-update-business-list`,
    data: data
  })
}

export const deleteBusinessList = (id) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/delete-business-list`,
    data: id
  })
}

export const deleteBusinessListMultiple = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/delete-business-list-multiple`,
    data: data
  })
}

export const getAllBusinessList = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-all-business-terms-lists`,
  })
}


export const getAndSaveLogExportBusinessList = (listId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-and-save-log-export-business-list/${listId}`,
  })
}

export const getTermsFromBusinessList = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-terms-from-business-list`,
    data: data
  })
}

export const editBusinessTermList = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/edit-business-term-list`,
    data: data
  })
}

export const editBusinessList = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/update-business-list`,
    data: data
  })
}

export const apiSmartBoxSaveManualTerms = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/manual-terms-document`,
    data
  })
}

export const apiSmartBoxGetManualTerms = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/manual-terms-document/${ id }`,
  })
}

export const deleteSmartBoxManualTerms = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/manual-terms-delete`,
    data
  })
}

export const apiSmartBoxSaveKeysRedacted = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/save-manual-terms-document-keys`,
    data
  })
}

export const apiSmartBoxGetKeys = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/manual-terms-document-keys/${ id }`,
  })
}

export const getBusinessTerms = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-all-business-terms-lists`,
  })
}

export const apiSaveBusinessTerms = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/save-term-to-list`,
    data
  })
}


export const getNewListBusinessTerms = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-terms-for-redaction`,
  })
}

export const getAllBusinessListsWithTerms = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-all-business-lists-with-terms`,
  })
}

export const getSpellChecker = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-spellcheck/${ id }`,
  })
}

export const getSpellCheckPage = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-spellcheck-page`,
    data
  })
}

export const selectBusinessTerms = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/select-business-terms`,
    data
  })
}

export const selectManualTerms = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/select-manual-terms`,
    data
  })
}

export const getSelectedBusinessItems = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-selected-terms/${id}`,
  })
}

export const getSelectedManualItems = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-selected-manual-terms/${id}`,
  })
}

export const saveManualTermsColor = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/save-manual-terms-color`,
    data
  })
}

export const editManualTerm = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/edit-manual-term`,
    data
  })
}

export const getRegexWords = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/regex-words/${id}`,
    //data
  })
}

export const getBusiness = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/business-term-words/${id}`,
    //data
  })
}
export const newGetRegexWords = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/regex-words-version2/${id}`,
    //data
  })
}

export const getRegexCategory = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/regex-category/${id}`,
    //data
  })
}

export const getRegexCategoryColor = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/regex-category-color/${id}`,
    //data
  })
}

export const savePiiCategories = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/save-pii-categories`,
    data
  })
}

export const getPiiCategories = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-pii-categories/${ id }`,
  })
}

export const saveRegularExpressionsColor = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/save-regular-expressions-color`,
    data
  })
}

export const apiEditRegularExpression = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/edit-regular-expression`,
    data: data
  })
}

export const apiEditRegularExpressionGlobal = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/edit-regular-expression-global`,
    data: data
  })
}

export const selectRegularExpressions = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/save-regular-expressions-ids`,
    data
  })
}

export const getRegexAndColor= (id) => {
  return axios({
    method: 'get',
    url: `${host}/osprey-0.1.0/v1/smart-box/get-regex-and-color/${id}`,
  })
}

export const deleteRegularExpressions = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/delete-regular-expressions`,
    data
  })
}

export const deleteRegularExpressionsGlobal = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/delete-regular-expressions-global`,
    data
  })
}


export const getSelectedRegex = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-selected-regex/${id}`
  })
}

export const newGetBusinessTermWords = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/business-term-words-version2/${id}`,
    //data
  })
}

export const getBusinessCategoryColor = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/business-term-color/${id}`,
    //data
  })
}


export const removeCategoryRegularExpressions = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/delete-regular-expresion-document`,
    data
  })
}

export const removeCategoryBusiness = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/delete-business-term-document`,
    data
  })
}

export const apiSmartBoxClearAllKeysOnPage = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/delete-page-readaction`,
    data: data
  })
}

export const getRedactedKeys = (id) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/solr/get-all-redactions`,
    data: id
  })
}

export const apiSendKeysHelper = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/key-helper`,
    data: data
  })
}

export const saveKeysRedactedOnRegEx = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/solr/save-regex-query-status`,
    data
  })
}

export const apiSmartBoxGetNewQueryStatusRegEx = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/solr/regex-query-status/`,
    data
  })
}

export const showRedactedRegexElementFromBulk = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/key-helper-regex/`,
    data
  })
}

export const apiSmartBoxClearCanvasPoints = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/pdf/clear-canvas/`,
    data
  })
}
export const apiSmartBoxRemoveCanvas= (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/solr/remove-redaction`,
    data
  })
}
export const saveKeysRedactedBusiness = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/solr/save-bt-query-status`,
    data
  })
}

export const apiSmartBoxGetNewQueryStatusBusiness = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/solr/bt-query-status/`,
    data
  })
}

export const showRedactedBusinessElementFromBulk = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/key-helper-business-terms/`,
    data
  })
}

export const showRedactedManualTermsElementFromBulk = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/key-helper-manual-terms/`,
    data
  })
}

export const clearAllBulk = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/clear-all-bulk/`,
    data
  })
}

export const exportToCsv = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/download/export-csv/`,
    responseType: 'arraybuffer',
    data
  })
}

export const exportToCsvOverview = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/download/export-csv-overview/`,
    responseType: 'arraybuffer',
    data
  })
}

export const exportToExcel = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/download/export-excel/`,
    responseType: 'arraybuffer',
    data
  })
}

export const exportToExcelOverview = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/download/export-excel-overview/`,
    responseType: 'arraybuffer',
    data
  })
}

export const importBusinessTerms = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/import-business-words/`,
    headers: { 'Content-Type': 'multipart/form-data' },
    data
  })
}

export const apiSaveSharingPermissions = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/save-sharing-permissions`,
    data: data
  })
}

export const apiGetSharingPermissions = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-sharing-permissions`,
    data: data
  })
}

export const apiGetPiiCategory = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/save-pii-from-all-terms`,
    data
  })
}

export const getOptionSelected = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-selected-option/${ id }`,
  })
}

export const apiSmartBoxMoveFiles= (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/move`,
    data
  })
}

export const getSmartBoxFilesPerPage = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/search-pagination`,
    data
  })
}
export const getThumbnailForFiles = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-thumbnail-for-files`,
    data
  })
}
export const apiSmartBoxDownloadFile = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/download-file`,
    data,
    responseType: 'arraybuffer'
  })
}

export const sendToReanalyseWithAI = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/reanalyse-with-ai`,
    data
  })
}

export const deleteSelected = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/delete-selected`,
    data
  })
}

export const setFileStatus = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/set-file-status`,
    data
  })
}


export const setFileStatusOpenSmartView = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/set-file-status-smartview`,
    data
  })
}

export const getProgressBarData = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/progress-bar-data`,
    data
  })
}

export const getProgressBarDataForBulk = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/progress-bar-bulk-data`,
    data
  })
}
export const getAllocateCategoryPiiOverview = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/allocate-category`,
    data
  })
}
export const apiSmartBoxTreeSearchImages = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/smart-view-redact-images`,
    data: data
  })
}
export const getAllocateCategoryDashboard = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/allocate-category-dashboard`,
    data
  })
}
export const getFolderCountFiles = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/folder-count-files`,
    data
  })
}
export const getFilesBeforeUploadDTO = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/check-existing-files-upload`,
    data
  })
}
export const getFolderSettingsRedactingTreeImages = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/tree-for-settings-images`,
    data
  })
}
export const apiSmartCheckBoxZipBoxFolder = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/zip/check-export-folder`,
    data
  })
}
export const apiSmartBoxZipBoxFolder = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/zip/download-folder`,
    responseType: 'arraybuffer',
    data
  })
}
export const getImageForUserOrTeam = (id, type) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/images/${ id }/${ type }`,
  })
}
export const getCompanyData = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/admin/company-data/${ id }`,
  })
}
export const updateCompanyData = (data) => {
  const bodyFormData = objectToFormData(data)
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/admin/company-data/update`,
    data: bodyFormData,
  })
}

export const getCheckFolderErrors = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/check-folder-errors`,
    data
  })
}
export const getListDuplicateFileBox = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/list-duplicate-file-box`,
    data
  })
}
export const getListDuplicateFileBoxSecond = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/list-duplicate-file-box-second`,
    data
  })
}
export const apiPreviewFileDuplicate = (path) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/preview-doc-by-path/${ path }`,
  })
}
export const getCheckFilesDuplicate= (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/check-for-duplicate`,
    data
  })
}
export const apiListDeleteDuplicates = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/delete-duplicate`,
    data
  })
}
export const apiStarListDuplicate = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-star-list-duplicate`,
    data
  })
}
export const apiSaveDocumentStarStatus = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/save-document-star-status`,
    data
  })
}
export const apiAutoDeduplicate = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/auto-deduping`,
    data
  })
}
export const apiSmartBoxSaveNewQueryStatusOcr = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/solr/save-query-status-ocr/`,
    data
  })
}

export const apiSmartBoxSaveXeroxRedactionSmartView= (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/xerox-redaction-smartView`,
    data
  })
}

export const getCheckFilesProprieties = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/check-file-proprieties`,
    data
  })
}
export const setClassificationStatusOpenSmartView = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/set-file-classification-smartview`,
    data
  })
}
export const getProgressRename = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/progress-bar-rename`,
    data
  })
}
export const checkFolderSettingTree = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/check-tree-for-settings`,
    data
  })
}

export const checkDetailedTreeSettingsAll = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/check-detailed-tree-settings-all`,
    data
  })
}
export const cancelBoxRedaction = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/cancel-bulk`,
    data
  })
}
export const savedRegularExpressions = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/save-regex-folder`,
    data
  })
}
export const cancelFilesUpload = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/cancel-upload`,
    data
  })
}
export const apiSmartNewBulkRedaction = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/detailed-tree-settings-redaction`,
    data
  })
}

export const apiSaveRedactionFilterSelections = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/save-redaction-filter-selections`,
    data
  })
}

export const apiSmartErrorsReport = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/error-report-list`,
    data
  })
}

export const downloadZipBox = (encodedId) => {
  //return `${ host }/osprey-0.1.0/v1/smart-box/zip/download-folder/${allValues}/${boxId}/${clientId}/${userId}/${boxName}`
  return `${ host }/osprey-0.1.0/v1/smart-box/zip/download-folder/${encodedId}`
}

export const apiCheckExistingZipExport = (boxId, clientId, userId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/zip/check-existing-zip-export/${boxId}/${clientId}/${userId}`,
  })
}

export const getCheckBulkStatus = (data) => {
  return axios({
    method: 'post',
    url: `${ hostRedact }/redaction-0.1.0/v1/smart-box/check-bulk-status`,
    data
  })
}
export const apiCancelBulk = (data) => {
  return axios({
    method: 'post',
    url: `${ hostRedact }/redaction-0.1.0/v1/smart-box/cancel-bulk`,
    data
  })
}
export const apiResumeBulk = (data) => {
  return axios({
    method: 'post',
    url: `${ hostRedact }/redaction-0.1.0/v1/smart-box/resume-bulk`,
    data
  })
}
export const getCheckResumeBulkStatus = (data) => {
  return axios({
    method: 'post',
    url: `${ hostRedact }/redaction-0.1.0/v1/smart-box/check-bulk-status-resume`,
    data
  })
}
export const apiGetRedactionServiceUrl = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-redaction-service-url`,
  })
}
export const apiGetTotalSizeBox = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-left-tree-report-info`,
    data
  })
}
export const apiGetUserBoxLogReportInfo = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-user-box-log-report-info`,
    data
  })
}
export const apiCheckBoxStatusForUpdate = (pageNr, pageRows) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/check-box-status-for-update/${ pageNr }/${ pageRows }`,
  })
}
export const apiBubbleChartStatisticsReport = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-bubble-chart-statistics-report`,
    data
  })
}
export const apiPieChartDataReport = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-pie-chart-data-report`,
    data
  })
}
export const apiCheckBoxRedactionStatus = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/check-box-redaction-status`,
    data
  })
}

export const apiGetDuplicatesCardInfo = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-duplications-card-info`,
    data
  })
}

export const deleteSelectedFilesError = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/error-report-list-delete`,
    data
  })
}

export const apiCheckExistingAuditExport = (boxId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/zip/check-for-audit-files/${ boxId }`,
  })
}

export const apiGetBoxDeduplicationStatus = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-box-deduplication-status/${ id }`,
  })
}

export const apiSmartBoxExportAudit = (docId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/export-audit/${ docId }`,
    responseType: 'arraybuffer'
  })
}

export const apiSmartBoxUpdateRedactionNumber = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/pdf/update-redactions-number`,
    data
  })
}
export const getNearDuplicate = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/delete-near-duplication-bulk`,
    data
  })
}

export const apiListDeleteNear = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/delete-near-duplicate`,
    data
  })
}
export const apiKeepNewestFile = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/delete-near-duplicate-bulk`,
    data
  })
}
export const apiBubblePerDay = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-bubble-chart-statistics-report-daily`,
    data
  })
}

export const apiEditDetailedTreeSettingsRedaction = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/edit-detailed-tree-settings-redaction`,
    data
  })
}

export const apiGetBoxNearStatus = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-box-near-deduplication-status/${ id }`,
  })
}

export const apiSmartBoxGetPageCanvasPoints = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/pdf/get-page-canvas-points`,
    data: data
  })
}

export const getListClutter = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-clutter-list`,
    data
  })
}
export const getClutterEmails = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/clutter-emails`,
    data
  })
}

export const downloadFilesError = (downloadAll, docIDs, boxId, clientId, searchWord) => {
  return `${ host }/osprey-0.1.0/v1/smart-box/error-report-list-download/${downloadAll}-${docIDs}-${boxId}-${clientId}-${searchWord}`
}

export const getErrorReportListFileLocation = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/error-report-list-file-location`,
    data
  })
}

export const getDictionariesRedacted = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-all-business-terms-lists`,
  })
}

export const addBusinessTermToDictionary = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/add-business-term-to-dictionary`,
    data
  })
}
export const apiGetBoxClutterStatus = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-box-clutter-status/${ id }`,
  })
}

export const getCullingFiles = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/culling-files`,
    data
  })
}
export const getCullReportData = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-cull-report-data`,
    data
  })
}
export const getCullReportDataSecond = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-cull-report-data-second-page`,
    data
  })
}

export const downloadExcelCullReport = (cullId, clientId, userId) => {
  return `${ host }/osprey-0.1.0/v1/smart-box/download/export-excel-cull-report/${cullId}/${clientId}/${userId}`
}

export const apiGetStatusStarReportCull = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-box-cull-report-star-status/${ id }`,
  })
}
export const sendReportStar = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/set-box-report-star-status`,
    data
  })
}

export const apiGetStatusStarReport = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-box-report-star-status/${ id }`,
  })
}
export const sendReportStarCull = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/set-box-cull-report-star-status`,
    data
  })
}

export const apiSmartBoxAddTermToSearchHistory = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/pdf/add-term-to-search-history`,
    data: data
  })
}

export const apiGetSmartviewSearchHistoryTerms = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/pdf/get-smartview-search-history-terms/${ id }`,
  })
}

export const apiGetSelectedOptionRedaction = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-last-options-selected-redaction`,
    data: data
  })
}

export const apiGetClutterCardInfo = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-clutter-card-info`,
    data
  })
}

export const apiSmartCullReportDuplicateList = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-cull-report-data-duplicate-list`,
    data
  })
}

export const apiSmartCullReportNearList = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-cull-report-data-near-duplicate-list`,
    data
  })
}
export const apiSmartCullReportEmptyList = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-cull-report-data-empty-folder-list`,
    data
  })
}

export const apiSmartCullReportThreadList = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-cull-report-data-email-threading-list`,
    data
  })
}

export const apiSaveConnector = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/save-connector`,
    data
  })
}

export const apiGetConnectorList = (clientId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-connector-list/${ clientId }`,
  })
}

export const apiGetSharePointSiteList = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/get-site-list-sharepoint`,
    data
  })
}

export const apiGetSharePointSiteFolderList = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/get-site-list-folders-sharepoint`,
    data
  })
}

export const apiDeleteConnector = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/delete-connector`,
    data
  })
}

export const apiEditConnector = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/edit-connector`,
    data
  })
}

export const apiDownloadFilesDriveInitialiseUpload = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/download-files-from-drive-and-initialise-upload`,
    data
  })
}
export const apiDownloadFilesFromSharePointInitialiseUpload = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/download-files-from-share-point-and-initialise-upload`,
    data
  })
}

export const apiGetConnectorInfo = (connectorId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/get-connector-info/${ connectorId }`,
  })
}

export const apiCountMailsFromUsExchange= (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/count-mails-from-ms-exchange-in-background`,
    data
  })
}

export const apiAuthenticateConnector = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/authenticateConnector`,
    data
  })
}

export const apiCancelUpload = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/cancel-upload`,
    data
  })
}

export const apiExtractEmailsAndInitialiseUpload = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/extract-emails-and-initialise-upload`,
    data
  })
}

export const apiFormBuilderCreateFormWorkflow = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/form-builder/create-form-workflow`,
    data
  })
}

export const apiGetFormBuilderListWorkflow = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/form-builder/get-form-list-workflow`,
  })
}

export const apiGetFormWorkflow = (formId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/form-builder/get-form-workflow/${formId}`,
  })
}
export const apiGetDefaultFormWorkflow = (formId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/form-builder/get-default-form-workflow/${formId}`,
  })
}

export const apiDeleteFormWorkflow = (formId) => {
  return axios({
    method: 'delete',
    url: `${ host }/osprey-0.1.0/v1/form-builder/delete-form-workflow/${formId}`,
  })
}

export const apiFormBuilderEditFormWorkflow = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/form-builder/edit-form-workflow`,
    data
  })
}

export const apiPostConnectorInfo = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/get-connector-info`,
    data
  })
}

export const apiCancelMultipleUpload = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/cancel-multiple-upload`,
    data
  })
}

export const apiSaveSourceMapping= (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/save-source-mapping`,
    data
  })
}

export const apiGetSourceMappingList = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-source-mapping-list`,
    data
  })
}

export const apiGetSourceMappingFilterOptions = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-source-mapping-filter-options`
  })
}

export const apiDeleteSourceMapping = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/delete-source-mapping`,
    data
  })
}

export const apiEditSourceMappingList = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/edit-source-mapping-list`,
    data
  })
}

export const apiGetSourceMappingListBusinessProcess = (clientId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-source-mapping-list-business-process/${ clientId }`,
  })
}

export const apiGetFormVariablesWorkflow = (formId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/form-builder/get-form-variables-workflow/${formId}`,
  })
}

export const apiGetCheckCountMailsFromMsExchange = (queryId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/check-count-mails-from-ms-exchange/${queryId}`,
  })
}

export const apiSmartBoxSetDocumentRedactAllStatus = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/pdf/set-doc-redact-all-status`,
    data
  })
}

export const apiGetSetDocumentRedactAllStatus = (docId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/pdf/get-doc-redact-all-status/${docId}`,
  })
}

export const apiRemoveAllSmartviewRedactions = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/pdf/remove-all-smartview-redactions`,
    data
  })
}

export const apiSmartBoxSaveCanvasDocumentSmartview = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/pdf/save-canvas-document-smartView`,
    data: data
  })
}

export const apiBusinessProcessCreate = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/business-process/create`,
    data
  })
}

export const apiGetBusinessProcessList = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/business-process/get-business-process-list`,
  })
}

export const apiBusinessProcessDelete = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/business-process/delete`,
    data
  })
}

export const apiBusinessProcessEdit = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/business-process/edit`,
    data
  })
}

export const apiWorkflowBuilderCreate = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/create-new-workflow-template-with-components`,
    data
  })
}

export const apiGetWorkflowBuilder = (clientId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/get-workflow-template-list/${clientId}`,
  })
}

export const apiGetWorkflowSelected = (workflowId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/get-workflow-template-with-components/${workflowId}`,
  })
}

export const apiWorkflowBuilderEdit = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/edit-workflow-template-with-components`,
    data
  })
}

export const apiWorkflowBuilderDelete = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/delete-workflow-template`,
    data
  })
}

export const apiWorkflowTemplateSimpleCreate = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/create-new-workflow-template-simple`,
    data
  })
}

export const apiWorkflowTemplateSimpleEdit = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/edit-workflow-template-simple`,
    data
  })
}

export const apiCreateBusinessProcessTemplate = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/ropa/create-ropa-template`,
    data
  })
}

export const apiGetBusinessProcessTemplate = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/ropa/get-ropa-template-list`,
  })
}

export const apiGetBusinessProcessTemplateId = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/ropa/get-ropa-template/${id}`,
  })
}

export const apiDeleteBusinessProcessTemplate = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/ropa/delete-ropa-template`,
    data
  })
}

export const apiEditBusinessProcessTemplate = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/ropa/edit-ropa-template`,
    data
  })
}

export const getFolderFilesNumber = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/folder-files-number`,
    data
  })
}

export const apiGetTagDocumentClient = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/tag-document/get-tags-client/${id}`,
  })
}

export const apiGetTagDocumentClientAdmin = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/tag-document/get-tags-client-admin/${id}`,
  })
}

export const apiGetTagsGodAdmin = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/tag-document/get-tags-god-admin`,
  })
}

export const apiSmartBoxCheckAllCanvasPoints = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/check/pdf/canvas/${ id }`,
  })
}

export const apiCheckExportErrorReportList = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/zip/check-export-error-report-list`,
    data
  })
}

export const apiExternalGetTenantList = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/externalAPI/getTenantList`,
  })
}

export const apiExternalCreateToken = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/externalAPI/create-token`,
    data
  })
}

export const apiExternalGetTokenList = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/externalAPI/getTokenList`,
  })
}

export const apiExternalEditToken = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/externalAPI/edit-token`,
    data
  })
}

export const apiExternalDeleteToken = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/externalAPI/delete-token`,
    data
  })
}

export const apiExternalGetAvailableToken = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/externalAPI/getAPIsAvaiableToken`,
  })
}

export const apiGiveTokenAccessToApi = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/externalAPI/give-token-access-to-api`,
    data
  })
}

export const apiGetTokenApiList = (tokenKey) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/externalAPI/getTokenAPIList/${ tokenKey }`,
  })
}

export const apiDeleteToken = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/externalAPI/delete-API-token`,
    data
  })
}

export const apiEditToken = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/externalAPI/edit-API-token`,
    data
  })
}
export const apiSaveNewBrandingProfile = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/branding-profile/save-new-branding-profile`,
    data
  })
}

export const apiGetBrandingProfiles = (pageNumber, pageRows, search) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/branding-profile/get-branding-profiles/${ pageNumber }/${ pageRows }-${ search }`,
  })
}

export const deleteBrandingProfile = (id) => {
  return axios({
    method: 'delete',
    url: `${ host }/osprey-0.1.0/v1/branding-profile/delete-branding-profile/${ id }`
  })
}

export const apiEditNewBrandingProfile = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/branding-profile/edit-branding-profile`,
    data
  })
}

export const apiGetBrandingProfilesSimple = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/branding-profile/get-branding-profiles-simple`,
  })
}

export const geRectanglesCoordsOcr = (docId) => {
  //const solrIp = '18.157.167.245'
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/solr/get-rectangles-coords-ocr/${docId}`,
    //url: `${ host }/osprey-0.1.0/v1/smart-box/solr/get-rectangles-coords-ocr/${docId}-${solrIp}`,
  })
}

export const apiExternalApiCreateToken = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/externalAPI/create-token`,
    data
  })
}

export const getExternalApiAvaiableToken = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/externalAPI/getAPIsAvaiableToken`,
  })
}

export const getExternalApiTokenList = (pageNumber, pageRows) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/externalAPI/getTokenList/${pageNumber}/${pageRows}`,
  })
}

export const apiExternalApiDeleteToken = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/externalAPI/delete-token`,
    data
  })
}

export const getExternalApiTokenListId = (tokenId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/externalAPI/getTokenAPIList/${tokenId}`,
  })
}

export const apiExternalApiEditToken = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/externalAPI/edit-token`,
    data
  })
}

export const apiExternalApiCopyToken = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/externalAPI/copyToken`,
    data
  })
}

export const apiCreateWorkflowTicket = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/create-workflow-ticket`,
    data
  })
}

export const apiEditWorkflowTicket = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/edit-workflow-ticket`,
    data
  })
}

export const getWorkflowTemplateList = (clientId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/get-workflow-template-list/${clientId}`,
  })
}

export const getTicketWorkflowAvailableUserList = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/get-ticket-workflow-available-user-list`,
  })
}

export const getTicketWorkflowListDashboard = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/get-ticket-workflow-list-dashboard`,
    data
  })
}

export const getTicketWorkflowFiltersDashboard = (ticketStatus) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/get-ticket-workflow-filters-dashboard/${ticketStatus}`
  })
}

export const getTicketWorkflowBoxStatusDashboard = (pageNumber, pageRows, search, ticketStatus) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/get-ticket-workflow-box-status-dashboard/${pageNumber}/${pageRows}-${ticketStatus}-${search}`,
  })
}

export const getPostTicketWorkflowBoxStatusDashboard = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/get-ticket-workflow-box-status-dashboard`,
    data
  })
}

export const apiSetTicketWorkflowStatus = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/set-ticket-workflow-status`,
    data
  })
}

export const getTicketWorkflowBoxStatisticsDashboard = (ticketStatus, year) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/get-workflow-dashboard-statistics/${ticketStatus}/${year}`,
  })
}

export const getTicketWorkflowAndHeaderDetails = (ticketId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/get-ticket-and-header-details/${ticketId}`,
  })
}

export const getSourceMappingListSimple = (clientId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/source-mapping/get-source-mapping-list-simple/${clientId}`,
  })
}

export const apiEditBusinessProcessComponents = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/business-process/edit-business-process-components`,
    data
  })
}

export const getBusinessProcessWithComponents = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/business-process/get-business-process-with-components`,
  })
}

export const getBusinessProcessWithComponentsId = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/business-process/get-business-process-with-components/${id}`,
  })
}

export const getRectanglesCoordsOcrPost = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/solr/get-rectangles-coords-ocr`,
    data
  })
}

export const apiSaveEmailTemplateSettings = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/save-email-template-settings`,
    data
  })
}

export const getEmailTemplateSettings = (clientId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/get-email-template-settings/${clientId}`,
  })
}

export const apiSaveFilesDisclosureFirstStep = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/load-files-disclosure-first-step`,
    data
  })
}

export const apiPackFilesDisclosureFirstStep = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/pack-files-disclosure-first-step`,
    data
  })
}

export const apiGetDisclosureFirstStepAndFiles = (ticketId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/get-disclosure-first-step-options-and-files/${ticketId}`,
  })
}

export const apiGetDisclosureEmailTextSecondStep = (ticketId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/get-disclosure-email-text-second-step/${ticketId}`,
  })
}

export const apiGetDisclosureEmailTrackingSteps = (ticketId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/get-disclosure-email-tracking-steps/${ticketId}`,
  })
}

export const apiCreateFormTicketWorkflow = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/workflow/ticket/create-form-ticket-workflow`,
    data
  })
}

export const apiGetFormTicketWorkflow = (ticketId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/workflow/ticket/get-form-ticket-workflow/${ticketId}`,
  })
}

export const apiEditFormTicketWorkflow = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/workflow/ticket/edit-form-ticket-workflow`,
    data
  })
}

export const apiGetSubTenantList = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/sub-tenant/getTenantList`,
  })
}

export const apiGetSubTenantsClientSmartbox = (clientId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/sub-tenant/get-sub-tenants-client-smartbox/${clientId}`,
  })
}

export const apiSwitchToSubTenant = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/sub-tenant/switch-to-sub-tenant`,
    data
  })
}

export const apiGetTicketFormComponentFromWorkflow = (ticketId, workflowComponentId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/get-ticket-form-component-from-workflow/${ticketId}/${workflowComponentId}`,
  })
}

export const apiSendInternalEmailNotificationWorkflow = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/send-internal-email-notification-ticket`,
    data
  })
}

export const apiMarkTicketWorkflowAsCompleted = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/mark-workflow-ticket-as-completed`,
    data
  })
}

export const apiSendEmailWorkflowTicket = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/send-email-workflow-ticket`,
    data
  })
}

export const apiGetEmailTemplateTextWorkflowTicket = (workflowComponentId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/get-text-email-template-workflow-ticket/${workflowComponentId}`,
  })
}

export const apiSaveFormSectionsDataTicketWorkflow = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/save-form-sections-data-ticket-workflow`,
    data
  })
}

export const apiSetStepStatusTicketWorkflow = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/set-step-status-ticket-workflow`,
    data
  })
}

export const apiDeleteFormSectionsFileData = (fileId) => {
  return axios({
    method: 'delete',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/delete-form-sections-file-data-ticket-workflow/${fileId}`,
  })
}
export const apiDeleteFormSectionsFileDataExtern = (fileId) => {
  return axios({
    method: 'delete',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/delete-form-sections-file-data-ticket-workflow-extern/${fileId}`,
  })
}

export const apiGetDataCollectorPageDataWorkflowTicket = (workflowComponentId, ticketId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/get-data-collector-page-data-workflow-ticket/${workflowComponentId}/${ticketId}`,
  })
}

export const apiSmartBoxWarningReportList = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/warning-report-list`,
    data
  })
}

export const apiSmartBoxWarningReportListDelete = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/warning-report-list-delete`,
    data
  })
}

export const apiSmartBoxWarningReportListSetTag = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/warning-report-list-set-tag`,
    data
  })
}

export const apiSaveDataCollectorOptionsWorkflowTicket = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/save-data-collector-options-workflow-ticket`,
    data
  })
}

export const apiGetBoxErrorReportDeleteStatus = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-box-error-report-delete-status/${ id }`,
  })
}

export const apiGetBoxWarningReportDeleteStatus = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-box-warning-report-delete-status/${ id }`,
  })
}

export const apiSaveSelectedTextComment = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/save-selected-text-comment`,
    data
  })
}

export const apiSendEmailDisclosureWorkflowTicket = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/send-email-disclosure-workflow-ticket`,
    data
  })
}

export const getDisclosureDeliveredPageData = (ticketId, workflowComponentId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/get-disclosure-delivered-page-data/${ticketId}/${workflowComponentId}`,
  })
}

export const apiGetClutterEmptyFolderList = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-clutter-empty-folder-list`,
    data
  })
}

export const apiGetClutterEmailThreadingListFirstPage = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-clutter-email-threading-list-first-page`,
    data
  })
}

export const apiGetClutterEmailThreadingListSecondPage = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/get-clutter-email-threading-list-second-page`,
    data
  })
}

export const apiPreviewDocByIdDoc = (idDoc) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/smart-box/preview-doc-by-id-doc/${ idDoc }`,
  })
}

export const downloadExportLoadedFilesDisclosure = (encodedId) => {
  return `${ host }/osprey-0.1.0/v1/workflow-builder/download/export-loaded-files-disclosure/${encodedId}`
}

export const downloadExportDeliveredPageDisclosure = (encodedId) => {
  return `${ host }/osprey-0.1.0/v1/workflow-builder/download/export-delivered-page-disclosure/${encodedId}`
}

export const apiSmartBoxFolderHasWarningFilesNotification= (folderPath) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/smart-box/folder-has-warning-files-notification`,
    data: folderPath
  })
}

export const apiGetDisclosureStepsDetails = (ticketId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/get-disclosure-steps-details/${ticketId}`,
  })
}

export const apiCollectorPageGetCollectorPageData = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/collectorPage/get-collector-page-data`,
    data
  })
}

export const apiCollectorPageSaveCollectorPageData = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/collectorPage/save-collector-page-data`,
    data
  })
}

export const apiCollectorPageGetCollectorPageUploadHistoryData = (boxId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/collectorPage/get-collector-page-upload-history-data/${boxId}`
  })
}

export const apiGetWorkflowTicketCollectorPageUploadHistoryData = (ticketId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/collectorPage/get-workflow-ticket-collector-page-upload-history-data/${ticketId}`
  })
}

export const apiCheckIfRequesterIsMandatoryTicket = (workflowId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/ckeck-if-requester-is-mandatory-ticket/${workflowId}`,
  })
}

export const apiGetFeedbackWorkflowTicketForm = (encodedId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/feedback-workflow-ticket-form/${encodedId}`,
  })
}

export const apiSaveFormSectionsDataTicketWorkflowByUse = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/save-form-sections-data-ticket-workflow-by-user`,
    data
  })
}

export const apiGetDefaultWorkflowTemplateList = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/get-default-workflow-template-list`,
  })
}

export const apiCreateNewDefaultWorkflowTemplateSimpleCreate = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/create-new-default-workflow-template-simple`,
    data
  })
}

export const apiDeleteDefaultWorkflowTemplate = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/delete-default-workflow-template`,
    data
  })
}

export const apiEditDefaultWorkflowTemplateSimple = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/edit-default-workflow-template-simple`,
    data
  })
}

export const apiEditDefaultWorkflowTemplateWithComponents = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/edit-default-workflow-template-with-components`,
    data
  })
}

export const apiGetDefaultFormListWorkflow = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/form-builder/get-default-form-list-workflow`,
  })
}

export const apiCreateDefaultFormWorkflow = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/form-builder/create-default-form-workflow`,
    data
  })
}

export const apiEditDefaultFormWorkflow = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/form-builder/edit-default-form-workflow`,
    data
  })
}

export const apiDeleteDefaultFormWorkflow = (formId) => {
  return axios({
    method: 'delete',
    url: `${ host }/osprey-0.1.0/v1/form-builder/delete-default-form-workflow/${formId}`,
  })
}

export const apiCreateNewDuplicateWorkflowTemplate = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/create-new-duplicate-workflow-template`,
    data
  })
}

export const apiCreateNewDefaultDuplicateWorkflowTemplate = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/create-new-default-duplicate-workflow-template`,
    data
  })
}

export const apiCreateDuplicateFormWorkflow = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/form-builder/create-duplicate-form-workflow`,
    data
  })
}

export const apiCreateDuplicateDefaultFormWorkflow = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/form-builder/create-default-duplicate-form-workflow`,
    data
  })
}

export const downloadDashboardWorkflow = () => {
  const accessToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId
  return `${ host }/osprey-0.1.0/v1/workflow-builder/download/export-workflow-page/${accessToken}`
}

