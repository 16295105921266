import { normal } from '../Helpers/OptionTemplates'

const shouldDisabled = ({ subType }) => (
  subType === 'OTHER' || subType === 'NONE_OF_THE_ABOVE'
)

export default ({ question }) => {
  let options = []
  
  if (question.options.length === 0) {
    options = [...Array(2).keys()].map((key, index) => (
      normal(index)
    ))
  } else {
    options = question.options.map((option) => ({
      ...option,
      title: '',
      disabled: shouldDisabled(option)
    }))
  }

  const newQuestion = {
    ...question,
    options
  }

  return newQuestion
}