import React from 'react'
import PropTypes from 'prop-types'

import Button from '../Common/Button'

import './PiaCompleteBox.scss'

const PiaCompleteBox = ({ onClick }) => (
  <div className="piaCompleteBox__wrapper">
    <h1 className="piaCompleteBox__title">This PIA has been completely answered. Last chance to review.</h1>
    <h2 className="piaCompleteBox__subTitle">
      Once your PIA has been submitted it will be set as locked and no further edits can be made. Are you sure you are ready to submit?
    </h2>

    <div className="piaCompleteBox__buttons">
      <Button theme="green" onClick={ onClick }>
        Submit as completed
      </Button>
    </div>
  </div>
)

PiaCompleteBox.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default PiaCompleteBox