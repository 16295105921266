import React from 'react'
import { Route, Navigate, Outlet } from 'react-router-dom'
import { connect } from 'react-redux'
import CustomNotificationManager from '../components/Common/CustomNotificationManager'

const AssetRegisterPrivateRouter = ({ component: Component, ...rest }) => {
    console.log(rest.isAdditionalEnvironmentsVisible)
    if (rest.isLoggedIn) {
        if(window.location.href.includes('multitenancy-invitations')){
            const found = rest.isAdditionalEnvironmentsVisible
            if (found) {
              return <Outlet {...rest}/>
            }
        }
      // CustomNotificationManager.info('Access restricted', 'Unauthorized')
      return <Navigate to="/smart-box/management"/>
    }
    return <Navigate to="/osp/login"/>
  }


const mapStateToProps = (state) => ({
  isLoggedIn: state.userReducer.isLogged,
  isAdditionalEnvironmentsVisible: state.applications.additionalEnvironmentsVisible
})

export default connect(mapStateToProps, {})(AssetRegisterPrivateRouter)