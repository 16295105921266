import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

import './Locked.scss'
import { Tooltip } from 'antd'

const Locked = ({ assessment, answered, total, processStatus }) => (
  <div className="lockedAssessment-wrapper">
    <div className="lockedAssessment-actionsContainer">
      <Tooltip title="You need to subscribe to Unlimited plan to access this feature.">
        <span className="iconReportDisabled" />
      </Tooltip>
      { getPlayOrViewIcon(assessment) }
      <Tooltip title="You need to subscribe to Unlimited plan to access this feature.">
        <span className="iconDownloadDisabled" />
      </Tooltip>
    </div>

    <div className="lockedAssessment-infoContainer">
      <div className="lockedAssessment-textContainer">
        { moment(assessment.validStartDate).format('MMMM YYYY') }
      </div>
      <div className="lockedAssessment-status">
        { processStatus !== 'INACTIVE' &&
          <span>
            { getStatusLabel(assessment, answered, total) }
          </span>
        }

        { processStatus === 'INACTIVE' &&
          <span>
            Terminated { moment(assessment.validStartDate).format('MMMM YYYY') }
          </span>
        }
        
        <div className="lockedAssessment-iconContainer">
          { getStatusIcon(assessment) }
        </div>
      </div>
    </div>
  </div>
)

const getStatusLabel = (assessment, answered, total) => {
  if (assessment.status === 'COMPLETED') return 'Completed'
  return `${ answered }/${ total } overdue on ${ moment(assessment.validEndDate).format('MMM DD') }`
}

const getStatusIcon = (assessment) => {
  if (assessment.status === 'COMPLETED') return (
    <span className="iconCheckDisabled" />
  )

  return (<span className="iconWarningDisabled" />)
}

const getPlayOrViewIcon = (assessment) => {
  let icon = 'iconPlayDisabled'
  if (assessment.status === 'COMPLETED') {
    icon = 'iconViewDisabled'
  }
  return (<Tooltip title="You need to subscribe to Unlimited plan to access this feature."><span className={ icon } /></Tooltip>)
}

Locked.propTypes = {
  assessment: PropTypes.object.isRequired,
  answered: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  processStatus: PropTypes.string.isRequired
}

export default Locked