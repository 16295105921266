import { divider } from './OptionTemplates'

export const defineDisplaySection = (options) => {
  let displaySection = ''

  return options.map((option) => {
    if (option.subType === 'DIVIDER') {
      displaySection = option.title
      return option
    }
    return ({
      ...option,
      displaySection
    })
  })
}

export const buildDivisors = (options) => {
  const newOptions = []

  const mappedSections = {}
  options.forEach((option) => {
    mappedSections[`__${ option.displaySection }`] = mappedSections[`__${ option.displaySection }`] || []
    mappedSections[`__${ option.displaySection }`].push(option)
  })

  Object.entries(mappedSections).forEach(([key, value]) => {
    if (key === '__null') {
      newOptions.push(...value)
    } else {
      newOptions.push(divider(0, key.replace('__', '')))
      newOptions.push(...value)
    }
  })

  return newOptions
}

export const updateOptionsOrder = (options) => {
  return options.map((option, index) => (
    {
      ...option,
      order: index
    }
  ))
}

export const findGoToTheQuestion = (option, questions, currentQuestion) => {
  const newOption = { ...option }
  if (newOption.jumpsToEnd) {
    newOption.goToQuestion = 'toTheEnd'
  }

  if (newOption.jumpsToQuestion) {
    const question = questions.find((question) => question.id === newOption.jumpsToQuestion)
    newOption.jumpsToQuestion = question.ref
    newOption.goToQuestion = question.ref
  }

  if (!newOption.jumpsToEnd && !newOption.jumpsToQuestion) {
    const question = questions.find((question) => question.order === currentQuestion.order + 1)
    if (question) {
      newOption.jumpsToQuestion = question.ref
      newOption.goToQuestion = 'nextQuestion'
    } else {
      newOption.jumpsToQuestion = ''
      newOption.jumpsToEnd = true
    }
  }

  return newOption
}

export const findJumpedQuestion = (type, questions, currentQuestion) => {
  const jumpQuestion = {}
  const nextQuestion = questions.find((question) => (question.order === currentQuestion.order + 1))
  if (!nextQuestion) type = 'toTheEnd'

  switch (type) {
    case 'toTheEnd':
      jumpQuestion.jumpsToQuestion = ''
      jumpQuestion.jumpsToEnd = true
      jumpQuestion.goToQuestion = 'toTheEnd'
      break
    case 'nextQuestion':
      jumpQuestion.jumpsToQuestion = nextQuestion.ref
      jumpQuestion.jumpsToEnd = false
      jumpQuestion.goToQuestion = 'nextQuestion'
      break
    default:
      jumpQuestion.goToQuestion = type
      jumpQuestion.jumpsToQuestion = type
      jumpQuestion.jumpsToEnd = false
      break
  }

  return jumpQuestion
}