import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import './AdditionalInformationWorkflow.scss'
import {Input, Select, Steps} from "antd";
const { Option } = Select;
const { Step } = Steps;
export class AdditionalInformationWorkflow extends Component {

    state = {
        formAdditional: {
            valueNotes1: '',
            valueNotes2: '',
            valueNotes3: '',
        }
    }
    onChangeNotesFormAdditional = (e, key) => {
       switch (key) {
           case 'notes1':
               this.setState({
                   formAdditional: { ...this.state.formAdditional, valueNotes1: e.target.value },
               })
               break
           case 'notes2':
               this.setState({
                   formAdditional: { ...this.state.formAdditional, valueNotes2: e.target.value },
               })
               break
           case 'notes3':
               this.setState({
                   formAdditional: { ...this.state.formAdditional, valueNotes3: e.target.value },
               })
               break
           default: break
       }
    }
    render() {
        const { formAdditional } = this.state
        return (
            <div className="additional-information-page">
                <div className="additional-information-page__header">
                    <span className="name-additional">Additional information form</span>
                    <span className="details-additional">XXXXXXXXXXXXXXX</span>
                </div>
                <div className="additional-information-page__content">
                    <div className="notes-form">
                        <span className="header-notes">Notes</span>
                        <span className="input-content">
                                <Input className="input-form-name"
                                       onChange={(e) => this.onChangeNotesFormAdditional(e, 'notes1')}
                                       placeholder={'XXXXXXX'}
                                       value={ formAdditional.valueNotes1 }/>
                            </span>
                    </div>
                    <div className="notes-form">
                        <span className="header-notes">Notes</span>
                        <span className="input-content">
                                <Input className="input-form-name"
                                       onChange={(e) => this.onChangeNotesFormAdditional(e, 'notes2')}
                                       placeholder={'XXXXXXX'}
                                       value={ formAdditional.valueNotes2 }/>
                            </span>
                    </div>
                    <div className="notes-form">
                        <span className="header-notes">Notes</span>
                        <span className="input-content">
                                <Input className="input-form-name"
                                       onChange={(e) => this.onChangeNotesFormAdditional(e, 'notes3')}
                                       placeholder={'XXXXXXX'}
                                       value={ formAdditional.valueNotes3 }/>
                            </span>
                    </div>
                </div>
            </div>
        )
    }
}


AdditionalInformationWorkflow.propTypes = {
    folder: PropTypes.object,
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id
})
export default compose(
    connect(mapStateToProps),
)(AdditionalInformationWorkflow)