import React from 'react'
import { compose } from 'redux'

import DataLinesList from './DataLines/DataLinesList'
 
import './DataLines.css'

import WithDataLines from './HOC/WithDataLines'

class DataLines extends React.Component {
  
    componentDidMount = () => {
      window.scrollTo(0, 0)
    }
    
    render = () => {
      
      return (
        <div className="backofficeDataLines__wrapper">
          <DataLinesList datalines={ this.props.datalines }/>
        </div>
      )
    }
}


export default compose(WithDataLines)(DataLines)