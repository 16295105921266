import React from 'react'
import {apiSarCheckImportBox, apiSarSteps, apiSarStepsImportBox} from '../../../api/Sar'
import './Tracker.scss'
import AcknowledgeStep from './steps/AcknowledgeStep'
import InitialRequestStep from './steps/InitialRequestStep'
import CheckIdStep from './steps/CheckIdStep'
import RespondStep from './steps/RespondStep'
import SearchFindStep from './steps/SearchFindStep'
import { Steps } from 'antd'
//import ReviewRedactStep from './steps/ReviewRedactStep'
import SendSecurelyStep from './steps/SendSecurelyStep'
import CompleteStep from './steps/CompleteStep'
import { connect } from 'react-redux'
import { getSmartBoxFilesFilter } from '../../../api/SmartBox'
import {
  setSmartBoxNotSarTracker
} from "../../../actions/smartBoxActions";
import CustomNotificationManager from "../../Common/CustomNotificationManager";
import moment from "moment";
import withRouter from '../../../private-routers/withRouter'
import cookie from "react-cookies";

const { Step } = Steps

const SarStepsEnum = {
  INITIAL_REQUEST: 0,
  ACKNOWLEDGE: 1,
  ACKNOWLEDGE_UNCOMPLETED: 1,
  CHECK_ID: 2,
  RESPOND: 3,
  SEARCH_FIND_ADD_DOCUMENTS: 4,
  //REVIEW_AND_REDACT: 5,
  SEND_SECURELY: 5,
  COMPLETE: 6
}

class Tracker extends React.Component {
  constructor() {
    super()

    this.state = {
      checked: false,
      sarRequest: {},
      initialRequestStep: {},
      acknowledgeStep: {},
      checkidStep: {},
      respondStep: {},
      searchFindStep: {},
      reviewStep: {},
      sendStep: {},
      completeStep: {},
      tableListFile: [],
      current: 0,
      searchText: '',
      listSarBox: [],
      valueAttachBox: '',
      listFilesOnBox: [],
      onSendStatusStatusReturnApi: '',
      valueAttachBoxId: '',
      statusResponseImport: '',
      setIntervalImport: 0,
      boxNameImport: '',
      responseCheckImport: false,
      dataImportBox: {},
      acknowledgeUncompletedStep: {}
    }

    this.getSarInfo = this.getSarInfo.bind(this)
    this.refreshSarData = this.refreshSarData.bind(this)
    this.setValues = this.setValues.bind(this)
  }

  componentDidMount() {
    const id = this.props.params.sarId
    this.getSarInfo(id)
    this.fetchFilesBox()
    cookie.remove('checkImportBox', { path: '/' })
  }

  getSarInfo = async(id) => {
    const response = await apiSarSteps(id)
    this.setValues(response.data)
  }
  fetchFilesBox = () => {
    const { searchText } = this.state
    const initFilter = {
      docFilename: searchText,
      docFolderPath: null
    }
    getSmartBoxFilesFilter(initFilter).then((res) => {
      this.setState({
        listSarBox: res.data
      })
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  setValues(sarRequest) {
    let initialRequestStep, acknowledgeStep, checkidStep, respondStep, searchFindStep, reviewStep, sendStep,
      completeStep, acknowledgeUncompletedStep
    sarRequest && sarRequest.sarSteps && sarRequest.sarSteps.forEach((item) => {
      switch (item.status) {
        case 'INITIAL_REQUEST' :
          initialRequestStep = item
            let linkVisited = []
            let linkSubmitted = []
             if (sarRequest.linkVisited && sarRequest.linkVisited.length > 0) {
              const notRequestVisited = [{
                nameActionEvent: 'Request form link visited',
                descriptionActionEvent: `${sarRequest.forename}`,
                timesEvent: moment(sarRequest.linkVisited[0]).format('DD/MM/YYYY HH:mm:ss'),
                status: 'update-sar'
              }]
              const accessRequest = []
              accessRequest.push(notRequestVisited)
              linkVisited = notRequestVisited.concat(this.props.smartBoxNotSarTracker)
              const uniqueLinkVisited = linkVisited && linkVisited.length > 0 && linkVisited.filter((a, i) => linkVisited.findIndex((s) => a.nameActionEvent === s.nameActionEvent) === i)
              this.props.setSmartBoxNotSarTracker(uniqueLinkVisited)
             }
          if (sarRequest.acknowledgeSubmitted && sarRequest.acknowledgeSubmitted.length > 0) {
            const notRequestSubmitted = [{
              nameActionEvent: 'Request form submitted',
              descriptionActionEvent: `${sarRequest.forename}`,
              timesEvent: moment(sarRequest.acknowledgeSubmitted[0]).format('DD/MM/YYYY HH:mm:ss'),
              status: 'update-sar'
            }]
            linkSubmitted = notRequestSubmitted.concat(this.props.smartBoxNotSarTracker)
            const uniqueLinkSubmitted = linkSubmitted && linkSubmitted.length > 0 && linkSubmitted.filter((a, i) => linkSubmitted.findIndex((s) => a.nameActionEvent === s.nameActionEvent) === i)
            this.props.setSmartBoxNotSarTracker(uniqueLinkSubmitted)
          }
          break
        case 'ACKNOWLEDGE' :
          acknowledgeStep = item
          break
        case 'ACKNOWLEDGE_UNCOMPLETED' :
          acknowledgeUncompletedStep = item
          break
        case 'CHECK_ID' :
          checkidStep = item
          break
        case 'RESPOND' :
          respondStep = item
          break
        case 'SEARCH_FIND_ADD_DOCUMENTS' :
          searchFindStep = item
          break
        // case 'REVIEW_AND_REDACT' :
        //   reviewStep = item
        //   break
        case 'SEND_SECURELY' :
          sendStep = item
          break
        case 'COMPLETE' :
          completeStep = item
          break
        default :
          return 0
      }
    })
    this.setState({
      sarRequest: sarRequest,
      initialRequestStep: initialRequestStep,
      acknowledgeStep: acknowledgeStep,
      checkidStep: checkidStep,
      respondStep: respondStep,
      searchFindStep: searchFindStep,
      reviewStep: reviewStep,
      sendStep: sendStep,
      completeStep: completeStep,
      current: SarStepsEnum[sarRequest.lastStep],
      lastStep: sarRequest.lastStep,
      acknowledgeUncompletedStep: acknowledgeUncompletedStep
    })
  }

  refreshSarData(data) {
    this.setValues(data)
  }

  onChange = (current) => {

    this.setState({ current })
  };

  customDot = (dot, { index, status }) => {
    switch (index) {
      case 0: {
        return <span className={ status === 'finish' ? 'icon-v2-request finish-step' : 'icon-v2-request' }></span>
      }
      case 1: {
        return <span className={ status === 'finish' ? 'icon-v10-acknowledged finish-step' : 'icon-v10-acknowledged'}></span>
      }
      case 2: {
        return <span className={ status === 'finish' ? 'icon-v2-checkid finish-step' : 'icon-v2-checkid'}></span>
      }
      case 3: {
        return <span className={ status === 'finish' ? 'icon-v10-rights-1 finish-step' : 'icon-v10-rights-1' }></span>
      }
      case 4: {
        return <span className={ status === 'finish' ? 'icon-v10-data finish-step' : 'icon-v10-data' }></span>
      }
      case 5: {
        return <span className={ status === 'finish' ? 'icon-v2-email finish-step' : 'icon-v2-email' }></span>
      }
      case 6: {
        return <span className= { status === 'finish' ? 'icon-v10-completed finish-step' : 'icon-v10-completed' }></span>
      }
      // case 5: {
      //   return <span className={ status === 'finish' ? 'icon-v2-redact finish-step' : 'icon-v2-redact' }></span>
      // }
      // case 6: {
      //   return <span className= { status === 'finish' ? 'icon-v2-email finish-step' : 'icon-v2-email' }></span>
      // }
      // case 7: {
      //   return <span className={ status === 'finish' ? 'icon-v2-finished finish-step' : 'icon-v2-finished' }></span>
      // }
      default:
        return <span></span>
    }

  }

  generateClass = (step, currentIndex) => {
    const { current } = this.state
    const { lastStep } = this.state
    // if (lastStep === 'REVIEW_AND_REDACT') {
    //   lastStep = 'SEARCH_FIND_ADD_DOCUMENTS'
    // }
    if (lastStep === step) {
      return 'active-step'
    } else if ((step === 'ACKNOWLEDGE' && lastStep === 'ACKNOWLEDGE_UNCOMPLETED')) {
      return 'active-step'
    } else if (current === currentIndex) {
      return 'selected-step'
    }
    return ''

  }

  generateStatus = (step) => {
    if (step) {
      if (step.completed) {
        return 'finish'
      } 
      return 'wait'
    }
    return 'wait'
  }

  nextStep = (nextStep) => {
    this.setState({
      current: nextStep
    })
  }
  beckStep = (backStep) => {
    this.setState({
      current: backStep
    })
  }
  onCallImportFilesData = (treeData, treeSelectedFiles, modeChooseFiles) => {
    //const { tableListFile } = this.state
    if (modeChooseFiles === 'all-files') {
      this.setState({
        tableListFile: treeData
      })
    } if (modeChooseFiles === 'choose-files') {
      this.setState({
        tableListFile: treeSelectedFiles
      })
    }
  }
  onDocumentsFoundData = (stateFound) => {
    if (!stateFound) {
      this.setState({
        valueAttachBox: ''
      })
    }

  }

  setIntervalCheckApiDownload = () => {
    let { setIntervalImport, responseCheckImport } = this.state
    //if (setIntervalImport === 0) {
      if (!responseCheckImport) {
        setIntervalImport = setInterval(this.callBackOnCheckImportData, 4000)
        this.setState({
          setIntervalImport
        })
      } else if (responseCheckImport) {
        clearInterval(setIntervalImport)
      }
    //}
  }

  callBackOnCheckImportData = () => {
    const { sarRequest, listSarBox, responseCheckImport, boxNameImport, setIntervalImport } = this.state
    let idBox = ''
    listSarBox && listSarBox.length > 0 && listSarBox.forEach((item) => {
      if (boxNameImport === item.name) {
        idBox = item.id
      }
    })
    const data = {
      sarId: sarRequest.id,
      boxName: boxNameImport,
     // boxId: Number(idBox)
      boxId: idBox
    }
    const itemCheckImport = cookie.load('checkImportBox', { path: '/' })
    if (!responseCheckImport && itemCheckImport === 'start') {
      apiSarCheckImportBox(data).then((response) => {
        if (!response.data) {
          CustomNotificationManager.info('Files are being imported in background! Please wait...')
        }
        if (response.data === true) {
          this.onImportDataBoxSar(boxNameImport)
          clearInterval(setIntervalImport)
          this.setState({setIntervalImport})
          cookie.remove('checkImportBox', { path: '/' })
        } else {
          cookie.save('checkImportBox', 'start', {path: '/'})
          this.setState({setIntervalImport})
        }
        this.setState({
          responseCheckImport: response.data,
          valueAttachBox: boxNameImport,
        })
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    } else if (responseCheckImport) {
      clearInterval(setIntervalImport)
      this.setState({ setIntervalImport })
    }
  }

  onClickImportBox = (boxName) => {
    const { sarRequest, listSarBox, responseCheckImport, setIntervalImport } = this.state
    let idBox = ''
    listSarBox && listSarBox.length > 0 && listSarBox.forEach((item) => {
      if (boxName === item.name) {
        idBox = item.id
      }
    })
    const data = {
      sarId: sarRequest.id,
      boxName: boxName,
     // boxId: Number(idBox)
      boxId: idBox
    }
    this.setState({
      boxNameImport: boxName
    })
    const itemCheckImport = cookie.load('checkImportBox', { path: '/' })
    if (!responseCheckImport) {
      apiSarCheckImportBox(data).then((response) => {
        this.setState({
          responseCheckImport: response.data,
          valueAttachBox: boxName,
        })
        if (!response.data) {
          CustomNotificationManager.info('Files are being imported in background! Please wait...')
          cookie.save('checkImportBox', 'start', {path: '/'})
        }
        if (response.data === false && responseCheckImport === false) {
          this.setIntervalCheckApiDownload()
        }
        else if (response.data === true) {
          this.onImportDataBoxSar(boxName)
          clearInterval(setIntervalImport)
          this.setState({setIntervalImport})
          cookie.remove('checkImportBox', { path: '/' })
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    } else {
      clearInterval(setIntervalImport)
      this.setState({setIntervalImport})
    }
  }

  onImportDataBoxSar = (boxName) => {
    const { sarRequest, listSarBox } = this.state
    let idBox = ''
    listSarBox && listSarBox.length > 0 && listSarBox.forEach((item) => {
      if (boxName === item.name) {
        idBox = item.id
      }
    })
    const data = {
      sarId: sarRequest.id,
      boxName: boxName,
     // boxId: Number(idBox),
      boxId: idBox,
      pageNr: 1,
      pageRows: 10,
      approvedFilesOnly: false
    }
      apiSarStepsImportBox(data).then((response) => {
        //const statusImport = response.data.status
        if (response.status === 200) {
          this.setState({
            valueAttachBox: response.data.boxName,
            listFilesOnBox: response.data.files,
            valueAttachBoxId: response.data.boxId,
            responseCheckImport: false,
            dataImportBox: response.data
          })
        }
        this.setState({
          onSendStatusStatusReturnApi: response.status,
          statusResponseImport: response.data.status
        })
      }).catch((res) => {
        this.setState({
          onSendStatusStatusReturnApi: res.response.status
        })
        if (res.response.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }
      })
  }
  render() {
    const {
      initialRequestStep,
      acknowledgeStep,
      checkidStep,
      respondStep,
      searchFindStep,
      sendStep,
      completeStep,
      current,
      tableListFile,
      listSarBox,
      valueAttachBox,
      listFilesOnBox,
      onSendStatusStatusReturnApi,
      valueAttachBoxId,
      statusResponseImport,
      dataImportBox,
      acknowledgeUncompletedStep,
      responseCheckImport
    } = this.state
    const { redactingMode } = this.props
    return (
      <div>
        {this.state.sarRequest &&
          <div className="tracker-page new-tracker-page" style={{ display: redactingMode ? 'flex' : 'block', padding: redactingMode ? 0 : '10px', marginBottom: redactingMode ? 0 : '40px' }}>
            {!redactingMode &&
            <div className="tracker-box tracker-header-step">
              <Steps progressDot={this.customDot} current={current} onChange={this.onChange}>
                <Step title="REQUEST" status={this.generateStatus(initialRequestStep)}
                  className={this.generateClass('INITIAL_REQUEST', 0)}/>
                <Step title="ACKNOWLEDGE" status={this.generateStatus(acknowledgeStep)}
                  className={this.generateClass('ACKNOWLEDGE', 1)}
                  disabled={ !initialRequestStep || (initialRequestStep && !initialRequestStep.completed) || (!acknowledgeStep && !acknowledgeUncompletedStep)  }
                />
                <Step title="ID CHECK" status={this.generateStatus(checkidStep)}
                  className={this.generateClass('CHECK_ID', 2)}
                  disabled={ !acknowledgeStep || (acknowledgeStep && !acknowledgeStep.completed)}/>
                <Step title="RIGHTS" status={this.generateStatus(respondStep)}
                  className={this.generateClass('RESPOND', 3)}
                  disabled={ !checkidStep || (checkidStep && !checkidStep.completed) || (checkidStep && checkidStep.completed && !respondStep)}/>
                <Step title="DATA" status={this.generateStatus(searchFindStep)}
                  className={this.generateClass('SEARCH_FIND_ADD_DOCUMENTS', 4)}
                  disabled={ !respondStep || (respondStep && respondStep.completed && respondStep.respondAnswer === false) || (respondStep && !respondStep.completed)}/>
                <Step title="SEND" status={this.generateStatus(sendStep)}
                  className={this.generateClass('SEND_SECURELY', 5)}
                  disabled={ !searchFindStep || (searchFindStep && !searchFindStep.completed) || (searchFindStep && searchFindStep.completed && !searchFindStep.documentsFound) }/>
                <Step title="COMPLETE" status={this.generateStatus(completeStep)}
                  className={this.generateClass('COMPLETE', 6)}
                  disabled={ (!sendStep && this.state.sarRequest.lastStep !== 'COMPLETE')
                  //|| (!sendStep && respondStep && respondStep.respondAnswer === true )
                  || (!sendStep && respondStep && !respondStep.completed && respondStep.respondAnswer !== false) || (sendStep && !sendStep.completed)}/>
                {/*<Step title="REDACT" status={this.generateStatus(respondStep)}*/}
                {/*  className={this.generateClass('REVIEW_AND_REDACT', 5)}/>*/}
                {/*<Step title="SEND" status={this.generateStatus(sendStep)}*/}
                {/*  className={this.generateClass('SEND_SECURELY', 6)} />*/}
                {/*<Step title="COMPLETE" status={this.generateStatus(completeStep)}*/}
                {/*  className={this.generateClass('COMPLETE', 7)}/>*/}
              </Steps>
            </div>
            }
            {current === 0 && <InitialRequestStep initialRequestStep={initialRequestStep} acknowledgeStep={acknowledgeStep} sar={this.state.sarRequest}
              refreshValues={this.refreshSarData} current={current} nextStep={this.nextStep} beckStep={this.beckStep} 
              valueAttachBox={valueAttachBox}/>
            }
            {current === 1 && <AcknowledgeStep initialStep={initialRequestStep} acknowledgeStep={acknowledgeStep}
              sar={this.state.sarRequest} current={current} nextStep={this.nextStep} beckStep={this.beckStep}
              refreshValues={this.refreshSarData} checkidStep={checkidStep}
              valueAttachBox={valueAttachBox}/>
            }
            {current === 2 &&
            <CheckIdStep acknowledgeStep={acknowledgeStep} checkidStep={checkidStep} sar={this.state.sarRequest}
              refreshData={this.refreshSarData} current={current} nextStep={this.nextStep} beckStep={this.beckStep}
              valueAttachBox={valueAttachBox} respondStep={respondStep} acknowledgeUncompletedStep={acknowledgeUncompletedStep} />
            }
            {current === 3 &&
            <RespondStep checkIdStep={checkidStep} respondStep={respondStep} sar={this.state.sarRequest}
              refreshData={this.refreshSarData} current={current} nextStep={this.nextStep} beckStep={this.beckStep}
              valueAttachBox={valueAttachBox} searchFindStep={ searchFindStep } />
            }
            {current === 4 && <SearchFindStep respondStep={respondStep} searchFindStep={searchFindStep} listSarBox={listSarBox}
              sar={this.state.sarRequest} refreshData={this.refreshSarData} current={current} nextStep={this.nextStep} beckStep={this.beckStep}
              onClickImportBox={this.onClickImportBox} onImportDataBoxSar={this.onImportDataBoxSar} valueAttachBox={valueAttachBox} listFilesOnBox={ listFilesOnBox }
              onCallImportFilesData={this.onCallImportFilesData} tableListFile={tableListFile} onDocumentsFoundData={this.onDocumentsFoundData}
              onSendStatusStatusReturnApi={onSendStatusStatusReturnApi} valueAttachBoxId={valueAttachBoxId} statusResponseImport={statusResponseImport}
              sendStep={sendStep} dataImportBox={dataImportBox}/>
            }
            {current === 5 && <SendSecurelyStep searchFindStep={searchFindStep} valueAttachBoxId={valueAttachBoxId} sendStep={sendStep} sar={this.state.sarRequest}
              refreshData={this.refreshSarData} current={current} nextStep={this.nextStep} beckStep={this.beckStep}
              valueAttachBox={valueAttachBox} completeStep={completeStep} />
            }
            {current === 6 &&
              <CompleteStep
                sendStep={sendStep}
                completeStep={completeStep}
                sar={this.state.sarRequest}
                refreshData={this.refreshSarData}
                current={current}
                nextStep={this.nextStep}
                beckStep={this.beckStep}
                valueAttachBox={valueAttachBox}
                searchFindStep={searchFindStep}
                respondStep={respondStep}
                checkIdStep={checkidStep}
                initialRequestStep={initialRequestStep}
                acknowledgeStep={acknowledgeStep}
              />
            }
            {/*{current === 5 && <ReviewRedactStep searchFindStep={searchFindStep} reviewStep={reviewStep}*/}
            {/*  sar={this.state.sarRequest} refreshData={this.refreshSarData}/>*/}
            {/*}*/}
            {/*{current === 6 && <SendSecurelyStep reviewStep={reviewStep} sendStep={sendStep} sar={this.state.sarRequest}*/}
            {/*  refreshData={this.refreshSarData} current={current} nextStep={this.nextStep} beckStep={this.beckStep}/>*/}
            {/*}*/}
            {/*{current === 7 && <CompleteStep sendStep={sendStep} completeStep={completeStep} sar={this.state.sarRequest}*/}
            {/*  refreshData={this.refreshSarData} current={current} nextStep={this.nextStep} beckStep={this.beckStep}/>*/}
            {/*}*/}
          </div>
        }
      </div>
    )
  }
}

Tracker = withRouter(Tracker)
const mapStateToProps = (state) => ({
  redactingMode: !state.shellReducer.sidebarVisible && !state.shellReducer.headerVisible && !state.shellReducer.footerVisible,
  smartBoxNotSarTracker: state.smartBox.smartBoxNotSarTracker,
})
export default connect(mapStateToProps, {
  setSmartBoxNotSarTracker
})(Tracker)