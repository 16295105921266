import React from 'react'
import PropTypes from 'prop-types'
import {Icon} from 'antd'
//import Icon from '@ant-design/icons'
import { generateContent } from '../../../../utils/AssetRegister/AssetRegisterHelperMethods'
import { customConfirm } from '../../../../utils/AssetRegister/CustomConfirm'
import CollapseContent from './CollapseContent'
import { updateComplianceDocument } from '../../../../api/DocLocker'

class LibraryTable extends React.Component {
  state = {
    libraryCollection: {},
    updateComplianceDocTabActive: false,
    deleteComplianceDocTabActive: false,
    findComplianceDocTabActive: false,
    viewRowId: 'null',
    visibleCollapseLocker: {}
  }
  
  componentDidMount() {
    if (this.props.libraryCollection) {
      this.setState({
        libraryCollection: this.props.libraryCollection
      })
    }
  }
  
  updateComplianceDoc = (value, columns) => {
    const index = columns.findIndex((item) => item.type === 'FILE')
    customConfirm({
      confirmAction: () => this.triggerUploadClick(value.id),
      title: 'DO YOU WANT TO UPDATE',
      content: `${ Object.values(value)[index] } ?`,
      confirmButtonText: 'UPDATE',
      cancelButtonText: 'CANCEL',
      mask: true,
      maskClosable: false,
      closeHaveLogic: false,
      className: 'asset-register-modal',
      centered: true,
      cancelClassName: 'cancel-button',
      confirmClassName: 'confirm-button',
      okType: 'file'
    })
  }

  triggerUploadClick = (id) => {
    document.querySelector(`#update-action-${ id }`).click()
  }

  updateComplianceDocLocker = (key, visibleCollapseLocker, register, columns, file) => {
    this.props.sentValueUpload(true)
    const columnIndex = columns.findIndex((column) => column.name === 'VERSION')
    const data = {
      id: register.id,
      version: register[columns[columnIndex].id],
      file
    }
    updateComplianceDocument(data).then((res) => {
      this.setState({
        visibleCollapseLocker: {
          ...this.state.visibleCollapseLocker, [key]: !visibleCollapseLocker
        }
      }, () => this.props.sentValueUpload(false))
    })
  }

  deleteComplianceDoc = (value, columns, libraryId) => {
    const index = columns.findIndex((item) => item.type === 'FILE')
    customConfirm({
      confirmAction: () => this.props.deleteComplianceDoc(value, libraryId),
      title: 'DO YOU WANT TO DELETE',
      content: `${ Object.values(value)[index] } ?`,
      confirmButtonText: 'DELETE',
      cancelButtonText: 'CANCEL',
      mask: true,
      maskClosable: false,
      closeHaveLogic: false,
      className: 'asset-register-modal',
      centered: true,
      cancelClassName: 'cancel-button',
      confirmClassName: 'confirm-button'
    })
  }

  onClickOpenCollapse = (key, visibleCollapseLocker) => {
    this.setState({
      visibleCollapseLocker: {
        ...this.state.visibleCollapseLocker, [key]: !visibleCollapseLocker
      }
    })
  }

  setNewVersion = (recordId, record) => {
    const { values } = { ...this.state.libraryCollection }
    const valueIndex = values.findIndex((record) => record.id === recordId)
    values[valueIndex] = record
    this.setState({
      libraryCollection: {
        ...this.state.libraryCollection, values
      }
    })
  }

  render() {
    const { libraryCollection } = this.state
    const { deleteComplianceDocTabActive, updateComplianceDocTabActive, findComplianceDocTabActive } = this.props
    return (
      <React.Fragment>
        <div className="table-title">{ libraryCollection.library ? libraryCollection.library.name : '' }</div>
        <div className="compliance-page__content__table">
          <div className="tr th table-header">
            {
              libraryCollection.library && libraryCollection.library.columns && libraryCollection.library.columns.length > 0 &&
              libraryCollection.library.columns.map((column, index) => {
                return (
                  <div className="td" key={ index }>
                    <span>{ column.name }</span>
                  </div>
                )
              })
            }
            <div className="td header-actions">
              {
                findComplianceDocTabActive &&
                <React.Fragment>
                  <span className="icon-edit-b" onClick={() => this.props.setUpdateComplianceDocActive(updateComplianceDocTabActive)}/>
                  <span className="icon-delete-b" onClick={() => this.props.setDeleteComplianceDocActive(deleteComplianceDocTabActive)}/>
                </React.Fragment>
              }
            </div>
          </div>
          {
            libraryCollection.values && libraryCollection.values.length > 0 &&
            libraryCollection.values.map((value, index) => {
              return (
                <React.Fragment key={ index }>
                  <div className="tr table-row" key={ index } id={value.id}>
                    {
                      libraryCollection.library && libraryCollection.library.columns && libraryCollection.library.columns.length > 0 && libraryCollection.library.columns.map((column2, index2) => {
                        return (
                          value[column2.id] !== 'id' &&
                            <div className="td" style={ { justifyContent: 'flex-start' } } key={ index2 }>
                              { generateContent(value[column2.id], column2) }
                            </div>
                        )
                      })
                    }
                    <div className="td actions" style={ { justifyContent: 'center', flexGrow: 0.5 } }>
                      { deleteComplianceDocTabActive &&
                        <span className="icon-delete-b"
                          onClick={ () => this.deleteComplianceDoc(value, libraryCollection.library.columns, libraryCollection.library.id) }/>
                      }
                      { updateComplianceDocTabActive &&
                        <React.Fragment>
                          {
                            !this.state.visibleCollapseLocker[index] ? <span className="icon-update-b-1"
                              onClick={ () => this.updateComplianceDoc(value, libraryCollection.library.columns, libraryCollection.library.id, index, this.state.visibleCollapseLocker[index]) }/> :
                              <span><Icon type="close"
                                onClick={ () => this.onClickOpenCollapse(index, this.state.visibleCollapseLocker[index]) }/></span>
                          }
                        </React.Fragment>
                      }
                      { !deleteComplianceDocTabActive && !updateComplianceDocTabActive &&
                        <span><Icon type={this.state.visibleCollapseLocker[index] ? 'close' : 'down'} onClick={() => this.onClickOpenCollapse(index, this.state.visibleCollapseLocker[index])}/></span>
                      }
                    </div>

                  </div>
                  <div className="table-row__accordion-container">
                    { this.state.visibleCollapseLocker[index] &&
                        <CollapseContent historyUpdate={ libraryCollection.library.historyUpdate }
                          additionalFields={ libraryCollection.library.additionalFields }
                          documentId={value.id}
                          columns={libraryCollection.library.columns}
                          record={value}
                          setNewVersion={ this.setNewVersion }
                        />
                    }
                    <input type="file" id={`update-action-${ value.id }`} onChange={(e) => this.updateComplianceDocLocker(index, this.state.visibleCollapseLocker[index], value, libraryCollection.library.columns, e.target.files[0])} style={{ display: 'none' }}/>
                  </div>
                </React.Fragment>
              )
            }
            )
          }
        </div>
      </React.Fragment>
    )
  }
}

LibraryTable.propTypes = {
  libraryCollection: PropTypes.object.isRequired,
  updateComplianceDocTabActive: PropTypes.bool.isRequired,
  deleteComplianceDocTabActive: PropTypes.bool.isRequired,
  findComplianceDocTabActive: PropTypes.bool.isRequired,
  deleteComplianceDoc: PropTypes.func.isRequired,
  setUpdateComplianceDocActive: PropTypes.func.isRequired,
  setDeleteComplianceDocActive: PropTypes.func.isRequired,
}

export default LibraryTable