import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'
import { compose } from 'redux'
import * as actions from '../../actions/userActions'
import Footer from './Footer'
import Header from './Header'
import Loading from './Loading'
import PrivacyPolicy from './PrivacyPolicy'
import './Shell.css'
import TermsOfService from './TermsOfService'
import { removeAllIntervalsFromApplication } from '../../actions/applicationsActions';
import withRouter from '../../private-routers/withRouter'

export class Shell extends Component {
  constructor() {
    super()

    this.state = {
      isOpenedPrivacy: false,
      isOpenedTerms: false,
    }

    this.hidePrivacyPolicy = this.hidePrivacyPolicy.bind(this)
    this.hideTermsOfService = this.hideTermsOfService.bind(this)
    this.showPrivacyPolicy = this.showPrivacyPolicy.bind(this)
    this.showTermsOfService = this.showTermsOfService.bind(this)
  }

  componentDidMount() {
    // const accessToken = localStorage.getItem('accessToken')
    let retrieveToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId
    if (!retrieveToken) {
      if (this.props && this.props.applicationIntervals) for (let i = 0; i < this.props.applicationIntervals.length; i++) { clearInterval(this.props.applicationIntervals[i]) }
      
      this.props.removeAllIntervalsFromApplication([])
      return this.props.userLogout()
    }
    
    if (!this.props.user) {
      this.props.fetchUserInfo()
    }
  }

  hidePrivacyPolicy() {
    this.setState({
      isOpenedPrivacy: false
    })
  }

  hideTermsOfService() {
    this.setState({
      isOpenedTerms: false
    })
  }

  showPrivacyPolicy() {
    this.setState({
      isOpenedPrivacy: true
    })
  }

  showTermsOfService() {
    this.setState({
      isOpenedTerms: true
    })
  }
  
  render() {
    const { isLoading, isLogged, user, headerVisible, footerVisible, subscription } = this.props
    const { isOpenedPrivacy, isOpenedTerms,} = this.state
    const pageWithoutFooter=[
      '/osp/user/settings','/company/settings','/osp/invitations',
      '/osp/multitenancy-invitations','/data-mapping/connectors','/data-mapping/source-mapping',
      '/data-mapping/business-process', '/sar/workflow/forms','/sar/workflow/templates',
      '/sar/workflow/builder','/sar/workflow/template-workflow','/osp/manage/users','/sar/dashboard', '/data-mapping/api-connector',
        '/sar/workflow/workflow-dashboard'
    ]
    const footerIsNotVisible = !pageWithoutFooter.includes(this.props.location.pathname) &&
        !this.props.location.pathname.includes("/osp/manage/users/edit/") &&
        !this.props.location.pathname.includes("/sar/settings/annotation")

    return (
      <div className="commonShell-page">
        { !isLoading &&
          !isLogged &&
          <Navigate to="/osp/login" />
        }

        { isOpenedPrivacy &&
          <PrivacyPolicy hidePrivacyPolicy={ this.hidePrivacyPolicy } />
        }

        { isOpenedTerms &&
          <TermsOfService hideTermsOfService={ this.hideTermsOfService } />
        }

        <div className="commonShell-main">
          { !isLoading &&
            isLogged &&
            user &&
            headerVisible &&
            <Header
              user={ user }
              subscription={subscription}
              toggleSidebar={ this.toggleSidebar }
              location={this.props.location}/>
          }
          
          { (isLoading || !user) &&
            <div className="commonShell-loading">
              <Loading />
            </div>
          }

          { isLogged &&
            !isLoading &&
            user &&
            <div className="commonShell-content">
              <Outlet />
            </div>
          }

          { isLogged &&
            user &&
            footerVisible &&
            footerIsNotVisible &&
            // this.props.location.pathname !== '/osp/user/settings' &&
            // !this.props.location.pathname.includes("/osp/manage/users/edit/") &&
            // !this.props.location.pathname.includes("/sar/settings/annotation") &&
            // this.props.location.pathname !== '/company/settings' &&
            // this.props.location.pathname !== '/osp/invitations' &&
            // this.props.location.pathname !== '/osp/multitenancy-invitations' &&
            // this.props.location.pathname !== '/data-mapping/connectors' &&
            // this.props.location.pathname !== '/data-mapping/source-mapping' &&
            // this.props.location.pathname !== '/data-mapping/business-process' &&
            // this.props.location.pathname !== '/sar/workflow/forms' &&
            // this.props.location.pathname !== '/sar/workflow/templates' &&
            // this.props.location.pathname !== '/sar/workflow/builder' &&
            // this.props.location.pathname !== '/sar/workflow/template-workflow' &&
            // this.props.location.pathname !== '/osp/manage/users' &&
            // this.props.location.pathname !== '/sar/dashboard' &&
            <Footer
              showPrivacyPolicy={ this.showPrivacyPolicy }
              showTermsOfService={ this.showTermsOfService }
            />
          }
        </div>
      </div>
    )
  }
}

const stateMap = (state) => ({
  isLoading: state.shellReducer.shell.loading,
  isLogged: state.userReducer.isLogged,
  user: state.userReducer.user,
  subscription: state.subscriptionReducer.subscription,
  sidebarVisible: state.shellReducer.sidebarVisible,
  headerVisible: state.shellReducer.headerVisible,
  footerVisible: state.shellReducer.footerVisible,
  applicationIntervals: state.applications.applicationIntervals,
  smartBoxProjects: state.smartBox.smartBoxProjects
})

const dispatchMap = (dispatch) => ({
  fetchUserInfo: () => dispatch(actions.fetchUserInfo()),
  userLogout: () => dispatch(actions.userLogout()),
  removeAllIntervalsFromApplication
})

export default withRouter(compose(connect(stateMap, dispatchMap))(Shell))
 