import React from 'react'
import PropTypes from 'prop-types'
import { Progress } from 'antd'

class Statistic extends React.Component {

  constructor() {
    super()
    this.state = {
    }
  }

  render() {
    const { title, percent } = this.props
    return (
      <div className="statistic">
        <p className="text-center">{ title }</p>
        <Progress
          type="circle"
          percent={ percent }
          status="active"
          strokeLinecap="square"
          strokeColor="#815dd7"
          strokeWidth={4}
          gapPosition={'top'}
          width={150}
        />
      </div>
    )
  }
}

Statistic.propTypes = {
  percent: PropTypes.number.isRequired
}

export default Statistic