import React from 'react'
import { Button, Select, Modal, Table } from 'antd'
import '../Admin.scss'
import './BrandingProfiles.scss'
import BrandingProfilesCreate from './BrandingProfilesCreate'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {Link} from "react-router-dom";
import {apiGetBrandingProfiles, apiGetBrandingProfilesChangePage, deleteBrandingProfile} from "../../../api/SmartBox";
import {customConfirm} from "../../../utils/AssetRegister/CustomConfirm";
import CustomNotificationManager from "../../Common/CustomNotificationManager";

const Option = Select.Option
const { Column } = Table

class BrandingProfiles extends React.Component {
    state = {
        classNameFind: 'find-inactive',
        classNameFindFocus: 'find-active',
        nameFind: '',
        // tableProfiles: [
        //     {key: 0, nameProfiles: 'Xerox'},
        //     {key: 1, nameProfiles: 'Gravicus'},
        // ],
        tableProfiles: [],
        hoverDelete: {},
        pageNumber: 1,
        selectColumnProfile: {},
        visibleProfileSettings: false,
        selectProfile: {},
        statusProfile: null,
        pageRows: 10
    }

    componentDidMount() {
       this.onGetBrandingProfiles()
    }

    onGetBrandingProfiles = () => {
        const { pageNumber, pageRows, nameFind} = this.state
        apiGetBrandingProfiles(pageNumber, pageRows, nameFind).then((response) => {
            this.setState({
                tableProfiles: response.data.brandingProfiles,
                totalElements: response.data.total,
            })
        })
    }
    updateFilterFind(e, type) {
        this.setState({
            nameFind: e.target.value
        }, () => {
            this.onGetBrandingProfiles()
        })
    }
    onHoverAddToken = (e, status, item) => {
        switch (status) {
            case 'delete':
                this.setState({
                    hoverDelete: item
                })
                break
            default: break
        }
    }
    onLeaveAddToken = () => {
        this.setState({
            hoverDelete: {},
        })
    }
    showTotalPages = (total) => {
        const { pageNumber } = this.state
        return (
            <div className="total-elements">
        <span className="pagination__box__header__total">
          <strong>{total}</strong> results
        </span>
                <span className="pagination__box__header__text">
              Page {pageNumber} of {Math.ceil(total / 10)}
        </span>
            </div>
        )
    }
    onSorterColumnsProfile = (e, column, sorter) => {
        const selectCol = {
            column: column,
            sorter: sorter
        }
        this.setState({
            selectColumnProfile: selectCol
        })
    }
    onCreateModifyProfile = (e, status, record) => {
        if (status === 'modify') {
            this.setState({
                selectProfile: record
            })
        } else {
            this.setState({
                selectProfile: {}
            })
        }
        this.setState({
            visibleProfileSettings: true,
            statusProfile: status,
        })
    }
    onGetDiscardChange = () => {
       this.setState({
           visibleProfileSettings: false
       }, () => {
           this.onGetBrandingProfiles()
       })
    }
    onGetCreateBrandingProfile = (listPrimaryColour, listSecondaryColour, valueInputProfile) => {

    }
    showConfirmDeleteWorkflow = (e, record) => {
        customConfirm({
            title: <span className="icon-v14-delete"></span>,
            content: `Delete branding profile?`,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            mask: true,
            maskClosable: false,
            closeHaveLogic: false,
            className: 'asset-register-modal modal-delete-connector',
            centered: true,
            cancelClassName: 'cancel-button',
            confirmClassName: 'confirm-button',
            confirmAction: () => {
                this.onDeleteBrandingProfile(e, record)
            }
        })
    }
    onDeleteBrandingProfile = (e, record) => {
        deleteBrandingProfile(record.profileId).then((response) => {
            if(response.data.title.toLowerCase() === 'success') {
                CustomNotificationManager.success(response.data.message, 'Success')
            } else if(response.data.title.toLowerCase() === 'info') {
                CustomNotificationManager.info(response.data.message, 'Info')
            }
            this.onGetBrandingProfiles()
        })
    }
    onPageChange = (page, pageSize) => {
        this.setState({
            pageNumber: page
        }, () => {
            this.onGetBrandingProfiles()
        })
    }
    render() {
        const { classNameFind, classNameFindFocus, nameFind, tableProfiles, hoverDelete, pageNumber, selectColumnProfile,
            visibleProfileSettings, selectProfile, statusProfile, totalElements } = this.state
        return (
            <div className="branding-profiles">
                { visibleProfileSettings ?
                    <BrandingProfilesCreate onGetDiscardChange={this.onGetDiscardChange} selectProfile={ selectProfile }
                                            visibleProfileSettings={ visibleProfileSettings }
                                            onGetCreateBrandingProfile={ this.onGetCreateBrandingProfile }
                                            statusProfile={ statusProfile }/>
                    :
                    <div className="branding-profiles-contain">
                        <div className="branding-profiles-header">
                            <div className="header-find">
                                <label>Find</label>
                                <input className={nameFind ? (classNameFindFocus) : (classNameFind)}
                                onChange={(e) => this.updateFilterFind(e, 'name')}
                                onBlur={(e) => this.updateFilterFind(e, 'name')}/>
                            </div>
                        <div className="header-create-profile">
                            <Button icon="plus-circle"
                            onClick={(e) => this.onCreateModifyProfile(e, 'create')}>CREATE PROFILE</Button>
                        </div>
                        </div>
                        <div className="branding-profiles-table">
                            <Table
                                dataSource={tableProfiles}
                                className="table-branding"
                                rowKey = { (record) => { return `rowKey${ record.profileId }`} }
                                pagination={{
                                    onChange: this.onPageChange,
                                    pageSize: 10,
                                    showTotal: this.showTotalPages,
                                    className: 'annotation-pagination',
                                    showLessItems: true,
                                    total: totalElements
                                }}
                            >
                            <Column width="85%"
                            title= {<span className="content-header-column"><span>PROFILE NAME</span>
                            <span className="content-buttons-sorter">
                            <span className={selectColumnProfile && selectColumnProfile.column === 'name' && selectColumnProfile.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow"}
                            onClick={(e) => this.onSorterColumnsProfile(e, 'name', 'asc')}></span>
                            <span className={selectColumnProfile && selectColumnProfile.column === 'name' && selectColumnProfile.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold"}
                            onClick={(e) => this.onSorterColumnsProfile(e, 'name', 'desc')}></span>
                            </span>
                            </span>}
                            dataIndex="profileName" key="profileName" render={(text, record) => {
                            return (<span>{text}</span>)
                        }}
                            />
                            <Column width="10%" title="ACTION" dataIndex="action" key="action" render={(text, record) => {
                            return (<span>
                            <Button className="button-modify"
                            onClick={(e) => this.onCreateModifyProfile(e, 'modify', record)}>MODIFY</Button>
                            </span>)
                        }}
                            />
                            <Column width="5%" title="" dataIndex="delete" key="delete" render={(text, record) => {
                            return (<span>
                            <span className={hoverDelete.profileName === record.profileName ? "icon-v19-delete-active" : 'icon-v19-delete'}
                            onMouseEnter={(e) => this.onHoverAddToken(e, 'delete', record)}
                            onMouseLeave={() => this.onLeaveAddToken()}
                                  onClick={(e) => this.showConfirmDeleteWorkflow(e, record)}
                            ></span>
                            </span>)
                        }}
                            />
                            </Table>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

const stateMap = (state) => ({
    user: state.userReducer.user
})

BrandingProfiles.propTypes = {
    user: PropTypes.object.isRequired
}

export default connect(stateMap)(BrandingProfiles)