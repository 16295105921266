import React from 'react'
import PropTypes from 'prop-types'

import {
  apiOspreyGetDataLines
} from '../../api/OspreyAnalytics.js'

const DataLinesContext = React.createContext()

class DataLinesProvider extends React.Component {
  state = {
    datalines: [],
    isFetchinDataLines: true,
  }

  getDataLines = () => {
    apiOspreyGetDataLines()
      .then((response) => {

        this.setState({
          datalines: response.data.value.crawlers.crawler,
          isFetchingDataLines: false
        })
      })
      .catch((error) => {
        console.log(error, 'error')
      })
  }

  render = () => {
    return (
      <DataLinesContext.Provider value={{
        datalines: this.state.datalines,
        getDataLines: this.getDataLines
      }}>
        { this.props.children }
      </DataLinesContext.Provider>
    )
  }
}

DataLinesProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export default DataLinesProvider
export const DataLinesConsumer = DataLinesContext.Consumer