import React from 'react'
import AddRegister from './AddRegister'
import PropTypes from 'prop-types'
import _ from 'lodash'

class ManageAssetRegister extends React.Component {
  state = {
    addRegisterActive: false,
    importExportActive: false,
    registerToEdit: {},
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.addRegisterOpened !== this.props.addRegisterOpened && this.props.addRegisterOpened === true) {
      this.setState({
        addRegisterActive: true
      })
    }

    if (prevProps.registerToEdit !== this.props.registerToEdit) {
      this.setState({
        registerToEdit: this.props.registerToEdit
      })
    }
  }

  setImportExportActive = (value) => {
    this.setState({
      importExportActive: value,
      addRegisterActive: false,
      registerToEdit: {}
    }, () => this.props.setImportExportActive(value))
  }

  closeAddEdit = () => {
    this.setState({
      addRegisterActive: false,
      registerToEdit: {}
    }, () => {
      this.props.setRegisterOpenedToFalse()
      this.props.fetchData()
    })
  }

  addRegisterActive = (addRegisterActive) => {
    this.setState({
      addRegisterActive: !addRegisterActive,
      importExportActive: false,
      registerToEdit: {}
    }, () => {
      if (this.state.addRegisterActive === false) {
        this.props.setRegisterOpenedToFalse()
      }
      this.props.setImportExportActive(false)
    })
  }

  closeAllTabs = () => {
    this.setState({
      importExportActive: false,
      addRegisterActive: false
    }, () => this.props.setImportExportActive(false))
  }

  render() {
    const { addRegisterActive, importExportActive, registerToEdit } = this.state
    return (
      <div className="settings-page__action-container">
        <div className="settings-page__action-container__header">
          <div className="title" onClick={() => this.closeAllTabs()}>
            MANAGE ASSET REGISTER
          </div>
          <div className="actions">
            <div className="add-tab" style={ this.state.addRegisterActive ? { color: 'black' } : {} } onClick={ () => this.addRegisterActive(addRegisterActive) }>
              <span className="icon-add-b" />
              <span>Add Register</span>
              {addRegisterActive && <i className="up custom-caret" />}
            </div>
            <div className="import-export" style={ this.state.importExportActive ? { color: 'black' } : {} } onClick={ () => this.setImportExportActive(!importExportActive) }>
              <span className="icon-export-b"/>
              <span>Import & Export</span>
            </div>
          </div>
        </div>
        { addRegisterActive &&
          <div className="settings-page__action-container__content">
            <AddRegister closeAddEdit={this.closeAddEdit} register={ registerToEdit || '' } updateMode={ !_.isEmpty(registerToEdit) }/>
          </div>
        }
      </div>
    )
  }
}

ManageAssetRegister.propTypes = {
  setImportExportActive: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  registerToEdit: PropTypes.object.isRequired,
  addRegisterOpened: PropTypes.bool,
  setRegisterOpenedToFalse: PropTypes.func
}

export default ManageAssetRegister