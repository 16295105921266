import React, { useEffect } from 'react'
import Icon from 'antd/es/icon'


const PageSidebarContent = ({ totalThumbnails, totalPages, canvasData, changePage, pageNumber, checkStartRedaction, onDefaultChange }) => {
  const [thumbnailList, setThumbnailList] = React.useState([])

  useEffect(() => {
    if (totalThumbnails) {
      const thumbnailList = []
      if (Object.keys(totalThumbnails).length) {
        for (const item of Object.entries(totalThumbnails)) {
          if (item.length > 1) {
            const temp = item[0].split(',')
            if (temp[1]) {
              const temporary = temp[1].split(':')
              const page = temporary[1].trim()
              const base64 = item[1]
              thumbnailList[page] = base64
            }
          }
        }
        setThumbnailList(thumbnailList)
      }
    }
  }, [totalThumbnails])

  const list = []
  if (thumbnailList && thumbnailList.length) {
    for (let i = 1; i <= totalPages; i++) {
      const thumbnail = thumbnailList[i - 1]
      list.push(
        <div
          className={ i === +pageNumber ?
            'previewpdf__container__item previewpdf__container__item--active' :
            'previewpdf__container__item' }
          data-pageid={ i } onClick={ checkStartRedaction ? () => onDefaultChange() : () => changePage(i, undefined, 'pageSidebar') } key={ i }>
          { canvasData[i] && canvasData[i].length > 0 ?
            <Icon type="file-protect" className="page-status" title="Redacted"/> :
            <Icon type="file-unknown" className={ 'page-status page-status--red' } title={ 'Not redacted' }/> }
          {thumbnail ?
            <img src={thumbnail} alt=""/> :
            <Icon className="fill-thumbnail-space no-thumbnail__icon" type="stop"/>
          }

          <span className="page-nr">page { i } </span>
        </div>
      )
    }
  } else {
    for (let i = 1; i <= totalPages; i++) {
      list.push(
        <div
          className={ i === +pageNumber ?
            'no-thumbnail no-thumbnail--active' :
            'no-thumbnail' }
          data-pageid={ i } onClick={ () => changePage(i, undefined, 'pageSidebar') } key={ i }>
          <Icon className= "no-thumbnail__icon" type="stop" />
          { canvasData[i] && canvasData[i].length > 0 ?
            <Icon type="file-protect" className="page-status" title="Redacted"/> :
            <Icon type="file-unknown" className={ 'page-status page-status--red' } title={ 'Not redacted' }/> }
          <span className="page-nr">page { i }</span>
        </div>
      )
    }
  }
  return list
}

export default PageSidebarContent
