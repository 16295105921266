import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'
import { compose } from 'redux'
import * as actions from '../../../actions/userActions'
import Loading from '../../Common/Loading'
import PrivacyPolicy from '../../Common/PrivacyPolicy'
import './Shell.scss'
import TermsOfService from '../../Common/TermsOfService'
import SideBar from '../SideBar'
import AdminHeader from './AdminHeader'
import { getScreenSize } from '../../../utils'
import { Drawer, Layout } from 'antd'

const { Content } = Layout
export class AdminShell extends Component {
  constructor() {
    super()

    this.state = {
      isOpenedPrivacy: false,
      isOpenedTerms: false,
      wrapSidebar: false
    }

    this.hidePrivacyPolicy = this.hidePrivacyPolicy.bind(this)
    this.hideTermsOfService = this.hideTermsOfService.bind(this)
    this.showPrivacyPolicy = this.showPrivacyPolicy.bind(this)
    this.showTermsOfService = this.showTermsOfService.bind(this)
    this.closeSidebar = this.closeSidebar.bind(this)
    this.toggleSidebar = this.toggleSidebar.bind(this)
  }
  componentDidMount() {
    // const accessToken = localStorage.getItem('accessToken')
    const tokenData = JSON.parse(localStorage.getItem('tokenData'))?.tokenId

    if (!tokenData) {
      return this.props.userLogout()
    }

    this.props.fetchUserInfo()

    if (getScreenSize() < 756) {
      this.setState({
        wrapSidebar: true
      })
    }
  }

  hidePrivacyPolicy() {
    this.setState({
      isOpenedPrivacy: false
    })
  }

  hideTermsOfService() {
    this.setState({
      isOpenedTerms: false
    })
  }

  showPrivacyPolicy() {
    this.setState({
      isOpenedPrivacy: true
    })
  }

  showTermsOfService() {
    this.setState({
      isOpenedTerms: true
    })
  }
  toggleSidebar() {
    this.setState({
      isSidebarOpen: !this.state.isSidebarOpen
    })
  }

  closeSidebar() {
    if (this.state.isSidebarOpen && getScreenSize() < 1200) {
      this.setState({
        isSidebarOpen: false
      })
    }
  }

  onClose = () => {
    this.setState({
      isSidebarOpen: false
    })
  }
  render() {
    const { isLoading, isLogged, user } = this.props
    const { isOpenedPrivacy, isOpenedTerms, isSidebarOpen, wrapSidebar } = this.state

    return (
      <Layout className="admin-page">
        {
          !isLoading &&
            !isLogged &&
            <Navigate to="/osp/login" />
        }

        { isOpenedPrivacy &&
          <PrivacyPolicy hidePrivacyPolicy={ this.hidePrivacyPolicy } />
        }

        { isOpenedTerms &&
          <TermsOfService hideTermsOfService={ this.hideTermsOfService } />
        }

        { !isLoading &&
          user &&
          !wrapSidebar &&
          <SideBar
            user={ user }
            isSidebarOpen={ isSidebarOpen }/>
        }

        { !isLoading &&
        user &&
        wrapSidebar &&
        <Drawer
          onClose={this.onClose}
          visible={isSidebarOpen}
          closable={ false }
          className="mobile-nav-bar"
          placement="left"
        >
          <SideBar
            user={ user }
            isSidebarOpen={ true }/>
        </Drawer>
        }

        <Layout className="admin-page__main">
          { isLogged &&
          user &&
          <AdminHeader
            user={ user }
            toggleSidebar={ this.toggleSidebar }
            mobileMode={ wrapSidebar }/>
          }
          { (isLoading || !user) &&
            <div className="commonShell-loading">
              <Loading />
            </div>
          }

          { isLogged &&
            !isLoading &&
            user &&
            <Content className="admin-page__main--content" onClick={ this.closeSidebar }>
              { this.props.children }
              <Outlet/>
            </Content>
          }

          {/*{ isLogged &&*/}
          {/*  user &&*/}
          {/*  <Footer*/}
          {/*    showPrivacyPolicy={ this.showPrivacyPolicy }*/}
          {/*    showTermsOfService={ this.showTermsOfService }*/}
          {/*  />*/}
          {/*}*/}
          
        </Layout>
      </Layout>
    )
  }
}

const stateMap = (state) => ({
  isLoading: state.shellReducer.shell.loading,
  isLogged: state.userReducer.isLogged,
  user: state.userReducer.user
})

const dispatchMap = (dispatch) => ({
  fetchUserInfo: () => dispatch(actions.fetchUserInfo()),
  userLogout: () => dispatch(actions.userLogout())
})

export default compose(
  connect(stateMap, dispatchMap)
)(AdminShell)