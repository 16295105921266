import * as types from './actionTypes'

export function setSidebarVisibility(value) {
  return { type: types.SET_SIDEBAR_VISIBILITY, payload: value }
}

export function setHeaderVisibility(value) {
  return { type: types.SET_HEADER_VISIBILITY, payload: value }
}

export function setFooterVisibility(value) {
  return { type: types.SET_FOOTER_VISIBILITY, payload: value }
}
