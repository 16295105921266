import axios from 'axios'
import { isLocalhost } from '../../utils'

const host = isLocalhost() ? 'http://localhost:8080' : ''

export const apiPrivacyGetProcesses = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/master/getRunningProcesses`
  })
}
