import React from 'react'
import PropTypes from 'prop-types'

import { WorkspacesConsumer } from '../../../../contexts/OspreyAnalytics/Workspaces'

const WithWorkspaces = (Component) => {
  return class WithTaxonomies extends React.Component {
    render = () => {
      return (
        <WorkspacesConsumer>
          { ({ getTaxonomies, ...rest }) => (
            <InitWorkspaces getTaxonomies={ getTaxonomies }>
              <Component {...this.props} {...rest} />
            </InitWorkspaces>
          )}
        </WorkspacesConsumer>
      )
    }
  }
}

class InitWorkspaces extends React.Component {
  componentDidMount = () => {
    this.props.getTaxonomies()
  }

  render = () => {
    return (
      this.props.children 
    )
  }
}

InitWorkspaces.propTypes = {
  children: PropTypes.element.isRequired,
  getTaxonomies: PropTypes.func.isRequired
}

export default WithWorkspaces