import React from 'react'
import PropTypes from 'prop-types'

import { BackOfficeDataModelsConsumer } from '../../../../contexts/OspreyAnalytics/DataModels'

const WithDataModels = (Component) => {
  return class WithDataModels extends React.Component {
    render = () => {
      return (
        <BackOfficeDataModelsConsumer>
          { ({ getDataModels, ...rest }) => (
            <InitDataModels getDataModels={ getDataModels }>
              <Component {...this.props} {...rest} />
            </InitDataModels>
          )}
        </BackOfficeDataModelsConsumer>
      )
    }
  }
}

class InitDataModels extends React.Component {
  componentDidMount = () => {
    this.props.getDataModels()
  }

  render = () => {
    return (
      this.props.children 
    )
  }
}

InitDataModels.propTypes = {
  children: PropTypes.element.isRequired,
  getDataModels: PropTypes.func.isRequired
}

export default WithDataModels