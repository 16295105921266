import React from "react";
import {connect} from "react-redux";
import {Input, Checkbox, Select} from "antd";
import {apiGetBusinessProcessList} from "../../../../api/SmartBox";
const { Option } = Select

class CollectorWorkflowBuilderAdmin extends React.Component {
    state = {
        formOptions: {
            valueSelectCollector: null,
            componentName: null,
        },
        hoverDelete: false,
        listBusiness: [],
    }
    componentDidMount() {
        this.onGetBusinessProcessList()
    }

    onGetBusinessProcessList = () => {
        apiGetBusinessProcessList().then((response) => {
            this.setState({
                listBusiness: response.data
            }, () => {
                if (this.props.component.componentName) {
                    let businessId = null
                    response.data && response.data.length > 0 && response.data.forEach((list) => {
                        if (this.props.component.componentName === list.businessProcessName) {
                            businessId = list.id
                        }
                    })
                    this.setState({
                        formOptions: { ...this.state.formOptions, valueSelectCollector: this.props.component.componentName, idComponent: businessId, componentName: this.props.component.componentName},
                    }, () => {
                        this.props.onChangeBuilder(this.state.formOptions, this.props.component)
                        this.props.onSelectIdBusiness(businessId)
                    })
                }
            })
        })
    }
    onHoverActionComponent = () => {
        this.setState({
            hoverDelete: true
        })
    }
    onLeaveActionComponent = () => {
        this.setState({
            hoverDelete: false
        })
    }
    onSelectCollector = (e) => {
        const { listBusiness, formOptions } = this.state
        let businessId = null
        listBusiness && listBusiness.length > 0 && listBusiness.forEach((list) => {
            if (e === list.businessProcessName) {
                businessId = list.id
            }
        })
        this.setState({
            formOptions: { ...this.state.formOptions, valueSelectCollector: e, idComponent: businessId, componentName: e},
        }, () => {
            this.props.onChangeBuilder(this.state.formOptions, this.props.component)
            this.props.onSelectIdBusiness(businessId)
        })
    }
    render() {
        const { hoverDelete, listBusiness, formOptions, valueSelectCollector } = this.state
        const { component, onStateProvidedDraggable } = this.props
        return (
            <div className="component-form">
                <div className="component-content component-content-new">
                    <div className="header-component">
                        <span className="header-title header-title-collector">Data Collector</span>
                    </div>
                    <div className="select-content">
                        <span onClick={ (e) => this.props.showConfirmDeleteSection(e, component) }
                              className={hoverDelete ? 'icon-v19-delete-active' : "icon-v19-delete" }
                              onMouseEnter={ (e) => this.onHoverActionComponent(e)}
                              onMouseLeave={() => this.onLeaveActionComponent()}></span>
                        <Select className="select-form"
                                dropdownClassName={"select-form-dropdown"}
                                value={ formOptions.valueSelectCollector ? formOptions.valueSelectCollector : 'Select Business process' }
                                onChange={(e) => this.onSelectCollector(e)}
                                placeholder="Select">
                            { listBusiness && listBusiness.length > 0 && listBusiness.map((item, index) => {
                                return  (<Option className="options-select-connector" value={item.businessProcessName} key={index}>
                                    {item.businessProcessName}
                                </Option>)
                            })}
                        </Select>
                    </div>
                </div>
                <div className="component-move">
                    <span onMouseEnter={() => this.props.onStateProvidedDraggable(component)} className="icon-v211-grid-view-1"></span>
                </div>
            </div>
        )
    }
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(CollectorWorkflowBuilderAdmin)