import React from 'react'
import './CompanySettings.scss'
import { Input, Switch,Button } from 'antd'
import ImageUploader from 'react-images-upload'
import { buildImageErrorNotification } from '../../../utils/ImageErrorsBuilder'
import { Link } from 'react-router-dom'
// import Button from '../../Common/Button'
import {
  getImageForUserOrTeam,
  getCompanyData,
  updateCompanyData
} from '../../../api/SmartBox'
import { connect } from 'react-redux'
import CustomNotificationManager from '../../Common/CustomNotificationManager'
import FormData from "form-data";
import _ from "lodash";
import axios from "axios";
import {ConcurrencyManager} from "axios-concurrency";
const isLocalhost = () => {
  return window.location.hostname === 'localhost'
}
const host = isLocalhost() ? 'http://localhost:8080' : ''
class CompanySettings extends React.Component {
  constructor() {
    super()
    this.state = {
      previewImage: '',
      imageUpload: null,
      pictureImg: [],
      respondFactor: true,
      nameCompany: '',
      pictures: [],
      pictureId: 0,
      loadingSaveChanges:false,
      loadingDiscardChanges: false
    }
    this.imageUpload = React.createRef()
    this.state = this.defaultState
    this.onDrop = this.onDrop.bind(this)
  }
  get defaultState() {
    return {
      imageUpload: null
    }
  }
  componentDidMount() {
    this.axiosClone = axios.create({ headers: this.props.smartBoxAxiosHeaders })
    this.manager = ConcurrencyManager(this.axiosClone, this.MAX_CONCURRENT_REQUESTS)
    this.onImageForUserOrTeam()
    this.onGetCompanyData()
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_.isEmpty(this.props.smartBoxAxiosHeaders)) {
      this.axiosClone = axios.create({ headers: this.props.smartBoxAxiosHeaders })
      this.manager = ConcurrencyManager(this.axiosClone, this.MAX_CONCURRENT_REQUESTS)
    }
  }
  onImageForUserOrTeam() {
    const type = 'client'
    getImageForUserOrTeam(this.props.clientId, type).then((respose) => {
      this.setState({
        previewImage: respose.data
      })
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  onGetCompanyData() {
    getCompanyData(this.props.clientId).then((response) => {
      this.setState({
        respondFactor: response.data.multi_factor_authentication,
        nameCompany: response.data.name,
        pictureId: response.data.pictureId
      })
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  componentWillUnmount() {
    this.setState(this.defaultState)
  }
  onDrop(pictures=[], done) {
    const { pictureImg } = this.state
    if (this.imageUpload &&
        (pictures.length === 0 ||
        this.imageUpload.state.notAcceptedFileSize?.length ||
        this.imageUpload.state.notAcceptedFileType?.length
      )) {

      // buildImageErrorNotification(this.imageUpload)

      this.imageUpload.setState({
        notAcceptedFileType: [],
        notAcceptedFileSize: []
      })
    } else {
      this.setState({
        pictures: pictureImg?.length ? pictureImg.concat(pictures) : pictures,
        previewImage: done })
      if (done.length > 1) {
        this.setState({
          previewImage: done[done.length - 1]
        })
      }
    }
  }
  onChangeRespondFactor = (respondFactor) => {
    this.setState({ respondFactor: respondFactor })
  }
  updateValueCompany(e) {
    this.setState({
      nameCompany: e.target.value
    })
  }
  companyDataSave = (nameCompany, respondFactor, pictureId) => {
    const obj = new FormData()
    let objData = {}
    if (this.state.pictures && this.state.pictures.length > 0) {
      objData = {
        id: this.props.clientId,
        name: nameCompany,
        multi_factor_authentication: respondFactor,
        pictureId: pictureId,
        multipartFile: this.state.pictures[this.state.pictures.length-1],
      }
    } else {
      objData = {
        id: this.props.clientId,
        name: nameCompany,
        multi_factor_authentication: respondFactor,
        pictureId: pictureId,
      }
    }
    const boundary = '----WebKitFormBoundary7MA4YWxkTrZu0gW';
    const body = `--${boundary}\r\nContent-Disposition: form-data; name="key1"\r\n\r\nvalue1\r\n--${boundary}\r\nContent-Disposition: form-data; name="key2"\r\n\r\nvalue2\r\n--${boundary}--`;
    // obj.append('id', this.props.clientId)
    // obj.append('name', nameCompany)
    // obj.append('multi_factor_authentication', respondFactor)
    // if (this.state.pictures && this.state.pictures.length > 0) {
    //   obj.append('multipartFile', this.state.pictures[this.state.pictures.length-1])
    // }
    // obj.append('pictureId', pictureId)
    return this.axiosClone({
          method: 'post',
          url: `${ host }/osprey-0.1.0/v1/admin/company-data/update`,
          data: objData,
          body: body,
          headers: {
            'Content-Type': `multipart/form-data; boundary=${boundary}`
          },
        }
    ).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  handleSubmitCompanySettings = (e) => {
    const { nameCompany, respondFactor, pictureId } = this.state
    this.setState({
      loadingSaveChanges:true,
    })
    e.preventDefault()
    const companyDTO = {
      id: this.props.clientId,
      name: nameCompany,
      multi_factor_authentication: respondFactor,
      multipartFile: this.state.pictures && this.state.pictures.length > 0 ? this.state.pictures[this.state.pictures.length-1] : null,
      pictureId: pictureId
    }
    this.companyDataSave(nameCompany, respondFactor, pictureId).then((res) => {
      this.setState({
        loadingSaveChanges:false,
      })
      // if(res.data.success){
      //   CustomNotificationManager.success('Image uploaded successfully', 'Success')
      // }
      if (res.data.title && res.data.title.toLowerCase() === 'success') {
        CustomNotificationManager.success(res.data.message, 'Success')
      } else if(res.data.title && res.data.title.toLowerCase() === 'info') {
        CustomNotificationManager.info(res.data.message, 'Info')
      }
    }).catch((e) => console.log(e))
    // updateCompanyData(companyDTO).then(() => {
    //   CustomNotificationManager.info('Your changes have been applied!', 'Info')
    // }).catch((error) => {
    //   if (error?.response?.status === 504) {
    //     CustomNotificationManager.cleanBuffer('warning', '')
    //   }else{
      //   console.log(error)
      // }
    // })
  }
  onDiscardChangesCompanySettings = () => {
    this.setState({
      loadingDiscardChanges: true
    }, () => {
      window.location.replace('/smart-box/management')
      this.setState({
        loadingDiscardChanges: false
      })
    })
  }
  render() {
    const { respondFactor, nameCompany, loadingSaveChanges, loadingDiscardChanges} = this.state
    return (
      <div className="company-settings">
        <div className="content-company-settings">
          <div className="content-company-settings__header">
            <span className="title-company">Company settings</span>
          </div>
          <div className="content-company-settings__content">
            <div className="content-company-name-upload">
              <div className="name-company">
                <label>Name</label>
                <Input className="input-company" value={ nameCompany } onChange={(e) => this.updateValueCompany(e)}/>
              </div>
              <div className="content-upload-logo-company">
                <h4 className="upload-title-logo"><span className="style-span-logo">Upload Logo</span></h4>
                <div className="float-left m-3 ">
                  { this.state.previewImage && this.state.previewImage.length > 0 ?
                    <img id="preview_profile" className="company-icon-logo" src={ this.state.previewImage } alt={ this.state.previewImage }/>:
                    <div className="image-empty"></div>
                  }
                </div>
                <div className="float-left m-3">
                  <ImageUploader
                    className="fileUpload file-upload-company"
                    withIcon={false}
                    label=''
                    labelStyle="styleLabelImageUpload"
                    buttonText='Choose image'
                    buttonClassName="buttonClassChange"
                    onChange={this.onDrop}
                    imgExtension={['.jpg', '.png', '.jpeg']}
                    maxFileSize={3145728}
                    withPreview={false}
                    imgPreview={false}
                    singleImage={true}
                    errorStyle={{ color: 'blue', fontSize:'20px' }}
                    ref={(ref) => this.imageUpload = ref}
                  />
                </div>
              </div>
            </div>
            <div className="content-factor-authentication">
              <span className="title-factor">Enable global Multi Factor Authentication</span>
              <div className="switcher-option-check">
                <Switch
                  checked={ respondFactor }
                  checkedChildren="YES"
                  unCheckedChildren="NO"
                  onChange={ () => this.onChangeRespondFactor(!respondFactor) }
                />
              </div>
            </div>
            <div>
              <footer className="userSettings-footer">
                <div>
                  {/*<Link className="userSettings-footerLink" to="/smart-box/management">Discard changes</Link>*/}
                  <Button loading={ loadingDiscardChanges } onClick={(e) => this.onDiscardChangesCompanySettings(e)} className="userSettings-footerLink">Discard changes</Button>
                </div>
                <div className="newQuestionnaire__submitButtons">
                  <div className={ this.disabledButton ? 'newQuestionnaire__disabledButton' : ''}>
                    <Button
                      className={loadingSaveChanges ? ' button-second button-loading' : 'button-second'}
                      theme='second'
                      loading={loadingSaveChanges}
                      onClick={ this.handleSubmitCompanySettings }>
                      Save changes
                    </Button>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id,
  smartBoxAxiosHeaders: state.smartBox.smartBoxAxiosHeaders
})
export default connect(mapStateToProps, {})(CompanySettings)