import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Link, Navigate } from 'react-router-dom'
import { compose } from 'redux'
import * as actions from '../../../actions/messageActions'
import { apiDpiaReportDetail } from '../../../api/Dpia'
import { userIsUser } from '../../../utils'
import Area from '../../Common/Area'
import Button from '../../Common/Button'
import CustomNotificationManager from '../../Common/CustomNotificationManager'
import ErrorPage from '../../Common/ErrorPage'
import Message from '../../Common/Message'
import SemiCircle from '../../Common/SemiCircle'
import '../../../style/common.scss'
import './Summary.scss'
import withRouter from '../../../private-routers/withRouter'

export class Summary extends React.Component {
  constructor() {
    super()
    this.state = {
      report: {
        isLoading: true,
        error: null
      }
    }
    this.seeDetailedReport = this.seeDetailedReport.bind(this)
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    this.getReport()
  }
  componentWillUnmount() {
    this.props.hideMessage()
  }
  getReport() {
    const idToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId
    const data = {
      id_token: idToken
    }
    const { assessmentId } = this.props.params
    apiDpiaReportDetail(data, assessmentId)
      .then((response) => {
        if (response.data.piScores.length > 0) {
          this.setState({
            report: {
              ...response.data,
              error: null,
              isLoading: false
            }
          })
        } else {
          CustomNotificationManager.error('Could not load report', 'Error')
        }
      }).catch((error) => {
        this.setState({
          report: {
            isLoading: false,
            error: error.response
          }
        })
      })
  }
  seeDetailedReport() {
    const assessmentId = this.props.params.assessmentId
    const processId = this.props.params.processId
    let name;
    //todo ask about which name must displays here
    if (this.props.location.state && this.props.location.state.name) {
      name = this.props.location.state.name;
    } else if (this.state.report.assessmentProcess.user){
      name = this.state.report.assessmentProcess.user.name.value
    }
    this.props.navigate({pathname: `/dpia/report/detail/${ processId }/${ assessmentId }`, state: {name}})
  }

  getName(report) {
    if (!report.assessmentProcess) return ''
    return report.assessmentProcess.name
  }

  reportIndicators(report) {
    if (report.isLoading || report.error) return {}

    const indicators = ['SEN', 'C', 'T', 'SEC', 'TB', 'DC', 'DS']
    const indicatorsValues = {}
    
    indicators.forEach((indicator) => {
      const value = this.getReportValues(report, indicator)
      if (value) indicatorsValues[indicator] = value
    })

    return indicatorsValues
  }

  getReportValues(report, risk) {
    if (!report.piScores) return
    const reportRisk = report.piScores.find((item) => item.code === risk)

    if (!reportRisk) return

    let score = reportRisk.piIndicatorScore

    if (!reportRisk.hasPositiveImpactOnPrivacy) {
      score = 1 - score
    }

    const value = Math.ceil(score * 100)
    let status = ''

    if (value <= 50) {
      status = 'low'
    } else if (value <= 75) {
      status = 'medium'
    } else {
      status = 'high'
    }

    return { status, value }
  }

  backButtonUrl(process) {
    if (process && process.status === 'INACTIVE') return '/dpia/dashboard/archived'
    return '/dpia/dashboard'
  }

  render() {
    const { messageArea } = this.props
    const { report } = this.state
    const { SEN, C, T, SEC, TB, DC, DS } = this.reportIndicators(report)
    
    const isAdmin = !userIsUser(this.props.user)
    if (!isAdmin) return (
      <Navigate to="/dpia/dashboard" />
    )
    
    return (
      <div className="reportSummary-page">
        { !report.isLoading &&
          !report.error &&
          <Link to={ this.backButtonUrl(report.assessmentProcess) } className="reportSummary-link">Back to DPIA dashboard screen</Link>
        }

        {
          report.error &&
          <ErrorPage error={ report.error } />
        }

        <div className="reportSummary-box">
          { messageArea.visible &&
            <Message
              hideMessageAction={ this.props.hideMessage }
              status={ messageArea.status }
              text={ messageArea.text } />
          }

          { !report.isLoading &&
            !report.error &&
            <div>
              <header className="reportSummary-header">
                <h1 className="reportSummary-title">{ this.getName(report) }</h1>
                <Button onClick={ this.seeDetailedReport.bind(this) }>
                  See detailed report
                </Button>
              </header>
        
              <div className="reportSummary-section">
                <header className="reportSummary-sectionHeader">
                  <div className="reportSummary-content">
                    <h1 className="reportSummary-sectionTitle">Privacy risk indicators</h1>
                    <h2 className="reportSummary-sectionSubtitle">Here are your privacy indicators:</h2>
                  </div>
                  <ul className="reportSummary-info">
                    <li className="reportSummary-low">Low</li>
                    <li className="reportSummary-medium">Medium</li>
                    <li className="reportSummary-high">High</li>
                  </ul>
                </header>

                <div>
                  { SEN &&
                    <div className="reportSummary-item">
                      <SemiCircle { ...SEN } />
                      <div className="reportSummary-itemContent">
                        <Area
                          description='Risks related to a sensitive market (i.e. elderly, children, etc.) and/or sensitive data (i.e. health, finance).'
                          icon='Sensitivity'
                          title='Sensitivity (SEN)'
                        />
                      </div>
                    </div>
                  }

                  { C &&
                    <div className="reportSummary-item">
                      <SemiCircle {...C} />
                      <div className="reportSummary-itemContent">
                        <Area
                          description='Risks related to compliance with external standards, policies, laws, etc.'
                          icon='Compliance'
                          title='Compliance (C)'
                        />
                      </div>
                    </div>
                  }

                  { T &&
                    <div className="reportSummary-item">
                      <SemiCircle {...T} />
                      <div className="reportSummary-itemContent">
                        <Area
                          description='Risks related to transparency in the areas of notice/user messaging and choice/consent.'
                          icon='Transparency'
                          title='Transparency (T)'
                        />
                      </div>
                    </div>
                  }

                  { SEC &&
                    <div className="reportSummary-item">
                      <SemiCircle {...SEC} />
                      <div className="reportSummary-itemContent">
                        <Area
                          description='Risks related to security of data and data flows.'
                          icon='Security'
                          title='Security (SEC)'
                        />
                      </div>
                    </div>
                  }

                  { TB &&
                    <div className="reportSummary-item">
                      <SemiCircle {...TB} />
                      <div className="reportSummary-itemContent">
                        <Area
                          description='Risks related to transfer of information across national borders.'
                          icon='Transborder'
                          title='Trans-border data flow (TB)'
                        />
                      </div>
                    </div>
                  }

                  { DC &&
                    <div className="reportSummary-item">
                      <SemiCircle {...DC} />
                      <div className="reportSummary-itemContent">
                        <Area
                          description='Risks related to control of the data lifecycle (i.e., collection, usage, quality, and/or retention).'
                          icon='DataControl'
                          title='Data control (DC)'
                        />
                      </div>
                    </div>
                  }

                  { DS &&
                    <div className="reportSummary-item">
                      <SemiCircle {...DS} />
                      <div className="reportSummary-itemContent">
                        <Area
                          description='Risks related to sharing data with third parties.'
                          icon='DataSharing'
                          title='Data sharing (DS)'
                        />
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}

const stateMap = (state) => ({
  messageArea: state.messageAreaReducer.messageArea,
  user: state.userReducer.user
})

const dispatchMap = (dispatch) => ({
  hideMessage: () => dispatch(actions.hideMessage()),
  showMessage: (status, text) => dispatch(actions.showMessage({ status, text }))
})
Summary = withRouter(Summary)
Summary.propTypes = {
  hideMessage: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  messageArea: PropTypes.object.isRequired,
  showMessage: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
}

export default compose(connect(stateMap, dispatchMap))(Summary)