import PropTypes from 'prop-types'
import React from 'react'
import './ToggleAccordion.scss'

const ToggleAccordion = ({ oppened, index, toggleAccordion }) => {
  return (
    <div
      className={ oppened ? 'toggleAccordion__wrapperOpened' : 'toggleAccordion__wrapper' }
      onClick={ (e) => { toggleAccordion(index) } }>
    </div>
  )
}

ToggleAccordion.propTypes = {
  oppened: PropTypes.bool,
  index: PropTypes.number.isRequired,
  toggleAccordion: PropTypes.func.isRequired
}

export default ToggleAccordion