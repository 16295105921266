import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import ButtonAddCollection from './Form/ButtonAddCollection'
import CollectionList from './Collection/CollectionList'
import Filters from './Collection/Filters'
import Footer from './Collection/Footer'
import Loading from '../Common/Loading'
import Pagination from './Form/Pagination'

import WithCollections from './HOC/WithCollections'
import WithConnectors from './HOC/WithConnectors'

import { debounce } from '../../utils'
import '../../style/common.scss'
import './Collection.css'

class Collection extends React.Component {
  constructor() {
    super()

    this.state = {
      activePage: 1,
      chosenView: 'cards'
    }

    this.filterByTerm = debounce(this.filterByTerm.bind(this), 1000)
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  componentWillUnmount = () => {
    // Reset the value for the filtered collections
    this.props.filterCollectionsByTerm('')
  }

  toggleChosenView = () => {
    const oldView = this.state.chosenView
    this.setState({ chosenView: oldView === 'cards' ? 'table' : 'cards' })
  }

  changePagination = (changePagination, page) => {
    this.setState({ activePage: page })
    changePagination(page)
  }

  filterByTerm(event) {
    this.setState({ activePage: 1 })
    this.props.filterCollectionsByTerm(event.target.value)
  }

  render = () => {
    const { activePage } = this.state
    
    return (
      <div className="privacyCollection__page">
        {/* Create a jira asking to include a sort field on the getCategoryChildren */}
        <Filters
          changeView={ this.toggleChosenView }
          chosenView={ this.state.chosenView }
          filterByTerm={ this.filterByTerm }
        />
        
        <div className="privacyCollection__box">
          <header className="privacyCollection__boxHeader">
            <div>
              <h1 className="privacyCollection__boxTitle">Collections</h1>
              { this.props.totalCollections > 0 &&
                <span className="privacyCollection__boxTitleTotal">
                  <strong>{ this.props.totalCollections }</strong> total
                </span>
              }
              <p className="privacyCollection__boxText">
                Groups of your data that match against pre-defined keyword lists and file security.
              </p>
            </div>
            <ButtonAddCollection />
          </header>
        </div>

        { this.props.isFetchingCollections
          ? <div className="privacyCollection__loading"><Loading /></div>
          : <CollectionList
            collections={ this.props.collections.slice((activePage - 1) * 12, activePage * 12) }
            chosenView={ this.state.chosenView }
          />
        }

        <React.Fragment>
          { !this.props.isFetchingCollections &&
            this.props.collections.length > 0 &&
            <React.Fragment>
              <div className="pagination__box">
                <header className="pagination__box__header">
                  <div>
                    <h1 className="privacyCollection__boxTitle">Collections</h1>
                    { this.props.totalCollections > 0 &&
                      <span className="pagination__box__header__total">
                        <strong>{ this.props.totalCollections }</strong> total
                      </span>
                    }
                    <p className="pagination__box__header__text">
                      Page { this.state.activePage } of { this.props.totalPagesForCollections }
                    </p>
                  </div>
                  <Pagination
                    activePage={ this.state.activePage }
                    changePagination={ this.changePagination.bind(this, this.props.changePaginationForCollections) }
                    pages={ this.props.totalPagesForCollections } />
                </header>
              </div>

              <Footer />
            </React.Fragment>
          }
        </React.Fragment>
      </div>
    )
  }
}

Collection.propTypes = {
  changePaginationForCollections: PropTypes.func.isRequired,
  collections: PropTypes.array.isRequired,
  filterCollectionsByTerm: PropTypes.func.isRequired,
  isFetchingCollections: PropTypes.bool.isRequired,
  totalCollections: PropTypes.number.isRequired,
  totalPagesForCollections: PropTypes.number.isRequired
}

export default compose(
  WithCollections,
  WithConnectors
)(Collection)