import React from 'react'
import { Table, Tooltip } from 'antd'
import moment from 'moment'
import '../Admin.scss'
import Switch from 'antd/es/switch'
import Icon from 'antd/es/icon'
import { switchUser } from '../../../api/Admin';
import withRouter from '../../../private-routers/withRouter'
import {apiUserResendInviteGodAdmin} from "../../../api/User";
import CustomNotificationManager from "../../Common/CustomNotificationManager";
import {getErrorMessage} from "../../../utils";
const { Column } = Table

class UserManagementTable extends React.Component {
  state= {
    pageNumber: 1,
      sorterColumnUser: '',
      sorterNameColumnUser: '',
      selectColumnUser: {},
      isSending: {},
      messageStatus: {},
      messageText: {},
      showMessage: {}
  }

  cancel = () => {
    this.setState({ editingKey: '' })
  };

  onPageChange = (page) => {
    this.cancel()

    this.setState({
      pageNumber: page
    }, () => { this.props.changePage(page) })
  }

  showTotalPages = (total) => {
    const { pageNumber } = this.state
    return (
      <div className="total-elements">
        <span className="pagination__box__header__total">
          <strong>{total}</strong> results
        </span>
        <span className="pagination__box__header__text">
              Page {pageNumber} of {Math.ceil(total / 10)}
        </span>
      </div>
    )
  }
  switchUser(record) {
    switchUser(record.key).then((response) => {
      const idToken = response.data
      // const accessToken = response.data
      var secondsToExp = Math.floor(new Date().getTime() / 1000);
      secondsToExp += (60*60*8)
      var tokenData = {'tokenId': idToken, 'expiresAt': secondsToExp}
      localStorage.setItem('tokenData', JSON.stringify(tokenData))
      // localStorage.setItem('accessToken', accessToken)
      window.location.reload()
    }).catch((err) =>{
    })
    return undefined;
  }
    onSorterColumnsUser = (e, column, sorter) => {
        const selectCol = {
            column: column,
            sorter: sorter
        }
        this.setState({
            sorterColumnUser: sorter,
            sorterNameColumnUser: column,
            selectColumnUser: selectCol
        })
        this.props.onSorterUserData(sorter, column)
    }

    resendInvite = (email, userId) => {
        const isSending = this.state.isSending
        if (isSending[userId]) {
            isSending[userId] = true
            this.setState({
                isSending: isSending
            })
        }
        const data = {
            email,
            id: userId
        }

        apiUserResendInviteGodAdmin(data)
            .then((response) => {
                const { messageStatus, messageText, showMessage } = this.state
                showMessage[userId] = true
                messageText[userId] = 'Invite resent.'
                messageStatus[userId] = 'success'
                if (response.data.success) {
                    CustomNotificationManager.success('Resend invite')
                } else {
                    CustomNotificationManager.info('Resend invite failed')
                }
                this.setState({
                    isSending: false,
                    messageStatus,
                    messageText,
                    showMessage
                })
            }).catch((e) => {
            const text = getErrorMessage(e)
            CustomNotificationManager.error(e.message, 'Resend error')
            const { messageStatus, messageText, showMessage } = this.state
            messageStatus[userId] = 'error'
            messageText[userId] = text
            showMessage[userId] = true

            this.setState({
                isSending: false,
                messageStatus,
                messageText,
                showMessage
            })
        })
    }

  render() {
    const { users } = this.props
    const { selectColumnUser } = this.state
    return (
      <Table dataSource={users} className="custom-table custom-table-client"
        scroll={{ x: 2223 }}
        pagination={{
          onChange: this.onPageChange,
          pageSize: 10,
          showTotal: this.showTotalPages,
          className: 'annotation-pagination',
          showLessItems: true,
          total: this.props.totalElements,
        }}>
        <Column title= { <span className="content-header-column"><span>Full Name</span>
                 <span className="content-buttons-sorter">
                      <span className={ selectColumnUser && selectColumnUser.column === 'name' && selectColumnUser.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                            onClick={(e) => this.onSorterColumnsUser(e, 'name', 'asc')}></span>
                     <span className={ selectColumnUser && selectColumnUser.column === 'name' && selectColumnUser.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                           onClick={(e) => this.onSorterColumnsUser(e, 'name', 'desc')}></span>
                 </span>
                </span>}
                dataIndex="fullName" key="fullName" fixed="left" width={150} render={(text, record) => {
            return (<span> {`${ text ? text : '' }`} </span>)
        }}/>
        <Column
                title= { <span className="content-header-column"><span>Osprey role</span>
                 <span className="content-buttons-sorter">
                      <span className={ selectColumnUser && selectColumnUser.column === 'osprey-role' && selectColumnUser.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                            onClick={(e) => this.onSorterColumnsUser(e, 'osprey-role', 'asc')}></span>
                     <span className={ selectColumnUser && selectColumnUser.column === 'osprey-role' && selectColumnUser.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                           onClick={(e) => this.onSorterColumnsUser(e, 'osprey-role', 'desc')}></span>
                 </span>
                </span>}
                dataIndex="ospreyRole" key="ospreyRole" width={100} render={(text) => {
          return (<span className={text !== 'user' ? 'role-admin': ''}> {text} </span>)
        }}
        />
        <Column
                title= { <span className="content-header-column"><span>Dpia role</span>
                 <span className="content-buttons-sorter">
                      <span className={ selectColumnUser && selectColumnUser.column === 'dpia-role' && selectColumnUser.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                            onClick={(e) => this.onSorterColumnsUser(e, 'dpia-role', 'asc')}></span>
                     <span className={ selectColumnUser && selectColumnUser.column === 'dpia-role' && selectColumnUser.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                           onClick={(e) => this.onSorterColumnsUser(e, 'dpia-role', 'desc')}></span>
                 </span>
                </span>}
                dataIndex="dpiaRole" key="dpiaRole" width={100} render={(text) => {
          return (<span className={text !== 'user' ? 'role-admin': ''}> {text} </span>)
        }}
        />
        <Column
                title= { <span className="content-header-column"><span>Email</span>
                 <span className="content-buttons-sorter">
                      <span className={ selectColumnUser && selectColumnUser.column === 'email' && selectColumnUser.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                            onClick={(e) => this.onSorterColumnsUser(e, 'email', 'asc')}></span>
                     <span className={ selectColumnUser && selectColumnUser.column === 'email' && selectColumnUser.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                           onClick={(e) => this.onSorterColumnsUser(e, 'email', 'desc')}></span>
                 </span>
                </span>}
                dataIndex="email" key="email" width={200}/>
        <Column
                title= { <span className="content-header-column"><span>Phone</span>
                 <span className="content-buttons-sorter">
                      <span className={ selectColumnUser && selectColumnUser.column === 'phone' && selectColumnUser.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                            onClick={(e) => this.onSorterColumnsUser(e, 'phone', 'asc')}></span>
                     <span className={ selectColumnUser && selectColumnUser.column === 'phone' && selectColumnUser.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                           onClick={(e) => this.onSorterColumnsUser(e, 'phone', 'desc')}></span>
                 </span>
                </span>}
                dataIndex="phone" key="phone" width={100}/>
        <Column
                title= { <span className="content-header-column"><span>Verify Email</span>
                 <span className="content-buttons-sorter">
                      <span className={ selectColumnUser && selectColumnUser.column === 'verified-email' && selectColumnUser.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                            onClick={(e) => this.onSorterColumnsUser(e, 'verified-email', 'asc')}></span>
                     <span className={ selectColumnUser && selectColumnUser.column === 'verified-email' && selectColumnUser.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                           onClick={(e) => this.onSorterColumnsUser(e, 'verified-email', 'desc')}></span>
                 </span>
                </span>}
                dataIndex="emailVerified" width={100} key="verifyEmail" render={(value) => {
          return (<Switch checkedChildren={<Icon type="check" />} unCheckedChildren={<Icon type="close" />}
            checked={value} />)
        }}/>
        <Column
                title= { <span className="content-header-column"><span>Verify Password</span>
                 <span className="content-buttons-sorter">
                      <span className={ selectColumnUser && selectColumnUser.column === 'verified-password' && selectColumnUser.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                            onClick={(e) => this.onSorterColumnsUser(e, 'verified-password', 'asc')}></span>
                     <span className={ selectColumnUser && selectColumnUser.column === 'verified-password' && selectColumnUser.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                           onClick={(e) => this.onSorterColumnsUser(e, 'verified-password', 'desc')}></span>
                 </span>
                </span>}
                dataIndex="verifyPassword" width={100} key="verifyPassword" render={(value) => {
          return (<Switch checkedChildren={<Icon type="check" />} unCheckedChildren={<Icon type="close" />}
            checked={value} />)
        }}/>
        <Column
                title= { <span className="content-header-column"><span>Last IP</span>
                 <span className="content-buttons-sorter">
                      <span className={ selectColumnUser && selectColumnUser.column === 'ip' && selectColumnUser.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                            onClick={(e) => this.onSorterColumnsUser(e, 'ip', 'asc')}></span>
                     <span className={ selectColumnUser && selectColumnUser.column === 'ip' && selectColumnUser.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                           onClick={(e) => this.onSorterColumnsUser(e, 'ip', 'desc')}></span>
                 </span>
                </span>}
                dataIndex="lastIp" width={150} key="lastIp" />
        <Column title= { <span className="content-header-column"><span>Last Login</span>
                 <span className="content-buttons-sorter">
                      <span className={ selectColumnUser && selectColumnUser.column === 'last-login' && selectColumnUser.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                            onClick={(e) => this.onSorterColumnsUser(e, 'last-login', 'asc')}></span>
                     <span className={ selectColumnUser && selectColumnUser.column === 'last-login' && selectColumnUser.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                           onClick={(e) => this.onSorterColumnsUser(e, 'last-login', 'desc')}></span>
                 </span>
                </span>}
                dataIndex="lastLogin" width={100} key="lastLogin" render={(text, record) => {

                    if(!record.emailVerified){
                        return (<span><div>
                          <span className="float-left ml-2 mto-5 content-resend"><button className="resend-manage-user" onClick={() => {
                              this.resendInvite(record.email, record.key)
                          }
                          }>RESEND</button></span>
                        </div>
                  </span>)
                    } else {
                        return (<span>{moment(text).format('DD/MM/YYYY')}</span>)
                    }
        }}/>
        <Column title= { <span className="content-header-column"><span>Login Count</span>
                 <span className="content-buttons-sorter">
                      <span className={ selectColumnUser && selectColumnUser.column === 'login-count' && selectColumnUser.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                            onClick={(e) => this.onSorterColumnsUser(e, 'login-count', 'asc')}></span>
                     <span className={ selectColumnUser && selectColumnUser.column === 'login-count' && selectColumnUser.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                           onClick={(e) => this.onSorterColumnsUser(e, 'login-count', 'desc')}></span>
                 </span>
                </span>}
                dataIndex="loginsCount" width={100} key="loginsCount" />
        <Column
                title= { <span className="content-header-column"><span>Created At</span>
                 <span className="content-buttons-sorter">
                      <span className={ selectColumnUser && selectColumnUser.column === 'created-at' && selectColumnUser.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                            onClick={(e) => this.onSorterColumnsUser(e, 'created-at', 'asc')}></span>
                     <span className={ selectColumnUser && selectColumnUser.column === 'created-at' && selectColumnUser.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                           onClick={(e) => this.onSorterColumnsUser(e, 'created-at', 'desc')}></span>
                 </span>
                </span>}
                dataIndex="createdAt" width={100} key="createdAt" render={(text) => {
          return (<span>{moment(text).format('DD/MM/YYYY')}</span>)
        }}/>
        <Column
                title= { <span className="content-header-column"><span>Blocked</span>
                 <span className="content-buttons-sorter">
                      <span className={ selectColumnUser && selectColumnUser.column === 'blocked' && selectColumnUser.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                            onClick={(e) => this.onSorterColumnsUser(e, 'blocked', 'asc')}></span>
                     <span className={ selectColumnUser && selectColumnUser.column === 'blocked' && selectColumnUser.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                           onClick={(e) => this.onSorterColumnsUser(e, 'blocked', 'desc')}></span>
                 </span>
                </span>}
                dataIndex="blocked" width={100} key="status" render={(value) => {
          return (<Switch checkedChildren={<Icon type="check" />} unCheckedChildren={<Icon type="close" />}
            checked={value} />)
        }}/>
        <Column
          title="Action"
          key="action"
          align="center"
          fixed="right"
          width={200}
          render={(text, record) => (
            <span>
              <Tooltip title="Modify"><button onClick={() => this.props.navigate(`/admin/user-data/${ record.key }`)} className="button-green" style={{marginRight:3}}><Icon type="edit" /> </button></Tooltip>
              <Tooltip title="Switch to user"><button className="button-green" onClick={ ()=> this.switchUser(record)}><Icon type="switcher" /></button></Tooltip>
            </span>
          )}
        />
      </Table>
    )
  }
}

export default withRouter(UserManagementTable)