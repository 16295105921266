import React from "react";
import {connect} from "react-redux";
import './EmailWork.scss';
import {Input, Tabs, Button, Tooltip, Popover} from 'antd'
import headerLogo from "../../../../assets/Key visual 40x40.svg";
import ImageUploader from "react-images-upload";
import {CompactPicker} from "react-color";
import {apiSaveEmailTemplateSettings, getEmailTemplateSettings} from "../../../../api/SmartBox";
import axios from "axios";
import {ConcurrencyManager} from "axios-concurrency";
import _ from "lodash";
import CustomNotificationManager from "../../../Common/CustomNotificationManager";
import FormData from "form-data";
import PropTypes from "prop-types";
const TabPane = Tabs.TabPane
const isLocalhost = () => {
    return window.location.hostname === 'localhost'
}
const host = isLocalhost() ? 'http://localhost:8080' : ''
class EmailWorkSettings extends React.Component {
    constructor () {
        super()
        this.state = {
            valueEmailDomain: '',
            valueEmailCompanyName: 'SmartBox.ai',
            listEmailSettingsColour: [
                {colorCode: 'email-header', colorName: 'Email header', defaultColor: '#1677FF', customColor: ''},
                {
                    colorCode: 'email-background',
                    colorName: 'Email background',
                    defaultColor: '#EFEFF0',
                    customColor: ''
                },
                {colorCode: 'email-logo', colorName: 'Email logo', imgDefault: ""},
            ],
            colorSettings: '#fff',
            selectedSettingsColor: '',
            colorPickerVisibleSettingsColor: {},
            showPicture: '',
            picture: '',
            statusEmailSettings: 'save',
            emailTemplateSettingsId: null
        }
        this.state.pictureImg = []
        this.onDrop = this.onDrop.bind(this)
        this.imageUpload = React.createRef()
    }
    componentDidMount() {
        this.axiosClone = axios.create({ headers: this.props.smartBoxAxiosHeaders })
        this.manager = ConcurrencyManager(this.axiosClone, this.MAX_CONCURRENT_REQUESTS)
        this.onGetEmailTemplateSettings()
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEmpty(this.props.smartBoxAxiosHeaders)) {
            this.axiosClone = axios.create({ headers: this.props.smartBoxAxiosHeaders })
            this.manager = ConcurrencyManager(this.axiosClone, this.MAX_CONCURRENT_REQUESTS)
        }
    }
    onGetEmailTemplateSettings = () => {
        const { listEmailSettingsColour } = this.state
        getEmailTemplateSettings(this.props.clientId).then((response) => {
            if (response.data && response.data.title && response.data.title.toLowerCase() === 'success') {
                CustomNotificationManager.success(response.data.message, 'Success')
            } else if (response.data && response.data.title && response.data.title.toLowerCase() === 'info') {
                CustomNotificationManager.info(response.data.message, 'Info')
            }
            if (Object.keys(response.data) && Object.keys(response.data).length > 0 && response.data.emailTemplateSettingsId) {
                listEmailSettingsColour && listEmailSettingsColour.length > 0 && listEmailSettingsColour.forEach((item) => {
                    if (item.colorCode === 'email-header') {
                        item.color = response.data.emailHeaderColor ? response.data.emailHeaderColor : '#1677FF'
                    }
                    if (item.colorCode === 'email-background') {
                        item.color = response.data.emailBackgroundColor ? response.data.emailBackgroundColor : '#EFEFF0'
                    }
                })
                this.setState({
                    statusEmailSettings: 'edit',
                    emailTemplateSettingsId: response.data.emailTemplateSettingsId,
                    valueEmailDomain: response.data.emailDomainAddress,
                    valueEmailCompanyName: response.data.companyName,
                    showPicture: response.data.emailLogo,
                    listEmailSettingsColour
                })
            }
        })
    }
    onInputEmailDomain = (e) => {
        this.setState({
            valueEmailDomain: e.target.value
        })
    }
    onInputEmailCompanyName = (e) => {
        this.setState({
            valueEmailCompanyName: e.target.value
        })
    }
    closeAndSaveColorPickerEmailSettings = (color) => {
        const {listEmailSettingsColour, selectedSettingsColor} = this.state
        if (listEmailSettingsColour.length) {
            listEmailSettingsColour.map((item) => {
                if (item.colorCode === selectedSettingsColor) {
                    item.color = color.hex
                }
            })
        }
        this.setState({
            listEmailSettingsColour
        })
    }
    toggleColorPickerEmailSettingsVisible = (e, id, open) => {
        const { listEmailSettingsColour } = this.state
        if (e) {
            e.stopPropagation()
        }
        const temp= {}
        if (listEmailSettingsColour.length) {
            listEmailSettingsColour.map((item) => {
                if (item.colorCode !== id) {
                    temp[item.colorCode]= false
                }
                return true
            })
        }
        temp[id] =  !this.state.colorPickerVisibleSettingsColor[id]
        this.setState({
            colorPickerVisibleSettingsColor: temp,
            selectedSettingsColor: id,
        })
    }

    onDrop(pictures, done) {
        const {listEmailSettingsColour} = this.state
        if (this.imageUpload &&
            (Object.keys(pictures).length === 0 ||
                this.imageUpload.state?.notAcceptedFileSize?.length ||
                this.imageUpload.state?.notAcceptedFileType?.length
            ))
        {
            // buildImageErrorNotification(this.imageUpload)

            this.imageUpload.setState({
                notAcceptedFileType: [],
                notAcceptedFileSize: []
            })

        } else {
            this.setState({
                pictures: this.state.pictureImg.concat(pictures),
                showPicture: done
            })
            if (done.length > 1) {
                this.setState({
                    showPicture: done[done.length -1]
                })
            }
            if (done.length > 1) {
                if (listEmailSettingsColour.length) {
                    listEmailSettingsColour.map((item) => {
                        if (item.colorCode === 'email-logo') {
                            item.imgDefault = done[done.length -1]
                        }
                    })
                }
                this.setState({
                    listEmailSettingsColour
                })
            } else {
                if (listEmailSettingsColour.length) {
                    listEmailSettingsColour.map((item) => {
                        if (item.colorCode === 'email-logo') {
                            item.imgDefault = done
                        }
                    })
                }
                this.setState({
                    listEmailSettingsColour
                })
            }
        }
    }
    onSaveEmailSettings = () => {
        const { valueEmailDomain, listEmailSettingsColour, statusEmailSettings, emailTemplateSettingsId, valueEmailCompanyName } = this.state
        let emailHeaderColor = '#1677FF'
        let emailBackgroundColor = '#EFEFF0'
        listEmailSettingsColour && listEmailSettingsColour.length > 0 && listEmailSettingsColour.forEach((item) => {
             if (item.colorCode === 'email-header') {
                 emailHeaderColor = item.color ? item.color : item.defaultColor
             }
            if (item.colorCode === 'email-background') {
                emailBackgroundColor = item.color ? item.color : item.defaultColor
            }
        })
        if (statusEmailSettings === 'save') {
            const emailTemplateSettingsSaveDTO = {
                emailDomainAddress: valueEmailDomain,
                companyName: valueEmailCompanyName,
                emailHeaderColor: emailHeaderColor,
                emailBackgroundColor: emailBackgroundColor,
                clientId: this.props.clientId,
                emailLogo: this.state.pictures ? this.state.pictures[0] : null,
                emailLogoHeight: 90,
                emailLogoWidth: 180,
            }
            this.onGetSaveEmailSettings(emailTemplateSettingsSaveDTO).then((response) => {
                if (response.data && response.data.title && response.data.title.toLowerCase() === 'success') {
                    CustomNotificationManager.success(response.data.message, 'Success')
                } else if (response.data && response.data.title && response.data.title.toLowerCase() === 'info') {
                    CustomNotificationManager.info(response.data.message, 'Info')
                }
                this.onGetEmailTemplateSettings()
            })
        } else if (statusEmailSettings === 'edit') {
            const emailTemplateSettingsSaveDTO = {
                emailDomainAddress: valueEmailDomain,
                companyName: valueEmailCompanyName,
                emailHeaderColor: emailHeaderColor,
                emailBackgroundColor: emailBackgroundColor,
                clientId: this.props.clientId,
                emailLogo: this.state.pictures ? this.state.pictures[0] : null,
                emailLogoHeight: 90,
                emailLogoWidth: 180,
                emailTemplateSettingsId: emailTemplateSettingsId
            }
            this.onGetEditEmailSettings(emailTemplateSettingsSaveDTO).then((response) => {
                if (response.data && response.data.title && response.data.title.toLowerCase() === 'success') {
                    CustomNotificationManager.success(response.data.message, 'Success')
                } else if (response.data && response.data.title && response.data.title.toLowerCase() === 'info') {
                    CustomNotificationManager.info(response.data.message, 'Info')
                }
                this.onGetEmailTemplateSettings()
            })
        }
    }
    onGetSaveEmailSettings = (data) => {
        const obj = new FormData()
        let objData = {}
        // obj.append('emailDomainAddress', data.emailDomainAddress)
        // obj.append('companyName', data.companyName)
        // obj.append('emailHeaderColor', data.emailHeaderColor)
        // obj.append('emailBackgroundColor', data.emailBackgroundColor)
        // obj.append('clientId', data.clientId)
        // obj.append('emailLogoHeight', data.emailLogoHeight)
        // obj.append('emailLogoWidth', data.emailLogoWidth)
        // if (data.emailLogo) {
        //     obj.append('emailLogo', data.emailLogo)
        // }
        if (data.emailLogo) {
            objData = {
                emailDomainAddress: data.emailDomainAddress,
                companyName: data.companyName,
                emailHeaderColor: data.emailHeaderColor,
                emailBackgroundColor: data.emailBackgroundColor,
                clientId: data.clientId,
                emailLogoHeight: data.emailLogoHeight,
                emailLogoWidth: data.emailLogoWidth,
                emailLogo: data.emailLogo,
            }
        } else {
            objData = {
                emailDomainAddress: data.emailDomainAddress,
                companyName: data.companyName,
                emailHeaderColor: data.emailHeaderColor,
                emailBackgroundColor: data.emailBackgroundColor,
                clientId: data.clientId,
                emailLogoHeight: data.emailLogoHeight,
                emailLogoWidth: data.emailLogoWidth,
            }
        }
        const boundary = '----WebKitFormBoundary7MA4YWxkTrZu0gW';
        const body = `--${boundary}\r\nContent-Disposition: form-data; name="key1"\r\n\r\nvalue1\r\n--${boundary}\r\nContent-Disposition: form-data; name="key2"\r\n\r\nvalue2\r\n--${boundary}--`;
        return this.axiosClone({
                method: 'post',
                url: `${ host }/osprey-0.1.0/v1/save-email-template-settings`,
                data: objData,
                body: body,
                headers: {
                    'Content-Type': `multipart/form-data; boundary=${boundary}`
                },
            }
        ).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
            }else{
                console.log(error)
            }
            this.setState({
                isSending: false
            })
        })

    }

    onGetEditEmailSettings = (data) => {
        const obj = new FormData()
        let objData = {}
        // obj.append('emailDomainAddress', data.emailDomainAddress)
        // obj.append('companyName', data.companyName)
        // obj.append('emailHeaderColor', data.emailHeaderColor)
        // obj.append('emailBackgroundColor', data.emailBackgroundColor)
        // obj.append('clientId', data.clientId)
        // obj.append('emailLogoHeight', data.emailLogoHeight)
        // obj.append('emailLogoWidth', data.emailLogoWidth)
        // obj.append('emailTemplateSettingsId', data.emailTemplateSettingsId)
        // if (data.emailLogo) {
        //     obj.append('emailLogo', data.emailLogo)
        // }
        if (data.emailLogo) {
            objData = {
                emailDomainAddress: data.emailDomainAddress,
                companyName: data.companyName,
                emailHeaderColor: data.emailHeaderColor,
                emailBackgroundColor: data.emailBackgroundColor,
                clientId: data.clientId,
                emailLogoHeight: data.emailLogoHeight,
                emailLogoWidth: data.emailLogoWidth,
                emailLogo: data.emailLogo,
                emailTemplateSettingsId: data.emailTemplateSettingsId,
            }
        } else {
            objData = {
                emailDomainAddress: data.emailDomainAddress,
                companyName: data.companyName,
                emailHeaderColor: data.emailHeaderColor,
                emailBackgroundColor: data.emailBackgroundColor,
                clientId: data.clientId,
                emailLogoHeight: data.emailLogoHeight,
                emailLogoWidth: data.emailLogoWidth,
                emailTemplateSettingsId: data.emailTemplateSettingsId,
            }
        }
        const boundary = '----WebKitFormBoundary7MA4YWxkTrZu0gW';
        const body = `--${boundary}\r\nContent-Disposition: form-data; name="key1"\r\n\r\nvalue1\r\n--${boundary}\r\nContent-Disposition: form-data; name="key2"\r\n\r\nvalue2\r\n--${boundary}--`;
        return this.axiosClone({
                method: 'post',
                url: `${ host }/osprey-0.1.0/v1/edit-email-template-settings`,
                data: objData,
                body: body,
                headers: {
                    'Content-Type': `multipart/form-data; boundary=${boundary}`
                },
            }
        ).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
            }else{
                console.log(error)
            }
            this.setState({
                isSending: false
            })
        })

    }

    render() {
     const { valueEmailDomain, listEmailSettingsColour, colorSettings, colorPickerVisibleSettingsColor, showPicture,
         valueEmailCompanyName } = this.state
        const disabled = false
        const renderColorPickerEmailSettings = (
            <React.Fragment>
                <div className="business-term-color-picker">
                    <CompactPicker
                        className="color-picker-a"
                        colors={['rgba(107, 91, 149, 1)', 'rgba(254, 178, 54, 1)', 'rgba(214, 65, 97, 1)',
                            'rgba(255, 123, 37, 1)', 'rgba(75, 195, 193, 1)', 'rgba(194, 194, 71, 1)', 'rgba(181, 231, 160, 1)',
                            'rgba(191, 108, 64, 1)', 'rgba(62, 68, 68, 1)', 'rgba(130, 183, 75, 1)', 'rgba(64, 93, 39, 1)',
                            'rgba(193, 148, 106, 1)', 'rgba(60, 136, 180, 1)', 'rgba(200, 100, 91, 1)', 'rgba(238, 162, 154, 1)',
                            'rgba(201, 76, 76, 1)', 'rgba(124, 108, 6, 1)', 'rgba(15, 195, 71, 1)', 'rgba(231, 82, 114, 1)',
                            'rgba(238, 140, 54, 1)', 'rgba(92, 212, 209, 1)', 'rgba(211, 211, 88, 1)', 'rgba(198, 248, 177, 1)',
                            'rgba(192, 125, 81, 1)', 'rgba(77, 100, 113, 1)', 'rgba(237, 226, 220, 1)', 'rgba(132, 206, 235, 1)']}
                        disableAlpha={true}
                        color={colorSettings}
                        onChange={(color) => this.closeAndSaveColorPickerEmailSettings(color)}
                    />
                </div>
            </React.Fragment>
        )

        return (
            <div className="email-workflow-settings">
                <div className="email-workflow-settings-contain">
                    <div className="input-email-domain">
                        <span className="name-input">Email domain:</span>
                        <Input className="input-domain" onChange={(e) => this.onInputEmailDomain(e)}
                               placeholder="email domain"
                               value={ valueEmailDomain }/>
                    </div>
                    <div className="input-email-domain input-email-company-name">
                        <span className="name-input">Email Company Name:</span>
                        <Input className="input-domain" onChange={(e) => this.onInputEmailCompanyName(e)}
                               placeholder="email company name"
                               value={valueEmailCompanyName}/>
                    </div>
                    <div className="email-settings-colour">
                       <span className="name-colours">Colours</span>
                        <div className="email-settings-colour-contain">
                            {
                                listEmailSettingsColour && listEmailSettingsColour.length > 0 && listEmailSettingsColour.map((listColour,index) => {
                                    if(listColour.colorName !== 'Email logo') {
                                        return (
                                            <div className="settings-colour-list" key={listColour.colorCode}>
                                                <div className="settings-colours-contain">
                                                <span className="change-colour-contain">
                                                     <span className='change-colour-nav-header'>{`${listColour.colorName}` + ':'}</span>
                                               <div className="content-change-colour">
                                               <Popover content={renderColorPickerEmailSettings} trigger="click"
                                                        visible={colorPickerVisibleSettingsColor[listColour.colorCode]}>
                                                  <div
                                                      className={disabled ? 'icon-change-color disabled' : 'icon-change-color'}
                                                      key={`i-${listColour.colorCode}`}
                                                      style={{backgroundColor: listColour.color ? listColour.color : listColour.defaultColor}}
                                                      onClick={(e) => disabled ? undefined : this.toggleColorPickerEmailSettingsVisible(e, listColour.colorCode)}>
                                                  </div>
                                                </Popover>
                                                     <span className="name-change-colour">{listColour.color ? listColour.color : listColour.defaultColor}</span>
                                               </div>
                                           </span>
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div className="settings-colour-list" key={listColour.colorCode}>
                                                <div className="settings-colours-contain">
                                                <span className="change-colour-contain">
                                                    <span className='change-colour-nav-header'>Email logo:</span>
                                                    <div className="style-upload-home-icon">
                                                        <div className="home-icon-container ">
                                                            <img id="field_picture_home_icon" className="home-icon" src={showPicture ? showPicture : headerLogo } alt="" onChange={(e) => this.handleChangeHomeIcon(e)} />
                                                        </div>
                                                        <div className="upload-home-icon">
                                                            <ImageUploader
                                                                className="file-upload-home-icon"
                                                                withIcon={false}
                                                                label=''
                                                                labelStyle="styleLabelImageUpload"
                                                                buttonText={<span><span className="icon-v22-upload-1"></span>
                                                                    <span className="upload-name">Upload</span></span>}
                                                                buttonClassName="button-upload-image"
                                                                onChange={this.onDrop}
                                                                imgExtension={['.jpg', '.png', '.jpeg']}
                                                                maxFileSize={3145728}
                                                                withPreview={false}
                                                                imgPreview={false}
                                                                singleImage={true}
                                                                errorStyle={{ color: 'blue', fontSize:'20px' }}
                                                                ref={(ref) => this.imageUpload = ref}/>
                                                        </div>
                                                        {/*<span className={'home-icon-size'}>(40x40 pixel)</span>*/}
                                                    </div>
                                                </span>
                                                    <span className={'home-icon-size'}>Recommended size: 180px x 90px</span>
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                    <div className="email-setting-button">
                        <Button disabled={ valueEmailDomain === '' || valueEmailCompanyName === ''} onClick={(e) => this.onSaveEmailSettings(e)} className="button-save-email-settings">Save</Button>
                    </div>
                </div>
            </div>
        )
    }
}

const stateMap = (state) => ({
    user: state.userReducer.user,
    smartBoxAxiosHeaders: state.smartBox.smartBoxAxiosHeaders,
})

EmailWorkSettings.propTypes = {
    user: PropTypes.object.isRequired
}


export default connect(stateMap)(EmailWorkSettings)