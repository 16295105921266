import { yn } from '../Helpers/OptionTemplates'

export default ({ question }) => {
  let options = []

  if (question.options.length === 0) {
    options = ['Yes', 'No'].map((type, index) => {
      return yn(type, index)
    })
  } else {
    options = question.options.slice(0, 2).map((option, index) => ({
      ...option,
      disabled: true,
      type: 'NORMAL',
      subType: 'YN',
      score: 0,
      title: index === 0 ? 'Yes' : 'No'
    }))
  }

  const newQuestion = {
    ...question,
    options
  }

  return newQuestion
}