import PropTypes from 'prop-types'
import React from 'react'
import Button from '../../Common/Button'

const ContinueActiveButton = ({ assessment, process, onClick, user, teams }) => {
  if (!assessment || process.isLocked) return null
  if (process.assignedToUserID && user.id !== process.assignedToUserID) return (
    <div className="dpiaDashboard-continueButton">
      <Button
        disabled>
        Continue
      </Button>
    </div>
  )
  if (process.assignedToTeamID) {
    for (let i=0;i <teams.length; i++) {
      if (teams[i].id === process.assignedToTeamID) {
        for (let j=0;j<teams[i].members.length;j++) {
          if (user.id === teams[i].members[j].userId) {
            return (
              <div className="dpiaDashboard-continueButton">
                <Button onClick={ () => onClick(assessment.id) }>
                  Continue
                </Button>
              </div>
            )
          }
        }
      }
    }
    return (
      <div className="dpiaDashboard-continueButton">
        <Button
          disabled>
          Continue
        </Button>
      </div>
    )    
  }

  if (process.id) {
    for (let i=0;i <process.assessments.length; i++) {
      if (process.assessments[i].status !== 'COMPLETED') {
        return (
          <div className="dpiaDashboard-continueButton">
            <Button onClick={ () => onClick(assessment.id) }>
                Continue
            </Button>
          </div>
        )
      }
    }
    return (
      <div className="dpiaDashboard-continueButton">
        <Button
          disabled>
            Continue
        </Button>
      </div>
    )
  }

  return (
    <div className="dpiaDashboard-continueButton">
      <Button onClick={ () => onClick(assessment.id) }>
        Continue
      </Button>
    </div>
  )
}

ContinueActiveButton.propTypes = {
  assessment: PropTypes.object,
  process: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
}

export default ContinueActiveButton