import { legacy_createStore as createStore , applyMiddleware, compose } from 'redux'
import rootReducer from '../reducers/rootReducer'
import initialStoreState from './initialStoreState'
import thunk from 'redux-thunk'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

function saveToLocalStorage(state) {
  const { smartBox, sar } = state

  const { sarPageLimit } = sar
  const { businessTermsPageLimit, listTermsPageLimit, overviewPageLimit, smartBoxPageLimit, smartBoxTree, smartBoxViewMode } = smartBox

  const savedState = {
    smartBox: { businessTermsPageLimit, listTermsPageLimit, overviewPageLimit, smartBoxPageLimit, smartBoxTree, smartBoxViewMode },
    sar: { sarPageLimit }
  }
  const serializedState = JSON.stringify(savedState);

  sessionStorage.setItem('gravicus-state', serializedState);
}
function loadFromLocalStorage() {
  const serializedState = sessionStorage.getItem('gravicus-state');

  if (serializedState === null) return undefined;
  let state = JSON.parse(serializedState)

  // state.shellReducer = {...state.shellReducer, footerVisible: true, headerVisible: true, sidebarVisible: true }
  const { smartBox, sar } = state
  const { sarPageLimit } = sar
  const { businessTermsPageLimit, listTermsPageLimit, overviewPageLimit, smartBoxPageLimit, smartBoxTree, smartBoxViewMode } = smartBox

  const initSmartBox = initialStoreState.smartBox
  const initSar = initialStoreState.sar

  return { ...initialStoreState,
    smartBox: {...initSmartBox, businessTermsPageLimit, listTermsPageLimit, overviewPageLimit, smartBoxPageLimit, smartBoxTree, smartBoxViewMode},
    sar: {...initSar, sarPageLimit}
  }
}

const persistedState = loadFromLocalStorage();

export default function configureStore() {

  const store = createStore(
    rootReducer,
    persistedState,
    composeEnhancers(applyMiddleware(thunk))
  )
  store.subscribe(() => saveToLocalStorage(store.getState()));
  return store
}