import initialState from './initialState'
import {
  SET_SIDEBAR_VISIBILITY,
  SET_HEADER_VISIBILITY,
  SET_FOOTER_VISIBILITY,
  USER_LOGOUT,
  FETCHING_USER_SUBSCRIPTION_DATA_COMPLETE,
  SET_SECOND_LEVEL_NAVIGATION,
  SET_THIRD_LEVEL_NAVIGATION
} from '../actions/actionTypes'

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGOUT:
      return {
        ...state,
        shell: {
          ...state.shell,
          loading: false
        }
      }
    case FETCHING_USER_SUBSCRIPTION_DATA_COMPLETE:
      return {
        ...state,
        shell: {
          ...state.shell,
          loading: action.payload
        }
      }
    case SET_SIDEBAR_VISIBILITY:
      return {
        ...state, sidebarVisible: action.payload
      }
    case SET_HEADER_VISIBILITY:
      return {
        ...state, headerVisible: action.payload
      }
    case SET_FOOTER_VISIBILITY:
      return {
        ...state, footerVisible: action.payload
      }
    case SET_SECOND_LEVEL_NAVIGATION:
      return {
        ...state, secondLevelNavigation: action.payload
      }
    case SET_THIRD_LEVEL_NAVIGATION:
      return {
        ...state, thirdLevelNavigation: action.payload
      }
    default:
      return state
  }
}