import initialState from './initialState'
import { RECEIVE_USER_INFO, UPDATE_USER_INFO, USER_LOGIN, USER_LOGOUT } from '../actions/actionTypes'

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN:
      return {
        ...state,
        isLogged: true
      }

    case USER_LOGOUT:
      return {
        ...state,
        isLogged: false,
        user: null
      }

    case RECEIVE_USER_INFO:
      return {
        ...state,
        isLogged: true,
        user: action.user
      }
      
    case UPDATE_USER_INFO:
      return {
        ...state,
        user: {
          ...state.user,
          email: action.user.email,
          user_metadata: {
            ...state.user.user_metadata,
            department: action.user.department,
            emailFrequency: action.user.emailFrequency,
            name: action.user.name,
            position: action.user.position
          }
        }
      }

    default:
      return state
  }
}