import * as types from './actionTypes'
import {BUSINESS_PROCESSES_VISIBLE, CONNECTORS_VISIBLE, SOURCE_MAPPING_VISIBLE} from "./actionTypes";

export function setSarVisible(data) {
  return { type: types.SAR_VISIBLE, payload: data }
}

export function setDocLockerVisible(data) {
  return { type: types.DOC_LOCKER_VISIBLE, payload: data }
}

export function setAssetRegisterVisible(data) {
  return { type: types.ASSET_REGISTER_VISIBLE, payload: data }
}

export function setSmartBoxVisible(data) {
  return { type: types.SMART_BOX_VISIBLE, payload: data }
}

export function setDpiaVisible(data) {
  return { type: types.DPIA_VISIBLE, payload: data }
}

export function setAdditionalEnvironmentsVisible(data) {
  return { type: types.ADDITIONAL_ENVIRONMENTS, payload: data }
}

export function setDataMappingAppVisible(data) {
  return { type: types.DATA_MAPPING_VISIBLE, payload: data }
}

export function setWorkflowAppVisible(data) {
  return { type: types.WORKFLOW_VISIBLE, payload: data }
}

export function addIntervalToApplication(data) {
  return { type: types.ADD_INTERVAL_TO_APPLICATION, payload: data }
}

export function removeAllIntervalsFromApplication(data) {
  return { type: types.REMOVE_ALL_INTERVAL_TO_APPLICATION, payload: data }
}

export function setBusinessProcessesVisible(data) {
  return { type: types.BUSINESS_PROCESSES_VISIBLE, payload: data }
}

export function setSourceMappingVisible(data) {
  return { type: types.SOURCE_MAPPING_VISIBLE, payload: data }
}

export function setConnectorsVisible(data) {
  return { type: types.CONNECTORS_VISIBLE, payload: data }
}
