import React from 'react'
import { compose } from 'redux'

import Loading from '../../../Common/Loading'
import WithDataModels from '../HOC/WithDataModels'

import './DocumentViewer.css'
import { apiOspreyGetDocumentForCategory, apiOspreyGetDataModelDocument } from '../../../../api/OspreyAnalytics.js'
import { formatContentWithHighlight } from '../../../../utils/Files'

class DocumentViewer extends React.Component {
  
  state = { 
    documentId: null,
    document: null,
    loadingDocument: true,
    categoryId: null,
    query: '',
    datamodel: 'documents',
    showMetadata: false
  }

  componentDidMount = () => {
    const { documentId, categoryId, datamodel, query } = this.props
    if (documentId) this.setState({
      documentId: documentId,
      categoryId: categoryId,
      query: query,
      datamodel: datamodel
    }, this.getDocument)
  }

  getDocument = () => {
    if (this.state.categoryId)
    {
      apiOspreyGetDocumentForCategory(this.state.categoryId, this.state.documentId, this.state.query)
        .then((response) => {
          this.setState({
            document: response.data.bean,
            loadingDocument: false
          }, (e) => this.addFieldNames())
        })
        .catch((error) => {
          console.log(error, 'error')
        })
    }
    else
    {
      apiOspreyGetDataModelDocument(this.state.datamodel, this.state.documentId, this.state.query)
        .then((response) => {
          this.setState({
            document: response.data.bean,
            loadingDocument: false
          }, (e) => this.addFieldNames())
        })
        .catch((error) => {
          console.log(error, 'error')
        })
    }
  }

  addFieldNames = () => {
    const dm = this.props.datamodels.filter((model) => model.name === this.state.datamodel)[0]

    const metadata = this.state.document.metadata
    const fields = dm.metadata.field

    const newMetadata = []

    Object.keys(metadata).map((key) => {
      const field = fields.filter((res) => res.id === key)
      if (field.length > 0)
      {
        newMetadata.push({ 
          displayName: field[0].name,
          value: metadata[key],
          id: key
        })
      }
      else
      {
        newMetadata.push({ 
          displayName: key,
          value: metadata[key],
          id: key
        })
      }
      return true
    })

    const doc = this.state.document
    doc.metadata = newMetadata

    this.setState({
      document: doc
    })
  }

  toggleShowMetadata = () => {
    this.setState({
      showMetadata: !this.state.showMetadata
    })
  }

  render = () => {
    return (
      <div className="backOffice_documentPreview">
        { this.state.loadingDocument
          ? <Loading/>
          : <div>
            <div title={this.state.document.metadata.doc_id} className="backOffice_documentTitle">
              { this.state.document.metadata.doc_id }
            </div>
            <div className="backOffice_documentMetadata_expand" onClick={(e) => this.toggleShowMetadata()}>
              { this.state.showMetadata
                ? '-'
                : '+'
              }
            </div>
            <div className="backOffice_documentContent">
              { this.state.showMetadata &&
                <div className="backOffice_documentMetadata">
                  <div className="backOffice_documentMetadata_title">
                    Document Metadata
                  </div>
                  <div className="backOffice_documentMetadata_container">
                    { this.state.document.metadata.sort(function(a, b) {
                      if (a.displayName < b.displayName) { return -1 }
                      if (a.displayName > b.displayName) { return 1 }
                      return 0
                    }).map((field) => {
                      return (
                        <div key={`doc_metadata_${ field.id }`}>
                          { field.id.startsWith('doc_') &&
                            <div className="backOffice_documentMetadata_field">
                              <div className="backOffice_documentMetadata_field_name" title={ field.id }>{ field.displayName }</div>
                              <div className="backOffice_documentMetadata_field_value">
                                { field.value instanceof Array
                                  ? <div>
                                    { field.value.map((val, index) => (
                                      <div key={`doc_metadata_${ field.id }_value_${ index }`} className="backOffice_documentMetadata_field_listvalue" title={ val }>{ val }</div>
                                    ))}
                                  </div>
                                  : <div className="backOffice_documentMetadata_field_listvalue" title={ field.value }>{ field.value }</div>
                                }
                              </div>
                            </div>
                          }
                        </div>
                      )
                    })}
                  </div>
                </div>
              }
              { this.state.document.content !== ''
                ? <p dangerouslySetInnerHTML={{
                  __html: formatContentWithHighlight(this.state.document.content)
                }}></p>
                : <p>No content stored for document</p>
              }
            </div>
          </div>
        }
      </div>
    )
  }
}

DocumentViewer.propTypes = {
}

export default compose(
  WithDataModels
)(DocumentViewer)