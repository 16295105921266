import {
  findGoToTheQuestion,
  buildDivisors,
  updateOptionsOrder,
  findJumpedQuestion
} from '../Questions/Options/Helpers/OptionsUtils'
import { riskIndicatorsStaticList } from '../../../../utils/StaticTexts'

const denormalizeQuestionsToAPI = (questions, questionnaire) => (
  questions.map((question) => {
    const newQuestion = {
      id: question.id,
      ref: question.ref,
      title: question.title,
      alias: question.alias,
      description: question.description,
      explanation: question.explanation,
      type: question.type,
      riskIndicators: riskIndicatorsToLabel(question),
      deletedOptions: question.deletedOptions,
      options: denormalizeOptionsToApi(question.options, question, questions)
    }

    if (questionnaire.type !== 'PIA') {
      newQuestion.weight = question.weight
    }

    return newQuestion
  })
)

const riskIndicatorsToLabel = (question) => {
  return question.riskIndicators.filter((riskIndicator) => riskIndicator.selected).map((riskIndicator) => (
    riskIndicator.value
  ))
}

const riskIndicatorsParsed = (question) => {
  return riskIndicatorsStaticList.map((riskIndicator) => (
    {
      ...riskIndicator,
      selected: question.riskIndicators.indexOf(riskIndicator.value) !== -1
    }
  ))
}

const denormalizeQuestionsFromAPI = (questions) => {
  const newQuestions = questions.map((question) => (
    {
      ...question,
      ref: (Date.now() + question.id).toString(),
      alias: question.alias || '',
      explanation: question.explanation || '',
      selected: false,
      weight: question.weight || 0,
      riskIndicators: riskIndicatorsParsed(question),
      errors: {},
      deletedOptions: [],
    }
  ))

  return newQuestions.map((question) => (
    {
      ...question,
      options: denormalizeOptionsFromApi(question, newQuestions)
    }
  ))
}

const denormalizeOptionsToApi = (options, question, questions) => {
  options = options.filter((option) => (option.subType !== 'DIVIDER'))
  
  const newOptions = options.map((option) => (
    {
      id: option.id,
      title: option.title,
      score: option.score,
      ...findJumpedQuestion(option.goToQuestion, questions, question),
      message: option.message,
      displaySection: option.displaySection,
      type: option.type
    }
  ))

  return updateOptionsOrder(newOptions)
}

const denormalizeOptionsFromApi = (question, questions) => {
  const newOptions = question.options.map((option) => (
    {
      ...option,
      ...findGoToTheQuestion(option, questions, question),
      message: option.message || '',
      hasMessage: option.message !== null,
      disabled: isDisabledOption(option, question),
      subType: subtype(option),
      errors: {}
    }
  ))

  return updateOptionsOrder(buildDivisors(newOptions))
}

const isDisabledOption = (option, question) => {
  switch (question.type) {
    case 'YN':
      return option.title.toLowerCase() === 'yes' || option.title.toLowerCase() === 'no' || option.title.toLowerCase() === 'i don\'t know'
    case 'Radio':
    case 'Checkbox':
      return option.type === 'OTHER' || option.type === 'NONE_OF_THE_ABOVE'
    default:
      return false
  }
}

const subtype = (option) => {
  if (option.type === 'OTHER' || option.type === 'NONE_OF_THE_ABOVE') return option.type
  if (option.title.toLowerCase() === 'i don\'t know') return 'I_DONT_KNOW'
  return 'NORMAL'
}

export const denormalizeQuestionnaireForDashboard = (questionnaire) => (
  {
    id: questionnaire.id,
    isEnabled: questionnaire.isEnabled,
    isGlobal: questionnaire.isGlobal,
    creationDate: questionnaire.creationDate,
    versions: questionnaire.versions,
    description: questionnaire.description || '',
    title: questionnaire.title,
    type: questionnaire.type
  }
)

export const denormalizeQuestionnaireFromAPI = (questionnaire) => (
  {
    errors: {},
    deletedQuestions: [],
    description: questionnaire.description || '',
    industry: questionnaire.industry || '',
    title: questionnaire.title,
    type: questionnaire.type
  }
)

export const denormalizeToAPI = (questionnaire) => {
  const questions = denormalizeQuestionsToAPI(questionnaire.questions, questionnaire)

  return {
    id: questionnaire.id,
    title: questionnaire.title,
    templateTitle: questionnaire.title,
    description: questionnaire.description,
    templateDescription: questionnaire.description,
    industry: questionnaire.industry,
    templateIndustry: questionnaire.industry,
    type: questionnaire.type,
    deletedQuestions: questionnaire.deletedQuestions,
    questions
  }
}

export const denormalizeFromAPI = (questionnaire) => {
  return {
    ...denormalizeQuestionnaireFromAPI(questionnaire),
    state: questionnaire.state || '',
    version: questionnaire.version || '',
    questions: denormalizeQuestionsFromAPI(questionnaire.questions),
  }
}