import * as types from './actionTypes'
// import {
//   SET_RESPONSE_PROGRESS_BAR_RENAME,
//   SET_SMART_ADD_NOTE,
//   SET_SMART_BOX_CHECK_FIRST_UPLOAD_FILE,
//   SET_SMART_BOX_DATA_READY,
//   SET_SMART_BOX_FOLDER_HAS_BULK,
//   SET_SMART_BOX_PARENT_STATISTICS_READY,
//   SET_SMART_BOX_SECURITY_CONFIGURATION_lIST_MODE_TREE,
//   SET_SMART_BOX_UPLOAD_FAILED,
//   SET_SMART_FILES_NOT_SAR_TRACKER, SET_SMART_NOT_GIVE_ACCESS_BOX,
//   SET_SMART_STATUS_CHANGE
// } from './actionTypes'

export function setSmartBoxUploadingObject(fileList, path) {
  const data = {
    fileList,
    path
  }
  return { type: types.SET_SMART_BOX_UPLOADING_OBJECT, payload: data }
}

export function setFetchFolderContent(value) {
  return { type: types.FETCH_FOLDER_CONTENT, payload: value }
}

export function setSmartBoxSearchedWord(value) {
  return { type: types.SET_SMART_BOX_SEARCHED_TEXT, payload: value }
}

export function setSmartBoxIntervalForAiPages(value) {
  return { type: types.SET_SMART_BOX_INTERVAL_FOR_AI_PAGES, payload: value }
}

export function setSmartBoxIntervalForPdfPages(value) {
  return { type: types.SET_SMART_BOX_INTERVAL_FOR_PDF_PAGES, payload: value }
}

export function removeSmartBoxIntervalForAiPages(value) {
  return { type: types.REMOVE_SMART_BOX_INTERVAL_FOR_AI_PAGES, payload: value }
}

export function removeSmartBoxIntervalForPdfPages(value) {
  return { type: types.REMOVE_SMART_BOX_INTERVAL_FOR_PDF_PAGES, payload: value }
}

export function setSmartBoxViewMode(value) {
  return { type: types.SET_SMART_BOX_VIEW_MODE, payload: value }
}

export function setSmartBoxScrollPositionObject(value) {
  return { type: types.SET_SMART_BOX_SCROLL_POSITION_OBJECT, payload: value }
}
export function setSmartBoxHomeClicked(value) {
  return { type: types.SET_SMART_BOX_HOME_CLICKED, payload: value }
}


export function setSmartBoxIntervalForBulkRedaction(value) {
  return { type: types.SET_SMART_BOX_INTERVAL_FOR_BULK_REDACTION, payload: value }
}

export function removeSmartBoxIntervalForBulkRedaction(value) {
  return { type: types.REMOVE_SMART_BOX_INTERVAL_FOR_BULK_REDACTION, payload: value }
}

export function setSmartBoxExploreDataVisibility(value) {
  return { type: types.SET_SMART_BOX_EXPORT_DATA_VISIBILITY, payload: value }
}

export function setSmartBoxFetchTreeData(value) {
  return { type: types.SET_SMART_BOX_FETCH_TREE_DATA, payload: value }
}

export function setAxiosHeadersCopy(data) {
  return { type: types.SET_AXIOS_HEADERS_COPY, payload: data }
}

export function setSmartBoxProjects(data) {
  return { type: types.SET_SMART_BOX_PROJECTS, payload: data }
}

export function setSmartBoxIntervalForBulkFinalise(value) {
  return { type: types.SET_SMART_BOX_INTERVAL_FOR_BULK_FINALISE, payload: value }
}

export function removeSmartBoxIntervalForBulkFinalise(value) {
  return { type: types.REMOVE_SMART_BOX_INTERVAL_FOR_BULK_FINALISE, payload: value }
}

export function setSmartBoxTree(value) {
  return { type: types.SET_SMART_BOX_TREE, payload: value }
}

export function setSmartBoxCurrentFolder(value) {
  return { type: types.SET_SMART_BOX_CURRENT_FOLDER, payload: value }
}

export function setSmartBoxLoadTree(value) {
  return { type: types.SET_SMART_BOX_LOAD_TREE, payload: value }
}

export function setSmartBoxNewNode(value) {
  return { type: types.SET_SMART_BOX_NEW_NODE, payload: value }
}

export function setSmartBoxFolderClosed(value) {
  return { type: types.SET_SMART_BOX_FOLDER_CLOSED, payload: value }
}

export function setSmartBoxMovedFiles(value) {
  return { type: types.SET_SMART_BOX_MOVED_FILES, payload: value }
}

export function setSmartBoxSelectedFiles(value) {
  return { type: types.SET_SMART_BOX_SELECTED_FILES, payload: value }
}

export function setSmartBoxDeletedFolder(value) {
  return { type: types.SET_SMART_BOX_DELETED_FOLDER, payload: value }
}

export function setSmartBoxFilesTable(value) {
  return { type: types.SET_SMART_BOX_FILES_TABLE, payload: value }
}
export function setSmartBoxFetchFiles(value) {
  return { type: types.SET_SMART_BOX_FETCH_FILES, payload: value }
}
export function setSmartBoxBaseDataTree(value) {
  return { type: types.SET_SMART_BOX_BASE_DATA_TREE, payload: value }
}
export function setSmartBoxToggleFilePreview(fileList, path) {
  const data = {
    fileList,
    path
  }
  return { type: types.SET_SMART_BOX_TOGGLE_FILE_PREVIEW, payload: data }
}
export function setSmartBoxDirectoryNote(value) {
  return { type: types.SET_SMART_BOX_VISIBLE_DIRECTORY_NOTE, payload: value }
}
export function setSmartBoxSelectedDocuments(action, documentId) {
  const data = {
    action,
    documentId
  }
  return { type: types.SET_SMART_BOX_DOCUMENT_SELECTED_ID, payload: data }
}

export function setSmartBoxRenamedFolder(value) {
  return { type: types.SET_SMART_BOX_RENAMED_FOLDER, payload: value }
}

export function setSmartBoxAnalysisPercent(value) {
  return { type: types.SET_SMART_BOX_ANALYSIS_PERCENT, payload: value }
}

export function setSmartBoxParentFolderEmpty(value) {
  return { type: types.SET_SMART_BOX_PARENT_FOLDER_EMPTY, payload: value }
}

export function setSmartBoxParentFolderErrors(value) {
  return { type: types.SET_SMART_BOX_PARENT_FOLDER_ERRORS, payload: value }
}

export function setSmartBoxParentFolderName(value) {
  return { type: types.SET_SMART_BOX_PARENT_FOLDER_NAME, payload: value }
}
export function setSmartBoxFoldersCheckErrors(value) {
  return { type: types.SET_SMART_BOX_FOLDERS_CHECK_ERRORS, payload: value }
}
export function setSmartBoxSelectedDuplicateFile(action, activeTab, hashFile) {
  const data = {
    action,
    activeTab,
    hashFile
  }
  return { type: types.SET_SMART_BOX_DOCUMENT_SELECTED_DUPLICATE, payload: data }
}
export function setSmartBoxMovedFolders(value) {
  return { type: types.SET_SMART_BOX_MOVED_FOLDERS, payload: value }
}
export function setSmartBoxOldParentOfMovedFolders(value) {
  return { type: types.SET_SMART_BOX_OLD_PARENT_OF_MOVED_FOLDERS, payload: value }
}
export function setSmartBoxNewParentOfMovedFolders(value) {
  return { type: types.SET_SMART_BOX_NEW_PARENT_OF_MOVED_FOLDERS, payload: value }
}

export function setSmartBoxSecurityConfiguration(value) {
  return { type: types.SET_SMART_BOX_SECURITY_CONFIGURATION, payload: value }
}
export function setSmartBoxResponseDeleteDuplicates(responseApi, statusDelete) {
  const data = {
    responseApi,
    statusDelete
  }
  return { type: types.SET_SMART_BOX_RESPONSE_DELETE_DUPLICATES, payload: data }
}
export function setSmartBoxProgressCleanDeleteDuplicates(statusApi, statusDelete) {
  const data = {
    statusApi,
    statusDelete
  }
  return { type: types.SET_SMART_BOX_PROGRESS_CLEAN_DELETE_DUPLICATES, payload: data }
}
export function setSmartBoxCheckFirstUploadFile(statusFolder, folder) {
  const data = {
  statusFolder,
    folder
  }
  return { type: types.SET_SMART_BOX_CHECK_FIRST_UPLOAD_FILE, payload: data }
}
export function setSmartBoxNotificationButtonFirstUpload(statusUpload, folderName) {
  const data = {
    statusUpload,
    folderName
  }
  return { type: types.SET_SMART_BOX_NOTIFICATION_BUTTON_FIRST_UPLOAD, payload: data }
}

export function setSmartBoxPageLimit(value) {
  return { type: types.SET_SMART_BOX_PAGE_LIMIT, payload: value }
}
export function setListTermsPageLimit(value) {
  return { type: types.SET_LIST_TERMS_PAGE_LIMIT, payload: value }
}
export function setOverviewPageLimit(value) {
  return { type: types.SET_OVERVIEW_PAGE_LIMIT, payload: value }
}
export function setBusinessTermsPageLimit(value) {
  return { type: types.SET_BUSINESS_TERMS_PAGE_LIMIT, payload: value }
}
export function setSmartBoxDataReady(statusFolder, folder) {
  const data = {
    statusFolder,
    folder
  }
  return { type: types.SET_SMART_BOX_DATA_READY, payload: data }
}
export function setSmartBoxTreeCurrentDepth(value) {
  return { type: types.SET_SMART_BOX_TREE_CURRENT_DEPTH, payload: value }
}
export function setSmartBoxCurrentSortOption(value) {
  return { type: types.SET_SMART_BOX_CURRENT_Sort_Option, payload: value }
}
export function setSmartBoxFolderHasBulk(value) {
  return { type: types.SET_SMART_BOX_FOLDER_HAS_BULK, payload: value }
}
export function setSmartBoxErrorLoadingThumbnails(status, nameActionEvent, descriptionActionEvent, timesEvent) {
  const data = {
    status,
    nameActionEvent,
    descriptionActionEvent,
    timesEvent
  }
  return { type: types.SET_SMART_BOX_ERROR_LOADING_THUMBNAILS, payload: data }
}
export function setSortSelectionCloseSmartview(value) {
  return { type: types.SET_SORT_CLOSE_SMART, payload: value }
}
export function setResponseProgressBarRename(listProgressRename, listBox, statusRename) {
  const dataProgress = {
    listProgressRename,
    listBox,
    statusRename
  }
  return { type: types.SET_RESPONSE_PROGRESS_BAR_RENAME, payload: dataProgress }
}
export function setSmartBoxParentStatisticsReady(value) {
  return { type: types.SET_SMART_BOX_PARENT_STATISTICS_READY, payload: value }
}
export function setSmartFilesErrorNotBeConverted(value) {
  return { type: types.SET_SMART_FILES_ERROR_NOT_BE_CONVERTED, payload: value }
}
export function setSmartBoxUploadFailed(value) {
  return { type: types.SET_SMART_BOX_UPLOAD_FAILED, payload: value }
}
export function setSmartBoxGenerateUniqueId(value) {
  return { type: types.SET_SMART_BOX_GENERATE_UNIQUE_ID, payload: value }
}
export function setSmartBoxNotSarTracker(value) {
  return { type: types.SET_SMART_NOT_SAR_TRACKER, payload: value }
}
export function setSmartBoxNotFolderRedactionComplete(value) {
  return { type: types.SET_SMART_NOT_FOLDER_REDACTION_COMPLETE, payload: value }
}
export function setSmartBoxAddedNote(value) {
  return { type: types.SET_SMART_ADD_NOTE, payload: value }
}
export function setSmartBoxStatusChange(value) {
  return { type: types.SET_SMART_STATUS_CHANGE, payload: value }
}
export function setSmartBoxPerPageDuplicate(value) {
  return { type: types.SET_SMART_PER_PAGE_DUPLICATE, payload: value }
}
export function setSmartBoxNotUploadComplete(value) {
  return { type: types.SET_SMART_NOT_UPLOAD_COMPLETE, payload: value }
}
export function setSmartBoxNotGiveAccessBox(value) {
  return { type: types.SET_SMART_NOT_GIVE_ACCESS_BOX, payload: value }
}
export function setSmartBoxNotAnalysisComplete(value) {
  return { type: types.SET_SMART_NOT_ANALYSIS_COMPLETE, payload: value }
}
export function setSmartBoxNotAccessPermission(value) {
  return { type: types.SET_SMART_NOT_ACCESS_PERMISSION, payload: value }
}
export function setSmartBoxFolderCountTree(value) {
  return { type: types.SET_SMART_BOX_FOLDER_COUNT_TREE, payload: value }
}
export function setSmartFolderCountTree(value) {
  return { type: types.SET_SMART_FOLDER_COUNT_TREE, payload: value }
}
export function setSmartBoxFilterRefreshOpenedBox(filter, sort, folderPath, search) {
  const data = {
    filter,
    sort,
    folderPath,
    search
  }
  return { type: types.SET_SMART_BOX_FILTER_REFRESH_OPENED_BOX, payload: data }
}
export function setSmartReprocessingFileDelete(value) {
  return { type: types.SET_SMART_REPROCESSING_FILE_DELETE, payload: value }
}
export function setSmartBoxBaseList(value) {
  return { type: types.SET_SMART_BOX_BASE_LIST, payload: value }
}
export function setSmartBoxSortSearch(sort) {
  const data = {
    sort,
  }
  return { type: types.SET_SMART_BOX_SORT_SEARCH, payload: data }
}
export function setSmartBoxCheckStatusDeduplication(value) {
  return { type: types.SET_SMART_BOX_CHECK_STATUS_DEDUPLICATION, payload: value }
}
export function setSmartBoxShowUploadNavigation(value) {
  return { type: types.SET_SMART_BOX_SHOW_UPLOAD_NAVIGATION, payload: value }
}
export function setSmartBoxCurrentFilterOption(value) {
  return { type: types.SET_SMART_BOX_CURRENT_Filter_Option, payload: value }
}
export function setSmartBoxNewBox(keysPii, checkedBusiness, selectRegexList, statusPii, statusBusiness, statusRegEx) {
  const data = {
    keysPii,
    checkedBusiness,
    selectRegexList,
    statusPii,
    statusBusiness,
    statusRegEx,
  }
  return { type: types.SET_SMART_BOX_NEW_BOX, payload: data }
}
export function setSmartBoxCheckBoxRedactionStatus(value) {
  return { type: types.SET_SMART_BOX_CHECK_BOX_REDACTION_STATUS, payload: value }
}

export function setCollectDataMapping(value) {
  return { type: types.SET_COLLECT_DATA_MAPPING, payload: value }
}

export function setAdminModifyClient(value) {
  return { type: types.SET_ADMIN_MODIFY_CLIENT, payload: value }
}

export function setSmartBoxCurrentFolderName(value) {
  return { type: types.SET_SMART_BOX_CURRENT_FOLDER_NAME, payload: value }
}
export function setSmartBoxSourceDrop(value) {
  return { type: types.SET_SMART_BOX_SOURCE_DROP, payload: value }
}
export function setLeftNavigationTreeShow(value) {
  return { type: types.SET_LEFT_NAVIGATION_TREE_SHOW, payload: value }
}

export function setSmartBoxCurrentNewFilters(value) {
  return { type: types.SET_SMART_BOX_CURRENT_NEW_FILTERS, payload: value }
}
export function setSmartBoxCurrentNewFiltersSort(value) {
  return { type: types.SET_SMART_BOX_CURRENT_NEW_FILTERS_SORT, payload: value }
}

export function setSmartBoxClickOutsideFilter(value) {
  return { type: types.SET_SMART_BOX_CLICK_OUTSIDE_FILTER, payload: value }
}

export function setRedactionNonSelectedWords(value) {
  return { type: types.SET_REDACTION_NON_SELECTED_WORDS, payload: value }
}

export function setStatusClearAllFiltersSmartbox(value) {
  return { type: types.SET_STATUS_CLEAR_ALL_FILTERS_SMARTBOX, payload: value }
}

export function setSmartBoxSelectedKeys(value) {
  return { type: types.SET_SMART_BOX_SELECTED_KEYS, payload: value }
}

export function setBoxListViewSelectedKeys(value) {
  return { type: types.SET_BOX_LIST_VIEW_SELECTED_KEYS, payload: value }
}

export function setProgressEventUpload(value) {
  return { type: types.SET_PROGRESS_EVENT_UPLOAD, payload: value }
}

export function setActiveDeleteFile(value) {
  return { type: types.SET_ACTIVE_DELETE_FILE, payload: value }
}

export function setGetDuplicateCardInfo(value) {
  return { type: types.SET_DUPLICATE_CARD_INFO, payload: value }
}

export function setGetVariablesEmailsWorkflow(value) {
  return { type: types.SET_GET_VARIABLES_EMAILS_WORKFLOW, payload: value }
}
export function setSmartBoxExistsFilesWarningsNotification(value) {
  return { type: types.SET_SMART_BOX_EXISTS_FILES_WARNINGS_NOTIFICATION, payload: value }
}

export function setImageProfileSettings(value) {
  return { type: types.SET_IMAGE_PROFILE_SETTINGS, payload: value }
}

export function setSmartBoxPreviousCurrentPage(previousPage, statusPrevious) {
  const data = {
    previousPage,
    statusPrevious
  }
  return { type: types.SET_SMART_BOX_PREVIOUS_CURRENT_PAGE, payload: data }
}