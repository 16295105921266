import React from 'react'
import { Menu, Layout, Icon } from 'antd'
import { Link } from 'react-router-dom'
import './Sidebar.scss'

const { SubMenu } = Menu
const { Sider } = Layout
const routes = [
  {
    key: 'client-user-data',
    title: 'Tenant/User Data',
    icon: <Icon type="team" />,
    children: [
      {
        key: 'client-data',
        title: 'Tenant Data',
        url: '/admin/client-data'
      },
      {
        key: 'user-data',
        title: 'User Data',
        url: '/admin/user-data'
      }
    ]
  },
  // {
  //   key: 'subscription-data',
  //   title: 'Manage Subscriptions',
  //   icon: <Icon type="file-protect" />,
  //   children: [
  //     {
  //       key: 'client-subscription',
  //       title: 'Client Subscription',
  //       url: '/admin/clients-subscriptions'
  //     }
  //   ]
  // },
  {
    key: 'email-templates',
    title: 'Email Templates',
    icon: <Icon type="read" />,
    children: [
      {
        key: 'default-email-templates',
        title: 'Templates',
        url: '/admin/email-templates'
      }
    ]
  },
  {
    key: 'annotations',
    title: 'Annotations',
    icon: <Icon type="read" />,
    children: [
      {
        key: 'annotation-colors',
        title: 'Annotation Colors',
        url: '/admin/annotation-colors'
      }
    ]
  },
  {
    key: 'tags',
    title: 'Tags',
    icon: <Icon type="read" />,
    children: [
      {
        key: 'annotation-tags',
        title: 'Tags',
        url: '/admin/tag-document'
      }
    ]
  },
  {
    key: 'wallboard',
    title: 'Wallboard',
    icon: <Icon type="dashboard" />,
    children: [
      {
        key: 'wallboard-admin',
        title: 'Osprey Monitoring',
        url: '/admin/wallboard'
      }
    ]
  },
  {
    key: 'backoffice',
    title: 'Back Office',
    icon: <Icon type="dashboard" />,
    children: [
      {
        key: 'back-office-processes',
        title: 'Processes',
        url: '/admin/processes'
      },
      {
        key: 'back-office-workspaces',
        title: 'Workspaces',
        url: '/admin/workspaces'
      },
      {
        key: 'back-office-datalines',
        title: 'Datalines',
        url: '/admin/datalines'
      }
    ]
  },
  {
    key: 'request',
    title: 'Request Tracker',
    icon: <Icon type="dashboard" />,
    children: [
      {
        key: 'request-processes',
        title: 'Request Tracker',
        url: '/admin/request-tracker'
      },
    ]
  },
  // {
  //   key: 'token',
  //   title: 'Token Management',
  //   icon: <Icon type="dashboard" />,
  //   children: [
  //     {
  //       key: 'token-management',
  //       title: 'Token Management',
  //       url: '/admin/token-management'
  //     },
  //   ]
  // },
  {
    key: 'branding',
    title: 'Branding profiles',
    icon: <Icon type="read" />,
    //url: '/admin/branding-profiles',
    children: [
      {
        key: 'branding-profiles',
        title: 'Branding profiles',
        url: '/admin/branding-profiles'
      },
    ]
  },
  {
    key: 'template-workflow',
    title: 'Workflow templates',
    icon: <Icon type="read" />,
    //url: '/admin/branding-profiles',
    children: [
      {
        key: 'workflow-templates',
        title: 'Workflow templates',
        url: '/admin/template-workflow'
      },
    ]
  },
  {
    key: 'forms-workflow',
    title: 'Forms workflow',
    icon: <Icon type="read" />,
    //url: '/admin/branding-profiles',
    children: [
      {
        key: 'workflow-forms',
        title: 'Forms workflow',
        url: '/admin/forms-workflow'
      },
    ]
  },
  {
    key: 'email-workflow',
    title: 'Email workflow',
    icon: <Icon type="read" />,
    //url: '/admin/branding-profiles',
    children: [
      {
        key: 'workflow-email',
        title: 'Email workflow',
        url: '/admin/email-workflow'
      },
    ]
  },
]

class SideBar extends React.Component {
  //** Default state values
  // openKeys mean which menu will be open, in case of new menu
  // item include his key in this array
  // */
  state = {
    selectedKeys: [],
    openKeys: ['client-user-data', 'subscription-data', 'email-templates', 'annotation-colors', 'wallboard'],
    isSidebarOpen: false
  }

  componentDidMount() {
    const currentUrl = window.location.pathname.split('/').splice(-1)[0]
    routes.map((parent) => {
      if (parent.children) {
        parent.children.map((child) => {
          if (child.url.includes(currentUrl)) {
            this.setState({
              selectedKeys: [child.key]
            })
          }
          return true
        })
      } else if (parent.url === currentUrl) {
        this.setState({
          selectedKeys: [parent.key]
        })
      }
      return true
    })
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.isSidebarOpen !== this.props.isSidebarOpen) {
      this.setState({
        isSidebarOpen: nextProps.isSidebarOpen
      })
    }
  }

  menuItemClicked = (e, parent, child) => {
    if (child) {
      this.setState({
        selectedKeys: [child.key]
      })
    } else {
      this.setState({
        selectedKeys: [parent.key]
      })
    }
  }

  render() {
    const { selectedKeys, openKeys, isSidebarOpen } = this.state
    return (
      <Sider trigger={null} className="admin-navbar" collapsible collapsed={isSidebarOpen} theme="light">
        <div className="sidebar-header logo">
          {isSidebarOpen ? '': 'Admin Dashboard'}
        </div>
        <Menu
          style={{ width: 256 }}
          mode="inline"
          selectedKeys={ selectedKeys }
          defaultOpenKeys={ openKeys }
        >
          {routes.map((parentRoute) => {
            if (parentRoute.children) {
              return <SubMenu
                key={parentRoute.key}
                title={
                  <span>
                    {parentRoute.icon}
                    <span>{parentRoute.title}</span>
                  </span>
                }
              >
                {parentRoute.children.map((childrenRoute) => (
                  <Menu.Item key={childrenRoute.key}><span><Link to={childrenRoute.url} onClick={(e) => this.menuItemClicked(e, parentRoute, childrenRoute)}>{childrenRoute.title}</Link></span></Menu.Item>
                ))}
              </SubMenu>
            }
            return <Menu.Item key={parentRoute.key} icon={parentRoute.icon} onClick={(e) => this.menuItemClicked(e, parentRoute)}>{parentRoute.title}</Menu.Item>

          })}
        </Menu>
      </Sider>

    )
  }
}

export default SideBar
