import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getBpList } from '../../../api/Dpia'
import Loading from '../../Common/Loading'
import PageTitle from '../../Common/PageTitle'
import './Dashboard.scss'
import Assessment from './Assessment'
import ProcessDetailsLocked from './ProcessDetails/Locked'
import ProcessDetailsUnLocked from './ProcessDetails/UnLocked'
import LockedHeader from './Type/Locked'
import UnlockedHeader from './Type/Unlocked'
import { apiTeamFetchTeamsList } from '../../../api/Team'
import withRouter from '../../../private-routers/withRouter'

export class DashboardArchived extends Component {
  _isMounted = false;
  constructor() {
    super()

    this.state = {
      accordion: {},
      isLoading: true,
      dpiaList: [],
      piaList: [],
      nonSensitiveList: [],
      list: [],
      listComplete: [],
      chosenView: 'cards',
      usersList: [],
      teamsList: [],
      teamId: null
    }

    this.toggleAccordion = this.toggleAccordion.bind(this)
  }

  componentDidMount() {
    window.scroll(0, 0)
    this._isMounted = true
    this.apiPiaFilterList()
    this.apiDpiaFilterList()
    this.getTeamsList()

  }
  componentWillUnmount() {
    this._isMounted = false
  }
  getTeamsList() {
    apiTeamFetchTeamsList()
      .then((response) => {
        if (this._isMounted) {
          this.setState({
            teamsList: response.data,
          })
        }
      })
  }
  apiPiaFilterList(data) {
    data = {
      'pageNumber': 1,
      'amountPerPage': this.state.resultsPerPagePia,
      'type': 'PIA_QUESTIONNAIRE',
      'status': 'INACTIVE',
      'userId': this.props.user.id,
      'teamId': this.state.teamId ? this.state.teamId: null,
      'userIdLong': this.props.user.id,
      'sort': this.state.completition ? this.state.completition: null,
      'subscription': this.props.subscription
    }
    getBpList(data)
      .then((response) => {
        let piaList
        response.data.total>0 ? piaList = response.data.businessProcesses: piaList = null
        if (this._isMounted) {
          this.setState({
            isLoading: false,
            piaList: piaList,
            numberOfPia: response.data.total,
            usersList: [...this.state.usersList, ...response.data.usersList]
          })
        }
      })

  }
  apiDpiaFilterList(data) {
    data = {
      'pageNumber': 1,
      'amountPerPage': this.state.resultsPerPagePia,
      'type': 'DPIA_QUESTIONNAIRE',
      'status': 'INACTIVE',
      'userId': this.props.user.id,
      'teamId': this.state.teamId ? this.state.teamId: null,
      'sort': this.state.completition ? this.state.completition: null,
      'subscription': this.props.subscription
    }
    getBpList(data)
      .then((response) => {
        let dpiaList
        response.data.total>0 ? dpiaList = response.data.businessProcesses : dpiaList = null
        if (this._isMounted) {
          this.setState({
            isLoading: false,
            dpiaList: dpiaList,
            numberOfDpia: response.data.total,
            usersList: [...this.state.usersList, ...response.data.usersList]
          })
        }
      })

  }

  toggleAccordion(accordion, index) {
    this.setState({
      accordion: {
        ...accordion,
        [index]: !accordion[index]
      }
    })
  }

  renderAssessmentList(process) {
    const { assessments } = process

    return assessments.map((assessment) => (
      <Assessment
        assessment={ assessment }
        key={ assessment.id }
        process={ process }
        processStatus="INACTIVE"
      />
    ))
  }

  renderProcess(process) {
    return (
      <React.Fragment>
        { process.isLocked &&
          <ProcessDetailsLocked 
            process={ process }
          />
        }

        { !process.isLocked &&
          <ProcessDetailsUnLocked 
            process={ process }
          />
        }
        { this.renderAssessmentList(process) }
      </React.Fragment>
    )
  }

  renderProcessList(processList, type) {
    const { accordion } = this.state
    const { subscription } = this.props
    return processList.map((process) => {
      const props = {
        accordion,
        content: this.renderProcess(process),
        index: process.assessments[0].id,
        process,
        subscription,
        type,
        processStatus: 'INACTIVE',
        dpiaQuestionnaireType: this.dpiaQuestionnaireType(process),
        toggleAccordion: this.toggleAccordion,
        usersList: this.state.usersList,
        chosenView: this.state.chosenView,
        user: this.props.user
      }

      if (process.isLocked) return (
        <LockedHeader key={ process.id } { ...props } />
      )

      return (<UnlockedHeader key={ process.id } { ...props } teams ={this.state.teamsList} />)
    })
  }

  dpiaQuestionnaireType({ dpiaQuestionnaireType }) {
    const groups = {
      'DATA_CONTROLLER_DPIA': 'Data Controller',
      'DATA_PROCESSOR_DPIA': 'Data Processor',
      '': 'I don\'t know'
    }
    return groups[dpiaQuestionnaireType]
  }

  render() {
    const {
      isLoading,
      dpiaList,
      piaList,
      nonSensitiveList,
    } = this.state

    return (
      <div className="dpiaDashboard-page">
        { isLoading &&
          <div className="dpiaDashboard-loading">
            <Loading />
          </div>
        }

        { !isLoading &&
          !dpiaList &&
          !piaList &&
          !nonSensitiveList &&
          <div className="dpiaDashboard-message">
            <PageTitle title="Archived Processes" />
            <p>
              There are no archived processes.
            </p>
          </div>
        }

        { !isLoading &&
          dpiaList &&
          dpiaList.length > 0 &&
          <div>
            <h2 className="dpiaDashboard__block-title">DPIA</h2>
            { this.renderProcessList(dpiaList, 'DPIA_QUESTIONNAIRE') }
          </div>
        }

        { !isLoading &&
          piaList &&
          piaList.length > 0 &&
          <div>
            <h2 className="dpiaDashboard__block-title">PIA</h2>
            { this.renderProcessList(piaList, 'PIA_QUESTIONNAIRE') }
          </div>
        }

        { !isLoading &&
          nonSensitiveList &&
          nonSensitiveList.length > 0 &&
          <div>
            <h2 className="dpiaDashboard__block-longTitle">NON SENSITIVE</h2>
            { this.renderProcessList(nonSensitiveList) }
          </div>
        }

      </div>
    )
  }
}
DashboardArchived = withRouter(DashboardArchived)
const stateMap = (state) => ({
  user: state.userReducer.user,
  subscription: state.subscriptionReducer.subscription
})

DashboardArchived.propTypes = {
  user: PropTypes.object.isRequired,
  subscription: PropTypes.object.isRequired
}

export default compose(
  connect(stateMap, null)
)(DashboardArchived)