import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { connect } from 'react-redux'
import CustomNotificationManager from '../components/Common/CustomNotificationManager'

const DataMappingPrivateRouter = ({ component: Component, ...rest }) => {
    if (rest.isLoggedIn) {
        const found = rest.dataMappingAppVisible
        if (found) {
            return <Outlet {...rest}/>
        }
        // CustomNotificationManager.info('Access restricted', 'Unauthorized')
        return <Navigate to="/smart-box/management"/>
    }
    return <Navigate to="/osp/login"/>
}


const mapStateToProps = (state) => ({
    isLoggedIn: state.userReducer.isLogged,
    dataMappingAppVisible: state.applications.dataMappingAppVisible
})

export default connect(mapStateToProps, {})(DataMappingPrivateRouter)