import PropTypes from 'prop-types'
import React from 'react'
import { Modal } from 'antd'
import './TermsPrivacy.scss'

const PrivacyPolicy = ({ hidePrivacyPolicy }) => (
  <Modal visible={ hidePrivacyPolicy } centered={true} maskClosable={true} style={{ width: '60%' }} footer={ null } onCancel={ hidePrivacyPolicy } className="terms-privacy-modal">
    <div className="modal__padding">
      <h1 className="modal-title">Gravicus Technologies Limited Privacy Policy</h1>
      
      <p className="modal-text">This privacy policy sets out how Gravicus uses and protects any information that you give Gravicus when you use Osprey Applications.</p>
      <p className="modal-text">Gravicus is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using Osprey, then you can be assured that it will only be used in accordance with this privacy statement.</p>
      <p className="modal-text">Gravicus may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes. This policy is effective from 1st October 2017</p>
      
      <h2 className="modal-subtitle">What we collect</h2>
      <p className="modal-text">We may collect the following information:</p>
      <ul className="modal-navigation">
        <li className="modal-listItem">name and job title</li>
        <li className="modal-listItem">contact information including email address</li>
        <li className="modal-listItem">demographic information such as postcode, preferences and interests</li>
        <li className="modal-listItem">other information relevant to customer surveys and/or offers</li>
        <li className="modal-listItem">Information about how you use an Osprey Application</li>
        <li className="modal-listItem">We may collect information sent to your browser or device to our servers whenever you use an Osprey Application, which may include your IP address, and the type of browser and device you used</li>
        <li className="modal-listItem">Payment information, including bank or credit card details for Osprey subscription management</li>
      </ul>

      <h2 className="modal-subtitle">What we do with the information we gather</h2>
      <p className="modal-text">We require this information to create and manage your Osprey account, internal record keeping, manage support requests, understand your needs and provide you with a better service, we may also use your information for:</p>
      <ul className="modal-navigation">
        <li className="modal-listItem">We may use the information to improve our products and services.</li>
        <li className="modal-listItem">We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided.</li>
        <li className="modal-listItem">From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone, fax or mail.</li>
      </ul>

      <h2 className="modal-subtitle">Security</h2>
      <p className="modal-text">We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.</p>

      <h2 className="modal-subtitle">Controlling your personal information</h2>
      <p className="modal-text">You may choose to restrict the collection or use of your personal information in the following ways:</p>
      <ul className="modal-navigation">
        <li className="modal-listItem">whenever you are asked to fill in a form within an Osprey Application, look for the box that you can click to indicate that you do not want the information to be used by anybody for direct marketing purposes</li>
        <li className="modal-listItem">if you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by writing to or emailing us at info@gravicus.com</li>
      </ul>

      <p className="modal-text">We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so. We may use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you wish this to happen.</p>
      <p className="modal-text">You may request details of personal information which we hold about you under the Data Protection Act 1998. If you would like a copy of the information held on you please write to:</p>

      <p className="modal-address">
        Gravicus Technologies Limited<br />
        1 Fore St<br />
        London<br />
        EC2Y 9DT<br />
        England
      </p>

      <p className="modal-text">If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible, at the above address. We will promptly correct any information found to be incorrect.</p>
    </div>
  </Modal>
)

PrivacyPolicy.propTypes = {
  hidePrivacyPolicy: PropTypes.func.isRequired
}

export default PrivacyPolicy
