import React from 'react'
import PropTypes from 'prop-types'
import { Select, Input } from 'antd'
import { findAssetRegisterByColumnAndValue, getAssetRegisterColumnNames } from '../../../../api/AssetRegister'
import { connect } from 'react-redux'
import { compose } from 'redux'
const { Option } = Select

class FindAsset extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      searchCategory: [],
      searchText: '',
      searchColumn: '',
      searchPressed: false,
      resultsFound: 0,
      allRegisters: [],
      allRegistersList: []
    }
  }

  componentDidMount() {
    getAssetRegisterColumnNames(this.props.clientId).then((response) => {
      if (response.status === 200 && response.data) {
        this.setState({ searchCategory: response.data })
      }
    })
  }

  changeSearchText = (e) => {
    this.setState({ searchText: e.target.value })
  }

  changeSearchType = (e) => {
    this.setState({ searchColumn: e })
  }

  search = () => {
    const data = {
      'searchedColumn': this.state.searchColumn,
      'searchedValue': this.state.searchText
    }
    findAssetRegisterByColumnAndValue(data).then((ressponse) => {
      const results = ressponse.data
      this.setState({
        searchColumn: this.state.searchColumn,
        searchText: this.state.searchText,
        allRegistersList: results.list,
        searchPressed: true,
        resultsFound: results.count
      })
      this.props.receiveAllSearchResult(results.list)
    })
  }

  render() {
    const { searchPressed, resultsFound, searchText, searchColumn, searchCategory } = this.state

    return (
      <div className="add-asset-page">
        <div className="add-asset-content">
          <div className="select-search">
            <Select placeholder="Search by..." onChange={ (e) => this.changeSearchType(e) }>
              { searchCategory.map((category, index) => {
                return (<Option key={ index } value={ category }>{ category }</Option>)
              })
              }
            </Select>
          </div>
          <div className="input-search">
            <Input value={this.state.searchText} placeholder="Search..." onChange={ (e) => this.changeSearchText(e) } />
          </div>
        </div>
        <div className="search-button-asset">
          <button className={(searchColumn === '' || searchText === '') ? 'button-disabled-light float-right' : 'button-blue-light float-right'} disabled={searchColumn === '' || searchText === ''} onClick={ () => this.search() } >SEARCH</button>
        </div>
        { searchPressed &&
          <div className="results-number text-center">
            { resultsFound === 0 ? (
              <p className="view-result"> No Results Found</p>
            ) : (
              <p className="view-result"> { resultsFound } Results Found</p>
            )}
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id
})

FindAsset.propTypes = {
  registerList: PropTypes.object,
  receiveAllSearchResult: PropTypes.func
}

export default compose(
  connect(mapStateToProps)
)(FindAsset)