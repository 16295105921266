import React from 'react'

import { FilesConsumer } from '../../../contexts/Files'

const WithFiles = (Component) => {
  return class WithFiles extends React.Component {
    render = () => {
      return (
        <FilesConsumer>
          { (props) => (
            <Component {...this.props} {...props} />
          )}
        </FilesConsumer>
      )
    }
  }
}

export default WithFiles