import { cloneObject } from '../../../../../utils'
import { riskIndicatorsStaticList } from '../../../../../utils/StaticTexts'
import { updateQuestionsOrder } from './Utils'

export default ({ questions, currentOrder }) => {
  const newQuestion = {
    alias: '',
    isValid: true,
    errors: {},
    title: '',
    explanation: '',
    deletedOptions: [],
    options: [],
    order: currentOrder + 1,
    ref: (Date.now() + currentOrder + 1).toString(),
    riskIndicators: cloneObject(riskIndicatorsStaticList),
    selected: false,
    type: '',
    weight: 0,
  }

  const questionsBefore = [
    ...questions.slice(0, currentOrder)
  ]

  const questionsAfter = [
    ...questions.slice(currentOrder)
  ]

  return updateQuestionsOrder([
    ...questionsBefore,
    newQuestion,
    ...questionsAfter
  ])
}