import initialState from './initialState'
import {
  ANSWER_HIDE_RESPONSE,
  ANSWER_SHOW_RESPONSE,
  ANSWER_UPDATE_VALUE_FOR_CHECKBOX,
  ANSWER_UPDATE_VALUE_FOR_OTHER,
  ANSWER_UPDATE_VALUE_FOR_RADIO,
  FETCH_QUESTIONS,
  QUESTIONS_CALCULATE_PROGRESS,
  QUESTIONS_SET_ASSESSMENT_ID,
  QUESTIONS_UPDATE_QUESTION,
  RECEIVE_ACTIVE_QUESTION,
  RECEIVED_ASSIGNED_USER,
  RECEIVE_QUESTIONS,
  RECEIVED_ASSESSMENT,
  RECEIVED_ASSESSMENT_ERROR,
  RECEIVED_PROCESS,
  SET_TO_LOADING_QUESTIONS_SCREEN,
  SET_TO_LOADING_PIA_RESULT_SCREEN,
  SET_CURRENT_USER_AS_ASSIGNED,
  UNDO_SKIPPED_QUESTIONS
} from '../actions/actionTypes'

export default (state = initialState, action) => {
  switch (action.type) {
    case QUESTIONS_CALCULATE_PROGRESS:
      const { questionsResults, questionsStateStats } = state.assessment
      const allQuestions = questionsStateStats.QUESTIONNAIRE_SKIPPED_NO +
                           questionsStateStats.SUBMITTED_NO +
                           questionsStateStats.UNANSWERED_NO +
                           questionsStateStats.USER_SKIPPED_NO

      const answered = questionsResults.SUBMITTED.length +
                       questionsResults.QUESTIONNAIRE_SKIPPED.length
      const progress = Math.trunc((answered / allQuestions) * 100)

      return {
        ...state,
        questions: {
          ...state.questions,
          complete: progress === 100,
          progress
        }
      }

    case RECEIVED_ASSESSMENT_ERROR:
      return {
        ...state,
        assessment: {
          ...state.assessment,
          error: action.error,
          isLoading: false,
        },
        questions: {
          ...state.questions,
          isLoading: false
        }
      }

    case RECEIVED_ASSIGNED_USER:
      return {
        ...state,
        assessment: {
          ...state.assessment,
          process: {
            ...state.assessment.process,
            assignedUser: {
              ...state.user,
              ...action.user,
              isLoading: false
            }
          }
        }
      }
    
    case SET_CURRENT_USER_AS_ASSIGNED:
      return {
        ...state,
        assessment: {
          ...state.assessment,
          process: {
            ...state.assessment.process,
            assignedUser: {
              ...state.user,
              isLoading: false
            }
          }
        }
      }
      
    case RECEIVED_ASSESSMENT:
      return {
        ...state,
        assessment: {
          ...state.assessment,
          ...action.assessment,
          error: null,
          isLoading: false,
          isPia: action.assessment.type === 'PIA_QUESTIONNAIRE'
        }
      }
    
    case RECEIVED_PROCESS:
      return {
        ...state,
        assessment: {
          ...state.assessment,
          process: {
            ...state.assessment.process,
            ...action.process,
            isLoading: false
          }
        }
      }

    case UNDO_SKIPPED_QUESTIONS:
      const skippedQuestionIds = action.questionIds
      const skippedList = state.assessment.questionsResults.QUESTIONNAIRE_SKIPPED
      const newSkippedList = skippedList.filter((skippedOption) => (skippedQuestionIds.indexOf(skippedOption.id) === -1))

      return {
        ...state,
        assessment: {
          ...state.assessment,
          questionsResults: {
            ...state.assessment.questionsResults,
            QUESTIONNAIRE_SKIPPED: newSkippedList
          }
        }
      }

    case QUESTIONS_UPDATE_QUESTION:
      const newQuestion = {
        id: action.question.id,
        options: action.question.options.filter((option) => (option.isSelected)).map((option) => (option.id))
      }
      const newResults = state.assessment.questionsResults

      newResults.SUBMITTED = newResults.SUBMITTED.filter((question) => (question.id !== action.question.id))
      newResults.QUESTIONNAIRE_SKIPPED = newResults.QUESTIONNAIRE_SKIPPED.filter((question) => (question.id !== action.question.id))
      newResults.USER_SKIPPED = newResults.USER_SKIPPED.filter((question) => (question.id !== action.question.id))

      newResults[action.status].push(newQuestion)

      return {
        ...state,
        assessment: {
          ...state.assessment,
          questionsResults: newResults
        }
      }

    case FETCH_QUESTIONS:
      return action

    case ANSWER_HIDE_RESPONSE:
      return {
        ...state,
        questions: {
          ...state.questions,
          showResponse: false,
          responseText: ''
        }
      }
         
    case ANSWER_SHOW_RESPONSE:
      return {
        ...state,
        questions: {
          ...state.questions,
          responseText: action.text,
          showResponse: true
        }
      }

    case ANSWER_UPDATE_VALUE_FOR_CHECKBOX:
      const newOptionsForCheckbox = state.questions.activeQuestion.options.map((option) => {
        
        // We need to handle the case for "None of the above"
        if (action.data.option.type === 'NONE_OF_THE_ABOVE' && action.data.value) {

          // Update the value for isSelected on the current option if it is the option clicked
          if (option.id === action.data.option.id) {
            return {
              ...option,
              isSelected: action.data.value
            }
          }

          return {
            ...option,
            isSelected: false
          }
        }

        if (option.type === 'NONE_OF_THE_ABOVE') {
          return {
            ...option,
            isSelected: false
          }
        }

        // Update the value for isSelected on the current option if it is the option clicked
        if (option.id === action.data.option.id) {
          return {
            ...option,
            isSelected: action.data.value
          }
        }

        return {
          ...option
        }
      })

      return {
        ...state,
        questions: {
          ...state.questions,
          responseText: action.data.option.message,
          showResponse: action.data.option.message,
          activeQuestion: {
            ...state.questions.activeQuestion,
            options: newOptionsForCheckbox
          }
        }
      }

    case ANSWER_UPDATE_VALUE_FOR_OTHER:
      const newOptionsForOther = state.questions.activeQuestion.options.map((option) => {
        // Update the value for otherOptionText on the current option if it is the option clicked
        if (option.id === action.data.option.id) {
          return {
            ...option,
            otherOptionText: action.data.value
          }
        }

        return {
          ...option
        }
      })

      return {
        ...state,
        questions: {
          ...state.questions,
          activeQuestion: {
            ...state.questions.activeQuestion,
            options: newOptionsForOther
          }
        }
      }

    case ANSWER_UPDATE_VALUE_FOR_RADIO:
      const newOptionsForRadio = state.questions.activeQuestion.options.map((option) => {
        // Update the value for isSelected on the current option if it is the option clicked
        return {
          ...option,
          isSelected: option.id === action.data.option.id
        }
      })

      return {
        ...state,
        questions: {
          ...state.questions,
          responseText: action.data.option.message,
          showResponse: action.data.option.message,
          activeQuestion: {
            ...state.questions.activeQuestion,
            options: newOptionsForRadio
          }
        }
      }

    case RECEIVE_ACTIVE_QUESTION:
      const newOptions = action.question.options.map((option) => (
        {
          ...option,
          isSelected: action.question.selectedOptions.find((selectedOption) => (selectedOption === option.id)) !== undefined,
          otherOptionText: action.question.otherOptionTexts[option.id]
        }
      ))

      return {
        ...state,
        questions: {
          ...state.questions,
          showResponse: action.question.selectedOptions.length > 0,
          activeQuestion: {
            ...state.questions.activeQuestion,
            ...action.question,
            isLoading: false,
            options: newOptions
          }
        }
      }

    case RECEIVE_QUESTIONS:
      return {
        ...state,
        questions: {
          ...state.questions,
          allQuestions: action.data.questions,
          isLoading: false
        }
      }

    case QUESTIONS_SET_ASSESSMENT_ID:
      return {
        ...state,
        assessment: {
          ...state.assessment,
          id: action.assessmentId
        }
      }

    case SET_TO_LOADING_QUESTIONS_SCREEN:
      return {
        ...state,
        assessment: {
          ...state.assessment,
          isLoading: true,
          process: {
            ...state.assessment.process,
            isLoading: true
          }
        },
        questions: {
          ...state.questions,
          isLoading: true
        }
      }
    
    case SET_TO_LOADING_PIA_RESULT_SCREEN:
      return {
        ...state,
        assessment: {
          ...state.assessment,
          isLoading: true,
          process: {
            ...state.assessment.process,
            isLoading: true,
            assignedUser: {
              ...state.assessment.process,
              isLoading: true
            }
          }
        },
        questions: {
          ...state.questions,
          isLoading: true
        }
      }

    default:
      return state
  }
}