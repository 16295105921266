import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'

import Filters from './Topic/Filters'
import Loading from '../Common/Loading'
import Pagination from './Form/Pagination'
import Preview from './Files/Preview'
import ReviewTopic from './Topic/ReviewTopic'
import TopicList from './Topic/TopicList'

import { sortValuesByField } from '../../utils'

import WithConnectors from './HOC/WithConnectors'
import WithTopics from './HOC/WithTopics'
import '../../style/common.scss'
import './Topic.css'

class Topic extends React.Component {
  state = {
    activePage: 1,
    filteredBySort: 'weight',
    isModalForPreviewOpened: false,
    isModalForTopicOpened: false,
    modal: { id: 0 }
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  componentWillUnmount = () => {
    // Reset the value for the filtered topics
    this.props.filterTopicsByTerm('')
  }

  addCollection = () => {
  }

  discardTopic = () => {
  }

  exportCsv = () => {
  }

  closeModalForTopic = () => {
    this.setState({ isModalForTopicOpened: false })
  }

  closeModalForPreview = () => {
    this.setState({ isModalForPreviewOpened: false })
  }

  showModalForTopic = ({ id }) => {
    this.setState({
      isModalForTopicOpened: true,
      modal: { id }
    })
  }

  showModalForPreview = (file) => {
    this.setState({ fileToView: file, isModalForPreviewOpened: true })
  }

  changePagination = (page) => {
    this.setState({ activePage: page })
  }

  filterTopicsByLetter = (filterTopicsByLetter, letter) => {
    this.setState({ activePage: 1 })
    filterTopicsByLetter(letter)
  }

  filterByConnector = (event) => {
  }

  filterBySort = (event) => {
    this.setState({ filteredBySort: event.target.value })
  }
  
  filterByTerm = (event) => {
    this.setState({ activePage: 1 })
    this.props.filterTopicsByTerm(event.target.value)
  }

  render = () => {
    const { activePage, filteredBySort } = this.state
    let sortedTopics = sortValuesByField(this.props.topics, this.state.filteredBySort)
    if (filteredBySort === 'weight') sortedTopics = sortedTopics.reverse()
    
    return (
      <div className="privacyTopic__page">
        { this.state.isModalForTopicOpened &&
          <ReviewTopic
            modal={ this.state.modal }
            onAddClicked={ this.addCollection }
            onDiscardClicked={ this.discardTopic }
            onExportClicked={ this.exportCsv }
            onClose={ this.closeModalForTopic }
            openFilePreview={ this.showModalForPreview } />
        }

        { this.state.isModalForPreviewOpened &&
          <Preview file={ this.state.fileToView } onClose={ this.closeModalForPreview } />
        }

        <Filters
          filterByConnector={ this.filterByConnector }
          filterBySort={ this.filterBySort }
          filterByTerm={ this.filterByTerm }
          sortValue={ this.state.filteredBySort }
        />

        <div className="privacyTopic__box">
          <header className="privacyTopic__boxHeader">
            <div>
              <h1 className="privacyTopic__boxTitle">Topics</h1>
              { this.props.topics.length > 0 &&
                <span className="privacyTopic__boxTitleTotal"><strong>{ this.props.topics.length }</strong> results</span>
              }

              <p className="privacyTopic__boxText">
                Groups of your data that match against patterns found in your objects keywords. 
              </p>
            </div>
          </header>
        </div>

        { Object.keys(this.props.letters).length > 0 &&
          <ul id="letters-list" className="privacyTopic__navigation">
            <li
              className="privacyTopic__listItemButton"
              onClick={ this.filterTopicsByLetter.bind(this, this.props.filterTopicsByLetter, '') }>
              All
            </li>
            <Letters
              letters={ this.props.letters }
              filterTopicsByLetter={ this.filterTopicsByLetter.bind(this, this.props.filterTopicsByLetter) } />
          </ul>
        }

        { this.props.isFetchingTopics
          ? <div className="privacyTopic__loading"><Loading /></div>
          :
          <TopicList
            openModal={ this.showModalForTopic }
            topics={ sortedTopics.slice((activePage - 1) * 12, activePage * 12) }
          />
        }
        
        { !this.props.isFetchingTopics &&
          this.props.topics.length > 0 &&
          <div className="pagination__box">
            <header className="pagination__box__header">
              <div>
                <h1 className="privacyTopic__boxTitle">Topics</h1>          
                <span className="pagination__box__header__total">
                  <strong>{ this.props.topics.length }</strong> results
                </span>
                <p className="pagination__box__header__text">
                  Page { this.state.activePage } of { this.props.totalPagesForTopics }
                </p>
              </div>
              <Pagination
                activePage={ this.state.activePage }
                changePagination={ this.changePagination }
                pages={ this.props.totalPagesForTopics } />
            </header>
          </div>
        }
      </div>
    )
  }
}

class Letters extends React.Component {
  render = () => {
    const { letters } = this.props

    return (
      Object.keys(letters).map((letter) => {
        if (letters[letter].disabled) return (
          <li
            className="privacyTopic__listItem--disabled"
            key={ letter }>
            { letter.toUpperCase() }
          </li>
        )

        return (
          <li
            className="privacyTopic__listItem"
            key={ letter }
            onClick={ this.props.filterTopicsByLetter.bind(this, letter) }>
            { letter.toUpperCase() }
          </li>
        )
      })
    )
  }
}

Letters.propTypes = {
  filterTopicsByLetter: PropTypes.func.isRequired,
  letters: PropTypes.object.isRequired
}

Topic.propTypes = {
  filterTopicsByLetter: PropTypes.func.isRequired,
  filterTopicsByTerm: PropTypes.func.isRequired,
  isFetchingTopics: PropTypes.bool.isRequired,
  letters: PropTypes.object.isRequired,
  topics: PropTypes.array.isRequired,
  totalPagesForTopics: PropTypes.number.isRequired
}

export default compose(
  WithConnectors,
  WithTopics
)(Topic)