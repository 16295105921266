import React from 'react'
import '../Duplicates.scss'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { Select, Tooltip, Input, Table, Modal, notification, Checkbox } from 'antd'
import Button from '../../../../Common/Button'
import Icon from 'antd/es/icon'
import { DropdownButton, Dropdown} from 'react-bootstrap'
import {
    getListDuplicateFileBoxSecond,
    apiPreviewFileDuplicate,
    apiListDeleteDuplicates,
    apiStarListDuplicate,
    apiSaveDocumentStarStatus,
    apiGetClutterEmailThreadingListSecondPage, apiPreviewDocByIdDoc
} from '../../../../../api/SmartBox'
import { generateBlobFromBase64 } from '../../../../../utils/GenerateBlob'
import PDFViewer from '../../../../../utils/PDFViewer'
import PDFJSBackend from '../../../../../utils/backends/PDFJs'
import CustomNotificationManager from '../../../../Common/CustomNotificationManager'
import { customConfirm } from '../../../../../utils/AssetRegister/CustomConfirm'
import { setSmartBoxProgressCleanDeleteDuplicates } from '../../../../../actions/smartBoxActions'
import {fetchAssinedUser} from "../../../../../actions/questionActions";
const { Option } = Select
const { Search } = Input
const { Column } = Table
class EmailThreadsList extends React.Component {
    state = {
        pageLimitDisplay: 10,
        selectedRowKeys: [],
        onHoverClean: false,
        selectedFile: {},
        selectedClickFileRow: {},
        filesPerPageLimit: 20,
        valueInputSearch: '',
        currentPage: 1,
        totalRecords: 20,
        tableDuplicatesOnFile: [],
        selectedRowsList: [],
        cursorMark: '',
        renderingBlock: {},
        previewVisibleDuplicate: false,
        onHoverMenuPreview: false,
        statusStar: null,
        listStarDuplicate: [],
        statusSelectAll: true,
        valueSelectOptionDelete: 'Clean and delete',
        deleteSelectableFile: {},
        checkBoxSelectedFiles: [],
        statusDeleteMouseEnter: false,
        statusAutoDeduplicate: false,
        isPrevDisabled: true,
        isNextDisabled: false,
        checkNextDisabled: true,
        checkSelectedAll: false,
        activePageBack: false,
        selectColumnDuplicate: {},
        hoverDeleteBtn:false,
        checkSelectAll: false
    }

    componentDidMount() {
        const { fileDuplicateOpen } = this.props
        if (fileDuplicateOpen && fileDuplicateOpen.path) {
            this.onGetDuplicateFiles()
        } else {
            this.onGetDuplicateFiles()
        }
        this.props.onGetSearchEmptyFolder('')
        this.props.onGetSelectAllEmailThreads(false)
    }

    onGetDuplicateFiles = () => {
        const { currentPage, filesPerPageLimit, cursorMark, valueInputSearch, selectColumnDuplicate } = this.state
        const { selectFileBox, folder, hashFile } = this.props
        let parentFolderName = ''
        if (typeof folder.relativePath !== 'undefined') {
            parentFolderName = folder.relativePath.split('/')[1]
        }
        const indexDestinationParent = folder && folder.path ? folder.path.indexOf(parentFolderName) : ''
        const newDestinationParent = folder && folder.path ? folder.path.substr(0, indexDestinationParent) : ''
        const rootPathParent = `${ newDestinationParent }${ parentFolderName }`
        const getEmailThreadingListRequestSecondPageDTO = {
            emailHash: selectFileBox && selectFileBox.emailThreadHash ? selectFileBox.emailThreadHash : '',
            pageNumber: currentPage,
            pageRows: filesPerPageLimit,
            boxPath: rootPathParent,
            search: valueInputSearch ? valueInputSearch : null,
            sortBy: selectColumnDuplicate.column ? selectColumnDuplicate.column : null,
            sortOrder: selectColumnDuplicate.sorter ? selectColumnDuplicate.sorter : null,
        }
        apiGetClutterEmailThreadingListSecondPage(getEmailThreadingListRequestSecondPageDTO).then((response) => {
            const duplicateFiles = []
            response.data.items && response.data.items.length > 0 && response.data.items.forEach((file, index) => {
                file.selectable = false
                file.key = index
                duplicateFiles.push(file)
            })
            this.setState({
                tableDuplicatesOnFile: duplicateFiles,
                checkBoxSelectedFiles: [],
                totalPages: response.data.total,
                //cursorMark: response.data.cursorMark
            }, () => {
                //this.onGetStarListDuplicate(response.data.duplicateFileInfoSecondDTOList)
            })
        }).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
            }else{
                console.log(error)
            }
        })
    }
    onGetStarListDuplicate = (duplicateList) => {
        const { selectedRowKeys, statusSelectAll } = this.state
        const { selectFileBox } = this.props
        const listDuplicateStarDTO = {
            hash: selectFileBox.hash
        }
        apiStarListDuplicate(listDuplicateStarDTO).then((response) => {
            duplicateList && duplicateList.length > 0 && duplicateList.forEach((listDuplicate, key) => {
                response.data && response.data.length > 0 && response.data.forEach((starList) => {
                    if (listDuplicate.path === starList.path) {
                        listDuplicate.status = starList.status
                        listDuplicate.key = key
                        if (starList.status !== 'empty') {
                            if (statusSelectAll) {
                                selectedRowKeys.push(key)
                            }
                        }
                    }
                    const table = []
                    listDuplicate.hash = selectFileBox.hash
                    table.push(listDuplicate)
                    this.setState({
                        //tableDuplicatesOnFile: tableDuplicatesOnFile.push(listDuplicate)
                    }, () => {
                        this.setState({
                            statusSelectAll: false
                        })
                    })
                })
            })
            this.checkFilesDeleteAfterSaved(duplicateList, response.data)
            if (response.status === 200) {
                this.setState({
                    listStarDuplicate: response.data
                })
            }
        }).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
            }else{
                console.log(error)
            }
        })
    }
    checkFilesDeleteAfterSaved = (duplicateList, listDuplicate) => {
        const { selectedRowsList } = this.state
        duplicateList && duplicateList.length > 0 && duplicateList.forEach((listD, key) => {
            listDuplicate && listDuplicate.length > 0 && listDuplicate.forEach((duplicateList, key) => {
                if (listD.path === duplicateList.path) {
                    listD.status = duplicateList.status
                    if (duplicateList.status !== 'empty') {
                        selectedRowsList.push(duplicateList)
                    }
                }
            })

        })
    }
    onMouseEnterOnPanel(e, status) {
        if (status === 'clean') {
            this.setState({
                onHoverClean: true
            })
        }
    }
    onMouseLeaveOnPanel() {
        this.setState({
            onHoverClean: false
        })
    }
    onClickBackList = () => {
        this.props.onVisibleEmailThreadsList(false)
    }

    onSelectChangeStar = (selectedRowKeys, filesSelect, e) => {
        const { selectFileBox } = this.props
        const { listStarDuplicate } = this.state
        let saveDocumentStarStatusDTO = []
        const deleteSelectedStar = []
        const saveListStar = []
        if (filesSelect && filesSelect.length > 1) {
            filesSelect.forEach((file) => {
                if (!file.status) {
                    file.status = 'silver'
                }
            })
        }
        if (filesSelect && filesSelect.length > 0) {
            filesSelect.forEach((file, index) => {
                //let indexSilver = 0
                if (filesSelect[index].status === 'silver' && filesSelect[index].status !== 'gold') {
                    //indexSilver++
                }

                const objectSavedStatus = {
                    hash: selectFileBox.hash,
                    path: file.path,
                    status: filesSelect && filesSelect.length === 1 ? 'gold' :
                        !file.status && file.status !== 'gold' ? 'gold' :
                            file.status && file.status === 'gold' ? 'gold' : 'silver'
                }
                deleteSelectedStar.push(objectSavedStatus)
            })

        } else if (listStarDuplicate && listStarDuplicate.length > 0) {
            listStarDuplicate.forEach((duplicate) => {
                const objectSavedStatus = {
                    hash: selectFileBox.hash,
                    path: duplicate.path,
                    status: 'empty'
                }
                saveListStar.push(objectSavedStatus)
            })
        }
        saveDocumentStarStatusDTO = deleteSelectedStar.concat(saveListStar)
        const statusCheck = []
        saveDocumentStarStatusDTO && saveDocumentStarStatusDTO.length > 0 && saveDocumentStarStatusDTO.forEach((file, index) => {
            statusCheck.push(file.status)
        })
        if (statusCheck && statusCheck.length > 1 && statusCheck.includes('gold')) {
        } else if (statusCheck && statusCheck.length > 1 && !statusCheck.includes('empty')) {
            saveDocumentStarStatusDTO[0].status = 'gold'
        }
        const saveDocumentStarStatusDTOList = saveDocumentStarStatusDTO
        apiSaveDocumentStarStatus(saveDocumentStarStatusDTOList).then(() => {
            this.onGetDuplicateFiles()
        }).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
            }else{
                console.log(error)
            }
        })
        let duplicateNewListId = []
        duplicateNewListId = new Set([...selectedRowKeys])
        const filterFilesIdFinally = [...duplicateNewListId]
        this.setState({ selectedRowKeys: filterFilesIdFinally, selectedRowsList: filesSelect })
    }
    onMouseEnterRow = (e, record) => {
        const { tableDuplicatesOnFile, checkBoxSelectedFiles, statusDeleteMouseEnter } = this.state
        const updateListDuplicate = []
        if (!statusDeleteMouseEnter) {
            tableDuplicatesOnFile && tableDuplicatesOnFile.length > 0 && tableDuplicatesOnFile.forEach((file) => {
                if (tableDuplicatesOnFile.length === checkBoxSelectedFiles.length) {
                    file.selectable = true
                } else
                if (file.docPath === record.docPath) {
                    file.selectable = true
                } else if (checkBoxSelectedFiles && checkBoxSelectedFiles.length > 0) {
                    checkBoxSelectedFiles.forEach((duplicate) => {
                        duplicate.selectable = true
                        if (duplicate.selectable === false) {
                            file.selectable = true
                        } else if (duplicate.selectable === true && file.docPath === duplicate.docPath) {
                            file.selectable = true
                        } else
                        if (file.docPath === duplicate.docPath) {
                            file.selectable = true
                        }
                        else {
                            file.selectable = false
                        }
                    })
                } else {
                    file.selectable = false
                }
                updateListDuplicate.push(file)
            })
            this.setState({
                tableDuplicatesOnFile: updateListDuplicate,
                checkSelectedAll: false
            })
        }
        this.setState({
            selectedFile: record
        })
    }
    onMouseLeaveRow = (e, record) => {
        this.setState({
            selectedFile: {},
            deleteSelectableFile: {},
            statusDeleteMouseEnter: false
        })
    }
    onMouseEnterRowDelete = (e) => {
        this.setState({
            statusDeleteMouseEnter: true
        })
    }
    onMouseLeaveRowDelete = (e) => {
        this.setState({
            statusDeleteMouseEnter: false
        })
    }
    onClickRowSelected = (e, record, rowIndex) => {
        record.id = rowIndex
        this.setState({
            selectedClickFileRow: record
        })
        // const updateListDuplicate = []
        // tableDuplicatesOnFile && tableDuplicatesOnFile.length > 0 && tableDuplicatesOnFile.forEach((file) => {
        //   if (record.fileName === file.fileName) {
        //     file.selectable = true
        //   } else if (checkBoxSelectedFiles && checkBoxSelectedFiles.length > 0) {
        //     checkBoxSelectedFiles.forEach((duplicate) => {
        //       if (file.fileName === duplicate.fileName) {
        //         file.selectable = true
        //       }
        //     })
        //   }
        //   else {
        //     file.selectable = false
        //   }
        //   updateListDuplicate.push(file)
        // })
        // this.setState({
        //   tableDuplicatesOnFile: updateListDuplicate
        // })
    }
    rowClassSelected = (record, rowIndex) => {
        const { checkBoxSelectedFiles } = this.state
        const pathFilesSelected = []
        checkBoxSelectedFiles && checkBoxSelectedFiles.length > 0 && checkBoxSelectedFiles.forEach((selectFile) => {
            pathFilesSelected.push(selectFile.docPath)
        })
        if (pathFilesSelected.includes(record.docPath)) {
            return 'selected-row-active'
        }
        // if (record.path === selectedClickFileRow.path) {
        //   return 'row-selected-table'
        // }
    }
    changeFilesPerPageLimit = (e) => {
        const currentPage = 1
        this.setState({ filesPerPageLimit: e, currentPage }, () => this.onGetDuplicateFiles())
    }
    onSearchDuplicates = (e) => {
        this.props.onGetSearchEmptyFolder(e)
        this.setState({
            valueInputSearch: e
        }, () => {
            if (e.length > 0) {
                this.onGetDuplicateFiles()
            }
            if (e === '') {
                this.onGetDuplicateFiles()
            }
        })
    }
    onChangeSearchDuplicates = (e) => {
        this.setState({
            valueInputSearch: e.target.value
        }, () => this.onGetDuplicateFiles())
    }
    gotoPage = (newPage) => {
        const { filesPerPageLimit, totalPages } = this.state
        const totalPagesRecord = Math.ceil(totalPages / filesPerPageLimit)
        const currentPage = Math.max(0, Math.min(newPage, totalPagesRecord))
        const isPrevDisabled = currentPage === 1
        const isNextDisabled = currentPage === totalPagesRecord
        this.setState({ currentPage, isPrevDisabled, isNextDisabled }, () => this.onGetDuplicateFiles())
    }
    handleMoveLeft = (e) => {
        e.preventDefault()
        this.gotoPage(this.state.currentPage - 1)
    }
    handleMoveRight = (e) => {
        e.preventDefault()
        this.setState({
            checkNextDisabled: false
        })
        this.gotoPage(this.state.currentPage + 1)
    }
    onPreviewFileDuplicate = (e, record) => {
        const path = record.docPath
        const idDoc = record.docId
        apiPreviewDocByIdDoc(idDoc).then((response) => {
            if (response.data && response.data.doc) {
                this.windowDocument = generateBlobFromBase64(response.data.doc)
                this.setState({
                    renderingBlock: <PDFViewer backend={ PDFJSBackend } src={ this.windowDocument } toolbar={ 0 }/>,
                    previewVisibleDuplicate: true
                })
            } else {
                CustomNotificationManager.info('File no longer exists.', 'Aborted')
            }
        }).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
            }else{
                console.log(error)
            }
        })
    }
    handleCancelModal = () => this.setState({ previewVisibleDuplicate: false })
    onMouseEnterOnMenu(e, status, record) {
        this.setState({
            onHoverMenuPreview: true
        })
    }
    onMouseLeaveOnMenu(e, status) {
        this.setState({
            onHoverMenuPreview: false
        })
    }
    handleDeleteRequestDashboard = (e, status) => {
        const { selectFileBox } = this.props
        const { checkBoxSelectedFiles } = this.state
        if (checkBoxSelectedFiles && checkBoxSelectedFiles.length > 0) {
            customConfirm({
                title: `${ selectFileBox.fileName }`,
                content: `is the only file you want to keep?`,
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel',
                mask: true,
                maskClosable: false,
                closeHaveLogic: false,
                className: 'asset-register-modal modal-delete-duplicate modal-delete-duplicate-selected',
                centered: true,
                cancelClassName: 'cancel-button',
                confirmClassName: 'confirm-button',
                confirmAction: () => {
                    this.onDeleteDuplicatesFiles(e, status)
                }
            })
        }
    }
    onDeleteDuplicatesFiles = (e, status) => {
        const { filesPerPageLimit, cursorMark, checkBoxSelectedFiles, selectColumnDuplicate } = this.state
        const { folder, selectFileBox } = this.props
        const selectFilesPath = []
        checkBoxSelectedFiles && checkBoxSelectedFiles.length > 0 && checkBoxSelectedFiles.forEach((file) => {
            selectFilesPath.push(file.originalPath)
        })
        this.props.setSmartBoxProgressCleanDeleteDuplicates(false, '')
        this.setState({
            statusCleanDuplicate: true
        })

        const requestDeleteDuplicateDTO = {
            folderPath: folder.path,
            hash: selectFileBox.hash,
            pathList: selectFilesPath,
            rows: filesPerPageLimit,
            pageNr: this.state.currentPage,
            cursorMark: this.state.currentPage === 1 ? '*' : cursorMark,
            boxId: folder.id,
            sortBy: selectColumnDuplicate.column ? selectColumnDuplicate.column : null,
            sortOrder: selectColumnDuplicate.sorter ? selectColumnDuplicate.sorter : null,
        }
        if (status === 'delete-duplicates') {
            notification.info({
                message: 'Deleting duplicates.',
                duration: 3
            })
            apiListDeleteDuplicates(requestDeleteDuplicateDTO).then((response) => {
                if (response.status === 200) {
                    this.props.setSmartBoxProgressCleanDeleteDuplicates(response.data, '')
                    this.onGetDuplicateFiles()
                    this.setState({
                        selectedRowKeys: [],
                        selectedRowsList: [],
                        statusCleanDuplicate: false
                    })
                }
            }).catch((error) => {
                if (error?.response?.status === 504) {
                    CustomNotificationManager.cleanBuffer('warning', '')
                }else{
                    console.log(error)
                }
            })
        }
    }
    getLocationDuplicate = (text) => {
        const { clientId } = this.props
        const indexPathDuplicate = text ? text.indexOf(`smartBox_${ clientId }`) : ''
        const newPathDuplicate = text ? text.substr(indexPathDuplicate + `smartBox_${ clientId }`.length) : ''
        const pathDuplicateFinal = newPathDuplicate ? newPathDuplicate.substr(2, newPathDuplicate.length) : ''
        //return pathDuplicateFinal
        return text
    }
    handleDeleteSelectedFile = (e, file) => {
        this.setState({
            deleteSelectableFile: file
        })
        customConfirm({
            title: `${ file.fileName }`,
            content: `is the only file you want to keep?`,
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
            mask: true,
            maskClosable: false,
            closeHaveLogic: false,
            className: 'asset-register-modal modal-delete-duplicate modal-delete-duplicate-selected',
            centered: true,
            cancelClassName: 'cancel-button',
            confirmClassName: 'confirm-button',
            confirmAction: () => {
                this.onDeleteDuplicatesFilesSelected(e, file)
            }
        })
    }
    onDeleteDuplicatesFilesSelected = (e, file) => {
        const { filesPerPageLimit, cursorMark, tableDuplicatesOnFile, selectColumnDuplicate } = this.state
        const { folder, selectFileBox } = this.props
        const selectFilesPath = []
        tableDuplicatesOnFile && tableDuplicatesOnFile.length > 0 && tableDuplicatesOnFile.forEach((fileName) => {
            if (fileName.originalPath !== file.originalPath) {
                selectFilesPath.push(fileName.originalPath)
            }
        })
        this.props.setSmartBoxProgressCleanDeleteDuplicates(false, '')
        this.setState({
            statusCleanDuplicate: true
        })
        const requestDeleteDuplicateDTO = {
            folderPath: folder.path,
            hash: selectFileBox.hash,
            pathList: selectFilesPath,
            rows: filesPerPageLimit,
            pageNr: this.state.currentPage,
            cursorMark: this.state.currentPage === 1 ? '*' : cursorMark,
            boxId: folder.id,
            sortBy: selectColumnDuplicate.column ? selectColumnDuplicate.column : null,
            sortOrder: selectColumnDuplicate.sorter ? selectColumnDuplicate.sorter : null,

        }
        apiListDeleteDuplicates(requestDeleteDuplicateDTO).then((response) => {
            if (response.status === 200) {
                this.props.setSmartBoxProgressCleanDeleteDuplicates(response.data, '')
                this.onGetDuplicateFiles()
                this.setState({
                    selectedRowKeys: [],
                    selectedRowsList: [],
                    statusCleanDuplicate: false
                })
            }
        }).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
            }else{
                console.log(error)
            }
        })
    }
    onCheckBoxFiles = (e, file) => {
        file.selectable = true
        const { checkBoxSelectedFiles, tableDuplicatesOnFile } = this.state
        checkBoxSelectedFiles.push(file)
        const uniqueFiles = [...new Set(checkBoxSelectedFiles.map((item) => item))]
        if (e.target.checked === false) {
            const unCheckedFile = checkBoxSelectedFiles && checkBoxSelectedFiles.length > 0 && checkBoxSelectedFiles.filter((item2) => item2.docPath !== file.docPath)
            const emailThreadHashList = []
            unCheckedFile && unCheckedFile.length > 0 && unCheckedFile.forEach((item) => {
                emailThreadHashList.push(item.emailThreadHash)
            })
            this.props.onGetDeclutterEmailsSecondPageHashList(emailThreadHashList)
            this.setState({
                checkBoxSelectedFiles: unCheckedFile
            })
        } else {
            const newTableList = []
            uniqueFiles && uniqueFiles.length > 0 && uniqueFiles.forEach((uniqF) => {
                uniqF.selectable = true
            })
            tableDuplicatesOnFile && tableDuplicatesOnFile.length && tableDuplicatesOnFile.forEach((table) => {
                if (file.docPath === table.docPath) {
                    table.selectable = true
                }
                newTableList.push(table)
            })
            const emailThreadHashList = []
            uniqueFiles && uniqueFiles.length > 0 && uniqueFiles.forEach((item) => {
                emailThreadHashList.push(item.emailThreadHash)
            })
            this.props.onGetDeclutterEmailsSecondPageHashList(emailThreadHashList)
            this.setState({
                checkBoxSelectedFiles: uniqueFiles,
                tableDuplicatesOnFile: newTableList
            })
        }

    }
    onCheckSelectAll = (e) => {
        const { tableDuplicatesOnFile } = this.state
        const checkBoxFile = []
        tableDuplicatesOnFile && tableDuplicatesOnFile.length > 0 && tableDuplicatesOnFile.forEach((fileT) => {
            if (e.target.checked) {
                fileT.selectable = true
                checkBoxFile.push(fileT)
            } else {
                fileT.selectable = false
                checkBoxFile.push(fileT)
            }
        })
        this.setState({
            tableDuplicatesOnFile: checkBoxFile,
            checkSelectedAll: e.target.checked
        })
    }
    onMouseEnterActive = (e, status) => {
        switch (status) {
            case "back-page" :
                this.setState({
                    activePageBack: true
                })
                break;
            case 'delete-active' :
                this.setState({
                    hoverDeleteBtn: true,
                })
                break;
            default:break;
        }
    }
    onMouseLeaveActive() {
        this.setState({
            activePageBack: false,
            hoverDeleteBtn: false,
        })
    }
    onSorterColumnsTenant = (e, column, sorter) => {
        const selectCol = {
            column: column,
            sorter: sorter
        }
        this.setState({
            selectColumnDuplicate: selectCol
        }, () => {
            this.onGetDuplicateFiles()
        })
    }
    render() {
        const { selectedFile, selectedClickFileRow, filesPerPageLimit, valueInputSearch,
            tableDuplicatesOnFile, renderingBlock, previewVisibleDuplicate, onHoverMenuPreview, totalPages,
            deleteSelectableFile, checkBoxSelectedFiles, checkNextDisabled, currentPage, isPrevDisabled, statusCleanDuplicate, activePageBack,
            selectColumnDuplicate , hoverDeleteBtn} = this.state
        let { isNextDisabled } = this.state
        const { selectFileBox } = this.props
        if (checkNextDisabled) {
            const totalPagesRecord = Math.ceil(totalPages / filesPerPageLimit)
            isNextDisabled = currentPage === totalPagesRecord
        }
        // const rowSelection = {
        //   selectedRowKeys,
        //   onChange: this.onSelectChangeStar,
        // }
        const fileNameD = selectFileBox && selectFileBox.docName ? (selectFileBox.docName.slice(0, 40) + (selectFileBox.docName.length > 40 ? '...' : '')) : ''
        return (
            <div className="new-clutter-page-contain">
                    <div className="new-clutter-page-header new-clutter-page-header-list">
                        <div className="name-files-header">
                               <span className="header-back-file"
                                     onMouseEnter={(e) => this.onMouseEnterActive(e, 'back-page')}
                                     onMouseLeave={(e) => this.onMouseLeaveActive(e)}>
                                 <span onClick={ this.onClickBackList } className={ activePageBack ? 'icon-v21-back-active' : "icon-v21-back-1" }></span>
                                 <Tooltip title={ selectFileBox.docName }>
                                   <span onClick={ this.onClickBackList } className={ activePageBack ? 'title-files-active' : "title-files" }>{ fileNameD }
                                   </span>
                                 </Tooltip>
                               </span>
                            <div className="actionsDotsCell action-drop-cell menu-duplicate actions-dots-cell-email-threads">
                                <DropdownButton
                                    className="dots-menu-sar"
                                    title={
                                        <div className="ellipsis-v2-threads"><span className={ 'icon-v14-options-small-active'  }></span></div> }
                                    nocaret="true"
                                    data-toggle="dropdown"
                                    id={ 'dropdown-1' }
                                    bsstyle="default"
                                >
                                    <Dropdown.Item onClick={ (e) => this.onPreviewFileDuplicate(e, selectFileBox) } className="menu-left-item-directory"
                                    >
                                        <span>Preview</span>
                                    </Dropdown.Item>
                                </DropdownButton>


                                {/* <span className="total-files-content">
                                 <span className="number-duplicate"><span className="nr-dupl">{totalPages ? totalPages : 0}</span></span>
                                 <span className="title-duplicate">threads2</span>
                               </span>*/}
                            </div>
                        </div>

                        {/*<div className="actionsDotsCell action-drop-cell menu-duplicate">*/}
                        {/*    <DropdownButton*/}
                        {/*        className="dots-menu-sar"*/}
                        {/*        title={*/}
                        {/*            <div className="ellipsis-v2"><span className={ 'icon-v14-options-small-active'  }></span></div> }*/}
                        {/*        nocaret="true"*/}
                        {/*        data-toggle="dropdown"*/}
                        {/*        id={ 'dropdown-1' }*/}
                        {/*        bsstyle="default"*/}
                        {/*    >*/}
                        {/*        <Dropdown.Item onClick={ (e) => this.onPreviewFileDuplicate(e, selectFileBox) } className="menu-left-item-directory"*/}
                        {/*        >*/}
                        {/*            <span>Preview</span>*/}
                        {/*        </Dropdown.Item>*/}
                        {/*    </DropdownButton>*/}


                        {/*    /!* <span className="total-files-content">*/}
                        {/*         <span className="number-duplicate"><span className="nr-dupl">{totalPages ? totalPages : 0}</span></span>*/}
                        {/*         <span className="title-duplicate">threads2</span>*/}
                        {/*       </span>*!/*/}
                        {/*</div>*/}

                        <div className="total-files-header">
                               <span className="total-files-content">
                                 <span className="number-duplicate"><span className="nr-dupl">{totalPages ? totalPages : 0}</span></span>
                                 <span className="title-duplicate">threads</span>
                               </span>
                        </div>

                        <div className="per-page-duplicates per-page-duplicates-list">
                            {/*<span className="show-duplicate">Show</span>*/}
                            <Select dropdownClassName="select-per-page-dropdown per-duplicate"
                                    value={ 'Show' + ' '+ `${filesPerPageLimit}` }
                                    onChange={ (e) => this.changeFilesPerPageLimit(e) }>
                                <Option value="20">20</Option>
                                <Option value="50">50</Option>
                                <Option value="100">100</Option>
                            </Select>
                        </div>
                        <div className="button-control">
                            <Button type="primary" theme="button-control button-control__left"
                                    onClick={ this.handleMoveLeft }
                                    disabled={ isPrevDisabled || tableDuplicatesOnFile.length === 0 }>
                                <Icon type="left"/>
                            </Button>
                            <Button type="primary" theme="button-control button-control__right"
                                    onClick={ this.handleMoveRight }
                                    disabled={ isNextDisabled || tableDuplicatesOnFile.length === 0 }>
                                <Icon type="right"/>
                            </Button>
                        </div>
                        {/*<div className="clean-header-duplicates">*/}
                        {/*       <span className={ checkBoxSelectedFiles && checkBoxSelectedFiles.length > 0 && hoverDeleteBtn ? 'icon-v14-delete-active' : checkBoxSelectedFiles && checkBoxSelectedFiles.length > 0 ? 'icon-v14-delete' : 'icon-v14-delete disabled-icon-v14-deleted'}*/}
                        {/*             onClick={ (e) => this.handleDeleteRequestDashboard(e, 'delete-duplicates')}*/}
                        {/*             onMouseEnter={(e)=> this.onMouseEnterActive(e, 'delete-active')}*/}
                        {/*             onMouseLeave={() => this.onMouseLeaveActive()}*/}

                        {/*       >*/}
                        {/*       </span>*/}
                        {/*    <Tooltip overlayClassName="help-message-locations" title={<div className="content-help-message-locations">*/}
                        {/*        <span className="text-information">Select the duplicate copies to keep or delete.</span>*/}
                        {/*        <span className="more-info-link" onClick={ () => window.open('https://support.smartbox.ai/support/solutions/articles/47001198054-duplicates-report', '_blank') }> More info. </span>*/}
                        {/*    </div>}>*/}
                        {/*        <span className="icon-v12-question"></span>*/}
                        {/*    </Tooltip>*/}
                        {/*</div>*/}
                        <div className="input-search-duplicates search-list-update input-search-duplicates-update">
                            <Search defaultValue={ valueInputSearch } className="search-duplicates search-duplicates-list"
                                    onSearch={this.onSearchDuplicates}
                                    allowClear
                                    placeholder="Search" style={{ width: 200 }} />
                        </div>
                    </div>
                    <div className="table-new-clutter-content table-new-clutter-content-list">
                        <Table
                            dataSource={ tableDuplicatesOnFile }
                            pagination={ false }
                            //rowSelection={rowSelection}
                            className="table-duplicates-list"
                            rowClassName={ (record, rowIndex) => this.rowClassSelected(record, rowIndex) }
                            showSorterTooltip={ true }
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: (e) => this.onClickRowSelected(e, record, rowIndex),
                                    //onMouseEnter: (e) => this.onMouseEnterRow(e, record, rowIndex),
                                    //onMouseLeave: (e) => this.onMouseLeaveRow(e, record, rowIndex),
                                }
                            }}>
                            <Column className="column-duplicate-list" title={ <span className="duplicate-list-name-header">
                 {/*<span*/}
                                {/*className={ (selectFileBox.iconType === 'empty' && selectedRowKeys.length === 0) ? 'icon-v14-star-active star-empty' : ((selectFileBox.iconType === 'gold' && selectedRowKeys.length !== 0) || selectedRowKeys && selectedRowKeys.length > 0) ? 'icon-v14-star-active star-gold' : 'icon-v14-star-active star-empty' }></span>*/}
                                {/*<Checkbox checked={ checkSelectedAll } onChange={ (e) => this.onCheckSelectAll(e) }></Checkbox>*/}
                                <span className="name-header">Name</span></span> }
                                    dataIndex="docName" key="docName" render={(text, record, rowIndex) => {
                                return (
                                    <div className="duplicate-list-name update-list-name">
                                        {/*<span className={ record.status === null ? 'icon-v14-star' : record.status === 'gold' ? 'icon-v14-star-active star-gold' : record.status === 'silver' ? 'icon-v14-star-active star-silver' : 'icon-v14-star'}></span>*/}
                                        <React.Fragment>
                                            { record.selectable && deleteSelectableFile && deleteSelectableFile.docPath !== record.docPath ?
                                                <Checkbox onChange={ (e) => this.onCheckBoxFiles(e, record) }></Checkbox> :
                                                <span className="icon-v14-delete"
                                                      onClick={ (e) => this.handleDeleteSelectedFile(e, record) }
                                                      onMouseEnter={(e) => this.onMouseEnterRowDelete(e, record)}
                                                      onMouseLeave={(e) => this.onMouseLeaveRowDelete(e)}></span>
                                            }
                                        </React.Fragment>
                                        <Tooltip placement="right" mouseEnterDelay={0.1} overlayClassName="tooltip-list" title={ text }>
                       <span className="title-row"
                             onMouseEnter= { (e) => this.onMouseEnterRow(e, record, rowIndex) }
                             onMouseLeave= { (e) => this.onMouseLeaveRow(e, record, rowIndex) }>{ text.slice(0, 60) + (text.length > 60 ? '...' : '') }</span>
                                        </Tooltip>
                                    </div>
                                )
                            }}/>
                            <Column className="column-duplicate-list" title={
                                <span className="content-date">
                                    <span>Location</span>
                                      <span className="content-buttons-sorter">
                                      <span className={ selectColumnDuplicate && selectColumnDuplicate.column === 'location' && selectColumnDuplicate.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                                            onClick={(e) => this.onSorterColumnsTenant(e, 'location', 'asc')}></span>
                                     <span className={ selectColumnDuplicate && selectColumnDuplicate.column === 'location' && selectColumnDuplicate.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                                           onClick={(e) => this.onSorterColumnsTenant(e, 'location', 'desc')}></span>
                                 </span>
                                </span>
                            }
                                    dataIndex="docPath" key="docPath"
                                    render={(text, record, rowIndex) => {
                                        return (
                                            <span className="title-row" onMouseEnter= { (e) => this.onMouseEnterRow(e, record, rowIndex) }
                                                  onMouseLeave= { (e) => this.onMouseLeaveRow(e, record, rowIndex) }>
                       <Tooltip placement="right" mouseEnterDelay={0.1} overlayClassName="tooltip-list" title={ this.getLocationDuplicate(text) }>
                         { this.getLocationDuplicate(text).slice(0, 50) + (this.getLocationDuplicate(text).length > 50 ? '...' : '') }
                       </Tooltip>
                     </span>
                                        )
                                    }}/>
                            <Column className="column-duplicate-list" title={
                                <span className="content-date">
                                    <span>Date</span>
                                      <span className="content-buttons-sorter">
                                      <span className={ selectColumnDuplicate && selectColumnDuplicate.column === 'date' && selectColumnDuplicate.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                                            onClick={(e) => this.onSorterColumnsTenant(e, 'date', 'asc')}></span>
                                     <span className={ selectColumnDuplicate && selectColumnDuplicate.column === 'date' && selectColumnDuplicate.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                                           onClick={(e) => this.onSorterColumnsTenant(e, 'date', 'desc')}></span>
                                 </span>
                                </span>
                            }
                                    dataIndex="date" key="date"
                                //sorter = {(a, b) => a.date && b.date ? a.date.localeCompare(b.date) : '' }
                                    render={(text, record, rowIndex) => {
                                        return (
                                            <span className="title-row"
                                                  onMouseEnter= { (e) => this.onMouseEnterRow(e, record, rowIndex) }
                                                  onMouseLeave= { (e) => this.onMouseLeaveRow(e, record, rowIndex) }>
                       { text.split(' ').join(' at ') }
                     </span>
                                        )
                                    }}/>
                            <Column className="column-duplicate-list" title="" dataIndex="menu" key="menu"
                                    render={(text, record, rowIndex) => {
                                        return (
                                            <div className="actionsDotsCell action-drop-cell menu-duplicate"
                                                 onMouseEnter= { (e) => this.onMouseEnterRow(e, record, rowIndex) }
                                                 onMouseLeave= { (e) => this.onMouseLeaveRow(e, record, rowIndex) }>
                                                <DropdownButton
                                                    className="dots-menu-sar"
                                                    title={ (selectedFile && selectedFile.docPath === record.docPath) || (selectedClickFileRow && selectedClickFileRow.docPath === record.docPath) ?
                                                        <div className="ellipsis-v2"><span
                                                            onMouseEnter={ (e) => this.onMouseEnterOnMenu(e, 'preview', record) }
                                                            onMouseLeave={ () => this.onMouseLeaveOnMenu() }
                                                            className={ selectedFile && selectedFile.docPath === record.docPath && onHoverMenuPreview ? 'icon-v14-options-small-active' : 'icon-v14-options-small' }></span></div> : <div className="ellipsis-v3">,,,,</div> }
                                                    nocaret="true"
                                                    data-toggle="dropdown"
                                                    id={ 'dropdown-1' }
                                                    // onClick={ (e) => {
                                                    //   e.stopPropagation()
                                                    // } }
                                                    bsstyle="default">
                                                    <Dropdown.Item onClick={ (e) => this.onPreviewFileDuplicate(e, record) } className="menu-left-item-directory"
                                                    >
                                                        <span>Preview</span>
                                                    </Dropdown.Item>
                                                </DropdownButton>
                                            </div>
                                        )
                                    }}/>
                        </Table>
                    </div>
                <Modal visible={ previewVisibleDuplicate } footer={ null } onCancel={ this.handleCancelModal } className="pdf-modal">
                    <div className="custom-modal-body">
                        { renderingBlock }
                    </div>
                </Modal>
            </div>
        )

    }
}

EmailThreadsList.propTypes = {
    folder: PropTypes.object,
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id
})
export default compose(
    connect(mapStateToProps, {
        setSmartBoxProgressCleanDeleteDuplicates
    }),
)(EmailThreadsList)