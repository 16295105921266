import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import * as messageActions from '../../../actions/messageActions'

export class HelpAndSupport extends Component {

  constructor() {
    super()
    this.state = {
      title: 'HelpAndSupport',

    }
  }

  render() {

    return (
      <div className="newSearch-page">

      </div>
    )
  }
}

const stateMap = (state) => ({
  messageArea: state.messageAreaReducer.messageArea,
  subscription: state.subscriptionReducer.subscription
})

const dispatchMap = (dispatch) => ({
  hideMessage: () => dispatch(messageActions.hideMessage()),
  showMessage: (status, text) => dispatch(messageActions.showMessage({ status, text }))
})

HelpAndSupport.propTypes = {
  hideMessage: PropTypes.func.isRequired,
  messageArea: PropTypes.object.isRequired,
  showMessage: PropTypes.func.isRequired,
  subscription: PropTypes.object.isRequired
}

export default compose(
  connect(stateMap, dispatchMap),
)(HelpAndSupport)