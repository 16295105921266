import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import './Active.scss'
import { Tooltip } from 'antd'
import withRouter from '../../../../private-routers/withRouter'


const Active = ({ assessment, answered, total, processStatus, type }) => {

  const seeQuestions = (assessmentId) => {
    this.props.navigate(`/dpia/assessment/${ assessmentId }`)
  }
  
return (

  <div className="activeAssessment-wrapper">
    <div className="activeAssessment-actionsContainer">
      <Tooltip title="DPIA Report">
        <span className="iconReportDisabled"/>
      </Tooltip>
      <Tooltip title="Download Report">
        <span className="iconDownloadDisabled"/>
      </Tooltip>
      {
        processStatus === 'INACTIVE' &&
        <Tooltip title="Continue Active DPIA"><span className="iconPlayDisabled"/></Tooltip>
      }
      
      {
        processStatus !== 'INACTIVE' &&
        <Tooltip title="Continue Active DPIA"><span className="iconPlay"
          onClick={seeQuestions(this, assessment.id)}/></Tooltip>
      }
    </div>
    
    <div className="activeAssessment-infoContainer">
      <div className="activeAssessment-textContainer">
        { <Link to={ `/dpia/assessment/${ assessment.id }` } className="piaActiveAssessment_link">
          { type }
        </Link>
        }
      </div>
      <div className="activeAssessment-status">
        { processStatus !== 'INACTIVE' &&
        <Link to={ `/dpia/assessment/${ assessment.id }` } className="activeAssessment-notCompleted">
          { `Incomplete, ${ answered }/${ total } due by ${ moment(assessment.validEndDate).format('MMMM Do') }` }
        </Link>
        }
        
        { processStatus === 'INACTIVE' &&
        <span className="activeAssessment-notCompleted">
            Terminated { moment(assessment.validStartDate).format('MMMM YYYY') }
        </span>
        }
        <div className="activeAssessment-iconContainer">
          { <Link to={ `/dpia/assessment/${ assessment.id }` }>
            <span className="iconError"/>
          </Link>
          }
        </div>
      </div>
    </div>
  </div>
)}
Active.propTypes = {
  assessment: PropTypes.object.isRequired,
  answered: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  processStatus: PropTypes.string.isRequired
}

export default withRouter(Active)