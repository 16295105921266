import React from "react";
import {connect} from "react-redux";
import {Input, Checkbox, Select} from "antd";
import {apiGetDefaultEmailTemplatesWorkflow, apiGetEmail} from "../../../../api/Sar";
import {setGetVariablesEmailsWorkflow} from "../../../../actions/smartBoxActions";
const { Option } = Select
class EmailWorkflowBuilderAdmin extends React.Component {
    state = {
        emailBuilder: {
            selectEmail: '',
            selectEmailVariables: '',
            variablesForm: [],
            variablesFormList: [],
            idComponentForm: [],
            workflowTemplateVariableList: []
        },
        hoverDelete: false,
        listEmails: [],
        listSelectedEmail: {},
        formVariables: [],
        idComponentForm: null
    }
    componentDidMount() {
        this.onGetEmailsTemplate()
        if ((this.props.formVariables2 && this.props.formVariables2.length > 0) || (this.props.component.formVariables && this.props.component.formVariables.length > 0)) {
            this.setState({
                emailBuilder: { ...this.state.emailBuilder, variablesFormList: this.props.formVariables2, idComponentForm: this.props.component.idComponentForm,
                    workflowTemplateVariableList: this.props.component.workflowTemplateVariableList},
                formVariables: this.props.formVariables2 && this.props.formVariables2.length > 0 ? this.props.formVariables2 : this.props.component.formVariables,
                idComponentForm: this.props.component.idComponentForm,
            }, () => {
                this.props.onChangeBuilder(this.state.emailBuilder, this.props.component)
            })
        } else
        if (this.props.setVariablesEmailWorkflow && this.props.setVariablesEmailWorkflow.length > 0) {
            this.setState({
                emailBuilder: { ...this.state.emailBuilder, variablesFormList: this.props.setVariablesEmailWorkflow, idComponentForm: this.props.component.idComponentForm,
                    workflowTemplateVariableList: this.props.component.workflowTemplateVariableList},
                formVariables: this.props.setVariablesEmailWorkflow,
                idComponentForm: this.props.component.idComponentForm,
            }, () => {
                this.props.onChangeBuilder(this.state.emailBuilder, this.props.component)
            })
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props && this.props.formVariables2 && this.props.formVariables2.length > 0) {
            this.setState({
                formVariables: this.props.formVariables2,
                emailBuilder: { ...this.state.emailBuilder, variablesFormList: this.props.formVariables2, idComponentForm: this.props.component.idComponentForm,
                    workflowTemplateVariableList: this.props.component.workflowTemplateVariableList},
            }, () => {
                //this.props.onChangeBuilder(this.state.emailBuilder, this.props.component)
            })
            if (this.props.component.idComponentForm) {
                this.setState({
                    idComponentForm: this.props.component.idComponentForm,
                    //emailBuilder: { ...this.state.emailBuilder, idComponentForm: this.props.component.idComponentForm },
                })
            }
        } else if (prevProps !== this.props && this.props.setVariablesEmailWorkflow && this.props.setVariablesEmailWorkflow.length > 0) {
            this.setState({
                formVariables: this.props.setVariablesEmailWorkflow,
                emailBuilder: { ...this.state.emailBuilder, variablesFormList: this.props.setVariablesEmailWorkflow, idComponentForm: this.props.component.idComponentForm,
                    workflowTemplateVariableList: this.props.component.workflowTemplateVariableList},
                idComponentForm: this.props.component.idComponentForm,
            }, () => {
                //this.props.onChangeBuilder(this.state.emailBuilder, this.props.component)
            })
            // if (this.props.component.idComponentForm) {
            //     this.setState({
            //         idComponentForm: this.props.component.idComponentForm,
            //     })
            // }
        }
    }

    onGetEmailsTemplate = () => {
        const { listSelectedEmail} = this.state
        apiGetDefaultEmailTemplatesWorkflow().then((response) => {
            response.data && response.data.length > 0 && response.data.forEach((item) => {
                if (this.props.component.componentName && this.props.component.componentName === item.templateName) {
                    item.variables && item.variables.length > 0 && item.variables.forEach((variable) => {
                        variable.formVariable = null
                        this.props.component.workflowTemplateVariableList.forEach((variable3) => {
                            if (variable.id === variable3.email_template_variable_id ) {
                                variable.selectedVariable = variable3.form_variable

                            }
                        })
                    })
                    this.setState({
                        listSelectedEmail: {
                            variables: item.variables
                        }
                    })
                }
            })
            const listEmailsFilter = []
            response.data && response.data.length > 0 && response.data.forEach((email) => {
                if (email && email.emailTemplateType === 'General emails') {
                    listEmailsFilter.push(email)
                }
            })
            this.setState({
                listEmails: listEmailsFilter
            }, () => {
                if (this.props.component.idComponent) {
                    this.onGetSelectEmails(this.props.component.idComponent)
                }
            })
        })
    }
    onGetSelectEmails = (e) => {
        const { listEmails } = this.state
        let componentName = ''
        let selectEmail = {}
        listEmails && listEmails.length > 0 && listEmails.forEach((list) => {
            if (list.emailTemplateId === e) {
                componentName = list.templateName
                selectEmail = list
            }
        })
        this.setState({
            emailBuilder: { ...this.state.emailBuilder, selectEmail: e, componentName: componentName, idComponent: e},
            listSelectedEmail: selectEmail
        }, () => {
            // this.props.onChangeBuilder(this.state.emailBuilder, this.props.component)
            this.props.onSelectIdEmailTemplate(e)
        })
    }
    onHoverActionComponent = () => {
        this.setState({
            hoverDelete: true
        })
    }
    onLeaveActionComponent = () => {
        this.setState({
            hoverDelete: false
        })
    }
    onSelectEmails = (e) => {
        const { listEmails } = this.state
        let componentName = ''
        let selectEmail = {}
        listEmails && listEmails.length > 0 && listEmails.forEach((list) => {
            if (list.emailTemplateId === e) {
                componentName = list.templateName
                selectEmail = list
            }
        })
        this.setState({
            emailBuilder: { ...this.state.emailBuilder, selectEmail: e, componentName: componentName, idComponent: e},
            listSelectedEmail: selectEmail
        }, () => {
            this.props.onChangeBuilder(this.state.emailBuilder, this.props.component)
            this.props.onSelectIdEmailTemplate(e)
        })
    }

    onSelectEmailVariables = (e, variable) => {
        const { listSelectedEmail } = this.state
        listSelectedEmail.variables && listSelectedEmail.variables.length > 0 && listSelectedEmail.variables.map((item) => {
            if (item.variableEmail === variable.variableEmail) {
                item.formVariable = e
                item.selectedForm = e
                item.selectedVariable = e
            }
        })
        this.setState({
            listSelectedEmail,
            emailBuilder: { ...this.state.emailBuilder, variablesForm: listSelectedEmail },
        }, () => {
            this.props.onChangeBuilder(this.state.emailBuilder, this.props.component)
        })
    }

    render() {
        const { listEmails, hoverDelete, emailBuilder, listSelectedEmail, formVariables, idComponentForm } = this.state
        const { component, onStateProvidedDraggable } = this.props

        return (
            <div className="component-form">
                <div className="component-content">
                    <div className="header-component">
                        <span className="header-title">Email</span>
                    </div>
                    <div className="select-content">
                          <span onClick={ (e) => this.props.showConfirmDeleteSection(e, component) }
                                className={hoverDelete ? 'icon-v19-delete-active' : "icon-v19-delete" }
                                onMouseEnter={ (e) => this.onHoverActionComponent(e)}
                                onMouseLeave={() => this.onLeaveActionComponent()}></span>
                        <Select className="select-form"
                                value={ emailBuilder.selectEmail ? emailBuilder.selectEmail : component.componentName ? component.componentName : 'Select' }
                                onChange={(e) => this.onSelectEmails(e)}
                                dropdownClassName={'select-form-dropdown'}
                                placeholder="Select">
                            { listEmails && listEmails.length > 0 && listEmails.map((item,index) => {
                                return  (<Option className="options-select-connector" value={ item.emailTemplateId } key={index}>
                                    { item.templateName }
                                </Option>)
                            })}
                        </Select>
                    </div>
                    <div className="content-variables-email">
                        { Object.keys(listSelectedEmail) && Object.keys(listSelectedEmail).length > 0 ?
                            <span className="content-active-variables-email">
                                <span className="variables-email">Variables:</span>
                                <span className="list-emails">
                                {
                                    listSelectedEmail.variables && listSelectedEmail.variables.length > 0 && listSelectedEmail.variables.map((item) => {
                                        return (
                                            <span className="content-select-email" key={item.variableEmail}>
                                                <span className="name-list">{ item.variableEmail ? item.variableEmail.replace('#', '$') : item.variableEmail }</span>
                                                {/*<Select className="select-form"*/}
                                                {/*        value={ item.selectedVariable }*/}
                                                {/*        onChange={(e) => this.onSelectEmailVariables(e, item)}*/}
                                                {/*        dropdownClassName={'select-form-dropdown'}*/}
                                                {/*        placeholder="Select">*/}
                                                {/*     { formVariables && formVariables.length > 0 && formVariables.map((item,index) => {*/}
                                                {/*         return  (<Option className="options-select-connector" value={ item } key={index}>*/}
                                                {/*             { item }*/}
                                                {/*         </Option>)*/}
                                                {/*     })}*/}
                                                {/*</Select>*/}
                                            </span>
                                        )
                                    })
                                }
                                </span>

                            </span>
                            :
                            <span className="variables-email-default">Variables:</span>
                        }
                    </div>
                </div>
                <div className="component-move">
                    <span onMouseEnter={() => this.props.onStateProvidedDraggable(component)} className="icon-v211-grid-view-1"></span>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    setVariablesEmailWorkflow: state.smartBox.setVariablesEmailWorkflow,
})

export default connect(mapStateToProps, { setGetVariablesEmailsWorkflow })(EmailWorkflowBuilderAdmin)