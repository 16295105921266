import PropTypes from 'prop-types'
import React, { Component } from 'react'
import './YN.css'
import Active from './YN/Active'
import Completed from './YN/Completed'
import NotApplicable from './YN/NotApplicable'

class YN extends Component {
  render() {
    const {
      assessmentStatus,
      questionsResults,
      processStatus,
      question
    } = this.props

    const answer = questionsResults.SUBMITTED.find((resultQuestion) => (resultQuestion.id === question.id))
    const skipped = questionsResults.QUESTIONNAIRE_SKIPPED.find((resultQuestion) => (resultQuestion.id === question.id))
    
    if (skipped || question.state === 'QUESTIONNAIRE_SKIPPED') return (
      <NotApplicable
        question={ question }
      />
    )

    if (processStatus === 'INACTIVE' || assessmentStatus === 'COMPLETED') return (
      <Completed
        question={ question }
        responseText={ this.props.responseText }
        showResponse={ this.props.showResponse }
      />
    )
    
    return (
      <Active
        answer={ answer }
        question={ question }
        questionsResults={ this.props.questionsResults }
        responseText={ this.props.responseText }
        showResponse={ this.props.showResponse }
        onChange={ this.props.updateValueForRadio }
      />
    )
  }
}

YN.propTypes = {
  assessmentStatus: PropTypes.string.isRequired,
  question: PropTypes.object.isRequired,
  responseText: PropTypes.string,
  showResponse: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  updateValueForRadio: PropTypes.func.isRequired
}

export default YN
