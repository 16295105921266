import { defineDisplaySection, updateOptionsOrder } from '../Helpers/OptionsUtils'

const addDeletedOptions = (question, index) => {
  const removedOption = question.options[index]
  if (!removedOption.id) return question.deletedOptions
  return [
    ...question.deletedOptions,
    removedOption.id
  ]
}

export default (question, index) => {
  let options = question.options
  const deletedOptions = addDeletedOptions(question, index)

  options.splice(index, 1)
  options = updateOptionsOrder(options)
  options = defineDisplaySection(options)

  const newQuestion = {
    ...question,
    deletedOptions,
    options
  }

  return newQuestion
}