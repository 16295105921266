import InputOptions from './InputOptions'
import React from 'react'
import { Checkbox, Icon, Radio, Form, Input, DatePicker, InputNumber, Select } from 'antd'
import ImageUploader from 'react-images-upload'
import Autocomplete from 'react-google-autocomplete'
import { GoogleMap, Marker } from '@react-google-maps/api'
import LocationInput from './LocationInput'
import Text from './Text'
import CustomTextArea from './TextArea'
import CheckBoxView from './CheckBoxView'
import DropdownView from './DropdownView'
import RadioView from './RadioView'
import HistoryNoteTableView from './HistoryNoteTableView'
import moment from 'moment'
import './AssetRegisterComponents.scss'

const { Option } = Select

export const generateColumnFieldTypeContent = (field, value, props) => {
  switch (value) {
    case 'TEXT':
    case 'TEXTAREA':
    case 'NUMBER':
    case 'ADDRESS_FINDER':
    case 'GEOLOCATION':
    case 'FILE':
      return ''
    case 'DROPDOWN':
      return (
        <InputOptions iconsBefore={ false }
          options={ field.options && field.options.length > 0 ? field.options : [] }
          setInputOptions={ (options, item) => props.onChangeOptions(field, options, item) }/>
      )
    case 'RADIO':
      return (
        <InputOptions iconsBefore={ false }
          options={ field.options && field.options.length > 0 ? field.options : [] }
          setInputOptions={ (options, item) => props.onChangeOptions(field, options, item) }/>
      )
    case 'CHECKBOX':
      return (
        <InputOptions iconsBefore={ true }
          options={ field.options && field.options.length > 0 ? field.options : [] }
          setInputOptions={ (options, item) => props.onChangeOptions(field, options, item) }/>
      )
    case 'DATE':
      return (
        <Radio.Group onChange={ (e) => props.onChangeDateFormat(field, e.target.value) } value={ field.dateFormat }
          className="custom-radio-buttons">
          <Radio value="DD/MM/YY">DD/MM/YY</Radio>
          <Radio value="MM/DD/YY">MM/DD/YY</Radio>
        </Radio.Group>
      )
    default:
      return ''
  }
}

export const generateSelectCaret = (value) => {
  switch (value) {
    case 'TEXT':
    case 'TEXTAREA':
    case 'NUMBER':
    case 'ADDRESS_FINDER':
    case 'GEOLOCATION':
    case 'FILE':
    case 'NULL':
      return ''
    default:
      return (
        <Icon type="caret-down" className="select-caret"/>
      )
  }
}

export const generateContentForField = (field) => {
  switch (field.type) {
    case 'GEOLOCATION':
      return <LocationInput/>
    case 'TEXT':
      return <Text/>
    case 'TEXTAREA':
      if (field.name.toUpperCase() === 'HISTORY NOTE') {
        return <HistoryNoteTableView/>
      }
      return <CustomTextArea/>
    case 'CHECKBOX':
      return <CheckBoxView options={ field.options }/>
    case 'DROPDOWN':
      return <DropdownView options={ field.options }/>
    case 'RADIO':
      return <RadioView options={ field.options }/>
    default:
      return ''
  }
}

export const renderColumns = (columns, fieldsValues, getFieldDecorator, isComponentReady, readonly, changeAction, keyPressHandler, additional) => {
  const response = []
  const { Option } = Select
  columns.map((item) => {
    switch (item.type) {
      case 'FILE':
        let uploadButtonText = 'UPLOAD IMAGE...'

        if (fieldsValues[item.id]) {
          if (fieldsValues && fieldsValues[item.id] && fieldsValues[item.id].length > 0 && typeof fieldsValues[item.id] === 'string') {
            const temp = JSON.parse(fieldsValues[item.id])
            uploadButtonText = temp.name
            changeAction(temp, 'FILE_INIT', item.id)
          }
          if (fieldsValues && typeof fieldsValues[item.id] === 'object') {
            uploadButtonText = fieldsValues[item.id].name
          }
        }
        response.push(
          <Form.Item key={ item.id } label={item.name} className="column">
            { getFieldDecorator(`image-${ item.name }-${ item.id }`, {
              rules: [{ required: true, message: 'Required field' }],
              initialValue: fieldsValues[item.id] ? uploadButtonText : ''
            })(
              <ImageUploader
                className={ 'asset-icon' }
                name={ item.id.toString() }
                buttonClassName='asset-icon'
                withIcon={ false }
                withLabel={ false }
                withPreview={ false }
                buttonText={ uploadButtonText }
                onChange={ (e) => changeAction(e, item.type, item.id) }
                imgExtension={ [ '.jpg', '.jpeg', '.png', '.gif' ] }
                maxFileSize={ 524288000000 }
                singleImage={ true }
                disabled = { readonly }
              />
            )}
          </Form.Item>
        )
        break
      case 'RADIO' :
        response.push(
          <Form.Item className="column" label={item.name} key={ item.id }>
            { getFieldDecorator(`radio-${ item.name }-${ item.id }`, {
              rules: [{ required: true, message: 'Required field' }],
              initialValue: fieldsValues[item.id]
            })(
              <Radio.Group
                className="custom-radio"
                name={ item.id.toString() }
                key={ item.id }
                disabled = { readonly }
                onChange={ (e) => changeAction(e, item.type, item.id) }
              >
                { item.options && item.options.map((element) => {
                  return (<Radio key={ element.id } value={ element.name.trim() }>{ element.name }</Radio>)
                }) }
              </Radio.Group>
            )}
          </Form.Item>
        )
        break
      case 'DATE' :
        const format = item.dateFormat ? item.dateFormat : 'DD/MM/YY'
        response.push(
          <Form.Item className="column" label={item.name} key={ item.id }>
            { getFieldDecorator(`date-${ item.name }-${ item.id }`, {
              rules: [{ required: true, message: 'Required field' }],
              initialValue: fieldsValues[item.id] ? moment(fieldsValues[item.id], ['YYYY-MM-DD', format]) : moment()
            })(
              <DatePicker
                allowClear = { false }
                format={ format }
                onChange={ (momentDate) => changeAction(momentDate.format('YYYY-MM-DD'), item.type, item.id) }
              />
            )}
          </Form.Item>
        )
        break
      case 'CHECKBOX' :
        if (fieldsValues[item.id]) {
          if (typeof fieldsValues[item.id] === 'object') {
            if (fieldsValues[item.id][0]) {
              if (fieldsValues[item.id][0].indexOf('[') > -1) {
                fieldsValues[item.id] = fieldsValues[item.id][0]
              }
            }
          }
          if (typeof fieldsValues[item.id] === 'string') {
            fieldsValues[item.id] = fieldsValues[item.id].replace(/["']]/g, '').replace(/[ "'[]/g, '').split(',')
          }
        } else {
          fieldsValues[item.id] = []
        }
        const optionsCheckbox = []
        item.options && item.options.map((element) => {
          optionsCheckbox.push({ label: element.name, value: element.name.trim() })
          return true
        })

        response.push(
          <Form.Item className="column" label={item.name} key={ item.id }>
            { getFieldDecorator(`checkbox-${ item.name }-${ item.id }`, {
              rules: [{ required: true, message: 'Required field' }],
              initialValue: fieldsValues[item.id]
            })(
              <Checkbox.Group
                className="custom-checkbox w-100"
                name={ item.id.toString() }
                options={ optionsCheckbox }
                disabled = { readonly }
                onChange={ (e) => changeAction(e, item.type, item.id) }/>
            ) }
          </Form.Item>
        )
        break
      case 'NUMBER' :
        response.push(
          <Form.Item className="column" label={item.name} key={ item.id }>
            { getFieldDecorator(`number-${ item.name }-${ item.id }`, {
              rules: [{ required: true, message: 'Required field' }],
              initialValue: fieldsValues[item.id]
            })(
              <Input
                type="text"
                className="custom-input"
                name={ item.id }
                onChange={ (e) => changeAction(e, item.type, item.id) }
                onKeyPress={ (e) => keyPressHandler(e) }
                disabled = { readonly }
              />
            ) }
          </Form.Item>
        )
        break
      case 'TEXT' :
        response.push(
          <Form.Item className="column" label={item.name} key={ item.id }>
            { getFieldDecorator(`text-${ item.name }-${ item.id }`, {
              rules: [{ required: true, message: 'Required field' }],
              initialValue: fieldsValues[item.id]
            })(
              <Input
                type="text"
                className="custom-input"
                name={ item.id }
                onChange={ (e) => changeAction(e, item.type, item.id) }
                onKeyPress={ (e) => keyPressHandler(e) }
                disabled = { readonly }
              />
            ) }
          </Form.Item>
        )
        break
      case 'GEOLOCATION' :
        let markValue = fieldsValues[item.id]
        if (markValue) {
          if (fieldsValues && fieldsValues[item.id] && fieldsValues[item.id].length > 0 && typeof fieldsValues[item.id] === 'string') {
            markValue = JSON.parse(fieldsValues[item.id])
            changeAction(markValue, 'GEO_INIT', item.id)
          }
        } else {
          markValue = { 'lat': 41.158169, 'lng': -8.629091, 'city': 'Porto' }
        }
        let cityName = ''
        if (fieldsValues[item.id]) {
          cityName = fieldsValues[item.id].city
        }
        const placeholder = readonly ? (cityName ? cityName : '') : (cityName ? cityName : 'Add an address')
        response.push(
          isComponentReady ?
            <div className="column-100" key={ item.id }>
              <p className="column-100__title">{ item.name }</p>
              <div className="type-map">
                <Autocomplete
                  placeholder= { placeholder }
                  className="type-map__input"
                  name={ fieldsValues.name }
                  onPlaceSelected={ (place) => changeAction(place, item.type, item.id) }
                  types={ [ '(regions)' ] }
                  onKeyPress={ (e) => keyPressHandler(e) }
                  disabled = { readonly }
                />
                <i className="type-map__icon icon-add-b"/>
              </div>
              <GoogleMap
                id='google-map'
                mapContainerClassName="map-container"
                zoom={ 18 }
                center={ markValue }
              >
                <Marker
                  position={ markValue }
                />
              </GoogleMap>
            </div> : ''
        )
        break
      case 'ADDRESS_FINDER' :
        if (fieldsValues && fieldsValues[item.id] && fieldsValues[item.id].length > 0 && typeof fieldsValues[item.id] === 'string') {
          fieldsValues[item.id] = JSON.parse(fieldsValues[item.id])
        }
        const placeholder2 = fieldsValues[item.id] ? fieldsValues[item.id].name ? fieldsValues[item.id].name : 'Add an address' : 'Add an address'
        response.push(
          <Form.Item className={ additional ? 'column-100' : 'column' } label={item.name} key={ item.id }>
            { getFieldDecorator(`text-${ item.name }-${ item.id }`, {
              rules: [{ required: true, message: 'Required field' }],
              initialValue: fieldsValues[item.id]
            })(
              <div className="type-map">
                <Autocomplete
                  placeholder= { placeholder2 }
                  className="type-map__input"
                  name={ fieldsValues.name }
                  onPlaceSelected={ (place) => changeAction(place, item.type, item.id) }
                  types={ [ '(regions)' ] }
                  onKeyPress={ (e) => keyPressHandler(e) }
                  disabled = { readonly }
                />
                <i className="type-map__icon icon-add-b"/>
              </div>
            )}
          </Form.Item>
        )
        break
      case 'TEXTAREA' :
        response.push(
          <Form.Item className="column" label={item.name} key={ item.id }>
            { getFieldDecorator(`textarea-${ item.name }-${ item.id }`, {
              rules: [{ required: true, message: 'Required field' }],
              initialValue: fieldsValues[item.id]
            })(
              <textarea
                className="custom-textarea"
                name={ item.id }
                onChange={ (e) => changeAction(e, item.type, item.id) }
                onKeyPress={ (e) => keyPressHandler(e) }
                disabled = { readonly }
                maxLength= '255'
              />
            )}
          </Form.Item>
        )
        break
      case 'DROPDOWN' :
        response.push(
          <Form.Item className="column" label={item.name} key={ item.id }>
            { getFieldDecorator(`textarea-${ item.name }-${ item.id }`, {
              rules: [{ required: true, message: 'Required field' }],
              initialValue: fieldsValues[item.id]
            })(
              <Select
                onChange={ (e) => changeAction(e, item.type, item.id) }
                placeholder="Select an option"
                className="custom-select"
                name={ item.id }
              >
                { item.options && item.options.map((element) => {
                  return (<Option key={ element.id } value={ element.name } disabled = { readonly }>{ element.name }</Option>)
                }) }
              </Select>
            )}
          </Form.Item>
        )
        break
      default:
        break
    }
    return true
  })
  return response
}

export const generateContent = (value, column) => {
  if (column) {
    switch (column.type) {
      case 'FILE' :
        return (
          <div className="span-item-document">
            <span className="icon-pages-b"/>
            { value }
          </div>
        )
      case 'NUMBER' :
        return <span className="span-item-number">{ value }</span>
      case 'DATE' :
        return (
          <div className="span-item-date">
            { generateDate(value, column) }
          </div>
        )
      case 'DROPDOWN' :
      case 'TEXT':
      case 'TEXTAREA':
        return <span className="span-item-dropdown">{ value }</span>
      case 'RADIO' :
        return <span className="span-item-dropdown">{ value }</span>
      case 'CHECKBOX' :
        return <div className="span-item-checkbox">
          {
            value.map((item, key) => <span className="span-item-checkbox__child"
              key={ key }><span
                className={ generateIconForOption(column, item) }/>{ item }</span>)
          }
        </div>
      default:
        return ''
    }
  }
}

const generateIconForOption = (column, value) => {
  if (column && column.options) {
    if (column.options.filter((option) => option.name === value)[0]) {
      return column.options.filter((option) => option.name === value)[0].icon
    }
  }
  return ''
}

const generateDate = (value, column) => {
  if (column.name.toLowerCase().includes('expiry')) {
    return (
      <span className="span-item-date__with-icon">
        { generateDateStatusIcon(value, column.dateFormat) }
      </span>
    )
  }
  return (
    <span className="span-item-date__without-icon">
      { moment(value).format(column.dateFormat) }
    </span>
  )
}

const generateDateStatusIcon = (value, pattern) => {
  if (moment().isAfter(value)) {
    return (
      <React.Fragment>
        <span style={ { minWidth: '67px' } }>Expired</span>
        <span className="icon-radio-button-off date-status-black"/>
      </React.Fragment>
    )
  } else if (moment(value).diff(moment(), 'days') >= 0 && moment(value).diff(moment(), 'days') <= 7) {
    return (
      <React.Fragment>
        <span style={ { minWidth: '67px' } }>{ moment(value).format(pattern) }</span>
        <span className="icon-radio-button-off date-status-red"/>
      </React.Fragment>
    )
  } else if (moment(value).diff(moment(), 'days') > 7 && moment(value).diff(moment(), 'days') <= 15) {
    return (
      <React.Fragment>
        <span style={ { minWidth: '67px' } }>{ moment(value).format(pattern) }</span>
        <span className="icon-radio-button-off date-status-orange"/>
      </React.Fragment>
    )
  } else if (moment(value).diff(moment(), 'days') > 15) {
    return (
      <React.Fragment>
        <span style={ { minWidth: '67px' } }>{ moment(value).format(pattern) }</span>
        <span className="icon-radio-button-off date-status-green"/>
      </React.Fragment>
    )
  }
}

const generateComplianceDocumentFormElements = (section, columns, getFieldDecorator, onChange, required = false) => {
  const columnsElements = []
  columns.map((column, key) => {
    switch (column.type) {
      case 'FILE':
        columnsElements.push(
          <Form.Item key={ key } label={ column.name } className={ `form-element upload-${ section }` }>
            { getFieldDecorator(`upload-${ column.name }-${ column.id }`, {
              rules: [ { required: required || column.required, message: 'Required field' } ]
            })(
              <div className="custom-file">
                <input type="file" name="file" aria-label="File browser example" className="custom-file-input" id="file"
                  onChange={ (e) => onChange(e.target.files[0], column.id, `upload-label-${ column.id }`) }/>
                <label htmlFor="file" className="custom-file-label" id={ `upload-label-${ column.id }` }>UPLOAD
                  FILE...</label>
              </div>
            ) }
          </Form.Item>
        )
        break
      case 'TEXT':
        columnsElements.push(
          <Form.Item key={ key } label={ column.name } className={ `form-element text-${ section }` }>
            { getFieldDecorator(`text-${ column.name }-${ column.id }`, {
              rules: [ { required: required || column.required, message: 'Required field' } ]
            })(
              <Input placeholder="Text goes here..." onChange={ (e) => onChange(e.target.value, column.id) } className="custom-input"/>
            ) }
          </Form.Item>
        )
        break
      case 'TEXTAREA':
        columnsElements.push(
          <Form.Item key={ key } label={ column.name } className={ `form-element textarea-${ section }` }>
            { getFieldDecorator(`textarea-${ column.name }-${ column.id }`, {
              rules: [ { required: required || column.required, message: 'Required field' } ]
            })(
              <textarea onChange={ (e) => onChange(e.target.value, column.id) } className="custom-textarea"
                rows="2"/>
            ) }
          </Form.Item>
        )
        break
      case 'CHECKBOX':
        columnsElements.push(
          <Form.Item key={ key } label={ column.name } className={ `form-element checkbox-${ section }` }>
            { getFieldDecorator(`checkbox-group-${ column.name }-${ column.id }`, {
              rules: [ { required: required || column.required, message: 'Required field' } ]
            })(
              <Checkbox.Group onChange={ (e) => onChange(e, column.id) } className="custom-checkbox">
                {
                  column.options.map((option, key) => <Checkbox value={ option.name } key={ key }><span
                    className={ option.icon }/>{ ` ${ option.name }` }</Checkbox>)
                }
              </Checkbox.Group>
            ) }
          </Form.Item>
        )
        break
      case 'RADIO':
        columnsElements.push(
          <Form.Item key={ key } label={ column.name } className={ `form-element radio-${ section }` }>
            { getFieldDecorator(`radio-button-${ column.name }-${ column.id }`, {
              rules: [ { required: required || column.required, message: 'Required field' } ]
            })(
              <Radio.Group onChange={ (e) => onChange(e.target.value, column.id) } className="custom-radio">
                {
                  column.options.map((option, key) => <Radio value={ option.name }
                    key={ key }>{ option.name }</Radio>)
                }
              </Radio.Group>
            ) }
          </Form.Item>
        )
        break
      case 'DATE':
        columnsElements.push(
          <Form.Item key={ key } label={ column.name } className={ `form-element date-${ section }` }>
            { getFieldDecorator(`date-${ column.name }-${ column.id }`, {
              rules: [ { required: required || column.required, message: 'Required field' } ]
            })(
              <DatePicker format={ column.dateFormat }
                onChange={ (momentDate, stringDate) => onChange(stringDate, column.id) }
                placeholder='SELECT DATE' className="custom-calendar"/>
            ) }
          </Form.Item>
        )
        break
      case 'NUMBER':
        columnsElements.push(
          <Form.Item key={ key } label={ column.name } className={ `form-element input-number-${ section }` }>
            { getFieldDecorator(`input-number-${ column.name }-${ column.id }`, {
              rules: [ { required: required || column.required, message: 'Required field' } ]
            })(
              <InputNumber min={ 0.01 } step={ 0.01 } onChange={ (value) => onChange(value, column.id) }
                precision={ 2 } placeholder={ 0.00 } className="custom-input-number"/>
            ) }
          </Form.Item>
        )
        break
      case 'DROPDOWN':
        columnsElements.push(
          <Form.Item key={ key } label={ column.name } className={ `form-element dropdown-${ section }` }>
            { getFieldDecorator(`dropdown-${ column.name }-${ column.id }`, {
              rules: [ { required: required || column.required, message: 'Required field' } ]
            })(
              <Select onChange={ (value) => onChange(value, column.id) } placeholder={ `SELECT ${ column.name }` } className="custom-select">
                {
                  column.options.map((option, key) => <Option value={ option.name }
                    key={ key }>{ option.name }</Option>)
                }
              </Select>
            ) }
          </Form.Item>
        )
        break
      default :
        break
    }
    return true
  })
  return columnsElements
}

export const generateComplianceDocumentFormColumns = (columns, getFieldDecorator, onChange) => {
  return generateComplianceDocumentFormElements('columns', columns, getFieldDecorator, onChange, true)
}
export const generateComplianceDocumentFormHistoryUpdate = (historyUpdate, getFieldDecorator, onChange) => {
  return generateComplianceDocumentFormElements('history-update', historyUpdate, getFieldDecorator, onChange, true)
}
export const generateComplianceDocumentFormAdditionalFields = (additionalFields, getFieldDecorator, onChange) => {
  return generateComplianceDocumentFormElements('additional-fields', additionalFields, getFieldDecorator, onChange)
}

export const generateDocumentUpdateAdditionalReadableContent = (column, defaultValue, key = 1) => {
  defaultValue = Array.isArray(defaultValue) ? defaultValue : Array(defaultValue)
  switch (column.type) {
    case 'TEXT':
      return <div className="preview-container" key={key}>
        <span className="title">{column.name || ''}</span>
        <Input placeholder="Text goes here..." className="preview-container__text custom-input" defaultValue={defaultValue && defaultValue.length > 0 ? defaultValue[0] : ''} disabled/>
      </div>
    case 'TEXTAREA':
      return <div className="preview-container" key={key}>
        <span className="title">{column.name || ''}</span>
        <textarea className="custom-textarea preview-container__text-area custom-textarea" rows="2" defaultValue={defaultValue && defaultValue.length > 0 ? defaultValue[0] : ''} disabled/>
      </div>
    case 'CHECKBOX':
      return (
        <div className="preview-container" key={key}>
          <span className="title">{column.name || ''}</span>
          <Checkbox.Group defaultValue={defaultValue} className="custom-checkbox preview-container__checkbox" disabled>
            {
              column.options.map((option, key) => <Checkbox value={ option.name } key={ key }><span
                className={ option.icon }/>{ ` ${ option.name }` }</Checkbox>)
            }
          </Checkbox.Group>
        </div>
      )
    case 'RADIO':
      return <div className="preview-container" key={key}>
        <span className="title">{column.name || ''}</span>
        <Radio.Group defaultValue={defaultValue && defaultValue.length > 0 ? defaultValue[0] : ''} className="preview-container__radio custom-radio" disabled>
          {
            column.options.map((option, key) => <Radio value={ option.name }
              key={ key }>{ option.name }</Radio>)
          }
        </Radio.Group>
      </div>
    case 'DATE':
      return <div className="preview-container" key={key}>
        <span className="title">{column.name || ''}</span>
        <span className="preview-container__default">{defaultValue && defaultValue.length > 0 ? moment(defaultValue[0]).format(column.dateFormat || 'DD/MM/YY') : ''}</span>
      </div>
    default:
      return <div className="preview-container" key={key}>
        <span className="title">{column.name || ''}</span>
        <span className="preview-container__default">{defaultValue && defaultValue.length > 0 ? defaultValue[0] : ''}</span>
      </div>
  }
}

export const downloadOriginalFile = (originalFileBytes, documentExtension, mimeType, documentName) => {
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.style = 'display: none'

  const url = window.URL.createObjectURL(_convertBase64toBlob(originalFileBytes, _generateMimeType(documentExtension, mimeType)))
  a.href = url
  a.download = documentName
  a.click()
  window.URL.revokeObjectURL(url)
}

const _generateMimeType = (documentExtension, mimeType) => {
  switch (documentExtension) {
    case 'docx':
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    case 'doc':
      return 'application/msword'
    case 'xls':
      return 'application/vnd.ms-excel'
    case 'xlsx' :
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    case 'ppt' :
      return 'application/vnd.ms-powerpoint'
    case 'pptx' :
      return 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    case 'csv' :
      return 'text/csv'
    case 'png' :
      return 'image/png'
    case 'jpg' :
      return 'image/jpeg'
    case 'odt' :
      return 'application/vnd.oasis.opendocument.text'
    case 'sldx':
      return 'application/vnd.openxmlformats-officedocument.presentationml.slide'
    case 'ppsx':
      return 'application/vnd.openxmlformats-officedocument.presentationml.slideshow'
    case 'svg':
      return 'image/svg+xml'
    case 'bmp' :
      return 'image/bmp'
    default : return mimeType
  }
}

const _convertBase64toBlob = (content, contentType) => {
  contentType = contentType || ''
  const sliceSize = 512
  const byteCharacters = window.atob(content) //method which converts base64 to binary
  const byteArrays = [
  ]
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)
    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }
  //statement which creates the blob
  return new Blob(byteArrays, {
    type: contentType
  })
}