import React, { Component } from 'react'
import Icon from 'antd/es/icon'
import '../../Sar/Tracker/DocumentNote/DocumentNote.scss'
import PropTypes from 'prop-types'
import ReactQuill from 'react-quill'
import moment from 'moment'
import { Spin } from 'antd'
import { getNotesFolder, saveNotesFolder } from '../../../api/SmartBox'
import {connect} from "react-redux";
import CustomNotificationManager from "../../Common/CustomNotificationManager";
import {setSmartBoxAddedNote} from "../../../actions/smartBoxActions";
import withRouter from '../../../private-routers/withRouter'

class DirectoryNote extends Component {
  state = {    
    theme: 'snow',
    saveLoading: false,
    note: '',
    notes: []
  }

  modules = {
    toolbar: [
      [ { 'header': '1' }, { 'header': '2' }, { 'font': [] } ],
      [ { size: [] } ],
      [ { align: [] } ],
      [ 'bold', 'italic', 'underline', 'strike' ],
      [ { 'color': [] }, { 'background': [] } ],
      [ { 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' } ],
      [ 'link', 'image' ]
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    }
  }

  componentDidMount() {
      this.fetchData()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.documentId !== this.props.documentId) {
      this.fetchData()
    }
  }

  fetchData = () => {
    getNotesFolder(this.props.documentId).then((res) => {
      if (res.data) {
        this.setState({
          notes: res.data
        })
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  
  saveDocumentNote = () => {
    this.setState({
      saveLoading: true
    }, () => {
      this.saveData()
    })
  }

  saveData = () => {
    const data = {
      note: this.state.note,
      docId: this.props.documentId
    }
    saveNotesFolder(data).then((res) => {
      let addNote = []
      const notAddNote = [{
        nameActionEvent: `${ this.props.user.name } added a note`,
        descriptionActionEvent: `${ this.props.documentName }`,
        timesEvent: moment().format('DD/MM/YYYY HH:mm:ss'),
        status: (this.props.type === 'DIRECTORY' && this.props.depthFromRoot === 0) ? 'box' :
                (this.props.type === 'DIRECTORY' && this.props.depthFromRoot > 0) ? 'folder' :
                (this.props.type === 'DIRECTORY' && this.props.depthFromRoot === null && this.props.depth > 0 ) ? 'folder' :
                (this.props.type !== 'DIRECTORY') ? 'file' : '',
        documentName: this.props.documentName,
        depthFromRoot: this.props.depthFromRoot,
        type: this.props.type,
      }]
      addNote = notAddNote.concat(this.props.smartBoxAddedNote)
      const uniqueFilesNotes = addNote && addNote.length > 0 && addNote.filter((a, i) => addNote.findIndex((s) => a.documentName === s.documentName) === i)
      this.props.setSmartBoxAddedNote(uniqueFilesNotes)
      const newNote = { 
        note: this.state.note, 
        date: moment(), 
        username: this.props.user.given_name
      }
      this.setState({
        saveLoading: false, 
        note: '',
        notes: [...this.state.notes, newNote]
      })
    }).catch((error) => {
      const errorMessage = 'Folder id does not exist'
      if (error?.response?.status === 500) {
        if (typeof error.response.data.message !== 'undefined' && error.response.data.message.indexOf(errorMessage) > 0) {
          this.props.closeDocumentNote()
          CustomNotificationManager.error('The current folder no longer exists or you do not have access to its settings', 'Error')
          const path = `/smart-box`
          this.props.navigate(path)
        }
      }else if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  
 
  render() {
    const { theme, note, saveLoading, notes } = this.state
    return (
      <div className="document-note-page">
        <div className="document-note-page-container">
          <div className="document-note-page-container__header">
            <span className="document-note-page-container__header--text">DOCUMENT NOTE</span>
            <span className="document-note-page-container__header--close-icon">
              <Icon type="close" onClick={ this.props.closeDocumentNote }/>
            </span>
          </div>
          <div className="document-note-page-container__content directory-notes">
            { notes.map((n, index) => 
              <div key={`note-${index}`} className="directory-note">
                <div className="user-img"></div>
                <div className="user-data"><strong>{n.username}</strong>{ moment(n.date).format('DD.MM.YYYY HH:mma') }</div>
                <div className="user-note" dangerouslySetInnerHTML={{__html: n.note}}></div>
              </div>
              ) }
            <ReactQuill
              theme={ theme }
              onChange={ (value) => this.setState({ note: value }) }
              value={ note }
              modules={ this.modules }
            />
          </div>
          <div className="document-note-page-container__footer">
            <button className="button-green cancel-button" disabled={ saveLoading } onClick={ this.props.closeDocumentNote }>Cancel</button>
            { saveLoading ?
              <Spin className="save-loading" spinning={ saveLoading }/> :
              <button className="button-green" onClick={this.saveDocumentNote}>Save</button>
            }
          </div>
        </div>
      </div>
    )
  }
}

DirectoryNote.propTypes = {
  closeDocumentNote: PropTypes.func.isRequired,
  documentId: PropTypes.string.isRequired
}
DirectoryNote = withRouter(DirectoryNote)
const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  smartBoxAddedNote: state.smartBox.smartBoxAddedNote
})

export default connect(mapStateToProps, { setSmartBoxAddedNote })(DirectoryNote)