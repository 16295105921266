import React from 'react'
import { Popover, Collapse, Modal, Tooltip, Input} from 'antd'
import {
  apiSmartBoxSaveRegularExpressionGlobal,
  apiEditRegularExpressionGlobal,
  getSmartBoxAllRegexList,
  saveRegularExpressionsColor,
  selectRegularExpressions,
  deleteRegularExpressionsGlobal,
} from '../../../../../api/SmartBox'
import { connect } from 'react-redux';
import { setSmartBoxFetchTreeData } from '../../../../../actions/smartBoxActions';
import {CompactPicker} from "react-color";
import CustomNotificationManager from "../../../../Common/CustomNotificationManager";
import './RegularExpressions.scss'
import {customConfirm} from "../../../../../utils/AssetRegister/CustomConfirm";

class RegularExpressionsAll extends React.Component {
  state = {
    visibleRegularExpressions: false,
    visibleExpressionBuilder: false,
    unit: '',
    unitModal: '',
    activeIndex: -1,
    caseSensitive: false,
    regex: '',
    title: '',
    description: '',
    regexModal: '',
    titleModal: '',
    descriptionModal: '',
    regexList: [],
    regexOpen:[],
    regexActive: null,
    color: 'rgba(77, 100, 113, 1)',
    colorPickerVisible: false,
    editModalVisible: false,
    selectedList: [],
    selectAllChecked: false,
    saveStep: 0,
    optionsEnabled: true,
    expandKeysRegex: [],
    onActiveAdd: false,
    hoverDelete:{},
  }
  
  ua = window.navigator.userAgent.toLowerCase();
  isIE = !!this.ua.match(/msie|trident\/7|edge/);
  isWinPhone = this.ua.indexOf('windows phone') !== -1;
  isIOS = !this.isWinPhone && !!this.ua.match(/ipad|iphone|ipod/);

  componentDidMount() {
    // if (this.props.folder.id) {
      this.fetchData()
    // }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // if (prevProps.folder.id !== this.props.folder.id) {
    //   this.fetchData()
    // }
  }

  fetchData = () => {
    getSmartBoxAllRegexList().then((res) => {
      if (res.status === 200) {
        let regexList = []
        for (const value of Object.values(res.data)) {
          regexList.push(value)
        }
        regexList.sort((a,b) => a.title.localeCompare(b.title) )

        //const listLength = regexList.length

        this.setState({ regexList }, () => {
          /*getSelectedRegex(this.props.folder.id).then((result) => {
            if (result.status === 200) {
              if (result.data.length) {
                optionsEnabled= false
                saveStep= 2
                if (result.data.length === listLength) {
                  selectAllChecked = true
                }
              }
              this.setState({
                selectedList: result.data,
                optionsEnabled,
                saveStep,
                selectAllChecked
              })
            }
          })*/
        })
        if (res.data.color) {
          this.setState({ color: this.replaceAlpha(res.data.color) })
        }
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  
  onClickVisibleRegular = () => {
    this.setState({
      visibleRegularExpressions: !this.state.visibleRegularExpressions
    })
  }
  
  onClickVisibleBuilder = () => {
    this.setState({
      visibleExpressionBuilder: !this.state.visibleExpressionBuilder
    })
  }

  replaceAlpha = (color) => {
    const temp = color.split(',')
    if (temp.length === 4) {
      temp[3] = " 1)"
      color = temp.join()
    }
    return color
  }
  
  applyHighlights(text, regExp) {
    const str = regExp.toString()
    const lastSlash = str.lastIndexOf('/')
    let restoredRegex = ''
    let isRegexValid
    try {
      restoredRegex = new RegExp(str.slice(1, lastSlash), str.slice(lastSlash + 1))
      isRegexValid = true
    } catch (e) {
      isRegexValid = false
    }
  
    text = text
      .replace(/\n$/g, '\n\n')
      .replace(restoredRegex, '<mark>$&</mark>')
    
    if (this.isIE) {
      // IE wraps whitespace differently in a div vs textarea, this fixes it
      text = text.replace(/ /g, ' <wbr>')
    }
    
    return { highlightedText: text, isRegexValid }
  }
  
  handleScroll = () => {
    document.getElementById('backdrop').scrollTop = document.getElementById('text-area-2').scrollTop

    document.getElementById('backdrop').scrollLeft = document.getElementById('text-area-2').scrollLeft
  }
  
  onChange = (e, type) => {
    switch (type) {
      case 'regex-input':
        this.setState({
          regex: e.target.value
        })
        break
      case 'regex-input-modal':
        this.setState({
          regexModal: e.target.value
        })
        break
      case 'regex-name':
        this.setState({
          title: e.target.value
        })
        break
      case 'regex-name-modal':
        this.setState({
          titleModal: e.target.value
        })
        break
      case 'regex-description':
        this.setState({
          description: e.target.value
        })
        break
      case 'regex-description-modal':
        this.setState({
          descriptionModal: e.target.value
        })
        break
      case 'textarea':
        this.setState({
          unit: e.target.value
        }, () => {
        })
        break
      case 'textarea-modal':
        this.setState({
          unitModal: e.target.value
        }, () => {
        })
        break
      default:
        break
    }
  }

  convertRgbaToString = ({ r, g, b, a }) => {
    return `rgba(${ r }, ${ g }, ${ b }, ${ a })`
  }

  openColorPickerVisible = (e) => {
    this.stop(e)
    this.setState({ colorPickerVisible: true })
  }

  closeAndSaveColorPicker = (color, e) => {
    this.stop(e)
    const convertedColor = this.convertRgbaToString(color.rgb)
    this.setState({ color: convertedColor, colorPickerVisible: false },
        () => {
          saveRegularExpressionsColor({color: convertedColor, folderPath: this.props.folder.path }).then((result) => {
            CustomNotificationManager.success('The colour was changed successfully.', 'Success')
          }).catch((error) => {
            if (error?.response?.status === 504) {
              CustomNotificationManager.cleanBuffer('warning', '')
            }else{
              console.log(error)
            }
          })
        })
  }

  saveRegularExpression = () => {
    const { regex, title, description } = this.state
    const data = {
      regex,
      title,
      description
    }
    if (title && description && regex) {
      apiSmartBoxSaveRegularExpressionGlobal(data).then((response) => {
        if (response.status === 200) {
          CustomNotificationManager.success('Regular expression saved.', 'Success')
        }
        this.setState({
          description: '',
          title: '',
          regex: '',
          unit: '',
          visibleRegularExpressions: true
        }, () => {
          this.props.setSmartBoxFetchTreeData(true)
          setTimeout(this.fetchData, 2000)
          // this.fetchData()
        })
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    }
  }

  stop = (e) => {
    e.stopPropagation()
    e.preventDefault()
  }

  toggleAccordeon = (key) => {
    const { regexList }= this.state
    let  { regexActive }= this.state
    let regexOpen = key
    if (key.length) {
      regexOpen= key[key.length-1]
      if (regexList && regexList.length) {
        regexList.map( (item) => {
          if (item.id === regexOpen) {
            regexActive = item
          }
          return true
        })
      }
    }
    this.setState({ regexOpen, regexActive, expandKeysRegex: key })
  }

  selectItem = (e, id) => {
    const { selectedList, regexList } = this.state
    let { selectAllChecked, saveStep } = this.state
    this.stop(e)
    if(e.target.checked) {
      selectedList.push(id)
    } else {
      let selectedKey = -1
      if (selectedList.includes(id)) {
        selectedList.map((item, key) => {
          if (item === id) {
            selectedKey = key
          }
          return true
        })
      }
      if (selectedKey >= 0) {
        selectedList.splice(selectedKey, 1)
      }
    }
    // set select all ckecked or unchecked
    if (regexList.length === selectedList.length) {
      selectAllChecked = true
    } else {
      if (!selectedList.length) {
        selectAllChecked = false
      }
    }
    // change step 0 to 1 when a radio button is checked
    if (selectedList.length && !saveStep) {
      saveStep = 1
    } else if (!selectedList.length) {
      saveStep = 0
    }
    this.setState({ selectedList, selectAllChecked, saveStep })
  }

  toggleEditModal = (e, visible, id=null) => {
    const { regexList }= this.state
    let { regexActive }= this.state
    e.stopPropagation()
    e.preventDefault()
    let editModalVisible= !this.state.editModalVisible
    if (visible) {
      editModalVisible= visible
      if (regexList && regexList.length) {
        regexList.map( (item) => {
          if (item.id === id) {
            regexActive = item
            this.setState({titleModal: item.title, descriptionModal: item.description, regexModal: item.regex})
          }
          return true
        })
      }
    }
    this.setState({ editModalVisible, regexActive })
  }

  submitEditForm = (e) => {
    const { regexList, regexActive, titleModal, descriptionModal, regexModal } = this.state
    const data = {
      id: regexActive.id,
      title: titleModal ? titleModal : regexActive.title,
      description: descriptionModal ? descriptionModal : regexActive.description,
      regex: regexModal ? regexModal : regexActive.regex
    }

    apiEditRegularExpressionGlobal(data).then((response) => {
      if (response.status === 200) {
        let selectedKey = -1
        regexList.map( (item, key) => {
          if (item.id === regexActive.id) {
            selectedKey = key
          }
          return true
        })
        if (selectedKey >= 0) {
          regexList[selectedKey].title = titleModal ? titleModal : regexActive.title
          regexList[selectedKey].description = descriptionModal ? descriptionModal : regexActive.description
          regexList[selectedKey].regex = regexModal ? regexModal : regexActive.regex
        }
        this.setState({ editModalVisible: false, regexList })
        CustomNotificationManager.success('Regular expression changes applied successfully!', 'Success')
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  removeRegularExpressions = (e, id, regex) => {
    this.stop(e)
    const { regexList } = this.state
    const newRegexList = regexList.filter(item => item.id !== id)

    this.setState({ regexList: newRegexList })
    const data = { regex: [regex] }
    deleteRegularExpressionsGlobal(data).then((response) => {
      CustomNotificationManager.success('Regular expression has been deleted.', 'Success')
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  showConfirmDeleteRegEx = (e, id, regex) => {
    this.stop(e)
      customConfirm({
        title: <span className="icon-v14-delete"></span>,
        content: `Delete regular expression ${ regex } ?`,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        mask: true,
        maskClosable: false,
        closeHaveLogic: false,
        className: 'asset-register-modal modal-delete-duplicate modal-delete-regex-ex',
        centered: true,
        cancelClassName: 'cancel-button',
        confirmClassName: 'confirm-button',
        confirmAction: () => {
          this.removeRegularExpressions(e, id, regex)
        }
      })
  }
  selectAllItems = (e) => {
    const { regexList } = this.state
    let { selectedList, selectAllChecked, saveStep } = this.state
    if(e.target.checked) {
      if (regexList && regexList.length) {
        regexList.forEach( (regex) => {
          selectedList.push(regex.id)
        })
        selectAllChecked = true
      }
      saveStep= 1
    } else {
      selectedList = []
      selectAllChecked = false
      saveStep= 0
    }
    this.setState({ selectedList, selectAllChecked, saveStep })
  }

  selectRegularExpression = () => {
    const { selectedList, saveStep } = this.state
    const { folder } = this.props
    if (saveStep === 1) {
      const data = {ids: selectedList, folderPath: folder.path}
      selectRegularExpressions(data).then((response) => {
        CustomNotificationManager.success('Selected regular expressions were sent.', 'Success')
        if (selectedList.length) {
          this.setState({ saveStep: 2, optionsEnabled: false })
        } else {
          this.setState({ saveStep: 0, optionsEnabled: true })
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    } else if (saveStep === 2) {
      this.setState({ saveStep: 1, optionsEnabled: true })

    }

  }

  renderEditModal = () => {
    const { editModalVisible, regexActive, titleModal, descriptionModal, regexModal } = this.state
    let submitCondition = false
    if (regexActive) {
      submitCondition = (titleModal || regexActive.title) && (descriptionModal || regexActive.description) && (regexModal || regexActive.regex)
    }
    return (
        <Modal className="import-terms-modal" width={700} title={ false } visible={ editModalVisible } onCancel={ (e) => this.toggleEditModal(e,false) } closable={ true } footer={ null } >
          <div className="import-terms-section">
            <h1 className="import-terms-title">Edit Regular Expression</h1>
            <br/>
            <div className="edit_form">
              <div className="insert-regular-expression">
                <label className="edit_form__label" htmlFor="edit_modal_name">Regex name:</label>
                <input className="input-insert-expression" id="edit_modal_name" onChange={ (e) => this.onChange(e, 'regex-name-modal') } value={titleModal} />
              </div>
              <div className="insert-regular-expression">
                <label className="edit_form__label" htmlFor="edit_modal_description">Regex description:</label>
                <input className="input-insert-expression" id="edit_modal_description" onChange={ (e) => this.onChange(e, 'regex-description-modal') } value={descriptionModal}/>
              </div>
              <div className="insert-regular-expression">
                <label className="edit_form__label" htmlFor="edit_modal_regex">Your regular expression:</label>
                <input className="input-insert-expression" id="edit_modal_regex" onChange={ (e) => this.onChange(e, 'regex-input-modal') } value={regexModal}/>
              </div>
              <div className="button-container">
                <button className={`button-green ${!submitCondition?'btn-disabled':''}`} onClick={(e)=>this.submitEditForm(e)} disabled={!submitCondition} >Update</button>
              </div>
            </div>
          </div>
        </Modal>
    )
  }

  renderEditIcon = (id, disabled) => {
    return (
      <span className={ disabled ? 'disabled regular-expression-edit' : 'icon-edit-b regular-expression-edit' } onClick={ (e) => disabled ? undefined : this.toggleEditModal(e, true, id) } />
    )
  }

  renderPopOver = () => {
    const { color, colorPickerVisible } = this.state
    const renderColorPicker = (
        <React.Fragment>
          <div className="business-term-color-picker">
            <CompactPicker
                className="color-picker-a"
                colors={['rgba(107, 91, 149, 1)', 'rgba(254, 178, 54, 1)', 'rgba(214, 65, 97, 1)',
                  'rgba(255, 123, 37, 1)', 'rgba(75, 195, 193, 1)', 'rgba(194, 194, 71, 1)', 'rgba(181, 231, 160, 1)',
                  'rgba(191, 108, 64, 1)', 'rgba(62, 68, 68, 1)', 'rgba(130, 183, 75, 1)', 'rgba(64, 93, 39, 1)',
                  'rgba(193, 148, 106, 1)', 'rgba(60, 136, 180, 1)', 'rgba(200, 100, 91, 1)', 'rgba(238, 162, 154, 1)',
                  'rgba(201, 76, 76, 1)', 'rgba(124, 108, 6, 1)', 'rgba(15, 195, 71, 1)', 'rgba(231, 82, 114, 1)',
                  'rgba(238, 140, 54, 1)', 'rgba(92, 212, 209, 1)', 'rgba(211, 211, 88, 1)', 'rgba(198, 248, 177, 1)',
                  'rgba(192, 125, 81, 1)', 'rgba(77, 100, 113, 1)', 'rgba(237, 226, 220, 1)', 'rgba(132, 206, 235, 1)']}
                disableAlpha={true}
                color={color}
                onChange={(color, e) => this.closeAndSaveColorPicker(color, e)}
            />
          </div>
        </React.Fragment>
    )
    return (
      <Popover content={renderColorPicker} trigger="click" visible={ colorPickerVisible } >
        <span className="regular-selected-color"  style={{backgroundColor: color}} onClick={ (e) => this.openColorPickerVisible(e) } />
      </Popover>
    )
  }
  onMouseEnter = (e, regex) => {
    this.setState ({
      hoverDelete: regex
    })
  }
  onMouseLeave = () => {
    this.setState ({
      hoverDelete:{}
    })
  }
  thisActive = (isActive) => {
    return true
  }
  onHoverAdd = () => {
    this.setState({
      onActiveAdd: true
    })
  }
  onLeaveAdd = () => {
    this.setState({
      onActiveAdd: false
    })
  }
  render() {
    const { regex, title, description, regexList,
      onActiveAdd, hoverDelete } = this.state
    let { disabled } = this.props
    //const { Panel } = Collapse
    const allowAdd = title && description && regex

    if(typeof disabled === 'undefined') {
      disabled = false
    }
    // save button
    // let saveBtnTitle = 'Confirm Selection'
    // let saveBtnClass = 'btn-grey'
    // if (saveStep === 1) {
    //   saveBtnClass = 'btn-green-fill'
    // } else if (saveStep === 2) {
    //   saveBtnTitle = 'Update Selection'
    //   saveBtnClass = 'btn-green'
    // }
    return (
      <div className="access-expressions regex-all">
        <div className="access-expressions__contain-regular-expressions">
          <div className="box-title">Regular Expressions
            <Tooltip overlayClassName="help-message-locations" title={<div className="content-help-message-locations">
              <span className="text-information">Create expressions to search for patterns within your data.</span>
              <span className="more-info-link" onClick={ () => window.open('https://support.smartbox.ai/support/solutions/articles/47001202655', '_blank') }>More info.</span>
            </div>}>
              <span className="icon-v14-question"></span>
            </Tooltip>
          </div>
          {/*<h3>Build complex rules to search within your data</h3>*/}
          {/*<Collapse
            defaultActiveKey={['list']}
            bordered={ false }
            expandIconPosition="right"
            expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} /> }
          >
            <Panel header="Your Regular Expressions" key="list" extra={ this.renderPopOver() }>*/}
              <div className="access-expressions__content-inputs">
                <div className="insert-regular-expression">
                  { disabled ?
                    <Input readOnly className="input-insert-expression" onChange={ (e) => this.onChange(e, 'regex-name') }
                           placeholder="Name" value={ title }/>
                    : <Input className="input-insert-expression" onChange={ (e) => this.onChange(e, 'regex-name') }
                             placeholder="Name" value={ title }/>
                  }
                </div>
                <div className="insert-regular-expression">
                  { disabled ?
                    <Input readOnly className="input-insert-expression" onChange={ (e) => this.onChange(e, 'regex-description') }
                           placeholder="Description" value={ description }/>
                    :
                    <Input className="input-insert-expression" onChange={ (e) => this.onChange(e, 'regex-description') }
                           placeholder="Description" value={ description }/>
                  }
                </div>
                <div className="insert-regular-expression">
                  { disabled ?
                    <Input readOnly className="input-insert-expression" onChange={ (e) => this.onChange(e, 'regex-input') }
                           placeholder="/ insert regular expression" value={ regex }/>
                    :
                    <Input className="input-insert-expression" onChange={ (e) => this.onChange(e, 'regex-input') }
                           placeholder="/ insert regular expression" value={ regex }/>
                  }
                  <div className="add-regular-expression" onClick={ disabled ? undefined : this.saveRegularExpression }>
                    <button className={ onActiveAdd ? "icon-v19-add-active" : "icon-v19-add" } disabled={ !allowAdd || disabled } style={{cursor: allowAdd ? 'pointer': 'not-allowed'}}
                    onMouseEnter={() => this.onHoverAdd()}
                    onMouseLeave={() => this.onLeaveAdd()}/>
                    {/*<span className="text-add" style={{cursor: allowAdd ? 'pointer': 'not-allowed'}}>Add Regular Expression</span>*/}
                  </div>
                </div>
              </div>
              <div className="access-expressions__content-radio">
                <div className="regexp-radio-check">
                  {/* regexList && regexList.length > 0 &&
                    <div className="select-all">
                      <Checkbox
                        className="select-all__label"
                        checked={selectAllChecked}
                        onChange={(e) => this.selectAllItems(e)}
                        disabled={!optionsEnabled || disabled}
                      >
                        {selectAllChecked ? 'Clear' : 'Select'} all
                      </Checkbox>
                      <button className="icon-delete-b select-all__delete" disabled={ disabled }
                              onClick={(e) => this.removeRegularExpressions(e)}/>
                    </div>
                  */}
                  <Collapse
                    bordered={ false }
                    expandIconPosition="right"
                    expandIcon={({ isActive }) => this.thisActive(isActive) }
                    onChange={ (key)=>this.toggleAccordeon(key) }
                  >
                    {
                      regexList && regexList.length > 0 && regexList.map((regex, key) =>

                          <div className='panel-container' key={"regex"+key}
                               onClick={ (e) => this.toggleEditModal(e, true, regex.id) }>
                            {/*regexActive*/ true &&
                            <React.Fragment>
                              <div className="pull-left"><strong>{regex.title}</strong></div>
                              <div className="pull-right">
                                <button className={hoverDelete.id === regex.id ? 'icon-v14-delete-active' : "icon-v14-delete"}
                                        onClick={(e) => this.showConfirmDeleteRegEx(e, regex.id, regex.regex)}
                                        onMouseEnter={(e) => this.onMouseEnter(e, regex)}
                                        onMouseLeave={() => this.onMouseLeave()}/>
                              </div>
                              <div className="clearfix"></div>
                              <div className='panel-container__left'>
                                <p><span className="font-weight-bold">Name:</span> {regex.title}</p>
                                <p><span className="font-weight-bold">Description:</span> {regex.description}</p>
                              </div>
                              <div className='panel-container__right'>
                                <p><span className="font-weight-bold">Regular expression:</span> {regex.regex}</p>
                              </div>
                            </React.Fragment>
                            }
                          </div>

                      )
                    }
                  </Collapse>
                </div>
              </div>
          {/*</Panel>
            <Panel header="Regular Expression Builder" key="add">*/}

          {/*</Panel>*/}
            { /*regexList && regexList.length > 0 &&
            <div className="scan-now-container">
              <button className={`button-green ${ saveBtnClass }`} disabled={!saveStep || disabled } onClick={ () => this.selectRegularExpression() } >
                { saveBtnTitle }
              </button>
            </div>*/
            }
          {/*</Collapse>*/}
        </div>
        { this.renderEditModal() }
      </div>
    )
  }
}
const mapStateToProps = () => ({})

export default connect(mapStateToProps, { setSmartBoxFetchTreeData })(RegularExpressionsAll)