import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import swal from 'sweetalert2'

import Loading from '../../../Common/Loading'
import RenderedDataLine from './RenderedDataLine'

import './DataLineItem.css'

import { 
  apiOspreyGetDataLineInfo,
  apiOspreyStopDataLine,
  apiOspreyStartDataLine,
  apiOspreyGetDataModelQuery,
  apiOspreyDeleteQuery,
  apiOspreyFlush
} from '../../../../api/OspreyAnalytics.js'

class DataLineItem extends React.Component {

  state = {
    datalineRunningInfo: null,
    datalineRunning: false,
    dataline: null,
    datalineId: null,
    loadingCount: true,
    loadingStatus: true,
    numdocs: 0
  }

  componentDidMount = () => {
    const { dataline } = this.props

    this.setState({
      dataline: dataline,
    })

    this.getDataLineRunning(dataline.id)
    this.getCount(dataline.id)
  }

  componentWillUnmount = () => {}

  getDataLineRunning = (id) => {
    apiOspreyGetDataLineInfo(id)
      .then((response) => {
  
        let isRunning = false
        let runningInfo = null

        if (response.data.engineStatus.processCodes[id] &&
          response.data.engineStatus.processCodes[id] === 200)
        {
          isRunning = true

          if (response.data.engineStatus.processData[id] &&
            response.data.engineStatus.processData[id].status)
          {
            runningInfo = response.data.engineStatus.processData[id].status
          }
        }

        this.setState({
          datalineRunningInfo: runningInfo,
          datalineRunning: isRunning,   
          loadingStatus: false     
        })
      })
      .catch((error) => {
        console.log(error, 'error')
      })
  }

  stopDataLine = (id) => {
    apiOspreyStopDataLine(id)
      .then((response) => {
      })
      .catch((error) => {
        console.log(error, 'error')
      })

    this.setState({
      datalineRunningInfo: null,
      datalineRunning: false          
    })
  }

  startDataLine = (id) => {
    this.setState({
      loadingStatus: true     
    })
    apiOspreyStartDataLine(id)
      .then((response) => {
        this.getDataLineRunning(id)
      })
      .catch((error) => {
        console.log(error, 'error')
      })
  }

  getCount = (id) => {
    apiOspreyGetDataModelQuery('documents', '*:*', ['doc_id'], [`doc_crawlerid:${ id }`], 0, 1, '', true)
      .then((response) => {
        this.setState({
          numdocs: response.data.pagination.total,
          loadingCount: false
        })
      })
      .catch((error) => {
        console.log(error, 'error')
      })
  }

  delete = () => {
    this.setState({
      loadingCount: true
    })

    apiOspreyDeleteQuery(`doc_crawlerid:${ this.state.dataline.id }`)
      .then((response) => {
        this.flush()
      })
      .catch((error) => {
        console.log(error, 'error')
      })
  }

  showDeleteConfirmation = () => {
    swal({
      showConfirmButton: true,
      showCancelButton: true,
      html: '<p style="font-size: 14px; margin: 10px 0; text-align: center;">Are you sure that you want to delete this content?</p> ',
      title: 'Delete documents',
      type: 'question',
      padding: 50
    }).then((result) => {
      if (result.value) this.delete()
    })
  }

  flush = () => {
    apiOspreyFlush()
      .then((response) => {
        this.getCount(this.state.dataline.id)
      })
  }

  render = () => {
    const { dataline } = this.props

    return (
      <div className="datalineItem__wrapper">
        <header className="datalineItem__header">
          <h1 className="datalineItem__title">{ dataline.name }</h1>
          <div className="datalineItem__editbutton">
            <Link className="backofficeNewDataLine__link" to={`/admin/datalines/edit/${ dataline.id }`}>
              Edit
            </Link>
          </div>
          { this.state.loadingCount 
            ? <Loading/>
            : <span>{ this.state.numdocs } Files
              { this.state.numdocs > 0 
                ? <span className="datalineItem__deleteData" onClick={ this.showDeleteConfirmation.bind() }>(Clear)</span>
                : <span/> 
              } 
            </span>
          }
        </header>
        <div className="datalineItem__content">
          { this.state.loadingStatus
            ? <Loading/>
            : <div>
              { this.state.datalineRunning 
                ? <div>
                  <button
                    className="connectorCard__buttonRescan"
                    onClick={ this.stopDataLine.bind(this, dataline.id) }>Stop Scan</button>
                  { this.state.datalineRunningInfo === null
                    ? <Loading/>
                    : <RenderedDataLine datalineId={ dataline.id }/>
                  }
                </div>
                : <div>
                  <button
                    className="connectorCard__buttonRescan"
                    onClick={ this.startDataLine.bind(this, dataline.id) }>Start Scan</button>
                </div>
              }
            </div>
          }
          
        </div>
      </div>
    )
  }
}

DataLineItem.propTypes = {
  dataline: PropTypes.object.isRequired
}

export default DataLineItem