import React from 'react'
import { Table, Button } from 'antd'
import moment from 'moment'
import { Link } from 'react-router-dom'
import '../Admin.scss'
import {connect} from "react-redux";
import {
    setAdminModifyClient,
} from "../../../actions/smartBoxActions";
const { Column } = Table

class ClientManagementTable extends React.Component {

  state= {
    pageNumber: 1,
      sorterColumnTenant: '',
      sorterNameColumnTenant: '',
      selectColumnTenant: {}
  }

  cancel = () => {
    this.setState({ editingKey: '' })
  };

  onPageChange = (page, pageSize) => {
    this.cancel()

    this.setState({
      pageNumber: page
    }, () => { this.props.changePage(page) })
  }

  showTotalPages = (total) => {
    const { pageNumber } = this.state
    return (
      <div className="total-elements">
        <span className="pagination__box__header__total">
          <strong>{total}</strong> results
        </span>
        <span className="pagination__box__header__text">
              Page {pageNumber} of {Math.ceil(total / 5)}
        </span>
      </div>
    )
  }
    onSorterColumnsTenant = (e, column, sorter) => {
        const selectCol = {
            column: column,
            sorter: sorter
        }
        this.setState({
            sorterColumnTenant: sorter,
            sorterNameColumnTenant: column,
            selectColumnTenant: selectCol
        })
        this.props.onSorterTenantData(sorter, column)
    }
    onChangeModify = (e, record) => {
        this.props.setAdminModifyClient(record)
    }
  render() {
    const { clients, onResetMfaGlobalClicked } = this.props
      const { selectColumnTenant } = this.state
    return (
      <Table dataSource={clients} className="custom-table new-custom-table"
        pagination={{
          onChange: this.onPageChange,
          pageSize: 10,
          showTotal: this.showTotalPages,
          className: 'annotation-pagination',
          showLessItems: true,
          total: this.props.totalElements
        }}>
        <Column title= { <span className="content-header-column"><span>Company Name</span>
                 <span className="content-buttons-sorter">
                      <span className={ selectColumnTenant && selectColumnTenant.column === 'name' && selectColumnTenant.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                            onClick={(e) => this.onSorterColumnsTenant(e, 'name', 'asc')}></span>
                     <span className={ selectColumnTenant && selectColumnTenant.column === 'name' && selectColumnTenant.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                           onClick={(e) => this.onSorterColumnsTenant(e, 'name', 'desc')}></span>
                 </span>
                </span>}
                dataIndex="name" key="name"/>
        <Column title= { <span className="content-header-column"><span>Status</span>
                 <span className="content-buttons-sorter">
                      <span className={ selectColumnTenant && selectColumnTenant.column === 'status' && selectColumnTenant.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                            onClick={(e) => this.onSorterColumnsTenant(e, 'status', 'asc')}></span>
                     <span className={ selectColumnTenant && selectColumnTenant.column === 'status' && selectColumnTenant.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                           onClick={(e) => this.onSorterColumnsTenant(e, 'status', 'desc')}></span>
                 </span>
                </span>}
                dataIndex="isActive" key="isActive" render={(text) => {
          return (<span>{text ? 'Active' : 'Inactive'}</span>)
        }} />
        <Column title= { <span className="content-header-column"><span>Joined</span>
                 <span className="content-buttons-sorter">
                      <span className={ selectColumnTenant && selectColumnTenant.column === 'joined' && selectColumnTenant.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                            onClick={(e) => this.onSorterColumnsTenant(e, 'joined', 'asc')}></span>
                     <span className={ selectColumnTenant && selectColumnTenant.column === 'joined' && selectColumnTenant.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                           onClick={(e) => this.onSorterColumnsTenant(e, 'joined', 'desc')}></span>
                 </span>
                </span>}
                dataIndex="joined" key="joined" render={(text) => {
          return (<span>{moment(text).format('DD/MM/YYYY')}</span>)
        }}/>
        <Column title= { <span className="content-header-column"><span>Domain</span>
                 <span className="content-buttons-sorter">
                      <span className={ selectColumnTenant && selectColumnTenant.column === 'domain' && selectColumnTenant.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                            onClick={(e) => this.onSorterColumnsTenant(e, 'domain', 'asc')}></span>
                     <span className={ selectColumnTenant && selectColumnTenant.column === 'domain' && selectColumnTenant.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                           onClick={(e) => this.onSorterColumnsTenant(e, 'domain', 'desc')}></span>
                 </span>
                </span>}
                dataIndex="domain" key="domain"/>
        <Column title= { <span className="content-header-column"><span>Subscription</span>
                 <span className="content-buttons-sorter">
                      <span className={ selectColumnTenant && selectColumnTenant.column === 'subscription' && selectColumnTenant.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                            onClick={(e) => this.onSorterColumnsTenant(e, 'subscription', 'asc')}></span>
                     <span className={ selectColumnTenant && selectColumnTenant.column === 'subscription' && selectColumnTenant.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                           onClick={(e) => this.onSorterColumnsTenant(e, 'subscription', 'desc')}></span>
                 </span>
                </span>}
                dataIndex="planName" key="planName" align="center"/>
        <Column title= { <span className="content-header-column"><span>Sar Visible</span>
                 <span className="content-buttons-sorter">
                      <span className={ selectColumnTenant && selectColumnTenant.column === 'sar' && selectColumnTenant.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                            onClick={(e) => this.onSorterColumnsTenant(e, 'sar', 'asc')}></span>
                     <span className={ selectColumnTenant && selectColumnTenant.column === 'sar' && selectColumnTenant.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                           onClick={(e) => this.onSorterColumnsTenant(e, 'sar', 'desc')}></span>
                 </span>
                </span>}
                dataIndex="sarVisible" key="sarVisible" render={(text) => {
          return (<span>{text ? 'ACTIVE' : 'INACTIVE'}</span>)
        }}/>
        <Column title= { <span className="content-header-column"><span>MFA</span>
                 <span className="content-buttons-sorter">
                      <span className={ selectColumnTenant && selectColumnTenant.column === 'mfa' && selectColumnTenant.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                            onClick={(e) => this.onSorterColumnsTenant(e, 'mfa', 'asc')}></span>
                     <span className={ selectColumnTenant && selectColumnTenant.column === 'mfa' && selectColumnTenant.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                           onClick={(e) => this.onSorterColumnsTenant(e, 'mfa', 'desc')}></span>
                 </span>
                </span>}
                dataIndex="multi_factor_authentication" key="multi_factor_authentication" render={(text) => {
          return (<span>{ text ? 'Yes' : 'No' }</span>)
        }}/>
        <Column
          title="Global reset"
          key="reset"
          align="center"
          render={(text, record) => (
            <span>
              <Button className="button-green button-blue-reset" onClick={ (e) => onResetMfaGlobalClicked(e, record.key) } > Reset MFA </Button>
            </span>
          )}
        />
        <Column
          title="Action"
          key="action"
          align="center"
          render={(text, record) => (
            <span onClick={(e) => this.onChangeModify(e, record)}>
              <Link to={`/admin/client-data/${ record.key }`} className="button-green" > Modify </Link>
            </span>
          )}
        />
      </Table>
    )
  }
}

//export default ClientManagementTable

const mapStateToProps = (state) => ({
})
export default connect(mapStateToProps, { setAdminModifyClient })(ClientManagementTable)