import React from 'react'

import './Page404.scss'

import imgImperial from '../../../assets/404-imperial.png'
import imgWalker from '../../../assets/404-walker.png'

const Page404 = () => (
  <div className="page404__wrapper">
    <div>
      <img className="page404__imgWalker" src={ imgWalker } alt="Star wars walker" />
    </div>
    <div className="page404__wrapper">
      <img src={ imgImperial } alt="Imperial 404" />
      <h2 className="page404__title">"We will crush the Rebel bugs"</h2>
      <p className="page404__text">Please check URL address again or help us by <a href="mailto:support@gravicus.com" className="page404__link">reporting an issue</a></p>
    </div>
  </div>
)

export default Page404