import React from 'react'
import PropTypes from 'prop-types'

import {
  apiPrivacyGetTopics,
  apiPrivacyGetLocationsForTopic
} from '../api/Privacy'

import * as utils from '../utils/Topics'
import { updateAnItemOnCollection } from '../utils'

const TopicsContext = React.createContext()

class TopicsProvider extends React.Component {
  state = {
    allTopics: [],
    isFetchingTopics: true,
    letters: {},
    topics: []
  }

  getTopics = () => {
    if (this.state.topics.length > 0) return

    apiPrivacyGetTopics()
      .then((response) => {
        const topics = utils.mappedTopicsWithInitialInformation(response)

        this.setState({
          allTopics: topics,
          isFetchingTopics: false,
          letters: utils.generateLetterList(topics),
          topics,
          totalTopics: topics.length
        })
      })
  }
  
  getLocationsForTopic = (id, page) => {
    apiPrivacyGetLocationsForTopic(id, page)
      .then((response) => {
        if (response.data.status === 'Success') {
          const newTopics = utils.putFilesOnTopic(response, this.state.allTopics, id)
          this.setState({ allTopics: newTopics })
        }
      })
  }

  updateTopic = (id, informationToUpdate) => {
    const newTopics = updateAnItemOnCollection(this.state.allTopics, id, informationToUpdate)
    this.setState({ allTopics: newTopics })
  }

  filterTopicsByLetter = (letter) => {
    if (letter === '') {
      return this.setState({ topics: this.state.allTopics })
    }

    const letterRE = new RegExp(letter, 'i')
    this.setState({
      topics: this.state.allTopics.filter((topic) => topic.title[0].match(letterRE))
    })
  }

  filterTopicsByTerm = (term) => {
    if (term === '') {
      return this.setState({ topics: this.state.allTopics })
    }

    const termRE = new RegExp(term, 'i')
    this.setState({
      topics: this.state.allTopics.filter((topic) => topic.title.match(termRE))
    })
  }

  render = () => {
    return (
      <TopicsContext.Provider value={{
        allTopics: this.state.allTopics,
        filterTopicsByLetter: this.filterTopicsByLetter,
        filterTopicsByTerm: this.filterTopicsByTerm,
        getLocationsForTopic: this.getLocationsForTopic,
        getTopics: this.getTopics,
        isFetchingTopics: this.state.isFetchingTopics,
        letters: this.state.letters,
        topics: this.state.topics,
        totalPagesForTopics: Math.ceil(this.state.topics.length / 12),
        totalTopics: this.state.allTopics.length,
        updateTopic: this.updateTopic
      }}>
        { this.props.children }
      </TopicsContext.Provider>
    )
  }
}

TopicsProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export default TopicsProvider
export const TopicsConsumer = TopicsContext.Consumer