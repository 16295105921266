import { defineDisplaySection } from '../Helpers/OptionsUtils'
import { divider, iDontKnow, noneOfTheAbove, normal, otherTextInput } from '../Helpers/OptionTemplates'

const newOptionFinder = (question, subType) => {
  const order = question.options.length
  
  switch (subType) {
    case 'NONE_OF_THE_ABOVE':
      return noneOfTheAbove(order)
    case 'I_DONT_KNOW':
      return iDontKnow(order)
    case 'OTHER':
      return otherTextInput(order)
    case 'DIVIDER':
      return divider(order)
    default:
      return normal(order)
  }
}

export default ({ question, subType }) => {
  const newOption = newOptionFinder(question, subType)

  let options = [
    ...question.options,
    newOption
  ]

  options = defineDisplaySection(options)

  const newQuestion = {
    ...question,
    options
  }
  return newQuestion
}