import React from 'react'

class ClientSubscriptionManagement extends React.Component {
  render() {
    return (
      <div>
          Client Subscription Section
      </div>
    )
  }
}

export default ClientSubscriptionManagement