import React from 'react'

const HistoryNoteTableView = () => {
  return (
    <div className="history-table-view">
      <span>Version</span>
      <span>Created By</span>
      <span>Modified by</span>
      <span>Modified on</span>
      <span>Note</span>
    </div>
  )
}

export default HistoryNoteTableView