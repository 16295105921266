import React from 'react'
import { connect } from 'react-redux'
import AddEditForm from './AddEditForm'
import CustomNotificationManager from '../../Common/CustomNotificationManager'
import { sendUniqueLinkForSuperAdmin, sendUniqueLinkForGravicusAdmin } from '../../../api/Admin'
import { apiViewPlan } from '../../../api/Plan'
import './ClientManagement.scss'
import Loading from '../../Common/Loading'
import { Spin } from 'antd'

class ClientNewForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loadingSuper: false,
      loadingGravicus: false,
      modalVisible: false,
      emailAddress: '',
      homeApplication: '',
      saName: '',
      saEmail: '',
      saStatus: '',
      aName: '',
      aEmail: '',
      aStatus: '',
      errors: {},
      newClientId: '',
      newClientDomain: ''
    }
  }

  componentDidMount() {
    apiViewPlan().then((res) => {
      this.setState({
        plans: res.data
      })
    })
  }

  sendUniqueLink = (e, type) => {
    const { aName, aEmail, saName, saEmail, newClientId, newClientDomain } = this.state
    const response = this.validateExtraField(type)
    if (response) {
      if (type === 'superAdmin') {
        const data = { name: saName, email: saEmail, clientDomain: newClientDomain }
        this.setState({ loadingSuper: true }, () => {
          sendUniqueLinkForSuperAdmin(data).then((response) => {
            const temp = { saName: '', saEmail: '' }
            if (response.status === 200) {
              CustomNotificationManager.success('Unique link was sent successfully', 'Success')
              this.setState({ saStatus: 'Sent', errors: temp, loadingSuper: false })
            }
          }).catch((error) => {
            if (error?.response?.data) {
              this.setState({ loadingSuper: false }, () => {
                CustomNotificationManager.error(error.response.data.error, 'Error')
              })
            }else{
              console.log(error)
            }
          })
        })
      } else {
        this.setState({ loadingGravicus: true }, () => {
          const data = { name: aName, email: aEmail, clientId: newClientId }
          sendUniqueLinkForGravicusAdmin(data).then((response) => {
            const temp = { aName: '', aEmail: '' }
            if (response.status === 200) {
              CustomNotificationManager.success('Unique link was sent successfully', 'Success')
              this.setState({ aStatus: 'Sent', errors: temp, loadingGravicus: false })
            }
          }).catch((error) => {
            if (error?.response?.data) {
              this.setState({ loadingGravicus: false }, () => {
                CustomNotificationManager.error(error.response.data.error, 'Error')
              })
            }else{
              console.log(error)
            }
          })
        })
      }
    }
  }

  validateExtraField = (type) => {
    const { aName, aEmail, saName, saEmail } = this.state

    if (type === 'superAdmin') {
      const response = { success: false, errors: {} }
      if (!saName) {
        response.errors.saName = 'This field is required'
        response.errors.saEmail = ''
      } else if (!saEmail || !this.validateEmail(saEmail)) {
        response.errors.saName = ''
        response.errors.saEmail = 'Invalid email'
      } else {
        response.success= true
        response.errors.saName = ''
        response.errors.saEmail = ''
      }
      this.setState({ errors: response.errors })
      return response.success
    } else if (type === 'gravicusAdmin') {
      const response = { success: false, errors: {} }
      if (!aName) {
        response.errors.aName = 'This field is required'
        response.errors.aEmail = ''
      } else if (!aEmail || !this.validateEmail(aEmail)) {
        response.errors.aName = ''
        response.errors.aEmail = 'Invalid email'
      } else if (aEmail.toLowerCase().replace(/.*@/, '') !== 'gravicus.com') {
        response.errors.aName = ''
        response.errors.aEmail = 'Invalid email domain. The domain should be "gravicus.com"'
      } else {
        response.success= true
        response.errors.aName = ''
        response.errors.aEmail = ''
      }
      this.setState({ errors: response.errors })
      return response.success
    }
  }

  validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  changeInput = (e, field, type) => {
    this.setState({ [field]: e.target.value }, () => {
      this.validateExtraField(type)
    })
  }

  setNewClientData = (newClient) => {
    this.setState({ newClientId: newClient.key, newClientDomain: newClient.domain })
  }

  render() {
    const { plans, applications, aStatus, saStatus, errors, newClientId, newClientDomain, loadingSuper, loadingGravicus } = this.state
    return (
      <div className="tracker-page create-client-page">
        <div className="tracker-box">
          <AddEditForm action="add" plans={plans} applications={applications} setNewClientData={this.setNewClientData} />
          { newClientDomain &&
            <div className="tracker-form">
              <header className="tracker-header">
                <div className="tracker-header__top">
                  <h1 className="tracker-title">DEFINE CLIENT SUPER ADMIN </h1>
                </div>
              </header>
              <div className="tracker-content">
                <div className="tracker_formGroup full_width">
                  <label htmlFor="saName">Name:</label>
                  <input type="text" name="saName" onChange={ (e) => this.changeInput(e, 'saName', 'superAdmin') }/>
                  <div className="error_container">
                    <span className="error_item">{errors.saName}</span>
                  </div>
                </div>
                <div className="tracker_formGroup full_width">
                  <label htmlFor="saEmail">Email Address:</label>
                  <input type="email" name="saEmail" onChange={ (e) => this.changeInput(e, 'saEmail', 'superAdmin') }/>
                  <div className="error_container">
                    <span className="error_item">{errors.saEmail}</span>
                  </div>
                </div>
              </div>
              <div className="tracker-footer">
                <div className="btnAndStatus">
                  { loadingSuper ?
                    <Spin className="float-right" spinning={ loadingSuper } indicator={ <div className="checkout-loading"><Loading/></div> }/> :
                    <React.Fragment>
                      <button
                        type="submit"
                        className="btn-blue-light float-right"
                        disabled={saStatus === 'Sent' ? true: false}
                        onClick={(e) => this.sendUniqueLink(e, 'superAdmin')}
                      >
                        Send Unique Link
                      </button>
                      { saStatus &&
                        <p className="btnAndStatus__status">Status { saStatus }</p>
                      }
                    </React.Fragment>
                  }
                </div>
              </div>
            </div>
          }
          { newClientId &&
            <div className="tracker-form">
              <header className="tracker-header">
                <div className="tracker-header__top">
                  <h1 className="tracker-title">GRAVICUS ADMIN </h1>
                </div>
              </header>
              <div className="tracker-content">
                <div className="tracker_formGroup full_width">
                  <label htmlFor="aName">Name:</label>
                  <input type="text" name="aName" onChange={ (e) => this.changeInput(e, 'aName', 'gravicusAdmin') }/>
                  <div className="error_container">
                    <span className="error_item">{ errors.aName }</span>
                  </div>
                </div>
                <div className="tracker_formGroup full_width">
                  <label htmlFor="aEmail">Email Address:</label>
                  <input type="email" name="aEmail" onChange={ (e) => this.changeInput(e, 'aEmail', 'gravicusAdmin') }/>
                  <div className="error_container">
                    <span className="error_item">{ errors.aEmail }</span>
                  </div>
                </div>
              </div>
              <div className="tracker-footer">
                <div className="btnAndStatus">
                  { loadingGravicus ?
                    <Spin className="float-right" spinning={ loadingGravicus } indicator={ <div className="checkout-loading"><Loading/></div> }/> :
                    <React.Fragment>
                      <button
                        type="submit"
                        className="btn-blue-light float-right"
                        disabled={ aStatus === 'Sent' ? true : false }
                        onClick={ (e) => this.sendUniqueLink(e, 'gravicusAdmin') }
                      >
                        Send Unique Link
                      </button>
                      { aStatus &&
                        <p className="btnAndStatus__status">Status { aStatus }</p>
                      }
                    </React.Fragment>
                  }
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}

const stateMap = (state) => ({
  client: state.subscriptionReducer.subscription.subscription.client,
  user: state.userReducer.user
})

export default connect(stateMap)(ClientNewForm)