import React from 'react'
import PropTypes from 'prop-types'

import {
  apiOspreyGetDataModelTaxonomies
} from '../../api/OspreyAnalytics.js'

const WorkspacesContext = React.createContext()

class WorkspacesProvider extends React.Component {
  state = {
    taxonomies: [],
    isFetchingTaxonomies: true,
  }

  getTaxonomies = () => {
    apiOspreyGetDataModelTaxonomies('documents', [100, 101, 102, 103])
      .then((response) => {
        if (response.data.value.taxonomies.field)
        {
          if (response.data.value.taxonomies.field instanceof Array)
          {
            const tax = response.data.value.taxonomies.field

            this.setState({
              taxonomies: tax,
              isFetchingTaxonomies: false
            })  
          }
          else
          {
            const tax = [response.data.value.taxonomies.field]

            this.setState({
              taxonomies: tax,
              isFetchingTaxonomies: false
            })  
          }
        }
      })
      .catch((error) => {
        console.log(error, 'error')
      })
  }

  render = () => {
    return (
      <WorkspacesContext.Provider value={{
        taxonomies: this.state.taxonomies,
        getTaxonomies: this.getTaxonomies
      }}>
        { this.props.children }
      </WorkspacesContext.Provider>
    )
  }
}

WorkspacesProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export default WorkspacesProvider
export const WorkspacesConsumer = WorkspacesContext.Consumer