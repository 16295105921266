import React from 'react'
import { Link } from 'react-router-dom'

import Button from '../Common/Button'

import imgGraphDataInsight from '../../assets/graph-data-insight.png'

import './BoxAddNewDataset.css'

const BoxAddNewDataset = () => (
  <div className="boxAddNewDataset__wrapper">
    <div className="boxAddNewDataset__flex">
      <img src={ imgGraphDataInsight } alt="Data insight" />
      <div className="boxAddNewDataset__content">
        <h1 className="boxAddNewDataset__title">Want more insight into your data?</h1>
        <p className="boxAddNewDataset__text">Connect more data sets to find out where your sensitive and insecure data might be hiding!</p>
        <div className="hideMobile">
          <Link to='/privacy/connectors'>
            <Button>Add new dataset</Button>
          </Link>
        </div>
      </div>
    </div>
    <div className="showMobile">
      <Button>Add new dataset</Button>
    </div>
  </div>
)

export default BoxAddNewDataset