import React from 'react'
import { apiUpdateInitialStepAcceptEmailTemplate, apiUpdateDefaultTemplate } from '../../../../../../api/Sar'
import { connect } from 'react-redux'
import ReactQuill from 'react-quill'
import swal from 'sweetalert2'
import CustomNotificationManager from '../../../../../Common/CustomNotificationManager'
import EmailVariables from '../../Components/EmailVariables'

class InitialStepOptionsTemplate extends React.Component {
  modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      [{ size: [] }],
      [{ align: [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    }
  }

  state = {
    emailTemplate: {
      ...this.props.emailTemplate,
      sentFromEmail: this.props.emailTemplate.sentFromEmail ? this.props.emailTemplate.sentFromEmail : this.props.user.email,
      sentFromName: this.props.emailTemplate.sentFromName ? this.props.emailTemplate.sentFromName : this.props.user.name,
    },
    isNameLinkPersist: false,
    isResultUrlPersist: false,
    valid: false,
    editMode: false,
    theme: 'snow'
  }

  componentDidMount() {
    this.validateBody(this.props.emailTemplate.content)
   this.setState({
     valid: false
   })
  }

  defaultTemplateClicked(e) {
    e.preventDefault()
    swal.fire({
      text: 'Are you sure you want to reset the template to default?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3DD8DB',
      cancelButtonColor: '#D33D33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        this.handleSendDefaultTemplate()
      } else {
        CustomNotificationManager.info('Action cancelled', 'Aborted')
      }
    })
  }

  handleSendDefaultTemplate = () => {

    apiUpdateDefaultTemplate(this.state.emailTemplate.id).then(
      (res) => {
        this.props.updateEmailTemplate(res.data)
        CustomNotificationManager.success('Switched to default template.', 'Success')
        this.setState({
          emailTemplate: {
            ...this.props.emailTemplate,
            sentFromEmail: this.props.emailTemplate.sentFromEmail ? this.props.emailTemplate.sentFromEmail : this.props.user.email,
            sentFromName: this.props.emailTemplate.sentFromName ? this.props.emailTemplate.sentFromName : this.props.user.name,
          },
        })
      }
    )
  }

  validateBody(data) {
    this.checkDetailsVariable(data)
    return (data.toString().match(new RegExp(/\${name}/, 'g')) || []).length === 1 && (data.toString().match(new RegExp(/\${resultUrl}/, 'g')) || []).length >= 1
  }

  checkDetailsVariable(data) {
    if (data.includes('{name}')) {
      this.setState({
        isNameLinkPersist: true
      })
    } else {
      this.setState({
        isNameLinkPersist: false
      })
    }
  }

  validateName(data) {
    this.checkNameVariable(data)
    return /(?=.*\${name})/.test(data)
  }

  checkNameVariable(data) {
    if (data.includes('{name}')) {
      this.setState({
        isNameLinkPersist: true
      })
    } else {
      this.setState({
        isNameLinkPersist: false
      })
    }
  }
  handleEditorChange = (e) => {
    this.setState({
      editMode: true,
      valid: this.validateName(e),
    }, () => this.props.isEditMode(this.state.editMode))

  }

  handleChange = (e, key) => {
    this.setState({
      editMode: true
    }, () => this.props.isEditMode(this.state.editMode))

    switch (key) {
      case 'subject':
        this.setState({
          emailTemplate: { ...this.state.emailTemplate, subject: e.target.value },
          valid: this.validateBody(this.state.emailTemplate.content),
        })

        break
      case 'from-email':
        this.setState({
          emailTemplate: { ...this.state.emailTemplate, sentFromEmail: e.target.value },
          valid: this.validateBody(this.state.emailTemplate.content),
        })
        break
      case 'from-name':
        this.setState({
          emailTemplate: { ...this.state.emailTemplate, sentFromName: e.target.value },
          valid: this.validateBody(this.state.emailTemplate.content),
        })
        break
      default:
        break
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()

    this.setState({
      emailTemplate: {
        ...this.state.emailTemplate,
        defaultTemplate: !!this.props.godAdmin,
        clientId: this.props.clientId
      }
    }, () => {
      apiUpdateInitialStepAcceptEmailTemplate(this.state.emailTemplate, this.props.godAdmin).then(
        (res) => {
          this.setState({
            editMode: false,
            valid: false
          }, () => this.props.updateEmailTemplate(res.data))
        }
      )
    })
  }

  discardChanges = (e) => {
    e.preventDefault()
    this.setState({
      editMode: false,
      valid: false,
      emailTemplate: {
        ...this.props.emailTemplate,
        sentFromEmail: this.props.emailTemplate.sentFromEmail ? this.props.emailTemplate.sentFromEmail : this.props.user.email,
        sentFromName: this.props.emailTemplate.sentFromName ? this.props.emailTemplate.sentFromName : this.props.user.name,
      },
    }, () => this.props.isEditMode(this.state.editMode))
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      emailTemplate: nextProps.emailTemplate
    }, () => this.validateBody(nextProps.emailTemplate.content))
  }

  checkIfTemplateVariablesIncludeOne = (value) => {
    const { emailTemplate } = this.state
    let response = false
    if (emailTemplate.variables) {
      emailTemplate.variables.forEach((item) => {
        if (item.includes(value)) {
          response = true
        }
      })
    }
    return response
  }

  checkIfTemplateVariablesIncludeOne = (value) => {
    const { emailTemplate } = this.state
    let response = false
    if (emailTemplate.variables) {
      emailTemplate.variables.forEach((item) => {
        if (item.includes(value)) {
          response = true
        }
      })
    }
    return response
  }

  render() {
    const { emailTemplate, isNameLinkPersist, isResultUrlPersist, valid, editMode } = this.state
    const { godAdmin } = this.props
    return (
      <div className="email-template">
        { emailTemplate &&
        <form className="form-content col-md-10" onSubmit={ this.handleSubmit }>
          <div className="form-group">
            <label className="col-md-2">Subject</label>
            <input type="text" placeholder="Subject" className="col-md-10"
                   onChange={ (e) => this.handleChange(e, 'subject') }
                   value={ emailTemplate.subject || '' } required/>
          </div>
          { !godAdmin ?
            <React.Fragment>
              <div className="form-group">
                <label className="col-md-2">From email</label>
                <input type="email" placeholder="From Email" className="col-md-10"
                       onChange={ (e) => this.handleChange(e, 'from-email') }
                       value={ emailTemplate.sentFromEmail || '' } required={ !godAdmin }/>
              </div>
              <div className="form-group">
                <label className="col-md-2">From name</label>
                <input type="text" placeholder="From Name" className="col-md-10"
                       onChange={ (e) => this.handleChange(e, 'from-name') }
                       value={ emailTemplate.sentFromName || '' } required={ !godAdmin }/>
              </div>
            </React.Fragment>
            : '' }
          <ReactQuill
            theme={ this.state.theme }
            onChange={ (value) => {
              this.setState({
                emailTemplate: {
                  ...this.state.emailTemplate,
                  content: value
                }
              }, () => this.handleEditorChange(value))
            }
            }
            value={ emailTemplate.content }
            modules={ this.modules }
          />
          <div className="form-buttons">
            <button className="discard" onClick={ (e) => this.discardChanges(e) } disabled={ !editMode }>Discard
              Changes
            </button>
            { !godAdmin ? <button className="button-green" onClick={ (e) => this.defaultTemplateClicked(e) }
                                  disabled={ emailTemplate.defaultTemplate }>Default template</button> : '' }
            <button className="button-green accept" disabled={ !valid }>Modify</button>
          </div>
        </form>
        }
        <EmailVariables
          variabels= { ['name', 'companyName'] }
          isNameLinkPersist= { isNameLinkPersist }
          isResultUrlPersist= { isResultUrlPersist }
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id,
  user: state.userReducer.user,
  godAdmin: state.admin.godAdminMode
})
export default connect(mapStateToProps, {})(InitialStepOptionsTemplate)