import React from 'react'
import PropTypes from 'prop-types'

import CollectionsList from '../Form/CollectionsList'

import './Filters.css'

class Filters extends React.Component {
  filterByTerm = (event) => {
    event.persist()
    this.props.filterByTerm(event)
  }

  render = () => {
    return (
      <div>
        <div className="filterAlert__wrapper">
          <div className="filterAlertItem-button">
            <label className="filterAlertItem-labelCentered">Expand</label>
            <div className="filterAlertItem-buttonWrapper" onClick={ this.props.expandAll }>
              <span className="iconExpand"></span>
            </div>
          </div>

          <div className="filterAlertItem-button">
            <label className="filterAlertItem-labelCentered">Find</label>
            <input
              className="filterAlertItem-inputFind"
              type='text'
              onChange={ this.filterByTerm } />
          </div>

          <div className="filterAlertItem-select">
            <label>Status level</label>
            <select onChange={ this.props.filterBy.bind(this, 'selectedStatusLevel') }>
              <option value="">choose...</option>
              <option value="low">Low</option>
              <option value="medium">Medium</option>
              <option value="high">High</option>
            </select>
          </div>

          <div className="filterAlertItem-select">
            <label>Collections</label>
            <CollectionsList
              value={ this.props.selectedCollection }
              onChange={ this.props.filterBy.bind(this, 'selectedCollection') } />
          </div>
        </div>
      </div>
    )
  }
}

Filters.propTypes = {
  expandAll: PropTypes.func.isRequired,
  filterBy: PropTypes.func.isRequired,
  filterByTerm: PropTypes.func.isRequired
}

export default Filters