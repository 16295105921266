import React from 'react'
import PropTypes from 'prop-types'
import './UploadProgressWindow.scss'
import { Progress, Tooltip } from 'antd'
import { objectWithChildArraysToFormData } from '../../../utils/FormDataBuilder'
import WebWorker from './workers/WebWorker'
import worker from './workers/file-names.worker'
import CustomNotificationManager from '../../Common/CustomNotificationManager'
import { getFilesBeforeUploadDTO } from '../../../api/SmartBox'
import {connect} from "react-redux";
import {
  setProgressEventUpload,
  setSmartBoxGenerateUniqueId,
  setSmartBoxShowUploadNavigation
} from "../../../actions/smartBoxActions";

class FileUploadProgress extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showUploadProgress: false,
      progressStatus: [],
      avatarUploadPercentage: 0,
      isActive: true,
      status: 'active',
      fileNamesWithErrors: [],
      messageErrorZip: 'You have already uploaded a zip with the same name!',
      newFiles: []
    }
  }
  
  componentDidMount() {
    //this.props.setSmartBoxShowUploadNavigation(false)
    this.setState({
      progressStatus: [],
      avatarUploadPercentage: 0,
      isActive: true,
      status: 'active'
    })
    const { files, path } = this.props
    this.handleUpload(files, path)
    
    this.worker = new WebWorker(worker)
    
    this.worker.addEventListener('message', (e) => {
      this.setState({
        fileNamesWithErrors: e.data
      })
    })
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.smartBoxShowUploadNavigation !== this.props.smartBoxShowUploadNavigation && this.props.smartBoxShowUploadNavigation === false) {
      this.setState({
        showUploadProgress: false
      })
      //this.props.setSmartBoxShowUploadNavigation(false)
    }
  }

  handleUpload(fileList, path) {
    const fileListBeforeUpload = []
    fileList && fileList.length > 0 && fileList.forEach((item) => {
      fileListBeforeUpload.push(item.name)
    })
    const checkFilesBeforeUploadDTO = {
      path: path,
      fileNames: fileListBeforeUpload
    }
    const listFilesBefore = []
    if (fileList && fileList.length > 0) {
      getFilesBeforeUploadDTO(checkFilesBeforeUploadDTO).then((response) => {
        let newListFilesBefore = []
        response.data && Object.keys(response.data).forEach((key) => {
          listFilesBefore.push({
            name: key,
            messageInfo: response.data[key]
          })
        })
        if (listFilesBefore && listFilesBefore.length > 0) {
          CustomNotificationManager.info(`Action cancelled: ${ listFilesBefore && listFilesBefore.length > 0 && listFilesBefore.map((item) => item.messageInfo) }`, 'Aborted')
        }
        newListFilesBefore = fileList && fileList.length > 0 && fileList.filter((file) => !listFilesBefore.map((item) => item.name).includes(file.name))
        this.setState({
          newFiles: newListFilesBefore,
        })
        if (newListFilesBefore && newListFilesBefore.length > 0) {
          let fileInfoList1 = []
          newListFilesBefore.forEach((item) => {
            fileInfoList1.push({ fileName: item.name, creationDate: item.lastModified, modifiedDate: item.lastModified })
          })
          const uniqueId = Math.floor(Math.random() * 1000)
          const data = {
            path,
            files: newListFilesBefore,
            fileInfoList: JSON.stringify(fileInfoList1),
            uploadId: uniqueId ? uniqueId : 123
          }
          const generateListUnique = [{
            uniqueId: uniqueId,
            pathUpload: path,
            filesUpload: fileList
          }]
          //let data = {}
          //this.props.setSmartBoxGenerateUniqueId(generateListUnique)
          //this.props.setSmartBoxShowUploadNavigation(false)
          const config = (progressEvent) => {
            //this.props.onProgressEventEstimated(progressEvent)
            //this.props.setProgressEventUpload(progressEvent.estimated)
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            percentCompleted = percentCompleted > 1 ? percentCompleted - 1 : percentCompleted
            this.setState({
              avatarUploadPercentage: percentCompleted,
            })
          }
          this.setState({
            files: fileList,
            showUploadProgress: true
          }, () => {
            this.props.upload(objectWithChildArraysToFormData(data), config, data)
            //this.props.upload(data, config)
              .then((res) => {
                //this.props.setSmartBoxShowUploadNavigation(true)
                this.props.setFetchingCount()
                this.setState({
                  isActive: false
                })
                this.worker.postMessage({
                  fileNames: this.props.fileNames,
                  fileNamesWithErrors: res.data
                })
                this.props.percentCompletedStatus(this.state.avatarUploadPercentage)
              }).catch((response) => {
                this.props.setFetchingCount()
                this.setState({
                  status: 'exception',
                  isActive: true
                })
              })
          })
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    }
  }
  render() {
    const { avatarUploadPercentage, isActive, status, fileNamesWithErrors, messageErrorZip, newFiles } = this.state
    const { files, showUploadProgress, responseErrorZip } = this.props
    const newFilesName = []
    newFiles && newFiles.length > 0 && newFiles.forEach((item) => {
      newFilesName.push(item.name)
    })
    // if (responseErrorZip) {
    //   files && files.map((file) => {
    //     if (file.type === 'application/zip') {
    //       CustomNotificationManager.error(messageErrorZip, 'Error')
    //     }
    //     return true
    //   })
    // }
    return (showUploadProgress && files && files.length > 0 &&
      <div className="progress-upload-files__content">
        <div className="file-names">
          { fileNamesWithErrors && fileNamesWithErrors.length > 0 ?
            fileNamesWithErrors.map((fileObject, key) => {
              if (fileObject.errorMessage) {
                return (
                  <Tooltip title={ fileObject.errorMessage } key={key}>
                    <p key={ fileObject.key }
                      style={ { color: fileObject.color } }>
                      { fileObject.name }
                      { key !== fileNamesWithErrors.length - 1 ? ',' : '' }
                    </p>
                  </Tooltip>
                )
              }
              return <p key={ fileObject.key }
                style={ { color: fileObject.color } }>
                { fileObject.name }
                { key !== fileNamesWithErrors.length - 1 ? ',' : '' }
              </p>
            })
            :
            newFilesName && newFilesName.length > 0 && newFilesName.map((el, i) => {
              return <p key={ i }>{ el },</p>
            }) }
        </div>
        {
          isActive ? <Progress key={ files[0].name } percent={ avatarUploadPercentage } size="small"
            status={ status }/> :
            <Progress key={ files[0].name } percent={ 100 } size="small"/>
        }
      </div>
    )
  }
}

FileUploadProgress.propTypes = {
  setFetchingCount: PropTypes.func.isRequired
}
const mapStateToProps = (state) => ({
})
export default connect(mapStateToProps, { setSmartBoxGenerateUniqueId, setSmartBoxShowUploadNavigation, setProgressEventUpload })(FileUploadProgress)