import React from 'react'
import { Tooltip, Icon, Checkbox, Row, Col } from 'antd'

function EmailWorkVariables({ variabels, isNameLinkPersist, isMessagePersist= false, isResultUrlPersist= false, onChangeVariables, emailTemplate, checkedVariables, listVariables, variablesTemplate, onSelectVariable,
                                onDisabledSelectVariable }) {
    // React.useEffect(() => {
    // }, [isNameLinkPersist, isMessagePersist, isMessagePersist, isResultUrlPersist ])
    const defaultChecked = []
    emailTemplate.variables && emailTemplate.variables.length > 0 && emailTemplate.variables.forEach((item) => {
        defaultChecked.push(item)
    })
    const checkedVariablesUpdate = checkedVariables.concat(defaultChecked)
    // console.log('listVariables ----- varibales', listVariables)
    // console.log('emailTemplate ----- varibales', emailTemplate)
    // console.log('variablesTemplate ----- varibales', variablesTemplate)
    const listMustContain = []
    const listAvailableVariables = []
    const listVariablesAll = []

    variablesTemplate && variablesTemplate.length > 0 && variablesTemplate.forEach((elem) => {
        if (elem.variableEmail) {
            listVariablesAll.push(elem.variableEmail.toLowerCase())
        } else if (elem.nameVariables) {
            listVariablesAll.push(elem.nameVariables.toLowerCase())
        }
    })

    listVariables && listVariables.length > 0 && listVariables.forEach((elem) => {
        if (elem.required) {
            elem.variable = elem.variable.toLowerCase()
            listMustContain.push(elem)
        } else {
            elem.variable = elem.variable.toLowerCase()
            listAvailableVariables.push(elem)
        }
    })

    listMustContain && listMustContain.length > 0 && listMustContain.forEach((must) => {
        if (listVariablesAll.includes(must.variable)) {
            must.existVariable = true
        } else {
            must.existVariable = false
        }
    })
    listAvailableVariables && listAvailableVariables.length > 0 && listAvailableVariables.forEach((available) => {
        if (listVariablesAll.includes(available.variable)) {
            available.existVariable = true
        } else {
            available.existVariable = false
        }
    })
     //console.log('listMustContain ----- varibales', listMustContain)
     //console.log('listAvailableVariables ----- varibales', listAvailableVariables)
    return (
        <fieldset className="email-legend email-legend-update">
            <div className="content-variables-workflow">
                <div className="content-must-variables">
                    <div className="content-must">
                    <legend className="legend-variable">Must contain:</legend>
                    <div className="email-variables-used">
                        <Tooltip placement="bottom" title={
                            <span>Email variables, are pieces of text in email templates that are automatically filled with the corresponding data about email recipients when they receive your message.
                          The most common variables include the first name of the recipient or the name of the company.
                          You can include it at {'${First Name}'}.</span>}>
                            <span className="icon-v14-question"></span>
                        </Tooltip>
                    </div>
                    </div>
                    { listMustContain && listMustContain.length > 0 && listMustContain.map((variable) => {
                        return (
                            <div className="content-variables">
                                { variable.existVariable ?
                                    <i className="fa fa-check" style={{ color: 'green', marginRight:3 }}/> :
                                    <i className="fa fa-times" style={{ color: 'red', marginRight:3}}/>

                                }
                                <span onClick={variable.existVariable ?
                                    (e) => onDisabledSelectVariable(e, variable) :
                                    (e) => onSelectVariable(e, variable)
                                }
                                      style={variable.existVariable ? { color: '#D4D7DA' } : {color: 'red'}} className="name-variable">{variable.variable.replaceAll('#{', '${')}</span>
                            </div>
                        )
                    })

                    }
                </div>
                <div className="content-must-variables">
                    <legend className="legend-variable">Available variables:</legend>
                    { listAvailableVariables && listAvailableVariables.length > 0 && listAvailableVariables.map((variable) => {
                        return (
                            <div className="content-variables">
                                <span onClick={variable.existVariable ?
                                    (e) => onDisabledSelectVariable(e, variable) :
                                    (e) => onSelectVariable(e, variable)}
                                      style={variable.existVariable ? { color: '#D4D7DA' } : {}} className="name-variable">{variable.variable.replaceAll('#{', '${')}</span>
                            </div>
                        )
                    })

                    }
                </div>
            </div>
            {/*<legend>Email Variables</legend>*/}
            {/*<div>*/}
            {/*    <Checkbox.Group style={{ width: '100%' }} onChange={ onChangeVariables } value={ checkedVariables }>*/}
            {/*        { variabels && variabels.length > 0 && variabels.map((variable, index) => {*/}
            {/*            return (*/}
            {/*                <span className="variables-content-email" key={ index }>*/}
            {/*                    {*/}
            {/*                        emailTemplate && checkedVariablesUpdate && checkedVariablesUpdate.length > 0 && checkedVariablesUpdate.includes(variable) && emailTemplate.content && emailTemplate.content !== undefined && Object.keys(emailTemplate.content).length > 0 && emailTemplate.content.includes(`\${${variable}}`) ?*/}
            {/*                            <i className="fa fa-check" style={{ color: 'green' }}/> :*/}
            {/*                            <i className="fa fa-times" style={{ color: 'red', marginRight:3}}/>*/}
            {/*                    }*/}
            {/*                    <Checkbox checked={ true } value={ variable }>{ `\${${variable}}` }</Checkbox>*/}
            {/*            /!*<Icon className="icon-info-tooltip" type="info-circle" style={{ fontSize: '16px', color: '#08c' }}/>*!/*/}
            {/*                  { variable.includes('name') ?*/}
            {/*                    <Tooltip placement="bottom" title={<span>This is the subject's forename and is mostly used in the first*/}
            {/*                      line of the email. However, if you need to insert it somewhere else in the email you can, just*/}
            {/*                      type ${'{name}'} wherever you want it to appear.</span>}>*/}
            {/*                      <Icon className="icon-info-tooltip" type="info-circle" style={{ fontSize: '16px', color: '#08c' }}/>*/}
            {/*                    </Tooltip> :*/}
            {/*                    variable.includes('companyName') ?*/}
            {/*                      <Tooltip placement="bottom" title={<span>This is the company name and is mostly used in the last*/}
            {/*                        line of the email. However, if you need to insert it somewhere else in the email you can, just*/}
            {/*                        type ${'{companyName}'} wherever you want it to appear.</span>}>*/}
            {/*                        <Icon className="icon-info-tooltip" type="info-circle" style={{ fontSize: '16px', color: '#08c' }} />*/}
            {/*                      </Tooltip> : "" }*/}
            {/*                </span>*/}
            {/*            )*/}
            {/*        })*/}
            {/*        }*/}
            {/*    </Checkbox.Group>*/}
            {/*</div>*/}

            {/*<ul>*/}
            {/*    { variabels.length > 0 && variabels.includes('name') &&*/}
            {/*    <li>{isNameLinkPersist ?*/}
            {/*        <i className="fa fa-check" style={{ color: 'green' }}/> :*/}
            {/*        <i className="fa fa-times" style={{ color: 'red' }}/>}&#36;{'{name}'}*/}
            {/*        <Tooltip placement="bottom" title={<span>This is the subject's forename and is mostly used in the first*/}
            {/*  line of the email. However, if you need to insert it somewhere else in the email you can, just*/}
            {/*  type ${'{name}'} wherever you want it to appear.</span>}>*/}
            {/*            <Icon className="icon-info-tooltip" type="info-circle" style={{ fontSize: '16px', color: '#08c' }}/>*/}
            {/*        </Tooltip>*/}
            {/*    </li>*/}
            {/*    }*/}
            {/*    {variabels.length > 0 && variabels.includes('message') &&*/}
            {/*    <li>{ isMessagePersist ? <i className="fa fa-check" style={{ color: 'green' }}/> :*/}
            {/*        <i className="fa fa-times" style={{ color: 'red' }}/>}&#36;{'{message}'}*/}
            {/*        <Tooltip placement="bottom"*/}
            {/*                 title={<span>This is the message from the admin, telling the subject why the ID*/}
            {/*  he sent was rejected. Put the variable ${'{message}'} wherever you want this text*/}
            {/*  to be in the email.</span>}>*/}
            {/*            <Icon className="icon-info-tooltip" type="info-circle" style={{ fontSize: '16px', color: '#08c' }}/>*/}
            {/*        </Tooltip>*/}
            {/*    </li>*/}
            {/*    }*/}
            {/*    {variabels.length > 0 && variabels.includes('details') &&*/}
            {/*    <li>{ isNameLinkPersist ?*/}
            {/*        <i className="fa fa-check" style={ { color: 'green' } }/> :*/}
            {/*        <i className="fa fa-times" style={ { color: 'red' } }/> }&#36;{ '{details}' }*/}
            {/*        <Tooltip placement="bottom" title={<span>These are the notes that the admin wrote, and contain the details*/}
            {/*that are exactly needed for the ID Check. Add the variable ${'{details}'} where you want*/}
            {/*this to appear in the email. </span>}>*/}
            {/*            <Icon className="icon-info-tooltip" type="info-circle" style={{ fontSize: '16px', color: '#08c' }} />*/}
            {/*        </Tooltip>*/}
            {/*    </li>*/}
            {/*    }*/}
            {/*    {variabels.length > 0 && variabels.includes('resultUrl') &&*/}
            {/*    <li>{ isResultUrlPersist ? <i className="fa fa-check" style={{ color: 'green' }}/> :*/}
            {/*        <i className="fa fa-times" style={{ color: 'red' }}/>}&#36;{'{resultUrl}'}*/}
            {/*        <Tooltip placement="bottom"*/}
            {/*                 title={<span>This is the link to be sent in the email. The placeholder of the*/}
            {/*  link should be coloured blue and underlined. You can edit this text if it is there already.*/}
            {/*  If not, you can write the placeholder in plain text, then select it and press the Link icon in the*/}
            {/*  toolbar; that's when a small textbox will show up, and that is where you should write the variable*/}
            {/*  ${'{resultUrl}'}. Now you've got a link to resultUrl, with the text what you wrote as placeholder.</span>}>*/}
            {/*            <Icon className="icon-info-tooltip" type="info-circle" style={{ fontSize: '16px', color: '#08c' }}/>*/}
            {/*        </Tooltip>*/}
            {/*    </li>*/}
            {/*    }*/}
            {/*    { variabels.length > 0 && variabels.includes('companyName') &&*/}
            {/*    <li>*/}
            {/*        <i className="fa fa-check" style={ { color: 'green' } }/>*/}
            {/*        &#36;{ '{companyName}' }*/}
            {/*        <Tooltip placement="bottom" title={ <span>This is the company name and is mostly used in the last*/}
            {/*    line of the email. However, if you need to insert it somewhere else in the email you can, just*/}
            {/*    type ${'{companyName}'} wherever you want it to appear.</span>}>*/}
            {/*            <Icon className="icon-info-tooltip" type="info-circle" style={{ fontSize: '16px', color: '#08c' }} />*/}
            {/*        </Tooltip>*/}
            {/*    </li>*/}
            {/*    }*/}
            {/*</ul>*/}
        </fieldset>
    )
}

export default EmailWorkVariables