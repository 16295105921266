import React from 'react'
import { Input, Modal, Tabs } from 'antd'
import { Link, Navigate } from 'react-router-dom'
import { compose } from 'redux'
import './SmartBoxFolderSettings.scss'
import { getRenameFile, getSmartBoxFileById, getSmartBoxFilesFilter } from '../../../../api/SmartBox'
import Access from './FolderSettings/Access'
import { generateFileForLink } from '../../../../utils/SmartBox/SmartBoxMethods'
import CustomNotificationManager from '../../../Common/CustomNotificationManager'
import imgFolderSmallOpenParentSmall from '../../../../assets/smart-folder-open-paret-small.svg'
import SmartBoxSettingsMenu from './SmartBoxSettingsMenu'
// import LeftFoldersNavigation from '../../../Common/LeftFoldersNavigation/LeftFoldersNavigation'
import { connect } from 'react-redux'
import DirectoryNote from '../DirectoryNote'
import {
  setSmartBoxFilesTable,
  // setSmartBoxBaseDataTree,
  setSmartBoxSelectedDocuments,
  setSmartBoxDirectoryNote,
  setSmartBoxToggleFilePreview
} from '../../../../actions/smartBoxActions'
import withRouter from '../../../../private-routers/withRouter'

class SmartBoxFolderAnalysisSettings extends React.Component {
  state = {
    folder: {},
    paths: '',
    renameFolderModalVisible: false,
    newFolderName: '',
    newFolderNameValid: { success: true, reason: 'Invalid name' },
    searchText: '',
    smartBoxDataTree: [],
    documentNoteVisible: true
  }

  componentDidMount() {
    this.fetchFolderInfo()
    document.addEventListener('mousedown', this.handleClickOutside, false)
    if (this.props.smartBoxBaseDataTree && this.props.smartBoxBaseDataTree.length > 0) {
      return true
    } 
    this.fetchFilesTree()
    
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props && this.props.smartBoxSelectedDocumentId.action === 'security-settings') {
      this.fetchFolderInfo()
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false)
  }

  toggleLoadingOpenedFolder = (open) => this.setState({ loadingOpenedFolder: open })

  toggleRenameFolderModal = (open) => this.setState({ renameFolderModalVisible: open })

  fetchFilesTree = () => {
    const { searchText } = this.state
    const filterFiles = {
      docFilename: searchText,
      docFolderPath: null
    }
    getSmartBoxFilesFilter(filterFiles).then((response) => {
      this.setState({
        smartBoxDataTree: response.data
      })
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  handleRenameFolder = () => {
    const { folder, newFolderName, newFolderNameValid } = this.state
    const { renameFolderModalVisible } = this.state
    const nameRgex = /^[A-Za-z\d_\s]+$/
    if (newFolderName) {
      newFolderNameValid.success= true
      if (!nameRgex.test(newFolderName) || folder.name === newFolderName) {
        newFolderNameValid.success = false
        if (!nameRgex.test(newFolderName)) {
          newFolderNameValid.reason = 'Invalid name. Please do not use the following characters: /\\?*;:[]\'"|,.<>'
        }
        if (folder.name === newFolderName) {
          newFolderNameValid.reason = 'It\'s old name. Please insert a new one.'
        }
      }
      if (newFolderNameValid.success) {
        const newRenameFolder = {
          id: folder.id,
          name: newFolderName
        }
        getRenameFile(newRenameFolder).then((response) => {
          if (response.status === 200) {
            folder.name= response.data
            const pos = folder.relativePath.lastIndexOf('/')
            const newRelativePathTemp = `${ folder.relativePath.substr(0, pos < 0 ? folder.relativePath.length : pos) }/${ response.data }`
            folder.relativePath = newRelativePathTemp
            this.setState({
              renameFolderModalVisible: false,
              newFolderName: '',
              folder
            }, () => { this.generateSmartBoxMenu('') })
          }
        }).catch((error) => {
          const errorMessage = 'Folder id does not exist'
          if (error?.response?.status === 500) {
            if (typeof error.response.data.message !== 'undefined' && error.response.data.message.indexOf(errorMessage) > 0) {
              CustomNotificationManager.error('The current folder no longer exists or you do not have access to its settings', 'Error')
              const path = '/smart-box'
              this.props.navigate(path)
            }
          }else if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
        })
      } else {
        this.setState({ newFolderName: '', renameFolderModalVisible })
      }

    }

  }

  handleChangeFolderName = (e) => this.setState({ newFolderName: e.target.value })

  fetchFolderInfo = () => {
    this.setState({
      folderId: this.props.params.id
    }, () => {
      getSmartBoxFileById(this.state.folderId).then((response) => {
        if (response.status === 200) {
          if (!response.data.path && !response.data.smartDocument) {
            CustomNotificationManager.error('The current folder no longer exists or you do not have access to its settings', 'Error')
            const path = '/smart-box'
            this.props.navigate(path)
          }
          this.setState({
            folder: response.data.smartDocument,
            paths: response.data.path
          })
        }
      }).catch((error) => {
        if (error?.response?.status === 500 && error?.response?.data?.detail === 'You don\'t have access to this folder') {
          CustomNotificationManager.error('You don\'t have access to this folder', 'Error')
          this.props.navigate('/smart-box')
        }else if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    })
  }

  handleClickOutside = (e) => {
    const { smartBoxMenuOpen } = this.state
    if (this.node) {
      if (this.node.contains(e.target)) {
        if (!smartBoxMenuOpen) {
          this.toggleSmartBoxMenu(true)
        }
        if (smartBoxMenuOpen && typeof e.target.className === 'string' && (e.target.className.includes('ant-menu-submenu-title') || e.target.className.includes('ant-menu-submenu-arrow'))) {
          this.toggleSmartBoxMenu(false)
        }
      } else if (smartBoxMenuOpen) {
        this.toggleSmartBoxMenu(false)
      }
    }
  }

  generateSmartBoxMenu = (activePage= '') => {
    const { folder, paths, smartBoxMenuOpen } = this.state
    const shortBreadcrumbLimit = 4
    let folderDepth = 0
    const path = folder.relativePath
    if (path) {
      folderDepth = path.split('/').length
      folderDepth = folderDepth - 1
      const fileId = (Object.keys(paths).length) ? paths[Object.keys(paths)[0]] : (folder.id ? folder.id : '')
      const pathArray = path.split('/')
      let breadCrumb = []
      let requestPath = ''
      let j = 0
      for (let i = 0; i < pathArray.length; i++) {
        if (pathArray[i]) {
          requestPath = `${ requestPath }/${ pathArray[i] }`
          const fileIdForLink = generateFileForLink(folder.id, paths, requestPath)

          if (i < shortBreadcrumbLimit + 1) {
            breadCrumb = [...breadCrumb, [<span key={j++}>&nbsp;&nbsp;&nbsp;</span>,
              <span key={j++} className="arrow">&rsaquo;</span>, <span key={j++}>&nbsp;&nbsp;&nbsp;</span>]]
          }

          if (path === requestPath) {
            breadCrumb.push(<img key={j++} src={imgFolderSmallOpenParentSmall} alt={path}/>)
          }
          if (i < shortBreadcrumbLimit || i === folderDepth) {
            breadCrumb.push(<span key={j++}>&nbsp;&nbsp;</span>)
            breadCrumb.push(<Link key={j++} to={{ pathname: `/smart-box/folder/${ fileIdForLink }` }}>{pathArray[i]}</Link>)
          } else if (i === shortBreadcrumbLimit && i < folderDepth) {
            breadCrumb.push(<span key={j++}>...&nbsp;&nbsp;&nbsp;&nbsp;</span>)
          }
          if (path === requestPath && !folder.depth) {
            breadCrumb = [...breadCrumb, [<span key={j++} className="arrow">&rsaquo;</span>,
              <span key={j++}>&nbsp;&nbsp;&nbsp;</span>,
              <span ref={ (node) => this.node = node } key={j++}>
                <SmartBoxSettingsMenu
                  folder={folder}
                  fileId={fileId}
                  toggleFilePreview={() => this.toggleFilePreview(true, folder)}
                  activePage={ activePage }
                  smartBoxMenuOpen={smartBoxMenuOpen}
                  fetchFiles = { this.fetchFolderInfo }
                  toggleLoadingOpenedFolder = { this.toggleLoadingOpenedFolder }
                  toggleSmartBoxMenu = { this.toggleSmartBoxMenu }
                  toggleRenameFolderModal = { this.toggleRenameFolderModal }
                >
                </SmartBoxSettingsMenu>
              </span>]]
          }
        }
      }
      return breadCrumb
    }
    return ''
  }

  toggleSmartBoxMenu = (open) => {
    const { smartBoxMenuOpen } = this.state
    if (smartBoxMenuOpen) {
      this.setState({ smartBoxMenuOpen: open })
    }
    this.setState({ smartBoxMenuOpen: open })
  }
  toggleNoteVisible = (open) => {
    this.setState({ documentNoteVisible: open })
  }

  renderRenameFolderModal = () => {
    const { renameFolderModalVisible, folder, newFolderNameValid } = this.state
    return (
      <Modal
        className="rename-folder"
        title="Rename folder"
        visible={ renameFolderModalVisible }
        onOk={ () => this.handleRenameFolder() }
        onCancel={ () => this.toggleRenameFolderModal(false) }
      >
        <p className="subtitle">Add new name for <b>{folder.name}</b></p>
        <Input type="text" placeholder="Rename folder" onChange={ (e) => this.handleChangeFolderName(e) } onPressEnter={ (e) => this.handleRenameFolder() }/>
        {!newFolderNameValid.success &&
        <p className="error">{newFolderNameValid.reason}</p>
        }
      </Modal>
    )
  }
  visibleDirectoryNote = (open) => {
    this.props.setSmartBoxDirectoryNote(open)
  }

  render() {
    const { folder, smartBoxDataTree } = this.state
    const { TabPane } = Tabs
    let basePath = ''
    if (this.props.smartBoxBaseDataTree && this.props.smartBoxBaseDataTree.length > 0) {
      basePath = this.props.smartBoxBaseDataTree[0].folderPath ? this.props.smartBoxBaseDataTree[0].folderPath : ''
    } else if (smartBoxDataTree && smartBoxDataTree.length > 0) {
      basePath = smartBoxDataTree[0].folderPath ? smartBoxDataTree[0].folderPath : ''
    }
    if (!folder) {
      const path = '/smart-box'
      return <Navigate to={ path } />
    }
    return (
      <React.Fragment>
        { this.props.smartBoxVisibleAddNote === true &&
        <DirectoryNote closeDocumentNote={(e) => this.visibleDirectoryNote(e, false)} documentId={ this.props.smartBoxSelectedDocumentId.documentId } user={ this.props.user }/>
        }
        <div className="smart-box-folder-settings_container smart-box-folder-settings_new-container-security">
          <div className="smart-box-folder-settings_header smart-box-folder-settings_header2 mt-5">
            {/*<div className="smart-box-folder-opened__breadcrumb">*/}
            {/*  <span className="smart-box-folder-opened__breadcrumb--bold">*/}
            {/*    <Link to="/smart-box/management">Smartbox.ai Home</Link>*/}
            {/*    { this.generateSmartBoxMenu(4) }*/}
            {/*  </span>*/}
            {/*</div>*/}
          </div>
          <div className="smart-box-folder-settings_content smart-box-folder-settings_security-content">
            <div className="file-settings-tab">
              <div className="parent-tabs tabs-security">
                <Tabs type="card" className="child-tabs ant-tabs-top-content child-tabs-security">
                  <TabPane tab="Security Settings" key="1">
                    <Access folder={ folder } folderId={ this.props.params.id } />
                  </TabPane>
                  {/*<TabPane tab="Data Topics" key="2">*/}
                  {/*  <DataTopics folder={ folder }/>*/}
                  {/*</TabPane>*/}
                  {/*<TabPane tab="Analytics" key="3">*/}
                  {/*  <Analytics folder={ folder }/>*/}
                  {/*</TabPane>*/}
                  {/*<TabPane tab="Regular Expressions" key="4">*/}
                  {/*  <RegularExpressions folder={ folder } />*/}
                  {/*</TabPane>*/}
                </Tabs>
              </div>
            </div>
          </div>
          { this.renderRenameFolderModal() }
        </div>
      </React.Fragment>
    )
  }
}


SmartBoxFolderAnalysisSettings = withRouter(SmartBoxFolderAnalysisSettings)
const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id,
  user: state.userReducer.user,
  smartBoxFilesTable: state.smartBox.smartBoxFilesTable,
  smartBoxBaseDataTree: state.smartBox.smartBoxBaseDataTree,
  smartBoxSelectedDocumentId: state.smartBox.smartBoxSelectedDocumentId,
  smartBoxVisibleAddNote: state.smartBox.smartBoxVisibleAddNote
})

export default compose(
  connect(mapStateToProps, {
    setSmartBoxFilesTable,
    // setSmartBoxBaseDataTree,
    setSmartBoxSelectedDocuments,
    setSmartBoxDirectoryNote,
    setSmartBoxToggleFilePreview
  }),
  )(SmartBoxFolderAnalysisSettings)