import axios from 'axios'

const isLocalhost = () => {
  return window.location.hostname === 'localhost'
}

const host = isLocalhost() ? 'http://localhost:8080' : ''
export const apiGetImage = (id = 0, type) => {
  const newUrl =`${ host }/osprey-0.1.0/v1/images/${ id }/${ type }`

  return axios({
    method: 'get',
    url: newUrl
  })
}
