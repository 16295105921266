import React from 'react'
import { connect } from 'react-redux'
import { Input, Button } from 'antd'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { customConfirm } from '../../../utils/AssetRegister/CustomConfirm'
import iconDelete from '../../../assets/icon-del-circle.png'
import { getProjects, saveProject, deleteProject, getDictionaries, addDictionary, saveDictionary, deleteDictionary } from '../../../api/SmartBox'
import { setSmartBoxProjects } from '../../../actions/smartBoxActions'
import './SmartBoxSettings.scss'
import CustomNotificationManager from "../../Common/CustomNotificationManager";


class SmartBoxSettings extends React.Component {
  state = {
    projects: [],
    dictionaries: [],
    subcategories: [],
    projectName: '',
    projectId: '',
    disabledAddProject: true,
    editMode: false,
    dictionaryName: '',
    dictionaryId: '',
    subcategoryName: '',
    disabledAddDictionary: true,
    editModeDictionary: false,
    subcategoriesEdited: [],
    subcategoriesDeleted: [],
    newProject: {}
  }
  
  componentDidMount() {    
    //this.fetchProjects()
    this.fetchDictionaries() 
  } 

  fetchProjects = () => {
    getProjects().then((res) => {
      this.props.setSmartBoxProjects(res.data)
      this.setState({
        projects: res.data,        
      })
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  fetchDictionaries = () => {
    getDictionaries().then((res) => {
      this.setState({
        dictionaries: res.data                
      }, () => {
        this.setState({
          projects: this.props.smartBoxProjects
        })
      })
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  updateValue(e, field, id = '') {
    const value = e.target.value

    let objState = {
      [field]: value
    }
    if(field === 'projectName') {
      objState.disabledAddProject = value === ''
    } else if(field === 'dictionaryName') {
      objState.disabledAddDictionary = value === ''
    } else if(field === 'subcategoriesEdited') {      
      let { subcategoriesEdited } = this.state
      const index = subcategoriesEdited.findIndex(s => s.id === id)
      if(index === -1) {
        subcategoriesEdited.push({id, name: value})
      } else {
        subcategoriesEdited[index].name = value
      }
      objState = { subcategoriesEdited } 
    }
    this.setState(objState)
  }
  addProject = () => {
    const { projectName } = this.state

    const data = {id: '', name: projectName}
    saveProject(data).then((res) => {
      this.setState({ 
        projectName: '', 
        disabledAddProject: true,
        newProject: res.data
      }, () => {      
        this.fetchProjects()
      })    
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  editProject = (id) => {
    const projects = this.state.projects
    const p = projects.find(project => project.id === id)

    this.setState({ 
      projectId: p.id,
      projectName: p.name, 
      editMode: true 
    })
    this.projectInput.focus()
  }
  
  showConfirmDelete = (p, type = 'project') => {
    const temp = this
    customConfirm({
      confirmAction: () => type === 'project' ? temp.deleteProject(p.id) : (type === 'dictionary' ? temp.deleteDictionary(p.id) : temp.deleteSubcategory(p.id)),
      title: 'Do you want to delete',
      content: `${ p.name } ?`,
      confirmButtonText: 'DELETE',
      cancelButtonText: 'CANCEL',
      mask: true,
      maskClosable: false,
      closeHaveLogic: false,
      className: 'asset-register-modal project-delete-modal',
      centered: true,
      cancelClassName: 'cancel-button',
      confirmClassName: 'confirm-button'
    })
  }
  deleteProject = (id) => {
    deleteProject(id).then((res) => {
      this.fetchProjects()
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  saveProject = () => {
    const { projectId, projectName } = this.state    
    
    const data = {id: projectId, name: projectName}
    saveProject(data).then(() => {
      this.setState({
        editMode: false,
        projectId: '',
        projectName: '',      
        disabledAddProject: true 
      }, () => {
        this.fetchProjects()
      })    
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  addDictionary = () => {
    
    const { dictionaryName, subcategoryName } = this.state
    const data = {
      name: dictionaryName,
      subcategory_name: subcategoryName
    }
    addDictionary(data).then(() => {
      this.setState({ 
        dictionaryName: '', 
        subcategoryName: '', 
        disabledAddDictionary: true 
      }, () => { 
        this.fetchDictionaries() 
      })
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  editDictionary = (id) => {
    const dictionaries = this.state.dictionaries
    const d = dictionaries.find(dict => dict.id === id)

    this.setState({ 
      dictionaryId: d.id,
      dictionaryName: d.name, 
      subcategories: d.subcategories,
      editModeDictionary: true 
    })
    this.dictionaryInput.focus()
  }
  saveDictionary = (id) => {
    const { dictionaryId, dictionaryName, subcategoryName, subcategoriesEdited, subcategoriesDeleted } = this.state
    
    const data = {
      id: dictionaryId,
      name: dictionaryName,
      subcategory_name: subcategoryName,
      subcategoriesEdited,
      subcategoriesDeleted
    }     
    saveDictionary(data).then(() => {
      this.setState({
        subcategories: [],
        dictionaryName: '',
        dictionaryId: '',
        subcategoryName: '',
        disabledAddDictionary: true,
        editModeDictionary: false,
        subcategoriesEdited: [],
        subcategoriesDeleted: []
      }, () => {
        this.fetchDictionaries()
      })
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  deleteDictionary = (id) => {
    
    deleteDictionary(id).then((res) => {
      this.fetchDictionaries()
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  deleteSubcategory = (id) => {
    const { subcategoriesDeleted } = this.state

    subcategoriesDeleted.push(id)
    this.setState({ subcategoriesDeleted }); 
  }

  render() {
    const { projects, dictionaries, disabledAddProject, projectName, editMode, disabledAddDictionary, dictionaryName, subcategoryName, editModeDictionary, subcategories, subcategoriesDeleted } = this.state

    return (
      <div className="smart-box-settings__container">
        <div className="box">
          <div className="box-title">My projects</div>
          { projects.map(p => 
            <div key={p.id} className="project-line">
              <div className="item-name">{ p.name }</div>
              <DropdownButton
                title={ <div className="ellipsis-v">...</div> }
                id={`dropdown-${ p.id }`}
              >
                <Dropdown.Item onSelect={ () => { this.editProject(p.id)} }>Edit name</Dropdown.Item>
                <Dropdown.Item onSelect={ () => { this.showConfirmDelete(p, 'project')} }>Delete</Dropdown.Item>
              </DropdownButton>
            </div> 
          )}
          <div className="box-footer">
            <div>
              <label>Project name</label>
              <Input className="project-name" onChange={ (e) => this.updateValue(e, 'projectName') } value={ projectName }
                ref={(input) => { this.projectInput = input; }}  />
            </div>
            {editMode && <Button onClick={ this.saveProject }>SAVE</Button>}
            {!editMode && <Button onClick={ this.addProject } disabled={ disabledAddProject }>ADD</Button>}
          </div>
        </div>
        <div className="box">
          <div className="box-title">My dictionaries</div>
          { dictionaries.map(d => 
            <div key={d.id} className="project-line">
              <div>
                <div className="item-name">{ d.name }</div>
                {typeof d.subcategories !== 'undefined' && Object.entries(d.subcategories).length > 0 &&
                  <ul>
                    { Object.entries(d.subcategories).map(([id, name]) => <li key={`subcat${id}`}>{name}</li>) }
                  </ul>
                }
              </div>
              <DropdownButton
                title={ <div className="ellipsis-v">...</div> }
                id={`dropdown-${ d.id }`}
              >
                <Dropdown.Item onSelect={ () => { this.editDictionary(d.id)} }>Edit</Dropdown.Item>
                <Dropdown.Item onSelect={ () => { this.showConfirmDelete(d, 'dictionary')} }>Delete</Dropdown.Item>
              </DropdownButton>
            </div> 
          )}
          <div className="box-footer">
            <div>
              <label>Dictionary name</label>
              <Input className="dictionary-name" onChange={ (e) => this.updateValue(e, 'dictionaryName') } value={ dictionaryName }
                ref={(input) => { this.dictionaryInput = input; }}  />
            </div>
            <div className="subcat-wrapper">
              <label>Sub category</label>
              <Input className="subcategory-name" onChange={ (e) => this.updateValue(e, 'subcategoryName') } value={ subcategoryName } 
                placeholder={editModeDictionary ? 'New subcategory name' : '' } ref={(input) => { this.subcategoryInput = input; }}  />
            </div>
            
            {editModeDictionary && <Button onClick={ this.saveDictionary }>SAVE</Button>}
            {!editModeDictionary && <Button onClick={ this.addDictionary } disabled={ disabledAddDictionary }>ADD</Button>}
          </div>
          {editModeDictionary && typeof subcategories !== 'undefined' && Object.entries(subcategories).length > 0 && 
            Object.entries(subcategories).filter(([id,name]) => subcategoriesDeleted.indexOf(id) === -1).map(([id,name]) => 
            <div key={id} className="box-footer pt0">
              <div className="placeholder-cont"></div>
              <div>                
                <Input className="subcategory-name" onChange={ (e) => this.updateValue(e, 'subcategoriesEdited', id) } defaultValue={ name } />
                <img src={iconDelete} alt="Delete subcategory" onClick={ () => this.showConfirmDelete({id, name}, 'subcategory') } />
              </div>
              <div className="placeholder-btn"></div>
            </div>
          )}
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id,
  smartBoxProjects: state.smartBox.smartBoxProjects
  
})

export default connect(mapStateToProps,{
  setSmartBoxProjects
})(SmartBoxSettings)