import React, { Component } from 'react'
import '../../style/common.scss'
import './SignUp.css'
import LogoOsprey from '../../assets/head-logo-osprey.png'
import Message from '../Common/Message'
import Loading from '../Common/Loading'
import { industryItems } from '../../utils/StaticTexts'
import PropTypes from 'prop-types'
import { emailRegex } from '../../utils'
import CustomNotificationManager from '../Common/CustomNotificationManager'
import { apiAcceptTenancy, checkMultitenancyToken } from '../../api/User'
import * as actions from '../../actions/messageActions'
import { compose } from 'redux'
import { connect } from 'react-redux'
import TermsOfService from '../Common/TermsOfService'
import { Link } from 'react-router-dom'

export class Multitenancy extends Component {
  constructor() {
    super()
    this.state = {
      isReceiveMarketingChecked: false,
      isTermsAccepted: false,
      isSending: false,
      name: '',
      companyName: '',
      tenantClient: 0,
      tenantEmail: '',
      ownerEmail: '',
      password: '',
      userId: 0,
      token: 0,
      companySizeOption: [],
      success: true,
      userCreated: false
    }
  }

  componentDidMount() {
    if (this.props.params) {
      const token = this.props.params.token
      checkMultitenancyToken(token)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.setState({
              tenantClient: response.data.tenantClient,
              tenantEmail: response.data.tenantEmail,
              ownerEmail: response.data.ownerEmail,
              success: true,
              userCreated: false
            })
          } else {

          }
        })
        .catch((e) => {
          this.setState({ success: false })
        })
    }
    const companySizeOption = {
      '': 'Please select',
      '1-10': '1-10 people',
      '11-20': '11-20 people',
      '20-50': '20-50 people',
      '100-500': '100-500 people',
      '500-5000': '500-5000 people',
      '5000+': 'more than 5000 people'
    }
    this.setState({ companySizeOption: companySizeOption })
  }

  componentWillUnmount() {
    this.props.hideMessage()
  }

  hideTermsOfService = (e) => {
    this.setState({
      isOpenedTerms: false
    })
  }

  showTermsOfService = (e) => {
    this.setState({
      isOpenedTerms: true
    })
    e.preventDefault()
  }

  updateValue(value, field) {
    this.setState({
      [field]: value
    })
  }

  isFormValid() {
    const { tenantEmail } = this.state
    if (!emailRegex.test(tenantEmail)) {
      CustomNotificationManager.error('Your email does not have a valid format', 'Error')
      return false
    }
    return true
  }

  handleSubmit(e) {
    e.preventDefault()
    if (this.isFormValid()) {
      this.doSignUp()
    }
  }

  doSignUp = () => {
    this.setState({
      isSending: true
    })
    this.props.hideMessage()

    const data = {
      id: this.state.tenantClient,
      ownerEmail: this.state.ownerEmail,
      tenantEmail: this.state.tenantEmail,
      email_verified: false,
      password: this.state.password,
      user_metadata: {
        agreeTermsOfService: this.state.isTermsAccepted,
        companyName: this.state.companyName,
        companySize: this.state.companySize,
        industry: this.state.industry,
        isAccountActivated: false,
        receiveMarketingInfo: this.state.isReceiveMarketingChecked,
        name: this.state.name,
        roles: {
          osprey: 'admin'
        }
      }
    }

    apiAcceptTenancy(data)
      .then((response) => {
        if (response.status === 200) {
          CustomNotificationManager.success('Account created successfully', 'Success')
          this.setState({
            isSending: false,
            userCreated: true
          })
        }
      }).catch((e) =>
        CustomNotificationManager.error('This email is already used', 'Error'),
      this.setState({
        isSending: false
      }))
  }

  render() {
    const { isOpenedTerms, isReceiveMarketingChecked, isTermsAccepted, isSending, name, companyName, tenantEmail, password, companySizeOption, success, userCreated } = this.state
    const industries = industryItems
    const { messageArea } = this.props
    return (
      <div className="sign-up-page new-sign-up-page">
        { isOpenedTerms &&
          <TermsOfService hideTermsOfService={ this.hideTermsOfService } />
        }
        <div className="sign-up-box">
          <div className="sign-up-header">
            <div className="formHeader-header">
              <div className="formHeader-logo">
                <img src={ LogoOsprey } alt="Osprey Logo" />
              </div>
            </div>
            { success && userCreated === false &&
            <form>
              <div className="sign-up-container">
                <div className="sign-up-form-group">
                  <label htmlFor="name">
                    Name<span className="sign-up-required">*</span>
                  </label>
                  <input
                    id="name"
                    type="text"
                    onChange={ (e) => this.updateValue(e.target.value, 'name') } />
                </div>

                <div className="sign-up-form-group">
                  <label htmlFor="company-name">
                    Company name<span className="sign-up-required">*</span>
                  </label>
                  <input
                    id="company-name"
                    type="text"
                    onChange={ (e) => this.updateValue(e.target.value, 'companyName') } />
                </div>
              </div>

              <div className="sign-up-container">
                <div className="sign-up-form-group">
                  <label htmlFor="email">
                    Email address<span className="sign-up-required">*</span>
                  </label>
                  <input
                    id="email"
                    type="text"
                    value={ this.state.tenantEmail }
                    readOnly={ true }
                    onChange={ (e) => this.updateValue(e.target.value, 'emailAddress') } />
                </div>

                <div className="sign-up-form-group">
                  <label htmlFor="password">
                    Password<span className="sign-up-required">*</span>
                  </label>
                  <input
                    id="password"
                    type="password"
                    onChange={ (e) => this.updateValue(e.target.value, 'password') } />
                </div>
              </div>

              <div className="sign-up-container">
                <div className="sign-up-form-group">
                  <label htmlFor="company-size">Company size</label>
                  <select id="company-size" onChange={ (e) => this.updateValue(e.target.value, 'companySize') }>
                    { Object.keys(companySizeOption).map((key, option) =>
                      <option key = {key} value={ key }>{ companySizeOption[key] }</option>
                    )}
                  </select>
                </div>

                <div className="sign-up-form-group">
                  <label htmlFor="industry">Industry</label>
                  <select id="industry" onChange={ (e) => this.updateValue(e.target.value, 'industry') }>
                    <option value="">Please select</option>
                    { industries.map((item) => (
                      <option key={ item } value={ item }>{ item }</option>
                    ))
                    }
                  </select>
                </div>
              </div>

              <div className="signUp-group">
                <div className="sign-up-checkbox">
                  <input
                    id="receiveProductUpdates"
                    type="checkbox"
                    value=""
                    onChange={ (e) => this.updateValue(e.target.checked, 'isReceiveMarketingChecked') } />
                  <label
                    className={ isReceiveMarketingChecked ? 'sign-up-checkbox-label--checked' : 'sign-up-checkbox-label' }
                    htmlFor="receiveProductUpdates"></label>
                </div>
                <span>Receive product updates and information by email</span>
              </div>

              <div className="signUp-group">
                <div className="sign-up-checkbox">
                  <input
                    id="termsOfService"
                    type="checkbox"
                    value=""
                    onChange={ (e) => this.updateValue(e.target.checked, 'isTermsAccepted') } />
                  <label
                    className={ isTermsAccepted ? 'sign-up-checkbox-label--checked' : 'sign-up-checkbox-label' }
                    htmlFor="termsOfService" id="termsOfServiceLabel"></label>
                </div>
                <span>I agree to the <button className="sign-up-link" onClick={ (e) => this.showTermsOfService(e) }>terms of service</button><span className="sign-up-required">*</span></span>
              </div>

              <div className="sign-up-footer">
                { !isSending &&
                <div>
                  <div>
                    { messageArea.visible &&
                    <div className="sign-up-message">
                      <Message
                        hideMessageAction={ this.props.hideMessage }
                        status={ messageArea.status }
                        text={ messageArea.text } />
                    </div>
                    }
                  </div>
                  <div>
                    <input
                      className="sign-up-submit"
                      disabled={
                        name === '' ||
                        companyName === '' ||
                        tenantEmail === '' ||
                        password === '' ||
                        !isTermsAccepted
                      }
                      type="submit"
                      value="Sign-up"
                      onClick={ (e) => this.handleSubmit(e) }
                    />
                  </div>
                </div>
                }

                { isSending &&
                <div className="sign-up-loading">
                  <Loading />
                </div>
                }
              </div>
            </form>
            }

            { success && userCreated &&
            <div className="multitenancy__body">
              <p>Thank you for accepting the tenant invitation!</p>
              <div className="login-btn">
                <Link to="/osp">&#8249; Home</Link>
              </div>
            </div>
            }

            { !success &&
            <div className="multitenancy__body">
              <p>You don't have permission on this page.</p>
              <div className="login-btn">
                <Link to="/osp">&#8249; Home</Link>
              </div>
            </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

const stateMap = (state) => ({
  messageArea: state.messageAreaReducer.messageArea
})

const dispatchMap = (dispatch) => ({
  hideMessage: () => dispatch(actions.hideMessage()),
  showMessage: (status, text) => dispatch(actions.showMessage({ status, text }))
})

Multitenancy.propTypes = {
  hideMessage: PropTypes.func,
  messageArea: PropTypes.object,
  showMessage: PropTypes.func
}

export default compose(
  connect(stateMap, dispatchMap)
)(Multitenancy)
