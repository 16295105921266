import React from 'react'
import PropTypes from 'prop-types'

import { TopicsConsumer } from '../../../contexts/Topics'

const WithTopics = (Component) => {
  return class WithTopics extends React.Component {
    render = () => {
      return (
        <TopicsConsumer>
          { ({ getTopics, ...rest }) => (
            <InitTopics getTopics={ getTopics }>
              <Component {...this.props} {...rest} />
            </InitTopics>
          )}
        </TopicsConsumer>
      )
    }
  }
}

class InitTopics extends React.Component {
  componentDidMount = () => {
    this.props.getTopics()
  }

  render = () => {
    return (
      this.props.children 
    )
  }
}

InitTopics.propTypes = {
  children: PropTypes.element.isRequired,
  getTopics: PropTypes.func.isRequired
}

export default WithTopics