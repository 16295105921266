import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Spin, Table } from 'antd'
import { getDocumentBytes, getHistoryUpdateData, getOriginalDocumentBytes } from '../../../../api/DocLocker'
import {
  downloadOriginalFile,
  generateDocumentUpdateAdditionalReadableContent
} from '../../../../utils/AssetRegister/AssetRegisterHelperMethods'
import _ from 'lodash'
import moment from 'moment'
import Loading from '../../../Common/Loading'
import { generateBlobFromBase64 } from '../../../../utils/GenerateBlob'
import { Document, Page } from 'react-pdf'
import TrackVisibility from './TrackVisibility'

class CollapseContent extends React.Component {
  state = {
    documentData: {},
    nameFieldAndVersion: '',
    pageNumber: 1,
    pdfBytes: [],
    documentLoaded: false,
    totalPages: 0,
    pagesLoaded: false,
    documentExtension: '',
    documentMimeType: '',
    originalFileBytes: [],
    documentName: ''
  }

  columns = [
    {
      title: 'Version',
      dataIndex: 'version',
      key: 'version',
      render: (text, record) => <span className="content-version" onClick={() => this.showPdfModal(record)}><span className="icon-pages-b history-icon" />{text}</span>,
    },
    {
      title: 'Created by',
      dataIndex: 'createdBy',
      key: 'createdBy',
    },
    {
      title: 'Modified by',
      dataIndex: 'modifiedBy',
      key: 'modifiedBy',
    },
    {
      title: 'Modified on',
      dataIndex: 'modifyOn',
      key: 'modifyOn',
      render: (text) => <span>{moment(text).format('MM/DD/YY')}</span>,
    },
    {
      title: 'Note',
      dataIndex: 'notes',
      key: 'notes',
      colSpan: 2,
      align: 'left'
    },
  ];

  componentDidMount() {
    const fileIndex = this.props.columns.findIndex((item) => item.type === 'FILE' && item.name === 'DOCUMENT')
    const versionsIndex = this.props.columns.findIndex((item) => item.type === 'NUMBER' && item.name === 'VERSION')
    getHistoryUpdateData(this.props.documentId).then((res) => {
      this.setState({
        documentData: res.data,
        nameFieldAndVersion: `${ this.props.record[this.props.columns[fileIndex].id] } ${ this.props.record[this.props.columns[versionsIndex].id] }`,
        scale: this.generateScale(),
      }, () => {
        const newRecord = { ...this.props.record }
        newRecord[this.props.columns[versionsIndex].id] = res.data.history[0].version
        this.props.setNewVersion(this.props.record.id, newRecord)
      })
    })
  }

  generateScale = () => {
    const width = window.innerWidth
    let scale = 1.5
    if (width > 1200 && width < 1440) {
      scale = 1.25
    } else if (width > 1440 && width < 1600) {
      scale = 1.4
    } else if (width > 1600) {
      scale = 1.6
    }
    return scale
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    if (!this.state.documentLoaded) {
      this.setState({ numPages, documentLoaded: true })
    }
  }

  onItemClick = ({ pageNumber }) => this.setState({ pageNumber });

  onPagesLoadSuccess = (pageNumber) => {
    if (pageNumber === this.state.totalPages) {
      this.setState({
        pageNumber: 1,
        pagesLoaded: true
      })
    }
  }

  showPdfModal = (record) => {
    this.setState({
      previewVisible: true,
      loading: true
    }, () => {
      getDocumentBytes(record.fileBytesId).then((response) => {
        this.setState({
          pdfBytes: response.data.pdfBytes,
          documentId: response.data.id,
          pageNumber: 1,
          totalPages: response.data.totalPages,
          landscape: response.data.landscape,
          width: this.state.scale * response.data.width + 40,
          loading: false,
          renderingBlock: <Document file={ generateBlobFromBase64(response.data.pdfBytes) } onLoadSuccess={ this.onDocumentLoadSuccess }>
            {Array.from(
              new Array(response.data.totalPages),
              (el, index) => (
                <React.Fragment key={index + 1}>
                  <TrackVisibility onVisible={() => this.setPageNumber(index + 1)} key={index}><span style={{ display: 'none' }} key={index * 2} /></TrackVisibility>
                  <Page
                    key={`page_${ index + 1 }`}
                    pageNumber={index + 1}
                    scale={this.state.scale}
                    onRenderSuccess={() => this.onPagesLoadSuccess(index + 1)}
                  />
                </React.Fragment>
              )
            )}
          </Document>
        })
      })
    })
  }

  setPageNumber = (pageNumber) => {
    if ((pageNumber !== this.state.pageNumber) && this.state.documentLoaded && this.state.pagesLoaded) {
      this.setState({
        pageNumber
      })
    }
  }

  handleCancel = () => this.setState({
    previewVisible: false,
    pdfBytes: [],
    totalPages: '',
    pageNumber: 1,
    documentLoaded: false,
    loading: true,
    pagesLoaded: false,
    width: 520,
    documentId: null
  })

  downloadOriginalFile = () => {
    const { documentId } = this.state

    getOriginalDocumentBytes(documentId).then((res) => {
      if (res.data) {
        this.setState({
          originalFileBytes: res.data.bytesArray,
          documentExtension: res.data.extension,
          documentMimeType: res.data.type,
          documentName: res.data.name
        }, () => {
          const { originalFileBytes, documentExtension, documentMimeType, documentName } = this.state
          downloadOriginalFile(originalFileBytes, documentExtension, documentMimeType, documentName)
        })
      }
    })

  }

  render() {
    const { historyUpdate, additionalFields } = this.props
    const { documentData, previewVisible, pageNumber, loading, renderingBlock, numPages, nameFieldAndVersion, width } = this.state
    return (
      <React.Fragment>
        <div className="history-container">
          <div className="history-container__title">HISTORY</div>
          <Table columns={this.columns} dataSource={documentData.history} pagination={false} className="history-container__table"/>
        </div>
        <div className="update-container">
          { !_.isEmpty(documentData) &&
          generateDocumentUpdateAdditionalReadableContent(historyUpdate.filter((item) => item.type === 'RADIO')[0], documentData.updateFrequency)
          }
          <i className="down custom-caret" />
        </div>
        { additionalFields && documentData.additionalFieldsValues && <div className="additional-fields-container">
          { additionalFields.map((field, index) => {
            return generateDocumentUpdateAdditionalReadableContent(field, documentData.additionalFieldsValues[index], index)
          })
          }
        </div>
        }
        { previewVisible &&
        <Modal
          visible={ previewVisible }
          footer={ null }
          onCancel={ this.handleCancel }
          className="pdf-modal-locker doc-locker-pdf-modal" width={width || '520px'}>
          <div className="custom-modal-body">
            { loading ? <Spin spinning={ loading } indicator={ <div className="checkout-loading" style={{ width: width }}><Loading/>
            </div> }/> :
              <React.Fragment>
                <div className="modal-custom-header">
                  <div className="modal-custom-header__page-count">{ `Page ${ pageNumber }/${ numPages }` }</div>
                  <div className="modal-custom-header__document-name">{ nameFieldAndVersion }</div>
                  <div className="modal-custom-header__document-download"><span className="icon-download-b" onClick={this.downloadOriginalFile}/></div>
                </div>
                { renderingBlock }
              </React.Fragment>
            }
          </div>
        </Modal>
        }
      </React.Fragment>
    )
  }
}

CollapseContent.propTypes = {
  historyUpdate: PropTypes.array.isRequired,
  additionalFields: PropTypes.array.isRequired,
  documentId: PropTypes.number.isRequired,
  setNewVersion: PropTypes.func.isRequired
}

export default CollapseContent