import PropTypes from 'prop-types'
import React from 'react'
import { truncateString } from '../../../../../utils'
import ErrorMessages from '../../../../Common/ErrorMessages'
import InputValidator from '../../../../Common/InputValidator'
import { findJumpedQuestion } from './Helpers/OptionsUtils'
import './QuestionOptionForm.scss'

class QuestionOptionForm extends React.Component {
  updateValue(event, field) {
    const newOption = { ...this.props.option }
    newOption[field] = event.target.value
    this.props.updateOption(this.props.index, newOption)
  }

  updateJumpsToQuestion(event, question) {
    const value = event.target.value
    const questions = this.props.questions
    const newOption = {
      ...this.props.option,
      ...findJumpedQuestion(value, questions, question),
    }

    this.props.updateOption(this.props.index, newOption)
  }

  updateCheckBox(e) {
    const newOption = { ...this.props.option }
    newOption.hasMessage = !this.props.option.hasMessage
    if (!this.props.option.hasMessage) newOption.message = '' 
    this.props.updateOption(this.props.index, newOption)
  }

  // Must list just the ones greater than the next one
  nextQuestionsList(order) {
    return this.props.questions.filter((question) => (
      question.order > order + 1
    ))
  }

  goToQuestionTitle(nextQuestion) {
    const title = nextQuestion.title || 'Question title blank'
    return nextQuestion.alias || truncateString(`${ nextQuestion.order } - ${ title }`, 25)
  }

  render() {
    const {
      index,
      question,
      removeOption,
      option
    } = this.props

    const {
      errors,
      disabled,
      goToQuestion,
      hasMessage,
      message,
      order,
      score,
      title,
      subType,
    } = option

    const hasErrors = Object.keys(errors).length > 0

    return (
      <div 
        className={ hasErrors ? 'questionnaireOptions__formWrapperError' : 'questionnaireOptions__formWrapper' }>
        
        <ErrorMessages errors={ errors }>
          <div className="questionnaireOptions__form">
            <div className="questionnaireOptions__formMove">
              <span className="iconMove"></span>
            </div>
            { subType === 'DIVIDER' &&
              <div className="questionnaireOptions__formDivider">
                <input
                  id="title"
                  value={ title }
                  disabled={ disabled }
                  onChange={ (e) => { this.updateValue(e, 'title') } }
                />
              </div>
            }

            { subType !== 'DIVIDER' &&
              <React.Fragment>
                <div className="questionnaireOptions__formTitle">
                  <InputValidator errors={errors} field="title" showErrorMessage={ false }>
                    <input
                      id="title"
                      value={ title }
                      disabled={ disabled }
                      onChange={(e) => { this.updateValue(e, 'title') }}
                    />
                  </InputValidator>
                </div>

                <div className="questionnaireOptions__formMessage">
                  <div className="questionnaireOptions__messageButton">
                    <label htmlFor={ `hasMessage-${ order }-${ question.ref }` } className={ hasMessage ? 'questionnaireOptions__messageLabelSelected' : 'questionnaireOptions__messageLabel' }></label>
                    <input
                      id={ `hasMessage-${ order }-${ question.ref }` }
                      type="checkbox"
                      value={ hasMessage }
                      onChange={(e) => { this.updateCheckBox(e) }}
                    />
                  </div>
                </div>

                <div className="questionnaireOptions__formScore">
                  <InputValidator errors={errors} field="score" showErrorMessage={ false }>
                    <input
                      value={ score }
                      type="number"
                      pattern="[+-]?([0-9]*[.])?[0-9]+"
                      onChange={(e) => { this.updateValue(e, 'score') }}
                    />
                  </InputValidator>
                </div>

                <div className="questionnaireOptions__formJump">
                  <select
                    id="goToQuestion"
                    value={ goToQuestion }
                    onChange={ (e) => { this.updateJumpsToQuestion(e, question) }}
                  >
                    <option value="nextQuestion">Next Question</option>
                    <option value="toTheEnd">To the end</option>
                    { (question.type === 'Radio' || question.type === 'YN') &&
                      this.nextQuestionsList(question.order).map((nextQuestion) => (
                        <option key={`option-${ nextQuestion.order }`} value={ nextQuestion.ref }>{ this.goToQuestionTitle(nextQuestion) }</option>
                      ))
                    }
                  </select>
                </div>
              </React.Fragment>
            }

            {
              question.type !== 'YN' &&
              question.options.length > 2 &&
              <div
                onClick={(e) => { removeOption(index, option) }}
                className="questionnaireOptions__removeButton"></div>
            }

            {
              (question.type !== 'YN' && question.options.length <= 2) &&
              <div
                className="questionnaireOptions__removeButtonDisabled"></div>
            }
          </div>
          {
            hasMessage &&
            <div className="questionnaireOptions__justification">
              <input
                value={ message }
                onChange={(e) => { this.updateValue(e, 'message') }}
              />
            </div>
          }
        </ErrorMessages>
      </div>
    ) 
  }
}

QuestionOptionForm.propTypes = {
  question: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired,
  updateOption: PropTypes.func.isRequired,
  removeOption: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  option: PropTypes.object.isRequired
}

export default QuestionOptionForm