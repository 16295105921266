import React from 'react'
import './Duplicates.scss'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { Select, Tooltip, Input, Table, Checkbox, Button } from 'antd'
import {getListDuplicateFileBox, apiAutoDeduplicate, apiGetBoxDeduplicationStatus} from '../../../../api/SmartBox'
import {
  setSmartBoxCheckStatusDeduplication,
  setSmartBoxPerPageDuplicate,
  setSmartBoxResponseDeleteDuplicates
} from '../../../../actions/smartBoxActions'
import CustomNotificationManager from '../../../../components/Common/CustomNotificationManager'
import {customConfirm} from "../../../../utils/AssetRegister/CustomConfirm";
import cookie from "react-cookies";
const { Option } = Select
const { Search } = Input
const { Column } = Table
class DuplicatesTable extends React.Component {
  state = {
    pageLimitDisplay: 20,
    totalRecords: 0,
    filesPerPageLimit: 20,
    currentPage: 1,
    isPrevDisabled: true,
    isNextDisabled: false,
    inputPage: '',
    valueInputSearch: '',
    tableDuplicatesFile: [],
    statusOpenedRow: false,
    onHoverForth: false,
    onHoverStart: false,
    onHoverBack: false,
    onHoverEnd: false,
    valueSelectAuto: 'auto-deduplicate',
    selectedRowKeys: [],
    filesSelect: [],
    filesSelectRowsGlobal: [],
    filesSelectRows: [],
    nonSelectedRows: [],
    nonSelectedRowsIds: [],
    nonSelectedWords: [],
    valueAutoSelectedAll: false,
    disabledSearchDuplicate: false,
    searchInputStatus: false,
    checkSelectAll: false,
    checkStatusDeduplicates: '',
    setIntervalDeduplication: 0,
    statusPaginationDuplicate: false,
    loadingRemoveDuplicates: false,
    setIntervalListDuplicateFileBox: 0,
    answerListDuplicateFileBox: '',
    requestIdRandom: null,
    loadingListDuplicateFileBox: ''

  }
  componentDidMount() {
    this.setState({
      searchInputStatus: true,
    })
    this.props.setSmartBoxCheckStatusDeduplication(true)
    this.setState({
      filesPerPageLimit: this.props.smartBoxPerPageDuplicate,
      //filesPerPageLimit: 5,
      requestIdRandom: Math.floor(Math.random() * 100000) + 5
    }, () => {
      this.onGetDeduplicationStatus()
    })
    cookie.remove('checkListDuplicateFileBox', { path: '/' })
    cookie.remove('checkListNearFileBox', { path: '/' })
    cookie.remove('checkListClutter', { path: '/' })
  }
   componentDidUpdate(prevProps, prevState, snapshot) {
     if (prevProps.activeTab !== this.props.activeTab && this.props.activeTab === 'duplicates') {
       cookie.remove('checkListNearFileBox', { path: '/' })
       cookie.remove('checkListClutter', { path: '/' })
       this.setState({
         searchInputStatus: true,
       })
       this.props.setSmartBoxCheckStatusDeduplication(true)
       this.setState({
         filesPerPageLimit: this.props.smartBoxPerPageDuplicate,
         requestIdRandom: Math.floor(Math.random() * 100000) + 5
       }, () => {
         this.onGetDeduplicationStatus()
       })
     }
     if (prevProps.activeTab !== this.props.activeTab && this.props.activeTab !== 'duplicates') {
       this.setState({
         selectedRowKeys: [],
         filesSelect: [],
         filesSelectRowsGlobal: [],
         filesSelectRows: [],
         nonSelectedRows: [],
         nonSelectedRowsIds: [],
         nonSelectedWords: [],
         checkSelectAll: false,
       })
     }
   }

  onGetDeduplicationStatus = () => {
    const { setIntervalDeduplication } = this.state
    const { folder } = this.props
    apiGetBoxDeduplicationStatus(folder.id).then((response) => {
      if (response.status === 200) {
        cookie.save('checkDeduplicationStatus', 'getDeduplication', {path: '/'})
        this.setState({
          checkStatusDeduplicates: response.data
        })
        if (response.data === 'In Progress') {
          this.setIntervalDeduplicationStatus()
        } else if (response.data === 'Finished') {
          clearInterval(setIntervalDeduplication)
          this.setState({setIntervalDeduplication})
          this.onGetListDuplicateFileBox()
        }
      }
    })
  }
  setIntervalDeduplicationStatus = () => {
    const {  checkStatusDeduplicates } = this.state
    let { setIntervalDeduplication } = this.state
    if (setIntervalDeduplication === 0) {
      if (checkStatusDeduplicates === 'In Progress' && this.props.smartBoxCheckStatusDeduplication) {
        setIntervalDeduplication = setInterval(this.callBackDeduplicationStatus, 1000)
        this.setState({
          setIntervalDeduplication
        })
      } else {
        clearInterval(setIntervalDeduplication)
      }
    }
  }
  callBackDeduplicationStatus = () => {
    const { setIntervalDeduplication, checkStatusDeduplicates } = this.state
    const { folder } = this.props
    const itemDeduplication = cookie.load('checkDeduplicationStatus', { path: '/' })
    if (checkStatusDeduplicates === 'In Progress' && this.props.smartBoxCheckStatusDeduplication && itemDeduplication === 'getDeduplication') {
      apiGetBoxDeduplicationStatus(folder.id).then((response) => {
        if (response.data === 'Finished') {
          this.onGetListDuplicateFileBox()
          clearInterval(setIntervalDeduplication)
          this.setState({ setIntervalDeduplication })
        }  else {
          this.setState({ setIntervalDeduplication })
        }
        this.setState({
          checkStatusDeduplicates: response.data
        })
      })
    } else if (checkStatusDeduplicates === 'In Progress') {
      clearInterval(setIntervalDeduplication)
      this.setState({ setIntervalDeduplication })
    }
  }
  onGetListDuplicateFileBox = () => {
    const { filesPerPageLimit, currentPage, valueInputSearch, checkSelectAll, setIntervalListDuplicateFileBox, requestIdRandom  } = this.state
    const { folder } = this.props
    let parentFolderName = ''
    if (typeof folder.relativePath !== 'undefined') {
      parentFolderName = folder.relativePath.split('/')[1]
    }
    const indexDestinationParent = folder && folder.path ? folder.path.indexOf(parentFolderName) : ''
    const newDestinationParent = folder && folder.path ? folder.path.substr(0, indexDestinationParent) : ''
    const rootPathParent = `${ newDestinationParent }${ parentFolderName }`
      const requestDuplicateFileBoxDTO = {
        clientId: this.props.clientId,
        folderPath: rootPathParent,
        pageNr: currentPage,
        rows: filesPerPageLimit,
        searchWord: valueInputSearch ? valueInputSearch : null,
        requestId: requestIdRandom ? requestIdRandom : null
    }
    getListDuplicateFileBox(requestDuplicateFileBoxDTO).then((response) => {
      if (response.status === 200) {
        cookie.save('checkListDuplicateFileBox', rootPathParent, {path: '/'})
        this.setState({
          answerListDuplicateFileBox: response.data,
          //answerListDuplicateFileBox: 'processing',
          loadingListDuplicateFileBox: response.data,
        })

      if(response.data.title && response.data.title.toLowerCase() === 'info') {
          CustomNotificationManager.info(response.data.message, 'Info')
          this.setIntervalGetListDuplicateFileBox()
        }
      // else {
      //      this.setIntervalGetListDuplicateFileBox()
      //   }

        // if (response.data === 'Processing in progress, this may take several minutes.') {
        //
        //   CustomNotificationManager.info('Processing in progress, this may take several minutes.')
        //   this.setState({
        //     tableDuplicatesFile: [],
        //     totalRecords: 0,
        //     disabledSearchDuplicate: true,
        //     searchInputStatus: true
        //   })
        // }  else  if(response.data === 'processing') {
        //    //this.setIntervalGetListDuplicateFileBox()
        // }

          if (response.data && response.data.dups && response.data.dups.length > 0) {
            this.setState({
              disabledSearchDuplicate: false,
              requestIdRandom: Math.floor(Math.random() * 100000) + 5,
              loadingRemoveDuplicates: false,
            })
            clearInterval(setIntervalListDuplicateFileBox)
            this.setState({setIntervalListDuplicateFileBox})
          } else {
            this.setState({
              disabledSearchDuplicate: true
            })
          }
          response.data.dups && response.data.dups.length > 0 && response.data.dups.forEach((item, index) => {
            item.key = index
          })
          this.setState({
            tableDuplicatesFile: response.data.dups,
            totalRecords: response.data.totalRows,
            searchInputStatus: false
          }, () => {
            this.updatePreviewNextPageNumber()
          })


        if (checkSelectAll) {
          const newKeysSelected = []
          response.data.dups && response.data.dups.length > 0 && response.data.dups.forEach((list, index) => {
            newKeysSelected.push(index)
          })
          this.setState({
            selectedRowKeys: newKeysSelected
          })
        } else {
          // this.setState({
          //   selectedRowKeys: []
          // })
        }
        this.setState({
          statusPaginationDuplicate: false
        })
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  setIntervalGetListDuplicateFileBox = () => {
    let { setIntervalListDuplicateFileBox } = this.state
    const { answerListDuplicateFileBox } = this.state
    if (setIntervalListDuplicateFileBox === 0) {
      if (answerListDuplicateFileBox.title && answerListDuplicateFileBox.title === 'Info') {
        setIntervalListDuplicateFileBox = setInterval(this.callBackGetListDuplicateFileBox, 5000)
        this.setState({
          setIntervalListDuplicateFileBox
        })
      } else {
        clearInterval(setIntervalListDuplicateFileBox)
      }
    }
  }

  callBackGetListDuplicateFileBox = () => {
    const { filesPerPageLimit, currentPage, valueInputSearch, checkSelectAll, answerListDuplicateFileBox, requestIdRandom,
      setIntervalListDuplicateFileBox } = this.state
    const { folder } = this.props
    let parentFolderName = ''
    if (typeof folder.relativePath !== 'undefined') {
      parentFolderName = folder.relativePath.split('/')[1]
    }
    const indexDestinationParent = folder && folder.path ? folder.path.indexOf(parentFolderName) : ''
    const newDestinationParent = folder && folder.path ? folder.path.substr(0, indexDestinationParent) : ''
    const rootPathParent = `${ newDestinationParent }${ parentFolderName }`
    const requestDuplicateFileBoxDTO = {
      clientId: this.props.clientId,
      folderPath: rootPathParent,
      pageNr: currentPage,
      rows: filesPerPageLimit,
      searchWord: valueInputSearch ? valueInputSearch : null,
      requestId: requestIdRandom ? requestIdRandom : null
    }
    const itemDeduplicationList = cookie.load('checkListDuplicateFileBox', { path: '/' })
    if (answerListDuplicateFileBox.title && answerListDuplicateFileBox.title === 'Info' && (rootPathParent && itemDeduplicationList === rootPathParent)) {
      getListDuplicateFileBox(requestDuplicateFileBoxDTO).then((response) => {
        if (response.status === 200) {
          this.setState({
            answerListDuplicateFileBox: response.data,
            //answerListDuplicateFileBox: 'processing',
            loadingListDuplicateFileBox: response.data,
          })
          if (response.data === 'Processing in progress, this may take several minutes.') {

            CustomNotificationManager.info('Processing in progress, this may take several minutes.')
            this.setState({
              tableDuplicatesFile: [],
              totalRecords: 0,
              disabledSearchDuplicate: true,
              searchInputStatus: true
            })
          } else if (response.data && response.data.dups && response.data.dups.length > 0) {
            this.setState({
              disabledSearchDuplicate: false,
              requestIdRandom: Math.floor(Math.random() * 100000) + 5,
              loadingRemoveDuplicates: false,
            })
            response.data.dups && response.data.dups.length > 0 && response.data.dups.forEach((item, index) => {
              item.key = index
            })
            this.setState({
              tableDuplicatesFile: response.data.dups,
              totalRecords: response.data.totalRows,
              searchInputStatus: false
            }, () => {
              this.updatePreviewNextPageNumber()
              cookie.remove('checkListDuplicateFileBox', { path: '/' })
            })
            // clearInterval(setIntervalListDuplicateFileBox)
            // this.setState({setIntervalListDuplicateFileBox})
          } else {
            this.setState({
              disabledSearchDuplicate: true
            })
          }
        if (response.data.dups && response.data.dups.length === 0) {
          cookie.remove('checkListDuplicateFileBox', { path: '/' })
        }
          if (checkSelectAll) {
            const newKeysSelected = []
            response.data.dups && response.data.dups.length > 0 && response.data.dups.forEach((list, index) => {
              newKeysSelected.push(index)
            })
            this.setState({
              selectedRowKeys: newKeysSelected
            })
          } else {
            this.setState({
              selectedRowKeys: []
            })
          }
          this.setState({
            statusPaginationDuplicate: false
          })
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        } else {
          console.log(error)
        }
      })
    }
  }

  updatePreviewNextPageNumber = () => {
    const { totalRecords, currentPage } = this.state
    const totalPages = Math.ceil(totalRecords / this.state.filesPerPageLimit)
    const isPrevDisabled = currentPage > 1 ? false : true
    const isNextDisabled = currentPage < totalPages ? false : true
    this.setState({ isPrevDisabled, isNextDisabled, currentPage }, () => {
      this.onCheckSelectedRows()
    })
  }
  gotoPage = (newPage) => {
    const { totalRecords, filesPerPageLimit } = this.state
    const totalPages = Math.ceil(totalRecords / filesPerPageLimit)
    newPage = Math.max(0, Math.min(newPage, totalPages))
    this.setState({ currentPage: newPage })
  }
  handleMovePage = (e, direction) => {
    const { isPrevDisabled, isNextDisabled, totalRecords, filesPerPageLimit, statusPaginationDuplicate } = this.state
    switch (direction) {
      case 'first':
        e.preventDefault()
        if (!isPrevDisabled && !statusPaginationDuplicate) {
          this.setState({
            statusPaginationDuplicate: true
          }, () => {
            this.gotoPage(1)
          })
        }
        break
      case 'previous':
        e.preventDefault()
        if (!isPrevDisabled && !statusPaginationDuplicate) {
          this.setState({
            statusPaginationDuplicate: true
          }, () => {
            this.gotoPage(this.state.currentPage - 1 ? this.state.currentPage - 1 : 1)
          })
        }
        break
      case 'next':
        e.preventDefault()
        if (!isNextDisabled && !statusPaginationDuplicate) {
          this.setState({
            statusPaginationDuplicate: true
          }, () => {
            this.gotoPage(this.state.currentPage + 1 > Math.ceil(totalRecords / filesPerPageLimit) ? Math.ceil(totalRecords / filesPerPageLimit) : this.state.currentPage + 1)
          })
        }
        break
      case 'last':
        e.preventDefault()
        if (!isNextDisabled && !statusPaginationDuplicate) {
          this.setState({
            statusPaginationDuplicate: true
          }, () => {
            this.gotoPage(Math.ceil(totalRecords/filesPerPageLimit))
          })
        }
        break
      default:
        e.preventDefault()
        this.setState({ showInsertPageNumber: true })
    }
  }
  onSearchDuplicates = (e) => {
    const { tableDuplicatesFile } = this.state
    this.setState({
      valueInputSearch: e
    }, () => {
      if (e.length > 0) {
        if (tableDuplicatesFile && tableDuplicatesFile.length > 0) {
          this.onGetListDuplicateFileBox()
        }
      }
      if (e === '') {
          this.onGetListDuplicateFileBox()
      }
    })
  }
  onChangeSearchDuplicates = (e) => {
    const { tableDuplicatesFile } = this.state
    this.setState({
      valueInputSearch: e
    }, () => {
        if (tableDuplicatesFile && tableDuplicatesFile.length > 0) {
          this.onGetListDuplicateFileBox()
        }
    })
  }
  onMouseEnterOnPanel(e, status) {
    switch (status) {
      case 'forth' :
        this.setState({
          onHoverForth: true
        })
        break
      case 'start' :
        this.setState({
          onHoverStart: true
        })
        break
      case 'back' :
        this.setState({
          onHoverBack: true
        })
        break
      case 'end' :
        this.setState({
          onHoverEnd: true
        })
        break
      default: break
    }
  }
  onMouseLeaveOnPanel(e, status) {
    this.setState({
      onHoverForth: false,
      onHoverStart: false,
      onHoverBack: false,
      onHoverEnd: false
    })

  }
  onSelectedRowDuplicates = (e, record) => {
    this.setState({
      statusOpenedRow: true
    })
    this.props.onVisibleDuplicatesList(true)
    this.props.selectFileDuplicateBox(record)
  }
  changeFilesPerPageLimit = (e) => {
    const { tableDuplicatesFile } = this.state
    this.props.setSmartBoxPerPageDuplicate(e)
    this.setState({ filesPerPageLimit: e }, () => {
      if (tableDuplicatesFile && tableDuplicatesFile.length > 0) {
        this.onGetListDuplicateFileBox()
      }
    })
  }
  handleInputPageEnter = (e) => {
    if (e.key === 'Enter') {
      this.handleInputPageBlur()
    }
  }

  handleInputPageBlur = () => {
    const { inputPage } = this.state
    if (inputPage) {
      const paginationEnd = Math.ceil(this.state.totalRecords / this.state.filesPerPageLimit)
      if (inputPage > 0 && inputPage <= paginationEnd) {
        this.setState({
          statusPaginationDuplicate: true
        }, () => {
          this.gotoPage(inputPage)
        })
      } else {
        this.setState({
          statusPaginationDuplicate: true
        }, () => {
          this.gotoPage(1)
        })
        this.setState({ inputPage: 1 })
      }
    }
  }
  gotoPage = (newPage) => {
    const { totalRecords, filesPerPageLimit, tableDuplicatesFile } = this.state
    const totalPages = Math.ceil(totalRecords / filesPerPageLimit)
    newPage = Math.max(0, Math.min(newPage, totalPages))
    if (tableDuplicatesFile && tableDuplicatesFile.length > 0) {
      this.setState({ currentPage: newPage }, () => {
        this.onGetListDuplicateFileBox()
      })
    }
  }
  handleChange = (value) => {
  }

  onCheckSelectedRows = () => {
    const { tableDuplicatesFile, filesSelectRows, checkSelectAll, nonSelectedRows, filesSelectRowsGlobal } = this.state
    const updateKeys = []
    if (checkSelectAll) {
      tableDuplicatesFile && tableDuplicatesFile.length > 0 && tableDuplicatesFile.forEach((list, index) => {
        filesSelectRowsGlobal && filesSelectRowsGlobal.length > 0 && filesSelectRowsGlobal.forEach((list2, index2) => {
          if (nonSelectedRows && nonSelectedRows.length > 0) {
            if (!nonSelectedRows.includes(list.hash)) {
              updateKeys.push(index)
            }
          } else {
            updateKeys.push(index)
          }
        })
      })
    } else {
      tableDuplicatesFile && tableDuplicatesFile.length > 0 && tableDuplicatesFile.forEach((list, index) => {
        filesSelectRowsGlobal && filesSelectRowsGlobal.length > 0 && filesSelectRowsGlobal.forEach((list2, index2) => {
          if (list.hash === list2.hash) {
            updateKeys.push(index)
          }
        })
      })
    }
    const uniqueUpdateKeys = [...new Set(updateKeys)]
    this.setState({
      selectedRowKeys: uniqueUpdateKeys
    })
  }

  onSelectDeduplicate = (selectedRowKeys, filesSelect, e) => {
    const { tableDuplicatesFile } = this.state

    const nonSelected = []
    const nonSelectedIds = []
    const nameFilesSelected = []
    filesSelect && filesSelect.length > 0 && filesSelect.forEach((error) => {
      nameFilesSelected.push(error.hash)
    })

    let filesSelectedGlobal = this.state.filesSelectRowsGlobal.concat(filesSelect)
    let filesSelected = this.state.filesSelectRows.concat(filesSelect)
    tableDuplicatesFile && tableDuplicatesFile.length > 0 && tableDuplicatesFile.forEach((error) => {
      if(!nameFilesSelected.includes(error.hash)) {
        nonSelected.push(error.hash)
        nonSelectedIds.push(error.hash)
      }
    })
    this.state.filesSelectRowsGlobal && this.state.filesSelectRowsGlobal.length > 0 && this.state.filesSelectRowsGlobal.forEach((error) => {
      if(nonSelected.includes(error.hash)) {
        filesSelectedGlobal = this.state.filesSelectRowsGlobal.filter((el) => el.hash !== error.hash)
        this.setState({
          filesSelectRowsGlobal: filesSelectedGlobal
        })
      }
    })

    const otherFileSelected = []
    this.state.filesSelectRows && this.state.filesSelectRows.length > 0 && this.state.filesSelectRows.forEach((error) => {
      if(nonSelected.includes(error.hash)) {
        filesSelected = this.state.filesSelectRows.filter((el) => el.hash !== error.hash)
      }
    })

    const uniqueFilesSelectedGlobal = filesSelectedGlobal && filesSelectedGlobal.length > 0 && filesSelectedGlobal.filter((a, i) => filesSelectedGlobal.findIndex((s) => a.hash === s.hash) === i)
    const uniqueFilesSelected = filesSelected && filesSelected.length > 0 && filesSelected.filter((a, i) => filesSelected.findIndex((s) => a.hash === s.hash) === i)

    this.setState({
      nonSelectedRows: [...new Set(this.state.nonSelectedRows.concat(nonSelected))],
      nonSelectedRowsIds: [...new Set(this.state.nonSelectedRowsIds.concat(nonSelectedIds))],
    })
    this.setState({
      //selectedRowKeys: selectedRowKeys,
      filesSelectRows: uniqueFilesSelected === false ? [] : uniqueFilesSelected,
      filesSelectRowsGlobal: uniqueFilesSelectedGlobal === false ? [] : uniqueFilesSelectedGlobal
    })

    this.setState({ 
      selectedRowKeys: selectedRowKeys,
      filesSelect: filesSelect })
    if (filesSelect && filesSelect.length > 0) {
      this.setState({
        valueAutoSelectedAll: true
      })
    } else {
      this.setState({
        valueAutoSelectedAll: false
      })
    }
    if (tableDuplicatesFile.length > selectedRowKeys.length) {
      this.setState({
        checkSelectAll: false
      })
    }
  }
  autoSelectedAll = (e) => {
    const { tableDuplicatesFile } = this.state
    const selectAllFiles = []
    const selectedAllKeys = []
    if (e.target.checked) {
      tableDuplicatesFile && tableDuplicatesFile.length > 0 && tableDuplicatesFile.forEach((file, index) => {
        file.key = index
        selectedAllKeys.push(index)
        selectAllFiles.push(file)
      })
      this.setState({
        selectedRowKeys: selectedAllKeys,
        checkSelectAll: true,
        filesSelect: selectAllFiles }
      //     , () => {
      //   this.onGetListDuplicateFileBox()
      // }
      )
    } else {
      this.setState({
        selectedRowKeys: [],
        checkSelectAll: false,
        filesSelect: [] })
    }

    if (tableDuplicatesFile && tableDuplicatesFile.length > 0) {
      this.setState({
        valueAutoSelectedAll: e.target.checked
      })
    } else {
      this.setState({
        valueAutoSelectedAll: false
      })
    }
  }
  handleInputPageChange = (e) => this.setState({ inputPage: e.target.value })

  handleRemoveDuplicates = (e) => {
    e.preventDefault()
    const {filesSelect, checkSelectAll, tableDuplicatesFile} = this.state
    customConfirm({
      title: <span className="icon-v14-delete"></span>,
      content: `Remove ${ filesSelect.length > 1 && filesSelect.length === tableDuplicatesFile.length ? 'all' : filesSelect.length } ${ filesSelect.length > 1 ? 'duplicates' : 'duplicate' } ?`,
      confirmButtonText: 'Remove',
      cancelButtonText: 'Cancel',
      mask: true,
      maskClosable: false,
      closeHaveLogic: false,
      className: 'asset-register-modal modal-delete-duplicate confirm-remove-duplicates',
      centered: true,
      cancelClassName: 'cancel-button',
      confirmClassName: 'confirm-button',
      confirmAction: () => {
        this.onClickAutoDeduplicateGo()
      }
    })
  }

  onClickAutoDeduplicateGo = () => {
    const { filesSelect, checkSelectAll, currentPage, filesPerPageLimit, valueInputSearch, tableDuplicatesFile, filesSelectRowsGlobal } = this.state
    const { folder, clientId } = this.props
    const hashes = []
    filesSelectRowsGlobal && filesSelectRowsGlobal.length > 0 && filesSelectRowsGlobal.forEach((file) => {
      hashes.push(file.hash)
    })
    let parentFolderName = ''
    if (typeof folder.relativePath !== 'undefined') {
      parentFolderName = folder.relativePath.split('/')[1]
    }
    const indexDestinationParent = folder && folder.path ? folder.path.indexOf(parentFolderName) : ''
    const newDestinationParent = folder && folder.path ? folder.path.substr(0, indexDestinationParent) : ''
    const rootPathParent = `${ newDestinationParent }${ parentFolderName }`
    let autoDedupingRequestDTO = {}
    if (checkSelectAll) {
      autoDedupingRequestDTO = {
        clientId: clientId,
        folderPath: rootPathParent,
        pageNr: currentPage,
        rows: filesPerPageLimit,
        searchWord: valueInputSearch ? valueInputSearch : null,
        selectAll: true,
        hashes: null,
        boxId: folder.id
      }
    }  else {
        autoDedupingRequestDTO = {
          clientId: clientId,
          folderPath: rootPathParent,
          hashes: hashes,
          boxId: folder.id
        }
    }
    this.setState({
      //statusAutoDeduplicate: true
    })
    const newTable = []
    const newTableKeys = []
    this.props.setSmartBoxResponseDeleteDuplicates(false, true)
    this.setState({
      loadingRemoveDuplicates: true
    }, () => {
      apiAutoDeduplicate(autoDedupingRequestDTO).then((response) => {
        if (response.status === 200) {
          this.onGetDeduplicationStatus()
          this.setState({
            statusAutoDeduplicate: false,
            //filesSelect: [],
            //selectedRowKeys: [],
            //valueAutoSelectedAll: false
          }, () => {
            // this.setState({
            //   loadingRemoveDuplicates: false
            // })
          })
          this.props.setSmartBoxResponseDeleteDuplicates(response.data, false)
          if (tableDuplicatesFile.length === filesSelect.length) {
            this.setState({
              tableDuplicatesFile: [],
              valueAutoSelectedAll: false
            })
          } else {
            filesSelect && filesSelect.length > 0 && filesSelect.forEach((file) => {
              newTableKeys.push(file.fileName)
            })
            tableDuplicatesFile && tableDuplicatesFile.length > 0 && tableDuplicatesFile.forEach((table) => {
              if (!newTableKeys.includes(table.fileName)) {
                newTable.push(table)
              }
            })
            this.setState({
              tableDuplicatesFile: newTable,
              selectedRowKeys: [],
              filesSelect: []
            })
            //this.onGetListDuplicateFileBox()
          }
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    })
  }
  render() {
    const { totalRecords, filesPerPageLimit, currentPage, isPrevDisabled, isNextDisabled, inputPage, tableDuplicatesFile, onHoverForth,
      onHoverStart, onHoverBack, onHoverEnd, valueInputSearch, selectedRowKeys, filesSelect, statusAutoDeduplicate, loadingListDuplicateFileBox} = this.state
    const { valueAutoSelectedAll, searchInputStatus, checkSelectAll, checkStatusDeduplicates, statusPaginationDuplicate, loadingRemoveDuplicates } = this.state
    const totalPages = Math.ceil(totalRecords / filesPerPageLimit)
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectDeduplicate,
    }
    return (
      <div className={ statusAutoDeduplicate ? 'duplicates-table duplicates-table-deleted' : 'duplicates-table' }>
        <div className="duplicates-table__content">
          <div className="content-actions-deduplicate">
            <div className="button-auto-go">
              <Button loading={ loadingRemoveDuplicates } className="button-blue-go" onClick={(e) => this.handleRemoveDuplicates(e) }
                disabled={ (loadingListDuplicateFileBox.title && loadingListDuplicateFileBox.title === 'Info') || filesSelect.length === 0 || !valueAutoSelectedAll || typeof tableDuplicatesFile === 'undefined' || tableDuplicatesFile.length === 0 }>
                  Remove duplicates
              </Button>
            </div>
          </div>
          <div className="duplicates-table-header duplicates-table-header-update">
            <div className="content-select-all-duplicates">
              <div className={ tableDuplicatesFile && tableDuplicatesFile.length > 0 ? '' : filesSelect.length === 0 ? 'checkbox-auto-disabled' : '' }>
                <div className={ checkSelectAll ? "checkbox-auto" : !checkSelectAll && selectedRowKeys.length > 0 ? 'select-non-checkbox' : 'checkbox-auto' }>
                <Checkbox checked={ !checkSelectAll && filesSelect.length === 0 ? false : checkSelectAll }
                          className="select-deduplicate-checkbox"
                          onChange={(e) => this.autoSelectedAll(e)}></Checkbox>
                  </div>
              </div>
              <div className="select-auto">
                <span className={ typeof tableDuplicatesFile === 'undefined' || tableDuplicatesFile.length === 0 ? 'option-select-all-disabled' : '' }>Select all</span>
              </div>
            </div>
            <div className={ tableDuplicatesFile.length === 0 ? "per-page-duplicates per-page-duplicates-table disabled" : "per-page-duplicates per-page-duplicates-table"}>
              {/*<span className="show-duplicate">Show</span>*/}
              <Select dropdownClassName="select-per-page-duplicate per-duplicate"
                      value={'Show'+ ' '+ `${filesPerPageLimit}`}
                      disabled={tableDuplicatesFile.length === 0}
                      //value={<span><span>{ `Show ${ filesPerPageLimit }` } </span><span className="icon-v19-arrow-medium"></span></span> }
                      onChange={ (e) => this.changeFilesPerPageLimit(e) }>
                <Option value="20">20</Option>
                <Option value="50">50</Option>
                <Option value="100">100</Option>
              </Select>
              {/*<span className="icon-v19-arrow-medium"></span>*/}
            </div>
            <div className="buttons-control-pagination">
              <Tooltip title="first" className={isPrevDisabled ? 'disabled' : ''}>
                <span className={ tableDuplicatesFile && tableDuplicatesFile.length === 0 ? 'icon-v14-start-1 disabled-pagination' :
                    statusPaginationDuplicate ? 'icon-v14-start-1 disabled-back' : isPrevDisabled ? 'icon-v14-start-1 disabled-back' : onHoverStart ? 'icon-v14-start-active' : 'icon-v14-start-1' }
                  onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'start') }
                  onMouseLeave={ () => this.onMouseLeaveOnPanel() }
                  onClick={ (e) => this.handleMovePage(e, 'first')}/>
              </Tooltip>
              <Tooltip title="previous" className={isPrevDisabled ? 'disabled' : ''}>
                <span className={ tableDuplicatesFile && tableDuplicatesFile.length === 0 ? 'icon-v14-back-1 disabled-pagination' :
                    statusPaginationDuplicate ? 'icon-v14-back-1 disabled-back' : isPrevDisabled ? 'icon-v14-back-1 disabled-back' : onHoverBack ? 'icon-v21-back-active' : 'icon-v14-back-1' }
                  onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'back') }
                  onMouseLeave={ () => this.onMouseLeaveOnPanel() }
                  onClick={ (e) => this.handleMovePage(e, 'previous')}/>
              </Tooltip>
              { totalPages === 0 &&
              <span className='buttons-control-pagination__text disabled-text'>{1} of {1} </span>
              }
              { totalPages === 1 &&
              <span className='buttons-control-pagination__text'>{currentPage} of {totalPages} </span>
              }
              { totalPages > 1 &&
              <span className='buttons-control-pagination__text'>
                <input
                  type="number"
                  className="insert-page"
                  placeholder= { currentPage }
                  value= { inputPage }
                  min="1"
                  disabled={ statusPaginationDuplicate === true }
                  max={totalPages}
                  onChange={ (e) => this.handleInputPageChange(e) }
                  onBlur={ () => this.handleInputPageBlur() }
                  onKeyDown={ (e) => this.handleInputPageEnter(e) }
                />
                <span>of {totalPages}</span>
              </span>
              }
              <Tooltip title="next" className={isNextDisabled ? 'disabled' : ''}>
                <span className={ tableDuplicatesFile && tableDuplicatesFile.length === 0 ? 'icon-v14-forth-1 disabled-pagination' :
                    statusPaginationDuplicate ? 'icon-v14-forth-1 disabled-next' : isNextDisabled ? 'icon-v14-forth-1 disabled-next' : onHoverForth ? 'icon-v21-forth-active' : 'icon-v14-forth-1' }
                  onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'forth') }
                  onMouseLeave={ () => this.onMouseLeaveOnPanel() }
                  onClick={ (e) => this.handleMovePage(e, 'next')}/>
              </Tooltip>
              <Tooltip title="last" className={ isNextDisabled ? 'disabled' : '' }>
                <span className={tableDuplicatesFile && tableDuplicatesFile.length === 0 ? 'icon-v14-end-1 disabled-pagination' :
                    statusPaginationDuplicate ? 'icon-v14-end-1 disabled-next' : isNextDisabled ? 'icon-v14-end-1 disabled-next' : onHoverEnd ? 'icon-v14-end-active' : 'icon-v14-end-1' }
                  onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'end') }
                  onMouseLeave={ () => this.onMouseLeaveOnPanel() }
                  onClick={ (e) => this.handleMovePage(e, 'last')}/>
              </Tooltip>
            </div>
            <div className="input-search-duplicates input-search-duplicates-update">
              <Search defaultValue={ valueInputSearch } className="search-duplicates" placeholder="Search"
                      allowClear
                      disabled={ searchInputStatus === true }
                //onChange={(e) => this.onChangeSearchDuplicates(e)}
                onSearch={this.onSearchDuplicates}
                style={{ width: 200 }} />
            </div>
          </div>
          <div className="table-duplicates-content">
            <Table className="table-duplicates-files"
              dataSource={ tableDuplicatesFile }
              pagination={ false }
              showHeader={ false }
                   loading={ checkStatusDeduplicates === 'In Progress'}
              rowSelection={ rowSelection }
              onRow={(record, rowIndex) => {
                return {
                  onClick: (e) => this.onSelectedRowDuplicates(e, record)
                }
              }}>
              <Column className="column-name-duplicate" title="Name" dataIndex="fileName" key="fileName" render={(text, record) => {
                return (
                  <span key={ record.hash } className="name-duplicate-content">
                    {/*<span className={ record.iconType === 'empty' ? 'icon-v14-star' : record.iconType === 'gold' ? 'icon-v14-star-active' : 'icon-v14-star' }></span>*/}
                    <span className="text-style">{text}</span>
                  </span>
                )
              }}/>
              <Column className="total-files" title="Total duplicates" dataIndex="hash" key="hash"render={(text, record) => {
                return (
                  <span key={ record.hash } className="total-files-content">
                    <span className="number-duplicate"><span className="nr-dupl">{ record.numberOfDuplicates }</span></span>
                    <span className="text-style icon-v16-arrow-medium rotate-arrow-medium"></span>
                  </span>
                )
              }}/>
            </Table>
          </div>
        </div>
      </div>
    )

  }
}

DuplicatesTable.propTypes = {
  folder: PropTypes.object,
}

const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id,
  smartBoxPerPageDuplicate: state.smartBox.smartBoxPerPageDuplicate,
  smartBoxCheckStatusDeduplication: state.smartBox.smartBoxCheckStatusDeduplication,
})
export default compose(
  connect(mapStateToProps, {
    setSmartBoxResponseDeleteDuplicates,
    setSmartBoxPerPageDuplicate,
    setSmartBoxCheckStatusDeduplication
  }),
)(DuplicatesTable)