import axios from 'axios'
import { objectWithChildArraysToFormData } from '../utils/FormDataBuilder'

const isLocalhost = () => {
  return window.location.hostname === 'localhost'
}
const host = isLocalhost() ? 'http://localhost:8080' : ''

export const saveLibrary = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/osprey/doc-locker/`,
    data: data
  })
}

export const updateLibrary = (data, id) => {
  return axios({
    method: 'put',
    url: `${ host }/osprey-0.1.0/v1/osprey/doc-locker/${ id }`,
    data: data
  })
}

export const getAllLibrariesByClientId = (clientId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/osprey/doc-locker/client/${ clientId }`
  })
}

export const deleteLibrary = (id) => {
  return axios({
    method: 'delete',
    url: `${ host }/osprey-0.1.0/v1/osprey/doc-locker/${ id }`
  })
}

export const getAllFieldNamesByClientIdForDocLockerApp = (clientId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/osprey/doc-locker/field-names/client/${ clientId }`
  })
}

export const removeFieldNameForDocLockerApp = (id) => {
  return axios({
    method: 'delete',
    url: `${ host }/osprey-0.1.0/v1/osprey/doc-locker/field-names/remove/${ id }`
  })
}

export const addFieldNameForDocLockerApp = (fieldNameObject) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/osprey/doc-locker/field-names/add`,
    data: fieldNameObject
  })
}

export const getAllData = (clientId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/osprey/doc-locker/compliance-document/client/${ clientId }`
  })
}

export const createComplianceDocument = (complianceDocument) => {
  complianceDocument.id = complianceDocument.library.id
  complianceDocument.library = {}
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/osprey/doc-locker/compliance-document/create`,
    data: objectWithChildArraysToFormData(complianceDocument)
  })
}

export const findComplianceDocument = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/osprey/doc-locker/find`,
    data
  })
}

export const getAllColumnNames = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/osprey/doc-locker/get-column-names`
  })
}

export const getHistoryUpdateData = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/osprey/doc-locker/get-expand-data/${ id }`
  })
}
export const getDocumentBytes = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/osprey/doc-locker/get-history-bytes/${ id }`
  })
}
export const getOriginalDocumentBytes = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/osprey/doc-locker/get-history-bytes/original/${ id }`
  })
}

export const deleteComplianceDocument = (id) => {
  return axios({
    method: 'delete',
    url: `${ host }/osprey-0.1.0/v1/osprey/doc-locker/compliance-document/${ id }`
  })
}

export const updateComplianceDocument = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/osprey/doc-locker/expand-data/add`,
    data: objectWithChildArraysToFormData(data)
  })
}

