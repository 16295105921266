import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { connect } from 'react-redux'
import CustomNotificationManager from '../components/Common/CustomNotificationManager'

const DocLockerPrivateRouter = ({ component: Component, ...rest }) => {
  if (rest.isLoggedIn) {
    const found = rest.docLockerVisible
    if (found) {
      return <Outlet {...rest}/>
    }
    // CustomNotificationManager.info('Access restricted', 'Unauthorized')
    return <Navigate to="/smart-box/management"/>
  }
  return <Navigate to="/osp/login"/>
}


const mapStateToProps = (state) => ({
  isLoggedIn: state.userReducer.isLogged,
  docLockerVisible: state.applications.docLockerVisible
})

export default connect(mapStateToProps, {})(DocLockerPrivateRouter)