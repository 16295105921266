import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import '../FormEmployeeWorkflow.scss'
import {Checkbox, Col, Icon, Input, Radio, Select, Steps, Upload} from "antd";
const { Option } = Select;
const { Step } = Steps;
import SignatureCanvas from "react-signature-canvas";

export class SignatureTemplateForm extends Component {
    constructor() {
        super()
        this.state = {
        }
        this.handleClearSignature = this.handleClearSignature.bind(this)
        this.signatureCanvas = React.createRef()
    }
    componentDidMount() {
        // const { component } = this.props
        // let ssss = this.signatureCanvas.toDataURL()
        // ssss = component.signature
    }

    handleClearSignature() {
        const { signatureForm } = this.state
        const { component } = this.props
        this.signatureCanvas.clear()
        this.props.onChangeNameAndDetails('', 'signature', component)
    }
    handleAddSignature = () => {
        const { component } = this.props
        const { signatureForm } = this.state
        //signatureForm.signature = this.signatureCanvas.toDataURL()
        this.setState({

        }, () => {
            this.props.onChangeNameAndDetails(this.signatureCanvas.toDataURL(), 'signature', component)
            this.signatureCanvas.clear()
        })
    }
    onChangeNameAndDetails = (e, key) => {
        switch (key) {
            case 'signature':
                // this.props.onChangeNameAndDetails(e, key)
                break
            default:
                break
        }
    }

    render() {
        const { formerEmployee, component, stepTicketActive } = this.props
        return (
            <div className="full-name-former">
                <span className="header-former header-former-address"> {component.header && component.mandatory ? `${component.header}*` :
                    component.header ? component.header : 'Signature'} </span>
                {component.signature &&
                < img id='signature_image' className="signature_image" src={component.signature || ''}
                      alt="signature"/>
                }
                <div className="signature-content">
                    <SignatureCanvas
                       ref={(ref) => {
                           this.signatureCanvas = ref}}
                       penColor='#000'
                       disabled={stepTicketActive.blockFormEdit === true}
                       canvasProps={{ className: 'signature' }}
                    />
                     <button type="button" className="button-small-red-empty float-right"
                             disabled={stepTicketActive.blockFormEdit === true}
                             onClick={this.handleClearSignature}>Clear
                        </button>
                        <button type="button" className="button-add-signature float-right"
                                disabled={stepTicketActive.blockFormEdit === true}
                                onClick={(e) => this.handleAddSignature(e)}>Add
                        </button>
                </div>
            </div>
        )
    }
}
SignatureTemplateForm.propTypes = {
    folder: PropTypes.object,
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id
})
export default compose(
    connect(mapStateToProps),
)(SignatureTemplateForm)