import React from 'react'
import PropTypes from 'prop-types'

import { ProcessConsumer } from '../../../../contexts/Processes'

const WithProcesses = (Component) => {
  return class WithProcesses extends React.Component {
    render = () => {
      return (
        <ProcessConsumer>
          { ({ getProcesses, ...rest }) => (
            <InitProcesses getProcesses={ getProcesses }>
              <Component {...this.props} {...rest} />
            </InitProcesses>
          )}
        </ProcessConsumer>
      )
    }
  }
}

class InitProcesses extends React.Component {
  componentDidMount = () => {
    this.props.getProcesses()
  }
  
  render = () => {
    return (
      this.props.children
    )
  }
}

InitProcesses.propTypes = {
  children: PropTypes.element.isRequired,
  getProcesses: PropTypes.func.isRequired
}

export default WithProcesses