/*eslint no-unused-vars: 0*/

import FabricCanvasTool from './fabrictool'

class Select extends FabricCanvasTool {

  configureCanvas(props) {
    let canvas = this._canvas;
    canvas.isDrawingMode = false;
    canvas.selection = true;
    canvas.forEachObject((o) => {
      //o.selectable = o.evented = true;
      if (props.isOcr) {
        if (o.fill && (o.fill === 'rgba(0,0,0,0.3)' || o.fill === 'rgba(0, 0, 0, 1)' || o.fill === 'rgba(0, 0, 0, 0.4)' || o.fill === 'rgba(0, 0, 0, 0.3)')) {
          o.selectable = o.evented = true;
        }
      } else {
        o.selectable = o.evented = true;
      }
    });
  }
}

export default Select