import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { apiPrivacyFetchToken, apiPrivacyAuthenticate } from '../../api/Privacy'
import Loading from '../Common/Loading'
import { Outlet } from 'react-router-dom'

class Shell extends Component {
  constructor() {
    super()

    this.state = { isFetching: true }
  }
  
  componentDidMount = () => {
    this.fetchPrivacyToken()
  }

  fetchPrivacyToken = () => {
    apiPrivacyFetchToken()
      .then((response) => {
        if (response.data.validUser === 'superadmin') {
          return this.setState({
            isFetching: false
          })
        }

        this.authenticate()
      })
  };

  authenticate = () => {
    apiPrivacyAuthenticate()
      .then((response) => {
        if (response.data.status === 'Success') {
          sessionStorage.setItem('gravicus_auth_token', response.data.sessionID)
          sessionStorage.setItem('gravicus_username', 'superadmin')
          this.setState({
            isFetching: false
          })
        }
      })
  };

  render = () => {
    const { isFetching } = this.state

    return (
      <div>
        { isFetching &&
          <div className="dpiaShell-loading">
            <Loading />
          </div>
        }
        {
          !isFetching &&
          <Outlet/>
        }
      </div>
    )
  }
}

Shell.propTypes = {
  children: PropTypes.element
}

export default Shell
