import { apiUserFetchUsersList } from '../api/User'
import * as types from './actionTypes'

export function showLoading() {
  return { type: types.MANAGE_GROUPS_SHOW_LOADING }
}

export function receiveList(data) {
  return { type: types.RECEIVE_MANAGE_GROUPS_LIST, list: data }
}

export function updateRole(value, user) {
  return { type: types.UPDATE_USER_ROLE, data: {
    value,
    user
  } }
}

export function fetchList(token) {
  return (dispatch) => {
    return apiUserFetchUsersList()
    .then((response) => {
      dispatch(receiveList(response.data))
    })
  }
}