import React from 'react'
import PropTypes from 'prop-types'
import { customConfirm } from '../../../../utils/AssetRegister/CustomConfirm'
import { generateContentForField } from '../../../../utils/AssetRegister/AssetRegisterHelperMethods'

const Library = (props) => {
  const { library } = props
  const showConfirmationMessage = (library) => {
    customConfirm({
      confirmAction: () => props.deleteLibrary(library),
      title: 'Do you want to delete',
      content: `${ library.name } ?`,
      confirmButtonText: 'DELETE',
      cancelButtonText: 'CANCEL',
      mask: true,
      maskClosable: false,
      closeHaveLogic: false,
      className: 'asset-register-modal',
      centered: true,
      cancelClassName: 'cancel-button',
      confirmClassName: 'confirm-button',
    })
  }

  return (
    <div className="library-card">
      <div className="library-card__name">{ library.name }</div>
      <div className="library-card__header">
        <div className="library-card__header__columns">
          {
            library.columns.map((column, key) => {
              return (
                <span key={ key }>
                  { column.name }
                </span>
              )
            })
          }
        </div>
        {
          !props.isImportExportActive ?
            <div className="library-card__header__actions">
              <span className="icon-edit-b" onClick={ () => props.editLibrary(library) }/>
              <span className="icon-delete-b" onClick={ () => showConfirmationMessage(library) }/>
            </div> :
            <div className="library-card__header__actions">
              <span className="icon-download-b" onClick={ () => console.log('ciotka') }/>
            </div>
        }
      </div>
      { library.additionalFields &&
      <div className="library-card__content">
        {
          library.historyUpdate.map((field, key) => {
            return (
              <div className="library-card__content__field" key={ key }>
                <div className="library-card__content__field__header">
                  <span className="library-card__content__field__header__name">{ field.name }</span>
                </div>
                <div className="library-card__content__field__content">
                  { generateContentForField(field) }
                </div>
              </div>
            )
          })
        }
        {
          library.additionalFields.map((field, key) => {
            return (
              <div className="library-card__content__field" key={ key }>
                <div className="library-card__content__field__header">
                  <span>{ field.name }</span>
                  <span>{ field.required ? 'Required' : '' }</span>
                </div>
                <div className="library-card__content__field__content">
                  { generateContentForField(field) }
                </div>
              </div>
            )
          })
        }
      </div>
      }
    </div>
  )
}

Library.propTypes = {
  editLibrary: PropTypes.func.isRequired,
  deleteLibrary: PropTypes.func.isRequired,
  library: PropTypes.object.isRequired,
  isImportExportActive: PropTypes.bool.isRequired
}

export default Library