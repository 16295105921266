import React from 'react'
import { connect } from 'react-redux'
import { Input, Button, Modal, Tooltip, Checkbox, Spin } from 'antd'
import { customConfirm } from '../../../utils/AssetRegister/CustomConfirm'
import cookies from 'react-cookies'
import {
  createBusinessList,
  deleteBusinessListMultiple,
  getAllBusinessList,
  getTermsFromBusinessList,
  editBusinessList } from '../../../api/SmartBox'
// import CSVReader from 'react-csv-reader'
// import { Pagination } from '../../Common/Pagination'
// import imgDownload from '../../../assets/download.svg'
// import iconAdd from '../../../assets/add.svg'
import './SmartBoxBusinessTermLists.scss'
// import { CSVLink } from "react-csv"
import CustomNotificationManager from "../../Common/CustomNotificationManager";
// import { setBusinessTermsPageLimit } from '../../../actions/smartBoxActions'
import withRouter from '../../../private-routers/withRouter'
//const { Option } = Select

class SmartBoxBusinessTermLists extends React.Component {
  state = {
    modalVisible: false,
    name: '',
    description: '',
    term: '', 
    modalImportVisible: false,
    fileUploaded: false,
    file: '',
    fileImported: '',
    lists: [],
    activeList: {
      listName: '',
      description: ''
    },
    terms: [],
    loading: false,
    searchTerm: '',
    // amountPerPage: 10,
    pageNumber: 1,
    totalPages: '',
    selectedLetter: 'all',
    editMode: false,
    deletedTermIds: [],
    newTerms: [],
    newTermsCount: 0, 
    updateListMode: false,
    addImportMode: false,
    categoryDisplayedTerm: '',
    csvData: [],
    modalAction: 'create',
    loadingExport: false,
    loadingPage: true,
    colorPickerVisible: false,
    color: 'rgba(5, 195, 71, 1)',
    isOpen: false,
    selectedLists: [],
    hoverEdit: false,
    hoverDelete: false,
    hoverAdd: false
  }
  lettersCategory = ['All','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
  /*columns = [
    {
      title: () => {
        const { activeList } = this.state
        return (
          <React.Fragment>
            <div className="table-header-small">List name</div>
            <div><span className="table-header-bold">{ activeList.listName }</span>&nbsp;&nbsp;<span className="table-header-small">{ activeList.description }</span></div>
          </React.Fragment>)
      },
      key: 'name',
      dataIndex: 'term',
      width: '80%',
      render: (term, record) => {
        if(term === 'ADD-SINGLE-LINE') {
          return (
            <Input id={record.id} ref={(input) => { this.newTermInput = input; }} onBlur={ (e) => this.addNewTermInState(e)} onChange={ (e) => this.updateValue(e,'termName') } autoFocus/>
          )
        }
        return term
      }
    },
    {
      title: () => {
        const { activeList, isOpen } = this.state
        const menuClass = `pull-left header-dots ${isOpen ? "show" : ""}`

        return (
          <div>
            <div className="pull-left">
              <div className="table-header-small">Display term</div>
              <div className="table-header-bold text-right">{ activeList.displayTerm }</div>
            </div>
            <div className={menuClass}>
              <DropdownButton
                menuRole= "menu"
                drop="up"
                title={ <div className="ellipsis-v">...</div> }
                id={`dropdown-${ activeList.id }`}
                style={{zIndex: '100'}}
                onClick={this.toggleOpen}
              >
                <DropdownItem onSelect={ (e) => this.toggleModal(e, true, 'update') }>Update List</DropdownItem>
                <DropdownItem onSelect={ () => this.toggleEditMode() }>{ this.state.editMode ? 'View' : 'Edit'}</DropdownItem>
                <DropdownItem onSelect={ this.enterAddImportMode }>Add Import</DropdownItem>
                <DropdownItem onSelect={ () => this.showConfirmDelete(activeList) }>Delete</DropdownItem>
              </DropdownButton>
            </div>
            <div className="clearfix"></div>
          </div>
        )
      },
      key: 'id',
      dataIndex: 'id', 
      width: '20%',
      className: 'text-right',
      render: (id) => {
        if(this.state.editMode) {
          return (
            <button className="icon-delete-b" onClick={ () => this.deleteTermFromCurrentList(id) } />          
          )
        }
        return null
      }     
    }
  ]*/
  constructor(props) {
    super(props)

    this.addOrRemoveSelectedLists = this.addOrRemoveSelectedLists.bind(this)
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    this.fetchLists()
    // document.body.addEventListener('click', this.closeOptionsDropdown)
  }
  componentWillUnmount() {
    // document.body.removeEventListener('click', this.closeOptionsDropdown)
  }
  // closeOptionsDropdown = () => this.setState({ isOpen : false })
  
  fetchLists = () => {
    getAllBusinessList().then( (response) => {
      if (response.status === 200) {
        let activeList = {}
        if (response.data.length) {
          activeList= response.data[0]
          if (cookies.load('business-term')) {
            response.data.map( (item) => {
              if (Number(item.id) === Number(cookies.load('business-term'))) {
                activeList= item
              }
              return true
            })
            cookies.remove('business-term')
          }
        }
        this.setState({
          lists: response.data,
          loadingPage: false,
          activeList
        }, () => {
          // this.fetchTermsForList()
        })
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  fetchTermsForList = () => {
    const { activeList, selectedLetter, searchTerm, pageNumber, lists } = this.state
    const { businessTermsPageLimit } = this.props
    // const csvData = []
    const searchNameTerm = searchTerm.trim()
    if (lists.length) {
      this.setState({
        loading: true
      }, () => {
        const data = {
          listId: activeList.id,
          selectedLetter: selectedLetter === 'all' ? '' : selectedLetter,
          searchTerm: searchNameTerm,
          pageNumber,
          amountPerPage: businessTermsPageLimit
        }
        getTermsFromBusinessList(data).then((response) => {
          if (response.status === 200) {
            if (response.data.terms && response.data.terms.length) {
              // csvData.push(['Id', 'Name'])
              // response.data.terms.map((item) => {
              //   csvData.push([item.id, item.term])
              //   return true
              // })
            }
            this.setState({
              terms: response.data.terms ? response.data.terms : [],
              totalPages: response.data.total,
              loading: false,
              deletedTermIds: [],
              newTerms: [],
              newTermsCount: 0,
              // csvData
            })
          }
        })
      })
    }
  }
/*
  toggleEditMode = () => {
    const { editMode } = this.state
    this.setState({ editMode: !editMode})
  }

  enterUpdateListMode = () => {
    this.setState({
      updateListMode: true,
      modalVisible: true,
      modalImportVisible: true
    })
  }

  enterAddImportMode = () => {
    this.setState({
      addImportMode: true,
      modalVisible: true,
      modalImportVisible: true,
      editMode: false
    })
  }*/

  showConfirmDelete = () => {
    const temp = this
    const { selectedLists, lists } = this.state

    if(selectedLists.length === 0) return

    let selectedNames = []
    lists.forEach(item => {
      if(selectedLists.includes(item.id)) {
        selectedNames.push(item.listName)
      }
    })
    customConfirm({
      confirmAction: () => temp.deleteList() ,
      title: '',
      content:
        <React.Fragment>
          <div className={"delete-dict-title"}>
            <div className={"icon-del icon-delete-b"}></div>
            <div className={"delete-dict-q"}>{'Delete selected '+(selectedLists.length === 1 ? 'dictionary' : 'dictionaries')+'?'}</div>
          </div>
          <div className={"delete-dict-names"}>{selectedNames.map((item , index) => <div key={`dict-${index}`}>{item}</div>)}</div>
        </React.Fragment>,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      mask: true,
      maskClosable: false,
      closeHaveLogic: false,
      className: 'delete-dict-modal',
      centered: true,
      cancelClassName: 'cancel-button',
      confirmClassName: 'confirm-button'
    })
  }
/*
  convertRgbaToString = ({ r, g, b, a }) => {
    return `rgba(${ r }, ${ g }, ${ b }, ${ a })`
  }

  closeAndSaveColorPicker = (color) => {
    const { activeList, modalAction }= this.state
    if (modalAction === 'update') {
      activeList.colour = this.convertRgbaToString(color.rgb)
    }
    this.setState({ color: this.convertRgbaToString(color.rgb), colorPickerVisible: false, activeList })
  } */

  deleteList = () => {
    let { lists, selectedLists } = this.state
    const deleteBusinessListMultipleDTO = {
      ids: selectedLists
    }
    deleteBusinessListMultiple(deleteBusinessListMultipleDTO).then( (response) => {
      if (response.status === 200) {
        lists = lists.filter(item => !selectedLists.includes(item.id))
        selectedLists = []
        // if (lists.length) {
        //   this.setState({
        //     // activeList: lists[0],
        //     lists,
        //     // amountPerPage: 10,
        //     pageNumber: 1,
        //     totalPages: '',
        //     selectedLetter: 'all',
        //     searchTerm: ''
        //   }, () => this.fetchTermsForList())
        // } else {
          this.setState({
            // terms: [],
            // activeList: '',
            lists,
            selectedLists
            // amountPerPage: 10,
            // pageNumber: 1,
            // totalPages: '',
            // selectedLetter: 'all',
            // searchTerm: ''
          }, () => {
            getAllBusinessList().then( (response) => {
              if (response.status === 200) {
                let activeList = {}
                if (response.data.length) {
                  activeList= response.data[0]
                  if (cookies.load('business-term')) {
                    response.data.map( (item) => {
                      if (Number(item.id) === Number(cookies.load('business-term'))) {
                        activeList= item
                      }
                      return true
                    })
                    cookies.remove('business-term')
                  }
                }
                this.setState({
                  lists: response.data,
                  loadingPage: false,
                  activeList
                })
              }
            }).catch((error) => {
              if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
              }else{
                console.log(error)
              }
            })
          })
        // }
      }
      // this.setState({ lists })
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  /*addNewTermInState = (e) => {
    let { newTerms } = this.state
    newTerms[e.target.id.replace('newTerm-','')] = e.target.value
    this.setState({ newTerms })
  }
 */
  toggleModal = (e, visible, action) => {
    const { activeList } = this.state
    if (!visible) {
      this.setState({
        colorPickerVisible: visible
      })
    }
    if (action === "update") {
      this.setState({
        modalVisible: visible,
        modalAction: action,
        name: activeList.listName,
        categoryDisplayedTerm: activeList.displayTerm,
        description: activeList.description
      })
    } else {
      this.setState({
        description: '',
        modalVisible: visible,
        modalAction: action,
        color: 'rgba(5, 195, 71, 1)',
        activeList: {
          listName: '',
          description: ''
        }
      })
    }
  };

  handleCancel = (e) => {
    this.setState({
      modalVisible: false,
      modalImportVisible: false,
      color: 'rgba(5, 195, 71, 1)',
      colorPickerVisible: false
    })
  }

  updateValue(e, field) {
    if (field === 'name') {
      this.setState({
        activeList: {...this.state.activeList, listName: e.target.value}
      })
    } else if (field === 'description') {
      this.setState({
        activeList: {...this.state.activeList, description: e.target.value}
      })
    }
    this.setState({
      [field]: e.target.value
    })
  }
  toggleValue(field, val) {
    switch(field) {
      case 'edit':
        this.setState({hoverEdit: val})
        break
      case 'delete':
        this.setState({hoverDelete: val})
        break
      case 'add':
        this.setState({hoverAdd: val})
        break
      default: break
    }
  }

  createEditBusinessList = () => {
    const { name, description, categoryDisplayedTerm, lists, modalAction, activeList, color } = this.state
    if (modalAction === 'create') {
      const data = {name: name, description: description, term: categoryDisplayedTerm, color: color}
      createBusinessList(data).then((response) => {
        if (response.status === 200) {
          const newList = {}
          newList.id = response.data.id
          newList.listName = response.data.listName
          newList.description = response.data.description
          newList.displayTerm = response.data.displayTerm
          newList.colour = response.data.colour
          lists.push(newList)
          this.setState({
            lists,
            //activeList: lists[lists.length-1],
            activeList: {...this.state.activeList, listName: '', description: ''},
            modalVisible: false,
          }/*, () => this.fetchTermsForList()*/)
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    } else if (modalAction === 'update') {
      const data = {id: activeList.id, name: name, description: description, term: categoryDisplayedTerm, color: color}
      editBusinessList(data).then((response) => {
        if (response.status === 200) {
          lists.map( (item) => {
            if (item.id === data.id) {
              item.listName = data.name
              item.description = data.description
              item.displayTerm = data.term
            }
            return true
          })
          this.setState({
            modalVisible: false,
          })
        }
      })
    }
  }

  /*getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  handleSubmitImport = () => {
    const { activeList, fileImported } = this.state
    const blob = fileImported.slice(0, fileImported.size, fileImported.type)
    const fileDto = new File([blob], fileImported.name)
    const data = {
      fileDto: fileDto,
      id: activeList.id
    }
    importBusinessTerms(data).then( (response) => {
      if (response.status === 200) {
        this.setState({
          modalVisible: false
        })
      }
    })
  }

  finalizeImportTerms = () => {
    const { name, description, term, file, updateListMode, addImportMode } = this.state
    // const { activeListId } = this.state
    // let data
    if(updateListMode) {
      // data = { activeListId, file }
      // call api to update list with imported terms
    } else if(addImportMode) {
      // data = { activeListId, file }
      // call api to import new terms into list
    } else {      
      const formData = new FormData()
      formData.append('file', file)
      formData.append('name', name)
      formData.append('description', description)
      formData.append('term', term)
      // call api to create new list and import file
    }
    this.setState({
      modalVisible: false,
      modalImportVisible: false,
      file: '',
      fileUploaded: false,
      name: '',
      description: '',
      term: ''
    })
  }
  
  onPageChanged = data => {
    const { currentPage, pageLimit } = data;

    this.setState({
      // amountPerPage: pageLimit,
      pageNumber: currentPage
    }, () => this.fetchTermsForList() )
  }

  changeActiveList = (value) => {
    this.setState({
      activeList: this.state.lists.filter(l => l.id === value)[0],
      selectedLetter: 'all',
      searchTerm: ''
    }, () => this.fetchTermsForList() )
  }

  onChangeLetter = (e) => {
    this.setState({ selectedLetter: e.target.value, searchTerm:'' }, () => this.fetchTermsForList())
  }
  
  handleSearchInputChange = (e) => {
    const searchedText = e.target.value
    this.setState({
      searchTerm: searchedText
    }, () => {
      if (searchedText === '') {
        this.fetchTermsForList()
      }
    })
  }

  handlerSearchEnter = (e) => {
    if (e.key === 'Enter') {
      this.fetchTermsOnSearch()
    }
  }

  fetchTermsOnSearch = (e) => {
    this.setState({
      selectedLetter: 'all'
    }, () => this.fetchTermsForList())
  }

  addSingleLine = () => {
    const { terms, newTermsCount } = this.state
    this.setState({
      terms: [...terms, {id: 'newTerm-'+newTermsCount, term: 'ADD-SINGLE-LINE'}],
      newTermsCount: newTermsCount+1
    }, () => {
      if(this.newTermInput !== null && typeof this.newTermInput !== 'undefined') {
        this.newTermInput.focus()
      }
    })
  }

  deleteTermFromCurrentList = (id) => {
    this.setState({
      deletedTermIds: [...this.state.deletedTermIds, id]
    })
  }

  onDropCsvFile = (file) => {
    const { terms, activeList } = this.state
    const newTerms = []
    const newTabelRaws = []
    file.forEach(function(row, index) {
      if (index) {
        const temp = {}
        if (row[1] && row[1].trim()) {
          temp.term = row[1]
          temp.list = activeList
          newTabelRaws.push(temp)
          newTerms.push(row[1])
        }
      }
    })
    const data = {
      listId: activeList.id,
      deletedIds: [],
      newTerms: newTerms
    }
    editBusinessTermList(data).then( (response) => {
      if (response.status === 200) {
        this.setState({ terms: terms.concat(newTabelRaws), modalImportVisible: false, modalVisible: false }, () => {
          CustomNotificationManager.success("File was uploaded successfully", "Upload successfully")
        })
      }
    }).catch( (e) => {
      CustomNotificationManager.error("Items should be valid and unique", "Upload fail")
    })
  }

  saveTermsOnList = () => {
    let { activeList, deletedTermIds, newTerms } = this.state
    const { terms } = this.state
    const listTerms = []

    deletedTermIds = deletedTermIds.filter(id => (id+'').indexOf('newTerm') === -1)
    const validNewTerms = []
    if (newTerms.length) {
      newTerms.map( (item)=> {
        if (item.trim()) {
          validNewTerms.push(item.trim())
        }
        return true
      })
    }

    terms && terms.length > 0 && terms.forEach((termsList) => {
      listTerms.push(termsList.term.toLowerCase())
    })
    let checkTermExist = false
    validNewTerms && validNewTerms.length > 0 && validNewTerms.forEach((newTerm) => {
      if (!listTerms.includes(newTerm.toLowerCase())) {
        checkTermExist = false
        // const data = {
        //   listId: activeList.id,
        //   deletedIds: deletedTermIds,
        //   newTerms: validNewTerms
        // }
        // editBusinessTermList(data).then( (response) => {
        //   if (response.status === 200) {
        //     this.fetchTermsForList()
        //     this.setState({ editMode: false })
        //   }
        // } ).catch( (error) => {
        //   const messageError = "Internal Server Error: could not execute statement; SQL [n/a]; constraint [unique_index]; nested exception is org.hibernate.exception.ConstraintViolationException: could not execute statement"
        //   if (error.response.status === 500 && error.response.data.message === messageError) {
        //     CustomNotificationManager.error('My business term already exist', 'Error')
        //   }
        //   this.setState({ editMode: true })
        // })
      } else {
        checkTermExist = true
        CustomNotificationManager.error('My business term already exist', 'Error')
      }
    })
    const data = {
      listId: activeList.id,
      deletedIds: deletedTermIds,
      newTerms: validNewTerms
    }
    if (!checkTermExist) {
      editBusinessTermList(data).then((response) => {
        if (response.status === 200) {
          this.fetchTermsForList()
          this.setState({ editMode: false })
        }
      }).catch((error) => {
        const messageError = "Internal Server Error: could not execute statement; SQL [n/a]; constraint [unique_index]; nested exception is org.hibernate.exception.ConstraintViolationException: could not execute statement"
        if (error.response.status === 500 && error.response.data.message === messageError) {
          CustomNotificationManager.error('My business term already exist', 'Error')
        }
        this.setState({ editMode: true })
      })
    }
  }

  exportToCsv = (event) => {
    const { activeList, selectedLetter } = this.state
    const csvData = []
    this.setState({loadingExport: true})
    if(!this.state.csvData.length) {
      CustomNotificationManager.warning('No data available to export.', 'No Data')
      event.preventDefault()
      const data = {
        listId: activeList.id,
        selectedLetter: selectedLetter === 'all' ? '' : selectedLetter,
        searchTerm: '',
        pageNumber: 1,
        amountPerPage: 9999
      }
      getTermsFromBusinessList(data).then((response) => {
        if (response.status === 200) {
          if (response.data.terms && response.data.terms.length) {
            response.data.terms.map( (item) => {
              csvData.push([item.term])
              return true
            })
            this.setState({ csvData })
          }
          setTimeout(() => {
            this.setState({loadingExport: false})
          }, 800)
        }
      })
    }
    setTimeout(() => {
      this.setState({loadingExport: false})
    }, 1000)
  }

  toggleColorPickerVisible = (e, open) => {
    this.setState({ colorPickerVisible: !this.state.colorPickerVisible })
    if (open) {
      this.setState({ colorPickerVisible: open })
    }
  }
  toggleOpen = (e) =>  {
    e.stopPropagation()
    this.setState({ isOpen: !this.state.isOpen });
  }
*/
  addOrRemoveSelectedLists = (e, id) => {
    e.stopPropagation()

    let { selectedLists } = this.state
    //const found = selectedLists.find(item => item === id)

    if(e.target.checked) {
      selectedLists.push(id)
    } else {
      selectedLists = selectedLists.filter(item => item !== id)
    }
    this.setState({selectedLists})
  }

  openEditModal = (e) => {
    const { selectedLists, lists } = this.state

    if(selectedLists.length === 0) return

    const activeList = lists.find(item => item.id === selectedLists[0])

    this.setState({ activeList }, () => {
      this.toggleModal(e, true, 'update')
    })
  }

  redirectToManageDictionaryPage = (id) => {
    this.props.navigate('/smart-box/business-term-list/'+id)
  }

  render() {
    const { name, selectedLists, modalVisible, modalImportVisible, lists, activeList, editMode, modalAction, loadingPage,
      hoverEdit, hoverDelete, hoverAdd} = this.state
    //const { businessTermsPageLimit } = this.props
    let terms = this.state.terms
    const { client } = this.props
    /*const inputColorDefaultValue= modalAction === 'update' ? activeList.colour : color
    const renderColorPicker = (
      <React.Fragment>
        <div className="business-term-color-picker">
          <CompactPicker
            className="color-picker-a"
            colors={['rgba(107, 91, 149, 1)', 'rgba(254, 178, 54, 1)', 'rgba(214, 65, 97, 1)',
              'rgba(255, 123, 37, 1)', 'rgba(75, 195, 193, 1)', 'rgba(194, 194, 71, 1)', 'rgba(181, 231, 160, 1)',
              'rgba(191, 108, 64, 1)', 'rgba(62, 68, 68, 1)', 'rgba(130, 183, 75, 1)', 'rgba(64, 93, 39, 1)',
              'rgba(193, 148, 106, 1)', 'rgba(60, 136, 180, 1)', 'rgba(200, 100, 91, 1)', 'rgba(238, 162, 154, 1)',
              'rgba(201, 76, 76, 1)', 'rgba(124, 108, 6, 1)', 'rgba(15, 195, 71, 1)', 'rgba(231, 82, 114, 1)',
              'rgba(238, 140, 54, 1)', 'rgba(92, 212, 209, 1)', 'rgba(211, 211, 88, 1)', 'rgba(198, 248, 177, 1)',
              'rgba(192, 125, 81, 1)', 'rgba(77, 100, 113, 1)', 'rgba(237, 226, 220, 1)', 'rgba(132, 206, 235, 1)']}
            disableAlpha={true}
            color={color}
            onChange={(color) => this.closeAndSaveColorPicker(color)}
          />
        </div>
      </React.Fragment>
    )
*/
    if (terms) {
      terms = terms.filter(t => !this.state.deletedTermIds.includes(t.id))
    }
    // let defaultValue = ''
    //
    // if (activeList) {
    //   defaultValue= activeList.id
    // } else if (lists.length) {
    //   defaultValue= lists[0].id
    // }
    return (
      <div className="smart-box-btl__container">
        <div className="box">
          <div className="update-box-title">
          <div className="box-title">Dictionaries</div>
            <Tooltip overlayClassName="help-message-locations" title={<div className="content-help-message-locations">
              <span className="text-information">Create dictionaries to search for words and phrases specific to your organisation.</span>
              <span className="more-info-link" onClick={ () => window.open('https://support.smartbox.ai/support/solutions/articles/47001204505', '_blank') }>More info.</span>
              </div>}>
              <span className="icon-v14-question"></span>
            </Tooltip>
          </div>
          {/*<h3>Create and manage your Library with specific terms of your choice.</h3>*/}
          <div className={ lists.length === 0 ? 'topbar no-lists' : (editMode ? 'topbar edit-mode' : 'topbar')}>            
            { /*lists.length > 0 && [
              <Select key= 'select' value={ defaultValue } onChange={ (e) => this.changeActiveList(e)  } placeholder={Object.keys(activeList).length === 0 ? 'Choose List' : ''}>
                { lists.map( (item) => {
                    return <Option key={ `list-${item.id}` } value={item.id}>{item.listName}</Option>
                  })
                }
              </Select>,          
              <div key="1" className="search-content">
                <Input value={ searchTerm } onChange={ (e) => this.handleSearchInputChange(e) } onKeyPress={ (e) => this.handlerSearchEnter(e) } />
                <div className="icon-wrapper" onClick={ () => this.fetchTermsOnSearch() }>
                  <Icon type="search" />
                </div>
              </div>            
            ] */}
            {/*lists.length > 0 && !editMode &&
              <div key="2" className="btn-cont buttons-terms"><CSVLink data={csvData} filename={`${activeList.listName}.csv`}>
                <Button className="transp export-btn" disabled={ loadingExport ? true : false } onClick={(event) => { this.exportToCsv(event) }}>
                  { loadingExport ? <i className="fa fa-spinner fa-spin"></i> : 'EXPORT' }
                </Button>
              </CSVLink>
              </div>
            */}
            {!editMode && 
              <div key="3" className="btn-cont buttons-terms buttons-terms-dictionaries">
                <button className={'icon-v17-edit'+(hoverEdit ? '-active' : '')} onMouseEnter={() => this.toggleValue('edit',true)}
                        onMouseLeave={() => this.toggleValue('edit',false)}
                        disabled={ selectedLists.length === 0 || selectedLists.length > 1 } onClick={(e) => this.openEditModal(e)} ></button>
                <button className={'icon-v17-delete'+(hoverDelete ? '-active' : '')} onMouseEnter={() => this.toggleValue('delete',true)}
                        onMouseLeave={() => this.toggleValue('delete',false)}
                        disabled={ selectedLists.length === 0 } onClick={(e) => this.showConfirmDelete() } ></button>
                <button className={'icon-v17-add'+(hoverAdd ? '-active' : '')} onMouseEnter={() => this.toggleValue('add',true)}
                        onMouseLeave={() => this.toggleValue('add',false)}
                        onClick={(e) => this.toggleModal(e, true, 'create')} ></button>
              </div>
            }
          </div>
          <div className="clearfix"></div>

          { lists.length > 0 && lists.map(item =>
            <div className={"dict-item"} key={'dict-'+item.id} onClick={ () => this.redirectToManageDictionaryPage(item.id) }>
              <div onClick={ (e) => { e.stopPropagation() } }>
                <Checkbox onChange={ (e) => this.addOrRemoveSelectedLists(e, item.id) }></Checkbox>
              </div>
              <div>{item.listName}</div>
              <i className={"icon-v14-arrow-bold"}></i>
            </div>)
          }
          { /*lists.length > 0 && [
            <Pagination key="4" totalRecords={ Number(totalPages) } pageLimit={ businessTermsPageLimit } onPageChanged={ this.onPageChanged }
              setPropsCall={ this.props.setBusinessTermsPageLimit }/>,
            <Radio.Group key="5" value={ selectedLetter } onChange={ (e) => this.onChangeLetter(e) } className="letters-term">
              {this.lettersCategory.map((item) => {
                return <Radio.Button key={item} value={item.toLowerCase()}>{item}</Radio.Button>
              })
              }
            </Radio.Group>,
            <Table key="6" dataSource={ terms } columns={ this.columns } loading={ loading } pagination={ false } rowKey="id" />
          ]*/}
          { /*lists.length > 0 && <Table key="6" dataSource={ terms } columns={ this.columns } loading={ loading } pagination={ false } rowKey="id" /> */}
          { /*lists.length > 0 && editMode && [
            <div key="7" className="pull-right add-single-line">
              <button onClick={ this.addSingleLine }>Add single line <img src={iconAdd} alt="Add single line" /></button>
            </div>,
            <div key="8" className="clearfix"></div>,
            <div key="9" className="pull-right buttons-terms mt5">
              <Button className="black" type="submit" onClick={ this.saveTermsOnList }>SAVE</Button>
            </div>,
            <div key="10" className="clearfix"></div>
          ] */}
           <Spin className="custom-spin custom-spin-update" spinning={ loadingPage}/>
          
          { lists.length === 0 && !loadingPage &&
            <div className="no_list_available">
              {/*<p> No business term lists available.</p>*/}
              <p> No dictionaries available.</p>
              <p>Please create a new one.</p>
            </div>
          }
          <Modal className="dictionary-modal"
                 title={ modalAction === 'create' ? <span className="content-header-modal"><span>New dictionary</span><span onClick={ this.handleCancel } className="icon-v21-close-black"></span></span> : <span className="content-header-modal"><span>{ 'Edit '+activeList.listName }</span><span onClick={ this.handleCancel } className="icon-v21-close-black"></span></span> }
                 visible={ modalVisible } onCancel={ this.handleCancel } footer={ null } closable={ false } >
              <div className="import-terms-section">
                <form className="import-terms-form">
                  <React.Fragment>
                    <div className={"dict-modal-line"}>
                      <label>Dictionary name</label>
                      <Input onChange={(e) => this.updateValue(e, 'name')}
                             value={modalAction === 'update' && activeList ? activeList.listName : modalAction === 'create' && activeList ? activeList.listName  : ''}
                             placeholder="Name"/>
                    </div>
                    <div className={"dict-modal-line"}>
                      <label>Description <span className="optional-description">(Optional)</span></label>
                      <Input onChange={(e) => this.updateValue(e, 'description')}
                             value={modalAction === 'update' && activeList ? activeList.description : modalAction === 'create' && activeList ? activeList.description : ''}
                             placeholder="Description"/>
                    </div>

                    {/*<label>Category displayed term*</label>
                    <Input onChange={(e) => this.updateValue(e, 'categoryDisplayedTerm')}
                           defaultValue={modalAction === 'update' && activeList ? activeList.displayTerm : ''}
                           placeholder="Category displayed term"/>
                    <div className='line'>

                      <label className="float-left">Color</label>
                      <Popover content={renderColorPicker} trigger="click" visible={colorPickerVisible}>
                        <div className="list-selected-color" style={{backgroundColor: inputColorDefaultValue}}
                             onClick={(e) => this.toggleColorPickerVisible(e)}></div>
                      </Popover>
                    </div>*/}
                  </React.Fragment>
                  <div className="buttons">
                    <Button className="transp-orange" onClick={ this.handleCancel }>Cancel</Button>
                    { !modalImportVisible &&
                      <Button className="orange" type="submit" disabled={ (!name.trim()) }
                              onClick={ (e) => this.createEditBusinessList(e) }>{ modalAction === 'create' ? 'Create' : 'Save' }</Button>
                      /*<CSVReader
                        cssClass="csv-reader-btn"
                        cssInputClass ="upload_csv_btn"
                        onFileLoaded={this.onDropCsvFile}
                        inputId="file_csv_upload"
                        label="IMPORT"
                      />*/
                    }
                  </div>
                </form>
              </div>
          </Modal>
        </div>        
      </div>
    )
  }
}

SmartBoxBusinessTermLists = withRouter(SmartBoxBusinessTermLists)

const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id,
  client: state.subscriptionReducer.subscription.subscription.client,
  // businessTermsPageLimit: state.smartBox.businessTermsPageLimit
})

export default connect(mapStateToProps, {
// setBusinessTermsPageLimit
})(SmartBoxBusinessTermLists)