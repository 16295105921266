import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { clickedRequestSalesCall, userIsAdmin } from '../../../utils'
import './About.scss'
import ButtonAddBusinessProcess from '../ButtonAddBusinessProcess/ButtonAddBusinessProcess'
import LimitedFreeAccount from '../Plan/LimitedFreeAccount'
import UnlimitedAccount from '../Plan/UnlimitedAccount'
import WhatIsBusinessProcessPia from '../WhatIsBusinessProcessPia/WhatIsBusinessProcessPia'

export class About extends React.Component {
  render() {
    const { user, subscription } = this.props
    const isAdmin = userIsAdmin(user)
    return (
      <div className="about-page">
        <WhatIsBusinessProcessPia />
        { isAdmin &&
          <div className="about-footerContainer">
            <div className="about-buttonAddContainer">
              <ButtonAddBusinessProcess 
                title="Add your first Business Process"/>
            </div>
            <div className="about-limitedFreeAccountContainer">
              {
                subscription.plan &&
                !subscription.unlimitedPlan &&
                <LimitedFreeAccount
                  plan={subscription.plan}
                  onClick={clickedRequestSalesCall.bind(this, this.props.user)}
                />
              }
              {
                subscription.plan &&
                subscription.unlimitedPlan &&
                <UnlimitedAccount subscription = { subscription } />
              }
            </div>
          </div>
        }
      </div>
    )
  }
}

const stateMap = (state) => ({
  user: state.userReducer.user,
  subscription: state.subscriptionReducer.subscription
})

About.propTypes = {
  user: PropTypes.object.isRequired,
  subscription: PropTypes.object.isRequired,
}

export default compose(connect(stateMap, null))(About)