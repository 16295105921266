import React from 'react'
import AdditionalFieldsContainer from '../../../Common/AssetDocLockerComponents/AdditionalFieldsContainer'
import ColumnsContainer from '../../../Common/AssetDocLockerComponents/ColumnsContainer'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { saveRegister, updateRegister } from '../../../../api/AssetRegister'
import _ from 'lodash'
import { Popover, Select } from 'antd'
import { CompactPicker } from 'react-color'

const { Option } = Select

class AddRegister extends React.Component {
  state = {
    register: {
      name: '',
      clientId: 0,
      currency: '',
      color: '#6e7882',
      additionalFields: [
        {
          name: '',
          domId: 1,
          required: false,
          additional: true,
          columnOrder: 1,
          type: 'NULL',
          options: []
        }
      ],
      columns: [
        {
          name: '',
          domId: 1,
          required: false,
          additional: false,
          columnOrder: 1,
          type: 'NULL',
          options: []
        },
        {
          name: '',
          domId: 2,
          required: false,
          additional: false,
          columnOrder: 2,
          type: 'NULL',
          options: []
        },
        {
          name: '',
          domId: 3,
          required: false,
          additional: false,
          columnOrder: 3,
          type: 'NULL',
          options: []
        },
        {
          name: '',
          domId: 4,
          required: false,
          additional: false,
          columnOrder: 4,
          type: 'NULL',
          options: []
        },
        {
          name: '',
          domId: 5,
          required: false,
          additional: false,
          columnOrder: 5,
          type: 'NULL',
          options: []
        }
      ]
    },
    columnsToDelete: [],
    optionsToDelete: [],
    displayColorPicker: false
  }

  componentDidMount() {
    if (!_.isEmpty(this.props.register)) {
      this.setState({
        register: this.props.register
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.register !== this.props.register && !_.isEmpty(this.props.register)) {
      this.setState({
        register: this.props.register
      })
    }
  }

  updateAdditionalFields = (newFieldsArray, columnToRemove, optionToRemove) => {
    const { columnsToDelete, optionsToDelete } = this.state
    if (columnToRemove) {
      columnsToDelete.push(columnToRemove)
    }
    if (optionToRemove) {
      optionsToDelete.push(optionToRemove)
    }

    this.setState({
      register: {
        ...this.state.register, additionalFields: newFieldsArray, columnsToDelete, optionsToDelete
      }
    })
  }

  setRegisterName = (name) => {
    this.setState({
      register: {
        ...this.state.register, name: name
      }
    })
  }

  setRegisterCurrency = (name) => {
    this.setState({
      register: {
        ...this.state.register, currency: name
      }
    })
  }

  setRegisterColor = (name) => {
    this.setState({
      register: {
        ...this.state.register, color: name
      }
    })
  }

  updateColumns = (newColumnsArray, columnToRemove, optionToRemove) => {
    const { columnsToDelete, optionsToDelete } = this.state
    if (columnToRemove) {
      columnsToDelete.push(columnToRemove)
    }
    if (optionToRemove) {
      optionsToDelete.push(optionToRemove)
    }

    this.setState({
      register: {
        ...this.state.register, columns: newColumnsArray, columnsToDelete, optionsToDelete
      }
    })
  }

  saveRegister = () => {
    const { register } = this.state
    register.clientId = this.props.clientId
    for (let i = 0; i < register.columns.length; i++) {
      register.columns[i].columnOrder = i + 1
    }
    for (let i = 0; i < register.additionalFields.length; i++) {
      register.additionalFields[i].columnOrder = i + 1
    }
    register.columns = [ ...register.columns, ...register.additionalFields ]

    register.columnsToDelete = this.state.columnsToDelete.filter((column) => column.id)
    register.optionsToDelete = this.state.optionsToDelete.filter((option) => option.id)

    if (register.id) {
      updateRegister(register, register.id).then((res) => {
        if (res) {
          this.props.closeAddEdit()
        }
      })
    } else {
      saveRegister(register).then((res) => {
        if (res) {
          this.props.closeAddEdit()
        }
      })
    }
  }

  componentWillUnmount() {
    this.setState({
      register: {}
    })
  }

  toggleColorPicker = () => {
    this.setState({
      displayColorPicker: !this.state.displayColorPicker
    })
  }

  closeAndSaveColorPicker = (e, color) => {
    e.stopPropagation()
    this.setState({
      register: {
        ...this.state.register, color: color.hex
      },
      displayColorPicker: false
    })
  }

  handleVisibleChange = (visible) => {
    this.setState({ displayColorPicker: visible })
  };

  render() {
    const { register, displayColorPicker } = this.state
    return (
      !_.isEmpty(register) ? <div className="add-register">
        <div className="add-register__input-section">
          <label htmlFor="name" className="register-title">REGISTER SETTINGS</label>
          <div className="add-register__input-section__right-container">
            <input type="text" placeholder="Register Name" onChange={ (e) => this.setRegisterName(e.target.value) }
              value={ register.name }/>
            <div className="add-register__input-section__right-container__color-currency-container">
              <Select placeholder="Register Currency Optional" onChange={ (value) => this.setRegisterCurrency(value) }
                className="custom-select"
                value={ register.currency || undefined }>
                <Option value="EUR">EUR    Euro</Option>
                <Option value="USD">USD    US Dollar</Option>
                <Option value="GBP">GBP    British Pound</Option>
                <Option value="CHF">CHF    Swiss Franc</Option>
                <Option value="AUD">AUD    Australian Dollar</Option>
                <Option value="THB">THB    Thai Baht</Option>
                <Option value="NZD">NZD    New Zealand Dollar</Option>
                <Option value="NOK">NOK    Norwegian Krone</Option>
                <Option value="CAD">CAD    Canadian Dollar</Option>
              </Select>
              <div className="color">
                {
                  <Popover placement="top"
                    overlayClassName="color-picker-popover"
                    onVisibleChange={ this.handleVisibleChange }
                    content={
                      <CompactPicker
                        colors={ [ 'rgba(107, 91, 149, 1)', 'rgba(254, 178, 54, 1)', 'rgba(214, 65, 97, 1)',
                          'rgba(255, 123, 37, 1)', 'rgba(75, 195, 193, 1)', 'rgba(194, 194, 71, 1)', 'rgba(181, 231, 160, 1)',
                          'rgba(191, 108, 64, 1)', 'rgba(62, 68, 68, 1)', 'rgba(130, 183, 75, 1)', 'rgba(64, 93, 39, 1)',
                          'rgba(193, 148, 106, 1)', 'rgba(60, 136, 180, 1)', 'rgba(200, 100, 91, 1)', 'rgba(238, 162, 154, 1)',
                          'rgba(201, 76, 76, 1)', 'rgba(124, 108, 6, 1)', 'rgba(15, 195, 71, 1)', 'rgba(231, 82, 114, 1)',
                          'rgba(238, 140, 54, 1)', 'rgba(92, 212, 209, 1)', 'rgba(211, 211, 88, 1)', 'rgba(198, 248, 177, 1)',
                          'rgba(192, 125, 81, 1)', 'rgba(77, 100, 113, 1)', 'rgba(237, 226, 220, 1)', 'rgba(132, 206, 235, 1)' ] }
                        disableAlpha={ true }
                        onChange={ (color, e) => this.closeAndSaveColorPicker(e, color) }
                        style={ { boxShadow: 'none' } }
                      />
                    } trigger="click" visible={ displayColorPicker }>
                    <span style={ {
                      backgroundColor: register.color || '#6e7882',
                      width: '28px',
                      height: '28px',
                      borderRadius: '50%',
                      marginRight: '10px'
                    } } onClick={ () => this.toggleColorPicker() }
                    />
                  </Popover>
                }
                <span>Register colour</span>
              </div>
            </div>
          </div>
        </div>
        <div className="add-register__columns-section">
          <ColumnsContainer columns={ register.columns } updateItems={ this.updateColumns }
            clientId={ this.props.clientId } application="asset-register"
            updateMode={ this.props.updateMode }/>
        </div>
        <div className="add-register__fields-section">
          <AdditionalFieldsContainer additionalFields={ register.additionalFields }
            updateItems={ this.updateAdditionalFields } application="asset-register"
            updateMode={ this.props.updateMode }/>
        </div>
        <div className="add-register__buttons-section">
          <button className="button-green cancel-button" onClick={ this.props.closeAddEdit }>CANCEL</button>
          <button className="button-green"
            onClick={ () => this.saveRegister() }>{ register.id ? 'UPDATE' : 'SAVE' }</button>
        </div>
      </div>
        : ''
    )
  }
}

AddRegister.propTypes = {
  register: PropTypes.object,
  closeAddEdit: PropTypes.func.isRequired,
  clientId: PropTypes.number,
  user: PropTypes.object,
  updateMode: PropTypes.bool
}

const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id,
  user: state.userReducer.user
})

export default connect(mapStateToProps)(AddRegister)