import swal from 'sweetalert2'

import { apiUserContactSales } from '../api/User'
import { getName } from '../utils'
import locker from '../assets/icon-lock.svg'

const LockedMessage = {
  show: (user, plan, type) => {
    swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      html: content(plan),
      imageUrl: locker,
      imageWidth: 70,
      imageHeight: 70,
      padding: 50
    }).then((result) => {
      if (result.value) {
        const phoneNumber = document.getElementById('phone').value
        
        const data = {
          email: user.email,
          name: getName(user),
          phone: phoneNumber
        }
        apiUserContactSales(data)
  
        swal.fire({
          padding: 50,
          showConfirmButton: false,
          text: 'This message will close automatically.',
          type: 'success',
          timer: 3000
        })
      }
    })
  }
}

const content = (plan) => (
  `<p style="font-size: 14px; margin: 10px 0; text-align: left; line-height: 25px;">
      You've reached your limit of ${ plan.upperlimit } free DPIA's on our Free plan. To get unlimited DPIA's please request a call back below to speak with our sales team.
    </p>
    <div style="margin: 10px 0;">
      <label for="phone" style="color: #AAAAAA; display: block; font-size: 14px; margin-bottom: 10px; text-align: left; text-transform: uppercase;">
        Phone number
      </label>
      <input id="phone" type="text" style="border: 1px solid #CCCCCC; border-radius: 5px; box-sizing: border-box; color: #555555; font-size: 18px; font-weight: 300; padding: 10px; width: 100%;" />
    </div>
    <p style="font-size: 14px; margin: 10px 0; text-align: left; line-height: 25px;">
      The phone number is not mandatory, the sales team can contact you via email.
    </p>`
)

export default LockedMessage