import React from 'react'
import PropTypes from 'prop-types'

import ConnectorCard from './ConnectorCard'
import ConnectorTable from './ConnectorTable'

import './ConnectorList.css'

const ConnectorList = ({ actionDelete, actionRescan, chosenView, connectors }) => {
  if (chosenView === 'cards') {
    return (
      <div id="connector-list" className="connectorList__wrapperCard">
        { connectors.map((connector) => (
          <ConnectorCard
            actionRescan={ actionRescan }
            connector={ connector }
            key={ connector.id } />
        ))}
      </div>
    )
  }

  return (
    <div className="connectorList__wrapperTable">
      <table>
        <thead>
          <tr>
            <th className="connectorList__cellDataCollector">
              Data collector
            </th>
            <th className="connectorList__cellDataSize">
              Data size
            </th>
            <th style={{ textAlign: 'center' }}>Objects</th>
            <th style={{ textAlign: 'center' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          { connectors.map((connector) => (
            <ConnectorTable
              actionRescan={ actionRescan }
              connector={ connector }
              key={ connector.id } />
          ))}
        </tbody>
      </table>
    </div>
  )
}

ConnectorList.propTypes = {
  actionRescan: PropTypes.func.isRequired,
  chosenView: PropTypes.string.isRequired,
  connectors: PropTypes.array.isRequired
}

export default ConnectorList