export default class PDFJs {
  init = (source, element) => {
    while (element.firstChild) {
      element.removeChild(element.firstChild)
    }
    const iframe = document.createElement('iframe')

    iframe.src = `/libs/pdfjs/web/viewer.html?file=${ source }`
    iframe.width = '100%'
    iframe.height = '100%'

    element.appendChild(iframe)
  }
}