import React from 'react'
import '../AssetRegister.scss'
import { customConfirm } from '../../../../utils/AssetRegister/CustomConfirm'
import AddEditAssetRegister from './AddEditAssetRegister'
import PropTypes from 'prop-types'
import { Icon } from 'antd'
import moment from 'moment'

class AssetTable extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      openCollapse: false,
      expandRow: null,
      allRegisters: [],
      visibleCollapse: [],
      openTable: false
    }
  }

  componentDidMount() {
    if (this.props.allRegisters) {
      this.setState({ allRegisters: this.props.allRegisters })
    }
    this.someFn()
    this.visibleTable()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      this.setState({ allRegisters: this.props.allRegisters, visibleCollapse: [] })
    }
  }
  
  showConfirm = (item) => {
    const temp = this
    customConfirm({
      confirmAction: () => temp.props.deleteAssetRegister(item),
      title: 'Do you want to delete',
      content: `${ item } ?`,
      confirmButtonText: 'DELETE',
      cancelButtonText: 'CANCEL',
      mask: true,
      maskClosable: false,
      closeHaveLogic: false,
      className: 'asset-register-modal',
      centered: true,
      cancelClassName: 'cancel-button',
      confirmClassName: 'confirm-button'
    })
  }

  toggleIsLoading = () => {
  }

  collapseAll = (e) => {
    this.setState({ visibleCollapse: [] })
    e.preventDefault()
  }

  onClickCollapse = (key, visibleCollapse, registerType, registerManagerType, accordion) => {
    const { allRegisters } = this.props
    let visibleCollapseTemp = this.state.visibleCollapse
    if (allRegisters) {
      if (accordion) {
        visibleCollapseTemp = []
      }
      visibleCollapseTemp[key] = !visibleCollapse
      this.setState({
        expandRow: registerManagerType,
        openCollapse: !this.state.openCollapse,
        visibleCollapse: visibleCollapseTemp
      })
    } else {
      this.setState({
        expandRow: registerManagerType,
        openCollapse: !this.state.openCollapse
      })
    }
  }

  onClickIconEdit = () => {
    this.setState({
      displayIconEdit: !this.state.displayIconEdit,
      displayIconDelete: false
    })
  }

  onClickIconDelete = () => {
    this.setState({
      displayIconDelete: !this.state.displayIconDelete,
      displayIconEdit: false
    })
  }

  onClickVisibleTable = () => {
    this.setState({
      openTable: !this.state.openTable
    })
  }
  generateContent = (value, column) => {
    if (column) {
      let response = ''
      switch (column.type) {
        case 'FILE' :
          let src = ''
          if (value && typeof value === 'string') {
            value = JSON.parse(value)
            src = value.file
          }
          response = <img className="ar-image image-item" src={ src } alt=""/>
          break
        case 'CHECKBOX' :
          if (value) {
            if (typeof value === 'object') {
              value = value.join(', ')
            }
          } else {
            value = <span className="span-item"> - </span>
          }
          response = <span className="span-item">{ value }</span>
          break
        case 'DATE' :
          response = <span>{moment(value).format(column.dateFormat)}</span>
          break
        case 'ADDRESS_FINDER' :
          let assetLocationName = '-'
          if (value && typeof value === 'string') {
            const unwrappedObject = JSON.parse(value)
            assetLocationName = `${ unwrappedObject['name'] }, ${ unwrappedObject['city'] }`
          } else {
            assetLocationName = `${ value['name'] }, ${ value['city'] }`
          }
          response = <span>{ assetLocationName }</span>
          break
        default:
          response = <span className="span-item">{ value ? value : '-' }</span>
          break
      }
      return response
    }
  }

  someFn = () => {
    this.props.myCallbackOpenCollapse(this.state.openCollapse)
  }

  visibleTable = () => {
    this.props.myCallbackOpenTable(this.state.openTable)
  }

  render() {
    const { register, activeDeleteAsset, activeAccessAsset, activeFindAsset, changeIconVisible, activeAddAsset } = this.props
    const { openTable } = this.state

    return (
      <div className="display-tables-page">
        <div className="content-title-of-table">
          <span className="title-of-table" style={{ background: register.library?.color ? register.library.color : '#cccccc' }}>{ register.library?.name }</span>
        </div>
        <div className="ar-page-content-table">
          <div className="tr th table-header">
            { register.library && register.library.columns && register.library.columns.length > 0 &&
            register.library.columns.map((column, index) => {
              return (
                <div className="td" key={ index }>
                  <span>{ column.name }</span>
                </div>
              )
            })
            }
            <div className="td title-icon" style={ { justifyContent: 'center', flexGrow: 0.5 } }>
              { activeFindAsset ?
                <div className="content-icons">
                  <span className="icon-edit-b" style={{ color: 'black' }} onClick={ () => this.onClickIconEdit() }/>
                  <span className="icon-delete-b" style={{ color: 'black' }}onClick={ () => this.onClickIconDelete() }/>
                </div> :
                <div className="content-icons">
                  <span className="content-icons-visible-table" onClick={() => this.onClickVisibleTable()}>
                    <i className={this.state.openTable || changeIconVisible || activeFindAsset || activeDeleteAsset || activeAccessAsset || activeAddAsset ? 'fa fa-caret-up' : 'fa fa-caret-down'}
                    />
                  </span>
                </div>
              }
            </div>
          </div>

          { register && register.values && register.values.length > 0 &&
          register.values.map((value, index) => {
            return (
              <div className={openTable || changeIconVisible || activeFindAsset || activeDeleteAsset || activeAccessAsset || activeAddAsset ? 'tr table-row' : 'table-visible'} key={ index }>
                <div className="table-row__line">
                  { Object.keys(value).map((key, index2) => {
                    const exist = register.library.columns.map((column, index3) => {
                      if (key === column.id) {
                        return true
                      }
                      return false
                    })
                    if (exist) {
                      const html = this.generateContent(value[key], register.library.columns[index2])
                      if (html) {
                        return (
                          key !== 'id' &&
                          <div className="td" style={ { justifyContent: 'flex-start' } } key={ index2 }>
                            { this.generateContent(value[key], register.library.columns[index2]) }
                          </div>
                        )
                      }
                    }
                    return true
                  })
                  }
                  <div className="td actions" style={ { justifyContent: 'center', flexGrow: 0.5 } }
                    onClick={ activeDeleteAsset || this.state.displayIconDelete ?
                      () => this.showConfirm(value['id']) :
                      activeAccessAsset || this.state.displayIconEdit ?
                        () => this.onClickCollapse(index, this.state.visibleCollapse[index], register.library.id, value.id, true) :
                        register.library.additionalFields.length ? () => this.onClickCollapse(index, this.state.visibleCollapse[index], register.library.id, value.id, false) : () => null
                    }>
                    { activeDeleteAsset || this.state.displayIconDelete ?
                      <span className="icon-delete-b" style={{ color: 'black' }}/> :
                      (activeAccessAsset || this.state.displayIconEdit) && this.state.visibleCollapse[index] ?
                        <Icon type={ 'close' } /> : activeAccessAsset ?
                          <span className="icon-edit-b" style={{ color: 'black' }}/> : register.library.additionalFields.length ?
                            <Icon type={ this.state.visibleCollapse[index] ? 'close' : 'down' } />
                            : <span/>
                    }
                  </div>
                </div>
                <div className="table-row__accordion-container">
                  { this.state.visibleCollapse[index] &&
                  <div className="tr table-row">
                    <div className="hidden-row w-100">
                      <div className="content-collapse">
                        <AddEditAssetRegister
                          action="edit"
                          toggleIsLoading={ this.toggleIsLoading }
                          onClickCollapse={ this.onClickCollapse }
                          collapseAll = { this.collapseAll }
                          selectedRegisterObj={ register }
                          fieldsValues={ value }
                          registerTypeManager={ value.id }
                          submitForm={ this.props.submitForm }
                          activeAccessAsset = { this.props.activeAccessAsset }
                        />
                      </div>
                    </div>
                  </div>
                  }
                </div>
              </div>
            )
          })
          }
        </div>
      </div>
    )
  }

}

AssetTable.propTypes = {
  allRegisters: PropTypes.array.isRequired,
  submitForm: PropTypes.func,
  deleteAssetRegister: PropTypes.func
}

export default AssetTable