export default {
  sarVisible: true,
  docLockerVisible: true,
  assetRegisterVisible: true,
  smartBoxVisible: true,
  dpiaVisible: true,
  workflowAppVisible: true,
  dataMappingAppVisible: true,
  additionalEnvironmentsVisible: true,
  applicationIntervals: [],
  businessProcessesVisible: true,
  sourceMappingVisible: true,
  connectorsVisible: true,
  //resellerAccount: true,
  //resellerProfile: null,
}