import React from 'react'
import iconChat from '../../../assets/icon-chat.png'
import Accordion from '../../Common/Accordion'
import Button from '../../Common/Button'
import './Help.scss'

class Help extends React.Component {
  state = {
    accordion: {},
    faq: {
      'General': [{
        question: 'How can I change my settings?',
        answer: 'By clicking on the Home icon and then on the Osprey Settings. You will be able to change your name, your company details and your password.'
      }, {
        question: 'How can I change my password?',
        answer: 'By clicking on the Home icon and then on the Osprey Settings. On the bottom of the page there is a "I want to change my password" button.'
      }, {
        question: 'How can I invite a user?',
        answer: 'By clicking on the Home icon and then on the Invite user. You just need to enter the email address of the person you want to send the invitation.'
      }, {
        question: 'My invitation was not send: Can I resend my invitation? ',
        answer: 'By clicking on the Home icon and then on the Manage Groups. You will see the option of "Resend Invite" next to the user\'s email.'
      }, {
        question: 'What is the difference between an admin and a user?',
        answer: 'By changing the status of a person from user to admin he will have access on the creation of a Business Process, he will be able to invite a user and manage groups. Also, he will be able to create a custom questionnaire (only applies to subscription plan). In the user status, he will only be able to answer the Business Process that is assigned to him.'
      }, {
        question: 'How can I assign a user to admin?',
        answer: 'By clicking on the Home icon and then on the Manage Groups. You can change the status from user to admin by clicking on the ADMIN/USER button next to the user\'s name. If you changed your mind you can always change the status from admin to user.'
      }, {
        question: 'How can I subscribe to the Unlimited plan?',
        answer: 'In order to subscribe to the Unlimited plan you need to request a call from the "Request a call back" button located on the DPIA Dashboard page. You will need to provide a phone number(optional) or we will contact you through an email.'
      }, {
        question: 'What does the Unlimited plan include?',
        answer: 'If you subscribe to our Unlimited plan you will be able to create as many Business Processes, DPIA\'s and PIA\'s you want and also have access to our PIA/DPIA Builder where you can make your own custom questionnaires and assign them to your Business Processes.'
      }, {
        question: 'Can I unsubscribe at anytime?',
        answer: 'Yes, you can unsubscribe anytime you want.'
      }, {
        question: 'How can I unsubscribe?',
        answer: 'In order to unsubscribe you will need to request a call from the "Request a call back" button located on the DPIA Dashboard page. You will need to provide a phone number(optional) or we will contact you through an email.'
      }, {
        question: 'What is the difference between the Free and the Unlimited plan?',
        answer: 'With the Free plan you are only able to create up to 5 Business Processes and you cannot have access to our PIA/DPIA Builder. With the Unlimited plan you will be able to create as many Business Processes, DPIA\'s and PIA\'s you want and also have access to our PIA/DPIA Builder where you can make your own custom questionnaires and assign them to your Business Processes.'
      }],
      'Business Processes': [{
        question: 'Why do I need a Business Process?',
        answer: 'Things like CV’s, User Signup forms, Employee reviews, Subscription services. All these have business processes around them, for which the data is collected and processed. Osprey has some pre-set common processes to get you started evaluating any typically risky processes.'
      }, {
        question: 'How do I make a Business Process?',
        answer: 'By clicking on the DPIA icon and then either on the New Process or through the Dashboard page by clicking the "+Add new Business Process" button. You are able to make either your own Business Process or select one from our predefined common Business Processes.'
      }, {
        question: 'How do I assign a Business Process to another user?',
        answer: 'During the creation of your Business Process you have the option to assign a Business Process to a specific user. This can be done by selecting the user you want to assign the Business Process through the dropdown option on the Assign to user section. Keep in mind that you can only assign a Business Process to a specific user only during the creation time.'
      }, {
        question: 'What is the difference between a Data Processor and a Data Controller?',
        answer: 'The Data Controller determines the purposes for which and the means by which personal data is processed. On the other hand, the Data Processor processes personal data only on behalf of the controller.'
      }, {
        question: 'What is a PIA?',
        answer: 'A PIA checks your Business Process for sensitive or non-sensitive data. If the risk level score of your PIA is less than the limit, then there is no need for you to take a detailed DPIA. Otherwise, you will be advised to take a detailed DPIA.'
      }, {
        question: 'What is a DPIA?',
        answer: 'A DPIA is an evaluation of the origin, nature, particularity and severity of the risk to the rights and freedoms of natural persons before processing personally identifiable information (PII) and should include the measures, safeguards and mechanisms envisaged for mitigating the identified risks. A DPIA can be established for specific business processes (established or new) and will need re-evaluating periodically to meet compliance requirements.'
      }, {
        question: 'Why do I need a DPIA?',
        answer: 'To reduce a project’s privacy risks. DPIAs help to identify and address risks at an early stage by analysing the proposed uses of PII together with related technologies for use, to then check how the PII and technology will work together in practice to identify potential compliance risks and proposing methods for mitigating such risks to the point of compliance.'
      }, {
        question: 'How can I terminate a Business Process?',
        answer: 'You can always terminate your Business Process by going on the DPIA Dashboard, select the Business Process you wish to terminate and click on the Edit Business Process button. You will be directed to the Edit page where you can click on the "Terminate Business Process" button.'
      }, {
        question: 'My Business Process limit is reached: How can I create more Business Processes?',
        answer: 'In order for you to create more Business Processes you need to subscribe to our Unlimited plan.'
      }, {
        question: 'Why is my Business Process locked?',
        answer: 'A Business Process may appear as locked in case you have unsubscribed. If you unsubscribe from our Unlimited plan any Business Process you have created outside the free ones will appear as locked.'
      }, {
        question: 'Why my Business Process moved to the NON SENSITIVE section?',
        answer: 'A Business Process will move to the NON SENSITIVE section if the risk level score of your PIA is less than the limit. In this case you do not need to take a detailed DPIA.'
      }, {
        question: 'What is the Archive section?',
        answer: 'In the Archive section located under the Dashboard section, all the terminated Business Processes will be stored. '
      }, {
        question: 'How can I add a custom template on my Business Process?',
        answer: 'During the creation of your Business Process you can assign it a custom PIA or DPIA template by clicking on the dropdown option that appears on the Use Templates section. You can either select a default template or the custom one you want (only applies to subscription plan).'
      }, {
        question: 'How can I choose the version of my custom template while creating a new Business Process?',
        answer: 'During the creation of your Business Process you can select the PIA or DPIA template version you want by clicking on the dropdown option that appears on the Use Templates section (only applies to subscription plan).'
      }, {
        question: 'How can I make my Business Process recurring?',
        answer: 'During the creation of your Business Process you can choose your recurring schedule by clicking on the YES button located on the bottom of the screen. You have the options of selecting monthly, 3 months, 6 months and yearly repetition. '
      }],
      'Reports': [{ 
        question: 'How can I see my DPIA report?',
        answer: 'After completing and submiting your DPIA you will be able to view your DPIA report. You can perform this action by selecting the DPIA you want to view the report on the DPIA Dashboard and then click on the "DPIA Report" icon.'
      }, {
        question: 'What information is displayed on my DPIA Report?',
        answer: 'On the DPIA Report you will be able to see your privacy risk indicators and how you scored on each one of them. '
      }, {
        question: 'What is the difference between a DPIA Report and a Detailed Report?',
        answer: 'A DPIA Report provides you information regarding your privacy risk indicators scoring while the Detailed Reports gives you a full list of your questions and answers. '
      }, {
        question: 'Can I print my report?',
        answer: 'Yes, you can print your report by clicking on the "Print" button on each report page.'
      }, {
        question: 'Why I cannot see my report?',
        answer: 'In case you have unsubscribed from the Unlimited plan you might not be able to see your report because your Business Process is locked. Another case of not being able to view your report could be that you have answered No in the first question on the global templates. '
      }],
      'Questionnaire Builder': [{
        question: 'What is the Questionnaire Builder?',
        answer: 'The Questionnaire Builder allows the user to customise a PIA or DPIA questionnaire. The user will be able to create custom templates of questionnaires and assign them to their Business Processes (only applies to subscription plan).'
      }, {
        question: 'How can I make a custom Questionnaire?',
        answer: 'You will be able to create your own custom questionnaire by clicking on the DPIA icon and then on the Questionnaires. Then you only need to click on the "Create New Questionnaire" button located on the top of the page.'
      }, {
        question: 'What is the difference between a Draft state and a Publish state?',
        answer: 'If your questionnaire is on a Draft state you can do any number of modifications you want and save it. If you decide that your version is final you can publish it. If you publish the questionnaire you are still able to make changes but every time you publish the questionnaire will be saved with a different version. On Published state you are only allowed to have only 10 versions.'
      }, {
        question: 'How many versions my Questionnaire can have?',
        answer: 'Your custom questionnaire can have up to 10 versions.'
      }, {
        question: 'Can I use the default question templates on my custom Questionnaire?',
        answer: 'Yes, you can always use the default PIA/DPIA templates on your custom Questionnaire.'
      }, {
        question: 'How many times can I save a draft?',
        answer: 'A draft can be saved as many times as you want.'
      }, {
        question: 'I don\'t need a custom questionnaire anymore: Can I delete it?',
        answer: 'No, a questionnaire cannot be deleted but you can always set it as INACTIVE.'
      }, {
        question: 'I accidentally published my questionnaire: Can I revert it?',
        answer: 'No, once the state of the questionnaire becomes published it cannot be set to draft again. You can always edit the published questionnaire up to 10 versions or make a new one.'
      }, {
        question: 'Can I delete a specific version of my custom questionnaire?',
        answer: 'No, you cannot delete a specific version of your custom questionnaire but you can always set the version as INACTIVE. This means that you will not be able to select this specific version during the creation of your Business Process.'
      }, {
        question: 'Can I edit the global question templates?',
        answer: 'No, the global question templates cannot be edited, deleted or modified. '
      }, {
        question: 'How can I edit a specific version?',
        answer: 'You can always edit a specific version by selecting the specific version and clicking on the edit button next to it. '
      }, {
        question: 'How can I add a question on my custom template?',
        answer: 'By clicking on the (+) icon next to a question you can add a new one below this question.'
      }, {
        question: 'How can I remove a question on my custom template?',
        answer: 'By clicking on the (x) icon on the question you want to remove. '
      }, {
        question: 'How can I delete multiple questions on my custom template?',
        answer: 'By clicking on the square next to each question you want to delete and then select the "Delete selected" option from the dropdown on the SELECT ALL - SELECT NONE section. In case you want to delete everything you can just click on the SELECT ALL and then on the "Delete selected" option from the dropdown.'
      }, {
        question: 'What values should I entered on the Question weight field?',
        answer: 'The question weight field can only take values between 0 and 5.'
      }, {
        question: 'What values should I entered on the Score field?',
        answer: 'The score field can only take 0 or 1 as values.'
      }, {
        question: 'Is there a limit on how many questions I can add?',
        answer: 'Currently there is no limit.'
      }, {
        question: 'Why I cannot see the Questionnaire Builder?',
        answer: 'In order to have access to the Questionnaire Builder you need to subscribe to our Unlimited plan.'
      }],
      'Answering a Questionnaire': [{ 
        question: 'How can I start answering a questionnaire?',
        answer: 'By assigning the questionnaire to a Business Process and start answering the DPIA.'
      }, { 
        question: 'Can I skip some questions?',
        answer: 'You can always skip some questions in case you do not know the answer but in order to submit you must answer everything.'
      }, { 
        question: 'How can I submit?',
        answer: 'After answering everything a "Submit as completed" button will appear that you can click on and submit.'
      }, { 
        question: 'I have skipped some questions: Why I cannot submit?',
        answer: 'In order for the "Submit as completed" button to appear you need to answer everything. In case you have skipped some questions you need to select them and answer them.'
      }, { 
        question: 'Can I leave my questionnaire half answered and then come back?',
        answer: 'Yes, you can leave the questionnaire incomplete and answer it later on. '
      }, { 
        question: 'How can I select a specific question from my questionnaire?',
        answer: 'When answering a questionnaire you will spot a dotted navigation bar on the top. By clicking on any numbered dot you can access the question you want. Another way to access a specific question is by clicking the question you want on the Questions section on the left navigation bar. '
      }]
    },
    selectedFaq: 'General'
  }

  componentDidMount() {
    window.scroll(0, 0)
  }

  changeSelectedFaq = (selected) => this.setState({
    accordion: {}, selectedFaq: selected
  })
  
  toggleAccordion = (index) => {
    const { accordion } = this.state

    this.setState({
      accordion: { ...accordion, [index]: !accordion[index] }
    })
  }

  render() {
    const { accordion, faq, selectedFaq } = this.state

    return (
      <div className="dpiaHelp__page">
        <div className="dpiaHelp__box">
          <header className="dpiaHelp__box-header">
            <h2>Help &amp; Support</h2>
          </header>
          <div className="dpiaHelp__box-body">
            <div className="dpiaHelp__box-menu--wrapper">
              <h2>Table of contents</h2>
              <ul className="dpiaHelp__box-menu">
                { Object.keys(faq).map((item, index) => (
                  <li
                    key={ `list-${ index }` }
                    className={ `dpiaHelp__box-listitem ${ item === selectedFaq ? 'dpiaHelp__box-listitem--active' : '' } ` }
                    onClick={ this.changeSelectedFaq.bind(this, item) }>
                    { item }
                  </li>
                ))}
              </ul>
            </div>
            <div className="dpiaHelp__box-content">
              <div className="dpiaHelp__box-content--list">
                { faq[selectedFaq].map((question, index) => (
                  <li key={ `question-${ index }` } className="dpiaHelp__box-content--item">
                    <Accordion
                      header={
                        <div
                          className={ accordion[index] ? 'dpiaHelp__item--opened' : 'dpiaHelp__item--closed' }
                          onClick={ this.toggleAccordion.bind(this, index) }>
                          <h3>{ question.question }</h3>
                        </div>
                      }
                      opened={ accordion[index] }
                      content={
                        <p>{ question.answer }</p>
                      }
                    />
                  </li>
                )) }
              </div>
            </div>
          </div>
        </div>

        <div className="dpiaHelp__info hidden">
          <img src={ iconChat } alt="Open a ticket" />
          <p>Still can’t find what your looking for? Open a support ticket below.</p>
        </div>

        <div className="dpiaHelp__box hidden">
          <header className="dpiaHelp__box-header">
            <h2>Open a support ticket</h2>
          </header>
          <div className="dpiaHelp__form">
            <p>You’ll receive a reply at (myles@snyx.com)</p>
            <div className="dpiaHelp__form-group">
              <label>Summary of your issue</label>
              <input type="text" />
            </div>

            <div className="dpiaHelp__form-group">
              <label>Please describe your issue in as much detail as possible</label>
              <textarea></textarea>
            </div>

            <Button theme="green">
              Submit support ticket
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default Help