import React, { Component } from 'react'
import Icon from 'antd/es/icon'
import './DocumentNote.scss'
import PropTypes from 'prop-types'
import ReactQuill from 'react-quill'
import { Spin } from 'antd'
import { getDocNotes, saveDocNotes } from '../../../../api/Sar'
import { getSmartBoxDocNotes, saveSmartBoxDocNotes } from '../../../../api/SmartBox'
import cookie from "react-cookies";
import CustomNotificationManager from "../../../Common/CustomNotificationManager";

class DocumentNote extends Component {
  state = {
    documentNote: {
      id: null,
      docId: null,
      note: ''
    },
    theme: 'snow',
    saveLoading: false,
    dbNote: ''
  }

  modules = {
    toolbar: [
      [ { 'header': '1' }, { 'header': '2' }, { 'font': [] } ],
      [ { size: [] } ],
      [ { align: [] } ],
      [ 'bold', 'italic', 'underline', 'strike' ],
      [ { 'color': [] }, { 'background': [] } ],
      [ { 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' } ],
      [ 'link', 'image' ]
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    }
  }

  componentDidMount() {
    if (this.props.documentId) {
      this.fetchData()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.documentId !== this.props.documentId) {
      this.fetchData()
    }
  }

  fetchData = () => {
    if (this.props.isSmartBoxApplication) {
      getSmartBoxDocNotes(this.props.documentId).then((res) => {
        if (res.data) {
          this.setState({
            documentNote: res.data,
            dbNote: res.data.note
          })
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    } else {
      getDocNotes(this.props.documentId).then((res) => {
        if (res.data) {
          this.setState({
            documentNote: res.data,
            dbNote: res.data.note
          })
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    }
  }
  
  saveDocumentNote = () => {
    if (this.state.documentNote.docId) {
      this.setState({
        saveLoading: true
      }, () => {
        this.saveData(this.state.documentNote)
      })
    } else {
      this.setState({
        saveLoading: true,
        documentNote: {
          ...this.state.documentNote, docId: this.props.documentId
        }
      }, () => {
        this.saveData(this.state.documentNote)
      })
    }
  }

  saveData = (data) => {
    if (this.props.isSmartBoxApplication) {
      saveSmartBoxDocNotes(data).then((res) => {
        if (res.status === 200) {
          this.props.addNotifyDocumentNote(res.data.note)
          cookie.save('sendNoteResponse', res.data.note, {path: '/'})
          this.setState({
            documentNote: res.data,
            saveLoading: false,
            dbNote: res.data.note
          })
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    } else {
      saveDocNotes(data).then((res) => {
        this.setState({
          documentNote: res.data,
          saveLoading: false,
          dbNote: res.data.note
        })
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    }
  }
  
  closeDocumentNote = () => {
    const { dbNote, documentNote } = this.state
    if (dbNote !== documentNote.note) {
      if (this.state.documentNote.docId) {
        this.saveData(this.state.documentNote)
      } else {
        this.setState({
          saveLoading: true,
          documentNote: {
            ...this.state.documentNote, docId: this.props.documentId
          }
        }, () => {
          this.saveData(this.state.documentNote)
        })
      }
      this.props.closeDocumentNote()
    } else {
      this.props.closeDocumentNote()
    }
  }

  render() {
    const { theme, documentNote, saveLoading } = this.state
    return (
      <div className="document-note-page">
        <div className="document-note-page-container">
          <div className="document-note-page-container__header">
            <span className="document-note-page-container__header--text">DOCUMENT NOTE</span>
            <span className="document-note-page-container__header--close-icon">
              <Icon type="close" onClick={ this.closeDocumentNote }/>
            </span>
          </div>
          <div className="document-note-page-container__content">
            <ReactQuill
              theme={ theme }
              onChange={ (value) => {
                this.setState({
                  documentNote: {
                    ...this.state.documentNote, note: value
                  }
                })
              }
              }
              value={ documentNote.note }
              modules={ this.modules }
            />
          </div>
          <div className="document-note-page-container__footer">
            <button className="button-green cancel-button" disabled={ saveLoading } onClick={ this.props.closeDocumentNote }>CANCEL</button>
            { saveLoading ?
              <Spin className="save-loading" spinning={ saveLoading }/> :
              <button className="button-green" onClick={this.saveDocumentNote}>SAVE</button>
            }
          </div>
        </div>
      </div>
    )
  }
}

DocumentNote.propTypes = {
  closeDocumentNote: PropTypes.func.isRequired,
  documentId: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
  isSmartBoxApplication: PropTypes.bool
}

export default DocumentNote