import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Select } from 'antd'
import { getBpList, apiDoewnloadCSVPia } from '../../../api/Dpia'
import { clickedRequestSalesCall, userIsAdmin, userIsSuperAdmin, debounce, userIsUser } from '../../../utils'
import Assessment from './Assessment'
import ButtonAddBusinessProcess from '../ButtonAddBusinessProcess/ButtonAddBusinessProcess'
import LimitedFreeAccount from '../Plan/LimitedFreeAccount'
import UnlimitedAccount from '../Plan/UnlimitedAccount'
import Loading from '../../Common/Loading'
import WhatIsBusinessProcessPia from '../WhatIsBusinessProcessPia/WhatIsBusinessProcessPia'
import { Unlocked } from './Type/Unlocked'
import LockedHeader from './Type/Locked'
import ProcessDetailsLocked from './ProcessDetails/Locked'
import ProcessDetailsUnLocked from './ProcessDetails/UnLocked'
import Pagination from '../../Privacy/Form/Pagination'
import Button from '../../Common/Button'
import '../../../style/common.scss'
import './Dashboard.scss'
import { apiTeamFetchTeamsList } from '../../../api/Team'
import withRouter from '../../../private-routers/withRouter'
const Option = Select.Option
const { parse } = require('json2csv')

export class Dashboard extends Component {
  _isMounted = false;

  constructor(props) {
    super(props)

    this.state = {
      accordion: {},
      isLoading: true,
      dpiaList: [],
      piaList: [],
      nonSensitiveList: [],
      list: [],
      listComplete: [],
      showLearnMessage: true,
      activePage: 1,
      activePagePia: 1,
      activePageDpia: 1,
      activePageNonsensitive: 1,
      chosenView: 'cards',
      filterTerm: '',
      resultsPerPage: 10,
      resultsPerPagePia: 5,
      resultsPerPageDpia: 5,
      resultsPerPageNonsensitive: 5,
      numberOfPia: 6,
      numberOfDpia: 6,
      numberOfNonsensitive: 6,
      numberOfViewBp: 6,
      usersList: [],
      teamsList: [],
      sortBy: '',
      sortDir: '',
      displaySort: 'all',
      serverStatus: 200,
      filterInput: '',
      findText: null,
      buttonClass: 'newBusiness',
      inputValue: '',
      classNameInput: 'filterCollectionItem-inputFind',
      classNameInputFocus: 'filterCollectionItem-inputFindFocus',
      classNameFilterStyle: '',
      filterSort: null,
      teamId: null,
      completion: null,
      totalBussinessProcess: [],
      typeBp: null,
      BussinessProcess: [],
      questionnaireType: '',
      assessmentStatusEnum: null,
      bpCompletionStatus: null,
      piaTemplateId: '',
      questionnaireIdPia: '',
    }

    this.dismissLearnMessage = this.dismissLearnMessage.bind(this)
    this.toggleAccordion = this.toggleAccordion.bind(this)

    this.filterByTerm = debounce(this.filterByTerms.bind(this), 1000)
  }

  componentDidMount() {
    this._isMounted = true
    window.scroll(0, 0)
    this.apiPiaFilterList()
    this.apiDpiaFilterList()
    this.apiNonSensitiveFilterList()
    this.getTeamsList()
    this.apiViewFilterList()

  }

  componentWillUnmount() {
    this._isMounted = false
  }

  refreshFilter() {
    if (this.state.chosenView === 'cards') {
      this.fetchAllBp()
    } else if (this.state.chosenView === 'table')
    {
      this.apiViewFilterList()
    }

  }
  toggleChosenView = () => {
    const oldView = this.state.chosenView
    this.setState({
      chosenView: oldView === 'cards' ? 'table' : 'cards',
      teamId: null,
      filterSort: null,
      bpCompletionStatus: null,
      typeBp: null,
    }, () => this.refreshFilter())

  }

  changePagination = (/*changePagination,*/ page) => {
    this.setState({ activePage: page }, () => this.apiViewFilterList())
    //changePagination(page)
  }
  changePaginationPia = (/*changePagination,*/ page) => {
    this.setState({ activePagePia: page }, () => this.apiPiaFilterList())
    //changePagination(page)
  }
  changePaginationDpia = (/*changePagination,*/ page) => {
    this.setState({ activePageDpia: page }, () => this.apiDpiaFilterList())
    //changePagination(page)
  }
  changePaginationNonsensitive = (/*changePagination,*/ page) => {
    this.setState({ activePageNonsensitive: page }, () => this.apiNonSensitiveFilterList())
    //changePagination(page)
  }

  filterByTerms(event) {
    event.persist()
    this.setState({
      activePage: 1,
      filterTerm: event.target.value
    })
  }

  apiViewFilterList(data) {
    data = {
      'pageNumber': this.state.activePage,
      'amountPerPage': this.state.resultsPerPage,
      'sortEnum': this.state.filterSort,
      'name': this.state.findText,
      'type': this.state.typeBp,
      'status': 'ACTIVE',
      'userId': this.props.user.id,
      'teamId': this.state.teamId ? this.state.teamId : null,
      'sort': this.state.completion ? this.state.completion : null,
      'subscription': this.props.subscription,
      'assessmentStatusEnum': this.state.assessmentStatusEnum,
      'nonSensitive': false,
      'bpCompletionStatus': this.state.bpCompletionStatus
    }
    getBpList(data)
      .then((response) => {
        let totalBussinessProcess
        response.data.total > 0 ? totalBussinessProcess = response.data.businessProcesses : totalBussinessProcess = null
        if (this._isMounted) {
          this.setState({
            isLoading: false,
            totalBussinessProcess: totalBussinessProcess,
            numberOfViewBp: response.data.total,
            usersList: [...this.state.usersList, ...response.data.usersList]
          })
        }
      })

  }

  apiPiaFilterList(data) {
    data = {
      'pageNumber': this.state.activePagePia,
      'assessmentStatusEnum': 'IN_PROGRESS',
      'amountPerPage': this.state.resultsPerPagePia,
      'sortEnum': this.state.filterSort,
      'name': this.state.findText,
      'type': 'PIA_QUESTIONNAIRE',
      'nonSensitive': false,
      'status': 'ACTIVE',
      'userId': this.props.user.id,
      'teamId': this.state.teamId ? this.state.teamId : null,
      'sort': this.state.completion ? this.state.completion : null,
      'subscription': this.props.subscription,
      'questionnaireType': this.state.questionnaireType,
      'bpCompletionStatus': this.state.bpCompletionStatus
    }
    getBpList(data)
      .then((response) => {
        let piaList
        response.data.total > 0 ? piaList = response.data.businessProcesses : piaList = null
        if (this._isMounted && piaList) {
          this.setState({
            isLoading: false,
            piaList: piaList,
            numberOfPia: response.data.total,
            usersList: [...this.state.usersList, ...response.data.usersList],
            piaTemplateId: response.data.piaTemplateId,
            questionnaireIdPia: response.data.questionnaireIdPia
          })
        } else {
          this.setState({
            piaList: []
          })
        }
      })

  }

  apiDpiaFilterList(data) {
    data = {
      'pageNumber': this.state.activePageDpia,
      'amountPerPage': this.state.resultsPerPageDpia,
      'sortEnum': this.state.filterSort,
      'name': this.state.findText,
      'type': 'DPIA_QUESTIONNAIRE',
      'nonSensitive': false,
      'status': 'ACTIVE',
      'userId': this.props.user.id,
      'teamId': this.state.teamId ? this.state.teamId : null,
      'sort': this.state.completion ? this.state.completion : null,
      'subscription': this.props.subscription,
      'questionnaireType': this.state.questionnaireType,
      'bpCompletionStatus': this.state.bpCompletionStatus
    }
    getBpList(data)
      .then((response) => {
        let dpiaList
        response.data.total > 0 ? dpiaList = response.data.businessProcesses : dpiaList = null
        if (this._isMounted && dpiaList) {
          this.setState({
            isLoading: false,
            dpiaList: dpiaList,
            numberOfDpia: response.data.total,
            usersList: [...this.state.usersList, ...response.data.usersList]
          })
        } else {
          this.setState({
            dpiaList: []
          })
        }
      })

  }

  apiNonSensitiveFilterList(data) {
    data = {
      'pageNumber': this.state.activePageNonsensitive,
      'amountPerPage': this.state.resultsPerPageNonsensitive,
      'userId': this.props.user.id,
      'sortEnum': this.state.filterSort,
      'name': this.state.findText,
      'status': 'ACTIVE',
      'teamId': this.state.teamId ? this.state.teamId : null,
      'sort': this.state.completion ? this.state.completion : null,
      'subscription': this.props.subscription,
      'nonSensitive': true,
      'type': 'PIA_QUESTIONNAIRE',
      'questionnaireType': this.state.questionnaireType,
      'bpCompletionStatus': this.state.bpCompletionStatus
    }
    getBpList(data)
      .then((response) => {
        let nonSensitiveList
        response.data.total > 0 ? nonSensitiveList = response.data.businessProcesses : nonSensitiveList = null
        if (this._isMounted && nonSensitiveList) {
          this.setState({
            isLoading: false,
            nonSensitiveList: nonSensitiveList,
            numberOfNonsensitive: response.data.total,
            usersList: [...this.state.usersList, ...response.data.usersList]
          })
        } else {
          this.setState({
            nonSensitiveList: []
          })
        }
      })

  }

  getTeamsList() {
    apiTeamFetchTeamsList()
      .then((response) => {
        if (this._isMounted) {
          this.setState({
            teamsList: response.data,
          })
        }
      })
  }

  toggleAccordion(accordion, index) {
    this.setState({
      accordion: {
        ...accordion,
        [index]: !accordion[index]
      }
    })
  }

  renderAssessmentList(process, type) {
    let { assessments } = process
    if (type === 'PIA_QUESTIONNAIRE') {
      assessments = assessments.filter((assessment) => assessment.type === 'PIA_QUESTIONNAIRE')
    }
    let name
    if (process.assignedToUserID) {
      name = this.state.usersList.filter((user) => user.id === process.assignedToUserID).map((user) => user.name)[0]
    } else {
      name = process.assignedToTeam
    }
    let disableButtons = false
    if (process.assignedToUserID === this.props.user.id) {
      disableButtons = true
    }
    if (process.assignedToTeamID) {
      this.state.teamsList.forEach((team) => {
        if (process.assignedToTeamID === team.id) {
          if (team.createdByUserId === this.props.user.id) {
            return disableButtons = true
          }
          team.members.forEach((member) => {
            if (member.userId === this.props.user.id) {
              return disableButtons = true
            }
          })
        }
      })
    }
    return assessments.map((assessment) => (
      <Assessment
        disableButtons={ disableButtons }
        name={ name }
        assessment={ assessment }
        key={ assessment.id }
        process={ process }
        isAdmin={ userIsAdmin(this.props.user) }
        isSuperAdmin={ userIsSuperAdmin(this.props.user) }
        type={ type }
        processStatus="ACTIVE"
      />
    ))
  }

  renderProcess(process, type) {
    return (
      <React.Fragment>
        { process.isLocked &&
        <ProcessDetailsLocked
          process={ process }
          reoccurringMessage={ this.formatReoccurringMessage(process) }
          type={ type }
        />
        }

        { !process.isLocked &&
        <ProcessDetailsUnLocked
          process={ process }
          reoccurringMessage={ this.formatReoccurringMessage(process) }
          isAdmin={ userIsAdmin(this.props.user) || userIsSuperAdmin(this.props.user) }
          type={ type }
        />
        }
        { this.renderAssessmentList(process, type) }
      </React.Fragment>
    )
  }

  renderProcessList(processList, type) {
    const { accordion } = this.state
    const { user, subscription } = this.props

    return processList.map((process) => {
      if (process.assessments.length) {
        const props = {
          accordion,
          content: this.renderProcess(process, type),
          index: process.assessments[0].id,
          process,
          subscription,
          type,
          processStatus: 'ACTIVE',
          dpiaQuestionnaireType: this.dpiaQuestionnaireType(process),
          user,
          toggleAccordion: this.toggleAccordion,
          usersList: this.state.usersList,
          chosenView: this.state.chosenView
        }
        if (process.isLocked) return (
          <LockedHeader key={ process.id } { ...props } />
        )
        return (<Unlocked key={ process.id } { ...props } teams= {this.state.teamsList} />)
      }
      return null
    })
  }

  dismissLearnMessage() {
    this.setState({
      showLearnMessage: false
    })
  }

  onFocusChange() {
    this.setState({
      buttonClass: 'newBusinessOnFocus',
      classNameInputFocus: 'filterCollectionItem-inputFindFocus',
      classNameFilterStyle: 'filterStyle'
    })
  }

  onBlurChange() {
    this.setState({
      classNameInput: 'filterCollectionItem-inputFind',
      buttonClass: 'newBusiness'
    })
  }

  updateFilterSearch(e) {
    const searchedText = e.target.value
    if (searchedText !== '' && searchedText.length > 2) {
      const timeout = null
      clearTimeout(timeout)
      setTimeout(function() {
        this.fetchAllBp()
      }.bind(this), 1000)
    } else if (searchedText === '') {
      this.fetchAllBp()
    }
    this.setState({
      findText: searchedText,
      activePagePia: 1,
      activePageDpia: 1,
      activePage: 1
    })
  }

  addProcessText(processList) {
    if (processList.length === 0) {
      return 'Create your first Business Process'
    }
    return 'Create a Business Process'
  }

  formatReoccurringMessage(process) {
    if (!process.isReoccurring) return null

    const messages = {
      '1': '1 month',
      '3': '3 months',
      '6': '6 months',
      '12': '12 months'
    }

    return (
      `Recurring every ${ messages[process.frequencyCount] }`
    )
  }

  dpiaQuestionnaireType({ dpiaQuestionnaireType }) {
    const groups = {
      'DATA_CONTROLLER_DPIA': 'Data Controller',
      'DATA_PROCESSOR_DPIA': 'Data Processor',
      '': 'I don\'t know'
    }
    return groups[dpiaQuestionnaireType]
  }

  updateFilters(e, field) {
    const value = e

    if (field === 'selectedDisplay' && this.state.chosenView === 'cards') {
      switch (value) {
        case 'PIA_QUESTIONNAIRE':
          this.setState({
            filterSort: value,
            typeBp: 'PIA_QUESTIONNAIRE',
            activePagePia: 1,
            dpiaList: [],
            assessmentStatusEnum: 'IN_PROGRESS'
          }, () => this.fetchPiaPb())
          break
        case 'DPIA_QUESTIONNAIRE':
          this.setState({
            filterSort: value,
            typeBp: 'DPIA_QUESTIONNAIRE',
            activePageDpia: 1,
            piaList: [],
            nonSensitiveList: [],
            assessmentStatusEnum: null
          }, () => this.apiDpiaFilterList())
          break
        default:
          this.setState({
            filterSort: null,
            activePagePia: 1,
            activePageDpia: 1,
            activePage: 1,
          }, () => this.fetchAllBp())
          break
      }
    }

    if (field === 'selectedDisplay' && this.state.chosenView === 'table') {
      switch (value) {
        case 'PIA_QUESTIONNAIRE':
          this.setState({
            filterSort: value,
            typeBp: 'PIA_QUESTIONNAIRE',
            activePagePia: 1,
            dpiaList: [],
            nonSensitiveList: [],
            assessmentStatusEnum: 'IN_PROGRESS'
          }, () => this.apiViewFilterList())
          break
        case 'DPIA_QUESTIONNAIRE':
          this.setState({
            filterSort: value,
            typeBp: 'DPIA_QUESTIONNAIRE',
            activePageDpia: 1,
            piaList: [],
            nonSensitiveList: []
          }, () => this.apiViewFilterList())
          break
        default:
          this.setState({
            filterSort: null,
            typeBp: null,
            activePagePia: 1,
            activePageDpia: 1,
            activePage: 1,

          }, () => this.apiViewFilterList())
          break
      }
    }
  }

  onSelectSort(e, field) {
    const value = e
    const filterSort = this.state.filterSort
    if (field === 'selectedSort' && this.state.chosenView === 'cards') {
      if (filterSort === 'PIA_QUESTIONNAIRE') {
        this.setState({
          bpCompletionStatus: value,
          activePagePia: 1,
        }, () => this.fetchPiaPb())
      } else if (filterSort === 'DPIA_QUESTIONNAIRE') {
        this.setState({
          bpCompletionStatus: value,
          activePageDpia: 1,
        }, () => this.apiDpiaFilterList())
      } else {
        this.setState({
          bpCompletionStatus: value,
          activePagePia: 1,
          activePageDpia: 1,
          activePageNonsensitive: 1
        }, () => this.fetchAllBp())
      }
    }

    if (field === 'selectedSort' && this.state.chosenView === 'table') {
      if (filterSort === 'PIA_QUESTIONNAIRE') {
        this.setState({
          bpCompletionStatus: value,
          activePage: 1,
        }, () => this.apiViewFilterList())
      } else if (filterSort === 'DPIA_QUESTIONNAIRE') {
        this.setState({
          bpCompletionStatus: value,
          activePage: 1,
        }, () => this.apiViewFilterList())
      }
      else {
        this.setState({
          bpCompletionStatus: value,
          activePage: 1,
        }, () => this.apiViewFilterList())
      }
    }
  }

  onSelectTeam(e, field) {
    const value = e
    const filterSort = this.state.filterSort
    if (field === 'teamSelected' && this.state.chosenView === 'cards') {
      if (filterSort === 'PIA_QUESTIONNAIRE') {
        this.setState({
          teamId: value,
          activePagePia: 1,
        }, () => this.apiPiaFilterList())
      } else if (filterSort === 'DPIA_QUESTIONNAIRE') {
        this.setState({
          teamId: value,
          activePageDpia: 1,
        }, () => this.apiDpiaFilterList())
      } else {
        this.setState({
          teamId: value,
          activePagePia: 1,
          activePageDpia: 1
        }, () => this.fetchAllBp())
      }
    }

    if (field === 'teamSelected' && this.state.chosenView === 'table') {
      if (value === null) {
        this.setState({
          teamId: value,
        }, () => this.apiViewFilterList())
      } else {
        this.setState({
          teamId: value,
        }, () => this.apiViewFilterList())
      }
    }
  }

  fetchPiaPb() {
    this.apiPiaFilterList()
    this.apiNonSensitiveFilterList()
  }

  fetchAllBp() {
    this.apiPiaFilterList()
    this.apiDpiaFilterList()
    this.apiNonSensitiveFilterList()
  }

  fetchCSVPia(clientId, clientName) {

    apiDoewnloadCSVPia(clientId, clientName)
      .then((response) => {
        this.exportCSV(response.data, clientId)
      })
  }

  exportCSV(content, clientId) {

    const fields = ['clientId', 'companyName', 'bpId', 'bpTitle', 'assessmentId', 'assessmentType', 'questionnaireTitle', 'questionTitle', 'questionType', 'questionAnswer', 'score']
    const opts = { fields }
    if (content) {
      const csvContent = parse(content, opts)
      const csvContentFile = `data:text/csv;charset=utf-8,${ csvContent }`
      const encodedUri = encodeURI(csvContentFile)
      const link = document.createElement('a')

      link.setAttribute('href', encodedUri)
      link.setAttribute('download', `assessment-${ clientId }-bp-summary-export.csv`)
      link.innerHTML = ''
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
  }

  render() {

    const {
      listComplete,
      isLoading,
      showLearnMessage,
      classNameInput,
      classNameInputFocus,
      classNameFilterStyle,
      findText
    } = this.state

    const dpiaList = this.state.dpiaList
    const piaList = this.state.piaList
    const nonSensitiveList = this.state.nonSensitiveList
    const { user, subscription } = this.props
    const isAdmin = userIsAdmin(user)
    const isUser = userIsUser(this.props.user)
    const isSuperAdmin = userIsSuperAdmin(user)
    return (
      <div className="dpiaDashboard-page">
        { showLearnMessage &&
        <div className="dpiaDashboard-learn">
          <Link to="/dpia/about" className="dpiaDashboard-learnLink">
            Learn about creating Business Process PIA's and DPIA's. Visit our About Assessments section for more.
          </Link>
          <span className="dpiaDashboard-learnClose" onClick={ this.dismissLearnMessage }/>
        </div>
        }
        <div className="filterDpia__wrapper">
          <div className="mu_filter__header">
            <div className="questionnaireFilter__wrapper">
              <div className="questionnaireFilter__button">
                <label>View</label>
                <div className="filterCollectionItem-viewButton" onClick={ this.toggleChosenView }>
                  { this.state.chosenView === 'table' &&
                  <span className="iconCards"/>
                  }
                  { this.state.chosenView === 'cards' &&
                  <span className="iconTable"/>
                  }
                </div>
              </div>
              <div className="questionnaireFilter__button">
                <label>Find</label>
                <input type='text'
                  className={ (findText === '' || findText === null) ? (classNameInput) : (classNameInputFocus) }
                  onBlur={ () => this.onBlurChange() } onFocus={ () => this.onFocusChange() }
                  onChange={ (e) => this.updateFilterSearch(e, 'filterInput') }/>
              </div>
              <div id={ classNameFilterStyle } className="questionnaireFilter__select">
                <label>Teams</label>
                <Select id="questionnaireFilter__teams" onChange={ (e) => this.onSelectTeam(e, 'teamSelected') }
                  value={ this.state.teamId }>
                  <Option value={ null }>All</Option>
                  {
                    this.state.teamsList.map((item) => item.enabled && (
                      <Option key={ item.id } value={ item.id }>
                        { item.name }
                      </Option>
                    ))
                  }
                </Select>
              </div>
              <div id={ classNameFilterStyle } className="questionnaireFilter__select">
                <label>Display</label>
                <Select id="questionnaireFilter__teams" onChange={ (e) => this.updateFilters(e, 'selectedDisplay') }
                  value={ this.state.filterSort }>
                  <Option value={ null }>View All</Option>
                  <Option value="PIA_QUESTIONNAIRE">PIA only</Option>
                  <Option value="DPIA_QUESTIONNAIRE">DPIA only</Option>
                </Select>
              </div>
              <div id={ classNameFilterStyle } className="questionnaireFilter__select">
                <label>Sort</label>
                <Select id="questionnaireFilter__teams" onChange={ (e) => this.onSelectSort(e, 'selectedSort') }
                  value={ this.state.bpCompletionStatus }>
                  <Option value={ null }>View All</Option>
                  <Option value="COMPLETED">Completion</Option>
                  <Option value="IN_PROGRESS">Incompletion</Option>
                </Select>
              </div>
            </div>
            { !isUser ?
              (<div className="filterDpia__item-button">
                <Link to="/dpia/new">
                  <Button theme={ this.state.buttonClass }>
                    New business process
                  </Button>
                </Link>
              </div>) : (<div></div>)
            }
          </div>
        </div>
        { isLoading &&
        <div className="dpiaDashboard-loading">
          <Loading/>
        </div>
        }
        { !isLoading &&
        !dpiaList &&
        !piaList &&
        !nonSensitiveList &&
        <WhatIsBusinessProcessPia/>
        }

        { !isLoading && this.state.chosenView === 'cards' &&
        piaList && piaList.length > 0 &&
        <div>
          <h2 className="dpiaDashboard__block-title">PIA</h2>
          { this.renderProcessList(piaList, 'PIA_QUESTIONNAIRE') }
          { isSuperAdmin &&
          <div className="button-download-csv">
            <button className='button_big_blue'
              onClick={ () => this.fetchCSVPia(this.props.clientId, this.props.subscription.subscription.client.name) }>DOWNLOAD
              CSV
            </button>
          </div>
          }
          { piaList.length ?
            (<div>

              <React.Fragment>
                <React.Fragment>
                  <div className="dpiaDashboard-separator">
                    <div className="pagination__box">
                      <header className="pagination__box__header">
                        <div>
                          { piaList && piaList.length > 0 &&
                          <span className="pagination__box__header__total">
                            <strong>{ this.state.numberOfPia } results</strong>
                          </span>
                          }
                          <p className="pagination__box__header__text">
                            Page { this.state.activePagePia } of { Math.ceil(this.state.numberOfPia / this.state.resultsPerPagePia) }
                          </p>
                        </div>
                        <Pagination
                          activePage={ this.state.activePagePia }
                          changePagination={ this.changePaginationPia }
                          pages={ Math.ceil(this.state.numberOfPia / this.state.resultsPerPagePia) }/>
                      </header>
                    </div>
                  </div>
                </React.Fragment>
              </React.Fragment>

            </div>) : (<div/>)
          }

        </div>
        }

        { !isLoading && this.state.chosenView === 'cards' &&
        dpiaList && dpiaList.length > 0 &&
        <div>
          <h2 className="dpiaDashboard__block-title">DPIA</h2>
          { this.renderProcessList(dpiaList, 'DPIA_QUESTIONNAIRE') }
          { dpiaList.length ?
            (<div>

              <React.Fragment>
                <React.Fragment>
                  <div className="dpiaDashboard-separator">
                    <div className="pagination__box">
                      <header className="pagination__box__header">
                        <div>
                          { dpiaList && dpiaList.length > 0 &&
                          <span className="pagination__box__header__total">

                            <strong>{ this.state.numberOfDpia } results</strong>

                          </span>
                          }
                          <p className="pagination__box__header__text">
                            Page { this.state.activePageDpia } of { Math.ceil(this.state.numberOfDpia / this.state.resultsPerPageDpia) }
                          </p>
                        </div>
                        <Pagination
                          activePage={ this.state.activePageDpia }
                          changePagination={ this.changePaginationDpia }
                          pages={ Math.ceil(this.state.numberOfDpia / this.state.resultsPerPageDpia) }/>
                      </header>
                    </div>
                  </div>
                </React.Fragment>
              </React.Fragment>

            </div>) : (<div/>)
          }
        </div>
        }

        { !isLoading && this.state.chosenView === 'cards' &&
        nonSensitiveList && nonSensitiveList.length > 0 &&
        <div>
          <h2 className="dpiaDashboard__block-title">NON SENSITIVE</h2>
          { this.renderProcessList(nonSensitiveList, 'NON SENSITIVE') }
          { nonSensitiveList.length ?
            (<div>

              <React.Fragment>
                <React.Fragment>
                  <div className="dpiaDashboard-separator">
                    <div className="pagination__box">
                      <header className="pagination__box__header">
                        <div>
                          {nonSensitiveList && nonSensitiveList.length > 0 &&
                                <span className="pagination__box__header__total">

                                  <strong>{ this.state.numberOfNonsensitive }<p
                                    className="styleResults">results</p></strong>

                                </span>
                          }
                          <p className="pagination__box__header__text">
                            Page { this.state.activePageNonsensitive } of { Math.ceil(this.state.numberOfNonsensitive / this.state.resultsPerPageNonsensitive) }
                          </p>
                        </div>
                        <Pagination
                          activePage={ this.state.activePageNonsensitive }
                          changePagination={ this.changePaginationNonsensitive }
                          pages={ Math.ceil(this.state.numberOfNonsensitive / this.state.resultsPerPageNonsensitive) }/>
                      </header>
                    </div>
                  </div>
                </React.Fragment>
              </React.Fragment>

            </div>) : (<div/>)
          }
        </div>
        }

        { !isLoading && this.state.chosenView === 'table' && this.state.totalBussinessProcess &&

        <div>
          <div id="collection-list" className="collectionList__wrapperTable">
            <table>
              <thead>
                <tr>
                  <th className="collectionList__cellSmaller sort-column" style={ { textAlign: 'center' } }>
                  TYPE
                  </th>
                  <th className="sort-column" style={ { textAlign: 'center' } }>
                  QUESTIONNAIRE TYPE
                  </th>
                  <th className="collectionList__cellTitle sort-column">
                  BUSINESS PROCESS
                  </th>
                  <th className="sort-column">
                  STATUS
                  </th>
                  <th className="sort-column">
                  COMPLETE
                  </th>
                  <th className="sort-column" style={ { textAlign: 'center' } }>
                  QUESTIONS
                  </th>
                  <th style={ { textAlign: 'center' } }>ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                { this.renderProcessList(this.state.totalBussinessProcess, 'table') }
                <tr>
                  <td id="styleColSpan" colSpan="6"/>
                </tr>
              </tbody>
            </table>
          </div>
          <React.Fragment>
            <React.Fragment>
              <div className="dpiaDashboard-separator">
                <div className="dpiaDashboard__box">
                  { this.state.totalBussinessProcess.length ?
                    (<header className="dpiaDashboard__boxHeader">
                      <div>
                        { this.state.totalBussinessProcess.length > 0 &&
                        <span className="dpiaDashboard__boxTitleTotal">
                          <strong>{ this.state.numberOfViewBp }</strong> results
                        </span>
                        }
                        <p className="dpiaDashboard__boxText">
                          Page { this.state.activePage } of { Math.ceil(this.state.numberOfViewBp / this.state.resultsPerPage) }
                        </p>
                      </div>
                      <Pagination
                        activePage={ this.state.activePage }
                        changePagination={ this.changePagination }
                        pages={ Math.ceil(this.state.numberOfViewBp / this.state.resultsPerPage) }/>
                    </header>) : (<div></div>)
                  }
                </div>
              </div>
            </React.Fragment>
          </React.Fragment>
        </div>
        }

        { (isAdmin || isSuperAdmin) ? (
          <div className="dpiaDashboard-separator">
            <div className="dpiaDashboard-footerContainer">
              <div className="dpiaDashboard-buttonAddContainer">
                <ButtonAddBusinessProcess
                  title={ this.addProcessText(listComplete) }/>
              </div>
              <div className="dpiaDashboard-limitedFreeAccountContainer">
                {
                  subscription.plan &&
                  !subscription.unlimitedPlan &&
                  <LimitedFreeAccount
                    plan={ subscription.plan }
                    onClick={ clickedRequestSalesCall.bind(this, this.props.user) }
                  />
                }

                {
                  subscription.plan &&
                  subscription.unlimitedPlan &&
                  <UnlimitedAccount subscription={ subscription }/>
                }
              </div>
            </div>
          </div>
        ) : (
          <div/>
        ) }
      </div>
    )
  }
}

const stateMap = (state) => ({
  user: state.userReducer.user,
  subscription: state.subscriptionReducer.subscription,
  clientId: state.subscriptionReducer.subscription.subscription.client.id
})
Dashboard = withRouter(Dashboard)
Dashboard.propTypes = {
  user: PropTypes.object.isRequired,
  subscription: PropTypes.object.isRequired
}

export default compose(
  connect(stateMap, null)
)(Dashboard)