import React from 'react'
import PageTitle from '../../../../Common/PageTitle'

export const ManagerUsersHeader = () => {
  return (
    <div className="manageUsers-box manageUsers-box-update">
      <PageTitle title="Manage Users" />
      <div className="manageUsers-section">
        {/*<h2 className="manageUsers-sectionSubtitle">Here you can assign users to admin or denote from admin. An Admin has the ability to:</h2>*/}
        {/*<h2 className="manageUsers-sectionSubtitle">*/}
        {/*  <b> 1)</b> see every team's activities,*/}
        {/*  <b> 2)</b> sign users to a team,*/}
        {/*  <b> 3)</b> create Business Processes and*/}
        {/*  <b> 4)</b> assign Business Processes to a Team*/}
        {/*</h2>*/}
      </div>
    </div>
  )
}