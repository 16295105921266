import React from 'react'
import PropTypes from 'prop-types'
import DataLineWindows from '../Visualisations/DataLineWindows'
import './ProcessTable.css'
import { apiOspreyTerminateProcess } from '../../../../api/OspreyAnalytics.js'

class ProcessTable extends React.Component {
  constructor() {
    super()

    this.state = { isMenuOpened: false }
  }

  toggleMenu = () => {
    this.setState({ isMenuOpened: !this.state.isMenuOpened })
  }

  endProcess = (processId) => {
    apiOspreyTerminateProcess(processId)
      .then((response) => {  
        console.log(response)
        // no need to do anything I think?
      })
      .catch((error) => {
        console.log(error, 'error')
      })
  }

  render = () => {
    const { process } = this.props
    
    return (

      <tr>
        <td className='processTable__td'>{ process.id }</td>
        <td className='processTable__td'>{ process.description }</td>
        <td className='processTable__td'>{ process.category }</td>
        <td className='processTable__td display-speed'>{ <DataLineWindows windows={ process.progresswindows } field={ 'processed' } /> }</td>
        <td className='processTable__td'>{ process.total }</td>
        <td className='processTable__td'>{ process.state }</td>
        <td className='processTable__td'>{ process.type }</td>
        <td className='processTable__stop' onClick={ (e) => this.endProcess(process.id) }><div>X</div></td>
      </tr>
    )
  }
}
  
ProcessTable.propTypes = {
  process: PropTypes.object.isRequired
}

export default ProcessTable