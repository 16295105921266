import React from 'react'
import Statistic from './Statistic'
import LineChart from './LineChart'
import MapPoint from './MapPoint'
import { Tabs } from 'antd'
import PropTypes from 'prop-types'
import { getManagerStatistics, getYearQuarterlyMonthlyStatistics, getMapStatistics } from '../../../../api/AssetRegister'

class StatisticList extends React.Component {

  constructor() {
    super()
    this.state = {
      statisticsTitle: '',
      totalAssetsData: {},
      listCities: [],
      monthState: {},
      quarterState: {},
      yearState: {}
    }
  }

  componentDidMount() {
    this.listCitiesMaps()
    this.getLineChartState()
    getManagerStatistics(this.props.clientId).then((response) => {
      if (response.status === 200 && response.data) {
        this.setState({ totalAssetsData: response.data.results })
      }
    })
  }

  listCitiesMaps = () => {
    getMapStatistics().then((response) => {
      if (response.status === 200 && response.data) {
        response.data.map(function(city, index) {
          const temp = city.coordinates[0]
          response.data[index].coordinates[0] = city.coordinates[1]
          response.data[index].coordinates[1] = temp
          return true
        })
        this.setState({
          listCities: response.data
        })
      }
    })
  }

  getLineChartState = () => {
    getYearQuarterlyMonthlyStatistics().then((response) => {
      if (response.status === 200 && response.data) {
        let monthState = { labels: ['JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE', 'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'], values: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] }
        if (response.data.assetManagerPeriodDTOMonth && response.data.assetManagerPeriodDTOMonth.labels.length && response.data.assetManagerPeriodDTOMonth.values.length) {
          monthState = { labels: response.data.assetManagerPeriodDTOMonth.labels, values: response.data.assetManagerPeriodDTOMonth.values }
        }
        const quarterState = { labels: ['JAN-MAR', 'APR-JUN', 'JUL-SEP', 'OCT-DEC'], values: [0, 0, 0, 0] }
        if (response.data.assetManagerPeriodDTOQuarterOne && response.data.assetManagerPeriodDTOQuarterOne.values.length) {
          quarterState.values[0] = (response.data.assetManagerPeriodDTOQuarterOne.values).reduce(function(acc, val) { return acc + val }, 0)
        }
        if (response.data.assetManagerPeriodDTOQuarterTwo && response.data.assetManagerPeriodDTOQuarterTwo.values.length) {
          quarterState.values[1] = (response.data.assetManagerPeriodDTOQuarterTwo.values).reduce(function(acc, val) { return acc + val }, 0)
        }
        if (response.data.assetManagerPeriodDTOQuarterThree && response.data.assetManagerPeriodDTOQuarterThree.values.length) {
          quarterState.values[2] = (response.data.assetManagerPeriodDTOQuarterThree.values).reduce(function(acc, val) { return acc + val }, 0)
        }
        if (response.data.assetManagerPeriodDTOQuarterFour && response.data.assetManagerPeriodDTOQuarterFour.values.length) {
          quarterState.values[3] = (response.data.assetManagerPeriodDTOQuarterFour.values).reduce(function(acc, val) { return acc + val }, 0)
        }
        let yearState = { labels: ['2015', '2016', '2017', '2018', '2019'], values: [0, 0, 0, 0, 0] }
        if (response.data.assetManagerPeriodDTOYear && response.data.assetManagerPeriodDTOYear.labels.length && response.data.assetManagerPeriodDTOYear.values.length) {
          yearState = { labels: response.data.assetManagerPeriodDTOYear.labels, values: response.data.assetManagerPeriodDTOYear.values }
        }
        this.setState({
          monthState: monthState,
          quarterState: quarterState,
          yearState: yearState,
        })
      }
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      const { activeStatisticsTab } = this.props
      this.setState({ statisticsTitle: activeStatisticsTab === 'Total assets' ? 'Total assets in categories' : activeStatisticsTab })
    }
  }

  render() {
    const { statisticsTitle, totalAssetsData, monthState, quarterState, yearState } = this.state
    const { TabPane } = Tabs
    return (
      totalAssetsData && statisticsTitle && statisticsTitle !== 'Total register' &&
        <div className="statistic-wrapper">
          <div>
            {statisticsTitle && statisticsTitle === 'Total assets in categories' ?
              <i className="up custom-caret"/> : statisticsTitle === 'Total value of assets'? <i className="up custom-value"/> :
                <i className="up custom-map"/>
            }
          </div>
          <h2 className="statistic-wrapper__title">{ statisticsTitle } </h2>
          { statisticsTitle === 'Total assets in categories' &&
          <div className="statistic-line">
            { Object.keys(totalAssetsData).map(function(key, index) {
              return <Statistic title= { key } percent={ Math.round(totalAssetsData[key])} key={key} />
            })
            }
          </div>
          }
          {
            statisticsTitle === 'Assets locations' &&
              <MapPoint/>
          }
          { statisticsTitle === 'Total value of assets' &&
          <div className="line-chart-wrapper">
            <Tabs
              defaultActiveKey="monthly"
              animated={false}
              className="line-chart-wrapper"
            >
              <TabPane tab="Yearly" key="yearly">
                <LineChart lineChartStateLabels={ yearState.labels } lineChartStateValues={ yearState.values } />
              </TabPane>
              <TabPane tab="Quarterly" key="quarterly">
                <LineChart lineChartStateLabels={ quarterState.labels } lineChartStateValues={ quarterState.values } />
              </TabPane>
              <TabPane tab="Monthly" key="monthly">
                <LineChart lineChartStateLabels={ monthState.labels } lineChartStateValues={ monthState.values } />
              </TabPane>
            </Tabs>
          </div>
          }
        </div>
    )
  }
}

StatisticList.propTypes = {
  activeStatisticsTab: PropTypes.string.isRequired
}

export default StatisticList