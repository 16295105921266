import PropTypes from 'prop-types'
import React from 'react'
import './Area.scss'

const Area = ({ description, icon, title }) => (
  <div className="area-wrapper">
    <span className={ `iconArea${ icon }` } />
    <div className="area-info">
      <h2 className="area-title">{ title }</h2>
      <p className="area-description">{ description }</p>
    </div>
  </div>
)

Area.propTypes = {
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default Area