import axios from 'axios'

export const apiPrivacyGetTopics = (host) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/categoryGetProcessData`,
    data: {
      sessionID: token,
      categoryID: '25',
      processID: 'analyser_clusters'
    }
  })
}

export const apiPrivacyGetLocationsForTopic = (host, id, page) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/getDataModelQuery`,
    data: {
      sessionID: token,
      datamodel: 'documents',
      query: '*',
      fieldNames: ['doc_id'],
      filterQueries: [`system_property_cluster_id:${ id }`],
      offset: page,
      length: 10,
      sortField: '',
      ascending: true
    }
  })
}