import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import './CullReport.scss'
import CullReportTable from './CullReportTable'
import CullReportList from './CullReportList'
import { Tooltip } from 'antd'
class CullReport extends React.Component {
    state = {
        visibleCullList: false,
        selectCullCategory: {}
    }

    onVisibleCullList = (status) => {
        this.setState({
            visibleCullList: status
        })
    }
    selectFileCullCategory = (file) => {
        this.setState({
            selectCullCategory: file
        })
    }
    onVisibleCullPage = (status) => {
        this.setState({
            visibleCullList: status
        })
    }
    render() {
        const { visibleCullList, selectCullCategory } = this.state
        return (
            <div className="cull-report-page">
                <div className="cull-report-page__content">
                    <div className="cull-report-page__content__header">
                        <div className="title-sweeped">Culled data</div>
                        <Tooltip overlayClassName="help-message-locations" title={<div className="content-help-message-locations">
                            <span className="text-information">It will display all the files that have been deleted/removed from the "Cull" tab, the time this was done and who actioned them.</span>
                            <span className="more-info-link" onClick={ () => window.open('https://support.smartbox.ai/support/solutions/articles/47001236275-cull-report', '_blank') }>More info.</span>
                            </div>}>
                            <span className="icon-v14-question"></span>
                        </Tooltip>
                    </div>
                    { !visibleCullList ?
                        <div className="cull-report-page__content__cull-page">
                            <CullReportTable folder={ this.props.folder } onVisibleCullList={ this.onVisibleCullList }
                                             selectFileCullCategory={ this.selectFileCullCategory }
                                             activeTab={this.props.activeTab}/>
                        </div> :
                        <div className="cull-report-page__content__cull-page">
                            <CullReportList folder={ this.props.folder } selectCullCategory={ selectCullCategory }
                                            onVisibleCullPage={ this.onVisibleCullPage }/>
                        </div>
                    }
                </div>
            </div>
        )

    }
}

CullReport.propTypes = {
    folder: PropTypes.object,
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id,
})
export default compose(
    connect(mapStateToProps),
)(CullReport)