import React from 'react'
import PropTypes from 'prop-types'

import * as utils from '../utils/Processes'

import {
  apiPrivacyGetProcesses
} from '../api/Privacy'
import { connect } from 'react-redux'
import { addIntervalToApplication } from '../actions/applicationsActions'

const ProcessContext = React.createContext()

class ProcessProvider extends React.Component {
  state = {
    intervalId: 0,
    processes: [],
    isFetchingProcesses: true,
    totalProcesses: 0
  }
 
  componentWillUnmount = () => {
    clearInterval(this.state.intervalId)
  }
  
  timer = () => {
    this.getProcesses()
  }
  
  componentDidMount = () => {
    const intervalId = setTimeout(this.timer, 5000)
    this.props.addIntervalToApplication(intervalId)
    this.setState({
      intervalId: intervalId
    })
  }
  
  getProcesses = () => {
    apiPrivacyGetProcesses()
      .then((response) => {
        const processlist = utils.mappedProcesses(response)
        this.setState({
          processes: processlist,
          isFetchingProcesses: false,
          totalProcesses: processlist.length
        })
      })
      .catch((error) => {
        console.log(error, 'error')
      })
  }
  
  render = () => {
    return (
      <ProcessContext.Provider value={{
        getProcesses: this.getProcesses,
        processes: this.state.processes,
        isFetchingProcesses: this.state.isFetchingProcesses,
        totalProcesses: this.state.totalProcesses
      }}>
        { this.props.children }
      </ProcessContext.Provider>
    )
  }
}

ProcessProvider.propTypes = {
  children: PropTypes.element.isRequired
}

ProcessProvider.contextType = ProcessContext

const mapStateToProps = () => ({})

export default connect(mapStateToProps, { addIntervalToApplication })(ProcessProvider)
export const ProcessConsumer = ProcessContext.Consumer