import React from "react";
import {connect} from "react-redux";
import './ComponentsFormAdmin.scss';
import {Checkbox, Upload, Icon, Input} from "antd";
const { Dragger } = Upload
class FileUploadFormAdmin extends React.Component {
    state = {
        fileList: [],
        fileUploadForm: {
            queryForSearch: false,
            mandatoryCheck: false,
            options: null,
            header: 'File upload',
            sectionVariable: ''
        },
        hoverDelete: false,
        editHeader: false,
        hoverEditBtn:false,
    }
    componentDidMount() {
        const { component } = this.props
        this.setState({
            fileUploadForm: { ...this.state.fileUploadForm,
                queryForSearch: component.queryForSearch === undefined ? false : component.queryForSearch,
                mandatoryCheck: component.mandatory === undefined ? false : component.mandatory,
                options: component.options === undefined ? null : component.options,
                header: component.header === undefined ? 'File upload' : component.header,
                sectionVariable: component.sectionVariable === undefined ? '' : component.sectionVariable,
            }
        })
        //this.props.onChangeForm(this.state.fileUploadForm, this.props.component)
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props && this.props.checkStartSaved) {
            this.props.onChangeForm(this.state.fileUploadForm, this.props.component)
        }
    }
   onChangeUpload = (file) => {
       const fileUpload = []
       fileUpload.push(file.file)
       this.setState({
           fileList: [...this.state.fileList, file.file ]
       })
   }
    customUploadForm = (file, file2) => {
        this.customUploadFormFinally(file)
        const listUid = []
        file2 && file2.length > 0 && file2.forEach((list) => {
            listUid.push(list.uid)
        })
        this.setState({
            fileList: file2
        })
    }
    customUploadFormFinally(file) {
      return file
    }
    onChangeCheckbox = (e, key) => {
        switch (key) {
            case 'query':
                this.setState({
                    fileUploadForm: { ...this.state.fileUploadForm, queryForSearch: e.target.checked },
                }, () => {
                    this.props.onChangeForm(this.state.fileUploadForm, this.props.component)
                })
                break
            case 'mandatory':
                this.setState({
                    fileUploadForm: { ...this.state.fileUploadForm, mandatoryCheck: e.target.checked },
                }, () => {
                    this.props.onChangeForm(this.state.fileUploadForm, this.props.component)
                })
                break
            default:
                break
        }
    }
    onHoverActionComponent = () => {
        this.setState({
            hoverDelete: true
        })
    }
    onLeaveActionComponent = () => {
        this.setState({
            hoverDelete: false
        })
    }
    onHoverEditBtn = () => {
        this.setState({
            hoverEditBtn: true
        })
    }
    onLeaveEditBtn = () => {
        this.setState({
            hoverEditBtn: false
        })
    }
    onClickEditHeader = (e, status) => {
        if (status === 'header') {
            this.setState({
                editHeader: true
            })
        }
    }
    addTitleHeader = (e, status) => {
        if (status === 'header') {
            this.setState({
                fileUploadForm: { ...this.state.fileUploadForm, header: e.target.value },
                //editHeader: false
            }, () => {
                this.props.onChangeForm(this.state.fileUploadForm, this.props.component)
            })
        }
    }
    onChangeFormVariable = (e) => {
        this.setState({
            fileUploadForm: { ...this.state.fileUploadForm, sectionVariable: e.target.value },
        }, () => {
            this.props.onChangeForm(this.state.fileUploadForm, this.props.component)
        })
    }
    confirmEditHeader = () => {
        this.setState({
            editHeader:false,
        })
    }
    render() {
        const { fileList, fileUploadForm, hoverDelete, editHeader, hoverEditBtn} = this.state
        const { component } = this.props
        const props = {
            name: 'file',
            multiple: true,
            action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
            onChange(info) {
                const { status } = info.file;
                if (status !== 'uploading') {
                }
                if (status === 'done') {
                    message.success(`${info.file.name} file uploaded successfully.`);
                } else if (status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        }
        const uniqueVerificationVisited = fileList && fileList.length > 0 && fileList.filter((a, i) => fileList.findIndex((s) => a.uid === s.uid) === i)
        return (
            <div className={ this.props.snapshot.isDragging ? "component-form dragging " : "component-form"}>
                <div className="component-delete">
                    <span onClick={ (e) => this.props.showConfirmDeleteFormElement(e, component) }
                          className={ hoverDelete ? 'icon-v19-delete-active' : "icon-v19-delete" }
                          onMouseEnter={ (e) => this.onHoverActionComponent(e)}
                          onMouseLeave={() => this.onLeaveActionComponent()}></span>
                </div>
                <div className="component-content">
                    <div className="content-input-form-variables">
                        <span className="header-checkbox">
                           {/*<Checkbox onChange={(e) => this.onChangeCheckbox(e, 'query')}*/}
                            {/*          checked={ fileUploadForm.queryForSearch }>Query for search</Checkbox>*/}
                            <Checkbox onChange={(e) => this.onChangeCheckbox(e, 'mandatory')}
                                      checked={ fileUploadForm.mandatoryCheck }>Mandatory</Checkbox>
                        </span>
                        {/*<span className="content-variables">*/}
                        {/*     <span className="variables-diaz">#</span>*/}
                        {/*<Input*/}
                        {/*    className="input-form-variables"*/}
                        {/*    placeholder="Form variable"*/}
                        {/*    value={fileUploadForm.sectionVariable}*/}
                        {/*    onChange={(e) => this.onChangeFormVariable(e)}*/}
                        {/*/>*/}
                        {/*</span>*/}
                    </div>
                    <div className="header-component header-component-update">
                       <span className="header-content">
                              { editHeader ?
                                  <div>
                                      <Input defaultValue={fileUploadForm.header} className="input-other-option"
                                             onChange={ (e) => this.addTitleHeader(e, 'header')}
                                             onPressEnter={() => this.confirmEditHeader()}
                                      />
                                      <span className={ hoverEditBtn ? "icon-v21-edit-active" : "icon-v21-edit" }
                                            onClick={(e) => this.confirmEditHeader()}
                                            onMouseEnter={() => this.onHoverEditBtn()}
                                            onMouseLeave={() => this.onLeaveEditBtn()}
                                      ></span>
                                  </div>
                                   :
                                  <div>
                                      <span className="header-title header-title-update">{fileUploadForm.header}</span>
                                      <span className={ hoverEditBtn ? "icon-v21-edit-active" : "icon-v21-edit" }
                                            onClick={(e) => this.onClickEditHeader(e, 'header')}
                                            onMouseEnter={() => this.onHoverEditBtn()}
                                            onMouseLeave={() => this.onLeaveEditBtn()}
                                      ></span>
                                  </div>
                              }
                          </span>
                    </div>
                    <div className="file-upload-content">
                        <Dragger  {...props}
                                 fileList={ uniqueVerificationVisited }
                                 accept={ '.pdf, .png, .jpeg, .jpg' }
                                 listType="picture-card"
                                 multiple={ true }
                                 onChange={this.onChangeUpload}
                                  disabled={true}
                                 //beforeUpload={this.customUploadForm}
                        >
                            <p className="ant-upload-drag-icon">
                                <Icon type="cloud-upload" />
                            </p>
                            <p className="ant-upload-text">Browse Files</p>
                            <p className="ant-upload-hint">
                              Drag and drop files here
                            </p>
                        </Dragger>
                        </div>
                </div>
                <div className="component-move">
                    <span onMouseEnter={() => this.props.onStateProvidedDraggable(component)} className="icon-v211-grid-view-1"></span>
                </div>
            </div>
        )
    }
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(FileUploadFormAdmin)