import React from 'react'
// import Button from '../../../Common/Button'
import {Select, Tooltip} from 'antd'
import { getAnnotationsByClientId, uploadCsvOfAnnotations, uploadCsvOfAnnotationsForGodAdmin } from '../../../../api/Sar'
import { connect } from 'react-redux'
import { CSVLink } from 'react-csv'
import CSVReader from 'react-csv-reader'
import './TagsDocuments.scss'
import TagsTable from './TagsTable'
import CustomNotificationManager from "../../../Common/CustomNotificationManager";
import {apiGetTagDocumentClientAdmin} from "../../../../api/SmartBox";

const Option = Select.Option

class TagsDocuments extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            filter: {
                exemptionCode: '',
                annotationCategoryEnum: null
            },
            classNameFind: 'page-filter__header--filter-container__filter--search-inactive',
            classNameFindFocus: 'page-filter__header--filter-container__filter--search-active',
            tagsList: [],
            tagsListCopy: [],
            count: null,
            hoverAddAnnotation: false,
            hoverUpload: false,
            hoverUpload1: false,
            statusAddTags: false,
            countAddLength: 0,
            statusPendingSaveCustomTag: false,
        }
    }

    componentDidMount() {
        apiGetTagDocumentClientAdmin(this.props.clientId).then(({ data }) => {
            this.setState({
                tagsList: data,
                tagsListCopy: data,
                count: data.length
            })
        }).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
            }else{
                console.log(error)
            }
        })
        // getAnnotationsByClientId(this.props.clientId).then(({ data }) => {
        //     this.setState({
        //         annotations: data,
        //         annotationsCopy: data,
        //         count: data.length
        //     })
        // }).catch((error) => {
        //     if (error?.response?.status === 504) {
        //         CustomNotificationManager.cleanBuffer('warning', '')
        //     }else{
        //         console.log(error)
        //     }
        // })
    }

    onGetListTagsDocuments = () => {
        apiGetTagDocumentClientAdmin(this.props.clientId).then(({ data }) => {
            this.setState({
                tagsList: data,
                tagsListCopy: data,
                count: data.length
            })
        }).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
            }else{
                console.log(error)
            }
        })
    }
    addTags = () => {
        const { count, tagsList } = this.state
        let { countAddLength } = this.state
        let newKey = 0
        // let sortAnnotations = []
        // sortAnnotations = annotations.sort((userA, userB) => userA.key - userB.key)
        // for (let i = 0; i < annotations.length; i++) {
        //      if (annotations[i].key > annotations[annotations.length - 1].key) {
        //        countAddLength = annotations[i].key + 1
        //      } else {
        //        countAddLength = annotations[annotations.length - 1].key + 1
        //      }
        // }
        this.setStatusPendingSaveCustomTag(false)
        let maxIdTag = 0
        if (tagsList && tagsList.length > 0) {
            maxIdTag = Math.max(...tagsList.map(o => o.idTag))
        }
        countAddLength = tagsList.length === 0 ? tagsList.length + 1 : maxIdTag + 1
        const newData = {
            active: true,
            clientId: this.props.clientId,
            color: null,
            default: false,
            icon: null,
            idTag: countAddLength,
            action: 'Add'
        }
        tagsList.unshift(newData)
        this.setState({
                tagsList: tagsList,
                count: count + 1,
                //countAddLength: count + 1,
                statusAddTags: true
            }, () => this.state.tagsList,
            this.setState({
                countAddLength: count + 1
            }))
    }

    onDeleteTagAction = (list) => {
        this.setState({
            tagsList: list
        })
    }
    getStatusAddTags = (status) => {
        this.setState({
            statusAddTags: status
        })
    }

    updateParentTags = (newData) => {
        this.setState({
            tagsList: newData
        })
    }
    setStatusPendingSaveCustomTag = (status) => {
        this.setState({
            statusPendingSaveCustomTag : status,
        })
    }

    onHoverActionButtons = (e, status) => {
        switch (status) {
            case 'add':
                this.setState({
                    hoverAddAnnotation: true
                })
                break
            case 'upload':
                this.setState({
                    hoverUpload: true
                })
                break
            case 'upload1':
                this.setState({
                    hoverUpload1: true
                })
                break
            default: break
        }

    }
    onLeaveActionsButtons = () => {
        this.setState({
            hoverAddAnnotation: false,
            hoverUpload: false,
            hoverUpload1: false,
        })
    }

    render() {
        const { exemptionCode, annotationCategoryEnum } = this.state.filter
        const { hoverAddAnnotation, hoverUpload, hoverUpload1, statusAddTags } = this.state
        let { tagsList, statusPendingSaveCustomTag} = this.state
        if (exemptionCode && tagsList && tagsList.length > 0) {
            tagsList = tagsList.filter((annotation) => annotation.exemptionCode && annotation.exemptionCode.toLowerCase().includes(exemptionCode))
        }

        if (annotationCategoryEnum && tagsList && tagsList.length > 0) {
            tagsList = tagsList.filter((annotation) => annotation.annotationCategoryEnum && annotation.annotationCategoryEnum.includes(annotationCategoryEnum))
        }
        const headersListUsers = [
            { label: 'Exemption Code', key: 'exemptionCode' },
            { label: 'Details', key: 'details' },
            { label: 'Annotation Category', key: 'annotationCategoryEnum' },
            { label: 'Country Law', key: 'countryLawEnum' },
        ]
        const annotationList = [
            {
                exemptionCode: '',
                details: '',
                annotationCategoryEnum: '',
                countryLawEnum: ''
            }
        ]
        return (
            <div className="page-container page-container-tags page-container-tags-update ">
                <header className="annotation-header">
                    <h1 className="pageTitle-title page-title-update">Document statuses</h1>
                </header>
                <div className="page-filter">
                    <div className="page-filter__header">
                        <div className="page-filter__header--button-container">
                            <span className={ hoverAddAnnotation ? "icon-v21-add-active" : "icon-v21-add" }theme="newBusiness" onClick={this.addTags}
                                  onMouseEnter={(e) => this.onHoverActionButtons(e, 'add')}
                                  onMouseLeave={(e) => this.onLeaveActionsButtons(e)}></span>
                        </div>
                    </div>
                </div>
                <div className="page-content">
                    <TagsTable tagsList={tagsList} updateParentTags={ this.updateParentTags } statusAddTags={ statusAddTags }
                               getStatusAddTags={this.getStatusAddTags}
                               onDeleteTagAction={this.onDeleteTagAction}
                               onGetListTagsDocuments={this.onGetListTagsDocuments}
                               setStatusPendingSaveCustomTag={this.setStatusPendingSaveCustomTag}
                               statusPendingSaveCustomTag={statusPendingSaveCustomTag}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id,
    user: state.userReducer.user
})

export default connect(mapStateToProps, {})(TagsDocuments)