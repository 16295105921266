import React from 'react'
import { Tooltip, Icon, Checkbox, Row, Col } from 'antd'

function EmailWorkVariablesAdmin({ variabels, isNameLinkPersist, isMessagePersist= false, isResultUrlPersist= false, onChangeVariables, emailTemplate, checkedVariables, listVariables, variablesTemplate, onSelectVariable,
                                onDisabledSelectVariable }) {
    // React.useEffect(() => {
    // }, [isNameLinkPersist, isMessagePersist, isMessagePersist, isResultUrlPersist ])
    const defaultChecked = []
    emailTemplate.variables && emailTemplate.variables.length > 0 && emailTemplate.variables.forEach((item) => {
        defaultChecked.push(item)
    })
    const checkedVariablesUpdate = checkedVariables.concat(defaultChecked)
    const listMustContain = []
    const listAvailableVariables = []
    const listVariablesAll = []

    variablesTemplate && variablesTemplate.length > 0 && variablesTemplate.forEach((elem) => {
        if (elem.variableEmail) {
            listVariablesAll.push(elem.variableEmail.toLowerCase())
        } else if (elem.nameVariables) {
            listVariablesAll.push(elem.nameVariables.toLowerCase())
        }
    })

    listVariables && listVariables.length > 0 && listVariables.forEach((elem) => {
        if (elem.required) {
            elem.variable = elem.variable.toLowerCase()
            listMustContain.push(elem)
        } else {
            elem.variable = elem.variable.toLowerCase()
            listAvailableVariables.push(elem)
        }
    })

    listMustContain && listMustContain.length > 0 && listMustContain.forEach((must) => {
        if (listVariablesAll.includes(must.variable)) {
            must.existVariable = true
        } else {
            must.existVariable = false
        }
    })
    listAvailableVariables && listAvailableVariables.length > 0 && listAvailableVariables.forEach((available) => {
        if (listVariablesAll.includes(available.variable)) {
            available.existVariable = true
        } else {
            available.existVariable = false
        }
    })
    return (
        <fieldset className="email-legend email-legend-update">
            <div className="content-variables-workflow">
                <div className="content-must-variables">
                    <div className="content-must">
                        <legend className="legend-variable">Must contain:</legend>
                        <div className="email-variables-used">
                            <Tooltip placement="bottom" title={
                                <span>Email variables, are pieces of text in email templates that are automatically filled with the corresponding data about email recipients when they receive your message.
                          The most common variables include the first name of the recipient or the name of the company.
                          You can include it at {'${First Name}'}.</span>}>
                                <span className="icon-v14-question"></span>
                            </Tooltip>
                        </div>
                    </div>
                    { listMustContain && listMustContain.length > 0 && listMustContain.map((variable) => {
                        return (
                            <div className="content-variables">
                                { variable.existVariable ?
                                    <i className="fa fa-check" style={{ color: 'green', marginRight:3 }}/> :
                                    <i className="fa fa-times" style={{ color: 'red', marginRight:3}}/>

                                }
                                <span onClick={variable.existVariable ?
                                    (e) => onDisabledSelectVariable(e, variable) :
                                    (e) => onSelectVariable(e, variable)
                                }
                                      style={variable.existVariable ? { color: '#D4D7DA' } : {color: 'red'}} className="name-variable">{variable.variable.replaceAll('#{', '${')}</span>
                            </div>
                        )
                    })

                    }
                </div>
                <div className="content-must-variables">
                    <legend className="legend-variable">Available variables:</legend>
                    { listAvailableVariables && listAvailableVariables.length > 0 && listAvailableVariables.map((variable) => {
                        return (
                            <div className="content-variables">
                                <span onClick={variable.existVariable ?
                                    (e) => onDisabledSelectVariable(e, variable) :
                                    (e) => onSelectVariable(e, variable)}
                                      style={variable.existVariable ? { color: '#D4D7DA' } : {}} className="name-variable">{variable.variable.replaceAll('#{', '${')}</span>
                            </div>
                        )
                    })

                    }
                </div>
            </div>
        </fieldset>
    )
}

export default EmailWorkVariablesAdmin