import axios from 'axios'

export const apiPrivacyFetchToken = (host) => {
  const token = sessionStorage.getItem('gravicus_auth_token')
  let data = {}

  if (token) {
    data = { grav_session: token }
  }

  return axios({
    method: 'post',
    url: `${ host }/datasourcemanager/api/validateGravSession`,
    data
  })
}

export const apiPrivacyAuthenticate = (host) => {
  return axios({
    method: 'post',
    url: `${ host }/proxy/authenticate`,
    data: {
      username: 'superadmin',
      password: 'adm1n'
    }
  })
}

export const apiPrivacyGetMsgraphUrl = (host) => {
  return axios({
    method: 'get',
    url: `${ host }/datasourcemanager/api/getLoginURL/msgrap`
  })
}
