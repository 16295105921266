export default () => {
  self.addEventListener('message', (e) => { // eslint-disable-line no-restricted-globals
    if (!e.data) return []
    const files = e.data
    const acceptedFiles = files.filter((fil) =>
      !(/\.(0XE|73K|89K|8CK|A6P|A7R|AC|ACC|ACR|ACTC|ACTION|ACTM|AHK|AIR|APK|APP|APPLESCRIPT|ARSCRIPT|ASB|AZW2|BA_|BAT|BEAM|BIN|BTM|CACTION|CEL|CELX|CGI|CMD|COF|COFFEE|COM|COMMAND|CSH|CYW|DEK|DLD|DMC|DS|DXL|E_E|EAR|EBM|EBS|EBS2|ECF|EHAM|EPK|ES|ESH|EX4|EX5|EX_|EXE|EXE1|EXOPC|EZS|EZT|FAS|FKY|FPI|FRS|FXP|GADGET|GPE|GPU|GS|HAM|HMS|HPF|HTA|ICD|IIM|IPA|IPF|ISU|ITA|JAR|JS|JSE|JSF|JSX|KIX|KSH|KX|LO|LS|M3G|MAC|MAM|MCR|MEL|MEM|MIO|MLX|MM|MRC|MRP|MS|MSL|MXE|N|NCL|NEXE|ORE|OSX|OTM|OUT|PAF|PAF.EXE|PEX|PHAR|PIF|PLSC|PLX|PRC|PRG|PS1|PVD|PWC|PYC|PYO|QIT|QPX|RBF|RBX|RFU|RGS|ROX|RPJ|RUN|RXE|S2A|SBS|SCA|SCAR|SCB|SCPT|SCPTD|SCR|SCRIPT|SCT|SEED|SERVER|SHB|SMM|SPR|TCP|THM|TIAPP|TMS|U3P|UDF|UPX|VBE|VBS|VBSCRIPT|VDO|VEXE|VLX|VPM|VXP|WCM|WIDGET|WIZ|WORKFLOW|WPK|WPM|WS|WSF|WSH|X86|XAP|XBAP|XLM|XQT|XYS|ZL9)$/i)
        .test(fil.name))
    const rejectedFiles = files.filter((fil) =>
      (/\.(0XE|73K|89K|8CK|A6P|A7R|AC|ACC|ACR|ACTC|ACTION|ACTM|AHK|AIR|APK|APP|APPLESCRIPT|ARSCRIPT|ASB|AZW2|BA_|BAT|BEAM|BIN|BTM|CACTION|CEL|CELX|CGI|CMD|COF|COFFEE|COM|COMMAND|CSH|CYW|DEK|DLD|DMC|DS|DXL|E_E|EAR|EBM|EBS|EBS2|ECF|EHAM|EPK|ES|ESH|EX4|EX5|EX_|EXE|EXE1|EXOPC|EZS|EZT|FAS|FKY|FPI|FRS|FXP|GADGET|GPE|GPU|GS|HAM|HMS|HPF|HTA|ICD|IIM|IPA|IPF|ISU|ITA|JAR|JS|JSE|JSF|JSX|KIX|KSH|KX|LO|LS|M3G|MAC|MAM|MCR|MEL|MEM|MIO|MLX|MM|MRC|MRP|MS|MSL|MXE|N|NCL|NEXE|ORE|OSX|OTM|OUT|PAF|PAF.EXE|PEX|PHAR|PIF|PLSC|PLX|PRC|PRG|PS1|PVD|PWC|PYC|PYO|QIT|QPX|RBF|RBX|RFU|RGS|ROX|RPJ|RUN|RXE|S2A|SBS|SCA|SCAR|SCB|SCPT|SCPTD|SCR|SCRIPT|SCT|SEED|SERVER|SHB|SMM|SPR|TCP|THM|TIAPP|TMS|U3P|UDF|UPX|VBE|VBS|VBSCRIPT|VDO|VEXE|VLX|VPM|VXP|WCM|WIDGET|WIZ|WORKFLOW|WPK|WPM|WS|WSF|WSH|X86|XAP|XBAP|XLM|XQT|XYS|ZL9)$/i)
        .test(fil.name))
  
    postMessage({
      acceptedFiles,
      rejectedFiles
    })
  })
}