import React from 'react'
import { Link } from 'react-router-dom'
import { userLogout } from '../../actions/userActions'
import './Header.scss'
import { Multitenant } from '../Multitenance/Main/Multitenant'
import {apiUserFetchInfo, setTenantId} from '../../api/User'
import { Tooltip, Popover } from 'antd'
import { connect } from 'react-redux'
import { removeAllIntervalsFromApplication } from '../../actions/applicationsActions'
import {
  setSmartBoxAddedNote, setSmartBoxCheckBoxRedactionStatus,
  setSmartBoxDataReady,
  setSmartBoxErrorLoadingThumbnails, setSmartBoxFilterRefreshOpenedBox,
  setSmartBoxHomeClicked, setSmartBoxNotAccessPermission, setSmartBoxNotAnalysisComplete,
  setSmartBoxNotFolderRedactionComplete, setSmartBoxNotGiveAccessBox,
  setSmartBoxNotSarTracker, setSmartBoxNotUploadComplete,
  setSmartBoxStatusChange,
  setSmartBoxUploadFailed,
  setSmartFilesErrorNotBeConverted,
  setSmartBoxSelectedKeys, setBoxListViewSelectedKeys, setSmartBoxExistsFilesWarningsNotification,
  setImageProfileSettings
} from '../../actions/smartBoxActions'
//import imgFolderNewParent from '../../assets/images/Box 80x80.png'
import imgFolderParentNegative from '../../assets/box/box_negative_40x40.png'
// import imgNewNavBox from '../../assets/New_Nav_Box_40x40.svg'
import imgNewNavBox from '../../assets/Key visual 40x40.svg'
import imgFolderParent from '../../assets/box/box_20x20.png'
import imgFolder from '../../assets/folder/folder_20x20.png'
import Switcher from "./Switcher";
import cookie from "react-cookies";
import withRouter from '../../private-routers/withRouter'
import NewNavigation from './NewNavigationMenu/NewNavigation'
import { newNavigationObject } from '../../NewNavigationObject'
import {apiGetSubTenantsClientSmartbox, apiSwitchToSubTenant} from "../../api/SmartBox";
import PropTypes from "prop-types";
import {UserSettings} from "../Osp/Users/UserSettings/UserSettings";
//import moment from "moment";
//import { sidebarWidth } from './Header.scss'
export var ColorCodes = {
  first:'#D09FD2',
  second:'#A72500',
  third:'#F67GHI',
  fourth:'#8FERDD',
}
export class Header extends React.Component {
  constructor() {
    super()
    this.state = {
      isMenuOpened: false,
      isMultitenant: false,
      profileImage: '',
      statusUploadedFiles: false,
      statusButtonUploadStatus: false,
      statusDataReady: false,
      valueVisibleErrorsNot: false,
      listErrorsNotification: [],
      listErrorsConverted: [],
      listErrorsThumbnails: [],
      listErrorsUpload: [],
      listMarkAllAsRead: [],
      listNotSarTracker: [],
      listFinallyNot: [],
      listBoxRedactComplete: [],
      listAddedNote: [],
      listStatusChange: [],
      listUploadComplete: [],
      listAccessBox: [],
      infoProcessError: false,
      listAnalysisComplete: [],
      listAccessPermission: [],
      visibleNotifications: false,
      subNavMenu1:[],
      subNavMenu2:[],
      activeSecondLevelNavigation: '',
      activeThirdLevelNavigation:'',
      listSubTenants: [],
      listExistsFilesWarningsNotification: []
    }
    this.toggleMenu = this.toggleMenu.bind(this)
    this.handleOutsideClick = this.handleOutsideClick.bind(this)
  }

  componentDidMount() {
    // const root = document.documentElement;
    // root.style.setProperty('--navigationBackgroud', '#70ce12');
    // const style = getComputedStyle(root);
    // const color = style.getPropertyValue('--navigationBackgroud');
    this.setSubNavigationMenu()
    this.getTenantsForOwner()
    if (this.props.smartBoxCheckFirstUploadFile.statusFolder === 'true') {
      this.setState({
        statusUploadedFiles: true
      }, () => {
        setTimeout(() => {
          this.setState({
            statusUploadedFiles: false
          })
        }, 5000)
      })
    }
    this.setState({
      infoProcessError: false
    })
    if (this.props.smartBoxButtonUploadStatus.statusUpload === true) {
      this.setState({
        statusButtonUploadStatus: true
      }, () => {
        setTimeout(() => {
          this.setState({
            statusButtonUploadStatus: false
          })
        }, 5000)
      })
    }
    if (typeof this.props.smartBoxDataReady !== 'undefined' && this.props.smartBoxDataReady.statusFolder === 'true') {
      this.setState({
        statusDataReady: true
      }, () => {
        setTimeout(() => {
          this.setState({
            statusDataReady: false
          })
        }, 5000)
      })
    }
    if (this.props.client.activeBrandingProfile) {
      this.onUpdateBrandingColours()
    }
    //this.onGetBrandingColours()
    //this.onGetSubHeaderBrandingColours()
    this.onGetSubTenantsClientSmartbox()
  }

 onGetSubTenantsClientSmartbox = () => {
   apiGetSubTenantsClientSmartbox(this.props.client.id).then((response) => {
     const listParents = []
     let listSubTenantAll= []
     if (response.data.parent) {
       listParents.push(response.data.parent)
     }
     if (response.data.subTenants && response.data.subTenants.length > 0) {
       listSubTenantAll = listParents.concat(response.data.subTenants)
     } else {
       listSubTenantAll = listParents
     }
       this.setState({
         listSubTenants: listSubTenantAll
       })
   })
 }
  onUpdateBrandingColours = () => {
    const { client } = this.props
    const { brandingProfileColors } = client
    const root = document.documentElement;
    brandingProfileColors && brandingProfileColors.colorsDTOS && brandingProfileColors.colorsDTOS.length > 0 && brandingProfileColors.colorsDTOS.forEach((brand) => {
      if (brand.color) {
        switch (brand.colorCode) {
          case 'background':
            root.style.setProperty('--navigationBackground', brand.color);
            // const style = getComputedStyle(root);
            // const color = style.getPropertyValue('--navigationBackgroud');
            break
          case 'background-hover':
            root.style.setProperty('--navigationBackgroundHover', brand.color);
            break
          case 'nav-body-font':
            root.style.setProperty('--navigationBodyFont', brand.color);
            break
          case 'nav-body-font-hover':
            root.style.setProperty('--navigationBodyFontHover', brand.color);
            break
          case 'sub-background':
            root.style.setProperty('--subNavigationBackground', brand.color);
            break
          case 'sub-background-hover':
            root.style.setProperty('--subNavigationBackgroundHover', brand.color);
            break
          case 'sub-nav-body-font':
            root.style.setProperty('--subNavigationBodyFont', brand.color);
            break
          case 'sub-nav-body-font-hover':
            root.style.setProperty('--subNavigationBodyFontHover', brand.color);
            break
          case 'sub-nav-body-font-active' :
            root.style.setProperty('--subNavigationBodyFontActive', brand.color);
            break
          case 'general-icon-font-hover' :
            root.style.setProperty('--generalIconFontHover', brand.color);
            break
          case 'general-icon-font-active' :
            root.style.setProperty('--generalIconFontActive', brand.color);
            break
          case 'general-button' :
            root.style.setProperty('--generalButton', brand.color);
            break
          case 'general-button-hover' :
            root.style.setProperty('--generalButtonHover', brand.color);
            break
          case 'general-button-active' :
            root.style.setProperty('--generalButtonActive', brand.color);
            break
          case 'general-button-font' :
            root.style.setProperty('--generalButtonFont', brand.color);
            break
          default:
            break;
        }
      }
    })
  }

  onGetBrandingColours = () => {
    const { client } = this.props
    const { brandingProfileColors } = client
    //document.getElementsByClassName('new-header-container')[0].style.backgroundColor = '#000000';
    brandingProfileColors && brandingProfileColors.colorsDTOS && brandingProfileColors.colorsDTOS.length > 0 && brandingProfileColors.colorsDTOS.forEach((brand) => {
      switch (brand.colorCode) {
        case 'background':
          if (brand.color) {
            document.getElementsByClassName('new-header-container')[0].style.backgroundColor = brand.color;
            const navButtons = document.getElementsByClassName('navTitleDropdown')[0]
            navButtons.addEventListener('mouseout', function() {
              navButtons.style.backgroundColor = brand.color
            })

            if (document.getElementsByClassName('panel-new-nav')[0]) {
              const panelNavigation = document.getElementsByClassName('panel-new-nav')[0]
              panelNavigation.addEventListener('mouseout', function () {
                panelNavigation.style.backgroundColor = brand.color
              })
            }
          }
          break
        case 'background-hover':
          if (brand.color) {
            const navButtons = document.getElementsByClassName('navTitleDropdown')[0]
            navButtons.addEventListener('mouseover', function() {
              navButtons.style.backgroundColor = brand.color
            })
          }
          break
        case 'nav-body-font':
          if (brand.color) {
            //document.getElementsByClassName('navTitleDropdown')[0].style.color = brand.color;
            document.getElementsByClassName('header-new-icons-user')[0].style.color = brand.color;
            const navTitleDropdown = document.getElementsByClassName('navTitleDropdown')[0]
            navTitleDropdown.style.color = brand.color
            navTitleDropdown.addEventListener('mouseout', function() {
              navTitleDropdown.style.color = brand.color
            })

            const headerNewIcons = document.getElementsByClassName('header-new-icons-user')[0]
            headerNewIcons.addEventListener('mouseout', function() {
              headerNewIcons.style.color = brand.color
            })
            const headerNewIconsHover = document.getElementsByClassName('icon-hover')[0]
            headerNewIconsHover.addEventListener('mouseout', function() {
              headerNewIconsHover.style.color = brand.color
            })
            const headerNewIconsHover2 = document.getElementsByClassName('icon-v12-nav-notifications')[0]
            headerNewIconsHover2.addEventListener('mouseout', function() {
              headerNewIconsHover2.style.color = brand.color
            })
            const headerSeparator = document.getElementsByClassName('header-separator')[0]
            headerSeparator.style.borderLeft = '1px solid' + `${brand.color}`
            const headerProfile= document.getElementsByClassName('active-userIcon')[0]
            headerProfile.style.color = brand.color
            headerProfile.addEventListener('mouseout', function() {
              headerProfile.style.borderColor = brand.color
            })
            const headerUserName = document.getElementsByClassName('header-userName')[0]
            headerUserName.style.color = brand.color
            headerUserName.addEventListener('mouseout', function() {
              headerUserName.style.color = brand.color
            })
          }
          break
        case 'nav-body-font-hover':
          if (brand.color) {
            const navButtons = document.getElementsByClassName('navTitleDropdown')[0]
            navButtons.addEventListener('mouseover', function() {
              navButtons.style.color = brand.color
              navButtons.style.borderColor = brand.color
            })
            navButtons.addEventListener('mouseout', function() {
              navButtons.style.borderColor = 'transparent'
            })

            const headerNewIconsHover = document.getElementsByClassName('icon-hover')[0]
            headerNewIconsHover.addEventListener('mouseover', function() {
              headerNewIconsHover.style.color = brand.color
            })
            const headerNewIconsHover2 = document.getElementsByClassName('icon-v12-nav-notifications')[0]
            headerNewIconsHover2.addEventListener('mouseover', function() {
              headerNewIconsHover2.style.color = brand.color
            })
            const headerProfile= document.getElementsByClassName('active-userIcon')[0]
            headerProfile.addEventListener('mouseover', function() {
              headerProfile.style.borderColor = brand.color
            })

            const headerUserName = document.getElementsByClassName('header-userName')[0]
            headerUserName.addEventListener('mouseover', function() {
              headerUserName.style.color = brand.color
            })
            //document.getElementsByClassName('navTitleDropdown')[0].style.color = brand.defaultColor;
          }
          break
        default: break
      }
    })
  }

  onGetSubHeaderBrandingColours = () => {
    const { client } = this.props
    const { brandingProfileColors } = client
    // if (document.getElementsByClassName('subheader')[0]) {
    //   document.getElementsByClassName('subheader')[0].style.backgroundColor = '#ffffff';
    // }
    brandingProfileColors && brandingProfileColors.colorsDTOS && brandingProfileColors.colorsDTOS.length > 0 && brandingProfileColors.colorsDTOS.forEach((brand) => {
      switch (brand.colorCode) {
        case 'sub-background':
          if (document.getElementsByClassName('subheader')[0]) {
            document.getElementsByClassName('subheader')[0].style.backgroundColor = brand.color;
          }
          for (let i = 0; i < document.getElementsByClassName('header-inactive').length; i++) {
            const navButtons = document.getElementsByClassName('header-inactive')[i]
            document.getElementsByClassName('header-inactive')[i].style.backgroundColor = brand.color;
          }
          break
        case 'sub-background-hover':
          for (let i = 0; i < document.getElementsByClassName('header-active').length; i++) {
            const navButtons = document.getElementsByClassName('header-active')[i]
            document.getElementsByClassName('header-active')[i].style.backgroundColor = brand.color;
            // navButtons.addEventListener('mouseover', function() {
            //   navButtons.style.color = 'rgba(107, 91, 149, 1)'
            // })
          }
          break
        case 'sub-nav-body-font':
          for (let i = 0; i < document.getElementsByClassName('header-inactive').length; i++) {
            const navButtons = document.getElementsByClassName('header-inactive')[i]
            document.getElementsByClassName('header-inactive')[i].style.color = brand.color;
            // navButtons.addEventListener('mouseover', function() {
            //   navButtons.style.color = '#000000'
            // })
          }

          for (let i = 0; i < document.getElementsByClassName('header-inactive').length; i++) {
            const navButtons = document.getElementsByClassName('header-inactive')[i]
           // document.getElementsByClassName('header-active')[i].style.color = '#ffffff';
            navButtons.addEventListener('mouseout', function() {
              navButtons.style.color = brand.color
            })
          }
          break
        case 'sub-nav-body-font-hover':
          for (let i = 0; i < document.getElementsByClassName('header-active').length; i++) {
            const navButtons = document.getElementsByClassName('header-active')[i]
            //document.getElementsByClassName('header-active')[i].style.color = '#ffffff';
            navButtons.addEventListener('mouseover', function() {
              navButtons.style.color = brand.color
            })
          }
          for (let i = 0; i < document.getElementsByClassName('header-inactive').length; i++) {
            const navButtons = document.getElementsByClassName('header-inactive')[i]
            navButtons.addEventListener('mouseover', function() {
              navButtons.style.color = brand.color
            })
          }
          break
        case 'sub-nav-body-font-active' :
          for (let i = 0; i < document.getElementsByClassName('header-active').length; i++) {
            const navButtons = document.getElementsByClassName('header-active')[i]
            document.getElementsByClassName('header-active')[i].style.color = brand.color;
          }
          for (let i = 0; i < document.getElementsByClassName('header-active').length; i++) {
            const navButtons = document.getElementsByClassName('header-active')[i]
            //document.getElementsByClassName('header-active')[i].style.color = '#ffffff';
            navButtons.addEventListener('mouseout', function() {
              navButtons.style.color = brand.color
            })
          }
          break
        default: break
      }
      })
  }


  onGetHeaderProfileBrandingColours = () => {
    const { client } = this.props
    const { brandingProfileColors } = client
    brandingProfileColors && brandingProfileColors.colorsDTOS && brandingProfileColors.colorsDTOS.length > 0 && brandingProfileColors.colorsDTOS.forEach((brand) => {
      switch (brand.colorCode) {
        case 'background':
          for (let i = 0; i < document.getElementsByClassName('header-listItem').length; i++) {
            const navButtons = document.getElementsByClassName('header-listItem')[i]
            document.getElementsByClassName('header-listItem')[i].style.backgroundColor = brand.color;
          }
          for (let i = 0; i < document.getElementsByClassName('header-link').length; i++) {
            const navButtons = document.getElementsByClassName('header-link')[i]
            navButtons.addEventListener('mouseout', function() {
              navButtons.style.backgroundColor = brand.color
            })
          }
          break
        case 'background-hover':
          for (let i = 0; i < document.getElementsByClassName('header-link').length; i++) {
            const navButtons = document.getElementsByClassName('header-link')[i]
            navButtons.addEventListener('mouseover', function() {
              navButtons.style.backgroundColor = brand.color
            })
          }
          break
        case 'nav-body-font':
          for (let i = 0; i < document.getElementsByClassName('header-link').length; i++) {
            const navButtons = document.getElementsByClassName('header-link')[i]
            document.getElementsByClassName('header-link')[i].style.color = brand.color;
          }
          for (let i = 0; i < document.getElementsByClassName('header-link').length; i++) {
            const navButtons = document.getElementsByClassName('header-link')[i]
            navButtons.addEventListener('mouseout', function() {
              navButtons.style.color = brand.color
            })
          }
          break
        case 'nav-body-font-hover':
          for (let i = 0; i < document.getElementsByClassName('header-link').length; i++) {
            const navButtons = document.getElementsByClassName('header-link')[i]
            navButtons.addEventListener('mouseover', function() {
              navButtons.style.color = brand.color
            })
          }
          break
        default: break
      }
    })
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.location.pathname !== this.props.location.pathname){
      this.setSubNavigationMenu()
      // if (document.getElementsByClassName('subheader')[0]) {
      //   document.getElementsByClassName('subheader')[0].style.backgroundColor = '#ffffff';
      // }
    this.setState({
      statusSubHeader: true
    }, () => {
      //this.onGetSubHeaderBrandingColours()
    })
      // this.setActiveSecondNavigation(this.props.location.pathname)
    }
    if(prevProps !== this.props && document.getElementsByClassName('subheader')[0]){
      this.setState({
        statusSubHeader: true
      }, () => {
        //this.onGetSubHeaderBrandingColours()
      })
    }

    // if(prevState !== this.state && document.getElementsByClassName('header-listItem')[0]){
    //   this.onGetHeaderProfileBrandingColours()
    // }
    //
    // if(prevState !== this.state && document.getElementsByClassName('header-listItem')[0]){
    //   this.onGetHeaderProfileBrandingColours()
    // }

    if (prevProps !== this.props && this.props.smartBoxCheckFirstUploadFile.statusFolder === 'true') {
      this.setState({
        statusUploadedFiles: true
      }, () => {
        setTimeout(() => {
          this.setState({
            statusUploadedFiles: false
          })
        }, 5000)
      })
    }
    if (prevProps !== this.props && this.props.smartBoxButtonUploadStatus.statusUpload === true) {
      this.setState({
        statusButtonUploadStatus: true
      }, () => {
        setTimeout(() => {
          this.setState({
            statusButtonUploadStatus: false
          })
        }, 5000)
      })
    }
    if (prevProps !== this.props && typeof this.props.smartBoxDataReady !== 'undefined' && this.props.smartBoxDataReady.statusFolder === 'true') {
      this.setState({
        statusDataReady: true
      }, () => {
        setTimeout(() => {
          this.setState({
            statusDataReady: false
          }, () => {
            this.props.setSmartBoxDataReady('false',{})
          })
        }, 5000)
      })
    }
    if (prevProps !== this.props && this.props.smartBoxErrorLoadingThumbnails) {
      const listError = []
      listError.push(this.props.smartBoxErrorLoadingThumbnails)
      this.setState({
        listErrorsThumbnails: listError[0].status && listError[0].status.length === 0 ? [] : listError
      })
    }
    if (prevProps !== this.props && this.props.smartBoxFilesErrorConverted) {
      this.setState({
        listErrorsConverted: this.props.smartBoxFilesErrorConverted[0] === false ? [] : this.props.smartBoxFilesErrorConverted
      })
    }
    if (prevProps !== this.props && this.props.smartBoxUploadFailed) {
      this.setState({
        listErrorsUpload: this.props.smartBoxUploadFailed
      })
    }
    if (prevProps !== this.props && this.props.smartBoxUploadFailed) {
      this.setState({
        listNotSarTracker: this.props.smartBoxNotSarTracker
      })
    }
    if (prevProps !== this.props && this.props.smartBoxNotRedactComplete) {
      this.setState({
        listBoxRedactComplete: this.props.smartBoxNotRedactComplete
      })
    }
    if (prevProps !== this.props && this.props.smartBoxAddedNote) {
      this.setState({
        listAddedNote: this.props.smartBoxAddedNote
      })
    }
    if (prevProps !== this.props && this.props.smartBoxStatusChange) {
      this.setState({
        listStatusChange: this.props.smartBoxStatusChange
      })
    }
    if (prevProps !== this.props && this.props.smartBoxNotUploadComplete) {
      this.setState({
        listUploadComplete: this.props.smartBoxNotUploadComplete
      })
    }
    if (prevProps !== this.props && this.props.smartBoxNotGiveAccessBox) {
      this.setState({
        listAccessBox: this.props.smartBoxNotGiveAccessBox
      })
    }
    if (prevProps !== this.props && this.props.smartBoxNotAnalysisComplete) {
      this.setState({
        listAnalysisComplete: this.props.smartBoxNotAnalysisComplete
      })
    }
    if (prevProps !== this.props && this.props.smartBoxNotAccessPermission) {
      this.setState({
        listAccessPermission: this.props.smartBoxNotAccessPermission
      })
    }
    if (prevProps !== this.props && this.props.smartBoxExistsFilesWarningsNotification) {
      this.setState({
        listExistsFilesWarningsNotification: this.props.smartBoxExistsFilesWarningsNotification
      })
    }
  }

  async getTenantsForOwner() {
    // if (this.props.user.user_metadata.roles['multitenancy']) {
    //   this.setState({
    //     isMultitenant: true
    //   })
    // }
    if (this.props.client && this.props.client.additionalEnvironments) {
      this.setState({
        isMultitenant: true
      })
    }
  }

  setActiveSecondNavigation = (url) => {
    this.setState({activeSecondLevelNavigation: url})
    this.props.navigate(url)
  }

  setActiveThirdNavigation = (url) => {
    this.setState({ activeThirdLevelNavigation: url})
    this.props.navigate(url)
  }

  setSubNavigationMenu = () => {
    this.setState({activeSecondLevelNavigation: this.props.location.pathname})
    if(!this.props.location.pathname.split('/').includes('settings') ){
      this.setState({subNavMenu2: []})
    }
    if(this.props.location.pathname.split('/').includes('settings') ){
      // FOR NOW THE ONLY SETTINGS('/sar/settings/annotation-management') has nested subnav
      // so we need to search for children of children of settings in the 'NewNavigationObject.js'
      newNavigationObject.forEach((item, index) => {
        if(item.title === 'Settings' && item.key==='main-settings'){
          // Filter children of settings to access permissions and show appropriate tabs!
          let filteredSubMenu = item.children.filter(child => {
            if(!child.applicationpermission){
              return child
            }else{
              let resp = this.props.applicationPermissions.get(child.applicationpermission)
              if(resp === undefined || resp === true){
                return child
              }
            }
          })
          this.setState({subNavMenu1: filteredSubMenu})
          let urlsSubNav2 = ['/sar/settings/annotation-management',
            '/sar/settings/tags',
          '/smart-box/regular-expressions/settings',
          '/smart-box/business-term-lists/settings',
          '/sar/settings/template-email']
          if(urlsSubNav2.includes(this.props.location.pathname)){
            item.children.forEach((tabMenu) => {
              if(tabMenu.title === 'Smartbox.ai' && tabMenu.key==='smart-box'){
                let filteredSubMenu = tabMenu.children
                if (this.props.user.user_metadata.roles.osprey === 'user' || this.props.user.user_metadata.roles.osprey === 'admin') {
                  filteredSubMenu = tabMenu.children.filter((child3) => child3.key !== 'smart-box-tags')
                }
                this.setState({
                  subNavMenu2: filteredSubMenu,
                  activeSecondLevelNavigation: '/sar/settings/annotation-management',
                  activeThirdLevelNavigation: this.props.location.pathname,
                })
              }
            })
          }else{
            this.setState({subNavMenu2: []})
          }
          if(this.props.location.pathname.includes('/osp/user/settings') ){
            this.setState({subNavMenu1: []})
          }

        }
      })
    } else if (this.props.location.pathname.split('/').includes('workflow')){
      newNavigationObject && newNavigationObject.length > 0 && newNavigationObject.forEach((item, index) => {
        if(item.title === 'Workflow APP' && item.key==='workflow-app') {
          this.setState({subNavMenu1: item.children})
          let urlsSubNav2 = ['/sar/workflow/forms',
            '/sar/workflow/templates',
            '/sar/workflow/builder',
            '/sar/workflow/template-workflow']
          let urlSubNav3 = ['/sar/workflow/data-source']
          if (urlsSubNav2.includes(this.props.location.pathname)) {
            item.children && item.children.length > 0 && item.children.forEach((tabMenu) => {
              if (tabMenu.title === 'Templates' && tabMenu.key === 'workflow-templates') {
                return this.setState({
                  subNavMenu2: tabMenu.children,
                  activeSecondLevelNavigation: '/sar/workflow/templates',
                  activeThirdLevelNavigation: this.props.location.pathname,})
              }
            })
          } else if (urlSubNav3.includes(this.props.location.pathname)) {
            item.children && item.children.length > 0 && item.children.forEach((tabMenu) => {
              if (tabMenu.title === 'Data source' && tabMenu.key === 'data-source') {
                return this.setState({subNavMenu2: tabMenu.children})
              }
            })
          }
        }
      })
    }  else if (this.props.location.pathname.split('/').includes('data-mapping')){
      newNavigationObject && newNavigationObject.length > 0 && newNavigationObject.forEach((item, index) => {
        if(item.title === 'Data sources' && item.key==='data-mapping-app') {
          this.setState({subNavMenu1: item.children})
        }
      })
    } else if(this.props.location.pathname.split('/').includes('dpia')){
      newNavigationObject.forEach((item, index) => {
        if(item.title === 'Assessments' && item.key=== 'assess'){
          return this.setState({subNavMenu1: newNavigationObject[index].children})
        }
      })
    }else if(this.props.location.pathname.split('/').includes('sar')){
        newNavigationObject.forEach((item, index) => {
          if(item.key === 'sar' && item.title=== 'Request Tracker'){
            return this.setState({subNavMenu1: newNavigationObject[index].children})
          }
        })
    }else if(this.props.location.pathname.split('/').includes('osp')){
      newNavigationObject.forEach((item, index) => {
        if(item.title === 'User management' && item.key=== 'home'){
          return this.setState({subNavMenu1: newNavigationObject[index].children})
        }
      })
    }else{
      this.setState({subNavMenu1: [], subNavMenu2:[]})
    }
  }

  getName(user) {
    const name = user.user_metadata.name || user.name
    const [firstName, ] = name ? name.split(' ') : ''
    return firstName
  }

  getCompany(subscription) {
    if (subscription.subscription) {
      return subscription.subscription.client.name
    }
    return ''
  }

  toggleMenu() {
    if (!this.state.isMenuOpened) {
      document.addEventListener('click', this.handleOutsideClick, false)
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false)
    }
    this.setState({
      isMenuOpened: !this.state.isMenuOpened,
    })
  
  }
  handleOutsideClick(e) {
    if (this.node && this.node.contains(e.target)) {
      return
    } 
    this.toggleMenu()
  }
  doLogout() {
    if (this.props && this.props.applicationIntervals) for (let i = 0; i < this.props.applicationIntervals.length; i++) { clearInterval(this.props.applicationIntervals[i]) }
    this.props.removeAllIntervalsFromApplication([])

    // window.location.href = '/osp/login'
    this.props.userLogout()
    this.props.navigate('/osp/login');
    // window.location.href = '/osp/login'
  }

  resetTenantToDefaultClient = () => {
    const data = {
      token: JSON.parse(localStorage.getItem('tokenData'))?.tokenId,
      tenantId: this.props.user.realClientId
    }
    setTenantId(data).then((res) => {
      const idToken = res.data
      // localStorage.removeItem('accessToken')
      localStorage.removeItem('tokenData')
      var secondsToExp = Math.floor(new Date().getTime() / 1000);
      secondsToExp += (60*60*8)
      var tokenData = {'tokenId': idToken, 'expiresAt': secondsToExp}
      localStorage.setItem('tokenData', JSON.stringify(tokenData))
      // localStorage.setItem('accessToken', idToken)
      this.props.navigate('/smart-box/management')
      window.location.replace('/smart-box/management')
    })
  }

  redirectToHome = () => {
    const path = '/smart-box/management'
    this.props.navigate(path)
    this.props.setSmartBoxHomeClicked(true)
    this.props.setSmartBoxCheckBoxRedactionStatus(0)
    this.props.setSmartBoxSelectedKeys([])
    this.props.setBoxListViewSelectedKeys([])
    //cookie.save('checkRedaction', 'dragos 888888888888', {path: '/'})
    cookie.remove('checkRedaction', { path: '/' })
    this.props.setSmartBoxFilterRefreshOpenedBox(null, null, null, null)
    cookie.remove('checkDeduplicationStatus', { path: '/' })
    cookie.remove('connectMicrosoft', { path: '/' })
    cookie.remove('mappingData', { path: '/' })
    cookie.remove('checkListDuplicateFileBox', { path: '/' })
    cookie.remove('checkListClutter', { path: '/' })
    cookie.remove('checkListNearFileBox', { path: '/' })
    cookie.remove('checkGetDuplicatesCardInfo', { path: '/' })
    cookie.remove('checkDownloadFilesError', { path: '/' })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user) {
      if (nextProps.user.picture_base64) {
        prevState.profileImage = nextProps.user.picture_base64
      }
    }
    return null
  }
  handleChangeVisibleErrorsNot(status) {
    this.setState({
      valueVisibleErrorsNot: status
    })
  }
  onClickMarkAllAsRead = (e) => {
    const { listErrorsConverted, listErrorsThumbnails, listErrorsUpload, listBoxRedactComplete, listAddedNote, listStatusChange,
      listUploadComplete, listAccessBox, listAnalysisComplete, listNotSarTracker, listAccessPermission, listExistsFilesWarningsNotification } = this.state
    const listOne = listErrorsConverted.concat(listErrorsThumbnails)
    const listTwo = listOne.concat(listErrorsUpload, listBoxRedactComplete, listAddedNote, listStatusChange, listUploadComplete, listAccessBox,
        listAnalysisComplete, listNotSarTracker, listAccessPermission, listExistsFilesWarningsNotification)
    const listFinallyMark = []
    listTwo && listTwo.length > 0 && listTwo.forEach((file) => {
      if (file !== false && Object.keys(file).length !== 0) {
        file.mark = false
        listFinallyMark.push(file)
      }
    })
    this.setState({
      listMarkAllAsRead: listFinallyMark,
      infoProcessError: true
    })
  }
  onClickDisplayWarningPage = (e, box) => {
    this.setState({

    })
    if (box.boxId) {
      window.history.replaceState(null, null, `/smart-box/folder/${box.boxId}/data-insights/warning-report`);
      window.location.reload()
      //window.open(`${window.location.origin}/smart-box/folder/${box.boxId}/data-insights/warning-report`)
    }
  }
  onClickDisabledWarningPage = (box) => {
      return true
  }
  onClickClearAllNotification = (e) => {
     this.setState({
       listErrorsConverted: [],
       listErrorsThumbnails: [],
       listErrorsUpload: [],
       listNotSarTracker: [],
       listMarkAllAsRead: [],
       listErrorsNotification: [],
       listBoxRedactComplete: [],
       listAddedNote: [],
       listStatusChange: [],
       listUploadComplete: [],
       listAccessBox: [],
       listAnalysisComplete: [],
       listAccessPermission: [],
       listExistsFilesWarningsNotification: []
     })
    this.props.setSmartBoxErrorLoadingThumbnails([])
    this.props.setSmartFilesErrorNotBeConverted([])
    this.props.setSmartBoxUploadFailed([])
    this.props.setSmartBoxNotSarTracker([])
    this.props.setSmartBoxNotFolderRedactionComplete([])
    this.props.setSmartBoxAddedNote([])
    this.props.setSmartBoxStatusChange([])
    this.props.setSmartBoxNotUploadComplete([])
    this.props.setSmartBoxNotGiveAccessBox([])
    this.props.setSmartBoxNotAnalysisComplete([])
    this.props.setSmartBoxNotAccessPermission([])
    this.props.setSmartBoxExistsFilesWarningsNotification([])
  }

   content = () => {
     const { valueVisibleErrorsNot, listErrorsConverted, listErrorsThumbnails, listErrorsUpload, listMarkAllAsRead, listNotSarTracker, listBoxRedactComplete,
       listAddedNote, listStatusChange, listUploadComplete, listAccessBox, listAnalysisComplete, listAccessPermission, listExistsFilesWarningsNotification } = this.state
     let { listErrorsNotification } = this.state
     const listError1 = listErrorsConverted.concat(listErrorsThumbnails)
     const listError2 = listError1.concat(listErrorsUpload)
     listErrorsNotification = listError2.concat(listNotSarTracker, listBoxRedactComplete, listAddedNote, listStatusChange, listUploadComplete, listAccessBox,
         listAnalysisComplete, listAccessPermission, listExistsFilesWarningsNotification)
     let listErrorsNotificationUpdate = []
     listErrorsNotification && listErrorsNotification.length > 0 && listErrorsNotification.forEach((file) => {
       if (file !== false && Object.keys(file).length !== 0) {
         file.mark = true
         listMarkAllAsRead && listMarkAllAsRead.length > 0 && listMarkAllAsRead.forEach((file2) => {
           if (file.timesEvent === file2.timesEvent) {
             file.mark = false
           }
         })
         listErrorsNotificationUpdate.push(file)
       }
     })
     listErrorsNotificationUpdate && listErrorsNotificationUpdate.length > 0 && listErrorsNotificationUpdate.forEach((file) => {
       const [dateValues, timeValues] = file.timesEvent.split(' ');

       const [month, day, year] = dateValues.split('/');
       const [hours, minutes, seconds] = timeValues.split(':');

       const date = new Date(+year, +month - 1, +day, +hours, +minutes, +seconds);
       file.timeUpdate = date
     })
     //listErrorsNotificationUpdate = listErrorsNotificationUpdate.sort((a, b) => moment(b.timesEvent).format('DD/MM/YYYY HH:mm:ss') - moment(a.timesEvent).format('DD/MM/YYYY HH:mm:ss'))
     //const listSortNotifications = listErrorsNotificationUpdate && listErrorsNotificationUpdate.length > 0 ? listErrorsNotificationUpdate.sort((userA, userB) => (moment(userA.timesEvent).format('DD/MM/YYYY HH:mm:ss').localeCompare(moment(userB.timesEvent).format('DD/MM/YYYY HH:mm:ss')))) : ''
     //const listSortNotifications = listErrorsNotificationUpdate.sort((a, b) => new moment(b.timesEvent).diff() - new moment(a.timesEvent).diff())
     let listSortNotifications = []
     if (listErrorsNotificationUpdate && listErrorsNotificationUpdate.length > 0) {
        listSortNotifications = listErrorsNotificationUpdate.sort((a,b) => {
         return new Date(a.timeUpdate).getTime() -
             new Date(b.timeUpdate).getTime()
       }).reverse()
     }
     return (
         <div className="notifications-server-errors">
           <div className={ listErrorsNotificationUpdate && listErrorsNotificationUpdate.length > 0 && Object.keys(listErrorsNotificationUpdate[0]) && Object.keys(listErrorsNotificationUpdate[0]).length > 0 ? '' +
               'header-notifications-errors' : 'header-notifications-errors header-notifications-errors-update' }>
             <span className="title-notification">Notifications</span>
             <div className="teams switcher-visible-notification">
               <Switcher
                   active={valueVisibleErrorsNot}
                   updateActive={() => this.handleChangeVisibleErrorsNot(!valueVisibleErrorsNot)
                   }
               />
             </div>
           </div>
           { !valueVisibleErrorsNot &&
           <div className="content-notifications-errors">
             {
               listSortNotifications && listSortNotifications.length > 0 && listSortNotifications.map((item, index) => {
                 return (
                     <React.Fragment key={index+1}>
                       { Object.keys(item) && Object.keys(item).length > 0 &&
                         <div key={index} className="list-notifications-errors"
                         onClick={item.status === 'file-warning' ? (e) => this.onClickDisplayWarningPage(e, item) : (e) => this.onClickDisabledWarningPage(e)}>
                           <div className="status-errors">
                             { item.status === 'box' ?
                                 <img src={ imgFolderParent } alt={ imgFolderParent }/> :
                                 item.status === 'upload failed' ?
                                     <img src={ imgFolderParent } alt={ imgFolderParent } /> :
                                     item.status === 'box-notification' ?
                                         <img src={ imgFolderParent } alt={ imgFolderParent } /> :
                                 item.status === 'folder' ?
                                     <img src={ imgFolder } alt={ imgFolder } /> :
                               <span
                                   className={item.status === 'file-warning' ? 'icon-v23-warning-1' : item.status === 'errors-loading' ? "icon-v18-gear" : item.descriptionActionEvent === 'Could not be converted' ? 'icon-v18-exclamation-mark' :
                                         item.status === 'update-sar' ? 'icon-v18-securtiy' :
                                                       item.status === 'file' ? 'icon-v18-extension-pdf' :
                                                           item.status === 'access-avatar' ? 'icon-v20-avatar' : "icon-v18-exclamation-mark"}></span>
                             }
                           </div>
                           <div className="content-info-errors">
                             <span className="action-event-name">{item.status === 'upload failed' ? `Box ${ item.nameActionEvent }` : item.nameActionEvent}</span>
                             <span className="action-event-description">{item.descriptionActionEvent}</span>
                             <span className="events-times">{item.timesEvent}</span>
                           </div>
                           <div className="content-mark-read">
                             { item.mark === true ?
                               <span className="status-mark"></span> :
                               <span className="status-mark-empty"></span>
                             }
                           </div>
                         </div>
                       }
                     </React.Fragment>
                 )
               })
             }
           </div>
           }
           { !valueVisibleErrorsNot &&
           <div className="content-footer-mark">
             { listErrorsNotificationUpdate && listErrorsNotificationUpdate.length > 0 && Object.keys(listErrorsNotificationUpdate[0]) && Object.keys(listErrorsNotificationUpdate[0]).length > 0 &&
                 <React.Fragment>
                     <span className="clear-all-notification"
                     onClick={(e) => this.onClickClearAllNotification(e)}>
                       Clear all
                     </span>
                     <span className="title-mark"
                      onClick={(e) => this.onClickMarkAllAsRead(e)}>
                      Mark all as read
                   </span>
                 </React.Fragment>
             }
           </div>
           }
         </div>
     )
   }
  handleVisibleChangeNotifications = (visible) => {
    this.setState({
      visibleNotifications: visible
    })
  }
  onResetSwitchToSubTenant = (idToken) => {
    localStorage.removeItem('tokenData')
    var secondsToExp = Math.floor(new Date().getTime() / 1000);
    secondsToExp += (60*60*8)
    var tokenData = {'tokenId': idToken, 'expiresAt': secondsToExp}
    localStorage.setItem('tokenData', JSON.stringify(tokenData))
    // localStorage.setItem('accessToken', idToken)
    this.props.navigate('/smart-box/management')
    window.location.replace('/smart-box/management')
  }
  render() {
    const { user, subscription, setImageProfile } = this.props
    const { isMenuOpened, isMultitenant, profileImage, statusDataReady, listErrorsConverted, listErrorsThumbnails,
      listErrorsUpload, listNotSarTracker, listBoxRedactComplete, listAddedNote, listStatusChange, listUploadComplete, listAccessBox, infoProcessError,
      listAnalysisComplete, listAccessPermission, visibleNotifications, subNavMenu1, subNavMenu2, activeSecondLevelNavigation, activeThirdLevelNavigation,
      valueVisibleErrorsNot, listSubTenants, listExistsFilesWarningsNotification } = this.state
      let joinedListNot = [].concat(listErrorsConverted, listErrorsThumbnails, listErrorsUpload, listNotSarTracker, listBoxRedactComplete, listAddedNote,
        listStatusChange, listUploadComplete, listAccessBox, listAnalysisComplete, listAccessPermission, listExistsFilesWarningsNotification)
    joinedListNot = joinedListNot && joinedListNot.length > 0 && joinedListNot.filter((item) => Object.keys(item).length !== 0)
    let newThirdLevelNavigation = []
    let subNavMenu1Update = []
    const filesWarnings = []
    joinedListNot && joinedListNot.length > 0 && joinedListNot.forEach((elem) => {
      filesWarnings.push(elem.status)
    })
    //console.log('profileImage --- REnder', profileImage)
    if (user.user_metadata.roles.osprey === 'super_admin' && activeThirdLevelNavigation && Object.keys(activeThirdLevelNavigation) && Object.keys(activeThirdLevelNavigation).length > 0) {
      newThirdLevelNavigation = activeThirdLevelNavigation.items
    } else
    if (user.user_metadata.roles.osprey === 'user' && activeThirdLevelNavigation && Object.keys(activeThirdLevelNavigation) && Object.keys(activeThirdLevelNavigation).length > 0) {
      newThirdLevelNavigation = activeThirdLevelNavigation.items && activeThirdLevelNavigation.items.length > 0 && activeThirdLevelNavigation.items.filter((item) => (item.title !== 'Email Templates' && item.title !== 'Annotations'))
    } else {
      newThirdLevelNavigation = activeThirdLevelNavigation && Object.keys(activeThirdLevelNavigation) && Object.keys(activeThirdLevelNavigation).length > 0 && activeThirdLevelNavigation.items
    }
    if (user.user_metadata.roles.osprey === 'user' && subNavMenu1 && subNavMenu1.length > 0) {
      subNavMenu1Update = subNavMenu1.filter((item) => (item.title !== 'Source mapping' && item.key !== 'data-sources') && (item.title !== 'My company' && item.key !== 'smart-box-company-settings'))
    } else if (user.user_metadata.roles.osprey === 'admin' && subNavMenu1 && subNavMenu1.length > 0) {
      subNavMenu1Update = subNavMenu1.filter((item) => (item.title !== 'My company' && item.key !== 'smart-box-company-settings'))
    } else if (this.props.user.inviteTenantPageActive !== true && subNavMenu1 && subNavMenu1.length > 0) {
      subNavMenu1Update = subNavMenu1.filter((item) => (item.title !== 'Create Tenant' && item.key !== 'home-invite-tenant'))
    } else {
      subNavMenu1Update = subNavMenu1 && subNavMenu1 && subNavMenu1.length > 0 && subNavMenu1
    }
    if (this.props.client.businessProcessesVisible === false && subNavMenu1 && subNavMenu1.length > 0) {
      subNavMenu1Update = subNavMenu1.filter((item) => (item.title !== 'Business processes' && item.key !== 'business-processes'))
    }
    if (this.props.client.sourceMappingVisible === false && subNavMenu1 && subNavMenu1.length > 0) {
      subNavMenu1Update = subNavMenu1.filter((item) => (item.title !== 'Source mapping' && item.key !== 'Source mapping'))
    }
    if (this.props.client.connectorsVisible === false && subNavMenu1 && subNavMenu1.length > 0) {
      subNavMenu1Update = subNavMenu1.filter((item) => (item.title !== 'Connectors' && item.key !== 'connectors'))
    }
    if (user.user_metadata.roles.osprey !== 'super_admin' && subNavMenu1 && subNavMenu1.length > 0) {
      subNavMenu1Update = subNavMenu1.filter((item) => (item.title !== 'Access tokens' && item.key !== 'apiConnector'))
    }
    return (
      <React.Fragment>
        <header className="header-container new-header-container">
          <div className="header-logo-and-navigation">
            <div className="header-logo">
              {/*{<span className="icon-v11-box-design icon-gravicus" onClick={() => this.redirectToHome()}></span>}*/}
              {<img src={this.props.client.activeBrandingProfile === false ? imgNewNavBox : this.props.client.brandingProfileColors && this.props.client.brandingProfileColors.homeIcon ? this.props.client.brandingProfileColors.homeIcon : imgNewNavBox } alt={this.props.client.brandingProfileColors && this.props.client.brandingProfileColors.homeIcon ? this.props.client.brandingProfileColors.homeIcon : imgNewNavBox } onClick={() => this.redirectToHome()} className="icon-gravicus"/>}


              {/*<span className="header-logo-xerox" onClick={() => this.redirectToHome()}>xerox</span>*/}



              {/*<span className="header-separator-icon" />*/}
            </div>
            <NewNavigation />
          </div>

          <div className="header-infoContainer" data-hj-suppress style={{ width: isMultitenant ? '770px' : '450px' }}>
            { <div className="header-multitenant">
              <Multitenant subscription={subscription} listSubTenants={listSubTenants} client={this.props.client} user={ this.props.user }
                           onResetSwitchToSubTenant={ this.onResetSwitchToSubTenant }/>
            </div>}
            {/*{ isMultitenant &&*/}
            {/*      <div className="header-company">*/}
            {/*        <Tooltip title="Reset client">*/}
            {/*          <p className="header-companyName new-header-companyName text-center">*/}
            {/*            /!*<Button onClick={this.resetTenantToDefaultClient} type="primary" icon="home"/>*!/*/}
            {/*            <span onClick={this.resetTenantToDefaultClient} className="icon-v12-home-active"></span>*/}
            {/*          </p>*/}
            {/*        </Tooltip>*/}
            {/*      </div>*/}
            {/*}*/}

            {/*{ !isMultitenant &&*/}
            {/*<div className="header-company">*/}
            {/*  <p className="header-companyName">{ this.getCompany(subscription).length > 20 ? `${ this.getCompany(subscription).substring(0, 20) }...` : this.getCompany(subscription)}</p>*/}
            {/*</div>*/}
            {/*}*/}
            <span className="header-separator" />
            <div className="header-new-icons-user">
              <Tooltip placement="bottom" title="Help">
                <span className="icon-v12-nav-help icon-hover"/>
              </Tooltip>
              { /*statusUploadedFiles || statusButtonUploadStatus ?
                <Tooltip overlayClassName="notification-first-upload" visible={ statusUploadedFiles ? true : statusButtonUploadStatus ? true : false } placement="bottom" title={<div className="content-notification">
                  <div className="content-folder-upload"><img src={ imgFolderParent } alt={ imgFolderParent }/><span className="name-folder-upload name-not-upload">
                    {`${ this.props.smartBoxCheckFirstUploadFile.folder && this.props.smartBoxCheckFirstUploadFile.folder.nameWithoutExtension ? this.props.smartBoxCheckFirstUploadFile.folder.nameWithoutExtension :
                      this.props.smartBoxButtonUploadStatus && this.props.smartBoxButtonUploadStatus.folderName ? this.props.smartBoxButtonUploadStatus.folderName : 'files' }: `}</span>
                  <span className="name-folder-upload"> Files uploaded</span></div><div><span className="icon-v14-radio-button-tick"></span></div></div> }>
                  <span className="icon-v12-nav-notifications nav-notifications-active"/>
                </Tooltip> :
                <Tooltip placement="bottom" title="Notifications">
                  <span className="icon-v12-nav-notifications"/>
                </Tooltip>*/
              }

              { statusDataReady ?
                <Tooltip overlayClassName="notification-first-upload" visible={ statusDataReady } placement="bottom" title={<div className="content-notification">
                  <div className="content-folder-upload"><img src={ imgFolderParent } alt={ imgFolderParent }/>
                    <span className="name-folder-upload">{ this.props.smartBoxDataReady.folder.nameWithoutExtension }:&nbsp;
                    Data ready</span></div></div> }>
                  <span className="icon-v12-nav-notifications nav-notifications-active icon-hover"/>
                </Tooltip> :
                  <Popover trigger="click"
                           content={this.content()}
                           // overlayClassName={"server-errors-notifications" +  ' ' + `${(this.getName(user) && this.getName(user).length <= 5) ? "left" : (this.getName(user) && this.getName(user).length > 8) ? '' : "center"}`}
                           overlayClassName={"server-errors-notifications"}
                           placement={'bottom'}
                           autoAdjustOverflow={false}
                           onVisibleChange={this.handleVisibleChangeNotifications}>
                    <span className="content-nav-notifications">
                       <span className={ !infoProcessError && joinedListNot && joinedListNot.length > 0 ? 'icon-v12-nav-notifications active-nav-notifications' : "icon-v12-nav-notifications" }/>
                      { !visibleNotifications && !infoProcessError && !valueVisibleErrorsNot && joinedListNot && joinedListNot.length > 0 &&
                        <span className={filesWarnings && filesWarnings.length > 0 && filesWarnings.includes('file-warning') ?
                            'icon-v23-warning-2' : "icon-v18-process-error" }></span>
                      }
                      </span>
                  </Popover>
              }

              {/*<Tooltip placement="bottom" title="User settings">*/}
              {/*  <a href="/osp/user/settings"><span className="icon-v8-Navigation_settings"/></a>*/}
              {/*</Tooltip>*/}
            </div>
            <div className="header-user" ref={(node) => { this.node = node }} onClick={ this.toggleMenu }>
              <div className="header-userIcon-main-app">
                { profileImage ?
                  (<img className="header-userIcon__profile active-userIcon"
                        src={profileImage ? profileImage : setImageProfile} alt={this.getName(user)}/>) :
                  (<span className="icon-user-circle-b"/>)
                }
              </div>
              <div className="header-userInfo">
                <p className="header-userName">{ this.getName(user) }</p>
              </div>
              { isMenuOpened &&
                <ul className="header-menu">
                  {this.props.user?.user_metadata?.roles?.application === 'god_admin' &&
                    <li className="header-listItem"><Link className="header-link" to="/admin/client-data">Admin dashboard</Link></li>
                  }
                  <li className="header-listItem"><Link className="header-link" to="/osp/user/settings">Profile</Link></li>
                  <li className="header-listItem"><button id="action-logout" className="header-link" onClick={ () => this.doLogout() }>Log out</button></li>
                  {/*<li className="header-listItem"><Link className="header-link" to="/osp/user/my_plan">My Plan</Link></li>*/}
                </ul>
              }
            </div>
          </div>
        </header>
        { subNavMenu1.length > 0 &&
        <div className="subheader" data-history={this.props.location.pathname}>
          { subNavMenu1Update.map((item) => {
            return(
              <div key={`second-level-`+item.key}
                className={activeSecondLevelNavigation === item.url ? 'active header-active' : 'header-inactive'}
                data-url={item.url}
                onClick={ () => { this.setActiveSecondNavigation(item.url)} }>
                { item.title === 'Data sources' ? 'Source mapping' : item.title }
              </div>
            )
          })}
        </div>
        }
        { subNavMenu1.length > 0 && subNavMenu2.length > 0 &&
        <div className="subheader2">
          { subNavMenu2.map((item) => (
            <div key={`third-level-`+item.key}
                 className={activeThirdLevelNavigation === item.url ? 'active' : ''}
                 data-url={item.url}
                 onClick={ () => { this.setActiveThirdNavigation(item.url)} }>{ item.title }</div>) ) }
        </div>
        }
      </React.Fragment>
    )
  }
}

Header = withRouter(Header)
Header.propTypes = {
  updateUserInfo: PropTypes.func.isRequired,
}
const mapStateToProps = (state) => ({
  applicationIntervals: state.applications.applicationIntervals,
  user: state.userReducer.user,
  client: state.subscriptionReducer.subscription.subscription.client,
  smartBoxCheckFirstUploadFile: state.smartBox.smartBoxCheckFirstUploadFile,
  smartBoxButtonUploadStatus: state.smartBox.smartBoxButtonUploadStatus,
  smartBoxDataReady: state.smartBox.smartBoxDataReady,
  smartBoxErrorLoadingThumbnails: state.smartBox.smartBoxErrorLoadingThumbnails,
  smartBoxFilesErrorConverted: state.smartBox.smartBoxFilesErrorConverted,
  smartBoxUploadFailed: state.smartBox.smartBoxUploadFailed,
  smartBoxNotSarTracker: state.smartBox.smartBoxNotSarTracker,
  smartBoxNotRedactComplete: state.smartBox.smartBoxNotRedactComplete,
  smartBoxAddedNote: state.smartBox.smartBoxAddedNote,
  smartBoxStatusChange: state.smartBox.smartBoxStatusChange,
  smartBoxNotUploadComplete: state.smartBox.smartBoxNotUploadComplete,
  smartBoxNotGiveAccessBox: state.smartBox.smartBoxNotGiveAccessBox,
  smartBoxNotAnalysisComplete: state.smartBox.smartBoxNotAnalysisComplete,
  smartBoxNotAccessPermission: state.smartBox.smartBoxNotAccessPermission,
  smartBoxExistsFilesWarningsNotification: state.smartBox.smartBoxExistsFilesWarningsNotification,
  setImageProfile: state.smartBox.setImageProfile,
  applicationPermissions: new Map(
    [ [ 'isDpiaVisible', state.applications.dpiaVisible ],
      [ 'isSarVisible', state.applications.sarVisible ],
      [ 'isSmartBoxVisible', state.applications.smartBoxVisible ],
      [ 'isAssetRegisterVisible', state.applications.assetRegisterVisible ],
      [ 'isDocLockerVisible', state.applications.docLockerVisible ],
      [ 'isDataMappingAppVisible', state.applications.dataMappingAppVisible ],
      [ 'isWorkflowAppVisible', state.applications.workflowAppVisible ],
      [ 'isAdditionalEnvironmentsVisible', state.applications.additionalEnvironmentsVisible ]
    ])
})


export default connect(mapStateToProps, {
  removeAllIntervalsFromApplication,
  setSmartBoxDataReady,
  setSmartBoxHomeClicked,
  setSmartFilesErrorNotBeConverted,
  setSmartBoxUploadFailed,
  setSmartBoxNotSarTracker,
  setSmartBoxErrorLoadingThumbnails,
  setSmartBoxNotFolderRedactionComplete,
  setSmartBoxAddedNote,
  setSmartBoxStatusChange,
  setSmartBoxNotUploadComplete,
  setSmartBoxNotGiveAccessBox,
  setSmartBoxNotAnalysisComplete,
  setSmartBoxNotAccessPermission,
  setSmartBoxFilterRefreshOpenedBox,
  setSmartBoxCheckBoxRedactionStatus,
  userLogout,
  setSmartBoxSelectedKeys,
  setBoxListViewSelectedKeys,
  setSmartBoxExistsFilesWarningsNotification,
  setImageProfileSettings
})(Header)