import PropTypes from 'prop-types'
import React from 'react'
import { truncateString } from '../../../../utils'
import LockedMessage from '../../../../utils/LockedMessage'
import Accordion from '../../../Common/Accordion'
import ToggleAccordion from '../../../Common/ToggleAccordion'
import './Locked.scss'
import { Tooltip } from 'antd'

const Locked = ({ accordion, content, index, process, subscription, type, dpiaQuestionnaireType, user, toggleAccordion }) => (
  <div className="lockedDashboard-wrapper">
    <Accordion
      opened={ accordion[index] }
      header={
        <div className={ accordion[index] ? 'lockedDashboard-itemHeaderOpened' : 'lockedDashboard-itemHeader' }>
          <div className="lockedDashboard-infoContainer">
            <div className="lockedDashboard-info" onClick={ () => toggleAccordion(accordion, index) }>
              <div className="lockedDashboard-name">
                <span>{type} - </span>{ truncateString(process.title, 50) }
                <Tooltip title="You've reached our Free limit plan.">
                  <span 
                    className="iconLock"
                    onClick={ () => LockedMessage.show(user, subscription.plan, type) }
                  />
                </Tooltip>
              </div>
              <h2 className="lockedDashboard__questionGroup">{ dpiaQuestionnaireType }</h2>
            </div>
          </div>
          <ToggleAccordion
            accordion={ accordion }
            index={ index }
            toggleAccordion={ toggleAccordion.bind(this, accordion, index) }
          />
        </div>
      }
      content={ content }
    />
  </div>
)

Locked.propTypes = {
  accordion: PropTypes.object.isRequired,
  content: PropTypes.element.isRequired,
  index: PropTypes.number.isRequired,
  process: PropTypes.object.isRequired,
  dpiaQuestionnaireType: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  toggleAccordion: PropTypes.func.isRequired
}

export default Locked