import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import '../ExternalFeedbackWorkflowTicketForm.scss'
import {Input, Select, Steps} from "antd";
import {NameTemplateFormExternal} from "./NameTemplateFormExternal";
const { Option } = Select;
const { Step } = Steps;

export class TelephoneTemplateFormExternal extends Component {

    state = {

    }
    onChangeNameAndDetails = (e, key) => {
        const { component } = this.props
        switch (key) {
            case 'telephone':
                this.props.onChangeNameAndDetails(e, key, component)
                break
            default:
                break
        }
    }
    render() {
        const { formerEmployee, component } = this.props
        return (
            <div className="full-name-former">
                <span className="header-former header-former-address">{component.header && component.mandatory ? `${component.header}*` :
                    component.header ? component.header : 'Telephone'}</span>
                <span className="input-content">
                                <Input className="input-form-name"
                                       onChange={(e) => this.onChangeNameAndDetails(e, 'telephone')}
                                       value={ component.phoneNumber }/>
                                <span className="label-input">Telephone</span>
                            </span>
            </div>
        )
    }
}


export default connect()(TelephoneTemplateFormExternal)