import React from "react";
import {connect} from "react-redux";
import './ComponentsForm.scss';
import {Input} from "antd";

class HeaderForm extends React.Component {
    state = {
        hoverDelete: false,
        headerForm: {
            type: 0,
            queryForSearch: null,
            mandatoryCheck: null,
            options: null,
            header: 'Header',
            subheader: 'Type a subheader',
            sectionVariable: ''
        },
        editHeader: false,
        editSubheader: false,
        hoverEditHeader:false,
        hoverEditSubheader:false,

    }
    componentDidMount() {
        const { component } = this.props
        this.setState({
            headerForm: { ...this.state.headerForm,
                queryForSearch: component.queryForSearch === undefined ? null : component.queryForSearch,
                mandatoryCheck: component.mandatory === undefined ? null : component.mandatory,
                options: component.options === undefined ? null : component.options,
                header: component.header === undefined ? 'Header' : component.header,
                subheader: component.subheader === undefined ? 'Type a subheader' : component.subheader,
                sectionVariable: component.sectionVariable === undefined ? '' : component.sectionVariable,
            }
        })
        //this.props.onChangeForm(this.state.headerForm, this.props.component)
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props && this.props.checkStartSaved) {
            this.props.onChangeForm(this.state.headerForm, this.props.component)
        }
    }

    onHoverActionComponent = () => {
        this.setState({
            hoverDelete: true
        })
    }
    onLeaveActionComponent = () => {
        this.setState({
            hoverDelete: false
        })
    }
    onHoverEditHeader = () => {
        this.setState({
            hoverEditHeader: true
        })
    }
    onLeaveEditHeader = () => {
        this.setState({
            hoverEditHeader: false
        })
    }
    onHoverEditSubheader = () => {
        this.setState({
            hoverEditSubheader: true
        })
    }
    onLeaveEditSubheader = () => {
        this.setState({
            hoverEditSubheader: false
        })
    }
    onClickEditHeader = (e, status) => {
      if (status === 'header') {
          this.setState({
              editHeader: true
          })
      } else {
         this.setState({
             editSubheader: true
         })
      }
    }
    addTitleHeader = (e, status) => {
        if (status === 'header') {
            this.setState({
                headerForm: { ...this.state.headerForm, header: e.target.value },
                //editHeader: false
            }, () => {
                this.props.onChangeForm(this.state.headerForm, this.props.component)
            })
        } else {
            this.setState({
                headerForm: { ...this.state.headerForm, subheader: e.target.value },
                //editSubheader: false
            }, () => {
                this.props.onChangeForm(this.state.headerForm, this.props.component)
            })
        }
    }
    onChangeFormVariable = (e) => {
        this.setState({
            headerForm: { ...this.state.headerForm, sectionVariable: e.target.value },
        }, () => {
            this.props.onChangeForm(this.state.headerForm, this.props.component)
        })
    }
    confirmEditHeader = () => {
        this.setState({
            editHeader:false,
        })
    }
    confirmEditSubheader = () => {
        this.setState({
            editSubheader:false,
        })
    }
    render() {
        const { hoverDelete, editHeader, editSubheader, headerForm, hoverEditHeader, hoverEditSubheader} = this.state
        const { component, defaultTemplate } = this.props
        return (
            <div className={ this.props.snapshot.isDragging ? "component-form dragging " : "component-form"}>
              <div className="component-delete">
                  <button onClick={ (e) => this.props.showConfirmDeleteFormElement(e, component) }
                        className={hoverDelete ? 'icon-v19-delete-active' : "icon-v19-delete" }
                          disabled={defaultTemplate}
                        onMouseEnter={ (e) => this.onHoverActionComponent(e)}
                        onMouseLeave={() => this.onLeaveActionComponent()}></button>
              </div>
              <div className="component-content">
                  {/*<div className="content-input-form-variables">*/}
                  {/*      <span className="content-variables">*/}
                  {/*           <span className="variables-diaz">#</span>*/}
                  {/*      <Input*/}
                  {/*          className="input-form-variables"*/}
                  {/*          placeholder="Form variable"*/}
                  {/*          value={headerForm.sectionVariable}*/}
                  {/*          onChange={(e) => this.onChangeFormVariable(e)}*/}
                  {/*      />*/}
                  {/*      </span>*/}
                  {/*</div>*/}
                  <div className="header-component header-component-new">
                          <span className="header-content">
                              { editHeader ?
                                  <div>
                                      <Input defaultValue={headerForm.header} className="input-other-option"
                                             onChange={ (e) => this.addTitleHeader(e, 'header')}
                                             onPressEnter={() => this.confirmEditHeader()}
                                      />
                                      <span className={ hoverEditHeader ?"icon-v21-edit-active" : "icon-v21-edit"}
                                            onClick={(e) => this.confirmEditHeader()}
                                            onMouseEnter={() => this.onHoverEditHeader()}
                                            onMouseLeave={() => this.onLeaveEditHeader()}></span>

                                  </div>
                                  :
                                  <div>
                                      <span className="header-title header-title-update">{headerForm.header}</span>
                                      <button className={ hoverEditHeader ?"icon-v21-edit-active" : "icon-v21-edit"}
                                            onClick={(e) => this.onClickEditHeader(e, 'header')}
                                            disabled={defaultTemplate}
                                            onMouseEnter={() => this.onHoverEditHeader()}
                                            onMouseLeave={() => this.onLeaveEditHeader()}></button>
                                  </div>

                              }

                          </span>
                           <span className="subheader-content">
                               { editSubheader ?
                                   <div>
                                       <Input defaultValue={headerForm.subheader} className="input-other-option"
                                              onChange={ (e) => this.addTitleHeader(e, 'subheader')}
                                              onPressEnter={() => this.confirmEditSubheader()}
                                       />
                                       <span className={ hoverEditSubheader ? "icon-v21-edit-active" : "icon-v21-edit"}
                                             onClick={(e) => this.confirmEditSubheader()}
                                             onMouseEnter={()=>this.onHoverEditSubheader()}
                                             onMouseLeave={() =>this.onLeaveEditSubheader()}
                                       ></span>
                                   </div>
                                   :
                                   <div>
                                       <span className="header-title">{headerForm.subheader}</span>
                                       <button className={ hoverEditSubheader ? "icon-v21-edit-active" : "icon-v21-edit"}
                                             onClick={(e) => this.onClickEditHeader(e, 'subheader')}
                                             disabled={defaultTemplate}
                                             onMouseEnter={()=>this.onHoverEditSubheader()}
                                             onMouseLeave={() =>this.onLeaveEditSubheader()}
                                       ></button>
                                   </div>
                               }
                          </span>
                  </div>
              </div>
              <div className="component-move">
                  <span onMouseEnter={() => this.props.onStateProvidedDraggable(component)}
                        //onMouseLeave={() => this.props.onLeaveProvidedDraggable(false)}
                      className="icon-v211-grid-view-1"></span>
              </div>
            </div>
        )
    }
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(HeaderForm)