import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import './Locked.scss'

const Locked = ({ reoccurringMessage, process, type }) => (
  <div className="dpiaDashboard__lockedProcess-info">
    <p>{ process.description || 'No Description available' }</p>
    <div>
      {
        reoccurringMessage &&
        <span className="dpiaDashboard__lockedProcess-recurring">
          { reoccurringMessage }
        </span>
      }
      <span>{type} created: <strong>{ moment(process.creationDate).format('MMMM Mo, YYYY') }</strong></span>
      <span
        data-tip="You need to subscribe to Unlimited plan to access this feature."
        className="iconEditDisabled"
      />
    </div>
  </div>
)

Locked.propTypes = {
  reoccurringMessage: PropTypes.string,
  process: PropTypes.object.isRequired
}

export default Locked