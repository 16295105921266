import React from 'react'
import { compose } from 'redux'
import Loading from '../../Common/Loading'
import WithProcesses from './HOC/WithProcesses'
import ProcessList from './Processes/ProcessList'
import './Processes.css'

class Processes extends React.Component {
    
    componentDidMount = () => {
      window.scrollTo(0, 0)
    }
  
  
    render = () => {
      return (
        <div className="backofficeProcesses__wrapper">
          <div className="backofficeProcesses__box">
            <header className="backofficeProcesses__boxHeader">
              <div>
                <h1 className="backofficeProcesses__boxTitle">
                  Running Processes
                </h1>
                <span className="backofficeProcesses__boxTitleTotal">{ this.props.totalProcesses } total</span>
                <p className="backofficeProcesses__boxText">
                  Analysis processes that are currently running. 
                </p>
              </div>
            </header>
            { this.props.isFetchingProcesses
              ? <div className="backofficeProcesses__loading"><Loading /></div>
              : <ProcessList processes={ this.props.processes } />
            }
          </div>
        </div>
      )
    }
}

Processes = WithProcesses(Processes)

export default compose()(Processes)