import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import './ClutterPage.scss'
import EmailThreadsTable from "./EmailThreadsTable"
import EmailThreadsList from "./EmailThreadsList"
import {Tooltip} from "antd";

class EmailThreadsPage extends React.Component {
    state = {
        visibleEmailThreadsList: false,
        selectFileBox: {},
    }
    componentDidMount() {
    }
    onVisibleEmailThreadsList = (status) => {
       this.setState({
           visibleEmailThreadsList: status
       })
    }
    selectFileDuplicateBox = (file) => {
        this.setState({
            selectFileBox: file
        })
    }
    render() {
        const { visibleEmailThreadsList, selectFileBox } = this.state
        return (
            <div className="new-clutter-page">
                { !visibleEmailThreadsList ?
                    <div className="new-clutter-page__content">
                        <EmailThreadsTable folder={this.props.folder}
                                           onGetSelectFilesEmptyFolder={this.props.onGetSelectFilesEmptyFolder}
                                           checkStatusClutter={this.props.checkStatusClutter}
                                           onVisibleEmailThreadsList={this.onVisibleEmailThreadsList}
                                           selectFileDuplicateBox={this.selectFileDuplicateBox}
                                           onGetSearchEmptyFolder={this.props.onGetSearchEmptyFolder}
                                           onGetSelectAllEmailThreads={this.props.onGetSelectAllEmailThreads}
                                           onGetDeclutterEmailsFirstPageHashList={this.props.onGetDeclutterEmailsFirstPageHashList}
                                           onBackFilesEmptyFolder={this.props.onBackFilesEmptyFolder}
                        />
                    </div>
                    :
                    <div className="new-clutter-page__content">
                        <EmailThreadsList folder={this.props.folder}
                                           onGetSelectFilesEmptyFolder={this.props.onGetSelectFilesEmptyFolder}
                                           checkStatusClutter={this.props.checkStatusClutter}
                                           onVisibleEmailThreadsList={this.onVisibleEmailThreadsList}
                                          selectFileBox={ selectFileBox }
                                          onGetSearchEmptyFolder={this.props.onGetSearchEmptyFolder}
                                          onGetSelectAllEmailThreads={this.props.onGetSelectAllEmailThreads}
                                          onGetDeclutterEmailsSecondPageHashList={this.props.onGetDeclutterEmailsSecondPageHashList}
                        />
                    </div>
                }
            </div>
        )

    }
}

EmailThreadsPage.propTypes = {
    folder: PropTypes.object,
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id
})
export default compose(
    connect(mapStateToProps)
)(EmailThreadsPage)