import React from 'react'
import PropTypes from 'prop-types'

import CollectionItem from './CollectionItem'

const CollectionList = ({ collections }) => (
  <div id="collection-list" className="collectionList__wrapper">
    { collections.map((collection, index) => (
      <CollectionItem key={ index } collection={ collection } />
    )) }
  </div>
)

CollectionList.propTypes = {
  collections: PropTypes.array.isRequired
}

export default CollectionList