import React from "react";
import {connect} from "react-redux";
import '../BuilderWorkflow.scss';
import {Input, Checkbox, Select} from "antd";
import {apiGetFormBuilderListWorkflow, apiGetFormVariablesWorkflow} from "../../../../../api/SmartBox";
import {setGetVariablesEmailsWorkflow} from "../../../../../actions/smartBoxActions";
const { Option } = Select
class FormWorkflowBuilder extends React.Component {
    state = {
        formOptions: {
            selectForm: '',
            formVariables: [],
            componentName: ''
        },
        hoverDelete: false,
        listForms: []
    }
    componentDidMount() {
        this.setState({
            listForms: this.props.listFormsWorkflow
        }, () => {
            //this.props.onSelectIdFormGenerateListForms(this.props.listFormComponents, this.props.listFormsWorkflow)
            if (this.props.component.idComponent) {
                this.onGetFormVariablesWorkflow(this.props.component.idComponent)
            }
        })
        // apiGetFormBuilderListWorkflow().then((response) => {
        //     this.setState({
        //         listForms: response.data
        //     }, () => {
        //         if (this.props.component.idComponent) {
        //            this.onGetFormVariablesWorkflow(this.props.component.idComponent)
        //         }
        //     })
        // })
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.listFormsWorkflow && this.props.listFormsWorkflow && prevProps.listFormsWorkflow.length !== this.props.listFormsWorkflow.length) {
            this.setState({
                listForms: this.props.listFormsWorkflow
            })
        }
        if (prevProps !== this.props) {
            this.props.onSelectIdFormGenerateListForms(this.props.listFormComponents, this.props.listFormsWorkflow)
        }
    }

    onGetFormVariablesWorkflow = (formId) => {
        const { listForms } = this.state
        apiGetFormVariablesWorkflow(formId).then((response) => {
            const variablesForm = []
            let componentName = ''
            const e = this.props.component.idComponent
            listForms && listForms.length > 0 && listForms.forEach((list) => {
                if (list.formId === e) {
                    componentName = list.formName
                }
            })
            response.data && response.data.length > 0 && response.data.forEach((item) => {
                variablesForm.push(`# ${item}`)
            })
            this.props.setGetVariablesEmailsWorkflow(variablesForm)
            this.setState({
                formOptions: { ...this.state.formOptions, selectForm: e, componentName: componentName, idComponent: e, formVariables: variablesForm},
            }, () => {
                this.props.onChangeBuilder(this.state.formOptions, this.props.component)
                this.props.onSelectIdForm(e)
            })
        })
    }
    onHoverActionComponent = () => {
        this.setState({
            hoverDelete: true
        })
    }
    onLeaveActionComponent = () => {
        this.setState({
            hoverDelete: false
        })
    }
    onSelectForm = (e) => {
        const { listForms, formOptions } = this.state
        const formId = e
        const variablesForm = []
        let componentName = ''
        listForms && listForms.length > 0 && listForms.forEach((list) => {
              if (list.formId === e) {
                  componentName = list.formName
              }
        })
        apiGetFormVariablesWorkflow(formId).then((response) => {
            response.data && response.data.length > 0 && response.data.forEach((item) => {
                variablesForm.push(`# ${item}`)
            })
            this.props.setGetVariablesEmailsWorkflow(variablesForm)
            this.setState({
                formOptions: { ...this.state.formOptions, selectForm: e, componentName: componentName, idComponent: e, formVariables: variablesForm},
            }, () => {
                this.props.onChangeBuilder(this.state.formOptions, this.props.component)
                this.props.onSelectIdForm(e)
                this.props.onSelectIdFormGenerateListForms(this.props.listFormComponents, listForms)
            })
        })
    }
    render() {
        const { listForms, hoverDelete, formOptions } = this.state
        const { component, listFormComponents, selectWorkflowTemplate } = this.props
        return (
            <div className="component-form">
                <div className="component-content">
                    <div className="header-component">
                        <span className="header-title">Form</span>
                    </div>
                    <div className="select-content">
                          <span onClick={ (e) => this.props.showConfirmDeleteSection(e, component) }
                              className={selectWorkflowTemplate.defaultTemplate === true ? 'icon-v19-delete icon-v19-delete-disabled' : hoverDelete ? 'icon-v19-delete-active' : "icon-v19-delete" }
                              onMouseEnter={ (e) => this.onHoverActionComponent(e)}
                              onMouseLeave={() => this.onLeaveActionComponent()}></span>
                        <Select className="select-form"
                                value={ formOptions.selectForm ? formOptions.selectForm : component.componentName ? component.componentName : 'Select' }
                                onChange={(e) => this.onSelectForm(e)}
                                dropdownClassName={'select-form-dropdown'}
                                placeholder="Select">
                            { listForms && listForms.length > 0 && listForms.map((item,index) => {
                                return  (<Option
                                    //disabled={item.disabledForm === true}
                                    className="options-select-connector" value={item.formId} key={index}>
                                    {item.formName}
                                </Option>)
                            })}
                        </Select>
                        </div>
                    {/*{ formOptions.formVariables && formOptions.formVariables.length > 0 ?*/}
                    {/*<span className="content-form-variables">*/}
                    {/*     <span className="variables-active"> Variables:</span>*/}
                    {/*    { formOptions.formVariables && formOptions.formVariables.length > 0 && formOptions.formVariables.map((item, index) => {*/}
                    {/*        return (*/}
                    {/*                   <span key={index} className="variables">{item}</span>*/}
                    {/*        )*/}
                    {/*    })*/}

                    {/*    }*/}
                    {/*</span> :*/}
                    {/*    <span className="content-form-variables">*/}
                    {/*   <span className="variables-default">Variables:</span>*/}
                    {/*</span>*/}
                    {/*    }*/}
                </div>
                <div className="component-move">
                    <span onMouseEnter={() => this.props.onStateProvidedDraggable(component)} className="icon-v211-grid-view-1"></span>
                </div>
            </div>
        )
    }
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps, {setGetVariablesEmailsWorkflow})(FormWorkflowBuilder)