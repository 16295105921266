import React from 'react'

export const CompleteStepCard = ({ icon, value, text }) => {

  return (
    <div className="card">
      <span className={ `card-icon ${icon}` } />
      <p className="badge-text badge-text--bold">{value}</p>
      <p className="badge-text">{text}</p>
    </div>
  )
}