import React from 'react'
import PropTypes from 'prop-types'

import ProcessTable from './ProcessTable'

import './ProcessList.css'

const ProcessList = ({ processes }) => (
  <div id="process-list" className="processList__wrapperTable">
    <table>
      <thead>
        <tr>
          <th style={{ textAlign: 'center' }}>ID</th>
          <th style={{ textAlign: 'center' }}>Description</th>
          <th style={{ textAlign: 'center' }}>Category</th>
          <th style={{ textAlign: 'center' }}>Speed</th>
          <th style={{ textAlign: 'center' }}>Processed</th>
          <th style={{ textAlign: 'center' }}>State</th>
          <th style={{ textAlign: 'center' }}>Type</th>
          <th style={{ textAlign: 'center' }}>Stop</th>
        </tr>
      </thead>
      <tbody>
        { processes && processes.length > 0 && processes.map((process, index) => (
          <ProcessTable process={ process } key={ index } />
        ))}
      </tbody>
    </table>
  </div>
)

ProcessList.propTypes = {
  processes: PropTypes.array.isRequired
}

export default ProcessList