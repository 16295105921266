import React from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { Field, reduxForm, submit } from 'redux-form'
import { apiSubjectAccessRemoteCreate, apiSubjectAccessUpdate } from '../../../api/Sar'
import CustomNotificationManager from '../../Common/CustomNotificationManager'
import './SubjectAccess.scss'
import { connect } from 'react-redux'
import { fetchUserSarForm, change } from '../../../actions/sarActions'
import Autocomplete from 'react-google-autocomplete'
import { Spin } from 'antd'
import Loading from '../../Common/Loading'
import scriptLoader from 'react-async-script-loader'
import swal from "sweetalert2"
import withRouter from '../../../private-routers/withRouter'

class SubjectAccessForm extends React.Component {

  constructor() {
    super()
    this.state = {
      surname: '',
      base64Signature: '',
      fromEmailLink: false,
      fullAddress: '',
      loading: '',
      disabledSubmit: false,
      sarId: 0,
      checkFormOnEndRequest: false
    }
    this.handleClearSignature = this.handleClearSignature.bind(this)
    this.signatureCanvas = React.createRef()
  }

  componentDidMount() {
    const urlSegments = window.location.href.split('/')
    if (!Number.isNaN(urlSegments[urlSegments.length-1])) {
      this.setState({ sarId: urlSegments[urlSegments.length-1] })
    }
    if (this.props.params.id) {
      this.props.fetchUserSarForm(this.props.params.id)
    }
  }

  handleSubmit = (formValues) => {
    this.setState({
      loading: true
    })
    formValues.signature = this.signatureCanvas.toDataURL()
    let editMode = false
    if (this.props.initialValues) {
      editMode = this.props.initialValues.editMode
    }

    if (this.state.fromEmailLink && editMode) {
      apiSubjectAccessUpdate(formValues)
        .then((response) => {
          const answerResponse = response.data
          if (answerResponse === 'ok') {
            CustomNotificationManager.success('Subject Access Request submitted.', 'Successfully updated')
            this.setState({
              loading: false,
              disabledSubmit: true
            })
            setTimeout(function() {
              window.close()
            }, 1000)
          } else {
            CustomNotificationManager.info('The SAR is already closed, and cannot be completed anymore.')
            this.props.fetchUserSarForm(this.props.params.id)
            this.setState({
              checkFormOnEndRequest: true
            })
            setTimeout(function() {
              window.close()
            }, 5000)
          }
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
      })
    } else if (!this.state.fromEmailLink) {
      apiSubjectAccessRemoteCreate(formValues)
        .then(() => {
          CustomNotificationManager.success('Subject Access Request submitted.', 'Successfully updated')
          this.setState({
            loading: false,
            disabledSubmit: true
          })
          setTimeout(function() {
            this.props.navigate(-1) || window.close()
          }, 1000)
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
      })
    }
  };

    onPlaceSelected = (place) => {
      this.props.change('address', place.formatted_address)
    }

    handleClearSignature() {
      this.signatureCanvas.clear()
    }

    renderError({ error, touched }) {
      if (touched && error) {
        return (
          <span className="error_container">
            <span className="error_item">{error}</span>
          </span>
        )
      }
    }

    renderInput = ({ input, label, type, meta, className }) => {
      return (
        <div className={`userSettings-formGroup ${ className }`}>
          <label htmlFor={label}>{label}<span className="required_field">*</span></label>
          <input {...input} type={type} className={`${ className }__input`} />
          <span className="edit-icon icon-v10-edit-b" />
          {this.renderError(meta)}
        </div>
      )
    };

  renderAddressInput = ({ input, label, meta, className }) => {
    return (
      <div className={`userSettings-formGroup ${ className }`}>
        <label htmlFor={label}>{label}<span className="required_field">*</span></label>
        <Autocomplete
          {...input}
          style={{
            width: '100%'
          }}
          onPlaceSelected={ this.onPlaceSelected }
          placeholder={''}
          types={['geocode']}
          className={`${ className }__input`}
        />
        <span className="edit-icon icon-v10-edit-b" />
        {this.renderError(meta)}
      </div>
    )
  }

    renderTextarea = ({ input, label, className, meta }) => {
      return (
        <div className="userSettings-formGroup">
          <label htmlFor={label}>{label}<span className="required_field">*</span></label>
          <textarea {...input} className={className} maxLength="255"/>
          { this.renderError(meta) }
        </div>
      )
    }

    renderHiddenCheckbox = ({ input, type, className, meta }) => {
      return (
        <div>
          <input {...input} type={type} className={className}/>
          {this.renderError(meta)}
        </div>
      )
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps !== this.props) {
        const { initialValues, valid, isScriptLoaded, isScriptLoadSucceed } = this.props
        if (isScriptLoaded && isScriptLoadSucceed) {
          if (this.props.params.id) {
            //this.props.fetchUserSarForm(this.props.params.id)
            this.setState({
              fromEmailLink: true,
              isComponentReady: true
            })
          }
        }

        if (initialValues && !initialValues.editMode && valid) {
          this.renderNotificationMessage()
        }
        if (initialValues && initialValues.sarStep === 'COMPLETE') {
          this.closedNotificationMassage()
        }
      }
    }

    renderNotificationMessage() {
      CustomNotificationManager.warning('Subject Access Request already submitted.')
      setTimeout(function() {
        window.close()
      }, 5000)
    }
    closedNotificationMassage() {
      CustomNotificationManager.info('The SAR is already closed, and cannot be completed anymore.')
      setTimeout(function() {
        window.close()
      }, 5000)
    }
  onClickDiscardChanges = (event) => {
    event.preventDefault()
    swal.fire({
      text: 'Do you want to clear form?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3DD8DB',
      cancelButtonColor: '#D33D33',
      confirmButtonText: 'Yes',
      customClass: 'swal-alert-discard'
    }).then((result) => {
      if (result.value) {
        this.props.destroy('form')
        this.signatureCanvas.clear()
      } else {
        CustomNotificationManager.info('Action cancelled', 'Aborted')
      }
    })
  }
  render() {
    const { initialValues } = this.props
    const { loading, disabledSubmit, isComponentReady, fromEmailLink, checkFormOnEndRequest } = this.state
    const editMode = initialValues ? initialValues.editMode : false
    return (
      (initialValues && initialValues.sarStep === 'COMPLETE') || checkFormOnEndRequest ? <div className="closed-form-request">
        <span className="message-closed-sar">The sar was closed.</span>
      </div> :
          (initialValues && initialValues.sarStep !== 'ACKNOWLEDGE_UNCOMPLETED') ?
          <div className="closed-form-request">
            <span className="message-closed-sar">Subject Access Request already submitted.</span>
          </div> :
          <div>
            { isComponentReady ?
              <form className="sa_form" onSubmit={this.props.handleSubmit(this.handleSubmit)}>
                <header className="sa_header">
                  <div className="sa_row">
                    <div className="col-xs-12 col-sm-8 col-md-9">
                      <h1 className="sa-title">{this.props.title}</h1>
                    </div>
                    {fromEmailLink === 'false' &&
                <div className="col-xs-12 col-sm-4 col-md-3">
                  {
                    loading ? <Spin className="float-right" spinning={loading}
                      indicator={<div className="checkout-loading"><Loading/></div>}/> :
                      <button className="button-blue float-right"
                        disabled={!editMode || disabledSubmit}>Submit this request
                      </button>
                  }
                </div>
                    }
                  </div>
                </header>
                <div className="sa_content">
                  <div className="custom-row">
                    <Field type="hidden" name="id" component="input"/>
                  </div>
                  <div className="custom-row">
                    <Field type="text" name="title" label="Title" className="custom-row-group" component={this.renderInput}/>
                  </div>
                  <div className="custom-row">
                    <Field type="text" name="forename" label="Forename(s)" className="custom-row-group" component={this.renderInput}/>
                  </div>
                  <div className="custom-row">
                    <Field type="text" name="surname" label="Surname" className="custom-row-group" component={this.renderInput}/>
                  </div>
                  <div className="custom-row">
                    <Field type="text" name="address" label="Address" className="custom-row-group" component={this.renderAddressInput}/>
                  </div>
                  <div className="custom-row">
                    <Field type="text" name="telephone" label="Telephone Number" className="custom-row-group" component={this.renderInput}/>
                  </div>
                  <div className="custom-row">
                    <Field type="email" name="workEmail" label="Email Address" className="custom-row-group" component={this.renderInput}/>
                  </div>
                  <h2>Information Being Requested</h2>
                  <p className='mb-1 userSettings-sectionSubtitle'>Please provide specific details (along with any
                  relevant dates) of the information being requested and any additional information that may help
                  us to locate your personal data and to confirm your indentity.</p>
                  <p className='userSettings-sectionSubtitle'>By completing this form, you are making a subject acces
                  request under the GDPR for personal data collected, processed, and help about you by us that you
                  are entitied to receive.</p>
                  <div className="custom-row">
                    <Field type="text" name="details" label="Details" className="custom-row-group" component={this.renderInput}/>
                  </div>

                  <h2>Declaration</h2>
                  <p className='userSettings-sectionSubtitle'>By using below, you confirm that you are the data
                  subject named in this Subject Access Request Form. You warrant that you are the individual named
                  and will fully indemnify the information Security Forum Limited for all losses and expenses
                  incurred if you are not. We cannot accept requests in respect of your personal from anyone else,
                  including members of your family.</p>
                  <div className="signature_wrapper">
                    <SignatureCanvas
                      ref={(ref) => {
                        this.signatureCanvas = ref
                      }}
                      penColor='#000'
                      canvasProps={{ className: 'signature' }}
                    />
                    <button type="button" className="button-small-red-empty float-right"
                      onClick={this.handleClearSignature}>Clear
                    </button>
                  </div>
                  <Field name="signature_check" type="checkbox" className="dn" component={this.renderHiddenCheckbox}/>
                </div>
                <footer className="userSettings-footer new-footer-settings">
                  {/*<a className="discard-changes-button" href="/sar/subject-access">Discard changes</a>*/}
                  <button className="button-blue"
                    //onClick={() => this.props.navigate(`/subject-access/${ sarId }`)}
                    onClick={ (e) => this.onClickDiscardChanges(e) }
                  >
                    Discard changes
                  </button>
                  <div>
                    {
                      loading ? <Spin className="float-right" spinning={loading}
                        indicator={<div className="checkout-loading"><Loading/></div>}/> :
                        <button className="button-red">Submit this
                        request
                        </button>
                    }
                  </div>
                </footer>
              </form> : '' }
          </div>
    )
  }
}

const validate = (formValues) => {
  const errors = {}
  if (!formValues.title) {
    errors.title = 'This field is required'
  } else if (formValues.title.length < 2) {
    errors.title = 'This field is too short'
  }
  if (!formValues.forename) {
    errors.forename = 'This field is required'
  } else if (formValues.forename.length < 3) {
    errors.forename = 'This field is too short'
  }
  if (!formValues.surname) {
    errors.surname = 'This field is required'
  } else if (formValues.surname.length < 3) {
    errors.surname = 'This field is too short'
  }
  if (!formValues.address) {
    errors.address = 'This field is required'
  } else if (formValues.address.length < 3) {
    errors.address = 'This field is too short'
  }
  if (!formValues.telephone) {
    errors.telephone = 'This field is required'
  } else if (!isNaN(errors.telephone)) {
    errors.telephone = 'This field should be number'
  }

  if (!formValues.workEmail) {
    errors.workEmail = 'This field is required'
  }

  if (!formValues.details) {
    errors.details = 'This field is required'
  }
  return errors
}

SubjectAccessForm = reduxForm({
  form: 'subject_access_form',
  validate,
  onSubmit: submit
})(SubjectAccessForm)

SubjectAccessForm = connect(
  (state) => ({
    initialValues: state.sar.sarUserForm
  }), { fetchUserSarForm, change }
)(SubjectAccessForm)
SubjectAccessForm = withRouter(SubjectAccessForm)
export default scriptLoader([
  'https://maps.googleapis.com/maps/api/js?key=AIzaSyB0vPXdXMi8ut3fOWv9LvyGZKRASY2PoI8&libraries=places'
])(SubjectAccessForm)
