import React from 'react'

import './Page500.scss'

const Page500 = () => (
  <div className="page500__page">
    Something went wrong.
  </div>
)

export default Page500