import React, { useEffect, useState} from 'react'
import { Progress } from 'antd';
export const ProgressLoadingFiles = ({tableFileListSelected, responseCheckAddDocuments, totalDocumentsFiles}) => {
  //const [running, setRunning] = useState(true)
  //const [progress, setProgress] = useState(0)
  const [seconds, setSeconds] = useState(0)
  //let interval = undefined
  // useEffect(() => {
  //   if (running) {
  //     interval = setInterval(() => {
  //       setProgress((prev) => prev + 10)
  //     }, 1000)
  //   } else {
  //     clearInterval(interval)
  //   }
  // }, [running])
  // useEffect(() => {
  //   if (progress === 50) {
  //     setRunning(false);
  //     clearInterval(interval);
  //   }
  // }, [progress])
  useEffect(() => {
    // let intervalSeconds = 0
    // if (tableFileListSelected.length <= 10) {
    //   intervalSeconds = 10
    // } else if (tableFileListSelected.length > 10 && tableFileListSelected.length < 30) {
    //   intervalSeconds = 5
    // } else {
    //   intervalSeconds = 1
    // }
    let checkIntervalSeconds = 0
    checkIntervalSeconds = (tableFileListSelected.length * 8)/10
    const loadingProgress = 100/checkIntervalSeconds
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds + loadingProgress)
    }, 1000)
    return () => clearInterval(interval)
  }, [tableFileListSelected.length])
  useEffect(() => {
    if (seconds === 60) {
      clearInterval(seconds)
    }
  }, [seconds])
  let percentProgress = 0
  if (Object.keys(responseCheckAddDocuments) && Object.keys(responseCheckAddDocuments).length > 0 && (responseCheckAddDocuments.nrOfFilesReady === responseCheckAddDocuments.totalFiles) && responseCheckAddDocuments.processFinished === false) {
    percentProgress = (responseCheckAddDocuments.nrOfFilesReady - 1)/responseCheckAddDocuments.totalFiles * 100
  } else {
    percentProgress = responseCheckAddDocuments.nrOfFilesReady/responseCheckAddDocuments.totalFiles * 100
  }
  return (
    <div className="content-progress-import-files">
      <Progress type="circle" percent={ percentProgress } format={() => ''} />
      <span className="text-import-files">{
        responseCheckAddDocuments.processFinished === true && responseCheckAddDocuments.nrOfFilesReady === -1 && responseCheckAddDocuments.totalFiles === -1 ?
            `Import files. Please wait...` :
        `Importing ${ responseCheckAddDocuments.totalFiles } files. Please wait.`
      }</span>
    </div>
  )
}
export default ProgressLoadingFiles
