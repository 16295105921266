import axios from 'axios'
import * as types from './actionTypes'
const isLocalhost = () => {
  return window.location.hostname === 'localhost'
}
const host = isLocalhost() ? 'http://localhost:8080' : ''

export const fetchUserSarForm = (sarHashedId) => async(dispatch) => {
  const response = await axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/sar/subject-access/${ sarHashedId }`
  })
  dispatch({ type: 'LOAD_SAR_FOR_USER', payload: response.data })
}

export const fetchUserSarFormFeedback = (sarHashedId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/feedback/${ sarHashedId }`
  })
}

export const fetchUserSarFormFeedbackWorkflow = (sarHashedId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/workflow-builder/feedback-workflow/${ sarHashedId }`
  })
}

export const change = (field, value) => async(dispatch) => {
  const data = {
    field,
    value
  }
  dispatch({ type: 'CHANGE_FORM', payload: data })
}

export function setAiAnnotationsStatus(data) {
  return { type: types.SET_AI_ANNOTATIONS_STATUS, payload: data }
}

export function setSarPathAiReady(data) {
  return { type: types.SET_SAR_PATH_AI_READY, payload: data }
}
export function setSarPageLimit(value) {
  return { type: types.SET_SAR_PAGE_LIMIT, payload: value }
}