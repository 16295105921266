import React from 'react'
import PropTypes from 'prop-types'
import '../YN.css'

const NotApplicable = ({ question }) => (
  <div>
    <div className="questionTypeYN-group">
      { question.options.map((option) => (
        <div
          className={ getClassName(option.title) }
          key={ option.id }>
          <input
            checked={ option.isSelected }
            disabled={ true }
            id={ option.id }
            name="answer"
            type="radio"
            value={ option.title } />
          <label
            className={ getLabelClassName(option.title) }
            htmlFor={ option.id }>
            { option.title }
          </label>
        </div>  
      ))
      }
    </div>
  </div>
)

const getClassName = (value) => {
  const classes = {
    'yes': 'yes',
    'no': 'no',
    'i don\'t know': 'idontknow'
  }
  return `questionTypeYN-${ classes[value.toLocaleLowerCase()] }`
}

const getLabelClassName = (title) => {
  const classes = {
    'yes': 'labelYes',
    'no': 'labelNo',
    'i don\'t know': 'labelIdontknow'
  }
  const value = classes[title.toLocaleLowerCase()]
  
  return `questionTypeYN-${ value }Disabled`
}

NotApplicable.propTypes = {
  question: PropTypes.object.isRequired
}

export default NotApplicable
