import React from 'react'

import './Terms.css'

class Terms extends React.Component {
  state = { terms1: [], terms2: [], terms3: [], terms4: [], terms5: [] }

  componentDidMount = () => {
    const { maxValue } = this.props
    const terms = this.props.terms.map((term) => {
      const normalized = term.value / maxValue
      const group = getGroup(normalized)
      return { name: term.name, group }
    })

    const terms1 = terms.filter((term) => term.group === 'terms1')
    const terms2 = terms.filter((term) => term.group === 'terms2')
    const terms3 = terms.filter((term) => term.group === 'terms3')
    const terms4 = terms.filter((term) => term.group === 'terms4')
    const terms5 = terms.filter((term) => term.group === 'terms5')

    this.setState({ terms1, terms2, terms3, terms4, terms5 })
  }

  render = () => {
    return (
      <div>
        <p className="terms__text">Top weighted</p>
        { this.state.terms1.length > 0 &&
          <ul className="terms__list">
            { this.state.terms1.map((term, index) => (
              <li
                className="terms__list-item"
                key={ `term-${ index }` }
                style={{ fontSize: '24px' }}>
                { term.name }
              </li>
            ))}
          </ul>
        }

        { this.state.terms2.length > 0 &&
          <ul className="terms__list">
            { this.state.terms2.map((term, index) => (
              <li
                className="terms__list-item"
                key={ `term-${ index }` }
                style={{ fontSize: '20px' }}>
                { term.name }
              </li>
            ))}
          </ul>
        }

        { this.state.terms3.length > 0 &&
          <ul className="terms__list">
            { this.state.terms3.map((term, index) => (
              <li
                className="terms__list-item"
                key={ `term-${ index }` }
                style={{ fontSize: '18px' }}>
                { term.name }
              </li>
            ))}
          </ul>
        }

        { this.state.terms4.length > 0 &&
          <ul className="terms__list">
            { this.state.terms4.map((term, index) => (
              <li
                className="terms__list-item"
                key={ `term-${ index }` }
                style={{ fontSize: '16px' }}>
                { term.name }
              </li>
            ))}
          </ul>
        }

        { this.state.terms5.length > 0 &&
          <ul className="terms__list">
            { this.state.terms5.map((term, index) => (
              <li
                className="terms__list-item"
                key={ `term-${ index }` }
                style={{ fontSize: '14px' }}>
                { term.name }
              </li>
            ))}
          </ul>
        }
      </div>
    )
  }
}

const getGroup = (value) => {
  if (value >= 0.8) return 'terms1'
  if (value >= 0.6) return 'terms2'
  if (value >= 0.4) return 'terms3'
  if (value >= 0.2) return 'terms4'
  return 'terms5'
}

export default Terms