import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { apiQuestionnaireChangeStatus, apiQuestionnaireChangeVersionStatus } from '../../../api/Questionnaire'
import { truncateString } from '../../../utils'
import Accordion from '../../Common/Accordion'
import Loading from '../../Common/Loading'
import Switcher from '../../Common/Switcher'
import ToggleAccordion from '../../Common/ToggleAccordion'
import './QuestionnaireTemplate.scss'
import { Tooltip } from 'antd'

class QuestionnaireTemplate extends React.Component {
  constructor() {
    super()

    this.state = {
      isLoading: false,
      showingOldest: false,
      versionErrors: {},
      versionLoading: {}
    }

    this.updateActive = this.updateActive.bind(this)
    this.updateActiveForVersion = this.updateActiveForVersion.bind(this)
  }

  toggleOldest() {
    this.setState({ showingOldest: !this.state.showingOldest })
  }

  templateType(template) {
    switch (template.type) {
      case 'DATA_CONTROLLER_DPIA':
        return 'Data Controller'
      case 'DATA_PROCESSOR_DPIA':
        return 'Data Processor'
      default:
        return 'Pia'
    }
  }

  templateHeader(template) {
    const { accordion, toggleAccordion, index } = this.props
    const latestVersion = this.latestActiveVersion(template) || this.latestVersion(template)
    const lastPublished = latestVersion.publicationDate ? moment(latestVersion.publicationDate).format('MMMM Do, YYYY') : 'Draft'
    const templateTypePreffix = template.type === 'PIA' ? template.type : 'DPIA'
    const type = this.templateType(template)

    return (
      <div
        className={ accordion[template.id] ? 'questionnaireTemplate__itemHeaderOpened' : 'questionnaireTemplate__itemHeader'}
        onClick={ toggleAccordion.bind(this, template.id) }
      >
        <div className="questionnaireTemplate__infoContainer">
          <div className="questionnaireTemplate__name">
            { `${ templateTypePreffix } - ${ truncateString(template.title, 55) }` }
            <span className="questionnaireTemplate__questionGroup">{ type }</span>
          </div>
          {
            !template.isGlobal &&
            <div className="questionnaireTemplate__currentVersionWrapper">
              <label>Last published</label>
              <div className="questionnaireTemplate__versionDetailsInfo">
                { latestVersion.isEnabled &&
                  <div className="questionnaireTemplate__activeFlag"></div>
                }
                <div className="questionnaireTemplate__versionDetails">
                  <span>{ `Version ${ latestVersion.version }` }</span>
                  -
                  <span>
                    <strong>{ lastPublished }</strong>
                  </span>
                </div>
              </div>
            </div>
          }
        </div>
        <ToggleAccordion
          oppened={ accordion[template.id] }
          index={ template.id }
          toggleAccordion={ toggleAccordion.bind(this, accordion, index) }
        />
      </div>
    )
  }

  templateContent(template) {
    const {
      isEnabled,
      isGlobal,
      description
    } = template

    const fallbackDescription = description || 'No description'

    return (
      <React.Fragment>
        <div className="questionnaireTemplate__description">
          <p>{ fallbackDescription }</p>

          {
            !isGlobal && !this.state.isLoading &&
            <Switcher active={ isEnabled } updateActive={ this.updateActive }/>
          }

          {
            this.state.isLoading &&
            <Loading />
          }
        </div>

        <div className="questionnaireTemplate__versionsWrapper">
          { this.renderVersions(template) }
          { this.renderLoadMoreVersionsButton(template) }
        </div>
      </React.Fragment>
    )
  }

  updateActive() {
    const { id, isEnabled } = this.props.template
    const action = isEnabled ? 'DISABLE' : 'ENABLE'
    this.setState({ isLoading: true })
    apiQuestionnaireChangeStatus(id, action)
      .then((response) => {
        this.props.fetchQuestionnaires()
        this.setState({ isLoading: false })
      })
      .catch(() => {
        this.setState({ isLoading: false })
      })
  }

  updateActiveForVersion(version) {
    const { id, belongsToQuestionnaire, isEnabled } = version
    const action = isEnabled ? 'INACTIVATE' : 'ACTIVATE'
    this.setState({ versionLoading: { [id]: true }, versionErrors: { [id]: '' } })

    apiQuestionnaireChangeVersionStatus(belongsToQuestionnaire, id, action)
      .then(() => {
        this.props.fetchQuestionnaires()
        this.setState({ versionLoading: { [id]: false } })
      })
      .catch(() => {
        this.setState({
          versionErrors: { [id]: 'This action can be placed only on published state' },
          versionLoading: { [id]: false }
        })
      })
  }

  latestActiveVersion(template) {
    const nonDrafts = template.versions.filter((version) => version.state !== 'DRAFT')
    if (nonDrafts.length === 0) return
    return nonDrafts.filter((version) => version.isEnabled).sort((versionA, versionB) => versionB.creationDate - versionA.creationDate)[0]
  }

  latestVersion(template) {
    return template.versions.sort((versionA, versionB) => versionB.id - versionA.id)[0]
  }

  renderLoadMoreVersionsButton(template) {
    if (template.versions.length < 5) return

    const { showingOldest } = this.state
    const totalRemaining = template.versions.length - 4
    const versionsLabel = totalRemaining <= 1 ? 'version' : 'versions'

    return (
      <div className="questionnaireTemplate__moreVersionWrapper" onClick={ this.toggleOldest.bind(this) }>
        {
          !showingOldest &&
          <React.Fragment>
            <div className="questionnaireTemplate__moreVersionIconWrapper">
              <span className="iconDownCaret"></span>
            </div>
            <span>{ `Show ${ totalRemaining } older ${ versionsLabel }` }</span>
          </React.Fragment>
        }

        {
          showingOldest &&
          <React.Fragment>
            <span className="iconDownCaret"></span>
            <span>{ `Hide ${ totalRemaining } older ${ versionsLabel }` }</span>
          </React.Fragment>
        }
      </div>
    )
  }

  questionsSizeLabel(questionsSize) {
    if (questionsSize <= 1) return `${ questionsSize } question`
    return `${ questionsSize } questions`
  }

  renderVersions(template) {
    let versions = template.versions.sort((versionA, versionB) => versionB.creationDate - versionA.creationDate)
    const { showingOldest, versionErrors, versionLoading } = this.state

    if (!showingOldest) {
      versions = versions.slice(0, 4)
    }

    return (
      versions.map((version) => (
        <div key={`template-version-${ version.id }`} className="questionnaireTemplate__versionWrapper">
          <div className="questionnaireTemplate__version">
            <div className={ version.isEnabled ? 'questionnaireTemplate__info' : 'questionnaireTemplate__infoInactive' }>
              <div className="questionnaireTemplate__infoItem">
                {
                  version.isEnabled &&
                  <div className="questionnaireTemplate__activeFlag" />
                }
                { `Version ${ version.version }` }
              </div>

              <div className="questionnaireTemplate__infoItem">
                { moment(version.creationDate).format('MMMM Do, YYYY @ H:mm') }
              </div>
              <div className="questionnaireTemplate__infoItem">
                { this.questionsSizeLabel(version.numQuestions) }
              </div>
              {
                !template.isGlobal && !versionLoading[version.id] &&
                <div className="questionnaireTemplate__infoItem">
                  <Switcher active={ version.isEnabled } updateActive={ () => { this.updateActiveForVersion(version) } }/>
                </div>
              }

              {
                versionLoading[version.id] &&
                <div className="questionnaireTemplate__loadingItem">
                  <Loading />
                </div>
              }
            </div>

            {
              !template.isGlobal &&
              <div className="questionnaireTemplate__actions">
                <Tooltip title="Edit questionnaire">
                  <Link to={ `/dpia/questionnaires/${ version.belongsToQuestionnaire }/versions/${ version.id }/edit` }>
                    <span className="iconEdit iconPreviewActive" />
                  </Link>
                </Tooltip>
                <div>
                  <Tooltip title="View questionnaire">
                    <Link to={ `/dpia/questionnaires/${ version.belongsToQuestionnaire }/versions/${ version.id }/` }>
                      <span className="iconPreview" />
                    </Link>
                  </Tooltip>
                </div>
              </div>
            }

            {
              template.isGlobal &&
              <Tooltip title="View questionnaire">
                <div className="questionnaireTemplate__actions">
                  <Link to={ `/dpia/questionnaires/${ version.belongsToQuestionnaire }/versions/${ version.id }/` }>
                    <span className="iconPreview" />
                  </Link>
                </div>
              </Tooltip>
            }
          </div>

          {
            versionErrors[version.id] &&
            <span className="questionnaireTemplate__versionError">{ versionErrors[version.id] }</span>
          }
        </div>
      ))
    )
  }

  render() {
    const { accordion, template } = this.props

    return (
      <div className="questionnaireDashboard__accordionWrapper">
        <Accordion
          opened={ accordion[template.id] }
          key={ `template-${ template.id }` }
          header={ this.templateHeader(template) }
          content={ this.templateContent(template) }

        />
      </div>
    )
  }
}

QuestionnaireTemplate.propTypes = {
  accordion: PropTypes.object.isRequired,
  toggleAccordion: PropTypes.func.isRequired,
  fetchQuestionnaires: PropTypes.func.isRequired,
  template: PropTypes.object.isRequired
}

export default QuestionnaireTemplate
