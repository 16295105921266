import React from 'react'
import {  Navigate, Outlet } from 'react-router-dom'
import { connect } from 'react-redux'
import CustomNotificationManager from '../components/Common/CustomNotificationManager'

const DpiaPrivateRouter = ( props ) => {
  if (props.isLoggedIn) {
    const found = props.dpiaVisible
    if (found) {
      return <Outlet {...props}/>
    }
    // CustomNotificationManager.info('Access restricted', 'Unauthorized')
    return <Navigate to="/smart-box/management"/>
  }
  return <Navigate to="/osp/login"/>
}


const mapStateToProps = (state) => ({
  isLoggedIn: state.userReducer.isLogged,
  dpiaVisible: state.applications.dpiaVisible
})

export default connect(mapStateToProps, {})(DpiaPrivateRouter)