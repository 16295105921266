import { regexBuilder } from '../../../../../utils'
import AddNewQuestionBuilder from './AddQuestionBuilder'
import { toggleAllAccordion, updateQuestionsOrder } from './Utils'

const selectAllQuestions = (questions, value) => {
  return questions.map((question) => (
    { ...question, selected: value }
  ))
}

const questionIdsToDelete = (questions) => {
  return questions.filter((question) => (question.selected)).map((question) => (
    question.ref
  ))
}

const addDefaultQuestions = (questions) => {
  questions = AddNewQuestionBuilder({ questions, currentOrder: 0 })
  questions = AddNewQuestionBuilder({ questions, currentOrder: 1 })
  return questions
}

export const filteredQuestions = (filterAttributes) => {
  const { 
    deletedQuestions,
    allQuestions,
    byTerm,
    byType,
    byRisk,
    select,
    action,
    expand
  } = filterAttributes

  const newState = { 
    questions: allQuestions,
    allQuestions: allQuestions,
    deletedQuestions: deletedQuestions
  }

  if (byType !== '') {
    newState.questions = newState.questions.filter((question) => (
      question.type === byType
    ))
  }

  if (byTerm !== '') {
    const termRE = regexBuilder(byTerm)
    newState.questions = newState.questions.filter((question) => (
      question.title.match(termRE)
    ))
  }

  if (byRisk !== '') {
    newState.questions = newState.questions.filter((question) => {
      const risks = question.riskIndicators.filter((risk) => risk.selected)
      return risks.find((risk) => (risk.value === byRisk))
    })
  }

  if (select) {
    newState.questions = selectAllQuestions(newState.questions, select === 'all')
    const questionIds = newState.questions.map((question) => question.id)
    newState.allQuestions = newState.allQuestions.map((question) => {
      if (questionIds.indexOf(question.id) !== -1) {
        return { ...question, selected: select === 'all' }
      }
      return question
    })
  }

  if (action === 'deleteSelected') {
    newState.deletedQuestions = [
      ...newState.deletedQuestions,
      ...questionIdsToDelete(newState.questions)
    ]

    newState.questions = updateQuestionsOrder(newState.questions.filter((question) => !question.selected))
    newState.allQuestions = updateQuestionsOrder(newState.allQuestions.filter((question) => !question.selected))

    if (newState.allQuestions.length === 0) {
      newState.questions = addDefaultQuestions(newState.questions)
      newState.allQuestions = newState.questions
    }
  }

  newState.questions = newState.questions.filter((question) => newState.deletedQuestions.indexOf(question.ref) === -1)
  newState.allQuestions = newState.allQuestions.filter((question) => newState.deletedQuestions.indexOf(question.ref) === -1)
  newState.questionsAccordion = toggleAllAccordion(newState.questions, expand)

  return newState
}