import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'

import AlertItem from './AlertItem'

import * as manageGroupsActions from '../../../actions/manageGroupsActions'

import './AlertList.css'

class AlertList extends React.Component {
  componentDidMount = () => {
    // const accessToken = localStorage.getItem('accessToken')
    const tokenData = JSON.parse(localStorage.getItem('tokenData'))?.tokenId

    this.props.fetchList(tokenData)
  }

  render = () => {
    return (
      <div id="alert-list" className="alert-alertList-wrapper">
        { this.props.alerts.map((alert, index) => (
          <AlertItem
            accordion={ this.props.accordion }
            alert={ alert }
            key={ alert.id }
            index={ index }
            showModal={ this.props.showModal }
            toggleAccordion={ this.props.toggle }
          />
        )) }
      </div>
    )
  } 
}

AlertList.propTypes = {
  accordion: PropTypes.object.isRequired,
  alerts: PropTypes.array.isRequired,
  fetchList: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired
}

const dispatchMap = (dispatch) => ({
  fetchList: (token) => dispatch(manageGroupsActions.fetchList(token))
})

export default compose(
  connect(null, dispatchMap)
)(AlertList)