import { Link } from 'react-router-dom'
import React from 'react'
import { Icon } from 'antd'

export const ManagerUsersFilters = ({ findText, currentUserIsSuperAdmin, updateFilterSearch, updateValue, clearFilterSearch }) => {
  return (
    <div className="mu_filter__header">
      <div className="questionnaireFilter__wrapper">
        <div className="questionnaireFilter__button">
          <label>Find</label>
          <input
            type="text"
            value={ findText }
            className={ (findText === '' || findText === null) ? 'questionnaire-filter__input-find questionnaire-filter__input-find-update' : 'questionnaire-filter__input-find-on-focus' }
            onChange={ (e) => updateFilterSearch(e, 'filterInput') }
            onBlur={(e) => updateFilterSearch(e, 'filterInput') }
          />
          { findText && findText.length > 0 &&
              <Icon onClick={ (e) => clearFilterSearch() } className="questionnaireFilter__button__clear_search" type="close-circle"/>
          }
        </div>
        <div className="questionnaireFilter__select questionnaireFilter__select-update">
          <label>Level</label>
          <select onChange={ (e) => updateValue(e, 'userType')}>
            <option value="null">All</option>
            <option value="user">All Users</option>
            <option value="admin">All admins</option>
          </select>
        </div>
        <div className="questionnaireFilter__select questionnaireFilter__select-update">
          <label>Display</label>
          <select onChange={ (e) => updateValue(e, 'userAccountActivated')}>
            <option value="">All Users</option>
            <option value="active">Active account(s)</option>
            <option value="inactive">Inactive account(s)</option>
          </select>
        </div>
        <div className="questionnaireFilter__select questionnaireFilter__select-update">
          <label>Sort</label>
          <select onChange={ (e) => updateValue(e, 'filterSort') }>
            <option value="asc">Email (ASC)</option>
            <option value="desc">Email (DESC)</option>
            <option value="last_login_asc">Last Login (ASC)</option>
            <option value="last_login_desc">Last Login (DESC)</option>
          </select>
        </div>
      </div>
      {currentUserIsSuperAdmin ? (
        <div className="questionnaireDashboard__newButtonWrapper">
          <Link to={ '/osp/manage/adduser/'} >
            <button className='button-green button-update'>Add New User(s)</button>
          </Link>
        </div>
      ) : (<div className="questionnaireDashboard__newButtonWrapper" />)
      }
    </div>
  )
}