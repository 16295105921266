import React from 'react'
import './Switcher.scss'

const Switcher = ({ active, updateActive }) => (
  <div
    className={ active ? 'switcher__sliderChecked' : 'switcher__slider' }
    onClick={ updateActive }>
    <span
      className={ active ? 'switcher__sliderButtonChecked' : 'switcher__sliderButton' }></span>
  </div>
)

export default Switcher