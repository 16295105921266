import React from 'react'
import { connect } from 'react-redux'
import AddNewInviteTenant from './AddNewInviteTenant'
import { apiViewPlan } from '../../../api/Plan'
import './ClientManagement.scss'

class ClientSendInviteTenant extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loadingSuper: false,
      loadingGravicus: false,
      modalVisible: false,
      emailAddress: '',
      homeApplication: '',
      saName: '',
      saEmail: '',
      saStatus: '',
      aName: '',
      aEmail: '',
      aStatus: '',
      errors: {},
      newClientId: '',
      newClientDomain: ''
    }
  }

  componentDidMount() {
    apiViewPlan().then((res) => {
      this.setState({
        plans: res.data
      })
    })
  }

  render() {
    const { plans } = this.state
    return (
      <div className="tracker-page create-client-page">
        <div className="tracker-box">
          <AddNewInviteTenant action="add" plans={plans} />
        </div>
      </div>
    )
  }
}

const stateMap = (state) => ({
  client: state.subscriptionReducer.subscription.subscription.client,
  user: state.userReducer.user
})

export default connect(stateMap)(ClientSendInviteTenant)