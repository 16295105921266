import PropTypes from 'prop-types'
import React from 'react'
import ImageUploader from 'react-images-upload'
import { connect } from 'react-redux'
import { Link, Navigate } from 'react-router-dom'
import { compose } from 'redux'
import swal from 'sweetalert2'
import { apiFetchQuestionnaire, apiFetchQuestionnaireVersion, apiQuestionnaireCreate, apiQuestionnaireCreateVersion, apiQuestionnairePublish, apiQuestionnaireUpdate } from '../../../../api/Questionnaire'
import { apiTeamCreateTeam } from '../../../../api/Team'
import '../../../../style/common.scss'
import { accountIsPaid, cloneObject, scrollToElement, userIsSuperAdmin } from '../../../../utils'
import Button from '../../../Common/Button'
import Page403 from '../../../Common/ErrorPage/Page403'
import InputValidator from '../../../Common/InputValidator'
import Loading from '../../../Common/Loading'
import { actionAttributes } from '../../../Dpia/Questionnaires/Helpers/ActionsAttributes'
import { changesChecker } from '../../../Dpia/Questionnaires/Helpers/ChangesChecker'
import { denormalizeFromAPI, denormalizeQuestionnaireFromAPI, denormalizeToAPI } from '../../../Dpia/Questionnaires/Helpers/Denormalizer'
import { validate } from '../../../Dpia/Questionnaires/Helpers/Validator'
import { filteredQuestions } from '../../../Dpia/Questionnaires/Questions/Helpers/Filter'
import { toggleInvalidAccordion } from '../../../Dpia/Questionnaires/Questions/Helpers/Utils'
import './NewTeamForm.scss'
import { buildImageErrorNotification } from '../../../../utils/ImageErrorsBuilder'
import withRouter from '../../../../private-routers/withRouter'

class NewTeamForm extends React.Component {
  constructor() {
    super()
    this.state = this.defaultState
    this.state.pictureImg = []
    this.state.name = ''
    this.state.unique = ''
    this.state.description = ''
    this.state.pictures = []
    this.state.previewImage = 'https://s.gravatar.com/avatar/53dc26bb6b2652bdf9150a3994a9ef2c'
    this.state.isSaveLoading = false
    this.state.imageUpload = null
    this.onDrop = this.onDrop.bind(this)
    this.updateQuestionsList = this.updateQuestionsList.bind(this)
    this.updateQuestion = this.updateQuestion.bind(this)
    this.filterQuestions = this.filterQuestions.bind(this)
    this.toggleAccordion = this.toggleAccordion.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmitForm = this.handleSubmitForm.bind(this)
    this.imageUpload = React.createRef()
  }

  handleChange(event, field) {
    if (field === 'field_name') {
      this.setState({ name: event.target.value })
    } else if (field === 'field_unique') {
      this.setState({ unique: event.target.value })
    } else {
      this.setState({ description: event.target.value })
    }
  }

  handleSubmitForm(event) {
    event.preventDefault()
    const parameters = {
      name: this.state.name,
      uniqueIdentifier: this.state.unique,
      description: this.state.description,
      multipartFile: this.state.pictures.length > 0 ? this.state.pictures[0] : null
    }
    this.setState({
      isSaveLoading: true
    })
    apiTeamCreateTeam(parameters)
      .then(() => {
        this.props.navigate('/osp/manage/teams')
      }).catch((e) => {
        this.setState({
          isSaveLoading: false
        })
      })
  }

  onDrop(pictures, done) {
    if (this.imageUpload &&
        (pictures.length === 0 ||
          this.imageUpload.state.notAcceptedFileSize?.length ||
          this.imageUpload.state.notAcceptedFileType?.length
        )) {
      // buildImageErrorNotification(this.imageUpload)

      this.imageUpload.setState({
        notAcceptedFileType: [],
        notAcceptedFileSize: []
      })
    } else {
      this.setState({
        pictures: this.state.pictureImg.concat(pictures),
        previewImage: done
      })
      if (done.length > 1) {
        this.setState({
          previewImage: done[done.length - 1]
        })
      }
    }
  }

  get methods() {
    return {
      createNewDraftQuestionnaire: this.createNewDraftQuestionnaire.bind(this),
      createNewQuestionnaireAndPublish: this.createNewQuestionnaireAndPublish.bind(this),
      createNewVersionAndPublish: this.createNewVersionAndPublish.bind(this),
      updateDraft: this.updateDraft.bind(this),
      publish: this.publish.bind(this)
    }
  }

  get defaultState() {
    return {
      isLoading: false,
      isSaving: false,
      originalState: {},
      questionsAccordion: {},
      errors: {},
      isEditMode: false,
      description: '',
      industry: '',
      deletedQuestions: [],
      questions: [],
      allQuestions: [],
      title: '',
      state: '',
      version: '',
      redirectToDashboard: false,
      type: 'DATA_CONTROLLER_DPIA',
      imageUpload: null
    }
  }

  get urlParams() {
    return {
      questionnaireId: this.props.params.questionnaireId,
      versionId: this.props.params.versionId
    }
  }

  get disabledButton() {
    return this.state.state === 'PUBLISHED' || this.state.isSaving
  }

  componentDidMount() {
    window.scrollTo(0, 0)

    const { questionnaireId, versionId } = this.urlParams

    if (questionnaireId) {
      this.setState({ isLoading: true, isEditMode: true })
      this.fetchQuestionnaire(questionnaireId)
      this.fetchQuestionnaireVersion(questionnaireId, versionId)
    }
  }

  componentWillUnmount() {
    this.setState(this.defaultState)
  }

  fetchQuestionnaire(questionnaireId) {
    apiFetchQuestionnaire(questionnaireId)
      .then((response) => {
        const questionnaire = denormalizeQuestionnaireFromAPI(response.data)
        const values = { ...this.state, ...questionnaire }
        const { originalState, ...initialValues } = values

        this.setState({ ...this.state, ...questionnaire, originalState: cloneObject(initialValues) })
      })
  }

  fetchQuestionnaireVersion(questionnaireId, versionId) {
    apiFetchQuestionnaireVersion(questionnaireId, versionId)
      .then((response) => {
        const questionnaire = denormalizeFromAPI(response.data)
        const values = {
          ...this.state,
          questions: questionnaire.questions,
          allQuestions: questionnaire.questions,
          state: questionnaire.state,
          version: questionnaire.version
        }
        const { originalState, ...initialValues } = values

        this.setState({ ...values, originalState: cloneObject(initialValues), isLoading: false })
      })
  }

  updateValue(event, field) {
    this.setState({
      [field]: event.target.value
    })
  }

  alertAttributes(data, questionnaire, action) {
    let hasChanges = false
    // Needs to check these changes just if isEditMode is true
    if (this.state.isEditMode) {
      const originalData = denormalizeToAPI(this.state.originalState)
      hasChanges = changesChecker(data, originalData)
    }
    return actionAttributes(questionnaire, hasChanges, action, this.methods)
  }

  buttonClicked(event, action) {
    event.preventDefault()

    // check if is a publish state trying to save a draft
    if (this.state.state === 'PUBLISHED' && action === 'draft') return

    const questionnaire = validate(this.state)
    this.setState({ ...questionnaire }, () => {
      if (questionnaire.isValid) {
        const data = denormalizeToAPI(this.state)
        const nextAction = this.alertAttributes(data, questionnaire, action)

        swal.fire({
          text: nextAction.title,
          type: 'warning',
          showCancelButton: nextAction.action !== 'noAction',
          confirmButtonColor: '#3DD8DB',
          cancelButtonColor: '#D33D33',
          confirmButtonText: nextAction.action !== 'noAction' ? 'Yes!' : 'Ok'
        }).then((result) => {
          if (result.value && nextAction.method) {
            this.setState({ isSaving: true })
            nextAction.method(data)
          }
        })
      } else {
        const questionsAccordion = toggleInvalidAccordion(this.state.questions)
        this.setState({ questionsAccordion, isSaving: false }, () => {
          scrollToElement('.errorMessages__list, .inputValidator__invalidWrapper')
        })
      }
    })
  }

  createNewQuestionnaireAndPublish(data) {
    apiQuestionnaireCreate(data)
      .then((response) => {
        const questionnaireId = response.data[0]
        const versionId = response.data[1]
        this.publish(true, questionnaireId, versionId)
      })
      .catch(() => {
        this.setState({ isSaving: false })
      })

  }

  createNewDraftQuestionnaire(data) {
    apiQuestionnaireCreate(data)
      .then(() => {
        this.setState({ redirectToDashboard: true, isSaving: false })
      })
      .catch(() => {
        this.setState({ isSaving: false })
      })
  }

  updateDraft(data) {
    const { questionnaireId, versionId } = this.urlParams

    apiQuestionnaireUpdate(data, questionnaireId, versionId)
      .then(() => {
        this.setState({ redirectToDashboard: true, isSaving: false })
      })
      .catch(() => {
        this.setState({ isSaving: false })
      })
  }

  createNewVersionAndPublish() {
    const questionnaireId = this.props.params.questionnaireId
    const data = denormalizeToAPI(this.state)
    Object.assign(data, { parentVersion: this.state.version })

    apiQuestionnaireCreateVersion(data, questionnaireId)
      .then((response) => {
        const versionId = response.data
        this.publish(true, questionnaireId, versionId)
      })
      .catch((e) => {
        if (e?.response?.data === 'QUESTIONNAIRE_VERSIONS_NO_LIMIT_REACHED') {
          swal.fire({
            text: 'You cannot publish more than 10 versions',
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3DD8DB',
            confirmButtonText: 'Ok'
          })
        }else{
          console.log(e)
        }
        this.setState({ isSaving: false })
      })
  }

  publish(redirectToDashboard, questionnaireId = this.props.params.questionnaireId, versionId=this.props.params.versionId) {
    const data = denormalizeToAPI(this.state)

    apiQuestionnairePublish(data, questionnaireId, versionId)
      .then(() => {
        this.setState({ redirectToDashboard, isSaving: false })
      })
      .catch((e) => {
        this.setState({ isSaving: false })
      })
  }

  updateQuestionsList({ questions, deletedQuestions = this.state.deletedQuestions }) {
    this.setState({
      questions,
      deletedQuestions,
      allQuestions: questions
    })
  }

  updateQuestion(index, question, callback) {
    const newQuestions = [...this.state.questions]
    newQuestions[index] = question
    this.setState({
      questions: newQuestions,
      allQuestions: newQuestions
    }, callback)
  }

  filterQuestions(filterAttributes) {
    const params = {
      ...filterAttributes,
      allQuestions: this.state.allQuestions,
      deletedQuestions: this.state.deletedQuestions
    }

    this.setState({
      ...filteredQuestions(params)
    })
  }

  formattedTitle(isEditMode, title, state, version) {
    if (!isEditMode) return 'New Team'
    if (state !== 'DRAFT') return `V${ version } - ${ title }`
    return `(Draft) V${ version } - ${ title }`
  }

  toggleAccordion(index) {
    this.setState({
      questionsAccordion: {
        ...this.state.questionsAccordion,
        [index]: !this.state.questionsAccordion[index]
      }
    })
  }

  render() {
    const currentUserIsSuperAdmin = userIsSuperAdmin(this.props.user)
    const currentAccountIsPaid = accountIsPaid(this.props.subscription)
    const {
      description,
      errors,
      isEditMode,
      state,
      title,
      version,
      redirectToDashboard,
      isLoading,
      isSaving,
      isSaveLoading
    } = this.state

    if (!currentUserIsSuperAdmin && !currentAccountIsPaid) {
      return (
        <div className="newQuestionnaire__page">
          <Page403 />
        </div>
      )
    }

    return (
      <div className="newQuestionnaire__page">
        { redirectToDashboard &&
          <Navigate to="/dpia/questionnaires" />
        }

        { isLoading &&
          <div className="newQuestionnaire__loading">
            <Loading />
          </div>
        }

        { !isLoading &&
          <React.Fragment>
            <div className="newQuestionnaire__section">
              <header className="newQuestionnaire__header m-3">
                <h1 className="newQuestionnaire__title">
                  { this.formattedTitle(isEditMode, title, state, version) }
                </h1>
                <div className="newQuestionnaire__submitButtons">
                  { isSaving &&
                    <div className="newQuestionnaire__loading">
                      <Loading />
                    </div>
                  }

                  { isSaveLoading &&
                    <div className={ this.disabledButton ? 'newQuestionnaire__disabledButton' : '' } id='firstSubmitBtn'>
                      <Loading />
                    </div>
                  }
                  { !isSaveLoading &&
                    <div className={ this.disabledButton ? 'newQuestionnaire__disabledButton' : '' } id='firstSubmitBtn'>
                      <Button
                        theme={ this.disabledButton ? 'gray' : 'green' }
                        onClick={ this.handleSubmitForm }>
                        Save Changes
                      </Button>
                    </div>
                  }
                </div>
              </header>

              <form className="newQuestionnaire__form">
                <div className="newQuestionnaire__formGroup">
                  <div className="float-left m-3">
                    <label className="styleTitleInput" htmlFor="field_name">Display name</label>
                    <input
                      name="name"
                      id="field_name"
                      className="inputDisplayName"
                      onChange={ (e) => this.handleChange(e, 'field_name') }
                    />
                  </div>
                  <div className="float-left m-3">
                    <label className="styleTitleInput" htmlFor="field_unique" >Unique identifier</label>
                    <InputValidator errors={ errors } field="title">
                      <input
                        id = 'field_unique'
                        name = 'unique'
                        className="inputDisplayUnique"
                        onChange={ (e) => this.handleChange(e, 'field_unique') }
                      />
                    </InputValidator>
                  </div>
                </div>
                <div className="newQuestionnaire__formGroup descriptionStyle m-3">
                  <label className="styleTitleInput" htmlFor="field_description">Description</label>
                  <input
                    name = 'description'
                    id = "field_description"
                    className="inputDisplaydescription"
                    value={ description }
                    onChange={ (e) => this.handleChange(e, 'field_description') }
                  />
                </div>
                <div className="newQuestionnaire__formGroup styleUploadImage ">
                  <div className="float-left m-3 ">
                    <img id="preview_profile" className="teamIconImage " src={ this.state.previewImage } alt="" />
                  </div>
                  <div className="float-left m-3">
                    <h4 className="upload-title"><span className="styleSpan1">Upload a logo or icon</span><span className="styleSpan2">(best result are square images and 250x250px)</span></h4>
                    <ImageUploader
                      className="fileUpload"
                      withIcon={false}
                      label=''
                      labelStyle="styleLabelImageUpload"
                      buttonText='BROWSE...'
                      buttonClassName="buttonClassChange"
                      onChange={this.onDrop}
                      imgExtension={['.jpg', '.png', '.jpeg']}
                      maxFileSize={3145728}
                      withPreview={false}
                      imgPreview={false}
                      images="Array"
                      errorStyle={{ color: 'red' }}
                      ref={(ref) => this.imageUpload = ref}
                    />
                  </div>
                </div>

                <div className={ this.disabledButton ? 'newQuestionnaire__disabledButton' : ''}>
                  <footer className="userSettings-footer">
                    <div>
                      <Link className="userSettings-footerLink" to="/osp/manage/teams">Discard changes</Link>
                    </div>
                    <div className="newQuestionnaire__submitButtons">
                      { isSaving &&
                        <div className="newQuestionnaire__loading">
                          <Loading />
                        </div>
                      }
                      { isSaveLoading &&
                        <div className={ this.disabledButton ? 'newQuestionnaire__disabledButton' : ''}>
                          <Loading />
                        </div>
                      }
                      { !isSaveLoading &&
                        <div className={ this.disabledButton ? 'newQuestionnaire__disabledButton' : ''}>
                          <Button
                            className= 'button-second'
                            theme='second'
                            onClick={ this.handleSubmitForm }>
                             Save Changes
                          </Button>
                        </div>
                      }
                    </div>
                  </footer>
                </div>

              </form>
            </div>
          </React.Fragment>
        }
      </div>
    )
  }
}
NewTeamForm = withRouter(NewTeamForm)
const stateMap = (state) => ({
  subscription: state.subscriptionReducer.subscription,
  user: state.userReducer.user
})

NewTeamForm.propTypes = {
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  subscription: PropTypes.object.isRequired
}

export default compose(
  connect(stateMap, null),
)(NewTeamForm)
