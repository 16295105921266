import { Modal } from 'antd'
import './AssetRegisterComponents.scss'

const { confirm } = Modal
/**
 *
 * @param props
 * props is object containing configuration for confirm
 * {
 *   confirmAction: () => "confirm callback",
      title: "Title",
      content: "Content",
      icon: "Icon tag",
      confirmButtonText: "Confirm Button Text",
      cancelButtonText:  "Reject/Cancel Button Text",
      mask:  "true if mask is visible, default true"
      maskClosable: "true if mask is closable, default true"
      closeHasLogic: "true if cancel button will implement logic, no default value (undefined)"
      className: "Class name of confirm modal",
      centered: "true value will align modal centered"
 * }
 */
export const customConfirm = (props) => {
  confirm({
    title: props.title,
    content: props.content,
    okText: props.confirmButtonText,
    cancelText: props.cancelButtonText,
    className: props.className,
    mask: props.mask,
    maskClosable: props.maskClosable,
    centered: props.centered,
    cancelButtonProps: {
      className: props.cancelClassName
    },
    okButtonProps: {
      className: props.confirmClassName
    },
    okType: props.okType,
    onOk() {
      props.confirmAction()
    },
    onCancel() {
      if (props.closeHaveLogic) {
        props.rejectAction()
      }
    },
  })
}