import PropTypes from 'prop-types'
import React from 'react'
//import LogoGravicus from '../../assets/Gravicus_Smartbox_Logo.svg'
import new_Smartbox_Logo from '../../assets/SB Logo Login 290x80.svg'
import './FormHeader.scss'

const FormHeader = ({ title, subtitle, companyLogo }) => (
  <div className="formHeader-header newFormHeader-header">
  
    <div className="formHeader-welcome newFormHeader-welcome">
      <span className="formHeader-title">{ subtitle }</span>
      <strong className="formHeader-subtitle">{ title }</strong>
    </div>

    <div className="formHeader-logo newFormHeader-logo">
      <img className="logo-osprey-login" src={companyLogo ? companyLogo : new_Smartbox_Logo } alt="Osprey" />
    </div>
  </div>
)

FormHeader.propTypes = {
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default FormHeader