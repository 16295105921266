import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import './FormEmployeeWorkflow.scss'
import NameTemplateForm from './ComponentsFormTemplate/NameTemplateForm'
import AddressTemplateForm from './ComponentsFormTemplate/AddressTemplateForm'
import EmailTemplateForm from './ComponentsFormTemplate/EmailTemplateForm'
import TelephoneTemplateForm from './ComponentsFormTemplate/TelephoneTemplateForm'
import ShortTextTemplateForm from "./ComponentsFormTemplate/ShortTextTemplateForm";
import LongTextTemplateForm from "./ComponentsFormTemplate/LongTextTemplateForm";
import DropdownTemplateForm from "./ComponentsFormTemplate/DropdownTemplateForm";
import SingleChoiceTemplateForm from './ComponentsFormTemplate/SingleChoiceTemplateForm';
import MultipleChoiceTemplateForm from "./ComponentsFormTemplate/MultipleChoiceTemplateForm";
import FileUploadTemplateForm from  './ComponentsFormTemplate/FileUploadTemplateForm';
import SignatureTemplateForm from "./ComponentsFormTemplate/SignatureTemplateForm";
import HeaderTemplateForm from "./ComponentsFormTemplate/HeaderTemplateForm";
import {Input, Select, Steps} from "antd";
import {apiGetFormTicketWorkflow, apiGetTicketFormComponentFromWorkflow} from "../../../../../api/SmartBox";
import CustomNotificationManager from "../../../../Common/CustomNotificationManager";
const { Option } = Select;
export class FormEmployeeWorkflow extends Component {

    state = {
        formerEmployee: {
            firstNameValue: null,
            lastNameValue: null,
            streetAddressValue: null,
            street2AddressValue: null,
            cityAddressValue: null,
            stateAddressValue: null,
            postalCodeValue: null,
            emailAddressValue: null,
            phoneNumber: null,
            shortTextValue: null,
            longTextValue: null,
            dropdownValue: null,
            singleChoiceValue: null,
            formName: null
        },
        statusFormWorkflow: 'create',
        listComponentsTemplateForm: [
            // {id: 'fullName', name: 'fullName'},
            // {id: 'address', name: 'address'},
            // {id: 'email', name: 'email'},
            // {id: 'telephone', name: 'telephone'},

            // {id: 'short-text', name: 'short-text'},
            // {id: 'long-text', name: 'long-text'},
            // {id: 'dropdown', name: 'dropdown'},
            // {id: 'single-choice', name: 'single-choice'},
            // {id: 'multiple-choice', name: 'multiple-choice'},
            // {id: 'file-upload', name: 'file-upload'},
            // {id: 'signature', name: 'signature'},
        ]
    }
    componentDidMount() {
        this.props.onStatusFormWorkflow('create', null)
        this.onGetTicketFormComponentFromWorkflow()
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.stepHeaderActive.stepComponentWorkflowId !== this.props.stepHeaderActive.stepComponentWorkflowId && this.props.statusSaveComponentFromWorkflow) {
            this.onGetTicketFormComponentFromWorkflow()
            this.props.onGetAfterSaveComponentFromWorkflow(false)
        }

        if (prevProps.activeStepProps.stepComponentWorkflowId !== this.props.activeStepProps.stepComponentWorkflowId) {
            this.props.onStatusFormWorkflow('create', null)
            this.onGetTicketFormComponentFromWorkflow()
        }
    }

    onGetTicketFormComponentFromWorkflow = () => {
       const { stepHeaderActive, selectHeaderTickets} = this.props
        if (stepHeaderActive && stepHeaderActive.stepComponentName === 'Form') {
            const workflowComponentId = stepHeaderActive.stepComponentWorkflowId
            const ticketId = selectHeaderTickets.ticketId
            apiGetTicketFormComponentFromWorkflow(ticketId, workflowComponentId).then((response) => {
                this.props.onGetAfterSaveComponentFromWorkflow(false)
                if (response.data.title && response.data.title.toLowerCase() === 'success') {
                    CustomNotificationManager.success(response.data.message, 'Success')
                } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
                    CustomNotificationManager.info(response.data.message, 'Info')
                }
                    if (response.data && response.data.sections && response.data.sections.length > 0) {
                        response.data.sections && response.data.sections.length > 0 && response.data.sections.forEach((elem) => {
                            if (elem.sectionType === 'dropdown-select') {
                                const dropdown = []
                                elem.options && elem.options.length > 0 && elem.options.forEach((elem2) => {
                                   if (elem2.selected) {
                                       dropdown.push(elem2.optionId)
                                       elem.dropdown = elem2.optionId
                                       elem.selectedSectionOptionIDs = dropdown
                                   }
                                })
                            }
                            if (elem.sectionType === 'radio') {
                                const singleChoice = []
                                elem.options && elem.options.length > 0 && elem.options.forEach((elem2) => {
                                    if (elem2.selected) {
                                        singleChoice.push(elem2.optionId)
                                        elem.singleChoice = elem2.optionId
                                        elem.selectedSectionOptionIDs = singleChoice
                                    }
                                })
                            }
                            if (elem.sectionType === 'multiple-choice') {
                                const multipleChoice = []
                                elem.options && elem.options.length > 0 && elem.options.forEach((elem2) => {
                                    if (elem2.selected) {
                                        multipleChoice.push(elem2.optionId)
                                        elem.multipleChoice = multipleChoice
                                        elem.selectedSectionOptionIDs = multipleChoice
                                    }
                                })
                            }
                        })
                        this.setState({
                            listComponentsTemplateForm: response.data.sections,
                            formId: response.data.formId,
                            formName: response.data.formName,
                        })
                        this.props.onGetFormId(response.data)
                        this.props.onGetFormTicketWorkflow(this.state.formerEmployee, response.data.sections)
                    }
            })
        }
     }

    onChangeNameAndDetails = (e, key, component, inputValue) => {
        const { listComponentsTemplateForm } = this.state
        listComponentsTemplateForm && listComponentsTemplateForm.length > 0 && listComponentsTemplateForm.forEach((elem) => {
            if (elem.sectionId === component.sectionId) {
                switch (key) {
                    case 'first_name':
                        elem.firstNameValue = e.target.value
                        this.setState({
                            formerEmployee: {...this.state.formerEmployee, firstNameValue: e.target.value},
                            listComponentsTemplateForm
                        }, () => {
                            this.props.onGetFormTicketWorkflow(this.state.formerEmployee, listComponentsTemplateForm)
                        })
                        break
                    case 'last_name':
                        elem.lastNameValue = e.target.value
                        elem.lastNameField = key
                        this.setState({
                            formerEmployee: {...this.state.formerEmployee, lastNameValue: e.target.value},
                            listComponentsTemplateForm
                        }, () => {
                            this.props.onGetFormTicketWorkflow(this.state.formerEmployee, listComponentsTemplateForm)
                        })
                        break
                    case 'address_street':
                        elem.streetAddressValue = e.target.value
                        this.setState({
                            formerEmployee: {...this.state.formerEmployee, streetAddressValue: e.target.value},
                        }, () => {
                            this.props.onGetFormTicketWorkflow(this.state.formerEmployee, listComponentsTemplateForm)
                        })
                        break
                    case 'address_street_line2':
                        elem.street2AddressValue = e.target.value
                        this.setState({
                            formerEmployee: {...this.state.formerEmployee, street2AddressValue: e.target.value},
                        }, () => {
                            this.props.onGetFormTicketWorkflow(this.state.formerEmployee, listComponentsTemplateForm)
                        })
                        break
                    case 'address_city':
                        elem.cityAddressValue = e.target.value
                        this.setState({
                            formerEmployee: {...this.state.formerEmployee, cityAddressValue: e.target.value},
                        }, () => {
                            this.props.onGetFormTicketWorkflow(this.state.formerEmployee, listComponentsTemplateForm)
                        })
                        break
                    case 'address_state':
                        elem.stateAddressValue = e.target.value
                        this.setState({
                            formerEmployee: {...this.state.formerEmployee, stateAddressValue: e.target.value},
                        }, () => {
                            this.props.onGetFormTicketWorkflow(this.state.formerEmployee, listComponentsTemplateForm)
                        })
                        break
                    case 'address_postal':
                        elem.postalCodeValue = e.target.value
                        this.setState({
                            formerEmployee: {...this.state.formerEmployee, postalCodeValue: e.target.value},
                        }, () => {
                            this.props.onGetFormTicketWorkflow(this.state.formerEmployee, listComponentsTemplateForm)
                        })
                        break
                    case 'email':
                        elem.emailAddressValue = e.target.value
                        this.setState({
                            formerEmployee: {...this.state.formerEmployee, emailAddressValue: e.target.value},
                        }, () => {
                            this.props.onGetFormTicketWorkflow(this.state.formerEmployee, listComponentsTemplateForm)
                        })
                        break
                    case 'telephone':
                        elem.phoneNumber = e.target.value
                        this.setState({
                            formerEmployee: {...this.state.formerEmployee, phoneNumber: e.target.value},
                        }, () => {
                            this.props.onGetFormTicketWorkflow(this.state.formerEmployee, listComponentsTemplateForm)
                        })
                        break
                    case 'short_text':
                        elem.shortTextValue = e.target.value
                        this.setState({
                            formerEmployee: {...this.state.formerEmployee, shortTextValue: e.target.value},
                        }, () => {
                            this.props.onGetFormTicketWorkflow(this.state.formerEmployee, listComponentsTemplateForm)
                        })
                        break
                    case 'long_text':
                        elem.longTextValue = e.target.value
                        this.setState({
                            formerEmployee: {...this.state.formerEmployee, longTextValue: e.target.value},
                        }, () => {
                            this.props.onGetFormTicketWorkflow(this.state.formerEmployee, listComponentsTemplateForm)
                        })
                        break
                    case 'dropdown':
                        const dropdown = []
                        dropdown.push(e)
                        elem.dropdown = e
                        elem.selectedSectionOptionIDs = dropdown
                        this.setState({
                            formerEmployee: {...this.state.formerEmployee, dropdownValue: e},
                        }, () => {
                            this.props.onGetFormTicketWorkflow(this.state.formerEmployee, listComponentsTemplateForm)
                        })
                        break
                    case 'single_choice':
                        const singleChoice = []
                        singleChoice.push(e.target.value)
                        elem.singleChoice = e.target.value
                        elem.selectedSectionOptionIDs = singleChoice
                        this.setState({
                            formerEmployee: {...this.state.formerEmployee, singleChoiceValue: e.target.value},
                        }, () => {
                            this.props.onGetFormTicketWorkflow(this.state.formerEmployee, listComponentsTemplateForm)
                        })
                        break
                    case 'multiple_choice':
                        elem.multipleChoice = e
                        elem.selectedSectionOptionIDs = e
                        this.setState({
                            formerEmployee: {...this.state.formerEmployee, multipleChoice: e},
                        }, () => {
                            this.props.onGetFormTicketWorkflow(this.state.formerEmployee, listComponentsTemplateForm)
                        })
                        break
                    case 'file_upload':
                        elem.fileIDs = []
                        this.props.onGetFormTicketWorkflow(this.state.formerEmployee, listComponentsTemplateForm)
                        break
                    case 'signature':
                        elem.signature = e
                        this.setState({
                            formerEmployee: {...this.state.formerEmployee, signature: e},
                        }, () => {
                            this.props.onGetFormTicketWorkflow(this.state.formerEmployee, listComponentsTemplateForm)
                        })
                        break
                    default:
                        break
                }
            }

        })
    }

    generateComponentsFormTemplate(formerEmployee, component, index, stepTicketActive) {
        const { listComponentsTemplateForm, formId } = this.state
        const { statusSaveComponentFromWorkflow, activeStepProps, selectHeaderTickets, statusResponseSaveFormSectionsData } = this.props
            switch(component.sectionType) {
                case 'fullName':
                    return <NameTemplateForm key={index} formerEmployee={formerEmployee} onGetFormTicketWorkflow = { this.props.onGetFormTicketWorkflow } onChangeNameAndDetails={this.onChangeNameAndDetails}
                                             component={component} listComponentsTemplateForm={listComponentsTemplateForm} statusSaveComponentFromWorkflow={statusSaveComponentFromWorkflow}
                                             stepTicketActive={stepTicketActive}/>
                    break
                case "address":
                    return <AddressTemplateForm key={index} formerEmployee={formerEmployee} onGetFormTicketWorkflow = { this.props.onGetFormTicketWorkflow } onChangeNameAndDetails={this.onChangeNameAndDetails}
                                                component={component} listComponentsTemplateForm={listComponentsTemplateForm} statusSaveComponentFromWorkflow={statusSaveComponentFromWorkflow}
                                                stepTicketActive={stepTicketActive}/>
                    break
                case "email":
                    return <EmailTemplateForm key={index} formerEmployee={formerEmployee} onGetFormTicketWorkflow = { this.props.onGetFormTicketWorkflow } onChangeNameAndDetails={this.onChangeNameAndDetails}
                                              component={component} listComponentsTemplateForm={listComponentsTemplateForm} statusSaveComponentFromWorkflow={statusSaveComponentFromWorkflow}
                                              stepTicketActive={stepTicketActive}/>
                    break
                case "phone":
                    return <TelephoneTemplateForm key={index} formerEmployee={formerEmployee} onGetFormTicketWorkflow = { this.props.onGetFormTicketWorkflow } onChangeNameAndDetails={this.onChangeNameAndDetails}
                                                  component={component} listComponentsTemplateForm={listComponentsTemplateForm} statusSaveComponentFromWorkflow={statusSaveComponentFromWorkflow}
                                                  stepTicketActive={stepTicketActive}/>
                    break
                case "short-text":
                    return <ShortTextTemplateForm key={index} formerEmployee={formerEmployee} onGetFormTicketWorkflow = { this.props.onGetFormTicketWorkflow } onChangeNameAndDetails={this.onChangeNameAndDetails}
                                                  component={component} listComponentsTemplateForm={listComponentsTemplateForm} statusSaveComponentFromWorkflow={statusSaveComponentFromWorkflow}
                                                  stepTicketActive={stepTicketActive}/>
                    break
                case "long-text":
                    return <LongTextTemplateForm key={index} formerEmployee={formerEmployee} onGetFormTicketWorkflow = { this.props.onGetFormTicketWorkflow } onChangeNameAndDetails={this.onChangeNameAndDetails}
                                                 component={component} listComponentsTemplateForm={listComponentsTemplateForm} statusSaveComponentFromWorkflow={statusSaveComponentFromWorkflow}
                                                 stepTicketActive={stepTicketActive}/>
                    break
                case "dropdown-select":
                    return <DropdownTemplateForm key={index} formerEmployee={formerEmployee} onGetFormTicketWorkflow = { this.props.onGetFormTicketWorkflow } onChangeNameAndDetails={this.onChangeNameAndDetails}
                                                 component={component} listComponentsTemplateForm={listComponentsTemplateForm} statusSaveComponentFromWorkflow={statusSaveComponentFromWorkflow}
                                                 stepTicketActive={stepTicketActive}/>
                    break
                case "radio":
                    return <SingleChoiceTemplateForm key={index} formerEmployee={formerEmployee} onGetFormTicketWorkflow = { this.props.onGetFormTicketWorkflow } onChangeNameAndDetails={this.onChangeNameAndDetails}
                                                     component={component} listComponentsTemplateForm={listComponentsTemplateForm} statusSaveComponentFromWorkflow={statusSaveComponentFromWorkflow}
                                                     stepTicketActive={stepTicketActive}/>
                    break
                case "multiple-choice":
                    return <MultipleChoiceTemplateForm key={index} formerEmployee={formerEmployee} onGetFormTicketWorkflow = { this.props.onGetFormTicketWorkflow } onChangeNameAndDetails={this.onChangeNameAndDetails}
                                                       component={component} listComponentsTemplateForm={listComponentsTemplateForm} statusSaveComponentFromWorkflow={statusSaveComponentFromWorkflow}
                                                       stepTicketActive={stepTicketActive}/>
                    break
                case "upload":
                    return <FileUploadTemplateForm key={index} formerEmployee={formerEmployee} onGetFormTicketWorkflow = { this.props.onGetFormTicketWorkflow } onChangeNameAndDetails={this.onChangeNameAndDetails}
                                                   component={component} listComponentsTemplateForm={listComponentsTemplateForm} statusSaveComponentFromWorkflow={statusSaveComponentFromWorkflow}
                                                   formId={formId} activeStepProps={ activeStepProps } selectHeaderTickets={ selectHeaderTickets }
                                                   onGetTicketFormComponentFromWorkflow={ this.onGetTicketFormComponentFromWorkflow }
                                                   onStartSaveFormSectionsData={ this.props.onStartSaveFormSectionsData }
                                                   statusResponseSaveFormSectionsData={ statusResponseSaveFormSectionsData }
                                                   stepTicketActive={stepTicketActive}/>
                    break
                case "signature":
                    return <SignatureTemplateForm key={index} formerEmployee={formerEmployee} onGetFormTicketWorkflow = { this.props.onGetFormTicketWorkflow } onChangeNameAndDetails={this.onChangeNameAndDetails}
                                                  component={component} listComponentsTemplateForm={listComponentsTemplateForm} statusSaveComponentFromWorkflow={statusSaveComponentFromWorkflow}
                                                  formId={formId} stepTicketActive={stepTicketActive}/>
                    break
                case "header":
                    return <HeaderTemplateForm key={index} formerEmployee={formerEmployee} onGetFormTicketWorkflow = { this.props.onGetFormTicketWorkflow } onChangeNameAndDetails={this.onChangeNameAndDetails}
                                               component={component} listComponentsTemplateForm={listComponentsTemplateForm} statusSaveComponentFromWorkflow={statusSaveComponentFromWorkflow}
                                               stepTicketActive={stepTicketActive}/>
                    break
                default: break
            }
    }
    render() {
        const { formerEmployee, listComponentsTemplateForm, formName } = this.state
        const { stepHeaderActive, stepTicketActive } = this.props
        //console.log('stepTicketActive ---- render ---- FORM', stepTicketActive)
        //console.log('this.props ---- render ---- FORM', this.props)
        return (
            <div className="form-workflow-page">
                {/*<div className="form-workflow-page__header">*/}
                {/*    <span className="name-former">{stepHeaderActive.stepComponentName ? stepHeaderActive.stepComponentName : 'SAR former employee' }</span>*/}
                {/*    <span className="details-former">{formName ? formName : 'Subject name and contact details' }</span>*/}
                {/*</div>*/}
                <div className="form-workflow-page__content">
                    { listComponentsTemplateForm && listComponentsTemplateForm.length > 0 && listComponentsTemplateForm.map((list, index) => {
                      return (
                          this.generateComponentsFormTemplate(formerEmployee, list, index, stepTicketActive)
                      )
                    })
                    }
                </div>
            </div>
        )
    }
}


FormEmployeeWorkflow.propTypes = {
    folder: PropTypes.object,
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id
})
export default compose(
    connect(mapStateToProps),
)(FormEmployeeWorkflow)