import React from 'react'
import './AssetRegister.scss'
import AddAsset from './components/AddAsset'
import FindAsset from './components/FindAsset'
import AssetTable from './components/AssetTable'
import CardList from './components/CardList'
import StatisticList from './components/StatisticList'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { getAllAssetRegister, addAssetRegister, updateAssetRegister, deleteAssetRegisterManager } from '../../../api//AssetRegister'
import CustomNotificationManager from '../../Common/CustomNotificationManager'

class AssetRegister extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeAddAsset: false,
      activeFindAsset: false,
      activeAccessAsset: false,
      allRegisters: [],
      searchResult: '',
      activeDeleteAsset: false,
      openCollapseProps: true,
      changeIconVisible: false,
      activeStatisticsTab: ''
    }
  }
  componentDidMount() {
    this.listSoftwareRegister()
  }

  listSoftwareRegister() {
    getAllAssetRegister(this.props.clientId).then((response) => {
      if (response.status === 200 && response.data) {
        this.setState({ allRegisters: response.data })
      }
    })
  }

  onSelectCard = (label) => {
    this.setState({ activeStatisticsTab: label })
  }

  submitForm = (e, action, registerType, registerTypeManager, fieldsValues, selectedRegisterObj) => {
    const { allRegisters } = this.state
    if (action === 'edit') {
      fieldsValues.registerType = registerType
      fieldsValues.registerManagerType = registerTypeManager
      delete fieldsValues.id
      const data = { values: fieldsValues }
      updateAssetRegister(data).then((response) => {
        let registerNumber= 0
        let registerRowNumber= 0
        if (response.status === 200) {
          for (let i=0; i<allRegisters.length; i++) {
            if (allRegisters[i].library.id === registerType) {
              registerNumber = i
            }
          }
          for (let j=0; j<allRegisters[registerNumber].values.length; j++) {
            if (allRegisters[registerNumber].values[j].id === registerTypeManager) {
              registerRowNumber = j
            }
          }
          const temp = this.state.allRegisters
          const newValues = response.data.values
          newValues.id = registerTypeManager
          temp[registerNumber].values[registerRowNumber] = newValues
          this.setState({ allRegisters: temp })
          CustomNotificationManager.success('Edit successful.', 'Edit')
        }
      })
    } else {
      fieldsValues.registerType = selectedRegisterObj.library.id
      const data = { values: fieldsValues }
      addAssetRegister(data).then((response) => {
        if (response.status === 200) {
          this.listSoftwareRegister()
          CustomNotificationManager.success('Saved successfully.', 'Saved')
        }
      })
    }
  }

  deleteAssetRegister = (row) => {
    const { allRegisters } = this.state
    deleteAssetRegisterManager(row).then((res) => {
      if (res.status === 200) {
        let registerIndex = 0
        let registerRowIndex = 0
        for (let i = 0; i < allRegisters.length; i++) {
          for (let j = 0; j < allRegisters[i].values.length; j++) {
            if (allRegisters[i].values[j].id === row) {
              registerIndex = i
              registerRowIndex = j
            }
          }
        }
        const temp = allRegisters
        temp[registerIndex].values.splice(registerRowIndex, 1)
        this.setState({ allRegisters: temp })
      }
    })
  }

  receiveAllSearchResult = (data) => {
    this.setState({
      allRegisters: data
    })
  }

  myCallbackOpenCollapse = (openCollapseProps) => {
    this.setState({
      openCollapseProps: openCollapseProps
    })
  }

  myCallbackOpenTable = (changeIconVisible) => {
    this.setState({
      changeIconVisible: changeIconVisible
    })
  }

  onClickAddAsset = () => {
    this.setState({
      activeAddAsset: !this.state.activeAddAsset,
      activeFindAsset: false,
      activeAccessAsset: false,
      activeDeleteAsset: false,
      openCollapseProps: false,
      changeIconVisible: !this.state.changeIconVisible
    })
  }
  onClickFindAsset= () => {
    this.setState({
      activeFindAsset: !this.state.activeFindAsset,
      activeAddAsset: false,
      activeAccessAsset: false,
      activeDeleteAsset: false,
      openCollapseProps: false,
      changeIconVisible: !this.state.changeIconVisible
    })
  }
  onClickAccessAsset = () => {
    this.setState({
      activeAccessAsset: !this.state.activeAccessAsset,
      activeDeleteAsset: false,
      activeFindAsset: false,
      activeAddAsset: false,
      openCollapseProps: false,
      changeIconVisible: !this.state.changeIconVisible
    })
  }

  onClickDeleteAsset = () => {
    this.setState({
      activeDeleteAsset: !this.state.activeDeleteAsset,
      activeAccessAsset: false,
      activeFindAsset: false,
      activeAddAsset: false,
      openCollapseProps: false,
      changeIconVisible: !this.state.changeIconVisible
    })
  }

  onClickCloseFormAdd = () => {
    this.setState({
      activeAddAsset: false
    })
  }

  closeAllTabs = () => {
    this.setState({
      activeDeleteAsset: false,
      activeAccessAsset: false,
      activeFindAsset: false,
      activeAddAsset: false,
      openCollapseProps: false
    })
  }

  render() {
    const { searchResult, allRegisters, activeStatisticsTab } = this.state
    return (
      <div className="assert-register-page__action-container">
        <div className="assert-register-page__action-container__statistic">
          <CardList onSelectCard={ this.onSelectCard } />
          <StatisticList activeStatisticsTab={ activeStatisticsTab }/>
        </div>
        <div className="assert-register-page__action-container__header">
          <div className="title title-Asset" onClick={ this.closeAllTabs }>
            ASSET REGISTER
          </div>
          <div className="actions">
            <div className="add-asset" onClick={() => this.onClickAddAsset()}>
              <span className="icon-add-b" style={ this.state.activeAddAsset ? { color: 'black' } : {} }/>
              <span className="title-plus-circle" style={ this.state.activeAddAsset ? { color: 'black' } : {} }>Add Asset</span>
              { this.state.activeAddAsset && <i className="up custom-asset"/> }
            </div>
            <div className="find-asset" onClick={() => this.onClickFindAsset()}>
              <span className="icon-find-asset-b" style={ this.state.activeFindAsset ? { color: 'black' } : {} }/>
              <span className="title-find-asset" style={ this.state.activeFindAsset ? { color: 'black' } : {} }>Find Asset</span>
              { this.state.activeFindAsset && <i className="up custom-asset"/> }
            </div>
            <div className="access-asset" onClick={() => this.onClickAccessAsset()}>
              <span className="icon-access-asset-b" style={ this.state.activeAccessAsset ? { color: 'black' } : {} } />
              <span className="title-access-asset" style={ this.state.activeAccessAsset ? { color: 'black' } : {} }>Access Asset</span>
            </div>
            <div className="delete-asset" onClick={() => this.onClickDeleteAsset()}>
              <span className="icon-delete-b" style={ this.state.activeDeleteAsset ? { color: 'black' } : {} }/>
              <span className="title-delete-asset" style={ this.state.activeDeleteAsset ? { color: 'black' } : {} }>Delete Asset</span>
            </div>
          </div>
        </div>
        { this.state.activeAddAsset ?
          <div className="assert-register-page__action-container__add-asset">
            <AddAsset allRegisters={ allRegisters } submitForm={ this.submitForm } onClickCloseFormAdd={ this.onClickCloseFormAdd } />
          </div> : ''
        }
        { this.state.activeFindAsset ?
          <div className="assert-register-page__action-container__add-asset">
            <FindAsset
              activeFindAsset={this.state.activeFindAsset}
              register={ allRegisters }
              receiveAllSearchResult={this.receiveAllSearchResult} />
          </div> : ''
        }
        { this.state.allRegisters && !this.state.activeAddAsset && !this.state.activeAccessAsset && !this.state.activeDeleteAsset && this.state.activeFindAsset &&
          <React.Fragment>
            <div className="accordion-table-register">
              { allRegisters && allRegisters.length > 0 && allRegisters.map((register, index) => {
                return (
                  <AssetTable allRegisters={ allRegisters }
                    key={index}
                    register={register}
                    listSoftwareRegister={ searchResult }
                    submitForm={ this.submitForm }
                    activeFindAsset = {this.state.activeFindAsset}
                    allRegistersList={this.state.allRegistersList}
                    myCallbackOpenCollapse = {this.myCallbackOpenCollapse}
                    myCallbackOpenTable = {this.myCallbackOpenTable}
                    changeIconVisible = {this.state.changeIconVisible}
                  />
                )

              })
              }
            </div>
          </React.Fragment>
        }
        { !this.state.activeFindAsset &&
        <div>
          { allRegisters && allRegisters.length > 0 && allRegisters.map((register, index) => {
            return (
              <AssetTable
                key={index}
                register={register}
                activeDeleteAsset={this.state.activeDeleteAsset}
                activeAccessAsset={this.state.activeAccessAsset}
                activeFindAsset = {this.state.activeFindAsset}
                activeAddAsset={this.state.activeAddAsset}
                allRegisters={ allRegisters }
                submitForm={ this.submitForm }
                deleteAssetRegister={ this.deleteAssetRegister }
                myCallbackOpenCollapse= {this.myCallbackOpenCollapse}
                openCollapseProps={this.state.openCollapseProps}
                changeIconVisible={this.state.changeIconVisible}
                myCallbackOpenTable = {this.myCallbackOpenTable}
              />
            )
          })
          }
        </div>
        }
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id
})
export default compose(connect(mapStateToProps))(AssetRegister)