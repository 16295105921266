import React from 'react'
import { Select } from 'antd'
import PropTypes from 'prop-types'
import {
  generateColumnFieldTypeContent,
  generateSelectCaret
} from '../../../../utils/AssetRegister/AssetRegisterHelperMethods'

const { Option } = Select
class HistoryUpdate extends React.Component {
  state = {
    fieldType: ''
  }

  onColumnTypeSelect = (field, value) => {
    this.setState({
      fieldType: value
    }, () => this.props.onChangeFieldType(field, value))
  }

  render() {
    const { field } = this.props
    const { fieldType } = this.state
    return (
      <div className="item-container" onMouseEnter={ () => this.props.disableDragging() }>
        <span className="name">{field.name}</span>
        <div className="custom-select-container">
          <Select placeholder="FIELD TYPE..." onChange={ (value) => this.onColumnTypeSelect(field, value) }
            className="custom-select"
            defaultValue={ field.type ? field.type : undefined } disabled>
            <Option value="TEXT">TEXT</Option>
            <Option value="TEXTAREA">TEXTAREA</Option>
            <Option value="RADIO">ONE CHOICE(radio buttons)</Option>
            <Option value="CHECKBOX">ONE CHOICE(dropdown)</Option>
            <Option value="MULTIPLE">MULTIPLE CHOICES</Option>
            <Option value="FILE">FILE</Option>
            <Option value="DATE">DATE</Option>
            <Option value="NUMBER">NUMBER</Option>
          </Select>
          {
            field.type || fieldType ? generateSelectCaret(field.type || fieldType) : ''
          }
          {
            field.type || fieldType ? generateColumnFieldTypeContent(field, field.type || fieldType, this.props) : ''
          }
        </div>
        <span className="icon-dragpad" onMouseEnter={ () => this.props.enableDragging() }/>
      </div>
    )
  }

}

HistoryUpdate.propTypes = {
  field: PropTypes.object.isRequired,
  onChangeOptions: PropTypes.func.isRequired,
  disableDragging: PropTypes.func.isRequired,
  enableDragging: PropTypes.func.isRequired
}
export default HistoryUpdate