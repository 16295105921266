import React from 'react'
import { getDraggableItemStyle, reorderDraggableCards } from '../../../../utils'
import PropTypes from 'prop-types'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import HistoryUpdate from './HistoryUpdate'

class HistoryUpdateContainer extends React.Component {

  state = {
    historyUpdate: [],
    isDragging: true
  }

  constructor(props) {
    super(props)
    this.onDragEnd = this.onDragEnd.bind(this)
  }

  componentDidMount() {
    if (this.props.historyUpdate) {
      this.setState({
        historyUpdate: this.props.historyUpdate
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.historyUpdate !== this.props.historyUpdate) {
      this.setState({
        historyUpdate: this.props.historyUpdate
      })
    }
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const historyUpdate = reorderDraggableCards(
      this.state.historyUpdate,
      result.source.index,
      result.destination.index
    )

    this.setState({
      historyUpdate,
      isDraggable: true
    }, () => this.props.updateItems(historyUpdate))
  }

  addOptions = (record, value, item) => {
    const index = this.state.historyUpdate.findIndex((field) => field.domId === record.domId)
    const newArray = [ ...this.state.historyUpdate ]
    newArray[index].options = value
    this.setState({
      columns: newArray
    }, () => this.props.updateItems(this.state.historyUpdate, '', item))
  }

  enableDragging = () => {
    this.setState({
      isDragging: false
    })
  }

  disableDragging = () => {
    this.setState({
      isDragging: true
    })
  }

  render() {
    const { historyUpdate, isDragging } = this.state
    return (
      historyUpdate && historyUpdate.length > 0 ?
        <React.Fragment>
          <div className="content">
            <DragDropContext onDragEnd={ this.onDragEnd }>
              <Droppable droppableId="droppable">
                { (provided, snapshot) => (
                  <div
                    { ...provided.droppableProps }
                    ref={ provided.innerRef }
                    // style={getListStyle(snapshot.isDraggingOver)}
                  >
                    { historyUpdate.map((item, index) => (
                      <Draggable key={ item.domId || item.id } draggableId={ item.domId || item.id } index={ index } isDragDisabled={ isDragging }>
                        { (provided, snapshot) => (
                          <div
                            className="item"
                            ref={ provided.innerRef }
                            { ...provided.draggableProps }
                            { ...provided.dragHandleProps }
                            style={ getDraggableItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            ) }>
                            <HistoryUpdate field={ item }
                              onChangeOptions={ this.addOptions }
                              disableDragging={ this.disableDragging }
                              enableDragging={ this.enableDragging }/>
                          </div>
                        ) }
                      </Draggable>
                    )) }
                    { provided.placeholder }
                  </div>
                ) }
              </Droppable>
            </DragDropContext>
          </div>
        </React.Fragment> : ''
    )
  }
}

HistoryUpdateContainer.propTypes = {
  historyUpdate: PropTypes.array.isRequired,
  updateItems: PropTypes.func.isRequired
}

export default HistoryUpdateContainer