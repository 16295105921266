import React from 'react'
import LoadingImg from '../../assets/loading.svg'
import './Loading.css'

const Loading = () => (
  <div className="loading-container">
    <img className="loading-image" src={ LoadingImg } alt="Loading spinner" />
  </div>
)

export default Loading
