import { sortByPublication } from './Utils'
import { regexBuilder } from '../../../../utils'

const filterByTerm = (term, templates) => {
  if (term === '') return templates
  const termRE = regexBuilder(term)
  return templates.filter((template) => (
    template.title.match(termRE)
  ))
}

const filterByDisplay = (display, templates) => {
  if (display === '') return templates
  
  if (display === 'global') {
    return templates.filter((template) => template.isGlobal)
  }

  if (display === 'active') {
    return templates.filter((template) => template.isEnabled && !template.isGlobal)
  }

  if (display === 'inactive') {
    return templates.filter((template) => !template.isEnabled && !template.isGlobal)
  }

  if (display === 'dpia') {
    return templates.filter((template) => template.type !== 'PIA')
  }

  if (display === 'pia') {
    return templates.filter((template) => template.type === 'PIA')
  }

  if (display === 'dataProcessor') {
    return templates.filter((template) => template.type === 'DATA_PROCESSOR_DPIA')
  }

  if (display === 'dataController') {
    return templates.filter((template) => template.type === 'DATA_CONTROLLER_DPIA')
  }
}

const filterBySort = (sortBy, templates) => {
  if (sortBy === 'creationDate') {
    return templates.sort((itemA, itemB) => (itemB.creationDate - itemA.creationDate))
  }

  if (sortBy === 'publicationDate') {
    return sortByPublication(templates)
  }
}

export const filterTemplates = (filterAttributes, allTemplates) => {
  let filteredTemplates = allTemplates
  const { byTerm, byDisplay, bySort } = filterAttributes

  filteredTemplates = filterByTerm(byTerm, filteredTemplates)
  filteredTemplates = filterByDisplay(byDisplay, filteredTemplates)
  filteredTemplates = filterBySort(bySort, filteredTemplates)

  return filteredTemplates
}