import React from 'react'
import PropTypes from 'prop-types'

import {
  apiOspreyGetDataModels
} from '../../api/OspreyAnalytics.js'

const BackOfficeDataModelsContext = React.createContext()

class BackOfficeDataModelsProvider extends React.Component {
  state = {
    datamodels: [],
    isFetchinDataModels: true,
  }

  getDataModels = () => {
    apiOspreyGetDataModels()
      .then((response) => {

        this.setState({
          datamodels: response.data.value.datamodels.datamodel,
          isFetchingDataModels: false
        })
      })
      .catch((error) => {
        console.log(error, 'error')
      })
  }

  render = () => {
    return (
      <BackOfficeDataModelsContext.Provider value={{
        datamodels: this.state.datamodels,
        getDataModels: this.getDataModels
      }}>
        { this.props.children }
      </BackOfficeDataModelsContext.Provider>
    )
  }
}

BackOfficeDataModelsProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export default BackOfficeDataModelsProvider
export const BackOfficeDataModelsConsumer = BackOfficeDataModelsContext.Consumer