import React from 'react'
import PropTypes from 'prop-types'

import DocumentItem from './DocumentItem'

import './DocumentList.css'

class DocumentList extends React.Component {

  state = { 
    documentId: null
  }
  
  render = () => {
    const { documents } = this.props
    const { fieldNames } = this.props
    const { fieldIds } = this.props

    if (documents.length === 0) return (
      <p>No Documents in Category</p>
    )

    return (
      <div id="backOffice-documentList_Table" className="documentList__wrapperTable">
        <table className="documentList__wrapperTable">
          <thead>
            <tr>
              { fieldNames.map(function(name, index) {
                return <th style={{ textAlign: 'center' }} key={ index }>{name}</th>
              })}
              { this.props.removeDocument !== null &&
                <th style={{ textAlign: 'center' }}>Remove</th>
              }
            </tr>
          </thead>
          { documents 
            ? <tbody>
              { documents.map((document, index) => (
                <DocumentItem fields={ fieldIds } document={ document } key={ document.ID } selectDocument={ this.props.selectDocument.bind(this) } removeDocument={ this.props.removeDocument !== null ? this.props.removeDocument.bind(this) : null }/>
              ))}
            </tbody>
            : <tbody/>
          }
        </table>
      </div>
    )
  }
}

DocumentList.propTypes = {
  documents: PropTypes.array.isRequired,
  selectDocument: PropTypes.func.isRequired,
}

export default DocumentList