import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CustomNotificationManager from '../Common/CustomNotificationManager'
import Icon from 'antd/es/icon'
import { Modal, Select, Input, Button } from "antd";
import {addBusinessTermToDictionary, getDictionariesRedacted} from "../../api/SmartBox";
const { Option } = Select
class RedactedSidebar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      newRedactedLabel: '',
      expandPageSelected: true,
      objectKeysPagesExpanded: [],
      onHoverRedaction: false,
      convertedAnnotationText: '',
      onHoverRedactionAnnotation: null,
      visibleModalDictionary: false,
      annotationSelect: {},
      getDictionary: [],
        valueDictionary: '',
        selectDictionary: {},
        actionAddTerm: false,
        itemsAnnotation: []
    }
  }
 componentDidMount() {
   getDictionariesRedacted().then((response) => {
     if (response.status === 200) {
       this.setState({
         getDictionary: response.data
       })
     }
   })
 }

  changeButtonInput = (annotationSelectedItem, convertedAnnotation, annotation, pageNumber) => {
    const { canvasData } = this.props
    let newRedactedLabel = ''
    canvasData[pageNumber] && canvasData[pageNumber].map((item, key) => {
      const itemDecoded = this.isJson(item) ? JSON.parse(item) : item
      if (itemDecoded.pagePointSum === annotationSelectedItem) {
        newRedactedLabel = itemDecoded.text ? itemDecoded.text : (itemDecoded.annotation ? itemDecoded.annotation.substring(0, 20) : `Redaction ${ key + 1 }`.substring(0, 20))
      }
      return true
    })
    this.props.setPage(pageNumber)
    this.props.setAnnotationSelectedItem(annotationSelectedItem)
    this.setState({ newRedactedLabel })
  }

  changeValues = (e) => {
    this.setState({ newRedactedLabel: e.target.value })
  }

  changeKeyPress= (e, annotationSelectedItem) => {
    if (e.key === 'Enter') {
      this.props.setAnnotationSelectedItem(annotationSelectedItem)
      this.updateRedactedLabel(annotationSelectedItem)
    }
  }

  updateRedactedLabel = (annotationSelectedItem) => {
    const { newRedactedLabel } = this.state
    const { canvasData } = this.props
    let selectedPage = 1
    canvasData && Object.keys(canvasData).map((page) => {
      canvasData[page] && canvasData[page].map((item, key) => {
        const itemDecoded = this.isJson(item) ? JSON.parse(item) : item
        if (itemDecoded.pagePointSum === annotationSelectedItem && newRedactedLabel) {
          selectedPage = page
          itemDecoded.text = newRedactedLabel
          this.props.editLabel(itemDecoded, newRedactedLabel)
        }
        canvasData[page][key]= itemDecoded
        return true
      })
      return true
    })
    this.props.savePointsOnPage(canvasData[selectedPage], selectedPage)
    this.props.loadPoints()
    this.props.setAnnotationSelectedItem(0)
    CustomNotificationManager.success('Annotation text was changed', 'Edit successfully')
  }

  isJson(str) {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }
  onMouseEnter(e, index, convertedAnnotation) {
   this.setState({
     onHoverRedaction: true,
     convertedAnnotationText: convertedAnnotation.text,
     onHoverRedactionAnnotation: convertedAnnotation.index
   })
  }
  onMouseLeave() {
    this.setState({
      onHoverRedaction: false,
      convertedAnnotationText: ''
    })
  }
  addDictionaryAnnotationSidebar = (e, convertedAnnotation) => {
    this.setState({
      visibleModalDictionary: true,
      annotationSelect: convertedAnnotation
    })
  }
  handleCancel = (e) => {
    this.setState({
      visibleModalDictionary: false
    })
  }
  onEnterAction = (e, status) => {
      this.setState({
          actionAddTerm: true
      })
  }
    onLeaveAction = (e, status) => {
      this.setState({
          actionAddTerm: false
      })
    }
  generateHtmlForOnePageAnnotation(canvasData, pageNumber) {
    const { newRedactedLabel, btnAnnotationActiveIndex, onHoverRedactionAnnotation, actionAddTerm } = this.state
    const { annotationSelectedItem, endPointXDraw } = this.props
    const items = []
    let index = 1
    canvasData[pageNumber].map((annotation, index2) => {
      if (annotation) {
        const convertedAnnotation = this.isJson(annotation) ? JSON.parse(annotation) : annotation
        if (convertedAnnotation.type !== 2) {
          convertedAnnotation.index = index2
          convertedAnnotation.endX1 = endPointXDraw
          convertedAnnotation.pageNumber = pageNumber
          //const difx = endPointXDraw - convertedAnnotation.x1
          //const dify = convertedAnnotation.y1 - convertedAnnotation.y2
           if (endPointXDraw !== convertedAnnotation.x1 && convertedAnnotation.endX1 !== convertedAnnotation.x1) {
              items.push(
                  <React.Fragment key={index}>
                      {convertedAnnotation &&
                      <div onMouseEnter={(e) => this.onMouseEnter(e, index, convertedAnnotation)}
                           onMouseLeave={() => this.onMouseLeave()} key={convertedAnnotation.pagePointSum}
                           className="position-relative">
                          {annotationSelectedItem !== convertedAnnotation.text &&
                          <React.Fragment>
                    <span
                        // className={convertedAnnotation.text && convertedAnnotationText && convertedAnnotationText === convertedAnnotation.text ? 'icon-v17-redaction-hover' : 'icon-v17-redaction'}
                        className='icon-v17-redaction'
                    ></span>
                              { annotationSelectedItem !== convertedAnnotation.pagePointSum &&
                              <button key={index}
                                      onClick={() => this.props.highlightRectangle(convertedAnnotation, annotation, pageNumber)}
                                      value={annotation} className={btnAnnotationActiveIndex === annotation
                                  ? 'annotation-page__button annotation-page__button--active annotation-page__update' : 'annotation-page__button  annotation-page__update'}>
                                  {convertedAnnotation.text && convertedAnnotation.text !== '!@#draw-by-hand#@!' ? convertedAnnotation.text :
                                      (convertedAnnotation.annotation && convertedAnnotation.annotation !== '!@#draw-by-hand#@!' ? convertedAnnotation.annotation.substring(0, 20) : `Redaction ${index}`.substring(0, 20))}
                              </button>
                              }
                              {
                                  // convertedAnnotation.text && convertedAnnotationText && convertedAnnotationText === convertedAnnotation.text && annotationSelectedItem !== convertedAnnotation.pagePointSum
                                  convertedAnnotation && onHoverRedactionAnnotation === convertedAnnotation.index &&
                                  <span className="action-redacted-sidebar">
                        <span
                            className="icon-v21-edit annotation-page__edit"
                            id={convertedAnnotation.pagePointSum}
                            onClick={() => {
                                this.changeButtonInput(convertedAnnotation.pagePointSum, convertedAnnotation, annotation, pageNumber)
                            }}
                        />
                              { annotationSelectedItem !== convertedAnnotation.pagePointSum &&
                              <span className={actionAddTerm ? "icon-v21-add-active" : 'icon-v21-add'}
                                    onClick={(e) => this.addDictionaryAnnotationSidebar(e, convertedAnnotation)}
                                    onMouseEnter={(e) => this.onEnterAction(e, 'add')}
                                    onMouseLeave={() => this.onLeaveAction()}></span>
                              }
                      </span>
                              }
                          </React.Fragment>
                          }
                          {annotationSelectedItem === convertedAnnotation.pagePointSum &&
                          <React.Fragment>
                              <input
                                  className="icon-edit-b annotation-page__input"
                                  type="text"
                                  id={convertedAnnotation.pagePointSum}
                                  value={newRedactedLabel}
                                  onChange={(event) => this.changeValues(event)}
                                  onKeyPress={(event) => this.changeKeyPress(event, convertedAnnotation.pagePointSum)}
                              />
                              <Icon type="save"
                                    className="annotation-page__edit"
                                    id={convertedAnnotation.pagePointSum}
                                    onClick={() => {
                                        this.updateRedactedLabel(convertedAnnotation.pagePointSum)
                                    }}
                              />
                          </React.Fragment>
                          }
                      </div>
                      }
                  </React.Fragment>
              )
        }
        }
      }
      index = index + 1
      return true
    })
      // const uniqueItems = items && items.length > 0 && items.filter((a, i) => items.findIndex((s) => a.props.children.key === s.props.children.key) === i)
    return items
  }
 onExpandPageReview = (e, canvasData, page, index, expandPageSelected) => {
    const { objectKeysPagesExpanded } = { ...this.state }
   objectKeysPagesExpanded.push(page)
   this.setState({
     expandPageSelected: expandPageSelected,
     objectKeysPagesExpanded: objectKeysPagesExpanded
   })

 }
  updateValues = (e, option, arrayOption, option2) => {
      const { getDictionary } = this.state
      let dictSelect = {}
      getDictionary && getDictionary.length > 0 && getDictionary.forEach((dict) => {
        if (e === dict.listName) {
            dictSelect = dict
        }
      })
      this.setState({
          valueDictionary: e,
          selectDictionary: dictSelect
      })
  }
  onCreateDictionary = (e) => {
      const { selectDictionary, annotationSelect } = this.state
      const { documentId } = this.props
      const dtoDictionary = {
          createDictionary: false,
          documentId: documentId,
          dictionaryName: selectDictionary.listName,
          dictionaryDescription: selectDictionary.description,
          dictionaryTerm: annotationSelect.text ? annotationSelect.text : null,
          dictionaryColor: selectDictionary.colour,
          dictionaryId: selectDictionary.id,
      }
      addBusinessTermToDictionary(dtoDictionary).then((response) => {
           if(response.status === 200) {
               this.setState({
                   visibleModalDictionary: false,
                   valueDictionary: ''
               })
           }
      })
  }

  render() {
    const { canvasData, page, index } = this.props
    const { expandPageSelected, visibleModalDictionary, annotationSelect, getDictionary, valueDictionary } = this.state
    return (
      <div className='annotation-page' key={ index }>
        <span onClick={(e) => this.onExpandPageReview(e, canvasData, page, index, !this.state.expandPageSelected)} className="annotation-page-content">
        <span className='annotation-page__title'>{ page } Page</span>
          { expandPageSelected ?
              <Icon type="down" aria-hidden="true" style={ { width: '20px', height: '15px' } }/> :
              <Icon type="up" aria-hidden="true" style={ { width: '20px', height: '15px' } }/>
          }
          </span>
        { expandPageSelected &&
          this.generateHtmlForOnePageAnnotation(canvasData, page)
        }
        <Modal className="dictionary-modal-redacted"
               title={ <span className="content-header-modal"><span>Add to dictionary</span><span onClick={ this.handleCancel } className="icon-v21-close-black"></span></span> }
               visible={ visibleModalDictionary } onCancel={ this.handleCancel } footer={ null } closable={ false } >
          <div className="modal-add-to-dictionary">
            <div className="content-add-to-dictionary">
              <Select className="option-redaction-dictionary" value={ valueDictionary === '' ? <span className="place-holder">Choose dictionary</span> : <span className="select-value">{ valueDictionary }</span> }
                      placeholder="Choose dictionary" dropdownClassName="dropdown-dict-options" onSelect={ this.updateValues }>
                { getDictionary && getDictionary.length > 0 && getDictionary.map((dict) => {
                  return (
                      <Option
                          value={ dict.listName } key={ dict.id}>{ dict.listName }</Option>
                  )
                })
                }
              </Select>
              <Input value={ annotationSelect.text } className="input-term"/>
            </div>
            <div className="footer-buttons-dictionary">
              <Button onClick={ this.handleCancel } className="action-cancel-dictionary">Cancel</Button>
              <Button disabled={ valueDictionary === ''} onClick={(e) => this.onCreateDictionary(e)} className="action-create-dictionary">Create</Button>
            </div>
          </div>
        </Modal>
      </div>
    )
  }

}

RedactedSidebar.propTypes = {
  index: PropTypes.number.isRequired,
  canvasData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  highlightRectangle: PropTypes.func.isRequired,
  savePointsOnPage: PropTypes.func.isRequired,
  loadPoints: PropTypes.func.isRequired,
  annotationSelectedItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setAnnotationSelectedItem: PropTypes.func
}

export default RedactedSidebar
