import React from 'react'
import './SmartBoxPage.scss'
import { connect } from 'react-redux'
import { Input, Spin, notification, Menu } from 'antd'
import { compose } from 'redux'
import SmartBoxFile from './SmartBoxFile'
import TreeStructureFolder from './TreeStructureFolder'
import {
  apiSmartBoxPreviewSarDocByThumbnailId,
  createSmartDocument,
  getSmartBoxFilesFilterForSearch,
  getSmartBoxFilesPerPage,
  setFileStatus,
  getProgressBarData,
  getProgressBarDataForBulk,
  getFolderCountFiles, getProgressRename, apiCheckBoxStatusForUpdate
} from '../../../api/SmartBox'
import moment from 'moment'
import { deleteFolderOrFile } from '../../../utils/SmartBox/SmartBoxMethods'
import {
  setSmartBoxSearchedWord,
  setSmartBoxUploadingObject,
  setSmartBoxViewMode,
  setSmartBoxNewNode,
  setSmartBoxCurrentFolder,
  setSmartBoxFilesTable,
  setSmartBoxFetchFiles,
  setSmartBoxSelectedFiles,
  setSmartBoxFolderHasBulk,
  setResponseProgressBarRename,
  setSmartBoxFolderCountTree,
  setSmartBoxCheckStatusDeduplication, setSmartBoxShowUploadNavigation,
  setSmartBoxNotAnalysisComplete,
  setSmartBoxCheckBoxRedactionStatus,
  setSmartBoxClickOutsideFilter,
  setSmartBoxHomeClicked,
  setSmartBoxSelectedKeys, setBoxListViewSelectedKeys,
  setProgressEventUpload, setSmartBoxExistsFilesWarningsNotification
} from '../../../actions/smartBoxActions'
import { RedactionTool } from '../../Sar/Redacting/RedactionTool'
import { generateBlobFromBase64 } from '../../../utils/GenerateBlob'
import swal from 'sweetalert2'
import CustomNotificationManager from '../../Common/CustomNotificationManager'
import { setFooterVisibility, setHeaderVisibility, setSidebarVisibility } from '../../../actions/shellActions'
import WebWorker from './workers/WebWorker'
import worker from './workers/file-extensions.worker'
import SmartBoxActionsHeader from './SmartBoxActionsHeader'
import MetadataPage from './MetadataPage'
import WrapperNotification from '../../../components/SmartBox/SmartBox/WrapperNotification'
import DirectoryNote from './DirectoryNote'
import NewBoxCreate from '../SmartBox/SettingsUpdateSmartBox/NewBoxCreate'
import cookie from "react-cookies";
import withRouter from '../../../private-routers/withRouter'

class SmartBoxPage extends React.Component {
  state = {
    files: [],
    foldersCountFiles: [],
    newFolderClicked: false,
    searchText: '',
    viewMode: 'grid',
    uploadExecuted: 0,
    showDeleteButtons: {},
    redactionMode: false,
    documentBytes: [],
    loading: false,
    documentId: '',
    smartBoxDocumentId: '',
    expandedRowKeys: [],
    clicks: [],
    clicksTimeout: 0,
    contentClass: '',
    sidebarClass: '',
    clickTimeout: null,
    listKeysFiles: [],
    listKeysFilesDeleted: [],
    checkKeysFiles: false,
    selectedDoc: {},
    sidebarOpen: false,
    loadingBoxPage: false,
    disabledOnClicked: false,
    visitedRedactionSmart: 'smart',
    validNewFolderName: { success: true, reason: 'Invalid name' },
    newCustomStatusNameValid: { success: true, reason: 'Invalid name !' },
    finalizedDocPath: false,
    totalPagesForPagination: 20,
    // amountPerPage: 20,
    currentPage: 1,
    paginationOptions: [20, 50, 100],
    customStatusColor: '#cccccc',
    customerStatusModalVisible: '',
    foldersInAIProcess: 0,
    documentNoteVisible: false,
    listOfThumbnails: [],
    currentUrl: window.location.href,
    refreshChildren: false,
    additionalProgressBarAi: [],
    sendStatusProgressOnRefresh: false,
    totalFolderOnRoot: null,
    countFilesTimer: 0,
    activeFile: {},
    startSettingsNewBox: false,
    newAddBoxOnList: '',
    listNewBoxCreate: [],
    foldersCheckErrors: [],
    // counterRandom: 0,
    filesProgressRename: [],
    checkContentProgressBar: [],
    responseCheckUpdateBox: [],
    setIntervalUpdateBox: 0,
    progressBulkData: true,
    testAfterRename: false,
    checkStatusResponse: false,
    responseProgressBarFiles: [],
    statusCreateBox: null,
    documentsStartNr: null,
    stateFetch: true,
    statusResponsePagination: false,
    statusPagination: null,
    statusFolderCountFiles: null,
    statusThumbnailForFiles: 200,
    statusProgressBarData: null,
    actionNextPreviousSmartbox: false,
    setProgressBarBulkData: 0,
    responseProgressBarBulkData: [],
    statusPageModalDownload: false,
    lockSmartViewChanges: false,
  }
  
  menu = (id) => (
    <Menu>
      <Menu.Item key="0">
        <span onClick={ (e) => {
          e.stopPropagation()
          this.props.navigate(`/smart-box/folder/${ id }/settings`)
        } }>Settings</span>
      </Menu.Item>
    </Menu>
  );
  
  constructor(props) {
    super(props)
    this.child = React.createRef()
    this.handleDeleteFile = this.handleDeleteFile.bind(this)
    this.handleNewFolderClick = this.handleNewFolderClick.bind(this)
    this.handleNewFolderKeyPressed = this.handleNewFolderKeyPressed.bind(this)
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this)
    this.handleChangeViewMode = this.handleChangeViewMode.bind(this)
  }
  
  componentDidMount() {
    window.addEventListener('beforeunload', function (event) {
      event.stopImmediatePropagation();
    })
    this.props.setSmartBoxCheckBoxRedactionStatus(0)
    //cookie.save('checkRedaction', 'dragos 55555555555', {path: '/'})
    cookie.remove('checkRedaction', { path: '/' })
    cookie.remove('countFiles', { path: '/' })
    cookie.save('checkProgressBarBulkData', 'getBarBulkData', {path: '/'})
    if (this.props.smartBoxCurrentSortOption) {
      this.setState({
        sort: this.props.smartBoxCurrentSortOption
      })
    }
    if (this.state.stateFetch) {
      this.setState({
        stateFetch: false
      }, () => {
        this.fetchFiles()
      })
    }
    //this.checkBoxStatusForUpdate()
    this.props.setSmartBoxCheckStatusDeduplication(false)
    this.props.setSmartBoxShowUploadNavigation(true)
    this.worker = new WebWorker(worker)
    this.worker.addEventListener('message', (e) => {
      const { acceptedFiles, rejectedFiles } = e.data
      acceptedFiles && acceptedFiles.length > 0 && this.props.setSmartBoxUploadingObject(acceptedFiles, null)
      for (let i = 0; i < rejectedFiles.length; i++) {
        notification.warning({
          message: 'Not Uploaded',
          description: `File ${ rejectedFiles[i].name } with the extension ${ rejectedFiles[i].type } cannot be uploaded.`,
          className: 'nt-warning',
          duration: 1000000
        })
      }
    })
    setTimeout(() => {
      cookie.remove('checkRedaction', { path: '/' })
    }, 10000)
    document.addEventListener('mousedown', this.handleClickOutsideFiles, false)
    cookie.remove('checkDeduplicationStatus', { path: '/' })
    cookie.remove('connectMicrosoft', { path: '/' })
    cookie.remove('checkListDuplicateFileBox', { path: '/' })
    cookie.remove('checkListClutter', { path: '/' })
    cookie.remove('checkListNearFileBox', { path: '/' })
    cookie.remove('checkGetDuplicatesCardInfo', { path: '/' })
    cookie.remove('checkDownloadFilesError', { path: '/' })
    cookie.remove('checkErrorReportStatus', { path: '/' })
    cookie.remove('checkWarningsReportStatus', { path: '/' })
    this.props.setProgressEventUpload(undefined)
  }

  componentWillUnmount() {
    clearInterval(this.state.countFilesTimer)
    clearInterval(this.timerBulk)
    clearInterval(this.timer)
  }
   checkBoxStatusForUpdate = () => {
     const {currentPage, responseCheckUpdateBox, setIntervalUpdateBox, checkStatusResponse} = this.state
     const {smartBoxPageLimit} = this.props
     if (!checkStatusResponse) {
     apiCheckBoxStatusForUpdate(currentPage, smartBoxPageLimit).then((response) => {
       if (response.status === 200) {
         this.setState({
           responseCheckUpdateBox: response.data
         }, () => {
           if (responseCheckUpdateBox.length > response.data.length) {
             //this.fetchFiles()
           }
         })
         if (response.data && response.data.length > 0) {
           this.setIntervalCheckBoxUpdate()
         } else {
           clearInterval(setIntervalUpdateBox)
           this.setState({setIntervalUpdateBox})
         }
       }
     }).catch((error) => {
       if (error?.response?.status === 504) {
         CustomNotificationManager.cleanBuffer('warning', '')
       }else if (error?.response?.status === 401) {
         CustomNotificationManager.cleanBuffer('warning', '')
         clearInterval(setIntervalUpdateBox)
         this.setState({
           setIntervalUpdateBox,
           checkStatusResponse: true
         })
       }else if (error?.response?.status === 403) {
         CustomNotificationManager.cleanBuffer('warning', '')
       }else if (error?.response?.status === 502) {
         CustomNotificationManager.cleanBuffer('warning', '')
       }else{
        console.log(error)
       }
     })
   }

   }
   setIntervalCheckBoxUpdate = () => {
     const { responseCheckUpdateBox, checkStatusResponse } = this.state
     let { setIntervalUpdateBox } = this.state
     if (setIntervalUpdateBox === 0) {
       if (responseCheckUpdateBox && responseCheckUpdateBox.length > 0 && !checkStatusResponse) {
         //setIntervalUpdateBox = setInterval(this.callBackCheckBoxUpdate, 5000)
         this.setState({
           setIntervalUpdateBox
         })
       } else {
         clearInterval(setIntervalUpdateBox)
       }
     }

   }
   callBackCheckBoxUpdate = () => {
     const { currentPage, responseCheckUpdateBox, setIntervalUpdateBox, checkStatusResponse } = this.state
     const { smartBoxPageLimit } = this.props
     if (responseCheckUpdateBox && responseCheckUpdateBox.length > 0 && !checkStatusResponse) {
       apiCheckBoxStatusForUpdate(currentPage, smartBoxPageLimit).then((response) => {
         if (response.status === 200) {
           this.setState({
             responseCheckUpdateBox: response.data
           }, () => {
             if (responseCheckUpdateBox.length !== response.data.length) {
               //this.fetchFiles()
             }
           })
           if (response.data && response.data.length > 0) {
             //this.setIntervalCheckBoxUpdate()
             //clearInterval(setIntervalUpdateBox)
             this.setState({setIntervalUpdateBox})
           } else {
             clearInterval(setIntervalUpdateBox)
             this.setState({setIntervalUpdateBox})
           }
         }
       }).catch((error) => {
        if(error?.response?.status){
          if (error.response.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }
          if (error.response.status === 401) {
            CustomNotificationManager.cleanBuffer('warning', '')
            clearInterval(setIntervalUpdateBox)
            this.setState({setIntervalUpdateBox, responseCheckUpdateBox: [],
             checkStatusResponse: true})
         }
         if (error.response.status === 403) {
           CustomNotificationManager.cleanBuffer('warning', '')
          }
          if (error.response.status === 502) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }
        }else{
          console.log(error)
        }
        })
     } else {
       clearInterval(setIntervalUpdateBox)
       this.setState({ setIntervalUpdateBox })
     }
   }
  handleClickOutsideFiles = (e) => {
    const { statusPageModalDownload } = this.state
    if (typeof e.target.className === 'string' && (e.target.className.includes('smart-box-page__action-container__content__files content-files') && e.target.className.includes('ant-spin-container')) && !statusPageModalDownload) {
      this.props.setSmartBoxClickOutsideFilter(true)
      this.props.setSmartBoxSelectedKeys([])
      this.setState({
        listKeysFiles: [],
        listKeysFilesDeleted: [],
      })
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.newFolderClicked && typeof this.newFolderInput !== 'undefined' && this.props.smartBoxViewMode === 'list') {
      this.newFolderInput.focus()
    }
    if ((prevProps !== this.props) && this.props.selectKeysSmartBox && this.props.selectKeysSmartBox.length > 0) {
      this.setState({
        listKeysFiles: this.props.selectKeysSmartBox
      })
    }
    if ((prevProps.fetchFolderContent !== this.props.fetchFolderContent) && this.props.fetchFolderContent === true) {
      this.fetchFilesWithoutRefresh()
    }
    if ((prevProps.smartBoxFolderHasBulk !== this.props.smartBoxFolderHasBulk) && this.props.smartBoxFolderHasBulk === true) {
      this.fetchFiles()
    }
    if (prevProps.smartBoxRenamedFolder.id !== this.props.smartBoxRenamedFolder.id || prevProps.smartBoxRenamedFolder.newName !== this.props.smartBoxRenamedFolder.newName) {
      this.changeFileName(this.props.smartBoxRenamedFolder.id, this.props.smartBoxRenamedFolder.newName)
    }
    this.props.setSmartBoxCurrentFolder('')
    if (prevProps.responseProgressBarRename.listProgressRename !== this.props.responseProgressBarRename.listProgressRename && (this.props.responseProgressBarRename.listProgressRename && this.props.responseProgressBarRename.listProgressRename.length > 0)) {
      if (this.props.responseProgressBarRename.listBox && this.props.responseProgressBarRename.listBox.length > 0) {
        this.setState({
          files: this.props.responseProgressBarRename.listBox
        })
      }
    }
    if (prevProps.responseProgressBarRename.listProgressRename !== this.props.responseProgressBarRename.listProgressRename && (this.props.responseProgressBarRename.listProgressRename && this.props.responseProgressBarRename.statusRename && this.props.responseProgressBarRename.listProgressRename.length === 0)) {
      if (this.props.responseProgressBarRename.listBox && this.props.responseProgressBarRename.listBox.length > 0) {
        if (this.props.responseProgressBarRename.statusRename) {
          this.fetchFiles()
          this.setState({
            files: this.props.responseProgressBarRename.listBox
          })
          this.props.setResponseProgressBarRename([], prevState.files, false)
        }
      }
    }
    if (prevProps !== this.props) {
      const openLoadDictionary = cookie.load('openDictionaryEdit')
      if (openLoadDictionary === 'openTrueDict') {
        this.props.navigate('/smart-box/business-term-lists/settings')
      }
      cookie.remove('openDictionaryEdit', { path: '/' })
    }
    if (prevProps !== this.props) {
      const openLoadDictionary = cookie.load('openRegularEdit')
      if (openLoadDictionary === 'openTrueRegEx') {
        this.props.navigate('/smart-box/regular-expressions/settings')
      }
      cookie.remove('openRegularEdit', { path: '/' })
    }
    if(prevProps.smartBoxHomeClicked !== this.props.smartBoxHomeClicked && this.props.smartBoxHomeClicked){
      this.fetchFiles()
      this.props.setSmartBoxHomeClicked(false)
    }
  }
  
  toggleFilePreview = (open, selectedDoc) => {
    const selectedFolderInfo = []
    selectedFolderInfo.push(selectedDoc.id)
    if (open) {
      this.setState({ sidebarClass: 'sidebar-class', contentClass: 'small-content', sidebarOpen: open, selectedDoc, listKeysFiles: selectedFolderInfo, listKeysFilesDeleted: selectedFolderInfo })
    } else {
      this.props.setSmartBoxSelectedKeys([])
      this.setState({ sidebarClass: '', contentClass: '', sidebarOpen: open, listKeysFiles: [], listKeysFilesDeleted: [] })
    }
  }

  fetchFiles = () => {
    const { currentPage, documentsStartNr } = this.state
    const { smartBoxPageLimit } = this.props
    let { setProgressBarBulkData } = this.state
    if(typeof this.timer !== 'undefined') {
      clearInterval(this.timer)
    }
    if(typeof this.timerBulk !== 'undefined') {
      clearInterval(this.timerBulk)
    }
    this.setState({
      loadingBoxPage: true,
      testAfterRename: false,
      statusPagination: null
    }, () => {
      const parameters = {
        documentsFolderPath: null,
        pageNumber: currentPage,
        resultsPerPage: smartBoxPageLimit,
        sort: this.props.smartBoxCurrentSortOption,
        folderTree: false,
        documentsStartNr: documentsStartNr,
        statusFilters: this.props.currentNewFilters.checkedStatus ? this.props.currentNewFilters.checkedStatus : [],
        tagFilters: this.props.currentNewFilters.checkedTag ? this.props.currentNewFilters.checkedTag : [],
      }
      getSmartBoxFilesPerPage(parameters).then((response) => {
        if (response.status === 200) {
          if (response.data.files) {
            for (let i = 0; i < response.data.files.length; i++) {
              response.data.files[i].root = true
            }
            this.setState({
              files: response.data.files,
              listKeysFiles: [],
              listKeysFilesDeleted: [],
              checkKeysFiles: false,
              totalPagesForPagination: response.data.total,
              loadingBoxPage: false,
              totalFolderOnRoot: response.data.totalFolders,
              documentsStartNr: response.data.documentsStartNr,
              statusPagination: response.status
            }, () => {
              this.getProgressBarInformation()
              this.getProgressRenameInformation()
              this.startIntervalBulkProgress()
              this.props.setSmartBoxFolderHasBulk(false)
            })
            this.props.setSmartBoxFilesTable(response.data.files)
          }
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    })
  }

  onGetShowExistsFilesWarningsNotification = (response) => {
    const filesWarnings = []
    response && response.length > 0 && response.forEach((item) => {
          if (item.show_exists_files_with_warnings_notification === 'true') {
            filesWarnings.push({
              nameActionEvent: item.boxName,
              descriptionActionEvent: `Warning, errors have been detected in one or more of the original PDF files.`,
              timesEvent: moment().format('DD/MM/YYYY HH:mm:ss'),
              status: 'file-warning',
              nameBox: item.boxName,
              boxId: item.id,
            })
          }
    })
    this.props.setSmartBoxExistsFilesWarningsNotification(filesWarnings)
  }

  fetchFilesWithoutRefresh = () => {
    const { currentPage, documentsStartNr } = this.state
    const { smartBoxPageLimit } = this.props
    let { setProgressBarBulkData } = this.state
    if(typeof this.timer !== 'undefined') {
      clearInterval(this.timer)
    }
    if(typeof this.timerBulk !== 'undefined') {
      clearInterval(this.timerBulk)
    }
    this.setState({
      testAfterRename: false,
      statusPagination: null
    }, () => {
      const parameters = {
        documentsFolderPath: null,
        pageNumber: currentPage,
        resultsPerPage: smartBoxPageLimit,
        sort: this.props.smartBoxCurrentSortOption,
        folderTree: false,
        documentsStartNr: documentsStartNr,
        statusFilters: this.props.currentNewFilters.checkedStatus ? this.props.currentNewFilters.checkedStatus : [],
        tagFilters: this.props.currentNewFilters.checkedTag ? this.props.currentNewFilters.checkedTag : [],
      }
      getSmartBoxFilesPerPage(parameters).then((response) => {
        if (response.status === 200) {
          if (response.data.files) {
            for (let i = 0; i < response.data.files.length; i++) {
              response.data.files[i].root = true
            }
            this.setState({
              files: response.data.files,
              listKeysFiles: [],
              listKeysFilesDeleted: [],
              checkKeysFiles: false,
              totalPagesForPagination: response.data.total,
              loadingBoxPage: false,
              totalFolderOnRoot: response.data.totalFolders,
              documentsStartNr: response.data.documentsStartNr,
              statusPagination: response.status
            }, () => {
              this.getProgressBarInformation()
              this.getProgressRenameInformation()
              this.startIntervalBulkProgress()
              this.props.setSmartBoxFolderHasBulk(false)
            })
            this.props.setSmartBoxFilesTable(response.data.files)
          }
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    })
  }


  fetchFilesForProgress = () => {
    const { currentPage, documentsStartNr } = this.state
    const { smartBoxPageLimit } = this.props
    let { setProgressBarBulkData } = this.state
    if(typeof this.timer !== 'undefined') {
      clearInterval(this.timer)
    }
    if(typeof this.timerBulk !== 'undefined') {
      clearInterval(this.timerBulk)
    }
    this.setState({
      loadingBoxPage: true,
      testAfterRename: false
    }, () => {
      const parameters = {
        documentsFolderPath: null,
        pageNumber: currentPage,
        resultsPerPage: smartBoxPageLimit,
        sort: this.props.smartBoxCurrentSortOption,
        folderTree: false,
        documentsStartNr: documentsStartNr,
        statusFilters: this.props.currentNewFilters.checkedStatus ? this.props.currentNewFilters.checkedStatus : [],
        tagFilters: this.props.currentNewFilters.checkedTag ? this.props.currentNewFilters.checkedTag : [],
      }
      getSmartBoxFilesPerPage(parameters).then((response) => {
        if (response.status === 200) {
          if (response.data.files) {
            for (let i = 0; i < response.data.files.length; i++) {
              response.data.files[i].root = true
            }
            this.props.setSmartBoxSelectedKeys([])
            this.setState({
              files: response.data.files,
              listKeysFiles: [],
              listKeysFilesDeleted: [],
              checkKeysFiles: false,
              totalPagesForPagination: response.data.total,
              loadingBoxPage: false,
              totalFolderOnRoot: response.data.totalFolders,
              documentsStartNr: response.data.documentsStartNr,
            }, () => {
              this.getProgressRenameInformation()
              this.startIntervalBulkProgress()
              this.props.setSmartBoxFolderHasBulk(false)
            })
            this.props.setSmartBoxFilesTable(response.data.files)
          }
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    })
  }
  retrieveFilesCountOfFolders = () => {
    const { files, countFilesTimer } = this.state
    const pathFiles = []
    if(files && files.length > 0) {
      files.forEach((item) => {
        if (item.path) {
          pathFiles.push(item.path)
        }
      })
      const folderCountFilesDTO = {
        pathList: pathFiles
      }
      if (document.visibilityState === 'visible') {
        getFolderCountFiles(folderCountFilesDTO).then((res) => {
          this.setState({
            statusFolderCountFiles: res.status
          })
          if (res.data.length) {
            this.props.setSmartBoxFolderCountTree(res.data)
            cookie.save('countFiles', res.data, {path: '/'})
            let countAr = this.state.foldersCountFiles
            files && files.length > 0 && files.forEach((file) => {
              res.data && res.data.length > 0 && res.data.forEach((el) => {
                if (file.path === el.path) {
                  file.numberOfFiles = el.numberOfFiles
                }
              })
            })
            res.data.forEach(item => {
              let found = false
              for (let i = 0; i < countAr.length; i++) {
                if (countAr[i].path === item.path) {
                  countAr[i].numberOfFiles = item.numberOfFiles
                  found = true
                  break
                }
              }
              if (!found) {
                countAr.push(item)
              }
            })
            this.props.setSmartBoxFolderCountTree(countAr)
            this.setState({foldersCountFiles: countAr})
          }
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          } else if (error?.response?.status === 403) {
            CustomNotificationManager.cleanBuffer('warning', '')
          } else {
            console.log(error)
          }
        })
      }
    } else if(countFilesTimer > 0) {
      clearInterval(countFilesTimer)
      this.setState({ countFilesTimer : 0 })
    }
  }

  refreshFilesCountOfFolders = () => {
    const { files, countFilesTimer } = this.state
    const pathFiles = []
    if(files && files.length > 0) {
      files.forEach((item) => {
        if (item.path) {
          pathFiles.push(item.path)
        }
      })
      const folderCountFilesDTO = {
        pathList: pathFiles
      }
      if (document.visibilityState === 'visible') {
        getFolderCountFiles(folderCountFilesDTO).then((res) => {
          if (res.data.length) {
            this.props.setSmartBoxFolderCountTree(res.data)
            cookie.save('countFiles', res.data, {path: '/'})
            let countAr = this.state.foldersCountFiles
            files && files.length > 0 && files.forEach((file) => {
              res.data && res.data.length > 0 && res.data.forEach((el) => {
                if (file.path === el.path) {
                  file.numberOfFiles = el.numberOfFiles
                }
              })
            })
            res.data.forEach(item => {
              let found = false
              for (let i = 0; i < countAr.length; i++) {
                if (countAr[i].path === item.path) {
                  countAr[i].numberOfFiles = item.numberOfFiles
                  found = true
                  break
                }
              }
              if (!found) {
                countAr.push(item)
              }
            })
            this.props.setSmartBoxFolderCountTree(countAr)
            this.setState({foldersCountFiles: countAr})
          }
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          } else if (error?.response?.status === 403) {
            CustomNotificationManager.cleanBuffer('warning', '')
          } else {
            console.log(error)
          }
        })
      }
    } else if(countFilesTimer > 0) {
      clearInterval(countFilesTimer)
      this.setState({ countFilesTimer : 0 })
    }
  }
  getListFoldersCheckErrors = () => {
    //const { files, countFilesTimer } = this.state
    // if(files.length > 0) {
    //   getCheckFolderErrors(files).then((response) => {
    //     if (response.data.length) {
    //       const errorsCheck = this.state.foldersCheckErrors
    //       response.data.forEach((item) => {
    //         let found = false
    //         for (let i=0; i<errorsCheck.length; i++) {
    //           if (errorsCheck[i].path === item.path) {
    //             errorsCheck[i].folderHasErrors = item.folderHasErrors
    //             found = true
    //             break
    //           }
    //         }
    //         if(!found) {
    //           errorsCheck.push(item)
    //         }
    //       })
    //       this.setState({ foldersCheckErrors: errorsCheck })
    //     }
    //   })
    // } else if (countFilesTimer > 0) {
    //   clearInterval(countFilesTimer)
    //   this.setState({ countFilesTimer: 0 })
    // }
  }
  getFilesErrors = (item) => {
    const { foldersCheckErrors, files } = this.state

    if(files.length > 0) {
      const match = foldersCheckErrors.filter((el) => el.path === item.path)
      if (match && match.length > 0) {
        return match[0].folderHasErrors
      }
    }
    return 0
  }
  onCallProgressRename = () => {
    const { testAfterRename } = this.state
     this.getProgressRenameInformation()
    this.setState({
      testAfterRename: true
    }, () => {
      if (testAfterRename) {
        this.fetchFiles()
        this.setState({
          testAfterRename: false
        })
      }
    })
    //this.fetchFiles()

  }
  getProgressRenameInformation = () => {
    const { files } = this.state
    const totalDocumentsIdRename = []
    if (files && files.length > 0) {
      files.forEach((item) => {
          if (item.type === 'DIRECTORY' && item.totalDocNrRename !== null && item.totalDocNrRename > 0) {
            totalDocumentsIdRename.push({ folderPath: item.path, id: item.id })
          }
      })
    }
    if (this.props.responseProgressBarRename.listProgressRename && this.props.responseProgressBarRename.listProgressRename.length > 0) {
      return true
    } else  {
      if (totalDocumentsIdRename && totalDocumentsIdRename.length > 0) {
        const progressBarDataDTO = {
          boxFolders: totalDocumentsIdRename
        }
        getProgressRename(progressBarDataDTO).then((response) => {
          if (response.data && response.data.length > 0) {
            files.forEach((item, index) => {
              const value = this.searchInArrayOfObjectRename(item.id, response.data)
              if (value) {
                files[index].progressRename = {
                  nrOfDocRenamed: value.nrOfDocRenamed,
                  id: value.id,
                  total: value.total,
                  folderPath: value.folderPath,
                }
              }
              const newFiles = JSON.parse(JSON.stringify(files))
              this.props.setResponseProgressBarRename(response.data, newFiles, false)
              this.setState({
                files: newFiles,
              })
            })
            this.setState({
              filesProgressRename: response.data
            }, () => {
              this.timerRename = setInterval(() => {
                    this.setIntervalProgressRename()
                  }
                  ,5000)
            })
          } else if (response.data && response.data.length === 0) {
            this.props.setResponseProgressBarRename([], files, false)
          } else {
            this.props.setResponseProgressBarRename([], files, true)
          }
          // if (response.data && response.data.length === 0) {
          //   setTimeout(() => {
          //     this.fetchFiles()
          //   }, 1000)
          // }
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
        })
      }
    }

  }
  setIntervalProgressRename = () => {
    const { files, filesProgressRename } = this.state
    const totalDocumentsIdRename = []
    if (files && files.length > 0) {
      files.forEach((item) => {

        if (item.type === 'DIRECTORY' && item.totalDocNrRename !== null && item.totalDocNrRename > 0) {
          totalDocumentsIdRename.push({ folderPath: item.path, id: item.id })
        }
      })
    }
    if (totalDocumentsIdRename && totalDocumentsIdRename.length > 0) {
      if (filesProgressRename && filesProgressRename.length > 0) {
        const progressBarDataDTO = {
          boxFolders: totalDocumentsIdRename
        }
        getProgressRename(progressBarDataDTO).then((response) => {
          this.setState({
            filesProgressRename: response.data
          })
          if (response.data && response.data.length > 0) {
            files.forEach((item, index) => {

              const value = this.searchInArrayOfObjectRename(item.id, response.data)
              if (value) {
                files[index].progressRename = {
                  nrOfDocRenamed: value.nrOfDocRenamed,
                  id: value.id,
                  total: value.total,
                  folderPath: value.folderPath,
                }
              }
              const newFiles = JSON.parse(JSON.stringify(files))
              this.props.setResponseProgressBarRename(response.data, newFiles, false)
              this.setState({
                files: newFiles,
              })
            })
          } else {
            this.props.setResponseProgressBarRename([], files, true)
            files.forEach((item, index) => {
                files[index].progressRename = {
                  nrOfDocRenamed: 0,
                  id: '',
                  total: 0,
                  folderPath: '',
                }
              const newFiles = JSON.parse(JSON.stringify(files))
              this.setState({
                files: newFiles,
              })
              clearTimeout(this.timerRename)
              clearInterval(this.timerRename)
            })
            //this.fetchFiles()
          }
          // if (response.data && response.data.length === 0) {
          //   setTimeout(() => {
          //     this.fetchFiles()
          //   }, 1000)
          // }
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
        })
      } else  {
        clearTimeout(this.timerRename)
        clearInterval(this.timerRename)
      }
    }
  }
  searchInArrayOfObjectRename = (id, data) => {
    let response = null
    if (data.length) {
      data.forEach((value) => {
        if (String(value.id) === String(id)) {
          response = value
        }
      })
    }
    return response
  }
  getProgressBarInformation = () => {
    const { files } = this.state
    let { foldersInAIProcess, countFilesTimer } = this.state
    const listOfBoxes = []
    const filesInBulk = []
    if (files && files.length) {
      files.forEach((item) => {
        if (item.path && item.id) {
          if (item.type.toUpperCase() === 'DIRECTORY') {
            listOfBoxes.push({folderPath: item.path, id: item.id})
            if (item.bulkProcessingStarted === 'started') {
              filesInBulk.push({folderPath: item.path, id: item.id})
            }
          }
        }
      })
    } else {
      this.setState({ loadingBoxPage: false })
    }
    if(countFilesTimer === 0) {
      this.retrieveFilesCountOfFolders()
      this.getListFoldersCheckErrors()
      countFilesTimer = setInterval(this.retrieveFilesCountOfFolders, 10000)
      this.setState({ countFilesTimer })
    }
    if (listOfBoxes.length) {
      const progressBarDataDTO = {
        boxFolders: listOfBoxes
      }
      if (document.visibilityState === 'visible') {
        getProgressBarData(progressBarDataDTO).then((response) => {
          if (response.status === 200) {
            if (response.data.length) {
              files.forEach((item, index) => {
                const value = this.searchInArrayOfObject(item.id, response.data)
                if (value) {
                  foldersInAIProcess++
                  files[index].progressBar = {
                    extractedForAI: value.extractedForAI,
                    failedForAI: value.failedForAI,
                    processedForAI: value.processedForAI,
                    doc_extraction: value.doc_extraction,
                    nrOfDocuments: value.nrOfDocuments,
                    analysed: value.analysed,
                    processed: value.processed,
                    generatingReport: value.generatingReport,
                    generatingReportRedaction: value.generatingReportRedaction,
                    progressBarClutter: value.progressBarClutter,
                    progressBarGeneratingDuplicates: value.progressBarGeneratingDuplicates,
                  }
                  // files[index].progressBar = null
                }
              })
              const newFiles = JSON.parse(JSON.stringify(files))
              this.setState({files: newFiles, foldersInAIProcess, loadingBoxPage: false}, () => {
                this.startIntervalAiProgress()
              })
            } else {
              this.setState({loadingBoxPage: false})
            }
            if (response.data && response.data.length > 0) {
              this.onGetShowExistsFilesWarningsNotification(response.data)
            }
            this.setState({
              checkContentProgressBar: response.data,
              statusProgressBarData: response.status,
            })
          }
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          } else {
            console.log(error)
          }
        })
      }
    } else {
      this.setState({ loadingBoxPage: false })
    }
    if (filesInBulk.length) {
      this.callApiProgressbarBulk(filesInBulk)
    }
  }
  onCheckRefreshProgressBar = (statusRefresh) => {
    this.setState({
      sendStatusProgressOnRefresh: false
    })
    setTimeout(() => {
      this.setState({
        sendStatusProgressOnRefresh: statusRefresh
      })
    }, 10000)
  }
  startIntervalAiProgress = () => {
    const { currentUrl } = { ...this.state }
    let { foldersInAIProcess } = { ...this.state }
    if (foldersInAIProcess && currentUrl === window.location.href) {
      this.timer = setInterval(() => {
        const { files } = this.state
        if (currentUrl === window.location.href) {
          const filesInAi = []
          if (files?.length) {
            files.forEach((item) => {
              if (item.progressBar && item.path && item.id) {
                if (item.progressBar.extractedForAI !== item.progressBar.processedForAI + item.progressBar.failedForAI) {
                  filesInAi.push({ folderPath: item.path, id: item.id })
                }
              }
            })
          }
          foldersInAIProcess = filesInAi.length
          const newFiles = []
          if (files.length) {
            files.forEach((val) => {
              newFiles.push(val)
            })
          }
          if (filesInAi.length) {
            const progressBarDataDTO = {
              boxFolders: filesInAi
            }
            if (document.visibilityState === 'visible') {
              getProgressBarData(progressBarDataDTO).then((response) => {
                const listProgress = this.state.responseProgressBarFiles.concat(response.data)
                this.setState({
                  responseProgressBarFiles: listProgress
                })
                //const uniqueListProgress = this.state.responseProgressBarFiles && this.state.responseProgressBarFiles.length > 0 && this.state.responseProgressBarFiles.filter((a, i) => this.state.responseProgressBarFiles.findIndex((s) => a.folderPath === s.folderPath) === i)
                if (this.state.checkContentProgressBar.length > 0 && response.data.length === 0) {
                  setTimeout(() => {
                    this.fetchFilesForProgress()
                    this.onGetNotificationAnalysisComplete(filesInAi, files)
                  }, 5000)
                } else if (response.data.length > 0) {
                  this.onGetNotificationAnalysisAllFiles(this.state.responseProgressBarFiles, filesInAi, files)
                }
                if (response.status === 200) {
                  files.forEach((item, index) => {
                    const value = this.searchInArrayOfObject(item.id, response.data)
                    if (value) {
                      // if (String(item.id) === String(value.id)) {
                      files[index].progressBar = {
                        extractedForAI: value.extractedForAI,
                        failedForAI: value.failedForAI,
                        // processedForAI: this.state.counterRandom
                        processedForAI: value.processedForAI,
                        doc_extraction: value.doc_extraction,
                        nrOfDocuments: value.nrOfDocuments,
                        analysed: value.analysed,
                        processed: value.processed,
                        statisticsStatus: value.statisticsStatus,
                        generatingReport: value.generatingReport,
                        generatingReportRedaction: value.generatingReportRedaction,
                        progressBarClutter: value.progressBarClutter,
                        progressBarGeneratingDuplicates: value.progressBarGeneratingDuplicates,
                      }
                      // files[index].progressBar = null
                    } else {
                      files[index].progressBar = null
                      delete files[index].progressBar
                    }
                  })
                  const newFiles = JSON.parse(JSON.stringify(files))
                  this.setState({
                    files: newFiles,
                    foldersInAIProcess,
                    refreshChildren: true,
                    // counterRandom: this.state.counterRandom + 1
                  })
                  if (response.data && response.data.length > 0) {
                    this.onGetShowExistsFilesWarningsNotification(response.data)
                  }
                  this.setState({
                    checkContentProgressBar: response.data,
                    statusProgressBarData: response.status,
                  })
                }
              }).catch((error) => {
                if (error?.response?.status === 504) {
                  CustomNotificationManager.cleanBuffer('warning', '')
                } else {
                  console.log(error)
                }
              })
            }
          } else {
            clearInterval(this.timer)
          }
        } else {
          clearInterval(this.timer)
        }
      }, 10000)
    } else {
      clearInterval(this.timer)
    }
  }
  onGetNotificationAnalysisAllFiles = (responseProgressBarFiles, filesInAi, filesAll) => {
    const { files } = this.state
    let responseAnalysis = []
    let addAnalysis = []
    let analysisComplete = []
    let filesInAiIds = []
    filesInAi && filesInAi.length > 0 && filesInAi.forEach((fil) => {
      filesInAiIds.push(fil.id)
    })
    let returnStatus = false
      files && files.length > 0 && files.forEach((file) => {
        if (this.state.responseProgressBarFiles.length) {
          this.state.responseProgressBarFiles.forEach((value) => {
            if (String(value.id) === String(file.id) && file.analyticsReady === 'false' && !filesInAiIds.includes(file.id)) {
              responseAnalysis.push(file)
              returnStatus = true
                analysisComplete.push({
                  nameActionEvent: 'Analysis complete',
                  descriptionActionEvent: `${ file.name }`,
                  timesEvent: moment().format('DD/MM/YYYY HH:mm:ss'),
                  status: 'box',
                  documentName: file.name
                })
              addAnalysis = analysisComplete.concat(this.props.smartBoxNotAnalysisComplete)
              const uniqueBoxAnalysis = addAnalysis && addAnalysis.length > 0 && addAnalysis.filter((a, i) => addAnalysis.findIndex((s) => a.documentName === s.documentName) === i)
              this.props.setSmartBoxNotAnalysisComplete(uniqueBoxAnalysis)
            }
          })
        }
      })
    if (returnStatus) {
      this.fetchFiles()
    }
  }
  onGetNotificationAnalysisComplete = (responseProgressBarFiles, files) => {
    let responseAnalysis = []
    let addAnalysis = []
    let analysisComplete = []
    files && files.length > 0 && files.forEach((file) => {
      if (responseProgressBarFiles.length) {
        responseProgressBarFiles.forEach((value) => {
          if (String(value.id) === String(file.id)) {
            responseAnalysis.push(file)
          }
        })
      }
    })

    responseAnalysis && responseAnalysis.length > 0 && responseAnalysis.forEach((resp) => {
      analysisComplete.push({
        nameActionEvent: 'Analysis complete',
        descriptionActionEvent: `${ resp.name }`,
        timesEvent: moment().format('DD/MM/YYYY HH:mm:ss'),
        status: 'box',
        documentName: resp.name
      })
    })
    addAnalysis = analysisComplete.concat(this.props.smartBoxNotAnalysisComplete)
    const uniqueBoxAnalysis = addAnalysis && addAnalysis.length > 0 && addAnalysis.filter((a, i) => addAnalysis.findIndex((s) => a.documentName === s.documentName) === i)
    this.props.setSmartBoxNotAnalysisComplete(uniqueBoxAnalysis)
  }
  searchInArrayOfObject= (id, data) => {
    let response = null
    if (data.length) {
      data.forEach((value) => {
        if (String(value.id) === String(id)) {
          response = value
        }
      })
    }
    return response
  }

  startIntervalBulkProgress = () => {
    this.timerBulk = setInterval(() => {
      const { files } = this.state
      const filesInBulk = []
      if (files?.length) {
        files.forEach((item) => {
          if (item.bulkProcessingStarted === 'started') {
            filesInBulk.push({ folderPath: item.path, id: item.id })
          }
        })
      }
      const itemProgressBarBulkData = cookie.load('checkProgressBarBulkData', { path: '/' })
      if (itemProgressBarBulkData === undefined) {
        clearInterval(this.timerBulk)
      }
      if (filesInBulk.length) {
        this.callApiProgressbarBulk(filesInBulk)
      } else {
        clearInterval(this.timerBulk)
      }
    }, 10000)
  }
  callApiProgressbarBulk = (input) => {
    const {files, progressBulkData, setProgressBarBulkData,responseProgressBarBulkData} = this.state
    if (progressBulkData) {
      const progressBarDataDTO = {
        boxFolders: input
      }
      const itemProgressBarBulkData = cookie.load('checkProgressBarBulkData', { path: '/' })
      if (document.visibilityState === 'visible') {
      if (itemProgressBarBulkData === 'getBarBulkData') {
        getProgressBarDataForBulk(progressBarDataDTO).then((response) => {
          // response = {
          //   status: 200,
          //   data: [{
          //     id: '1607066008696',
          //     bulkFinished: this.state.counterRandom,
          //     bulkStarted: 3- this.state.counterRandom,
          //     bulkTotal: 3,
          //     folderPath: './../'
          //   }]
          // }
          if (response.status === 200) {
            if (responseProgressBarBulkData && responseProgressBarBulkData.length > 0 && response.data && response.data.length > 0 && (responseProgressBarBulkData.length > response.data.length)) {
              //this.fetchFiles()
              this.fetchFilesWithoutRefresh()
            }
            if (responseProgressBarBulkData && responseProgressBarBulkData.length === 1 && response.data && response.data.length === 1 && (responseProgressBarBulkData[0].id !== response.data[0].id)) {
              //
              // this.fetchFiles()
              this.fetchFilesWithoutRefresh()
            }
            this.setState({
              responseProgressBarBulkData: response.data
            })
            if (!response.data.length) {
              this.setState({
                progressBulkData: false
              })
              // setTimeout(() => {
              //   this.fetchFiles()
              // }, 6000)
              clearInterval(this.timerBulk)
              clearInterval(setProgressBarBulkData)
            }
            files.forEach((file, index) => {
              const value = this.searchInArrayOfObject(file.id, response.data)
              if (value) {
                files[index].progressBarForBulk = {
                  bulkTotal: value.bulkTotal,
                  bulkFailedForAI: 0,
                  bulkFinished: value.bulkFinished,
                  bulkStarted: value.bulkStarted,
                  bulkProcessingStarted: value.bulkFinished >= value.bulkTotal ? 'finished' : 'started',
                  doc_extraction: value.doc_extraction

                }
                if (files[index].bulkProcessingStarted !== 'started') {
                  clearInterval(this.timerBulk)
                  clearInterval(setProgressBarBulkData)
                }
              } else {
                files[index].progressBarForBulk = null
                //files[index].bulkProcessingStarted = 'finished'
                delete files[index].progressBarForBulk
              }
            })
            const newFiles = JSON.parse(JSON.stringify(files))
            this.setState({files: newFiles}, () => {
              if (!response.data.length) {
                this.setState({
                  progressBulkData: false
                })
                setTimeout(() => {
                  //this.fetchFiles()
                  this.fetchFilesWithoutRefresh()
                }, 5000)
                files.forEach((file, index) => {
                  const value = this.searchInArrayOfObject(file.id, response.data)
                  if (value) {
                    files[index].progressBarForBulk = {
                      bulkTotal: value.bulkTotal,
                      bulkFailedForAI: 0,
                      bulkFinished: value.bulkFinished,
                      bulkStarted: value.bulkStarted,
                      bulkProcessingStarted: value.bulkFinished >= value.bulkTotal ? 'finished' : 'started',
                      doc_extraction: value.doc_extraction
                    }
                    if (files[index].bulkProcessingStarted !== 'started') {
                      clearInterval(this.timerBulk)
                      clearInterval(setProgressBarBulkData)
                    }
                  } else {
                    files[index].progressBarForBulk = null
                    delete files[index].progressBarForBulk
                  }
                })
                const newFiles = JSON.parse(JSON.stringify(files))
                this.setState({
                  files: newFiles,
                })
                clearInterval(this.timerBulk)
                clearInterval(setProgressBarBulkData)
              }
            })
          }
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          } else {
            console.log(error)
          }
        })
      }
    }
  }
    // this.setState({counterRandom : this.state.counterRandom + 1})
  }

  fetchFilesForSearch = () => {
    if (this.state.searchText) {
      const initFilter = {
        'docFilename': this.state.searchText.trim(),
        'docFolderPath': null
      }
      getSmartBoxFilesFilterForSearch(initFilter).then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].root = true
        }
        this.setState({
          files: res.data,
        })
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    }
  }

  toggleNoteVisible = (open) => {
    this.setState({ documentNoteVisible: open })
  }

  onFilePressed = (file) => {
    if (file.isPdf === 'true') {
      file.totalPages > 20 ? this.handlePreviewClicked(file) : this.handlePreview(file)
    }
  }

  onChange = () => this.setState({ someValue: null })

  handlePreviewClicked(file) {
    swal.fire({
      text: `This file has ${ file.totalPages } pages. Are you sure you want to open in Smartview ?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3DD8DB',
      cancelButtonColor: '#D33D33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        this.handlePreview(file)
      } else {
        CustomNotificationManager.info('Action cancelled', 'Aborted')
      }
    })
  }

  handlePreview = (file) => {
    this.props.setHeaderVisibility(false)
    this.props.setSidebarVisibility(false)
    this.props.setFooterVisibility(false)
    this.setState({ loading: true }, () => {
      apiSmartBoxPreviewSarDocByThumbnailId(file.id).then((response) => {
        const { doc, id, totalPages, landscape, width, height, name, systemPropertyFileStatus, doc_classification, visibleToDownload, xeroxUpload,
          lockSmartViewChanges } = response.data
        const spliterPosition = name.indexOf('-') + 1
        this.setState({
          documentBytes: doc,
          documentId: id,
          totalPages: totalPages,
          landscape,
          width,
          height,
          currentFileId: file.id,
          initialName: name.substr(spliterPosition),
          smartBoxDocumentId: `${ file.folderPath + file.path }${ file.path.includes('.') ? '' : `.${ file.type }` }`,
          loading: false,
          systemPropertyFileStatus,
          doc_classification,
          visibleToDownload,
          //xeroxUpload: xeroxUpload !== 'false',
          xeroxUpload: false,
          lockSmartViewChanges
        })
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    })
  }

  handlePreviewSmartBox = (file) => {
    this.props.setHeaderVisibility(false)
    this.props.setSidebarVisibility(false)
    this.props.setFooterVisibility(false)
    this.setState({ loading: true }, () => {
      apiSmartBoxPreviewSarDocByThumbnailId(file.id).then((response) => {
        const { doc, id, totalPages, landscape, width, height, name, finalizedDocPath, systemPropertyFileStatus, doc_classification, visibleToDownload,
          xeroxUpload, lockSmartViewChanges } = response.data
        const spliterPosition = name.indexOf('-') + 1
        this.setState({
          documentBytes: doc,
          documentId: id,
          totalPages: totalPages,
          landscape,
          width: width,
          height: height,
          currentFileId: file.id,
          initialName: name.substr(spliterPosition),
          smartBoxDocumentId: `${ file.folderPath + file.name }${ file.name.includes('.') ? '' : `.${ file.type }` }`,
          previewVisible: true,
          showCloseButton: false,
          loading: false,
          isOcr: file.isOcr !== 'false',
          isSmartBoxApplication: true,
          finalizedDocPath,
          systemPropertyFileStatus,
          doc_classification,
          visibleToDownload,
          //xeroxUpload: xeroxUpload !== 'false',
          xeroxUpload: false,
          lockSmartViewChanges
        })
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    })
  }

  closeRedacting = (obj) => {
    this.props.setHeaderVisibility(true)
    this.props.setSidebarVisibility(true)
    this.props.setFooterVisibility(true)
    this.setState({
      documentBytes: null,
      searchText: this.props.smartBoxSearchedText
    }, () => window.URL.revokeObjectURL(obj))
  }

  customUploadFolder = (e) => {
    const newFileList = {}
    if (e.target.files) {
      if (Object.keys(e.target.files).length) {
        Object.keys(e.target.files).forEach((item, index) => {
          const file = e.target.files[index]
          const extension = file.name.split('.').pop().toLowerCase()
          const newWebkitRelativePath = `${ file.webkitRelativePath.substr(0, file.webkitRelativePath.lastIndexOf('.')) }.${ extension }`
          const blob = file.slice(0, file.size, file.type)
          const newFile = new File([blob], newWebkitRelativePath, {
            type: file.type,
            webkitRelativePath: newWebkitRelativePath,
            lastModified: file.lastModified
          })
          newFileList[index] = newFile
        })
      }
      this.worker.postMessage(Object.values(newFileList))
    }
  };

  adjustFilesTable(files) {
    if (files && files.length) {
      for (let i = 0; i < files.length; i++) {
        files[i].key = files[i].id
      }
      return files
    }
    
    return []
    
  }

  handleNewFolderClick = () => {
    this.setState({
      startSettingsNewBox: true
    })
    this.setState({ newFolderClicked: true })
  }

  isUnique = (newFileName) => {
    const { files } = this.state
    let response = true
    if (files && files.length) {
      files.forEach((item) => {
        if (item.name === newFileName) {
          response = false
        }
      })
    }
    return response
  }

  getFilesCount = (item) => {
    const { foldersCountFiles, files } = this.state
    files && files.length > 0 && files.forEach((file) => {
      foldersCountFiles && foldersCountFiles.length > 0 && foldersCountFiles.forEach((el) => {
        if (file.path === el.path) {
          file.numberOfFiles = el.numberOfFiles
        }
      })
    })
    if(files.length > 0) {
      const match = foldersCountFiles.filter(el => el.path === item.path)
      if (match && match.length > 0) {
        return match[0].numberOfFiles
      }
    }
    return 0
  }

  handleNewFolderKeyPressed = () => {
    const { validNewFolderName, newAddBoxOnList, listNewBoxCreate, statusCreateBox } = this.state
    let { files } = this.state
    const nextId = (new Date()).getTime()
    let newFolderName = newAddBoxOnList || listNewBoxCreate[0]?.valueNewBox
    const newFolder = {
      'id': nextId,
      'key': nextId,
      'name': newFolderName,
      'type': 'DIRECTORY',
      'modified': moment().format('D.MM.YYYY hh:mm:ss'),
      'folderConfigurationType': listNewBoxCreate[0]?.valueConfigurationBox === 'advanced configuration' ? 'advanced configuration' : '',
      'listPII': listNewBoxCreate[0]?.checkedKeysPii ? listNewBoxCreate[0].checkedKeysPii : [],
      'listBusinessTerms': listNewBoxCreate[0]?.listNewBoxBusinessTerms ? listNewBoxCreate[0].listNewBoxBusinessTerms : [],
      'regexList': listNewBoxCreate[0]?.listNewBoxRegExp ? listNewBoxCreate[0].listNewBoxRegExp : [],
      'selectRegexList': listNewBoxCreate[0]?.selectedListRegExp ? listNewBoxCreate[0].selectedListRegExp : [],
      'regexColor': listNewBoxCreate[0]?.colorRegular ? listNewBoxCreate[0].colorRegular : '',
      'manualColor': listNewBoxCreate[0]?.colorTerms ? listNewBoxCreate[0].colorTerms : '',
      'manualList': listNewBoxCreate[0]?.manualTermsList ? listNewBoxCreate[0].manualTermsList : [],
      'selectManualList': listNewBoxCreate[0]?.selectedListManualTerms ? listNewBoxCreate[0].selectedListManualTerms : [],
      'sharingPermissionsInputNewDto': listNewBoxCreate[0]?.sharingPermissionsInputNewDto ? listNewBoxCreate[0].sharingPermissionsInputNewDto : {},
      'autoDeduping': listNewBoxCreate[0]?.sharingPermissionsInputNewDto ? listNewBoxCreate[0].sharingPermissionsInputNewDto.autoDeduping : '',
    }
    // const nameRgex = /^[^\\/?*:;[',\]|"<>.]+$/
    const nameRgex = /^[A-Za-z\d_\s]+$/
    if (nameRgex.test(newFolderName) && this.isUnique(newFolderName)) {
      validNewFolderName.success = true
      this.setState({
        disabledOnClicked: true,
        validNewFolderName,
        statusCreateBox: true,
      })
      newFolder.name ? newFolder.name = newFolder.name.trim() : newFolder.name = newFolder.name
      if(!newFolderName) return

      createSmartDocument(newFolder).then((res) => {
        if(res.data.title && res.data.title.toLowerCase() === 'success'){
          CustomNotificationManager.success(res.data.message, 'Success')
        }else if(res.data.title && res.data.title.toLowerCase() === 'info'){
          CustomNotificationManager.info(res.data.message, 'Info')
          this.setState({
            newFolderClicked: false,
            disabledOnClicked: false
          })
        } else {
          files = [res.data, ...files]
          this.setState({
            files,
            newFolderClicked: false,
            disabledOnClicked: false,
            statusCreateBox: true,
          })
          //this.fetchFiles()
          setTimeout(() => {
            this.fetchFiles()
          }, 200);
          this.props.setSmartBoxNewNode({
            id: res.data.id,
            name: newFolderName,
            path: res.data.path,
            parentId: null,
          })
        }
        // if (res.data && res.data !== 'Directory exists!') {
        //   files = [res.data, ...files]
        //   this.setState({
        //     files,
        //     newFolderClicked: false,
        //     disabledOnClicked: false,
        //     statusCreateBox: true,
        //   })
        //   //this.fetchFiles()
        //
        //   setTimeout(() => {
        //     this.fetchFiles()
        //   }, 200);
        //   this.props.setSmartBoxNewNode({
        //     id: res.data.id,
        //     name: newFolderName,
        //     path: res.data.path,
        //     parentId: null,
        //   })
        // } if (res.data === 'Directory exists!') {
        //   CustomNotificationManager.info('Already exists', 'Cancelled')
        //   this.setState({
        //     newFolderClicked: false,
        //     disabledOnClicked: false
        //   })
        // }
        this.setState({
          newAddBoxOnList: '',
          listNewBoxCreate: []
        })
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
        this.setState({
          newFolderClicked: false,
          disabledOnClicked: false
        }, () => {
          if (error?.response?.data?.message) {
            if (error.response.data.message.includes('This name already exists')) {
              CustomNotificationManager.warning('This name already exists', 'Already exist')
            }
          }
        })
      })
    }
    else {
      if (newFolderName && ! nameRgex.test(newFolderName)) {
        validNewFolderName.reason = 'Invalid name'
        CustomNotificationManager.info('Invalid name', 'Cancelled')
        this.setState({
          newAddBoxOnList: '',
          listNewBoxCreate: []
        })
      }
      if (newFolderName && ! this.isUnique(newFolderName)) {
        validNewFolderName.reason = 'Already exist'
        CustomNotificationManager.info('Already exists', 'Cancelled')
        this.setState({
          newAddBoxOnList: '',
          listNewBoxCreate: []
        })
      }
      validNewFolderName.success = false
      this.setState({ validNewFolderName })
    }
    // else {
    //   if (! nameRgex.test(e.target.value)) {
    //     validNewFolderName.reason = 'Invalid name'
    //   }
    //   if (! this.isUnique(e.target.value)) {
    //     validNewFolderName.reason = 'Already exist'
    //   }
    //   validNewFolderName.success = false
    //   this.setState({ validNewFolderName })
    // }
  }
  
  handleSearchInputChange = (e) => {
    const searchedText = e.target.value
    
    this.setState({
      searchText: searchedText
    }, () => {
      this.props.setSmartBoxSearchedWord(this.state.searchText)
      if (searchedText === '') {
        this.fetchFiles()
      }
    })
  }
  
  handleChangeViewMode = () => {
    this.props.setSmartBoxViewMode(this.props.smartBoxViewMode === 'grid' ? 'list' : 'grid')
    this.props.setSmartBoxSelectedKeys([])
    this.props.setBoxListViewSelectedKeys([])
    this.toggleFilePreview(false, {})
  }

  handleDeleteFile = (file) => {
    const { currentPage, totalPagesForPagination, documentsStartNr } = this.state
    const { smartBoxPageLimit } = this.props
    const remainingFilesOnLastPage = totalPagesForPagination % smartBoxPageLimit -1
    const parameters = {
      documentsFolderPath: null,
      pageNumber: currentPage,
      resultsPerPage: smartBoxPageLimit,
      sort: this.props.smartBoxCurrentSortOption,
      folderTree: false,
      documentsStartNr: documentsStartNr
    }
    getSmartBoxFilesPerPage(parameters).then((response) => {
      if (response.status === 200) {
        this.setState({ totalPagesForPagination: response.data.total },
          () => {
            if ((remainingFilesOnLastPage === 0) && (Math.ceil(response.data.total/smartBoxPageLimit) < currentPage)) {
              const data = {
                currentPage: Math.ceil(response.data.total/smartBoxPageLimit) ? Math.ceil(response.data.total/smartBoxPageLimit) : 1,
                pageLimit: smartBoxPageLimit,
                totalFolderOnRoot: response.data.totalFolders,
                documentsStartNr: response.data.documentsStartNr,
              }
              this.onPageChanged(data)
            } else {
              const data = {
                currentPage: currentPage,
                pageLimit: smartBoxPageLimit,
                totalFolderOnRoot: response.data.totalFolders
              }
              this.onPageChanged(data)
            }
          })
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
    this.setState({ files: deleteFolderOrFile(file, this.state.files) })
  }
  
  removeNewFolderFormView = () => this.setState({
    newFolderClicked: false,
    files: this.state.files.filter((file) => file.id !== 0)
  })
  
  fetchFilesOnSearch = (e) => {
    if (e.key === 'Enter') {
      this.fetchFilesForSearch()
    }
  }

  // Selects / Deselects Folders and Boxes
  addOrRemoveValue = (file) => {
    let newListKeysFiles = [ ...this.state.listKeysFiles ]
    let newListKeysFiles2 = [ ...this.state.listKeysFilesDeleted ]
    const fileId = file.id
    if (newListKeysFiles.includes(fileId)) {
      newListKeysFiles = newListKeysFiles.filter((key) => key !== fileId)
    } else {
      newListKeysFiles.push(fileId.toString())
    }
    if (newListKeysFiles2.includes(fileId)) {
      newListKeysFiles2 = newListKeysFiles2.filter((key) => key !== fileId)
    } else {
      newListKeysFiles2.push(fileId.toString())
    }
    this.setState({ listKeysFiles: newListKeysFiles, listKeysFilesDeleted: newListKeysFiles2 })
    this.props.setSmartBoxSelectedFiles( newListKeysFiles )
  }
  
  removeAllSelected = () => this.setState({ listKeysFiles: [], listKeysFilesDeleted: [] })
  
  /*onSelectAll = (e) => this.setState({
    listKeysFiles: e.target.checked ? this.state.files.map((file) => file.id) : [],
    checkKeysFiles: e.target.checked
  })*/

  onSelectAll = (selected) => {
    this.setState({
      listKeysFiles: selected ? this.state.files.map((file) => file.id) : [],
      listKeysFilesDeleted: selected ? this.state.files.map((file) => file.id) : [],
      checkKeysFiles: selected
    }, () => {
      this.props.setSmartBoxSelectedFiles(this.state.listKeysFiles)
    })
  }

  onPageChanged = (newData) => {
    const { countFilesTimer, documentsStartNr } = this.state
    clearInterval(this.timer)
    clearInterval(countFilesTimer)
    this.setState({ countFilesTimer : 0 })
    this.setState({
      loadingBoxPage: true,
      statusPagination: null,
      statusFolderCountFiles: null,
    }, () => {
      if (Object.keys(newData).length) {
        const parameters = {
          documentsFolderPath: null,
          pageNumber: newData.currentPage,
          resultsPerPage: newData.pageLimit,
          sort: newData.sort,
          filter: newData.filter,
          search: newData.search,
          statusFilters: newData.checkedStatus,
          tagFilters: newData.checkedTag,
          folderTree: false,
          documentsStartNr: documentsStartNr
        }
        getSmartBoxFilesPerPage(parameters).then((response) => {
          if (response.status === 200) {
            for (let i = 0; i < response.data.length; i++) {
              response.data.files[i].root = true
            }
            this.props.setSmartBoxSelectedKeys([])
            this.setState({
              currentPage: newData.currentPage,
              // amountPerPage: newData.pageLimit,
              files: response.data.files,
              listKeysFiles: [],
              listKeysFilesDeleted: [],
              checkKeysFiles: false,
              totalPagesForPagination: response.data.total,
              loadingBoxPage: false,
              totalFolderOnRoot: response.data.totalFolders,
              documentsStartNr: response.data.documentsStartNr,
              statusResponsePagination: true,
              statusPagination: response.status
            }, () => {
              this.getProgressBarInformation()
              this.getProgressRenameInformation()
            })
          }
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
        })
      }
    })
  }

  setRefWrapperNotification(callbacks) {
    this.refWrapperNotification = callbacks
  }

  setNewFiles = (files) => {
    this.setState({ files })
  }

  toggleCustomerStatusModalVisible = (open) => this.setState({ customerStatusModalVisible: open })

  handleAddEditCustomerStatus = () => {
    const { customStatusColor, customStatusText, selectedDoc, newCustomStatusNameValid } = this.state
    if (customStatusText) {
      const parameters = {
        filePath: selectedDoc.path,
        fileStatus: customStatusText,
        color: customStatusColor
      }
      setFileStatus(parameters).then((response) => {
        if (response.status === 200) {
          newCustomStatusNameValid.success= true
          this.setState({ newCustomStatusNameValid }, () => {
            this.toggleCustomerStatusModalVisible('')
          })
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    } else {
      newCustomStatusNameValid.success= false
      this.setState({ newCustomStatusNameValid })
    }
  }

  handleChangeComplete = (color, event) => {
    this.setState({ customStatusColor: color.hex, displayColorPicker: false })
  }

  togglePickerFromCustomStatusModal = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  }

  closePickerFromCustomStatusModal = () => {
    this.setState({ displayColorPicker: false })
  };

  handleChangeCustomStatus = (e) => {
    this.setState({ customStatusText: e.target.value })
  }

  changeFileName = (id, newFileName) => {
    const { files } = this.state

    const index = files.findIndex((file) => file.id === id)
    if (index !== -1) {

      const path = files[index].path.split('/')
      path[path.length - 1] = newFileName

      files[index].path = path.join('/')
      files[index].name = newFileName
      files[index].nameWithoutExtension = newFileName

      this.setState({ files })
    }
  }

  resetPageNumber = () => {
    this.setState({ currentPage: 1 })
  }
  getThumbnailsTreeStructureFolder = (listOfThumbnails) => {
    if (listOfThumbnails && listOfThumbnails.length > 0) {
      this.setState({
        listOfThumbnails: listOfThumbnails
      })
    }
  }

  setActiveFile = (e, file) => this.setState({ activeFile: file })

  statusCancelModalNewBox = (status) => {
    this.setState({
      startSettingsNewBox: status
    })
  }
  onAddNewBox = (nameBox) => {
    if (nameBox) {
      this.setState({
        newAddBoxOnList: nameBox
      }, () => {
        this.handleNewFolderKeyPressed()
      })
    }
  }
  onAddNewBoxValuesFinally = (listNewBox) => {
    if (listNewBox) {
      this.setState({
        listNewBoxCreate: listNewBox,
      }, () => {
        this.handleNewFolderKeyPressed()
        // setTimeout(() => {
        //   this.handleNewFolderKeyPressed()
        // }, 1000);
      })
      //this.handleNewFolderKeyPressed()
      this.setState({
        startSettingsNewBox: false,
        //listNewBoxCreate: []
      })
      // setTimeout(function() {
      //   window.location.href = '/smart-box/management'
      // }.bind(this), 1000)
    }
  }

  setNewFoldersCountFiles = (foldersCountFiles) => {
    this.setState({ foldersCountFiles: [...this.state.foldersCountFiles, ...foldersCountFiles] })
  }
  getFilesCountListView = (item) => {
    const { foldersCountFiles, files } = this.state
    files && files.length > 0 && files.forEach((file) => {
      foldersCountFiles && foldersCountFiles.length > 0 && foldersCountFiles.forEach((el) => {
        if (file.path === el.path) {
          file.numberOfFiles = el.numberOfFiles
        }
      })
    })
    if(files && files.length > 0) {
      const match = foldersCountFiles.filter(el => el.path === item.path)
      if (match && match.length > 0) {
        return match[0].numberOfFiles
      }
    }
    return 0
  }

  onGetStatusTags = (defaultSelectedValue, listKeysFilesDeleted) => {
    let { files } = this.state
    files && files.length > 0 && files.forEach((item) => {
      if (listKeysFilesDeleted.includes(item.id)) {
        if (defaultSelectedValue.icon) {
          item.systemPropertyFileStatusIcon = defaultSelectedValue.icon
          item.systemPropertyFileStatus = defaultSelectedValue.tagName
          item.systemPropertyFileStatusColor = null
        } else if (defaultSelectedValue.color) {
          item.systemPropertyFileStatusColor = defaultSelectedValue.color
          item.systemPropertyFileStatusIcon = null
          item.systemPropertyFileStatus = defaultSelectedValue.tagName
        } else if (!defaultSelectedValue.icon && !defaultSelectedValue.color) {
          item.systemPropertyFileStatusColor = null
          item.systemPropertyFileStatusIcon = null
          item.systemPropertyFileStatus = defaultSelectedValue.tagName
        }
      }
    })
    this.setState({
      loadingBoxPage: true,
    }, () => {
      this.setState({
        files,
        loadingBoxPage: false,
      })
    })
  }

  onStatusOpenModalExport = (status) => {
     this.setState({
       statusPageModalDownload: status
     })
  }
  onDeleteAllFiles = (list) => {
    this.setState({
      listKeysFilesDeleted: list,
      listKeysFiles: list,
    })
  }
  render() {
    const { files, newFolderClicked, documentBytes, documentId, totalPages, landscape, aspectRatio, height, width,
      initialName, smartBoxDocumentId, searchText, sidebarClass, contentClass, listKeysFiles, checkKeysFiles, selectedDoc,
      sidebarOpen, loadingBoxPage, disabledOnClicked, visitedRedactionSmart, validNewFolderName, finalizedDocPath,
      totalPagesForPagination, currentPage, documentNoteVisible, systemPropertyFileStatus, listOfThumbnails,
      totalFolderOnRoot, foldersCountFiles, activeFile, startSettingsNewBox, doc_classification, visibleToDownload, progressBulkData,
      statusResponsePagination, listKeysFilesDeleted, statusPagination, statusFolderCountFiles, statusThumbnailForFiles, statusProgressBarData,
      actionNextPreviousSmartbox, statusPageModalDownload, lockSmartViewChanges
    } = this.state
    const { redactingMode, smartBoxSearchedText, smartBoxViewMode, smartBoxPageLimit } = this.props
    let filesTable = this.adjustFilesTable(files)
    // if (newFolderClicked) {
    //   const newFolderInput = (
    //     <Input placeholder="New folder name"
    //            //onKeyPress={ this.handleNewFolderKeyPressed }
    //       onBlur={ this.removeNewFolderFormView } ref={ (input) => {
    //         this.newFolderInput = input
    //       } }/>)
    //   const emptyFile = [ {
    //     id: 0,
    //     key: 1,
    //     name: newFolderInput,
    //     type: 'DIRECTORY',
    //     depth: 0,
    //     modified: moment().format('D.MM.YYYY'),
    //     members: [ 'Only Me' ]
    //   } ]
    //   filesTable = [ ...emptyFile, ...filesTable ]
    // }
    return (
      !redactingMode ? <React.Fragment>
        <WrapperNotification onMounted={ (callbacks) => this.setRefWrapperNotification(callbacks)}/>
        <div className={ `smart-box-page__action-container float-left ${ contentClass }` }>
          <div className="smart-box-page__action-container__row">
            { startSettingsNewBox &&
              <NewBoxCreate
                statusCancelModalNewBox={ this.statusCancelModalNewBox }
                onAddNewBox={ this.onAddNewBox }
                onAddNewBoxValuesFinally = { this.onAddNewBoxValuesFinally }
                client={ this.props.client }/>
            }
            <SmartBoxActionsHeader
              handleSearchInputChange={ this.handleSearchInputChange }
              searchText={ searchText }
              fetchFilesOnSearch={ this.fetchFilesOnSearch }
              fetchFiles={ this.fetchFiles }
              handleNewFolderClick={ this.handleNewFolderClick }
              newFolderClicked={ newFolderClicked }
              customUploadFolder={ this.customUploadFolder }
              disableNewFolder={ false /*this.props.smartBoxViewMode === 'list'*/ }
              filesTable={ filesTable }
              root={ true }
              totalRecords={ totalPagesForPagination }
              currentPage = { currentPage }
              pageLimit={ smartBoxPageLimit }
              loadingBoxPage = { loadingBoxPage }
              onPageChanged={ this.onPageChanged }
              handleChangeViewMode = { this.handleChangeViewMode }
              checkKeysFiles = { checkKeysFiles }
              onSelectAll = { this.onSelectAll }
              listKeysFiles = { listKeysFiles }
              listKeysFilesDeleted = { listKeysFilesDeleted }
              clientId={ this.props.clientId }
              toggleFilePreview = { this.toggleFilePreview }
              toggleNoteVisible = { this.toggleNoteVisible }
              currentFolderPath={ '' }
              folderPath={ '' }
              totalFolderOnRoot={ totalFolderOnRoot }
              showLeftFoldersNav={false}
              foldersCountFiles={ foldersCountFiles }
              statusResponsePagination={ statusResponsePagination }
              refreshFilesCountOfFolders={ this.refreshFilesCountOfFolders }
              statusPagination={ statusPagination }
              statusFolderCountFiles={ statusFolderCountFiles }
              statusThumbnailForFiles={ statusThumbnailForFiles }
              statusProgressBarData={ statusProgressBarData }
              actionNextPreviousSmartbox={ actionNextPreviousSmartbox }
              //onCheckRefreshProgressBar = { this.onCheckRefreshProgressBar }
              onGetStatusTags={ this.onGetStatusTags }
              onDeleteAllFiles={ this.onDeleteAllFiles }
            />
            {/*<div className="smart-box-page__action-container__breadcrumb">*/}
            {/*  <span className="smart-box-page__action-container__breadcrumb--bold">*/}
            {/*    <Link to="/smart-box/management">Smartbox.ai Home</Link>*/}
            {/*  </span>*/}
            {/*</div>*/}
            {/*<div className="smart-box-page__action-container__tree">*/}
            {/*  <LeftTree files={ files } homePage={ true } />*/}
            {/*</div>*/}
            <div className= {smartBoxViewMode === 'grid' ? 'smart-box-page__action-container__content new-content-smart-box' : 'smart-box-page__action-container__content content-list'}>
              <div className="row">
                <div className="col-md-12" style={{paddingLeft: 0, paddingRight: 0}}>
                  <Spin className="custom-spin" spinning={ loadingBoxPage && smartBoxViewMode === 'grid' }>
                    <div className="smart-box-page__action-container__content__files content-files">
                      { smartBoxViewMode === 'grid' && filesTable && filesTable.length > 0 && loadingBoxPage === false && filesTable.map((item, index) =>
                        <SmartBoxFile
                          key={ index }
                          file={ item }
                          handleDeleteFile={ this.handleDeleteFile }
                          isNewFolder={ item.id === 0 }
                          handleNewFolderKeyPressed={ this.handleNewFolderKeyPressed }
                          location={ this.props.location } 
                          onFilePressed={ this.onFilePressed }
                          removeNewFolderFormView={ this.removeNewFolderFormView }
                          listKeysFiles={ listKeysFiles }
                          listKeysFilesDeleted={ listKeysFilesDeleted }
                          parent={ true }
                          addOrRemoveValue={ this.addOrRemoveValue }
                          removeAllSelected={ this.removeAllSelected }
                          toggleFilePreview={ this.toggleFilePreview }
                          disabledOnClicked={ disabledOnClicked }
                          fetchFiles={ this.fetchFiles }
                          user={this.props.user}
                          refreshFolder={ this.fetchFiles}
                          validNewFolderName = {validNewFolderName}
                          isUnique = {this.isUnique}
                          changeFileName = { this.changeFileName }
                          totalRecords={ totalPagesForPagination }
                          pageLimit={ smartBoxPageLimit }
                          currentPage = { currentPage }
                          onPageChanged={ this.onPageChanged }
                          root={ true }
                          filesCount={ this.getFilesCount(item) }
                          setActiveFile = { this.setActiveFile }
                          activeFile = { activeFile }
                          filesErrors = { this.getFilesErrors(item) }
                          getFilesDuplicates ={ false }
                          onCallProgressRename={ this.onCallProgressRename }
                          progressBulkData={ progressBulkData }
                          onStatusOpenModalExport={ this.onStatusOpenModalExport }
                            //setCurrentFolder={ this.setCurrentFolder }
                        />
                      ) }
                    </div>
                  </Spin>
                </div>
              </div>
              { smartBoxViewMode === 'list' &&
                <TreeStructureFolder
                  loadingBoxPage={ loadingBoxPage }
                  files={ files }
                  toggleFilePreview={ this.toggleFilePreview }
                  toggleFileSelect={ this.addOrRemoveValue }
                  lastFileClicked={''}
                  lastFileClickedAction={''}
                  setSidebarHeight={ this.setSidebarHeight }
                  handlePreview={ this.handlePreview }
                  handlePreviewClicked={ this.handlePreviewClicked }
                  setNewFiles={ this.setNewFiles }
                  getTumbnailsForExpendedNode={ this.getTumbnailsForExpendedNode }
                  root={ true }
                  handleDeleteFile={ this.handleDeleteFile }
                  onFilePressed={ this.handlePreviewSmartBox }
                  fetchFiles={ this.fetchFiles }
                  hasDragBelow={false}
                  resetPageNumber = { this.resetPageNumber }
                  getThumbnailsTreeStructureFolder = { this.getThumbnailsTreeStructureFolder }
                  foldersCountFiles = { foldersCountFiles }
                  setNewFoldersCountFiles = { this.setNewFoldersCountFiles }
                  showArrowsFirstLevel={false}
                  getFilesCountListView={ this.getFilesCountListView }
                  isUnique = {this.isUnique}
                />
              }
            </div>
          </div>
        </div>
        <div className={ `smart-box-page__action-sidebar float-left ${ sidebarClass }` }>
          <MetadataPage
            documentId={selectedDoc.id}
            documentPath={selectedDoc.path}
            document= { selectedDoc }
            documentName={selectedDoc.name}
            documentType={selectedDoc.type}
            documentDepth={selectedDoc.depth}
            documentNameWithoutExtension={selectedDoc.nameFileWithoutExtension}
            sidebarOpen={sidebarOpen}
            toggleCustomerStatusModalVisible={this.toggleCustomerStatusModalVisible}
            fetchFiles={ this.fetchFiles }
            listOfThumbnails = { listOfThumbnails }
          />
          <div className="smart-box-page__action-sidebar__close" title="close">
            <svg width="16px" height="16px" onClick={ () => this.toggleFilePreview(false, {}) }>
              <polygon points="0,0,16,8 0,16" fill="#d8d8d8"/>
            </svg>
          </div>
        </div>
        <React.Fragment key={`notes-${ selectedDoc.id }`}>
          { documentNoteVisible ? <DirectoryNote closeDocumentNote={ () => this.toggleNoteVisible(false) } documentId={ selectedDoc.id } documentName={ selectedDoc.name } user={ this.props.user } /> : '' }
        </React.Fragment>
      </React.Fragment>
        :
        <div className="redacting-container col-md-12">
          { documentBytes && documentId &&
          <RedactionTool
            pdfBytes={ generateBlobFromBase64(documentBytes) }
            documentId={ documentId }
            initialName={ initialName }
            totalPages={ totalPages }
            closeRedacting={ this.closeRedacting }
            landscape={ landscape }
            clientId={ this.props.clientId }
            aspectRatio={ aspectRatio } dbHeight={ height } dbWidth={ width }
            setHeaderVisibility={ this.props.setHeaderVisibility }
            setSidebarVisibility={ this.props.setSidebarVisibility }
            setFooterVisibility={ this.props.setFooterVisibility }
            isSmartBoxApplication={ true }
            smartBoxDocumentId={ smartBoxDocumentId }
            searchedValue={ smartBoxSearchedText }
            visitedRedactionSmart={ visitedRedactionSmart }
            fetchFiles={ this.fetchFiles }
            finalizedDocPath = { finalizedDocPath }
            systemPropertyFileStatus = { systemPropertyFileStatus }
            doc_classification = { doc_classification }
            visibleToDownload = { visibleToDownload }
            client={ this.props.client }
            lockSmartViewChanges={ lockSmartViewChanges }
          />
          }
        </div>
    )
  }
}


SmartBoxPage = withRouter(SmartBoxPage)
const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id,
  client: state.subscriptionReducer.subscription.subscription.client,
  user: state.userReducer.user,
  redactingMode: !state.shellReducer.sidebarVisible && !state.shellReducer.headerVisible && !state.shellReducer.footerVisible,
  fetchFolderContent: state.smartBox.fetchFolderContent,
  smartBoxSearchedText: state.smartBox.smartBoxSearchedText,
  smartBoxViewMode: state.smartBox.smartBoxViewMode,
  smartBoxFilesTable: state.smartBox.smartBoxFilesTable,
  smartBoxRenamedFolder: state.smartBox.smartBoxRenamedFolder,
  smartBoxPageLimit: state.smartBox.smartBoxPageLimit,
  smartBoxCurrentSortOption: state.smartBox.smartBoxCurrentSortOption,
  smartBoxFolderHasBulk: state.smartBox.smartBoxFolderHasBulk,
  responseProgressBarRename: state.smartBox.responseProgressBarRename,
  smartBoxCheckStatusDeduplication: state.smartBox.smartBoxCheckStatusDeduplication,
  smartBoxNotAnalysisComplete: state.smartBox.smartBoxNotAnalysisComplete,
  currentNewFilters: state.smartBox.currentNewFilters,
  smartBoxHomeClicked: state.smartBox.smartBoxHomeClicked,
  selectKeysSmartBox: state.smartBox.selectKeysSmartBox,
  smartBoxExistsFilesWarningsNotification: state.smartBox.smartBoxExistsFilesWarningsNotification,
})
export default connect(mapStateToProps, {
  setHeaderVisibility,
  setSidebarVisibility,
  setFooterVisibility,
  setSmartBoxUploadingObject,
  setSmartBoxSearchedWord,
  setSmartBoxViewMode,
  setSmartBoxNewNode,
  setSmartBoxCurrentFolder,
  setSmartBoxFilesTable,
  setSmartBoxFetchFiles,
  setSmartBoxSelectedFiles,
  setSmartBoxFolderHasBulk,
  setResponseProgressBarRename,
  setSmartBoxFolderCountTree,
  setSmartBoxCheckStatusDeduplication,
  setSmartBoxShowUploadNavigation,
  setSmartBoxNotAnalysisComplete,
  setSmartBoxCheckBoxRedactionStatus,
  setSmartBoxClickOutsideFilter,
  setSmartBoxHomeClicked,
  setSmartBoxSelectedKeys,
  setBoxListViewSelectedKeys,
  setProgressEventUpload,
  setSmartBoxExistsFilesWarningsNotification
})(SmartBoxPage)