import axios from 'axios'

export const apiPrivacyGetDataModels = (host) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/getDataModels`,
    data: {
      sessionID: token,
      datamodel: 'documents'
    }
  })
}

export const apiPrivacyGetFacetValues = (host) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/getDataModelFacetValues`,
    data: { 
      sessionID: token,
      query: '*',
      datamodel: 'documents',
      facetnames: ['doc_author_name'],
      filterQueries: [],
      offset: 0,
      length: 100,
      prefix: ''
    }
  })
}

export const apiPrivacyGetCountFolder = (host, { facetnames, query, filterQueries }) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/getDataModelFacetValues`,
    data: { 
      sessionID: token,
      query,
      datamodel: 'documents',
      facetnames,
      filterQueries,
      offset: 0,
      length: 1,
      prefix: ''
    }
  })
}