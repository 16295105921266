import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import '../FormEmployeeWorkflow.scss'
import {Input, Select, Steps} from "antd";
const { Option } = Select;
const { Step } = Steps;

export class TelephoneTemplateForm extends Component {

    state = {

    }
    onChangeNameAndDetails = (e, key) => {
        const { component } = this.props
        switch (key) {
            case 'telephone':
                this.props.onChangeNameAndDetails(e, key, component)
                break
            default:
                break
        }
    }
    render() {
        const { formerEmployee, component, stepTicketActive } = this.props
        return (
            <div className="full-name-former">
                <span className="header-former header-former-address">{component.header && component.mandatory ? `${component.header}*` :
                    component.header ? component.header : 'Telephone'}</span>
                <span className="input-content">
                                <Input className="input-form-name"
                                       onChange={(e) => this.onChangeNameAndDetails(e, 'telephone')}
                                       disabled={stepTicketActive.blockFormEdit === true}
                                       value={ component.phoneNumber }/>
                                <span className="label-input">Telephone</span>
                            </span>
            </div>
        )
    }
}


TelephoneTemplateForm.propTypes = {
    folder: PropTypes.object,
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id
})
export default compose(
    connect(mapStateToProps),
)(TelephoneTemplateForm)