import React, { Component } from "react";
import "./NotFound.scss";
import Button from "../Common/Button";
import withRouter from "../../private-routers/withRouter";

class NotFound extends Component {
  constructor() {
    super();
    this.state = {
      title: "404 Error Page",
      titleType: "404",
      titleDescription: "PAGE NOT FOUND",
    };
  }
  render() {
    const { title, titleType, titleDescription } = this.state;
    return (
      <>
        <Button theme="green" onClick={() => {this.props.navigate(-1)}} styles= {{position:'absolute !important'}}>
          Back
        </Button>
        <div className="sa_page_wrapper">
          <div className="sa_page">
            <p className="styleTitleError">
              {titleType}
              <small className="styleSmall">{titleDescription}</small>
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(NotFound);
