import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import './PiaCompleted.scss'
import { Tooltip } from 'antd'
import withRouter from '../../../../private-routers/withRouter'

let PiaCompleted = ({ assessment, processStatus, isAdmin, name, score }) => {
  return (
    <div className="piaCompleteAssessment__wrapper">
      {
        isAdmin &&
                <div className="piaCompleteAssessment__actionsContainer">
                  <Tooltip title="PIA Result">
                    <span className="iconReport"
                      onClick={seeResult.bind(this, assessment.id, name)} />
                  </Tooltip>
                  <Tooltip title="Download Report">
                    <span className="iconDownload"
                      onClick={seeDetailReport.bind(this, assessment.id, name)} />
                  </Tooltip>
                  <Tooltip title="View Answers">
                    <span className="iconView"
                      onClick={seeQuestions.bind(this, assessment.id)} />
                  </Tooltip>
                </div>
      }
      {
        !isAdmin &&
                <div className="piaCompleteAssessment__actionsContainer">
                  <Tooltip title="PIA Report">
                    <span className="iconReportDisabled" />
                  </Tooltip>
                  <Tooltip title="Download Report">
                    <span className="iconDownloadDisabled" />
                  </Tooltip>
                  <Tooltip title="View Answers">
                    <span className="iconView"
                      onClick={seeQuestions.bind(this, assessment.id)} />
                  </Tooltip>
                </div>
      }
      <div className="piaCompleteAssessment__infoContainer">
        <div className="piaCompleteAssessment__textContainer">
          <Link to={`/dpia/assessment/${ assessment.id }/result`}>
                        PIA
          </Link>
          { score ? <span
            className="piaScoreTotalAssesment">{ score }</span> : ''}
        </div>
        <div className="piaCompleteAssessment__status">
          {processStatus !== 'INACTIVE' &&
                    <Link to={`/dpia/assessment/${ assessment.id }/result`} className="piaCompleteAssessment__completed">
                        Completed
                    </Link>
          }

          {processStatus === 'INACTIVE' &&
                    <span className="piaCompleteAssessment__completed">
            Terminated {moment(assessment.validStartDate).format('MMMM YYYY')}
                    </span>
          }
        </div>
        <div className="piaCompleteAssessment__iconContainer">
          <Link to={`/dpia/assessment/${ assessment.id }/result`}>
            <span className="iconCheckDisabled" />
          </Link>
        </div>
      </div>
    </div>
  )
}

const seeResult = (id) => {
  this.props.navigate(`/dpia/assessment/${ id }/result`)
}

const seeQuestions = (id) => {
  this.props.navigate(`/dpia/assessment/${ id }`)
}
const seeDetailReport = (assessmentId, name) => {
  this.props.navigate({
    pathname: `/dpia/assessment/${ assessmentId }/report`,
    state: { name }
  }
  )
}
PiaCompleted = withRouter(PiaCompleted)
PiaCompleted.propTypes = {
  assessment: PropTypes.object.isRequired,
  processId: PropTypes.number.isRequired
}

export default PiaCompleted