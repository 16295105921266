import React, { useEffect, useState} from 'react'
import { Progress } from 'antd';
export const ProgressCollectionData = ({ onStatusCollectionData, percentCollectData }) => {
  const [seconds, setSeconds] = useState(0)
  useEffect(() => {
    let checkIntervalSeconds = 0
    checkIntervalSeconds = 100/percentCollectData
    const loadingProgress = 100/checkIntervalSeconds
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds + loadingProgress)
    }, 1000)
    return () => clearInterval(interval)
  }, [percentCollectData])
  useEffect(() => {
    if (seconds === 100) {
      clearInterval(seconds)
    }
    onStatusCollectionData(seconds)
  }, [seconds, onStatusCollectionData])
  return (
    <div className="content-progress-collection">
      <span className="collection-progress-text">Collecting data ...</span>
      <Progress percent={ percentCollectData } />
    </div>
  )
}
export default ProgressCollectionData
