import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'

import Button from '../Common/Button'
import FolderList from './Files/FolderList'
import Filters from './Files/Filters'
import Loading from '../Common/Loading'
import Pagination from './Form/Pagination'
import Preview from './Files/Preview'

import WithAlerts from './HOC/WithAlerts'
import WithCollections from './HOC/WithCollections'
import WithConnectors from './HOC/WithConnectors'
import WithFiles from './HOC/WithFiles'
import '../../style/common.scss'
import './Files.css'

class Files extends React.Component {
  state = {
    activePage: 1,
    accordion: {},
    fileToView: {},
    selectedAlert: '',
    selectedCollection: '',
    selectedConnector: '',
    selectedObjectType: '',
    isModalOpened: false
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)

    const collectionId = this.props.params.id
    this.setState({
      selectedCollection: collectionId ? collectionId : ''
    }, this.getFolders)

    if (collectionId) {
      // When we update the value for collection, we should fetch the related alerts
      this.props.getAlertsUsingCollectionId(collectionId)
    }
  }

  componentWillUnmount = () => this.props.resetAlerts()

  toggleAccordion = (accordion, index) => {
    this.setState({ accordion: { ...accordion, [index]: !accordion[index] } })
  }

  closeModal = () => this.setState({ isModalOpened: false })

  showModal = (file) => {
    this.setState({ fileToView: file, isModalOpened: true })
  }

  filterBy = (field, event) => {
    const { value } = event.target

    this.setState({ [field]: value }, () => {
      this.getFolders()
      // When we update the value for collection, we should fetch the related alerts
      this.props.getAlertsUsingCollectionId(value)
    })
  }

  getFolders = () => {
    this.props.resetFiles()
    this.setState({ accordion: {} })

    const filter = this.getFilterQuery()
    this.props.getObjects(filter)
    this.props.getDirectories(filter)
    this.props.getFolders(0, filter)
  }

  getFilterQuery = () => {
    const filterQueries = []
    if (this.state.selectedAlert !== '') filterQueries.push(`doc_dynamic_type:${ this.state.selectedAlert }`)
    if (this.state.selectedCollection !== '') filterQueries.push(`doc_dynamic_type:${ this.state.selectedCollection }`)
    if (this.state.selectedConnector !== '') filterQueries.push(`doc_crawlerid:${ this.state.selectedConnector }`)
    if (this.state.selectedObjectType !== '') filterQueries.push(`doc_ext:${ this.state.selectedObjectType }`)
    return filterQueries
  }

  changePagination = (changePagination, page) => {
    this.setState({ activePage: page })
    changePagination(page, this.getFilterQuery())
  }

  render = () => {
    const { totalDirectories, totalObjects } = this.props

    return (
      <div className="privacyFiles__wrapper">
        { this.state.isModalOpened &&
          <Preview
            file={ this.state.fileToView }
            selectedAlert={ this.state.selectedAlert }
            selectedCollection={ this.state.selectedCollection }
            onClose={ this.closeModal } />
        }

        <Filters
          filterBy={ this.filterBy }
          selectedAlert={ this.state.selectedAlert }
          selectedCollection={ this.state.selectedCollection }/>

        { !this.props.isFetchingFolders &&
          <div className="privacyFiles__box">
            <header className="privacyFiles__boxHeader">
              <div>
                <h1 className="privacyFiles__boxTitle">Files</h1>
                { totalObjects > 0 &&
                  <span className="privacyFiles__boxTitleTotal">
                    <strong>{ totalObjects } objects</strong> in <strong>{ totalDirectories } directories</strong> matching your filters
                  </span>
                }
                <p className="privacyFiles__boxText">
                  These are objects that exist in your data connections pool.
                </p>
              </div>
              <div className="privacyFiles__boxButton">
                <Button>
                  Export CSV
                </Button>
              </div>
            </header>
          </div>
        }

        { this.props.isFetchingFolders
          ? <div className="privacyFiles__loading"><Loading /></div>
          :
          <FolderList
            accordion={ this.state.accordion }
            folders={ this.props.folders }
            toggle={ this.toggleAccordion }
            showModal={ this.showModal }
          />
        }
        
        { !this.props.isFetchingFolders &&
          totalDirectories > 0 &&
          <div className="pagination__box">
            <header className="pagination__box__header">
              <div>
                <h1 className="privacyFiles__boxTitle">Files</h1>
                { totalObjects > 0 &&
                  <span className="pagination__box__header__total">
                    <strong>{ totalObjects } objects</strong> in <strong>{ totalDirectories } directories</strong>
                  </span>
                }
                <p className="pagination__box__header__text">
                  Page { this.state.activePage } of { Math.ceil(totalDirectories / 12) }
                </p>
              </div>
              <Pagination
                activePage={ this.state.activePage }
                changePagination={ this.changePagination.bind(this, this.props.changePaginationForFiles) }
                pages={ Math.ceil(totalDirectories / 12) } />
            </header>
          </div>
        }
      </div>
    )
  }
}

Files.propTypes = {
  getAlertsUsingCollectionId: PropTypes.func.isRequired,
  getDirectories: PropTypes.func.isRequired,
  getObjects: PropTypes.func.isRequired,
  resetAlerts: PropTypes.func.isRequired,
  totalDirectories: PropTypes.number.isRequired,
  totalObjects: PropTypes.number.isRequired
}

export default compose(
  WithAlerts,
  WithCollections,
  WithConnectors,
  WithFiles
)(Files)