import React from 'react'
import PropTypes from 'prop-types'
import {
  apiPrivacyGetAlertUsingId,
  apiPrivacyGetAlerts,
  apiPrivacyGetAlertsForCollection,
  apiPrivacyGetTotalObjectsForCollections
} from '../api/Privacy'

import * as utils from '../utils/Alerts'
import { updateAnItemOnCollection } from '../utils'

const AlertsContext = React.createContext()

class AlertsProvider extends React.Component {
  state = { alerts: [], isFetchingAlerts: true }

  getAlerts = () => {
    this.setState({ isFetchingAlerts: true })

    apiPrivacyGetAlerts()
      .then((response) => {
        const collections = response.data.value.categories.category
        for (const collection of collections) {
          this.getAlertsForCollection(collection)
        }
      })
  }

  getAlertsForCollection = (collection, level) => {
    const id = collection ? collection.id : '25'

    apiPrivacyGetAlertsForCollection(id, level ? 'level' : 'type', level ? level : 'alert')
      .then((response) => {
        if (utils.hasAlerts(response)) {
          let alerts = response.data.value.categories.category

          if (!(alerts instanceof Array)) alerts = [alerts]
          const newAlerts = utils.mappedAlertsWithInformation(alerts, collection)

          this.setState({ alerts: [...this.state.alerts, ...newAlerts] })

          const firstPageForAlerts = newAlerts.slice(0, 12)
          for (const alert of firstPageForAlerts) {
            this.getInformationForAlert(alert.id)
          }
        }

        this.setState({ isFetchingAlerts: false })
      })
  }

  getAlertsUsingCollectionId = (collectionId) => {
    apiPrivacyGetAlerts()
      .then((response) => {
        for (const collection of response.data.value.categories.category) {
          if (collection.id === parseInt(collectionId, 10)) this.getAlertsForCollection(collection)
        }
      })
  }

  getAlertUsingId = (id) => {
    this.setState({ isFetchingAlerts: true })
    apiPrivacyGetAlertUsingId(id)
      .then((response) => {
        const alert = response.data.value.category
        this.setState({ alerts: alert ? [alert] : [], isFetchingAlerts: false })
      })
  }

  getInformationForAlert = (id, page = 0) => {
    apiPrivacyGetTotalObjectsForCollections(id, page)
      .then((response) => {
        const newAlerts = utils.putFilesOnAlert(response, this.state.alerts, id)
        this.setState({ alerts: newAlerts })
      })
  }

  updateAlert = (id, informationToUpdate) => {
    const newAlerts = updateAnItemOnCollection(this.state.alerts, id, informationToUpdate)
    this.setState({ alerts: newAlerts })
  }

  resetAlerts = () => {
    return new Promise((resolve) => {
      this.setState({ alerts: [], isFetchingAlerts: true }, resolve)
    })
  }

  render = () => {
    return (
      <AlertsContext.Provider value={{
        alerts: this.state.alerts,
        getAlertUsingId: this.getAlertUsingId,
        getAlerts: this.getAlerts,
        getAlertsForCollection: this.getAlertsForCollection,
        getAlertsUsingCollectionId: this.getAlertsUsingCollectionId,
        getInformationForAlert: this.getInformationForAlert,
        isFetchingAlerts: this.state.isFetchingAlerts,
        resetAlerts: this.resetAlerts,
        totalAlerts: this.state.alerts.length,
        updateAlert: this.updateAlert
      }}>
        { this.props.children }
      </AlertsContext.Provider>
    )
  }
}

AlertsProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export default AlertsProvider
export const AlertsConsumer = AlertsContext.Consumer