import React from 'react'

import imgLogoClean from '../../../assets/clean-logo-title.png'
import imgLogoMigration from '../../../assets/migration-logo-title.png'
import imgLogoRecords from '../../../assets/records-logo-title.png'
import imgLogoSubject from '../../../assets/subject-logo-title.png'

import './BoxComing.scss'

class BoxComing extends React.Component {
  render() {
    return (
      <div className="boxComing__box">
        <div className="boxComing__logo">
          { getImage(this.props.type) }
        </div>
        { this.props.children }
      </div>
    )
  }
}

const getImage = (type) => {
  if (type === 'clean') return <img src={ imgLogoClean } alt="Osprey Clean-Up" />
  if (type === 'migration') return <img src={ imgLogoMigration } alt="Osprey Migration" />
  if (type === 'records') return <img src={ imgLogoRecords } alt="Osprey Records" />
  if (type === 'subject') return <img src={ imgLogoSubject } alt="Osprey Subject Request" />
}

export default BoxComing