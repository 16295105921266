import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '../../Common/Button'
import Checkbox from './QuestionType/Checkbox'
import Radio from './QuestionType/Radio'
import YN from './QuestionType/YN'
import './ActiveQuestion.scss'

export class ActiveQuestion extends Component {
  constructor() {
    super()
    this.handleKey = this.handleKey.bind(this)
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKey)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKey)
  }

  handleKey(e) {
    // The DPIA is already completed, so no changes here.
    if (this.props.assessmentStatus === 'COMPLETED') return false

    // The DPIA is already overdue, so no changes here.
    if (this.props.assessmentStatus === 'OVERDUE') return false
    
    const pressedKey = e.keyCode

    // "Enter" key
    if (pressedKey !== 13) return false

    // Check to see if there is a selected option
    if (this.props.question.options.filter((option) => option.isSelected).length === 0) return false

    // Question 1 and option "no" selected, so we will not confirm the answer
    if (this.props.question.order === 1 && this.props.question.options[1].isSelected) return false

    this.props.confirmAnswer()
  }

  getClassForPage(assessmentStatus, processStatus, item) {
    const isDisabled = processStatus === 'INACTIVE' || assessmentStatus === 'COMPLETED' || item.state === 'QUESTIONNAIRE_SKIPPED'
    return isDisabled ? 'activeQuestion-pageDisabled' : 'activeQuestion-page'
  }

  canAnswerQuestionnaire(assessmentStatus, question) {
    if (question.state === 'QUESTIONNAIRE_SKIPPED') return false
    if (assessmentStatus !== 'NEW' && assessmentStatus !== 'ACTIVE' && assessmentStatus !== 'IN_PROGRESS') return false
    if (question.type !== 'YN') return true

    return true
  }

  isThereSelectedOption(question) {
    return question.options.filter((option) => option.isSelected).length > 0
  }

  render() {
    const { assessmentStatus, question, processStatus } = this.props

    if (!question) return null

    return (
      <div className={ this.getClassForPage(assessmentStatus, processStatus, question) }>
        <header className="activeQuestion-header">
          <h1 className="activeQuestion-title">{ question.order }. { question.title }</h1>
          
          { this.canAnswerQuestionnaire(assessmentStatus, question) &&
            <div className="activeQuestion-buttons">
              { question.userSkippable &&
                <button className={ this.isThereSelectedOption(question) ? 'activeQuestion-skipButtonSelected' : 'activeQuestion-skipButton' } onClick={ this.props.skipAnswer }>
                </button>
              }
              <div className="activeQuestion-button">
                <Button
                  disabled={ !this.isThereSelectedOption(question) }
                  theme="green"
                  onClick={ this.props.confirmAnswer }>
                  Confirm answer
                </Button>
              </div>
            </div>
          }
        </header>

        <p className="activeQuestion-description">{ question.description }</p>

        { question.type === 'YN' &&
          <YN { ...this.props } />
        }

        { question.type === 'Radio' &&
          <Radio { ...this.props } />
        }

        { question.type === 'Checkbox' &&
          <Checkbox { ...this.props } />
        }

      </div>
    )
  }
}

ActiveQuestion.propTypes = {
  confirmAnswer: PropTypes.func.isRequired,
  question: PropTypes.object,
  responseText: PropTypes.string,
  showResponse: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  skipAnswer: PropTypes.func.isRequired,
  updateValueForCheckbox: PropTypes.func.isRequired,
  updateValueForOther: PropTypes.func.isRequired,
  updateValueForRadio: PropTypes.func.isRequired,
}

export default ActiveQuestion
