import React from 'react'
import PropTypes from 'prop-types'

import './Filters.css'

class Filters extends React.Component {
  render = () => {
    return (
      <div className="filterTopics-wrapper">
        <div className="filterTopicsItem-button">
          <label className="filterTopicsItem-labelCentered">Find</label>
          <input
            type='text'
            className="filterTopicsItem-inputFind"
            onChange={ this.props.filterByTerm }></input>
        </div>

        <div className="filterTopicsItem-select">
          <label>Sort by</label>
          <select value={ this.props.sortValue } onChange={ this.props.filterBySort }>
            <option value="title">Alphabetically</option>
            <option value="weight">Weight</option>
          </select>
        </div>
      </div>
    )
  }
}

Filters.propTypes = {
  filterByConnector: PropTypes.func.isRequired,
  filterBySort: PropTypes.func.isRequired,
  filterByTerm: PropTypes.func.isRequired,
  sortValue: PropTypes.string.isRequired
}

export default Filters