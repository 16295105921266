import React from "react";
import {connect} from "react-redux";
import './ComponentsForm.scss';
import { Input, Checkbox, Col, Select} from "antd";
const { Option } = Select;
class DropdownForm extends React.Component {
    state = {
        listDropdown: [],
        statusOtherOption: false,
        dropdownForm: {
            valuesDropdown: [],
            queryForSearch: false,
            mandatoryCheck: false,
            options: [],
            header: 'Dropdown',
            valueOptionsChoice: [{ optionId: 0, optionText: '' }],
            sectionVariable: ''
        },
        hoverDelete: false,
        indexOption: 0,
        listOption: [],
        editHeader: false,
        hoverEditBtn:false,
        hoverRemoveLabelBtn:{},

    }
    componentDidMount() {
        const { component } = this.props
        this.setState({
            dropdownForm: { ...this.state.dropdownForm,
                queryForSearch: component.queryForSearch === undefined ? false : component.queryForSearch,
                mandatoryCheck: component.mandatory === undefined ? false : component.mandatory,
                options: component.options === undefined || component.options === null ? [] : component.options,
                valueOptionsChoice: component.options === undefined || component.options === null ? [] : component.options,
                header: component.header === undefined ? 'Dropdown' : component.header,
                sectionVariable: component.sectionVariable === undefined ? '' : component.sectionVariable,
            },
            listDropdown: component.options === undefined || component.options === null ? [] : component.options
        })
        //this.props.onChangeForm(this.state.dropdownForm, this.props.component)
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props && this.props.checkStartSaved) {
            this.props.onChangeForm(this.state.dropdownForm, this.props.component)
        }
    }
    onChangeCheckbox = (e, key) => {
        switch (key) {
            case 'query':
                this.setState({
                    dropdownForm: { ...this.state.dropdownForm, queryForSearch: e.target.checked },
                }, () => {
                    this.props.onChangeForm(this.state.dropdownForm, this.props.component)
                })
                break
            case 'mandatory':
                this.setState({
                    dropdownForm: { ...this.state.dropdownForm, mandatoryCheck: e.target.checked },
                }, () => {
                    this.props.onChangeForm(this.state.dropdownForm, this.props.component)
                })
                break
            default:
                break
        }
    }
    onHoverActionComponent = () => {
        this.setState({
            hoverDelete: true
        })
    }
    onLeaveActionComponent = () => {
        this.setState({
            hoverDelete: false
        })
    }
    onHoverEditBtn = () => {
        this.setState({
            hoverEditBtn: true
        })
    }
    onLeaveEditBtn = () => {
        this.setState({
            hoverEditBtn: false
        })
    }
    onHoverRemoveLabelBtn = (index) => {
        this.setState({
            hoverRemoveLabelBtn: index
        })
    }
    onLeaveRemoveLabelBtn = () => {
        this.setState({
            hoverRemoveLabelBtn: {}
        })
    }
    addOptionDropdown = () => {
        this.setState({
            statusOtherOption: true
        })
    }
    displayInputOtherOptionDropdown = () => {
        const { listDropdown, indexOption, listOption, dropdownForm } = this.state
        //const listOption = []
        dropdownForm.valueOptionsChoice && dropdownForm.valueOptionsChoice.length > 0 && dropdownForm.valueOptionsChoice.forEach((list) => {
            listOption.push(list.optionText)
        })
        this.setState({
            listOption
        })
        if (listOption && listOption.length > 0 && !listOption.includes('Other')) {
            this.setState({
                listDropdown: [...this.state.listDropdown, {
                    optionId: indexOption,
                    optionText: 'Other'
                }],
                dropdownForm: { ...this.state.dropdownForm, options:  [...this.state.dropdownForm.options, {
                        optionId: indexOption,
                        optionText: 'Other'
                    }], valueOptionsChoice:  [...this.state.dropdownForm.valueOptionsChoice, {
                        optionId: indexOption,
                        optionText: 'Other'
                    }] },
                indexOption: indexOption + 1
            }, () => {
                this.props.onChangeForm(this.state.dropdownForm, this.props.component)
            })
        } else if (dropdownForm.valueOptionsChoice && dropdownForm.valueOptionsChoice.length === 0) {
            this.setState({
                listDropdown: [...this.state.listDropdown, {
                    optionId: indexOption,
                    optionText: 'Other'
                }],
                dropdownForm: { ...this.state.dropdownForm, options:  [...this.state.dropdownForm.options, {
                        optionId: indexOption,
                        optionText: 'Other'
                    }],
                    valueOptionsChoice:  [...this.state.dropdownForm.valueOptionsChoice, {
                        optionId: indexOption,
                        optionText: 'Other'
                    }]},
                indexOption: indexOption + 1
            }, () => {
                this.props.onChangeForm(this.state.dropdownForm, this.props.component)
            })
        }
    }
    addOtherOptionDropdown = (e) => {
        const { listDropdown, indexOption } = this.state
        this.setState({
            listDropdown: [...this.state.listDropdown, {
                optionId: indexOption,
                optionText: e.target.value
            }],
            dropdownForm: { ...this.state.dropdownForm, options:  [...this.state.dropdownForm.options, {
                    optionId: indexOption,
                    optionText: e.target.value
                }] },
            statusOtherOption: false,
            indexOption: indexOption + 1
        }, () => {
            this.props.onChangeForm(this.state.dropdownForm, this.props.component)
        })
    }
    onSelectOption = (e) => {

    }
    onClickEditHeader = (e, status) => {
        if (status === 'header') {
            this.setState({
                editHeader: true
            })
        }
    }
    addTitleHeader = (e, status) => {
        if (status === 'header') {
            this.setState({
                dropdownForm: { ...this.state.dropdownForm, header: e.target.value },
                //editHeader: false
            }, () => {
                this.props.onChangeForm(this.state.dropdownForm, this.props.component)
            })
        }
    }

    addRemoveOptions = (e, status, radio, index) => {
        const { dropdownForm } = this.state
        let removedLast = this.state.dropdownForm.options
        switch (status) {
            case 'decrementOption':
                const removedUpdate = []
                delete removedLast[index]
                removedLast && removedLast.length > 0 && removedLast.map((item2, index2) => {
                    if (index2 !== index) {
                        removedUpdate.push(item2)
                    }
                })
                this.setState({
                    listRadio: removedUpdate,
                    dropdownForm: { ...this.state.dropdownForm, options: removedUpdate },
                }, () => {
                    this.props.onChangeForm(this.state.dropdownForm, this.props.component)
                })
                let removedLast2 = this.state.dropdownForm.valueOptionsChoice
                const removedUpdate2 = []
                delete removedLast[index]
                removedLast2 && removedLast2.length > 0 && removedLast2.map((item2, index2) => {
                    if (item2.optionId !== index) {
                        removedUpdate2.push(item2)
                    }
                })
                this.setState({
                    dropdownForm: { ...this.state.dropdownForm, valueOptionsChoice: removedUpdate2 },
                })
                break
            default: break
        }

    }
    addRemoveInputs = (e, status, item, index) => {
        const { dropdownForm } = this.state
        const { valueOptionsChoice } = this.state.dropdownForm
        switch (status) {
            case 'decrementOption':
                let removedLast = this.state.dropdownForm.valueOptionsChoice
                const removedUpdate = []
                delete removedLast[index]
                removedLast && removedLast.length > 0 && removedLast.map((item2, index2) => {
                    if (item2.optionId !== index) {
                        removedUpdate.push(item2)
                    }
                })
                this.setState({
                    dropdownForm: { ...this.state.dropdownForm, valueOptionsChoice: removedUpdate },
                })
                break
            case 'addField':
                let copyState = dropdownForm.valueOptionsChoice
                copyState[valueOptionsChoice.length] = {
                    optionId: valueOptionsChoice.length,
                    optionText: ''
                }
                this.setState({
                    dropdownForm: { ...this.state.dropdownForm, valueOptionsChoice: copyState },
                })
                break
            case 'addFieldFirst':
                let copyState2 = dropdownForm.valueOptionsChoice
                const newArray = []
                newArray.push({
                    optionId: 0,
                    optionText: ''
                })
                dropdownForm.valueOptionsChoice && dropdownForm.valueOptionsChoice.length > 0 && dropdownForm.valueOptionsChoice.forEach((item, index2) => {
                    newArray.push({
                        optionId: index2 + 1,
                        optionText: item.optionText
                    })
                })
                newArray.sort((a, b) => a.optionId - (b.optionId))
                this.setState({
                    dropdownForm: { ...this.state.dropdownForm, valueOptionsChoice: newArray },
                })
                break
            default: break
        }
    }
    addOtherOptionChange = (e, item, index) => {
        const { dropdownForm } = this.state
        let value  =  e.target.value
        let copyState = dropdownForm.valueOptionsChoice
        copyState[index].optionText = value
        if (copyState[index] && copyState[index].optionText) {
            copyState[index].optionText = value
        } else {
            copyState[index] = {
                optionId: null,
                optionText: value
            }
        }
        this.setState({
            dropdownForm: { ...this.state.dropdownForm, valueOptionsChoice: copyState },
        })
    }
    addSubmit = () => {
        const { dropdownForm } = this.state
        const { valueOptionsChoice } = this.state.dropdownForm
        const newArrayAdd = []
        dropdownForm.valueOptionsChoice && dropdownForm.valueOptionsChoice.length > 0 && dropdownForm.valueOptionsChoice.forEach((item, index) => {
            if (item && item.optionText) {
                newArrayAdd.push({
                    optionId: index,
                    optionText: item.optionText
                })
            }
        })
        this.setState({
            dropdownForm: { ...this.state.dropdownForm, options: newArrayAdd },
            statusOtherOption: false
        }, () => {
            this.props.onChangeForm(this.state.dropdownForm, this.props.component)
        })
    }
    onChangeFormVariable = (e) => {
        this.setState({
            dropdownForm: { ...this.state.dropdownForm, sectionVariable: e.target.value },
        }, () => {
            this.props.onChangeForm(this.state.dropdownForm, this.props.component)
        })
    }
    confirmEditHeader = () => {
        this.setState({
            editHeader:false,
        })
    }
    render() {
        const { listDropdown, statusOtherOption, dropdownForm, hoverDelete, editHeader, hoverEditBtn, hoverRemoveLabelBtn } = this.state
        const { component, defaultTemplate } = this.props
        return (
            <div className={ this.props.snapshot.isDragging ? "component-form dragging " : "component-form"}>
                <div className="component-delete">
                    <button onClick={ (e) => this.props.showConfirmDeleteFormElement(e, component) }
                          className={hoverDelete ? 'icon-v19-delete-active' : "icon-v19-delete" }
                            disabled={defaultTemplate}
                          onMouseEnter={ (e) => this.onHoverActionComponent(e)}
                          onMouseLeave={() => this.onLeaveActionComponent()}></button>
                </div>
                <div className="component-content">
                    <div className="content-input-form-variables">
                        <span className="header-checkbox">
                           {/*<Checkbox onChange={(e) => this.onChangeCheckbox(e, 'query')}*/}
                            {/*          checked={ dropdownForm.queryForSearch }>Query for search</Checkbox>*/}
                            <Checkbox onChange={(e) => this.onChangeCheckbox(e, 'mandatory')}
                                      disabled={defaultTemplate}
                                      checked={ dropdownForm.mandatoryCheck }>Mandatory</Checkbox>
                        </span>
                        {/*<span className="content-variables">*/}
                        {/*     <span className="variables-diaz">#</span>*/}
                        {/*<Input*/}
                        {/*    className="input-form-variables"*/}
                        {/*    placeholder="Form variable"*/}
                        {/*    value={dropdownForm.sectionVariable}*/}
                        {/*    onChange={(e) => this.onChangeFormVariable(e)}*/}
                        {/*/>*/}
                        {/*</span>*/}
                    </div>
                    <div className="header-component header-component-update">
                        <span className="header-content">
                              { editHeader ?
                                  <div>
                                      <Input defaultValue={dropdownForm.header} className="input-other-option"
                                             onChange={ (e) => this.addTitleHeader(e, 'header')}
                                             onPressEnter={() => this.confirmEditHeader()}
                                      />
                                      <button className={ hoverEditBtn ? "icon-v21-edit-active" : "icon-v21-edit"}
                                            onClick={(e) => this.confirmEditHeader()}
                                              disabled={defaultTemplate}
                                            onMouseEnter={() => this.onHoverEditBtn()}
                                            onMouseLeave={() => this.onLeaveEditBtn()}
                                      ></button>
                                  </div>
                                  :
                                  <div>
                                      <span className="header-title header-title-update">{dropdownForm.header}</span>
                                      <button className={ hoverEditBtn ? "icon-v21-edit-active" : "icon-v21-edit"}
                                            onClick={(e) => this.onClickEditHeader(e, 'header')}
                                              disabled={defaultTemplate}
                                            onMouseEnter={() => this.onHoverEditBtn()}
                                            onMouseLeave={() => this.onLeaveEditBtn()}
                                      ></button>
                                  </div>
                              }
                          </span>
                    </div>
                    <div className="dropdown-content dropdown-content-select">
                        <Select className='select-data-form'
                                placeholder="Select"
                               dropdownClassName={'select-data-form-dropdown'}
                                onChange={(e) => this.onSelectOption(e)}>
                            { dropdownForm.options && dropdownForm.options.length > 0 && dropdownForm.options.map((list, index) => {
                                return (
                                        <Option key={ index } value={ list.optionText }>{ list.optionText }</Option>
                                )
                            })

                            }
                        </Select>
                        {
                            statusOtherOption &&
                            <div className="content-options">
                                { dropdownForm.valueOptionsChoice && dropdownForm.valueOptionsChoice.length > 0 && dropdownForm.valueOptionsChoice.map((item, index) => {
                                    return (
                                        <span className="option-add" key={item.optionId}>
                                            <Input className="input-other-option"
                                                   value={item.optionText}
                                                //onPressEnter={(e) => this.addOtherOptionChange(e, item, index)}
                                                   onChange={(e) => this.addOtherOptionChange(e, item, index)}
                                            />
                                            <button onClick={(e) => this.addRemoveInputs(e, 'decrementOption', item, index)}
                                                    onMouseEnter={() => this.onHoverRemoveLabelBtn(index)}
                                                    onMouseLeave={() => this.onLeaveRemoveLabelBtn()}
                                                    className={ hoverRemoveLabelBtn === index ? "icon-v16-minus-active" : "icon-v16-minus"}/>
                                        </span>
                                    )
                                })
                                }
                                <span className={'btn-add-wrapper'}>
                                   <button onClick={(e) => this.addRemoveInputs(e, 'addField')} className="button-add">+ Add field</button>
                                   <button onClick={(e) => this.addRemoveInputs(e, 'addFieldFirst')} className="button-add">+ Add field at head</button>
                                </span>
                                <button onClick={(e) => this.addSubmit(e)} className="button-submit">Submit</button>

                                <span className="content-info-dynamic">
                                    <span className="dynamic-form">
                                        <span className="dynamic">Dynamic Form Item</span>
                                        <span className="icon-v211-file-redacted"></span>
                                    </span>
                                    <span className="info-dynamic">
                                        <span>Add or remove form items dynamically. </span>
                                        <span className="add">add</span>
                                        <span> function support config initial value.</span>
                                    </span>
                                </span>
                            </div>
                        }
                        {
                            !statusOtherOption &&
                            <span className="action-options-add">
                            <span className="add-option" onClick={(e) => this.addOptionDropdown(e)}>Add Option</span>
                            <span className="add-other-option" onClick={(e) => this.displayInputOtherOptionDropdown(e)}>Add "Other" Option</span>
                        </span>
                        }
                    </div>
                </div>
                <div className="component-move">
                    <span onMouseEnter={() => this.props.onStateProvidedDraggable(component)} className="icon-v211-grid-view-1"></span>
                </div>
            </div>
        )
    }
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(DropdownForm)