import React from 'react'
import PropTypes from 'prop-types'
import { ComposableMap, ZoomableGroup, Geographies, Geography, Marker } from 'react-simple-maps'
import world from './world-countries.json'
//import maps from '../../../../assets/maps'
import { getMapStatistics } from '../../../../api/AssetRegister'
import { scaleLinear } from 'd3-scale'
//const geoUrl = "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json"
const geoUrl = world
const cityScale = scaleLinear()
  .domain([0, 100])
  .range([1, 25])

class MapPoint extends React.Component {

  constructor() {
    super()
    this.state = {
      listCities: []
    }
  }

  componentDidMount() {
    getMapStatistics().then((response) => {
      if (response.status === 200 && response.data) {
        response.data.map(function(city, index) {
          const temp = city.coordinates[0]
          response.data[index].coordinates[0] = city.coordinates[1]
          response.data[index].coordinates[1] = temp
          return true
        })
        this.setState({
          listCities: response.data
        })
      }
    })
  }

  render() {
    const uniqueListCities = this.state.listCities && this.state.listCities.length > 0 && this.state.listCities.filter((a) => !a.coordinates.includes(undefined))
    return (
      <div className="statistic-map-content">
        <ComposableMap
          projectionConfig={{ scale: 205 }}
          width={980}
          height={551}
          style={{
            width: '100%',
            height: 'auto',
          }}
        >
          <ZoomableGroup>
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
                geographies.map((geo) => (
                    <Geography
                        key={geo.rsmKey}
                        geography={geo}
                        style={{
                          default: {
                            fill: '#d8d8d8',
                            stroke: '#d8d8d8',
                            strokeWidth: 0.75,
                            outline: 'none',
                          },
                          hover: {
                            fill: '#d8d8d8',
                            strokeWidth: 0.75,
                            stroke: '#d8d8d8',
                            outline: 'none',
                          },
                          pressed: {
                            fill: '#d8d8d8',
                            strokeWidth: 0.75,
                            stroke: '#d8d8d8',
                            outline: 'none',
                          },
                        }}
                    />
                ))
            }
          </Geographies>
            { uniqueListCities && uniqueListCities.length > 0 && uniqueListCities.map((city, i) => (
                <Marker key={i} coordinates={city.coordinates}>
                  <circle fill="#F53"
                          r={cityScale(city.percent)}
                          style={{
                            //default: { fill: '#fac669' },
                            // hover: { fill: '#fa0099' },
                            // pressed: { fill: '#00f6fa' },
                            fill: '#fff',
                            stroke: '#fac669',
                            strokeWidth: 3,
                            opacity: 0.9
                          }}
                  />
                </Marker>
            ))
            }
          </ZoomableGroup>
          {/*<ZoomableGroup center={[0, 20]} disablePanning>*/}
          {/*  <Geographies geography={maps}>*/}
          {/*    {(geographies, projection) => geographies.map((geography, i) =>*/}
          {/*      geography.id !== 'ATA' && (*/}
          {/*        <Geography*/}
          {/*          key={i}*/}
          {/*          geography={geography}*/}
          {/*          projection={projection}*/}
          {/*          style={{*/}
          {/*            default: {*/}
          {/*              fill: '#d8d8d8',*/}
          {/*              stroke: '#d8d8d8',*/}
          {/*              strokeWidth: 0.75,*/}
          {/*              outline: 'none',*/}
          {/*            },*/}
          {/*            hover: {*/}
          {/*              fill: '#d8d8d8',*/}
          {/*              strokeWidth: 0.75,*/}
          {/*              stroke: '#d8d8d8',*/}
          {/*              outline: 'none',*/}
          {/*            },*/}
          {/*            pressed: {*/}
          {/*              fill: '#d8d8d8',*/}
          {/*              strokeWidth: 0.75,*/}
          {/*              stroke: '#d8d8d8',*/}
          {/*              outline: 'none',*/}
          {/*            },*/}
          {/*          }}*/}
          {/*        />*/}
          {/*      ))}*/}
          {/*  </Geographies>*/}
          {/*  <Markers>*/}
          {/*    {*/}
          {/*      this.state.listCities.map((city, i) => (*/}
          {/*        <Marker key={i}*/}
          {/*          marker={city}*/}
          {/*          style={{*/}
          {/*            default: { fill: '#fac669' },*/}
          {/*            hover: { fill: '#1f4cfa' },*/}
          {/*            pressed: { fill: '#fac669' },*/}
          {/*          }}*/}
          {/*        >*/}
          {/*          <text*/}
          {/*            style={{*/}
          {/*              fontFamily: 'Roboto, sans-serif',*/}
          {/*              fill: '#607D8B',*/}
          {/*            }}*/}
          {/*            x="-25" y="-60" className={'rsm-marker__info'}>{city.name}</text>*/}
          {/*          <text x="-15" y="-35" className={'rsm-marker__info'}>{`${ city.percent }%`}</text>*/}
          {/*          <circle*/}
          {/*            cx={0}*/}
          {/*            cy={0}*/}
          {/*            r={cityScale(city.percent)}*/}
          {/*            style={{*/}
          {/*              default: { fill: '#fac669' },*/}
          {/*              hover: { fill: '#fa0099' },*/}
          {/*              pressed: { fill: '#00f6fa' },*/}
          {/*              fill: '#fff',*/}
          {/*              stroke: '#fac669',*/}
          {/*              strokeWidth: 6,*/}
          {/*              opacity: 0.9*/}
          {/*            }}*/}
          {/*          />*/}
          {/*        </Marker>*/}
          {/*      ))*/}
          {/*    }*/}
          {/*  </Markers>*/}
          {/*</ZoomableGroup>*/}
        </ComposableMap>
      </div>
    )
  }
}

MapPoint.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
  valuePrefix: PropTypes.string,
  valueNumber: PropTypes.number,
  valueSuffix: PropTypes.string,
  onSelectCard: PropTypes.func
}

export default MapPoint