import React from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'

import Button from '../../Common/Button'
import Loading from '../../Common/Loading'

import {
  apiPrivacyAddAlert,
  apiPrivacySetCategoryProperty
} from '../../../api/Privacy'

class ButtonAddAlert extends React.Component {
  constructor() {
    super()

    this.state = {
      isCreatingAlert: false,
      redirect: {
        value: false,
        id: null
      }
    }
  }

  newAlert = () => {
    this.setState({ isCreatingAlert: true })
    
    apiPrivacyAddAlert(this.props.categoryId)
    .then((response) => {
      const alert = response.data.value.category
      
      apiPrivacySetCategoryProperty(alert.id, 'type', 'alert')
        .then(() => {
          this.setState({
            redirect: {
              value: true,
              id: alert.id
            }
          })
        })
    })
  }

  render = () => {
    return (
      <div>
        { this.state.redirect.value &&
          <Navigate to={ `/privacy/alert/edit/${ this.state.redirect.id }` } />
        }

        { this.state.isCreatingAlert
          ? <Loading />
          : <Button onClick={ this.newAlert }>New alert</Button>
        }
      </div>
    )
  }
}

ButtonAddAlert.propTypes = {
  categoryId: PropTypes.number.isRequired
}

export default ButtonAddAlert