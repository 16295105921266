import React from 'react'
import PropTypes from 'prop-types'

class StatusLevel extends React.Component {
  render = () => {
    return (
      <select
        name="selectedStatusLevel"
        value={ this.props.value }
        onChange={ this.props.onChange }>
        <option value="">choose...</option>
        <option value="high">High</option>
        <option value="medium">Medium</option>
        <option value="low">Low</option>
      </select>
    )
  }
}

StatusLevel.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string
}

export default StatusLevel