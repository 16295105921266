import React from 'react'
import PropTypes from 'prop-types'

import { sortValuesByField } from '../../../utils'

import WithConnectors from '../HOC/WithConnectors'

const ConnectorList = ({ connectors, onChange }) => (
  <select name="selectedConnector" onChange={ onChange }>
    <option value="">choose...</option>
    { sortValuesByField(connectors, 'name').map((connector, index) => (
      <option key={ `connector-${ index }` } value={ connector.id }>
        { connector.name }
      </option>
    )) }
  </select>
)

ConnectorList.propTypes = {
  connectors: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
}

export default WithConnectors(ConnectorList)