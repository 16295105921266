import initialState from './initialState'
import { RECEIVE_SUBSCRIPTION_INFO } from '../actions/actionTypes'

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_SUBSCRIPTION_INFO:
      const activeSubscription = action.subscription
      const plan = activeSubscription ? activeSubscription.plan : null

      return {
        ...state,
        subscription: {
          ...state.subscription,
          subscription: action.subscription,
          unlimitedPlan: true,
          plan
        }
      }
    default:
      return state
  }
}