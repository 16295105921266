import React, { Component } from 'react'
import './Shell.scss'
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';

export class SarShell extends Component {
  constructor() {
    super()

    this.state = {
      isFetching: true
    }

  }

  render() {
    return (
      <>
        {this.props.children}
        <Outlet />
      </>
    )
  }
}



const stateMap = (state) => ({
  isLoading: state.shellReducer.shell.loading
})

export default connect(stateMap)(SarShell)
