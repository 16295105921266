import React from 'react'
import PropTypes from 'prop-types'

import './TaxonomyItem.css'

import CategoryItem from './CategoryItem'
import {
  apiOspreyRenameTaxonomy
} from '../../../../api/OspreyAnalytics.js'

class TaxonomyItem extends React.Component {
  state = {
    taxonomy: this.props.taxonomy
  }

  componentDidMount = () => {
    
    this.setState({
      taxonomy: this.props.taxonomy
    })
  }

  renameTaxonomy = (value) => {
    const { taxonomy } = this.props

    apiOspreyRenameTaxonomy(taxonomy.id, value)
      .then((response) => {
        if (response.data.status === 'Success') {
          console.log('done')
        }
      })
  }

  render = () => {
    const { taxonomy } = this.props

    return (
      <div className="taxonomyItem__wrapper">
        <header className="taxonomyItem__header">
          <h1 className="taxonomyItem__title">
            { /* can't do this until we fix the renameTaxonomy endpoint
              this.state.taxonomy
              ? <EditInPlace
                value={ this.state.taxonomy.name }
                save={ this.renameTaxonomy } />
              : <Loading/>*/
              this.state.taxonomy.name
            }

          </h1>
        </header>
        <div className="taxonomyItem__content">
          <ul>
            <CategoryItem category={ taxonomy.category } key={ taxonomy.category.id }/>
          </ul>
        </div>
      </div>
    )
  }
}

TaxonomyItem.propTypes = {
  taxonomy: PropTypes.object.isRequired
}

export default TaxonomyItem