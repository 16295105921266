import React from 'react'
import {
  apiFileUploadSarSteps, deleteAllDocuments, deleteDocumentById,
  getPreviewSarDocByThumbnailId,
  getSarDocByThumbnailId,
  getSarDocumentsThumbnailId,
  getSarDocumentsSaved, sarEndRequestData
} from '../../../../api/Sar'
import { Modal, Spin, Select, Button, Switch, Tooltip, notification, Icon } from 'antd'
import CustomNotificationManager from '../../../Common/CustomNotificationManager'
import PDFViewer from '../../../../utils/PDFViewer'
import PDFJSBackend from '../../../../utils/backends/PDFJs'
import { generateBlobFromBase64 } from '../../../../utils/GenerateBlob'
//import Loading from '../../../Common/Loading'
import ImportDataStep from './ImportDataStep'
import swal from 'sweetalert2'
//import Card from 'antd/es/card'
//import Meta from 'antd/es/card/Meta'
import imgFolderSmallParent from '../../../../assets/smart-folder-medium-parent.svg'
import { connect } from 'react-redux'
import moment from "moment";
import {setSmartBoxNotSarTracker} from "../../../../actions/smartBoxActions";

//const RadioGroup = Radio.Group
const { Option } = Select
class SearchFindStep extends React.Component {

  constructor() {
    super()
    this.state = {
      fileList: [],
      previewImage: '',
      previewVisible: false,
      loading: false,
      documents_found: null,
      loadingComplete: false,
      documentsFound: false,
      completeDisabled: true,
      copyFileList: [],
      sendEmailNotification: false,
      modalVisibleAttacheBox: false,
      valueSelectedBox: '',
      visibleStepImportData: false,
      textTooltipBox: 'Please attach a box.',
      visibleMessageAttach: false,
      disabledAttachABox: false,
      loadingImportData: false
    }
  }

  componentDidMount() {
    if (this.props.searchFindStep && this.props.searchFindStep.stepState !== 'CREATED' && this.props.searchFindStep.documentsFound) {
      //this.fetchImages(this.props.sar.id, this.props.searchFindStep)
      //this.fetchSarDocumentsSaved(this.props.sar.id, this.props.clientId, this.props.searchFindStep)
      this.props.onImportDataBoxSar(this.props.sar.boxName)
    }
    this.onCheckStartImportData()
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.searchFindStep !== this.props.searchFindStep) && (prevProps.searchFindStep.completed !== this.props.searchFindStep.completed)) {
      //this.fetchImages(prevProps.sar.id)
    }
  }
   onCheckStartImportData = () => {
    const { sar } = this.props
     if (sar.start_import_data === true) {
       this.setState({
         visibleStepImportData: true
       })
     }
   }
  onChangeNotification = () => {
    this.setState({
      sendEmailNotification: !this.state.sendEmailNotification
    })
  }
  async fetchSarDocumentsSaved(idSar, clientId, searchFindStep) {
    const response = await getSarDocumentsSaved(idSar, clientId)
    this.setState({
      fileList: response.data,
      copyFileList: response.data,
      documentsFound: searchFindStep.stepState !== 'CREATED',
      completeDisabled: searchFindStep.stepState === 'CREATED',
      visibleStepImportData: true
    })
  }

  onDocumentsFound = (e) => {
    if (e.target.value) {
      this.setState({
        documentsFound: e.target.value,
        completeDisabled: true
      })
    } else {
      this.setState({
        documentsFound: e.target.value,
        completeDisabled: false
      })
    }
  }
  onDocumentsFoundSwitcher = (documentsFound) => {
    const { valueAttachBox } = this.props
    this.props.onDocumentsFoundData(documentsFound)
    if (documentsFound) {
      this.setState({
        documentsFound: documentsFound,
        completeDisabled: true,
        disabledAttachABox: false
      })
    } else {
      this.setState({
        documentsFound: documentsFound,
        completeDisabled: false,
        disabledAttachABox: false
      })
    }
    if (documentsFound && !valueAttachBox) {
      this.setState({
        visibleMessageAttach: true
      })
    } else if (documentsFound && valueAttachBox) {
      this.setState({
        visibleMessageAttach: false
      })
    }
    else {
      this.setState({
        visibleMessageAttach: false
      })
    }
  }

  handleSearchStep = () => {
    const { valueAttachBoxId } = this.props
    let input = {}
    let fileList
    let solrFiles = []
    if (this.state.documentsFound) {
      fileList = this.state.fileList && this.state.fileList.length > 0 ? this.state.fileList.map((file) => file.id) : []
      solrFiles = this.state.fileList && this.state.fileList.length > 0 ? this.state.fileList.map((file) => file.id) : []

      input = {
        sar_id: this.props.sar.id,
        ids: fileList,
        documents_found: true,
        solr_ids: solrFiles,
        sendEmailNotification: this.state.sendEmailNotification,
        box_id: valueAttachBoxId.toString()
      }
    } else {
      input = {
        sar_id: this.props.sar.id,
        documents_found: false,
        solr_ids: solrFiles,
        ids: [],
        sendEmailNotification: this.state.sendEmailNotification,
        box_id: valueAttachBoxId.toString()
      }
    }
    this.setState({
      loadingComplete: true
    }, () => {
      getSarDocByThumbnailId(input)
        .then((response) => {
          this.props.refreshData(response.data)
          CustomNotificationManager.success('Documents successfully uploaded.', 'Success')
          this.setState({
            uploadFile: false,
            loadingComplete: false
          })
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
      })
    })
  };

  endRequestData = () => {
    this.setState({
      loadingComplete: true
    }, () => {
      sarEndRequestData(this.props.sar.id)
          .then((response) => {
            if (response.status === 200) {
              this.handleSearchStep()
              this.setState({
                loadingComplete: false
              })
            }
          }).catch((error) => {
            if (error?.response?.status === 504) {
              CustomNotificationManager.cleanBuffer('warning', '')
            }else{
              console.log(error)
            }
      })
    })
  }
  customUploadFile = (file, fileList) => {
    file.status = 'uploading'
    const maxSizeFile = file.size / 1024 / 1024 < 5
    const filteredList = fileList.filter((file) => file.size / 1024 / 1024 < 5)
    this.setState({
      completeDisabled: true,
      copyFileList: [...this.state.copyFileList.concat(filteredList)],
      fileList: [...this.state.fileList, ...filteredList]
    }, () => {
      if (maxSizeFile) {
        this.handleFileChange(file)
      } else {
        CustomNotificationManager.warning('File must be smaller than 5MB!', 'File Size Warning')
      }
    })
    return false
  };

  handleFileChange(file) {
    apiFileUploadSarSteps(file, this.props.sar.id)
      .then((response) => {
        const index = this.state.fileList.findIndex((x) => x.uid === file.uid)
        if (index !== -1) {
          this.setState({
            fileList: [
              ...this.state.fileList.slice(0, index),
              Object.assign({}, this.state.fileList[index], response.data),
              ...this.state.fileList.slice(index + 1)
            ]
          }, () => {
            if (this.state.fileList.length === this.state.copyFileList.length) {
              const statuses = this.state.fileList.filter((file) => file.status !== 'uploading')
              if (statuses.length === this.state.copyFileList.length) {
                this.setState({
                  completeDisabled: false
                })
              }
            }
          })
        }
        return false
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
    })
  }

  async fetchImages(id, searchAndFindStep) {
    const response = await getSarDocumentsThumbnailId(id)
    this.setState({
      fileList: response.data,
      copyFileList: response.data,
      documentsFound: searchAndFindStep.stepState !== 'CREATED',
      completeDisabled: searchAndFindStep.stepState === 'CREATED',
      visibleStepImportData: true
    })
  }

  handleCancel = () => this.setState({ previewVisible: false })

  handlePreview = (file) => {
    const { sar } = this.props

    if (sar) {
      this.setState({
        previewVisible: true,
        loading: true
      }, () => {
        getPreviewSarDocByThumbnailId(file.id).then((response) => {
          switch (response.data.fileType) {
            case 'image/png':
            case 'image/jpg':
            case 'image/jpeg':
              const imageUrl = `data:${ response.data.fileType };base64, ${ response.data.doc }`
              this.setState({
                renderingBlock: <img alt="example" style={{ width: '100%' }} src={imageUrl}/>,
                loading: false
              })
              break
            case 'application/pdf':
              const { doc } = response.data
              this.setState({
                renderingBlock: <PDFViewer backend={PDFJSBackend} src={generateBlobFromBase64(doc)} toolbar={0}/>,
                loading: false
              })

              break
            default:
              break
          }
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
        })
      })
    } else {
      return false
    }

  };

  onRemoveFileClicked = (file) => {
    swal.fire({
      text: 'Do you want to delete this file?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3DD8DB',
      cancelButtonColor: '#D33D33',
      confirmButtonText: 'Yes, remove file'
    }).then((result) => {
      if (result.value) {
        this.onRemoveFile(file)
      } else {
        CustomNotificationManager.info('Action cancelled', 'Aborted')
      }
    })
  }

  onRemoveFile = (file) => {
    deleteDocumentById(file.id).then(() => {
      this.setState((state) => {
        const index = state.fileList.indexOf(file)
        const newFileList = state.fileList.slice()
        newFileList.splice(index, 1)
        return {
          fileList: newFileList,
          copyFileList: newFileList
        }
      })
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  onClearAllFileClicked(e) {
    e.preventDefault()
    swal.fire({
      text: 'Do you want to clear all files?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3DD8DB',
      cancelButtonColor: '#D33D33',
      confirmButtonText: 'Yes, clear files'
    }).then((result) => {
      if (result.value) {
        this.clearFilesList()
      } else {
        CustomNotificationManager.info('Action cancelled', 'Aborted')
      }
    })
  }

  clearFilesList = () => {
    deleteAllDocuments(this.props.sar.id).then(() => {
      this.setState({
        fileList: [],
        copyFileList: [],
        completeDisabled: true
      })
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  generateCardContent = (index) => {
    if (index && index.thumbUrl) {
      return <img alt="" src={index.thumbUrl}/>
    } else if (index && index.status === 'error') {
      return <div className="image-loading-container">
        <div className="error-message">
          <Icon type="warning"/>
          <span>Something went wrong while converting file...</span>
        </div>
      </div>
    } else if ((/(doc|docx|jpeg|jpg|xls|xlsx|png|ppt|pptx)$/i).exec(index.type || index.name || index.name.split('.').pop())) {
      return <div className="image-loading-container">
        <Spin
          tip={`Converting ${ index.type ? index.type.split('/').pop() : index.name.split('.').pop() } file, it may take a while...`}/>
      </div>
    } else if (/([a-zA-Z0-9\s_\\.\-:])+(.pdf)$/.exec(index.type || index.name)) {
      return <div className="image-loading-container">
        <Spin tip={'Uploading file, it may take a while...'}/>
      </div>
    }
  }

  onClickImportDataPage = () => {
    const { listFilesOnBox, searchFindStep, sar } = this.props
    this.setState({
      loadingImportData: true
    }, () => {
      this.setState({
        visibleStepImportData: true,
        disabledAttachABox: true
      })
      if (listFilesOnBox && listFilesOnBox.length > 0) {
        const notOpenedData = {
          nameActionEvent: `Update to ${ searchFindStep.id } in SAR ${ sar.id }`,
          descriptionActionEvent: 'Opened their SAR data',
          timesEvent: moment().format('DD/MM/YYYY HH:mm:ss'),
          status: 'update-sar'
        }
        const dataNotification = []
        dataNotification.push(notOpenedData)
        //this.props.setSmartBoxNotSarTracker(dataNotification)
      }
    })
  }
  onAttachBoxModal = () => {
    this.setState({
      modalVisibleAttacheBox: true,
      visibleMessageAttach: false
    })

  }
  onClickImportBoxSelected = () => {
    const { valueSelectedBox } = this.state
    this.props.onClickImportBox(valueSelectedBox)
    this.setState({
      modalVisibleAttacheBox: false,
      disabledAttachABox: false
    })
  }
  chooseAttachBox(e) {
    this.setState({
      valueSelectedBox: e
    })
  }
  handleCancelModalBox = () => {
    this.setState({
      modalVisibleAttacheBox: false,
      valueSelectedBox: ''
    })
  }
  onClickNextStep = () => {
    const { current, sendStep, sar } = this.props
    if (!sendStep && sar && sar.lastStep === 'COMPLETE') {
      this.props.nextStep(current + 2)
    } else if (!sendStep) {
      this.props.nextStep(current)
    } else {
      this.props.nextStep(current + 1)
    }
  }
  onClickBackStep = () => {
    const { current } = this.props
    this.props.beckStep(current - 1)
  }
  onDisabledAttachABox(disabledAttachABox) {
    this.setState({
      disabledAttachABox: disabledAttachABox
    })
  }
  onClickAttachFalse = () => {

  }
  onCheckImportEmptyFiles = (files) => {
    if (files && files.length > 0) {
      this.setState({
        visibleStepImportData: true,
        disabledAttachABox: true
      })
    } else {
      notification.info({
        message: 'No files found',
        description: 'Please attach another box.',
        duration: 5
      })
      this.setState({
        visibleStepImportData: false,
        disabledAttachABox: false
      })
    }
  }
  onChooseEmptyFileSelectBox = (e) => {
    const { listFilesOnBox } = this.props
    if (e.target.value === 'choose-files' && listFilesOnBox === null) {
      notification.info({
        message: 'No files found',
        description: 'Please attach another box.',
        duration: 5
      })
      this.setState({
        visibleStepImportData: false,
        disabledAttachABox: false
      })
    }
  }
  render() {
    const { loadingComplete, completeDisabled, modalVisibleAttacheBox, valueSelectedBox, visibleStepImportData, textTooltipBox, fileList, visibleMessageAttach,
      loadingImportData } = this.state
    const { respondStep, searchFindStep, sar, tableListFile, listSarBox, valueAttachBox, listFilesOnBox, onSendStatusStatusReturnApi, valueAttachBoxId, statusResponseImport, sendStep } = this.props
    const { documentsFound } = this.state
    let { disabledAttachABox } = this.state
    const lastSarStepCompleted = respondStep ? respondStep.completed : false
    if (onSendStatusStatusReturnApi === 500) {
      disabledAttachABox = false
    }
    // else if (onSendStatusStatusReturnApi === 200 && statusResponseImport === 'SUCCESS') {
    //   disabledAttachABox = true
    // }
    else if (onSendStatusStatusReturnApi === 200 && statusResponseImport === 'FAILED') {
      disabledAttachABox = false
    }
    return (
      <React.Fragment>
        {/*<div className="tracker-surname-contain">*/}
        {/*  <span className="tracker-surname">{`${ sar.forename } ${ sar.surname }`}</span>*/}
        {/*</div>*/}
        <div className="tracker-header-steps">
          <div className="contain-name-back-step">
            <div className="back-step back-step-hover">
              <span className="icon-v10-back" onClick={this.onClickBackStep}></span>
            </div>
            <div className="tracker-fullname-contain">
              <span className="tracker-surname">{`${ sar.forename } ${ sar.surname }`}</span>
            </div>
          </div>
          <Tooltip placement="top" title={ textTooltipBox } visible={ visibleMessageAttach } overlayClassName="box-info-column">
            <div className="attach-box-contain">
              { (valueAttachBox || this.props.sar.boxName) ?
                <img alt={ valueSelectedBox } src={ imgFolderSmallParent }/>:
                <span className="icon-v10-smart-folder-medium-parent-2"></span>
              }
              <span className={ disabledAttachABox ? 'attach-box disabled-attach' : searchFindStep && searchFindStep.completed ? 'attach-box disabled-attach' :
                !searchFindStep ? 'attach-box disabled-attach' : !documentsFound ? 'attach-box disabled-attach' : 'attach-box' }
              onClick={ disabledAttachABox ? this.onClickAttachFalse : searchFindStep && searchFindStep.completed ? this.onClickAttachFalse :
                !searchFindStep ? this.onClickAttachFalse : !documentsFound ? this.onClickAttachFalse : this.onAttachBoxModal }>
                { valueAttachBox ? valueAttachBox : this.props.sar.boxName ? this.props.sar.boxName : 'Attach a box' }
              </span>
            </div>
          </Tooltip>
          <div className="contain-save-last-step">
            <div className="save-close-contain">
              <span className="icon-v10-save"></span>
              <span className="save-and-close">
             Save & Close
              </span>
            </div>
            <div className={ !sendStep && sar && sar.lastStep !== 'COMPLETE' ? 'next-step next-step-disabled' : 'next-step next-step-hover' }>
              <span className="icon-v10-forth" onClick={this.onClickNextStep}></span>
            </div>
          </div>
        </div>
        { !visibleStepImportData ?
          <div className="tracker-box tracker-box-data" style={ { marginTop: 0 } }>
            <div className="tracker-form">
              <header className="tracker-step-header">
                <div className="tracker-step-header__top">
                  <h1 className="tracker-step-header__top__step">Data<Tooltip overlayClassName="help-message-locations" title={<div className="content-help-message-locations">
                    <span className="text-information">Import data from Smartbox to the request.</span>
                    <span className="more-info-link" onClick={ () => window.open('https://support.smartbox.ai/support/solutions/articles/47001174700', '_blank') }> More info. </span>
                  </div>}>
                    <span className="icon-v14-question"></span>
                  </Tooltip></h1>
                </div>
              </header>
              { lastSarStepCompleted && respondStep.respondAnswer &&
              <div className="tracker-step-content">
                <div className="content-documents-subject">
                  <h3>Are there any documents about the subject?</h3>
                  <div className="teams switcher-option-check">
                    <Switch
                      checked={ documentsFound }
                      checkedChildren="YES"
                      unCheckedChildren="NO"
                      disabled={ disabledAttachABox === true || searchFindStep.stepState !== 'CREATED' }
                      //disabled={ searchFindStep.stepState !== 'CREATED' }
                      onChange={ () => this.onDocumentsFoundSwitcher(!documentsFound) }
                    />
                  </div>
                  {/*<div className="tracker-radio">*/ }
                  {/*  <RadioGroup onChange={this.onDocumentsFound}*/ }
                  {/*    value={documentsFound} disabled={searchFindStep.stepState !== 'CREATED'}*/ }
                  {/*  >*/ }
                  {/*    <span className="custom-radio__yes">*/ }
                  {/*      <Radio value={true}>Yes</Radio>*/ }
                  {/*    </span>*/ }
                  {/*    <span className="custom-radio__no">*/ }
                  {/*      <Radio value={false}>No</Radio>*/ }
                  {/*    </span>*/ }
                  {/*  </RadioGroup>*/ }
                  {/*</div>*/ }
                </div>
                { documentsFound ?
                  <div className="tracker-content-button">
                    { valueAttachBox && <Button className="button-blue-light button-check-id button-search float-right"
                      onClick={ this.onClickImportDataPage } loading={ loadingImportData }
                    >IMPORT DATA
                    </Button>
                    }
                  </div> :
                  <div className="tracker-content-button tracker-end-request">
                    {(searchFindStep && !searchFindStep.completed) &&
                    <React.Fragment>
                      {
                        loadingComplete ?
                          <div className="search-and-find-loading-container">
                            <Spin className="finalize-loading" spinning={loadingComplete}/>
                          </div> :
                          <button className="button-blue-light button-check-id button-search float-right"
                            onClick={this.endRequestData}>END REQUEST
                          </button>
                      }
                    </React.Fragment>
                    }
                  </div>
                } 
              </div>
              }
            </div>
          </div>
          :
          <ImportDataStep
            onCallImportFilesData={ this.props.onCallImportFilesData }
            tableListFile={tableListFile}
            searchFindStep={searchFindStep}
            onChangeNotification={this.onChangeNotification}
            loadingComplete={loadingComplete}
            completeDisabled={completeDisabled}
            handleSearchStep={this.handleSearchStep}
            listFilesOnBox={listFilesOnBox}
            sar={sar}
            documentsFound={documentsFound}
            fileList={fileList}
            refreshData={this.props.refreshData}
            onDisabledAttachABox={this.onDisabledAttachABox}
            valueAttachBoxId={valueAttachBoxId}
            onCheckImportEmptyFiles={this.onCheckImportEmptyFiles}
            onChooseEmptyFileSelectBox={this.onChooseEmptyFileSelectBox}
            dataImportBox={ this.props.dataImportBox }/>
        }
        {/*{documentsFound &&*/}
        {/*<React.Fragment>*/}
        {/*  <h3 className="title-upload">Add each document seperately</h3>*/}
        {/*  <div className="tracker-upload-container upload-container text-container">*/}
        {/*    <div className="picture-search">*/}
        {/*      <div className="list-picture-container">*/}
        {/*        {fileList.map((index, i) => {*/}
        {/*          return (*/}
        {/*            <div className="view-cards-container" key={i}>*/}
        {/*              {*/}
        {/*                searchFindStep && !searchFindStep.completed ? <Icon type="delete" className="remove-document-icon" onClick={() => this.onRemoveFileClicked(index)}/> : ''*/}
        {/*              }*/}
        {/*              <Card*/}
        {/*                hoverable*/}
        {/*                cover={this.generateCardContent(index)}*/}
        {/*                onClick={() => this.handlePreview(index)}*/}
        {/*              >*/}
        {/*                <Meta title={index.name} description={index.totalPages ? `pages - ${ index.totalPages }` :*/}
        {/*                  <span style={{ visibility: 'hidden' }}>Pages</span>*/}
        {/*                }*/}
        {/*                />*/}
        {/*              </Card>*/}
        {/*            </div>*/}
        {/*          )*/}
        {/*        })}*/}
        {/*        { (searchFindStep && !searchFindStep.completed) &&*/}
        {/*          <span className="title-upload-documents">Add single documents</span>*/}
        {/*        }*/}
        {/*        <Upload*/}
        {/*          accept={'.pdf, .png, .jpeg, .jpg, .doc, .docx, .pptx, .xlsx, .xls'}*/}
        {/*          listType="picture-card"*/}
        {/*          fileList={fileList}*/}
        {/*          multiple={true}*/}
        {/*          showUploadList={{*/}
        {/*            showPreviewIcon: true,*/}
        {/*            showRemoveIcon: !searchFindStep.completed*/}
        {/*          }}*/}
        {/*          beforeUpload={this.customUploadFile}*/}
        {/*          onPreview={this.handlePreview}*/}
        {/*        >*/}
        {/*          {(searchFindStep && !searchFindStep.completed) ? uploadButton : ''}*/}
        {/*        </Upload>*/}
        {/*      </div>*/}

        {/*      <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel} className="pdf-modal">*/}
        {/*        <div className="custom-modal-body">*/}
        {/*          {loading ? <Spin spinning={loading} indicator={<div className="checkout-loading"><Loading/>*/}
        {/*          </div>}/> : renderingBlock}*/}
        {/*        </div>*/}
        {/*      </Modal>*/}
        {/*    </div>*/}

        {/*  </div>*/}
        {/*  {searchFindStep && !searchFindStep.completed &&*/}
        {/*  <div className="button-discard-changes">*/}
        {/*    <button className="button-red button-red--search"*/}
        {/*      onClick={(e) => this.onClearAllFileClicked(e)}>Discard*/}
        {/*      changes*/}
        {/*    </button>*/}
        {/*  </div>*/}
        {/*  }*/}

        {/*</React.Fragment>*/}
        {/*}*/}

        {/*{searchFindStep && !searchFindStep.completed &&*/}
        {/*<div className="tracker-content-links full_width">*/}
        {/*  <div className="tracker-form-link">*/}
        {/*    <Checkbox className="send-form" onChange={this.onChangeNotification}>Send Email*/}
        {/*      Notification</Checkbox>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*}*/}

        {/*{searchFindStep && !searchFindStep.completed &&*/}
        {/*<div className="tracker-content-button">*/}
        {/*  {*/}
        {/*    loadingComplete ?*/}
        {/*      <div className="search-and-find-loading-container">*/}
        {/*          Uploading and analyzing files, please wait up to one minute ...*/}
        {/*        <Spin className="finalize-loading" spinning={loadingComplete}/>*/}
        {/*      </div> :*/}
        {/*      <button className="button-blue-light button-check-id button-search float-right"*/}
        {/*        onClick={this.handleSearchStep} disabled={completeDisabled}>NEXT*/}
        {/*      </button>*/}
        {/*  }*/}
        {/*</div>*/}
        {/*}*/}

        {/*    </div>*/}
        {/*    }*/}
        {/*  </div>*/}
        {/*</div>*/}
        <Modal className="modal-attach-box" visible={ modalVisibleAttacheBox } footer={ null } closable={ false }>
          <div className="modal-attach-box-content">
            <div className="title-modal-attach">
              <img alt={ valueSelectedBox } src={ imgFolderSmallParent }/>
              <span>Attach a BOX</span>
            </div>
            <div className="content-attach-modal">
              <Select value={ valueSelectedBox === '' ? 'Choose BOX' : valueSelectedBox }
                className="select-box"
                suffixIcon={ <Icon type="caret-down" /> }
                onChange={ (e) => this.chooseAttachBox(e) }>
                { listSarBox && listSarBox.length > 0 && listSarBox.map((item, index) => {
                  return (
                    <Option key={item.id} className="item-options-box" value={ item.name }>
                      <img alt={ valueSelectedBox } className="icon-box" src={ imgFolderSmallParent }/>
                      { item.name }</Option>
                  )
                })
                }
              </Select>
            </div>
            <div className="footer-modal-buttons">
              <Button className="action-cancel-box" onClick={ this.handleCancelModalBox }>
                Cancel
              </Button>
              <Button disabled={ valueSelectedBox === '' } className={ valueSelectedBox === '' ? 'action-import-box action-import-box-disabled' : 'action-import-box' } onClick={ this.onClickImportBoxSelected }>
                Import
              </Button>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    )
  }

}

const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id
})
SearchFindStep = connect(mapStateToProps, {setSmartBoxNotSarTracker})(SearchFindStep)
export default SearchFindStep