import axios from 'axios'

export const addAutoAnnotateProcess = (host, annotatorID, categoryId, runImmediately) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/addAutoAnnotateProcess`,
    data: {
      sessionID: token,
      annotatorID: annotatorID, 
      categoryID: categoryId, 
      runImmediately: runImmediately
    }
  })
}

export const removeAutoAnnotateProcess = (host, annotatorID, categoryId) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/removeAutoAnnotateProcess`,
    data: {
      sessionID: token,
      annotatorID: annotatorID, 
      categoryID: categoryId, 
    }
  })
}

export const runAutoAnnotateProcess = (host, annotatorID, categoryId) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/runAutoAnnotateProcess`,
    data: {
      sessionID: token,
      annotatorID: annotatorID, 
      categoryID: categoryId, 
    }
  })
}

export const runCategoryAnnotator = (host, annotatorID, categoryId) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/runCategoryAnnotator`,
    data: {
      sessionID: token,
      annotatorID: annotatorID, 
      categoryID: categoryId, 
    }
  })
}

