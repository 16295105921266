import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import '../FormEmployeeWorkflow.scss'
import {Input, Select, Steps} from "antd";
const { Option } = Select;
const { Step } = Steps;
const { TextArea } = Input

export class DropdownTemplateForm extends Component {

    state = {
        dropdownForm:{
            options:[1,2,3]
        }

    }
    onChangeNameAndDetails = (e, key) => {
        const { component } = this.props
        switch (key) {
            case 'dropdown':
                this.props.onChangeNameAndDetails(e, key, component)
                break
            default:
                break
        }
    }
    render() {
        const {dropdownForm} = this.state
        const { formerEmployee, component, stepTicketActive } = this.props
        return (
            <div className="full-name-former">
                <span className="header-former header-former-address">{component.header && component.mandatory ? `${component.header}*` :
                    component.header ? component.header : 'Dropdown'}</span>
                <span className="input-content">
                    <Select className='select-data-form'
                            placeholder="Select"
                            dropdownClassName={'select-data-form-option'}
                            disabled={stepTicketActive.blockFormEdit === true}
                            value={component.dropdown ? component.dropdown : 'Select'}
                            onChange={(e) => this.onChangeNameAndDetails(e, 'dropdown')}>
                            { component.options && component.options.length > 0 && component.options.map((list, index) => {
                                return (
                                    <Option key={index} value={list.optionId}>{list.optionText}</Option>
                                )
                            })
                            }
                        </Select>
                </span>
            </div>
        )
    }
}


DropdownTemplateForm.propTypes = {
    folder: PropTypes.object,
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id
})
export default compose(
    connect(mapStateToProps),
)(DropdownTemplateForm)