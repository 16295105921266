import React, { Component } from 'react'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import { Document, pdfjs } from 'react-pdf'
import {
  getAnnotationsByClientId,
  apiGetAllCanvasPoints,
  apiUpdateCanvasPoints,
  getPreviewRedactingSarDocByThumbnailId,
  saveRedactedDocument, getDocNotes
} from '../../../api/Sar'
import '../../../style/common.scss'
import '../../Osp/Users/InviteUser/InviteUser.scss'
import './RedactionTool.scss'
import * as html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import CanvasPage from './CanvasPage'
import { Spin, Select, Affix, Drawer } from 'antd'
import { groupArrayBy } from '../../../utils'
import Icon from 'antd/es/icon'
import Search from 'antd/es/input/Search'
//import ButtonGroup from 'antd/es/button/button-group'
import Button from '../../Common/Button'
import swal from 'sweetalert2'
import Mark from 'mark.js'
import DocumentNote from '../Tracker/DocumentNote/DocumentNote'
import MetadataPage from './MetadataPage'
import AnnotateSelectedRedaction from './AnnotateSelectedRedaction'
import RedactedSidebar from '../RedactedSidebar'
import CommentsSidebar from './CommentsSidebar'
import PageSidebarContent from './PageSidebarContent'
import SearchImagesContent from './SearchImagesContent'

import AiAnnotationsSidebar from './AiAnnotationsSidebar'
import SpellCheckerPage from './SpellCheckerPage'
import {
  apiSmartBoxGetAllCanvasPoints,
  apiSmartBoxGetAnnotationsByClientId,
  apiSmartBoxGetNewQueryStatus,
  apiSmartBoxGetPreviewRedactingSarDocByThumbnailId,
  apiSmartBoxUpdateCanvasPoints,
  getIdFinaliseDocument,
  apiSmartBoxClearAllKeysOnPage,
  getRedactedKeys,
  apiSmartBoxClearCanvasPoints,
  setFileStatusOpenSmartView,
  apiSmartBoxUpdateCanvasPointsSaved,
  setClassificationStatusOpenSmartView,
  apiSmartBoxRemoveCanvas,
  apiSmartBoxGetPageCanvasPoints,
  apiSmartBoxAddTermToSearchHistory,
  apiGetSmartviewSearchHistoryTerms,
  apiSmartBoxSetDocumentRedactAllStatus,
  apiGetSetDocumentRedactAllStatus,
  apiRemoveAllSmartviewRedactions,
  apiSmartBoxSaveCanvasDocumentSmartview,
  apiGetTagDocumentClient,
  geRectanglesCoordsOcr,
  getRectanglesCoordsOcr,
  getRectanglesCoordsOcrPost,
  getSmartBoxDocNotes, apiSaveSelectedTextComment, apiUnlockSmartviewDocument, apiUpdateLockStatusSmartviewDocument
} from '../../../api/SmartBox'
import CustomNotificationManager from "../../Common/CustomNotificationManager";
import SearchHistory from "./SearchHistory";
//import ReviewPrevNextAiTag from "./ReviewPrevNextAiTag";
//import {timerCheckCanvas} from "../../../utils/SmartBox/SmartBoxMethods";
import cookie from "react-cookies";

const fabric = require('fabric').fabric

pdfjs.GlobalWorkerOptions.workerSrc = '/libs/pdfjs/build/pdf.worker.js'
//pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
const { Option } = Select
const Tools = {
  Circle: 'circle',
  Line: 'line',
  Pencil: 'pencil',
  Rectangle: 'rectangle',
  Select: 'select',
  Pan: 'pan'
}

export class RedactionTool extends Component {

  settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    arrows: true,
    focusOnSelect: true
  }

  constructor(props) {
    super(props)
    this.myDiv = React.createRef()
    // this.isRunning = false
    this.state = {
      pdfBytes: '',
      file: null,
      numPages: 0,
      pageNumber: 1,
      fillColor: 'transparent',
      width: null,
      height: null,
      pages: [],
      canUndo: false,
      canRedo: false,
      divPrint: '',
      annotationText: '',
      canvasData: [],
      canvasDataCopy: [],
      canvasPoints: [],
      tool: Tools.Select,
      history: [],
      modal: false,
      removeSelectedBtnDisabled: true,
      sketch: false,
      forPageNumber: null,
      totalPages: null,
      totalThumbnails: null,
      selectedTool: 'select',
      isDocumentLoaded: false,
      loading: true,
      loadingBeforeMark: false,
      annotationList: {},
      clientId: props.clientId,
      documentId: props.documentId,
      haveChanges: false,
      landscape: null,
      finalizeLoading: false,
      aspectRatio: null,
      dbWidth: null,
      dbHeight: null,
      printAnnotation: true,
      selectionMode: false,
      redactingMode: true,
      btnClasses: {
        'undo': { 'class': 'btn-action mr-2', 'disabled': false },
        'select': { 'class': 'btn-action mr-2', 'disabled': false },
        'draw': { 'class': 'btn-action mr-2', 'disabled': false },
        'clear': { 'class': 'btn-action mr-2', 'disabled': false },
        'preview': { 'class': 'btn-action mr-2', 'disabled': false },
      },
      navigationType: 'find-asset',
      btnAnnotationActiveIndex: 0,
      showCloseButton: true,
      matchedText: [],
      searchIndex: -1,
      currentMarkedIndex: 0,
      renderSearch: !!props.searchedValue,
      top: 0,
      annotationListVisible: false,
      objectHasAnnotation: false,
      viewAnnotationsRedaction: false,
      renderSelectText: false,
      renderCommentsText: false,
      renderSelectDraw: false,
      renderSelectTool: false,
      renderSelectToolMove: false,
      docAnnotations: [],
      searchValue: props.searchedValue,
      annotatedAiHTML: '',
      printLoading: false,
      disableClickOnPage: !props.searchedValue,
      documentNoteVisible: false,
      isDocumentReady: false,
      isSmartBoxApplication: props.isSmartBoxApplication,
      smartBoxDocumentId: props.smartBoxDocumentId,
      annotationSelectedItem: 0,
      itemsSelected: [],
      oldAnnotationSelectedItem: '',
      newRedactedLabel: '',
      idDoc: '',
      annotateSelectedModalVisible: false,
      numberOfAnnotateSelected: 0,
      colorPikerDisplay: false,
      colorSelected: { 'input': 'rgba(107, 91, 149, 1)', 'select': 'rgba(254, 178, 54, 1)' },
      annotateSelectBtnDisabled: true,
      annotateLabel: '',
      renderedPagesStatus: [],
      pageSidebarVisible: true,
      pageSidebarVisibleBefore: true,
      docAnnotationCategories: [],
      treeData: [],
      pagePointSumList: '',
      displaySearchButtons: !!props.searchedValue,
      alreadyRedactedKeys: [],
      alreadyRedactedList: [],
      treeDataAnnotations: [],
      newListKeysStandard: [],
      newListKeysManual: [],
      newListKeysBusiness: [],
      verificationOnClickClearAll: false,
      verificationOnClickClearAllManual: false,
      newListKeysRegEx: [],
      verificationOnClickClearAllRegEx: false,
      verificationOnClickClearAllBusiness: false,
      newListKeys: [],
      canvasCleaned: false,
      loadingProcess: false,
      loadingProcessMessage: 'Please wait until finalise process is finalised.',
      statusSelectAgainRedacted: '',
      statusKeysEmptyOnClearAll: false,
      valueStatusFile: 'File Status',
      statusSavedSmartView: false,
      drawByHandText: '!@#draw-by-hand#@!',
      emptiedPages: [],
      clearSketch: false,
      spanCoords: {},
      activeDocumentNote: false,
      activePrintDocument: false,
      activeClearPage: false,
      activeClearSelected: false,
      activeSearchWord: false,
      activeAnnotation: false,
      activeComments: false,
      activeSelectTool: false,
      activeSelectDraw: false,
      activeSelectText: false,
      activeUndo: false,
      activeRedo: false,
      treeDataSearchHistory: [],
      valueSelectNavigation: 'ai-results',
      isNextDocument: false,
      isNextDocumentId: {},
      disabledNextDocument: null,
      nextDocumentLength: 0,
      disabledBackDocument: null,
      checkStartRedactAllPagesNextFiles: false,
      checkStartRedactAllPagesBackFiles: false,
      valueStatusClassification: '',
      notifyNote: '',
      searchMatchedElement: [],
      searchMatchId: 0,
      checkObjectRefsStandard: [],
      statusSaveQuery: false,
      statusAfterRemove: false,
      activePreview: false,
      treeDataRegEx: [],
      statusClickPageSidebar: '',
      visibleDropdownStatus: false,
      visibleDropdownNavigation: false,
      drawSelectedMouse: false,
      allRedactedKeys: [],
      checkStartRedaction: null,
      statusElement: '',
      sourceActive: '',
      stateSidebarPages: false,
      clearPageDisabled: false,
      statusLastPages: true,
      statusRedactAll: "false",
      extractPointsToSave: [],
      extractPageNumber: [],
      dtoRedactAll: [],
      statusCloseSmartviewRedactAll: false,
      statusNextDocumentBusiness: false,
      statusNextDocumentRegEx: false,
      statusNextDocumentStandard: false,
      statusDrawRedaction: false,
      selectTag: {},
      previewPageDisabled: false,
      dtoRedactWord: [],
      startRedactXerox: false,
      canvasDataOcrDrawBox: {},
      canvasDataOcrDownload: {},
      listAllDocAnnotationOcr: ['Dictionary', 'RegEx', 'doc_annotate_address_email', 'doc_annotate_address_url', 'doc_annotate_address_phone', 'doc_annotate_address_postcode',
        'doc_annotate_address_complete', 'doc_annotate_address_ip', 'doc_annotate_person_female', 'doc_annotate_person_male', 'doc_annotate_person', 'doc_annotate_identifier',
        'doc_annotate_password', 'doc_annotate_location_country', 'doc_annotate_location_city', 'doc_annotate_location_region', 'doc_annotate_location_airport',
        'doc_annotate_location_country_abbrev', 'doc_annotate_location_county', 'doc_annotate_location_province', 'doc_annotate_location_other', 'doc_annotate_location_pre',
        'doc_annotate_money', 'doc_annotate_percent', 'doc_annotate_ssn', 'doc_annotate_creditcard', 'doc_annotate_organization_company', 'doc_annotate_organization_department',
        'doc_annotate_organization_government', 'doc_annotate_organization_newspaper', 'doc_annotate_organization_university', 'doc_annotate_organization_team', 'doc_annotate_date'],
      allCategoryVisibleOcr: [],
      docAnnotationsStandardOcr: [],
      listSearchHistory: [],
      documentNoteGet: '',
      canvasDataComments: {},
      closeRedactingStatus: false,
      statusSaveCanvasDeleteSection: false,
      deleteSelectedRedactionBox: false,
      checkUpdateLockStatus: '',
      setIntervalUpdateLockStatus: 0,
    }
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.onScanAnnotateClick = this.onScanAnnotateClick.bind(this)
  }

  componentWillUnmount() {
    this.props.setHeaderVisibility(true)
    this.props.setSidebarVisibility(true)
    this.props.setFooterVisibility(true)
    window.URL.revokeObjectURL(this.props.pdfBytes)
  }

  componentDidMount() {
    console.log('7Aug')
    window.scrollTo(0,0)
    // this.setState({
    //   loadingProcess: true
    // })
    if (this.state.documentId) {
      const { setIntervalUpdateLockStatus } = this.state
      cookie.remove('checkLockStatusSmartviewDocument', { path: '/' })
      clearInterval(setIntervalUpdateLockStatus)
      this.setState({setIntervalUpdateLockStatus}, () => {
        this.onGetUpdateLockStatusSmartviewDocument(this.state.documentId)
      })
      if (!this.state.isSmartBoxApplication) {
        this.fetchAllData(this.state.documentId, this.state.clientId)
      } else {
        if (this.props.isOcr) {
          this.onGeRectanglesCoordsOcr(this.state.documentId, this.state.clientId)
        } else {
          this.fetchSmartBoxAllData(this.state.documentId, this.state.clientId)
        }
      }
      this.onGetDocumentNotes(this.state.documentId)
      // if (this.props.lockSmartViewChanges) {
      //   CustomNotificationManager.info('This document is already opened by another user, changes are disabled.', 'Info')
      // }
    }
    const { systemPropertyFileStatus, systemPropertyFileStatusColor, systemPropertyFileStatusIcon } = this.props
    this.getTagDocumentClient()
    this.setState({
      valueStatusFile: systemPropertyFileStatus === null ? 'File Status' : systemPropertyFileStatus,
      selectTag: {
        tagName: systemPropertyFileStatus === null ? 'File Status' : systemPropertyFileStatus,
        color: systemPropertyFileStatusColor === null ? null : systemPropertyFileStatusColor,
        icon: systemPropertyFileStatusIcon === null ? null : systemPropertyFileStatusIcon,
    }
    })
    cookie.remove('sendNoteResponse', { path: '/' })
  }
   onGetDocumentNotes = (documentId) => {
     if (this.props.isSmartBoxApplication) {
       getSmartBoxDocNotes(this.props.documentId).then((res) => {
         if (res.data) {
           this.setState({
             documentNoteGet: res.data.note
           })
         }
       }).catch((error) => {
         if (error?.response?.status === 504) {
           CustomNotificationManager.cleanBuffer('warning', '')
         }else{
           console.log(error)
         }
       })
     } else {
       getDocNotes(this.props.documentId).then((res) => {
         if (res.data) {
           this.setState({
             documentNoteGet: res.data.note
           })
         }
       }).catch((error) => {
         if (error?.response?.status === 504) {
           CustomNotificationManager.cleanBuffer('warning', '')
         }else{
           console.log(error)
         }
       })
     }
   }
  getTagDocumentClient = () => {
      apiGetTagDocumentClient(this.props.clientId).then((response) => {
        this.setState({
          customerStatus: response.data
        })
      })
  }

  onGetRedactStatus = (documentId) => {
    apiGetSetDocumentRedactAllStatus(documentId).then((response) => {
     this.setState({
       statusRedactAll: response.data.document_redact_all_status
     })
    })
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.documentId !== this.props.documentId) {
      if (!this.state.isSmartBoxApplication) {
        this.fetchAllData(this.props.documentId, this.props.clientId)
      } else {
        //this.fetchSmartBoxAllData(this.state.documentId, this.state.clientId)
      }
    }
    if (prevProps.documentId !== this.props.documentId) {
      const { systemPropertyFileStatus, systemPropertyFileStatusColor, systemPropertyFileStatusIcon } = this.props
      this.setState({
        valueStatusFile: this.props.systemPropertyFileStatus === null ? 'File Status' : systemPropertyFileStatus,
        selectTag: {
          tagName: systemPropertyFileStatus === null ? 'File Status' : systemPropertyFileStatus,
          color: systemPropertyFileStatusColor === null ? null : systemPropertyFileStatusColor,
          icon: systemPropertyFileStatusIcon === null ? null : systemPropertyFileStatusIcon,
        }
      })
    }
    // if (prevProps !== this.props && this.props.checkRedactionCount === false) {
    //   this.setState({
    //     checkStartRedaction: false
    //   })
    // }
    // if (prevState !== this.state && prevState.statusSavedSmartView === true) {
    //   this.setState({
    //     checkStartRedaction: false
    //   })
    // }
  }

  onGetUpdateLockStatusSmartviewDocument = (documentId) => {
    const itemLockStatusSmartviewDocument = cookie.load('checkLockStatusSmartviewDocument', { path: '/' })
    apiUpdateLockStatusSmartviewDocument(documentId).then((response) => {
      cookie.save('checkLockStatusSmartviewDocument', 'getLockStatus', {path: '/'})
         this.setIntervalUpdateLockStatusSmartviewDocument()
    })
  }

  setIntervalUpdateLockStatusSmartviewDocument = () => {
     let { setIntervalUpdateLockStatus } = this.state
    const itemLockStatusSmartviewDocument = cookie.load('checkLockStatusSmartviewDocument', { path: '/' })
    if (itemLockStatusSmartviewDocument === 'getLockStatus') {
      if (setIntervalUpdateLockStatus === 0) {
        setIntervalUpdateLockStatus = setInterval(this.callBackUpdateLockStatusSmartviewDocument, 30000)
        this.setState({
          setIntervalUpdateLockStatus
        })
      } else {
        clearInterval(setIntervalUpdateLockStatus)
      }
    } else {
      clearInterval(setIntervalUpdateLockStatus)
      this.setState({setIntervalUpdateLockStatus})
    }
  }

  callBackUpdateLockStatusSmartviewDocument = () => {
    const { setIntervalUpdateLockStatus } = this.state
    const itemLockStatusSmartviewDocument = cookie.load('checkLockStatusSmartviewDocument', { path: '/' })
    if (itemLockStatusSmartviewDocument === 'getLockStatus') {
      apiUpdateLockStatusSmartviewDocument(this.state.documentId).then((response) => {
        cookie.save('checkLockStatusSmartviewDocument', 'getLockStatus', {path: '/'})
        this.setState({setIntervalUpdateLockStatus})
      })
    } else {
      clearInterval(setIntervalUpdateLockStatus)
      this.setState({setIntervalUpdateLockStatus})
    }
  }
  /*shouldComponentUpdate(nextProps, nextState) {
    let allRendered = true
    for(let i=0; i < nextState.renderedPagesStatus.length; i++) {
      if(nextState.renderedPagesStatus[i] === false) {
        allRendered = false;
        break;
      }
    }
    return true;
  }*/
  showHideCategoryOcr = async (e, category, state, item) => {
    const { listAllDocAnnotationOcr, allCategoryVisibleOcr } = this.state
    const docAnnotateCategory = []
    const docAnnotateCategoryFilter = []
    let uniqueVerificationVisited = []
    let allCategoryVisibleOcr2 = []
    if (item && item.children && item.children.length > 0) {
      item.children.forEach((item2) => {
        docAnnotateCategory.push(item2.categoryNameUpdate)
        listAllDocAnnotationOcr && listAllDocAnnotationOcr.length > 0 && listAllDocAnnotationOcr.forEach((item3) => {
          if (!listAllDocAnnotationOcr.includes(item2.categoryNameUpdate)) {
            docAnnotateCategoryFilter.push(item3)
          }
        })
        uniqueVerificationVisited = listAllDocAnnotationOcr && listAllDocAnnotationOcr.length > 0 && listAllDocAnnotationOcr.filter((a, i) => a !== item2.categoryNameUpdate)
      })
    }
    allCategoryVisibleOcr2 = this.state.allCategoryVisibleOcr.concat(docAnnotateCategory)
    if (state) {
      allCategoryVisibleOcr2 = allCategoryVisibleOcr2 && allCategoryVisibleOcr2.length > 0 && allCategoryVisibleOcr2.filter((a, i) => !docAnnotateCategory.includes(a))
    }
    const uniqueVerificationVisited2 = listAllDocAnnotationOcr && listAllDocAnnotationOcr.length > 0 && listAllDocAnnotationOcr.filter((a, i) => !allCategoryVisibleOcr2.includes(a))
    const getColoredRectanglesDTO = {
      docId: this.state.documentId,
      categories: uniqueVerificationVisited2,
      //solrIp: '18.157.167.245'
    }
    // const response1 = await getRectanglesCoordsOcrPost(getColoredRectanglesDTO)
    // const newCanvasData = this.convertCanvasData(response1.data.pageRectangleMapCategory === null ? {} : response1.data.pageRectangleMapCategory)
    const response5 = await apiSmartBoxGetAllCanvasPoints(this.props.documentId)
    const newCanvasData2 = await this.convertCanvasData(response5.data.pageRectangleMap === null ? {} : response5.data.pageRectangleMap,)
    getRectanglesCoordsOcrPost(getColoredRectanglesDTO).then((response1) => {
      const newCanvasData = this.convertCanvasDataPost(response1.data.pageRectangleMapCategory === null ? {} : response1.data.pageRectangleMapCategory)
      Object.keys(newCanvasData) && Object.keys(newCanvasData).length > 0 && Object.keys(newCanvasData).forEach((item) => {
        Object.keys(newCanvasData2) && Object.keys(newCanvasData2).length > 0 && Object.keys(newCanvasData2).forEach((item2) => {
          if (item === item2) {
            newCanvasData[item] = newCanvasData2[item2].concat(newCanvasData[item])
          }

        })
      })
      this.setState({
        pdfBytes: ''
      }, () => {
        this.setState({
          canvasData: newCanvasData,
          pdfBytes: this.props.pdfBytes,
          totalPages: this.props.totalPages,
          landscape: this.props.landscape,
          allCategoryVisibleOcr: allCategoryVisibleOcr2,
        })
      })
    })
  }
  onGeRectanglesCoordsOcr = async(documentId, clientId) => {
    const { listAllDocAnnotationOcr } = this.state
    const getColoredRectanglesDTO = {
      docId: documentId,
      categories: listAllDocAnnotationOcr,
      //solrIp: '18.157.167.245'
    }
    const response1 = await getRectanglesCoordsOcrPost(getColoredRectanglesDTO)
    const response10 = await getRectanglesCoordsOcrPost(getColoredRectanglesDTO)
    //const response1 = await geRectanglesCoordsOcr(documentId)
    const response5 = await apiSmartBoxGetAllCanvasPoints(documentId)
    const response2 = await apiSmartBoxGetPreviewRedactingSarDocByThumbnailId(documentId)
    const response3 = await apiSmartBoxGetAnnotationsByClientId(clientId)
    const response4 = await apiGetSetDocumentRedactAllStatus(documentId)
    let newCanvasData = await this.convertCanvasData(response1.data.pageRectangleMap === null ? {} : response1.data.pageRectangleMap)
    let newCanvasDataNew = await this.convertCanvasData(response10.data.pageRectangleMap === null ? {} : response10.data.pageRectangleMap)
    const newCanvasData2 = await this.convertCanvasData(response5.data.pageRectangleMap === null ? {} : response5.data.pageRectangleMap)

    // const newCanvasData3 = await this.convertCanvasDataOcr(response1.data.pageRectangleMapCategory === null ? {} : response1.data.pageRectangleMapCategory,
    //     response1.data.pageRectangleMap === null ? {} : response1.data.pageRectangleMap )


    // const pageRectangleMap1 = response1.data.pageRectangleMapCategory
    //
    // const table2 = []
    // let table3 = {}
    //
    // for (let canvasPageKey of Object.keys(pageRectangleMap1)) {
    //   for (let canvasPageKey2 of Object.keys(pageRectangleMap1[canvasPageKey])) {
    //     table3 = (pageRectangleMap1[canvasPageKey])[canvasPageKey2]
    //     for (let canvasPageKey3 of Object.keys((pageRectangleMap1[canvasPageKey])[canvasPageKey2])) {
    //       table2.push(((pageRectangleMap1[canvasPageKey])[canvasPageKey2])[canvasPageKey3])
    //     }
    //     // (pageRectangleMap1[canvasPageKey])[canvasPageKey2] = table2
    //     pageRectangleMap1[canvasPageKey2] = table2
    //   }
    //   // canvasPageKey = Object.keys(pageRectangleMap1[canvasPageKey])
    //   //pageRectangleMap1[canvasPageKey] = table2
    // }
    if (Object.keys(newCanvasData) && Object.keys(newCanvasData).length > 0 && Object.keys(newCanvasData2) && Object.keys(newCanvasData2).length > 0) {
      Object.keys(newCanvasData).forEach((item) => {
        Object.keys(newCanvasData2).forEach((item2) => {
          if (item === item2) {
            newCanvasData[item] = newCanvasData2[item2].concat(newCanvasData[item])
          }

        })
      })
    } else if (Object.keys(newCanvasData2) && Object.keys(newCanvasData2).length > 0 && Object.keys(newCanvasData).length === 0) {
      newCanvasData = newCanvasData2
    } else if (Object.keys(newCanvasData) && Object.keys(newCanvasData).length > 0 && Object.keys(newCanvasData2).length === 0) {
      newCanvasData = newCanvasData
    }


    // Object.keys(newCanvasData) && Object.keys(newCanvasData).length > 0 && Object.keys(newCanvasData).forEach((item) => {
    //   Object.keys(newCanvasData2) && Object.keys(newCanvasData2).length > 0 && Object.keys(newCanvasData2).forEach((item2) => {
    //     if (item === item2) {
    //       newCanvasData[item] = newCanvasData2[item2].concat(newCanvasData[item])
    //     }
    //
    //   })
    // })
    if (Object.keys(newCanvasDataNew) && Object.keys(newCanvasDataNew).length > 0 && Object.keys(newCanvasData2) && Object.keys(newCanvasData2).length > 0) {
      Object.keys(newCanvasDataNew).forEach((item) => {
        Object.keys(newCanvasData2).forEach((item2) => {
          if (item === item2) {
            newCanvasDataNew[item] = newCanvasData2[item2].concat(newCanvasDataNew[item])
          }

        })
      })
    } else if (Object.keys(newCanvasData2) && Object.keys(newCanvasData2).length > 0 && Object.keys(newCanvasDataNew).length === 0) {
      newCanvasDataNew = newCanvasData2
    } else if (Object.keys(newCanvasDataNew) && Object.keys(newCanvasDataNew).length > 0 && Object.keys(newCanvasData2).length === 0) {
      newCanvasDataNew = newCanvasDataNew
    }
    // Object.keys(newCanvasDataNew) && Object.keys(newCanvasDataNew).length > 0 && Object.keys(newCanvasDataNew).forEach((item) => {
    //   Object.keys(newCanvasData2) && Object.keys(newCanvasData2).length > 0 && Object.keys(newCanvasData2).forEach((item2) => {
    //     if (item === item2) {
    //       //newCanvasData[item] = newCanvasData2[item2].concat(newCanvasData[item])
    //       newCanvasDataNew[item] = newCanvasData2[item2].concat(newCanvasDataNew[item])
    //     }
    //
    //   })
    // })
    const alreadyRedactedKeys = await getRedactedKeys({ id: documentId })
    this.setState({
      canvasData: newCanvasData,
      canvasDataCopy: newCanvasDataNew,
      //canvasData: newCanvasData3,
      canvasDataOcrDownload: response5.data,
      docAnnotationsStandardOcr: response1.data.wordsPII,
      pdfBytes: this.props.pdfBytes,
      totalPages: this.props.totalPages,
      landscape: this.props.landscape,
      divPrint: this.props.landscape ? 'div-to-print-landscape' : 'div-to-print-portrait',
      width: this.props.dbWidth * (this._generateScale(this.props.landscape)),
      height: this.props.dbHeight * (this._generateScale(this.props.landscape)),
      forPageNumber: 1,
      totalThumbnails: response2.data,
      annotationList: response3.data,
      scale: this._generateScale(this.props.landscape),
      loading: true,
      idDoc: response1.data.idDoc,
      renderedPagesStatus: [...Array(this.props.totalPages)].map((item) => false),
      alreadyRedactedKeys: alreadyRedactedKeys.data,
      statusRedactAll: response4.data.document_redact_all_status
    }, () => {
      if (response2.status === 200) {
        if (Object.keys(newCanvasData) && Object.keys(newCanvasData).length === 0) {
          this.setState({
            previewPageDisabled: true,
          })
        }
        this.setState({
          clearPageDisabled: true,
        })
      }
    })
  }
  setNumberOfAnnotateSelected = async(checkedKeys) => {
    this.setState({ itemsSelected: checkedKeys, numberOfAnnotateSelected: checkedKeys.length })
  }

  setAnnotationSelectedItem = (annotationSelectedItem) => this.setState({ annotationSelectedItem })

  setTreeDataAnnotations = (treeDataAnnotations) => {
    this.setState({treeDataAnnotations}, () => {
      this.setState({
        renderStatus: true
      })
    })
    // setTimeout(() => {
    //   this.setState({
    //     renderStatus: true
    //   })
    // }, 2000)

  }

  fetchAllData = async(documentId, clientId) => {
    const response1 = await apiGetAllCanvasPoints(documentId)
    const response10 = await apiGetAllCanvasPoints(documentId)
    const response2 = await getPreviewRedactingSarDocByThumbnailId(documentId)
    const response3 = await getAnnotationsByClientId(clientId)
    const response4 = await apiGetSetDocumentRedactAllStatus(documentId)
    this.setState({
      canvasData: response1.data.pageRectangleMap,
      //canvasDataCopy: response1.data.pageRectangleMap,
      pdfBytes: this.props.pdfBytes,
      totalPages: this.props.totalPages,
      landscape: this.props.landscape,
      divPrint: this.props.landscape ? 'div-to-print-landscape' : 'div-to-print-portrait',
      width: this.props.dbWidth * (this._generateScale(this.props.landscape)),
      height: this.props.dbHeight * (this._generateScale(this.props.landscape)),
      forPageNumber: 1,
      totalThumbnails: response2.data,
      annotationList: response3.data,
      loading: true,
      scale: this._generateScale(this.props.landscape),
      renderedPagesStatus: [...Array(this.props.totalPages)].map((item) => false),
      statusRedactAll: response4.data.document_redact_all_status,
      previewPageDisabled: false,
    })
  }
  
  convertCanvasData = async(canvasData) => {
    for (const canvasPageKey of Object.keys(canvasData)) {
      canvasData[canvasPageKey] = Object.values(canvasData[canvasPageKey])
    }
    return canvasData
  }

  convertCanvasDataNew = async(canvasDataNew) => {
    for (const canvasPageKey of Object.keys(canvasDataNew)) {
      canvasDataNew[canvasPageKey] = Object.values(canvasDataNew[canvasPageKey])
    }
    return canvasDataNew
  }

  convertCanvasDataPost = (canvasData) => {
    for (const canvasPageKey of Object.keys(canvasData)) {
      canvasData[canvasPageKey] = Object.values(canvasData[canvasPageKey])
    }
    return canvasData
  }

  convertCanvasDataOcr = async(canvasData, canvasDataUpdate) => {

    const pageRectangleMap1 = canvasData
    const pageRectangleMap3 = canvasDataUpdate
    const pageRectangleMap2 = {}

    const table2 = []
    let table3 = {}

    for (let canvasPageKey of Object.keys(pageRectangleMap1)) {
      if (canvasPageKey !== 'doc_annotate_location_city') {
        for (let canvasPageKey2 of Object.keys(pageRectangleMap1[canvasPageKey])) {
          //table3 = (pageRectangleMap1[canvasPageKey])[canvasPageKey2]
          for (let canvasPageKey3 of Object.keys((pageRectangleMap1[canvasPageKey])[canvasPageKey2])) {
            table2.push(((pageRectangleMap1[canvasPageKey])[canvasPageKey2])[canvasPageKey3])
          }
          // (pageRectangleMap1[canvasPageKey])[canvasPageKey2] = table2
          table3 = (pageRectangleMap1[canvasPageKey])[canvasPageKey2] = table2
          pageRectangleMap1[canvasPageKey2] = table2
        }
      }
      // canvasPageKey = Object.keys(pageRectangleMap1[canvasPageKey])
      //pageRectangleMap1[canvasPageKey] = table2
    }

    for (const canvasPageKey of Object.keys(pageRectangleMap3)) {
      pageRectangleMap3[canvasPageKey] = table3
    }
    return pageRectangleMap3
  }

  fetchSmartBoxAllData = async(documentId, clientId) => {
    const response2 = await apiSmartBoxGetPreviewRedactingSarDocByThumbnailId(documentId)
    const response1 = await apiSmartBoxGetAllCanvasPoints(documentId)
    const response10 = await apiSmartBoxGetAllCanvasPoints(documentId)
    const response3 = await apiSmartBoxGetAnnotationsByClientId(clientId)
    const response4 = await apiGetSetDocumentRedactAllStatus(documentId)
    // const canvasNew = {
    //   "1": {
    //     "0": "{\"x1\":567,\"x2\":721,\"y1\":458.12,\"y2\":424,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image1\"}",
    //     "1": "{\"x1\":291,\"x2\":304,\"y1\":150.595,\"y2\":119,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image2\"}",
    //     "2": "{\"x1\":43,\"x2\":812,\"y1\":769.665,\"y2\":733,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image3\"}",
    //     "3": "{\"x1\":291,\"x2\":304,\"y1\":150.595,\"y2\":119,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image4\"}",
    //     "4": "{\"x1\":43,\"x2\":812,\"y1\":719.42,\"y2\":684,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image5\"}",
    //     "5": "{\"x1\":285,\"x2\":418,\"y1\":223.955,\"y2\":191,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image6\"}",
    //     "6": "{\"x1\":287,\"x2\":359,\"y1\":273.20502,\"y2\":241,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image7\"}",
    //     "7": "{\"x1\":584,\"x2\":721,\"y1\":608.53503,\"y2\":507,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image8\"}",
    //     "8": "{\"x1\":43,\"x2\":812,\"y1\":719.42,\"y2\":684,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image9\"}",
    //     "9": "{\"x1\":287,\"x2\":359,\"y1\":273.20502,\"y2\":241,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image10\"}",
    //     "10": "{\"x1\":584,\"x2\":721,\"y1\":608.53503,\"y2\":507,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image11\"}",
    //     "11": "{\"x1\":43,\"x2\":812,\"y1\":719.42,\"y2\":684,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image12\"}",
    //     "12": "{\"x1\":43,\"x2\":812,\"y1\":769.665,\"y2\":733,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image13\"}",
    //     "13": "{\"x1\":351,\"x2\":404,\"y1\":412.9,\"y2\":380,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image14\"}",
    //     "14": "{\"x1\":290,\"x2\":324,\"y1\":413.905,\"y2\":381,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image15\"}",
    //     "15": "{\"x1\":430,\"x2\":502,\"y1\":413.9,\"y2\":380,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image16\"}",
    //     "16": "{\"x1\":351,\"x2\":404,\"y1\":546.56,\"y2\":512,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image17\"}",
    //     "17": "{\"x1\":430,\"x2\":502,\"y1\":545.56,\"y2\":512,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image18\"}",
    //     "18": "{\"x1\":290,\"x2\":324,\"y1\":545.56,\"y2\":512,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image19\"}",
    //     "19": "{\"x1\":351,\"x2\":404,\"y1\":611.89,\"y2\":578,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image20\"}",
    //     "20": "{\"x1\":429,\"x2\":502,\"y1\":611.89,\"y2\":578,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image21\"}",
    //     "21": "{\"x1\":290,\"x2\":324,\"y1\":611.89,\"y2\":578,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image22\"}",
    //     "22": "{\"x1\":351,\"x2\":404,\"y1\":412.9,\"y2\":380,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image23\"}",
    //     "23": "{\"x1\":290,\"x2\":324,\"y1\":413.905,\"y2\":381,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image24\"}",
    //     "24": "{\"x1\":430,\"x2\":502,\"y1\":413.9,\"y2\":380,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image25\"}",
    //     "25": "{\"x1\":569,\"x2\":600,\"y1\":413.975,\"y2\":395,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image26\"}",
    //     "26": "{\"x1\":569,\"x2\":600,\"y1\":413.975,\"y2\":395,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image27\"}",
    //     "27": "{\"x1\":409,\"x2\":531,\"y1\":68.185,\"y2\":37,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image28\"}"
    //   }
    // }
    const newCanvasData = await this.convertCanvasData(response1.data.pageRectangleMap === null ? {} : response1.data.pageRectangleMap)
    const newCanvasDataNew = await this.convertCanvasDataNew(response10.data.pageRectangleMap === null ? {} : response10.data.pageRectangleMap)
    //const newCanvasData = await this.convertCanvasData(canvasNew)
    const alreadyRedactedKeys = await getRedactedKeys({ id: documentId })
    this.setState({
      canvasData: newCanvasData,
      canvasDataCopy: newCanvasDataNew,
      canvasDataDragos: newCanvasDataNew,
      pdfBytes: this.props.pdfBytes,
      totalPages: this.props.totalPages,
      landscape: this.props.landscape,
      divPrint: this.props.landscape ? 'div-to-print-landscape' : 'div-to-print-portrait',
      width: this.props.dbWidth * (this._generateScale(this.props.landscape)),
      height: this.props.dbHeight * (this._generateScale(this.props.landscape)),
      forPageNumber: 1,
      totalThumbnails: response2.data,
      annotationList: response3.data,
      scale: this._generateScale(this.props.landscape),
      loading: true,
      idDoc: response1.data.idDoc,
      renderedPagesStatus: [...Array(this.props.totalPages)].map((item) => false),
      alreadyRedactedKeys: alreadyRedactedKeys.data,
      statusRedactAll: response4.data.document_redact_all_status
    }, ()=>{ /*console.log(2)*/ })
    //this.onGetRedactStatus(documentId)
    if (response2.status === 200) {
      if (Object.keys(newCanvasData) && Object.keys(newCanvasData).length === 0) {
        this.setState({
          previewPageDisabled: true,
        })
      }
      this.setState({
        clearPageDisabled: true,
      })
      setTimeout(() => {
        this.setState({
          renderStatus: true
        })
      }, 5000)
    }
  }

  _generateScale = (documentType) => {
    return documentType ? 1.3 : 1.4
    //todo add scale for different devices
  }

  checkOnClearAll = (status) => {
    this.setState({
      verificationOnClickClearAll: status
    })
  }

  fetchSmartBoxDataCanvasOcr = async() => {
    const { documentId, clientId } = this.state
    const response2 = await apiSmartBoxGetPreviewRedactingSarDocByThumbnailId(documentId)
    const response1 = await apiSmartBoxGetAllCanvasPoints(documentId)
    const response3 = await apiSmartBoxGetAnnotationsByClientId(clientId)
    const { listAllDocAnnotationOcr } = this.state
    const getColoredRectanglesDTO = {
      docId: documentId,
      categories: listAllDocAnnotationOcr,
      //solrIp: '18.157.167.245'
    }
    const response5 = await getRectanglesCoordsOcrPost(getColoredRectanglesDTO)
    //const response5 = await geRectanglesCoordsOcr(documentId)

    const newCanvasDate = {
      "1": {
        "0": "{\"x1\":567,\"x2\":721,\"y1\":458.12,\"y2\":424,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image1\"}",
        "1": "{\"x1\":291,\"x2\":304,\"y1\":150.595,\"y2\":119,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image2\"}",
        "2": "{\"x1\":43,\"x2\":812,\"y1\":769.665,\"y2\":733,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image3\"}",
        "3": "{\"x1\":291,\"x2\":304,\"y1\":150.595,\"y2\":119,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image4\"}",
        "4": "{\"x1\":43,\"x2\":812,\"y1\":719.42,\"y2\":684,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image5\"}",
        "5": "{\"x1\":285,\"x2\":418,\"y1\":223.955,\"y2\":191,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image6\"}",
        "6": "{\"x1\":287,\"x2\":359,\"y1\":273.20502,\"y2\":241,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image7\"}",
        "7": "{\"x1\":584,\"x2\":721,\"y1\":608.53503,\"y2\":507,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image8\"}",
        "8": "{\"x1\":43,\"x2\":812,\"y1\":719.42,\"y2\":684,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image9\"}",
        "9": "{\"x1\":287,\"x2\":359,\"y1\":273.20502,\"y2\":241,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image10\"}",
        "10": "{\"x1\":584,\"x2\":721,\"y1\":608.53503,\"y2\":507,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image11\"}",
        "11": "{\"x1\":43,\"x2\":812,\"y1\":719.42,\"y2\":684,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image12\"}",
        "12": "{\"x1\":43,\"x2\":812,\"y1\":769.665,\"y2\":733,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image13\"}",
        "13": "{\"x1\":351,\"x2\":404,\"y1\":412.9,\"y2\":380,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image14\"}",
        "14": "{\"x1\":290,\"x2\":324,\"y1\":413.905,\"y2\":381,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image15\"}",
        "15": "{\"x1\":430,\"x2\":502,\"y1\":413.9,\"y2\":380,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image16\"}",
        "16": "{\"x1\":351,\"x2\":404,\"y1\":546.56,\"y2\":512,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image17\"}",
        "17": "{\"x1\":430,\"x2\":502,\"y1\":545.56,\"y2\":512,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image18\"}",
        "18": "{\"x1\":290,\"x2\":324,\"y1\":545.56,\"y2\":512,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image19\"}",
        "19": "{\"x1\":351,\"x2\":404,\"y1\":611.89,\"y2\":578,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image20\"}",
        "20": "{\"x1\":429,\"x2\":502,\"y1\":611.89,\"y2\":578,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image21\"}",
        "21": "{\"x1\":290,\"x2\":324,\"y1\":611.89,\"y2\":578,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image22\"}",
        "22": "{\"x1\":351,\"x2\":404,\"y1\":412.9,\"y2\":380,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image23\"}",
        "23": "{\"x1\":290,\"x2\":324,\"y1\":413.905,\"y2\":381,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image24\"}",
        "24": "{\"x1\":430,\"x2\":502,\"y1\":413.9,\"y2\":380,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image25\"}",
        "25": "{\"x1\":569,\"x2\":600,\"y1\":413.975,\"y2\":395,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image26\"}",
        "26": "{\"x1\":569,\"x2\":600,\"y1\":413.975,\"y2\":395,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image27\"}",
        "27": "{\"x1\":409,\"x2\":531,\"y1\":68.185,\"y2\":37,\"text\":\"picture\",\"annotation\":\"\",\"type\":\"\",\"picture\":\"true\",\"pagePointsSum\":\"page0image28\"}"
      }
    }
    //const newCanvasData = await this.convertCanvasData(newCanvasDate)
    const newCanvasData = await this.convertCanvasData(response1.data.pageRectangleMap === null ? {} : response1.data.pageRectangleMap)
    const newCanvasData2 = await this.convertCanvasData(response5.data.pageRectangleMap === null ? {} : response5.data.pageRectangleMap)
    Object.keys(newCanvasData2) && Object.keys(newCanvasData2).length > 0 && Object.keys(newCanvasData2).forEach((item) => {
      Object.keys(newCanvasData) && Object.keys(newCanvasData).length > 0 && Object.keys(newCanvasData).forEach((item2) => {
        if (item === item2) {
          newCanvasData2[item2] = newCanvasData[item].concat(newCanvasData2[item2])
        }

      })
    })
    const alreadyRedactedKeys = await getRedactedKeys({ id: documentId })

    this.setState({
      //canvasData: newCanvasData,
      canvasData: newCanvasData2,
      docAnnotationsStandardOcr: response5.data.wordsPII,
      pdfBytes: this.props.pdfBytes,
      totalPages: this.props.totalPages,
      landscape: this.props.landscape,
      divPrint: this.props.landscape ? 'div-to-print-landscape' : 'div-to-print-portrait',
      width: this.props.dbWidth * (this._generateScale(this.props.landscape)),
      height: this.props.dbHeight * (this._generateScale(this.props.landscape)),
      forPageNumber: 1,
      totalThumbnails: response2.data,
      annotationList: response3.data,
      scale: this._generateScale(this.props.landscape),
      loading: true,
      idDoc: response1.data.idDoc,
      renderedPagesStatus: [...Array(this.props.totalPages)].map((item) => false),
      alreadyRedactedKeys: alreadyRedactedKeys.data
    }, () => {
      this.setState({
        canvasData: newCanvasData2,
      })
    })
    if (response2.status === 200) {
      if (Object.keys(newCanvasData) && Object.keys(newCanvasData).length === 0) {
        this.setState({
          previewPageDisabled: true,
        })
      }
      this.setState({
        clearPageDisabled: true
      })
    }
  }

  checkOnClearAllManual = (status) => {
    this.setState({
      verificationOnClickClearAllManual: status
    })
  }
  checkOnClearAllRegEx = (status) => {
    this.setState({
      verificationOnClickClearAllRegEx: status
    })
  }
  checkOnClearAllBusiness = (status) => {
    this.setState({
      verificationOnClickClearAllBusiness: status
    })
  }

  onSendStatusOnClearAll = (status) => {
    this.setState({
      statusKeysEmptyOnClearAll: status
    })
  }

  clearAllKeysRedactedOnPage = () => {
    const { pageNumber } = this.state
    const data = {
      id: this.props.documentId,
      page: pageNumber
    }
    this.setState({
      verificationOnClickClearAll: true,
      verificationOnClickClearAllManual: true,
      verificationOnClickClearAllRegEx: true,
      verificationOnClickClearAllBusiness: true
    })

    if (this.props.isSmartBoxApplication) {
      apiSmartBoxClearAllKeysOnPage(data).then((response) => {
        const listItems = response.data
        const listKeysStandard = []
        const listKeysManual = []
        const listKeysRegex = []
        const listKeysBusiness = []

        listItems && listItems.map((list) => {
          list && Object.keys(list).map((item) => {
            if (item === 'standard') {
              list[item] && Object.keys(list[item]).map((listStandard) => {
                if (list[item][listStandard]) {
                  list[item][listStandard].forEach((itemStandard) => {
                    listKeysStandard.push(itemStandard.key)
                  })
                  return true
                }
                return true
              })
            } else if (item === 'manual') {
              list[item] && Object.keys(list[item]).map((listManual) => {
                if (list[item][listManual]) {
                  list[item][listManual].forEach((itemManual) => {
                    listKeysManual.push(itemManual.key)
                  })
                  return true
                }
                return true
              })
            } else if (item === 'regex') {
              list[item] && Object.keys(list[item]).map((item3) => {
                if (list[item][item3]) {
                  list[item][item3].forEach((item4) => {
                    listKeysRegex.push(item4.key)
                  })
                  return true
                }
                return true
              })
            } else if (item === 'businessterms') {
              list[item] && Object.keys(list[item]).map((item3) => {
                if ((list[item][item3])) {
                  list[item][item3].forEach((item4) => {
                    listKeysBusiness.push(item4.key)
                  })
                  return true
                }
                return true
              })
            }
            return true
          })
          return true
        })

        if (listKeysStandard && listKeysStandard.length === 0) {
        //   this.setState({
        //     statusKeysEmptyOnClearAll: true
        //   })
        // } else {
        //   this.setState({
        //     statusKeysEmptyOnClearAll: false
        //   })
        }

        this.setState({
          newListKeysStandard: listKeysStandard,
          newListKeysManual: listKeysManual,
          newListKeysRegEx: listKeysRegex,
          newListKeysBusiness: listKeysBusiness
        })
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
            console.log(error)
        }
      })
    }
  }
   clearPageAfter = () => {

   }

  onGeRectanglesAfterClearOcr = async(documentId, clientId) => {
    const { listAllDocAnnotationOcr } = this.state
    const getColoredRectanglesDTO = {
      docId: documentId,
      categories: listAllDocAnnotationOcr,
      //solrIp: '18.157.167.245'
    }
    const response1 = await getRectanglesCoordsOcrPost(getColoredRectanglesDTO)
    //const response1 = await geRectanglesCoordsOcr(documentId)
    const response5 = await apiSmartBoxGetAllCanvasPoints(documentId)
    const newCanvasData = await this.convertCanvasData(response1.data.pageRectangleMap === null ? {} : response1.data.pageRectangleMap)
    const newCanvasData2 = await this.convertCanvasData(response5.data.pageRectangleMap === null ? {} : response5.data.pageRectangleMap)

    Object.keys(newCanvasData) && Object.keys(newCanvasData).length > 0 && Object.keys(newCanvasData).forEach((item) => {
      Object.keys(newCanvasData2) && Object.keys(newCanvasData2).length > 0 && Object.keys(newCanvasData2).forEach((item2) => {
        if (item === item2) {
          newCanvasData[item] = newCanvasData2[item2].concat(newCanvasData[item])
        }

      })
    })
    this.setState({
      pdfBytes: '',
      renderSearch: false,
      renderSelectText: false,
      renderCommentsText: false,
      viewAnnotationsRedaction: false,
      renderSelectTool: false,
      renderSelectToolMove: false,
      displaySearchButtons: false,
      drawSelectedMouse: false,
      renderSelectDraw: false,
      selectedTool: 'select',
      tool: Tools.Select,
      disableClickOnPage: true
    }, () => {
      this.setState({
        canvasData: newCanvasData,
        canvasDataOcrDownload: response5.data,
        docAnnotationsStandardOcr: response1.data.wordsPII,
        pdfBytes: this.props.pdfBytes,
        totalPages: this.props.totalPages
      })
    })
  }

  _clear = () => {
    const { clearPageDisabled } = this.state
    const rectangleOnCurrentPage = this.state.canvasData
    rectangleOnCurrentPage[this.state.pageNumber] = []
    if (clearPageDisabled) {
      this._sketch.setBackgroundFromDataUrl('')
      this._sketch.clear()
    }
    this.onSetDocumentRedactAllStatus("false")
    // if (this.props.isSmartBoxApplication) {
    //   this.clearAllKeysRedactedOnPage()
    // }
    const alreadyRedactedKeys= {}
    alreadyRedactedKeys.business= []
    alreadyRedactedKeys.manual= []
    alreadyRedactedKeys.regex= []
    alreadyRedactedKeys.standard= []
    this.setState({
      controlledValue: null,
      backgroundColor: 'transparent',
      fillWithBackgroundColor: false,
      canUndo: this._sketch.canUndo(),
      canRedo: this._sketch.canRedo(),
      canvasData: rectangleOnCurrentPage,
      haveChanges: true,
      alreadyRedactedKeys
    } , () => {
      const { pageNumber } = this.state
      const data = {
        id: this.props.documentId,
        page: pageNumber
      }
      this.setState({
        verificationOnClickClearAll: true,
        verificationOnClickClearAllManual: true,
        verificationOnClickClearAllRegEx: true,
        verificationOnClickClearAllBusiness: true
      })
      if (this.props.isSmartBoxApplication) {
        apiSmartBoxClearAllKeysOnPage(data).then((response) => {
          const listItems = response.data
          const listKeysStandard = []
          const listKeysManual = []
          const listKeysRegex = []
          const listKeysBusiness = []
          if (this.props.isOcr) {
            this.onGeRectanglesAfterClearOcr(this.state.documentId, this.state.clientId)
          }
          listItems && listItems.map((list) => {
            list && Object.keys(list).map((item) => {
              if (item === 'standard') {
                list[item] && Object.keys(list[item]).map((listStandard) => {
                  if (list[item][listStandard]) {
                    list[item][listStandard].forEach((itemStandard) => {
                      listKeysStandard.push(itemStandard.key)
                    })
                    return true
                  }
                  return true
                })
              } else if (item === 'manual') {
                list[item] && Object.keys(list[item]).map((listManual) => {
                  if (list[item][listManual]) {
                    list[item][listManual].forEach((itemManual) => {
                      listKeysManual.push(itemManual.key)
                    })
                    return true
                  }
                  return true
                })
              } else if (item === 'regex') {
                list[item] && Object.keys(list[item]).map((item3) => {
                  if (list[item][item3]) {
                    list[item][item3].forEach((item4) => {
                      listKeysRegex.push(item4.key)
                    })
                    return true
                  }
                  return true
                })
              } else if (item === 'businessterms') {
                list[item] && Object.keys(list[item]).map((item3) => {
                  if ((list[item][item3])) {
                    list[item][item3].forEach((item4) => {
                      listKeysBusiness.push(item4.key)
                    })
                    return true
                  }
                  return true
                })
              }
              return true
            })
            return true
          })

          // if (listKeysStandard && listKeysStandard.length === 0) {
          //     this.setState({
          //       statusKeysEmptyOnClearAll: true
          //     })
          //   } else {
          //     this.setState({
          //       statusKeysEmptyOnClearAll: false
          //     })
          // }

          this.setState({
            newListKeysStandard: listKeysStandard,
            newListKeysManual: listKeysManual,
            newListKeysRegEx: listKeysRegex,
            newListKeysBusiness: listKeysBusiness
          })
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
              console.log(error)
          }
        })
      }
      this.hideCanvasModals()
    })

    this.hideCanvasModals()
  }

  deleteAllImages = (item) => {
    this._sketch.setBackgroundFromDataUrl('')
    this._sketch.removeSelectedImage(item)
    this.setState({
      controlledValue: null,
      backgroundColor: 'transparent',
      fillWithBackgroundColor: false,
      canUndo: this._sketch.canUndo(),
      canRedo: this._sketch.canRedo(),
      haveChanges: true
    })
    this.hideCanvasModals()
  }

  _undo = () => {
    this.hideCanvasModals()
    this._sketch.undo()
    this.setState({
      canUndo: this._sketch.canUndo(),
      canRedo: this._sketch.canRedo(),
      haveChanges: true
    })
  }

  _redo = () => {
    this.hideCanvasModals()
    this._sketch.redo()
    this.setState({
      canUndo: this._sketch.canUndo(),
      canRedo: this._sketch.canRedo(),
      haveChanges: true
    })
  }

  updateCanvasText = (list, label, color, backgroundColor) => {
    const { canvasData, statusPageSidebar } = this.state
    const newData = []
    if (list.length && Object.keys(canvasData).length) {
      list.map((item) => {
        Object.keys(canvasData).map((page) => {
          canvasData[page].map((value) => {
            let valueDecoded = value

            if (typeof valueDecoded === 'string') {
              valueDecoded = JSON.parse(value)
            }
            if (valueDecoded.text === item && (valueDecoded.type === null || valueDecoded.type === 1)) {
              const temp = valueDecoded
              temp.type= 2
              temp.y1 = valueDecoded.y1 - 20
              temp.y2 = temp.y1 + 12
              temp.text = label
              temp.color = color
              temp.background = backgroundColor
              newData.push({ item: temp, page: page })
            }
            return true
          })
          return true
        })
        return true
      })
    }
    const pagesWithChanges = []
    if (newData.length) {
      newData.map((item) => {
        if (!pagesWithChanges.includes(item.page)) {
          pagesWithChanges.push(item.page)
        }
        return true
      })
    }
    if (pagesWithChanges) {
      pagesWithChanges.map((page) => {
        const data = {}
        const pageRectangleMapData = []
        newData.map((item) => {
          if (item.page === page) {
            pageRectangleMapData.push(JSON.stringify(item.item))
          }
          return true
        })
        //data.idDoc= this.props.documentId
        data.page= Number(page)
        data.pageRectangleMap= {}
        data.pageRectangleMap[page] = pageRectangleMapData
        const dtoRedactEachWord = []
        dtoRedactEachWord.push(data)
        const saveCanvasDocumentSmartViewDTO = {
          idDoc: this.props.documentId,
          pageCoordinates: dtoRedactEachWord

        }
        if (!this.props.isOcr) {
          apiSmartBoxSaveCanvasDocumentSmartview(saveCanvasDocumentSmartViewDTO).then((response) => {
            this.setState({
              statusDrawRedaction: false
            })
          }).catch((error) => {
            if (error?.response?.status === 504) {
              CustomNotificationManager.cleanBuffer('warning', '')
            } else {
              console.log(error)
            }
          })
        }

        // apiSmartBoxUpdateCanvasPoints(data).then(() => {
        //
        // }).catch((error) => {
        //   if (error?.response?.status === 504) {
        //     CustomNotificationManager.cleanBuffer('warning', '')
        //   }else{
        //     console.log(error)
        //   }
        // })
        this.setState({
          statusSavedSmartView: true
        })
        return true
      })
    }

    apiSmartBoxGetAllCanvasPoints(this.props.documentId).then((response) => {
      if (response.status === 200) {

      }
    })
  }

  clearAnnotation = () => {
    const { totalPages } = this.state
    const data = {}
    data.idDoc= this.props.documentId
    apiSmartBoxClearCanvasPoints(data).then((response) => {
      if (response.status === 200) {

          const initialCanvasData = this.state.canvasData
          for (let i = 1; i <= totalPages; i++) {
            initialCanvasData[i] = []
          }
          this.setState({
            canvasData: initialCanvasData,
            haveChanges: false,
            clearSketch: true
          }, () => {
          });

        // for (let i = 1; i <= totalPages; i++) {
          // this._sketch.clear()
          // remove only redacted words and keep drawn boxes

          // if(this.state.pageNumber !== i) {
          //   const initialCanvasData = this.state.canvasData
          //   initialCanvasData[i] = []
          //   this.setState({
          //     canvasData: initialCanvasData,
          //     haveChanges: false,
          //     pageNumber: i
          //   }, () => {
          //     this._sketch._clear()
          //   });
            // this.onSearchChangePage(null, i).then(() => {
            //   this._clear()
              /*const history = this._sketch._fc._objects
              for (let i = 0; i < history.length; i++) {
                if(typeof history[i].text !== drawByHandText) {
                  this._sketch._fc.remove(history[i])
                }
              }*/
            // })
          // } else {
            // const initialCanvasData = this.state.canvasData
            // initialCanvasData[this.state.pageNumber] = []
            //
            // this.setState({
            //   canvasData: initialCanvasData,
            //   haveChanges: false,
            // }, () => {
            //   this._sketch._clear()
            // });
            /*const history = this._sketch._fc._objects
            for (let i = 0; i < history.length; i++) {
              if(typeof history[i].text !== drawByHandText) {
                this._sketch._fc.remove(history[i])
              }
            }*/
          }

        // }
      // }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
          console.log(error)
      }
    })
    // this._sketch.clear()
    // remove only redacted words and keep drawn boxes
    /*const history = this._sketch._fc._objects
    for (let i = 0; i < history.length; i++) {
      if(typeof history[i].text !== drawByHandText) {
        this._sketch._fc.remove(history[i])
      }
    }*/
  }

  getSelectedWords = () => {
    const selection = document.getSelection()
    if (!this.state.renderCommentsText) {
      if (selection && selection.rangeCount) {
        const poligonPoints = this.setPolygonPoints(selection)
        const poly = new fabric.Polygon(poligonPoints, {
          fill: 'rgba(0, 0, 0, 0.4)',
          angle: 0,
          padding: 0,
          selectable: true,
          annotation: selection.toString() ? selection.toString() : '',
          text: selection.toString() ? selection.toString() : '',
          annotationType: 3,
          type: 3,
        })
        this._sketch._fc.add(poly)

        if (window.getSelection) {
          if (window.getSelection().empty) { // Chrome
            window.getSelection().empty()
          } else if (window.getSelection().removeAllRanges) { // Firefox
            window.getSelection().removeAllRanges()
          }
        } else if (document.selection) { // IE?
          document.selection.empty()
        }
      }
    } else if (this.state.renderCommentsText) {
      if (selection && selection.rangeCount) {
        const poligonPoints = this.setPolygonPoints(selection)
        const poly = new fabric.Polygon(poligonPoints, {
          fill: 'rgba(0, 0, 0, 0.4)',
          angle: 0,
          padding: 0,
          selectable: true,
          annotation: selection.toString() ? selection.toString() : '',
          text: selection.toString() ? selection.toString() : '',
          annotationType: 4,
          type: 4,
        })
        this._sketch._fc.add(poly)

        if (window.getSelection) {
          if (window.getSelection().empty) { // Chrome
            window.getSelection().empty()
          } else if (window.getSelection().removeAllRanges) { // Firefox
            window.getSelection().removeAllRanges()
          }
        } else if (document.selection) { // IE?
          document.selection.empty()
        }
      }
    }
  }

  setPolygonPoints = (selection) => {
    const parentWr = document.querySelector('div.canvas-page-1')
    const selectionPositions = selection.getRangeAt(0).getClientRects()
    let left = 9999
    let top = 9999
    let right = 0
    let bottom = 0
    const leftFix = parentWr.getBoundingClientRect().left + window.scrollX
    const topFix = parentWr.getBoundingClientRect().top - 2
    const bottomFix = parentWr.getBoundingClientRect().top - 1
    const poligonPoints = []
    for (let i = 0; i < selectionPositions.length; i++) {
      if (selectionPositions[i].left - leftFix < left) {
        left = selectionPositions[i].left - leftFix
      }
      if (selectionPositions[i].top - topFix < top) {
        top = selectionPositions[i].top - topFix
      }
      if (selectionPositions[i].right - leftFix > right) {
        right = selectionPositions[i].right - leftFix
      }
      if (selectionPositions[i].bottom - bottomFix > bottom) {
        bottom = selectionPositions[i].bottom - bottomFix
      }
    }

    poligonPoints.push({ x: left, y: top })
    poligonPoints.push({ x: (right + left) / 2, y: top })
    poligonPoints.push({ x: right, y: top })
    poligonPoints.push({ x: right, y: (bottom + top) / 2 })
    poligonPoints.push({ x: right, y: bottom })
    poligonPoints.push({ x: (right + left) / 2, y: bottom })
    poligonPoints.push({ x: left, y: bottom })
    poligonPoints.push({ x: left, y: (bottom + top) / 2 })
    if (Object.keys(selectionPositions)) {
      switch (Object.keys(selectionPositions).length) {
        case 2:
          poligonPoints[0] = {
            x: selectionPositions[0].left - leftFix,
            y: selectionPositions[0].bottom - bottomFix
          }
          poligonPoints[7] = { x: left, y: selectionPositions[0].bottom - bottomFix }
          poligonPoints[1] = { x: selectionPositions[0].left - leftFix, y: top }
          poligonPoints[4] = { x: selectionPositions[1].right - leftFix, y: selectionPositions[1].top - topFix }
          poligonPoints[3] = { x: right, y: selectionPositions[1].top - topFix }
          poligonPoints[5] = { x: selectionPositions[1].right - leftFix, y: bottom }
          break
        case 3:
          poligonPoints[0] = {
            x: selectionPositions[0].left - leftFix,
            y: selectionPositions[0].bottom - bottomFix
          }
          poligonPoints[7] = { x: left, y: selectionPositions[0].bottom - bottomFix }
          poligonPoints[1] = { x: selectionPositions[0].left - leftFix, y: top }
          poligonPoints[4] = { x: selectionPositions[2].right - leftFix, y: selectionPositions[2].top - topFix }
          poligonPoints[3] = { x: right, y: selectionPositions[2].top - topFix }
          poligonPoints[5] = { x: selectionPositions[2].right - leftFix, y: bottom }
          break
        case 4:
          poligonPoints[0] = {
            x: selectionPositions[0].left - leftFix,
            y: selectionPositions[0].bottom - bottomFix
          }
          poligonPoints[7] = { x: left, y: selectionPositions[0].bottom - bottomFix }
          poligonPoints[1] = { x: selectionPositions[0].left - leftFix, y: top }
          poligonPoints[4] = { x: selectionPositions[3].right - leftFix, y: selectionPositions[3].top - topFix }
          poligonPoints[3] = { x: right, y: selectionPositions[3].top - topFix }
          poligonPoints[5] = { x: selectionPositions[3].right - leftFix, y: bottom }
          break
        case 5:
          poligonPoints[0] = {
            x: selectionPositions[0].left - leftFix,
            y: selectionPositions[0].bottom - bottomFix
          }
          poligonPoints[7] = { x: left, y: selectionPositions[0].bottom - bottomFix }
          poligonPoints[1] = { x: selectionPositions[0].left - leftFix, y: top }
          poligonPoints[4] = { x: selectionPositions[4].right - leftFix, y: selectionPositions[4].top - topFix }
          poligonPoints[3] = { x: right, y: selectionPositions[4].top - topFix }
          poligonPoints[5] = { x: selectionPositions[4].right - leftFix, y: bottom }
          break
        case 6:
          poligonPoints[0] = {
            x: selectionPositions[0].left - leftFix,
            y: selectionPositions[0].bottom - bottomFix
          }
          poligonPoints[7] = { x: left, y: selectionPositions[0].bottom - bottomFix }
          poligonPoints[1] = { x: selectionPositions[0].left - leftFix, y: top }
          poligonPoints[4] = { x: selectionPositions[5].right - leftFix, y: selectionPositions[5].top - topFix }
          poligonPoints[3] = { x: right, y: selectionPositions[5].top - topFix }
          poligonPoints[5] = { x: selectionPositions[5].right - leftFix, y: bottom }
              break
        case 7:
          poligonPoints[0] = {
            x: selectionPositions[0].left - leftFix,
            y: selectionPositions[0].bottom - bottomFix
          }
          poligonPoints[7] = { x: left, y: selectionPositions[0].bottom - bottomFix }
          poligonPoints[1] = { x: selectionPositions[0].left - leftFix, y: top }
          poligonPoints[4] = { x: selectionPositions[6].right - leftFix, y: selectionPositions[6].top - topFix }
          poligonPoints[3] = { x: right, y: selectionPositions[6].top - topFix }
          poligonPoints[5] = { x: selectionPositions[6].right - leftFix, y: bottom }
          break
        case 8:
          poligonPoints[0] = {
            x: selectionPositions[0].left - leftFix,
            y: selectionPositions[0].bottom - bottomFix
          }
          poligonPoints[7] = { x: left, y: selectionPositions[0].bottom - bottomFix }
          poligonPoints[1] = { x: selectionPositions[0].left - leftFix, y: top }
          poligonPoints[4] = { x: selectionPositions[7].right - leftFix, y: selectionPositions[7].top - topFix }
          poligonPoints[3] = { x: right, y: selectionPositions[7].top - topFix }
          poligonPoints[5] = { x: selectionPositions[7].right - leftFix, y: bottom }
          break
        default:
            if (Object.keys(selectionPositions).length > 9 && Object.keys(selectionPositions).length < 20) {
              poligonPoints[0] = {
                x: selectionPositions[0].left - leftFix,
                y: selectionPositions[0].bottom - bottomFix
              }
              poligonPoints[7] = { x: left, y: selectionPositions[0].bottom - bottomFix }
              poligonPoints[1] = { x: selectionPositions[0].left - leftFix, y: top }
              poligonPoints[4] = { x: selectionPositions[Object.keys(selectionPositions).length - 1].right - leftFix, y: selectionPositions[Object.keys(selectionPositions).length - 1].top - topFix }
              poligonPoints[3] = { x: right, y: selectionPositions[Object.keys(selectionPositions).length - 1].top - topFix }
              poligonPoints[5] = { x: selectionPositions[Object.keys(selectionPositions).length - 1].right - leftFix, y: bottom }
            } else if (Object.keys(selectionPositions).length > 19 && Object.keys(selectionPositions).length < 30) {
              poligonPoints[0] = {
                x: selectionPositions[0].left - leftFix,
                y: selectionPositions[0].bottom - bottomFix
              }
              poligonPoints[7] = { x: left, y: selectionPositions[0].bottom - bottomFix }
              poligonPoints[1] = { x: selectionPositions[0].left - leftFix, y: top }
              poligonPoints[4] = { x: selectionPositions[Object.keys(selectionPositions).length - 1].right - leftFix, y: selectionPositions[Object.keys(selectionPositions).length - 1].top - topFix }
              poligonPoints[3] = { x: right, y: selectionPositions[Object.keys(selectionPositions).length - 1].top - topFix }
              poligonPoints[5] = { x: selectionPositions[Object.keys(selectionPositions).length - 1].right - leftFix, y: bottom }
            } else if (Object.keys(selectionPositions).length > 29 && Object.keys(selectionPositions).length < 200) {
              poligonPoints[0] = {
                x: selectionPositions[0].left - leftFix,
                y: selectionPositions[0].bottom - bottomFix
              }
              poligonPoints[7] = { x: left, y: selectionPositions[0].bottom - bottomFix }
              poligonPoints[1] = { x: selectionPositions[0].left - leftFix, y: top }
              poligonPoints[4] = { x: selectionPositions[Object.keys(selectionPositions).length - 1].right - leftFix, y: selectionPositions[Object.keys(selectionPositions).length - 1].top - topFix }
              poligonPoints[3] = { x: right, y: selectionPositions[Object.keys(selectionPositions).length - 1].top - topFix }
              poligonPoints[5] = { x: selectionPositions[Object.keys(selectionPositions).length - 1].right - leftFix, y: bottom }
            } else  {
              for (let i = 0; i < selectionPositions.length; i++) {
                if ((selectionPositions[i].top - topFix === top) && (selectionPositions[i].left - leftFix > left)) {
                  poligonPoints[0] = {
                    x: selectionPositions[0].left - leftFix,
                    y: selectionPositions[i].bottom - bottomFix
                  }
                  poligonPoints[7] = {x: left, y: selectionPositions[i].bottom - bottomFix}
                  poligonPoints[1] = {x: selectionPositions[0].left - leftFix, y: top}
                }

                if ((selectionPositions[i].bottom - bottomFix === bottom) && (selectionPositions[i].right - leftFix < right)) {
                  poligonPoints[4] = {x: selectionPositions[i].right - leftFix, y: selectionPositions[i].top - topFix}
                  poligonPoints[3] = {x: right, y: selectionPositions[i].top - topFix}
                  poligonPoints[5] = {x: selectionPositions[i].right - leftFix, y: bottom}
                }
              }
            }
          break
      }
    }

    return poligonPoints
  }

  async printDocument() {
    const {visibleToDownload} = this.props
    const { canvasDataOcrDownload } = this.state
    if (visibleToDownload !== false) {
    if (this.state.matchedText.length > 0) {
      await this.clearPreviousSearchedData()
    }
    window.scrollTo(0, 0)
    const {landscape, scale, totalPages, width, height, printAnnotation, divPrint, redactingMode} = this.state
    this.setState({
      showCloseButton: false,
      printLoading: true
    })
    const elements = document.querySelectorAll(`div#${divPrint}`)
    for (let i = 0; i < totalPages; i++) {
      elements[i].classList.add('show-pdf-page')
    }
      if (this.props.isOcr && canvasDataOcrDownload && Object.keys(canvasDataOcrDownload.pageRectangleMap) && Object.keys(canvasDataOcrDownload.pageRectangleMap).length > 0) {
        this.fill('rgba(0, 0, 0, 1)')
        this._sketch.showAnnotation(printAnnotation)
      } else if (!this.props.isOcr) {
        this.fill('rgba(0, 0, 0, 1)')
        this._sketch.showAnnotation(printAnnotation)
      }

    const parentElement = document.querySelector('#pdf-page')
    const HTML_Width = parentElement.clientWidth
    const PDF_Width = HTML_Width / scale
    const PDF_Height = height / scale
    let position = 0
    let canvas_image_width = parentElement.clientWidth / scale
    let canvas_image_height = parentElement.clientHeight / scale
    let heightLeft = canvas_image_height

    let pdf = null
    if (landscape) {
      pdf = new jsPDF('landscape', 'pt', [PDF_Width, PDF_Height])
    } else {
      pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height])
      pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height])
    }

    if (totalPages > 5) {
      let counter = totalPages
      let step = 5
      let from = 0
      const pages = Array.from(document.querySelectorAll(landscape ?
          '#div-to-print-landscape' :
          '#div-to-print-portrait'))
      while (counter > 0) {
        if (counter < step) {
          if (counter > 0) {
            step = counter
          }
        }
        counter -= step
        const printElements = pages.slice(from, from + step + 1)
        const printParent = document.createElement('div')
        const parent = document.querySelectorAll('.redacted-section')[0]
        printElements.forEach((item) => printParent.appendChild(item))
        parent.appendChild(printParent)
        from += step

        canvas_image_width = printParent.clientWidth / scale
        canvas_image_height = printParent.clientHeight / scale
        heightLeft = canvas_image_height
        await this.renderPdf(printParent, position, totalPages, scale, width, height, heightLeft, canvas_image_width, canvas_image_height, PDF_Height, landscape, pdf)

        printParent.remove()
        printElements.forEach((item) => parent.appendChild(item))
      }
      pdf.save(`${this.props.initialName.substring(0, this.props.initialName.lastIndexOf('.'))}.pdf`)
      this.setState({
        showCloseButton: true,
        printLoading: false
      })
      if (this.props.isOcr && canvasDataOcrDownload && Object.keys(canvasDataOcrDownload.pageRectangleMap) && Object.keys(canvasDataOcrDownload.pageRectangleMap).length > 0) {
        this.hidePrintMode(totalPages, elements, redactingMode)
      } else if (!this.props.isOcr) {
        this.hidePrintMode(totalPages, elements, redactingMode)
      }
    } else {
      html2canvas(parentElement, {allowTaint: true, scale}).then((canvas) => {
        pdf.addImage(canvas, 'JPG', 0, position, canvas_image_width, canvas_image_height)
        heightLeft -= PDF_Height
        while (heightLeft > 0) {
          position = heightLeft - canvas_image_height
          //pdf.addPage(landscape ? 'l' : 'p', [width / scale, height / scale])
          pdf.addPage(landscape ? 'l' : 'p', 'p')
          pdf.addImage(canvas, 'JPG', 0, position, canvas_image_width, canvas_image_height)
          heightLeft -= PDF_Height
        }
        pdf.save(`${this.props.initialName.substring(0, this.props.initialName.lastIndexOf('.'))}.pdf`)
        this.setState({
          showCloseButton: true,
          printLoading: false
        })
        if (this.props.isOcr && canvasDataOcrDownload && Object.keys(canvasDataOcrDownload.pageRectangleMap) && Object.keys(canvasDataOcrDownload.pageRectangleMap).length > 0) {
          this.hidePrintMode(totalPages, elements, redactingMode)
        } else if (!this.props.isOcr) {
          this.hidePrintMode(totalPages, elements, redactingMode)
        }
        //this.hidePrintMode(totalPages, elements, redactingMode)
      })
    }
  }
  }

  hidePrintMode = (totalPages, elements, redactionMode) => {
    //hide back all pages except current
    for (let i = 0; i < totalPages; i++) {
      if (Number(elements[i].getAttribute('data-pageid')) === 1) {
        elements[i].classList.add('show-pdf-page')
      } else {
        elements[i].classList.remove('show-pdf-page')
      }
    }
    if (redactionMode) {
      this.fill('rgba(0, 0, 0, 0.5)')
    }
    this._sketch.showAnnotation(true)
  }

  renderPdf(printParent, position, totalPages, scale, width, height, heightLeft, canvas_image_width, canvas_image_height, PDF_Height, landscape, pdf) {
    return new Promise(function(resolve) {
      html2canvas(printParent, { allowTaint: true, dpi: 300 }).then((canvas) => {
        pdf.addImage(canvas, 'JPG', 0, position, canvas_image_width, canvas_image_height)
        heightLeft -= PDF_Height
        while (heightLeft > 0) {
          position = heightLeft - canvas_image_height
          //pdf.addPage(landscape ? 'l' : 'p', [ width / scale, height / scale ])
          pdf.addPage(landscape ? 'l' : 'p', 'p')
          pdf.addImage(canvas, 'JPG', 0, position, canvas_image_width, canvas_image_height)
          heightLeft -= PDF_Height
        }
        resolve()
      })
    })
  }


  fill = (color) => {
    for (let i = 1; i <= this.state.totalPages; i++) {
      this.refs[i].fill(color)
    }
  }


  removeObj = () => {
    this.hideCanvasModals()
    this._sketch.removeSelected()
    this.setState({
      haveChanges: true,
      deleteSelectedRedactionBox: true,
    }, () => {
      setTimeout(() => {
        this.savePageOnButton()
      }, 1000)
    })
  }

  setPage = (pageNumber) => {
    const elements = document.querySelectorAll(`div#${ this.state.divPrint }`)
    for (let i = 0; i < this.state.totalPages; i++) {
      if (Number(elements[i].getAttribute('data-pageid')) === +pageNumber) {
        elements[i].classList.add('show-pdf-page')
      } else {
        elements[i].classList.remove('show-pdf-page')
      }
    }
    this.setState({ pageNumber: pageNumber })

  }

  loadPoints = (canvasData) => {
    if (canvasData) {
      if (this._sketch) {
        this._sketch.clearTemporary()
      }
      const elements = canvasData
      const elemstsJson = []
      elements.forEach((item) => {
        elemstsJson.push(JSON.parse(item))
      })
      elemstsJson.forEach((point) => {
        if (this._sketch) {
          this._sketch.fillPoints(point, 'rgba(0,0,0,0.5)')
        }
      })
    }

  }

  changePageWithClear = (newPageNumber, annt, statusPageSidebar) => {
    const elements = document.querySelectorAll(`div#${ this.state.divPrint }`)
    if (elements) {
      for (let i = 0; i < this.state.totalPages; i++) {
        if (elements[i] && Number(elements[i].getAttribute('data-pageid')) === newPageNumber) {
          elements[i].classList.add('show-pdf-page')
        } else if (elements[i]) {
          elements[i].classList.remove('show-pdf-page')
        }
      }
    }
    const elementsSmall = document.querySelectorAll('div.previewpdf__container__item')
    if (this.state.navigationType === 'pages') {
      for (let i = 0; i < this.state.totalPages; i++) {
        if (Number(elementsSmall[i].getAttribute('data-pageid')) === newPageNumber) {
          elementsSmall[i].classList.add('previewpdf__container__item--active')
        } else {
          elementsSmall[i].classList.remove('previewpdf__container__item--active')
        }
      }
    }

    const points = this.getRectanglesForPreviewPage()
    this.updateRectangles(points, statusPageSidebar)
    const initialCanvasData = this.state.canvasData
    initialCanvasData[this.state.pageNumber] = points
    this.hideCanvasModals()
    this.setState({
      pageNumber: newPageNumber,
      canvasData: initialCanvasData,
      forPageNumber: newPageNumber,
      haveChanges: false,
    }, () => {
      if (this.state.navigationType !== 'pages' && this.state.navigationType !== 'find-asset' && this.state.navigationType !== 'image') {
        const canvas = this._sketch._fc
        canvas._objects.forEach((rectangle) => {
          rectangle.set('stroke', 'rgba(0,0,0,0.41)')
          rectangle.set('strokeWidth', 1)
          if (annt) {
            if ((rectangle.aCoords.br.x === annt.x2 + 1 && rectangle.aCoords.bl.y === annt.y1 + 1)
              || (rectangle.aCoords.br.x === annt.x2 && rectangle.aCoords.bl.y === annt.y1)) {
              rectangle.set('stroke', 'rgb(255,244,11)')
              rectangle.set('strokeWidth', 2)
            }
          }
        })
        canvas.renderAll()
      } else {
        const canvas = this._sketch._fc
        canvas._objects.forEach((rectangle) => {
          rectangle.set('stroke', 'rgba(0,0,0,0.41)')
          rectangle.set('strokeWidth', 1)
        })
        canvas.renderAll()
      }
    })
    this._sketch.clear()
  }
  onDefaultChange = () => {
    return true
  }
  changePage = (newPageNumber, annt, statusPageSidebar, indexEl, arrayQuery) => {
    this.setState({
      statusClickPageSidebar: statusPageSidebar,
      stateSidebarPages: false
    })
    const elements = document.querySelectorAll(`div#${ this.state.divPrint }`)
    if (elements) {
      for (let i = 0; i < this.state.totalPages; i++) {
        if (elements[i] && Number(elements[i].getAttribute('data-pageid')) === newPageNumber) {
          elements[i].classList.remove('show-pdf-page')
          elements[i].classList.add('show-pdf-page')
        } else if (elements[i]) {
          elements[i].classList.remove('show-pdf-page')
        }
      }
    }

    //add active class to pages thumbnails
    const elementsSmall = document.querySelectorAll('div.previewpdf__container__item')
    if (this.state.navigationType === 'pages') {
      for (let i = 0; i < this.state.totalPages; i++) {
        if (Number(elementsSmall[i].getAttribute('data-pageid')) === newPageNumber) {
          elementsSmall[i].classList.add('previewpdf__container__item--active')
        } else {
          elementsSmall[i].classList.remove('previewpdf__container__item--active')
        }
      }
    }

    const points = this.getRectanglesForPreviewPage()
    this.updateRectangles(points, statusPageSidebar, indexEl, arrayQuery)
    const initialCanvasData = this.state.canvasData
    initialCanvasData[this.state.pageNumber] = points
    this.hideCanvasModals()
    this.setState({
      pageNumber: newPageNumber,
      canvasData: initialCanvasData,
      forPageNumber: newPageNumber,
      haveChanges: false,
    }, () => {
      if (this.state.navigationType !== 'pages' && this.state.navigationType !== 'find-asset' && this.state.navigationType !== 'image') {
        const canvas = this._sketch._fc
        canvas._objects.forEach((rectangle) => {
          rectangle.set('stroke', 'rgba(0,0,0,0.41)')
          rectangle.set('strokeWidth', 1)
          if (annt) {
            if ((rectangle.aCoords.br.x === annt.x2 + 1 && rectangle.aCoords.bl.y === annt.y1 + 1)
              || (rectangle.aCoords.br.x === annt.x2 && rectangle.aCoords.bl.y === annt.y1)) {
              rectangle.set('stroke', 'rgb(255,244,11)')
              rectangle.set('strokeWidth', 2)
            }
          }
        })
        canvas.renderAll()
      } else {
        const canvas = this._sketch._fc
        canvas._objects.forEach((rectangle) => {
          rectangle.set('stroke', 'rgba(0,0,0,0.41)')
          rectangle.set('strokeWidth', 1)
        })
        canvas.renderAll()
      }
    })
  }

  changePageRedacted = (newPageNumber, annt) => {
    const elements = document.querySelectorAll(`div#${ this.state.divPrint }`)
    for (let i = 0; i < this.state.totalPages; i++) {
      if (Number(elements[i].getAttribute('data-pageid')) === newPageNumber) {
        elements[i].classList.add('show-pdf-page')
      } else {
        elements[i].classList.remove('show-pdf-page')
      }
    }
    //add active class to pages thumbnails
    const elementsSmall = document.querySelectorAll('div.previewpdf__container__item')
    if (this.state.navigationType === 'pages') {
      for (let i = 0; i < this.state.totalPages; i++) {
        if (Number(elementsSmall[i].getAttribute('data-pageid')) === newPageNumber) {
          elementsSmall[i].classList.add('previewpdf__container__item--active')
        } else {
          elementsSmall[i].classList.remove('previewpdf__container__item--active')
        }
      }
    }

    this.setState({
      pageNumber: newPageNumber,
      forPageNumber: newPageNumber,
      haveChanges: false,
    }, () => {
      const canvas = this._sketch._fc
      canvas._objects.forEach((rectangle) => {
        rectangle.set('stroke', 'rgba(0,0,0,0.41)')
        rectangle.set('strokeWidth', 1)
        if ((rectangle.aCoords.br.x === annt.x2 + 1 && rectangle.aCoords.bl.y === annt.y1 + 1)
          || (rectangle.aCoords.br.x === annt.x2 && rectangle.aCoords.bl.y === annt.y1)) {
          rectangle.set('stroke', 'rgb(255,244,11)')
          rectangle.set('strokeWidth', 2)
        }
      })
      canvas.renderAll()
    })
  }

  savePointsOnPage = (points, pageNr) => {
    this.updateRectangles(points)
    this.setState((canvasData) => ({ ...canvasData, [pageNr]: points }))
  }

  savePage = () => {
    const points = this.getRectanglesForPreviewPage()
    this.updateRectangles(points, 'pageSidebar')
    const initialCanvasData = this.state.canvasData
    initialCanvasData[this.state.pageNumber] = points
    this.setState({
      haveChanges: true,
      canvasData: initialCanvasData
    })
  }
  savePageAfterRemove = (dtoRemoveCanvas, alreadyRedactedKeys, parameters, unCheckedKeys, classNameList, source, dtoRemoveCanvasAllCanvas) => {
    const points = this.getRectanglesForPreviewPage()
    this.updateRectanglesAfterRemove(points, dtoRemoveCanvas, alreadyRedactedKeys, parameters, unCheckedKeys, classNameList, source, dtoRemoveCanvasAllCanvas)
    const initialCanvasData = this.state.canvasData
    initialCanvasData[this.state.pageNumber] = points
    this.setState({
      haveChanges: true,
      canvasData: initialCanvasData
    })
  }
  updateRectanglesAfterRemove = (points, dtoRemoveCanvas, alreadyRedactedKeys, parameters, unCheckedKeys, classNameList, source, dtoRemoveCanvasAllCanvas) => {
    const { treeDataAnnotations, canvasDataCopy, canvasData } = this.state
    const pointsToSave = {}
    const listPoints = []
    let maxHeight //= 10000
    const elements = document.querySelectorAll(`div#${ this.state.divPrint }.show-pdf-page`)
    if(elements.length > 0) {
      maxHeight = elements[0].getBoundingClientRect().height
    } else {
      maxHeight = 10000
    }
    points.forEach((element) => {
      let y1 = element.y1 - 1
      if(y1 > maxHeight) {
        y1 = y1 - maxHeight
      }

      let y2 = element.y2
      if(y2 > maxHeight) {
        y2 = y2 - maxHeight
      }
      const obj = {
        x1: element.x1,
        x2: element.x2 - 1,
        y1: y1,
        y2: y2,
        annotation: element.annotation ? element.annotation : (element.text ? element.text : ''),
        text: element.text ? element.text : '',
        type: element.type,
        pagePointSum: element.pagePointSum,
        points: element.points,
        label: element.label,
        picture: true
      }
      const stringObj = JSON.stringify(obj)
      listPoints.push(stringObj)
      pointsToSave[this.state.pageNumber] = listPoints
      this.setState({
        pagePointSumList: element.pagePointSum,
      })
    })

    //START
    const canvasDataCopyListFinally = []
    let key2 = 0
    if (canvasDataCopy[this.state.pageNumber] && canvasDataCopy[this.state.pageNumber].length) {
      for (let i = 1; i <= this.state.totalPages; i++) {
        if (canvasDataCopy[i] && canvasDataCopy[i].length > 0) {
          for (let j = 0; j < canvasDataCopy[i].length; j++) {
            key2++
            const parsed = typeof canvasDataCopy[i][j].annotation === 'undefined' ?
                JSON.parse(canvasDataCopy[i][j]) : canvasDataCopy[i][j]
            canvasDataCopyListFinally.push(parsed)
          }
        }
      }
    }
    const pointsUpdate2 = canvasDataCopyListFinally
    const groupsChildren2 = {}
    const groupsChildren3 = []
    pointsUpdate2 && pointsUpdate2.length > 0 && pointsUpdate2.forEach((child) => {
      const { text, annotation } = child
      if (text) {
        if (!groupsChildren2[text]) {
          groupsChildren2[text] = {
            name: text,
            children: []
          }
        }
        child.children = groupsChildren2[text].children
        groupsChildren2[text].children.push(child)
      } else if (annotation) {
        if (!groupsChildren2[annotation]) {
          groupsChildren2[annotation] = {
            name: annotation,
            children: []
          }
        }
        child.children = groupsChildren2[annotation].children
        groupsChildren2[annotation].children.push(child)
      }

    })

    pointsUpdate2 && pointsUpdate2.length > 0 && pointsUpdate2.forEach((child2) => {
      child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3, index) => {
        if (child3.text) {
          const keyName = child3.text.replace(/\W/g, 'a')
          child3.indexChild = index
          child3.key3 = `${keyName}-${index}`
        } else if (child3.annotation) {
          const keyName = child3.annotation.replace(/\W/g, 'a')
          child3.indexChild = index
          child3.key4 = child3.annotation
        }
      })
    })

    const pagePointSumNewUpdate = []
    const pagePointSumTotalPoints = []
    let pagePointSumDeleted = []

    const dtoRemoveCanvasAllCanvasIds = []
    const dtoRemoveCanvasAllCanvasIds2 = []
    const pointsToSaveDelete = []
    dtoRemoveCanvasAllCanvas && dtoRemoveCanvasAllCanvas.length > 0 && dtoRemoveCanvasAllCanvas.forEach((elem) => {
      if (elem.key3) {
        dtoRemoveCanvasAllCanvasIds.push(elem.key3.toLowerCase())
      }
      if (elem.key) {
        dtoRemoveCanvasAllCanvasIds2.push(elem.key.toLowerCase())
      }
    })

    pointsUpdate2 && pointsUpdate2.length > 0 && pointsUpdate2.forEach((elem) => {
      elem.children && elem.children.length > 0 && elem.children.forEach((elem2) => {
        if (elem2.key3) {
          if (dtoRemoveCanvasAllCanvasIds.includes(elem2.key3.toLowerCase())) {
            pointsToSaveDelete.push(elem2.pagePointSum)
          }
        } else if (elem2.key4) {
          if (dtoRemoveCanvasAllCanvasIds2.includes(elem2.key4.toLowerCase())) {
            pointsToSaveDelete.push(elem2.pagePointSum)
          }
        }
      })
    })
    const uniquePointsToSaveDelete = [...new Set(pointsToSaveDelete.map((item) => item))]
    //solutions 1
    const saveCanvasDocumentSmartViewDTO = {
      idDoc: this.props.documentId,
      pageCoordinates: [],
      coordsToDelete: uniquePointsToSaveDelete

    }

    const dataToSave = {
      idDoc: this.props.documentId,
      page: this.state.pageNumber,
      pageRectangleMap: pointsToSave

    }
    // const dataGetPoints = {
    //   idDoc: this.props.documentId,
    //   page: this.state.pageNumber,
    // }
    const selectAllItemsIds = []
    treeDataAnnotations && treeDataAnnotations.length > 0 && treeDataAnnotations[0].children && treeDataAnnotations[0].children.length > 0 && treeDataAnnotations[0].children.forEach((child) => {
      child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
        child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
          child3 && child3.children && child3.children.length > 0 && child3.children.forEach((child4) => {
            selectAllItemsIds.push(child4.key)
          })
        })
      })
    })
    // const selectedStandard = []
    // selectAllItemsIds && selectAllItemsIds.length > 0 && selectAllItemsIds.forEach((item) => {
    //      if (alreadyRedactedKeys.standard && alreadyRedactedKeys.standard.length > 0) {
    //        if (alreadyRedactedKeys.standard.includes(item)) {
    //          selectedStandard.push(item)
    //        }
    //      }
    // })
    const uniqueStandard = selectAllItemsIds && selectAllItemsIds.length > 0 && selectAllItemsIds.filter((a, i) => selectAllItemsIds.findIndex((s) => a === s) === i)
    if (this.props.isSmartBoxApplication) {
      if (Object.keys(pointsToSave) && Object.keys(pointsToSave).length > 0) {
        apiSmartBoxSaveCanvasDocumentSmartview(saveCanvasDocumentSmartViewDTO).then((response) => {
          this.setState({
            statusDrawRedaction: false
          })
          if (response.status === 200) {
            apiSmartBoxRemoveCanvas(dtoRemoveCanvas).then((response) => {
              if (response.status === 200) {
                this.setState({
                  statusAfterRemove: true,
                  checkStartRedaction: false
                }, () => {
                  this.fetchSmartBoxAllAfterRemove(this.state.documentId, this.state.clientId)
                  this.onRemoveEventDisableScroll()
                  if (source === 'standard') {
                    this.initialUpdateCanvas(parameters, classNameList, unCheckedKeys, source)
                    if (alreadyRedactedKeys.dictionaries && alreadyRedactedKeys.dictionaries.length > 0) {
                      this.initialUpdateCanvas(parameters, alreadyRedactedKeys.dictionaries, unCheckedKeys, 'dictionaries')
                    }
                    if (alreadyRedactedKeys.regex && alreadyRedactedKeys.regex.length > 0) {
                      this.initialUpdateCanvas(parameters, alreadyRedactedKeys.regex, unCheckedKeys, 'regex')
                    }
                  } else if (source === 'dictionaries') {
                    this.initialUpdateCanvas(parameters, classNameList, unCheckedKeys, source)
                    if (alreadyRedactedKeys.standard && alreadyRedactedKeys.standard.length > 0) {
                      this.initialUpdateCanvas(parameters, uniqueStandard, unCheckedKeys, 'standard')
                    }
                    if (alreadyRedactedKeys.regex && alreadyRedactedKeys.regex.length > 0) {
                      this.initialUpdateCanvas(parameters, alreadyRedactedKeys.regex, unCheckedKeys, 'regex')
                    }
                  } else if (source === 'regex') {
                    this.initialUpdateCanvas(parameters, classNameList, unCheckedKeys, source)
                    if (alreadyRedactedKeys.dictionaries && alreadyRedactedKeys.dictionaries.length > 0) {
                      this.initialUpdateCanvas(parameters, alreadyRedactedKeys.dictionaries, unCheckedKeys, 'dictionaries')
                    }
                    if (alreadyRedactedKeys.standard && alreadyRedactedKeys.standard.length > 0) {
                      this.initialUpdateCanvas(parameters, uniqueStandard, unCheckedKeys, 'standard')
                    }
                  }
                })
              }
            })
          }
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          } else {
            console.log(error)
          }
        })
      }
        // if (Object.keys(pointsToSave) && Object.keys(pointsToSave).length > 0) {
        //   apiSmartBoxUpdateCanvasPointsSaved(dataToSave).then((response) => {
        //       if (response.status === 200) {
        //         apiSmartBoxRemoveCanvas(dtoRemoveCanvas).then((response) => {
        //           if (response.status === 200) {
        //             this.setState({
        //               statusAfterRemove: true,
        //               checkStartRedaction: false
        //             }, () => {
        //               this.fetchSmartBoxAllAfterRemove(this.state.documentId, this.state.clientId)
        //               this.onRemoveEventDisableScroll()
        //               if (source === 'standard') {
        //                 this.initialUpdateCanvas(parameters, classNameList, unCheckedKeys, source)
        //                 if (alreadyRedactedKeys.dictionaries && alreadyRedactedKeys.dictionaries.length > 0) {
        //                   this.initialUpdateCanvas(parameters, alreadyRedactedKeys.dictionaries, unCheckedKeys, 'dictionaries')
        //                 }
        //                 if (alreadyRedactedKeys.regex && alreadyRedactedKeys.regex.length > 0) {
        //                   this.initialUpdateCanvas(parameters, alreadyRedactedKeys.regex, unCheckedKeys, 'regex')
        //                 }
        //               } else if (source === 'dictionaries') {
        //                 this.initialUpdateCanvas(parameters, classNameList, unCheckedKeys, source)
        //                 if (alreadyRedactedKeys.standard && alreadyRedactedKeys.standard.length > 0) {
        //                   this.initialUpdateCanvas(parameters, uniqueStandard, unCheckedKeys, 'standard')
        //                 }
        //                 if (alreadyRedactedKeys.regex && alreadyRedactedKeys.regex.length > 0) {
        //                   this.initialUpdateCanvas(parameters, alreadyRedactedKeys.regex, unCheckedKeys, 'regex')
        //                 }
        //               } else if (source === 'regex') {
        //                 this.initialUpdateCanvas(parameters, classNameList, unCheckedKeys, source)
        //                 if (alreadyRedactedKeys.dictionaries && alreadyRedactedKeys.dictionaries.length > 0) {
        //                   this.initialUpdateCanvas(parameters, alreadyRedactedKeys.dictionaries, unCheckedKeys, 'dictionaries')
        //                 }
        //                 if (alreadyRedactedKeys.standard && alreadyRedactedKeys.standard.length > 0) {
        //                   this.initialUpdateCanvas(parameters, uniqueStandard, unCheckedKeys, 'standard')
        //                 }
        //               }
        //             })
        //           }
        //         })
        //       }
        //     }).catch((error) => {
        //       if (error.response.status === 504) {
        //         CustomNotificationManager.cleanBuffer('warning', '')
        //       }else{
        //         console.log(error)
        //       }
        //     })
        // }
      this.setState({
        statusSavedSmartView: true
      })
    }
  }
  savePageOnButton = () => {
    const points = this.getRectanglesForPreviewPage()
    //this.updateRectanglesSaved(points)
    // const initialCanvasData = this.state.canvasData
    // initialCanvasData[this.state.pageNumber] = points
    this.setState({
      haveChanges: true,
      //canvasData: initialCanvasData
    })
  }
  updateRectanglesSaved = (points) => {
    const { canvasDataCopy, pageNumber, deleteSelectedRedactionBox, canvasData} = this.state
    const pointsToSave = {}
    const listPoints = []
    points.forEach((element) => {
      const obj = {
        x1: element.x1,
        x2: element.x2 - 1,
        y1: element.y1 - 1,
        y2: element.y2,
        annotation: element.annotation ? element.annotation : (element.text ? element.text : ''),
        text: element.text ? element.text : '',
        type: element.type,
        pagePointSum: element.pagePointSum,
        points: element.points,
        label: element.label,
        picture: true
      }
      const stringObj = JSON.stringify(obj)
      listPoints.push(stringObj)
      pointsToSave[this.state.pageNumber] = listPoints
      this.setState({
        pagePointSumList: element.pagePointSum,
      })
    })
    const pagePointSumNewUpdate = []
    const pagePointSumTotalPoints = []
    let pagePointSumDeleted = []
    //Object.keys(canvasDataCopy).forEach((key) => {
      if (canvasDataCopy[this.state.pageNumber] && canvasDataCopy[this.state.pageNumber].length) {
        canvasDataCopy[this.state.pageNumber].forEach((item) => {
          if (item) {
            let itemDecoded = item
            if (typeof item === 'string') {
              itemDecoded = JSON.parse(item)
            } else if (typeof item === 'object') {
              itemDecoded = item
            }
              if (itemDecoded.pagePointSum) {
                pagePointSumTotalPoints.push(itemDecoded.pagePointSum)
              }
          }
        })
      }
    //})
    Object.keys(pointsToSave).forEach((key) => {
      if (pointsToSave[key] && pointsToSave[key].length) {
        pointsToSave[key].forEach((item) => {
          if (item) {
            let itemDecoded = item
            if (typeof item === 'string') {
              itemDecoded = JSON.parse(item)
            } else if (typeof item === 'object') {
              itemDecoded = item
            }
              if (itemDecoded.pagePointSum) {
                pagePointSumNewUpdate.push(itemDecoded.pagePointSum)
              }
          }
        })
      }
    })
    pagePointSumDeleted = pagePointSumTotalPoints.filter((item3) => !pagePointSumNewUpdate.includes(item3))
    const dataToSave = {
      idDoc: this.props.documentId,
      page: this.state.pageNumber,
      coordsToDelete: pagePointSumDeleted

    }
    if (this.props.isSmartBoxApplication) {
      if (deleteSelectedRedactionBox) {
        apiSmartBoxSaveCanvasDocumentSmartview(dataToSave).then(() => {
          this.setState({
            statusSaveCanvasDeleteSection: false,
            deleteSelectedRedactionBox: false,
          })
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
        })
      } else {
        this.setState({
          statusSaveCanvasDeleteSection: true
        }, () => {
          apiSmartBoxSaveCanvasDocumentSmartview(dataToSave).then(() => {
            this.setState({
              statusSaveCanvasDeleteSection: false,
              deleteSelectedRedactionBox: false,
            })
          }).catch((error) => {
            if (error?.response?.status === 504) {
              CustomNotificationManager.cleanBuffer('warning', '')
            }else{
              console.log(error)
            }
          })
        })
      }
      this.setState({
        statusSavedSmartView: true
      })
    } else {
      apiUpdateCanvasPoints(dataToSave).then(() => {

      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
            console.log(error)
        }
      })
    }
  }
  getRectanglesForPreviewPage = () => {
    const points = []
    if (!this.state.renderCommentsText) {
      if (typeof this._sketch !== 'undefined') {
        const history = this._sketch._fc._objects
        for (let i = 0; i < history.length; i++) {
          if (history[i].type !== 4) {
            points.push({
              idd: history[i].idd,
              x1: history[i].aCoords.bl.x,
              x2: history[i].aCoords.br.x,
              y1: history[i].aCoords.bl.y,
              y2: history[i].aCoords.tr.y,
              text: history[i].text ? history[i].text : '',
              annotation: history[i].annotation ? history[i].annotation : (history[i].text) ? history[i].text : '',
             // annotationDragos: history[i].annotation ? `${history[i].annotation}-${i}` : (history[i].text) ? `${history[i].text}-${i}` : '',
              annotationType: Number(history[i].type),
              type: Number(history[i].type),
              pagePointSum: history[i].pagePointSum,
              points: history[i].points,
              picture: true
            })
          }
          this.setState({
            pagePointSumList: history[i].pagePointSum,
          })
        }
      }
    }
    return points
  }

  updateRectangles = (points, statusPageSidebar, indexEl, arrayQuery) => {
    const { statusAfterRemove, stateSidebarPages, statusRedactAll, statusDrawRedaction, dtoRedactWord, sourceActive, canvasDataDragos,
      canvasDataCopy, deleteSelectedRedactionBox } = this.state
    const pointsToSave = {}
    const pointsToSaveOcr = {}
    const listPoints = []
    const listPointsOcr = []
    const pointsOcr = []
    let maxHeight //= 10000
    const elements = document.querySelectorAll(`div#${ this.state.divPrint }.show-pdf-page`)
    if(elements.length > 0) {
      maxHeight = elements[0].getBoundingClientRect().height
    } else {
      maxHeight = 10000
    }
    if (this.props.isOcr) {
      points && points.length > 0 && points.forEach((itemPoint) => {
           if (itemPoint && itemPoint.annotationType && itemPoint.annotationType === 1) {
             pointsOcr.push(itemPoint)
           }
      })
    }
    points.forEach((element) => {
      let y1 = element.y1 - 1
      if(y1 > maxHeight) {
        y1 = y1 - maxHeight
      }

      let y2 = element.y2
      if(y2 > maxHeight) {
        y2 = y2 - maxHeight
      }
      const obj = {
        x1: element.x1,
        x2: element.x2 - 1,
        y1: y1,
        y2: y2,
        annotation: element.annotation ? element.annotation : (element.text ? element.text : ''),
        text: element.text ? element.text : '',
        type: element.type,
        pagePointSum: element.pagePointSum,
        points: element.points,
        label: element.label,
        picture: true
      }
      const stringObj = JSON.stringify(obj)
      listPoints.push(stringObj)
      pointsToSave[this.state.pageNumber] = listPoints
      this.setState({
        pagePointSumList: element.pagePointSum,
      })
    })
    if (this.props.isOcr) {
      pointsOcr.forEach((element) => {
        let y1 = element.y1 - 1
        if(y1 > maxHeight) {
          y1 = y1 - maxHeight
        }

        let y2 = element.y2
        if(y2 > maxHeight) {
          y2 = y2 - maxHeight
        }
        const obj = {
          x1: element.x1,
          x2: element.x2 - 1,
          y1: y1,
          y2: y2,
          annotation: element.annotation ? element.annotation : (element.text ? element.text : ''),
          text: element.text ? element.text : '',
          type: element.type,
          pagePointSum: element.pagePointSum,
          points: element.points,
          label: element.label,
          picture: true
        }
        const stringObj = JSON.stringify(obj)
        listPointsOcr.push(stringObj)
        pointsToSaveOcr[this.state.pageNumber] = listPointsOcr
        this.setState({
          pagePointSumList: element.pagePointSum,
        })
      })
    }
    const dataToSave = {
      //idDoc: this.props.documentId,
      page: this.state.pageNumber,
      pageRectangleMap: pointsToSave

    }
    const dataToSaveOcr = {
      //idDoc: this.props.documentId,
      page: this.state.pageNumber,
      pageRectangleMap: pointsToSaveOcr

    }
    const dataGetPoints = {
      idDoc: this.props.documentId,
      page: this.state.pageNumber,
    }
    if (this.props.isSmartBoxApplication) {
      if (!statusAfterRemove) {
        if (Object.keys(pointsToSave) && Object.keys(pointsToSave).length > 0) {
          if ((statusDrawRedaction || (statusPageSidebar && statusPageSidebar === 'pageSidebar')) && this.state.drawSelectedMouse === true && !stateSidebarPages) {
            const dtoRedactEachWord = []
            const dtoRedactEachWordOcr = []
            dtoRedactEachWord.push(dataToSave)
            dtoRedactEachWordOcr.push(dataToSaveOcr)

            const pagePointSumNewUpdate = []
            const pagePointSumTotalPoints = []
            let pagePointSumDeleted = []
            //Object.keys(canvasDataCopy).forEach((key) => {
            if (canvasDataCopy[this.state.pageNumber] && canvasDataCopy[this.state.pageNumber].length) {
              canvasDataCopy[this.state.pageNumber].forEach((item) => {
                if (item) {
                  let itemDecoded = item
                  if (typeof item === 'string') {
                    itemDecoded = JSON.parse(item)
                  } else if (typeof item === 'object') {
                    itemDecoded = item
                  }
                  if (itemDecoded.pagePointSum) {
                    pagePointSumTotalPoints.push(itemDecoded.pagePointSum)
                  }
                }
              })
            }
            Object.keys(pointsToSave).forEach((key) => {
              if (pointsToSave[key] && pointsToSave[key].length) {
                pointsToSave[key].forEach((item) => {
                  if (item) {
                    let itemDecoded = item
                    if (typeof item === 'string') {
                      itemDecoded = JSON.parse(item)
                    } else if (typeof item === 'object') {
                      itemDecoded = item
                    }
                    if (itemDecoded.pagePointSum) {
                      pagePointSumNewUpdate.push(itemDecoded.pagePointSum)
                    }
                  }
                })
              }
            })

            pagePointSumDeleted = pagePointSumTotalPoints.filter((item3) => !pagePointSumNewUpdate.includes(item3))


            const saveCanvasDocumentSmartViewDTO = {
              idDoc: this.props.documentId,
              pageCoordinates: dtoRedactEachWord,
              coordsToDelete: pagePointSumDeleted

            }
            const saveCanvasDocumentSmartViewDTOOcr = {
              idDoc: this.props.documentId,
              pageCoordinates: dtoRedactEachWordOcr,
              coordsToDelete: pagePointSumDeleted

            }
            if (!this.props.isOcr) {
              apiSmartBoxSaveCanvasDocumentSmartview(saveCanvasDocumentSmartViewDTO).then((response) => {
                this.setState({
                  statusDrawRedaction: false
                })
              }).catch((error) => {
                if (error?.response?.status === 504) {
                  CustomNotificationManager.cleanBuffer('warning', '')
                } else {
                  console.log(error)
                }
              })
            }
            if (this.props.isOcr) {
              apiSmartBoxSaveCanvasDocumentSmartview(saveCanvasDocumentSmartViewDTOOcr).then((response) => {
                this.setState({
                  statusDrawRedaction: false
                })
              }).catch((error) => {
                if (error?.response?.status === 504) {
                  CustomNotificationManager.cleanBuffer('warning', '')
                } else {
                  console.log(error)
                }
              })
            }
            //update 15 Decembrie
            // apiSmartBoxUpdateCanvasPoints(dataToSave).then(() => {
            //     this.setState({
            //       statusDrawRedaction: false
            //     })
            // }).catch((error) => {
            //   if (error?.response?.status === 504) {
            //     CustomNotificationManager.cleanBuffer('warning', '')
            //   }else{
            //     console.log(error)
            //   }
            // })

            apiSmartBoxGetPageCanvasPoints(dataGetPoints).then(() => {

            }).catch((error) => {
              if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
              }else{
                console.log(error)
              }
            })
          } else if (this.state.statusAnnotationSidebar === 'annotationSidebar') {
              const dtoRedactEachWord = []
              const dtoRedactEachWordOcr = []
              dtoRedactEachWord.push(dataToSave)
              dtoRedactEachWordOcr.push(dataToSaveOcr)
              const saveCanvasDocumentSmartViewDTO = {
                idDoc: this.props.documentId,
                pageCoordinates: dtoRedactEachWord

              }
              const saveCanvasDocumentSmartViewDTOOcr = {
                idDoc: this.props.documentId,
                pageCoordinates: dtoRedactEachWordOcr

              }
              if (!this.props.isOcr) {
                apiSmartBoxSaveCanvasDocumentSmartview(saveCanvasDocumentSmartViewDTO).then((response) => {
                  this.setState({
                    statusDrawRedaction: false,
                    statusAnnotationSidebar: '',
                  })
                }).catch((error) => {
                  if (error?.response?.status === 504) {
                    CustomNotificationManager.cleanBuffer('warning', '')
                  } else {
                    console.log(error)
                  }
                })
              }
              if (this.props.isOcr) {
                apiSmartBoxSaveCanvasDocumentSmartview(saveCanvasDocumentSmartViewDTOOcr).then((response) => {
                  this.setState({
                    statusDrawRedaction: false
                  })
                }).catch((error) => {
                  if (error?.response?.status === 504) {
                    CustomNotificationManager.cleanBuffer('warning', '')
                  } else {
                    console.log(error)
                  }
                })
              }

              apiSmartBoxGetPageCanvasPoints(dataGetPoints).then(() => {

              }).catch((error) => {
                if (error?.response?.status === 504) {
                  CustomNotificationManager.cleanBuffer('warning', '')
                }else{
                  console.log(error)
                }
              })
            } else if (this.state.renderSelectToolMove && this.state.closeRedactingStatus) {
            const dtoRedactEachWord = []
            const dtoRedactEachWordOcr = []

            const pagePointSumNewUpdate = []
            const pagePointSumTotalPoints = []
            let pagePointSumDeleted = []

            if (canvasDataCopy[this.state.pageNumber] && canvasDataCopy[this.state.pageNumber].length) {
              canvasDataCopy[this.state.pageNumber].forEach((item) => {
                if (item) {
                  let itemDecoded = item
                  if (typeof item === 'string') {
                    itemDecoded = JSON.parse(item)
                  } else if (typeof item === 'object') {
                    itemDecoded = item
                  }
                  if (itemDecoded.pagePointSum && itemDecoded.x1 && itemDecoded.x2 && itemDecoded.y1 && itemDecoded.y2) {
                    pagePointSumTotalPoints.push({
                      stringValue: `x1-${itemDecoded.x1}-x2-${itemDecoded.x2}-y1-${itemDecoded.y1}-y2-${itemDecoded.y2}-pagePointSum-${itemDecoded.pagePointSum}`,
                      pagePointSumValue: itemDecoded.pagePointSum
                  })
                  }
                }
              })
            }

            Object.keys(pointsToSave).forEach((key) => {
              if (pointsToSave[key] && pointsToSave[key].length) {
                pointsToSave[key].forEach((item) => {
                  if (item) {
                    let itemDecoded = item
                    if (typeof item === 'string') {
                      itemDecoded = JSON.parse(item)
                    } else if (typeof item === 'object') {
                      itemDecoded = item
                    }
                    if (itemDecoded.pagePointSum && itemDecoded.x1 && itemDecoded.x2 && itemDecoded.y1 && itemDecoded.y2) {
                      pagePointSumNewUpdate.push(`x1-${itemDecoded.x1}-x2-${itemDecoded.x2}-y1-${itemDecoded.y1}-y2-${itemDecoded.y2}-pagePointSum-${itemDecoded.pagePointSum}`)
                    }
                  }
                })
              }
            })

            pagePointSumDeleted = pagePointSumTotalPoints.filter((item3) => !pagePointSumNewUpdate.includes(item3.stringValue))
            const pagePointSumDeletedFinish = []
            const pointsToSaveNew = []
            const pointsToSaveNew2 = {}
            pagePointSumDeleted && pagePointSumDeleted.length > 0 && pagePointSumDeleted.forEach((elem) => {
              pagePointSumDeletedFinish.push(elem.pagePointSumValue)
            })

            Object.keys(pointsToSave).forEach((key) => {
              if (pointsToSave[key] && pointsToSave[key].length) {
                pointsToSave[key].forEach((item) => {
                  if (item) {
                    let itemDecoded = item
                    if (typeof item === 'string') {
                      itemDecoded = JSON.parse(item)
                    } else if (typeof item === 'object') {
                      itemDecoded = item
                    }
                    if (pagePointSumDeletedFinish.includes(itemDecoded.pagePointSum)) {
                      pointsToSaveNew.push(item)
                      pointsToSaveNew2[this.state.pageNumber] = pointsToSaveNew
                    }
                  }
                })
              }
            })

            const dataToSaveNew = {
              page: this.state.pageNumber,
              pageRectangleMap: pointsToSaveNew2,

            }
            const dataToSaveOcrNew = {
              page: this.state.pageNumber,
             // pageRectangleMap: pointsToSaveOcr,
              pageRectangleMap: pointsToSaveNew2,

            }
            //dtoRedactEachWord.push(dataToSave)
            dtoRedactEachWord.push(dataToSaveNew)
            //dtoRedactEachWordOcr.push(dataToSaveOcr)
            dtoRedactEachWordOcr.push(dataToSaveOcrNew)
            const saveCanvasDocumentSmartViewDTO = {
              idDoc: this.props.documentId,
              pageCoordinates: dtoRedactEachWord,
              coordsToDelete: pagePointSumDeletedFinish

            }
            const saveCanvasDocumentSmartViewDTOOcr = {
              idDoc: this.props.documentId,
              pageCoordinates: dtoRedactEachWordOcr,
              coordsToDelete: pagePointSumDeletedFinish

            }
            if (!this.props.isOcr) {
              apiSmartBoxSaveCanvasDocumentSmartview(saveCanvasDocumentSmartViewDTO).then((response) => {
                this.setState({
                  statusDrawRedaction: false
                })
              }).catch((error) => {
                if (error?.response?.status === 504) {
                  CustomNotificationManager.cleanBuffer('warning', '')
                } else {
                  console.log(error)
                }
              })
            }
            if (this.props.isOcr) {
              const pagePointSumNewUpdate3 = []
              const pagePointSumTotalPoints3 = []
              let pagePointSumDeleted3 = []
              //Object.keys(canvasDataCopy).forEach((key) => {
              if (canvasDataCopy[this.state.pageNumber] && canvasDataCopy[this.state.pageNumber].length) {
                canvasDataCopy[this.state.pageNumber].forEach((item) => {
                  if (item) {
                    let itemDecoded = item
                    if (typeof item === 'string') {
                      itemDecoded = JSON.parse(item)
                    } else if (typeof item === 'object') {
                      itemDecoded = item
                    }
                    if (itemDecoded.pagePointSum) {
                      pagePointSumTotalPoints3.push(itemDecoded.pagePointSum)
                    }
                  }
                })
              }
              //})
              Object.keys(pointsToSave).forEach((key) => {
                if (pointsToSave[key] && pointsToSave[key].length) {
                  pointsToSave[key].forEach((item) => {
                    if (item) {
                      let itemDecoded = item
                      if (typeof item === 'string') {
                        itemDecoded = JSON.parse(item)
                      } else if (typeof item === 'object') {
                        itemDecoded = item
                      }
                      if (itemDecoded.pagePointSum) {
                        pagePointSumNewUpdate3.push(itemDecoded.pagePointSum)
                      }
                    }
                  })
                }
              })
              pagePointSumDeleted3 = pagePointSumTotalPoints3.filter((item3) => !pagePointSumNewUpdate3.includes(item3))
              const saveCanvasDocumentSmartViewDTOOcr3 = {
                idDoc: this.props.documentId,
                pageCoordinates: dtoRedactEachWordOcr,
                coordsToDelete: pagePointSumDeleted3

              }
              apiSmartBoxSaveCanvasDocumentSmartview(saveCanvasDocumentSmartViewDTOOcr3).then((response) => {
                this.setState({
                  statusDrawRedaction: false
                })
              }).catch((error) => {
                if (error?.response?.status === 504) {
                  CustomNotificationManager.cleanBuffer('warning', '')
                } else {
                  console.log(error)
                }
              })
            }
          } else if (statusPageSidebar && statusPageSidebar === 'pageSidebar' && !stateSidebarPages) {
            if (deleteSelectedRedactionBox) {
              const points = this.getRectanglesForPreviewPage()
              this.updateRectanglesSaved(points)
            }
            apiSmartBoxGetPageCanvasPoints(dataGetPoints).then(() => {

            }).catch((error) => {
              if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
              }else{
                console.log(error)
              }
            })
          } else if (deleteSelectedRedactionBox) {
              const points = this.getRectanglesForPreviewPage()
              this.updateRectanglesSaved(points)
            apiSmartBoxGetPageCanvasPoints(dataGetPoints).then(() => {

            }).catch((error) => {
              if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
              }else{
                console.log(error)
              }
            })
          } else if(!statusPageSidebar && statusPageSidebar !== 'pageSidebar' && statusRedactAll !== 'true') {

            const dtoRedactEachWord = []
            dtoRedactEachWord.push(dataToSave)
            const saveCanvasDocumentSmartViewDTO = {
              idDoc: this.props.documentId,
              pageCoordinates: dtoRedactEachWord

            }
            if (!this.props.isOcr) {
              apiSmartBoxSaveCanvasDocumentSmartview(saveCanvasDocumentSmartViewDTO).then((response) => {
                this.setState({
                  statusDrawRedaction: false
                })
              }).catch((error) => {
                if (error?.response?.status === 504) {
                  CustomNotificationManager.cleanBuffer('warning', '')
                } else {
                  console.log(error)
                }
              })
            }

            // apiSmartBoxUpdateCanvasPoints(dataToSave).then(() => {
            //
            // }).catch((error) => {
            //   if (error?.response?.status === 504) {
            //     CustomNotificationManager.cleanBuffer('warning', '')
            //   }else{
            //     console.log(error)
            //   }
            // })
        }
        } else {
          if (deleteSelectedRedactionBox) {
            const points = this.getRectanglesForPreviewPage()
            this.updateRectanglesSaved(points)
          }
        }
      }
      this.setState({
        statusSavedSmartView: true
      })
    } else {
      apiUpdateCanvasPoints(dataToSave).then(() => {

      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
            console.log(error)
        }
      })
      this.setState({
        statusAfterRemove: false
      })
    }
  }

  selectObject = (object, annotationObj) => {
    let annotationText = ''
    let objectHasAnnotation = false
    if (object.annotationType === 1) {
      if (annotationObj) {

        annotationText = annotationObj.text
        objectHasAnnotation = true
      }
    }
    this.setState({
      annotationText: annotationText,
      objectHasAnnotation: objectHasAnnotation
    })
  }

  selectTool = () => {
    const allPagesCanvas = document.getElementsByClassName('canvas-area')
    this.setState({
      renderSelectTool: !this.state.renderSelectTool
    }, () => {
      if (!this.state.renderSelectTool) {
        this.setState({
          disableClickOnPage: true,
          removeSelectedBtnDisabled: true
        })
      } else {
        this.setState({
          tool: Tools.Select,
          selectedTool: 'select',
          disableClickOnPage: false,
          removeSelectedBtnDisabled: false
        })
        for (const page of allPagesCanvas) {
          if (page) {
            page.setAttribute('style', 'z-index: 1;')
          }
        }
      }
    })
  }

  selectRectangle = () => {
    const allPagesCanvas = document.getElementsByClassName('canvas-area')
    this.setState({
      renderSelectDraw: !this.state.renderSelectDraw,
      renderSearch: false,
      renderSelectText: false,
      renderCommentsText: false,
      viewAnnotationsRedaction: false,
      renderSelectTool: false,
      displaySearchButtons: false,
      drawSelectedMouse: true,
    }, () => {
      if (this.state.renderSelectDraw) {
        for (const page of allPagesCanvas) {
          if (page) {
            page.setAttribute('style', 'z-index: 1;')
          }
        }
      } else {
        for (const page of allPagesCanvas) {
          if (page) {
            page.setAttribute('style', 'z-index: 0;')
          }
        }
      }
      this.setState({
        tool: Tools.Rectangle,
        selectedTool: 'draw',
      }, () => {
        if (!this.state.renderSelectDraw) {
          this.setState({
            disableClickOnPage: true
          })
        } else {
          this.setState({
            disableClickOnPage: false
          })
        }
      })
    })
  }

  selectText() {
    const allPagesCanvas = document.getElementsByClassName('canvas-area')
    //this._sketch._fc.remove()
    const history = this._sketch._fc._objects
     for (let i = 0; i < history.length; i++) {
       // if (history[i].type === 4) {
       //   this._sketch._fc.remove(history[i])
       // }
     }
    this.setState({
      renderSelectText: !this.state.renderSelectText,
      renderCommentsText: false,
      renderSelectDraw: false,
      renderSearch: false,
      renderSelectTool: false,
      viewAnnotationsRedaction: false,
      displaySearchButtons: false,
      drawSelectedMouse: true
    }, () => {
      if (this.state.renderSelectText) {
        for (const page of allPagesCanvas) {
          if (page) {
            page.setAttribute('style', 'z-index: 0;')
          }
        }
        this.setState({
          valueSelectNavigation: 'review-by-page'
        })
        this.changeNavigation('redacting')
      } else {
        for (const page of allPagesCanvas) {
          if (page) {
            page.setAttribute('style', 'z-index: 1;')
          }
        }
        this.setState({
          valueSelectNavigation: 'ai-results'
        })
        this.changeNavigation('find-asset')
      }
      this.setState({
        tool: Tools.Select,
      }, () => {
        if (!this.state.renderSelectText) {
          this.setState({
            disableClickOnPage: true
          })
        } else {
          this.setState({
            disableClickOnPage: false
          })
        }
      })
    })
  }

  selectComments() {
    const allPagesCanvas = document.getElementsByClassName('canvas-area')
    this.setState({
      //renderSelectText: !this.state.renderSelectText,
      renderSelectText: false,
      renderCommentsText: !this.state.renderCommentsText,
      renderSelectDraw: false,
      renderSearch: false,
      renderSelectTool: false,
      viewAnnotationsRedaction: false,
      displaySearchButtons: false,
      drawSelectedMouse: true
    }, () => {
      if (this.state.renderCommentsText) {
        for (const page of allPagesCanvas) {
          if (page) {
            page.setAttribute('style', 'z-index: 0;')
          }
        }
        this.setState({
          valueSelectNavigation: 'comments'
        })
        this.changeNavigation('comments')
      } else {
        for (const page of allPagesCanvas) {
          if (page) {
            page.setAttribute('style', 'z-index: 1;')
          }
        }
        this.setState({
          valueSelectNavigation: 'ai-results'
        })
        this.changeNavigation('find-asset')
      }
      this.setState({
        tool: Tools.Select,
      }, () => {
        if (!this.state.renderCommentsText) {
          this.setState({
            disableClickOnPage: true
          })
        } else {
          this.setState({
            disableClickOnPage: false
          })
        }
      })
    })
  }

  toggle = () => {
    this._sketch.selectedObject()
    this.setState((prevState) => ({
      modal: !prevState.modal
    }))
  }

  setCurrentSketch = (sketch) => {
    this._sketch = sketch
  }

  cavnasModalRemove = (type) => {
    if (type === 'object') {
      this._sketch.removeSelected()
    } else {
      this._sketch.removeSelectedAnnotation()
    }
    this.hideCanvasModals()
    this._sketch.unselectObject()
    this.setState({
      annotationText: ''
    })
  }

  hideCanvasModals = () => {
    let { pageSidebarVisibleBefore, pageSidebarVisible } = this.state
    if (pageSidebarVisibleBefore) {
      pageSidebarVisible = true
    }
    this.setState({
      annotationListVisible: false,
      pageSidebarVisible
    })
  }

  addAnnotation = (text) => {
    const annotation = (text) ? text : this.state.annotationText
    if (annotation) {
      if (this.state.objectHasAnnotation) {
        this._sketch.editAnnotation(annotation)
      } else {
        this._sketch.addAnnotation(annotation)
        this.setState({
          objectHasAnnotation: true
        })
      }
    }
    this.setState({
      haveChanges: true
    })
  }

  editLabel = (obj, label) => {
    if (label) {
      this._sketch.editLabel(obj, label)
    }
  }

  changeAnnotationText = (text, type) => {
    if (type === 'old') {
      if (text) {
        this.setState({
          annotationText: text
        })
      }
    } else {
      this.setState({ annotationText: text.target.value })
    }
  }

  onDocumentLoadSuccess = () => {
    this.setState({
      isDocumentLoaded: true
    })
  }

  initMouseUpEvent = () => {
    const pages = document.getElementsByClassName('react-pdf__Page__textContent')
    const that = this
    Array.from(pages).forEach((element) => {
      element.addEventListener('mouseup', () => that.getSelectedWords())
    })
  }

  loadingFinished = () => {
    this.setState({
      loading: false,
      showCloseButton: true,
      isDocumentReady: true,
      loadingBeforeMark: true
    }, () => {
      this.initMouseUpEvent()
      if (this.props.searchedValue) {
        this.searchTextInDoc(this.props.searchedValue)
      }
    })
  }

  annotationHandleChange = (value, mode, type) => {
    if (value.length) {
      if (type === 'select') {
        this.addAnnotation(value)
        if (mode === 'add') {
          this.setState({
            annotationText: value
          })
        }
        if (mode === 'edit') {
          this.setState({
            annotationText: value
          })
        }
      }
    }
  }

  handleRenderPage = (pageNumber) => {
    const newState = [...this.state.renderedPagesStatus]
    newState[pageNumber-1] = true

    this.setState({
      renderedPagesStatus: newState
    })
  }

  addObjectToCanvas = (sketchObj) => {
    const { canvasData, pageNumber, totalPages, canvasDataOcrDrawBox, canvasDataComments } = this.state
    if (this.state.renderCommentsText) {
      let foundObj = false
      let foundObjOcr = false
      for(let i=1; i<= totalPages; i++){
        if(typeof canvasDataComments[i] !== 'undefined') {
          const found = canvasDataComments[i].find(el => el.pagePointSum === sketchObj.pagePointSum)
          if (typeof found !== 'undefined') {
            foundObj = true
            break
          }
        }
      }
      if (this.props.isOcr) {
        for (let i = 1; i <= totalPages; i++) {
          if (typeof canvasDataOcrDrawBox[i] !== 'undefined') {
            const found = canvasDataOcrDrawBox[i].find(el => el.pagePointSum === sketchObj.pagePointSum)
            if (typeof found !== 'undefined') {
              foundObjOcr = true
              break
            }
          }
        }
      }

      if(!foundObj) {
        if (typeof canvasDataComments[pageNumber] === 'undefined') {
          canvasDataComments[pageNumber] = []
        }
        const obj = {
          x1: sketchObj.aCoords.bl.x,
          x2: sketchObj.aCoords.br.x,
          y1: sketchObj.aCoords.bl.y,
          y2: sketchObj.aCoords.tr.y,
          text: sketchObj.text ? sketchObj.text : '',
          annotation: (sketchObj.text) ? sketchObj.text : sketchObj.annotation ? sketchObj.annotation : '',
          type: Number(sketchObj.type),
          pagePointSum: sketchObj.pagePointSum,
          picture: true,
          drawTesdt: false
        }
        //const aCoordsblx = sketchObj.aCoords.br.x - sketchObj.aCoords.bl.x
        // if (aCoordsblx > 10) {
        if (obj) {
          const saveSelectedTextCommentSmartViewDTO = {
            text: sketchObj.text ? sketchObj.text : '',
            textPosition: JSON.stringify(obj),
            page: pageNumber,
            idDocument: this.state.documentId,
          }
          // apiSaveSelectedTextComment(saveSelectedTextCommentSmartViewDTO).then((response) => {
          //
          // })
          canvasDataComments[pageNumber].push(JSON.stringify(obj))
        }
        this.setState({
          canvasDataComments
        })
        //}
      }

      if(!foundObjOcr && this.props.isOcr) {
        if (typeof canvasDataOcrDrawBox[pageNumber] === 'undefined') {
          canvasDataOcrDrawBox[pageNumber] = []
        }
        const obj = {
          x1: sketchObj.aCoords.bl.x,
          x2: sketchObj.aCoords.br.x,
          y1: sketchObj.aCoords.bl.y,
          y2: sketchObj.aCoords.tr.y,
          text: sketchObj.text ? sketchObj.text : '',
          annotation: (sketchObj.text) ? sketchObj.text : sketchObj.annotation ? sketchObj.annotation : '',
          type: Number(sketchObj.type),
          pagePointSum: sketchObj.pagePointSum,
          picture: true,
          drawTesdt: true
        }
        //const aCoordsblx = sketchObj.aCoords.br.x - sketchObj.aCoords.bl.x
        // if (aCoordsblx > 10) {
        canvasDataOcrDrawBox[pageNumber].push(JSON.stringify(obj))
        this.setState({ canvasDataOcrDrawBox })
        //}
      }
    } else {
      let foundObj = false
      let foundObjOcr = false
      for(let i=1; i<= totalPages; i++){
        if(typeof canvasData[i] !== 'undefined') {
          const found = canvasData[i].find(el => el.pagePointSum === sketchObj.pagePointSum)
          if (typeof found !== 'undefined') {
            foundObj = true
            break
          }
        }
      }
      if (this.props.isOcr) {
        for (let i = 1; i <= totalPages; i++) {
          if (typeof canvasDataOcrDrawBox[i] !== 'undefined') {
            const found = canvasDataOcrDrawBox[i].find(el => el.pagePointSum === sketchObj.pagePointSum)
            if (typeof found !== 'undefined') {
              foundObjOcr = true
              break
            }
          }
        }
      }

      if(!foundObj) {
        if (typeof canvasData[pageNumber] === 'undefined') {
          canvasData[pageNumber] = []
        }
        const obj = {
          x1: sketchObj.aCoords.bl.x,
          x2: sketchObj.aCoords.br.x,
          y1: sketchObj.aCoords.bl.y,
          y2: sketchObj.aCoords.tr.y,
          text: sketchObj.text ? sketchObj.text : '',
          annotation: (sketchObj.text) ? sketchObj.text : sketchObj.annotation ? sketchObj.annotation : '',
          type: Number(sketchObj.type),
          pagePointSum: sketchObj.pagePointSum,
          picture: true,
          drawTesdt: false
        }
        //const aCoordsblx = sketchObj.aCoords.br.x - sketchObj.aCoords.bl.x
        // if (aCoordsblx > 10) {
        canvasData[pageNumber].push(JSON.stringify(obj))
        this.setState({
          canvasData})
        //}
      }

      if(!foundObjOcr && this.props.isOcr) {
        if (typeof canvasDataOcrDrawBox[pageNumber] === 'undefined') {
          canvasDataOcrDrawBox[pageNumber] = []
        }
        const obj = {
          x1: sketchObj.aCoords.bl.x,
          x2: sketchObj.aCoords.br.x,
          y1: sketchObj.aCoords.bl.y,
          y2: sketchObj.aCoords.tr.y,
          text: sketchObj.text ? sketchObj.text : '',
          annotation: (sketchObj.text) ? sketchObj.text : sketchObj.annotation ? sketchObj.annotation : '',
          type: Number(sketchObj.type),
          pagePointSum: sketchObj.pagePointSum,
          picture: true,
          drawTesdt: true
        }
        //const aCoordsblx = sketchObj.aCoords.br.x - sketchObj.aCoords.bl.x
        // if (aCoordsblx > 10) {
        canvasDataOcrDrawBox[pageNumber].push(JSON.stringify(obj))
        this.setState({ canvasDataOcrDrawBox })
        //}
      }
    }
  }

  onUpdateAnswerToogleOcr = (e , checkedPreview) => {
    const allPagesCanvas = document.getElementsByClassName('canvas-area')
    if (checkedPreview) {
      this.fill('rgba(0, 0, 0, 0.3)')
    } else {
      this.fill('rgba(0, 0, 0, 1)')
    }
    if (this.state.renderSelectText) {
      if (checkedPreview) {
        for (const page of allPagesCanvas) {
          if (page) {
            page.setAttribute('style', 'z-index: 0;')
          }
        }
      } else {
        for (const page of allPagesCanvas) {
          if (page) {
            page.setAttribute('style', 'z-index: 1;')
          }
        }
      }
    }
    this._sketch.changeRedactingMode(checkedPreview)
    this.setState({
      redactingMode: checkedPreview
    })
  }

  onUpdateAnswerToogle = (e , checkedPreview) => {
    const allPagesCanvas = document.getElementsByClassName('canvas-area')
    if (checkedPreview) {
      this.fill('rgba(0, 0, 0, 0.3)')
    } else {
      this.fill('rgba(0, 0, 0, 1)')
    }
    if (this.state.renderSelectText) {
      if (checkedPreview) {
        for (const page of allPagesCanvas) {
          if (page) {
            page.setAttribute('style', 'z-index: 0;')
          }
        }
      } else {
        for (const page of allPagesCanvas) {
          if (page) {
            page.setAttribute('style', 'z-index: 1;')
          }
        }
      }
    }
    this._sketch.changeRedactingMode(checkedPreview)
    this.setState({
      redactingMode: checkedPreview
    })
  }
  onEndPoints = (endx, endy) => {
    this.setState({
      endPointXDraw: endx,
      endPointYDraw: endy,
    })
  }
  renderPages = (totalPages, canvasData, forPageNumber, tool, pdfBytes, isDocumentLoaded, divPrint, height, width, scale, viewAnnotationsRedaction, renderSelectDraw, renderSelectText, drawByHandText, clearSketch, isOcr) => {
    return (
      <Document
        ref={ (ref) => this.myDiv = ref }
        file={ pdfBytes ? pdfBytes : '' }
        onLoadSuccess={ () => this.onDocumentLoadSuccess() }
        noData={ <h4>Please select a file</h4> }
        loading={<div></div>}
        //loading={<div className="content-spinning-document"><Spin tip="Loading"></Spin></div>}
      >
        { [ ...Array(totalPages).keys() ].map((index) => {
          return (
            <CanvasPage
              ref={ index + 1 }
              pageNumber={ index + 1 }
              totalPages={ totalPages }
              canvasData={ canvasData[index + 1] }
              setSketch={ this.setCurrentSketch }
              forPageNumber={ forPageNumber }
              tool={ tool }
              isDocumentLoaded={ isDocumentLoaded }
              loadingFinished={ this.loadingFinished }
              divPrint={ divPrint }
              width={ width }
              height={ height }
              key={ index }
              selectTool={ this.selectTool }
              selectRectangle={ this.selectRectangle }
              scale={ scale }
              showAnnotationsList={ this.showAnnotationsList }
              hideAnnotationsList={ this.hideAnnotationsList }
              isModalShow={ viewAnnotationsRedaction }
              selectObject={ this.selectObject }
              renderSelectDraw={ renderSelectDraw }
              renderSelectText={ renderSelectText }
              handleRenderPage={ this.handleRenderPage }
              setAlreadyRedactedList = { this.setAlreadyRedactedList }
              drawByHandText={ drawByHandText }
              clearSketch={ clearSketch }
              addObjectToCanvas = { this.addObjectToCanvas }
              onEndPoints = { this.onEndPoints }
              isOcr={isOcr}
              renderCommentsText={ this.state.renderCommentsText }
            />
          )
        }
        ) }
      </Document>
    )
  }

  onAnswerToogle = (checked) => {
    if (checked) {
      this.fill('rgba(0, 0, 0, 0.5)')
    } else {
      this.fill('rgba(0, 0, 0, 1)')
    }
    this._sketch.changeRedactingMode(checked)
    this.setState({
      redactingMode: checked
    })
  }

  onAnnotationToogle = (checked) => {
    if (checked) {
      this.setState({ printAnnotation: true })
    } else {
      this.setState({ printAnnotation: false })
    }
  }

  async finalizeRedacting() {
    const { landscape, scale, totalPages, width, height, printAnnotation, divPrint, idDoc } = this.state
    const timeOut = totalPages > 10 ? 2000 : 1000
    if (this.props.visitedRedactionSmart === 'smart') {
      this.toggleLoadingProcess(true, 'finalise')
      this.savePage()
      setTimeout(() => {
        getIdFinaliseDocument(idDoc).then(() => {
          this.toggleLoadingProcess(false)
          this.props.closeRedacting(this.props.pdfBytes)
          this.props.fetchFiles()
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
              console.log(error)
          }
        })
      }, timeOut)
    } else {
      if (this.state.matchedText.length > 0) {
        await this.clearPreviousSearchedData()
      }

      window.scrollTo(0, 0)
      this.setState({
        showCloseButton: false
      })
      const elements = document.querySelectorAll(`div#${ divPrint }`)
      for (let i = 0; i < totalPages; i++) {
        elements[i].classList.add('show-pdf-page')
      }
      this.fill('rgba(0, 0, 0, 1)')
      this._sketch.showAnnotation(printAnnotation)
      const parentElement = document.querySelector('#pdf-page')
      const HTML_Width = parentElement.clientWidth
      const PDF_Width = HTML_Width / scale
      const PDF_Height = height / scale
      let position = 0
      let canvas_image_width = parentElement.clientWidth / scale
      let canvas_image_height = parentElement.clientHeight / scale
      let heightLeft = canvas_image_height

      let pdf = null
      if (landscape) {
        pdf = new jsPDF('landscape', 'pt', [PDF_Width, PDF_Height])
      } else {
        pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height])
      }

      if (totalPages > 5) {
        let counter = totalPages
        let step = 5
        let from = 0
        const pages = Array.from(document.querySelectorAll(landscape ?
          '#div-to-print-landscape' :
          '#div-to-print-portrait'))
        while (counter > 0) {
          if (counter < step) {
            if (counter > 0) {
              step = counter
            }
          }
          counter -= step
          const printElements = pages.slice(from, from + step + 1)
          const printParent = document.createElement('div')
          const parent = document.querySelectorAll('.redacted-section')[0]
          printElements.forEach((item) => printParent.appendChild(item))
          parent.appendChild(printParent)
          from += step

          canvas_image_width = printParent.clientWidth / scale
          canvas_image_height = printParent.clientHeight / scale
          heightLeft = canvas_image_height
          await this.renderPdf(printParent, position, totalPages, scale, width, height, heightLeft, canvas_image_width,
            canvas_image_height, PDF_Height, landscape, pdf)

          printParent.remove()
          printElements.forEach((item) => parent.appendChild(item))
        }

        const pdfFile = pdf.output('blob')
        const data = new FormData()
        data.append('data', pdfFile)

        saveRedactedDocument(data, this.props.documentId).then(() => {
          this.toggleLoadingProcess(false)
          this.savePage()
          this.props.closeRedacting(this.props.pdfBytes)
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
              console.log(error)
          }
        })
        this.toggleLoadingProcess(false)
      } else {
        html2canvas(document.getElementById('pdf-page'), { allowTaint: true }).then((canvas) => {
          pdf.addImage(canvas, 'JPG', 0, position, canvas_image_width, canvas_image_height)
          heightLeft -= PDF_Height
          while (heightLeft > 0) {
            position = heightLeft - canvas_image_height
            pdf.addPage(landscape ? 'l' : 'p', [width / scale, height / scale])
            pdf.addImage(canvas, 'JPG', 0, position, canvas_image_width, canvas_image_height)
            heightLeft -= PDF_Height
          }
          const pdfFile = pdf.output('blob')
          const data = new FormData()
          data.append('data', pdfFile)

          saveRedactedDocument(data, this.props.documentId).then(() => {
            this.savePage()
            this.toggleLoadingProcess(false)
            this.props.closeRedacting(this.props.pdfBytes)
          }).catch((error) => {
            if (error?.response?.status === 504) {
              CustomNotificationManager.cleanBuffer('warning', '')
            }else{
                console.log(error)
            }
          })

          //hide back all pages except current
          for (let i = 0; i < this.state.totalPages; i++) {
            if (Number(elements[i].getAttribute('data-pageid')) === 1) {
              elements[i].classList.add('show-pdf-page')
            } else {
              elements[i].classList.remove('show-pdf-page')
            }
          }
          this.fill('rgba(0, 0, 0, 0.5)')
          this._sketch.showAnnotation(true)
        })
      }
    }
  }

  closeRedacting = () => {
    const { statusCloseSmartviewRedactAll, setIntervalUpdateLockStatus } = this.state
    cookie.remove('checkLockStatusSmartviewDocument', { path: '/' })
    clearInterval(setIntervalUpdateLockStatus)
    this.setState({setIntervalUpdateLockStatus})
    if (!statusCloseSmartviewRedactAll) {
      this.setState({
        sourceActive: '',
        closeRedactingStatus: true
      }, () => {
        this.savePage()
        this.props.closeRedacting(this.props.pdfBytes)
      })
      this.setState({
        finalizeLoading: false,
      })
      cookie.remove('sendNoteResponse', {path: '/'})
      this.onRemoveEventDisableScroll()
    }
    this.onUnlockSmartviewDocument()
  }

  onUnlockSmartviewDocument = () => {
    apiUnlockSmartviewDocument(this.state.documentId).then((response) => {

    })
  }

  addAnnotationOptions = (list) => {
    const { Option } = Select
    const response = []
    for (const item of list) {
      response.push(<Option key={ item.exemptionCode } value={ item.exemptionCode }>{ item.exemptionCode }</Option>)
    }
    return response
  }

  addTimeForOpening(totalPages) {
    if (totalPages) {
      if (totalPages <= 10) {
        return Math.ceil(totalPages * 0.25)
      } else if (totalPages > 10 && totalPages <= 30) {
        return Math.ceil(totalPages * 0.35)
      } else if (totalPages > 30 && totalPages <= 60) {
        return Math.ceil(totalPages * 0.45)
      } else if (totalPages > 60 && totalPages <= 100) {
        return Math.ceil(totalPages * 0.60 / 60)
      } else if (totalPages > 100) {
        return Math.ceil(totalPages * 0.75 / 60)
      }
    }
  }

  calculatePrintingTime(totalPages) {
    if (totalPages) {
      if (totalPages <= 10) {
        return Math.ceil(totalPages * 0.85)
      } else if (totalPages > 10 && totalPages <= 30) {
        return Math.ceil(totalPages * 0.85)
      } else if (totalPages > 30 && totalPages <= 60) {
        return Math.ceil(totalPages * 0.90)
      } else if (totalPages > 60 && totalPages <= 100) {
        return Math.ceil(totalPages * 0.90 / 60)
      } else if (totalPages > 100) {
        return Math.ceil(totalPages * 0.99 / 60)
      }
    }
  }

  /**
   * This method searches the words in documents
   * */
  onSaveSearchItemHistory = (value, status) => {
    if (value) {
      const dtoSearch = {
        term: value,
        idDoc: this.props.documentId
      }
      apiSmartBoxAddTermToSearchHistory(dtoSearch).then((response) => {
        if (response.status === 200) {
          this.setState({
            statusHistory: true
          }, () => {
            this.getSearchHistoryList()
          })
        }
      })
    }
  }
  getSearchHistoryList = () => {
    apiGetSmartviewSearchHistoryTerms(this.props.documentId).then((response) => {
      let getTermsSearch = []
      let sortTermsSearch = []
      getTermsSearch = response.data
      const uniqueGetTermsSearch = [...new Set(getTermsSearch)]
      const uniqueTermsSearch = uniqueGetTermsSearch && uniqueGetTermsSearch.length > 0 && uniqueGetTermsSearch.filter((a, i) => uniqueGetTermsSearch.findIndex((s) => a.toLowerCase() === s.toLowerCase()) === i)
      if (uniqueTermsSearch && uniqueTermsSearch.length > 0) {
        sortTermsSearch = uniqueGetTermsSearch.sort((a, b) => b.length - a.length)
      }
      this.setState({
        listSearchHistory: sortTermsSearch
      })
      sortTermsSearch && sortTermsSearch.length > 0 && sortTermsSearch.forEach((item) => {
        this.searchTextInDoc(item, 'search-history', 'get')
      })
    })
  }

  async searchTextInDocHistory(value, status, status2) {
    const { listSearchHistory, searchMatchedElement, sortTermsSearch  } = this.state
    const valueHistoryList = []
    let sortTermsSearchHistory = []
    let uniqueListSearchHistory = []
    const parentClassName = this.props.landscape ? '#div-to-print-landscape' : '#div-to-print-portrait'
    if (value) {
      searchMatchedElement && searchMatchedElement.length > 0 && searchMatchedElement.forEach((item) => {
        const element = item
        if (element) {
          element.id = 'matched-word--current'
          element.style.background = 'transparent'
        }
      })

      sortTermsSearchHistory = uniqueListSearchHistory.sort((a, b) => b.length - a.length)
      searchMatchedElement && searchMatchedElement.length && searchMatchedElement.forEach((element) => {
        // const element2 = document.getElementsByClassName(element.className)
        // if (element2 && element2.length > 0) {
        //   element2[0].removeAttribute('id')
        // }
      })

      listSearchHistory && listSearchHistory.length > 0 && listSearchHistory.forEach((value2) => {
            // const aiAnnotations = document.querySelectorAll(`.${ value2 }`)
            // for (const aiAnnotate of aiAnnotations) {
            //   aiAnnotate.style.background = 'transparent'
            // }
            //const instance = new Mark(document.querySelector('#pdf-page'))
            // const instance = new Mark(document.querySelector('#pdf-page'))
            //    instance.unmark({
            //      element: markRef.current,
            //      className: 'mark',
            //      exclude: [new RegExp(`\\b${value2}\\b`, 'gi')]
            //    })

          }
      )


      valueHistoryList.push(value)
      if (value && status === 'search-history' && status2 !== 'get') {
        this.onSaveSearchItemHistory(value)
      }
      this.setState({
        listSearchHistory: valueHistoryList.concat(listSearchHistory),
        searchMatchedWords: [],
        matchedText: [],
      }, () => {
        if (this.state.listSearchHistory && this.state.listSearchHistory.length > 0) {
          uniqueListSearchHistory = this.state.listSearchHistory.filter((a, i) => this.state.listSearchHistory.findIndex((s) => a === s) === i)
        } else {
          uniqueListSearchHistory = []
        }
        sortTermsSearchHistory = uniqueListSearchHistory.sort((a, b) => b.length - a.length)
        this.searchHistoryTextInDoc(value, status, status2)
        // sortTermsSearchHistory && sortTermsSearchHistory.length > 0 && sortTermsSearchHistory.forEach((value2) => {
        //   this.searchHistoryTextInDoc(value2, status, status2)
        // })
      })
    } else {
      this.setState({
        searchEmpty: true
      })
    }
  }

  async searchHistoryTextInDoc(value, status, status2) {
    let {searchMatchedElement} = this.state
    // if (value && status === 'search-history' && status2 !== 'get') {
    //   this.onSaveSearchItemHistory(value)
    // }

    searchMatchedElement && searchMatchedElement.length > 0 && searchMatchedElement.forEach((item) => {
      const element = item
      if (element) {
        element.style.background = 'transparent'
      }
    })
    let j = 0
    let i = 0
    const color = '#3dd8db'
    if (this.state.matchedText.length > 0) {
      await this.clearPreviousSearchedData()
    }
    const listSearchHistory = []
    listSearchHistory.push({
      key: `${value}-parent`,
      identifier: 'category-parent',
      name: value,
      children: []
    })
    const items = []
    const parentClassName = this.props.landscape ? '#div-to-print-landscape' : '#div-to-print-portrait'
    const options = {
      'className': 'matched-word',
      //'className': `${ value } doc_annotate`,
      'ignoreJoiners': false,
      'element': 'span',
      'acrossElements': true,
      'separateWordSearch': false,
      'wildcards': 'withSpaces',
      //'accuracy': {'limiters': [' @', ',', ', ', ' .', '. ', ':', ';', '/>', '"', "'", "`", '<', '>', '[', ']', '(', ')']},
      'accuracy': {'value': 'exactly', 'limiters': [' @',',',', ',' .','. ',':',';','/>','"',"'","`",'<','>','[',']','(',')','’','’s','’ ','’s ','-','.','@','{','}','$','&','!','?','~','#','%','^','*','+','_','=','|','\\','/']},
      each: (element) => {
        j++
        const parent = element.parentElement.closest(parentClassName)
        //add to element the class ${ keyName }-${ j }
        const item = {
          key: `${ value }-${ j }`,
          index: j,
          identifier: 'children',
          pageNumber: parent ? parent.getAttribute('data-pageid') : '',
          name: value,
          title: `${ value }`,
          isSearchedWord: true,
          children: [],
          backgroundColor: element.style.background
        }
        const keyName = value.replace(/\W/g, 'a')
        element.classList.add(`${ keyName }-${ j }`)
        element.name = `${ value }-${ j }`
        element.setAttribute('data-page-nr', element.pageNumber)
        items.push(item)
        if (element.parentElement && element.classList.value === element.parentElement.classList.value) {
          element.style.background = 'transparent'
        }
        //   else {
        //   element.style.background = element.style.background
        // }
      }
    }
    const instance = new Mark(document.querySelector('#pdf-page'))
    instance.mark(value.trim(), options)
    const searchMatchedWords = document.querySelectorAll('span.matched-word')
    searchMatchedWords.forEach((v) => {
      const parent = v.closest(parentClassName)
      v.setAttribute('data-page-nr', parent.getAttribute('data-pageid'))

    })
    searchMatchedWords && searchMatchedWords.length > 0 && searchMatchedWords.forEach((item) => {
      if (item.name.includes(value)) {
        i++
        listSearchHistory[0].redacted = false
        listSearchHistory[0].children.push({
          key: `${value}-${i}`,
          name: value,
          pageNumber: `${Number(item.getAttribute('data-page-nr'))}`,
          id: i - 1
        })
      }
    })
    let updateListSearchHistory = []
    if (this.state.treeDataSearchHistory && this.state.treeDataSearchHistory.length > 0) {
      updateListSearchHistory = this.state.treeDataSearchHistory.concat(listSearchHistory)
    } else {
      updateListSearchHistory = listSearchHistory
    }
    if (status && status === 'search-history') {
      this.setState({
        treeDataSearchHistory: updateListSearchHistory,
        navigationType: 'search-history'
      })
    }
    if (searchMatchedWords) {
      this.setState({
        matchedText: searchMatchedWords,
        searchIndex: 0,
        searchMatchedElement: searchMatchedWords,
        searchPage: searchMatchedWords[0] ? Number(searchMatchedWords[0].getAttribute('data-page-nr')) : 1,
        currentMarkedIndex: 1,
        borderColor: color,
        valueSelectNavigation: 'search-history'
      })
      const element = searchMatchedWords[0]
      if (element) {
        element.id = 'matched-word--current'
        element.style.borderColor = color
        this.onSearchChangePage(element).then(() => {
          this.scrollToMatchedWord()
        })
      }
    }
  }

  async searchTextInDoc(value, status, status2) {
    const {searchMatchedElement} = this.state
    if (value && status === 'search-history' && status2 !== 'get') {
      this.onSaveSearchItemHistory(value)
    }
    searchMatchedElement && searchMatchedElement.length > 0 && searchMatchedElement.forEach((item) => {
      const element = item
      if (element) {
        element.style.background = 'transparent'
      }
    })
    let j = 0
    let i = 0
    const color = '#3dd8db'
    if (this.state.matchedText.length > 0) {
      await this.clearPreviousSearchedData()
    }
    const listSearchHistory = []
    listSearchHistory.push({
      key: `${value}-parent`,
      identifier: 'category-parent',
      name: value,
      children: []
    })
    const items = []
    const parentClassName = this.props.landscape ? '#div-to-print-landscape' : '#div-to-print-portrait'
    const options = {
      'className': 'matched-word',
      //'className': `${ value } doc_annotate`,
      'ignoreJoiners': true,
      'element': 'span',
      'acrossElements': false,
      'separateWordSearch': false,
      'wildcards': 'withSpaces',
      //'accuracy': {'limiters': [' @', ',', ', ', ' .', '. ', ':', ';', '/>', '"', "'", "`", '<', '>', '[', ']', '(', ')']},
      'accuracy': {'value': 'exactly', 'limiters': [' @',',',', ',' .','. ',':',';','/>','"',"'","`",'<','>','[',']','(',')','’','’s','’ ','’s ','-','.','@','{','}','$','&','!','?','~','#','%','^','*','+','_','=','|','\\','/']},
      each: (element) => {
        j++
        const parent = element.parentElement.closest(parentClassName)
        //add to element the class ${ keyName }-${ j }
        const item = {
          key: `${ value }-${ j }`,
          index: j,
          identifier: 'children',
          pageNumber: parent ? parent.getAttribute('data-pageid') : '',
          name: value,
          title: `${ value }`,
          isSearchedWord: true,
          children: [],
          backgroundColor: element.style.background
        }
        const keyName = value.replace(/\W/g, 'a')
        element.classList.add(`${ keyName }-${ j }`)
        element.name = `${ value }-${ j }`
        element.setAttribute('data-page-nr', element.pageNumber)
        items.push(item)
        if (element.parentElement && element.classList.value === element.parentElement.classList.value) {
          element.style.background = 'transparent'
        }
        //   else {
        //   element.style.background = element.style.background
        // }
      }
    }
    const instance = new Mark(document.querySelector('#pdf-page'))
    instance.mark(value.trim(), options)
    const searchMatchedWords = document.querySelectorAll('span.matched-word')
    searchMatchedWords.forEach((v) => {
      const parent = v.closest(parentClassName)
      v.setAttribute('data-page-nr', parent.getAttribute('data-pageid'))

    })
    searchMatchedWords && searchMatchedWords.length > 0 && searchMatchedWords.forEach((item) => {
      if (item.name.includes(value)) {
        i++
        listSearchHistory[0].redacted = false
        listSearchHistory[0].children.push({
          key: `${value}-${i}`,
          name: value,
          pageNumber: `${Number(item.getAttribute('data-page-nr'))}`,
          id: i - 1
        })
      }
    })
    let updateListSearchHistory = []
    if (this.state.treeDataSearchHistory && this.state.treeDataSearchHistory.length > 0) {
      updateListSearchHistory = this.state.treeDataSearchHistory.concat(listSearchHistory)
    } else {
      updateListSearchHistory = listSearchHistory
    }
    if (status && status === 'search-history') {
      this.setState({
        treeDataSearchHistory: updateListSearchHistory,
        navigationType: 'search-history'
      })
    }
    if (searchMatchedWords) {
      this.setState({
        matchedText: searchMatchedWords,
        searchIndex: 0,
        searchMatchedElement: searchMatchedWords,
        searchPage: searchMatchedWords[0] ? Number(searchMatchedWords[0].getAttribute('data-page-nr')) : 1,
        currentMarkedIndex: 1,
        borderColor: color,
        valueSelectNavigation: 'search-history'
      })
      const element = searchMatchedWords[0]
      if (element) {
        element.id = 'matched-word--current'
        element.style.borderColor = color
        this.onSearchChangePage(element).then(() => {
          this.scrollToMatchedWord()
        })
      }
    }
  }

  /**
   * This method is for searching functionality, it is highlighting the next text adding a special id,
   * and change the page to the location of searched text
   * */
  highlightNextText = () => {
    let index = this.state.searchIndex
    if (index >= 0) {
      const prevElement = this.state.matchedText[index]
      if (prevElement) {
        prevElement.removeAttribute('id')
      }
    }
    index = index + 1

    if (this.state.matchedText) {
      const element = this.state.matchedText[index]
      if (element) {
        element.id = 'matched-word--current'
        element.style.borderColor = this.state.borderColor
        const currentMarkedIndex = this.state.currentMarkedIndex + 1
        this.setState({
          searchIndex: index,
          currentMarkedIndex: currentMarkedIndex
        })
        this.onSearchChangePage(element)
      }
    }
  }

  /**
   * This method is for searching functionality, it is highlighting the prev text adding a special id,
   * and change the page to the location of searched text
   * */
  highlightPrevText = () => {
    let index = this.state.searchIndex
    const nextElement = this.state.matchedText[index]
    if (nextElement) {
      nextElement.removeAttribute('id')
    }
    if (this.state.matchedText) {
      index = index - 1
      const element = this.state.matchedText[index]
      if (element) {
        element.id = 'matched-word--current'
        element.style.borderColor = this.state.borderColor
        const currentMarkedIndex = this.state.currentMarkedIndex > 0 ? this.state.currentMarkedIndex - 1 : 0
        this.setState({
          searchIndex: index,
          currentMarkedIndex: currentMarkedIndex
        })
        this.onSearchChangePage(element)
      }
    }
  }
  onSearchTextElementFind = (item) => {
    const { searchMatchedElement, searchMatchId } = this.state
    const previousElement = searchMatchedElement[searchMatchId]
    //const previousElement = searchMatchedElement[item.id]
    if (searchMatchedElement) {
      if (item.id !== searchMatchId && previousElement)
        previousElement.removeAttribute('id')
    }
    const color = '#3dd8db'

    //const parentClassName = this.props.landscape ? '#div-to-print-landscape' : '#div-to-print-portrait'
    // const options = {
    //   'className': 'matched-word',
    //   'ignoreJoiners': true,
    //   'element': 'span',
    //   'acrossElements': true,
    //   'separateWordSearch': false,
    //   'wildcards': 'withSpaces'
    // }
    // const instance = new Mark(document.querySelector('#pdf-page'))
    // instance.mark(item.name.trim(), options)
    // const searchMatchedWords = document.querySelectorAll('span.matched-word')
    // searchMatchedWords.forEach((v) => {
    //   const parent = v.closest(parentClassName)
    //   v.setAttribute('data-page-nr', parent.getAttribute('data-pageid'))
    //
    // })
    if (searchMatchedElement) {
      this.setState({
        matchedText: searchMatchedElement[item.id],
        searchIndex: item.id,
        searchPage: searchMatchedElement[item.id] ? Number(searchMatchedElement[item.id].getAttribute('data-page-nr')) : 1,
        currentMarkedIndex: 1,
        borderColor: color,
        searchMatchId: item.id,
        valueSelectNavigation: 'search-history'
      })
      let elementSelect = ''
      let elementSelect3 = []
      searchMatchedElement && searchMatchedElement.length > 0 && searchMatchedElement.forEach((item2) => {
        const element = item2
        if (element.name.toLowerCase() === item.key.toLowerCase()) {
          elementSelect = item2
          elementSelect3.push(item2)
          element.style.borderColor = color
        } else {
          //elementSelect = item2
          element.style.borderColor = 'transparent'
        }
      })
      //const element = searchMatchedElement[item.name]
      if (elementSelect) {
        elementSelect.id = 'matched-word--current'
        elementSelect.style.borderColor = color
        this.onSearchChangePage(elementSelect).then(() => {
          this.scrollToMatchedWord()
        })
      }
      // if (element) {
      //   element.id = 'matched-word--current'
      //   element.style.borderColor = color
      //   this.onSearchChangePage(element).then(() => {
      //     this.scrollToMatchedWord()
      //   })
      // }
    }
  }
  /**
   *  This method is for searching functionality, it changes the page to the location of searched text
   * */
  async onSearchChangePage(element, indexEl, arrayQuery, uniqueElementKeyList, pageNr = 1) {
    const elementsPage = element !== null ? Number(element.getAttribute('data-page-nr')) : pageNr
    if (elementsPage === this.state.totalPages) {
      // this.setState({
      //   checkStartRedaction: false
      // })
      this.setState({
        checkStartRedactAllPagesNextFiles: false,
        checkStartRedactAllPagesBackFiles: false
      })
    }
    if (elementsPage && (elementsPage !== this.state.pageNumber) && elementsPage !== 0) {
      // if (this.state.navigationType === 'pages') {
      //   this.slider.slickGoTo(elementsPage - 1)
      // }
      // this.setState({
      //   checkStartRedaction: false
      // })
      //this.changePage(elementsPage, null, null, indexEl, arrayQuery)
      this.changePageWords(elementsPage, null, null, indexEl, arrayQuery, uniqueElementKeyList)
      const elemPage = elementsPage + 1
      if (elementsPage === this.state.totalPages) {
        setTimeout(() => {
          if (elementsPage === this.state.totalPages) {
            this.setState({
              checkStartRedaction: false
            })
            this.onRemoveEventDisableScroll()
          }
        }, 5000)
      } else if (elemPage === this.state.totalPages) {
        setTimeout(() => {
          if (elemPage === this.state.totalPages) {
            this.setState({
              checkStartRedaction: false
            })
            this.onRemoveEventDisableScroll()
          }
        }, 5000)
      } else if (elemPage !== this.state.totalPages && (this.getStatusElementRedaction() === 'elementStandard' ||
          this.state.sourceActive === 'standard' || this.state.sourceActive === 'regex' || this.state.sourceActive === 'dictionaries')) {
        setTimeout(() => {
            this.setState({
              checkStartRedaction: false
            })
            this.onRemoveEventDisableScroll()
        }, 5000)
      }
    } else if ((indexEl && arrayQuery && arrayQuery.length && indexEl === arrayQuery.length - 1) || (indexEl === 0 &&  arrayQuery.length === 1)) {
      //this.changePage(elementsPage,null, null, indexEl, arrayQuery)
      this.changePageWords(elementsPage,null, null, indexEl, arrayQuery, uniqueElementKeyList)
      const elemPage = elementsPage + 1
      if (elementsPage === this.state.totalPages) {
        setTimeout(() => {
          if (elementsPage === this.state.totalPages) {
            this.setState({
              checkStartRedaction: false
            })
            this.onRemoveEventDisableScroll()
          }
        }, 5000)
      } else if (elemPage === this.state.totalPages) {
        setTimeout(() => {
          if (elemPage === this.state.totalPages) {
            this.setState({
              checkStartRedaction: false
            })
            this.onRemoveEventDisableScroll()
          }
        }, 5000)
      } else if (elemPage !== this.state.totalPages && (this.getStatusElementRedaction() === 'elementStandard' ||
          this.state.sourceActive === 'standard' || this.state.sourceActive === 'regex' || this.state.sourceActive === 'dictionaries')) {
        setTimeout(() => {
          this.setState({
            checkStartRedaction: false
          })
          this.onRemoveEventDisableScroll()
        }, 5000)
      }
    } else {
      // this.setState({
      //   checkStartRedaction: false
      // })
    }
  }

  changePageWords = (newPageNumber, annt, statusPageSidebar, indexEl, arrayQuery, uniqueElementKeyList) => {
    this.setState({
      statusClickPageSidebar: statusPageSidebar,
      stateSidebarPages: false
    })
    const elements = document.querySelectorAll(`div#${ this.state.divPrint }`)
    if (elements) {
      for (let i = 0; i < this.state.totalPages; i++) {
        if (elements[i] && Number(elements[i].getAttribute('data-pageid')) === newPageNumber) {
          elements[i].classList.remove('show-pdf-page')
          elements[i].classList.add('show-pdf-page')
        } else if (elements[i]) {
          elements[i].classList.remove('show-pdf-page')
        }
      }
    }

    //add active class to pages thumbnails
    const elementsSmall = document.querySelectorAll('div.previewpdf__container__item')
    if (this.state.navigationType === 'pages') {
      for (let i = 0; i < this.state.totalPages; i++) {
        if (Number(elementsSmall[i].getAttribute('data-pageid')) === newPageNumber) {
          elementsSmall[i].classList.add('previewpdf__container__item--active')
        } else {
          elementsSmall[i].classList.remove('previewpdf__container__item--active')
        }
      }
    }
    const points = this.getRectanglesForPreviewPage()
    this.updateRectanglesWords(points, statusPageSidebar, indexEl, arrayQuery, uniqueElementKeyList)
    if ((indexEl && arrayQuery && arrayQuery.length && indexEl === arrayQuery.length - 1) || (indexEl === 0 &&  arrayQuery.length === 1)) {
      setTimeout(() => {
        const points = this.getRectanglesForPreviewPage()
        this.updateRectanglesWords(points, statusPageSidebar, indexEl, arrayQuery, uniqueElementKeyList)
        initialCanvasData[this.state.pageNumber] = points
      }, 5000)
    }
    const initialCanvasData = this.state.canvasData
    initialCanvasData[this.state.pageNumber] = points
    this.hideCanvasModals()
    this.setState({
      pageNumber: newPageNumber,
      canvasData: initialCanvasData,
      forPageNumber: newPageNumber,
      haveChanges: false,
    }, () => {
      if (this.state.navigationType !== 'pages' && this.state.navigationType !== 'find-asset' && this.state.navigationType !== 'image') {
        const canvas = this._sketch._fc
        canvas._objects.forEach((rectangle) => {
          rectangle.set('stroke', 'rgba(0,0,0,0.41)')
          rectangle.set('strokeWidth', 1)
          if (annt) {
            if ((rectangle.aCoords.br.x === annt.x2 + 1 && rectangle.aCoords.bl.y === annt.y1 + 1)
                || (rectangle.aCoords.br.x === annt.x2 && rectangle.aCoords.bl.y === annt.y1)) {
              rectangle.set('stroke', 'rgb(255,244,11)')
              rectangle.set('strokeWidth', 2)
            }
          }
        })
        canvas.renderAll()
      } else {
        const canvas = this._sketch._fc
        canvas._objects.forEach((rectangle) => {
          rectangle.set('stroke', 'rgba(0,0,0,0.41)')
          rectangle.set('strokeWidth', 1)
        })
        canvas.renderAll()
      }
    })
  }

  updateRectanglesWords = (points, statusPageSidebar, indexEl, arrayQuery, uniqueElementKeyList) => {
    const { statusAfterRemove, stateSidebarPages, statusRedactAll, statusDrawRedaction, dtoRedactWord, sourceActive } = this.state
    const pointsToSave = {}
    const listPoints = []
    let maxHeight //= 10000
    const elements = document.querySelectorAll(`div#${ this.state.divPrint }.show-pdf-page`)
    if(elements.length > 0) {
      maxHeight = elements[0].getBoundingClientRect().height
    } else {
      maxHeight = 10000
    }
    points.forEach((element) => {
      let y1 = element.y1 - 1
      if(y1 > maxHeight) {
        y1 = y1 - maxHeight
      }

      let y2 = element.y2
      if(y2 > maxHeight) {
        y2 = y2 - maxHeight
      }
      const obj = {
        x1: element.x1,
        x2: element.x2 - 1,
        y1: y1,
        y2: y2,
        annotation: element.annotation ? element.annotation : (element.text ? element.text : ''),
        text: element.text ? element.text : '',
        type: element.type,
        pagePointSum: element.pagePointSum,
        points: element.points,
        label: element.label,
        picture: true
      }
      const stringObj = JSON.stringify(obj)
      listPoints.push(stringObj)
      pointsToSave[this.state.pageNumber] = listPoints
      this.setState({
        pagePointSumList: element.pagePointSum,
      })
    })
    const dtoRedactEachWord = []
    let dtoRedactEachWordAll = []
    let uniqueDtoRedactEachWordAll = []
    const dataToSave = {
      //idDoc: this.props.documentId,
      page: this.state.pageNumber,
      pageRectangleMap: pointsToSave

    }
    const dataGetPoints = {
      idDoc: this.props.documentId,
      page: this.state.pageNumber,
    }
      dtoRedactEachWord.push(dataToSave)
      //dtoRedactEachWordAll = this.state.dtoRedactWord.concat(dtoRedactEachWord)
      dtoRedactEachWordAll = dtoRedactEachWord.concat(this.state.dtoRedactWord)
    dtoRedactEachWordAll && dtoRedactEachWordAll.length > 0 && dtoRedactEachWordAll.forEach((list) => {
    })

      uniqueDtoRedactEachWordAll = dtoRedactEachWordAll && dtoRedactEachWordAll.length > 0 && dtoRedactEachWordAll.filter((a, i) => dtoRedactEachWordAll.findIndex((s) => a.page === s.page) === i)
    this.setState({
      dtoRedactWord: dtoRedactEachWordAll
    }, () => {
    })
    if (this.props.isSmartBoxApplication) {
      if (!statusAfterRemove) {
        if (Object.keys(pointsToSave) && Object.keys(pointsToSave).length > 0) {
          if ((statusDrawRedaction || (statusPageSidebar && statusPageSidebar === 'pageSidebar')) && this.state.drawSelectedMouse === true && !stateSidebarPages) {
            const dtoRedactEachWord = []
            dtoRedactEachWord.push(dataToSave)
            const saveCanvasDocumentSmartViewDTO = {
              idDoc: this.props.documentId,
              pageCoordinates: dtoRedactEachWord

            }
            if (!this.props.isOcr) {
              apiSmartBoxSaveCanvasDocumentSmartview(saveCanvasDocumentSmartViewDTO).then((response) => {
                this.setState({
                  statusDrawRedaction: false
                })
              }).catch((error) => {
                if (error?.response?.status === 504) {
                  CustomNotificationManager.cleanBuffer('warning', '')
                } else {
                  console.log(error)
                }
              })
            }

            // apiSmartBoxUpdateCanvasPoints(dataToSave).then(() => {
            //   this.setState({
            //     statusDrawRedaction: false
            //   })
            // }).catch((error) => {
            //   if (error?.response?.status === 504) {
            //     CustomNotificationManager.cleanBuffer('warning', '')
            //   }else{
            //     console.log(error)
            //   }
            // })
            apiSmartBoxGetPageCanvasPoints(dataGetPoints).then(() => {

            }).catch((error) => {
              if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
              }else{
                console.log(error)
              }
            })
          } else
          if (statusPageSidebar && statusPageSidebar === 'pageSidebar' && !stateSidebarPages) {
            apiSmartBoxGetPageCanvasPoints(dataGetPoints).then(() => {

            }).catch((error) => {
              if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
              }else{
                console.log(error)
              }
            })
          } else if(!statusPageSidebar && statusPageSidebar !== 'pageSidebar' && statusRedactAll !== 'true') {
          /*  if ((arrayQuery !== null && arrayQuery && indexEl === arrayQuery.length - 1) || (arrayQuery !== null && arrayQuery && indexEl === 0 && arrayQuery.length === 1)) {
              const saveCanvasDocumentSmartViewDTO = {
                idDoc: this.props.documentId,
                pageCoordinates: uniqueDtoRedactEachWordAll

              }

              if (!this.props.isOcr) {
                apiSmartBoxSaveCanvasDocumentSmartview(saveCanvasDocumentSmartViewDTO).then((response) => {

                })
              }
            }*/

              const saveCanvasDocumentSmartViewDTO = {
                idDoc: this.props.documentId,
                pageCoordinates: uniqueDtoRedactEachWordAll

              }
              if (!this.props.isOcr) {
                apiSmartBoxSaveCanvasDocumentSmartview(saveCanvasDocumentSmartViewDTO).then((response) => {

                })
              }



            // apiSmartBoxUpdateCanvasPoints(dataToSave).then(() => {
            //
            // }).catch((error) => {
            //   if (error?.response?.status === 504) {
            //     CustomNotificationManager.cleanBuffer('warning', '')
            //   }else{
            //     console.log(error)
            //   }
            // })
          }
        }
      }
      this.setState({
        statusSavedSmartView: true
      })
    } else {
      apiUpdateCanvasPoints(dataToSave).then(() => {

      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
      this.setState({
        statusAfterRemove: false
      })
    }
  }

  async onChangePageWithClear(pageNr = 1) {
   if (pageNr && (pageNr !== this.state.pageNumber) && pageNr !== 0) {

      this.changePageWithClear(pageNr)

    }
  }

  /**
   *  This method is for searching functionality, it draw rectangle on current searched text
   * */
  drawRectangleOnMatchedWord = () => {
    const el = document.getElementById('matched-word--current')
    if (el) {
      const elementsText = el.innerText
      const selectionPosition = el.getBoundingClientRect()
      const parentWr = document.querySelector('div.canvas-page-1')
      const rect = new fabric.Rect({
        left: selectionPosition.left - parentWr.getBoundingClientRect().left + window.scrollX,
        top: selectionPosition.top - parentWr.getBoundingClientRect().top - 3,
        width: selectionPosition.width,
        height: selectionPosition.height + 3,
        fill: 'rgba(0, 0, 0, 0.4)',
        angle: 0,
        padding: 0,
        selectable: true,
        annotation: elementsText ? elementsText : '',
        text: elementsText ? elementsText : ''
      })
      this._sketch._fc.add(rect)
    }
  }

  fireSwalToRedactAllMatchedWords = () => {
    swal.fire({
      text: `Do you want to hide all ${ this.state.matchedText.length } matched words ?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3DD8DB',
      cancelButtonColor: '#D33D33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        this.drawAllMatchedWords()
      }
    })
  }

  /**
   *  This method is for searching functionality, it draw rectangle on current searched text
   * */
  drawAllMatchedWords() {

    const elements = document.querySelectorAll('span.matched-word')
    const firstElement = elements[0]
    const parentWr = document.querySelector('div.canvas-page-1')
    for (const el of elements) {
      const elRect = el.getBoundingClientRect()
      this.addSearchedRectangle(el, parentWr, false, elRect)
    }
    if (firstElement) {
      this.onSearchChangePage(firstElement)
    }
  }

  /**
   *  This method is for searching functionality, it draw rectangle on current searched text
   * */
  drawAllMatchedWordsByClassList = (classNameList, unCheckedKeys=[], source='standard') => {
    const { idDoc, docAnnotationCategories, totalPages, divPrint } = this.state
    this.setState({
      checkStartRedaction: null,
      sourceActive: source
    })
    if (classNameList && idDoc) {
      const parameters = {
        idDocument: idDoc,
        orderCategory: docAnnotationCategories
      }
      let spanCoords = []
      for(let i=1; i<=totalPages; i++) {

        const otherPages = document.querySelectorAll('div#'+divPrint+':not([data-pageid="'+i+'"])')
        otherPages.forEach((page) => {
          page.classList.remove('show-pdf-page')
        })

        const page = document.querySelectorAll('div#'+divPrint+'[data-pageid="'+i+'"]')
        if(page.length > 0) {
          page[0].classList.add('show-pdf-page')
        }

        const spans = document.querySelectorAll('span[data-markjs][data-page-nr="'+i+'"]')

        spans.forEach((el) => {
          const temp = el.className.split(' ').slice(-1)
          const rect = el.getBoundingClientRect()
          spanCoords[temp[0]] = { top: rect.top, left: rect.left, width: rect.width, height: rect.height }
        })
      }
      this.setState({ spanCoords }, () => {
        this.changeQueryStatus(parameters, classNameList, unCheckedKeys, source)
        this.onSetDocumentRedactAllStatus("false")
      })
    }
  }

  setAlreadyRedactedList = (alreadyRedactedList) => this.setState({ alreadyRedactedList })

  changeDataAnnotationCategory = (docAnnotationCategories, unCheckedKeys) => {
    const { treeDataAnnotations } = this.state
    const newDocAnnotationCategories = []
    if (treeDataAnnotations && treeDataAnnotations.length) {
      const tree = treeDataAnnotations[0]
      if (tree.children && tree.children.length) {
        tree.children.forEach((parent) => {
          if (parent.children && parent.children.length) {
            parent.children.forEach((children) => {
              const temp = `doc_annotate_${ children.key.split(' ').join('_') }`
              if (docAnnotationCategories.includes(temp)) {
                if (children.children.length) {
                  let numberNumberOfChildren = 0
                  children.children.forEach((nephew) => {
                    numberNumberOfChildren += nephew.children.length
                  })
                  newDocAnnotationCategories.push({ 'name': temp, 'children': numberNumberOfChildren })
                } else {
                  newDocAnnotationCategories.push({ 'name': temp, 'children': children.children.length })
                }
              }
            })
          }
        })
      }
    }
    return newDocAnnotationCategories
  }
  checkedKeysObjectRefsStandard = (checkObjectRefsStandard) => {
    this.setState({
      checkObjectRefsStandard: checkObjectRefsStandard
    })
  }
  fetchSmartBoxAllAfterRemove = async(documentId, clientId) => {
    const response1 = await apiSmartBoxGetAllCanvasPoints(documentId)
    const newCanvasData = await this.convertCanvasData(response1.data.pageRectangleMap === null ? {} : response1.data.pageRectangleMap)
    this.setState({
      canvasData: newCanvasData,
    }, () => {
      //this.initialUpdateCanvas()
    })
  }

  initialUpdateCanvas = (parameters, classNameList, unCheckedKeys, source) => {
    const { totalPages } = this.state
    const initialCanvasData = this.state.canvasData
    for (let i = 1; i <= totalPages; i++) {
      let keepOnPage = []
      if(typeof initialCanvasData[i] !== 'undefined') {
        for (let j = 0; j < initialCanvasData[i].length; j++) {
          const parsed = typeof initialCanvasData[i][j].annotation === 'undefined' ?
              JSON.parse(initialCanvasData[i][j]) : initialCanvasData[i][j]
          if(parsed.type === 1 || parsed.type === 3) {
            keepOnPage.push(parsed)
          }
        }
      }
      initialCanvasData[i] = keepOnPage
    }
    this.setState({
      canvasData: initialCanvasData,
      haveChanges: false,
      clearSketch: true
    }, () => {
      this.setState({ clearSketch: false })
      this.onSearchChangePage(null, 1).then(() => {
      this.callQueryStatus(parameters, classNameList, unCheckedKeys, source, false)
      })
    })
  }
  setTreeDataRegEx = (treeDataRegEx) => this.setState({ treeDataRegEx })
  setTreeDataDict = (treeDataDict) => this.setState({ treeDataDict })

  changeQueryStatus = (parameters, classNameList, unCheckedKeys, source) => {
    const { alreadyRedactedKeys, treeDataAnnotations, canvasData, treeDataRegEx, treeDataDict, treeDataSearchHistory } = this.state
    parameters.orderCategory = this.changeDataAnnotationCategory(parameters.orderCategory, unCheckedKeys)
    if (unCheckedKeys.length) {
      alreadyRedactedKeys[source] = []
      this.setState({ alreadyRedactedKeys, alreadyRedactedList: [], unCheckedKeys: [], canvasCleaned: true, statusSaveQuery: true }, () => {

        //const { totalPages } = this.state
        const data = {}
        data.idDoc = this.props.documentId
        let unCheckedKeyWord = []
        let unCheckedKeyWordAllList = []
        let unCheckedKeyWordUpdate = []
        let unCheckedKeyWordHistory = []
        let unCheckedKeyWordRegEx = []
        let unCheckedKeyWordRegExAllList = []
        let unCheckedKeyWordDict = []
        let unCheckedKeyWordDictAllDict = []
        let unCheckedKeyWordDictKey = []
        const selectAllItems = []
        const selectAllItemsRegEx = []
        const selectAllItemsDict = []
        treeDataAnnotations && treeDataAnnotations.length > 0 && treeDataAnnotations[0].children && treeDataAnnotations[0].children.length > 0 && treeDataAnnotations[0].children.forEach((child) => {
          child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
            child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
              child3 && child3.children && child3.children.length > 0 && child3.children.forEach((child4) => {
                selectAllItems.push(child4)
              })
            })
          })
        })
        if (treeDataSearchHistory && treeDataSearchHistory.length > 0) {
          treeDataSearchHistory.forEach((tree) => {
            tree.children && tree.children.length > 0 && tree.children.forEach((tree2) => {
              unCheckedKeys && unCheckedKeys.length > 0 && unCheckedKeys.forEach((key2) => {
                if (tree2.key === key2) {
                  unCheckedKeyWordHistory.push(`${tree2.key} => ${tree2.name} => ${tree2.pageNumber}`)
                }
              })
            })
          })
        }
        const wordSelected = []
        selectAllItems && selectAllItems.length > 0 && selectAllItems.forEach((obj) => {
          unCheckedKeys && unCheckedKeys.length > 0 && unCheckedKeys.forEach((key2) => {
            if (obj.key === key2) {
              wordSelected.push(obj)
              unCheckedKeyWord.push(`${obj.key} => ${obj.name} => ${obj.pageNumber}`)
              unCheckedKeyWordAllList.push(obj)
            }
          })
        })
        treeDataRegEx && treeDataRegEx.length > 0 && treeDataRegEx[0].children && treeDataRegEx[0].children.length > 0 && treeDataRegEx[0].children.forEach((child) => {
          child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
            child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
              selectAllItemsRegEx.push(child3)
            })
          })
        })
        selectAllItemsRegEx && selectAllItemsRegEx.length > 0 && selectAllItemsRegEx.forEach((obj) => {
          unCheckedKeys && unCheckedKeys.length > 0 && unCheckedKeys.forEach((key2) => {
            if (obj.key === key2) {
              unCheckedKeyWordRegEx.push(`${obj.key} => ${obj.name} => ${obj.pageNumber}`)
              unCheckedKeyWordRegExAllList.push(obj)
            }
          })
        })
        treeDataDict && treeDataDict.length > 0 && treeDataDict[0].children && treeDataDict[0].children.length > 0 && treeDataDict[0].children.forEach((child) => {
          child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
            child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
              selectAllItemsDict.push(child3)
              unCheckedKeyWordDictKey.push(child3.key)
            })
          })
        })
        selectAllItemsDict && selectAllItemsDict.length > 0 && selectAllItemsDict.forEach((obj) => {
          unCheckedKeys && unCheckedKeys.length > 0 && unCheckedKeys.forEach((key2) => {
            if (obj.key === key2) {
              unCheckedKeyWordDict.push(`${obj.key} => ${obj.name} => ${obj.pageNumber}`)
              unCheckedKeyWordDictAllDict.push(obj)
            }
          })
        })
        let pageRectangle = []
        Object.keys(canvasData).forEach((key) => {
          if (canvasData[key] && canvasData[key].length) {
            canvasData[key].forEach((item) => {
              if (item) {
                let itemDecoded = item
                if (typeof item === 'string') {
                  itemDecoded = JSON.parse(item)
                }
                wordSelected && wordSelected.length > 0 && wordSelected.forEach((item2) => {
                    if ((itemDecoded.annotation === item2.key) || (itemDecoded.annotation === item2.name)) {
                      itemDecoded.numberPage = key
                       pageRectangle.push(itemDecoded)
                    }
                })
              }
            })
          }
        })
        const uniquePageRectangle = pageRectangle && pageRectangle.length > 0 ? pageRectangle.filter((a, i) => pageRectangle.findIndex((s) => a.annotation === s.annotation) === i) : []
        this.onPageRectangle(pageRectangle, 'key')
        //const jsonPageRectangle = JSON.stringify(this.onPageRectangle(pageRectangle, 'key'))
        const dtoRemoveCanvas = {
          idDocument: this.props.documentId,
          keyWordStandard: source === 'history' ? unCheckedKeyWordHistory : unCheckedKeyWord,
          keywordDictionary: unCheckedKeyWordDict,
          keywordRegex: unCheckedKeyWordRegEx,
          pageRectangleList: uniquePageRectangle
        }
        const dtoRemoveCanvas2 = {
          idDocument: this.props.documentId,
          keyWordStandard: source === 'history' ? unCheckedKeyWordHistory : unCheckedKeyWord,
          keywordDictionary: unCheckedKeyWordDict,
          keywordRegex: unCheckedKeyWordRegEx,
          pageRectangleList: uniquePageRectangle
        }
        let dtoRemoveCanvasAllCanvas = []
        dtoRemoveCanvasAllCanvas = unCheckedKeyWordAllList.concat(unCheckedKeyWordRegExAllList, unCheckedKeyWordDictAllDict)
        this.savePageAfterRemove(dtoRemoveCanvas, alreadyRedactedKeys, parameters, unCheckedKeys, classNameList, source, dtoRemoveCanvasAllCanvas)
        // apiSmartBoxRemoveCanvas(dtoRemoveCanvas).then((response) => {
        //   if (response.status === 200) {
        //     this.setState({
        //       statusAfterRemove: true
        //     }, () => {
        //       this.fetchSmartBoxAllAfterRemove(this.state.documentId, this.state.clientId)
        //       if (source === 'standard') {
        //         this.initialUpdateCanvas(parameters, classNameList, unCheckedKeys, source)
        //         if (alreadyRedactedKeys.dictionaries && alreadyRedactedKeys.dictionaries.length > 0) {
        //           this.initialUpdateCanvas(parameters, alreadyRedactedKeys.dictionaries, unCheckedKeys, 'dictionaries')
        //         }
        //         if (alreadyRedactedKeys.regex && alreadyRedactedKeys.regex.length > 0) {
        //           this.initialUpdateCanvas(parameters, alreadyRedactedKeys.regex, unCheckedKeys, 'regex')
        //         }
        //       } else if (source === 'dictionaries') {
        //         this.initialUpdateCanvas(parameters, classNameList, unCheckedKeys, source)
        //         if (alreadyRedactedKeys.standard && alreadyRedactedKeys.standard.length > 0) {
        //           this.initialUpdateCanvas(parameters, alreadyRedactedKeys.standard, unCheckedKeys, 'standard')
        //         }
        //         if (alreadyRedactedKeys.regex && alreadyRedactedKeys.regex.length > 0) {
        //           this.initialUpdateCanvas(parameters, alreadyRedactedKeys.regex, unCheckedKeys, 'regex')
        //         }
        //       } else if (source === 'regex') {
        //         this.initialUpdateCanvas(parameters, classNameList, unCheckedKeys, source)
        //         if (alreadyRedactedKeys.dictionaries && alreadyRedactedKeys.dictionaries.length > 0) {
        //           this.initialUpdateCanvas(parameters, alreadyRedactedKeys.dictionaries, unCheckedKeys, 'dictionaries')
        //         }
        //         if (alreadyRedactedKeys.standard && alreadyRedactedKeys.standard.length > 0) {
        //           this.initialUpdateCanvas(parameters, alreadyRedactedKeys.standard, unCheckedKeys, 'standard')
        //         }
        //       }
        //     })
        //   }
        // }).catch((error) => {
        //   if (error?.response?.status === 504) {
        //   CustomNotificationManager.cleanBuffer('warning', '')
          // }else{
          //     console.log(error)
          // }
        // })

        // apiSmartBoxClearCanvasPoints(data).then((response) => {
        //   if (response.status === 200) {
        //
        //     const initialCanvasData = this.state.canvasData
        //     for (let i = 1; i <= totalPages; i++) {
        //       let keepOnPage = []
        //       if(typeof initialCanvasData[i] !== 'undefined') {
        //         for (let j = 0; j < initialCanvasData[i].length; j++) {
        //           const parsed = typeof initialCanvasData[i][j].annotation === 'undefined' ?
        //             JSON.parse(initialCanvasData[i][j]) : initialCanvasData[i][j]
        //           if(parsed.type === 1 || parsed.type === 3) {
        //             keepOnPage.push(parsed)
        //           }
        //         }
        //       }
        //       initialCanvasData[i] = keepOnPage
        //     }
        //     this.setState({
        //       canvasData: initialCanvasData,
        //       haveChanges: false,
        //       clearSketch: true
        //     }, () => {
        //       this.setState({ clearSketch: false })
        //       // this.onSearchChangePage(null, 1).then(() => {
        //       this.callQueryStatus(parameters, classNameList, unCheckedKeys, source, false)
        //       // })
        //     })
        //   }
        // })

      })
    } else {
      this.setState({ canvasCleaned: false }, () => {
        this.setState({
          statusSaveQuery: false
        })
        this.callQueryStatus(parameters, classNameList, unCheckedKeys, source, true)
      })
    }
  }
  onPageRectangle = (arr, key) => {
    return arr.reduce((obj, item) => {
      obj[item] = item
      return obj
    }, {})
  }
  callQueryStatus = (parameters, classNameList, unCheckedKeys, source, emptyUncheckedKeys) => {
    const { alreadyRedactedKeys, redactingMode, spanCoords } = this.state
    if (source === 'standard') {
      apiSmartBoxGetNewQueryStatus(parameters).then((response) => {
        if (response.status === 200) {
          this.setState({
            checkStartRedactAllPagesNextFiles: true,
            checkStartRedactAllPagesBackFiles: true
          })
          if (this.state.pageNumber === 1) {
            setTimeout(() => {
              this.setState({
                checkStartRedactAllPagesNextFiles: false,
                checkStartRedactAllPagesBackFiles: false
              })
            }, 2000)
          }
          const queryStatus = response.data
          const parentWr = document.querySelector('div.canvas-page-1')
          const pageHeight = document.querySelector('#pdf-page').clientHeight
          let elements = []
          classNameList.forEach((name, index) => {
            const elementsByClass = document.getElementsByClassName(name)
            Object.keys(elementsByClass).forEach((idx) => {
              elements.push(elementsByClass[idx])
            })
          })
          elements = this.sortElementsByPage(elements)
           const elementKeyList = []
            // const parentWr = document.querySelector('div.canvas-page-1')
            // let elements = document.getElementsByClassName(name)
            // elements = this.sortElementsByPage(elements)
            //for (const el of elements) {
              elements && elements.length > 0 && elements.forEach((el, indexEl, arrayQuery) => {
              const temp = el.className.split(' ').slice(-1)
              if (temp.length && Object.keys(queryStatus).length) {
                const elementKey = temp[0]
                let elRect = typeof spanCoords[elementKey] !== 'undefined' ? spanCoords[elementKey] : el.getBoundingClientRect()
                Object.keys(queryStatus).forEach((key) => {
                  if (queryStatus[key].length) {
                    queryStatus[key].forEach((item) => {
                      if (!alreadyRedactedKeys[source].includes(elementKey)) {
                        if (indexEl < arrayQuery.length - 2) {
                          //indexEl = arrayQuery.length - 1
                        }
                        const left = elRect.left
                        const top = elRect.top > pageHeight ? elRect.top - pageHeight : elRect.top
                        const width = elRect.width
                        const height = elRect.height
                        const elRectObj = {left, top, width, height}
                        elementKeyList.push(elementKey)

                        const uniqueElementKeyList = elementKeyList && elementKeyList.length > 0 && elementKeyList.filter((a, i) => elementKeyList.findIndex((s) => a === s) === i)
                        if (item.key === elementKey) {
                          alreadyRedactedKeys[source].push(elementKey)
                          const colorForRectangles = redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'

                          // const elRect = el.getBoundingClientRect();
                          setTimeout(() => {
                            this.addSearchedRectangle(el, parentWr, colorForRectangles, elRectObj, unCheckedKeys, indexEl, arrayQuery, uniqueElementKeyList)
                          }, 500)
                        } else {
                          const tempItemKey = item.key.split('-')[0]
                          const tempElementKey = elementKey.split('-')[0]
                          if (tempItemKey === tempElementKey) {
                            alreadyRedactedKeys[source].push(elementKey)
                            const colorForRectangles = redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'
                            setTimeout(() => {
                              this.addSearchedRectangle(el, parentWr, colorForRectangles, elRectObj, unCheckedKeys, indexEl, arrayQuery, uniqueElementKeyList)
                            }, 500)
                          } else {
                            this.setState({
                              checkStartRedaction: true
                            })
                            this.onCallToDisableScroll()
                          }
                        }
                      } else if (unCheckedKeys && unCheckedKeys.length > 0) {
                          const left = elRect.left
                          const top = elRect.top > pageHeight ? elRect.top - pageHeight : elRect.top
                          const width = elRect.width
                          const height = elRect.height
                          const elRectObj = {left, top, width, height}

                          if (item.key === elementKey) {
                            alreadyRedactedKeys[source].push(elementKey)
                            const colorForRectangles = redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'

                            setTimeout(() => {
                              this.addSearchedRectangle(el, parentWr, colorForRectangles, elRectObj, unCheckedKeys, indexEl, arrayQuery)
                            }, 500)
                          }
                        } else {
                        if (indexEl && arrayQuery && arrayQuery.length && indexEl === arrayQuery.length - 1) {
                          const left = elRect.left
                          const top = elRect.top > pageHeight ? elRect.top - pageHeight : elRect.top
                          const width = elRect.width
                          const height = elRect.height
                          const elRectObj = {left, top, width, height}

                          if (item.key === elementKey) {
                            alreadyRedactedKeys[source].push(elementKey)
                            //const colorForRectangles = redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'
                            const colorForRectangles = null

                            setTimeout(() => {
                              this.addSearchedRectangle(el, parentWr, colorForRectangles, elRectObj, unCheckedKeys, indexEl, arrayQuery)
                            }, 500)
                          }
                        }
                      }
                    })
                  }
                })
                if(emptyUncheckedKeys) {
                  // this.setState({ unCheckedKeys: [] })
                }
              }
            //}
           })
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
            console.log(error)
        }
      })
    } else if ((source === 'business22' || source === 'manual') && classNameList.length) {
      this.callQueryStatusForBusiness(classNameList, source)
    } else if ((source === 'dictionaries' || source === 'regex')) {
      const pageHeight = document.querySelector('#pdf-page').clientHeight
        classNameList.forEach((name) => {
          const parentWr = document.querySelector('div.canvas-page-1')
          let elements = document.getElementsByClassName(name)
          elements = this.sortElementsByPage(elements)
          Object.keys(elements).forEach((index) => {
            const temp = elements[index].className.split(' ').slice(-1)
            if (temp.length) {
              const elementKey = temp[0]

              let elRect = typeof spanCoords[elementKey] !== 'undefined' ? spanCoords[elementKey] : elements[index].getBoundingClientRect()
              const left = elRect.left
              const top = elRect.top > pageHeight ? elRect.top - pageHeight : elRect.top
              const width = elRect.width
              const height = elRect.height
              const elRectObj = {left, top, width, height}

              if (!alreadyRedactedKeys[source]) {
                alreadyRedactedKeys[source] = []
              }
              if (!alreadyRedactedKeys[source].includes(elementKey) || true) {
                alreadyRedactedKeys[source].push(elementKey)
                const colorForRectangles = redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'
                setTimeout(() => {
                  this.addSearchedRectangle(elements[index], parentWr, colorForRectangles, elRectObj)
                }, 500)
              }
            }
          })
        })
      this.toggleLoadingProcess(false)
    } else if (source === 'history') {
      const pageHeight = document.querySelector('#pdf-page').clientHeight
      const { searchMatchedElement } = this.state
      const classNameListNew = []
      classNameList && classNameList.length > 0 && classNameList.forEach((classItem) => {
        const keyName = classItem.replace(/\W/g, 'a')
        //classNameListNew.push(keyName.toLowerCase())
        classNameListNew.push(keyName)
      })
      const searchMatchedElement3 = []
      searchMatchedElement && searchMatchedElement.length > 0 && searchMatchedElement.forEach((item3) => {
        const substringName = item3.className && item3.className.substring('matched-word'.length + 1, item3.className.length)
        const keyName2 = substringName.replace(/\W/g, 'a')
        if (classNameListNew.includes(keyName2)) {
          searchMatchedElement3.push(item3)
        }
      })

      searchMatchedElement3 && searchMatchedElement3.length > 0 && searchMatchedElement3.forEach((item, indexEl, arrayQuery) => {
        const uniqueElementKeyList = classNameListNew
        const arrayQuery2 = []
        arrayQuery && arrayQuery.length > 0 && arrayQuery.forEach((elem2, indexClass) => {
          arrayQuery2.push(elem2.className.split(' ').slice(-1))
        })

        const uniqueElementKeyList2 = arrayQuery2 && arrayQuery2.length > 0 && arrayQuery2.filter((a, i) => arrayQuery2.findIndex((s) => a === a) === i)

        const parentWr = document.querySelector('div.canvas-page-1')
        const substringName = item.className && item.className.substring('matched-word'.length + 1, item.className.length)
        const keyName2 = substringName.replace(/\W/g, 'a')
        if (classNameListNew.includes(keyName2)) {
          if (item) {
            const temp = item.className.split(' ').slice(-1)
            const elementKey = temp[0]
            let elRect = typeof spanCoords[elementKey] !== 'undefined' ? spanCoords[elementKey] : item.getBoundingClientRect()
            const left = elRect.left
            const top = elRect.top > pageHeight ? elRect.top - pageHeight : elRect.top
            const width = elRect.width
            const height = elRect.height
            const elRectObj = {left, top, width, height}
            const colorForRectangles = redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'
            setTimeout(() => {
              this.addSearchedRectangleHistory(item, parentWr, colorForRectangles, elRectObj, unCheckedKeys, indexEl, arrayQuery, uniqueElementKeyList)
            }, 500)
          }
        }
      })
      //const pageHeight = document.querySelector('#pdf-page').clientHeight
      this.toggleLoadingProcess(false)
    } else if (classNameList.length) {
      const pageHeight = document.querySelector('#pdf-page').clientHeight

      classNameList.forEach((name) => {
        const parentWr = document.querySelector('div.canvas-page-1')
        let elements = document.getElementsByClassName(name)
        elements = this.sortElementsByPage(elements)
        Object.keys(elements).forEach((index) => {
          const temp = elements[index].className.split(' ').slice(-1)

          if (temp.length) {
            const elementKey = temp[0]

            let elRect = typeof spanCoords[elementKey] !== 'undefined' ? spanCoords[elementKey] : elements[index].getBoundingClientRect()
            const left = elRect.left
            const top = elRect.top > pageHeight ? elRect.top - pageHeight : elRect.top
            const width = elRect.width
            const height = elRect.height
            const elRectObj = {left, top, width, height}

            if (!alreadyRedactedKeys[source]) {
              alreadyRedactedKeys[source] = []
            }
            if (!alreadyRedactedKeys[source].includes(elementKey) || true) {
              alreadyRedactedKeys[source].push(elementKey)
              const colorForRectangles = redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'
              setTimeout(() => {
                this.addSearchedRectangle(elements[index], parentWr, colorForRectangles, elRectObj)
              }, 500)

            }
          }
        })
      })
      this.toggleLoadingProcess(false)
    }
    // else if (unCheckedKeys && unCheckedKeys.length > 0) {
    //   alreadyRedactedKeys.business= []
    //   alreadyRedactedKeys.manual= []
    //   alreadyRedactedKeys.regex= []
    //   alreadyRedactedKeys.standard= []
    //   if (!alreadyRedactedKeys[source]) {
    //     alreadyRedactedKeys[source] = []
    //   }
    //   this.setState({
    //     alreadyRedactedKeys
    //   })
    // }
    else {
      this.toggleLoadingProcess(false)
    }
  }
  addSearchedRectangleHistory = (el, parentWr, color= false, elRect, unCheckedKeys = [], indexEl, arrayQuery, uniqueElementKeyList) => {
    const { canvasCleaned, alreadyRedactedList, redactingMode, totalPages, treeDataSearchHistory } = this.state

    if (el) {
      const temp = el.className.split(' ').slice(-1)
      if (temp.length) {
        const elementKey = temp[0]
        const elementsPage = Number(el.getAttribute('data-page-nr'))
        const elementsText = el.innerText
        this.setState({
          checkStartRedaction: true
        })
        const elementKeyList = []
        elementKeyList.push(temp[0])
        this.onCallToDisableScroll()

        if (elementsPage !== this.state.pageNumber) {
          this.onSearchChangePage(el, indexEl, arrayQuery, uniqueElementKeyList).then(() => {

            const parentWr = document.querySelector('div.canvas-page-'+this.state.pageNumber)
            const selectionPosition = elRect //el.getBoundingClientRect()
            const left = selectionPosition.left - parentWr.getBoundingClientRect().left + window.scrollX
            const top = selectionPosition.top - parentWr.getBoundingClientRect().top - 3

            if (!canvasCleaned) {
              if (!this.duplicateInCanvas(el, left, top)) {
                const rect = new fabric.Rect({
                  left: left,
                  top: top,
                  width: selectionPosition.width,
                  height: selectionPosition.height + 3,
                  fill: color ? color : (redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'),
                  angle: 0,
                  padding: 0,
                  selectable: true,
                  text: elementsText ? elementsText : '',
                  annotation: elementKey
                })
                this._sketch._fc.add(rect)
                this._sketch._fc.renderAll()
              }
            } else if (!alreadyRedactedList.includes(elementKey)) {
              alreadyRedactedList.push(elementKey)
              const rect = new fabric.Rect({
                left: left,
                top: top,
                width: selectionPosition.width,
                height: selectionPosition.height + 3,
                fill: color ? color : (redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'),
                angle: 0,
                padding: 0,
                selectable: true,
                text: elementsText ? elementsText : '',
                annotation: elementKey
              })
              this._sketch._fc.add(rect)
              this._sketch._fc.renderAll()
            }
            this.toggleLoadingProcess(false)
            if (elementsPage !== totalPages) {
              this.toggleLoadingProcess(false)
              setTimeout(() => {
                if (elementsPage === totalPages) {
                  this.setState({
                    checkStartRedaction: false
                  })
                  this.onRemoveEventDisableScroll()
                }
              }, 5000)
            }
          })
          if (elementsPage !== totalPages) {
            this.toggleLoadingProcess(false)
            setTimeout(() => {
              if (elementsPage === totalPages) {
                this.setState({
                  checkStartRedaction: false
                })
                this.onRemoveEventDisableScroll()
              }
            }, 5000)
          }
        } else if ((indexEl && arrayQuery && arrayQuery.length && indexEl === arrayQuery.length - 1) || (indexEl === 0 &&  arrayQuery.length === 1) || (indexEl === 0 &&  uniqueElementKeyList.length === 1)) {

          this.onSearchChangePage(el, indexEl, arrayQuery, uniqueElementKeyList).then(() => {

            const parentWr = document.querySelector('div.canvas-page-'+this.state.pageNumber)
            const selectionPosition = elRect //el.getBoundingClientRect()
            const left = selectionPosition.left - parentWr.getBoundingClientRect().left + window.scrollX
            const top = selectionPosition.top - parentWr.getBoundingClientRect().top - 3

            if (!canvasCleaned) {
              if (!this.duplicateInCanvas(el, left, top)) {
                const rect = new fabric.Rect({
                  left: left,
                  top: top,
                  width: selectionPosition.width,
                  height: selectionPosition.height + 3,
                  fill: color ? color : (redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'),
                  angle: 0,
                  padding: 0,
                  selectable: true,
                  text: elementsText ? elementsText : '',
                  annotation: elementKey
                })
                this._sketch._fc.add(rect)
                this._sketch._fc.renderAll()
              }
            } else if (!alreadyRedactedList.includes(elementKey)) {
              alreadyRedactedList.push(elementKey)
              const rect = new fabric.Rect({
                left: left,
                top: top,
                width: selectionPosition.width,
                height: selectionPosition.height + 3,
                fill: color ? color : (redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'),
                angle: 0,
                padding: 0,
                selectable: true,
                text: elementsText ? elementsText : '',
                annotation: elementKey
              })
              this._sketch._fc.add(rect)
              this._sketch._fc.renderAll()
            }
            this.toggleLoadingProcess(false)
            if (elementsPage !== totalPages) {
              this.toggleLoadingProcess(false)
              setTimeout(() => {
                if (elementsPage === totalPages) {
                  this.setState({
                    checkStartRedaction: false
                  })
                  this.onRemoveEventDisableScroll()
                }
              }, 5000)
            }
          })
          if (elementsPage !== totalPages) {
            this.toggleLoadingProcess(false)
            setTimeout(() => {
              if (elementsPage === totalPages) {
                this.setState({
                  checkStartRedaction: false
                })
                this.onRemoveEventDisableScroll()
              }
            }, 5000)
          }
        } else {
          if (elementsPage === totalPages) {
            setTimeout(() => {
              if (elementsPage === totalPages) {
                this.setState({
                  checkStartRedaction: false
                })
                this.onRemoveEventDisableScroll()
              }
            }, 5000)
          } else if (elementsPage !== totalPages) {
            setTimeout(() => {
              if (elementsPage !== totalPages) {
                this.setState({
                  checkStartRedaction: false
                })
                this.onRemoveEventDisableScroll()
              }
            }, 5000)
          }
          const parentWr = document.querySelector('div.canvas-page-'+this.state.pageNumber)
          const selectionPosition = elRect //el.getBoundingClientRect()
          const left = selectionPosition.left - parentWr.getBoundingClientRect().left + window.scrollX
          const top = selectionPosition.top - parentWr.getBoundingClientRect().top - 3
          if (!canvasCleaned) {
            if (!this.duplicateInCanvas(el, left, top)) {
              const rect = new fabric.Rect({
                left: left,
                top: top,
                width: selectionPosition.width,
                height: selectionPosition.height + 3,
                fill: color ? color : (redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'),
                angle: 0,
                padding: 0,
                selectable: true,
                text: elementsText ? elementsText : '',
                annotation: elementKey
              })
              this._sketch._fc.add(rect)
              this._sketch._fc.renderAll()
            }
          } else if (!alreadyRedactedList.includes(elementKey)) {
            alreadyRedactedList.push(elementKey)
            const rect = new fabric.Rect({
              left: left,
              top: top,
              width: selectionPosition.width,
              height: selectionPosition.height + 3,
              fill: color ? color : (redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'),
              angle: 0,
              padding: 0,
              selectable: true,
              text: elementsText ? elementsText : '',
              annotation: elementKey
            })
            this._sketch._fc.add(rect)
            this._sketch._fc.renderAll()
          }
          if (elementsPage === totalPages) {
            this.toggleLoadingProcess(false)
          }
        }
        treeDataSearchHistory && treeDataSearchHistory.length > 0 && treeDataSearchHistory.forEach((elem) => {
          if (el.name.toUpperCase().includes(elem.name.toUpperCase())) {
            elem.redacted = true
          }
        })
      }
    }
    return 1
  }
  sortElementsByPage = (elements) => {
    const response = []
    const { totalPages } = this.state
    for (let i=1; i<=totalPages; i++) {
      if (Object.keys(elements).length) {
        Object.keys(elements).forEach((index) => {
          const elementsPage = Number(elements[index].getAttribute('data-page-nr'))
          if (elementsPage === i) {
            response.push(elements[index])
          }
        })
      }
    }
    return response
  }

  callQueryStatusForBusiness = async(classNameList, source) => {
    const { alreadyRedactedKeys, redactingMode } = this.state
    classNameList.forEach((name) => {
      const parentWr = document.querySelector('div.canvas-page-1')
      let elements = document.getElementsByClassName(name)
      elements = this.sortElementsByPage(elements)
      Object.keys(elements).forEach((index) => {
        const temp = elements[index].className.split(' ').slice(-1)
        const elRect = elements[index].getBoundingClientRect()
        if (temp.length > 0) {
          const elementKey = temp[0]
          if (!alreadyRedactedKeys[source]) {
            alreadyRedactedKeys[source] = []
          }
          if (!alreadyRedactedKeys[source].includes(elementKey) || true) {
            const colorForRectangles = redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'
            alreadyRedactedKeys[source].push(elementKey)
            setTimeout(() => {
              this.addSearchedRectangle(elements[index], parentWr, colorForRectangles, elRect)
            }, 500)

          }
        }
      })
    })
    this.toggleLoadingProcess(false)
  }

  duplicateInCanvas = (el, left, top) => {
    const { canvasData } = this.state
    let response = false
    this.setState({

    }, () => {
      Object.keys(canvasData).forEach(function(key) {
        if (canvasData[key] && canvasData[key].length) {
          canvasData[key].forEach((item) => {
            if (item) {
              let itemDecoded = item
              if (typeof item === 'string') {
                itemDecoded = JSON.parse(item)
              }
              if (Math.round(itemDecoded.x1) === Math.round(left) && Math.round(itemDecoded.y2) === Math.round(top)) {
                response = true
              }
            }
          })
        }
      })
      return response
    })
  }
  getStatusElementRedaction = (status) => {
      // this.setState({
      //   statusElement: status
      // })
    return status
  }
  addSearchedRectangle = (el, parentWr, color= false, elRect, unCheckedKeys = [], indexEl, arrayQuery, uniqueElementKeyList) => {
    // while(this.isRunning) {
    //   await new Promise(r => setTimeout(r, 100))
    // }
    // this.isRunning = true
    const { canvasCleaned, alreadyRedactedList, redactingMode, totalPages } = this.state
      this.setState({
        checkStartRedaction: true
      })
    this.onCallToDisableScroll()
    if (el) {
      const temp = el.className.split(' ').slice(-1)
      if (temp.length) {
        const elementKey = temp[0]
        const elementsPage = Number(el.getAttribute('data-page-nr'))
        const elementsText = el.innerText
        if (elementsPage === this.state.pageNumber) {
          //if (checkStartRedaction !== false) {
          //   this.setState({
          //     checkStartRedaction: false
          //   })
          //}
        }
        if (elementsPage !== this.state.pageNumber) {
          //if (checkStartRedaction !== false) {
          //   this.setState({
          //     checkStartRedaction: true
          //   })
          //}
          this.onSearchChangePage(el, indexEl, arrayQuery, uniqueElementKeyList).then((response) => {
            // this.setState({
            //   checkStartRedaction: false
            // })
            // if(unCheckedKeys.length > 0) {
            //   let elementRemoved = false
            //   const history = this._sketch._fc._objects
            //   for (let i = 0; i < history.length; i++) {
            //     if(unCheckedKeys.includes(history[i].annotation)) {
            //       this._sketch._fc.remove(history[i])
            //       elementRemoved = true
            //     }
            //   }
            //   if(elementRemoved) {
            //     this._sketch._fc.renderAll()
            //   }
            // }

            const parentWr = document.querySelector('div.canvas-page-'+this.state.pageNumber)
            const selectionPosition = elRect //el.getBoundingClientRect()
            const left = selectionPosition.left - parentWr.getBoundingClientRect().left + window.scrollX
            const top = selectionPosition.top - parentWr.getBoundingClientRect().top - 3

            if (!canvasCleaned) {
              if (!this.duplicateInCanvas(el, left, top)) {
                // this.setState({
                //   checkStartRedaction: false
                // })
                const rect = new fabric.Rect({
                  left: left,
                  top: top,
                  width: selectionPosition.width,
                  height: selectionPosition.height + 3,
                  fill: color ? color : (redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'),
                  angle: 0,
                  padding: 0,
                  selectable: true,
                  text: elementsText ? elementsText : '',
                  annotation: elementKey
                })
                this._sketch._fc.add(rect)
                this._sketch._fc.renderAll()
              }
            } else if (!alreadyRedactedList.includes(elementKey)) {
              alreadyRedactedList.push(elementKey)
              const rect = new fabric.Rect({
                left: left,
                top: top,
                width: selectionPosition.width,
                height: selectionPosition.height + 3,
                fill: color ? color : (redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'),
                angle: 0,
                padding: 0,
                selectable: true,
                text: elementsText ? elementsText : '',
                annotation: elementKey
              })
              this._sketch._fc.add(rect)
              this._sketch._fc.renderAll()
            }
            this.toggleLoadingProcess(false)
            // if(typeof spanCoords[elementKey] === 'undefined') {
            //   spanCoords[elementKey] = elRect
            //   this.setState({ spanCoords })
            // }
          })
        } else if ((indexEl && arrayQuery && arrayQuery.length && indexEl === arrayQuery.length - 1) || (indexEl === 0 &&  arrayQuery.length === 1)) {
          this.onSearchChangePage(el, indexEl, arrayQuery, uniqueElementKeyList).then((response) => {
            const parentWr = document.querySelector('div.canvas-page-'+this.state.pageNumber)
            const selectionPosition = elRect //el.getBoundingClientRect()
            const left = selectionPosition.left - parentWr.getBoundingClientRect().left + window.scrollX
            const top = selectionPosition.top - parentWr.getBoundingClientRect().top - 3

            if (!canvasCleaned) {
              if (!this.duplicateInCanvas(el, left, top)) {
                const rect = new fabric.Rect({
                  left: left,
                  top: top,
                  width: selectionPosition.width,
                  height: selectionPosition.height + 3,
                  fill: color ? color : (redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'),
                  angle: 0,
                  padding: 0,
                  selectable: true,
                  text: elementsText ? elementsText : '',
                  annotation: elementKey
                })
                this._sketch._fc.add(rect)
                this._sketch._fc.renderAll()
              }
            } else if (!alreadyRedactedList.includes(elementKey)) {
              alreadyRedactedList.push(elementKey)
              const rect = new fabric.Rect({
                left: left,
                top: top,
                width: selectionPosition.width,
                height: selectionPosition.height + 3,
                fill: color ? color : (redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'),
                angle: 0,
                padding: 0,
                selectable: true,
                text: elementsText ? elementsText : '',
                annotation: elementKey
              })
              this._sketch._fc.add(rect)
              this._sketch._fc.renderAll()
            }
            this.toggleLoadingProcess(false)
          })
        } else {
          // const points = this.getRectanglesForPreviewPage()
          // this.updateRectangles(points)

          // if(unCheckedKeys.length > 0) {
          //   const history = this._sketch._fc._objects
          //   let elementRemoved = false
          //   for (let i = 0; i < history.length; i++) {
          //     if(unCheckedKeys.includes(history[i].annotation)) {
          //       this._sketch._fc.remove(history[i])
          //       elementRemoved = true
          //     }
          //   }
          //   if(elementRemoved) {
          //     this._sketch._fc.renderAll()
          //   }
          // }
          const parentWr = document.querySelector('div.canvas-page-'+this.state.pageNumber)
          const selectionPosition = elRect //el.getBoundingClientRect()
          const left = selectionPosition.left - parentWr.getBoundingClientRect().left + window.scrollX
          const top = selectionPosition.top - parentWr.getBoundingClientRect().top - 3
          if (!canvasCleaned) {
            if (!this.duplicateInCanvas(el, left, top)) {
              const rect = new fabric.Rect({
                left: left,
                top: top,
                width: selectionPosition.width,
                height: selectionPosition.height + 3,
                fill: color ? color : (redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'),
                angle: 0,
                padding: 0,
                selectable: true,
                text: elementsText ? elementsText : '',
                annotation: elementKey
              })
              this._sketch._fc.add(rect)
              this._sketch._fc.renderAll()
              if (elementsPage !== this.state.totalPages && (this.getStatusElementRedaction() === 'elementStandard' || this.getStatusElementRedaction() === 'elementDict' || this.getStatusElementRedaction() === 'elementRegEx' ||
                  this.state.sourceActive === 'standard' || this.state.sourceActive === 'regex' || this.state.sourceActive === 'dictionaries')) {
                setTimeout(() => {
                  this.setState({
                    checkStartRedaction: false
                  })
                  this.onRemoveEventDisableScroll()
                }, 5000)
              }
            }
          } else if (!alreadyRedactedList.includes(elementKey)) {
            alreadyRedactedList.push(elementKey)
            const rect = new fabric.Rect({
              left: left,
              top: top,
              width: selectionPosition.width,
              height: selectionPosition.height + 3,
              fill: color ? color : (redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'),
              angle: 0,
              padding: 0,
              selectable: true,
              text: elementsText ? elementsText : '',
              annotation: elementKey
            })
            this._sketch._fc.add(rect)
            this._sketch._fc.renderAll()
          }
          if (elementsPage === totalPages) {
            this.toggleLoadingProcess(false)
            // this.setState({
            //   checkStartRedaction: false
            // })
            // this.onRemoveEventDisableScroll()
            setTimeout(() => {
              if (elementsPage === totalPages) {
                this.setState({
                  checkStartRedaction: false
                })
                this.onRemoveEventDisableScroll()
              }
            }, 5000)
          }
          // if(typeof spanCoords[elementKey] === 'undefined') {
          //   spanCoords[elementKey] = elRect
          //   this.setState({ spanCoords })
          // }
        }
      }
    }
    // this.isRunning = false
    return 1
  }

  /**
   * This method is for searching functionality, it removes all classes related to previous search
   * */
  async clearPreviousSearchedData() {
    this.setState({
      matchedText: [],
      searchIndex: 0,
      searchPage: 1,
      currentMarkedIndex: 1,
      //treeDataSearchHistory: [],
      valueSelectNavigation: 'ai-results',
      searchValue: ''
    }, () => {
      // const el = document.querySelector('span#matched-word--current')
      // if (el) {
      //   el.removeAttribute('id')
      //   const text = el.textContent
      //   el.parentNode.replaceChild(document.createTextNode(text), el)
      // }
      // const elements = document.querySelectorAll('span.matched-word')
      // if (elements) {
      //   for (let i = 0; i < elements.length; i++) {
      //     if (elements[i].classList) {
      //       elements[i].classList.remove('matched-word')
      //       const text = elements[i].textContent
      //       elements[i].parentNode.replaceChild(document.createTextNode(text), elements[i])
      //     }
      //   }
      // }
      // return
    })
    this.changeNavigation('find-asset')
  }

  changeNavigation = (type) => {
    let { pageSidebarVisible, pageSidebarVisibleBefore, navigationType, annotationListVisible } = this.state
    if (type === 'redacting') {
      this.changePage(this.state.pageNumber, '', 'pageSidebar')
      this.setState({
        renderCommentsText: false
      })
    }
    if (type === 'comments') {
      this.changePage(this.state.pageNumber, '', 'pageSidebar')
    }
    if (type === 'pages') {
      pageSidebarVisible = !this.state.pageSidebarVisible
      pageSidebarVisibleBefore = pageSidebarVisible
      if (pageSidebarVisible) {
        annotationListVisible = false
      }
      this.setState({
        renderCommentsText: false
      })
    } else {
      navigationType= type
    }
    this.setState({
      navigationType: navigationType,
      pageSidebarVisible,
      pageSidebarVisibleBefore,
      annotationListVisible
    }, () => {
      if (type === 'pages') {
        const canvas = this._sketch._fc
        canvas._objects.forEach((rectangle) => {
          rectangle.set('stroke', 'rgba(0,0,0,0.41)')
          rectangle.set('strokeWidth', 1)
        })
        canvas.renderAll()
      }
    })
  }

  next() {
    this.slider.slickNext()
  }

  previous() {
    this.slider.slickPrev()
  }

  setCanvasButtonsClasses = () => {
    const { selectedTool, btnClasses, selectionMode } = this.state
    if (selectionMode === false) {
      btnClasses.draw.disabled = true
      btnClasses.select.disabled = true
      btnClasses.draw.class = btnClasses.draw.class.replace(new RegExp('btn-action--border', 'g'), '')
      btnClasses.select.class = btnClasses.select.class.replace(new RegExp('btn-action--border', 'g'), '')
      if (btnClasses.draw.class.indexOf('disabled') === -1) {
        btnClasses.draw.class += ' disabled'
      }
      if (btnClasses.select.class.indexOf('disabled') === -1) {
        btnClasses.select.class += ' disabled'
      }
    } else {
      btnClasses.draw.disabled = false
      btnClasses.select.disabled = false
      btnClasses.draw.class = btnClasses.draw.class.replace(new RegExp('disabled', 'g'), '')
      btnClasses.select.class = btnClasses.select.class.replace(new RegExp('disabled', 'g'), '')
      if (selectedTool === 'draw') {
        if (btnClasses.draw.class.indexOf('btn-action--border') === -1) {
          btnClasses.draw.class += ' btn-action--border'
        }
        btnClasses.select.class = btnClasses.select.class.replace(new RegExp('btn-action--border', 'g'), '')
      }
      if (selectedTool === 'select') {
        if (btnClasses.select.class.indexOf('btn-action--border') === -1) {
          btnClasses.select.class += ' btn-action--border'
        }
        btnClasses.draw.class = btnClasses.draw.class.replace(new RegExp('btn-action--border', 'g'), '')
      }
    }
    if (this._sketch) {
      const nrOfCanvasObjects = this.state.canvasData[this.state.pageNumber] ? this.state.canvasData[this.state.pageNumber].length : 0
      if (nrOfCanvasObjects === 0 && this._sketch._fc._objects.length === 0) {
        btnClasses.undo.disabled = true
        btnClasses.clear.disabled = true
        btnClasses.preview.disabled = true
        btnClasses.undo.class = btnClasses.undo.class.replace(new RegExp('btn-action--border', 'g'), '')
        btnClasses.clear.class = btnClasses.clear.class.replace(new RegExp('btn-action--border', 'g'), '')
        btnClasses.preview.class = btnClasses.preview.class.replace(new RegExp('btn-action--border', 'g'), '')
        if (btnClasses.undo.class.indexOf('disabled') === -1) {
          btnClasses.undo.class += ' disabled'
        }
        if (btnClasses.clear.class.indexOf('disabled') === -1) {
          btnClasses.clear.class += ' disabled'
        }
        if (btnClasses.preview.class.indexOf('disabled') === -1) {
          btnClasses.preview.class += ' disabled'
        }
      } else {
        btnClasses.undo.disabled = this._sketch._fc._objects.length === 0
        btnClasses.clear.disabled = false
        btnClasses.preview.disabled = false
        btnClasses.undo.class = this._sketch._fc._objects.length > 0
            ? btnClasses.undo.class.replace(new RegExp('disabled', 'g'), '')
            : btnClasses.undo.class.replace(new RegExp('btn-action--border', 'g'), '')
        btnClasses.clear.class = btnClasses.clear.class.replace(new RegExp('disabled', 'g'), '')
        btnClasses.preview.class = btnClasses.preview.class.replace(new RegExp('disabled', 'g'), '')
      }
    }
    return btnClasses
  }

  onScanAnnotateClick(value, index, color) {
    this.setState({
      searchValue: value,
      renderSearch: true,
      displaySearchButtons: true,
      activeAnnotation: index,
      disableClickOnPage: false
    })

    const allElements = document.querySelectorAll('.annotation-page__element')
    allElements.forEach((el) => {
      el.classList.remove('annotation-page__element--active')
      el.style.color = 'inherit'
    })
    index.classList.add('annotation-page__element--active')
    index.style.color = color
    this.searchTextInDoc(value, color)
  }

  setQueryStatus = (docAnnotationCategories) => {
    this.setState({ docAnnotationCategories })
  }

  scrollToMatchedWord() {
    const elementToScrollTO = document.querySelector('#matched-word--current')
    if (elementToScrollTO) {
      elementToScrollTO.scrollIntoView(false)
    }
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.addAnnotation()
      this.hideCanvasModals()
    }
  }

  showAnnotationsList = () => {
    const { pageSidebarVisible } = this.state
    const pageSidebarVisibleBefore = pageSidebarVisible
    this.setState({
      annotationListVisible: true,
      pageSidebarVisible: false,
      pageSidebarVisibleBefore
    })
  }

  hideAnnotationsList = () => {
    let { pageSidebarVisibleBefore, pageSidebarVisible } = this.state
    if (pageSidebarVisibleBefore) {
      pageSidebarVisible = true
    }
    this.setState({
      annotationListVisible: false,
      pageSidebarVisible
    })
  }

  highlightRectangle = (convertedAnnotation, annotation, page) => {
    this.setState({
      btnAnnotationActiveIndex: annotation
    }, () => this.changePageRedacted(Number(page), convertedAnnotation))
  }

  renderSearch = () => {
    this.setState({
      renderSearch: !this.state.renderSearch,
      displaySearchButtons: !this.state.displaySearchButtons
    }, () => {
      if (!this.state.renderSearch) {
        this.setState({
          disableClickOnPage: true
        })
      } else {
        this.setState({
          disableClickOnPage: false
        })
      }
    })
  }

  async closeSearch() {
    if (this.state.matchedText.length > 0) {
      await this.clearPreviousSearchedData()
    }
    this.setState({
      renderSearch: false,
      displaySearchButtons: false,
      searchValue: ''
    }, () => {
      // this.setState({
      //   pdfBytes: this.props.pdfBytes,
      // })
    })
  }

  onSearchChangeValue = (e) => {
    // if (e.target.value) {
      this.setState({
        searchValue: e.target.value
      })
    //}
  }

  annotationRedactionView() {
    this.setState({
      viewAnnotationsRedaction: !this.state.viewAnnotationsRedaction
    }, () => {
      if (!this.state.viewAnnotationsRedaction) {
        this.setState({
          disableClickOnPage: true
        })
      } else {
        this.setState({
          disableClickOnPage: false
        })
      }
    })
  }

  addAnnotationsRedaction = () => {
    this.annotationRedactionView()
  }

  toggleLoadingProcess = (open, source= 'finalise') => {
    let { loadingProcessMessage } = this.state
    if (open) {
      if (source === 'redact') {
        loadingProcessMessage = 'Please wait until redacted process is finished.'
      } else {
        loadingProcessMessage = 'Please wait until finalise process is finished.'
      }
    }
    this.setState({ loadingProcess: open, loadingProcessMessage })
  }
  disabledMethodRedacting(value) {
     return true
  }
  disabledMethodSearch(value) {
    return true
  }
  disabledAnswerToogle(value) {
    return true
  }
  chooseMethodRedacting(value) {
    const allPagesCanvas = document.getElementsByClassName('canvas-area')
    for (const page of allPagesCanvas) {
      if (page) {
        switch (value) {
          case 'search':
            //this.renderSearch(value)
            this.setState({
              renderSelectDraw: false,
              renderSelectText: false,
              renderCommentsText: false,
              viewAnnotationsRedaction: false,
              renderSelectTool: false,
              tool: Tools.Select,
              selectedTool: 'select',
              renderSearch: !this.state.renderSearch,
              displaySearchButtons: !this.state.displaySearchButtons
            })
            if (!this.state.renderSearch) {
              this.setState({
                disableClickOnPage: false
              })
            } else {
              this.setState({
                disableClickOnPage: true
              })
            }
            break
          case 'draw':
            this.selectRectangle()
            // this.setState({
            //   renderSearch: false,
            //   renderSelectText: false,
            //   viewAnnotationsRedaction: false,
            //   renderSelectTool: false,
            //   // renderSelectDraw: !this.state.renderSelectDraw,
            //   renderSelectDraw: false,
            //   displaySearchButtons: false
            // }, () => { this.selectRectangle() })
            page.setAttribute('style', 'z-index: 1;')
            break
          case 'selectText':
            this.selectText()
            this.setState({
              // renderSelectDraw: false,
              // renderSelectText: false,
              // renderSearch: false,
              // renderSelectTool: false,
              // viewAnnotationsRedaction: false,
              // displaySearchButtons: false
              stateSidebarPages: true
            }, () => { })
            break
          case 'comments':
            this.selectComments()
            this.setState({
              stateSidebarPages: true
            }, () => { })
            break
          case 'annotation':
            //this.addAnnotationsRedaction()
            this.setState({
              renderSelectDraw: false,
              renderSearch: false,
              renderSelectText: false,
              renderCommentsText: false,
              renderSelectTool: false,
              tool: Tools.Select,
              selectedTool: 'select',
              displaySearchButtons: false,
              viewAnnotationsRedaction: !this.state.viewAnnotationsRedaction
            })
            if (!this.state.viewAnnotationsRedaction) {
              this.setState({
                disableClickOnPage: false
              })
            } else {
              this.setState({
                disableClickOnPage: true
              })
            }
            page.setAttribute('style', 'z-index: 1;')
            break
          case 'selectTool':
            //this.selectTool()
            this.setState({
              renderSelectDraw: false,
              renderSearch: false,
              renderSelectText: false,
              renderCommentsText: false,
              displaySearchButtons: false,
              renderSelectTool: !this.state.renderSelectTool,
              renderSelectToolMove: true
            })
            const allPagesCanvas = document.getElementsByClassName('canvas-area')
            if (!this.state.renderSelectTool) {
              this.setState({
                disableClickOnPage: false,
                removeSelectedBtnDisabled: false,
                tool: Tools.Select,
                selectedTool: 'select',
              }, () => {
                for (const page of allPagesCanvas) {
                  if (page) {
                    page.setAttribute('style', 'z-index: 1;')
                  }
                }
              })
            } else {
              this.setState({
                tool: Tools.Select,
                selectedTool: 'select',
                disableClickOnPage: true,
                removeSelectedBtnDisabled: true
              }, () => {
                for (const page of allPagesCanvas) {
                  if (page) {
                    page.setAttribute('style', 'z-index: 1;')
                  }
                }
              })
            }
            break
          default:
            return false
        }
      }
    }
  }

  inSelectOption = (children) => {
    const { annotationText } = this.state
    let response = false
    if (children && children.length) {
      children.forEach((item) => {
        if (item.props) {
          if (item.props.children && item.props.children) {
            item.props.children.forEach((value) => {
              if (value.key === annotationText) {
                response = true
              }
            })
          }
        }
      })
    }
    return response
  }
  updateRenderAnnotationsList = (children) => {
    const { annotationText, viewAnnotationsRedaction, annotationListVisible } = this.state
    if (viewAnnotationsRedaction) {
      return (
          <div className="annotations">
            { annotationListVisible &&
            <Drawer
                getContainer={ this.state.landscape ? 'div.redacted-page-landscape' : 'div.redacted-page-portrait' }
                placement="left"
                closable={ false }
                onClose={ this.hideAnnotationsList }
                destroyOnClose={ true }
                visible={ annotationListVisible }
                mask={ false }
                maskClosable={ true }
                className="update-annotation-sidebar"
                maskStyle={ { 'opacity': 0 } }
            >
             <div className="content-annotation-sidebar">
               <div className="header-annotation-sidebar">
                 <Select
                     placeholder="Annotate selected"
                     className='custom-status-annotate'
                     defaultValue="annotate-selected"
                     dropdownClassName="new-dropdown-status-annotate"
                 >
                   <Option className="options-annotate" key={1} value="annotate-selected">
                     Annotate selected
                   </Option>
                   <Option className="options-annotate" key={2} value="annotate-all-redacted">
                     Annotate all redacted
                   </Option>
                 </Select>
                 <span onClick={this.hideAnnotationsList} className="icon-v17-close-black"></span>
               </div>
               <div className="body-annotation-sidebar">
                 <Search type={ 'text' } className="annotation-sidebar-search"
                        value={ annotationText }
                        onChange={ (e) => this.changeAnnotationText(e, 'new') }
                        onBlur={ () => this.addAnnotation() }
                         placeholder="Annotation"
                        onKeyDown={ (e) => this.handleKeyDown(e) }
                 />
                 <span className="spacing-select">or</span>
                 <Select
                     value = { this.inSelectOption(children) ? annotationText : 'Custom list' }
                     mode="single"
                     showSearch={ true }
                     style={ { width: '100%' } }
                     autoFocus={ false }
                     autoClearSearchValue={ false }
                     notFoundContent=''
                     className="select-custom-list"
                     placeholder="Custom list"
                     defaultValue="Custom list"
                     onSelect={ (e) => this.annotationHandleChange(e, 'add', 'select') }
                     dropdownClassName="dropdown-custom-list"
                 >
                   { children }
                 </Select>
                 <Button onClick={ this.onClickAnnotateUpdate } disabled={ annotationText === '' }
                         //onClick={ () => this.cavnasModalRemove('annotation') }
                 >
                   Annotate
                 </Button>
               </div>
             </div>
            </Drawer>
            }
          </div>
      )
    }
  }
  onClickAnnotateUpdate = () => {
    const { annotationText } = this.state
    this.setState({
      statusAnnotationSidebar: 'annotationSidebar'
    })
    this.updateCanvasText([], annotationText, 'rgba(107, 91, 149, 1)', 'rgba(254, 178, 54, 1)')
  }
  renderAnnotationsList = (children) => {
    const { annotationText, objectHasAnnotation, viewAnnotationsRedaction, annotationListVisible } = this.state
    if (viewAnnotationsRedaction) {
      return (
        <div className="annotations">
          { annotationListVisible &&
          <Drawer
            getContainer={ this.state.landscape ? 'div.redacted-page-landscape' : 'div.redacted-page-portrait' }
            placement="right"
            closable={ true }
            onClose={ this.hideAnnotationsList }
            destroyOnClose={ true }
            visible={ annotationListVisible }
            mask={ false }
            maskClosable={ true }
            className="annotation-sidebar"
            maskStyle={ { 'opacity': 0 } }
          >
            <p>Add annotation text:</p>
            <input type={ 'text' } className="annotation-sidebar__text"
              value={ annotationText }
              onChange={ (e) => this.changeAnnotationText(e, 'new') }
              onBlur={ () => this.addAnnotation() }
              onKeyDown={ (e) => this.handleKeyDown(e) }
            />
            <p>Add annotation from list </p>
            <Select
              value = { this.inSelectOption(children) ? annotationText : '' }
              mode="single"
              showSearch={ true }
              style={ { width: '100%' } }
              autoFocus={ false }
              autoClearSearchValue={ false }
              notFoundContent=''
              onSelect={ (e) => this.annotationHandleChange(e, 'add', 'select') }
            >
              { children }
            </Select>
            { objectHasAnnotation &&
              <div className="delete_annotation_wrapper text-center">
                <button className="button-small-red-empty delete_annotation-extra" onClick={ () => this.cavnasModalRemove('annotation') }>
                  Delete Annotation
                </button>
              </div>
            }
          </Drawer>
          }
        </div>
      )
    }
  }

  generateNavigationScroll = () => {
    if (this.state.navigationType === 'redacting' || this.state.navigationType === 'find-asset') {
      return 'auto'
    }
    return 'unset'
  }

  generateGridColumnProperties = () => {
    if (this.state.navigationType === 'redacting' || this.state.navigationType === 'find-asset' || this.state.navigationType === 'metadata' || this.state.navigationType === 'image' || this.state.navigationType === 'spell_checker' || this.state.navigationType === 'search-history') {
      //return '3% 18% 2% 64% 2% 0%'
      //return '0% 18% 5% 68% 2% 0%'
      return '20% 73% 7%'
    } else if (this.state.navigationType === 'pages') {
      //return '0% 0% 5% 72% 2% 10%'
      return '20% 73% 7%'
    }
    //return '3% 10% 2% 72% 2% 0%'
    //return '0% 10% 5% 76% 2% 0%'
    return '20% 73% 7%'

  }

  openDocumentNote = () => {
    this.setState({
      documentNoteVisible: true
    })
  }

  closeDocumentNote = () => {
    this.setState({
      documentNoteVisible: false
    })
  }
  addNotifyDocumentNote = (note) => {
     this.setState({
       notifyNote: note
     })
  }

  generateSVGColor = (navigationType, target) => {
    if (navigationType === target) {
      return '#00D3D8'
    }
    return '#6e7882'
  }

  generateHtmlForAnnotationPreview(canvasData) {
    const result = []
    let i = 1
    Object.keys(canvasData).map((page) => {
      const pageHtml = []
      if (canvasData[page].length) {
        pageHtml.push(
          <RedactedSidebar
            canvasData= { canvasData }
            index= { i }
            key= { i }
            setPage= { this.setPage }
            highlightRectangle={ this.highlightRectangle }
            savePointsOnPage={ this.savePointsOnPage }
            page = { page }
            loadPoints = { this.loadPoints }
            editLabel = { this.editLabel }
            annotationSelectedItem = { this.state.annotationSelectedItem }
            setAnnotationSelectedItem = { this.setAnnotationSelectedItem }
            endPointXDraw={ this.state.endPointXDraw }
            endPointYDraw={ this.state.endPointYDraw }
            documentId={ this.props.documentId }
          />
        )
        result.push(pageHtml)
        i++
      }
      return true
    })

    return result
  }

  generateCommentsPreview(canvasDataComments) {
    const result = []
    let i = 1
    Object.keys(canvasDataComments).map((page) => {
      const pageHtml = []
      if (canvasDataComments[page].length) {
        pageHtml.push(
            <CommentsSidebar
                canvasDataComments= { canvasDataComments }
                canvasData= { this.state.canvasData }
                index= { i }
                key= { i }
                setPage= { this.setPage }
                highlightRectangle={ this.highlightRectangle }
                savePointsOnPage={ this.savePointsOnPage }
                page = { page }
                loadPoints = { this.loadPoints }
                editLabel = { this.editLabel }
                annotationSelectedItem = { this.state.annotationSelectedItem }
                setAnnotationSelectedItem = { this.setAnnotationSelectedItem }
                endPointXDraw={ this.state.endPointXDraw }
                endPointYDraw={ this.state.endPointYDraw }
                documentId={ this.props.documentId }
            />
        )
        result.push(pageHtml)
        i++
      }
      return true
    })

    return result
  }

  collapseAnnotateSelectModal = (e, action= true) => {
    e.stopPropagation()
    this.setState({ annotateSelectedModalVisible: action })
  }

  renderAnnotateSelected = () => {
    const { numberOfAnnotateSelected, colorSelected, annotationList, annotateLabel } = this.state
    let { annotateSelectBtnDisabled } = this.state
    const { Option } = Select
    if (numberOfAnnotateSelected && annotateLabel) {
      annotateSelectBtnDisabled = false
    }
    return (
      <div className="annotate" tabIndex="-1" onKeyDown={ (e) => this.pressEscapeKey(e) }>
        <div className="annotate__header">
          <span className="annotate__header__title">ANNOTTE SELECTED REDACTION</span>
          <span className="annotate__header__close" onClick={ (e) => this.collapseAnnotateSelectModal(e, false) }>X</span>
        </div>
        <div className="annotate__body">
          <div className="annotate__body__line">
            <div className="input-space" onChange={ (e) => this.changeAnnotateLabel(e) }>
              <input type="text"/>
            </div>
            <div className="button-space">
              <div className="button-space__circle" style={{ backgroundColor: colorSelected.input }}/>
              <div className="icon-colourpalette-b" onClick={ () => this.toggleSimpleCustomColorPicker('input')}/>
            </div>
          </div>
          <div className="annotate__body__line">
            <div className="select-space">
              <Select className="select-space__select" defaultValue={annotationList[0].exemptionCode} onChange={ (e) => this.changeAnnotationSelected() }>
                {
                  annotationList.map((item) => {
                    return (<Option key={item.key} value={item.exemptionCode}>{item.exemptionCode}</Option>)
                  })
                }
              </Select>
            </div>
            <div className="button-space">
              <div className="button-space__circle" style={{ backgroundColor: colorSelected.select }}/>
              <div className="icon-colourpalette-b" onClick={ () => this.toggleSimpleCustomColorPicker('select') }/>
            </div>
          </div>
        </div>
        <div className="annotate__footer">
          <button className="finalize-button button-green annotate__footer__btn" disabled={annotateSelectBtnDisabled}>ANNOTATE</button>
          <p className="annotate__footer__number">{ numberOfAnnotateSelected } names selected</p>
        </div>
        { this.renderSimpleCustomColorPicker() }
      </div>
    )
  }

  hideLoadingBeforeMark = () => {
    this.setState({ loadingBeforeMark: false })
  }
  onDropdownVisibleChange = (e) => {
    this.setState({
      visibleDropdownStatus: e
    })
  }
  updateValueStatus(e) {
    const { smartBoxDocumentPath, isSmartBoxApplication } = this.props
    const { customerStatus } = this.state
    let iconSelect = null
    let colorSelect = null
    const statusSmartView = e
    let tagSelect = {}
    const fileStatusValue = statusSmartView.toLowerCase() === 'for review' ? 'for review' : statusSmartView.toLowerCase()
    customerStatus && customerStatus.length > 0 && customerStatus.forEach((list) => {
      if (fileStatusValue.toLowerCase() === list.tagName.toLowerCase()) {
        iconSelect = list.icon
        colorSelect = list.color
        tagSelect = list
      }
    })
    this.setState({
      selectTag: tagSelect
    })
    const listIds = []
    listIds.push(this.props.documentId)
    const ToDoStatusSmartView = {
      fileStatus: fileStatusValue.toLowerCase(),
      //filePath: smartBoxDocumentPath,
      fileId: listIds,
      color: colorSelect,
      icon: iconSelect,
    }
    if (isSmartBoxApplication) {
      setFileStatusOpenSmartView(ToDoStatusSmartView).then((response) => {
        if (response.status === 200) {
          this.props.onGetStatusTagsSmartView(tagSelect, listIds)
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
            console.log(error)
        }
      })
    }
    this.setState({
      valueStatusFile: e
    })
  }

  updateValueClassification(e) {
    const { documentId, isSmartBoxApplication } = this.props
    this.setState({
      valueStatusClassification: e
    })
    const ToDoStatusSmartView = {
      docId: documentId,
      docClassification: e
    }
    if (isSmartBoxApplication) {
      setClassificationStatusOpenSmartView(ToDoStatusSmartView).then((response) => {

      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
            console.log(error)
        }
      })
    }
  }
  onCheckStatusSelectAgainRedacted = (status) => {
    this.setState({
      statusSelectAgainRedacted: status
    })
  }
  onMouseEnter(e, type) {
    switch (type) {
      case 'document-note':
        this.setState({
          activeDocumentNote: true,
          activePrintDocument: false
        })
        break
      case 'print-document':
        this.setState({
          activePrintDocument: true,
          activeDocumentNote: false
        })
        break
      case 'clear-page':
        this.setState({
          activeClearPage: true
        })
          break
      case 'clear-selected':
        this.setState({
          activeClearSelected: true
        })
        break
      case 'search-word':
        this.setState({
          activeSearchWord: true
        })
        break
      case 'annotation':
        this.setState({
          activeAnnotation: true
        })
        break
      case 'comments':
        this.setState({
          activeComments: true
        })
        break
      case 'select-tool':
        this.setState({
          activeSelectTool: true
        })
        break
      case 'select-draw':
        this.setState({
          activeSelectDraw: true,
          statusDrawRedaction: true
        })
        break
      case 'select-text':
        this.setState({
          activeSelectText: true
        })
        break
      case 'select-undo':
        this.setState({
          activeUndo: true,
        })
        break
      case 'select-redo':
        this.setState({
          activeRedo: true,
        })
        break
      case 'preview':
        this.setState({
          activePreview: true,
        })
          break
      default: break
    }
  }
  onMouseLeave() {
    this.setState({
      activeDocumentNote: false,
      activePrintDocument: false,
      activeClearPage: false,
      activeClearSelected: false,
      activeSearchWord: false,
      activeAnnotation: false,
      activeComments: false,
      activeSelectTool: false,
      activeSelectDraw: false,
      activeSelectText: false,
      activeUndo: false,
      activeRedo: false,
      activePreview: false,
    })
  }
  onVisibleDropdownNavigation = (e) => {
     this.setState({
       visibleDropdownNavigation: e
     })
  }
  changeNavigationBar(e) {
    switch (e) {
      case 'ai-results':
        this.changeNavigation('find-asset')
        break
      case 'search-history':
        this.changeNavigation('search-history')
          this.getSearchHistoryList()
        break
      // case 'images':
      //   this.changeNavigation('image')
      //       break
      // case 'spell-checker':
      //   this.changeNavigation('spell_checker')
      //   break
      case 'review-by-page':
        this.changeNavigation('redacting')
        break
      case 'comments':
        this.changeNavigation('comments')
        break
      case 'metadata':
        this.changeNavigation('metadata')
        break
      default: break
    }
    this.setState({
      valueSelectNavigation: e
    })
  }
  saveNextPreviousPage = () => {
    const points = this.getRectanglesForPreviewPage()
    this.updateRectangles(points)
    const initialCanvasData = this.state.canvasData
    initialCanvasData[this.state.pageNumber] = points
    this.setState({
      haveChanges: true,
      canvasData: initialCanvasData
    })
  }
  onClickForthDocument = () => {
     const { filesTable, documentId, systemPropertyFileStatus } = this.props
    const { disabledNextDocument, setIntervalUpdateLockStatus } = this.state
    cookie.remove('checkLockStatusSmartviewDocument', { path: '/' })
    clearInterval(setIntervalUpdateLockStatus)
    this.setState({setIntervalUpdateLockStatus})
    //this.props.closeRedacting(this.props.pdfBytes)
    //window.URL.revokeObjectURL(this.props.pdfBytes)

    // this.setState({
    //   documentBytes: null
    // }, () => {
    //   window.URL.revokeObjectURL(this.props.pdfBytes)
    // })


    //window.location.reload()

    ///TEST ---- 1111
    // const loadingTask = pdfjs.getDocument('/libs/pdfjs/build/pdf.worker.js')
    // loadingTask.destroy();
    //window.worker

    ///TEST ---- 22222
    //localStorage.clear()

    ///TEST ---- 3333333
    // window.URL.revokeObjectURL(this.props.pdfBytes)
    // delete window.worker
    // const myWorker = new Worker("worker.js");
    //
    // myWorker.terminate();
    //this.destroyed = true;

    ///TEST ---- 4444444
    // const transportDestroyed = !this._transport
    //     ? Promise.resolve()
    //     : this._transport.destroy();
    // return transportDestroyed.then(() => {
    //   this._transport = null;
    //   if (this._worker) {
    //     this._worker.destroy();
    //     this._worker = null;
    //   }
    // });

    // const worker = new Worker('worker.js');
    let nextDocument
    const filesTablePdf = []
    global.finalItems = {}
    global.items = {}
    global.markedTimes = 0
    global.itemsToBeMarked = 0
    global.arEl = []
    global.arOptions = []
    if (disabledNextDocument === false) {
      this.setState({
        disabledNextDocument: true
      }, () => {
        this.props.closeRedactingNext(this.props.pdfBytes)
        this.setState({
          isDocumentReady: false,
          pageNumber: 1,
          isNextDocumentId: {}
        })
        this.saveNextPreviousPage()
        filesTable && filesTable.length > 0 && filesTable.forEach((file) => {
          if (file.type !== 'DIRECTORY') {
            filesTablePdf.push(file)
          }

        })
        for (let i = 0; i < filesTablePdf.length; i++) {
          if (filesTablePdf[i].id === documentId) {
            while((i+1 < filesTablePdf.length) && filesTablePdf[i+1].errorMessage != "No error"){
              i++;
            }

            nextDocument = i + 1;
          }
        }
        if ((nextDocument) === filesTablePdf.length) {
          // this.setState({
          //   disabledNextDocument: true
          // })
          nextDocument--;
        } else {
          this.props.handlePreviewDocumentNext(filesTablePdf[nextDocument])
          //update 22Septembrie
          //this.fetchSmartBoxAllData(filesTablePdf[nextDocument].id, this.state.clientId)
          this.setState({
            //disabledNextDocument: false
          })
        }
        this.setState({
          isNextDocument: true,
          isNextDocumentId: filesTablePdf[nextDocument],
          nextDocumentLength: nextDocument,
          documentId: filesTablePdf[nextDocument].id,
          renderSelectDraw: false,
          renderSearch: false,
          renderSelectText: false,
          renderCommentsText: false,
          displaySearchButtons: false,
          tool: Tools.Select,
          selectedTool: 'select',
        })
        this.setState({
          valueStatusFile: this.props.systemPropertyFileStatus === null ? 'File Status' : systemPropertyFileStatus,
          statusNextDocumentBusiness: true,
          statusNextDocumentRegEx: true,
          statusNextDocumentStandard: true,
        })
      })
    }
    this.onUnlockSmartviewDocument()
  }

  onDisabledNextDocument = (status) => {
    this.setState({
      disabledNextDocument: status
    })
  }
  onGetStatusNextDocumentBusiness = (status) => {
    this.setState({
      statusNextDocumentBusiness: status
    })
  }

  onGetStatusNextDocumentRegEx = (status) => {
    this.setState({
      statusNextDocumentRegEx: status
    })
  }

  onGetStatusNextDocumentStandard = (status) => {
    this.setState({
      statusNextDocumentStandard: status
    })
  }
  onDisabledForthDocument = () => {
    return true
  }
  onClickBackDocument = () => {
    const { filesTable, documentId, systemPropertyFileStatus } = this.props
    const { disabledBackDocument, setIntervalUpdateLockStatus } = this.state
    cookie.remove('checkLockStatusSmartviewDocument', { path: '/' })
    clearInterval(setIntervalUpdateLockStatus)
    this.setState({setIntervalUpdateLockStatus})
    let backDocument
    const filesTablePdf = []
    this.setState({
      isDocumentReady: false
    })
    global.finalItems = {}
    global.items = {}
    global.markedTimes = 0
    global.itemsToBeMarked = 0
    global.arEl = []
    global.arOptions = []

    if (disabledBackDocument === false) {
      this.setState({
        disabledBackDocument: true
      }, () => {
        this.props.closeRedactingNext(this.props.pdfBytes)
        this.saveNextPreviousPage()
        filesTable && filesTable.length > 0 && filesTable.forEach((file) => {
          if (file.type !== 'DIRECTORY') {
            filesTablePdf.push(file)
          }
        })
        for (let i = filesTablePdf.length - 1; 0 <= i; i--) {
          if (filesTablePdf[i].id === documentId) {

            while((i-1 >= 0) && filesTablePdf[i-1].errorMessage != "No error"){
              i--;
            }

            backDocument = i - 1
          }
        }
        if (backDocument < 0) {
          backDocument = 0;
          // this.setState({
          //   disabledBackDocument: true
          // })
        } else {
          this.props.handlePreviewDocumentNext(filesTablePdf[backDocument])
          //this.fetchSmartBoxAllData(filesTablePdf[backDocument].id, this.state.clientId)
          // this.setState({
          //   disabledBackDocument: false
          // })
        }
        this.setState({
          isNextDocument: true,
          isNextDocumentId: filesTablePdf[backDocument],
          nextDocumentLength: backDocument,
          documentId: filesTablePdf[backDocument].id,
          renderSelectDraw: false,
          renderSearch: false,
          renderSelectText: false,
          renderCommentsText: false,
          displaySearchButtons: false,
          tool: Tools.Select,
          selectedTool: 'select',
        })
        this.setState({
          valueStatusFile: this.props.systemPropertyFileStatus === null ? 'File Status' : systemPropertyFileStatus,
          statusNextDocumentBusiness: true,
          statusNextDocumentRegEx: true,
          statusNextDocumentStandard: true,
          pageNumber: 1
        })
      })
    }
    this.onUnlockSmartviewDocument()
  }

  onDisabledBackDocument = (status) => {
    this.setState({
      disabledBackDocument: status
    })
  }
  onCallToDisableScroll = () => {
    let supportsPassive = false;
    try {
      window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () { supportsPassive = true; return true }
      }))
    } catch(e) {}

    const wheelOpt = supportsPassive ? { passive: false } : false
    const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel'
    window.addEventListener('DOMMouseScroll', this.preventDefaultKeyDown, false)
    window.addEventListener(wheelEvent, this.preventDefaultKeyDown, wheelOpt)
    window.addEventListener('touchmove', this.preventDefaultKeyDown, wheelOpt)
    window.addEventListener('keydown', this.preventDefaultEventScroll, false)
  }
  onRemoveEventDisableScroll = () => {
    let supportsPassive = false;
    try {
      window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () { supportsPassive = true; return true }
      }))
    } catch(e) {}

    const wheelOpt = supportsPassive ? { passive: false } : false
    const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel'
    window.removeEventListener('DOMMouseScroll', this.preventDefaultKeyDown, false)
    window.removeEventListener(wheelEvent, this.preventDefaultKeyDown, wheelOpt)
    window.removeEventListener('touchmove', this.preventDefaultKeyDown, wheelOpt)
    window.removeEventListener('keydown', this.preventDefaultEventScroll, false)
  }
  preventDefaultEventScroll = (e) => {
    this.preventDefaultKeyDown(e)
    return false
  }
  preventDefaultKeyDown = (e) => {
    e.preventDefault()
  }

  drawRedactAllMatchedWords = (classNameList, classListStandard, classListBusiness, classListRegEx, unCheckedKeys=[], source='redactAll') => {
    const { idDoc, docAnnotationCategories, totalPages, divPrint, statusRedactAll } = this.state
    let classAllNameList = []
    classAllNameList = classListStandard.concat(classListBusiness, classListRegEx)
    this.setState({
      checkStartRedaction: null,
      sourceActive: source,
      statusCloseSmartviewRedactAll: true
    })
    if (classNameList && idDoc) {
      const parameters = {
        idDocument: idDoc,
        orderCategory: docAnnotationCategories
      }
    let spanCoords = []
    for(let i=1; i<=totalPages; i++) {

      const otherPages = document.querySelectorAll('div#' + divPrint + ':not([data-pageid="' + i + '"])')
      otherPages.forEach((page) => {
        page.classList.remove('show-pdf-page')
      })

      const page = document.querySelectorAll('div#' + divPrint + '[data-pageid="' + i + '"]')
      if (page.length > 0) {
        page[0].classList.add('show-pdf-page')
      }

      const spans = document.querySelectorAll('span[data-markjs][data-page-nr="' + i + '"]')
      spans.forEach((el) => {
        const temp = el.className.split(' ').slice(-1)
        const rect = el.getBoundingClientRect()
        spanCoords[temp[0]] = {top: rect.top, left: rect.left, width: rect.width, height: rect.height}
      })
     }
      this.setState({ spanCoords }, () => {
        if (statusRedactAll === "true") {
          this.onRemoveAllQueryStatus()
        } else {
          this.changeAllQueryStatus(parameters, classNameList, unCheckedKeys, source)
        }
      })
    }
  }

  onRemoveAllQueryStatus = (parameters, classNameList, unCheckedKeys, source) => {
    // this.onSearchChangePage(null, 1).then(() => {
    //
    // })
    const elements = document.querySelectorAll(`div#${ this.state.divPrint }`)
    for (let i = 1; i < this.state.totalPages + 1; i++) {
      setTimeout(() => {
        this.changePageRemoveAllRedact(i)

      }, 500)
    }
    this.onSetDocumentRedactAllStatus("false")
    this.onRemoveAllRedaction()
  }
  changePageRemoveAllRedact = (newPageNumber, annt, statusPageSidebar) => {
    this.setState({
      statusClickPageSidebar: statusPageSidebar,
      stateSidebarPages: false
    })
    const elements = document.querySelectorAll(`div#${ this.state.divPrint }`)
    if (elements) {
      for (let i = 0; i < this.state.totalPages; i++) {
        if (elements[i] && Number(elements[i].getAttribute('data-pageid')) === newPageNumber) {
          elements[i].classList.remove('show-pdf-page')
          elements[i].classList.add('show-pdf-page')
        } else if (elements[i]) {
          elements[i].classList.remove('show-pdf-page')
        }
      }
    }

    //add active class to pages thumbnails
    const elementsSmall = document.querySelectorAll('div.previewpdf__container__item')
    if (this.state.navigationType === 'pages') {
      for (let i = 0; i < this.state.totalPages; i++) {
        if (Number(elementsSmall[i].getAttribute('data-pageid')) === newPageNumber) {
          elementsSmall[i].classList.add('previewpdf__container__item--active')
        } else {
          elementsSmall[i].classList.remove('previewpdf__container__item--active')
        }
      }
    }

    //const points = this.getRectanglesForPreviewPage()
    //this.updateRectangles(points, statusPageSidebar)
    const initialCanvasData = this.state.canvasData
    initialCanvasData[this.state.pageNumber] = []
    this.hideCanvasModals()
    this.setState({
      pageNumber: newPageNumber,
      canvasData: initialCanvasData,
      forPageNumber: newPageNumber,
      haveChanges: false,
    }, () => {
      const rectangleOnCurrentPage = this.state.canvasData
      rectangleOnCurrentPage[newPageNumber] = []
      this._sketch.setBackgroundFromDataUrl('')
      this._sketch.clear()
      const alreadyRedactedKeys= {}
      alreadyRedactedKeys.business= []
      alreadyRedactedKeys.manual= []
      alreadyRedactedKeys.regex= []
      alreadyRedactedKeys.standard= []
      this.setState({
        controlledValue: null,
        backgroundColor: 'transparent',
        fillWithBackgroundColor: false,
        canUndo: this._sketch.canUndo(),
        canRedo: this._sketch.canRedo(),
        canvasData: rectangleOnCurrentPage,
        haveChanges: true,
        alreadyRedactedKeys: {},
        newListKeysStandard: [],
        newListKeysManual: [],
        newListKeysRegEx: [],
        newListKeysBusiness: [],
        verificationOnClickClearAll: true,
        verificationOnClickClearAllManual: true,
        verificationOnClickClearAllRegEx: true,
        verificationOnClickClearAllBusiness: true
      })
    })
  }
   changeAllQueryStatus = (parameters, classNameList, unCheckedKeys, source) => {
     this.setState({ canvasCleaned: false }, () => {
       this.setState({
         statusSaveQuery: false
       })
        this.callAllQueryStatus(parameters, classNameList, unCheckedKeys, source, true)
     })
  }

  onSetDocumentRedactAllStatus = (status) => {
    const smartViewRedactAllDTO = {
      document_redact_all_status: status,
      documentId: this.props.documentId
    }
    apiSmartBoxSetDocumentRedactAllStatus(smartViewRedactAllDTO).then((response) => {
       this.setState({
         statusRedactAll: status
       })
    })
  }
  onRemoveAllRedaction = () => {
    const smartViewRedactAllDTO = {
      docId: this.props.documentId
    }
    apiRemoveAllSmartviewRedactions(smartViewRedactAllDTO).then((response) => {
      this.setState({
        statusCloseSmartviewRedactAll: false
      })
    })
  }
  callAllQueryStatus = (parameters, classNameList, unCheckedKeys, source, emptyUncheckedKeys) => {
    const { alreadyRedactedKeys, redactingMode, spanCoords } = this.state
    const pageHeight = document.querySelector('#pdf-page').clientHeight
    const parentWr = document.querySelector('div.canvas-page-1')
    let elements = []
    classNameList.forEach((name, index) => {
      const elementsByClass = document.getElementsByClassName(name)
      Object.keys(elementsByClass).forEach((idx) => {
        elements.push(elementsByClass[idx])
      })
    })
    elements = this.sortElementsByPage(elements)
    elements && elements.length > 0 &&  elements.forEach((el, indexEl, arrayQuery) => {
      const temp = el.className.split(' ').slice(-1)
      if (temp.length) {
        const elementKey = temp[0]
        let elRect = typeof spanCoords[elementKey] !== 'undefined' ? spanCoords[elementKey] : el.getBoundingClientRect()
        const left = elRect.left
        const top = elRect.top > pageHeight ? elRect.top - pageHeight : elRect.top + 1
        const width = elRect.width
        const height = elRect.height
        const elRectObj = {left, top, width, height}

        if (!alreadyRedactedKeys[source]) {
          alreadyRedactedKeys[source] = []
        }
        if (!alreadyRedactedKeys[source].includes(elementKey)) {
          alreadyRedactedKeys[source].push(elementKey)
          const colorForRectangles = redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'
          setTimeout(() => {
            this.addAllSearchedRectangle(el, parentWr, colorForRectangles, elRectObj, unCheckedKeys, indexEl, arrayQuery)
          }, 800)
        }
      }
      //finsih
    })
    this.onSetDocumentRedactAllStatus("true")
  }

  addAllSearchedRectangle = (el, parentWr, color= false, elRect, unCheckedKeys, indexEl, arrayQuery) => {
    const { canvasCleaned, alreadyRedactedList, redactingMode, totalPages } = this.state
    this.setState({
      checkStartRedaction: true
    })
    this.onCallToDisableScroll()
    if (el) {
      const temp = el.className.split(' ').slice(-1)
      if (temp.length) {
        const elementKey = temp[0]
        const elementsPage = Number(el.getAttribute('data-page-nr'))
        const elementsText = el.innerText
        //if (elementsPage !== this.state.pageNumber) {
        this.onAllSearchChangePage(el, indexEl, arrayQuery).then((response) => {
          const parentWr = document.querySelector('div.canvas-page-'+this.state.pageNumber)
          const selectionPosition = elRect //el.getBoundingClientRect()
          const left = selectionPosition.left - parentWr.getBoundingClientRect().left + window.scrollX
          const top = selectionPosition.top - parentWr.getBoundingClientRect().top - 3
          if (!canvasCleaned) {
            if (!this.duplicateInCanvas(el, left, top)) {
              const rect = new fabric.Rect({
                left: left,
                top: top,
                width: selectionPosition.width,
                height: selectionPosition.height + 3,
                fill: color ? color : (redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'),
                angle: 0,
                padding: 0,
                selectable: true,
                text: elementsText ? elementsText : '',
                annotation: elementKey
              })
              this._sketch._fc.add(rect)
              this._sketch._fc.renderAll()
              if (elementsPage !== this.state.totalPages && (this.getStatusElementRedaction() === 'elementStandard' || this.getStatusElementRedaction() === 'elementDict' || this.getStatusElementRedaction() === 'elementRegEx' ||
                  this.state.sourceActive === 'standard' || this.state.sourceActive === 'regex' || this.state.sourceActive === 'dictionaries')) {
                setTimeout(() => {
                  this.setState({
                    checkStartRedaction: false
                  })
                  this.onRemoveEventDisableScroll()
                }, 5000)
              }
            }
          } else if (!alreadyRedactedList.includes(elementKey)) {
            alreadyRedactedList.push(elementKey)
            const rect = new fabric.Rect({
              left: left,
              top: top,
              width: selectionPosition.width,
              height: selectionPosition.height + 3,
              fill: color ? color : (redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'),
              angle: 0,
              padding: 0,
              selectable: true,
              text: elementsText ? elementsText : '',
              annotation: elementKey
            })
            this._sketch._fc.add(rect)
            this._sketch._fc.renderAll()
          }
          if (elementsPage === totalPages) {
            this.toggleLoadingProcess(false)
            // this.setState({
            //   checkStartRedaction: false
            // })
            // this.onRemoveEventDisableScroll()
            setTimeout(() => {
              if (elementsPage === totalPages) {
                this.setState({
                  checkStartRedaction: false
                })
                this.onRemoveEventDisableScroll()
              }
            }, 5000)
          }
        })

        //}

      }
    }
  }

  async onAllSearchChangePage(element, indexEl, arrayQuery, pageNr = 1) {
    const { statusLastPages } = this.state
    const elementsPage = element !== null ? Number(element.getAttribute('data-page-nr')) : pageNr
    if (elementsPage === this.state.totalPages) {
      this.setState({
        checkStartRedactAllPagesNextFiles: false,
        checkStartRedactAllPagesBackFiles: false
      })
    }
    if (elementsPage === 2) {
    }
    if (elementsPage && (elementsPage !== this.state.pageNumber) && elementsPage !== 0) {
      this.changeAllPage(elementsPage, indexEl, arrayQuery)
      const elemPage = elementsPage + 1
      if (elementsPage === this.state.totalPages) {
        setTimeout(() => {
          if (elementsPage === this.state.totalPages) {
            this.setState({
              checkStartRedaction: false
            })
            this.onRemoveEventDisableScroll()
          }
        }, 5000)
      } else if (elemPage === this.state.totalPages) {
        setTimeout(() => {
          if (elemPage === this.state.totalPages) {
            this.setState({
              checkStartRedaction: false
            })
            this.onRemoveEventDisableScroll()
          }
        }, 5000)
      } else if (elemPage !== this.state.totalPages && (this.getStatusElementRedaction() === 'elementStandard' ||
          this.state.sourceActive === 'standard' || this.state.sourceActive === 'regex' || this.state.sourceActive === 'dictionaries')) {
        setTimeout(() => {
          this.setState({
            checkStartRedaction: false
          })
          this.onRemoveEventDisableScroll()
        }, 5000)
      }
    } else if (indexEl === arrayQuery.length - 1) {
          this.changeAllPage(elementsPage, indexEl, arrayQuery)
        this.setState({
          statusLastPages: false
        }, () => {
          this.setState({
            statusLastPages: false,
            pageNumber: elementsPage
          })
          //this.changeAllPage(elementsPage)
        })
      } else if (this.state.totalPages !== elementsPage) {
      }
  }

  changeAllPage = (newPageNumber, indexEl, arrayQuery, annt, statusPageSidebar) => {
    this.setState({
      statusClickPageSidebar: statusPageSidebar,
      stateSidebarPages: false
    })
    const elements = document.querySelectorAll(`div#${ this.state.divPrint }`)
    if (elements) {
      for (let i = 0; i < this.state.totalPages; i++) {
        if (elements[i] && Number(elements[i].getAttribute('data-pageid')) === newPageNumber) {
          elements[i].classList.remove('show-pdf-page')
          elements[i].classList.add('show-pdf-page')
        } else if (elements[i]) {
          elements[i].classList.remove('show-pdf-page')
        }
      }
    }

    //add active class to pages thumbnails
    const elementsSmall = document.querySelectorAll('div.previewpdf__container__item')
    if (this.state.navigationType === 'pages') {
      for (let i = 0; i < this.state.totalPages; i++) {
        if (Number(elementsSmall[i].getAttribute('data-pageid')) === newPageNumber) {
          elementsSmall[i].classList.add('previewpdf__container__item--active')
        } else {
          elementsSmall[i].classList.remove('previewpdf__container__item--active')
        }
      }
    }

    const points = this.getRectanglesForPreviewPage()
    this.updateAllRectangles(points, statusPageSidebar, indexEl, arrayQuery)
    const initialCanvasData = this.state.canvasData
    initialCanvasData[this.state.pageNumber] = points
    this.hideCanvasModals()
    this.setState({
      pageNumber: newPageNumber,
      canvasData: initialCanvasData,
      forPageNumber: newPageNumber,
      haveChanges: false,
    }, () => {
      if (this.state.navigationType !== 'pages' && this.state.navigationType !== 'find-asset' && this.state.navigationType !== 'image') {
        const canvas = this._sketch._fc
        canvas._objects.forEach((rectangle) => {
          rectangle.set('stroke', 'rgba(0,0,0,0.41)')
          rectangle.set('strokeWidth', 1)
          if (annt) {
            if ((rectangle.aCoords.br.x === annt.x2 + 1 && rectangle.aCoords.bl.y === annt.y1 + 1)
                || (rectangle.aCoords.br.x === annt.x2 && rectangle.aCoords.bl.y === annt.y1)) {
              rectangle.set('stroke', 'rgb(255,244,11)')
              rectangle.set('strokeWidth', 2)
            }
          }
        })
        canvas.renderAll()
      } else {
        const canvas = this._sketch._fc
        canvas._objects.forEach((rectangle) => {
          rectangle.set('stroke', 'rgba(0,0,0,0.41)')
          rectangle.set('strokeWidth', 1)
        })
        canvas.renderAll()
      }
    })
  }

  updateAllRectangles = (points, statusPageSidebar, indexEl, arrayQuery) => {
    const { statusAfterRemove, stateSidebarPages, extractPointsToSave, dtoRedactAll } = this.state
    const pointsToSave = {}
    const listPoints = []
    let maxHeight //= 10000
    const elements = document.querySelectorAll(`div#${ this.state.divPrint }.show-pdf-page`)
    if(elements.length > 0) {
      maxHeight = elements[0].getBoundingClientRect().height
    } else {
      maxHeight = 10000
    }
    points.forEach((element) => {
      let y1 = element.y1 - 1
      if(y1 > maxHeight) {
        y1 = y1 - maxHeight
      }

      let y2 = element.y2
      if(y2 > maxHeight) {
        y2 = y2 - maxHeight
      }
      const obj = {
        x1: element.x1,
        x2: element.x2 - 1,
        y1: y1,
        y2: y2,
        annotation: element.annotation ? element.annotation : (element.text ? element.text : ''),
        text: element.text ? element.text : '',
        type: element.type,
        pagePointSum: element.pagePointSum,
        points: element.points,
        label: element.label,
        picture: true
      }
      const stringObj = JSON.stringify(obj)
      listPoints.push(stringObj)
      pointsToSave[this.state.pageNumber] = listPoints
      this.setState({
        pagePointSumList: element.pagePointSum,
      })
    })
    const pointsCanvas = []
    let pointsCanvasAll = []
    const numberPage = []
    let numberPageAll = []
    pointsCanvas.push(pointsToSave)
    pointsCanvasAll = this.state.extractPointsToSave.concat(pointsCanvas)
    numberPage.push(this.state.pageNumber)
    numberPageAll = this.state.extractPageNumber.concat(numberPage)
    this.setState({
      extractPointsToSave: pointsCanvasAll,
      extractPageNumber: numberPageAll
    })
    const dataToSave = {
      idDoc: this.props.documentId,
      page: this.state.pageNumber,
      pageRectangleMap: pointsToSave

    }
    const dtoRedact = []
    let redactAllDto = []
    const dataGetPoints = {
      idDoc: this.props.documentId,
      page: this.state.pageNumber,
    }

    const dataToSaveRedactAll = {
      page: this.state.pageNumber,
      pageRectangleMap: pointsToSave

    }
    dtoRedact.push(dataToSaveRedactAll)
    redactAllDto = this.state.dtoRedactAll.concat(dtoRedact)
    this.setState({
      dtoRedactAll: redactAllDto
    }, () => {
      if ((indexEl === arrayQuery.length - 1)) {
        const saveCanvasDocumentSmartViewDTO = {
          idDoc: this.props.documentId,
          pageCoordinates: redactAllDto

        }
        if (this.props.isSmartBoxApplication) {
          if (Object.keys(pointsToSave) && Object.keys(pointsToSave).length > 0) {
            if (!this.props.isOcr) {
              apiSmartBoxSaveCanvasDocumentSmartview(saveCanvasDocumentSmartViewDTO).then((response) => {
                this.setState({
                  statusCloseSmartviewRedactAll: false
                })
              })
            }
          }
        }
      }
    })
    if (this.props.isSmartBoxApplication) {
      if (!statusAfterRemove) {
        if (Object.keys(pointsToSave) && Object.keys(pointsToSave).length > 0) {
          // apiSmartBoxUpdateCanvasPoints(dataToSave).then(() => {
          //
          // }).catch((error) => {
          //   if (error?.response?.status === 504) {
          //     CustomNotificationManager.cleanBuffer('warning', '')
          //   }else{
          //     console.log(error)
          //   }
          // })
        }
      }
      this.setState({
        statusSavedSmartView: true
      })
    } else {
      apiUpdateCanvasPoints(dataToSave).then(() => {

      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
            console.log(error)
        }
      })
      this.setState({
        statusAfterRemove: false
      })
    }
  }

  onGetCanvasPointsXeroxRedaction = async(response) => {
    // global.finalItems = {}
    // global.items = {}
    // global.markedTimes = 0
    // global.itemsToBeMarked = 0
    // global.arEl = []
    // global.arOptions = []
    const response2 = await apiSmartBoxGetPreviewRedactingSarDocByThumbnailId(response.idDoc)
    const response3 = await apiSmartBoxGetAnnotationsByClientId(this.props.clientId)
    const response4 = await apiGetSetDocumentRedactAllStatus(response.idDoc)
    const newCanvasData = await this.convertCanvasData(response.pageRectangleMap === null ? {} : response.pageRectangleMap)
    const alreadyRedactedKeys = await getRedactedKeys({ id: response.idDoc })
    //this.generateHtmlForAnnotationPreview(newCanvasData)
    this.setState({
      //isDocumentReady: false,
      canvasData: newCanvasData,
      //pdfBytes: this.props.pdfBytes,
      landscape: this.props.landscape,
      divPrint: this.props.landscape ? 'div-to-print-landscape' : 'div-to-print-portrait',
      width: this.props.dbWidth * (this._generateScale(this.props.landscape)),
      height: this.props.dbHeight * (this._generateScale(this.props.landscape)),
      forPageNumber: 1,
      totalThumbnails: response2.data,
      annotationList: response3.data,
      loading: true,
      idDoc: response.idDoc,
      renderedPagesStatus: [...Array(this.props.totalPages)].map((item) => false),
      alreadyRedactedKeys: alreadyRedactedKeys.data,
      statusRedactAll: response4.data.document_redact_all_status,
      loadingBeforeMark: true,
      totalPages: null,
      startRedactXerox: true,
    }, () => {
      //this.generateHtmlForAnnotationPreview(this.state.canvasData)
      this.setState({
        canvasData: newCanvasData,
        totalPages: this.props.totalPages,
        loadingBeforeMark: false,
        startRedactXerox: false,
      })
    })
    //this.props.handlePreviewDocumentNext(response.idDoc)
    if (Object.keys(newCanvasData) && Object.keys(newCanvasData).length === 0) {
      this.setState({
        previewPageDisabled: true,
      })
    }
    this.setState({
      clearPageDisabled: true
    })
  }
  render() {
    const { OptGroup } = Select
    const {
      totalPages,
      tool,
      forPageNumber,
      canvasData,
      pdfBytes,
      totalThumbnails,
      isDocumentLoaded,
      divPrint,
      landscape,
      height,
      width,
      annotationList,
      finalizeLoading,
      //printAnnotation,
      scale,
      showCloseButton,
      renderSearch,
      viewAnnotationsRedaction,
      renderSelectDraw,
      renderSelectText,
      renderCommentsText,
      annotatedAiHTML,
      printLoading,
      disableClickOnPage,
      documentNoteVisible,
      isDocumentReady,
      pageNumber,
      annotateSelectedModalVisible,
      itemsSelected,
      numberOfAnnotateSelected,
      idDoc,
      renderedPagesStatus,
      loadingBeforeMark,
      pageSidebarVisible,
      displaySearchButtons,
      newListKeys,
      loadingProcess,
      loadingProcessMessage,
      //statusSelectAgainRedacted,
      newListKeysStandard,
      verificationOnClickClearAll,
      newListKeysManual,
      verificationOnClickClearAllManual,
      newListKeysRegEx,
      verificationOnClickClearAllRegEx,
      newListKeysBusiness,
      verificationOnClickClearAllBusiness,
      statusKeysEmptyOnClearAll,
      statusSavedSmartView,
      drawByHandText,
      clearSketch,
      activeDocumentNote,
      activePrintDocument,
      activeClearPage,
      activeClearSelected,
      activeSearchWord,
      activeAnnotation,
      activeSelectTool,
      activeSelectDraw,
      activeSelectText,
      activeUndo,
      activeRedo,
      treeDataSearchHistory,
      valueSelectNavigation,
      isNextDocument,
      isNextDocumentId,
      disabledNextDocument,
      nextDocumentLength,
      filesTable,
      disabledBackDocument,
      checkStartRedactAllPagesNextFiles,
      checkStartRedactAllPagesBackFiles,
      notifyNote,
      searchMatchedElement,
      statusSaveQuery,
      alreadyRedactedKeys,
      activePreview,
      visibleDropdownStatus,
      visibleDropdownNavigation,
      clearPageDisabled,
      statusRedactAll,
      extractPointsToSave,
      statusCloseSmartviewRedactAll,
      statusNextDocumentBusiness,
      statusNextDocumentRegEx,
      statusNextDocumentStandard,
      customerStatus,
      selectTag,
      checkedPreview,
      redactingMode,
      removeSelectedBtnDisabled,
      renderSelectTool,
      previewPageDisabled,
      startRedactXerox,
      canvasDataOcrDrawBox,
      docAnnotationsStandardOcr,
      statusPageSidebar,
      documentNoteGet,
      activeComments,
      canvasDataComments,
      statusSaveCanvasDeleteSection
    } = this.state
    let { valueStatusFile } = this.state
    let { checkStartRedaction } = this.state
    const { smartBoxDocumentId, isOcr, smartBoxDocumentPath, documentId, visibleToDownload, xeroxUpload, fileWarnings, lockSmartViewChanges } = this.props
    const { client } = this.props
    const itemSearch = cookie.load('sendNoteResponse', { path: '/' })
    // console.log('this.state --- RENDERR', this.state)
    // console.log('this.props --- RENDERR', this.props)
    // if (typeof this._sketch !== 'undefined') {

    // }
    // if (statusSavedSmartView === true) {
    //   checkStartRedaction = false
    // }
    //valueStatusFile = systemPropertyFileStatus
    //valueStatusClassification = doc_classification
    const btnClasses = this.setCanvasButtonsClasses()
    let canvasModalText = ''
    if (this._sketch) {
      const canvasModalObjectSelected = this._sketch.state.selectedObject
      const relatedObj = this._sketch.findByPagePointSum(canvasModalObjectSelected.pagePointSum, canvasModalObjectSelected.annotationType)
      if (relatedObj) {
        canvasModalText = relatedObj.text
      }
    }
    const filesPdf = []
    let disabledNextFiles = false
    let disabledBackFiles = false
    this.props.filesTable && this.props.filesTable.length > 0 && this.props.filesTable.forEach((file) => {
      if (file.type !== 'DIRECTORY') {
        filesPdf.push(file)
      }
    })
    if (filesPdf && filesPdf.length > 1) {
      if (filesPdf[filesPdf.length-1].id === documentId) {
        disabledNextFiles = true
      } else {
        disabledNextFiles = false
      }
    }
    if (filesPdf && filesPdf.length > 1) {
      if (filesPdf[0].id === documentId) {
        disabledBackFiles = true
      } else {
        disabledBackFiles = false
      }
    }
    const children = []
    if (annotationList) {
      const groupedAnnotationList = groupArrayBy(annotationList, 'annotationCategoryEnum')
      Object.keys(groupedAnnotationList).forEach((category, key) => {
        const item = groupedAnnotationList[category]
        if (item) {
          children.push(
            <OptGroup label={ category } key={ key }>
              { this.addAnnotationOptions(item) }
            </OptGroup>
          )
        }
      })
    }
    this.settings.slidesToShow = 3
    //const openingTime = this.addTimeForOpening(this.props.totalPages)
    return (
      <React.Fragment>
        {/*<Spin className="custom-spin" spinning={ loading }
          tip={ loading ?
            `Opening ${ this.props.totalPages } ${ this.props.totalPages === 1 ? 'page' : 'pages' }, please wait up to ${ openingTime } ${ this.props.totalPages > 60 ?
              (openingTime === 1 ? 'minute' : 'minutes') :
              (openingTime === 1 ? 'second' : 'seconds') }` :
            '' }>*/}
          { (loadingProcess) &&
            <div className="redacting-mask">
              <div className="redacting-mask-div">
                <Spin className="redacting-mask-spin mt-20" spinning={true}/>
                <p className="redacting-mask-text">{ loadingProcessMessage }</p>
              </div>
            </div>
          }
          { this.props.isSmartBoxApplication &&
          <div className="content-name-saved"><span className="content-document-name">
            { isDocumentReady ?
              <span
                  className={checkStartRedactAllPagesBackFiles ? 'icon-v17-back-1 disabled-back-1' : disabledBackDocument ? 'icon-v17-back-1 disabled-back-1' :
                      (filesPdf && filesPdf.length === 1) ? 'icon-v17-back-1 disabled-back-1' :
                          disabledBackFiles ? 'icon-v17-back-1 disabled-back-1' : 'icon-v17-back-1 active-back-1'}
                  onClick={checkStartRedactAllPagesBackFiles ? (e) => this.onDisabledForthDocument(e)
                      : disabledBackDocument ? (e) => this.onDisabledForthDocument(e) :
                      (filesPdf && filesPdf.length === 1) ? (e) => this.onDisabledForthDocument(e) :
                          disabledBackFiles ? (e) => this.onDisabledForthDocument(e) :
                      (e) => this.onClickBackDocument(e)}></span> :
              <span
              className={checkStartRedactAllPagesBackFiles ? 'icon-v17-back-1 disabled-back-1' : disabledBackDocument ? 'icon-v17-back-1 disabled-back-1' :
                  filesPdf && filesPdf.length === 1 ? 'icon-v17-back-1 disabled-back-1' :
                      disabledBackFiles ? 'icon-v17-back-1 disabled-back-1' :
                          !isDocumentReady ? 'icon-v17-back-1 disabled-back-1' : 'icon-v17-back-1 active-back-1'}></span>
            }
            <span className="document-name">{
            smartBoxDocumentId.substr(smartBoxDocumentId.lastIndexOf('/') + 1, smartBoxDocumentId.length) }
          </span>
            {/*<span className="document-saved">{ statusSavedSmartView ? '- saved' : '' }</span>*/}
            {isDocumentReady ?
                <span
                    className={checkStartRedactAllPagesNextFiles ? 'icon-v17-forth-1 disabled-v17-forth'
                        : disabledNextDocument ? 'icon-v17-forth-1 disabled-v17-forth' :
                        (filesPdf && filesPdf.length === 1) ? 'icon-v17-forth-1 disabled-v17-forth' :
                            disabledNextFiles ? 'icon-v17-forth-1 disabled-v17-forth' : 'icon-v17-forth-1 active-v17-forth' }
                    onClick={checkStartRedactAllPagesNextFiles ? (e) => this.onDisabledForthDocument(e) :
                        disabledNextDocument ? (e) => this.onDisabledForthDocument(e) :
                        (filesPdf && filesPdf.length === 1) ? (e) => this.onDisabledForthDocument(e) :
                            disabledNextFiles ?  (e) => this.onDisabledForthDocument(e)
                        : (e) => this.onClickForthDocument(e) }></span> :
                <span
                    className={checkStartRedactAllPagesNextFiles ? 'icon-v17-forth-1 disabled-v17-forth' :
                        disabledNextDocument ? 'icon-v17-forth-1 disabled-v17-forth' :
                        filesPdf && filesPdf.length === 1 ? 'icon-v17-forth-1 disabled-v17-forth' :
                            disabledNextFiles ? 'icon-v17-forth-1 disabled-v17-forth' :
                                !isDocumentReady ? 'icon-v17-forth-1 disabled-v17-forth' : 'icon-v17-forth-1 active-v17-forth'}></span>
            }
            </span></div>
          }
          <div className={ checkStartRedaction ? "redacting redacting-opacity" : 'redacting' }
            style={ { height: 'calc(100vh - 0px)', gridTemplateColumns: this.generateGridColumnProperties() } }>
            <div className="previewpdf" style={ {
              overflowY: this.generateNavigationScroll(),
              pointerEvents: documentNoteVisible ? 'none' : 'all'
            } }>

              <div className="new-navigation-smartview">
                <Select
                    value={ <span className="content-value-navigation"><span>{ valueSelectNavigation === 'ai-results' ?
                            <span className="nav-option"><span className="icon-v17-personal icon-nav-pii"></span>
                            <span className="text-option">Ai results</span></span> :
                            valueSelectNavigation === 'search-history' ?
                            <span className="nav-option"> <span className="icon-v17-search-history icon-nav"></span>
                            <span className="text-option">Search history</span></span> :
                            valueSelectNavigation === 'images' ?
                                <span className="nav-option"><span className="icon-v17-images icon-nav"></span>
                                <span className="text-option">Images</span></span> :
                                valueSelectNavigation === 'review-by-page' ?
                                  <span className="nav-option"><span className="icon-v17-db-documents icon-nav"></span>
                                  <span className="text-option">Review by page</span></span> :
                                  //   valueSelectNavigation === 'comments' ?
                                  //       <span className="nav-option"><span className="icon-v17-db-documents icon-nav"></span>
                                  // <span className="text-option">Comments</span></span> :
                                      valueSelectNavigation === 'metadata' ?
                                  <span className="nav-option"><span className="icon-v17-metadata-1 icon-nav-metadata"></span>
                                  <span className="text-option">Metadata</span></span> : ''}</span>
                      <span className={ visibleDropdownNavigation ? "icon-v19-arrow-medium icon-v19-arrow-medium-rotate" : 'icon-v19-arrow-medium' }></span></span> }
                    className='navigation-bar-smartview'
                    dropdownClassName="new-navigation-bar-smartview"
                    onChange={ (e) => this.changeNavigationBar(e) }
                    showArrow={ false }
                    onDropdownVisibleChange={ (e) => this.onVisibleDropdownNavigation(e) }
                >
                  <Option className="options-bar-smartview" key={1} value="ai-results">
                    <span className="icon-v17-personal icon-nav-pii"></span>
                    <span className="text-option">Ai results</span>
                  </Option>
                  <Option className="options-bar-smartview" key={2} value="search-history">
                    <span className="icon-v17-search-history icon-nav"></span>
                    <span className="text-option">Search history</span>
                  </Option>
                  {/*<Option className="options-bar-smartview" key={3} value="images">*/}
                  {/*  <span className="icon-v17-images icon-nav"></span>*/}
                  {/*  <span className="text-option">Images</span>*/}
                  {/*</Option>*/}
                  {/*<Option className="options-bar-smartview" key={4} value="spell-checker">*/}
                  {/*  <span className="icon-v17-spell-checker-1 icon-nav-spell"></span>*/}
                  {/*  <span className="text-option">Spell Checker</span>*/}
                  {/*</Option>*/}
                  <Option className="options-bar-smartview" key={5} value="review-by-page">
                    <span className="icon-v17-db-documents icon-nav"></span>
                    <span className="text-option">Review by page</span>
                  </Option>
                  {/*<Option className="options-bar-smartview" key={7} value="comments">*/}
                  {/*  <span className="icon-v17-db-documents icon-nav"></span>*/}
                  {/*  <span className="text-option">Comments</span>*/}
                  {/*</Option>*/}
                  <Option className="options-bar-smartview" key={6} value="metadata">
                    <span className="icon-v17-metadata-1 icon-nav-metadata"></span>
                    <span className="text-option">Metadata</span>
                  </Option>
                </Select>
              </div>

              { this.state.navigationType === 'redacting' &&
                <div className="previewpdf__container previewpdf__container-review">
                  <div className="header-contain-page-review">
                    <div className="content-option-ai">
                      {/*<span className="icon-v17-redaction-hover"></span>*/}
                      <Select
                          placeholder="show-all"
                          className='custom-option-review'
                          dropdownClassName="dropdown-custom-option-review"
                      >
                        <Option className="item-options-review" key={1} value="review-all">
                          show all
                        </Option>
                        <Option className="item-options-review" key={2} value="review-redacted">
                          redacted
                        </Option>
                        <Option className="item-options-review" key={3} value="review-annotated">
                          annotated
                        </Option>
                      </Select>
                    </div>
                    {/*<ReviewPrevNextAiTag/>*/}
                  </div>
                  {
                    this.generateHtmlForAnnotationPreview(canvasData)
                  }
                </div>
              }

              {/*{*/}
              {/*  this.state.navigationType === 'comments' &&*/}
              {/*  <div className="previewpdf__container previewpdf__container-review">*/}
              {/*    {*/}
              {/*      this.generateCommentsPreview(canvasDataComments)*/}
              {/*    }*/}
              {/*  </div>*/}
              {/*}*/}

              { this.state.navigationType === 'annotation' &&
              <div className="previewpdf__container" style={{ width: '14vw' }}>
                <p>Annotation sidebar</p>
              </div>
              }
              { this.state.navigationType === 'security-scan' &&
              <div className="previewpdf__container" style={{ width: '14vw' }}>
                {
                  annotatedAiHTML
                }
              </div>
              }

              { this.state.navigationType === 'image' &&
              <div className="previewpdf__container" style={{ width: '17vw' }}>
                <SearchImagesContent documentId = { this.props.documentId }
                  canvasData = { canvasData }
                  highlightRectangle={ this.highlightRectangle }
                  isDocumentReady={ isDocumentReady }
                  changePage={ this.changePage }
                  drawAllMatchedWordsByClassList={this.drawAllMatchedWordsByClassList}
                  pageNumber={this.state.pageNumber}
                  deleteAllImages={ this.deleteAllImages }
                  removeObj={this.removeObj}
                  smartBoxDocumentId={ this.props.smartBoxDocumentId }
                  initialName={ this.props.initialName}
                  isSmartBoxApplication = { this.props.isSmartBoxApplication }
                  totalPages={ totalPages }
                  totalThumbnails={ totalThumbnails }
                  isNextDocumentId={ isNextDocumentId }/>
              </div>
              }
              { this.state.navigationType === 'spell_checker' &&
              <div className="previewpdf__container" style={{ width: '15vw' }}>
                <SpellCheckerPage
                  highlightRectangle={ this.highlightRectangle }
                  isDocumentReady={ isDocumentReady }
                  changePage={this.changePage}
                  drawAllMatchedWordsByClassList={this.drawAllMatchedWordsByClassList}
                  documentId = { this.props.documentId }
                  totalPages={ totalPages }
                  smartBoxDocumentId={ this.props.smartBoxDocumentId }
                  isSmartBoxApplication = { this.props.isSmartBoxApplication }
                  pageNumber={ this.state.pageNumber }
                  isNextDocumentId={ isNextDocumentId }
                />
              </div>
              }
              { this.state.navigationType === 'search-history' &&
              <div className="previewpdf__container" style={{ width: '100%' }}>
                <SearchHistory isDocumentReady={ isDocumentReady }
                               treeDataSearchHistory={ treeDataSearchHistory }
                               highlightNextText={ this.highlightNextText }
                               highlightPrevText={ this.highlightPrevText }
                               matchedText={this.state.matchedText}
                               isOcr={ isOcr }
                               onSearchTextElementFind={ this.onSearchTextElementFind }
                               searchMatchedElement={ searchMatchedElement }
                               idDocument={ this.state.documentId }
                               isSmartBoxApplication={ this.props.isSmartBoxApplication }
                               drawAllMatchedWordsByClassList={this.drawAllMatchedWordsByClassList}
                               changePage={this.changePage}
                               getSearchHistoryList= { this.getSearchHistoryList }
                               alreadyRedactedKeys= { alreadyRedactedKeys }
                               lockSmartViewChanges= { lockSmartViewChanges }
                />
              </div>
              }
              { this.state.navigationType === 'metadata' &&
                <div className="previewpdf__container" style={{ width: '14vw', marginLeft: '10px' }}>
                  <MetadataPage
                    isSmartBoxApplication = { this.props.isSmartBoxApplication }
                    documentId = { isNextDocumentId.id ? isNextDocumentId.id : this.props.documentId }
                    isNextDocumentId={ isNextDocumentId }
                  />
                </div>
              }
              { this.state.navigationType === 'security-scan' &&
                <div className="previewpdf__container" style={{ width: '14vw' }}>
                  {
                    annotatedAiHTML
                  }
                </div>
              }
              <div className="previewpdf__container" style={{ display: this.state.navigationType === 'find-asset' ? 'initial' : 'none', width: '100%' }}>
                {
                  <AiAnnotationsSidebar
                    documentId={ this.props.isSmartBoxApplication ? smartBoxDocumentId : this.props.documentId }
                    idDocument={ this.state.documentId }
                    onScanAnnotateClick={ this.onScanAnnotateClick }
                    isDocumentReady={ isDocumentReady }
                    isSmartBoxApplication={ this.props.isSmartBoxApplication }
                    landscape={ landscape }
                    changePage={this.changePage}
                    pageNumber={ this.state.pageNumber }
                    drawAllMatchedWordsByClassList={this.drawAllMatchedWordsByClassList}
                    collapseAnnotateSelectModal={this.collapseAnnotateSelectModal}
                    setNumberOfAnnotateSelected={this.setNumberOfAnnotateSelected}
                    hideLoadingBeforeMark={this.hideLoadingBeforeMark}
                    allPagesRendered={ renderedPagesStatus.length > 0 && renderedPagesStatus.indexOf(false) === -1 }
                    setQueryStatus={this.setQueryStatus}
                    highlightRectangle={ this.highlightRectangle }
                    setTreeDataAnnotations = { this.setTreeDataAnnotations }
                    newListKeys = { newListKeys }
                    smartBoxDocumentPath={ this.props.isSmartBoxApplication ? smartBoxDocumentPath : this.props.documentId }
                    toggleLoadingProcess = { this.toggleLoadingProcess }
                    onCheckStatusSelectAgainRedacted= { this.onCheckStatusSelectAgainRedacted }
                    newListKeysStandard = { newListKeysStandard }
                    verificationOnClickClearAll = { verificationOnClickClearAll }
                    checkOnClearAll = { this.checkOnClearAll }
                    newListKeysManual = { newListKeysManual }
                    verificationOnClickClearAllManual = { verificationOnClickClearAllManual }
                    checkOnClearAllManual = { this.checkOnClearAllManual }newListKeysRegEx = { newListKeysRegEx }
                    checkOnClearAllRegEx = { this.checkOnClearAllRegEx }
                    verificationOnClickClearAllRegEx = { verificationOnClickClearAllRegEx }
                    newListKeysBusiness = { newListKeysBusiness }
                    checkOnClearAllBusiness = { this.checkOnClearAllBusiness }
                    verificationOnClickClearAllBusiness = { verificationOnClickClearAllBusiness }
                    statusKeysEmptyOnClearAll = { statusKeysEmptyOnClearAll }
                    onSendStatusOnClearAll = { this.onSendStatusOnClearAll }
                    isOcr={ isOcr }
                    fetchSmartBoxDataCanvasOcr={ this.fetchSmartBoxDataCanvasOcr }
                    isNextDocument={ isNextDocument }
                    isNextDocumentId={ isNextDocumentId }
                    disabledNextDocument={ disabledNextDocument }
                    nextDocumentLength={ nextDocumentLength }
                    filesTable={ filesTable }
                    checkedKeysObjectRefsStandard={this.checkedKeysObjectRefsStandard}
                    statusSaveQuery={ statusSaveQuery }
                    alreadyRedactedKeys={ alreadyRedactedKeys }
                    setTreeDataRegEx={ this.setTreeDataRegEx }
                    setTreeDataDict={ this.setTreeDataDict }
                    savePage={ this.savePage }
                    getStatusElementRedaction={ this.getStatusElementRedaction }
                    drawRedactAllMatchedWords={ this.drawRedactAllMatchedWords }
                    statusRedactAll={ statusRedactAll }
                    statusNextDocumentBusiness={ statusNextDocumentBusiness }
                    onGetStatusNextDocumentBusiness={ this.onGetStatusNextDocumentBusiness }
                    statusNextDocumentRegEx={ statusNextDocumentRegEx }
                    onGetStatusNextDocumentRegEx={ this.onGetStatusNextDocumentRegEx }
                    statusNextDocumentStandard={ statusNextDocumentStandard }
                    onGetStatusNextDocumentStandard={ this.onGetStatusNextDocumentStandard }
                    onDisabledNextDocument={ this.onDisabledNextDocument }
                    onDisabledBackDocument={ this.onDisabledBackDocument }
                    xeroxUpload={ xeroxUpload }
                    onGetCanvasPointsXeroxRedaction={ this.onGetCanvasPointsXeroxRedaction }
                    startRedactXerox={ startRedactXerox }
                    showHideCategoryOcr={ this.showHideCategoryOcr }
                    docAnnotationsStandardOcr={ docAnnotationsStandardOcr }
                    lockSmartViewChanges={ lockSmartViewChanges }
                  />
                }
              </div>
            </div>

            <div className= { renderSearch ? 'previewpdf-right previewpdf-right-open-search' : 'previewpdf-right' } style={ {
              overflowY: this.generateNavigationScroll(),
              pointerEvents: documentNoteVisible ? 'none' : 'all',
              display: pageSidebarVisible ? 'flex' : 'none'
            } }>
              { pageSidebarVisible &&
              <div
                className={ `previewpdf__container__pages ${ landscape ?
                  'previewpdf__container__pages--preview-landscape' :
                  '' }` } >
                <h3 className="previewpdf__container__pages__title">{ totalPages !== null ? `PAGES ${ pageNumber } / ${ totalPages }` : '' }</h3>
                <PageSidebarContent
                  totalThumbnails={ totalThumbnails }
                  totalPages={ totalPages }
                  canvasData={ canvasData }
                  changePage={ this.changePage }
                  pageNumber={ pageNumber }
                  checkStartRedaction={ checkStartRedaction }
                  onDefaultChange={ this.onDefaultChange }
                />
              </div>
              }
            </div>

            {/*<div className="navigation-tabs" style={ { pointerEvents: documentNoteVisible ? 'none' : 'all' } }>*/}
            {/*  <Tooltip placement="right" title="Pages">*/}
            {/*    <Icon type="copy"*/}
            {/*      className={ `pages-navigation-tab ${ pageSidebarVisible ? 'navigation-active' : '' } ` }*/}
            {/*      onClick={ () => this.changeNavigation('pages') } style={ { fontSize: '33px' } }/>*/}
            {/*  </Tooltip>*/}

            {/*  <Tooltip placement="right" title="Redacted">*/}
            {/*    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 19 25"*/}
            {/*      onClick={ () => this.changeNavigation('redacting') }*/}
            {/*      style={ { marginTop: '15px', cursor: 'pointer' } }>*/}
            {/*      <g fill="none" fillRule="evenodd">*/}
            {/*        <path stroke={ this.generateSVGColor(navigationType, 'redacting') } strokeWidth="2"*/}
            {/*          d="M18 24V7.215L11.716 1H1v23h17z"/>*/}
            {/*        <path stroke={ this.generateSVGColor(navigationType, 'redacting') } strokeWidth="2" d="M11 1v7h7"/>*/}
            {/*        <path fill={ this.generateSVGColor(navigationType, 'redacting') } d="M5 12h9v3H5zM5 17h6v3H5z"/>*/}
            {/*      </g>*/}
            {/*    </svg>*/}
            {/*  </Tooltip>*/}
            {/*  { navigationType === 'redacting' ? <Icon type="caret-right" className="navigation-icon-redacting"/> : '' }*/}

            {/*  <Tooltip placement="right" title="Annotation">*/}
            {/*    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 19 26"*/}
            {/*      onClick={ () => this.changeNavigation('annotation') }*/}
            {/*      style={ { marginTop: '15px', cursor: 'pointer' } }>*/}
            {/*      <path fill="none" fillRule="evenodd" stroke={ this.generateSVGColor(navigationType, 'annotation') } strokeWidth="2" d="M18 .978H1v22.769l8.5-7.58 8.5 7.58V.978z"/>*/}
            {/*    </svg>*/}
            {/*  </Tooltip>*/}
            {/*  { navigationType === 'annotation' ? <Icon type="caret-right" className="navigation-icon-annotation"/> : '' }*/}

            {/*  <Tooltip placement="right" title="Find asset">*/}
            {/*    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 33 33"*/}
            {/*      onClick={ () => this.changeNavigation('find-asset') }*/}
            {/*      style={ { marginTop: '15px', cursor: 'pointer' } }>*/}
            {/*      <defs>*/}
            {/*        <path id="a" d="M0 .38h32.663V32H0z"/>*/}
            {/*      </defs>*/}
            {/*      <g fill="none" fillRule="evenodd">*/}
            {/*        <path fill={ this.generateSVGColor(navigationType, 'find-asset') }*/}
            {/*          d="M13.446 15.527a1.563 1.563 0 1 0 0-3.125 1.563 1.563 0 0 0 0 3.125M19.7 15.527a1.563 1.563 0 1 0-.001-3.126 1.563 1.563 0 0 0 0 3.126M25.952 15.527a1.563 1.563 0 1 0 0-3.126 1.563 1.563 0 0 0 0 3.126"/>*/}
            {/*        <g transform="translate(0 .621)">*/}
            {/*          <mask id="b" fill="#fff">*/}
            {/*            <use xlinkHref="#a"/>*/}
            {/*          </mask>*/}
            {/*          <path fill={ this.generateSVGColor(navigationType, 'find-asset') }*/}
            {/*            d="M19.7 23.307c-5.495 0-9.965-4.47-9.965-9.964 0-5.494 4.47-9.964 9.964-9.964 5.494 0 9.964 4.47 9.964 9.964 0 5.494-4.47 9.964-9.964 9.964M6.735 13.343c0 2.767.88 5.33 2.362 7.438L0 29.88 2.121 32l8.98-8.98c2.291 2.037 5.298 3.287 8.598 3.287 7.148 0 12.964-5.816 12.964-12.964C32.663 6.194 26.847.38 19.7.38 12.551.38 6.735 6.194 6.735 13.343z"*/}
            {/*            mask="url(#b)"/>*/}
            {/*        </g>*/}
            {/*      </g>*/}
            {/*    </svg>*/}
            {/*  </Tooltip>*/}
            {/*  { navigationType === 'find-asset' ?*/}
            {/*    <Icon type="caret-right" className="navigation-icon-find-asset"/> : '' }*/}

            {/*  <Tooltip placement="right" title="Image">*/}
            {/*    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 28 30"*/}
            {/*      onClick={ () => this.changeNavigation('image') }*/}
            {/*      style={ { marginTop: '15px', cursor: 'pointer' } }>*/}
            {/*      <g fill="none" fillRule="evenodd">*/}
            {/*        <path stroke={ this.generateSVGColor(navigationType, 'image') } strokeWidth="2" d="M1 13.398L7.7 7l5.883 6.249 4.498-3.497L24 15"/>*/}
            {/*        <path stroke={ this.generateSVGColor(navigationType, 'image') } strokeWidth="2" d="M1 1h23v17H1z"/>*/}
            {/*        <path fill={ this.generateSVGColor(navigationType, 'image') } d="M20 5c0-1.103-.897-2-2-2s-2 .897-2 2 .897 2 2 2 2-.897 2-2z"/>*/}
            {/*      </g>*/}
            {/*    </svg>*/}
            {/*  </Tooltip>*/}
            {/*  { navigationType === 'image' ? <Icon type="caret-right" className="navigation-icon-image"/> : '' }*/}

            {/*  <Tooltip placement="right" title="Spell checker">*/}
            {/*    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 28 30"*/}
            {/*      onClick={ () => this.changeNavigation('spell_checker') }*/}
            {/*      style={ { marginTop: '15px', cursor: 'pointer' } }>*/}
            {/*      <g fill="none" fillRule="evenodd">*/}
            {/*        <path fill={ this.generateSVGColor(navigationType, 'spell_checker') } d="M2 3.019h2.008V16h7.01c1.188 0 2.354.313 3.37.906l.242.141.243-.141a6.692 6.692 0 0 1 3.37-.906h6.796V3.019H27V18H16.54l-.935.896h-1.95L12.722 18H2V3.019zM6 1.92h5.857c.86.065 1.703.334 2.438.778l.007.004h.396l.007-.004a5.538 5.538 0 0 1 2.438-.778H23v12.312h-5.443c-.997 0-1.976.238-2.86.688h-.395a6.305 6.305 0 0 0-2.859-.688H6V1.92zm7.03 19.075h2.94L16.979 20H29V1.374h-3.989V0h-7.158l-.037.001A8.134 8.134 0 0 0 14.5.98a8.134 8.134 0 0 0-3.316-.978L3.996 0v1.374H0V20h12.021l1.01.995z"/>*/}
            {/*        <path fill={ this.generateSVGColor(navigationType, 'spell_checker') } d="M10 7l1 3H9z"/>*/}
            {/*        <text fill={ this.generateSVGColor(navigationType, 'spell_checker') } fontFamily="Helvetica" fontSize="8">*/}
            {/*          <tspan x="6" y="14">A-Z</tspan>*/}
            {/*        </text>*/}
            {/*      </g>*/}
            {/*    </svg>*/}
            {/*  </Tooltip>*/}
            {/*  { navigationType === 'spell_checker' ? <Icon type="caret-right" className="navigation-icon-spell_checker"/> : '' }*/}

            {/*  <Tooltip placement="right" title="Metadata">*/}
            {/*    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 22 25"*/}
            {/*      onClick={ () => this.changeNavigation('metadata') }*/}
            {/*      style={ { marginTop: '15px', cursor: 'pointer' } } >*/}
            {/*      <g fill="none" fillRule="evenodd" strokeWidth="2.009">*/}
            {/*        <path stroke={ this.generateSVGColor(navigationType, 'metadata') } d="M1.004 1.004h19.084v19.084H1.004zM12.602 3.823L8.491 17.27"/>*/}
            {/*        <path stroke={ this.generateSVGColor(navigationType, 'metadata') } d="M7.03 8.31l-2.556 2.473 2.557 2.64M14.062 8.31l2.556 2.473-2.556 2.64"/>*/}
            {/*      </g>*/}
            {/*    </svg>*/}
            {/*  </Tooltip>*/}
            {/*  { navigationType === 'metadata' ? <Icon type="caret-right" className="navigation-icon-metadata"/> : '' }*/}
            {/*</div>*/}

            { renderSearch ?
                <Affix aria-hidden="false" style={{position:'fixed'}} className={ displaySearchButtons ? 'search-affix buttons-search-affix' : 'search-affix'}>
                  <div aria-hidden="false" className="update-wr-search-text">
                    <div className="header-search-text">
                      <span className="title-header">Manual search</span>
                      <span className="close-search"
                            onClick={ () => this.closeSearch() }><span
                          className="icon-v17-close-black"/></span>
                    </div>
                    <Search
                        placeholder="Search"
                        className="search-text-input"
                        //onSearch={ (value) => this.searchTextInDoc(value, 'search-history') }
                        onSearch={ (value) => this.searchTextInDocHistory(value, 'search-history') }
                        onChange={ this.onSearchChangeValue }
                        value={ this.state.searchValue }
                    />
                  </div>
                </Affix> : ''

            }
            {/*{ renderSearch ?*/}
            {/*  <Affix className={ displaySearchButtons ? 'search-affix buttons-search-affix' : 'search-affix'}>*/}
            {/*    <div className="wr-search-text">*/}
            {/*      <Search*/}
            {/*        addonBefore={ <span>{ this.state.matchedText.length >= 0 ?*/}
            {/*          (`${ this.state.currentMarkedIndex }/${ this.state.matchedText.length }`) : '' }</span> }*/}
            {/*        addonAfter={*/}
            {/*          <ButtonGroup>*/}
            {/*            <Button onClick={ () => this.highlightPrevText() }><Icon type="left"*/}
            {/*              title="Prev"/></Button>*/}
            {/*            <Button onClick={ () => this.highlightNextText() }><Icon type="right"*/}
            {/*              title="Next"/></Button>*/}
            {/*            <Button onClick={ () => this.drawRectangleOnMatchedWord() }><Icon*/}
            {/*              type="italic"*/}
            {/*              title="Redact"/></Button>*/}
            {/*            <Button onClick={ () => this.fireSwalToRedactAllMatchedWords() }><Icon*/}
            {/*              type="highlight"*/}
            {/*              title="Redact all results"/></Button>*/}
            {/*            <span className="close-search"*/}
            {/*              onClick={ () => this.closeSearch() }><Icon*/}
            {/*                type="close"/></span>*/}
            {/*          </ButtonGroup>*/}
            {/*        }*/}
            {/*        placeholder="input search text"*/}
            {/*        onSearch={ (value) => this.searchTextInDoc(value) }*/}
            {/*        onChange={ this.onSearchChangeValue }*/}
            {/*        value={ this.state.searchValue }*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </Affix> : ''*/}
            {/*}*/}

            {/*<div className="wr-empty"/>*/}

            <div className="wr-buttons sticky-header">
              {/*<div className="document-note">*/}
              {/*  <button className="document-note-button" onClick={ this.openDocumentNote }>DOCUMENT NOTE</button>*/}
              {/*</div>*/}

              {/*<div className="new-navigation-smartview">*/}
              {/*  <Select*/}
              {/*      value={ valueSelectNavigation }*/}
              {/*      className='navigation-bar-smartview'*/}
              {/*      dropdownClassName="new-navigation-bar-smartview"*/}
              {/*      onChange={ (e) => this.changeNavigationBar(e) }*/}
              {/*  >*/}
              {/*    <Option className="options-bar-smartview" key={1} value="ai-results">*/}
              {/*      <span className="icon-v17-personal icon-nav-pii"></span>*/}
              {/*      <span className="text-option">Ai results</span>*/}
              {/*    </Option>*/}
              {/*    <Option className="options-bar-smartview" key={2} value="search-history">*/}
              {/*      <span className="icon-v17-search-history icon-nav"></span>*/}
              {/*      <span className="text-option">Search history</span>*/}
              {/*    </Option>*/}
              {/*    <Option className="options-bar-smartview" key={3} value="images">*/}
              {/*      <span className="icon-v17-images icon-nav"></span>*/}
              {/*      <span className="text-option">Images</span>*/}
              {/*    </Option>*/}
              {/*    /!*<Option className="options-bar-smartview" key={4} value="spell-checker">*!/*/}
              {/*    /!*  <span className="icon-v17-spell-checker-1 icon-nav-spell"></span>*!/*/}
              {/*    /!*  <span className="text-option">Spell Checker</span>*!/*/}
              {/*    /!*</Option>*!/*/}
              {/*    <Option className="options-bar-smartview" key={5} value="review-by-page">*/}
              {/*      <span className="icon-v17-db-documents icon-nav"></span>*/}
              {/*      <span className="text-option">Review by page</span>*/}
              {/*    </Option>*/}
              {/*    <Option className="options-bar-smartview" key={6} value="metadata">*/}
              {/*      <span className="icon-v17-metadata-1 icon-nav-metadata"></span>*/}
              {/*      <span className="text-option">Metadata</span>*/}
              {/*    </Option>*/}
              {/*  </Select>*/}
              {/*</div>*/}

              <div className="update-document-note">
                <React.Fragment>
                  { (documentNoteGet || notifyNote || itemSearch) &&
                    <span className="icon-v17-process-error"></span>
                  }
                <button onMouseEnter={(e) => this.onMouseEnter(e, 'document-note')}
                        onMouseLeave={ () => this.onMouseLeave() }
                        disabled={lockSmartViewChanges === true}
                        className={ activeDocumentNote ? 'icon-v17-document-note-active-1' : 'icon-v17-document-note-1' } onClick={ this.openDocumentNote }></button>
                </React.Fragment>
                <button className={ visibleToDownload === false ? 'icon-v17-download icon-download-disabled' : activePrintDocument ? 'icon-v17-download-active' : 'icon-v17-download' }
                        onMouseEnter={(e) => this.onMouseEnter(e, 'print-document')}
                        onMouseLeave={ () => this.onMouseLeave() }
                        disabled={lockSmartViewChanges === true}
                        onClick={ () => this.printDocument() }></button>
              </div>
              <div className="center-container">
                <div className="center-container__buttons">
                  {/*<div className="wr-document-note">*/}
                  {/*  <button className="icon-v17-document-note-1" onClick={ this.openDocumentNote }></button>*/}
                  {/*</div>*/}
                  <div className="wr-clear-button">
                    <button
                            className={ activeClearPage ? 'icon-v17-clear-page-selected' : 'icon-v17-clear-page' }
                            //className={ btnClasses.clear.class }
                            //id="btn-clear"
                            onMouseEnter={(e) => this.onMouseEnter(e, 'clear-page')}
                            onMouseLeave={ () => this.onMouseLeave() }
                            title="Clear page (clear all redaction boxes and annotations on page)"
                            disabled={(clearPageDisabled === false ? true : btnClasses.clear.disabled) || lockSmartViewChanges === true} onClick={this._clear}/>
                    <button className={ activeClearSelected ? 'icon-v17-clear-selected-selected' : 'icon-v17-clear-selected' }
                            //id="btn-eraser"
                            title="Delete selection (delete selected redaction box)"
                            onMouseEnter={(e) => this.onMouseEnter(e, 'clear-selected')}
                            onMouseLeave={ () => this.onMouseLeave() }
                            onClick={this.removeObj}
                            disabled={this.state.removeSelectedBtnDisabled || statusSaveCanvasDeleteSection === true || lockSmartViewChanges === true}/>

                  </div>
                  {/*<div className="wr-search-button">*/}
                  {/*  <button*/}
                  {/*    className={ `${ btnClasses.undo.class } ${ renderSearch ? 'button-active' : '' }` }*/}
                  {/*    id={ this.state.renderSearch ? 'btn-search-active' : 'btn-search' }*/}
                  {/*    title="Search (search for words or sentences)"*/}
                  {/*    onClick={ (e) => this.chooseMethodRedacting('search') }/>*/}
                  {/*  { renderSearch ? <Icon type="up" className="search-arrow"/> : '' }*/}
                  {/*</div>*/}
                  <div className="wr-undo-redo-button">
                  <button className={ activeUndo ? 'icon-v17-undo-selected' : 'icon-v17-undo' }
                      //className={ btnClasses.undo.class } id="btn-undo"
                      onMouseEnter={(e) => this.onMouseEnter(e, 'select-undo')}
                      onMouseLeave={ () => this.onMouseLeave() }
                    title="Undo (step back)"
                    disabled={ btnClasses.undo.disabled || lockSmartViewChanges === true } onClick={ this._undo }/>

                  <button className={ activeRedo ? 'icon-v17-redo-selected' : 'icon-v17-redo' }
                      //className="btn-action mr-2" id="btn-redo"
                      onMouseEnter={(e) => this.onMouseEnter(e, 'select-redo')}
                          disabled={lockSmartViewChanges === true}
                      onMouseLeave={ () => this.onMouseLeave() }
                          title="Redo (step forward)"
                    onClick={ this._redo }/>
                </div>
                  <div className="wr-select-button">
                  <button className={this.state.renderSelectTool ? 'icon-v17-move-selected' : activeSelectTool ? 'icon-v17-move-selected' : 'icon-v17-move' }
                      //className={ btnClasses.select.class }
                    //id={ this.state.renderSelectTool ? 'btn-select-active' : 'btn-select' }
                    onMouseEnter={(e) => this.onMouseEnter(e, 'select-tool')}
                    onMouseLeave={ () => this.onMouseLeave() }
                    title="Selection Tool (selection tool)"
                          disabled={lockSmartViewChanges === true}
                    onClick={ (e) => this.chooseMethodRedacting('selectTool') }/>

                  <button className={this.state.renderSelectDraw ? 'icon-v17-draw-redaction-selected' : activeSelectDraw ? 'icon-v17-draw-redaction-selected' : 'icon-v17-draw-redaction'}
                      //className={ btnClasses.draw.class }
                    //id={ this.state.renderSelectDraw ? 'btn-draw-active' : 'btn-draw' }
                          onMouseEnter={(e) => this.onMouseEnter(e, 'select-draw')}
                          onMouseLeave={ () => this.onMouseLeave() }
                          disabled={lockSmartViewChanges === true}
                    title="Draw (draw redaction box)"
                    onClick={ (e) => this.chooseMethodRedacting('draw') }/>
                    <button className={isOcr ? 'icon-v17-text-selection icon-v17-text-selection-disabled' : this.state.renderSelectText ? 'icon-v17-text-selection-selected' : activeSelectText ? 'icon-v17-text-selection-selected' : 'icon-v17-text-selection' }
                      //className={ btnClasses.undo.class }
                      onMouseEnter={(e) => this.onMouseEnter(e, 'select-text')}
                      onMouseLeave={ () => this.onMouseLeave() }
                            disabled={lockSmartViewChanges === true}
                      onClick={isOcr ? (e) => this.disabledMethodRedacting('disabled') : (e) => this.chooseMethodRedacting('selectText') }
                      //id={ this.state.renderSelectText ? 'btn-text-select-active' : 'btn-text-select' }
                      title="Select Text (select text)"
                    />
                  </div>
                  <div className="wr-search-button">
                    <button
                        className={ `${ isOcr ? 'icon-v17-word-search icon-v17-word-search-disabled' : renderSearch ? 'icon-v17-word-search-selected' : activeSearchWord ? 'icon-v17-word-search-selected' : 'icon-v17-word-search' }` }
                        //id={ this.state.renderSearch ? 'btn-search-active' : 'btn-search' }
                        onMouseEnter={(e) => this.onMouseEnter(e, 'search-word')}
                        onMouseLeave={ () => this.onMouseLeave() }
                        disabled={lockSmartViewChanges === true}
                        title="Search (search for words or sentences)"
                        onClick={ isOcr ? (e) => this.disabledMethodSearch('disabled') : (e) => this.chooseMethodRedacting('search') }/>
                    { renderSearch ? <Icon type="up" className="search-arrow"/> : '' }
                    <button className={ isOcr ? 'icon-v17-annotate-1 icon-v17-annotate-1-disabled' : `${ this.state.viewAnnotationsRedaction ? 'icon-v17-annotate-selected' : activeAnnotation ? 'icon-v17-annotate-selected' : 'icon-v17-annotate-1'}` }
                        //className={ btnClasses.undo.class }
                        //id={ this.state.viewAnnotationsRedaction ? 'btn-message-active' : 'btn-message' }
                            title="Annotation (add annotation to redaction box)"
                            onMouseEnter={(e) => this.onMouseEnter(e, 'annotation')}
                            onMouseLeave={ () => this.onMouseLeave() }
                            disabled={lockSmartViewChanges === true}
                            onClick={ isOcr ? (e) => this.disabledAnswerToogle('disabled') : (e) => this.chooseMethodRedacting('annotation') }/>
                  </div>

                  {/*<button className="btn-action mr-2" id="btn-save" title="Save (save page)"*/}
                  {/*  onClick={ () => this.savePageOnButton() }/>*/}

                  {/*<button className="btn-action mr-2" id="btn-download"*/}
                  {/*  title="Download (download document)"*/}
                  {/*  onClick={ () => this.printDocument() }/>*/}

                  {/*<button className="btn-action mr-2" id="btn-eraser"*/}
                  {/*  title="Delete selection (delete selected redaction box)"*/}
                  {/*  onClick={this.removeObj}*/}
                  {/*  disabled={this.state.removeSelectedBtnDisabled}/>*/}

                  {/*<button className={btnClasses.clear.class} id="btn-clear"*/}
                  {/*  title="Clear page (clear all redaction boxes and annotations on page)"*/}
                  {/*  disabled={btnClasses.clear.disabled} onClick={this._clear}/>*/}
                  <div className='wr-switch-redact'>
                    {/*<Switch className="mr-2 audit-redacting-switch" onChange={this.onAnswerToogle}*/}
                    {/*  checked={this.state.redactingMode}*/}
                    {/*  checkedChildren="Redacting" unCheckedChildren="Preview"/>*/}
                    <button className={ activePreview ? 'icon-v17-preview-selected-1' : this.state.redactingMode ? 'icon-v17-preview' :
                        activePreview ? 'icon-v17-preview-selected-1' : 'icon-v17-preview-selected-1' }
                                    onMouseEnter={(e) => this.onMouseEnter(e, 'preview')}
                            //disabled={previewPageDisabled === false ? true : btnClasses.preview.disabled }
                            onMouseLeave={ () => this.onMouseLeave() }
                            disabled={lockSmartViewChanges === true}
                            onClick={this.props.isOcr ? (e) => this.onUpdateAnswerToogleOcr(e, !this.state.redactingMode) :
                                (e) => this.onUpdateAnswerToogle(e, !this.state.redactingMode) }>

                    </button>

                    {/*<button className={ isOcr ? 'icon-v17-annotate-1 icon-v17-annotate-1-disabled' : `${ this.state.renderCommentsText ? 'icon-v17-annotate-selected' : activeComments ? 'icon-v17-annotate-selected' : 'icon-v17-annotate-1'}` }*/}
                    {/*        title="Comments"*/}
                    {/*        onMouseEnter={(e) => this.onMouseEnter(e, 'comments')}*/}
                    {/*        onMouseLeave={ () => this.onMouseLeave() }*/}
                    {/*        onClick={ isOcr ? (e) => this.disabledAnswerToogle('disabled') : (e) => this.chooseMethodRedacting('comments') }/>*/}

                    {/*<Switch onChange={this.onAnnotationToogle} checked={printAnnotation}*/}
                    {/*  title="Print with annotation"*/}
                    {/*  className="annotation-switch"*/}
                    {/*  checkedChildren="Annotate" unCheckedChildren="No annotate"/>*/}
                  </div>
                </div>
              </div>
              {/*{ finalizeLoading ?*/}
              {/*  <div className="finalize-loading-container">*/}
              {/*    <Spin className="finalize-loading" spinning={ finalizeLoading }/>*/}
              {/*  </div>*/}
              {/*  :*/}
              {/*  <button className={ finalizedDocPath && statusSelectAgainRedacted ? 'finalize-button button-green again-finalize-button' : 'finalize-button button-green' }*/}
              {/*    onClick={ () => this.finalizeRedacting() }>{ !finalizedDocPath && finalizedDocPath ? 'UPDATE REDACTED VERSION' : finalizedDocPath && statusSelectAgainRedacted ? 'SELECTED AGAIN UPDATE REDACTED VERSION' : 'CREATE REDACTED VERSION'}</button> */}
              {/*}*/}
              <div className="content-option-smartview">
                {/*<div className="content-status-file content-status-classification">*/}
                {/*  <Select*/}
                {/*      placeholder="Classification"*/}
                {/*      className='custom-status-holder'*/}
                {/*      defaultValue={ valueStatusClassification === '' || valueStatusClassification === null ? 'Classification' : valueStatusClassification }*/}
                {/*      onChange={(e) => this.updateValueClassification(e)}*/}
                {/*  >*/}
                {/*    <Option className="item-options-status" key={1} value="needs-review">*/}
                {/*      Needs review*/}
                {/*    </Option>*/}
                {/*    <Option className="item-options-status" key={2} value="relevant">*/}
                {/*      Relevant*/}
                {/*    </Option>*/}
                {/*    <Option className="item-options-status" key={3} value=" not-relevant">*/}
                {/*      Not relevant*/}
                {/*    </Option>*/}
                {/*    <Option className="item-options-status" key={4} value="privileged">*/}
                {/*      Privileged*/}
                {/*    </Option>*/}
                {/*  </Select>*/}
                {/*</div>*/}
              <div className="content-status-file">

                <Select
                  //value={ valueStatusFile === '' ? 'File Status' : valueStatusFile }
                 /* value={ <span className="content-value-status"><span>{ valueStatusFile }</span>*/
                  // value={ <span className="content-value-status"><span>{ valueStatusFile === 'For review' ? 'for review' : valueStatusFile }</span>
                  //   <span className={visibleDropdownStatus ? "icon-v19-arrow-medium icon-v19-arrow-medium-rotate" : 'icon-v19-arrow-medium' }></span></span>
                    disabled={ lockSmartViewChanges === true }
                    value={ <span className="content-value-status">
                  { selectTag.icon && selectTag.icon.includes('v211') ?
                      <span style={{backgroundColor: selectTag.color}} className={selectTag.icon}></span> :
                      selectTag.color && selectTag.color.includes('#') ?
                          <span style={{backgroundColor: selectTag.color}} className={'icon-color'}></span> :
                          <span><Icon type={selectTag.icon} /></span>
                  }
                      <span>{ Object.keys(selectTag).length === 0 ? 'Select' : selectTag.tagName === '' ? 'Select' : selectTag.tagName }</span></span> }
                  //placeholder="File Status"
                  dropdownClassName="file-status-dropdown"
                  dropdownMenuStyle={{overflowY:'auto', overflowX:'hidden',}}
                  showArrow={ false }
                  className={ valueStatusFile === '' || valueStatusFile === null ? 'custom-status-holder' : 'custom-status-select' }
                  onChange={ (e) => this.updateValueStatus(e) }
                  onDropdownVisibleChange={(e) => this.onDropdownVisibleChange(e)}
                >
                  {
                    customerStatus && customerStatus.length > 0 && customerStatus.map((item, index) => {
                      return (
                          <Option className="item-options-status-new" key={index} value={item.tagName}>
                            { item.icon && item.icon.includes('v211') ?
                                <span style={{backgroundColor: item.color ? item.color : 'icon-color'}}
                                      className={item.icon ? item.icon : 'icon-color'}></span> :
                                item.color ?
                                    <span style={{backgroundColor: item.color ? item.color : 'icon-color'}}
                                          className={item.icon ? item.icon : 'icon-color'}></span> :
                                    <span><Icon type={item.icon} /></span>
                            }
                            <span className={'item-tagName'}>{item.tagName.length > 17 ? item.tagName.substring(0,15) + '...' : item.tagName}</span>
                          </Option>
                      )
                    })
                  }
                 {/* <Option className="item-options-status" key={1} value="redacted">*/}
                 {/* <Option className="item-options-status" key={4} value="none">*/}
                 {/*   None*/}
                 {/* </Option>*/}
                 {/* <Option className="item-options-status" key={1} value="for review">*/}
                 {/*   <span className="icon-v10-tool-eraser-neg icon-status" />*/}
                 {/*   For review*/}
                 {/* </Option>*/}
                 {/* <Option className="item-options-status" key={2} value="approved">*/}
                 {/*   <span className="icon-v10-approved-1 icon-status" />*/}
                 {/*   Approved*/}
                 {/* </Option>*/}
                 {/* <Option className="item-options-status" key={3} value="sent">*/}
                 {/*   <span className="icon-v10-email icon-status" />*/}
                 {/*   Sent*/}
                 {/* </Option>*/}
                </Select>
              </div>
              </div>
            </div>
            { showCloseButton ?
              <div className="close-icon">
                {/*<Icon title="Close redaction mode" type="close"/>*/}
                <span className="icon-v17-close-black" onClick={ this.closeRedacting }></span>
              </div> : <div className="close-icon"/>
            }
            <div className={ landscape ? 'redacting__page-landscape' : 'redacting__page-portrait redacting-block' }
              style={ { overflow: documentNoteVisible ? 'hidden' : 'auto' } }>
              { printLoading || finalizeLoading ?
                <Spin className="print-loading" spinning={ printLoading || finalizeLoading }
                  tip={ printLoading ? `Prepare for downloading ${ totalPages } pages, please wait up to
                      ${ this.calculatePrintingTime(totalPages) } ${ totalPages > 60 ? 'minutes' : 'seconds' }` :
                    `Prepare for finalizing ${ totalPages } pages, please wait up to
                      ${ this.calculatePrintingTime(totalPages) } ${ totalPages > 60 ? 'minutes' : 'seconds' }` }/> :
                '' }
              { fileWarnings && fileWarnings.length > 0 &&
                <div className="contain-warnings-report">
                <span className="contain-text">
                <span className="icon-v23-warning-1"></span>
                <span className="content-text-warning">One or more errors have been detected in the original PDF file. This may cause issues with alignment of highlights and redactions.</span>
                </span>
                  <span className="link-text-warning" onClick={ () => window.open('https://support.smartbox.ai/support/solutions/articles/47001260647', '_blank') }>Learn more</span>
                </div>
              }
              {
                lockSmartViewChanges &&
                    <div className="contain-warnings-report">
                <span className="contain-text">
                <span className="icon-v23-warning-1"></span>
                <span className="content-text-warning">This document is already opened by another user, changes are disabled.</span>
                </span>
                    </div>
              }
              <div className={ landscape ? 'redacted-page-landscape' : 'redacted-page-portrait' }>
                <div className="redacted-container" style={ { width: width, height: height } }>
                  <div className="redacted-section">
                    { disableClickOnPage ? <div className="disabled-clicked-page"/> : '' }
                    { documentNoteVisible ?
                      <DocumentNote closeDocumentNote={ this.closeDocumentNote }
                        documentId={ this.props.documentId }
                        isSmartBoxApplication={ this.props.isSmartBoxApplication }
                        addNotifyDocumentNote={ this.addNotifyDocumentNote }
                                    documentNoteGet={ documentNoteGet }
                      />
                      :
                      '' }
                    <div id="pdf-page" style={ { width: width, display: documentNoteVisible ? 'none' : 'block' } }>
                      { !isDocumentReady &&
                        <div className="content-spinning-render-pages"><Spin tip="Loading"></Spin></div>
                      }
                      <Spin className="custom-spin spin-over-pages" spinning={ loadingBeforeMark && !isOcr } tip={loadingBeforeMark && !isOcr ? 'Please wait while annotations are being marked on document!' : ''}></Spin>
                      { totalPages ?
                        this.renderPages(totalPages, canvasData, forPageNumber, tool, pdfBytes, isDocumentLoaded, divPrint, height, width, scale, viewAnnotationsRedaction, renderSelectDraw, renderSelectText, drawByHandText, clearSketch, isOcr) :
                        '' }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            { this.updateRenderAnnotationsList(children, canvasModalText) }
          </div>
        {/*</Spin>*/}
        { annotateSelectedModalVisible &&
          <AnnotateSelectedRedaction itemsSelected={itemsSelected} documentId={idDoc} numberOfAnnotateSelected={numberOfAnnotateSelected} collapseAnnotateSelectModal={this.collapseAnnotateSelectModal} updateCanvasText={this.updateCanvasText}/>
        }
      </React.Fragment>
    )
  }
}

export default RedactionTool
