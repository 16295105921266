import { Link } from 'react-router-dom'
import React, { Component } from 'react'
import SubjectsList from './SubjectsList'
import CardList from './CardList'
import LineChart from './LineChart'
import HeaderTicket from './HeaderTicket'
import HeaderWorkflowStep from './HeaderWorkflowStep'
// import Button from '../../../../Common/Button'
import { apiFetchByFilter, apiSarDeleteRequest } from '../../../../../api/Sar'
import '../../../../../style/common.scss'
import './Dashboard.scss'
import { connect } from 'react-redux'
import { Checkbox, Input, Select, Tooltip, Button } from 'antd'
import { customConfirm } from '../../../../../utils/AssetRegister/CustomConfirm'
import { setSarPageLimit } from '../../../../../actions/sarActions'
import CustomNotificationManager from '../../../../Common/CustomNotificationManager';
import withRouter from '../../../../../private-routers/withRouter'
import NewTicketSettingsModal from "./NewTicketSettingsModal";
import NewTicketSettingsPage from "./NewTicketSettingsPage";
import DisclosureWorkflow from "./DisclosureWorkflow";
import FormEmployeeWorkflow from "./FormEmployeeWorkflow";
import EmailAcknowledgedWorkflow from "./EmailAcknowledgedWorkflow";
import DataCollectionWorkflow from "./DataCollectionWorkflow";
import AdditionalInformationWorkflow from "./AdditionalInformationWorkflow";
import InternalEmailNotificationWorkflow from "./InternalEmailNotificationWorkflow";
import imgBoxEmpty from '../../../../../assets/box/box_empty_20x20.png';
import imgBoxProcessing from '../../../../../assets/box/box_processing_20x20.png';
import imgBox from '../../../../../assets/box/box_20x20.png';
import {
  getPostTicketWorkflowBoxStatusDashboard,
  getTicketWorkflowAndHeaderDetails,
  getTicketWorkflowBoxStatisticsDashboard,
  getTicketWorkflowBoxStatusDashboard, getTicketWorkflowFiltersDashboard,
  getTicketWorkflowListDashboard, downloadDashboardWorkflow
} from "../../../../../api/SmartBox";
import variables from './Dashboard.scss'
const { Search } = Input
const { Option } = Select

export class DashboardWorkflow extends Component {
  constructor() {
    super()
    this.state = {
      isLoading: true,
      showLearnMessage: true,
      sars: [],
      accordion: {},
      name: null,
      step: null,
      complete: null,
      time: null,
      pageNumber: 1,
      amountPerPage: 2,
      totalElements: null,
      archived: false,
      // itemsPerPageLimit: 10,
      itemsPerPageLimitOptions: [20, 50, 100],
      selectPageLimitValue:20,
      isPrevDisabled: true,
      isNextDisabled: true,
      totalRecords: 0,
      currentPage: 1,
      showInsertPageNumber: false,
      sortCriteria: { column: 'created', order: 'desc' },
      filterCriteria: [],
      filterChanged: false,
      listKeysSar: [],
      selectSarRowTable: {},
      checkSelectAllSar: false,
      checkBoxSelectAll: false,
      onHoverDelete: false,
      onStateStart: false,
      onStateDownload: false,
      onStateEnd:false,
      onHoverShow:false,
      onHoverBackBtn:false,
      onHoverNextBtn:false,
      loadingBtnNewTicket:false,
      visibleNewTicket: false,
      searchTicketList: '',
      dataSourceTicket: [],
      dataSourceTicketBoxStatus: [],
      statisticsDashboard: {},
      ticketStatus: 'Open',
      statusModalNewTickets: '',
      selectEditTickets: {},
      selectHeaderTickets: {},
      statusDisplayDisclosure: false,
      stepHeaderActive: '',
      formTicketWorkflow: {},
      ticketWorkflowStepDTOS: [],
      formId: null,
      listComponentsTemplateFormProps: [],
      statusSaveComponentFromWorkflow: false,
      activeStepProps: {},
      startSaveFormSectionsData: null,
      statusResponseSaveFormSectionsData: null,
      nextLast: false,
      backFirst: true,
      statusDisplayHeader: false,
      indexStartHeaderDetails: null,
      orderBy: null,
      orderType: null,
      ownerListFilter: [],
      workflowNames: [],
      timeframes: [],
      boxStatusList: [],
      filtersMap: new Map(),
      filtersList: [],
      stepTicketActive: {},
      ticketCompleted: false,
      startFirstStepTicket: false,
      activeFirstStepTicket: {},
      activeOpenEditTickets: false,
      validateFormComponent: false,
      checkMandatoryForm: null,
      checkMandatoryFormShort: null,
      checkMandatoryFormLong: null,
      checkMandatoryFormAddress: null,
      checkMandatoryFormEmail: null,
      checkMandatoryFormPhone: null,
      checkMandatoryFormRadio: null,
      checkMandatoryFormDropdown: null,
      checkMandatoryFormMultiple: null,
      checkMandatoryFormSignature: null,
      checkMandatoryFormUpload: null,
      checkedRequestorDetails: false,
      selectTicketTableDashboard: {},
      loadingGetListDashboard: false
    }
  }

  componentDidMount() {
    this.onGetTicketWorkflowListDashboard()
    this.onGetTicketWorkflowBoxStatisticsDashboard()
    const { primary, secondary } = variables
    console.log('15Aug')
  }

  onGetTicketWorkflowListDashboard = () => {
    const {currentPage, selectPageLimitValue, searchTicketList, ticketStatus, orderBy, orderType, filtersMap, filtersList } = this.state
    const createdMap = new Map();
    filtersList.map((obj) => {
      createdMap.set(obj.nameColumns, obj.nameWorkflow);
    })
    const mapObjectFilters = Object.fromEntries(filtersMap)
    const getTicketsWorkflowDashboardRequestDTO = {
      pageNumber: currentPage,
      pageRows: selectPageLimitValue,
      ticketStatus: ticketStatus,
      search: searchTicketList,
      orderBy: orderBy,
      orderType: orderType,
      filtersMap: mapObjectFilters,
    }
    this.setState({
      loadingGetListDashboard: true
    }, () => {
      getTicketWorkflowListDashboard(getTicketsWorkflowDashboardRequestDTO).then((response) => {
        this.setState({
          //dataSourceTicket: response.data.ticketWorkflowDashboardDTOList,
          searchTicketList: '',
          totalRecords: response.data.totalRows
        }, () => {
          this.onGetTicketWorkflowBoxStatusDashboard(response.data.ticketWorkflowDashboardDTOList)
        })
      })
    })
  }
  onGetTicketWorkflowBoxStatusDashboard = (dataSourceTicket) => {
    const {currentPage, selectPageLimitValue, searchTicketList, ticketStatus, orderBy, orderType, filtersMap } = this.state
    const mapObjectFilters = Object.fromEntries(filtersMap)
    const getTicketsWorkflowDashboardRequestDTO = {
      pageNumber: currentPage,
      pageRows: selectPageLimitValue,
      ticketStatus: ticketStatus,
      search: searchTicketList,
      orderBy: orderBy,
      orderType: orderType,
      filtersMap: mapObjectFilters,
    }
    getPostTicketWorkflowBoxStatusDashboard(getTicketsWorkflowDashboardRequestDTO).then((response) => {
      this.setState({
        dataSourceTicketBoxStatus: response.data
      })
      dataSourceTicket && dataSourceTicket.length > 0 && dataSourceTicket.forEach((list1) => {
        response.data && response.data.length > 0 && response.data.forEach((list2) => {
           if (list1.ticketId === list2.ticketId) {
             list1.boxStatus = list2.boxStatus
             list1.ticketDonePercent = list2.donePercentage
           }
        })
      })
      this.setState({
        dataSourceTicket: dataSourceTicket,
        loadingGetListDashboard: false
      }, () => {
        this.updatePreviewNextPageNumber()
        this.onGetTicketWorkflowFiltersDashboard()
      })
    })
  }

  onGetTicketWorkflowFiltersDashboard = () => {
    const { ticketStatus } = this.state
    getTicketWorkflowFiltersDashboard(ticketStatus).then((response) => {
      const ownerListFilter = []
      const workflowNames = []
      const timeframes = []
      const boxStatusList = []
      if (response.data.ownerListFilter && response.data.ownerListFilter.length > 0) {
        response.data.ownerListFilter.forEach((item) => {
          ownerListFilter.push({
            text: item,
            value: item,
          })
        })
          this.setState({
            ownerListFilter
          })
      } else {
        ownerListFilter.push({
          text: '',
          value: '',
        })
        this.setState({
          ownerListFilter
        })
      }

      if (response.data.workflowNames && response.data.workflowNames.length > 0) {
        response.data.workflowNames.forEach((item) => {
          workflowNames.push({
            text: item,
            value: item,
          })
        })
        this.setState({
          workflowNames
        })
      } else {
        workflowNames.push({
          text: '',
          value: '',
        })
        this.setState({
          workflowNames
        })
      }

      if (response.data.timeframes && response.data.timeframes.length > 0) {
        //response.data.timeframes.push('None')
        response.data.timeframes.forEach((item) => {
          timeframes.push({
            text: (<span className={'color-time-frame-wrapper'}> <span className={item === "None" ? 'color-time-frame no-color' : item === 'Disclosed' ? 'color-time-frame no-color' : 'color-time-frame'} style={{backgroundColor: item === "White" ? 'white':
                  item === "Yellow" ? '#FFB803' : item === "Orange" ? " #F74006" : item === "Black" ? "#000000" : "" }}></span> <span>{item === 'Disclosed' ? item :
                item === 'None' ? 'None' : ''}</span>
              </span>),
            value: item,
          })
        })
        this.setState({
          timeframes
        })
      } else {
        timeframes.push({
          text: '',
          value: '',
        })
        this.setState({
          timeframes
        })
      }


      if (response.data.boxStatusList && response.data.boxStatusList.length > 0) {
        response.data.boxStatusList.forEach((item) => {
          boxStatusList.push({
            text: (<span className={ item === "Box does not exists" ? 'box-icon-empty-filter-option' : ""}>
              <img src={
                item === "Box does not exists" ? imgBoxEmpty :
                item ===  "Box does not contains data" ? imgBoxEmpty :
                item ===  "Box contains data" ? imgBoxProcessing :
                item === "Box contains data and workflow is disclose" ? imgBox : imgBoxEmpty} /></span>),
            value: item,
          })
        })
        this.setState({
          boxStatusList
        })
      } else {
        boxStatusList.push({
          text: '',
          value: '',
        })
        this.setState({
          boxStatusList
        })
      }


    })
  }
  onGetSorterColumnTickets = (column, sorter) => {
     this.setState({
       orderBy: column,
       orderType: sorter,
     }, () => {
       this.onGetTicketWorkflowListDashboard()
     })
  }

  onGetFiltersColumnTickets = (filters, extra) => {
    const ticketOwnerName = []
    const ticketWorkflowName = []
    const ticketTimeFrame = []
    const box = []
    let allList = []
    if (filters.ticketOwnerName && filters.ticketOwnerName.length > 0) {
      ticketOwnerName.push({
        nameColumns: 'owner',
        nameWorkflow: filters.ticketOwnerName,
      })
    }
    if (filters.ticketWorkflowName && filters.ticketWorkflowName.length > 0) {
      ticketWorkflowName.push({
        nameColumns: 'workflow',
        nameWorkflow: filters.ticketWorkflowName,
      })
    }

    if (filters.ticketTimeFrame && filters.ticketTimeFrame.length > 0) {
      ticketTimeFrame.push({
        nameColumns: 'timeFrame',
        nameWorkflow: filters.ticketTimeFrame,
      })
    }

    if (filters.box && filters.box.length > 0) {
      box.push({
        nameColumns: 'box',
        nameWorkflow: filters.box,
      })
    }
    allList = ticketOwnerName.concat(ticketWorkflowName, ticketTimeFrame, box)
    if (allList && allList.length > 0) {
      const result = new Map(allList.map(i => [i.nameColumns, i.nameWorkflow]))
      this.setState({
        filtersMap: new Map(allList.map(i => [i.nameColumns, i.nameWorkflow])),
        filtersList: allList
      }, () => {
        this.onGetTicketWorkflowListDashboard()
      })
    } else {
      this.setState({
        filtersMap: new Map(),
        filtersList: allList
      }, () => {
        this.onGetTicketWorkflowListDashboard()
      })
    }

  }
  onGetTicketWorkflowBoxStatisticsDashboard = () => {
    const ticketStatus = 'Open'
    const year = 2024
    getTicketWorkflowBoxStatisticsDashboard(ticketStatus, year).then((response) => {
       this.setState({
         statisticsDashboard: response.data
       })
    })
  }
  updatePreviewNextPageNumber = () => {
    const { totalRecords, currentPage } = this.state
    const totalPages = Math.ceil(totalRecords / this.state.selectPageLimitValue)
    const isPrevDisabled = currentPage > 1 ? false : true
    const isNextDisabled = currentPage < totalPages ? false : true
    this.setState({ isPrevDisabled, isNextDisabled, currentPage })
  }

  setFilterAndSort = (sortCriteria, filterCriteria) => {
    const { currentPage } = this.state
    let { filterChanged } = this.state

    if (currentPage > 1) {
      filterChanged= true
    }
    this.setState({ sortCriteria, filterCriteria, filterChanged })
  }

  setFilter = (filterCriteria) => {
    const { currentPage } = this.state
    let { filterChanged } = this.state
    if (currentPage > 1) {
      filterChanged= true
    }
    this.setState({ filterCriteria, filterChanged })
  }

  updateFilterSearch(e) {
    const searchText = e.target.value
    if (searchText !== '' && searchText.length > 2) {
      const timeout = null
      clearTimeout(timeout)
    } else if (searchText === '') {
    }

    this.setState({
      name: searchText,
      pageNumber: 1
    })
  }

  updateValue = (e, field) => {
    const value = e
    if (field === 'time') {
      this.setState({
        time: Number(value),
        pageNumber: 1
      })
    }

    if (field === 'step') {
      this.setState({
        step: Number(value),
        pageNumber: 1
      })
    }

    if (field === 'complete') {
      this.setState({
        complete: value,
        pageNumber: 1
      })
    }
  }

  changePagination = (page) => {
    this.setState({ currentPage: page, inputPage: page })
  }

  changeItemsPerPageLimit = (e) => {
    // this.setState({ filterChanged: true }, () => {
      // this.props.setSarPageLimit(e)
    // })
    this.setState({
      selectPageLimitValue:e
    }, () => {
      this.onGetTicketWorkflowListDashboard()
    })
  }

  handleMovePage = (e, direction) => {
    const { isPrevDisabled, isNextDisabled, totalRecords, selectPageLimitValue } = this.state
    switch (direction) {
      case 'first':
        e.preventDefault()
        if (!isPrevDisabled) {
          this.gotoPage(1)
        }
        break
      case 'previous':
        e.preventDefault()
        if (!isPrevDisabled) {
          this.gotoPage(this.state.currentPage - 1 ? this.state.currentPage - 1 : 1)
        }
        break
      case 'next':
        e.preventDefault()
        if (!isNextDisabled) {
          this.gotoPage(this.state.currentPage + 1 > Math.ceil(totalRecords / selectPageLimitValue) ? Math.ceil(totalRecords / selectPageLimitValue) : this.state.currentPage + 1)
        }
        break
      case 'last':
        e.preventDefault()
        if (!isNextDisabled) {
          this.gotoPage(Math.ceil(totalRecords/selectPageLimitValue))
        }
        break
      default:
        e.preventDefault()
        this.setState({ showInsertPageNumber: true })
    }
  }

  gotoPage = (newPage) => {
    const { totalRecords, selectPageLimitValue, currentPage } = this.state
    const totalPages = Math.ceil(totalRecords / selectPageLimitValue)
    newPage = Math.max(0, Math.min(newPage, totalPages))
    const isNextDisabled = currentPage < totalPages ? false : true
    this.setState({ currentPage: newPage, isNextDisabled, inputPage: newPage }, () => {
      this.onGetTicketWorkflowListDashboard()
    })
  }

  handleInputPageChange = (e) => this.setState({ inputPage: e.target.value })

  handleInputPageEnter = (e) => {
    if (e.key === 'Enter') {
      this.handleInputPageBlur()
    }
  }

  handleInputPageBlur = () => {
    const { inputPage, selectPageLimitValue } = this.state
    if (inputPage) {
      const paginationEnd = Math.ceil(this.state.totalRecords / selectPageLimitValue)
      if (inputPage > 0 && inputPage <= paginationEnd) {
        this.gotoPage(inputPage)
      } else {
        this.gotoPage(1)
        this.setState({ inputPage: 1 })
      }
    }
  }
  onSelectAll = (e) => {
    const { sars } = this.state
    if (e.target.checked) {
      this.setState({
        checkSelectAllSar: true
      })
    } else {
      this.setState({
        checkSelectAllSar: false
      })
    }
    this.setState({
      listKeysSar: e.target.checked ? sars.map((sar) => sar.id) : [],
      checkBoxSelectAll: e.target.checked
    })
  }
  onSelectSarRow = (sar) => {
    this.setState({
      selectSarRowTable: sar
    })
  }
  handleDeleteRequestDashboard = (e) => {
    const { listKeysSar, selectSarRowTable } = this.state
    if ((listKeysSar && listKeysSar.length > 0) || (selectSarRowTable && selectSarRowTable.id)) {
      customConfirm({
        title: 'Do you want to delete request?',
        content: ``,
        confirmButtonText: 'DELETE',
        cancelButtonText: 'CANCEL',
        mask: true,
        maskClosable: false,
        closeHaveLogic: false,
        className: 'asset-register-modal',
        centered: true,
        cancelClassName: 'cancel-button',
        confirmClassName: 'confirm-button',
        confirmAction: () => {
          this.onClickDeleteAllSelectSar(e)
        }
      })
    }
  }

  onClickDeleteAllSelectSar(e) {
    const { listKeysSar, selectSarRowTable, sars } = this.state
    const selectDeleteSar = []
    selectDeleteSar.push(selectSarRowTable.id)
    const deleteSarDTO = {
      sarIds: listKeysSar && listKeysSar.length > 0 ? sars.map((sar) => sar.id) : selectDeleteSar && selectDeleteSar.length > 0 ? selectDeleteSar : []
    }
    // apiSarDeleteRequest(deleteSarDTO).then(() => {
    //   this.setState({
    //     selectSarRowTable: {},
    //     checkBoxSelectAll: false,
    //     listKeysSar: [],
    //     checkSelectAllSar: false
    //   })
    // }).catch((error) => {
    //   if (error?.response?.status === 504) {
    //     CustomNotificationManager.cleanBuffer('warning', '')
    //   }else{
    //     console.log(error)
    //   }
    // })
  }
  onMouseEnterOnPanel(e, status) {
    if (status === 'delete') {
      this.setState({
        onHoverDelete: true
      })
    }
  }
  onMouseLeaveOnPanel(e, status) {
    this.setState({
      onHoverDelete: false,
    })
  }
  onMouseEnterAction = (e, state) => {
    switch (state) {
      case 'start':
        this.setState({
          onStateStart: true,
        })
        break
      case 'end':
        this.setState({
          onStateEnd: true,
        })
        break
      case 'show':
        this.setState({
          onHoverShow: true,
        })
        break
      case 'previous':
        this.setState({
          onHoverBackBtn: true,
        })
        break
      case 'next':
        this.setState({
          onHoverNextBtn: true,
        })
        break
      case 'download':
        this.setState({
          onStateDownload: true,
        })
        break
      default: break
    }
  }
  onMouseLeaveAction = () => {
    this.setState({
      onStateStart: false,
      onStateEnd: false,
      onHoverShow:false,
      onHoverBackBtn: false,
      onHoverNextBtn:false,
      onStateDownload:false,
    })
  }
  openSarTrackerPage = () => {
    this.setState({
      loadingBtnNewTicket:true,
      statusModalNewTickets: 'new',
      //selectHeaderTickets: {}
    }, () => {
      this.setState({
        loadingBtnNewTicket: false,
        visibleNewTicket:true,
        selectEditTickets: {},
      })
    })
        // () => this.props.navigate('/sar/tracker-page')

  }
  onCloseNewTickets = (status) => {
    this.setState({
      visibleNewTicket: status,
      stepHeaderActive: '',
      activeOpenEditTickets: false
    })
  }
  onSearchTicket = (e) => {
    this.setState({
      searchTicketList: e
    }, () => {
      this.onGetTicketWorkflowListDashboard()
    })
  }
  onEditModalTickets = (record) => {
    this.setState({
      statusModalNewTickets: 'edit'
    }, () => {
      this.onGetTicketWorkflowAndHeaderDetails(record)
    })
    // this.setState({
    //   loadingBtnNewTicket:true,
    //   visibleNewTicket:true,
    //   statusModalNewTickets: 'edit'
    // })
  }

  onEditFirstStepModalTickets = (record) => {
    const { selectHeaderTickets, stepHeaderActive, statusDisplayHeader } = this.state
    let stepHeaderActiveNext = {}
    let indexStart = null
    //this.onDisplayHeaderStep()
    this.setState({
      startFirstStepTicket: true,
      statusModalNewTickets: 'edit',
      selectTicketTableDashboard: record
    })
    getTicketWorkflowAndHeaderDetails(record.ticketId).then((response) => {
      if (response.data && response.data.ticketWorkflowStepDTOS && response.data.ticketWorkflowStepDTOS.length > 0) {
        //this.onDisplayHeaderStep(true, response.data.ticketWorkflowStepDTOS[0])
        let firstStepFalse = null
        response.data.ticketWorkflowStepDTOS.forEach((ticket, index) => {
          //ticket.blockFormEdit = true
          if (firstStepFalse === null && (ticket.stepCompleted === null || ticket.stepCompleted === false)) {

            firstStepFalse = ticket
            indexStart = index
            this.setState({
              activeFirstStepTicket: response.data.ticketWorkflowStepDTOS[index],
            })
            this.setState({
              indexStartHeaderDetails: index,
              stepHeaderActiveNext: response.data.ticketWorkflowStepDTOS[index],
              stepHeaderActive: response.data.ticketWorkflowStepDTOS[index],
              stepTicketActive: response.data.ticketWorkflowStepDTOS[index],
            })
            if (index < response.data.ticketWorkflowStepDTOS.length - 1) {
              stepHeaderActiveNext = response.data.ticketWorkflowStepDTOS[index+1]
            } else if (index <= response.data.ticketWorkflowStepDTOS.length - 1) {
              stepHeaderActiveNext = response.data.ticketWorkflowStepDTOS[index-1]
            }
          } else if (ticket.stepCompleted) {
            indexStart = response.data.ticketWorkflowStepDTOS.length - 1
            this.setState({
              activeFirstStepTicket: response.data.ticketWorkflowStepDTOS[indexStart],
            })
            this.setState({
              indexStartHeaderDetails: indexStart,
              stepHeaderActiveNext: response.data.ticketWorkflowStepDTOS[indexStart],
              stepHeaderActive: response.data.ticketWorkflowStepDTOS[indexStart],
              stepTicketActive: response.data.ticketWorkflowStepDTOS[indexStart],
            })
            if (indexStart < response.data.ticketWorkflowStepDTOS.length - 1) {
              stepHeaderActiveNext = response.data.ticketWorkflowStepDTOS[indexStart+1]
            } else if (indexStart <= response.data.ticketWorkflowStepDTOS.length - 1) {
              stepHeaderActiveNext = response.data.ticketWorkflowStepDTOS[indexStart-1]
            }
          } else if (stepHeaderActive.stepComponentWorkflowId === ticket.stepComponentWorkflowId) {
            indexStart = index
            this.setState({
              activeFirstStepTicket: response.data.ticketWorkflowStepDTOS[0],
            })
            this.setState({
              indexStartHeaderDetails: index,
              stepHeaderActiveNext: response.data.ticketWorkflowStepDTOS[index],
              stepHeaderActive: response.data.ticketWorkflowStepDTOS[index],
              stepTicketActive: response.data.ticketWorkflowStepDTOS[index],
            })
            if (index < response.data.ticketWorkflowStepDTOS.length - 1) {
              stepHeaderActiveNext = response.data.ticketWorkflowStepDTOS[index+1]
            } else if (index <= response.data.ticketWorkflowStepDTOS.length - 1) {
              stepHeaderActiveNext = response.data.ticketWorkflowStepDTOS[index-1]
            }
          }
        })
        // this.setState({
        //   activeFirstStepTicket: response.data.ticketWorkflowStepDTOS[0]
        // })
        //this.onGetTicketWorkflowAndHeaderDetails(record)
        if (response.data) {
          this.setState({
            ticketCompleted: response.data.ticketCompleted
          })
        }
        //response.data.ticketWorkflowStepDTOS && response.data.ticketWorkflowStepDTOS.length > 0 && response.data.ticketWorkflowStepDTOS.forEach((ticket, index) => {
          // if (statusDisplayHeader && stepHeaderActive.stepComponentWorkflowId === ticket.stepComponentWorkflowId) {
          //   indexStart = index
          //   this.setState({
          //     indexStartHeaderDetails: index,
          //     stepHeaderActiveNext: response.data.ticketWorkflowStepDTOS[index],
          //     stepHeaderActive: response.data.ticketWorkflowStepDTOS[index],
          //     stepTicketActive: response.data.ticketWorkflowStepDTOS[index],
          //   })
          //   if (index < response.data.ticketWorkflowStepDTOS.length - 1) {
          //     stepHeaderActiveNext = response.data.ticketWorkflowStepDTOS[index+1]
          //   } else if (index <= response.data.ticketWorkflowStepDTOS.length - 1) {
          //     stepHeaderActiveNext = response.data.ticketWorkflowStepDTOS[index-1]
          //   }
          // }
        //})
        if (indexStart < response.data.ticketWorkflowStepDTOS.length - 1) {
          if (indexStart === response.data.ticketWorkflowStepDTOS.length - 2) {

          } else {
            this.setState({
              nextLast: false
            })
          }
          this.setState({

          }, () => {
            this.setState({
              backFirst: false
            })
          })
        } else if (indexStart !== 0 && indexStart <= response.data.ticketWorkflowStepDTOS.length - 1) {
          this.setState({

          }, () => {
            this.setState({
              nextLast: false
            })
          })
        } else {
          this.setState({
            nextLast: true,
            backFirst: true,
          })
        }


        this.setState({
          selectEditTickets: response.data,
          statusModalNewTickets: 'edit',
          selectHeaderTickets: response.data,
          ticketWorkflowStepDTOS: response.data.ticketWorkflowStepDTOS,
          //loadingBtnNewTicket:true,
        }, () => {
          this.setState({
            visibleNewTicket:true,
          })
        })
      }
    })
  }

  onGetTicketWorkflowAndHeaderDetails = (record) => {
    const { selectHeaderTickets, stepHeaderActive, statusDisplayHeader } = this.state
    let stepHeaderActiveNext = {}
    let indexStart = null
    getTicketWorkflowAndHeaderDetails(record.ticketId).then((response) => {
      if (response.data) {
        this.setState({
          ticketCompleted: response.data.ticketCompleted
        })
      }
      response.data.ticketWorkflowStepDTOS && response.data.ticketWorkflowStepDTOS.length > 0 && response.data.ticketWorkflowStepDTOS.forEach((ticket, index) => {
        //ticket.blockFormEdit = true
        if (statusDisplayHeader && stepHeaderActive.stepComponentWorkflowId === ticket.stepComponentWorkflowId) {
          indexStart = index
          this.setState({
            indexStartHeaderDetails: index,
            stepHeaderActiveNext: response.data.ticketWorkflowStepDTOS[index],
            stepHeaderActive: response.data.ticketWorkflowStepDTOS[index],
            stepTicketActive: response.data.ticketWorkflowStepDTOS[index],
          })
          if (index < response.data.ticketWorkflowStepDTOS.length - 1) {
            stepHeaderActiveNext = response.data.ticketWorkflowStepDTOS[index+1]
          } else if (index <= response.data.ticketWorkflowStepDTOS.length - 1) {
            stepHeaderActiveNext = response.data.ticketWorkflowStepDTOS[index-1]
          }
        }
      })
        if (statusDisplayHeader && indexStart < response.data.ticketWorkflowStepDTOS.length - 1) {
          if (indexStart === response.data.ticketWorkflowStepDTOS.length - 2) {

          } else {
            this.setState({
              nextLast: false
            })
          }
          this.setState({

          }, () => {
            this.setState({
              backFirst: false
            })
          })
        } else if (statusDisplayHeader && indexStart !== 0 && indexStart <= response.data.ticketWorkflowStepDTOS.length - 1) {
          this.setState({

          }, () => {
            this.setState({
              nextLast: false
            })
          })
        } else {
          this.setState({
            nextLast: true,
            backFirst: true,
          })
        }


       this.setState({
         selectEditTickets: response.data,
         statusModalNewTickets: 'edit',
         selectHeaderTickets: response.data,
         ticketWorkflowStepDTOS: response.data.ticketWorkflowStepDTOS,
      //loadingBtnNewTicket:true,
       }, () => {
         this.setState({
           visibleNewTicket:true,
         })
       })
    })
  }

  onGetTicketWorkflowAndHeaderDetailsNextBack = (record, status) => {
    const { selectHeaderTickets, stepHeaderActive, ticketWorkflowStepDTOS } = this.state
    let stepHeaderActiveNext = {}
    let indexStart = null
    getTicketWorkflowAndHeaderDetails(record.ticketId).then((response) => {
      response.data.ticketWorkflowStepDTOS && response.data.ticketWorkflowStepDTOS.length > 0 && response.data.ticketWorkflowStepDTOS.forEach((ticket, index) => {
        //ticket.blockFormEdit = true
        if ((stepHeaderActive.stepId && ticket.stepId && stepHeaderActive.stepId === ticket.stepId) || (stepHeaderActive.stepComponentWorkflowId === ticket.stepComponentWorkflowId)) {
                indexStart = index
                if (status === 'next' && index < response.data.ticketWorkflowStepDTOS.length - 1) {
                  stepHeaderActiveNext = response.data.ticketWorkflowStepDTOS[index+1]
                }
          if (status === 'back' && index <= response.data.ticketWorkflowStepDTOS.length - 1) {
            stepHeaderActiveNext = response.data.ticketWorkflowStepDTOS[index-1]
          }
              }
        if (((response.data.ticketWorkflowStepDTOS[index].stepId && stepHeaderActiveNext.stepId && response.data.ticketWorkflowStepDTOS[index].stepId === stepHeaderActiveNext.stepId) ||
                (response.data.ticketWorkflowStepDTOS[index].stepComponentWorkflowId === stepHeaderActiveNext.stepComponentWorkflowId))) {
          this.setState({
            indexStartHeaderDetails: index,
            stepHeaderActiveNext: response.data.ticketWorkflowStepDTOS[index]
          })
        }
      })
      if (status === 'next') {
        if (indexStart < response.data.ticketWorkflowStepDTOS.length - 1) {
          if (indexStart === response.data.ticketWorkflowStepDTOS.length - 2) {
            // this.setState({
            //   nextLast: true
            // })
          } else {
            this.setState({
              nextLast: false
            })
          }
          this.setState({
            selectEditTickets: response.data,
            statusModalNewTickets: 'edit',
            selectHeaderTickets: response.data,
            ticketWorkflowStepDTOS: response.data.ticketWorkflowStepDTOS,
            stepHeaderActive: stepHeaderActiveNext,
            stepTicketActive: stepHeaderActiveNext,
            //loadingBtnNewTicket:true,
          }, () => {
            this.setState({
              visibleNewTicket:true,
              backFirst: false
            })
          })
        } else {
          this.setState({
            nextLast: true,
          })
        }
      } else if (status === 'back') {
        if (indexStart !== 0 && indexStart <= response.data.ticketWorkflowStepDTOS.length - 1) {
          this.setState({
            selectEditTickets: response.data,
            statusModalNewTickets: 'edit',
            selectHeaderTickets: response.data,
            ticketWorkflowStepDTOS: response.data.ticketWorkflowStepDTOS,
            stepHeaderActive: stepHeaderActiveNext,
            stepTicketActive: stepHeaderActiveNext,
            //loadingBtnNewTicket:true,
          }, () => {
            this.setState({
              visibleNewTicket:true,
              nextLast: false
            })
            if ((stepHeaderActiveNext && stepHeaderActiveNext.stepId && ticketWorkflowStepDTOS[0].stepId && ticketWorkflowStepDTOS[0].stepId === stepHeaderActiveNext.stepId) ||
                (stepHeaderActiveNext && stepHeaderActiveNext.stepComponentWorkflowId && ticketWorkflowStepDTOS[0].stepComponentWorkflowId === stepHeaderActiveNext.stepComponentWorkflowId)) {
              this.setState({
                indexStartHeaderDetails: 0
              })
            }
          })
        } else {
          this.setState({
            backFirst: true,
            selectEditTickets: response.data,
            statusModalNewTickets: 'edit',
            selectHeaderTickets: response.data,
            ticketWorkflowStepDTOS: response.data.ticketWorkflowStepDTOS,
            stepHeaderActive: stepHeaderActiveNext,
            stepTicketActive: stepHeaderActiveNext,
          })
        }
      }
    })
  }


  onDisplayHeaderStep = (status, step) => {
    const { indexStartHeaderDetails, ticketWorkflowStepDTOS } = this.state
    if (ticketWorkflowStepDTOS && ticketWorkflowStepDTOS.length > 0 && ((step.stepComponentName)
    || (ticketWorkflowStepDTOS[0].stepComponentWorkflowId === step.stepComponentWorkflowId))) {
      this.setState({
        statusDisplayDisclosure: status,
        statusDisplayHeader: status,
        stepHeaderActive: step,
        stepTicketActive: step,
      })
    }
  }

  onGetFormId = (form) => {
     this.setState({
       formId: form.formId
     })
  }
  onGenerateHeaderSteps = (step) => {
    const { selectEditTickets, stepHeaderActive, selectHeaderTickets, statusSaveComponentFromWorkflow, activeStepProps, statusResponseSaveFormSectionsData, stepTicketActive } = this.state
     switch (step.stepComponentName) {
       case 'Form':
         return <FormEmployeeWorkflow onGetFormTicketWorkflow={ this.onGetFormTicketWorkflow } selectEditTickets={selectEditTickets}
                                      selectHeaderTickets={selectHeaderTickets} onStatusFormWorkflow={ this.onStatusFormWorkflow }
                                      stepHeaderActive={stepHeaderActive} onGetFormId={ this.onGetFormId }
                                      onGetAfterSaveComponentFromWorkflow={ this.onGetAfterSaveComponentFromWorkflow}
                                      statusSaveComponentFromWorkflow={ statusSaveComponentFromWorkflow }
                                      activeStepProps={ activeStepProps }
                                      onStartSaveFormSectionsData={ this.onStartSaveFormSectionsData}
                                      stepTicketActive={ stepTicketActive }
                                      statusResponseSaveFormSectionsData={ statusResponseSaveFormSectionsData }
                                      onGetTicketWorkflowAndHeaderDetails={ this.onGetTicketWorkflowAndHeaderDetails }/>
         break
       case 'Email':
         return <EmailAcknowledgedWorkflow stepHeaderActive={stepHeaderActive}  activeStepProps={ activeStepProps } selectHeaderTickets={selectHeaderTickets}
                                           onGetTicketWorkflowAndHeaderDetails={ this.onGetTicketWorkflowAndHeaderDetails }
                                           stepTicketActive={ stepTicketActive }
         />
         break
       case 'Web Form Email Template':
         return <EmailAcknowledgedWorkflow stepHeaderActive={stepHeaderActive}  activeStepProps={ activeStepProps } selectHeaderTickets={selectHeaderTickets}
                                           onGetTicketWorkflowAndHeaderDetails={ this.onGetTicketWorkflowAndHeaderDetails }
                                           stepTicketActive={ stepTicketActive }
         />
         break
       case 'Collector':
         return <DataCollectionWorkflow stepHeaderActive={stepHeaderActive}  activeStepProps={ activeStepProps } selectHeaderTickets={selectHeaderTickets}
                                        onGetTicketWorkflowAndHeaderDetails={ this.onGetTicketWorkflowAndHeaderDetails }
                                        stepTicketActive={ stepTicketActive }
         />
         break
       case 'Disclosure':
         return <DisclosureWorkflow selectEditTickets={selectEditTickets} selectHeaderTickets={selectHeaderTickets} stepHeaderActive={stepHeaderActive} onDisplayHeaderStep={ this.onDisplayHeaderStep }
                                    onGetTicketWorkflowAndHeaderDetails={ this.onGetTicketWorkflowAndHeaderDetails }
                                    stepTicketActive={ stepTicketActive }
         />
       break
       case 'Internal email notification':
         return <InternalEmailNotificationWorkflow stepHeaderActive={stepHeaderActive}  activeStepProps={ activeStepProps } selectHeaderTickets={selectHeaderTickets}
                                                   onGetTicketWorkflowAndHeaderDetails={ this.onGetTicketWorkflowAndHeaderDetails }
                                                   stepTicketActive={ stepTicketActive }
         />
         break
       case 'additional-information':
         return <AdditionalInformationWorkflow onGetTicketWorkflowAndHeaderDetails={ this.onGetTicketWorkflowAndHeaderDetails } stepTicketActive={ stepTicketActive }
                                               stepHeaderActive={stepHeaderActive}  activeStepProps={ activeStepProps } selectHeaderTickets={selectHeaderTickets}
         />
             break
       default: break
     }
  }
  onGetFormTicketWorkflow = (formWorkflow, listComponentsTemplateForm) => {
    this.setState({
      formTicketWorkflow: formWorkflow,
      listComponentsTemplateFormProps: listComponentsTemplateForm,
    }, () => {
      this.onMandatoryFormComponentsValidation(formWorkflow, listComponentsTemplateForm)
    })
  }
  onMandatoryFormComponentsValidation = (formWorkflow, listComponentsTemplateForm) => {
    const { validateFormComponent } = this.state
    this.setState({
      checkMandatoryForm: false,
      checkMandatoryFormShort: false,
      checkMandatoryFormLong: false,
    }, () => {
      listComponentsTemplateForm && listComponentsTemplateForm.length > 0 &&listComponentsTemplateForm.forEach((form) => {
        switch (form.sectionType) {
          case 'fullName' :
            if (form.mandatory) {
              if (form.firstNameValue && form.lastNameValue) {
                this.setState({
                  checkMandatoryForm: false
                })
              } else {
                this.setState({
                  checkMandatoryForm: true
                })
              }
            }
            break
          case 'short-text' :
            if (form.mandatory) {
              if (form.shortTextValue) {
                this.setState({
                  checkMandatoryFormShort: false
                })
              } else {
                this.setState({
                  checkMandatoryFormShort: true
                })
              }
            }
            break
          case 'long-text' :
            if (form.mandatory) {
              if (form.longTextValue) {
                this.setState({
                  checkMandatoryFormLong: false
                })
              } else {
                this.setState({
                  checkMandatoryFormLong: true
                })
              }
            }
            break
          case 'address' :
            if (form.mandatory) {
              if (form.streetAddressValue && form.street2AddressValue && form.cityAddressValue && form.stateAddressValue && form.postalCodeValue) {
                this.setState({
                  checkMandatoryFormAddress: false
                })
              } else {
                this.setState({
                  checkMandatoryFormAddress: true
                })
              }
            }
            break
          case 'email' :
            if (form.mandatory) {
              if (form.emailAddressValue) {
                this.setState({
                  checkMandatoryFormEmail: false
                })
              } else {
                this.setState({
                  checkMandatoryFormEmail: true
                })
              }
            }
            break
          case 'phone' :
            if (form.mandatory) {
              if (form.phoneNumber) {
                this.setState({
                  checkMandatoryFormPhone: false
                })
              } else {
                this.setState({
                  checkMandatoryFormPhone: true
                })
              }
            }
            break
          case 'radio' :
            if (form.mandatory) {
              if (form.options && form.options.length > 0 && form.singleChoice) {
                this.setState({
                  checkMandatoryFormRadio: false
                })
              } else if (form.options && form.options.length > 0) {
                this.setState({
                  checkMandatoryFormRadio: true
                })
              }
            }
            break
          case 'dropdown-select' :
            if (form.mandatory) {
              if (form.options && form.options.length > 0 && form.dropdown) {
                this.setState({
                  checkMandatoryFormDropdown: false
                })
              } else if (form.options && form.options.length > 0) {
                this.setState({
                  checkMandatoryFormDropdown: true
                })
              }
            }
            break
          case 'multiple-choice' :
            if (form.mandatory) {
              if (form.options && form.options.length > 0 && form.multipleChoice && form.multipleChoice.length > 0) {
                this.setState({
                  checkMandatoryFormMultiple: false
                })
              } else if (form.options && form.options.length > 0) {
                this.setState({
                  checkMandatoryFormMultiple: true
                })
              }
            }
            break
          case 'signature' :
            if (form.mandatory) {
              if (form.signature) {
                this.setState({
                  checkMandatoryFormSignature: false
                })
              } else {
                this.setState({
                  checkMandatoryFormSignature: true
                })
              }
            }
            break
          case 'upload' :
            if (form.mandatory) {
              if (form.fileSectionDTOS && form.fileSectionDTOS.length > 0) {
                this.setState({
                  checkMandatoryFormUpload: false
                })
              } else {
                this.setState({
                  checkMandatoryFormUpload: true
                })
              }
            }
            break
          default: break
        }
      })
    })

  }
  onStatusFormWorkflow = (status, ticketWorkflowFormId) => {
    this.setState({
      statusFormWorkflow: status,
      ticketWorkflowFormId: ticketWorkflowFormId,
    })
  }
  onGetAfterSaveComponentFromWorkflow = (status) => {
    this.setState({
      statusSaveComponentFromWorkflow: status
    })
  }
  onGetActiveStep = (activeStep) => {
    this.setState({
      activeStepProps: activeStep
    })
  }

  onStartSaveFormSectionsData = (startSaveFormSectionsData) => {
     this.setState({
       startSaveFormSectionsData: startSaveFormSectionsData
     })
  }
  onStatusResponseSaveFormSectionsData = (status) => {
    this.setState({
      statusResponseSaveFormSectionsData: status
    })
  }
  onOpenEditTickets = (selectHeaderTickets, activeOpenEditTickets) => {
    if (!this.state.activeOpenEditTickets || !activeOpenEditTickets) {
      this.setState({
        statusModalNewTickets: 'edit',
        stepHeaderActive: '',
        activeOpenEditTickets: !this.state.activeOpenEditTickets
      }, () => {
        this.onGetTicketWorkflowAndHeaderDetails(selectHeaderTickets)
      })
    } else {
      this.setState({
        statusModalNewTickets: 'edit',
        stepHeaderActive: this.state.stepHeaderActiveNext,
        activeOpenEditTickets: !this.state.activeOpenEditTickets
      }, () => {
        this.onGetTicketWorkflowAndHeaderDetails(selectHeaderTickets)
      })
    }
  }
  render()
  {
    const { sars, isLoading, itemsPerPageLimitOptions, isPrevDisabled, isNextDisabled, totalRecords,
      currentPage, inputPage, showInsertPageNumber, sortCriteria, filterCriteria, filterChanged, listKeysSar, selectSarRowTable, checkSelectAllSar,
      checkBoxSelectAll, onHoverDelete, onStateStart, onStateEnd, selectPageLimitValue, onHoverShow, onHoverBackBtn, onHoverNextBtn, loadingBtnNewTicket,
      visibleNewTicket, dataSourceTicket, statisticsDashboard, statusModalNewTickets, selectEditTickets, selectHeaderTickets, statusDisplayDisclosure,
      stepHeaderActive, formTicketWorkflow, statusFormWorkflow, ticketWorkflowFormId, ticketWorkflowStepDTOS, formId, listComponentsTemplateFormProps, startSaveFormSectionsData,
      nextLast, backFirst, indexStartHeaderDetails, stepHeaderActiveNext, ownerListFilter, workflowNames, timeframes, boxStatusList, filtersMap, stepTicketActive,
      ticketCompleted, startFirstStepTicket, activeFirstStepTicket, activeOpenEditTickets, checkMandatoryForm, checkMandatoryFormShort, checkMandatoryFormLong,
      checkMandatoryFormAddress, checkMandatoryFormEmail, checkMandatoryFormPhone, checkMandatoryFormRadio, checkMandatoryFormDropdown, checkMandatoryFormMultiple,
      checkMandatoryFormSignature, checkMandatoryFormUpload, checkedRequestorDetails, selectTicketTableDashboard, onStateDownload, loadingGetListDashboard} = this.state
    const totalPages = Math.ceil(totalRecords / selectPageLimitValue)
     //console.log('this.state ---- RENDER', this.state)
     //console.log('this.state checkMandatoryFormRadio ---- RENDER', checkMandatoryFormRadio)
    return (
        <React.Fragment>
          { statusModalNewTickets === 'edit' && visibleNewTicket && Object.keys(selectHeaderTickets) && Object.keys(selectHeaderTickets).length > 0 &&
            <HeaderTicket selectHeaderTickets={selectHeaderTickets} onEditModalTickets={this.onEditModalTickets}
                          onGetTicketWorkflowListDashboard={this.onGetTicketWorkflowListDashboard} onCloseNewTickets={this.onCloseNewTickets}
                          selectEditTickets={ selectEditTickets } ticketCompleted={ ticketCompleted }
                          onOpenEditTickets={ this.onOpenEditTickets } activeOpenEditTickets={ activeOpenEditTickets }
                          selectTicketTableDashboard={selectTicketTableDashboard}/>
          }
          {statusModalNewTickets === 'edit' && visibleNewTicket && Object.keys(selectHeaderTickets) && Object.keys(selectHeaderTickets).length > 0 &&
          <HeaderWorkflowStep selectHeaderTickets={selectHeaderTickets} onDisplayHeaderStep={ this.onDisplayHeaderStep } formTicketWorkflow={ formTicketWorkflow }
                              statusFormWorkflow={ statusFormWorkflow } ticketWorkflowFormId={ticketWorkflowFormId}
                              selectEditTickets={ selectEditTickets } ticketWorkflowStepDTOS={ ticketWorkflowStepDTOS }
                              onEditModalTickets={this.onEditModalTickets} formId={ formId } listComponentsTemplateFormProps={ listComponentsTemplateFormProps }
                              onGetAfterSaveComponentFromWorkflow={this.onGetAfterSaveComponentFromWorkflow}
                              onGetActiveStep={ this.onGetActiveStep } startSaveFormSectionsData={startSaveFormSectionsData }
                              onStatusResponseSaveFormSectionsData={ this.onStatusResponseSaveFormSectionsData}
                              onGetTicketWorkflowAndHeaderDetailsNextBack={ this.onGetTicketWorkflowAndHeaderDetailsNextBack }
                              onGetTicketWorkflowAndHeaderDetails={ this.onGetTicketWorkflowAndHeaderDetails }
                              nextLast={ nextLast }
                              backFirst={ backFirst }
                              stepHeaderActive={ stepHeaderActive }
                              indexStartHeaderDetails={ indexStartHeaderDetails }
                              stepHeaderActiveNext={ stepHeaderActiveNext }
                              stepTicketActive={ stepTicketActive }
                              ticketCompleted={ ticketCompleted }
                              startFirstStepTicket={ startFirstStepTicket }
                              activeFirstStepTicket={ activeFirstStepTicket }
                              activeOpenEditTickets={ activeOpenEditTickets }
                              checkMandatoryForm={ checkMandatoryForm }
                              checkMandatoryFormShort={ checkMandatoryFormShort }
                              checkMandatoryFormLong={ checkMandatoryFormLong }
                              checkMandatoryFormAddress={ checkMandatoryFormAddress }
                              checkMandatoryFormEmail={ checkMandatoryFormEmail }
                              checkMandatoryFormPhone={ checkMandatoryFormPhone }
                              checkMandatoryFormRadio={ checkMandatoryFormRadio }
                              checkMandatoryFormDropdown={ checkMandatoryFormDropdown }
                              checkMandatoryFormMultiple={ checkMandatoryFormMultiple }
                              checkMandatoryFormSignature={ checkMandatoryFormSignature }
                              checkMandatoryFormUpload={ checkMandatoryFormUpload }
          />
          }

          { visibleNewTicket ?
              <React.Fragment>
                { stepHeaderActive ?
                  <div className="sarDashboard-disclosure-workflow">
                    { this.onGenerateHeaderSteps(stepHeaderActive) }
                  </div> :
                  <div className={statusModalNewTickets === 'edit' ? "sarDashboard-page sarDashboard-page-edit-ticket " : 'sarDashboard-page sarDashboard-page-new-ticket'}>
                  <NewTicketSettingsPage status="add" onCloseNewTickets={this.onCloseNewTickets} onGetTicketWorkflowListDashboard={this.onGetTicketWorkflowListDashboard}
                  statusModalNewTickets={statusModalNewTickets} selectEditTickets={selectEditTickets} checkedRequestorDetails={checkedRequestorDetails}/>
                  </div>
                }
              </React.Fragment>
              :
      <div className="sarDashboard-page sarDashboard-page-new ">
        {/*{*/}
        {/*  visibleNewTicket &&*/}
        {/*  <NewTicketSettingsModal status="add" onCloseNewTickets={ this.onCloseNewTickets} onGetTicketWorkflowListDashboard={ this.onGetTicketWorkflowListDashboard }*/}
        {/*                          statusModalNewTickets={ statusModalNewTickets } selectEditTickets={selectEditTickets}/>*/}
        {/*}*/}
        <div className='dashboard-workflow-title-page'>
          <header className="dashboard-workflow-headline">
            <div className='dashboard-workflow-header'>
              <h1 className="dashboard-workflow-title">Tickets</h1>
              <Button className={ loadingBtnNewTicket ? 'new-ticket-button btn-loading' : 'new-ticket-button'}
                      loading={loadingBtnNewTicket}
                      onClick={() => this.openSarTrackerPage()}>
                New ticket
              </Button>

            </div>
          </header>
        </div>
        <div className="sar_statistic_card sar_statistic_card-update">
          <CardList statisticsDashboard={statisticsDashboard} status="dashboard"/>
          { Object.keys(statisticsDashboard) && Object.keys(statisticsDashboard).length > 0 &&
            <LineChart statisticsDashboard={statisticsDashboard}/>
          }
        </div>
        <div className="sar-table-content sar-table-new-content">
          <div className="sar-table-content__header table-content-header-update">
            <div className="per-page-wrapper-update">
              <div className={ `per-page per-page-update icon-v14-show-${selectPageLimitValue }${onHoverShow ? '-active' : ''}`}
                   onMouseEnter={ (e) => this.onMouseEnterAction(e, 'show') } onMouseLeave={ () => this.onMouseLeaveAction() }>
                <Select
                        value={selectPageLimitValue}
                        dropdownClassName={'select-per-page-tickets per-tickets'}
                        onChange={ (e) => this.changeItemsPerPageLimit(e) }>
                  { itemsPerPageLimitOptions.map((option) => {
                    return <Option key={option} value={option}>{option}</Option>
                  })}
                </Select>
              </div>
            </div>
            {/*<div className='select-all-wrapper select-all-wrapper-sar'>*/}
            {/*  <Checkbox*/}
            {/*    className="select-all-sar"*/}
            {/*    onChange={ this.onSelectAll }*/}
            {/*    checked={ checkBoxSelectAll }*/}
            {/*  >*/}
            {/*    Select all*/}
            {/*  </Checkbox>*/}
            {/*  <span*/}
            {/*    onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'delete') }*/}
            {/*    onMouseLeave={ () => this.onMouseLeaveOnPanel() }*/}
            {/*    className={onHoverDelete && ((listKeysSar && listKeysSar.length > 0) || (selectSarRowTable && selectSarRowTable.id)) ? 'icon-v12-delete-active' : listKeysSar && listKeysSar.length > 0 ? 'icon-v12-delete' : selectSarRowTable && selectSarRowTable.id ? 'icon-v12-delete' : 'icon-v12-delete disabled-delete' }*/}
            {/*    onClick={ (e) => this.handleDeleteRequestDashboard(e)}*/}
            {/*  />*/}
            {/*</div>*/}
            <div className='pagination-wrapper pagination-wrapper-new'>
              <div className="custom-pagination-sb">
                <div className="button-control-dashboard-new">
                  <Tooltip title="first" className={isPrevDisabled ? 'disabled' : ''}>
                    <span className={ isPrevDisabled ? 'icon-v14-start-1 disabled' : onStateStart ? 'icon-v14-start-active' : 'icon-v14-start-1' }
                          onClick={ (e) => this.handleMovePage(e, 'first')}
                          onMouseEnter={(e) => this.onMouseEnterAction(e, 'start')}
                          onMouseLeave={() => this.onMouseLeaveAction()}/>
                  </Tooltip>
                  <Tooltip title="previous" className={isPrevDisabled ? 'disabled' : ''}>
                    <span className={ isPrevDisabled ? 'icon-v21-back-1 disabled' : onHoverBackBtn ? 'icon-v21-back-active' : 'icon-v21-back-1'}
                          onMouseEnter={(e) => this.onMouseEnterAction(e,'previous')}
                          onMouseLeave={() => this.onMouseLeaveAction()}
                          onClick={ (e) => this.handleMovePage(e, 'previous')}/>
                  </Tooltip>
                  { totalPages === 0 &&
                    <span className='button-control__text'>{1} of {1} </span>
                  }
                  { totalPages === 1 &&
                    <span className='button-control__text'>{currentPage} of {totalPages} </span>
                  }
                  { totalPages > 1 &&
                    <span className='button-control__text'>
                      <input
                        type="number"
                        className="insert-page-new"
                        placeholder= { currentPage }
                        value= { inputPage }
                        onChange={ (e) => this.handleInputPageChange(e) }
                        onBlur={ () => this.handleInputPageBlur() }
                        onKeyDown={ (e) => this.handleInputPageEnter(e) }
                        min="1"
                        max={totalPages}
                      />
                      <span>of {totalPages}</span>
                    </span>
                  }
                  <Tooltip title="next" className={isNextDisabled ? 'disabled' : ''}>
                    <span className={ isNextDisabled ? 'icon-v21-forth-1 disabled' : onHoverNextBtn ?  'icon-v21-forth-active' : 'icon-v21-forth-1' }
                          onClick={ (e) => this.handleMovePage(e, 'next')}
                          onMouseEnter={(e) => this.onMouseEnterAction(e, 'next')}
                          onMouseLeave={() => this.onMouseLeaveAction()}
                    />
                  </Tooltip>
                  <Tooltip title="last" className={isNextDisabled ? 'disabled' : ''}>
                    <span className={ isNextDisabled ? 'icon-v14-end-1 disabled' : onStateEnd ? 'icon-v14-end-active' : 'icon-v14-end-1'  }
                          onClick={ (e) => this.handleMovePage(e, 'last')}
                          onMouseEnter={(e) => this.onMouseEnterAction(e, 'end')}
                          onMouseLeave={() => this.onMouseLeaveAction()}/>
                  </Tooltip>
                </div>
                { showInsertPageNumber &&
                  <Search
                    className='insert-page'
                    placeholder={`Insert page number (max ${ Math.ceil(totalRecords / selectPageLimitValue) } )`}
                    onSearch={(value) => this.goToInputPage(value)}
                    style={{ width: 250 }}
                  />
                }
              </div>
            </div>
            {/*<div className="button-wrapper">*/}
            {/*  <Link to="/sar/tracker-page">*/}
            {/*    <Button theme="newBusiness" >*/}
            {/*      <span className="icon-v10-add-b button-newBusiness__icon"/>*/}
            {/*        New request*/}
            {/*    </Button>*/}
            {/*  </Link>*/}
            {/*</div>*/}
            <div className="content-search-dashboard-workflow">
              <a href={downloadDashboardWorkflow()}
                 className={onStateDownload ? 'icon-v21-download-active-1' : 'icon-v21-download-1'}
                 onMouseEnter={(e) => this.onMouseEnterAction(e, 'download')}
                 onMouseLeave={() => this.onMouseLeaveAction()}> </a>
            <div className={'search-content'}>
              <Search
                  allowClear
                  placeholder={'Search'}
                 onSearch={(e) => this.onSearchTicket(e)}
              />
            </div>
            </div>
          </div>
        </div>
        <div className="styleAccordion">
              <SubjectsList
                  sars={sars}
                  isLoading={loadingGetListDashboard}
                  itemsPerPageLimit={selectPageLimitValue}
                  currentPage={currentPage}
                  sortCriteria={sortCriteria}
                  filterCriteria={filterCriteria}
                  filterChanged={filterChanged}
                  setFilterAndSort={this.setFilterAndSort}
                  setFilter={this.setFilter}
                  onSelectSarRow={this.onSelectSarRow}
                  checkSelectAllSar={checkSelectAllSar}
                  onCloseNewTickets={this.onCloseNewTickets}
                  dataSourceTicket={dataSourceTicket}
                  onGetTicketWorkflowListDashboard={this.onGetTicketWorkflowListDashboard}
                  onEditModalTickets={this.onEditModalTickets}
                  onGetSorterColumnTickets={this.onGetSorterColumnTickets}
                  ownerListFilter={ownerListFilter}
                  workflowNames={workflowNames}
                  timeframes={timeframes}
                  boxStatusList={boxStatusList}
                  onGetFiltersColumnTickets={this.onGetFiltersColumnTickets}
                  onEditFirstStepModalTickets={this.onEditFirstStepModalTickets}
              />
        </div>
      </div>
          }
        </React.Fragment>
    )
  }
}



DashboardWorkflow = withRouter(DashboardWorkflow)

const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id,
  sarPageLimit: state.sar.sarPageLimit
})

export default connect(mapStateToProps,{
  setSarPageLimit
})(DashboardWorkflow)