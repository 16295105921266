import React from 'react'
import {Button, Checkbox, Dropdown, Icon, Input, Menu, Modal, Select, Spin, Table, Tooltip, TreeSelect} from 'antd'
import '../FolderRedaction.scss'
import {connect} from 'react-redux'
import {
    apiCancelBulk, apiCheckBoxRedactionStatus, apiEditDetailedTreeSettingsRedaction, apiGetSelectedOptionRedaction,
    apiResumeBulk, apiSaveRedactionFilterSelections,
    apiSmartNewBulkRedaction,
    getAllBusinessList,
    getCheckResumeBulkStatus,
    getFolderPath,
    getSelectedBusinessItems,
    getSelectedRegex,
    getSmartBoxAllRegexList
} from "../../../../../api/SmartBox";
import {
    generatePiiAnalyticsTree,
    generatePiiTree
} from '../../../../../utils/SmartBox/SmartBoxMethods'
import {
    setRedactionNonSelectedWords,
    setSmartBoxCheckBoxRedactionStatus, setSmartBoxNotFolderRedactionComplete
} from '../../../../../actions/smartBoxActions'
import CustomNotificationManager from "../../../../Common/CustomNotificationManager";
import moment from "moment";
import cookie from "react-cookies";
//import {hideResponse} from "../../../../../actions/questionActions";

const {Option} = Select
const {Search} = Input
const {Column} = Table
const { TreeNode } = TreeSelect;
// const isLocalhost = () => {
//     return window.location.hostname === 'localhost'
// }
class NewFolderRedaction extends React.Component {
    state = {
        currentPage: 1,
        tableBulkRedaction: [
            {
                key: 2,
                nameChild: 'Alex',
                category: 'PII',
                categoryParent: 'Name',
                categoryChild: 'Person'
            },
            {
                key: 3,
                nameChild: 'George',
                category: 'Dictionary',
                categoryParent: 'Company terms',
                categoryChild: 'term'
            },
            {
                key: 4,
                nameChild: 'Sarah',
                category: 'PII',
                categoryParent: 'Locations',
                categoryChild: 'Cities'
            }
        ],
        selectedRowKeys: [],
        selectedRowKeysUpdate: [],
        valueOptionAll: 'all',
        valueOptionAllArray: ['all'],
        dictionariesData: [],
        treeAllPii: [],
        checkedKeysPii: [],
        treeExpandedKeys: [],
        selectFilterDictionaries: null,
        selectFilterRegEx: null,
        regexList: [],
        valueInputRow: '',
        selectDictionariesRow: '',
        modalVisibleAddDictionary: false,
        name: '',
        description: '',
        activeListNewDict: {},
        categoryDisplayedTerm: '',
        color: 'rgba(5, 195, 71, 1)',
        disabledAddTerm: false,
        redactionPerPage: 20,
        totalRecords: 0,
        isPrevDisabled: true,
        isNextDisabled: false,
        inputPageRedaction: '',
        valueInputSearchRedact: '',
        valueInputSearchRedactArray: [],
        onHoverForth: false,
        onHoverStart: false,
        onHoverBack: false,
        onHoverEnd: false,
        newBulkRedaction: [],
        allCheckedNodes: [],
        selectedRowWord: [],
        selectedRow: [],
        selectFilterDictionariesIds: null,
        startBulkRedaction: false,
        checkedKeysDictionary: [],
        checkedKeysRegEx: [],
        regexListIds: [],
        dictionariesListIds: [],
        selectAllChecked: false,
        nonSelectedKeys: [],
        //nonSelectedWords: [],
        checkBulkStatus: false,
        checkStopBulk: false,
        checkResumeStatus: false,
        setIntervalResume: 0,
        getSelectedDictionaries: [],
        getSelectedRegEx: [],
        selectedDictionaries: [],
        newLocalHost: '',
        statusSorter: null,
        checkSearchSelected: false,
        selectedRegEx: [],
        selectedPagesRows: [],
        responseCheckBoxStatus: false,
        setIntervalBoxStatus: 0,
        checkApplyPiiFilter: false,
        extractKeysPiiChecked: [],
        excludedWordsBulk: [],
        extractDictionaryChecked: [],
        checkStartSearch: false,
        checkBulkRedaction: false,
        selectedKeysOnPii: [],
        checkSelectAll: false,
        piiSelectedOption: [],
        checkClearPiiFilter: false,
        redactAllGlobal: false,
        allCheckedNodesDetail: [],
        excludedDictionaryGlobal: [],
        excludedDictionaryGlobalNames: [],
        selectedDictionaryGlobal: [],
        excludedPiiGlobal: [],
        selectedPiiGlobal: [],
        selectedRegExGlobal: [],
        excludedWordGlobal: [],
        selectedWordGlobal: [],
        selectedWordGlobalFull: [],
        excludedRegExGlobal: [],
        selectedDictNamesGlobal: [],
        excludedRedactedWord: [],
        regexListSelected: [],
        onHoverReset: false,
        nonSelectedResponseApi: [],
        selectedCheckbox: false,
        hoverAddTerm: false,
        valueTerm: '',
        dictionaryIdSelect: null,
        createDictionary: false,
        onHoverDelete: false,
        onHoverEdit: false,
        visibleAddRow: false,
        checkLoadFirstPage: false,
        selectedRowWordAll: [],
        statusOpenAddTerm: false,
        firstLoadPage: true,
        regexSelectedId: [],
        listNewRegEx: [],
        checkResetFiltre: false,
        statusActivePagination: false,
        loadingBulkRedaction: false,
        statusSelectAll: null,
        uniqueIdFolderRedaction: null,
        uniqueIdFolderRedactionLastSelection: null,
        checkSelectAllUpdate: false,
        allCheckedNodesLabel: {},
        excludedPiiCategoryResponse: [],
        selectedPiiGlobalResponse: [],
        selectedDictionaryGlobalResponse: [],
        excludedDictionaryResponse: [],
        allCheckedNodesLabelDictionaries: {},
        allCheckedNodesLabelRegEx: {},
        selectedRegExGlobalResponse: [],
        excludedRegExResponse: [],
        statusResponseSaveRedaction: null,
        filterRedactionTypeNew: 'all',
        filterSearchTermNew: '',
        selectedPiiCategoryNew: [],
        selectedRegexNew: [],
        selectedDictionaryNew: [],
        selectedWordNew: '',
        excludedWordNew: '',
        redactAllNew: false,
        typeNewRedact: 'Redact (All)',
        loadingRedact: false,
        statusSaveRedaction: false
    }

    componentDidMount() {
        let { treeAllPii } = this.state
        const uniqueIdFolderRedaction = Math.floor(Math.random() * 1000000000)
         treeAllPii = generatePiiAnalyticsTree()
        this.props.setRedactionNonSelectedWords([])
        this.setState({
            treeAllPii,
            uniqueIdFolderRedaction: uniqueIdFolderRedaction
        }, () => {
            this.categoriesTreePii(treeAllPii)
        })
        this.getDictionariesData()
        this.getRegularExpressions()
        // apiGetRedactionServiceUrl().then((response) => {
        //     this.setState({
        //         newLocalHost: response.data
        //     })
        // })
        //this.getNewBulkRedaction()
        //this.onCheckStartBulk()
    }
    onCheckStartBulk = () => {
        // getCheckBulkStatus(this.props.folder.id).then((response) => {
        //     this.setState({
        //         checkBulkStatus: response.data
        //     })
        // }).catch((error) => {
        //     if (error.response.status === 504) {
        //         CustomNotificationManager.cleanBuffer('warning', '')
        //     }
        // })
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.folder !== this.props.folder && this.props.folder && prevProps.folder) {
            this.props.setRedactionNonSelectedWords([])
            this.getNewBulkRedaction()
            //this.onGetOptionSelectedRedaction()
            this.setState({
                getOption: true
            }, () => {
                this.onGetOptionSelectedRedaction()
            })
            let { treeAllPii } = this.state
            //this.onCheckStartBulk()
            this.getDictionariesData()
            //this.onUpdateCheckResumeStatus()
            //this.onGetSelectedDictionaries()
            //this.onGetSelectedRegex()
            this.getRegularExpressions()
           treeAllPii = generatePiiTree()
            this.setState({
                treeAllPii
            }, () => {
                this.categoriesTreePii(treeAllPii)
            })
          }
        }
    categoriesTreePii = (treeAllPii) => {
        treeAllPii && treeAllPii.length > 0 && treeAllPii.forEach((cat, index) => {
          cat.id = index
        })
        this.setState({
            treeAllPii: treeAllPii
        })
    }
    onGetSelectedDictionaries = () => {
        const { dictionariesData } = this.state
        const newDictionariesSelected = []
        getSelectedBusinessItems(this.props.folder.id).then((response) => {
            dictionariesData && dictionariesData.length > 0 && dictionariesData.forEach((dict) => {
                response.data && response.data.length > 0 && response.data.forEach((key) => {
                    if (dict.id.toString() === key) {
                        newDictionariesSelected.push(dict)
                    }
                })
            })
            this.setState({
                getSelectedDictionaries: response.data,
                selectedDictionaries: newDictionariesSelected
            })
        }).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
              }else{
                console.log(error)
              }
        })
    }
    onGetSelectedRegex = () => {
        const { regexList } = this.state
        const regexListNew = []

        getSelectedRegex(this.props.folder.id).then((response) => {
            response.data && response.data.length > 0 && response.data.forEach((key) => {
                regexList && regexList.length > 0 && regexList.forEach((reg) => {
                    if (key === reg.regex) {
                        regexListNew.push(reg)
                    }
                })

            })
           this.setState({
               getSelectedRegEx: response.data,
               selectedRegEx: regexListNew
           })
        }).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
              }else{
                console.log(error)
              }
        })
    }
    onUpdateCheckResumeStatus = () => {
        getCheckResumeBulkStatus(this.props.folder.id).then((response) => {
            this.setState({
                checkResumeStatus: response.data
            })
        }).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
              }else{
                console.log(error)
              }
        })
    }
    onStopBulkRedaction = () => {
        apiCancelBulk(this.props.folder.id).then((response) => {
            if (response.status === 200) {
                if (response.data === 'Redaction process scheduled for cancel!') {
                    CustomNotificationManager.info('Redaction process scheduled for cancel!')
                }
                this.setState({
                    checkStopBulk: true
                })
                //this.onCheckResumeBulk()
            }
        }).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
              }else{
                console.log(error)
              }
        })
    }
    // onCheckResumeBulk = () => {
    //     const { checkResumeStatus } = this.state
    //     getCheckResumeBulkStatus(this.props.folder.id).then((response) => {
    //        this.setState({
    //            checkResumeStatus: response.data
    //        })
    //         this.setIntervalCheckResumeBulk()
    //     }).catch((error) => {
    //         if (error.response.status === 504) {
    //             CustomNotificationManager.cleanBuffer('warning', '')
    //         }
    //     })
    // }
    setIntervalCheckResumeBulk = () => {
        let { setIntervalResume, checkResumeStatus } = this.state
        if (setIntervalResume === 0) {
            if (!checkResumeStatus) {
                setIntervalResume = setInterval(this.callBackCheckResume, 5000)
                this.setState({
                    setIntervalResume
                })
            } else {
                clearInterval(setIntervalResume)
            }
        }
    }

    callBackCheckResume = () => {
        const { checkResumeStatus, setIntervalResume } = this.state
        if (!checkResumeStatus) {
            getCheckResumeBulkStatus(this.props.folder.id).then((response) => {
                this.setState({
                    checkResumeStatus: response.data
                })
            }).catch((error) => {
                if (error?.response?.status === 504) {
                    CustomNotificationManager.cleanBuffer('warning', '')
                  }else{
                    console.log(error)
                  }
            })
        } else if (checkResumeStatus) {
            clearInterval(setIntervalResume)
            this.setState({ setIntervalResume })
        }
    }

    onResumeBulk = () => {
        const { selectedRowKeys, newBulkRedaction, valueOptionAll, selectFilterRegEx, treeAllPii, allCheckedNodes,
            currentPage, redactionPerPage, valueInputSearchRedact, selectFilterDictionariesIds, selectAllChecked, selectedPagesRows } = this.state
        const { nonSelectedWords } = this.props
        const checkOptionAll = valueOptionAll === 'all' ? null : valueOptionAll === 'redacted' ? true : false
        const secondLayerFilters = []
        const thirdLayerFilters = []
        treeAllPii && treeAllPii.length > 0 && treeAllPii.forEach((tree) => {
            allCheckedNodes && allCheckedNodes.length > 0 && allCheckedNodes.forEach((check) => {
                if (tree.key === check.node.key && check.children && check.children.length > 0) {
                    secondLayerFilters.push(check.node.key[0].toUpperCase() + check.node.key.substring(1).toLowerCase())
                } else if (!check.children) {
                    thirdLayerFilters.push(check.node.props.value)
                }
            })
        })
        const uniqueThirdLayerFilters = [...new Set(thirdLayerFilters)]
        const updateNonSelectedWords = []
        nonSelectedWords && nonSelectedWords.length > 0 && nonSelectedWords.forEach((nonS) => {
            updateNonSelectedWords.push(nonS.toLowerCase())
        })
        const folderRedactionDTO = {
            path: this.props.folder.path,
            terms: selectAllChecked ? null : selectedRowKeys.length > 0 && selectedRowKeys.length === newBulkRedaction.length ? null : selectedPagesRows,
            selected: checkOptionAll,
            regex: selectFilterRegEx ? selectFilterRegEx : null,
            dictionaryId: selectFilterDictionariesIds ? selectFilterDictionariesIds : null,
            secondLayerFilters: secondLayerFilters,
            thirdLayerFilters: uniqueThirdLayerFilters,
            pageNr: currentPage,
            pageRows: redactionPerPage,
            searchTerm: valueInputSearchRedact ? valueInputSearchRedact : null,
            excludedWords: updateNonSelectedWords
        }
        apiResumeBulk(folderRedactionDTO).then((resume) => {

        }).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
              }else{
                console.log(error)
              }
        })
    }

        fetchCheckBoxStatus = () => {
        const { setIntervalBoxStatus } = this.state
            cookie.save('checkRedaction', 'start', {path: '/'})
            apiCheckBoxRedactionStatus(this.props.folder.id).then((response) => {
                this.setState({
                    responseCheckBoxStatus: response.data
                })
                if (!response.data) {
                   this.setIntervalCheckBoxStatus()
                } else
                if (response.data) {
                    //this.getUpdateBulk()
                    this.getNewBulkRedaction()
                    this.props.setSmartBoxCheckBoxRedactionStatus(0)
                    clearInterval(setIntervalBoxStatus)
                    this.setState({setIntervalBoxStatus, startBulkRedaction: false})

                    let reactionComplete = []
                    const notificationRedactionComplete = [{
                        nameActionEvent: 'Redaction complete',
                        descriptionActionEvent: `${ this.props.folder.name }`,
                        timesEvent: moment().format('DD/MM/YYYY HH:mm:ss'),
                        status: 'box-notification',
                        nameBox: this.props.folder.name
                    }]
                    reactionComplete = notificationRedactionComplete.concat(this.props.smartBoxNotRedactComplete)
                    const uniqueFiles = reactionComplete && reactionComplete.length > 0 && reactionComplete.filter((a, i) => reactionComplete.findIndex((s) => a.nameBox === s.nameBox) === i)
                    this.props.setSmartBoxNotFolderRedactionComplete(uniqueFiles)

                }
            }).catch((error) => {
                if (error?.response?.status === 504) {
                    CustomNotificationManager.cleanBuffer('warning', '')
                  }else{
                    console.log(error)
                  }
            })
        }
      setIntervalCheckBoxStatus = () => {
          let { responseCheckBoxStatus, setIntervalBoxStatus } = this.state
          this.props.setSmartBoxCheckBoxRedactionStatus(50)
          if (setIntervalBoxStatus === 0) {
              if (!responseCheckBoxStatus) {
                  setIntervalBoxStatus = setInterval(this.callBackCheckBoxStatus, 5000)
                  this.setState({
                      setIntervalBoxStatus
                  })
              } else {
                  setIntervalBoxStatus = this.props.setSmartBoxCheckBoxRedactionStatus(0)
                  clearInterval(setIntervalBoxStatus)
              }
          }
      }

      callBackCheckBoxStatus = () => {
        const { responseCheckBoxStatus, setIntervalBoxStatus } = this.state
          const itemRedaction = cookie.load('checkRedaction', { path: '/' })
          if (!responseCheckBoxStatus && itemRedaction === 'start') {
              apiCheckBoxRedactionStatus(this.props.folder.id).then((response) => {
                  this.setState({
                      responseCheckBoxStatus: response.data
                  })
                  if (response.data) {
                      //this.getUpdateBulk()
                      this.getNewBulkRedaction()
                      clearInterval(setIntervalBoxStatus)
                      this.setState({ setIntervalBoxStatus, startBulkRedaction: false })

                      let reactionComplete = []
                      const notificationRedactionComplete = [{
                          nameActionEvent: 'Redaction complete',
                          descriptionActionEvent: `${ this.props.folder.name }`,
                          timesEvent: moment().format('DD/MM/YYYY HH:mm:ss'),
                          status: 'box-notification',
                          nameBox: this.props.folder.name
                      }]
                      reactionComplete = notificationRedactionComplete.concat(this.props.smartBoxNotRedactComplete)
                      const uniqueFiles = reactionComplete && reactionComplete.length > 0 && reactionComplete.filter((a, i) => reactionComplete.findIndex((s) => a.nameBox === s.nameBox) === i)
                      this.props.setSmartBoxNotFolderRedactionComplete(uniqueFiles)

                  } else {
                      this.setState({setIntervalBoxStatus})
                  }
              }).catch((error) => {
                if (error?.response?.status === 504) {
                    CustomNotificationManager.cleanBuffer('warning', '')
                }else{
                console.log(error)
                }
              })
          } else if (responseCheckBoxStatus) {
              clearInterval(setIntervalBoxStatus)
              this.setState({ setIntervalBoxStatus })
          }
      }

    onGetSaveRedactionFilterSelectionsStartBulk = () => {
        const { valueOptionAll, uniqueIdFolderRedaction, valueInputSearchRedact, selectedPiiGlobal, redactAllGlobal, selectedDictionaryGlobal,
            selectedWordGlobal, excludedPiiGlobal, excludedDictionaryGlobal, excludedPiiCategoryResponse,
            selectedPiiGlobalResponse, selectedDictionaryGlobalResponse, excludedDictionaryResponse,
            excludedRegExResponse, selectedRegExGlobalResponse, excludedWords, typeNewRedact } = this.state
        const { nonSelectedWords  } = this.props
        let  nonSelectedWords2 = []
        const uniqueSelectedPiiGlobal = [...new Set(selectedPiiGlobalResponse)]
        const uniqueSelectedDictionaryGlobal = [...new Set(selectedDictionaryGlobalResponse)]
        const uniqueGlobalWord = [...new Set(selectedWordGlobal)]
        const uniqueExcludedPiiCategories = [...new Set(excludedPiiCategoryResponse)]
        const uniqueExcludedDictionaryGlobal = [...new Set(excludedDictionaryResponse)]
        const uniqueSelectedRegExGlobalResponse = [...new Set(selectedRegExGlobalResponse)]
        const uniqueExcludedRegExResponse = [...new Set(excludedRegExResponse)]

        if (this.props.nonSelectedWords.filter((item) => !uniqueGlobalWord.includes(item))) {
            nonSelectedWords2 = this.props.nonSelectedWords.filter((item) => !uniqueGlobalWord.includes(item))
        }

        const uniqueNonSelectedWords2 = [...new Set(nonSelectedWords2)]
        const saveRedactionFiltersSelectionsDTO = {
            uid: uniqueIdFolderRedaction,
            filterRedactionType: valueOptionAll,
            redactAll: redactAllGlobal,
            filterSearchTerm: valueInputSearchRedact,
            selectedPiiCategory: uniqueSelectedPiiGlobal,
            excludedPiiCategory: uniqueExcludedPiiCategories,
            selectedWord: uniqueGlobalWord,
            excludedWord: uniqueNonSelectedWords2,
            selectedDictionary: selectedDictionaryGlobalResponse,
            excludedDictionary: uniqueExcludedDictionaryGlobal,
            selectedRegex: uniqueSelectedRegExGlobalResponse,
            excludedRegex: uniqueExcludedRegExResponse,
        }
        const { filterRedactionTypeNew, filterSearchTermNew, selectedPiiCategoryNew, selectedRegexNew, selectedDictionaryNew, selectedWordNew, excludedWordNew,
            redactAllNew} = this.state
        const saveRedactionFiltersSelectionsDTOUpdate = {
            uid: uniqueIdFolderRedaction,
            filterRedactionType: filterRedactionTypeNew,
            filterSearchTerm: filterSearchTermNew,
            selectedPiiCategory: selectedPiiCategoryNew,
            selectedRegex: selectedRegexNew,
            selectedDictionary: selectedDictionaryNew,
            selectedWord: selectedWordNew,
            excludedWord: excludedWordNew,
            redactAllCustom: redactAllNew,
            redactAll: redactAllGlobal,
        }
        this.setState({
            statusResponseSaveRedaction: true
        }, () => {
            this.onStartBulkRedaction()
       /*     apiSaveRedactionFilterSelections(saveRedactionFiltersSelectionsDTOUpdate).then((response) => {
                // if (response.data.excludedWords && response.data.excludedWords.length > 0) {
                //     this.props.setRedactionNonSelectedWords(response.data.excludedWords)
                // }
                this.setState({
                    excludedPiiCategoryResponse: response.data.excludedPiiCategory,
                    excludedDictionaryResponse: response.data.excludedDictionary,
                    selectedDictionaryGlobalResponse: response.data.selectedDictionary,
                    excludedRegExResponse: response.data.excludedRegex,
                    selectedRegExGlobalResponse: response.data.selectedRegex,
                    selectedWordGlobal: response.data.selectedWord,
                    redactAllNew: response.data.redactAllCustom,
                    redactAllGlobal: response.data.redactAll,
                    statusResponseSaveRedaction: false
                }, () => {
                    this.onStartBulkRedaction()
                })
            }
            )*/
        })
    }

        onStartBulkRedaction = () => {
           const { valueOptionAll, treeAllPii, allCheckedNodes,
               currentPage, redactionPerPage, selectedPagesRows, selectedDictionaries, extractDictionaryChecked,
               redactAllGlobal, selectedPiiGlobal, selectedDictionaryGlobal, selectedWordGlobal, excludedPiiGlobal, excludedDictionaryGlobal,
               excludedWordGlobal, selectedCheckbox, uniqueIdFolderRedaction, typeNewRedact } = this.state
            const { nonSelectedWords } = this.props
            const checkOptionAll = valueOptionAll === 'all' ? null : valueOptionAll === 'redacted' ? true : false
            const secondLayerFilters = []
            const thirdLayerFilters = []
            //const checkedKeys = []
            const excludedDict = []
            treeAllPii && treeAllPii.length > 0 && treeAllPii.forEach((tree) => {
                allCheckedNodes && allCheckedNodes.length > 0 && allCheckedNodes.forEach((check) => {
                    if (tree.key === check.node.key && check.children && check.children.length > 0) {
                        secondLayerFilters.push(check.node.key[0].toUpperCase() + check.node.key.substring(1).toLowerCase())
                    } else if (!check.children) {
                        thirdLayerFilters.push(check.node.props.value)
                    }
                })
            })
            //const uniqueThirdLayerFilters = [...new Set(thirdLayerFilters)]
            const updateNonSelectedWords = []
            nonSelectedWords && nonSelectedWords.length > 0 && nonSelectedWords.forEach((nonS) => {
                updateNonSelectedWords.push(nonS.toLowerCase())
            })
            //const excludedPiiCategories = secondLayerFilters.concat(thirdLayerFilters)

            selectedDictionaries && selectedDictionaries.length > 0 && selectedDictionaries.forEach((dict) => {
                extractDictionaryChecked && extractDictionaryChecked.length > 0 && extractDictionaryChecked.forEach((check) => {
                    if (dict.listName === check) {
                        excludedDict.push(dict.id)
                    }
                })
            })
            const selectedWords = []
            selectedPagesRows && selectedPagesRows.length > 0 && selectedPagesRows.forEach((word) => {
                selectedWords.push(word.word)
            })
            //const selectedPiiCategories = secondLayerFilters.concat(thirdLayerFilters)
            //const uniquePiiCategories = [...new Set(selectedPiiCategories)]
            const uniqueExcludedPiiCategories = [...new Set(excludedPiiGlobal)]
            const uniqueExcludedDictionaryGlobal = [...new Set(excludedDictionaryGlobal)]
            const uniqueGlobalWord = [...new Set(selectedWordGlobal)]
            //const uniqueGlobalWordExcluded = [...new Set(excludedWordGlobal)]
            const uniqueGlobalWordExcluded = [...new Set(nonSelectedWords)]
            const uniqueSelectedPiiGlobal = [...new Set(selectedPiiGlobal)]
            const uniqueSelectedDictionaryGlobal = [...new Set(selectedDictionaryGlobal)]
            //const uniqueExcludedRedactedWord = [...new Set(excludedRedactedWord)]
            const uniqueNonSelectedWords = [...new Set(nonSelectedWords)]
            const uniqueGlobalWordUpdate = []
            uniqueGlobalWord && uniqueGlobalWord.length > 0 && uniqueGlobalWord.forEach((word) => {
                if (uniqueNonSelectedWords && uniqueNonSelectedWords.length > 0) {
                    uniqueNonSelectedWords.forEach((word2) => {
                        if (!uniqueGlobalWord.includes(word2))
                        {
                            //if (word === word2) {
                                if (!uniqueNonSelectedWords.includes(word)) {
                                    uniqueGlobalWordUpdate.push(word)
                                }
                           // }
                        }  else {
                            uniqueGlobalWordUpdate.push(word)
                        }
                    })
                } else {
                    uniqueGlobalWordUpdate.push(word)
                }
            })
            const uniqueGlobalWordUpdateFinally = [...new Set(uniqueGlobalWordUpdate)]
            const folderRedactionDTO = {
               path: this.props.folder.path,
                //terms: selectAllChecked ? null : selectedRowKeys.length > 0 && selectedRowKeys.length === newBulkRedaction.length ? null : selectedPagesRows,
                //selected: checkOptionAll,
                //regex: selectFilterRegEx ? selectFilterRegEx : null,
                //dictionaryId: selectFilterDictionariesIds ? selectFilterDictionariesIds : null,
                //secondLayerFilters: secondLayerFilters,
                //thirdLayerFilters: uniqueThirdLayerFilters,
                pageNr: currentPage,
                pageRows: redactionPerPage,
                //searchTerm: valueInputSearchRedact ? valueInputSearchRedact : null,
                excludedWords: redactAllGlobal && uniqueGlobalWordExcluded && uniqueGlobalWordExcluded.length > 0 ? uniqueGlobalWordExcluded :
                    redactAllGlobal && uniqueNonSelectedWords && uniqueNonSelectedWords.length > 0 ? uniqueNonSelectedWords :
                        !redactAllGlobal && uniqueNonSelectedWords && uniqueNonSelectedWords.length > 0 ? uniqueNonSelectedWords : [],
                redactAll: redactAllGlobal,
                //redactAll:  checkSelectAll && checkApplyPiiFilter && excludedPiiCategories.length > 0 ? checkSelectAll : uniquePiiCategories && uniquePiiCategories.length > 0 ? false : checkSelectAll ? checkSelectAll : uniquePiiCategories && uniquePiiCategories.length > 0 ? false : dictionariesListIds && dictionariesListIds.length > 0 ? false : checkApplyPiiFilter && selectAllChecked === false ? true : selectAllChecked,
                excludedPiiCategories: redactAllGlobal ? uniqueExcludedPiiCategories : [],
                excludedDictionaries: redactAllGlobal ? uniqueExcludedDictionaryGlobal : [],
                selectedWords: !redactAllGlobal ? uniqueGlobalWordUpdateFinally : redactAllGlobal && selectedCheckbox ? uniqueGlobalWordUpdateFinally : [],
                selectedPiiCategories: !redactAllGlobal ? uniqueSelectedPiiGlobal : [],
                selectedDictionaries: !redactAllGlobal ? uniqueSelectedDictionaryGlobal : [],
                displayRedactedTerms: checkOptionAll,
                redactionType: valueOptionAll,
                uid: uniqueIdFolderRedaction,
                contextualRedaction: typeNewRedact === 'Redact (Contextual)' ? true : false
                //excludedRedactedWords: uniqueExcludedRedactedWord
            }
            this.setState({
                startBulkRedaction: true,
                //startBulkRedaction: false,
                loadingBulkRedaction: true
            }, () => {
                    getFolderPath(folderRedactionDTO).then((response) => {
                        this.fetchCheckBoxStatus()
                        let bulkProcessed = ''
                        bulkProcessed = response.data
                            // if (bulkProcessed && bulkProcessed.toLowerCase() === 'started') {
                            //     CustomNotificationManager.info('Bulk processing started!')
                            // }
                        this.setState({
                            startBulkRedaction: true,
                            loadingBulkRedaction: false
                        })
                    }).catch((error) => {
                        if (error?.response?.status === 504) {
                            CustomNotificationManager.cleanBuffer('warning', '')
                            this.setState({
                                startBulkRedaction: true
                            })
                        }else{
                            console.log(error)
                        }
                    })
            })
        }


        onGetOptionSelectedRedaction = () => {
            const {selectedCheckbox, checkLoadFirstPage, redactAllGlobal, selectedRowWordAll, newBulkRedaction,
                selectedPagesRows, totalRecords } = this.state
            const { nonSelectedWords } = this.props
            let checkedKeys = []
            const checkedKeysPages = []
            const extractWordSaved = []
            const extractWordSavedNon = []
            let checkedKeysWord = []
            let idBox = ''
            if (this.props.folder) {
                idBox = this.props.folder.id
            }
            //const currentPath = this.props.folder.path
            //const indexCurrentPath = currentPath ? currentPath.indexOf(`smartBox_${ this.props.clientId }`) : ''
            //const newCurrentPath = currentPath ? currentPath.substr(indexCurrentPath + `smartBox_${ this.props.clientId }`.length) : ''
            //const newCurrentPathUpdate = `smartBox_${ this.props.clientId }` + newCurrentPath
            apiGetSelectedOptionRedaction(idBox).then((response) => {
                if (response.status === 200) {
                    if (response.data && Object.keys(response.data).length > 0 && response.data === 'Processing in progress, this may take several minutes.') {
                      //CustomNotificationManager.info('Processing in progress, this may take several minutes.')
                        this.setState({
                            startBulkRedaction: true
                        })
                    } else
                    if (response.data && Object.keys(response.data).length > 0 && response.data !== 'Processing in progress, this may take several minutes.') {

                        // selectedRowWordAll && selectedRowWordAll.length > 0 && selectedRowWordAll.forEach((item) => {
                        //     if (response.data.excludedWords && response.data.excludedWords.length > 0) {
                        //         if (response.data.excludedWords.includes(item)) {
                        //             extractWordSaved.push(item)
                        //         }
                        //     }
                        // })
                        // if (response.data.excludedWords && response.data.excludedWords.length > 0) {
                        //     const allNonWords = this.props.nonSelectedWords.concat(response.data.excludedWords)
                        //     allNonWords && allNonWords.length > 0 && allNonWords.forEach((item2) => {
                        //         if (!extractWordSaved.includes(item2)) {
                        //             extractWordSavedNon.push(item2)
                        //         }
                        //     })
                        // }
                    if (response.data.redactAll !== null) {
                        this.setState({
                            redactAllGlobal: response.data.redactAllLastSelection
                        })
                    }
                    if (response.data.uidLastSelection) {
                        this.setState({
                            uniqueIdFolderRedaction: response.data.uidLastSelection,
                            uniqueIdFolderRedactionLastSelection: response.data.uidLastSelection
                        }, () => {
                            this.getNewBulkRedaction()
                        })
                    }
                        this.setState({
                            startBulkRedaction: false
                        })
                       // this.props.setRedactionNonSelectedWords(response.data.excludedWords)
                        this.setState({
                           //redactAllGlobal: !checkLoadFirstPage ? response.data.redactAll : response.data.redactAll,
                            //excludedWordGlobal: selectedCheckbox ? this.state.excludedWordGlobal : this.state.excludedWordGlobal.concat(response.data.excludedWords),
                            //nonSelectedWords: selectedCheckbox ? extractWordSavedNon : extractWordSavedNon,
                            //nonSelectedResponseApi: this.props.nonSelectedWords.concat(response.data.excludedWords),
                            redactAllGlobal: response.data.redactAllLastSelection
                            // selectAllChecked: response.data.redactAll !== null && response.data.redactAll,
                            // excludedWords: response.data.excludedWords,
                            // selectedWordsGlobal: response.data.selectedWordsGlobal,
                            // excludedDictionaryGlobal: this.state.excludedDictionaryGlobal.concat(response.data.excludedDictionaries),
                            // excludedDictionaryResponse: this.state.excludedDictionaryGlobal.concat(response.data.excludedDictionaries),
                            // excludedPiiGlobal: this.state.excludedPiiGlobal.concat(response.data.excludedPiiCategories),
                            // excludedPiiCategoryResponse: this.state.excludedPiiGlobal.concat(response.data.excludedPiiCategories),
                            // excludedRedactedWord: this.state.excludedRedactedWord.concat(response.data.excludedRedactedWords),
                            // selectedDictionaryGlobal: this.state.selectedDictionaryGlobal.concat(response.data.selectedDictionaries),
                            // selectedDictionaryGlobalResponse: this.state.selectedDictionaryGlobal.concat(response.data.selectedDictionaries),
                            // selectedPiiGlobal: response.data.selectedPiiCategories,
                            // selectedPiiGlobalResponse: response.data.selectedPiiCategories,
                            // selectedWordGlobal: response.data.selectedWords,
                            // selectedRegExGlobalResponse: response.data.selectedRegex ? response.data.selectedRegex : [],
                            // excludedRegExResponse: response.data.excludedRegex ? response.data.excludedRegex : [],
                            //selectedPiiGlobal: this.state.selectedPiiGlobal.concat(response.data.folderRedactionDTO.selectedPiiCategories),
                            //checkedKeysPii: uniqueCheckedKeysPii,
                            //checkedKeysPii: uniqueCheckedKeysPii,
                            //selectedWordGlobal: response.data.folderRedactionDTO ? this.state.selectedWordGlobal.concat(response.data.folderRedactionDTO.selectedWords) : [],
                            //checkedKeysDictionary: uniqueCheckedKeysDict,
                            //checkedKeysRegEx: uniqueCheckedKeysRegEx,
                            //currentPage: response.data.folderRedactionDTO.pageNr,
                            //redactionPerPage: response.data.folderRedactionDTO.pageRows,
                        })
                        // const selectedWordsUpdate = []
                        // if (nonSelectedWords && nonSelectedWords.length > 0) {
                        //     response.data.selectedWords && response.data.selectedWords.length > 0 && response.data.selectedWords.forEach((item) => {
                        //         if (!nonSelectedWords.includes(item)) {
                        //             selectedWordsUpdate.push(item)
                        //         }
                        //     })
                        // }
                        // this.setState({
                        //     selectedWordGlobal: response.data ? this.state.selectedWordGlobal.concat(selectedWordsUpdate) : [],
                        // })
                        newBulkRedaction && newBulkRedaction.length > 0 && newBulkRedaction.forEach((key, id) => {
                            // if (key.redacted) {
                            //     if (redactAllGlobal) {
                            //         if (!nonSelectedWords.includes(key.word)) {
                            //             checkedKeys.push(id)
                            //             checkedKeysWord.push(key.word)
                            //         }
                            //     }
                            //     if (!redactAllGlobal) {
                            //         if (response.data.redactAll && checkLoadFirstPage) {
                            //             checkedKeys = []
                            //             checkedKeysWord = []
                            //         }
                            //         if (!nonSelectedWords.includes(key.word)) {
                            //             checkedKeys.push(id)
                            //             checkedKeysWord.push(key.word)
                            //         }
                            //
                            //     }
                            // }
                            selectedPagesRows && selectedPagesRows.length > 0 && selectedPagesRows.forEach((keys) => {
                                if (key.word === keys.word) {
                                    if (!nonSelectedWords.includes(key.word)) {
                                        checkedKeysPages.push(id)
                                    }
                                }
                            })
                        })
                        if ( newBulkRedaction && newBulkRedaction.length > 0) {
                            //change 18
                            // this.setState({
                            //     selectedRowKeys: checkedKeys,
                            //     selectedRowWord: checkedKeysWord,
                            // })
                        }

                    }
                    const updateCheckbox = checkedKeys.concat(checkedKeysPages)
                    let duplicateNewListId = []
                    duplicateNewListId = new Set([...updateCheckbox])
                    const filterFilesIdFinally = [...duplicateNewListId]
                    if ( newBulkRedaction && newBulkRedaction.length > 0) {
                        //change 1
                        this.setState({
                            //selectedRowKeys: filterFilesIdFinally,
                        })
                    }
                }
            })
            this.setState({
                statusFolder: true
            }, () => {
                //this.getNewBulkRedaction()
            })
        }
    onGetSaveRedactionFilterSelections = () => {
        const { valueOptionAll, uniqueIdFolderRedaction, valueInputSearchRedact, selectedPiiGlobal, redactAllGlobal, selectedDictionaryGlobal,
            selectedWordGlobal, excludedPiiGlobal, excludedDictionaryGlobal, excludedPiiCategoryResponse,
            selectedPiiGlobalResponse, selectedDictionaryGlobalResponse, excludedDictionaryResponse,
            excludedRegExResponse, selectedRegExGlobalResponse, excludedWords } = this.state
        const { nonSelectedWords  } = this.props
        let  nonSelectedWords2 = []
        const uniqueSelectedPiiGlobal = [...new Set(selectedPiiGlobalResponse)]
        const uniqueSelectedDictionaryGlobal = [...new Set(selectedDictionaryGlobalResponse)]
        const uniqueGlobalWord = [...new Set(selectedWordGlobal)]
        const uniqueExcludedPiiCategories = [...new Set(excludedPiiCategoryResponse)]
        const uniqueExcludedDictionaryGlobal = [...new Set(excludedDictionaryResponse)]
        const uniqueSelectedRegExGlobalResponse = [...new Set(selectedRegExGlobalResponse)]
        const uniqueExcludedRegExResponse = [...new Set(excludedRegExResponse)]

        if (this.props.nonSelectedWords.filter((item) => !uniqueGlobalWord.includes(item))) {
            nonSelectedWords2 = this.props.nonSelectedWords.filter((item) => !uniqueGlobalWord.includes(item))
        }

        const uniqueNonSelectedWords2 = [...new Set(nonSelectedWords2)]
        const saveRedactionFiltersSelectionsDTO = {
            uid: uniqueIdFolderRedaction,
            filterRedactionType: valueOptionAll,
            redactAll: redactAllGlobal,
            filterSearchTerm: valueInputSearchRedact,
             selectedPiiCategory: uniqueSelectedPiiGlobal,
            excludedPiiCategory: uniqueExcludedPiiCategories,
            selectedWord: uniqueGlobalWord,
            excludedWord: uniqueNonSelectedWords2,
            selectedDictionary: selectedDictionaryGlobalResponse,
            excludedDictionary: uniqueExcludedDictionaryGlobal,
            selectedRegex: uniqueSelectedRegExGlobalResponse,
            excludedRegex: uniqueExcludedRegExResponse,
        }
        const { filterRedactionTypeNew, filterSearchTermNew, selectedPiiCategoryNew, selectedRegexNew, selectedDictionaryNew, selectedWordNew, excludedWordNew,
            redactAllNew} = this.state
        const saveRedactionFiltersSelectionsDTOUpdate = {
            uid: uniqueIdFolderRedaction,
            filterRedactionType: filterRedactionTypeNew,
            filterSearchTerm: filterSearchTermNew,
            selectedPiiCategory: selectedPiiCategoryNew,
            selectedRegex: selectedRegexNew,
            selectedDictionary: selectedDictionaryNew,
            selectedWord: selectedWordNew,
            excludedWord: excludedWordNew,
            redactAllCustom: redactAllNew,
            redactAll: redactAllGlobal,
        }

        this.setState({
           // uniqueIdFolderRedactionLastSelection : uniqueIdFolderRedaction,
            statusResponseSaveRedaction: true
        }, () => {
            apiSaveRedactionFilterSelections(saveRedactionFiltersSelectionsDTOUpdate).then((response) => {
                // if (response.data.excludedWords && response.data.excludedWords.length > 0) {
                //     this.props.setRedactionNonSelectedWords(response.data.excludedWords)
                // }
                this.setState({
                    // excludedPiiCategoryResponse: response.data.excludedPiiCategory,
                    // excludedDictionaryResponse: response.data.excludedDictionary,
                    // selectedDictionaryGlobalResponse: response.data.selectedDictionary,
                    // excludedRegExResponse: response.data.excludedRegex,
                    // selectedRegExGlobalResponse: response.data.selectedRegex,
                    //selectedWordGlobal: response.data.selectedWord,
                    statusResponseSaveRedaction: false,
                    redactAllNew: response.data.redactAllCustom,
                    redactAllGlobal: response.data.redactAll,
                    statusSaveRedaction: true,
                })
            })
        })
    }
    getNewBulkRedaction = () => {
        const { valueOptionAll, treeAllPii, currentPage, redactionPerPage, valueInputSearchRedact, startBulkRedaction, dictionariesListIds, statusSorter, totalRecords,
             allCheckedNodesDetail, checkedKeysDictionary, regexListSelected, checkedKeysPii,
            // selectedPagesRows,
            selectedCheckbox, checkLoadFirstPage, redactAllGlobal, selectedRowWordAll, excludedWords, selectedWordsGlobal, selectedWordGlobal, firstLoadPage,
            regexListIds, regexSelectedId, statusSelectAll, selectedPiiGlobal, uniqueIdFolderRedaction, filterSearchTermNew } = this.state
        const { nonSelectedWords } = this.props
        //const checkOptionAll = valueOptionAll === 'all' ? null : valueOptionAll === 'redacted' ? true : false
        const secondLayerFilters = []
        const thirdLayerFilters = []
        let checkedKeys = []
        let checkedKeysPages = []
        const extractWordSaved = []
        const extractWordSavedNon = []
        treeAllPii && treeAllPii.length > 0 && treeAllPii.forEach((tree) => {
            allCheckedNodesDetail && allCheckedNodesDetail.length > 0 && allCheckedNodesDetail.forEach((check) => {
                if (tree.key === check.node.key && check.children && check.children.length > 0) {
                    secondLayerFilters.push(check.node.key[0].toUpperCase() + check.node.key.substring(1).toLowerCase())
                } else if (!check.children) {
                    thirdLayerFilters.push(check.node.props.value)
                }
            })
        })
        const uniqueThirdLayerFilters = [...new Set(thirdLayerFilters)]
        const totalPages =  Math.ceil(totalRecords / redactionPerPage)
        const redactionTermListDTO = {
            uid: uniqueIdFolderRedaction,
            folderPath: this.props.folder.path,
            //displayRedactedTerms: checkOptionAll,
            redactionType: valueOptionAll,
            dictionaryIds: checkedKeysDictionary && checkedKeysDictionary.length > 0 ? dictionariesListIds : [],
            regexList: regexSelectedId,
            secondLayerFilters: checkedKeysPii.length === 0 ? [] : secondLayerFilters,
            thirdLayerFilters: checkedKeysPii.length === 0 ? [] : uniqueThirdLayerFilters,
            pageNr: statusSorter === 'descend' ? (totalPages - currentPage) + 1 : currentPage,
            pageRows: redactionPerPage,
            searchTerm: valueInputSearchRedact ? valueInputSearchRedact : null
        }
            apiSmartNewBulkRedaction(redactionTermListDTO).then((response) => {
                if (response.status === 200) {
                    this.setState({
                        redactAllNew: response.data.redactAllCustom
                    })
                    selectedRowWordAll && selectedRowWordAll.length > 0 && selectedRowWordAll.forEach((item) => {
                        if (excludedWords && excludedWords.length > 0) {
                            if (excludedWords.includes(item)) {
                                extractWordSaved.push(item)
                            }
                        }
                    })
                    if (excludedWords && excludedWords.length > 0) {
                        const allNonWords = this.props.nonSelectedWords.concat(excludedWords)
                        allNonWords && allNonWords.length > 0 && allNonWords.forEach((item2) => {
                            if (!extractWordSaved.includes(item2)) {
                                extractWordSavedNon.push(item2)
                            }
                        })
                        this.props.setRedactionNonSelectedWords(selectedCheckbox ? extractWordSavedNon : extractWordSavedNon)
                        // this.setState({
                        //     nonSelectedWords: selectedCheckbox ? extractWordSavedNon : extractWordSavedNon,
                        // })
                    }
                        // this.setState({
                        //     nonSelectedWords: selectedCheckbox ? extractWordSavedNon : extractWordSavedNon,
                        // })
                    const selectedWordsUpdate = []
                    const selectedRowKeys2  = []
                    if (nonSelectedWords && nonSelectedWords.length > 0) {
                        selectedWordsGlobal && selectedWordsGlobal.length > 0 && selectedWordsGlobal.forEach((item, index) => {
                            if (!nonSelectedWords.includes(item)) {
                                selectedWordsUpdate.push(item)
                                selectedRowKeys2.push(index)
                            }
                        })
                    }
                    this.setState({
                        selectedWordGlobal: response.data ? this.state.selectedWordGlobal.concat(selectedWordsUpdate) : [],
                        //selectedRowKeys : this.state.selectedRowKeys.concat(selectedRowKeys2),
                    })
                   // const checkKeysPii = this.state.checkedKeysPii.concat(response.data.folderRedactionDTO.selectedPiiCategories)
                   //  const checkKeysDict = this.state.checkedKeysDictionary.concat(response.data.folderRedactionDTO.selectedDictionaries)
                   //  const checkKeysRegEx = response.data.folderRedactionDTO.selectedWords
                   //  const checkKeysDictFinally = []
                   //  const checkKeysRegExFinally = []
                   //  const uniqueCheckedKeysPii = [...new Set(checkKeysPii)]
                    // dictionariesData && dictionariesData.length > 0 && dictionariesData.forEach((dict) => {
                    //     if (checkKeysDict.includes(dict.id)) {
                    //         checkKeysDictFinally.push(dict.name)
                    //     }
                    // })
                    // regexList && regexList.length > 0 && regexList.forEach((reg) => {
                    //     if (checkKeysRegEx.includes(reg.regex)) {
                    //         checkKeysRegExFinally.push(reg.regex)
                    //     }
                    // })
                    // const newListBulk = []
                    // const uniqueCheckedKeysDict = [...new Set(checkKeysDictFinally)]
                    // const uniqueCheckedKeysRegEx = [...new Set(checkKeysRegExFinally)]

                    // if (response.data.folderRedactionDTO && Object.keys(response.data.folderRedactionDTO).length > 0) {
                    //
                    //     selectedRowWordAll && selectedRowWordAll.length > 0 && selectedRowWordAll.forEach((item) => {
                    //         if (response.data.folderRedactionDTO.excludedWords && response.data.folderRedactionDTO.excludedWords.length > 0) {
                    //             if (response.data.folderRedactionDTO.excludedWords.includes(item)) {
                    //                 extractWordSaved.push(item)
                    //             }
                    //         }
                    //     })

                    //     if (response.data.folderRedactionDTO.excludedWords && response.data.folderRedactionDTO.excludedWords.length > 0) {
                    //         const allNonWords = this.state.nonSelectedWords.concat(response.data.folderRedactionDTO.excludedWords)
                    //         allNonWords && allNonWords.length > 0 && allNonWords.forEach((item2) => {
                    //             if (!extractWordSaved.includes(item2)) {
                    //                 extractWordSavedNon.push(item2)
                    //             }
                    //         })
                    //     }

                    //     this.setState({
                    //         redactAllGlobal: !checkLoadFirstPage && response.data.folderRedactionDTO.redactAll,
                    //         excludedWordGlobal: selectedCheckbox ? this.state.excludedWordGlobal : this.state.excludedWordGlobal.concat(response.data.folderRedactionDTO.excludedWords),
                    //         nonSelectedWords: selectedCheckbox ? extractWordSavedNon : extractWordSavedNon,
                    //         nonSelectedResponseApi: this.state.nonSelectedWords.concat(response.data.folderRedactionDTO.excludedWords),
                    //         excludedDictionaryGlobal: this.state.excludedDictionaryGlobal.concat(response.data.folderRedactionDTO.excludedDictionaries),
                    //         excludedPiiGlobal: this.state.excludedPiiGlobal.concat(response.data.folderRedactionDTO.excludedPiiCategories),
                    //         excludedRedactedWord: this.state.excludedRedactedWord.concat(response.data.folderRedactionDTO.excludedRedactedWords),
                    //         selectedDictionaryGlobal: this.state.selectedDictionaryGlobal.concat(response.data.folderRedactionDTO.selectedDictionaries),
                    //         //selectedPiiGlobal: this.state.selectedPiiGlobal.concat(response.data.folderRedactionDTO.selectedPiiCategories),
                    //         //checkedKeysPii: uniqueCheckedKeysPii,
                    //         //checkedKeysPii: uniqueCheckedKeysPii,
                    //         //selectedWordGlobal: response.data.folderRedactionDTO ? this.state.selectedWordGlobal.concat(response.data.folderRedactionDTO.selectedWords) : [],
                    //         //checkedKeysDictionary: uniqueCheckedKeysDict,
                    //         //checkedKeysRegEx: uniqueCheckedKeysRegEx,
                    //         //currentPage: response.data.folderRedactionDTO.pageNr,
                    //         //redactionPerPage: response.data.folderRedactionDTO.pageRows,
                    //     })
                    //     const selectedWordsUpdate = []
                    //     if (nonSelectedWords && nonSelectedWords.length > 0) {
                    //         response.data.folderRedactionDTO.selectedWords && response.data.folderRedactionDTO.selectedWords.length > 0 && response.data.folderRedactionDTO.selectedWords.forEach((item) => {
                    //             if (!nonSelectedWords.includes(item)) {
                    //                 selectedWordsUpdate.push(item)
                    //             }
                    //         })
                    //     }
                    //     this.setState({
                    //         selectedWordGlobal: response.data.folderRedactionDTO ? this.state.selectedWordGlobal.concat(selectedWordsUpdate) : [],
                    //     })
                    // }

                    if (response.data === 'Processing in progress, this may take several minutes.') {
                        //CustomNotificationManager.info('Processing in progress, this may take several minutes.')
                    }
                    // response.data.reducedResult && response.data.reducedResult.length > 0 && response.data.reducedResult.forEach((key, id) => {
                    //     key.id = id ? id + 1 : 1
                    //     key.key = id
                    //     key.status = 'start'
                    //     if (key.redacted && !nonSelectedWords.includes(key.word)) {
                    //         checkedKeys.push(id)
                    //         this.setState({
                    //             selectedRowKeys: checkedKeys,
                    //             //selectedRowWord: selectAllWords,
                    //         })
                    //     }
                    //     selectedPagesRows && selectedPagesRows.length > 0 && selectedPagesRows.forEach((keys) => {
                    //         if (key.word === keys.word) {
                    //             checkedKeysPages.push(id)
                    //         }
                    //     })
                    //
                    // })
                    let listReducedResult = response.data.reducedResult
                    let checkedKeysWord = []
                    // if (statusSorter === 'descend') {
                    //     listReducedResult = response.data.reducedResult && response.data.reducedResult.length > 0 ? response.data.reducedResult.sort((userA, userB) => (userB.word.localeCompare(userA.word))) : []
                    // } else {
                    //     listReducedResult = response.data.reducedResult && response.data.reducedResult.length > 0 ? response.data.reducedResult.sort((userA, userB) => (userA.word.localeCompare(userB.word))) : []
                    // }
                    // let selectedPagesRowsUpdate = []
                    // if (response.data.folderRedactionDTO && response.data.folderRedactionDTO.selectedWords && response.data.folderRedactionDTO.selectedWords.length > 0) {
                    //     selectedPagesRowsUpdate = selectedPagesRows.concat(response.data.folderRedactionDTO.selectedWords)
                    // } else {
                    //     selectedPagesRowsUpdate = selectedPagesRows
                    // }
                    const selectedKeysRedact = []
                    listReducedResult && listReducedResult.length > 0 && listReducedResult.forEach((key, id) => {
                        if (key.selected) {
                            selectedKeysRedact.push(id)
                        }
                        key.id = id ? id + 1 : 1
                        key.key = id
                        key.status = 'start'
                        // if (key.redacted) {
                        //     if (redactAllGlobal) {
                        //      if (!nonSelectedWords.includes(key.word)) {
                        //             checkedKeys.push(id)
                        //             checkedKeysWord.push(key.word)
                        //         }
                        //     }
                        //    if (!redactAllGlobal) {
                        //         if (checkLoadFirstPage) {
                        //             if (selectedWordGlobal.length === 0) {
                        //                 checkedKeys = []
                        //                 checkedKeysWord = []
                        //             } else if (selectedWordGlobal && selectedWordGlobal.length > 0 && selectedWordGlobal.includes(key.word)) {
                        //                 checkedKeys.push(id)
                        //                 checkedKeysWord.push(key.word)
                        //             }
                        //         } else if (!nonSelectedWords.includes(key.word)) {
                        //             if (selectedPiiGlobal && selectedPiiGlobal.length > 0) {
                        //                key.secondLayerCategories && key.secondLayerCategories.length > 0 && key.secondLayerCategories.forEach((key2) => {
                        //                    if (selectedPiiGlobal.includes(key2)) {
                        //                        checkedKeys.push(id)
                        //                        checkedKeysWord.push(key.word)
                        //                    }
                        //                })
                        //             } else {
                        //                 checkedKeys.push(id)
                        //                 checkedKeysWord.push(key.word)
                        //             }
                        //         } else if (this.state.selectedPiiGlobal && this.state.selectedPiiGlobal.length > 0 && nonSelectedWords.length === 0) {
                        //             // checkedKeys.push(id)
                        //             // checkedKeysWord.push(key.word)
                        //         }
                        //
                        //    }
                        // }
                        //selectedPagesRows
                        // if ( selectedWordGlobal && selectedWordGlobal.length > 0) {
                        //     if (selectedWordGlobal.includes(key.word)) {
                        //         // if (checkLoadFirstPage) {
                        //         //     checkedKeysPages = []
                        //         // } else
                        //         if (nonSelectedWords.length > 0) {
                        //             if (!nonSelectedWords.includes(key.word)) {
                        //                 checkedKeysPages.push(id)
                        //             }
                        //         } else {
                        //             checkedKeysPages.push(id)
                        //         }
                        //     }
                        // }

                        //change11 19 martie
                        if (selectedWordGlobal && selectedWordGlobal.length > 0) {
                            if (selectedWordGlobal.includes(key.word)) {
                                checkedKeysPages.push(id)
                            }
                        }
                        //change 2
                        // selectedWordGlobal && selectedWordGlobal.length > 0 && selectedWordGlobal.forEach((keys) => {
                        //     if (keys === key.word) {
                        //         if (checkLoadFirstPage) {
                        //             //checkedKeysPages = []
                        //             checkedKeysPages.push(id)
                        //         }
                        //         else
                        //         if (!nonSelectedWords.includes(key.word)) {
                        //             checkedKeysPages.push(id)
                        //         }
                        //     }
                        // })
                    })
                    this.setState({
                        //selectedRowKeys: checkedKeys,
                        selectedRowWord: checkedKeysWord,
                        selectedRowKeys: selectedKeysRedact,
                    })

                    const updateCheckbox = checkedKeys.concat(checkedKeysPages)
                    let duplicateNewListId = []
                    duplicateNewListId = new Set([...updateCheckbox])
                    const filterFilesIdFinally = [...duplicateNewListId]
                    if (firstLoadPage && response.data.totalRecords === response.data.totalRecordsRedacted) {
                        this.setState({
                            redactAllGlobal: true
                        })
                    }
                    //change 2
                    this.setState({
                        newBulkRedaction: listReducedResult,
                        totalRecords: response.data.totalRecords,
                        //selectedRowKeys: filterFilesIdFinally,
                        statusActivePagination: false
                    }, () => { this.updatePreviewNextPageNumber()
                        //this.onGetOptionSelectedRedaction()
                        this.onChangePageCheckSelectKeys()})
                    this.setState({
                        firstLoadPage: false
                    })
                }
            }).catch((error) => {
                if (error?.response?.status === 504) {
                    CustomNotificationManager.cleanBuffer('warning', '')
                }else{
                    console.log(error)
                }
            })
    }
    getDictionariesData = () => {
        const dictionariesData= []
        const dictionariesDataId= []
        getAllBusinessList().then((response) => {
            let uniqueDictionariesData = []
            let uniqueResponse = []
            if (response.status === 200) {
                uniqueResponse = response.data && response.data.length > 0 && response.data.filter((a, i) => response.data.findIndex((s) => a.listName === s.listName) === i)
                uniqueResponse && uniqueResponse.length > 0 && uniqueResponse.map( (item) => {
                    const temp = {}
                    temp.id = item.id
                    temp.name = item.listName
                    temp.description = item.description
                    temp.displayTerm = item.displayTerm
                    temp.colour = item.colour ? this.replaceAlpha(item.colour) : 'rgba(77, 100, 113, 1)'
                    if (item.terms && item.terms.length) {
                        temp.childrens= []
                        item.terms.forEach( (value) => {
                            temp.childrens.push({id: value.id, name: value.term})
                        })
                    }
                    dictionariesData.push(temp)
                    dictionariesDataId.push(item.id)
                    return true
                })
                uniqueDictionariesData = dictionariesData && dictionariesData.length > 0 && dictionariesData.filter((a, i) => dictionariesData.findIndex((s) => a.listName === s.listName) === i)

            }
            this.setState({
                dictionariesData,
                uniqueDictionariesData,
                getSelectedDictionaries: dictionariesDataId,
                selectedDictionaries: uniqueResponse
            }, () => {
                //this.onGetSelectedDictionaries()
            })
        }).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
            }else{
                console.log(error)
            }
        })
    }
    getRegularExpressions = () => {
        getSmartBoxAllRegexList().then((res) => {
            if (res.status === 200) {
                let regexList = []
                let regexList2 = []
                for (let value of Object.values(res.data)) {
                    delete value.folderId
                    //delete value.id
                    regexList.push(value)
                }
                regexList = res.data
                regexList.sort((a,b) => a.title.localeCompare(b.title) )
                regexList && regexList.length > 0 && regexList.forEach((item, index) => {
                    //item.id = index
                    item.key = index
                    item.idRegex = item.id
                    regexList2.push(item.regex)
                })
                this.setState({ regexList, selectedRegEx: regexList, getSelectedRegEx: regexList2, listNewRegEx: res.data }, () => {
                    //this.onGetSelectedRegex()
                })
            }
        }).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
            }else{
                console.log(error)
            }
        })
    }
    replaceAlpha = (color) => {
        const temp = color.split(',')
        if (temp.length === 4) {
            temp[3] = " 1)"
            color = temp.join()
        }
        return color
    }
    onRowSelected = (record, selected, selectedRows, nativeEvent) => {
        const { selectedWordNew } = this.state
        if (selected) {
           this.setState({
               selectedWordNew: record.word,
               excludedWordNew: ''
           })
        } else {
            this.setState({
                selectedWordNew: '',
                excludedWordNew: record.word
            })
        }
    }
    onChangeRowRedact = (selectedRowKeys, filesSelect, e) => {
        const { selectAllChecked, newBulkRedaction, extractKeysPiiChecked, selectedKeysOnPii,
            redactAllGlobal, selectedPiiGlobal, nonSelectedResponseApi, selectedWordGlobal, valueInputSearchRedact, excludedPiiGlobal } = this.state
        //let { nonSelectedWords } = this.state
        let { nonSelectedWords } = this.props
        const selectRow = []
        const notIncludesWordPii = []
        const unselectedRows = []
        const unselectedRowsFull = []
        const unselectedExtractRows = []

        this.setState({
            selectedCheckbox: true,
            statusOpenAddTerm: false,
            selectedRowKeysUpdate: selectedRowKeys,
        })
        filesSelect && filesSelect.length > 0 && filesSelect.forEach((selectedWord) => {
            unselectedExtractRows.push(selectedWord.word)
        })
        let uniqueFilesSelectedGlobal = []
        const uniqueFilesSelected = unselectedExtractRows && unselectedExtractRows.length > 0 && unselectedExtractRows.filter((a, i) => unselectedExtractRows.findIndex((s) => a === s) === i)
        if (selectedWordGlobal && selectedWordGlobal.length > 0) {
            uniqueFilesSelectedGlobal = selectedWordGlobal.filter((a, i) => selectedWordGlobal.findIndex((s) => a === s) === i)
        }
        if (uniqueFilesSelected && uniqueFilesSelected.length === 1 && uniqueFilesSelected.includes('add new dictionary') && uniqueFilesSelectedGlobal.length === 0) {
            this.setState({
                statusOpenAddTerm: true
            })
        }
        newBulkRedaction && newBulkRedaction.length > 0 && newBulkRedaction.forEach((bulk) => {
           if (!unselectedExtractRows.includes(bulk.word)) {
               unselectedRows.push(bulk.word)
               unselectedRowsFull.push(bulk)
           }
        })
        filesSelect && filesSelect.length > 0 && filesSelect.forEach((row) => {
            selectRow.push(row.word)
        })
        if (selectedRowKeys && selectedRowKeys.length > 0) {
            this.setState({
                checkBulkRedaction: true
            })
        }
        //let selectAllKeys = []
            //selectAllKeys = this.state.selectedRowKeys.concat(nonSelectedKeys)
            //const uniqueSelectAllKeys = selectAllKeys && selectAllKeys.length > 0 && selectAllKeys.filter((a, i) => selectAllKeys.findIndex((s) => a === s) === i)
            //nonSelectedWords = nonSelectedWords.filter((non) => selectedRowKeys.map((sel) => non !== sel))
            selectedRowKeys && selectedRowKeys.length > 0 && selectedRowKeys.forEach((sel1) => {
                nonSelectedWords = nonSelectedWords.filter((f) => f !== sel1)
            })
        this.props.setRedactionNonSelectedWords(nonSelectedWords)
            // this.setState({
            //     nonSelectedWords
            // })
         if (redactAllGlobal) {
             //if (nonSelectedResponseApi && nonSelectedResponseApi.length > 0) {
                 let filterNonSelected = []
                 let filterNonSelectedUpdate = []
             filterNonSelectedUpdate = selectRow.concat(this.props.nonSelectedWords)
             newBulkRedaction && newBulkRedaction.length > 0 && newBulkRedaction.forEach((bulk) => {
                 if ( nonSelectedWords && nonSelectedWords.length > 0) {
                 nonSelectedWords && nonSelectedWords.length > 0 && nonSelectedWords.forEach((row) => {
                     //filterNonSelected = filterNonSelected.concat(this.state.nonSelectedWords)
                     //filterNonSelected = nonSelectedWords.filter((item) => item !== row.word)
                     if (!selectRow.includes(row) && !selectRow.includes(bulk.word)) {
                         //filterNonSelected.push(bulk.word)
                         if (!nonSelectedWords.includes(bulk.word)) {
                             filterNonSelected = filterNonSelected.concat(this.props.nonSelectedWords)
                             filterNonSelected.push(bulk.word)
                         } else  if (nonSelectedWords.includes(bulk.word)) {
                             //filterNonSelected = []
                             filterNonSelected.push(bulk.word)
                         }
                     }
                 })
                 }
                 else if (valueInputSearchRedact.length > 0) {
                     if (!selectRow.includes(bulk.word)) {
                         //filterNonSelected.push(bulk.word)
                         if (!nonSelectedWords.includes(bulk.word)) {
                             filterNonSelected = filterNonSelected.concat(this.props.nonSelectedWords)
                             filterNonSelected.push(bulk.word)
                         } else  if (nonSelectedWords.includes(bulk.word)) {
                             //filterNonSelected = []
                             filterNonSelected.push(bulk.word)
                         }
                     }
                 } else if (excludedPiiGlobal && excludedPiiGlobal.length > 0) {
                     if (!selectRow.includes(bulk.word)) {
                         //filterNonSelected.push(bulk.word)
                         if (!nonSelectedWords.includes(bulk.word)) {
                             filterNonSelected = filterNonSelected.concat(this.props.nonSelectedWords)
                             filterNonSelected.push(bulk.word)
                         } else  if (nonSelectedWords.includes(bulk.word)) {
                             //filterNonSelected = []
                             filterNonSelected.push(bulk.word)
                         }
                     }
                 }
             })
                 if (filterNonSelected.length === 0) {
                     this.setState({
                         nonSelectedResponseApi: []
                     })
                 }
             const uniqueFilterNonSelected = [...new Set(filterNonSelected)]
             this.props.setRedactionNonSelectedWords(uniqueFilterNonSelected)
                     this.setState({
                         //nonSelectedWords: uniqueFilterNonSelected,
                         //nonSelectedWords: uniqueFilterNonSelected.concat(this.state.nonSelectedWords),
                         excludedWordGlobal: filterNonSelected,
                     })
             //}
         }
        if (selectAllChecked) {
            let nonSelectedWordsUpdateTwo = []
            let nonSelectedWordsUpdateThree = []
            let allWords = []
            let nonSelectedWords2 = []
            newBulkRedaction && newBulkRedaction.length > 0 && newBulkRedaction.forEach((bulk) => {
                allWords.push(bulk.word)
                if(!selectedRowKeys.includes(bulk.key)) {
                    this.props.setRedactionNonSelectedWords([...this.props.nonSelectedWords, bulk.word])
                   this.setState({
                       nonSelectedKeys: [...this.state.nonSelectedKeys, bulk.key],
                       //nonSelectedWords: [...this.state.nonSelectedWords, bulk.word],
                   }, () => {
                       if (filesSelect && filesSelect.length > 0) {
                           filesSelect.forEach((non1) => {
                               const nonSelectedWordsUpdate = this.props.nonSelectedWords.filter((item) => item !== non1.word)
                               //nonSelectedWordsUpdateTwo = this.state.nonSelectedWords.filter((item) => item === non1.word)
                               this.props.nonSelectedWords && this.props.nonSelectedWords.length > 0 && this.props.nonSelectedWords.forEach((elem) => {
                                   if (elem === non1.word) {
                                       nonSelectedWordsUpdateTwo.push(elem)
                                   }
                               })
                               this.props.nonSelectedWords && this.props.nonSelectedWords.length > 0 && this.props.nonSelectedWords.forEach((elem) => {
                                   if (elem !== non1.word) {
                                       nonSelectedWordsUpdateThree.push(non1.word)
                                   }
                               })
                               nonSelectedWords2 = [...new Set(nonSelectedWordsUpdateThree)]
                               const nonSelectedKeysUpdate = this.state.nonSelectedKeys.filter((item) => item !== non1.key)
                               if (!this.props.nonSelectedWords.includes(non1)) {
                                   nonSelectedWordsUpdate.forEach((item2) => {
                                       this.props.setRedactionNonSelectedWords([...new Set(nonSelectedWordsUpdate)])
                                       this.setState({
                                           nonSelectedKeys: [...new Set(this.props.nonSelectedWords.concat(nonSelectedKeysUpdate))],
                                           //nonSelectedWords: [...new Set(nonSelectedWordsUpdate)],
                                       })
                                   })
                               }
                           })
                           if (nonSelectedWordsUpdateTwo && nonSelectedWordsUpdateTwo.length > 0) {
                               let nonWordsUpdate = []
                               nonSelectedWordsUpdateTwo.forEach((item2) => {
                                   nonWordsUpdate = this.props.nonSelectedWords.filter((item) => item !== item2)
                               })
                               this.props.setRedactionNonSelectedWords([...new Set(nonWordsUpdate)])
                               this.setState({
                                   //nonSelectedKeys: [...new Set(nonSelectedKeysUpdate)],
                                   //nonSelectedWords: [...new Set(nonWordsUpdate)],
                               })
                           } else
                           if (nonSelectedWords2 && nonSelectedWords2.length > 0 && allWords && allWords.length > 0) {
                               const tempArrNonSelected = allWords.filter((item) => (!nonSelectedWords2.includes(item)))
                               this.props.setRedactionNonSelectedWords([...new Set(this.props.nonSelectedWords.concat(tempArrNonSelected))])
                               this.setState({
                                   //nonSelectedWords: tempArrNonSelected,
                                   //nonSelectedWords: [...new Set(this.state.nonSelectedWords.concat(tempArrNonSelected))]
                               })
                           }
                       } else {
                           this.props.setRedactionNonSelectedWords([...new Set(this.props.nonSelectedWords.concat(allWords))])
                           this.setState({
                               nonSelectedKeys: [...this.state.nonSelectedKeys, bulk.key],
                               //nonSelectedWords: [...new Set(this.state.nonSelectedWords.concat(allWords))],
                           })
                       }
                   })
                } else if (filesSelect.length === newBulkRedaction.length) {
                    let filterWord = []
                    filesSelect && filesSelect.length > 0 && filesSelect.forEach((file) => {
                         if (nonSelectedWords.includes(file.word)) {
                             filterWord = nonSelectedWords.filter((item) => item !== file.word)
                         }
                    })
                    this.props.setRedactionNonSelectedWords(filterWord)
                    // this.setState({
                    //     nonSelectedWords: filterWord
                    // })
                }
            })
        }
        const bulkNonSelect = []
        if (!redactAllGlobal && selectedPiiGlobal && selectedPiiGlobal.length > 0 && selectedWordGlobal.length > 0) {
            const selectedWord3 = []
            newBulkRedaction && newBulkRedaction.length > 0 && newBulkRedaction.forEach((bulk2) => {
                selectedWord3.push(bulk2.word)
            })

            const uniqueGlobalWord = [...new Set(selectedWordGlobal.concat(selectedWord3))]
            let uniqueBulkNonSelect = []
            let nonSelectedKeysUpdate = []
            newBulkRedaction && newBulkRedaction.length > 0 && newBulkRedaction.forEach((bulk) => {
                //if (selectedWordGlobal.includes(bulk.word)) {
                if (uniqueGlobalWord.includes(bulk.word)) {
                    if (!selectedRowKeys.includes(bulk.key)) {
                        bulkNonSelect.push(bulk.word)
                        let uniqueBulkNonSelect2 = [...new Set(this.props.nonSelectedWords.concat(bulkNonSelect))]
                        //change 1111 19 martie
                        // uniqueBulkNonSelect2 && uniqueBulkNonSelect2.length > 0 && uniqueBulkNonSelect2.forEach((nonS) => {
                        //     //if (!this.state.nonSelectedKeys.includes(nonS)) {
                        //     // if (!this.props.nonSelectedWords.includes(nonS)) {
                        //     //     nonSelectedKeysUpdate.push(nonS)
                        //     // }
                        //     uniqueBulkNonSelect2 = uniqueBulkNonSelect2.filter((item3) => !this.props.nonSelectedWords.includes(item3))
                        // })
                        //change 222
                        if (uniqueBulkNonSelect2 && uniqueBulkNonSelect2.length > 0) {
                            //uniqueBulkNonSelect2 = uniqueBulkNonSelect2.filter((item3) => !this.props.nonSelectedWords.includes(item3))
                        }
                        //const uniqueBulkNonSelect = [...new Set(this.state.nonSelectedWords.concat(bulkNonSelect))]
                        //uniqueBulkNonSelect = [...new Set(nonSelectedKeysUpdate)]
                        uniqueBulkNonSelect = [...new Set(uniqueBulkNonSelect2)]
                        //this.props.setRedactionNonSelectedWords(this.props.nonSelectedWords.concat(uniqueBulkNonSelect))
                        this.props.setRedactionNonSelectedWords(uniqueBulkNonSelect)
                        this.setState({
                            nonSelectedKeys: [...this.state.nonSelectedKeys, bulk.key],
                            //nonSelectedWords: [...this.state.nonSelectedWords, bulk.word],
                            //nonSelectedWords: this.state.nonSelectedWords.concat(uniqueBulkNonSelect),
                            //nonSelectedWords: uniqueBulkNonSelect,
                        })
                    }
                }
            })
        } else  if (!redactAllGlobal && valueInputSearchRedact && selectedWordGlobal.length > 0) {
            let uniqueBulkNonSelect = []
            let nonSelectedKeysUpdate = []
            newBulkRedaction && newBulkRedaction.length > 0 && newBulkRedaction.forEach((bulk) => {
                if (selectedWordGlobal.includes(bulk.key)) {
                    if (!selectedRowKeys.includes(bulk.key)) {
                        bulkNonSelect.push(bulk.word)
                        const uniqueBulkNonSelect2 = [...new Set(this.props.nonSelectedWords.concat(bulkNonSelect))]
                        uniqueBulkNonSelect2 && uniqueBulkNonSelect2.length > 0 && uniqueBulkNonSelect2.forEach((nonS) => {
                            if (!this.state.nonSelectedKeys.includes(nonS)) {
                                nonSelectedKeysUpdate.push(nonS)
                            }
                        })
                        //const uniqueBulkNonSelect = [...new Set(this.state.nonSelectedWords.concat(bulkNonSelect))]
                        uniqueBulkNonSelect = [...new Set(nonSelectedKeysUpdate)]
                        this.props.setRedactionNonSelectedWords(uniqueBulkNonSelect)
                        this.setState({
                            nonSelectedKeys: [...this.state.nonSelectedKeys, bulk.key],
                            //nonSelectedWords: [...this.state.nonSelectedWords, bulk.word],
                            //nonSelectedWords: uniqueBulkNonSelect
                        })
                    }
                }
            })
        }
        if (!selectAllChecked && extractKeysPiiChecked.length > 0) {
            filesSelect && filesSelect.length > 0 && filesSelect.forEach((bulk) => {
                notIncludesWordPii.push(bulk.word)
            })
        }
        if (!selectAllChecked && extractKeysPiiChecked.length > 0) {
            selectedKeysOnPii && selectedKeysOnPii.length > 0 && selectedKeysOnPii.forEach((piiKeys) => {
                if (notIncludesWordPii && notIncludesWordPii.length > 0 && !notIncludesWordPii.includes(piiKeys)) {
                    if(!selectedRowKeys.includes(piiKeys)) {
                        this.props.setRedactionNonSelectedWords([...this.props.nonSelectedWords, piiKeys])
                        this.setState({
                            nonSelectedKeys: [...this.state.nonSelectedKeys, piiKeys],
                            //nonSelectedWords: [...this.state.nonSelectedWords, piiKeys],
                        })
                    }
                }
            })
        }
        newBulkRedaction && newBulkRedaction.length > 0 && newBulkRedaction.forEach((redaction) => {
            if(!selectedRowKeys.includes(redaction.key)) {
                //this.props.setRedactionNonSelectedWords([...this.state.excludedWordsBulk, redaction.word])
                this.setState({
                    excludedWordsBulk: [...this.state.excludedWordsBulk, redaction.word],
                    //nonSelectedWords : [...this.state.nonSelectedWords, redaction.word],
                })
            }
        })
        //const newSelectedRows = this.state.selectedPagesRows.concat(filesSelect)
        const newSelectedRows = filesSelect
        let uniqueSelectedRows = [
            ...new Map(newSelectedRows.map((item) => [item["word"], item])).values(),
        ]
        const selectedWords = []
        const selectedWordsFull = []
        uniqueSelectedRows && uniqueSelectedRows.length > 0 && uniqueSelectedRows.forEach((word) => {
            // if (!word.redacted) {
            //     selectedWords.push(word.word)
            //     selectedWordsFull.push(word)
            // }
            selectedWords.push(word.word)
            selectedWordsFull.push(word)
        })
        let blobalWord = this.state.selectedWordGlobal.concat(selectedWords)
        let blobalWordFull = this.state.selectedWordGlobalFull.concat(selectedWordsFull)
        let filterGlobalWord = []
        let filterGlobalWordFull = []

        unselectedRows && unselectedRows.length > 0 && unselectedRows.forEach((row) => {
            if (blobalWord.includes(row)) {
                filterGlobalWord = blobalWord.filter((el) => el !== row)
                blobalWord = filterGlobalWord
            }
        })
        const blobalWordFullFilter = []
        blobalWordFull && blobalWordFull.length > 0 && blobalWordFull.forEach((item) => {
            blobalWordFullFilter.push(item.word)
        })
        unselectedRowsFull && unselectedRowsFull.length > 0 && unselectedRowsFull.forEach((row) => {
            if (blobalWordFullFilter.includes(row.word)) {
                filterGlobalWordFull = blobalWordFull.filter((el) => el.word !== row.word)
                blobalWordFull = filterGlobalWordFull
            }
        })

        const redactedGlobal = []
        // if (!redactAllGlobal) {
        const nonSelectedWordsRedacted = []
            newBulkRedaction && newBulkRedaction.length > 0 && newBulkRedaction.forEach((redaction) => {
                // if (redaction.redacted) {
                //     if(!selectedRowKeys.includes(redaction.key)) {
                //         nonSelectedWordsRedacted.push(redaction.word)
                //         this.props.setRedactionNonSelectedWords([...this.props.nonSelectedWords, redaction.word])
                //         this.setState({
                //             nonSelectedKeys: [...this.state.nonSelectedKeys, redaction.key],
                //             //nonSelectedWords: [...this.state.nonSelectedWords, redaction.word],
                //         })
                //     }
                //     //const totalNonSelectedWordsRedacted = this.state.nonSelectedWords.concat(nonSelectedWordsRedacted)
                //     const totalNonSelectedWordsRedacted = this.props.nonSelectedWords.concat(nonSelectedWordsRedacted)
                //     let totalNonSelectedWordsRedactedFilters = []
                //     if (this.state.selectedWordGlobal) {
                //         if (totalNonSelectedWordsRedacted && totalNonSelectedWordsRedacted.length > 0) {
                //             totalNonSelectedWordsRedactedFilters = totalNonSelectedWordsRedacted.filter((item) => !unselectedExtractRows.includes(item))
                //             const totalNonSelectedWordsRedactedFilters2 = totalNonSelectedWordsRedactedFilters.filter((a, i) => totalNonSelectedWordsRedactedFilters.findIndex((s) => a === s) === i)
                //             this.props.setRedactionNonSelectedWords(totalNonSelectedWordsRedactedFilters2)
                //         }
                //        // const totalNonSelectedWordsRedactedFilters2 = totalNonSelectedWordsRedactedFilters.filter((a, i) => totalNonSelectedWordsRedactedFilters.findIndex((s) => a === s) === i)
                //        //  this.props.setRedactionNonSelectedWords(totalNonSelectedWordsRedactedFilters2)
                //         // this.setState({
                //         //     nonSelectedWords: totalNonSelectedWordsRedactedFilters,
                //         // })
                //     } else {
                //         this.props.setRedactionNonSelectedWords(totalNonSelectedWordsRedacted)
                //         // this.setState({
                //         //     nonSelectedWords: totalNonSelectedWordsRedacted,
                //         // })
                //     }
                //     //const nonRedactionDeselect = filesSelect.filter((item) => item.word === redaction.word)
                //     if(!selectedRowKeys.includes(redaction.key)) {
                //         redactedGlobal.push(redaction.word)
                //     }
                // }
            })
        // }
        const redactedGlobalNew = this.state.excludedRedactedWord.concat(redactedGlobal)
        let uniqueSelectedRowsAll = [
            ...new Map(filesSelect.map((item) => [item["word"], item])).values(),
        ]
        const selectedWordsAll = []
        uniqueSelectedRowsAll && uniqueSelectedRowsAll.length > 0 && uniqueSelectedRowsAll.forEach((word) => {
            selectedWordsAll.push(word.word)
        })
        let selectedRowWordAllFinish = this.state.selectedRowWordAll.concat(selectedWordsAll)
        let filterGlobalWordAll = []
        unselectedRows && unselectedRows.length > 0 && unselectedRows.forEach((row) => {
            if (selectedRowWordAllFinish.includes(row)) {
                filterGlobalWordAll = selectedRowWordAllFinish.filter((el) => el !== row)
                selectedRowWordAllFinish = filterGlobalWordAll
            }
        })
        //const updateNonSelected = []
        let blobalWord2 = []
        let blobalWord2Full = []
        if (blobalWord && blobalWord.length > 0) {
            blobalWord2 = blobalWord.filter((a, i) => blobalWord.findIndex((s) => a === s) === i)
        }
        if (blobalWordFull && blobalWordFull.length > 0) {
            blobalWord2Full = blobalWordFull.filter((a, i) => blobalWordFull.findIndex((s) => a.word === s.word) === i)
        }
        newBulkRedaction && newBulkRedaction.length > 0 && newBulkRedaction.forEach((bulk) => {

        })
        //change 3
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRowWord: selectRow,
            selectedRow: filesSelect,
            selectedPagesRows: uniqueSelectedRows,
            selectedWordGlobal: blobalWord2,
            selectedWordGlobalFull: blobalWord2Full,
            excludedRedactedWord: redactedGlobalNew,
            selectedRowWordAll: selectedRowWordAllFinish
        }, () => {
            if (blobalWord && blobalWord.length) {
                // nonSelectedWords && nonSelectedWords.length > 0 && nonSelectedWords.forEach((non) => {
                //     if (!blobalWord.includes(non)) {
                //         updateNonSelected.push(non)
                //     }
                //     this.setState({
                //         nonSelectedWords: updateNonSelected
                //     })
                // })
            }
            this.setState({

            }, () => {
                this.onGetSaveRedactionFilterSelections()
            })
        })
    }

    updateSelectAllBulkRedaction = (e) => {
        const { checkedKeysPii, treeAllPii, allCheckedNodesDetail, selectedPiiGlobal,
            allCheckedNodesLabel, excludedPiiCategoryResponse, selectedDictionaryGlobalResponse, dictionariesData, checkedKeysDictionary,
            excludedDictionaryResponse, allCheckedNodesLabelDictionaries, allCheckedNodesLabelRegEx, listNewRegEx, excludedRegExResponse,
            selectedWordGlobalFull, selectedWordGlobal, redactAllGlobal, selectedPiiGlobalResponse, valueOptionAll, valueInputSearchRedact, checkedKeysRegEx } = this.state
        const secondLayerFiltersUpdate = []
        const thirdLayerFiltersUpdate = []
        let excludedPiiCategoryResponse2 = []
        let allPiiFinish = []
        let allPiiFinish2 = []
        let allPiiFinishDictionaries = []
        let allPiiFinishExcluded = []
        let allFinishRegEx = []
        let allRegExFinishExcluded = []

        let finishDictionaryUpdate = []
        let finishDictionaryUpdate2 = []
        let finishDictionaryExcluded = []
        let finishDictionaryExcluded2 = []
        let finishDictionaryNameExcluded = []

        let finishRegExUpdate = []
        let finishRegExUpdate2 = []
        let finishRegExExcluded = []
        let finishRegExExcluded2 = []
        treeAllPii && treeAllPii.length > 0 && treeAllPii.forEach((tree) => {
            allCheckedNodesLabel.allCheckedNodes && allCheckedNodesLabel.allCheckedNodes.length > 0 && allCheckedNodesLabel.allCheckedNodes.forEach((check) => {
                if (tree.key === check.node.key && check.children && check.children.length > 0) {
                    secondLayerFiltersUpdate.push(`${check.node.key[0].toUpperCase() + check.node.key.substring(1).toLowerCase()}#--#${valueInputSearchRedact}#--#${valueOptionAll}`)
                } else if (!check.children) {
                    thirdLayerFiltersUpdate.push(`${check.node.props.value}#--#${valueInputSearchRedact}#--#${valueOptionAll}`)
                }
            })
        })
        allPiiFinish = this.state.selectedPiiGlobalResponse.concat(secondLayerFiltersUpdate, thirdLayerFiltersUpdate)

        dictionariesData && dictionariesData.length > 0 && dictionariesData.forEach((dict) => {
            allCheckedNodesLabelDictionaries.allCheckedNodes && allCheckedNodesLabelDictionaries.allCheckedNodes.length > 0 && allCheckedNodesLabelDictionaries.allCheckedNodes.forEach((check) => {
                if (dict.id === Number(check.node.key)) {
                    finishDictionaryUpdate.push(dict.id)
                }
            })
        })
        allPiiFinishDictionaries = this.state.selectedDictionaryGlobalResponse.concat(finishDictionaryUpdate)


        listNewRegEx && listNewRegEx.length > 0 && listNewRegEx.forEach((reg) => {
            allCheckedNodesLabelRegEx.allCheckedNodes && allCheckedNodesLabelRegEx.allCheckedNodes.length > 0 && allCheckedNodesLabelRegEx.allCheckedNodes.forEach((check) => {
                if (Number(reg.id) === Number(check.node.key)) {
                    finishRegExUpdate.push(Number(reg.id))
                }
            })
        })
        allFinishRegEx = this.state.selectedRegExGlobalResponse.concat(finishRegExUpdate)
        if (e.target.checked) {
            if (excludedPiiCategoryResponse && excludedPiiCategoryResponse.length > 0) {
                excludedPiiCategoryResponse2 = excludedPiiCategoryResponse.filter((item) => !secondLayerFiltersUpdate.includes(item))
                allPiiFinish = allPiiFinish.filter((item) => !excludedPiiCategoryResponse2.includes(item))
            }

            if (excludedDictionaryResponse && excludedDictionaryResponse.length > 0) {
                finishDictionaryExcluded2 = excludedDictionaryResponse.filter((dict) => !allPiiFinishDictionaries.includes(dict))
            }
            if (excludedRegExResponse && excludedRegExResponse.length > 0) {
                finishRegExUpdate2 = excludedRegExResponse.filter((dict) => !allFinishRegEx.includes(dict))
            }
             if (checkedKeysPii.length === 0 && checkedKeysDictionary.length === 0 && checkedKeysRegEx.length === 0 && valueInputSearchRedact.length > 1) {
                 let inputSearchRedactArray = []
                 inputSearchRedactArray.push(`#--#${valueInputSearchRedact}#--#${valueOptionAll}`)
                 inputSearchRedactArray = this.state.valueInputSearchRedactArray.concat(inputSearchRedactArray)
                 this.setState({
                     valueInputSearchRedactArray: inputSearchRedactArray
                 })
             }

                this.setState({
                    excludedPiiCategoryResponse: excludedPiiCategoryResponse2,
                    selectedPiiGlobalResponse: allPiiFinish,
                    selectedDictionaryGlobalResponse: allPiiFinishDictionaries,
                    excludedDictionaryResponse: finishDictionaryExcluded2,
                    selectedRegExGlobalResponse: allFinishRegEx,
                    excludedRegExResponse: finishRegExUpdate2,
                })
        } else {
            let nonSelectedPii = []
            let checkAll = []
            treeAllPii && treeAllPii.length > 0 && treeAllPii.forEach((pii) => {

                allCheckedNodesDetail && allCheckedNodesDetail.length > 0 && allCheckedNodesDetail.forEach((check) => {
                    if (pii.key === check.node.key && check.children && check.children.length > 0) {
                        //checkAll.push(check.node.key[0].toUpperCase() + check.node.key.substring(1).toLowerCase())
                        checkAll.push(`${check.node.key[0].toUpperCase() + check.node.key.substring(1).toLowerCase()}#--#${valueInputSearchRedact}#--#${valueOptionAll}`)

                        //nonSelectedPii = selectedPiiGlobalResponse.filter((selec) => selec !== check.node.key[0].toUpperCase() + check.node.key.substring(1).toLowerCase())
                        nonSelectedPii = selectedPiiGlobalResponse.filter((selec) => !checkAll.includes(selec))
                    } else if (!check.children) {
                        nonSelectedPii = selectedPiiGlobalResponse.filter((selec) => selec !== check.node.props.value)
                    }
                })
            })
            if (allPiiFinish && allPiiFinish.length > 0) {
                allPiiFinishExcluded = allPiiFinish.filter((select) => !nonSelectedPii.includes(select))
                allPiiFinishExcluded = allPiiFinishExcluded.filter((a, i) => allPiiFinishExcluded.findIndex((s) => a === s) === i)
                allPiiFinishExcluded = this.state.excludedPiiCategoryResponse.concat(allPiiFinishExcluded)
            }
            if (nonSelectedPii && nonSelectedPii.length > 0) {
                allPiiFinish2 = allPiiFinish.filter((dict) => !allPiiFinishExcluded.includes(dict))
            }
            dictionariesData && dictionariesData.length > 0 && dictionariesData.forEach((dict) => {
                allCheckedNodesLabelDictionaries.allCheckedNodes && allCheckedNodesLabelDictionaries.allCheckedNodes.length > 0 && allCheckedNodesLabelDictionaries.allCheckedNodes.forEach((check) => {
                    if (dict.id === Number(check.node.key)) {
                        finishDictionaryExcluded.push(dict.id)
                    }
                })
            })

            if (finishDictionaryExcluded && finishDictionaryExcluded.length > 0) {
                finishDictionaryUpdate2 = allPiiFinishDictionaries.filter((dict) => !finishDictionaryExcluded.includes(dict))
                finishDictionaryExcluded = this.state.excludedDictionaryResponse.concat(finishDictionaryExcluded)
            }
            listNewRegEx && listNewRegEx.length > 0 && listNewRegEx.forEach((reg) => {
                allCheckedNodesLabelRegEx.allCheckedNodes && allCheckedNodesLabelRegEx.allCheckedNodes.length > 0 && allCheckedNodesLabelRegEx.allCheckedNodes.forEach((check) => {
                    if (Number(reg.id) === Number(check.node.key)) {
                        finishRegExExcluded.push(Number(reg.id))
                    }
                })
            })
            if (finishRegExExcluded && finishRegExExcluded.length > 0) {
                finishRegExExcluded2 = finishRegExUpdate2.filter((dict) => !finishRegExExcluded.includes(dict))
                finishRegExExcluded = this.state.excludedRegExResponse.concat(finishRegExExcluded)
            }
            const wordUpdate = []
            const wordUpdate2 = []
            if (!redactAllGlobal && allPiiFinishExcluded && allPiiFinishExcluded.length > 0) {
                selectedWordGlobalFull && selectedWordGlobalFull.length > 0 && selectedWordGlobalFull.forEach((item) => {
                    const elementExist = item.secondLayerCategories.some((item2) => allPiiFinishExcluded.includes(item2))
                    if (item.secondLayerCategories.some((item2) => allPiiFinishExcluded.includes(item2))) {

                    } else {
                        wordUpdate.push(item.word)
                    }
                })

                this.setState({
                    selectedWordGlobal: wordUpdate
                })
            } else if (!redactAllGlobal && finishDictionaryExcluded && finishDictionaryExcluded.length > 0) {
                selectedWordGlobalFull && selectedWordGlobalFull.length > 0 && selectedWordGlobalFull.forEach((item) => {
                    if (item.dictionaires.some((item2) => finishDictionaryExcluded.includes(item2))) {

                    } else {
                        wordUpdate.push(item.word)
                    }
                })
                this.setState({
                    selectedWordGlobal: wordUpdate
                })
            } else if (!redactAllGlobal && finishRegExExcluded && finishRegExExcluded.length > 0) {
                selectedWordGlobalFull && selectedWordGlobalFull.length > 0 && selectedWordGlobalFull.forEach((item) => {
                    if (item.regex.some((item2) => finishRegExExcluded.includes(item2))) {

                    } else {
                        wordUpdate.push(item.word)
                    }
                })
                this.setState({
                    selectedWordGlobal: wordUpdate
                })
            }
                this.setState({
                    excludedPiiCategoryResponse: allPiiFinishExcluded,
                    selectedPiiGlobalResponse: allPiiFinish2,
                    selectedDictionaryGlobalResponse: finishDictionaryUpdate2,
                    excludedDictionaryResponse: finishDictionaryExcluded,
                    selectedRegExGlobalResponse: finishRegExExcluded2,
                    excludedRegExResponse: finishRegExExcluded,
                })
        }
    }
    selectAllBulkRedaction = (e) => {
        //e.stopPropagation()
        //e.preventDefault()
        const { newBulkRedaction, checkedKeysPii, checkApplyPiiFilter,
            checkedKeysDictionary, valueInputSearchRedact, regexList, checkedKeysRegEx, treeAllPii, allCheckedNodesDetail, selectedPiiGlobal,
            dictionariesData, selectedDictionaryGlobal, redactAllGlobal, selectedWordGlobal, regexSelectedId, selectedRegExGlobal,
            allCheckedNodesLabel, excludedPiiCategoryResponse, selectedWordGlobalFull, valueOptionAll } = this.state
        let allKeysSelected = []
        let selectAllWords = []
        let extractSearchSelectAll = []
        let secondLayerFilters = []
        let thirdLayerFilters = []
        let finishPii = []
        let finishDictionary = []
        let finishRegEx = []
        let finishRegExIds = []
        let finishDictionaryName = []
        let finishPiiExcluded = []
        if (e.target.checked) {
           this.setState({
               checkSelectAllUpdate: true,
               selectedWordNew: '',
               excludedWordNew: '',
           })
        } else {
            this.setState({
                checkSelectAllUpdate: false,
                selectedWordNew: '',
                excludedWordNew: '',
            })
        }

        if (e.target.checked) {
            this.setState({
                redactAllNew: true
            })
        } else {
            this.setState({
                redactAllNew: false
            })
        }

         this.updateSelectAllBulkRedaction(e)

        if (e.target.checked && valueOptionAll === 'all' && checkedKeysPii.length === 0 && checkedKeysDictionary.length === 0 && checkedKeysRegEx.length === 0 && valueInputSearchRedact.length === 0) {
            const uniqueIdFolderRedaction = Math.floor(Math.random() * 1000000000)
            this.setState({
                uniqueIdFolderRedaction: uniqueIdFolderRedaction,
                checkSelectAll: true,
                redactAllGlobal: true,
                checkLoadFirstPage: false,
                statusSelectAll: true,
                excludedPiiGlobal: [],
                selectedWordGlobal: [],
                excludedPiiCategoryResponse: [],
                selectedPiiGlobalResponse: [],
                excludedDictionaryGlobal: [],
                excludedDictionaryGlobalNames: [],
                excludedDictionaryResponse: [],
                excludedRegExResponse: [],
                excludedRegExGlobal: [],
                selectedDictionaryGlobalResponse: [],
                selectedRegExGlobalResponse: [],
                excludedWordGlobal: [],
                excludedWords: [],
                valueInputSearchRedactArray: [],
                valueOptionAllArray: [],
                filterRedactionTypeNew: 'all',
                filterSearchTermNew: '',
                selectedPiiCategoryNew: [],
                selectedRegexNew: [],
                selectedDictionaryNew: [],
                selectedWordNew: '',
                excludedWordNew: '',
                redactAllNew: true,
            })
            this.props.setRedactionNonSelectedWords([])
        } else if (!e.target.checked && valueOptionAll === 'all' && checkedKeysPii.length === 0 && checkedKeysDictionary.length === 0 && checkedKeysRegEx.length === 0 && valueInputSearchRedact.length === 0) {
            const uniqueIdFolderRedaction = Math.floor(Math.random() * 1000000000)
            this.setState({
                uniqueIdFolderRedaction: uniqueIdFolderRedaction,
                checkSelectAll: false,
                redactAllGlobal: false,
                checkLoadFirstPage: true,
                selectedWordGlobal: [],
                selectedRowWordAll: [],
                statusSelectAll: false,
                selectedPiiGlobal: [],
                selectedPiiGlobalResponse: [],
                excludedPiiCategoryResponse: [],
                excludedDictionaryGlobal: [],
                excludedDictionaryGlobalNames: [],
                excludedDictionaryResponse: [],
                excludedRegExResponse: [],
                excludedRegExGlobal: [],
                selectedDictionaryGlobalResponse: [],
                selectedRegExGlobalResponse: [],
                excludedWordGlobal: [],
                excludedWords: [],
                valueInputSearchRedactArray: [],
                valueOptionAllArray: [],
                filterRedactionTypeNew: 'all',
                filterSearchTermNew: '',
                selectedPiiCategoryNew: [],
                selectedRegexNew: [],
                selectedDictionaryNew: [],
                selectedWordNew: '',
                excludedWordNew: '',
                redactAllNew: false,
            })
            this.props.setRedactionNonSelectedWords([])
        } else if (!redactAllGlobal && e.target.checked && (checkedKeysPii.length > 0 || checkedKeysDictionary.length > 0 || checkedKeysRegEx.length > 0)) {
            treeAllPii && treeAllPii.length > 0 && treeAllPii.forEach((tree) => {
                allCheckedNodesDetail && allCheckedNodesDetail.length > 0 && allCheckedNodesDetail.forEach((check) => {
                    if (tree.key === check.node.key && check.children && check.children.length > 0) {
                        secondLayerFilters.push(check.node.key[0].toUpperCase() + check.node.key.substring(1).toLowerCase())
                    } else if (!check.children) {
                        thirdLayerFilters.push(check.node.props.value)
                    }
                })
            })
            dictionariesData && dictionariesData.length > 0 && dictionariesData.forEach((dict) => {
                checkedKeysDictionary && checkedKeysDictionary.length > 0 && checkedKeysDictionary.forEach((check) => {
                    if (dict.name === check) {
                        finishDictionary.push(dict.id)
                        ///finishDictionaryName.push(dict.name)
                    }
                })
            })
            regexList && regexList.length > 0 && regexList.forEach((regEx) => {
                checkedKeysRegEx && checkedKeysRegEx.length > 0 && checkedKeysRegEx.forEach((check) => {
                    if (regEx.regex === check) {
                        finishRegEx.push(regEx.regex)
                        finishRegExIds.push(regEx.id)
                        ///finishDictionaryName.push(dict.name)
                    }
                })
            })
            const dictF = this.state.selectedDictionaryGlobal.concat(finishDictionary)
            const regExGlobal = this.state.selectedWordGlobal.concat(finishRegEx)
            const regExGlobalIds = this.state.regexSelectedId.concat(finishRegExIds)
            //const dictF2 = this.state.selectedDictNamesGlobal.concat(finishDictionaryName)
            finishPii = this.state.selectedPiiGlobal.concat(secondLayerFilters, thirdLayerFilters)
            this.setState({
                selectedPiiGlobal: finishPii,
                selectedDictionaryGlobal: dictF,
                selectedWordGlobal: regExGlobal,
                selectedRegExGlobal: regExGlobalIds,
                statusSelectAll: null
                //selectedDictNamesGlobal: dictF2
            })
        } else if (!redactAllGlobal && !e.target.checked && (checkedKeysPii.length > 0 || checkedKeysDictionary.length > 0 || checkedKeysRegEx.length > 0)) {
            let nonSelected = []
            dictionariesData && dictionariesData.length > 0 && dictionariesData.forEach((dict) => {

                checkedKeysDictionary && checkedKeysDictionary.length > 0 && checkedKeysDictionary.forEach((check) => {
                    if (dict.name === check) {
                        nonSelected = selectedDictionaryGlobal.filter((selec) => selec !== dict.id)
                    }
                })
            })
            let nonSelectedPii2 = []
            let selectPiiCheckNode = []
            let selectPiiNonCheckNode = []
            treeAllPii && treeAllPii.length > 0 && treeAllPii.forEach((pii) => {
                allCheckedNodesDetail && allCheckedNodesDetail.length > 0 && allCheckedNodesDetail.forEach((check) => {
                    if (pii.key === check.node.key && check.children && check.children.length > 0) {
                        selectPiiCheckNode.push(check.node.key[0].toUpperCase() + check.node.key.substring(1).toLowerCase())
                    } else if (!check.children) {
                        selectPiiNonCheckNode.push(check.node.props.value)
                    }
                })
            })

            if (selectPiiCheckNode && selectPiiCheckNode.length > 0) {
                nonSelectedPii2 = selectedPiiGlobal.filter((select) => !selectPiiCheckNode.includes(select))
            } else if (selectPiiNonCheckNode && selectPiiNonCheckNode.length > 0) {
                nonSelectedPii2 = selectedPiiGlobal.filter((select) => !selectPiiNonCheckNode.includes(select))
            }

            // let nonSelectedPii = []
            // treeAllPii && treeAllPii.length > 0 && treeAllPii.forEach((pii) => {
            //     allCheckedNodesDetail && allCheckedNodesDetail.length > 0 && allCheckedNodesDetail.forEach((check) => {
            //         if (pii.key === check.node.key && check.children && check.children.length > 0) {
            //             nonSelectedPii = selectedPiiGlobal.filter((selec) => selec !== check.node.key[0].toUpperCase() + check.node.key.substring(1).toLowerCase())
            //         } else if (!check.children) {
            //             nonSelectedPii = selectedPiiGlobal.filter((selec) => selec !== check.node.props.value)
            //         }
            //     })
            // })

            if (valueInputSearchRedact.length > 1) {
                //nonSelectedPii = nonSelectedPii.concat(selectedPiiGlobal)
                nonSelectedPii2 = nonSelectedPii2.concat(selectedPiiGlobal)
            }
            //change 8 4 2024
            if (checkedKeysRegEx && checkedKeysRegEx.length > 0) {
                let nonSelectedRegEx = []
                let nonSelectedRegExId = []
                regexList && regexList.length > 0 && regexList.forEach((regEx) => {
                    checkedKeysRegEx && checkedKeysRegEx.length > 0 && checkedKeysRegEx.forEach((check) => {
                        if (regEx.regex === check) {
                            nonSelectedRegEx = selectedWordGlobal.filter((regex) => regex !== check)
                            nonSelectedRegExId = selectedRegExGlobal.filter((regex) => regex.id !== check)
                        }
                    })
                })
                // this.setState({
                //     selectedWordGlobal: nonSelectedRegEx,
                //     selectedRegExGlobal: nonSelectedRegExId,
                // })
            }
            this.setState({
                selectedDictionaryGlobal: nonSelected,
                //selectedPiiGlobal: nonSelectedPii,
                selectedPiiGlobal: nonSelectedPii2,
                statusSelectAll: null
            })
        } else if (redactAllGlobal && !e.target.checked && !valueInputSearchRedact && (checkedKeysPii.length > 0 || checkedKeysDictionary.length > 0 || checkedKeysRegEx.length > 0)) {
            treeAllPii && treeAllPii.length > 0 && treeAllPii.forEach((tree) => {
                allCheckedNodesDetail && allCheckedNodesDetail.length > 0 && allCheckedNodesDetail.forEach((check) => {
                    if (tree.key === check.node.key && check.children && check.children.length > 0) {
                        secondLayerFilters.push(check.node.key[0].toUpperCase() + check.node.key.substring(1).toLowerCase())
                    } else if (!check.children) {
                        thirdLayerFilters.push(check.node.props.value)
                    }
                })
            })

            if (checkedKeysDictionary && checkedKeysDictionary.length > 0) {
                newBulkRedaction && newBulkRedaction.length > 0 && newBulkRedaction.forEach((dict) => {
                    checkedKeysDictionary.forEach((check) => {
                        if (dict.thirdLayerCategories.includes(check)) {
                            finishRegEx.push(dict.word)
                        }
                    })
                })
                const nonSelectDict = this.props.nonSelectedWords.concat(finishRegEx)
                //change 9 aprilie
                //this.props.setRedactionNonSelectedWords(nonSelectDict)
                // this.setState({
                //     nonSelectedWords: nonSelectDict,
                // })
            }
            dictionariesData && dictionariesData.length > 0 && dictionariesData.forEach((dict) => {
                checkedKeysDictionary && checkedKeysDictionary.length > 0 && checkedKeysDictionary.forEach((check) => {
                    if (dict.name === check) {
                        finishDictionary.push(dict.id)
                        finishDictionaryName.push(dict.name)
                    }
                })
            })

            if (checkedKeysRegEx && checkedKeysRegEx.length > 0) {
                regexList && regexList.length > 0 && regexList.forEach((regEx) => {
                    regexSelectedId && regexSelectedId.length > 0 && regexSelectedId.forEach((check) => {
                        if (regEx.id === check) {
                            finishRegExIds.push(regEx.id)
                        }
                    })
                })
                newBulkRedaction && newBulkRedaction.length > 0 && newBulkRedaction.forEach((regEx) => {
                    // checkedKeysRegEx.forEach((check) => {
                    //     if (regEx.thirdLayerCategories.includes(check)) {
                            finishRegEx.push(regEx.word)
                    //     }
                    // })
                })
                const nonSelectRegEx = this.props.nonSelectedWords.concat(finishRegEx)
                //change 9 aprilie
                //this.props.setRedactionNonSelectedWords(nonSelectRegEx)
                // this.setState({
                //     nonSelectedWords: nonSelectRegEx,
                // })
            } else regexList && regexList.length > 0 && regexList.forEach((regEx) => {
                checkedKeysRegEx && checkedKeysRegEx.length > 0 && checkedKeysRegEx.forEach((check) => {
                    if (regEx.regex === check) {
                        finishRegEx.push(regEx.regex)
                    }
                })
            })
            const regExGlobal = this.state.selectedWordGlobal.concat(finishRegEx)
            const dictF = this.state.selectedDictionaryGlobal.concat(finishDictionary)
            const dictF2 = this.state.excludedDictionaryGlobalNames.concat(finishDictionaryName)
            const finishRegExIds2 = this.state.excludedRegExGlobal.concat(finishRegExIds)
            //const dictF2 = this.state.selectedDictNamesGlobal.concat(finishDictionaryName)
            finishPii = this.state.selectedPiiGlobal.concat(secondLayerFilters, thirdLayerFilters)
            let nonSelectedPii = []
            treeAllPii && treeAllPii.length > 0 && treeAllPii.forEach((pii) => {

                allCheckedNodesDetail && allCheckedNodesDetail.length > 0 && allCheckedNodesDetail.forEach((check) => {
                    if (pii.key === check.node.key && check.children && check.children.length > 0) {
                        nonSelectedPii = selectedPiiGlobal.filter((selec) => selec !== check.node.key[0].toUpperCase() + check.node.key.substring(1).toLowerCase())
                    } else if (!check.children) {
                        nonSelectedPii = selectedPiiGlobal.filter((selec) => selec !== check.node.props.value)
                    }
                })
            })
            if (finishPii && finishPii.length > 0) {
                finishPiiExcluded = finishPii.filter((select) => !nonSelectedPii.includes(select))
                finishPiiExcluded = finishPiiExcluded.filter((a, i) => finishPiiExcluded.findIndex((s) => a === s) === i)
            }

            this.setState({
                //excludedPiiGlobal: finishPii,
                excludedPiiGlobal: finishPiiExcluded,
                excludedDictionaryGlobal: dictF,
                excludedDictionaryGlobalNames: dictF2,
                excludedWordGlobal: regExGlobal,
                excludedRegExGlobal: finishRegExIds2,
                statusSelectAll: null,
                selectedPiiGlobal: nonSelectedPii,
            })
        } else if (redactAllGlobal && e.target.checked && (checkedKeysPii.length > 0 || checkedKeysDictionary.length > 0 || checkedKeysRegEx.length > 0)) {
            let nonSelected = []
            dictionariesData && dictionariesData.length > 0 && dictionariesData.forEach((dict) => {

                checkedKeysDictionary && checkedKeysDictionary.length > 0 && checkedKeysDictionary.forEach((check) => {
                    if (dict.name === check) {
                        nonSelected = selectedDictionaryGlobal.filter((selec) => selec !== dict.id)
                    }
                })
            })

            let nonSelectedPii = []
            treeAllPii && treeAllPii.length > 0 && treeAllPii.forEach((pii) => {

                allCheckedNodesDetail && allCheckedNodesDetail.length > 0 && allCheckedNodesDetail.forEach((check) => {
                    if (pii.key === check.node.key && check.children && check.children.length > 0) {
                        nonSelectedPii = selectedPiiGlobal.filter((selec) => selec !== check.node.key[0].toUpperCase() + check.node.key.substring(1).toLowerCase())
                    } else if (!check.children) {
                        nonSelectedPii = selectedPiiGlobal.filter((selec) => selec !== check.node.props.value)
                    }
                })
            })
            let nonSelectedRegEx = []
            regexList && regexList.length > 0 && regexList.forEach((regEx) => {
                checkedKeysRegEx && checkedKeysRegEx.length > 0 && checkedKeysRegEx.forEach((check) => {
                    if (regEx.regex === check) {
                        nonSelectedRegEx = selectedWordGlobal.filter((regex) => regex !== check)
                    }
                })
            })
            this.setState({
                excludedDictionaryGlobal: nonSelected,
                excludedPiiGlobal: nonSelectedPii,
                excludedWordGlobal: nonSelectedRegEx,
                statusSelectAll: null
            })
        }

        ///change 8 4 2024
        if (e.target.checked && checkedKeysPii.length === 0 && checkedKeysDictionary.length === 0 && checkedKeysRegEx.length === 0 && valueInputSearchRedact.length === 0 && valueOptionAll === 'all') {
            newBulkRedaction && newBulkRedaction.length > 0 && newBulkRedaction.forEach((red) => {
                allKeysSelected.push(red.key)
                selectAllWords.push(red.word)
            })
            //change 4
            this.setState({
                selectedRowKeys: allKeysSelected,
                selectedRowWord: selectAllWords,
                //selectedWordGlobal: this.state.selectedWordGlobal.concat(selectAllWords),
                checkBulkRedaction: true
            })
        } else if (e.target.checked && (checkedKeysPii.length > 0 || checkedKeysDictionary.length > 0 || checkedKeysRegEx.length > 0 || valueInputSearchRedact.length > 0 || valueOptionAll !== 'all')) {
            newBulkRedaction && newBulkRedaction.length > 0 && newBulkRedaction.forEach((red) => {
                allKeysSelected.push(red.key)
                selectAllWords.push(red.word)
            })

            if (valueInputSearchRedact.length > 0 || valueOptionAll !== 'all') {
               this.setState({
                   selectedWordGlobal: this.state.selectedWordGlobal.concat(selectAllWords),
               })
            }
            //change 4
            this.setState({
                selectedRowKeys: allKeysSelected,
                selectedRowWord: selectAllWords,
                //selectedWordGlobal: this.state.selectedWordGlobal.concat(selectAllWords),
                checkBulkRedaction: true
            })
        } else if (!e.target.checked && (valueInputSearchRedact.length > 0 || valueOptionAll !== 'all')) {
            let selectedWord2 = []
            const wordPage = []
            newBulkRedaction && newBulkRedaction.length > 0 && newBulkRedaction.forEach((red) => {
                wordPage.push(red.word)
            })
            selectedWord2 = this.state.selectedWordGlobal.filter((item) => !wordPage.includes(item))
            this.setState({
                selectedRowKeys: [],
                selectedRowWord: [],
                checkBulkRedaction: false,
                selectedWordGlobal: selectedWord2,
            })

        } else {
            this.setState({
                selectedRowKeys: [],
                selectedRowWord: [],
                checkBulkRedaction: false,
            })
        }
        if (!e.target.checked && checkApplyPiiFilter) {
           this.setState({
               extractKeysPiiChecked: checkedKeysPii,
               checkBulkRedaction: true,
               checkSelectAll: true
           })
        }
        if (!e.target.checked && checkedKeysDictionary.length > 0) {
            this.setState({
                extractDictionaryChecked: checkedKeysDictionary,
                checkBulkRedaction: true,
                checkSelectAll: true
            })
        }
        if (redactAllGlobal && !e.target.checked && (valueInputSearchRedact.length > 1 || valueOptionAll !== 'all')) {
            newBulkRedaction && newBulkRedaction.length > 0 && newBulkRedaction.forEach((red) => {
                extractSearchSelectAll.push(red.word)
            })
            this.props.setRedactionNonSelectedWords(this.props.nonSelectedWords.concat(extractSearchSelectAll))
            this.setState({
                //nonSelectedWords: this.state.nonSelectedWords.concat(extractSearchSelectAll),
                excludedWords: extractSearchSelectAll,
                checkBulkRedaction: true,
                checkSelectAll: true,
                redactAllGlobal: true,
            })
        } else if (!redactAllGlobal && !e.target.checked && (valueInputSearchRedact.length > 1 || valueOptionAll !== 'all')) {
            newBulkRedaction && newBulkRedaction.length > 0 && newBulkRedaction.forEach((red) => {
                extractSearchSelectAll.push(red.word)
            })
            this.props.setRedactionNonSelectedWords(extractSearchSelectAll)
            this.setState({
                //nonSelectedWords: extractSearchSelectAll,
                excludedWords: extractSearchSelectAll,
            })
        } else if (!e.target.checked && valueInputSearchRedact.length === 0) {
            // this.setState({
            //     nonSelectedWords: [],
            // })
        } else if (redactAllGlobal && e.target.checked && (valueInputSearchRedact.length > 1 || valueOptionAll !== 'all')) {
            this.props.setRedactionNonSelectedWords([])
            this.setState({
                //nonSelectedWords: [],
                excludedWords: [],
            })
        } else if (!redactAllGlobal && e.target.checked && (valueInputSearchRedact.length > 1 || valueOptionAll !== 'all')) {
            this.props.setRedactionNonSelectedWords([])
            this.setState({
                //nonSelectedWords: [],
                excludedWords: [],
            })
        }
      this.setState({
          selectAllChecked: e.target.checked
      }, () => {
          this.setState({

          }, () => {
              this.onGetSaveRedactionFilterSelections()
          })
      })
    }
    onChangePageCheckSelectKeys = () => {
        const { newBulkRedaction, selectAllChecked, piiSelectedOption, redactAllGlobal, allCheckedNodesDetail, treeAllPii,
            checkedKeysDictionary, checkedKeysRegEx, selectedPiiGlobal, excludedWordGlobal, excludedDictionaryGlobalNames, excludedPiiGlobal, selectedDictionaryGlobal,
            excludedRegExGlobal, regexSelectedId, regexListIds, selectedRegExGlobal, selectedWordGlobal, checkResetFiltre, valueInputSearchRedact, statusSelectAll } = this.state
        const { nonSelectedWords } = this.props
        let allKeysSelected = []
        let selectAllWords = []
        let newSelectAllWords = []
        // let extractKeys = []
        // let extractKeysPii = []
        // let extractKeysDictionary = []
        const secondLayerFilters = []
        const thirdLayerFilters = []
        let temporarLayer = true
        const redactedKeysWord = []
        treeAllPii && treeAllPii.length > 0 && treeAllPii.forEach((tree) => {
            allCheckedNodesDetail && allCheckedNodesDetail.length > 0 && allCheckedNodesDetail.forEach((check) => {
                if (tree.key === check.node.key && check.children && check.children.length > 0) {
                    secondLayerFilters.push(check.node.key[0].toUpperCase() + check.node.key.substring(1).toLowerCase())
                } else if (!check.children) {
                    thirdLayerFilters.push(check.node.props.value)
                }
            })
        })
        newBulkRedaction && newBulkRedaction.length > 0 && newBulkRedaction.forEach((file) => {
            // if (file.redacted) {
            //     redactedKeysWord.push(file.word)
            // }
        })

        if (redactAllGlobal) {
            newBulkRedaction && newBulkRedaction.length > 0 && newBulkRedaction.forEach((red) => {
                red.thirdLayerCategories && red.thirdLayerCategories.length > 0 && red.thirdLayerCategories.forEach((third) => {
                    // if (red.redacted === true) {
                    //     if (excludedPiiGlobal && excludedPiiGlobal.length > 0) {
                    //         red.secondLayerCategories.forEach((exclude1) => {
                    //             if (!excludedPiiGlobal.includes(exclude1)) {
                    //                 allKeysSelected.push(red.key)
                    //                 selectAllWords.push(red.word)
                    //             }
                    //         })
                    //         excludedPiiGlobal.forEach((exclude) => {
                    //             // if (!red.secondLayerCategories.includes(exclude)) {
                    //             //     allKeysSelected.push(red.key)
                    //             //     selectAllWords.push(red.word)
                    //             // }
                    //         })
                    //     }
                    //  }
                    piiSelectedOption.forEach((select) => {
                        if (third === select) {
                            if (nonSelectedWords && nonSelectedWords.length > 0) {
                                if (!nonSelectedWords.includes(red.word)) {
                                    newSelectAllWords.push(red.word)
                                    allKeysSelected.push(red.key)
                                    selectAllWords.push(red.word)
                                }

                            } else
                            {
                                if (excludedPiiGlobal && excludedPiiGlobal.length > 0) {
                                    red.secondLayerCategories && red.secondLayerCategories.length > 0 && red.secondLayerCategories.forEach((exclude) => {
                                        if (!excludedPiiGlobal.includes(exclude) && !nonSelectedWords.includes(red.word)) {
                                            allKeysSelected.push(red.key)
                                            selectAllWords.push(red.word)
                                        }
                                    })
                                    // excludedPiiGlobal.forEach((exclude) => {
                                    //     if (!red.secondLayerCategories.includes(exclude)) {
                                    //         allKeysSelected.push(red.key)
                                    //         selectAllWords.push(red.word)
                                    //     }
                                    // })
                                } else
                                {
                                    allKeysSelected.push(red.key)
                                   selectAllWords.push(red.word)
                                }
                            }
                        }
                    })
                })
                const uniqueExcludedDictionaryGlobalNames2 = [...new Set(excludedDictionaryGlobalNames)]

                red.thirdLayerCategories && red.thirdLayerCategories.length > 0 && red.thirdLayerCategories.forEach((dict) => {
                    if (excludedDictionaryGlobalNames && excludedDictionaryGlobalNames.length > 0 && uniqueExcludedDictionaryGlobalNames2.length === checkedKeysDictionary.length) {
                        if (selectedWordGlobal.includes(red.word)) {
                            allKeysSelected.push(red.key)
                            selectAllWords.push(red.word)
                        } else if (!selectedWordGlobal.includes(red.word)) {
                            // selectAllWords = []
                            // allKeysSelected = []
                        }
                    } else
                    if (checkedKeysDictionary && checkedKeysDictionary.length > 0) {
                        //'INTRA 2222222')
                        checkedKeysDictionary.forEach((check) => {
                            if (dict === check && !nonSelectedWords.includes(red.word)) {
                                allKeysSelected.push(red.key)
                                selectAllWords.push(red.word)
                            }
                        })
                    } else if (checkedKeysDictionary.includes(dict) && !excludedDictionaryGlobalNames.includes(dict)) {
                        allKeysSelected.push(red.key)
                        selectAllWords.push(red.word)
                    }
                })
                if (checkedKeysRegEx.includes(red.word) && !excludedWordGlobal.includes(red.word)) {
                    allKeysSelected.push(red.key)
                    selectAllWords.push(red.word)
                } else if (excludedPiiGlobal && excludedPiiGlobal.length > 0) {
                    red.secondLayerCategories && red.secondLayerCategories.length > 0 && red.secondLayerCategories.forEach((exclude) => {
                        if (!excludedPiiGlobal.includes(exclude) && !nonSelectedWords.includes(red.word)) {
                            allKeysSelected.push(red.key)
                            selectAllWords.push(red.word)
                        }
                    })
                }  else if (excludedRegExGlobal && excludedRegExGlobal.length > 0 && regexSelectedId && regexSelectedId.length > 0) {
                    red.regex && red.regex.length > 0 && red.regex.forEach((exclude) => {
                        if (!excludedRegExGlobal.includes(exclude.toString()) && !nonSelectedWords.includes(red.word)) {
                            allKeysSelected.push(red.key)
                            selectAllWords.push(red.word)
                        }
                    })
                }
                else  if (nonSelectedWords && nonSelectedWords.length > 0) {
                    if ( uniqueExcludedDictionaryGlobalNames2.length === checkedKeysDictionary.length && !nonSelectedWords.includes(red.word) && checkResetFiltre) {
                        if (selectedWordGlobal.includes(red.word)) {
                            allKeysSelected.push(red.key)
                            selectAllWords.push(red.word)
                        } if (!nonSelectedWords.includes(red.word)) {
                            allKeysSelected.push(red.key)
                            selectAllWords.push(red.word)
                        } else {
                            selectAllWords = []
                            allKeysSelected = []
                        }
                    } else
                    if (!nonSelectedWords.includes(red.word)) {
                        newSelectAllWords.push(red.word)
                        allKeysSelected.push(red.key)
                        selectAllWords.push(red.word)
                    } else {

                    }

                }
                else {
                    allKeysSelected.push(red.key)
                    selectAllWords.push(red.word)
                }
            })
            //change 5
            this.setState({
                //selectedRowKeys: allKeysSelected,
                selectedRowWord: selectAllWords,
            })
        } else if ((secondLayerFilters && secondLayerFilters.length > 0) || (thirdLayerFilters && thirdLayerFilters.length > 0) ||
            (checkedKeysDictionary && checkedKeysDictionary.length > 0) || (regexSelectedId && regexSelectedId.length > 0) || (checkedKeysRegEx && checkedKeysRegEx.length > 0)) {
                newBulkRedaction && newBulkRedaction.length > 0 && newBulkRedaction.forEach((red) => {
                    red.thirdLayerCategories && red.thirdLayerCategories.length > 0 && red.thirdLayerCategories.forEach((second) => {
                        if (!secondLayerFilters.includes(second)) {
                            temporarLayer = false
                        }
                        if (selectedPiiGlobal.includes(second)) {
                            if (nonSelectedWords && nonSelectedWords.length > 0) {
                                if (!nonSelectedWords.includes(red.word)) {
                                    allKeysSelected.push(red.key)
                                    selectAllWords.push(red.word)
                                }
                            } else {
                                allKeysSelected.push(red.key)
                                selectAllWords.push(red.word)
                            }
                            //change 6
                            this.setState({
                                //selectedRowKeys: allKeysSelected,
                                selectedRowWord: selectAllWords,
                            })
                        }
                    })
                    red.regex && red.regex.length > 0 && red.regex.forEach((second) => {
                        if (selectedRegExGlobal.includes(second.toString()) ) {
                            if (nonSelectedWords && nonSelectedWords.length > 0) {
                                if (!nonSelectedWords.includes(red.word)) {
                                    allKeysSelected.push(red.key)
                                    selectAllWords.push(red.word)
                                }
                            } else {
                                allKeysSelected.push(red.key)
                                selectAllWords.push(red.word)
                            }
                            //change 7
                            this.setState({
                               // selectedRowKeys: allKeysSelected,
                                selectedRowWord: selectAllWords,
                            })
                        }
                    })
                })
            if (newBulkRedaction.length === 0) {
                temporarLayer = false
            }
              this.setState({
                  selectAllChecked: temporarLayer
              })
            const secondPii = []
            newBulkRedaction && newBulkRedaction.length > 0 && newBulkRedaction.forEach((red) => {
                red.secondLayerCategories && red.secondLayerCategories.length > 0 && red.secondLayerCategories.forEach((second) => {
                    secondPii.push(second)
                    if (nonSelectedWords  && nonSelectedWords.length > 0 && selectAllChecked && secondPii.length === 1) {
                        const wordNon = []
                        if (nonSelectedWords.includes(red.word) && selectAllChecked ) {
                            wordNon.push(red.word)
                        } else
                        if (!nonSelectedWords.includes(red.word) && selectAllChecked) {
                            allKeysSelected.push(red.key)
                            selectAllWords.push(red.word)
                            //change 8
                            this.setState({
                                //selectedRowKeys: allKeysSelected,
                                selectedRowWord: selectAllWords,
                            }, () => {
                                 if (wordNon && wordNon.length > 0) {
                                     wordNon.forEach((nonItem) => {
                                         if (nonSelectedWords.includes(nonItem)) {
                                             allKeysSelected.push(red.key)
                                             selectAllWords.push(nonItem)
                                             //change 9
                                             this.setState({
                                                 //selectedRowKeys: allKeysSelected,
                                                 selectedRowWord: selectAllWords,
                                             })
                                         }
                                     })
                                 }
                            })
                        } else {
                            const redWord2 = []
                            redWord2.push(red.word)
                            if (redWord2.length === 1) {
                                //change 10
                                this.setState({
                                    //selectedRowKeys: [],
                                    selectedRowWord: [],
                                })
                            }
                            if (nonSelectedWords && nonSelectedWords.length > 0 && !nonSelectedWords.includes(red.word)) {
                                allKeysSelected.push(red.key)
                                selectAllWords.push(red.word)
                                //change 11
                                this.setState({
                                    //selectedRowKeys: allKeysSelected,
                                    selectedRowWord: selectAllWords,
                                })
                            }
                        }
                    } else
                    if (selectedPiiGlobal.includes(second) || selectedWordGlobal.includes(red.word)) {
                        if (nonSelectedWords && nonSelectedWords.length > 0) {
                            if (!nonSelectedWords.includes(red.word)) {
                                allKeysSelected.push(red.key)
                                selectAllWords.push(red.word)
                            }
                        }
                            // else if (selectedWordGlobal && selectedWordGlobal.length > 0) {

                            //         if (selectedWordGlobal.includes(red.word)) {

                            //             allKeysSelected.push(red.key)
                            //             selectAllWords.push(red.word)
                            //         }
                            //     }
                        else if (statusSelectAll !== false) {
                            allKeysSelected.push(red.key)
                            selectAllWords.push(red.word)
                        }
                        //change 13
                        this.setState({
                            //selectedRowKeys: allKeysSelected,
                            selectedRowWord: selectAllWords,
                        })
                    }
                })
            })
            if (selectedDictionaryGlobal && selectedDictionaryGlobal.length > 0) {
                newBulkRedaction && newBulkRedaction.length > 0 && newBulkRedaction.forEach((file) => {
                    file.dictionaires && file.dictionaires.length > 0 && file.dictionaires.forEach((second) => {
                        if (nonSelectedWords  && nonSelectedWords.length > 0) {
                            if (!nonSelectedWords.includes(file.word)) {
                                allKeysSelected.push(file.key)
                                selectAllWords.push(file.word)
                                //change 14
                                this.setState({
                                    //selectedRowKeys: allKeysSelected,
                                    selectedRowWord: selectAllWords,
                                })
                            }
                        } else
                        if (selectedDictionaryGlobal.includes(second)) {
                            allKeysSelected.push(file.key)
                            selectAllWords.push(file.word)
                            //change 15
                            this.setState({
                                //selectedRowKeys: allKeysSelected,
                                selectedRowWord: selectAllWords,
                            })
                        }
                    })
                })
            }
            if (excludedRegExGlobal && excludedRegExGlobal.length > 0 && regexSelectedId && regexSelectedId.length > 0) {
                newBulkRedaction && newBulkRedaction.length > 0 && newBulkRedaction.forEach((file) => {
                    file.regex && file.regex.length > 0 && file.regex.forEach((exclude) => {
                        if (!excludedRegExGlobal.includes(exclude.toString()) && !nonSelectedWords.includes(file.word)) {
                            allKeysSelected.push(file.key)
                            selectAllWords.push(file.word)
                        }
                    })
                })
                //change 16
                this.setState({
                    //selectedRowKeys: allKeysSelected,
                    selectedRowWord: selectAllWords,
                })
            }
        }
        // if (this.state.selectedRowWord.length === 0 && !checkSearchSelected && redactedKeysWord.length === 0) {
        //     this.setState({
        //         selectedRowKeys: []
        //     })
        // }
    }
    onCheckBoxRedact = (e, file) => {
        const selectedRowKeys = []
        selectedRowKeys.push(file.key)
        this.setState({
            selectedRowKeys: [...this.state.selectedRowKeys ,file.key]
        })
    }
    onChangeRedact = (pagination, filters, sorter) => {
        if (sorter && sorter.order) {
            this.setState({
                statusSorter: sorter.order
            }, () => {
                this.getNewBulkRedaction()
            })
        } else {
            this.setState({
                statusSorter: null
            }, () => {
                this.getNewBulkRedaction()
            })
        }
        //(a, b) => a.word && b.word ? a.word.localeCompare(b.word) : ''
    }
    sorterTable = (e, sorter) => {
       // this.getNewBulkRedaction()
    }
    rowClassSelected = (record) => {
        if (record.redacted) {
            return 'row-redacted'
        }
        return 'row-level-redaction'
    }
    changeOptionAllRedacted = (e, status) => {
        this.setState({
            valueOptionAll: e,
            filterRedactionTypeNew: e,
        }, () => {
            this.getNewBulkRedaction()
        })
    }
    generateTitleTreeParent = (tree) => {
        const { treeExpandedKeys } = this.state
        return (
            <div className="content-parent-tree">
                <span className="title-parent">
                    {
                        tree.title
                    }
                </span>
                { treeExpandedKeys.includes(tree.title.toLowerCase()) ?
                    <span onClick={(e) => this.expandCategoryPii(e, tree.title, 'remove') } className="icon-v19-arrow-medium open-arrow">
                </span> :
                    <span onClick={(e) => this.expandCategoryPii(e, tree.title, 'add') } className="icon-v19-arrow-medium">
                </span>
                }
            </div>
        )
    }
    generateTitleTreeDictionary = (tree) => {
        return (
            <div className="content-parent-tree">
                <span className="title-parent">
                    {
                        tree.listName
                    }
                </span>
            </div>
        )
    }
    generateTitleTreeRegEx = (tree) => {
        return (
            <div className="content-parent-tree">
                <span className="title-parent title-parent-regex">
                    {
                        tree.title
                    }
                </span>
            </div>
        )
    }
    generateTitleTreeChild = (tree) => {
        return (
            <div className="content-child-tree">
                <span className="title-child">
                    {
                        tree.title
                    }
                </span>
            </div>
        )
    }
    generateFooterTreePii = () => {
        const { checkedKeysPii } = this.state
        return (
            <div className="content-buttons-footer">
                <Button disabled={ checkedKeysPii && checkedKeysPii.length === 0 } onClick={ () => this.onClearTreePii() } className="button-blue-clear">
                    Clear
                </Button>
                <Button disabled={ checkedKeysPii && checkedKeysPii.length === 0 } onClick={() => this.onClickApplyTree()} className="button-blue-apply">
                   Apply
                </Button>
            </div>
        )
    }
    onClearTreePii = () => {
       this.setState({
           //allCheckedNodes: [],
           checkedKeysPii: [],
           checkApplyPiiFilter: false,
           checkClearPiiFilter: true,
           checkResetFiltre: false,
       }, () => {
           this.getNewBulkRedaction()
       })
    }
    onClickApplyTree = () => {
        const { piiSelectedOption, checkedKeysPii } = this.state
        const updateSelectPii = piiSelectedOption.concat(checkedKeysPii)
        const uniqueSelectPii = [...new Set(updateSelectPii)]
        this.setState({
            checkApplyPiiFilter: true,
            checkClearPiiFilter: false,
            piiSelectedOption: uniqueSelectPii
        })
        this.getNewBulkRedaction()
    }

    onClearKeysTree = () => {
        this.setState({
            checkedKeysPii: [],
            allCheckedNodes: []
        })
    }

    onChangeTree = (checkNode, node, label, extra) => {
        const { piiSelectedOption, checkedKeysPii, treeAllPii } = this.state
        const updateSelectPii = this.state.piiSelectedOption.concat(checkNode)
        const uniqueSelectPii = [...new Set(updateSelectPii)]
        const updateSelectPiiNode = this.state.allCheckedNodes.concat(label.allCheckedNodes)
        //const uniqueSelectPiiNode = [...new Set(updateSelectPiiNode)]

        const secondLayerFiltersUpdate = []
        const thirdLayerFiltersUpdate = []
        let selectedPiiCategoryNewList = []
        treeAllPii && treeAllPii.length > 0 && treeAllPii.forEach((tree) => {
            label.allCheckedNodes && label.allCheckedNodes.length > 0 && label.allCheckedNodes.forEach((check) => {
                if (tree.key === check.node.key && check.children && check.children.length > 0) {
                    secondLayerFiltersUpdate.push(check.node.key[0].toUpperCase() + check.node.key.substring(1).toLowerCase())
                } else if (!check.children) {
                    thirdLayerFiltersUpdate.push(check.node.props.value)
                }
            })
        })
        selectedPiiCategoryNewList = selectedPiiCategoryNewList.concat(secondLayerFiltersUpdate, thirdLayerFiltersUpdate)
        const uniquePiiCategoryNewList = [...new Set(selectedPiiCategoryNewList)]
        this.setState({
            selectedPiiCategoryNew: uniquePiiCategoryNewList,
            checkedKeysPii: checkNode,
            allCheckedNodes: updateSelectPiiNode,
            piiSelectedOption: uniqueSelectPii,
            allCheckedNodesDetail: label.allCheckedNodes,
            currentPage: 1,
            inputPageRedaction: 1,
            allCheckedNodesLabel: label
        } , () => {
            const updateSelectPii2 = piiSelectedOption.concat(checkedKeysPii)
            const uniqueSelectPii2 = [...new Set(updateSelectPii2)]
            this.setState({
                checkApplyPiiFilter: true,
                checkClearPiiFilter: false,
                piiSelectedOption: uniqueSelectPii2
            })
            this.getNewBulkRedaction()
        })
    }
    onTreeExpandPii = (expandedKeys) => {
        this.setState({
            treeExpandedKeys: expandedKeys
        })
    }
    expandCategoryPii = (e, key, action) => {
        e.stopPropagation()
        const { treeAllPii } = this.state
        let { treeExpandedKeys } = {...this.state}
        const treeExpandKeys = []
        treeExpandKeys.push(key.toLowerCase())
        if (action === 'add') {
            this.setState({
                treeExpandedKeys: treeExpandedKeys.concat(treeExpandKeys)
            })
            //treeExpandedKeys.push(key.toLowerCase())
        } else {
            this.setState({
                treeExpandedKeys: treeExpandedKeys.filter((eKey) => eKey !== key.toLowerCase())
            })
            //treeExpandedKeys = treeExpandedKeys.filter((eKey) => eKey !== key.toLowerCase())
        }
        // this.setState({
        //     treeExpandedKeys
        // }, () => {
        //     this.setState({
        //         treeExpandedKeys
        //     })
        // })
    }
    onChangeDictionaries(e, option, list) {
        const { dictionariesData } = this.state
        let idDictionaries = null
        dictionariesData && dictionariesData.length > 0 && dictionariesData.forEach((dict) => {
            if (dict.name === e) {
                idDictionaries = dict.id
            }
        })
        this.setState({
            selectFilterDictionaries: e,
            selectFilterDictionariesIds: idDictionaries,
        }, () => {
            this.getNewBulkRedaction()
        })
    }
    stopPropagationDictionary = (e) => {
        e.stopPropagation()
        e.preventDefault()
    }
    onChangeDictionaryTree = (checkNode, node, label, extra) => {
        const { dictionariesData } = this.state
        let idDictionaries = []
        dictionariesData && dictionariesData.length > 0 && dictionariesData.forEach((dict) => {
            if (checkNode.includes(dict.name)) {
                idDictionaries.push(dict.id)
            }
        })
        const selectedDictionaryNewList = []
        dictionariesData && dictionariesData.length > 0 && dictionariesData.forEach((dict) => {
            label.allCheckedNodes && label.allCheckedNodes.length > 0 && label.allCheckedNodes.forEach((check) => {
                if (dict.id === Number(check.node.key)) {
                    selectedDictionaryNewList.push(dict.id)
                }
            })
        })
        const uniqueDictionaryNewList = [...new Set(selectedDictionaryNewList)]
        this.setState({
            selectedDictionaryNew: uniqueDictionaryNewList,
            checkedKeysDictionary: checkNode,
            dictionariesListIds: idDictionaries,
            currentPage: 1,
            inputPageRedaction: 1,
            checkResetFiltre: true,
            allCheckedNodesLabelDictionaries: label
        }, () => {
            this.getNewBulkRedaction()
        })
    }
    stopPropagationRegEx = (e) => {
        e.stopPropagation()
        e.preventDefault()
    }
    onChangeRegExTree = (checkNode, node, label, extra) => {
        const { regexList, selectedRegEx, listNewRegEx } = this.state
        let idRegEx = []
        let regexgEx = []
        let regexIds = []
        regexList && regexList.length > 0 && regexList.forEach((reg) => {
            if (checkNode.includes(reg.title)) {
                idRegEx.push(reg.title)
                regexgEx.push(reg.regex)
                regexIds.push(reg.id)
            }
        })

        const selectedRegexNewList = []
        listNewRegEx && listNewRegEx.length > 0 && listNewRegEx.forEach((reg) => {
            label.allCheckedNodes && label.allCheckedNodes.length > 0 && label.allCheckedNodes.forEach((check) => {
                if (Number(reg.id) === Number(check.node.key)) {
                    selectedRegexNewList.push(Number(reg.id))
                }
            })
        })
        const uniqueRegexNewList = [...new Set(selectedRegexNewList)]
        this.setState({
            selectedRegexNew: uniqueRegexNewList,
            checkedKeysRegEx: checkNode,
            regexListIds: idRegEx,
            regexListSelected: regexgEx,
            regexSelectedId: regexIds,
            currentPage: 1,
            inputPageRedaction: 1,
            allCheckedNodesLabelRegEx: label
        }, () => this.getNewBulkRedaction())
    }
    onChangeRegularExpressions(e) {
        this.setState({
            selectFilterRegEx: e
        }, () => {
            this.getNewBulkRedaction()
        })
    }
    addDictionaryTerm = (e) => {
        const { tableBulkRedaction, disabledAddTerm, newBulkRedaction, selectedWordGlobal } = this.state
        if (!disabledAddTerm) {
            const rowNew = {
                firstLayerCategory: "",
                secondLayerCategories: [],
                thirdLayerCategories: [],
                key: '0',
                word: "add new dictionary",
                status: 'add',
                id: 0
            }
            const newTable = []
            newTable.push(rowNew)
            newBulkRedaction.push(rowNew)
            newBulkRedaction.sort((a, b) => a.id - b.id)
            this.setState({
                //tableBulkRedaction: [...this.state.tableBulkRedaction, rowNew]
                tableBulkRedaction,
                newBulkRedaction,
                disabledAddTerm: true
            })
            if (selectedWordGlobal && selectedWordGlobal.length === 0) {
              this.setState({
                  statusOpenAddTerm: true
              })
            }
        }
    }
    updateInputRow = (e, record) => {
        this.setState({
            valueInputRow: e.target.value
        })
    }
    onChangeDictionariesRow(e) {
        const { tableBulkRedaction, newBulkRedaction } = this.state
        if (e === 'new-dictionary') {
            this.setState({
                selectDictionariesRow: ''
            })
        } else {
            this.setState({
                selectDictionariesRow: e
            })
        }
        // newBulkRedaction && newBulkRedaction.length > 0 && newBulkRedaction.forEach((item, index) => {
        //     if (item.status === 'add' || item.status === 'push') {
        //         item.status = ''
        //     }
        //     item.key = index + 2
        // })
        newBulkRedaction && newBulkRedaction.length > 0 && newBulkRedaction.forEach((item, index) => {
            if (item.status === 'add' || item.status === 'push') {
                //item.status = 'start'
            }
            item.id = index + 2
        })
        this.setState({
            tableBulkRedaction,
            disabledAddTerm: false,
            valueInputRow: '',
            newBulkRedaction
        })
    }

    onSelectDictionariesRow = (e) => {
        const { tableBulkRedaction, newBulkRedaction, dictionariesData } = this.state
        //e.preventDefault()
        let idDictionaries = null
        dictionariesData && dictionariesData.length > 0 && dictionariesData.forEach((dict) => {
            if (dict.name === e) {
                idDictionaries = dict.id
            }
        })
        this.setState({
            dictionaryIdSelect: idDictionaries,
            createDictionary: false
        })
        if (e === 'new-dictionary') {
            this.setState({
                selectDictionariesRow: ''
            })
        } else {
            this.setState({
                selectDictionariesRow: e
            })
        }
        const newList = []
        if (e !== 'new-dictionary') {
            newBulkRedaction && newBulkRedaction.length > 0 && newBulkRedaction.forEach((item, index) => {
                if ((item.status !== 'add' || item.status !== 'push') && item.id !== 0) {
                    //item.status = 'start'
                    newList.push(item)
                }
                //item.id = index + 2
            })
            this.setState({
                newBulkRedaction: newList,
                visibleAddRow: true
            })
        }
        this.setState({
            tableBulkRedaction,
            disabledAddTerm: false,
            valueInputRow: '',
        }, () => {
            if (e !== 'new-dictionary') {
                this.getEditDetailedTreeSettingsRedaction()
            }
        })
    }
    editNewTerm = (e, record) => {
        const { tableBulkRedaction, newBulkRedaction } = this.state
        newBulkRedaction && newBulkRedaction.length > 0 && newBulkRedaction.forEach((item) => {
            if (item.status === 'push') {
                item.word = e.target.value
                item.status = 'add'
            }
        })
        this.setState({
            tableBulkRedaction,
            newBulkRedaction
        })
    }
    onPressEnterInput = (e, record) => {
        const { tableBulkRedaction, newBulkRedaction } = this.state
        newBulkRedaction && newBulkRedaction.length > 0 && newBulkRedaction.forEach((item) => {
            if (item.status === 'add') {
                item.word = e.target.value
                item.status = 'push'
            }
        })
        this.setState({
            tableBulkRedaction,
            newBulkRedaction,
            valueTerm: e.target.value
        })
    }
    onDeleteRow = (e, record) => {
     let { tableBulkRedaction, newBulkRedaction } = this.state
        newBulkRedaction =  newBulkRedaction.filter((list) => list.status !== 'push')
        this.setState({
            tableBulkRedaction,
            newBulkRedaction,
            disabledAddTerm: false,
            valueInputRow: '',
            selectedRowKeys: [],
            selectedRow : [],
            name: '',
            valueTerm: '',
            dictionaryIdSelect: null,
        })
    }
    onAddNewDictionaries = (e, record) => {
        const { tableBulkRedaction, newBulkRedaction } = this.state
        newBulkRedaction && newBulkRedaction.length > 0 && newBulkRedaction.forEach((item) => {
            if (item.status === 'push') {
                item.status = 'push'
            } else {
                item.status = ''
            }
        })
        this.setState({
            modalVisibleAddDictionary: true,
            disabledAddTerm: true,
            tableBulkRedaction,
            newBulkRedaction
        })
    }

    getEditDetailedTreeSettingsRedaction = () => {
        const { valueTerm, name, dictionaryIdSelect, createDictionary, color, description } = this.state
        const { folder } = this.props
        const data = {
            createDictionary: createDictionary,
            dictionaryName: name ? name : null,
            dictionaryDescription: description ? description : null,
            dictionaryColor: createDictionary ? color : null,
            dictionaryTerm: valueTerm ? valueTerm : null,
            dictionaryId: dictionaryIdSelect ? dictionaryIdSelect : null,
            folderPath: folder.path ? folder.path : null,
        }
        apiEditDetailedTreeSettingsRedaction(data).then(() => {
            this.getNewBulkRedaction()
            this.setState({
                visibleAddRow: false
            })
        })
    }

    updateValue(e, field) {
        this.setState({
            [field]: e.target.value
        })
    }
    handleCancel = (e) => {
        const { tableBulkRedaction, newBulkRedaction } = this.state
        newBulkRedaction && newBulkRedaction.length > 0 && newBulkRedaction.forEach((item) => {
            if (item.status === 'push') {
                item.status = 'add'
            }
        })
        this.setState({
            modalVisibleAddDictionary: false,
            color: 'rgba(5, 195, 71, 1)',
            tableBulkRedaction,
            newBulkRedaction
        })
    }
    createAddNewDictionary = () => {
       const { dictionariesData, tableBulkRedaction, newBulkRedaction } = this.state
        //const data = {name: name, description: description, term: categoryDisplayedTerm, color: color}
        // createBusinessList(data).then((response) => {
        //     const newList = {}
        //     newList.id = response.data.id
        //     newList.name = response.data.listName
        //     newList.description = response.data.description
        //     newList.displayTerm = response.data.displayTerm
        //     newList.colour = response.data.colour
        //     dictionariesData.push(newList)
        //     newBulkRedaction && newBulkRedaction.length > 0 && newBulkRedaction.forEach((item, index) => {
        //         if (item.status === 'add' || item.status === 'push') {
        //             //item.status = 'start'
        //             item.status = 'push'
        //         }
        //         //item.id = index + 2
        //     })
        //     this.setState({
        //         dictionariesData,
        //         modalVisibleAddDictionary: false,
        //         selectDictionariesRow: response.data.listName,
        //         tableBulkRedaction,
        //         disabledAddTerm: false,
        //         valueInputRow: '',
        //         //newBulkRedaction
        //     })
        // }).catch((error) => {
        //     if (error.response.status === 504) {
        //         CustomNotificationManager.cleanBuffer('warning', '')
        //     }
        // })
        const newList = []
            newBulkRedaction && newBulkRedaction.length > 0 && newBulkRedaction.forEach((item, index) => {
                if ((item.status !== 'add' || item.status !== 'push') && item.id !== 0) {
                    //item.status = 'start'
                    newList.push(item)
                }
                //item.id = index + 2
            })
            this.setState({
                newBulkRedaction: newList,
                visibleAddRow: true
            })
        this.setState({
            dictionariesData,
            modalVisibleAddDictionary: false,
            tableBulkRedaction,
            disabledAddTerm: false,
            valueInputRow: '',
            createDictionary: true,
        }, () => {
            this.getEditDetailedTreeSettingsRedaction()
        })
    }
    changePerPageRedaction = (e) => {
        const { newBulkRedaction } = this.state
       this.setState({
           redactionPerPage: e
       }, () => {
           if (newBulkRedaction && newBulkRedaction.length > 0) {
               this.getNewBulkRedaction()
           }
       })
    }
    gotoPage = (newPage) => {
        const { totalRecords, redactionPerPage, newBulkRedaction } = this.state
        const totalPages = Math.ceil(totalRecords / redactionPerPage)
        newPage = Math.max(0, Math.min(newPage, totalPages))
        const inputNewPage = Math.max(0, Math.min(newPage, totalPages))
        this.setState({ currentPage: newPage, inputPageRedaction: inputNewPage }, () => {
            if (newBulkRedaction && newBulkRedaction.length > 0) {
                this.getNewBulkRedaction()
            }
        })
    }
    updatePreviewNextPageNumber = () => {
        const { totalRecords, currentPage } = this.state
        const totalPages = Math.ceil(totalRecords / this.state.redactionPerPage)
        const isPrevDisabled = currentPage > 1 ? false : true
        const isNextDisabled = currentPage < totalPages ? false : true
        this.setState({ isPrevDisabled, isNextDisabled, currentPage })
    }
    handleMovePageRedaction = (e, direction) => {
        const { isPrevDisabled, redactionPerPage, totalRecords, isNextDisabled, statusActivePagination } = this.state
        switch (direction) {
            case 'first':
                e.preventDefault()
                if (!isPrevDisabled && !statusActivePagination) {
                    this.setState({
                        statusActivePagination: true
                    }, () => {
                        this.gotoPage(1)
                    })
                }
                break
            case 'previous':
                e.preventDefault()
                if (!isPrevDisabled && !statusActivePagination) {
                    this.setState({
                        statusActivePagination: true
                    }, () => {
                        this.gotoPage(this.state.currentPage - 1 ? this.state.currentPage - 1 : 1)
                    })
                }
                break
            case 'next':
                e.preventDefault()
                if (!isNextDisabled && !statusActivePagination) {
                    this.setState({
                        statusActivePagination: true
                    }, () => {
                        this.gotoPage(this.state.currentPage + 1 > Math.ceil(totalRecords / redactionPerPage) ? Math.ceil(totalRecords / redactionPerPage) : this.state.currentPage + 1)
                    })

                }
                break
            case 'last':
                e.preventDefault()
                if (!isNextDisabled && !statusActivePagination) {
                    this.setState({
                        statusActivePagination: true
                    }, () => {
                        this.gotoPage(Math.ceil(totalRecords/redactionPerPage))
                    })
                }
                break
            default:
                e.preventDefault()
        }
    }
    handleInputPageChange = (e) => this.setState({ inputPageRedaction: e.target.value })
    handleInputPageBlur = () => {
        const { inputPageRedaction } = this.state
        if (inputPageRedaction) {
            const paginationEnd = Math.ceil(this.state.totalRecords / this.state.redactionPerPage)
            if (inputPageRedaction > 0 && inputPageRedaction <= paginationEnd) {
                this.setState({
                    statusActivePagination: true
                }, () => {
                    this.gotoPage(inputPageRedaction)
                })
            } else {
                this.setState({
                    statusActivePagination: true
                }, () => {
                    this.gotoPage(1)
                })
                this.setState({ inputPageRedaction: 1 })
            }
        }
    }
    handleInputPageEnter = (e) => {
        if (e.key === 'Enter') {
            this.handleInputPageBlur()
        }
    }
    onChangeSearchRedaction = (e, event) => {
        const { valueOptionAll } = this.state
        const inputSearchRedactArray = []
        inputSearchRedactArray.push(`#--#${e.length > 0 ? e.trim() : e}#--#${valueOptionAll}`)
        this.setState({
            //valueInputSearchRedactArray: inputSearchRedactArray,
            valueInputSearchRedact: e.length > 0 ? e.trim() : e,
            filterSearchTermNew: e.length > 0 ? e.trim() : e,
            checkSearchSelected: true,
            currentPage: 1,
            inputPageRedaction: 1
        }, () => {
            if (e.length > 1) {
                this.getNewBulkRedaction()
            }
            if (e === '') {
                this.getNewBulkRedaction()
            }
        })
    }

    onChangeSearchSelectKeys = () => {
        const { newBulkRedaction, selectAllChecked } = this.state
        const { nonSelectedWords } = this.props
        let allKeysSelected = []
        let selectAllWords = []
        let newSelectAllWords = []
        if (selectAllChecked) {
            newBulkRedaction && newBulkRedaction.length > 0 && newBulkRedaction.forEach((red) => {
                if (nonSelectedWords && nonSelectedWords.length > 0) {
                    if (!nonSelectedWords.includes(red.word)) {
                        newSelectAllWords.push(red.word)
                        allKeysSelected.push(red.key)
                        selectAllWords.push(red.word)
                    }

                } else {
                    allKeysSelected.push(red.key)
                    selectAllWords.push(red.word)
                }
            })
            //change 17
            this.setState({
                //selectedRowKeys: allKeysSelected,
                selectedRowWord: selectAllWords,
            })
        }
    }

    onSearchRedactionTerm = (e) => {
        const { valueOptionAll } = this.state
        const inputSearchRedactArray = []
        inputSearchRedactArray.push(`#--#${e.length > 0 ? e.trim() : e}#--#${valueOptionAll}`)
        this.setState({
            //valueInputSearchRedactArray: inputSearchRedactArray,
            valueInputSearchRedact: e.target.value.length > 0 ? e.target.value.trim() : e.target.value,
            filterSearchTermNew: e.target.value.length > 0 ? e.target.value.trim() : e.target.value,
            currentPage: 1,
            inputPageRedaction: 1,
            checkSearchSelected: true
        }, () => {
            if (e.target.value.length > 1) {
                this.getNewBulkRedaction()
            }
            if (e.target.value === '') {
                this.getNewBulkRedaction()
            }
        })
    }
    onKeyPressRedactionTerm = (e) => {
        const { valueOptionAll } = this.state
        const inputSearchRedactArray = []
        inputSearchRedactArray.push(`#--#${e.length > 0 ? e.trim() : e}#--#${valueOptionAll}`)
        this.setState({
            //valueInputSearchRedactArray: inputSearchRedactArray,
            valueInputSearchRedact: e.target.value.length > 0 ? e.target.value.trim() : e.target.value,
            filterSearchTermNew: e.target.value.length > 0 ? e.target.value.trim() : e.target.value,
            checkSearchSelected: true
        }, () => {
            // if (e.target.value.length > 1) {
            //     this.getNewBulkRedaction()
            // }
            // if (e.target.value === '') {
            //     this.getNewBulkRedaction()
            // }
        }, () => {
            this.getNewBulkRedaction()
        })

    }
    onMouseEnterNavigation(e, status) {
        switch (status) {
            case 'forth' :
                this.setState({
                    onHoverForth: true
                })
                break
            case 'start' :
                this.setState({
                    onHoverStart: true
                })
                break
            case 'back' :
                this.setState({
                    onHoverBack: true
                })
                break
            case 'end' :
                this.setState({
                    onHoverEnd: true
                })
                break
            case 'reset' :
                this.setState({
                    onHoverReset: true
                })
                break
            case 'add':
                this.setState({
                    hoverAddTerm: true
                })
                break
            case 'delete':
                this.setState({
                    onHoverDelete: true
                })
                break
            case 'edit':
                this.setState({
                    onHoverEdit: true
                })
                break
            default: break
        }
    }
    onMouseLeaveOnPanel(e, status) {
        this.setState({
            onHoverForth: false,
            onHoverStart: false,
            onHoverBack: false,
            onHoverEnd: false,
            onHoverReset: false,
            hoverAddTerm: false,
            onHoverDelete: false,
            onHoverEdit: false,
        })

    }
    onResetAllFilters = () => {
       this.setState({
           selectFilterRegEx: null,
           selectFilterDictionaries: null,
           valueOptionAll: 'all',
           allCheckedNodes: [],
           checkedKeysPii: [],
           selectFilterDictionariesIds: null,
            checkApplyPiiFilter: false,
           checkResetFiltre: false,
            regexListIds: [],
           // allCheckedNodesDetail: [],
            checkedKeysDictionary: [],
            checkedKeysRegEx: [],
            regexListSelected: [],
           regexSelectedId: [],
           filterRedactionTypeNew: 'all',
           //filterSearchTermNew: '',
           selectedPiiCategoryNew: [],
           selectedRegexNew: [],
           selectedDictionaryNew: [],
           // selectedWordNew: '',
           // excludedWordNew: '',
           redactAllNew: false,
       }, () => this.getNewBulkRedaction())
    }
    onValueOptionRedacted = (valueOptionAll) => {
        return (
            <span className="content-value"><span className="value-option">{ valueOptionAll }</span><span className="icon-v19-arrow-medium"></span></span>
        )
    }
    onLoadData = (treeNode) =>
        new Promise(resolve => {
            const { id } = treeNode.props;
            setTimeout(() => {
                this.setState({
                    treeData: this.state.treeData.concat([
                        this.genTreeNode(id, false),
                        this.genTreeNode(id, true),
                    ]),
                });
                resolve()
            }, 300)
        })
    genTreeNode = (parentId, isLeaf = false) => {
        const random = Math.random()
            .toString(36)
            .substring(2, 6);
        return {
            id: random,
            pId: parentId,
            value: random,
            title: isLeaf ? 'Tree Node' : 'Expand to load',
            isLeaf,
        }
    }
    onClickMenu = (e) => {
        this.setState({
            typeNewRedact: e.key
        })
    }
    menuNewest = () => {
        return  <Menu onClick={this.onClickMenu}>
            <Menu.Item id="Redact all" key="Redact (All)">Redact (All)</Menu.Item>
            <Menu.Item id="Redact (Contextual)" key="Redact (Contextual)">Redact (Contextual)</Menu.Item>
        </Menu>;
    }
    handleNewRedact = (e) => {
        e.preventDefault()
        this.onGetSaveRedactionFilterSelectionsStartBulk(e)
    }
    render() {
        const { currentPage, selectedRowKeys, valueOptionAll, treeAllPii, checkedKeysPii, treeExpandedKeys, dictionariesData, regexList, valueInputRow, selectDictionariesRow, modalVisibleAddDictionary,
            name, disabledAddTerm, redactionPerPage, isNextDisabled, isPrevDisabled, totalRecords, inputPageRedaction, valueInputSearchRedact,
            onHoverForth, onHoverStart, onHoverBack, onHoverEnd, newBulkRedaction, startBulkRedaction, checkedKeysDictionary, checkedKeysRegEx,
            selectAllChecked, getSelectedRegEx, selectedDictionaries, selectedRegEx, redactAllGlobal, selectedDictionaryGlobal, selectedPiiGlobal, selectedWordGlobal,
            onHoverReset, hoverAddTerm, onHoverDelete, onHoverEdit, visibleAddRow, statusOpenAddTerm, nonSelectedWords, excludedDictionaryGlobal, firstLoadPage,
            excludedDictionaryGlobalNames, selectedDictNamesGlobal, excludedPiiGlobal, excludedRegExGlobal, regexSelectedId, excludedWords, statusActivePagination,
            loadingBulkRedaction, selectedRowWordAll, selectedRowWord, checkSelectAll, statusSelectAll, selectedRowKeysUpdate, statusResponseSaveRedaction,
            redactAllNew , uniqueIdFolderRedactionLastSelection, typeNewRedact, loadingRedact, statusSaveRedaction} = this.state
        const rowSelection = {
            selectedRowKeys,
            //selectedRowKeysUpdate,
            onChange: this.onChangeRowRedact,
            onSelect: this.onRowSelected,
            type: "checkbox",
            getCheckboxProps: record => ({
                disabled: statusResponseSaveRedaction === true ? true : false
            }),
        }
        const totalPages =  Math.ceil(totalRecords / redactionPerPage)
        const antIcon = <Icon type="loading" style={{ fontSize: 15 }} spin />;
        return (
            <div className="new-page-redact">
                <div className="header-new-page-redact">
                    <div className="header-title-bulk">
                        <span className="title-redact">Redact</span>
                        <span className="space-redact"></span>
                    </div>
                    <div className="button-bulk-go">
                        {/*<Button className="button-blue-bulk button-blue-bulk-stop"*/}
                        {/*        disabled={ checkBulkStatus === false || checkStopBulk === true }*/}
                        {/*onClick={(e) => this.onStopBulkRedaction()}>*/}
                        {/*    Stop Bulk*/}
                        {/*</Button>*/}
                        {/*<Button className="button-blue-bulk button-blue-bulk-resume"*/}
                        {/*        disabled={ checkResumeStatus === false}*/}
                        {/*        onClick={ (e) => this.onResumeBulk() }*/}
                        {/*    >*/}
                        {/*    Resume Bulk*/}
                        {/*</Button>*/}

                        {/*<Button className="button-blue-bulk"*/}
                        {/*        loading={ loadingBulkRedaction }*/}
                        {/*      disabled={ uniqueIdFolderRedactionLastSelection == null  && (statusResponseSaveRedaction === true || startBulkRedaction === true || statusOpenAddTerm === true ||*/}
                        {/*            ( redactAllGlobal === false && selectedDictionaryGlobal.length === 0 &&*/}
                        {/*            selectedPiiGlobal.length === 0 && selectedWordGlobal.length === 0 && selectedRowWordAll.length === 0))*/}
                        {/*        }*/}
                        {/*onClick={ (e) => this.onGetSaveRedactionFilterSelectionsStartBulk(e) }>*/}
                        {/*    Bulk Redaction*/}
                        {/*</Button>*/}


                        <Dropdown.Button
                            icon={<span className="icon-v21-arrow-bold"></span>}
                            className="button-dropdown-redact"
                            // loading={loadings[1]}
                            overlay={this.menuNewest}
                            //dropdownClassName="dropdown-newest-class"
                            disabled={ uniqueIdFolderRedactionLastSelection == null  && (statusResponseSaveRedaction === true || startBulkRedaction === true || statusOpenAddTerm === true ||
                                (statusSaveRedaction === false && redactAllGlobal === false && selectedDictionaryGlobal.length === 0 &&
                                    selectedPiiGlobal.length === 0 && selectedWordGlobal.length === 0 && selectedRowWordAll.length === 0))
                            }
                            onClick={(e) => this.handleNewRedact(e)}
                        >
                            { <span><Spin spinning={loadingRedact} indicator={antIcon} /><span>{typeNewRedact}</span></span> }
                        </Dropdown.Button>
                    </div>
                </div>
                <div className="page-bulk-redaction">
                    <div className="title-bulk-redaction">
                        <span className="title-bulk">Bulk redaction</span>
                        <Tooltip overlayClassName="help-message-locations" title={<div className="content-help-message-locations">
                            <span className="text-information">Bulk Redaction is the process of redacting selected terms within a whole Box and its sub-folders.</span>
                            <span className="more-info-link" onClick={ () => window.open('https://support.smartbox.ai/support/solutions/articles/47001174231-bulk-redaction', '_blank') }>More info.</span>
                            </div>}>
                            <span className="icon-v14-question"></span>
                        </Tooltip>
                    </div>
                    <div className="content-bulk-redaction">
                        <div className="pagination-bulk-redaction">
                            <div className="per-page-redaction">
                                <span className="name-show">Show</span>
                                <Select dropdownClassName="select-per-page-redaction"
                                        disabled={ !newBulkRedaction || startBulkRedaction === true }
                                onChange={(e) => this.changePerPageRedaction(e)}
                                        value={ redactionPerPage }
                                        //value={<span><span>{ `Show ${ redactionPerPage }` } </span><span className="icon-v19-arrow-medium"></span></span> }
                                //defaultValue={ redactionPerPage }
                                >
                                    <Option value="20">20</Option>
                                    <Option value="50">50</Option>
                                    <Option value="100">100</Option>
                                </Select>
                            </div>
                            <div className="buttons-control-pagination">
                                <Tooltip title="first">
                                    <span className={ statusActivePagination ? 'icon-v19-start-1 disabled-prev' : isPrevDisabled ? 'icon-v19-start-1 disabled-prev' : newBulkRedaction.length === 0 ? 'icon-v19-start-1 disabled-prev' : onHoverStart ? 'icon-v19-start-active' : 'icon-v19-start-1' }
                                          onClick={ (e) => this.handleMovePageRedaction(e, 'first')}
                                          onMouseEnter={ (e) => this.onMouseEnterNavigation(e, 'start') }
                                          onMouseLeave={ () => this.onMouseLeaveOnPanel() }/>
                                </Tooltip>
                                <Tooltip title="previous">
                                    <span className={ statusActivePagination ? 'icon-v19-back-1 disabled-prev' : isPrevDisabled ? 'icon-v19-back-1 disabled-prev' : newBulkRedaction.length === 0 ? 'icon-v19-start-1 disabled-prev' : onHoverBack ? 'icon-v19-back-1 active-back' : 'icon-v19-back-1' }
                                          onClick={ (e) => this.handleMovePageRedaction(e, 'previous')}
                                          onMouseEnter={ (e) => this.onMouseEnterNavigation(e, 'back') }
                                          onMouseLeave={ () => this.onMouseLeaveOnPanel() }/>
                                </Tooltip>
                                {totalPages === 0 &&
                                <span className='buttons-control-pagination__text'>{1} of {1} </span>
                                }
                                {totalPages === 1 &&
                                <span className='buttons-control-pagination__text'>{currentPage} of {totalPages} </span>
                                }
                                {totalPages > 1 &&
                                  <span className='buttons-control-pagination__text'>
                                    <input
                                        type="number"
                                        className="insert-page"
                                        placeholder={ currentPage }
                                        min="1"
                                        max={totalPages}
                                        value={ inputPageRedaction }
                                        disabled={ statusActivePagination === true }
                                        onChange={ (e) => this.handleInputPageChange(e) }
                                        onBlur={ () => this.handleInputPageBlur() }
                                        onKeyDown={ (e) => this.handleInputPageEnter(e) }
                                    />
                                 <span>of {totalPages}</span>
                                </span>
                                }
                                <Tooltip title="next">
                                    <span className={ statusActivePagination ? 'icon-v19-forth-1 disabled-next' : isNextDisabled ? 'icon-v19-forth-1 disabled-next' : newBulkRedaction && newBulkRedaction.length === 0 ? 'icon-v19-forth-1 disabled-next' :  onHoverForth ? 'icon-v19-forth-1 next-active' : 'icon-v19-forth-1' }
                                          onClick={ (e) => this.handleMovePageRedaction(e, 'next')}
                                          onMouseEnter={ (e) => this.onMouseEnterNavigation(e, 'forth') }
                                          onMouseLeave={ () => this.onMouseLeaveOnPanel() }/>
                                </Tooltip>
                                <Tooltip title="last">
                                    <span className={ statusActivePagination ? 'icon-v19-end-1 disabled-next' : isNextDisabled ? 'icon-v19-end-1 disabled-next' : newBulkRedaction && newBulkRedaction.length === 0 ? 'icon-v19-end-1 disabled-next' : onHoverEnd ? 'icon-v19-end-active' : 'icon-v19-end-1' }
                                          onClick={ (e) => this.handleMovePageRedaction(e, 'last')}
                                          onMouseEnter={ (e) => this.onMouseEnterNavigation(e, 'end') }
                                          onMouseLeave={ () => this.onMouseLeaveOnPanel() }/>
                                </Tooltip>
                            </div>
                            <div className="input-search-bulk-redaction">
                                <Search className="search-redaction" placeholder="Search"
                                        allowClear
                                        style={{width: 200}}
                                        defaultValue={ valueInputSearchRedact }
                                        disabled={ !newBulkRedaction || startBulkRedaction === true}
                                        onSearch={ this.onChangeSearchRedaction }
                                        onChange={ (e) => this.onSearchRedactionTerm(e) }
                                        onKeyPress={ (e) => this.onKeyPressRedactionTerm(e) }
                                />
                            </div>
                        </div>

                        <div className="filters-bulk-redaction">
                            <div className="content-filters-redaction">
                                <Select
                                    placeholder="All"
                                    className='custom-option-redaction'
                                    dropdownClassName="filter-option-all-redacted"
                                    //value={ this.onValueOptionRedacted(valueOptionAll) }
                                    value={ valueOptionAll }
                                    disabled={ !newBulkRedaction || startBulkRedaction === true }
                                    onChange={(e) => this.changeOptionAllRedacted(e)}
                                >
                                    <Option className="item-options-review" key={1} value="all">
                                        All
                                    </Option>
                                    <Option className="item-options-review" key={2} value="redacted">
                                        Redacted
                                    </Option>
                                    <Option className="item-options-review" key={3} value="unredacted">
                                        Unredacted
                                    </Option>
                                </Select>

                                <TreeSelect
                                    treeAllPii
                                    //checkable
                                    className='custom-option-redaction tree-select-option-redaction'
                                    dropdownClassName="dropdown-tree-pii-options tree-pii-options"
                                    value={ checkedKeysPii }
                                    placeholder={ <span className="place-holder-pii"><span>{checkedKeysPii && checkedKeysPii.length > 0 ? `PII (${ checkedKeysPii.length })` : 'PII' }</span><span className="icon-v19-arrow-medium"></span></span> }
                                    treeCheckable= { true }
                                    showArrow= { false }
                                    disabled={ !newBulkRedaction || startBulkRedaction === true }
                                    onTreeExpand={ this.onTreeExpandPii }
                                    treeExpandedKeys={ this.state.treeExpandedKeys }
                                    //treeDefaultExpandedKeys={ treeExpandedKeys }
                                    //treeDefaultExpandAll
                                    //treeCheckStrictly={ true }
                                    loadData={this.onLoadData}
                                    onChange={ this.onChangeTree }>
                                    {
                                        treeAllPii && treeAllPii.length > 0 && treeAllPii.map((tree, index) => {
                                            return (
                                                <TreeNode selectable={true} value={tree.title} title={ this.generateTitleTreeParent(tree) } key={tree.key} className="tree-parent-node">
                                                    { tree && tree.children && tree.children.length > 0 && tree.children.map((treeChild, index) => {
                                                        if (treeChild && typeof treeChild !== 'undefined') {
                                                            return (
                                                                <TreeNode selectable={true} value={treeChild.title} title={this.generateTitleTreeChild(treeChild)} key={treeChild.key} className="tree-child-node"/>

                                                            )
                                                        }
                                                        return null
                                                    })

                                                    }
                                                </TreeNode>
                                            )
                                        })
                                    }
                                    {/*<TreeNode disableCheckbox={ true } value="footer-buttons" title={this.generateFooterTreePii()} key="2" className="tree-buttons-node">*/}
                                    {/* </TreeNode>*/}
                                </TreeSelect>
                                { selectedDictionaries && selectedDictionaries.length > 0 ?
                                    <TreeSelect
                                        dictionariesData
                                        className='custom-option-redaction tree-select-option-redaction'
                                        dropdownClassName="dropdown-tree-pii-options dropdown-tree-dictionary"
                                        value={checkedKeysDictionary}
                                        placeholder={<span
                                            className="place-holder-pii"><span>{checkedKeysDictionary && checkedKeysDictionary.length > 0 ? `Dictionaries (${checkedKeysDictionary.length})` : 'Dictionaries'}</span><span
                                            className="icon-v19-arrow-medium"></span></span>}
                                        treeCheckable={true}
                                        showArrow={false}
                                        disabled={!newBulkRedaction || startBulkRedaction === true}
                                        onChange={dictionariesData && dictionariesData.length > 0 ? this.onChangeDictionaryTree : (e) => this.stopPropagationDictionary(e)}>
                                        {
                                            selectedDictionaries && selectedDictionaries.length > 0 && selectedDictionaries.map((tree, index) => {
                                                // if (getSelectedDictionaries && getSelectedDictionaries.length > 0 && getSelectedDictionaries.includes(tree.id.toString())) {
                                                    return (
                                                        <TreeNode value={tree.listName}
                                                                  title={this.generateTitleTreeDictionary(tree)}
                                                                  key={tree.id} className="tree-parent-node">
                                                        </TreeNode>
                                                    )
                                                // }
                                            })
                                        }
                                    </TreeSelect> :
                                    <TreeSelect
                                        dictionariesData
                                        className='custom-option-redaction tree-select-option-redaction'
                                        dropdownClassName="dropdown-tree-pii-options dropdown-tree-dictionary"
                                        value={checkedKeysDictionary}
                                        placeholder={<span
                                            className="place-holder-pii"><span>{checkedKeysDictionary && checkedKeysDictionary.length > 0 ? `Dictionaries (${checkedKeysDictionary.length})` : 'Dictionaries'}</span><span
                                            className="icon-v19-arrow-medium"></span></span>}
                                        treeCheckable={true}
                                        showArrow={false}
                                       >
                                    </TreeSelect>
                                }
                                {/*<Select*/}
                                {/*    placeholder="Dictionaries"*/}
                                {/*    className='custom-option-redaction'*/}
                                {/*    onChange={ (e) => this.onChangeDictionaries(e) }*/}
                                {/*    value={ selectFilterDictionaries ? selectFilterDictionaries : 'Dictionaries' }*/}
                                {/*    disabled={ !newBulkRedaction || startBulkRedaction === true }*/}
                                {/*    dropdownClassName="dropdown-filters-options"*/}
                                {/*>*/}
                                {/*    { dictionariesData && dictionariesData.length > 0 && dictionariesData.map((dict) => {*/}
                                {/*        return (*/}
                                {/*            <Option className="item-options-review" key={ dict.id } value={ dict.name }>*/}
                                {/*                { dict.name }*/}
                                {/*            </Option>*/}
                                {/*        )*/}
                                {/*    })*/}
                                {/*    }*/}
                                {/*</Select>*/}

                                { getSelectedRegEx.length === 0 ?
                                    <TreeSelect
                                        regexList
                                        className='custom-option-redaction tree-select-option-redaction'
                                        dropdownClassName="dropdown-tree-pii-options dropdown-tree-regex"
                                        value={checkedKeysRegEx}
                                        placeholder={<span
                                            className="place-holder-pii"><span>{ 'Regular Expressions' }</span><span
                                            className="icon-v19-arrow-medium"></span></span>}
                                        treeCheckable={false}
                                        showArrow={false}
                                        //disabled={ true }
                                    >
                                    </TreeSelect> :
                                    <TreeSelect
                                        regexList
                                        className='custom-option-redaction tree-select-option-redaction'
                                        dropdownClassName="dropdown-tree-pii-options dropdown-tree-regex"
                                        value={checkedKeysRegEx}
                                        placeholder={<span
                                            className="place-holder-pii"><span>{checkedKeysDictionary && checkedKeysRegEx.length > 0 ? `Regular Expressions (${checkedKeysRegEx.length})` : 'Regular Expressions'}</span><span
                                            className="icon-v19-arrow-medium"></span></span>}
                                        treeCheckable={true}
                                        showArrow={false}
                                        disabled={!newBulkRedaction || startBulkRedaction === true}
                                        onChange={regexList && regexList.length > 0 ? this.onChangeRegExTree : (e) => this.stopPropagationRegEx(e)}>
                                        {
                                            selectedRegEx && selectedRegEx.length > 0 && selectedRegEx.map((tree, index) => {
                                                // if (getSelectedRegEx && getSelectedRegEx.length > 0 && getSelectedRegEx.includes(tree.regex)) {
                                                    return (
                                                        <TreeNode value={tree.title}
                                                                  title={this.generateTitleTreeRegEx(tree)}
                                                                  key={tree.id}
                                                                  className="tree-parent-node">
                                                        </TreeNode>
                                                    )
                                                // }
                                            })
                                        }
                                    </TreeSelect>
                                }
                                {/*<Select*/}
                                {/*    placeholder="Regular expressions"*/}
                                {/*    className='custom-option-redaction'*/}
                                {/*    dropdownClassName="dropdown-filters-options"*/}
                                {/*    value={ selectFilterRegEx ? selectFilterRegEx : 'Regular Expressions'}*/}
                                {/*    onChange={ (e) => this.onChangeRegularExpressions(e) }*/}
                                {/*    disabled={ !newBulkRedaction || startBulkRedaction === true }*/}
                                {/*>*/}
                                {/*    { regexList && regexList.length > 0 && regexList.map((reg) => {*/}
                                {/*        return (*/}
                                {/*            <Option className="item-options-review" key={ reg.id } value={ reg.title }>*/}
                                {/*                { reg.title }*/}
                                {/*            </Option>*/}
                                {/*        )*/}
                                {/*    })*/}

                                {/*    }*/}
                                {/*</Select>*/}
                            </div>
                            <div className="content-ai-dust">
                                <span onClick={ (e) => this.onResetAllFilters(e) }
                                      className={ onHoverReset ? 'icon-v19-clear-active' : "icon-v19-clear" }
                                      onMouseEnter={ (e) => this.onMouseEnterNavigation(e, 'reset') }
                                      onMouseLeave={ () => this.onMouseLeaveOnPanel() }></span>
                                {/*<div className="ai-dust">*/}
                                {/*<div className='checkbox-auto'>*/}
                                {/*    <Checkbox checked={false} className="select-deduplicate-checkbox"></Checkbox>*/}
                                {/*</div>*/}
                                {/*<div className="select-auto-dust">*/}
                                {/*    <span>incl AI dust</span>*/}
                                {/*</div>*/}
                                {/*<span className="icon-v19-question"></span>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className="table-bulk-redaction">
                            <Table className="table-new-redaction"
                                   dataSource={ newBulkRedaction }
                                   pagination={false}
                                   loading={ startBulkRedaction === true }
                                   rowClassName={(record) => this.rowClassSelected(record) }
                                   checkStrictly={ false }
                                   onChange={this.onChangeRedact}
                                   //bordered={true}
                                   rowSelection={ rowSelection }>
                                <Column className="column-ai-results column-ai-select-all" title={ <span className="select-all-bulk">
                                        <Checkbox
                                            className="select-deduplicate-checkbox"
                                            disabled={statusResponseSaveRedaction === true ? true : false}
                                            //checked={selectedRowKeys.length === newBulkRedaction.length ? true : selectAllChecked}

                                            checked={newBulkRedaction && selectedRowKeys.length === newBulkRedaction.length ? true : false}
                                            //checked={ redactAllNew ? true : false}
                                            onChange={(e) => this.selectAllBulkRedaction(e)}
                                        >
                                        </Checkbox></span> }
                                        key="check"
                                        dataIndex="check"
                                        />
                                    <Column className="column-ai-results" title={ <span>
                                        {/*<Checkbox*/}
                                        {/*          className="select-deduplicate-checkbox"*/}
                                        {/*          onChange={(e) => this.selectAllBulkRedaction(e)}*/}
                                        {/*>*/}

                                        {/*</Checkbox>*/}
                                        <span>Ai Results</span></span> }
                                            dataIndex="word"
                                        key="word"
                                            sorter = { this.sorterTable }
                                      render={(text, record) => {
                                       return (
                                                record.status === 'add' && !visibleAddRow ?
                                               <Input placeholder="New term"
                                               defaultValue={ valueInputRow }
                                                      key={text}
                                                      className="input-value-row"
                                               onChange={(e) => this.updateInputRow(e, record)}
                                                      onPressEnter={(e) => this.onPressEnterInput(e, record)}/> :
                                                    record.status === 'push' && !visibleAddRow ?
                                                        <span key={record.word} ><span key={record.word} className="text-ai-results">{ text }</span>
                                                            <span onClick={ (e) => this.editNewTerm(e, record) }
                                                                  onMouseEnter={ (e) => this.onMouseEnterNavigation(e, 'edit') }
                                                                  onMouseLeave={ () => this.onMouseLeaveOnPanel() }
                                                                  key={record.word}
                                                                  className={ onHoverEdit ? 'icon-v19-edit-active' : "icon-v19-edit" }></span></span> :
                                            <span key={record.word} className="text-ai-results">{text}</span>
                                          )
                                     }}/>
                                <Column className="column-ai-categories" title={
                                    <span className="content-column-ai-results"><span>Ai categories</span>
                                    <span
                                          onClick={(e) => this.addDictionaryTerm(e)}
                                          onMouseEnter={ (e) => this.onMouseEnterNavigation(e, 'add') }
                                          onMouseLeave={ () => this.onMouseLeaveOnPanel() }
                                          className={ disabledAddTerm ? "icon-v19-add icon-v19-add-disabled" :
                                              hoverAddTerm ? 'icon-v19-add-active' : 'icon-v19-add' }></span></span>} dataIndex="firstLayerCategory"
                                        key="firstLayerCategory" render={(text, record) => {
                                    return (
                                        (record.status === 'add' && !visibleAddRow) || (record.status === 'push' && !visibleAddRow) ?
                                            <span key={ text } className="content-row-dictionaries">
                                            <Select
                                                placeholder="Dictionaries"
                                                key={ text }
                                                className='custom-option-redaction'
                                                disabled={ record.status === 'add' }
                                                onSelect={ (e) => this.onSelectDictionariesRow(e) }
                                                //onChange={ (e) => this.onChangeDictionariesRow(e) }
                                                value={ selectDictionariesRow === 'new-dictionary' ? 'Add dictionaries' : selectDictionariesRow ? selectDictionariesRow : 'Add dictionaries' }
                                                dropdownClassName="custom-option-redaction-row"
                                            >
                                                { dictionariesData && dictionariesData.length > 0 && dictionariesData.map((dict, idD) => {
                                                    return (
                                                        <Option className="item-options-review" key={ dict.id } value={ dict.name }>
                                                            { dict.name }
                                                        </Option>
                                                    )
                                                })
                                                }
                                                <Option className="item-add-dictionaries" key={ text } value="new-dictionary">
                                                    <span onClick={(e) => this.onAddNewDictionaries(e, record)} className="content-add-dictionaries">
                                                        <span className="icon-v19-add"></span>
                                                        <span>New Dictionary</span>
                                                    </span>
                                                </Option>
                                            </Select>
                                                <span key={ text } className={ record.status === 'add' ? "icon-v19-delete icon-v19-delete-disabled" :
                                                    onHoverDelete ? 'icon-v19-delete-active' : 'icon-v19-delete' }
                                                      onMouseEnter={ (e) => this.onMouseEnterNavigation(e, 'delete') }
                                                      onMouseLeave={ () => this.onMouseLeaveOnPanel() }
                                                onClick={(e) => this.onDeleteRow(e, record)}></span>
                                            </span>
                                            :
                                         <span key={ text } className="text-ai-categories">
                                             <span>{ text }</span>
                                             <span> { text ? '>' : '' } </span>
                                             <span>
                                             {
                                             record.secondLayerCategories && record.secondLayerCategories.length > 0 && record.secondLayerCategories.map((second, key) => {
                                                 return (
                                                     <span key={key}>{ `${ second }${ key === record.secondLayerCategories.length - 1 ? '' : ','}` }</span>
                                                 )
                                             })
                                             }
                                         </span>
                                             <span> {'>'} </span>
                                             <span>
                                             {
                                                 record.thirdLayerCategories && record.thirdLayerCategories.length > 0 && record.thirdLayerCategories.map((second, key) => {
                                                     return (
                                                         <span key={ key }>{ `${ second }${ key === record.thirdLayerCategories.length - 1 ? '' : ','}` }</span>
                                                     )
                                                 })
                                             }
                                         </span>
                                         </span>
                                    )
                                }}/>
                            </Table>
                        </div>
                    </div>
                </div>
                <Modal className="dictionary-modal dictionary-modal-new-dictionary"
                       title={ 'New dictionary' }
                       visible={ modalVisibleAddDictionary } onCancel={ this.handleCancel } footer={ null } >
                    <div className="import-terms-section">
                        <form className="import-terms-form">
                            <React.Fragment>
                                <div className={"dict-modal-line dict-modal-line-first"}>
                                    <label>Dictionary name*</label>
                                    <Input onChange={(e) => this.updateValue(e, 'name')}
                                           placeholder="Name"/>
                                </div>
                                <div className={"dict-modal-line"}>
                                    <label>Description</label>
                                    <Input onChange={(e) => this.updateValue(e, 'description')}
                                           placeholder="Description"/>
                                </div>

                            </React.Fragment>
                            <div className="buttons">
                                <Button className="transp-orange" onClick={ this.handleCancel }>Cancel</Button>
                                <Button disabled={ (!name.trim())} className="orange" type="submit"
                                        onClick={ (e) => this.createAddNewDictionary(e) }>Create</Button>
                            </div>
                        </form>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id,
    intervalForBulkRedaction: state.smartBox.intervalForBulkRedaction,
    smartBoxNotRedactComplete: state.smartBox.smartBoxNotRedactComplete,
    smartBoxCheckBoxRedactionStatus: state.smartBox.smartBoxCheckBoxRedactionStatus,
    nonSelectedWords: state.smartBox.nonSelectedWords,
})


export default connect(mapStateToProps, {
    setSmartBoxNotFolderRedactionComplete,
    setSmartBoxCheckBoxRedactionStatus,
    setRedactionNonSelectedWords
})(NewFolderRedaction)