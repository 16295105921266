import React from 'react'
import { Radio, Select, Spin } from 'antd'
import Loading from '../../Common/Loading'
import { Field, reduxForm, submit } from 'redux-form'
import { connect } from 'react-redux'
import { fetchClientData, initializeFormInvite } from '../../../actions/adminActions'
import CustomNotificationManager from '../../Common/CustomNotificationManager'
import {apiCreateClient, getRolesByApplication, apiInviteTenant, apiInviteTenantGoodAdmin} from '../../../api/Admin'
import { apiViewPlan } from '../../../api/Plan'
import './ClientManagement.scss'
import PropTypes from 'prop-types'
import { getErrorMessage } from '../../../utils'

const { Option } = Select
const RadioGroup = Radio.Group

class AddNewInviteTenant extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      modalVisible: false,
      emailAddress: '',
      homeApplication: 'DOC_LOCKER',
      disabledCreateButton: false,
      applications: [
        { applicationId: '', name: '' },
        { applicationId: 'HOME', name: 'Home' },
        { applicationId: 'ASSESSMENTS', name: 'Assessments' },
        { applicationId: 'SAR_TRACKER', name: 'SAR Tracker' },
        { applicationId: 'ASSET_REGISTER', name: 'Asset Register' },
        { applicationId: 'DOC_LOCKER', name: 'Document Locker' },
        { applicationId: 'SMART_BOX', name: 'Smart Box' }
      ],
      ospreyRoles: [],
      dpiaRoles: [],
      multitenancyRoles: []

    }
  }

  componentDidMount() {
    // if (this.props) {
    //   const formType = this.props.action
    //   if (formType === 'add') {
    //     this.props.initializeData()
    //   }
    // }
    this.props.initializeFormInvite()
    this.fetchRoles()
    apiViewPlan().then((res) => {
      this.setState({
        plans: res.data,
        loading: false
      })
    })
  }
  fetchRoles = async() => {
    const res1 = await getRolesByApplication('dpia')
    const res2 = await getRolesByApplication('osprey')
    const res3 = await getRolesByApplication('multitenancy')

    this.setState({ loading: false, dpiaRoles: res1.data, ospreyRoles: res2.data, multitenancyRoles: res3.data })
  }
  handleSubmit = (formValues) => {
    formValues.homeApplication = this.state.homeApplication
    this.setState({
      loading: true
    }, () => {
      this.sendInviteTenant(formValues)
    })
  }
  sendInviteTenant = (formValues) => {
    apiInviteTenantGoodAdmin(formValues).then((response) => {
      if (response.status === 200) {
        CustomNotificationManager.success('Your invite has been sent.', 'Invited')
      }
    }).catch((error) => {
      this.setState({ loading: false })
      let errorToDisplay = getErrorMessage(error).length === 2 ? getErrorMessage(error)[1] : getErrorMessage(error)
      if (errorToDisplay === 'CLIENT_ALREADY_EXISTS_EXCEPTION') {
        errorToDisplay = 'This email is already used.'
        CustomNotificationManager.error(errorToDisplay, 'Error')
      }
    })
  }
  createOrUpdate = (formValues) => {
    apiCreateClient(formValues).then((response) => {
      if (response.status === 200) {
        this.setState({
          loading: false,
          disabledCreateButton: true
        }, () => {
          CustomNotificationManager.success('Client was successfully created ', 'Success')
          //this.props.setNewClientData(response.data)
        })

      }
    }).catch((error) => {
      this.setState({ loading: false })
      let errorToDisplay = getErrorMessage(error).length === 2 ? getErrorMessage(error)[1] : getErrorMessage(error)
      if (errorToDisplay === 'CLIENT_ALREADY_EXISTS_EXCEPTION') {
        errorToDisplay = 'This email is already used.'
        CustomNotificationManager.error(errorToDisplay, 'Error')
      }
    })
  }

  renderError({ error, touched }) {
    if (touched && error) {
      return (
        <span className="error_container">
          <span className="error_item">{error}</span>
        </span>
      )
    }
  }

  renderInput = ({ input, label, type, meta, placeholder }) => {
    return (
      <div className="tracker_formGroup full_width">
        <label htmlFor={label}>{label}:</label>
        <input {...input} type={type} placeholder={placeholder}/>
        {this.renderError(meta)}
      </div>
    )
  };
  renderInputOwner = ({ input, label, type, meta, placeholder }) => {
    return (
      <div className="tracker_formGroup full_width">
        <label htmlFor={label}>{label}:</label>
        <input {...input} type={type} placeholder={placeholder}/>
        {this.renderError(meta)}
      </div>
    )
  };
  renderRadioAuthentication = ({ input, label, meta }) => {
    const defaultValue = this.props.initialValues ? this.props.initialValues.sarVisible: true
    return (
      <div className="tracker_formGroup full_width">
        <label htmlFor={label}>{label}:</label>
        <RadioGroup
          defaultValue={defaultValue} {...input} style={{ display: 'flex' }}>
          <span className="custom-radio__yes">
            <Radio value={true}>Yes</Radio>
          </span>
          <span className="custom-radio__no">
            <Radio value={false}>No</Radio>
          </span>
        </RadioGroup>
        {this.renderError(meta)}
      </div>
    )
  };
  renderActiveSelect = ({ input, label, meta }) => {
    const defaultValue = this.props.initialValues ? this.props.initialValues.isActive: 1
    return (
      <div className="tracker_formGroup full_width" id={`${ input.name }-container`}>
        <label htmlFor={label}>{label}:</label>
        <Select defaultValue={defaultValue} {...input} getPopupContainer={() => document.querySelector(`#${ input.name }-container`)}>
          <Option value={1}>ACTIVE</Option>
          <Option value={0}>INACTIVE</Option>
        </Select>
        {this.renderError(meta)}
      </div>
    )
  };

  renderPlanSelect = ({ input, label, meta }) => {
    const defaultValue = this.props.initialValues ? this.props.initialValues.planId: null
    const { plans } = { ...this.state }
    return (
      <div className="tracker_formGroup full_width" id={`${ input.name }-container`}>
        <label htmlFor={label}>{label}:</label>
        <Select defaultValue={defaultValue} {...input} getPopupContainer={() => document.querySelector(`#${ input.name }-container`)}>
          { plans && plans.map((plan, index) => <Option value={plan.planId} key={index}>{plan.name}</Option>) }
        </Select>
        {this.renderError(meta)}
      </div>
    )
  };

  renderApplicationsSelect = ({ input, label, meta }) => {

    let defaultValue = ''
    if (this.props) {
      defaultValue = this.props.initialValues ? this.props.initialValues.applicationId : 'SMART_BOX'
    }
    const { applications } = { ...this.state }
    return (
      <div className="tracker_formGroup full_width" id={`${ input.name }-container`}>
        { this.props &&
        <React.Fragment>
          <label htmlFor={ label }>{ label }:</label>
          <Select defaultValue={ defaultValue } onSelect={ (e) => this.updateValues(e) } getPopupContainer={() => document.querySelector(`#${ input.name }-container`)}>
            { applications && applications.map((applicationItem, index) => <Option
              value={ applicationItem.applicationId } key={ index }>{ applicationItem.name }</Option>) }
          </Select>
          {this.renderError(meta)}
        </React.Fragment>
        }
      </div>

    )
  };

  renderRadio = ({ input, label, meta }) => {
    const defaultValue = this.props.initialValues ? this.props.initialValues.sarVisible: true
    return (
      <div className="tracker_formGroup full_width">
        <label htmlFor={label}>{label}:</label>
        <RadioGroup
          defaultValue={defaultValue} {...input} style={{ display: 'flex' }}>
          <span className="custom-radio__yes">
            <Radio value={true}>Yes</Radio>
          </span>
          <span className="custom-radio__no">
            <Radio value={false}>No</Radio>
          </span>
        </RadioGroup>
        {this.renderError(meta)}
      </div>
    )
  };
  renderSelect = ({ input, label, options, key }) => {
    const defaultValue = this.props.initialValues ? this.props.initialValues.isActive: null
    return (
      <div className="tracker_formGroup full_width" key={key}>
        <label htmlFor={label}>{label}:</label>
        <Select defaultValue={defaultValue} {...input}>
          {options && options.map((item) => (
            <Select.Option key={item.id} value={item.permission}>
              {item.permission}
            </Select.Option>
          ))}
        </Select>
      </div>
    )
  };
  renderRadioOwner = ({ input, label, meta }) => {
    const defaultValue = true
    return (
      <div className="tracker_formGroup full_width">
        <label htmlFor={label}>{label}:</label>
        <RadioGroup
          defaultValue={defaultValue} {...input} style={{ display: 'flex' }}>
          <span className="custom-radio__yes">
            <Radio value={true}>Yes</Radio>
          </span>
          <span className="custom-radio__no">
            <Radio value={false}>No</Radio>
          </span>
        </RadioGroup>
        {this.renderError(meta)}
      </div>
    )
  };
  updateValues = (e) => {
    this.setState({ homeApplication: e })
  }

  render() {
    const { loading, disabledCreateButton } = this.state
    const { plans } = this.props
    return (
      <form className="tracker-form" onSubmit={this.props.handleSubmit(this.handleSubmit)}>
        <header className="tracker-header">
          <div className="tracker-header__top">
            <h1 className="tracker-title">Invite tenant</h1>
          </div>
          <div className="tracker-header__top-business">
            <h2>Business</h2>
          </div>
        </header>
        <div className="tracker-content">
          <Field type="text" name="name" label="Company Name" component={ this.renderInput }/>
          <Field type="text" name="domain" label="Client Domain" component={ this.renderInput }/>
          <Field type="text" name="smartBoxVisible" label="Smart Box Visible" component={ this.renderRadio }/>
          <Field type="text" name="sarVisible" label="Sar Tracker Visible" component={ this.renderRadio }/>
          <Field type="text" name="assetRegisterVisible" label="Asset Register Visible"
            component={ this.renderRadio }/>
          <Field type="text" name="docLockerVisible" label="Document Locker Visible" component={ this.renderRadio }/>
          <Field type="text" name="dpiaVisible" label="Assessments Visible" component={ this.renderRadio }/>
          <Field type="text" name="isActive" label="Client Status" component={ this.renderActiveSelect }/>
          <Field type="text" name="applicationId" label="Home Application"
            component={ this.renderApplicationsSelect }/>
          { plans && plans.length > 0 &&
            <Field type="text" name="planId" label="Subscription Plan" component={ this.renderPlanSelect }/>
          }
          <Field type="text" name="additionalEnvironments" label="Allow additional environments"
            component={ this.renderRadio }/>
          <Field type="text" name="multi_factor_authentication" label="Global Multi Factor Authentication"
            component={ this.renderRadioAuthentication }/>
          <div className="content-form-owner">
            <h2>Owner</h2>
          </div>
          <Field type="text" name="blocked" label="Is blocked" component={ this.renderRadioOwner }/>
          <Field type="text" name="forename" label="Forename" component={this.renderInputOwner}/>
          <Field type="text" name="surname" label="Surname" component={this.renderInputOwner}/>
          <Field type="text" name="email" label="Email" component={this.renderInputOwner}/>
          <Field type="text" name="phone" label="Phone" component={this.renderInputOwner}/>
          <Field type="text" name="ospreyRole" label="Osprey role" options={this.state.ospreyRoles} key={1} component={this.renderSelect}/>
          <Field type="text" name="dpiaRole" label="Dpia role" options={this.state.dpiaRoles} key={2} component={this.renderSelect}/>
          <Field type="text" name="multitenancyRole" label="Multitenance role" options={this.state.multitenancyRoles} key={3} component={this.renderSelect}/>
          <Field type="text" name="emailVerified" label="Is email verified" component={this.renderRadioOwner}/>
          <Field type="text" name="verifyPassword" label="Is password verified" component={this.renderRadioOwner}/>
          <Field type="text" name="allowTenants" label="Allow additional Tenants" component={this.renderRadioOwner}/>
        </div>
        <div className="tracker-footer tracker-footer-invite-tenant">
          {
            loading ? <Spin className="float-right" spinning={loading}
              indicator={<div className="checkout-loading"><Loading/></div>}/> :
              <button type="submit" className="btn-blue-light btn-invite float-right" disabled={disabledCreateButton}>
                Invite
              </button>
          }
        </div>
      </form>
    )
  }
}
const validate = (values) => {
  const errors = {}
  if (!values.name) {
    errors.name = 'This field is required'
  }
  if (!Number.isInteger(values.isActive)) {
    errors.isActive = 'This field is required'
  }
  if (!values.domain) {
    errors.domain = 'This field is required'
  } else if (!/^[A-Z0-9.-]+\.[A-Z]{2,14}$/i.test(values.domain)) {
    errors.domain = 'This must be a valid domain like "gravicus.com"'
  }
  if (typeof values.sarVisible !== 'boolean') {
    errors.sarVisible = 'This field is required'
  }
  if (typeof values.assetRegisterVisible !== 'boolean') {
    errors.assetRegisterVisible = 'This field is required'
  }
  if (typeof values.docLockerVisible !== 'boolean') {
    errors.docLockerVisible = 'This field is required'
  }
  if (typeof values.dpiaVisible !== 'boolean') {
    errors.dpiaVisible = 'This field is required'
  }
  if (typeof values.smartBoxVisible !== 'boolean') {
    errors.smartBoxVisible = 'This field is required'
  }
  if (typeof values.additionalEnvironments !== 'boolean') {
    errors.additionalEnvironments = 'This field is required'
  }
  if (!values.planId) {
    errors.planId = 'This field is required'
  }
  return errors
}
AddNewInviteTenant.propTypes = {
  action: PropTypes.string.isRequired,
  plans: PropTypes.array,
  applications: PropTypes.array,
  setNewClientData: PropTypes.func,
}

const stateMap = (state) => ({
  client: state.subscriptionReducer.subscription.subscription.client
})

AddNewInviteTenant = reduxForm({
  form: 'addNewInviteTenant',
  validate,
  onSubmit: submit,
  enableReinitialize: true
})(AddNewInviteTenant)

AddNewInviteTenant = connect(
  (state) => ({
    initialValues: state.admin.addNewInviteTenant
  }), { fetchClientData, initializeFormInvite }
)(AddNewInviteTenant)

export default connect(stateMap)(AddNewInviteTenant)