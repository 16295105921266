import React from "react";
import {connect} from "react-redux";
import './ComponentsForm.scss';
import { Input, Checkbox} from "antd";
const { TextArea } = Input
class LongTextForm extends React.Component {
    state = {
        longTextForm: {
            textLong: '',
            queryForSearch: false,
            mandatoryCheck: false,
            options: null,
            header: 'Long Text',
            sectionVariable: ''
        },
        hoverDelete: false,
        editHeader: false,
        hoverEditBtn:false,
    }
    componentDidMount() {
        const { component } = this.props
        this.setState({
            longTextForm: { ...this.state.longTextForm,
                queryForSearch: component.queryForSearch === undefined ? false : component.queryForSearch,
                mandatoryCheck: component.mandatory === undefined ? false : component.mandatory,
                options: component.options === undefined ? null : component.options,
                header: component.header === undefined ? 'Long Text' : component.header,
                sectionVariable: component.sectionVariable === undefined ? '' : component.sectionVariable,
            }
        })
        //this.props.onChangeForm(this.state.longTextForm, this.props.component)
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props && this.props.checkStartSaved) {
            this.props.onChangeForm(this.state.longTextForm, this.props.component)
        }
    }
    onChangeLong = (e, key) => {
        switch (key) {
            case 'long-text':
                this.setState({
                    longTextForm: { ...this.state.longTextForm, textLong: e.target.value },
                })
                break
            default:
                break
        }
    }
    onChangeCheckbox = (e, key) => {
        switch (key) {
            case 'query':
                this.setState({
                    longTextForm: { ...this.state.longTextForm, queryForSearch: e.target.checked },
                }, () => {
                    this.props.onChangeForm(this.state.longTextForm, this.props.component)
                })
                break
            case 'mandatory':
                this.setState({
                    longTextForm: { ...this.state.longTextForm, mandatoryCheck: e.target.checked },
                }, () => {
                    this.props.onChangeForm(this.state.longTextForm, this.props.component)
                })
                break
            default:
                break
        }
    }
    onHoverActionComponent = () => {
        this.setState({
            hoverDelete: true
        })
    }
    onLeaveActionComponent = () => {
        this.setState({
            hoverDelete: false
        })
    }
    onHoverEditBtn = () => {
        this.setState({
            hoverEditBtn: true
        })
    }
    onLeaveEditBtn = () => {
        this.setState({
            hoverEditBtn: false
        })
    }
    onClickEditHeader = (e, status) => {
        if (status === 'header') {
            this.setState({
                editHeader: true
            })
        }
    }
    addTitleHeader = (e, status) => {
        if (status === 'header') {
            this.setState({
                longTextForm: { ...this.state.longTextForm, header: e.target.value },
                //editHeader: false
            }, () => {
                this.props.onChangeForm(this.state.longTextForm, this.props.component)
            })
        }
    }
    onChangeFormVariable = (e) => {
        this.setState({
            longTextForm: { ...this.state.longTextForm, sectionVariable: e.target.value },
        }, () => {
            this.props.onChangeForm(this.state.longTextForm, this.props.component)
        })
    }
    confirmEditHeader = () => {
        this.setState({
            editHeader:false,
        })
    }
    render() {
        const { longTextForm, hoverDelete, editHeader, hoverEditBtn } = this.state
        const { component, defaultTemplate } = this.props
        return (
            <div className={ this.props.snapshot.isDragging ? "component-form dragging " : "component-form"}>
                <div className="component-delete">
                    <button onClick={ (e) => this.props.showConfirmDeleteFormElement(e, component) }
                          className={hoverDelete ? 'icon-v19-delete-active' : "icon-v19-delete" }
                            disabled={defaultTemplate}
                          onMouseEnter={ (e) => this.onHoverActionComponent(e)}
                          onMouseLeave={() => this.onLeaveActionComponent()}></button>
                </div>
                <div className="component-content">
                    <div className="content-input-form-variables">
                        <span className="header-checkbox">
                           {/*<Checkbox onChange={(e) => this.onChangeCheckbox(e, 'query')}*/}
                            {/*          checked={ longTextForm.queryForSearch }>Query for search</Checkbox>*/}
                            <Checkbox onChange={(e) => this.onChangeCheckbox(e, 'mandatory')}
                                      disabled={defaultTemplate}
                                      checked={ longTextForm.mandatoryCheck }>Mandatory</Checkbox>
                        </span>
                        {/*<span className="content-variables">*/}
                        {/*     <span className="variables-diaz">#</span>*/}
                        {/*<Input*/}
                        {/*    className="input-form-variables"*/}
                        {/*    placeholder="Form variable"*/}
                        {/*    value={longTextForm.sectionVariable}*/}
                        {/*    onChange={(e) => this.onChangeFormVariable(e)}*/}
                        {/*/>*/}
                        {/*</span>*/}
                    </div>
                    <div className="header-component header-component-update">
                        <span className="header-content">
                              { editHeader ?
                                  <div>
                                      <Input defaultValue={longTextForm.header} className="input-other-option"
                                             onChange={ (e) => this.addTitleHeader(e, 'header')}
                                             onPressEnter={() => this.confirmEditHeader()}
                                      />
                                      <button className={ hoverEditBtn ? "icon-v21-edit-active" : "icon-v21-edit"}
                                            onClick={(e) => this.confirmEditHeader()}
                                              disabled={defaultTemplate}
                                            onMouseEnter={() => this.onHoverEditBtn()}
                                            onMouseLeave={() => this.onLeaveEditBtn()}
                                      ></button>
                                  </div>
                                 :
                                  <div>
                                      <span className="header-title header-title-update">{longTextForm.header}</span>
                                      <button className={ hoverEditBtn ? "icon-v21-edit-active" : "icon-v21-edit"}
                                            onClick={(e) => this.onClickEditHeader(e, 'header')}
                                              disabled={defaultTemplate}
                                            onMouseEnter={() => this.onHoverEditBtn()}
                                            onMouseLeave={() => this.onLeaveEditBtn()}
                                      ></button>
                                  </div>
                              }
                          </span>
                    </div>
                    <span className="paragraph-content">
                            <TextArea className="paragraph-form-name"
                                      onChange={(e) => this.onChangeLong(e, 'long-text')}
                                      rows={4}
                                      disabled={true}
                                      value={ longTextForm.textLong }/>
                        </span>
                </div>
                <div className="component-move">
                    <span onMouseEnter={() => this.props.onStateProvidedDraggable(component)} className="icon-v211-grid-view-1"></span>
                </div>
            </div>
        )
    }
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(LongTextForm)