import React from 'react'
import './ManualTerms.scss'
import {Checkbox, Popover, Input, Button, Modal} from 'antd'
import CustomNotificationManager from '../../../../Common/CustomNotificationManager'
import {
  getAllBusinessList,
  deleteDirectories,
  selectBusinessTerms,
  selectManualTerms,
  getSelectedBusinessItems,
  editBusinessList,
  createBusinessList,
  saveManualTermsColor,

} from '../../../../../api/SmartBox'
import { setSmartBoxFetchTreeData } from '../../../../../actions/smartBoxActions'
import { connect } from 'react-redux'
import cookies from 'react-cookies'
import {CompactPicker} from "react-color";
import imgDownload from "../../../../../assets/download.svg";
import cookie from "react-cookies";
import withRouter from '../../../../../private-routers/withRouter'

class BusinessTermList extends React.Component {
  state = {
    manualTerms: [],
    businessData: [],
    isManualTermsListVisible: false,
    isManualTermsBuilderVisible: false,
    manualTerm: '',
    manualTermsSelected: [],
    expendedDictionaries: [],
    expendedDictionariesItems: [],
    checkedBusiness: [],
    expendedTerms: [],
    manualTermInputVisibility: false,
    inputDisabled: false,
    color: '#fff',
    colorPickerVisible: {},
    colorPickerModalVisible: false,
    colorPickerManualVisible: false,
    colorPickerLeft: 0,
    colorPickerTop: 0,
    lastSelectedColor: 'rgba(107, 91, 149, 1)',
    manualColor: 'rgba(77, 100, 113, 1)',
    selectedBusinessItem: 0,
    modalBusinessVisible: false,
    newName: '',
    newDescription: '',
    newCategory: '',
    newColor: 'rgba(77, 100, 113, 1)',
    showEdit: 0,
    manualNewName: '',
    businessSaveStep: 0,
    businessTreeEnable: true,
    businessSelectAllChecked: false,
    manualSaveStep: 0,
    manualTreeEnable: true,
    manualSelectAllChecked: false,
    activeEdit:false,
  }

  componentDidMount() {
    if (this.props.folder.id) {
      this.getBusinessData()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.folder.id !== this.props.folder.id) {
      const openLoadDictionary = cookie.load('sendOpenDictionary')
      if (openLoadDictionary === 'openIsTrue') {
        this.props.navigate('/smart-box/business-term-lists')
      }
      cookie.remove('sendOpenDictionary', { path: '/' })
    }
    if (prevProps.folder.id !== this.props.folder.id && this.props.folder.id) {
      this.getBusinessData()
    }
  }

  getBusinessData = () => {
    const businessData= []
    getAllBusinessList().then((response) => {
      if (response.status === 200) {
        response.data.map( (item) => {
          const temp = {}
          temp.id = item.id
          temp.name = item.listName
          temp.description = item.description
          temp.displayTerm = item.displayTerm
          temp.colour = item.colour ? this.replaceAlpha(item.colour) : 'rgba(77, 100, 113, 1)'
          if (item.terms && item.terms.length) {
            temp.childrens= []
            item.terms.forEach( (value) => {
              temp.childrens.push({id: value.id, name: value.term})
            })
          }
          businessData.push(temp)
          return true
        })
      }
      this.setState({
        businessData
      }, () => {
        this.getSelectedBusinessItems()
      })
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  getSelectedBusinessItems = (e) => {
    let { businessSaveStep, businessTreeEnable, businessData, businessSelectAllChecked } = this.state
    const { folder } = this.props
    const checkedBusiness = []
    getSelectedBusinessItems(folder.id).then((response) => {
      if (response.status === 200) {
        if (response.data && response.data.length) {
          response.data.forEach((item) => {
            checkedBusiness.push(Number(item))
          })
          if (checkedBusiness.length) {
            businessTreeEnable = false
            businessSaveStep = 2
          }
          if (businessData && businessData.length > 0 && checkedBusiness.length === businessData.length) {
            businessSelectAllChecked = true
          } else if (businessData && businessData.length > 0 && checkedBusiness.length < businessData.length) {
            businessSelectAllChecked = false
          }
          this.setState({
            checkedBusiness: checkedBusiness,
            businessSaveStep,
            businessTreeEnable,
            businessSelectAllChecked
          })
        }
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  replaceAlpha = (color) => {
    const temp = color.split(',')
    if (temp.length === 4) {
      temp[3] = " 1)"
      color = temp.join()
    }
    return color
  }

  onChange = (e) => this.setState({ manualTerm: e.target.value })

  removeDirectories = (e) => {
    const { checkedBusiness, businessData } = this.state
    const dictionaryIds = []
    const subcategoryIds = []
    if (checkedBusiness.length) {
      checkedBusiness.map( (item) => {
        businessData.map( (value) => {
          if (Number(value.id) === Number(item) && !dictionaryIds.includes(item)) {
            dictionaryIds.push(value.id)
          }
          if (value.childrens && value.childrens.length) {
            value.childrens.map( (val) => {
              if ( Number(val.id) === Number(item) && !subcategoryIds.includes(item)) {
                subcategoryIds.push(val.id)
              }
              return true
            })
          }
          return true
        })
        return true
      })
      if (dictionaryIds.length) {
        dictionaryIds.map((item, key) => {
          if (subcategoryIds.length) {
            if (subcategoryIds.includes(item)) {
              subcategoryIds.splice(key, 1)
            }
          }
          return true
        })
      }
      deleteDirectories({ 'businessTermsListIds': dictionaryIds, 'businessTermsIds': subcategoryIds }).then((response) => {
        if (response.status === 200) {
          this.getBusinessData()
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    }
  }

  getNrOfItems = (data) => {
    let response = 0
    if (data && data.length) {
      response += data.length
      data.map( (item) => {
        if (item.subcategories) {
          response += Object.keys(item.subcategories).length
        }
        return true
      })
    }
    return response
  }

  onBusinessCheckboxChange = (values) => {
    let { businessSaveStep, businessData, businessSelectAllChecked } = this.state
    if (businessData && businessData.length > 0 && values.length === businessData.length) {
      businessSelectAllChecked = true
    } else if (businessData && businessData.length > 0 && values.length < businessData.length) {
      businessSelectAllChecked = false
    }
    if (values.length && !businessSaveStep) {
      businessSaveStep = 1
    } else if (!values.length) {
      businessSaveStep = 0
    }
    // set select all ckecked or unchecked
    if (values.length === businessData.length) {
      //businessSelectAllChecked = true
    } else {
      //businessSelectAllChecked = false
    }
    this.props.onGetStatusRunAnalytics(false)
    this.setState({
      checkedBusiness: values,
      businessSaveStep,
      businessSelectAllChecked
    }, () => this.scanNow('', 'business'))
  }

  selectAll = (e, type) => {
    const { manualTerms, businessData } = this.state
    let { businessSaveStep, manualSaveStep } = this.state
    if (type === 'terms') {
      let { manualTermsSelected } = this.state
      if (manualTerms && manualTerms.length) {
        if (!e.target.checked) {
          manualTermsSelected = []
        } else if (manualTermsSelected.length !== manualTerms.length && e.target.checked) {
          manualTermsSelected = []
          manualTerms.map((value) => {
            manualTermsSelected.push(value.id)
            return true
          })
        }
        manualSaveStep = 1
      }
      this.setState({ manualTermsSelected, manualSaveStep, manualSelectAllChecked: e.target.checked })
    }
    if (type === 'business') {
      let { checkedBusiness } = this.state
      if (businessData && businessData.length) {
        if (!e.target.checked) {
          checkedBusiness = []
        } else {
          checkedBusiness = []
          businessData.map( (item) => {
            checkedBusiness.push(item.id)
            return true
          })
        }
        businessSaveStep = 1
      }
      this.setState({ checkedBusiness, businessSaveStep, businessSelectAllChecked: e.target.checked }, () => this.scanNow('', 'business'))
    }
    this.props.onGetStatusRunAnalytics(false)
  }

  updateExpendedDictionaries = (key) => {
    this.setState({ expendedDictionaries: key })
  }

  onSelect = (keys, event) => {
    let { expendedDictionariesItems } = this.state
    if (keys.length && !expendedDictionariesItems.includes(keys[0])) {
      expendedDictionariesItems.push(keys[0])
    } else {
      expendedDictionariesItems = expendedDictionariesItems.filter((eKey) => eKey !== keys[0])
    }
    this.setState({ expendedDictionariesItems })
  }

  updateValue(e, field) {
    this.setState({
      [field]: e.target.value
    })
  }

  creatBusinessList = (e) => {
    const { businessData, newName, newDescription, newCategory, newColor } = this.state
    const data = {name: newName, description: newDescription, term: newCategory, color: newColor}
    createBusinessList(data).then((response) => {
      if (response.status === 200) {
        const newList = {}
        newList.id = response.data.id
        newList.name = response.data.listName
        newList.description = response.data.description
        newList.displayTerm = response.data.displayTerm
        newList.colour = this.replaceAlpha(response.data.colour)
        businessData.push(newList)
        this.setState({ businessData, modalBusinessVisible: false })
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  scanNow = (e, type) => {
    const { checkedBusiness, manualTermsSelected, lastSelectedColor, businessSaveStep, manualSaveStep } = this.state
    const { folder } = this.props
    if (type === 'business') {
      if (businessSaveStep === 1 || businessSaveStep === 0) {
        const data = {
          list: checkedBusiness,
          folderPath: folder.path,
          userId: this.props.user.id,
          color: lastSelectedColor
        }
        selectBusinessTerms(data).then( (response) => {
          //CustomNotificationManager.success('Selected business terms were sent.', 'Success')
          if (checkedBusiness.length) {
            this.setState({ businessSaveStep: 2, businessTreeEnable: false })
          } else {
            this.setState({ businessSaveStep: 0, businessTreeEnable: true })
          }

        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
        })
      } else if (businessSaveStep === 2) {
        const data = {
          list: checkedBusiness,
          folderPath: folder.path,
          userId: this.props.user.id,
          color: lastSelectedColor
        }
        selectBusinessTerms(data).then( (response) => {
          //CustomNotificationManager.success('Selected business terms were sent.', 'Success')
          if (checkedBusiness.length) {
            this.setState({ businessSaveStep: 2, businessTreeEnable: false })
          } else {
            this.setState({ businessSaveStep: 0, businessTreeEnable: true })
          }

        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
        })
        this.setState({ businessSaveStep: 1, businessTreeEnable: true })
      }

    } else if (type === 'manual') {
      if (manualSaveStep === 1) {
        const list = []
        if (manualTermsSelected && manualTermsSelected.length) {
          manualTermsSelected.map((item) => {
            list.push(item.id)
            return true
          })
        }
        const data = {
          list: manualTermsSelected,
          folderPath: folder.path,
          userId: this.props.user.id
        }
        selectManualTerms(data).then((response) => {
          CustomNotificationManager.success('Selected manual terms were sent.', 'Success')
          if (manualTermsSelected.length) {
            this.setState({ manualSaveStep: 2, manualTreeEnable: false })
          } else {
            this.setState({ manualSaveStep: 0, manualTreeEnable: true })
          }
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
        })
      } else if (manualSaveStep === 2) {
        this.setState({ manualSaveStep: 1, manualTreeEnable: true })
      }
    }
  }

  convertRgbaToString = ({ r, g, b, a }) => {
    return `rgba(${ r }, ${ g }, ${ b }, ${ a })`
  }

  toggleBusinessModal = (open) => {
    this.setState({ modalBusinessVisible: !this.state.modalBusinessVisible })
    if (open) {
      this.setState({ modalBusinessVisible: open })
    } else {
      this.setState({colorPickerModalVisible: false })
    }
  }

  toggleColorPickerVisible = (e, id, open) => {
    const { businessData }= this.state
    let { colorPickerModalVisible, colorPickerManualVisible }= this.state
    if (id === 0) {
      colorPickerModalVisible= !colorPickerModalVisible
      if (open) {
        colorPickerModalVisible= open
      }
    }
    if (id === -1) {
      colorPickerManualVisible= !colorPickerManualVisible
      if (open) {
        colorPickerManualVisible= open
      }
    }
    if (e) {
      e.stopPropagation()
    }
    const temp= {}
    if (businessData.length) {
      businessData.map((item) => {
        if (item.id !== id) {
          temp[item.id]= false
        }
        return true
      })
    }
    temp[id] =  !this.state.colorPickerVisible[id]
    if (open) {
      temp[id] = open
    }
    this.setState({
      selectedBusinessItem: id,
      colorPickerVisible: temp,
      colorPickerLeft: e.currentTarget.getBoundingClientRect().left - 380,
      colorPickerTop: e.currentTarget.getBoundingClientRect().top + 0,
      colorPickerModalVisible,
      colorPickerManualVisible
    })

  }

  closeAndSaveColorPicker = (color) => {
    const { businessData, selectedBusinessItem }= this.state
    let { newColor, manualColor, colorPickerModalVisible, colorPickerManualVisible }= this.state
    const { folder } = this.props
    const temp= {}
    if (businessData.length) {
      businessData.map((item) => {
        temp[item.id]= false
        if (item.id === selectedBusinessItem) {
          item.colour = this.convertRgbaToString(color.rgb)
          const data = {id: selectedBusinessItem, name: item.name, description: item.description, term: item.displayTerm, color: item.colour, client: this.props.client}
          editBusinessList(data)
        }
        return true
      })
    }
    if (selectedBusinessItem === 0) {
      newColor = this.convertRgbaToString(color.rgb)
      colorPickerModalVisible = false
    }
    if (selectedBusinessItem === -1) {
      manualColor = this.convertRgbaToString(color.rgb)
      colorPickerManualVisible = false
      saveManualTermsColor({color: manualColor, folderPath: folder.path }).then(() => {

      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    }
    this.props.onGetStatusRunAnalytics(false)
    this.setState({
      colorPickerVisible: temp,
      businessData,
      newColor,
      manualColor,
      colorPickerModalVisible,
      colorPickerManualVisible
    })
  }

  handleClickBusinessItem = (businessItemId) => {
    const { businessData } = this.state
    let dictId = 0
    businessData && businessData.length > 0 && businessData.forEach((item) => {
      if (item.id === businessItemId) {
        dictId = item.id
      }
    })
    cookies.save('business-term', businessItemId, { path: '/smart-box' })
    window.open(`/smart-box/business-term-list/${dictId ? dictId : businessItemId}`)
    //this.props.navigate('/smart-box/business-term-lists/settings')
  }
  openInDictionary = (e) => {
    e.preventDefault()
    window.open('/smart-box/business-term-lists/settings')
    cookie.save('sendOpenDictionary', 'openIsTrue', { path: '/', maxAge: 60*60 })
  }
  onMouseEnter = (e) => {
    this.setState({
      activeEdit:true
    })
  }
  onMouseLeave = () => {
    this.setState({
      activeEdit:false
    })
  }
  render() {
    const {businessData, checkedBusiness, color, colorPickerVisible, modalBusinessVisible, newName, newCategory, newColor,
      colorPickerModalVisible, businessSelectAllChecked,activeEdit } = this.state
    let { disabled } = this.props
    if(typeof disabled === 'undefined') {
      disabled = false
    }
    //const { Panel } = Collapse

    // business save button
    // let saveBusinessBtnTitle = 'Confirm Selection'
    // let saveBusinessBtnClass = 'btn-grey'
    // if (businessSaveStep === 1) {
    //   saveBusinessBtnClass = 'btn-green-fill'
    // } else if (businessSaveStep === 2) {
    //   saveBusinessBtnTitle = 'Update Selection'
    //   saveBusinessBtnClass = 'btn-green'
    // }
    const renderColorPicker = (
      <React.Fragment>
        <div className="business-term-color-picker">
          <CompactPicker
            className="color-picker-a"
            colors={['rgba(107, 91, 149, 1)', 'rgba(254, 178, 54, 1)', 'rgba(214, 65, 97, 1)',
              'rgba(255, 123, 37, 1)', 'rgba(75, 195, 193, 1)', 'rgba(194, 194, 71, 1)', 'rgba(181, 231, 160, 1)',
              'rgba(191, 108, 64, 1)', 'rgba(62, 68, 68, 1)', 'rgba(130, 183, 75, 1)', 'rgba(64, 93, 39, 1)',
              'rgba(193, 148, 106, 1)', 'rgba(60, 136, 180, 1)', 'rgba(200, 100, 91, 1)', 'rgba(238, 162, 154, 1)',
              'rgba(201, 76, 76, 1)', 'rgba(124, 108, 6, 1)', 'rgba(15, 195, 71, 1)', 'rgba(231, 82, 114, 1)',
              'rgba(238, 140, 54, 1)', 'rgba(92, 212, 209, 1)', 'rgba(211, 211, 88, 1)', 'rgba(198, 248, 177, 1)',
              'rgba(192, 125, 81, 1)', 'rgba(77, 100, 113, 1)', 'rgba(237, 226, 220, 1)', 'rgba(132, 206, 235, 1)']}
            disableAlpha={true}
            color={color}
            onChange={(color) => this.closeAndSaveColorPicker(color)}
          />
        </div>
      </React.Fragment>
    )

    return (
      <div className="business-terms-page update-business-terms-page">
        <div className="business-terms-page__dictionaries business-terms-page__business-list">
          {/*<Collapse
            onChange={ this.updateExpendedDictionaries }
            className="dictionaries-collapse"
            defaultActiveKey= { ['dictionaries'] }
            bordered={ false }
            expandIconPosition="right"
            expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} /> }
          >
            <Panel header="My Business Term Lists" key="dictionaries">*/}
              { businessData && businessData.length > 0 &&
              <div className={ businessData && businessData.length > 0 && businessData.length === checkedBusiness.length ? 'selected-all-list selected-all-list-active' :
                  checkedBusiness && checkedBusiness.length > 0 ? 'selected-all-list selected-children-list' : 'selected-all-list' }>
                <Checkbox checked={ businessSelectAllChecked } onChange={(e) => this.selectAll(e, 'business')}>Select all</Checkbox>
                {/*<button className="icon-delete-b" onClick={(e) => this.removeDirectories(e)}/>*/}
                <span onClick={(e) => this.openInDictionary(e)} className={activeEdit ? "icon-v17-edit-active" :"icon-v17-edit"}
                       onMouseEnter={(e) => this.onMouseEnter(e)}
                        onMouseLeave={(e) => this.onMouseLeave(e)}></span>
              </div>
              }
              <div className="tree">
                {businessData && businessData.length > 0 &&
                <div className="list overflow">
                  <div className="business-terms-page__list-container__content">
                    { (!businessData || businessData.length === 0) &&
                    <p className="text-center">No data available</p>
                    }
                    { businessData && businessData.length > 0 &&
                    <Checkbox.Group  onChange={ this.onBusinessCheckboxChange } value={ checkedBusiness }>
                      { businessData.map((item, key) => {
                        return (
                          <div  key={`div-${item.id}`} className="line-item">
                            <div className="content-name-dictionary">
                            <Checkbox disabled={ disabled } className="business-terms-page__list-container__content__term radio-style" key={item.id} value={item.id}>
                              {/*<span className="business-terms-item" onClick={ () => disabled ? undefined : this.handleClickBusinessItem(item.id) }>{item.name}</span>*/}
                            </Checkbox>
                              <span className="business-terms-item" onClick={ () => disabled ? undefined : this.handleClickBusinessItem(item.id) }>{item.name}</span>
                            </div>
                            <Popover content={renderColorPicker} trigger="click" visible={ colorPickerVisible[item.id] }>
                              <div className={ disabled ? 'icon-change-color disabled' : 'icon-change-color' } key={`i-${item.id}`} style={{backgroundColor: item.colour}} onClick={(e) => disabled ? undefined : this.toggleColorPickerVisible(e, item.id)}/>
                            </Popover>
                          </div>
                        )
                      })
                      }
                    </Checkbox.Group>
                    }
                  </div>
                </div>
                }
                { (!businessData || businessData.length === 0) &&
                <p className="text-center">No data available</p>
                }
                {/*<div className="business-terms-page__builder-container__content__buttons-section" onClick={ () => this.toggleBusinessModal(true)  }>*/}
                {/*  <button className="icon-add-b"/>*/}
                {/*  <span className="text-add">Add Business Term List</span>*/}
                {/*</div>*/}
              </div>
              <div className="business-term-scan">
                {/*<button className={`button-green ${ saveBusinessBtnClass }`} disabled={ !businessSaveStep || disabled } onClick={ (e) => this.scanNow(e, 'business') }>{ saveBusinessBtnTitle }</button>*/}
              </div>
          {/*</Panel>
          </Collapse>*/}
        </div>

        <Modal className="import-terms-modal" title={ false } visible={ modalBusinessVisible } onCancel={ () => this.toggleBusinessModal(false) } closable={ true } footer={ null } >
          <div className="import-terms-section">
            <img src={ imgDownload } alt="Add new business term" />
            <h1 className="import-terms-title">New Business Term List</h1>
            <br/>
            <form className="import-terms-form">
              <React.Fragment>
                <label>List name*</label>
                <Input onChange={(e) => this.updateValue(e, 'newName')} placeholder="Name" />
                <Input.TextArea rows="3" onChange={(e) => this.updateValue(e, 'newDescription')} placeholder="Add a description of your list (optional)" />
                <label>Category displayed term*</label>
                <Input onChange={(e) => this.updateValue(e, 'newCategory')} placeholder="Category displayed term" />
                <div className='line'>
                  <label className="float-left">Color</label>
                  <Popover content={renderColorPicker} trigger="click" visible={ colorPickerModalVisible }>
                    <div className="list-selected-color" style={{ backgroundColor: newColor }} onClick={(e) => this.toggleColorPickerVisible(e, 0)}></div>
                  </Popover>
                </div>
              </React.Fragment>
              <div className="buttons-terms">
                <Button className="transp-blue" onClick={ () => this.toggleBusinessModal(false)} >CANCEL</Button>
                <Button className="blue" type="submit" disabled={ (!newName.trim() || !newCategory.trim()) } onClick={ (e) => this.creatBusinessList(e) }>Create</Button>
              </div>
            </form>
          </div>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  client: state.subscriptionReducer.subscription.subscription.client,

})
BusinessTermList= withRouter(BusinessTermList)
export default connect(mapStateToProps, { setSmartBoxFetchTreeData })(BusinessTermList)