import React from 'react'
import { Tabs } from 'antd'
import InitialStepAcceptTemplate from './IntitialTemplates/InitialStepAcceptTemplate'
import InitialStepOptionsTemplate from './IntitialTemplates/InitialStepOptionsTemplate'
import InitialStepEndRequestTemplate from './IntitialTemplates/InitialStepEndRequestTemplate'

import { apiGetAllEmailTemplate } from '../../../../../api/Sar'
import { apiGetAllEmailTemplate as apiGetAllDefaultEmailTemplates } from '../../../../../api/Admin'
import { connect } from 'react-redux'
import AcknowledgeStepAcceptTemplate from './AcknowledgetTemplates/AcknowledgeStepAcceptTemplate'
import AcknowledgeStepRejectTemplate from './AcknowledgetTemplates/AcknowledgeStepRejectTemplate'
import CheckStepAcceptTemplate from './CheckTemplates/CheckStepAcceptTemplate'
import CheckStepRejectTemplate from './CheckTemplates/CheckStepRejectTemplate'
import CheckStepRejectIDTemplate from './CheckTemplates/CheckStepRejectIDTemplate'
import RespondStepAcceptTemplate from './RespondTemplates/RespondStepAcceptTemplate'
import RespondStepRejectTemplate from './RespondTemplates/RespondStepRejectTemplate'
import SearchAndFindStepAcceptTemplate from './SearchAndFindTemplates/SearchAndFindStepAcceptTemplate'
import SearchAndFindStepRejectTemplate from './SearchAndFindTemplates/SearchAndFindStepRejectTemplate'
import ReviewAndRedactAccteptTemplate from './ReviewAndRedactTemplates/ReviewAndRedactAccteptTemplate'
import SendSecurelyStepRejectTemplate from './SendSecurelyTemplates/SendSecurelyStepRejectTemplate'
import SendSecurelyStepAcceptTemplate from './SendSecurelyTemplates/SendSecurelyStepAcceptTemplate'
import CheckStepProofIdReject from './CheckTemplates/CheckStepProofIdReject'
import CompleteStepAcceptTemplate from './CompleteTemplates/CompleteStepAcceptTemplate'
import CompleteStepRejectTemplate from './CompleteTemplates/CompleteStepRejectTemplate'

const TabPane = Tabs.TabPane

class TemplateEmailContent extends React.Component {
  INITIAL_REQUEST = 'INITIAL_REQUEST'
  ACKNOWLEDGE = 'ACKNOWLEDGE'
  CHECK_ID = 'CHECK_ID'
  RESPOND = 'RESPOND'
  SEARCH_FIND_ADD_DOCUMENTS = 'SEARCH_FIND_ADD_DOCUMENTS'
  REVIEW_AND_REDACT = 'REVIEW_AND_REDACT'
  SEND_SECURELY = 'SEND_SECURELY'
  COMPLETE = 'COMPLETE'
  ACCEPT = 'ACCEPT'
  REJECT = 'REJECT'
  OPTIONS = 'OPTIONS'
  CHECK_ID_REJECT = 'CHECK_ID_REJECT'
  PROOF_ID_REJECT = 'PROOF_ID_REJECT'

  constructor(props) {
    super(props)

    this.state = {
      templates: [],
      editMode: false,
      defaultMode: false,
      editModeNew: false
    }
  }

  componentDidMount() {
    if (!this.props.godAdmin && this.props.clientId) {
      apiGetAllEmailTemplate(this.props.sarStep, this.props.clientId).then((res) => this.setState({ templates: res.data }))
    } else {
      apiGetAllDefaultEmailTemplates(this.props.sarStep).then((res) => this.setState({ templates: res.data }))
    }
    this.setState({
      editModeNew: false
    }, () => {
      this.props.isEditMode(this.state.editModeNew)
    })
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.godAdmin) {
      apiGetAllDefaultEmailTemplates(nextProps.sarStep).then((res) => this.setState({ templates: res.data }))
    }
    if (!nextProps.godAdmin && this.props.clientId !== nextProps.clientId) {
      this.setState({
        editModeNew: false
      }, () => {
        this.props.isEditMode(this.state.editModeNew)
      })
      apiGetAllEmailTemplate(this.props.sarStep, nextProps.clientId).then((res) => this.setState({ templates: res.data }))
    }
  }

  isTemplateInEditMode = (editing) => {
    if (editing) {
      this.setState({
        editMode: true
      }, () => {
        this.props.isEditMode(this.state.editMode)
      })
    } else {
      this.setState({
        editMode: false
      }, () => {

        this.forceUpdate()
        this.props.isEditMode(this.state.editMode)
      })
    }
  }

  updateTemplate = (newEmailTemplate) => {
    const newTemplateList = this.state.templates.map((value) => {
      let returnValue = { ...value }

      if (value.type === newEmailTemplate.type) {
        returnValue = newEmailTemplate
      }
      return returnValue
    })

    this.setState({
      templates: newTemplateList,
      editMode: false
    }, () => {
      this.props.isEditMode(this.state.editMode)
    })
  }

  renderEmailTemplate = (template) => {
    switch (template.sarStep) {
      case this.INITIAL_REQUEST:
        switch (template.type) {
          case this.ACCEPT:
            return <InitialStepAcceptTemplate
              emailTemplate={template} isEditMode={this.isTemplateInEditMode}
              updateEmailTemplate={this.updateTemplate}/>
          case this.OPTIONS:
            return <InitialStepOptionsTemplate
              emailTemplate={template} isEditMode={this.isTemplateInEditMode}
              updateEmailTemplate={this.updateTemplate}/>
          case this.REJECT:
            return <InitialStepEndRequestTemplate
              emailTemplate={template} isEditMode={this.isTemplateInEditMode}
              updateEmailTemplate={this.updateTemplate}/>
          default:
            return <div>No Email Templates Found</div>
        }
      case this.ACKNOWLEDGE:
        switch (template.type) {
          case this.ACCEPT:
            return <AcknowledgeStepAcceptTemplate
              emailTemplate={template} isEditMode={this.isTemplateInEditMode}
              updateEmailTemplate={this.updateTemplate}/>
          case this.REJECT:
            return <AcknowledgeStepRejectTemplate
              emailTemplate={template} isEditMode={this.isTemplateInEditMode}
              updateEmailTemplate={this.updateTemplate}/>
          default:
            return <div>No Email Templates Found</div>
        }
      case this.CHECK_ID:
        switch (template.type) {
          case this.ACCEPT:
            return <CheckStepAcceptTemplate
              emailTemplate={template} isEditMode={this.isTemplateInEditMode}
              updateEmailTemplate={this.updateTemplate}/>
          case this.REJECT:
            return <CheckStepRejectTemplate
              emailTemplate={template} isEditMode={this.isTemplateInEditMode}
              updateEmailTemplate={this.updateTemplate}/>
          case this.CHECK_ID_REJECT:
            return <CheckStepRejectIDTemplate
              emailTemplate={template} isEditMode={this.isTemplateInEditMode}
              updateEmailTemplate={this.updateTemplate}/>
          case this.PROOF_ID_REJECT:
            return <CheckStepProofIdReject
              emailTemplate={template} isEditMode={this.isTemplateInEditMode}
              updateEmailTemplate={this.updateTemplate}/>
          default:
            return <div>No Email Templates Found</div>
        }
      case this.RESPOND:
        switch (template.type) {
          case this.ACCEPT:
            return <RespondStepAcceptTemplate
              emailTemplate={template} isEditMode={this.isTemplateInEditMode}
              updateEmailTemplate={this.updateTemplate}/>
          case this.REJECT:
            return <RespondStepRejectTemplate
              emailTemplate={template} isEditMode={this.isTemplateInEditMode}
              updateEmailTemplate={this.updateTemplate}/>
          default:
            return <div>No Email Templates Found</div>
        }
      case this.SEARCH_FIND_ADD_DOCUMENTS:
        switch (template.type) {
          case this.ACCEPT:
            return <SearchAndFindStepAcceptTemplate
              emailTemplate={template} isEditMode={this.isTemplateInEditMode}
              updateEmailTemplate={this.updateTemplate}/>
          case this.REJECT:
            return <SearchAndFindStepRejectTemplate
              emailTemplate={template} isEditMode={this.isTemplateInEditMode}
              updateEmailTemplate={this.updateTemplate}/>
          default:
            return <div>No Email Templates Found</div>
        }
      case this.REVIEW_AND_REDACT:
        switch (template.type) {
          case this.ACCEPT:
            return <ReviewAndRedactAccteptTemplate
              emailTemplate={template} isEditMode={this.isTemplateInEditMode}
              updateEmailTemplate={this.updateTemplate}/>
          default:
            return <div>No Email Templates Found</div>
        }
      case this.SEND_SECURELY:
        switch (template.type) {
          case this.ACCEPT:
            return <SendSecurelyStepAcceptTemplate
              emailTemplate={template} isEditMode={this.isTemplateInEditMode}
              updateEmailTemplate={this.updateTemplate}/>
          case this.REJECT:
            return <SendSecurelyStepRejectTemplate
              emailTemplate={template} isEditMode={this.isTemplateInEditMode}
              updateEmailTemplate={this.updateTemplate}/>
          default:
            return <div>No Email Templates Found</div>
        }
      case this.COMPLETE:
        switch (template.type) {
          case this.ACCEPT:
            return <CompleteStepAcceptTemplate
              emailTemplate={template}
              isEditMode={this.isTemplateInEditMode}
              updateEmailTemplate={this.updateTemplate}/>
          case this.REJECT:
            return <CompleteStepRejectTemplate
              emailTemplate={template} isEditMode={this.isTemplateInEditMode}
              updateEmailTemplate={this.updateTemplate}/>
          default:
            return <div>No Email Templates Found</div>
        }
      default:
        return <div>No Email Templates Found</div>
    }
  }
  onChangeTabsTemplate = (activeKey) => {
    this.setState({
      editMode: false,
      editModeNew: false
    }, () => {
      this.props.isEditMode(this.state.editMode)
    })
  }
  render() {
    const { templates, editMode, editModeNew } = this.state
    const uniqueTemplates = templates && templates.length > 0 && templates.filter((a, i) => templates.findIndex((s) => a.type === s.type) === i)
    return (
      <React.Fragment>
        {templates && templates.length > 0 &&
          <Tabs
            defaultActiveKey="0"
            tabPosition="top"
            style={{ height: '100%' }}
            className="custom-top-tabs"
            onChange={ this.onChangeTabsTemplate }
          >
            {uniqueTemplates && uniqueTemplates.length > 0 && uniqueTemplates.map((value, key) => {
              return (
                <TabPane tab={value ? value.type : ''} key={key}
                  disabled={editMode && editModeNew}>{this.renderEmailTemplate(value)}</TabPane>
              )
            })
            }
          </Tabs>
        }
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription ? state.subscriptionReducer.subscription.subscription.client.id : null,
  godAdmin: state.admin.godAdminMode
})
export default connect(mapStateToProps, {})(TemplateEmailContent)