import React from 'react'
import { Button, Modal, Radio, Select, Spin, Table } from 'antd'
import Loading from '../../Common/Loading'
import { Field, reduxForm, submit } from 'redux-form'
import { connect } from 'react-redux'
import { fetchUserData } from '../../../actions/adminActions'
import swal from 'sweetalert2'
import CustomNotificationManager from '../../Common/CustomNotificationManager'
import {
  apiUpdateUser,
  getRolesByApplication,
  apiResetMfaUserAdmin,
  getClientDataList
} from '../../../api/Admin'
import moment from 'moment'
import withRouter from '../../../private-routers/withRouter'
const { Column } = Table
const RadioGroup = Radio.Group

class UserEditForm extends React.Component {
    state = {
      loading: true,
      dpiaRoles: [],
      ospreyRoles: [],
      multitenancyRoles: [],
      visibleModalTenant: false,
      companyList: [],
      selectedCompanyRow: {},
      disabledSelect: false
    }

    componentDidMount() {
      if (this.props.params.id) {
        this.props.fetchUserData(this.props.params.id)
        this.fetchRoles()
        this.fetchClientDataList()
      }
    }

    fetchRoles = async() => {
      const res1 = await getRolesByApplication('dpia')
      const res2 = await getRolesByApplication('osprey')
      const res3 = await getRolesByApplication('multitenancy')

      this.setState({ loading: false, dpiaRoles: res1.data, ospreyRoles: res2.data, multitenancyRoles: res3.data })
    }
     fetchClientDataList = () => {
       getClientDataList().then((response) => {
         this.setState({
           companyList: response.data
         })
       })
     }
    handleSubmit = (formValues) => {
      const { selectedCompanyRow } = this.state
      const { email } = this.props.initialValues
      this.setState({
        loading: true
      })
      formValues.clientId = selectedCompanyRow && selectedCompanyRow.key ? selectedCompanyRow.key : null
      if (email !== formValues.email) {
        swal.fire({
          text: `Email was modified, will be modified to new ${ formValues.email } email, are you sure? `,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3DD8DB',
          cancelButtonColor: '#D33D33',
          confirmButtonText: 'Yes'
        }).then((result) => {
          if (result.value) {
            apiUpdateUser(formValues).then(() => {
              CustomNotificationManager.success('User was successfully modified ', 'Success')
              this.setState({
                loading: false
              }, () => this.props.fetchUserData(this.props.params.id))
              this.props.navigate('/admin/user-data')
            })
          } else {
            this.setState({
              loading: false
            })
            CustomNotificationManager.info('Action cancelled', 'Aborted')
          }
        })
      } else {
        apiUpdateUser(formValues).then(() => {
          CustomNotificationManager.success('User was successfully modified ', 'Success')
          this.setState({
            loading: false
          }, () => this.props.fetchUserData(this.props.params.id))
          this.props.navigate('/admin/user-data')
        })
      }
    }

    renderInput = ({ input, label, type, placeholder }) => {
      return (
        <div className="tracker_formGroup full_width">
          <label htmlFor={label}>{label}:</label>
          <input {...input} type={type} placeholder={placeholder}/>
        </div>
      )
    };

    renderSelect = ({ input, label, options, key }) => {
      const defaultValue = this.props.initialValues ? this.props.initialValues.isActive: null
      return (
        <div className="tracker_formGroup full_width" key={key} id={`${ input.name }-container`}>
          <label htmlFor={label}>{label}:</label>
          <Select defaultValue={defaultValue} {...input}
                  getPopupContainer={() => document.querySelector(`#${input.name}-container`)}>
            {options && options.map((item) => (
              <Select.Option key={item.id} value={item.permission}>
                {item.permission}
              </Select.Option>
            ))}
          </Select>
        </div>
      )
    };

    renderRadio = ({ input, label }) => {
      const defaultValue = this.props.initialValues ? this.props.initialValues.sarVisible: null
      return (
        <div className="tracker_formGroup full_width">
          <label htmlFor={label}>{label}:</label>
          <RadioGroup
            defaultValue={defaultValue} {...input} style={{ display: 'flex' }}>
            <span className="custom-radio__yes">
              <Radio value={true}>Yes</Radio>
            </span>
            <span className="custom-radio__no">
              <Radio value={false}>No</Radio>
            </span>
          </RadioGroup>
        </div>
      )
    };
  renderRadioAuthentication = ({ input, label, meta }) => {
    const defaultValue = this.props.initialValues ? this.props.initialValues.sarVisible: true
    return (
      <div className="tracker_formGroup full_width tracker_formGroup-reset">
        <div className="form-group-reset-mfa">
          <label htmlFor={label}>{label}:</label>
          <RadioGroup
            defaultValue={defaultValue} {...input} style={{ display: 'flex' }}>
            <span className="custom-radio__yes">
              <Radio value={true}>Yes</Radio>
            </span>
            <span className="custom-radio__no">
              <Radio value={false}>No</Radio>
            </span>
          </RadioGroup>
        </div>
        <button onClick={ (e) => this.onResetMfaGlobalClicked(e)} className="button-green button-blue-reset button-blue-reset-new" > Reset MFA </button>
      </div>
    )
  }
  renderChangeUserCompany = ({ input, label, meta }) => {
    //const defaultValue = this.props.initialValues ? this.props.initialValues.sarVisible: true
    return (
      <div className="tracker_formGroup full_width tracker_formGroup-company">
        <label htmlFor={label}>{label}</label>
        <button onClick={ (e) => this.onChangeCompany(e) } className="button-green button-blue-reset button-blue-change-company" > Assign company </button>
      </div>
    )
  }
  onChangeCompany = (e) => {
    e.preventDefault()
    this.setState({
      visibleModalTenant: true
    })
  }
  onResetMfaGlobalClicked = (e) => {
    e.preventDefault()
    swal.fire({
      text: 'Please confirm reset of the Multi Factor Authentication account!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3DD8DB',
      cancelButtonColor: '#D33D33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        this.onGetResetGlobalMfa(e)
      } else {
        CustomNotificationManager.info('Action cancelled', 'Aborted')
      }
    })
  }
  onGetResetGlobalMfa = (e) => {
    e.preventDefault()
    const resetMfaAdminDTO = {
      userId: this.props.initialValues.key
    }
    apiResetMfaUserAdmin(resetMfaAdminDTO).then((response) => {
      if (response.data.title && response.data.title.toLowerCase() === 'success') {
        CustomNotificationManager.success(response.data.message, 'Success')
      } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
        CustomNotificationManager.info(response.data.message, 'Info')
      }
      // if (response.data === 'The MFA was reset successfully!' && response.status === 200) {
      //   CustomNotificationManager.success('The MFA was reset successfully!', 'Success')
      // }
    })
  }
  rowClassSelected = (record) => {
    const { selectedCompanyRow } = this.state
    if (record.key === selectedCompanyRow.key) {
      return 'row-selected-table'
    }
  }
  handleCancelModal = () => {
    this.setState({
      visibleModalTenant: false,
      disabledSelect: false,
      selectedCompanyRow: {}
    })
  }
  onClickRowSelected = (e, record) => {
    this.setState({
      selectedCompanyRow: record,
      disabledSelect: true
    })
  }
  saveChangesInvite = () => {
    this.setState({
      visibleModalTenant: false
    })
  }
  render() {
    const { loading, visibleModalTenant, companyList, disabledSelect } = this.state
    return (
      <div className="tracker-page">
        <div className="tracker-box">
          <form className="tracker-form" onSubmit={this.props.handleSubmit(this.handleSubmit)}>
            <header className="tracker-header">
              <div className="tracker-header__top">
                <h1 className="tracker-title">Modify User </h1>
              </div>
            </header>
            <div className="tracker-content">
              {/*<Field type="text" name="fullName" label="Full name" component={this.renderInput}/>*/}
              <Field type="text" name="firstName" label="First name" component={this.renderInput}/>
              <Field type="text" name="middleName" label="Middle name (optional)" component={this.renderInput}/>
              <Field type="text" name="lastName" label="Last name" component={this.renderInput}/>
              <Field type="text" name="blocked" label="Is blocked" component={this.renderRadio}/>
              <Field type="text" name="email" label="Email" component={this.renderInput}/>
              <Field type="text" name="emailVerified" label="Is email verified" component={this.renderRadio}/>
              <Field type="text" name="ospreyRole" label="Osprey role" options={this.state.ospreyRoles} key={1} component={this.renderSelect}/>
              <Field type="text" name="dpiaRole" label="Dpia role" options={this.state.dpiaRoles} key={2} component={this.renderSelect}/>
              <Field type="text" name="multitenancyRole" label="Multitenance role" options={this.state.multitenancyRoles} key={3} component={this.renderSelect}/>
              <Field type="text" name="allowTenants" label="Allow additional Tenants" component={this.renderRadio}/>
              <Field type="text" name="phone" label="Phone" component={this.renderInput}/>
              <Field type="text" name="verifyPassword" label="Is password verified" component={this.renderRadio}/>
              <Field type="text" name="multi_factor_authentication" label="Global Multi Factor Authentication"
                component={ this.renderRadioAuthentication }/>
              <Field type="text" name="company_name" label="Change user company"
                component={ this.renderChangeUserCompany }/>
            </div>
            <div className="tracker-footer">
              {
                loading ? <Spin className="float-right" spinning={loading}
                  indicator={<div className="checkout-loading"><Loading/></div>}/> :
                  <button type="submit" className="button-blue-light float-right">Modify User Data</button>
              }
            </div>
          </form>
        </div>
        <Modal
          title="Please select a tenant"
          visible={visibleModalTenant}
          onCancel={this.handleCancelModal}
          footer={ null }
          className="user-invite-tenant-company"
        >
          <div className="invite-tenant-company-content">
            <div className="client-list-data">
              <Table className="custom-table new-custom-table"
                dataSource={ companyList }
                pagination={ false }
                rowClassName={(record) => this.rowClassSelected(record) }
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (e) => this.onClickRowSelected(e, record)
                  }
                }}>
                <Column title="Company Name" dataIndex="name" key="name"/>
                <Column title="Status" dataIndex="isActive" key="isActive" render={(text) => {
                  return (<span>{text ? 'Active' : 'Inactive'}</span>)
                }} />
                <Column title="Joined" dataIndex="joined" key="joined" render={(text) => {
                  return (<span>{moment(text).format('DD/MM/YYYY')}</span>)
                }}/>
                <Column title="Domain" dataIndex="domain" key="domain"/>
                <Column title="Subscription" dataIndex="planName" key="planName" align="center"/>
              </Table>
            </div>
            <div className="footer-buttons-action-invite">
              <Button className="action-cancel-invite" onClick={(e) => this.handleCancelModal(e) }>
                Cancel
              </Button>
              <Button disabled={ disabledSelect === false } className="action-invite" onClick={ this.saveChangesInvite }
              >
                Select
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}
UserEditForm = withRouter(UserEditForm)
UserEditForm = reduxForm({
  form: 'userEditForm',
  onSubmit: submit,
  enableReinitialize: true
})(UserEditForm)

UserEditForm = connect(
  (state) => ({
    initialValues: state.admin.userEditForm
  }), { fetchUserData }
)(UserEditForm)

export default UserEditForm