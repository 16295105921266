import React from 'react'
import PropTypes from 'prop-types'

import WithCollections from '../HOC/WithCollections'

class CollectionsList extends React.Component {
  render = () => {
    return (
      <select
        name="selectedCollection"
        value={ this.props.value }
        onChange={ this.props.onChange }>
        <option value="">choose...</option>
        { this.props.collections.map((collection, index) => (
          <option key={ collection.id } value={ collection.id }>
            { collection.name }
          </option>
        )) }
      </select>
    )
  }
}

CollectionsList.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string
}

export default WithCollections(CollectionsList)