import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CustomNotificationManager from '../../Common/CustomNotificationManager'
import Icon from 'antd/es/icon'
import { Modal, Select, Input, Button } from "antd";
import {addBusinessTermToDictionary, getDictionariesRedacted} from "../../../api/SmartBox";
const { Option } = Select
class CommentsSidebar extends Component {

    constructor(props) {
        super(props)
        this.state = {
            newRedactedLabel: '',
            expandPageSelected: true,
            objectKeysPagesExpanded: [],
            onHoverRedaction: false,
            convertedAnnotationText: '',
            onHoverRedactionAnnotation: null,
            visibleModalDictionary: false,
            annotationSelect: {},
            actionAddTerm: false,
            itemsAnnotation: []
        }
    }
    componentDidMount() {
    }

    onExpandPageComments = (e, canvasData, page, index, expandPageSelected) => {
        const { objectKeysPagesExpanded } = { ...this.state }
        objectKeysPagesExpanded.push(page)
        this.setState({
            expandPageSelected: expandPageSelected,
            objectKeysPagesExpanded: objectKeysPagesExpanded
        })

    }
    onMouseEnter(e, index, convertedAnnotation) {
        this.setState({
            onHoverRedaction: true,
            convertedAnnotationText: convertedAnnotation.text,
            onHoverRedactionAnnotation: convertedAnnotation.index
        })
    }
    onMouseLeave() {
        this.setState({
            onHoverRedaction: false,
            convertedAnnotationText: ''
        })
    }
    onEnterAction = (e, status) => {
        this.setState({
            actionAddTerm: true
        })
    }
    onLeaveAction = (e, status) => {
        this.setState({
            actionAddTerm: false
        })
    }
    isJson(str) {
        try {
            JSON.parse(str)
        } catch (e) {
            return false
        }
        return true
    }
    generateHtmlPageComments(canvasData, pageNumber) {
        const { newRedactedLabel, btnAnnotationActiveIndex, onHoverRedactionAnnotation, actionAddTerm } = this.state
        const { annotationSelectedItem, endPointXDraw } = this.props
        const items = []
        let index = 1
        canvasData[pageNumber].map((annotation, index2) => {
            if (annotation) {
                const convertedAnnotation = this.isJson(annotation) ? JSON.parse(annotation) : annotation
                if (convertedAnnotation.type !== 2 && (convertedAnnotation.type === 3 || convertedAnnotation.type === 4)) {
                    convertedAnnotation.index = index2
                    convertedAnnotation.endX1 = endPointXDraw
                    convertedAnnotation.pageNumber = pageNumber
                    //const difx = endPointXDraw - convertedAnnotation.x1
                    //const dify = convertedAnnotation.y1 - convertedAnnotation.y2
                    if (endPointXDraw !== convertedAnnotation.x1 && convertedAnnotation.endX1 !== convertedAnnotation.x1) {
                        items.push(
                            <React.Fragment key={index}>
                                {convertedAnnotation &&
                                <div onMouseEnter={(e) => this.onMouseEnter(e, index, convertedAnnotation)}
                                     onMouseLeave={() => this.onMouseLeave()} key={convertedAnnotation.pagePointSum}
                                     className="position-relative">
                                    {annotationSelectedItem !== convertedAnnotation.text &&
                                    <React.Fragment>
                    <span
                        // className={convertedAnnotation.text && convertedAnnotationText && convertedAnnotationText === convertedAnnotation.text ? 'icon-v17-redaction-hover' : 'icon-v17-redaction'}
                        className='icon-v17-redaction'
                    ></span>
                                        { annotationSelectedItem !== convertedAnnotation.pagePointSum &&
                                        <button key={index}
                                                onClick={() => this.props.highlightRectangle(convertedAnnotation, annotation, pageNumber)}
                                                value={annotation} className={btnAnnotationActiveIndex === annotation
                                            ? 'annotation-page__button annotation-page__button--active annotation-page__update' : 'annotation-page__button  annotation-page__update'}>
                                            {convertedAnnotation.text && convertedAnnotation.text !== '!@#draw-by-hand#@!' ? convertedAnnotation.text :
                                                (convertedAnnotation.annotation && convertedAnnotation.annotation !== '!@#draw-by-hand#@!' ? convertedAnnotation.annotation.substring(0, 20) : `Redaction ${index}`.substring(0, 20))}
                                        </button>
                                        }
                      {/*                  {*/}
                      {/*                      // convertedAnnotation.text && convertedAnnotationText && convertedAnnotationText === convertedAnnotation.text && annotationSelectedItem !== convertedAnnotation.pagePointSum*/}
                      {/*                      convertedAnnotation && onHoverRedactionAnnotation === convertedAnnotation.index &&*/}
                      {/*                      <span className="action-redacted-sidebar">*/}
                      {/*  <span*/}
                      {/*      className="icon-v21-edit annotation-page__edit"*/}
                      {/*      id={convertedAnnotation.pagePointSum}*/}
                      {/*      onClick={() => {*/}
                      {/*          this.changeButtonInput(convertedAnnotation.pagePointSum, convertedAnnotation, annotation, pageNumber)*/}
                      {/*      }}*/}
                      {/*  />*/}
                      {/*                          { annotationSelectedItem !== convertedAnnotation.pagePointSum &&*/}
                      {/*                          <span className={actionAddTerm ? "icon-v21-add-active" : 'icon-v21-add'}*/}
                      {/*                                onClick={(e) => this.addDictionaryAnnotationSidebar(e, convertedAnnotation)}*/}
                      {/*                                onMouseEnter={(e) => this.onEnterAction(e, 'add')}*/}
                      {/*                                onMouseLeave={() => this.onLeaveAction()}></span>*/}
                      {/*                          }*/}
                      {/*</span>*/}
                      {/*                  }*/}
                                    </React.Fragment>
                                    }
                                    {annotationSelectedItem === convertedAnnotation.pagePointSum &&
                                    <React.Fragment>
                                        <input
                                            className="icon-edit-b annotation-page__input"
                                            type="text"
                                            id={convertedAnnotation.pagePointSum}
                                            value={newRedactedLabel}
                                            onChange={(event) => this.changeValues(event)}
                                            onKeyPress={(event) => this.changeKeyPress(event, convertedAnnotation.pagePointSum)}
                                        />
                                        <Icon type="save"
                                              className="annotation-page__edit"
                                              id={convertedAnnotation.pagePointSum}
                                              onClick={() => {
                                                  this.updateRedactedLabel(convertedAnnotation.pagePointSum)
                                              }}
                                        />
                                    </React.Fragment>
                                    }
                                </div>
                                }
                            </React.Fragment>
                        )
                    }
                }
            }
            index = index + 1
            return true
        })
        // const uniqueItems = items && items.length > 0 && items.filter((a, i) => items.findIndex((s) => a.props.children.key === s.props.children.key) === i)
        return items
    }

    render() {
        const { canvasDataComments, page, index } = this.props
        const { expandPageSelected } = this.state
        return (
            <div className='comments-page annotation-page' key={ index }>
                <span onClick={(e) => this.onExpandPageComments(e, canvasDataComments, page, index, !this.state.expandPageSelected)} className="annotation-page-content">
        <span className='annotation-page__title'>{ page } Page</span>
                    { expandPageSelected ?
                        <Icon type="down" aria-hidden="true" style={ { width: '20px', height: '15px' } }/> :
                        <Icon type="up" aria-hidden="true" style={ { width: '20px', height: '15px' } }/>
                    }
          </span>
                { expandPageSelected &&
                this.generateHtmlPageComments(canvasDataComments, page)
                }
            </div>
        )
    }

}

CommentsSidebar.propTypes = {
    index: PropTypes.number.isRequired,
    canvasData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    highlightRectangle: PropTypes.func.isRequired,
    savePointsOnPage: PropTypes.func.isRequired,
    loadPoints: PropTypes.func.isRequired,
    annotationSelectedItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    setAnnotationSelectedItem: PropTypes.func
}

export default CommentsSidebar
