import axios from 'axios'
import { objectToFormData } from '../utils/FormDataBuilder'

const isLocalhost = () => {
  return window.location.hostname === 'localhost'
}
//const host = isLocalhost() ? 'https://staging.ospreysmart.com' : ''
const host = isLocalhost() ? 'http://localhost:8080' : ''

export const apiTeamFetchTeamsList = (data) => {
  // const accessToken = localStorage.getItem('accessToken')
  const idToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId
  const dpiaToken = localStorage.getItem('dpiaToken')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/2.0/teams?action=GET`,
    headers: {
      'Authorization': `Bearer ${ idToken }`
    },
    data: { 'id_token': idToken, 'dpia_token': dpiaToken, 'osprey_access_token': idToken }
  })
}

export const apiTeamFetchTeamsListSmall = () => {
  // const accessToken = localStorage.getItem('accessToken')
  const idToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId
  
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/2.0/get-teams?action=GET`,
    headers: {
      'Authorization': `Bearer ${ idToken }`
    },
    data: { 'id_token': idToken, 'osprey_access_token': idToken }
  })
}

export const apiTeamFetchTeam = (id) => {
  // const accessToken = localStorage.getItem('accessToken')
  const idToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/2.0/teams/${ id }?action=GET`,
    headers: {
      'Authorization': `Bearer ${ idToken }`
    },
    data: { 'id_token': idToken }
  })
}
export const apiLogsForTeam = (id) => {
  // const accessToken = localStorage.getItem('accessToken')
  const idToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/2.0/teams/${ id }/logsforteam?action=GET`,
    headers: {
      'Authorization': `Bearer ${ idToken }`
    },
    data: { 'id_token': idToken }
  })
}
export const apiClearLogsForTeam = (id) => {
  // const accessToken = localStorage.getItem('accessToken')
  const idToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/2.0/teams/${ id }/clearlogsforteam?action=GET`,
    headers: {
      'Authorization': `Bearer ${ idToken }`
    },
    data: { 'id_token': idToken }
  })
}

export const apiTeamResendInvite = (data) => {
  // const accessToken = localStorage.getItem('accessToken')
  const idToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/2.0/teams`,
    headers: {
      'Authorization': `Bearer ${ idToken }`
    },
    data
  })
}

export const apiTeamUpdate = (data, id) => {
  // const accessToken = localStorage.getItem('accessToken')
  const idToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/2.0/teams/${ id }?action=UPDATE`,
    headers: {
      'Authorization': `Bearer ${ idToken }`
    },
  })
}

export const apiTeamProcessSave = (data, id) => {
  const ospToken = localStorage.getItem('ospToken')
  // const accessToken = localStorage.getItem('accessToken')
  const idToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId

  Object.assign(data, { osprey_access_token: idToken })

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/2.0/teams/${ id }?action=UPDATE`,
    headers: {
      'Authorization': `Bearer ${ ospToken }`
    },
    data
  })
}

export const apiTeamCreate = (data) => {
  // const accessToken = localStorage.getItem('accessToken')
  const accessToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/teams/?action=CREATE`,
    headers: {
      'Authorization': `Bearer ${ accessToken }`
    },
    data
  })
}

export const apiTeamUpdateTeam = (data, id) => {
  // const accessToken = localStorage.getItem('accessToken')
  const idToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId

  //id = 3
  let bodyFormData
  bodyFormData = objectToFormData(data)
  bodyFormData.append('idToken', idToken)

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/2.0/teams/${ id }`,
    headers: {
      'Authorization': `Bearer ${ idToken }`
    },
    data: bodyFormData
  })
}

export const apiTeamCreateTeam = (data) => {
  // const accessToken = localStorage.getItem('accessToken')
  const idToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId
  let bodyFormData
  bodyFormData = objectToFormData(data)
  bodyFormData.append('idToken', idToken)

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/2.0/teams`,
    headers: {
      'Authorization': `Bearer ${ idToken }`,
    },
    data: bodyFormData
  })
}

export const apiTeamEnableDisableTeam = (data, id, status) => {
  // const accessToken = localStorage.getItem('accessToken')
  const idToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/2.0/teams/${ id }?action=${ status }`,
    headers: {
      'Authorization': `Bearer ${ idToken }`
    },
    data: { 'id_token': idToken }
  })
}

export const apiTeamEnableDisableUser = (id, memberId, status) => {
  // const accessToken = localStorage.getItem('accessToken')
  const idToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/2.0/teams/${ id }/members/${ memberId }?action=${ status }`,
    headers: {
      'Authorization': `Bearer ${ idToken }`
    },
    data: { 'id_token': idToken }
  })
}

export const apiTeamAddMember = (id, data) => {
  // const accessToken = localStorage.getItem('accessToken')
  const idToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/2.0/teams/${ id }/members`,
    headers: {
      'Authorization': `Bearer ${ idToken }`
    },
    data
  })
}

export const apiTeamAddMembers = (data) => {
  // const accessToken = localStorage.getItem('accessToken')
  const idToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/2.0/teams/assign-member`,
    headers: {
      'Authorization': `Bearer ${ idToken }`
    },
    data
  })
}

// @RequestMapping(value = "/{apiVersion}/teams/{id}/wanna-be-members",method = RequestMethod.POST,params = "action=GET")
export const apiTeamGetPossibleNewMembers = (id) => {
  // const accessToken = localStorage.getItem('accessToken')
  const idToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/2.0/teams/${ id }/wanna-be-members?action=GET`,
    headers: {
      'Authorization': `Bearer ${ idToken }`
    },
    data: { 'id_token': idToken }
  })
}

export const setTenantIdForTeam = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/multitenancy/assign-team`,
    data
  })
}

