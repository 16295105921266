import React from "react";
import {connect} from "react-redux";
import './ComponentsFormAdmin.scss';
import { Input, Checkbox} from "antd";

class AddressFormAdmin extends React.Component {
    state = {
        addressForm: {
            addressStreet: '',
            addressStreetLine2: '',
            addressCity: '',
            addressState: '',
            addressPostal: '',
            queryForSearch: false,
            mandatoryCheck: false,
            options: null,
            header: 'Address',
            sectionVariable: ''
        },
        hoverDelete: false,
        editHeader: false,
        hoverEditBtn:false,
    }
    componentDidMount() {
        const { component } = this.props
        this.setState({
            addressForm: { ...this.state.addressForm,
                queryForSearch: component.queryForSearch === undefined ? false : component.queryForSearch,
                mandatoryCheck: component.mandatory === undefined ? false : component.mandatory,
                options: component.options === undefined ? null : component.options,
                header: component.header === undefined ? 'Address' : component.header,
                sectionVariable: component.sectionVariable === undefined ? '' : component.sectionVariable,
            }
        })
        //this.props.onChangeForm(this.state.addressForm, this.props.component)
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props && this.props.checkStartSaved) {
            this.props.onChangeForm(this.state.addressForm, this.props.component)
        }
    }
    onChangeAddress = (e, key) => {
        switch (key) {
            case 'address_street':
                this.setState({
                    addressForm: { ...this.state.addressForm, addressStreet: e.target.value },
                })
                break
            case 'address_street_line2':
                this.setState({
                    addressForm: { ...this.state.addressForm, addressStreetLine2: e.target.value },
                })
                break
            case 'address_city':
                this.setState({
                    addressForm: { ...this.state.addressForm, addressCity: e.target.value },
                })
                break
            case 'address_state':
                this.setState({
                    addressForm: { ...this.state.addressForm, addressState: e.target.value },
                })
                break
            case 'address_postal':
                this.setState({
                    addressForm: { ...this.state.addressForm, addressPostal: e.target.value },
                })
                break
            default:
                break
        }
    }
    onChangeCheckbox = (e, key) => {
        switch (key) {
            case 'query':
                this.setState({
                    addressForm: { ...this.state.addressForm, queryForSearch: e.target.checked },
                }, () => {
                    this.props.onChangeForm(this.state.addressForm, this.props.component)
                })
                break
            case 'mandatory':
                this.setState({
                    addressForm: { ...this.state.addressForm, mandatoryCheck: e.target.checked },
                }, () => {
                    this.props.onChangeForm(this.state.addressForm, this.props.component)
                })
                break
            default:
                break
        }
    }
    onHoverActionComponent = () => {
        this.setState({
            hoverDelete: true
        })
    }
    onLeaveActionComponent = () => {
        this.setState({
            hoverDelete: false
        })
    }
    onHoverEditBtn = () => {
        this.setState({
            hoverEditBtn: true
        })
    }
    onLeaveEditBtn = () => {
        this.setState({
            hoverEditBtn: false
        })
    }
    onClickEditHeader = (e, status) => {
        if (status === 'header') {
            this.setState({
                editHeader: true
            })
        }
    }
    addTitleHeader = (e, status) => {
        if (status === 'header') {
            this.setState({
                addressForm: { ...this.state.addressForm, header: e.target.value },
                //editHeader: false
            }, () => {
                this.props.onChangeForm(this.state.addressForm, this.props.component)
            })
        }
    }
    onChangeFormVariable = (e) => {
        this.setState({
            addressForm: { ...this.state.addressForm, sectionVariable: e.target.value },
        }, () => {
            this.props.onChangeForm(this.state.addressForm, this.props.component)
        })
    }
    confirmEditHeader = () => {
        this.setState({
            editHeader:false,
        })
    }
    render() {
        const { addressForm, hoverDelete, editHeader, hoverEditBtn } = this.state
        const { component } = this.props
        return (
            <div className={ this.props.snapshot.isDragging ? "component-form dragging " : "component-form"}>
                <div className="component-delete">
                    <span onClick={ (e) => this.props.showConfirmDeleteFormElement(e, component) }
                          className={hoverDelete ? 'icon-v19-delete-active' : "icon-v19-delete" }
                    onMouseEnter={ (e) => this.onHoverActionComponent(e)}
                    onMouseLeave={() => this.onLeaveActionComponent()}></span>
                </div>
                <div className="component-content">
                    <div className="content-input-form-variables">
                          <span className="header-checkbox">
                           {/*<Checkbox onChange={(e) => this.onChangeCheckbox(e, 'query')}*/}
                              {/*          checked={ addressForm.queryForSearch }>Query for search</Checkbox>*/}
                              <Checkbox onChange={(e) => this.onChangeCheckbox(e, 'mandatory')}
                                        checked={ addressForm.mandatoryCheck }>Mandatory</Checkbox>
                        </span>
                        {/*<span className="content-variables">*/}
                        {/*     <span className="variables-diaz">#</span>*/}
                        {/*<Input*/}
                        {/*    className="input-form-variables"*/}
                        {/*    placeholder="Form variable"*/}
                        {/*    value={addressForm.sectionVariable}*/}
                        {/*    onChange={(e) => this.onChangeFormVariable(e)}*/}
                        {/*/>*/}
                        {/*</span>*/}
                    </div>
                    <div className="header-component header-component-update">
                         <span className="header-content">
                              { editHeader ?
                                  <div>
                                      <Input defaultValue={addressForm.header} className="input-other-option"
                                             onChange={ (e) => this.addTitleHeader(e, 'header')}
                                             onPressEnter={() => this.confirmEditHeader()}
                                      />
                                      <span className={ hoverEditBtn ? "icon-v21-edit-active" : "icon-v21-edit" }
                                            onClick={(e) => this.confirmEditHeader()}
                                            onMouseEnter={() => this.onHoverEditBtn()}
                                            onMouseLeave={() => this.onLeaveEditBtn()}
                                      ></span>
                                  </div>
                                   :
                                  <div>
                                      <span className="header-title header-title-update">{addressForm.header}</span>
                                      <span className={ hoverEditBtn ? "icon-v21-edit-active" : "icon-v21-edit" }
                                            onClick={(e) => this.onClickEditHeader(e, 'header')}
                                            onMouseEnter={() => this.onHoverEditBtn()}
                                            onMouseLeave={() => this.onLeaveEditBtn()}
                                      ></span>
                                  </div>
                              }
                          </span>
                    </div>
                    <div className="inputs-address">
                        <span className="input-content">
                                <Input className="input-form-name"
                                       onChange={(e) => this.onChangeAddress(e, 'address_street')}
                                       disabled={true}
                                       value={ addressForm.addressStreet }/>
                                <span className="label-input">Street Address</span>
                            </span>
                        <span className="input-content">
                                <Input className="input-form-name"
                                       onChange={(e) => this.onChangeAddress(e, 'address_street_line2')}
                                       disabled={true}
                                       value={ addressForm.addressStreetLine2 }/>
                                <span className="label-input">Street Address Line 2</span>
                            </span>
                        <div className="input-header-component">
                            <span className="input-content">
                                <Input className="input-form-name"
                                       onChange={(e) => this.onChangeAddress(e, 'address_city')}
                                       disabled={true}
                                       value={ addressForm.addressCity }/>
                                <span className="label-input">City</span>
                            </span>
                            <span className="input-content">
                                <Input className="input-form-name"
                                       onChange={(e) => this.onChangeAddress(e, 'address_state')}
                                       disabled={true}
                                       value={ addressForm.addressState }/>
                                <span className="label-input">State / Province</span>
                            </span>
                        </div>
                        <span className="input-content">
                                <Input className="input-form-name"
                                       onChange={(e) => this.onChangeAddress(e, 'address_postal')}
                                       disabled={true}
                                       value={ addressForm.addressPostal }/>
                                <span className="label-input">Postal / Zip Code</span>
                            </span>
                    </div>
                </div>
                <div className="component-move">
                    <span onMouseEnter={() => this.props.onStateProvidedDraggable(component)} className="icon-v211-grid-view-1"></span>
                </div>
            </div>
        )
    }
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(AddressFormAdmin)