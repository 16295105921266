import React from 'react'
import PropTypes from 'prop-types'

import CategoryItem from './CategoryItem'

import './CategoryList.css'

const CategoryList = ({ categories }) => {
  if (categories.length === 0) return (
    <p>No children</p>
  )
  return (
      
    <div className="ospreyCategoryList">
      { categories !== null && categories.length > 0
        ? categories.map((category, index) => (
          <CategoryItem category={ category } key={ category.id }/>
        ))
        : <div/>
      }
    </div>
  )
}

CategoryList.propTypes = {
  categories: PropTypes.array.isRequired
}

export default CategoryList