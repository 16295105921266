import React, { Component } from 'react'
import './ExternalFeedbackWorkflowTicketForm.scss'
import { connect } from 'react-redux';
import NameTemplateFormExternal from "./ComponentsExternalForm/NameTemplateFormExternal";
import AddressTemplateFormExternal from "./ComponentsExternalForm/AddressTemplateFormExternal";
import EmailTemplateFormExternal from "./ComponentsExternalForm/EmailTemplateFormExternal";
import TelephoneTemplateFormExternal from "./ComponentsExternalForm/TelephoneTemplateFormExternal";
import ShortTextTemplateFormExternal from "./ComponentsExternalForm/ShortTextTemplateFormExternal";
import LongTextTemplateFormExternal from "./ComponentsExternalForm/LongTextTemplateFormExternal";
import DropdownTemplateFormExternal from "./ComponentsExternalForm/DropdownTemplateFormExternal";
import SingleChoiceTemplateFormExternal from "./ComponentsExternalForm/SingleChoiceTemplateFormExternal";
import MultipleChoiceTemplateFormExternal from "./ComponentsExternalForm/MultipleChoiceTemplateFormExternal";
import FileUploadTemplateFormExternal from "./ComponentsExternalForm/FileUploadTemplateFormExternal";
import SignatureTemplateFormExternal from "./ComponentsExternalForm/SignatureTemplateFormExternal";
import HeaderTemplateFormExternal from "./ComponentsExternalForm/HeaderTemplateFormExternal";
import {Button} from "antd";
import {apiGetFeedbackWorkflowTicketForm, apiSaveFormSectionsDataTicketWorkflowByUse} from "../../../api/SmartBox";
import withRouter from "../../../private-routers/withRouter";
import CustomNotificationManager from "../../Common/CustomNotificationManager";

class ExternalFeedbackWorkflowTicketForm extends Component {
    constructor() {
        super()
        this.state = {
            listComponentsTemplateForm: [
                // {id: 'fullName', sectionType: 'fullName', header: 'FullName'},
                // {id: 'address', sectionType: 'address', header: 'Address'},
                // {id: 'email', sectionType: 'email', header: 'Email'},
            ],
            formerEmployee: {
                firstNameValue: null,
                lastNameValue: null,
                streetAddressValue: null,
                street2AddressValue: null,
                cityAddressValue: null,
                stateAddressValue: null,
                postalCodeValue: null,
                emailAddressValue: null,
                phoneNumber: null,
                shortTextValue: null,
                longTextValue: null,
                dropdownValue: null,
                singleChoiceValue: null,
                formName: null,
            },
            formId: null,
            workflowComponentId: null,
            ticketId: null,
            responseFeedback: {},
            checkMandatoryForm: null,
            checkMandatoryFormShort: null,
            checkMandatoryFormLong: null,
            checkMandatoryFormAddress: null,
            checkMandatoryFormEmail: null,
            checkMandatoryFormPhone: null,
            checkMandatoryFormRadio: null,
            checkMandatoryFormDropdown: null,
            checkMandatoryFormMultiple: null,
            checkMandatoryFormSignature: null,
            checkMandatoryFormUpload: null,
        }
    }

    componentDidMount() {
       if(this.props.params.id) {
           this.onGetFeedbackWorkflowTicketForm()
       }
    }

    onGetFeedbackWorkflowTicketForm = () => {
        const {formerEmployee, listComponentsTemplateForm } = this.state
        apiGetFeedbackWorkflowTicketForm(this.props.params.id).then((response) => {
            if (response.data.title && response.data.title.toLowerCase() === 'success') {
                CustomNotificationManager.success(response.data.message, 'Success')
            } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
                CustomNotificationManager.info(response.data.message, 'Info')
            }
            if (response.data && response.data.sections && response.data.sections.length > 0) {
                response.data.sections && response.data.sections.length > 0 && response.data.sections.forEach((elem) => {
                    if (elem.sectionType === 'dropdown-select') {
                        const dropdown = []
                        elem.options && elem.options.length > 0 && elem.options.forEach((elem2) => {
                            if (elem2.selected) {
                                dropdown.push(elem2.optionId)
                                elem.dropdown = elem2.optionId
                                elem.selectedSectionOptionIDs = dropdown
                            }
                        })
                    }
                    if (elem.sectionType === 'radio') {
                        const singleChoice = []
                        elem.options && elem.options.length > 0 && elem.options.forEach((elem2) => {
                            if (elem2.selected) {
                                singleChoice.push(elem2.optionId)
                                elem.singleChoice = elem2.optionId
                                elem.selectedSectionOptionIDs = singleChoice
                            }
                        })
                    }
                    if (elem.sectionType === 'multiple-choice') {
                        const multipleChoice = []
                        elem.options && elem.options.length > 0 && elem.options.forEach((elem2) => {
                            if (elem2.selected) {
                                multipleChoice.push(elem2.optionId)
                                elem.multipleChoice = multipleChoice
                                elem.selectedSectionOptionIDs = multipleChoice
                            }
                        })
                    }
                })
                this.setState({
                    listComponentsTemplateForm: response.data.sections,
                    formId: response.data.formId,
                    formName: response.data.formName,
                    workflowComponentId: response.data.workflowComponentId,
                    ticketId: response.data.ticketId,
                }, () => {
                    this.onMandatoryFormComponentsValidation(this.state.formerEmployee, response.data.sections)
                })
            } else {
                this.setState({
                    responseFeedback: response.data,
                })
            }
        })
    }

    onChangeNameAndDetails = (e, key, component, inputValue) => {
        const { listComponentsTemplateForm, formerEmployee } = this.state
        listComponentsTemplateForm && listComponentsTemplateForm.length > 0 && listComponentsTemplateForm.forEach((elem) => {
            if (elem.sectionId === component.sectionId) {
                switch (key) {
                    case 'first_name':
                        elem.firstNameValue = e.target.value
                        this.setState({
                            formerEmployee: {...this.state.formerEmployee, firstNameValue: e.target.value},
                            listComponentsTemplateForm
                        }, () => {
                            this.onMandatoryFormComponentsValidation(formerEmployee, listComponentsTemplateForm)
                        })
                        break
                    case 'last_name':
                        elem.lastNameValue = e.target.value
                        elem.lastNameField = key
                        this.setState({
                            formerEmployee: {...this.state.formerEmployee, lastNameValue: e.target.value},
                            listComponentsTemplateForm
                        }, () => {
                            this.onMandatoryFormComponentsValidation(formerEmployee, listComponentsTemplateForm)
                        })
                        break
                    case 'address_street':
                        elem.streetAddressValue = e.target.value
                        this.setState({
                            formerEmployee: {...this.state.formerEmployee, streetAddressValue: e.target.value},
                        }, () => {
                            this.onMandatoryFormComponentsValidation(formerEmployee, listComponentsTemplateForm)
                        })
                        break
                    case 'address_street_line2':
                        elem.street2AddressValue = e.target.value
                        this.setState({
                            formerEmployee: {...this.state.formerEmployee, street2AddressValue: e.target.value},
                        }, () => {
                            this.onMandatoryFormComponentsValidation(formerEmployee, listComponentsTemplateForm)
                        })
                        break
                    case 'address_city':
                        elem.cityAddressValue = e.target.value
                        this.setState({
                            formerEmployee: {...this.state.formerEmployee, cityAddressValue: e.target.value},
                        }, () => {
                            this.onMandatoryFormComponentsValidation(formerEmployee, listComponentsTemplateForm)
                        })
                        break
                    case 'address_state':
                        elem.stateAddressValue = e.target.value
                        this.setState({
                            formerEmployee: {...this.state.formerEmployee, stateAddressValue: e.target.value},
                        }, () => {
                            this.onMandatoryFormComponentsValidation(formerEmployee, listComponentsTemplateForm)
                        })
                        break
                    case 'address_postal':
                        elem.postalCodeValue = e.target.value
                        this.setState({
                            formerEmployee: {...this.state.formerEmployee, postalCodeValue: e.target.value},
                        }, () => {
                            this.onMandatoryFormComponentsValidation(formerEmployee, listComponentsTemplateForm)
                        })
                        break
                    case 'email':
                        elem.emailAddressValue = e.target.value
                        this.setState({
                            formerEmployee: {...this.state.formerEmployee, emailAddressValue: e.target.value},
                        }, () => {
                            this.onMandatoryFormComponentsValidation(formerEmployee, listComponentsTemplateForm)
                        })
                        break
                    case 'telephone':
                        elem.phoneNumber = e.target.value
                        this.setState({
                            formerEmployee: {...this.state.formerEmployee, phoneNumber: e.target.value},
                        }, () => {
                            this.onMandatoryFormComponentsValidation(formerEmployee, listComponentsTemplateForm)
                        })
                        break
                    case 'short_text':
                        elem.shortTextValue = e.target.value
                        this.setState({
                            formerEmployee: {...this.state.formerEmployee, shortTextValue: e.target.value},
                        }, () => {
                            this.onMandatoryFormComponentsValidation(formerEmployee, listComponentsTemplateForm)
                        })
                        break
                    case 'long_text':
                        elem.longTextValue = e.target.value
                        this.setState({
                            formerEmployee: {...this.state.formerEmployee, longTextValue: e.target.value},
                        }, () => {
                            this.onMandatoryFormComponentsValidation(formerEmployee, listComponentsTemplateForm)
                        })
                        break
                    case 'dropdown':
                        const dropdown = []
                        dropdown.push(e)
                        elem.dropdown = e
                        elem.selectedSectionOptionIDs = dropdown
                        this.setState({
                            formerEmployee: {...this.state.formerEmployee, dropdownValue: e},
                        }, () => {
                            this.onMandatoryFormComponentsValidation(formerEmployee, listComponentsTemplateForm)
                        })
                        break
                    case 'single_choice':
                        const singleChoice = []
                        singleChoice.push(e.target.value)
                        elem.singleChoice = e.target.value
                        elem.selectedSectionOptionIDs = singleChoice
                        this.setState({
                            formerEmployee: {...this.state.formerEmployee, singleChoiceValue: e.target.value},
                        }, () => {
                            this.onMandatoryFormComponentsValidation(formerEmployee, listComponentsTemplateForm)
                        })
                        break
                    case 'multiple_choice':
                        elem.multipleChoice = e
                        elem.selectedSectionOptionIDs = e
                        this.setState({
                            formerEmployee: {...this.state.formerEmployee, multipleChoice: e},
                        }, () => {
                            this.onMandatoryFormComponentsValidation(formerEmployee, listComponentsTemplateForm)
                        })
                        break
                    case 'file_upload':
                        elem.fileIDs = []
                        this.setState({
                            formerEmployee: {...this.state.formerEmployee, fileIDs: []},
                        }, () => {
                            this.onMandatoryFormComponentsValidation(formerEmployee, listComponentsTemplateForm)
                        })
                        //this.props.onGetFormTicketWorkflow(this.state.formerEmployee, listComponentsTemplateForm)
                        break
                    case 'signature':
                        elem.signature = e
                        this.setState({
                            formerEmployee: {...this.state.formerEmployee, signature: e},
                        }, () => {
                            this.onMandatoryFormComponentsValidation(formerEmployee, listComponentsTemplateForm)
                        })
                        break
                    default:
                        break
                }
            }

        })
    }

    generateComponentsFormTemplate(formerEmployee, component, index) {
        const { listComponentsTemplateForm, formId, workflowComponentId, ticketId } = this.state
        const { statusSaveComponentFromWorkflow, activeStepProps, selectHeaderTickets, statusResponseSaveFormSectionsData } = this.props
        const encodedId = this.props.params.id
        switch(component.sectionType) {
            case 'fullName':
                return <NameTemplateFormExternal key={index} formerEmployee={formerEmployee} onChangeNameAndDetails={this.onChangeNameAndDetails}
                                         component={component} listComponentsTemplateForm={listComponentsTemplateForm} statusSaveComponentFromWorkflow={statusSaveComponentFromWorkflow}/>
                break
            case "address":
                return <AddressTemplateFormExternal key={index} formerEmployee={formerEmployee} onChangeNameAndDetails={this.onChangeNameAndDetails}
                                            component={component} listComponentsTemplateForm={listComponentsTemplateForm} statusSaveComponentFromWorkflow={statusSaveComponentFromWorkflow}/>
                break
            case "email":
                return <EmailTemplateFormExternal key={index} formerEmployee={formerEmployee} onChangeNameAndDetails={this.onChangeNameAndDetails}
                                          component={component} listComponentsTemplateForm={listComponentsTemplateForm} statusSaveComponentFromWorkflow={statusSaveComponentFromWorkflow}/>
                break
            case "phone":
                return <TelephoneTemplateFormExternal key={index} formerEmployee={formerEmployee} onChangeNameAndDetails={this.onChangeNameAndDetails}
                                              component={component} listComponentsTemplateForm={listComponentsTemplateForm} statusSaveComponentFromWorkflow={statusSaveComponentFromWorkflow}/>
                break
            case "short-text":
                return <ShortTextTemplateFormExternal key={index} formerEmployee={formerEmployee} onChangeNameAndDetails={this.onChangeNameAndDetails}
                                              component={component} listComponentsTemplateForm={listComponentsTemplateForm} statusSaveComponentFromWorkflow={statusSaveComponentFromWorkflow}/>
                break
            case "long-text":
                return <LongTextTemplateFormExternal key={index} formerEmployee={formerEmployee} onChangeNameAndDetails={this.onChangeNameAndDetails}
                                             component={component} listComponentsTemplateForm={listComponentsTemplateForm} statusSaveComponentFromWorkflow={statusSaveComponentFromWorkflow}/>
                break
            case "dropdown-select":
                return <DropdownTemplateFormExternal key={index} formerEmployee={formerEmployee} onChangeNameAndDetails={this.onChangeNameAndDetails}
                                             component={component} listComponentsTemplateForm={listComponentsTemplateForm} statusSaveComponentFromWorkflow={statusSaveComponentFromWorkflow}/>
                break
            case "radio":
                return <SingleChoiceTemplateFormExternal key={index} formerEmployee={formerEmployee} onChangeNameAndDetails={this.onChangeNameAndDetails}
                                                 component={component} listComponentsTemplateForm={listComponentsTemplateForm} statusSaveComponentFromWorkflow={statusSaveComponentFromWorkflow}/>
                break
            case "multiple-choice":
                return <MultipleChoiceTemplateFormExternal key={index} formerEmployee={formerEmployee} onChangeNameAndDetails={this.onChangeNameAndDetails}
                                                   component={component} listComponentsTemplateForm={listComponentsTemplateForm} statusSaveComponentFromWorkflow={statusSaveComponentFromWorkflow}/>
                break
            case "upload":
                return <FileUploadTemplateFormExternal key={index} formerEmployee={formerEmployee} onChangeNameAndDetails={this.onChangeNameAndDetails}
                                               component={component} listComponentsTemplateForm={listComponentsTemplateForm} statusSaveComponentFromWorkflow={statusSaveComponentFromWorkflow}
                                               formId={formId} activeStepProps={ activeStepProps } selectHeaderTickets={ selectHeaderTickets }
                                               onGetTicketFormComponentFromWorkflow={ this.onGetTicketFormComponentFromWorkflow }
                                               onStartSaveFormSectionsData={ this.props.onStartSaveFormSectionsData }
                                               statusResponseSaveFormSectionsData={ statusResponseSaveFormSectionsData }
                                                       workflowComponentId={workflowComponentId} ticketId={ticketId}
                                                       encodedId={encodedId}/>
                break
            case "signature":
                return <SignatureTemplateFormExternal key={index} formerEmployee={formerEmployee} onChangeNameAndDetails={this.onChangeNameAndDetails}
                                              component={component} listComponentsTemplateForm={listComponentsTemplateForm} statusSaveComponentFromWorkflow={statusSaveComponentFromWorkflow}
                                              formId={formId}/>
                break
            case "header":
                return <HeaderTemplateFormExternal key={index} formerEmployee={formerEmployee} onChangeNameAndDetails={this.onChangeNameAndDetails}
                                           component={component} listComponentsTemplateForm={listComponentsTemplateForm} statusSaveComponentFromWorkflow={statusSaveComponentFromWorkflow}/>
                break
            default: break
        }
    }
    onSaveExternalFeedbackWorkflow = () => {
        const { listComponentsTemplateForm, formerEmployee, ticketId, formId, workflowComponentId } = this.state;
        const formSectionDataTicketDTOList = []
        listComponentsTemplateForm && listComponentsTemplateForm.length > 0 && listComponentsTemplateForm.forEach((elem) => {
            formSectionDataTicketDTOList.push({
                formSectionType: elem.sectionType,
                formSectionId: elem.sectionId,
                shortTextValue: elem.shortTextValue ? elem.shortTextValue : null,
                longTextValue: elem.longTextValue ? elem.longTextValue : null,
                firstNameValue: elem.firstNameValue ? elem.firstNameValue : null,
                lastNameValue: elem.lastNameValue ? elem.lastNameValue : null,
                emailAddressValue: elem.emailAddressValue ? elem.emailAddressValue : null,
                streetAddressValue: elem.streetAddressValue ? elem.streetAddressValue : null,
                street2AddressValue: elem.street2AddressValue ? elem.street2AddressValue : null,
                cityAddressValue: elem.cityAddressValue ? elem.cityAddressValue : null,
                stateAddressValue: elem.stateAddressValue ? elem.stateAddressValue : null,
                postalCodeValue: elem.postalCodeValue ? elem.postalCodeValue : null,
                phoneNumber: elem.phoneNumber ? elem.phoneNumber : null,
                selectedSectionOptionIDs: elem.selectedSectionOptionIDs && elem.selectedSectionOptionIDs.length > 0 ? elem.selectedSectionOptionIDs : null,
                signature: elem.signature ? elem.signature : null,
                fileIDs: elem.fileIDs && elem.fileIDs.length > 0 ? elem.fileIDs : null,
            })
        })
        const dtoSaveForm = {
            encodedId: this.props.params.id,
            ticketId: ticketId,
            workflowComponentFormSelectedId: formId,
            workflowComponentId: workflowComponentId,
            formSectionDataTicketDTOList: formSectionDataTicketDTOList,
        }
        apiSaveFormSectionsDataTicketWorkflowByUse(dtoSaveForm).then((response) => {
            if (response.data.title && response.data.title.toLowerCase() === 'success') {
                CustomNotificationManager.success(response.data.message, 'Success')
                setTimeout(function() {
                    window.close()
                }, 4000)
            } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
                CustomNotificationManager.info(response.data.message, 'Info')
            }
        })
    }
    onMandatoryFormComponentsValidation = (formWorkflow, listComponentsTemplateForm) => {
        const { validateFormComponent } = this.state
        this.setState({
            checkMandatoryForm: false,
            checkMandatoryFormShort: false,
            checkMandatoryFormLong: false,
            checkMandatoryFormAddress: false,
            checkMandatoryFormEmail: false,
            checkMandatoryFormPhone: false,
            checkMandatoryFormRadio: false,
            checkMandatoryFormDropdown: false,
            checkMandatoryFormMultiple: false,
            checkMandatoryFormSignature: false,
            checkMandatoryFormUpload: false,
        }, () => {
            listComponentsTemplateForm && listComponentsTemplateForm.length > 0 &&listComponentsTemplateForm.forEach((form) => {
                switch (form.sectionType) {
                    case 'fullName' :
                        if (form.mandatory) {
                            if (form.firstNameValue && form.lastNameValue) {
                                this.setState({
                                    checkMandatoryForm: false
                                })
                            } else {
                                this.setState({
                                    checkMandatoryForm: true
                                })
                            }
                        }
                        break
                    case 'short-text' :
                        if (form.mandatory) {
                            if (form.shortTextValue) {
                                this.setState({
                                    checkMandatoryFormShort: false
                                })
                            } else {
                                this.setState({
                                    checkMandatoryFormShort: true
                                })
                            }
                        }
                        break
                    case 'long-text' :
                        if (form.mandatory) {
                            if (form.longTextValue) {
                                this.setState({
                                    checkMandatoryFormLong: false
                                })
                            } else {
                                this.setState({
                                    checkMandatoryFormLong: true
                                })
                            }
                        }
                        break
                    case 'address' :
                        if (form.mandatory) {
                            if (form.streetAddressValue && form.street2AddressValue && form.cityAddressValue && form.stateAddressValue && form.postalCodeValue) {
                                this.setState({
                                    checkMandatoryFormAddress: false
                                })
                            } else {
                                this.setState({
                                    checkMandatoryFormAddress: true
                                })
                            }
                        }
                        break
                    case 'email' :
                        if (form.mandatory) {
                            if (form.emailAddressValue) {
                                this.setState({
                                    checkMandatoryFormEmail: false
                                })
                            } else {
                                this.setState({
                                    checkMandatoryFormEmail: true
                                })
                            }
                        }
                        break
                    case 'phone' :
                        if (form.mandatory) {
                            if (form.phoneNumber) {
                                this.setState({
                                    checkMandatoryFormPhone: false
                                })
                            } else {
                                this.setState({
                                    checkMandatoryFormPhone: true
                                })
                            }
                        }
                        break
                    case 'radio' :
                        if (form.mandatory) {
                            if (form.options && form.options.length > 0 && form.singleChoice) {
                                this.setState({
                                    checkMandatoryFormRadio: false
                                })
                            } else if (form.options && form.options.length > 0) {
                                this.setState({
                                    checkMandatoryFormRadio: true
                                })
                            }
                        }
                        break
                    case 'dropdown-select' :
                        if (form.mandatory) {
                            if (form.options && form.options.length > 0 && form.dropdown) {
                                this.setState({
                                    checkMandatoryFormDropdown: false
                                })
                            } else if (form.options && form.options.length > 0) {
                                this.setState({
                                    checkMandatoryFormDropdown: true
                                })
                            }
                        }
                        break
                    case 'multiple-choice' :
                        if (form.mandatory) {
                            if (form.options && form.options.length > 0 && form.multipleChoice && form.multipleChoice.length > 0) {
                                this.setState({
                                    checkMandatoryFormMultiple: false
                                })
                            } else if (form.options && form.options.length > 0) {
                                this.setState({
                                    checkMandatoryFormMultiple: true
                                })
                            }
                        }
                        break
                    case 'signature' :
                        if (form.mandatory) {
                            if (form.signature) {
                                this.setState({
                                    checkMandatoryFormSignature: false
                                })
                            } else {
                                this.setState({
                                    checkMandatoryFormSignature: true
                                })
                            }
                        }
                        break
                    case 'upload' :
                        if (form.mandatory) {
                            if (form.fileSectionDTOS && form.fileSectionDTOS.length > 0) {
                                this.setState({
                                    checkMandatoryFormUpload: false
                                })
                            } else {
                                this.setState({
                                    checkMandatoryFormUpload: true
                                })
                            }
                        }
                        break
                    default: break
                }
            })
        })

    }
    render() {
        const { listComponentsTemplateForm, formerEmployee, checkMandatoryForm, checkMandatoryFormShort, checkMandatoryFormLong,
            checkMandatoryFormAddress, checkMandatoryFormEmail, checkMandatoryFormPhone, checkMandatoryFormRadio, checkMandatoryFormDropdown, checkMandatoryFormMultiple,
            checkMandatoryFormSignature, checkMandatoryFormUpload, responseFeedback, formId, workflowComponentId, ticketId} = this.state;
        // console.log('listComponentsTemplateForm ---  RENDER', listComponentsTemplateForm)
        // console.log('responseFeedback ---  RENDER', responseFeedback)
        // console.log('checkMandatoryForm ---  RENDER', checkMandatoryForm)
        return (
            <div className="external-workflow-ticket">
                <div className="external-workflow-ticket-page">
                    { listComponentsTemplateForm && listComponentsTemplateForm.length > 0 ?
                        <div className="external-workflow-ticket-content">
                            <div className="external-workflow-ticket-form">
                                {listComponentsTemplateForm && listComponentsTemplateForm.length > 0 && listComponentsTemplateForm.map((list, index) => {
                                    return (
                                        this.generateComponentsFormTemplate(formerEmployee, list, index)
                                    )
                                })
                                }
                            </div>
                            <div className="external-save-button-content">
                                <Button onClick={(e) => this.onSaveExternalFeedbackWorkflow(e)}
                                        disabled={checkMandatoryForm === true || checkMandatoryFormShort === true || checkMandatoryFormLong === true
                                        || checkMandatoryFormAddress === true || checkMandatoryFormEmail === true || checkMandatoryFormPhone === true
                                        || checkMandatoryFormRadio === true || checkMandatoryFormDropdown === true || checkMandatoryFormMultiple === true
                                        || checkMandatoryFormSignature === true}
                                        className="external-save-button">Save</Button>
                            </div>
                        </div> :
                        <div className="external-workflow-ticket-content">
                            { responseFeedback && responseFeedback.title && responseFeedback.title === 'Info' &&
                                <div className="external-workflow-ticket-completed">
                                     <span className="message-info">{responseFeedback.message}</span>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        )
    }
}


//export default connect()(ExternalFeedbackWorkflowTicketForm)
ExternalFeedbackWorkflowTicketForm = withRouter(ExternalFeedbackWorkflowTicketForm)
const mapStateToProps = (state) => ({

})
export default connect(mapStateToProps)(ExternalFeedbackWorkflowTicketForm)