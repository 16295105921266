import React from 'react'
import { getDraggableItemStyle, reorderDraggableCards } from '../../../utils'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import PropTypes from 'prop-types'
import Column from './Column'
import {
  addFieldNameForAssetRegisterApp,
  getAllFieldNamesByClientIdForAssetRegisterApp,
  removeFieldNameForAssetRegisterApp
} from '../../../api/AssetRegister'
import {
  addFieldNameForDocLockerApp,
  getAllFieldNamesByClientIdForDocLockerApp,
  removeFieldNameForDocLockerApp
} from '../../../api/DocLocker'

class ColumnsContainer extends React.Component {
  state = {
    columns: [],
    fieldNameOptions: [],
    isDragging: true
  }

  constructor(props) {
    super(props)
    this.onDragEnd = this.onDragEnd.bind(this)
  }

  componentDidMount() {
    if (this.props.columns) {
      this.setState({
        columns: this.props.columns
      })
    }

    if (this.props.clientId) {
      this.fetchData()
    }
  }
  
  fetchData = () => {
    if (this.props.application === 'asset-register') {
      getAllFieldNamesByClientIdForAssetRegisterApp(this.props.clientId).then((res) => {
        if (res.data) {
          this.setState({
            fieldNameOptions: res.data
          })
        }
      })
    } else {
      getAllFieldNamesByClientIdForDocLockerApp(this.props.clientId).then((res) => {
        if (res.data) {
          this.setState({
            fieldNameOptions: res.data
          })
        }
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.columns !== this.props.columns) {
      this.setState({
        columns: this.props.columns
      })
    }

    if (prevProps.clientId !== this.props.clientId) {
      this.fetchData()
    }
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const columns = reorderDraggableCards(
      this.state.columns,
      result.source.index,
      result.destination.index
    )

    this.setState({
      columns,
      isDraggable: true
    }, () => this.props.updateItems(columns))
  }

  addColumn = () => {
    const newColumn = {
      name: '',
      domId: this.state.columns.length + 1,
      required: true,
      additional: false,
      columnOrder: this.state.columns.length + 1,
      type: '',
      options: [],
      history: false
    }
    this.setState({
      columns: [ ...this.state.columns, newColumn ]
    }, () => this.props.updateItems(this.state.columns))
  }

  removeColumn = () => {
    const valueToRemove = this.state.columns.pop()
    this.setState({
      columns: this.state.columns.filter((field) => field.domId
        !== valueToRemove.domId)
    }, () => this.props.updateItems(this.state.columns, valueToRemove))
  }

  addColumnName = (record, value) => {
    const index = this.state.columns.findIndex((field) => field.domId === record.domId)
    const newArray = [ ...this.state.columns ]
    newArray[index].name = value
    this.setState({
      columns: newArray
    }, () => this.props.updateItems(this.state.columns))
  }

  addColumnType = (record, value) => {
    const index = this.state.columns.findIndex((field) => field.domId === record.domId)
    const newArray = [ ...this.state.columns ]
    newArray[index].type = value
    this.setState({
      columns: newArray
    }, () => this.props.updateItems(this.state.columns))
  }

  addOptions = (record, value, item) => {
    const index = this.state.columns.findIndex((field) => field.domId === record.domId)
    const newArray = [ ...this.state.columns ]
    newArray[index].options = value
    this.setState({
      columns: newArray
    }, () => this.props.updateItems(this.state.columns, '', item))
  }

  addDateFormat = (record, value) => {
    const index = this.state.columns.findIndex((field) => field.domId === record.domId)
    const newArray = [ ...this.state.columns ]
    newArray[index].dateFormat = value
    this.setState({
      columns: newArray
    }, () => this.props.updateItems(this.state.columns))
  }

  updateNameOptions = (optionsArray, item, action) => {
    if (action === 'add') {
      item.clientId = this.props.clientId
      if (this.props.application === 'asset-register') {
        addFieldNameForAssetRegisterApp(item).then((res) => {
          if (res.data) {
            const index = this.state.fieldNameOptions.findIndex((field) => field.name === res.data.name)
            const newArray = [ ...this.state.fieldNameOptions ]
            newArray[index] = res.data
            this.setState({
              fieldNameOptions: newArray
            })
          }
        }
        )
      } else {
        addFieldNameForDocLockerApp(item).then((res) => {
          if (res.data) {
            const index = this.state.fieldNameOptions.findIndex((field) => field.name === res.data.name)
            const newArray = [ ...this.state.fieldNameOptions ]
            newArray[index] = res.data
            this.setState({
              fieldNameOptions: newArray
            })
          }
        }
        )
      }
    } else if (action === 'remove') {
      if (this.props.application === 'asset-register') {
        removeFieldNameForAssetRegisterApp(item.id).then(() => {
          this.setState({
            fieldNameOptions: optionsArray
          })
        })
      } else {
        removeFieldNameForDocLockerApp(item.id).then(() => {
          this.setState({
            fieldNameOptions: optionsArray
          })
        })
      }
    }
  }

  enableDragging = () => {
    this.setState({
      isDragging: false
    })
  }

  disableDragging = () => {
    this.setState({
      isDragging: true
    })
  }

  render() {
    const { columns, fieldNameOptions, isDragging } = this.state
    const { application } = this.props
    return (
      columns && columns.length > 0 ?
        <React.Fragment>
          <div className="columns-section__header">
            <span className="text">COLUMN</span>
            <span className="actions">
              <button className="icon-add-b" onClick={ () => this.addColumn() }
                disabled={ application === 'asset-register' ? columns.length === 5 : columns.length === 6 }/>
              <button className="icon-minus-b" onClick={ () => this.removeColumn() } disabled={ columns.length <= 3 }/>
            </span>
          </div>
          <div className="columns-section__content">
            <DragDropContext onDragEnd={ this.onDragEnd }>
              <Droppable droppableId="droppable" direction="horizontal">
                { (provided, snapshot) => (
                  <div
                    className="columns-container"
                    ref={ provided.innerRef }
                    { ...provided.droppableProps }
                    // style={getListStyle(snapshot.isDraggingOver)}
                  >
                    { columns.map((item, index) => (
                      <Draggable key={ item.domId || item.id } draggableId={ item.domId || item.id } index={ index }
                        isDragDisabled={ isDragging }>
                        { (provided, snapshot) => (
                          <div
                            className="column"
                            ref={ provided.innerRef }
                            { ...provided.draggableProps }
                            { ...provided.dragHandleProps }
                            style={ getDraggableItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            ) }>
                            <Column column={ item } onChangeName={ this.addColumnName }
                              onChangeColumnType={ this.addColumnType }
                              onChangeOptions={ this.addOptions }
                              onChangeDateFormat={ this.addDateFormat }
                              fieldNameOptions={ fieldNameOptions }
                              onChangeNameOptions={ this.updateNameOptions }
                              disableDragging={ this.disableDragging }
                              enableDragging={ this.enableDragging }
                              updateMode={ this.props.updateMode }
                              application={ this.props.application }/>
                          </div>
                        ) }
                      </Draggable>
                    )) }
                    { provided.placeholder }
                  </div>
                ) }
              </Droppable>
            </DragDropContext>
          </div>
        </React.Fragment>
        :
        ''
    )
  }
}

ColumnsContainer.propTypes = {
  columns: PropTypes.array.isRequired,
  updateItems: PropTypes.func.isRequired,
  clientId: PropTypes.number.isRequired,
  application: PropTypes.string.isRequired,
  updateMode: PropTypes.bool.isRequired
}

export default ColumnsContainer