import React from 'react'
import PropTypes from 'prop-types'

import TopicItem from './TopicItem'

const TopicList = ({ topics }) => (
  <div id="topic-list" className="topicList-wrapper">
    { topics.map((topic, index) => (
      <TopicItem key={ index } topic={ topic } />
    )) }
  </div>
)

TopicList.propTypes = {
  topics: PropTypes.array.isRequired
}

export default TopicList