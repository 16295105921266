import initialState from './initialState'
import { HIDE_MESSAGE, SHOW_MESSAGE } from '../actions/actionTypes'

export default (state = initialState, action) => {
  switch (action.type) {
    case HIDE_MESSAGE:
      return {
        ...state,
        messageArea: {
          status: '',
          text: '',
          visible: false
        }
      }

    case SHOW_MESSAGE:
      return {
        ...state,
        messageArea: {
          status: action.message.status,
          text: action.message.text,
          visible: true
        }
      }
      
    default:
      return state
  }
}