import React from 'react'
import ObjectTypeList from '../Form/ObjectTypeList'

import { doFilterFacetValueFor } from '../../../utils'

import './AddObjectTypes.css'

class AddObjectTypes extends React.Component {
  setObjectTypeRule = (event) => {
    const index = event.target.name.replace('objectype-rule-', '')
    const newObjects = [...this.props.objectTypes]
    newObjects[index] = {
      ...this.props.objectTypes[index],
      rule: event.target.value
    }
    this.props.updateObjectTypes(newObjects)
  }

  setObjectTypeKind = (event) => {
    const index = event.target.name.replace('objectype-kind-', '')
    const selectedValue = event.target.value
    const filteredFacetValues = selectedValue === ''
      ? []
      : doFilterFacetValueFor(this.props.facetValues, selectedValue)

    const newObjects = [...this.props.objectTypes]
    newObjects[index] = {
      ...this.props.objectTypes[index],
      kind: selectedValue,
      value: '',
      filteredFacetValues
    }
    this.props.updateObjectTypes(newObjects)
  }

  setObjectTypeValue = (event) => {
    const index = event.target.name.replace('objectype-value-', '')
    const newObjects = [...this.props.objectTypes]
    newObjects[index] = {
      ...this.props.objectTypes[index],
      value: event.target.value
    }
    this.props.updateObjectTypes(newObjects)
  }

  render = () => {
    return (
      <React.Fragment>
        { this.props.objectTypes.map((field, index) => (
          <div className="addObjectTypes__wrapper" key={ index }>
            <div>
              <select
                name={ `objectype-rule-${ index }` }
                value={ field.rule }
                onChange={ this.setObjectTypeRule }>
                <option value="">Choose...</option>
                <option value="exclude">Exclude</option>
                <option value="include">Include</option>
              </select>
            </div>
            <div>
              { field.rule
                ? <ObjectTypeList
                  filterByGroup='Basic'
                  index={ index }
                  value={ field.kind }
                  onChange={ this.setObjectTypeKind } />
                : <select disabled>
                  <option value="">Choose...</option>
                </select>
              }
            </div>
            <div>
              { !field.kind &&
                <select disabled>
                  <option value="">Choose...</option>
                </select>
              }

              { field.kind &&
                field.filteredFacetValues.length > 0 &&
                <select
                  name={ `objectype-value-${ index }` }
                  value={ field.value }
                  onChange={ this.setObjectTypeValue }>
                  <option value="">All Object Types</option>
                  { field.filteredFacetValues.map((facet, index) => (
                    <option key={ index } value={ facet.id }>{ facet.name }</option>
                  ))}
                </select>
              }

              { field.kind &&
                field.filteredFacetValues.length === 0 &&
                <input
                  name={ `objectype-value-${ index }` }
                  type='text'
                  value={ field.value }
                  onChange={ this.setObjectTypeValue } />
              }
            </div>
          </div>
        ))}
      </React.Fragment>
    )
  }
}

export default AddObjectTypes