import React from 'react'
import { connect } from 'react-redux'
import { Input, Radio, Checkbox, Tooltip, Button, Modal, Icon } from 'antd'
import { customConfirm } from '../../../utils/AssetRegister/CustomConfirm'
import {
  getAllBusinessList,
  getTermsFromBusinessList,
  getAndSaveLogExportBusinessList,
  editBusinessTermList} from '../../../api/SmartBox'
import CSVReader from 'react-csv-reader'
import './SmartBoxBusinessTermLists.scss'
import { CSVLink } from "react-csv"
import CustomNotificationManager from "../../Common/CustomNotificationManager"
import withRouter from '../../../private-routers/withRouter'

class SmartBoxBusinessTermList extends React.Component {
  state = {
    activeList: {},
    terms: [],
    termsAfterSearch: [],
    loading: false,
    searchTerm: '',
    searchIconClicked: false,
    csvData: [],
    loadingExport: false,
    termsByLetter: {},
    selectedTerms: [],
    hoverAdd: false,
    hoverDelete: false,
    modalVisible: false,
    hoverAddModal: false,
    hoverRemoveModal: false,
    termsCount: 1,
    newTerms: [''],
    errorAddTerm: false,
    onActiveImport: false,
    onActiveExport: false,
    onHoverBack: false,
    checkedSelectAll: false,
  }
  lettersCategory = ['#','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']

  constructor() {
    super()
    this.ref = {}
    this.lettersCategory.forEach(letter => {
      this.ref[letter] = React.createRef();
    })
  }
  componentDidMount() {
    this.fetchLists()
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if(window.scrollY >= 115) {
      document.querySelector('body').classList.add('scrolling');
    } else {
      document.querySelector('body').classList.remove('scrolling');
    }
  }
  fetchLists = () => {
    const id = this.props.params.id

    getAllBusinessList().then( (response) => {
      if (response.status === 200 && response.data.length) {
        let activeList = response.data.filter(item => item.id === Number(id))
        activeList = activeList[0]

        this.setState({
          activeList
        }, () => {
          this.fetchTermsForList()
        })
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  fetchTermsForList = () => {
    //const { businessTermsPageLimit } = this.props
    const { searchTerm, activeList } = this.state
    const searchNameTerm = searchTerm.trim()

    const data = {
      listId: activeList.id,
      selectedLetter: '',
      searchTerm: searchNameTerm,
      pageNumber: 1,
      amountPerPage: 200 //businessTermsPageLimit
    }
    getTermsFromBusinessList(data).then((response) => {
      if (response.status === 200) {
        let termsByLetter = { }
        if (response.data.terms && response.data.terms.length) {
          response.data.terms.forEach((item) => {
            let letter
            if(item.term !== null) {
              const charCode = item.term.charCodeAt(0)
              letter = charCode >= 65 && charCode <= 122 ? item.term.charAt(0).toUpperCase() : '#'
            } else {
              letter = '#'
            }
            if (typeof termsByLetter[letter] === 'undefined') {
              termsByLetter[letter] = []
            }
            termsByLetter[letter].push(item)
          })
        }
        this.setState({
          terms: response.data.terms ? response.data.terms : [],
          totalPages: response.data.total,
          loading: false,
          deletedTermIds: [],
          newTerms: [],
          newTermsCount: 0,
          termsByLetter
        })
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  increaseTermsCount = () => {
    const { termsCount, newTerms } = this.state
    this.setState({
      termsCount: termsCount + 1,
      newTerms: newTerms.concat([''])
    })
  }

  decreaseTermsCount = () => {
    const { termsCount, newTerms } = this.state
    if(termsCount > 1) {
      this.setState({
        termsCount: termsCount - 1,
        newTerms: newTerms.slice(0, newTerms.length - 1)
      })
    }
  }

  scroll(ref) {
    document.getElementsByTagName('html')[0].scrollTo({top: ref.current.offsetTop - 230, behavior: 'smooth'})
  }
  deleteTerms = () => {
    const { activeList, selectedTerms, terms } = this.state
    let deletedIds = []
    selectedTerms.forEach(item => {
      deletedIds.push(item.id)
    })

    const data = {
      listId: activeList.id,
      deletedIds,
      newTerms: []
    }
    editBusinessTermList(data).then( (response) => {
      if (response.status === 200) {
        let { termsByLetter } = this.state
        this.lettersCategory.forEach(letter => {
          if(typeof termsByLetter[letter] !== 'undefined') {
            termsByLetter[letter] = termsByLetter[letter].filter(item => !deletedIds.includes(item.id))
          }
        })
        this.setState({
          terms: terms.filter(item => !deletedIds.includes(item.id)),
          termsByLetter
        })
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  showConfirmDelete = () => {
    const temp = this
    const { selectedTerms } = this.state

    if(selectedTerms.length === 0) return

    customConfirm({
      confirmAction: () => temp.deleteTerms() ,
      title: '',
      content:
        <React.Fragment>
          <div className={"delete-dict-title"}>
            <div className={"icon-del icon-delete-b"}></div>
            <div className={"delete-dict-q"}>{'Delete '+selectedTerms.length + (selectedTerms.length === 1 ? ' term' : ' terms') +' from your dictionary?'}</div>
          </div>
        </React.Fragment>,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      mask: true,
      maskClosable: false,
      closeHaveLogic: false,
      className: 'delete-dict-modal',
      centered: true,
      cancelClassName: 'cancel-button',
      confirmClassName: 'confirm-button'
    })
  }
  
  handleSearchInputChange = (e) => {
    const searchedText = e.target.value
    this.setState({
      searchTerm: searchedText
    })
  }

  handlerSearchEnter = (e) => {
    if (e.key === 'Enter') {
      this.fetchTermsOnSearch()
    }
  }

  fetchTermsOnSearch = () => {
    const { searchTerm, terms } = this.state
    const termsAfterSearch = terms.filter(item => item.term.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1)
    this.setState({
      termsAfterSearch,
      searchIconClicked: true
    })
  }
  clearSearch = () => {
    this.setState({
      searchTerm: '',
      termsAfterSearch: [],
      searchIconClicked: false
    })
  }

  onSearchInput = (e) => {
    const { searchTerm, terms } = this.state
    if (e !== '') {
      const termsAfterSearch = terms.filter(item => item.term.toLowerCase().indexOf(e.toLowerCase()) !== -1)
      this.setState({
        termsAfterSearch,
        searchIconClicked: true
      })
    }
  }

  onDropCsvFile = (file) => {
    const { activeList } = this.state
    const newTerms = []
    file.forEach(function(row, index) {
      if (index) {
        const temp = {}
        if (row[0] && row[0].trim()) {
          temp.term = row[0]
          temp.list = activeList
          newTerms.push(row[0])
        }
      }
    })
    const data = {
      listId: activeList.id,
      deletedIds: [],
      newTerms: newTerms
    }
    editBusinessTermList(data).then( (response) => {
      if (response.status === 200) {
        this.fetchTermsForList()
        CustomNotificationManager.success("File was uploaded successfully", "Upload successfully")
      }
    }).catch( (error) => {
      CustomNotificationManager.error("Items should be valid and unique", "Upload fail")
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  exportToCsv = (event) => {
    const { activeList, selectedLetter } = this.state
    const csvData = []
    this.setState({loadingExport: true})
    if(!this.state.csvData.length) {
      CustomNotificationManager.warning('No data available to export.', 'No Data')
      event.preventDefault()
      const data = {
        listId: activeList.id,
        selectedLetter: selectedLetter === 'all' ? '' : selectedLetter,
        searchTerm: '',
        pageNumber: 1,
        amountPerPage: 9999
      }
      getTermsFromBusinessList(data).then((response) => {
        if (response.status === 200) {
          if (response.data.terms && response.data.terms.length) {
            response.data.terms.map( (item) => {
              csvData.push([item.term])
              return true
            })
            this.setState({ csvData })
          }
          setTimeout(() => {
            this.setState({loadingExport: false})
          }, 800)
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    }
    setTimeout(() => {
      this.setState({loadingExport: false})
    }, 1000)
  }

  addOrRemoveTermFromSelected = (e, item) => {
    let { selectedTerms } = this.state
    const { terms } = this.state
    let csvData = []
    //const found = selectedTerms.find(selectedItem => selectedItem.id === item.id)

    if(e.target.checked) {
      selectedTerms.push(item)
    } else {
      selectedTerms = selectedTerms.filter(selectedItem => selectedItem.id !== item.id)
    }
    selectedTerms.forEach(selectedItem => {
      csvData.push([selectedItem.id, selectedItem.term])
    })
    this.setState({selectedTerms, csvData}, () => {
      if (selectedTerms.length === 0) {
        this.setState({
          checkedSelectAll: false
        })
      } else if (selectedTerms.length > 0 && terms.length > 0 && selectedTerms.length === terms.length) {
        this.setState({
          checkedSelectAll: true
        })
      } else if (selectedTerms.length > 0 && terms.length > 0 && selectedTerms.length < terms.length) {
        this.setState({
          checkedSelectAll: false
        })
      }
    })
  }

  toggleSelectAllTerms = (e) => {
    const { terms } = this.state
    let selectedTerms = []
    let csvData = []
    if(e.target.checked) {
      selectedTerms = terms
      selectedTerms.forEach(selectedItem => {
        csvData.push([selectedItem.id, selectedItem.term])
      })
    } else {
      selectedTerms = []
      csvData = []
    }
    this.setState({ selectedTerms, csvData, checkedSelectAll: e.target.checked })
  }

  toggleValue(field, val) {
    switch(field) {
      case 'delete':
        this.setState({hoverDelete: val})
        break
      case 'add':
        this.setState({hoverAdd: val})
        break
      case 'modalVisible':
        this.setState({modalVisible: val})
        break
      case 'add-modal':
        this.setState({hoverAddModal: val})
        break
      case 'remove-modal':
        this.setState({hoverRemoveModal: val})
        break
      default: break
    }
  }

  getTermCheckbox = (item) => {
    const { selectedTerms } = this.state
    return <Checkbox key={"chk-"+item.id} checked={ typeof selectedTerms.find(selectedItem => selectedItem.id === item.id) !== 'undefined'}
                     onChange={(e) => this.addOrRemoveTermFromSelected(e,item)}>{item.term}</Checkbox>
  }
  renderLetterLine = (letter, keyLetter) => {
    let { termsByLetter } = this.state
    termsByLetter = termsByLetter[letter]

    let col1Terms, col2Terms, col3Terms, col4Terms

    if(typeof termsByLetter !== 'undefined') {
      const countPerColumn = termsByLetter.length > 4 ? Math.ceil(termsByLetter.length / 4) : 1
      col1Terms = termsByLetter.slice(0, countPerColumn)
      col2Terms = termsByLetter.slice(countPerColumn, countPerColumn * 2)
      col3Terms = termsByLetter.slice(countPerColumn * 2, countPerColumn * 3)
      col4Terms = termsByLetter.slice(countPerColumn * 3, countPerColumn * 4)
    } else {
      col1Terms = col2Terms = col3Terms = col4Terms = []
    }

    return (
      <div className={"letter-area"+(letter === '#' ? ' first' : '')} key={'letter-line-'+letter} ref={this.ref[letter]}>
        <div key={ keyLetter } data-empty={col1Terms.length === 0 ? 1 : 0}>{letter}</div>
        { col1Terms.length > 0 &&
          <React.Fragment>
            <div>{ col1Terms.map(item => this.getTermCheckbox(item))}</div>
            <div>{ col2Terms.map(item => this.getTermCheckbox(item)) }</div>
            <div>{ col3Terms.map(item => this.getTermCheckbox(item)) }</div>
            <div>{ col4Terms.map(item => this.getTermCheckbox(item)) }</div>
          </React.Fragment>
        }
      </div>
    )
  }
  renderTermsAfterSearch = () => {
    const { termsAfterSearch } = this.state
    let col1Terms, col2Terms, col3Terms, col4Terms, col5Terms

    const countPerColumn = termsAfterSearch.length > 5 ? Math.ceil(termsAfterSearch.length / 5) : 1
    col1Terms = termsAfterSearch.slice(0, countPerColumn)
    col2Terms = termsAfterSearch.slice(countPerColumn, countPerColumn * 2)
    col3Terms = termsAfterSearch.slice(countPerColumn * 2, countPerColumn * 3)
    col4Terms = termsAfterSearch.slice(countPerColumn * 3, countPerColumn * 4)
    col5Terms = termsAfterSearch.slice(countPerColumn * 4, countPerColumn * 5)

    return (
      <div className={"letter-area after-search-area"}>
        <div>{ col1Terms.map(item => this.getTermCheckbox(item))}</div>
        <div>{ col2Terms.map(item => this.getTermCheckbox(item)) }</div>
        <div>{ col3Terms.map(item => this.getTermCheckbox(item)) }</div>
        <div>{ col4Terms.map(item => this.getTermCheckbox(item)) }</div>
        <div>{ col5Terms.map(item => this.getTermCheckbox(item)) }</div>
      </div>
    )
  }
  updateNewTerms = (event, index) => {
    const { newTerms } = this.state
    newTerms[index] = event.target.value
    this.setState({ newTerms })
  }

  updateDeleteInputPlace = (event, index) => {
    const { newTerms } = this.state
    newTerms[index] = ''
    this.setState({ newTerms, errorAddTerm: false, })
  }

  renderTermsInput = () => {
    const { termsCount, newTerms } = this.state
    let inputs = []
    for (let i=0; i< termsCount; i++) {
      if(i === 0) {
        inputs = inputs.concat([
         <span key={i} className="content-input-add-term"><input type="text" className={"ant-input"} key={ 'input-' + termsCount } onChange={ (e) => this.updateNewTerms(e, i) }
                placeholder="Term" value={newTerms[i] || ''}
                 ref={el => this.firstInput = el} />
           { newTerms[i] !== '' &&
             <span key={'input-' + i} className="icon-v17-close-black"
                   onClick={(e) => this.updateDeleteInputPlace(e, i)}></span>
           }
         </span> ])
      } else {
        inputs = inputs.concat([
          <span key={i} className="content-input-add-term">
          <input type="text" className={"ant-input"} key={ 'input-' + termsCount } onChange={ (e) => this.updateNewTerms(e, i) }
                 placeholder="Term" value={newTerms[i] || ''}/>
            { newTerms[i] !== '' &&
            <span key={'input-' + i} className="icon-v17-close-black"
                  onClick={(e) => this.updateDeleteInputPlace(e, i)}></span>
            }
          </span>])
      }
    }
    return <React.Fragment>{inputs}</React.Fragment>
  }
  addNewTermsToDictionary = () => {
    const { activeList, newTerms } = this.state
    const filtered = newTerms.filter(function (el) {
      return el != '';
    });
    const data = {
      listId: activeList.id,
      deletedIds: [],
      newTerms: filtered
    }
    editBusinessTermList(data).then( (response) => {
      if (response.status === 200) {
        this.setState({
          modalVisible: false,
          errorAddTerm: false
        }, () => {
          this.fetchTermsForList()
        })
      }
    }).catch((error) => {
      if(error?.response?.status === 500) {
        this.setState({ errorAddTerm: true })
      }else if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  displayAddTermsModal = () => {
    this.setState({
      modalVisible: true,
      termsCount: 1,
      newTerms: ['']
    }, () => {
      if(typeof this.firstInput !== 'undefined') {
        this.firstInput.value = ''
      }
    })
  }

  hideAddTermsModal = () => {
    this.setState({
      modalVisible: false,
      termsCount: 1,
      newTerms: [''],
      errorAddTerm: false,
    }, () => { this.firstInput.value = '' })
  }
  onMouseEnterActions = (e, status) => {
    switch (status) {
      case 'import':
        this.setState({
          onActiveImport: true
        })
        break
      case 'export':
        this.setState({
          onActiveExport: true
        })
        break
      case 'back':
        this.setState({
          onHoverBack: true
        })
        break
      default:
        break
    }
  }
  onMouseLeaveActions = () => {
    this.setState({
      onActiveImport: false,
      onActiveExport: false,
      onHoverBack: false
    })
  }
  render() {
    const { termsByLetter, selectedTerms, termsAfterSearch, searchIconClicked, searchTerm, selectedLetter, activeList, hoverAdd, hoverDelete,modalVisible, hoverAddModal, hoverRemoveModal, termsCount, newTerms, errorAddTerm, onActiveImport,
      onActiveExport, onHoverBack, checkedSelectAll } = this.state
    //const { businessTermsPageLimit } = this.props
    let { terms, csvData } = this.state
    const { Search } = Input
    if(csvData.length) {
      csvData = [...[['Id', 'Name']], ...csvData]
    }
    const csvDataHeader = [
      { label: 'Terms', key: 'term' },
    ]
    return (
      <div className="smart-box-btl__container smart-box-btl-container-update">
        <div className="box">
          <div className="update-box-title">
            <div className="box-title">Manage dictionary</div>
            <Tooltip overlayClassName="help-message-locations" title={<div className="content-help-message-locations">
              <span className="text-information">Add or remove terms.</span>
              <span className="more-info-link" onClick={ () => window.open('https://support.smartbox.ai/support/solutions/articles/47001204505', '_blank') }>More info.</span>
            </div>}>
              <span className="icon-v14-question"></span>
            </Tooltip>
          </div>
          {/*<h3>Delete terms from your dictionary or upload new terms.</h3>*/}
          <div className={"btl-topbar"}>
            <div className="btl-topbar-contain"
                 onMouseEnter={(e) => this.onMouseEnterActions(e, 'back')}
                 onMouseLeave={() => this.onMouseLeaveActions()}>
            <span className={ onHoverBack ? 'icon-v21-back-active' : "icon-v17-back-1" }
                  onClick={() => this.props.navigate('/smart-box/business-term-lists/settings')}></span>
            <div className={"btl-name"} onClick={() => this.props.navigate('/smart-box/business-term-lists/settings')}>{ activeList.listName }</div>
            <div className={"btl-description"} onClick={() => this.props.navigate('/smart-box/business-term-lists/settings')}>{ activeList.description }</div>
            </div>
            <div className={"actions"}>
              <Checkbox checked={ checkedSelectAll } onChange={(e) => this.toggleSelectAllTerms(e)}
                        className={selectedTerms.length > 0 && terms.length > 0 && selectedTerms.length < terms.length ? "select-all-dictionaries select-all-dictionaries-unchecked" : "select-all-dictionaries" }>Select all</Checkbox>
              <button className={'icon-v17-delete'+(hoverDelete ? '-active' : '')} onMouseEnter={() => this.toggleValue('delete',true)}
                      onMouseLeave={() => this.toggleValue('delete',false)} disabled={selectedTerms.length === 0}
                      onClick={() => this.showConfirmDelete()}></button>
              <Tooltip title={"Add term"}>
                <button className={'icon-v17-add'+(hoverAdd ? '-active' : '')} onMouseEnter={() => this.toggleValue('add',true)}
                        onMouseLeave={() => this.toggleValue('add',false)}
                        onClick={() => this.displayAddTermsModal()}></button>
              </Tooltip>
              <CSVReader
                onFileLoaded={this.onDropCsvFile}
                inputId="file_csv_upload"
                label={<Tooltip title={"Import"}><div className={ onActiveImport ? 'icon-v17-import-active-1' : "icon-v17-import-1"}
                                                      onMouseEnter={(e) => this.onMouseEnterActions(e, 'import')}
                                                      onMouseLeave={() => this.onMouseLeaveActions()}></div></Tooltip>}
              />
              <Tooltip title={"Export"}>
                <CSVLink data={terms.map((x) => ({ term: x.term }))} filename={`${activeList.listName}.csv`} header={ csvDataHeader }>
                  <button className={onActiveExport ? 'icon-v17-export-active-1' : "icon-v17-export-1"}
                          onMouseEnter={(e) => this.onMouseEnterActions(e, 'export')}
                          onMouseLeave={() => this.onMouseLeaveActions()}
                          onClick={() =>  getAndSaveLogExportBusinessList(activeList.id)}
                  ></button>
                </CSVLink>
              </Tooltip>
              <div className={"search-content"}>
                <Search value={ searchTerm } placeholder={'Search'} onChange={ (e) => this.handleSearchInputChange(e) } onKeyPress={ (e) => this.handlerSearchEnter(e) }
                onSearch={(e) => this.onSearchInput(e)}/>
                { searchTerm && searchIconClicked &&
                  <span className={"remove-search-term icon-v17-close-black"} onClick={() => this.clearSearch()}></span> }
                {/*<div className="icon-wrapper" onClick={ () => this.fetchTermsOnSearch() }>*/}
                {/*  <Icon type="search" />*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
          { terms.length === 0 && <div>Dictionary empty. Please add terms.</div> }
          { terms.length > 0 &&
            <div className={"btl-terms"}>
              <Radio.Group key="5" value={ selectedLetter } className="btl-letters">
                {this.lettersCategory.map((item) => {
                  return <Radio.Button key={item} value={item.toLowerCase()} onClick={() => this.scroll(this.ref[item])}
                                       disabled={typeof termsByLetter[item] === 'undefined' || termsByLetter[item].length === 0}>{item}</Radio.Button>
                })
                }
              </Radio.Group>
            </div>
          }
          { terms.length > 0 && termsAfterSearch.length === 0 && this.lettersCategory.map((letter, keyLetter) => this.renderLetterLine(letter, keyLetter))}
          { termsAfterSearch.length > 0 && this.renderTermsAfterSearch() }
        </div>
        <Modal className="dictionary-modal" title={ <span className={'add-term-modal-title'}>Add term to dictionary</span> }
               visible={ modalVisible } onCancel={() => this.toggleValue('modalVisible',false)} footer={ null } closable={false} >
          <div className="import-terms-section">
            <form className="import-terms-form">
              { errorAddTerm && <div className={"error"}>Terms must be unique!</div> }
              <div className={"dict-modal-line no-flex"}>{ this.renderTermsInput() }</div>
              <div className={"modal-action-btns"}>
                <div className={"icon-v17-minus"+(hoverRemoveModal ? '-active' : '')} disabled={ termsCount === 1 }
                     onMouseEnter={() => this.toggleValue('remove-modal',true)}
                     onMouseLeave={() => this.toggleValue('remove-modal',false)}
                     onClick={() => this.decreaseTermsCount()}></div>
                <div className={"icon-v17-add"+(hoverAddModal ? '-active' : '')} onMouseEnter={() => this.toggleValue('add-modal',true)}
                     onMouseLeave={() => this.toggleValue('add-modal',false)}
                     onClick={() => this.increaseTermsCount()}></div>
              </div>
              <div className="buttons">
                <Button className="transp-orange" onClick={ this.hideAddTermsModal }>Cancel</Button>
                <Button className="orange" type="submit" disabled={ typeof newTerms[0] === 'undefined' || newTerms[0] === '' }
                        onClick={ this.addNewTermsToDictionary } >Add</Button>
              </div>
            </form>
          </div>
        </Modal>
      </div>
    )
  }
}


SmartBoxBusinessTermList = withRouter(SmartBoxBusinessTermList)

const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id,
  // businessTermsPageLimit: state.smartBox.businessTermsPageLimit
})

export default connect(mapStateToProps, {
  // setBusinessTermsPageLimit
})(SmartBoxBusinessTermList)