import React from 'react'
import './UnlimitedAccount.scss'

const planEnterprise = 'You can create as many Business Processes, PIA and DPIAs as you like. You also have access to our PIA/DPIA builder.'
const planFree = 'You can create 2 Business Processes and own 2 Live SAR Trackers. Upgrade your plan to find out what else our product can do!'
const planProfessional = 'You can create up to 100 Business Processes and own up to 100 Live SAR Trackers. Upgrade to Enterprise for more!'
const planStarter = 'You can create up to 5 Business Processes and own up to 10 Live SAR Trackers. Upgrade your plan for extra features!'

const getPlan = (namePlan) => {
  switch (namePlan) {
    case 'ENTERPRISE':
      return planEnterprise
    case 'STARTER':
      return planStarter
    case 'PROFESSIONAL':
      return planProfessional
    case 'FREE':
      return planFree
    default:
      return null
  }
}

const UnlimitedAccount = (subscription) => (
  <div className="dpiaHelp__box">
    <header className="dpiaHelp__box-header">
      <h2>{ subscription.subscription.plan.name }</h2>
    </header>
    <div className="limitedFreeAccount-container">
      <h2 className="unLimitedAccount__title">You're subscribed to our <span className="styleSubscriptionPlan">{subscription.subscription.plan.name}</span> plan.</h2><br />
      <p className="limitedFreeAccount-text">{getPlan(subscription.subscription.plan.name)}
      </p>
    </div>
  </div>
)

export default UnlimitedAccount