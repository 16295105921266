import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Swal from 'sweetalert2'
import CustomNotificationManager from '../../../Common/CustomNotificationManager'
import * as manageGroupsActions from '../../../../actions/manageGroupsActions'
import * as messageActions from '../../../../actions/messageActions'
import { apiTeamAddMembers, apiTeamFetchTeamsList } from '../../../../api/Team'
import { apiUserResendInvite, apiUserUpdateRoles, apiUserFilterList, apiRemoveUser } from '../../../../api/User'
import { accountIsPaid, getErrorMessage, userIsAdmin, userIsSuperAdmin } from '../../../../utils'
import Page403 from '../../../Common/ErrorPage/Page403'
import '../../../Common/FontAwesome.min.css'
import { ManagerUsersHeader } from './Components/ManagerUsersHeader'
import { ManagerUsersFilters } from './Components/ManagerUsersFilters'
import ManagerUsersPagination from './Components/ManagerUsersPagination'
import ManagerUsersTable from './Components/ManagerUsersTable'
import '../../../../style/common.scss'
import './ManageUsers.scss'
import '../../ManageGroup/ManageGroups.css'

import UserTeamsModal from '../UserTeamsModal/UserTeamsModal'
//import {setSmartBoxNotAccessPermission} from "../../../../actions/smartBoxActions";
import * as smartBoxActions from '../../../../actions/smartBoxActions'
import moment from "moment";

export class ManageUsers extends Component {
  constructor() {
    super()
    this.state = {
      isSaving: false,
      isSending: {},
      messageStatus: {},
      messageText: {},
      showMessage: {},
      usersList: [],
      activePage: 1,
      resultsPerPage: 5,
      isOpenedTeamsModal: false,
      activeUser: false,
      activeTeam: false,
      teamsList: [],
      checked: false,
      allTeamsList: [],
      teamSelected: '',
      filterInput: '',
      userTypeAdmin: false,
      userType: null,
      userAccountActivated: null,
      isLoading: true,
      users: {},
      filterSort: null,
      filterSortDir: '',
      selectedUserName: '',
      selectedUserStatus: '',
      selectedUserId: '',
      poets: [],
      filteredPoets: [],
      inputValueSelect: '',
      teamSelectedName: '',
      classNameOverflowModal: '',
      numberOfUsers: 0,
      findText: '',
      classNameSwitcher: 'small-switcher',
      classNameSwitcherAdmin: 'small-switcherForAdmin',
      chkTeamsInit: {},
      chkTeams: {},
      openedMenuId: ''
    }

    this.saveChanges = this.saveChanges.bind(this)
    this.hideTeamsModal = this.hideTeamsModal.bind(this)
    this.showTeamsModal = this.showTeamsModal.bind(this)
    this.updateValue = this.updateValue.bind(this)
    this.updateValueTeam = this.updateValueTeam.bind(this)
    this.addTeamsToUser = this.addTeamsToUser.bind(this)
    this.confirmRolesUser = this.confirmRolesUser.bind(this)
    this.filtersTeams = this.filtersTeams.bind(this)
    this.loadOptions = this.loadOptions.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.updateChkValues = this.updateChkValues.bind(this)
    
  }

  get defaultState() {
    return {
      teamSelected: '',
      selectedTeamsModal: ''
    }
  }

  componentWillUnmount() {
    this.props.hideMessage()
    document.body.removeEventListener('click', this.closeOptionsDropdown)
  }
  
  componentDidMount() {
    window.scrollTo(0, 0)
    //this.apiUserFilterList()
    this.apiUserFilterListRenderMount()
    //this.fetchTeams()
    document.body.addEventListener('click', this.closeOptionsDropdown)
  }
  apiUserFilterListRenderMount(data) {
    data = {
      'userAuthoritiesEnum': this.state.userType,
      'accountActivated': this.state.userAccountActivated,
      'sortEnum': this.state.filterSort,
      'pageNumber': this.state.activePage - 1,
      'amountPerPage': this.state.resultsPerPage,
      'name': this.state.findText
    }

    this.setState({
      isLoading: true
    }, () => {
      apiUserFilterList(data)
          .then((response) => {
            let usersList
            response.data.totalElements>0 ? usersList = response.data.list : usersList = null
            this.setState({
              newList: response.data,
              isLoading: false,
              usersList: usersList,
              numberOfUsers: response.data.totalElements
            }, () => {
              this.fetchTeams()
            })
          })
    })

  }
  closeOptionsDropdown = () => this.setState({ openedMenuId : '' })

  initializationCheckedTeams(activeUser) {
    let response = {}
    this.state.usersList.forEach(function(entry) {
      if (entry.user.id === activeUser.id) {
        if (entry.teamsInformation.length > 0) {
          entry.teamsInformation.forEach(function(team) {
            response = { ...response, [team.id]: true }
          })
        }
      }
    })
    return response
  }

  updateChkValues(index, value) {
    const chkTeams = this.state.chkTeams
    this.setState({
      chkTeams: {
        ...chkTeams,
        [index]: value
      }
    })
  }

  showTeamsModal(e, activeUser) {
    const chkTeams= this.initializationCheckedTeams(activeUser)
    this.setState({
      isOpenedTeamsModal: true,
      activeUser: activeUser,
      classNameOverflowModal: document.body.style.overflow = 'hidden',
      chkTeamsInit: chkTeams,
      chkTeams: chkTeams,
      selectedUserId: activeUser.id
    })

  }

  hideTeamsModal() {
    this.setState({
      teamSelected: '',
      chkTeams: '',
      isOpenedTeamsModal: false,
      classNameOverflowModal: document.body.style.overflow = 'auto'
    })
  }

  onLastPage() {
    const users = this.state.usersList
    if (this.state.usersList === null) {
      this.setState({ activePage: 1 })
    } else if ((this.state.activePage * this.state.resultsPerPage) > (users.length/this.state.resultsPerPage)) {
      this.setState({ activePage: 1 })
    }
  }

  updateValueTeam(e, field) {
    const value = e.value
    const valueName = e.label

    if (field === 'teamSelected') {
      this.setState({
        teamSelected: value,
        teamSelectedName: valueName
      })
    } else {
      this.setState({
        teamSelected: ''
      })
    }
  }
  updateValue = (e, field) => {
    this.onLastPage()
    const value = e.target.value
    if (field === 'filterSort') {
      switch (value) {
        case 'asc': 
          this.setState({
            filterSort: 'ASC'
          }, () => this.apiUserFilterList())
          break
        case 'desc': 
          this.setState({
            filterSort: 'DESC'
          }, () => this.apiUserFilterList())
          break
        case 'last_login_asc':
          this.setState({
            filterSort: 'LAST_LOGIN_ASC'
          }, () => this.apiUserFilterList())
          break
        case 'last_login_desc':
          this.setState({
            filterSort: 'LAST_LOGIN_DESC'
          }, () => this.apiUserFilterList())
          break
        default: break
      }
    }

    if (field === 'userAccountActivated') {
      switch (value) {
        case 'active':
          this.setState({
            userAccountActivated: true
          }, () => this.apiUserFilterList())
          break
        case 'inactive':
          this.setState({
            userAccountActivated: false
          }, () => this.apiUserFilterList())
          break
        default:
          this.setState({
            userAccountActivated: null
          }, () => this.apiUserFilterList())
          break
      }
    }

    if (field === 'userType') {
      switch (value) {
        case 'user':
          this.setState({
            userType: 'user'
          }, () => this.apiUserFilterList())
          break
        case 'admin':
          this.setState({
            userType: 'admin'
          }, () => this.apiUserFilterList())
          break
        default:
          this.setState({
            userType: null
          }, () => this.apiUserFilterList())
          break
      }
    }

    if (field === 'teamSelected') {
      this.setState({
        description: this.getTeam(value),
        title: value
      })
    } else {
      this.setState({
        teamSelected: ''
      })
    }
  }

  getTeam(teamId) {
    const teamList = this.state.teamsList
    for (let i=0; i<teamList.length; i++) {
      if (teamList[i].id === teamId) {
        return teamList[i]
      }
    }
  }

  addTeamsToUser(e) {
    e.preventDefault()
    const id_token = JSON.parse(localStorage.getItem('tokenData'))?.tokenId
    const ts = []
    Object.keys(this.state.chkTeams).forEach((key) => {
      if (this.state.chkTeams[key] === true && typeof this.state.chkTeamsInit[key] === 'undefined') {
        ts.push(key)
      }
    })
    const data = {
      id_token: id_token,
      members: ts,
      userId: this.state.selectedUserId
    }
    this.hideTeamsModal()
    apiTeamAddMembers(data)
      .then(() => {
        this.setState({
          teamSelected: ''
        })
        this.apiUserFilterList()
        CustomNotificationManager.success('User was successfully assigned to the selected teams', 'Assigned successfully')
      })
  }

  updateRole = (user) => {
    user = user.user
    if (user.user_metadata.roles.osprey !== 'super_admin') {
      const selectedUserName = user.user_metadata.name || user.user_metadata.nickname
      const selectedUserStatus = user.user_metadata.roles.osprey
      const tempUserStatus = selectedUserStatus === 'admin' ? 'User' : 'Admin'
      const textTeam = `Do you want to change ${ selectedUserName }'s role to ${ tempUserStatus } ?`
      const swalUser = Swal.mixin({
        // confirmButtonClass: 'btn btn-success',
        // cancelButtonClass: 'btn btn-danger',
        buttonsStyling: false,
        customClass: 'modal-content-roles'
      })

      swalUser.fire({
        text: textTeam,
        //type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          const currentUserIsSuperAdmin = userIsSuperAdmin(this.props.user)
          const currentUserIsAdmin = userIsAdmin(this.props.user)
          if (currentUserIsSuperAdmin || currentUserIsAdmin) {
            const temp = user
            if (temp.user_metadata.roles.osprey === 'admin') {
              temp.user_metadata.roles.osprey = 'user'
            } else if (temp.user_metadata.roles.osprey === 'user') {
              temp.user_metadata.roles.osprey = 'admin'
            }
            if (user.user_metadata.roles.osprey !== 'super_admin') {
              this.updateUsersRole([temp])
            }
          }
        }
      })
    }
  }

  saveChanges(e) {
    e.preventDefault()
    this.updateUsersRole() 
  }

  updateUsersRole(data) {
    this.setState({
      isSaving: true
    })
    apiUserUpdateRoles(data)
      .then((response) => {
        if (this.props.user.email === data[0].email) {
          let idLinkVisited = []
          const notLinkVisited = [{
            nameActionEvent: `New access/permission`,
            descriptionActionEvent: `You have been given the ${response.data['new role']} role`,
            timesEvent: moment().format('DD/MM/YYYY HH:mm:ss'),
            status: 'access-avatar'
          }]
          idLinkVisited = notLinkVisited.concat(this.props.smartBoxNotAccessPermission)
          const uniqueVerificationVisited = idLinkVisited && idLinkVisited.length > 0 && idLinkVisited.filter((a, i) => idLinkVisited.findIndex((s) => a.nameActionEvent === s.nameActionEvent) === i)
          this.props.setSmartBoxNotAccessPermission(uniqueVerificationVisited)
        }
        const newUsersList = JSON.parse(JSON.stringify(this.state.usersList))
        if (response.data) {
          if (newUsersList && newUsersList.length) {
            newUsersList.forEach((item, index) => {
              if (item.user.id === data[0].id) {
                newUsersList[index].user.user_metadata.roles.osprey = response.data['new role']
              }
            })
          }
          this.setState({
            isSaving: false,
            usersList: newUsersList
          }, () => CustomNotificationManager.success(`User ${ data[0].name } has ${ data[0].user_metadata.roles.osprey } role.`, 'Role updated'))
        }
      })
  }

  resendInvite = (email, userId) => {
    const isSending = this.state.isSending
    if (isSending[userId]) {
      isSending[userId] = true
      this.setState({
        isSending: isSending
      })
    }
    const data = {
      email,
      id: userId
    }

    apiUserResendInvite(data)
      .then((response) => {
        const { messageStatus, messageText, showMessage } = this.state
        showMessage[userId] = true
        messageText[userId] = 'Invite resent.'
        messageStatus[userId] = 'success'
        if (response.data.success) {
          CustomNotificationManager.success('Resend invite')
        } else {
          CustomNotificationManager.info('Resend invite failed')
        }
        this.setState({
          isSending: false,
          messageStatus,
          messageText,
          showMessage
        })
      }).catch((e) => {
        const text = getErrorMessage(e)
        CustomNotificationManager.error(e.message, 'Resend error')
        const { messageStatus, messageText, showMessage } = this.state
        messageStatus[userId] = 'error'
        messageText[userId] = text
        showMessage[userId] = true

        this.setState({
          isSending: false,
          messageStatus,
          messageText,
          showMessage
        })
      })
  }

  fetchTeams() {
    apiTeamFetchTeamsList()
      .then((response) => {
        this.setState({
          teamsList: response.data,
        })
      })
  }

  hideMessageAction(userId) {
    const { messageStatus, messageText, showMessage } = this.state
    messageStatus[userId] = ''
    messageText[userId] = ''
    showMessage[userId] = false

    this.setState({
      messageStatus: messageStatus,
      messageText: messageText,
      showMessage: showMessage,
    })
  }
 
  apiUserFilterList(data) {
    data = {
      'userAuthoritiesEnum': this.state.userType,
      'accountActivated': this.state.userAccountActivated,
      'sortEnum': this.state.filterSort,
      'pageNumber': this.state.activePage - 1,
      'amountPerPage': this.state.resultsPerPage,
      'name': this.state.findText
    }

    apiUserFilterList(data)
      .then((response) => {
        let usersList
        response.data.totalElements>0 ? usersList = response.data.list : usersList = null
        this.setState({
          newList: response.data,
          isLoading: false,
          usersList: usersList,
          numberOfUsers: response.data.totalElements
        })
      })

  }

  changePagination = (page) => {
    this.setState({ activePage: page }, () => this.apiUserFilterList())
  };

  updateFilterSearch = (e) => {
    const searchedText = e.target.value
    if (searchedText !== '' && searchedText.length > 2) {
      const timeout = null
      clearTimeout(timeout)
      setTimeout(function() {
        this.apiUserFilterList()
      }.bind(this), 1100)
    } else if (searchedText === '') {
      this.apiUserFilterList()
    }
    this.setState({
      findText: searchedText,
      activePage: 1
    })
  }

  clearFilterSearch = () => {
    this.apiUserFilterList()
    this.setState({
      findText: '',
      activePage: 1
    })
  }
  
  confirmRolesUser() {
    const selectedUserName = this.state.selectedUserName
    const selectedUserStatus = this.state.selectedUserStatus
    const tempUserStatus = selectedUserStatus === 'admin' ? 'User' : 'Admin'
    const textTeam = `Would you like to make ${ selectedUserName }: ${ tempUserStatus } ?`
    const textConfirmUser = `${ selectedUserName } has been ${ tempUserStatus }`
    const swalUser = Swal.mixin({
      // confirmButtonClass: 'btn btn-success',
      // cancelButtonClass: 'btn btn-danger',
      buttonsStyling: false,
    })
    swalUser.fire({
      text: textTeam,
      //type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        CustomNotificationManager.success(textConfirmUser, 'Success')
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        CustomNotificationManager.info('Action cancelled', 'Aborted')
      }
    })
  }
  filtersTeams(inputValueSelect) {
    const teamList = this.state.teamsList
    return teamList.filter((i) => i.name.toLowerCase().includes(inputValueSelect.toLowerCase()))
  }
  loadOptions(inputValueSelect, callBack) {
    setTimeout(() => { callBack(this.filtersTeams(inputValueSelect)) }, 1000)
  }

  handleInputChange(newValue) {
    const inputValueSelect = newValue.replace(/\W/g, '')
    this.setState({ inputValueSelect: this.state.inputValueSelect })
    return inputValueSelect
  }

  handleRemoveUser = (user) => {
    const currentUsersIsAdmin = userIsAdmin(this.props.user)
    this.setState({
      isSaving: true
    })
    if (!currentUsersIsAdmin) {
      const data = this.state.newList.list.filter((item) => {
        return item.user.user_id === user
      })
      const text = `Do you want to ${ data[0].user.blocked === true ? 'enable' : 'disable' } ${ data[0].user.user_metadata.name } ${ data[0].user.user_metadata.nickname } ?`
      const swalUser = Swal.mixin({
        // confirmButtonClass: 'btn btn-success',
        // cancelButtonClass: 'btn btn-danger',
        buttonsStyling: false,
        customClass: 'modal-check-enable-user'
      })
      swalUser.fire({
        text: text,
        //type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          apiRemoveUser(data[0]).then((response) => {
            this.apiUserFilterList()
            if (response.data) {
              if (response.data.hasBusinessProcesses) {
                CustomNotificationManager.error(`User ${ data[0].user.name } has active Business Processes`)
              }
              else {
                this.setState({
                  isSaving: false
                })
                let disableStatus = 'enabled'
                if (! data[0].user.blocked) {
                  disableStatus = 'disabled'
                }
                CustomNotificationManager.success(`User ${ data[0].user.name } has been ${ disableStatus }.`, `User ${ disableStatus }`)
              }
            }
          })
        }
      })
    }
  }
  toggleOpen = (e, val) => {
    // e.stopPropagation()

    const { openedMenuId } = this.state
    this.setState({ openedMenuId: val === openedMenuId ? '' : val })
  }

  render() {
    const currentUserIsSuperAdmin = userIsSuperAdmin(this.props.user)
    const currentAccountIsPaid = accountIsPaid(this.props.subscription)
    const currentUsersIsAdmin = userIsAdmin(this.props.user)
    const { isOpenedTeamsModal, isLoading, findText, numberOfUsers,
      usersList, activePage, resultsPerPage, openedMenuId } = this.state
    
    if (!currentAccountIsPaid && !currentUserIsSuperAdmin) {
      return (
        <div className="newQuestionnaire__page">
          <Page403 />
        </div>
      )
    }
    
    return (
      <div className="manageUsers-page">
        <ManagerUsersHeader />
        <ManagerUsersFilters
          findText={ findText }
          currentUserIsSuperAdmin= { currentUserIsSuperAdmin }
          updateFilterSearch= { this.updateFilterSearch }
          updateValue= { this.updateValue }
          clearFilterSearch = {this.clearFilterSearch}
        />
        <ManagerUsersTable
          isLoading={isLoading}
          usersList={usersList}
          currentAccountIsPaid={currentAccountIsPaid}
          currentUsersIsAdmin={currentUsersIsAdmin}
          updateRole={this.updateRole}
          handleRemoveUser={this.handleRemoveUser}
          resendInvite={this.resendInvite}
          showTeamsModal={this.showTeamsModal}
          toggleOpen={this.toggleOpen}
          openedMenuId={openedMenuId}
        />
        { numberOfUsers > 0 &&
        <ManagerUsersPagination
            usersList={usersList}
            numberOfUsers={numberOfUsers}
            activePage={activePage}
            resultsPerPage={resultsPerPage}
            changePagination={this.changePagination}
            currentAccountIsPaid={this.currentAccountIsPaid}
        />
        }

        { isOpenedTeamsModal &&
        <UserTeamsModal
          hideTeamsModal={ this.hideTeamsModal }
          user={ this.state.activeUser}
          teamsList={ this.state.teamsList }
          teamSelected = { this.state.teamSelected }
          updateValueTeam = { this.updateValueTeam }
          addTeamsToUser={ this.addTeamsToUser }
          inputValueSelect={ this.state.inputValueSelect }
          filtersTeams={ this.filtersTeams }
          loadOptions={ this.loadOptions }
          handleInputChange= { this.handleInputChange }
          teamSelectedName= { this.state.teamSelectedName }
          updateChkValues = { this.updateChkValues }
          chkTeams = { this.state.chkTeams }
          chkTeamsInit = { this.state.chkTeamsInit }
        />
        }
      </div>

    )
  }
}

const stateMap = (state) => ({
  list: state.manageGroupsReducer.manageGroups.list,
  messageArea: state.messageAreaReducer.messageArea,
  user: state.userReducer.user,
  subscription: state.subscriptionReducer.subscription,
  smartBoxNotAccessPermission: state.smartBox.smartBoxNotAccessPermission,
})

const dispatchMap = (dispatch) => ({
  hideMessage: () => dispatch(messageActions.hideMessage()),
  showMessage: (status, text) => dispatch(messageActions.showMessage({ status, text })),
  showLoading: () => dispatch(manageGroupsActions.showLoading()),
  updateRole: (value, user) => dispatch(manageGroupsActions.updateRole(value, user)),
  setSmartBoxNotAccessPermission: (value) => dispatch(smartBoxActions.setSmartBoxNotAccessPermission(value)),
})
// const mapStateToProps = (state) => ({
//   smartBoxNotSarTracker: state.smartBox.smartBoxNotSarTracker,
// })
ManageUsers.propTypes = {
  list: PropTypes.array.isRequired,
  hideMessage: PropTypes.func.isRequired,
  messageArea: PropTypes.object.isRequired,
  showMessage: PropTypes.func.isRequired,
  showLoading: PropTypes.func.isRequired,
  updateRole: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  subscription: PropTypes.object.isRequired
}

export default compose(connect(stateMap, dispatchMap ),)(ManageUsers)
