import React from 'react'
import DateOptions from './DateOptions'

const FormDateSpan = ({ selectedValueFrom, selectedValueTo, selectedRangeDate }) => (
  <div className="privacyCollectionCreate__formFields">
    <div className="privacyCollectionCreate__formGroup">
      <label>Date span</label>
      <div className="privacyCollectionCreate__formHalf">
        <div>
          <select
            onChange={ selectedRangeDate.bind(this, 'selectedValueFrom') }
            value={ selectedValueFrom }>
            <option value="">choose...</option>
            <DateOptions />
            <option value="*">The beginning</option>
          </select>
        </div>

        <div>
          <select
            onChange={ selectedRangeDate.bind(this, 'selectedValueTo') }
            value={ selectedValueTo }>
            <option value="">choose...</option>
            <option value="NOW">Current day</option>
            <DateOptions />
          </select>
        </div>
      </div>
    </div>
  </div>
)

export default FormDateSpan