import React from 'react'
import PropTypes from 'prop-types'

import TopicItem from './TopicItem'

import './TopicList.css'

const TopicList = ({ openModal, topics }) => (
  <div id="topic-list" className="topic-topicList-wrapper">
    { topics.map((topic) => (
      <TopicItem
        key={ topic.id }
        openModal={ openModal }
        topic={ topic } />
    )) }
  </div>
)

TopicList.propTypes = {
  openModal: PropTypes.func.isRequired,
  topics: PropTypes.array.isRequired
}

export default TopicList