import initialState from '../reducers/initialState'
import initialStateSar from '../reducers/initialStateSar'
import initialStateAdmin from '../reducers/initialStateAdmin'
import initialStateSmartBox from '../reducers/initialStateSmartBox'
import initialStateApplications from '../reducers/initialStateApplications'

const initialStoreState = {
  manageGroupsReducer: initialState,
  messageAreaReducer: initialState,
  subscriptionReducer: initialState,
  questionReducer: initialState,
  shellReducer: initialState,
  userReducer: initialState,
  form: { },
  sar: initialStateSar,
  admin: initialStateAdmin,
  applications: initialStateApplications,
  smartBox: initialStateSmartBox,
}

export default initialStoreState