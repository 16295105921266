export const mappedCollectionsWithAlerts = (collections) => {
  return collections.map((collection) => ({
    ...collection,
    isFetchingInfo: true,
    isFetchingTotalAlerts: true,
    totalAlerts: { low: 0, medium: 0, high: 0 }
  }))
}

export const startEmptyAlertLevelsOnCollection = (alert) => {
  return alert.reduce((acc, item) => {
    const level = (item.properties && item.properties.level) ? item.properties.level : null
    if (level) acc[level].push(item.id)
    return acc
  }, { high: [], medium: [], low: [] })
}

export const findCollectionByName = (collection, term) => {
  const termRE = new RegExp(term, 'i')
  return collection.filter((item) => item.name.match(termRE))
}

export const findCategory = (collection, id) => {
  const category = collection.filter((collection) => collection.id === id)
  return category.length > 0 ? category[0] : null
}