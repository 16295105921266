import React from 'react'
import PropTypes from 'prop-types'

import './Tabs.css'
import Tab from './Tab'

class Tabs extends React.Component {
  constructor() {
    super()
    
    this.state = { 
      tabs: []
    }
  }

  componentDidMount = () => {

    const { tabs } = this.props

    this.setState({
      tabs: tabs
    })
  }

  clickTab = (ind) => {
    this.props.tabClick(ind)
  }

  render = () => {

    const selected = this.props.selectedTab
    const { tabs } = this.props

    return (
      <div>
        <ul className="backOffice__tabsContainer">
          { tabs.map((name, index) => {
            return <Tab key={ `tabs_${ index }_${ selected }` } tabName={ name } tabNum={ index } isSelected={ (index === selected) } clickTab={ this.clickTab.bind(this) } />
          })}
        </ul>
        <div className="backOffice__tabsSpacer"/>
      </div>
    )
  }
}

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  tabClick: PropTypes.func.isRequired,
  selectedTab: PropTypes.number.isRequired
}

export default Tabs