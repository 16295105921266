import PropTypes from 'prop-types'
import React, { Component } from 'react'
//import './Pagination.scss'
import '../../../Common/Pagination.scss'
//import Button from './Button'
import Button from '../../../Common/Button'
import Icon from 'antd/es/icon'
import { Select } from 'antd'

export class PaginationData extends Component {
  state = {
    currentPage: 1,
    isPrevDisabled: true,
    isNextDisabled: false,
  }
  constructor(props) {
    super(props)
    const { totalRecords, pageLimit } = this.props

    this.totalPages = Math.ceil(totalRecords / pageLimit)
  }

  componentDidMount() {
    //    this.gotoPage(1);
  }
  componentDidUpdate(prevProps) {
    if (this.props.totalRecords !== prevProps.totalRecords && this.props.totalRecords !== undefined) {
      this.setState({
        currentPage: 1,
        isPrevDisabled: true,
        isNextDisabled: false
      })

      const { totalRecords, pageLimit } = this.props
      this.totalPages = Math.ceil(totalRecords / pageLimit)
    }
  }

  gotoPage = (page) => {
    const { onPageChanged, pageLimit } = this.props
    const currentPage = Math.max(1, Math.min(page, this.totalPages))
    const paginationData = {
      currentPage,
      pageLimit,
    }

    const isPrevDisabled = currentPage === 1
    const isNextDisabled = currentPage === this.totalPages

    this.setState({ currentPage, isPrevDisabled, isNextDisabled }, () => onPageChanged(paginationData))
  }

  handleMoveLeft = (e) => {
    e.preventDefault()
    this.gotoPage(this.state.currentPage - 1)
  }

  handleMoveRight = (e) => {
    e.preventDefault()
    this.gotoPage(this.state.currentPage + 1)
  }

  changePageLimit = (pageLimit) => {

    const { onPageChanged, totalRecords } = this.props
    const currentPage = 1

    this.totalPages = Math.ceil(totalRecords / pageLimit)

    const paginationData = {
      currentPage,
      pageLimit,
    }
    this.setState({
      currentPage,
      isPrevDisabled: true,
      isNextDisabled: false
    }, () => onPageChanged(paginationData))
  }

  render() {

    const { currentPage, isPrevDisabled, isNextDisabled } = this.state
    const { totalRecords, pageLimit, tableFileListSelected, fileList } = this.props
    //if (!totalRecords || this.totalPages === 0) return null
    const { Option } = Select
    let paginationStart
    //const paginationStart = (currentPage - 1) * pageLimit + 1
    if (tableFileListSelected && tableFileListSelected.length > 0) {
      paginationStart = (currentPage - 1) * pageLimit + 1
    } else if (fileList && fileList.length > 0) {
      paginationStart = (currentPage - 1) * pageLimit + 1
    } else {
      paginationStart = (currentPage - 1) * pageLimit
    }
    let paginationEnd = 0
    let totalRecordsFormatted = 0
    if (totalRecords !== undefined) {
       paginationEnd = pageLimit * currentPage > totalRecords ? totalRecords : pageLimit * currentPage
      ///const totalRecordsFormatted = totalRecords.toLocaleString(navigator.language, { minimumFractionDigits: 0 })
       totalRecordsFormatted = totalRecords
    }

    return (
      <React.Fragment>
        <div className="custom-pagination custom-pagination-data">
          <div className="per-page-data">Show
            <Select defaultValue={ pageLimit } onChange={ this.changePageLimit }>
              <Option value="10">10</Option>
              <Option value="25">25</Option>
              <Option value="50">50</Option>
              <Option value="100">100</Option>
            </Select>
            </div>
          <div className="button-control button-control-data">
            <Button type="primary" theme="button-control button-control__left"
              onClick={ this.handleMoveLeft }
              disabled={ isPrevDisabled }>
              <Icon type="left"/>
            </Button>
            <Button type="primary" theme="button-control button-control__right"
              onClick={ this.handleMoveRight }
              disabled={ isNextDisabled }>
              <Icon type="right"/>
            </Button>
          </div>
          <div className="data-nr-data">Result: <strong>{ paginationStart } - { paginationEnd }</strong> of { totalRecordsFormatted }</div>
        </div>
      </React.Fragment>
    )
  }
}
PaginationData.propTypes = {
  //totalRecords: PropTypes.number.isRequired,
  pageLimit: PropTypes.number,
  onPageChanged: PropTypes.func
}

export default PaginationData