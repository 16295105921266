import PropTypes from 'prop-types'
import React, { Component } from 'react'
import './Radio.css'
import Active from './Radio/Active'
import Completed from './Radio/Completed'

class Radio extends Component {
  render() {
    const { assessmentStatus, processStatus, question } = this.props

    if (processStatus === 'INACTIVE' || assessmentStatus === 'COMPLETED' || question.state === 'QUESTIONNAIRE_SKIPPED') return (
      <Completed
        question={ question }
      />
    )

    return (
      <Active
        question={ question }
        onChange={ this.props.updateValueForRadio }
        updateValueForOther={ this.props.updateValueForOther }
      />
    )
  }
}

Radio.propTypes = {
  assessmentStatus: PropTypes.string.isRequired,
  question: PropTypes.object.isRequired,
  updateValueForRadio: PropTypes.func.isRequired,
  updateValueForOther: PropTypes.func.isRequired
}

export default Radio
