import React from 'react'
import ObjectTypeList from '../Form/ObjectTypeList'

import { doFilterFacetValueFor } from '../../../utils'

import './AddPii.css'

class AddPii extends React.Component {
  setPiiRule = (event) => {
    const index = event.target.name.replace('pii-rule-', '')
    const newPii = [...this.props.pii]
    newPii[index] = {
      ...this.props.pii[index],
      rule: event.target.value
    }
    this.props.updatePii(newPii)
  }

  setPiiKind = (event) => {
    const index = event.target.name.replace('objectype-kind-', '')
    const selectedValue = event.target.value
    const filteredFacetValues = selectedValue === ''
      ? []
      : doFilterFacetValueFor(this.props.facetValues, selectedValue)

    const newPii = [...this.props.pii]
    newPii[index] = {
      ...this.props.pii[index],
      kind: selectedValue,
      value: '',
      filteredFacetValues
    }
    this.props.updatePii(newPii)
  }

  setPiiValue = (event) => {
    const index = event.target.name.replace('pii-value-', '')
    const newPii = [...this.props.pii]
    newPii[index] = {
      ...this.props.pii[index],
      value: event.target.value
    }
    this.props.updatePii(newPii)
  }

  render = () => {
    return (
      <React.Fragment>
        { this.props.pii.map((field, index) => (
          <div className="addPii__wrapper" key={ index }>
            <div>
              <select
                name={ `pii-rule-${ index }` }
                value={ field.rule }
                onChange={ this.setPiiRule }>
                <option value="">Choose...</option>
                <option value="exclude">Exclude</option>
                <option value="include">Include</option>
              </select>
            </div>
            <div>
              { field.rule
                ? <ObjectTypeList
                  filterByGroup='PII'
                  index={ index }
                  value={ field.kind }
                  onChange={ this.setPiiKind } />
                : <select disabled>
                  <option value="">Choose...</option>
                </select>
              }
            </div>
            <div>
              { !field.kind &&
                <select disabled>
                  <option value="">Choose...</option>
                </select>
              }

              { field.kind &&
                <select
                  name={ `pii-value-${ index }` }
                  value={ field.value }
                  onChange={ this.setPiiValue }>
                  <option value="">All Object Types</option>
                  { field.filteredFacetValues.length > 0 &&
                    field.filteredFacetValues.map((facet, index) => (
                      <option key={ index } value={ facet.id }>{ facet.name } ({ facet.count })</option>
                    ))}
                </select>
              }
            </div>
          </div>
        ))}
      </React.Fragment>
    )
  }
}

export default AddPii