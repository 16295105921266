import React from 'react'
import { Link } from 'react-router-dom'
import { apiFetchQuestionnaireVersion } from '../../../../api/Questionnaire'
import './QuestionnaireGlobal.scss'
import Checkbox from './Questions/Checkbox'
import Radio from './Questions/Radio'
import YN from './Questions/YN'

class QuestionnaireGlobal extends React.Component {
  constructor() {
    super()

    this.state = {
      isLoading: false,
      questions: []
    }
  }

  get urlParams() {
    return {
      questionnaireId: this.props.params.questionnaireId,
      versionId: this.props.params.versionId
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)

    const { questionnaireId, versionId } = this.urlParams
    this.setState({ isLoading: true })
    this.fetchQuestionnaireVersion(questionnaireId, versionId)
  }

  fetchQuestionnaireVersion(questionnaireId, versionId) {
    apiFetchQuestionnaireVersion(questionnaireId, versionId)
    .then((response) => {
      this.setState({ questions: response.data.questions })
    })
  }

  render() {
    const { questions } = this.state

    return (
      <div className="questionnaireGlobal__page">
        <Link to="/dpia/questionnaires" className="reportDetail-link">Back to questionnaires</Link>

        {
          questions.map((question, index) => {
            switch (question.type) {
              case 'Checkbox':
                return (<Checkbox key={ index } question={ question } />)
              case 'Radio':
                return (<Radio key={ index } question={ question } />)
              default:
                return (<YN key={ index } question={ question } />)
            }
          })
        }
      </div>
    )
  }
}

export default QuestionnaireGlobal