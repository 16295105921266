import React, { Component } from 'react'
import './SubjectAccess.scss'
import SubjectAccessForm from './SubjectAccessForm'
import { connect } from 'react-redux';

class SubjectAccess extends Component {
  constructor() {
    super()
    this.state = {
      title: 'Your Subject Access Request form'
    }
  }
  render() {
    const { title } = this.state;
    return (
      <div className="sa_page_wrapper">
        <div className="sa_page">
          <div className="sa_box">
            <SubjectAccessForm title= { title } match = { this.props.match } />
          </div>
        </div>
      </div>
    )
  }
}

export default connect()(SubjectAccess)