import React from 'react'
import PropTypes from 'prop-types'

import '../Radio.css'

const Completed = ({ question }) => (
  <ul>
    { question.options.map((option) => (
      <li key={ option.id }>
        <div className="questionTypeRadio-item">
          <div className="questionTypeRadio-group">
            <div className="questionTypeRadio-radioDisabled">
              <input
                checked={ option.isSelected }
                disabled={ true }
                id={ option.id }
                name='answer'
                type="radio"
                value={ option.title } />
              <label
                className={ getLabelClass(option.isSelected) }
                htmlFor={ option.id }></label>
            </div>
            <span className="questionTypeRadio-optionTitle">{ option.title }</span>
          </div>
          {
            option.isSelected &&
            option.message &&
            <span className="questionTypeRadio-optionMessage">{ option.message }</span>
          }
          { option.type === 'OTHER' &&
            <textarea
              className="questionTypeCheckbox-textarea"
              disabled={ true }
              value={ option.otherOptionText || '' }
            ></textarea>
          }
        </div>
      </li>
    ))}
  </ul>
)

const getLabelClass = (isSelected) => {
  return isSelected ? 'questionTypeRadio-labelCheckedDisabled' : 'questionTypeRadio-labelDisabled'
}

Completed.propTypes = {
  question: PropTypes.object.isRequired
}

export default Completed
