import React, { Component } from 'react'
import { Button, Modal, Radio, Table, Tree, Checkbox, Tooltip } from 'antd'
import PaginationData from './PaginationData'
import ProgressLoadingFiles from './ProgressLoadingFiles'
import {
  apiSarCheckAddDocumentsReady,
  apiSarStartAddDocuments, apiSarStepsImportBox,
  apiSarStepsImportFiles, getSarDocByThumbnailId
} from '../../../../api/Sar'
import CustomNotificationManager from '../../../Common/CustomNotificationManager'
const { TreeNode } = Tree
export class ImportDataStep extends Component {
  constructor() {
    super()

    this.state = {
      totalPages: 0,
      amountPerPage: 10,
      modalVisibleImportFiles: false,
      modeChoose: '',
      treeData: [
        {
          title: 'ExampleFile1',
          key: '1',
        },
        {
          title: 'ExampleFile2',
          key: '2',
        },
        {
          title: 'ExampleFile3',
          key: '3',
        },
        {
          title: 'ExampleFile4',
          key: '4',
        },
        {
          title: 'ExampleFile5',
          key: '5',
        },
        {
          title: 'ExampleFile6',
          key: '6',
        },
        {
          title: 'ExampleFile7',
          key: '7',
        },
        {
          title: 'ExampleFile8',
          key: '8',
        },
        {
          title: 'ExampleFile9',
          key: '9',
        },
        {
          title: 'ExampleFile10',
          key: '10',
        },
        {
          title: 'ExampleFile11',
          key: '11',
        },
      ],
      visibleTreeFiles: false,
      checkedKeysFiles: [],
      selectedKeys: [],
      treeSelectedFiles: [],
      totalDocuments: 0,
      totalPagesFiles: 0,
      totalSizeFiles: 0,
      selectedLetter: 'all',
      modeChooseFiles: '',
      pageNumber: 1,
      listSelectedFiles: [],
      tableFileListSelected: [],
      loadingConfirmData: false,
      sendEmailNotificationData: true,
      loadingCompleteData: false,
      activeModalDelete: false,
      fileSelectedRemove: {},
      statusSelectedOption: '',
      noSearchLetterCompleted: false,
      setIntervalAddDocuments: 0,
      responseCheckAddDocuments: {},
      checkAddDocumentsReady: null,
      approvedFilesOnly: false,
      selectAllDeleted: false,
      deletedSelectedFile: [],
      selectedRowKeys: [],
      onCheckSelectAll: false,
      loadingConfirmDataStatus: false
    }
  }
  lettersCategory = ['All', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
  columns = [
    {
      title: <span className="title-files-column">Files</span>,
      dataIndex: 'title',
      key: 'title',
      className: 'column-files',
      render: (text, record) => {
        const thumbnailFile = record.thumbnail ? record.thumbnail : record.thumbUrl ? record.thumbUrl : ''
        const newRelativeName = record.name ? record.name.substr(0, record.name.length - `.${ record.originalType }`.length) : ''
        return (
          <div className="content-name-column">
            { thumbnailFile ? <Tooltip placement="right" overlayClassName="preview-file-data" title={<img alt={ record.name } src={ thumbnailFile }/>}><img alt={ record.name } src={ thumbnailFile }/></Tooltip> : <span className="icon-v10-extension-pdf"></span> }
            <span>{ newRelativeName }</span>
          </div>
        )
      }
    },
    {
      title: '',
      dataIndex: 'page',
      key: 'page',
      className: 'column-dates',
      render: (text, record) => {
        const totalNumberPages = record.numberOfPages ? record.numberOfPages : record.totalPages ? record.totalPages : 0
        return (
          <div>
            <span>{ totalNumberPages.length === 1 ? `${ totalNumberPages } page` : `${ totalNumberPages } pages` }</span>
          </div>
        )
      }
    },
    {
      title: '',
      dataIndex: 'size',
      key: 'size',
      className: 'column-dates',
      render: (text, record) => {
        return (
          <div>
            <span>{ record.size ? `${ record.size } KB` : '0 KB' }</span>
          </div>
        )
      }
    },
    {
      title: <span onClick={() => this.onClickDeleteFile('record', 'select-all')} className="remove-title-column"> <span className="icon-v10-delete-b"></span>Remove all </span>,
      dataIndex: 'remove',
      key: 'remove',
      className: 'column-remove',
      render: (text, record) => {
        return (
          <div className="content-remove-files">
            <span onClick={() => this.onClickDeleteFile(record, 'select-file')} 
              className={ record.thumbUrl ? 'icon-v10-delete-b icon-delete-file-disabled' : 'icon-v10-delete-b icon-delete-file'}></span>
          </div>
        )
      }
    }
  ]

  fetchPagionationFiltersFile = () => {
    //const { pageNumber, amountPerPage, selectedLetter, modeChoose, listSelectedFiles } = this.state
    const { listSelectedFiles } = this.state
    //const { sar } = this.props
    const listIdFiles = []
    listSelectedFiles && listSelectedFiles.length > 0 && listSelectedFiles.forEach((fileId) => {
      listIdFiles.push(fileId.idFile)
    })
    // let chooseOptionSelected
    // if (modeChoose === 'all-files') {
    //   chooseOptionSelected = true
    // } else if (modeChoose === 'choose-files') {
    //   chooseOptionSelected = false
    // }
    // const dataPagination = {
    //   pageNumber: pageNumber,
    //   amountPerPage: amountPerPage,
    //   idSar: sar.id,
    //   letter: selectedLetter === 'all' ? null : selectedLetter,
    //   modeChooseFiles: chooseOptionSelected,
    //   listSelectedFiles: modeChoose === 'all-files' ? null : listIdFiles
    // }
    // apiSarStepsPaginationFiltersFiles(dataPagination).then((response) => {
    //
    // })
  }
  componentDidMount() {
    if (this.props.sar.start_import_data) {
      this.onCheckAddDocumentsAfterStart()
      this.onImportDataBoxSarUpdate()
    }
    this.setState({
      totalPages: this.props.dataImportBox.totalDocuments,
      totalDocumentsFiles: this.props.dataImportBox.totalDocuments
    })
  }

  onImportDataBoxSarUpdate = (boxName) => {
    const { amountPerPage, approvedFilesOnly, pageNumber } = this.state
    const { sar } = this.props
    const data = {
      sarId: sar.id,
      boxName: sar.boxName,
      boxId: Number(sar.boxId),
      pageRows: amountPerPage,
      pageNr: pageNumber,
      approvedFilesOnly: approvedFilesOnly
    }
    apiSarStepsImportBox(data).then((response) => {
      if (response.status === 200) {
        this.setState({
          tableFileListSelected: response.data.files,
          totalPages: response.data.totalDocuments,
          totalDocumentsFiles: response.data.totalDocuments,
          totalFilesPages: response.data.totalPages,
          sizeFiles: response.data.totalSize,
        })
      }
      this.setState({
        tableFileListSelected: response.data.files,
      }, () => {
        this.onCheckSelectAllKeys()
      })
    }).catch((res) => {
      this.setState({
        onSendStatusStatusReturnApi: res.response.status
      })
      if (res.response.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }
    })
  }

  onCheckSelectAllKeys = () => {
    const { tableFileListSelected, onCheckSelectAll } = this.state
    const listIds = []
    if (onCheckSelectAll) {
      tableFileListSelected && tableFileListSelected.length > 0 && tableFileListSelected.forEach((list) => {
        listIds.push(list.id)
      })
      this.setState({
        selectedRowKeys: listIds
      })
    }
  }
  onCheckAddDocumentsAfterStart = () => {
    const { sar } = this.props
    if (sar.start_import_data === true) {
      this.onCheckAddDocuments()
    }
  }

  onRemoveAllFiles = () => {
    const { tableFileListSelected } = this.state
    if (tableFileListSelected && tableFileListSelected.length > 0) {
      this.setState({
        tableFileListSelected: [],
        activeModalDelete: false,
        totalPages: 0,
        amountPerPage: 10
      })
    }
  }
  removeOnClickFile = (record) => {
    const { tableFileListSelected } = this.state
    //let { deletedSelectedFile } = this.state
    const { fileList } = this.state
    let listForRemove = []
    let fileListData = []
    listForRemove = tableFileListSelected
    fileListData = fileList
    if (listForRemove && listForRemove.length > 0) {
      listForRemove = listForRemove.filter((file) => file.id !== record.id)
    } else if (fileListData && fileListData.length > 0) {
      fileListData = fileListData.filter((file) => file.id !== record.id)
    }
    this.setState({
      tableFileListSelected: listForRemove,
      fileList: fileListData,
      activeModalDelete: false,
      totalPages: listForRemove.length,
      amountPerPage: 10,
      deletedSelectedFile: [...this.state.deletedSelectedFile, record.id]
    })
  }
  onPageChanged = (data) => {
    const { currentPage, pageLimit } = data
    this.setState({
      amountPerPage: pageLimit,
      pageNumber: currentPage
    }, () => {
      this.onImportDataBoxSarUpdate()
    })
    //, () => this.fetchPagionationFiltersFile())
  }
  onClickImportData = () => {
    this.setState({
      modalVisibleImportFiles: true
    })
  }
  onChangeRadio = (e) => {
    if (e.target.value === 'choose-files') {
      this.setState({
        visibleTreeFiles: true,
        approvedFilesOnly: false
      })
    } else if (e.target.value === 'all-box-files') {
      this.setState({
        visibleTreeFiles: null,
        checkedKeysFiles: [],
        approvedFilesOnly: false
      })
    } else if (e.target.value === 'all-files') {
      this.setState({
        visibleTreeFiles: null,
        checkedKeysFiles: [],
        approvedFilesOnly: true
      })
    }
    else {
      this.setState({
        visibleTreeFiles: false,
        checkedKeysFiles: []
      })
    }
    this.setState({
      modeChoose: e.target.value
    })
    this.props.onChooseEmptyFileSelectBox(e)
  }
  onChangeNotification = () => {
    this.setState({
      sendEmailNotificationData: !this.state.sendEmailNotificationData
    })
  }
  handleCancelModalBox = () => {
    this.setState({
      modalVisibleImportFiles: false,
      modeChoose: '',
      checkedKeysFiles: [],
      visibleTreeFiles: false,
      treeSelectedFiles: []
    })
  }
  generateTreeListFiles(listFilesOnBox) {
    const { checkedKeysFiles } = this.state
    return (
      listFilesOnBox && listFilesOnBox.length > 0 && listFilesOnBox.map((file) => {
        return (
          <TreeNode
            title={this.generateTitleTree(file)}
            key={file.id}
            dataRef={ file }
            data-key={ file.id }
            selectable={true}
            className={checkedKeysFiles.includes(file.id) ? 'tree-node-file-selected' : 'tree-node-file'}>
          </TreeNode>
        )
      })

    )
  }

  generateTitleTree(file) {
    const newRelativeName = file.name ? file.name.substr(0, file.name.length - `.${ file.originalType }`.length) : ''
    return (
      <div className="file-import-content">
        { file.thumbnail ? <img alt={ file.name } src={ file.thumbnail }/> : <span className="icon-v10-extension-pdf"></span> }
        <span className="title-file">{ newRelativeName }</span>
      </div>
    )
  }
  onCheckFiles = (checkedKeys, { checkedNodes }) => {
    const { treeData } = this.state
    const { listFilesOnBox } = this.props
    const treeSelectedFiles = []
    const listSelectedFiles = []
    treeData && treeData.length > 0 && treeData.forEach((file) => {
      if (checkedKeys && checkedKeys.includes(file.key)) {
        treeSelectedFiles.push(file)
      }
    })
    listFilesOnBox && listFilesOnBox.length > 0 && listFilesOnBox.forEach((file) => {
      if (checkedKeys && checkedKeys.includes(file.id)) {
        listSelectedFiles.push(file)
      }
    })
    this.setState({
      checkedKeysFiles: checkedKeys,
      treeSelectedFiles,
      listSelectedFiles: listSelectedFiles
    })
  }
  onSelectKeysFile(selectedKeys, info) {
    this.setState({
      selectedKeys })
  }
  fetchImportFiles() {
    const { pageNumber, amountPerPage, selectedLetter, modeChoose, listSelectedFiles } = this.state
    const { sar } = this.props
    let modeChooseOptions
    //let listSelected
    if (modeChoose === 'all-files') {
      modeChooseOptions = true
      //listSelected = null
    } else if (modeChoose === 'choose-files') {
      modeChooseOptions = false
      //listSelected = listSelectedFiles
    }
    const data = {
      pageNumber: pageNumber,
      amountPerPage: amountPerPage,
      idSar: sar.id,
      letter: selectedLetter === 'all' ? null : selectedLetter,
      modeChooseFiles: modeChooseOptions,
      listSelectedFiles: modeChoose === 'all-files' ? null : listSelectedFiles
    }
    apiSarStepsImportFiles(data).then((response) => {

    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  onStartAddDocuments = () => {
    let input = {}
    const { documentsFound, fileList, valueAttachBoxId } = this.props
    const { tableFileListSelected, approvedFilesOnly, deletedSelectedFile, selectedRowKeys, onCheckSelectAll } = this.state
    //let fileListData
    //let solrFiles = []
    let selectedRowKeysNumber = []
    if (documentsFound) {
      if (tableFileListSelected && tableFileListSelected.length > 0) {
        //fileListData = tableFileListSelected.map((file) => Number(file.id))
        //solrFiles = tableFileListSelected.map((file) => file.id)
      } else if (fileList && fileList.length > 0) {
        //fileListData = fileList.map((file) => file.id)
      }
      selectedRowKeys && selectedRowKeys.length > 0 && selectedRowKeys.forEach((num) => {
        selectedRowKeysNumber.push(Number(num))
      })
      if (onCheckSelectAll) {
        input = {
          sar_id: this.props.sar.id,
          ids: [],
          solr_ids: [],
          documents_found: true,
          //solr_ids: solrFiles,
          sendEmailNotification: this.state.sendEmailNotificationData,
          box_id: valueAttachBoxId.toString(),
          approvedFilesOnly: approvedFilesOnly,
          //solr_ids_deleted: selectAllDeleted,
          solr_ids_deleted: [],
          addAllDocuments: onCheckSelectAll
        }
      } else {
        input = {
          sar_id: this.props.sar.id,
          ids: selectedRowKeysNumber,
          solr_ids: selectedRowKeys,
          documents_found: true,
          //solr_ids: solrFiles,
          sendEmailNotification: this.state.sendEmailNotificationData,
          box_id: valueAttachBoxId.toString(),
          approvedFilesOnly: approvedFilesOnly,
          //solr_ids_deleted: selectAllDeleted,
          solr_ids_deleted: deletedSelectedFile,
          addAllDocuments: onCheckSelectAll
        }
      }
    }
    this.setState({
      loadingConfirmDataStatus: true
    }, () => {
      apiSarStartAddDocuments(input).then((response) => {
        if (response.status === 200) {
          this.setState({
            loadingConfirmDataStatus: false
          })
          if (response.data === 'Process started successfully!') {
            CustomNotificationManager.info('Process started successfully!')
          }
          this.onCheckAddDocuments()
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    })
  }
  onCheckAddDocuments = () => {
    let input = {}
    const { fileList, searchFindStep  } = this.props
    let { setIntervalAddDocuments } = this.state
    const { tableFileListSelected } = this.state
    //let fileListData
    //let solrFiles = []
    //if (documentsFound) {
      if (tableFileListSelected && tableFileListSelected.length > 0) {
       // fileListData = tableFileListSelected.map((file) => Number(file.id))
        //solrFiles = tableFileListSelected.map((file) => file.id)
      } else if (fileList && fileList.length > 0) {
        //fileListData = fileList.map((file) => file.id)
      }
      input = {
        sar_id: this.props.sar.id,
        // ids: fileListData,
        // documents_found: true,
        // solr_ids: solrFiles,
        // sendEmailNotification: this.state.sendEmailNotificationData,
        // box_id: this.props.sar.boxId ? this.props.sar.boxId : valueAttachBoxId.toString(),
      }
    //}
    apiSarCheckAddDocumentsReady(input).then((response) => {
      this.setState({
        responseCheckAddDocuments: response.data,
        loadingConfirmData: true
      })
      if (response.data === true) {
        this.setState({
          respAfterCheckAddDocuments: response.data
        })
      }
      if (Object.keys(response.data) && Object.keys(response.data).length > 0 && response.data.processFinished === true && response.data.nrOfFilesReady === -1 && response.data.totalFiles === -1 && !searchFindStep.completed) {
        this.handleConfirmData()
      }
      if (Object.keys(response.data) && Object.keys(response.data).length > 0) {
        this.setState({
          checkAddDocumentsReady: response.data.processFinished
        })
      }
       if (setIntervalAddDocuments === 0) {

         this.startSetIntervalAddDocuments()
         this.setState({setIntervalAddDocuments})
       }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  startSetIntervalAddDocuments = () => {
    let { setIntervalAddDocuments } = this.state
    const { responseCheckAddDocuments, respAfterCheckAddDocuments } = this.state
    if (setIntervalAddDocuments === 0) {
      if (Object.keys(responseCheckAddDocuments) && Object.keys(responseCheckAddDocuments).length > 0 && !responseCheckAddDocuments.processFinished && !respAfterCheckAddDocuments) {
        setIntervalAddDocuments = setInterval(this.callBackOnCheckAddDocuments, 5000)
        this.setState({
          setIntervalAddDocuments
        })
      }
    } else {
      clearInterval(setIntervalAddDocuments)
      this.setState({ setIntervalAddDocuments })
    }
  }
  callBackOnCheckAddDocuments = () => {
    let input = {}
    const { fileList } = this.props
    let { setIntervalAddDocuments } = this.state
    const { tableFileListSelected, responseCheckAddDocuments, respAfterCheckAddDocuments } = this.state
    //let fileListData
    //let solrFiles = []
    //if (documentsFound) {
      if (tableFileListSelected && tableFileListSelected.length > 0) {
        //fileListData = tableFileListSelected.map((file) => Number(file.id))
        //solrFiles = tableFileListSelected.map((file) => file.id)
      } else if (fileList && fileList.length > 0) {
        //fileListData = fileList.map((file) => file.id)
      }
      input = {
        sar_id: this.props.sar.id,
        // ids: fileListData,
        // documents_found: true,
        // solr_ids: solrFiles,
        // sendEmailNotification: this.state.sendEmailNotificationData,
        // box_id: this.props.sar.boxId ? this.props.sar.boxId : valueAttachBoxId.toString()
      }
    //}
    if ((Object.keys(responseCheckAddDocuments) && Object.keys(responseCheckAddDocuments).length > 0 && !responseCheckAddDocuments.processFinished && !respAfterCheckAddDocuments)) {
      apiSarCheckAddDocumentsReady(input).then((response) => {
        this.setState({
          responseCheckAddDocuments: response.data,
          loadingConfirmData: true
        })
        if (response.data === true) {
          this.setState({
            respAfterCheckAddDocuments: response.data
          })
        }
        if (Object.keys(response.data) && Object.keys(response.data).length > 0) {
          this.setState({
            checkAddDocumentsReady: response.data.processFinished
          })
        }
        if (response.data.processFinished === true) {
          this.handleConfirmData()
          clearInterval(setIntervalAddDocuments)
          this.setState({ setIntervalAddDocuments })
        } else if (response.data.processFinished === false) {
            clearInterval(setIntervalAddDocuments)
            this.setState({ setIntervalAddDocuments })
          }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    }
  }

  handleConfirmData = () => {
    let input = {}
    const { documentsFound, fileList } = this.props
    const { tableFileListSelected } = this.state
    //let fileListData
    //let solrFiles = []
    if (documentsFound) {
      if (tableFileListSelected && tableFileListSelected.length > 0) {
        //fileListData = tableFileListSelected.map((file) => Number(file.id))
        //solrFiles = tableFileListSelected.map((file) => file.id)
      } else if (fileList && fileList.length > 0) {
        //fileListData = fileList.map((file) => file.id)
      }
      input = {
        sar_id: this.props.sar.id,
        // ids: fileListData,
        // documents_found: true,
        // solr_ids: solrFiles,
        // sendEmailNotification: this.state.sendEmailNotificationData,
        // box_id: this.props.sar.boxId ? this.props.sar.boxId : valueAttachBoxId.toString()
      }
    } else {
      input = {
        sar_id: this.props.sar.id,
        // documents_found: false,
        // ids: [],
        // solr_ids: [],
        // sendEmailNotification: this.state.sendEmailNotificationData,
        // box_id: this.props.sar.boxId ? this.props.sar.boxId : valueAttachBoxId.toString()
      }
    }
      getSarDocByThumbnailId(input)
        .then((response) => {
          this.props.refreshData(response.data)
          CustomNotificationManager.success('Documents successfully uploaded.', 'Success')
          this.setState({
            uploadFile: false,
            loadingConfirmData: false
          })
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
      })
  };

  onClickImportFiles = () => {
    const { treeData, treeSelectedFiles, modeChoose, listSelectedFiles } = this.state
    let { listFilesOnBox } = this.props
    this.props.onCallImportFilesData(treeData, treeSelectedFiles, modeChoose)
    //this.fetchImportFiles()
    if (modeChoose === 'all-files') {
      listFilesOnBox = listFilesOnBox && listFilesOnBox.length > 0 ? listFilesOnBox.filter((list) => list.approved === true) : []
      this.setState({
        tableFileListSelected: listFilesOnBox,
        totalPages: listFilesOnBox.length
      })
      this.props.onCheckImportEmptyFiles(listFilesOnBox)
    } else if (modeChoose === 'choose-files') {
      this.setState({
        tableFileListSelected: listSelectedFiles && listSelectedFiles.length > 0 ? listSelectedFiles : [],
        totalPages: listSelectedFiles.length
      })
      this.props.onCheckImportEmptyFiles(listSelectedFiles)
    } else if (modeChoose === 'all-box-files') {
      listFilesOnBox = listFilesOnBox && listFilesOnBox.length > 0 ? listFilesOnBox : []
      this.setState({
        tableFileListSelected: listFilesOnBox,
        totalPages: listFilesOnBox.length
      })
      this.props.onCheckImportEmptyFiles(listFilesOnBox)
    }
    //this.fetchPagionationFiltersFile()
    this.setState({
      modalVisibleImportFiles: false,
      modeChoose: '',
      checkedKeysFiles: [],
      visibleTreeFiles: false
    }, () => {
      if (modeChoose !== 'choose-files') {
        this.onImportDataBoxSarUpdate()
      }
    })
  }
  searchAfterFirstLetter = (letter) => {
    const { tableFileListSelected } = this.state
    const index = 0
    if (tableFileListSelected && tableFileListSelected.length > 0) {
      for (let i = 0; i < tableFileListSelected.length; i++) {
        if (letter === tableFileListSelected[i].name.charAt(index)) {
        }
      }
    }
  }
  functionFiltersLetters = (letter) => {
    const { listSelectedFiles } = this.state
    let { tableFileListSelected } = this.state
    const { listFilesOnBox, fileList } = this.props
    tableFileListSelected = listSelectedFiles && listSelectedFiles.length > 0 ? listSelectedFiles : listFilesOnBox && listFilesOnBox.length > 0 ? listFilesOnBox : ''
    const resultSearch = []
    const resultSearchCompleted = []
    if (tableFileListSelected && tableFileListSelected.length > 0) {
      tableFileListSelected.forEach((file) => {
        const letterNameFile = file.name.toLowerCase()
        if (letter === 'all') {
          this.setState({
            tableFileListSelected: listSelectedFiles && listSelectedFiles.length > 0 ? listSelectedFiles : listFilesOnBox && listFilesOnBox.length > 0 ? listFilesOnBox : '',
            totalPages: listSelectedFiles && listSelectedFiles.length > 0 ? listSelectedFiles.length : listFilesOnBox && listFilesOnBox.length > 0 ? listFilesOnBox.length : 0,
            amountPerPage: 10,
            noSearchLetterCompleted: false
          })
        } else if (letterNameFile && letterNameFile.indexOf(letter) === 0) {
          resultSearch.push(file)
          this.setState({
            tableFileListSelected: resultSearch,
            amountPerPage: 10,
            totalPages: resultSearch.length,
            noSearchLetterCompleted: false
          })
        } else {
          this.setState({
            tableFileListSelected: [],
            totalPages: 0,
            amountPerPage: 10,
            noSearchLetterCompleted: true
          })
        }
      })
    } else if (fileList && fileList.length > 0) {
      fileList.forEach((file) => {
        const letterNameFileCompleted = file.name.toLowerCase()
        if (letter === 'all') {
          this.setState({
            tableFileListSelected: fileList,
            noSearchLetterCompleted: false,
            totalPages: fileList.length
          })
        } else if (letterNameFileCompleted && letterNameFileCompleted.indexOf(letter) === 0) {
          resultSearchCompleted.push(file)
          this.setState({
            tableFileListSelected: resultSearchCompleted,
            amountPerPage: 10,
            noSearchLetterCompleted: false,
            totalPages: resultSearchCompleted.length
          })
        } else {
          this.setState({
            tableFileListSelected: [],
            totalPages: 0,
            amountPerPage: 10,
            noSearchLetterCompleted: true
          })
        }
      })
    }
    if (resultSearch && resultSearch.length > 0) {
      this.setState({
        tableFileListSelected: resultSearch,
        totalPages: resultSearch.length,
        amountPerPage: 10
      })
    } else if (resultSearchCompleted && resultSearchCompleted.length > 0) {
      this.setState({
        totalPages: resultSearchCompleted.length,
        tableFileListSelected: resultSearchCompleted,
        amountPerPage: 10,
        noSearchLetterCompleted: false
      })
    }
  }
  onChangeLetter = (e) => {
    this.functionFiltersLetters(e.target.value)
    this.searchAfterFirstLetter(e.target.value)
    this.setState({
      selectedLetter: e.target.value
    })
    //, () => this.fetchPagionationFiltersFile())
  }
  onSumTotalPages() {
    const { tableFileListSelected } = this.state
    const { fileList } = this.props
    const numberPagesTotalFiles = []
    if (tableFileListSelected && tableFileListSelected.length > 0) {
      tableFileListSelected.forEach((file) => {
        numberPagesTotalFiles.push(file.numberOfPages)
        numberPagesTotalFiles.reduce((a, b) => a + b, 0)
      })
    } else if (fileList && fileList.length > 0) {
      fileList.forEach((file) => {
        numberPagesTotalFiles.push(file.totalPages)
        numberPagesTotalFiles.reduce((a, b) => a + b, 0)
      })
    }
    return numberPagesTotalFiles && numberPagesTotalFiles.length > 0 ? numberPagesTotalFiles.reduce((a, b) => a + b, 0) : 0
  }
  OnSumTotalSize() {
    const { tableFileListSelected } = this.state
    const { fileList } = this.props
    const totalSizeFiles = []
    if (tableFileListSelected && tableFileListSelected.length > 0) {
      tableFileListSelected.forEach((file) => {
        totalSizeFiles.push(file.size)
      })
    } else if (fileList && fileList.length > 0) {
      fileList.forEach((file) => {
        totalSizeFiles.push(file.size)
      })
    }
    return totalSizeFiles && totalSizeFiles.length > 0 ? totalSizeFiles.reduce((a, b) => a + b, 0) : 0
  }
  handleCancelDelete = () => {
    this.setState({
      activeModalDelete: false
    })
  }
  onClickDeleteFile = (record, status) => {
    const { tableFileListSelected } = this.state
    const { fileList, searchFindStep } = this.props
    if (status === 'select-all') {
      this.setState({
        selectAllDeleted: true
      })
    } else {
      this.setState({
        selectAllDeleted: false
      })
    }
    if (tableFileListSelected && tableFileListSelected.length > 0 && !searchFindStep.completed) {
      this.setState({
        activeModalDelete: true
      })
    } else if (fileList && fileList.length > 0) {
      this.setState({
        activeModalDelete: false
      })
    } else {
      this.setState({
        activeModalDelete: false
      })
    }
    this.setState({
      fileSelectedRemove: record,
      statusSelectedOption: status
    })
  }
  onChangeRowImport = (selectedRowKeys, filesSelect, e) => {
    const selectRow = []
    filesSelect && filesSelect.length > 0 && filesSelect.forEach((row) => {
      selectRow.push(row.word)
    })
    this.setState({
      selectedRowKeys: selectedRowKeys,
      selectedRowWord: selectRow,
      selectedRow: filesSelect
    })
  }
  onSelectAll = (selected, selectedRows, changeRows) => {
    this.setState({
      onCheckSelectAll: selected
    })
  }
  render() {
    const { amountPerPage, modalVisibleImportFiles, modeChoose, visibleTreeFiles, checkedKeysFiles, selectedKeys, totalPagesFiles, selectedLetter, tableFileListSelected, activeModalDelete, fileSelectedRemove,
      statusSelectedOption, loadingConfirmData, noSearchLetterCompleted, sendEmailNotificationData, responseCheckAddDocuments, checkAddDocumentsReady,
      totalFilesPages, sizeFiles, totalDocumentsFiles, selectedRowKeys, loadingConfirmDataStatus } = this.state
    let { totalPages } = this.state
    const { searchFindStep, listFilesOnBox } = this.props
    let { fileList } = this.props
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onChangeRowImport,
      onSelectAll: this.onSelectAll,
    }
    if (fileList && fileList.length > 0) {
      totalPages = fileList.length
    }
    if (noSearchLetterCompleted) {
      fileList = []
      totalPages = 0
    }
    return (
      <React.Fragment>
        <div className="tracker-import-data" style={ { marginTop: 0 } }>
          <div className="tracker-form-import-data">
            <header className="import-data-step-header">
              <div className="tracker-step-header-top">
                <span className="tracker-step-header-top__top1">Import data</span>
                {
                  tableFileListSelected && tableFileListSelected.length > 0 ?
                    <span className="tracker-step-header-top__top2">Review before you send all data listed below.</span>:
                    <span className="tracker-step-header-top__top2">Import data from your attached box</span>
                }
              </div>
              <div className="tracker-step-header-button">
                { searchFindStep && !searchFindStep.completed &&
                  <Button disabled={ checkAddDocumentsReady === false } className="import-data-content" onClick={ this.onClickImportData }>
                    Import data
                  </Button>
                }
              </div>
            </header>
            <div className="tracker-content-import-data">
              <PaginationData totalRecords={ totalPages } pageLimit={ amountPerPage } onPageChanged={ this.onPageChanged } tableFileListSelected={tableFileListSelected} fileList={fileList}/>
              <Radio.Group className="letters" value={selectedLetter} onChange={ (e) => this.onChangeLetter(e) }>
                { this.lettersCategory && this.lettersCategory.length > 0 && this.lettersCategory.map((item) => {
                  return <Radio.Button key={item} value={item.toLowerCase()}>{item}</Radio.Button>
                })
                }
              </Radio.Group>
              <Table
                dataSource={tableFileListSelected && tableFileListSelected.length > 0 ? tableFileListSelected : fileList && fileList.length > 0 ? fileList : []}
                //dataSource={tableFileListSelected }
                columns={this.columns}
                className={ tableFileListSelected && tableFileListSelected.length > 0 ? 'table-import-one' : 'table-import-files' }
                rowKey={ (record) => record.id }
                pagination={false}
                rowSelection={ rowSelection }>
              </Table>
              <div className="content-dates-files">
                <span>{ `Total documents ${ tableFileListSelected && tableFileListSelected.length > 0 ? totalDocumentsFiles : fileList && fileList.length > 0 ? totalDocumentsFiles : totalDocumentsFiles }` }</span>
                <span className="space-dates"> | </span>
                <span>{ `Total pages ${ (tableFileListSelected && tableFileListSelected.length > 0) || (fileList && fileList.length > 0) ? totalFilesPages : totalPagesFiles }` }</span>
                <span className="space-dates"> | </span>
                <span>{ `Total ${ (tableFileListSelected && tableFileListSelected.length > 0) || (fileList && fileList.length > 0) ? sizeFiles : sizeFiles } KB` }</span>
              </div>
            </div>
            <div className="footer-content-buttons">
              { searchFindStep && !searchFindStep.completed &&
              <div className="tracker-content-links full_width">
                <div className="tracker-form-link">
                  <Checkbox defaultChecked={ sendEmailNotificationData } className="send-form" disabled={ tableFileListSelected.length === 0 } onChange={this.onChangeNotification}>Send Email Notification</Checkbox>
                </div>
              </div>
              }
              { searchFindStep && !searchFindStep.completed &&
            <div className="tracker-content-button">
              {
                loadingConfirmData ?
                  <div className="search-and-find-loading-container loading-import-files">
                    {/*<span>Please wait...</span>*/}
                    <ProgressLoadingFiles tableFileListSelected={tableFileListSelected} responseCheckAddDocuments={ responseCheckAddDocuments }
                                          totalDocumentsFiles={ totalDocumentsFiles } />
                    {/*<Spin className="finalize-loading" spinning={loadingConfirmData}/>*/}
                  </div> :
                  <Button className="button-blue-light button-check-id button-search float-right"
                          loading={loadingConfirmDataStatus}
                    disabled={(tableFileListSelected && tableFileListSelected.length === 0) || selectedRowKeys.length === 0} onClick={this.onStartAddDocuments}>CONFIRM DATA
                  </Button>
              }
            </div>
              }
            </div>
          </div>
        </div>
        <Modal className="modal-import-files" visible={ activeModalDelete } footer={ null } closable={ false }>
          <div className="modal-import-files-content">
            <div className="header-modal-delete">
              <span className="question-delete">{statusSelectedOption === 'select-file' ? 'Do you want to delete file?' : 'Do you want to delete all files?' }</span>
              <span className="name-file">{ fileSelectedRemove.name ? fileSelectedRemove.name.substr(0, fileSelectedRemove.name.indexOf('.')) : '' }</span>
            </div>
            <div className="footer-modal-buttons footer-modal-delete">
              <Button className="action-cancel-box" onClick={ this.handleCancelDelete }>
                Cancel
              </Button>
              <Button className="action-import-box" onClick={statusSelectedOption === 'select-file' ? () => this.removeOnClickFile(fileSelectedRemove) : () => this.onRemoveAllFiles() }>
                Delete
              </Button>
            </div>
          </div>

        </Modal>
        <Modal className="modal-import-files" visible={ modalVisibleImportFiles } footer={ null } closable={ false }>
          <div className="modal-import-files-content">
            <div className="header-modal-import">
              {/*<img alt={imgFolderSmallParent} src={ imgFolderSmallParent }/>*/}
              <span className="icon-v18-import-1"></span>
              <span>Import</span>
            </div>
            <div className="content-import-files">
              <Radio.Group onChange={this.onChangeRadio} value={modeChoose}>
                <Radio value="all-files">Approved files only</Radio>
                <Radio value="all-box-files">All files</Radio>
                {/*<Radio value="choose-files">Select files</Radio>*/}
              </Radio.Group>
            </div>
            { visibleTreeFiles &&
              <div className={listFilesOnBox && listFilesOnBox.length > 0 ? 'content-tree-files' : 'content-tree-files content-tree-files-empty'}>
                <Tree
                  checkable
                  onCheck={this.onCheckFiles}
                  onSelect={ (selectedKeys, { node }) => this.onSelectKeysFile(selectedKeys, node) }
                  selectedKeys={selectedKeys}
                  checkedKeys={ checkedKeysFiles }
                >
                  {
                    this.generateTreeListFiles(listFilesOnBox)
                  }
                </Tree>
              </div>
            }
            <div className="footer-modal-buttons">
              <Button className="action-cancel-box" onClick={ this.handleCancelModalBox }>
                Cancel
              </Button>
              <Button className="action-import-box" disabled={modeChoose === '' || (modeChoose === 'choose-files' && checkedKeysFiles.length === 0) } onClick={ this.onClickImportFiles }>
                Import
              </Button>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    )
  }

}

export default ImportDataStep