import React from 'react'
import { Routes, Navigate, Route, BrowserRouter } from 'react-router-dom'
import DpiaAbout from './components/Dpia/About/About'
import BusinessProcessForm from './components/Dpia/BusinessProcess/BusinessProcessForm'
import DpiaDashboard from './components/Dpia/Dashboard/Dashboard'
import DpiaDashboardArchived from './components/Dpia/Dashboard/DashboardArchived'
import DpiaQuestions from './components/Dpia/Assessment/Questions'
import DpiaHelp from './components/Dpia/Help/Help'
import DpiaDetailReport from './components/Dpia/Reports/Detail'
import DpiaSummaryReport from './components/Dpia/Reports/Summary'
import DpiaShell from './components/Dpia/Shell/Shell'

import DpiaQuestionnaireForm from './components/Dpia/Questionnaires/QuestionnaireForm'
import DpiaTeamsForm from './components/Dpia/Questionnaires/TeamsForm'
import DpiaUsersForm from './components/Dpia/Questionnaires/UsersForm'
import DpiaQuestionnaireDashboard from './components/Dpia/QuestionnaireDashboard/QuestionnaireDashboard'
import DpiaQuestionnaireGlobal from './components/Dpia/Questionnaires/Globals/QuestionnaireGlobal'
import PiaResult from './components/Pia/PiaResult'
import PiaReport from './components/Pia/PiaReport'
import Email from './components/Public/Email'
import Login from './components/Public/Login'
import PasswordRecovery from './components/Public/PasswordRecovery'
import SetPassword from './components/Public/SetPassword'
import Shell from './components/Common/Shell'
// import SignUp from './components/Public/SignUp'
import Multitenancy from './components/Public/Multitenancy'
import MultitenancyAccept from './components/Public/MultitenancyAccept'
import OspDashboard from './components/Osp/Dashboard/Dashboard'
import OspDashboardOld from './components/Osp/DashboardOld/DashboardOld'
import OspInviteUser from './components/Osp/Users/InviteUser/InviteUser'
import MultitenancyInvitation from './components/Osp/Users/InviteUser/MultitenancyInvitation'
import OspManageUsers from './components/Osp/Users/ManageUsers/ManageUsers'
import NewUserForm from './components/Osp/Users/NewUserForm/NewUserForm'
import OspAddUser from './components/Osp/Users/AddUser/AddUser'
import EditUserForm from './components/Osp/Users/EditUserForm/EditUserForm'
import OspReviewActivityUser from './components/Osp/Users/ReviewActivityUser/ReviewActivityUser'
// import OspManageTeams from './components/Osp/Teams/ManageTeams/ManageTeams'
// import EditTeamForm from './components/Osp/Teams/EditTeamForm/EditTeamForm'
// import OspLogsForTeam from './components/Osp/Teams/LogsForTeam/LogsForTeam'
import OspNewTeamForm from './components/Osp/Teams/NewTeamForm/NewTeamForm'

import OspResources from './components/Osp/Resource/Resources'
import OspUserSettings from './components/Osp/Users/UserSettings/UserSettings'
// import MyPlan from './components/Osp/Users/MyPlan/MyPlan'
import SubjectAccess from './components/Sar/SubjectAccess/SubjectAccess'
import ExternFileUpload from './components/Sar/ExternFileUpload/ExternFileUpload'
import ExternFileFeedbackResponse from './components/Sar/ExternFileFeedbackResponse/ExternFileFeedbackResponse'
import ExternalFeedbackWorkflowTicketForm from './components/Sar/ExternalFeedbackWorkflowTicketForm/ExternalFeedbackWorkflowTicketForm'
import ExternFileFeedbackWorkflowResponse from './components/Sar/ExternFileFeedbackResponse/ExternFileFeedbackWorkflowResponse'
import OspUserSettingsChangePassword from './components/Osp/Users/UserSettingsChangePassword/UserSettingsChangePassword'

import SarDashboard from './components/Sar/Dashboard/Dashboard'
import Tracker from './components/Sar/Tracker/Tracker'
import TrackerPage from './components/Sar/Tracker/TrackerPage'
// import SarNewSearch from './components/Sar/NewSearch/NewSearch'
import SarSettings from './components/Sar/Settings/Settings'
// import SarAbout from './components/Sar/About/About'
import SarArchive from './components/Sar/Archive/Archive'
import SarHelpAndSupport from './components/Sar/HelpAndSupport/HelpAndSupport'
import TemplateEmail from './components/Sar/Settings/Email/TemplateEmail'
import EmailWork from './components/Sar/Settings/EmailsWorkflows/EmailWork'
import DataSourceRopa from './components/Sar/Settings/DataSource/DataSourceRopa'
import FormsWorkflow from './components/Sar/Settings/FormsWorkflow/FormsWorkflow'
import BuilderWorkflow from './components/Sar/Settings/BuilderWorkflow/BuilderWorkflow'
import TemplateWorkflow from './components/Sar/Settings/BuilderWorkflow/TemplateWorkflow'
import NotFoundPage from './components/Custom/NotFound'
import PrivacyAlertCreate from './components/Privacy/AlertCreate'
import PrivacyAlert from './components/Privacy/Alert'
import PrivacyCollectionCreate from './components/Privacy/CollectionCreate'
import PrivacyCollection from './components/Privacy/Collection'
import PrivacyConnectors from './components/Privacy/Connectors'
import PrivacyConnectorCreate from './components/Privacy/ConnectorCreate'
import PrivacyConnectorManage from './components/Privacy/ConnectorManage'
import PrivacyDashboard from './components/Privacy/Dashboard'
import PrivacyFiles from './components/Privacy/Files'
import PrivacyShell from './components/Privacy/Shell'
import PrivacyTopic from './components/Privacy/Topic'
import AnnotationManagement from './components/Sar/Settings/Annotation/AnnotationManagement'
import TagsDocuments from './components/Sar/Settings/Tags/TagsDocuments'
import AnnotationColor from './components/Sar/Settings/Annotation/AnnotationColor'
import TagsDocumentsAdmin from './components/Sar/Settings/TagsAdmin/TagsDocumentsAdmin'
import PrivateRoute from './private-routers/PrivateRouter'
import RequestTrackerManagement from './components/Admin/RequestTrackerManagement/RequestTrackerManagement'
import TokenManagement from './components/Admin/TokenManagement/TokenManagement'
import BrandingProfiles from './components/Admin/BrandingProfiles/BrandingProfiles'
import TemplateWorkflowAdmin from './components/Admin/TemplateWorkflowAdmin/TemplateWorkflowAdmin'
import FormsWorkflowAdmin from './components/Admin/FormsWorkflowAdmin/FormsWorkflowAdmin'
import EmailWorkAdmin from './components/Admin/EmailsWorkflowsAdmin/EmailWorkAdmin'
import ClientManagement from './components/Admin/ClientManagement/ClientManagement'
import ClientNewForm from './components/Admin/ClientManagement/ClientNewForm'
import ClientSendInviteTenant from './components/Admin/ClientManagement/ClientSendInviteTenant'
import UsersManagement from './components/Admin/UsersManagement/UsersManagement'
import ClientSubscriptionManagement from './components/Admin/SubscriptionManagement/ClientSubscriptionManagement'
import AdminShell from './components/Admin/Shell/AdminShell'
import AdminManagementShell from './components/Admin/Shell/AdminManagementShell'
import ClientEditForm from './components/Admin/ClientManagement/ClientEditForm'
import SarPrivateRouter from './private-routers/SarPrivateRouter'
import SarShell from './components/Sar/Shell/SarShell'
import AssetRegisterShell from './components/AssetRegister/Shell/AssetRegisterShell'
import AssetRegister from './components/AssetRegister/AssetRegisterPage/AssetRegister'
import SmartBoxSettings from './components/SmartBox/SmartBox/SmartBoxSettings'
import SmartBoxBusinessTermLists from './components/SmartBox/SmartBox/SmartBoxBusinessTermLists'
import SmartBoxBusinessTermList from './components/SmartBox/SmartBox/SmartBoxBusinessTermList'
import SmartBoxShell from './components/SmartBox/Shell/SmartBoxShell'
import SmartBoxFolderOpened from './components/SmartBox/SmartBox/SmartBoxFolderOpened'
import SmartBoxFolderSettings from './components/SmartBox/SmartBox/Settings/SmartBoxFolderSettings'
import SmartBoxFolderAnalysisSettings from './components/SmartBox/SmartBox/Settings/SmartBoxFolderAnalysisSettings'
import SmartBoxFolderDataInsights from './components/SmartBox/SmartBox/Settings/SmartBoxFolderDataInsights'
import SmartBoxFolderCull from './components/SmartBox/SmartBox/Settings/SmartBoxFolderCull'
import SmartBoxFolderRedaction from './components/SmartBox/SmartBox/Settings/SmartBoxFolderRedaction'
import SmartBoxFolderDataCollector from './components/SmartBox/SmartBox/Settings/SmartBoxFolderDataCollector'
import RegularExpressionsAll from './components/SmartBox/SmartBox/Settings/FolderSettings/RegularExpressionsAll'
import SmartBoxFolderSecuritySettings from './components/SmartBox/SmartBox/Settings/SmartBoxFolderSecuritySettings'
import Settings from './components/AssetRegister/SettingsPage/Settings'
import DocLockerShell from './components/DocLocker/Shell/DocLockerShell'
import CompanySettingsShell from './components/CompanySettings/Shell/CompanySettingsShell'
import ComplianceDocumentLocker from './components/DocLocker/ComplianceDocumentLocker/ComplianceDocumentLocker'
import DocLockerSettings from './components/DocLocker/SettingsPage/Settings'
import UserEditForm from './components/Admin/UsersManagement/UserEditForm'
import AssetRegisterPrivateRouter from './private-routers/AssetRegisterPrivateRouter'
import SmartBoxPrivateRouter from './private-routers/SmartBoxPrivateRouter'
import DocLockerPrivateRouter from './private-routers/DocLockerPrivateRouter'
import { Multitenant } from './components/Multitenance/Main/Multitenant'
import SmartBoxPage from './components/SmartBox/SmartBox/SmartBoxPage'
import WallBoard from './components/Admin/Wallboard/WallBoard'
import DpiaPrivateRouter from './private-routers/DpiaPrivateRouter'
import DataMappingPrivateRouter from './private-routers/DataMappingPrivateRouter'
import WorkflowPrivateRouter from './private-routers/WorkflowPrivateRouter'

import CompanySettings from './components/CompanySettings/CompanySettings/CompanySettings'
//back office
import BackOfficeProcesses from './components/Admin/BackOffice/Processes'
import BackOfficeWorkspaces from './components/Admin/BackOffice/Workspaces'
import ProcessContext from './contexts/Processes'
import BackOfficeCategory from './components/Admin/BackOffice/Category'
import WorkspacesContext from './contexts/OspreyAnalytics/Workspaces'
import BackOfficeDataModelsContext from './contexts/OspreyAnalytics/DataModels'
import DataLinesContext from './contexts/OspreyAnalytics/DataLines'
import BackOfficeDataLines from './components/Admin/BackOffice/DataLines'
import BackOfficeCreateDataLine from './components/Admin/BackOffice/CreateDataLine'
import BackOfficeEditDataLine from './components/Admin/BackOffice/EditDataLine'
import ConnectorsPage from "./components/ConnectorsPage";
import SourceMappingPage from "./components/SourceMapping/SourceMappingPage";
import ApiConnector from "./components/ApiConnector/ApiConnector";
import AdditionalEnvironmentPrivateRouter from './private-routers/AdditionalEnvironmentPrivateRouter'
import BusinessProcessPage from "./components/BusinessProcess/BusinessProcessPage";
import {DashboardWorkflow} from "./components/Sar/Settings/WorkflowApp/Dashboard/DashboardWorkflow";
import {ArchiveWorkflow} from "./components/Sar/Settings/WorkflowApp/Dashboard/ArchiveWorkflow";

class UserRedirect extends React.PureComponent {
  render() {
    // const accessToken = localStorage.getItem('accessToken')
      const tokenId = JSON.parse(localStorage.getItem('tokenData'))?.tokenId
  return (
      <div>
        { tokenId &&
        <Navigate to="/smart-box/management" replace={true}/>
        }

        { !tokenId &&
        <Navigate to="/osp/login"  replace={true}/>
        }
      </div>
    )
  }
}

const OspRouter = () => (
    <Routes>
      <Route element={<DpiaShell />} >
        <Route path='dashboard' element={ <OspDashboard/> }/>
        <Route path='dashboard_old' element={ <OspDashboardOld/> }/>
        <Route path='user/settings' element={ <OspUserSettings/> }/>
        {/*<Route  path='user/my_plan' element={ MyPlan }/>*/}
        <Route path='user/settings/change-password' element={ <OspUserSettingsChangePassword/> }/>
        <Route path='invitations' element={ <OspInviteUser/> }/>
        <Route element={<AdditionalEnvironmentPrivateRouter/>} >
          <Route path='multitenancy-invitations' element={ <MultitenancyInvitation/> }/>
        </Route>
        <Route path='manage/users' element={ <OspManageUsers/> }/>
        <Route path='manage/users/new' element={ <NewUserForm/> }/>
        <Route path='manage/adduser' element={ <OspAddUser/> }/>
        <Route path='manage/users/edit/:userId' element={ <EditUserForm/> }/>
        <Route path='manage/users/:userId/reviewactivityuser' element={ <OspReviewActivityUser/> }/>
        {/*<Route  path='/osp/manage/teams' element={ OspManageTeams }/>*/}
        {/*<Route  path='/osp/manage/teams/edit/:teamId' element={ EditTeamForm }/>*/}
        {/*<Route  path='/osp/manage/teams/:teamId/logsforteam' element={ OspLogsForTeam }/>*/}
        <Route path='manage/newteamform' element={ <OspNewTeamForm/> }/>
        <Route path='resources' element={ <OspResources/> }/>
        <Route path='multitenant' element={ <Multitenant/> }/>
        <Route path='*' element={ <OspDashboard/> }/>
      </Route>

    </Routes>
)

const DpiaRouter = () => (
    <Routes>
      <Route element= {<DpiaShell/>} >
        <Route element ={<DpiaPrivateRouter />} > 
          <Route path='dashboard' element={ <DpiaDashboard/> }/>
          <Route path='dashboard/archived' element={ <DpiaDashboardArchived/> }/>
          <Route path='assessment/:assessmentId' element={ <DpiaQuestions/> }/>
          <Route path='assessment/:assessmentId/result' element={ <PiaResult/> }/>
          <Route path='assessment/:assessmentId/report' element={ <PiaReport/> }/>
          <Route path='report/:processId/:assessmentId' element={ <DpiaSummaryReport/> }/>
          <Route path='report/detail/:processId/:assessmentId' element={ <DpiaDetailReport/> }/>
          <Route path='edit/:processId' element={ <BusinessProcessForm/> }/>
          <Route path='new' element={ <BusinessProcessForm/> }/>
          <Route path='about' element={ <DpiaAbout/> }/>
          <Route path='help' element={ <DpiaHelp/> }/>
          <Route path='questionnaires' element={ <DpiaQuestionnaireDashboard/> }/>
          <Route path='questionnaires/new' element={ <DpiaQuestionnaireForm/> }/>
          <Route path='questionnaires/newform' element={ <DpiaTeamsForm/> }/>
          <Route path='questionnaires/newformusers' element={ <DpiaUsersForm/> }/>
          <Route path='questionnaires/:questionnaireId/versions/:versionId/edit' element={ <DpiaQuestionnaireForm/> }/>
          <Route path='questionnaires/:questionnaireId/versions/:versionId' element={ <DpiaQuestionnaireGlobal/> }/>
          <Route path='*' element={ <DpiaDashboard/> }/>
        </Route>
      </Route>
    </Routes>
)

const AdminRouter = () => (
<ProcessContext>
    <WorkspacesContext>
      <BackOfficeDataModelsContext>
        <DataLinesContext>
          <Routes>
            <Route element={ <AdminShell/>}>
              <Route element={ <AdminManagementShell/>}>
                <Route element={ <PrivateRoute roles={ [ 'god_admin' ]} domain="application"/>}>
                  <Route path="client-data" element={ <ClientManagement/> } />
                  <Route path="request-tracker" element={ <RequestTrackerManagement/> } />
                  {/*<Route path="token-management" element={ <TokenManagement/> } />*/}
                  <Route path="branding-profiles" element={ <BrandingProfiles/> } />
                  <Route path="template-workflow" element={ <TemplateWorkflowAdmin/> } />
                  <Route path="forms-workflow" element={ <FormsWorkflowAdmin/> } />
                  <Route path="email-workflow" element={ <EmailWorkAdmin/> } />
                  <Route path="client-data/new" element={ <ClientNewForm/> } />
                  <Route path="client-data/new-invite" element={ <ClientSendInviteTenant/> } />
                  <Route path="client-data/:id" element={ <ClientEditForm/> } />
                  <Route path="user-data" element={ <UsersManagement/> } />
                  <Route path="user-data/:id" element={ <UserEditForm/> } />
                  <Route path="clients-subscriptions" element={ <ClientSubscriptionManagement/> }/>
                  <Route path="email-templates" element={ <TemplateEmail/> }/>
                  <Route path="templates" element={ <EmailWork/> }/>
                  <Route path="data-source" element={ <DataSourceRopa/> }/>
                  <Route path="annotation-colors" element={ <AnnotationColor/> }/>
                  <Route path="tag-document" element={ <TagsDocumentsAdmin/> }/>
                  <Route path="wallboard" element={ <WallBoard/> }/>
                  <Route path="processes" element={ <BackOfficeProcesses/> } />
                  <Route path="workspaces" element={ <BackOfficeWorkspaces/> }/>
                  <Route path="category/:id" element={ <BackOfficeCategory/> }/>
                  <Route path="datalines" element={ <BackOfficeDataLines/> } />
                  <Route path="datalines/new" element={ <BackOfficeCreateDataLine/> }/>
                  <Route path="datalines/edit/:id" element={ <BackOfficeEditDataLine/> }/>
                  {/* <Navigate to='/admin/client-data'  replace={true}/>
                  <Navigate to='/admin/client-data'  replace={true}/> */}
                  </Route>
                </Route>
              </Route>
            </Routes>
          </DataLinesContext>
        </BackOfficeDataModelsContext>
      </WorkspacesContext>
    </ProcessContext>
)

const SarRouter = () => (
  <Routes>
    <Route element = {<SarShell />} > 
      <Route element ={ <SarPrivateRouter /> } >
        <Route path='dashboard' element={ <SarDashboard/> }/>
        <Route path='tracker/:sarId' element={ <Tracker/> }/>
        <Route path='tracker-page/' element={ <TrackerPage/> }/>
        <Route path='settings' element={ <SarSettings/> }/>
        <Route path='archive' element={ <SarArchive/> }/>
        <Route path='help' element={ <SarHelpAndSupport/> }/>
        <Route path='settings/template-email' element={ <TemplateEmail/> }/>
        <Route path='settings/annotation-management' element={ <AnnotationManagement/> }/>
        <Route path='settings/tags' element={ <TagsDocuments/> }/>
        <Route path='settings/annotation-color' element={ <AnnotationColor/> }/>
        {/*<SarPrivateRouter  path='/sar/about' element={ SarAbout }/>*/}
        {/*<SarPrivateRouter  path='/sar/newsearch' element={ SarNewSearch }/>*/}
        <Route path='*' element={ <SarDashboard/> }/>
      </Route>
    </Route>
  </Routes>
)

const AssetRegisterRouter = () => (
    <Routes>
      <Route element = {<AssetRegisterShell />} >
        <Route element = { <AssetRegisterPrivateRouter /> } >
          <Route path='management' element={ <AssetRegister/> }/>
          <Route path='settings' element={ <Settings/> }/>
          <Route path='*' element={ <AssetRegister/> }/>
        </Route>
      </Route>
    </Routes>
)

const DocLockerRouter = () => (
  <Routes>
    <Route element={<DocLockerShell/>} >
      <Route element = {<DocLockerPrivateRouter/>}>
        <Route path='management' element={ <ComplianceDocumentLocker/> }/>
        <Route path='settings' element={ <DocLockerSettings/> }/>
        <Route path='*' element={ <ComplianceDocumentLocker/> }/>
      </Route>
      <Route path='/' element={ <AssetRegister/> }/>
    </Route>
  </Routes>
)

const SmartBoxRouter = () => (
    <Routes>
      <Route  element = {<SmartBoxShell/> } >
        <Route path='management' element= {<SmartBoxPage/>} ></Route>
        <Route element = { <SmartBoxPrivateRouter /> } >
          <Route path='settings' element={ <SmartBoxSettings/> }/>
          <Route path='regular-expressions/settings' element={ <RegularExpressionsAll/> }/>
          <Route path='business-term-lists/settings' element={ <SmartBoxBusinessTermLists/> }/>
          <Route path='business-term-list/:id' element={ <SmartBoxBusinessTermList/> }/>
          <Route path='folder/:id/settings' element={ <SmartBoxFolderSettings/> }/>
          <Route path='folder/:id/analysis-settings' element={ <SmartBoxFolderAnalysisSettings/> }/>
          <Route path='folder/:id/data-insights/*' element={ <SmartBoxFolderDataInsights/> }/>
          <Route path='folder/:id/cull/*' element={ <SmartBoxFolderCull/> }/>
          <Route path='folder/:id/folder-redaction' element={ <SmartBoxFolderRedaction/> }/>
          <Route path='folder/:id/data-collector' element={ <SmartBoxFolderDataCollector/> }/>
          <Route path='folder/:id/security-settings' element={ <SmartBoxFolderSecuritySettings/> }/>
          <Route path='folder/:id' element={ <SmartBoxFolderOpened/> }/>
        </Route>
        <Route path="*"  element= {<SmartBoxPage/>}/>
      </Route>
    </Routes>
)
const CompanySettingsRouter = () => (
  
  <Routes>
    <Route element = { <CompanySettingsShell />} >
      <Route  path='settings' element={ <CompanySettings/> }/>
      <Route  path='*' element={ <CompanySettings/> }/>
    </Route>
  </Routes>
)
const PrivacyRouter = () => (
  <Routes>
    <Route element = {<PrivacyShell/>} >
      <Route path='/privacy/alerts' element={  <PrivacyAlert/> }/>
      <Route path='/privacy/alerts/:id' element={  <PrivacyAlert/> }/>
      <Route path='/privacy/alert/edit/:id' element={  <PrivacyAlertCreate/> }/>
      <Route path='/privacy/collections' element={ <PrivacyCollection/> }/>
      <Route path='/privacy/collection/edit/:id' element={ <PrivacyCollectionCreate/> }/>
      <Route path='/privacy/connectors' element={ <PrivacyConnectors/> }/>
      <Route path='/privacy/connector/new' element={ <PrivacyConnectorCreate/> }/>
      <Route path='/privacy/connector/:id' element={ <PrivacyConnectorCreate/> }/>
      <Route path='/privacy/connectors/manage' element={ <PrivacyConnectorManage/> }/>
      <Route path='/privacy/dashboard' element={ <PrivacyDashboard/> }/>
      <Route path='/privacy/files' element={ <PrivacyFiles/> }/>
      <Route path='/privacy/files/:id' element={ <PrivacyFiles/> }/>
      <Route path='/privacy/topics' element={ <PrivacyTopic/> }/>
      <Route path='*' element={  <PrivacyAlert/> }/>
    </Route>
  </Routes>
)

const DataMappingRouter = () => (
    <Routes>
      <Route element = { <DataMappingPrivateRouter /> } >
        <Route path='source-mapping' element={ <SourceMappingPage/> }/>
        <Route path='business-process' element={ <BusinessProcessPage/> }/>
        <Route path='connectors' element={ <ConnectorsPage/> }/>
        <Route path='api-connector' element={ <ApiConnector/> }/>
        <Route path='*' element={ <ConnectorsPage/> }/>
      </Route>
    </Routes>
)

const WorkflowRouter = () => (
    <Routes>
      <Route element = { <WorkflowPrivateRouter /> } >
        <Route path='workflow-dashboard' element={ <DashboardWorkflow/> }/>
        <Route path='workflow-archive' element={ <ArchiveWorkflow/> }/>
        <Route path='templates' element={ <EmailWork/> }/>
        <Route path='data-source' element={ <DataSourceRopa/> }/>
        <Route path='forms' element={ <FormsWorkflow/> }/>
        {/*<Route path='builder' element={ <BuilderWorkflow/> }/>*/}
        <Route path='template-workflow' element={ <TemplateWorkflow/> }/>
        <Route path='*' element={ <EmailWork/> }/>
      </Route>
    </Routes>
)
const RoutesV6 = () => {
    return (
  <BrowserRouter>
    <Routes>
      <Route path='/' element={ <UserRedirect/> }/>
      <Route path='/osp/login' element={ <Login/> }/>
      <Route path='/osp/email' element={ <Email/> }/>
      <Route path='/osp/password-recovery' element={ <PasswordRecovery/> }/>
      <Route path='/osp/set-password' element={ <SetPassword/> }/>
      {/*<Route  path='/osp/signup' element={ SignUp }/>*/}
      <Route path='/osp/multitenancy/:token' element={ <Multitenancy/> }/>
      <Route path='/osp/multitenancy/accept/:token' element={ <MultitenancyAccept/> }/>
      <Route path='/subject-access' element={ <SubjectAccess/> }/>
      <Route path='/subject-access/:id' element={ <SubjectAccess/> }/>
      <Route path='admin/*' element={ <AdminRouter/> }/>
      <Route path='/extern-file-upload/:id' element={ <ExternFileUpload/> }/>
      <Route path='/feedback/:id' element={ <ExternFileFeedbackResponse/> }/>
      <Route path='/v1/workflow-builder/feedback-workflow/:id' element={ <ExternFileFeedbackWorkflowResponse/> }/>
      <Route path='/v1/workflow-builder/feedback-workflow-ticket-form/:id' element={ <ExternalFeedbackWorkflowTicketForm/> }/>
      <Route path='*' element={ <NotFoundPage/> }/>
      <Route element={<Shell/>}>
        <Route path='osp/*' element={ <OspRouter/> }/>
        <Route path='dpia/*' element={ <DpiaRouter/> }/>
        <Route path='sar/*' element={ <SarRouter/> }/>
        <Route path='asset-register/*' element={ <AssetRegisterRouter/> }/>
        <Route path='doc-locker/*' element={ <DocLockerRouter/> }/>
        <Route path='smart-box/*' element={ <SmartBoxRouter/> }/>
        <Route path='company/*' element={ <CompanySettingsRouter/> }/>
        <Route path='privacy/*' element={ <PrivacyRouter/> }/>
        <Route path='data-mapping/*' element={ <DataMappingRouter/> }/>
        <Route path='sar/workflow/*' element={ <WorkflowRouter/> }/>
      </Route>
    </Routes>
  </BrowserRouter>
  )
}

export default RoutesV6