import React from 'react'
import './InputValidator.scss'

class InputValidator extends React.Component {
  render() {
    const {
      children,
      errors,
      field,
      showErrorMessage
    } = this.props

    const fieldErrors = errors[field]

    return (
      <React.Fragment>
        {
          !fieldErrors &&
          <div className="inputValidator__validWrapper">
            { children }
          </div>
        }
        {
          fieldErrors &&
          <div className="inputValidator__invalidWrapper">
            { children }
            { showErrorMessage &&
              fieldErrors.map((error) => (
                <span key={`option-error-${ error }`}>{ error }</span>
              ))
            }
          </div>
        }
      </React.Fragment>
    )
  }
}

InputValidator.defaultProps = {
  showErrorMessage: true
}

export default InputValidator