import React from 'react'
import { getDraggableItemStyle, reorderDraggableCards } from '../../../utils'
import PropTypes from 'prop-types'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import AdditionalField from './AdditionalField'

class AdditionalFieldsContainer extends React.Component {

  state = {
    additionalFields: [],
    isDragging: true
  }

  constructor(props) {
    super(props)
    this.onDragEnd = this.onDragEnd.bind(this)
  }

  componentDidMount() {
    if (this.props.additionalFields) {
      this.setState({
        additionalFields: this.props.additionalFields
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.additionalFields !== this.props.additionalFields) {
      this.setState({
        additionalFields: this.props.additionalFields
      })
    }
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const additionalFields = reorderDraggableCards(
      this.state.additionalFields,
      result.source.index,
      result.destination.index
    )

    this.setState({
      additionalFields,
      isDraggable: true
    }, () => this.props.updateItems(additionalFields))
  }

  addAdditionalField = () => {
    const newField = {
      domId: this.state.additionalFields.length + 1,
      columnOrder: this.state.additionalFields.length + 1,
      name: '',
      type: '',
      required: false,
      options: [],
      additional: true,
      history: false
    }
    this.setState({
      additionalFields: [ ...this.state.additionalFields, newField ]
    }, () => this.props.updateItems(this.state.additionalFields))
  }

  removeAdditionalField = () => {
    const valueToRemove = this.state.additionalFields.pop()
    this.setState({
      additionalFields: this.state.additionalFields.filter((field) => field.domId
        !== valueToRemove.domId)
    }, () => this.props.updateItems(this.state.additionalFields, valueToRemove))
  }

  addRegisterName = (record, value) => {
    const index = this.state.additionalFields.findIndex((field) => field.domId === record.domId)
    const newArray = [ ...this.state.additionalFields ]
    newArray[index].name = value
    this.setState({
      additionalFields: newArray
    }, () => this.props.updateItems(this.state.additionalFields))
  }

  addFieldType = (record, value) => {
    const index = this.state.additionalFields.findIndex((field) => field.domId === record.domId)
    const newArray = [ ...this.state.additionalFields ]
    newArray[index].type = value
    this.setState({
      additionalFields: newArray
    }, () => this.props.updateItems(this.state.additionalFields))
  }

  addIsRequired = (record, value) => {
    const index = this.state.additionalFields.findIndex((field) => field.domId === record.domId)
    const newArray = [ ...this.state.additionalFields ]
    newArray[index].required = value
    this.setState({
      additionalFields: newArray
    }, () => this.props.updateItems(this.state.additionalFields))
  }

  addOptions = (record, value, item) => {
    const index = this.state.additionalFields.findIndex((field) => field.domId === record.domId)
    const newArray = [ ...this.state.additionalFields ]
    newArray[index].options = value
    this.setState({
      additionalFields: newArray
    }, () => this.props.updateItems(this.state.additionalFields, '', item))
  }

  addDateFormat = (record, value) => {
    const index = this.state.additionalFields.findIndex((field) => field.domId === record.domId)
    const newArray = [ ...this.state.additionalFields ]
    newArray[index].dateFormat = value
    this.setState({
      additionalFields: newArray
    }, () => this.props.updateItems(this.state.additionalFields))
  }

  enableDragging = () => {
    this.setState({
      isDragging: false
    })
  }

  disableDragging = () => {
    this.setState({
      isDragging: true
    })
  }

  render() {
    const { additionalFields, isDragging } = this.state
    return (
      <React.Fragment>
        <div className="header">
          <span className="text">ADDITIONAL FIELDS</span>
          <span className="actions">
            <button className="icon-add-b" onClick={ () => this.addAdditionalField() }/>
            <button className="icon-minus-b" onClick={ () => this.removeAdditionalField() }
              disabled={ additionalFields && additionalFields.length <= 1 }/>
          </span>
        </div>
        {
          additionalFields && additionalFields.length > 0 &&
            <div className="content">
              <DragDropContext onDragEnd={ this.onDragEnd }>
                <Droppable droppableId="droppable">
                  { (provided, snapshot) => (
                    <div
                      { ...provided.droppableProps }
                      ref={ provided.innerRef }
                      // style={getListStyle(snapshot.isDraggingOver)}
                    >
                      { additionalFields.map((item, index) => (
                        <Draggable key={ item.domId || item.id } draggableId={ item.domId || item.id } index={ index }
                          isDragDisabled={ isDragging }>
                          { (provided, snapshot) => (
                            <div
                              className="item"
                              ref={ provided.innerRef }
                              { ...provided.draggableProps }
                              { ...provided.dragHandleProps }
                              style={ getDraggableItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              ) }>
                              <AdditionalField field={ item } onChangeName={ this.addRegisterName }
                                onChangeFieldType={ this.addFieldType }
                                onChangeIsRequired={ this.addIsRequired }
                                onChangeDateFormat={ this.addDateFormat }
                                onChangeOptions={ this.addOptions }
                                disableDragging={ this.disableDragging }
                                enableDragging={ this.enableDragging }
                                updateMode={ this.props.updateMode }
                                application={ this.props.application }/>
                            </div>
                          ) }
                        </Draggable>
                      )) }
                      { provided.placeholder }
                    </div>
                  ) }
                </Droppable>
              </DragDropContext>
            </div>
        }
      </React.Fragment>
    )
  }
}

AdditionalFieldsContainer.propTypes = {
  additionalFields: PropTypes.array.isRequired,
  updateItems: PropTypes.func.isRequired,
  updateMode: PropTypes.bool.isRequired,
  application: PropTypes.string.isRequired,
}

export default AdditionalFieldsContainer