export const mappedProcesses = (response) => {
  if (!response.data) return []
  if (!response.data.value) return []
  if (!response.data.value.processes) return []
  if (!response.data.value.processes.process) return []
  
  if (!(response.data.value.processes.process instanceof Array)) {
    const process = response.data.value.processes.process
    return [mapSingleProcess(process)]
  }
  
  return response.data.value.processes.process.map((process) => {
    return mapSingleProcess(process)
  })
}

export const mapSingleProcess = (process) => {
  
  const finalwindow = process.progresswindows.window.length-1
  let speed = 'High'
  let maxspeed = ''
  let hasSpeed = false
  const category = process.categoryId
  const id = process.id
  let description = process.description
  const total = process.progresswindows.totalSoFar
  const state = process.state
  const type = process.type
  
  // for crawler processes there is no speed.... yet
  if (process.progresswindows.window[finalwindow].processed)
  {
    hasSpeed = true
    speed = process.progresswindows.window[finalwindow].processed
    maxspeed = getMaxValueSuccessWindow(process.progresswindows.window)
  }
  
  if (process.id.startsWith('annotator_1'))
  {
    description = 'Finding personal information'
  }
  if (process.id.startsWith('annotator_5'))
  {
    description = 'Performing deep scan'
  }
  if (process.id.startsWith('crawler_'))
  {
    description = 'Performing light scan'
  }
  if (process.id.startsWith('system_categorysync'))
  {
    description = 'Classifying data into Collections'
  }
  if (process.id.startsWith('category_clustering'))
  {
    description = 'Finding topics within the data'
  }
  
  const ret = {
    category: category,
    id: id,
    description: description,
    total: total,
    speed: speed,
    maxSpeed: maxspeed,
    state: state,
    type: type,
    hasSpeed: hasSpeed,
    progresswindows: process.progresswindows
  }
  
  return ret
}

export const getMaxValueSuccessWindow = (arr) => {
  return arr.reduce((max, p) => p.processed > max ? p.processed : max, arr[0].processed)
}
