import React from 'react'

import {
  XYPlot, 
  XAxis, 
  YAxis, 
  VerticalRectSeries, 
  Highlight
} from 'react-vis'

class TimeSeries extends React.Component {
  state = {
    lastDrawLocation: null,
    selectionStart: null,
    selectionEnd: null
  }

  componentDidMount = () => {
  }

  componentWillUnmount = () => {}

  updateDragState = (area) => {
    this.setState({
      selectionStart: area && area.left,
      selectionEnd: area && area.right
    }, (e) => this.callOutsideMethod())
  }

  callOutsideMethod = () => {
    const roundedStart = Math.floor((this.state.selectionStart * 100) / 100)
    const roundedEnd = Math.floor((this.state.selectionEnd * 100) / 100)

    this.props.onDragEnd(roundedStart, roundedEnd)
  }

  render = () => {
    
    const { selectionStart, selectionEnd } = this.state
    const { data } = this.props
    const { width } = this.props
    const { height } = this.props

    return (
      <div>
        <XYPlot width={ width } height={ height } margin={ { left: 100 } }>
          <XAxis />
          <YAxis />
          <VerticalRectSeries
            data={ data }
            stroke="white"
            colorType="literal"
            getColor={(d) => {
              if (selectionStart === null || selectionEnd === null) {
                return '#1E96BE'
              }
              const inX = d.x >= selectionStart && d.x <= selectionEnd
              const inX0 = d.x0 >= selectionStart && d.x0 <= selectionEnd
              const inStart = selectionStart >= d.x0 && selectionStart <= d.x
              const inEnd = selectionEnd >= d.x0 && selectionEnd <= d.x

              return inStart || inEnd || inX || inX0 ? '#12939A' : '#1E96BE'
            }}
          />

          <Highlight
            color="#829AE3"
            drag
            enableY={ false }
            onDrag={ this.updateDragState }
            onDragEnd={ this.updateDragState }
          />
        </XYPlot>
      </div>
    )
  }
}

TimeSeries.displayName = 'TimeSeries'

export default TimeSeries