import React from 'react'
import {Select, Tree} from 'antd'
import {renderTreeSearchHistory} from "../../../utils/SmartBox/SmartBoxMethods";
import './SearchHistory.scss'
import Button from "../../Common/Button";
import Icon from "antd/es/icon";
import swal from "sweetalert2";
import {apiSmartBoxSaveNewQueryStatus} from "../../../api/SmartBox";
import CustomNotificationManager from "../../Common/CustomNotificationManager";
const { Option } = Select
class SearchHistory extends React.Component {
    state = {
        expandedKeys: [],
        autoExpandParent: true,
        categoriesVisibleSearch: [],
        visibleIconShow: '',
        visibleRedactedStatus: '',
        unCheckedKeys: [],
        visibleRedactedStatusChild: '',
        redactedListKeysItems: [],
        checkedKeys: [],
        selectedKeys: [],
        redactedKeys: [],
        redactedKeysExisting: [],
        redactedListKeys: [],
    }

    componentDidMount() {
        this.generateHtmlWord()
        //this.props.getSearchHistoryList()
        //this.generateRedactedKeysSaved()
        setTimeout(() => {
            this.generateRedactedKeysSaved()
        }, 2000)
    }
    generateRedactedKeysSaved = () => {
        const redactedKeysSaved = []
        if (this.props.treeDataSearchHistory && this.props.treeDataSearchHistory.length > 0) {
            this.props.treeDataSearchHistory.forEach((tree) => {
                tree.children && tree.children.length > 0 && tree.children.forEach((tree2) => {
                    if (this.props.alreadyRedactedKeys && this.props.alreadyRedactedKeys.standard && this.props.alreadyRedactedKeys.standard.length > 0) {
                       if (this.props.alreadyRedactedKeys.standard.includes(tree2.key)) {
                           redactedKeysSaved.push(tree2.key)
                       }
                    }
                })
            })
            this.setState({
                redactedListKeysItems: redactedKeysSaved,
                checkedKeys: redactedKeysSaved,
            })
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.searchMatchedElement !== this.props.searchMatchedElement) {
            this.generateHtmlWord()
            //this.props.getSearchHistoryList()
        }
    }
    onExpand = (expandedKeys) => {
        this.setState({
            expandedKeys,
            autoExpandParent: true,
        })
    }
   onSelect = (keys, info) => {

    }
    expandHideCategory = (e, key, action) => {
        e.stopPropagation()
        let { expandedKeys } = { ...this.state }
        if (action === 'add') {
            expandedKeys.push(key)
        } else {
            expandedKeys = expandedKeys.filter((eKey) => eKey !== key)
        }
        this.setState({
            expandedKeys,
            autoExpandParent: false,
        })
    }
    searchNextText() {
        this.props.highlightNextText()
    }
    searchPrevText() {
        this.props.highlightPrevText()
    }
    onSelectFindElementTreeOnSearch = (e, item) => {
        e.preventDefault()
        this.props.onSearchTextElementFind(item)
    }
    generateHtmlWord = () => {
         const { searchMatchedElement } = this.props
        searchMatchedElement && searchMatchedElement.length > 0 && searchMatchedElement.forEach((item) => {
            const element = item
            if (element) {
                element.id = 'matched-word--current'
                element.style.background = 'rgba(255, 145, 87, 0.3)'
            }
        })
    }
    showHideCategoryHistory = (e, category, state) => {
        const { searchMatchedElement } = this.props
        e.stopPropagation()
        const catVisible = []
        catVisible.push(category.key)
        const aiAnnotations = document.querySelectorAll(`.${ category.key }`)
        for (const aiAnnotate of aiAnnotations) {
            aiAnnotate.style.background = 'transparent'
        }
        if (!state) {
            this.setState({
                categoriesVisibleSearch: catVisible
            })
                searchMatchedElement && searchMatchedElement.length > 0 && searchMatchedElement.forEach((item) => {
                    if (item.name.includes(category.name)) {
                        const element = item
                        if (element) {
                            element.id = 'matched-word--current'
                            element.style.background = 'transparent'
                        }
                    }
                })
        } else {
            this.setState({
                categoriesVisibleSearch: []
            })
            searchMatchedElement && searchMatchedElement.length > 0 && searchMatchedElement.forEach((item) => {
                if (item.name.includes(category.name)) {
                    const element = item
                    if (element) {
                        element.id = 'matched-word--current'
                        element.style.background = 'rgba(255, 145, 87, 0.3)'
                    }
                }
            })
        }
    }
    onMouseEnterVisible = (item) => {
        this.setState({
            visibleIconShow: item.key
        })
    }
    onMouseLeaveVisible = () => {
        this.setState({
            visibleIconShow: ''
        })
    }
    onMouseEnterRedacted = (item) => {
        this.setState({
            visibleRedactedStatus: item.key
        })
    }
    onMouseLeaveRedacted = () => {
        this.setState({
            visibleRedactedStatus: ''
        })
    }
    onMouseEnterRedactedChild = (item) => {
        this.setState({
            visibleRedactedStatusChild: item.key
        })
    }
    onMouseLeaveRedactedChild = () => {
        this.setState({
            visibleRedactedStatusChild: ''
        })
    }
    drawMatchedWords = (redactedKeys, redactedList, redactedListKeys, item, classList) => {
        const { unCheckedKeys, redactedListKeysItems } = this.state
        const unCheckedKeysUpdate = []
        if (item && item.name) {
            if (redactedListKeysItems.includes(item.key)) {
                unCheckedKeysUpdate.push(item.key)
            }
        }
        const textMessageRedact = unCheckedKeysUpdate && unCheckedKeysUpdate.length > 0 ? `Remove ${ unCheckedKeysUpdate.length } selected redactions?` :
            unCheckedKeys && unCheckedKeys.length > 0 ? `Remove ${ unCheckedKeys.length } selected redactions?` : redactedList && redactedList.length === 1 ? `Redact ${ redactedList.length } selected?` : `Redact all ${ redactedList.length } selected?`
        swal.fire({
            text: 'Redact all selected?',
            html: `  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 19 25">
                  <g fill="none" fillRule="evenodd">
                    <path stroke=" #00D3D8" strokeWidth="4" d="M18 24V7.215L11.716 1H1v23h17z"/>
                    <path stroke="#00D3D8"  strokeWidth="4" d="M11 1v7h7"/>
                    <path fill="#00D3D8" d="M5 12h9v3H5zM5 17h6v3H5z"/>
                  </g>
                </svg>
          <h3 class="swall-redact-title">${ textMessageRedact }</h3>`,
            showCancelButton: true,
            confirmButtonColor: '#3DD8DB',
            cancelButtonColor: '#3DD8DB',
            confirmButtonText: unCheckedKeysUpdate && unCheckedKeysUpdate.length > 0 ? 'Unredact' :  unCheckedKeys && unCheckedKeys.length > 0 ? 'Unredact' : 'REDACT',
            customClass: {
                container: 'redact-swall-wr'
            }
        }).then((result) => {
            if (result.value) {
                if (unCheckedKeys.length === 0) {
                    this.onSaveNewRedactionSearch(redactedKeys)
                }
                this.setState({
                    redactedKeys: redactedKeys,
                    redactedListKeysItems: this.state.redactedListKeysItems.concat(redactedListKeys)
                }, () => {
                    let existingRedactedKeys= classList && classList.length > 0 ? new Set([...this.state.redactedKeysExisting, ...classList]) :
                        new Set([...this.state.redactedKeysExisting, ...redactedListKeys])
                    const newUnCheckedKeys = []
                    if (unCheckedKeys && unCheckedKeys.length) {
                        unCheckedKeys.forEach( (item) => {
                            if (existingRedactedKeys.has(item)) {
                                existingRedactedKeys.delete(item)
                            }
                            if (!redactedKeys.includes(item)) {
                                newUnCheckedKeys.push(item)
                            }
                        })
                    }
                    let redactedListKeysItemsFilters = []
                    let checkedKeysUpdate = []
                    if (item && item.children && item.children.length > 0) {
                        item.children.forEach((item2) => {
                            checkedKeysUpdate.push(item2.key)
                            if (redactedListKeysItems.includes(item2.key)) {
                                redactedListKeysItemsFilters = redactedListKeysItems.filter((item3) => item3 !== item2.key)
                            }
                        })
                    } else if (item && item.name) {
                        checkedKeysUpdate.push(item.key)
                        if (redactedListKeysItems.includes(item.key)) {
                            redactedListKeysItemsFilters = redactedListKeysItems.filter((item2) => item2 !== item.key)
                        }
                    }
                    if (classList && classList.length > 0) {
                        this.props.drawAllMatchedWordsByClassList(classList, newUnCheckedKeys, 'history')
                    } else
                    if (redactedListKeys && redactedListKeys.length > 0) {
                        this.props.drawAllMatchedWordsByClassList(redactedListKeys, newUnCheckedKeys, 'history')
                    }
                    this.setState({
                        unCheckedKeys: [],
                        redactedKeysExisting: existingRedactedKeys,
                        redactedListKeysItems: redactedListKeysItemsFilters,
                        redactedListKeys: this.state.redactedListKeys.concat(redactedListKeys)
                    })
                })
            } else {
                this.setState({
                    unCheckedKeys: []
                })
            }
        })
    }
    onSaveNewRedactionSearch = (redactedKeys) => {
        const data = {
            idDocument: this.props.idDocument,
            keyWordNonOcr: redactedKeys,
            keyWordOcr: [],
            updateRedactions: false
        }
        apiSmartBoxSaveNewQueryStatus(data).then((response) => {
        }).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
            }else{
                console.log(error)
            }
        })
    }
    onDefaultCategory = (e) => {
        e.stopPropagation()
        return true
    }
    onRedactAiSearch = (e, item) => {
        //const { checkedKeysObjectRefs } = this.state
        //const { treeDataSearchHistory } = this.props
        const redactedKeys = []
        const redactedList = []
        const redactedListKeys = []
      //   redactedListKeys.push(item.name)
      //   redactedListKeys.push(`${item.name}-found-parent-all`)
        if (item && item.children && item.children.length > 0) {
                item.children.forEach((item2) => {
                redactedKeys.push(`${item2.key} => ${item2.name} => ${item2.pageNumber}`)
                redactedList.push(item2)
                redactedListKeys.push(item2.key)
            })
        } else {
            redactedKeys.push(`${item.key} => ${item.name} => ${item.pageNumber}`)
            redactedList.push(item)
            redactedListKeys.push(item.key)
        }
        //const sortedByPageItems = checkedKeysObjectRefs.sort((a, b) => a.pageNumber > b.pageNumber ? 1 : -1)
        this.onRedactUpdate(redactedKeys, redactedList, redactedListKeys, item)
    }
    onRedactUpdate = (redactedKeys, redactedList, redactedListKeys, item) => {
        const { checkedKeysObjectRefs } = this.state
        //this.drawMatchedWords(redactedKeys, redactedList, redactedListKeys, item)
        this.setState({
            redactStatus: true
        }, () => {
            const sortedByPageItems = checkedKeysObjectRefs && checkedKeysObjectRefs.length > 0 && checkedKeysObjectRefs.sort((a, b) => a.pageNumber > b.pageNumber ? 1 : -1)
            if (sortedByPageItems && sortedByPageItems.length > 0) {
                this.drawMatchedWords(redactedKeys, redactedList, redactedListKeys, item, sortedByPageItems.map((item) => item.key))
            } else {
                this.drawMatchedWords(redactedKeys, redactedList, redactedListKeys, item)
            }
        })
    }
    extractLeafKeys = (node) => {
        const { unCheckedKeys } = this.state
        if (node.children && node.children.length) {
            node.children.forEach((item) => {
                this.extractLeafKeys(item.props)
            })
        } else {
            if (!unCheckedKeys.includes(node['data-key'])) {
                unCheckedKeys.push(node['data-key'])
            }
            this.setState({ unCheckedKeys })
        }
    }

    onCheck = (checkedKeys, { checkedNodes, checked, node }) => {
        const { unCheckedKeys } = this.state
        if (!checked) {
            this.extractLeafKeys(node.props)
        } else {
            if (unCheckedKeys.length) {
                unCheckedKeys.forEach((item, index) => {
                    if (checkedKeys.includes(item)) {
                        unCheckedKeys.splice(index, 1)
                    }
                })
            }
        }
        this.setState({
            checkedKeys,
            unCheckedKeys,
            checkedKeysObjectRefs: checkedNodes.map((node) => node.props.dataRef).filter((obj) => obj.key),
        })
    }
    findElementHistory(selectedKeys, node) {
        let { expandedKeys } = { ...this.state }
        if (node.props.dataRef.key.includes('found-parent-all') || node.props.dataRef.identifier === 'category-parent') {
            if (!expandedKeys.includes(node.props.dataRef.key)) {
                expandedKeys.push(node.props['data-key'])
                this.setState({
                    expandedKeys,
                    autoExpandParent: false
                })
            } else {
                expandedKeys = expandedKeys.filter((eKey) => eKey !== node.props['data-key'])
                this.setState({
                    expandedKeys,
                    autoExpandParent: false
                })
            }
        } else {
            this.setState({
                selectedWord: selectedKeys && selectedKeys.length > 0 ? selectedKeys[0] : ''
            }, () => {
                if (this.state.selectedWord) {
                    const { backgroundColor, pageNumber } = node.props.dataRef
                    const oldElement = document.querySelector('#matched-word--current')
                    if (oldElement) {
                        oldElement.removeAttribute('id')
                    }
                    if (+pageNumber !== +this.props.pageNumber) {
                        this.props.changePage(+pageNumber)
                    }
                    const element = document.getElementsByClassName(this.state.selectedWord)
                    if (element && element.length > 0) {
                        element[0].id = 'matched-word--current'
                        element[0].style.borderColor = backgroundColor.replace('0.3', '1')
                        element[0].scrollIntoView(false)
                    }
                } else {
                    const element = document.querySelector('#matched-word--current')
                    element.removeAttribute('id')
                }
            })
        }
    }
    onRedactAllSearchHistory = () => {
        const { treeDataSearchHistory, lockSmartViewChanges } = this.props
        if (!lockSmartViewChanges) {
            const selectAllChildren = []
            const uniqueTree = treeDataSearchHistory && treeDataSearchHistory.length > 0 && treeDataSearchHistory.filter((a, i) => treeDataSearchHistory.findIndex((s) => a.key.toLowerCase() === s.key.toLowerCase()) === i)
            const uniqueTreeUpdate = uniqueTree && uniqueTree.length > 0 && uniqueTree.filter((a) =>  a.name.toLowerCase() !== '')
            uniqueTreeUpdate && uniqueTreeUpdate.length > 0 && uniqueTreeUpdate.forEach((tree) => {
                tree && tree.children && tree.children.length > 0 && tree.children.forEach((tree2) => {
                    selectAllChildren.push(tree2.key)
                })
            })
            this.onRedactAiAllTag(selectAllChildren)
        }
    }
    onRedactAiAllTag = (selectAllChildren) => {
        if (selectAllChildren && selectAllChildren.length > 0) {
            const sortedByPageItems = selectAllChildren.sort((a, b) => a.pageNumber > b.pageNumber ? 1 : -1)
            this.drawMatchedAllWordsHistory(sortedByPageItems.map((item) => item))
        }
    }
    drawMatchedAllWordsHistory = (classHistory) => {
        const newUnCheckedKeys = []
        const textMessageRedact = classHistory && classHistory.length === 1 ? `Redact ${ classHistory.length } selected?` : `Redact all ${ classHistory.length } selected?`
        swal.fire({
            text: 'Redact all selected?',
            html: `<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 19 25">
                  <g fill="none" fillRule="evenodd">
                    <path stroke=" #00D3D8" strokeWidth="4" d="M18 24V7.215L11.716 1H1v23h17z"/>
                    <path stroke="#00D3D8"  strokeWidth="4" d="M11 1v7h7"/>
                    <path fill="#00D3D8" d="M5 12h9v3H5zM5 17h6v3H5z"/>
                  </g>
                </svg>
          <h3 class="swall-redact-title"> ${ textMessageRedact }</h3>`,
            showCancelButton: true,
            confirmButtonColor: '#3DD8DB',
            cancelButtonColor: '#3DD8DB',
            confirmButtonText: 'REDACT',
            customClass: {
                container: 'redact-swall-wr'
            }
        }).then(() => {
            if (classHistory && classHistory.length > 0) {
                this.onSaveNewRedactionSearch(classHistory)
                this.setState({
                    redactedKeys: classHistory,
                    redactedListKeys: classHistory,
                }, () => {
                    this.props.drawAllMatchedWordsByClassList(classHistory, newUnCheckedKeys, 'history')
                })
            }
        })
    }
    render() {
        const { expandedKeys, autoExpandParent, categoriesVisibleSearch, visibleIconShow, visibleRedactedStatus, visibleRedactedStatusChild, redactedListKeysItems,
            checkedKeys, redactedListKeys } = this.state
        const { treeDataSearchHistory, matchedText } = this.props
        const searchHistoryFinally = []
        const uniqueTreeDataSearchHistory = treeDataSearchHistory && treeDataSearchHistory.length > 0 && treeDataSearchHistory.filter((a, i) => treeDataSearchHistory.findIndex((s) => a.key.toLowerCase() === s.key.toLowerCase()) === i)
        const uniqueTreeDataSearchHistory2 = uniqueTreeDataSearchHistory && uniqueTreeDataSearchHistory.length > 0 && uniqueTreeDataSearchHistory.filter((a) =>  a.name.toLowerCase() !== '')
        if (uniqueTreeDataSearchHistory2 && uniqueTreeDataSearchHistory2.length > 0) {
            uniqueTreeDataSearchHistory2.forEach((item) => {
                if (item && item.children && item.children.length > 0) {
                    searchHistoryFinally.push(item)
                }
            })
        }
        return (
            this.props.isDocumentReady && <div className="search-history-page">
                <div className="search-history-page__container">
                    <div className="content-tree-search-history">
                        <div className="header-action-ai-results">
                            <div className="content-option-ai">
                                <span className="icon-v17-redaction-hover" onClick={(e) => this.onRedactAllSearchHistory(e)}></span>
                                <Select
                                    placeholder="Show all"
                                    className='custom-option-review'
                                    dropdownClassName="custom-option-review-history"
                                >
                                    <Option className="item-options-review" key={1} value="review-all">
                                        show all
                                    </Option>
                                    <Option className="item-options-review" key={2} value="review-redacted">
                                        redacted
                                    </Option>
                                    <Option className="item-options-review" key={3} value="review-annotated">
                                        annotated
                                    </Option>
                                </Select>
                                </div>
                            <div className="wr-review-redact redacted-selected">
                                <div className="button-control">
                                    <Button type="primary" theme="button-control button-control__left"
                                        onClick={() => this.searchPrevText()}
                                        disabled={matchedText && matchedText.length === 0}>
                                        <Icon type="left"/>
                                    </Button>
                                    <Button type="primary" theme="button-control button-control__right"
                                            onClick={() => this.searchNextText()}
                                            disabled={matchedText && matchedText.length === 0}>
                                        <Icon type="right"/>
                                    </Button>
                                </div>
                            </div>
                            </div>
                        { this.props.isDocumentReady && searchHistoryFinally && searchHistoryFinally.length > 0 &&
                            <Tree
                                checkable
                                onExpand={this.onExpand}
                                defaultExpandAll={true}
                                expandedKeys={expandedKeys}
                                onSelect={ (selectedKeys, { node }) => this.findElementHistory(selectedKeys, node) }
                                onCheck={this.onCheck}
                                checkedKeys={ checkedKeys }
                                selectedKeys={ this.state.selectedKeys }
                                autoExpandParent={autoExpandParent}>
                                {
                                    renderTreeSearchHistory(
                                        searchHistoryFinally,
                                        expandedKeys,
                                        this.expandHideCategory,
                                    this.onSelectFindElementTreeOnSearch,
                                    this.showHideCategoryHistory,
                                        categoriesVisibleSearch,
                                    this.onMouseEnterVisible,
                                    this.onMouseLeaveVisible,
                                        visibleIconShow,
                                    this.onMouseEnterRedacted,
                                    this.onMouseLeaveRedacted,
                                        visibleRedactedStatus,
                                    this.onRedactAiSearch,
                                        visibleRedactedStatusChild,
                                       this.onMouseEnterRedactedChild,
                                    this.onMouseLeaveRedactedChild,
                                        redactedListKeysItems,
                                    this.onDefaultCategory,
                                        checkedKeys,
                                        redactedListKeys)
                                }
                            </Tree>
                        }
                        </div>
                    </div>
                </div>
        )
    }
}

SearchHistory.propTypes = {
}

export default SearchHistory