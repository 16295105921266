import React, { Component } from 'react'
import './DocLockerShell.scss'
import { connect } from 'react-redux'
import { Outlet } from 'react-router-dom'

export class DocLockerShell extends Component {
  constructor() {
    super()
  }

  render() {
    return (
      <div>
        <Outlet />
      </div>
    )
  }
}

const stateMap = (state) => ({
  isLoading: state.shellReducer.shell.loading
})

export default connect(stateMap)(DocLockerShell)
