import React, { Component } from 'react'
import '../../style/common.scss'
import './SignUp.css'
import { checkMultitenancyTokenOldUser } from '../../api/User'
import { Link } from 'react-router-dom'
import LogoOsprey from '../../assets/head-logo-osprey.png'

export class MultitenancyAccept extends Component {
  constructor() {
    super()
    this.state = {
      success: true
    }
  }

  componentDidMount() {
    if (this.props.params) {
      const token = this.props.params.token
      checkMultitenancyTokenOldUser(token)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.setState({ success: true })
          }
        })
    }
  }

  render() {
    const { success } = this.state
    return (
      <div className="sign-up-page new-sign-up-page">
        <div className="multitenancy">
          <div className="multitenancy__header">
            <div className="formHeader-logo">
              <img src={ LogoOsprey } alt="Osprey" />
            </div>
          </div>
          { success &&
          <div className="multitenancy__body">
            <h2>Welcome to Osprey</h2>
            <p>Thank you for accepting the tenant invitation!</p>
            <div className="login-btn">
              <Link to="/osp/login">&#8249; Login</Link>
            </div>
          </div>
          }
          { !success &&
          <div className="multitenancy__body">
            <p>You don't have permission on this page.</p>
            <div className="login-btn">
              <Link to="/osp">&#8249; Back to home</Link>
            </div>
          </div>
          }
        </div>
      </div>
    )
  }
}

export default MultitenancyAccept