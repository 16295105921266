import React from 'react'
import './AssetRegisterComponents.scss'

const TextArea = (props) => {
  return (
    <div className="textarea-component">
      <textarea placeholder="Text goes here..." className="textarea-component__input" rows={4} readOnly/>
    </div>
  )
}

export default TextArea