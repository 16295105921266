import React from 'react'
import { apiSarComplete } from '../../../../api/Sar'
import CustomNotificationManager from '../../../Common/CustomNotificationManager'
import moment from 'moment'
import swal from 'sweetalert2'
import imgFolderSmallParent from '../../../../assets/smart-folder-medium-parent.svg'
import { CompleteStepCard } from './Components/CompleteStepCard'
import { Navigate } from 'react-router-dom'

class CompleteStep extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loadingCompletSar: false,
      feedbackResponseTrue: 'The subject has allowed you to keep all the data.',
      feedbackResponseFalse: 'The subject has decided to delete all the data you had about him.',
      visibleContent: false,
      valueSelectedBox: '',
      daysToCompleteRequest: 0,
      deliveredDocuments: 0,
      redirectPathDashboard: false
    }
  }

  componentDidMount() {
    const lastSarStepCompleted = this.props.sendStep ? this.props.sendStep.completed : false
    if (this.props.sar && this.props.sar.start_date && lastSarStepCompleted) {
      this.extractDataForFinalStep()
    }
  }

  extractDataForFinalStep = () => {
    if (this.props.sar && this.props.sar.start_date && this.props.completeStep) {
      const deliveredDocuments = this.props.completeStep.nbOfDocuments ? this.props.completeStep.nbOfDocuments : ''
      const deliveredTime = this.props.sar.completionTime ? this.props.sar.completionTime : [0, 0, 0, 30]
      let daysToCompleteRequest = ''
      if (deliveredTime && deliveredTime.length) {
        let selected = false
        deliveredTime.forEach((item, key) => {
          if (item && selected === false) {
            switch (key) {
              case 0: daysToCompleteRequest = `${ item } days`; selected = true; break
              case 1: daysToCompleteRequest = `${ item } hours`; selected = true; break
              case 2: daysToCompleteRequest = `${ item } minutes`; selected = true; break
              default: daysToCompleteRequest = `${ item } seconds`; selected = true
            }
          }
        })
      }
      this.setState({ daysToCompleteRequest, deliveredDocuments })
    }
  }

  handleSendCompleteSar = () => {
    const sarFeedbackResponse = this.props.sar.sarFeedbackResponse
    this.setState({
      loadingCompletSar: true
    }, () => {
      apiSarComplete(this.props.sar.id, sarFeedbackResponse)
        .then((response) => {
          this.props.refreshData(response.data)
          CustomNotificationManager.success('Subject Access Request completed.', 'Success')
          this.setState({
            loadingCompletSar: false
          })
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
      })
    })
  }

  generateDate(date) {
    return moment(date).format('DD/MM/YYYY HH:mm')
  }
  completeSarClicked(e) {
    e.preventDefault()
    swal.fire({
      text: 'Do you wish to mark this SAR as complete?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3DD8DB',
      cancelButtonColor: '#D33D33',
      confirmButtonText: 'Yes, complete SAR'
    }).then((result) => {
      if (result.value) {
        this.handleSendCompleteSar()
      } else {
        CustomNotificationManager.info('Action cancelled', 'Aborted')
      }
    })
  }

  onClickBackStep = () => {
    const { current, searchFindStep, sendStep, sar, respondStep, checkIdStep, initialRequestStep, acknowledgeStep } = this.props
    if (!sendStep && searchFindStep && searchFindStep.completed && sar && sar.lastStep === 'COMPLETE') {
      this.props.beckStep(current - 2)
    } else if (!searchFindStep && respondStep && respondStep.completed && sar && sar.lastStep === 'COMPLETE') {
      this.props.beckStep(current - 3)
    } else if (!respondStep && checkIdStep && checkIdStep.completed && sar && sar.lastStep === 'COMPLETE') {
      this.props.beckStep(current - 4)
    } else if (!acknowledgeStep && initialRequestStep && initialRequestStep.completed && sar && sar.lastStep === 'COMPLETE') {
      this.props.beckStep(current - 6)
    } else {
      this.props.beckStep(current - 1)
    }

  }
 onClickNextStep = () => {
  this.setState({
    redirectPathDashboard: true
  })
 }
 render() {
   const { valueSelectedBox, daysToCompleteRequest, deliveredDocuments, redirectPathDashboard } = this.state
   const { sar, valueAttachBox, sendStep } = this.props
   const lastSarStepCompleted = sendStep ? sendStep.completed : false
   const path = `/sar/dashboard`
   if (redirectPathDashboard) {
     return <Navigate to={ path } />
   }
   return (
     <React.Fragment>
       <div className="tracker-header-steps">
         <div className="contain-name-back-step">
           <div className="back-step back-step-hover">
             <span onClick={this.onClickBackStep} className="icon-v10-back"></span>
           </div>
           <div className="tracker-fullname-contain">
             <span className="tracker-surname">{`${ sar.forename } ${ sar.surname }`}</span>
           </div>
         </div>
         <div className="attach-box-contain">
           { (valueAttachBox || this.props.sar.boxName) ?
             <img alt={ valueSelectedBox } src={ imgFolderSmallParent }/>:
             <span className="icon-v10-smart-folder-medium-parent-2"></span>
           }
           <span className="attach-box disabled-attach-box">
             { valueAttachBox ? valueAttachBox : this.props.sar.boxName ? this.props.sar.boxName : 'Attach a box' }
           </span>
         </div>
         <div className="contain-save-last-step">
           <div className="save-close-contain">
             <span className="icon-v10-save"></span>
             <span className="save-and-close">
             Save & Close
             </span>
           </div>
           <div className="next-step next-step-hover">
             <span onClick={this.onClickNextStep} className="icon-v10-forth"></span>
           </div>
         </div>
       </div>
       <div className="tracker-box" style={{ marginTop: 0 }}>
         <div className="tracker-form new-tracker-form-complete">
           { lastSarStepCompleted && sar && sar.sarFeedbackResponse !== null ?
             <div className="step-complete">
               <div className="badge-wrapper">
                {/* <span className={ sar && sar.sarFeedbackResponse !== null ? 'icon-v10-completed feedback-response-true' : 'icon-v10-completed feedback-response-false' } />*/}
                 <span className={ sar && sar.sarFeedbackResponse === true  ? 'icon-v10-completed feedback-response-true' : 'icon-v10-completed feedback-response-false' } />

                 {sar && sar.sarFeedbackResponse === true  ?
                     <p className="badge-text">Congratulations! The Subject Access Request has been successfully completed. The subject has also allowed you to <span className="badge-text__special">keep all data</span></p>
                     :
                     <p className="badge-text">Congratulations! The Subject Access Request has been successfully completed. The subject has decided to  <span className="badge-text__special">delete all the data you had about him.</span></p>}

                {/* <p className="badge-text">Congratulations! The Subject Access Request has been successfully completed.</p>
                 <p className="badge-text">The subject has also allowed you to
                   <span className="badge-text__special">keep all data</span>
                    you had
                 </p>*/}
               </div>
               <div className="cards">
                 <CompleteStepCard icon="icon-v10-speed icon-speed" value={ daysToCompleteRequest } text="to complete request" />
                 <CompleteStepCard icon="icon-v10-paper" value={ deliveredDocuments } text="delivered documents" />
               </div>
             </div> :
             <div className="step-complete step-complete-end-request">
               <div className="badge-wrapper">
                 <span className="icon-v10-ended" />
                 <p className="badge-text">Subject Access Request has been finished.</p>
                 <p className="badge-text">No permission to request data.
                 </p>
               </div>
             </div>
           }
         </div>
       </div>

     </React.Fragment>
   )
 }
}
export default CompleteStep