import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import './EmailAcknowledgedWorkflow.scss'
import {Select, Steps, Button } from "antd";
const { Option } = Select;
const { Step } = Steps;
import {
    apiGetEmailTemplateTextWorkflowTicket, apiSendEmailWorkflowTicket, apiSendInternalEmailNotificationWorkflow,
    getTicketWorkflowAndHeaderDetails
} from "../../../../../api/SmartBox";
import {customConfirm} from "../../../../../utils/AssetRegister/CustomConfirm";
import CustomNotificationManager from "../../../../Common/CustomNotificationManager";
import moment from "moment";
export class EmailAcknowledgedWorkflow extends Component {

    state = {
        emailTemplateText : '',
        emailTemplateTitle : '',
        emailSendDate : 'Pending',
        emailLinkVisited : 'Pending',
        statusSent : 'wait',
        statusLinkVisited : 'wait',
        emailComponentSendEmailDates: [],
        emailComponentLinkVisitedDates: [],
        checkSendEmail: false,
        loadingButtonSend: false
    }
    generateClassSteps = (step, status, state) => {
        switch (step) {
            case 'status-link':
                if (state === 'finish') {
                    return 'status-link status-step-finish'
                }
                return 'status-link'

            case 'status-link-visited':
                if (state === 'finish') {
                    return 'status-link-visited status-step-finish'
                }
                return 'status-link-visited'

            default :
                return 'status'
        }

    }
    generateDate(date) {
        return moment(date).format('DD.MM.YYYY')
    }
    generateDescriptionSteps = (step, current) => {
        const { emailComponentSendEmailDates, emailComponentLinkVisitedDates } = this.state
        const { stepHeaderActive } = this.props
        switch (step) {
            case 'status-link':
                if (emailComponentSendEmailDates && emailComponentSendEmailDates.length > 0 && emailComponentSendEmailDates.length > stepHeaderActive?.emailComponentSendEmailDates?.length) {
                    return <React.Fragment>
                        {
                            emailComponentSendEmailDates.map((date, i) => <span className="status-finish-info"
                                                                                                 key={i}>{date}</span>)
                        }
                    </React.Fragment>
                } else if (stepHeaderActive && stepHeaderActive.emailComponentSendEmailDates && stepHeaderActive.emailComponentSendEmailDates.length > 0) {
                    return <React.Fragment>
                        {
                            stepHeaderActive.emailComponentSendEmailDates.map((date, i) => <span className="status-finish-info"
                                                                   key={i}>{date}</span>)
                        }
                    </React.Fragment>
                }
                return <span className="status-pending">{this.state.emailSendDate}</span>

            case 'status-link-visited':
                if (emailComponentLinkVisitedDates && emailComponentLinkVisitedDates.length > 0 && emailComponentLinkVisitedDates.length > stepHeaderActive?.emailComponentLinkVisitedDates?.length) {
                    return <React.Fragment>
                        {
                            emailComponentLinkVisitedDates.map((date, i) => <span className="status-finish-info"
                                                                                key={i}>{date}</span>)
                        }
                    </React.Fragment>
                } else if (stepHeaderActive && stepHeaderActive.emailComponentLinkVisitedDates && stepHeaderActive.emailComponentLinkVisitedDates.length > 0) {
                    return <React.Fragment>
                        {
                            stepHeaderActive.emailComponentLinkVisitedDates.map((date, i) => <span className="status-finish-info"
                                                                                                 key={i}>{date}</span>)
                        }
                    </React.Fragment>
                }
                return <span className="status-pending">{this.state.emailLinkVisited}</span>

            default :
                return 0
        }
    }
    customDot = (dot, { index, status }) => {
        switch (index) {
            case 0: {
                return <span className={ status === 'process' ? 'icon-v10-radio-button-tick' : status === 'finish' ? 'icon-v10-radio-button-tick' : 'icon-v10-radio-button-off' }></span>
            }
            case 1: {
                return <span className={ status === 'process' ? 'icon-v10-radio-button-tick' : status === 'finish' ? 'icon-v10-radio-button-tick' : 'icon-v10-radio-button-off' }></span>
            }
            default:
                return <span></span>
        }
    }
    componentDidMount() {
        return (
            this.callApiGetEmailTemplateTextWorkflowTicket()
        )
    }


    render() {
        const { stepTicketActive } = this.props
        const { checkSendEmail, loadingButtonSend } = this.state
        return (
            <div className="acknowledged-workflow-page">
                <div className="acknowledged-workflow-page__header">
                    <span className="name-acknowledged">{this.state.emailTemplateTitle}</span>
                </div>
                <div className="acknowledged-workflow-page__content">
                    <div className="email-acknowledged-info-steps">
                        <Steps progressDot={ this.customDot }>
                            <Step title="Sent" description={ this.generateDescriptionSteps('status-link', 0) }
                                  className={ this.generateClassSteps('status-link', 0, this.state.statusSent) } status={ this.state.statusSent }/>
                            {
                                stepTicketActive && stepTicketActive.stepComponentName === 'Web Form Email Template' &&
                                <Step title="Link visited" description={ this.generateDescriptionSteps('status-link-visited', 1) }
                                      className={ this.generateClassSteps('status-link-visited', 1, this.state.statusLinkVisited) } status={ this.state.statusLinkVisited }/>
                            }
                            {/*<Step title="Link visited" description={ this.generateDescriptionSteps('status-link-visited', 1) }*/}
                            {/*      className={ this.generateClassSteps('status-link-visited', 1, this.state.statusLinkVisited) } status={ this.state.statusLinkVisited }/>*/}
                        </Steps>
                    </div>
                    <div className="content-email-acknowledged">
                            <span className="email-acknowledged"> <div dangerouslySetInnerHTML={
                                { __html: this.state.emailTemplateText ? this.state.emailTemplateText.replaceAll('#{', '${') : this.state.emailTemplateText}
                            }></div> </span>
                    </div>
                    <div className="content-send-email-acknowledged">
                       <Button disabled={checkSendEmail === true || (this.state.emailTemplateText !== null && this.state.emailTemplateText.length > 0 ? false : true)} className="button-send-email-acknowledged"
                               loading={loadingButtonSend}
                               onClick={this.callSendEmailWorkflowTicketAPI} >Send</Button>
                    </div>
                </div>
            </div>

        )
    }
    callApiGetEmailTemplateTextWorkflowTicket = () => {
        const {stepHeaderActive, selectHeaderTickets} = this.props
        if (stepHeaderActive && (stepHeaderActive.stepComponentName === 'Email' || stepHeaderActive.stepComponentName === 'Web Form Email Template')) {

            apiGetEmailTemplateTextWorkflowTicket(Number(stepHeaderActive.stepComponentWorkflowId)).then(response => {
                    if (response.data.title && response.data.title.toLowerCase() === 'success') {
                        CustomNotificationManager.success(response.data.message, 'Success')
                    } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
                        CustomNotificationManager.info(response.data.message, 'Info')
                    }else{
                        this.setState({
                            emailTemplateText : response.data.content,
                            emailTemplateTitle : response.data.templateName
                        })
                    }

            })
            if (stepHeaderActive && stepHeaderActive.emailComponentSendEmailDates && stepHeaderActive.emailComponentSendEmailDates.length > 0) {
                this.setState({
                    statusSent: 'finish'
                })
            }
            if (stepHeaderActive && stepHeaderActive.emailComponentLinkVisitedDates && stepHeaderActive.emailComponentLinkVisitedDates.length > 0) {
                this.setState({
                    statusLinkVisited: 'finish'
                })
            }
        }
    }

    callSendEmailWorkflowTicketAPI = () => {
        const {stepHeaderActive, selectHeaderTickets} = this.props
        if (stepHeaderActive && (stepHeaderActive.stepComponentName === 'Email' || stepHeaderActive.stepComponentName === 'Web Form Email Template')) {
            const SendEmailWorkflowTicketDTO = {
                ticketId: selectHeaderTickets.ticketId,
                workflowComponentId: stepHeaderActive.stepComponentWorkflowId,
            }
            this.setState({
                checkSendEmail: true,
                loadingButtonSend: true,
            }, () => {
                apiSendEmailWorkflowTicket(SendEmailWorkflowTicketDTO).then(response => {
                    if (response.data.title && response.data.title.toLowerCase() === 'success') {
                        CustomNotificationManager.success(response.data.message, 'Success')
                        let emailComponentSendEmailDates = []
                        let emailComponentLinkVisitedDates = []
                        this.props.onGetTicketWorkflowAndHeaderDetails(selectHeaderTickets)
                        getTicketWorkflowAndHeaderDetails(selectHeaderTickets.ticketId).then(response => {
                            response.data.ticketWorkflowStepDTOS && response.data.ticketWorkflowStepDTOS.length > 0 && response.data.ticketWorkflowStepDTOS.forEach((elem) => {
                                if (elem.stepComponentName === 'Email' || elem.stepComponentName === 'Web Form Email Template') {
                                    emailComponentSendEmailDates = elem.emailComponentSendEmailDates
                                    emailComponentLinkVisitedDates = elem.emailComponentLinkVisitedDates
                                    this.setState({
                                        emailComponentSendEmailDates: elem.emailComponentSendEmailDates,
                                        emailComponentLinkVisitedDates: elem.emailComponentLinkVisitedDates,
                                    })
                                }
                            })
                        })
                    } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
                        CustomNotificationManager.info(response.data.message, 'Info')
                    }else{
                        getTicketWorkflowAndHeaderDetails(selectHeaderTickets.ticketId).then(response => {
                        })

                        //test
                        this.setState({
                            emailSendDate : "today",
                            statusSent : 'finish',
                            statusLinkVisited : 'finish'
                        })
                    }

                   this.setState({
                       checkSendEmail: false,
                       loadingButtonSend: false,
                   })
                })
            })
        }
    }

}





EmailAcknowledgedWorkflow.propTypes = {
    folder: PropTypes.object,
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id
})
export default compose(
    connect(mapStateToProps),
)(EmailAcknowledgedWorkflow)