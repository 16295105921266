import React from 'react'
// import Button from '../../../Common/Button'
import {Select, Tooltip} from 'antd'
import AnnotationsTable from './AnnotationsTable'
import { getAnnotationsByClientId, uploadCsvOfAnnotations, uploadCsvOfAnnotationsForGodAdmin } from '../../../../api/Sar'
import { connect } from 'react-redux'
import { CSVLink } from 'react-csv'
import CSVReader from 'react-csv-reader'
import CustomNotificationManager from "../../../Common/CustomNotificationManager";

const Option = Select.Option

class AnnotationManagement extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      filter: {
        exemptionCode: '',
        annotationCategoryEnum: null
      },
      classNameFind: 'page-filter__header--filter-container__filter--search-inactive',
      classNameFindFocus: 'page-filter__header--filter-container__filter--search-active',
      annotations: [],
      annotationsCopy: [],
      count: null,
      hoverAddAnnotation: false,
      hoverUpload: false,
      hoverUpload1: false,
      statusAddAnnotation: false,
      countAddLength: 0,
      statusPendingSaveAnnotation: false,
    }
  }

  componentDidMount() {
    getAnnotationsByClientId(this.props.clientId).then(({ data }) => {
      this.setState({
        annotations: data,
        annotationsCopy: data,
        count: data.length
      })
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  updateFilters(e, type) {

    switch (type) {
      case 'exemptionCode':
        this.setState({ filter: { ...this.state.filter, exemptionCode: e.target.value } })
        break
      case 'annotationCategoryEnum':
        this.setState({ filter: { ...this.state.filter, annotationCategoryEnum: e } })
        break
      default :
        return
    }
    this.setState({
      annotations: this.state.annotationsCopy
    })
  }

  addAnnotation = () => {
    const { count, annotations } = this.state
    let { countAddLength } = this.state
    let newKey = 0
    // let sortAnnotations = []
    // sortAnnotations = annotations.sort((userA, userB) => userA.key - userB.key)
    // for (let i = 0; i < annotations.length; i++) {
    //      if (annotations[i].key > annotations[annotations.length - 1].key) {
    //        countAddLength = annotations[i].key + 1
    //      } else {
    //        countAddLength = annotations[annotations.length - 1].key + 1
    //      }
    // }
    this.setStatusPendingSaveAnnotation(false)
    countAddLength = annotations.length + 1
    const newData = {
      annotationCategoryEnum: '',
      clientId: this.props.clientId,
      countryLawEnum: null,
      defaultAnnotation: false,
      details: '',
      exemptionCode: '',
      key: countAddLength,
      action: 'Add'
    }

    annotations.unshift(newData)
    this.setState({
      annotations: annotations,
      count: count + 1,
      //countAddLength: count + 1,
      statusAddAnnotation: true
    }, () => this.state.annotations,
        this.setState({
          countAddLength: count + 1
        }))
  }

  setStatusPendingSaveAnnotation = (status) => {
    this.setState({
      statusPendingSaveAnnotation : status,
    })
  }

  onDeleteAnnotationAction = (list) => {
    this.setState({
      annotations: list
    })
  }
  getStatusAddAnnotation = (status) => {
    this.setState({
      statusAddAnnotation: status
    })
  }
  onDropFile = (file) => {
    const nuploadedUsersList =[]
    file.map((row, key) => {
      if (key === 0) {
        return ''
      }
      const temp = {}
      temp.exemptionCode = row[0]
      temp.details = row[1]
      temp.annotationCategoryEnum = row[2]
      temp.countryLawEnum = row[3]
      temp.clientId = null
      nuploadedUsersList.push(temp)
      return true
    })

    if (this.props.user.user_metadata.roles.osprey === 'god_admin') {
      uploadCsvOfAnnotationsForGodAdmin(nuploadedUsersList).then((response) => {
        this.setState({
          annotations: response.data
        })
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    } else {
      uploadCsvOfAnnotations(this.props.clientId, nuploadedUsersList).then((response) => {
        this.setState({
          annotations: response.data
        })
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    }

    return true
  }

  updateParentAnnotations = (newData) => {
    this.setState({
      annotations: newData
    })
  }
  onHoverActionButtons = (e, status) => {
    switch (status) {
      case 'add':
        this.setState({
          hoverAddAnnotation: true
        })
        break
      case 'upload':
        this.setState({
          hoverUpload: true
        })
        break
      case 'upload1':
        this.setState({
          hoverUpload1: true
        })
        break
      default: break
    }

  }
  onLeaveActionsButtons = () => {
    this.setState({
      hoverAddAnnotation: false,
      hoverUpload: false,
      hoverUpload1: false,
    })
  }

  render() {
    const { exemptionCode, annotationCategoryEnum } = this.state.filter
    const { hoverAddAnnotation, hoverUpload, hoverUpload1, statusAddAnnotation, statusPendingSaveAnnotation } = this.state
    let { annotations } = this.state
    if (exemptionCode && annotations && annotations.length > 0) {
      annotations = annotations.filter((annotation) => annotation.exemptionCode && annotation.exemptionCode.toLowerCase().includes(exemptionCode))
    }

    if (annotationCategoryEnum && annotations && annotations.length > 0) {
      annotations = annotations.filter((annotation) => annotation.annotationCategoryEnum && annotation.annotationCategoryEnum.includes(annotationCategoryEnum))
    }
    const headersListUsers = [
      { label: 'Exemption Code', key: 'exemptionCode' },
      { label: 'Details', key: 'details' },
      { label: 'Annotation Category', key: 'annotationCategoryEnum' },
      { label: 'Country Law', key: 'countryLawEnum' },
    ]
    const annotationList = [
      {
        exemptionCode: '',
        details: '',
        annotationCategoryEnum: '',
        countryLawEnum: ''
      }
    ]
    return (
      <div className="page-container page-container-annotation">
        <header className="annotation-header">
          <h1 className="pageTitle-title page-title-update">Annotations</h1>
          {/*<h3>Manage your annotations here</h3>*/}
          <Tooltip overlayClassName="help-message-locations" title={<div className="content-help-message-locations">
            <span className="text-information">Create and manage annotations.</span>
            <span className="more-info-link" onClick={ () => window.open('https://support.smartbox.ai/support/solutions/articles/47001202652-annotations', '_blank') }>More info.</span>
          </div>}>
            <span className="icon-v14-question"></span>
          </Tooltip>
        </header>
        <div className="page-filter">
          <div className="page-filter__header">
            <div className="page-filter__header--filter-container">

              {/*<div className="page-filter__header--filter-container__filter">*/}
              {/*  <label>Find</label>*/}
              {/*  <input className={exemptionCode ? (classNameFindFocus) : (classNameFind)}*/}
              {/*    onChange={(e) => this.updateFilters(e, 'exemptionCode')}*/}
              {/*    onBlur={(e) => this.updateFilters(e, 'exemptionCode')}/>*/}
              {/*</div>*/}
              <div className="page-filter__header--filter-container__select">
                {/*<label>Time</label>*/}
                <Select id="questionnaireFilter__teams"
                  onChange={(e) => this.updateFilters(e, 'annotationCategoryEnum')}
                        dropdownClassName="select-team-annotation"
                  value={annotationCategoryEnum}>
                  <Option value={null}>View All</Option>
                  <Option value="NO_CATEGORY">NO CATEGORY</Option>
                  <Option value="CATEGORY_1">CATEGORY I</Option>
                </Select>
              </div>

            </div>
            <div className="page-filter__header--button-container">
              <CSVReader
                  //cssClass={ hoverUpload1 ? "icon-v21-download-active-1" : 'icon-v21-download-1' }
                  cssInputClass ="upload_csv_btn"
                  onFileLoaded={this.onDropFile}
                  inputId="file_csv_upload"
                  label={<span className={ hoverUpload1 ? "icon-v21-download-active-1" : 'icon-v21-download-1' }
                               onMouseEnter={(e) => this.onHoverActionButtons(e, 'upload1')}
                               onMouseLeave={(e) => this.onLeaveActionsButtons(e)}></span>}
              >
              </CSVReader>
              <CSVLink data={annotationList} headers={headersListUsers} className="download_csv_link">
                <span className={ hoverUpload ? 'icon-v21-upload-active-1' : "icon-v21-upload-1" }
                        onMouseEnter={(e) => this.onHoverActionButtons(e, 'upload')}
                        onMouseLeave={(e) => this.onLeaveActionsButtons(e)}></span>
              </CSVLink>
              <span className={ hoverAddAnnotation ? "icon-v21-add-active" : "icon-v21-add" }theme="newBusiness" onClick={this.addAnnotation}
              onMouseEnter={(e) => this.onHoverActionButtons(e, 'add')}
              onMouseLeave={(e) => this.onLeaveActionsButtons(e)}></span>
            </div>
          </div>
        </div>
        <div className="page-content">
          <AnnotationsTable annotations={annotations}
                            updateParentAnnotations={ this.updateParentAnnotations }
                            statusAddAnnotation={ statusAddAnnotation }
                            getStatusAddAnnotation={this.getStatusAddAnnotation}
                            onDeleteAnnotationAction={this.onDeleteAnnotationAction}
                            setStatusPendingSaveAnnotation={this.setStatusPendingSaveAnnotation}
                            statusPendingSaveAnnotation={statusPendingSaveAnnotation} />
          {/*<div className="upload-container">*/}
          {/*  <CSVLink data={annotationList} headers={headersListUsers} className="download_csv_link">*/}
          {/*    <button className="button_big_green--custom">Download csv template</button>*/}
          {/*  </CSVLink>*/}
          {/*  <CSVReader*/}
          {/*    cssClass="upload_csv_btn_container mt-1"*/}
          {/*    cssInputClass ="upload_csv_btn"*/}
          {/*    onFileLoaded={this.onDropFile}*/}
          {/*    inputId="file_csv_upload"*/}
          {/*    label="Upload new annotations CSV"*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id,
  user: state.userReducer.user
})

export default connect(mapStateToProps, {})(AnnotationManagement)