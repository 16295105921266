import React, { Component } from "react";
import "./SmartBoxShell.scss";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";
import { Navigate } from "react-router-dom";

export class SmartBoxShell extends Component {
  constructor() {
    super();
    this.state = {
      isFetching: true,
    };
  }

  render() {
    return (
      <>
      
      {!this.props.isLoading &&
        this.props.isLogged &&
        <Outlet />
      }
      {!this.props.isLogged && <Navigate to="/osp/login" replace />}
      </>
    );
  }
}


const stateMap = (state) => ({
  isLoading: state.shellReducer.shell.loading,
  isLogged: state.userReducer.isLogged
});

export default connect(stateMap)(SmartBoxShell);
