import React from 'react'
import PropTypes from 'prop-types'

import TimeSeries from '../../../Investigate/Visual/TimeSeries'

import Loading from '../../../Common/Loading'

class DataLineWindows extends React.Component {
  constructor() {
    super()

    this.state = { 

    }
  }

  doNothing = () => {

  }

  process = (windows, field) => {  

    return windows.window.map((window, index) => {
      return {
        x0: index,
        x: index+1,
        y: window[field] ? window[field] : 0
      }
    })
    
  }

  render = () => {
    const { windows, field } = this.props
    
    const data = this.process(windows, field)

    return (
      <div>
        { data 
          ? <TimeSeries 
            data={ data }
            onDragEnd={ this.doNothing.bind(this) } 
            width={ 250 }
            height={ 100 }/>
          : <Loading/>
        }
      </div>
    )
  }
}
  
DataLineWindows.propTypes = {
  windows: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired
}

export default DataLineWindows