/* eslint-disable */

const checkChangesOnQuestionnaire = (data, originalData) => {
  const fields = ['title', 'description', 'industry', 'type']
  let changed = false

  fields.forEach((field) => {
    if (data[field] != originalData[field]) {
      changed = true
    }
  })
  return changed
}

const checkChangesOnQuestions = (data, originalData) => {
  const fields = ['title', 'alias', 'explanation', 'description', 'riskIndicators', 'type', 'weight']
  let changed = false

  // Check if was added new questions
  if (data.questions.length !== originalData.questions.length) {
    return true
  }

  // Check if there is replaced question to another new one
  if (data.questions.filter((question) => question.id !== undefined).length !== originalData.questions.length) {
    return true
  }

  fields.forEach((field) => {
    data.questions.forEach((question, index) => {
      const originalQuestion = originalData.questions.find((originalQuestion) => originalQuestion.id === question.id)
      if (field === 'riskIndicators') {
        if (question[field].length != originalQuestion[field].length) {
          changed = true
        }
      } else if (question[field] != originalQuestion[field]) {
        changed = true
      }
    })
  })
  return changed
}

const checkChangesOnOptions = (data, originalData) => {
  const fields = ['title', 'score', 'displaySection', 'message', 'jumpsToEnd', 'jumpsToQuestion']
  const originalOptions = [].concat(...originalData.questions.map((question) => question.options))
  const currentOptions = [].concat(...data.questions.map((question) => question.options))
  let changed = false

  if (originalOptions.length !== currentOptions.filter((option) => option.id !== undefined).length) {
    return true
  }

  fields.forEach((field) => {
    currentOptions.forEach((option, index) => {
      const originalOption = originalOptions.find((originalOption) => originalOption.id === option.id)

      if (!originalOption || option[field] != originalOption[field]) {
        changed = true
      }
    })
  })

  return changed
}

export const changesChecker = (questionnaire, originalQuestionnaire) => {
  const questionnaireChanges = checkChangesOnQuestionnaire(questionnaire, originalQuestionnaire)
  const questionsChanges = checkChangesOnQuestions(questionnaire, originalQuestionnaire)
  const optionsChanges = checkChangesOnOptions(questionnaire, originalQuestionnaire)

  return questionnaireChanges || questionsChanges || optionsChanges
}