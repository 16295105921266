import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import './UnLocked.scss'
import withRouter from '../../../../private-routers/withRouter'

let UnLocked = ({ reoccurringMessage, process, type }) => (
  <div className="dpiaDashboard__unLockedProcess-info">
    <p>{ process.description || 'No Description available' }</p>
    <div>
      {
        reoccurringMessage &&
        <span className="dpiaDashboard__unLockedProcess-recurring">
          { reoccurringMessage }
        </span>
      }
      {
        nextDpiaOrCreatedMessage(process, type)
      }
      <span
        data-tip="Edit Business Process"
        className="iconEdit"
        onClick={ () => this.props.navigate(`/dpia/edit/${ process.id }`) } />
    </div>
  </div>
)

const nextDpiaOrCreatedMessage = (process, type) => {
  if (!process.toBeReoccurringGeneratedNextDPIADate) {
    return <span>{type} created: <strong>{ moment(process.creationDate).format('MMMM Do, YYYY') }</strong></span>
  }
  return <span>Next DPIA: <strong>{ moment(process.toBeReoccurringGeneratedNextDPIADate).format('MMMM Do, YYYY') }</strong></span>
}
UnLocked = withRouter(UnLocked)
UnLocked.propTypes = {
  reoccurringMessage: PropTypes.string,
  process: PropTypes.object.isRequired
}

export default UnLocked