import React from 'react'
import PropTypes from 'prop-types'

import WithDataModels from '../HOC/WithDataModels'

class ObjectTypeList extends React.Component {
  render = () => {
    return (
      <select
        name={ `objectype-kind-${ this.props.index }` }
        value={ this.props.value }
        onChange={ this.props.onChange }>
        <option value="">Choose...</option>
        { this.props.dataModels
          .filter((facet) => facet.group === this.props.filterByGroup)
          .map((facet, index) => (
            <option key={ index } value={ facet.id }>{ facet.name }</option>
          )) }
      </select>
    )
  }
}

ObjectTypeList.propTypes = {
  dataModels: PropTypes.array.isRequired,
  filterByGroup: PropTypes.string,
  index: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.string
}

ObjectTypeList.defaultProps = {
  filterByGroup: 'Basic',
  index: 0,
  value: ''
}

export default WithDataModels(ObjectTypeList)