import React from 'react'
import {
  postCheckId,
  getPreviewFileCheckThumbnailId,
  apiGetAnswerCheckId,
  getPreviewDocByThumbnailId,
  apiSarCheckIdSendEmail,
  apiDeleteProofId, apiGetEndRequestCheckId
} from '../../../../api/Sar'
import {Radio, Upload, Modal, Spin, Checkbox, Switch, Steps, Input, Tooltip, Button} from 'antd'
import CustomNotificationManager from '../../../../components/Common/CustomNotificationManager'
import PDFViewer from '../../../../utils/PDFViewer'
import PDFJSBackend from '../../../../utils/backends/PDFJs'
import { generateBlobFromBase64 } from '../../../../utils/GenerateBlob'
import Loading from '../../../Common/Loading'
import moment from 'moment'
import imgFolderSmallParent from '../../../../assets/smart-folder-medium-parent.svg'
import { customConfirm } from '../../../../utils/AssetRegister/CustomConfirm'
import {connect} from "react-redux";
//import {removeAllIntervalsFromApplication} from "../../../../actions/applicationsActions";
import {
  setSmartBoxNotSarTracker
} from "../../../../actions/smartBoxActions";
//import {Header} from "../../../Common/Header";
const { TextArea } = Input
//const RadioGroup = Radio.Group
const { Step } = Steps
class CheckIdStep extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      data: {
        sarId: '',
        knownToBusiness: false,
        sendIdRequest: false,
        documents: null,
        type: '',
        notes: '',
        sendEmailNotification: true,
      },
      fileList: [],
      previewImage: '',
      previewVisible: false,
      sarId: '',
      resend: '',
      message: '',
      loading: false,
      loadingReject: false,
      loadingCheckId: false,
      validationType: 'please select type',
      hiddenType: 'hidden',
      validationKnownBusiness: 'please select known to business',
      hiddenKnownBusiness: 'hidden',
      validationSendId: 'please select send id',
      hiddenSendId: 'hidden',
      valueRadio: 1,
      visibleContent: false,
      valueSelectedBox: '',
      checkOnFocusNotes: '',
      checkSendEmailUpload: false,
      valueTypeOptionSelected: '',
      comment: '',
      visibleImageDeleted: false,
      onEnterFile: {},
      loadingConfirmIdCheck: false,
      fileListNew: []
    }

    this.checkId = this.checkId.bind(this)
    this.sendEmail = this.sendEmail.bind(this)
    this.windowDocument = null
  }

  componentDidMount() {
       if (!this.props.checkidStep.completed && this.props.checkidStep.sendIdRequest && this.props.sar.checkIdLinkVisited && this.props.sar.checkIdLinkVisited.length > 0) {
        let idVerificationVisited = []
      const notIdVisited = [{
        nameActionEvent: `ID verification link visited`,
        descriptionActionEvent: `${ this.props.sar.forename }`,
        timesEvent: moment(this.props.sar.checkIdLinkVisited[0]).format('DD/MM/YYYY HH:mm:ss'),
        status: 'update-sar'
      }]
        idVerificationVisited = notIdVisited.concat(this.props.smartBoxNotSarTracker)
        const uniqueVerificationVisited = idVerificationVisited && idVerificationVisited.length > 0 && idVerificationVisited.filter((a, i) => idVerificationVisited.findIndex((s) => a.nameActionEvent === s.nameActionEvent) === i)
      this.props.setSmartBoxNotSarTracker(uniqueVerificationVisited)
     }
    if (!this.props.checkidStep.completed && this.props.checkidStep.sendIdRequest && this.props.sar.checkIdSubmitted && this.props.sar.checkIdSubmitted.length > 0) {
      let idVerificationVisited = []
      const notIdVisited = [{
        nameActionEvent: `ID verification submitted`,
        descriptionActionEvent: `${ this.props.sar.forename }`,
        timesEvent: moment(this.props.sar.checkIdSubmitted[0]).format('DD/MM/YYYY HH:mm:ss'),
        status: 'update-sar'
      }]
      idVerificationVisited = notIdVisited.concat(this.props.smartBoxNotSarTracker)
      const uniqueVerificationVisited = idVerificationVisited && idVerificationVisited.length > 0 && idVerificationVisited.filter((a, i) => idVerificationVisited.findIndex((s) => a.nameActionEvent === s.nameActionEvent) === i)
      this.props.setSmartBoxNotSarTracker(uniqueVerificationVisited)
    }
    if (this.props.checkidStep && this.props.checkidStep.sendIdRequest) {
      this.fetchImages(this.props.sar.id)

      if (this.props.checkidStep.stepState !== 'CREATED') {
        const { checkidStep } = this.props
        this.setState({
          data: { ...this.state.data, knownToBusiness: checkidStep.knownToBusiness, sendIdRequest: checkidStep.sendIdRequest, type: checkidStep.type, notes: checkidStep.notes, sarId: this.props.sar.id },
          knownToBusinessPressed: false
        })
      }
    } else {
      return false
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props && this.props.checkidStep.stepState === 'UPLOAD') {
      const { checkidStep } = this.props
      this.setState({
        data: { ...this.state.data, knownToBusiness: checkidStep.knownToBusiness, sendIdRequest: checkidStep.sendIdRequest, type: checkidStep.type, notes: checkidStep.notes, sarId: this.props.sar.id },
        knownToBusinessPressed: false
      })
    }
  }

  onChangeNotification = () => {
    this.setState({
      data: { ...this.state.data, sendEmailNotification: !this.state.data.sendEmailNotification }
    })
  }

    onSubjectKnown = (e) => {
      if (e.target.value) {
        this.setState({ data: { ...this.state.data, knownToBusiness: e.target.value, notes: '', type: '', sarId: Number(this.props.sar.id) }, knownToBusinessPressed: true })
      } else {
        this.setState({ data: { ...this.state.data, knownToBusiness: e.target.value, notes: '', sendIdRequest: '', type: 'UNKNOWN', sarId: Number(this.props.sar.id) }, knownToBusinessPressed: true })
      }
    }

    onSubjectKnownSwitcher(knownToBusiness) {
      if (knownToBusiness) {
        this.setState({ data: { ...this.state.data, knownToBusiness: knownToBusiness, notes: '', sendIdRequest: this.state.data.sendIdRequest, type: '', sarId: Number(this.props.sar.id) }, knownToBusinessPressed: true })
      } else {
        this.setState({ data: { ...this.state.data, knownToBusiness: knownToBusiness, notes: '', sendIdRequest: false, type: 'UNKNOWN', sarId: Number(this.props.sar.id) }, knownToBusinessPressed: true })
      }
    }

    onSendIdRequest = (e) => {
      this.setState({ data: { ...this.state.data, sendIdRequest: e.target.value } })
    }

    selectTypeOfMember = (e) => {
      this.setState({ data: { ...this.state.data, type: e.target.value } })
    }
  onSendIdRequestSwitch = (sendIdRequest) => {
    this.setState({ data: { ...this.state.data, sendIdRequest: sendIdRequest } })
  }

  onReject = () => {
    this.setState({
      loadingReject: true
    })
    const data = {
      sarId: this.props.sar.id,
      message: this.state.message,
      thumbnailsIds: this.state.fileList.map((file) => file.id),
      subjectType: this.state.data.type,
      comment: this.state.comment
    }

    apiGetAnswerCheckId(data)
      .then((response) => {
        this.props.refreshData(response.data)
        this.setState({
          loadingConfirmIdCheck: false
        })
        this.setState({
          data: response.data,
          loadingReject: false,
          fileList: []
        })
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
    })
  }

  handleChangeInput(event, field) {
    if (field === 'field_input') {
      this.setState({ message: event.target.value })
    }
  }
  sendEmail() {
    this.setState({
      hiddenType: '',
      hiddenKnownBusiness: ''
    })
    if (this.state.data.type) {
      apiSarCheckIdSendEmail(this.state.data)
        .then((response) => {
          this.props.refreshData(response.data)
          if (response.status === 200) {
            this.setState({
              checkSendEmailUpload: true
            })
            const notAcknowledge = {
              nameActionEvent: `Update to ${ this.props.checkidStep.id } in SAR ${ this.props.sar.id }`,
              descriptionActionEvent: 'Submitted ID verification',
              timesEvent: moment(this.props.acknowledgeStep.endDate).format('DD/MM/YYYY HH:mm:ss'),
              status: 'update-sar'
            }
            const acknowledgeNotification = []
            acknowledgeNotification.push(notAcknowledge)
            //this.props.setSmartBoxNotSarTracker(acknowledgeNotification)
          }
          response.data.sarSteps.forEach((data) => {
            this.props.refreshData(response.data)
            if (data.status === 'CHECK_ID') {
              if (data.completed) {
                CustomNotificationManager.success('Email sent', 'Success')
              } else {
                CustomNotificationManager.success('Email sent', 'Success')
              }
            }
          })
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
      })
    }
  }

  checkId() {
    this.setState({
      hiddenType: '',
      hiddenKnownBusiness: '',
      hiddenSendId: ''
    })
    if (this.state.data.type && (this.state.data.sendIdRequest === true || this.state.data.sendIdRequest === false)) {
      this.setState({
        loadingCheckId: true
      }, () => {
        postCheckId(this.state.data)
            .then((response) => {
              this.props.refreshData(response.data)
              response.data.sarSteps.forEach((data) => {
                this.props.refreshData(response.data)
                this.setState({
                  loadingConfirmIdCheck: false
                })
                if (data.status === 'CHECK_ID') {
                  if (data.completed) {
                    this.setState({
                      loadingCheckId: false
                    })
                    CustomNotificationManager.success('Check ID step successful', 'Success')
                  } else {
                    CustomNotificationManager.error('Check ID step is not valid', 'Error')
                  }
                }
              })
            }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
        })
      })
    }
  }

  async fetchImages(id) {
    const response = await getPreviewFileCheckThumbnailId(id)
    const listThumbnail = []
    const listThumbnailNew = []
    response.data.forEach((thumb) => {
      if (thumb && thumb.proofIdentityDocumentsEntity) {
        thumb.thumbnail.doc = thumb.proofIdentityDocumentsEntity.doc
        thumb.thumbnail.fileType = thumb.proofIdentityDocumentsEntity.fileType
      }
      listThumbnail.push(thumb.thumbnail)
      listThumbnailNew.push(thumb)
    })
    this.setState({
      fileList: listThumbnail,
      fileListNew: listThumbnailNew
    })
  }

  handleCancel = () => this.setState({ previewVisible: false })

  handlePreview = (file) => {
    const { sar } = this.props
    if (sar) {
      this.setState({
        previewVisible: true,
        loading: true
      }, () => {
        getPreviewDocByThumbnailId(file.id).then((response) => {
          switch (response.data.fileType) {
            case 'image/png':
            case 'image/jpg':
            case 'image/jpeg':
              const imageUrl = `data:${ response.data.fileType };base64, ${ response.data.doc }`
              this.setState({
                renderingBlock: <img alt="example" style={{ width: '100%' }} src={imageUrl}/>,
                loading: false
              })
              break
            case 'application/pdf':
              const { doc } = response.data
              this.setState({
                renderingBlock: <PDFViewer backend={PDFJSBackend} src={ generateBlobFromBase64(doc) }/>,
                loading: false
              })

              break
            default: break
          }
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
        })
      })
    } else {
      return false
    }

  }

  generateDate(date) {
    return moment(date).format('DD/MM/YYYY')
  }

  handleChange(e, field) {

    if (field === 'textarea') {
      const notes = e.target.value
      this.setState({
        data: { ...this.state.data, notes: notes }
      })
    } else {
      this.setState({
        data: { ...this.state.data, [field]: e.target.value }
      })
    }
  }

  handlePreviewUpdate = (e, file) => {
    e.preventDefault()
    e.stopPropagation()
    const { sar } = this.props
    const { fileListNew } = this.state
    fileListNew && fileListNew.length > 0 && fileListNew.forEach((file2) => {
      if (file2.thumbnail.id === file.id) {
        file.newThumbnail = file2.expandedThumbnail
      }
    })
    if (sar) {
      this.setState({
        previewVisible: true,
        loading: true
      }, () => {
        //getPreviewDocByThumbnailId(file.id).then((response) => {
          switch (file.fileType) {
            case 'image/png':
            case 'image/jpg':
            case 'image/jpeg':
              //const imageUrl = `data:${ file.type };base64, ${ file.newThumbnail }`
              const imageUrl = `data:${ file.type };base64, ${ file.thumbnailBytes }`
              this.setState({
                renderingBlock: <img alt="example" style={{ width: '100%' }} src={imageUrl}/>,
                loading: false
              })
              break
            case 'application/pdf':
              if (file.doc) {
                this.windowDocument = generateBlobFromBase64(file.doc)
                this.setState({
                  renderingBlock: <PDFViewer backend={PDFJSBackend} src={ this.windowDocument } toolbar={ 0 }/>,
                  loading: false
                })
              } else if (file.newThumbnail) {
                const codeThumbnail = file.newThumbnail.substr(8, file.newThumbnail.length)
                const doc = file.newThumbnail
                this.windowDocument = generateBlobFromBase64(codeThumbnail)
                this.setState({
                  renderingBlock: <PDFViewer backend={PDFJSBackend} src={ this.windowDocument }/>,
                  loading: false
                })
              }

              break
            default: break
          }
        // }).catch((error) => {
        //   if (error.response.status === 504) {
        //     CustomNotificationManager.cleanBuffer('warning', '')
        //   }
        // })
      })
    } else {
      return false
    }

  }
  onClickVisibleContent = () => {
    this.setState({
      visibleContent: !this.state.visibleContent
    })
  }
  customDot = (dot, { index, status }) => {
    switch (index) {
      case 0: {
        return <span className={ status === 'process' ? 'icon-v10-radio-button-tick' : status === 'finish' ? 'icon-v10-radio-button-tick' : 'icon-v10-radio-button-off' }></span>
      }
      case 1: {
        return <span className={ status === 'process' ? 'icon-v10-radio-button-tick' : status === 'finish' ? 'icon-v10-radio-button-tick' : 'icon-v10-radio-button-off' }></span>
      }
      case 2: {
        return <span className={ status === 'process' ? 'icon-v10-radio-button-tick' : status === 'finish' ? 'icon-v10-radio-button-tick' : 'icon-v10-radio-button-off' }></span>
      }
      default:
        return <span></span>
    }
  }
  generateClassSteps = (step, status, state) => {
    switch (step) {
      case 'status-link':
        if (state === 'finish') {
          return 'status-link status-step-finish'
        } 
        return 'status-link'
        
      case 'status-link-visited':
        if (state === 'finish') {
          return 'status-link-visited status-step-finish'
        } 
        return 'status-link-visited'
        
      case 'status-submitting-data':
        if (state === 'finish') {
          return 'status-submitting-data status-step-finish'
        } 
        return 'status-submitting-data'
        
      default :
        return 'status'
    }

  }
  generateDescriptionSteps = (step, current) => {
    const { sar } = this.props
    const { checkIdLinkVisited, checkIdSubmitted, checkIdSent } = sar
    switch (step) {
      case 'status-link':
        if (checkIdSent && checkIdSent.length > 0) {
          return <React.Fragment>
            {
              checkIdSent.map((date, i) => <span className="status-finish-info"
                key={i}>{this.generateDate(date)}</span>)
            }
          </React.Fragment>
        }
        return <span className="status-pending">Pending</span>
        
      case 'status-link-visited':
        if (checkIdLinkVisited && checkIdLinkVisited.length > 0) {
          return <React.Fragment>
            {
              checkIdLinkVisited.map((date, i) => <span className="status-finish-info"
                key={i}>{this.generateDate(date)}</span>)
            }
          </React.Fragment>
        } 
        return <span className="status-pending">Pending</span>
        
      case 'status-submitting-data':
        if (checkIdSubmitted && checkIdSubmitted.length > 0) {
          return <React.Fragment>
            {
              checkIdSubmitted.map((date, i) => <span className="status-finish-info"
                key={i}>{this.generateDate(date)}</span>)
            }
          </React.Fragment>
        } 
        return <span className="status-pending">Pending</span>
        
      default :
        return 0
    }
  }
  autoFocusNotes(e, field) {
    this.setState({
      checkOnFocusNotes: field
    })
  }
  autoBlurNotes() {
    this.setState({
      checkOnFocusNotes: ''
    })
  }

  onClickNextStep = () => {
    const { current, sar, respondStep } = this.props
    this.props.nextStep(current + 1)
    if (!respondStep && sar && sar.lastStep === 'COMPLETE') {
      this.props.nextStep(current + 4)
    } else if (!respondStep) {
      this.props.nextStep(current)
    } else {
      this.props.nextStep(current + 1)
    }
  }
  onClickBackStep = () => {
    const { current, acknowledgeStep, sar } = this.props
    if (!acknowledgeStep && sar && sar.lastStep === 'COMPLETE') {
      this.props.beckStep(current - 2)
    } else {
      this.props.beckStep(current - 1)
    }
  }
  selectOptionsRequest = (e) => {
    this.setState({
      valueTypeOptionSelected: e.target.value
    })
  }
  onChangeTextComment = (e) => {
    this.setState({
      comment: e.target.value
    })
  }
  onConfirmOrRejectIdCheck = () => {
    const { valueTypeOptionSelected } = this.state
    if (valueTypeOptionSelected === 'Approve_ID_and_continue') {
      this.setState({
        loadingConfirmIdCheck: true
      }, () => {
        this.checkId()
      })
    } else if (valueTypeOptionSelected === 'Reject_ID_end_request') {
      this.setState({
        loadingConfirmIdCheck: true
      }, () => {
        this.onReject()
      })
    } else {
      this.setState({
        loadingConfirmIdCheck: true
      }, () => {
        this.checkId()
      })
    }
  }
  onRemoveFileUpload = (e) => {
    customConfirm({
      title: 'Do you want to delete file?',
      content: `${ e.name }`,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      mask: true,
      maskClosable: false,
      closeHaveLogic: false,
      className: 'asset-register-modal delete-file-check-id-modal',
      centered: true,
      cancelClassName: 'cancel-button',
      confirmClassName: 'confirm-button',
      confirmAction: () => {
        this.removeFileConfirm(e)
      }
    })
  }
  removeFileConfirm = (e) => {
    const id = e.id
    apiDeleteProofId(id).then((response) => {
      this.fetchImages(this.props.sar.id)
      this.setState({
        visibleImageDeleted: true
      })
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  onRemoveFileUploadUpdate = (e, file) => {
    const { checkidStep } = this.props
    if (!checkidStep.completed) {
      customConfirm({
        title: 'Do you want to delete file?',
        content: `${file.name}`,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        mask: true,
        maskClosable: false,
        closeHaveLogic: false,
        className: 'asset-register-modal delete-file-check-id-modal',
        centered: true,
        cancelClassName: 'cancel-button',
        confirmClassName: 'confirm-button',
        confirmAction: () => {
          this.removeFileConfirm(file)
        }
      })
    }
  }
  onMouseEnterPicture(file) {
    this.setState({
      onEnterFile: file
    })
  }
  onMouseLeavePicture() {
    this.setState({
      onEnterFile: {}
    })
  }
  onEndRequest = () => {
    this.setState({
      loadingReject: true
    })
    const data = {
      sarId: this.props.sar.id,
    }

    apiGetEndRequestCheckId(data)
        .then((response) => {
          this.props.refreshData(response.data)
          this.setState({
            data: response.data,
            loadingReject: false,
            fileList: []
          })
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
    })
  }
  render() {
    const { acknowledgeStep, checkidStep, sar, valueAttachBox, respondStep, acknowledgeUncompletedStep } = this.props
    let { knownToBusiness, sendIdRequest, type, notes } = this.state.data
    const { sendEmailNotification } = this.state.data
    const { fileList, renderingBlock, previewVisible, message, loading, validationKnownBusiness, hiddenKnownBusiness,
      loadingCheckId, knownToBusinessPressed, valueSelectedBox, checkOnFocusNotes, valueTypeOptionSelected, comment, onEnterFile, loadingConfirmIdCheck,
      fileListNew } = this.state
    const lastSarStepCompleted = acknowledgeStep ? acknowledgeStep.completed ? acknowledgeUncompletedStep : acknowledgeUncompletedStep.completed : false
    const checkIdState = checkidStep ? checkidStep.stepState : ''
    const { checkIdLinkVisited, checkIdSubmitted, checkIdSent } = sar
    //const isLinkFromEmail = checkIdEmailOpened || checkIdLinkVisited || checkIdSubmitted
    let statusSent
    let statusLinkVisited
    let statusSubmittingData
    if (checkIdSent && checkIdSent.length > 0) {
      statusSent = 'finish'
    } else {
      statusSent = 'wait'
    }
    if (checkIdLinkVisited && checkIdLinkVisited.length > 0) {
      statusLinkVisited = 'finish'
    } else {
      statusLinkVisited = 'wait'
    }
    if (checkIdSubmitted && checkIdSubmitted.length > 0) {
      statusSubmittingData = 'finish'
    } else {
      statusSubmittingData = 'wait'
    }

    // let emailStatus
    // if (checkIdEmailOpened && checkIdEmailOpened.length > 0) {
    //   emailStatus = 'OPENED'
    // } else {
    //   emailStatus = 'SENT'
    // }
    //
    // let emailLinkOpened
    // if (checkIdLinkVisited && checkIdLinkVisited.length > 0) {
    //   emailLinkOpened = 'VISITED'
    // } else {
    //   emailLinkOpened = 'PENDING'
    // }
    //
    // let linkSubmitted
    // if (checkIdSubmitted && checkIdSubmitted.length > 0) {
    //   linkSubmitted = 'SUBMITTED'
    // } else {
    //   linkSubmitted = 'PENDING'
    // }
    // let linkApproved
    // if (checkIdSubmitted && checkIdSubmitted.length > 0) {
    //   linkApproved = 'APPROVED'
    // } else {
    //   linkApproved = 'PENDING'
    // }
    if (checkidStep && checkidStep.completed) {
      switch (checkidStep.stepState) {
        case 'VALIDATED': {
          knownToBusiness = checkidStep.knownToBusiness
          sendIdRequest = checkidStep.sendIdRequest
          type = checkidStep.type
          notes = checkidStep.notes
          break
        }
        case 'CREATED' : {
          break
        }
        default : {
          knownToBusiness = checkidStep.knownToBusiness
          sendIdRequest = checkidStep.sendIdRequest
          type = checkidStep.type
          notes = checkidStep.notes
        }
      }
    }

    if (checkidStep) {
      switch (checkidStep.stepState) {
        case 'VALIDATED': {
          if (!knownToBusinessPressed) {
            knownToBusiness = checkidStep.knownToBusiness
            notes = checkidStep.notes
          }
          sendIdRequest = checkidStep.sendIdRequest
          break
        }
        case 'CREATED' : {
          break
        }
        default : {
          knownToBusiness = checkidStep.knownToBusiness
          sendIdRequest = checkidStep.sendIdRequest
          type = checkidStep.type
          notes = checkidStep.notes
        }
      }
    }
    return (
      <React.Fragment>
        <div className="tracker-header-steps">
          <div className="contain-name-back-step">
            <div className="back-step back-step-hover">
              <span className="icon-v10-back" onClick={ this.onClickBackStep }></span>
            </div>
            <div className="tracker-fullname-contain">
              <span className="tracker-surname">{`${ sar.forename } ${ sar.surname }`}</span>
            </div>
          </div>
          <div className="attach-box-contain">
            { (valueAttachBox || this.props.sar.boxName) ?
              <img alt={ valueSelectedBox } src={ imgFolderSmallParent }/>:
              <span className="icon-v10-smart-folder-medium-parent-2"></span>
            }
            <span className="attach-box disabled-attach-box">
              { valueAttachBox ? valueAttachBox : this.props.sar.boxName ? this.props.sar.boxName : 'Attach a box' }
            </span>
          </div>
          <div className="contain-save-last-step">
            <div className="save-close-contain">
              <span className="icon-v10-save"></span>
              <span className="save-and-close">
             Save & Close
              </span>
            </div>
            <div className={!respondStep && sar.lastStep !== 'COMPLETE' ? 'next-step next-step-disabled' : 'next-step next-step-hover'}>
              <span className="icon-v10-forth" onClick={this.onClickNextStep}></span>
            </div>
          </div>
        </div>
        {/*<div className="tracker-surname-contain">*/}
        {/*  <span className="tracker-surname">{`${ sar.forename } ${ sar.surname }`}</span>*/}
        {/*</div>*/}
        <div className="tracker-box tracker-box-check-id" style={{ marginTop: 0 }}>
          <div className="tracker-form">
            <header className="tracker-step-header">
              <div className="tracker-step-header__top">
                <h1 className="tracker-step-header__top__step">ID Check
                  <Tooltip overlayClassName="help-message-locations" title={<div className="content-help-message-locations">
                    <span className="text-information">Confirm the subject’s identity.</span>
                    <span className="more-info-link" onClick={ () => window.open('https://support.smartbox.ai/support/solutions/articles/47001174700', '_blank') }> More info. </span>
                  </div>}>
                    <span className="icon-v14-question"></span>
                  </Tooltip></h1>
              </div>
            </header>
            {/*<div className="tracker-acknowledge-info">*/}
            {/*  <div className="content-pending">*/}
            {/*    <span className={linkSubmitted === 'SUBMITTED' ? 'email-sent' : 'email-opened'}>{linkApproved}</span>*/}
            {/*  </div>*/}
            {/*  {isLinkFromEmail &&*/}
            {/*  <div className="content-email-status">*/}
            {/*    <span className="info-email-status">Email Status</span>*/}
            {/*    <span className="contain-email-status">*/}
            {/*      <span*/}
            {/*        className={emailStatus === 'OPENED' ? 'email-opened' : 'email-sent'}>{emailStatus}</span>*/}
            {/*      {*/}
            {/*        emailStatus !== 'OPENED' &&*/}
            {/*        <Icon type="check" className="icon-check"/>*/}
            {/*      }*/}
            {/*    </span>*/}
            {/*  </div>*/}
            {/*  }*/}
            {/*  <div className="content-link-status">*/}
            {/*    <span className="info-link-status">Form Link Status</span>*/}
            {/*    <span className="contain-email-status">*/}
            {/*      <span className={emailLinkOpened === 'VISITED' ? 'email-sent' : 'email-opened'}>{emailLinkOpened}</span>*/}
            {/*      { emailLinkOpened === 'VISITED' &&*/}
            {/*      <Icon type="check" className="icon-check-two"/>*/}
            {/*      }*/}
            {/*    </span>*/}
            {/*  </div>*/}
            {/*  <div className="content-data-status">*/}
            {/*    <span className="info-data-status">Form Data Status</span>*/}
            {/*    <span className="contain-data-status">*/}
            {/*      <span className={linkSubmitted === 'SUBMITTED' ? 'email-sent' : 'email-opened'}>{linkSubmitted}</span>*/}
            {/*      { linkSubmitted === 'SUBMITTED' &&*/}
            {/*      <Icon type="check" className="icon-check"/>*/}
            {/*      }*/}
            {/*    </span>*/}
            {/*  </div>*/}
            {/*  <div className="tracker-content-icons">*/}
            {/*    <span className="tracker-icons-visible-content" onClick={() => this.onClickVisibleContent()}>*/}
            {/*      <i className={ this.state.visibleContent ? 'fa fa-caret-down' : 'fa fa-caret-up' }></i>*/}
            {/*    </span>*/}
            {/*  </div>*/}
            {/*</div>*/}

            {/*{ this.state.visibleContent &&*/}
            {/*<div className="tracker-acknowledge-info-content">*/}
            {/*  <div className="display-email-status">*/}
            {/*    { checkIdEmailOpened &&*/}
            {/*    <React.Fragment>*/}
            {/*      {*/}
            {/*        checkIdEmailOpened.map((date, i) => <span*/}
            {/*          key={i}>{this.generateDate(date)}</span>)*/}
            {/*      }*/}
            {/*    </React.Fragment>*/}
            {/*    }*/}
            {/*  </div>*/}
            {/*  <div className="display-form-link-status">*/}
            {/*    {checkIdLinkVisited &&*/}
            {/*    <React.Fragment>*/}
            {/*      {*/}
            {/*        checkIdLinkVisited.map((date, i) => <span*/}
            {/*          key={i}>{this.generateDate(date)}</span>)*/}
            {/*      }*/}
            {/*    </React.Fragment>*/}
            {/*    }*/}
            {/*  </div>*/}
            {/*  <div className="display-form-data-status">*/}
            {/*    {*/}
            {/*      checkIdSubmitted.map((date, i) => <span*/}
            {/*        key={i}>{this.generateDate(date)}</span>)*/}
            {/*    }*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*}*/}
            <div className="tracker-email-info-steps">
              { lastSarStepCompleted &&
              <Steps progressDot={ this.customDot }>
                <Step status={ statusSent } title="Sent" description={this.generateDescriptionSteps('status-link', 0)} className={ this.generateClassSteps('status-link', 0, statusSent)}/>
                <Step status={ statusLinkVisited } title="Link visited" description={this.generateDescriptionSteps('status-link-visited', 1)} className={ this.generateClassSteps('status-link-visited', 1, statusLinkVisited)}/>
                <Step status={ statusSubmittingData } title="Submitting data" description={this.generateDescriptionSteps('status-submitting-data', 2)} className={ this.generateClassSteps('status-submitting-data', 2, statusSubmittingData)}/>
              </Steps>
              }
            </div>

            {
              <div className="tracker-step-content">
                { lastSarStepCompleted &&
                <div className="content-subject-know">
                  <div className="title-subject-know">
                    <span className="know-business">Is your subject known to your business?</span>
                  </div>
                  <div className="tracker-radio tracker-radio-subject">
                    {/*<RadioGroup onChange={this.onSubjectKnown}*/}
                    {/*  disabled={checkidStep && checkidStep.completed}*/}
                    {/*  defaultValue={knownToBusiness}>*/}
                    {/*  <span className={checkidStep && checkidStep.completed ? 'custom-radio__yes custom-radio-disabled__yes' : 'custom-radio__yes' }>*/}
                    {/*    <Radio value={true}>Yes</Radio>*/}
                    {/*  </span>*/}
                    {/*  <span className={checkidStep && checkidStep.completed ? 'custom-radio__no custom-radio-disabled__yes' : 'custom-radio__no' }>*/}
                    {/*    <Radio value={false}>No</Radio>*/}
                    {/*  </span>*/}
                    {/*</RadioGroup>*/}
                    <div className="teams switcher-option-check">
                      <Switch
                        checked={ knownToBusiness }
                        checkedChildren="YES"
                        unCheckedChildren="NO"
                        disabled={checkIdState === 'VALIDATED' || (checkidStep && checkidStep.completed)}
                        onChange={ () => this.onSubjectKnownSwitcher(!knownToBusiness) }
                      />
                    </div>
                    <div className="validation-check-id-new">
                      <span className={hiddenKnownBusiness}>{ knownToBusiness === true || knownToBusiness === false ? '' : validationKnownBusiness }</span>
                    </div>
                  </div>
                </div>
                }
                { checkidStep &&
                  <React.Fragment>
                    { knownToBusiness &&
                      <div className="tracker-content-radio">
                        <div className={ checkIdState === 'VALIDATED' || (checkidStep && checkidStep.completed) ? 'tracker-radio-disbled' : 'tracker-radio-check' }>
                          <Radio.Group disabled={ checkIdState === 'VALIDATED' || (checkidStep && checkidStep.completed) } onChange={this.selectTypeOfMember} value={type}>
                            <Radio className="radion-style" value="MEMBER_OF_STAFF">
                                      Member of staff
                            </Radio>
                            <Radio className="radion-style" value="EX_MEMBER_OF_STAFF">
                                      Ex member of staff
                            </Radio>
                            <Radio className="radion-style" value="SUPPLIER">
                                      Supplier
                            </Radio>
                            <Radio className="radion-style" value="OTHER">
                                      Other
                            </Radio>
                          </Radio.Group>
                        </div>
                      </div>
                    }

                    {(knownToBusiness === false || knownToBusiness === true) &&
                      <div className="tracker_formGroup">
                        <label>Notes</label>
                        <input id='textarea' maxLength="255" value={notes ? notes : ''} className="textArea_checkId"
                          onChange={(e) => this.handleChange(e, 'textarea')} rows="5"
                          onFocus={(e) => this.autoFocusNotes(e, 'textarea')} onBlur={(e) => this.autoBlurNotes(e)}/>
                        <div className={ checkOnFocusNotes === 'textarea' ? 'edit-input edit-input-focus' : 'edit-input' }>
                          <span className="icon-v10-edit-b"></span>
                        </div>
                      </div>
                    }
                  </React.Fragment>
                }
                { lastSarStepCompleted &&
                <React.Fragment>
                  <div className="tracker-switch new-tracker-switch">
                    {/*<div className="tracker-radio">*/}
                    {/*  <h3>Send ID request email</h3>*/}
                    {/*  <RadioGroup defaultValue={sendIdRequest} disabled={checkIdState === 'VALIDATED'}*/}
                    {/*    onChange={this.onSendIdRequest}>*/}
                    {/*    <span className={checkIdState === 'VALIDATED' ? 'custom-radio__yes custom-radio-disabled__yes' : 'custom-radio__yes' }>*/}
                    {/*      <Radio value={true}>Yes</Radio>*/}
                    {/*    </span>*/}
                    {/*    <span className={checkIdState === 'VALIDATED' ? 'custom-radio__no custom-radio-disabled__yes' : 'custom-radio__no' }>*/}
                    {/*      <Radio value={false}>No</Radio>*/}
                    {/*    </span>*/}
                    {/*  </RadioGroup>*/}
                    {/*</div>*/}
                    <div className="tracker-check-send-id">
                      <div className="content-message-send">
                        <span>Send ID request email</span>
                      </div>
                      <div className="teams switcher-option-check">
                        <Switch
                          checked={ sendIdRequest }
                          checkedChildren="YES"
                          unCheckedChildren="NO"
                          disabled={checkIdState === 'VALIDATED' || (checkidStep && checkidStep.completed)}
                          onChange={ () => this.onSendIdRequestSwitch(!sendIdRequest) }
                        />
                      </div>
                    </div>
                    {/*<div className="content-button">*/}
                    {/*  { sendIdRequest && !checkidStep.completed &&*/}
                    {/*  <button className={checkIdState === 'VALIDATED' ? 'button-blue-disabled' : 'button-blue-light'}*/}
                    {/*    onClick={this.sendEmail} disabled={checkIdState === 'VALIDATED' || checkIdState === 'REUPLOAD' || checkidStep.completed}>{checkIdState === 'UPLOAD' ? 'Resend ID request' : 'Send ID request'}*/}
                    {/*  </button>*/}
                    {/*  }*/}
                    {/*</div>*/}
                    {/*<div className="validation-check-id">*/}
                    {/*  <span className={hiddenSendId}>{ sendIdRequest === true || sendIdRequest === false ? '' : validationSendId }</span>*/}
                    {/*</div>*/}
                  </div>
                </React.Fragment>
                }
                { lastSarStepCompleted &&
                <div className="received-documents-content">

                  <div className="content-button content-button-request-id">
                    { sendIdRequest && checkidStep && !checkidStep.completed && fileList.length === 0 &&
                    <div>
                      <button className={ checkIdState === 'VALIDATED' ? 'button-blue-disabled' : 'button-blue-light' }
                        onClick={ this.sendEmail }
                        disabled={ checkIdState === 'VALIDATED' || checkIdState === 'REUPLOAD' || checkidStep.completed || (!this.state.data.type || this.state.data.type === 'UNKNOWN') }>{ checkIdState === 'UPLOAD' ? 'Resend ID request' : 'Request ID' }
                      </button>
                    </div>
                    }
                  </div>

                  { sendIdRequest && fileList && fileList.length > 0 ?
                    <div className="proof-id-documents">
                      { checkidStep && !checkidStep.completed && (checkIdState === 'UPLOAD' || checkIdState === 'VALIDATED' || checkIdState === 'REUPLOAD') && fileList && !fileList.length > 0 ?
                        (<div>Waiting for subject to upload ID.</div>) : (<div
                          className="received-proof-documents">{ fileList && fileList.length > 0 ? 'ID submissions' : '' }</div>)
                      }
                      { fileList && fileList.length > 0 && fileList.map((file) => {
                        return (
                               <div className="picture-check-id picture-check-id-update"
                                   onMouseEnter={() => this.onMouseEnterPicture(file)}
                                    onMouseLeave={() => this.onMouseLeavePicture() }
                               >
                                 { file.name === onEnterFile.name &&
                                   <span className={ !checkidStep.completed ? "icon-v12-delete" : 'icon-v12-delete disabled-delete' }
                                         onClick={(e) => this.onRemoveFileUploadUpdate(e, file)}></span>
                                 }
                                 <span onClick={(e) => this.handlePreviewUpdate(e, file)}>
                                  <Upload
                                      multiple={ true }
                                      listType="picture-card"
                                      showUploadList={ {
                                        showPreviewIcon: false,
                                        showRemoveIcon: false,
                                      } }
                                      fileList={ [file] }
                                  >
                              </Upload>
                                 </span>
                               </div>
                        )
                      })
                      }
                      <div className="picture-check-id">
                        {/*<Upload*/}
                        {/*  multiple={ true }*/}
                        {/*  listType="picture-card"*/}
                        {/*  showUploadList={ {*/}
                        {/*    showPreviewIcon: false,*/}
                        {/*  } }*/}
                        {/*  fileList={ fileList }*/}
                        {/*  //onPreview={ this.handlePreview }*/}
                        {/*  onRemove={ this.onRemoveFileUpload }*/}
                        {/*>*/}
                        {/*</Upload>*/}

                        <Modal visible={ previewVisible } footer={ null } onCancel={ this.handleCancel }
                          className="pdf-modal">
                          <div className="custom-modal-body">
                            { loading ? <Spin spinning={ loading }
                              indicator={ <div className="checkout-loading"><Loading/>
                              </div> }/> : renderingBlock }
                          </div>
                        </Modal>

                      </div>
                    </div> : sendIdRequest && checkIdState !== 'CREATED' && fileList.length === 0 ?
                      <div>
                        <span className="received-delete-proof">ID submissions</span>
                        <div className="documents-empty-deleted">
                          <span>Image</span>
                          <span>deleted</span>
                        </div>
                      </div>
                      :
                      <div></div>
                    // <div
                    //   className={ sendIdRequest ? 'proof-id-documents-empty' : 'proof-id-documents-empty opacity-documents' }>
                    //   <div className="received-proof-documents">
                    //     { checkidStep && !checkidStep.completed && (checkIdState === 'UPLOAD' || checkIdState === 'VALIDATED' || checkIdState === 'REUPLOAD') && fileList && !fileList.length > 0 ?
                    //       (<div>Waiting for subject to upload ID.</div>) : (<div
                    //         className="received-proof-documents">{ fileList && !fileList.length > 0 ? 'Received proof id documents' : '' }</div>)
                    //     }
                    //   </div>
                    //   <div className="documents-empty">
                    //
                    //   </div>
                    // </div>
                  }

                  {/*<div className="content-button">*/}
                  {/*  { sendIdRequest && checkidStep && !checkidStep.completed &&*/}
                  {/*    <div>*/}
                  {/*      <button className={ checkIdState === 'VALIDATED' ? 'button-blue-disabled' : 'button-blue-light' }*/}
                  {/*        onClick={ this.sendEmail }*/}
                  {/*        disabled={ checkIdState === 'VALIDATED' || checkIdState === 'REUPLOAD' || checkidStep.completed || (!this.state.data.type || this.state.data.type === 'UNKNOWN') }>{ checkIdState === 'UPLOAD' ? 'Resend ID request' : 'Send ID request' }*/}
                  {/*      </button>*/}
                  {/*    </div>*/}
                  {/*  }*/}
                  {/*  { !sendIdRequest && checkidStep && !checkidStep.completed &&*/}
                  {/*  <button className={ checkIdState === 'VALIDATED' ? 'button-blue-disabled' : 'button-blue-light' }*/}
                  {/*    disabled={ !sendIdRequest || (!this.state.data.type || this.state.data.type === 'UNKNOWN') }>Send ID request*/}
                  {/*  </button>*/}
                  {/*  }*/}
                  {/*</div>*/}

                </div>
                }

                { !checkidStep.completed && sendIdRequest && checkIdState !== 'CREATED' &&
                  <div className="content-radio-options-proofId">
                    <Radio.Group onChange={ this.selectOptionsRequest }>
                      <Radio className="radion-style" value="Approve_ID_and_continue">
                        Approve ID and continue
                      </Radio>
                      <Radio className="radion-style" value="Request_additional_proof">
                        Request additional proof
                      </Radio>
                      { valueTypeOptionSelected === 'Request_additional_proof' &&
                        <div className="content-request-additional-proof">
                          <TextArea type="textarea" onChange={ this.onChangeTextComment } className="input-comment" placeholder="Comment"/>
                          <button className='button-blue-light'
                            onClick={ this.sendEmail }
                          >Send
                          </button>
                        </div>
                      }
                      <Radio className="radion-style" value="Reject_ID_end_request">
                        Reject ID & end request
                      </Radio>
                      { valueTypeOptionSelected === 'Reject_ID_end_request' &&
                      <div className="content-request-additional-proof">
                        <TextArea onChange={ this.onChangeTextComment } type="textarea" className="input-comment" placeholder="Comment"/>
                      </div>
                      }
                    </Radio.Group>
                  </div>
                }

                {/*{ checkidStep && !checkidStep.completed && (checkIdState === 'UPLOAD' || checkIdState === 'VALIDATED' || checkIdState === 'REUPLOAD') && fileList && !fileList.length > 0 ?*/}
                {/*  (<div>Waiting for subject to upload ID.</div>) : (<div className="received-proof-documents">{fileList && fileList.length > 0 ? 'Received proof id documents' : ''}</div>)*/}
                {/*}*/}

                {/*{ sendIdRequest && fileList && fileList.length > 0 &&*/}
                <div className="tracker_formGroup form_check">
                  <div className="contain-picture-form">
                    {/*<div className="picture-check-id">*/}
                    {/*  <Upload*/}
                    {/*    multiple={true}*/}
                    {/*    listType="picture-card"*/}
                    {/*    showUploadList={{*/}
                    {/*      showPreviewIcon: true,*/}
                    {/*      showRemoveIcon: false*/}
                    {/*    }}*/}
                    {/*    fileList={fileList}*/}
                    {/*    onPreview={this.handlePreview}*/}
                    {/*  >*/}
                    {/*  </Upload>*/}

                    {/*  <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel} className="pdf-modal">*/}
                    {/*    <div className="custom-modal-body">*/}
                    {/*      {loading ? <Spin spinning={loading} indicator={<div className="checkout-loading"><Loading /></div>} /> : renderingBlock }*/}
                    {/*    </div>*/}
                    {/*  </Modal>*/}
                    {/*</div>*/}

                    {/*  {checkidStep && !checkidStep.completed && checkIdState === 'VALIDATED' &&*/}
                    {/*<div className="buttonReject">*/}
                    {/*  {*/}
                    {/*    loadingReject ? <Spin className="float-right" spinning={loadingReject}*/}
                    {/*      indicator={<div className="checkout-loading"><Loading/></div>}/> :*/}
                    {/*      <button className="button-blue-light float-right"*/}
                    {/*        onClick={this.onReject}>REJECT ID*/}
                    {/*      </button>*/}
                    {/*  }*/}
                    {/*</div>*/}
                    {/*  }*/}
                  </div>
                  {/*{ checkidStep && !checkidStep.completed && checkIdState === 'VALIDATED' &&*/}
                  <div>
                    { lastSarStepCompleted &&
                    <div
                      className={ checkidStep && !checkidStep.completed && checkIdState === 'VALIDATED' ? 'inputCheckId' : 'inputCheckId input-checked-id-disabled' }>
                      <label className="field_input" htmlFor="field_input">
                        {/*If the ID is not valid and you want to reject it, add your message here telling the subject what was wrong.*/}
                        Notes
                      </label>
                      {/*<input*/ }
                      {/*  id='textarea'*/ }
                      {/*  maxLength="255"*/ }
                      {/*  defaultValue={message}*/ }
                      {/*  onChange={(e) => this.handleChangeInput(e, 'field_input')}*/ }
                      {/*  className="text_area_resend"/>*/ }

                      <input id='message' maxLength="255" defaultValue={ message } className="textArea_checkId"
                        disabled={ (checkidStep && checkIdState !== 'VALIDATED') || checkidStep.completed }
                        onChange={ (e) => this.handleChangeInput(e, 'field_input') } rows="5"
                        onFocus={ (e) => this.autoFocusNotes(e, 'field_input') }
                        onBlur={ (e) => this.autoBlurNotes(e) }/>
                      <div
                        className={ checkOnFocusNotes === 'field_input' ? 'edit-input edit-input-focus' : 'edit-input' }>
                        <span className="icon-v10-edit-b"></span>
                      </div>
                    </div>
                    }
                  </div>
                  {/*}*/}
                </div>
                {/*}*/}

                <div className="tracker-content-links full_width new-tracker-content-links">
                  { lastSarStepCompleted && (checkidStep && !checkidStep.completed) &&
                    <div className="tracker-form-link">
                      <Checkbox disabled={(knownToBusiness === false || type === '') || (valueTypeOptionSelected === '' && sendIdRequest === true) || (valueTypeOptionSelected === 'Request_additional_proof') || (valueTypeOptionSelected === 'Reject_ID_end_request' && comment === '') ||
                      checkIdState === 'REUPLOAD' || (sendIdRequest === true && checkIdState === 'CREATED') || checkIdState === 'UPLOAD' } defaultChecked={ sendEmailNotification } className="send-form" onChange={this.onChangeNotification}>Send Email
                            Notification</Checkbox>
                    </div>
                  }
                </div>
                <div className="content-footer-check-id">
                  {/*{ ((checkidStep && !checkidStep.completed && checkIdState === 'VALIDATED') || checkSendEmailUpload === true || checkIdState === 'UPLOAD') &&*/}
                  {/*<div className="buttonReject">*/}
                  {/*  {*/}
                  {/*    loadingReject ? <Spin className="float-right" spinning={ loadingReject }*/}
                  {/*      indicator={ <div className="checkout-loading"><Loading/></div> }/> :*/}
                  {/*      <button className="button-blue-light float-right"*/}
                  {/*        onClick={ this.onReject }>REJECT ID & END REQUEST*/}
                  {/*      </button>*/}
                  {/*  }*/}
                  {/*</div>*/}
                  {/*}*/}
                  {lastSarStepCompleted && (checkidStep && !checkidStep.completed) &&
                      <React.Fragment>
                        { sendIdRequest === false && knownToBusiness === false ?
                            <div className="tracker-content-button tacker-button-confirm">
                              <button className="reject-check-id" onClick={ this.onEndRequest }>
                                END REQUEST
                              </button>
                            </div> :
                        <div className="tracker-content-button tacker-button-confirm">
                            {
                              loadingCheckId ? <Spin className="float-right" spinning={loadingCheckId}
                                                     indicator={<div className="checkout-loading"><Loading/></div>}/> :
                                  <Button
                                      loading={ loadingConfirmIdCheck }
                                      className={(valueTypeOptionSelected === '' && sendIdRequest === true) || (valueTypeOptionSelected === 'Request_additional_proof') || (valueTypeOptionSelected === 'Reject_ID_end_request' && comment === '') ||
                                      checkIdState === 'REUPLOAD' || (sendIdRequest === true && checkIdState === 'CREATED') || checkIdState === 'UPLOAD' || (!this.state.data.type || this.state.data.type === 'UNKNOWN') ? 'button-blue-disabled button-confirm-check-disabled float-right' : 'button-blue-light button-check-id float-right'}
                                      onClick={this.onConfirmOrRejectIdCheck}
                                      disabled={(valueTypeOptionSelected === '' && sendIdRequest === true) || (valueTypeOptionSelected === 'Request_additional_proof') || (valueTypeOptionSelected === 'Reject_ID_end_request' && comment === '') ||
                                      checkIdState === 'REUPLOAD' || (sendIdRequest === true && checkIdState === 'CREATED') || checkIdState === 'UPLOAD'}>
                                    CONFIRM ID CHECK
                                  </Button>
                            }
                          </div>
                        }
                      </React.Fragment>
                  }
                </div>
              </div>
            }
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  smartBoxNotSarTracker: state.smartBox.smartBoxNotSarTracker,
})
export default connect(mapStateToProps, {
  setSmartBoxNotSarTracker
})(CheckIdStep)