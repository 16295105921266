import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'

import ConnectorList from './Connectors/ConnectorList'
import WithConnectors from './HOC/WithConnectors'

import './Connectors.css'

class Connectors extends React.Component {
  state = {
    connectors: [{ name: 'File system' }]
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  render = () => {
    return (
      <div className="privacyConnectors__page">
        <div className="privacyConnectors__box">
          <header className="privacyConnectors__boxHeader">
            <h1>Select a connector</h1>
          </header>
          <ConnectorList connectors={ this.state.connectors } />
        </div>
      </div>
    )
  }
}


export default compose(
  WithConnectors,
)(Connectors)