import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { groupBy } from '../../../../utils'
import './Checkbox.css'
import Active from './Checkbox/Active'
import Completed from './Checkbox/Completed'

class Checkbox extends Component {
  render() {
    const { assessmentStatus, question, processStatus } = this.props
    const groups = groupBy(question.options, (option) => option.displaySection)
    
    if (processStatus === 'INACTIVE' || assessmentStatus === 'COMPLETED' || question.state === 'QUESTIONNAIRE_SKIPPED') return (
      <Completed groups={ groups } />
    )

    return (
      <Active
        groups={ groups }
        updateValueForCheckbox={ this.props.updateValueForCheckbox }
        updateValueForOther={ this.props.updateValueForOther }
      />
    )
  }
}

Checkbox.propTypes = {
  assessmentStatus: PropTypes.string.isRequired,
  question: PropTypes.object.isRequired,
  updateValueForCheckbox: PropTypes.func.isRequired,
  updateValueForOther: PropTypes.func.isRequired
}

export default Checkbox
