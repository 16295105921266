import React, { Component } from 'react'
import './AssetRegisterShell.scss'
import { connect } from 'react-redux'
import { Outlet } from 'react-router-dom'

export class AssetRegisterShell extends Component {
  constructor() {
    super()
  }

  render() {
    return (
      <div>
        {!this.props.isLoading &&
          <Outlet/>
        }
      </div>
    )
  }
}

const stateMap = (state) => ({
  isLoading: state.shellReducer.shell.loading
})

export default connect(stateMap)(AssetRegisterShell)
