export const groupTemplate = (templates) => {
  const templateVersions = {}
  templates.forEach((template) => { templateVersions[template.id] = template.versions })
  const PIATemplates = templates.filter((template) => (template.type === 'PIA')).sort((template) => (!template.isGlobal))
  const DPIAControllerTemplates = templates.filter((template) => (template.type === 'DATA_CONTROLLER_DPIA')).sort((template) => (!template.isGlobal))
  const DPIAProcessorTemplates = templates.filter((template) => (template.type === 'DATA_PROCESSOR_DPIA')).sort((template) => (!template.isGlobal))
  
  return { PIATemplates, DPIAControllerTemplates, DPIAProcessorTemplates, templateVersions }
}

export const findTemplateVersions = (templates, crtDPIATemplateId, piaTemplateId, selectedGroup) => {
  const { DPIAControllerTemplates, DPIAProcessorTemplates, PIATemplates, templateVersions } = templates
  const DPIATemplateSelect = DPIAControllerTemplates.find((template) => template.versions.find((version) => (version.id === crtDPIATemplateId)))
  const DPIAProcessorTemplateSelect = DPIAProcessorTemplates.find((template) => template.versions.find((version) => (version.id === crtDPIATemplateId)))
  const PIATemplateSelect = PIATemplates.find((template) => template.versions.find((version) => (version.id === piaTemplateId)))

  if (selectedGroup.value === '') {
    if (DPIATemplateSelect) {
      templates['DPIATemplateSelected'] = DPIATemplateSelect.id
      templates['DPIATemplateVersions'] = templateVersions[DPIATemplateSelect.id]
      templates['DPIATemplates'] = DPIAControllerTemplates
    }
  }

  if (selectedGroup.value === 'DATA_CONTROLLER_DPIA') {
    if (DPIATemplateSelect) {
      templates['DPIATemplateSelected'] = DPIATemplateSelect.id
      templates['DPIATemplateVersions'] = templateVersions[DPIATemplateSelect.id]
      templates['DPIATemplates'] = DPIAControllerTemplates
    }
  }

  if (selectedGroup.value === 'DATA_PROCESSOR_DPIA') {
    if (DPIAProcessorTemplateSelect) {
      templates['DPIATemplateSelected'] = DPIAProcessorTemplateSelect.id
      templates['DPIATemplateVersions'] = templateVersions[DPIAProcessorTemplateSelect.id]
      templates['DPIATemplates'] = DPIAProcessorTemplates
    }
  }

  if (PIATemplateSelect) {
    templates['PIATemplateSelected'] = PIATemplateSelect.id
    templates['PIATemplateVersions'] = templateVersions[PIATemplateSelect.id]
  }

  return templates
}

export const templateSelected = (templateId, field, templateVersions) => {
  let versionsFor = 'PIATemplateVersions'
  let versionField = 'piaTemplateId'
  let versions = templateVersions[templateId] || []

  if (field === 'DPIATemplateSelected') {
    versionsFor = 'DPIATemplateVersions'
    versionField = 'crtDPIATemplateId'
  }

  if (versions.length > 0) {
    versions = versions.sort((versionA, versionB) => (versionB.id - versionA.id))
  }

  const selectedVersion = versions.length > 0 ? versions[0].id : ''

  return {
    [field]: templateId,
    [versionsFor]: versions,
    [versionField]: selectedVersion
  }
}