import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Button from '../../Common/Button'
import './NeedPia.css'

class NeedPia extends React.Component {
  constructor() {
    super()

    this.state = {
      assignedToUserDisplay: ''
    }
  }

  repeatMessage(process) {
    if (!process.reoccurring) return 'DPIA will not repeat.'
    
    const period = process.reoccurringFrequency >= 1 ? 'every month' : `every ${ process.frequencyCount } months`

    return `DPIA will be sent out the 3rd day for ${ period }`
  }


  render() {
    const { process, displayName } = this.props;
    const newAssessment = process.assessments[0];

    return (
      <div className="piaAlertNeedPia__wrapper">
        <h1 className="piaAlertNeedPia__title">Based on your answers you <ins>do require a DPIA</ins> from this Business Process.</h1>
        <h2 className="piaAlertNeedPia__subTitle">
          Your Business Process
          &nbsp;
          <strong>{ process.title }</strong>
          &nbsp;
          DPIA is scheduled as follows:
        </h2>
        <div className="piaAlertNeedPia__details">
          <div className="piaAlertNeedPia__details-repeat">
            <h2>Repeat</h2>
            <span>{ this.repeatMessage(process) }</span>
          </div>
        
          <div className="piaAlertNeedPia__details-assigned">
            <h2>Assigned to</h2>
            <span>{displayName}</span>
          </div>
        </div>
  
        <div className="piaAlertNeedPia__buttons">
          <Link to="/dpia/dashboard">
            <Button theme="green">
              Back to Dashboard
            </Button>
          </Link>

          {
            newAssessment &&
            <Link to={`/dpia/assessment/${ newAssessment.id }`}>
              <Button>
                View the DPIA
              </Button>
            </Link>
          }
        </div>
      </div>
    )
  }
}

NeedPia.propTypes = {
  process: PropTypes.object.isRequired,
  user: PropTypes.object
}

export default NeedPia