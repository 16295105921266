export default () => {
  self.addEventListener('message', (e) => { // eslint-disable-line no-restricted-globals
    if (!e.data) return []
    const { fileNames, fileNamesWithErrors } = e.data
    let newFileNames = []
    if (fileNames && fileNames.length > 0) {
      newFileNames = fileNames.map((el, key) => {
        if (fileNamesWithErrors && Object.keys(fileNamesWithErrors).length > 0 && Object.keys(fileNamesWithErrors).find((errorEl) => errorEl.includes(el))) {
          return { name: el, key, color: 'red', errorMessage: fileNamesWithErrors[el] }
        }
        return { name: el, key, color: 'green' }
      })
    }
    postMessage(newFileNames)
  })
}