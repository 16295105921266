import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import '../FormEmployeeWorkflow.scss'
import {Input, Select, Steps} from "antd";
const { Option } = Select;
const { Step } = Steps;

export class NameTemplateForm extends Component {

    state = {

    }
    componentDidMount() {
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== prevProps && this.props.statusSaveComponentFromWorkflow) {

        }
    }
    onChangeNameAndDetails = (e, key) => {
        const { formerEmployee, listComponentsTemplateForm, component } = this.props
        // listComponentsTemplateForm && listComponentsTemplateForm.length > 0 && listComponentsTemplateForm.forEach((elem) => {
        //     if (elem.sectionId === component.sectionId) {
        //        elem.firstName = e.target.value
        //     }
        // })
        switch (key) {
            case 'first_name':
                const inputValue3 = {
                    name: e.target.value,
                    field: key,
                }
                this.props.onChangeNameAndDetails(e, key, component, inputValue3)
                break
            case 'last_name':
                const inputValue4 = {
                    name: e.target.value,
                    field: key,
                }
                this.props.onChangeNameAndDetails(e, key, component, inputValue4)
                break
            default:
                break
        }
    }
    render() {
        const { formerEmployee, component, stepTicketActive } = this.props
        //console.log('this.props --- RENDER FULL NAME', this.props)
        //console.log('stepTicketActive --- RENDER FULL NAME', stepTicketActive)
        return (
            <div className="full-name-former">
                <span className="header-former">{component.header && component.mandatory ? `${component.header}*` :
                    component.header ? component.header : 'Name'}</span>
                <div className="full-name-component">
                        <span className="input-content">
                            <Input onChange={(e) => this.onChangeNameAndDetails(e, 'first_name')}
                                   value={ component.firstNameValue }
                                   disabled={stepTicketActive.blockFormEdit === true}
                                   className="input-form-name"/>
                            <span className="label-input">First name</span>
                        </span>
                    <span className="input-content">
                            <Input onChange={(e) => this.onChangeNameAndDetails(e, 'last_name')}
                                   value={ component.lastNameValue }
                                   disabled={stepTicketActive.blockFormEdit === true}
                                   className="input-form-name"/>
                            <span className="label-input">Last name</span>
                        </span>
                </div>
            </div>
        )
    }
}


NameTemplateForm.propTypes = {
    folder: PropTypes.object,
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id
})
export default compose(
    connect(mapStateToProps),
)(NameTemplateForm)