import PropTypes from 'prop-types'
import React from 'react'
import './Radio.css'

const Radio = ({ question }) => (
  <div className="questionnaireGlobalRadio__wrapper">
    <h1 className="questionnaireGlobalRadio__title">{ question.order }. { question.title }</h1>
    <ul>
      { question.options.map((option) => (
        <li key={ option.id }>
          <div className="questionnaireGlobalRadio__item">
            <div className="questionnaireGlobalRadio__group">
              <div className="questionnaireGlobalRadio__radioDisabled">
                <input
                  disabled={ true }
                  id={ option.id }
                  name='answer'
                  type="radio"
                  value={ option.title } />
                <label
                  className="questionnaireGlobalRadio__labelDisabled"
                  htmlFor={ option.id }></label>
              </div>
              <span className="questionnaireGlobalRadio__optionTitle">{ option.title }</span>
            </div>
            { option.type === 'OTHER' &&
              <textarea
                className="questionnaireGlobalRadio__textarea"
                disabled={ true }
              ></textarea>
            }
          </div>
        </li>
      ))}
    </ul>
  </div>
)

Radio.propTypes = {
  question: PropTypes.object.isRequired
}

export default Radio
