import PropTypes from 'prop-types'
import React from 'react'
import { Modal } from 'antd'

const TermsOfService = ({ hideTermsOfService }) => (
  <Modal visible={ hideTermsOfService } centered={true} maskClosable={true} footer={ null } onCancel={ hideTermsOfService } className="terms-privacy-modal">
    <div className="modal__padding">
      <h1 className="modal-title">Terms of Service</h1>
      <h2 className="modal-subtitle">Last updated: (21 November 2017)</h2>

      <p className="modal-text">Please read these Terms of Service ("Terms", "Terms of Service") carefully before using the http://www.ospreysmart.com website (the "Service") operated by Gravicus Technologies Limited ("us", "we", "our" or the “Company”).</p>
      <p className="modal-text">Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.</p>
      <p className="modal-text"><strong>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.</strong></p>

      <h2 className="modal-subtitle">Termination</h2>
      <p className="modal-text">We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</p>
      <p className="modal-text">All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>

      <h2 className="modal-subtitle">Copyright information/user rights.</h2>
      <p className="modal-text">All content, images, branding, designs, logos, and other intellectual property appearing on this Website is the property of the Company, unless otherwise stated. As a user, you are authorized only to view, copy, print, and distribute documents found on this website, so long as (1) the document is used for informational purposes only, and (2) any copy of the document (or portion thereof) includes the following copyright notice:  Copyright 2017 Gravicus™. All rights reserved.</p>
      <p className="modal-text">By accessing and using this Website, you agree to abide by UK and International copyright law and all other applicable laws.  You may access this Website and retrieve content that contains Company’s copyright notice, store such content on your own computer, and print one copy of such content for noncommercial, personal, or educational purposes only, provided that you (1) do not modify, distribute, or transmit such content, and (2) include any copyright notice originally appearing with such content.  Permission may be needed for other content not marked with Company’s or any copyright notice.  You may cite or refer to the URL of this Website without limitation.</p>
      <p className="modal-text">All brand, product, service, and process names appearing on this web site are trademarks of their respective holders.  Reference to or use of a product, service, or process does not imply recommendation, approval, affiliation, or sponsorship of that product, service, or process by Company. Nothing contained herein shall be construed as conferring by implication, estoppel, or otherwise any license or right under any patent, copyright, trademark, or other intellectual property right of Company or any third party, except as expressly granted herein.</p>

      <h2 className="modal-subtitle">Disclaimer</h2>
      <p className="modal-text">The information contained in this Service is for general information purposes only. The information is provided by Gravicus Technologies Limited and while we endeavour to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the Service or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.</p>
      <p className="modal-text">In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of the Service.</p>
      <p className="modal-text">Through the Service you are able to link to other websites which are not under the control of Gravicus Technologies Limited. We have no control over the nature, content and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.</p>
      <p className="modal-text">Every effort is made to keep the website up and running smoothly. However, Gravicus Technologies Limited takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.</p>

      <h2 className="modal-subtitle">Warranty information</h2>
      <p className="modal-text">The information and content on this server is provided “as is” with no warranty of any kind, either express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.  </p>

      <h2 className="modal-subtitle">Links To Other Web Sites</h2>
      <p className="modal-text">Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company</p>
      <p className="modal-text">The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>

      <h2 className="modal-subtitle">Changes</h2>
      <p className="modal-text">We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 14 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>

      <h2 className="modal-subtitle">Contact Us</h2>
      <p className="modal-text">If you have any questions about these Terms, please contact us at <a className="modal-link" href="mailto:info@gravicus.com">info@gravicus.com.</a></p>
    </div>
  </Modal>
)

TermsOfService.propTypes = {
  hideTermsOfService: PropTypes.oneOfType([PropTypes.func.isRequired, PropTypes.bool.isRequired])
}

export default TermsOfService
