import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { CSVLink } from 'react-csv'
import withRouter from '../../../../private-routers/withRouter'
import { userIsSuperAdmin } from '../../../../utils'
import Swal from 'sweetalert2'
import moment from 'moment'
import {
  apiReviewActivityUser,
  apiClearReviewActivityUser,
  apiPostReviewActivityUser,
  apiPostDownloadActivityUser
} from '../../../../api/User'
import './ReviewActivityUser.scss'
import CustomNotificationManager from '../../../Common/CustomNotificationManager'
import { Link } from 'react-router-dom'
import {Input, Table} from 'antd'
import {downloadExportLoadedFilesDisclosure} from "../../../../api/SmartBox";
const { Column } = Table
export class LogsForUser extends Component {
  constructor() {
    super()

    this.state = {
      isSaving: false,
      showMessage: {},
      isLoading: true,
      listActions: [],
      firstname: '',
      pageNumber: 1,
      pageRows: 20,
      userName: '',
      listActionsReview: [],
      userId: null
    }

    this.saveChanges = this.saveChanges.bind(this)
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const userId = this.props.params.userId
    //this.fetchReview(userId)
    this.onPostReviewActivityUser()

  }
  fetchReview(userId) {
    apiReviewActivityUser(userId)
      .then((response) => {
        this.initializeFieldsReview(response.data)
      })
  }
  onPostReviewActivityUser = () => {
    const { pageNumber, pageRows } = this.state
    const userId = this.props.params.userId
    const getUserActivityRequestDTO = {
      userId: userId,
      pageNumber: pageNumber,
      pageRows: pageRows
    }
    apiPostReviewActivityUser(getUserActivityRequestDTO).then((response) => {
        this.setState({
          listActionsReview: response.data.actions,
          total: response.data.total,
          userName: response.data.userName,
          userId: response.data.userId,
          isLoading: false,
        })
    })
  }
  initializeFieldsReview(user) {
    const members = []
    const listActions = user.actions
    this.setState({
      listActions,
      isLoading: false,
      members: members,
      firstname: typeof user.userName !== 'undefined' ? user.userName : '',
    })
  }

  handleClearReview() {
    this.setState({ listActions: [] })
    apiClearReviewActivityUser(this.props.params.userId)
  }
  handleClearAllReview = (e) => {
    e.preventDefault()
    const swalReview = Swal.mixin({
      // confirmButtonClass: 'btn btn-success',
      // cancelButtonClass: 'btn btn-danger',
      buttonsStyling: false,
    })
    swalReview.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this history!',
      //type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.handleClearReview()
        CustomNotificationManager.success('Success')
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        CustomNotificationManager.info('Action cancelled', 'Cancelled')
      }
    })
  }

  saveChanges(e) {
    e.preventDefault()
    this.updateUsersRole()
  }
  showTotalPages = (total) => {
    const { pageNumber } = this.state
    return (
        <div className="total-elements">
        <span className="pagination__box__header__total">
          <strong>{total}</strong> results
        </span>
          <span className="pagination__box__header__text">
              Page {pageNumber} of {Math.ceil(total / 20)}
        </span>
        </div>
    )
  }
  onPageChange = (page) => {

    this.setState({
      pageNumber: page
    }, () => {
      this.onPostReviewActivityUser()
    })
  }
  onDownloadCsv = () => {
    const userId = this.props.params.userId
    apiPostDownloadActivityUser(userId).then((response) => {

    })
  }
  render() {
    const { listActions, isLoading, firstname, userName, listActionsReview, userId } = this.state
    const currentUserIsSuperAdmin = userIsSuperAdmin(this.props.user)

    const csvDataHeader = [
      { label: 'Date', key: 'date' },
      { label: 'Initiator', key: 'initiator' },
      { label: 'Action', key: 'action' },
      { label: 'Receiver', key: 'receiver' },
      { label: 'Team Name', key: 'teamName' },
    ]
    const accessToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId

    return (
      <div className="manageLogsForTeam-page">
        <div className="mu_filter__header">
          <div className="questionnaireFilter__wrapper">
            <div className="questionnaireFilter__select">
            </div>
          </div>
        </div>
        <div className="newLogsForTeam__section">
          <header id="review_header" className="review-user-header">

            <div className="styleTitlesReview float-left">
              <h4 className="logs-title"><span className="styleReviewSpanOne ">Review activity for user </span><span className="styleReviewSpanTwo">{ userName }</span></h4>
            </div>
            { !isLoading && currentUserIsSuperAdmin && listActionsReview && listActionsReview.length > 0 ?
              (<div className="button_positionReview">
                <div className="styleAddNewTeam">
                </div>
                <div className="styleCsvLogs">
                  {/*<CSVLink filename={`${ userName }-review-file.csv`} data={listActionsReview.map((x) => ({ date: moment(x.date).format('YYYY-MM-DD @ HH:mm:ss'), initiator: x.initiator, action: x.action, receiver: x.receiver, teamName: x.teamName }))} headers={csvDataHeader}>*/}
                  {/*  <button className='button_big_blue' onClick={(e) => this.onDownloadCsv(e)}>DOWNLOAD CSV</button>*/}
                  {/*</CSVLink>*/}
                  <a href={apiPostDownloadActivityUser(this.props.params.userId, accessToken)}
                     className='button_big_blue_download'
                  >DOWNLOAD CSV</a>
                </div>
             {/*   <div className="styleClearLogs">
                  <Button
                    className="butCancel"
                    theme={this.disabledButton ? 'red' : 'red'}
                    onClick={this.handleClearAllReview}>
                    CLEAR ALL
                  </Button>
                </div>*/}
              </div>) : (<div> </div>)
            }

          </header>

          <div className="review-user-box review-user-box-content">
            <Table
                dataSource={listActionsReview}
                showHeader={false}
                className="table-review-user"
                pagination={{
                  onChange: this.onPageChange,
                  pageSize: 20,
                  showTotal: this.showTotalPages,
                  className: 'review-user-pagination',
                  showLessItems: true,
                  total: this.state.total,
                }}
            >
              <Column title='date'
                      className='column-review-user'
                      dataIndex="date" key="date" render={(text, record) => {
                return (<span className="data-review"> { `${ moment(text).format('YYYY-MM-DD @ HH:mm:ss') }` }  </span>)
              }}/>
              <Column title='initiator'
                      className='column-review-user'
                      dataIndex="initiator" key="initiator" render={(text, record) => {
                return (<span className="initiator-review"> { text }  </span>)
              }}/>
              <Column title='action'
                      className='column-review-user'
                      dataIndex="action" key="action" render={(text, record) => {
                return (<span className="action-review"> { text }  </span>)
              }}/>
              <Column title='document'
                      className='column-review-user'
                      dataIndex="document" key="document" render={(text, record) => {
                return (<span className="document-review"> { text }  </span>)
              }}/>
              <Column title='details'
                      className='column-review-user'
                      dataIndex="details" key="details" render={(text, record) => {
                return (<span className="details-review"> { text }  </span>)
              }}/>
              <Column title='details_content'
                      className='column-review-user'
                      dataIndex="details_content" key="details_content" render={(text, record) => {
                return (<span className="details-content-review"> { text }  </span>)
              }}/>
            </Table>
          </div>

          {/*<div className="review-user-box">*/}
          {/*  { !isLoading && listActions && listActions.length > 0 ?*/}
          {/*    (<div className="manageGroups-tableContainer">*/}
          {/*      <div className="manageTeam-table">*/}
          {/*        { !isLoading && listActions && listActions.length > 0 &&*/}
          {/*          listActions.map((user, index) => {*/}
          {/*            return (*/}
          {/*              <div key={ index + user.action } className="col-xs-12 col-sm-12 col-lg-12 col-md-12 col-xl-12" >*/}
          {/*                <p className="logsForTeam-table"> <span className="logsForTeam-date"> { `${ moment(user.date).format('YYYY-MM-DD @ HH:mm:ss') }` } </span> <span> - </span>*/}
          {/*                  <span className="logsForTeam-initialization">*/}
          {/*                    { user.initiator }*/}
          {/*                  </span>*/}
          {/*                  <span className="logsForTeam-action">*/}
          {/*                    { user.action === 'invite' ? 'INVITED' : user.action === 'create' ? 'CREATED' : user.action === 'disable' ? 'DISABLED' : user.action === 'change role' ? 'CHANGED THE ROLE FOR' : user.action }*/}
          {/*                  </span>*/}
          {/*                  <span className="logsForTeam-initialization">*/}
          {/*                    { user.receiver ? user.receiver : user.document ? user.document : '' }*/}
          {/*                  </span>*/}
          {/*                  { user.action !== 'create' && user.action !== 'change role' && user.teamName ?*/}
          {/*                    (<span className="logsForTeam-action">*/}
          {/*                    TO THE TEAM*/}
          {/*                    </span>) : (<span></span>)*/}
          {/*                  }*/}
          {/*                  { user.action !== 'create' && user.action !== 'change role' ?*/}
          {/*                    <span className="logsForTeam-receiver">*/}
          {/*                      { user.teamName }*/}
          {/*                    </span> : (<span></span>)*/}
          {/*                  }*/}
          {/*                  <span className="logsForTeam-action">*/}
          {/*                    { user.details ? user.details : '' }*/}
          {/*                  </span>*/}
          {/*                  <span className="logsForTeam-receiver">*/}
          {/*                    { user.details_content ? user.details_content : '' }*/}
          {/*                  </span>*/}
          {/*                </p>*/}
          {/*              </div>*/}
          {/*            )*/}
          {/*          })*/}
          {/*        }*/}
          {/*      </div>*/}

          {/*    </div>) : (<div className="styleMessageActivities">The user has no activities</div>)*/}
          {/*  }*/}
          {/*</div>*/}

          <footer className="user-review-footer">
            <Link className="userSettings-footerLink" to="/osp/manage/users">Back to users list</Link>
          </footer>

        </div>
      </div>
    )
  }
}
LogsForUser = withRouter(LogsForUser)
const stateMap = (state) => ({
  user: state.userReducer.user,
})

LogsForUser.propTypes = {
  user: PropTypes.object.isRequired,
}

export default compose(
  connect(stateMap),
)(LogsForUser)
