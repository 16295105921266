import React from 'react'
import './annotations.scss'
import { Table, Input, Popconfirm, Form } from 'antd'
import {deleteAnnotation, saveAnnotationByClientId, updateDefaultAnnotation} from '../../../../api/Sar'
import { connect } from 'react-redux'
import CustomNotificationManager from "../../../Common/CustomNotificationManager";
import {customConfirm} from "../../../../utils/AssetRegister/CustomConfirm";

const EditableContext = React.createContext()
const { TextArea } = Input

class EditableCell extends React.Component {
    getInput = () => {
      if (this.props.inputType === 'textarea') {
        return <TextArea autoSize={{ minRows: 3, maxRows: 4 }} />
      }
      return <Input />
    };

    renderCell = ({ getFieldDecorator }) => {
      const {
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
      } = this.props
      return (
        <td {...restProps}>
          {editing ? (
            <Form.Item style={{ margin: 0 }}>
              {getFieldDecorator(dataIndex, {
                rules: [
                  {
                    required: true,
                    message: `Please Input ${ title }!`,
                  },
                ],
                initialValue: record[dataIndex],
              })(this.getInput())}
            </Form.Item>
          ) : (
            children
          )}
        </td>
      )
    };

    render() {
      return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    }
}

class AnnotationsTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = { data: [], editingKey: '', pageNumber: 1, activeEdit: {}, activeDelete: {} }
    this.columns = [
      {
        title: 'Exemption Code',
        dataIndex: 'exemptionCode',
        width: '10%',
        editable: true,
        align: 'center'
      },
      {
        title: 'Details',
        dataIndex: 'details',
        width: '60%',
        editable: true,
      },
      {
        title: 'Annotation Category',
        dataIndex: 'annotationCategoryEnum',
        width: '20%',
        editable: true,
        align: 'center'
      },
      {
        title: 'Operation',
        dataIndex: 'operation',
        render: (text, record) => {
          const { editingKey } = this.state
          const editable = this.isEditing(record)
          return editable ? (
              <span className="content-edit-annotate">
            <span>
              <EditableContext.Consumer>
                {(form) => (
                  <button className="save-button"
                          onClick={() => this.save(form, record, record.key)}
                          style={{ marginRight: 8 }}
                          disabled={ this.props.statusPendingSaveAnnotation === true}
                  >
                        Save
                  </button>
                )}
              </EditableContext.Consumer>
              <Popconfirm
                  title="Sure to cancel?"
                  onConfirm={() => this.cancel(record.key)}
                  overlayClassName={'annotation-table-pop-confirm'}
                  zIndex={1}>
                <button className="cancel-button">Discard</button>
              </Popconfirm>
            </span>
                <span onClick={(e) => this.showConfirmDeleteAnnotation(e, record)} className={ this.state.activeDelete.key === record.key ? "icon-v19-delete-active" : "icon-v19-delete"}
                      onMouseEnter={(e) => this.onHoverDeleteAnnotation(e, record)}
                      onMouseLeave={() => this.onLeaveDeleteAnnotation()}></span>
                </span>
          ) : (
            <span className={ this.state.activeEdit.key === record.key ? 'icon-v21-edit-active' : "icon-v21-edit" }
                  onMouseEnter={(e) => this.onHoverTableAnnotation(e, record)}
                  onMouseLeave={(e) => this.onLeaveTableAnnotation(e)}
                  disabled={editingKey !== ''} onClick={() => this.edit(record.key)}>
            </span>
          )
        },
      },
    ]
  }
  onHoverDeleteAnnotation = (e, record) => {
    this.setState ( {
      activeDelete : record
    })
  }
  onLeaveDeleteAnnotation = () => {
    this.setState ({
      activeDelete : {}
    })
  }
  onHoverTableAnnotation = (e, record) => {
   this.setState({
     activeEdit: record
   })
  }
  onLeaveTableAnnotation = () => {
    this.setState({
      activeEdit: {}
    })
  }
  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.annotations !== this.props.annotations) {
      this.setState({
        data: nextProps.annotations
      })
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props && this.props.statusAddAnnotation) {
      this.setState({
        data: this.props.annotations
      })
      this.props.getStatusAddAnnotation(false)
    }
  }

  onDeleteAnnotation = (e, record) => {
    const { data } = this.state
    deleteAnnotation(record.key).then((response) => {
      let updateData = []
      if (response.status === 200) {
        if (response.data === 'Annotation was deleted successfully!') {
          CustomNotificationManager.success('Annotation was deleted successfully!', 'Success')
          this.setState({
            editingKey: ''
          })
        }
        if (response.data === 'Annotation does not exist!') {
          updateData = this.state.data && this.state.data.length > 0 && this.state.data.slice(1)
        } else {
          updateData = this.state.data && this.state.data.length > 0 && this.state.data.filter((list) => list.key !== record.key)
        }
        this.setState({
          data: updateData,
        })
        this.props.onDeleteAnnotationAction(updateData)
      }
    })
  }
  showConfirmDeleteAnnotation = (e, record) => {
    customConfirm({
      confirmAction: () => this.onDeleteAnnotation(e, record) ,
      title: '',
      content:
          <React.Fragment>
            <div className={"delete-dict-title"}>
              <div className={"icon-del icon-delete-b"}></div>
              <div className={"delete-dict-q"}>{'Delete selected annotation'}</div>
            </div>
          </React.Fragment>,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      mask: true,
      maskClosable: false,
      closeHaveLogic: false,
      className: 'delete-dict-modal',
      centered: true,
      cancelClassName: 'cancel-button',
      confirmClassName: 'confirm-button'
    })
  }
  isEditing = (record) => record.key === this.state.editingKey;

    cancel = () => {
      this.setState({ editingKey: '' })
    };

    onPageChange = (page, pageSize) => {
      this.cancel()

      this.setState({
        pageNumber: page
      })
    }

    save(form, record, key) {
      form.validateFields((error, row) => {
        if (error) {
          return
        }
        record = { ...record, ...row }
        // record.key = 0
        if (!record.clientId && !(this.props.user.user_metadata.roles.osprey === 'god_admin')) {
          record.clientId = this.props.clientId
          record.defaultAnnotation = false
          record.key = 0
        }
        const newData = [...this.state.data]
        const index = newData.findIndex((item) => key === item.key)
        if (index > -1) {
          if (this.props.user.user_metadata.roles.osprey === 'god_admin') {
            updateDefaultAnnotation(record).then((response) => {
              newData.splice(index, 1, response.data)
              this.setState({ data: newData, editingKey: '' }, () => {
                this.props.updateParentAnnotations(newData)
              })
            }).catch((error) => {
              if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
              }else{
                console.log(error)
              }
            })
          } else if (record.action && record.action === 'Add') {
            record.key = 0
            this.props.setStatusPendingSaveAnnotation(true)
            saveAnnotationByClientId(record.clientId, record).then((response) => {
              newData.splice(index, 1, response.data)
              if (response.status === 200) {
                this.props.setStatusPendingSaveAnnotation(false)
              }
              this.setState({ data: newData, editingKey: '' }, () => {
                this.props.updateParentAnnotations(newData)
              })
            }).catch((error) => {
              if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
              }else{
                console.log(error)
              }
            })
          } else {
            this.props.setStatusPendingSaveAnnotation(true)
            saveAnnotationByClientId(record.clientId, record).then((response) => {
              newData.splice(index, 1, response.data)
              if (response.status === 200) {
                this.props.setStatusPendingSaveAnnotation(false)
              }
              this.setState({ data: newData, editingKey: '' }, () => {
                this.props.updateParentAnnotations(newData)
              })
            }).catch((error) => {
              if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
              }else{
                console.log(error)
              }
            })
          }
        } else {
          newData.push(row)
          this.setState({ data: newData, editingKey: '' })
        }
      })
    }

    edit(key) {
      if (this.state.editingKey && this.state.editingKey !== key) {
        this.setState({ editingKey: '' }, () => {
          this.setState({ editingKey: key })
        })
      } else {
        this.setState({ editingKey: key })
      }
    }

    showTotalPages = (total, range) => {
      const { pageNumber } = this.state
      return (
        <div className="total-elements">
          <span className="pagination__box__header__total">
            <strong>{total}</strong> results
          </span>
          <span className="pagination__box__header__text">
              Page {pageNumber} of {Math.ceil(total / 5)}
          </span>
        </div>
      )
    }

    render() {
      const components = {
        body: {
          cell: EditableCell,
        },
      }

      const columns = this.columns.map((col) => {
        if (!col.editable) {
          return col
        }
        return {
          ...col,
          onCell: (record) => ({
            record,
            inputType: col.dataIndex === 'details' ? 'textarea' : 'text',
            dataIndex: col.dataIndex,
            title: col.title,
            editing: this.isEditing(record),
          }),
        }
      })
      return (
        <EditableContext.Provider value={this.props.form}>
          <Table
            components={components}
            dataSource={this.state.data}
            columns={columns}
            rowClassName="editable-row"
            pagination={{
              onChange: this.onPageChange,
              pageSize: 5,
              showTotal: this.showTotalPages,
              className: 'annotation-pagination',
              showLessItems: true
            }}
            className="annotation-table"
          />
        </EditableContext.Provider>
      )
    }
}

const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id,
  user: state.userReducer.user
})

AnnotationsTable = connect(mapStateToProps)(AnnotationsTable)
export default AnnotationsTable = Form.create()(AnnotationsTable)