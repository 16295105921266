import React, { Component } from 'react'
import { Navigate } from 'react-router-dom'
import moment from 'moment'
import './SubjectsList.scss'
import swal from 'sweetalert2'
import CustomNotificationManager from '../../Common/CustomNotificationManager'
import { apiFetchByFilter, apiSarArchiveSteps, apiSarDeleteRequest } from '../../../api/Sar'
import { Dropdown, DatePicker, Menu, Table, Tooltip, Input, Icon } from 'antd'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { DropdownButton } from 'react-bootstrap'
import Dropdown2 from 'react-bootstrap/Dropdown';
import { customConfirm } from '../../../utils/AssetRegister/CustomConfirm'
import withRouter from '../../../private-routers/withRouter'
export class SubjectsList extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      showLearnMessage: true,
      accordion: {},
      history: {},
      isSending: false,
      redirectToArchiveSar: false,
      expandedRowKeys: [],
      dropdownActive: null,
      columns: [],
      columnSubjectValue: {},
      columnEmailValue: {},
      columnCreatedValue: {},
      columnOwnerValue: {},
      columnFoundValue: {},
      columnDaysLeftValue: {},
      columnStepValue: {},
      searchInputSubject: '',
      searchInputEmail: '',
      searchInputOwner: '',
      datePickerBeginCreated: null,
      datePickerEndCreated: null,
      selectedSar: {},
      selectedClickSarRow: {},
    }
    this.navigateToDpiaDashboard = this.navigateToDpiaDashboard.bind(this)
  }

  componentDidMount() {
    this.initColumns()
  }

  filterHasColumn = (column) => {
    let response = -1
    const { filterCriteria } = { ...this.props }
    if (filterCriteria && filterCriteria.length) {
      filterCriteria.forEach((item, index) => {
        if (item.column === column) {
          response = index
        }
      })
    }
    return response
  }

  handleClickFilterMenu = ({ item, key, domEvent }, column) => {
    const { searchInputSubject, searchInputEmail, searchInputOwner } = { ...this.state }
    let { dropdownActive } = this.state
    const newFilterCriteria = this.props.filterCriteria
    let { sortCriteria } = { ...this.props }

    if (column === 'created' && key === 'selected') {
      return
    }

    if (key === 'asc' || key === 'desc') {
      sortCriteria = {}
      sortCriteria = { column: column, order: key }
    } else {
      switch (column) {
        case 'subject': {
          const index = this.filterHasColumn(column)
          if (searchInputSubject.trim()) {
            if (index > -1) {
              newFilterCriteria[index] = { column: column, searchTerm: searchInputSubject }
            } else {
              newFilterCriteria.push({ column: column, searchTerm: searchInputSubject })
            }
          } else if (index > -1) {
            newFilterCriteria.splice(index, 1)
          }
          break
        }
        case 'email': {
          const index = this.filterHasColumn(column)
          if (searchInputEmail.trim()) {
            if (index > -1) {
              newFilterCriteria[index] = { column: column, searchTerm: searchInputEmail }
            } else {
              newFilterCriteria.push({ column: column, searchTerm: searchInputEmail })
            }
          } else if (index > -1) {
            newFilterCriteria.splice(index, 1)
          }
          break
        }
        case 'created': {
          break
        }
        case 'owner': {
          const index = this.filterHasColumn(column)
          if (searchInputOwner.trim()) {
            if (index > -1) {
              newFilterCriteria[index] = { column: column, searchTerm: searchInputOwner }
            } else {
              newFilterCriteria.push({ column: column, searchTerm: searchInputOwner })
            }
          } else if (index > -1) {
            newFilterCriteria.splice(index, 1)
          }
          break
        }
        case 'step': {
          if (key) {
            const index = this.filterHasColumn(column)
            if (index > -1) {
              if (newFilterCriteria[index].searchTerm === key) {
                newFilterCriteria.splice(index, 1)
              } else {
                newFilterCriteria[index] = { column: column, searchTerm: key }
              }
            } else {
              newFilterCriteria.push({ column: column, searchTerm: key })
            }
          }
          break
        }
        default: {
          if (searchInputSubject) {
            const index = this.filterHasColumn(column)
            if (index > -1) {
              newFilterCriteria[index] = { column: column, searchTerm: searchInputSubject }
            } else {
              newFilterCriteria.push({ column: column, searchTerm: searchInputSubject })
            }
          }
        }
      }
    }

    if (!['search', 'select'].includes(key)) {
      dropdownActive = false
    }

    this.props.setFilterAndSort(sortCriteria, newFilterCriteria)
    this.setState({
      dropdownActive: dropdownActive,
    }, () => {
      this.initColumns()
      if (!['search', 'select'].includes(key)) {
        this.props.fetchSars()
      }
    })
  }

  selectDate = (e, field) => {
    const { filterCriteria } = this.props
    const { columnCreatedValue } = this.state
    let { datePickerBeginCreated, datePickerEndCreated } = this.state
    if (! columnCreatedValue.range) {
      columnCreatedValue.range = [null, null]
    }
    if (field === 'start' && e) {
      columnCreatedValue.range[0] = e.unix()
      datePickerBeginCreated = e

      const newValue = `${ datePickerBeginCreated ? datePickerBeginCreated.unix() : '' },${ datePickerEndCreated ? datePickerEndCreated.unix() : '' }`
      const index = this.filterHasColumn('created')
      if (index > -1) {
        filterCriteria[index] = { column: 'created', searchTerm: newValue }
      } else {
        filterCriteria.push({ column: 'created', searchTerm: newValue })
      }
      this.setState({ datePickerBeginCreated, columnCreatedValue }, () => {
        this.props.setFilter(filterCriteria)
        if (datePickerBeginCreated && datePickerEndCreated) {
          this.setState({ dropdownActive: null }, () => {
            this.initColumns()
            this.props.fetchSars()
          })
        }
      })
    } else if (e) {
      columnCreatedValue.range[1] = e.unix()
      datePickerEndCreated = e

      const newValue = `${ datePickerBeginCreated ? datePickerBeginCreated.unix() : '' },${ datePickerEndCreated ? datePickerEndCreated.unix() : '' }`
      const index = this.filterHasColumn('created')
      if (index > -1) {
        filterCriteria[index] = { column: 'created', searchTerm: newValue }
      } else {
        filterCriteria.push({ column: 'created', searchTerm: newValue })
      }
      this.setState({ datePickerEndCreated, columnCreatedValue }, () => {
        this.props.setFilter(filterCriteria)
        if (datePickerBeginCreated && datePickerEndCreated) {
          this.setState({ dropdownActive: null }, () => {
            this.initColumns()
            this.props.fetchSars()
          })
        }
      })
    } else if (field === 'start') {
      datePickerBeginCreated = null
      const newValue = `${ datePickerBeginCreated ? datePickerBeginCreated.unix() : '' },${ datePickerEndCreated ? datePickerEndCreated.unix() : '' }`
      const index = this.filterHasColumn('created')
      if (!datePickerBeginCreated && !datePickerEndCreated && index > -1) {
        filterCriteria.splice(index, 1)
      } else {
        filterCriteria[index] = { column: 'created', searchTerm: newValue }
      }
      this.setState({ datePickerBeginCreated, dropdownActive: null }, () => {
        this.props.setFilter(filterCriteria)
        this.initColumns()
      })
    } else {
      datePickerEndCreated = null
      const newValue = `${ datePickerBeginCreated ? datePickerBeginCreated.unix() : '' },${ datePickerEndCreated ? datePickerEndCreated.unix() : '' }`
      const index = this.filterHasColumn('created')
      if (!datePickerBeginCreated && !datePickerEndCreated && index > -1) {
        filterCriteria.splice(index, 1)
      } else {
        filterCriteria[index] = { column: 'created', searchTerm: newValue }
      }
      this.setState({ datePickerEndCreated, dropdownActive: null }, () => {
        this.props.setFilter(filterCriteria)
        this.initColumns()
      })
    }
  }

  openDropdown = (columnSelected) => {
    if (this.state.dropdownActive === columnSelected) {
      this.setState({ dropdownActive: null }, () => this.initColumns())
    } else {
      this.setState({ dropdownActive: columnSelected }, () => this.initColumns())
    }
  }

  initColumns = () => {
    const columns = [
      {
        key: 'subject',
        title: this.renderColumnTitle('Subject', 'subject'),
        dataIndex: 'subject',
        render: (text, record, index) => {
          return <div key={record.id} className="text-container text-container--start">
            { text }
          </div>
        }
      },
      {
        key: 'email',
        title: this.renderColumnTitle('Email', 'email'),
        dataIndex: 'email',
        render: (text, record, index) => {
          return <div key={record.id} className="text-container text-container--center">
            { text }
          </div>
        }
      },
      {
        key: 'creationDate',
        title: this.renderColumnTitle('Created', 'created'),
        dataIndex: 'creationDate',
        align: 'center',
        render: (text, record, index) => {
          return <div key={record.id} className="text-container text-container--center">
            {/*{ moment(text).format('DD.MM.YYYY HH:mm') }*/}
            { moment(text).format('DD.MM.YY') }
          </div>
        }
      },
      {
        key: 'owner',
        title: this.renderColumnTitle('Owner', 'owner'),
        dataIndex: 'ownerName',
        render: (text, record, index) => {
          return <div key={record.id} className="text-container text-container--center">
            { text }
          </div>
        }
      },
      {
        key: 'found',
        title: this.renderColumnTitle('Found', 'found'),
        dataIndex: 'found',
        align: 'center',
        render: (text, record, index) => {
          return <div key={record.id} className="text-container text-container--center text-container--transform ">
            { text === 1 ? `${ text } document` : `${ text } documents` }
          </div>
        }
      },
      {
        key: 'step',
        title: this.renderColumnTitle('Step', 'step'),
        dataIndex: 'lastStep',
        align: 'center',
        render: (text, record, index) => {
          return <div key={record.id} className="text-container text-container--center">
            { this.getStepStatus(text, record) }
          </div>
        }
      },
      {
        key: 'box',
        title: 'Box',
        dataIndex: 'box',
        render: (text, record, index) => {
          return <div key={record.id} className="text-container text-container--center">
            <Tooltip title = {` ${ record.boxId ? record.boxName : 'Unselected' }`} placement="bottom">
              <span
                className= {`icon-v10-smart-folder-medium-2 ${ record.boxId ? 'icon-blue' : 'icon-grey' }`}
                onClick = { () => this.gotToSmartbox(record.boxId) }
              ></span>
            </Tooltip>
          </div>
        }
      },
      {
        key: 'daysLeft',
        title: this.renderColumnTitle('Days left', 'daysLeft'),
        dataIndex: 'timeLeft',
        align: 'center',
        render: (text, record, index) => {
          return <div key={record.id} className="text-container text-container--center text-container--days">
            { record.sarCompleted === true && record.step === 'COMPLETE' ? '-' : text ? this.getDaysLeft(text) : '-' }
          </div>
        }
      },
      {
        key: 'menuSar',
        title: '',
        dataIndex: 'menuSar',
        align: 'center',
        className: 'parent-delete-sar',
        render: (text, record, index) => {
          return (
            <div className="actionsDotsCell action-drop-cell">
              <DropdownButton
                className="dots-menu-sar"
                title={(this.state.selectedSar && this.state.selectedSar.id === record.id) || (this.state.selectedClickSarRow && this.state.selectedClickSarRow.id && this.state.selectedClickSarRow.id === record.id) ?
                  <div className="ellipsis-v2"><span className="icon-v14-options-small-active"></span></div> : <div className="ellipsis-v3">,,,,</div> }
                nocaret="true"
                data-toggle="dropdown"
                id={ 'dropdown-1' }
                // onClick={ (e) => {
                //   e.stopPropagation()
                // } }
                bsstyle="default">
                <Dropdown2.Item className="menu-left-item-directory"
                  onClick={ (e, props) => this.handleDeleteSarAction(record, 'delete', e) }>
                    Delete
                </Dropdown2.Item>
              </DropdownButton>
            </div>
          )
        }
      }
    ]
    this.setState({ columns })
  }

  gotToSmartbox = (boxId) => {
    if (boxId) {
      this.props.navigate(`/smart-box/folder/${ boxId }`)
    }
  }
  executeActionSar = (record, action, e) => {
    if (action === 'delete') {
      const sarSelected = []
      sarSelected.push(record.id)
      const deleteSarDTO = {
        sarIds: sarSelected
      }
      apiSarDeleteRequest(deleteSarDTO).then(() => {
        this.fetchData()
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    }

  }
  handleDeleteSarAction = (record, action, e) => {
    customConfirm({
      title: 'Do you want to delete request?',
      content: `${ record.subject }`,
      confirmButtonText: 'DELETE',
      cancelButtonText: 'CANCEL',
      mask: true,
      maskClosable: false,
      closeHaveLogic: false,
      className: 'asset-register-modal',
      centered: true,
      cancelClassName: 'cancel-button',
      confirmClassName: 'confirm-button',
      confirmAction: () => {
        this.executeActionSar(record, action, e)
      }
    })
  }
  onFilterSearchChange = (e, key) => {
    switch (key) {
      case 'subject':
        this.setState({ searchInputSubject: e.target.value }); break
      case 'email':
        this.setState({ searchInputEmail: e.target.value }); break
      default:
        this.setState({ searchInputOwner: e.target.value })
    }
    this.setState({ dropdownActive: false })
  }

  fetchData = () => {
    const { sortCriteria, filterCriteria } = this.state
    const { currentPage, itemsPerPageLimit } = this.props

    // create data
    const data = {
      sort: sortCriteria,
      filter: filterCriteria,
      pageNumber: currentPage - 1,
      amountPerPage: itemsPerPageLimit,
      clientId: this.props.clientId
    }
    apiFetchByFilter(data).then((response) => {
      if (response.status === 200) {
        this.props.updateListAfterFilers(response.data)
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  disabledDateStart = (current) => {
    const { datePickerEndCreated } = this.state
    if (datePickerEndCreated) {
      return current > datePickerEndCreated
    }
    return current && current > moment()
  }

  disabledDateEnd = (current) => {
    const { datePickerBeginCreated } = this.state
    if (datePickerBeginCreated) {
      return current <= datePickerBeginCreated
    }
    return null
  }

  getClassForTableFilters = (key) => {
    const { sortCriteria, filterCriteria } = this.props
    let titleColorClass = ''
    if (Object.keys(sortCriteria).length) {
      if (sortCriteria.column === key) {
        titleColorClass = 'black-title'
      }
    }
    if (filterCriteria && filterCriteria.length) {
      filterCriteria.forEach((item) => {
        if (item.column === key) {
          titleColorClass = 'black-title'
        }
      })
    }
    return titleColorClass
  }

  getSelectedItems = (key) => {
    const { sortCriteria } = this.props
    let selectedItems = ''
    if (Object.keys(sortCriteria).length) {
      if (sortCriteria.column === key) {
        selectedItems = Object.values(sortCriteria)
      }
    }
    return selectedItems
  }

  getSelectedStepItem = () => {
    const { filterCriteria } = this.props
    let selectedItem = ''
    if (filterCriteria && filterCriteria.length) {
      filterCriteria.forEach((item) => {
        if (item.column === 'step') {
          selectedItem = item.searchTerm
        }
      })
    }
    return selectedItem
  }

  renderColumnTitle = (title, key) => {
    const { dropdownActive } = this.state
    const { Search } = Input
    const { SubMenu } = Menu
    const titleColorClass = this.getClassForTableFilters(key)
    let selectedItems = this.getSelectedItems(key)
    const selectedStepItem = this.getSelectedStepItem()
    if (selectedItems) {
      if (selectedStepItem) {
        selectedItems.push(selectedStepItem)
      }
    } else if (selectedStepItem) {
      selectedItems = [selectedStepItem]
    }
    const columnTitleMenu = (
      <Menu
        className="sar-dashboard-filter-menu"
        onClick={ (e) => this.handleClickFilterMenu(e, key) }
        mode="vertical"
        selectedKeys={ selectedItems }
      >
        {['subject', 'email', 'owner', 'step'].includes(key) &&
          <Menu.Item key= "asc">Sort A-Z</Menu.Item>
        }
        {['subject', 'email', 'owner', 'step'].includes(key) &&
          <Menu.Item key= "desc">Sort Z-A</Menu.Item>
        }
        { key === 'step' &&
          <SubMenu
            key="sub1"
            title='Filter by status'
            popupClassName="steps-filters-popup-position"
          >
            <Menu.Item key="0">Action Notification</Menu.Item>
            <Menu.Item key="1">Request</Menu.Item>
            <Menu.Item key="2">Acknowledge</Menu.Item>
            <Menu.Item key="3">ID Check</Menu.Item>
            <Menu.Item key="4">Rights</Menu.Item>
            <Menu.Item key="5">Data</Menu.Item>
            <Menu.Item key="6">Send</Menu.Item>
            <Menu.Item key="8">Complete</Menu.Item>
          </SubMenu>
        }
        {['found', 'daysLeft'].includes(key) &&
          <Menu.Item key= "desc">Sort High - Low</Menu.Item>
        }
        {['found', 'daysLeft'].includes(key) &&
          <Menu.Item key= "asc">Sort Low - High</Menu.Item>
        }
        { key === 'created' &&
          <Menu.Item key= "desc">Sort Latest - Oldest</Menu.Item>
        }
        { key === 'created' &&
          <Menu.Item key= "asc">Sort Oldest - Latest</Menu.Item>
        }
        { key === 'created' &&
          <Menu.Item key= "select" className={`date-picker-input-wrapper date-picker-input-wrapper_${ key }`}>
            <DatePicker
              className="date-picker"
              disabledDate={ this.disabledDateStart }
              dropdownClassName="date-picker-selector date-picker-selector--left"
              placeholder="Date"
              format='DD.MM.YY'
              showToday = {false}
              onChange={ (e) => this.selectDate(e, 'start')}
              suffixIcon={<span className="icon-calendar-b" />}
            />
            <span className="date-picker-to"> to </span>
            <DatePicker
              className="date-picker"
              disabledDate={ this.disabledDateEnd }
              dropdownClassName="date-picker-selector date-picker-selector--right"
              placeholder="Date"
              format='DD.MM.YY'
              showToday = {false}
              onChange={ (e) => this.selectDate(e, 'end')}
              suffixIcon={ <span className="icon-calendar-b" /> }
            />
          </Menu.Item>
        }
        {['subject', 'email', 'owner'].includes(key) &&
          <Menu.Item key="search" className={`search-input-wrapper search-input-wrapper_${ key }`}>
            <Search placeholder="input search text" onChange={ (e) => this.onFilterSearchChange(e, key) } onSearch={ () => this.props.fetchSars() }/>
          </Menu.Item>
        }
      </Menu>
    )
    return <Dropdown overlay={columnTitleMenu} visible={ dropdownActive === key } trigger={['click']}>
      <span className={`ant-dropdown-linke + ${ titleColorClass }`} onClick={ () => this.openDropdown(key)}>
        { title } <span className="icon-v10-arrow"></span>
      </span>
    </Dropdown>
  }

  navigateToDpiaDashboard() {
    this.props.navigate('/sar/dashboard')
  }

  addExpandedKey = (index) => this.setState({ expandedRowKeys: [...this.state.expandedRowKeys, index ] })
  removeExpandedKey = (index) => this.setState({ expandedRowKeys: this.state.expandedRowKeys.filter((value) => value !== index) })

  getStepStatus(step, record) {
    switch (step) {
      case 'INITIAL_REQUEST' :
        return <div className='step-icon'>
          <Tooltip title="Request" placement="bottom">
            <span className='icon-v2-request icon-step'></span>
            {(record.feedbackStatusMessage === 'true') &&
            <Icon className="exclamation-mark" type="exclamation-circle"/>
            }
          </Tooltip>
        </div>
      case 'ACKNOWLEDGE' :
        return <div className='step-icon'>
          <Tooltip title="Acknowledge" placement="bottom">
            <span className='icon-v10-acknowledged icon-step'></span>
            {(record.feedbackStatusMessage === 'true') &&
            <Icon className="exclamation-mark" type="exclamation-circle"/>
            }
          </Tooltip>
        </div>
      case 'ACKNOWLEDGE_UNCOMPLETED' :
        return <div className='step-icon'>
          <Tooltip title="Acknowledge" placement="bottom">
            <span className='icon-v10-acknowledged icon-step'></span>
            {(record.feedbackStatusMessage === 'true') &&
            <Icon className="exclamation-mark" type="exclamation-circle"/>
            }
          </Tooltip>
        </div>
      case 'CHECK_ID' :
        return <div className='step-icon'>
          <Tooltip title="Check ID" placement="bottom">
            <span className='icon-v2-checkid icon-step'></span>
            {(record.feedbackStatusMessage === 'true') &&
            <Icon className="exclamation-mark" type="exclamation-circle"/>
            }
          </Tooltip>
        </div>
      case 'RESPOND' :
        return <div className='step-icon'>
          <Tooltip title="Rights" placement="bottom">
            <span className='icon-v10-rights-1 icon-step'></span>
          </Tooltip>
        </div>
      case 'SEARCH_FIND_ADD_DOCUMENTS' :
        return <div className='step-icon'>
          <Tooltip title="Data" placement="bottom">
            <span className='icon-v10-data resize-icon-v10-data'></span>
          </Tooltip>
        </div>
      case 'REVIEW_AND_REDACT' :
        return <div className='step-icon'>
          <Tooltip title="Send" placement="bottom">
            <span className='icon-v2-email icon-step'></span>
          </Tooltip>
        </div>
      case 'SEND_SECURELY' :
        return <div className='step-icon'>
          <Tooltip title="Send" placement="bottom">
            <span className='icon-v2-email icon-step'></span>
            {(record.feedbackStatusMessage === 'true') &&
            <Icon className="exclamation-mark" type="exclamation-circle"/>
            }
          </Tooltip>
        </div>
      case 'COMPLETE' :
        return <div className={record.sarCompleted === true ? 'sar-completed step-icon' : 'step-icon'}>
          <Tooltip title="Completed" placement="bottom">
            <span className='icon-v10-completed icon-step'></span>
          </Tooltip>
        </div>
      default:
        return 'no status'
    }
  }

  getName({ step, feedbackStatusMessage }) {

    switch (step) {
      case 'INITIAL_REQUEST' :
        return 'Initial Request'
      case 'ACKNOWLEDGE' :
        return 'Acknowledge'
      case 'CHECK_ID' :
        return 'Check Id'
      case 'RESPOND' :
        return 'Respond'
      case 'COMPLETE' : {
        if (feedbackStatusMessage) {
          return feedbackStatusMessage
        }
        return 'Complete'
      }

      case 'SEARCH_FIND_ADD_DOCUMENTS' :
        return 'Search Find Add Documents'
      case 'REVIEW_AND_REDACT' :
        return 'Review And Redact'
      case 'SEND_SECURELY' :
        return 'Send Securely'
      default:
        return 'No status'
    }

  }

  getDaysLeft(time) {

    if (time && time[0] >= 7) {
      return (
        <span>{ `${ time[0] }d ${ time[1] }h`}</span>
      )
    } else if (time && time[0] < 0) {
      return (
        <span className="time-span-color">{ `${ time[0] }d ${ -time[1] }h`}</span>
      )
    } else if (time && time[0] >= 0 && time[0] < 7 && time[1] >= 0) {
      return (
        <span className="time-span-color-yellow">{ `${ time[0] }d ${ time[1] }h`}</span>
      )
    } else if (time && time[0] === 0 && time[1] < 0) {
      return (
        <span className="time-span-color">{ `-${ time[0] }d ${ -time[1] }h`}</span>
      )
    }
  }
  archiveStepClicked(e, id) {
    e.preventDefault()
    swal.fire({
      text: 'This step will be archived so that your reports will still be accessible.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3DD8DB',
      cancelButtonColor: '#D33D33',
      confirmButtonText: 'Yes, archive it!'
    }).then((result) => {
      if (result.value) {
        this.confirmedActionToArchive(id)
      } else {
        CustomNotificationManager.info('Action cancelled', 'Aborted')
      }
    })
  }

  confirmedActionToArchive(id) {
    this.setState({
      isSending: true
    })
    apiSarArchiveSteps(id)
      .then(() => {
        CustomNotificationManager.success('Archived successfully', 'Success')
        this.setState({
          isSending: false,
          redirectToArchiveSar: true
        })
      }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  renderContentAccordion(obj) {
    let details = 'Without details'
    if (typeof obj.details !== undefined) {
      if (obj.details !== null && obj.details.trim() !== '') {
        details = obj.details
      }
    }
    return (
      <React.Fragment>
        <div className="subject-list-info">
          <div className="detailsAccordion">
            <p>{ details }</p>
          </div>
          <div className="emailAccordion">
            <p>email: <span className="font-weight-bold">{ obj.email }</span></p>
          </div>
        </div>
      </React.Fragment>
    )
  }
  onClickRowSelected = (e, record) => {
    this.props.onSelectSarRow(record)
    this.setState({
      selectedClickSarRow: record
    })
  }
  onMouseEnterRow = (e, record) => {
    this.setState({
      selectedSar: record
    })
  }
  onMouseLeaveRow = (e) => {
    this.setState({
      selectedSar: {}
    })
  }
  rowClassSelected = (record) => {
    const { selectedClickSarRow } = this.state
    if (record.id === selectedClickSarRow.id) {
      return 'row-selected-table'
    }
  }
  render() {
    const { redirectToArchiveSar, expandedRowKeys, columns } = this.state
    const { isLoading, sars, checkSelectAllSar } = this.props
    return (
      <div>
        { redirectToArchiveSar &&
          <Navigate to="/sar/archive" />
        }
        { isLoading &&
          <div className="dpiaDashboard-loading">

          </div>
        }
        <div className ="styleAccordion">
          <Table
            columns={ columns }
            rowKey = { (record) => { return `rowKey${ record.id }` }}
            expandedRowRender={ (record) => this.renderContentAccordion(record) }
            dataSource={ sars }
            pagination={ false }
            expandedRowKeys={ expandedRowKeys }
            className="sar-dashboard-table"
            rowClassName={checkSelectAllSar ? 'row-selected-table' : (record) => this.rowClassSelected(record) }
            onRow={(record, rowIndex) => {
              return {
                onClick: (e) => this.onClickRowSelected(e, record),
                onMouseEnter: (e) => this.onMouseEnterRow(e, record),
                onMouseLeave: (e) => this.onMouseLeaveRow(e),
                onDoubleClick: (event) => {
                  this.props.navigate(`/sar/tracker/${ record.id }`)
                }
              }
            }}

          />
        </div>
      </div>
    )
  }
}
SubjectsList = withRouter(SubjectsList)
const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id
})

export default compose(connect(mapStateToProps))(SubjectsList)