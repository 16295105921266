export default () => {
  self.addEventListener('message', e => { // eslint-disable-line no-restricted-globals
    if (!e) return {
      totalBatchOfFiles: [],
      totalBatchOfFilesNames: []
    }
    const fileList = e.data
    const limitInBytesPerRequst = 1000000
    const totalBatchOfFiles = []
    const totalBatchOfFilesNames = []
    let oneBatchOfFiles = []
    let oneBatchOfFilesNames = []
    let totalBatchSize = 0
    let totalFilesSize = 0
    fileList.map((f) => totalFilesSize += f.size)
    const batchNumber = totalFilesSize / limitInBytesPerRequst > 0 && totalFilesSize / limitInBytesPerRequst < 1 ? 1 : Math.round(totalFilesSize / limitInBytesPerRequst)
    for (let i = 0; i < fileList.length; i++) {
      oneBatchOfFiles.push(fileList[i])
      oneBatchOfFilesNames.push(fileList[i].name)
      totalBatchSize += fileList[i].size
      if (totalBatchSize >= limitInBytesPerRequst || totalBatchSize <= limitInBytesPerRequst) {
        totalBatchSize = 0
        totalBatchOfFiles.push(oneBatchOfFiles)
        totalBatchOfFilesNames.push(oneBatchOfFilesNames)
        oneBatchOfFiles = []
        oneBatchOfFilesNames = []
      }
    }
    if (batchNumber > totalBatchOfFiles.length && oneBatchOfFiles.length > 0) {
      totalBatchOfFiles.push(oneBatchOfFiles)
      totalBatchOfFilesNames.push(oneBatchOfFilesNames)
    }
    postMessage({
      totalBatchOfFiles,
      totalBatchOfFilesNames,
    })
  })
}