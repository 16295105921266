import React from 'react'
import PropTypes from 'prop-types'

import './Post.css'

const Post = ({ content }) => (
  <a className="post-link" href={ content.url } >
    <h3 className="post-title">{ content.title }</h3>
    <p className="post-date">{ content.date }</p>
  </a>
)

Post.propTypes = {
  content: PropTypes.object.isRequired
}

export default Post