import React from "react";
import {connect} from "react-redux";
import './BuilderWorkflow.scss'
import {Button, Input, Select} from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import FormBuilder from './componentsBuilder/FormBuilder'
import EmailBuilder from './componentsBuilder/EmailBuilder'
import BusinessProcessBuilder from './componentsBuilder/BusinessProcessBuilder'
import DataSourceBuilder from './componentsBuilder/DataSourceBuilder'
import Disclosure from './componentsBuilder/Disclosure'
import {customConfirm} from "../../../../utils/AssetRegister/CustomConfirm";
import {
    apiGetWorkflowBuilder,
    apiGetWorkflowSelected,
    apiWorkflowBuilderCreate, apiWorkflowBuilderDelete,
    apiWorkflowBuilderEdit
} from "../../../../api/SmartBox";
import CustomNotificationManager from "../../../Common/CustomNotificationManager";
const { Option } = Select
class BuilderWorkflow extends React.Component {
    state = {
        listAddSection: [
            {id: 'form',key: 0, nameSection: 'Form'},
            {id: 'email',key: 1, nameSection: 'Email'},
            {id: 'business-process',key: 2, nameSection: 'Business process'},
            {id: 'data-source',key: 3, nameSection: 'Data source'},
            {id: 'disclosure',key: 4, nameSection: 'Disclosure'},
        ],
        valueWorkflowName: '',
        valueSection: '',
        listComponentsSection: [],
        keySection: 0,
        actionProvidedDraggable: {},
        valuesAddSection: [],
        idForm: null,
        idEmailTemplate: null,
        idBusinessProcess: null,
        listWorkflow: [],
        valueSelectWorkflow: '',
        hoverDeleteButton:false,
    }

    componentDidMount() {
      this.onGetWorkflowBuilderList()
    }

    onGetWorkflowBuilderList = () => {
        apiGetWorkflowBuilder(this.props.clientId).then((response) => {
           this.setState({
               listWorkflow: response.data
           })
        })
    }
    onCLickCancelBuilder = () => {
        this.setState({
            valueWorkflowName: '',
            valueSection: '',
            listComponentsSection: [],
            valueSelectWorkflow: ''
        })
    }
    onCLickSaveBuilder = () => {
        const { listComponentsSection, valueWorkflowName, valuesAddSection, idForm, idEmailTemplate, idBusinessProcess } = this.state
        const { clientId } = this.props
        const sectionWorkflow = []
        const valuesSections = []
        const workflowComponents = []
        listComponentsSection && listComponentsSection.length > 0 && listComponentsSection.forEach((list) => {
            valuesSections.push(list.name)
            workflowComponents.push({
                idComponent: list.name === 'form' ? idForm : list.name === 'email' ? idEmailTemplate : list.name === 'business process' ? idBusinessProcess : null,
                componentName: list.componentName,
                componentType: list.name,
            })
        })
       const createNewWorkflowRequestDTO  = {
           clientId: clientId,
           workflowName: valueWorkflowName,
           workflowComponents: workflowComponents
           //idForm: idForm,
           // idEmailTemplate: idEmailTemplate,
           // idBusinessProcess: idBusinessProcess,
           // hasDataSource: valuesSections.includes('data source') ? true :  false,
           // hasDisclosure: valuesSections.includes('disclosure') ? true :  false,
       }
        apiWorkflowBuilderCreate(createNewWorkflowRequestDTO).then((response) => {
            this.onGetWorkflowBuilderList()
            if (response?.data?.title?.toLowerCase() === 'success') {
                CustomNotificationManager.success(response.data.message, 'Success')
            } else if(response?.data?.title?.toLowerCase() === 'info') {
                CustomNotificationManager.info(response.data.message, 'Info')
            }
        })
    }
    onCLickEditBuilder = () => {
        const { listComponentsSection, valueWorkflowName, valuesAddSection, idForm, idEmailTemplate, idBusinessProcess, workflowId } = this.state
        const { clientId } = this.props
        const sectionWorkflow = []
        const valuesSections = []
        const workflowComponents = []
        listComponentsSection && listComponentsSection.length > 0 && listComponentsSection.forEach((list) => {
            valuesSections.push(list.name)
            workflowComponents.push({
                idComponent: list.name === 'form' ? idForm : list.name === 'email' ? idEmailTemplate : list.name === 'business process' ? idBusinessProcess : null,
                componentName: list.componentName,
                componentType: list.name,
            })
        })
        const editNewWorkflowRequestDTO  = {
            idWorkflow: workflowId,
            clientId: clientId,
            workflowName: valueWorkflowName,
            workflowComponents: workflowComponents
            // idForm: idForm,
            // idEmailTemplate: idEmailTemplate,
            // idBusinessProcess: idBusinessProcess,
            // hasDataSource: valuesSections.includes('data source') ? true :  false,
            // hasDisclosure: valuesSections.includes('disclosure') ? true :  false,
        }
        apiWorkflowBuilderEdit(editNewWorkflowRequestDTO).then((response) => {
            this.onGetWorkflowBuilderList()
            if (response.data.title.toLowerCase() === 'success') {
                CustomNotificationManager.success(response.data.message, 'Success')
            } else if(response.data.title.toLowerCase() === 'info') {
                CustomNotificationManager.info(response.data.message, 'Info')
            }
        })
    }
    onChangeWorkflowName = (e) => {
        this.setState({
            valueWorkflowName: e.target.value
        })
    }
    onSelectAddSection = (e) => {
        const { listComponentsSection, keySection, valuesAddSection } = this.state
        this.setState({
            valueSection: e
        })
        const sectionNames = []
        const valuesNames = []
        listComponentsSection && listComponentsSection.length > 0 && listComponentsSection.forEach((list) => {
            sectionNames.push(list.name)
        })
        switch(e) {
            case 'Form':
                if (!sectionNames.includes('form')) {
                    listComponentsSection.push({id: 'form', key: keySection, name: 'form'})
                    valuesAddSection.push(e.toLowerCase())
                }
                break
            case 'Email':
                if (!sectionNames.includes('email')) {
                    listComponentsSection.push({id: 'email', key: keySection, name: 'email'})
                    valuesAddSection.push(e.toLowerCase())
                }
                break
            case 'Business process':
                if (!sectionNames.includes('business process')) {
                    listComponentsSection.push({id: 'business-process', key: keySection, name: 'business process'})
                    valuesAddSection.push(e.toLowerCase())
                }
                break
            case 'Data source':
                if (!sectionNames.includes('data source')) {
                    listComponentsSection.push({id: 'data-source', key: keySection, name: 'data source'})
                    valuesAddSection.push(e.toLowerCase())
                }
                break
            case 'Disclosure':
                if (!sectionNames.includes('disclosure')) {
                    listComponentsSection.push({id: 'disclosure', key: keySection, name: 'disclosure'})
                    valuesAddSection.push(e.toLowerCase())
                }
                break
            default:
                break
        }
        this.setState({
            keySection: keySection + 1,
            listComponentsSection,
            valuesAddSection
        })
        setTimeout(() => {
           this.setState({
               valueSection: 'Add section'
           })
        }, 2000)
    }
    onGenerateComponentsBuilder = (component, onStateProvidedDraggable, showConfirmDeleteSection, onChangeBuilder, onSelectIdForm, onSelectIdEmailTemplate, onSelectIdBusinessProcess) => {
        switch(component.name.toLowerCase()) {
            case 'form':
                return <FormBuilder component={component} onStateProvidedDraggable={onStateProvidedDraggable} showConfirmDeleteSection={showConfirmDeleteSection}
                                    onChangeBuilder={ onChangeBuilder } onSelectIdForm={onSelectIdForm}
                                   />
                break
            case 'email' || 'internal email notification' || 'email template':
                return <EmailBuilder component={component} onStateProvidedDraggable={onStateProvidedDraggable} showConfirmDeleteSection={showConfirmDeleteSection}
                                     onChangeBuilder={ onChangeBuilder } onSelectIdEmailTemplate={onSelectIdEmailTemplate}
                />
                break
            case 'business process' || 'collector':
                return <BusinessProcessBuilder component={component} onStateProvidedDraggable={onStateProvidedDraggable} showConfirmDeleteSection={showConfirmDeleteSection}
                                               onChangeBuilder={ onChangeBuilder } onSelectIdBusinessProcess={ onSelectIdBusinessProcess }
                />
                break
            case 'data source':
                return <DataSourceBuilder component={component} onStateProvidedDraggable={onStateProvidedDraggable} showConfirmDeleteSection={showConfirmDeleteSection}
                                          onChangeBuilder={ onChangeBuilder }
                />
                break
            case 'disclosure':
                return <Disclosure component={component} onStateProvidedDraggable={onStateProvidedDraggable} showConfirmDeleteSection={showConfirmDeleteSection}
                                   onChangeBuilder={ onChangeBuilder }
                />
                break
            case 'Disclosure':
                return <Disclosure component={component} onStateProvidedDraggable={onStateProvidedDraggable} showConfirmDeleteSection={showConfirmDeleteSection}
                                   onChangeBuilder={ onChangeBuilder }
                />
                break
            default:
                break
        }
        this.setState({
            valueSection: 'Add section'
        })
    }
    handleDropComponent = (droppedItem) => {
        if (!droppedItem.destination) return
        const updatedList = [...this.state.listComponentsSection];
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        updatedList && updatedList.length > 0 && updatedList.forEach((item, index) => {
            item.key = index
        })
        this.setState({
            listComponentsSection: updatedList
        })
    }
    onStateProvidedDraggable = (state) => {
        this.setState({
            actionProvidedDraggable: state
        })
    }
    onLeaveProvidedDraggable = (state) => {
        this.setState({
            actionProvidedDraggable: {}
        })
    }
    onProvidedDraggable = (provided) => {
        return {...provided.draggableProps}
    }
    showConfirmDeleteSection = (e, component) => {
        customConfirm({
            title: <span className="icon-v14-delete"></span>,
            content: `Delete section?`,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            mask: true,
            maskClosable: false,
            closeHaveLogic: false,
            className:'asset-register-modal modal-delete-connector',
            centered: true,
            cancelClassName: 'cancel-button',
            confirmClassName: 'confirm-button',
            confirmAction: () => {
                this.onActionDeleteSection(e, component)
            }
        })
    }
    onActionDeleteSection = (e, component) => {
        const { listComponentsSection } = this.state
        if (listComponentsSection && listComponentsSection.length > 0) {
            let filtersForm = []
            filtersForm = listComponentsSection.filter((elem) => elem.key !== component.key)

            this.setState({
                listComponentsSection: filtersForm
            })
        }
    }
    onChangeBuilder = (builder, component) => {
        const { listComponentsSection } = this.state
        listComponentsSection && listComponentsSection.length > 0 &&  listComponentsSection.forEach((list) => {
            if (list.key === component.key) {
                list.selectEmail = builder.selectEmail
                list.selectForm = builder.selectForm
                list.componentName = builder.componentName
                list.idComponent = builder.idComponent
            }
        })
        this.setState({
            listComponentsSection
        })
    }
    onSelectWorkflowBuilder = (e) => {
      this.setState({
          valueSelectWorkflow: e
      }, () => {
          apiGetWorkflowSelected(e).then((response) => {
              const listComponents = []
              response.data.workflowTemplateComponentResponseDTOList && response.data.workflowTemplateComponentResponseDTOList.length > 0 && response.data.workflowTemplateComponentResponseDTOList.forEach((item, index) => {
                  listComponents.push({
                      id: item.componentType === 'Form template' ? 'form' : item.componentType,
                      key: index,
                      name: item.componentType === 'Form template' ? 'form' :
                          item.componentType === 'Email template' ? 'email' :
                              item.componentType === 'Disclosure' ? 'disclosure' :
                              item.componentType === 'Collector' ? 'business process' :
                              item.componentType === 'Internal email notification' ? 'email' :
                                  item.componentType,
                      componentName: item.componentName === 'Form template' ? 'form' : item.componentName,
                      idComponent: item.idComponent === 'Form template' ? 'form' : item.idComponent,
                  })
                  if (item.componentType === 'email') {
                      this.setState({
                          idEmailTemplate: item.idComponent
                      })
                  } else  if (item.componentType === 'business process') {
                      this.setState({
                          idBusinessProcess: item.idComponent
                      })
                  } else if (item.componentType === 'form') {
                      this.setState({
                          idForm: item.idComponent
                      })
                  } else if (item.componentType === 'Form template') {
                      this.setState({
                          idForm: item.idComponent
                      })
                  }
              })
              this.setState({
                  listComponentsSection: listComponents,
                  valueWorkflowName: response.data.workflowName,
                  workflowId: response.data.workflowId,
              })
          })
      })
    }
    onSelectIdForm = (id) => {
        this.setState({
            idForm: id
        })
    }
    onSelectIdEmailTemplate = (id) => {
        this.setState({
            idEmailTemplate: id
        })
    }
    onSelectIdBusinessProcess = (id) => {
        this.setState({
            idBusinessProcess: id
        })
    }
    showConfirmDeleteWorkflow = (e) => {
        const { valueSelectWorkflow } = this.state
        if (valueSelectWorkflow !== '') {
            customConfirm({
                title: <span className="icon-v14-delete"></span>,
                content: `Delete workflow ${valueSelectWorkflow}?`,
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel',
                mask: true,
                maskClosable: false,
                closeHaveLogic: false,
                className: 'asset-register-modal modal-delete-connector',
                centered: true,
                cancelClassName: 'cancel-button',
                confirmClassName: 'confirm-button',
                confirmAction: () => {
                    this.onDeleteWorkflow(e)
                }
            })
        }
    }
    onDeleteWorkflow = () => {
        const { workflowId } = this.state
        const ids = []
        ids.push(workflowId)
        apiWorkflowBuilderDelete(ids).then((response) => {
            if (response.data.title.toLowerCase() === 'success') {
                this.setState({
                    valueWorkflowName: '',
                    valueSection: '',
                    listComponentsSection: [],
                    valueSelectWorkflow: ''
                }, () => {
                    this.onGetWorkflowBuilderList()
                })
                CustomNotificationManager.success(response.data.message, 'Success')
            } else if(response.data.title.toLowerCase() === 'info') {
                this.onGetWorkflowBuilderList()
                CustomNotificationManager.info(response.data.message, 'Info')
            }
        })
    }
    onHoverDeleteButton = () => {
        this.setState({
            hoverDeleteButton:true,
        })
    }
    onLeaveDeleteButton = () => {
        this.setState({
            hoverDeleteButton:false,
        })
    }
    render() {
        const { listAddSection, valueWorkflowName, valueSection, listComponentsSection, actionProvidedDraggable, onStateProvidedDraggable, valuesAddSection,
            idEmailTemplate, listWorkflow, idBusinessProcess, valueSelectWorkflow, hoverDeleteButton } = this.state
        return (
            <div className="builder-workflow">
                <div className="builder-workflow__page">
                    <header className="header-builder-workflow">
                        <h1>Workflow builder</h1>
                    </header>
                    <div className="header-builder">
                        <Select className="select-workflow"
                                placeholder="Select workflow"
                                value={ valueSelectWorkflow ? valueSelectWorkflow : 'Select' }
                                dropdownStyle={{border:'1px solid #eda41e'}}
                                onChange={(e) => this.onSelectWorkflowBuilder(e)}>
                            { listWorkflow && listWorkflow.length > 0 && listWorkflow.map((item,index) => {
                                return  (<Option className="options-select-connector" value={ item.workflowId } key={index}>
                                    { item.workflowName }
                                </Option>)
                            })}
                        </Select>
                    <div className="action-header-builder">
                        <Button className="button-cancel-template" onClick={(e) => this.onCLickCancelBuilder(e)}>Cancel</Button>
                        { valueSelectWorkflow ?
                            <Button className="button-save-template" onClick={(e) => this.onCLickEditBuilder(e)}
                                    disabled={valueWorkflowName === '' || listComponentsSection.length === 0}>Save</Button> :
                            <Button className="button-save-template" onClick={(e) => this.onCLickSaveBuilder(e)}
                                    disabled={valueWorkflowName === '' || listComponentsSection.length === 0}>Save</Button>
                        }
                    </div>
                    </div>
                    <div className="input-header-builder">
                        <div className="content-input-header-builder">
                        <Input className="input-form-name" onChange={(e) => this.onChangeWorkflowName(e)}
                               value={ valueWorkflowName }
                               placeholder="Workflow name"/>
                            <button disabled={valueSelectWorkflow === ''}
                                    className={ hoverDeleteButton ? "icon-v19-delete-active" : "icon-v19-delete"}
                                    onClick={(e) => this.showConfirmDeleteWorkflow(e)}
                                    onMouseEnter={() => this.onHoverDeleteButton()}
                                    onMouseLeave={() => this.onLeaveDeleteButton()}
                            ></button>
                        </div>
                        <Select className="select-form"
                                onChange={(e) => this.onSelectAddSection(e)}
                                value={ valueSection ? valueSection : 'Add section' }
                                dropdownStyle={{border:'1px solid #eda41e'}}
                                placeholder="Select form">
                            { listAddSection.map((item,index) => {
                                return  (<Option className="options-select-connector" value={item.nameSection} key={index}>
                                    {item.nameSection}
                                </Option>)
                            })}
                        </Select>
                    </div>
                    <div className="page-builder-content">
                        <div className="form-builder-content">
                            { listComponentsSection && listComponentsSection.length > 0 &&
                            <DragDropContext onDragEnd={this.handleDropComponent}>
                                <Droppable droppableId="list-container">
                                    {(provided) => (
                                        <div
                                            className="list-container"
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {
                                                listComponentsSection && listComponentsSection.length > 0 && listComponentsSection.map((component, index) => {
                                                    return (
                                                        <Draggable key={component.key} draggableId={`${component.key}`} index={index}>
                                                            {(provided) => (
                                                                <div
                                                                    id={index}
                                                                    className="item-container"
                                                                    ref={provided.innerRef}
                                                                    {...provided.dragHandleProps}
                                                                    {...this.onProvidedDraggable(provided)}
                                                                >
                                                                    {
                                                                        this.onGenerateComponentsBuilder(component, this.onStateProvidedDraggable, this.showConfirmDeleteSection,
                                                                        this.onChangeBuilder, this.onSelectIdForm, this.onSelectIdEmailTemplate, this.onSelectIdBusinessProcess)
                                                                    }
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    )
                                                })
                                            }
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id
})

export default connect(mapStateToProps)(BuilderWorkflow)