import axios from 'axios'

export const apiOspreyGetSystemPropertyValue = (host, propertyName, defaultValue) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/getSystemPropertyValue`,
    data: {
      sessionID: token,
      propertyName: propertyName, 
      defaultValue: defaultValue
    }
  })
}

export const apiOspreySetSystemPropertyValue = (host, propertyName, value) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/setSystemProperty`,
    data: {
      sessionID: token,
      propertyName: propertyName, 
      value: value
    }
  })
}

export const apiOspreyTerminateProcess = (host, processID) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/terminateProcess`,
    data: {
      sessionID: token,
      processID: processID
    }
  })
}
