import React from 'react'
import PropTypes from 'prop-types'

import './DocumentItem.css'

class DocumentItem extends React.Component {

  componentDidMount = () => {
  }

  donothing = () => {

  }

  render = () => {
    const { document } = this.props
    const { fields } = this.props

    return (
      <tr className="documentListItem__docline">
        { fields.map((name, index) => {
          return <td title={document.metadata[name]} onClick={ this.props.selectDocument.bind(this, document.ID) } className="documentListItem__metadataField" id={`documentListItem__metadataField_${ name }`} key={ index }>{document.metadata[name]}</td>
        })}
        { this.props.removeDocument !== null &&
          <td className="documentListItem__remove" onClick={ this.props.removeDocument ? this.props.removeDocument.bind(this, document.ID) : this.donothing.bind(this) }><div>X</div></td>
        }
      </tr>
    )
  }
}

DocumentItem.propTypes = {
  document: PropTypes.object.isRequired,
  selectDocument: PropTypes.func.isRequired
}

export default DocumentItem