import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import '../FormEmployeeWorkflow.scss'
import {Input, Radio, Select, Steps} from "antd";
const { Option } = Select;
const { Step } = Steps;

export class SingleChoiceTemplateForm extends Component {

    state = {
        singleChoiceForm: {
            options: [1, 2, 3],
        }
    }
    onChangeNameAndDetails = (e, key) => {
        const { component } = this.props
        switch (key) {
            case 'single_choice':
                this.props.onChangeNameAndDetails(e, key, component)
                break
            default:
                break
        }
    }
    render() {
        const {singleChoiceForm} = this.state
        const { formerEmployee, component, stepTicketActive } = this.props
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        };
        return (
            <div className="full-name-former">
                <span className="header-former header-former-address">{component.header && component.mandatory ? `${component.header}*` :
                    component.header ? component.header : 'Single choice'}</span>
                <span className="input-content">
                    <Radio.Group onChange={(e) => this.onChangeNameAndDetails(e, 'single_choice')}
                                 disabled={stepTicketActive.blockFormEdit === true}
                                 value={component.singleChoice}>
                            {
                                component.options && component.options.length > 0 && component.options.map((radio, index) => {
                                    return (
                                        <Radio key={index} style={radioStyle} value={radio.optionId}>
                                           <span>
                                               <span>{ radio.optionText }</span>
                                           </span>
                                        </Radio>
                                    )
                                })
                            }
                    </Radio.Group>
                </span>
            </div>
        )
    }
}


SingleChoiceTemplateForm.propTypes = {
    folder: PropTypes.object,
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id
})
export default compose(
    connect(mapStateToProps),
)(SingleChoiceTemplateForm)