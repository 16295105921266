export const getDescription = (value) => {
  const item = findOptionForTemplateProcesses(value)
  return (item && item.description) || ''
}

const findOptionForTemplateProcesses = (valueTitle) => {
  const items = []
  Object.keys(processesTemplateItems).forEach((group) => {
    processesTemplateItems[group].forEach(({ title, description }) => items.push({
      title,
      description
    }))
  })
  return items.filter((item) => item.title === valueTitle)[0]
}

export const processesTemplateItems = {
  'Compliance': [
    {
      title: 'Subject Access Requests',
      description: 'People may submit Subject Access Requests. As part of this process we will request/retain details of the requesting party, and surface/store/process results of our search.'
    },
    {
      title: 'Breach Notifications',
      description: 'When a breach is detected in our systems, we may need to collate and process some personal details of individuals who may be affected such that we can respond appropriately.'
    }
  ],
  'HR': [
    {
      title: 'Recruitment',
      description: 'We collect CVs of individuals when they apply for roles at our company.'
    },
    {
      title: 'Employee reviews',
      description: 'We perform employee reviews which are collected and stored as part of their employment record at the company.'
    },
    {
      title: 'Internal complaints',
      description: 'We have a complaints procedure which allows employees to submit complaints against another member of staff. These are stored as part of their employment record at the company.'
    }
  ],
  'Legal': [
    {
      title: 'Litigation Early Data Assessments',
      description: 'In litigation we must search our data for responsiveness to specific criteria. This process is an early data assessment and forms the first part of a review.'
    },
    {
      title: 'Litigation Review',
      description: 'Our legal team reviews data which may be relevant to a particular case. This may include any amount of sensitive and/or personal information of individuals.'
    },
    {
      title: 'Litigation data collections',
      description: 'In preparation for litigation review we may need to collect volumes of data. This may include any amount of sensitive and/or personal information of individuals.'
    },
    {
      title: 'Litigation data production',
      description: 'Following a review process our legal team may produce a set of relevant documents for a specific case.  This may include any amount of sensitive and/or personal information of individuals.'
    }
  ],
  'Marketing': [
    {
      title: '3rd party (marketing) data purchase',
      description: 'We purchase data such as names, phone numbers and email addresses from a third party which sells this for marketing and/or lead generation purposes.'
    },
    {
      title: 'Marketing campaigns',
      description: 'We run marketing campaigns to generate leads and/or collect information on individuals for sales purposes.'
    }
  ],
  'Medical': [
    {
      title: 'Medical sign up forms',
      description: 'Clients registering for medical products or services are required to submit details of their personal and medical background.'
    },
    {
      title: 'Medical procedures or advice',
      description: 'We offer medical procedures or advice. As part of this, we create and store medical records on our clients.'
    }
  ],
  'Operations': [
    {
      title: '3rd party activities (agents)',
      description: 'We share data with independent 3rd parties such as sales or customer service agents as outsourced operations.'
    }
  ],
  'PR': [
    {
      title: 'Website data collection',
      description: 'We collect anonymous information on individuals by tracking their use of our website. This may include detailed recording of movements and keystrokes within the pages.'
    },
    {
      title: 'Website signup',
      description: 'We collect information on individuals when they sign up to our website. This could include email addresses, passwords, phone numbers, and more.'
    },
    {
      title: 'New client/prospect inquiry',
      description: 'Clients or prospects are invited to submit inquiries through our website, by phone or mail. We record the details of these individuals to serve their requests.'
    }
  ],
  'Sales': [
    {
      title: 'SaaS activity analysis',
      description: 'We analyse the use of our SaaS product by individual customers to help generate new leads.'
    },
    {
      title: 'Retail transactions',
      description: 'Our customers purchase products from us and we record these transactions. This possibly includes names, contact details, transaction details and payment information.'
    },
    {
      title: 'Product/Service subscriptions',
      description: 'Our customers subscribe to our products or services. We store their personal and/or financial information to charge them on a recurring basis.'
    }
  ],
  'Support': [
    {
      title: 'Customer queries/complaints',
      description: 'Customers may submit support tickets, complaints or general queries. We record this information for response and possibly for generalised insights.'
    }
  ]
}