import React from 'react'

const DateOptions = () => (
  <React.Fragment>
    <optgroup label="Day">
      <option value="NOW-1DAY/DAY">1 day ago</option>
      <option value="NOW-2DAY/DAY">2 days ago</option>
      <option value="NOW-3DAY/DAY">3 days ago</option>
      <option value="NOW-4DAY/DAY">4 days ago</option>
      <option value="NOW-5DAY/DAY">5 days ago</option>
      <option value="NOW-6DAY/DAY">6 days ago</option>
    </optgroup>
    <optgroup label="Week">
      <option value="NOW-1WEEK/DAY">1 week ago</option>
      <option value="NOW-2WEEK/DAY">2 weeks ago</option>
      <option value="NOW-3WEEK/DAY">3 weeks ago</option>
      <option value="NOW-4WEEK/DAY">4 weeks ago</option>
    </optgroup>
    <optgroup label="Month">
      <option value="NOW-1MONTH/DAY">1 month ago</option>
      <option value="NOW-2MONTH/DAY">2 months ago</option>
      <option value="NOW-3MONTH/DAY">3 months ago</option>
      <option value="NOW-4MONTH/DAY">4 months ago</option>
      <option value="NOW-5MONTH/DAY">5 months ago</option>
      <option value="NOW-6MONTH/DAY">6 months ago</option>
    </optgroup>
    <optgroup label="Year">
      <option value="NOW-1YEAR/DAY">1 year ago</option>
      <option value="NOW-2YEAR/DAY">2 years ago</option>
      <option value="NOW-3YEAR/DAY">3 years ago</option>
      <option value="NOW-4YEAR/DAY">4 years ago</option>
      <option value="NOW-5YEAR/DAY">5 years ago</option>
      <option value="NOW-6YEAR/DAY">6 years ago</option>
      <option value="NOW-7YEAR/DAY">7 years ago</option>
      <option value="NOW-8YEAR/DAY">8 years ago</option>
      <option value="NOW-9YEAR/DAY">9 years ago</option>
      <option value="NOW-10YEAR/DAY">10 years ago</option>
    </optgroup>
  </React.Fragment>
)

export default DateOptions