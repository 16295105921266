import React from 'react'
import { Tabs } from 'antd'
import '../FolderSettings/FolderSettings.scss'
import FolderRedaction from './FolderRedaction'
import ManualTerms from './ManualTerms'
const { TabPane } = Tabs

class FolderRedactionTab extends React.Component {
  render() {
    return (
      <div className="redaction-settings-tab">
        <Tabs type="card" className="child-tabs">
          <TabPane tab="Redacting" key="1">
            <FolderRedaction folder={ this.props.folder } fetchFolderInfo={() => this.props.fetchFolderInfo() } />
          </TabPane>
          <TabPane tab="Manual terms" key="2">
            <ManualTerms folder={ this.props.folder } />
          </TabPane>
        </Tabs>
      </div>
    )
  }
}

export default FolderRedactionTab