import { Link } from 'react-router-dom'
import React, { Component } from 'react'
import SubjectsList from './SubjectsList'
import CardList from './CardList'
import LineChart from './LineChart'
import Button from '../../Common/Button'
import { apiFetchByFilter, apiSarDeleteRequest } from '../../../api/Sar'
import '../../../style/common.scss'
import './Dashboard.scss'
import { connect } from 'react-redux'
import { Checkbox, Input, Select, Tooltip } from 'antd'
import { customConfirm } from '../../../utils/AssetRegister/CustomConfirm'
import { setSarPageLimit } from '../../../actions/sarActions'
import CustomNotificationManager from "../../Common/CustomNotificationManager";
import withRouter from '../../../private-routers/withRouter'

const { Search } = Input
const { Option } = Select

export class Dashboard extends Component {
  constructor() {
    super()
    this.state = {
      isLoading: true,
      showLearnMessage: true,
      sars: [],
      accordion: {},
      name: null,
      step: null,
      complete: null,
      time: null,
      pageNumber: 1,
      amountPerPage: 2,
      totalElements: null,
      archived: false,
      // itemsPerPageLimit: 10,
      itemsPerPageLimitOptions: [10, 20, 50, 100],
      isPrevDisabled: true,
      isNextDisabled: false,
      totalRecords: 0,
      currentPage: 1,
      showInsertPageNumber: false,
      sortCriteria: { column: 'created', order: 'desc' },
      filterCriteria: [],
      filterChanged: false,
      listKeysSar: [],
      selectSarRowTable: {},
      checkSelectAllSar: false,
      checkBoxSelectAll: false,
      onHoverDelete: false,
      onStateStart: false,
      onStateEnd:false,
    }
  }

  componentDidMount() {
    this.fetchSars()
  }

  fetchSars = async() => {
    const { isNextDisabled, sortCriteria, filterCriteria, filterChanged } = this.state
    const { sarPageLimit } = this.props
    const data = {
      'sort': sortCriteria,
      'filter': filterCriteria,
      'amountPerPage': sarPageLimit,
      'clientId': this.props.clientId,
      'pageNumber': (filterChanged && this.state.currentPage > 1) ? 0 : this.state.currentPage - 1
    }
   await apiFetchByFilter(data).then((response) => {
      this.setState({
        isLoading: false,
        sars: response.data.sars,
        isNextDisabled,
        totalRecords: response.data.totalElements,
        currentPage: (filterChanged && this.state.currentPage > 1) ? 1 : this.state.currentPage,
        inputPage: (filterChanged && this.state.currentPage > 1) ? 1 : this.state.currentPage
      }, () => { this.updatePreviewNestButtonStatus() })
    }).catch((error) => {
     if (error.response && error.response.status && error.response.status === 500) {
       this.setState({
         isNextDisabled: true
       })
     }
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  updateListAfterFilers = (newData) => {
    const { currentPage } = this.state
    const { sarPageLimit } = this.props
    this.setState({
      isLoading: false,
      sars: newData.sars,
      totalRecords: newData.totalElements,
      isNextDisabled: currentPage * sarPageLimit < newData.totalElements ? true : false,
      filterChanged: false
    }, () => { this.updatePreviewNestButtonStatus() })
  }

  setFilterAndSort = (sortCriteria, filterCriteria) => {
    const { currentPage } = this.state
    let { filterChanged } = this.state

    if (currentPage > 1) {
      filterChanged= true
    }
    this.setState({ sortCriteria, filterCriteria, filterChanged })
  }

  setFilter = (filterCriteria) => {
    const { currentPage } = this.state
    let { filterChanged } = this.state
    if (currentPage > 1) {
      filterChanged= true
    }
    this.setState({ filterCriteria, filterChanged })
  }

  updateFilterSearch(e) {
    const searchText = e.target.value
    if (searchText !== '' && searchText.length > 2) {
      const timeout = null
      clearTimeout(timeout)
      setTimeout(function() {
        this.fetchSars()
      }.bind(this), 1000)
    } else if (searchText === '') {
      this.fetchSars()
    }

    this.setState({
      name: searchText,
      pageNumber: 1
    })
  }

  updateValue = (e, field) => {
    const value = e
    if (field === 'time') {
      this.setState({
        time: Number(value),
        pageNumber: 1
      }, () => this.fetchSars())
    }

    if (field === 'step') {
      this.setState({
        step: Number(value),
        pageNumber: 1
      }, () => this.fetchSars())
    }

    if (field === 'complete') {
      this.setState({
        complete: value,
        pageNumber: 1
      }, () => this.fetchSars())
    }
  }

  changePagination = (page) => {
    this.setState({ currentPage: page }, () => this.fetchSars())
  }

  changeItemsPerPageLimit = (e) => {
    this.setState({ filterChanged: true }, () => {
      this.fetchSars()
      this.props.setSarPageLimit(e)
    })
  }

  updatePreviewNestButtonStatus = () => {
    const { totalRecords, currentPage } = this.state
    const { sarPageLimit } = this.props
    const totalPages = Math.ceil(totalRecords / sarPageLimit)
    const isPrevDisabled = currentPage > 1 ? false : true
    const isNextDisabled = currentPage < totalPages ? false : true
    this.setState({ isPrevDisabled, isNextDisabled, currentPage, filterChanged: false })
  }

  handleMovePage = (e, direction) => {
    const { isPrevDisabled, isNextDisabled, totalRecords } = this.state
    const { sarPageLimit } = this.props
    switch (direction) {
      case 'first':
        e.preventDefault()
        if (!isPrevDisabled) {
          this.gotoPage(1)
        }
        break
      case 'previous':
        e.preventDefault()
        if (!isPrevDisabled) {
          this.gotoPage(this.state.currentPage - 1 ? this.state.currentPage - 1 : 1)
        }
        break
      case 'next':
        e.preventDefault()
        if (!isNextDisabled) {
          this.gotoPage(this.state.currentPage + 1 > Math.ceil(totalRecords / sarPageLimit) ? Math.ceil(totalRecords / sarPageLimit) : this.state.currentPage + 1)
        }
        break
      case 'last':
        e.preventDefault()
        if (!isNextDisabled) {
          this.gotoPage(Math.ceil(totalRecords/sarPageLimit))
        }
        break
      default:
        e.preventDefault()
        this.setState({ showInsertPageNumber: true })
    }
  }

  gotoPage = (newPage) => {
    const { totalRecords } = this.state
    const { sarPageLimit } = this.props
    const totalPages = Math.ceil(totalRecords / sarPageLimit)
    newPage = Math.max(0, Math.min(newPage, totalPages))
    this.setState({ currentPage: newPage }, () => this.fetchSars())
  }

  handleInputPageChange = (e) => this.setState({ inputPage: e.target.value })

  handleInputPageEnter = (e) => {
    if (e.key === 'Enter') {
      this.handleInputPageBlur()
    }
  }

  handleInputPageBlur = () => {
    const { inputPage } = this.state
    const { sarPageLimit } = this.props
    if (inputPage) {
      const paginationEnd = Math.ceil(this.state.totalRecords / sarPageLimit)
      if (inputPage > 0 && inputPage <= paginationEnd) {
        this.gotoPage(inputPage)
      } else {
        this.gotoPage(1)
        this.setState({ inputPage: 1 })
      }
    }
  }
  onSelectAll = (e) => {
    const { sars } = this.state
    if (e.target.checked) {
      this.setState({
        checkSelectAllSar: true
      })
    } else {
      this.setState({
        checkSelectAllSar: false
      })
    }
    this.setState({
      listKeysSar: e.target.checked ? sars.map((sar) => sar.id) : [],
      checkBoxSelectAll: e.target.checked
    })
  }
  onSelectSarRow = (sar) => {
    this.setState({
      selectSarRowTable: sar
    })
  }
  handleDeleteRequestDashboard = (e) => {
    const { listKeysSar, selectSarRowTable } = this.state
    if ((listKeysSar && listKeysSar.length > 0) || (selectSarRowTable && selectSarRowTable.id)) {
      customConfirm({
        title: 'Do you want to delete request?',
        content: ``,
        confirmButtonText: 'DELETE',
        cancelButtonText: 'CANCEL',
        mask: true,
        maskClosable: false,
        closeHaveLogic: false,
        className: 'asset-register-modal',
        centered: true,
        cancelClassName: 'cancel-button',
        confirmClassName: 'confirm-button',
        confirmAction: () => {
          this.onClickDeleteAllSelectSar(e)
        }
      })
    }
  }

  onClickDeleteAllSelectSar(e) {
    const { listKeysSar, selectSarRowTable, sars } = this.state
    const selectDeleteSar = []
    selectDeleteSar.push(selectSarRowTable.id)
    const deleteSarDTO = {
      sarIds: listKeysSar && listKeysSar.length > 0 ? sars.map((sar) => sar.id) : selectDeleteSar && selectDeleteSar.length > 0 ? selectDeleteSar : []
    }
    apiSarDeleteRequest(deleteSarDTO).then(() => {
      this.fetchSars()
      this.setState({
        selectSarRowTable: {},
        checkBoxSelectAll: false,
        listKeysSar: [],
        checkSelectAllSar: false
      })
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  onMouseEnterOnPanel(e, status) {
    if (status === 'delete') {
      this.setState({
        onHoverDelete: true
      })
    }
  }
  onMouseLeaveOnPanel(e, status) {
    this.setState({
      onHoverDelete: false,
    })
  }
  onMouseEnterAction = (e, state) => {
    switch (state) {
      case 'start':
        this.setState({
          onStateStart: true,
        })
        break
      case 'end':
        this.setState({
          onStateEnd: true,
        })
        break
      default: break
    }
  }
  onMouseLeaveAction = () => {
    this.setState({
      onStateStart: false,
      onStateEnd: false,
    })
  }
  render()
  {
    const { sars, isLoading, itemsPerPageLimitOptions, isPrevDisabled, isNextDisabled, totalRecords,
      currentPage, inputPage, showInsertPageNumber, sortCriteria, filterCriteria, filterChanged, listKeysSar, selectSarRowTable, checkSelectAllSar,
      checkBoxSelectAll, onHoverDelete,onStateStart,onStateEnd} = this.state
    const { sarPageLimit } = this.props
    const totalPages = Math.ceil(totalRecords / sarPageLimit)
    return (
      <div className="sarDashboard-page">
        <div className="sar_statistic_card">
          <CardList />
        </div>
        <LineChart />
        <div className="sar-table-content">
          <div className="sar-table-content__header">
            <div className="per-page-wrapper">
              <div className="per-page">Show
                <Select defaultValue={ sarPageLimit } onChange={ (e) => this.changeItemsPerPageLimit(e) }>
                  { itemsPerPageLimitOptions.map((option) => {
                    return <Option key={option} value={option}>{option}</Option>
                  })}
                </Select>
              </div>
            </div>
            <div className='select-all-wrapper select-all-wrapper-sar'>
              <Checkbox
                className="select-all-sar"
                onChange={ this.onSelectAll }
                checked={ checkBoxSelectAll }
              >
                Select all
              </Checkbox>
              <span
                onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'delete') }
                onMouseLeave={ () => this.onMouseLeaveOnPanel() }
                className={onHoverDelete && ((listKeysSar && listKeysSar.length > 0) || (selectSarRowTable && selectSarRowTable.id)) ? 'icon-v12-delete-active' : listKeysSar && listKeysSar.length > 0 ? 'icon-v12-delete' : selectSarRowTable && selectSarRowTable.id ? 'icon-v12-delete' : 'icon-v12-delete disabled-delete' }
                onClick={ (e) => this.handleDeleteRequestDashboard(e)}
              />
            </div>
            <div className='pagination-wrapper'>
              <div className="custom-pagination-sb">
                <div className="button-control button-control-dashboard">
                  <Tooltip title="first" className={isPrevDisabled ? 'disabled' : ''}>
                    <span className={ isPrevDisabled ? 'icon-v14-start-1 disabled' : onStateStart ? 'icon-v14-start-active' : 'icon-v14-start-1' }
                          onClick={ (e) => this.handleMovePage(e, 'first')}
                          onMouseEnter={(e) => this.onMouseEnterAction(e, 'start')}
                          onMouseLeave={() => this.onMouseLeaveAction()}/>
                  </Tooltip>
                  <Tooltip title="previous" className={isPrevDisabled ? 'disabled' : ''}>
                    <span className={ isPrevDisabled ? 'icon-v21-back-1 disabled' : 'icon-v21-back-active'}
                          onClick={ (e) => this.handleMovePage(e, 'previous')}/>
                  </Tooltip>
                  { totalPages === 0 &&
                    <span className='button-control__text'>{1} of {1} </span>
                  }
                  { totalPages === 1 &&
                    <span className='button-control__text'>{currentPage} of {totalPages} </span>
                  }
                  { totalPages > 1 &&
                    <span className='button-control__text'>
                      <input
                        type="number"
                        className="insert-page"
                        placeholder= { currentPage }
                        value= { inputPage }
                        onChange={ (e) => this.handleInputPageChange(e) }
                        onBlur={ () => this.handleInputPageBlur() }
                        onKeyDown={ (e) => this.handleInputPageEnter(e) }
                        min="1"
                        max={totalPages}
                      />
                      <span>of {totalPages}</span>
                    </span>
                  }
                  <Tooltip title="next" className={isNextDisabled ? 'disabled' : ''}>
                    <span className={ isNextDisabled ? 'icon-v21-forth-1 disabled' : 'icon-v21-forth-active'}
                          onClick={ (e) => this.handleMovePage(e, 'next')}/>
                  </Tooltip>
                  <Tooltip title="last" className={isNextDisabled ? 'disabled' : ''}>
                    <span className={ isNextDisabled ? 'icon-v14-end-1 disabled' : onStateEnd ? 'icon-v14-end-active' : 'icon-v14-end-1'  }
                          onClick={ (e) => this.handleMovePage(e, 'last')}
                          onMouseEnter={(e) => this.onMouseEnterAction(e, 'end')}
                          onMouseLeave={() => this.onMouseLeaveAction()}/>
                  </Tooltip>
                </div>
                { showInsertPageNumber &&
                  <Search
                    className='insert-page'
                    placeholder={`Insert page number (max ${ Math.ceil(totalRecords / sarPageLimit) } )`}
                    onSearch={(value) => this.goToInputPage(value)}
                    style={{ width: 250 }}
                  />
                }
              </div>
            </div>
            <div className="button-wrapper">
              <Link to="/sar/tracker-page">
                <Button theme="newBusiness" >
                  <span className="icon-v10-add-b button-newBusiness__icon"/>
                    New request
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="styleAccordion">
          <SubjectsList
            sars={ sars }
            isLoading = { isLoading }
            itemsPerPageLimit = { sarPageLimit }
            currentPage = { currentPage }
            sortCriteria= { sortCriteria }
            filterCriteria= { filterCriteria }
            filterChanged= { filterChanged }
            updateListAfterFilers= { this.updateListAfterFilers }
            setFilterAndSort= { this.setFilterAndSort }
            setFilter= { this.setFilter }
            fetchSars= { this.fetchSars }
            onSelectSarRow={ this.onSelectSarRow }
            checkSelectAllSar={ checkSelectAllSar }
          />
        </div>
      </div>
    )
  }
}



Dashboard = withRouter(Dashboard)

const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id,
  sarPageLimit: state.sar.sarPageLimit
})

export default connect(mapStateToProps,{
  setSarPageLimit
})(Dashboard)