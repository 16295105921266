import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import Button from '../../Common/Button'
import Loading from '../../Common/Loading'

import WithFiles from '../HOC/WithFiles'

import './Content.css'

class Content extends React.Component {
  state = { page: 0 }

  showMore = () => {
    this.setState({ page: this.state.page + 1 },
      () => {
        const { name, query } = this.props.folder
        this.props.updateFolder(name, { isFetchingMoreFiles: true })
        this.props.getFilesFromFolders(this.state.page, query)
      })
  }

  render = () => {
    const { contents, folder, showModal } = this.props

    if (folder.isFetchingFiles) return (
      <div className="privacyFileContent__loading"><Loading /></div>
    )

    return (
      <div className="privacyFileContent__wrapper">
        <div id="content-list" className="privacyFileContent-list">
          { renderContentsList(contents, showModal) }
          { renderButtonShowMore(folder, this.showMore) }
        </div>
      </div>
    )
  }
}

const renderContentsList = (contents, showModal) => {
  if (contents.length === 0) return (
    <p className="privacyFileContent__noFiles">No files</p>
  )

  return contents.map((content, index) => {
    return (
      <div 
        onClick={ showModal.bind(this, content) }
        key={ index } 
        className="privacyFileContent-fileItem">
        <span className="privacyFileContent__info--modified">
          { moment(content.metadata.doc_date_modified).fromNow() }
        </span>
        <div className="privacyFileContent__fileItem--wrapper">
          <span className="privacyFileContent__info--dir">
            {content.metadata.doc_id }
          </span>
          <span className="privacyFileContent__info--view">View</span>
        </div>
      </div>
    )
  })
}

const renderButtonShowMore = (folder, showMoreClicked) => {
  if (folder.isFetchingMoreFiles) return (
    <div className="privacyFileContent__action">
      <Loading />
    </div>
  )

  if (!folder.isFetchingMoreFiles && folder.hasMoreFilesToShow) return (
    <div className="privacyFileContent__action">
      <Button onClick={ showMoreClicked }>Show more files</Button>
    </div>
  )
}

Content.propTypes = {
  contents: PropTypes.array.isRequired,
  folder: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired
}

export default WithFiles(Content)