import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import './Option.scss'

class OptionYes extends React.Component {
  showTextForPeriod(period) {
    const text = {
      'Monthly': `Your DPIA will be sent out the ${ moment().format('Do') } day of every month`,
      '3 months': `Your DPIA will be sent out the ${ moment().format('Do') } day every 3 months`,
      '6 months': `Your DPIA will be sent out the ${ moment().format('Do') } day every 6 months`,
      '12 months': `Your DPIA will be sent out the ${ moment().format('Do') } day every 12 months`,
    }
    
    return text[period]
  }

  radioClass(isSelected, isEditMode) {
    if (isEditMode && isSelected) {
      return 'newOption-periodLabelCheckedDisabled'
    } else if (!isEditMode && isSelected) {
      return 'newOption-periodLabelChecked'
    } else if (isEditMode && !isSelected) {
      return 'newOption-periodLabelDisabled'
    }

    return 'newOption-periodLabel'
  }

  radioClassTop(isSelected, isEditMode) {
    if (isEditMode && isSelected) {
      return 'newOption-radio checkedDisabled'
    } else if (!isEditMode && isSelected) {
      return 'newOption-radio checked'
    } else if (isEditMode && !isSelected) {
      return 'newOption-radio disabled'
    }

    return 'newOption-radio'
  }

  render() {
    const { isEditMode, periods, periodSelected } = this.props

    return (
      <div>
        <div className="newOption-periodGroup">
          { periods.map((period) => (
            <div className="newOption-period" key={ period }>
              <div className={ this.radioClassTop(periodSelected === period, isEditMode) }>
                <input
                  checked={ periodSelected === period }
                  disabled={ isEditMode }
                  id={ period }
                  name="period"
                  type="radio"
                  value={ period }
                  onClick={ (e) => this.props.updateValueForPeriod(e) }
                  onChange={ () => {} } />
                <label
                  className={ this.radioClass(periodSelected === period, isEditMode) }
                  htmlFor={ period }></label>
              </div>
              <span className="newOption-periodDays">{ period }</span>
            </div>
          ))
          }
        </div>

        <div className="newOption-option">
          <p className="newOption-text">{ this.showTextForPeriod(periodSelected) }</p>
          <p className="newOption-text-small">You can pause or change this at anytime.</p>
        </div>
      </div>
    )
  }
}

OptionYes.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  periodSelected: PropTypes.string.isRequired,
  periods: PropTypes.arrayOf(PropTypes.string).isRequired,
  updateValueForPeriod: PropTypes.func.isRequired
}

OptionYes.defaultProps = {
  isEditMode: false,
  periodSelected: 'Monthly'
}

export default OptionYes