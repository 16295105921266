import initialState from './initialState'
import { MANAGE_GROUPS_SHOW_LOADING, RECEIVE_MANAGE_GROUPS_LIST, UPDATE_USER_ROLE } from '../actions/actionTypes'

export default (state = initialState, action) => {
  switch (action.type) {
    case MANAGE_GROUPS_SHOW_LOADING:
      return {
        ...state,
        manageGroups: {
          ...state.manageGroups,
          loading: true
        }
      }

    case RECEIVE_MANAGE_GROUPS_LIST:
      const list = action.list.items || []
      
      return {
        ...state,
        manageGroups: {
          loading: false,
          list
        }
      }

    case UPDATE_USER_ROLE:
      const oldList = state.manageGroups.list

      // We create a new list equal to the oldList,
      // but the role being updated according to "action.data.value"
      const updatedList = oldList.map((item) => {

        // If the user on item is not the one being update, return everything without changes
        if (item.id !== action.data.user.id) {
          return item
        }

        return Object.assign(item, {
          user_metadata: {
            ...item.user_metadata,
            roles: {
              dpia: item.user_metadata.roles.dpia,
              osprey: action.data.value ? 'admin' : 'user'
            }
          }
        })
      })

      return {
        ...state,
        manageGroups: {
          ...state.manageGroups,
          list: updatedList
        }
      }
      
    default:
      return state
  }
}