import React from 'react'
import { Tooltip, Icon } from 'antd'

function EmailVariables({ variabels, isMessagePersist= false, isNameLinkPersist, isResultUrlPersist= false }) {
  // React.useEffect(() => {
  // }, [isNameLinkPersist, isMessagePersist, isMessagePersist, isResultUrlPersist ])
  return (
    <fieldset className="email-legend col-md-2">
      <legend>Email Variables</legend>
      <ul>
        { variabels.length > 0 && variabels.includes('name') &&
          <li>{isNameLinkPersist ?
            <i className="fa fa-check" style={{ color: 'green' }}/> :
            <i className="fa fa-times" style={{ color: 'red' }}/>}&#36;{'{name}'}
          <Tooltip placement="bottom" title={<span>This is the subject's forename and is mostly used in the first
              line of the email. However, if you need to insert it somewhere else in the email you can, just
              type ${'{name}'} wherever you want it to appear.</span>}>
            <Icon className="icon-info-tooltip" type="info-circle" style={{ fontSize: '16px', color: '#08c' }}/>
          </Tooltip>
          </li>
        }
        {variabels.length > 0 && variabels.includes('message') &&
          <li>{ isMessagePersist ? <i className="fa fa-check" style={{ color: 'green' }}/> :
            <i className="fa fa-times" style={{ color: 'red' }}/>}&#36;{'{message}'}
          <Tooltip placement="bottom"
            title={<span>This is the message from the admin, telling the subject why the ID
              he sent was rejected. Put the variable ${'{message}'} wherever you want this text
              to be in the email.</span>}>
            <Icon className="icon-info-tooltip" type="info-circle" style={{ fontSize: '16px', color: '#08c' }}/>
          </Tooltip>
          </li>
        }
        {variabels.length > 0 && variabels.includes('details') &&
        <li>{ isNameLinkPersist ?
          <i className="fa fa-check" style={ { color: 'green' } }/> :
          <i className="fa fa-times" style={ { color: 'red' } }/> }&#36;{ '{details}' }
        <Tooltip placement="bottom" title={<span>These are the notes that the admin wrote, and contain the details
            that are exactly needed for the ID Check. Add the variable ${'{details}'} where you want
            this to appear in the email. </span>}>
          <Icon className="icon-info-tooltip" type="info-circle" style={{ fontSize: '16px', color: '#08c' }} />
        </Tooltip>
        </li>
        }
        {variabels.length > 0 && variabels.includes('resultUrl') &&
          <li>{ isResultUrlPersist ? <i className="fa fa-check" style={{ color: 'green' }}/> :
            <i className="fa fa-times" style={{ color: 'red' }}/>}&#36;{'{resultUrl}'}
          <Tooltip placement="bottom"
            title={<span>This is the link to be sent in the email. The placeholder of the
              link should be coloured blue and underlined. You can edit this text if it is there already.
              If not, you can write the placeholder in plain text, then select it and press the Link icon in the
              toolbar; that's when a small textbox will show up, and that is where you should write the variable
              ${'{resultUrl}'}. Now you've got a link to resultUrl, with the text what you wrote as placeholder.</span>}>
            <Icon className="icon-info-tooltip" type="info-circle" style={{ fontSize: '16px', color: '#08c' }}/>
          </Tooltip>
          </li>
        }
        { variabels.length > 0 && variabels.includes('companyName') &&
          <li>
            <i className="fa fa-check" style={ { color: 'green' } }/>
            &#36;{ '{companyName}' }
            <Tooltip placement="bottom" title={ <span>This is the company name and is mostly used in the last
                line of the email. However, if you need to insert it somewhere else in the email you can, just
                type ${'{companyName}'} wherever you want it to appear.</span>}>
              <Icon className="icon-info-tooltip" type="info-circle" style={{ fontSize: '16px', color: '#08c' }} />
            </Tooltip>
          </li>
        }
      </ul>
    </fieldset>
  )
}

export default EmailVariables