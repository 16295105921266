import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import '../DisclosureWorkflow.scss'
import {Select, Steps, Checkbox, Radio, Icon, Button} from "antd";
import CustomNotificationManager from "../../../../../Common/CustomNotificationManager";
const { Option } = Select;
const { Step } = Steps;
import moment from "moment";
import ReactQuill from "react-quill";
import {
    apiGetDisclosureEmailTextSecondStep,
    apiGetDisclosureEmailTrackingSteps,
    apiSendEmailDisclosureWorkflowTicket
} from "../../../../../../api/SmartBox";
export class SendDisclosure extends Component {
    modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            [{ align: [] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
                { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image']
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        }
    }
    state = {
        emailTemplate: {
            content: ''
        },
        listTrackingProgress: [
            {key: 0, emailStepName: 'Email Sent', emailStepDate: ['dd.mm.yyyy'], status: 'wait'},
            {key: 1, emailStepName: 'Email link visited', emailStepDate: ['dd.mm.yyyy'], status: 'wait'},
            {key: 2, emailStepName: 'Subject ended request', emailStepDate: ['dd.mm.yyyy'], status: 'wait'},
        ],
        loadingButtonSend: false
    }
    componentDidMount() {
      this.onGetDisclosureEmailTextSecondStep()
      this.onGetDisclosureEmailTrackingSteps()
    }

    onGetDisclosureEmailTextSecondStep = () => {
        const { selectEditTickets } = this.props
        const ticketId = selectEditTickets.ticketId
        apiGetDisclosureEmailTextSecondStep(ticketId).then((response) => {
            if (response.data && response.data.textEmail) {
                this.setState({
                    emailTemplate: { ...this.state.emailTemplate, content: response.data.textEmail ? response.data.textEmail.replaceAll('#{', '${') : response.data.textEmail }
                })
            }
        })
    }

    onGetDisclosureEmailTrackingSteps = () => {
        const { listTrackingProgress } = this.state
        const { selectEditTickets } = this.props
        const ticketId = selectEditTickets.ticketId
        let listStepsDate = []
        apiGetDisclosureEmailTrackingSteps(ticketId).then((response) => {
            const emailSentDate = []
            const emailLinkVisited = []
            const subjectEnded = []
            this.props.onGetDisclosureEmailTrackingSteps(response.data)
            if (response.data && response.data.length > 0) {
                response.data.filter((item) => {
                    if (item.emailStepName && item.emailStepName.toLowerCase() === 'email sent') {
                        //const emailSentDate = []
                        if (item.emailStepDate) {
                            emailSentDate.push(item.emailStepDate)
                        }
                        listStepsDate.push({
                            emailStepName: item.emailStepName,
                            emailStepDate: emailSentDate,
                            status: emailSentDate && emailSentDate.length > 0 ? 'process' : 'wait',
                            key: 0
                        })
                        listStepsDate = listStepsDate && listStepsDate.length > 0 && listStepsDate.filter((a, i) => listStepsDate.findIndex((s) => a.emailStepName === s.emailStepName) === i)
                    }
                    if (item.emailStepName && item.emailStepName.toLowerCase() === 'email link visited') {
                        //const emailSentDate = []
                        if (item.emailStepDate) {
                            emailLinkVisited.push(item.emailStepDate)
                        }
                        listStepsDate.push({
                            emailStepName: item.emailStepName,
                            emailStepDate: emailLinkVisited,
                            status: emailLinkVisited && emailLinkVisited.length > 0 ? 'process' : 'wait',
                            key: 0
                        })
                        listStepsDate = listStepsDate && listStepsDate.length > 0 && listStepsDate.filter((a, i) => listStepsDate.findIndex((s) => a.emailStepName === s.emailStepName) === i)
                    }

                    if (item.emailStepName && item.emailStepName.toLowerCase() === 'subject ended request') {
                        //const emailSentDate = []
                        if (item.emailStepDate) {
                            subjectEnded.push(item.emailStepDate)
                        }
                        listStepsDate.push({
                            emailStepName: item.emailStepName,
                            emailStepDate: subjectEnded,
                            status: subjectEnded && subjectEnded.length > 0 ? 'process' : 'wait',
                            key: 0
                        })
                        listStepsDate = listStepsDate && listStepsDate.length > 0 && listStepsDate.filter((a, i) => listStepsDate.findIndex((s) => a.emailStepName === s.emailStepName) === i)
                    }
                })
                const listStepsDateFinally = []
                listTrackingProgress && listTrackingProgress.length > 0 && listTrackingProgress.forEach((item) => {
                    listStepsDate && listStepsDate.length > 0 && listStepsDate.forEach((item2) => {
                          if (item.emailStepName === item2.emailStepName && item2.emailStepDate && item2.emailStepDate.length > 0) {
                              item.emailStepDate = item2.emailStepDate
                              item.status = item2.status
                          }
                    })
                })
                this.setState({
                    listTrackingProgress
                })
            }
        })
    }

    handleSubmit = (e) => {
        this.setState({
            emailTemplate: {
                ...this.state.emailTemplate,
                clientId: this.props.clientId
            }
        })
    }
    handleEditorChange = (e) => {
        let validateName = []
        let validateCompany = []
        let validateUrl = []
        this.setState({
            editMode: true,
            valid: this.validateBody(e),
        })
    }
    validateBody(data) {
        this.checkNameVariable(data)
        this.onExtractVariables(data)
        return /(?=.*\${name})/.test(data)
    }
    checkNameVariable(data) {
        if (data && data.includes('{name}')) {
            this.setState({
                isNameLinkPersist: true
            })
        } else {
            this.setState({
                isNameLinkPersist: true
            })
        }
    }
    onExtractVariables = (data) => {
        const { emailTemplate } = this.state
        let delWords = [];
        let variables = [];
        let variables2 = [];
        let variables3 = [];
        for(let i = 0; i < data.length; i++) {
            if (data[i - 1] == '$' && data[i] == '{') {
                delWords.push(i)
            } else if ((data[i] == '}') && (delWords.length > 0)) {
                let position = delWords[delWords.length - 1]
                delWords.pop()
                let len = i - 1 - position
                let ansMatch
                if (position > 0 && len > 0) {
                    ansMatch = data.substring(position + 1, len + position + 1)
                }
                if (ansMatch) {
                    if (emailTemplate.variablesTemplate && emailTemplate.variablesTemplate.length > 0) {
                        emailTemplate.variablesTemplate.forEach((item) => {
                            if ((item.name === ansMatch) || (item.variableEmail === `\${${ansMatch}}`)) {
                                variables2.push({
                                    name: ansMatch,
                                    nameVariables: `\${${ansMatch}}`,
                                    content: item.variableValue,
                                    variableValue: item.variableValue,
                                })
                            } else if ((item.name !== ansMatch) || (item.variableEmail !== `\${${ansMatch}}`)) {
                                variables3.push({
                                    name: ansMatch,
                                    nameVariables: `\${${ansMatch}}`,
                                    content: ``,
                                    variableValue: ``,
                                })
                            }
                        })
                    } else {
                        variables2.push({
                            name: ansMatch,
                            nameVariables: `\${${ansMatch}}`,
                            content: ``,
                            variableValue: ``,
                        })
                    }
                }
            }
        }
        variables = variables2.concat(variables3)
        let uniqueVariables = []
        if (variables && variables.length > 0) {
            uniqueVariables = variables.filter((a, i) => variables.findIndex((s) => a.name && s.name && a.name.toLowerCase() === s.name.toLowerCase()) === i)
        } else if (emailTemplate.variables && emailTemplate.variables.length > 0) {
            uniqueVariables = emailTemplate.variables
        }
        if ( emailTemplate.variables && emailTemplate.variables.length > 0) {
            emailTemplate.variables.forEach((item) => {
                uniqueVariables && uniqueVariables.length > 0 && uniqueVariables.forEach((item2) => {
                    if (item.variableEmail === item2.nameVariables) {
                        item2.variableValue = item.variableValue
                    }
                })
            })
        } else {
            emailTemplate.variablesTemplate && emailTemplate.variablesTemplate.length > 0 && emailTemplate.variablesTemplate.forEach((item) => {
                uniqueVariables && uniqueVariables.length > 0 && uniqueVariables.forEach((item2) => {
                    if (item.variableEmail === item2.nameVariables) {
                        item2.variableValue = item.variableValue
                    }
                })
            })
        }
        this.setState({
            emailTemplate: {...this.state.emailTemplate, variablesTemplate: uniqueVariables}
        })
    }
    onResetEmail = () => {
        this.setState({
            emailTemplate: {
                content: ''
            }
        })
    }
    onSendEmailDisclosure = () => {
        const { selectEditTickets, selectHeaderTickets } = this.props
        const sendDisclosureEmailWorkflowTicketRequestDTO = {
            ticketId: selectHeaderTickets.ticketId ? selectHeaderTickets.ticketId : selectEditTickets.ticketId ? selectEditTickets.ticketId : null
        }
        this.setState({
            loadingButtonSend: true
        }, () => {
            apiSendEmailDisclosureWorkflowTicket(sendDisclosureEmailWorkflowTicketRequestDTO).then((response) => {
                if (response.status === 200) {
                    if (response.data.title && response.data.title.toLowerCase() === 'success') {
                        CustomNotificationManager.success(response.data.message, 'Success')
                    } else if (response.data.title && response.data.title.toLowerCase() === 'info') {
                        CustomNotificationManager.info(response.data.message, 'Info')
                    }
                    this.onGetDisclosureEmailTrackingSteps()
                    this.props.onGetDisclosureStepsDetails()
                }
                this.setState({
                    loadingButtonSend: false
                })
            })
        })
    }
    render() {
        const { emailTemplate, listTrackingProgress, loadingButtonSend } = this.state
        const { selectEditTickets } = this.props

        return (
            <div className="send-disclosure">
              <div className="email-disclosure-content">
                 <div className="email-disclosure-header">
                     <span className="email-subject">Email subject:</span>
                     <span className="sar-disclosure">SAR disclosure</span>
                 </div>
                  <div className="email-subject-content">
                      <form className="form-content" onSubmit={ this.handleSubmit }>
                          { emailTemplate &&
                          <ReactQuill
                              theme={this.state.theme}
                              className="quill-write"
                              onChange={(value) => {
                                  this.setState({
                                      emailTemplate: {
                                          ...this.state.emailTemplate,
                                          content: value
                                      }
                                  }, () => this.handleEditorChange(value))
                              }
                              }
                              value={emailTemplate.content}
                              modules={this.modules}
                          />
                          }
                      </form>
                  </div>
                  <div className="email-buttons">
                    <Button className="reset-button" onClick={(e) => this.onResetEmail(e)}>Reset</Button>
                    <Button className="send-button" onClick={(e) => this.onSendEmailDisclosure(e)}
                    loading={loadingButtonSend}>Send</Button>
                  </div>
              </div>
                <div className="tracking-progress-content">
                   <div className="tracking-progress-header">
                      <span className="tracking-name">Tracking progress</span>
                   </div>
                    <div className="tracking-progress-steps">
                        <Steps className="progress-step" progressDot current={0} direction="vertical">
                            { listTrackingProgress && listTrackingProgress.length > 0 && listTrackingProgress.map((time) => {
                                return (
                                    <Step status={time.status} title={time.emailStepName} description={
                                        time.emailStepDate && time.emailStepDate.length > 0 && time.emailStepDate.map((date) => {
                                            return (
                                                <span>{date}</span>
                                            )
                                        })
                                    } />
                                )
                            })

                            }
                        </Steps>
                        {/*<Steps className="progress-step" progressDot current={0} direction="vertical">*/}
                        {/*    <Step status='wait' title="Email sent" description="dd.mm.yyyy" />*/}
                        {/*    <Step title="Email link visited" description="dd.mm.yyyy" />*/}
                        {/*    <Step title="Subject ended request" description="dd.mm.yyyy" />*/}
                        {/*</Steps>*/}
                    </div>
                </div>
            </div>
        )
    }
}


SendDisclosure.propTypes = {
    folder: PropTypes.object,
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id
})
export default compose(
    connect(mapStateToProps),
)(SendDisclosure)