import React from 'react'
import PropTypes from 'prop-types'
import {Button, Icon, Modal, Radio, Select, Tooltip, Checkbox} from 'antd'
import './DownloadBoxModalOptionFiles.scss'
import imgFolderParent from '../../../assets/images/Box negative2 80x80.png'
import CustomNotificationManager from '../../Common/CustomNotificationManager'
import {
  apiCheckExistingAuditExport,
  apiCheckExistingZipExport, apiGetTagDocumentClient,
  apiSmartBoxZipBoxFolder,
  apiSmartCheckBoxZipBoxFolder,
  downloadZipBox
} from '../../../api/SmartBox'
import FileDownload from 'js-file-download'
import ProgressCollectionData from './ProgressCollectionData'
//import {downloadFileExternFeedbackData} from "../../../api/Sar";
import {connect} from "react-redux";
const { Option } = Select
class DownloadBoxModalOptionFiles extends React.Component {

  constructor(props) {
    super(props)
    this.state= {
      valueStatusFiles: '',
      formatOptionType: '',
      valueTypeFiles: '',
      valueFormatFiles: '',
      displayContentProgressDownload: false,
      statusCollectionData: '',
      typeValueFile: '',
      setIntervalDownload: 0,
      checkResponseApiDownload: null,
      responseApiDownload: '',
      disabledCancelModalOnDownload: true,
      percentCollectData: 0,
      statusCollectData: '',
      visibleCollectData: true,
      checkExistingZip: false,
      checkAudit: false,
      loadingCollectData: false,
      customerStatus: [],
     // customerStatusRawEmails: ['EML', 'MSG', 'PST', 'MBOX', 'OLM'],
      customerStatusRawEmails: ['PST', 'MBOX', 'EML', 'MSG'],
      removeFolderStructure: false,
      renameFilesAndFolders: false,
      encodedId: null
    }
  }
  handleCancel = (e) => {
    const { setIntervalDownload, disabledCancelModalOnDownload } = this.state
    if (disabledCancelModalOnDownload === true) {
      this.props.onCancelModalDownload(false)
      clearInterval(setIntervalDownload)
      this.setState({
        responseApiDownload: 'finished',
        setIntervalDownload: 10
      })
    }
  }
  componentWillUnmount() {
    const { setIntervalDownload } = this.state
    if(setIntervalDownload > 0) {
      clearInterval(setIntervalDownload)
    }
  }
  componentDidMount() {
    //this.onCheckExistingZipExport()
    this.onCheckAuditExport()
    this.getTagDocumentClientExport()
  }

  getTagDocumentClientExport = () => {
      apiGetTagDocumentClient(this.props.clientId).then((response) => {
        this.setState({
          customerStatus: response.data
        })
      })
  }
  onCheckAuditExport = () => {
    const { file } = this.props
    apiCheckExistingAuditExport(file.id).then((response) => {
      this.setState({
        checkAudit: response.data
      })
    })
  }
  onCheckExistingZipExport = () => {
    const { file } = this.props
    apiCheckExistingZipExport(file.id, this.props.clientId, this.props.user.id).then((response) => {
      if (response.status === 200) {
          if (response.data) {
            this.setState({
              checkExistingZip: true
            })
          } else {
            this.setState({
              checkExistingZip: false
            })
          }
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  onChangeStatusFile(e) {
    this.setState({
      valueStatusFiles: e,
    })
  }
  onChangeFormatOptionType(e) {
    this.setState({
      formatOptionType: e,
    })
  }
  onChangeTypeFiles(e) {
    if (e.target.value === 'all files') {
      this.setState({
        valueStatusFiles: ''
      })
    }
    this.setState({
      valueTypeFiles: e.target.value
    })
  }
  onChangeFormatFiles(e) {
    this.setState({
      valueFormatFiles: e.target.value
    })
  }
  onChangeUploadOptions = (checkedValues) => {
    if (checkedValues.includes('removeFolderStructure')) {
      this.setState({
        removeFolderStructure: true,
        renameFilesAndFolders: false,
      })
    }
    if (checkedValues.includes('renameFilesAndFolders')) {
      this.setState({
        renameFilesAndFolders: true,
        removeFolderStructure: false,
      })
    }
    if (checkedValues.includes('renameFilesAndFolders') && checkedValues.includes('removeFolderStructure')) {
      this.setState({
        renameFilesAndFolders: true,
        removeFolderStructure: true,
      })
    }
    if (checkedValues.length === 0) {
      this.setState({
        removeFolderStructure: false,
        renameFilesAndFolders: false,
      })
    }
  }
  onChangeFileTypeSelected(e) {
    const { valueFormatFiles } = this.state
    this.setState({
      typeValueFile: e.target.value
    })
    if (e.target.value === 'redacted files' && (valueFormatFiles === '' || valueFormatFiles === 'raw files' || valueFormatFiles === 'raw email files')) {
      this.setState({
        valueFormatFiles: ''
      })
    }
  }
  onDownloadZipBoxFolder = () => {
    const { valueStatusFiles, valueTypeFiles, valueFormatFiles, typeValueFile, formatOptionType } = this.state
    const { file } = this.props
    const FolderDownloadDTO = {
      folderId: file.id,
      folderName: file.name,
      fileOption: valueTypeFiles,
      formatOption: valueFormatFiles,
      fileTag: valueStatusFiles,
      formatOptionType : formatOptionType,
      type: typeValueFile,
      allValues: `${file.id}, ${file.name}, ${valueTypeFiles}, ${valueStatusFiles}, ${typeValueFile}, ${formatOptionType}`
    }
    apiSmartBoxZipBoxFolder(FolderDownloadDTO).then((response) => {
      FileDownload(response.data, `${ file.name }_${ valueTypeFiles }.zip`)
      if (response.status === 200) {
        this.props.onCloseCollectData(false)
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  checkDownloadBox = (e) => {
    e.preventDefault()
    const { valueStatusFiles, valueTypeFiles, valueFormatFiles, typeValueFile, setIntervalDownload, removeFolderStructure, renameFilesAndFolders, encodedId,
      formatOptionType } = this.state
    const { file } = this.props
    const FolderDownloadDTO = {
      folderId: file.id,
      folderName: file.name,
      fileOption: valueTypeFiles,
      formatOption: valueFormatFiles,
      fileTag: valueStatusFiles,
      formatOptionType : formatOptionType,
      type: typeValueFile,
      removeFolderStructure: removeFolderStructure,
      renameFilesAndFolders: renameFilesAndFolders,
      encodedId: encodedId
      //allValues: `${file.id}, ${file.name}, ${valueTypeFiles}, ${valueStatusFiles}, ${typeValueFile}, ${valueFormatFiles}, ${removeFolderStructure}, ${renameFilesAndFolders}`,
    }
    apiSmartCheckBoxZipBoxFolder(FolderDownloadDTO).then((response) => {
      this.setState({
        percentCollectData: response.data.percent,
        statusCollectData: response.data.status,
        encodedId: response.data.encodedId,
      })
      if (response.data.status === 'processing') {
        this.setState({
          responseApiDownload: 'processing',
          disabledCancelModalOnDownload: false
        })
        //CustomNotificationManager.info('Finalised folder is being downloaded as a zip. Please wait...', 'Info')
        if (response.data.status === 'processing') {
          this.setIntervalCheckApiDownload(response.data)
          this.setState({ setIntervalDownload })
        }
      } else if (response.data.status === 'finished') {
        //this.onDownloadZipBoxFolder()
        if (response.data.messageTextResponseDTO && Object.keys(response.data.messageTextResponseDTO).length > 0 && response.data.messageTextResponseDTO.message) {
          // CustomNotificationManager.info(response.data.messageTextResponseDTO.message, 'Info')
          // const messageInfo = <span>Exported path and filenames exceed 256 characters and Windows default unpacker may not be able to open it.<span className="more-info-link-notification" onClick={ () => window.open('https://support.smartbox.ai/support/solutions/articles/47001222170',"_blank" )}>More info.</span></span>
          // CustomNotificationManager.info(messageInfo, 'Info')
        }
        this.setState({
          responseApiDownload: 'finished',
          disabledCancelModalOnDownload: true
        })
        clearInterval(setIntervalDownload)
        this.setState({ setIntervalDownload })
      }
      this.setState({
        loadingCollectData: false
      })
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  setIntervalCheckApiDownload = (response) => {
    const { responseApiDownload } = this.state
    let { setIntervalDownload } = this.state
    if (responseApiDownload === 'processing' || response.status === 'processing') {
      setIntervalDownload = setInterval(this.callBackCheckApiDownload, 4000)
    } else if (responseApiDownload === 'finished' || response.status === 'finished') {
      clearInterval(setIntervalDownload)
    }
  }
  callBackCheckApiDownload = () => {
    const { valueStatusFiles, valueTypeFiles, valueFormatFiles, typeValueFile, responseApiDownload, removeFolderStructure, renameFilesAndFolders, encodedId,
      formatOptionType } = this.state
    const { file } = this.props
    let { setIntervalDownload } = this.state
    const FolderDownloadDTO = {
      folderId: file.id,
      folderName: file.name,
      fileOption: valueTypeFiles,
      formatOption: valueFormatFiles,
      fileTag: valueStatusFiles,
      formatOptionType: formatOptionType,
      type: typeValueFile,
      removeFolderStructure: removeFolderStructure,
      renameFilesAndFolders: renameFilesAndFolders,
      encodedId: encodedId,
      //allValues: `${file.id}, ${file.name}, ${valueTypeFiles}, ${valueStatusFiles}, ${typeValueFile}, ${valueFormatFiles}, ${removeFolderStructure}, ${renameFilesAndFolders}`
    }
    if (responseApiDownload === 'processing') {
      apiSmartCheckBoxZipBoxFolder(FolderDownloadDTO).then((response) => {
        this.setState({
          percentCollectData: response.data.percent,
          statusCollectData: response.data.status,
          encodedId: response.data.encodedId,
        })
        if (response.data.status === 'processing') {
          clearInterval(setIntervalDownload)
          this.setState({setIntervalDownload: 0})
          this.setState({
            responseApiDownload: 'processing',
            disabledCancelModalOnDownload: false
          })
        } else if (response.data.status === 'finished') {
          //this.onDownloadZipBoxFolder()
          if (response.data.messageTextResponseDTO && Object.keys(response.data.messageTextResponseDTO).length > 0 && response.data.messageTextResponseDTO.message) {
            // const messageInfo = <span>Exported path and filenames exceed 256 characters and Windows default unpacker may not be able to open it.<span className="more-info-link-notification" onClick={ () => window.open('https://support.smartbox.ai/support/solutions/articles/47001222170',"_blank" )}>More info.</span></span>
            // CustomNotificationManager.info(messageInfo, 'Info')
            // CustomNotificationManager.info(response.data.messageTextResponseDTO.message, 'Info')
          }
          clearInterval(setIntervalDownload)
          this.setState({setIntervalDownload: 10})
          this.setState({
            responseApiDownload: 'finished',
            disabledCancelModalOnDownload: true
          })
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    } else if (responseApiDownload === 'finished') {
      clearInterval(setIntervalDownload)
      this.setState({setIntervalDownload: 10, disabledCancelModalOnDownload: true})
    }
  }
  onVisibleCollectionData = (e) => {
    this.setState({
      loadingCollectData: true
    }, () => {
      this.setState({
        displayContentProgressDownload: true
      })
      this.checkDownloadBox(e)
    })
  }
  onStatusCollectionData = (status) => {
    this.setState({
      statusCollectionData: status
    })
  }
 secureDownloadLink = async() => {
   const { valueStatusFiles, valueTypeFiles, valueFormatFiles, typeValueFile, removeFolderStructure, renameFilesAndFolders, formatOptionType } = this.state
   const { file } = this.props
   const isLocalhost = () => {
     return window.location.hostname === 'localhost'
   }
   const host = isLocalhost() ? 'http://localhost:8080' : ''
   const idToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId

   const allValues = `${file.id}, ${file.name}, ${valueTypeFiles}, ${valueStatusFiles}, ${typeValueFile}, ${valueFormatFiles}, ${removeFolderStructure}, ${renameFilesAndFolders}, ${formatOptionType}`
   const boxName = file ? file.name : ''
   const boxId = file ? file.id : ''
    const apiUrl = `${ host }/osprey-0.1.0/v1/smart-box/zip/download-folder/${allValues}/${boxId}/${this.props.clientId}/${this.props.user.id}/${boxName}`
   const response = await fetch(apiUrl, {
     headers: {
       method: 'get',
       headers: {
         'Authorization': `Bearer ${ idToken }`,
         'Content-type': 'application/json'
       },
     },
   })
   const blob = await response.blob()
   const url = window.URL.createObjectURL(blob)
   const a = document.createElement('a')
   a.href = url
   a.download = 'secure-file.zip'
   document.body.appendChild(a)
   a.click()
   a.remove()
 }
  render() {
    const { valueStatusFiles, valueTypeFiles, valueFormatFiles, displayContentProgressDownload, typeValueFile, disabledCancelModalOnDownload,
      percentCollectData, statusCollectData, checkExistingZip, checkAudit, loadingCollectData, customerStatus, removeFolderStructure, renameFilesAndFolders,
      encodedId, customerStatusRawEmails, formatOptionType  } = this.state
    const { file } = this.props
    const { client } = this.props

    const allValues = `${file.id}, ${file.name}, ${valueTypeFiles}, ${valueStatusFiles}, ${typeValueFile}, ${valueFormatFiles}, ${removeFolderStructure}, ${renameFilesAndFolders}`
    const boxName = file ? file.name : ''
    const boxId = file ? file.id : ''
    return (
      <Modal
        title={ <div className="content-header-modal-collect"><img src={imgFolderParent} alt={imgFolderParent}/><span className="title-header">{ file.name}</span></div> }
        visible={ true }
        onCancel={() => this.handleCancel()}
        className={ disabledCancelModalOnDownload === false ? 'import-modal-option-box content-update-download disabled-close' : !displayContentProgressDownload ? 'import-modal-option-box' : 'import-modal-option-box import-modal-option-box-finish' }
        footer={ null }
      >
        <React.Fragment>
          { !displayContentProgressDownload ?
            <div className='access-modal-download'>
              <div className="header-access-modal">
                <div className="content-download">
                <span className="title-download">Download</span>
                <Tooltip overlayClassName="help-message-locations" title={<div className="content-help-message-locations">
                  <span className="text-information">Select files and formats for download.</span>
                  <span className="more-info-link" onClick={ () => window.open('https://support.smartbox.ai/support/solutions/articles/47001175261-downloading-data', '_blank') }> More info. </span>
                </div>}>
                  <span className="icon-v14-question"></span>
                </Tooltip>
                </div>
                {/*<div className="export-preview-setting">*/}
                {/*  { checkExistingZip ?*/}
                {/*    <a href={downloadZipBox(allValues, boxId, this.props.clientId, this.props.user.id, boxName)}*/}
                {/*       className='action-collect-data action-collect-data-href'*/}
                {/*    >Export Box with previous settings!</a> :*/}
                {/*      <a href={() => false}*/}
                {/*         className='action-collect-data-disabled action-collect-data-href-disabled'*/}
                {/*      >Export Box with previous settings!</a>*/}
                {/*  }*/}
                {/*</div>*/}
                {/*<span className="title-choose">Choose how and what files you would like to download</span>*/}
              </div>
              <div className="access-modal-download__content-modal">
                <div className="content-modal-option-file">
                  <div className="content-files-option">
                    <div className="title-option">Files</div>
                    <div className="content-functionality-files-option">
                      <div className="row">
                        <div className="col-md-3">
                          <Radio.Group onChange={ (e) => this.onChangeTypeFiles(e) }>
                            <Radio className="text-option" value="all files">All</Radio>
                            <Radio className="text-option" value="only files tag">Only with tag
                            </Radio>
                          </Radio.Group>
                        </div>
                      </div>
                    </div>
                    <div className="content-option-tag">
                      <Select placeholder="Select tag"
                        onChange={ (e) => this.onChangeStatusFile(e) }
                        disabled={ valueTypeFiles === '' || valueTypeFiles === 'original files' || valueTypeFiles === 'redacted files' || valueTypeFiles === 'all files' }
                              value={ valueStatusFiles === '' ? 'Select tag' : valueStatusFiles }
                      >
                        {
                          customerStatus.map((item, index) => {
                            return (
                                <Option className="item-options-status-new" key={index} value={item.tagName}>
                                  { item.icon && item.icon.includes('v211') ?
                                      <span style={{backgroundColor: item.color ? item.color : 'icon-color'}}
                                            className={item.icon ? item.icon : 'icon-color'}></span> :
                                      item.color ?
                                          <span style={{backgroundColor: item.color ? item.color : 'icon-color'}}
                                                className={item.icon ? item.icon : 'icon-color'}></span> :
                                          <span><Icon type={item.icon} /></span>
                                  }
                                  {item.tagName}
                                </Option>
                            )
                          })
                        }
                        {/*<Option className="options-status-collection" key={ 1 } value="redacted">*/}
                        {/*  For Review*/}
                        {/*</Option>*/}
                        {/*<Option className="options-status-collection" key={ 2 } value="approved">*/}
                        {/*  Approved*/}
                        {/*</Option>*/}
                        {/*<Option className="options-status-collection" key={ 3 } value="sent">*/}
                        {/*  Sent*/}
                        {/*</Option>*/}
                      </Select>
                    </div>
                  </div>
                  <div className="content-files-option content-files-types">
                    <div className="title-option">Types</div>
                    <div className="content-functionality-files-option option-types">
                      <div className="row">
                        <div className="col-md-3">
                          <Radio.Group onChange={ (e) => this.onChangeFileTypeSelected(e) }>
                            <Radio className="text-option-redact" value="original files">Original</Radio>
                            <Radio className="text-option-redact" value="redacted files">Redacted</Radio>
                            <Radio className="text-option-redact" disabled={ checkAudit === false } value="audit files">Audit</Radio>
                          </Radio.Group>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="content-format-option content-format-files">
                    <div className="title-option">Format</div>
                    <div className="content-functionality-files-option">
                      <div className="row">
                        <div className="col-md-3">
                          <Radio.Group value={ valueFormatFiles } onChange={ (e) => this.onChangeFormatFiles(e) }>
                            <Radio className="text-option-file" value="keep files">Multiple PDFs</Radio>
                            <Radio className="text-option-file" value="combine files">Combine into one PDF</Radio>
                            <Radio className="text-option-file" disabled={ typeValueFile === 'redacted files' || typeValueFile === 'audit files'} value="raw email files">Raw files</Radio>
                            {/*<Radio className="text-option-file" disabled={ typeValueFile === 'redacted files' || typeValueFile === 'audit files'} value="raw files">Raw files</Radio>*/}
                            {/*<Radio className="text-option-file" disabled={ typeValueFile === 'redacted files' || typeValueFile === 'audit files'} value="raw emails">Raw emails</Radio>*/}
                          </Radio.Group>
                        </div>
                      </div>
                    </div>
                    {/*<div className="content-option-tag">*/}
                    {/*  <Select placeholder="Select option type"*/}
                    {/*          onChange={ (e) => this.onChangeFormatOptionType(e) }*/}
                    {/*          disabled={ valueFormatFiles  === '' || valueFormatFiles !== 'raw emails' }*/}
                    {/*          value={ formatOptionType === '' ? 'Select option type' : formatOptionType }*/}
                    {/*  >*/}
                    {/*    {*/}
                    {/*      customerStatusRawEmails.map((item, index) => {*/}
                    {/*        return (*/}
                    {/*            <Option className="item-options-status-new" key={index} value={item}>*/}
                    {/*              {item}*/}
                    {/*            </Option>*/}
                    {/*        )*/}
                    {/*      })*/}
                    {/*    }*/}
                    {/*  </Select>*/}
                    {/*</div>*/}
                  </div>

                  <div className="content-format-option content-format-files content-format-options">
                    <div className="title-option">Options</div>
                    <div className="content-functionality-files-option">
                      <div className="row">
                        <div className="col-md-12">
                          <Checkbox.Group onChange={this.onChangeUploadOptions} className="format-options-download">
                            <Checkbox value="removeFolderStructure">Remove Folder Structure</Checkbox>
                            <Checkbox value="renameFilesAndFolders">Rename Files And Folders</Checkbox>
                          </Checkbox.Group>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className="footer-buttons-action">
                <Button className="action-cancel-collect" onClick={() => this.handleCancel() }>
                  Cancel
                </Button>
                <Button loading={ loadingCollectData } className="action-collect-data" onClick={(e) => this.onVisibleCollectionData(e) }
                  disabled={ (valueTypeFiles === '' || valueFormatFiles === '' || typeValueFile === '' || (valueTypeFiles === 'only files tag' && valueStatusFiles === '')) }>
                  Collect data
                </Button>
              </div>

            </div> :
            <div className='access-modal-finish-download'>
              { statusCollectData === 'finished' ?
                <div className="content-collection-completed">
                  <div className="content-title-completed">
                    <span className="collection-completed">Collection completed</span>
                    <span className="icon-v12-radio-button-tick"></span>
                  </div>
                  {/*<Button className={ percentCollectData !== 100 ? "action-collect-data-disabled" : 'action-collect-data' }*/}
                  {/*        disabled={ percentCollectData !== 100 }*/}
                  {/*        onClick={(e) => this.onDownloadZipBoxFolder(e) }>*/}
                  {/*  Download*/}
                  {/*</Button>*/}
                  { percentCollectData !== 100 ?
                      <a href= {() => false} className= 'action-collect-data-disabled action-collect-data-href-disabled'
                      >Download</a> :
                      //<a href="#" onClick={this.secureDownloadLink} className= 'action-collect-data action-collect-data-href'>Download</a>
                    <a href={ downloadZipBox(encodedId) } className= 'action-collect-data action-collect-data-href'
                    >Download</a>
                  }
                </div>
                :
                <ProgressCollectionData
                  onStatusCollectionData={ this.onStatusCollectionData } percentCollectData={ percentCollectData.toFixed(2) }/>
              }
            </div>
          }
        </React.Fragment>
      </Modal>
    )
  }
}
const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id,
  client: state.subscriptionReducer.subscription.subscription.client,
  user: state.userReducer.user,
})
DownloadBoxModalOptionFiles.propTypes = {
  user: PropTypes.object,
}
export default connect(mapStateToProps)(DownloadBoxModalOptionFiles)