import React, { Component } from 'react'
import { connect } from 'react-redux'
import './ExternFileFeedbackResponse.scss'
import { Radio, Spin } from 'antd'
import {
  apiSarFeedbackResponse,
  getPreviewSarDocByThumbnailIdFinalized, downloadZipExternFeedbackBox, downloadFileExternFeedbackData
} from '../../../api/Sar'
import CustomNotificationManager from '../../Common/CustomNotificationManager'
import { fetchUserSarFormFeedback } from '../../../actions/sarActions'
import Loading from '../../Common/Loading'
import { generateBlobFromBase64 } from '../../../utils/GenerateBlob'
import PDFViewer from '../../../utils/PDFViewer'
import PDFJSBackend from '../../../utils/backends/PDFJs'
import imgFolderSmallParent from '../../../assets/smart-folder-base-close.png'
import FileDownload from 'js-file-download'
import withRouter from '../../../private-routers/withRouter'
const RadioGroup = Radio.Group

class ExternFileFeedbackResponse extends Component {

  constructor(props) {
    super(props)
    this.state = {
      title: 'Your Subject Access Request results',
      question: 'Would you like us keep this data or delete it?',
      fileList: [],
      previewImage: '',
      previewVisible: false,
      uploadFileExtern: false,
      loading: false,
      loadingSubmit: false,
      currentSarId: null,
      data: {
        sarId: '',
        //responseFeedback: undefined,
      },
      editMode: false,
      nameBox: '',
      idBox: '',
      sarId: '',
      initialValues: {},
      checkEndRequest: false,
      statusDownload: false
    }
  }

  componentDidMount() {

    if (this.props.params.id) {
      fetchUserSarFormFeedback(this.props.params.id).then((response) => {
        this.setState({
          //fileList: response.data.thumbnails,
          editMode: response.data.editMode,
          nameBox: response.data.boxName,
          idBox: response.data.boxId,
          sarId: response.data.sarId,
          initialValues: response.data
        })
      })
    }
  }

  onSendAnswer = (e) => {
    this.setState({ data: { ...this.state.data, responseFeedback: e.target.value, sarId: this.props.params.id } })
  }

  handleFeedbackResponse = () => {
    if (this.state.editMode) {
      this.setState({
        loadingSubmit: true
      })
      this.setState({ data: { ...this.state.data, sarId: this.props.params.id } }, () => {
        apiSarFeedbackResponse(this.state.data)
            .then((response) => {
              const answerResponse = response.data
              if (answerResponse === 'Successful' && response.status === 200) {
                CustomNotificationManager.success('Your response was sent.', 'Success')
                this.setState({
                  loadingSubmit: false,
                  editMode: false
                })
                setTimeout(function() {
                  this.props.navigate(-1) || window.close()
                }, 3000)
              } else {
                CustomNotificationManager.info('The SAR is already closed, and cannot be completed anymore.')
                this.setState({
                  checkEndRequest: true
                })
                setTimeout(function() {
                  this.props.navigate(-1) || window.close()
                }, 4000)
              }
            }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
        })
      })
    }
  }

    handlePreview = (file) => {
      if (file) {
        this.setState({
          previewVisible: true,
          loading: true
        }, () => {
          getPreviewSarDocByThumbnailIdFinalized(file.id).then((response) => {
            switch (response.data.fileType) {
              case 'image/png':
              case 'image/jpg':
              case 'image/jpeg':
                const imageUrl = `data:${ response.data.fileType };base64, ${ response.data.doc }`
                this.setState({
                  renderingBlock: <img alt="example" style={{ width: '100%' }} src={imageUrl}/>,
                  loading: false
                })
                break
              case 'application/pdf':
                const { doc } = response.data
                this.setState({
                  renderingBlock: <PDFViewer backend={PDFJSBackend} src={ generateBlobFromBase64(doc) }/>,
                  loading: false
                })

                break
              default: break
            }
          }).catch((error) => {
            if (error?.response?.status === 504) {
              CustomNotificationManager.cleanBuffer('warning', '')
            }else{
              console.log(error)
            }
          })
        })
      } else {
        return false
      }

    };

  handleCancel = () => this.setState({ previewVisible: false })

  onDownloadBoxFeedback = () => {
    //const { sarId, nameBox } = this.state
    // downloadFileExternFeedbackBox(sarId).then((response) => {
    //   FileDownload(response.data, `${ nameBox }`)
    // })
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { initialValues } = this.state
    if (initialValues && !initialValues.editMode) {
      this.renderNotificationMessage()
    }
    if (initialValues && initialValues.completed) {
      this.renderNotificationMessageClosed()
    }
  }
  renderNotificationMessage() {
    CustomNotificationManager.warning('This form has already been completed.')
    setTimeout(function() {
      window.close()
    }, 4000)
  }
  renderNotificationMessageClosed() {
    CustomNotificationManager.warning('The sar was closed.')
    setTimeout(function() {
      window.close()
    }, 4000)
  }
  onDownloadZipExternFeedback = () => {
    const { nameBox } = this.state
    downloadZipExternFeedbackBox(this.props.params.id).then((response) => {
      FileDownload(response.data, `${ nameBox }.zip`)
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  onStatusDownload(status) {
    this.setState({
      statusDownload: true
    })
  }
  render() {

    const { title, question, loadingSubmit, editMode, nameBox, initialValues, checkEndRequest, statusDownload } = this.state
    const { responseFeedback } = this.state.data
    return (
      <div className="sa_page_extern_feedback">
        <div className="externFile-page-feedback">
          { (initialValues && initialValues.completed) || checkEndRequest ?
            <div className="closed-extern-feedback">
              <span className="message-closed-extern-feedback">The sar was closed.</span>
            </div> :
            initialValues && !initialValues.editMode ?
              <div className="closed-extern-feedback">
                <span className="message-closed-extern-feedback-update">Request closed</span>
                <span className="message-closed-data-confirmed">Receipt of data has been confirmed.</span>
              </div> :
              <div className="externFile-box-feedback">
                <header className="externFile-header-feedback">
                  <div className="externFile-header__top__feedback">
                    <h1 className="externFile-title-feedback"> { title } </h1>
                  </div>
                </header>

                <div className="content-feedback-upload-document">
                  <div className="document-feedback">
                    <img alt={ imgFolderSmallParent } src={ imgFolderSmallParent }/>
                    <span className="icon-v10-tool-download-neg"></span>
                  </div>
                  <div className="name-document-feedback">
                    <span className="name-document">
                      { nameBox ? nameBox : '' }
                    </span>
                  </div>

                </div>
                <div className="button-download-feedback col-xs-2">
                  {/*{ <a className="button-blue button-download-data"*/}
                  {/*  href={ downloadFileExternFeedback(this.props.params.id) }>DOWNLOAD</a>*/}
                  {/*}*/}

                  {/*{<a className="button-blue button-download-data" onClick={ this.onDownloadBoxFeedback }>DOWNLOAD</a>*/ }
                  {/*}*/ }
                  {/*{ <button className="button-blue button-download-data"*/}
                  {/*     onClick={ this.onDownloadZipExternFeedback }>DOWNLOAD</button>*/}
                  {/*}*/}
                  { <a className="button-blue button-download-data"
                       onClick={(e) => this.onStatusDownload(e)}
                       href={ downloadFileExternFeedbackData(this.props.params.id, nameBox) }>DOWNLOAD</a>
                  }
                </div>
                <div className="content-message-hyperlink">
                  <span className="text-information">Trouble downloading? </span>
                  <span className="more-info-link" onClick={ () => window.open('https://support.smartbox.ai/support/solutions/articles/47001222170-having-trouble-downloading-data-', '_blank') }> Click here. </span>
                </div>

                <div className="file-upload-container-feedback">
                  {/*<div className="extern-file-feedback">*/ }
                  {/*  <Upload*/ }
                  {/*    accept={'.pdf, .png, .jpeg, .jpg, .doc, .docx, .pptx, .xlsx, .xls'}*/ }
                  {/*    listType="picture-card"*/ }
                  {/*    fileList={fileList}*/ }
                  {/*    multiple={true}*/ }
                  {/*    showUploadList={{*/ }
                  {/*      showPreviewIcon: true,*/ }
                  {/*      showRemoveIcon: false*/ }
                  {/*    }}*/ }
                  {/*    onPreview={this.handlePreview}*/ }
                  {/*  >*/ }

                  {/*  </Upload>*/ }
                  {/*  <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel} className="preview-modal">*/ }
                  {/*    <div className="custom-modal-body">*/ }
                  {/*      {loading ? <Spin spinning={loading} indicator={<div className="checkout-loading"><Loading /></div>} /> : renderingBlock }*/ }
                  {/*    </div>*/ }
                  {/*  </Modal>*/ }
                  {/*</div>*/ }
                  {/*<div className="content-choose-keep">*/}
                  {/*  <span className="question-keep-data">{ question }</span>*/}
                  {/*<div className="tracker-radio-feedback">*/ }
                  {/*  <RadioGroup*/ }
                  {/*    onChange={this.onSendAnswer}*/ }
                  {/*  >*/ }
                  {/*    <span className="custom-radio__yes">*/ }
                  {/*      <Radio value={true}>Yes, keep it</Radio>*/ }
                  {/*    </span>*/ }
                  {/*    <span className="custom-radio__no">*/ }
                  {/*      <Radio value={false}>No, delete it</Radio>*/ }
                  {/*    </span>*/ }
                  {/*  </RadioGroup>*/ }
                  {/*</div>*/ }
                  {/*    <div className="new-tracker-radio-feedback">*/}
                  {/*      <RadioGroup onChange={ this.onSendAnswer }>*/}
                  {/*        <Radio value={ true }><span className="true-keep">Yes, keep it</span></Radio>*/}
                  {/*        <Radio value={ false }><span className="false-keep">No, please delete it</span></Radio>*/}
                  {/*      </RadioGroup>*/}
                  {/*    </div>*/}
                  {/*  </div>*/}

                  {/*<div className="new-tracker-radio-feedback-update">*/}
                  {/*       <RadioGroup onChange={this.onSendAnswer}>*/}
                  {/*        <Radio value={ true }><span className="true-keep">I have read and understood your privacy information.</span></Radio>*/}
                  {/*        <Radio value={ false }><span className="false-keep">I wish to restrict the processing of my personal data, or my personal data must be erase without undue delay if: the processing of the personal data*/}
                  {/*          <br></br> will infringe the data protection principles; you do not meet safeguards for archiving and processing of sensitive personal data;or you have a legal*/}
                  {/*          <br></br> obligation to erase the data.*/}
                  {/*        </span></Radio>*/}
                  {/*       </RadioGroup>*/}
                  {/*      </div>*/}
                </div>

                <footer className="row footer-extern-file-feedback">
                  <div className="button-submit-feedback col-xs-offset-7 col-xs-2">
                    {
                      loadingSubmit ? <Spin className="float-right" spinning={ loadingSubmit }
                        indicator={ <div className="checkout-loading"><Loading/></div> }/> :
                        <button className={ editMode ? "button-blue button-submit" : "btn-submit-update"} onClick={ this.handleFeedbackResponse }
                          //disabled={ responseFeedback === undefined || !editMode }
                            disabled={ statusDownload === false }
                        >{ this.state.editMode ? 'I hereby confirm receipt of my data and close my request' : 'Request closed'}</button>
                    }
                  </div>
                </footer>
              </div>
          }
        </div>
      </div>
    )
  }
}
ExternFileFeedbackResponse = withRouter(ExternFileFeedbackResponse)
const mapStateToProps = (state) => ({
  sar: state.sar.sarUserForm
})
export default connect(mapStateToProps)(ExternFileFeedbackResponse)
