import React from 'react'

import './Footer.css'

const Footer = () => (
  <div className="privacyCollection__footer">
    <div className="privacyCollection__footer-priorities">
      <p className="privacyCollection__footer-text">Priority levels</p>
      <span className="privacyCollection__footer-high">High</span>
      <span className="privacyCollection__footer-medium">Caution</span>
      <span className="privacyCollection__footer-low">Low</span>
    </div>
    <div className="privacyCollection__footer-info">
      <p><strong>GDPR</strong> - General Data Protection Regulation</p>
      <p><strong>SAR</strong> - Subject Access Request</p>
      <p><strong>PII/PIHI</strong> - Personally Identifiable Information/Personally Identifiable Health Information</p>
      <p><strong>PIPEDA</strong> - Personal Information Protection and Electronic Documents Act</p>
    </div>
  </div>
)

export default Footer