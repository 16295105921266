import React from 'react'
import { Navigate } from 'react-router-dom'
import swal from 'sweetalert2'

import Button from '../Common/Button'
import Loading from '../Common/Loading'

import WithConnectors from './HOC/WithConnectors'

import { findConnector, mountCrawlerConfig } from '../../utils/Connectors'

import './ConnectorCreate.css'

class ConnectorCreate extends React.Component {
  state = {
    connectorId: null,
    connectorToEdit: {},
    hasError: false,
    initFields: false,
    isDeleting: false,
    isFetchingConnector: false,
    isSaving: false,
    location: '',
    name: '',
    server: '',
    redirectToConnectors: false
  }

  componentDidMount = () => {
    this.props.getServers()
    const id = parseInt(this.props.params.id, 10)
    if (id) this.setState({
      connectorId: id,
      initFields: true,
      isFetchingConnector: true
    }, this.initFields)
  }

  componentWillReceiveProps = () => this.initFields()

  initFields = () => {
    if (this.state.initFields && this.props.connectors.length > 0) {
      const connectorToEdit = findConnector(this.props.connectors, this.state.connectorId)

      // No connector was found using the id on url.
      if (!connectorToEdit) return this.setState({
        hasError: true,
        initFields: false,
        isFetchingConnector: false
      })

      // All the information is already fetched, so just fill the fields.
      if (!connectorToEdit.isFetchingInfo) return this.fillConnector(connectorToEdit)
      
      this.props.getInformationForOneConnector(connectorToEdit.id)
    }
  }

  fillConnector = (connector) => {
    this.setState({
      initFields: false,
      isFetchingConnector: false,
      name: connector.name
    })
  }

  handleInputChange = (event) => {
    const field = event.target.name
    this.setState({
      [field]: event.target.value
    })
  }

  save = () => {
    this.setState({ isSaving: true })

    const serverID = this.state.server
    const crawlerConfig = mountCrawlerConfig(this.state.name, this.state.location)
    this.props.addNewCrawler(this.state.name, serverID, crawlerConfig)
      .then(() => {
        this.setState({ isAdding: false, redirectToConnectors: true })
      })
  }

  delete = () => {
    this.setState({ isDeleting: true })
    // apiPrivacyDeleteCrawler(connectorId)
    //   .then((response) => {
    //     if (response.data.status === 'Success') {
    //       const newConnectors = this.props.connectors
    //         .filter((connector) => connector.id !== connectorId)

    //       this.props.updateConnectors(newConnectors)
    //       this.setState({ redirectToConnectors: true })
    //     }
    //   })
  }

  showConfirmation = () => {
    swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      html: '<p style="font-size: 14px; margin: 10px 0; text-align: center;">Are you sure that you want to delete this connector?</p> ',
      title: 'Delete connector',
      type: 'question',
      padding: 50
    }).then((result) => {
      if (result.value) this.delete()
    })
  }

  render() {
    if (this.state.redirectToConnectors) return (
      <Navigate to="/privacy/connectors/manage" />
    )

    if (this.state.isFetchingConnector) return (
      <div className="privacyConnectorCreate__page">
        <div className="privacyConnectorCreate__loading"><Loading /></div>
      </div>
    )

    if (this.state.hasError) return (
      <div className="privacyConnectorCreate__page">
        <div className="privacyConnectorCreate__error">
          <p>This connector does not exist.</p>
        </div>
      </div>
    )

    return (
      <div className="privacyConnectorCreate__page">
        <div className="privacyConnectorCreate__box">
          <header className="privacyConnectorCreate__boxHeader">
            <h1 className="privacyConnectorCreate__boxTitle">Add new connector</h1>
          </header>

          <div className="privacyConnectorCreate__content">
            <div className="privacyConnectorCreate__formFields">
              <div className="privacyConnectorCreate__formGroup">
                <label>Dataset Label</label>
                <p>Adding labels to your datasets will help sort between multiple services.</p>
                <input type="text" name="name" value={ this.state.name } onChange={ this.handleInputChange } />
              </div>

              <div className="privacyConnectorCreate__formGroup">
                <label>Server</label>
                <select name="server" value={ this.state.server } onChange={ this.handleInputChange }>
                  <option value="">choose...</option>
                  { this.props.servers.map((server) => (
                    <option key={ server.id } value={ server.id }>{ server.id }</option>
                  ))}
                </select>
              </div>
              
              <div className="privacyConnectorCreate__formGroup">
                <label>Location</label>
                <input type="text" name="location" onChange={ this.handleInputChange } />
              </div>

              { (this.state.isSaving || this.state.isDeleting) && 
                <Loading />
              }

              { (!this.state.isSaving && !this.state.isDeleting) &&
                <div className="privacyConnectorCreate__buttons">
                  { this.state.connectorId &&
                    <Button
                      theme="red"
                      onClick={ this.showConfirmation }>
                    Delete connector</Button>
                  }

                  <Button
                    disabled={ this.state.name === '' || this.state.server === '' || this.state.location === '' }
                    theme="green"
                    onClick={ this.save }>
                  Save connector</Button>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default WithConnectors(ConnectorCreate)