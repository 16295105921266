import React from "react";
import {connect} from "react-redux";
import './ComponentsForm.scss';
import { Input, Checkbox} from "antd";
const { TextArea } = Input
class ParagraphForm extends React.Component {
    state = {
        paragraphForm: {
            textParagraph: '',
            queryForSearch: false,
            mandatoryCheck: false,
            options: null
        },
        hoverDelete: false
    }
    componentDidMount() {
        const { component } = this.props
        this.setState({
            paragraphForm: { ...this.state.paragraphForm,
                queryForSearch: component.queryForSearch === undefined ? false : component.queryForSearch,
                mandatoryCheck: component.mandatory === undefined ? false : component.mandatory,
                options: component.options === undefined ? null : component.options,
            }
        })
        //this.props.onChangeForm(this.state.paragraphForm, this.props.component)
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props && this.props.checkStartSaved) {
            this.props.onChangeForm(this.state.paragraphForm, this.props.component)
        }
    }
    onChangeParagraph = (e, key) => {
        switch (key) {
            case 'paragraph':
                this.setState({
                    paragraphForm: { ...this.state.paragraphForm, textParagraph: e.target.value },
                })
                break
            default:
                break
        }
    }
    onChangeCheckbox = (e, key) => {
        switch (key) {
            case 'query':
                this.setState({
                    paragraphForm: { ...this.state.paragraphForm, queryForSearch: e.target.checked },
                }, () => {
                    this.props.onChangeForm(this.state.paragraphForm, this.props.component)
                })
                break
            case 'mandatory':
                this.setState({
                    paragraphForm: { ...this.state.paragraphForm, mandatoryCheck: e.target.checked },
                }, () => {
                    this.props.onChangeForm(this.state.paragraphForm, this.props.component)
                })
                break
            default:
                break
        }
    }
    onHoverActionComponent = () => {
        this.setState({
            hoverDelete: true
        })
    }
    onLeaveActionComponent = () => {
        this.setState({
            hoverDelete: false
        })
    }
    render() {
        const { paragraphForm, hoverDelete } = this.state
        const { component } = this.props
        return (
            <div className="component-form">
                <div className="component-delete">
                    <span onClick={ (e) => this.props.showConfirmDeleteFormElement(e, component) }
                          className={hoverDelete ? 'icon-v19-delete-active' : "icon-v19-delete" }
                          onMouseEnter={ (e) => this.onHoverActionComponent(e)}
                          onMouseLeave={() => this.onLeaveActionComponent()}></span>
                </div>
                <div className="component-content">
                    <div className="header-component">
                        <span className="header-title">Paragraph</span>
                        <span className="header-checkbox">
                           {/*<Checkbox onChange={(e) => this.onChangeCheckbox(e, 'query')}*/}
                           {/*          checked={ paragraphForm.queryForSearch }>Query for search</Checkbox>*/}
                           <Checkbox onChange={(e) => this.onChangeCheckbox(e, 'mandatory')}
                                     checked={ paragraphForm.mandatoryCheck }>Mandatory</Checkbox>
                        </span>
                    </div>
                    <span className="paragraph-content">
                            <TextArea className="paragraph-form-name"
                                      onChange={(e) => this.onChangeParagraph(e, 'paragraph')}
                                      rows={4}
                                      value={ paragraphForm.textParagraph }/>
                        </span>
                </div>
                <div className="component-move">
                    <span onMouseEnter={() => this.props.onStateProvidedDraggable(component)} className="icon-v211-grid-view-1"></span>
                </div>
            </div>
        )
    }
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(ParagraphForm)