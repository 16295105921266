import PropTypes from 'prop-types'
import React from 'react'
import './RepeatOption.scss'

class RepeatOption extends React.Component {

  classState(isSelected, isEditMode) {
    if (isEditMode && isSelected) {
      return 'LabelCheckedDisabled'
    } else if (!isEditMode && isSelected) {
      return 'LabelChecked'
    } else if (isEditMode && !isSelected) {
      return 'LabelDisabled'
    }

    return 'Label'
  }

  render() {
    const { item, isEditMode, repeatSelected } = this.props
    const classState = this.classState(repeatSelected === item, isEditMode)

    return (
      <div className={ `newRepeatOption-${ item }` }>
        <input
          checked={ repeatSelected === item }
          disabled={ isEditMode }
          id={ item }
          name="answer"
          type="radio"
          value={ item }
          onClick={ (e) => this.props.updateValueForRepeat(e) }
          onChange={ () => {} } />
        <label
          className={ repeatSelected === item ? `newRepeatOption-${ item }${ classState }` : `newRepeatOption-${ item }${ classState }` }
          htmlFor={ item }>
          { item }
        </label>
      </div>
    )
  }
}

RepeatOption.propTypes = {
  item: PropTypes.string.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  repeatSelected: PropTypes.string.isRequired,
  updateValueForRepeat: PropTypes.func.isRequired
}

RepeatOption.defaultProps = {
  isEditMode: false
}

export default RepeatOption