import React from "react";
import {connect} from "react-redux";
import './EmailWorkAdmin.scss';

import {Tabs, Checkbox} from 'antd'
import {
    apiDeleteDefaultEmailTemplateWorkflow, apiGetDefaultEmailTemplatesWorkflowPage,
    apiGetEmail,
    apiGetEmailPage, apiGetEmailTemplatesPageAvailableVariables
} from "../../../api/Sar";
import {customConfirm} from "../../../utils/AssetRegister/CustomConfirm";
import CustomNotificationManager from "../../Common/CustomNotificationManager";
import EmailWorkTemplateAdmin from './EmailWorkTemplateAdmin';
const TabPane = Tabs.TabPane

class EmailWorkAdmin extends React.Component {
    state = {
        selectedEmailTemplate: "2",
        mode: 'left',
        listEmailTemplate: [],
        getEmailsTemplate: [],
        getObjectEmailsTemplate: {},
        listKeysCheckbox: [],
        listKeysCheckboxGeneral: [],
        listKeysCheckboxWebform: [],
        listKeysCheckboxDisclosure: [],
        hoverAddTemplate: {},
        hoverDeleteTemplate: {},
        statusAddTemplate: false,
        responseSavedEmailTemplate: false,
        statusActiveEdit: false,
        updateGetEmailTemplates: false,
        keyTemplate: {},
        activeTab: 'template',
        statusDeleteEMailTemplateWorkflow: false,
        listTabsEmailTemplate: [
            {key: 0, emailTabName: 'General emails'},
            {key: 1, emailTabName: 'Webform emails'},
            {key: 2, emailTabName: 'Disclosure emails'},
        ],
        listKeysCheckboxSelected: [],
        listVariables: [],
        loadingGetEmailTemplates: false
    }
    componentDidMount() {
        this.onGetEmailsTemplate()
    }
    onGetEmailsTemplate = () => {
        const { listTabsEmailTemplate, listKeysCheckbox  } = this.state
        apiGetDefaultEmailTemplatesWorkflowPage().then((response) => {
            response.data && response.data.length > 0 && response.data.forEach((item, index) => {
                if (listKeysCheckbox && listKeysCheckbox.length > 0) {
                    listKeysCheckbox.forEach((elem) => {
                        if (item.templateName === elem.templateName) {
                            item.checked = true
                        }
                    })
                } else {
                    item.checked = false
                }
                item.key = index + 1
                item.fromEmail = item.fromEmail ? item.fromEmail : ""
                item.fromName = item.fromName ? item.fromName : ""
                const variables = []

                let delWords = [];
                if (item.content) {
                    item.content = item.content.replaceAll('#{', '${')
                }
                for(let i = 0; i < item.content.length; i++) {
                    if (item.content[i-1] == '$' && item.content[i] == '{') {
                        delWords.push(i)
                    } else if ((item.content[i] == '}') && (delWords.length > 0)) {
                        let position = delWords[delWords.length - 1]
                        delWords.pop()
                        let len = i - 1 - position
                        let ansMatch
                        if (position > 0 && len > 0) {
                            ansMatch = item.content.substring(position + 1, len + position + 1)
                        }
                        if (ansMatch) {
                            variables.push({
                                name: ansMatch,
                                nameVariables: `\${#{ansMatch}}`,
                                content: ''
                            })
                        }
                    }
                }

                const matches = item.content.match(/\{(.*?)\}/g);
                item.variablesTemplate = item.variables
            })
            this.setState({
                getEmailsTemplate: response.data
                //getEmailsTemplate: []
            }, () => {
                let { getEmailsTemplate } = this.state
                getEmailsTemplate.push(
                    {
                        checked: false,
                        //key: 0,
                        content: '',
                        fromEmail: '',
                        fromName: '',
                        subject: '',
                        templateName: '',
                        variables: [],
                        statusTab: 'parent',
                        tabNameParent: 'General emails',
                        emailTemplateType: 'General emails',
                    },
                    {
                        checked: false,
                        //key: 1,
                        content: '',
                        fromEmail: '',
                        fromName: '',
                        subject: '',
                        templateName: '',
                        variables: [],
                        statusTab: 'parent',
                        tabNameParent: 'Webform emails',
                        emailTemplateType: 'Webform emails',
                    },
                    {
                        checked: false,
                        //key: 2,
                        content: '',
                        fromEmail: '',
                        fromName: '',
                        subject: '',
                        templateName: '',
                        variables: [],
                        statusTab: 'parent',
                        tabNameParent: 'Disclosure emails',
                        emailTemplateType: 'Disclosure emails',
                    },
                )
                //const filteredArray = getEmailsTemplate.filter((item) => item.statusTab && item.statusTab !== 'parent')

                const filteredArray = []
                getEmailsTemplate && getEmailsTemplate.length > 0 && getEmailsTemplate.map((item, index) => {
                    if (!item.statusTab && item.statusTab !== 'parent') {
                        item.statusTab = 'children'
                    }
                })

                const customOrderParent = ['parent', 'children']
                getEmailsTemplate = [...getEmailsTemplate].sort((a, b) => {
                    return customOrderParent.indexOf(a.statusTab) - customOrderParent.indexOf(b.statusTab)
                })
                const customOrder = ['General emails', 'Webform emails', 'Disclosure emails']
                //getEmailsTemplate = getEmailsTemplate.sort((a, b) => a.key - (b.key))
                getEmailsTemplate = [...getEmailsTemplate].sort((a, b) => {
                    return customOrder.indexOf(a.emailTemplateType) - customOrder.indexOf(b.emailTemplateType)
                })

                getEmailsTemplate && getEmailsTemplate.length > 0 && getEmailsTemplate.map((item, index) => {
                    item.index = index
                    item.key = index
                    if (item.statusTab && item.statusTab !== 'parent') {
                        //item.statusTab = 'children'
                        filteredArray.push(item)
                    }
                })
                if (filteredArray.length === 0) {
                    filteredArray.push(getEmailsTemplate[0])
                }

                const lastElem = filteredArray.pop()
                this.onGetEmailTemplatesWorkflowPageAvailableVariables(lastElem.emailTemplateType)

                this.setState({
                    getEmailsTemplate,
                    //keyTemplate: getEmailsTemplate[getEmailsTemplate.length - 1],
                    keyTemplate: getEmailsTemplate[lastElem.index],
                    //selectedEmailTemplate: `${getEmailsTemplate.length}`
                    selectedEmailTemplate: `${lastElem.index}`
                })
            })
        })
    }
    changeEmailWorkflow = (selectedKey) => {
        const { getEmailsTemplate } = this.state

        //if (selectedKey !== '0') {
        getEmailsTemplate && getEmailsTemplate.length > 0 && getEmailsTemplate.forEach((item) => {
            if (item.index === selectedKey) {
                this.setState({
                    keyTemplate: item,
                })
            }
        })
        this.onResponseSaveEmailTemplate(false)
        //this.onGetEmailsTemplate()

        this.setState({
            selectedEmailTemplate: selectedKey,
            keyTemplate: getEmailsTemplate[selectedKey],
            //getEmailsTemplate
        })
        this.onGetEmailTemplatesWorkflowPageAvailableVariables(getEmailsTemplate[selectedKey].emailTemplateType)
        this.onStatusActiveEdit(false)
        this.onUpdateGetEmailTemplates(false)
        //}
    }
    onClickAddTemplate = (e, tabName) => {
        e.preventDefault()
        const { getEmailsTemplate } = this.state
        const selectedKey = '0'
        getEmailsTemplate && getEmailsTemplate.length > 0 && getEmailsTemplate.forEach((item) => {
            if (item.key === (selectedKey - 1)) {
                this.setState({
                    keyTemplate: item,
                })
            }
        })
        this.onResponseSaveEmailTemplate(false)
        this.setState({
            selectedEmailTemplate: selectedKey,
        })
        this.onStatusActiveEdit(false)
        this.onUpdateGetEmailTemplates(false)
        getEmailsTemplate && getEmailsTemplate.length > 0 && getEmailsTemplate.forEach((item) => {
            item.checked = false
        })
        this.setState({
            statusAddTemplate: true,
            listKeysCheckbox: [],
            listKeysCheckboxGeneral: [],
            listKeysCheckboxWebform: [],
            listKeysCheckboxDisclosure: [],
            getEmailsTemplate
        })
    }
    onGenerateListEmailTemplate = (listTemplate) => {
        const templateList = this.state.listEmailTemplate.concat(listTemplate)
        this.setState({
            listEmailTemplate: templateList
        })
    }
    onChangeCheckbox = (e, listT) => {
        const { getEmailsTemplate } = this.state
        let { listKeysCheckbox, listKeysCheckboxSelected, listKeysCheckboxGeneral, listKeysCheckboxWebform, listKeysCheckboxDisclosure } = this.state
        let validateName = []
        const listCheckbox = []
        getEmailsTemplate && getEmailsTemplate.length > 0 && getEmailsTemplate.forEach((item) => {
            if (e.target.checked) {
                if (listT.templateName === item.templateName) {
                    item.checked = true
                    listKeysCheckbox.push(listT)
                    if (listT.emailTemplateType === 'General emails') {
                        listKeysCheckboxGeneral.push(listT)
                    }
                    if (listT.emailTemplateType === 'Webform emails') {
                        listKeysCheckboxWebform.push(listT)
                    }
                    if (listT.emailTemplateType === 'Disclosure emails') {
                        listKeysCheckboxDisclosure.push(listT)
                    }
                }
            } else {
                if (listT.templateName === item.templateName) {
                    item.checked = false
                    listKeysCheckbox = listKeysCheckbox.filter((item2) => item2.templateName !== listT.templateName)
                    if (listT.emailTemplateType === 'General emails') {
                        listKeysCheckboxGeneral = listKeysCheckboxGeneral.filter((item2) => item2.templateName !== listT.templateName)
                    }
                    if (listT.emailTemplateType === 'Webform emails') {
                        listKeysCheckboxWebform = listKeysCheckboxWebform.filter((item2) => item2.templateName !== listT.templateName)
                    }
                    if (listT.emailTemplateType === 'Disclosure emails') {
                        listKeysCheckboxDisclosure = listKeysCheckboxDisclosure.filter((item2) => item2.templateName !== listT.templateName)
                    }
                }
            }
        })
        getEmailsTemplate && getEmailsTemplate.length > 0 && getEmailsTemplate.forEach((item) => {
            if (e.target.checked) {
                if (item.emailTemplateId === listT.emailTemplateId) {
                    listKeysCheckboxSelected.push(item.emailTemplateId)
                }
            } else {
                listKeysCheckboxSelected = listKeysCheckboxSelected.filter((item2) => item2 !== listT.emailTemplateId)
            }
        })
        this.onGetEmailTemplatesWorkflowPageAvailableVariables(listT.emailTemplateType)
        this.setState({
            getEmailsTemplate,
            listKeysCheckbox,
            listKeysCheckboxSelected,
            listKeysCheckboxGeneral,
            listKeysCheckboxWebform,
            listKeysCheckboxDisclosure,
        })
    }
    onHoverActionTemplate = (e, status, listT) => {
        switch (status) {
            case 'add':
                this.setState({
                    hoverAddTemplate: listT
                })
                break
            case 'delete':
                this.setState({
                    hoverDeleteTemplate: listT
                })
                break
            default: break
        }
    }
    onHoverLeaveTemplate = (e, status) => {
        this.setState({
            hoverAddTemplate: {},
            hoverDeleteTemplate: {},
        })
    }

    showConfirmDeleteTemplate = (e, listT) => {
        const { listKeysCheckbox } = this.state
        e.preventDefault()

        if (listKeysCheckbox && listKeysCheckbox.length > 0) {
            customConfirm({
                title: <span className="icon-v14-delete"></span>,
                content: `Delete templates?`,
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel',
                mask: true,
                maskClosable: false,
                closeHaveLogic: false,
                className: 'asset-register-modal modal-delete-connector',
                centered: true,
                cancelClassName: 'cancel-button',
                confirmClassName: 'confirm-button',
                confirmAction: () => {
                    this.onDeleteTemplates(e, listT)
                }
            })
        }
    }

    onDeleteTemplates = (e, listT) => {
        const { listKeysCheckbox, listKeysCheckboxGeneral, listKeysCheckboxWebform, listKeysCheckboxDisclosure } = this.state
        const listIds = []
        listKeysCheckbox && listKeysCheckbox.length > 0 && listKeysCheckbox.forEach((item) => {
            if (item.emailTemplateType === listT.emailTemplateType) {
                listIds.push(item.emailTemplateId)
            }
        })
        const dtoDelete = {
            ids: listIds
        }
        this.setState({
            statusDeleteEMailTemplateWorkflow: true
        }, () => {
            apiDeleteDefaultEmailTemplateWorkflow(listIds).then((response) => {
                if(response.status === 200) {
                    if (response.data.title && response.data.title.toLowerCase() === 'success') {
                        CustomNotificationManager.success(response.data.message, 'Success')
                    } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
                        CustomNotificationManager.info(response.data.message, 'Info')
                    }
                    this.setState({
                        listKeysCheckbox: listKeysCheckbox.filter((elem) => elem.emailTemplateType !== listT.emailTemplateType),
                        listKeysCheckboxGeneral : listKeysCheckboxGeneral.filter((elem) => elem.emailTemplateType !== listT.emailTemplateType),
                        listKeysCheckboxWebform  : listKeysCheckboxWebform .filter((elem) => elem.emailTemplateType !== listT.emailTemplateType),
                        listKeysCheckboxDisclosure  : listKeysCheckboxDisclosure .filter((elem) => elem.emailTemplateType !== listT.emailTemplateType),
                        statusDeleteEMailTemplateWorkflow: false
                    }, () => {
                        this.onGetEmailsTemplate()
                    })
                }
            })
        })
    }
    onResponseSaveEmailTemplate = (response) => {
        this.setState({
            responseSavedEmailTemplate: response
        })
    }
    onGetActiveKeyTab = (keyTab) => {
        this.setState({
            selectedEmailTemplate: `${keyTab}`
        })
    }
    onStatusActiveEdit = (status) => {
        this.setState({
            statusActiveEdit: status
        })
    }
    onUpdateGetEmailTemplates = (status) => {
        this.setState({
            updateGetEmailTemplates: status
        })
    }
    onCallbackTab = (key) => {
        this.setState({
            activeTab: key
        })
    }
    onGetEmailTemplatesWorkflowPageAvailableVariables = (emailTemplateType) => {
        this.setState({
            loadingGetEmailTemplates: true
        }, () => {
            apiGetEmailTemplatesPageAvailableVariables(emailTemplateType).then((response) => {
                this.setState({
                    listVariables: response.data,
                    loadingGetEmailTemplates: false
                })
            }).catch((err) =>{
                this.setState({
                    loadingGetEmailTemplates: false
                })
            })
        })
    }
    render() {
        const { selectedEmailTemplate, mode, listEmailTemplate, getEmailsTemplate, getObjectEmailsTemplate, listKeysCheckbox, hoverAddTemplate, statusAddTemplate,
            hoverDeleteTemplate, responseSavedEmailTemplate, statusActiveEdit, updateGetEmailTemplates, keyTemplate, activeTab, statusDeleteEMailTemplateWorkflow,
            listTabsEmailTemplate, listKeysCheckboxSelected, listVariables, listKeysCheckboxGeneral, listKeysCheckboxWebform, listKeysCheckboxDisclosure,
            loadingGetEmailTemplates } = this.state
        return (
            <div className="email-workflow-admin">
                <div className="email-workflow-admin__contain">
                    <div className="template-emails">
                        {/*tabs AAAAAAA*/}
                        <Tabs
                            activeKey= { selectedEmailTemplate }
                            tabPosition={mode}
                            style={{ height: '100%' }}
                            onChange = { (e) => this.changeEmailWorkflow(e) }
                            className="template-email-wrapper-work"
                        >
                            { getEmailsTemplate && getEmailsTemplate.length > 0 && getEmailsTemplate.map((listT, index) => {
                                if (listT.statusTab === 'parent') {
                                    return (
                                        <TabPane className="email-template-tab tab1" tab=
                                            {<span className="template-tab">
                                                            <span className="name-template">{listT.tabNameParent}</span>
                                                            <span className="actions-template">
                                                                <span className={!statusDeleteEMailTemplateWorkflow &&
                                                                ((listKeysCheckboxGeneral.length > 0 && listT.emailTemplateType === "General emails") ||
                                                                    (listKeysCheckboxWebform.length > 0 && listT.emailTemplateType === "Webform emails") ||
                                                                    (listKeysCheckboxDisclosure.length > 0 && listT.emailTemplateType === "Disclosure emails"))
                                                                && hoverDeleteTemplate.index === index ? "icon-v19-delete-active" :
                                                                    !statusDeleteEMailTemplateWorkflow &&
                                                                    ((listKeysCheckboxGeneral.length > 0 && listT.emailTemplateType === "General emails") ||
                                                                        (listKeysCheckboxWebform.length > 0 && listT.emailTemplateType === "Webform emails") ||
                                                                        (listKeysCheckboxDisclosure.length > 0 && listT.emailTemplateType === "Disclosure emails"))
                                                                        ? 'icon-v19-delete' : 'icon-v19-delete icon-v19-delete-disabled' }
                                                                      onClick={(e) => this.showConfirmDeleteTemplate(e, listT)}
                                                                      onMouseEnter={(e) => this.onHoverActionTemplate(e, 'delete', listT)}
                                                                      onMouseLeave={(e) => this.onHoverLeaveTemplate(e)}></span>
                                                                <span className={hoverAddTemplate.index === index ? 'icon-v19-add-active' : "icon-v19-add" }
                                                                      onMouseEnter={(e) => this.onHoverActionTemplate(e, 'add', listT)}
                                                                      onMouseLeave={(e) => this.onHoverLeaveTemplate(e)}
                                                                      onClick={(e) => this.onClickAddTemplate(e, listT)}
                                                                ></span>
                                                            </span>
                                                        </span>}
                                                 key={ listT.index } animated={false}>
                                            <EmailWorkTemplateAdmin onGenerateListEmailTemplate={this.onGenerateListEmailTemplate} listEmailTemplate={ listEmailTemplate }
                                                               selectedEmailTemplate={ selectedEmailTemplate }
                                                               getEmailsTemplate={ listT }
                                                               onGetEmailsTemplate={ this.onGetEmailsTemplate }
                                                               statusAddTemplate={ statusAddTemplate }
                                                               onResponseSaveEmailTemplate={ this.onResponseSaveEmailTemplate }
                                                               responseSavedEmailTemplate={ responseSavedEmailTemplate }
                                                               onGetActiveKeyTab={ this.onGetActiveKeyTab }
                                                               statusActiveEdit={ statusActiveEdit }
                                                               updateGetEmailTemplates={ updateGetEmailTemplates }
                                                               onStatusActiveEdit={ this.onStatusActiveEdit }
                                                               onUpdateGetEmailTemplates={ this.onUpdateGetEmailTemplates }
                                                               listVariables={ listVariables }
                                                               loadingGetEmailTemplates={ loadingGetEmailTemplates }
                                            />
                                        </TabPane>
                                    )
                                } else if (listT.statusTab === 'children') {
                                    return (
                                        <TabPane className="email-template-tab" tab={
                                            <span className="content-list-tabs">
                                            <Checkbox disabled={ !listT.defaultTemplate } checked={ listT.checked } onChange={(e) => this.onChangeCheckbox(e, listT)}></Checkbox>
                                            <span
                                                //onClick={() => this.changeEmailWorkflow()}
                                                className="template-name">{listT.templateName}</span>
                                        </span>
                                        } key={listT.index} animated={false}>
                                            <EmailWorkTemplateAdmin
                                                onGenerateListEmailTemplate={this.onGenerateListEmailTemplate}
                                                listEmailTemplate={listT}
                                                keyTemplate={listT}
                                                getEmailsTemplateList={getEmailsTemplate}
                                                selectedEmailTemplate={selectedEmailTemplate}
                                                getEmailsTemplate={keyTemplate}
                                                getObjectEmailsTemplate={listT}
                                                onGetEmailsTemplate={this.onGetEmailsTemplate}
                                                statusAddTemplate={statusAddTemplate}
                                                onResponseSaveEmailTemplate={ this.onResponseSaveEmailTemplate }
                                                responseSavedEmailTemplate={ responseSavedEmailTemplate }
                                                onGetActiveKeyTab={ this.onGetActiveKeyTab }
                                                statusActiveEdit={ statusActiveEdit }
                                                updateGetEmailTemplates={ updateGetEmailTemplates }
                                                onStatusActiveEdit={ this.onStatusActiveEdit }
                                                onUpdateGetEmailTemplates={ this.onUpdateGetEmailTemplates }
                                                listVariables={ listVariables }
                                                loadingGetEmailTemplates={ loadingGetEmailTemplates }
                                            />
                                        </TabPane>
                                    )
                                }
                            })

                            }
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id,
    client: state.subscriptionReducer.subscription.subscription.client,
})

export default connect(mapStateToProps)(EmailWorkAdmin)