import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Loading from '../../Common/Loading'

import './CollectionItem.css'

class CollectionItem extends React.Component {
  render = () => {
    const { collection } = this.props

    return (
      <div className="collectionItem-wrapper">
        <h1 className="collectionItem-title">{ collection.name }</h1>
        <p className="collectionItem-text">Potential alerts</p>
        { (collection.isFetchingInfo || collection.isFetchingTotalAlerts) &&
          <div className="collectionItem__loading">
            <Loading />
          </div>
        }
    
        { (!collection.isFetchingInfo && !collection.isFetchingTotalAlerts) &&
          <React.Fragment>
            <div>
              <span className="collectionItem-high">
                { collection.totalAlerts.high }
              </span>
              <span className="collectionItem-medium">
                { collection.totalAlerts.medium }
              </span>
              <span className="collectionItem-low">
                { collection.totalAlerts.low }
              </span>
            </div>
            <span className="collectionItem-total">
              <strong>{ collection.totalObjects }</strong>total objects
            </span>

            <Link to={ `/privacy/alerts/${ collection.id }` } className="collectionItem-link">
              Review alerts
            </Link>
          </React.Fragment>
        }
      </div>
    )
  }
}

CollectionItem.propTypes = {
  collection: PropTypes.object.isRequired
}

export default CollectionItem