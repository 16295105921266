import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import './PiaActive.scss'
import { Tooltip } from 'antd'
import withRouter from '../../../../private-routers/withRouter'

let PiaActive = ({ assessment, answered, total, processStatus, type, disableButtons }) => (
  <div className="piaActiveAssessment__wrapper">
    <div className="piaActiveAssessment__actionsContainer">
      <Tooltip title="PIA Result">
        <span className="iconReportDisabled" />
      </Tooltip>
      <Tooltip title="Download Report">
        <span className="iconDownloadDisabled" />
      </Tooltip>
      { 
        (processStatus === 'INACTIVE' || !disableButtons) &&
        <Tooltip title="Continue Active PIA"><span className="iconPlayDisabled" /></Tooltip>
      }

      { 
        (processStatus !== 'INACTIVE' && disableButtons) &&
        <Tooltip title="Continue Active PIA"><span className="iconPlay" onClick={ seeQuestions.bind(this, assessment.id) } /></Tooltip>
      }
      
    </div>

    <div className="piaActiveAssessment__infoContainer">
      <div className="piaActiveAssessment__textContainer">
        {disableButtons && <Link to={ `/dpia/assessment/${ assessment.id }`} className="piaActiveAssessment_link">
          {type}
        </Link>
        }
      </div>
      <div className="piaActiveAssessment__status">
        { disableButtons && processStatus !== 'INACTIVE' &&
          <Link to={ `/dpia/assessment/${ assessment.id }`} className="piaActiveAssessment__notCompleted">
            { `Incomplete, ${ answered }/${ total }` }
          </Link>
        }
        { !disableButtons && 
          <div className="piaActiveAssessment__notCompleted">
            { `Incomplete, ${ answered }/${ total }` }
          </div> 
        }

        { processStatus === 'INACTIVE' &&
          <span className="piaActiveAssessment__notCompleted">
            Terminated { moment(assessment.validStartDate).format('MMMM YYYY') }
          </span>
        }
        <div className="piaActiveAssessment__iconContainer">
          {disableButtons && <Link to={ `/dpia/assessment/${ assessment.id }`}>
            <span className="iconError" />
          </Link>
          }
        </div>
      </div>
    </div>
  </div>
)

PiaActive = withRouter(PiaActive)

const seeQuestions = (assessmentId) => {
  this.props.navigate(`/dpia/assessment/${ assessmentId }`)
}

PiaActive.propTypes = {
  assessment: PropTypes.object.isRequired,
  answered: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  processStatus: PropTypes.string.isRequired
}

export default PiaActive