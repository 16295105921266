import React from 'react'
import './NearDeduplication.scss'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import NearTable from './NearTable'
import NearList from './NearList'
import { compose } from 'redux'
import { Tooltip } from 'antd'

class NearDeduplication extends React.Component {
    state = {
        visibleNearList: false,
        selectBoxNear: {}
    }
  componentDidMount() {

  }
    onVisibleNearList = (status) => {
        this.setState({
            visibleNearList: status
        })
    }
    selectFileNearBox = (file) => {
        this.setState({
            selectBoxNear: file
        })
    }
    render() {
        const { visibleNearList, selectBoxNear } = this.state
        const { hashFile } = this.props
        return (
            <div className="near-deduplication">
                <div className="near-deduplication__content">
                    <div className="header-near">
                        <span className="title-header">Groups of matching files</span>
                        <Tooltip overlayClassName="help-message-locations" title={<div className="content-help-message-locations">
                            <span className="text-information">Near-duplicate detection will identify documents that have similar text-content by analysing the 'closeness' of the language. Documents which have sufficiently similar language will be considered near-duplicates.</span>
                            <span className="more-info-link" onClick={ () => window.open('https://support.smartbox.ai/support/solutions/articles/47001216556-near-duplicates', '_blank') }>More info.</span>
                            </div>}>
                            <span className="icon-v14-question"></span>
                        </Tooltip>
                    </div>
                    { !visibleNearList ?
                        <div className="content-near-deduplication">
                            <NearTable folder={this.props.folder} onVisibleNearList={ this.onVisibleNearList }
                                       selectFileNearBox={ this.selectFileNearBox }
                                       activeTab={ this.props.activeTab }/>
                        </div> :
                        <div className="content-near-deduplication">
                            <NearList onVisibleNearList={ this.onVisibleNearList } folder={this.props.folder} selectBoxNear={ selectBoxNear } hashFile={ hashFile }/>
                        </div>
                    }
                </div>
            </div>
        )

    }
}

NearDeduplication.propTypes = {
    folder: PropTypes.object,
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id
})
export default compose(
    connect(mapStateToProps),
)(NearDeduplication)