import React from 'react'
import PropTypes from 'prop-types'

import {
  apiPrivacyGetCollections,
  apiPrivacyGetDataSourceCountForCollection,
  apiPrivacyGetAlertsForCollection,
  apiPrivacyGetTotalFilesForAlertLevels,
  apiPrivacyGetTotalObjectsForCollections
} from '../api/Privacy'

import { hasAlerts } from '../utils/Alerts'
import * as utils from '../utils/Collections'
import { updateAnItemOnCollection } from '../utils'

const CollectionsContext = React.createContext()

class CollectionsProvider extends React.Component {
  state = {
    allCollections: [],
    collections: [],
    isFetchingCollections: true,
    searchedTerm: ''
  }

  getCollections = () => {
    if (this.state.collections.length > 0) return

    apiPrivacyGetCollections()
      .then((response) => {
        const collections = response.data.value.categories.category
        const newCollections = utils.mappedCollectionsWithAlerts(collections)

        const firstPageForCollections = collections.slice(0, 12)
        for (const collection of firstPageForCollections) {
          this.getInformationForOneCollection(collection.id)
        }

        this.setState({
          allCollections: newCollections,
          collections: newCollections,
          isFetchingCollections: false
        })
      })
  }

  getInformationForOneCollection = (id) => {
    this.getInformation(id)
    this.getAlerts(id)
    this.getDataSourceCount(id)
  }

  getInformation = (id) => {
    apiPrivacyGetTotalObjectsForCollections(id)
      .then((response) => {
        const newInformation = {
          isFetchingInfo: false,
          totalObjects: response.data.pagination.total
        }
        const newCollections = updateAnItemOnCollection(this.state.collections, id, newInformation)
        this.updateCollectionsConsideringSearchedTerm(newCollections)
      })
  }

  getAlerts = (id) => {
    apiPrivacyGetAlertsForCollection(id, 'type', 'alert')
      .then((response) => {
        if (hasAlerts(response)) {
          let alert = response.data.value.categories.category
          if (!(alert instanceof Array)) alert = [alert]
          const alerts = utils.startEmptyAlertLevelsOnCollection(alert)

          this.getTotalFilesForAlert(alerts, alert[0].taxonomy, id)
        } else {
        // Remove the loader for the collection
          const newInformation = { isFetchingTotalAlerts: false }
          const newCollections = updateAnItemOnCollection(this.state.collections, id, newInformation)
        
          this.updateCollectionsConsideringSearchedTerm(newCollections)
        }
      })
  }

  getTotalFilesForAlert = (alerts, taxonomy, collectionId) => {
    if (alerts.high.length > 0) this.getTotalFilesForAlertLevels(alerts, 'high', taxonomy, collectionId)
    if (alerts.medium.length > 0) this.getTotalFilesForAlertLevels(alerts, 'medium', taxonomy, collectionId)
    if (alerts.low.length > 0) this.getTotalFilesForAlertLevels(alerts, 'low', taxonomy, collectionId)

    // We don't have any alerts on this collection, so remove the loading
    if (alerts.high.length === 0
      && alerts.medium.length === 0
      && alerts.low.length === 0) {
      const newInformation = { isFetchingTotalAlerts: false }
      const newCollections = updateAnItemOnCollection(this.state.collections, collectionId, newInformation)
      
      this.updateCollectionsConsideringSearchedTerm(newCollections)
    }
  }

  getTotalFilesForAlertLevels = (alerts, level, taxonomy, collectionId) => {
    apiPrivacyGetTotalFilesForAlertLevels(alerts[level], taxonomy)
      .then((response) => {
        const total = response.data.pagination.total
        const newCollections = this.state.collections.map((collection) => {
          if (collection.id === collectionId) return {
            ...collection,
            isFetchingTotalAlerts: false,
            totalAlerts: { ...collection.totalAlerts, [level]: total }
          }
          return collection
        })
        this.updateCollectionsConsideringSearchedTerm(newCollections)
      })
  }

  getDataSourceCount = (id) => {
    apiPrivacyGetDataSourceCountForCollection(id)
      .then((response) => {
        const newInformation = { totalDataSources: Object.keys(response.data.value).length }
        const newCollections = updateAnItemOnCollection(this.state.collections, id, newInformation)

        this.updateCollectionsConsideringSearchedTerm(newCollections)
      })
  }

  changePagination = (page) => {
    const newCollections = this.state.collections.slice((page - 1) * 12, page * 12)
    this.updateValues(newCollections)
  }

  filterCollectionsByTerm = (term) => {
    if (term === '') return this.setState({
      collections: this.state.allCollections,
      searchedTerm: ''
    })

    const newCollections = utils.findCollectionByName(this.state.allCollections, term)
    const firstPageForCollections = newCollections.slice(0, 12)
    this.updateValues(firstPageForCollections)

    this.setState({
      collections: newCollections,
      searchedTerm: term
    })
  }

  updateValues = (collections) => {
    // Check if the values were previously loaded or not
    for (const collection of collections) {
      if (collection.isFetchingInfo) this.getInformation(collection.id)
      if (collection.isFetchingTotalAlerts) this.getAlerts(collection.id)
      if (collection.totalDataSources === 0) this.getDataSourceCount(collection.id)
    }
  }

  updateCollectionsConsideringSearchedTerm = (collections) => {
    this.setState({
      allCollections: this.state.searchedTerm === '' ? collections : this.state.allCollections,
      collections
    })
  }

  updateCollections = (collections) => {
    this.setState({ allCollections: collections, collections })
  }

  render = () => {
    return (
      <CollectionsContext.Provider value={{
        changePaginationForCollections: this.changePagination,
        collections: this.state.collections,
        filterCollectionsByTerm: this.filterCollectionsByTerm,
        getCollections: this.getCollections,
        getInformationForOneCollection: this.getInformationForOneCollection,
        isFetchingCollections: this.state.isFetchingCollections,
        totalCollections: this.state.collections.length,
        totalPagesForCollections: Math.ceil(this.state.collections.length / 12),
        updateCollections: this.updateCollections
      }}>
        { this.props.children }
      </CollectionsContext.Provider>
    )
  }
}

CollectionsProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export default CollectionsProvider
export const CollectionsConsumer = CollectionsContext.Consumer