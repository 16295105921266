import React from 'react'
import { compose } from 'redux'

import DataLineBuilder from './DataLines/DataLineBuilder'
import Loading from '../../Common/Loading'

import {
  apiOspreyGetCrawlerConfiguration
} from '../../../api/OspreyAnalytics.js'

import './EditDataLine.css'

class EditDataLine extends React.Component {

  
    componentDidMount = () => {
      window.scrollTo(0, 0)

      this.setState({
        categoryId: parseInt(this.props.params.id, 10)
      }, this.initFields)
    }

    initFields = () => {
      this.loadDataline(this.state.categoryId)
    }
  
    loadDataline = (id) => {
      apiOspreyGetCrawlerConfiguration(id)
        .then((response) => {
          this.setState({
            dataline: response.data
          })
        })
    }
      
    render = () => {
      
      return (
        <div className="backofficeEditDataLine__wrapper">
          { this.state.dataline
            ? <DataLineBuilder dataline={ this.state.dataline }/>
            : <Loading/>
          }
        </div>
      )
    }
}



export default compose()(EditDataLine)