import React from 'react'
import PropTypes from 'prop-types'

import './TopicItem.css'

const TopicItem = ({ topic }) => {
  const terms = topic.terms.slice(0, 6)

  return (
    <div className="privacyDashboard__topicItem-wrapper">
      <h1 className="privacyDashboard__topicItem-title">{ topic.title } ({ (topic.weight * 100).toFixed(2) })</h1>
      <ul id="term-list">
        { terms.map((term) => (
          <TopicListItem key={ term.name } maxValue={ topic.maxValue } term={ term } />
        )) }
      </ul>
    </div>
  )
}

const TopicListItem = ({ maxValue, term }) => (
  <li className="privacyDashboard__topicItem-listItem">
    <div className="privacyDashboard__topicItem-listItem-title">
      { term.name }
    </div>
    <div className="privacyDashboard__topicItem-listItem-valueWrapper">
      <div className="privacyDashboard__topicItem-listItem-value" style={{ width: `${ (term.value * 100) / maxValue }%` }}></div>
    </div>
  </li>
)

TopicListItem.propTypes = {
  maxValue: PropTypes.number.isRequired,
  term: PropTypes.object.isRequired
}

TopicItem.propTypes = {
  topic: PropTypes.object.isRequired
}

export default TopicItem