import React from 'react'
import PropTypes from 'prop-types'

class Card extends React.Component {

  constructor() {
    super()
    this.state = {
    }
  }

  render() {
    const { title, color, icon, valuePrefix, valueNumber, valueSuffix, onSelectCard } = this.props
    return (
      title &&
      <div className= { `card card--${ color }` } onClick={ () => onSelectCard(title) }>
        <p className="card__title">{ title }</p>
        <div className="card__content">
          <span className={ `card__content__icon ${ icon }` }/>
          <p className="value">
            <span className="value__prefix">{ valuePrefix }</span>‎
            <span className="value__number">{ String(valueNumber) }</span>
            <span className="value__suffix">{ valueSuffix }</span>
          </p>
        </div>
      </div>
    )
  }
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  valuePrefix: PropTypes.string,
  valueNumber: PropTypes.number.isRequired,
  valueSuffix: PropTypes.string,
  onSelectCard: PropTypes.func.isRequired
}

export default Card