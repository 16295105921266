import React from 'react'
import { Tooltip } from 'antd'
import PaginationSmartBox from '../../Common/PaginationSmartBox'
import HeaderNavigationBox from '../../Common/HeaderNavigationBox'
import { connect } from 'react-redux'
import { deleteSelected, getSmartBoxFilesFilter } from '../../../api/SmartBox'
import { customConfirm } from '../../../utils/AssetRegister/CustomConfirm'
import LeftFoldersNavigation from '../../Common/LeftFoldersNavigation/LeftFoldersNavigation'
import {
  setSmartBoxFilesTable,
  setSmartBoxBaseDataTree,
  setSmartBoxDeletedFolder,
  setSmartBoxSelectedFiles,
  setSmartBoxBaseList,
  setSmartBoxHomeClicked,
  setSmartBoxSelectedKeys, setBoxListViewSelectedKeys, setActiveDeleteFile

} from '../../../actions/smartBoxActions'
import CustomNotificationManager from '../../Common/CustomNotificationManager'

class SmartBoxActionsHeader extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      showPopover: false,
      paginationOptions: [20, 50, 100],
      baseDataForTree: [],
      basePath: '',
      onCheckFetchFiles: false,
      onHoverViewMode: false,
      onHoverRefresh: false,
      onHoverUpload: false,
      refreshData: false,
      onHoverUploadInput: false,
      baseIdParent: '',
      baseParentBox: {},
      statusSearchApiBox: null,
      boxConnectedToWorkflow: false,
    }
    this.wrapperRef = React.createRef()
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
    if (!this.props.actionNextPreviousSmartbox) {
      this.extractBaseTree()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.smartBoxHomeClicked !== this.props.smartBoxHomeClicked){
      //this.refreshData()
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  extractBaseTree = () => {
    const parameters = {
      docFilename: '',
      docFolderPath: null,
      sort: this.props.smartBoxSortSearch.sort
    }
    getSmartBoxFilesFilter(parameters).then((response) => {
      if (response.status === 200) {
        let idParentBase = ''
        let baseParent = {}
        this.props.setSmartBoxBaseList(response.data)
        response.data && response.data.length > 0 && response.data.forEach((file) => {
          if (file.name === this.props.smartBoxParentFolderName) {
            idParentBase = file.id
            baseParent = file
          }


        })
        this.props.setSmartBoxBaseDataTree(response.data)
        this.setState({
          baseDataForTree: response.data,
          basePath: response.data.length ? response.data[0].folderPath : '',
          baseIdParent: response.data.length ? idParentBase : '',
          baseParentBox: baseParent,
          statusSearchApiBox: response.status
        })
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  mouseOver = () => {
    if (this.props.filesTable && this.props.filesTable.length === 0 && this.props.root === true) {
      this.setState({
        showPopover: true
      })
    }
  }

  mouseOut = () => {
    this.setState({
      showPopover: false
    })
  }

  handleDeleteManyFile = () => {
    const { listKeysFiles, currentPage, listKeysFilesDeleted } = this.props
    const uniqueListKeysFilesDeleted = listKeysFilesDeleted && listKeysFilesDeleted.length > 0 && listKeysFilesDeleted.filter((a, i) => listKeysFilesDeleted.findIndex((s) => a === s) === i)

    if (uniqueListKeysFilesDeleted.length && this.state.boxConnectedToWorkflow) {
      //If a user tries to delete a box that is connected to a workflow
      customConfirm({
        title: <span className="icon-v23-information"></span>,
        content: `Deletion failed.The selected box is part of a workflow.`,
        confirmButtonText: 'Okay',
        cancelButtonText: '',
        mask: true,
        maskClosable: false,
        closeHaveLogic: false,
        className: 'asset-register-modal modal-delete-error modal-delete-failed',
        centered: true,
        cancelClassName: 'cancel-button ',
        confirmClassName: 'confirm-button',
        confirmAction: () => {
        }
      })

    } else if (uniqueListKeysFilesDeleted.length) {
      customConfirm({
        title: <span className="icon-v14-delete"></span>,
        content: `Delete ${ uniqueListKeysFilesDeleted.length } item(s) ?`,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        mask: true,
        maskClosable: false,
        closeHaveLogic: false,
        className: 'asset-register-modal navigation-modal-delete',
        centered: true,
        cancelClassName: 'cancel-button',
        confirmClassName: 'confirm-button',
        confirmAction: () => {
          const parametersForDelete = {
            id: uniqueListKeysFilesDeleted
          }
          deleteSelected(parametersForDelete).then((response) => {
            if (response.status === 200) {
              // if (response.data === 'Please wait until upload finishes!') {
              //   CustomNotificationManager.info('', 'Please wait until upload finishes!', { duration: 5 })
              // }
              if (response.data.title && response.data.title.toLowerCase() === 'success') {
                CustomNotificationManager.success(response.data.message, 'Success')
              } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
                CustomNotificationManager.info(response.data.message, 'Info')
              }
                const remainingFilesOnCurrentPage = this.props.totalRecords % this.props.pageLimit - listKeysFiles.length
                if ((remainingFilesOnCurrentPage === 0) && (Math.ceil(this.props.totalRecords / this.props.pageLimit) >= currentPage)) {
                  const parameters = {
                    currentPage: currentPage-1 ? currentPage-1 : 1,
                    pageLimit: this.props.pageLimit,
                    sort: 'A-Z'
                  }
                  this.props.onPageChanged(parameters)
                } else {
                  this.props.fetchFiles()
                }
                this.props.setSmartBoxDeletedFolder(listKeysFiles)
                this.props.setSmartBoxSelectedFiles([])
                this.props.onDeleteAllFiles([])
            }
          }).catch((error) => {
            if (error?.response?.status === 504) {
              CustomNotificationManager.cleanBuffer('warning', '')
            }else{
              console.log(error)
            }
          })
        }
      })
    }
  }
  onClickCheckRefreshFetchFiles = () => {
    this.props.fetchFiles()
    this.setState({
      onCheckFetchFiles: true
    })
    //this.props.onCheckRefreshProgressBar(true)
    setTimeout(() => {
      this.setState({
        onCheckFetchFiles: false
      })
      //this.props.onCheckRefreshProgressBar(false)
    }, 5000)
  }
  onMouseEnterOnPanel(e, status) {
    switch (status) {
      case 'view-mode':
        this.setState({
          onHoverViewMode: true
        })
        break
      case 'refresh':
        this.setState({
          onHoverRefresh: true
        })
        break
      case 'upload':
        this.setState({
          onHoverUpload: true
        })
        break
      case 'upload-input':
        this.setState({
          onHoverUploadInput: true
        })
        break
      default: break
    }
  }
  onMouseLeaveOnPanel(e, status) {
    this.setState({
      onHoverViewMode: false,
      onHoverRefresh: false,
      onHoverUpload: false,
      onHoverUploadInput: false
    })
  }

  cancelRefreshData = () => {
    this.setState({
      refreshData: false
    })
  }

  refreshData = () => {
    this.setState({
      refreshData: true
    } , () => {
      this.props.setSmartBoxSelectedKeys([])
      this.props.setBoxListViewSelectedKeys([])
      this.props.setActiveDeleteFile(false)
      this.props.fetchFiles()
      this.props.refreshFilesCountOfFolders()
      this.extractBaseTree()
    })
  }

  refreshDataLeftSort = () => {
    this.setState({
      refreshData: true
    } , () => {
      this.extractBaseTree()
    })
  }

  refreshStatus = () => {
    this.props.setSmartBoxSelectedKeys([])
    this.props.setBoxListViewSelectedKeys([])
    this.props.setActiveDeleteFile(false)
    this.props.fetchFiles()
    this.props.refreshFilesCountOfFolders()
    this.extractBaseTree()
  }
  render() {
    const { paginationOptions, baseDataForTree, basePath, onHoverViewMode, onHoverRefresh, onHoverUpload, refreshData, onHoverUploadInput, baseIdParent, baseParentBox,
      statusSearchApiBox } = this.state
    const { newFolderClicked, disableNewFolder, totalFolderOnRoot, folderParent, statusCloseNavigationBar, checkCloseSmartViewForPagination, loadingOpenedFolder,
      foldersCountFiles } = this.props
    // style={{ marginRight: '0px', marginLeft: '-10px' }}
    return (
      <div ref={this.wrapperRef} className="row">
        <div className= 'col-md-2'>
          { this.props.filesTable && 
            this.props.showLeftFoldersNav && 
            this.props.smartBoxViewMode === 'grid' &&
            this.state.baseDataForTree.length &&
            
            <LeftFoldersNavigation
              clientId={this.props.clientId}
              folder={this.props.folder}
              paths={this.props.paths}
              smartBoxMenuOpen={this.state.smartBoxMenuOpen}
              content={this.props.filesTable}
              toggleFilePreview={this.props.toggleFilePreview}
              toggleNoteVisible={this.props.toggleNoteVisible}
              fetchFiles={this.props.fetchFiles}
              baseDataForTree={baseDataForTree}
              basePath={basePath}
              setFolderFromChildren={ this.props.setFolderFromChildren }
              currentFolderPath={this.props.currentFolderPath.replace(basePath, '')}
              refreshData={ refreshData }
              cancelRefreshData={ this.cancelRefreshData }
              actionNextPreviousSmartbox={ this.props.actionNextPreviousSmartbox }
            />
          }
        </div>
        {/*<div className={this.props.smartBoxViewMode === 'grid' ? 'col-md-10':'col-md-12'}>*/}
            <div className='col-md-10'>
              <div className={ this.props.root ? 'smart-box-page__action-container__header update-pagination-header-box'
                  : this.props.smartBoxViewMode === 'grid' && statusCloseNavigationBar ?
                      `smart-box-page__action-container__header__${!this.props.leftNavigationTreeShow}`
                      : `smart-box-page__action-container__header update-pagination-header-box new-pagination-header ${!this.props.leftNavigationTreeShow} ${ this.props.smartBoxViewMode}` }>

                <div className="new-folder-wrapper">
                  { this.props.root ?
                      <Tooltip title="New Box">
                <span className={ [(disableNewFolder) ? 'disabled-icon' : '', 'icon-v14-add-box-small'] }
                      onClick={ this.props.handleNewFolderClick } />
                      </Tooltip> :
                      <Tooltip title="New Folder">
                <span className={ [(newFolderClicked || disableNewFolder) ? 'disabled-icon' : '', 'icon-v14-add-folder-small'] }
                      onClick={ this.props.handleNewFolderClick } />
                      </Tooltip>
                  }
                </div>

                <PaginationSmartBox
                    className='smartbox-pagination'
                    totalRecords={ this.props.totalRecords ? this.props.totalRecords : 1 }
                    currentPage = { this.props.currentPage }
                    pageLimit={ this.props.pageLimit }
                    onPageChanged={ this.props.onPageChanged }
                    options= { paginationOptions }
                    extendNavigation= { true }
                    checkKeysFiles = { this.props.checkKeysFiles }
                    listKeysFiles = { this.props.listKeysFiles }
                    onSelectAll = { this.props.onSelectAll }
                    root = { this.props.root }
                    folderPath = { this.props.folderPath }
                    handleDeleteManyFile={ this.handleDeleteManyFile }
                    wrapperRef={ this.wrapperRef }
                    totalRecordsFinally={ this.props.totalRecords ? this.props.totalRecords : 0 }
                    totalFolderOnRoot={ totalFolderOnRoot }
                    checkCloseSmartViewForPagination={ checkCloseSmartViewForPagination }
                    extractBaseTree={ this.extractBaseTree }
                    refreshDataLeftSort={ this.refreshDataLeftSort }
                    loadingBoxPage={ this.props.loadingBoxPage }
                    filesTable={ this.props.filesTable }
                    statusResponsePagination={ this.props.statusResponsePagination }
                    statusPagination={ this.props.statusPagination }
                    statusFolderCountFiles={ this.props.statusFolderCountFiles }
                    statusThumbnailForFiles={ this.props.statusThumbnailForFiles }
                    statusProgressBarData={ this.props.statusProgressBarData }
                    statusSearchApiBox={ statusSearchApiBox }
                    listKeysFilesDeleted={ this.props.listKeysFilesDeleted }
                    refreshStatus={ this.refreshStatus }
                    onGetStatusTags={ this.props.onGetStatusTags }
                />

                <div className="switch-view-wrapper-contain switch-view-wrapper-contain-root">
                  <div className='switch-view-wrapper'>
                    <div className="view-mode" onClick={ this.props.handleChangeViewMode }>
                      { this.props.smartBoxViewMode === 'grid' ?
                          <Tooltip title="Change to list view"><span className="view-icon-wrapper"><span
                              onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'view-mode') }
                              onMouseLeave={ () => this.onMouseLeaveOnPanel() }
                              className={onHoverViewMode ? 'icon-v14-list-view-avtive' : 'icon-v14-list-view-1' }/></span></Tooltip> :
                          <Tooltip title="Change to icons view"><span className="view-icon-wrapper"><span
                              onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'view-mode') }
                              onMouseLeave={ () => this.onMouseLeaveOnPanel() }
                              className={onHoverViewMode ? 'icon-v14-grid-view-active' : 'icon-v14-grid-view-1' }/></span></Tooltip> }
                    </div>
                  </div>
                  <div className="refresh-wrapper">
                    <Tooltip title="Refresh data">
                  <span
                      onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'refresh') }
                      onMouseLeave={ () => this.onMouseLeaveOnPanel() }
                      className={onHoverRefresh ? 'icon-v14-refresh-active' : 'icon-v14-refresh-1' }
                      onClick={ this.refreshData }/>
                    </Tooltip>
                  </div>
                  <div className="upload-wrapper">
                    <Tooltip title="Upload">
                  <span
                      onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'upload') }
                      onMouseLeave={ () => this.onMouseLeaveOnPanel() }
                      className={ (this.props.root) ? 'icon-v14-upload disabled-icon' : onHoverUpload || onHoverUploadInput ? 'icon-v14-upload-active' : 'icon-v14-upload' } onClick={ this.props.customUploadFolder } />
                      { !this.props.root &&
                      <input
                          className="hidden-file-input"
                          onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'upload-input') }
                          onMouseLeave={ () => this.onMouseLeaveOnPanel() }
                          type="file"
                          directory=""
                          multiple
                          // webkitdirectory=""  (only directories)
                          onChange={this.props.customUploadFolder}
                          name="file"
                          id="file"
                      />
                      }
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>

        { /*this.props.smartBoxViewMode === 'grid' &&*/ !this.props.root && !statusCloseNavigationBar &&
          <div className='col-md-10'>
            <HeaderNavigationBox
              folderParent={folderParent}
              onStatusCloseNavigationBar={this.props.onStatusCloseNavigationBar}
              filesTable={this.props.filesTable}
              clientId={ this.props.clientId }
              basePath={ this.props.basePath }
              boxIdBasePath={ this.props.boxIdBasePath }
              activeItem = { 'folder-overview' }
              baseIdParent={ baseIdParent }
              baseDataForTree={ baseDataForTree }
              baseParentBox={ baseParentBox }
              fetchFiles={this.props.fetchFiles}
              loadingOpenedFolder={loadingOpenedFolder}
              foldersCountFiles={foldersCountFiles}
            />
          </div>
        }

      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  smartBoxHomeClicked: state.smartBox.smartBoxHomeClicked,
  smartBoxViewMode: state.smartBox.smartBoxViewMode,
  clientId: state.subscriptionReducer.subscription.subscription.client.id,
  smartBoxParentFolderName: state.smartBox.smartBoxParentFolderName,
  smartBoxSortSearch: state.smartBox.smartBoxSortSearch,
  leftNavigationTreeShow: state.smartBox.leftNavigationTreeShow
})

export default connect(mapStateToProps, {
  setSmartBoxFilesTable,
  setSmartBoxBaseDataTree,
  setSmartBoxDeletedFolder,
  setSmartBoxSelectedFiles,
  setSmartBoxBaseList,
  setSmartBoxHomeClicked,
  setSmartBoxSelectedKeys,
  setBoxListViewSelectedKeys,
  setActiveDeleteFile
})(SmartBoxActionsHeader)