import React from 'react'
import './NewBoxCreate.scss'
import { Button, Checkbox, Popover } from 'antd'
import {
  getAllBusinessList,
  editBusinessList, getSmartBoxAllRegexList
} from '../../../../api/SmartBox'
import { CompactPicker } from 'react-color'
import CustomNotificationManager from '../../../Common/CustomNotificationManager'
import cookie from "react-cookies";
import {compose} from "redux";
import {connect} from "react-redux";
import {setSmartBoxNewBox} from "../../../../actions/smartBoxActions";
class NewBoxCustom extends React.Component {

  constructor(props) {
    super(props)
    this.state= {
      businessData: [],
      checkedBusiness: [],
      colorPickerVisible: {},
      color: '#fff',
      regex: '',
      title: window.title,
      description: '',
      regexList: [],
      colorPickerVisibleRegular: false,
      colorPickerVisibleTerms: false,
      colorRegular: 'rgba(77, 100, 113, 1)',
      colorTerms: 'rgba(77, 100, 113, 1)',
      manualTerms: '',
      manualTermsList: [],
      selectedList: [],
      selectedListManualTerms: [],
      checkedManualTerms: [],
      checkedRegularExp: [],
      validationNameRegEx: false,
      validationRegex: false,
      businessSelectAllChecked: false
    }
  }
  componentDidMount() {
    const { listNewBoxRegExp, selectedListRegExp, colorRegular, manualTermsList, selectedListManualTerms, colorTerms } = this.props
    this.getBusinessData()
    const keysSelectedRegEx = []
    const newManualTermsList = []
    const keysSelectedTerm = []
    listNewBoxRegExp && listNewBoxRegExp.length > 0 && listNewBoxRegExp.forEach((list) => {
      selectedListRegExp && selectedListRegExp.length > 0 && selectedListRegExp.forEach((name) => {
        if (list.title === name) {
          keysSelectedRegEx.push(list.id)
        }
      })
    })
    if (colorRegular) {
      this.setState({
        colorRegular: colorRegular
      })
    }
    if (colorTerms) {
      this.setState({
        colorTerms: colorTerms
      })
    }
    manualTermsList && manualTermsList.length > 0 && manualTermsList.forEach((term, key) => {
      newManualTermsList.push({
        id: key,
        name: term
      })
      selectedListManualTerms && selectedListManualTerms.length > 0 && selectedListManualTerms.forEach((name) => {
        if (term === name) {
          keysSelectedTerm.push(key)
        }
      })
    })
    this.setState({
      regexList: listNewBoxRegExp,
      checkedRegularExp: keysSelectedRegEx,
      manualTermsList: newManualTermsList,
      checkedManualTerms: keysSelectedTerm
    }, () => {
      this.fetchRegularExpressions()
    })
  }

  onRenderStatusSelectAllDictionary = () => {
    const { checkedBusiness, businessData } = this.state
    if (checkedBusiness && businessData && checkedBusiness.length > 0 && (checkedBusiness.length === businessData.length)) {
      this.setState({
        businessSelectAllChecked: true
      })
    } else {
      this.setState({
        businessSelectAllChecked: false
      })
    }
  }

  openInDictionaryEdit = (e) => {
    e.preventDefault()
    window.open(window.location.href)
    cookie.save('openDictionaryEdit', 'openTrueDict', { path: '/', maxAge: 60*60 })
  }
  fetchRegularExpressions = () => {
    getSmartBoxAllRegexList().then((res) => {
      if (res.status === 200) {
        let regexList = []
        for (const value of Object.values(res.data)) {
          delete value.folderId
          delete value.id
          regexList.push(value)
        }
        regexList.sort((a,b) => a.title.localeCompare(b.title) )
        regexList && regexList.length > 0 && regexList.forEach((item, index) => {

          item.id = index

        })
        this.setState({ regexList })
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  getBusinessData = () => {
    const businessData= []
    const businessDataKeys= []
    getAllBusinessList().then((response) => {
      if (response.status === 200) {
        response.data.forEach((item) => {
          if (item && item.listName) {
            const temp = {}
            temp.id = item.id
            temp.name = item.listName ? item.listName : ''
            temp.description = item.description
            temp.displayTerm = item.displayTerm
            temp.colour = item.colour ? this.replaceAlpha(item.colour) : 'rgba(77, 100, 113, 1)'
            if (item.terms && item.terms.length) {
              temp.childrens= []
              item.terms.forEach((value) => {
                temp.childrens.push({ id: value.id, name: value.term })
              })
            }
            businessData.push(temp)
            return true
          }
        })
      }
      businessData && businessData.length > 0 && businessData.forEach((key) => {
        businessDataKeys.push(key.id)
      })
      this.setState({
        businessData,
        checkedBusiness: this.props.smartBoxNewBox.checkedBusiness && this.props.smartBoxNewBox.checkedBusiness.length > 0 ? this.props.smartBoxNewBox.checkedBusiness :
       this.props.statusBackBusiness && this.props.smartBoxNewBox.statusBusiness ? this.props.smartBoxNewBox.checkedBusiness : businessDataKeys
      }, () => {
        this.onRenderStatusSelectAllDictionary()
      })
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  replaceAlpha = (color) => {
    const temp = color.split(',')
    if (temp.length === 4) {
      temp[3] = ' 1)'
      color = temp.join()
    }
    return color
  }
  convertRgbaToString = ({ r, g, b, a }) => {
    return `rgba(${ r }, ${ g }, ${ b }, ${ a })`
  }
  closeAndSaveColorPicker = (color) => {
    const { businessData, selectedBusinessItem }= this.state
    let { newColor, manualColor, colorPickerModalVisible, colorPickerManualVisible }= this.state
    const temp= {}
    if (businessData.length) {
      businessData.map((item) => {
        temp[item.id]= false
        if (item.id === selectedBusinessItem) {
          item.colour = this.convertRgbaToString(color.rgb)
          const data = { id: selectedBusinessItem, name: item.name, description: item.description, term: item.displayTerm, color: item.colour, client: this.props.client }
          editBusinessList(data)
        }
        return true
      })
    }
    if (selectedBusinessItem === 0) {
      newColor = this.convertRgbaToString(color.rgb)
      colorPickerModalVisible = false
    }
    if (selectedBusinessItem === -1) {
      manualColor = this.convertRgbaToString(color.rgb)
      colorPickerManualVisible = false
      //saveManualTermsColor({color: manualColor, folderPath: folder.path })
    }
    this.setState({
      colorPickerVisible: temp,
      businessData,
      newColor,
      manualColor,
      colorPickerModalVisible,
      colorPickerManualVisible,
      businessSaveStep: 0
    })
  }
  toggleColorPickerVisible = (e, id, open) => {
    const { businessData }= this.state
    let { colorPickerModalVisible, colorPickerManualVisible }= this.state
    if (id === 0) {
      colorPickerModalVisible= !colorPickerModalVisible
      if (open) {
        colorPickerModalVisible= open
      }
    }
    if (id === -1) {
      colorPickerManualVisible= !colorPickerManualVisible
      if (open) {
        colorPickerManualVisible= open
      }
    }
    if (e) {
      e.stopPropagation()
    }
    const temp= {}
    if (businessData.length) {
      businessData.map((item) => {
        if (item.id !== id) {
          temp[item.id]= false
        }
        return true
      })
    }
    temp[id] = !this.state.colorPickerVisible[id]
    if (open) {
      temp[id] = open
    }
    this.setState({
      selectedBusinessItem: id,
      colorPickerVisible: temp,
      colorPickerLeft: e.currentTarget.getBoundingClientRect().left - 380,
      colorPickerTop: e.currentTarget.getBoundingClientRect().top + 0,
      colorPickerModalVisible,
      colorPickerManualVisible
    })

  }
  onBusinessCheckboxChange = (values) => {
    this.props.setSmartBoxNewBox(this.props.smartBoxNewBox.keysPii, values, this.props.smartBoxNewBox.selectRegexList, this.props.smartBoxNewBox.statusPii,
        true, this.props.smartBoxNewBox.statusRegEx)
    this.setState({
      checkedBusiness: values,
    }, () => {
      this.onRenderStatusSelectAllDictionary()
    })
  }
  onManualTermsCheckboxChange = (values) => {
    this.setState({
      checkedManualTerms: values,
    })
  }
  onRegularExpCheckboxChange = (values) => {
    this.setState({
      checkedRegularExp: values,
    })
  }
  selectAll = (e, type) => {
    const { businessData, manualTermsList, regexList } = this.state
    let { businessSaveStep } = this.state
    let { checkedManualTerms, checkedRegularExp, selectedList, selectedListManualTerms } = this.state
    if (type === 'business') {
      let { checkedBusiness } = this.state
      if (businessData && businessData.length) {
        if (!e.target.checked) {
          checkedBusiness = []
          this.setState({
            checkedBusiness: []
          })
        } else {
          checkedBusiness = []
          this.setState({
            checkedBusiness: []
          })
          businessData.map((item) => {
            checkedBusiness.push(item.id)
            return true
          })
        }
        businessSaveStep = 1
      }
      this.props.setSmartBoxNewBox(this.props.smartBoxNewBox.keysPii, checkedBusiness, this.props.smartBoxNewBox.selectRegexList, this.props.smartBoxNewBox.statusPii,
          true, this.props.smartBoxNewBox.statusRegEx)
      this.setState({ checkedBusiness, businessSaveStep, businessSelectAllChecked: e.target.checked })
    } else if (type === 'manual') {
      if (!e.target.checked) {
        checkedManualTerms = []
        selectedListManualTerms = []
      } else {
        manualTermsList.map((item) => {
          checkedManualTerms.push(item.id)
          selectedListManualTerms.push(item.name)
          return true
        })
      }
      this.setState({
        checkedManualTerms,
        selectedListManualTerms
      })
    } else if (type === 'terms') {
      if (!e.target.checked) {
        checkedRegularExp = []
        selectedList = []
      } else {
        regexList.map((item) => {
          checkedRegularExp.push(item.id)
          selectedList.push(item.title)
          return true
        })
      }
      this.setState({
        checkedRegularExp: checkedRegularExp,
        selectedList
      })
    }
  }
  onChange = (e, type) => {
    switch (type) {
      case 'regex-input':
        this.setState({
          regex: e.target.value
        })
        break
      case 'regex-name':
        this.setState({
          title: e.target.value
        })
        break
      case 'regex-description':
        this.setState({
          description: e.target.value
        })
        break
      case 'custom-terms-input':
        this.setState({
          manualTerms: e.target.value
        })
        break
      default:
        break
    }
  }
  stop = (e) => {
    e.stopPropagation()
    e.preventDefault()
  }
  openColorPickerVisible = (e) => {
    this.stop(e)
    this.setState({ colorPickerVisibleRegular: true })
  }
  closeAndSaveColorPickerRegular = (color, e) => {
    this.stop(e)
    const convertedColor = this.convertRgbaToString(color.rgb)
    this.setState({ colorRegular: convertedColor, colorPickerVisibleRegular: false })
  }
  renderPopOver = () => {
    const { colorRegular, colorPickerVisibleRegular } = this.state
    const renderColorPicker = (
      <React.Fragment>
        <div className="business-term-color-picker">
          <CompactPicker
            className="color-picker-a"
            colors={['rgba(107, 91, 149, 1)', 'rgba(254, 178, 54, 1)', 'rgba(214, 65, 97, 1)',
              'rgba(255, 123, 37, 1)', 'rgba(75, 195, 193, 1)', 'rgba(194, 194, 71, 1)', 'rgba(181, 231, 160, 1)',
              'rgba(191, 108, 64, 1)', 'rgba(62, 68, 68, 1)', 'rgba(130, 183, 75, 1)', 'rgba(64, 93, 39, 1)',
              'rgba(193, 148, 106, 1)', 'rgba(60, 136, 180, 1)', 'rgba(200, 100, 91, 1)', 'rgba(238, 162, 154, 1)',
              'rgba(201, 76, 76, 1)', 'rgba(124, 108, 6, 1)', 'rgba(15, 195, 71, 1)', 'rgba(231, 82, 114, 1)',
              'rgba(238, 140, 54, 1)', 'rgba(92, 212, 209, 1)', 'rgba(211, 211, 88, 1)', 'rgba(198, 248, 177, 1)',
              'rgba(192, 125, 81, 1)', 'rgba(77, 100, 113, 1)', 'rgba(237, 226, 220, 1)', 'rgba(132, 206, 235, 1)']}
            disableAlpha={true}
            color={colorRegular}
            onChange={(colorRegular, e) => this.closeAndSaveColorPickerRegular(colorRegular, e)}
          />
        </div>
      </React.Fragment>
    )
    return (
      <Popover content={renderColorPicker} trigger="click" visible={ colorPickerVisibleRegular } >
        <span className="regular-selected-color" style={{ backgroundColor: colorRegular }} onClick={ (e) => this.openColorPickerVisible(e) } />
      </Popover>
    )
  }
  closeAndSaveColorPickerTerms = (color, e) => {
    this.stop(e)
    const convertedColor = this.convertRgbaToString(color.rgb)
    this.setState({ colorTerms: convertedColor, colorPickerVisibleTerms: false })
  }
  openColorPickerVisibleTerms = (e) => {
    this.stop(e)
    this.setState({ colorPickerVisibleTerms: true })
  }
  renderPopOverTerms = () => {
    const { colorTerms, colorPickerVisibleTerms } = this.state
    const renderColorPicker = (
      <React.Fragment>
        <div className="business-term-color-picker">
          <CompactPicker
            className="color-picker-a"
            colors={['rgba(107, 91, 149, 1)', 'rgba(254, 178, 54, 1)', 'rgba(214, 65, 97, 1)',
              'rgba(255, 123, 37, 1)', 'rgba(75, 195, 193, 1)', 'rgba(194, 194, 71, 1)', 'rgba(181, 231, 160, 1)',
              'rgba(191, 108, 64, 1)', 'rgba(62, 68, 68, 1)', 'rgba(130, 183, 75, 1)', 'rgba(64, 93, 39, 1)',
              'rgba(193, 148, 106, 1)', 'rgba(60, 136, 180, 1)', 'rgba(200, 100, 91, 1)', 'rgba(238, 162, 154, 1)',
              'rgba(201, 76, 76, 1)', 'rgba(124, 108, 6, 1)', 'rgba(15, 195, 71, 1)', 'rgba(231, 82, 114, 1)',
              'rgba(238, 140, 54, 1)', 'rgba(92, 212, 209, 1)', 'rgba(211, 211, 88, 1)', 'rgba(198, 248, 177, 1)',
              'rgba(192, 125, 81, 1)', 'rgba(77, 100, 113, 1)', 'rgba(237, 226, 220, 1)', 'rgba(132, 206, 235, 1)']}
            disableAlpha={true}
            color={colorTerms}
            onChange={(colorTerms, e) => this.closeAndSaveColorPickerTerms(colorTerms, e)}
          />
        </div>
      </React.Fragment>
    )
    return (
      <Popover content={renderColorPicker} trigger="click" visible={ colorPickerVisibleTerms } >
        <span className="regular-selected-color" style={{ backgroundColor: colorTerms }} onClick={ (e) => this.openColorPickerVisibleTerms(e) } />
      </Popover>
    )
  }
  selectItem = (e, id) => {
    const { selectedList } = this.state
    if (e.target.checked) {
      selectedList.push(id)
    } else {
      let selectedKey = -1
      if (selectedList.includes(id)) {
        selectedList.map((item, key) => {
          if (item === id) {
            selectedKey = key
          }
          return true
        })
      }
      if (selectedKey >= 0) {
        selectedList.splice(selectedKey, 1)
      }
    }
    this.setState({ selectedList })
  }
  selectItemManualTerms = (e, id) => {
    const { selectedListManualTerms } = this.state
    if (e.target.checked) {
      selectedListManualTerms.push(id)
    }
    this.setState({ selectedListManualTerms })
  }

  saveRegularExpression = () => {
    const { regex, title, description, regexList } = this.state
    const addRegexList = regexList
    let validationName = false
    let validationReg = false
    regexList && regexList.length > 0 && regexList.forEach((reg) => {
      if (reg.title === title) {
        validationName = true
      } else if (reg.regex === regex) {
        validationReg = true
      }
    })
    if (validationName) {
      this.setState({
        validationNameRegEx: true,
        validationRegex: false
      })
    } else if (validationReg) {
      this.setState({
        validationRegex: true,
        validationNameRegEx: false
      })
    } else {
      addRegexList.push({
        title: title,
        description: description,
        regex: regex,
        id: regexList.length
      })
      this.setState({
        regexList: addRegexList,
        validationNameRegEx: false,
        validationRegex: false
      }, () => {
        this.setState({
          regex: '',
          title: '',
          description: '',
        })
      })
    }
  }
  saveCustomTerms = () => {
    const { manualTerms, manualTermsList } = this.state
    const addmManualTerms = manualTermsList
    if (manualTerms) {
      addmManualTerms.push({
        name: manualTerms,
        id: manualTermsList.length
      })
      this.setState({
        manualTermsList: addmManualTerms,
        manualTerms: ''
      })
    } else {
      CustomNotificationManager.warning('Please insert a manual term')
    }
  }
  onCreateBoxOrNextPage = (e, status) => {
    const { checkedBusiness, colorTerms, manualTermsList, selectedListManualTerms } = this.state
    const manualList = []
    manualTermsList && manualTermsList.length > 0 && manualTermsList.forEach((name) => {
      manualList.push(name.name)
    })
    this.props.onCreateBoxOrNextPage(e, status)
    this.props.onGetValuesCustom(checkedBusiness, colorTerms, manualList, selectedListManualTerms)
  }
  handleBackCustom = (e, status) => {
    this.props.handleCancelBackPage(e, status)
  }
  render() {
    const { businessData, checkedBusiness, colorPickerVisible, color, businessSelectAllChecked } = this.state
    //const allowAdd = title && description && regex
    const renderColorPicker = (
      <React.Fragment>
        <div className="business-term-color-picker">
          <CompactPicker
            className="color-picker-a"
            colors={['rgba(107, 91, 149, 1)', 'rgba(254, 178, 54, 1)', 'rgba(214, 65, 97, 1)',
              'rgba(255, 123, 37, 1)', 'rgba(75, 195, 193, 1)', 'rgba(194, 194, 71, 1)', 'rgba(181, 231, 160, 1)',
              'rgba(191, 108, 64, 1)', 'rgba(62, 68, 68, 1)', 'rgba(130, 183, 75, 1)', 'rgba(64, 93, 39, 1)',
              'rgba(193, 148, 106, 1)', 'rgba(60, 136, 180, 1)', 'rgba(200, 100, 91, 1)', 'rgba(238, 162, 154, 1)',
              'rgba(201, 76, 76, 1)', 'rgba(124, 108, 6, 1)', 'rgba(15, 195, 71, 1)', 'rgba(231, 82, 114, 1)',
              'rgba(238, 140, 54, 1)', 'rgba(92, 212, 209, 1)', 'rgba(211, 211, 88, 1)', 'rgba(198, 248, 177, 1)',
              'rgba(192, 125, 81, 1)', 'rgba(77, 100, 113, 1)', 'rgba(237, 226, 220, 1)', 'rgba(132, 206, 235, 1)']}
            disableAlpha={true}
            color={color}
            onChange={(color) => this.closeAndSaveColorPicker(color)}
          />
        </div>
      </React.Fragment>
    )
    return (
      <div className='box-setup-custom'>
        <div className="header-box-custom">
          <span className="title-custom">
              AI configuration
          </span>
          {/*<span className="description-custom">*/}
          {/*    Add customised search criteria.*/}
          {/*</span>*/}
        </div>
        <div className="box-setup-custom__content box-setup-custom__content-update">
          <div className="business-terms-setup business-terms-setup-dictionary">
            <div className="title-box-setup">Dictionaries</div>
            <div className="content-business-configuration">
              <div className="row">
                <div className="col-md-3">
                  <div className="select-all-dictionaries">
                  <Checkbox
                      className={ checkedBusiness && businessData && checkedBusiness.length > 0 && (checkedBusiness.length < businessData.length) ?
                      "select-all-checkbox select-all-checkbox-child" :
                      checkedBusiness && businessData && checkedBusiness.length > 0 && (checkedBusiness.length === businessData.length) ? 'select-all-checkbox select-all-checkbox-finish' : 'select-all-checkbox' }
                            checked={ businessSelectAllChecked }
                            onChange={(e) => this.selectAll(e, 'business')}
                    disabled={ businessData.length === 0 }>Select all</Checkbox>
                    <span onClick={ (e) => this.openInDictionaryEdit(e) } className='icon-v18-edit'></span>
                  </div>
                  <div className="tree-business-terms">
                    <Checkbox.Group value={ checkedBusiness } onChange={ this.onBusinessCheckboxChange } >
                      { businessData.map((item, key) => {
                        return (
                          <div key={`div-${ item.id }`} className="line-item">
                            <Checkbox className="business-terms-page__list-container__content__term radio-style" key={item.id} value={item.id}>
                              <span className="business-terms-item">{item.name}</span>
                            </Checkbox>
                            <Popover content={renderColorPicker} trigger="click" visible={ colorPickerVisible[item.id] }>
                              <div className="icon-change-color" key={`i-${ item.id }`} style={{ backgroundColor: item.colour }} onClick={(e) => this.toggleColorPickerVisible(e, item.id)}/>
                            </Popover>
                          </div>
                        )
                      })
                      }
                    </Checkbox.Group>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*<div className="business-terms-setup regular-expressions-setup">*/}
          {/*  <div className="title-box-setup">Regular expressions</div>*/}
          {/*  <div className="content-business-configuration">*/}
          {/*    <div className="row">*/}
          {/*      <div className="col-md-3">*/}
          {/*        <div className="content-inputs">*/}
          {/*          <div className="insert-regular-expression">*/}
          {/*            <Input className="input-insert-expression" onChange={ (e) => this.onChange(e, 'regex-name') } placeholder="Name" value={ title }/>*/}
          {/*            { validationNameRegEx &&*/}
          {/*                <span className="validation-reg-ex">name already exist</span>*/}
          {/*            }*/}
          {/*          </div>*/}
          {/*          <div className="insert-regular-expression">*/}
          {/*            <Input className="input-insert-expression" onChange={ (e) => this.onChange(e, 'regex-description') } placeholder="Description" value={ description }/>*/}
          {/*          </div>*/}
          {/*          <div className="insert-regular-expression">*/}
          {/*            <Input className="input-insert-expression" onChange={ (e) => this.onChange(e, 'regex-input') } placeholder="/ insert regular expression" value={ regex }/>*/}
          {/*            <div className="add-regular-expression">*/}
          {/*              <button onClick={ this.saveRegularExpression } className="icon-add-b" disabled={ !allowAdd } style={{ cursor: allowAdd ? 'pointer': 'not-allowed' }}/>*/}
          {/*            </div>*/}
          {/*            { validationRegex &&*/}
          {/*            <span className="validation-reg-ex">regex already exist</span>*/}
          {/*            }*/}
          {/*          </div>*/}
          {/*        </div>*/}
          {/*        <div className="content-list-setup">*/}
          {/*          <div className="select-all-regular">*/}
          {/*            <Checkbox className="select-all-checkbox"*/}
          {/*              disabled={ regexList.length === 0 }*/}
          {/*              onChange={(e) => this.selectAll(e, 'terms')}>Select all</Checkbox>*/}
          {/*            {*/}
          {/*              this.renderPopOver()*/}
          {/*            }*/}
          {/*          </div>*/}
          {/*          <div className="content-list-view-add">*/}
          {/*            <Checkbox.Group value={ checkedRegularExp } onChange={ this.onRegularExpCheckboxChange } >*/}
          {/*              {*/}
          {/*                regexList && regexList.length > 0 && regexList.map((regex, key) => {*/}
          {/*                  return (*/}
          {/*                    <Checkbox*/}
          {/*                      className= "radio-style"*/}
          {/*                      key= { key }*/}
          {/*                      value={ key }*/}
          {/*                      onChange={ (e) => this.selectItem(e, regex.title) }*/}
          {/*                      //onClick= { (e) => this.stop(e)}*/}
          {/*                    >*/}
          {/*                      {`${ regex.title }`}*/}
          {/*                    </Checkbox>*/}
          {/*                  )*/}
          {/*                })*/}
          {/*              }*/}
          {/*            </Checkbox.Group>*/}
          {/*          </div>*/}
          {/*        </div>*/}

          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<div className="business-terms-setup regular-expressions-setup">*/}
          {/*  <div className="title-box-setup">Custom terms</div>*/}
          {/*  <div className="content-business-configuration">*/}
          {/*    <div className="row">*/}
          {/*      <div className="col-md-3">*/}
          {/*        <div className="content-inputs">*/}
          {/*          <div className="insert-regular-expression">*/}
          {/*            <Input value={ manualTerms } className="input-insert-expression" onChange={ (e) => this.onChange(e, 'custom-terms-input') } placeholder="Custom wording"/>*/}
          {/*            <div className="add-regular-expression">*/}
          {/*              <button onClick={ this.saveCustomTerms } className="icon-add-b" style={{ cursor: !allowAdd ? 'pointer': 'not-allowed' }}/>*/}
          {/*            </div>*/}
          {/*          </div>*/}
          {/*        </div>*/}
          {/*        <div className="content-list-setup">*/}
          {/*          <div className="select-all-regular">*/}
          {/*            <Checkbox onChange={(e) => this.selectAll(e, 'manual')} className="select-all-checkbox"*/}
          {/*              disabled={ manualTermsList.length === 0 }>Select all</Checkbox>*/}
          {/*            {*/}
          {/*              this.renderPopOverTerms()*/}
          {/*            }*/}
          {/*          </div>*/}
          {/*          <div className="content-list-view-add">*/}
          {/*            <Checkbox.Group value={ checkedManualTerms }*/}
          {/*              onChange={ this.onManualTermsCheckboxChange } >*/}
          {/*              {*/}
          {/*                manualTermsList && manualTermsList.length > 0 && manualTermsList.map((term, key) => {*/}
          {/*                  return (*/}
          {/*                    <Checkbox*/}
          {/*                      className= "radio-style"*/}
          {/*                      key= { key }*/}
          {/*                      value={ key }*/}
          {/*                      onChange={ (e) => this.selectItemManualTerms(e, term.name) }*/}
          {/*                    // onClick= { (e) => this.stop(e)}*/}
          {/*                    >*/}
          {/*                      {`${ term.name }`}*/}
          {/*                    </Checkbox>*/}
          {/*                  )*/}
          {/*                })*/}
          {/*              }*/}
          {/*            </Checkbox.Group>*/}
          {/*          </div>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
        <div className="footer-buttons-action-new-box custom-buttons">
          <Button className="action-cancel-new-box" onClick={ (e) => this.handleBackCustom(e, 'back-custom')}>
            Back
          </Button>
          <div className="statistics-active-new-box">
            <span className="dot-new-box"></span>
            <span className={ this.props.activePage === 'custom' ? 'dot-new-box dot-new-box active-dot-new-box' : "dot-new-box" }></span>
            <span className="dot-new-box"></span>
            <span className="dot-new-box"></span>
          </div>
          <Button className="action-create-new-box" onClick={ (e) => this.onCreateBoxOrNextPage(e, 'new-box-custom') }
          >
            Next
          </Button>
        </div>
      </div>
    )
  }
}

NewBoxCustom.propTypes = {
}
const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id,
  userId: state.userReducer.user.id,
  smartBoxNewBox: state.smartBox.smartBoxNewBox,
})
export default compose(
    connect(mapStateToProps, {
      setSmartBoxNewBox
    }),
)(NewBoxCustom)