import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Accordion from '../../Common/Accordion'
import Loading from '../../Common/Loading'
import Content from './Content'

import WithDataModels from '../HOC/WithDataModels'

import './AlertItem.css'

class AlertItem extends React.Component {
  toggleClick = () => {
    const { accordion, index } = this.props
    this.props.toggleAccordion(accordion, index)
  }

  render = () => {
    const { alert, accordion, index, showModal } = this.props

    return (
      <Accordion
        opened={ accordion[index] }
        header={
          <div className={ accordion[index] ? 'privacyAlertItem__headerOpened' : 'privacyAlertItem__header' }>
            <div className={ `privacyAlertItem__content ${ alertLevelClass(alert) }` }>
              { alert.isFetchingInfo ?
                <div className="privacyAlertItem__contentSmallItem privacyAlertItem__loading">
                  <Loading />
                </div> :
                <div className="privacyAlertItem__contentSmallItem privacyAlertItem__info">
                  <strong>{ alert.totalObjects }</strong> { alert.totalObjects === 1 ? 'object' : 'objects' }
                </div>
              }
              { alert.collection &&
                <div className="privacyAlertItem__contentSmallItem">
                  <h2 className="privacyAlertItem__contentTitle">Collection</h2>
                  <p className="privacyAlertItem__contentText">{ alert.collection }</p>
                </div>
              }
              <div className="privacyAlertItem__contentSmallItem">
                <h2 className="privacyAlertItem__contentTitle">Alert Id</h2>
                <p className="privacyAlertItem__contentText">{ alert.id }</p>
              </div>
              <div className="privacyAlertItem__contentSmallItem">
                <h2 className="privacyAlertItem__contentTitle">Alert Name</h2>
                <p className="privacyAlertItem__contentText">{ alert.name }</p>
              </div>
              { (alert.conflicts && alert.conflicts.length > 0) &&
                <div className="privacyAlertItem__contentBigItem">  
                  { this.props.isFetchingDataModels
                    ? <Loading />
                    :
                    <React.Fragment>
                      { showConflicts(alert.conflicts, this.props.dataModels) &&
                        <React.Fragment>
                          <h2 className="privacyAlertItem__contentTitle">Conflicts</h2>
                          <p className="privacyAlertItem__contentText">{ showConflicts(alert.conflicts, this.props.dataModels) }</p>
                        </React.Fragment>
                      }
                    </React.Fragment>
                  }
                </div>
              }
              <Link className="privacyAlertItem__button" to={ `/privacy/alert/edit/${ alert.id }` }>
                Review
              </Link>
            </div>
            <div 
              className={ accordion[index] ? 'privacyAlertItem__toggleHeaderOpened' : 'privacyAlertItem__toggleHeader' }
              onClick={ this.toggleClick }>
            </div>
          </div>
        }
        content={ <Content alert={ alert } showModal={ showModal } /> }
      />
    )
  }
}

const alertLevelClass = (alert) => {
  if (alert.level === 'high') return 'privacyAlertItem__contentHigh'
  if (alert.level === 'low') return 'privacyAlertItem__contentLow'
  return 'privacyAlertItem__contentMedium'
}

const showConflicts = (conflicts, models) => {
  const conflictsName = []

  if (conflicts instanceof Array) {
    // Extract only the first part, because the pattern is like this "-doc_date_created:[* TO NOW-1WEEK]"
    for (const conflict of conflicts) {
      conflictsName.push(conflict.split(':')[0].replace('-', ''))
    }
    
    // Use the models to filter the names on conflicts, for example: doc_date_created
    const names = conflictsName.map((conflict) => models
      .filter((model) => model.id === conflict)
      .map((model) => model.name))
  
    return names.join(', ')
  }
  return null
}

AlertItem.propTypes = {
  accordion: PropTypes.object.isRequired,
  alert: PropTypes.object.isRequired,
  dataModels: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  isFetchingDataModels: PropTypes.bool.isRequired,
  toggleAccordion: PropTypes.func.isRequired
}

export default WithDataModels(AlertItem)