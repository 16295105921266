import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import '../DisclosureWorkflow.scss'
import {Select, Steps, Checkbox, Radio, Icon, Button, Spin, Tooltip} from "antd";
import {
    apiGetDisclosureFirstStepAndFiles,
    apiGetTagDocumentClient,
    apiPackFilesDisclosureFirstStep,
    apiSaveFilesDisclosureFirstStep, downloadExportLoadedFilesDisclosure, downloadZipBox
} from "../../../../../../api/SmartBox";
import CustomNotificationManager from "../../../../../Common/CustomNotificationManager";
import Loading from '../../../../../Common/Loading'
const { Option } = Select;
const { Step } = Steps;
import moment from "moment";
export class PackingDisclosure extends Component {

    state = {
        includeROPA: false,
        includeBoxData: false,
        customerStatus: [],
        valueStatusFiles: '',
        valueTypeFiles: '',
        formatOptionType: '',
        packAllFiles: false,
        packFilesOnlyWithTag: false,
        valueFormatFiles: '',
        typeValueFile: '',
        checkAudit: false,
        packRedactedFiles: false,
        packOriginalFiles: false,
        packAuditFiles: false,
        selectedTag: '',
        packFilesAsOneSinglePDF: false,
        packFilesMultiplePDF: false,
        packFilesRowVersion: false,
        packFilesRowEmails: false,
        packEmailFilesRowVersion: false,
        listFilesDisclosure: [],
        disabledPack: false,
        loadingPackingCompleted: null,
        dateDisclosure: '',
        loadingFilesDisclosure: false,
        removeFolderStructure: false,
        renameFilesAndFolders: false,
        checkedValues: [],
        ticketEncodedId: null,
        packingCompleted: false,
       // customerStatusRawEmails: ['EML', 'MSG', 'PST', 'MBOX', 'OLM'],
        customerStatusRawEmails: ['PST', 'MBOX', 'EML', 'MSG'],
        loadingButtonUpdate: false,
    }
    componentDidMount() {
        this.getTagDocumentClientExport()
        this.onGetDisclosureFirstStepAndFiles()
    }
    getTagDocumentClientExport = () => {
        apiGetTagDocumentClient(this.props.clientId).then((response) => {
            this.setState({
                customerStatus: response.data
            })
        })
    }
    onGetDisclosureFirstStepAndFiles = () => {
        const { selectEditTickets } = this.props
       const ticketId = selectEditTickets.ticketId
        apiGetDisclosureFirstStepAndFiles(ticketId).then((response) => {
            this.props.onGetDisclosureFirstStepAndFiles(response.data)
            if (response.data && response.data.packFilesDisclosureFirstStepDTO && Object.keys(response.data.packFilesDisclosureFirstStepDTO) && Object.keys(response.data.packFilesDisclosureFirstStepDTO).length > 0) {
                const optionPack = response.data.packFilesDisclosureFirstStepDTO
                const checkedValues2 = []
                if (response.data.packFilesDisclosureFirstStepDTO.removeFolderStructure) {
                    checkedValues2.push('removeFolderStructure')
                    this.setState({
                        removeFolderStructure: true
                    })
                }
                if (response.data.packFilesDisclosureFirstStepDTO.renameFilesAndFolders) {
                    checkedValues2.push('renameFilesAndFolders')
                    this.setState({
                        renameFilesAndFolders: true
                    })
                }
                this.setState({
                    includeROPA: response.data.packFilesDisclosureFirstStepDTO.includeROPA,
                    packingCompleted: response.data.packingCompleted,
                    ticketEncodedId: response.data.filesToShow.ticketEncodedId,
                    packAllFiles: response.data.packFilesDisclosureFirstStepDTO.packAllFiles,
                    packAuditFiles: response.data.packFilesDisclosureFirstStepDTO.packAuditFiles,
                    packFilesAsOneSinglePDF: response.data.packFilesDisclosureFirstStepDTO.packFilesAsOneSinglePDF,
                    packFilesMultiplePDF: response.data.packFilesDisclosureFirstStepDTO.packFilesMultiplePDF,
                    packFilesOnlyWithTag: response.data.packFilesDisclosureFirstStepDTO.packFilesOnlyWithTag,
                    packFilesRowVersion: response.data.packFilesDisclosureFirstStepDTO.packFilesRowVersion,
                    packEmailFilesRowVersion: response.data.packFilesDisclosureFirstStepDTO.packEmailFilesRowVersion,
                    packOriginalFiles: response.data.packFilesDisclosureFirstStepDTO.packOriginalFiles,
                    packRedactedFiles: response.data.packFilesDisclosureFirstStepDTO.packRedactedFiles,
                    selectedTag: response.data.packFilesDisclosureFirstStepDTO.selectedTag,
                    valueStatusFiles: response.data.packFilesDisclosureFirstStepDTO.selectedTag,
                    ticketId: response.data.packFilesDisclosureFirstStepDTO.ticketId,
                    valueTypeFiles: optionPack.packAllFiles ? 'all files' : optionPack.packFilesOnlyWithTag ? 'only files tag' : '',
                    typeValueFile: optionPack.packRedactedFiles ? 'redacted files' : optionPack.packOriginalFiles ? 'original files' : optionPack.packAuditFiles ? 'audit files' : '',
                    valueFormatFiles: optionPack.packFilesAsOneSinglePDF ? 'combine files' : optionPack.packFilesMultiplePDF ? 'keep files' : optionPack.packFilesRowVersion ? 'raw email files' :
                        optionPack.packEmailFilesRowVersion ? 'raw email files' : '',
                    checkedValues: checkedValues2
                })
            }
            if (response.data && response.data.filesToShow && Object.keys(response.data.filesToShow) && Object.keys(response.data.filesToShow).length > 0) {
                this.setState({
                    listFilesDisclosure: response.data.filesToShow,
                    dateDisclosure: response.data.filesToShow.date,
                })
            }
           this.setState({
               loadingPackingCompleted: response.data.packingCompleted,
           })
        })
    }
    onChangeCheckBoxRopa = (checked) => {
        this.setState({
            includeROPA: checked.target.checked
        })

    }
    onChangeCheckBoxData = (checked) => {
        this.setState({
            includeBoxData: checked.target.checked
        })
    }
    onChangeTypeFiles(e) {
        if (e.target.value === 'all files') {
            this.setState({
                valueStatusFiles: '',
                packAllFiles: true,
                packFilesOnlyWithTag: false,
            })
        }
        if (e.target.value === 'only files tag') {
            this.setState({
                packAllFiles: false,
                packFilesOnlyWithTag: true,
            })
        }
        this.setState({
            valueTypeFiles: e.target.value
        })
    }
    onChangeStatusFile(e) {
        this.setState({
            valueStatusFiles: e,
            selectedTag: e,
        })
    }
    onChangeFormatOptionType(e) {
        this.setState({
            formatOptionType: e,
        })
    }
    onChangeFileTypeSelected(e) {
        const { valueFormatFiles } = this.state
        this.setState({
            typeValueFile: e.target.value
        })
        if (e.target.value === 'redacted files' && (valueFormatFiles === '' || valueFormatFiles === 'raw files' || valueFormatFiles === 'raw email files')) {
            this.setState({
                valueFormatFiles: ''
            })
        }
        switch (e.target.value) {
            case 'redacted files':
                this.setState({
                    packRedactedFiles: true,
                    packOriginalFiles: false,
                    packAuditFiles: false,
                })
                break
            case 'original files':
                this.setState({
                    packRedactedFiles: false,
                    packOriginalFiles: true,
                    packAuditFiles: false,
                })
                break
            case 'audit files':
                this.setState({
                    packRedactedFiles: false,
                    packOriginalFiles: false,
                    packAuditFiles: true,
                })
                break
            default: break;
        }
    }
    onChangeFormatFiles(e) {
        this.setState({
            valueFormatFiles: e.target.value
        })
        switch (e.target.value) {
            case "combine files":
                this.setState({
                    packFilesAsOneSinglePDF: true,
                    packFilesMultiplePDF: false,
                    packFilesRowVersion: false,
                    packFilesRowEmails: false,
                    packEmailFilesRowVersion: false,
                    formatOptionType: '',
                })
                break
            case "keep files":
                this.setState({
                    packFilesAsOneSinglePDF: false,
                    packFilesMultiplePDF: true,
                    packFilesRowVersion: false,
                    packFilesRowEmails: false,
                    packEmailFilesRowVersion: false,
                    formatOptionType: '',
                })
                break
            case "raw files":
                this.setState({
                    packFilesAsOneSinglePDF: false,
                    packFilesMultiplePDF: false,
                    packFilesRowEmails: false,
                    packEmailFilesRowVersion: false,
                    packFilesRowVersion: true,
                    formatOptionType: '',
                })
                break
            case "raw email files":
                this.setState({
                    packFilesAsOneSinglePDF: false,
                    packFilesMultiplePDF: false,
                    packFilesRowEmails: false,
                    packFilesRowVersion: true,
                    packEmailFilesRowVersion: true,
                    formatOptionType: '',
                })
                break
            case "raw emails":
                this.setState({
                    packFilesAsOneSinglePDF: false,
                    packFilesMultiplePDF: false,
                    packFilesRowVersion: false,
                    packEmailFilesRowVersion: false,
                    packFilesRowEmails: true,
                })
                break
            default: break
        }
    }
    onUpdateInclude = () => {
        const { includeROPA, packAllFiles, packFilesOnlyWithTag, selectedTag, packRedactedFiles, packOriginalFiles, packAuditFiles, packFilesAsOneSinglePDF,
            packFilesMultiplePDF, packFilesRowVersion, loadingFilesDisclosure, removeFolderStructure, renameFilesAndFolders, formatOptionType,
            packFilesRowEmails, packEmailFilesRowVersion } = this.state
        const { selectEditTickets } = this.props
      const packFilesDisclosureFirstStepDTO = {
          ticketId: selectEditTickets.ticketId,
          includeROPA: includeROPA,
          packAllFiles: packAllFiles,
          packFilesOnlyWithTag: packFilesOnlyWithTag,
          selectedTag: selectedTag,
          packRedactedFiles: packRedactedFiles,
          packOriginalFiles: packOriginalFiles,
          packAuditFiles: packAuditFiles,
          packFilesAsOneSinglePDF: packFilesAsOneSinglePDF,
          packFilesMultiplePDF: packFilesMultiplePDF,
          //packFilesRowVersion: packFilesRowVersion,
          packEmailFilesRowVersion: packEmailFilesRowVersion,
          packFilesRowEmails: packFilesRowEmails,
          removeFolderStructure : removeFolderStructure,
          renameFilesAndFolders : renameFilesAndFolders,
          formatOptionType : formatOptionType,
      }
        this.setState({
            loadingFilesDisclosure: true,
            loadingButtonUpdate: true,
        }, () => {
            apiSaveFilesDisclosureFirstStep(packFilesDisclosureFirstStepDTO).then((response) => {
                if (response.data.title && response.data.title.toLowerCase() === 'success') {
                    CustomNotificationManager.success(response.data.message, 'Success')
                } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
                    CustomNotificationManager.info(response.data.message, 'Info')
                }
                this.setState({
                    listFilesDisclosure: response.data,
                    ticketEncodedId: response.data.ticketEncodedId,
                    loadingFilesDisclosure: false,
                    loadingButtonUpdate: false,
                    //disabledPack: true
                })
                // if(response.data.title && response.data.title.toLowerCase() === 'info') {
                //     this.setState({
                //         disabledPack: false
                //     })
                // } else {
                //     this.setState({
                //         disabledPack: true
                //     })
                // }
            })
        })
    }
    onPackFilesDisclosureFirstStep = () => {
        const { includeROPA, packAllFiles, packFilesOnlyWithTag, selectedTag, packRedactedFiles, packOriginalFiles, packAuditFiles, packFilesAsOneSinglePDF,
            packFilesMultiplePDF, packFilesRowVersion, removeFolderStructure, renameFilesAndFolders, formatOptionType, packFilesRowEmails,
            packEmailFilesRowVersion} = this.state
        const { selectEditTickets, stepHeaderActive } = this.props
        const packFilesDisclosureFirstStepDTO = {
            ticketId: selectEditTickets.ticketId,
            workflowComponentId: stepHeaderActive.workflowComponentId,
            includeROPA: includeROPA,
            packAllFiles: packAllFiles,
            packFilesOnlyWithTag: packFilesOnlyWithTag,
            selectedTag: selectedTag,
            packRedactedFiles: packRedactedFiles,
            packOriginalFiles: packOriginalFiles,
            packAuditFiles: packAuditFiles,
            packFilesAsOneSinglePDF: packFilesAsOneSinglePDF,
            packFilesMultiplePDF: packFilesMultiplePDF,
            //packFilesRowVersion: packFilesRowVersion,
            packEmailFilesRowVersion: packEmailFilesRowVersion,
            packFilesRowEmails: packFilesRowEmails,
            removeFolderStructure : removeFolderStructure,
            renameFilesAndFolders : renameFilesAndFolders,
            formatOptionType : formatOptionType,
        }
        this.setState({
            disabledPack: true
        }, () => {
            apiPackFilesDisclosureFirstStep(packFilesDisclosureFirstStepDTO).then((response) => {
                if (response.data.title && response.data.title.toLowerCase() === 'success') {
                    CustomNotificationManager.success(response.data.message, 'Success')
                    this.props.onEndPackingDisclosure('packing')
                } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
                    CustomNotificationManager.info(response.data.message, 'Info')
                }
                this.props.onGetDisclosureStepsDetails()
                this.setState({
                    disabledPack: false
                })
            })
        })
    }
    onChangeOptionsBoxData = (checkedValues) => {
        this.setState({
            checkedValues: checkedValues
        })
        if (checkedValues.includes('removeFolderStructure')) {
            this.setState({
                removeFolderStructure: true,
                renameFilesAndFolders: false,
            })
        }
        if (checkedValues.includes('renameFilesAndFolders')) {
            this.setState({
                renameFilesAndFolders: true,
                removeFolderStructure: false,
            })
        }
        if (checkedValues.includes('renameFilesAndFolders') && checkedValues.includes('removeFolderStructure')) {
            this.setState({
                renameFilesAndFolders: true,
                removeFolderStructure: true,
            })
        }
        if (checkedValues.length === 0) {
            this.setState({
                removeFolderStructure: false,
                renameFilesAndFolders: false,
            })
        }
    }
        render() {
     const { includeROPA, includeBoxData, customerStatus, valueStatusFiles, valueTypeFiles, packAllFiles, packFilesOnlyWithTag, checkAudit,
         valueFormatFiles, typeValueFile, listFilesDisclosure, disabledPack, loadingPackingCompleted, selectedTag, packRedactedFiles, packOriginalFiles,
         packAuditFiles, packFilesAsOneSinglePDF, packFilesMultiplePDF, packFilesRowVersion, dateDisclosure, loadingFilesDisclosure,
         removeFolderStructure, renameFilesAndFolders, checkedValues, ticketEncodedId, packingCompleted, formatOptionType,
         customerStatusRawEmails, packFilesRowEmails, packEmailFilesRowVersion, loadingButtonUpdate } = this.state
            const { selectEditTickets } = this.props
        return (
            <div className="packing-disclosure">
                 <div className="packing-disclosure__include">
                   <div className="include-header">
                       <span className="include-name">Include</span>
                   </div>
                     <div className="include-content">
                       <div className="content-checkbox">
                           <Checkbox checked={ includeROPA } onChange={this.onChangeCheckBoxRopa}>ROPA</Checkbox>
                       </div>
                         <div className="content-checkbox">
                             <Checkbox checked={ includeBoxData } onChange={this.onChangeCheckBoxData}>Box data</Checkbox>
                         </div>
                         <div className="content-all-options-box-data">

                             <div className="content-files-option">
                                 <div className="title-option">Files</div>
                                 <div className="content-functionality-files-option">
                                     <div className="row">
                                         <div className="col-md-3">
                                             <Radio.Group onChange={ (e) => this.onChangeTypeFiles(e) }
                                             value={ packAllFiles ? 'all files' : packFilesOnlyWithTag ? 'only files tag' : ''}>
                                                 <Radio className="text-option" value="all files">All</Radio>
                                                 <Radio className="text-option" value="only files tag">Only with tag:
                                                 </Radio>
                                             </Radio.Group>
                                         </div>
                                     </div>
                                 </div>
                                 <div className="content-option-tag">
                                     <Select placeholder="Select tag"
                                             dropdownClassName={'dropdown-select-tag'}
                                             onChange={ (e) => this.onChangeStatusFile(e) }
                                             disabled={ valueTypeFiles === '' || valueTypeFiles === 'original files' || valueTypeFiles === 'redacted files' || valueTypeFiles === 'all files' }
                                             value={ selectedTag === '' ? 'Select tag' : selectedTag }
                                     >
                                         {
                                             customerStatus.map((item, index) => {
                                                 return (
                                                     <Option className="item-options-status-new" key={index} value={item.tagName}>
                                                         <Tooltip title={item.tagName}>
                                                         { item.icon && item.icon.includes('v211') ?
                                                             <span className={item.icon ? item.icon : 'icon-color'}></span> :
                                                             item.color ?
                                                                 <span style={{backgroundColor: item.color ? item.color : 'icon-color'}}
                                                                       className={item.icon ? item.icon : 'icon-color'}></span> :
                                                                 <span><Icon type={`${item.icon}`} /></span>
                                                         }
                                                         {item.tagName.length > 18 ? item.tagName.substring(0,12) + '...' : item.tagName }
                                                         </Tooltip>
                                                     </Option>
                                                 )
                                             })
                                         }
                                     </Select>
                                 </div>
                             </div>

                             <div className="content-files-option content-files-types">
                                 <div className="title-option">Types</div>
                                 <div className="content-functionality-files-option option-types">
                                     <div className="row">
                                         <div className="col-md-3">
                                             <Radio.Group value={ packRedactedFiles ? 'redacted files' : packOriginalFiles ? 'original files' :
                                                 packAuditFiles ? 'audit files' : ''}
                                                 onChange={ (e) => this.onChangeFileTypeSelected(e) }>
                                                 <Radio className="text-option-redact" value="redacted files">Redacted</Radio>
                                                 <Radio className="text-option-redact" value="original files">Original</Radio>
                                                 <Radio className="text-option-redact"
                                                        //disabled={ checkAudit === false }
                                                        value="audit files">Audit</Radio>
                                             </Radio.Group>
                                         </div>
                                     </div>
                                 </div>
                             </div>

                             <div className="content-files-option content-format-files">
                                 <div className="title-option">Format</div>
                                 <div className="content-functionality-files-option">
                                     <div className="row">
                                         <div className="col-md-3">
                                             <Radio.Group value={ packFilesAsOneSinglePDF ? 'combine files' : packFilesMultiplePDF ? 'keep files' :
                                                 packEmailFilesRowVersion ? 'raw email files' :
                                                 packFilesRowVersion ? 'raw email files' : packFilesRowEmails ? 'raw emails' : '' }
                                                          onChange={ (e) => this.onChangeFormatFiles(e) }>
                                                 <Radio className="text-option-file" value="combine files">One PDF</Radio>
                                                 <Radio className="text-option-file" value="keep files">Multiple PDF</Radio>
                                                 <Radio className="text-option-file" disabled={ typeValueFile === 'redacted files' || typeValueFile === 'audit files'} value="raw email files">Raw files</Radio>
                                                 {/*<Radio className="text-option-file" disabled={ typeValueFile === 'redacted files' || typeValueFile === 'audit files'} value="raw files">Raw files</Radio>*/}
                                                 {/*<Radio className="text-option-file" disabled={ typeValueFile === 'redacted files' || typeValueFile === 'audit files'} value="raw emails">Raw emails</Radio>*/}
                                             </Radio.Group>
                                         </div>
                                     </div>
                                 </div>
                                 {/*<div className="content-option-tag">*/}
                                 {/*    <Select placeholder="Select option type"*/}
                                 {/*            onChange={ (e) => this.onChangeFormatOptionType(e) }*/}
                                 {/*            disabled={ valueFormatFiles  === '' || valueFormatFiles !== 'raw emails' }*/}
                                 {/*            value={ formatOptionType === '' ? 'Select option type' : formatOptionType }*/}
                                 {/*    >*/}
                                 {/*        {*/}
                                 {/*            customerStatusRawEmails.map((item, index) => {*/}
                                 {/*                return (*/}
                                 {/*                    <Option className="item-options-status-new" key={index} value={item}>*/}
                                 {/*                        {item}*/}
                                 {/*                    </Option>*/}
                                 {/*                )*/}
                                 {/*            })*/}
                                 {/*        }*/}
                                 {/*    </Select>*/}
                                 {/*</div>*/}
                             </div>

                             <div className="content-files-option content-format-files">
                                 <div className="title-option">Options</div>
                                 <div className="content-functionality-files-option content-format-options">
                                     <div className="row">
                                         <div className="col-md-12">
                                             <Checkbox.Group onChange={this.onChangeOptionsBoxData}
                                                             className="format-options-download"
                                             value={ checkedValues }>
                                                 <Checkbox value="removeFolderStructure">Remove Folder Structure</Checkbox>
                                                 <Checkbox value="renameFilesAndFolders">Rename Files And Folders</Checkbox>
                                             </Checkbox.Group>
                                         </div>
                                     </div>
                                 </div>
                             </div>

                         </div>
                     </div>

                     <div className="include-button">
                       <Button onClick={(e) => this.onUpdateInclude(e)} className="update-include-button"
                       disabled={((!includeROPA && !includeBoxData) || valueTypeFiles === '' || valueFormatFiles === '' || typeValueFile === '' ||
                           (valueTypeFiles === 'only files tag' && valueStatusFiles === '') ||
                           (valueFormatFiles === 'raw emails' && formatOptionType === ''))
                       }>Update</Button>
                     </div>
                 </div>
                <div className="packing-disclosure__wrap-it-up">
                    <div className="wrap-it-up-header">
                        <span className="wrap-it-up-name">Wrap it up</span>
                        {/*<span className="icon-v22-download-1"></span>*/}
                        { packingCompleted && ticketEncodedId ?
                            <a href={downloadExportLoadedFilesDisclosure(ticketEncodedId)}
                               className='icon-v22-download-1'
                            ></a> :
                            <a
                               className='icon-v22-download-1 disabled-download-1'
                            ></a>
                        }
                    </div>
                    { loadingFilesDisclosure ?
                        <div className="content-loading-disclosure"><Spin tip='Loading'/></div>
                        :
                        <div className="wrap-it-up-content">
                            <div className="wrap-it-up-time">
                                <span
                                    className="time-name">{dateDisclosure ? dateDisclosure : moment().format('HH:MM, DD.MM.YYYY')}</span>
                            </div>
                            <div className='wrap-it-up-list-ropa'>
                                {listFilesDisclosure.ropaFile && Object.keys(listFilesDisclosure.ropaFile) && Object.keys(listFilesDisclosure.ropaFile).length > 0 &&
                                <span className="content-list">
                                 <span className="content-file-name">
                                       <span className="icon-v22-extension-ai"></span>
                                 <span
                                     className="file-name">{listFilesDisclosure.ropaFile.fileName ? listFilesDisclosure.ropaFile.fileName : ''}</span>
                                 </span>
                                 <span
                                     className="file-size">{listFilesDisclosure.ropaFile.size ? listFilesDisclosure.ropaFile.size : ''}</span>
                             </span>
                                }
                            </div>
                            <div className='wrap-it-up-list-files'>
                                {listFilesDisclosure && listFilesDisclosure.files && listFilesDisclosure.files.length > 0 && listFilesDisclosure.files.map((item) => {
                                    return (
                                        <span className="content-list">
                                 <span className="content-file-name">
                                       <span className="icon-v22-extension-ai"></span>
                                 <span className="file-name">{item.fileName ? item.fileName : ''}</span>
                                 </span>
                                 <span className="file-size">{item.size ? item.size : ''}</span>
                             </span>
                                    )
                                })

                                }
                            </div>
                        </div>
                    }
                    <div className="wrap-it-up-button">
                        <span className="info-total-files">{listFilesDisclosure.files && listFilesDisclosure.files.length > 0 ? `Total files: ${listFilesDisclosure.files.length}` : 'Total files: 0'}</span>
                        <Button
                                loading={disabledPack === true && packingCompleted === false }
                                disabled={disabledPack === true || listFilesDisclosure.length === 0 || listFilesDisclosure.title === 'Info'}
                                onClick={(e) => this.onPackFilesDisclosureFirstStep(e)} className="pack-button">Pack</Button>
                    </div>
                </div>
            </div>
        )
    }
}


PackingDisclosure.propTypes = {
    folder: PropTypes.object,
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id
})
export default compose(
    connect(mapStateToProps),
)(PackingDisclosure)