import React from 'react'
import PropTypes from 'prop-types'

import Loading from '../../Common/Loading'

import './CollectionInfo.css'

const CollectionInfo = ({ collection, countFolders }) => {
  if (collection.isFetchingInfo) return (
    <div className="collectionInfo__loading">
      <Loading />
    </div>
  )

  return (
    <React.Fragment>
      <div className="collectionInfo__item">Objects<br />
        <strong>{ collection.totalObjects }</strong>
      </div>
      <div className="collectionInfo__item">Folders<br />
        <strong>{ countFolders }</strong>
      </div>
    </React.Fragment>
  )
}

CollectionInfo.propTypes = {
  collection: PropTypes.object.isRequired,
  countFolders: PropTypes.number.isRequired
}

export default CollectionInfo