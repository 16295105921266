import PropTypes from 'prop-types'
import React from 'react'
import './Message.css'

const Message = ({ status, text, hideMessageAction }) => (
  <div id="message" className={ `message-${ status }` }>
    <span id="message-text" className="message-text" >{ text }</span>
    <span
      id="message-close"
      className="message-close"
      onClick={ hideMessageAction }>x</span>
  </div>
)

Message.propTypes = {
  hideMessageAction: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
}

export default Message
