import React from 'react'

class DataTopics extends React.Component {
  
  render() {
    return (
      <div className="access-expressions">
        <div className="access-expressions__button-run">
          <button className="button-black-run"
          >FIND TOPICS
          </button>
        </div>
        <div className="access-expressions__content-text-topics">
          <span className="text-topics">
            Find the overall topics of the data in your smart folder and let it automatically stack
          </span>
        </div>
        <div className="access-expressions__content-footer">
          <button className="button-blue-cancel">CANCEL
          </button>
          <button className="button-blue-light">SAVE
          </button>
        </div>
      </div>
    )
  }
}

export default DataTopics