import React from 'react'
import { compose } from 'redux'
import swal from 'sweetalert2'
import { Link } from 'react-router-dom'

import Button from '../../Common/Button'
import Loading from '../../Common/Loading'
import EditInPlace from '../../Privacy/Form/EditInPlace'
import DocumentViewer from './DocumentList/DocumentViewer'
import DataSourceMap from './Visualisations/DataSourceMap'

import {
  apiOspreyGetCategory,
  apiOspreyRenameCategory,
  apiOspreyGetDocumentsForCategory,
  apiOspreyDeleteCategory,
  apiOspreyCreateCategory,
  apiOspreySaveCategory,
  apiOspreyTrainCategory,
  apiOspreyCategoryGetProcessData,
  apiOspreyCategoryCluster,
  apiOspreyGetTrainingBlacklist,
  apiOspreyAddTrainingBlacklist,
  apiOspreyRemoveDocumentFromCategoryID,
  apiOspreyGetDataModels,
  apiOspreyRunCategoryAnnotator,
  apiOspreyAddAutoAnnotateProcess,
  apiOspreyRemoveAutoAnnotateProcess
} from '../../../api/OspreyAnalytics.js'


import './Category.css'
import DocumentList from './DocumentList/DocumentList'
import Tabs from './Utils/Tabs'
import DocumentPagination from './DocumentList/DocumentPagination'

class Category extends React.Component {
  state = {
    category: null,
    categoryId: null,
    documentId: null,
    offset: 0,
    length: 10,
    fieldNames: [ 'doc_id', 'text', 'doc_author' ],
    sortField: '',
    ascending: false,
    flatten: false,
    documents: [],
    query: '',
    filterQueries: [],
    datasets: [],
    loadingTrainingData: true,
    isTrained: false,
    tabs: [ 'General', 'Data', 'Rules', 'Topics', 'Training', 'Annotators' ],
    selectedTab: 0,
    numTopics: 120,
    topics: false,
    selectedFilters: [],
    blacklist: false
  }
  
  componentDidMount = () => {
    window.scrollTo(0, 0)
    
    this.setState({
      categoryId: parseInt(this.props.params.id, 10)
    }, this.initFields)
  }
  
  initFields = () => {
    this.loadCategory(this.state.categoryId)
    this.getTopics()
    this.getBlacklist()
  }
  
  loadCategory = (id) => {
    apiOspreyGetCategory(id)
      .then((response) => {
        
        if (response.data.value.category) {
          let fq = []
          if (response.data.value.category.params.fq) {
            if (response.data.value.category.params.fq instanceof Array) {
              fq = response.data.value.category.params.fq
            } else {
              fq = [ response.data.value.category.params.fq ]
            }
          }
          
          let q = '*'
          
          if (response.data.value.category.params.q) {
            q = response.data.value.category.params.q
          }
          const datasets = (response.data.value.category.dataset && (response.data.value.category.dataset instanceof Array)) ? response.data.value.category.dataset : (response.data.value.category.dataset) ? [ response.data.value.category.dataset ] : []
          const isTrained = datasets.indexOf('analyser_training') !== -1
          
          this.setState({
            category: response.data.value.category,
            query: q,
            filterQueries: fq,
            autoprocess: (response.data.value.category.autoprocess instanceof Array) ? response.data.value.category.autoprocess : [ response.data.value.category.autoprocess ],
            child: (response.data.value.category.child instanceof Array) ? response.data.value.category.child : [ response.data.value.category.child ],
            datamodel: response.data.value.category.datamodel,
            id: response.data.value.category.id,
            islockable: response.data.value.category.islockable,
            isquerydriven: response.data.value.category.isquerydriven,
            istrainable: response.data.value.category.istrainable,
            locked: response.data.value.category.locked,
            name: response.data.value.category.name,
            parentid: response.data.value.category.parentid,
            supportFlatten: response.data.value.category.supportFlatten,
            taxonomy: response.data.value.category.taxonomy,
            typename: response.data.value.category.typename,
            isTrained: isTrained,
            datasets: datasets,
            sortField: isTrained ? `system_category_probability_${ response.data.value.category.id }` : '',
            fieldNames: isTrained ? [ 'doc_id', 'text', 'doc_author', `system_category_probability_${ response.data.value.category.id }` ] : [ 'doc_id', 'text', 'doc_author' ],
            fieldDisplayIds: isTrained ? [ 'doc_id', 'doc_author', `system_category_probability_${ response.data.value.category.id }` ] : [ 'doc_id', 'doc_author' ],
            fieldDisplayNames: isTrained ? [ 'File', 'Author', 'Confidence' ] : [ 'File', 'Author' ]
          }, () => this.getAnnotators())
          
          if (this.state.isTrained) {
            this.getTrainingData()
          }
          
          this.loadDocuments(this.state.categoryId)
        }
      })
      .catch((error) => {
      })
  }
  
  getAnnotators = () => {
    apiOspreyGetDataModels()
      .then((response) => {
        const dm = response.data.value.datamodels.datamodel.filter((datamodel) => datamodel.name === this.state.datamodel)[0]
        const annotators = dm.annotators && dm.annotators.annotator && dm.annotators.annotator instanceof Array ? dm.annotators.annotator : dm.annotators && dm.annotators.annotator ? [ dm.annotators.annotator ] : []
        const checkedAnnotators = annotators.map((annotator) => {
          annotator.checked = (this.state.autoprocess.indexOf(annotator.id) !== -1)
          return annotator
        })
        this.setState({
          annotators: checkedAnnotators
        })
      })
  }
  
  loadDocuments = (id) => {
    apiOspreyGetDocumentsForCategory(id, this.state.offset, this.state.length, this.state.fieldNames, this.state.filterQueries.concat(this.state.selectedFilters), this.state.sortField, this.state.ascending, this.state.flatten)
      .then((response) => {
        const docid = (response.data.documentList.length > 0) ? response.data.documentList[0].ID : null
        
        const doclist = response.data.documentList.map((document) => {

       //   console.log(document)
          
          if (document.metadata[`system_category_probability_${ this.state.categoryId }`]) {
            const val = Number.parseFloat(document.metadata[`system_category_probability_${ this.state.categoryId }`] * 100).toPrecision(4)
            document.metadata[`system_category_probability_${ this.state.categoryId }`] = `${ val }%`
            document.actions = [ 'remove' ]
          }
          
          return document
        })
        
        this.setState({
          documents: doclist,
          pagination: response.data.pagination,
          documentId: docid
        })
      })
      .catch((error) => {
        console.log(error, 'error')
      })
  }
  loadDocumentsWithOffset = (id, offset) => {
    apiOspreyGetDocumentsForCategory(id, offset, this.state.length, this.state.fieldNames, this.state.filterQueries.concat(this.state.selectedFilters), this.state.sortField, this.state.ascending, this.state.flatten)
      .then((response) => {
        const docid = (response.data.documentList) ? response.data.documentList[0].ID : null
        
        const doclist = response.data.documentList.map((document) => {
          
          if (document.metadata[`system_category_probability_${ this.state.categoryId }`]) {
            const val = Number.parseFloat(document.metadata[`system_category_probability_${ this.state.categoryId }`] * 100).toPrecision(4)
            document.metadata[`system_category_probability_${ this.state.categoryId }`] = `${ val }%`
            document.actions = [ 'remove' ]
          }
          
          return document
        })
        
        this.setState({
          documents: doclist,
          pagination: response.data.pagination,
          documentId: docid
        })
      })
      .catch((error) => {
        console.log(error, 'error')
      })
  }
  
  goToOffset = (offset) => {
    this.setState({ offset: offset, pagination: false })
    this.loadDocumentsWithOffset(this.state.id, offset)
  }
  
  renameCategory = (value) => {
    const { categoryId } = this.state
    
    apiOspreyRenameCategory(categoryId, value)
      .then((response) => {
        if (response.data.status === 'Success') {
          this.loadCategory(categoryId)
        }
      })
  }
  
  showTopicDocuments = (topicID) => {
    
    const filter = `system_property_cluster_id:${ topicID }`
    this.setState({
      selectedTab: 1,
      selectedFilters: [ filter ]
    }, () => {
      this.loadDocuments(this.state.categoryId)
      console.log(this.state.selectedTab)
    })
    
  }
  
  delete = () => {
    this.setState({ isDeleting: true })
    
    apiOspreyDeleteCategory(this.state.categoryId)
      .then((response) => {
        if (response.data.status === 'Success') {
          this.setState({ redirectToTaxonomies: true })
        }
      })
  }
  
  createChild = () => {
    apiOspreyCreateCategory(this.state.categoryId, 'New Category')
      .then((response) => {
        if (response.data.status === 'Success') {
          this.setState({ redirectToTaxonomies: true })
        }
      })
  }
  
  saveCategory = () => {
    apiOspreySaveCategory(this.state.query, this.state.filterQueries, this.state.categoryId)
      .then((response) => {
        // don't wait for a response - it doesn't come
      })
    this.setState({ redirectToProcesses: true })
  }
  
  trainCategory = () => {
    apiOspreyTrainCategory(this.state.categoryId)
      .then((response) => {
        // don't wait for a response - it doesn't come
      })
    this.setState({ redirectToProcesses: true })
  }
  
  findTopics = () => {
    apiOspreyCategoryCluster(this.state.categoryId, this.state.numTopics)
      .then((response) => {
        // don't wait for a response - it doesn't come
      })
    this.setState({ redirectToProcesses: true })
  }
  
  getTopics = () => {
    apiOspreyCategoryGetProcessData(this.state.categoryId, 'analyser_clusters')
      .then((response) => {
        
        let topics = []
        if (response.data.value && response.data.value.clusters) {
          topics = response.data.value.clusters.cluster.map((cluster) => {
            cluster.displayLength = 5
            return cluster
          })
        }
        
        this.setState({
          topics: topics
        })
      })
  }
  
  changeTopicDisplayLength = (indextochange, length) => {
    const topics = this.state.topics.map((topic, index) => {
      if (index === indextochange) {
        topic.displayLength = length
      }
      return topic
    })
    this.setState({
      topics: topics
    })
  }
  
  getTrainingData = () => {
    apiOspreyCategoryGetProcessData(this.state.categoryId, 'analyser_training')
      .then((response) => {
        this.setState({
          classifier: response.data.value.classifier,
          loadingTrainingData: false
        })
      })
  }
  
  showDeleteConfirmation = () => {
    swal({
      showConfirmButton: true,
      showCancelButton: true,
      html: '<p style="font-size: 14px; margin: 10px 0; text-align: center;">Are you sure that you want to delete this Category?</p> ',
      title: 'Delete Category',
      type: 'question',
      padding: 50
    }).then((result) => {
      if (result.value) this.delete()
    })
  }
  
  showCreateConfirmation = () => {
    swal({
      showConfirmButton: true,
      showCancelButton: true,
      html: '<p style="font-size: 14px; margin: 10px 0; text-align: center;">Create new Category?</p> ',
      title: 'Create Category',
      type: 'question',
      padding: 50
    }).then((result) => {
      if (result.value) this.createChild()
    })
  }
  
  showSaveConfirmation = () => {
    swal({
      showConfirmButton: true,
      showCancelButton: true,
      html: '<p style="font-size: 14px; margin: 10px 0; text-align: center;">Save new criteria for this Category?</p> ',
      title: 'Save Criteria',
      type: 'question',
      padding: 50
    }).then((result) => {
      if (result.value) this.saveCategory()
    })
  }
  
  showTrainConfirmation = () => {
    swal({
      showConfirmButton: true,
      showCancelButton: true,
      html: '<p style="font-size: 14px; margin: 10px 0; text-align: center;">Are you sure you want to train this category?</p> ',
      title: 'Train category',
      type: 'question',
      padding: 50
    }).then((result) => {
      if (result.value) this.trainCategory()
    })
  }
  
  changeFilterQuery = (event, index) => {
    const tmp = this.state.filterQueries
    tmp[index] = event.target.value
    
    this.setState({
      filterQueries: tmp
    })
  }
  changeQuery = (event) => {
    this.setState({
      query: event.target.value
    })
  }
  
  addFilterQuery = () => {
    
    const newArray = this.state.filterQueries.slice()
    newArray.push('')
    this.setState({
      filterQueries: newArray
    })
  }
  
  removeFilterQuery = (index) => {
    
    const newArray = this.state.filterQueries.splice(index, 1)
    
    this.setState({
      filterQueries: newArray
    })
  }
  
  removeSelectedFilter = (index) => {
    
    const newArray = this.state.selectedFilters
    newArray.splice(index, 1)
    
    this.setState({
      selectedFilters: newArray
    }, () => this.loadDocuments(this.state.categoryId))
  }
  
  setNumTopics = (val) => {
    this.setState({
      numTopics: val
    })
  }
  
  clickTab = (index) => {
    this.setState({
      selectedTab: index
    })
  }
  
  selectDocument = (id) => {
    const docid = id
    this.setState({
      documentId: docid
    })
  }
  
  getBlacklist = () => {
    apiOspreyGetTrainingBlacklist(this.state.categoryId)
      .then((response) => {
        
        this.setState({
          blacklist: response.data.value
        })
      })
  }
  
  isBlacklisted = (value) => {
    
    if (this.state.blacklist && this.state.blacklist.includes(`${ value }`.toLowerCase())) {
      return true
    }
    
    return false
  }
  
  blacklist = (value) => {
    
    apiOspreyAddTrainingBlacklist(this.state.categoryId, value)
      .then((response) => {
        this.getBlacklist()
      })
  }
  
  removeDocument = (docID) => {
    apiOspreyRemoveDocumentFromCategoryID(this.state.categoryId, docID)
      .then((response) => {
        this.loadDocuments(this.state.categoryId)
      })
  }
  
  componentWillUnmount = () => {
  }
  selectDataMap = (v, primary, secondary, query, pivotfield) => {
    this.setState({
      clickedCell: v.x0 && v.y0 ? v : false,
      pivotfield: pivotfield,
      query: query,
      primary: primary,
      secondary: secondary
    })
  }
  
  setHoverDataMap = (v, primary, secondary, query, pivotfield) => {
    
  }
  
  unHoverDataMap = () => {
  }
  
  toggleAnnotator = (e, index) => {
    console.log(e)
    
    if (this.state.annotators[index].checked) {
      apiOspreyRemoveAutoAnnotateProcess(this.state.annotators[index].id, this.state.categoryId)
        .then((response) => {
          this.loadCategory(this.state.categoryId)
        })
    } else {
      apiOspreyAddAutoAnnotateProcess(this.state.annotators[index].id, this.state.categoryId, false)
        .then((response) => {
          this.loadCategory(this.state.categoryId)
        })
    }
    
  }
  
  runAnnotator = (annotatorId) => {
    apiOspreyRunCategoryAnnotator(annotatorId, this.state.categoryId)
    this.setState({
      redirectToProcesses: true
    })
  }
  
  render = () => {
    if (this.state.redirectToWorkspaces) return (
      <Link to="/admin/workspaces"/>
    )
    if (this.state.redirectToProcesses) return (
      <Link to="/admin/processes"/>
    )
    
    return (
      <div className="backofficeCategory__wrapper">
        <Link className="backofficeCategory__link" to="/admin/workspaces">
          Back to Workspaces screen
        </Link>
        <div className="backOfficeCategoryEdit__box">
          <header className="backOfficeCategoryEdit__boxHeader">
            <div>
              <h1 className="backOfficeCategoryEdit__boxTitle">
                { this.state.category !== null
                  ? <EditInPlace
                    value={ this.state.category.name }
                    save={ this.renameCategory }/>
                  : <Loading/>
                }
              </h1>
              <p className="backOfficeCategoryEdit__boxText">
                Edit Category.
              </p>
            </div>
          </header>
          <div className="backOfficeCategoryEdit__categoryContainer">
            <Tabs tabClick={ this.clickTab.bind(this) } tabs={ this.state.tabs }
              selectedTab={ this.state.selectedTab }/>
            <div className="backOfficeCategoryEdit__tabContent">
              { this.state.tabs[this.state.selectedTab] === 'General'
                ? <div>
                  { this.state.category !== null
                    ? <div className="backOfficeCategoryEdit__general">
                      <DataSourceMap
                        filterQueries={ [ `${ this.state.category.taxonomy }:${ this.state.categoryId }` ] }
                        pivotFields={ [ 'doc_datasource_name', 'doc_folder_path' ] }
                        onSelect={ this.selectDataMap.bind(this) }
                        onHover={ this.setHoverDataMap.bind(this) }
                        unHover={ this.unHoverDataMap.bind(this) }/>
                      <div className="backOfficeCategoryEdit_buttons">
                        <Button
                          theme="red"
                          onClick={ this.showDeleteConfirmation }>
                          Delete Category</Button>
                        <Button
                          theme="green"
                          onClick={ this.showCreateConfirmation }>
                          Create Child</Button>
                      </div>
                    </div>
                    : <Loading/>
                  }
                </div>
                : <div/>
              }
              { this.state.tabs[this.state.selectedTab] === 'Data'
                ? <div className="backOfficeCategoryEdit__documentsLayout">
                  <div className="backOfficeCategoryEdit__documentList">
                    { this.state.selectedFilters.length > 0
                      ? <div className="backOfficeCategoryEdit_filters">
                        <div className="backOfficeCategoryEdit_filtersTitle">Filters:</div>
                        { this.state.selectedFilters.map((filter, filterindex) => (
                          <div className="backOfficeCategoryEdit_filter" key={ `selectedfilter_${ filterindex }` }
                            onClick={ (e) => this.removeSelectedFilter(filterindex) }>{ filter }
                            <div className="backOfficeCategoryEdit_filterRemove">X</div>
                          </div>
                        ))
                        }
                      </div>
                      : <div/>
                    }
                    { this.state.category !== null && this.state.categoryId && this.state.documents && this.state.pagination
                      ? <DocumentList key={ `documentlist_${ this.state.pagination.offset }` }
                        fieldNames={ this.state.fieldDisplayNames }
                        fieldIds={ this.state.fieldDisplayIds } documents={ this.state.documents }
                        selectDocument={ this.selectDocument.bind(this) }
                        removeDocument={ this.removeDocument.bind(this) }/>
                      : <Loading/>
                    }
                    { this.state.category !== null && this.state.categoryId && this.state.pagination
                      ? <DocumentPagination pagination={ this.state.pagination }
                        changeOffset={ this.goToOffset.bind(this) }
                        key={ `pagination_${ this.state.pagination.offset }` }/>
                      : <Loading/>
                    }
                    { this.state.category !== null && this.state.categoryId && this.state.documents && this.state.pagination
                      ? <div className="backOfficeCategoryEdit__documentViewer">
                        { this.state.documentId !== null
                          ? <DocumentViewer key={ `documentviewer_${ this.state.documentId }` }
                            datamodel={ this.state.datamodel } documentId={ this.state.documentId }
                            categoryId={ this.state.categoryId }
                            query={ this.state.category.params.q }/>
                          : <div/>
                        }
                      </div>
                      : <Loading/>
                    }
                  </div>
                </div>
                : <div/>
              }
              { this.state.tabs[this.state.selectedTab] === 'Rules'
                ? <div>
                  { this.state.isquerydriven
                    ? <div className="backOfficeCategoryEdit_queries">
                      <div className="backOfficeCategoryEdit_query">
                        <label className="title-edit-query">Query</label>
                        <div>
                          <textarea
                            name="categoryquery"
                            type="text"
                            value={ this.state.query }
                            onChange={ this.changeQuery }/>
                        </div>
                      </div>
                      
                      <div className="backOfficeCategoryEdit_filterQueries">
                        <label>FilterQueries</label>
                        
                        <div>
                          { this.state.filterQueries.map((query, index) => (
                            <div className="backOfficeCategoryEdit_query" key={ `filterQueries_${ index }` }><textarea
                              name={ `filterQueries_${ index }` }
                              type="text"
                              value={ query }
                              onChange={ (e) => this.changeFilterQuery(e, index) }/>
                            <div className="backOfficeCategoryEdit_removeFilterQuery"
                              onClick={ this.removeFilterQuery.bind(index) }>Remove Filter
                            </div>
                            </div>
                          )) }
                        </div>
                        <div className="backOfficeCategoryEdit_addFilterQuery" onClick={ this.addFilterQuery }>Add
                          Filter
                        </div>
                      </div>
                      <div className="backOfficeCategoryEdit_buttons">
                        <Button
                          theme="green"
                          onClick={ this.showSaveConfirmation }>
                          Save Category Criteria</Button>
                      </div>
                    </div>
                    : <div>This category does not support rules based configuration</div>
                  }
                </div>
                : <div/>
              }
              { this.state.tabs[this.state.selectedTab] === 'Training'
                ? <div>
                  { this.state.istrainable
                    ? <div className="backOfficeCategoryEdit_training">
                      <Button
                        theme="green"
                        onClick={ this.showTrainConfirmation }>
                        Train Category</Button>
                      { this.state.isTrained
                        ? <div className="backOfficeCategoryEdit__isTrained">
                          { this.state.loadingTrainingData
                            ? <Loading/>
                            : <div className="backOfficeCategoryEdit__trainingStats">
                              <div className="backOfficeCategoryEdit__trainingStatsTitle">Training Stats</div>
                              <div
                                className="backOfficeCategoryEdit__trainingStat">Accuracy: { Number.parseFloat(this.state.classifier.accuracy * 100).toPrecision(3) }%
                              </div>
                              <div
                                className="backOfficeCategoryEdit__trainingStat">Precision: { Number.parseFloat(this.state.classifier.precision * 100).toPrecision(3) }%
                              </div>
                              <div
                                className="backOfficeCategoryEdit__trainingStat">Recall: { Number.parseFloat(this.state.classifier.recall * 100).toPrecision(3) }%
                              </div>
                              <div className="backOfficeCategoryEdit__trainingStat">F
                                Score: { Number.parseFloat(this.state.classifier.fscore * 100).toPrecision(3) }%
                              </div>
                              <div className="backOfficeCategoryEdit__trainingTerms">
                                <div
                                  className="backOfficeCategoryEdit__trainingTermsTitle">Top { this.state.classifier.terms.term.length } Trained
                                  Terms
                                </div>
                                { this.state.classifier.terms.term.map((term, index) => {
                                  return <div className="backOfficeCategoryEdit__trainingTerm"
                                    key={ index }>{ term } { !this.isBlacklisted(term) &&
                                  <div className="backOfficeCategoryEdit_filterRemove"
                                    onClick={ (e) => this.blacklist(term) }>X</div> }</div>
                                }) }
                              </div>
                            </div>
                          }
                        </div>
                        : <div className="category-trained">Category not trained</div>
                      }
                    </div>
                    : <div>This category does not support training</div>
                  }
                </div>
                : <div/>
              }
              { this.state.tabs[this.state.selectedTab] === 'Topics'
                ? <div>
                  <div className="content-button-topics">
                    <Button
                      theme="green"
                      onClick={ this.findTopics }>
                      Find Topics</Button>
                  </div>
                  <div className="content-input-search">
                    <input type="text" onChange={ (e) => this.setNumTopics(e.target.value) }
                      value={ this.state.numTopics }/>
                  </div>
                  <div>
                    { this.state.topics
                      ? <div className="backOfficeCategoryEdit__topicsWrapper">
                        { this.state.topics.map((topic, index) => (
                          <div key={ `topic_${ index }` } className="backOfficeCategoryEdit__topicWrapper">
                            { topic.term
                              ? <div>
                                <div className="backOfficeCategoryEdit__topicTitle">
                                  { topic.id } ({ Number.parseFloat(topic.weight * 100).toPrecision(2) })
                                </div>
                                <div className="backOfficeCategoryEdit__topicTerms">
                                  <table>
                                    <thead>
                                      <tr>
                                        <th style={ { textAlign: 'center' } }>Term</th>
                                        <th style={ { textAlign: 'center' } }>Weight</th>
                                        <th style={ { textAlign: 'center' } }>Block</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      { topic.term instanceof Array
                                        ? topic.term.map((term, termindex) =>
                                          termindex < topic.displayLength && <tr key={ `term_${ index }_${ termindex }` }
                                            className="backOfficeCategoryEdit__topicTerm">
                                            <td className="backOfficeCategoryEdit__topicTerm_name">{ term.name }</td>
                                            <td
                                              className="backOfficeCategoryEdit__topicTerm_weight">{ Number.parseFloat(term.value * 100).toPrecision(2) }</td>
                                            { this.isBlacklisted(term.name)
                                              ? <td />
                                              : <td className="backOfficeCategoryEdit__topicTerm_block"
                                                onClick={ (e) => this.blacklist(term.name) }>Block</td>
                                            }
                                          </tr>
                                        )
                                        : <tr className="backOfficeCategoryEdit__topicTerm">
                                          <td className="backOfficeCategoryEdit__topicTerm_name">topic.term.name</td>
                                          <td
                                            className="backOfficeCategoryEdit__topicTerm_weight">{ Number.parseFloat(topic.term.value * 100).toPrecision(2) }</td>
                                          <td className="backOfficeCategoryEdit__topicTerm_weight_block">Block</td>
                                        </tr>
                                      }
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              : <div/>
                            }
                            { topic.term instanceof Array && topic.term.length > topic.displayLength
                              ? <div className="backOfficeCategoryEdit__topic_showMore"
                                onClick={ (e) => this.changeTopicDisplayLength(index, topic.term.length) }>More...</div>
                              : <div className="backOfficeCategoryEdit__topic_showLess"
                                onClick={ (e) => this.changeTopicDisplayLength(index, 5) }>Less...</div>
                            }
                            <div className="backOfficeCategoryEdit__topicViewContentButton"
                              onClick={ (e) => this.showTopicDocuments(topic.id) }>View Content
                            </div>
                          </div>
                        ))
                        }
                      </div>
                      : <Loading/>
                    }
                  </div>
                </div>
                : <div/>
              }
              { this.state.tabs[this.state.selectedTab] === 'Annotators'
                ? <div className="backOfficeCategoryEdit__annotatorsWrapper">
                  <div className="backOfficeCategoryEdit__annotatorsTitle">
                    Available Annotators
                  </div>
                  { this.state.annotators
                    ? <div>
                      { this.state.annotators.map((annotator, index) => (
                        <div key={ `annotator_${ index }` } className="backOfficeCategoryEdit__annotatorWrapper">
                          <div className="backOfficeCategoryEdit__annotatorCheckbox">
                            <input type="checkbox" checked={ annotator.checked }
                              onChange={ (e) => this.toggleAnnotator(e, index) }/>Always run
                          </div>
                          <div className="backOfficeCategoryEdit__annotatorRun"
                            onClick={ (e) => this.runAnnotator(annotator.id) }>
                            Run now
                          </div>
                          <div className="backOfficeCategoryEdit__annotatorName">
                            { annotator.name }
                          </div>
                          <div className="backOfficeCategoryEdit__annotatorDescription">
                            { annotator.description }
                          </div>
                        </div>
                      )) }
                    </div>
                    : <Loading/>
                  }
                </div>
                : <div/>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default compose()(Category)