import React, { Component } from 'react'
import PropTypes from 'prop-types'

import '../Radio.css'

class Active extends Component {
  constructor() {
    super()
    this.handleKey = this.handleKey.bind(this)
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKey)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKey)
  }

  updateValueForOther(option, e) {
    this.props.updateValueForOther(e.target.value, option)
  }

  handleKey(e) {
    const pressedKey = e.keyCode

    // Only keys 1, 2, 3, 4 and 5.
    if (pressedKey >= 49 && pressedKey <= 53) {
      const values = {
        49: 1,
        50: 2,
        51: 3,
        52: 4,
        53: 5
      }

      const selectedOption = this.props.question.options.filter((item) => item.order === values[pressedKey])

      // We need this because number 3 is for the option "I don't know" and there aren't
      // so many questions with this.
      if (selectedOption.length > 0) {
        this.props.onChange(selectedOption[0].title, selectedOption[0])
      }
    }
  }

  getLabelClass(option) {
    return option.isSelected ? 'questionTypeRadio-labelChecked' : 'questionTypeRadio-label'
  }

  onChange(option, e) {
    this.props.onChange(e.target.value, option)
  }

  render() {
    const { question } = this.props

    return (
      <ul>
        { question.options.map((option) => (
          <li key={ option.id }>
            <div className="questionTypeRadio-item">
              <div className="questionTypeRadio-group">
                <div className="questionTypeRadio-radio">
                  <input
                    checked={ option.isSelected }
                    id={ option.id }
                    name='answer'
                    type="radio"
                    value={ option.title }
                    onChange={ this.onChange.bind(this, option) } />
                  <label
                    className={ this.getLabelClass(option) }
                    htmlFor={ option.id }></label>
                </div>
                <span className="questionTypeRadio-optionTitle">{ option.title }</span>
              </div>
              {
                option.isSelected &&
                option.message &&
                <span className="questionTypeRadio-optionMessage">{ option.message }</span>
              }
              { option.type === 'OTHER' &&
                <textarea
                  className="questionTypeCheckbox-textarea"
                  disabled={ false }
                  value={ option.otherOptionText || '' }
                  onChange={ this.updateValueForOther.bind(this, option) }
                ></textarea>
              }
            </div>
          </li>
        ))}
      </ul>
    )
  }
}

Active.propTypes = {
  onChange: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired
}

export default Active
