import React from 'react'
import PropTypes from 'prop-types'

import '../Checkbox.css'

class Active extends React.Component {
  updateValueForCheckbox(option, e) {
    this.props.updateValueForCheckbox(e.target.checked, option)
  }

  updateValueForOther(option, e) {
    this.props.updateValueForOther(e.target.value, option)
  }

  render() {
    const { groups } = this.props

    return (
      <div>
        { Object.keys(groups).map((group) => (
          <ul key={ group }>
            { group !== '__null' &&
              <h2 className="questionTypeCheckbox-title"><span>{ group.replace('__', '') }</span></h2>
            }
            <ul>
              { groups[group].map((option) => (
                <li key={ option.id }>
                  <div className="questionTypeCheckbox-item">
                    <div className="questionTypeCheckbox-group">
                      <div className="questionTypeCheckbox-checkbox">
                        <input
                          checked={ option.isSelected }
                          disabled={ false }
                          id={ option.id }
                          type="checkbox"
                          onChange={ this.updateValueForCheckbox.bind(this, option) } />
                        <label
                          className={ getLabelClass(option.isSelected) }
                          htmlFor={ option.id }></label>
                      </div>
                      <span className="questionTypeCheckbox-optionTitle">{ option.title }</span>
                    </div>
                    {
                      option.isSelected &&
                      option.message &&
                      <span className="questionTypeCheckbox-optionMessage">{ option.message }</span>
                    }
                    { option.type === 'OTHER' &&
                      <textarea
                        className="questionTypeCheckbox-textarea"
                        disabled={ false }
                        value={ option.otherOptionText || '' }
                        onChange={ this.updateValueForOther.bind(this, option) }
                      ></textarea>
                    }
                  </div>
                </li>
              ))}
            </ul>
          </ul>
        ))
        }
      </div>
    )
  }
}

const getLabelClass = (isSelected) => {
  return isSelected ? 'questionTypeCheckbox-labelChecked' : 'questionTypeCheckbox-label'
}

Active.propTypes = {
  groups: PropTypes.object.isRequired,
  updateValueForCheckbox: PropTypes.func.isRequired,
  updateValueForOther: PropTypes.func.isRequired
}

export default Active
