import PropTypes from 'prop-types'
import React, { useState } from 'react'
import './Assessment.scss'
import Active from './Assessment/Active'
import Completed from './Assessment/Completed'
import Locked from './Assessment/Locked'
import Overdue from './Assessment/Overdue'
import PiaActive from './Assessment/PiaActive'
import PiaCompleted from './Assessment/PiaCompleted'
import { apiRiskIndicatorReport } from '../../../api/Dpia'

const Assessment = ({ assessment, process, processStatus, isAdmin, type, isSuperAdmin, name, disableButtons }) => {

  const [score, setScore] = useState(0)
  if (process.isLocked) return (
    <Locked 
      assessment={ assessment }
      answered={ getAnswered(assessment) }
      total={ getTotal(assessment) }
      processStatus={ processStatus }
    />
  )

  if ((assessment.status === 'NEW' || assessment.status === 'IN_PROGRESS') && (assessment.timelineStatus === 'DUE' || assessment.timelineStatus === 'UPCOMING')) {
    if (assessment.type === 'DPIA_QUESTIONNAIRE') {
      return (<Active
        disableButtons = { disableButtons }
        assessment={ assessment }
        answered={ getAnswered(assessment) }      
        total={ getTotal(assessment) }
        processStatus={ processStatus }
        type={type}
      />)
    }
    return (
      <PiaActive
        disableButtons = { disableButtons }
        assessment={ assessment }
        answered={ getAnswered(assessment) }      
        total={ getTotal(assessment) }
        processStatus={ processStatus }
        type={type}
      />
    )
  }

  if ((assessment.status === 'NEW' || assessment.status === 'IN_PROGRESS') && assessment.timelineStatus === 'OVERDUE') return (

    <Overdue
      assessment={ assessment }
      answered={ getAnswered(assessment) }
      total={ getTotal(assessment) }
      processStatus={ processStatus }
      type={type}
    />
  )

  if (assessment.status === 'COMPLETED') {
    if (assessment.type === 'DPIA_QUESTIONNAIRE') {
      // if (scoreDpia.length === 0) {
      //   const data = ''
      //   apiDpiaReportDetail(data, assessment.id).then((res) => setScoreDpia(res.data.piScores.map((item) => { return (`${ item.code }=${ item.piIndicatorScore }; `) })))
      // }
      return (<Completed 
        name = { name }
        assessment={ assessment }
        processId={ process.id }
        processStatus={ processStatus }
        isAdmin={ isAdmin || isSuperAdmin }
        type={type}
        // scoreDpia={scoreDpia}
      />)
    }
    if (score === 0) {
      apiRiskIndicatorReport(assessment.id).then((res) => setScore(res.data.score))
    }
    return (
      <PiaCompleted 
        name = { name }
        assessment={ assessment }
        processId={ process.id }
        processStatus={ processStatus }
        isAdmin={ isAdmin || isSuperAdmin }
        score={score}
      />
    )
  }
}

const getAnswered = (assessment) => {
  const answered = assessment.questionsStateStats.SUBMITTED_NO || 0
  const notapplicable = assessment.questionsStateStats.QUESTIONNAIRE_SKIPPED_NO || 0
  return answered + notapplicable
}

const getTotal = (assessment) => {
  const answered = assessment.questionsStateStats.SUBMITTED_NO || 0
  const unanswered = assessment.questionsStateStats.UNANSWERED_NO || 0
  const skipped = assessment.questionsStateStats.USER_SKIPPED_NO || 0
  const notapplicable = assessment.questionsStateStats.QUESTIONNAIRE_SKIPPED_NO || 0

  return answered + unanswered + skipped + notapplicable
}

Assessment.propTypes = {
  assessment: PropTypes.object.isRequired,
  process: PropTypes.object.isRequired,
}

export default Assessment