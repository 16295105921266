import React from 'react'
import PropTypes from 'prop-types'
import DataLineWindows from '../Visualisations/DataLineWindows'
import './RenderedDataLine.css'

import { 
  apiOspreyGetDataLineInfo,
} from '../../../../api/OspreyAnalytics.js'
import { connect } from 'react-redux';
import { addIntervalToApplication } from '../../../../actions/applicationsActions';

class RenderedDataLine extends React.Component {
  constructor() {
    super()
    
    this.state = { 
      intervalId: 0,
      dataline: null,
    }
  }

  componentWillUnmount = () => {
    clearInterval(this.state.intervalId)
  }

  timer = () => {
    this.getDataLineRunning(this.state.datalineId)
  }

  componentDidMount = () => {
    const { datalineId } = this.props

    this.setState({
      datalineId: datalineId
    })
    const intervalId = setInterval(this.timer, 5000)
    this.props.addIntervalToApplication(intervalId)
    this.setState({
      intervalId: intervalId,
    })
  }

  getDataLineRunning = (id) => {
    apiOspreyGetDataLineInfo(id)
      .then((response) => {
  
        let runningInfo = null

        if (response.data.engineStatus.processCodes[id] &&
          response.data.engineStatus.processCodes[id] === 200 &&
          response.data.engineStatus.processData[id] &&
          response.data.engineStatus.processData[id].status)
        {
          runningInfo = response.data.engineStatus.processData[id].status
        }

        this.setState({
          datalineRunningInfo: runningInfo,
        })
      })
      .catch((error) => {
        console.log(error, 'error')
      })
  }

  render = () => {
    return (
      <div>
        { this.state.datalineRunningInfo && 
        this.state.datalineRunningInfo.processors &&
        this.state.datalineRunningInfo.processors.stack.map((stack, index) => (
          <div key={`processorstack_${ index }`} className="backOffice_stackWrapper">
            <div className="backOffice_processorWrapper">
              <div>{ stack.config.processorname.value }
                { stack.processor instanceof Array
                  ? <span> (x{ stack.processor.length })</span>
                  : <span> (x1)</span>
                }
              </div>
              <div>{ stack.progresswindows.totalSoFar }</div>
              <div>
                { 
                  <DataLineWindows 
                    windows={ stack.progresswindows }
                    field={ 'Success' } 
                  />
                }
              </div>
            </div>
            <div className="backOffice_queueWrapper">
              { index < this.state.datalineRunningInfo.queues.documentqueue.length 
                ? <div><div>Queue </div><div>{this.state.datalineRunningInfo.queues.documentqueue[index].number}</div></div>
                : <div/>                
              }
            </div>
          </div>
        ))
        }
      </div>
    )
  }
}

RenderedDataLine.propTypes = {
  datalineId: PropTypes.number.isRequired
}

export default connect(({}), { addIntervalToApplication })(RenderedDataLine)