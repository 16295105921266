import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { CSVLink } from 'react-csv'
import {Link, Navigate} from 'react-router-dom'
import { compose } from 'redux'
import { Spin } from 'antd'
import { apiRiskIndicatorReport } from '../../api/Dpia'
import { userIsUser, userIsSuperAdmin } from '../../utils'
import Button from '../Common/Button'
import './PiaSummary.scss'

class PiaSummary extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
     scoreAnswerTotal: '',
        piaTemplateId: '',
        questionnaireIdPia: '',
        loadingPiaSummary: true

    }
  }

 componentWillReceiveProps(nextProps, nextContext) {
      if ((nextProps.assessment.id === this.props.assessment.id) && !this.state.scoreAnswerTotal) {
          this.fetchScoreQuestion(nextProps.assessment.id)
          this.setState({
              loadingPiaSummary: false
          })
      }
 }
    renderQuestionAndAnswer(currentQuestion, questionsResults) {
    const answers = this.findAnswers(currentQuestion, questionsResults)
    if (!answers) return
    return (
      <div key={ `pia-question-${ currentQuestion.id }` } className="piaSummary__question">
        { `${ currentQuestion.order }. ${ currentQuestion.title }` }

        <div className="piaSummary__answers">
          {
            currentQuestion.type === 'YN' &&
            this.renderAnswersYN(answers)
          }

          {
            currentQuestion.type !== 'YN' &&
            this.renderQuestionItems(answers, currentQuestion)
          }
        </div>
      </div>
    )
  }

  renderQuestionItems(answers, currentQuestion) {

      const currentUserIsSuperAdmin = userIsSuperAdmin(this.props.user)

    return currentQuestion.options.map((option) => {

      const answered = answers.find((answer) => (answer.id === option.id))

      return (
        <React.Fragment key={`question${ currentQuestion.id }-${ option.id }`}>
          <p
            className={answered ? 'piaSummary__answerSelected' : 'piaSummary__answer'}>
            {option.title}
              <span className="score-question">{ currentUserIsSuperAdmin ?  `( score: ${ option.score } )` : '' }</span>
          </p>

          { option.type === 'OTHER' &&
            <p className="piaSummary__otherOptionText">{currentQuestion.otherOptionTexts[option.id]}</p>

          }
        </React.Fragment>
      )
    })
  }

  renderAnswersYN(answers) {
      const currentUserIsSuperAdmin = userIsSuperAdmin(this.props.user)
    return answers.map((answer) => (
      <div key={`answer-${ answer.id }`} className="piaSummary__answerSelected">
        { answer.title }
        <span className="score-question">{ currentUserIsSuperAdmin ? `( score: ${ answer.score } )` : '' }</span>
      </div>
    ))
  }

  findAnswers(currentQuestion, questionsResults) {
    const currentResult = questionsResults.SUBMITTED.find((result) => (result.id === currentQuestion.id))
    if (!currentResult) return

    return currentResult.options.map((optionId) => (
      currentQuestion.options.find((option) => option.id === optionId)
    ))
  }

  exportCsvLink(questions) {
      let letScore = true;
      let result = []
      if(questions) {
          questions.forEach(x => {
              let answers = ''
              let scores = ''
                  x.selectedOptions.forEach(a => {
                      answers = x.selectedOptions.length === 1 ? answers + (x.options.filter((o) => o.id === a))[0].title : answers + (x.options.filter((o) => o.id === a))[0].title + '|'
                      scores = x.selectedOptions.length === 1 ? scores + (x.options.filter((o) => o.id === a))[0].score : scores + (x.options.filter((o) => o.id === a))[0].score + '|'
                  })
              let item = {
                  title: x.title,
                  options: answers,
                  score: scores,
                  total: letScore ? this.state.scoreAnswerTotal : ''
              }
              result.push(item)
              letScore = false;
          })
      }
      return result
  }
  fetchScoreQuestion(assessmentId) {

    apiRiskIndicatorReport(assessmentId)
        .then((response) => {
          this.setState({
            scoreAnswerTotal: response.data.score
          })
        })
  }
  render() {
    const { assessment, questions } = this.props
    const { scoreAnswerTotal, loadingPiaSummary } = this.state
    const isAdmin = !userIsUser(this.props.user)
      const currentUserIsSuperAdmin = userIsSuperAdmin(this.props.user)
      const csvDataHeader = [
          { label: 'title', key: 'title' },
          { label: 'options', key: 'options' },
          { label: 'score', key: 'score' },
          { label: 'score total', key: 'total' }
      ]
    if (!isAdmin) return (
      <Navigate to="/dpia/dashboard" />
    )

    return (
      <div className="piaSummary__wrapper">
          <Spin className="custom-spin-pia-summary" spinning={loadingPiaSummary} >
        <div className="piaSummary__headerWrapper">
          <div className="piaSummary__header">
              <div className="pia-title">
            <h1>PIA Summary</h1>
                  <span className="piaScoreTotal"> { scoreAnswerTotal ? `( total score: ${ scoreAnswerTotal } )` : '' }</span>
              </div>
            <div className="piaSummary__headerButtons">
              <div className="piaSummary__printButton">
                <Button theme="gray" onClick={ window.print }>
                  Print Friendly
                </Button>
              </div>
              <div className="piaSummary__downloadButton">
                  <CSVLink filename={`${assessment.type}${assessment.id}-file.csv`} data={this.exportCsvLink(questions)} headers={ csvDataHeader }>
                <Button theme="gray">
                  Export CSV
                </Button>
              </CSVLink>
              </div>
            </div>
          </div>
        </div>

        <div className="piaSummary__questions">
          <div className="piaSummary__questionWrapper">
            { questions.map((question) => (
              this.renderQuestionAndAnswer(question, assessment.questionsResults)
            ))}
          </div>
            { currentUserIsSuperAdmin &&
                <div className="button-view-questionnaire">
                    <Link
                        to={`/dpia/questionnaires/${assessment.process.questionnaireIdPia}/versions/${assessment.process.piaTemplateId}/edit`}>
                        <button className='button_big_blue'
                        > view questionnaire
                        </button>
                    </Link>
                </div>
            }
        </div>
          </Spin>
      </div>
    )
  }
}

const stateMap = (state) => ({
  user: state.userReducer.user
})

PiaSummary.propTypes = {
  assessment: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired
}

export default compose(
  connect(stateMap)
)(PiaSummary)