import React from 'react'
import PropTypes from 'prop-types'

import './Filters.css'

class Filters extends React.Component {
  filterByTerm = (event) => {
    event.persist()
    this.props.filterByTerm(event)
  }

  render = () => {
    const { chosenView } = this.props

    return (
      <div>
        <div className="filterCollection__wrapper">
          <div className="filterCollectionItem-button">
            <label className="filterCollectionItem-labelCentered">View</label>
            <div className="filterCollectionItem-viewButton" onClick={ this.props.changeView }>
              { chosenView === 'cards' &&
                <span className="iconCards"></span>
              }

              { chosenView === 'table' &&
                <span className="iconTable"></span>
              }
            </div>
          </div>

          <div className="filterCollectionItem-button">
            <label className="filterCollectionItem-labelCentered">Find</label>
            <input
              type='text'
              className="filterCollectionItem-inputFind"
              onChange={ this.filterByTerm }></input>
          </div>
        </div>
      </div>
    )
  }
}

Filters.propTypes = {
  changeView: PropTypes.func.isRequired,
  chosenView: PropTypes.string.isRequired,
  filterByTerm: PropTypes.func.isRequired
}

export default Filters