import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link, Navigate } from 'react-router-dom'
import * as actions from '../../actions/questionActions'

import PiaSummary from './PiaSummary'
import Loading from '../Common/Loading'
import ErrorPage from '../Common/ErrorPage'
import { userIsUser } from '../../utils'
import './PiaReport.scss'

class PiaReport extends React.Component {
  componentWillMount() {
    const assessmentId = this.props.params.assessmentId
    this.props.setAssessmentId(assessmentId)
    this.props.fetchQuestions(assessmentId)
    this.props.fetchAssessment(assessmentId)
  }

  render() {
    const { assessment, process, questions } = this.props
    const isAdmin = !userIsUser(this.props.user)

    if (!isAdmin) return (
      <Navigate to="/dpia/dashboard" />
    )

    return (
      <React.Fragment>
        {
          !assessment.isLoading &&
          assessment.error &&
          <ErrorPage error={ assessment.error } />
        }

        {
          (assessment.isLoading || process.isLoading || questions.isLoading) &&
          !assessment.error &&
          <div className="piaReport__loading">
            <Loading />
          </div>
        }

        {
          !assessment.isLoading &&
          !assessment.error &&
          <div className="piaReport__page">
            { 
              !process.isLoading &&
              <header className="piaReport__header">
                <h1>{ process.title }</h1>
              </header>
            }

            <Link to="/dpia/dashboard" className="piaReport__link">Back to DPIA dashboard screen</Link>

            {
              !assessment.isLoading && !questions.isLoading &&
              <div className="piaReport__summaryWrapper">
                <PiaSummary assessment={ assessment } questions={ questions } />
              </div>
            }
          </div>
        }
      </React.Fragment>
    )
  }
}

const stateMap = (state) => ({
  questions: state.questionReducer.questions.allQuestions,
  assessment: state.questionReducer.assessment,
  process: state.questionReducer.assessment.process,
  user: state.userReducer.user
})

const dispatchMap = (dispatch) => ({
  fetchAssessment: (assessmentId) => dispatch(actions.fetchAssessment(assessmentId)),
  fetchQuestions: (assessmentId) => dispatch(actions.fetchQuestions(assessmentId)),
  setAssessmentId: (assessmentId) => dispatch(actions.setAssessmentId(assessmentId))
})

PiaReport.propTypes = {
  fetchQuestions: PropTypes.func.isRequired,
  fetchAssessment: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  process: PropTypes.object.isRequired,
  setAssessmentId: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
}

export default compose(
  connect(stateMap, dispatchMap)
)(PiaReport)