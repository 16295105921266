import React from 'react'
import './DataSourceRopa.scss'
import {Button, Transfer, Icon, Input, Select, notification} from "antd";
import BusinessTemplate from './ComponentRopa/BusinessTemplate'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import {customConfirm} from "../../../../utils/AssetRegister/CustomConfirm";
import {
    apiCreateBusinessProcessTemplate,
    apiDeleteBusinessProcessTemplate, apiEditBusinessProcessTemplate,
    apiGetBusinessProcessTemplate,
    apiGetBusinessProcessTemplateId,
    apiGetConnectorList,
    apiGetSourceMappingList,
    apiGetSourceMappingListBusinessProcess
} from "../../../../api/SmartBox";
import {connect} from "react-redux";
import CustomNotificationManager from "../../../Common/CustomNotificationManager";

const { Option } = Select
class DataSourceRopa extends React.Component {
        state = {
            dataSource: [],
            targetKeysDataSource: [],
            selectedKeysSource: [],
            targetKeys: [],
            dataSourceConnectors: [],
            targetKeysDataConnectors: [],
            selectedKeysConnectors: [],
            hoverAdd: false,
            listAddTemplate: [
                {id: 'business_function', key: 0, name: 'Business function', header: 'Business function / Department / Team'},
                {id: 'category_personal', key: 1, name: 'Category personal', header: 'Category of personal data / What is it?' },
                {id: 'who_owns', key: 2, name: 'Who owns', header: 'Who here owns it?'},
                {id: 'retention_schedule', key: 3, name: 'Retention schedule', header: 'Retention schedule (if possible)'},
                {id: 'general_description', key: 4, name: 'General description', header: 'General description of technical and organisational security measures (if possible)'},
                {id: 'source_personal', key: 5, name: 'Source personal', header: 'Source of personal data'},
                {id: 'link_record', key: 6, name: 'Link record', header: 'Link to record of consent (connector)'},
            ],
            listComponentsTemplate: [],
            listConnectors: [],
            optionSelect: 'No template',
            valueEmploymentContracts: '',
            connectorIds: [],
            dataLocationIds: [],
            dataSourceIds: [],
            getListBusinessProcessTemplate: [],
            selectTemplate: null,
            hoverDelete:false,
        }

    componentDidMount() {
        //this.getDataSource()
        //this.getDataSourceConnectors()
        this.getConnectorsList()
        this.onGetBusinessProcessTemplate()
    }
    onGetBusinessProcessTemplate = () => {
        apiGetBusinessProcessTemplate().then((response) => {
            this.setState({
                getListBusinessProcessTemplate: response.data
            })
        })
    }
    getConnectorsList = () => {
        const dtoGetMapping = {
            clientId: this.props.clientId,
            pageNumber: 1,
            pageRows: 1000,
            sortOrder: null,
            sortBy: null,
            filtersOptions: null,
            filterBy: null,
        }
        apiGetSourceMappingListBusinessProcess(this.props.clientId).then((response) => {
            this.setState({
                listConnectors: response.data ? response.data : [],
            }, () => {
                this.getDataSource()
            })
        })
    }
    onSelectAddTemplate = (e) => {
            const { listComponentsTemplate } = this.state
        const sectionNames = []
        listComponentsTemplate && listComponentsTemplate.length > 0 && listComponentsTemplate.forEach((list) => {
            sectionNames.push(list.name)
        })
        switch(e) {
            case 'Business function':
                if (!sectionNames.includes('Business function')) {
                    listComponentsTemplate.push({
                        id: 'business_function',
                        key: listComponentsTemplate.length,
                        name: 'Business function',
                        header: 'Business function / Department / Team'
                    })
                } else {
                    notification.info({ message: 'Email template with this name already exists!', duration: 4 })
                }
                break
            case 'Category personal':
                if (!sectionNames.includes('Category personal')) {
                    listComponentsTemplate.push({
                        id: 'category_personal',
                        key: listComponentsTemplate.length,
                        name: 'Category personal',
                        header: 'Category of personal data / What is it?'
                    })
                } else {
                    notification.info({ message: 'Email template with this name already exists!', duration: 4 })
                }
                break
            case 'Who owns':
                if (!sectionNames.includes('Who owns')) {
                    listComponentsTemplate.push({
                        id: 'who_owns',
                        key: listComponentsTemplate.length,
                        name: 'Who owns',
                        header: 'Who here owns it?'
                    })
                } else {
                    notification.info({ message: 'Email template with this name already exists!', duration: 4 })
                }
                break
            case 'Retention schedule':
                if (!sectionNames.includes('Retention schedule')) {
                    listComponentsTemplate.push({
                        id: 'retention_schedule',
                        key: listComponentsTemplate.length,
                        name: 'Retention schedule',
                        header: 'Retention schedule (if possible)'
                    })
                } else {
                    notification.info({ message: 'Email template with this name already exists!', duration: 4 })
                }
                break
            case 'General description':
                if (!sectionNames.includes('General description')) {
                    listComponentsTemplate.push({
                        id: 'general_description',
                        key: listComponentsTemplate.length,
                        name: 'General description',
                        header: 'General description of technical and organisational security measures (if possible)'
                    })
                } else {
                    notification.info({ message: 'Email template with this name already exists!', duration: 4 })
                }
                break
            case 'Source personal':
                if (!sectionNames.includes('Source personal')) {
                    listComponentsTemplate.push({
                        id: 'source_personal',
                        key: listComponentsTemplate.length,
                        name: 'Source personal',
                        header: 'Source of personal data'
                    })
                } else {
                    notification.info({ message: 'Email template with this name already exists!', duration: 4 })
                }
                break
            case 'Link record':
                if (!sectionNames.includes('Link record')) {
                    listComponentsTemplate.push({
                        id: 'link_record',
                        key: listComponentsTemplate.length,
                        name: 'Link record',
                        header: 'Link to record of consent (connector)'
                    })
                } else {
                    notification.info({ message: 'Email template with this name already exists!', duration: 4 })
                }
                break
            default: break
        }
        this.setState({
            listComponentsTemplate,
            optionSelect: 'No template'
        })
    }
    onSelectOptionTemplate = (e) => {
         this.setState({
             optionSelect: e
         })
    }
    addSaveAsNewTemplate = () => {
      const { optionSelect } = this.state
        this.onSelectAddTemplate(optionSelect)
    }
    onGenerateComponentsDataRopa = (component, onChangeDataSource, showConfirmDeleteTemplate) => {
        switch (component.name) {
            case 'Business function':
                return <BusinessTemplate component={component} onChangeDataSource={ onChangeDataSource } showConfirmDeleteTemplate={ showConfirmDeleteTemplate }/>
                break
            case 'Category personal':
                return <BusinessTemplate component={component} onChangeDataSource={ onChangeDataSource } showConfirmDeleteTemplate={ showConfirmDeleteTemplate }/>
                break
            case 'Who owns':
                return <BusinessTemplate component={component} onChangeDataSource={ onChangeDataSource } showConfirmDeleteTemplate={ showConfirmDeleteTemplate }/>
                break
            case 'Retention schedule':
                return <BusinessTemplate component={component} onChangeDataSource={ onChangeDataSource } showConfirmDeleteTemplate={ showConfirmDeleteTemplate }/>
                break
            case 'General description':
                return <BusinessTemplate component={component} onChangeDataSource={ onChangeDataSource } showConfirmDeleteTemplate={ showConfirmDeleteTemplate }/>
                break
            case 'Source personal':
                return <BusinessTemplate component={component} onChangeDataSource={ onChangeDataSource } showConfirmDeleteTemplate={ showConfirmDeleteTemplate }/>
                break
            case 'Link record':
                return <BusinessTemplate component={component} onChangeDataSource={ onChangeDataSource } showConfirmDeleteTemplate={ showConfirmDeleteTemplate }/>
                break
            default:
                break
        }
    }
    getDataSourceConnectors = (targetKeys) => {
        const { listConnectors, dataSource } = this.state
        const targetKeysDataSource = []
        const dataSourceConnectors = []
        const extractSourceConnectors = []
        dataSource && dataSource.length > 0 && dataSource.forEach((item) => {
            if (targetKeys.includes(item.key)) {
               item.dataSourceConnectorUpdate && item.dataSourceConnectorUpdate.length > 0 && item.dataSourceConnectorUpdate.forEach((item2) => {
                   extractSourceConnectors.push(item2)
               })
            }

        })
        extractSourceConnectors && extractSourceConnectors.length > 0 && extractSourceConnectors.forEach((item3, index) => {
            const data = {
                key: index.toString(),
                title: item3.name,
                id: item3.id,
                type: item3.type,
                description: `description of content${item3.name + 1}`,
                //chosen: Math.random() * 2 > 1,
            }
            dataSourceConnectors.push(data)
        })
        // for (let i = 0; i < 20; i++) {
        //     const data = {
        //         key: i.toString(),
        //         title: `connector${i + 1}`,
        //         description: `description of content${i + 1}`,
        //         //chosen: Math.random() * 2 > 1,
        //     }
        //     dataSourceConnectors.push(data)
        // }
        const oriTargetKeys = dataSourceConnectors
            .filter((item) => +item.key % 3 > 1)
            .map((item) => item.key);
        this.setState({ dataSourceConnectors, targetKeysDataSource: oriTargetKeys })
    }
    getDataSource = () => {
            const { listConnectors } = this.state
        const targetKeysDataSource = []
        const targetKeys = []
        const dataSource = []
        listConnectors && listConnectors.length > 0 && listConnectors.forEach((item, index) => {
            const connectorList = []
            const connectorListUpdate = []
            const connectorDataList = []
            const connectorDataListUpdate = []
            item.connectorList &&  item.connectorList.length > 0 &&  item.connectorList.forEach((item2) => {
                connectorList.push(item2.connectorProvider)
                connectorListUpdate.push({
                    name: item2.connectorProvider,
                    id: item2.connectorId,
                    type: 'connectorList'
                })
            })
            item.dataLocationList &&  item.dataLocationList.length > 0 && item.dataLocationList.forEach((item2) => {
                connectorDataList.push(item2.text)
                connectorDataListUpdate.push({
                    name: item2.text,
                    id: item2.id,
                    type: 'dataLocationList'
                })
            })
            const data = {
                key: index.toString(),
                title: item.dataHoldingInfoList && item.dataHoldingInfoList.length > 0 && item.dataHoldingInfoList[0].text ? item.dataHoldingInfoList[0].text : '',
                description: item.dataHoldingInfoList && item.dataHoldingInfoList.length > 0 && item.dataHoldingInfoList[0].text ? item.dataHoldingInfoList[0].text : '',
                dataSourceConnector: connectorList.concat(connectorDataList),
                dataSourceConnectorUpdate: connectorListUpdate.concat(connectorDataListUpdate),
                sourceMappingId: item.sourceMappingId
                //chosen: Math.random() * 2 > 1,
            }
            dataSource.push(data)
        })
        // for (let i = 0; i < 20; i++) {
        //     const data = {
        //         key: i.toString(),
        //         title: `content${i + 1}`,
        //         description: `description of content${i + 1}`,
        //         //chosen: Math.random() * 2 > 1,
        //     }
        //      dataSource.push(data)
        // }
        this.setState({ dataSource, targetKeys })
    }
    handleChangeTransferSource = (targetKeys, direction, moveKeys) => {
            const { dataSource } = this.state
        const dataSourceIds = []
        dataSource && dataSource.length > 0 && dataSource.forEach((item) => {
            if (targetKeys.includes(item.key)) {
                dataSourceIds.push(item.sourceMappingId)
            }
        })
        this.setState({ targetKeys, targetKeysDataSource: targetKeys, dataSourceIds: dataSourceIds }, () => {
            this.getDataSourceConnectors(targetKeys)
        })
    }
    onSelectSource = (sourceSelectedKeys, targetSelectedKeys) => {
        const selectKeys = [...sourceSelectedKeys]
        this.setState({
            selectedKeysSource: [...sourceSelectedKeys, ...targetSelectedKeys]
        })
    }
    renderItemDataSource = (item) => {
        const customLabel = (
            <span className="custom-item">
             {item.title}
          </span>
        )

        return {
            label: customLabel, // for displayed item
            value: item.title, // for title and filter matching
        }
    }
    renderTitleDataSource() {
        return <span>dddd</span>
    }

    handleChangeTransferConnectors = (nextTargetKeys, direction, moveKeys) => {
            const { dataSourceConnectors } = this.state
        const connectorIds = []
        const dataLocationIds = []
        dataSourceConnectors && dataSourceConnectors.length > 0 && dataSourceConnectors.forEach((item) => {
            if (nextTargetKeys.includes(item.key) && item.type === 'connectorList') {
                connectorIds.push(item.id)
            } else
            if (nextTargetKeys.includes(item.key) && item.type === 'dataLocationList') {
                dataLocationIds.push(item.id)
            }
        })
        this.setState({ targetKeysDataConnectors: nextTargetKeys,
            connectorIds: connectorIds, dataLocationIds: dataLocationIds })
    }
    renderItemDataConnectors = (item) => {
        const customLabel = (
            <span className="custom-item">
             {item.title}
          </span>
        )

        return {
            label: customLabel, // for displayed item
            value: item.title, // for title and filter matching
        }
    }
    onSelectConnectors = (sourceSelectedKeys, targetSelectedKeys) => {
        const selectKeys = [...sourceSelectedKeys, ...targetSelectedKeys]
        this.setState({
            selectedKeysConnectors: selectKeys
        })
    }
    onHoverAddTemplate = () => {
        this.setState({
            hoverAdd: true
        })
    }
    onLeaveAddTemplate = () => {
        this.setState({
            hoverAdd:false
        })
    }
    handleDropComponent = (droppedItem) => {
        if (!droppedItem.destination) return
        const updatedList = [...this.state.listComponentsTemplate];
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        updatedList && updatedList.length > 0 && updatedList.forEach((item, index) => {
            item.key = index
        })
        this.setState({
            listComponentsTemplate: updatedList
        })
    }
    onProvidedDraggable = (provided) => {
        return {...provided.draggableProps}
    }
    onChangeDataSource = (builder, component) => {
            const { listComponentsTemplate } = this.state
        listComponentsTemplate && listComponentsTemplate.length > 0 &&  listComponentsTemplate.forEach((list) => {
            if (list.key === component.key) {
                list.inputName = builder.inputName
            }
        })
        this.setState({
            listComponentsTemplate
        })
    }
    showConfirmDeleteTemplate = (e, component) => {
        customConfirm({
            title: <span className="icon-v14-delete"></span>,
            content: `Delete template?`,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            mask: true,
            maskClosable: false,
            closeHaveLogic: false,
            className:'asset-register-modal modal-delete-connector',
            centered: true,
            cancelClassName: 'cancel-button',
            confirmClassName: 'confirm-button',
            confirmAction: () => {
                this.onActionDeleteTemplate(e, component)
            }
        })
    }
    onActionDeleteTemplate = (e, component) => {
        const { listComponentsTemplate } = this.state
        if (listComponentsTemplate && listComponentsTemplate.length > 0) {
            let filtersForm = []
            filtersForm = listComponentsTemplate.filter((elem) => elem.key !== component.key)

            this.setState({
                listComponentsTemplate: filtersForm,
                optionSelect: 'No template'
            })
        }
    }
    onCancelEmploymentContracts = () => {
       this.setState({
           selectTemplate: null,
           valueEmploymentContracts: '',
           listComponentsTemplate: [],
           targetKeys: [],
           dataSourceConnectors: [],
           targetKeysDataConnectors: [],
           selectedKeysSource: [],
           optionSelect: 'No template'
       })
    }
    onChangeEmploymentContractsName = (e) => {
        this.setState({
            valueEmploymentContracts: e.target.value
        })
    }

    onSaveEmploymentContracts = () => {
            const { valueEmploymentContracts, connectorIds, listComponentsTemplate, dataLocationIds, dataSourceIds } = this.state
        const components = []
        listComponentsTemplate && listComponentsTemplate.length > 0 && listComponentsTemplate.forEach((item, index) => {
            components.push({
                componentType: item.name,
                componentTitle: item.header,
                componentValue: item.inputName,
                componentPosition: index,
            })
        })
        const createBusinessProcessTemplateRequestDTO = {
            clientId: this.props.clientId,
            templateName: valueEmploymentContracts,
            connectorIds: connectorIds,
            dataLocationIds: dataLocationIds,
            dataSourceIds: dataSourceIds,
            components: components
        }
        apiCreateBusinessProcessTemplate(createBusinessProcessTemplateRequestDTO).then((response) => {
            if(response.data.title.toLowerCase() === 'success') {
                CustomNotificationManager.success(response.data.message, 'Success')
            } else {
                CustomNotificationManager.info(response.data.message, 'Info')
            }
            this.onGetBusinessProcessTemplate()
        })
    }

    onEditEmploymentContracts = () => {
        const { valueEmploymentContracts, connectorIds, listComponentsTemplate, dataLocationIds, dataSourceIds, selectTemplate } = this.state
        const components = []
        listComponentsTemplate && listComponentsTemplate.length > 0 && listComponentsTemplate.forEach((item, index) => {
            components.push({
                componentType: item.name,
                componentTitle: item.header,
                componentValue: item.inputName,
                componentPosition: index,
            })
        })
        const editBusinessProcessTemplateRequestDTO = {
            templateId: selectTemplate,
            clientId: this.props.clientId,
            templateName: valueEmploymentContracts,
            connectorIds: connectorIds,
            dataLocationIds: dataLocationIds,
            dataSourceIds: dataSourceIds,
            components: components
        }
        apiEditBusinessProcessTemplate(editBusinessProcessTemplateRequestDTO).then((response) => {
            if(response.data.title.toLowerCase() === 'success') {
                CustomNotificationManager.success(response.data.message, 'Success')
            } else {
                CustomNotificationManager.info(response.data.message, 'Info')
            }
            this.onGetBusinessProcessTemplate()
        })
    }
    onSelectBusinessTemplate = (e) => {
            const { getListBusinessProcessTemplate, dataSource, listConnectors } = this.state
        const listTemplate = []
        const targetKeys = []
        const connectors = []
        const connectorsUpdate = []
        const dataLocations = []
        const dataLocationsUpdate = []
        let listConcatConnection = []
        let listConcatConnectionUpdate = []
        const keysFinally = []
        const keysFinallyName = []
        const keysFinallyUpdate = []
        this.setState({
            selectTemplate: e,
            listComponentsTemplate: []
        }, () => {
            apiGetBusinessProcessTemplateId(e).then((response) => {
                listConnectors  && listConnectors .length > 0 && listConnectors.forEach((item) => {
                    if (response.data.dataSources && response.data.dataSources.length > 0 && response.data.dataSources.includes(item.sourceMappingId)) {
                        if (item.connectorList && item.connectorList.length > 0) {
                            item.connectorList.forEach((item, index) => {
                                connectorsUpdate.push({
                                    //key: index.toString(),
                                    title: item.connectorProvider,
                                    id: item.connectorId,
                                    type: 'connectorList',
                                    description: `description of content${item.connectorProvider + 1}`,
                                })
                            })
                        }
                        if (item.dataLocationList && item.dataLocationList.length > 0) {
                            item.dataLocationList.forEach((item, index) => {
                                dataLocationsUpdate.push({
                                    //key: index.toString(),
                                    title: item.text,
                                    id: item.id,
                                    type: 'dataLocationList',
                                    description: `description of content${item.text + 1}`,
                                })
                            })
                        }
                    }
                })
                dataSource && dataSource.length > 0 && dataSource.forEach((item) => {
                    if (response.data.dataSources && response.data.dataSources.length > 0 && response.data.dataSources.includes(item.sourceMappingId)) {
                        targetKeys.push(item.key)
                    }
                })
                if (response.data && response.data.connectors && response.data.connectors.length > 0) {
                    response.data.connectors.forEach((item, index) => {
                        connectors.push({
                            //key: index.toString(),
                            title: item.connectorProvider,
                            id: item.connectorId,
                            type: 'connectorList',
                            description: `description of content${item.connectorProvider + 1}`,
                        })
                    })
                }
                if (response.data && response.data.dataLocations && response.data.dataLocations.length > 0) {
                    response.data.dataLocations.forEach((item, index) => {
                        dataLocations.push({
                            //key: index.toString(),
                            title: item.dataLocation,
                            id: item.id,
                            type: 'dataLocationList',
                            description: `description of content${item.dataLocation + 1}`,
                        })
                    })
                }

                listConcatConnection = connectors.concat(dataLocations)
                listConcatConnectionUpdate = connectorsUpdate.concat(dataLocationsUpdate)

                listConcatConnection && listConcatConnection.length > 0 && listConcatConnection.forEach((item, index) => {
                    item.key = index.toString()
                })
                listConcatConnectionUpdate && listConcatConnectionUpdate.length > 0 && listConcatConnectionUpdate.forEach((item, index) => {
                    item.key = index.toString()
                })
                if (response.data && response.data.components && response.data.components.length > 0) {
                    response.data.components.forEach((item, index) => {
                        listTemplate.push({
                            header: item.componentTitle,
                            id: item.componentType,
                            name: item.componentType,
                            inputName: item.componentValue,
                            //componentPosition: item.index,
                            key: index,
                        })
                    })
                }
                listConcatConnection && listConcatConnection.length > 0 && listConcatConnection.forEach((item, index) => {
                    keysFinally.push(item.key)
                    keysFinallyName.push(item.id)
                })
                listConcatConnectionUpdate && listConcatConnectionUpdate.length > 0 && listConcatConnectionUpdate.forEach((item, index) => {
                    if (keysFinallyName.includes(item.id)) {
                        keysFinallyUpdate.push(item.key)
                    }
                })
                this.setState({
                    valueEmploymentContracts: response.data.templateName,
                    listComponentsTemplate: listTemplate,
                    targetKeys: targetKeys,
                    dataSourceConnectors: listConcatConnectionUpdate,
                    targetKeysDataConnectors: keysFinallyUpdate,
                })
            })
        })
    }
    showConfirmDeleteTemplateBusiness = (e) => {
        const { valueEmploymentContracts } = this.state
        if (valueEmploymentContracts !== '') {
            customConfirm({
                title: <span className="icon-v14-delete"></span>,
                content: `Delete template ${valueEmploymentContracts}?`,
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel',
                mask: true,
                maskClosable: false,
                closeHaveLogic: false,
                className: 'asset-register-modal modal-delete-connector',
                centered: true,
                cancelClassName: 'cancel-button',
                confirmClassName: 'confirm-button',
                confirmAction: () => {
                    this.onDeleteTemplate(e)
                }
            })
        }
    }
    onDeleteTemplate = () => {
            const { selectTemplate } = this.state
            const ids = []
        ids.push(selectTemplate)
        apiDeleteBusinessProcessTemplate(ids).then((response) => {
            if(response.data.title.toLowerCase() === 'success') {
                CustomNotificationManager.success(response.data.message, 'Success')
            } else {
                CustomNotificationManager.info(response.data.message, 'Info')
            }
            this.setState({
                selectTemplate: null,
                valueEmploymentContracts: '',
                listComponentsTemplate: [],
                targetKeys: [],
                dataSourceConnectors: [],
                targetKeysDataConnectors: [],
                selectedKeysSource: [],
                optionSelect: 'No template'
            }, () => {
                this.getConnectorsList()
                this.onGetBusinessProcessTemplate()
            })
        })
    }
    onHoverAction = () => {
        this.setState({
                hoverDelete:true,
            })
    }
    onLeaveAction = () => {
        this.setState({
            hoverDelete:false,
        })
    }
    render() {
        const { selectedKeysSource, dataSourceConnectors, targetKeysDataConnectors, selectedKeysConnectors, hoverAdd,
            listAddTemplate, listComponentsTemplate, optionSelect, valueEmploymentContracts, connectorIds, getListBusinessProcessTemplate,
            selectTemplate, listConnectors, hoverDelete } = this.state
        return (
            <div className='data-source'>
                <div className="data-source__page">
                    <header className="header-data-source">
                        <h1>Employment contracts</h1>
                        <div className="content-edit-template">
                            <Select className="select-form"
                                    placeholder="Select form"
                                    dropdownClassName={'select-dropdown'}
                                    value={ !selectTemplate ? 'Select form' : selectTemplate }
                                    onChange={(e) => this.onSelectBusinessTemplate(e)}>
                                { getListBusinessProcessTemplate.map((item,index) => {
                                    return  (<Option className="options-select-connector" value={item.templateId} key={index}>
                                        {item.templateName}
                                    </Option>)
                                })}
                            </Select>
                            <button disabled={ selectTemplate === null }
                                    className={ hoverDelete ? "icon-v19-delete-active" : "icon-v19-delete"}
                                    onMouseEnter={() => this.onHoverAction()}
                                    onMouseLeave={() => this.onLeaveAction()}
                                    onClick={(e) => this.showConfirmDeleteTemplateBusiness(e)}
                            ></button>
                        </div>
                    </header>
                    <div className="action-header-data-source">
                        <Input className="input-form-name" placeholder="Template name"
                        value={ valueEmploymentContracts }
                        onChange={(e) => this.onChangeEmploymentContractsName(e)}/>
                        <div className="content-buttons">
                        <Button onClick={(e) => this.onCancelEmploymentContracts(e)} className="button-cancel-source">Cancel</Button>
                            { selectTemplate ?
                                <Button onClick={(e) => this.onEditEmploymentContracts(e)} className="button-save-source"
                                        disabled={valueEmploymentContracts === ''}>Save</Button> :
                                <Button onClick={(e) => this.onSaveEmploymentContracts(e)} className="button-save-source"
                                        disabled={valueEmploymentContracts === ''}>Save</Button>
                            }
                        </div>
                    </div>
                    <div className="data-source-transfer-content">
                      <div className="data-source-transfer data-source-transfer-one">
                          <Transfer
                              dataSource={ this.state.dataSource }
                              listStyle={{
                                  width: 280,
                                  height: 200,
                              }}
                              titles={[<span className="header-list-source">Data sources</span>]}
                              className="transfer-data"
                              targetKeys={ this.state.targetKeys }
                              onChange={this.handleChangeTransferSource}
                              render={this.renderItemDataSource}
                              showSelectAll={false}
                              onSelectChange={this.onSelectSource}
                              selectedKeys={selectedKeysSource}
                          />
                      </div>
                      <div className="content-caret"><Icon type="caret-right" /></div>
                      <div className="data-source-transfer data-source-transfer-two">
                          <Transfer
                              dataSource={ dataSourceConnectors }
                              listStyle={{
                                  width: 280,
                                  height: 200,
                              }}
                              titles={[<span className="header-list-source">Data source connectors</span>]}
                              className="transfer-data"
                              targetKeys={ targetKeysDataConnectors }
                              onChange={this.handleChangeTransferConnectors}
                              render={this.renderItemDataConnectors}
                              showSelectAll={false}
                              onSelectChange={this.onSelectConnectors}
                              selectedKeys={selectedKeysConnectors}
                          />
                      </div>
                    </div>
                    <div className="save-new-template">
                        <Select className="select-form"
                                placeholder="No template"
                                dropdownClassName="select-dropdown"
                                value= { optionSelect }
                                onChange={(e) => this.onSelectOptionTemplate(e)}>
                            { listAddTemplate.map((item,index) => {
                                return  (<Option className="options-select-connector" value={item.name} key={index}>
                                    {item.header}
                                </Option>)
                            })}
                        </Select>
                        <span className="save-template">Save as new template</span>
                        <button className={ hoverAdd ? 'icon-v17-add-active' : "icon-v17-add" }
                                onClick={(e) => this.addSaveAsNewTemplate(e)}
                                onMouseEnter={(e) => this.onHoverAddTemplate(e)}
                                onMouseLeave={() => this.onLeaveAddTemplate()}/>
                    </div>
                    <div className="page-source-content">
                        <div className="form-source-content">
                            <DragDropContext onDragEnd={this.handleDropComponent}>
                                <Droppable droppableId="list-container">
                                    {(provided) => (
                                        <div
                                            className="list-container"
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {
                                                listComponentsTemplate && listComponentsTemplate.length > 0 && listComponentsTemplate.map((component, index) => {
                                                    return (
                                                        <Draggable key={component.key} draggableId={`${component.key}`} index={index}>
                                                            {(provided) => (
                                                                <div
                                                                    id={index}
                                                                    className="item-container"
                                                                    ref={provided.innerRef}
                                                                    {...provided.dragHandleProps}
                                                                    {...this.onProvidedDraggable(provided)}
                                                                >
                                                                    {
                                                                        this.onGenerateComponentsDataRopa(component, this.onChangeDataSource, this.showConfirmDeleteTemplate)
                                                                    }
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    )
                                                })
                                            }
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id,
})
export default connect(mapStateToProps, {
})(DataSourceRopa)