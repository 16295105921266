import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Navigate } from 'react-router-dom'
import { compose } from 'redux'
import * as messageActions from '../../../actions/messageActions'
import * as actions from '../../../actions/questionActions'
import { apiDpiaAssessmentSave } from '../../../api/Dpia'
import ErrorPage from '../../Common/ErrorPage'
import Loading from '../../Common/Loading'
import PiaCompleteBox from '../../Pia/PiaCompleteBox'
import ActiveQuestion from './ActiveQuestion'
import '../../../style/common.scss'
import './Questions.scss'
import CompleteBox from './Questions/CompleteBox'
import QuestionsHeaderBig from './Questions/Headers/QuestionsHeaderBig'
import QuestionsHeaderSmall from './Questions/Headers/QuestionsHeaderSmall'

export class Questions extends Component {
  constructor() {
    super()

    this.state = {
      isSending: false,
      NavigateToDashboard: false,
      NavigateToResult: false
    }

    this.fetchActiveQuestion = this.fetchActiveQuestion.bind(this)
  }

  componentDidMount() {
    window.scroll(0, 0)

    const assessmentId = this.props.params.assessmentId
    this.props.setAssessmentId(assessmentId)
    this.props.fetchQuestions(assessmentId)
    this.props.fetchAssessment(assessmentId)
  }

  componentWillUnmount() {
    this.props.setToLoadingQuestionsScreen()
  }

  fetchActiveQuestion(questionId) {
    const id = this.props.params.assessmentId
    this.props.fetchActiveQuestion(id, questionId)
  }

  confirmAnswer() {
    const id = this.props.params.assessmentId
    this.props.confirmAnswer(id, this.props.activeQuestion, this.props.allQuestions)
  }

  skipAnswer() {
    const id = this.props.params.assessmentId
    this.props.skipAnswer(id, this.props.activeQuestion, this.props.allQuestions)
  }

  submit() {
    this.setState({
      isSending: true
    })

    const { assessment } = this.props
    const idToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId
    const data = {
      id_token: idToken
    }
    const id = this.props.params.assessmentId

    apiDpiaAssessmentSave(data, id)
      .then((response) => {
        if (assessment.isPia) {
          this.setState({ NavigateToResult: true })
        } else {
          this.setState({ NavigateToDashboard: true })
        }
      }).catch((e) => {
        this.setState({
          isSending: false
        })
      })
  }

  backButtonUrl(process) {
    if (process.status === 'INACTIVE') return '/dpia/dashboard/archived'
    return '/dpia/dashboard'
  }

  render() {
    const {
      activeQuestion,
      allQuestions,
      assessment,
      assessmentStatus,
      complete,
      isLoadingAssessment,
      isLoadingQuestions,
      process,
      progress,
      questionsResults
    } = this.props

    const { NavigateToDashboard, NavigateToResult } = this.state
    
    return (
      <div className="questions-page">
        { NavigateToResult &&
          <Navigate to={ `/dpia/assessment/${ assessment.id }/result` } />
        }

        { NavigateToDashboard &&
          <Navigate to="/dpia/dashboard" />
        }

        { (isLoadingAssessment || isLoadingQuestions) &&
          <div className="questions-loading">
            <Loading />
          </div>
        }

        {
          !assessment.isLoading &&
          assessment.error &&
          <ErrorPage error={ assessment.error } />
        }

        {
          !process.isLoading &&
          !assessment.error &&
          <Link to={ this.backButtonUrl(process)} className="questions-backButton">Back to Assessments dashboard</Link>
        }

        { !isLoadingAssessment &&
          !isLoadingQuestions &&
          !process.isLoading &&
          !activeQuestion.isLoading &&
          !assessment.error &&
          <React.Fragment>
            {
              assessment.isPia &&
              <QuestionsHeaderSmall
                activeQuestion={ activeQuestion }
                questionsResults={ questionsResults }
                allQuestions={ allQuestions }
                fetchActiveQuestion={ this.fetchActiveQuestion }
                process={ process }
                progress={ progress }
              /> 
            }

            {
              !assessment.isPia &&
              <QuestionsHeaderBig
                activeQuestion={ activeQuestion }
                questionsResults={ questionsResults }
                allQuestions={ allQuestions }
                fetchActiveQuestion={ this.fetchActiveQuestion }
                process={ process }
                progress={ progress }
              /> 
            }

            { complete &&
              !assessment.isPia &&
              assessmentStatus !== 'COMPLETED' &&
              <CompleteBox
                isSending={ this.state.isSending }
                hideMessage={ this.props.hideMessage }
                messageArea={ this.props.messageArea }
                onClick={ () => this.submit() }
              />
            }

            { complete &&
              assessment.isPia &&
              assessmentStatus !== 'COMPLETED' &&
              <PiaCompleteBox
                isSending={ this.state.isSending }
                hideMessage={ this.props.hideMessage }
                messageArea={ this.props.messageArea }
                onClick={ () => this.submit() }
              />
            }

            <ActiveQuestion
              assessmentStatus={ assessmentStatus }
              confirmAnswer={ () => this.confirmAnswer() }
              processStatus={ process.status }
              question={ activeQuestion }
              questionsResults={ questionsResults }
              responseText={ this.props.responseText }
              showResponse={ this.props.showResponse }
              skipAnswer={ () => this.skipAnswer() }
              updateValueForCheckbox={ (value, option) => this.props.updateValueForCheckbox(value, option) }
              updateValueForOther={ (value, option) => this.props.updateValueForOther(value, option) }
              updateValueForRadio={ (value, option) => this.props.updateValueForRadio(value, option) }
            />
          </React.Fragment>
        }
      </div>
    )
  }
}

const stateMap = (state) => ({
  activeQuestion: state.questionReducer.questions.activeQuestion,
  allQuestions: state.questionReducer.questions.allQuestions,
  assessment: state.questionReducer.assessment,
  assessmentStatus: state.questionReducer.assessment.status,
  complete: state.questionReducer.questions.complete,
  isLoadingAssessment: state.questionReducer.assessment.isLoading,
  isLoadingQuestions: state.questionReducer.questions.isLoading,
  messageArea: state.messageAreaReducer.messageArea,
  process: state.questionReducer.assessment.process,
  progress: state.questionReducer.questions.progress,
  questionsResults: state.questionReducer.assessment.questionsResults,
  responseText: state.questionReducer.questions.responseText,
  showResponse: state.questionReducer.questions.showResponse
})

const dispatchMap = (dispatch) => ({
  confirmAnswer: (assessmentId, answer, questions) => dispatch(actions.confirmAnswer(assessmentId, answer, questions)),
  fetchActiveQuestion: (assessmentId, questionId) => dispatch(actions.fetchActiveQuestion(assessmentId, questionId)),
  fetchAssessment: (assessmentId) => dispatch(actions.fetchAssessment(assessmentId)),
  fetchQuestions: (assessmentId) => dispatch(actions.fetchQuestions(assessmentId)),
  hideMessage: () => dispatch(messageActions.hideMessage()),
  showMessage: (status, text) => dispatch(messageActions.showMessage({ status, text })),
  setAssessmentId: (assessmentId) => dispatch(actions.setAssessmentId(assessmentId)),
  skipAnswer: (assessmentId, answer, questions) => dispatch(actions.skipAnswer(assessmentId, answer, questions)),
  setToLoadingQuestionsScreen: () => dispatch(actions.setToLoadingQuestionsScreen()),
  updateValueForCheckbox: (value, option) => dispatch(actions.updateValueForCheckbox(value, option)),
  updateValueForOther: (value, option) => dispatch(actions.updateValueForOther(value, option)),
  updateValueForRadio: (value, option, answer) => dispatch(actions.updateValueForRadio(value, option, answer))
})

Questions.propTypes = {
  activeQuestion: PropTypes.object,
  complete: PropTypes.bool.isRequired,
  confirmAnswer: PropTypes.func.isRequired,
  fetchActiveQuestion: PropTypes.func.isRequired,
  fetchQuestions: PropTypes.func.isRequired,
  fetchAssessment: PropTypes.func.isRequired,
  hideMessage: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  messageArea: PropTypes.object.isRequired,
  process: PropTypes.object.isRequired,
  progress: PropTypes.number.isRequired,
  responseText: PropTypes.string,
  setAssessmentId: PropTypes.func.isRequired,
  skipAnswer: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  showResponse: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  updateValueForCheckbox: PropTypes.func.isRequired,
  updateValueForOther: PropTypes.func.isRequired,
  updateValueForRadio: PropTypes.func.isRequired
}

export default compose(connect(stateMap, dispatchMap))(Questions)
