import PropTypes from 'prop-types'
import React from 'react'
import LogoGravicus from '../../assets/Gravicus_Smartbox_Logo_Green.svg'
import './Footer.scss'

const isInSmartBox = () => { return window.location.href.includes('smart-box') }

const Footer = ({ showPrivacyPolicy, showTermsOfService }) => (
  <div className={ `footer-wrapper` + (isInSmartBox() ? ' in-smartbox' : '') }>
    {!isInSmartBox() &&
      <React.Fragment>
        <div className="footer-container">
          <img className="footer-logo" src={ LogoGravicus } alt="Osprey Logo"/>
          <nav>
            <ul className="footer-navigation">
              <li className="footer-listItem">
                <span className="footer-action" onClick={showPrivacyPolicy}>privacy policy</span>
              </li>
              <li className="footer-listItem">
                <span className="footer-action" onClick={showTermsOfService}>terms of service</span>
              </li>
            </ul>
          </nav>
        </div>
        <p className="footer-copyright">Copyright © {(new Date()).getFullYear()} <a href="https://gravicus.com" target="_blank" rel="noopener noreferrer" className="footer-action">Gravicus Technologies Ltd</a></p>
      </React.Fragment>
    }
  </div>
)

Footer.propTypes = {
  showPrivacyPolicy: PropTypes.func.isRequired,
  showTermsOfService: PropTypes.func.isRequired,
}

export default Footer
