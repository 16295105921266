import React from 'react'
import { Menu, notification } from 'antd'
import PropTypes from 'prop-types'
import {
  apiSmartBoxCheckBulkFolderFinalise,
  apiSmartBoxFinalisedFolder,
  apiSmartBoxZip,
  deleteSmartDocument,
  getFolderChildren
} from '../../../../api/SmartBox'
import { customConfirm } from '../../../../utils/AssetRegister/CustomConfirm'
import DirectoryNote from '../DirectoryNote'
import FileDownload from 'js-file-download'
import { connect } from 'react-redux'
import { addIntervalToApplication } from '../../../../actions/applicationsActions'
import {
  removeSmartBoxIntervalForBulkFinalise,
  setSmartBoxIntervalForBulkFinalise
} from '../../../../actions/smartBoxActions'
import CustomNotificationManager from '../../../Common/CustomNotificationManager'
import {ClickOutside} from "reactjs-click-outside";
import withRouter from '../../../../private-routers/withRouter'

class SmartBoxSettingsMenu extends React.Component {

  state = {
    documentNoteVisible: false,
    childrenIsNotEmpty: 0,
    childrenLength: 0,
    isIntervalStartedForBulkFinalise: false,
    openKeys: [],
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.smartBoxMenuOpen !== this.props.smartBoxMenuOpen) {
      this.forceUpdate()
    }
  }

  onDownloadZip = (id, name) => {
    apiSmartBoxZip(id, name).then((response) => {
      FileDownload(response.data, `${ name }.zip`)
    })
  }

  toggleNoteVisible = (e, open) => {
    this.setState({ documentNoteVisible: open })
  }

  startIntervalFinalise = () => {
    if (this.props.folder) {
      const { folder } = this.props
      const currentLocation = this.props.location
      this.props.toggleLoadingOpenedFolder(true)
      this.props.toggleSmartBoxMenu(false)
      this.setState({
        isIntervalStartedForBulkFinalise: true
      }, () => {
        this.props.setSmartBoxIntervalForBulkFinalise(currentLocation)
        const bulkReadyInterval = setInterval(() => {
          apiSmartBoxCheckBulkFolderFinalise(folder.id).then((res) => {
            const message = <span>{'Finalise folder process finished for '}<span
              style={ { textDecoration: 'underline', cursor: 'pointer' } }><span
                onClick={ () => this.props.navigate('/smart-box/management') }>{ folder.name }</span></span></span>
            if (res.data) {
              notification.success({ message: message, duration: 1000000 })
              this.props.removeSmartBoxIntervalForBulkFinalise(this.props.intervalForBulkRedaction.filter((path) => path !== currentLocation))
              this.setState({
                isIntervalStartedForBulkFinalise: false
              }, () => this.props.fetchFiles())
              clearInterval(bulkReadyInterval)
            }
            this.forceUpdate()
            this.props.toggleLoadingOpenedFolder(false)
          }).catch((error) => {
            clearInterval(bulkReadyInterval)
            if (error?.response?.status === 504) {
              CustomNotificationManager.cleanBuffer('warning', '')
            }else{
              console.log(error)
            }
          })
          this.props.toggleLoadingOpenedFolder(false)

        }, 10000)
        this.props.addIntervalToApplication(bulkReadyInterval)
      })
    }
  }

  onFinaliseFolder = (id) => {
    apiSmartBoxFinalisedFolder(id).then(() => {
      this.props.fetchFiles()
      this.startIntervalFinalise()
    }).catch((error) => {
      const errorMessage = 'Folder id does not exist'
      if (error?.response?.status === 500) {
        if (typeof error.response.data.message !== 'undefined' && error.response.data.message.indexOf(errorMessage) > 0) {
          CustomNotificationManager.error('The current folder no longer exists or you do not have access to its settings', 'Error')
          const path = '/smart-box'
          this.props.navigate(path)
        }
      }else if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  deleteFolder = async(e) => {
    const selectedFile= this.props.folder
    const { childrenIsNotEmpty, childrenLength }= this.state
    const parentId = {
      id: selectedFile.id,
      filePath: selectedFile.path,
      folder: selectedFile.type === 'DIRECTORY'
    }
    const fileList = await getFolderChildren(parentId)
    this.setState({
      childrenIsNotEmpty: fileList.data.length > 0,
      childrenLength: fileList.data.length
    }, () => {
      customConfirm({
        confirmAction: () => deleteSmartDocument(parentId).then((resp) => {
          if (resp.data === 'Please wait until upload finishes!') {
            CustomNotificationManager.info('', 'Please wait until upload finishes!', { duration: 5 })
          }
          this.props.navigate('/smart-box')
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
        }),
        title: 'Do you want to delete item ?',
        content: `${ selectedFile.name } ${ childrenIsNotEmpty ? `contains ${ childrenLength } items` : '' } ?`,
        confirmButtonText: 'DELETE',
        cancelButtonText: 'CANCEL',
        mask: true,
        maskClosable: false,
        closeHaveLogic: false,
        className: 'asset-register-modal',
        centered: true,
        cancelClassName: 'cancel-button',
        confirmClassName: 'confirm-button'
      })
    })
  }

  onMenuOpenChange = (e) => {
    if (e.length) {
      this.setState({ openKeys: e })
    }
  }

  onMenuClick = () => {
    this.setState({ openKeys: [] })
  }

  render() {
    const { documentNoteVisible, openKeys }= this.state
    const { folder } = this.props
    const { SubMenu } = Menu
    let folderPathSecurity = ''
    if (folder) {
      const folderPath = folder.relativePath
      if (folderPath) {
        folderPathSecurity = folderPath.split('/').length - 1
      }
    }
    if (this.props) {
      // const openKeys = smartBoxMenuOpen ? ['1'] : []
      let menuTitle = ''
      switch (this.props.activePage) {
        case 1: menuTitle= 'Data Insights'; break
        case 2: menuTitle= 'Analysis Settings'; break
        case 3: menuTitle= 'Folder Redaction'; break
        case 4: menuTitle= 'Security Settings'; break
        case 5: menuTitle= 'Folder Info'; break
        default: menuTitle= 'Content'
      }
      return (
        <React.Fragment>
          <ClickOutside outsideCall={()=> this.onMenuClick() }>
            <Menu
              mode="inline"
              className="smart-box-settings-menu"
              openKeys={ openKeys }
              onOpenChange={ (e) => this.onMenuOpenChange(e) }
              onClick={ () => this.onMenuClick() }
            >
              <SubMenu
                key= "1"
                title={ menuTitle }
              >
                <Menu.Item key="0" onClick={ () => this.props.navigate(`/smart-box/folder/${ this.props.folder.id }`) }>Content</Menu.Item>
                <Menu.Item key="2" onClick={ () => this.props.navigate(`/smart-box/folder/${ this.props.folder.id }/analysis-settings`) }>Analysis Settings</Menu.Item>
                <Menu.Item key="1" onClick={ () => this.props.navigate(`/smart-box/folder/${ this.props.folder.id }/data-insights`) }>Data Insights</Menu.Item>
                <Menu.Item key="3" onClick={ () => this.props.navigate(`/smart-box/folder/${ this.props.folder.id }/folder-redaction`) }>Folder Redaction</Menu.Item>
                { this.props.folder.bulkProcessingStarted === 'finished' &&
                  <Menu.Item key="4" onClick={ () => this.onDownloadZip(folder.id, folder.name) }>Export
                    finalised</Menu.Item>
                }
                <Menu.Item key="5" onClick={ () => this.props.toggleRenameFolderModal(true) }>Rename</Menu.Item>
                <Menu.Item key="6" onClick={ (e) => this.deleteFolder(e) }>Delete</Menu.Item>
                <Menu.Item key="7" onClick={ (e) => this.toggleNoteVisible(e, true) } >Add a Note</Menu.Item>
                {/*<Menu.Item key="8" onClick={ (e) => this.onFinaliseFolder(folder.id) } >{ folder.finaliseFolder ? 'Update redaction versions' : 'Create redacted versions' }</Menu.Item>*/}
                <Menu.Item key="9" onClick={ this.props.toggleFilePreview } disabled={ [1, 2, 3, 4].includes(this.props.activePage) ? true : false}>Folder Info</Menu.Item>
                { folderPathSecurity && folderPathSecurity === 1 &&
                  <Menu.Item key="10"
                    onClick={ () => this.props.navigate(`/smart-box/folder/${ folder.id }/security-settings`) }>Security
                    Settings</Menu.Item>
                }
              </SubMenu>
            </Menu>
          </ClickOutside>
          <React.Fragment key={`notes-${ folder.id }`}>
            { documentNoteVisible ? <DirectoryNote closeDocumentNote={ (e) => this.toggleNoteVisible(e, false) } documentId={ folder.id } documentName={ folder.name } user={ this.props.user } /> : '' }
          </React.Fragment>
        </React.Fragment>
      )
    }
  }
}

const mapStateToProps = (state) => ({
  intervalForBulkRedaction: state.smartBox.intervalForBulkRedaction
})
SmartBoxSettingsMenu= withRouter(SmartBoxSettingsMenu)
SmartBoxSettingsMenu.propType = {
  folder: PropTypes.object.isRequired,
  activePage: PropTypes.string,
  smartBoxMenuOpen: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  toggleRenameFolderModal: PropTypes.func
}

export default connect(mapStateToProps, {
  addIntervalToApplication,
  setSmartBoxIntervalForBulkFinalise,
  removeSmartBoxIntervalForBulkFinalise,
})(SmartBoxSettingsMenu)