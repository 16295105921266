import initialStateSar from './initialStateSar'
import {
  CHANGE_FORM,
  LOAD_SAR_FOR_USER,
  LOAD,
  SET_AI_ANNOTATIONS_STATUS,
  SET_SAR_PATH_AI_READY,
  SET_SAR_PAGE_LIMIT
} from '../actions/actionTypes'
/*const INITIAL_STATE = {
  sarPathAiReady: '',
  sarAiAnnotationsStatus: false,
  sarPageLimit: 10
}*/
const sarFormReducer = (state = initialStateSar, action) => {
  switch (action.type) {
    case LOAD:
      return {
        ...state, sar: action.payload
      }

    case LOAD_SAR_FOR_USER:
      return {
        ...state, sarUserForm: action.payload
      }

    case CHANGE_FORM:
      return {
        ...state, sarUserForm: { ...state.sarUserForm, [action.payload.field]: action.payload.value }
      }
    case SET_SAR_PATH_AI_READY:
      return {
        ...state, sarPathAiReady: action.payload
      }
    case SET_AI_ANNOTATIONS_STATUS:
      return {
        ...state, sarAiAnnotationsStatus: action.payload
      }
    case SET_SAR_PAGE_LIMIT:
      return {
        ...state, sarPageLimit: action.payload
      }
    default:
      return state
  }
}
export const load = (data) => ({ type: LOAD, payload: data })

export default sarFormReducer