import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'

import CollectionsList from '../Form/CollectionsList'
import ConnectorsList from '../Form/ConnectorList'

import WithAlerts from '../HOC/WithAlerts'
import WithCollections from '../HOC/WithCollections'
import WithDataModels from '../HOC/WithDataModels'

import './Filters.css'

class Filters extends React.Component {
  state = { objectTypes: [] }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.facetValues !== this.props.facetValues) {
      this.updateObjectTypes(nextProps.facetValues)
    }
  }

  updateObjectTypes = (facetValues) => {
    const extensions = facetValues
      .filter((facet) => facet.name === 'doc_ext')

    this.setState({ objectTypes: extensions[0].values.value })
  }

  render = () => {
    return (
      <div>
        <div className="filterFiles-wrapper">
          <div className="filterFilesItem-button">
            <label className="filterFilesItem-labelCentered">Find</label>
            <input type='text' className="filterFilesItem-inputFind"></input>
          </div>

          <div className="filterFilesItem-select">
            <label>Collections</label>
            <CollectionsList
              value={ this.props.selectedCollection }
              onChange={ this.props.filterBy.bind(this, 'selectedCollection') } />
          </div>

          <div className="filterFilesItem-select">
            <label>Alert ID</label>
            <select
              disabled={ this.props.selectedCollection === '' || this.props.alerts.length === 0 }
              value={ this.props.selectedAlert }
              onChange={ this.props.filterBy.bind(this, 'selectedAlert') }>
              <option value="">choose...</option>
              { this.props.alerts.map((alert) => (
                <option key={ alert.id } value={ alert.id }>{ alert.name }</option>
              )) }
            </select>
          </div>

          <div className="filterFilesItem-select">
            <label>Data Connectors</label>
            <ConnectorsList onChange={ this.props.filterBy.bind(this, 'selectedConnector') } />
          </div>

          <div className="filterFilesItem-select">
            <label>Object Types</label>
            <select onChange={ this.props.filterBy.bind(this, 'selectedObjectType') }>
              <option value="">choose...</option>
              { this.state.objectTypes.map((type) => (
                <option key={ type.query } value={ type.query }>{ type.name }</option>
              ))}
            </select>
          </div>
        </div>
      </div>
    )
  }
}

Filters.propTypes = {
  alerts: PropTypes.array.isRequired,
  collections: PropTypes.array.isRequired,
  facetValues: PropTypes.array.isRequired,
  filterBy: PropTypes.func.isRequired
}

export default compose(
  WithAlerts,
  WithCollections,
  WithDataModels
)(Filters)