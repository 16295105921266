import PropTypes from 'prop-types'
import React from 'react'
import { truncateString } from '../../../../utils'
import { questionTypes } from '../../../../utils/StaticTexts'
import Accordion from '../../../Common/Accordion'
import InputValidator from '../../../Common/InputValidator'
import MultiSelect from '../../../Common/MultiSelect'
import ToggleAccordion from '../../../Common/ToggleAccordion'
import MultipleBuilder from './Options/Helpers/MultipleBuilder'
import ResetOptions from './Options/Helpers/ResetOptions'
import YNBuilder from './Options/Helpers/YNBuilder'
import QuestionOptionsList from './Options/QuestionOptionsList'
import './QuestionnaireQuestionForm.scss'
import { Tooltip } from 'antd'

class QuestionnaireQuestionForm extends React.Component {
  constructor() {
    super()

    this.buildOptions = this.buildOptions.bind(this)
    this.selectRiskIndicator = this.selectRiskIndicator.bind(this)
  }

  componentDidMount() {
    if (this.props.question.opened) {
      this.props.toggleAccordion(this.props.questionIndex)
    }
  }

  updateValue(event, field, cb) {
    const newQuestion = { ...this.props.question }
    newQuestion[field] = event.target.value
    
    this.props.updateQuestion(this.props.questionIndex, newQuestion, cb)
  }

  updateCheckBox(question) {
    const newQuestion = {
      ...question,
      selected: !question.selected
    }
    this.props.updateQuestion(this.props.questionIndex, newQuestion)
  }

  buildOptions(Builder, options) {
    const newQuestion = Builder(options)
    this.props.updateQuestion(this.props.questionIndex, newQuestion)
  }

  changedQuestionType(e, field) {
    this.updateValue(e, field, () => {
      const question = this.props.question

      switch (question.type) {
        case 'YN': {
          this.buildOptions(YNBuilder, { question })
          break
        }
        case 'Radio':
        case 'Checkbox': {
          this.buildOptions(MultipleBuilder, { question })
          break
        }
        default: {
          this.buildOptions(ResetOptions, { question })
          break
        }
      }
    })
  }

  selectRiskIndicator(item, value) {
    const newRiskIndicatorsList = [...this.props.question.riskIndicators].map((riskIndicator) => {
      if (riskIndicator.value !== item) return riskIndicator
      riskIndicator.selected = value
      return riskIndicator
    })
    const newQuestion = {
      ...this.props.question,
      riskIndicators: newRiskIndicatorsList
    }

    this.props.updateQuestion(this.props.questionIndex, newQuestion)
  }

  render() {
    const {
      accordion,
      addNewQuestion,
      question,
      questionIndex,
      questionnaireType,
      questions,
      removeQuestion,
      toggleAccordion
    } = this.props

    const {
      alias,
      errors,
      title,
      explanation,
      order,
      riskIndicators,
      selected,
      options,
      type,
      weight
    } = question

    const questionTitle = title || '[Question description blank]'

    return (
      <div className="newQuestionnaireQuestion__wrapper">
        <Accordion
          opened={ accordion[order] }
          header={
            <div className={ accordion[order] ? 'newQuestionnaireQuestion__headerWrapper' : '' }>
              <header className="newQuestionnaireQuestion__header">
                <p
                  className="newQuestionnaireQuestion__title"
                  onClick={ (e) => { toggleAccordion(order) }}>
                  { truncateString(`${ order }. ${ questionTitle }`, 35) }
                </p>

                <div className="newQuestionnaireQuestion__actionButtons">
                  <Tooltip title="Remove this question">
                    <div 
                      onClick={ (e) => { removeQuestion(questionIndex) }}
                      className={ questions.length === 1 ? 'newQuestionnaireQuestion__removeButtonDisabled' : 'newQuestionnaireQuestion__removeButton' }>
                    </div>
                  </Tooltip>
  
                  <Tooltip title="Add a new question">
                    <div
                      onClick={ (e) => { addNewQuestion(order) }}
                      className="newQuestionnaireQuestion__addButton">
                    </div>
                  </Tooltip>

                  <div className="newQuestionnaireQuestion__selectButton">
                    <input
                      id={ order }
                      checked={ selected }
                      onChange={ (e) => { this.updateCheckBox(this.props.question) }}
                      type="checkbox"
                    />
                    <label 
                      htmlFor={ order }
                      className={ selected ? 'newQuestionnaireQuestion__selectLabelSelected' : 'newQuestionnaireQuestion__selectLabel'} />
                  </div>
                </div>
              </header>
              <ToggleAccordion 
                oppened={ accordion[order] }
                index={ order }
                toggleAccordion={ toggleAccordion }
              />
            </div>
          }
          content={
            <React.Fragment>
              <form className="newQuestionnaireQuestion__form">
                <div className="newQuestionnaireQuestion__formGroup">
                  <label htmlFor="title" className="newQuestionnaireQuestion__requiredField">Question title</label>
                  <InputValidator errors={ errors } field="title">
                    <input
                      id="title"
                      value={ title }
                      onChange={(e) => { this.updateValue(e, 'title') }}
                    />
                  </InputValidator>
                </div>

                <div className="newQuestionnaireQuestion__formGroup">
                  <label htmlFor="explanation">Question Explanation</label>
                  <textarea
                    id="explanation"
                    value={ explanation }
                    onChange={(e) => { this.updateValue(e, 'explanation') }}
                  />
                </div>

                { questionnaireType !== 'PIA' &&
                  <div className="newQuestionnaireQuestion__formGroup">
                    <label htmlFor="weight" className="newQuestionnaireQuestion__requiredField">Question weight</label>
                    <InputValidator errors={ errors } field="weight">
                      <input
                        id="weight"
                        value={ weight }
                        type="number"
                        pattern="[+-]?([0-9]*[.])?[0-9]+"
                        onChange={ (e) => { this.updateValue(e, 'weight') }}
                      />
                    </InputValidator>
                  </div>
                }

                <div className="newQuestionnaireQuestion__formGroup--sideBySide">
                  { questionnaireType !== 'PIA' &&
                    <div className="newQuestionnaireQuestion__formGroup">
                      <label htmlFor="riskIndicator" className="newQuestionnaireQuestion__requiredField">Risk indicator</label>
                      <InputValidator errors={ errors } field="riskIndicators">
                        <MultiSelect
                          index={ order }
                          placeHolder="-- Select --"
                          items={ riskIndicators }
                          selectItem={ this.selectRiskIndicator }
                        />
                      </InputValidator>
                    </div>
                  }

                  <div className="newQuestionnaireQuestion__formGroup">
                    <label htmlFor="type" className="newQuestionnaireQuestion__requiredField">Question type</label>
                    <InputValidator errors={ errors } field="type">
                      <select
                        id="type"
                        value={ type }
                        onChange={ (e) => { this.changedQuestionType(e, 'type') }}
                      >
                        <option value=''>-- Select --</option>
                        {
                          questionTypes.map((questionType, index) => (
                            <option key={ `questionnaire-questionType${ index }` } value={ questionType.value }>
                              { questionType.label }
                            </option>
                          ))
                        }
                      </select>
                    </InputValidator>
                  </div>

                  <div className="newQuestionnaireQuestion__formGroup">
                    <label id="formInputAlias" htmlFor="alias">Shortened alias for linking back to</label>
                    <input
                      id="alias"
                      value={ alias }
                      onChange={(e) => { this.updateValue(e, 'alias') }}
                    />
                  </div>
                </div>
              </form>

              {
                options.length > 0 &&
                <QuestionOptionsList
                  questionIndex={ questionIndex }
                  question={ question }
                  questions={ questions }
                  buildOptions={ this.buildOptions }
                  updateQuestion={ this.props.updateQuestion }
                />
              }
            </React.Fragment>
          }
        />
      </div>
    )
  }
}

QuestionnaireQuestionForm.propTypes = {
  accordion: PropTypes.object.isRequired,
  questionIndex: PropTypes.number.isRequired,
  question: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired,
  removeQuestion: PropTypes.func.isRequired,
  toggleAccordion: PropTypes.func.isRequired,
  addNewQuestion: PropTypes.func.isRequired,
  questionnaireType: PropTypes.string.isRequired,
}

export default QuestionnaireQuestionForm