import React from 'react'
import { apiUpdateInitialStepAcceptEmailTemplate, apiUpdateDefaultTemplate } from '../../../../../../api/Sar'
import { connect } from 'react-redux'
import ReactQuill from 'react-quill'
import { Tooltip, Icon } from 'antd'
import swal from 'sweetalert2'
import CustomNotificationManager from '../../../../../Common/CustomNotificationManager'

class AcknowledgeStepRejectTemplate extends React.Component {
  modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      [{ size: [] }],
      [{ align: [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    }
  }

  state = {
    emailTemplate: {
      ...this.props.emailTemplate,
      sentFromEmail: this.props.emailTemplate.sentFromEmail ? this.props.emailTemplate.sentFromEmail : this.props.user.email,
      sentFromName: this.props.emailTemplate.sentFromName ? this.props.emailTemplate.sentFromName : this.props.user.name,
    },
    isNameLinkPersist: false,
    isResultUrlPersist: false,
    valid: false,
    editMode: false,
    theme: 'snow'
  }

  componentDidMount() {
    this.validateBody(this.props.emailTemplate.content)
  }

  defaultTemplateClicked(e) {
    e.preventDefault()
    swal.fire({
      text: 'Are you sure you want to reset the template to default?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3DD8DB',
      cancelButtonColor: '#D33D33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        this.handleSendDefaultTemplate()
      } else {
        CustomNotificationManager.info('Action cancelled', 'Aborted')
      }
    })
  }

  handleSendDefaultTemplate = () => {

    apiUpdateDefaultTemplate(this.state.emailTemplate.id).then(
      (res) => {
        this.props.updateEmailTemplate(res.data)
        CustomNotificationManager.success('Switched to default template.', 'Success')
        this.setState({
          emailTemplate: {
            ...this.props.emailTemplate,
            sentFromEmail: this.props.emailTemplate.sentFromEmail ? this.props.emailTemplate.sentFromEmail : this.props.user.email,
            sentFromName: this.props.emailTemplate.sentFromName ? this.props.emailTemplate.sentFromName : this.props.user.name,
          },
        })
      }
    )
  }

  validateBody(data) {
    this.checkDetailsVariable(data)
    this.checkUrlVariable(data)
    return (data.toString().match(new RegExp(/\${details}/, 'g')) || []).length === 1 && (data.toString().match(new RegExp(/\${resultUrl}/, 'g')) || []).length >= 1
  }

  checkDetailsVariable(data) {
    if (data.includes('{details}')) {
      this.setState({
        isNameLinkPersist: true
      })
    } else {
      this.setState({
        isNameLinkPersist: false
      })
    }
  }

  checkUrlVariable(data) {
    if (data.includes('{resultUrl}')) {
      this.setState({
        isResultUrlPersist: true
      })
    } else {
      this.setState({
        isResultUrlPersist: false
      })
    }
  }

  handleEditorChange = (e) => {
    this.setState({
      editMode: true,
      valid: this.validateBody(e),
    }, () => this.props.isEditMode(this.state.editMode))
  }

  handleChange = (e, key) => {
    this.setState({
      editMode: true
    }, () => this.props.isEditMode(this.state.editMode))

    switch (key) {
      case 'subject':
        this.setState({
          emailTemplate: { ...this.state.emailTemplate, subject: e.target.value },
          valid: this.validateBody(this.state.emailTemplate.content),
        })

        break
      case 'from-email':
        this.setState({
          emailTemplate: { ...this.state.emailTemplate, sentFromEmail: e.target.value },
          valid: this.validateBody(this.state.emailTemplate.content),
        })
        break
      case 'from-name':
        this.setState({
          emailTemplate: { ...this.state.emailTemplate, sentFromName: e.target.value },
          valid: this.validateBody(this.state.emailTemplate.content),
        })
        break
      default:
        break
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()

    this.setState({
      emailTemplate: {
        ...this.state.emailTemplate,
        defaultTemplate: !!this.props.godAdmin,
        clientId: this.props.clientId
      }
    }, () => {
      apiUpdateInitialStepAcceptEmailTemplate(this.state.emailTemplate, this.props.godAdmin).then(
        (res) => {
          this.setState({
            editMode: false,
            valid: false
          }, () => this.props.updateEmailTemplate(res.data))
        }
      )
    })
  }

  discardChanges = (e) => {
    e.preventDefault()
    this.setState({
      editMode: false,
      valid: false,
      emailTemplate: {
        ...this.props.emailTemplate,
        sentFromEmail: this.props.emailTemplate.sentFromEmail ? this.props.emailTemplate.sentFromEmail : this.props.user.email,
        sentFromName: this.props.emailTemplate.sentFromName ? this.props.emailTemplate.sentFromName : this.props.user.name,
      },
    }, () => this.props.isEditMode(this.state.editMode))
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      emailTemplate: nextProps.emailTemplate
    }, () => this.validateBody(nextProps.emailTemplate.content))
  }

  checkIfTemplateVariablesIncludeOne = (value) => {
    const { emailTemplate } = this.state
    let response = false
    if (emailTemplate.variables) {
      emailTemplate.variables.forEach((item) => {
        if (item.includes(value)) {
          response = true
        }
      })
    }
    return response
  }

  render() {
    const { emailTemplate, isNameLinkPersist, isResultUrlPersist, valid, editMode } = this.state
    const { godAdmin } = this.props

    return (
      <div className="email-template">
        { emailTemplate &&
        <form className="form-content col-md-10" onSubmit={ this.handleSubmit }>
          <div className="form-group">
            <label className="col-md-2">Subject</label>
            <input type="text" placeholder="Subject" className="col-md-10"
              onChange={ (e) => this.handleChange(e, 'subject') }
              value={ emailTemplate.subject || '' } required/>
          </div>
          { !godAdmin ?
            <React.Fragment>
              <div className="form-group">
                <label className="col-md-2">From email</label>
                <input type="email" placeholder="From Email" className="col-md-10"
                  onChange={ (e) => this.handleChange(e, 'from-email') }
                  value={ emailTemplate.sentFromEmail || '' } required={ !godAdmin }/>
              </div>
              <div className="form-group">
                <label className="col-md-2">From name</label>
                <input type="text" placeholder="From Name" className="col-md-10"
                  onChange={ (e) => this.handleChange(e, 'from-name') }
                  value={ emailTemplate.sentFromName || '' } required={ !godAdmin }/>
              </div>
            </React.Fragment>
            : '' }
          <ReactQuill
            theme={ this.state.theme }
            onChange={ (value) => {
              this.setState({
                emailTemplate: {
                  ...this.state.emailTemplate,
                  content: value
                }
              }, () => this.handleEditorChange(value))
            }
            }
            value={ emailTemplate.content }
            modules={ this.modules }
          />
          <div className="form-buttons">
            <button className="discard" onClick={ (e) => this.discardChanges(e) } disabled={ !editMode }>Discard
              Changes
            </button>
            { !godAdmin ? <button className="button-green" onClick={ (e) => this.defaultTemplateClicked(e) }
              disabled={ emailTemplate.defaultTemplate }>Default template</button> : '' }
            <button className="button-green accept" disabled={ !valid }>Modify</button>
          </div>
        </form>
        }
        <fieldset className="email-legend col-md-2">
          <legend>Email Variables</legend>
          <ul>
            <li>{ isNameLinkPersist ? <i className="fa fa-check" style={ { color: 'green' } }/> :
              <i className="fa fa-times" style={ { color: 'red' } }/> }&#36;{ '{details}' }
            <Tooltip placement="bottom" title={<span>These are the notes that the admin wrote, and contain the details
                that are exactly needed for the ID Check. Add the variable ${'{details}'} where you want
                this to appear in the email. </span> } >
              <Icon className="icon-info-tooltip" type="info-circle" style={{ fontSize: '16px', color: '#08c' }} />
            </Tooltip>
            </li>
            <li>{ isResultUrlPersist ? <i className="fa fa-check" style={ { color: 'green' } }/> :
              <i className="fa fa-times" style={ { color: 'red' } }/> }&#36;{ '{resultUrl}' }
            <Tooltip placement="bottom" title={<span>This is the link to be sent in the email. The placeholder of the
              link should be coloured blue and underlined. You can edit this text if it is there already.
              If not, you can write the placeholder in plain text, then select it and press the Link icon in the
              toolbar; that's when a small textbox will show up, and that is where you should write the variable
              ${'{resultUrl}'}. Now you've got a link to resultUrl, with the text what you wrote as placeholder.</span>}>
              <Icon className="icon-info-tooltip" type="info-circle" style={{ fontSize: '16px', color: '#08c' }} />
            </Tooltip>
            </li>
            { this.checkIfTemplateVariablesIncludeOne('companyName') &&
            <li>
              <i className="fa fa-check" style={ { color: 'green' } }/>
              &#36;{ '{companyName}' }
              <Tooltip placement="bottom" title={ <span>This is the company name and is mostly used in the last
              line of the email. However, if you need to insert it somewhere else in the email you can, just
              type ${'{companyName}'} wherever you want it to appear.</span>}>
                <Icon className="icon-info-tooltip" type="info-circle" style={{ fontSize: '16px', color: '#08c' }} />
              </Tooltip>
            </li>
            }
          </ul>
        </fieldset>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id,
  user: state.userReducer.user,
  godAdmin: state.admin.godAdminMode
})
export default connect(mapStateToProps, {})(AcknowledgeStepRejectTemplate)