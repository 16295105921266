import axios from 'axios'
import * as types from './actionTypes'
const isLocalhost = () => {
  return window.location.hostname === 'localhost'
}
const host = isLocalhost() ? 'http://localhost:8080' : ''

export const fetchClientData = (clientId) => async(dispatch) => {
  const response = await axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/admin/client-data/${ clientId }`
  })
  dispatch({ type: 'LOAD_CLIENT_EDIT_FORM', payload: response.data })
}

export const initializeData = (client, action) => async(dispatch) => {
  let response = {}
  if (action === 'edit') {
    response = {
      additionalEnvironments: client.additionalEnvironments,
      assetRegisterVisible: client.assetRegisterVisible,
      docLockerVisible: client.docLockerVisible,
      dpiaVisible: client.dpiaVisible,
      homeApplication: client.homeApplication ? client.homeApplication : 'HOME',
      sarVisible: client.sarVisible,
      smartBoxVisible: client.smartBoxVisible,
      isActive: 1,
      planId: client.planId ? client.planId : 1,
      name: client.name,
      domain: client.domain,
      applicationId: client.homeApplication,
      allowXeroxUpload: client.allowXeroxUpload,
      workflowAppVisible: client.workflowAppVisible,
      dataMappingAppVisible: client.dataMappingAppVisible,
      businessProcessesVisible: client.businessProcessesVisible,
      connectorsVisible: client.connectorsVisible,
      sourceMappingVisible: client.sourceMappingVisible,
      resellerAccount: client.resellerAccount,
      resellerProfile: client.resellerProfile,
      subTenants: client.subTenants,
    }
  } else {
    response = {
      additionalEnvironments: false,
      assetRegisterVisible: false,
      docLockerVisible: false,
      dpiaVisible: false,
      homeApplication: 'HOME',
      sarVisible: false,
      smartBoxVisible: false,
      isActive: 1,
      planId: 1,
      name: '',
      //resellerAccount: true,
      //resellerProfile: null,
    }
  }
  dispatch({ type: 'LOAD_CLIENT_EDIT_FORM', payload: response })
}
export const initializeFormInvite = (clientId) => async(dispatch) => {
  const response = {
    additionalEnvironments: true,
    assetRegisterVisible: false,
    docLockerVisible: false,
    dpiaVisible: false,
    homeApplication: 'HOME',
    sarVisible: true,
    smartBoxVisible: true,
    isActive: 1,
    planId: 1,
    applicationId: 'SMART_BOX',
    blocked: false,
    emailVerified: false,
    verifyPassword: false,
    allowTenants: false,
    ospreyRole: 'super_admin',
    dpiaRole: 'super_admin',
    multitenancyRole: 'user',
    multi_factor_authentication: true,
    //resellerAccount: true,
    //resellerProfile: null,
  }
  dispatch({ type: 'LOAD_INVITE_TENANT_FORM', payload: response })
}
export const fetchUserData = (clientId) => async(dispatch) => {
  const response = await axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/admin/user-data/${ clientId }`
  })
  dispatch({ type: 'LOAD_USER_EDIT_FORM', payload: response.data })
}

export function setGodAdminMode(data) {
  return { type: types.GOD_ADMIN_MODE, payload: data }
}
