import PropTypes from 'prop-types'
import React from 'react'
import './Button.scss'

const Button = ({ children, disabled, onClick, theme }) => (
  <button
    className={ getClassName(theme) }
    disabled={ disabled }
    onClick={ (e) => onClick(e) }>
    { children }
  </button>
)

const getClassName = (theme) => {
  if (theme) return `button-${ theme }`
  
  return 'button'
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  theme: PropTypes.string
}

Button.defaultProps = {
  disabled: false,
  onClick: (e) => {}
}

export default Button