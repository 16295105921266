import React from "react";
import {connect} from "react-redux";
import './ComponentsForm.scss';
import { Input, Checkbox, Radio} from "antd";

class SubjectVerificationForm extends React.Component {
    state = {
        valueRadio: 1,
        listRadio: [
            // {key: 1, option: 'Type option 1' },
            // {key: 2, option: 'Type option 2' },
            // {key: 3, option: 'Type option 3' },
            // {key: 4, option: 'Type option 4' },
        ],
        statusOtherOption: false,
        subjectForm: {
            valueRadio: null,
            queryForSearch: false,
            mandatoryCheck: false,
            options: [],
            header: 'Single choice',
            ///valueOptionsChoice: [{ optionId: 0, optionText: '' }],
            valueOptionsChoice: [],
            sectionVariable: ''
        },
        hoverDelete: false,
        indexOption: 0,
        listOption: [],
        editHeader: false,
        hoverEditBtn:false,
        hoverRemoveLabelBtn:{},
        hoverRemoveOptionBtn:{},
    }
    componentDidMount() {
        const { component } = this.props
        this.setState({
            subjectForm: { ...this.state.subjectForm,
                queryForSearch: component.queryForSearch === undefined ? false : component.queryForSearch,
                mandatoryCheck: component.mandatory === undefined ? false : component.mandatory,
                options: component.options === undefined || component.options === null ? [] : component.options,
                header: component.header === undefined ? 'Single choice' : component.header,
                sectionVariable: component.sectionVariable === undefined ? '' : component.sectionVariable,
                valueOptionsChoice: component.options === undefined || component.options === null ? [] : component.options,
            },
            listRadio: component.options === undefined || component.options === null ? [] : component.options,
        })
        //this.props.onChangeForm(this.state.subjectForm, this.props.component)
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props && this.props.checkStartSaved) {
            this.props.onChangeForm(this.state.subjectForm, this.props.component)
        }
    }
    onChange = e => {
        this.setState({
            valueRadio: e.target.value,
            subjectForm: { ...this.state.subjectForm, valueRadio: e.target.value },
        });
    };
    addOption = (e) => {
        this.setState({
            statusOtherOption: true
        })
    }
    addOtherOption = (e, item, index) => {
        const { listRadio, indexOption } = this.state
        this.setState({
            // listRadio: [...this.state.listRadio, {
            //     optionId: indexOption,
            //     optionText: e.target.value
            // }],
            statusOtherOption: false,
            subjectForm: { ...this.state.subjectForm, options:  [...this.state.subjectForm.options, {
                    optionId: indexOption,
                    optionText: e.target.value
                }],
                valueOptionsChoice:  [...this.state.subjectForm.valueOptionsChoice, {
                    optionId: indexOption,
                    optionText: e.target.value
                }],
            },
            indexOption: indexOption + 1
        }, () => {
            this.props.onChangeForm(this.state.subjectForm, this.props.component)
        })
    }

    displayInputOtherOption = () => {
        const { listRadio, indexOption, listOption, subjectForm } = this.state
        //const listOption = []
        subjectForm.valueOptionsChoice && subjectForm.valueOptionsChoice.length > 0 && subjectForm.valueOptionsChoice.forEach((list) => {
            listOption.push(list.optionText)
        })
        this.setState({
            listOption
        })
        if (listOption && listOption.length > 0 && !listOption.includes('Other')) {
            this.setState({
                listRadio: [...this.state.listRadio, {
                    optionId: subjectForm.options.length,
                    optionText: 'Other'
                }],
                subjectForm: { ...this.state.subjectForm, options:  [...this.state.subjectForm.options, {
                        optionId: subjectForm.options.length,
                        optionText: 'Other'
                    }],
                    valueOptionsChoice:  [...this.state.subjectForm.valueOptionsChoice, {
                        optionId: subjectForm.options.length,
                        optionText: 'Other'
                    }]
                },
                indexOption: indexOption + 1
            }, () => {
                this.props.onChangeForm(this.state.subjectForm, this.props.component)
            })
        } else if (subjectForm.options && subjectForm.options.length === 0) {
            this.setState({
                listRadio: [...this.state.listRadio, {
                    optionId: subjectForm.options.length,
                    optionText: 'Other'
                }],
                subjectForm: { ...this.state.subjectForm, options:  [...this.state.subjectForm.options, {
                        optionId: subjectForm.options.length,
                        optionText: 'Other'
                    }],
                    valueOptionsChoice:  [...this.state.subjectForm.valueOptionsChoice, {
                        optionId: subjectForm.options.length,
                        optionText: 'Other'
                    }]},
                indexOption: indexOption + 1
            }, () => {
                this.props.onChangeForm(this.state.subjectForm, this.props.component)
            })
        }
    }
    onChangeCheckbox = (e, key) => {
        switch (key) {
            case 'query':
                this.setState({
                    subjectForm: { ...this.state.subjectForm, queryForSearch: e.target.checked },
                }, () => {
                    this.props.onChangeForm(this.state.subjectForm, this.props.component)
                })
                break
            case 'mandatory':
                this.setState({
                    subjectForm: { ...this.state.subjectForm, mandatoryCheck: e.target.checked },
                }, () => {
                    this.props.onChangeForm(this.state.subjectForm, this.props.component)
                })
                break
            default:
                break
        }
    }
    onHoverActionComponent = () => {
        this.setState({
            hoverDelete: true
        })
    }
    onLeaveActionComponent = () => {
        this.setState({
            hoverDelete: false
        })
    }
    onHoverEditBtn = () => {
        this.setState({
            hoverEditBtn: true
        })
    }
    onLeaveEditBtn = () => {
        this.setState({
            hoverEditBtn: false
        })
    }
    onHoverRemoveLabelBtn = (index) => {
        this.setState({
            hoverRemoveLabelBtn: index
        })
    }
    onLeaveRemoveLabelBtn = () => {
        this.setState({
            hoverRemoveLabelBtn: {}
        })
    }
    onHoverRemoveOptionBtn = (index) => {
        this.setState({
            hoverRemoveOptionBtn: index
        })
    }
    onLeaveRemoveOptionBtn = () => {
        this.setState({
            hoverRemoveOptionBtn: {}
        })
    }
    onClickEditHeader = (e, status) => {
        if (status === 'header') {
            this.setState({
                editHeader: true
            })
        }
    }
    addTitleHeader = (e, status) => {
        if (status === 'header') {
            this.setState({
                subjectForm: {...this.state.subjectForm, header: e.target.value},
                //editHeader: false
            }, () => {
                this.props.onChangeForm(this.state.subjectForm, this.props.component)
            })
        }
    }
    addRemoveOptions = (e, status, radio, index) => {
        const { subjectForm } = this.state
        let removedLast = this.state.subjectForm.valueOptionsChoice
        const nameListOther = []
        switch (status) {
            case 'decrementOption':
                const removedUpdate = []
                //delete removedLast[index]
                removedLast && removedLast.length > 0 && removedLast.map((item2, index2) => {
                    if (index2 !== index) {
                        removedUpdate.push(item2)
                        nameListOther.push(item2.optionText)
                    }
                })
                //if (!nameListOther.includes('Other')) {
                    this.setState({
                        listRadio: removedUpdate,
                        subjectForm: {...this.state.subjectForm, options: removedUpdate, valueOptionsChoice: removedUpdate},
                        hoverRemoveOptionBtn:{},
                    }, () => {
                        this.props.onChangeForm(this.state.subjectForm, this.props.component)
                    })
                //}

                // let removedLast2 = this.state.subjectForm.valueOptionsChoice
                // const removedUpdate2 = []
                // delete removedLast[index]
                // removedLast2 && removedLast2.length > 0 && removedLast2.map((item2, index2) => {
                //     if (item2.optionId !== index) {
                //         removedUpdate2.push(item2)
                //     }
                // })
                // this.setState({
                //     subjectForm: { ...this.state.subjectForm, valueOptionsChoice: removedUpdate2 },
                // })
                break
            default: break
        }

    }
    addRemoveInputs = (e, status, item, index) => {
        const { subjectForm } = this.state
        const { valueOptionsChoice } = this.state.subjectForm
      switch (status) {
          case 'decrementOption':
              let removedLast = this.state.subjectForm.valueOptionsChoice
              const removedUpdate = []
              delete removedLast[index]
              removedLast && removedLast.length > 0 && removedLast.map((item2, index2) => {
                  if (item2.optionId !== index) {
                      removedUpdate.push(item2)
                  }
              })
              this.setState({
                  subjectForm: { ...this.state.subjectForm, valueOptionsChoice: removedUpdate },
              })
              break
          case 'addField':
              let copyState = subjectForm.valueOptionsChoice
              copyState[valueOptionsChoice.length] = {
                  optionId: valueOptionsChoice.length,
                  optionText: ''
              }
              this.setState({
                  subjectForm: { ...this.state.subjectForm, valueOptionsChoice: copyState },
              })
              break
          case 'addFieldFirst':
              let copyState2 = subjectForm.valueOptionsChoice
              const newArray = []
              newArray.push({
                  optionId: 0,
                  optionText: ''
              })
              subjectForm.valueOptionsChoice && subjectForm.valueOptionsChoice.length > 0 && subjectForm.valueOptionsChoice.forEach((item, index2) => {
                  newArray.push({
                      optionId: index2 + 1,
                      optionText: item.optionText
                  })
              })
              newArray.sort((a, b) => a.optionId - (b.optionId))
              this.setState({
                  subjectForm: { ...this.state.subjectForm, valueOptionsChoice: newArray },
              })
              break
          default: break
      }
    }
    addOtherOptionChange = (e, item, index) => {
        const { subjectForm } = this.state
        let value  =  e.target.value
        let copyState = subjectForm.valueOptionsChoice
        copyState[index].optionText = value
        if (copyState[index] && copyState[index].optionText) {
            copyState[index].optionText = value
        } else {
            copyState[index] = {
                optionId: null,
                optionText: value
            }
        }
        this.setState({
            subjectForm: { ...this.state.subjectForm, option: copyState, valueOptionsChoice: copyState },
        })
    }
    addSubmit = () => {
        const { subjectForm } = this.state
        const { valueOptionsChoice } = this.state.subjectForm
        const newArrayAdd = []
        subjectForm.valueOptionsChoice && subjectForm.valueOptionsChoice.length > 0 && subjectForm.valueOptionsChoice.forEach((item, index) => {
            if (item && item.optionText) {
                newArrayAdd.push({
                    optionId: index,
                    optionText: item.optionText
                })
            }
        })
        this.setState({
            subjectForm: { ...this.state.subjectForm, options: newArrayAdd },
            statusOtherOption: false
        }, () => {
            this.props.onChangeForm(this.state.subjectForm, this.props.component)
        })
    }
    onChangeFormVariable = (e) => {
        this.setState({
            subjectForm: { ...this.state.subjectForm, sectionVariable: e.target.value },
        }, () => {
            this.props.onChangeForm(this.state.subjectForm, this.props.component)
        })
    }
    confirmEditHeader = () => {
        this.setState({
            editHeader:false,
        })
    }
    render() {
        const { listRadio, statusOtherOption, subjectForm, hoverDelete, editHeader, hoverEditBtn, hoverRemoveLabelBtn } = this.state
        const { component, defaultTemplate } = this.props
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        };
        return (
            <div className={ this.props.snapshot.isDragging ? "component-form dragging " : "component-form"}>
                <div className="component-delete">
                    <button onClick={ (e) => this.props.showConfirmDeleteFormElement(e, component) }
                          className={hoverDelete ? 'icon-v19-delete-active' : "icon-v19-delete" }
                            disabled={defaultTemplate}
                          onMouseEnter={ (e) => this.onHoverActionComponent(e)}
                          onMouseLeave={() => this.onLeaveActionComponent()}></button>
                </div>
                <div className="component-content">
                    <div className="content-input-form-variables">
                        <span className="header-checkbox">
                           {/*<Checkbox onChange={(e) => this.onChangeCheckbox(e, 'query')}*/}
                            {/*          checked={ subjectForm.queryForSearch }>Query for search</Checkbox>*/}
                            <Checkbox onChange={(e) => this.onChangeCheckbox(e, 'mandatory')}
                                      disabled={defaultTemplate}
                                      checked={ subjectForm.mandatoryCheck }>Mandatory</Checkbox>
                        </span>
                        {/*<span className="content-variables">*/}
                        {/*     <span className="variables-diaz">#</span>*/}
                        {/*<Input*/}
                        {/*    className="input-form-variables"*/}
                        {/*    placeholder="Form variable"*/}
                        {/*    value={subjectForm.sectionVariable}*/}
                        {/*    onChange={(e) => this.onChangeFormVariable(e)}*/}
                        {/*/>*/}
                        {/*</span>*/}
                    </div>
                    <div className="header-component header-component-update">
                         <span className="header-content">
                              { editHeader ?
                                  <div>
                                      <Input defaultValue={subjectForm.header} className="input-other-option"
                                             onChange={ (e) => this.addTitleHeader(e, 'header')}
                                             onPressEnter={() => this.confirmEditHeader()}
                                      />
                                      <button className={ hoverEditBtn ? "icon-v21-edit-active" : "icon-v21-edit"}
                                            onClick={(e) => this.confirmEditHeader()}
                                              disabled={defaultTemplate}
                                            onMouseEnter={() => this.onHoverEditBtn()}
                                            onMouseLeave={() => this.onLeaveEditBtn()}
                                      ></button>
                                  </div>
                                   :
                                  <div>
                                      <span className="header-title header-title-update">{subjectForm.header}</span>
                                      <button className={ hoverEditBtn ? "icon-v21-edit-active" : "icon-v21-edit"}
                                            onClick={(e) => this.onClickEditHeader(e, 'header')}
                                              disabled={defaultTemplate}
                                            onMouseEnter={() => this.onHoverEditBtn()}
                                            onMouseLeave={() => this.onLeaveEditBtn()}
                                      ></button>
                                  </div>
                              }
                          </span>
                    </div>
                    <div className="radio-content">
                        <Radio.Group onChange={this.onChange}
                        >
                            {
                                subjectForm.options && subjectForm.options.length > 0 && subjectForm.options.map((radio, index) => {
                                    return (
                                        <Radio key={index} style={radioStyle} value={radio.optionId ? radio.optionId : ''}>
                                           <span>
                                               <span>{ radio.optionText }</span>
                                               <button onClick={(e) => this.addRemoveOptions(e, 'decrementOption', radio, index)}
                                                       onMouseEnter={() => this.onHoverRemoveOptionBtn(index)}
                                                       onMouseLeave={() => this.onHoverRemoveOptionBtn()}
                                                       className={this.state.hoverRemoveOptionBtn === index ? 'icon-v16-minus-active' : 'icon-v16-minus'}
                                               />
                                           </span>
                                        </Radio>
                                    )
                                })
                            }
                            {/*<Radio style={radioStyle} value={5}>*/}
                            {/*    More...*/}
                            {/*    {this.state.valueRadio === 5 ? <Input style={{ width: 100, marginLeft: 10 }} /> : null}*/}
                            {/*</Radio>*/}
                        </Radio.Group>
                        {
                            statusOtherOption &&
                            <div className="content-options">
                                { subjectForm.valueOptionsChoice && subjectForm.valueOptionsChoice.length > 0 && subjectForm.valueOptionsChoice.map((item, index) => {
                                    return (
                                        <span className="option-add" key={item.optionId}>
                                            <Input className="input-other-option"
                                                   value={item.optionText}
                                                   defaultValue="passenger name"
                                                   //onPressEnter={(e) => this.addOtherOptionChange(e, item, index)}
                                                   onChange={(e) => this.addOtherOptionChange(e, item, index)}
                                            />
                                            <button onClick={(e) => this.addRemoveInputs(e, 'decrementOption', item, index)}
                                                    onMouseEnter={() => this.onHoverRemoveLabelBtn(index)}
                                                    onMouseLeave={() => this.onLeaveRemoveLabelBtn()}
                                                    className={ hoverRemoveLabelBtn === index ? "icon-v16-minus-active" : "icon-v16-minus"}
                                            />
                                        </span>
                                    )
                                })
                                }
                                <span className={'btn-add-wrapper'}>
                                   <button onClick={(e) => this.addRemoveInputs(e, 'addField')} className="button-add">+ Add field</button>
                                   <button onClick={(e) => this.addRemoveInputs(e, 'addFieldFirst')} className="button-add">+ Add field at head</button>
                                </span>
                                    <button onClick={(e) => this.addSubmit(e)} className="button-submit">Submit</button>

                                <span className="content-info-dynamic">
                                    <span className="dynamic-form">
                                        <span className="dynamic">Dynamic Form Item</span>
                                        <span className="icon-v211-file-redacted"></span>
                                    </span>
                                    <span className="info-dynamic">
                                        <span>Add or remove form items dynamically. </span>
                                        <span className="add">add</span>
                                        <span> function support config initial value.</span>
                                    </span>
                                </span>
                            </div>
                        }
                        { !statusOtherOption &&
                            <span className="action-options-add">
                            <span className="add-option" onClick={(e) => this.addOption(e)}>Add Option</span>
                            <span className="add-other-option" onClick={(e) => this.displayInputOtherOption(e)}>Add "Other" Option</span>
                        </span>
                        }
                    </div>
                </div>
                <div className="component-move">
                    <span onMouseEnter={() => this.props.onStateProvidedDraggable(component)} className="icon-v211-grid-view-1"></span>
                </div>
            </div>
        )
    }
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(SubjectVerificationForm)