import React from 'react'
import PropTypes from 'prop-types'

const CheckBoxView = (props) => {
  return (
    <div className="check-box-view">
      {
        props.options.map((option, key) =>
          <div className="check-box-view__item" key={key}>
            <span className="check-box-view__item__check" />
            <span className="check-box-view__item__name">{option.name}</span>
          </div>
        )
      }
    </div>
  )
}

CheckBoxView.propTypes = {
  options: PropTypes.array.isRequired
}

export default CheckBoxView