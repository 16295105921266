import React from 'react'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import PropTypes from 'prop-types'

import BoxAddNewDataset from './BoxAddNewDataset'
import Button from '../Common/Button'
import ConnectorList from './ConnectorManage/ConnectorList'
import Filters from './ConnectorManage/Filters'
import Loading from '../Common/Loading'

import WithConnectors from './HOC/WithConnectors'

import { debounce } from '../../utils'

import './ConnectorManage.css'

class ConnectorManage extends React.Component {
  constructor() {
    super()
    
    this.state = { chosenView: 'cards' }

    this.filterByTerm = debounce(this.filterByTerm.bind(this), 1000)
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  componentWillUnmount = () => {
    this.props.resetConnectors()
  }

  toggleChosenView = () => {
    const oldView = this.state.chosenView
    this.setState({
      chosenView: oldView === 'cards' ? 'table' : 'cards'
    })
  }

  filterByTerm(event) {
    this.props.filterConnectorsByTerm(event.target.value)
  }

  actionRescan = (connectorId) => {
    this.props.crawlerStart(connectorId)
  }

  render = () => {
    return (
      <div className="privacyConnectorManage__page">
        <Filters
          changeView={ this.toggleChosenView }
          chosenView={ this.state.chosenView }
          filterByTerm={ this.filterByTerm }
        />

        { this.props.isFetchingConnectors &&
          <div className="privacyConnectorManage__loading"><Loading /></div>
        }
        
        { !this.props.isFetchingConnectors &&
          <React.Fragment>
            <div className="privacyConnectorManage__box">
              <header className="privacyConnectorManage__boxHeader">
                <div>
                  <h1 className="privacyConnectorManage__boxTitle">Data Connectors</h1>
                  <span className="privacyConnectorManage__boxTitleTotal">
                    { this.props.totalConnectors } total
                    { formatStorage(this.props.totalStorage) }
                    { formatFiles(this.props.totalFiles) }
                  </span>
                  <p className="privacyConnectorManage__boxText">
                    Data connectors are your linked cloud services.
                  </p>
                </div>
                <Link to='/privacy/connectors'>
                  <Button>Add new dataset</Button>
                </Link>
              </header>
            </div>

            <ConnectorList
              actionRescan={ this.actionRescan }
              connectors={ this.props.connectors }
              chosenView={ this.state.chosenView }
            />
          </React.Fragment>
        }

        <BoxAddNewDataset />
      </div>
    )
  }
}

const formatFiles = (total) => {
  return total === 0 ? '' : `${ total } objects`
}

const formatStorage = (docSize) => {
  const value = Math.round(docSize/1024/1024)
  return value === 0 ? '' : ` - ${ value } MB - `
}

ConnectorManage.propTypes = {
  connectors: PropTypes.array.isRequired,
  crawlerStart: PropTypes.func.isRequired,
  isFetchingConnectors: PropTypes.bool.isRequired,
  totalConnectors: PropTypes.number.isRequired,
  totalFiles: PropTypes.number.isRequired,
  totalStorage: PropTypes.number.isRequired
}

export default compose(
  WithConnectors,
)(ConnectorManage)