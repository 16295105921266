import axios from 'axios'

export const apiPrivacyChangeCategoryParent = (host, categoryID, parentID) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/categoryChangeParent`,
    data: {
      sessionID: token,
      categoryID,
      parentID
    }
  })
}

export const apiPrivacyDeleteCategory = (host, id) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/deleteCategory`,
    data: {
      sessionID: token,
      categoryID: id
    }
  })
}

export const apiPrivacyRenameCategory = (host, id, name) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/renameCategory`,
    data: {
      sessionID: token,
      categoryID: id,
      categoryName: name
    }
  })
}

export const apiPrivacySaveCategory = (host, query, filterQueries, id) => {
  const token = sessionStorage.getItem('gravicus_auth_token')
  
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/setCategoryQuery`,
    data: {
      sessionID: token,
      categoryID: id,
      query,
      filterQueries
    }
  })
}

export const apiPrivacySetCategoryProperty = (host, categoryID, propertyName, propertyValue) => {
  const token = sessionStorage.getItem('gravicus_auth_token')
  
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/setCategoryProperty`,
    data: {
      sessionID: token,
      categoryID,
      propertyName,
      propertyValue
    }
  })
}