import React from 'react'
import { connect } from 'react-redux'
import { Skeleton, Spin } from 'antd'
import ManageComplianceDocumentLocker from './components/ManageComplianceDocumentLocker'
import './ComplianceDocumentLocker.scss'
import LibraryTable from './components/LibraryTable'
import { deleteComplianceDocument, getAllData } from '../../../api/DocLocker'

class ComplianceDocumentLocker extends React.Component {
  state = {
    deleteComplianceDocActive: false,
    updateComplianceDocActive: false,
    findComplianceDocActive: false,
    libraries: [],
    foundLibraries: [],
    loadingLibraries: true,
    tablesVisible: true,
    loadingSpinUpload: false
  }
  
  setUpdateComplianceDocActive = (value) => {
    this.setState({
      updateComplianceDocActive: value,
      deleteComplianceDocActive: false,
      findComplianceDocActive: false
    })
  }
  
  setDeleteComplianceDocActive = (value) => {
    this.setState({
      deleteComplianceDocActive: value,
      updateComplianceDocActive: false,
      findComplianceDocActive: false
    })
  }

  setFindComplianceDocActive = (value) => {
    this.setState({
      findComplianceDocActive: value,
      deleteComplianceDocActive: false,
      updateComplianceDocActive: false
    })
  }
  
  componentDidMount() {
    getAllData(this.props.clientId).then((res) => {
      this.setState({
        libraries: res.data,
        loadingLibraries: false
      })
    })
    this.setState({
      loadingLibraries: false
    })
  }
  
  // fetchData = () => {

  // }
  
  deleteComplianceDocument = (row, libraryId) => {
    const { libraries } = { ...this.state }
    const libraryIndex = libraries.findIndex((libraryCollection) => libraryCollection.library.id === libraryId)
    libraries[libraryIndex].values = libraries[libraryIndex].values.filter((document) => document.id !== row.id)
    
    deleteComplianceDocument(row.id).then((res) => {
      this.setState({
        libraries
      })
    })
  }

  sentValueUpload = (value) => {
    this.setState({
      loadingSpinUpload: value
    })
  }

  setDefaultTableStatus = () => {
    this.setState({
      deleteComplianceDocActive: false,
      updateComplianceDocActive: false,
      findComplianceDocActive: false
    })
  }
  
  setTablesVisible = (value) => {
    this.setState({
      tablesVisible: value
    })
  }

  setDeleteComplianceDocActiveForFind = (value) => {
    this.setState({
      deleteComplianceDocActive: !value,
      updateComplianceDocActive: false,
    })
  }

  setUpdateComplianceDocActiveForFind = (value) => {
    this.setState({
      updateComplianceDocActive: !value,
      deleteComplianceDocActive: false,
    })
  }

  addNewValueToTable = (complianceDoc, library) => {
    const { libraries } = { ...this.state }
    const selectedLibraryIndex = libraries.findIndex((lib) => lib.library.id === library.id)

    libraries[selectedLibraryIndex].values.push(complianceDoc)
    this.setState({
      libraries: libraries
    })

  }

  setFoundLibraries = (newList) => {
    this.setState({
      loadingLibraries: true
    }, () => {
      this.setState({
        foundLibraries: newList,
        loadingLibraries: false
      })
    })
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   console.log(prevState.libraries, this.state.libraries)
  //   console.log(prevState.foundLibraries, this.state.foundLibraries)
  //   if (prevState.foundLibraries !== this.state.foundLibraries) {
  //     this.setState({
  //       foundLibraries: this.state.foundLibraries
  //     })
  //   }
  // }

  render() {
    const { deleteComplianceDocActive, updateComplianceDocActive, findComplianceDocActive, loadingLibraries, tablesVisible, foundLibraries, libraries, loadingSpinUpload } = this.state
    let showLibraries
    if (foundLibraries && foundLibraries.length > 0) {
      showLibraries = [...foundLibraries]
    } else {
      showLibraries = libraries
    }

    return (
      <div className="compliance-page">
        <ManageComplianceDocumentLocker
          setUpdateComplianceDocActive={ this.setUpdateComplianceDocActive }
          setDeleteComplianceDocActive={ this.setDeleteComplianceDocActive }
          setFindComplianceDocActive={ this.setFindComplianceDocActive }
          // fetchData={ this.fetchData }
          setDefaultTableStatus={ this.setDefaultTableStatus }
          setTablesVisible={this.setTablesVisible}
          libraries={showLibraries}
          addNewValueToTable={ this.addNewValueToTable }
          setFoundLibraries={ this.setFoundLibraries }/>
        <Spin className="custom-spin" spinning={ loadingSpinUpload }
          tip={ loadingSpinUpload ?
            'Uploading, please wait...' :
            '' }>
          <div className="compliance-page__content">
            {
              loadingLibraries ?
                <Skeleton active/>
                :
                tablesVisible && showLibraries && showLibraries.length > 0 && showLibraries.map((libraryCollection, key) =>
                  <LibraryTable libraryCollection={ libraryCollection } key={ key }
                    deleteComplianceDocTabActive={ deleteComplianceDocActive }
                    updateComplianceDocTabActive={ updateComplianceDocActive }
                    findComplianceDocTabActive={ findComplianceDocActive }
                    deleteComplianceDoc={ this.deleteComplianceDocument }
                    setDeleteComplianceDocActive={ this.setDeleteComplianceDocActiveForFind }
                    setUpdateComplianceDocActive={ this.setUpdateComplianceDocActiveForFind }
                    sentValueUpload={this.sentValueUpload}/>
                )
            }
          </div>
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id
})

export default connect(mapStateToProps)(ComplianceDocumentLocker)