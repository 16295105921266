import React from 'react'

import './Page403.scss'

const Page403 = () => (
  <div className="page403__page">
    This page cannot be accessed.
  </div>
)

export default Page403