import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import imgBoxEmpty from '../../../../../assets/box/box_empty_20x20.png'
import imgBoxEmptyNeg from '../../../../../assets/box/box_empty_negative_20x20.png'
import imgBoxProcessing from '../../../../../assets/box/box_processing_20x20.png'
import imgBox from '../../../../../assets/box/box_20x20.png'
import {Select} from "antd";
import moment from "moment";
import {apiSetTicketWorkflowStatus} from "../../../../../api/SmartBox";
import CustomNotificationManager from "../../../../Common/CustomNotificationManager";
const { Option } = Select;
export class HeaderTicket extends Component {

    state = {
      hoverRequestDetail: false,
        hoverIconDate: false,
        listOptionStatus:['Open', 'Archived'],
        selectOptionStatus: 'Open'
    }
     componentDidMount() {
        if (this.props.selectHeaderTickets && this.props.selectHeaderTickets.ticketStatus) {
            this.setState({
                selectOptionStatus: this.props.selectHeaderTickets.ticketStatus
            })
        }
     }

    onMouseEnter = (e, status) => {

        switch (status) {
            case 'request':
                this.setState({
                    hoverRequestDetail: true
                })
                break
            case 'date':
                this.setState({
                    hoverIconDate: true
                })
                break
            default: break
        }
    }

    onMouseLeave = () => {
      this.setState({
          hoverRequestDetail: false,
          hoverIconDate: false,
      })
    }
    onChangeSelect = (e) => {
        const { dataSourceTicket } = this.state
        this.setState({
            selectOptionStatus: e,
        }, () => {
            const setTicketWorkflowStatusDTO = {
                ticketId: this.props.selectHeaderTickets.ticketId,
                ticketStatus: e,
            }
            apiSetTicketWorkflowStatus(setTicketWorkflowStatusDTO).then((response) => {
                if (response.data.title && response.data.title.toLowerCase() === 'success') {
                    CustomNotificationManager.success(response.data.message, 'Success')
                } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
                    CustomNotificationManager.info(response.data.message, 'Info')
                }
                if (response.data.title && response.data.title.toLowerCase() === 'success' && e === 'Archived') {
                    this.props.onCloseNewTickets(false)
                }
                this.setState({
                    selectOptionStatus: 'Open'
                })
                this.props.onGetTicketWorkflowListDashboard()
            })
        })
    }
    onModalHeader = () => {
        //this.props.onEditModalTickets(this.props.selectHeaderTickets)
        this.props.onOpenEditTickets(this.props.selectHeaderTickets, this.props.activeOpenEditTickets)
    }
    onCloseHeaderTickets = () => {
      this.props.onCloseNewTickets(false)
    }
    render() {
      const { hoverRequestDetail, hoverIconDate, listOptionStatus, selectOptionStatus } = this.state
        const { selectHeaderTickets, selectEditTickets, activeOpenEditTickets, selectTicketTableDashboard } = this.props

        let nameHeaderTicket = ''
        let fullNameHeaderTicket = ''
        if (selectEditTickets && selectEditTickets.ticketId && selectEditTickets.ticketName) {
            nameHeaderTicket = `${selectEditTickets.ticketId} ${selectEditTickets.ticketName}`
        }
        if (selectEditTickets && selectEditTickets.ticketId && selectEditTickets.ticketRequestorName) {
            fullNameHeaderTicket = `${selectEditTickets.ticketId} ${selectEditTickets.ticketRequestorName}`
        }
        let diffDays = 0
        if (selectEditTickets) {
            const timeCurrent = moment().format('YYYY.MM.DD');
            const ticketTimeFrame = selectEditTickets.ticketTimeFrame
            const date1 = new Date(selectEditTickets.creationDate);
            const date2 = new Date(timeCurrent);
            const diffTime = Math.abs(date2 - date1);
            diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        }
        let openingDate = 0
        if ( selectHeaderTickets?.ticketTimeFrame === 99999 && selectHeaderTickets?.creationDate ) {
            const [year, month, day] = selectEditTickets.creationDate.split('.')
            openingDate = `${day}.${month}.${year}`
        }
        return (
            <div className="header-ticket-page">
                <div className="content-header-ticket-page">
                <div className="content-header-ticket-id">
                   <span className="ticket-id">{nameHeaderTicket}</span>
                    <span className={activeOpenEditTickets ? 'icon-v22-request-detail-active' : hoverRequestDetail ? 'icon-v22-request-detail-active' : "icon-v22-request-detail" }
                    onMouseEnter={(e) => this.onMouseEnter(e, 'request')}
                          onClick={(e) => this.onModalHeader(e)}
                    onMouseLeave={() => this.onMouseLeave()}></span>
                    <span></span>
                </div>

                    <div className="header-navigation-content">
                       <span className="ticket-box-header">
                            <img  src={selectTicketTableDashboard && selectTicketTableDashboard.boxStatus && selectTicketTableDashboard.boxStatus === 'DISABLED' ? imgBoxEmptyNeg :
                                selectTicketTableDashboard && selectTicketTableDashboard.boxStatus && selectTicketTableDashboard.boxStatus === 'EMPTY' ? imgBoxEmpty :
                                    selectTicketTableDashboard && selectTicketTableDashboard.boxStatus &&  selectTicketTableDashboard.boxStatus === 'GRAY' ? imgBoxProcessing :
                                        selectTicketTableDashboard &&  selectTicketTableDashboard.boxStatus &&  selectTicketTableDashboard.boxStatus === 'TURQUOISE' ? imgBox : imgBoxEmpty}/>
                            <span className="ticket-id">{fullNameHeaderTicket}</span>
                       </span>
                        <span className="ticket-owner">
                            {`Processor: ${selectHeaderTickets.ownerName ? selectHeaderTickets.ownerName : ''}`}
                        </span>
                        <span className='date-request'>
                            <span className={ hoverIconDate ? 'icon-v211-date-active':'icon-v211-date'}
                                  onMouseEnter={(e)=>this.onMouseEnter(e, 'date')}
                                  onMouseLeave={() => this.onMouseLeave()}
                            ></span>
                            { selectHeaderTickets.ticketTimeFrame && selectHeaderTickets.ticketTimeFrame !== 99999 ?
                                <span className='content-body-data'>{`Day ${ diffDays }`}{` of ${selectHeaderTickets.ticketTimeFrame ? selectHeaderTickets.ticketTimeFrame : 0 }`}</span> :
                                <span className='content-body-data'>{openingDate}</span>
                            }
                        </span>
                        <span className="ticket-owner">
                           Status:
                             <Select
                                 defaultValue={selectOptionStatus}
                                 onChange={(e) => this.onChangeSelect(e)}
                                 value={selectOptionStatus}
                                 dropdownClassName={'select-per-page-tickets select-status header-select-status'}>
              { this.state.listOptionStatus &&  this.state.listOptionStatus.length > 0 && this.state.listOptionStatus.map((item, index) => {
                  return (<Option className="options-select-connector" value={item} key={index}>
                      {item}
                  </Option>)
              })}
            </Select>
                        </span>

                        <span onClick={(e) => this.onCloseHeaderTickets(e)} className="icon-v22-close-black"></span>
                    </div>
                </div>
            </div>
        )
    }
}


HeaderTicket.propTypes = {
    folder: PropTypes.object,
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id
})
export default compose(
    connect(mapStateToProps),
)(HeaderTicket)