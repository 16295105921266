import React from 'react'
import imgStep1 from '../../../assets/icon-step1.svg'
import imgStep2 from '../../../assets/icon-step2.svg'
import imgStep3 from '../../../assets/icon-step3.svg'
import './WhatIsBusinessProcessPia.scss'

const WhatIsBusinessProcessPia = () => (
  <div className="whatIsBusinessProcessPia__box">
    <h1 className="whatIsBusinessProcessPia__title">Business Processes, PIA's, DPIA's. .. oh my!</h1>
    <p className="whatIsBusinessProcessPia__subtitle">A quick overview of Osprey's DPIA tools.</p>

    <div className="whatIsBusinessProcessPia__item">
      <div className="whatIsBusinessProcessPia__containerImage">
        <img src={ imgStep1 } alt="Step 1" />
      </div>
      <div className="whatIsBusinessProcessPia__itemInfo">
        <h3 className="whatIsBusinessProcessPia__step">Step 1 - Capture your Business Process</h3>
        <h2 className="whatIsBusinessProcessPia__itemTitle">Begin by looking at processes within your business that handle potentially sensitive data</h2>
        <p className="whatIsBusinessProcessPia__itemText">Things like CV’s, User Signup forms, Employee reviews, Subscription services. All these have business processes around them, for which the data is collected and processed. Osprey has some pre-set common processes to get you started evaluating any typically risky processes.</p>
      </div>
    </div>

    <div className="whatIsBusinessProcessPia__item">
      <div className="whatIsBusinessProcessPia__containerImage">
        <img src={ imgStep2 } alt="Step 2" />
      </div>
      <div className="whatIsBusinessProcessPia__itemInfo">
        <h3 className="whatIsBusinessProcessPia__step">Step 2 - Run a Privacy Impact Assessment (PIA) for that business process</h3>
        <h2 className="whatIsBusinessProcessPia__itemTitle">A PIA checks your Business Process for sensitive or non-sensitive data.</h2>
        <p className="whatIsBusinessProcessPia__itemText">It is a precursor to the DPIA. It tests your business process against a range of metrics to determine if the data within your process is sensitive enough to warrant ongoing attention to ensure that data is being managed correctly.</p>
      </div>
    </div>

    <div className="whatIsBusinessProcessPia__item">
      <div className="whatIsBusinessProcessPia__containerImage">
        <img src={ imgStep3 } alt="Step 3" />
      </div>
      <div className="whatIsBusinessProcessPia__itemInfo">
        <h3 className="whatIsBusinessProcessPia__step">Step 3 - Setup your repeating Data Protection Impact Assessment (DPIA)</h3>
        <h2 className="whatIsBusinessProcessPia__itemTitle">So you have a Business Process which handles sensitive data. Stay on top of it with a DPIA.</h2>
        <p className="whatIsBusinessProcessPia__itemText">DPIA is a more in-depth questionnaire to help determine if your company is managing this sensitive data process correctly. DPIA’s are set on a repeating schedule so that any improvements are captured - after all, you want to be able to demonstrate your efforts.</p>
      </div>
    </div>
  </div>
)

export default WhatIsBusinessProcessPia