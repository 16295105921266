import React from 'react'
import { compose } from 'redux'

import DataLineBuilder from './DataLines/DataLineBuilder'
 
import './CreateDataLine.css'

class CreateDataLine extends React.Component {
    state = {
    }
  
    componentDidMount = () => {
      window.scrollTo(0, 0)
    }
  
    render = () => {
      
      return (
        <div className="backofficeCreateDataLine__wrapper">
          <DataLineBuilder/>
        </div>
      )
    }
}


export default compose()(CreateDataLine)