import React from 'react'
import PropTypes from 'prop-types'

import Loading from '../../../Common/Loading'
import { apiOspreyGetDataModelStats } from '../../../../api/OspreyAnalytics.js'
import { processData } from './Graphs/DataMappingUtils'
import { DynamicTreemap } from './Graphs/DynamicTreemap'
import './DataSourceMap.css'

class DataSourceMap extends React.Component {
  constructor() {
    super()

    this.state = { 
      loadingData: true,
      data: [],
      filterQueries: []
    }
  }

  onHover = () => {
  }
  onSelect = () => {
  }
  unHover = () => {
  }

  componentDidMount = () => {
    
    const { filterQueries } = this.props
    const { pivotFields } = this.props
    let fq = []
    let pf = ['doc_datasource_name', 'doc_type']

    if (filterQueries && filterQueries !== null)
    {
      fq = filterQueries
    }
    if (pivotFields && pivotFields !== null)
    {
      pf = pivotFields
    }

    this.setState({
      filterQueries: fq,
      pivotFields: pf 
    }, () => this.loadData())      
  }

  loadData = () => {
    apiOspreyGetDataModelStats('documents', '*:*', this.state.pivotFields, ['doc_size'], this.state.filterQueries, [0, 0], [10, 10])
      .then((response) => {
        const arr = (response.data.value.stats.field.value instanceof Array) ? response.data.value.stats.field.value : [response.data.value.stats.field.value] 

        this.setState({
          loadingData: false,
          data: {
            title: 'analytics',
            color: '#12939A',
            children: processData(arr, 0)
          }
        })
      })
      .catch((error) => {
        console.log(error, 'error')
      })
  }

  render = () => {
    
    return (
      <div>
        { this.state.loadingData 
          ? <Loading/>
          : <DynamicTreemap data={ this.state.data } onSelect={ this.props.onSelect.bind(this) } onHover={ this.props.onHover.bind(this) } unHover={ this.props.unHover.bind(this) } primary='Datasource' secondary='Type'/>
        }
      </div>
    )
  }
}
  
DataSourceMap.propTypes = {
  onHover: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  unHover: PropTypes.func.isRequired
}

export default DataSourceMap