import React from 'react'
import { Link, Navigate } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import AccessControl from './Form/AccessControl'
import AddKeywords from './Form/AddKeywords'
import AddPii from './Form/AddPii'
import AddObjectTypes from './Form/AddObjectTypes'
import ButtonAddAlert from './Form/ButtonAddAlert'
import ButtonsArea from './Form/ButtonsArea'
import CollectionInfo from './CollectionCreate/CollectionInfo'
import ConnectorsList from './Form/ConnectorList'
import EditInPlace from './Form/EditInPlace'
import Loading from '../Common/Loading'
import {
  apiPrivacyDeleteCategory,
  apiPrivacyGetCountFolder,
  apiPrivacyRenameCategory,
  apiPrivacySaveCategory,
  apiPrivacySetCategoryProperty
} from '../../api/Privacy'
import {
  extractCountFolder,
  initialFilterQuery,
  mountAccessControlFilter,
  mountKeywordFilter,
  mountSelectFilter,
  unmountAccessControlFilter,
  unmountSelectFilter,
  unmountKeywordFilter
} from '../../utils'

import { findCategory } from '../../utils/Collections'

import WithCollections from './HOC/WithCollections'
import WithDataModels from './HOC/WithDataModels'

import './CollectionCreate.css'

class CollectionCreate extends React.Component {
  state = {
    accessControl: [],
    countFolders: '--',
    category: {},
    categoryfilterQuery: '',
    categoryId: null,
    hasError: false,
    initFields: false,
    isDeleting: false,
    isFetchingCollection: true,
    isFetchingFolders: true,
    isSaving: false,
    keywords: [{ rule: '', value: '' }],
    objectTypes: [{ rule: '', kind: '', value: '' }],
    owner: '',
    pii: [{ rule: '', kind: '', value: '' }],
    selectedConnector: '',
    title: ''
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
    
    this.setState({
      categoryId: parseInt(this.props.params.id, 10),
      initFields: true
    }, this.initFields)
  }

  componentWillReceiveProps = () => this.initFields()

  initFields = () => {
    if (this.state.initFields &&
        this.props.collections.length > 0 &&
        this.props.dataModels.length > 0) {
      const categoryToEdit = findCategory(this.props.collections, this.state.categoryId)

      // No category was found using the id on url.
      if (!categoryToEdit) return this.showError()

      // All the information is already fetched, so just fill the fields.
      if (!categoryToEdit.isFetchingInfo) return this.fillCategory(categoryToEdit)
      
      this.props.getInformationForOneCollection(categoryToEdit.id)
    }
  }

  showError = () => this.setState({ hasError: true, isFetchingCollection: false })

  fillCategory = (categoryToEdit) => {
    this.setState({
      accessControl: unmountAccessControlFilter(categoryToEdit),
      category: categoryToEdit,
      categoryfilterQuery: initialFilterQuery(categoryToEdit),
      initFields: false,
      isFetchingCollection: false,
      keywords: unmountKeywordFilter(categoryToEdit),
      objectTypes: unmountSelectFilter(categoryToEdit, 'Basic', this.props.dataModels),
      owner: (categoryToEdit.properties && categoryToEdit.properties.owner) ? categoryToEdit.properties.owner : '',
      pii: unmountSelectFilter(categoryToEdit, 'PII', this.props.dataModels)
    }, this.getFolderInformationForCollection)
  }

  getFolderInformationForCollection = () => this.getCountFolder()

  getCountFolder = () => {
    this.setState({ isFetchingFolders: true })
    
    const facetnames = ['doc_folder_path']
    const { query, filterQueries } = this.getFilters()

    apiPrivacyGetCountFolder({ facetnames, query, filterQueries })
      .then((response) => {
        this.setState({
          countFolders: extractCountFolder(response),
          isFetchingFolders: false
        })
      })
  }

  addAnother = (field) => {
    if (field === 'objectType') return this.setState({
      objectTypes: [...this.state.objectTypes, { rule: '', kind: '', value: '' }]
    })
    if (field === 'pii') return this.setState({
      pii: [...this.state.pii, { rule: '', kind: '', value: '' }]
    })
    if (field === 'keyword') return this.setState({
      keywords: [...this.state.keywords, { rule: '', value: '' }]
    })
  }

  selectedAccessControl = (newAccess) => this.setState({ accessControl: newAccess })

  updateObjectTypes = (objectTypes) => this.setState({ objectTypes })

  updatePii = (pii) => this.setState({ pii })

  updateKeywords = (keywords) => this.setState({ keywords })

  handleInputChange = (event) => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({ [name]: value }, () => {
      const shouldUpdateFolders = ['selectedConnector']
      if (shouldUpdateFolders.includes(name)) {
        this.getFolderInformationForCollection()
      }
    })
  }

  save = () => {
    this.setState({ isSaving: true })
    const { query, filterQueries } = this.getFilters()

    this.changeOwner()
      .then(() => {
      apiPrivacySaveCategory(query, filterQueries, this.state.categoryId)
        .then((response) => {
          if (response.data.status === 'Success') {
            this.setState({ isSaving: false, redirectToCollections: true })
          }
        })
      })
  }

  changeOwner() {
    return new Promise((resolve, reject) => {
      const { owner } = this.state
      if (owner === '') resolve()

      const categoryID = this.state.categoryId
      apiPrivacySetCategoryProperty(categoryID, 'owner', owner)
      .then((response) => {
        if (response.data.status === 'Success') {
          resolve()
        }
      })
    })
  }

  getFilters() {
    const pii = mountSelectFilter(this.state.pii)
    const objects = mountSelectFilter(this.state.objectTypes)
    const query = mountKeywordFilter(this.state.keywords)
    const access = mountAccessControlFilter(this.state.accessControl)
    const crawler = this.state.selectedConnector ? `doc_crawlerid:${ this.state.selectedConnector }` : ''
    const filterQueries = [
      this.state.alertfilterQuery,
      ...pii,
      ...objects,
      access,
      crawler
    ].filter((item) => item && item !== '')
    
    return { query, filterQueries }
  }

  delete = () => {
    this.setState({ isDeleting: true })
    const { categoryId } = this.state

    apiPrivacyDeleteCategory(categoryId)
    .then((response) => {
      if (response.data.status === 'Success') {
        const newCollections = this.props.collections
          .filter((collection) => collection.id !== categoryId)

        this.props.updateCollections(newCollections)
        this.setState({ redirectToCollections: true })
      }
    })
  }

  renameCollection = (value) => {
    const { categoryId } = this.state

    apiPrivacyRenameCategory(categoryId, value)
    .then((response) => {
      if (response.data.status === 'Success') {
        const newCollections = this.props.collections
          .map((collection) => {
            if (collection.id !== categoryId) return collection
            return { ...collection, name: value }
          })
        this.props.updateCollections(newCollections)
        this.setState({ category: findCategory(this.props.collections, categoryId) })
      }
    })
  }
  
  getName = (user) => {
    const name = user.user_metadata.name || user.name
    if (!name) return ''

    const [firstName, ] = name.split(' ')
    return firstName
  }

  render = () => {
    if (this.state.isFetchingCollection) return (
      <div className="privacyCollectionCreate__page">
        <div className="privacyCollectionCreate__loading"><Loading /></div>
      </div>
    )

    if (this.state.hasError) return (
      <div className="privacyCollectionCreate__page">
        <div className="privacyCollectionCreate__error">
          <p>This collection does not exist.</p>
        </div>
      </div>
    )

    const { user, users } = this.props

    return (
      <div className="privacyCollectionCreate__page">

        <Link className="privacyCollectionCreate__link" to="/privacy/collections">
          Back to collections screen
        </Link>

        { this.state.redirectToCollections &&
          <Navigate to="/privacy/collections" />
        }

        <div className="privacyCollectionCreate__box">
          <div className="privacyCollectionCreate__button-addAlert">
            <ButtonAddAlert categoryId={ this.state.categoryId } />
          </div>
          
          <header className="privacyCollectionCreate__boxHeader">
            <div>
              <h1 className="privacyCollectionCreate__boxTitle">
                <EditInPlace
                  value={ this.state.category.name }
                  save={ this.renameCollection } />
              </h1>
              <p className="privacyCollectionCreate__boxText">
                Adjust your collection settings.
              </p>
            </div>
            <ButtonsArea
              delete={ this.delete }
              entity="Collection"
              isDeleting={ this.state.isDeleting }
              isSaving={ this.state.isSaving }
              save={ this.save } />
          </header>

          <div className="privacyCollectionCreate__content">
            <div className="privacyCollectionCreate__form">

              <div className="privacyCollectionCreate__formFields">
                <div className="privacyCollectionCreate__formGroup">
                  <label>Owner</label>
                  <select name="owner" onChange={ this.handleInputChange } value={ this.state.owner }>
                    <option value="">Assign to review...</option>
                    <option value={ user.user_id }>{ user.email } { `- ${ this.getName(user) }` }</option>
                    { users.length > 0 &&
                      users.map((item) => (
                        <option key={ item.user_id } value={ item.user_id }>
                          { item.email } { this.getName(item) }
                        </option>
                      ))
                    }
                  </select>
                  
                </div>
              </div>

              <div className="privacyCollectionCreate__formFields">
                <div className="privacyCollectionCreate__formGroup">
                  <label>Data connector</label>
                  <ConnectorsList onChange={ this.handleInputChange } />
                  <button className="privacyCollectionCreate__addButton">Add new data connector</button>
                </div>
              </div>

              <div className="privacyCollectionCreate__formFields">
                <div className="privacyCollectionCreate__formGroup">
                  <label>Access control</label>
                  <AccessControl
                    values={ this.state.accessControl }
                    selectedAccessControl={ this.selectedAccessControl } />
                </div>
              </div>

              <div className="privacyCollectionCreate__formFields">
                <div className="privacyCollectionCreate__formGroup">
                  <label>Object types</label>
                  <AddObjectTypes
                    facetValues={ this.props.facetValues }
                    objectTypes={ this.state.objectTypes }
                    updateObjectTypes={ this.updateObjectTypes } />
                  
                  <button
                    className="privacyCollectionCreate__addButton"
                    onClick={ this.addAnother.bind(this, 'objectType') }>
                    Add another parameter
                  </button>
                </div>
              </div>

              <div className="privacyCollectionCreate__formFields">
                <div className="privacyCollectionCreate__formGroup">
                  <label>PII</label>
                  <AddPii
                    facetValues={ this.props.facetValues }
                    pii={ this.state.pii }
                    updatePii={ this.updatePii } />
                  
                  <button
                    className="privacyCollectionCreate__addButton"
                    onClick={ this.addAnother.bind(this, 'pii') }>
                    Add another parameter
                  </button>
                </div>
              </div>

            </div>
            <div className="privacyCollectionCreate__scan">
              <div className="privacyCollectionCreate__scanHeader">
                <div className="privacyCollectionCreate__scanHeaderWrapper">
                  { (this.props.isFetchingCollections || this.state.isFetchingFolders)
                    ? <div className="privacyCollectionCreate__loading-container">
                      <Loading />
                    </div>
                    : <CollectionInfo
                      collection={ this.state.category }
                      countFolders={ this.state.countFolders } />
                  }
                </div>
              </div>

              <button 
                className="privacyCollectionCreate__addButton"
                onClick={ this.addAnother.bind(this, 'keyword') } >
                Add another filter
              </button>

              <AddKeywords
                keywords={ this.state.keywords }
                updateKeywords={ this.updateKeywords } />
            </div>

          </div>

          <div className="privacyCollectionCreate__footer">
            <ButtonsArea
              delete={ this.delete }
              entity="Collection"
              isDeleting={ this.state.isDeleting }
              isSaving={ this.state.isSaving }
              save={ this.save } />
          </div>
        </div>
      </div>
    )
  }
}

CollectionCreate.propTypes = {
  collections: PropTypes.array.isRequired,
  isFetchingCollections: PropTypes.bool.isRequired,
  match: PropTypes.object,
}

const stateMap = (state) => ({
  user: state.userReducer.user,
  users: state.manageGroupsReducer.manageGroups.list
})

export default compose(
  connect(stateMap, null),
  WithCollections,
  WithDataModels
)(CollectionCreate)