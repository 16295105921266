import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import '../DisclosureWorkflow.scss'
import moment from "moment";
import {
    downloadExportDeliveredPageDisclosure,
    downloadExportLoadedFilesDisclosure,
    getDisclosureDeliveredPageData
} from "../../../../../../api/SmartBox";
export class DeliveredDisclosure extends Component {
    state = {
       deliveredPage: {},
        ticketEncodedId: null
    }
    componentDidMount() {
       this.onDisclosureDeliveredPageData()
    }

    onDisclosureDeliveredPageData = () => {
        const { selectHeaderTickets, selectEditTickets, stepHeaderActive } = this.props
        const ticketId = selectHeaderTickets.ticketId ? selectHeaderTickets.ticketId : selectEditTickets.ticketId
        const workflowComponentId = stepHeaderActive.stepComponentWorkflowId
        getDisclosureDeliveredPageData(ticketId, workflowComponentId).then((response) => {
            this.props.onGetTicketWorkflowAndHeaderDetails(selectHeaderTickets)
            this.props.onGetDisclosureStepsDetails()
            this.setState({
                deliveredPage: response.data,
                ticketEncodedId: response.data.ticketEncodedId,
            })
        })
    }

    render() {
        const { deliveredPage, ticketEncodedId } = this.state
        return (
            <div className="delivered-disclosure">
                <div className="delivered-disclosure-header">
                    <span className="delivered-disclosure-name">Deliverable record</span>
                    {/*<span className="icon-v22-download-1"></span>*/}
                    { ticketEncodedId ?
                        <a href={downloadExportDeliveredPageDisclosure(ticketEncodedId)}
                           className='icon-v22-download-1'
                        ></a> :
                        <a
                           className='icon-v22-download-1 disabled-download-1'
                        ></a>
                    }
                </div>
                <div className="delivered-disclosure-content">

                   <div className="delivered-disclosure-contain">
                       <span className="record-header">Requestor</span>
                       <div className="record-header-contain">
                           <span className="delivered-record-example">
                             <span className="title-record">Name:</span>
                             <span className="description-record">{ deliveredPage && deliveredPage.requestorName ? deliveredPage.requestorName : '' }</span>
                           </span>
                           <span className="delivered-record-example">
                             <span className="title-record">Email:</span>
                             <span className="description-record">{ deliveredPage && deliveredPage.requestorEmail ? deliveredPage.requestorEmail : '' }</span>
                           </span>
                           <span className="delivered-record-example">
                             <span className="title-record">Request details:</span>
                             <span className="description-record">{ deliveredPage && deliveredPage.requestorDetails ? deliveredPage.requestorDetails : '' }</span>
                           </span>
                       </div>
                   </div>

                    <div className="delivered-disclosure-contain">
                        <span className="record-header">Processor</span>
                        <div className="record-header-contain">
                           <span className="delivered-record-example">
                             <span className="title-record">Name:</span>
                             <span className="description-record">{ deliveredPage && deliveredPage.processorName ? deliveredPage.processorName : '' }</span>
                           </span>
                            <span className="delivered-record-example">
                             <span className="title-record">Email:</span>
                             <span className="description-record">{ deliveredPage && deliveredPage.processorEmail ? deliveredPage.processorEmail : '' }</span>
                           </span>
                        </div>
                    </div>

                    <div className="delivered-disclosure-contain">
                        <span className="record-header">Request process</span>
                        <div className="record-header-contain">
                           <span className="delivered-record-example">
                             <span className="title-record">Request received</span>
                             <span className="description-record">{ deliveredPage && deliveredPage.requestReceivedDate ? deliveredPage.requestReceivedDate : '' }</span>
                           </span>
                        </div>
                    </div>

                    <div className="delivered-disclosure-contain">
                        <span className="record-header">Data delivered</span>
                        <div className="record-header-contain">
                           <span className="delivered-record-example">
                             <span className="title-record">Record of Processing Activity</span>
                             <span className="description-record">{ deliveredPage && deliveredPage.ropadelivered ? deliveredPage.ropadelivered : '' }</span>
                           </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


DeliveredDisclosure.propTypes = {
    folder: PropTypes.object,
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id
})
export default compose(
    connect(mapStateToProps),
)(DeliveredDisclosure)