import React from 'react'
import PropTypes from 'prop-types'

import Progress from '../Progress'

import './QuestionsHeaderBig.css'

const QuestionsHeaderBig = ({ activeQuestion, fetchActiveQuestion, questionsResults, allQuestions, process, progress }) => (
  <React.Fragment>
    {
      !process.isLoading &&
      <header className="questionsHeaderBig__header">
        <h1>{ process.title }</h1>
      </header>
    }
    
    { activeQuestion &&
      <Progress
        questionsResults={ questionsResults }
        activeQuestion={ activeQuestion }
        allQuestions={ allQuestions }
        progress={ progress }
        onClick={ (id) => fetchActiveQuestion(id) }
      />
    }
  </React.Fragment>    
)

QuestionsHeaderBig.propTypes = {
  activeQuestion: PropTypes.object.isRequired,
  fetchActiveQuestion: PropTypes.func.isRequired,
  questionsResults: PropTypes.object.isRequired,
  allQuestions: PropTypes.array.isRequired,
  process: PropTypes.object.isRequired,
  progress: PropTypes.number.isRequired
}

export default QuestionsHeaderBig