import React from 'react'
import swal from 'sweetalert2'
import { apiResendActivationLink, apiUserContactSales } from './api/User'
import cookie from 'react-cookies'
import CustomNotificationManager from './components/Common/CustomNotificationManager'

export const cloneObject = (object) => {
  return JSON.parse(JSON.stringify(object))
}

export const regexBuilder = (str, rule = 'i') => {
  const term = str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1')
  return new RegExp(term, rule)
}

export const scrollToElement = (element) => {
  const el = document.querySelector(element)
  if (el === null) return
  const rect = el.getBoundingClientRect()
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop
  const top = rect.top + scrollTop
  window.scrollTo(0, top - (window.innerHeight / 2))
}

export const truncateString = (str, num) => {
  if (!str) return ''
  if (str.length < num) return str

  const sliceValue = num > 3 ? num - 3 : num
  return `${ str.slice(0, sliceValue) } ...`
}

export const emailRegex = /^(([^<>()[\]\\.,:\s@"]+(\.[^<>()[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const uniqueValues = (array) => [...new Set(array)]

export const sortValuesByField = (values, fieldName, reverse = false) => {
  values.sort((a, b) => {
    if (a[fieldName] > b[fieldName]) {
      return 1
    } else if (a[fieldName] < b[fieldName]) {
      return -1
    }
    return 0
  })

  return reverse ? values.reverse() : values
}

export const getParameterByName = (name) => {
  const url = window.location.href
  name = name.replace(/[[\]]/g, '\\$&')
  const regex = new RegExp(`[?&]${ name }(=([^&#]*)|&|#|$)`)
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export const getActiveApplication = () => {
  const isOspPage = (/^\/osp.*/).test(window.location.pathname)
  const isDpiaPage = (/^\/dpia.*/).test(window.location.pathname)
  const isSarPage = (/^\/sar.*/).test(window.location.pathname)
  const isDataSourcePage = (/^\/data-source.*/).test(window.location.pathname)
  const isPrivacyPage = (/^\/privacy.*/).test(window.location.pathname)
  const isAssertRegister = (/^\/assert-register.*/).test(window.location.pathname)
  const isDocLocker = (/^\/doc-locker.*/).test(window.location.pathname)
  const isSmartBox = (/^\/smart-box.*/).test(window.location.pathname)

  if (isOspPage) return 'osp'
  if (isDpiaPage) return 'dpia'
  if (isSarPage) return 'sar'
  if (isDataSourcePage) return 'data-source'
  if (isPrivacyPage) return 'privacy'
  if (isAssertRegister) return 'assert-register'
  if (isDocLocker) return 'doc-locker'
  if (isSmartBox) return 'smart-box'
}

export const getActiveUrl = () => {
  const page = window.location.pathname

  // We need to replace the "/" in the end of the url to do a properly check.
  return page.replace(/\/$/, '')
}

export const accountIsPaid = ({ subscription }) => {
  let paid = false
  if (subscription !== null) {
    if (subscription.plan.name) {
      paid = true
    }
  }
  return paid
}

export const userIsUser = (user) => {
  return user &&
    user.user_metadata &&
    user.user_metadata.roles &&
    user.user_metadata.roles.osprey === 'user'
}

export const userIsAdmin = (user) => {
  return user &&
    user.user_metadata &&
    user.user_metadata.roles &&
    user.user_metadata.roles.osprey === 'admin'
}

export const userIsSuperAdmin = (user) => {
  return user &&
    user.user_metadata &&
    user.user_metadata.roles &&
    user.user_metadata.roles.osprey === 'super_admin'
}

export const userIsMultitenance = (user) => {
  return user &&
      user.user_metadata &&
      user.user_metadata.roles &&
      user.user_metadata.roles.multitenancy && user.user_metadata.roles.multitenancy.length > 0
}

export const logout = (url) => {
  localStorage.removeItem('tokenData')
  localStorage.removeItem('dpiaToken')
  // localStorage.removeItem('accessToken')
  // if (url) {
  //   window.location.href = url
  // }
  window.location.href='/osp/login'
}

export const getErrorMessage = (e) => {
  const errorDescription =
    e.response &&
    e.response.data &&
    e.response.data.message

  if (errorDescription) {
    const parts = errorDescription.split(':')

    const requestRegex = /^Request.*/
    return parts
      .filter((item) => !requestRegex.test(item))
      .map((item) => item.trim())
  }
  return 'There is a server error. Please, try again.'
}

export const getScreenSize = () => {
  return window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth
}

export const industryItems = [
  'Agriculture, Forestry and Fishing',
  'Mining and Quarrying',
  'Manufacturing',
  'Electricity, Gas, Steam and Air Conditioning Supply',
  'Water Supply; Sewerage, Waste Management and Remediation Activities',
  'Construction',
  'Wholesale and Retail Trade; Repair of Motor Vehicles and Motorcycles',
  'Transportation ans Storage',
  'Accommodation and Food Service Activities',
  'Iinformation and Communication',
  'Financial and Insurance Activities',
  'Real Estate Activities',
  'Professional, Scientific and Technical Activities',
  'Administrative and Support Service Activities',
  'Public Administration and Defence; Compulsory Social Security',
  'Education',
  'Human Health and Social Work Activities',
  'Arts, Entertainment and Recreation',
  'Other Service Activities',
  'Activities of Households as Employers; Undifferentiated Goods- and Services-Producing Activities of Households for Own Use',
  'Activities of Extraterritorial Organisations and Bodies'
]

export const clickedRequestSalesCall = (user) => {
  swal.fire({
    showConfirmButton: true,
    showCancelButton: true,
    html: '<div style="margin: 10px 0;"><label for="phone" style="color: #AAAAAA; display: block; font-size: 14px; margin-bottom: 10px; text-align: left; text-transform: uppercase;">Phone number</label><input id="phone" type="text" style="border: 1px solid #CCCCCC; border-radius: 5px; box-sizing: border-box; color: #555555; font-size: 18px; font-weight: 300; padding: 10px; width: 100%;" /></div><p style="font-size: 14px; margin: 10px 0; text-align: left;">The phone number is not mandatory, the sales team can contact you via email.</p>',
    type: 'question',
    padding: 50
  }).then((result) => {
    // The user clicked on "confirm" button.
    if (result.value) {
      const phoneNumber = document.getElementById('phone').value

      const data = {
        email: user.email,
        name: getName(user),
        phone: phoneNumber
      }
      apiUserContactSales(data)

      swal.fire({
        padding: 50,
        showConfirmButton: false,
        text: 'This message will close automatically.',
        type: 'success',
        timer: 3000
      })
    }
  })
}

export const getName = (user) => {
  const name = user.user_metadata.name || user.name
  const [firstName, ] = name.split(' ')
  return firstName
}

export const groupBy = (list, funcProp) => {
  return list.reduce((acc, val) => {
    (acc[`__${ funcProp(val) }`] = acc[`__${ funcProp(val) }`] || []).push(val)
    return acc
  }, {})
}

export const debounce = (fn, interval) => {
  let timer
  return function debounced() {
    clearTimeout(timer)
    const args = arguments
    const that = this
    timer = setTimeout(function callOriginalFn() {
      fn.apply(that, args)
    }, interval)
  }
}

export const mountKeywordFilter = (arr) => {
  arr = arr
    .filter((item) => item.rule !== '' && item.value !== '')

  const aux = {
    'text': [],
    '-text': []
  }
  const include = arr.filter((item) => item.rule === 'include')
  const exclude = arr.filter((item) => item.rule === 'exclude')

  for (const item of include) {
    aux['text'].push(`"${ item.value }"`)
  }

  for (const item of exclude) {
    aux['-text'].push(`"${ item.value }"`)
  }

  const response = Object.entries(aux)
    .filter((item) => item[1].length > 0)
    .map((item) => {
      const key = item[0]
      const value = item[1].length === 1
        ? item[1]
        : `(${ item[1].join(' OR ') })`

      return `(${ key }:${ value })`
    })

  if (response.length === 0) return ''
  if (response.length === 1) return response[0]
  return `(${ response[0] }) AND (${ response[1] })`
}

export const mountSelectFilter = (arr) => {
  arr = arr
    .filter((item) => item.rule !== '' && item.kind !== '')

  const aux = {}
  const include = arr.filter((item) => item.rule === 'include')
  const exclude = arr.filter((item) => item.rule === 'exclude')

  for (const item of include) {
    const value = item.value === '' ? '[* TO *]' : item.value
    if (aux[item.kind]) {
      aux[item.kind].push(value)
    } else {
      aux[item.kind] = [value]
    }
  }

  for (const item of exclude) {
    const value = item.value === '' ? '[* TO *]' : item.value
    if (aux[`-${ item.kind }`]) {
      aux[`-${ item.kind }`].push(value)
    } else {
      aux[`-${ item.kind }`] = [value]
    }
  }

  return Object.entries(aux).map((item) => {
    const key = item[0]
    const value = item[1].length === 1
      ? item[1]
      : `(${ item[1].join(' OR ') })`
  
    return `${ key }:${ value }`
  })
}

export const unmountKeywordFilter = (category) => {
  let query = (category && category.params && category.params.q)
    ? category.params.q
    : []

  // We can have (text: and text:
  // we should have consistency on this.
  query = query[0] === '(' ? query : `(${ query }`

  const include = extractedMatch(query, /\(text:(.+)\)/)
  const exclude = extractedMatch(query, /\(-text:(.+)\)/)
  const newFilters = []

  for (const item of include) {
    newFilters.push({
      rule: 'include',
      value: item.trim()
    })
  }

  for (const item of exclude) {
    newFilters.push({
      rule: 'exclude',
      value: item.trim()
    })
  }

  return newFilters.length > 0 ? newFilters : [{ rule: '', value: '' }]
}

export const extractedMatch = (query, regex) => {
  let found = regex.exec(query)

  if (found) {
    found = found[1]
    const indexForFirstClosingParenthesis = found.indexOf(')')
    if (indexForFirstClosingParenthesis >= 0) {
      found = found.substring(0, indexForFirstClosingParenthesis)
    }
    found = found.split('(').join('')
    found = found.split('"').join('')
    found = found.split(' OR ')
  }

  return found ? found : []
}

export const unmountSelectFilter = (category, group, models) => {
  const query = (category && category.params && category.params.fq)
    ? category.params.fq
    : []

  if (query.length === 0) return [{ rule: '', kind: '', value: '' }]

  if (query instanceof Array) {
    const querySplitted = query.map((item) => {
      const a = item.split(':')
      return [a[0], a[1]]
    })

    if (query.length === 1 &&
        querySplitted[0][0] === 'doc_dynamic_type'
    ) return [{ rule: '', kind: '', value: '' }]

    const response = []
    for (const item of querySplitted) {
      if (item[0] !== 'doc_dynamic_type') {
        const values = item[1]
          .split('(').join('')
          .split(')').join('')
          .split('OR')

        for (const value of values) {
          if (item[0][0] === '-') response.push({
            rule: 'exclude',
            filteredFacetValues: [],
            kind: item[0].substring(1),
            value: value === '[* TO *]' ? '' : value.trim()
          })
          else response.push({
            rule: 'include',
            filteredFacetValues: [],
            kind: item[0],
            value: value === '[* TO *]' ? '' : value.trim()
          })
        }
      }
    }

    // We need to filter all the models and get only the ones witht the same passed group
    const filteredGroup = models.filter((model) => model.group === group)
    const filteredResponse = response.filter((item) => {
      // We need to check if the item.kind is present on the models or not
      const foundId = filteredGroup.filter((model) => model.id === item.kind)
      return foundId.length > 0
    })

    return filteredResponse.length > 0
      ? filteredResponse
      : [{ rule: '', kind: '', value: '' }]
  }

  return [{ rule: '', kind: '', value: '' }]
}

export const mountAccessControlFilter = (access) => {
  const allChecked = access.filter((item) => item.isChecked)
  if (allChecked.length === 0) return ''
  if (allChecked.length === 1) return `doc_security_group_permitted:${ allChecked[0].name }`

  return `doc_security_group_permitted:(${ allChecked
    .map((item) => item.name)
    .join(' OR ') })`
}

export const unmountAccessControlFilter = (category) => {
  if (!category.params) return []
  if (!category.params.fq) return []

  let fq = category.params.fq
  if (!(fq instanceof Array)) fq = [fq]

  const access = fq.filter((param) => /doc_security_group_permitted:.+/.test(param))
  if (access.length === 0) return []

  let [, params] = access[0].split(':')
  params = params.replace('(', '').replace(')', '')
  return params
    .split(' OR ')
    .map((param) => ({
      isChecked: true,
      name: param
    }))
}

export const unmountDateFilter = (category) => {
  const defaultDate = {
    selectedValueFrom: '*',
    selectedValueTo: 'NOW'
  }

  if (!category.params) return defaultDate
  if (!category.params.fq) return defaultDate

  let fq = category.params.fq
  if (!(fq instanceof Array)) fq = [fq]

  const access = fq.filter((param) => /doc_date_modified:.+/.test(param))
  if (access.length === 0) return defaultDate

  let [, params] = access[0].split(':')
  params = params.replace('[', '').replace(']', '')
  const [selectedValueFrom, selectedValueTo] = params.split(' TO ')
  return { selectedValueFrom, selectedValueTo }
}

export const initialFilterQuery = (category) => {
  let query = category.params.fq

  if (!query || query.length === 0) return ''

  let response = ''
  if (!(query instanceof Array)) query = [query]
  query.forEach((item) => {
    const [value, ] = item.split(':')
    if (value === 'doc_dynamic_type') response = item
  })

  return response
}

export const doFilterFacetValueFor = (facetValues, selectedValue) => {
  const filteredFacets = facetValues.filter((facetValue) => facetValue.name === selectedValue)
  let valuesForFacet = []

  if (filteredFacets.length > 0) {
    if (filteredFacets[0].values) {
      valuesForFacet = filteredFacets[0].values.value
    }
  }

  if (valuesForFacet.length === 0) return []

  return (valuesForFacet instanceof Array)
    ? sortValuesByField(valuesForFacet, 'count', true)
    : [valuesForFacet]
}

export const extractCountFolder = (response) => {
  const foundItem = response.data.value.facets.facet.filter((facet) => facet.name === 'doc_folder_path')
  return foundItem[0].pagination.total
}

export const isLocalhost = () => {
  return window.location.hostname === 'localhost'
}

export const updateAnItemOnCollection = (collection, id, informationToUpdate) => {
  return collection.map((item) => {
    if (item.id === id) { return { ...item, ...informationToUpdate } }
    return item
  })
}

export const catchErrorMessage = (error) => {
  let errorMessage = getErrorMessage(error)
  const toManyErrorMessage = 'Too Many Requests'
  const unauthorizedErrorMessage = 'Unauthorized'
  const invalidCredentials = 'Wrong email or password'
  const userAlreadyExists = 'The user already exists'
  const inactivEmailAddress = 'Email not verified for user'
  const userBlocked = 'user is blocked'
  const companyAlreadyRegister = 'This company is already registered.'
  const errorPublicDomain = 'No public domain allowed'
  const errorPublicLimit= 'Subscription limit reached upgrade plan to create more'
  const createPassword = 'The reset password link was sent to your email'
  const badCredentials = 'Bad credentials!'
  const columnAdditionalFieldsMissing = ' Columns or additional fields are missing type'
  if (error.response) {
    if (error.response.status === 504) {
      return  CustomNotificationManager.cleanBuffer('warning', '')
    }
    if (error.response.status === 502) {
      return  CustomNotificationManager.cleanBuffer('warning', '')
    }
    if (error.response.status >= 402 && error.response.status < 460) {
      return CustomNotificationManager.cleanBuffer('warning', '')
    }
    if (error.response.status >= 500 && error.response.status < 512) {
      return CustomNotificationManager.cleanBuffer('warning', '')
    }
    if (error.response.status === 400) {

      if (error.response.data.error && error.response.data.error.indexOf(toManyErrorMessage) > -1) {
        errorMessage = toManyErrorMessage
        cookie.save('logout_reason', toManyErrorMessage, { path: '/', maxAge: 60*60 })
        const redirectUrl = `${ window.location.origin }/osp/login`
        logout(redirectUrl)
      }
      if (error.response.data.detail && error.response.data.detail.indexOf(badCredentials) > -1) {
        errorMessage = badCredentials
        CustomNotificationManager.error('The email or the password is wrong.', errorMessage)
        return ''
      }
      if (error.response.data.error && error.response.data.error.indexOf(invalidCredentials) > -1) {
        errorMessage = invalidCredentials
      }
      if (error.response.data.error && error.response.data.error.indexOf(userAlreadyExists) > -1) {
        errorMessage = userAlreadyExists
      }
      if (error.response.data.error && error.response.data.error.indexOf(createPassword) > -1) {
        errorMessage = createPassword
        CustomNotificationManager.warning(errorMessage, 'Request to reset password')
        return ''
      }
      if (error.response.data.error && error.response.data.error.indexOf(inactivEmailAddress) > 0) {
        errorMessage = 
          <div>
            <p className="mb-2">This email has not yet been activated</p>
            <button className="button-small-red-empty" onClick={ () => resendActivationLink(error.response.data.error) }>Resend activation link</button>
          </div>
      }
    }
    if (error.response.status === 401) {
      if (error.response.data.error_description && error.response.data.error_description.indexOf(companyAlreadyRegister) >= -1) {
        return errorMessage = companyAlreadyRegister
      }
      errorMessage = 'Token expired, login for a new session.'
      localStorage.removeItem('tokenData')
      // localStorage.removeItem('accessToken')
      CustomNotificationManager.warning(errorMessage, 'Message')
      return window.location.href = '/osp/login'
    }
    if (error.response.status === 403) {

      if (error.response.data.error_description && error.response.data.error_description.indexOf(companyAlreadyRegister) > -1) {
        errorMessage = companyAlreadyRegister
      }
      if (error.response.data.error_description && error.response.data.error_description.indexOf(userBlocked) > -1) {
        errorMessage = 'This account is blocked.'
      }
    }
    if (error.response.status === 404) {
      if (error.response.data.status === 'NOT_FOUND') {
        return ''
      }
      if (error.response.data.detail) {
        window.open(`${ window.location.origin }/custom/notfound`)
      }
    }
    if (error.response.status === 500) {
      if (error.response.data.detail && error.response.data.detail.indexOf(badCredentials) > -1) {
        errorMessage = badCredentials
        CustomNotificationManager.error('The password that you\'ve entered is incorrect.', errorMessage)
        return ''
      }
      if (error.response.data.message && (error.response.data.message.includes('Server Error')|| error.response.data.message.includes('server error'))) {
        return ''
      }
      if (error.response.data.detail && (error.response.data.detail.includes('Server Error') || error.response.data.detail.includes('server error'))) {
        return ''
      }
      if (error.response.data.error_description && (error.response.data.error_description.includes('Server Error') || error.response.data.error_description.includes('server error'))) {
        return ''
      }
      if (error.response.data.localizedMessage && (error.response.data.localizedMessage.includes('Server Error') || error.response.data.localizedMessage.includes('server error'))) {
        return ''
      }
      if (error.response.data.detail && error.response.data.detail.indexOf(columnAdditionalFieldsMissing) > -1) {
        CustomNotificationManager.warning('Columns or additional fields are missing type', 'Missing Data')
        return ''
      }
      if (error.response.data.error_description && error.response.data.error_description.indexOf(errorPublicDomain) > -1) {
        errorMessage = errorPublicDomain
      }
      if (error.response.data.msg && error.response.data.msg.indexOf(errorPublicLimit) > -1) {
        errorMessage = errorPublicLimit
      }
      if (error.response.data.localizedMessage) {
        errorMessage = error.response.data.localizedMessage
      }
    }
    CustomNotificationManager.error(errorMessage, 'Error')
  }
}

const resendActivationLink = (errorMessage) => {
  const lastOccurence = errorMessage.lastIndexOf('email:')
  if (lastOccurence > -1) {
    const emailInserted = errorMessage.substring(lastOccurence + 6)
    if (emailInserted.trim()) {
      const data = {
        email: emailInserted.trim()
      }
      apiResendActivationLink(data)
        .then(() => {
          CustomNotificationManager.success('A new activation link was sent. Please check your email, even in span.', 'Email sent')
        })
    }
  }
}

export const groupArrayBy = (list, column) => {
  const map = new Map()
  for (const i of Object.keys(list)) {
    const item = list[i]
    const key = list[i][column]
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  }
  const obj = {}
  for (const prop of map) {
    obj[prop[0]] = prop[1]
  }
  return obj
}

// a little function to help us with reordering the result
export const reorderDraggableCards = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [ removed ] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const grid = 8

export const getDraggableItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${ grid }px 0`,

  // change background colour if dragging
  background: isDragging ? 'white' : 'white',

  // styles we need to apply on draggables
  ...draggableStyle
})

export const getDraggableListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid,
  width: 250
})
export const getHomeApplicationUrlPath = (data) => {
  if (data.homeApplication) {
    switch (data.homeApplication) {
      case 'SAR_TRACKER':  return '/sar/dashboard';
      case 'ASSET_REGISTER': return '/asset-register/management';
      case 'DOC_LOCKER': return '/doc-locker/management';
      case 'ASSESSMENTS': return '/dpia/dashboard'; 
      case 'SMART_BOX': return '/smart-box/management';
      default: return '/smart-box/management'
    }
  }
}


export const hasDuplicates = (data) => {
  const valuesSoFar = Object.create(null)
  for (let i = 0; i < data.length; ++i) {
    const value = data[i].id
    if (value in valuesSoFar) {
      return true
    }
    valuesSoFar[value] = true
  }
  return false
}

export const handleByteConversion = (bytes) => {
  let MB = bytes/(1024*1024)
  if(MB <= 1){
      return `${(bytes / 1024).toFixed(1)} KB`
  }else if(MB > 1024){
      return `${(bytes / 1024*1024*1024).toFixed(1)} GB`
  }else{
      return MB
  }
}