import React from 'react'
import PropTypes from 'prop-types'

import { ConnectorsConsumer } from '../../../contexts/Connectors'

const WithConnectors = (Component) => {
  return class WithConnectors extends React.Component {
    render = () => {
      return (
        <ConnectorsConsumer>
          { ({ getConnectors, ...rest }) => (
            <InitConnectors getConnectors={ getConnectors }>
              <Component {...this.props} {...rest} />
            </InitConnectors>
          )}
        </ConnectorsConsumer>
      )
    }
  }
}

class InitConnectors extends React.Component {
  componentDidMount = () => {
    this.props.getConnectors()
  }

  render = () => {
    return (
      this.props.children 
    )
  }
}

InitConnectors.propTypes = {
  children: PropTypes.element.isRequired,
  getConnectors: PropTypes.func.isRequired
}

export default WithConnectors