import axios from 'axios'

export const apiPrivacyGetCollections = (host) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/getCategoryChildren`,
    data: {
      sessionID: token,
      categoryID: '25'
    }
  })
}

export const apiPrivacyAddCollection = (host) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/addCategory`,
    data: {
      sessionID: token,
      parentID: '25',
      categoryName: 'New Collection'
    }
  })
}

export const apiPrivacyGetAlertsForCollection = (host, id, propertyName, propertyValue) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/getCategoriesByPropertyValue`,
    data: {
      sessionID: token,
      parentID: id,
      propertyName,
      propertyValue
    }
  })
}

export const apiPrivacyGetTotalObjectsForCollections = (host, id, page = 0) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/getDocumentsForCategory`,
    data: {
      sessionID: token,
      categoryID: id,
      offset: page,
      length: 10,
      fieldNames: [
        'doc_path',
        'doc_annotate_person',
        'doc_annotate_location',
        'doc_annotate_organization',
        'doc_annotate_money',
        'doc_annotate_date',
        'doc_annotate_address',
        'doc_annotate_identifier'
      ],
      filterQueries: [''],
      sortField: '',
      ascending: true,
      flatten: false
    }
  })
}

export const apiPrivacyGetDataSourceCountForCollection = (host, id) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/getCategoryStats`,
    data: {
      sessionID: token,
      categoryID: id,
      query: '*',
      pivotFields: ['doc_datasource_name'],
      fieldNames: ['doc_size'],
      filterQueries: [],
      facetOffsets: [0, 0],
      facetLengths: [100, 100]
    }
  })
}