import React from 'react'
import { connect } from 'react-redux'
import { fetchClientData } from '../../../actions/adminActions'
import { apiViewPlan } from '../../../api/Plan'
import AddEditForm from './AddEditForm'

class ClientEditForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      modalVisible: false,
      emailAddress: '',
      homeApplication: 'DOC_LOCKER',
    }
  }

  componentDidMount() {
    if (this.props.id) {
      this.props.fetchClientData(this.props.params.id)
      apiViewPlan().then((res) => {
        this.setState({
          plans: res.data,
          loading: false
        })
      })
    }
  }

  render() {
    const { plans, applications } = this.state
    return (
      <div className="tracker-page">
        <div className="tracker-box">
          <AddEditForm action="edit" plans={plans} applications={applications} />
        </div>
      </div>
    )
  }
}

const stateMap = (state) => ({
  client: state.subscriptionReducer.subscription.subscription.client
})

ClientEditForm = connect(
  (state) => ({
    initialValues: state.admin.clientEditForm
  }), { fetchClientData }
)(ClientEditForm)

export default connect(stateMap)(ClientEditForm)