import React from "react";
import {connect} from "react-redux";
import '../BuilderWorkflow.scss';
import {Input, Checkbox, Select} from "antd";
const { Option } = Select

class Disclosure extends React.Component {
    state = {
        formOptions: {
            valueSelect: '',
        },
        hoverDelete: false
    }
    onHoverActionComponent = () => {
        this.setState({
            hoverDelete: true
        })
    }
    onLeaveActionComponent = () => {
        this.setState({
            hoverDelete: false
        })
    }
    render() {
        const { hoverDelete } = this.state
        const { component, onStateProvidedDraggable } = this.props
        return (
            <div className="component-form">
                <div className="component-content component-content-new">
                    <div className="header-component">
                         <span onClick={ (e) => this.props.showConfirmDeleteSection(e, component) }
                             className={hoverDelete ? 'icon-v19-delete-active' : "icon-v19-delete" }
                             onMouseEnter={ (e) => this.onHoverActionComponent(e)}
                             onMouseLeave={() => this.onLeaveActionComponent()}></span>
                        <span className="header-title">Disclosure</span>
                    </div>
                </div>
                <div className="component-move">
                    <span onMouseEnter={() => this.props.onStateProvidedDraggable(component)} className="icon-v211-grid-view-1"></span>
                </div>
            </div>
        )
    }
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(Disclosure)