import axios from 'axios'

const isLocalhost = () => {
  return window.location.hostname === 'localhost'
}
// const host = isLocalhost() ? 'https://staging.ospreysmart.com' : ''
const host = isLocalhost() ? 'http://localhost:8081' : ''

export const apiQuestionnaireCreateVersion = (data, questionnaireId) => {
  const dpiaToken = localStorage.getItem('dpiaToken')
  const idToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId
  // const accessToken = localStorage.getItem('accessToken')
  const accessToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId

  Object.assign(data, { osprey_access_token: accessToken, id_token: idToken })

  return axios({
    method: 'post',
    url: `${ host }/dpia-0.1.0/2.0/questionnaires/${ questionnaireId }/versions?action=CREATE`,
    headers: {
      'Authorization': `Bearer ${ dpiaToken }`
    },
    data
  })
}
export const apiQuestionnaireCreate = (data) => {
  const dpiaToken = localStorage.getItem('dpiaToken')
  const idToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId
  // const accessToken = localStorage.getItem('accessToken')
  const accessToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId

  Object.assign(data, { osprey_access_token: accessToken, id_token: idToken })

  return axios({
    method: 'post',
    url: `${ host }/dpia-0.1.0/2.0/questionnaires?action=CREATE`,
    headers: {
      'Authorization': `Bearer ${ dpiaToken }`
    },
    data
  })
}

export const apiQuestionnairePublish = (data, questionnaireId, versionId) => {
  const dpiaToken = localStorage.getItem('dpiaToken')
  const accessToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId
  // const accessToken = localStorage.getItem('accessToken')

  Object.assign(data, { osprey_access_token: accessToken, id_token: accessToken })

  return axios({
    method: 'post',
    url: `${ host }/dpia-0.1.0/2.0/questionnaires/${ questionnaireId }/versions/${ versionId }?action=PUBLISH`,
    headers: {
      'Authorization': `Bearer ${ dpiaToken }`
    },
    data
  })
}

export const apiQuestionnaireUpdate = (data, questionnaireId, versionId) => {
  const dpiaToken = localStorage.getItem('dpiaToken')
  const accessToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId
  // const accessToken = localStorage.getItem('accessToken')

  Object.assign(data, { osprey_access_token: accessToken, id_token: accessToken })

  return axios({
    method: 'post',
    url: `${ host }/dpia-0.1.0/2.0/questionnaires/${ questionnaireId }/versions/${ versionId }?action=UPDATE`,
    headers: {
      'Authorization': `Bearer ${ dpiaToken }`
    },
    data
  })
}

export const apiFetchQuestionnaireVersion = (questionnaireId, versionId) => {
  const dpiaToken = localStorage.getItem('dpiaToken')
  const accessToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId

  // const accessToken = localStorage.getItem('accessToken')
  const data = { osprey_access_token: accessToken, id_token: accessToken }

  return axios({
    method: 'post',
    url: `${ host }/dpia-0.1.0/2.0/questionnaires/${ questionnaireId }/versions/${ versionId }?action=GET`,
    headers: {
      'Authorization': `Bearer ${ dpiaToken }`
    },
    data
  })
}

export const apiFetchQuestionnaire = (questionnaireId) => {
  const dpiaToken = localStorage.getItem('dpiaToken')
  const accessToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId
  // const accessToken = localStorage.getItem('accessToken')
  const data = { osprey_access_token: accessToken, id_token: accessToken }

  return axios({
    method: 'post',
    url: `${ host }/dpia-0.1.0/2.0/questionnaires/${ questionnaireId }/?action=GET`,
    headers: {
      'Authorization': `Bearer ${ dpiaToken }`
    },
    data
  })
}

export const apiQuestionnaireChangeVersionStatus = (questionnaireId, versionId, status) => {
  const dpiaToken = localStorage.getItem('dpiaToken')
  const accessToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId
  // const accessToken = localStorage.getItem('accessToken')
  const data = { osprey_access_token: accessToken, id_token: accessToken }

  return axios({
    method: 'post',
    url: `${ host }/dpia-0.1.0/2.0/questionnaires/${ questionnaireId }/versions/${ versionId }?action=${ status }`,
    headers: {
      'Authorization': `Bearer ${ dpiaToken }`
    },
    data
  })
}

export const apiQuestionnaireChangeStatus = (questionnaireId, status) => {
  const dpiaToken = localStorage.getItem('dpiaToken')
  const accessToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId
  // const accessToken = localStorage.getItem('accessToken')
  const data = { osprey_access_token: accessToken, id_token: accessToken }

  return axios({
    method: 'post',
    url: `${ host }/dpia-0.1.0/2.0/questionnaires/${ questionnaireId }?action=${ status }`,
    headers: {
      'Authorization': `Bearer ${ dpiaToken }`
    },
    data
  })
}

export const apiQuestionnairesList = (extraPath = '') => {
  const dpiaToken = localStorage.getItem('dpiaToken')
  const accessToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId
  // const accessToken = localStorage.getItem('accessToken')
  const data = { osprey_access_token: accessToken, id_token: accessToken }
    
  return axios({
    method: 'post',
    url: `${ host }/dpia-0.1.0/2.0/questionnaires?action=GET${ extraPath }`,
    headers: {
      'Authorization': `Bearer ${ dpiaToken }`
    },
    data
  })
}

export const apiRiskIndicators = (data) => {
  const dpiaToken = localStorage.getItem('dpiaToken')
  const accessToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId
  // const accessToken = localStorage.getItem('accessToken')

  Object.assign(data, { osprey_access_token: accessToken, id_token: accessToken })

  return axios({
    method: 'post',
    url: `${ host }/dpia-0.1.0/2.0/risk-indicators?action=GET`,
    headers: {
      'Authorization': `Bearer ${ dpiaToken }`
    },
    data
  })
}