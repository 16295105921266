import React from 'react'
import PropTypes from 'prop-types'

import './TopicItem.css'

const TopicItem = ({ openModal, topic }) => {
  const terms = topic.terms.slice(0, 6)

  return (
    <div className="topicItem-wrapper">
      <div className="topicItem-header">
        <h1 className="topicItem-title">{ topic.title } ({ (topic.weight * 100).toFixed(2) })</h1>
      </div>

      <div className="topicItem-content">
        <div className="topicItem-contentHeader">
          <p className="topicItem-text">Prediction</p>
        </div>
        <ul>
          { terms.map((term, index) => (
            <TopicListItem key={ index } maxValue={ topic.maxValue } term={ term } />
          )) }
        </ul>
      </div>

      <div className="topicItem-footer">
        <button
          className="topicItem-link"
          onClick={ openModal.bind(this, topic) }>
        Review topic
        </button>
      </div>
    </div>
  )
}

const TopicListItem = ({ maxValue, term }) => (
  <li className="topicItem-listItem">
    <div className="topicItem-listItem-title">
      { term.name }
    </div>
    <div className="topicItem-listItem-valueWrapper">
      <div className="topicItem-listItem-value" style={{ width: `${ (term.value * 100) / maxValue }%` }}></div>
    </div>
  </li>
)

TopicItem.propTypes = {
  openModal: PropTypes.func.isRequired,
  topic: PropTypes.object.isRequired
}

TopicListItem.propTypes = {
  maxValue: PropTypes.number.isRequired,
  term: PropTypes.object.isRequired
}

export default TopicItem