export const actionAttributes = (questionnaire, hasChanges, action, methods) => {
  const { state } = questionnaire

  if (state === 'PUBLISHED' && action === 'publish' && hasChanges) {
    return { title: 'A new version will be created', action: 'createNewVersionAndPublish', method: methods['createNewVersionAndPublish'] }
  }

  if (state === 'PUBLISHED' && action === 'publish' && !hasChanges) {
    return { title: 'No changes have been made', action: 'noAction' }
  }

  if (state === 'DRAFT' && action === 'draft' && hasChanges) {
    return { title: 'This draft will be updated', action: 'updateDraft', method: methods['updateDraft'] }
  }

  if (state === 'DRAFT' && action === 'draft' && !hasChanges) {
    return { title: 'No changes have been made', action: 'noAction' }
  }

  if (state === 'DRAFT' && action === 'publish' && hasChanges) {
    return { title: 'A new version will be created and published', action: 'createNewVersionAndPublish', method: methods['createNewVersionAndPublish'] }
  }

  if (state === 'DRAFT' && action === 'publish' && !hasChanges) {
    return { title: 'This questionnaire will be published', action: 'publish', method: methods['publish'] }
  }

  if (state === '' && action === 'draft') {
    return { title: 'This questionnaire will be saved as draft', action: 'createNewDraftQuestionnaire', method: methods['createNewDraftQuestionnaire'] }
  }

  if (state === '' && action === 'publish') {
    return { title: 'This questionnaire will be published', action: 'createNewQuestionnaireAndPublish', method: methods['createNewQuestionnaireAndPublish'] }
  }
}
