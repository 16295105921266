export const objectToFormData = (obj, form, namespace) => {
  const fd = form || new FormData()
  let formKey

  for (const property in obj) {
    if (obj.hasOwnProperty(property)) {

      if (namespace) {
        formKey = `${ namespace }[${ property }]`
      } else {
        formKey = property
      }

      // if the property is an object, but not a File,
      // use recursivity.
      if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {

        objectToFormData(obj[property], fd, property)

      } else {

        // if it's a string or a File object
        fd.append(formKey, obj[property])
      }

    }
  }

  return fd
}

export const objectWithChildArraysToFormData = (obj, form, namespace) => {
  //const fd = form || new FormData()
  const FormData = require('form-data');
  const fd = new FormData()
  //const fd = new URLSearchParams()
  let formKey
  for (const property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        if (namespace.includes('[')) {
          formKey = `${ namespace }`
        } else {
          formKey = `${ namespace }[${ property }]`
        }
      } else {
        formKey = property
      }
      //formKey = namespace ? `${namespace}[${property}]` : property;

      // if the property is an object, but not a File,
      // use recursivity.

      /*if (obj[property] instanceof Array && typeof obj[property][0] !== 'undefined' && !(obj[property][0] instanceof File)) {
        obj[property].forEach((element, index) => {
          const tempFormKey = `${formKey}[${index}]`;
          objectWithChildArraysToFormData(element, fd, tempFormKey);
        })
      } else */
      if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
        objectWithChildArraysToFormData(obj[property], fd, /*formKey */!isNaN(Number(property)) ? formKey : property)
      } else {
        // if it's a string or a File object
        //fd.set(formKey, obj[property])
        fd.append(formKey, obj[property])
      }

    }
  }
  return fd
}