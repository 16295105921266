import React from "react";
import {connect} from "react-redux";
import { Input, Checkbox} from "antd";

class BusinessTemplate extends React.Component {
    state = {
        templateForm: {
            inputName: '',
        },
        hoverDelete: false
    }
    componentDidMount() {
        const { component } = this.props
        this.setState({
            templateForm: { ...this.state.templateForm, inputName: component.inputName },
        })
    }
   componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            const { component } = this.props
            this.setState({
                templateForm: { ...this.state.templateForm, inputName: component.inputName },
            })
        }
   }

    onChangeInput = (e, key) => {
        switch (key) {
            case 'business':
                this.setState({
                    templateForm: { ...this.state.templateForm, inputName: e.target.value },
                }, () => {
                    this.props.onChangeDataSource(this.state.templateForm, this.props.component)
                })
                break
            default:
                break
        }
    }

    onHoverActionComponent = () => {
        this.setState({
            hoverDelete: true
        })
    }
    onLeaveActionComponent = () => {
        this.setState({
            hoverDelete: false
        })
    }
    render() {
        const { templateForm, hoverDelete } = this.state
        const { component } = this.props
        return (
            <div className="component-form">
                <div className="component-delete">
                    <span onClick={(e) => this.props.showConfirmDeleteTemplate(e, component)}
                          className={hoverDelete ? 'icon-v19-delete-active' : "icon-v19-delete" }
                          onMouseEnter={ (e) => this.onHoverActionComponent(e)}
                          onMouseLeave={() => this.onLeaveActionComponent()}></span>
                </div>
                <div className="component-content">
                    <div className="header-component">
                        <span className="header-title">{ component.header }</span>
                    </div>
                    <span className="input-content">
                            <Input onChange={(e) => this.onChangeInput(e, 'business')}
                                   value={ templateForm.inputName } className="input-form-name"/>
                            <span className="label-input">example@email.com</span>
                        </span>
                </div>
                <div className="component-move">
                    <span className="icon-v211-grid-view-1"></span>
                </div>
            </div>
        )
    }
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(BusinessTemplate)