import React from 'react'
import { Tree, Button, Select } from 'antd'
import {
  getSpellChecker,
  getSpellCheckPage
} from '../../../api/SmartBox'
import { renderTreeSpellChecker } from '../../../utils/SmartBox/SmartBoxMethods'
import './SpellCheckerPage.scss'
import CustomNotificationManager from '../../Common/CustomNotificationManager'
import swal from 'sweetalert2'
//import ReviewRedact from './ReviewRedact'
import ReviewSpellImg from "./ReviewSpellImg";

const { Option } = Select

class SpellCheckerPage extends React.Component {
   state = {
     pageNumberSelect: '',
     valueRadioSelect: '',
     visibleSearchButton: false,
     autoExpandParent: true,
     expandedKeys: ['select-all'],
     checkedKeys: [],
     selectedKeys: [],
     treeData: [{
       // title: 'Select All',
       // key: 'select-all',
       // identifier: 'top-parent',
       children: []
     }],
     redactedKeys: [],
     selectedChildrenBold: []
   }

   componentDidMount() {
     //this.onDisplaySpellChecker(this.props.documentId)
   }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.isNextDocumentId !== this.props.isNextDocumentId) {
      this.onDisplaySpellChecker(this.props.isNextDocumentId.id)
    }
  }
  fetchDataSpellChecker = (id) => {
    const { treeData } = this.state
    treeData[0].children = []
    if (this.props.isSmartBoxApplication) {
      getSpellChecker(id).then((response) => {
        if (response.data.length !== 0) {
          response.data.map((ind) => {
            treeData[0].children.push(ind)
            return true
          })
          this.setState({
            treeData
          })
        } else {
          CustomNotificationManager.info('No misspelled words found', 'Empty')
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
            console.log(error)
        }
      })
    }
  }

  fetchDataSpellCheckerPage = () => {
    const { pageNumberSelect, treeData } = this.state
    treeData[0].children = []
    const data = {
      docId: this.props.documentId,
      page: pageNumberSelect
    }
    if (this.props.isSmartBoxApplication) {
      getSpellCheckPage(data).then((response) => {
        if (response.data.length !== 0) {
          response.data.map((ind) => {
            treeData[0].children.push(ind)
            return true
          })
          this.setState({
            treeData
          })
        } else {
          CustomNotificationManager.info('No misspelled words found', 'Empty')
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
            console.log(error)
        }
      })
    }
  }
  onDisplaySpellChecker = (id) => {
    const { treeData } = this.state
    treeData[0].children = []
    if (this.props.isSmartBoxApplication) {
      getSpellChecker(id).then((response) => {
        if (response.data.length !== 0) {
          response.data && response.data.length > 0 && response.data.map((ind) => {
            treeData[0].children.push(ind)
            ind.children && ind.children.length > 0 && ind.children.forEach((child, index) => {
              child.pageNumber = `${ ind.page }`
              child.key = `${child.title}-${index}`
              child.index = index
              child.backgroundColor = "rgba(132, 206, 235, 0.3)"
              child.name = child.title
              child.isSearchedWord = true

            })
            return true
          })
          this.setState({
            treeData
          })
        } else {
          CustomNotificationManager.info('No misspelled words found', 'Empty')
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
            console.log(error)
        }
      })
    }
  }
  onChangeSelect = (e) => {
    const { valueRadioSelect } = this.state
    if (e !== '' && valueRadioSelect === 'redact-page') {
      this.setState({
        pageNumberSelect: e,
        visibleSearchButton: true
      })
    } else {
      this.setState({
        pageNumberSelect: '',
        visibleSearchButton: false
      })
    }
  }

  onChangeRadioGroup = (e) => {
    if (e.target.value === 'redact-document') {
      this.setState({
        valueRadioSelect: e.target.value,
        pageNumberSelect: '',
        visibleSearchButton: true
      })
    } else {
      this.setState({
        valueRadioSelect: e.target.value,
        visibleSearchButton: false
      })
    }
  }

  onClickCHeck = () => {
    const { valueRadioSelect } = this.state
    //const { pageNumberSelect } = this.state
    if (valueRadioSelect === 'redact-document') {
      this.fetchDataSpellChecker(this.props.documentId)
      this.setState({
        pageNumberSelect: '',
        visibleSearchButton: false
      })
      //this.props.highlightRectangle(true, true, 1)
    } else {
      this.fetchDataSpellCheckerPage()
      //this.props.highlightRectangle(true, true, pageNumberSelect)
      this.setState({
        visibleSearchButton: false
      })
    }
  }

  onExpand = (expandedKeys) => {
    this.setState({
      expandedKeys,
      autoExpandParent: true,
    })
  };

  onSelect = (selectedKeys, info) => {
    this.setState({ selectedKeys })
  }

  onCheck = (checkedKeys, { checkedNodes }) => {
    this.setState({
      checkedKeys,
      checkedKeysForDelete: checkedNodes.map((node) => node.props.dataRef).filter((obj) => obj.identifier === 'category-parent'),
      checkedKeysForDeleteChildren: checkedNodes.map((node) => node.props.dataRef).filter((obj) => obj.identifier === 'children'),
      checkedKeysObjectRefs: checkedNodes.map((node) => node.props.dataRef).filter((obj) => obj.key)

    })
  }

  expandHideCategory = (e, key, action) => {
    e.stopPropagation()
    let { expandedKeys } = { ...this.state }
    if (action === 'add') {
      expandedKeys.push(key)
    } else {
      expandedKeys = expandedKeys.filter((eKey) => eKey !== key)
    }
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    })
  }

  onDeleteCategory = () => {

    const { treeData, checkedKeys, checkedKeysForDelete, checkedKeysForDeleteChildren } = { ...this.state }

    for (const checkedKey of checkedKeysForDelete.map((cKey) => cKey.key)) {
      treeData[0].children = treeData[0].children.filter((child) => child.key !== checkedKey)
      this.setState({
        treeData,
        checkedKeysForDelete: [],
        checkedKeys
      })
    }

    for (const checkedKey of checkedKeysForDeleteChildren.map((cKey) => cKey.key)) {
      for (const children of treeData[0].children) {
        children.children = children.children.filter((child) => child.key !== checkedKey)
        this.setState({
          treeData,
          checkedKeysForDeleteChildren: [],
          checkedKeys
        })
      }
    }
  }

  findElementByClassAndAddMarkPulse(selectedKeys, node) {

    this.setState({
      selectedChildrenBold: selectedKeys
    })
  }

  drawMatchedWords = (classList) => {
    const textMessageRedact = classList && classList.length === 1 ? `Redact ${ classList.length } selected?` : `Redact all ${ classList.length } selected?`
    swal.fire({
      text: 'Redact all selected?',
      html: `  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 19 25">
                  <g fill="none" fillRule="evenodd">
                    <path stroke=" #00D3D8" strokeWidth="4" d="M18 24V7.215L11.716 1H1v23h17z"/>
                    <path stroke="#00D3D8"  strokeWidth="4" d="M11 1v7h7"/>
                    <path fill="#00D3D8" d="M5 12h9v3H5zM5 17h6v3H5z"/>
                  </g>
                </svg>
          <h3 class="swall-redact-title">${ textMessageRedact }</h3>`,
      showCancelButton: true,
      confirmButtonColor: '#3DD8DB',
      cancelButtonColor: '#3DD8DB',
      confirmButtonText: 'REDACT',
      customClass: {
        container: 'redact-swall-wr'
      }
    }).then((result) => {
      if (result.value) {
        this.setState({
          redactedKeys: new Set([ ...this.state.redactedKeys, ...classList ])
        }, () => this.props.drawAllMatchedWordsByClassList(classList))
      } else {
        CustomNotificationManager.info('Action cancelled', 'Aborted')
      }
    })
  }

  render() {
    const { expandedKeys, checkedKeys, treeData, checkedKeysObjectRefs, redactedKeys, selectedChildrenBold } = this.state
    const { totalPages } = this.props
    const selectPage = []
    for (let i = 1; i <= totalPages; i++) {
      selectPage.push(i)
    }
    return (
      treeData && expandedKeys ?
        <div className="spell-checker-page">
          <div className="spell-checker-container">
            <div className="header-action-ai-results">
              <div className="content-option-ai">
                <span className="icon-v17-redaction-hover"></span>
                <Select
                    placeholder="Show all"
                    className='custom-option-review'
                >
                  <Option className="item-options-review" key={1} value="review-all">
                    show all
                  </Option>
                  <Option className="item-options-review" key={2} value="review-redacted">
                    redacted
                  </Option>
                  <Option className="item-options-review" key={3} value="review-annotated">
                    annotated
                  </Option>
                </Select>
              </div>
              { treeData[0].children && treeData[0].children.length > 0 ?
                <ReviewSpellImg
                    checkedKeysObjectRefs={checkedKeysObjectRefs}
                    pageNumber={this.props.pageNumber}
                    changePage={this.props.changePage}/>
                  :
                <div className="spell-checker-container__button-search-spell">
                <Button className="search-button button-green"
                onClick={() => this.onDisplaySpellChecker(this.props.documentId)}
                >Search</Button>
                </div>
              }
            </div>
            {/*<div className="spell-checker-container__title-spell">SPELL CHECKER</div>*/}
            {/*<div className="spell-checker-container__radio-contain">*/}
            {/*  <Radio.Group onChange={ this.onChangeRadioGroup }>*/}
            {/*    <Radio value="redact-document"> Document</Radio>*/}
            {/*    <Radio value="redact-page"> Page*/}
            {/*    </Radio>*/}
            {/*  </Radio.Group>*/}
            {/*  <Select onChange={ (e) => this.onChangeSelect(e) } value={ pageNumberSelect } className="select-page">*/}
            {/*    {*/}
            {/*      selectPage && selectPage.map((numberPage, i) => (*/}
            {/*        <Option className="items-options" value={ numberPage } key={ numberPage }>*/}
            {/*          { numberPage }*/}
            {/*        </Option>*/}
            {/*      ))*/}
            {/*    }*/}
            {/*  </Select>*/}
            {/*</div>*/}
            {/*<div className="spell-checker-container__button-search-spell">*/}
            {/*  <Button className="search-button button-green"*/}
            {/*    disabled={ !visibleSearchButton }*/}
            {/*    onClick={ this.onClickCHeck }*/}
            {/*  >CHECK</Button>*/}
            {/*</div>*/}
            <div className="spell-checker-container__content-spell-checker image-tags-scroll ai-tags-scroll">
              <Tree
                checkable
                onExpand={ this.onExpand }
                autoExpandParent={ this.state.autoExpandParent }
                expandedKeys={ expandedKeys }
                onCheck={ this.onCheck }
                checkedKeys={ checkedKeys }
                onSelect={ (selectedKeys, { node }) => this.findElementByClassAndAddMarkPulse(selectedKeys, node) }
                selectedKeys={ this.state.selectedKeys }
              >
                {
                  renderTreeSpellChecker(treeData[0].children,
                    expandedKeys,
                    this.expandHideCategory,
                    this.onDeleteCategory,
                    redactedKeys,
                    selectedChildrenBold)
                }
              </Tree>
            </div>
          </div>
          {/*<ReviewRedact*/}
          {/*  isDocumentReady={ this.props.isDocumentReady }*/}
          {/*  checkedKeysObjectRefs={ checkedKeysObjectRefs }*/}
          {/*  changePage={ changePage }*/}
          {/*  drawAllMatchedWordsByClassList={ this.drawMatchedWords }*/}
          {/*/>*/}
        </div> :
        'loading...'
    )
  }
}

SpellCheckerPage.propTypes = {
}

export default SpellCheckerPage