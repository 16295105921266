import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Loading from '../../Common/Loading'

import './CollectionTable.css'

class CollectionTable extends React.Component {
  constructor() {
    super()

    this.state = { isMenuOpened: false }
  }

  toggleMenu = () => {
    this.setState({ isMenuOpened: !this.state.isMenuOpened })
  }

  render = () => {
    const { collection } = this.props

    return (
      <tr>
        <td style={{ textAlign: 'left' }}><strong>{ collection.name }</strong></td>
        <td style={{ textAlign: 'center' }}>
          { collection.isFetchingTotalAlerts
            ? <div className="collectionTable__loading"><Loading /></div>
            : collection.totalAlerts.high
          }
        </td>
        <td style={{ textAlign: 'center' }}>
          { collection.isFetchingTotalAlerts
            ? <div className="collectionTable__loading"><Loading /></div>
            : collection.totalAlerts.medium
          }
        </td>
        <td style={{ textAlign: 'center' }}>
          { collection.isFetchingTotalAlerts
            ? <div className="collectionTable__loading"><Loading /></div>
            : collection.totalAlerts.low
          }
        </td>
        <td style={{ textAlign: 'center' }}>
          { collection.isFetchingInfo
            ? <div className="collectionTable__loading"><Loading /></div>
            : collection.totalObjects
          }
        </td>
        <td
          className="collectionTable__actions"
          style={{ textAlign: 'center' }}
          onClick={ this.toggleMenu }>
          ...
          { this.state.isMenuOpened &&
            <ul className="collectionTable__menu">
              <li className="collectionTable__menu-item">
                <Link to={ `/privacy/alerts/${ collection.id }` }>
                  Review Alerts
                </Link>
              </li>
              <li className="collectionTable__menu-item">
                <Link to={ `/privacy/files/${ collection.id }` }>
                  Review Files
                </Link>
              </li>
              <li className="collectionTable__menu-item">
                <Link to={ `/privacy/collection/edit/${ collection.id }` }>
                  Edit
                </Link>
              </li>
            </ul>
          }
        </td>
      </tr>
    )
  }
}
  
CollectionTable.propTypes = {
  collection: PropTypes.object.isRequired
}

export default CollectionTable