import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import * as actions from '../../../../actions/messageActions'
import { apiUserInvite } from '../../../../api/User'
import { emailRegex, getErrorMessage } from '../../../../utils'
import CustomNotificationManager from '../../../Common/CustomNotificationManager'
import Loading from '../../../Common/Loading'
import Message from '../../../Common/Message'
import PageTitle from '../../../Common/PageTitle'
import '../../../../style/common.scss'
import './InviteUser.scss'
import { Spin ,Icon, Switch} from 'antd'
export class InviteUser extends Component {
  constructor() {
    super()

    this.state = {
      emailAddress: '',
      isSending: false,
      valueMultiFactor: true,
      selectedCompanyRow: {}
    }

    this.saveChanges = this.saveChanges.bind(this)
    this.updateValue = this.updateValue.bind(this)
  }

  componentWillUnmount() {
    this.props.hideMessage()
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  updateValue(e) {
    this.setState({
      emailAddress: e.target.value.trim()
    })
  }

  saveChanges(e) {
    e.preventDefault()
    if (!this.isFormValid()) return

    this.inviteUser()
  }

  isFormValid() {
    const { emailAddress } = this.state

    // Validates if email has a valid format.
    if (!emailRegex.test(emailAddress)) {
      CustomNotificationManager.error('Your email does not have a valid format.', 'Error')
      
      return false
    }

    return true
  }

  inviteUser() {
    this.setState({
      isSending: true
    })
    this.props.hideMessage()
    //const currentEmailUser = this.props.user.email
    //const emailUserCurrent = currentEmailUser.split('@')[1]
    //const sendEmailCurrent = this.state.emailAddress
    //const emailCurrent = sendEmailCurrent.split('@')[1]
    //const messageErrors = `You cannot invite a user whose e-mail is on another domain. Please try an e-mail address ending in @${ emailUserCurrent }`
    const data = {
      email: this.state.emailAddress,
      multi_factor_authentication: this.state.valueMultiFactor
    }
    // if (emailCurrent === emailUserCurrent) {
    // if (emailCurrent.localeCompare(emailUserCurrent, undefined, { sensitivity: 'base' })===0) {
    apiUserInvite(data)
      .then((response) => {
        this.setState({
          isSending: false,
        })
        CustomNotificationManager.success('Your invite has been sent. Why not invite someone else?', 'Invited')
      }).catch((e) => {
        const errorMessage = getErrorMessage(e)
        let errorTitle = 'Error'
        let errorText= ''
        if (errorMessage[0].trim() === 'Insufficient Access Rights') {
          const currentUserEmail = this.props.user.email
          const currentUserDomain = currentUserEmail.split('@')[1]
          errorText= `Please insert an email with same domain as yours ( @${ currentUserDomain } )`
        }
        if (errorMessage[1].trim() === 'User with this email already exist') {
          errorTitle= 'User already exist'
          errorText= errorMessage[1].trim()
        }
        CustomNotificationManager.error(errorText, errorTitle)
        this.setState({
          isSending: false,
        })
      })
    // } else {
    //   CustomNotificationManager.error(messageErrors)
    //   this.setState({
    //     isSending: false
    //   })
    // }
  }
  onChangeMultiFactorAuthentication = (valueMultiFactor) => {
    this.setState({ valueMultiFactor: valueMultiFactor })
  }
  render() {
    const { isSending, valueMultiFactor } = this.state
    const { messageArea } = this.props
    
    return (
      <React.Fragment>
        <div className="invite-user-page-update">
          <div className="invite-user-box-update">
            <PageTitle title="Invite User" />

            <div className="invite-user-section">
              {/*<header>*/}
              {/*  <h1 className="invite-user-section-title">Quick invite</h1>*/}
              {/*  <h2 className="invite-user-section-subtitle">Enter an email address to invite a user</h2>*/}
              {/*</header>*/}

              <div className="new-invite-user-form-group">
                <div className="invite-user-form-group invite-user-form-group-update">
                  <label htmlFor="email">Email address</label>
                  <input
                      id="email"
                      type="text"
                      onChange={ this.updateValue } />
                </div>


                {/*{ isSending &&*/}
                {/*<div className="invite-user-loading">*/}
                {/*  <Loading />*/}
                {/*</div>*/}
                {/*}*/}
              </div>
              <form className="invite-user-form new-invite-user-form new-invite-user-form-update">
                <div className="content-factor-authentication-invite content-factor-authentication-invite-update ">
                  <span className="title-factor-invite">Multi Factor Authentication</span>
                  <div className="switcher-option-check-invite switcher-option-check-invite-update">
                    <Switch
                      checked={ valueMultiFactor }
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={ () => this.onChangeMultiFactorAuthentication(!valueMultiFactor) }
                    />
                  </div>
                </div>

              </form>

              { messageArea.visible &&
              <div className="invite-user-message">
                <Message
                  hideMessageAction={ this.props.hideMessage }
                  status={ messageArea.status }
                  text={ messageArea.text } />
              </div>
              }
            </div>
            { !isSending &&
            <input
                className="invite-user-submit user-submit-mfa"
                disabled={ this.state.emailAddress === '' }
                type="submit"
                value="Send invite"
                onClick={ this.saveChanges } />
            }

            {
              isSending &&
                  <div className={'send-invite-btn-wrapper'}>
                    <input
                        className="invite-user-submit user-submit-mfa btn-loading-state"
                        type="submit"
                        value="Send invite"/>
                    <Spin className="send-invite-spinner" spinning={ isSending }
                          indicator={ <Icon type="loading" style={{ fontSize: 16, color:"#red"}} spin />}/>
                  </div>
            }
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const stateMap = (state) => ({
  messageArea: state.messageAreaReducer.messageArea,
  user: state.userReducer.user
})

const dispatchMap = (dispatch) => ({
  hideMessage: () => dispatch(actions.hideMessage()),
  showMessage: (status, text) => dispatch(actions.showMessage({ status, text }))
})

InviteUser.propTypes = {
  hideMessage: PropTypes.func.isRequired,
  messageArea: PropTypes.object.isRequired,
  showMessage: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
}

export default compose(
  connect(stateMap, dispatchMap))(InviteUser)