import moment from 'moment'

export const mapFacetsToFolders = (facets) => {
  return facets.map((folder) => ({
    files: [],
    hasMoreFilesToShow: false,
    isFetchingDataset: true,
    isFetchingLastDateModified: true,
    isFetchingFiles: true,
    name: folder.name,
    query: folder.query
  }))
}

export const putDatasetOnFolder = (folders, folderQuery, dataset) => {
  return folders.map((folder) => {
    if (folder.query === folderQuery) return {
      ...folder,
      isFetchingDataset: false,
      dataset
    }
    return folder
  })
}

export const putDateModifiedOnFolder = (folders, folderQuery, lastDateModified) => {
  return folders.map((folder) => {
    if (folder.query === folderQuery) return {
      ...folder,
      isFetchingLastDateModified: false,
      lastDateModified: lastDateModified ? moment(new Date(lastDateModified)).fromNow() : null
    }
    return folder
  })
}

export const putFilesOnFolders = (folders, folderQuery, data) => {
  return folders.map((folder) => {
    if (folder.query === folderQuery) {
      const newFiles = folder.files.concat(data.documentList)

      return {
        ...folder,
        files: newFiles,
        hasMoreFilesToShow: newFiles.length < data.pagination.total,
        isFetchingFiles: false,
        isFetchingMoreFiles: false
      }
    }
    return folder
  })
}

export const updateFolderWithInformation = (folders, name, informationToUpdate) => {
  return folders.map((folder) => {
    if (folder.name === name) {
      return { ...folder, ...informationToUpdate }
    }
    return folder
  })
}

export const formatContentWithHighlight = (content) => {
  const regexQueryHighlightsStart = new RegExp(/<highlight\.query>/, 'g')
  const regexQueryHighlightsEnd = new RegExp(/<\/highlight\.query>/, 'g')
  const regexAnnotateHighlightsStart = new RegExp(/<highlight\.annotator_([^\s]*?)>/, 'g')
  const regexAnnotateHighlightsEnd = new RegExp(/<\/highlight\.annotator_([^\s]*?)>/, 'g')
  
  content = content.replace(/\n/g, '<br />')
  content = content.replace(regexQueryHighlightsStart, '<span title="Query Term" class="highlight_query">')
  content = content.replace(regexQueryHighlightsEnd, '</span>')
  content = content.replace(regexAnnotateHighlightsStart, '<span title="Annotated Term $1" class="highlight_annotate_$1">')
  content = content.replace(regexAnnotateHighlightsEnd, '</span>')
  
  return content
}