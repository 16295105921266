import React from 'react'
import Button from '../../Common/Button'
import Icon from 'antd/es/icon'

class ReviewSpellImg extends React.Component {

    state = {
        isPrevDisabled: true,
        isNextDisabled: true,
        isRedactingDisabled: true,
        currentIndexToHighlight: -1,
        maxIndex: 0,
        currentPage: 1
    }

    componentDidMount() {
        if (this.props.checkedKeysObjectRefs) {
            this.setState({
                isRedactingDisabled: false
            })
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.checkedKeysObjectRefs !== this.props.checkedKeysObjectRefs && this.props.checkedKeysObjectRefs.length > 0) {
            this.setState({
                isRedactingDisabled: false,
                isNextDisabled: false,
                maxIndex: this.props.checkedKeysObjectRefs.length,
                currentIndexToHighlight: -1
            })
        }
        if (prevProps.checkedKeysObjectRefs !== this.props.checkedKeysObjectRefs && this.props.checkedKeysObjectRefs.length === 0) {
            this.setState({
                isRedactingDisabled: true,
                isNextDisabled: true,
                currentIndexToHighlight: -1
            })
        }
    }
    onNextAiTag = () => {
        const { currentIndexToHighlight, maxIndex } = this.state
        const { checkedKeysObjectRefs } = this.props
        const index = currentIndexToHighlight + 1
        this.setState({
            currentIndexToHighlight: index,
            isNextDisabled: index >= (maxIndex - 1),
            isPrevDisabled: index <= 0,
        }, () => {
            if (index > 0) {
                this.findElementByClassAndRemoveMarkPulse(checkedKeysObjectRefs[index - 1].key)
            }
            if (+this.props.pageNumber !== +checkedKeysObjectRefs[this.state.currentIndexToHighlight].pageNumber) {
                this.props.changePage(+checkedKeysObjectRefs[this.state.currentIndexToHighlight].pageNumber)
            }
            this.findElementByClassAndAddMarkPulse(checkedKeysObjectRefs[this.state.currentIndexToHighlight].key)
        })
    }
    onPrevAiTag = () => {
        const { currentIndexToHighlight, maxIndex } = this.state
        const { checkedKeysObjectRefs } = this.props
        const index = currentIndexToHighlight
        this.setState({
            currentIndexToHighlight: index - 1,
            isPrevDisabled: (index - 1) <= 0,
            isNextDisabled: index >= (maxIndex - 1),
        }, () => {
            if (index <= maxIndex) {
                this.findElementByClassAndRemoveMarkPulse(checkedKeysObjectRefs[index].key)
            }
            if (+this.props.pageNumber !== +checkedKeysObjectRefs[this.state.currentIndexToHighlight].pageNumber) {
                this.props.changePage(+checkedKeysObjectRefs[this.state.currentIndexToHighlight].pageNumber)
            }
            this.findElementByClassAndAddMarkPulse(checkedKeysObjectRefs[this.state.currentIndexToHighlight].key)
        })
    }
    findElementByClassAndAddMarkPulse(className) {
        const element = document.getElementsByClassName(className)
        if (element && element.length > 0) {
            element[0].id = 'matched-word--current'
            element[0].style.borderColor = '#3dd8db'
        }
    }
    findElementByClassAndRemoveMarkPulse(className) {
        const element = document.getElementsByClassName(className)
        if (element && element.length > 0) {
            element[0].removeAttribute('id')
        }
    }
    render() {
        const { isPrevDisabled, isNextDisabled } = this.state
        return (
            <div className="wr-review-redact redacted-selected">
                <div className="button-control">
                    <Button type="primary" theme="button-control button-control__left"
                            onClick={ this.onPrevAiTag }
                            disabled={ isPrevDisabled }>
                        <Icon type="left"/>
                    </Button>
                    <Button type="primary" theme="button-control button-control__right"
                            onClick={ this.onNextAiTag }
                            disabled={ isNextDisabled }>
                        <Icon type="right"/>
                    </Button>
                </div>
            </div>
        )
    }
}

export default ReviewSpellImg