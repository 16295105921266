import React from 'react'
import PropTypes from 'prop-types'

import Accordion from '../../Common/Accordion'
import Content from './Content'
import Loading from '../../Common/Loading'

import './FolderItem.css'

class FolderItem extends React.Component {
  toggleClick = () => {
    const { accordion, index } = this.props
    this.props.toggleAccordion(accordion, index)
  }

  render = () => {
    const { folder, accordion, index, showModal } = this.props

    return (
      <Accordion
        key={ index }
        opened={ accordion[index] }
        header={
          <div className={ accordion[index] ? 'privacyFolderItem-headerOpened' : 'privacyFolderItem-header' }>
            <div className="privacyFolderItem-content privacyFolderItem-contentHigh">
              { renderDate(folder) }
              <div className="privacyFolderItem-info">
                <div className="privacyFolderItem-contentSmallItem">
                  <h2 className="privacyFolderItem-contentTitle">Location</h2>
                  <p className="privacyFolderItem-contentText">{ folder.name }</p>
                </div>
                <div className="privacyFolderItem-contentSmallItem">
                  { folder.isFetchingDataset
                    ? <div className="privacyFolderItem__loading">
                      <Loading />
                    </div>
                    : <React.Fragment>
                      <h2 className="privacyFolderItem-contentTitle">Data Set</h2>
                      <p className="privacyFolderItem-contentText">{ folder.dataset }</p>
                    </React.Fragment>
                  }
                </div>
              </div>
            </div>
            <div 
              className={ accordion[index] ? 'privacyFolderItem-toggleHeaderOpened' : 'privacyFolderItem-toggleHeader' }
              onClick={ this.toggleClick }>
            </div>
          </div>
        }
        content={ <Content contents={ folder.files } folder={ folder } showModal={ showModal } /> }
      />
    )
  }
}

const renderDate = (folder) => {
  if (folder.isFetchingLastDateModified) return (
    <div className="privacyFolderItem-contentSmallItem privacyFolderItem__lastModified">
      <div className="privacyFolderItem__loading">
        <Loading />
      </div>
    </div>
  )

  if (folder.lastDateModified) return (
    <div className="privacyFolderItem-contentSmallItem privacyFolderItem__lastModified">
      <h2 className="privacyFolderItem-contentTitle">Last modified</h2>
      <p className="privacyFolderItem-contentText">{ folder.lastDateModified }</p>
    </div>
  )

  return null
}

FolderItem.propTypes = {
  accordion: PropTypes.object.isRequired,
  folder: PropTypes.object.isRequired,
  index: PropTypes.number,
  showModal: PropTypes.func.isRequired,
  toggleAccordion: PropTypes.func.isRequired
}

export default FolderItem