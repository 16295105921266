import React from 'react'
import './WallBoard.scss'
import { getSystemVariableForAdminUrl } from '../../../api/Admin';

class WallBoard extends React.Component {
  state = {
    adminUrl: '',
  }
  
  componentDidMount() {
    getSystemVariableForAdminUrl().then(res => {
      let url ='';
      if(res.data){
          url = `http://${res.data}`
      }
      this.setState({
        adminUrl: url
      })
    })
  }
  
  render() {
    return ( this.state.adminUrl &&
      <div className="wr-iframe-admin">
        <iframe id="spring-boot-admin" src={`${this.state.adminUrl}`} frameBorder="0" width="100%" height="100%" title='admin-panel'>
          admin not active
        </iframe>
      </div>
    )
  }
}

export default WallBoard