import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import DataLineItem from './DataLineItem'

import './DataLinesList.css'

const DataLineList = ({ datalines }) => {
  if (datalines.length === 0) return (
    <div>
      <Link className="backofficeNewDataLine__link" to="/backoffice/datalines/new">
        New DataLine
      </Link>
      <p className="datalinesList__empty">No results</p>
    </div>
  )
  return (
    <div id="datalines-list" className="datalinesList__wrapperTable">
      <Link className="backofficeNewDataLine__link" to="/backoffice/datalines/new">
        New DataLine
      </Link>
      { datalines.map((dataline) => (
        <DataLineItem dataline={ dataline } key={ dataline.id }/>
      ))}
    </div>
  )
}

DataLineList.propTypes = {
  datalines: PropTypes.array.isRequired
}

export default DataLineList