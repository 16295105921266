import React from "react";
import './ConnectorsPage.scss';
import {Table, Modal, Select, Button, Input, Popconfirm, notification, Icon, Progress, Tooltip} from "antd";
import {customConfirm} from "../utils/AssetRegister/CustomConfirm";
import {
    apiAuthenticateConnector,
    apiDeleteConnector, apiDownloadFilesDriveInitialiseUpload,
    apiEditConnector, apiGetConnectorInfo,
    apiGetConnectorList,
    apiGetSharePointSiteList,
    apiSaveConnector,
    getSmartBoxFilesFilter
} from "../api/SmartBox";
import {connect} from "react-redux";
import CustomNotificationManager from "./Common/CustomNotificationManager";
import imgFolderSmallParent from "../assets/smart-folder-medium-parent.svg";
import cookie from "react-cookies";
const { Option } = Select;
const { Column } = Table

class ConnectorsTable extends React.Component {
    state = {
        columns : [
            {
                title: 'Connector',
                dataIndex: 'connector',
                render: (text) => <a onClick={()=> {this.handleOnClickConnector()}}>{text}</a>,
            },
            {
                title: 'Connected with',
                dataIndex: 'connectedWith',
            },
            {
                title: 'Connection owner',
                dataIndex: 'connectionOwner',
            },
            {
                title: 'Status',
                dataIndex: 'status',
            },
        ],
        dataTable:[],
        // dataTable:[
        //     {
        //         key: '1',
        //         connector: 'Microsoft 365 Exchange',
        //         connectedWith: 'Credentials',
        //         connectionOwner: 'Michael Davis',
        //         status:'Connected',
        //     },
        //     {
        //         key: '2',
        //         connector: 'Microsoft 365',
        //         connectedWith:'Credentials',
        //         connectionOwner:'Joe Black',
        //         status:'Connected',
        //     },
        //     {
        //         key: '3',
        //         connector: 'MS365 SharePoint',
        //         connectedWith: 'Credentials',
        //         connectionOwner: 'Jim Green',
        //         status:'Connected',
        //     },
        //     {
        //         key: '4',
        //         connector: 'MS365 OneDrive',
        //         connectedWith: 'Credentials',
        //         connectionOwner: 'John Brown',
        //         status:'Connected',
        //     },
        // ],
        selectedKeysDelete: [],
       // listOptionConnectors:[ 'Microsoft 365 Exchange', 'Microsoft 365 Onedrive', 'Microsoft 365 SharePoint'],
        listOptionConnectors:[ 'Microsoft 365 Exchange', 'Microsoft 365 Onedrive', 'Microsoft 365 SharePoint'],
        listOptionConnectedWith:['Client Credentials', 'Username & Password Credentials'],
        modalVisible: false,
        hoverDelete:false,
        hoverAdd:false,
        disabledConnectButton:true,
        valueConnectionOwner:'',
        valueCredentialOwner:'',
        valueSelectOptionConnector: '',
        valueSelectOptionConnectedWith: '',
        filesSelectConnector: [],
        modalVisibleEdit: false,
        selectConnector: {},
        modalConnectVisible: false,
        listBoxConnect: [],
        valueSelectedBox: '',
        valueClientId: '',
        valueUserId: '',
        valueSecretValue: '',
        valueTenantId: '',
        valueUserName: '',
        valuePassword: '',
        valueFolderName: '',
        valueOptionConnect: {},
        percentConnectInfo: 0,
        displayProgressConnectInfo: false,
        setIntervalConnect: 0,
        responseConnectInfo: '',
        uploadIdGenerate: '',
        selectedRowKeys: [],
        loadingSaveConnector: false,
        loadingCancelAction:false,
        loadingConnect:false,
        loadingCancelConnect:false,
        loadingCancelEdit:false,
        loadingConnectEdit:false,

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.valueSelectOptionConnector !== this.state.valueSelectOptionConnector
            || prevState.valueSelectOptionConnectedWith !== this.state.valueSelectOptionConnectedWith
            || prevState.valueConnectionOwner !== this.state.valueConnectionOwner || prevState.valueCredentialOwner !== this.state.valueCredentialOwner){
            if(this.state.valueSelectOptionConnectedWith!=='Select' && this.state.valueSelectOptionConnector!=='Select' && this.state.valueConnectionOwner !== '' &&
             this.state.valueSelectOptionConnectedWith && this.state.valueCredentialOwner !== '' ) {
                this.setState({disabledConnectButton: false})
            }else {
                this.setState({disabledConnectButton: true})
            }
        }
        if (prevProps !== this.props && this.props.locationSearch) {
            this.onGetConnectorList()
        }
    }

   componentDidMount() {
     this.onGetConnectorList()
       setTimeout(() => {
           if (this.props.locationSearch) {
               this.onGetConnectorList()
           }
       }, 2000);
       this.fetchFilesBox()
       let result = '';
       const characters = '0123456789';
       const charactersLength = characters.length;
       let counter = 0;
       while (counter < 5) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
           counter += 1;
       }
       this.setState({
           uploadIdGenerate: result
       })
   }
   onGetConnectorList = () => {
       apiGetConnectorList(this.props.clientId).then((response) => {
          this.setState({
              dataTable: response.data ? response.data : []
          }, () => {
              response.data && response.data.length > 0 && response.data.map((list) => {
                  if (list.connectionStatus === 'FILES EXTRACTION IN COURSE') {
                      //this.onGetConnectInfo(list.connectorId)
                      this.setState({
                          connectorIdUpdate: list.connectorId
                      })
                  }
              })
          })
       })
   }
   onGetConnectInfo = (connectorIdUpdate) => {
        const { valueOptionConnect, dataTable, uploadIdGenerate } = this.state
        const { setIntervalConnect } = this.state
       const connectorId = valueOptionConnect.connectorId ? valueOptionConnect.connectorId : connectorIdUpdate
       let result = '';
       const characters = '0123456789';
       const charactersLength = characters.length;
       let counter = 0;
       while (counter < 5) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
           counter += 1;
       }
       apiGetConnectorInfo(uploadIdGenerate).then((response) => {
           this.setState({
               responseConnectInfo: response.data
           })
           this.setState({
               dataTable,
               connectorIdUpdate
           })
           if (response.data.connectorId === null || response.data.connectorStatus === 'CONNECTED' || response.data.connectorStatus === 'FILES EXTRACTION IN COURSE') {
               dataTable && dataTable.length > 0 && dataTable.map((connect) => {
                   if (connect.connectorId === response.data.connectorId) {
                       connect.connectionStatus = response.data.connectorStatus
                       connect.nrOfFilesExtracted  = response.data.nrOfFilesExtracted
                       connect.totalFilesNumber   = response.data.totalFilesNumber
                   }
               })
               this.setIntervalConnectInfo(response.data, connectorIdUpdate)
           } else {
               dataTable && dataTable.length > 0 && dataTable.map((connect) => {
                   if (connect.connectorId === response.data.connectorId) {
                       connect.connectionStatus = response.data.connectorStatus
                       connect.nrOfFilesExtracted  = response.data.nrOfFilesExtracted
                       connect.totalFilesNumber   = response.data.totalFilesNumber
                   }
               })
               clearInterval(setIntervalConnect)
               this.setState({setIntervalConnect: 0})
           }
       })
   }

    setIntervalConnectInfo = (response, connectorIdUpdate) => {
        let { setIntervalConnect } = this.state
        if (response) {
            setIntervalConnect = setInterval(this.callBackCheckConnectInfo, 3000)
        } else {
            clearInterval(setIntervalConnect)
            this.setState({setIntervalConnect: 0})
        }
    }
    callBackCheckConnectInfo = () => {
        const { responseConnectInfo, valueOptionConnect, dataTable, setIntervalConnect, connectorIdUpdate, uploadIdGenerate } = this.state
        const connectorId = valueOptionConnect.connectorId ? valueOptionConnect.connectorId : connectorIdUpdate
        let result = '';
        const characters = '0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < 5) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        if (responseConnectInfo) {
            apiGetConnectorInfo(uploadIdGenerate).then((response) => {
                this.setState({
                    responseConnectInfo: response.data
                })
                if (response.data.connectorId === null || response.data.connectorStatus === 'CONNECTED' || response.data.connectorStatus === 'FILES EXTRACTION IN COURSE') {
                    dataTable && dataTable.length > 0 && dataTable.map((connect) => {
                        if (connect.connectorId === response.data.connectorId) {
                            connect.connectionStatus = response.data.connectorStatus
                            connect.nrOfFilesExtracted = response.data.nrOfFilesExtracted
                            connect.totalFilesNumber = response.data.totalFilesNumber
                        }
                    })
                    this.setState({
                        dataTable
                    })
                } else {
                    dataTable && dataTable.length > 0 && dataTable.map((connect) => {
                        if (connect.connectorId === response.data.connectorId) {
                            connect.connectionStatus = response.data.connectorStatus
                            connect.nrOfFilesExtracted = response.data.nrOfFilesExtracted
                            connect.totalFilesNumber = response.data.totalFilesNumber
                        }
                    })
                    clearInterval(setIntervalConnect)
                    this.setState({setIntervalConnect: 0})
                }
            })
        }
    }
    displayAddNewConnectorModal = () => {
        this.setState({
            modalVisible: true,
            loadingCancelAction:false,
        })
    }
    handleOnClickConnector = () => {
        this.displayAddNewConnectorModal()
    }
    onHoverDeleteConnectors = () => {
        this.setState({
            hoverDelete: true
        })
    }
    onLeaveDeleteConnectors = () => {
        this.setState({
            hoverDelete:false
        })
    }
    onHoverAddConnectors = () => {
        this.setState({
            hoverAdd: true
        })
    }
    onLeaveAddConnectors = () => {
        this.setState({
            hoverAdd:false
        })
    }
    onChangeSelectConnector = (e) => {
        this.setState({
            valueSelectOptionConnector: e,
        })
    }
    onChangeSelectConnectedWith = (e) => {
        this.setState({
            valueSelectOptionConnectedWith: e,
        })
    }
    onChangeInputConnectionOwner = (e) => {
        this.setState({
            valueConnectionOwner:e.target.value,
        })
    }
    onChangeInputCredentialOwner = (e) => {
        this.setState({
            valueCredentialOwner:e.target.value,
        })
    }

    handleCancel = () => {
        this.setState({
            modalVisible: false,
            valueSelectOptionConnector: '',
            valueSelectOptionConnectedWith: '',
            valueConnectionOwner:'',
            valueCredentialOwner:'',
            loadingCancelAction:true,
        })
    }

    removeConnector = () => {
        const { filesSelectConnector } = this.state
        const tableConnector = []
        filesSelectConnector && filesSelectConnector.length > 0 && filesSelectConnector.forEach((item) => {
            tableConnector.push(item.connectorId)
        })
        const connectorRequestDTO = {
            connectorIds: tableConnector,
        }
        apiDeleteConnector(connectorRequestDTO).then((response) => {
            if (response.status === 200) {
                    this.setState({
                        selectedKeysDelete: [],
                        filesSelectConnector: [],
                        selectedRowKeys: [],
                    }, () => {
                        this.onGetConnectorList()
                    })
            }
        })
        // const { selectedKeysDelete ,dataTable} = this.state
        // let newData = []
        // for(let i = 0; i < selectedKeysDelete.length; i++){
        //     for(let j = 0; i < dataTable.length; j++){
        //         if(dataTable[j]['key'] === selectedKeysDelete[i]){
        //             newData = dataTable.splice(i, 1)
        //         }
        //     }
        // }
        // this.setState({dataTable: newData})
    }

    showConfirmDeleteConnector = () => {
        const {filesSelectConnector, dataTable} = this.state
        customConfirm({
            title: <span className="icon-v14-delete"></span>,
            content: `Delete ${ filesSelectConnector && filesSelectConnector.length > 1  && filesSelectConnector.length === dataTable.length ? 'all connectors' : filesSelectConnector.length > 1  ? 'connectors' : 'connector' } ?`,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            mask: true,
            maskClosable: false,
            closeHaveLogic: false,
            className:'asset-register-modal modal-delete-connector',
            centered: true,
            cancelClassName: 'cancel-button',
            confirmClassName: 'confirm-button',
            confirmAction: () => {
                this.removeConnector()
            }
        })
    }

    handleConnect = () => {
        const { valueSelectOptionConnectedWith, valueSelectOptionConnector, valueConnectionOwner, dataTable, valueCredentialOwner } = this.state
        const owners = []
        const types = []
        const providers = []
        dataTable && dataTable.length > 0 && dataTable.forEach((item) => {
            owners.push(item.connectionOwner)
            types.push(item.connectionType)
            providers.push(item.connectorProvider)
        })
        const connectorRequestDTO = {
            connectorProvider: valueSelectOptionConnector,
            connectionType: valueSelectOptionConnectedWith,
            connectorName: valueConnectionOwner,
            credentialOwner: valueCredentialOwner,
            clientId: this.props.clientId
        }
        this.setState({
            loadingSaveConnector :true,
        })
        // if (!owners.includes(valueConnectionOwner) || !types.includes(valueSelectOptionConnectedWith) || !providers.includes(valueSelectOptionConnector)) {
            apiSaveConnector(connectorRequestDTO).then((response) => {
                if (response.status = 200) {
                    this.onGetConnectorList()
                    this.setState({
                        valueSelectOptionConnector: '',
                        valueSelectOptionConnectedWith: '',
                        valueConnectionOwner:'',
                        valueCredentialOwner:'',
                        loadingSaveConnector :false,
                    })
                }
            })
            this.setState({
                modalVisible: false,
            })
        // }
        // else {
        //     if (owners.includes(valueConnectionOwner)) {
        //         notification.info({ message: 'Owner already exist', duration: 6 })
        //     } else if (types.includes(valueSelectOptionConnectedWith)) {
        //         notification.info({ message: 'Connection type already exist', duration: 6 })
        //     } else if (providers.includes(valueSelectOptionConnector)) {
        //         notification.info({ message: 'Connection provider already exist', duration: 6 })
        //     }
        // }
    }
    onSelectConnector = (selectedRowKeys, filesSelect, e) => {
        this.setState({
            selectedRowKeys,
            selectedKeysDelete: selectedRowKeys,
            filesSelectConnector: filesSelect,
        })
    }
    onVisibleModalEditConnector = (e, text, element) => {
        this.setState({
            modalVisibleEdit: true,
            selectConnector: element,
            valueSelectOptionConnector: element.connectorProvider,
            valueSelectOptionConnectedWith: element.connectionType,
            valueConnectionOwner: element.connectorName,
            valueCredentialOwner: element.credential_owner,
            loadingCancelEdit:false,
        })
    }
    handleCancelEdit = () => {
        this.setState({
            modalVisibleEdit: false,
            valueSelectOptionConnector: '',
            valueSelectOptionConnectedWith: '',
            valueConnectionOwner: '',
            valueCredentialOwner: '',
            loadingCancelEdit:true,
        })
    }
    handleConnectEdit = () => {
        const { valueSelectOptionConnectedWith, valueSelectOptionConnector, valueConnectionOwner, dataTable, selectConnector, valueCredentialOwner } = this.state
        const owners = []
        dataTable && dataTable.length > 0 && dataTable.forEach((item) => {
            owners.push(item.connectionOwner)
        })
        const editConnectorDTO  = {
            connectorProviderNew: valueSelectOptionConnector,
            connectionTypeNew: valueSelectOptionConnectedWith,
            connectorNameNew: valueConnectionOwner,
            credentialOwnerNew: valueCredentialOwner,
            connectorId: selectConnector.connectorId,
            clientId: this.props.clientId
        }
        // if (!owners.includes(valueConnectionOwner)) {
            apiEditConnector(editConnectorDTO).then((response) => {
                this.setState({
                    loadingConnectEdit:true,
                })
                if (response.status = 200) {
                    this.onGetConnectorList()
                    this.setState({
                        loadingConnectEdit:false,
                    })
                }
            })
            this.setState({
                modalVisibleEdit: false,
                loadingConnectEdit:false,
            })
        // }
        // else {
        //     notification.info({ message: 'Owner already exist', duration: 6 })
        // }
    }
    cancelVisibleModalConnect = (e) => {
        this.setState({
            modalConnectVisible: false,
            valueClientId: '',
            valueUserId: '',
            valueSecretValue: '',
            valueTenantId: '',
            valueSelectedBox: '',
            valueUserName: '',
            valuePassword: '',
            loadingCancelConnect:true,
        })
    }
    onVisibleModalConnect = (e, record) => {
        this.setState({
            modalConnectVisible: true,
            valueOptionConnect: record,
            loadingCancelConnect:false,
        })
    }
    fetchFilesBox = () => {
        const initFilter = {
            docFilename: '',
            docFolderPath: null
        }
        getSmartBoxFilesFilter(initFilter).then((res) => {
            this.setState({
                listBoxConnect: res.data
            })
        }).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
              }else{
                console.log(error)
              }
        })
    }
    chooseSelectBox(e) {
        this.setState({
            valueSelectedBox: e
        })
    }
    handleConnectDownload = () => {
        const { valueSelectedBox, valueClientId, valueUserId, valueSecretValue, valueTenantId, valueOptionConnect, valueUserName, valuePassword,
            dataTable, valueFolderName } = this.state
        let connectorsUploadDTO = {}
        if (valueOptionConnect.connectionType === "Username & Password Credentials") {
            connectorsUploadDTO = {
                boxPath: valueSelectedBox,
                clientId: valueClientId,
                username: valueUserName,
                password: valuePassword,
                connectionType: valueOptionConnect.connectionType,
                connectorId: valueOptionConnect.connectorId,
                folderName: valueFolderName,
            }
        } else {
            connectorsUploadDTO = {
                boxPath: valueSelectedBox,
                clientId: valueClientId,
                userId: valueUserId,
                secretValue: valueSecretValue,
                tenantId: valueTenantId,
                connectionType: valueOptionConnect.connectionType,
                connectorId: valueOptionConnect.connectorId,
                folderName: valueFolderName,
            }
        }
        let connectorsConnectDTO = {}
        if (valueOptionConnect.connectionType === 'Username & Password Credentials') {
            connectorsConnectDTO = {
                connectorId: valueOptionConnect.connectorId,
                clientIdMs: valueClientId,
                usernameMs: valueUserName,
                passwordMs: valuePassword,
            }
        } else {
            connectorsConnectDTO = {
                connectorId: valueOptionConnect.connectorId,
                clientIdMs: valueClientId,
                clientSecretMs: valueSecretValue,
                tenantIdMs: valueTenantId,
                userIdMs: valueUserId,
            }
        }
        if (valueOptionConnect.connectionType !== 'Username & Password Credentials') {
            this.setState({
                loadingConnect:true,
            })
            apiAuthenticateConnector(connectorsConnectDTO).then((response) => {
                if (response.status === 200) {
                    if (response.data === 'CONNECTED') {
                        notification.success({message: 'Connected!', duration: 6})
                    }
                    if (response.data && response.data === 'CONNECTION_FAILED') {
                        notification.info({message: 'CONNECTION FAILED!', duration: 6})
                    }
                    if (response.data && response.data === 'Invalid credentials! Please try again!') {
                        notification.info({message: 'Invalid credentials! Please try again!', duration: 6})
                    }
                    this.onGetConnectorList()
                    this.setState({
                        modalConnectVisible: false,
                        valueClientId: '',
                        valueUserId: '',
                        valueSecretValue: '',
                        valueTenantId: '',
                        valueSelectedBox: '',
                        valueUserName: '',
                        valuePassword: '',
                        dataTable,
                        loadingConnect:false,
                    })
                }
            })
        } else {
            const clientId = valueClientId
            const clientAndConnect = `clientIdMs${valueClientId}` + `connectorId${valueOptionConnect.connectorId}`
            const connectorId = valueOptionConnect.connectorId
            const urlLocal = 'http://localhost:3000/data-mapping/connectors'
            const redirectUri = window.location.origin + `/data-mapping/connectors`
            const urlMicrosoft = `https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&response_mode=query&scope=user.read&state=${clientAndConnect}`
            window.open(urlMicrosoft)
            const connectValue = {
                connectorId: valueOptionConnect.connectorId,
                clientIdMs: valueClientId,
            }
            cookie.save('connectMicrosoft', connectValue, { path: './' })
        }
        // apiDownloadFilesDriveInitialiseUpload(connectorsUploadDTO).then((response) => {
        //      if (response.status === 200) {
        //          if (response.data === 'CONNECTED') {
        //              notification.success({ message: 'Connected!', duration: 6 })
        //              this.onGetConnectorList()
        //          }
        //          dataTable && dataTable.length > 0 && dataTable.forEach((list) => {
        //              if (valueOptionConnect.connectorId === list.connectorId) {
        //                  list.connectionStatus = response.data === 'CONNECTION FAILED' ? 'CONNECTION FAIL' : response.data
        //              }
        //          })
        //          this.setState({
        //              modalConnectVisible: false,
        //              valueClientId: '',
        //              valueUserId: '',
        //              valueSecretValue: '',
        //              valueTenantId: '',
        //              valueSelectedBox: '',
        //              valueUserName: '',
        //              valuePassword: '',
        //              dataTable
        //          }, () => {
        //              this.onGetConnectInfo()
        //          })
        //      }
        // })
    }
    handleChangeConnect(event, field) {
        switch (field) {
            case 'field_clientId':
                this.setState({ valueClientId: event.target.value })
                break
            case 'field_userId':
                this.setState({ valueUserId: event.target.value })
                break
            case 'field_secretValue':
                this.setState({ valueSecretValue: event.target.value })
                break
            case 'field_tenantId':
                this.setState({ valueTenantId: event.target.value })
                break
            case 'field_userName':
                this.setState({ valueUserName: event.target.value })
                break
            case 'field_password':
                this.setState({ valuePassword: event.target.value })
                break
            case 'field_folderName':
                this.setState({ valueFolderName: event.target.value })
                break
            default:
                break
        }
    }
    render (){
        const {hoverDelete, hoverAdd, modalVisible, valueSelectOptionConnector, valueSelectOptionConnectedWith, disabledConnectButton,selectedKeysDelete,
            modalVisibleEdit, modalConnectVisible, valueClientId, valueUserId, valueSecretValue,
            valueTenantId, valueOptionConnect, valueUserName, valuePassword, filesSelectConnector, selectedRowKeys, loadingSaveConnector, loadingCancelAction, loadingConnect, loadingCancelConnect,loadingCancelEdit, loadingConnectEdit} = this.state

        const { client } = this.props

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectConnector,
                // (selectedRowKeys) => {
                // this.setState({selectedKeysDelete: selectedRowKeys})
            //},
        };
        return (
            <div className="connectors-table">
                <div className="connectors-table__content">
                    <div className="topbar">
                        <div className="btn-container">
                            <button className={hoverAdd ? "icon-v17-add-active":"icon-v17-add"}
                                    onMouseEnter={(e) => this.onHoverAddConnectors(e)}
                                    onMouseLeave={() => this.onLeaveAddConnectors()}
                                    onClick={() => this.displayAddNewConnectorModal()}>
                            </button>
                            <button className={ hoverDelete ? "icon-v17-delete-active" : "icon-v17-delete"}
                                    onMouseEnter={(e) => this.onHoverDeleteConnectors(e)}
                                    onMouseLeave={() => this.onLeaveDeleteConnectors()}
                                    onClick={() => this.showConfirmDeleteConnector()}
                                     disabled={this.state.selectedKeysDelete.length === 0 ||this.state.dataTable.length === 0}>
                            </button>

                        </div>
                    </div>
                    <div className="table-connectors-content">
                        <Table
                            rowSelection={ rowSelection }
                            // rowKey={ selectedRowKeys }
                            rowKey = { (record) => { return `rowKey${ record.connectorId }` }}
                            //columns={this.state.columns}
                            dataSource={this.state.dataTable}
                            pagination={false}>
                            <Column className="column-error-report"
                                    title = 'Connector'
                                    dataIndex="connectorProvider" key="connectorProvider"
                                    render={(text, record) => {
                                        return (
                                            <span key={ record.connectorId } onClick={(e) => this.onVisibleModalEditConnector(e, text, record)} className="text-error-report text-provider">{ text }</span>
                                        )
                                    }} />
                            <Column className="column-error-report"
                                    title = 'Connector name'
                                    dataIndex="connectorName" key="connectorName"
                                    render={(text, record) => {
                                        return (
                                            <span key={ record.connectorId } className="text-error-report text-update">{ text }</span>
                                        )
                                    }} />
                            <Column className="column-error-report"
                                    title = 'Connected with'
                                    dataIndex="connectionType" key="connectionType"
                                    render={(text, record) => {
                                        return (
                                            <span key={ record.connectorId } className="text-error-report">{ text }</span>
                                        )
                                    }} />
                            <Column className="column-error-report"
                                    title = 'Credential owner'
                                    dataIndex="credential_owner" key="credential_owner"
                                    render={(text, record) => {
                                        return (
                                            <span key={ record.connectorId } className="text-error-report text-update">{ text }</span>
                                        )
                                    }} />
                            <Column className="column-error-report"
                                    title = 'Status'
                                    dataIndex="connectionStatus" key="connectionStatus"
                                    render={(text, record) => {
                                        return (
                                            <span key={ record.connectorId } className="text-error-report">{ text }</span>
                                        )
                                    }} />
                            <Column className="column-error-report"
                                    title = 'Connection Expiration Date'
                                    dataIndex="expireDate" key="expireDate"
                                    render={(text, record) => {
                                        return (
                                            <span key={ record.expireDate } className="text-error-report">
                                                { record && record.expireDate && record.expireDate !== null ? `${record.expireDate.substr(0, record.expireDate.indexOf(' '))} at
                                                ${record.expireDate.substr(record.expireDate.indexOf(' '), record.expireDate.length)}` : '' }</span>
                                        )
                                    }} />
                            {/*<Column className="column-error-report"*/}
                            {/*        //title = 'Status'*/}
                            {/*        dataIndex="progress" key="progress"*/}
                            {/*        render={(text, record) => {*/}
                            {/*            return (*/}
                            {/*                <span key={ record.connectorId } className="text-error-report progress-info">*/}
                            {/*                    <Tooltip title={<div>*/}
                            {/*                        <p>Number files extracted: { record.nrOfFilesExtracted}</p>*/}
                            {/*                        <p>Number files: { record.totalFilesNumber}</p>*/}
                            {/*                    </div>}>*/}
                            {/*                    { (record.connectionStatus === 'CONNECTED' || record.connectionStatus === 'FILES EXTRACTION IN COURSE') &&*/}
                            {/*                        <Progress*/}
                            {/*                            percent={((record.nrOfFilesExtracted / record.totalFilesNumber)*100).toFixed(2)}/>*/}
                            {/*                    }*/}
                            {/*                    </Tooltip>*/}
                            {/*                </span>*/}
                            {/*            )*/}
                            {/*        }} />*/}
                            <Column className="column-error-report"
                                    dataIndex="connectorId" key="connectorId"
                                    render={(text, record) => {
                                        return (
                                            <span className="text-error-report active-connect"
                                                  key={ record.connectorId }
                                                  onClick={(e) => this.onVisibleModalConnect(e, record)}>Connect</span>
                                        )
                                    }} />
                        </Table>
                    </div>


                </div>
                <Modal className="connector-modal" title={<div className="content-header-modal-connector"><span>Connector</span></div>} visible={ modalVisible} footer={null}
                       onCancel={(e)=>this.handleCancel(e) } closable={ false }>
                    <div className="add-connector-form">
                        <div className="select-connector-wrapper">
                            <div className="title-select-connector">Connector</div>
                            <Select className="select-connector"
                                    value={valueSelectOptionConnector ? valueSelectOptionConnector : 'Select' }
                                    //defaultValue={valueSelectOptionConnector}
                                    placeholder="Select"
                                    onChange={(e) => this.onChangeSelectConnector(e)}>
                                { this.state.listOptionConnectors.map((item,index) => {
                                    return  (<Option className="options-select-connector" value={item} key={index}>
                                        {item}
                                    </Option>)
                                })}
                            </Select>


                        </div>
                        <div className="select-connector-wrapper">
                            <div className="title-select-connector">Connector name</div>
                            <Input className="input-connection-owner" onChange={(e) => this.onChangeInputConnectionOwner(e)} value={this.state.valueConnectionOwner}></Input>
                        </div>
                        <div className="select-connector-wrapper">
                            <div className="title-select-connector">Connected with</div>
                            <Select className="select-connector"
                                    value={valueSelectOptionConnectedWith ? valueSelectOptionConnectedWith : 'Select'}
                                    //defaultValue={valueSelectOptionConnectedWith}
                                    placeholder="Select"
                                    onChange={(e) => this.onChangeSelectConnectedWith(e)}>
                                { this.state.listOptionConnectedWith.map((item,index) => {
                                    return  (<Option className="options-select-connector" value={item} key={index}>
                                        {item}
                                    </Option>)
                                })}

                            </Select>
                        </div>
                        <div className="select-connector-wrapper">
                            <div className="title-select-connector">Credential owner</div>
                           <Input className="input-connection-owner" onChange={(e) => this.onChangeInputCredentialOwner(e)} value={this.state.valueCredentialOwner}></Input>
                        </div>

                    </div>
                    <div className="footer-connector-modal">
                        <Button className={ loadingCancelAction ? "action-cancel-loading" : "action-cancel-connect"} loading={loadingCancelAction} onClick={(e) => this.handleCancel(e)}>Cancel</Button>
                        <Button  type="primary" className={ loadingSaveConnector ? "action-connect-loading" : "action-connect"} disabled={disabledConnectButton} loading={loadingSaveConnector} onClick={this.handleConnect}>Save</Button>
                    </div>
                </Modal>

                <Modal className="connector-modal" title={<div className="content-header-modal-connector"><span>Connector</span></div>} visible={ modalVisibleEdit } footer={null}
                       onCancel={(e)=>this.handleCancelEdit(e) } closable={ false }>
                    <div className="add-connector-form">
                        <div className="select-connector-wrapper">
                            <div className="title-select-connector">Connector</div>
                            <Select className="select-connector"
                                    value={valueSelectOptionConnector ? valueSelectOptionConnector : 'Select' }
                                //defaultValue={valueSelectOptionConnector}
                                    placeholder="Select"
                                    onChange={(e) => this.onChangeSelectConnector(e)}>
                                { this.state.listOptionConnectors.map((item,index) => {
                                    return  (<Option className="options-select-connector" value={item} key={index}>
                                        {item}
                                    </Option>)
                                })}
                            </Select>


                        </div>
                        <div className="select-connector-wrapper">
                            <div className="title-select-connector">Connector name</div>
                            <Input className="input-connection-owner" onChange={(e) => this.onChangeInputConnectionOwner(e)} value={this.state.valueConnectionOwner}></Input>
                        </div>
                        <div className="select-connector-wrapper">
                            <div className="title-select-connector">Connected with</div>
                            <Select className="select-connector"
                                    value={valueSelectOptionConnectedWith ? valueSelectOptionConnectedWith : 'Select'}
                                //defaultValue={valueSelectOptionConnectedWith}
                                    placeholder="Select"
                                    onChange={(e) => this.onChangeSelectConnectedWith(e)}>
                                { this.state.listOptionConnectedWith.map((item,index) => {
                                    return  (<Option className="options-select-connector" value={item} key={index}>
                                        {item}
                                    </Option>)
                                })}

                            </Select>
                        </div>
                        <div className="select-connector-wrapper">
                            <div className="title-select-connector">Credential owner</div>
                            <Input className="input-connection-owner" onChange={(e) => this.onChangeInputCredentialOwner(e)} value={this.state.valueCredentialOwner}></Input>
                        </div>

                    </div>
                    <div className="footer-connector-modal">
                        <Button className={loadingCancelEdit ? "action-cancel-loading" : "action-cancel-connect"}  loading={loadingCancelEdit} onClick={(e) => this.handleCancelEdit(e)}>Cancel</Button>
                        <Button  type="primary" className={loadingConnectEdit ? "action-connect-loading" : "action-connect"} loading={loadingConnectEdit} disabled={disabledConnectButton} onClick={this.handleConnectEdit}>Save</Button>
                    </div>
                </Modal>

                <Modal className="connector-modal" title={<div className="content-header-modal-connector"><span>Connect</span></div>} visible={ modalConnectVisible } footer={null}
                       onCancel={(e)=>this.cancelVisibleModalConnect(e) } closable={ false }>
                    <div className="add-connector-form">
                        <div className="select-connector-wrapper">
                            <div className="title-select-connector">ClientId</div>
                            <Input className="input-connection-owner"
                                   value={ valueClientId }
                            onChange={(e) => this.handleChangeConnect(e, 'field_clientId')}></Input>
                        </div>
                        { valueOptionConnect.connectionType !== "Username & Password Credentials" &&
                        <div className="select-connector-wrapper">
                            <div className="title-select-connector">UserId</div>
                            <Input className="input-connection-owner"
                                   value={valueUserId}
                                   onChange={(e) => this.handleChangeConnect(e, 'field_userId')}></Input>
                        </div>
                        }
                        { valueOptionConnect.connectionType !== "Username & Password Credentials" &&
                            <div className="select-connector-wrapper">
                                <div className="title-select-connector">AppSecretValue</div>
                                <Input className="input-connection-owner"
                                       value={valueSecretValue}
                                       onChange={(e) => this.handleChangeConnect(e, 'field_secretValue')}></Input>
                            </div>
                        }
                        { valueOptionConnect.connectionType !== "Username & Password Credentials" &&
                        <div className="select-connector-wrapper">
                            <div className="title-select-connector">TenantId</div>
                            <Input className="input-connection-owner"
                                   value={valueTenantId}
                                   onChange={(e) => this.handleChangeConnect(e, 'field_tenantId')}></Input>
                        </div>
                        }
                        {/*{ valueOptionConnect.connectionType === "Username & Password Credentials" &&*/}
                        {/*<div className="select-connector-wrapper">*/}
                        {/*    <div className="title-select-connector">User Name</div>*/}
                        {/*    <Input className="input-connection-owner"*/}
                        {/*           value={valueUserName}*/}
                        {/*           onChange={(e) => this.handleChangeConnect(e, 'field_userName')}></Input>*/}
                        {/*</div>*/}
                        {/*}*/}
                        {/*{ valueOptionConnect.connectionType === "Username & Password Credentials" &&*/}
                        {/*<div className="select-connector-wrapper">*/}
                        {/*    <div className="title-select-connector">Password</div>*/}
                        {/*    <Input className="input-connection-owner" type="password"*/}
                        {/*           value={valuePassword}*/}
                        {/*           onChange={(e) => this.handleChangeConnect(e, 'field_password')}></Input>*/}
                        {/*</div>*/}
                        {/*}*/}
                        {/*<div className="select-connector-wrapper">*/}
                        {/*    <div className="title-select-connector">Folder Name</div>*/}
                        {/*    <Input className="input-connection-owner"*/}
                        {/*           value={valueFolderName}*/}
                        {/*           onChange={(e) => this.handleChangeConnect(e, 'field_folderName')}></Input>*/}
                        {/*</div>*/}
                        {/*<div className="select-connector-wrapper select-connector-box">*/}
                        {/*    <div className="title-select-connector">Box</div>*/}
                        {/*    <Select value={ valueSelectedBox === '' ? 'Choose BOX' : valueSelectedBox }*/}
                        {/*            className="select-box"*/}
                        {/*            suffixIcon={ <Icon type="caret-down" /> }*/}
                        {/*            onChange={ (e) => this.chooseSelectBox(e) }>*/}
                        {/*        { listBoxConnect && listBoxConnect.length > 0 && listBoxConnect.map((item, index) => {*/}
                        {/*            return (*/}
                        {/*                <Option key={item.id} className="item-options-box" value={ item.name }>*/}
                        {/*                    <img alt={ valueSelectedBox } className="icon-box" src={ imgFolderSmallParent }/>*/}
                        {/*                    { item.name }</Option>*/}
                        {/*            )*/}
                        {/*        })*/}
                        {/*        }*/}
                        {/*    </Select>*/}
                        {/*</div>*/}

                    </div>
                    <div className="footer-connector-modal">
                        <Button className={ loadingCancelConnect ? "action-cancel-loading" : "action-cancel-connect"} loading={loadingCancelConnect} onClick={(e) => this.cancelVisibleModalConnect(e)}>Cancel</Button>
                        <Button  type="primary" className={loadingConnect ? "action-connect-loading" : "action-connect"} loading={loadingConnect} onClick={this.handleConnectDownload}>Connect</Button>
                    </div>
                </Modal>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id,
    client: state.subscriptionReducer.subscription.subscription.client,
})
export default connect(mapStateToProps, {
})(ConnectorsTable)