import React, { useState, useEffect } from 'react'
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import { getYearlyOverview } from '../../../api/Sar'
import Icon from 'antd/es/icon'
import moment from 'moment'
import CustomNotificationManager from "../../Common/CustomNotificationManager";

const LineChart = () => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
  const [lineChartLabels] = useState(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'])
  const [lineChartValues, setLineChartValues] = useState([])

  useEffect(() => {
    const newLineChartValues = []
    let minMonth = moment().month()
    minMonth = minMonth < 3 ? 12 : minMonth
    for (let i= 0; i< 12; i++) {
      if (selectedYear === moment().year() && i <= minMonth) {
        newLineChartValues[i] = 0
      } else if (selectedYear < moment().year()) {
        newLineChartValues[i] = 0
      } else {
        newLineChartValues[i] = null
      }
    }

    const getList = async() => {
      getYearlyOverview(selectedYear).then((response) => {
        if (response.status === 200 && response.data) {
          const recalculatedResponse = {}
          if (Object.keys(response.data).length) {
            Object.keys(response.data).forEach((key) => {
              const temp = key.split('-')
              if (temp.length > 2) {
                if (recalculatedResponse.hasOwnProperty(temp[1])) {
                  recalculatedResponse[temp[1]] += response.data[key]
                } else {
                  recalculatedResponse[temp[1]] = response.data[key]
                }
              }
            })
          }
          if (Object.keys(recalculatedResponse).length) {
            Object.keys(recalculatedResponse).forEach((key) => {
              const month = Number(key) - 1
              if (selectedYear !== moment().year() || (selectedYear === moment().year() && month <= minMonth)) {
                newLineChartValues[month] = recalculatedResponse[key]
              }
            })
          }

          setSelectedYear(selectedYear)
          setLineChartValues(newLineChartValues)
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    }
    getList()

  }, [selectedYear])

  const ChangeChart = (newYear) => {
    if (newYear > new Date().getFullYear()) {
      newYear = new Date().getFullYear()
    }
    setSelectedYear(newYear)
  }

  return (
    <div className="sar_statistic_chart">
      <span className='title-statistic-chart'>Received request { selectedYear }</span>
      <Icon className="arrow arrow-left" type="left" onClick={ () => ChangeChart(selectedYear - 1) } />
      <Icon className="arrow arrow-right" type="right" onClick={ () => ChangeChart(selectedYear + 1) } />
      <div className="w-100">
        <Chart type='line'
          data={
            {
              labels: lineChartLabels,
              datasets: [
                {
                  label: 'Values',
                  fill: false,
                  lineTension: 0.5,
                  backgroundColor: '#6e7882',
                  borderColor: '#00D3D8',
                  borderWidth: 4,
                  pointBorderColor: '#6e7882',
                  pointBackgroundColor: '#fff',
                  pointBorderWidth: 0,
                  pointHoverRadius: 8,
                  pointHoverBackgroundColor: '#ffffff',
                  pointHoverBorderColor: '#222B38',
                  pointHoverBorderWidth: 8,
                  pointRadius: 0,
                  pointHitRadius: 10,
                  data: lineChartValues,
                }
              ]
            }
          }
          height={ 150 }
          options={{
            animation: {
              duration: 0
            },
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
            },
            layout: {
              padding: {
                top: 15,
                right: 10,
                left: 10,
              },
            },
            tooltips: {
              custom: function(tooltip) {
                if (!tooltip) return
                tooltip.displayColors = false
              },
              callbacks: {
                title: function(tooltipItem, data) {
                  const i = tooltipItem[0].index
                  const shortMonthName = data.labels[i]
                  switch (shortMonthName) {
                    case 'Jan' : return 'January'
                    case 'Feb' : return 'February'
                    case 'Mar' : return 'March'
                    case 'Apr' : return 'April'
                    case 'Ma' : return 'May'
                    case 'Jun' : return 'June'
                    case 'Jul' : return 'July'
                    case 'Aug' : return 'August'
                    case 'Sep' : return 'September'
                    case 'Oct' : return 'October'
                    case 'Nov' : return 'November'
                    case 'Dec' : return 'December'
                    default : return shortMonthName
                  }
                },
                label: function(tooltipItem, data) {
                  let label = 'Received requests : '
                  label += Math.round(tooltipItem.yLabel * 100) / 100
                  return label
                },
              }
            },
            scales: {
              x: {
                beginAtZero: true,
              },
              y: {
                  beginAtZero: true,
                  display: false,
              }
            }
          }}
          redraw={true}
          key={Math.random()}
        />
      </div>
    </div>
  )
}

export default LineChart