import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import * as actions from '../../../../actions/messageActions'
import { apiUserMultitenancySignUp } from '../../../../api/User'
import { emailRegex, getErrorMessage } from '../../../../utils'
import CustomNotificationManager from '../../../Common/CustomNotificationManager'
import Loading from '../../../Common/Loading'
import Message from '../../../Common/Message'
import PageTitle from '../../../Common/PageTitle'
import '../../../../style/common.scss'
import './InviteUser.scss'
import {Checkbox, Icon, Spin} from "antd";
import {apiInviteTenant} from "../../../../api/Admin";

export class MultitenancyInvitation extends Component {
  constructor() {
    super()

    this.state = {
      emailAddress: '',
      companyName: '',
      isSending: false,
      checkedInvite: false
    }
  }
  componentWillUnmount() {
    this.props.hideMessage()
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  updateValue(e) {
    this.setState({
      emailAddress: e.target.value.trim()
    })
  }
  updateValueCompanyName(e) {
    this.setState({
      companyName: e.target.value.trim()
    })
  }
  saveChanges(e) {
    e.preventDefault()
    if (!this.isFormValid()) return

    this.inviteUser()
  }

  isFormValid() {
    const { emailAddress, checkedInvite, companyName } = this.state
    if (companyName && checkedInvite && !emailRegex.test(emailAddress)) {
      CustomNotificationManager.error('Your email does not have a valid format.', 'Error')
      
      return false
    }

    return true
  }

  inviteUser() {
    const { emailAddress, checkedInvite, companyName } = this.state
    const currentUserEmailDomain = this.props.user.email.split('@')[1]
    const messageErrors = `You cannot invite a user whose email is on same domain. Please try an e-mail address with other ending than @${ currentUserEmailDomain }`
    this.setState({
      isSending: true
    })
    this.props.hideMessage()
    const data = this.state.emailAddress
    const inviteTenantDTO = {
      emailUser: emailAddress,
      companyName: companyName,
      inviteUser: checkedInvite,
    }
    if ((companyName && !checkedInvite) || emailAddress.split('@')[1] !== currentUserEmailDomain) {
      apiInviteTenant(inviteTenantDTO)
        .then(() => {
          this.setState({
            isSending: false
          })
          CustomNotificationManager.success('Your invite has been sent. Why not invite someone else?', 'Invited')
        }).catch((e) => {
          let errorMessage = getErrorMessage(e)
          if (errorMessage[0].trim() === 'Insufficient Access Rights') {
            errorMessage = errorMessage[0].trim()
          }
          CustomNotificationManager.error(errorMessage, 'Error')
          this.setState({
            isSending: false
          })
        })
    } else {
      CustomNotificationManager.error(messageErrors)
      this.setState({
        isSending: false
      })
    }
  }
  toggleCheckedInvite = (e) => {
    this.setState({
      checkedInvite: e.target.checked
    })
  }
  render() {
    const { isSending, checkedInvite, companyName, emailAddress } = this.state
    const { messageArea } = this.props
    return (
      <div className="invite-user-page invite-tenant-page-update">
        <div className=" invite-tenant-box-update">
          <PageTitle title="Create tenant" />
          <div className="invite-user-section">
            {/*<header>*/}
            {/*  <h1 className="invite-user-section-title">Quick invite</h1>*/}
            {/*  <h2 className="invite-user-section-subtitle">Please insert an email!</h2>*/}
            {/*</header>*/}

            <form className="invite-user-form invite-user-form-update">
              <div className="invite-user-form-group company-name">
                <label htmlFor="company">Company name</label>
                <input
                  id="company"
                  type="text"
                  onChange={ (e) => this.updateValueCompanyName(e) } />
              </div>
              <div className="invite-user-form-group checked-invite">
                <label htmlFor="email">Invite user?</label>
                <Checkbox
                    checked={checkedInvite}
                    onChange={(e) => this.toggleCheckedInvite(e)}
                    className='modal-content-headlines'>
                </Checkbox>
              </div>
              {checkedInvite &&
              <div className="invite-user-form-group invite-address">
                <label htmlFor="email">Email address</label>
                <input
                    id="email"
                    type="text"
                    onChange={(e) => this.updateValue(e)}/>
              </div>
              }

              {/*{ isSending &&*/}
              {/*  <div className="invite-user-loading">*/}
              {/*    <Loading />*/}
              {/*  </div>*/}
              {/*}*/}
            </form>

            { messageArea.visible &&
              <div className="invite-user-message">
                <Message
                  hideMessageAction={ this.props.hideMessage }
                  status={ messageArea.status }
                  text={ messageArea.text } />
              </div>
            }
          </div>
          { !isSending &&
          <input
              className="invite-user-submit"
              disabled={ companyName && !checkedInvite ? false : companyName && checkedInvite && emailAddress ? false : true }
              type="submit"
              value="Send invite"
              onClick={ (e) => this.saveChanges(e) } />
          }
          { isSending &&
          <div className={'send-invite-btn-wrapper'}>
            <input
                className="invite-user-submit btn-loading-state"
                type="submit"
                value="Send invite"/>
            <Spin className="send-invite-spinner" spinning={ isSending }
                  indicator={ <Icon type="loading" style={{ fontSize: 16, color:"#red"}} spin />}/>
          </div>

          }

        </div>
      </div>
    )
  }
}

const stateMap = (state) => ({
  messageArea: state.messageAreaReducer.messageArea,
  user: state.userReducer.user
})

const dispatchMap = (dispatch) => ({
  hideMessage: () => dispatch(actions.hideMessage()),
  showMessage: (status, text) => dispatch(actions.showMessage({ status, text }))
})

MultitenancyInvitation.propTypes = {
  hideMessage: PropTypes.func.isRequired,
  messageArea: PropTypes.object.isRequired,
  showMessage: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
}

export default compose(connect(stateMap, dispatchMap))(MultitenancyInvitation)