
import { calculateStorageString } from '../../../../../utils/Connectors'

export const processData = (data, depth) => {
  if (data instanceof Array)
  {
    return data.map((facet, index) => (
      processNode(facet, depth, index)
    ))  
  }
  return [processNode(data)]
}

export const processNode = (facet, depth, index) => {
  let children = []
  if (facet.stats)
  {
    children = processData(facet.stats.field.value)
  }
  if (depth > 0)
  {
    if (children.length > 0)
    {
      return {
        id: `${ depth }_${ index }`,
        count: facet.count,
        angle: facet.count,
        title: `${ facet.name }`,
        query: facet.query,
        color: '#12939A',
        children: children,
        size: calculateStorageString(facet.nonpivotstats.doc_size.sum)
      }
    }
    return {
      id: `${ depth }_${ index }`,
      count: facet.count,
      angle: facet.count,
      title: facet.name,
      query: facet.query,
      color: '#12939A',
      size: calculateStorageString(facet.nonpivotstats.doc_size.sum)
    }
  }
  if (children.length > 0)
  {
    return {
      id: `${ depth }_${ index }`,
      title: facet.name,
      angle: facet.count,
      query: facet.query,
      color: '#12939A',
      children: children,
    }
  }
  return {
    id: `${ depth }_${ index }`,
    count: facet.count,
    angle: facet.count,
    query: facet.query,
    title: facet.name,
    size: calculateStorageString(facet.nonpivotstats.doc_size.sum),
    color: '#12939A'
  }
}
