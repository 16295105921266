import React from "react"
import withRouter from "../../../private-routers/withRouter";
import Notification from "./Notification";

class WrapperNotification extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ignore: true,
      title: '',
      url: '',
      text: 'hello'
    }
  }

  componentDidMount () {
    if (this.props.onMounted) {
      this.props.onMounted({
        showNotification: (title, text, url) => this.showNotification(title, text, url)
      })
    }
  }

  showNotification = (title, text, url) => {
    if(this.state.ignore) {
      return
    }
    title = title ? title : 'Smartbox notification'
    url = url ? url: window.location.href.toString()
    const body = text ? text : 'Smartbox notification content'
    const tag = Date.now()
    const icon = window.location.origin + '/libs/smartbox_icon.png'

    const options = {
      tag: tag,
      body: body,
      icon: icon,
      lang: 'en',
      dir: 'ltr'
    }
    this.setState({
      title: title,
      options: options,
      url: url
    })
  }

  handleNotSupported = () => {
    this.setState({
      ignore: true
    })
  }

  handlePermissionGranted(){
    this.setState({
      ignore: false
    })
  }

  handlePermissionDenied(){
    this.setState({
      ignore: true
    })
  }

  handleNotificationOnShow(e, tag){
  }

  handleNotificationOnClick(e, tag){
    this.props.navigate(this.state.url)
  }

  handleNotificationOnError(e, tag){
  }

  handleNotificationOnClose(e, tag){
  }

  render() {
    return (
        <div>
          <Notification
            ignore={this.state.ignore && this.state.title !== ''}
            notSupported={this.handleNotSupported.bind(this)}
            onPermissionGranted={this.handlePermissionGranted.bind(this)}
            onPermissionDenied={this.handlePermissionDenied.bind(this)}
            onShow={this.handleNotificationOnShow.bind(this)}
            onClick={this.handleNotificationOnClick.bind(this)}
            onClose={this.handleNotificationOnClose.bind(this)}
            onError={this.handleNotificationOnError.bind(this)}
            timeout={5000}
            title={this.state.title}
            options={this.state.options}
            swRegistration={this.props.swRegistration}
          />
          <audio id='sound' preload='auto'>
            <source src='./sound.mp3' type='audio/mpeg' />
            <source src='./sound.ogg' type='audio/ogg' />
            <embed hidden={true} autostart='false' loop={false} src='./sound.mp3' />
          </audio>
        </div>
    )
  }
}
WrapperNotification = withRouter(WrapperNotification)
export default WrapperNotification