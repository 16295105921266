import React from 'react'

import { AlertsConsumer } from '../../../contexts/Alerts'

const WithAlerts = (Component) => {
  return class WithAlerts extends React.Component {
    render = () => {
      return (
        <AlertsConsumer>
          { ({ ...props }) => (
            <Component {...this.props} {...props} />
          )}
        </AlertsConsumer>
      )
    }
  }
}

export default WithAlerts