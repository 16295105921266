import React from 'react'
import AnnotationColorTable from './AnnotationColorTable'
import { getAnnotationColorsCategoryByClientId, saveAnnotationColor } from '../../../../api/Sar'
import { connect } from 'react-redux'
import { CompactPicker } from 'react-color'
import CustomNotificationManager from '../../../Common/CustomNotificationManager'
import './annotations.scss'

class AnnotationColor extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      classNameFind: 'page-filter__header--filter-container__filter--search-inactive',
      classNameFindFocus: 'page-filter__header--filter-container__filter--search-active',
      annotations: [],
      annotationsCopy: [],
      count: null,
      displayColorPicker: false,
      newColor: '#ddd',
      lastSelectedColor: '',
      newText: '',
      showAddColorDiv: false,
      clientId: null
    }
  }

  componentDidMount() {
    if (this.props.godAdminMode) {
    } else if (!this.props.godAdminMode && this.props.clientId) {
      this.fetchAnnotationColorsByClientId(this.props.clientId)
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (!nextProps.godAdminMode && this.props.clientId !== nextProps.clientId) {
      this.fetchAnnotationColorsByClientId(nextProps.clientId)
    } else if (nextProps.godAdminMode) {
    }
  }

  fetchAnnotationColorsByClientId(clientId) {
    getAnnotationColorsCategoryByClientId(clientId).then(({ data }) => {
      this.setState({
        annotations: data,
        count: data.length
      })
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
          console.log(error)
      }
    })
  }

  updateParentAnnotations = (newData) => {
    const list = this.state.annotations
    list.splice(0, 0, newData)
    this.setState({
      annotations: list
    })
  }

  removeAnnotation = (id) => {
    let position = 0
    const list = this.state.annotations
    for (let i=0; i<list.length; i++) {
      if (list[i].id === id) {
        position = i
      }
    }
    list.splice(position, 1)
    this.setState({
      annotations: list
    })
  }

  pickerHandleOpen = () => {
    this.setState({ displayColorPicker: true })
  }

  pickerHandleClose = () => {
    this.setState({ displayColorPicker: false, lastSelectedColor: '' })
  }

  pickerHandleCloseAndSave = () => {
    const temp = this.state.lastSelectedColor
    this.setState({
      newColor: this.convertRgbaToString(temp),
      displayColorPicker: false,
      lastSelectedColor: ''
    })
  }

  pickerHandleChange = (color) => {
    this.setState({ lastSelectedColor: color.rgb })
  }

  convertRgbaToString = ({ r, g, b, a }) => {
    return `rgba(${ r }, ${ g }, ${ b }, ${ a })`
  }

  updateNewCategory(event) {
    this.setState({ newCategory: event.target.value })
  }

  save =() => {
    const data= {
      color: this.state.newColor,
      defaultColor: 1,
      id: 0,
      tag: this.state.newCategory
    }
    const valid = this.frontValidation(data)
    if (valid) {
      saveAnnotationColor(data).then((response) => {
        this.updateParentAnnotations(response.data)
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
            console.log(error)
        }
      })
      this.setState({
        newColor: '#ddd',
        lastSelectedColor: '',
        newText: '',
      })
    }
  }

  toggleAddAnnotation = () => {
    this.setState({ 'showAddColorDiv': !this.state.showAddColorDiv })
  }

  frontValidation = (data) => {
    let response = true
    const { annotations } = this.state
    if (! data.tag) {
      CustomNotificationManager.warning('Annotation category should not be empty', 'Empty field')
      response = false
    } else {
      if (!data.tag.trim()) {
        CustomNotificationManager.warning('Annotation category should not be empty', 'Empty field')
        response = false
      }
      if (data.tag.includes(' ')) {
        CustomNotificationManager.warning('Spaces not allowed in annotation category names', 'Spaces not allowed')
        response = false
      }
      for (let i = 0; i < annotations.length; i++) {
        if (data.tag.trim() === annotations[i].tag) {
          CustomNotificationManager.warning('This annotation category already exist. Try another name', 'Already exist')
          response = false
        }
      }
    }
    return response
  }

  render() {
    const { annotations, displayColorPicker, newColor, showAddColorDiv } = this.state
    const classShowAddColorDiv = showAddColorDiv ? '' : 'hidden'
    return (
      <div className="page-container page-content-annotation-color">
        {this.props.godAdminMode &&
          <div className="page-filter">
            <div className="page-filter__header--button-container">
              <button disabled="true" className="button-newBusiness" onClick={ this.toggleAddAnnotation }>+ Add Annotation Color</button>
            </div>
          </div>
        }
        <div className={`page-add mb-4 ${ classShowAddColorDiv }`}>
          <div className="page-add__header">
            <div className="col-md-10">
              <p className="page-add__header__title pt-3"> Annotation Category </p>
            </div>
            <div className="col-md-1">
              <p className="page-add__header__title pt-3 text-center"> Color </p>
            </div>
            <div className="col-md-1">
              <p className="page-add__header__title pt-3"> Action </p>
            </div>
          </div>
          <div className="page-add__form">
            <div className="col-md-10 page-add__form__category">
              <input type="text" className="page-add__form__category__input" onChange={ (e) => this.updateNewCategory(e) }/>
            </div>
            <div className="col-md-1 page-add__form__color">
              <div className="color_input" onClick={ () => this.pickerHandleOpen() } >
                <div className="color_input__value" style={{ backgroundColor: newColor }} />
              </div>
              { displayColorPicker ? <div className = "color_picker_popup">
                <div className = "color_picker_popup__cover" onClick={ () => this.pickerHandleClose() }/>
                <CompactPicker
                  colors = {['rgba(107, 91, 149, 0.3)', 'rgba(254, 178, 54, 0.3)', 'rgba(214, 65, 97, 0.3)',
                    'rgba(255, 123, 37, 0.3)', 'rgba(75, 195, 193, 0.3)', 'rgba(194, 194, 71, 0.3)', 'rgba(181, 231, 160, 0.3)',
                    'rgba(191, 108, 64, 0.3)', 'rgba(62, 68, 68, 0.3)', 'rgba(130, 183, 75, 0.3)', 'rgba(64, 93, 39, 0.3)',
                    'rgba(193, 148, 106, 0.3)', 'rgba(60, 136, 180, 0.3)', 'rgba(200, 100, 91, 0.3)', 'rgba(238, 162, 154, 0.3)',
                    'rgba(201, 76, 76, 0.3)', 'rgba(124, 108, 6, 0.3)', 'rgba(15, 195, 71, 0.3)', 'rgba(231, 82, 114, 0.3)',
                    'rgba(238, 140, 54, 0.3)', 'rgba(92, 212, 209, 0.3)', 'rgba(211, 211, 88, 0.3)', 'rgba(198, 248, 177, 0.3)',
                    'rgba(192, 125, 81, 0.3)' ]}
                  disableAlpha= {false}
                  color={ newColor }
                  onSwatchHover ={ this.pickerHandleChange }
                  onChange = { () => this.pickerHandleCloseAndSave() }
                />
              </div> : null }
            </div>
            <div className="col-md-1 page-add__form__button">
              <button className="page-add__form__button__new" onClick={ this.save }>Add</button>
            </div>
          </div>
        </div>
        <div className="page-content">
          <AnnotationColorTable annotations={ annotations } updateParentAnnotations={ this.updateParentAnnotations } removeAnnotation={ this.removeAnnotation } />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription ? state.subscriptionReducer.subscription.subscription.client.id : null,
  godAdminMode: state.admin.godAdminMode
})

export default connect(mapStateToProps)(AnnotationColor)