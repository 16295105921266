import React from "react";
import './ConnectorsPage.scss';
import ConnectorsTable from "./ConnectorsTable";
import withRouter from "../private-routers/withRouter";
import {apiAuthenticateConnector} from "../api/SmartBox";
import cookie from "react-cookies";
import {notification} from "antd";
import CustomNotificationManager from "./Common/CustomNotificationManager";

class ConnectorsPage extends React.Component {
    componentDidMount() {
        if (this.props.location.search) {
            const locationCode = this.props.location.search

            const startIndex = locationCode.indexOf('code=') + 5
            const startIndexState = locationCode.indexOf('&state=')
            const codeFinally = locationCode.substr(startIndex, startIndexState - startIndex)

            const startIndexClientId = locationCode.indexOf('clientIdMs') + 10
            const startIndexStateSessionStatus = locationCode.indexOf('connectorId')
            const clientIdFinally = locationCode.substr(startIndexClientId, startIndexStateSessionStatus - startIndexClientId)

            const startIndexConnectId = locationCode.indexOf('connectorId') + 11
            const startIndexStateConnectIdStatus = locationCode.indexOf('&session')
            const connectIdFinally = locationCode.substr(startIndexConnectId, startIndexStateConnectIdStatus - startIndexConnectId)

            const valueConnect = cookie.load('connectMicrosoft')
            const redirectUri = window.location.origin + `/data-mapping/connectors`
            const connectorsConnectDTO = {
                clientIdMs: clientIdFinally,
                authorizationCode: codeFinally,
                connectorId: connectIdFinally,
                redirectUri: redirectUri,
            }
            apiAuthenticateConnector(connectorsConnectDTO).then((response) => {
                    if (response.data && response.data === 'CONNECTED') {
                        notification.success({message: 'Connected!', duration: 6})
                    }
                if (response.data && response.data === 'CONNECTION_FAILED') {
                    notification.info({message: 'CONNECTION FAILED!', duration: 6})
                }
                    cookie.remove('connectMicrosoft', { path: '/' })
                    //window.history.replaceState(null, null, `/smart-box/data-mapping/connectors`);
                    window.history.pushState(null, null, '/smart-box/data-mapping/connectors')
            }).catch((error) => {
                notification.info({message: 'CONNECTION FAILED!', duration: 6})
                window.history.pushState(null, null, '/smart-box/data-mapping/connectors')
                cookie.remove('connectMicrosoft', { path: '/' })
            })
        } else {
            cookie.remove('connectMicrosoft', { path: '/' })
        }
    }

    render(){
        return (
            <div className="connectors-page">
            <div className="connectors-page__content">
                <div className="connectors-header">
                    <span className="title-header">Data Connectors</span>
                </div>
                <div className="content-connectors-page">
                    <ConnectorsTable folder={this.props.folder} locationSearch={ this.props.location.search }/>
                </div>
            </div>
            </div>
        )
    }
}
ConnectorsPage = withRouter(ConnectorsPage)
export default ConnectorsPage;