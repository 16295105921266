import React from 'react'
import { Button, notification, Radio, Tooltip } from 'antd'
import '../FolderRedaction.scss'
import SearchTree from '../SearchTree'
import {
  apiSmartBoxCheckBulkFolderStatus,
  getFolderPath,
  getFolderSettingsRedactingTree,
  clearAllBulk,
  detailedTreeSettingsAll,
  getOptionSelected,
  getFolderSettingsRedactingTreeImages,
  checkFolderSettingTree,
  checkDetailedTreeSettingsAll
} from '../../../../../api/SmartBox'
import {
  removeSmartBoxIntervalForBulkRedaction,
  setSmartBoxIntervalForBulkRedaction,
  setSmartBoxFolderHasBulk, setSmartBoxNotFolderRedactionComplete
} from '../../../../../actions/smartBoxActions'
import { connect } from 'react-redux'
import { addIntervalToApplication } from '../../../../../actions/applicationsActions'
import WrapperNotification from '../../WrapperNotification'
import CustomNotificationManager from '../../../../Common/CustomNotificationManager'
import moment from "moment";
import withRouter from '../../../../../private-routers/withRouter'

class FolderRedaction extends React.Component {
  state = {
    redactionMode: '',
    redactButtonDisabled: false,
    annotationsToRedact: [],
    allRedactionTags: [],
    isIntervalStartedForBulkRedaction: false,
    currentLocation: this.props.location,
    terms: [],
    newTerms: [],
    selectedRedact: '',
    allKeysTerms: [],
    checkedKeys: [],
    checkedKeysWithNephews: [],
    treeDataAnalytic: [],
    newCheckIdManualTerm: [],
    redactingStep: 0,
    inBulk: false,
    treeData: [],
    nephewData: [],
    allNephew: [],
    allKeysWithoutChildrens: [],
    treeKeysLevelStructure: [],
    newBulkProcessedStarted: '',
    allNephewKeyList: [],
    emptyTree: false,
    nameLocationCallTree: 'Folder Redaction',
    submitedSelectedRedact: 1,
    treeDataImages: {},
    treeAllData: [],
    actionExpandParent: '',
    responseDataTree: '',
    setIntervalCheckTree: 0,
    responseCheckTree: '',
    setIntervalDetailsCheckTree: 0,
    responseDetailsCheckTree: '',
    disabledCreateFirstRedactions: ''
  }
  
  componentDidMount() {
    this.getOptionSelected()
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.folder !== this.props.folder && this.props.folder && prevProps.folder) {
      this.getOptionSelected()
      if (this.state.redactionMode !== 'clear-all') {
        this.startInterval()
      }
      //this.getCheckTreeForSettings()
      //this.getCheckedRedactions()
    }
  }

  getSettingsImagesRedaction = () => {
    const { nameLocationCallTree } = this.state
    let { treeData } = this.state
    treeData = treeData && treeData.length> 0 && treeData.slice(0, treeData.length - 1)
    const treeSettingInputDTO = {
      path: `${ this.props.folder.folderPath }${ this.props.folder.name }`,
      page: nameLocationCallTree
    }
    getFolderSettingsRedactingTreeImages(treeSettingInputDTO).then((response) => {
      if (response.status === 200) {
        treeData = treeData && treeData.length > 0 && treeData.concat(response.data)
        this.setState({
          treeDataImages: response.data,
          treeData
        })
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  getOptionSelected = () => {
    if (this.props.folder.id)
      getOptionSelected(this.props.folder.id).then((response) => {
        if (response.status === 200) {
          let submitedSelectedRedact = 1
          let redactionMode = 'redact-selected'
          let selectedRedact = true
          if (response.data) {
            switch (response.data) {
              case 'r_a':
                redactionMode = 'redact-all'
                selectedRedact = null
                submitedSelectedRedact = 3
                break
              case 'o_t':
                redactionMode = 'redact-selected'
                selectedRedact = true
                submitedSelectedRedact = 1
                break
              case 'o_f':
                redactionMode = 'redact-apart'
                selectedRedact = false
                submitedSelectedRedact = 2
                break
              case 'c_a':
                redactionMode = 'clear-all'
                selectedRedact = null
                submitedSelectedRedact = 3
                break
              default: selectedRedact = null
            }
            this.setState({
              redactionMode: redactionMode,
              selectedRedact,
              submitedSelectedRedact: submitedSelectedRedact
            }, () => {
              if (this.state.redactionMode !== 'clear-all') {
                this.startInterval()
              }
            })
          } else {
            this.setState({ redactionMode, selectedRedact, submitedSelectedRedact: 1 }, () => {
              if (this.state.redactionMode !== 'clear-all') {
                this.startInterval()
              }
            })
          }
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
  }

  getCheckTreeForSettings = () => {
    const { nameLocationCallTree } = this.state
    let { setIntervalCheckTree } = this.state
    const data = {
      path: `${ this.props.folder.folderPath }${ this.props.folder.name }`,
      page: nameLocationCallTree
    }
    checkFolderSettingTree(data).then((response) => {
      if (response.status === 200) {
        this.setState({
          responseCheckTree: response.data
        })
        if (response.data === 'Processing!') {
          if (setIntervalCheckTree === 0) {
            CustomNotificationManager.info('Please wait! Report generation could take some time...')
            setIntervalCheckTree = setInterval(this.setIntervalCheckTreeForSettings, 4000)
            this.setState({setIntervalCheckTree})
          }
        } else if (response.data === 'Processing in progress, this may take several minutes.') {
          CustomNotificationManager.info('Data is being processed, please check this page later.')
        } else if (response.data === 'Finished!') {
          this.getCheckedRedactions()
          clearInterval(setIntervalCheckTree)
          this.setState({setIntervalCheckTree})
        }
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  setIntervalCheckTreeForSettings = () => {
    const {nameLocationCallTree, setIntervalCheckTree, responseCheckTree} = this.state
    const data = {
      path: `${ this.props.folder.folderPath }${ this.props.folder.name }`,
      page: nameLocationCallTree
    }
    if (responseCheckTree === 'Processing!') {
      checkFolderSettingTree(data).then((response) => {
        if (response.status === 200) {
          this.setState({
            responseCheckTree: response.data
          })
          if (response.data === 'Processing!') {
            //this.getFolderSettingsRedactingTree()
            this.setState({setIntervalCheckTree})
          } else if (response.data === 'Processing in progress, this may take several minutes.') {
            CustomNotificationManager.info('Data is being processed, please check this page later.')
          } else if (response.data === 'Finished!') {
            this.getCheckedRedactions()
            this.setState({setIntervalCheckTree})
            clearInterval(setIntervalCheckTree)
          }
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    }
  }

  getCheckedRedactions = () => {
    let { treeData, nephewData } = this.state
    const { nameLocationCallTree } = this.state
    const data = {
      path: `${ this.props.folder.folderPath }${ this.props.folder.name }`,
      page: nameLocationCallTree
    }
    let checkedKeys = []
    getFolderSettingsRedactingTree(data).then((res) => {
      if (res.status === 200) {
        const detailError= 'Processing in progress, this may take several minutes.'
        if (res.data === detailError) {
          //CustomNotificationManager.info('Processing in progress, this may take several minutes.')
          this.setState({
            responseDataTree: res.data
          })
        }
        this.setState({
          disabledCreateFirstRedactions: res.data
        })
        if (res.data === 'Analytics Report is underway! This may take several minutes!') {
          CustomNotificationManager.info('Analytics Report is underway! This may take several minutes!')
        }
        treeData = res.data
        if (! treeData.length) {
          this.setState({ emptyTree: true })
        }
        const checkKeyParents = []
        res.data.forEach((treeChild) => {
          this.getAllKeysWhitoutNephews(res.data)
          treeChild.children && treeChild.children.map((children) => {
            if (children.selected === true) {
              checkKeyParents.push(children.key)
            }
            children && children.children.map((child) => {
              if (child.selected === true) {
                checkKeyParents.push(child.key)
              }
              child && child.children && child.children.length > 0 && child.children.map((nephew) => {
                if (nephew.selected === true) {
                  checkKeyParents.push(`${ nephew.categoryParent }_${ nephew.key }`)
                }
                return true
              })
              return true
            })
            return true
          })
        })
        checkedKeys = checkedKeys.concat(checkKeyParents)
        //call for nephew
        this.setState({
          treeData,
          nephewData,
          checkedKeys
        }, () => {
          //this.checkDetailTreeForSettings()
          this.detailTreeSettingsAll()
        })
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  checkDetailTreeForSettings = (checkedKeys) => {
    let { setIntervalDetailsCheckTree } = this.state
    checkDetailedTreeSettingsAll({ folderPath: this.props.folder.path }).then((response) => {
      this.setState({
        responseDetailsCheckTree: response.data,
        disabledCreateFirstRedactions: response.data
      })
      if (response.data === 'Processing!') {
        if (setIntervalDetailsCheckTree === 0) {
          CustomNotificationManager.info('Please wait! Report generation could take some time...')

          setIntervalDetailsCheckTree = setInterval(this.setIntervalDetailsCheckTreeForSettings, 4000)
          this.setState({setIntervalDetailsCheckTree})
        }
      } else if (response.data === 'Finished!') {
        this.detailTreeSettingsAll()
        clearInterval(setIntervalDetailsCheckTree)
        this.setState({setIntervalDetailsCheckTree})
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  setIntervalDetailsCheckTreeForSettings = () => {
    let { setIntervalDetailsCheckTree, responseDetailsCheckTree } = this.state
    if (responseDetailsCheckTree === 'Processing!') {
      checkDetailedTreeSettingsAll({folderPath: this.props.folder.path}).then((response) => {
        this.setState({
          responseDetailsCheckTree: response.data,
          disabledCreateFirstRedactions: response.data
        })
        if (response.data === 'Processing!') {
          this.setState({setIntervalDetailsCheckTree})
        } else if (response.data === 'Finished!') {
          this.detailTreeSettingsAll()
          clearInterval(setIntervalDetailsCheckTree)
          this.setState({setIntervalDetailsCheckTree})
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    }
  }
  detailTreeSettingsAll = () => {
    let { treeData, nephewData } = this.state
    const checkedKeysWithNephews = []
    let { checkedKeys } = this.state
    detailedTreeSettingsAll({ folderPath: this.props.folder.path }).then((response) => {

      if (response.status === 200) {
       if (response.data === 'Processing!') {
         CustomNotificationManager.info('Please wait! Report generation could take some time...')
        }
        const checkedNephews = []
        nephewData = response.data
        const treeAllDataList = []
        for (const item in response.data) {
          treeAllDataList.push({
            nameCategory: item,
            childrenCategory: response.data[item]
          })
          treeAllDataList && treeAllDataList.length > 0 && treeAllDataList.forEach((item) => {
            item.childrenCategory && item.childrenCategory.length > 0 && item.childrenCategory.forEach((item2) => {
              item2.keyCategory = `${ item.nameCategory }_${ item2.text }`
            })
          })
          if (response.data[item] && response.data[item].length) {
            response.data[item].forEach((value) => {
              if (value.selected) {
                checkedNephews.push(`${ item }_${ value.text }`)
              }
            })
          }
        }
        checkedKeys = checkedKeys.concat(checkedNephews)
        if (checkedKeys.length) {
          this.setState({
            redactingStep: 1,
            checkedKeys,
            checkedKeysWithNephews
          })
        }
        //create key nephew list
        const allNephewKeyList = []
        for (const index in nephewData) {
          if (nephewData[index].length) {
            nephewData[index].forEach((item) => {
              allNephewKeyList.push(`${ index }_${ item.text }`)
            })
          }
        }
        this.setState({ treeData, nephewData, allNephewKeyList, treeAllData: treeAllDataList }, () => {
          this.createTreeKeysLevelStructure()
          //this.getSettingsImagesRedaction()
        })
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  createTreeKeysLevelStructure = () => {
    const { treeData } = this.state
    const response = []
    if (treeData && treeData.length) {
      treeData.forEach((grandParent) => {
        response.push({ [grandParent.key]: 1 })
        if (grandParent.children && grandParent.children.length) {
          grandParent.children.forEach((parent) => {
            response.push({ [parent.key]: 2 })
            if (parent.children && parent.children.length) {
              parent.children.forEach((children) => {
                response.push({ [children.key]: 3 })
              })
            }
          })
        }
      })
    }
    this.setState({ 'treeKeysLevelStructure': response })
  }

  getAllKeysWhitoutNephews = (treeData) => {
    const allKeysWithoutChildrens = []
    if (treeData && treeData.length) {
      treeData.forEach((grandParent) => {
        allKeysWithoutChildrens.push(grandParent.key)
        if (grandParent.children && grandParent.children.length) {
          grandParent.children.forEach((parent) => {
            allKeysWithoutChildrens.push(parent.key)
            if (parent.children && parent.children.length) {
              parent.children.forEach((children) => {
                allKeysWithoutChildrens.push(children.key)
              })
            }
          })
        }
      })
    }
    this.setState({ allKeysWithoutChildrens })
  }

  setTreeData = (e, data) => {
    e.stopPropagation()
    this.setState({ treeData: data, currentActiveParent: data.key, isStatisticsPressed: true })
  }

  viewChildren = (children, allKeysTerms) => {
    this.setState({
      terms: children,
      allKeysTerms: allKeysTerms
    })
  }

  viewTreeDataAnalytic = (treeDataAnalytic) => {
    this.setState({
      treeDataAnalytic: treeDataAnalytic
    })
  }

  startInterval = () => {
    const { redactingStep, newBulkProcessedStarted } = this.state
    let { inBulk } = this.state
    if (this.props.folder && redactingStep) {
      let redactButtonDisabled = true
      if (redactingStep === 2) {
        redactButtonDisabled = false
        inBulk= false
      }
      const { isIntervalStartedForBulkRedaction, currentLocation } = this.state
      const { folder } = this.props
      if (folder.bulkProcessingStarted === null) {
        folder.bulkProcessingStarted = newBulkProcessedStarted
      }
      if ((folder.bulkProcessingStarted === 'started' || folder.bulkProcessingStarted === 'finished') && !isIntervalStartedForBulkRedaction && inBulk) {
        this.setState({
          redactButtonDisabled: redactButtonDisabled,
          isIntervalStartedForBulkRedaction: true,
        }, () => {
          this.props.setSmartBoxIntervalForBulkRedaction(currentLocation)
          const bulkReadyInterval = setInterval(() => {
            apiSmartBoxCheckBulkFolderStatus(folder.id).then((res) => {
              const message = <span>{'Bulk redaction is ready for folder '}<span
                style={ { textDecoration: 'underline', cursor: 'pointer' } }><span
                  onClick={ () => this.props.navigate(`/smart-box/folder/${ folder.id }`) }>{ folder.name }</span></span></span>
              if (res.data) {
                this.props.setSmartBoxFolderHasBulk(res.data)
                notification.success({ message: message })
                this.props.fetchFolderInfo()
                let reactionComplete = []
                const notRedactionComplete = [{
                  nameActionEvent: 'Bulk redaction complete',
                  descriptionActionEvent: `${ folder.name }`,
                  timesEvent: moment().format('DD/MM/YYYY HH:mm:ss'),
                  status: 'box-notification',
                  nameBox: folder.name
                }]
                reactionComplete = notRedactionComplete.concat(this.props.smartBoxNotRedactComplete)
                const uniqueFiles = reactionComplete && reactionComplete.length > 0 && reactionComplete.filter((a, i) => reactionComplete.findIndex((s) => a.nameBox === s.nameBox) === i)
                this.props.setSmartBoxNotFolderRedactionComplete(uniqueFiles)
                const notifierTitle = 'Bulk redaction is ready'
                const notifierText = `Bulk redaction is ready for folder ${ folder.name }`
                const notifierUrl = `${ window.location.origin }/smart-box/folder/${ folder.id }`
                this.refWrapperNotification.showNotification(notifierTitle, notifierText, notifierUrl)
                inBulk= false
                this.props.removeSmartBoxIntervalForBulkRedaction(this.props.intervalForBulkRedaction.filter((path) => path !== currentLocation))
                this.setState({
                  redactButtonDisabled: false,
                  isIntervalStartedForBulkRedaction: false,
                  inBulk: false
                }, () => this.props.fetchFolderInfo())
                clearInterval(bulkReadyInterval)
              }
            }).catch((error) => {
              this.setState({
                redactButtonDisabled: false,
              })
              if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
              }else{
                console.log(error)
              }
              clearInterval(bulkReadyInterval) })
          }, 10000)
          this.props.addIntervalToApplication(bulkReadyInterval)
        })
      }
    }
  }

  setAllNephew = (list) => this.setState({ allNephew: list })
  
  onChange = (e) => {
    let { checkedKeys, redactButtonDisabled } = this.state
    let valueSelect
    switch (e.target.value) {
      case 'redact-selected':
        valueSelect = true
        break
      case 'redact-apart':
        valueSelect = false
        break
      case 'clear-all':
        checkedKeys= []
        redactButtonDisabled = false
        break
      default:
        valueSelect = null
    }

    this.setState({
      redactionMode: e.target.value,
      selectedRedact: valueSelect,
      checkedKeys,
      redactButtonDisabled
    })
  };

  removeDuplicatedKeys = (list) => {
    const result = []
    if (list && list.length) {
      list.forEach((item) => {
        if (!result.includes(item)) {
          result.push(item)
        }
      })
    }
    return result
  }

  onClickSendPath = () => {
    const { annotationsToRedact, allRedactionTags, redactionMode, allKeysTerms, terms, treeDataAnalytic, allKeysWithoutChildrens, allNephewKeyList, treeDataImages, treeAllData, actionExpandParent } = { ...this.state }
    let { selectedRedact, checkedKeys, redactingStep, inBulk } = this.state
    const redactButtonDisabled = redactionMode === 'clear-all' ? false : true
    if (redactingStep !==1) {
      inBulk = true
    }
    redactingStep = (redactingStep + 1 > 2) ? (checkedKeys.length || redactionMode ==='clear-all' ? 1 : 0) : redactingStep + 1
    if (redactionMode ==='clear-all') {
      redactingStep = 0
    }
    this.setState({
      redactButtonDisabled: redactButtonDisabled,
      redactingStep,
      inBulk
    }, () => {
      if (redactingStep !== 2) {
        let finalAnnotationsToRedact = []
        let finalChildrenRedaction = []
        const checkManualTerm = []
        let newCheckManualTerm = []
        switch (redactionMode) {
          case 'redact-all':
            finalAnnotationsToRedact = allRedactionTags
            checkedKeys = allKeysWithoutChildrens
            break
          case 'redact-selected':
            allRedactionTags && allRedactionTags.map((check) => {
              annotationsToRedact.map((red) => {
                if (check === red) {
                  finalAnnotationsToRedact.push(red)
                }
                return true
              })
              return true
            })
            allKeysTerms && terms && allKeysTerms.map((check) => {
              terms.map((red) => {
                if (check === red) {
                  finalChildrenRedaction.push(red)
                }
                return true
              })
              return true
            })
            treeDataAnalytic && treeDataAnalytic.map((treeData) => {
              if (treeData.title === 'Manual term') {
                treeData.children && treeData.children.map((term) => {
                  checkManualTerm.push(term.key)
                  return true
                })
                return true
              }
              return true
            })
            newCheckManualTerm = checkManualTerm.filter((checkTerm) => terms.map((newTerm) => newTerm).includes(checkTerm))
            selectedRedact = true
            break
          case 'redact-apart':
            finalAnnotationsToRedact = allRedactionTags.filter((redChild) => !terms.map((red) => red).includes(redChild))
            finalChildrenRedaction = allKeysTerms.filter((redChild) => !terms.map((red) => red).includes(redChild))
            selectedRedact = false
            break
          default:
            break
        }

        //transform key "doc_annotate_person" to "Doc_annotate_person"
        if (checkedKeys && checkedKeys.length) {
          checkedKeys.forEach((item, index) => {
            if (item === 'doc_annotate_person') {
              checkedKeys.splice(index, 1, 'doc_annotate_person')
            }
          })
        }

        finalChildrenRedaction = this.removeDuplicatedKeys(finalChildrenRedaction)
        const avoidTerms = ['male', 'female']
        let newTerms = []
        let newCheckedKeys = []
        if (checkedKeys && checkedKeys.length) {
          checkedKeys.forEach((item, index) => {
            newCheckedKeys.push(item)
            if (item.includes('_')) {
              const temp = item.split('_')
              const sufix = temp[temp.length - 1]
              if (!newTerms.includes(sufix) && !avoidTerms.includes(sufix)) {
                newTerms.push(sufix)
              }
            }
          })
        }
        newCheckedKeys = this.removeDuplicatedKeys(newCheckedKeys)
        let allKeys = allKeysWithoutChildrens.concat(allNephewKeyList)
        allKeys = this.removeDuplicatedKeys(allKeys)
        const uncheckedKeys = []
        allKeys.forEach((item) => {
          let condition= true
          newCheckedKeys.forEach((value) => {
            if (item.includes(value)) {
              condition= false
            }
            if (value === 'numbers' && (item.includes('doc_annotate_percent') || item.includes('doc_annotate_money'))) {
              condition= false
            }
            if (value === 'time' && item.includes('doc_annotate_date')) {
              condition= false
            }
            if (value === 'date' && item.includes('date')) {
              condition= false
            }
          })
          if (!newCheckedKeys.includes(item) && condition) {
            uncheckedKeys.push(item)
          }
        })
        // let checkImageReady = false
        // if (Object.keys(treeDataImages).length === 0) {
        //   checkImageReady = false
        // } else {
        //   checkImageReady = true
        // }
        let submitedSelectedRedact = ''
        switch (selectedRedact) {
          case true: submitedSelectedRedact = 1; break
          case false: submitedSelectedRedact = 2; break
          default: submitedSelectedRedact = 3
        }
        if (Object.keys(treeDataImages).length === 0) {
          checkedKeys = checkedKeys.filter((checkKeys) => checkKeys !== 'image-category')
          newCheckedKeys = newCheckedKeys.filter((checkKeys) => checkKeys !== 'image-category')
        }
        const pushNewChild = []
        if (actionExpandParent === 'add' && redactionMode === 'redact-selected') {
          treeAllData && treeAllData.length > 0 && treeAllData.forEach((item) => {
            item && item.childrenCategory && item.childrenCategory.length > 0 && item.childrenCategory.forEach((child) => {
              if (checkedKeys && checkedKeys.length > 0 && checkedKeys.includes(item.nameCategory)) {
                pushNewChild.push(child.text)
                let updateNewTerms = []
                if (newTerms && newTerms.length > 0 && !newTerms.includes(child.text)) {
                  updateNewTerms.push(child.text)
                  newTerms = newTerms.concat(updateNewTerms)
                }
                const checkedKeysCategory = []
                if (checkedKeys && checkedKeys.length > 0 && !checkedKeys.includes(child.keyCategory)) {
                  checkedKeysCategory.push(child.keyCategory)
                  checkedKeys = checkedKeys.concat(checkedKeysCategory)
                }
                const newCheckedKeysCategory = []
                if (newCheckedKeys && newCheckedKeys.length > 0 && !newCheckedKeys.includes(child.keyCategory)) {
                  newCheckedKeysCategory.push(child.keyCategory)
                  newCheckedKeys = newCheckedKeys.concat(newCheckedKeysCategory)
                }
              }
            })
          })
        }
        let imageMode = false
        let checkedKeysImage = false
        checkedKeys && checkedKeys.length > 0 && checkedKeys.forEach((key2) => {
          if(key2.includes('Image')) {
            checkedKeysImage = true
          }
        })
        if (redactionMode === 'redact-selected' && checkedKeysImage === true) {
          imageMode = true
        } else if (redactionMode === 'redact-apart' && checkedKeysImage === true) {
          imageMode = false
        } else if (redactionMode === 'redact-apart' && checkedKeysImage === false) {
          imageMode = true
        } else if (redactionMode === 'redact-all') {
          imageMode = true
        } else {
          imageMode = false
        }
        const data = {
          path: `${ this.props.folder.folderPath }${ this.props.folder.name }`,
          annotations: checkedKeys,
          terms: newTerms,
          selectedRedact: selectedRedact,
          submitedSelectedRedact: submitedSelectedRedact,
          checkedKeys: newCheckedKeys,
          uncheckedKeys: uncheckedKeys,
          imagesReady: imageMode
        }

        if (redactionMode === 'clear-all') {
          clearAllBulk(this.props.folder.id).then((response) => {
            this.setState({ checkedKeys: [] }, () => {
              this.props.fetchFolderInfo()
            })
          }).catch((error) => {
            if (error?.response?.status === 504) {
              CustomNotificationManager.cleanBuffer('warning', '')
            }else{
              console.log(error)
            }
          })
        } else {
          getFolderPath(data).then((response) => {
            let bulkProcessed = ''
            bulkProcessed = response.data
            if (bulkProcessed) {
              if (bulkProcessed.toLowerCase() === 'started') {
                CustomNotificationManager.info('Bulk processing started!')
                this.setState({
                  newBulkProcessedStarted: bulkProcessed
                })
              } else if (bulkProcessed.toLowerCase() === 'failed') {
                CustomNotificationManager.error('Please wait until data is analyzed and retry later.')
                this.setState({
                  redactingStep: 0,
                  redactButtonDisabled: false
                })
              }
            }
            this.startInterval()
          }).catch((error) => {
            if (error?.response?.status === 504) {
              CustomNotificationManager.cleanBuffer('warning', '')
            }else{
              console.log(error)
            }
          })
        }

        this.setState({
          newCheckIdManualTerm: newCheckManualTerm
        })

      } else if (redactingStep === 2) {
        this.setState({ redactButtonDisabled: false })
      }
    })
  }
  setActionExpandParent = (action) => {
     this.setState({
       actionExpandParent: action
     })
  }
  sendSaveKeys = (checkedKeys) => {
    this.setState({
      checkedKeys: checkedKeys
    })
  }

  setAnnotationsForRedact = (annotationsToRedact) => this.setState({ annotationsToRedact })
  
  setAllTags = (allRedactionTags) => this.setState({ allRedactionTags })

  setRefWrapperNotification(callbacks) {
    this.refWrapperNotification = callbacks
  }

  getRedactButtonText = () => {
    const { redactingStep } = this.state
    let redactButtonText = 'create first redactions'
    if (redactingStep === 1) {
      redactButtonText = 'update redactions'
    } else if (redactingStep === 2) {
      redactButtonText = 'confirm update redactions'
    }
    return redactButtonText
  }

  render() {
    const { redactionMode, redactButtonDisabled, treeData, redactingStep, nephewData, selectedRedact, submitedSelectedRedact, emptyTree, treeDataImages, responseDataTree, disabledCreateFirstRedactions } = { ...this.state }
    const { folder } = this.props
    const redactButtonText = this.getRedactButtonText()
    if (folder && Object.keys(folder).length > 0) {
      return (
        <div className="folder-redaction-page folder-redaction-new-page">
          <WrapperNotification onMounted={ (callbacks) => this.setRefWrapperNotification(callbacks)}/>
          { !emptyTree > 0 &&
            <React.Fragment>
              <Tooltip overlayClassName="help-message-locations" title={<div className="content-help-message-locations">
                <span className="text-information">Select items for bulk redaction.</span>
                <span className="more-info-link" onClick={ () => window.open('https://support.smartbox.ai/support/solutions/articles/47001174231', '_blank') }> More info. </span>
              </div>}>
                <span className="icon-v14-question"></span>
              </Tooltip>
              <div className="content-header-folder-redaction">
                <div className={ redactingStep ? 'folder-redaction-page__button-green redaction-button' : 'folder-redaction-page__button redaction-button'}>
                  <Button
                    title = { folder.bulkProcessingStarted === 'started' ? 'Wait for bulk process' : redactButtonText }
                    disabled={disabledCreateFirstRedactions === 'Analytics Report is underway! This may take several minutes!' || disabledCreateFirstRedactions === 'Processing!' || responseDataTree === 'Processing in progress, this may take several minutes.' || !redactionMode || redactButtonDisabled || folder.bulkProcessingStarted === 'started'}
                    onClick={ this.onClickSendPath }
                  >
                    { redactButtonText }
                  </Button>
                </div>
                <div className="folder-redaction-page__radio-buttons new-radio-buttons">
                  <Radio.Group onChange={this.onChange} value={redactionMode}>
                    <Radio value="redact-all" disabled={redactingStep === 1}>Redact
                      all</Radio>
                    <Radio value="redact-selected" disabled={redactingStep === 1}>Redact
                      selected</Radio>
                    <Radio value="redact-apart" disabled={redactingStep === 1}>Redact
                      everything apart from selected</Radio>
                    <Radio value="clear-all" disabled={redactingStep === 0 || redactingStep === 1}>Clear all redactions</Radio>
                  </Radio.Group>
                </div>
              </div>
              <div className="folder-redaction-page__tree folder-redaction-page__tree-redaction">
                <SearchTree
                  tab="folder-redaction"
                  folder={this.props.folder}
                  setTreeData={this.setTreeData}
                  setAnnotationsForRedact={this.setAnnotationsForRedact}
                  setAllTags={this.setAllTags}
                  viewChildren={this.viewChildren}
                  sendSaveKeys={this.sendSaveKeys}
                  viewTreeDataAnalytic={this.viewTreeDataAnalytic}
                  redactingStep={redactingStep}
                  treeData={treeData}
                  nephewData={nephewData}
                  setAllNephew={this.setAllNephew}
                  redactionMode={redactionMode}
                  selectedRedact={selectedRedact}
                  submitedSelectedRedact={submitedSelectedRedact}
                  treeDataImages={ treeDataImages }
                  setActionExpandParent={ this.setActionExpandParent }
                />
              </div>
            </React.Fragment>
          }
          { emptyTree &&
            <p className="text-center empty">Data is being processed, please check this page later.</p>
          }
        </div>
      )
    } 
    return null
    
  }
}

const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id,
  intervalForBulkRedaction: state.smartBox.intervalForBulkRedaction,
  smartBoxNotRedactComplete: state.smartBox.smartBoxNotRedactComplete,
})

FolderRedaction = withRouter(FolderRedaction)

export default connect(mapStateToProps, {
  setSmartBoxIntervalForBulkRedaction,
  removeSmartBoxIntervalForBulkRedaction,
  addIntervalToApplication,
  setSmartBoxFolderHasBulk,
  setSmartBoxNotFolderRedactionComplete
})(FolderRedaction)