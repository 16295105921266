import React from 'react'
import { Checkbox, Select } from 'antd'
import PropTypes from 'prop-types'
import {
  generateColumnFieldTypeContent,
  generateSelectCaret
} from '../../../utils/AssetRegister/AssetRegisterHelperMethods'

const { Option } = Select
class AdditionalField extends React.Component {
  state = {
    fieldType: ''
  }

  onColumnTypeSelect = (field, value) => {
    this.setState({
      fieldType: value
    }, () => this.props.onChangeFieldType(field, value))
  }

  render() {
    const { field } = this.props
    const { fieldType } = this.state
    return (
      <div className="item-container" onMouseEnter={ () => this.props.disableDragging() }>
        <input type="text" placeholder="FIELD NAME"
          onChange={ (e) => this.props.onChangeName(field, e.target.value) } defaultValue={ field.name } className="item-container__input"/>
        <div className="custom-select-container">
          <Select placeholder="FIELD TYPE..." onChange={ (value) => this.onColumnTypeSelect(field, value) }
            className="custom-select"
            defaultValue={ field.type ? (field.type === 'NULL' ? undefined : field.type) : undefined }
            disabled={ !!(this.props.updateMode && field.type) }>
            <Option value="TEXT">TEXT</Option>
            <Option value="TEXTAREA">TEXTAREA</Option>
            <Option value="RADIO">ONE CHOICE(radio buttons)</Option>
            <Option value="DROPDOWN">ONE CHOICE(dropdown)</Option>
            <Option value="CHECKBOX">MULTIPLE CHOICES</Option>
            <Option value="FILE">FILE</Option>
            <Option value="DATE">DATE</Option>
            <Option value="NUMBER">NUMBER</Option>
            {
              this.props.application === 'asset-register' && <Option value="ADDRESS_FINDER">ADDRESS FINDER</Option>
            }
            {
              this.props.application === 'asset-register' && <Option value="GEOLOCATION">GEOLOCATION</Option>
            }
          </Select>
          {
            field.type || fieldType ? generateSelectCaret(field.type || fieldType) : ''
          }
          {
            field.type || fieldType ? generateColumnFieldTypeContent(field, field.type || fieldType, this.props) : ''
          }
        </div>
        <Checkbox className="custom-checkbox" checked={ field.required }
          onChange={ (e) => this.props.onChangeIsRequired(field, e.target.checked) }>Required</Checkbox>
        <span className="icon-dragpad" onMouseEnter={ () => this.props.enableDragging() }/>
      </div>
    )
  }

}

AdditionalField.propTypes = {
  field: PropTypes.object.isRequired,
  onChangeName: PropTypes.func.isRequired,
  onChangeFieldType: PropTypes.func.isRequired,
  onChangeIsRequired: PropTypes.func.isRequired,
  onChangeOptions: PropTypes.func.isRequired,
  disableDragging: PropTypes.func.isRequired,
  enableDragging: PropTypes.func.isRequired,
  application: PropTypes.string.isRequired,
  updateMode: PropTypes.bool.isRequired,
}
export default AdditionalField