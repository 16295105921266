import PropTypes from 'prop-types'
import React from 'react'
import SortableTree, {
  addNodeUnderParent,
  removeNodeAtPath,
  changeNodeAtPath,
  find,
  insertNode,
  getNodeAtPath,
  walk
} from 'react-sortable-tree'
import 'react-sortable-tree/style.css'
import './styleLeftNavigation.scss'
import { Input, Menu, notification, Tooltip, Spin } from 'antd'
import {
  apiSmartBoxCheckBulkFolderFinalise,
  apiSmartBoxFinalisedFolder,
  apiSmartBoxMoveFiles,
  apiSmartBoxZip,
  deleteSmartDocument,
  getFolderCountFiles,
  getRenameFile,
  getSmartBoxFileById,
  getSmartBoxFilesFilter,
  getSmartBoxFilesPerPage
} from '../../../api/SmartBox'
import CustomNotificationManager from '../CustomNotificationManager'
import FileDownload from 'js-file-download'
import { customConfirm } from '../../../utils/AssetRegister/CustomConfirm'
import { extractDepthForTree } from '../../../utils/SmartBox/SmartBoxMethods'
//import imgBoxClose from '../../../assets/smart-folder-medium-parent.svg'
import imgFolderNewParent from '../../../assets/box/box_20x20.png'
import imgFolderNewParentEmpty from '../../../assets/box/box_empty_20x20.png'
import imgFolderNewParentProcessing from '../../../assets/box/box_processing_20x20.png'
import imgFolder from '../../../assets/folder/folder_20x20.png'
import imgFolderEmpty from '../../../assets/folder/folder_empty_20x20.png'
import imgFolderOpen from '../../../assets/folder/folder_open_20x20.png'

//import imgBoxOpen from '../../../assets/smart-folder-open-paret-small.svg'
import { Dropdown } from 'antd'

import { connect } from 'react-redux'
import {
  setSmartBoxTree,
  setSmartBoxCurrentFolder,
  setSmartBoxLoadTree,
  setSmartBoxFolderClosed,
  setSmartBoxDeletedFolder,
  setSmartBoxMovedFiles,
  setSmartBoxToggleFilePreview,
  setSmartBoxDirectoryNote,
  setSmartBoxSelectedDocuments,
  setSmartBoxRenamedFolder,
  setSmartBoxFoldersCheckErrors,
  setSmartBoxSelectedDuplicateFile,
  setSmartBoxSecurityConfiguration,
  setSmartBoxTreeCurrentDepth,
  setSmartBoxScrollPositionObject,
  setSmartBoxFilterRefreshOpenedBox,
  setSmartBoxCurrentFolderName,
  setLeftNavigationTreeShow,
  setSmartBoxOldParentOfMovedFolders,
  setSmartBoxNewParentOfMovedFolders
} from '../../../actions/smartBoxActions'
import { compose } from 'redux'
import { noop } from '../../../utils/SmartBox/DoubleClickMethods/cancellable-promise-exports'
import pleaseStopTriggeringClicksOnDoubleClick
  from '../../../utils/SmartBox/DoubleClickMethods/please-stop-triggering-clicks-on-double-click'
import DownloadBoxModalOptionFiles from '../../SmartBox/SmartBox/DownloadBoxModalOptionFiles'
import withRouter from '../../../private-routers/withRouter'

const nameRgex = /^[A-Za-z\d_\s]+$/

const ClickableNode = ({ node, oldNode, width, currentFolder, onClick, onDoubleClick, renameFileId, filesErrors, nameNodeTitle}) =>(
    <div className={`node-title ${ currentFolder === node.idPath ? 'active' : '' }`}
         style={{ width: width }}
         onClick={ onClick }
         onDoubleClick={ onDoubleClick }
    >
      <Tooltip title={ (renameFileId === '' || renameFileId !== node.idPath) && ( nameNodeTitle ? nameNodeTitle : node.title) }>
        <span className={ filesErrors ? 'node-with-error' : ''}>{ (renameFileId === '' || renameFileId !== node.idPath) && ( nameNodeTitle ? nameNodeTitle : node.title) }</span>
      </Tooltip>
      {/*{ node && filesErrors &&*/}
      {/*  <span className="icon-v12-process-error"></span>*/}
      {/*}*/}
    </div>
)
ClickableNode.defaultProps = {
  onClick: noop,
  onDoubleClick: noop,
}

const EnhancedClickableNode = pleaseStopTriggeringClicksOnDoubleClick(ClickableNode)

export class LeftFoldersNavigation extends React.Component {
  _isMounted = false

  constructor() {
    super()
    this.state = {
      files: [],
      treeData: [],
      smartBoxMenuOpen: false,
      loadingOpenedFolder: true,
      lastNodeOpened: '',
      renameFileId: '',
      newNameFolder: '',
      validEditFolderName: { success: true, reason: 'Invalid name' },
      addAsFirstChild: false,
      visiblePageModalDownload: false,
      fileSelectDownload: {},
      foldersCheckErrors: [],
      hoverRowMenuId: '',
      isRowMenuVisible: false,
      visibleCollectData: false,
      filesCountBox: [],
      foldersOpened: [],
      documentsStartNr: null,
      folderMovesInTree: 0 // max 3
    }
    this.renderTheme = this.renderTheme.bind(this)
    this.fetchFolderInfo = this.fetchFolderInfo.bind(this)
    this.parseData = this.parseData.bind(this)
    this.getFolders = this.getFolders.bind(this)
    this.setViewMenu = this.setViewMenu.bind(this)
    this.moveNodeOnTree = this.moveNodeOnTree.bind(this)
    this.canDropOnTree = this.canDropOnTree.bind(this)
    this.canDragOnTree = this.canDragOnTree.bind(this)
  }

  componentDidMount() {
    this.props.setSmartBoxCurrentFolder(this.props.location.pathname.split('/').pop())
    this._isMounted = true
    this.getFolders()
    this.getCountFilesBoxes()
  }

  getCountFilesBoxes = () => {
    if (this.props.smartBoxTree && this.props.smartBoxTree.length > 0) {
      const files = this.props.smartBoxTree.filter((item) => item.type === 'DIRECTORY')
      files && files.length > 0 && files.forEach((file) => {
        file.path = file.pathFiles
      })
      const pathFiles = []
      files && files.length > 0 && files.forEach((item) => {
        if (item.path) {
          pathFiles.push(item.path)
        }
      })
      const folderCountFilesDTO = {
        pathList: pathFiles
      }
      getFolderCountFiles(folderCountFilesDTO).then((response) => {
        this.setState({
          filesCountBox: response.data
        })
      })
    }
  }
  onStatusCountFilesBox = (item) => {
    const  { filesCountBox } = this.state
    if (item) {
      if (filesCountBox.length > 0) {
        const match = filesCountBox.filter(el => el.path === item.pathFiles)
        if (match && match.length > 0) {
          return match[0].numberOfFiles
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.baseDataForTree !== this.props.baseDataForTree){
      this.getFolders(this.props.refreshData)
      // this.getListFoldersCheckErrors(this.props.baseDataForTree)
      this.props.cancelRefreshData()
    }
    // eslint-disable-next-line
    if (((prevProps.smartBoxCurrentFolder !== this.props.smartBoxCurrentFolder) && (this.props.smartBoxCurrentFolder !== '')
    && (this.props.smartBoxCurrentFolder !== this.state.lastNodeOpened) && !this.props.actionNextPreviousSmartbox) || (!this.state.lastNodeOpened && this.props.smartBoxCurrentFolder && !this.props.actionNextPreviousSmartbox) ) {
      this.addChildrenForCurrentFolder()
      this.checkNodeAtPathExpanded()
    }

    if (prevProps.smartBoxNewNode !== this.props.smartBoxNewNode) {
      this.addNodeForParent()
    }
    if (prevProps.smartBoxDeletedFolder !== this.props.smartBoxDeletedFolder && this.props.smartBoxDeletedFolder.length > 0) {
      this.removeNodeFromTree()
    }
    if (prevProps.smartBoxRenamedFolder.id !== this.props.smartBoxRenamedFolder.id || prevProps.smartBoxRenamedFolder.newName !== this.props.smartBoxRenamedFolder.newName) {
      this.changeNodeInTree(null, this.props.smartBoxRenamedFolder.id, this.props.smartBoxRenamedFolder.newName, false)
    }
    if (this.props.smartBoxOldParentOfMovedFolders && this.props.smartBoxNewParentOfMovedFolders) {
      this.moveNodesInTree()
    }
    if(prevProps.smartBoxCurrentSortOption !== this.props.smartBoxCurrentSortOption) {
      this.sortChildrenOfCurrentNode()
    }
  }
  componentWillUnmount() {
    this._isMounted = false
    // this.props.setLeftNavigationTreeShow(false)
    // this.props.setSmartBoxTree([])
  }

  onViewOptionsFileModalDownload = (file) => {
    if(this._isMounted) {
      this.setState({
        visiblePageModalDownload: true,
        visibleCollectData: true,
        fileSelectDownload: file
      })
    }
  }
  onCancelModalDownload = (status) => {
    if(this._isMounted) {
      this.setState({
        visiblePageModalDownload: status,
      })
    }
  }
  moveNodesInTree() {
    const { smartBoxMovedFolders, smartBoxOldParentOfMovedFolders, smartBoxNewParentOfMovedFolders } = this.props
    let { smartBoxTree } = this.props
    // find current parent of moved folders
    let objFind = find({
      getNodeKey: ({ treeIndex }) => treeIndex,
      treeData: smartBoxTree,
      searchQuery: smartBoxOldParentOfMovedFolders,
      searchMethod: this.searchNodeById,
      searchFocusOffset: 0,
      expandAllMatchPaths: false,
      expandFocusMatchPaths: false,
    })
    const foundOldParentNode = objFind.matches.length ? objFind.matches[objFind.matches.length - 1] : null

    if (foundOldParentNode !== null) {

      smartBoxMovedFolders.forEach((folderId) => {
        // if(foundNewParentNode !== null) {
        // find each folder node in tree
        objFind = find({
          getNodeKey: ({ treeIndex }) => treeIndex,
          treeData: smartBoxTree,
          searchQuery: folderId,
          searchMethod: this.searchNodeById,
          searchFocusOffset: 0,
          expandAllMatchPaths: false,
          expandFocusMatchPaths: false,
        })
        const foundNode = objFind.matches.length ? objFind.matches[objFind.matches.length - 1] : null

        if (foundNode !== null) {
          const { node } = foundNode

          // remove node from old parent
          smartBoxTree = removeNodeAtPath({
            treeData: smartBoxTree,
            path: foundNode.path, // You can use path from here
            getNodeKey: ({ treeIndex }) => treeIndex,
            ignoreCollapsed: false,
          })
          
          // find new parent for moved nodes
          objFind = find({
            getNodeKey: ({ treeIndex }) => treeIndex,
            treeData: smartBoxTree,
            searchQuery: smartBoxNewParentOfMovedFolders,
            searchMethod: this.searchNodeById,
            searchFocusOffset: 0,
            expandAllMatchPaths: false,
            expandFocusMatchPaths: false,
          })
          const foundNewParentNode = objFind.matches.length ? objFind.matches[objFind.matches.length - 1] : null

          if(foundNewParentNode !== null) {
            // add node under new parent
            smartBoxTree = addNodeUnderParent({
              treeData: smartBoxTree,
              parentKey: foundNewParentNode.path[foundNewParentNode.path.length - 1],
              getNodeKey: ({ treeIndex }) => treeIndex,
              newNode: node,
              addAsFirstChild: true,
            }).treeData
          }
          // update tree after each node change
          if(this._isMounted) {
            this.setState({ treeData: smartBoxTree})
            this.props.setSmartBoxTree(smartBoxTree)
          }
        }
        // }
      })
    }
    this.props.setSmartBoxOldParentOfMovedFolders('')
    this.props.setSmartBoxNewParentOfMovedFolders('') 
  }

  addNodeForParent() {
    const { smartBoxNewNode } = this.props

    let smartBoxTree = this.props.smartBoxTree.length > 0 ? this.props.smartBoxTree : this.state.treeData
    if (smartBoxNewNode.parentId === null) {
      const newNode = {
        title: smartBoxNewNode.name,
        expanded: true,
        noDragging: false,
        pathRelative: null,
        idPath: smartBoxNewNode.id,
        type: 'DIRECTORY',
        children: [],
        bulkProcessingStarted: false,
        statisticsReady: smartBoxNewNode.statisticsReady,
        numberOfFiles: this.onNumberOfFiles(smartBoxNewNode),
      }
      smartBoxTree = insertNode({
        newNode: newNode,
        getNodeKey: ({ treeIndex }) => treeIndex,
        treeData: smartBoxTree,
        depth: 0,
        minimumTreeIndex: 0,
      }).treeData
      if(this._isMounted) {
        this.setState({ treeData: smartBoxTree })
      }
      this.props.setSmartBoxTree(smartBoxTree)
    } else {
      const objFind = find({
        getNodeKey: ({ treeIndex }) => treeIndex,
        treeData: smartBoxTree,
        searchQuery: smartBoxNewNode.parentId,
        searchMethod: this.searchNodeById,
        searchFocusOffset: 0,
        expandAllMatchPaths: false,
        expandFocusMatchPaths: false,
      })
      const foundNode = objFind.matches.length ? objFind.matches[objFind.matches.length - 1] : null

      if (foundNode === null) return

      const { path, node } = foundNode

      if (node) {
        const newNode = {
          title: smartBoxNewNode.name,
          expanded: true,
          noDragging: false,
          path: path,
          pathRelative: smartBoxNewNode.path.replace(this.props.basePath, ''),
          idPath: smartBoxNewNode.id,
          type: 'DIRECTORY',
          children: [],
          bulkProcessingStarted: false,
          isEmpty: true,
          statisticsReady: smartBoxNewNode.statisticsReady,
          numberOfFiles: this.onNumberOfFiles(smartBoxNewNode),
        }
        smartBoxTree = addNodeUnderParent({
          treeData: smartBoxTree,
          parentKey: path[path.length - 1],
          expandParent: true,
          getNodeKey: ({ treeIndex }) => treeIndex,
          newNode: newNode,
          addAsFirstChild: true,
        }).treeData
        if(this._isMounted) {
          this.setState({ treeData: smartBoxTree })
        }
        this.props.setSmartBoxTree(smartBoxTree)
      }
    }
  }

  changeNodeInTree(foundNode, id, newFileName, setRenamedFolderProp = false) {
    let { smartBoxTree } = this.props
    if (foundNode === null) {
      // find node in tree
      const objFind = find({
        getNodeKey: ({ treeIndex }) => treeIndex,
        treeData: smartBoxTree,
        searchQuery: id,
        searchMethod: this.searchNodeById,
        searchFocusOffset: 0,
        expandAllMatchPaths: false,
        expandFocusMatchPaths: false,
      })
      foundNode = objFind.matches.length ? objFind.matches[objFind.matches.length - 1] : null
    }
    if (foundNode === null) return

    const { node } = foundNode
    let newNode = { ...node, title: newFileName }
    let pathRelative, oldPathRelative

    if (newNode.pathRelative !== null) {
      const arPath = newNode.pathRelative.split('/')
      arPath[arPath.length - 1] = newFileName
      pathRelative = arPath.join('/')

      newNode = { ...newNode, pathRelative }
      pathRelative += '/'
      oldPathRelative = `${ node.pathRelative }/`
    } else {
      pathRelative = `${ newFileName }/`
      oldPathRelative = `${ node.title }/`
    }
    if (newNode.children.length) {
      newNode = { ...newNode, children: this.changePathRelativeToAllChildren(newNode, oldPathRelative, pathRelative) }
    }

    const nextTreeData = changeNodeAtPath({
      treeData: smartBoxTree,
      path: foundNode.path,
      getNodeKey: ({ treeIndex }) => treeIndex,
      ignoreCollapsed: true,
      newNode
    })
    if(this._isMounted) {
      this.setState({
        treeData: nextTreeData[this.state.currentTreeIndex]
      }, () => {
        this.props.setSmartBoxTree(nextTreeData)
        if (setRenamedFolderProp) {
          this.props.setSmartBoxRenamedFolder({ id: node.idPath, newName: newFileName })
        }
      })
    }
  }
  findNodeInTree(id, smartBoxTree) {
    if (id === 0) return null
    return find({
      getNodeKey: ({ treeIndex }) => treeIndex,
      treeData: smartBoxTree,
      searchQuery: id,
      searchMethod: this.searchSingleNodeById,
      searchFocusOffset: 0,
      expandAllMatchPaths: false,
      expandFocusMatchPaths: false,
    })

  }

  sortChildrenOfCurrentNode = () => {
    const currentFolder = this.props.smartBoxCurrentFolder
    let {smartBoxTree} = this.props
    const objFind = this.findNodeInTree(currentFolder, smartBoxTree)

    if (objFind.matches.length > 0) {
      const foundNode = objFind.matches[0]
      const newNode = { ...foundNode.node, children : [] }

      smartBoxTree = changeNodeAtPath({
        treeData: smartBoxTree,
        path: foundNode.path,
        getNodeKey: ({ treeIndex }) => treeIndex,
        ignoreCollapsed: true,
        newNode
      })
      this.setState({
        treeData: smartBoxTree[this.state.currentTreeIndex]
      }, () => {
        this.props.setSmartBoxTree(smartBoxTree)
        this.addChildrenForCurrentFolder(true)
      })
    }
  }
  removeNodeFromTree() {
    const foldersToBeRemoved = this.props.smartBoxDeletedFolder
    let {smartBoxTree} = this.props
    foldersToBeRemoved.forEach((fileId) => {
      const objFind = this.findNodeInTree(fileId, smartBoxTree)

      if (objFind.matches.length > 0) {
        const foundNode = objFind.matches[0]

        const parentNode = getNodeAtPath({
          getNodeKey: ({ treeIndex }) => treeIndex,
          treeData: smartBoxTree,
          path: foundNode.node.path
        })

        // if (parentNode !== null) {
        // if current folder is to be deleted, then open next folder, or previous folder
        // or if node doesn't have siblings open the parent folder
        if(foundNode.node.idPath === this.props.smartBoxCurrentFolder) {
          let nextFolderId = 0
          let prevFolderId = 0
          let currentFolderFound = false
          walk({
            treeData: parentNode !== null ? parentNode.node.children : smartBoxTree,
            getNodeKey: ({ treeIndex }) => treeIndex,
            callback: (node) => {
              // consider only siblings of the foundNode not children too (if it is expanded)
              if(node.path.length > 1 || (parentNode === null && node.node.pathRelative !== null)) return

              const id = node.node.idPath
              if (currentFolderFound) {
                nextFolderId = id
                currentFolderFound = false
              }
              if (id === foundNode.node.idPath) {
                currentFolderFound = true
              } else if (nextFolderId === 0) {
                prevFolderId = id
              }
            },
            ignoreCollapsed: true
          })
          try {
            smartBoxTree = removeNodeAtPath({
              treeData: smartBoxTree,
              path: foundNode.path,
              getNodeKey: ({ node: TreeNode, treeIndex: number }) => {
                return number
              },
              ignoreCollapsed: false,
            })
          } catch(error) { }

          let siblingId = 0
          let nextFolder

          if (nextFolderId !== 0) {
            siblingId = nextFolderId
          } else if (prevFolderId !== 0) {
            siblingId = prevFolderId
          }

          const nodeId = siblingId !== 0 ? siblingId : (parentNode !== null ? parentNode.node.idPath : 0)
          const objFind = this.findNodeInTree(nodeId, smartBoxTree)
          if(objFind !== null) {
            nextFolder = objFind.matches[0]

            const depth = extractDepthForTree(nextFolder.node.pathRelative)
            this.fetchFolderInfo(nextFolder.node.idPath, nextFolder, null, depth)
          }
          if(siblingId === 0 || foundNode.node.children.length === 0) {
            if(this._isMounted) {
              this.setState({
                treeData: smartBoxTree[this.state.currentTreeIndex]
              }, () => {
                this.props.setSmartBoxTree(smartBoxTree)
              })
            }
          }
        } else {
          try {
            smartBoxTree = removeNodeAtPath({
              treeData: smartBoxTree,
              path: foundNode.path,
              getNodeKey: ({ node: TreeNode, treeIndex: number }) => {
                return number
              },
              ignoreCollapsed: false,
            })
          } catch (error) { }
          if(this._isMounted) {
            this.setState({
              treeData: smartBoxTree[this.state.currentTreeIndex]
            }, () => {
              this.props.setSmartBoxTree(smartBoxTree)
            })
          }
        }
        // }
      }
    })

  }
  
  checkNodeAtPathExpanded () {
    let {smartBoxTree} = this.props
    const objFind = find({
      getNodeKey: ({treeIndex}) => treeIndex,
      treeData: smartBoxTree,
      searchQuery: this.props.smartBoxCurrentFolder,
      searchMethod: this.searchNodeById,
      searchFocusOffset: 0,
      expandAllMatchPaths: false,
      expandFocusMatchPaths: false,
    })

    const foundNode = objFind.matches.length ? objFind.matches[objFind.matches.length - 1] : null
    if (foundNode === null) return
    const {path, node} = foundNode
    if (node.expanded === false && node.children.length) {
      const nextTreeData = changeNodeAtPath({
        treeData: smartBoxTree,
        path: path,
        getNodeKey: ({treeIndex}) => treeIndex,
        ignoreCollapsed: true,
        newNode: {...node, expanded: true}
      })
      if(this._isMounted){
        this.setState({ treeData: nextTreeData, lastNodeOpened: node.idPath})
        this.props.setSmartBoxTree(nextTreeData)
      }
    }
    if(node.expanded === true && node.children.length){
      const objFind = find({
        getNodeKey: ({treeIndex}) => treeIndex,
        treeData: smartBoxTree,
        searchQuery: this.props.smartBoxCurrentFolder,
        searchMethod: this.searchNodeById,
        searchFocusOffset: 0,
        expandAllMatchPaths: false,
        expandFocusMatchPaths: false,
      })
      const foundNode = objFind.matches.length ? objFind.matches[objFind.matches.length - 1] : null
      if (foundNode === null) return
      const {path, node} = foundNode
      const nextTreeData = changeNodeAtPath({
        treeData: smartBoxTree,
        path: path,
        getNodeKey: ({treeIndex}) => treeIndex,
        ignoreCollapsed: true,
        newNode: {...node, expanded: true}
      })
      if(this._isMounted){
        this.setState({ treeData: nextTreeData, lastNodeOpened: node.idPath})
        this.props.setSmartBoxTree(nextTreeData)
      }
    }
  }

  addChildrenForCurrentFolder(alwaysAdd = false) {
    if(this.state.foldersOpened.includes(this.props.smartBoxCurrentFolder)) return
    let {smartBoxTree} = this.props
    const { documentsStartNr } = this.state
    // const { smartBoxCurrentSortOption } = this.props
    const objFind = find({
      getNodeKey: ({ treeIndex }) => treeIndex,
      treeData: smartBoxTree,
      searchQuery: this.props.smartBoxCurrentFolder,
      searchMethod: this.searchNodeById,
      searchFocusOffset: 0,
      expandAllMatchPaths: false,
      expandFocusMatchPaths: false,
    })

    const foundNode = objFind.matches.length ? objFind.matches[objFind.matches.length - 1] : null
    if (foundNode === null) return
    const { path, node } = foundNode
    if(foundNode.treeIndex === null) return
    if (node && (this.state.lastNodeOpened !== node.idPath || alwaysAdd)) {
      this.setState({foldersOpened: [...this.state.foldersOpened, this.props.smartBoxCurrentFolder] })
      let sort = 'creation'
      // let filter = ''
      // switch (smartBoxCurrentSortOption) {
      //   case 'asc': sort = smartBoxCurrentSortOption; break
      //   case 'desc': sort = smartBoxCurrentSortOption; break
      //   case 'modified': sort = smartBoxCurrentSortOption; break
      //   case 'creation': sort = smartBoxCurrentSortOption; break
      //   default: filter = smartBoxCurrentSortOption;
      // }
      const initFilter = {
        documentsFolderPath: node.pathRelative ? node.pathRelative.replace(this.props.basePath, '') : node.title,
        pageNumber: 1,
        resultsPerPage: 1000,
        folderTree: true,
        sort: sort,
        documentsStartNr: documentsStartNr
      }
      getSmartBoxFilesPerPage(initFilter).then((response) => {
        if (response.status === 200) {
          if (response.data.files && response.data.files.length) {
            // this.getListFoldersCheckErrors(response.data.files)
            response.data.files.forEach(function(item) {
              // adding one folder (children of the found node) when matches title and name
              const found = alwaysAdd ? false : node.children.find((element) => element.title === item.name)

              if (item.type === 'DIRECTORY' && !found) {
                const newNode = {
                  title: item.name,
                  expanded: true,
                  noDragging: false,
                  path: path,
                  pathRelative: item.path.replace(this.props.basePath, ''),
                  idPath: item.id,
                  type: item.type,
                  children: [],
                  isEmpty: item.isEmpty,
                  hasProcessingErrors: false,
                  pathFiles: item.path,
                  statisticsReady: item.statisticsReady,
                  numberOfFiles: this.onNumberOfFiles(item),
                }
                smartBoxTree = addNodeUnderParent({
                  treeData: smartBoxTree,
                  parentKey: path[path.length-1],
                  getNodeKey: ({ treeIndex }) => treeIndex,
                  newNode: newNode,
                  expandParent: true,
                  addAsFirstChild: this.state.addAsFirstChild
                }).treeData
                this.props.setSmartBoxTree(smartBoxTree)
                if(!alwaysAdd && this._isMounted) {
                  this.setState({ treeData: smartBoxTree,
                    lastNodeOpened: node.idPath,
                    documentsStartNr: response.data.documentsStartNr
                  }, () => {
                  })
                }
              }
            }, this)

            if(alwaysAdd) {
              this.setState({ treeData: smartBoxTree})
            }
            this.props.setSmartBoxTree(smartBoxTree)
          } else {
            if(this._isMounted) {
              this.setState({ lastNodeOpened: node.idPath })
            }
          }
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    }
  }
  searchSingleNodeById(obj) {
    if (obj.node.idPath === obj.searchQuery) {
      return true
    }
    return false
  }
  searchNodeById(obj) {
    if (obj.node.idPath === obj.searchQuery) {
      return true
    }
    if (obj.node.children.length) {
      for (let i=0; i<obj.node.children.length; i++) {
        if (obj.node.children[i].idPath === obj.searchQuery) {
          return true
        }
      }
    }
    return false
  }

  getFolders = ( keepChildren ) => {
    //extract data for level 0
    let newTreeData = []
    if(keepChildren && this.props.smartBoxTree){
      return
    }
    this.props.baseDataForTree.forEach((item) => {
      if (item.id === this.props.location.pathname.split('/').pop()) {
        const newNode = {
          title: item.name,
          expanded: false,
          noDragging: false,
          pathRelative: item.relativePath,
          idPath: item.id,
          type: item.type,
          bulkProcessingStarted: item.bulkProcessingStarted,
          children: [],
          isEmpty: item.isEmpty,
          hasProcessingErrors: false,
          pathFiles: item.path,
          statisticsReady: item.statisticsReady,
          numberOfFiles: this.onNumberOfFiles(item),
        }
        newTreeData.push(newNode)
        if(newTreeData.length){
          this.props.setSmartBoxTree(newTreeData)
          this.setState({ treeData: newTreeData}) 
        }
      }
    })

    if(this._isMounted) {
      if(keepChildren) {
        this.state.treeData.forEach((item) => {
          if(item.children.length > 0) {
            const index = newTreeData.findIndex(newItem => newItem.idPath === item.idPath)
            if(index !== -1) {
              newTreeData[index].children = item.children
              newTreeData[index].expanded = item.expanded
            }
          }
        })
      }

      this.setState({ treeData: newTreeData }, () => {
        if (this.props.smartBoxLoadTree === true) {
          this.addChildrenForCurrentFolder()
          this.props.setSmartBoxLoadTree(false)
          // this.getListFoldersCheckErrors(this.props.baseDataForTree)
          // if (this.props.baseDataForTree && this.props.baseDataForTree.length > 0) {
          //   this.getListFoldersCheckErrors(this.props.baseDataForTree)
          // }
        }
        if(keepChildren) {
          this.props.setSmartBoxTree(newTreeData)
        }
      })
    }
  }

  parseData(content) {
    const files = []
    if (content.length >= 0) {
      content.forEach(function(item, index) {
        if (item.type === 'DIRECTORY') {
          files[files.length] = {
            title: item.name,
            expanded: true,
            noDragging: false,
            pathRelative: item.path,
            folderPath: item.folderPath,
            idPath: item.id,
            type: item.type,
            children: [],
            statisticsReady: item.statisticsReady,
            numberOfFiles: this.onNumberOfFiles(item),
          }
        }

      }, this)
    }
    if(this._isMounted) {
      this.setState({
        treeData: files
      })
    }
    return files
  }

  setViewMenu() {
    if(this._isMounted) {
      this.setState({
        smartBoxMenuOpen: !this.state.smartBoxMenuOpen
      })
    }
  }

  closeTreeNode(event, nodeIdPath, node) {
    if (node.node.children.length) {

      let {smartBoxTree} = this.props

      const nextTreeData = changeNodeAtPath({
        treeData: smartBoxTree,
        path: node.path,
        getNodeKey: ({ treeIndex }) => treeIndex,
        ignoreCollapsed: true,
        newNode: { ...node.node, expanded: false }
      })
      if(this._isMounted) {
        this.setState({ treeData: nextTreeData })
      }
      // if (typeof this.props.smartBoxCurentFolder === 'undefined' || this.props.smartBoxCurentFolder === node.node.idPath) {
      //   this.props.setSmartBoxCurrentFolder('')
      // }
      this.props.setSmartBoxTree(nextTreeData)

      // this.props.setSmartBoxFolderClosed(node.node.idPath)
    } else {

      const depth = extractDepthForTree(node.node.pathRelative)
      this.fetchFolderInfo(nodeIdPath, node, null, depth)
    }
  }

  fetchFolderInfo(folderId, node, arg, depth = 0) {
    if (node.node.type !== 'DIRECTORY') {
      return false
    }
    
    let {smartBoxTree} = this.props
    smartBoxTree = changeNodeAtPath({
      treeData: smartBoxTree,
      path: node.path,
      getNodeKey: ({ treeIndex }) => treeIndex,
      ignoreCollapsed: true,
      newNode: { ...node.node, expanded: true }
    })
    this.setState({ lastNodeOpened: node.node.idPath })

    const { smartBoxCurrentSortOption } = this.props
    const { documentsStartNr } = this.state
    const initFilter = {
      documentsFolderPath: node.node.pathRelative != null ? node.node.pathRelative : node.node.title, //+'/'+node.node.title
      pageNumber: 1,
      resultsPerPage: 1000,
      folderTree: true,
      sort: smartBoxCurrentSortOption,
      documentsStartNr: documentsStartNr
    }
    getSmartBoxFilesPerPage(initFilter).then((response) => {
      if (response.status === 200) {
        if (response.data.files && response.data.files.length) {
          response.data.files.forEach(function(item) {
            const found = node.node.children.find((element) => element.title === item.name)
            if (item.type === 'DIRECTORY' && !found) {
              const newNode = {
                title: item.name,
                expanded: true,
                noDragging: false,
                path: node.path,
                pathRelative: item.path.replace(this.props.basePath, ''),
                idPath: item.id,
                type: item.type,
                children: [],
                isEmpty: item.isEmpty,
                hasProcessingErrors: null,
                pathFiles: item.path,
                statisticsReady: item.statisticsReady,
                numberOfFiles: this.onNumberOfFiles(item),
              }

              smartBoxTree = addNodeUnderParent({
                treeData: smartBoxTree,
                parentKey: node.path[node.path.length - 1],
                expandParent: true,
                getNodeKey: ({ treeIndex }) => treeIndex,
                newNode: newNode,
                addAsFirstChild: this.state.addAsFirstChild,
              }).treeData
            }
          }, this)
          if(this._isMounted) {
            this.setState({
              treeData: smartBoxTree[this.state.currentTreeIndex],
              lastNodeOpened: node.node.idPath,
              documentsStartNr: response.data.documentsStartNr
            }, () => {
              // this.getListFoldersCheckErrors(response.data.files)
            })
          }
          this.props.setSmartBoxCurrentFolder(node.node.idPath)
          this.props.setSmartBoxCurrentFolderName(node.node)
          this.props.setSmartBoxTree(smartBoxTree)
          this.props.setSmartBoxTreeCurrentDepth(depth)
          this.props.setSmartBoxScrollPositionObject({ expandedRowKeys: [], url: '', scrollPosition: 0 })
        } else {
          if(!node.node.isEmpty) {
            smartBoxTree = changeNodeAtPath({
              treeData: smartBoxTree,
              path: node.path,
              getNodeKey: ({ treeIndex }) => treeIndex,
              ignoreCollapsed: true,
              newNode: { ...node.node, isEmpty: true }
            })
          }
          if(this._isMounted) {
            this.setState({
              lastNodeOpened: node.node.idPath,
              treeData: smartBoxTree[this.state.currentTreeIndex]
            }, () => {
              this.props.setSmartBoxCurrentFolder(node.node.idPath)
              this.props.setSmartBoxCurrentFolderName(node.node)
              this.props.setSmartBoxTree(smartBoxTree)
              this.props.setSmartBoxTreeCurrentDepth(depth)
              this.props.setSmartBoxScrollPositionObject({ expandedRowKeys: [], url: '', scrollPosition: 0 })
            })
          }
        }
        // redirect to folderOpened page
        this.props.setSmartBoxFilterRefreshOpenedBox(null, null, null, null)
        this.props.navigate(`/smart-box/folder/${ folderId }`)
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  // getListFoldersCheckErrors = (files) => {
    // if (files.length > 0) {
    //   getCheckFolderErrors(files).then((response) => {
    //     if (response.data.length) {
    //       const foldersCheckErrors = this.props.smartBoxFoldersCheckErrors
    //
    //       response.data.forEach((item) => {
    //         const index = foldersCheckErrors.findIndex((el) => el.path === item.path)
    //         if (index !== -1) {
    //           foldersCheckErrors[index].folderHasErrors = item.folderHasErrors
    //         } else {
    //           foldersCheckErrors.push(item)
    //         }
    //       })
    //       this.props.setSmartBoxFoldersCheckErrors(foldersCheckErrors)
    //       if(this._isMounted) {
    //         this.setState({ foldersCheckErrors })
    //       }
    //     }
    //   })
    // }
  // }
  getFilesErrors = (item) => {
    // const { foldersCheckErrors } = this.state
    const foldersCheckErrors = this.props.smartBoxFoldersCheckErrors
    const match = foldersCheckErrors.filter((el) => el.path === item.pathFiles)

    if (match && match.length > 0) {
      return match[0].folderHasErrors
    }
    return false
  }
  insertNewNodeinExistingTree = (tree, newNodeId, newNodeContent) => {
    if (tree.length) {
      tree.forEach((item) => {
        if (item.idPath === newNodeId) {
          item.children = newNodeContent
        } else {
          this.insertNewNodeinExistingTree(item.children, newNodeId, newNodeContent)
        }
      })
    }
    return tree
  }

  onDownloadZip = (id, name) => {
    apiSmartBoxZip(id, name).then((response) => {
      FileDownload(response.data, `${ name }.zip`)
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  toggleNoteVisible = (e, open) => {
    if(this._isMounted) {
      this.setState({ documentNoteVisible: open })
    }
  }

  startIntervalFinalise = () => {
    if (this.props.folder) {
      const { folder } = this.props
      const currentLocation = window.location.pathname
      this.props.toggleLoadingOpenedFolder(true)
      this.props.toggleSmartBoxMenu(false)
      if(this._isMounted) {
        this.setState({
          isIntervalStartedForBulkFinalise: true
        }, () => {
          this.props.setSmartBoxIntervalForBulkFinalise(currentLocation)
          const bulkReadyInterval = setInterval(() => {
            apiSmartBoxCheckBulkFolderFinalise(folder.id).then((res) => {
              const message = <span>{ 'Finalise folder process finished for ' }<span
                  style={ { textDecoration: 'underline', cursor: 'pointer' } }><span
                  onClick={ () => this.props.navigate('/smart-box/management') }>{ folder.name }</span></span></span>
              if (res.data) {
                notification.success({ message: message, duration: 1000000 })
                this.props.removeSmartBoxIntervalForBulkFinalise(this.props.intervalForBulkRedaction.filter((path) => path !== currentLocation))
                this.setState({
                  isIntervalStartedForBulkFinalise: false
                }, () => this.props.fetchFiles())
                clearInterval(bulkReadyInterval)
              }
              this.forceUpdate()
              this.props.toggleLoadingOpenedFolder(false)
            }).catch((error) => {
              clearInterval(bulkReadyInterval)
              if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
              }else{
                console.log(error)
              }
            })
            this.props.toggleLoadingOpenedFolder(false)

          }, 10000)
          this.props.addIntervalToApplication(bulkReadyInterval)
        })
      }
    }
  }

  onFinaliseFolder = (id, name) => {
    //CustomNotificationManager.info('Finalised folder is being downloaded as a zip. Please wait...', 'Info')
    apiSmartBoxZip(id, name).then((response) => {
      FileDownload(response.data, `${ name }.zip`)
    }).then(() => {
      CustomNotificationManager.cleanBuffer()
    })
    /*apiSmartBoxFinalisedFolder(id).then(() => {
      this.props.fetchFiles()
      this.startIntervalFinalise()
    }).catch((e) => {
      const errorMessage = 'Folder id does not exist'
      if (typeof e.response !== 'undefined') {
        if (e.response.status === 500) {
          if (typeof e.response.data.message !== 'undefined' && e.response.data.message.indexOf(errorMessage) > 0) {
            CustomNotificationManager.error('The current folder no longer exists or you do not have access to its settings', 'Error')
            const path = '/smart-box'
            this.props.navigate(path)
          }
        }
      }
    })*/
  }

  toggleFilePreview = (open, selectedDoc) => {
    if(this._isMounted) {
      if (open) {
        this.setState({
          sidebarClass: 'sidebar-class',
          contentClass: 'small-content',
          sidebarOpen: open,
          selectedDoc
        })
      } else {
        this.setState({ sidebarClass: '', contentClass: '', sidebarOpen: open })
      }
    }
  }
  toggleRenameFolderModal = (open) => {
    if(this._isMounted) {
      this.setState({ renameFolderModalVisible: open })
    }
  }
  moveNode = (event) => {
    const currentPathSelectedFiles = event.node.folderPath
    const indexCurrentPath = currentPathSelectedFiles ? currentPathSelectedFiles.indexOf(`smartBox_${ this.props.clientId }`) : ''
    const newCurrentPathSelectedFiles = currentPathSelectedFiles ? currentPathSelectedFiles.substr(indexCurrentPath + `smartBox_${ this.props.clientId }`.length) : ''
    const indexDestinationPath = event.nextParentNode.pathRelative ? event.nextParentNode.pathRelative.indexOf(`smartBox_${ this.props.clientId }`) : ''
    const newDestinationPath = event.nextParentNode.pathRelative ? event.nextParentNode.pathRelative.substr(indexDestinationPath + `smartBox_${ this.props.clientId }`.length) : ''
    const newDestinationFinal = newDestinationPath ? newDestinationPath.substr(1, newDestinationPath.length) : ''
    const newCurrentPathSelectedFilesFinal = newCurrentPathSelectedFiles ? newCurrentPathSelectedFiles.substr(1, newCurrentPathSelectedFiles.length) : ''
    const MoveFileDTO = {
      destinationPath: newDestinationFinal,
      files: [`${ event.node.title }.DIRECTORY`]
    }
    apiSmartBoxMoveFiles(MoveFileDTO).then((response) => {
      if(res.data.title.toLowerCase() === 'success'){
        CustomNotificationManager.success(res.data.message, 'Success')
      }else if(res.data.title.toLowerCase() === 'info'){
        CustomNotificationManager.info(res.data.message, 'Info')
      }else{
        return CustomNotificationManager.warning(res.data.message, 'Warning')
      } 
    }).catch((error) => {
      //this.fetchFiles()
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  fetchFiles = () => {
    const initFilter = {
      'docFilename': this.state.searchText.trim(),
      'docFolderPath': this.state.folder.relativePath.substr(1)
    }
    getSmartBoxFilesFilter(initFilter).then((res) => {
      if(this._isMounted) {
        this.setState({
          foldersPath: res.data.length ? res.data[0].folderPath : '',
          childFiles: res.data,
        })
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }


  toggleSmartBoxMenu = (open) => {

    const { smartBoxMenuOpen } = this.state
    if(this._isMounted) {
      if (smartBoxMenuOpen) {
        this.setState({ smartBoxMenuOpen: open })
      }
      this.setState({ smartBoxMenuOpen: open })
    }
  }
  toggleLoadingOpenedFolder = (open) => {
    if(this._isMounted) {
      this.setState({ loadingOpenedFolder: open })
    }
  }

  deleteFolder = async(node, path, treeIndex) => {
    getSmartBoxFileById(node.idPath).then((response) => {
      if (response.status === 200) {
        customConfirm({
          confirmAction: () => {
            const paremeters = {
              id: response.data.smartDocument.id,
              filePath: response.data.smartDocument.path,
              folder: response.data.smartDocument.type === 'DIRECTORY'
            }
            deleteSmartDocument(paremeters).then((resp) => {
              if (resp.data === 'Please wait until upload finishes!') {
                CustomNotificationManager.info('', 'Please wait until upload finishes!', { duration: 5 })
              }
              this.props.setSmartBoxDeletedFolder([response.data.smartDocument.id])
              //this.removeElementFromTree(node, path, treeIndex)
            }).catch((error) => {
              if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
              }else{
                console.log(error)
              }
            })
          },
          title: 'Do you want to delete item ?',
          content: `${ response.data.smartDocument.name }`,
          confirmButtonText: 'DELETE',
          cancelButtonText: 'CANCEL',
          mask: true,
          maskClosable: false,
          closeHaveLogic: false,
          className: 'asset-register-modal',
          centered: true,
          cancelClassName: 'cancel-button',
          confirmClassName: 'confirm-button'
        })
      }

    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  removeElementFromTree = (node, path) => {
    if(this._isMounted) {
      let newTreeData = removeNodeAtPath({
        treeData: this.props.smartBoxTree,
        path: path, // You can use path from here
        getNodeKey: ({ node: TreeNode, treeIndex: number }) => {
          return number
        },
        ignoreCollapsed: false,
      })
      this.setState({ treeData: newTreeData}) 
      this.props.setSmartBoxTree(newTreeData)

      
    }
  }
  routeChangeDataInsight(action, id) {
    this.props.navigate(`/smart-box/folder/${ id }/data-insights/dashboard`)
    this.props.setSmartBoxCurrentFolder(id)
    this.props.setSmartBoxSelectedDuplicateFile({}, '', '')
  }
  menuItemAction = (action, id, name) => {
    this.props.setSmartBoxSelectedDocuments(action, id)
    switch (action) {
      case 'analysis-settings': this.routeChangeAnalysisSettings(action, id); break
      case 'data-insights': this.routeChangeDataInsight(action, id); break
      case 'folder-redaction': this.routeChangeFolderRedaction(action, id); break
      case 'cull': this.routeChangeCull(action, id); break
      case 'download-finalised': this.onViewOptionsFileModalDownload({ id, name }); /*this.onFinaliseFolder(id, name);*/ break
      case 'folder-info':
        getSmartBoxFileById(id).then((response) => {
          if (response.status === 200) {
            if (response.data.smartDocument) {
              this.props.setSmartBoxToggleFilePreview(true, response.data.smartDocument)
              this.props.toggleFilePreview(true, response.data.smartDocument)
            }
          }
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
        })
        break
      case 'note': this.props.toggleNoteVisible(id); this.props.setSmartBoxDirectoryNote(true); break
      case 'create-redacted':
        apiSmartBoxFinalisedFolder(id).then(() => {
          this.props.fetchFiles()
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
        }); break
      case 'security-settings': this.routeChangeSecuritySettings(action, id); break
      default: this.props.toggleNoteVisible(id)
    }
  }
  routeChangeAnalysisSettings(action, id) {
    this.props.setSmartBoxCurrentFolder(id)
    this.props.navigate(`/smart-box/folder/${ id }/analysis-settings`)
  }
  routeChangeFolderRedaction(action, id) {
    this.props.navigate(`/smart-box/folder/${ id }/folder-redaction`)
    this.props.setSmartBoxCurrentFolder(id)
  }
  routeChangeCull(action, id) {
    this.props.navigate(`/smart-box/folder/${ id }/cull/duplicates`)
    this.props.setSmartBoxCurrentFolder(id)
  }
  routeChangeSecuritySettings(action, id) {
    //this.props.navigate(`/smart-box/folder/${ id }/security-settings`)
    this.props.setSmartBoxCurrentFolder(id)
    this.props.navigate(`/smart-box/folder/${ id }/analysis-settings`)
    this.props.setSmartBoxSecurityConfiguration('security-settings')
  }
  updateTreeAfterMoveDirectories(smartBoxTree, item, foundDropNode) {

    // remove moved folders from tree
    const objFind = find({
      getNodeKey: ({ treeIndex }) => treeIndex,
      treeData: smartBoxTree,
      searchQuery: item.id,
      searchMethod: this.searchNodeById,
      searchFocusOffset: 0,
      expandAllMatchPaths: false,
      expandFocusMatchPaths: false,
    })
    const foundNode = objFind.matches.length ? objFind.matches[objFind.matches.length - 1] : null
    if (typeof foundNode !== 'undefined') {
      smartBoxTree = removeNodeAtPath({
        treeData: smartBoxTree,
        path: foundNode.path, // You can use path from here
        getNodeKey: ({ treeIndex }) => treeIndex,
        ignoreCollapsed: false,
      })
    }

    // add moved folder into tree only if destination folder is opened
    if (foundDropNode.node.children.length && foundDropNode.node.expanded) {

      const newNode = {
        title: item.name,
        expanded: true,
        noDragging: false,
        path: foundDropNode.path,
        pathRelative: item.path.replace(this.props.basePath, ''),
        idPath: item.id,
        type: item.type,
        children: [],
        statisticsReady: item.statisticsReady,
        numberOfFiles: this.onNumberOfFiles(item),
      }
      smartBoxTree = addNodeUnderParent({
        treeData: smartBoxTree,
        parentKey: foundDropNode.path[foundDropNode.path.length - 1],
        expandParent: true,
        getNodeKey: ({ treeIndex }) => treeIndex,
        newNode: newNode,
        addAsFirstChild: this.state.addAsFirstChild,
      }).treeData
    }
    return smartBoxTree
  }

  dropFileFromOutside(event, treeNodeId) {
    let fileId = event.dataTransfer.getData('Text')
    fileId = fileId.replace('file-', '')

    if (!fileId) return

    const fileName = event.dataTransfer.getData('name')
    const fileType = event.dataTransfer.getData('type')

    let {smartBoxTree} = this.props

    const objFind = find({
      getNodeKey: ({ treeIndex }) => treeIndex,
      treeData: smartBoxTree,
      searchQuery: treeNodeId,
      searchMethod: this.searchNodeById,
      searchFocusOffset: 0,
      expandAllMatchPaths: false,
      expandFocusMatchPaths: false,
    })
    const foundDropNode = objFind.matches.length ? objFind.matches[objFind.matches.length - 1] : null

    if (foundDropNode === null) return

    const { node } = foundDropNode

    const nodeParentFolder = node.pathRelative !== null ? node.pathRelative.substr(0, node.pathRelative.indexOf('/')) : node.title
    const fileParentFolder = event.dataTransfer.getData('parentFolder')

    if (nodeParentFolder !== fileParentFolder) {
      CustomNotificationManager.error('Move operation not allowed!', 'Error')
      this.removeDropNodeSelection()
    } else if (typeof node !== 'undefined') {
      const { smartBoxSelectedFiles } = this.props

      const arIds = []
      let foldersCount = 0
      let filesCount = 0

      for (let i=0; i<smartBoxSelectedFiles.length; i++) {
        arIds[arIds.length] = smartBoxSelectedFiles[i].id
        if (smartBoxSelectedFiles[i].type === 'DIRECTORY') foldersCount++
        else filesCount++
      }
      // if no file was selected in Folder Opened, move only the file that was dragged
      if (arIds.length === 0) {
        arIds[arIds.length] = fileId
      }
      let message = ''
      if (foldersCount) message += `${ foldersCount } folder${ foldersCount > 1 ? 's' : '' }`
      if (foldersCount && filesCount) message += ' and '
      if (filesCount) message += `${ filesCount } file${ filesCount > 1 ? 's' : '' }`

      if (message === '') {
        message = '1 item'
      }
      customConfirm({
        confirmAction: () => {
          let newDestinationFinal = null
          let newCurrentPathSelectedFilesFinal = null
          let sourcePath = null
          if (this.props.smartBoxCurrentFolderName.folderPath) {
            const currentPathSelectedFiles = this.props.smartBoxCurrentFolderName.folderPath
            const indexCurrentPath = currentPathSelectedFiles ? currentPathSelectedFiles.indexOf(`smartBox_${this.props.clientId}`) : ''
            const newCurrentPathSelectedFiles = currentPathSelectedFiles ? currentPathSelectedFiles.substr(indexCurrentPath + `smartBox_${this.props.clientId}`.length) : ''
            newCurrentPathSelectedFilesFinal = newCurrentPathSelectedFiles ? newCurrentPathSelectedFiles.substr(1, newCurrentPathSelectedFiles.length) : ''
            sourcePath = `${newCurrentPathSelectedFiles}${this.props.smartBoxCurrentFolderName.name}`
          }
          const MoveFileDTO = {
            destinationRelativePath: node.pathRelative ? node.pathRelative : node.title,
            id: arIds
          }
          apiSmartBoxMoveFiles(MoveFileDTO).then((res) => {
            if(res.data.title.toLowerCase() === 'success'){
              CustomNotificationManager.success(res.data.message, 'Success')
            }else if(res.data.title.toLowerCase() === 'info'){
              CustomNotificationManager.info(res.data.message, 'Info')
            }else{
              return CustomNotificationManager.warning(res.data.message, 'Warning')
            } 
            this.props.setSmartBoxMovedFiles(arIds)

            if (smartBoxSelectedFiles.length === 0) {
              if (fileType === 'DIRECTORY') {
                getSmartBoxFileById(fileId).then((response) => {
                  if (response.status === 200) {
                    const item = {
                      id: fileId,
                      name: fileName,
                      path: response.data.smartDocument.path
                    }
                    smartBoxTree = this.updateTreeAfterMoveDirectories(smartBoxTree, item, foundDropNode)
                    if(this._isMounted) { this.setState({ treeData: smartBoxTree }) }
                      this.props.setSmartBoxTree(smartBoxTree)

                  }
                }).catch((error) => {
                  CustomNotificationManager.cleanBuffer('warning', '')
                })
              }
            } else {
              for (let i = 0; i < smartBoxSelectedFiles.length; i++) {
                const item = smartBoxSelectedFiles[i]
                if (item.type === 'DIRECTORY') {
                  smartBoxTree = this.updateTreeAfterMoveDirectories(smartBoxTree, item, foundDropNode)
                }
              }
              if(this._isMounted) { this.setState({ treeData: smartBoxTree }) }
              this.props.setSmartBoxTree(smartBoxTree)
            }
          }).catch((e) => {
            if (e === 504) {
              CustomNotificationManager.cleanBuffer('warning', '')
            }
          })

          this.removeDropNodeSelection()
        },
        rejectAction: () => {
          this.removeDropNodeSelection()
        },
        title: 'Move file ?',
        content: `Are you sure you want to move ${ message } to Folder "${ node.title }" `,
        confirmButtonText: 'YES',
        cancelButtonText: 'CANCEL',
        mask: true,
        maskClosable: false,
        closeHaveLogic: true,
        className: 'asset-register-modal',
        centered: true,
        cancelClassName: 'cancel-button',
        confirmClassName: 'confirm-button'
      })
    }
    event.preventDefault()
  }

  isUnique = (newFileName, fileId) => {
    let unique = true
    const {smartBoxTree} = this.props

    // find node in tree
    const objFind = find({
      getNodeKey: ({ treeIndex }) => treeIndex,
      treeData: smartBoxTree,
      searchQuery: fileId,
      searchMethod: this.searchNodeById,
      searchFocusOffset: 0,
      expandAllMatchPaths: false,
      expandFocusMatchPaths: false,
    })
    const foundNode = objFind.matches.length ? objFind.matches[objFind.matches.length - 1] : null
    if (foundNode !== null) {
      let foundSameName

      if (typeof foundNode.node.path !== 'undefined') {
        // inner tree node - find parent of node to get siblings afterwards
        const parentNode = getNodeAtPath({
          getNodeKey: ({ treeIndex }) => treeIndex,
          treeData: smartBoxTree,
          path: foundNode.node.path
        })
        if (parentNode !== null) {
          foundSameName = parentNode.node.children.find((file) => file.title === newFileName)
        }
      } else {
        // first level tree node
        foundSameName = smartBoxTree.find((node) => node.title === newFileName)
      }
      unique = typeof foundSameName === 'undefined' || foundSameName.idPath === fileId
    }
    return { unique, foundNode }
  }
  changePathRelativeToAllChildren = (node, oldPathRelative, newPathRelative) => {
    for (let i=0; i<node.children.length; i++) {
      const item = node.children[i]
      if (item.children.length) {
        node.children[i].children = this.changePathRelativeToAllChildren(item, oldPathRelative, newPathRelative)
      }
      node.children[i].pathRelative = node.children[i].pathRelative.replace(oldPathRelative, newPathRelative)
    }
    return node.children
  }
  handleNewRenameFolderPressed = (e, fileName, fileId) => {

    const { validEditFolderName } = this.state
    const newFileName = e.target.value

    validEditFolderName.success= true
    if (e.key === 'Enter') {

      if (fileName === newFileName) {
        if(this._isMounted) {
          this.setState({
            validEditFolderName,
            renameFileId: '',
            // positionOnRenameFolder: false
          })
        }
      } else {

        const { unique, foundNode } = this.isUnique(newFileName, fileId)

        if (!nameRgex.test(newFileName) || !unique) {
          validEditFolderName.success = false
          if (! nameRgex.test(newFileName)) {
            validEditFolderName.reason = 'Invalid name'
          }
          if (! unique) {
            validEditFolderName.reason = 'Already exist'
          }
          CustomNotificationManager.error(validEditFolderName.reason, 'Error')

          if(this._isMounted) {
            this.setState({
              validEditFolderName,
              renameFileId: fileId,
              // positionOnRenameFolder: true
            })
          }
        } else {
          validEditFolderName.success = true
          if(this._isMounted) {
            this.setState({
              renameFileId: '',
              // positionOnRenameFolder: false,
              // newNameFolder: '',
              validEditFolderName
            })
          }
          const newRenameFolder = {
            id: fileId,
            name: newFileName
          }
          getRenameFile(newRenameFolder).then((response) => {
            if (response.status === 200) {
              this.changeNodeInTree(foundNode, fileId, newFileName, true)
              if(response.data.title.toLowerCase() === 'success') {
                CustomNotificationManager.success(response.data.message, 'Success')
              } else {
                CustomNotificationManager.info(response.data.message, 'Info')
              }
            }
          }).catch((error) => {
            const errorMessage = 'Folder id does not exist'
              if (error?.response?.status === 500) {
                if (typeof error?.response?.data.message !== 'undefined' && error.response.data.message.indexOf(errorMessage) > 0) {
                  CustomNotificationManager.error('The current folder no longer exists or you do not have access to its settings', 'Error')
                }
              }else if (error?.response?.status === 504) {
              CustomNotificationManager.cleanBuffer('warning', '')
              }else{
                console.log(error)
              }
          })
        }
      }
    }
  }
  removeDropNodeSelection = () => {
    const allNodes = document.getElementsByClassName('list-group list-group-horizontal drop-active')
    for (let i = 0; i < allNodes.length; i++) {
      allNodes[i].classList.remove('drop-active')
    }

    // const allItems = document.getElementsByClassName('content-file drag-select')
    for (let i = 0; i < allNodes.length; i++) {
      allNodes[i].classList.remove('drag-select')
    }
  }
  markDropNode = (event) => {
    this.removeDropNodeSelection()
    event.currentTarget.classList.add('drop-active')
    event.preventDefault()
  }
  onNumberOfFiles(item) {
    this.props.content && this.props.content.length > 0 && this.props.content.forEach((item2) => {

    })
    if (item) {
      if (this.props.content.length > 0) {
        const match = this.props.content.filter(el => el.path === item.pathFiles)
        if (match && match.length > 0) {
          return match[0].numberOfFiles
        }
      }
    }
    //return 0
  }
  onStatusBoxEmpty = (item) => {
    if (item) {
      if (this.props.smartBoxFolderCountTree.length > 0) {
        const match = this.props.smartBoxFolderCountTree.filter(el => el.path === item.pathFiles)
        if (match && match.length > 0) {
          return match[0].numberOfFiles
        }
      }
    }
  }
  onStatusFolderEmpty = (item) => {
    if (item) {
      if (this.props.smartFolderCountTree.length > 0) {
        const match = this.props.smartFolderCountTree.filter(el => el.path === item.pathFiles)
        if (match && match.length > 0) {
          return match[0].numberOfFiles
        }
      }
    }
  }
  renderTheme = (oldNode) => {
    const { node, path, treeIndex } = oldNode
    this.onStatusBoxEmpty(node)
    this.onStatusFolderEmpty(node)
    const lefTreeItemMenu = (
        <Menu className='left-tree-item-menu'>
          <i className="arrow-up-dropdown"></i>
          { path.length === 1 && [
            <Menu.Item key={'menu-item-1'} onClick={() => this.menuItemAction('analysis-settings', node.idPath, node.title)}
                       disabled={ node.statisticsReady === 'false' && node.isEmpty === false }>
              {
                node.statisticsReady === 'false' && node.isEmpty === false ?
                    <Tooltip title="Generating stats. Please wait.">
                      <span>Box settings</span>
                    </Tooltip> :
                    <span>Box settings</span>
              }
            </Menu.Item>,
            <Menu.Item key={'menu-item-2'} onClick={() => this.menuItemAction('data-insights', node.idPath, node.title)}
                       disabled={ node.statisticsReady === 'false' || this.onStatusCountFilesBox(node) === 0}
            >
              { (node.statisticsReady === 'false' || this.onStatusCountFilesBox(node) === 0) ?
                  <Tooltip title="Generating stats. Please wait.">
                    <span>Report</span>
                  </Tooltip> :
                  <span>Report</span>
              }
            </Menu.Item>,
            <Menu.Item key={'menu-item-3'} onClick={() => this.menuItemAction('folder-redaction', node.idPath, node.title)}
                       disabled={ node.statisticsReady === 'false' || this.onStatusCountFilesBox(node) === 0 }>
              { node.statisticsReady === 'false' || this.onStatusCountFilesBox(node) === 0 ?
                  <Tooltip title="Generating stats. Please wait.">
                    <span>Redaction</span>
                  </Tooltip> :
                  <span>Redaction</span>
              }
            </Menu.Item>,
            <Menu.Item key={'menu-item-15'} onClick={() => this.menuItemAction('cull', node.idPath, node.title)}
                       disabled={ node.statisticsReady === 'false' || this.onStatusCountFilesBox(node) === 0 }>
              { node.statisticsReady === 'false' || this.onStatusCountFilesBox(node) === 0 ?
                  <Tooltip title="Generating stats. Please wait.">
                    <span>Cull</span>
                  </Tooltip> :
                  <span>Cull</span>
              }
            </Menu.Item>,
            <Menu.Item key={'menu-item-4'} onClick={ () => this.menuItemAction('download-finalised', node.idPath, node.title) }>
              <span>Export</span>
            </Menu.Item>,
            <Menu.Item key={'menu-item-5'} className="separator" disabled>
              <span>&nbsp;</span>
            </Menu.Item> ]
          }
          <Menu.Item key={'menu-item-6'} onClick={ () => { if(this._isMounted) { this.setState({ renameFileId: node.idPath }) }}}>
            <span>Rename</span>
          </Menu.Item>
          <Menu.Item key={'menu-item-7'} onClick={ () => this.deleteFolder(node, path, treeIndex) }>
            <span>Delete</span>
          </Menu.Item>
          <Menu.Item key={'menu-item-8'} onClick={ () => this.menuItemAction('note', node.idPath, node.title) }>
            <span>Add a note</span>
          </Menu.Item>

          {/*<Menu.Item onClick={ () => this.menuItemAction('create-redacted', node.idPath, node.title) }>*/}
          {/*  <span>Create Redacted Version</span>*/}
          {/*</Menu.Item>*/}

          <Menu.Item key={'menu-item-10'} className="separator" disabled>
            <span>&nbsp;</span>
          </Menu.Item>
          { path.length === 1 ?
              <Menu.Item key={'menu-item-11'} onClick={ () => this.menuItemAction('folder-info', node.idPath, node.title) }>
                <span>Box Info</span>
              </Menu.Item> :
              <Menu.Item key={'menu-item-11'} onClick={ () => this.menuItemAction('folder-info', node.idPath, node.title) }>
                <span>Folder Info</span>
              </Menu.Item>
          }
          <Menu.Item onClick={ () => this.menuItemAction('security-settings', node.idPath, node.title) }>
            <span>Security Settings</span>
          </Menu.Item>
        </Menu>
    )
    const hasProcessingErrors = this.getFilesErrors(node)
    let imgIconSrc = node && node.type === 'DIRECTORY' && (this.onStatusCountFilesBox(node) > 0 || this.onStatusBoxEmpty(node) > 0) ? imgFolderNewParent : node && node.type === 'DIRECTORY' && (this.onStatusCountFilesBox() === 0 || typeof this.onStatusCountFilesBox() === 'undefined') ? imgFolderNewParentEmpty :
        node && node.type === 'DIRECTORY' && hasProcessingErrors ? imgFolderNewParentProcessing : imgFolderNewParent
    let innerNode = false
    if (node.pathRelative) {
      if (node.children.length) {
        imgIconSrc = imgFolderOpen
        innerNode = true
      } else {
        // imgIconSrc = imgFolderClose
        imgIconSrc = node && node.type === 'DIRECTORY' && this.onStatusFolderEmpty(node) === 0 ? imgFolderEmpty : imgFolder
        innerNode = true
      }
    } else if (node.children.length && node.expanded && !hasProcessingErrors) {
      imgIconSrc = imgFolderNewParent
    }
    let depth = 0
    let width = 280
    if (oldNode.node.pathRelative) {
      depth = extractDepthForTree(oldNode.node.pathRelative)
      if (depth) {
        width = width - 40
        width = width - 15 * depth
      }
    }
    const indexNodeTitle = node && node.title ? node.title.indexOf('.') : ''
    const nameNodeTitle = node && node.title ? node.title.substr(0, indexNodeTitle) : ''
    const { renameFileId, validEditFolderName } = this.state
    return ({
      // onClick: event =>this.fetchFolderInfo(event,node.idPath,node,this.state.treeData),
      title: (
          <div className="list-group list-group-horizontal" style={{ flexDirection: 'row' }}
               onDrop={ (event) => this.dropFileFromOutside(event, node.idPath) }
               onDragOver={ (event) => this.markDropNode(event) } >
            <div className={`list-group-item`+(this.props.smartBoxCurrentFolder === node.idPath ? ' active' : '') } style={{ width: width }}>
              { innerNode === false &&
              <img src={ imgIconSrc } alt={ node.title } className={ /*node.children.length && node.expanded && !node.hasProcessingErrors ?*/
                `tree-icon ${ renameFileId && renameFileId === node.idPath ? 'icon-before-rename' : '' }` /*:
                node.hasProcessingErrors ?
                  `tree-icon ${ renameFileId && renameFileId === node.idPath ? 'icon-before-rename' : '' } tree-icon-error` :
                  `tree-icon ${ renameFileId && renameFileId === node.idPath ? 'icon-before-rename' : '' } tree-icon-close`*/ }
                   onClick={ (event) => this.fetchFolderInfo(node.idPath, oldNode, null, depth)}/>
              }
              { innerNode === true && !this.canNodeBeMoved(node) &&
              <Tooltip title="Folder cannot be moved, it is in bulk processing"><div className={ imgIconSrc }></div></Tooltip>
              }
              <EnhancedClickableNode
                  node={node}
                  oldNode={oldNode}
                  width={ node.pathRelative === null ? width - 70 : width - 35 }
                  currentFolder={this.props.smartBoxCurrentFolder}
                  treeData = {this.props.smartBoxTree}
                  onClick={ (event) => this.fetchFolderInfo(node.idPath, oldNode, null, depth)}
                  onDoubleClick={ (event) => this.closeTreeNode(event, node.idPath, oldNode) }
                  renameFileId={ renameFileId }
                  filesErrors={ this.getFilesErrors(node)}
                  nameNodeTitle={ nameNodeTitle }
              />
              {
                renameFileId && renameFileId === node.idPath &&
                <span className='content-file__name tree-rename' onKeyPress={ (e) => this.handleNewRenameFolderPressed(e, node.title, node.idPath) } key={ 20 }>
                  <Input placeholder={ node.title } className={ validEditFolderName.success === false ? 'rename-folder-tree-input invalid' : 'rename-folder-tree-input' } />
                </span>
              }
              <Dropdown placement="bottomCenter" overlay={ lefTreeItemMenu } trigger={['click']}
                        onVisibleChange={ (visible) => this.setState({ isRowMenuVisible: visible })}>
              <span className={ this.props.smartBoxCurrentFolder === node.idPath || this.state.hoverRowMenuId === node.idPath ? 'icon-v14-options-small-active' : 'icon-v14-options-small' }
                    
                    onMouseLeave={ () => { if(!this.state.isRowMenuVisible) this.setState({ hoverRowMenuId: '' })}}></span>
              </Dropdown>
            </div>
          </div>
      ),
      className: 'depth-'+depth+' '+
          (this.props.smartBoxCurrentFolder === node.idPath ? 'active ': '')+
          (node && node.children.length && node.expanded ? 'has-children' : this.onStatusFolderEmpty(node) === 0 ? 'has-empty' : '')
    })
  }
  canNodeBeMoved(node) {
    
    return node.pathRelative !== null && !(node.bulkProcessingStarted && node.bulkProcessingStarted === 'started')
  }
  canDragOnTree = ({ node }) => {

    return this.canNodeBeMoved(node)
  }

  canDropOnTree = ({ node, prevPath, prevParent, prevTreeIndex, nextPath, nextParent, nextTreeIndex }) => {
    if (nextParent === null) return false

    const nodeRoot = node.pathRelative.substr(0, node.pathRelative.indexOf('/'))
    const destRoot = nextParent.pathRelative ? nextParent.pathRelative.substr(0, nextParent.pathRelative.indexOf('/')) : nextParent.title

    if (nodeRoot !== destRoot) return false

    return true
  }
  moveNodeOnTree = ({ treeData, node, nextParentNode, prevPath, prevTreeIndex, nextPath, nextTreeIndex }) => {
    let { folderMovesInTree } = this.state
    this.setState({folderMovesInTree:  folderMovesInTree + 1})

    let objFind = find({
      getNodeKey: ({ treeIndex }) => treeIndex,
      treeData: this.props.smartBoxTree,
      searchQuery: nextParentNode.idPath,
      searchMethod: this.searchNodeById,
      searchFocusOffset: 0,
      expandAllMatchPaths: false,
      expandFocusMatchPaths: false,
    })
    let rightPath = objFind?.matches[0].node.pathRelative +'/'+ nextParentNode.title
    rightPath = rightPath.split('/')
    rightPath[0] = nextParentNode?.pathRelative?.split('/')[0]
    rightPath = rightPath.join('/')
    if(rightPath[0] === '/'){
      rightPath = rightPath.slice(1)
    }
    const MoveFileDTO = {
      destinationRelativePath: rightPath,
      id: [node.idPath],
    }
   
    apiSmartBoxMoveFiles(MoveFileDTO).then((res) => {
      if(res.data.title.toLowerCase() === 'success'){
        CustomNotificationManager.success(res.data.message, 'Success')
        // more than 2 moves redirect
          if(this.state.folderMovesInTree >= 2){
            setTimeout(() => {
              this.props.navigate('/smart-box/management', {state: {message: 'Moving more than three folders'}}) 
            }, 1000);
          }
      }else if(res.data.title.toLowerCase() === 'info'){
        CustomNotificationManager.info(res.data.message, 'Info')
          // more than 2 moves redirect
          if(this.state.folderMovesInTree >= 2){
            setTimeout(() => {
              this.props.navigate('/smart-box/management', {state: {message: 'Moving more than three folders'}}) 
            }, 1000);
          }
      }else{
        return CustomNotificationManager.warning(res.data.message, 'Warning')
      } 
    }).catch((e) => {
      console.log(e)
      CustomNotificationManager.warning('Error occured', 'Info')
    })
  }

  // redirectToHome = () => window.location.href = '/smart-box/management'
  onCloseCollectData = (status) => {
    this.setState({
      visibleCollectData: status,
    })
  }
  
  
  render() {
    const { folder, refreshData, smartBoxTree, leftNavigationTreeShow } = this.props
    const { visiblePageModalDownload, fileSelectDownload, visibleCollectData } = this.state
    return (
        <div className={ leftNavigationTreeShow ? "leftNavigation media-screen-left-navigation" : "leftNavigationCollapsed"}>
          <span className="collapseWrapper">
            <button className='icon-v16-arrow-medium' onClick={() => this.props.setLeftNavigationTreeShow(!this.props.leftNavigationTreeShow)}></button>
          </span>
          <div className="smart-box-folder-opened__breadcrumb" style={{ padding: '0px 0px' }}>
          <span className="smart-box-folder-opened__breadcrumb--bold" style={{ marginTop: '0px' }}>
            { folder && this.generateSmartBoxMenu('') }
            {/*<span className='link-to-home' onClick={() => this.redirectToHome()}><span className="icon-v11-home"></span>Home</span>*/}
          </span>
          </div>
          <Spin className={`custom-spin ${!this.props.leftNavigationTreeShow}`} tip="Loading..." spinning={ refreshData }>
            <SortableTree
                // rowHeight={"auto"}
                treeData={ smartBoxTree ? smartBoxTree : []}
                isVirtualized={false}
                canDrag={this.canDragOnTree}
                canDrop={this.canDropOnTree}
                onMoveNode={this.moveNodeOnTree}
                scaffoldBlockPxWidth={20}
                generateNodeProps={ (rowInfo) => this.renderTheme(rowInfo) }
                onChange={(treeData) => { this.props.setSmartBoxTree(treeData)}} 
                //theme={FileExplorerTheme}
            />
          </Spin>
          {
            (visiblePageModalDownload && visibleCollectData) &&
            <DownloadBoxModalOptionFiles
                file={ fileSelectDownload }
                onCancelModalDownload = { this.onCancelModalDownload }
                onCloseCollectData = { this.onCloseCollectData }
            />
          }
        </div>
    )
  }
}
LeftFoldersNavigation.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.array
  ]).isRequired,
  folder: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.object
  ]),
  paths: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.object
  ]),
  smartBoxMenuOpen: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.object
  ])
}

LeftFoldersNavigation = withRouter(LeftFoldersNavigation)
const mapStateToProps = (state) => ({
  smartBoxTree: state.smartBox.smartBoxTree,
  smartBoxCurrentFolder: state.smartBox.smartBoxCurrentFolder,
  smartBoxLoadTree: state.smartBox.smartBoxLoadTree,
  smartBoxNewNode: state.smartBox.smartBoxNewNode,
  smartBoxSelectedFiles: state.smartBox.smartBoxSelectedFiles,
  smartBoxDeletedFolder: state.smartBox.smartBoxDeletedFolder,
  smartBoxPreviewToggleFile: state.smartBox.smartBoxPreviewToggleFile,
  smartBoxRenamedFolder: state.smartBox.smartBoxRenamedFolder,
  smartBoxFoldersCheckErrors: state.smartBox.smartBoxFoldersCheckErrors,
  smartBoxMovedFolders: state.smartBox.smartBoxMovedFolders,
  smartBoxOldParentOfMovedFolders: state.smartBox.smartBoxOldParentOfMovedFolders,
  smartBoxNewParentOfMovedFolders: state.smartBox.smartBoxNewParentOfMovedFolders,
  smartBoxCurrentSortOption: state.smartBox.smartBoxCurrentSortOption,
  smartBoxFolderCountTree: state.smartBox.smartBoxFolderCountTree,
  smartFolderCountTree: state.smartBox.smartFolderCountTree,
  smartBoxCurrentFolderName: state.smartBox.smartBoxCurrentFolderName,
  leftNavigationTreeShow: state.smartBox.leftNavigationTreeShow
})

export default compose(
    connect(mapStateToProps, {
      setSmartBoxTree,
      setSmartBoxCurrentFolder,
      setSmartBoxLoadTree,
      setSmartBoxFolderClosed,
      setSmartBoxMovedFiles,
      setSmartBoxDeletedFolder,
      setSmartBoxToggleFilePreview,
      setSmartBoxDirectoryNote,
      setSmartBoxSelectedDocuments,
      setSmartBoxRenamedFolder,
      setSmartBoxFoldersCheckErrors,
      setSmartBoxSelectedDuplicateFile,
      setSmartBoxSecurityConfiguration,
      setSmartBoxTreeCurrentDepth,
      setSmartBoxScrollPositionObject,
      setSmartBoxFilterRefreshOpenedBox,
      setSmartBoxCurrentFolderName,
      setLeftNavigationTreeShow,
      setSmartBoxNewParentOfMovedFolders,
      setSmartBoxOldParentOfMovedFolders
    }),
)(LeftFoldersNavigation)
// export default LeftFoldersNavigation