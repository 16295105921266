import React, { Component } from 'react'
import { Outlet } from 'react-router-dom'
import { apiDpiaFetchToken } from '../../../api/Dpia'
import withRouter from '../../../private-routers/withRouter'
import Loading from '../../Common/Loading'
import './Shell.scss'

export class Shell extends Component {
  constructor() {
    super()

    this.state = {
      isFetching: true,
      shouldShowBetaMessage: false
    }

    this.dismissBetaMessage = this.dismissBetaMessage.bind(this)
  }
  
  componentDidMount() {
    if (this.props.location.pathname!== '/osp/login') {
      this.fetchDpiaToken()
    }

    const betaDismissed = localStorage.getItem('betaDismissed')

    if (!betaDismissed) {
      this.setState({
        shouldShowBetaMessage: true
      })
    }
  }

  fetchDpiaToken() {
    apiDpiaFetchToken()
      .then((response) => {
        if (response.data.access_token) {
          localStorage.setItem('dpiaToken', response.data.access_token)
          this.setState({
            isFetching: false
          })
        }
      })
  }

  dismissBetaMessage() {
    localStorage.setItem('betaDismissed', true)
    this.setState({
      shouldShowBetaMessage: false
    })
  }

  render() {
    const { isFetching } = this.state

    return (
      <div>
        { isFetching &&
          <div className="dpiaShell-loading">
            <Loading />
          </div>
        }
        {/*{ shouldShowBetaMessage &&*/}
        {/*  <BetaMessage onClick={ this.dismissBetaMessage } />*/}
        {/*}*/}
        <Outlet/>
      </div>
    )
  }
}

export default withRouter(Shell)
