import React, { Component } from 'react'
import { Link, Navigate } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import CustomNotificationManager from '../Common/CustomNotificationManager'
import { apiUserPasswordRecovery } from '../../api/User'
import * as actions from '../../actions/messageActions'
import Loading from '../Common/Loading'
import Message from '../Common/Message'
import { emailRegex } from '../../utils'
import '../../style/common.scss'
import './PasswordRecovery.css'
import './LoginUpdate.scss'
import Smartbox_Logo_neg from '../../assets/Smartbox_Logo_neg.svg'

export class PasswordRecovery extends Component {
  constructor() {
    super()
    this.state = {
      emailAddress: '',
      isSending: false
    }
  }

  componentWillUnmount() {
    this.props.hideMessage()
  }

  handleSubmit(e) {
    e.preventDefault()

    if (!this.isFormValid()) return
    this.doRequest()
  }

  isFormValid() {
    const { emailAddress } = this.state

    // Validates if email has a valid format.
    if (!emailRegex.test(emailAddress)) {
      CustomNotificationManager.error('Your email does not have a valid format.', 'Error')
      return false
    }

    return true
  }

  doRequest() {
    this.setState({
      isSending: true
    })
    this.props.hideMessage()

    const data = {
      email: this.state.emailAddress
    }
    
    apiUserPasswordRecovery(data)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            isSending: false
          })
          CustomNotificationManager.success('Success! Verify your email to set your password.', 'Success')
        }
      }).catch((error) => {
          if (error?.response?.data?.error && error?.response?.data?.error.includes('User with email does not exist')) {
            CustomNotificationManager.error('The user with this email does not exist.', 'Does not exist')
          }else{
            console.log(error)
          }
        this.setState({
          isSending: false
        })
      })
  }

  updateValue(e) {
    this.setState({
      emailAddress: e.target.value
    })
  }

  render() {
    const { emailAddress, isSending } = this.state
    const { isLogged, messageArea } = this.props

    return (
      <div className="passwordRecovery-page">
        {
          isLogged &&
          <Navigate to="/smart-box/management" />
        }
        <div className="new-content-recovery-page">
          { !isLogged &&
          <div className="passwordRecovery-header">
            <img className="logo-osprey-header" src={ Smartbox_Logo_neg } alt="Osprey" />
          </div>
          }
          { !isLogged &&
          <div className="passwordRecovery-box">
            <h1>Forgot your password</h1>
            <p>Confirm your email address and we will send you a link to reset your password</p>
            <form className="passwordRecovery-form">
              { messageArea.visible &&
              <div className="passwordRecovery-message">
                <Message
                  hideMessageAction={ this.props.hideMessage }
                  status={ messageArea.status }
                  text={ messageArea.text }/>
              </div>
              }

              <div className="passwordRecovery-container">
                <div className="passwordRecovery-formGroup">
                  <label htmlFor="email">Email Address</label>
                  <input
                    id="email"
                    type="text"
                    onChange={ this.updateValue.bind(this) }/>
                </div>
              </div>

              <div className="passwordRecovery-footer">
                { !isSending &&
                <div className="passwordRecovery-footerForm">
                  <input
                    disabled={ emailAddress === '' }
                    type="submit"
                    value="Recover"
                    className="passwordRecovery-btn recover-button"
                    onClick={ this.handleSubmit.bind(this) }/>
                </div>
                }
                <div className="passwordRecovery-link">
                  <Link
                    id="login-link"
                    to="/osp/login"><i className="icon-v11-arrow-medium"></i>Back to login</Link>
                </div>

                { isSending &&
                <Loading/>
                }
              </div>
            </form>
          </div>
          }
        </div>
      </div>
    )
  }
}

const stateMap = (state) => ({
  messageArea: state.messageAreaReducer.messageArea
})

const dispatchMap = (dispatch) => ({
  hideMessage: () => dispatch(actions.hideMessage()),
  showMessage: (status, text) => dispatch(actions.showMessage({ status, text }))
})

PasswordRecovery.propTypes = {
  hideMessage: PropTypes.func.isRequired,
  messageArea: PropTypes.object.isRequired,
  showMessage: PropTypes.func.isRequired
}

export default compose(
  connect(stateMap, dispatchMap)
)(PasswordRecovery)
