import PropTypes from 'prop-types'
import React from 'react'
import { questionTypes, riskIndicatorsStaticList } from '../../../../utils/StaticTexts'
import './QuestionnaireQuestionsFilters.scss'

class QuestionnaireQuestionsFilters extends React.Component {
  state = {
    expand: 'all',
    byTerm: '',
    byType: '',
    byRisk: '',
    byScore: '',
  }

  actionClicked = (e) => {
    this.props.filterQuestions({ ...this.state, action: e.target.value })
  }

  selectAllClicked = (value) => {
    this.props.filterQuestions({ ...this.state, select: value })
  }

  expandClicked = () => {
    this.setState({
      expand: this.state.expand === 'all' ? 'none' : 'all'
    }, () => {
      this.props.filterQuestions(this.state)
    })
  }

  updateFilter = (e, field) => {
    this.setState({
      [field]: e.target.value
    }, () => {
      this.props.filterQuestions(this.state)
    })
  }

  render = () => {
    return (
      <div className="newQuestionnaireFilter__wrapper">
        <div className="newQuestionnaireFilter__button">
          <label className="newQuestionnaireFilter__labelCentered">Expand</label>
          <div className="newQuestionnaireFilter__buttonWrapper" onClick={ this.expandClicked }>
            { this.state.expand === 'none' &&
              <span className="iconExpand"></span>
            }

            { this.state.expand === 'all' &&
              <span className="iconExpandClose"></span>
            }
          </div>
        </div>

        <div className="newQuestionnaireFilter__button">
          <label className="newQuestionnaireFilter__labelCentered">Find</label>
          <input
            type='text'
            className="newquestionnaire-filter__input-find"
            onChange={ (e) => { this.updateFilter(e, 'byTerm') }}>
          </input>
        </div>

        <div className="newQuestionnaireFilter__select">
          <label>Question Type</label>
          <select
            onChange={(e) => { this.updateFilter(e, 'byType') }}>>
            <option value="">View all</option>
            {
              questionTypes.map((questionType, index) => (
                <option key={`filters-questionType${ index }`} value={ questionType.value }>
                  { questionType.label }
                </option>
              ))
            }
          </select>
        </div>

        <div className="newQuestionnaireFilter__select">
          <label>Risk Indicator</label>
          <select
            onChange={ (e) => { this.updateFilter(e, 'byRisk') } }>>
            <option value="">View all</option>
            {
              riskIndicatorsStaticList.map((risk, index) => (
                <option key={ `filters-${ index }` } value={ risk.value }>{ risk.label }</option>
              ))
            }
          </select>
        </div>

        <div className="newQuestionnaireFilter__select">
          <div className="newQuestionnaireFilter__selectActions">
            <label onClick={ () => { this.selectAllClicked('all') } }>Select All</label>
            <span> - </span>
            <label onClick={ () => { this.selectAllClicked('none') } }>Select None</label>
          </div>
          
          <select value="" onChange={ (e) => { this.actionClicked(e) } }>
            <option value="">--Actions--</option>
            <option value="deleteSelected">Delete selected</option>
          </select>
        </div>
      </div>
    )
  }
}

QuestionnaireQuestionsFilters.propTypes = {
  filterQuestions: PropTypes.func.isRequired
}

export default QuestionnaireQuestionsFilters