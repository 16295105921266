import React from 'react'
import PropTypes from 'prop-types'

import './Progress.scss'

const Progress = ({ activeQuestion, allQuestions, progress, questionsResults, onClick }) => (
  <div className="progress-wrapper">
    <span className="progress-value">{ progress }% completed</span>

    <nav className="progress-navigation">
      <ul className="progress-list">
        { allQuestions.map((item) => (
          <li
            className={ getClassName(item.state, item.id, activeQuestion, questionsResults) }
            key={ item.id }
            title={ `Question ${ item.id }` }
            onClick={ onClick.bind(this, item.id) }>
            Question { item.title }
          </li>
        ))
        }
      </ul>
    </nav>
  </div>
)

const getClassName = (state, id, activeQuestion, questionsResults) => {
  // Checks if the current item of list is the active question.
  // This is used to change the style of the circle item on list.
  if (activeQuestion && activeQuestion.id === id) {
    return 'progress-activeStatus'
  }

  const answered = questionsResults.SUBMITTED.find((question) => (question.id === id))
  const skippedByUser = questionsResults.USER_SKIPPED.find((question) => (question.id === id))
  const skipped = questionsResults.QUESTIONNAIRE_SKIPPED.find((question) => (question.id === id))

  if (skipped) return 'progress-notapplicableStatus'
  if (skippedByUser) return 'progress-skippedStatus'
  if (answered) return 'progress-answeredStatus'

  return 'progress-unansweredStatus'
}

Progress.propTypes = {
  activeQuestion: PropTypes.object,
  allQuestions: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  progress: PropTypes.number.isRequired
}

export default Progress