import React from 'react'
import {Checkbox, Icon, Spin, Switch, Tooltip} from 'antd'
import { postCheckRespond } from '../../../../api/Sar'
import CustomNotificationManager from '../../../Common/CustomNotificationManager'
import Loading from '../../../Common/Loading'
import imgFolderSmallParent from '../../../../assets/smart-folder-medium-parent.svg'

//const RadioGroup = Radio.Group

class RespondStep extends React.Component {

  constructor() {
    super()

    this.state = {
      data: {
        sarId: '',
        respondAnswer: false,
        sendEmailNotification: true
      },
      loading: false,
      valueSelectedBox: '',
    }
    this.checkRespond = this.checkRespond.bind(this)
  }
    onChangeRespond = (e) => {
      this.setState({ data: { ...this.state.data, respondAnswer: e.target.value, sarId: Number(this.props.sar.id) } })
    }
  onChangeRespondRight = (respondAnswer) => {
    this.setState({ data: { ...this.state.data, respondAnswer: respondAnswer, sarId: Number(this.props.sar.id) } })
  }
  onChangeNotification = () => {
    this.setState({
      data: { ...this.state.data, sendEmailNotification: !this.state.data.sendEmailNotification }
    })
  }
  
  componentDidMount() {
    if (this.props.sar) {
      this.setState({ data: { ...this.state.data, sarId: Number(this.props.sar.id) } })
    }
  }
  checkRespond() {
    this.setState({
      loading: true
    }, () => {
      postCheckRespond(this.state.data)
        .then((response) => {
          this.props.refreshData(response.data)
          response.data.sarSteps.forEach((data) => {
            if (data.status === 'RESPOND') {
              if (data.completed) {
                this.setState({
                  loading: false
                })
                CustomNotificationManager.success('Respond step successful', 'Success')
              } else {
                CustomNotificationManager.success('Requested documents to confirm identity', 'Success')
              }
            }
          })
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
      })
    })
  }
  onClickNextStep = () => {
    const { current, searchFindStep, sar } = this.props
    if (!searchFindStep && sar && sar.lastStep === 'COMPLETE') {
      this.props.nextStep(current + 3)
    } else if (!searchFindStep) {
      this.props.nextStep(current)
    } else {
      this.props.nextStep(current + 1)
    }
  }
  onClickBackStep = () => {
    const { current } = this.props
    this.props.beckStep(current - 1)
  }
  render() {
    const { respondStep, checkIdStep, sar, valueAttachBox, searchFindStep } = this.props
    let { respondAnswer } = this.state.data
    const { sendEmailNotification } = this.state.data
    const { loading, valueSelectedBox } = this.state
    const lastSarStepCompleted = checkIdStep ? checkIdStep.completed : false
    if (respondStep && respondStep.completed) {
      respondAnswer = respondStep.respondAnswer
    }
    return (
      <React.Fragment>
        <div className="tracker-header-steps">
          <div className="contain-name-back-step">
            <div className="back-step back-step-hover">
              <span className="icon-v10-back" onClick={this.onClickBackStep}></span>
            </div>
            <div className="tracker-fullname-contain">
              <span className="tracker-surname">{`${ sar.forename } ${ sar.surname }`}</span>
            </div>
          </div>
          <div className="attach-box-contain">
            { (valueAttachBox || this.props.sar.boxName) ?
              <img alt={ valueSelectedBox } src={ imgFolderSmallParent }/>:
              <span className="icon-v10-smart-folder-medium-parent-2"></span>
            }
            <span className="attach-box disabled-attach-box">
              { valueAttachBox ? valueAttachBox : this.props.sar.boxName ? this.props.sar.boxName : 'Attach a box' }
            </span>
          </div>
          <div className="contain-save-last-step">
            <div className="save-close-contain">
              <span className="icon-v10-save"></span>
              <span className="save-and-close">
             Save & Close
              </span>
            </div>
            <div className={ !searchFindStep && sar && sar.lastStep !== 'COMPLETE' ? 'next-step next-step-disabled' : 'next-step next-step-hover' }>
              <span className="icon-v10-forth" onClick={this.onClickNextStep}></span>
            </div>
          </div>
        </div>
        {/*<div className="tracker-surname-contain">*/}
        {/*  <span className="tracker-surname">{`${ sar.forename } ${ sar.surname }`}</span>*/}
        {/*</div>*/}
        <div className="tracker-box tracker-box-rights" style={{ marginTop: 0 }}>
          <div className="tracker-form">
            <header className="tracker-step-header">
              <div className="tracker-step-header__top">
                <h1 className="tracker-step-header__top__step">Rights<Tooltip overlayClassName="help-message-locations" title={<div className="content-help-message-locations">
                  <span className="text-information">Confirm the subject’s right to a request.</span>
                  <span className="more-info-link" onClick={ () => window.open('https://support.smartbox.ai/support/solutions/articles/47001174700', '_blank') }> More info. </span>
                </div>}>
                  <span className="icon-v14-question"></span>
                </Tooltip></h1>
              </div>
            </header>
            {lastSarStepCompleted && respondStep &&
            <React.Fragment>
              <div className="Radio">
                <h3>Is this person legally entitled to a Subject Access Request from your business?</h3>

                {/*<div className="tracker-radio">*/}
                {/*  <RadioGroup value={respondAnswer} onChange={this.onChangeRespond}>*/}
                {/*    <span className="custom-radio__yes">*/}
                {/*      <Radio value={true}>Yes</Radio>*/}
                {/*    </span>*/}
                {/*    <span className="custom-radio__no">*/}
                {/*      <Radio value={false}>No</Radio>*/}
                {/*    </span>*/}
                {/*  </RadioGroup>*/}
                {/*</div>*/}
                <div className="switcher-option-check">
                  <Switch
                    checked={ respondAnswer }
                    checkedChildren="YES"
                    unCheckedChildren="NO"
                    disabled={respondStep.completed}
                    onChange={ () => this.onChangeRespondRight(!respondAnswer) }
                  />
                </div>

              </div>
              {respondStep && !respondStep.completed &&
              <div className="tracker-step-content">
                { respondAnswer &&
                <div className="tracker-content-links full_width">
                  <div className="tracker-form-link">
                    <Checkbox defaultChecked={ sendEmailNotification } className="send-form" onChange={ this.onChangeNotification }>Send Email
                      Notification</Checkbox>
                  </div>
                </div>
                }
                { respondAnswer ?
                  <div className="tracker-content-button tracker-content-button-right">
                    {
                      loading ? <div className={'button-loading-wrapper'}> <Spin className="float-right" spinning={ loading }
                                            indicator={ <Icon type="loading" style={{ fontSize: 20, color:"#FFFFFF"}} spin /> }/>
                            <button className={'button-blue-light button-check-id float-right button-check-id-loading'}>CONFIRM RIGHTS</button>
                      </div> :
                        <button
                          className={ respondAnswer === undefined ? 'button-blue-disabled float-right' : 'button-blue-light button-check-id float-right' }
                          disabled={ respondAnswer === undefined }
                          onClick={ this.checkRespond }>CONFIRM RIGHTS
                        </button>
                    }
                  </div> :
                  <div className="tracker-content-button tracker-content-button-right">
                    {
                      loading ? <Spin className="float-right" spinning={ loading }
                        indicator={ <div className="checkout-loading"><Loading/></div> }/> :
                        <button
                          className={ respondAnswer === undefined ? 'button-blue-disabled float-right' : 'button-end-request button-check-id float-right' }
                          disabled={ respondAnswer === undefined }
                          onClick={ this.checkRespond }>END REQUEST
                        </button>
                    }
                  </div>
                }
              </div>
              }
            </React.Fragment>
            }
          </div>
        </div>
      </React.Fragment>
    )
  }

}

export default RespondStep