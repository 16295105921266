// import PropTypes from 'prop-types'
import React, { Component } from 'react'
import './Shell.scss'
import { apiDpiaFetchToken } from '../../../api/Dpia'
import { Outlet } from 'react-router-dom'
import withRouter from '../../../private-routers/withRouter'

export class AdminManagementShell extends Component {
  componentDidMount() {
    if (this.props.location.pathname !== '/osp/login') {
      this.fetchDpiaToken()
    }

    const betaDismissed = localStorage.getItem('betaDismissed')

    if (!betaDismissed) {
      this.setState({
        shouldShowBetaMessage: true
      })
    }
  }

  fetchDpiaToken() {
    apiDpiaFetchToken()
      .then((response) => {
        if (response.data.access_token) {
          localStorage.setItem('dpiaToken', response.data.access_token)
          this.setState({
            isFetching: false
          })
        }
      })
  }

  render() {

    return (
      <div>
        {this.props.children}
        <Outlet/>
      </div>
    )
  }
}


export default withRouter(AdminManagementShell)
