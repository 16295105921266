import React from 'react'
import PropTypes from 'prop-types'
import { customConfirm } from '../../../../utils/AssetRegister/CustomConfirm'
import { generateContentForField } from '../../../../utils/AssetRegister/AssetRegisterHelperMethods'

const Register = (props) => {
  const { register } = props
  const showConfirmationMessage = (register) => {
    customConfirm({
      confirmAction: () => props.deleteRegister(register),
      title: 'Do you want to delete',
      content: `${ register.name } ?`,
      confirmButtonText: 'DELETE',
      cancelButtonText: 'CANCEL',
      mask: true,
      maskClosable: false,
      closeHaveLogic: false,
      className: 'asset-register-modal',
      centered: true,
      cancelClassName: 'cancel-button',
      confirmClassName: 'confirm-button',
    })
  }

  return (
    <div className="register-card">
      <div className="register-card__name">{ register.name }</div>
      <div className="register-card__header">
        <div className="register-card__header__columns">
          {
            register.columns.map((column, key) => {
              return (
                <span key={ key }>
                  { column.name }
                </span>
              )
            })
          }
        </div>
        {
          !props.isImportExportActive ?
            <div className="register-card__header__actions">
              <span className="icon-edit-b" onClick={ () => props.editRegister(register) }/>
              <span className="icon-delete-b" onClick={ () => showConfirmationMessage(register) }/>
            </div> :
            <div className="register-card__header__actions">
              <span className="icon-download-b" onClick={ () => console.log('ciotka') }/>
            </div>
        }
      </div>
      { register.additionalFields &&
      <div className="register-card__content">
        {
          register.additionalFields.map((field, key) => {
            return (
              <div className="register-card__content__field" key={ key }>
                <div className="register-card__content__field__header">
                  <span>{ field.name }</span>
                  <span>{ field.required ? 'Required' : '' }</span>
                </div>
                <div className="register-card__content__field__content">
                  { generateContentForField(field) }
                </div>
              </div>
            )
          })
        }
      </div>
      }
    </div>
  )
}

Register.propTypes = {
  editRegister: PropTypes.func.isRequired,
  deleteRegister: PropTypes.func.isRequired,
  register: PropTypes.object.isRequired,
  isImportExportActive: PropTypes.bool.isRequired
}

export default Register