import PropTypes from 'prop-types'
import React from 'react'
import Button from '../../Common/Button'
import './LimitedFreeAccount.scss'

const LimitedFreeAccount = ({ onClick, plan }) => (

  <div className="dpiaHelp__box">
    <header className="dpiaHelp__box-header">
      <h2>Free Assessment Plan</h2>
    </header>
    <div className="limitedFreeAccount-container">
      <p className="limitedFreeAccount-text">
        You're currently on a free plan that allows you up to <br /><h3>{ businessProcessAmount(plan) }</h3>
      </p>
      <p className="limitedFreeAccount-text">
        If you require more please contact us below.
      </p>
      <div className="limitedFreeAccount-button">
        <Button onClick={ onClick }>
          Request a call back
        </Button>
      </div>
    </div>
  </div>

)

const businessProcessAmount = (plan) => {
  if (plan.upperlimit > 1) return `${ plan.upperlimit } active Business Processes.`
  return `${ plan.upperlimit } active Business Process.`
}

LimitedFreeAccount.propTypes = {
  onClick: PropTypes.func  
}

export default LimitedFreeAccount