import React from 'react'

// import Button from '../../Common/Button'

import imgLogoMailroom from '../../../assets/mailroom-logo-title.png'
import imgLogoPrivacy from '../../../assets/privacy-logo-title.png'

import './BoxAvailable.scss'

class BoxAvailable extends React.Component {
  render() {
    return (
      <div className="boxAvailable__box">
        <div className={ `boxAvailable__logo boxAvailable__logo--${ this.props.type }` }>
          { getImage(this.props.type) }
        </div>
        { this.props.children }
      </div>
    )
  }
}

const getImage = (type) => {
  if (type === 'privacy') return <img src={ imgLogoPrivacy } alt="Osprey Privacy" />
  if (type === 'mailroom') return <img src={ imgLogoMailroom } alt="Osprey Mailroom" />
}

export default BoxAvailable