import React from "react";
import {connect} from "react-redux";
import './ComponentsFormAdmin.scss';
import { Input, Checkbox} from "antd";

class FullNameFormAdmin extends React.Component {
    state = {
        fullNameForm: {
            firstName: '',
            lastName: '',
            queryForSearch: false,
            mandatoryCheck: false,
            options: null,
            header: '',
            sectionVariable: ''
        },
        hoverDelete: false,
        editHeader: false,
        hoverEditBtn:false,
    }
    componentDidMount() {
        const { component } = this.props
        this.setState({
            fullNameForm: { ...this.state.fullNameForm,
                queryForSearch: component.queryForSearch === undefined ? false : component.queryForSearch,
                mandatoryCheck: component.mandatory === undefined ? false : component.mandatory,
                options: component.options === undefined ? null : component.options,
                header: component.header === undefined ? 'Name' : component.header,
                sectionVariable: component.sectionVariable === undefined ? '' : component.sectionVariable,
            }
        })
        //this.props.onChangeForm(this.state.fullNameForm, this.props.component)
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props && this.props.checkStartSaved) {
            this.props.onChangeForm(this.state.fullNameForm, this.props.component)
        }
    }

    onChangeFullName = (e, key) => {
        switch (key) {
            case 'first_name':
                this.setState({
                    fullNameForm: { ...this.state.fullNameForm, firstName: e.target.value },
                })
                break
            case 'last_name':
                this.setState({
                    fullNameForm: { ...this.state.fullNameForm, lastName: e.target.value },
                })
                break
            default:
                break
        }
    }
    onChangeCheckbox = (e, key) => {
        switch (key) {
            case 'query':
                this.setState({
                    fullNameForm: { ...this.state.fullNameForm, queryForSearch: e.target.checked },
                }, () => {
                    this.props.onChangeForm(this.state.fullNameForm, this.props.component)
                })
                break
            case 'mandatory':
                this.setState({
                    fullNameForm: { ...this.state.fullNameForm, mandatoryCheck: e.target.checked },
                }, () => {
                    this.props.onChangeForm(this.state.fullNameForm, this.props.component)
                })
                break
            default:
                break
        }
    }
    onHoverActionComponent = () => {
        this.setState({
            hoverDelete: true
        })
    }
    onLeaveActionComponent = () => {
        this.setState({
            hoverDelete: false
        })
    }
    onHoverEditBtn = () => {
        this.setState({
            hoverEditBtn: true
        })
    }
    onLeaveEditBtn = () => {
        this.setState({
            hoverEditBtn: false
        })
    }
    onClickEditHeader = (e, status) => {
        if (status === 'header') {
            this.setState({
                editHeader: true
            })
        }
    }
    addTitleHeader = (e, status) => {
        if (status === 'header') {
            this.setState({
                fullNameForm: { ...this.state.fullNameForm, header: e.target.value },
                //editHeader: false
            }, () => {
                this.props.onChangeForm(this.state.fullNameForm, this.props.component)
            })
        }
    }
    onChangeFormVariable = (e) => {
        this.setState({
            fullNameForm: { ...this.state.fullNameForm, sectionVariable: e.target.value },
        }, () => {
            this.props.onChangeForm(this.state.fullNameForm, this.props.component)
        })
    }
    confirmEditHeader = () => {
        this.setState({
            editHeader:false,
        })
    }
    render() {
        const { fullNameForm, hoverDelete, editHeader, sectionVariable, hoverEditBtn } = this.state
        const { component } = this.props
        return (
            <div className={ this.props.snapshot.isDragging ? "component-form dragging " : "component-form"}>
                <div className="component-delete">
                    <span onClick={ (e) => this.props.showConfirmDeleteFormElement(e, component) }
                          className={hoverDelete ? 'icon-v19-delete-active' : "icon-v19-delete" }
                          onMouseEnter={ (e) => this.onHoverActionComponent(e)}
                          onMouseLeave={() => this.onLeaveActionComponent()}></span>
                </div>
                <div className="component-content">
                    <div className="content-input-form-variables">
                         <span className="header-checkbox">
                           {/*<Checkbox onChange={(e) => this.onChangeCheckbox(e, 'query')}*/}
                             {/*          checked={fullNameForm.queryForSearch}>Query for search</Checkbox>*/}
                             <Checkbox onChange={(e) => this.onChangeCheckbox(e, 'mandatory')}
                                       checked={fullNameForm.mandatoryCheck}>Mandatory</Checkbox>
                        </span>
                        {/*<span className="content-variables">*/}
                        {/*     <span className="variables-diaz">#</span>*/}
                        {/*<Input*/}
                        {/*    className="input-form-variables"*/}
                        {/*    placeholder="Form variable"*/}
                        {/*    value={fullNameForm.sectionVariable}*/}
                        {/*    onChange={(e) => this.onChangeFormVariable(e)}*/}
                        {/*/>*/}
                        {/*</span>*/}
                    </div>
                    <div className="header-component header-component-update">
                         <span className="header-content">
                              { editHeader ?
                                  <div>
                                      <Input defaultValue={fullNameForm.header} className="input-other-option"
                                             onChange={ (e) => this.addTitleHeader(e, 'header')}
                                             onPressEnter={() => this.confirmEditHeader()}
                                      />
                                      <span className={ hoverEditBtn ? "icon-v21-edit-active" : "icon-v21-edit"}
                                            onClick={(e) => this.confirmEditHeader(e, 'header')}
                                            onMouseEnter={() => this.onHoverEditBtn()}
                                            onMouseLeave={() => this.onLeaveEditBtn()}
                                      ></span>
                                  </div>
                                 :
                                  <div>
                                      <span className="header-title header-title-update">{fullNameForm.header}</span>
                                      <span className={ hoverEditBtn ? "icon-v21-edit-active" : "icon-v21-edit"}
                                            onClick={(e) => this.onClickEditHeader(e, 'header')}
                                            onMouseEnter={() => this.onHoverEditBtn()}
                                            onMouseLeave={() => this.onLeaveEditBtn()}
                                      ></span>
                                  </div>

                              }
                          </span>
                    </div>
                    <div className="input-header-component">
                        <span className="input-content">
                            <Input onChange={(e) => this.onChangeFullName(e, 'first_name')}
                                   value={ fullNameForm.firstName }
                                   disabled={true}
                                   className="input-form-name"/>
                            <span className="label-input">First name</span>
                        </span>
                        <span className="input-content">
                            <Input onChange={(e) => this.onChangeFullName(e, 'last_name')}
                                   value={ fullNameForm.lastName }
                                   disabled={true}
                                   className="input-form-name"/>
                            <span className="label-input">Last name</span>
                        </span>
                    </div>
                </div>
                <div className="component-move">
                    <span onMouseEnter={() => this.props.onStateProvidedDraggable(component)} className="icon-v211-grid-view-1"></span>
                </div>
            </div>
        )
    }
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(FullNameFormAdmin)