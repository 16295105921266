import React from 'react'
import PropTypes from 'prop-types'

import ConnectorItem from './ConnectorItem'

import './ConnectorList.css'

const ConnectorList = ({ connectors }) => (
  <div id="connector-list" className="connectorList__wrapper">
    { connectors.map((connector) => (
      <ConnectorItem key={ connector.id } connector={ connector } />
    )) }
  </div>
)

ConnectorList.propTypes = {
  connectors: PropTypes.array.isRequired
}

export default ConnectorList