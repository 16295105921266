import React from 'react'
import Pagination from '../../../../Privacy/Form/Pagination'
import { connect } from 'react-redux'

export const ManagerUsersPagination = ({ usersList, numberOfUsers, activePage, resultsPerPage, user= {}, changePagination, currentAccountIsPaid }) => {
  const defaultNumberOfUsers = 6
  const isPaid = (user.id && currentAccountIsPaid) ? true : false
  return (
    <div className="manageUsers-box">
      <React.Fragment>
        <div className="dpiaDashboard mt-4">
          <div className="pagination__box">
            <header className="pagination__box__header">
              <div>
                { usersList !== null && usersList.length > 0 &&
                  <span className="pagination__box__header__total">
                    {/*<strong>{ (isPaid) ? numberOfUsers : defaultNumberOfUsers }</strong> results*/}
                    {/*<strong>{ usersList && usersList.length > 0 ? usersList.length : 0 }</strong> results*/}
                    <strong>{ numberOfUsers }</strong> results
                  </span>
                }
                <p className="pagination__box__header__text">
                  Page { activePage } of { Math.ceil(numberOfUsers / resultsPerPage) }
                </p>
              </div>
              <Pagination
                activePage={ activePage }
                changePagination={ changePagination }
                pages={ Math.ceil(numberOfUsers / resultsPerPage) } />
            </header>
          </div>
        </div>
      </React.Fragment>
    </div>
  )
}

const stateMap = (state) => ({
  user: state.userReducer.user
})

export default connect(stateMap)(ManagerUsersPagination)