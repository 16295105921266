import React from 'react'
import scriptLoader from 'react-async-script-loader'
import { renderColumns } from '../../../../utils/AssetRegister/AssetRegisterHelperMethods'
import PropTypes from 'prop-types'
import { Form, Spin } from 'antd'
import _ from 'lodash'
import Loading from '../../../Common/Loading'

class AddEditAssetRegister extends React.Component {
  
  constructor(props) {
    super(props)
    this.autocomplete = null
    this.state = {
      accordion: {},
      fieldsValues: [],
      registerType: '',
      registerTypeManager: '',
      selectedRegisterObj: {},
      action: '',
      isComponentReady: false,
      loadingSave: false,
      loadingAdd: false
    }
  }
  
  componentDidMount() {
    const { selectedRegisterObj, fieldsValues, isScriptLoaded, isScriptLoadSucceed } = this.props
    if (this.props.action === 'edit') {
      this.props.toggleIsLoading()
      this.setState({
        action: this.props.action,
        fieldsValues: fieldsValues,
        registerType: selectedRegisterObj.library.id,
        registerTypeManager: this.props.registerTypeManager,
        selectedRegisterObj,
        isComponentReady: isScriptLoaded && isScriptLoadSucceed
      })
    } else {
      this.setState({
        action: this.props.action,
        fieldsValues: [],
        registerType: selectedRegisterObj.library.id,
        registerTypeManager: selectedRegisterObj.library.registerTypeManager,
        selectedRegisterObj,
        isComponentReady: isScriptLoaded && isScriptLoadSucceed
      })
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props
    if (isScriptLoaded && !prevProps.isScriptLoaded) {
      if (isScriptLoadSucceed) {
        this.setState({
          isComponentReady: true
        })
      }
    }
  }
  
  toggleAccordion(accordion, index) {
    this.setState({
      accordion: {
        ...accordion,
        [index]: !accordion[index]
      }
    })
  }
  
  handleSubmit = (e, action) => {
    e.preventDefault()
    const { registerType, fieldsValues, selectedRegisterObj } = this.state
    this.setState({
      loadingSave: true,
      loadingAdd: true
    })
    this.props.form.validateFields((err) => {
      if (!err) {
        this.props.submitForm(e, action, registerType, this.props.registerTypeManager, fieldsValues, selectedRegisterObj)
        if (action === 'edit') {
          this.props.onClickCollapse()
        } else {
          this.props.onClickCloseFormAdd()
        }
      }
    })
    this.forceUpdate()
  }
  
  getCityFromGoogleLocation = (googleLocation) => {
    let response = ' '
    for (let i = 0; i < googleLocation.address_components.length; i++) {
      if (googleLocation.address_components[i].types.indexOf('administrative_area_level_1') > -1) {
        response = googleLocation.address_components[i].long_name
      }
    }
    return response
  }
  
  changeAction = (e, type, columnId) => {
    switch (type) {
      case 'RADIO':
      case 'TEXT':
      case 'NUMBER':
      case 'TEXTAREA':
        this.setState({ fieldsValues: { ...this.state.fieldsValues, [columnId]: e.target.value } })
        break
      case 'DROPDOWN':
      case 'GEO_INIT':
      case 'CHECKBOX_INIT':
      case 'DATE':
        this.setState({ fieldsValues: { ...this.state.fieldsValues, [columnId]: e } })
        break
      case 'FILE':
        const reader = new FileReader()
        reader.readAsDataURL(e[e.length - 1])
        reader.onload = () => {
          this.setState({
            fieldsValues: {
              ...this.state.fieldsValues,
              [columnId]: { 'name': e[e.length - 1].name, 'file': reader.result }
            }
          })
        }
        break
      case 'GEOLOCATION':
        if (e.geometry) {
          const markPoint = {
            'lat': e.geometry.location.lat(),
            'lng': e.geometry.location.lng(),
            'city': this.getCityFromGoogleLocation(e)
          }
          this.setState({ fieldsValues: { ...this.state.fieldsValues, [columnId]: markPoint } })
        }
        break
      case 'FILE_INIT':
        this.setState({
          fieldsValues: {
            ...this.state.fieldsValues,
            [columnId]: { 'name': e.name, 'file': e.file }
          }
        })
        break
      case 'CHECKBOX':
        if (e.length < 1) {
          e = []
        }
        this.setState({ fieldsValues: { ...this.state.fieldsValues, [columnId]: e } })
        break
      case 'ADDRESS_FINDER':
        if (e.geometry) {
          const city = this.getCityFromGoogleLocation(e)
          this.setState({ fieldsValues: { ...this.state.fieldsValues,
            [columnId]: {
              'name': e.formatted_address,
              'city': city,
              'lat': e.geometry.location.lat(),
              'lng': e.geometry.location.lng()
            }
          }
          })
        }
        break
      default:
        this.setState({ fieldsValues: { ...this.state.fieldsValues, [columnId]: e } })
    }
  }
  
  keyPressHandler = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }
  
  render = () => {
    const { fieldsValues, action, isComponentReady, loadingSave, loadingAdd } = this.state
    const { activeAccessAsset, selectedRegisterObj } = this.props
    const { getFieldDecorator } = this.props.form
    const readonly = (!(activeAccessAsset || action === 'add'))
    return (
      isComponentReady &&
      <Form className="asset_form" onSubmit={ (e) => this.handleSubmit(e, action) }>
        <div className="list-info-register">
          { !_.isEmpty(selectedRegisterObj) && selectedRegisterObj.library && fieldsValues &&
          <React.Fragment>
            { !readonly &&
            <div className="list-info-register__columns">
              { selectedRegisterObj.library && 
              selectedRegisterObj.library.columns && 
              this.props.form && 
              renderColumns(selectedRegisterObj.library.columns, fieldsValues, getFieldDecorator, isComponentReady, readonly, this.changeAction, this.keyPressHandler, false) }
            </div>
            }
            <div className="list-info-register__columns additional">
              { !activeAccessAsset && action !== 'add' && <i className="up custom-asset"/> }
              { selectedRegisterObj.library && 
              selectedRegisterObj.library.additionalFields && 
              this.props.form && 
              renderColumns(selectedRegisterObj.library.additionalFields, fieldsValues, getFieldDecorator, isComponentReady, readonly, this.changeAction, this.keyPressHandler, true) }
            </div>
          </React.Fragment>
          }
          <div className="list-info-register__buttons">
            { action === 'edit' && !readonly &&
            <React.Fragment>
              <button className="button-blue-light mr-3" onClick={ (e) => this.props.collapseAll(e) }>CANCEL</button>
              {
                loadingSave && !getFieldDecorator ? <Spin className="float-right" spinning={ loadingSave }
                  indicator={ <div className="asset-register-loading"><Loading/>
                  </div> }/> :
                  <button className="button-blue  mr-3">SAVE</button>
              }
            </React.Fragment>
            }
            { action === 'add' &&
            <React.Fragment>
              {
                loadingAdd && !getFieldDecorator ? <Spin className="float-right" spinning={ loadingAdd }
                  indicator={ <div className="asset-register-loading"><Loading/>
                  </div> }/> :
                  <button className="button-blue  mr-3">ADD</button>
              }
            </React.Fragment>
            }
          </div>
        </div>
      </Form>
    )
  }
}

AddEditAssetRegister.propTypes = {
  action: PropTypes.string.isRequired,
  registerTypeManager: PropTypes.number,
  toggleIsLoading: PropTypes.func,
  onClickCollapse: PropTypes.func,
  collapseAll: PropTypes.func,
  selectedRegisterObj: PropTypes.object,
  fieldsValues: PropTypes.object
}

AddEditAssetRegister = Form.create({ name: 'add-asset' })(AddEditAssetRegister)

export default scriptLoader([
  'https://maps.googleapis.com/maps/api/js?key=AIzaSyB0vPXdXMi8ut3fOWv9LvyGZKRASY2PoI8&libraries=places'
])(AddEditAssetRegister)
