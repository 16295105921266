import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import '../ExternalFeedbackWorkflowTicketForm.scss'
import {Checkbox, Col, Input, Radio, Select, Steps} from "antd";
import {NameTemplateFormExternal} from "./NameTemplateFormExternal";
const { Option } = Select;
const { Step } = Steps;

export class MultipleChoiceTemplateFormExternal extends Component {

    state = {
        multipleChoiceForm: {
            options: [1, 2, 3],
            checkedValues: [],
        }
    }

    onChangeCheckbox = (checkedValues) => {
        const { component } = this.props
        console.log(checkedValues,'checkedValues')
        this.setState({
            multipleChoiceForm: { ...this.state.multipleChoiceForm, checkedValues: checkedValues },
        }, () => {
            this.props.onChangeNameAndDetails(checkedValues, 'multiple_choice', component)
        })
    }

    render() {
        const {multipleChoiceForm} = this.state
        const { formerEmployee, component } = this.props
        return (
            <div className="full-name-former">
                <span className="header-former header-former-address">{component.header && component.mandatory ? `${component.header}*` :
                    component.header ? component.header : 'Multiple choice'}</span>
                <span className="input-content">
                    <Checkbox.Group style={{ width: '100%' }}
                                    value={ component.multipleChoice }
                                    onChange={this.onChangeCheckbox}>
                            { component.options && component.options.length > 0 && component.options.map((list, index) => {
                                return (
                                    <Col key={ index }>
                                        <span>
                                        <Checkbox key={ index } value={ list.optionId }>{ list.optionText }</Checkbox>
                                            </span>
                                    </Col>
                                )
                            })

                            }
                        </Checkbox.Group>
                </span>
            </div>
        )
    }
}


export default connect()(MultipleChoiceTemplateFormExternal)