import React from 'react'
import './Settings.scss'
import Library from './components/Library'
import { connect } from 'react-redux'
import { deleteLibrary, getAllLibrariesByClientId } from '../../../api/DocLocker'
import { Skeleton } from 'antd'
import ManageDocumentLocker from './components/ManageDocumentLocker'

class Settings extends React.Component {
  state = {
    isImportExportActive: false,
    libraries: [],
    libraryToEdit: {},
    addLibraryOpened: false,
    loadingLibraries: true
  }

  componentDidMount() {
    if (this.props.clientId) {
      this.fetchData()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.clientId !== this.props.clientId) {
      this.fetchData()
    }
  }

  fetchData = () => {
    this.setState({
      loadingLibraries: true
    }, () => {
      getAllLibrariesByClientId(this.props.clientId).then((res) => {
        this.setState({
          libraries: res.data,
          loadingLibraries: false,
          libraryToEdit: {}
        })
      })
    })
  }

  editLibrary = (library) => {
    window.scrollTo({
      top: 200
    })
    this.setState({
      libraryToEdit: library,
      addLibraryOpened: true
    })
  }

  deleteLibrary = (library) => {
    deleteLibrary(library.id).then((res) => {
      if (res) {
        this.setState({
          libraries: this.state.libraries.filter((reg) => reg.id !== library.id)
        })
      }
    })
  }

  setImportExportActive = (value) => {
    this.setState({
      isImportExportActive: value,
      libraryToEdit: {}
    })
  }

  setLibraryOpenedToFalse = () => {
    this.setState({
      addLibraryOpened: false,
      libraryToEdit: {}
    })
  }

  render() {
    const { isImportExportActive, libraries, libraryToEdit, addLibraryOpened, loadingLibraries } = this.state
    return (
      <div className="settings-page">
        <ManageDocumentLocker
          setImportExportActive={ this.setImportExportActive }
          fetchData={ this.fetchData }
          libraryToEdit={ libraryToEdit }
          addLibraryOpened={ addLibraryOpened }
          setLibraryOpenedToFalse={ this.setLibraryOpenedToFalse }
        />
        {
          loadingLibraries ?
            <Skeleton active />
            :
            libraries && libraries.map((library, key) =>
              <Library editLibrary={ this.editLibrary }
                deleteLibrary={ this.deleteLibrary }
                library={ library } key={ key }
                isImportExportActive={ isImportExportActive }/>)
        }
      </div>
    )
  }
}



const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id
})

export default connect(mapStateToProps)(Settings)