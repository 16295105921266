import {
    apiMasterServiceToken,
    apiUserCreateFieldSolr,
    apiUserFetchInfo,
    apiWarmupAddCustomTagClient,
    apiWarmupAssignMember,
    apiWarmupCreateNewEmailTemplateSimple,
    apiWarmupCreateWorkflowTicket,
    apiWarmupDeleteEmailTemplateWorkflow,
    apiWarmupEditBusinessProcessComponents,
    apiWarmupResendInvite, apiWarmupSaveFormSectionsDataTicketWorkflow,
    apiWarmupSendEmailWorkflowTicket,
    apiWarmupTrigger,
    apiWarmupTriggerCreateBusinessProcess,
    apiWarmupTriggerCreateDirectory,
    apiWarmupTriggerCreateSar,
    apiWarmupTriggerCreateUser,
    apiWarmupTriggerDeactivateUser,
    apiWarmupTriggerDeleteBusinessProcess,
    apiWarmupTriggerDocLocker,
    apiWarmupTriggerEditBusinessTermList,
    apiWarmupTriggerFieldNames,
    apiWarmupTriggerGetAnnotationColorList,
    apiWarmupTriggerGetAnnotations,
    apiWarmupTriggerGetCompanyDetails,
    apiWarmupTriggerGetConnectList,
    apiWarmupTriggerGetSarSteps,
    apiWarmupTriggerLoginUser,
    apiWarmupTriggerSaveRedactionFilters,
    apiWarmupTriggerUpdateClientData,
    apiWarmupTriggerUpdateUser
} from '../api/User'
import * as types from './actionTypes'
import * as adminActions from '../actions/adminActions'
import * as applicationsActions from '../actions/applicationsActions'
import * as smartBoxActions from '../actions/smartBoxActions'
import {
    setBusinessProcessesVisible,
    setConnectorsVisible,
    setSourceMappingVisible
} from "../actions/applicationsActions";
import {setImageProfileSettings} from "../actions/smartBoxActions";

export function userLogin(idToken, accessToken) {
  var secondsToExp = Math.floor(new Date().getTime() / 1000);
  secondsToExp += (60*60*8) // 8 is the hours unit
  var tokenObject = { 'tokenId': idToken, 'expiresAt': secondsToExp};
  localStorage.setItem('tokenData', JSON.stringify(tokenObject))
  // localStorage.setItem('accessToken', accessToken)
  
  return { type: types.USER_LOGIN }
}

export function userLogout() {
  // localStorage.removeItem('accessToken')
  localStorage.removeItem('tokenData')
  localStorage.removeItem('dpiaToken')

  return { type: types.USER_LOGOUT }
}

export function receiveUserInfo(user) {
  return { type: types.RECEIVE_USER_INFO, user }
}

export function receiveSubscriptionInfo(subscription) {
  return { type: types.RECEIVE_SUBSCRIPTION_INFO, subscription }
}

export function updateUserInfo(data) {
  return { type: types.UPDATE_USER_INFO, user: data }
}

export function fetchingDataComplete(data) {
  return { type: types.FETCHING_USER_SUBSCRIPTION_DATA_COMPLETE, payload: data }
}
export function fetchUserInfo() {
  return (dispatch) => {
    return apiUserFetchInfo().then((response) => {
        dispatch(fetchingDataComplete(true))
        dispatch(receiveUserInfo(response.data.user))
        dispatch(smartBoxActions.setImageProfileSettings(response.data.user.picture_base64))
        dispatch(receiveSubscriptionInfo(response.data.subscription))
        if (response.data.user.user_metadata.roles['application'] === 'god_admin') {
          dispatch(adminActions.setGodAdminMode(true))
          apiMasterServiceToken().then((res) => {
            sessionStorage.setItem('gravicus_auth_token', res.data.sessionID)
            sessionStorage.setItem('gravicus_username', 'superadmin')
          })
          // if(window.location.pathname === '/smart-box/management'){
          //   window.location.href='/admin/client-data'
          // }
        }
        apiUserCreateFieldSolr().then(() => {})

        apiWarmupTrigger().then((response1) => {
            if (response1.data.triggerWarmUp) {
                //2
                apiWarmupTriggerGetConnectList().then((response2) => {
                  //3
                    apiWarmupTriggerCreateBusinessProcess().then((response3) => {
                       //4
                        apiWarmupTriggerDeleteBusinessProcess().then((response4) => {
                           //5
                            apiWarmupTriggerCreateDirectory().then((response5) => {
                              //6
                                apiWarmupTriggerGetSarSteps().then((response6) => {
                                  //7
                                    apiWarmupTriggerUpdateClientData().then((response7) => {
                                      //8
                                        apiWarmupTriggerCreateSar().then((response8) => {
                                          //9
                                            apiWarmupTriggerGetAnnotations().then((response9) => {
                                              //10
                                                apiWarmupTriggerEditBusinessTermList().then((response10) => {
                                                   //11
                                                    apiWarmupTriggerGetCompanyDetails().then((response11) => {
                                                        //12
                                                        apiWarmupTriggerUpdateUser().then((response12) => {
                                                          //13
                                                            apiWarmupTriggerDeactivateUser().then((response13) => {
                                                             //14
                                                                apiWarmupTriggerGetAnnotationColorList().then((response14) => {
                                                                   //15
                                                                    apiWarmupTriggerCreateUser().then((response15) => {
                                                                      //16
                                                                        apiWarmupTriggerLoginUser().then((response16) => {
                                                                          //17
                                                                            apiWarmupTriggerSaveRedactionFilters().then((response17) => {
                                                                              //18
                                                                                apiWarmupTriggerFieldNames().then((response18) => {
                                                                                    // 19
                                                                                    apiWarmupTriggerDocLocker().then((response19) => {
                                                                                        //20
                                                                                        apiWarmupAddCustomTagClient().then((response20) => {
                                                                                            // 21
                                                                                            apiWarmupAssignMember().then((response21) => {
                                                                                                // 22
                                                                                                apiWarmupResendInvite().then((response22) =>{
                                                                                                    // 23
                                                                                                    apiWarmupEditBusinessProcessComponents().then((response23) => {
                                                                                                       //24
                                                                                                        apiWarmupCreateWorkflowTicket().then((response24) => {
                                                                                                          //25
                                                                                                            apiWarmupDeleteEmailTemplateWorkflow().then((response25) => {
                                                                                                                //26
                                                                                                                apiWarmupSendEmailWorkflowTicket().then((response26) => {
                                                                                                                    //27
                                                                                                                    apiWarmupCreateNewEmailTemplateSimple().then((response27) => {
                                                                                                                        //28
                                                                                                                        apiWarmupSaveFormSectionsDataTicketWorkflow().then((response28) => {

                                                                                                                        })
                                                                                                                    })
                                                                                                                })

                                                                                                            })
                                                                                                        })
                                                                                                    })

                                                                                                })

                                                                                            })

                                                                                        })

                                                                                    })

                                                                                })

                                                                            })
                                                                        })
                                                                    })
                                                                })
                                                            })
                                                        })
                                                    })
                                                })
                                            })
                                        })
                                    })
                                })
                            })
                        })
                    })
                })
            }
        }).catch((error) => {

        })

        dispatch(applicationsActions.setSarVisible(response.data.subscription.client.sarVisible))
        dispatch(applicationsActions.setDocLockerVisible(response.data.subscription.client.docLockerVisible))
        dispatch(applicationsActions.setAssetRegisterVisible(response.data.subscription.client.assetRegisterVisible))
        dispatch(applicationsActions.setSmartBoxVisible(response.data.subscription.client.smartBoxVisible))
        dispatch(applicationsActions.setDpiaVisible(response.data.subscription.client.dpiaVisible))
        dispatch(applicationsActions.setDataMappingAppVisible(response.data.subscription.client.dataMappingAppVisible))
        dispatch(applicationsActions.setWorkflowAppVisible(response.data.subscription.client.workflowAppVisible))
        dispatch(applicationsActions.setAdditionalEnvironmentsVisible(response.data.subscription.client.additionalEnvironments))
        dispatch(applicationsActions.setBusinessProcessesVisible(response.data.subscription.client.businessProcessesVisible))
        dispatch(applicationsActions.setSourceMappingVisible(response.data.subscription.client.sourceMappingVisible))
        dispatch(applicationsActions.setConnectorsVisible(response.data.subscription.client.connectorsVisible))
        dispatch(fetchingDataComplete(false))
        dispatch(smartBoxActions.setAxiosHeadersCopy(response.config.headers))
      }).catch((error) => {
        console.log('ERROR', error)
        dispatch(userLogout())
      })
  }
}
