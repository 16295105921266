import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import * as actions from '../../actions/questionActions'

import DoNotNeedPia from './Alerts/DoNotNeedPia'
import ErrorPage from '../Common/ErrorPage'
import Loading from '../Common/Loading'
import NeedPia from './Alerts/NeedPia'
import PiaSummary from './PiaSummary'

import './PiaResult.scss'

class PiaResult extends React.Component {
  componentWillMount() {
    const assessmentId = this.props.params.assessmentId
    this.props.setAssessmentId(assessmentId)
    this.props.fetchQuestions(assessmentId)
    this.props.fetchAssessment(assessmentId)
  }

  componentWillUnmount() {
    this.props.setToLoadingPiaResultScreen()
  }

  assignedUser(process) {
    if (process.assignedUser.isLoading) return
    if (process.assignedUser.user) return process.assignedUser.user
    return process.assignedUser
  }

  render() {
    const { assessment, process, questions } = this.props
    const user = this.assignedUser(process)
    let displayName;
    if (process.assignedToTeam) {
      displayName = process.assignedToTeam
    } else if(user) {
      displayName = user.email
    }

    return (
      <div className="piaResult__page">
        {
          !assessment.isLoading &&
          assessment.error &&
          <ErrorPage error={ assessment.error } />
        }

        {
          !assessment.isLoading &&
          !assessment.error &&
          !assessment.isPia &&
          !assessment.status === 'COMPLETED' &&
          <Navigate to="/dpia/dashboard" />
        }

        {
          (assessment.isLoading || process.isLoading || questions.isLoading) &&
          <div className="piaResult__loading">
            <Loading />
          </div>
        }

        {
          !process.isLoading &&
          assessment.hasLinkToNextStageAssessment &&
          <NeedPia process={process} user={user} displayName={displayName} />
        }

        {
          !process.isLoading &&
          !assessment.hasLinkToNextStageAssessment &&
          <DoNotNeedPia process={process} />
        }

        {
          !assessment.isLoading &&
          !questions.isLoading &&
          <div className="piaResult__summaryWrapper">
            <PiaSummary assessment={assessment} questions={questions} />
          </div>
        }
      </div>
    )
  }
}

const stateMap = (state) => ({
  assessment: state.questionReducer.assessment,
  process: state.questionReducer.assessment.process,
  questions: state.questionReducer.questions.allQuestions,
  user: state.userReducer.user
})

const dispatchMap = (dispatch) => ({
  fetchAssessment: (assessmentId) => dispatch(actions.fetchAssessment(assessmentId)),
  fetchQuestions: (assessmentId) => dispatch(actions.fetchQuestions(assessmentId)),
  setAssessmentId: (assessmentId) => dispatch(actions.setAssessmentId(assessmentId)),
  setToLoadingPiaResultScreen: () => dispatch(actions.setToLoadingPiaResultScreen()),
})

PiaResult.propTypes = {
  fetchQuestions: PropTypes.func.isRequired,
  fetchAssessment: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  process: PropTypes.object.isRequired,
  setAssessmentId: PropTypes.func.isRequired
}

export default compose(
  connect(stateMap, dispatchMap)
)(PiaResult)