import React from 'react'
import './TemplateEmail.scss'
import {Tabs, Tooltip} from 'antd'
import TemplateEmailContent from './EmailSteps/TemplateEmailContent'

const TabPane = Tabs.TabPane

export function TemplateEmail() {
  const [mode] = React.useState('left')
  const [editMode, setEditMode] = React.useState(false)
  const [selectedEmailTemplate, setSelectedEmailTemplate] = React.useState('0')
  const sarSteps = ['Initial Request', 'Acknowledge', 'Check ID', 'Respond', 'Search And Find', 'Review And Redact', 'Send', 'Complete']

  function changeEmailTemplate(selectedKey) {
    setSelectedEmailTemplate(selectedKey)
  }

  function isEditModeEnabledNew(editMode) {
    if (editMode) {
      setEditMode(true)
    } else {
      setEditMode(false)
    }
  }
  return (
    <div className="page-container page-container-update">
      <div className="templateEmail-page">
        <header className="header-emails-templates">
          <h1>Email templates</h1>
          {/*<h3>Customise your email responses.</h3>*/}
          <Tooltip overlayClassName="help-message-locations" title={<div className="content-help-message-locations">
            <span className="text-information">Customise your Request Tracker email responses.</span>
            <span className="more-info-link" onClick={ () => window.open('https://support.smartbox.ai/support/solutions/articles/47001186706-customising-request-tracker-email-templates', '_blank') }>More info.</span>
          </div>}>
            <span className="icon-v14-question"></span>
          </Tooltip>
        </header>
        <div className="templateEmail-box">
          <Tabs
            activeKey= { selectedEmailTemplate }
            tabPosition={mode}
            style={{ height: '100%' }}
            onChange = { (e) => changeEmailTemplate(e) }
            className="template-email-wrapper"
          >
            {sarSteps.map((value, key) => (
              <TabPane tab={value} key={key} className={key.toString() === selectedEmailTemplate ? 'right-item selected-template' : 'right-item'} disabled={editMode}>
                <TemplateEmailContent isEditMode={() => isEditModeEnabledNew(editMode)} sarStep={key + 1}/>
              </TabPane>))}
          </Tabs>
        </div>
      </div>
    </div>
  )
}


export default TemplateEmail