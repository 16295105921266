import PropTypes from 'prop-types'
import React from 'react'
import './PageTitle.css'
import {Tooltip} from "antd";

const PageTitle = ({ title }) => (
  <header className="pageTitle-header pageTitle-header-update">
    <h1 className="pageTitle-title page-title-update">{ title }
        { title === 'Manage Users' ?
            <Tooltip overlayClassName="help-message-locations" title={<div className="content-help-message-locations">
                <span className="text-information">Edit your organisation’s users and roles.</span>
                <span className="more-info-link"
                      onClick={() => window.open('https://support.smartbox.ai/support/solutions/articles/47001174208', '_blank')}> More info. </span>
            </div>}>
                <span className="help-location-update icon-v14-question"></span>
            </Tooltip> :
            title === 'Manage Teams' ?
                <Tooltip overlayClassName="help-message-locations" title={<div className="content-help-message-locations">
                    <span className="text-information">Create and edit your organisation’s teams.</span>
                    <span className="more-info-link"
                          onClick={() => window.open('https://support.smartbox.ai/support/solutions/articles/47001174211-manage-teams', '_blank')}> More info. </span>
                </div>}>
                    <span className="help-location-update icon-v14-question"></span>
                </Tooltip> :
            <React.Fragment>

            </React.Fragment>
        }
    </h1>
  </header>
)

PageTitle.propTypes = {
  title: PropTypes.string.isRequired
}

export default PageTitle