import React from 'react'
import { Input, Modal, Tabs } from 'antd'
import './SmartBoxFolderSettings.scss'
import ReportDashboard from './FolderSettings/ReportDashboard'
//import Duplicates from './Duplicates'
//import NearDeduplication from './NearDeduplication'
import ErrorReport from './ErrorReport'
import WarningReport from "./WarningReport";
//import Clutter from './Clutter'
import CullReport from './CullReport'
import { Link } from 'react-router-dom'
import { generateFileForLink } from '../../../../utils/SmartBox/SmartBoxMethods'
// import LeftFoldersNavigation from '../../../Common/LeftFoldersNavigation/LeftFoldersNavigation'
import {
  apiSmartBoxPreviewSarDocByThumbnailId,
  getRenameFile,
  getSmartBoxFileById,
  getSmartBoxFilesFilter,
  getFolderSettingsRedactingTree,
  getFolderSettingsRedactingTreeImages,
  checkFolderSettingTree,
  apiGetStatusStarReportCull, sendReportStarCull
} from '../../../../api/SmartBox'
import { RedactionTool } from '../../../Sar/Redacting/RedactionTool'
import { generateBlobFromBase64 } from '../../../../utils/GenerateBlob'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { setFooterVisibility, setHeaderVisibility, setSidebarVisibility } from '../../../../actions/shellActions'
import { setSmartBoxFilesTable, setSmartBoxFetchFiles, 
  // setSmartBoxBaseDataTree, 
  setSmartBoxToggleFilePreview,
  setSmartBoxDirectoryNote, setSmartBoxSelectedDocuments, setSmartBoxAnalysisPercent, setSmartBoxSelectedDuplicateFile } from '../../../../actions/smartBoxActions'
import { Navigate } from 'react-router-dom'
import CustomNotificationManager from '../../../Common/CustomNotificationManager'
import imgFolderSmallOpenParentSmall from '../../../../assets/smart-folder-open-paret-small.svg'
import SmartBoxSettingsMenu from './SmartBoxSettingsMenu'
import cookie from 'react-cookies'
import DirectoryNote from '../DirectoryNote'
import HeaderNavigationBox from '../../../Common/HeaderNavigationBox'
import withRouter from '../../../../private-routers/withRouter'

class SmartBoxFolderDataInsights extends React.Component {
  state = {
    folder: {},
    paths: '',
    folderInfo: {
      documents: 125345,
      folders: 325699,
      pages: 1365943,
      found: 200022223,
    },
    isStatisticsPressed: false,
    treeData: [],
    currentActiveParent: '',
    isTreeExpanded: false,
    openPrintReport: false,
    isExploreDataVisible: false,
    redactionMode: false,
    documentBytes: {},
    documentId: 0,
    initialName: '',
    totalPages: 0,
    width: 0,
    height: 0,
    smartBoxDocumentId: 0,
    fileName: '',
    searchText: '',
    smartBoxMenuOpen: false,
    finalizedDocPath: false,
    renameFolderModalVisible: false,
    newFolderName: '',
    newFolderNameValid: { success: true, reason: 'Invalid name' },
    smartBoxDocumentPath: '',
    activeKeyTab: 'dashboard',
    treeForSettings: [],
    treeForSettingsInsight: [],
    nameLocationCallTree: 'Data Insights',
    documentNoteVisible: true,
    checkBeingProcessed: false,
    fileDuplicateOpen: {},
    hashFile: '',
    setIntervalCheckTree: 0,
    responseCheckTree: '',
    activeTab: 'dashboard',
    statusReportStar: false,
    activeErrorTab: '',
    statusWarningsReport: false,
    warningListItems: [],
    lockSmartViewChanges: false
  }

  componentDidMount() {
    this.fetchFolderInfo()
    let currentURL = this.props.location.pathname
    if(currentURL.includes('dashboard')){
        this.setState({activeTab: 'ReportDashboard', activeKeyTab: 'ReportDashboard'})
    }else if(currentURL.includes('error-report')){
        this.setState({activeTab: 'errorReport', activeKeyTab: 'errorReport'})
    }else if(currentURL.includes('warning-report')) {
      this.setState({activeTab: 'warningsReport', activeKeyTab: 'warningsReport'})
    } else{
        this.setState({activeTab: 'sweepReport', activeKeyTab: 'sweepReport'})
    }
    document.addEventListener('mousedown', this.handleClickOutside, false)
    const cookiess = cookie.load('tabs')
    if (cookiess) {
      this.setState({
        activeKeyTab: cookiess
      })
    }
    if (this.props.smartBoxSelectedDocumentDuplicate.activeTab === 'duplicates') {
      this.setState({
        activeKeyTab: this.props.smartBoxSelectedDocumentDuplicate.activeTab,
        fileDuplicateOpen: this.props.smartBoxSelectedDocumentDuplicate.action,
        hashFile: this.props.smartBoxSelectedDocumentDuplicate.hashFile
      })
    }
    cookie.remove('tabs', { path: '/' })
    this.sendCookiesForOpenSmartView()
    if (this.props.smartBoxBaseDataTree && this.props.smartBoxBaseDataTree.length > 0) {
      return true
    } 
    this.fetchFiles()
    
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props && this.props.smartBoxSelectedDocumentId.action === 'data-insights') {
      this.setState({
        treeForSettings: [],
        treeForSettingsInsight: [],
        folder: {}
      }, () => this.fetchFolderInfoUpdate())
    }
  }
  onClickReportCullStar = () => {
    const { folder } = this.state
    const { smartBoxBaseDataTree } = this.props
    //const { statusReportStar } = this.state
    let parentName = ''
    let idBox = ''
    if (folder && typeof folder.relativePath !== 'undefined') {
      parentName = folder.relativePath.split('/')[1]
    }
    smartBoxBaseDataTree && smartBoxBaseDataTree.length > 0 && smartBoxBaseDataTree.forEach((list) => {
      if (parentName === list.name) {
        idBox = list.id
      }
    })
    const dtoId = idBox ? idBox : folder.id
    sendReportStarCull(dtoId).then((response) => {
      this.setState({
        statusReportStar: false
      })
    })
  }
  onSendStatusStarCull = () => {
    const { folder } = this.state
    const { smartBoxBaseDataTree } = this.props
    //const { statusReportStar } = this.state
    let idBoxRefresh = ''
    let parentName = ''
    if (folder && typeof folder.relativePath !== 'undefined') {
      parentName = folder.relativePath.split('/')[1]
    }
    smartBoxBaseDataTree && smartBoxBaseDataTree.length > 0 && smartBoxBaseDataTree.forEach((list) => {
      if (parentName === list.name) {
        idBoxRefresh = list.id
      }
    })
    const folderId = idBoxRefresh ? idBoxRefresh : folder.id
    apiGetStatusStarReportCull(folderId).then((response) => {
       this.setState({
         statusReportStar: response.data
       })
    })
  }
  getSettingsImagesInsight = () => {
    const { nameLocationCallTree, folder } = this.state
    let { treeForSettingsInsight } = this.state
    treeForSettingsInsight = treeForSettingsInsight && treeForSettingsInsight.length> 0 && treeForSettingsInsight.slice(0, treeForSettingsInsight.length - 1)
    const treeSettingInputDTO = {
      path: `${ folder.folderPath }${ folder.name }`,
      page: nameLocationCallTree
    }
    getFolderSettingsRedactingTreeImages(treeSettingInputDTO).then((response) => {
      if (response.status === 200) {
        treeForSettingsInsight = treeForSettingsInsight && treeForSettingsInsight.length > 0 && treeForSettingsInsight.concat(response.data)
        this.setState({
          treeForSettingsInsight
        })
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  getCheckTreeForSettings = () => {
    const { nameLocationCallTree, folder } = this.state
    let { setIntervalCheckTree } = this.state
    const data = {
      path: `${ folder.folderPath }${ folder.name }`,
      page: nameLocationCallTree
    }
    checkFolderSettingTree(data).then((response) => {
      if (response.status === 200) {
        this.setState({
          responseCheckTree: response.data
        })
        if (response.data === 'Processing!') {
          if (setIntervalCheckTree === 0) {
            CustomNotificationManager.info('Please wait! Report generation could take some time...')
            setIntervalCheckTree = setInterval(this.setIntervalCheckTreeForSettings, 4000)
            this.setState({setIntervalCheckTree})
          }
        } else if (response.data === 'Processing in progress, this may take several minutes.') {
          CustomNotificationManager.info('Data is being processed, please check this page later.')
        } else if (response.data === 'Finished!') {
          this.getFolderSettingsRedactingTree()
          clearInterval(setIntervalCheckTree)

          this.setState({setIntervalCheckTree})
        }
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
      }else{
          console.log(error)
      }
    })
  }
  setIntervalCheckTreeForSettings = () => {
    const {nameLocationCallTree, folder, setIntervalCheckTree, responseCheckTree} = this.state
    const data = {
      path: `${folder.folderPath}${folder.name}`,
      page: nameLocationCallTree
    }
    if (responseCheckTree === 'Processing!') {
    checkFolderSettingTree(data).then((response) => {
      if (response.status === 200) {
        this.setState({
          responseCheckTree: response.data
        })
        if (response.data === 'Processing!') {
          //this.getFolderSettingsRedactingTree()
          this.setState({setIntervalCheckTree})
        } else if (response.data === 'Processing in progress, this may take several minutes.') {
          CustomNotificationManager.info('Data is being processed, please check this page later.')
        } else if (response.data === 'Finished!') {
          //this.getFolderSettingsRedactingTree()
          this.setState({setIntervalCheckTree})
          clearInterval(setIntervalCheckTree)
        }
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
      }else{
          console.log(error)
      }
    })
  }
  }

  getFolderSettingsRedactingTree = () => {
    const { nameLocationCallTree, folder } = this.state
    const data = {
      path: `${ folder.folderPath }${ folder.name }`,
      page: nameLocationCallTree
    }
    getFolderSettingsRedactingTree(data).then((response) => {
      if (response.status === 200) {
        let treeForSettings = JSON.parse(JSON.stringify(response.data))
        if (!treeForSettings.length) {
          treeForSettings = [
            { title: 'Analytic Results', children: [], found: 0 },
            { title: 'Regular expression', children: [], found: 0 },
            { title: 'Business Terms', children: [], found: 0 },
            { title: 'Manual term', children: [], found: 0 }
          ]
        }
        if (response.data === 'Analytics Report is underway! This may take several minutes!') {
          CustomNotificationManager.info('Analytics Report is underway! This may take several minutes!')
        }
        const treeForSettingsInsight = [ ...response.data]
        this.setState({ treeForSettings, treeForSettingsInsight, checkBeingProcessed: false }, () => {
          //this.getSettingsImagesInsight()
        })
      }
    }).catch((error) => {
      const detailError= 'Handler dispatch failed; nested exception is java.lang.Error: Data is being processed, please check this page later.'
      if (error?.response?.data?.detail === detailError) {
        CustomNotificationManager.info('Data is being processed, please check this page later.')
        this.setState({
          checkBeingProcessed: true
        })
      }else if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  sendCookiesForOpenSmartView = () => {
    const cookOpenTabOverview = cookie.load('tabsOverview')
    if (cookOpenTabOverview) {
      this.setState({
        activeKeyTab: cookOpenTabOverview
      })
    }
    let viewFileOpenSmartView = {}
    viewFileOpenSmartView = cookie.load('sendFileOpenSmart')
    if (viewFileOpenSmartView) {
      this.handlePreview(viewFileOpenSmartView)
    }
    cookie.remove('tabsOverview', { path: '/' })
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false)
  }

  handleClickOutside = (e) => {
    const { smartBoxMenuOpen } = this.state
    if (this.node) {
      if (this.node.contains(e.target)) {
        if (!smartBoxMenuOpen) {
          this.toggleSmartBoxMenu(true)
        }
        if (smartBoxMenuOpen && typeof e.target.className === 'string' && (e.target.className.includes('ant-menu-submenu-title') || e.target.className.includes('ant-menu-submenu-arrow'))) {
          this.toggleSmartBoxMenu(false)
        }
      } else if (smartBoxMenuOpen) {
        this.toggleSmartBoxMenu(false)
      }
    }
  }

  toggleLoadingOpenedFolder = (open) => this.setState({ loadingOpenedFolder: open })

  toggleSmartBoxMenu = (open) => {
    const { smartBoxMenuOpen } = this.state
    if (smartBoxMenuOpen) {
      this.setState({ smartBoxMenuOpen: open })
    }
    this.setState({ smartBoxMenuOpen: open })
  }

  toggleRenameFolderModal = (open) => this.setState({ renameFolderModalVisible: open })

  handleRenameFolder = () => {
    const { folder, newFolderName, newFolderNameValid } = this.state
    const { renameFolderModalVisible } = this.state
    const nameRgex = /^[A-Za-z\d_\s]+$/
    if (newFolderName) {
      newFolderNameValid.success= true
      if (!nameRgex.test(newFolderName) || folder.name === newFolderName) {
        newFolderNameValid.success = false
        if (!nameRgex.test(newFolderName)) {
          newFolderNameValid.reason = 'Invalid name. Please do not use the following characters: /\\?*;:[]\'"|,.<>'
        }
        if (folder.name === newFolderName) {
          newFolderNameValid.reason = 'It\'s old name. Please insert a new one.'
        }
      }
      if (newFolderNameValid.success) {
        const newRenameFolder = {
          id: folder.id,
          name: newFolderName
        }
        getRenameFile(newRenameFolder).then((response) => {
          if (response.status === 200) {
            folder.name= response.data
            const pos = folder.relativePath.lastIndexOf('/')
            const newRelativePathTemp = `${ folder.relativePath.substr(0, pos < 0 ? folder.relativePath.length : pos) }/${ response.data }`
            folder.relativePath = newRelativePathTemp
            this.setState({
              renameFolderModalVisible: false,
              newFolderName: '',
              folder
            }, () => { this.generateSmartBoxMenu('') })
          }
        }).catch((e) => {
          const errorMessage = 'Folder id does not exist'
          if (e?.response?.status === 500) {
            if (typeof e.response?.data?.message !== 'undefined' && e.response?.data?.message.indexOf(errorMessage) > 0) {
              CustomNotificationManager.error('The current folder no longer exists or you do not have access to its settings', 'Error')
              this.props.navigate('/smart-box')
            }
          }else if (e?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
        })
      } else {
        this.setState({ newFolderName: '', renameFolderModalVisible })
      }

    }

  }

  handleChangeFolderName = (e) => this.setState({ newFolderName: e.target.value })

  getUpdateTreeForSettings = () => {
    const { folder, nameLocationCallTree } = this.state
    const data = {
      path: `${ folder.folderPath }${ folder.name }`,
      page: nameLocationCallTree
    }
    getFolderSettingsRedactingTree(data).then((response) => {
      if (response.status === 200) {
        let treeForSettings = JSON.parse(JSON.stringify(response.data))
        if (!treeForSettings.length) {
          treeForSettings = [
            { title: 'Analytic Results', children: [], found: 0 },
            { title: 'Regular expression', children: [], found: 0 },
            { title: 'Business Terms', children: [], found: 0 },
            { title: 'Manual term', children: [], found: 0 }
          ]
        }
        if (response.data === 'Analytics Report is underway! This may take several minutes!') {
          CustomNotificationManager.info('Analytics Report is underway! This may take several minutes!')
        }
        const treeForSettingsInsight = [ ...response.data]
        this.setState({ treeForSettings, treeForSettingsInsight, checkBeingProcessed: false })
      }
    }).catch((error) => {
      const detailError= 'Handler dispatch failed; nested exception is java.lang.Error: Data is being processed, please check this page later.'
      if (error?.response?.data?.detail === detailError) {
        CustomNotificationManager.info('Data is being processed, please check this page later.')
        this.setState({
          checkBeingProcessed: true,
          treeForSettings: [],
          treeForSettingsInsight: [],
        })
      }else if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  fetchFolderInfoUpdate = () => {
    this.setState({
      folderId: this.props.params.id
    }, () => {
      getSmartBoxFileById(this.state.folderId).then((response) => {
        if (response.status === 200) {
          if (!response.data.path && !response.data.smartDocument) {
            CustomNotificationManager.error('The current folder no longer exists or you do not have access to its settings', 'Error')
            this.props.navigate('/smart-box')
          }
          this.setState({
            folder: response.data.smartDocument
          }, () => {
            //this.getUpdateTreeForSettings()
            this.onSendStatusStarCull()
          })
        }
      }).catch((error) => {
        if (error?.response?.status === 500 && error?.response?.data?.detail === 'You don\'t have access to this folder') {
          CustomNotificationManager.error('You don\'t have access to this folder', 'Error')
          this.props.navigate('/smart-box')
        }else if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }

        
      })
    })
  }
  fetchFiles = () => {
    const { searchText } = this.state
    const initFilter = {
      docFilename: searchText,
      docFolderPath: null
    }
    // getSmartBoxFilesFilter(initFilter).then((res) => {
    //   for (let i = 0; i < res.data.length; i++) {
    //     res.data[i].root = true
    //   }

    //   this.props.setSmartBoxBaseDataTree(res.data)
    //   this.setState({
    //     files: res.data,
    //     loadingBoxPage: false,
    //     listKeysFiles: [],
    //     checkKeysFiles: false
    //   }, () => {
    //     //this.onSendStatusStarCull()
    //   })
    // }).catch((error) => {
    //   if (error.response.status === 504) {
    //     CustomNotificationManager.cleanBuffer('warning', '')
    //   }
    // })
  }

  fetchFolderInfo = () => {

    this.setState({
      folderId: this.props.params.id
    }, () => {
      getSmartBoxFileById(this.state.folderId).then((response) => {
        if (response.status === 200) {
          if (!response.data.path && !response.data.smartDocument) {
            CustomNotificationManager.error('The current folder no longer exists or you do not have access to its settings', 'Error')
            const path = '/smart-box'
            this.props.navigate(path)
          }
          const folderData = response.data.smartDocument
          if(folderData.relativePath.substr(1).indexOf('/') !== -1) {
            this.props.setSmartBoxAnalysisPercent(100)
          }
          this.setState({
            folder: folderData
          }, () => {
            this.onSendStatusStarCull()
            //this.getCheckTreeForSettings()
            //this.getFolderSettingsRedactingTree()
          })
        }
      }).catch((error) => {
        if (error?.response?.status === 500 && error?.response?.data?.detail === 'You don\'t have access to this folder') {
          CustomNotificationManager.error('You don\'t have access to this folder', 'Error')
          this.props.navigate('/smart-box')
        }else if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    })
  }

  generateSmartBoxMenu = (activePage= '') => {
    const { folder, paths, smartBoxMenuOpen } = this.state
    const shortBreadcrumbLimit = 4
    let folderDepth = 0
    const path = folder.relativePath
    if (path) {
      folderDepth = path.split('/').length
      folderDepth = folderDepth - 1
      const fileId = (Object.keys(paths).length) ? paths[Object.keys(paths)[0]] : (folder.id ? folder.id : '')
      const pathArray = path.split('/')
      let breadCrumb = []
      let requestPath = ''
      let j = 0
      for (let i = 0; i < pathArray.length; i++) {
        if (pathArray[i]) {
          requestPath = `${ requestPath }/${ pathArray[i] }`
          const fileIdForLink = generateFileForLink(folder.id, paths, requestPath)

          if (i < shortBreadcrumbLimit + 1) {
            breadCrumb = [...breadCrumb, [<span key={j++}>&nbsp;&nbsp;&nbsp;</span>,
              <span key={j++} className="arrow">&rsaquo;</span>, <span key={j++}>&nbsp;&nbsp;&nbsp;</span>]]
          }

          if (path === requestPath) {
            breadCrumb.push(<img key={j++} src={imgFolderSmallOpenParentSmall} alt={path}/>)
          }
          if (i < shortBreadcrumbLimit || i === folderDepth) {
            breadCrumb.push(<span key={j++}>&nbsp;&nbsp;</span>)
            breadCrumb.push(<Link key={j++} to={{ pathname: `/smart-box/folder/${ fileIdForLink }` }}>{pathArray[i]}</Link>)
          } else if (i === shortBreadcrumbLimit && i < folderDepth) {
            breadCrumb.push(<span key={j++}>...&nbsp;&nbsp;&nbsp;&nbsp;</span>)
          }
          if (path === requestPath && !folder.depth) {
            breadCrumb = [...breadCrumb, [<span key={j++} className="arrow">&rsaquo;</span>,
              <span key={j++}>&nbsp;&nbsp;&nbsp;</span>,
              <span ref={ (node) => this.node = node } key={j++}>
                <SmartBoxSettingsMenu
                  folder={folder}
                  fileId={fileId}
                  toggleFilePreview={() => this.toggleFilePreview(true, folder)}
                  activePage={ activePage }
                  smartBoxMenuOpen={smartBoxMenuOpen}
                  fetchFiles = { this.fetchFiles }
                  toggleLoadingOpenedFolder = { this.toggleLoadingOpenedFolder }
                  toggleSmartBoxMenu = { this.toggleSmartBoxMenu }
                  toggleRenameFolderModal = { this.toggleRenameFolderModal }
                >
                </SmartBoxSettingsMenu></span>]]
          }
        }
      }
      return breadCrumb
    }
    return ''
  }

  closeRedacting = (obj) => {
    this.setState({ redactionMode: false })
  }

  handlePreview = (file) => {
    // this.props.setHeaderVisibility(false)
    this.props.setSidebarVisibility(false)
    this.props.setFooterVisibility(false)
    this.setState({ loading: true })
    apiSmartBoxPreviewSarDocByThumbnailId(file.fileId).then((response) => {
      const { doc, id, totalPages, landscape, width, height, name, finalizedDocPath, relativePath, systemPropertyFileStatus, systemPropertyFileColor, systemPropertyFileIcon, doc_classification, visibleToDownload, fileWarnings,
        lockSmartViewChanges } = response.data
      const spliterPosition = name.indexOf('-') + 1
      this.props.setHeaderVisibility(false)
      this.setState({
        redactionMode: true,
        documentBytes: doc,
        documentId: id,
        totalPages: totalPages,
        landscape,
        width,
        height,
        initialName: name.substr(spliterPosition),
        //smartBoxDocumentId: file.fileId,
        loading: false,
        fileName: name,
        finalizedDocPath,
        //smartBoxDocumentPath: relativePath,
        systemPropertyFileStatus,
        systemPropertyFileIcon,
        systemPropertyFileColor,
        doc_classification,
        visibleToDownload,
        smartBoxDocumentId: `${ file.folderPath + file.fileName }`,
        smartBoxDocumentPath: `${ file.folderPath + file.fileName }`,
        nameFileOpenInRedaction: `${ file.fileName }`,
        isOcr: file.isOcr !== 'false',
        //xeroxUpload: file.xeroxUpload !== 'false',
        xeroxUpload: false,
        fileWarnings,
        lockSmartViewChanges
      })
    }).catch((error) => {
      if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
    })
    cookie.remove('sendFileOpenSmart', { path: '/' })
  };

  onActiveKeyTab = (keyTab) => {
    this.setState({
      activeKeyTab: keyTab
    })
  }
  visibleDirectoryNote = (e, open) => {
    this.props.setSmartBoxDirectoryNote(open)
  }

  renderRenameFolderModal = () => {
    const { renameFolderModalVisible, folder, newFolderNameValid } = this.state
    return (
      <Modal
        className="rename-folder"
        title="Rename folder"
        visible={ renameFolderModalVisible }
        onOk={ () => this.handleRenameFolder() }
        onCancel={ () => this.toggleRenameFolderModal(false) }
      >
        <p className="subtitle">Add new name for <b>{folder.name}</b></p>
        <Input type="text" placeholder="Rename folder" onChange={ (e) => this.handleChangeFolderName(e) } onPressEnter={ (e) => this.handleRenameFolder() }/>
        {!newFolderNameValid.success &&
        <p className="error">{newFolderNameValid.reason}</p>
        }
      </Modal>
    )
  }
  toggleNoteVisible = (open) => {
    this.setState({ documentNoteVisible: open })
  }
  onChangeTab = (activeTab) => {
    this.setState({
      activeTab: activeTab,
      activeErrorTab: activeTab,
      activeKeyTab: activeTab,
    })
    //sweepReport errorReport ReportDashboard
    if(activeTab === 'ReportDashboard'){
      window.history.replaceState(null, null, `/smart-box/folder/${this.state.folder.id}/data-insights/dashboard`);
    }else if(activeTab === 'errorReport'){
      window.history.replaceState(null, null, `/smart-box/folder/${this.state.folder.id}/data-insights/error-report`);
    } else if(activeTab === 'warningsReport'){
      window.history.replaceState(null, null, `/smart-box/folder/${this.state.folder.id}/data-insights/warning-report`);
    }else{
      window.history.replaceState(null, null, `/smart-box/folder/${this.state.folder.id}/data-insights/cull-report`);
    }

  }
  onClickStatusStar = () => {
    this.onClickReportCullStar()
  }
  onGetStatusTagsSmartView = (tagSelect, listIds) => {
    let { warningListItems } = this.state
    warningListItems && warningListItems.length > 0 && warningListItems.forEach((item) => {
      if (listIds.includes(item.fileId)) {
        if (tagSelect.icon) {
          item.systemPropertyFileStatusIcon = tagSelect.icon
          item.systemPropertyFileStatus = tagSelect.tagName
          item.systemPropertyFileStatusColor = null
        } else if (tagSelect.color) {
          item.systemPropertyFileStatusColor = tagSelect.color
          item.systemPropertyFileStatusIcon = null
          item.systemPropertyFileStatus = tagSelect.tagName
        } else if (!tagSelect.color && !tagSelect.icon) {
          item.systemPropertyFileStatusColor = null
          item.systemPropertyFileStatusIcon = null
          item.systemPropertyFileStatus = tagSelect.tagName
        }
      }
    })
    this.setState({
      warningListItems
    })
  }
  onTableWarningReport = (warningListItems) => {
    this.setState({
      warningListItems: warningListItems
    })
  }
  render() {
    const { folder, redactionMode, documentBytes, documentId, totalPages, width, height, fileName, finalizedDocPath,
      smartBoxDocumentPath, activeKeyTab, treeForSettings, systemPropertyFileStatus, doc_classification, activeTab, visibleToDownload, statusReportStar,
      activeErrorTab, statusWarningsReport, fileWarnings, systemPropertyFileColor, systemPropertyFileIcon,lockSmartViewChanges } = this.state
    const { TabPane } = Tabs
    let basePath = ''
    if (this.props.smartBoxBaseDataTree && this.props.smartBoxBaseDataTree.length > 0) {
      basePath = this.props.smartBoxBaseDataTree[0].folderPath
    }
    if (!folder) {
      const path = '/smart-box'
      return <Navigate to={ path } />
    }
    const newKey = activeKeyTab
    //let treeForSettingsDashboard = []
    //let treeForReportAi = []
    if (activeTab === 'dashboard') {
      if (typeof treeForSettings === 'object' && treeForSettings && treeForSettings.length > 0) {
        //treeForSettingsDashboard = treeForSettings && treeForSettings.length > 0 && treeForSettings.filter((elem) => (elem.title !== 'Images' && elem.title !== 'Manual term'))
      } else {
        //treeForSettingsDashboard = treeForSettings
      }
    }
      if (typeof treeForSettings === 'object' && treeForSettings && treeForSettings.length > 0) {
        //treeForReportAi = treeForSettings && treeForSettings.length > 0 && treeForSettings.filter((elem) => (elem.title !== 'Images' && elem.title !== 'Manual term'))
      }
    return (
      <React.Fragment>
        
        { this.props.smartBoxVisibleAddNote === true &&
        <DirectoryNote closeDocumentNote={(e) => this.visibleDirectoryNote(e, false)} documentId={ this.props.smartBoxSelectedDocumentId.documentId } user={ this.props.user } />
        }
        <div className={ !redactionMode ? "smart-box-folder-settings_container smart-box-folder-settings_new-container" : 'smart-box-folder-settings_container smart-box-folder-settings_new-container smart-box-folder-settings_new-container-update '} key="1">
          { !redactionMode &&
          <React.Fragment>
            {/*<span className="smart-box-folder-opened__breadcrumb--bold breadcrumb-data breadcrumb-top" key={ folder.id }>*/}
            {/*  <Link to="/smart-box/management" key={ folder.id }>Smartbox.ai Home</Link>*/}
            {/*  { this.generateSmartBoxMenu(1) }*/}
            {/*</span>*/}
            <HeaderNavigationBox
              folderParent={ folder }
              filesTable={ this.props.smartBoxBaseDataTree }
              clientId={ this.props.clientId }
              basePath={ folder.name }
              boxIdBasePath={ folder.id }
              activeItem = { 'data-insights' }
              activeErrorTab={ activeErrorTab }/>


            <div className="smart-box-folder-settings_data-insight">
              <div className="insight_wrapper">
                { Object.keys(folder).length > 0 &&
                <div className="update-tabs-deduplicate">
                  <Tabs onChange={ this.onChangeTab } key={ folder.id } type="card" className="child-tabs child-tabs-data" defaultActiveKey={ newKey }>
                    {/*<TabPane disabled={ folder.statisticsReady === 'false' } tab="Dashboard" key="dashboard">*/}
                    {/*  <DashboardTab folder={ folder } treeForSettings= { treeForSettingsDashboard }/>*/}
                    {/*</TabPane>*/}
                    {/*<TabPane disabled={ folder.statisticsReady === 'false' } tab={ <span className="content-name-tabs"><span className="border-tab"></span><span className="duplicate-class">Duplicates</span></span> } key="duplicates">*/}
                    {/*  <Duplicates folder={ folder } smartBoxFilesTable={ this.props.smartBoxFilesTable } fileDuplicateOpen={ fileDuplicateOpen } hashFile={ hashFile } />*/}
                    {/*</TabPane>*/}
                    {/*<TabPane disabled={ folder.statisticsReady === 'false' }  hashFile={ hashFile } tab={ <span className="content-name-tabs"><span className="border-tab"></span><span className="duplicate-class">Near duplicates</span></span> } key="near">*/}
                    {/*  <NearDeduplication folder={ folder } />*/}
                    {/*</TabPane>*/}
                    {/*<TabPane disabled={ folder.statisticsReady === 'false' } tab={ <span className="content-name-tabs"><span className="border-tab"></span><span className="duplicate-class">Clutter</span></span> } key="clutter">*/}
                    {/*  <Clutter folder={ folder } />*/}
                    {/*</TabPane>*/}
                    <TabPane tab="Dashboard" key="ReportDashboard" className="dashboard-tab">
                      <ReportDashboard folder={ folder }
                                       treeForSettings= { treeForSettings }
                                       folderParent={ folder }
                                       activeTab={activeTab}/>
                    </TabPane>
                    <TabPane tab={ <span className="content-error-tabs"><span className="border-tab-error"></span>
                      <span className="error-class">Error report</span></span> } key="errorReport">
                      <ErrorReport
                          folder={ folder }
                          basePath={ basePath }
                          smartBoxParentFolderName={ this.props.smartBoxParentFolderName }
                          activeTab={activeTab}/>
                    </TabPane>
                    <TabPane tab={ <span className="content-error-tabs"><span className="border-tab-error"></span>
                      <span className="error-class">Warnings</span><span className={ folder.show_exists_files_with_warnings_notification === 'true' ?
                          'icon-v23-warning-1': '' }></span></span> } key="warningsReport">
                      <WarningReport
                          folder={ folder }
                          basePath={ basePath }
                          smartBoxParentFolderName={ this.props.smartBoxParentFolderName }
                          activeTab={activeTab}
                          handlePreview={ this.handlePreview }
                          onTableWarningReport={ this.onTableWarningReport }
                      />
                    </TabPane>
                    <TabPane tab={ <span className="content-error-tabs"><span className="border-tab-error"></span>
                      <span onClick={() => this.onClickStatusStar()} className="content-dots-report"><span className="error-class error-class-report">
                        Cull report
                      </span><span className={ statusReportStar ? "dots-report" : '' }></span></span></span> } key="sweepReport">
                      <CullReport 
                        folder={ folder } 
                        basePath={ basePath } 
                        smartBoxParentFolderName={ this.props.smartBoxParentFolderName }
                        folderParent={ folder }
                        activeTab={activeTab}/>
                    </TabPane>
                    {/*<TabPane tab="Insights" key="insights">*/}
                    {/*  <FolderInsightsTab folder={ folder } treeForSettings= { treeForSettingsInsight } checkBeingProcessed={checkBeingProcessed}/>*/}
                    {/*</TabPane>*/}
                    {/*<TabPane tab="List of all terms" key="list">*/}
                    {/*  <ListAllTerms onActiveKeyTab={this.onActiveKeyTab} folder={ folder }/>*/}
                    {/*</TabPane>*/}
                    {/*<TabPane tab="Overview analysed" key="overview">*/}
                    {/*  <OverviewAnalysed folder={ folder } />*/}
                    {/*</TabPane>*/}
                    {/*<div>*/}
                    {/*  <span>ddd</span>*/}
                    {/*</div>*/}
                  </Tabs>
                </div>
                }
              </div>
            </div>
            { this.renderRenameFolderModal() }
          </React.Fragment>
          }
          { redactionMode &&
          <div className="redacting-container col-md-12">
            { documentBytes &&
            <RedactionTool
              pdfBytes={ generateBlobFromBase64(documentBytes) }
              documentId={ documentId }
              initialName={ fileName }
              totalPages={ totalPages }
              closeRedacting={ this.closeRedacting }
              clientId={ this.props.clientId }
              dbHeight={ height }
              dbWidth={ width }
              setHeaderVisibility={ this.props.setHeaderVisibility }
              setSidebarVisibility={ this.props.setSidebarVisibility }
              setFooterVisibility={ this.props.setFooterVisibility }
              isSmartBoxApplication={ true }
              smartBoxDocumentId= { smartBoxDocumentPath }
              fetchFiles={ this.fetchFiles }
              visitedRedactionSmart= 'smart'
              finalizedDocPath = { finalizedDocPath }
              systemPropertyFileStatus = { systemPropertyFileStatus }
              systemPropertyFileStatusColor={ systemPropertyFileColor }
              systemPropertyFileStatusIcon={ systemPropertyFileIcon }
              doc_classification = { doc_classification }
              visibleToDownload = { visibleToDownload }
              fileWarnings = { fileWarnings }
              onGetStatusTagsSmartView = { this.onGetStatusTagsSmartView }
              lockSmartViewChanges={ lockSmartViewChanges }
            />
            }
          </div>
          }
        </div>
      </React.Fragment>
    )
  }
}

SmartBoxFolderDataInsights= withRouter(SmartBoxFolderDataInsights)

const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id,
  user: state.userReducer.user,
  smartBoxFilesTable: state.smartBox.smartBoxFilesTable,
  smartBoxBaseDataTree: state.smartBox.smartBoxBaseDataTree,
  smartBoxVisibleAddNote: state.smartBox.smartBoxVisibleAddNote,
  smartBoxSelectedDocumentId: state.smartBox.smartBoxSelectedDocumentId,
  smartBoxSelectedDocumentDuplicate: state.smartBox.smartBoxSelectedDocumentDuplicate,
  smartBoxParentFolderName: state.smartBox.smartBoxParentFolderName,
})

export default compose(
  connect(mapStateToProps, {
    setHeaderVisibility,
    setSidebarVisibility,
    setFooterVisibility,
    setSmartBoxFilesTable,
    setSmartBoxFetchFiles,
    // setSmartBoxBaseDataTree,
    setSmartBoxToggleFilePreview,
    setSmartBoxDirectoryNote,
    setSmartBoxSelectedDocuments,
    setSmartBoxAnalysisPercent,
    setSmartBoxSelectedDuplicateFile
  })
)(SmartBoxFolderDataInsights)