import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import './Completed.scss'
import { Tooltip } from 'antd';
import withRouter from '../../../../private-routers/withRouter'

const completedLabel = ({ completionDate }) => {
  if (!completionDate) return 'Completed'
  return `Completed on ${ moment(completionDate).format('MMMM YYYY') }`
}

let Completed = ({ assessment, processId, processStatus, isAdmin, type, name }) => (
  <div className="completeAssessment-wrapper">
    <div className="completeAssessment-actionsContainer">
      <React.Fragment>
        {
          assessment.riskIndicatorsReportAvailable &&
          isAdmin &&
          <React.Fragment>
            <Tooltip title="DPIA Report"><span className="iconReport" onClick={ seeReport.bind(this, processId, assessment.id, name) } /></Tooltip>
            <Tooltip title="Download Report"><span className="iconDownload" onClick={ seeDetailReport.bind(this, processId, assessment.id, name) } /></Tooltip>
          </React.Fragment>
        }
        {
          assessment.riskIndicatorsReportAvailable &&
          !isAdmin &&
          <React.Fragment>
            <Tooltip title="PIA Report"><span className="iconReportDisabled" /></Tooltip>
            <Tooltip title="Download Report"><span className="iconDownloadDisabled" /></Tooltip>
          </React.Fragment>
        }

        {
          !assessment.riskIndicatorsReportAvailable &&
          <React.Fragment>
            <Tooltip title="No data available"><span className="iconReportDisabled" /></Tooltip>
            <Tooltip title="No data available"><span className="iconDownloadDisabled" /></Tooltip>
          </React.Fragment>
        }
        <Tooltip title="View Answers"><span className="iconView" onClick={ seeQuestions.bind(this, assessment.id) } /></Tooltip>
      </React.Fragment>
    </div>

    <div className="completeAssessment-infoContainer">
      <div className="completeAssessment-textContainer">
        <Link to={ `/dpia/report/${ processId }/${ assessment.id }` } className="piaActiveAssessment_link">
          {type}
        </Link>
      </div>
      <div className="completeAssessment-status">
        { processStatus !== 'INACTIVE' &&
          <Link to={ `/dpia/report/${ processId }/${ assessment.id }` } className="completeAssessment-completed">
            { completedLabel(assessment) }
          </Link>
        }

        { processStatus === 'INACTIVE' &&
          <span className="completeAssessment-completed">
            Terminated { moment(assessment.validStartDate).format('MMMM YYYY') }
          </span>
        }
      </div>
      <div className="completeAssessment-iconContainer">
        <Link to={ `/dpia/report/${ processId }/${ assessment.id }` }>
          <span className="iconCheckDisabled" />
        </Link>
      </div>
    </div>
  </div>
)

const seeQuestions = (id) => {
  this.props.navigate(`/dpia/assessment/${ id }`)
}

const seeReport = (processId, id, name) => {
  this.props.navigate({
    pathname: `/dpia/report/${ processId }/${ id }`,
    state: { name }
  })
}

const seeDetailReport = (processId, id, name) => {
  this.props.navigate({
    pathname: `/dpia/report/detail/${ processId }/${ id }`,
    state: { name }
  }
  ) }

Completed = withRouter(Completed)

Completed.propTypes = {
  assessment: PropTypes.object.isRequired,
  processId: PropTypes.number.isRequired
}

export default Completed