import React from 'react'
import PropTypes from 'prop-types'

import { DataLinesConsumer } from '../../../../contexts/OspreyAnalytics/DataLines'

const WithDataLines = (Component) => {
  return class WithDataLines extends React.Component {
    render = () => {
      return (
        <DataLinesConsumer>
          { ({ getDataLines, ...rest }) => (
            <InitDataLines getDataLines={ getDataLines }>
              <Component {...this.props} {...rest} />
            </InitDataLines>
          )}
        </DataLinesConsumer>
      )
    }
  }
}

class InitDataLines extends React.Component {
  componentDidMount = () => {
    this.props.getDataLines()
  }

  render = () => {
    return (
      this.props.children 
    )
  }
}

InitDataLines.propTypes = {
  children: PropTypes.element.isRequired,
  getDataLines: PropTypes.func.isRequired
}

export default WithDataLines