import initialStateApplications from './initialStateApplications'
import {
  SAR_VISIBLE,
  DOC_LOCKER_VISIBLE,
  ASSET_REGISTER_VISIBLE,
  SMART_BOX_VISIBLE,
  DPIA_VISIBLE,
  REMOVE_ALL_INTERVAL_TO_APPLICATION,
  ADD_INTERVAL_TO_APPLICATION,
  WORKFLOW_VISIBLE,
  DATA_MAPPING_VISIBLE,
  ADDITIONAL_ENVIRONMENTS,
  BUSINESS_PROCESSES_VISIBLE,
  SOURCE_MAPPING_VISIBLE,
  CONNECTORS_VISIBLE
} from '../actions/actionTypes'

/*const INITIAL_STATE = {
  sarVisible: true,
  docLockerVisible: true,
  assetRegisterVisible: true,
  smartBoxVisible: true,
  dpiaVisible: true,
  applicationIntervals: []
}*/
const applicationsReducer = (state = initialStateApplications, action) => {
  switch (action.type) {
    case SAR_VISIBLE:
      return { ...state, sarVisible: action.payload }
    case DOC_LOCKER_VISIBLE:
      return { ...state, docLockerVisible: action.payload }
    case ASSET_REGISTER_VISIBLE:
      return { ...state, assetRegisterVisible: action.payload }
    case SMART_BOX_VISIBLE:
      return { ...state, smartBoxVisible: action.payload }
    case DPIA_VISIBLE:
      return { ...state, dpiaVisible: action.payload }
    case WORKFLOW_VISIBLE:
      return { ...state, workflowAppVisible: action.payload }
    case ADDITIONAL_ENVIRONMENTS:
      return { ...state, additionalEnvironmentsVisible: action.payload }
    case DATA_MAPPING_VISIBLE:
      return { ...state, dataMappingAppVisible: action.payload }
    case REMOVE_ALL_INTERVAL_TO_APPLICATION:
      return { ...state, applicationIntervals: action.payload }
    case ADD_INTERVAL_TO_APPLICATION:
      return { ...state, applicationIntervals: [...state.applicationIntervals, action.payload] }
    case BUSINESS_PROCESSES_VISIBLE:
      return { ...state, businessProcessesVisible: action.payload }
    case SOURCE_MAPPING_VISIBLE:
      return { ...state, sourceMappingVisible: action.payload }
    case CONNECTORS_VISIBLE:
      return { ...state, connectorsVisible: action.payload }
    default:
      return state
  }
}

export default applicationsReducer