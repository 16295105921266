import { combineReducers } from 'redux'
import manageGroups from './manageGroupsReducer'
import messageArea from './messageAreaReducer'
import subscription from './subscriptionReducer'
import question from './questionReducer'
import shell from './shellReducer'
import user from './userReducer'
import applicationsReducer from './applicationsReducer'

import { reducer as formReducer } from 'redux-form'
import sarFormReducer from './sarFormReducer'
import adminReducer from './adminReducer'
import smartBoxReducer from './smartBoxReducer'
const rootReducer = combineReducers({
  manageGroupsReducer: manageGroups,
  messageAreaReducer: messageArea,
  subscriptionReducer: subscription,
  questionReducer: question,
  shellReducer: shell,
  userReducer: user,
  form: formReducer,
  sar: sarFormReducer,
  admin: adminReducer,
  applications: applicationsReducer,
  smartBox: smartBoxReducer,
})

export default rootReducer