import PropTypes from 'prop-types'
import React from 'react'
import './SemiCircle.css'

const SemiCircle = ({ status, value }) => (
  <div className="semiCircle-circle">
    <div className="semiCircle-border"></div>
    <div className={ getActiveClass(status) } style={ getStyle(value) }></div>
    <span className={ getSpanClass(status) }>{ value }</span>
  </div>
)

const getActiveClass = (status) => {
  const active = `${ status }Active`
  return `semiCircle-${ active }`
}

const getSpanClass = (status) => {
  const span = `${ status }Span`
  return `semiCircle-${ span }`
}

const getStyle = (value) => {
  const degree = 180 + (180 * value) / 100
  return {
    transform: `rotate(${ degree }deg)`
  }
}

SemiCircle.propTypes = {
  status: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired
}

export default SemiCircle