import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

import Loading from '../../Common/Loading'

import imgLogoAlfresco from '../../../assets/logo-alfresco.png'
import imgLogoDropbox from '../../../assets/logo-dropbox.png'
import imgLogoSharepoint from '../../../assets/logo-sharepoint.png'

import './ConnectorItem.css'

class ConnectorItem extends React.Component {
  render = () => {
    const { connector } = this.props

    return (
      <div className="privacyDashboardConnectorItem__wrapper">
        <div className="privacyDashboardConnectorItem__info">
          <div className="privacyDashboardConnectorItem__logo">
            { showConnectorName(connector.name) }
          </div>
          { connector.isFetchingInfo
            ? <div className="privacyDashboard__connectorItem-loading"><Loading /></div>
            : <div>
              <strong>{ Math.round(connector.docSize/1024/1024) } MB</strong> - { connector.filesCounter } objects
            </div>
          }
        </div>
        { (connector.description || connector.scanDate) &&
          <div className="privacyDashboardConnectorItem__actions">
            { connector.description &&
              <p>Users description</p>
            }
            { connector.scanDate &&
              <span>Soft scan: { moment.unix(connector.lastScan).format('MMMM Do, YYYY') }</span>
            }
          </div>
        }
      </div>
    )
  }
}

const showConnectorName = (connectorName) => {
  if (connectorName.match(new RegExp('alfresco', 'i'))) return (
    <img src={ imgLogoAlfresco } alt="Logo Alfresco" />
  )
  if (connectorName.match(new RegExp('dropbox', 'i'))) return (
    <img src={ imgLogoDropbox } alt="Logo Dropbox" />
  )
  if (connectorName.match(new RegExp('sharepoint', 'i'))) return (
    <img src={ imgLogoSharepoint } alt="Logo Sharepoint" />
  )

  return connectorName
}

ConnectorItem.propTypes = {
  connector: PropTypes.object.isRequired
}

export default ConnectorItem