import React from 'react'
import './NewBoxCreate.scss'
import {Button, Checkbox, Popover, Collapse} from 'antd'
import { getSmartBoxAllRegexList
} from '../../../../api/SmartBox'
import { CompactPicker } from 'react-color'
import CustomNotificationManager from '../../../Common/CustomNotificationManager'
import cookie from "react-cookies";
import {compose} from "redux";
import {connect} from "react-redux";
import {setSmartBoxNewBox} from "../../../../actions/smartBoxActions";
class NewBoxCustom2 extends React.Component {

    constructor(props) {
        super(props)
        this.state= {
            businessData: [],
            checkedBusiness: [],
            colorPickerVisible: {},
            color: '#fff',
            regex: '',
            title: window.title,
            description: '',
            regexList: [],
            colorPickerVisibleRegular: false,
            colorPickerVisibleTerms: false,
            colorRegular: 'rgba(77, 100, 113, 1)',
            colorTerms: 'rgba(77, 100, 113, 1)',
            manualTerms: '',
            manualTermsList: [],
            selectedList: [],
            selectedListManualTerms: [],
            checkedManualTerms: [],
            checkedRegularExp: [],
            validationNameRegEx: false,
            validationRegex: false,
            activeOpenRegEx: false,
            expandKeysRegex: [],
            regExSelectAllChecked: false
        }
    }
    componentDidMount() {
        const { listNewBoxRegExp, selectedListRegExp, colorRegular, manualTermsList, selectedListManualTerms, colorTerms } = this.props
        const keysSelectedRegEx = []
        const newManualTermsList = []
        const keysSelectedTerm = []
        listNewBoxRegExp && listNewBoxRegExp.length > 0 && listNewBoxRegExp.forEach((list) => {
            selectedListRegExp && selectedListRegExp.length > 0 && selectedListRegExp.forEach((name) => {
                if (list.title === name) {
                    keysSelectedRegEx.push(list.id)
                }
            })
        })
        if (colorRegular) {
            this.setState({
                colorRegular: colorRegular
            })
        }
        if (colorTerms) {
            this.setState({
                colorTerms: colorTerms
            })
        }
        manualTermsList && manualTermsList.length > 0 && manualTermsList.forEach((term, key) => {
            newManualTermsList.push({
                id: key,
                name: term
            })
            selectedListManualTerms && selectedListManualTerms.length > 0 && selectedListManualTerms.forEach((name) => {
                if (term === name) {
                    keysSelectedTerm.push(key)
                }
            })
        })
        this.setState({
            regexList: listNewBoxRegExp,
            checkedRegularExp: keysSelectedRegEx,
            manualTermsList: newManualTermsList,
            checkedManualTerms: keysSelectedTerm
        }, () => {
            this.fetchRegularExpressions()
        })
    }

    onRenderStatusSelectAllDictionary = () => {
        const { regexList } = this.state
        let uniqueSelectRegexList = []
        if (this.props.smartBoxNewBox && this.props.smartBoxNewBox.selectRegexList && this.props.smartBoxNewBox.selectRegexList.length > 0) {
            uniqueSelectRegexList = [...new Set(this.props.smartBoxNewBox.selectRegexList)]
        }
        if (regexList && regexList.length > 0) {
            this.setState({
                regExSelectAllChecked: true
            })
        } else {
            this.setState({
                regExSelectAllChecked: false
            })
        }
    }
    openInRegularEdit = (e) => {
        e.preventDefault()
        window.open(window.location.href)
        cookie.save('openRegularEdit', 'openTrueRegEx', { path: '/', maxAge: 60*60 })
    }
    fetchRegularExpressions = () => {
        const selectedListKeys = []
        getSmartBoxAllRegexList().then((res) => {
            if (res.status === 200) {
                let regexList = []
                for (const value of Object.values(res.data)) {
                    delete value.folderId
                    delete value.id
                    regexList.push(value)
                }
                regexList.sort((a,b) => a.title.localeCompare(b.title) )
                regexList && regexList.length > 0 && regexList.forEach((item, index) => {

                        item.id = index
                        selectedListKeys.push(item.title)

                })
                this.setState({ regexList,
                    selectedList: this.props.smartBoxNewBox.selectRegexList && this.props.smartBoxNewBox.selectRegexList.length > 0 ? this.props.smartBoxNewBox.selectRegexList:
                      this.props.statusBackRegEx && this.props.smartBoxNewBox.statusRegEx ? this.props.smartBoxNewBox.selectRegexList : selectedListKeys
                }, () => {
                    this.onRenderStatusSelectAllDictionary()
                })
            }
        }).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
              }else{
                console.log(error)
              }
        })
    }

    convertRgbaToString = ({ r, g, b, a }) => {
        return `rgba(${ r }, ${ g }, ${ b }, ${ a })`
    }
    onRegularExpCheckboxChange = (values) => {
        this.setState({
            checkedRegularExp: values,
        })
    }
    selectAll = (e, type) => {
        const { businessData, manualTermsList, regexList } = this.state
        let { businessSaveStep } = this.state
        let { checkedManualTerms, checkedRegularExp, selectedList, selectedListManualTerms } = this.state
        if (type === 'business') {
            let { checkedBusiness } = this.state
            if (businessData && businessData.length) {
                if (!e.target.checked) {
                    checkedBusiness = []
                } else {
                    checkedBusiness = []
                    businessData.map((item) => {
                        checkedBusiness.push(item.id)
                        return true
                    })
                }
                businessSaveStep = 1
            }
            this.setState({ checkedBusiness, businessSaveStep, businessSelectAllChecked: e.target.checked })
        } else if (type === 'manual') {
            if (!e.target.checked) {
                checkedManualTerms = []
                selectedListManualTerms = []
            } else {
                manualTermsList.map((item) => {
                    checkedManualTerms.push(item.id)
                    selectedListManualTerms.push(item.name)
                    return true
                })
            }
            this.setState({
                checkedManualTerms,
                selectedListManualTerms
            })
        } else if (type === 'terms') {
            if (!e.target.checked) {
                checkedRegularExp = []
                selectedList = []
            } else {
                regexList.map((item) => {
                    checkedRegularExp.push(item.id)
                    selectedList.push(item.title)
                    return true
                })
            }
            this.props.setSmartBoxNewBox(this.props.smartBoxNewBox.keysPii, this.props.smartBoxNewBox.checkedBusiness, selectedList, this.props.smartBoxNewBox.statusPii,
                this.props.smartBoxNewBox.statusBusiness, true)
            this.setState({
                checkedRegularExp: checkedRegularExp,
                selectedList,
                regExSelectAllChecked: e.target.checked
            })
        }
        // this.setState({
        //     activeOpenRegEx: false
        // })
    }
    onChange = (e, type) => {
        switch (type) {
            case 'regex-input':
                this.setState({
                    regex: e.target.value
                })
                break
            case 'regex-name':
                this.setState({
                    title: e.target.value
                })
                break
            case 'regex-description':
                this.setState({
                    description: e.target.value
                })
                break
            case 'custom-terms-input':
                this.setState({
                    manualTerms: e.target.value
                })
                break
            default:
                break
        }
    }
    stop = (e) => {
        e.stopPropagation()
        e.preventDefault()
    }
    openColorPickerVisible = (e) => {
        this.stop(e)
        this.setState({ colorPickerVisibleRegular: true })
    }
    closeAndSaveColorPickerRegular = (color, e) => {
        this.stop(e)
        const convertedColor = this.convertRgbaToString(color.rgb)
        this.setState({ colorRegular: convertedColor, colorPickerVisibleRegular: false })
    }
    renderPopOver = () => {
        const { colorRegular, colorPickerVisibleRegular } = this.state
        const renderColorPicker = (
            <React.Fragment>
                <div className="business-term-color-picker">
                    <CompactPicker
                        className="color-picker-a"
                        colors={['rgba(107, 91, 149, 1)', 'rgba(254, 178, 54, 1)', 'rgba(214, 65, 97, 1)',
                            'rgba(255, 123, 37, 1)', 'rgba(75, 195, 193, 1)', 'rgba(194, 194, 71, 1)', 'rgba(181, 231, 160, 1)',
                            'rgba(191, 108, 64, 1)', 'rgba(62, 68, 68, 1)', 'rgba(130, 183, 75, 1)', 'rgba(64, 93, 39, 1)',
                            'rgba(193, 148, 106, 1)', 'rgba(60, 136, 180, 1)', 'rgba(200, 100, 91, 1)', 'rgba(238, 162, 154, 1)',
                            'rgba(201, 76, 76, 1)', 'rgba(124, 108, 6, 1)', 'rgba(15, 195, 71, 1)', 'rgba(231, 82, 114, 1)',
                            'rgba(238, 140, 54, 1)', 'rgba(92, 212, 209, 1)', 'rgba(211, 211, 88, 1)', 'rgba(198, 248, 177, 1)',
                            'rgba(192, 125, 81, 1)', 'rgba(77, 100, 113, 1)', 'rgba(237, 226, 220, 1)', 'rgba(132, 206, 235, 1)']}
                        disableAlpha={true}
                        color={colorRegular}
                        onChange={(colorRegular, e) => this.closeAndSaveColorPickerRegular(colorRegular, e)}
                    />
                </div>
            </React.Fragment>
        )
        return (
            <Popover content={renderColorPicker} trigger="click" visible={ colorPickerVisibleRegular } >
                <span className="regular-selected-color" style={{ backgroundColor: colorRegular }} onClick={ (e) => this.openColorPickerVisible(e) } />
            </Popover>
        )
    }
    openColorPickerVisibleTerms = (e) => {
        this.stop(e)
        this.setState({ colorPickerVisibleTerms: true })
    }
    stop = (e) => {
        e.stopPropagation()
        e.preventDefault()
    }
    selectItem = (e, id) => {
        const { selectedList, regexList } = this.state
        //this.stop(e)
        // if (activeOpenRegEx === true) {
        //     this.setState({
        //         activeOpenRegEx: false
        //     })
        // } else {
        //     this.setState({
        //         activeOpenRegEx: false
        //     })
        // }
        if (e.target.checked) {
            selectedList.push(id)
        } else {
            let selectedKey = -1
            if (selectedList && selectedList.length > 0 && selectedList.includes(id)) {
                selectedList.map((item, key) => {
                    if (item === id) {
                        selectedKey = key
                    }
                    return true
                })
            }
            if (selectedKey >= 0) {
                selectedList.splice(selectedKey, 1)
            }
        }
        let uniqueSelectRegexList = []
        if (this.props.smartBoxNewBox && this.props.smartBoxNewBox.selectRegexList && this.props.smartBoxNewBox.selectRegexList.length > 0) {
            uniqueSelectRegexList = [...new Set(this.props.smartBoxNewBox.selectRegexList)]
        }
        if (regexList && uniqueSelectRegexList && regexList.length > 0 && (uniqueSelectRegexList.length === regexList.length)) {
            this.setState({
                regExSelectAllChecked: true
            })
        } else {
            this.setState({
                regExSelectAllChecked: false
            })
        }
        this.props.setSmartBoxNewBox(this.props.smartBoxNewBox.keysPii, this.props.smartBoxNewBox.checkedBusiness, selectedList, this.props.smartBoxNewBox.statusPii,
             this.props.smartBoxNewBox.statusBusiness, true)
        this.setState({ selectedList,
            // expandKeysRegex: []
        })
    }

    saveRegularExpression = () => {
        const { regex, title, description, regexList } = this.state
        const addRegexList = regexList
        let validationName = false
        let validationReg = false
        regexList && regexList.length > 0 && regexList.forEach((reg) => {
            if (reg.title === title) {
                validationName = true
            } else if (reg.regex === regex) {
                validationReg = true
            }
        })
        if (validationName) {
            this.setState({
                validationNameRegEx: true,
                validationRegex: false
            })
        } else if (validationReg) {
            this.setState({
                validationRegex: true,
                validationNameRegEx: false
            })
        } else {
            addRegexList.push({
                title: title,
                description: description,
                regex: regex,
                id: regexList.length
            })
            this.setState({
                regexList: addRegexList,
                validationNameRegEx: false,
                validationRegex: false
            }, () => {
                this.setState({
                    regex: '',
                    title: '',
                    description: '',
                })
            })
        }
    }

    onCreateBoxOrNextPage = (e, status) => {
        const { regexList, selectedList, colorRegular, manualTermsList } = this.state
        const manualList = []
        manualTermsList && manualTermsList.length > 0 && manualTermsList.forEach((name) => {
            manualList.push(name.name)
        })
        this.props.onCreateBoxOrNextPage(e, status)
        //this.props.onGetValuesCustom(checkedBusiness, regexList, selectedList, colorRegular, colorTerms, manualList, selectedListManualTerms)
        this.props.onGetValuesCustomTwo(regexList, selectedList, colorRegular)
    }
    handleBackCustom = (e, status) => {
        this.props.handleCancelBackPage(e, status)
    }
    thisActive = (isActive) => {
        return true
    }
    toggleAccordeon = (key) => {
        const { regexList }= this.state
        let  { regexActive }= this.state
        let regexOpen = key
        if (key.length) {
            regexOpen= key[key.length-1]
            if (regexList && regexList.length) {
                regexList.map( (item) => {
                    if (item.id === regexOpen) {
                        regexActive = item
                    }
                    return true
                })
            }
        }
        this.setState({ regexOpen, regexActive, expandKeysRegex: key })
    }
    onExpandRegEx = (e) => {
        const { expandKeysRegex } = this.state
        if (expandKeysRegex && expandKeysRegex.length > 0) {
            this.setState({
                activeOpenRegEx: true
            })
        } else {
            this.setState({
                activeOpenRegEx: true
            })
        }
    }
    stop = (e) => {
        e.stopPropagation()
        e.preventDefault()
    }
    onExpandContentCollapse = (e) => {
        const { expandKeysRegex } = this.state
        if (expandKeysRegex && expandKeysRegex.length > 0) {
            this.setState({
                activeOpenRegEx: true
            })
        } else {
            this.setState({
                activeOpenRegEx: true
            })
        }
    }
    render() {
        const { regexList, activeOpenRegEx, expandKeysRegex, selectedList, regExSelectAllChecked } = this.state
        //const allowAdd = title && description && regex
        const { Panel } = Collapse
        let uniqueSelectRegexList = []
        if (this.props.smartBoxNewBox && this.props.smartBoxNewBox.selectRegexList && this.props.smartBoxNewBox.selectRegexList.length > 0) {
            uniqueSelectRegexList = [...new Set(this.props.smartBoxNewBox.selectRegexList)]
        }
        return (
            <div className='box-setup-custom'>
                <div className="header-box-custom">
          <span className="title-custom">
              AI configuration
          </span>
                    {/*<span className="description-custom">*/}
                    {/*    Add customised search criteria.*/}
                    {/*</span>*/}
                </div>
                <div className="box-setup-custom__content box-setup-custom__content-update2">
                    <div className="business-terms-setup regular-expressions-setup">
                        <div className="title-box-setup">Regular expressions</div>
                        <div className="content-business-configuration">
                            <div className="row">
                                <div className="col-md-3">
                                    {/*<div className="content-inputs">*/}
                                    {/*    <div className="insert-regular-expression">*/}
                                    {/*        <Input className="input-insert-expression" onChange={ (e) => this.onChange(e, 'regex-name') } placeholder="Name" value={ title }/>*/}
                                    {/*        { validationNameRegEx &&*/}
                                    {/*        <span className="validation-reg-ex">name already exist</span>*/}
                                    {/*        }*/}
                                    {/*    </div>*/}
                                    {/*    <div className="insert-regular-expression">*/}
                                    {/*        <Input className="input-insert-expression" onChange={ (e) => this.onChange(e, 'regex-description') } placeholder="Description" value={ description }/>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="insert-regular-expression">*/}
                                    {/*        <Input className="input-insert-expression" onChange={ (e) => this.onChange(e, 'regex-input') } placeholder="/ insert regular expression" value={ regex }/>*/}
                                    {/*        <div className="add-regular-expression">*/}
                                    {/*            <button onClick={ this.saveRegularExpression } className="icon-add-b" disabled={ !allowAdd } style={{ cursor: allowAdd ? 'pointer': 'not-allowed' }}/>*/}
                                    {/*        </div>*/}
                                    {/*        { validationRegex &&*/}
                                    {/*        <span className="validation-reg-ex">regex already exist</span>*/}
                                    {/*        }*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="content-list-setup content-list-setup-reg-ex">
                                        <div className="select-all-regular">
                                            <div className="select-all-regular-ex">
                                            <Checkbox className={ selectedList && selectedList.length > 0 && (selectedList.length < regexList.length) ? "select-all-checkbox select-all-checkbox-parent" :
                                                uniqueSelectRegexList && uniqueSelectRegexList.length > 0 && (uniqueSelectRegexList.length === regexList.length) ? 'select-all-checkbox select-all-checkbox-finish' :
                                                selectedList && selectedList.length > 0 && (selectedList.length === regexList.length) ? 'select-all-checkbox select-all-checkbox-finish' : 'select-all-checkbox' }
                                                      disabled={ regexList.length === 0 }
                                                      checked={ regExSelectAllChecked }
                                                      onChange={(e) => this.selectAll(e, 'terms')}>Select all</Checkbox>
                                            {/*{*/}
                                            {/*    this.renderPopOver()*/}
                                            {/*}*/}
                                                <span onClick={(e) => this.openInRegularEdit(e)} className='icon-v18-edit'></span>
                                            </div>
                                        </div>
                                        <div className="content-list-view-add">
                                            {/*<Checkbox.Group value={ checkedRegularExp } onChange={ this.onRegularExpCheckboxChange } >*/}
                                            {/*    {*/}
                                            {/*        regexList && regexList.length > 0 && regexList.map((regex, key) => {*/}
                                            {/*            return (*/}
                                            {/*                <Checkbox*/}
                                            {/*                    className= "radio-style"*/}
                                            {/*                    key= { key }*/}
                                            {/*                    value={ key }*/}
                                            {/*                    onClick= { e => this.stop(e)}*/}
                                            {/*                    checked= { selectedList.includes(regex.title) }*/}
                                            {/*                    onChange={ (e) => this.selectItem(e, regex.title) }*/}
                                            {/*                    //onClick= { (e) => this.stop(e)}*/}
                                            {/*                >*/}
                                            {/*                    /!*{`${ regex.title }`}*!/*/}
                                            {/*                </Checkbox>*/}
                                            {/*            )*/}
                                            {/*        })*/}
                                            {/*    }*/}
                                            {/*</Checkbox.Group>*/}

                                            { regexList && regexList.length > 0 && regexList.map((regex, key) =>
                                                <div className="content-single-collapse" key= { key }>
                                                    <Checkbox
                                                        className= "radio-style"
                                                        checked= { selectedList && selectedList.length > 0 && selectedList.includes(regex.title) }
                                                        key= { regex.id }
                                                        //onClick= { e => this.stop(e)}
                                                        onChange={ (e)=> this.selectItem(e, regex.title) }
                                                        //disabled = { !optionsEnabled || disabled }
                                                    >
                                                    </Checkbox>
                                                <Collapse
                                                    bordered={false}
                                                    expandIconPosition="right"
                                                    expandIcon={({isActive}) => this.thisActive(isActive)}
                                                    onChange={(key) => this.toggleAccordeon(key)}
                                                >
                                                    {/*{*/}
                                                    {/*    regexList && regexList.length > 0 && regexList.map((regex, key) =>*/}
                                                            <Panel
                                                                //extra={ this.renderEditIcon(regex.id, disabled) }
                                                                showArrow={true}
                                                                key={regex.id}
                                                                header={
                                                                    <div className="content-new-box-reg">
                                                                        {/*<Checkbox*/}
                                                                        {/*    className= "radio-style"*/}
                                                                        {/*    checked= { selectedList.includes(regex.title) }*/}
                                                                        {/*    key= { regex.id }*/}
                                                                        {/*    onClick= { e => this.stop(e)}*/}
                                                                        {/*    onChange={ (e)=> this.selectItem(e, regex.title) }*/}
                                                                        {/*    //disabled = { !optionsEnabled || disabled }*/}
                                                                        {/*>*/}
                                                                        {/*</Checkbox>*/}
                                                                        <span
                                                                            onClick={(e) => this.onExpandContentCollapse(e)}>
                                                                    {`${regex.title}`}<span
                                                                            //onClick={ (e) => this.onExpandRegEx(e) }
                                                                            className="text-radio"><span
                                                                            onClick={(e) => this.onExpandRegEx(e)}>{expandKeysRegex && expandKeysRegex.length > 0 && expandKeysRegex.includes(regex.id.toString()) ?
                                                                            <span
                                                                                className="icon-v16-arrow-medium"></span> :
                                                                            <span
                                                                                className="icon-v16-arrow-medium rotate-arrow-medium"></span>}</span></span>
                                                                    </span>
                                                                    </div>
                                                                }
                                                            >
                                                                <div className='panel-container'>
                                                                    {(activeOpenRegEx) &&
                                                                    <React.Fragment>
                                                                        <div className='panel-container__left'>
                                                                            <p><span
                                                                                className="font-weight-bold">Name:</span> {regex.title}
                                                                            </p>
                                                                            <p><span
                                                                                className="font-weight-bold">Description:</span> {regex.description}
                                                                            </p>
                                                                        </div>
                                                                        <div className='panel-container__right'>
                                                                            <p><span className="font-weight-bold">Regular expression:</span> {regex.regex}
                                                                            </p>
                                                                        </div>
                                                                    </React.Fragment>
                                                                    }
                                                                </div>
                                                            </Panel>
                                                    {/*    )*/}
                                                    {/*}*/}
                                                </Collapse>
                                                </div>
                                            )
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-buttons-action-new-box custom-buttons">
                    <Button className="action-cancel-new-box" onClick={ (e) => this.handleBackCustom(e, 'back-custom-two')}>
                        Back
                    </Button>
                    <div className="statistics-active-new-box">
                        <span className="dot-new-box"></span>
                        <span className="dot-new-box"></span>
                        <span className={ this.props.activePage === 'custom2' ? 'dot-new-box dot-new-box active-dot-new-box' : "dot-new-box" }></span>
                        <span className="dot-new-box"></span>
                    </div>
                    <Button className="action-create-new-box" onClick={ (e) => this.onCreateBoxOrNextPage(e, 'new-box-custom-two') }
                    >
                        Next
                    </Button>
                </div>
            </div>
        )
    }
}

NewBoxCustom2.propTypes = {
}
const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id,
    userId: state.userReducer.user.id,
    smartBoxNewBox: state.smartBox.smartBoxNewBox,
})
export default compose(
    connect(mapStateToProps, {
        setSmartBoxNewBox
    }),
)(NewBoxCustom2)