import React from 'react'
import PropTypes from 'prop-types'
import AddLibrary from './AddLibrary'
import _ from 'lodash'

class ManageDocumentLocker extends React.Component {
  state = {
    addLibraryActive: false,
    importExportActive: false,
    libraryToEdit: {},
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.addLibraryOpened !== this.props.addLibraryOpened && this.props.addLibraryOpened === true) {
      this.setState({
        addLibraryActive: true
      })
    }
    if (prevProps.libraryToEdit !== this.props.libraryToEdit) {
      this.setState({
        libraryToEdit: this.props.libraryToEdit
      })
    }
  }

  setImportExportActive = (value) => {
    this.setState({
      importExportActive: value,
      addLibraryActive: false,
      libraryToEdit: {}
    }, () => this.props.setImportExportActive(value))
  }

  closeAddEdit = () => {
    this.setState({
      addLibraryActive: false,
      libraryToEdit: {}
    }, () => {
      this.props.setLibraryOpenedToFalse()
      this.props.fetchData()
    })
  }

  addLibraryActive = (addLibraryActive) => {
    this.setState({
      addLibraryActive: !addLibraryActive,
      importExportActive: false,
      libraryToEdit: {}
    }, () => {
      if (this.state.addLibraryActive === false) {
        this.props.setLibraryOpenedToFalse()
      }
      this.props.setImportExportActive(false)
    })
  }

  closeAllTabs = () => {
    this.setState({
      addLibraryActive: false,
      importExportActive: false
    }, () => {
      if (this.state.addLibraryActive === false) {
        this.props.setLibraryOpenedToFalse()
      }
      this.props.setImportExportActive(false)})
  }

  render() {
    const { addLibraryActive, importExportActive, libraryToEdit } = this.state
    return (
      <div className="settings-page__action-container">
        <div className="settings-page__action-container__header">
          <div className="title" onClick={this.closeAllTabs}>
            MANAGE DOCUMENT LOCKER
          </div>
          <div className="actions">
            <div className="add-tab" onClick={ () => this.addLibraryActive(addLibraryActive) }>
              <span className="icon-add-b" />
              <span>Add Library</span>
              {addLibraryActive && <i className="up custom-caret" />}
            </div>
            <div className="import-export" onClick={ () => this.setImportExportActive(!importExportActive) }>
              <span className="icon-download-b" />
              <span>Download Library</span>
            </div>
          </div>
        </div>
        { addLibraryActive &&
        <div className="settings-page__action-container__content">
          <AddLibrary closeAddEdit={this.closeAddEdit} library={ libraryToEdit || '' } updateMode={ !_.isEmpty(libraryToEdit) } />
        </div>
        }
      </div>
    )
  }
}

ManageDocumentLocker.propTypes = {
  setImportExportActive: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  libraryToEdit: PropTypes.object.isRequired,
  addLibraryOpened: PropTypes.bool,
  setLibraryOpenedToFalse: PropTypes.func
}

export default ManageDocumentLocker