import React from 'react'
import './FolderRedaction.scss'
import SearchTree from './SearchTree'
import { connect } from 'react-redux'
import { setSmartBoxExploreDataVisibility } from '../../../../actions/smartBoxActions'
import PropTypes from 'prop-types'
import CustomNotificationManager from '../../../Common/CustomNotificationManager'

class FolderInsightsTab extends React.Component {
  state = {
    folderInfo: {
      documents: 125345,
      folders: 325699,
      pages: 1365943,
      found: 200022223,
    },
    isStatisticsPressed: false,
    treeData: [],
    currentActiveParent: '',
    isTreeExpanded: false,
    openPrintReport: false,
    isExploreDataVisible: false
  }
  
  onStatisticsPressed = () => this.setState({ isStatisticsPressed: true })
  componentDidMount() {
    if (this.props.checkBeingProcessed) {
      CustomNotificationManager.info('Data is being processed, please check this page later.')
    }
  }

  setTreeData = (e, data) => {
    e.stopPropagation()
    this.setState({ treeData: data, currentActiveParent: data.key, isStatisticsPressed: true })
  }

  render() {
    const { currentActiveParent } = { ...this.state }
    const { folder } = this.props
    if (folder.id) {
      return (
        <div className="folder-redaction-page folder-insigths-page">
          <div className="folder-redaction-page__tree">
            {this.props.treeForSettings && this.props.treeForSettings.length > 0 &&
              <SearchTree
                onTreePressed={this.onStatisticsPressed}
                tab="folder-insights"
                folder={folder}
                setTreeData={this.setTreeData}
                currentActiveParent={currentActiveParent}
                treeForSettings={this.props.treeForSettings}
              />
            }
            {this.props.treeForSettings.length === 0 &&
             <p className="text-center title">Data is being processed, please check this page later.</p>
            }
          </div>
        </div>
      )
    } 
    return null
    
  }
}

FolderInsightsTab.propTypes = {
  folder: PropTypes.object,
  treeForSettings: PropTypes.array
}

const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id
})
export default connect(mapStateToProps, { setSmartBoxExploreDataVisibility })(FolderInsightsTab)