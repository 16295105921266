import React from 'react'
import './WarningReport.scss'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import {Select, Tooltip, Input, Table, Dropdown, Icon, Button} from "antd";
import {setSmartReprocessingFileDelete} from "../../../../actions/smartBoxActions";
import {customConfirm} from "../../../../utils/AssetRegister/CustomConfirm";
import {
    apiGetBoxErrorReportDeleteStatus, apiGetBoxWarningReportDeleteStatus,
    apiGetTagDocumentClient,
    apiSmartBoxWarningReportList,
    apiSmartBoxWarningReportListDelete, apiSmartBoxWarningReportListSetTag, setFileStatusOpenSmartView
} from "../../../../api/SmartBox";
import CustomNotificationManager from "../../../Common/CustomNotificationManager";
import cookie from "react-cookies";
const { Option } = Select
const { Search } = Input
const { Column } = Table
const isLocalhost = () => {
    return window.location.hostname === 'localhost'
}
const host = isLocalhost() ? 'http://localhost:8080' : ''

class WarningReport extends React.Component {
    state = {
        totalRecords: 0,
        currentPage: 1,
        inputPage: '',
        // tableWarningReport: [
        //     {
        //         deletedBy: 'James Brown',
        //         warningMessage: "PDF has Article Threads",
        //         fileId: "17135253259340.7222741176433125",
        //         fileName: "SampleVideo_1280x720_1mb.mp4",
        //         folderPath: "/home/neuro/realShare/smartBox_115/dd16apr/SampleVideo_1280x720_1mb.mp4",
        //         key:0,
        //         location: "SampleVideo_1280x720_1mb.mp4",
        //     },
        //     {
        //         deletedBy: null,
        //         warningMessage: "PDF has Article Threads",
        //         fileId: "17135253259340.7222741176433125",
        //         fileName: "SampleVideo_1280x720_1mb.mp4",
        //         folderPath: "/home/neuro/realShare/smartBox_115/dd16apr/SampleVideo_1280x720_1mb.mp4",
        //         key:1,
        //         location: "SampleVideo_1280x720_1mb.mp4",
        //     },
        // ],
        tableWarningReport: [],
        selectedRowKeys: [],
        filesPerPageLimit: 20,
        nrFilesWithWarnings: 0,
        isPrevDisabled: true,
        isNextDisabled: true,
        onStateStart: false,
        onStateBack: false,
        onStateForth: false,
        onStateEnd: false,
        valueInputSearch: '',
        menuStatusVisible: false,
        customerStatus: [],
        defaultSelectedValue: '',
        defaultSelectedValueTag: '',
        activeStatus: false,
        selectColumn: {},
        sorterColumn: '',
        sorterNameColumn: '',
        activeLocation: {},
        nrOfFilesWithWarnings: 0,
        nrOfFilesWithWarningsDeleted: 0,
        onCheckSelectAll: false,
        filesSelectRows: [],
        filesSelectRowsGlobal: [],
        nonSelectedRows: [],
        nonSelectedRowsIds: [],
        nonSelectedWords: [],
        checkSearchError: false,
        checkStatusWarnings: '',
        setIntervalWarnings: 0,
    }
    componentDidMount() {
        cookie.remove('checkWarningsReportStatus', { path: '/' })
         this.onGetSmartBoxWarningReportList()
        this.setState({
            checkSearchError: true
        })
    }
    componentDidUpdate(prevProps, prevState, snapshot) {

    }
    onGetSmartBoxWarningReportList = () => {
        const { currentPage, filesPerPageLimit, valueInputSearch, totalRecords, sorterColumn, sorterNameColumn } = this.state
        const warningReportRequestDTO = {
            folderPath: this.props.folder.path,
            nrOfFilesWithWarnings: totalRecords.length > 0 ? totalRecords : null,
            pageNumber: currentPage,
            pageRows: filesPerPageLimit,
            searchWord: valueInputSearch ? valueInputSearch : null,
            sortBy: sorterNameColumn ? sorterNameColumn : null,
            sortOrder: sorterColumn ? sorterColumn : null
        }

        apiSmartBoxWarningReportList(warningReportRequestDTO).then((response) => {
            if (response.data.warningListItems && response.data.warningListItems.length > 0) {
                this.setState({
                    checkSearchError: false
                })
                this.props.onTableWarningReport(response.data.warningListItems)
            }
            this.setState({
                tableWarningReport: response.data.warningListItems,
                totalRecords: response.data.nrOfFilesWithWarnings,
                blockReanalyse: response.data.blockReanalyse,
                nrOfFilesWithWarnings: response.data.nrOfFilesWithWarnings,
                nrOfFilesWithWarningsDeleted: response.data.nrOfFilesWithWarningsDeleted,
            },() => {
                    this.updatePreviewNextPageNumber()
                }
            )
        })
    }
    changeFilesPerPageLimit = (e) => {
        this.setState({ filesPerPageLimit: e, currentPage: 1 }, () => {
            // this.onGetErrorsReport()
            this.onGetSmartBoxWarningReportList()
        })
    }
    onMouseActiveAction = (e, state) => {
        switch (state) {
            case 'delete':
                this.setState({
                    statusDeleteError: true
                })
                break
            case 'refresh':
                this.setState({
                    statusRefreshError: true
                })
                break
            case 'start':
                this.setState({
                    onStateStart: true
                })
                break
            case 'back':
                this.setState({
                    onStateBack: true
                })
                break
            case 'forth':
                this.setState({
                    onStateForth: true
                })
                break
            case 'end':
                this.setState({
                    onStateEnd: true
                })
                break
            case 'status':
                this.setState({
                    activeStatus: true
                })
                break
            default: break
        }
    }
    onLeaveMouseAction = () => {
        this.setState({
            statusDeleteError: false,
            statusRefreshError: false,
            onStateStart: false,
            onStateBack: false,
            onStateForth: false,
            onStateEnd: false,
            activeStatus: false
        })
    }
    onSorterColumns = (e, column, sorter) => {
        const { tableWarningReport } = this.state
        if (tableWarningReport && tableWarningReport.length > 0) {
            const selectCol = {
                column: column,
                sorter: sorter
            }
            this.setState({
                sorterColumn: sorter,
                sorterNameColumn: column,
                selectColumn: selectCol,
                selectedRowKeys: []
            }, () => {
                // this.onGetErrorsReport()
                this.onGetSmartBoxWarningReportList()
            })
        }
    }
    onCheckSelectedRows = () => {
        const { tableWarningReport, filesSelectRows, onCheckSelectAll, nonSelectedRows } = this.state
        const updateKeys = []
        if (onCheckSelectAll) {
            tableWarningReport && tableWarningReport.length > 0 && tableWarningReport.forEach((list, index) => {
                filesSelectRows && filesSelectRows.length > 0 && filesSelectRows.forEach((list2, index2) => {
                    if (nonSelectedRows && nonSelectedRows.length > 0) {
                        if (!nonSelectedRows.includes(list.fileName)) {
                            updateKeys.push(index)
                        }
                    } else {
                        updateKeys.push(index)
                    }
                })
            })
        } else {
            tableWarningReport && tableWarningReport.length > 0 && tableWarningReport.forEach((list, index) => {
                filesSelectRows && filesSelectRows.length > 0 && filesSelectRows.forEach((list2, index2) => {
                    if (list.fileName === list2.fileName) {
                        updateKeys.push(index)
                    }
                })
            })
        }
        const uniqueUpdateKeys = [...new Set(updateKeys)]
        this.setState({
            selectedRowKeys: uniqueUpdateKeys
        })
    }
    updatePreviewNextPageNumber = () => {
        const { totalRecords, currentPage } = this.state
        const totalPages = Math.ceil(totalRecords / this.state.filesPerPageLimit)
        const isPrevDisabled = currentPage > 1 ? false : true
        const isNextDisabled = currentPage < totalPages ? false : true
        this.setState({ isPrevDisabled, isNextDisabled, currentPage }, () => {
            this.onCheckSelectedRows()
        })
    }
    handleMovePage = (e, direction) => {
        const { isPrevDisabled, isNextDisabled, totalRecords, filesPerPageLimit } = this.state
        switch (direction) {
            case 'first':
                e.preventDefault()
                if (!isPrevDisabled) {
                    this.gotoPage(1)
                }
                break
            case 'previous':
                e.preventDefault()
                if (!isPrevDisabled) {
                    this.gotoPage(this.state.currentPage - 1 ? this.state.currentPage - 1 : 1)
                }
                break
            case 'next':
                e.preventDefault()
                if (!isNextDisabled) {
                    this.gotoPage(this.state.currentPage + 1 > Math.ceil(totalRecords / filesPerPageLimit) ? Math.ceil(totalRecords / filesPerPageLimit) : this.state.currentPage + 1)
                }
                break
            case 'last':
                e.preventDefault()
                if (!isNextDisabled) {
                    this.gotoPage(Math.ceil(totalRecords/filesPerPageLimit))
                }
                break
            default:
                e.preventDefault()
                this.setState({ showInsertPageNumber: true })
        }
    }
    gotoPage = (newPage) => {
        const { totalRecords, filesPerPageLimit } = this.state
        const totalPages = Math.ceil(totalRecords / filesPerPageLimit)
        newPage = Math.max(0, Math.min(newPage, totalPages))
        this.setState({ currentPage: newPage }, () => {
            // this.onGetErrorsReport()
            this.onGetSmartBoxWarningReportList()
        })
    }
    handleInputPageChange = (e) => this.setState({ inputPage: e.target.value }, () => {
        // this.onGetErrorsReport()
        this.onGetSmartBoxWarningReportList()
    })
    handleInputPageBlur = () => {
        const { inputPage } = this.state
        if (inputPage) {
            const paginationEnd = Math.ceil(this.state.totalRecords / this.state.filesPerPageLimit)
            if (inputPage > 0 && inputPage <= paginationEnd) {
                this.gotoPage(inputPage)
            } else {
                this.gotoPage(1)
                this.setState({ inputPage: 1 })
            }
        }
    }
    handleInputPageEnter = (e) => {
        if (e.key === 'Enter') {
            this.handleInputPageBlur()
        }
    }
    onDeleteSelected = () => {
        const { onCheckSelectAll, valueInputSearch, filesSelectRowsGlobal, totalRecords, nonSelectedRowsIds } = this.state
        const idSelected = []
        filesSelectRowsGlobal && filesSelectRowsGlobal.length > 0 && filesSelectRowsGlobal.forEach((row) => {
            idSelected.push(row.fileId)
        })
        const uniqueIdSelected = [...new Set(idSelected)]
        const deleteWarningReportPageDTO  = {
            deleteAll: onCheckSelectAll,
            docIDsSelected: onCheckSelectAll ? [] : uniqueIdSelected && uniqueIdSelected.length > 0 ? uniqueIdSelected : [],
            docIDsExcluded: onCheckSelectAll ? nonSelectedRowsIds : [],
            folderPath: this.props.folder.path,
            searchWord: valueInputSearch ? valueInputSearch : ''
        }
        apiSmartBoxWarningReportListDelete(deleteWarningReportPageDTO).then((response) => {
            if (response.status === 200) {
                const totalRecordsUpdate = totalRecords - idSelected.length
                if (onCheckSelectAll) {
                    this.setState({
                        totalRecords: 0
                    })
                } else {
                    this.setState({
                        totalRecords: totalRecordsUpdate
                    })
                }
                if (response.data === 'Success!') {
                    this.setState({
                        checkProcessingDelete: false
                    }, () => {
                        this.onGetDeletedErrorsStatus()
                        //this.onGetSmartBoxWarningReportList()
                    })
                }
                this.setState({
                    selectedRowKeys: [],
                    filesSelectRows: [],
                    filesSelectRowsGlobal: []
                })
            }
        }).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
            }else{
                console.log(error)
            }
        })
    }
    showConfirmDeleteWarning = (e) => {
        const { selectedRowKeys } = this.state
        if (selectedRowKeys && selectedRowKeys.length > 0) {
            customConfirm({
                title: <span className="icon-v14-delete"></span>,
                content: `Delete file(s) ?`,
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel',
                mask: true,
                maskClosable: false,
                closeHaveLogic: false,
                className: 'asset-register-modal modal-delete-error',
                centered: true,
                cancelClassName: 'cancel-button',
                confirmClassName: 'confirm-button',
                confirmAction: () => {
                    this.onDeleteSelected(e)
                }
            })
        }
    }

    onGetDeletedErrorsStatus = () => {
        const { setIntervalWarnings } = this.state
        const { folder } = this.props
        apiGetBoxWarningReportDeleteStatus(folder.id).then((response) => {
            cookie.save('checkWarningsReportStatus', 'getErrorReport', {path: '/'})
            if (response.status === 200) {
                this.setState({
                    checkStatusWarnings: response.data
                })
                if (response.data === 'In Progress') {
                    this.setIntervalErrorStatus()
                } else if (response.data === 'Finished') {
                    clearInterval(setIntervalWarnings)
                    this.setState({setIntervalWarnings})
                    this.onGetSmartBoxWarningReportList()
                }
            }
        })
    }

    setIntervalErrorStatus = () => {
        const {  checkStatusWarnings } = this.state
        let { setIntervalWarnings } = this.state
        // if (setIntervalDeleted === 0) {
        if (checkStatusWarnings === 'In Progress') {
            setIntervalWarnings = setInterval(this.callBackDeletedStatus, 1000)
            this.setState({
                setIntervalWarnings
            })
        } else {
            clearInterval(setIntervalWarnings)
        }
        //}
    }

    callBackDeletedStatus = () => {
        const { setIntervalWarnings, checkStatusWarnings } = this.state
        const { folder } = this.props
        const itemError = cookie.load('checkWarningsReportStatus', { path: '/' })
        if (checkStatusWarnings === 'In Progress' && itemError === 'getErrorReport') {
            apiGetBoxWarningReportDeleteStatus(folder.id).then((response) => {
                if (response.data === 'Finished') {
                    this.onGetSmartBoxWarningReportList()
                    clearInterval(setIntervalWarnings)
                    this.setState({ setIntervalWarnings })
                }  else {
                    this.setState({ setIntervalWarnings })
                }
                this.setState({
                    checkStatusWarnings: response.data
                })
            })
        }
    }

    onChangeSearchWarningReport = (e) => {
        this.setState({
            valueInputSearch: e.target.value.length > 0 ? e.target.value.trim() : e.target.value
        }, () => {
            // this.onGetErrorsReport()
            this.onGetSmartBoxWarningReportList()
        })
    }
    onKeyPressWarningTerm = (e) => {
        this.setState({
            valueInputSearch: e.target.value.length > 0 ? e.target.value.trim() : e.target.value
        }, () => {
            // this.onGetErrorsReport()
            this.onGetSmartBoxWarningReportList()
            })
    }
    onChangeSearchWarning = (e) => {
        this.setState({
            valueInputSearch: e.length > 0 ? e.trim() : e
        }, () => {
            // this.onGetErrorsReport()
            this.onGetSmartBoxWarningReportList()
        })
    }
    getTagDocumentClient = () => {
            apiGetTagDocumentClient(this.props.clientId).then((response) => {
                this.setState({
                    customerStatus: response.data
                })
            })
    }
    setStatus = (e) => {
        const { customerStatus } = this.state
        const { onCheckSelectAll, valueInputSearch, filesSelectRowsGlobal, totalRecords, nonSelectedRowsIds } = this.state
        const idSelected = []
        filesSelectRowsGlobal && filesSelectRowsGlobal.length > 0 && filesSelectRowsGlobal.forEach((row) => {
            idSelected.push(row.fileId)
        })
        const uniqueIdSelected = [...new Set(idSelected)]
        let selectTag = {}
        let iconSelect = null
        let colorSelect = null
        const statusSmartView = e
        let tagSelect = {}
        const fileStatusValue = e.toLowerCase() === 'for review' ? 'for review' : e.toLowerCase()
        customerStatus && customerStatus.length > 0 && customerStatus.map((list) => {
            if (list.tagName === e) {
                selectTag = list
                iconSelect = list.icon
                colorSelect = list.color
                tagSelect = list
            }
        })
        this.setState({defaultSelectedValue: e, defaultSelectedValueTag: selectTag })
    }
    updateFolderStatus = () => {
        const { customerStatus, defaultSelectedValue, tableWarningReport } = this.state

        const { onCheckSelectAll, valueInputSearch, filesSelectRowsGlobal, totalRecords, nonSelectedRowsIds } = this.state
        const idSelected = []
        filesSelectRowsGlobal && filesSelectRowsGlobal.length > 0 && filesSelectRowsGlobal.forEach((row) => {
            idSelected.push(row.fileId)
        })
        const uniqueIdSelected = [...new Set(idSelected)]
        let selectTag = {}
        let iconSelect = null
        let colorSelect = null
        const statusSmartView = defaultSelectedValue
        let tagSelect = {}
        const fileStatusValue = defaultSelectedValue.toLowerCase() === 'for review' ? 'for review' : defaultSelectedValue.toLowerCase()
        customerStatus && customerStatus.length > 0 && customerStatus.map((list) => {
            if (list.tagName === defaultSelectedValue) {
                selectTag = list
                iconSelect = list.icon
                colorSelect = list.color
                tagSelect = list
            }
        })
        const setTagWarningReportPageDTO = {
            deleteAll: onCheckSelectAll,
            docIDsSelected: onCheckSelectAll ? [] : uniqueIdSelected && uniqueIdSelected.length > 0 ? uniqueIdSelected : [],
            docIDsExcluded: onCheckSelectAll ? nonSelectedRowsIds : [],
            folderPath: this.props.folder.path,
            searchWord: valueInputSearch ? valueInputSearch : '',
            fileStatus: fileStatusValue.toLowerCase(),
            color: colorSelect,
            icon: iconSelect,
        }
        apiSmartBoxWarningReportListSetTag(setTagWarningReportPageDTO).then((response) => {
            if (response.data && response.data.title && response.data.title.toLowerCase() === 'success') {
                CustomNotificationManager.success(response.data.message, 'Success')
            } else if (response.data && response.data.title && response.data.title.toLowerCase() === 'info') {
                CustomNotificationManager.info(response.data.message, 'Info')
            }
            tableWarningReport && tableWarningReport.length > 0 && tableWarningReport.forEach((item) => {
               if (uniqueIdSelected.includes(item.fileId)) {
                   item.systemPropertyFileColor = colorSelect
                   item.systemPropertyFileIcon = iconSelect
                   item.systemPropertyFileStatus = fileStatusValue.toLowerCase()
               }
            })
            if (response.status === 200) {
                this.setState({
                    defaultSelectedValue: '',
                    defaultSelectedValueTag: '',
                    menuStatusVisible: false,
                    tableWarningReport
                })
            }
            //this.onGetSmartBoxWarningReportList()
        })
    }

    renderDropdownStatusInfo = () => {
        const { customerStatus, defaultSelectedValue, defaultSelectedValueTag } = this.state
        return (
            <React.Fragment>
                {customerStatus && customerStatus.length > 0 &&
                <div className="dropdown-status">
                    <span className='title-status'>Status</span>
                    <Select
                        placeholder="Select"
                        dropdownStyle={{border:'1px solid #eda41e'}}
                        dropdownMenuStyle={{overflowY:'auto', overflowX:'hidden',}}
                        value={ <span className="content-value-status">
                  { defaultSelectedValueTag.icon && defaultSelectedValueTag.icon.includes('v211') ?
                      <span style={{backgroundColor: defaultSelectedValueTag.color}} className={defaultSelectedValueTag.icon}></span> :
                      defaultSelectedValueTag.color && defaultSelectedValueTag.color.includes('#') ?
                          <span style={{backgroundColor: defaultSelectedValueTag.color}} className={'icon-color'}></span> :
                          <span><Icon type={`${defaultSelectedValueTag.icon}`} /></span>
                  }
                            <span>{ defaultSelectedValue === '' ? 'Select' : defaultSelectedValue }</span></span> }
                        className="custom-status-select"
                        dropdownClassName="custom-status-select-options custom-status-select-options-warning-report"
                        getPopupContainer={trigger => trigger.parentNode}
                        onChange={(e) => this.setStatus(e)}>
                        {
                            customerStatus.map((item, index) => {
                                return (
                                    <Option className="item-options-status-new" key={index} value={item.tagName}>
                                        <Tooltip title={item.tagName}>
                                            { item.icon && item.icon.includes('v211') ?
                                                <span style={{backgroundColor: item.color ? item.color : 'icon-color'}}
                                                      className={item.icon ? item.icon : 'icon-color'}></span> :
                                                item.color ?
                                                    <span style={{backgroundColor: item.color ? item.color : 'icon-color'}}
                                                          className={item.icon ? item.icon : 'icon-color'}></span> :
                                                    <span><Icon type={`${item.icon}`} /></span>
                                            }
                                            {item.tagName.length > 19 ? item.tagName.substring(0,14) + '...' : item.tagName }
                                        </Tooltip>
                                    </Option>
                                )
                            })
                        }
                    </Select>
                    <Button className={'button-green'}
                            onClick={() => this.updateFolderStatus()}
                            disabled={ defaultSelectedValue === '' }
                    >Update</Button>
                </div>
                }
            </React.Fragment>
        )
    }
    toggleStatusMenu = (e) => {
        const { tableWarningReport, selectedRowKeys } = this.state
        if (typeof e !== 'undefined') {
            e.stopPropagation()
        }
        if (tableWarningReport.length > 0 ) {
            this.getTagDocumentClient()
            if (selectedRowKeys.length > 0) {
                this.setState({
                    menuStatusVisible: !this.state.menuStatusVisible,
                })
            }
        }
    }
    locationTextTable(text) {
        const textLocation = text.length > 30 ? `${ text.replaceAll("/", " > ").substring(0, 30) } ...` : `${text}`
        return textLocation
    }
    onActiveLocation = (e, location) => {
        this.setState({
            activeLocation: location
        })
    }
    onNonActiveLocation = () => {
        this.setState({
            activeLocation: {}
        })
    }
    rowClassSelected = (record) => {
        if (record.deletedBy) {
            return 'row-deleted'
        }
    }
    onChangeRowWarningReport = (selectedRowKeys, filesSelect,) => {
        const { blockReanalyse, tableWarningReport } = this.state
        const nonSelected = []
        const nonSelectedIds = []
        const nameFilesSelected = []
        const selectedRowKeys2 = []
        if (!blockReanalyse) {
            filesSelect && filesSelect.length > 0 && filesSelect.forEach((error, index) => {
                if (!error.deletedBy) {
                    nameFilesSelected.push(error.fileName)
                }
            })
            let filesSelectedGlobal = this.state.filesSelectRowsGlobal.concat(filesSelect)
            let filesSelected = this.state.filesSelectRows.concat(filesSelect)
            tableWarningReport && tableWarningReport.length > 0 && tableWarningReport.forEach((error, index) => {
                if(!nameFilesSelected.includes(error.fileName)) {
                    nonSelected.push(error.fileName)
                    nonSelectedIds.push(error.fileId)
                }
                if(nameFilesSelected.includes(error.fileName)) {
                    selectedRowKeys2.push(index)
                }
            })
            this.state.filesSelectRowsGlobal && this.state.filesSelectRowsGlobal.length > 0 && this.state.filesSelectRowsGlobal.forEach((error) => {
                if(nonSelected.includes(error.fileName)) {
                    filesSelectedGlobal = this.state.filesSelectRowsGlobal.filter((el) => el.fileName !== error.fileName)
                    this.setState({
                        filesSelectRowsGlobal: filesSelectedGlobal
                    })
                }
            })

            const otherFileSelected = []
            this.state.filesSelectRows && this.state.filesSelectRows.length > 0 && this.state.filesSelectRows.forEach((error) => {
                if(nonSelected.includes(error.fileName)) {
                    filesSelected = this.state.filesSelectRows.filter((el) => el.fileName !== error.fileName)
                    this.setState({
                        filesSelectRows: filesSelected
                    })
                }
            })
            this.setState({
                selectedRowKeys: selectedRowKeys,
                filesSelectRows: filesSelected,
                filesSelectRowsGlobal: filesSelectedGlobal,
                nonSelectedRows: [...new Set(this.state.nonSelectedRows.concat(nonSelected))],
                nonSelectedRowsIds: [...new Set(this.state.nonSelectedRowsIds.concat(nonSelectedIds))],
            })
        }
    }
    onSelectAll = (selected, selectedRows, changeRows) => {
        if (!selected) {
            this.setState({
                filesSelectRows: [],
                filesSelectRowsGlobal: []
            })
        }
        this.setState({
            onCheckSelectAll: selected,
            nonSelectedRows: [],
            nonSelectedRowsIds: [],
        })
    }
    onClickSelectedRowWarning = (e, record) => {

    }
    onDoubleClickSelectedRowWarning = (e, record) => {
        if (record.conversionStatus !== 'Unconverted' && !record.deletedBy) {
            this.props.handlePreview(record)
        }
    }

    render() {
        const { tableWarningReport, filesPerPageLimit, nrFilesWithWarnings, isPrevDisabled,isNextDisabled, onStateStart, onStateBack, onStateForth,
            onStateEnd, currentPage, totalRecords, inputPage, valueInputSearch, menuStatusVisible, activeStatus, selectedRowKeys, selectColumn,
            activeLocation, nrOfFilesWithWarnings, statusDeleteError, checkSearchError, checkStatusWarnings }= this.state
        const totalPages = Math.ceil(totalRecords / filesPerPageLimit)
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onChangeRowWarningReport,
            onSelectAll: this.onSelectAll,
            // disabled: !checkReprocessingFile || !blockReanalyse
        }
        return (
            <div className="warning-report-page">
                <div className="warning-report-page__content">
                    <div className="header-warning-page">
                        <div className="title-header-warning-report">
                            <span className="title-page">File warnings</span>
                            <span className="help-message">These documents contain errors that may cause issues with alignment of highlights and redactions.</span>
                            <span className="learn-more-link" onClick={ () => window.open('https://support.smartbox.ai/support/solutions/articles/47001260647', '_blank') }> Learn more </span>
                        </div>
                    </div>
                    <div className="content-warning-report">
                        <div className="warning-report-pagination">
                            <div className= {tableWarningReport.length === 0 ? 'per-page-warning per-page-warning-disabled' : "per-page-warning" }>
                                <Select dropdownClassName="select-per-page-error per-error"
                                        value={<span><span>{ `Show ${ filesPerPageLimit }` } </span><span className="icon-v19-arrow-medium"></span></span> }
                                        disabled={ tableWarningReport.length === 0 }
                                        onChange={(e) => this.changeFilesPerPageLimit(e)}>
                                    <Option value="20">20</Option>
                                    <Option value="50">50</Option>
                                    <Option value="100">100</Option>
                                </Select>
                            </div>
                            <div className="warnings-found-content">
                                <div className="count-warnings">
                                    <span className="count-warning">{ nrOfFilesWithWarnings }</span>
                                    <span className="error-found">Warnings</span>
                                </div>
                            </div>
                            <div className="buttons-control-pagination">
                                <Tooltip title="first" className={isPrevDisabled ? 'disabled' : ''}>
                                <span className={ isPrevDisabled ? 'icon-v14-start-1 disabled' : onStateStart ? 'icon-v14-start-active' : 'icon-v14-start-1' }
                                      onMouseEnter={(e) => this.onMouseActiveAction(e, 'start')}
                                      onMouseLeave={() => this.onLeaveMouseAction()}
                                      onClick={ (e) => this.handleMovePage(e, 'first')}/>
                                </Tooltip>
                                <Tooltip title="previous" className={isPrevDisabled ? 'disabled' : ''}>
                                <span className={ isPrevDisabled ? 'icon-v21-back-1 disabled' : onStateBack ? 'icon-v21-back-active' : 'icon-v21-back-1' }
                                      onMouseEnter={(e) => this.onMouseActiveAction(e, 'back')}
                                      onMouseLeave={() => this.onLeaveMouseAction()}
                                      onClick={ (e) => this.handleMovePage(e, 'previous')}/>
                                </Tooltip>
                                { totalPages === 0 &&
                                <span className='buttons-control-pagination__text disabled-text'>{1} of {1} </span>
                                }
                                { totalPages === 1 &&
                                <span className='buttons-control-pagination__text'>{currentPage} of {totalPages} </span>
                                }
                                { totalPages > 1 &&
                                <span className='buttons-control-pagination__text'>
                                <input
                                    type="number"
                                    className="insert-page"
                                    placeholder= { currentPage }
                                    value= { inputPage }
                                    min="1"
                                    max={totalPages}
                                    onChange={ (e) => this.handleInputPageChange(e) }
                                    onBlur={ () => this.handleInputPageBlur() }
                                    onKeyDown={ (e) => this.handleInputPageEnter(e) }
                                />
                                <span>of {totalPages}</span>
                              </span>
                                }
                                <Tooltip title="next" className={isNextDisabled ? 'disabled' : ''}>
                                <span className={isNextDisabled ? 'icon-v21-forth-1 disabled' : onStateForth ? 'icon-v21-forth-active' : 'icon-v21-forth-1' }
                                      onMouseEnter={(e) => this.onMouseActiveAction(e, 'forth')}
                                      onMouseLeave={() => this.onLeaveMouseAction()}
                                      onClick={ (e) => this.handleMovePage(e, 'next')}/>
                                </Tooltip>
                                <Tooltip title="last" className={ isNextDisabled ? 'disabled' : '' }>
                                <span className={ isNextDisabled ? 'icon-v14-end-1 disabled' : onStateEnd ? 'icon-v14-end-active' : 'icon-v14-end-1' }
                                      onMouseEnter={(e) => this.onMouseActiveAction(e, 'end')}
                                      onMouseLeave={() => this.onLeaveMouseAction()}
                                      onClick={ (e) => this.handleMovePage(e, 'last')}/>
                                </Tooltip>
                            </div>
                            <div className="content-delete-refresh">
                                <Tooltip title="Delete">
                               <span onClick={(e) => this.showConfirmDeleteWarning(e)}
                                     onMouseEnter={(e) => this.onMouseActiveAction(e, 'delete')}
                                     onMouseLeave={() => this.onLeaveMouseAction()}
                                     className={selectedRowKeys.length === 0 ? 'icon-v14-delete disabled-delete' :
                                         statusDeleteError ? 'icon-v14-delete-active' : 'icon-v14-delete'}> </span>
                                </Tooltip>
                                <Dropdown overlay={ this.renderDropdownStatusInfo() }
                                          trigger={['hover']}
                                          onClick={ (event) => this.toggleStatusMenu(event) }
                                          visible={ menuStatusVisible }
                                          overlayClassName={ "dropdown-status-warning-report" }
                                          getPopupContainer={trigger => trigger.parentNode}
                                          disabled={tableWarningReport.length === 0 || selectedRowKeys.length === 0}
                                    >
                                    <div>
                                        <span className={ tableWarningReport.length === 0 ||  selectedRowKeys.length === 0 ? 'icon-v211-label-dropdown icon-v211-label-dropdown-disabled' : activeStatus || menuStatusVisible ? 'icon-v211-label-dropdown-active' : 'icon-v211-label-dropdown'}
                                              onMouseEnter={(e) => this.onMouseActiveAction(e, 'status')}
                                              onMouseLeave={() => this.onLeaveMouseAction()}
                                              onClick={ (e) => e.preventDefault() }/>
                                    </div>
                                </Dropdown>
                            </div>
                            <div className="input-search-warning">
                                <Search className="search-warning"
                                        allowClear
                                        style={{ width: 200 }}
                                        placeholder="Search"
                                        defaultValue={ valueInputSearch }
                                        disabled={ checkSearchError === true }
                                        onSearch={ this.onChangeSearchWarning }
                                        onChange={ (e) => this.onChangeSearchWarningReport(e)}
                                        onKeyPress={ (e) => this.onKeyPressWarningTerm(e)}/>
                            </div>
                        </div>
                        <div className="table-warning-report-content">
                            <Table className='table-error-files'
                                   dataSource={ tableWarningReport }
                                   pagination={ false }
                                   rowClassName={(record) => this.rowClassSelected(record) }
                                   rowSelection={ rowSelection }
                                   //rowKey = { (record) => { return `rowKey${ record.fileId }` }}
                                   loading={ checkStatusWarnings === 'In Progress'}
                                   onRow={(record, rowIndex) => {
                                       return {
                                           onClick: (e) => this.onClickSelectedRowWarning(e, record),
                                           onDoubleClick: (e) => this.onDoubleClickSelectedRowWarning(e, record)
                                       }
                                   }}
                            >
                                <Column className="column-error-report"
                                        title= { <span className="content-header-column"><span>File</span>
                                                 <span className="content-buttons-sorter">
                                                     <span className={ selectColumn.column === 'name' && selectColumn.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                                                           onClick={(e) => this.onSorterColumns(e, 'name', 'asc')}></span>
                                                     <span className={ selectColumn.column === 'name' && selectColumn.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                                                           onClick={(e) => this.onSorterColumns(e, 'name', 'desc')}></span>
                                                 </span>
                                        </span>}
                                        dataIndex="fileName" key="fileName"
                                        render={(text, record) => {
                                            return (
                                                <span className="text-error-report">{ text }</span>
                                            )
                                        }} />
                                <Column className="column-error-report"
                                        title= { <span className="content-header-column"><span>Location</span>
                                                 <span className="content-buttons-sorter">
                                                     <span className={ selectColumn.column === 'location' && selectColumn.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                                                           onClick={(e) => this.onSorterColumns(e, 'location', 'asc')}></span>
                                                     <span className={ selectColumn.column === 'location' && selectColumn.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                                                           onClick={(e) => this.onSorterColumns(e, 'location', 'desc')}></span>
                                                 </span>
                                        </span>}
                                        dataIndex="location" key="location"
                                        render={(text, record) => {
                                            return (
                                                <Tooltip title={ text ? text.replaceAll("/", " > ") : ''}>
                                        <span
                                            //onClick={record.deletedBy === null ? (e) => this.onErrorReportListFileLocation(e, record) : (e) => this.onErrorReportDefault(e)}
                                              className={ activeLocation.fileId === record.fileId && record.deletedBy === null ? "text-error-report text-error-report-location-active" : 'text-error-report text-error-report-location' }
                                              //onMouseEnter={(e) => this.onActiveLocation(e, record)}
                                              //onMouseLeave={() => this.onNonActiveLocation()}
                                        >{ this.locationTextTable(text) }
                                            </span>
                                                </Tooltip>
                                            )
                                        }}/>
                                <Column className="column-error-report"
                                        title= { <span className="content-header-column"><span>Warning</span>
                                                 <span className="content-buttons-sorter">
                                                      <span className={ selectColumn.column === 'warning' && selectColumn.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                                                            onClick={(e) => this.onSorterColumns(e, 'warning', 'asc')}></span>
                                                     <span className={ selectColumn.column === 'warning' && selectColumn.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                                                           onClick={(e) => this.onSorterColumns(e, 'warning', 'desc')}></span>
                                                 </span>
                                        </span>}
                                        dataIndex="warningMessage" key="warningMessage"

                                        render={(text, record) => {
                                            return (
                                                <Tooltip overlayClassName="tooltip-warning-report"
                                                title={<span className="text-warning-report-tooltip">{
                                                    text && text.length > 0 && text.map((mess, index) => {
                                                        return (
                                                            <span className="title-warning" key={index}>{mess}</span>
                                                        )
                                                    })
                                                }</span>}>
                                                <span className="text-error-report text-warning-report">
                                                    { text && text.length > 0 &&
                                                    <span className="title-warning">{text[0]}</span>
                                                    }
                                                </span>
                                                </Tooltip>
                                            )
                                        }}/>
                                <Column className="column-error-report"
                                        title= { <span className="content-header-column"><span>Tag</span>
                                                 <span className="content-buttons-sorter">
                                                      <span className={ selectColumn.column === 'tag' && selectColumn.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                                                            onClick={(e) => this.onSorterColumns(e, 'tag', 'asc')}></span>
                                                     <span className={ selectColumn.column === 'tag' && selectColumn.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                                                           onClick={(e) => this.onSorterColumns(e, 'tag', 'desc')}></span>
                                                 </span>
                                        </span>}
                                        dataIndex="tag" key="tag"
                                        render={(text, record) => {
                                            return (
                                                <span className="text-error-report text-tag-report">
                                                    <Tooltip title={record.systemPropertyFileStatus}>
                                            { record.systemPropertyFileIcon && record.systemPropertyFileIcon.includes('v211') ?
                                                <span
                                                     //style={{backgroundColor: record.systemPropertyFileColor ? record.systemPropertyFileColor : 'icon-color'}}
                                                      className={record.systemPropertyFileIcon ? record.systemPropertyFileIcon : 'icon-color'}></span> :
                                                record.systemPropertyFileColor ?
                                                    <span style={{backgroundColor: record.systemPropertyFileColor ? record.systemPropertyFileColor : 'icon-color'}}
                                                          className={record.systemPropertyFileIcon ? record.systemPropertyFileIcon : 'icon-color'}></span> :
                                                    <span><Icon type={`${record.systemPropertyFileIcon}`} /></span>
                                            }
                                                        {/*{ record.systemPropertyFileStatus && record.systemPropertyFileStatus.length > 19 ? record.systemPropertyFileStatus.substring(0,14) + '...' : record.systemPropertyFileStatus }*/}
                                        </Tooltip>
                                                </span>
                                            )
                                        }}/>
                                <Column className="column-error-report"
                                        title={<span className="content-header-column"><span>Deleted by</span>
                                                 <span className="content-buttons-sorter">
                                                      <span className={ selectColumn.column === 'deletedBy' && selectColumn.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                                                            onClick={(e) => this.onSorterColumns(e, 'deletedBy', 'asc')}></span>
                                                     <span className={ selectColumn.column === 'deletedBy' && selectColumn.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                                                           onClick={(e) => this.onSorterColumns(e, 'deletedBy', 'desc')}></span>
                                                 </span>
                                        </span>}
                                        dataIndex="deletedBy" key="folderPath"
                                        render={(text, record) => {
                                            return (
                                                <span className="text-error-report">{ text }</span>
                                            )
                                        }}/>
                                <Column className="column-error-report"
                                        title={""}
                                        dataIndex="arrow" key="arrow"
                                        render={(text, record) => {
                                            return (
                                                <span className="text-error-report icon-v16-arrow-medium rotate-arrow-medium"></span>
                                            )
                                        }}/>
                            </Table>
                        </div>
                </div>
                </div>

            </div>
        )

    }
}

WarningReport.propTypes = {
    folder: PropTypes.object,
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id,
    user: state.userReducer.user,
    smartBoxParentFolderName: state.smartBox.smartBoxParentFolderName
})
export default compose(
    connect(mapStateToProps, {
        setSmartReprocessingFileDelete
    })
)(WarningReport)