import PropTypes from 'prop-types'
import React from 'react'
import { CSVLink } from 'react-csv'
import CSVReader from 'react-csv-reader'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import * as actions from '../../../../actions/messageActions'
import { apiUserFetchInfo, apiUserInvite, apiUserInviteMultipleEmails } from '../../../../api/User'
import logoOsprey from '../../../../assets/excel.png'
import logoOsprey1 from '../../../../assets/icon-letter.png'
import Button from '../../../Common/Button'
import Loading from '../../../Common/Loading'
import Message from '../../../Common/Message'
import './AddUser.scss'
import '../../../../style/common.scss'
import CustomNotificationManager from "../../../Common/CustomNotificationManager";
import {getErrorMessage} from "../../../../utils";
import withRouter from '../../../../private-routers/withRouter'

class AddUser extends React.Component {
  constructor() {
    super()
    this.getSendInviteMultipleUser=this.getSendInviteMultipleUser.bind(this)
    this.state = {
      isSaving: false,
      isSending: {},
      showMessage: {},
      usersList: [],
      email: '',
      showLearnMessage: true,
      listUsersExcel: [],
      emailAddress: '',
      previewTableExacel: [],
      isSent: false,
      break: false,
      errorMessageSendEmail: ''
    }
    this.state.pictureImg = []
    // this.onDrop = this.onDrop.bind(this)
    this.onDropFile = this.onDropFile.bind(this)
    this.saveInvite = this.saveInvite.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleModelChange = this.handleModelChange.bind(this)
  }
  handleModelChange(model) {
    this.setState({
      model: model
    })
  }

  // onDrop(picture, done) {
  //   this.setState({
  //     pictures: this.state.pictureImg.concat(picture),
  //     listUsersExcel: done
  //   })
  //   if (done.length>1) {
  //     this.setState({
  //       listUsersExcel: done[done.length -1]
  //     })
  //   }
  // }

  onDropFile(file) {
    const nuploadedUsersList =[]
    file.forEach(function(row) {
      const temp = {}
      temp.user_metadata = {}
      temp.user_metadata.name = row[0]
      temp.nickname = row[1]
      temp.email = row[2]
      temp.user_metadata.companyName = row[3]
      nuploadedUsersList.push(temp)
    })

    this.setState({
      usersList: nuploadedUsersList
    })
  }

  get defaultState() {
    return {
      isLoading: false,
      isSaving: false,
      errors: {},
      isEditMode: false,
      title: '',
      state: '',
      version: '',
      isSending: false,
    }
  }

  get disabledButton() {
    return this.state.state === 'PUBLISHED' || this.state.isSaving
  }

  getUsersList() {
    const tempData = []
    tempData.push(['First Name', 'Last Name', 'Email Addres', 'Group'])
    for (let i = 0; i<this.state.usersList.length; i++)
    {
      tempData.push([this.state.usersList[i].user_metadata.name, this.state.usersList[i].nickname, this.state.usersList[i].email, this.state.usersList[i].user_metadata.companyName])
    }
    this.setState({
      listUsersExcel: { data: tempData }
    })
  }

  componentDidMount() {
    this.fetchUsersList()
  }
  getSendInviteMultipleUser() {
    const emailAddress= this.state.emailAddress.split(',')

    apiUserInviteMultipleEmails(emailAddress)
      .then((response) => {
        this.setState({
          isLoading: false,
          isSent: true,
          emailAddress: []
        })
        this.props.navigate('/osp/manage/adduser')
        CustomNotificationManager.success('Your invites have been sent.')
      }).catch((e) => {
        let errorMessage = getErrorMessage(e)
        let errorTitle = 'Error'
        let errorText= ''
        if (errorMessage[1].trim() === 'User with this email already exist') {
          errorTitle= 'User already exist'
          errorText= errorMessage[1].trim()
          this.setState({
            errorMessageSendEmail: errorMessage[1].trim()
          })
        }
        CustomNotificationManager.error(errorText, errorTitle)
        this.setState({
          isLoading: false,
          break: true
        })
      })
  }
  getSendInviteUser() {
    const emailAddres = this.state.emailAddress.split(',')
    for (let i=0; i<emailAddres.length; i++)
    {
      const data = {
        email: emailAddres[i].toString()
      }
      if (this.state.break)
        break
      apiUserInvite(data)
        .then((response) => {
          if (i===emailAddres.length -1) {
            this.setState({
              isLoading: false,
              isSent: true
            })
          }
          this.props.navigate('/osp/manage/adduser')
        }).catch((e) => {
        this.setState({
          isLoading: false,
          break: true
        })
      })
    }
  }
  saveInvite(e) {
    e.preventDefault()
    this.setState({
      isLoading: true,
    }, () => {
      this.getSendInviteUser()
    })

  }

  fetchUsersList(data) {
    apiUserFetchInfo()
      .then((response) => {
        this.setState({
          usersList: [response.data.user]
        })

      }).catch((e) => {
      console.log(e)
    })
  }

  handleChange(event, field) {
    if (field === 'field_email') {
      this.setState({
        emailAddress: event.target.value,
        errorMessageSendEmail: ''
      })
    }
  }

  formattedTitle(isEditMode, title, state, version) {
    if (!isEditMode) return 'New Team'
    if (state !== 'DRAFT') return `V${ version } - ${ title }`
    return `(Draft) V${ version } - ${ title }`
  }

  handleEditorChange = (e) => {
  }

  renderTable = () => {
    return this.state.usersList.map((value) => {
      return (
        <table>
          <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email Address</th>
            <th>Group</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{value.nickname}</td>
            <td>{value.user_metadata.name}</td>
            <td>{value.email}</td>
            <td>{value.user_metadata.companyName}</td>
          </tr>
          </tbody>
        </table>
      )
    })
  }

  render() {
    const {
      isLoading,
      isSaving,
      showLearnMessage,
      isSent,
      emailAddress,
      errorMessageSendEmail
    } = this.state
    const { messageArea } = this.props
    const headersListUsers = [
      { label: 'First Name', key: 'user_metadata.name' },
      { label: 'Last Name', key: 'nickname' },
      { label: 'Email Address', key: 'email' },
      { label: 'Group', key: 'user_metadata.companyName' }
    ]
    return (
      <div className="newQuestionnaire__page newQuestionnaire__update ">

        <div className="newAddUser_section">
          <div className="newTitle_header">
            <h1 className="styleTitlesAddUser">Add User(s)</h1>
            <h2 className="manageGroups-sectionSubtitle">Users who signup using your company domain email @company.com will automatically be added to your user list. You can also share this link: </h2>
            { !isLoading &&
            showLearnMessage &&
            <div className="dpiaDashboard-learn">
              <Link to="/dpia/about" className="dpiaDashboard-learnLink">
                http://osprey.gravicus.com/osp/signup?c=company-name&t=barclays+tntdelivery
              </Link>
              <span className="dpiaDashboard-learnClose" onClick={ this.dismissLearnMessage }></span>
            </div>
            }
          </div>
        </div>

        <div className="newAddUser_section">
          <div className="pl-4">
            <header className="styleBulk">
              <h1 className="styleTitlesAddUser styleTitlesAddUser1">Bulk Upload</h1>
              <h2 className="manageGroups-sectionSubtitle"> If you wish to add multiple users at once, you can do so by uploading a formatted CSV file. Download our template or follow the example below </h2>
            </header>
          </div>
          <div className="pl-4">
            <h2 className="styleTitleCSV">CSV Format Example</h2>
            <table className="CSV-Table">
              <thead>
              <tr>
                <th width="5%" >{'  '}</th>
                <th width="20%" className="Grey-border">A</th>
                <th width="20%"className="Grey-border">B</th>
                <th width="35%" className="Grey-border">C</th>
                <th width="20%" className="Grey-border">D</th>
              </tr>
              </thead>
              {this.state.usersList.map((value, index) => {
                if (value.email) {
                  return (
                    <tbody key={index}>
                    <tr>
                      <td className="td-style">{index +1}</td>
                      <td>{value.user_metadata.name}</td>
                      <td>{value.nickname}</td>
                      <td>{value.email}</td>
                      <td>{value.user_metadata.companyName}</td>
                    </tr>
                    </tbody>
                  )
                }
                return true
              })}
            </table>
          </div>

          <div className="newQuestionnaire__formGroup styleUploadImage ">
            <div className="download_csv_container">
              <div className="img_btn">
                <img className="teamIconExcel" src={ logoOsprey } alt="" />
                <CSVLink data={this.state.usersList} headers={headersListUsers} className="download_csv_link">
                  <button className="button_big_green">Download csv template</button>
                </CSVLink>
              </div>
            </div>
            <div className="upload_csv_container">
              <CSVReader
                cssClass="upload_csv_btn_container mt-1"
                cssInputClass ="upload_csv_btn"
                onFileLoaded={this.onDropFile}
                onError={this.handleDarkSideForce}
                inputId="file_csv_upload"
                label="UPLOAD NEW USERS CSV"
              />
            </div>
          </div>
        </div>

        <div className="newAddUser_section">
          <div className="newAddUser_formGroup  descriptionStyle m-3">
            <label className="styleTitleInput newQuestionnaire__requiredField" htmlFor="field_email">Enter any number of email addresses, separated by a comma</label>
            <input
              id="field_email"
              type="text"
              className="datesInput"
              value= {this.state.emailAddress}
              placeholder = 'name1@company.com, name2@company.com, name3@company.com'
              onChange={ (e) => this.handleChange(e, 'field_email') }
            />
            { errorMessageSendEmail &&
              <span className="error-message-send-email">{errorMessageSendEmail}</span>
            }
          </div>
          {/* //todo Add new editor */}
          {/*<div className="styleEditor2">*/}
          {/*  <label className="styleTitleInput2 newQuestionnaire__requiredField" >Enter your invitation message</label>*/}
          {/*  <FroalaEditor*/}
          {/*    model={this.state.model}*/}
          {/*    onModelChange={this.handleModelChange}*/}
          {/*    config={{*/}
          {/*      placeholderText: 'Eneter your invitation text here',*/}
          {/*      charCounterCount: false,*/}
          {/*      heightMin: 200,*/}
          {/*      toolbarButtons: ['bold', 'italic', 'underline', 'fontFamily', 'color', 'align', 'indent', 'quote', 'undo', 'redo']*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</div>*/}

          <div className="user-footer">
            <div>
              <Link className="userSettings-footerLink" to="/osp/manage/users">Manage Users</Link>
            </div>
            <div className="newQuestionnaire__submitButtons">
              { isSaving &&
              <div className="newQuestionnaire__loading">
                <Loading />
              </div>
              }
              <div className={ this.disabledButton ? 'newQuestionnaire__disabledButton' : ''}>
                {
                  !isLoading && !messageArea.text &&
                  <Button
                    theme={ this.disabledButton ? 'gray' : 'green' }
                    disabled={ emailAddress === '' }
                    onClick={ this.getSendInviteMultipleUser }>

                    SEND INVITE(S)
                  </Button>
                }
                {
                  isLoading && messageArea.text &&
                  <Message
                    hideMessageAction={ this.props.hideMessage }
                    status={ messageArea.status }
                    text={ messageArea.text } />
                }
                {
                  !isLoading && messageArea.text &&
                  <Message
                    hideMessageAction={ this.props.hideMessage }
                    status={ messageArea.status }
                    text={ messageArea.text } />
                }
                {
                  isLoading && !messageArea.text &&
                  <Loading/>
                }
              </div>
            </div>
          </div>

          { !isLoading &&
          showLearnMessage &&
          isSent &&
          <div className="dpiaDashboard-learn2">
            <Link to="/dpia/about" className="dpiaDashboard-learnLink2">
              <span><img className="" src={ logoOsprey1 } alt=" " /></span>  Your invites have been sent. Why not invite someone else?
            </Link>
            <span className="dpiaDashboard-learnClose" onClick={ this.dismissLearnMessage }></span>
          </div>
          }
        </div>
      </div>
    )
  }
}

AddUser = withRouter(AddUser)

const stateMap = (state) => ({
  messageArea: state.messageAreaReducer.messageArea
})

const dispatchMap = (dispatch) => ({
  hideMessage: () => dispatch(actions.hideMessage()),
  showMessage: (status, text) => dispatch(actions.showMessage({ status, text }))
})

AddUser.propTypes = {
  hideMessage: PropTypes.func.isRequired,
  messageArea: PropTypes.object.isRequired,
  showMessage: PropTypes.func.isRequired
}

export default compose(
  connect(stateMap, dispatchMap, null),
)(AddUser)