import React from "react";
import {connect} from "react-redux";
import '../BuilderWorkflow.scss';
import {Input, Checkbox, Select} from "antd";
import {apiGetFormBuilderListWorkflow, apiGetFormVariablesWorkflow} from "../../../../../api/SmartBox";
const { Option } = Select
class FormBuilder extends React.Component {
    state = {
        formOptions: {
            selectForm: '',
            formVariables: [],
            componentName: ''
        },
        hoverDelete: false,
        listForms: []
    }
    componentDidMount() {
        apiGetFormBuilderListWorkflow().then((response) => {
            this.setState({
                listForms: response.data
            })
        })
    }
    onHoverActionComponent = () => {
        this.setState({
            hoverDelete: true
        })
    }
    onLeaveActionComponent = () => {
        this.setState({
            hoverDelete: false
        })
    }
    onSelectForm = (e) => {
        const { listForms, formOptions } = this.state
        const formId = e
        const variablesForm = []
        let componentName = ''
        listForms && listForms.length > 0 && listForms.forEach((list) => {
              if (list.formId === e) {
                  componentName = list.formName
              }
        })
        apiGetFormVariablesWorkflow(formId).then((response) => {
            response.data && response.data.length > 0 && response.data.forEach((item) => {
                variablesForm.push(`# ${item}`)
            })
            this.setState({
                formOptions: { ...this.state.formOptions, selectForm: e, componentName: componentName, idComponent: e, formVariables: variablesForm},
            }, () => {
                this.props.onChangeBuilder(this.state.formOptions, this.props.component)
                this.props.onSelectIdForm(e)
            })
        })
    }
    render() {
        const { listForms, hoverDelete, formOptions } = this.state
        const { component } = this.props
        return (
            <div className="component-form">
                <div className="component-content">
                    <div className="header-component">
                        <span className="header-title">Form</span>
                    </div>
                    <div className="select-content">
                          <span onClick={ (e) => this.props.showConfirmDeleteSection(e, component) }
                              className={hoverDelete ? 'icon-v19-delete-active' : "icon-v19-delete" }
                              onMouseEnter={ (e) => this.onHoverActionComponent(e)}
                              onMouseLeave={() => this.onLeaveActionComponent()}></span>
                        <Select className="select-form"
                                value={ formOptions.selectForm ? formOptions.selectForm : component.componentName ? component.componentName : 'Select' }
                                onChange={(e) => this.onSelectForm(e)}
                                dropdownStyle={{border:"1px solid #eda41e"}}
                                placeholder="Select">
                            { listForms && listForms.length > 0 && listForms.map((item,index) => {
                                return  (<Option className="options-select-connector" value={item.formId} key={index}>
                                    {item.formName}
                                </Option>)
                            })}
                        </Select>
                        </div>
                    <span className="content-form-variables">
                        { formOptions.formVariables && formOptions.formVariables.length > 0 && formOptions.formVariables.map((item, index) => {
                            return (
                                <span key={index} className="variables">{item}</span>
                            )
                        })

                        }
                    </span>
                </div>
                <div className="component-move">
                    <span onMouseEnter={() => this.props.onStateProvidedDraggable(component)} className="icon-v211-grid-view-1"></span>
                </div>
            </div>
        )
    }
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(FormBuilder)