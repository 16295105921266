import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import './FormEmployeeWorkflow.scss'
import {customConfirm} from "../../../../../utils/AssetRegister/CustomConfirm";
import {apiSendInternalEmailNotificationWorkflow, getTicketWorkflowAndHeaderDetails} from "../../../../../api/SmartBox";
import CustomNotificationManager from "../../../../Common/CustomNotificationManager";
export class InternalEmailNotificationWorkflow extends Component {

    state = {
        cancelButtonModalWasPressed: false
    }
    componentDidMount() {
        return (
            customConfirm({
                title: <span className="icon-v22-nav-notifications"></span>,
                content: `Send internal email notification to move on to the next step.`,
                confirmButtonText: 'Send',
                cancelButtonText: 'Cancel',
                mask: true,
                maskClosable: false,
                closeHaveLogic: true,
                className: 'asset-register-modal modal-send-email-step-workflow modal-internal-email-notification',
                centered: true,
                cancelClassName: 'cancel-button',
                confirmClassName: 'confirm-button',
                confirmAction: () => {
                    this.callSendInternalEmailNotificationAPI(true)
                },
                rejectAction: () => {
                    this.callSendInternalEmailNotificationAPI(false)
                }
            })
        )
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if((this.props.stepHeaderActive.stepComponentWorkflowId !== prevProps.stepHeaderActive.stepComponentWorkflowId)) {
            return (
                customConfirm({
                    title: <span className="icon-v22-nav-notifications"></span>,
                    content: `Send internal email notification to move on to the next step.`,
                    confirmButtonText: 'Send',
                    cancelButtonText: 'Cancel',
                    mask: true,
                    maskClosable: false,
                    closeHaveLogic: true,
                    className: 'asset-register-modal modal-send-email-step-workflow modal-internal-email-notification',
                    centered: true,
                    cancelClassName: 'cancel-button',
                    confirmClassName: 'confirm-button',
                    confirmAction: () => {
                        this.callSendInternalEmailNotificationAPI(true)
                    },
                    rejectAction: () => {
                        this.callSendInternalEmailNotificationAPI(false)
                    }
                })
            )
        }
    }


    closeModal = () => {
        this.setState({
                cancelButtonModalWasPressed : true
        })
    }

    render() {

    }


    callSendInternalEmailNotificationAPI = (sendEmailOrNot) => {
        const {stepHeaderActive, selectHeaderTickets} = this.props
        if (stepHeaderActive && stepHeaderActive.stepComponentName === 'Internal email notification') {
            const SendInternalEmailNotificationTicketDTO = {
                ticketId: selectHeaderTickets.ticketId,
                workflowComponentId: stepHeaderActive.stepComponentWorkflowId,
                sendEmail: sendEmailOrNot
            }
            apiSendInternalEmailNotificationWorkflow(SendInternalEmailNotificationTicketDTO).then(response => {
                if (response.data.title && response.data.title.toLowerCase() === 'success') {
                    CustomNotificationManager.success(response.data.message, 'Success')
                } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
                    CustomNotificationManager.info(response.data.message, 'Info')
                }
                this.props.onGetTicketWorkflowAndHeaderDetails(selectHeaderTickets)
                getTicketWorkflowAndHeaderDetails(selectHeaderTickets.ticketId).then(r => {})
            })
        }
    }

}


InternalEmailNotificationWorkflow.propTypes = {
    folder: PropTypes.object,
}


const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id
})
export default compose(
    connect(mapStateToProps),
)(InternalEmailNotificationWorkflow)