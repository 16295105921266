import React, { Component } from 'react'
import { apiFetchByFilterArchive } from '../../../api/Sar'
import ArchiveList from './ArchiveList'
import Pagination from '../../Privacy/Form/Pagination'
import '../../../style/common.scss'
import './Archive.scss'
import { connect } from 'react-redux'
import CustomNotificationManager from "../../Common/CustomNotificationManager";

export class Archive extends Component {

  constructor() {
    super()
    this.state = {
      title: 'Archive',
      stepOfSar: null,
      timeExpiring: null,
      findText: null,
      filterSort: null,
      isLoading: true,
      showLearnMessage: true,
      sars: [],
      accordion: {},
      pageNumber: 1,
      amountPerPage: 10,
      totalElements: null,
      name: null,
      step: null,
      time: null,
      archived: true,
      classNameFind: 'questionnaire-filter__input-find',
      classNameFindFocus: 'questionnaire-filter__input-find-on-focus'
    }
  }
  componentDidMount() {
    this.fetchSars()
  }
  fetchSars = async() => {
    const data = {
      'step': this.state.step,
      'time': this.state.time,
      'archived': this.state.archived,
      'name': this.state.name,
      'pageNumber': this.state.pageNumber - 1,
      'amountPerPage': this.state.amountPerPage,
      'clientId': this.props.clientId
    }

    await apiFetchByFilterArchive(data).then((response) => {
      this.setState({
        isLoading: false,
        sars: response.data.sars,
        totalElements: response.data.totalElements
      })
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  };
  updateValue(e, field) {
    const value = e.target.value
    if (field === 'time') {
      this.setState({
        time: Number(value),
        pageNumber: 1
      }, () => this.fetchSars())
    }

    if (field === 'step') {
      this.setState({
        step: Number(value),
        pageNumber: 1
      }, () => this.fetchSars())
    }
  }
  updateFilterSearch(e) {
    const searchText = e.target.value
    if (searchText !== '' && searchText.length > 2) {
      const timeout = null
      clearTimeout(timeout)
      setTimeout(function() {
        this.fetchSars()
      }.bind(this), 1000)
    } else if (searchText === '') {
      this.fetchSars()
    }

    this.setState({
      name: searchText
    })
  }

  changePagination = (page) => {
    this.setState({ pageNumber: page }, () => this.fetchSars())
  }
  render() {
    const { sars, isLoading, totalElements, amountPerPage, pageNumber, classNameFind, classNameFindFocus, name } = this.state

    return (
      <div className="newSearch-page">
        <div className="filterDpia__wrapper">
          <div className="mu_filter__header">
            <div className="questionnaireFilter__wrapper">
              <div className="questionnaireFilter__button">
                <label>Find</label>
                <input className={name === '' || name === null ? classNameFind : classNameFindFocus}
                  onChange={(e) => this.updateFilterSearch(e)} onBlur={(e) => this.updateFilterSearch(e)}/>
              </div>
              <div id="sarFilter__display" className="questionnaireFilter__select">
                <label>Time</label>
                <select id="questionnaireFilter__teams" onChange={(e) => this.updateValue(e, 'time')}>
                  <option value="null">All</option>
                  <option value="30">Expiring in a month</option>
                  <option value="20">Expiring in 20 days</option>
                  <option value="15">Expiring in 15 days</option>
                  <option value="10">Expiring in 10 days</option>
                  <option value="7">Expiring in 1 week</option>
                  <option value="3">Expiring in 3 days</option>
                </select>
              </div>
              <div className="questionnaireFilter__select">
                <label>Step of SAR</label>
                <select id="questionnaireFilter__teams" onChange={(e) => this.updateValue(e, 'step')}>
                  <option value="null">All</option>
                  <option value="1">Initial Request</option>
                  <option value="2">Acknowledge</option>
                  <option value="3">Check ID</option>
                  <option value="4">Respond</option>
                  <option value="5">Search, Find and add documents</option>
                  <option value="6">Review and Redact</option>
                  <option value="7">Send securely</option>
                  <option value="8">Complete</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="styleAccordion">
          <ArchiveList sars={ sars } isLoading = { isLoading } fetchSars={ this.fetchSars } />
          { sars && sars.length > 0 ?
            (<div className="manageUsers-box">
              <React.Fragment>
                <div className="dpiaDashboard mt-4">
                  <div className="pagination__box">
                    <header className="pagination__box__header">
                      <div>
                        {sars !== null && sars.length > 0 &&
                        <span className="pagination__box__header__total">
                          <strong>{totalElements}</strong> results
                        </span>
                        }
                        <p className="pagination__box__header__text">
                          Page {pageNumber} of {Math.ceil(totalElements / amountPerPage)}
                        </p>
                      </div>
                      <Pagination
                        activePage={pageNumber}
                        changePagination={this.changePagination}
                        pages={Math.ceil(totalElements / amountPerPage)}/>
                    </header>
                  </div>
                </div>
              </React.Fragment>
            </div>) : (<div />)
          }
        </div>

      </div>
    )
  }
}



const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id
})

export default connect(mapStateToProps)(Archive)