import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import './ConnectorCard.css'

import Loading from '../../Common/Loading'

import imgLogoAlfresco from '../../../assets/logo-alfresco.png'
import imgLogoDropbox from '../../../assets/logo-dropbox.png'
import imgLogoSharepoint from '../../../assets/logo-sharepoint.png'

const ConnectorCard = ({ actionDelete, actionRescan, connector }) => (
  <div className="connectorCard__wrapper">
    <header className="connectorCard__header">
      <React.Fragment>
        { showConnectorName(connector.name) }
      </React.Fragment>
      <Link to={ `/privacy/connector/${ connector.id }` }>
        <span className="iconEdit" />
      </Link>
      { connector.description &&
        <p className="connectorCard__description">{ connector.description }</p>
      }
    </header>
    { connector.isFetchingInfo
      ? <Loading />
      : <div className="connectorCard__content">
        <p className="connectorCard__info">
          <strong>{ Math.round(connector.docSize/1024/1024) } MB</strong> - { connector.filesCounter } objects
        </p>
        <div className="connectorCard__buttons">
          <button
            className="connectorCard__buttonRescan"
            onClick={ actionRescan.bind(this, connector.id) }>Rescan</button>
        </div>
      </div>
    }
  </div>
)

const showConnectorName = (connectorName) => {
  if (connectorName.match(new RegExp('alfresco', 'i'))) return (
    <img src={ imgLogoAlfresco } alt="Logo Alfresco" />
  )
  if (connectorName.match(new RegExp('dropbox', 'i'))) return (
    <img src={ imgLogoDropbox } alt="Logo Dropbox" />
  )
  if (connectorName.match(new RegExp('sharepoint', 'i'))) return (
    <img src={ imgLogoSharepoint } alt="Logo Sharepoint" />
  )

  return connectorName
}

ConnectorCard.propTypes = {
  actionRescan: PropTypes.func.isRequired,
  connector: PropTypes.object.isRequired
}

export default ConnectorCard