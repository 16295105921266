import React from 'react'
import './NewBoxCreate.scss'
import { Button, Checkbox, Select } from 'antd'
import { apiUserFetchUsersListMinimized } from '../../../../api/User'
import Switcher from '../../../Common/Switcher'
import CustomNotificationManager from "../../../Common/CustomNotificationManager";
import {compose} from "redux";
import {connect} from "react-redux";
import {setSmartBoxNewBox} from "../../../../actions/smartBoxActions";
const { Option } = Select
class NewBoxConfiguration extends React.Component {

  constructor(props) {
    super(props)
    this.state= {
      usersList: [],
      formData: {
        sharingAndPermissions: [
          {
            userName: null
          },
          {
            userName: null
          },
          {
            userName: null
          }
        ]
      },
      valueShareFolder: true,
      valueVisibleAllUser: true,
      checkedKeys: [],
      checkedKeysDownload: [],
      valueVisibleDownload: true,
      valueAutomatedDeduplication: false,
      formDataDownload: {
        sharingAndPermissionsDownload: [
          {
            userName: null
          },
          {
            userName: null
          },
          {
            userName: null
          }
        ]
      },
      userListDownload: [],
      activeVisibleAdd: false,
      activeVisibleRemove: false,
      activePermissionAdd: false,
      activePermissionRemove: false
    }
  }
  componentDidMount() {
    const { sharingPermissionsConfiguration, formData, formDataDownload } = this.props
    this.fetchUsersList()
    if (sharingPermissionsConfiguration && sharingPermissionsConfiguration.statusValues) {
      this.setState({
        valueAutomatedDeduplication: sharingPermissionsConfiguration.autoDeduping,
        valueShareFolder: sharingPermissionsConfiguration.shareFolder,
        valueVisibleAllUser: sharingPermissionsConfiguration.visibleAllUsers,
        valueVisibleDownload: sharingPermissionsConfiguration.visibleToDownload,
        checkedKeys: sharingPermissionsConfiguration.users,
        checkedKeysDownload: sharingPermissionsConfiguration.usersAccessDownload

      })
    }
    if (formData && formData.sharingAndPermissions && formData.sharingAndPermissions.length > 0) {
       this.setState({
         formData: formData
       })
    }
    if (formDataDownload && formDataDownload.sharingAndPermissionsDownload && formDataDownload.sharingAndPermissionsDownload.length > 0) {
      this.setState({
        formDataDownload: formDataDownload
      })
    }
  }
  filtersUpdateUsersBack = () => {
    const { usersList, formData, userListDownload, formDataDownload } = this.state
    const { sharingPermissionsConfiguration } = this.props
    const sharingNewList = []
    const sharingNewListDownload = []
    usersList && usersList.length > 0 && usersList.forEach((userL) => {
      sharingPermissionsConfiguration && sharingPermissionsConfiguration.users && sharingPermissionsConfiguration.users.length > 0 && sharingPermissionsConfiguration.users.forEach((userSel) => {
        if (userL.value === userSel) {
          sharingNewList.push({
            userName: userL.label
          })
          formData.sharingAndPermissions = sharingNewList
        }
      })
    })
    this.setState({
      formData
    })
    userListDownload && userListDownload.length > 0 && userListDownload.forEach((userL) => {
      sharingPermissionsConfiguration && sharingPermissionsConfiguration.usersAccessDownload && sharingPermissionsConfiguration.usersAccessDownload.length > 0 && sharingPermissionsConfiguration.usersAccessDownload.forEach((userSel) => {
        if (userL.value === userSel) {
          sharingNewListDownload.push({
            userName: userL.label
          })
          formDataDownload.sharingAndPermissionsDownload = sharingNewListDownload
        }
      })
    })
    this.setState({
      formDataDownload
    })
   }
  fetchUsersList() {
    apiUserFetchUsersListMinimized()
      .then((response) => {
        this.setState({
          usersList: response.data,
          userListDownload: response.data
        }, () => this.filtersUpdateUsersBack())
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
    })
  }
  handleChangeActiveShareFolder(status) {
    this.setState({
      valueShareFolder: status
    })
  }
  handleChangeActiveShareFolderVisibleUser(status) {
    this.setState({
      valueVisibleAllUser: status
    })
  }
  handleChangeAutomatedDeduplication(status) {
    this.setState({
      valueAutomatedDeduplication: status
    })
  }
  setCheckedKeys = (checkedKeys) => {
    this.setState({ checkedKeys })
  }
  updateSetting(key, e, position) {
    const settings = this.state.formData
    if (key === 'userName') {
      const { sharingAndPermissions } = settings
      sharingAndPermissions[position][key] = e
      this.setState({
        formData: {
          ...settings,
          sharingAndPermissions
        }
      })
      if (e) {
        this.setState({
          stateAccessSelectUsers: false
        })
      }
    }
  }
  addItem = () => {
    const settings = this.state.formData
    const { sharingAndPermissions } = settings
    sharingAndPermissions.push({
      userName: null
    })
    this.setState({
      formData: {
        ...settings,
        sharingAndPermissions
      }
    })
  }
  removeItem = () => {
    const settings = this.state.formData
    let { sharingAndPermissions } = settings
    sharingAndPermissions = sharingAndPermissions.filter((sPermission, key) => !this.state.checkedKeys.includes(key))
    this.setState({
      formData: {
        ...settings,
        sharingAndPermissions
      },
      checkedKeys: []
    }, () => {
      if (this.state.formData.sharingAndPermissions && this.state.formData.sharingAndPermissions.length === 0 && this.state.checkedKeys.length === 0) {
        this.setState({
          formData: {
            sharingAndPermissions: [
              {
                userName: null
              }
            ]
          },
        })
      }
    })
  }
  addItemDownload = () => {
    const settings = this.state.formDataDownload
    const { sharingAndPermissionsDownload } = settings
    sharingAndPermissionsDownload.push({
      userName: null
    })
    this.setState({
      formDataDownload: {
        ...settings,
        sharingAndPermissionsDownload
      }
    })
  }
  removeItemDownload = () => {
    const settings = this.state.formDataDownload
    let { sharingAndPermissionsDownload } = settings
    sharingAndPermissionsDownload = sharingAndPermissionsDownload && sharingAndPermissionsDownload.length > 0 ? sharingAndPermissionsDownload.filter((sPermission, key) => !this.state.checkedKeysDownload.includes(key)) : ''
    this.setState({
      formDataDownload: {
        ...settings,
        sharingAndPermissionsDownload
      },
      checkedKeysDownload: []
    }, () => {
      if (this.state.formDataDownload.sharingAndPermissionsDownload && this.state.formDataDownload.sharingAndPermissionsDownload.length === 0 && this.state.checkedKeysDownload.length === 0) {
        this.setState({
          formDataDownload: {
            sharingAndPermissionsDownload: [
              {
                userName: null
              }
            ]
          },
        })
      }
    })
  }
  updateSettingDownload = (key, e, position) => {
    const settings = this.state.formDataDownload
    const usersSelected = []
    if (e) {
      usersSelected.push(e)
    }
    if (key === 'userName') {
      const { sharingAndPermissionsDownload } = settings
      sharingAndPermissionsDownload[position][key] = e
      this.setState({
        formDataDownload: {
          ...settings,
          sharingAndPermissionsDownload
        }
      })
      if (e) {
        this.setState({
          checkVisibleUserDownload: false
        })
      }
    } else {
      const newValue = settings[key] === false
      this.setState({
        formDataDownload: {
          ...settings,
          [key]: newValue
        }
      })
    }
  }
  handleChangeActiveVisibleDownload(status) {
    this.setState({
      valueVisibleDownload: status
    })
  }
  setCheckedKeysDownload = (checkedKeys) => {
    this.setState({
      checkedKeysDownload: checkedKeys
    })
  }
  handleBackConfiguration = (e, status) => {
    this.props.handleCancelBackPage(e, status)
    this.onKeepValuesBack()
  }
  onCreateBoxOrNextPage = (e, status) => {
    const { valueShareFolder, formDataDownload, formData, usersList, userListDownload } = this.state
    let { valueVisibleAllUser, valueVisibleDownload } = this.state
     let users = []
    let usersAccessDownload = []
    formData.sharingAndPermissions = formData && formData.sharingAndPermissions && formData.sharingAndPermissions.length > 0 ? formData.sharingAndPermissions.filter((user) => user.userName !== null) : []
    formDataDownload.sharingAndPermissionsDownload = formDataDownload && formDataDownload.sharingAndPermissionsDownload && formDataDownload.sharingAndPermissionsDownload.length > 0 ? formDataDownload.sharingAndPermissionsDownload.filter((user) => user.userName !== null) : []
    if (formData) {
      if (valueShareFolder === false) {
        users = []
        valueVisibleAllUser = null
      } else if (valueShareFolder === true && valueVisibleAllUser === true) {
        users = []
      } else {
        usersList && usersList.length > 0 && usersList.forEach((user) => {
          formData.sharingAndPermissions && formData.sharingAndPermissions.length > 0 && formData.sharingAndPermissions.forEach((value) => {
            if (user.label === value.userName) {
              users.push(user.value)
            }
          })
        })
      }
    }
    if (formDataDownload) {
      if (valueShareFolder === false) {
        valueVisibleDownload = null
        usersAccessDownload = []
      } else if (valueShareFolder === true && valueVisibleDownload === true) {
        usersAccessDownload = []
      } else {
        userListDownload && userListDownload.length > 0 && userListDownload.forEach((user) => {
          formDataDownload.sharingAndPermissionsDownload && formDataDownload.sharingAndPermissionsDownload.length > 0 && formDataDownload.sharingAndPermissionsDownload.forEach((value) => {
            if (user.label === value.userName) {
              usersAccessDownload.push(user.value)
            }
          })
        })
      }
    }
    const sharingPermissionsInputNewDto = {
      shareFolder: valueShareFolder,
      visibleAllUsers: valueVisibleAllUser,
      visibleToDownload: valueVisibleDownload,
      users: users,
      usersAccessDownload: usersAccessDownload,
      //autoDeduping: valueAutomatedDeduplication
    }
    this.props.onCreateBoxOrNextPage(e, status)
    this.props.onGetValuesConfiguration(sharingPermissionsInputNewDto)
    this.props.setSmartBoxNewBox([], [], [], false, false, false)
  }
  onKeepValuesBack = () => {
    const { valueShareFolder, formDataDownload, formData, usersList, userListDownload } = this.state
    let { valueVisibleAllUser, valueVisibleDownload } = this.state
    let users = []
    let usersAccessDownload = []
    formData.sharingAndPermissions = formData && formData.sharingAndPermissions && formData.sharingAndPermissions.length > 0 ? formData.sharingAndPermissions.filter((user) => user.userName !== null) : []
    formDataDownload.sharingAndPermissionsDownload = formDataDownload && formDataDownload.sharingAndPermissionsDownload && formDataDownload.sharingAndPermissionsDownload.length > 0 ? formDataDownload.sharingAndPermissionsDownload.filter((user) => user.userName !== null) : []
    if (formData) {
      if (valueShareFolder === false) {
        users = []
        valueVisibleAllUser = null
      } else if (valueShareFolder === true && valueVisibleAllUser === true) {
        users = []
      } else {
        usersList && usersList.length > 0 && usersList.forEach((user) => {
          formData.sharingAndPermissions && formData.sharingAndPermissions.length > 0 && formData.sharingAndPermissions.forEach((value) => {
            if (user.label === value.userName) {
              users.push(user.value)
            }
          })
        })
      }
    }
    if (formDataDownload) {
      if (valueShareFolder === false) {
        valueVisibleDownload = null
        usersAccessDownload = []
      } else if (valueShareFolder === true && valueVisibleDownload === true) {
        usersAccessDownload = []
      } else {
        userListDownload && userListDownload.length > 0 && userListDownload.forEach((user) => {
          formDataDownload.sharingAndPermissionsDownload && formDataDownload.sharingAndPermissionsDownload.length > 0 && formDataDownload.sharingAndPermissionsDownload.forEach((value) => {
            if (user.label === value.userName) {
              usersAccessDownload.push(user.value)
            }
          })
        })
      }
    }
    const sharingPermissionsInputNewDto = {
      shareFolder: valueShareFolder,
      visibleAllUsers: valueVisibleAllUser,
      visibleToDownload: valueVisibleDownload,
      users: users,
      usersAccessDownload: usersAccessDownload,
      //autoDeduping: valueAutomatedDeduplication,
      statusValues: true
    }
    this.props.onBackValuesConfiguration(sharingPermissionsInputNewDto)
    this.props.onSavedGeneral(formData, formDataDownload)
  }
  onActiveAddRemove = (e, status) => {
    switch (status) {
      case 'visible-user' :
        this.setState({
          activeVisibleAdd: true
        })
        break
      case 'visible-user-remove' :
        this.setState({
          activeVisibleRemove: true
        })
        break
      case 'add-user-download':
        this.setState({
          activePermissionAdd: true
        })
        break
      case 'remove-user-download':
        this.setState({
          activePermissionRemove: true
        })
        break
      default: break
    }
  }
  onInactiveAddRemove() {
    this.setState({
      activeVisibleAdd: false,
      activeVisibleRemove: false,
      activePermissionAdd: false,
      activePermissionRemove: false
    })
  }
  render() {
    const { valueShareFolder, valueVisibleAllUser, formData, checkedKeys, valueVisibleDownload, formDataDownload, checkedKeysDownload } = this.state
    let { usersList, userListDownload, activeVisibleAdd, activeVisibleRemove, activePermissionAdd, activePermissionRemove } = this.state
    formData && formData.sharingAndPermissions && formData.sharingAndPermissions.length > 0 && formData.sharingAndPermissions.forEach((form) => {
      usersList = usersList && usersList.length > 0 ? usersList.filter((user) => user.label !== form.userName) : ''
    })
    formDataDownload && formDataDownload.sharingAndPermissionsDownload && formDataDownload.sharingAndPermissionsDownload.length > 0 && formDataDownload.sharingAndPermissionsDownload.forEach((formUsers) => {
      userListDownload = userListDownload && userListDownload.length > 0 ? userListDownload.filter((user) => user.label !== formUsers.userName) : ''
    })
    return (
      <div className='box-setup-custom box-setup-configuration'>
        <div className="header-box-custom">
          <span className="title-custom">
              General
          </span>
          {/*<span className="description-custom">*/}
          {/*    Setup Box access and behavior.*/}
          {/*</span>*/}
        </div>
        <div className="box-setup-custom__content content-configuration">
          <div className="content-configuration-box-update">
          {/*<div className="content-visibility-folder">*/}
          {/*  <div className="title-folder-visibility">Automated deduplication</div>*/}
          {/*  <div className="content-functionality-visibility-folder content-automated-deduplication">*/}
          {/*    <div className="share-folder-container">*/}
          {/*      <div className="row">*/}
          {/*        <div className="left-container">*/}
          {/*          <div className="content-radio-share-folder">*/}
          {/*            <div className="teams switcher-select-configuration">*/}
          {/*              <Switcher*/}
          {/*                active={ valueAutomatedDeduplication }*/}
          {/*                updateActive={() => {*/}
          {/*                  this.handleChangeAutomatedDeduplication(!valueAutomatedDeduplication)*/}
          {/*                }}*/}
          {/*              />*/}
          {/*            </div>*/}
          {/*          </div>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="content-visibility-folder">
            <div className="title-folder-visibility">Share Box</div>
            <div className="content-functionality-visibility-folder">
              <div className="share-folder-container">
                <div className="row">
                  {/*<div className="left-container">*/}
                  {/*  <p className="title-users">Share Box</p>*/}
                  {/*</div>*/}
                  <div className="right-container">
                    <div className="content-radio-share-folder">
                      <div className="teams switcher-select-configuration">
                        <Switcher
                          active={ valueShareFolder }
                          updateActive={() => {
                            this.handleChangeActiveShareFolder(!valueShareFolder)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content-visibility-folder content-visibility-folder-update">
            {valueShareFolder === true &&
            <div className="title-folder-visibility title-folder-visibility-update">Box visible to all users</div>
            }
            <div className="content-functionality-visibility-folder">
              <div className="share-folder-container share-folder-container-visible">
                { valueShareFolder === true &&
                <div className="row">
                  <div className="right-container">
                    <div className="content-radio-share-folder">
                      <div className="teams switcher-select-configuration">
                        <Switcher
                            active={ valueVisibleAllUser }
                            updateActive={() => {
                              this.handleChangeActiveShareFolderVisibleUser(!valueVisibleAllUser)
                            }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                }
              </div>
              <div className="share-folder-container share-and-permissions-container">
                { valueShareFolder &&
                    <div className="row">
                      <div className="left-container">
                        <p className={ valueVisibleAllUser ? "title-users title-users-disabled" : 'title-users' }>Box only visible for:</p>
                      </div>
                      <div className="col-md-9 right-container">
                        <Checkbox.Group onChange={ (e) => this.setCheckedKeys(e) } value={ checkedKeys }>
                          { formData.sharingAndPermissions.map((permission, key) => <Checkbox
                              key={ key } value={ key } disabled={ !valueShareFolder || (valueShareFolder && valueVisibleAllUser) }>
                            <Select
                                value={ permission.userName || 'Select User' }
                                // value={ <span className="content-value-user"><span>{ permission.userName || 'Select User' }</span><span className="icon-v16-arrow-medium"></span></span> }
                                key={ key }
                                type="text"
                                disabled={ !valueShareFolder || (valueShareFolder && valueVisibleAllUser) }
                                onChange={ (e) => this.updateSetting('userName', e, key) }
                                showSearch
                                optionFilterProp="label"
                                className="user-team-select"
                                dropdownClassName="user-team-select-dropdown"
                                filterOption={ (input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                              { usersList && usersList.length > 0 && usersList.map((user, key) => <Option value={ user.label } key={ key }>{ user.label }</Option>) }
                            </Select>
                          </Checkbox>) }
                        </Checkbox.Group>
                        <div className="action-buttons-wrapper">
                          <div className={ !valueShareFolder ? 'action-buttons-wrapper--float-right content-disabled' : 'action-buttons-wrapper--float-right' }>
                            <button className={ activeVisibleRemove ? "icon-v16-minus-active" : 'icon-v16-minus' } disabled={ checkedKeys && !checkedKeys.length > 0}
                                    onClick={ this.removeItem }
                                    onMouseEnter={(e) => this.onActiveAddRemove(e, 'visible-user-remove')}
                                    onMouseLeave={() => this.onInactiveAddRemove()}/>
                            <button disabled={ valueVisibleAllUser === true } className={ activeVisibleAdd ? 'icon-v16-add-active' : 'icon-v16-add' }
                                    onClick={ this.addItem }
                                    onMouseEnter={(e) => this.onActiveAddRemove(e, 'visible-user')}
                                    onMouseLeave={() => this.onInactiveAddRemove()}/>
                          </div>
                        </div>
                      </div>
                    </div>
                }
              </div>
            </div>
          </div>
          <div className="content-visibility-folder content-visibility-permission content-visibility-folder-update">
            { valueShareFolder &&
            <div className="title-folder-visibility">Allow downloads</div>
            }
            { valueShareFolder &&
            <div className="content-functionality-visibility-folder">
              <div className="share-folder-container">
                <div className="row">
                  <div className="right-container">
                    <div className="content-radio-share-folder">
                      <div className="teams switcher-select-configuration">
                        <Switcher
                          active={ valueVisibleDownload }
                          updateActive={() => {
                            this.handleChangeActiveVisibleDownload(!valueVisibleDownload)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="share-folder-container share-and-permissions-container">
                { valueShareFolder &&
                  <div className="row">
                    <div className="left-container">
                      <p className={ valueVisibleDownload ? 'title-users title-users-disabled' : "title-users" }>Restrict downloads to:</p>
                    </div>
                    <div className="col-md-9 right-container">
                      <Checkbox.Group onChange={ (e) => this.setCheckedKeysDownload(e) } value={ checkedKeysDownload } disabled={ valueVisibleDownload }>
                        { formDataDownload.sharingAndPermissionsDownload.map((permission, key) => <Checkbox
                          key={ key } value={ key }>
                          <Select
                              value={ permission.userName || 'Select User' }
                            // value={ <span className="content-value-user"><span>{ permission.userName || 'Select User' }</span><span className="icon-v16-arrow-medium"></span></span> }
                            key={ key }
                            type="text"
                            disabled={ valueVisibleDownload }
                            onChange={ (e) => this.updateSettingDownload('userName', e, key) }
                            showSearch
                            optionFilterProp="label"
                            className="user-team-select"
                            dropdownClassName="user-team-select-dropdown"
                            filterOption={ (input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            { userListDownload && userListDownload.length > 0 && userListDownload.map((user, key) => <Option value={ user.label } key={ key }>{ user.label }</Option>) }
                          </Select>
                        </Checkbox>) }
                      </Checkbox.Group>
                      <div className="action-buttons-wrapper">
                        <div className="action-buttons-wrapper--float-right">
                          <button className={ activePermissionRemove ? 'icon-v16-minus-active' : 'icon-v16-minus' } disabled={ checkedKeysDownload && !checkedKeysDownload.length > 0 }
                                  onClick={ this.removeItemDownload }
                                  onMouseEnter={(e) => this.onActiveAddRemove(e, 'remove-user-download')}
                                  onMouseLeave={() => this.onInactiveAddRemove()}/>
                          <button disabled={ valueVisibleDownload } className={ activePermissionAdd ? 'icon-v16-add-active' : 'icon-v16-add' }
                                  onClick={ this.addItemDownload }
                                  onMouseEnter={(e) => this.onActiveAddRemove(e, 'add-user-download')}
                                  onMouseLeave={() => this.onInactiveAddRemove()}/>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
            }
          </div>
          </div>

          <div className="footer-buttons-action-new-box custom-buttons configuration-buttons">
            <Button className="action-cancel-new-box" onClick={ (e) => this.handleBackConfiguration(e, 'back-configuration')}>
              Back
            </Button>
            <div className="statistics-active-new-box">
              <span className="dot-new-box"></span>
              <span className="dot-new-box"></span>
              <span className="dot-new-box"></span>
              <span className={ this.props.activePage === 'configuration' ? 'dot-new-box dot-new-box active-dot-new-box' : "dot-new-box" }></span>
            </div>
            <Button className="action-create-new-box" onClick={ (e) => this.onCreateBoxOrNextPage(e, 'new-box-configuration') }
            >
              Create
            </Button>
          </div>

        </div>

        {/*<div className="footer-buttons-action-new-box custom-buttons configuration-buttons">*/}
        {/*  <Button className="action-cancel-new-box" onClick={ (e) => this.handleBackConfiguration(e, 'back-configuration')}>*/}
        {/*    Back*/}
        {/*  </Button>*/}
        {/*  <div className="statistics-active-new-box">*/}
        {/*    <span className="dot-new-box"></span>*/}
        {/*    <span className="dot-new-box"></span>*/}
        {/*    <span className="dot-new-box"></span>*/}
        {/*    <span className={ this.props.activePage === 'configuration' ? 'dot-new-box dot-new-box active-dot-new-box' : "dot-new-box" }></span>*/}
        {/*  </div>*/}
        {/*  <Button className="action-create-new-box" onClick={ (e) => this.onCreateBoxOrNextPage(e, 'new-box-configuration') }*/}
        {/*  >*/}
        {/*    Create*/}
        {/*  </Button>*/}
        {/*</div>*/}
      </div>
    )
  }
}

NewBoxConfiguration.propTypes = {
}
const mapStateToProps = (state) => ({
  smartBoxNewBox: state.smartBox.smartBoxNewBox,
})
export default compose(
    connect(mapStateToProps, {
      setSmartBoxNewBox
    }),
)(NewBoxConfiguration)