import './DropMultipleFileDirectory.scss'
import React from 'react'
import Dropzone from 'react-dropzone'
import { getDroppedOrSelectedFiles } from 'html5-file-selector'
import WebWorker from './workers/WebWorker'
import worker from './workers/file-extensions.worker'
import { notification } from 'antd'
import { setSmartBoxCheckFirstUploadFile, setSmartBoxUploadingObject, setSmartBoxDataReady } from '../../../actions/smartBoxActions'
import { connect } from 'react-redux'
import CustomNotificationManager from '../../Common/CustomNotificationManager'
import { checkIfFileHasSizeIsBig } from '../../../utils/SmartBox/SmartBoxMethods'

class DropMultipleFileDirectory extends React.Component {
  state = {
    activeNotificationUpload: false,
    acceptedFilesUpload: []
  }
  
  componentDidMount() {
    this.worker = new WebWorker(worker)
    this.worker.addEventListener('message', (e) => {
      const { acceptedFiles, rejectedFiles } = e.data
      this.setState({
        acceptedFilesUpload: acceptedFiles
      })
      if (this.props.emptyFolder && acceptedFiles && acceptedFiles.length > 0 && this.props.folderNewDepth === 1) {
        this.props.setSmartBoxCheckFirstUploadFile('true', this.props.folder)
      } else if (!this.props.emptyFolder && this.props.folderNewDepth === 1) {
        this.props.setSmartBoxCheckFirstUploadFile('false', this.props.folder)
      }
      this.props.setSmartBoxDataReady('false', this.props.folder )

      let nameFile = ''
      let nameExtension = ''
      let nameToBe = ''
      const newNames = rejectedFiles && rejectedFiles.length > 0 && rejectedFiles.map((file) => { return (file.name.substring(0, file.name.indexOf('.'))) })
      const newTypes = [...new Set(rejectedFiles.map((file) => { return (file.name.substring(file.name.indexOf('.') + 1)) }))]
      if (rejectedFiles && rejectedFiles.length > 1) {
        nameFile = 'files'
        nameExtension = 'extensions'
        nameToBe = 'are'
      } else {
        nameFile = 'file'
        nameExtension = 'extension'
        nameToBe = 'is'
      }
      this.props.setSmartBoxUploadingObject(acceptedFiles, this.props.folder.relativePath.substr(1))
      if (rejectedFiles && rejectedFiles.length > 0) {
        notification.warning({
          message: 'Not Uploaded',
          description: `Following ${ nameFile } "${ newNames }" cannot be uploaded. The ${ nameExtension } "${ newTypes }" ${ nameToBe } not permitted.`,
          className: 'notification-warning-upload',
          duration: 1000000
        })
      }
    })
    window.addEventListener('resize', this.handleResize.bind(this))
    if (this.props.emptyFolder) {
      this.setState({
        activeNotificationUpload: true
      })
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props && prevState.acceptedFilesUpload.length === 0 && !this.props.emptyFolder) {
      this.props.setSmartBoxCheckFirstUploadFile('false', this.props.folder)
    }
  }

  handleResize() {
    if(document.querySelector('.mega-drop-zone')) {
      document.querySelector('.mega-drop-zone').style.height = this.props.emptyFolder ? `${ document.body.offsetHeight - 175 }px` : 'auto'
    }
  }
  
  onDrop = (files) => {
    this.worker.postMessage(Object.values(files))
  }
  
   handleSubmit = (files, allFiles) => {
     allFiles.forEach((f) => f.remove())
   }
  onDragOver = (event) => {
    document.querySelector('.dzu-dropzone').classList.add('dzu-dropzoneActive')
     document.getElementsByClassName('smart-box-folder-opened_container__content__files new-content-file')[0].classList.add('drag-over-breadcrumb')
  }
  onDragLeave = (event) => {
    document.querySelector('.dzu-dropzone').classList.remove('dzu-dropzoneActive')
     document.getElementsByClassName('smart-box-folder-opened_container__content__files new-content-file')[0].classList.remove('drag-over-breadcrumb')
  }

  getFilesFromEvent = (e) => {
    return new Promise(() => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        if (chosenFiles.length) {
          if (checkIfFileHasSizeIsBig(chosenFiles)) {
            CustomNotificationManager.info('The file size is too large. Please upload one under 5 GB', 'The file is too large', { duration: 3 })
            return false
          }
          let hasFolder = false
          chosenFiles.forEach((file) => {
            if (file.fullPath.split('/').length - 1 > 1) {
              hasFolder = true
            }
          })
          // if (checkIfFolderIsPstOrZip(chosenFiles)) {
          //   CustomNotificationManager.info('The file may take a moment to appear.', 'Please wait a moment', { duration: 3 })
          // }
          if (hasFolder) {
            CustomNotificationManager.warning('Please don\'t upload folder with drag and drop. Use upload button instead.', 'Not Permitted')
          } else {
            this.worker.postMessage(chosenFiles)
          }
        }
      })
    })
  }

  render() {
    let height, marginTop
    if (this.props.emptyFolder) {
      height = `${ document.body.offsetHeight - 150 }px`
      marginTop = -35
    } else {
      height = 'auto'
      marginTop = 0
    }
// InputComponent={Input}
    /*<Dropzone
      accept="*"
      getUploadParams={() => ({ url: '' })}
      onSubmit={this.handleSubmit}
      getFilesFromEvent={this.getFilesFromEvent}
      InputComponent={Input}
    />*/
    return (
      <div className={`mega-drop-zone${ this.props.emptyFolder ? ' full-height mega-empty' : '' }`}
        style={{ height, marginTop }} >
        <Dropzone onDragOver={this.onDragOver} onDragLeave={this.onDragLeave}
                  getFilesFromEvent={this.getFilesFromEvent}
                  noClick={true}
        >
          {({getRootProps, getInputProps}) => (
            <div className="dzu-dropzone">
              <div
                {...getRootProps({
                  className: 'btn-upload',
                })}
              >
                {/*<input {...getInputProps()} />*/}
                <input
                    className="hidden-file-input"
                    type="file"
                    directory=""
                    multiple
                    onChange={this.props.customUploadFolder}
                    name="file"
                    id="file"
                    title=""
                />
                { (this.props.folder.statisticsReady === "true" && this.props.filesTable.length === 0) ? <label>No results found</label> :
                    (this.props.filesTable.length === 0 && Object.keys(this.props.currentNewFilters) && Object.keys(this.props.currentNewFilters).length > 0
                    && this.props.currentNewFilters.checkedStatus.length > 0) ? <label>No results found</label> :
                        (this.props.filesTable.length === 0 && Object.keys(this.props.currentNewFilters) && Object.keys(this.props.currentNewFilters).length > 0
                            && this.props.currentNewFilters.checkedTag.length > 0) ? <label>No results found</label> :<label>Drag and drop a file here</label> }
              </div>
            </div>
          )}
        </Dropzone>
      </div>
    )
  }
}

// const Input = () => {
//   return (
//     <label className='btn-upload'>
//       <label>Drag and drop a file here</label>
//     </label>
//   )
// }

const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id,
  redactingMode: !state.shellReducer.sidebarVisible && !state.shellReducer.headerVisible && !state.shellReducer.footerVisible,
  fetchFolderContent: state.smartBox.fetchFolderContent,
  intervalForAiPages: state.smartBox.intervalForAiPages,
  intervalForPdfPages: state.smartBox.intervalForPdfPages,
  currentNewFilters: state.smartBox.currentNewFilters,
})
export default connect(mapStateToProps, {
  setSmartBoxUploadingObject, setSmartBoxCheckFirstUploadFile, setSmartBoxDataReady })(DropMultipleFileDirectory)