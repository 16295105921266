import PropTypes from 'prop-types'
import React from 'react'
import { apiPrivacyAddNewCrawler, apiPrivacyCrawlerGetStatus, apiPrivacyCrawlerStart, apiPrivacyGetConnectorObjects, apiPrivacyGetConnectors, apiPrivacyGetServers } from '../api/Privacy'
import * as utils from '../utils/Connectors'

const ConnectorsContext = React.createContext()

class ConnectorsProvider extends React.Component {
  state = {
    connectors: [],
    isFetchingConnectors: true,
    servers: [],
    totalFiles: 0,
    totalStorage: 0
  }

  getConnectors = () => {
    if (this.state.connectors.length > 0) return

    apiPrivacyGetConnectors()
      .then((response) => {
        const connectors = utils.mappedCrawlers(response)

        this.setState({
          allConnectors: connectors,
          connectors,
          isFetchingConnectors: false
        })

        for (const connector of connectors) {
          this.getObjects(connector.id)
          this.getStatus(connector.id)
        }
      })
  };

  getObjects = (id) => {
    apiPrivacyGetConnectorObjects(id)
      .then((response) => {
        const newConnectors = utils.mappedConnectorWithInformation(this.state.allConnectors, id, response)

        this.setState({
          allConnectors: newConnectors,
          connectors: newConnectors,
          totalFiles: newConnectors.reduce((acc, connector) => acc + connector.filesCounter, 0),
          totalStorage: newConnectors.reduce((acc, connector) => acc + connector.docSize, 0)
        })
      })
  };

  getStatus = (id) => {
    apiPrivacyCrawlerGetStatus(id)
      .then((response) => {
      })
  };

  getServers = () => {
    apiPrivacyGetServers()
      .then((response) => {
        if (response.data.status === 'Success') {
          if (response.data.value && response.data.value.servers && response.data.value.servers.server) {
            let servers = response.data.value.servers.server 
            if (!(servers instanceof Array)) servers = [servers]
            this.setState({ servers })
          }
        }
      })
  };

  addNewCrawler = (name, serverID, crawlerConfig) => {
    return new Promise((resolve, reject) => {
      apiPrivacyAddNewCrawler(serverID, crawlerConfig)
        .then((response) => {
          if (response.data.message === 'New Crawler added.') {
            // TODO: Put the real id
            const newConnectors = [...this.state.allConnectors, {
              id: 14, isFetchingInfo: true, name
            }]
            
            this.setState({
              allConnectors: newConnectors, connectors: newConnectors
            }, () => {
              this.getObjects(14)
              resolve()
            })
          }
          reject()
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  getInformationForOneConnector = (id) => {
    // apiPrivacyGetConnectorConfiguration(id)
    //   .then((response) => {
    //     console.log(response)
    //   })
  }

  crawlerStart = (crawlerID) => {
    apiPrivacyCrawlerStart(crawlerID)
  }

  resetConnectors = () => {
    this.setState({ connectors: this.state.allConnectors })
  }

  filterConnectorsByTerm = (term) => {
    if (term === '') {
      return this.setState({ connectors: this.state.allConnectors })
    }

    const termRE = new RegExp(term, 'i')
    const newConnectors = this.state.allConnectors
      .filter((connector) => connector.name.match(termRE))

    this.setState({ connectors: newConnectors })
  }

  updateConnectors = (connectors) => {
    this.setState({ allConnectors: connectors, connectors })
  }

  render = () => {
    return (
      <ConnectorsContext.Provider value={{
        addNewCrawler: this.addNewCrawler,
        connectors: this.state.connectors,
        crawlerStart: this.crawlerStart,
        filterConnectorsByTerm: this.filterConnectorsByTerm,
        getConnectors: this.getConnectors,
        getInformationForOneConnector: this.getInformationForOneConnector,
        getServers: this.getServers,
        isFetchingConnectors: this.state.isFetchingConnectors,
        resetConnectors: this.resetConnectors,
        servers: this.state.servers,
        totalConnectors: this.state.connectors.length,
        totalFiles: this.state.totalFiles,
        totalStorage: this.state.totalStorage,
        updateConnectors: this.updateConnectors
      }}>
        { this.props.children }
      </ConnectorsContext.Provider>
    )
  }
}

ConnectorsProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export default ConnectorsProvider
export const ConnectorsConsumer = ConnectorsContext.Consumer