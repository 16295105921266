import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import { apiQuestionnairesList } from '../../../api/Questionnaire'
import Button from '../../Common/Button'
import Page403 from '../../Common/ErrorPage/Page403'
import Loading from '../../Common/Loading'
import './QuestionnaireDashboard.scss'
import Filters from '../Questionnaires/DashboardFilters'
import { denormalizeQuestionnaireForDashboard } from '../Questionnaires/Helpers/Denormalizer'
import { filterTemplates } from '../Questionnaires/Helpers/Filter'
import { sortByPublication } from '../Questionnaires/Helpers/Utils'
import Template from '../Questionnaires/QuestionnaireTemplate'

class QuestionnaireDashboard extends React.Component {
  constructor() {
    super()

    this.state = {
      isLoading: true,
      actives: [],
      accordion: {},
      inactives: [],
      globals: [],
      templates: []
    }

    this.toggleAccordion = this.toggleAccordion.bind(this)
    this.filterQuestionnaires = this.filterQuestionnaires.bind(this)
    this.fetchQuestionnaires = this.fetchQuestionnaires.bind(this)
  }

  componentDidMount() {
    this.fetchQuestionnaires()
    
  }

  fetchQuestionnaires() {
    apiQuestionnairesList()
      .then((response) => {
        const questionnaires = sortByPublication(response.data)
        const denormalizedQuestionnaires = questionnaires.map((questionnaire) => (
          denormalizeQuestionnaireForDashboard(questionnaire))
        )
        this.setState({ allTemplates: denormalizedQuestionnaires })
        this.groupList(denormalizedQuestionnaires)
      })
  }

  groupList(templates) {
    const actives = templates.filter((template) => template.isEnabled && template.isGlobal === false)
    const inactives = templates.filter((template) => template.isEnabled === false && template.isGlobal === false)
    const globals = templates.filter((template) => template.isGlobal)

    this.setState({ templates, actives, inactives, globals, isLoading: false })
  }

  toggleAccordion(index) {
    this.setState({
      accordion: {
        ...this.state.accordion,
        [index]: !this.state.accordion[index]
      }
    })
  }

  renderTemplateList(templates) {
    return templates.map((template) => (
      <Template
        key={`template-${ template.id }` }
        template={ template }
        toggleAccordion={ this.toggleAccordion }
        accordion={ this.state.accordion }
        fetchQuestionnaires={ this.fetchQuestionnaires }
      />
    ))
  }

  filterQuestionnaires(filterAttributes) {
    const templates = filterTemplates(filterAttributes, this.state.allTemplates)

    this.setState({ templates }, () => {
      this.groupList(templates)
    })
  }

  render() {
    const {
      isLoading,
      actives,
      globals,
      inactives
    } = this.state

    const { subscription } = this.props

    if (!subscription.unlimitedPlan) {
      return (
        <div className="questionnaireDashboard__page">
          <Page403 />
        </div>
      )
    }

    return (
      <div className="questionnaireDashboard__page">
        { isLoading &&
          <div className="questionnaireDashboard__loading">
            <Loading />
          </div>
        }

        { !isLoading &&
          <React.Fragment>
            <div className="questionnaireDashboard__header">
              <Filters
                filterQuestionnaires={ this.filterQuestionnaires }
              />

              <div className="questionnaireDashboard__newButtonWrapper">
                <Link to="/dpia/questionnaires/new">
                  <Button theme="green">
                    Create New Questionnaire
                  </Button>
                </Link>
              </div>
            </div>

            { actives.length > 0 &&
              <React.Fragment>
                <h2 className="dpiaDashboard__block-title">ACTIVE</h2>
                { this.renderTemplateList(actives) }
              </React.Fragment>
            }

            { globals.length > 0 &&
              <div>
                <h2 className="dpiaDashboard__block-title">GLOBAL</h2>
                { this.renderTemplateList(globals) }
              </div>
            }

            { inactives.length > 0 &&
              <div>
                <h2 className="dpiaDashboard__block-title">INACTIVE</h2>
                { this.renderTemplateList(inactives) }
              </div>
            }
          </React.Fragment>
        }
      </div>
    )
  }
}

const stateMap = (state) => ({
  subscription: state.subscriptionReducer.subscription
})

QuestionnaireDashboard.propTypes = {
  subscription: PropTypes.object.isRequired,
}

export default compose(connect(stateMap, null))(QuestionnaireDashboard)