import React, { Component } from 'react'
import './ExploreData.scss'
import { Button, Select, Table, Icon } from 'antd'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  deleteAnalyticsFilter,
  fetchSubFolders, getAnalyticsByFilter,
  getAnalyticsFilter,
  saveAnalyticsFilter
} from '../../../../api/SmartBox'
import CustomNotificationManager from "../../../Common/CustomNotificationManager";

const { Option } = Select

export class ExploreData extends Component {
  state = {
    conditions: [],
    categoryName: 'ALL',
    numberInputs: [],
    listInputs: 0,
    analyticsList: [],
    visibleResults: false,
    displayExploreClose: false,
    filterCategory: '',
    listFilter: [],
    pageNumber: 1,
    amountPerPage: 10,
    totalPages: 0
  }

  columns = [
    {
      title: 'Unique reference',
      key: 'unique-reference',
      dataIndex: 'uniqueReference'
    },
    {
      title: 'Analytics results',
      key: 'analytics-results',
      dataIndex: 'analyticsResult'
    },
    {
      title: 'Folder',
      key: 'folder',
      dataIndex: 'folder'
    },
    {
      title: 'Document',
      key: 'document',
      dataIndex: 'document'
    },
    {
      title: 'Count',
      key: 'count',
      dataIndex: 'count'
    },
    {
      title: 'Category',
      key: 'category',
      dataIndex: 'category'
    },
    {
      title: 'Location',
      key: 'location',
      dataIndex: 'location'
    },
  ]

  componentDidMount() {
    const getSubFoldersApiDTO = {
      folderPath: this.props.folder.path
    }
    fetchSubFolders(getSubFoldersApiDTO).then((res) => {
      this.setState({
        subFoldersList: res.data
      })
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
    getAnalyticsFilter(this.props.folder.id).then((res) => {
      if (res.data && res.data.length > 0) {
        this.setState({
          conditions: res.data,
          categoryName: 'CONDITIONS'
        })
      }

    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  onClickAddInputs(event, field, changeCategoryName = false) {
    const { conditions } = { ...this.state }

    const categoryName = changeCategoryName ? event : this.state.categoryName

    switch (field) {
      case 'addInputs':
        conditions.push({
          key: conditions.length + 1,
          category: null,
          location: null,
          folder: null
        })
        this.setState({
          listInputs: this.state.listInputs + 1,
          conditions,
          categoryName: categoryName
        })
        break
      case 'removeInputs':
        conditions.pop()
        this.setState({
          listInputs: this.state.listInputs - 1,
          conditions,
          categoryName: categoryName
        })
        break
      default:
        break
    }
  }

  onFilterSearch(arrayKey, objectKey, value) {
    const { conditions } = this.state
    conditions[arrayKey][objectKey] = value
    this.setState({
      conditions
    })
  }

  renderInputs = () => {
    const { conditions, listInputs, subFoldersList } = this.state
    return conditions.map((child, key) => (
      <div className="explore-data-page__content-export-data__condition-item" key={ key }>
        { key < 1 &&
        <span className="label-search">Search Conditions</span>
        }
        <div className="content-search-category">
          <Select onChange={ (e) => this.onFilterSearch(key, 'category', e) } placeholder="Category"
            value={ child.category }>
            <Option value={ null }>
              Select Category
            </Option>
            <Option value="ADDRESS">
              Address
            </Option>
            <Option value="PERSON">
              Person
            </Option>
            <Option value="LOCATION">
              Location
            </Option>
            <Option value="NUMBERS">
              Numbers
            </Option>
            <Option value="TIME">
              Time
            </Option>
            <Option value="IDENTIFIERS">
              Identifiers
            </Option>
          </Select>
          <span className="signal-and">&</span>
          <Select onChange={ (e) => this.onFilterSearch(key, 'location', e) } placeholder="Location"
            value={ child.location }>
            <Option value={ null }>
              Select location
            </Option>
            <Option value="SERVER">
              Server
            </Option>
          </Select>
          <span className="signal-and">&</span>
          <Select onChange={ (e) => this.onFilterSearch(key, 'folder', e) } placeholder="Folder" value={ child.folder }>
            <Option value={ null }>
              Select folder
            </Option>
            { subFoldersList && subFoldersList.length > 0 && subFoldersList.map((subFolder, key) => (
              <Option value={ subFolder.id } key={ key }>
                { subFolder.name }
              </Option>
            )) }
          </Select>
        </div>
        { key < listInputs - 1 &&
        <span className="condition-or">or</span>
        }
      </div>
    ))
  }

  onClickSearch = () => {
    const { categoryName, pageNumber, amountPerPage, conditions } = this.state
    const { folder } = this.props
    if (conditions && conditions.length) {
      conditions.map((item, key) => {
        if (!item.category && !item.location && !item.folder) {
          conditions.splice(key, 1)
        }
        return true
      })
    }
    //api for getting data from back end
    const data = {
      folderId: folder.id,
      conditions: categoryName === 'ALL' ? [] : conditions,
      pageNumber,
      amountPerPage,
      path: folder.path
    }
    this.setState({
      loading: true
    }, () => {
      getAnalyticsByFilter(data).then((res) => {
        this.setState({
          visibleResults: true,
          analyticsList: res.data.items,
          totalPages: res.data.totalPage,
          loading: false
        })
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    })
  }

  saveFilter = () => {
    //api to save filter here
    const data = {
      folderId: this.props.folder.id,
      conditions: this.state.conditions
    }
    saveAnalyticsFilter(data)
  }

  resetFilter = () => {
    deleteAnalyticsFilter(this.props.folder.id).then(() => this.setState({
      conditions: [],
      analyticsList: [],
      visibleResults: false,
      categoryName: 'ALL'
    })).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  onChange = (e) => {
    if (e === 'CONDITIONS') {
      this.onClickAddInputs(e, 'addInputs', true)
    } else {
      this.setState({ categoryName: e })
    }
  }


  setPageableValues(page) {
    this.setState({
      pageNumber: page
    }, () => this.onClickSearch())
  }

  setPageSizeValues(current, pageSize) {
    this.setState({
      amountPerPage: pageSize
    }, () => this.onClickSearch())
  }

  render() {
    const { analyticsList, visibleResults, displayExploreClose, categoryName, conditions, pageNumber, amountPerPage, totalPages, loading } = this.state
    return (
      <React.Fragment>
        { !displayExploreClose &&
        <div className="explore-data-page">
          <Button className="explore-data-page" id="export_btn">EXPORT DATA</Button>
          <div className="explore-data-page__content-export-data">
            <div className="content-category">
              <div className="select-category">
                <label className="label-category">Category</label>
                <Select defaultValue="ALL" onChange={ this.onChange }>
                  <Option value="ALL">
                    All analytic results
                  </Option>
                  <Option value="CONDITIONS">
                    All by conditions
                  </Option>
                </Select>
              </div>
              <div className="content-category-buttons">
                <Button onClick={ this.saveFilter } disabled={ (conditions.length) ? false: true }>SAVE FILTER SETTINGS</Button>
                <Button onClick={ this.resetFilter } disabled={ (conditions.length) ? false: true }>REFRESH</Button>
                <Button onClick={ this.resetFilter } disabled={ (conditions.length) ? false: true }>RESET FILTER</Button>
              </div>
            </div>

            { categoryName && categoryName === 'CONDITIONS' && this.renderInputs() }
            { categoryName && categoryName === 'CONDITIONS' && <div className="content-buttons-add">
              <Icon onClick={ (e) => this.onClickAddInputs(e, 'addInputs') } type="plus-circle"/>
              <Icon onClick={ (e) => this.onClickAddInputs(e, 'removeInputs') } type="minus-circle"/>
            </div> }
            <div className="buttons-search">
              <Button onClick={ this.onClickSearch }>SEARCH</Button>
            </div>
          </div>
          { visibleResults &&
          <div className="explore-data-page__content-table-search">
            <span className="find-result">{ `Result: ${ totalPages }` }</span>
            <Table dataSource={ analyticsList } columns={ this.columns }
              loading={ loading }
              pagination={
                {
                  current: pageNumber,
                  pageSize: amountPerPage,
                  onChange: (page, pageSize) => this.setPageableValues(page, pageSize),
                  total: totalPages,
                  pageSizeOptions: ['10', '25', '50', '100'],
                  showSizeChanger: true,
                  onShowSizeChange: (current, size) => this.setPageSizeValues(current, size)
                }
              }
            />
          </div>
          }
        </div>
        }
      </React.Fragment>
    )
  }
}

ExploreData.propTypes = {
  folder: PropTypes.object.isRequired,
  hideExploreDataComponent: PropTypes.func,
}
const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id
})

export default compose(
  connect(mapStateToProps),
)(ExploreData)