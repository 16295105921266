import React from 'react'
import './ErrorMessages.scss'

class ErrorMessages extends React.Component {
  render() {
    const {
      children,
      errors,
    } = this.props
    
    const hasErrors = Object.keys(errors).length > 0

    return (
      <React.Fragment>
        {
          !hasErrors &&
          <React.Fragment>
            { children }
          </React.Fragment>
        }
        {
          hasErrors &&
          <React.Fragment>
            {children}
            <div className="errorMessages__list">
              {
                Object.keys(errors).map((key) => (
                  <span key={`option-error-${ key }`}>{ errors[key].join(', ') }</span>
                ))
              }
            </div>
          </React.Fragment>
        }
      </React.Fragment>
    )
  }
}

export default ErrorMessages