export default {
  isLogged: false,
  manageGroups: {
    loading: true,
    listBackup: [],
    list: []
  },
  messageArea: {
    status: '',
    text: '',
    visible: false
  },
  subscription: {
    subscription: null,
    totalProcessCreated: null,
  },
  assessment: {
    isLoading: true,
    isPia: false,
    error: null,
    questionsResults: {
      QUESTIONNAIRE_SKIPPED: [],
      SUBMITTED: [],
      USER_SKIPPED: []
    },
    questionsStateStats: {
      SUBMITTED_NO: 0,
      USER_SKIPPED_NO: 0,
      QUESTIONNAIRE_SKIPPED_NO: 0,
      UNANSWERED_NO: 0
    },
    process: {
      assignedUser: {
        isLoading: true
      },
      error: {},
      isLoading: true,
      title: '',
      status: ''
    }
  },
  questions: {
    activeQuestion: {
      isLoading: true,
      error: null
    },
    allQuestions: [],
    assessmentId: null,
    assessmentStatus: '',
    complete: false,
    isLoadingAssessmentStatus: true,
    isLoading: true,
    progress: 0,
    responseText: '',
    showResponse: false
  },
  shell: {
    loading: true
  },
  user: null,
  sidebarVisible: true,
  headerVisible: true,
  footerVisible: true,
}