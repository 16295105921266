export const industryItems = [
  'Agriculture, Forestry and Fishing',
  'Mining and Quarrying',
  'Manufacturing',
  'Electricity, Gas, Steam and Air Conditioning Supply',
  'Water Supply; Sewerage, Waste Management and Remediation Activities',
  'Construction',
  'Wholesale and Retail Trade; Repair of Motor Vehicles and Motorcycles',
  'Transportation ans Storage',
  'Accommodation and Food Service Activities',
  'Iinformation and Communication',
  'Financial and Insurance Activities',
  'Real Estate Activities',
  'Professional, Scientific and Technical Activities',
  'Administrative and Support Service Activities',
  'Public Administration and Defence; Compulsory Social Security',
  'Education',
  'Human Health and Social Work Activities',
  'Arts, Entertainment and Recreation',
  'Other Service Activities',
  'Activities of Households as Employers; Undifferentiated Goods- and Services-Producing Activities of Households for Own Use',
  'Activities of Extraterritorial Organisations and Bodies'
]

export const riskIndicatorsStaticList = [
  { selected: false, value: 'SEN', label: 'Sensitivity' },
  { selected: false, value: 'C', label: 'Compliance' },
  { selected: false, value: 'T', label: 'Transparency' },
  { selected: false, value: 'SEC', label: 'Security' },
  { selected: false, value: 'TB', label: 'Trans-border data flow' },
  { selected: false, value: 'DC', label: 'Data control' },
  { selected: false, value: 'DS', label: 'Data sharing' },
]

export const questionTypes = [
  { value: 'YN', label: 'Yes / No' },
  { value: 'Radio', label: 'Radio' },
  { value: 'Checkbox', label: 'Checkbox' }
]