import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import './DataCollectionWorkflow.scss'
import {Select, Steps, Checkbox, Input, DatePicker, Button, Modal, Upload, Collapse} from "antd";
import {
    apiSendInternalEmailNotificationWorkflow,
    getTicketWorkflowAndHeaderDetails,
    apiGetDataCollectorPageDataWorkflowTicket,
    apiSaveDataCollectorOptionsWorkflowTicket,
    apiGetSharePointSiteList,
    apiGetSharePointSiteFolderList,
    apiGetWorkflowTicketCollectorPageUploadHistoryData, apiCollectorPageGetCollectorPageUploadHistoryData
} from "../../../../../api/SmartBox";
import CustomNotificationManager from "../../../../Common/CustomNotificationManager";
import {customConfirm} from "../../../../../utils/AssetRegister/CustomConfirm";
const { Option } = Select;
const { Step } = Steps;
import moment from 'moment'
import axios from "axios";
import {ConcurrencyManager} from "axios-concurrency";
import _ from "lodash";
const isLocalhost = () => {
    return window.location.hostname === 'localhost'
}
const host = isLocalhost() ? 'http://localhost:8080' : ''
const { Panel } = Collapse
export class DataCollectionWorkflow extends Component {

    state = {
       formDataCollection: {
           contactDetailsOneDrive: [
               { key: 0, id: 'detailsOneDrive', checked: true, firstNameCheckbox: 'HR personnel system', lastNameCheckbox: ' - Microsoft 365 OneDrive', valueInputFolder: '' }
           ],
           contactDetailsTenantAccess: [
               { key: 0, id: 'emailTenantAccess', checked: true, firstNameCheckbox: 'Entire Email tenant access', lastNameCheckbox: ' - Microsoft 365 Exchange', selectEmailAccount: '', dateTime: [] }
           ],
           bankDetails: [
               { key: 0, id: 'bankDetails', checked: true, firstNameCheckbox: 'HR personnel system', lastNameCheckbox: ' - Microsoft 365 OneDrive', valueInputFolder: '' }
           ],
           searchQueries: [
               { key: 0, id: 'searchQueries', checked: true, nameCheckbox: 'Subject name', valueInputFolder: '' },
               { key: 1, id: 'searchQueries', checked: true, nameCheckbox: 'Nation Insurance Number', valueInputFolder: '' },
               { key: 2, id: 'searchQueries', checked: true, nameCheckbox: 'Employment Number', valueInputFolder: '' },
           ],
       },
        onHoverAddFolderName: {},
        onHoverRemoveFolderName: {},
        hoverIconMinusMsOneDrive : {},
        onHoverAddDetailEmail : {},
        hoverIconPlusMsExchange: {},
        hoverIconPlusMsOneDrive: {},
        onHoverRemoveDetailEmail: {},
        hoverIconMinusMsExchange: {},
        onHoverAddBankDetail: false,
        onRemoveBankDetail: {},
        onHoverAddSearchQueries: false,
        onHoverRemoveSearchQueries: {},
        businessProcessName: "",
        dataSources: [],
        businessProcessQueries: [],
        businessProcessId: null,
        disabledActionCollect: false,
        disabledActionCollectDate: false,
        disabledBusinessProcessQueries: false,
        dataUploadHistory: [],
        visibleDataUploadFrom: false,
        fileList: [],
        nameFileUpload: '',
        valueInputDesktop: '',
        formOtherDataSourcesSelect: {},
        dataSourcesSelect: {},
        loadingCollect: false
    }

    onChangeCheckboxDetailsHr = (e, form1, sourceMappingName) => {
        const { formDataCollection } = this.state
        e.stopPropagation()
        const { dataSources } = this.state
        if(e.target.checked) {
            dataSources && dataSources.length > 0 && dataSources.forEach((item) => {
                item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2) => {
                    if (form1.connectorId === item2.connectorId && sourceMappingName === item.sourceMappingName) {
                        item2.selected = e.target.checked
                    }
                })
            })
        } else {
            dataSources && dataSources.length > 0 && dataSources.forEach((item) => {
                item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2) => {
                    if (form1.connectorId === item2.connectorId && sourceMappingName === item.sourceMappingName) {
                        item2.selected = e.target.checked
                    }
                })
            })
        }
        this.setState({
            dataSources
        }, () => {
            this.onValidateDataCollect(dataSources)
        })
        // let { contactDetailsOneDrive } = this.state.formDataCollection
        // if(e.target.checked) {
        //     contactDetailsOneDrive && contactDetailsOneDrive.length > 0 && contactDetailsOneDrive.forEach((item) => {
        //         if (form1.key === item.key) {
        //             item.checked = true
        //         }
        //     })
        // } else {
        //     contactDetailsOneDrive && contactDetailsOneDrive.length > 0 && contactDetailsOneDrive.forEach((item) => {
        //         if (form1.key === item.key) {
        //             item.checked = false
        //         }
        //     })
        // }
        // this.setState({
        //     formDataCollection: {...this.state.formDataCollection, contactDetailsOneDrive: contactDetailsOneDrive}
        // })
    }

    onChangeCheckboxTenantAccess = (e, form1) => {
        const { formDataCollection } = this.state
        e.stopPropagation()
        let { contactDetailsTenantAccess } = this.state.formDataCollection
        if(e.target.checked) {
            contactDetailsTenantAccess && contactDetailsTenantAccess.length > 0 && contactDetailsTenantAccess.forEach((item) => {
                if (form1.key === item.key) {
                    item.selected = true
                }
            })
        } else {
            contactDetailsTenantAccess && contactDetailsTenantAccess.length > 0 && contactDetailsTenantAccess.forEach((item) => {
                if (form1.key === item.key) {
                    item.selected = false
                }
            })
        }
        this.setState({
            formDataCollection: {...this.state.formDataCollection, contactDetailsTenantAccess: contactDetailsTenantAccess}
        })
    }

    onChangeCheckboxBankDetails = (e, form1) => {
        const { formDataCollection } = this.state
        e.stopPropagation()
        let { bankDetails } = this.state.formDataCollection
        if(e.target.checked) {
            bankDetails && bankDetails.length > 0 && bankDetails.forEach((item) => {
                if (form1.key === item.key) {
                    item.selected = true
                }
            })
        } else {
            bankDetails && bankDetails.length > 0 && bankDetails.forEach((item) => {
                if (form1.key === item.key) {
                    item.selected = false
                }
            })
        }
        this.setState({
            formDataCollection: {...this.state.formDataCollection, bankDetails: bankDetails}
        })
    }
    onChangeCheckboxSearchQueries = (e, form1) => {
        const { businessProcessQueries } = this.state
        e.stopPropagation()
        if(e.target.checked) {
            businessProcessQueries && businessProcessQueries.length > 0 && businessProcessQueries.forEach((item) => {
                    if (form1.id === item.id) {
                        item.selected = e.target.checked
                    }
            })
        } else {
            businessProcessQueries && businessProcessQueries.length > 0 && businessProcessQueries.forEach((item) => {
                    if (form1.id === item.id) {
                        item.selected = e.target.checked
                    }
            })
        }
        this.setState({
            businessProcessQueries
        }, () => {
            this.onValidateBusinessProcessQueries()
        })
        // let { searchQueries } = this.state.formDataCollection
        // if(e.target.checked) {
        //     searchQueries && searchQueries.length > 0 && searchQueries.forEach((item) => {
        //         if (form1.key === item.key) {
        //             item.checked = true
        //         }
        //     })
        // } else {
        //     searchQueries && searchQueries.length > 0 && searchQueries.forEach((item) => {
        //         if (form1.key === item.key) {
        //             item.checked = false
        //         }
        //     })
        // }
        // this.setState({
        //     formDataCollection: {...this.state.formDataCollection, searchQueries: searchQueries}
        // })
    }
    onInputContactDetails = (e, key, form1) => {
        const { dataSources } = this.state
        const settingsOnedrive = this.state.dataSources
        const { connectorDataSources } = settingsOnedrive
        dataSources && dataSources.length > 0 && dataSources.forEach((item) => {
            item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2) => {
                if ((form1.index && form1.index === item2.index && item2.connectorType === 'Microsoft 365 Onedrive')) {
                    item2.valueInputFolder = e.target.value
                    item2.folderNames = e.target.value
                }
            })
        })
        this.setState({
            dataSources
        }, () => {
            this.onValidateDataCollect(dataSources)
        })

        // const settings = this.state.formDataCollection
        // const { contactDetailsOneDrive } = settings
        // contactDetailsOneDrive && contactDetailsOneDrive.length > 0 && contactDetailsOneDrive.forEach((item2) => {
        //     if (key === item2.key) {
        //         contactDetailsOneDrive[key].valueInputFolder = e.target.value
        //     }
        // })
        // this.setState({
        //     formDataCollection: {
        //         ...settings,
        //         contactDetailsOneDrive
        //     },
        // })
    }
    onInputBankDetails = (e, key, form1) => {
        const settings = this.state.formDataCollection
        const { bankDetails } = settings
        bankDetails && bankDetails.length > 0 && bankDetails.forEach((item2) => {
            if (key === item2.key) {
                bankDetails[key].valueInputFolder = e.target.value
            }
        })
        this.setState({
            formDataCollection: {
                ...settings,
                bankDetails
            },
        })
    }
    onInputSearchQueries = (e, key, form1) => {
        const { businessProcessQueries } = this.state
        businessProcessQueries && businessProcessQueries.length > 0 && businessProcessQueries.forEach((item) => {
            if ((form1.id !== null && form1.id === item.id) || (form1.key !== null && form1.key === item.key)) {
                item.value = e.target.value
                item.valueInputQueries = e.target.value
            }
        })

        this.setState({
            businessProcessQueries
        }, () => {
            this.onValidateBusinessProcessQueries()
        })
        // const settings = this.state.formDataCollection
        // const { searchQueries } = settings
        // searchQueries && searchQueries.length > 0 && searchQueries.forEach((item2) => {
        //     if (key === item2.key) {
        //         searchQueries[key].valueInputFolder = e.target.value
        //     }
        // })
        // this.setState({
        //     formDataCollection: {
        //         ...settings,
        //         searchQueries
        //     },
        // })
    }
    onCLickAddRemoveBankDetails = (e, status, index, form1) => {
        const { formDataCollection } = this.state
        let { bankDetails } = formDataCollection
        if (status === 'add') {
            const listBankDetails = []
            listBankDetails.push({ key: bankDetails.length, id: 'bankDetails', selected: true, firstNameCheckbox: 'HR personnel system', lastNameCheckbox: ' - Microsoft 365 OneDrive', valueInputFolder: ''})
            this.setState({
                formDataCollection: {...this.state.formDataCollection,
                    bankDetails: bankDetails.concat(listBankDetails) }
            })
        } else {
            bankDetails = bankDetails.filter((sPermission, key) => sPermission.key !== form1.key)
            bankDetails && bankDetails.length > 0 && bankDetails.forEach((item, index) => {
                item.key = index
            })
            this.setState({
                formDataCollection: {...this.state.formDataCollection,
                    bankDetails: bankDetails }
            })
        }
    }

    onCLickAddRemoveSearchQueries = (e, status, index, form1) => {
        let { businessProcessQueries } = this.state
        if (status === 'add') {
            businessProcessQueries.push({
                key: businessProcessQueries.length,
                id: null,
                selected: true,
                query: 'Subject',
                nameCheckbox: 'Subject',
                value: '',
                valueInputFolder: ''})
            this.setState({
                businessProcessQueries
            })
        } else {
            businessProcessQueries = businessProcessQueries.filter((sPermission, key) => sPermission.key !== form1.key)
            businessProcessQueries && businessProcessQueries.length > 0 && businessProcessQueries.forEach((item, index) => {
                item.key = index
            })
            this.setState({
                businessProcessQueries
            })
        }
    }

    onCLickAddRemoveContactDetailsHr = (e, status, index, form1, data) => {
        const { dataSources } = this.state
        if (status === 'add') {
            dataSources && dataSources.length > 0 && dataSources.forEach((item, index) => {
                if (item.sourceMappingId === data.sourceMappingId) {
                    let indexInput = 0
                    const itemSource = {
                        connectorId: form1.connectorId,
                        connectorName: form1.connectorName,
                        connectorType: form1.connectorType,
                        folderNames: null,
                        index: item.connectorDataSources.length + 1,
                        key: item.connectorDataSources.length + 1

                    }
                    item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2, index2) => {
                       if (form1.connectorName === item2.connectorName && item2.connectorType === 'Microsoft 365 Onedrive') {
                           indexInput = index2 + 1
                       }
                    })
                    item.connectorDataSources.splice(indexInput, 0, itemSource)
                    item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2, index2) => {
                        item2.index = index2 + 1
                        item2.key = index2 + 1
                    })
                    // item.connectorDataSources.push({
                    //     connectorId: null,
                    //     connectorName: form1.connectorName,
                    //     connectorType: form1.connectorType,
                    //     folderNames: null,
                    //     index: item.connectorDataSources.length,
                    //     key: item.connectorDataSources.length
                    //
                    // })
                }
            })

            this.setState({
                dataSources
            })
        } else {
            dataSources && dataSources.length > 0 && dataSources.forEach((item) => {
                if (item.sourceMappingId === data.sourceMappingId) {
                    item.connectorDataSources = item.connectorDataSources.filter((sPermission, key) => sPermission.index !== form1.index)
                }
                item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2, index2) => {
                    item2.key = index2 + 1
                    item2.index = index2 + 1
                })
            })
            this.setState({
                dataSources
            })
        }
    }
    onCLickAddRemoveContactDetailsTenant = (e, status, index, form1, data) => {
        const { dataSources } = this.state
        if (status === 'add') {
            dataSources && dataSources.length > 0 && dataSources.forEach((item, index) => {
                if (item.sourceMappingId === data.sourceMappingId) {
                    let indexLocation = 0
                    const itemLocation = {
                        connectorId: form1.connectorId,
                        key: item.connectorDataSources.length + 1,
                        index: item.connectorDataSources.length + 1,
                        id: 'emailTenantAccess',
                        connectorName: form1.connectorName,
                        connectorType: form1.connectorType,
                        selected: false,
                        firstNameCheckbox: 'Entire Email tenant access',
                        lastNameCheckbox: '- Microsoft 365 Exchange',
                        //connectorType: 'Microsoft 365 Exchange',
                        selectEmailAccount: '',
                        dateTime: [],
                        folderNames: null

                    }
                    item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2, index2) => {
                        if (form1.connectorName === item2.connectorName && item2.connectorType === 'Microsoft 365 Exchange') {
                            indexLocation = index2 + 1
                        }
                    })
                    item.connectorDataSources.splice(indexLocation, 0, itemLocation)

                    item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2, index2) => {
                        item2.index = index2 + 1
                        item2.key = index2 + 1
                    })

                    // item.connectorDataSources.push({
                    //     connectorId: null,
                    //     key: item.connectorDataSources.length,
                    //     id: 'emailTenantAccess',
                    //     connectorName: form1.connectorName,
                    //     selected: false,
                    //     firstNameCheckbox: 'Entire Email tenant access',
                    //     lastNameCheckbox: '- Microsoft 365 Exchange',
                    //     connectorType: 'Microsoft 365 Exchange',
                    //     selectEmailAccount: '',
                    //     dateTime: [],
                    //     folderNames: null
                    //
                    // })
                }
            })
            this.setState({
                dataSources
            })
        } else {
            dataSources && dataSources.length > 0 && dataSources.forEach((item) => {
                if (item.sourceMappingId === data.sourceMappingId) {
                    item.connectorDataSources = item.connectorDataSources.filter((sPermission, key) => sPermission.index !== form1.index)
                }
                item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2, index2) => {
                    item2.key = index2 + 1
                    item2.index = index2 + 1
                })
            })
            this.setState({
                dataSources
            })
        }
    }
    selectDate = (date, dateString, index, form1) => {
        const { dataSources } = this.state
        dataSources && dataSources.length > 0 && dataSources.forEach((item) => {
            item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2) => {
                if ((form1.index && form1.index === item2.index && item2.connectorType === 'Microsoft 365 Exchange')) {
                    item2.dateTime = dateString
                    item2.dateTime2 = date
                    item2.startDates = date[0]
                    item2.endDates = date[1]
                }
            })
        })
        this.setState({
            dataSources
        }, () => {
            this.onValidateDataCollect(dataSources)
        })
        // const { formDataCollection } = this.state
        // let { contactDetailsTenantAccess } = formDataCollection
        // contactDetailsTenantAccess && contactDetailsTenantAccess.length > 0 && contactDetailsTenantAccess.forEach((item2) => {
        //     if (form1.key === item2.key) {
        //         contactDetailsTenantAccess[index].dateTime = dateString
        //     }
        // })
        // this.setState({
        //     dateSelect: dateString,
        //     dateTime: date,
        //     formDataCollection: {...this.state.formDataCollection,
        //         contactDetailsTenantAccess: contactDetailsTenantAccess }
        // })
    }
    onChangeSelectContactDetails = (e, key, form1) => {
        const { dataSources } = this.state
        dataSources && dataSources.length > 0 && dataSources.forEach((item) => {
            item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2) => {
                if (form1.connectorId === item2.connectorId && item2.connectorType === 'Microsoft 365 Exchange') {
                    item2.selectEmailAccount = e
                }
            })
        })
        this.setState({
            dataSources
        })
        // const { formDataCollection } = this.state
        // let { contactDetailsTenantAccess } = formDataCollection
        // contactDetailsTenantAccess && contactDetailsTenantAccess.length > 0 && contactDetailsTenantAccess.forEach((item2) => {
        //     if (form1.key === item2.key) {
        //         contactDetailsTenantAccess[key].selectEmailAccount = e
        //     }
        // })
        // this.setState({
        //     formDataCollection: {...this.state.formDataCollection,
        //         contactDetailsTenantAccess: contactDetailsTenantAccess }
        // })
    }

    onChangeSelectSite = (e, key, form1) => {
        const { dataSources } = this.state
        const getSharePointSiteFolders = {
            connectorId: form1.connectorId,
            siteId: e
        }
        apiGetSharePointSiteFolderList(getSharePointSiteFolders).then((response) => {
            dataSources && dataSources.length > 0 && dataSources.forEach((item) => {
                item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2, index) => {
                    if (key === index && item2.connectorType === 'Microsoft 365 SharePoint') {
                        item2.siteIdSharePoint = e
                        item2.dataSelectSiteListSharePoint && item2.dataSelectSiteListSharePoint.length > 0 && item2.dataSelectSiteListSharePoint.forEach((elem) => {
                            if (elem.siteId === e) {
                                item2.siteNameSharePoint = elem.siteName
                                item2.folderNameSiteSharePoint = null
                                item2.dataSelectSiteFolderListSharePoint = response.data && response.data.sharePointSiteListItemDTOS ? response.data.sharePointSiteListItemDTOS : []
                            }
                        })
                    }
                })
            })
            this.setState({
                dataSources
            }, () => {
                //this.onValidateDataCollect(dataSources)
            })
        })
    }
    onChangeSelectFolder = (e, key, form1) => {
        const { dataSources } = this.state
        dataSources && dataSources.length > 0 && dataSources.forEach((item) => {
            item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2, index) => {
                if (key === index && item2.connectorType === 'Microsoft 365 SharePoint') {
                    item2.folderNameSiteSharePoint = e
                }
            })
        })
        this.setState({
            dataSources
        }, () => {
            this.onValidateDataCollect(dataSources)
        })
    }

    onCLickAddRemoveSharePoints = (e, status, index, form1, data) => {
        const { dataSources } = this.state
        if (status === 'add') {
            dataSources && dataSources.length > 0 && dataSources.forEach((item, index) => {
                if (item.sourceMappingId === data.sourceMappingId) {
                    let indexInput = 0
                    const itemSource = {
                        connectorId: form1.connectorId,
                        connectorName: form1.connectorName,
                        connectorType: form1.connectorType,
                        folderNames: null,
                        siteIdSharePoint: null,
                        siteNameSharePoint: null,
                        folderNameSiteSharePoint: null,
                        index: item.connectorDataSources.length + 1,
                        key: item.connectorDataSources.length + 1,
                        dataSelectSiteListSharePoint: form1.dataSelectSiteListSharePoint,
                        dataSelectSiteFolderListSharePoint: form1.dataSelectSiteFolderListSharePoint,
                        selected: form1.selected,

                    }
                    item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2, index2) => {
                        if (form1.connectorName === item2.connectorName && item2.connectorType === 'Microsoft 365 SharePoint') {
                            indexInput = index2 + 1
                        }
                    })
                    item.connectorDataSources.splice(indexInput, 0, itemSource)
                    item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2, index2) => {
                        item2.index = index2 + 1
                        item2.key = index2 + 1
                    })
                }
            })

            this.setState({
                dataSources
            }, () => {
                this.onValidateDataCollect(dataSources)
            })
        } else {
            dataSources && dataSources.length > 0 && dataSources.forEach((item) => {
                if (item.sourceMappingId === data.sourceMappingId) {
                    item.connectorDataSources = item.connectorDataSources.filter((sPermission, key) => sPermission.index !== form1.index)
                }
                item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2, index2) => {
                    item2.key = index2 + 1
                    item2.index = index2 + 1
                })
            })
            this.setState({
                dataSources
            }, () => {
                this.onValidateDataCollect(dataSources)
            })
        }
    }

    onHoverAction = (field, val, sourceMappingIdVal) => {
        switch (field) {
            case 'add-folder-name':
                this.setState({
                    onHoverAddFolderName: val,
                    hoverIconPlusMsOneDrive: sourceMappingIdVal,
                })
                break;
            case 'remove-folder-name':
                this.setState({
                    onHoverRemoveFolderName: val,
                    hoverIconMinusMsOneDrive: sourceMappingIdVal,
                })
                break;
            case 'add-detail-email':
                this.setState({
                    onHoverAddDetailEmail: val,
                    hoverIconPlusMsExchange: sourceMappingIdVal,
                })
                break;
            case 'remove-detail-email':
                this.setState({
                    onHoverRemoveDetailEmail: val,
                    hoverIconMinusMsExchange: sourceMappingIdVal,
                })
                break;
            case 'add-bank-detail':
                this.setState({
                    onHoverAddBankDetail: true,
                })
                break;
            case 'remove-bank-detail':
                this.setState({
                    onRemoveBankDetail: val,
                })
                break;
            case 'add-search-queries':
                this.setState({
                    onHoverAddSearchQueries: true,
                })
                break;
            case 'remove-search-queries':
                this.setState({
                    onHoverRemoveSearchQueries: val,
                })

        }
    }
    onLeaveAction = () => {
        this.setState({
            onHoverAddFolderName: {},
            hoverIconPlusMsOneDrive:{},
            onHoverRemoveFolderName: {},
            hoverIconMinusMsOneDrive: {},
            onHoverAddDetailEmail: {},
            hoverIconPlusMsExchange: {},
            onHoverRemoveDetailEmail: {},
            hoverIconMinusMsExchange: {},
            onHoverAddBankDetail: false,
            onRemoveBankDetail: {},
            onHoverAddSearchQueries: false,
            onHoverRemoveSearchQueries: {},
        })
    }


    callGetDataCollectorDataAPIWorkflowTicket = () => {
        const {stepHeaderActive, selectHeaderTickets} = this.props
        if (stepHeaderActive && stepHeaderActive.stepComponentName === 'Collector') {

            apiGetDataCollectorPageDataWorkflowTicket(Number(stepHeaderActive.stepComponentWorkflowId), selectHeaderTickets.ticketId).then(response => {
                if(response !== null && response.data !== null){
                    //const connectorDataSourcesUpdate = []
                    const duplicates = []
                    const uniqueElements = new Set()
                    let indexing = 0
                    response.data.dataSources && response.data.dataSources.length > 0 && response.data.dataSources.forEach((item) => {
                        const connectorDataSourcesUpdate = []
                        item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2, index2) => {
                            //if (item2.connectorType === 'Microsoft 365 Onedrive') {
                               //indexing = indexing + index2
                                // if (item2.connectorType === 'Microsoft 365 SharePoint') {
                                //     const getSharePointSitesDTO = {
                                //         connectorId: item2.connectorId
                                //     }
                                //     apiGetSharePointSiteList(getSharePointSitesDTO).then((response2) => {
                                //         item2.dataSelectSiteListSharePoint = response2.data && response2.data.sharePointSiteDTOList ? response2.data.sharePointSiteDTOList : []
                                //         // connectorDataSourcesUpdate.push({
                                //         //     connectorId: item2.connectorId,
                                //         //     connectorName: item2.connectorName,
                                //         //     connectorType: item2.connectorType,
                                //         //     selected: item2.selected,
                                //         //     folderNames: item2.folderNames,
                                //         //     startDates: item2.startDates,
                                //         //     endDates: item2.endDates,
                                //         //     dateTime: [],
                                //         //     visibleSelected: true,
                                //         //     dataSelectSiteListSharePoint : response2.data && response2.data.sharePointSiteDTOList ? response2.data.sharePointSiteDTOList : []
                                //         // })
                                //     })
                                // }
                               if (item2.folderNames && item2.folderNames.length > 0) {
                                    item2.folderNames.forEach((item3, index3) => {
                                        //item.connectorDataSources.splice(item.connectorDataSources.length + 1, 0, itemOnedrive)
                                        connectorDataSourcesUpdate.push({
                                            connectorId: item2.connectorId,
                                            connectorName: item2.connectorName,
                                            connectorType: item2.connectorType,
                                            selected: item2.selected,
                                            folderNames: item3,
                                            visibleSelected: index3 === 0 ? true : false,
                                            // index: indexing,
                                            // key: indexing
                                        })
                                    })
                                } else if (item2.startDates && item2.startDates.length > 0 && item2.endDates && item2.endDates.length > 0) {
                                    item2.startDates.forEach((dateStart, indexStart) => {
                                        //const dateStart1 = dateStart.split(" ", 1)
                                        const dateStart1 = dateStart.split(" ")[0]
                                        const dateEnd1 = item2.endDates[indexStart].split(" ")[0]
                                        const [day, month, year] = dateStart1.split('-')
                                        const [day1, month1, year1] = dateEnd1.split('-')
                                        const formatDateStart = `${year}-${month}-${day}`
                                        const formatDateEnd = `${year1}-${month1}-${day1}`

                                        connectorDataSourcesUpdate.push({
                                            connectorId: item2.connectorId,
                                            connectorName: item2.connectorName,
                                            connectorType: item2.connectorType,
                                            selected: item2.selected,
                                            folderNames: item2.folderNames,
                                            //startDates: dateStart,
                                            startDates: formatDateStart,
                                            //endDates: item2.endDates[indexStart],
                                            endDates: formatDateEnd,
                                            //dateTime: [moment(dateStart1.toString()).format("YYYY-MM-DD"), moment(dateEnd1.toString()).format("YYYY-MM-DD")],
                                            //dateTime: ['2024-03-02', '2024-07-09'],
                                            dateTime: [formatDateStart, formatDateEnd],
                                            visibleSelected: indexStart === 0 ? true : false,
                                            // index: indexing,
                                            // key: indexing
                                        })
                                        item2.endDates.forEach((dateEnd, indexEnd) => {
                                            // const dateStart1 = dateStart.split(" ", 1)
                                            // const dateEnd1 = dateEnd.split(" ", 1)
                                            // connectorDataSourcesUpdate.push({
                                            //     connectorId: item2.connectorId,
                                            //     connectorName: item2.connectorName,
                                            //     connectorType: item2.connectorType,
                                            //     selected: item2.selected,
                                            //     folderNames: item2.folderNames,
                                            //     startDates: dateStart,
                                            //     endDates: dateEnd,
                                            //     //dateTime: [dateStart1[0], dateEnd1[0]],
                                            //     dateTime: ['2024-03-02', '2024-07-09'],
                                            //     visibleSelected: true
                                            // })
                                        })
                                    })
                                } else if (item2.siteAndFolderDataCollectorDTOList && item2.siteAndFolderDataCollectorDTOList.length > 0) {
                                   item2.siteAndFolderDataCollectorDTOList.forEach((shareSite2, indexShare2) => {
                                       connectorDataSourcesUpdate.push({
                                           connectorId: item2.connectorId,
                                           connectorName: item2.connectorName,
                                           connectorType: item2.connectorType,
                                           selected: item2.selected,
                                           folderNames: item2.folderNames,
                                           startDates: item2.startDates,
                                           endDates: item2.endDates,
                                           dateTime: [],
                                           visibleSelected: indexShare2 === 0 ? true : false,
                                           siteIdSharePoint: shareSite2.siteId,
                                           siteNameSharePoint: shareSite2.siteName,
                                           folderNameSiteSharePoint: shareSite2.folderName,
                                       })
                                   })
                               } else {
                                    connectorDataSourcesUpdate.push({
                                        connectorId: item2.connectorId,
                                        connectorName: item2.connectorName,
                                        connectorType: item2.connectorType,
                                        selected: item2.selected,
                                        folderNames: item2.folderNames,
                                        startDates: item2.startDates,
                                        endDates: item2.endDates,
                                        dateTime: [],
                                        visibleSelected: true,
                                        // index: indexing,
                                        // key: indexing
                                    })
                                }
                            //}
                        })
                        item.connectorDataSources = connectorDataSourcesUpdate
                        item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2, index2) => {
                            indexing = indexing + 1
                            // item2.selected = false
                            item2.selected = item2.selected
                            item2.index = indexing
                            item2.key = indexing
                            // if (item2.connectorType === 'Microsoft 365 SharePoint') {
                            //     const getSharePointSitesDTO = {
                            //         connectorId: item2.connectorId
                            //     }
                            //     apiGetSharePointSiteList(getSharePointSitesDTO).then((response2) => {
                            //         item2.dataSelectSiteListSharePoint = response2.data && response2.data.sharePointSiteDTOList ? response2.data.sharePointSiteDTOList : []
                            //     })
                            // }

                        })
                    })
                    response.data.businessProcessQueries && response.data.businessProcessQueries.length > 0 && response.data.businessProcessQueries.forEach((item, index) => {
                        // item.selected = false
                        item.selected = item.selected
                        item.key = index
                    })
                     this.setState({
                         dataSources : response.data.dataSources,
                         businessProcessQueries : response.data.businessProcessQueries ? response.data.businessProcessQueries : [],
                         businessProcessId : response.data.businessProcessId,
                     }, () => {
                         this.onValidateDataCollect(response.data.dataSources)
                         this.onGetSharePointSiteList(response.data.dataSources)
                         this.onValidateBusinessProcessQueries()
                     })
                    if(response.data.businessProcessName !== null){
                        this.setState({
                            businessProcessName : response.data.businessProcessName,
                        })
                    }

                }

            })
        }
    }
    onGetSharePointSiteList = (dataSources) => {
        dataSources && dataSources.length > 0 && dataSources.forEach((item) => {
            if (item.connectorDataSources && item.connectorDataSources.length > 0) {
                item.connectorDataSources.forEach((item2) => {
                    if (item2.connectorType === 'Microsoft 365 SharePoint') {
                        const getSharePointSitesDTO = {
                            connectorId: item2.connectorId
                        }
                        apiGetSharePointSiteList(getSharePointSitesDTO).then((response2) => {
                            item2.dataSelectSiteListSharePoint = response2.data && response2.data.sharePointSiteDTOList ? response2.data.sharePointSiteDTOList : []
                            this.setState({
                                dataSources
                            })
                        })
                    }
                })
            }
        })
    }
    onValidateDataCollect = (dataSources) => {
        const { disabledActionCollectDate, disabledBusinessProcessQueries } = this.state
        const folderNamesList = []
        const dateTimeList = []
        const folderNameSiteSharePointList = []
        const siteNameSharePointList = []
        dataSources && dataSources.length > 0 && dataSources.forEach((item) => {
            if (item.connectorDataSources && item.connectorDataSources.length > 0) {
                item.connectorDataSources.forEach((item2) => {
                    if (item2.connectorType === 'Microsoft 365 Onedrive' && item2.selected) {
                        folderNamesList.push(item2.folderNames)
                    }
                    if (item2.connectorType === 'Microsoft 365 Exchange' && item2.selected) {
                        dateTimeList.push(item2.dateTime)
                    }
                    if (item2.connectorType === 'Microsoft 365 SharePoint' && item2.selected) {
                        siteNameSharePointList.push(item2.siteNameSharePoint)
                        folderNameSiteSharePointList.push(item2.folderNameSiteSharePoint)
                    }
                })
            }
        })
        if (folderNamesList && folderNamesList.length > 0 && (folderNamesList.includes(null) || folderNamesList.includes(''))) {
            this.setState({
                disabledActionCollect: true
            })
        } else if (folderNamesList && folderNamesList.length > 0 && (!folderNamesList.includes(null) || !folderNamesList.includes(''))) {
            this.setState({
                disabledActionCollect: false
            })
        }
        if (dateTimeList && dateTimeList.length > 0 && dateTimeList.find((subArray) => Array.isArray(subArray) && subArray.length === 0)) {
            this.setState({
                disabledActionCollectDate: true
            })
        } else if (dateTimeList && dateTimeList.length > 0 && dateTimeList.find((subArray) => Array.isArray(subArray) && subArray.length !== 0)) {
            this.setState({
                disabledActionCollectDate: false
            })
        } else if (siteNameSharePointList && siteNameSharePointList.length > 0 && folderNameSiteSharePointList && folderNameSiteSharePointList.length > 0
            && (siteNameSharePointList.includes(null) || siteNameSharePointList.includes(undefined) || siteNameSharePointList.includes('')) && (folderNameSiteSharePointList.includes(null) || folderNameSiteSharePointList.includes(undefined) || folderNameSiteSharePointList.includes(''))) {
            this.setState({
                disabledActionCollect: true
            })
        } else if (siteNameSharePointList && siteNameSharePointList.length > 0 && folderNameSiteSharePointList && folderNameSiteSharePointList.length > 0
            && (!siteNameSharePointList.includes(null) || !siteNameSharePointList.includes(undefined) || !siteNameSharePointList.includes('')) && (!folderNameSiteSharePointList.includes(null) || !folderNameSiteSharePointList.includes(undefined) || !folderNameSiteSharePointList.includes(''))) {
            this.setState({
                disabledActionCollect: false
            })
        }
    }

    onValidateBusinessProcessQueries = (dataSources) => {
        const { businessProcessQueries, disabledBusinessProcessQueries } = this.state
        const businessProcessQueriesList = []
        businessProcessQueries && businessProcessQueries.length > 0 && businessProcessQueries.forEach((item) => {
              if (item.selected) {
                  businessProcessQueriesList.push(item.value)
              }
        })
        if (businessProcessQueriesList && businessProcessQueriesList.length > 0 && (businessProcessQueriesList.includes(null) || businessProcessQueriesList.includes(''))) {
            this.setState({
                disabledBusinessProcessQueries: true
            })
        } else if (businessProcessQueriesList && businessProcessQueriesList.length > 0 && (!businessProcessQueriesList.includes(null) || !businessProcessQueriesList.includes(''))) {
            this.setState({
                disabledBusinessProcessQueries: false
            })
        } else if (businessProcessQueriesList.length === 0) {
            this.setState({
                disabledBusinessProcessQueries: false
            })
        }
    }
    componentDidMount() {
        this.axiosClone = axios.create({ headers: this.props.smartBoxAxiosHeaders })
        this.manager = ConcurrencyManager(this.axiosClone, this.MAX_CONCURRENT_REQUESTS)
            this.callGetDataCollectorDataAPIWorkflowTicket()
        this.onGetWorkflowTicketCollectorPageUploadHistoryData()
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEmpty(this.props.smartBoxAxiosHeaders)) {
            this.axiosClone = axios.create({ headers: this.props.smartBoxAxiosHeaders })
            this.manager = ConcurrencyManager(this.axiosClone, this.MAX_CONCURRENT_REQUESTS)
        }
    }
    onGetWorkflowTicketCollectorPageUploadHistoryData = () => {
        const { selectHeaderTickets } = this.props
        apiGetWorkflowTicketCollectorPageUploadHistoryData(selectHeaderTickets.ticketId).then((response) => {
            if (response.data.title && response.data.title.toLowerCase() === 'success') {
                CustomNotificationManager.success(response.data.message, 'Success')
            } else if (response.data.title && response.data.title.toLowerCase() === 'info') {
                CustomNotificationManager.info(response.data.message, 'Info')
            }
            this.setState({
                dataUploadHistory: response.data && response.data.length > 0 ? response.data : []
            })
        })
    }
    onDataCollectWorkflow = () => {
        const { selectHeaderTickets, stepHeaderActive } = this.props
        const { businessProcessId, dataSources, businessProcessQueries } = this.state
        const saveDataCollectorDataSourceOptions = []
        const saveDataCollectorOptionsQueries = []
        dataSources && dataSources.length > 0 && dataSources.forEach((item) => {
            const connectorOptions = []
            const dataLocationOptionDTOS = []
            item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2) => {
                connectorOptions.push({
                    connectorId: item2.connectorId ? item2.connectorId : null,
                    selected: item2.selected,
                    folderName: item2.folderNames ? item2.folderNames : null,
                    startDate: item2.dateTime && item2.dateTime.length > 0 ? item2.dateTime[0] : null,
                    endDate: item2.dateTime && item2.dateTime.length > 0 ? item2.dateTime[1] : null,
                    siteIdSharePoint: item2.siteIdSharePoint ? item2.siteIdSharePoint : null,
                    siteNameSharePoint: item2.siteNameSharePoint ? item2.siteNameSharePoint : null,
                    folderNameSiteSharePoint: item2.folderNameSiteSharePoint ? item2.folderNameSiteSharePoint : null,
                })
            })
            item.connectorOptions = connectorOptions
            item.otherDataSources && item.otherDataSources.length > 0 && item.otherDataSources.forEach((item2) => {
                dataLocationOptionDTOS.push({
                    dataLocationId: item2.dataLocationId ? item2.dataLocationId : null,
                    dataLocationPath: null,
                })
            })
            item.dataLocationOptionDTOS = dataLocationOptionDTOS
        })
        dataSources && dataSources.length > 0 && dataSources.forEach((item) => {
            saveDataCollectorDataSourceOptions.push({
                sourceMappingId: item.sourceMappingId,
                connectorOptions: item.connectorOptions,
                dataLocationOptionDTOS: item.dataLocationOptionDTOS,
            })
        })

        businessProcessQueries && businessProcessQueries.length > 0 && businessProcessQueries.forEach((item) => {
            saveDataCollectorOptionsQueries.push({
                idQuery: item.id ? item.id : null,
                queryName: item.query,
                selected: item.selected,
                queryValue: item.value ? item.value : null,
            })
        })
        const saveDataCollectorOptionsWorkflowTicketDTO = {
            ticketId: selectHeaderTickets.ticketId,
            workflowComponentId: stepHeaderActive.stepComponentWorkflowId,
            businessProcessId: businessProcessId,
            saveDataCollectorDataSourceOptions: saveDataCollectorDataSourceOptions,
            saveDataCollectorOptionsQueries: saveDataCollectorOptionsQueries,
        }
        this.setState({
            loadingCollect: true
        }, () => {
            apiSaveDataCollectorOptionsWorkflowTicket(saveDataCollectorOptionsWorkflowTicketDTO).then((response) => {
                this.props.onGetTicketWorkflowAndHeaderDetails(selectHeaderTickets)
                if (response.data.title && response.data.title.toLowerCase() === 'success') {
                    CustomNotificationManager.success(response.data.message, 'Success')
                    this.onSendUploadFile()
                } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
                    CustomNotificationManager.info(response.data.message, 'Info')
                }
                this.onGetWorkflowTicketCollectorPageUploadHistoryData()
                this.setState({
                    loadingCollect: false
                })
            })
        })
    }
    onOpenModalDataUploadFrom = (e, form2, indexOther) => {
        form2.indexOther = indexOther
        this.setState({
            visibleDataUploadFrom: !this.state.visibleDataUploadFrom,
            formOtherDataSourcesSelect: form2
        })
    }
    onInputDesktop = (e) => {
        const { dataSources, formOtherDataSourcesSelect } = this.state
        dataSources && dataSources.length > 0 && dataSources.forEach((elem) => {
            elem.otherDataSources && elem.otherDataSources.length > 0 && elem.otherDataSources.map((form2, index) => {
                if (formOtherDataSourcesSelect.dataLocation === form2.dataLocation) {
                    form2.valueInputDesktop = e.target.value
                }
            })
        })
        this.setState({
            valueInputDesktop: e.target.value
        })
    }
    onImportModal = () => {

    }
    onCancelModalVisible = () => {
        this.setState({
            visibleDataUploadFrom: false
        })
    }
    customUploadDraft = (file, file2) => {
        const { dataSources, formOtherDataSourcesSelect, valueShareBusiness } = this.state
        const maxSizeFile = file.size / 1024 / 1024 < 5
        let nameFile = ''
        const listUid = []
        const listUid2 = []
        file2 && file2.length > 0 && file2.forEach((list) => {
            listUid.push(list.uid)
            nameFile = list.name
            listUid2.push({
                uid: list.uid,
                name: list.name,
                lastModified: list.lastModified,
                type: list.type,
                size: list.size,
            })
            dataSources && dataSources.length > 0 && dataSources.forEach((elem) => {
                elem.otherDataSources && elem.otherDataSources.length > 0 && elem.otherDataSources.map((form2, index) => {
                    if (formOtherDataSourcesSelect.dataLocation === form2.dataLocation || formOtherDataSourcesSelect.indexOther === index) {
                        form2.nameUpload = list.name
                    }
                })
            })
        })
        dataSources && dataSources.length > 0 && dataSources.forEach((elem) => {
            elem.otherDataSources && elem.otherDataSources.length > 0 && elem.otherDataSources.map((form2, index) => {
                if (formOtherDataSourcesSelect.dataLocation === form2.dataLocation || formOtherDataSourcesSelect.indexOther === index) {
                    form2.fileSelectUpload = file,
                        form2.dataSourceName = elem.sourceMappingName
                }
            })
        })
        this.setState({
            fileList: listUid2,
            fileSelectUpload: file,
            nameFileUpload: nameFile,
            dataSources
        }, () => {
            this.setState({
                visibleDataUploadFrom: false
            })
        })
        return false
    }
    onSendUploadFile = (uploadHistoryId) => {
        const { fileSelectUpload, folder, valueShareBusiness, dataSources } = this.state
        const { selectHeaderTickets } = this.props
       // selectHeaderTickets.ticketId
        dataSources && dataSources.length > 0 && dataSources.forEach((elem) => {
            elem.otherDataSources && elem.otherDataSources.length > 0 && elem.otherDataSources.map((form2, index) => {
                if (form2.fileSelectUpload) {
                    let result = '';
                    const characters = '0123456789';
                    const charactersLength = characters.length;
                    let counter = 0;
                    while (counter < 3) {
                        result += characters.charAt(Math.floor(Math.random() * charactersLength));
                        counter += 1;
                    }
                    let objData = {}
                    objData = {
                        uploadId: result,
                        ticketId: selectHeaderTickets.ticketId,
                        files: form2.fileSelectUpload,
                        fileInfoList: null,
                    }
                    const boundary = '----WebKitFormBoundary7MA4YWxkTrZu0gW';
                    const body = `--${boundary}\r\nContent-Disposition: form-data; name="key1"\r\n\r\nvalue1\r\n--${boundary}\r\nContent-Disposition: form-data; name="key2"\r\n\r\nvalue2\r\n--${boundary}--`;
                    return this.axiosClone({
                            method: 'post',
                            url: `${host}/osprey-0.1.0/v1/workflow-builder/upload-collector-workflow_ticket-page-data`,
                            data: objData,
                            body: body,
                            headers: {
                                'Content-Type': `multipart/form-data; boundary=${boundary}`
                            },
                            //onUploadProgress: config
                        }
                    ).catch((error) => {
                        if (error?.response?.status === 504) {
                            CustomNotificationManager.cleanBuffer('warning', '')
                        } else {
                            console.log(error)
                        }
                    })
                }

            })
        })
    }

    callback = (key) => {
        console.log(key);
    }

    onClickOkUpload(e) {
        this.onDataCollectWorkflow()
    }

    render() {
        const { formDataCollection, onHoverAddFolderName, onHoverRemoveFolderName, onHoverAddDetailEmail, onHoverRemoveDetailEmail,
            onHoverAddBankDetail, onRemoveBankDetail, onHoverAddSearchQueries, onHoverRemoveSearchQueries, dataSources, businessProcessQueries,
            hoverIconPlusMsExchange, hoverIconPlusMsOneDrive, hoverIconMinusMsExchange, hoverIconMinusMsOneDrive, disabledActionCollect, disabledActionCollectDate,
            disabledBusinessProcessQueries, visibleDataUploadFrom, formOtherDataSourcesSelect, fileList, dataUploadHistory, loadingCollect } = this.state
        const { RangePicker } = DatePicker;
        //console.log('dataSources --- RENDER', dataSources)
        //console.log('propssss --- RENDER', this.props)
        dataSources && dataSources.length > 0 && dataSources.map((item) => {
            item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.map((item2) => {
                if (item2.connectorType && item2.connectorType === 'Microsoft 365 SharePoint') {
                    //console.log('item2 44444444444444444444', item2)
                    //console.log('item.form1.dataSelectSiteListSharePoint', item2.dataSelectSiteListSharePoint)
                }
            })
        })
        return (
            <div className="data-collection-workflow-page">
                <div className="data-collection-workflow-page__header">
                    <span className="name-data-collection">Data collection</span>
                </div>
                <div className="data-collection-workflow-page__content">
                    <div className="content-company">
                        <span className="icon-v22-company"></span>
                      {/*  <Select className="select-options-company" dropdownClassName={'dropdown-select-options-company'}  placeholder="Human resource">
                            <Option value='Human resource'>{"Human resource"}</Option>
                        </Select>*/}
                        <span className="name-data-collection" style={{fontWeight: "700"}}>{this.state.businessProcessName}</span>
                    </div>
                    <div className="date-source">
                        <span className="icon-v22-db-storage"></span>
                        <div className="content-date-source">
                             <div className="date-source-header">
                                 <span className="name-data-source">Data source</span>
                             </div>
                            <div>
                                { dataSources && dataSources.length > 0 && dataSources.map((data, index2) => {
                                    return (
                                        <div className="contact-details" key={index2}>
                                            <span className="contact-details-name">{data.sourceMappingName}</span>
                                            { data.connectorDataSources && data.connectorDataSources.length > 0 && data.connectorDataSources.map((form1, index) => {

                                                return (
                                                    <div className="contact-details-contain" key={`key-${index}`}>
                                                        {form1.visibleSelected ?
                                                            <div className="checkbox-contain">
                                                                <Checkbox checked={form1.selected}
                                                                          onChange={(e) => this.onChangeCheckboxDetailsHr(e, form1, data.sourceMappingName)}>{
                                                                    <span className="value-checkbox"><span
                                                                        className="first-value-checkbox">{form1.connectorName}</span><span
                                                                        className="last-value-checkbox">{` - ${form1.connectorType}`}</span></span>}</Checkbox>
                                                            </div> :
                                                            <div className="checkbox-contain">
                                                            </div>
                                                        }
                                                        { form1.connectorType && form1.connectorType === 'Microsoft 365 Onedrive' &&
                                                        <div className="option-contact-details">
                                                            <Input value={ form1.folderNames } onChange={(e) => this.onInputContactDetails(e, index, form1)} className="input-folder-name" placeholder={'Folder name'}></Input>
                                                            {/*{ form1.visibleSelected &&*/}
                                                            {/*<span*/}
                                                            {/*    onClick={(e) => this.onCLickAddRemoveContactDetailsHr(e, 'add', index, form1, data)}*/}
                                                            {/*    className={onHoverAddFolderName === index && hoverIconPlusMsOneDrive === data.sourceMappingId  ? 'icon-v22-add-active' : "icon-v22-add"}*/}
                                                            {/*    onMouseEnter={() => this.onHoverAction('add-folder-name', index, data.sourceMappingId)}*/}
                                                            {/*    onMouseLeave={() => this.onLeaveAction()}*/}
                                                            {/*></span>*/}
                                                            {/*}*/}
                                                            { !form1.visibleSelected &&
                                                            <span
                                                                onClick={(e) => this.onCLickAddRemoveContactDetailsHr(e, 'remove', index, form1, data)}
                                                                className={ onHoverRemoveFolderName === index && hoverIconMinusMsOneDrive === data.sourceMappingId ? "icon-v22-minus-active" :"icon-v22-minus"}
                                                                onMouseEnter={() => this.onHoverAction('remove-folder-name', index, data.sourceMappingId)}
                                                                onMouseLeave={() => this.onLeaveAction()}></span>
                                                            }
                                                        </div>
                                                        }

                                                        { form1.connectorType && form1.connectorType === 'Microsoft 365 Exchange' &&
                                                        <div className="option-contact-details">
                                                            <RangePicker onChange={(date,dateString) => this.selectDate(date,dateString, index, form1)}
                                                                         className='date-picker'
                                                                         format="YYYY-MM-DD"
                                                                         //value={form1.dateTime ? form1.dateTime : []}
                                                                         //defaultValue={form1.dateTime && form1.dateTime.length > 0 ? form1.dateTime : []}
                                                                         value={form1.startDates && form1.endDates ? [moment(form1.startDates), moment(form1.endDates)] : []}
                                                                         //value={form1.dateTime ? form1.dateTime : []}
                                                                         //defaultValue={form1.dateTime ? form1.dateTime : []}
                                                                         dropdownClassName="date-picker-up"/>
                                                            <Select onChange={(e) => this.onChangeSelectContactDetails(e, index, form1)}
                                                                    placeholder="select-details-email"
                                                                    value={form1.selectEmailAccount}
                                                                    dropdownClassName={'dropdown-select-details-email'}
                                                                    className="select-details-email">
                                                                <Option value="All Email accounts">{'All email accounts'}</Option>
                                                            </Select>
                                                            {/*{ form1.visibleSelected &&*/}
                                                            {/*<span*/}
                                                            {/*    onClick={(e) => this.onCLickAddRemoveContactDetailsTenant(e, 'add', index, form1, data)}*/}
                                                            {/*    className={ onHoverAddDetailEmail === index && hoverIconPlusMsExchange === data.sourceMappingId ? "icon-v22-add-active" : "icon-v22-add"}*/}
                                                            {/*    onMouseEnter={() => this.onHoverAction('add-detail-email', index, data.sourceMappingId)}*/}
                                                            {/*    onMouseLeave={() => this.onLeaveAction()}*/}
                                                            {/*></span>*/}
                                                            {/*}*/}
                                                            { !form1.visibleSelected &&
                                                            <span
                                                                onClick={(e) => this.onCLickAddRemoveContactDetailsTenant(e, 'remove', index, form1, data)}
                                                                className={ onHoverRemoveDetailEmail === index && hoverIconMinusMsExchange === data.sourceMappingId ? 'icon-v22-minus-active' :"icon-v22-minus"}
                                                                onMouseEnter={() => this.onHoverAction('remove-detail-email', index, data.sourceMappingId)}
                                                                onMouseLeave={() => this.onLeaveAction()}
                                                            ></span>
                                                            }
                                                        </div>
                                                        }

                                                        { form1.connectorType && form1.connectorType === 'Microsoft 365 SharePoint' &&
                                                        <div className="option-contact-details">
                                                            <Select onChange={(e) => this.onChangeSelectSite(e, index, form1)}
                                                                    placeholder="Site"
                                                                    value={form1.siteNameSharePoint ? form1.siteNameSharePoint : 'Select SharePoint Site'}
                                                                    dropdownClassName={'dropdown-select-details-email'}
                                                                    className="select-details-email">
                                                                { form1.dataSelectSiteListSharePoint && form1.dataSelectSiteListSharePoint.length > 0 && form1.dataSelectSiteListSharePoint.map((site) => {
                                                                    return (
                                                                        <Option key={site.siteId} value={site.siteId}>{site.siteName}</Option>
                                                                    )
                                                                })
                                                                }
                                                            </Select>
                                                            <Select onChange={(e) => this.onChangeSelectFolder(e, index, form1)}
                                                                    placeholder="Folder"
                                                                    value={form1.folderNameSiteSharePoint ? form1.folderNameSiteSharePoint : 'Select Folder'}
                                                                    dropdownClassName={'dropdown-select-details-email'}
                                                                    className="select-details-email">
                                                                { form1.dataSelectSiteFolderListSharePoint && form1.dataSelectSiteFolderListSharePoint.length > 0 && form1.dataSelectSiteFolderListSharePoint.map((folder) => {
                                                                    return (
                                                                        <Option key={folder.folderId} value={folder.folderName}>{folder.folderName}</Option>
                                                                    )
                                                                })
                                                                }
                                                            </Select>
                                                            {/*{ form1.visibleSelected &&*/}
                                                            {/*<span*/}
                                                            {/*    onClick={(e) => this.onCLickAddRemoveSharePoints(e, 'add', index, form1, data)}*/}
                                                            {/*    className={onHoverAddFolderName === index && hoverIconPlusMsOneDrive === data.sourceMappingId  ? 'icon-v22-add-active' : "icon-v22-add"}*/}
                                                            {/*    onMouseEnter={() => this.onHoverAction('add-folder-name', index, data.sourceMappingId)}*/}
                                                            {/*    onMouseLeave={() => this.onLeaveAction()}*/}
                                                            {/*></span>*/}
                                                            {/*}*/}
                                                            { !form1.visibleSelected &&
                                                            <span
                                                                onClick={(e) => this.onCLickAddRemoveSharePoints(e, 'remove', index, form1, data)}
                                                                className={ onHoverRemoveFolderName === index && hoverIconMinusMsOneDrive === data.sourceMappingId ? "icon-v22-minus-active" :"icon-v22-minus"}
                                                                onMouseEnter={() => this.onHoverAction('remove-folder-name', index, data.sourceMappingId)}
                                                                onMouseLeave={() => this.onLeaveAction()}></span>
                                                            }
                                                        </div>
                                                        }

                                                    </div>

                                                )

                                            })
                                            }

                                            {
                                                data.otherDataSources && data.otherDataSources.length > 0 && data.otherDataSources.map((form2, index) => {

                                                    return (
                                                        <div className="finance-payroll-system" key={`key-${index}`}>
                                                            <span className="name-finance-payroll">{ form2.dataLocation }</span>
                                                            <span onClick={(e) => this.onOpenModalDataUploadFrom(e, form2, index)} className="icon-v22-upload-1"></span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })
                                }
                            </div>

                        </div>

                    </div>

                    <div className="date-source">
                        <span className="icon-v22-search-b"></span>
                        <div className="content-date-source">
                            <div className="date-source-header">
                                <span className="name-data-source">Search queries</span>
                                <span  onClick={(e) => this.onCLickAddRemoveSearchQueries(e, 'add')}
                                       className={ onHoverAddSearchQueries ? "icon-v22-add-active" : "icon-v22-add"}
                                       onMouseEnter={() => this.onHoverAction('add-search-queries')}
                                       onMouseLeave={() => this.onLeaveAction()}
                                ></span>
                            </div>

                            { businessProcessQueries && businessProcessQueries.length > 0 &&
                            <div className="contact-details">
                                { businessProcessQueries && businessProcessQueries.length > 0 && businessProcessQueries.map((form1, index) => {
                                    return (
                                        <div className="contact-details-contain" key={`key-${index}`}>
                                            <div className="checkbox-contain">
                                                <Checkbox checked={form1.selected} onChange={(e) => this.onChangeCheckboxSearchQueries(e, form1)} >{ <span className="value-checkbox"><span className="first-value-checkbox">{form1.query}</span></span> }</Checkbox>
                                            </div>
                                            <div className="option-contact-details">
                                                <Input value={ form1.value } onChange={(e) => this.onInputSearchQueries(e, index, form1)}
                                                       className={ !form1.id ? "input-folder-name input-folder-name-remove" : 'input-folder-name'}></Input>
                                                { !form1.id &&
                                                <span
                                                    onClick={(e) => this.onCLickAddRemoveSearchQueries(e, 'remove', index, form1)}
                                                    className={ onHoverRemoveSearchQueries === index ? "icon-v22-minus-active" : "icon-v22-minus"}
                                                    onMouseEnter={() => this.onHoverAction('remove-search-queries', index)}
                                                    onMouseLeave={() => this.onLeaveAction()}
                                                ></span>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </div>
                            }

                        </div>
                    </div>
                    <div className="content-collect">
                        <Button className={loadingCollect ? "button-collect button-collect-loading" : 'button-collect' }
                                disabled={ disabledActionCollect === true || disabledActionCollectDate === true || disabledBusinessProcessQueries === true}
                                loading={loadingCollect}
                        onClick={() => this.onDataCollectWorkflow()}>Collect</Button>
                    </div>

                    <div className="import-data-collector">
                        <div className="header-import-data-collector">
                            <span className="title-import">Import</span>
                        </div>
                        <div className="content-import-data-collector">
                            { dataUploadHistory && dataUploadHistory.length > 0 && dataUploadHistory.map((dataUpload, indexUpload) => {
                                return (
                                    <Collapse onChange={(key) => this.callback(key)}>
                                        <Panel className="header-import-collapse"
                                               header={<span className="header-import-content"><span>{dataUpload.uploadDate}</span><span>{dataUpload.userName}</span></span>}
                                               key={indexUpload}>
                                            {
                                                dataUpload.dataSourceResponseDTOS && dataUpload.dataSourceResponseDTOS.length > 0 && dataUpload.dataSourceResponseDTOS.map((dataUpload2, indexUpload2) => {
                                                    return (
                                                        <Collapse
                                                            //defaultActiveKey="1"
                                                        >
                                                            <Panel className="subheader-import-collapse" header={dataUpload2.dataSourceName} key={indexUpload2}>
                                                                {/*<p className="paragraf-text">{text}</p>*/}
                                                                { dataUpload2.dataSourceItemResponseDTOS && dataUpload2.dataSourceItemResponseDTOS.length > 0 && dataUpload2.dataSourceItemResponseDTOS.map((dataUpload3, indexUpload3) => {
                                                                    return (
                                                                        <span className="source-items">
                                                                                     <span className="data-source-collapse">
                                                                                         <span className="data-source-item-name">{dataUpload3.dataSourceItemName }</span>
                                                                                         <span className="data-source-item-type">{` - ${dataUpload3.dataSourceItemType}`}</span>
                                                                                     </span>
                                                                                  <span className="file-upload-number">
                                                                                        {dataUpload3.dataSourceDateRange ? `${dataUpload3.dataSourceDateRange}` : ''}
                                                                                    </span>
                                                                                    <span className="file-upload-number">
                                                                                        {`${dataUpload3.filesUploadedNumber} files`}
                                                                                    </span>
                                                                                </span>
                                                                    )
                                                                })

                                                                }
                                                            </Panel>
                                                        </Collapse>
                                                    )
                                                })
                                            }
                                            {
                                                dataUpload.searchQueries && dataUpload.searchQueries.length > 0 &&
                                                <Collapse
                                                    //defaultActiveKey="1"
                                                >
                                                    <Panel className="subheader-import-collapse" header="Search queries" key={indexUpload}>
                                                        { dataUpload.searchQueries.map((dataQueries, indexQueries) => {
                                                            return (
                                                                <span className="source-items-queries">
                                                                            {dataQueries}
                                                                                </span>
                                                            )
                                                        })

                                                        }
                                                    </Panel>
                                                </Collapse>
                                            }
                                        </Panel>
                                    </Collapse>
                                )
                            })

                            }
                        </div>
                    </div>

                </div>
                <Modal className="data-upload-from-modal" title={<div className="header-connector-modal"><span>{'Data upload from'}</span></div>}
                       visible={ visibleDataUploadFrom } footer={null} closable={ false }>
                    <div className="content-data-upload-from">
                        <div className="data-upload-from-text-field">
                            <Input className="input-token-name"
                                   placeholder={"Desktop"}
                                   value={formOtherDataSourcesSelect.valueInputDesktop ? formOtherDataSourcesSelect.valueInputDesktop :
                                       formOtherDataSourcesSelect.nameUpload ? formOtherDataSourcesSelect.nameUpload : "" }
                                   onChange={(e) => this.onInputDesktop(e)}></Input>
                        </div>
                        <div className="data-upload-from-modal-buttons">
                            <Button onClick={ () => this.onCancelModalVisible() }>Cancel</Button>
                            <Upload
                                fileList={ fileList }
                                accept={ '.pdf, .png, .jpeg, .jpg' }
                                beforeUpload={ this.customUploadDraft }
                                multiple={ false }
                                onChange={e => this.onClickOkUpload()}
                            >
                                <Button type="primary" onClick={() => this.onImportModal()}
                                >{'Import'}</Button>
                            </Upload>
                        </div>
                    </div>

                </Modal>
            </div>
        )

    }
}


DataCollectionWorkflow.propTypes = {
    folder: PropTypes.object,
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id,
    smartBoxAxiosHeaders: state.smartBox.smartBoxAxiosHeaders,
})
export default compose(
    connect(mapStateToProps),
)(DataCollectionWorkflow)