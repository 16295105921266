import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import '../ExternalFeedbackWorkflowTicketForm.scss'
import {Input, Select, Steps} from "antd";
import {NameTemplateFormExternal} from "./NameTemplateFormExternal";
const { Option } = Select;
const { Step } = Steps;

export class AddressTemplateFormExternal extends Component {

    state = {

    }
    onChangeNameAndDetails = (e, key) => {
        const { component } = this.props
        switch (key) {
            case 'address_street':
                this.props.onChangeNameAndDetails(e, key, component)
                break
            case 'address_street_line2':
                this.props.onChangeNameAndDetails(e, key, component)
                break
            case 'address_city':
                this.props.onChangeNameAndDetails(e, key, component)
                break
            case 'address_state':
                this.props.onChangeNameAndDetails(e, key, component)
                break
            case 'address_postal':
                this.props.onChangeNameAndDetails(e, key, component)
                break
            default:
                break
        }
    }
    render() {
        const { formerEmployee, component } = this.props
        return (
            <div className="full-name-former">
                <span className="header-former header-former-address">{component.header && component.mandatory ? `${component.header}*` :
                    component.header ? component.header : 'Address'}</span>
                <span className="input-content">
                                <Input className="input-form-name"
                                       onChange={(e) => this.onChangeNameAndDetails(e, 'address_street')}
                                       value={ component.streetAddressValue }/>
                                <span className="label-input">Street Address</span>
                            </span>
                <span className="input-content">
                                <Input className="input-form-name"
                                       onChange={(e) => this.onChangeNameAndDetails(e, 'address_street_line2')}
                                       value={ component.street2AddressValue }/>
                                <span className="label-input">Street Address Line 2</span>
                            </span>
                <div className="input-header-component">
                            <span className="input-content">
                                <Input className="input-form-name"
                                       onChange={(e) => this.onChangeNameAndDetails(e, 'address_city')}
                                       value={ component.cityAddressValue }/>
                                <span className="label-input">City</span>
                            </span>
                    <span className="input-content">
                                <Input className="input-form-name"
                                       onChange={(e) => this.onChangeNameAndDetails(e, 'address_state')}
                                       value={ component.stateAddressValue }/>
                                <span className="label-input">State / Province</span>
                            </span>
                </div>
                <span className="input-content">
                                <Input className="input-form-name"
                                       onChange={(e) => this.onChangeNameAndDetails(e, 'address_postal')}
                                       value={ component.postalCodeValue }/>
                                <span className="label-input">Postal / Zip Code</span>
                            </span>
            </div>
        )
    }
}

export default connect()(AddressTemplateFormExternal)