import React from 'react'
import './Duplicates.scss'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import DuplicatesTable from './DuplicatesTable'
import DuplicatesList from './DuplicatesList'
import {Tooltip} from "antd";

class Duplicates extends React.Component {
  state = {
    visibleDuplicatesList: false,
    selectFileBox: {}
  }
  componentDidMount() {
    const { fileDuplicateOpen } = this.props
    if (fileDuplicateOpen && fileDuplicateOpen.name) {
      this.setState({
        visibleDuplicatesList: true
      })
    } else {
      this.setState({
        visibleDuplicatesList: false
      })
    }
  }
  onVisibleDuplicatesList = (status) => {
    this.setState({
      visibleDuplicatesList: status
    })
  }
  onVisibleDuplicatePage = (status) => {
    this.setState({
      visibleDuplicatesList: status
    })
  }
  selectFileDuplicateBox = (file) => {
    this.setState({
      selectFileBox: file
    })
  }
  render() {
    const { visibleDuplicatesList, selectFileBox } = this.state
    const { fileDuplicateOpen, hashFile } = this.props
    return (
      <div className="duplicates-page">
        <div className="duplicates-page__content">
          { !visibleDuplicatesList &&
            <div className="header-duplicates header-duplicates-update">
              <span className="title-header">Duplicates</span>
              <Tooltip overlayClassName="help-message-locations" title={<div className="content-help-message-locations">
                <span className="text-information">Select files for auto-deduplication or click on a duplicate to manage it manually.</span>
                <span className="more-info-link" onClick={ () => window.open('https://support.smartbox.ai/support/solutions/articles/47001198054-duplicates-report', '_blank') }> More info. </span>
              </div>}>
                <span className="icon-v14-question"></span>
              </Tooltip>
              {/*<span className="subtitle-header">Clean your data from duplicate files</span>*/}
            </div>
          }
          { !visibleDuplicatesList ?
            <div className="content-duplicates-page">
              <DuplicatesTable selectFileDuplicateBox={ this.selectFileDuplicateBox } folder={ this.props.folder }
                onVisibleDuplicatesList={ this.onVisibleDuplicatesList } smartBoxFilesTable={ this.props.smartBoxFilesTable }
                               activeTab={ this.props.activeTab }/>
            </div> :
            <div className="content-duplicates-page">
              <DuplicatesList onVisibleDuplicatePage={ this.onVisibleDuplicatePage } selectFileBox={ selectFileBox }
                folder={ this.props.folder } smartBoxFilesTable={ this.props.smartBoxFilesTable } fileDuplicateOpen={ fileDuplicateOpen }
                hashFile={ hashFile }/>
            </div>
          }
        </div>
      </div>
    )

  }
}

Duplicates.propTypes = {
  folder: PropTypes.object,
}

const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id
})
export default compose(
  connect(mapStateToProps)
)(Duplicates)