import React from 'react'
import PropTypes from 'prop-types'
import FieldName from '../../../utils/AssetRegister/FieldName'
import { Select } from 'antd'
import {
  generateColumnFieldTypeContent,
  generateSelectCaret
} from '../../../utils/AssetRegister/AssetRegisterHelperMethods'

const { Option } = Select

class Column extends React.Component {
  state = {
    columnType: ''
  }

  onColumnTypeSelect = (column, value) => {
    this.setState({
      columnType: value
    }, () => this.props.onChangeColumnType(column, value))
  }

  render() {
    const { column, fieldNameOptions } = this.props
    const { columnType } = this.state
    return (
      <React.Fragment>
        <div className="column__header">
          <span className="icon-dragpad" onMouseEnter={ () => this.props.enableDragging() }/>
          <FieldName options={ fieldNameOptions }
            onChangeOptions={ (optionsArray, item, action) => this.props.onChangeNameOptions(optionsArray, item, action) }
            onSelectOption={ (name) => this.props.onChangeName(column, name) }
            disableDragging={ () => this.props.disableDragging() }
            defaultValue={ column.name }
          />
        </div>
        <div className="column__content" onMouseEnter={ () => this.props.disableDragging() }>
          <Select placeholder="FIELD TYPE..." onChange={ (value) => this.onColumnTypeSelect(column, value) }
            className="custom-select"
            defaultValue={ column.type ? (column.type === 'NULL' ? undefined : column.type) : undefined }
            disabled={ !!(this.props.updateMode && column.type) }>
            <Option value="TEXT">TEXT</Option>
            <Option value="TEXTAREA">TEXTAREA</Option>
            <Option value="RADIO">ONE CHOICE (radio buttons)</Option>
            <Option value="DROPDOWN">ONE CHOICE (dropdown)</Option>
            <Option value="CHECKBOX">MULTIPLE CHOICES</Option>
            <Option value="FILE">FILE</Option>
            <Option value="DATE">DATE</Option>
            <Option value="NUMBER">NUMBER</Option>
            <Option value="ADDRESS_FINDER">ADDRESS FINDER</Option>
          </Select>
          {
            column.type || columnType ? generateSelectCaret(column.type || columnType) : ''
          }
          {
            column.type || columnType ? generateColumnFieldTypeContent(column, column.type || columnType, this.props) : ''
          }
        </div>
      </React.Fragment>
    )
  }

}

Column.propTypes = {
  column: PropTypes.object.isRequired,
  onChangeName: PropTypes.func.isRequired,
  onChangeNameOptions: PropTypes.func.isRequired,
  onChangeColumnType: PropTypes.func.isRequired,
  onChangeOptions: PropTypes.func.isRequired,
  onChangeDateFormat: PropTypes.func.isRequired,
  disableDragging: PropTypes.func.isRequired,
  enableDragging: PropTypes.func.isRequired,
  fieldNameOptions: PropTypes.array.isRequired,
  updateMode: PropTypes.bool.isRequired
}

export default Column
