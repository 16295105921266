export const generateBlobFromBase64 = (base64) => {
  const binary = atob(base64)
  const len = binary.length
  const buffer = new ArrayBuffer(len)
  const view = new Uint8Array(buffer)
  for (let i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i)
  }
  // create the blob object with content-type "application/pdf"
  const blob1 = new Blob([view], { type: 'application/pdf' })
  return window.URL.createObjectURL(blob1)
}