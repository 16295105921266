import axios from 'axios'

const isLocalhost = () => {
  return window.location.hostname === 'localhost'
}
// const host = isLocalhost() ? 'https://staging.ospreysmart.com' : ''
const host = isLocalhost() ? 'http://localhost:8080' : ''

export const saveRegister = (data) => {
  return axios({
    method: 'post',
    url: `${host}/osprey-0.1.0/v1/osprey/asset/`,
    data: data
  })
}



export const updateRegister = (data, id) => {
  return axios({
    method: 'put',
    url: `${ host }/osprey-0.1.0/v1/osprey/asset/${ id }`,
    data: data
  })
}

export const getAllRegistersByClientId = (clientId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/osprey/asset/client/${ clientId }`
  })
}

export const deleteRegister = (id) => {
  return axios({
    method: 'delete',
    url: `${ host }/osprey-0.1.0/v1/osprey/asset/${ id }`
  })
}

export const getAllFieldNamesByClientIdForAssetRegisterApp = (clientId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/osprey/asset/field-names/client/${ clientId }`
  })
}

export const removeFieldNameForAssetRegisterApp = (id) => {
  return axios({
    method: 'delete',
    url: `${ host }/osprey-0.1.0/v1/osprey/asset/field-names/remove/${ id }`
  })
}

export const addFieldNameForAssetRegisterApp = (fieldNameObject) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/osprey/asset/field-names/add`,
    data: fieldNameObject
  })
}

export const searchRegister = (data) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/osprey/asset/search`,
    data: data
  })
}

export const addAssetRegister = (object) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/osprey/asset/create-register-manager`,
    data: object
  })
}

export const updateAssetRegister = (object) => {
  return axios({
    method: 'put',
    url: `${ host }/osprey-0.1.0/v1/osprey/asset/update-register-manager`,
    data: object
  })
}

export const getAssetRegister = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/osprey/asset/register/${ id }`
  })
}

export const getAllAssetRegister = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/osprey/asset/register/client/${ id }`
  })
}

export const deleteAssetRegisterManager = (id) => {
  return axios({
    method: 'delete',
    url: `${ host }/osprey-0.1.0/v1/osprey/asset/register/remove/${ id }`
  })
}

export const findAssetRegisterByColumnAndValue = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/osprey/asset/find`,
    data
  })
}

export const getAssetRegisterColumnNames = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/osprey/asset/get-column-names/${ id }`
  })
}

export const getRegisterStatistics = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/osprey/asset/get-register-statistics`
  })
}

export const getManagerStatistics = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/osprey/asset/get-manager-statistics`
  })
}

export const getYearQuarterlyMonthlyStatistics = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/osprey/asset/get-year-quarterly-monthly-statistics`
  })
}

export const getMapStatistics = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/osprey/asset/get-map-statistics`
  })
}