import { isLocalhost } from '../utils'

import * as Authentication from './Privacy/Authentication'
import * as Alert from './Privacy/Alert'
import * as Category from './Privacy/Category'
import * as Connector from './Privacy/Connector'
import * as Collection from './Privacy/Collection'
import * as DataModel from './Privacy/DataModel'
import * as File from './Privacy/File'
import * as Topic from './Privacy/Topic'
import * as Process from './Privacy/Processes'
import * as Osprey from './Managers/CategoryManager'

const host = isLocalhost() ? 'http://192.168.1.126:9080' : ''

export const apiPrivacyAuthenticate = () => Authentication.apiPrivacyAuthenticate(host)
export const apiPrivacyFetchToken = () => Authentication.apiPrivacyFetchToken(host)
export const apiPrivacyGetMsgraphUrl = () => Authentication.apiPrivacyGetMsgraphUrl(host)

export const apiPrivacyGetAlerts = () => Alert.apiPrivacyGetAlerts(host)
export const apiPrivacyGetTotalFilesForAlertLevels = (...args) => Alert.apiPrivacyGetTotalFilesForAlertLevels(host, ...args)
export const apiPrivacyAddAlert = (...args) => Alert.apiPrivacyAddAlert(host, ...args)
export const apiPrivacyGetAlertUsingId = (...args) => Alert.apiPrivacyGetAlertUsingId(host, ...args)

export const apiPrivacyChangeCategoryParent = (...args) => Category.apiPrivacyChangeCategoryParent(host, ...args)
export const apiPrivacyDeleteCategory = (...args) => Category.apiPrivacyDeleteCategory(host, ...args)
export const apiPrivacyRenameCategory = (...args) => Category.apiPrivacyRenameCategory(host, ...args)
export const apiPrivacySaveCategory = (...args) => Category.apiPrivacySaveCategory(host, ...args)
export const apiPrivacySetCategoryProperty = (...args) => Category.apiPrivacySetCategoryProperty(host, ...args)

export const apiPrivacyAddNewCrawler = (...args) => Connector.apiPrivacyAddNewCrawler(host, ...args)
export const apiPrivacyCrawlerGetStatus = (...args) => Connector.apiPrivacyCrawlerGetStatus(host, ...args)
export const apiPrivacyCrawlerStart = (...args) => Connector.apiPrivacyCrawlerStart(host, ...args)
export const apiPrivacyDeleteCrawler = (...args) => Connector.apiPrivacyDeleteCrawler(host, ...args)
export const apiPrivacyGetConnectors = () => Connector.apiPrivacyGetConnectors(host)
export const apiPrivacyGetConnectorConfiguration = (...args) => Connector.apiPrivacyGetConnectorConfiguration(host, ...args)
export const apiPrivacyGetConnectorObjects = (...args) => Connector.apiPrivacyGetConnectorObjects(host, ...args)
export const apiPrivacyGetServers = (...args) => Connector.apiPrivacyGetServers(host, ...args)

export const apiPrivacyAddCollection = () => Collection.apiPrivacyAddCollection(host)
export const apiPrivacyGetAlertsForCollection = (...args) => Collection.apiPrivacyGetAlertsForCollection(host, ...args)
export const apiPrivacyGetCollections = () => Collection.apiPrivacyGetCollections(host)
export const apiPrivacyGetDataSourceCountForCollection = (...args) => Collection.apiPrivacyGetDataSourceCountForCollection(host, ...args)
export const apiPrivacyGetTotalObjectsForCollections = (...args) => Collection.apiPrivacyGetTotalObjectsForCollections(host, ...args)

export const apiPrivacyGetDataModels = () => DataModel.apiPrivacyGetDataModels(host)
export const apiPrivacyGetFacetValues = () => DataModel.apiPrivacyGetFacetValues(host)
export const apiPrivacyGetCountFolder = (...args) => DataModel.apiPrivacyGetCountFolder(host, ...args)

export const apiPrivacyGetObjects = (...args) => File.apiPrivacyGetObjects(host, ...args)
export const apiPrivacyGetDirectories = (...args) => File.apiPrivacyGetDirectories(host, ...args)
export const apiPrivacyGetFolders = (...args) => File.apiPrivacyGetFolders(host, ...args)
export const apiPrivacyGetDatasetForFolder = (...args) => File.apiPrivacyGetDatasetForFolder(host, ...args)
export const apiPrivacyGetLastDateModifiedForFolder = (...args) => File.apiPrivacyGetLastDateModifiedForFolder(host, ...args)
export const apiPrivacyGetFilesFromFolders = (...args) => File.apiPrivacyGetFilesFromFolders(host, ...args)
export const apiPrivacyGetInformationForFiles = (...args) => File.apiPrivacyGetInformationForFiles(host, ...args)

export const apiPrivacyGetTopics = () => Topic.apiPrivacyGetTopics(host)
export const apiPrivacyGetLocationsForTopic = (...args) => Topic.apiPrivacyGetLocationsForTopic(host, ...args)

export const apiPrivacyGetProcesses = () => Process.apiPrivacyGetProcesses(host)

export const apiOspreyGetCategory = (...args) => Osprey.CategoryManager.getCategory(host, ...args)