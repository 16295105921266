import axios from 'axios'

export const apiPrivacyGetDataModels = (host, datamodel) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/getDataModels`,
    data: {
      sessionID: token,
      datamodel: datamodel
    }
  })
}

export const apiPrivacyGetFacetValues = (host, query, datamodel, facetnames, filterQueries, offset, length, prefix) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/getDataModelFacetValues`,
    data: { 
      sessionID: token,
      query: query,
      datamodel: datamodel,
      facetnames: facetnames,
      filterQueries: filterQueries,
      offset: offset,
      length: length,
      prefix: prefix
    }
  })
}

export const apiOspreyGetDataModels = (host) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/getDataModels`,
    data: { 
      sessionID: token
    }
  })
}

export const apiOspreyGetDataModelQuery = (host, datamodel, query, fieldNames, filterQueries, offset, length, sortfield, ascending) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/getDataModelQuery`,
    data: { 
      sessionID: token,
      datamodel: datamodel,
      query: query,
      fieldNames: fieldNames,
      filterQueries: filterQueries,
      offset: offset,
      length: length,
      sortfield: sortfield,
      ascending: ascending
    }
  })
}

export const apiOspreyGetDataModelStats = (host, datamodel, query, pivotFields, fieldNames, filterQueries, facetOffsets, facetLengths) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/getDataModelStats`,
    data: { 
      sessionID: token,
      datamodel: datamodel,
      query: query,
      pivotFields: pivotFields,
      fieldNames: fieldNames,
      filterQueries: filterQueries,
      facetOffsets: facetOffsets,
      facetLengths: facetLengths
    }
  })
}

export const apiOspreyGetDataModelDocument = (host, datamodel, documentID, query) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/getDocumentByDatamodel`,
    data: { 
      sessionID: token,
      datamodel: datamodel,
      documentID: documentID,
      query: query
    }
  })
}

export const apiOspreyGetDataModelFacetValues = (host, datamodel, facetNames, filterQueries, offset, length, prefix) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/getDataModelFacetValues`,
    data: { 
      sessionID: token,
      datamodel: datamodel,
      facetNames: facetNames, 
      filterQueries: filterQueries, 
      offset: offset, 
      length: length, 
      prefix: prefix
    }
  })
}

export const apiOspreyAddMetadataBlacklist = (host, datamodel, fieldName, value) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/addMetadataBlacklist`,
    data: { 
      sessionID: token,
      datamodel: datamodel,
      fieldName: fieldName, 
      value: value
    }
  })
}

export const apiOspreyGetMetadataBlacklist = (host, datamodel) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/getMetadataBlacklist`,
    data: { 
      sessionID: token,
      datamodel: datamodel
    }
  })
}

export const apiOspreyGetTrainingBlacklist = (host, categoryID) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/getCategoryTrainingBlacklist`,
    data: { 
      sessionID: token,
      categoryID: categoryID
    }
  })
}

export const apiOspreyAddTrainingBlacklist = (host, categoryID, blacklistTerm) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/addCategoryTrainingBlacklist`,
    data: { 
      sessionID: token,
      categoryID: categoryID,
      blacklistTerm: blacklistTerm
    }
  })
}