import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'

import Button from '../../Common/Button'
import Loading from '../../Common/Loading'

import WithAlerts from '../HOC/WithAlerts'

import './Content.css'

class Content extends React.Component {
  state = { page: 0 }

  getName = (user) => {
    const name = user.user_metadata.name || user.name
    if (!name) return ''

    const [firstName, ] = name.split(' ')
    return firstName
  }
  
  showMore = () => {
    this.setState({ page: this.state.page + 1 },
      () => {
        const { id } = this.props.alert
        this.props.updateAlert(id, { isFetchingMoreFiles: true })
        this.props.getInformationForAlert(id, this.state.page)
      })
  }
  
  render = () => {
    const { alert, showModal, user, users } = this.props

    if (alert.isFetchingInfo) return (
      <div className="privacyAlertContent__wrapper--loading">
        <Loading />
      </div>
    )

    return (
      <div className="privacyAlertContent__wrapper">
        <div id="content-list" className="privacyAlertContent__list">
          { renderContentsList(alert.files, showModal) }
        </div>
        <div className="reviewTopic__content--action">
          { alert.isFetchingMoreFiles &&
            <Loading />
          }
          { alert.hasMoreFilesToShow &&
            !alert.isFetchingMoreFiles &&
            <Button onClick={ this.showMore }>Show more files</Button>
          }
        </div>

        <div className="privacyAlertContent__footerWrapper">
          <div className="privacyAlertContent__assignedSelect">
            <select>
              <option value="">Assign to review...</option>
              <option value={ user.user_id }>{ user.email } { `- ${ this.getName(user) }` }</option>
              { users.length > 0 &&
                users.map((item) => (
                  <option key={ item.user_id } value={ item.user_id }>
                    { item.email } { this.getName(item) }
                  </option>
                ))
              }
            </select>
          </div>
        </div>
      </div>
    )
  }
}

const renderContentsList = (contents, showModal) => {
  return contents.map((content, index) => {
    return (
      <div
        key={ index }
        className="privacyAlertContent__fileItem"
        onClick={ showModal.bind(this, content) }>
        <span className="privacyAlertContent__path">{ content.metadata.doc_path }</span>
        <span className="privacyAlertContent__view">View</span>
      </div>
    )
  })
}

Content.propTypes = {
  alert: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired
}

const stateMap = (state) => ({
  user: state.userReducer.user,
  users: state.manageGroupsReducer.manageGroups.list
})

export default compose(
  connect(stateMap, null),
  WithAlerts
)(Content)