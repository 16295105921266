import React from "react";
import {connect} from "react-redux";
import './ComponentsFormAdmin.scss';
import { Input, Checkbox} from "antd";
import SignatureCanvas from "react-signature-canvas";

class SignatureFormAdmin extends React.Component {
    constructor() {
        super()
        this.state = {
            signatureForm: {
                signature: '',
                queryForSearch: false,
                mandatoryCheck: false,
                options: null,
                header: 'Signature',
                sectionVariable: ''
            },
            hoverDelete: false,
            editHeader: false,
            hoverEditBtn:false,
        }
        this.handleClearSignature = this.handleClearSignature.bind(this)
        this.signatureCanvas = React.createRef()
    }
    componentDidMount() {
        const { component } = this.props
        this.setState({
            signatureForm: { ...this.state.signatureForm,
                queryForSearch: component.queryForSearch === undefined ? false : component.queryForSearch,
                mandatoryCheck: component.mandatory === undefined ? false : component.mandatory,
                options: component.options === undefined ? null : component.options,
                header: component.header === undefined ? 'Signature' : component.header,
                sectionVariable: component.sectionVariable === undefined ? '' : component.sectionVariable,
            }
        })
        //this.props.onChangeForm(this.state.signatureForm, this.props.component)
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props && this.props.checkStartSaved) {
            this.props.onChangeForm(this.state.signatureForm, this.props.component)
        }
    }
    handleClearSignature() {
        const { signatureForm } = this.state
        this.signatureCanvas.clear()
        signatureForm.signature = ''
        this.setState({
            signatureForm
        })
    }
    handleAddSignature = () => {
        const { signatureForm } = this.state
        signatureForm.signature = this.signatureCanvas.toDataURL()
        this.setState({
            signatureForm
        })
    }
    onChangeCheckbox = (e, key) => {
        switch (key) {
            case 'query':
                this.setState({
                    signatureForm: { ...this.state.signatureForm, queryForSearch: e.target.checked },
                }, () => {

                })
                break
            case 'mandatory':
                this.setState({
                    signatureForm: { ...this.state.signatureForm, mandatoryCheck: e.target.checked },
                }, () => {
                    this.props.onChangeForm(this.state.signatureForm, this.props.component)
                })
                break
            default:
                break
        }
    }
    onHoverActionComponent = () => {
        this.setState({
            hoverDelete: true
        })
    }
    onLeaveActionComponent = () => {
        this.setState({
            hoverDelete: false
        })
    }
    onHoverEditBtn = () => {
        this.setState({
            hoverEditBtn: true
        })
    }
    onLeaveEditBtn = () => {
        this.setState({
            hoverEditBtn: false
        })
    }

    onClickEditHeader = (e, status) => {
        if (status === 'header') {
            this.setState({
                editHeader: true
            })
        }
    }
    addTitleHeader = (e, status) => {
        if (status === 'header') {
            this.setState({
                signatureForm: { ...this.state.signatureForm, header: e.target.value },
                //editHeader: false
            }, () => {
                this.props.onChangeForm(this.state.signatureForm, this.props.component)
            })
        }
    }
    onChangeFormVariable = (e) => {
        this.setState({
            signatureForm: { ...this.state.signatureForm, sectionVariable: e.target.value },
        }, () => {
            this.props.onChangeForm(this.state.signatureForm, this.props.component)
        })
    }
    confirmEditHeader = () => {
        this.setState({
            editHeader:false,
        })
    }
    render() {
        const { signatureForm, hoverDelete, editHeader, hoverEditBtn } = this.state
        const { component } = this.props
        return (
            <div className={ this.props.snapshot.isDragging ? "component-form dragging " : "component-form"}>
                <div className="component-delete">
                    <span onClick={ (e) => this.props.showConfirmDeleteFormElement(e, component) }
                          className={hoverDelete ? 'icon-v19-delete-active' : "icon-v19-delete" }
                          onMouseEnter={ (e) => this.onHoverActionComponent(e)}
                          onMouseLeave={() => this.onLeaveActionComponent()}></span>
                </div>
                <div className="component-content">
                    <div className="content-input-form-variables">
                        <span className="header-checkbox">
                           {/*<Checkbox onChange={(e) => this.onChangeCheckbox(e, 'query')}*/}
                            {/*          checked={ signatureForm.queryForSearch }>Query for search</Checkbox>*/}
                            <Checkbox onChange={(e) => this.onChangeCheckbox(e, 'mandatory')}
                                      checked={ signatureForm.mandatoryCheck }>Mandatory</Checkbox>
                        </span>
                        {/*<span className="content-variables">*/}
                        {/*     <span className="variables-diaz">#</span>*/}
                        {/*<Input*/}
                        {/*    className="input-form-variables"*/}
                        {/*    placeholder="Form variable"*/}
                        {/*    value={signatureForm.sectionVariable}*/}
                        {/*    onChange={(e) => this.onChangeFormVariable(e)}*/}
                        {/*/>*/}
                        {/*</span>*/}
                    </div>
                    <div className="header-component header-component-update">
                         <span className="header-content">
                              { editHeader ?
                                  <div>
                                      <Input defaultValue={signatureForm.header} className="input-other-option"
                                             onChange={ (e) => this.addTitleHeader(e, 'header')}
                                             onPressEnter={() => this.confirmEditHeader()}
                                      />
                                      <span className={ hoverEditBtn ? "icon-v21-edit-active" : "icon-v21-edit"}
                                            onClick={(e) => this.confirmEditHeader()}
                                            onMouseEnter={() => this.onHoverEditBtn()}
                                            onMouseLeave={() => this.onLeaveEditBtn()}
                                      ></span>
                                  </div>
                                   :
                                  <div>
                                      <span className="header-title header-title-update">{signatureForm.header}</span>
                                      <span className={ hoverEditBtn ? "icon-v21-edit-active" : "icon-v21-edit"}
                                            onClick={(e) => this.onClickEditHeader(e, 'header')}
                                            onMouseEnter={() => this.onHoverEditBtn()}
                                            onMouseLeave={() => this.onLeaveEditBtn()}
                                      ></span>
                                  </div>
                              }
                          </span>
                    </div>
                    <div className="signature-content">
                        {/*<SignatureCanvas*/}
                        {/*    ref={(ref) => {*/}
                        {/*        this.signatureCanvas = ref*/}
                        {/*    }}*/}
                        {/*    penColor='#000'*/}
                        {/*    canvasProps={{ className: 'signature' }}*/}
                        {/*/>*/}
                        <div className="signature-disabled">

                        </div>
                        <button type="button" className="button-small-red-empty float-right"
                                disabled={true}
                                onClick={this.handleClearSignature}>Clear
                        </button>
                        <button type="button" className="button-add-signature float-right"
                                disabled={true}
                                onClick={(e) => this.handleAddSignature(e)}>Add
                        </button>
                    </div>
                </div>
                <div className="component-move">
                    <span onMouseEnter={() => this.props.onStateProvidedDraggable(component)} className="icon-v211-grid-view-1"></span>
                </div>
            </div>
        )
    }
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(SignatureFormAdmin)