import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Loading from '../../Common/Loading'

import './CollectionCard.css'

class CollectionCard extends React.Component {
  render = () => {
    const { collection } = this.props

    return (
      <div className="collectionCard__wrapper">
        <header className="collectionCard__header">
          <p className="collectionCard__description">{ collection.description }</p>
          <h1 className="collectionCard__title">{ collection.name }</h1>
          <Link to={ `/privacy/collection/edit/${ collection.id }` }>
            <span className="iconEdit"></span>
          </Link>
        </header>
        <div className="collectionCard__content">
          { (collection.isFetchingInfo || collection.isFetchingTotalAlerts) &&
            <div className="collectionCard__loading">
              <Loading />
            </div>
          }

          { (!collection.isFetchingInfo && !collection.isFetchingTotalAlerts) &&
            <React.Fragment>
              <div className="collectionCard__alerts">
                <p className="collectionCard__text">Potential alerts</p>
                <div>
                  <span className="collectionCard__high">
                    { collection.totalAlerts.high }
                  </span>
                  <span className="collectionCard__medium">
                    { collection.totalAlerts.medium }
                  </span>
                  <span className="collectionCard__low">
                    { collection.totalAlerts.low }
                  </span>
                </div>
                <Link to={ `/privacy/alerts/${ collection.id }` } className="collectionCard__review-alerts">
                  Review alerts
                </Link>
              </div>

              <div className="collectionCard__info">
                <div className="collectionCard__objects">
                  <div className="collectionCard__total">{ collection.totalObjects }</div>
                  total objects<br />
                  <div className="collectionCard__datasets">
                    <strong>{ collection.totalDataSources }</strong> data sources
                  </div>
                </div>
                
                <Link to={ `/privacy/files/${ collection.id }` } className="collectionCard__review-files">
                  Review files
                </Link>
              </div>
            </React.Fragment>
          }
        </div>
      </div>
    )
  }
}

CollectionCard.propTypes = {
  collection: PropTypes.object.isRequired
}

export default CollectionCard