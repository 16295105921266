import React from 'react'
import { compose } from 'redux'

import Loading from '../../Common/Loading'

import TaxonomyList from './Taxonomies/TaxonomyList'

import './Workspaces.css'
import WithWorkspaces from './HOC/WithWorkspaces'

class Workspaces extends React.Component {
    state = {
    }
  
    componentDidMount = () => {
      window.scrollTo(0, 0)
    }
    
    render = () => {
      
      return (
        <div className="backofficeTaxonomies__wrapper">
          { this.props.isFetchingCollections
            ? <div className="ospreyTaxonomies__loading"><Loading /></div>
            : <TaxonomyList
              taxonomies={ this.props.taxonomies }
            />
          }
        </div>
      )
    }
}

export default compose(WithWorkspaces)(Workspaces)