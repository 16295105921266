import React from 'react'
import Card from './Card'
import PropTypes from 'prop-types'
import { getRegisterStatistics } from '../../../../api/AssetRegister'

class CardList extends React.Component {

  constructor() {
    super()
    this.state = {
      cardData: {}
    }
  }

  componentDidMount() {
    getRegisterStatistics(this.props.clientId).then((response) => {
      if (response.status === 200 && response.data) {
        this.setState({ cardData: response.data })
      }
    })
  }

  render() {
    const { cardData } = this.state
    return (
      <React.Fragment>
        { this.props.onSelectCard && cardData &&
        <div className= "card-line">
          <Card title="Total register" color="red" icon="icon-assetregister-icon-neg" valueNumber={ +cardData.totalRegister } onSelectCard={ this.props.onSelectCard } />
          <Card title="Total assets" color="purple" icon="fa fa-cog" valueNumber={ +cardData.totalAssets } onSelectCard={ this.props.onSelectCard } />
          <Card title="Total value of assets" color="green" icon="fa fa-database" valuePrefix="‎£" valueNumber={ +cardData.totalValueOfAssets } valueSuffix="k" onSelectCard={ this.props.onSelectCard }/>
          <Card title="Assets locations" color="yellow" icon="fa fa-building-o" valueNumber={ +cardData.assetLocations } valueSuffix="‎ Cities" onSelectCard={ this.props.onSelectCard } />
        </div>
        }
      </React.Fragment>
    )
  }
}

CardList.propTypes = {
  onSelectCard: PropTypes.func.isRequired
}

export default CardList