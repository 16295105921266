import initialStateAdmin from './initialStateAdmin'
import { GOD_ADMIN_MODE } from '../actions/actionTypes'
/*const INITIAL_STATE = {
  godAdminMode: false
}*/

const adminReducer = (state = initialStateAdmin, action) => {
  switch (action.type) {
    case 'LOAD_CLIENT_EDIT_FORM':
      return {
        ...state, clientEditForm: action.payload
      }
    case 'LOAD_USER_EDIT_FORM':
      return {
        ...state, userEditForm: action.payload
      }
    case GOD_ADMIN_MODE:
      return {
        ...state, godAdminMode: action.payload
      }
    case 'LOAD_INVITE_TENANT_FORM':
      return {
        ...state, addNewInviteTenant: action.payload
      }
      
    default:
      return state
  }
}
export const load = (data) => ({ type: 'LOAD', payload: data })

export default adminReducer