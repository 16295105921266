import React from 'react'
import PropTypes from 'prop-types'

import { CollectionsConsumer } from '../../../contexts/Collections'

const WithCollections = (Component) => {
  return class WithCollections extends React.Component {
    render = () => {
      return (
        <CollectionsConsumer>
          { ({ getCollections, ...rest }) => (
            <InitCollections getCollections={ getCollections }>
              <Component {...this.props} {...rest} />
            </InitCollections>
          )}
        </CollectionsConsumer>
      )
    }
  }
}

class InitCollections extends React.Component {
  componentDidMount = () => {
    this.props.getCollections()
  }

  render = () => {
    return (
      this.props.children 
    )
  }
}

InitCollections.propTypes = {
  children: PropTypes.element.isRequired,
  getCollections: PropTypes.func.isRequired
}

export default WithCollections