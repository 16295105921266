import React from 'react'
import {
  generatePiiAnalyticsTree,
  renderTreeNodesAnalytics,
} from '../../../../../utils/SmartBox/SmartBoxMethods'
import { Tree } from 'antd'
import { savePiiCategories, getPiiCategories } from '../../../../../api/SmartBox'
import CustomNotificationManager from '../../../../Common/CustomNotificationManager'
import {getAnnotationColorsByClientId, getAnnotationColorsCategoryByClientId} from "../../../../../api/Sar";

class Analytics extends React.Component {
  state = {
    expandedKeys: [/*'doc_annotate_select_all'*/],
    categoriesTree: [ /*{
      title: 'Select All',
      key: 'doc_annotate_select_all',
      identifier: 'top-parent',
      children: []
    }*/ ],
    autoExpandParent: true,
    checkedKeysObjectRefs: [],
    //checkedKeys: ['addresses', 'names', 'identifiers', 'locations', 'numbers', 'organisations', 'time'],
    checkedKeys: [],
    newKeysCategory: [],
    stateUpdateConfirmSelection: false,
    checkKeysForNewFolder: false,
    categoryVisited: '',
    checkUpdatePiiCategories: false,
    stateGetPiiCategories: '',
    resultKeysSaved: [],
    annotationsCategoriesColours: []
  }

  expandHideCategory = (e, key, action) => {
    e.stopPropagation()
    let { expandedKeys } = { ...this.state }
    if (action === 'add') {
      expandedKeys.push(key)
    } else {
      expandedKeys = expandedKeys.filter((eKey) => eKey !== key)
    }
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    }, () => this.expandCategoryVisited(e, key, action))
  }
  
  onCheck = (checkedKeys, { checkedNodes }) => {
    this.setState({
      checkedKeys,
      checkedKeysObjectRefs: checkedNodes.map((node) => node.props.dataRef).filter((obj) => obj.identifier === 'category-child').map((obj) => obj.key),
      checkKeysForNewFolder: false
    }, () => {
      this.saveChanges()
      this.props.onGetStatusRunAnalytics(false)
    })
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.folderId !== this.props.folderId && prevProps.folderId && this.props.smartBoxSelectedDocumentId.action === 'analysis-settings') {
      if (this.props.smartBoxSelectedDocumentId) {
        //this.onUpdateGetPiiCategories(this.props.smartBoxSelectedDocumentId.documentId)
        this.getAnnotateColorPii(this.props.clientId, this.props.userId, this.state.categoriesTree)
      }
      let { categoriesTree } = this.state
      categoriesTree = generatePiiAnalyticsTree()
      const listSortCategoriesTree = categoriesTree && categoriesTree.length > 0 ? categoriesTree.sort((userA, userB) => (userA.title.localeCompare(userB.title))) : ''
      this.setState({
        categoriesTree: listSortCategoriesTree
      }, () => {
        //this.fetchAnnotationColorsCategories(this.props.clientId, categoriesTree)
        //this.getAnnotateColorPii(this.props.clientId, this.props.userId, categoriesTree)
      })
      this.getAnnotateColorPii(this.props.clientId, this.props.userId, categoriesTree)
    }
    if (prevProps.resultKeysSavedS.length !== this.props.resultKeysSavedS.length && prevState.categoriesTree.length > 0) {
      this.onGetPiiCategoriesSavedUpdate()
    }
  }
  onUpdateGetPiiCategories = (documentId) => {
    const { categoriesTree } = this.state
    const extractKeys = []
    categoriesTree && categoriesTree[0].children.map((child) => {
      child && child.children && child.children.length > 0 && child.children.map((child2) => {
        extractKeys.push(child2.key)
        return true
      })
      return true
    })
    getPiiCategories(documentId).then((response) => {
      this.setState({
        stateGetPiiCategories: response.data.state,
        resultKeysSaved: response.data.result
      })
      if (response.data && response.data.result !== 0) {
        if (response.data.length === extractKeys.length) {
          this.setState({
            checkKeysForNewFolder: true
          })
        } else {
          this.setState({
            checkKeysForNewFolder: false
          })
        }
        if (response.data.state) {
          this.setState({
            checkedKeys: response.data.result
          })
        } else {
          this.setState({
            //checkedKeys: ['addresses', 'names', 'identifiers', 'locations', 'numbers', 'organisations', 'time']
            checkedKeys: response.data.result
          })
        }
      } else {
        this.setState({
          checkedKeys: response.data.result
        })
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
          console.log(error)
      }
    })
  }
  expandHideRow = ({ props }) => {
    const { dataRef } = props
    let { expandedKeys } = { ...this.state }
    if (expandedKeys.includes(dataRef.key)) {
      expandedKeys = expandedKeys.filter((eKey) => eKey !== dataRef.key)
    } else {
      expandedKeys.push(dataRef.key)
    }
    this.setState({
      expandedKeys,
      autoExpandParent: false
    })
  }
  expandCategoryVisited = (e, key, action) => {
    this.setState({
      categoryVisited: action === 'add' ? key : ''
    })
  }
   getAnnotateColorPii = async(clientId, userId, categoriesTree) => {
     let response
     response = await getAnnotationColorsByClientId(clientId, userId)
     response.data && response.data.length > 0 && response.data.forEach((color) => {
       categoriesTree && categoriesTree.length > 0 && categoriesTree.forEach((cat) => {
         if (color.category === cat.key) {
           cat.color = color.color
         }

       })
     })
     // getAnnotationColorsByClientId(clientId, userId).then((response) => {
     //   response.data && response.data.length > 0 && response.data.forEach((color) => {
     //     categoriesTree && categoriesTree.length > 0 && categoriesTree.forEach((cat) => {
     //       if (color.category === cat.key) {
     //         cat.color = color.color
     //       }
     //
     //     })
     //   })
     // }).catch((error) => {
     //   if (error.response.status === 504) {
     //     CustomNotificationManager.cleanBuffer('warning', '')
     //   }
     // })
   }
  componentDidMount() {
    let { categoriesTree } = this.state
    //const { idFolder } = this.props
    categoriesTree = generatePiiAnalyticsTree()
    const listSortCategoriesTree = categoriesTree && categoriesTree.length > 0 ? categoriesTree.sort((userA, userB) => (userA.title.localeCompare(userB.title))) : ''
    this.setState({
      categoriesTree: listSortCategoriesTree
    }, () => {
      //this.fetchAnnotationColorsCategories(this.props.clientId, categoriesTree)
      this.getAnnotateColorPii(this.props.clientId, this.props.userId, categoriesTree)
    })
    //this.onGetPiiCategoriesSaved(idFolder)
    //this.fetchAnnotationColorsCategories(this.props.clientId)
  }
  fetchAnnotationColorsCategories(clientId, categoriesTree) {
    //const { categoriesTree } = this.state
    getAnnotationColorsCategoryByClientId(clientId).then(({ data }) => {
      data && data.length > 0 && data.forEach((item) => {
        categoriesTree && categoriesTree.length > 0 && categoriesTree.forEach((cat) => {
          if (item.category === cat.key) {
            cat.color = item.color
          }

        })
      })
      this.setState({
        annotationsCategoriesColours: data,
      })
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
          console.log(error)
      }
    })
  }
  onGetPiiCategoriesSavedUpdate = (folderId) => {
    const { categoriesTree } = this.state
    const { stateGetPiiCategoriesS, resultKeysSavedS } = this.props
    const extractKeys = []
    let stateGetPiiCategoriesFilters = []
    categoriesTree && categoriesTree/*[0].children*/.map((child) => {
      child && child.children.map((child2) => {
        extractKeys && extractKeys.length > 0 && extractKeys.push(child2.key)
        return true
      })
      return true
    })
    if (resultKeysSavedS && resultKeysSavedS.length > 0) {
      stateGetPiiCategoriesFilters = resultKeysSavedS.filter((a) => a !== 'doc_annotate_person_male' && a !== 'doc_annotate_person_female')
    }
    if (stateGetPiiCategoriesFilters && stateGetPiiCategoriesFilters !== 0) {
      if (stateGetPiiCategoriesFilters.length === extractKeys.length) {
        this.setState({
          checkKeysForNewFolder: true
        })
      } else {
        this.setState({
          checkKeysForNewFolder: false
        })
      }
      if (stateGetPiiCategoriesS) {
        this.setState({
          checkedKeys: stateGetPiiCategoriesFilters
        })
      } else {
        this.setState({
          //checkedKeys: ['addresses', 'names', 'identifiers', 'locations', 'numbers', 'organisations', 'time']
          checkedKeys: stateGetPiiCategoriesFilters
        })
      }
    } else {
      this.setState({
        checkedKeys: stateGetPiiCategoriesFilters
      })
    }
  }
  onGetPiiCategoriesSaved =(folderId) => {
    const { categoriesTree } = this.state
    // const { stateGetPiiCategoriesS, resultKeysSavedS } = this.props
    const extractKeys = []
    categoriesTree && categoriesTree/*[0].children*/.map((child) => {
      child && child.children.map((child2) => {
        extractKeys.push(child2.key)
        return true
      })
      return true
    })
    getPiiCategories(folderId).then((response) => {
      this.setState({
        stateGetPiiCategories: response.data.state,
        resultKeysSaved: response.data.result
      })
      if (response.data && response.data.result !== 0) {
        if (response.data.length === extractKeys.length) {
          this.setState({
            checkKeysForNewFolder: true
          })
        } else {
          this.setState({
            checkKeysForNewFolder: false
          })
        }
        if (response.data.state) {
          this.setState({
            checkedKeys: response.data.result
          })
        } else {
          this.setState({
            //checkedKeys: ['addresses', 'names', 'identifiers', 'locations', 'numbers', 'organisations', 'time']
            checkedKeys: response.data.result
          })
        }
      } else {
        this.setState({
          checkedKeys: response.data.result
        })
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
          console.log(error)
      }
    })
  }

  removeParentCategory = (checkedKeys) => {
    const response = []
    if (checkedKeys && checkedKeys.length) {
      checkedKeys.map((item) => {
        if (item.includes('doc_annotate')) {
          response.push(item)
        }
        return true
      })
    }
    return response
  }
  
  saveChanges = () => {
    const { checkedKeys } = this.state
    const { folder } = this.props
    const newCheckedKeys = this.removeParentCategory(checkedKeys)
    const data = {
      folderPath: folder.path ? folder.path : '/',
      listKeys: newCheckedKeys
    }
    savePiiCategories(data).then(() => {
      //CustomNotificationManager.success('Your selected options are saved.', 'Success')
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
          console.log(error)
      }
    })
    // this.setState({
    //   stateUpdateConfirmSelection: true
    // })
  }

  onCheckUpdateSelection = () => {
    this.setState({
      stateUpdateConfirmSelection: false
    })
  }

  render() {
    const { categoriesTree, expandedKeys, autoExpandParent, checkedKeys, stateUpdateConfirmSelection, checkKeysForNewFolder, categoryVisited } = { ...this.state }
    let { disabled } = this.props
    if(typeof disabled === 'undefined') {
      disabled = false
    }
    return (
      <div className="analytics-page new-analytics-page">
        <Tree
          checkable
          expandedKeys={ expandedKeys }
          defaultExpandedKeys={ expandedKeys }
          autoExpandParent={ autoExpandParent }
          defaultExpandAll={ true }
          onCheck={ this.onCheck }
          disabled={ stateUpdateConfirmSelection || disabled }
          checkedKeys={ checkedKeys }
          selectable={ false }
          className={ 'analytics-tree' }
          onSelect={(selectedKeys, { node }) =>
           disabled ? {} : this.expandHideRow(node) }
        >
          { renderTreeNodesAnalytics(categoriesTree, expandedKeys, this.expandHideCategory, checkedKeys, checkKeysForNewFolder, categoryVisited, disabled) }
        </Tree>
        <div className="analytics-page__action-buttons analytics-action-buttons">
          {/*<Button disabled={ disabled } className={stateUpdateConfirmSelection ? 'analytics-page__action-buttons__update new-button-confirm-action' : 'analytics-page__action-buttons__save new-button-confirm-action'} onClick={stateUpdateConfirmSelection ? this.onCheckUpdateSelection : this.saveChanges} disabled={ !this.state.checkedKeysObjectRefs.length }>{ stateUpdateConfirmSelection ? 'UPDATE SELECTION' : 'CONFIRM SELECTION'}</Button>*/}
        </div>
      </div>
    )
  }
}

export default Analytics