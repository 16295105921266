import React from 'react'
import { Button, Select, Modal, Table } from 'antd'
import { apiFetchClients, apiGlobalResetMfa } from '../../../api/Admin'
import '../Admin.scss'
import './TokenManagement.scss'
import AddEditTokenManagement from './AddEditTokenManagement'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import swal from 'sweetalert2'
import {apiExternalDeleteToken, apiExternalGetTokenList} from "../../../api/SmartBox";
import {customConfirm} from "../../../utils/AssetRegister/CustomConfirm";
import CustomNotificationManager from "../../Common/CustomNotificationManager";

const Option = Select.Option
const { Column } = Table

class TokenManagement extends React.Component {
    state = {
      // tokenListTable: [
      //     {key: 1, email: 'example1', text1: 'text1', text2: 'text2', text3: 'text3', text4: 'text4', text5: 'text5'}
      // ],
        tokenListTable: [],
        visibleAddEditToken: null,
        hoverAdd: false,
        hoverEdit: {},
        hoverDelete: {},
        selectManagementToken: {},
        statusEditableToken: '',
        loadingGetTokenList: false
    }

    componentDidMount() {
       this.onGetExternalGetTokenList()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.visibleAddEditToken !== this.state.visibleAddEditToken && this.state.visibleAddEditToken === false) {
            this.onGetExternalGetTokenList()
        }

    }

    onGetExternalGetTokenList = () => {
        this.setState({
            tokenListTable: [],
            loadingGetTokenList: true
        }, () => {
            apiExternalGetTokenList().then((response) => {
                if (response.status === 200) {
                    this.setState({
                        tokenListTable: response.data,
                        loadingGetTokenList: false,
                    })
                }
            })
        })
    }
    onAddEditTokenManagement = (e, status, record) => {
        if (status === 'add') {
            this.setState({
                visibleAddEditToken: true,
                statusEditableToken: 'add'
            })
        } else {
            this.setState({
                visibleAddEditToken: true,
                statusEditableToken: 'edit',
                selectManagementToken: record
            })
        }
    }
    onActionDeleteToken = (e, status, record) => {
        customConfirm({
            title: <span className="icon-v14-delete"></span>,
            content: `Delete token?`,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            mask: true,
            maskClosable: false,
            closeHaveLogic: false,
            className: 'asset-register-modal modal-delete-connector',
            centered: true,
            cancelClassName: 'cancel-button',
            confirmClassName: 'confirm-button',
            confirmAction: () => {
                this.onDeleteToken(e, status, record)
            }
        })
    }
    onDeleteToken = (e, status, record) => {
        let tokenKeys = []
        tokenKeys.push(record.tokenKey)
        apiExternalDeleteToken(tokenKeys).then((response) => {
            if (response.data.title && response.data.title.toLowerCase() === 'success') {
                CustomNotificationManager.success(response.data.message, 'Success')
            } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
                CustomNotificationManager.info(response.data.message, 'Info')
            }
            this.onGetExternalGetTokenList()
        })
    }
    onHoverAddToken = (e, status, item) => {
        switch (status) {
            case 'add':
                this.setState({
                    hoverAdd: true
                })
                break
            case 'edit':
                this.setState({
                    hoverEdit: item
                })
                break
            case 'delete':
                this.setState({
                    hoverDelete: item
                })
                break
            default: break
        }
    }
    onLeaveAddToken = () => {
        this.setState({
            hoverAdd:false,
            hoverEdit:false,
            hoverDelete: {},
        })
    }
    onBackTableToken = (status) => {
        this.setState({
            visibleAddEditToken: status
        })
    }
    onGetSaveEditToken = (formToken) => {
      const { tokenListTable, statusEditableToken } = this.state
        // if (statusEditableToken === 'add') {
        //     tokenListTable.push({
        //         key: tokenListTable.length + 1,
        //         email: formToken.inputEmail,
        //         //text1: formToken.inputTokenOne,
        //         text1: formToken.valueAllowAccess,
        //         text2: formToken.inputTokenTwo,
        //         text3: formToken.inputTokenThree,
        //         text4: formToken.inputTokenFour,
        //         text5: 'text5'
        //     })
        //     this.setState({
        //         tokenListTable
        //     })
        // } else {
        //     tokenListTable && tokenListTable.length > 0 && tokenListTable.forEach((item) => {
        //         if (item.key === formToken.key) {
        //             item.email = formToken.inputEmail,
        //             //item.text1 = formToken.inputTokenOne,
        //             item.text1 = formToken.valueAllowAccess,
        //             item.text2 = formToken.inputTokenTwo,
        //             item.text3 = formToken.inputTokenThree,
        //             item.text4 = formToken.inputTokenFour,
        //             item.text5 = 'text5'
        //         }
        //     })
        //     this.setState({
        //         tokenListTable
        //     })
        // }
        //this.onGetExternalGetTokenList()
    }
    onGetCancelToken = () => {
       this.setState({
           visibleAddEditToken: false
       })
    }
    render() {
        const { tokenListTable, visibleAddEditToken, hoverAdd, hoverEdit, selectManagementToken, statusEditableToken, hoverDelete, loadingGetTokenList } = this.state

        return (
           <div className="token-management">
               { visibleAddEditToken ?
                   <div className="token-management__page">
                   <AddEditTokenManagement onBackTableToken={ this.onBackTableToken } selectManagementToken={selectManagementToken}
                                           statusEditableToken={ statusEditableToken }
                                           onGetSaveEditToken={ this.onGetSaveEditToken }
                                           onGetCancelToken={ this.onGetCancelToken }
                                           tokenListTable={ tokenListTable }
                   />
                   </div>
                   :
                   <div className="token-management__page">
                       <div className="header-token">
                       <span className={hoverAdd ? "icon-v17-add-active" : "icon-v17-add"}
                             onMouseEnter={(e) => this.onHoverAddToken(e, 'add')}
                             onMouseLeave={() => this.onLeaveAddToken()}
                             onClick={(e) => this.onAddEditTokenManagement(e, 'add')}></span>
                       </div>
                       <div className="token-management-table">
                           <Table
                               dataSource={tokenListTable}
                               pagination={false}
                               loading={ loadingGetTokenList }
                               className="custom-table new-custom-table">
                               <Column title='email'
                                       dataIndex="email" key="email" render={(text, record) => {
                                   return (<span> {text} </span>)
                               }}/>
                               <Column title='Tenant name'
                                       dataIndex="tenant" key="tenant" render={(text, record) => {
                                   return (<span> {text}</span>)
                               }}/>
                               <Column title='Creation date'
                                       dataIndex="creationDate" key="creationDate" render={(text, record) => {
                                   return (<span> {text}</span>)
                               }}/>
                               <Column title='Expiration date'
                                       dataIndex="expirationDate" key="expirationDate" render={(text, record) => {
                                   return (<span> {text}</span>)
                               }}/>
                               <Column title='Active'
                                       dataIndex="tokenIsActive" key="tokenIsActive" render={(text, record) => {
                                   return (<span> {text ? 'true' : 'false'}</span>)
                               }}/>
                               <Column title='Token key'
                                       dataIndex="tokenKey" key="tokenKey" render={(text, record) => {
                                   return (<span> {text}</span>)
                               }}/>
                               <Column title='action'
                                       dataIndex="edit" key="edit" render={(text, record) => {
                                   return (<span className={ hoverEdit.tokenKey === record.tokenKey ? "icon-v21-edit-active" : 'icon-v21-edit'}
                                                 onMouseEnter={(e) => this.onHoverAddToken(e, 'edit', record)}
                                                 onMouseLeave={() => this.onLeaveAddToken()}
                                                 onClick={(e) => this.onAddEditTokenManagement(e, 'edit', record)}></span>)
                               }}/>
                               <Column title='action'
                                       dataIndex="delete" key="delete" render={(text, record) => {
                                   return (<span className={ hoverDelete.tokenKey === record.tokenKey ? "icon-v19-delete-active" : 'icon-v19-delete'}
                                                 onMouseEnter={(e) => this.onHoverAddToken(e, 'delete', record)}
                                                 onMouseLeave={() => this.onLeaveAddToken()}
                                                 onClick={(e) => this.onActionDeleteToken(e, 'delete', record)}></span>)
                               }}/>
                           </Table>
                       </div>
                   </div>
               }
           </div>
        )
    }
}

const stateMap = (state) => ({
    user: state.userReducer.user
})

TokenManagement.propTypes = {
    user: PropTypes.object.isRequired
}

export default connect(stateMap)(TokenManagement)