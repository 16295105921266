import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link, Navigate } from 'react-router-dom'
import iconUser from '../../../assets/icon-user.png'
import logoGravicus from '../../../assets/Gravicus_Smartbox_Logo.svg'
import { userLogout } from '../../../actions/userActions'
import '../../Common/Header.scss'
import { Layout, Icon } from 'antd'
import { connect } from 'react-redux'
import { removeAllIntervalsFromApplication } from '../../../actions/applicationsActions'
import imgNewNavBox from '../../../assets/Key visual 40x40.svg'
import withRouter from '../../../private-routers/withRouter'
const { Header } = Layout

export class AdminHeader extends Component {
  constructor() {
    super()
    this.state = {
      isMenuOpened: false
    }
    this.toggleMenu = this.toggleMenu.bind(this)
    this.handleOutsideClick = this.handleOutsideClick.bind(this)
  }

  getName(user) {
    const name = user.user_metadata.name || user.name
    if (name) {
      const [firstName, ] = name.split(' ')
      return firstName
    }
  }

  getCompany(user) {
    if (user.user_metadata) {
      return user.user_metadata.companyName
    }
    return ''
  }
  toggleMenu() {
    if (!this.state.isMenuOpened) {
      document.addEventListener('click', this.handleOutsideClick, false)
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false)
    }
    this.setState({
      isMenuOpened: !this.state.isMenuOpened,
    })
  }
  handleOutsideClick(e) {
    if (this.node && this.node.contains(e.target)) {
      return
    }
    this.toggleMenu()
  }
  doLogout() {
    for (let i = 0; i < this.props.applicationIntervals.length; i++) { clearInterval(i) }
    this.props.removeAllIntervalsFromApplication([])
    this.props.userLogout()
    this.props.navigate('/osp/login')
    // window.location.href = '/osp/login'
}

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    }, () => this.props.toggleSidebar())
  };

  render() {
    const { isSidebarOpened, user, mobileMode } = this.props
    const { isMenuOpened } = this.state
    return (
      <Header style={{ background: 'rgb(236 236 236)', padding: 0 }}>
        <div className="header-logo">
          {mobileMode ? '' : <Icon
            className="trigger"
            type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
            onClick={() => this.toggle()}
          />
          }
          <div className="header-logo-text">
            <span className="header-powered">powered by</span>
            <img className="header-logoImage" src={ logoGravicus } alt="Gravicus" />
          </div>
        <div className="header-logo-and-navigation" style={{display: 'flex', justifyContent:'start'}}>
          <div className="header-logo" >
            <img src={ imgNewNavBox } alt={ imgNewNavBox } onClick={() => this.props.navigate('/smart-box/management')} className="icon-gravicus"/>
          </div>
        </div>
        </div>
        <div className="header-infoContainer" data-hj-suppress>
          <div className="header-user" ref={(node) => { this.node = node }} onClick={ this.toggleMenu }>
            <div className="header-userIcon">
              <img className="header-userIconImage" src={ iconUser } alt="Icon user" />
            </div>
            <div className="header-userInfo" style={{display:'flex', flexDirection: 'column'}}>
              <div>logged in as</div>
              <div className="header-userName" style={{color:'black', marginTop:"5px"}}>{ this.getName(user) }</div>
            </div>
            { isMenuOpened &&
              <ul className="header-menu">
                <li className="header-listItem"><Link className="header-link" to="/osp/user/settings">Profile Settings</Link></li>
                <li className="header-listItem"><button id="action-logout" className="header-link" onClick={ () => this.doLogout() }>Log Out</button></li>
              </ul>
            }
          </div>
          <button
            className={ isSidebarOpened ? 'header-sidebarOpenedToggle' : 'header-toggle' }
            onClick={ () => this.props.toggleSidebar() }>
              Toggle sidebar
          </button>
        </div>
      </Header>
    )
  }
}
AdminHeader.propTypes = {
  toggleSidebar: PropTypes.func.isRequired
}

AdminHeader = withRouter(AdminHeader)

const mapStateToProps = (state) => ({
  applicationIntervals: state.applications.applicationIntervals
})

export default connect(mapStateToProps, { removeAllIntervalsFromApplication, userLogout })(AdminHeader)
