import PropTypes from 'prop-types'
import React from 'react'
import AddQuestionBuilder from './Helpers/AddQuestionBuilder'
import RemoveQuestion from './Helpers/RemoveQuestion'
import QuestionnaireQuestionForm from './QuestionnaireQuestionForm'

class QuestionnaireQuestionsList extends React.Component {
  constructor() {
    super()

    this.addNewQuestion = this.addNewQuestion.bind(this)
    this.removeQuestion = this.removeQuestion.bind(this)
  }

  componentDidMount() {
    if (this.props.questions.length > 0) return
    this.defaultQuestions()
  }

  defaultQuestions() {
    let questions = []
    questions = AddQuestionBuilder({ questions, currentOrder: 0 })
    questions = AddQuestionBuilder({ questions, currentOrder: 1 })

    this.props.updateQuestionsList({
      questions,
      deletedQuestions: this.props.deletedQuestions
    })
    this.props.toggleAccordion(1)
  }

  addNewQuestion(currentOrder) {
    const newQuestions = AddQuestionBuilder({
      questions: this.props.questions,
      currentOrder
    })
    this.props.updateQuestionsList({
      questions: newQuestions,
      deletedQuestions: this.props.deletedQuestions
    })
    this.props.toggleAccordion(currentOrder + 1)
  }

  removeQuestion(index) {
    const newQuestionsList = RemoveQuestion({
      questions: this.props.questions,
      deletedQuestions: this.props.deletedQuestions,
      index
    })
    this.props.updateQuestionsList(newQuestionsList)
  }

  render() {
    const {
      toggleAccordion,
      questionsAccordion,
      questions,
      questionnaireType,
      updateQuestion
    } = this.props

    return (
      <React.Fragment>
        {
          questions.map((question, index) => (
            <QuestionnaireQuestionForm
              key={ `question-${ index }` }
              questionIndex={ index }
              questionnaireType={ questionnaireType }
              accordion={ questionsAccordion }
              addNewQuestion={ this.addNewQuestion }
              removeQuestion={ this.removeQuestion }
              toggleAccordion={ toggleAccordion }
              updateQuestion={ updateQuestion }
              question={ question }
              questions={ questions }
            />
          ))
        }
      </React.Fragment>
    )
  }
}

QuestionnaireQuestionsList.propTypes = {
  toggleAccordion: PropTypes.func.isRequired,
  questionsAccordion: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired,
  questionnaireType: PropTypes.string,
  deletedQuestions: PropTypes.array.isRequired,
  updateQuestion: PropTypes.func.isRequired,
  updateQuestionsList: PropTypes.func.isRequired,
}

export default QuestionnaireQuestionsList