import React from 'react'

import Loading from '../../../Common/Loading'

import {
  apiOspreyGetCrawlerProcessorTemplateConfigurations
} from '../../../../api/OspreyAnalytics.js'

import EditInPlace from '../../../Privacy/Form/EditInPlace'

import './DataLineBuilder.css'
import { apiPrivacyAddNewCrawler } from '../../../../api/Privacy'

class DataLineBuilder extends React.Component {
  state = {
  }

  componentDidMount = () => {
    this.getTemplates()

    if (this.props.dataline)
    {
      this.setState({
        dataline: this.props.dataline,
        operation: 'edit'
      })
    }
    else
    {
      this.setState({
        dataline: this.createBaseConfig(),
        operation: 'new'
      })
    }
  }

  createBaseConfig = () => {
    return {
      pipeline: [
        {
          properties: {
            include_mimetype: {
              propertyName: 'include_mimetype',
              values: [
                'true'
              ]
            },
            crawlername: {
              propertyName: 'crawlername',
              values: [
                'New Dataline'
              ]
            }
          },
          id: '1000'
        },
        {
          properties: {
          },

        },
        {
          properties: {
          },
          id: '3000'
        },
        {
          properties: {
            doc_datasource_type: {
              propertyName: 'doc_datasource_type',
              values: [
                'File System'
              ]
            },
            doc_datasource_name: {
              propertyName: 'doc_datasource_name',
              values: [
                'New Dataline'
              ]
            }
          },
          id: '3003'
        },
        {
          properties: {
          },
          id: '4000'
        }
      ],
      baseConfig: {
        properties: {
          crawlername: {
            propertyName: 'name',
            values: [
              'New Dataline'
            ]
          }
        }
      }
    }
  }

  getTemplates = () => {
    apiOspreyGetCrawlerProcessorTemplateConfigurations()
      .then((response) => {

        const baseTemplate = response.data.configBean
        baseTemplate.id = 'base'

        const pipeLineProcessorTemplates = response.data.configBeanList
        pipeLineProcessorTemplates['base'] = baseTemplate

        const connectorTemplates = this.filterByType(pipeLineProcessorTemplates, 'connector')
        const processorTemplates = this.filterByType(pipeLineProcessorTemplates, 'processor')
        const senderTemplates = this.filterByType(pipeLineProcessorTemplates, 'sender')

        this.setState({
          pipeLineProcessorTemplates: pipeLineProcessorTemplates,
          connectorTemplates: connectorTemplates,
          processorTemplates: processorTemplates,
          senderTemplates: senderTemplates,
          baseTemplate: baseTemplate
        })
      })
  }

  filterByType = (list, type) => {
    return list.filter((processor) => (processor.properties.type.values[0] === type))
  }
  filterByID = (list, id) => {
    return list.filter((processor) => (processor.id === id))
  }

  filterConfigurableOnly = (propertyList) => {
    return propertyList.filter((property) => (property.configurable))
  }

  setName = (value) => {
    const dataline = this.state.dataline
    dataline.baseConfig.properties.crawlername.values = [value]

    this.setState({
      dataline: dataline
    })

  }

  objListToArray = (objList) => {
    const arr = []
    Object.keys(objList).forEach(function(key) {
      arr.push(objList[key])
    })
    return arr
  }

  savePropertyValue = (event, processor, propertyName) => {
    const dataline = this.state.dataline

    if (processor === 'base')
    {
      if (dataline.baseConfig.properties[propertyName])
      {
        // value already edited so present in the config
        dataline.baseConfig.properties[propertyName].values = [event.target.value]
      }
      else
      {
        // no value in the pipe as currently default
        dataline.baseConfig.properties[propertyName] = {
          propertyName: propertyName,
          values: [event.target.value]
        }
      }
    }
    else if (dataline.pipeline[processor].properties[propertyName])
    {
      // value already edited so present in the config
      dataline.pipeline[processor].properties[propertyName].values = [event.target.value]
    }
    else
    {
      // no value in the pipe as currently default
      dataline.pipeline[processor].properties[propertyName] = {
        propertyName: propertyName,
        values: [event.target.value]
      }
    }

    this.setState({
      dataline: dataline
    })
  }

  getValue= (options, values, processorindex, propertyName) => {
    let conf = null

    if (processorindex === 'base')
    {
      conf = this.state.dataline.baseConfig
    }
    else
    {
      conf = this.state.dataline.pipeline[processorindex]
    }

    // first get whatever value has been set in the pipeline
    if (conf.properties[propertyName])
    {
      return conf.properties[propertyName].values[0]
    }

    // next the default value from the template
    if (values.length > 0)
    {
      return values[0]
    }

    // then the default option
    const result = options.filter((option) => (option.default))
    if (result.length > 0)
    {
      return result[0]
    }

    // then the first option
    if (options.length > 0)
    {
      return options[0]
    }

    // finally, empty string
    return ''
  }

  getProcessorOptions(processorindex) {
    if (!this.state.connectorTemplates)
    {
      return <option>Loading...</option>
    }

    if (processorindex === 0)
    {
      return this.state.connectorTemplates.map((processor, connectorindex) => (
        <option key={`typedropdown_${ processorindex }_${ connectorindex }`} value={processor.id}>{processor.properties.processorname.values[0]}</option>
      ))
    }
    if (processorindex === this.state.dataline.pipeline.length-1)
    {
      return this.state.senderTemplates.map((processor, connectorindex) => (
        <option key={`typedropdown_${ processorindex }_${ connectorindex }`} value={processor.id}>{processor.properties.processorname.values[0]}</option>
      ))
    }
    return this.state.processorTemplates.map((processor, connectorindex) => (
      <option key={`typedropdown_${ processorindex }_${ connectorindex }`} value={processor.id}>{processor.properties.processorname.values[0]}</option>
    ))
  }

  selectNewProcessor = (processorindex, processorid) => {
    const dataline = this.state.dataline

    if (dataline.pipeline[processorindex].id !== processorid)
    {
      dataline.pipeline[processorindex] = {
        properties: {
        },
        'id': processorid
      }

      // processorid hasn't changed
      this.setState({
        dataline: dataline
      })
    }

  }

  revertToDefaultValue = (processorindex, propertyName) => {
    const dataline = this.state.dataline

    if (processorindex === 'base')
    {
      dataline.baseConfig.properties[propertyName] = undefined
    }
    else
    {
      dataline.pipeline[processorindex].properties[propertyName] = undefined
    }

    this.setState({
      dataline: dataline
    })
  }

  isEdited = (processorIndex, propertyName) => {
    let conf = null
    let template = null

    if (processorIndex === 'base')
    {
      conf = this.state.dataline.baseConfig
      template = this.state.baseTemplate
    }
    else
    {
      conf = this.state.dataline.pipeline[processorIndex]
      template = this.filterByID(this.state.pipeLineProcessorTemplates, conf.id)[0]
    }

    return (
      conf.properties[propertyName] &&
      conf.properties[propertyName] !== null &&
      conf.properties[propertyName].values &&
      conf.properties[propertyName].values[0] !== template.properties[propertyName].values[0]
    )
  }

  removeProcessor(processorIndex)
  {
    const dataline = this.state.dataline
    dataline.pipeline.splice(processorIndex, 1)

    this.setState({
      dataline: dataline
    })
  }

  addProcessorBefore(processorIndex)
  {
    const newProcessor = {
      properties: {
      }
    }
    const dataline = this.state.dataline
    dataline.pipeline.splice(processorIndex, 0, newProcessor)

    this.setState({
      dataline: dataline
    })
  }

  print = (obj) => {
  }

  saveCrawler = () => {
    apiPrivacyAddNewCrawler('Master', this.state.dataline)
      .then((response) => {
      })
  }
  render = () => {

    return (
      <div className="datalineBuilder__wrapper">

        <header className="datalineBuilder__header">
          <h1 className="datalineBuilder__title">
            { this.state.dataline
              ? <EditInPlace
                value={ this.state.dataline.baseConfig.properties.crawlername.values[0] }
                save={ this.setName } />
              : <Loading/>
            }
          </h1>
        </header>
        <div className="datalineBuilder__content">
          <div className="datalineBuilder__wrapperTable">
            { this.state.baseTemplate
              ? <div className="datalineBuilder__processor">
                <div className="datalineBuilder__processorType">Base Settings</div>
                { this.filterConfigurableOnly(this.objListToArray(this.state.baseTemplate.properties)).map((property) => (
                  <div
                    key={`processorproperty_base_${ property.propertyName }`}
                    className={ `datalineBuilder__processorProperty${ this.isEdited('base', property.propertyName) ? '_edited' : '' }` }>
                    <div>
                      <div className="datalineBuilder__propertyname">{property.propertyName}</div>
                      <div className="datalineBuilder__propertyvalue">
                        { property.options && this.objListToArray(property.options).length > 0
                          ? <select
                            value={this.getValue(this.objListToArray(property.options), property.values, 'base', property.propertyName)}
                            onChange={(e) => this.savePropertyValue(e, 'base', property.propertyName)} >
                            { this.objListToArray(property.options).map((option, optionindex) => (
                              <option
                                key={`processorproperty_base_${ property.propertyName }_${ optionindex }`}
                                value={ option.value }>{ option.value }</option>
                            ))
                            }
                          </select>
                          : <input
                            type="text"
                            onChange={(e) => this.savePropertyValue(e, 'base', property.propertyName)}
                            value={ this.getValue(this.objListToArray(property.options), property.values, 'base', property.propertyName) }/>
                        }
                      </div>
                      { this.isEdited('base', property.propertyName)
                        ? <div className="datalineBuilder__processorRevert" onClick={(e) => this.revertToDefaultValue('base', property.propertyName) }>Revert to default</div>
                        : <div/>
                      }
                    </div>
                  </div>
                ))
                }
              </div>
              : <Loading/>
            }
            { this.state.dataline
              ? <div> { this.state.dataline.pipeline.map((processor, processorindex) => (
                <div key={`processor_${ processorindex }_${ this.state.dataline.pipeline[processorindex].id ? this.state.dataline.pipeline[processorindex].id : '' }`} className="datalineBuilder__processor">
                  <div className="datalineBuilder__processorType">
                    { processorindex === 0
                      ? <span>Connector: </span>
                      : <span/>
                    }
                    { processorindex > 0 && processorindex < (this.state.dataline.pipeline.length-1)
                      ? <span>Processor: </span>
                      : <span/>
                    }
                    { processorindex > 0 && processorindex < (this.state.dataline.pipeline.length-1)
                      ? <div className="datalineBuilder__addRemoveProcessor">
                        <div className="datalineBuilder__addProcessor" onClick={(e) => this.addProcessorBefore(processorindex)}>Add Before</div>
                        <div className="datalineBuilder__removeProcessor" onClick={(e) => this.removeProcessor(processorindex)}>Remove</div>
                      </div>
                      : <div/>
                    }

                    { processorindex === (this.state.dataline.pipeline.length-1)
                      ? <span>Sender: </span>
                      : <span/>
                    }
                    { processorindex === (this.state.dataline.pipeline.length-1)
                      ? <div className="datalineBuilder__addRemoveProcessor">
                        <div className="datalineBuilder__addProcessor" onClick={(e) => this.addProcessorBefore(processorindex)}>Add Before</div>
                      </div>
                      : <div/>
                    }
                    { this.state.pipeLineProcessorTemplates
                      ? <select
                        onChange={(e) => this.selectNewProcessor(processorindex, e.target.value) }
                        value={ this.state.dataline.pipeline[processorindex].id ? this.state.dataline.pipeline[processorindex].id : '' }>
                        { this.getProcessorOptions(processorindex) }
                      </select>
                      : <Loading/>
                    }
                  </div>
                  { processor.id && this.state.pipeLineProcessorTemplates
                    ? <div>
                      { this.filterConfigurableOnly(this.objListToArray(this.filterByID(this.state.pipeLineProcessorTemplates, processor.id)[0].properties)).map((property) => (
                        <div
                          key={`processorproperty_${ processorindex }_${ property.propertyName }`}
                          className={ `datalineBuilder__processorProperty${ this.isEdited(processorindex, property.propertyName) ? '_edited' : '' }` }>
                          <div>
                            <div className="datalineBuilder__propertyname">{property.propertyName}</div>
                            <div className="datalineBuilder__propertyvalue">
                              { property.options && this.objListToArray(property.options).length > 0
                                ? <select
                                  value={this.getValue(this.objListToArray(property.options), property.values, processorindex, property.propertyName)}
                                  onChange={(e) => this.savePropertyValue(e, processorindex, property.propertyName)} >
                                  { this.objListToArray(property.options).map((option, optionindex) => (
                                    <option
                                      key={`processorproperty_${ processorindex }_${ property.propertyName }_${ optionindex }`}
                                      value={ option.value }>{ option.value }</option>
                                  ))
                                  }
                                </select>
                                : <input
                                  type="text"
                                  onChange={(e) => this.savePropertyValue(e, processorindex, property.propertyName)}
                                  value={ this.getValue(this.objListToArray(property.options), property.values, processorindex, property.propertyName) }/>
                              }
                            </div>
                            { this.isEdited(processorindex, property.propertyName)
                              ? <div className="datalineBuilder__processorRevert" onClick={(e) => this.revertToDefaultValue(processorindex, property.propertyName) }>Revert to default</div>
                              : <div/>
                            }
                          </div>
                        </div>
                      ))
                      }
                    </div>
                    : <div className="datalineBuilder__propertyempty">Please select a Processor Type</div>
                  }
                </div>
              ))}
              </div>
              : <Loading/>
            }
          </div>
        </div>
        <div className="datalineItem__content">
          <div>
            <button className="connectorCard__buttonRescan" onClick={() => this.saveCrawler()}>Save</button>
          </div>
        </div>
      </div>
    )
  }
}

DataLineBuilder.propTypes = {
}

export default DataLineBuilder