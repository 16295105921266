import React, { Component } from 'react'
import iconBlog from '../../../assets/icon-blog.png'
import iconWhitepaper from '../../../assets/icon-whitepaper.png'
import Area from '../../Common/Area'
import Button from '../../Common/Button'
import Post from './Post'
import './Resources.css'

export class Resources extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)

  }

  render() {
    const whitepapers = [{
      title: '12 key questions to help prepare for GDPR',
      date: 'December, 06, 2017',
      url: 'https://resources.gravicus.com/5311_Gravicus_Whitepaper_12_Questions_WEB.pdf'
    }, {
      title: 'GDPR and the Sales Team',
      date: 'December, 06, 2017',
      url: 'https://resources.gravicus.com/5308_Gravicus_Whitepaper_GDPR_and_the_Sales_Team_WEB.pdf'
    }, {
      title: 'A Marketer’s Guide to GDPR',
      date: 'December, 06, 2017',
      url: 'https://resources.gravicus.com/5310_Gravicus_Whitepaper_GDPR_Marketing_WEB.pdf'
    }]

    const whitepapers2 = [{
      title: 'GDPR -  An Executive Summary for CEOs',
      date: 'November, 15, 2017',
      url: 'http://resources.ospreysmart.com/Gravicus_whitepaper_GDPR_An_Executive_Summary_for_CEOs.pdf'
    }, {
      title: 'GDPR – What you need to know…',
      date: 'November, 15, 2017',
      url: 'http://resources.ospreysmart.com/Gravicus_whitepaper_GDPR_What_you_need_to_know.pdf'
    }, {
      title: 'GDPR for small businesses',
      date: 'November, 15, 2017',
      url: 'http://resources.ospreysmart.com/Gravicus_whitepaper_GDPR_for_small_businesses.pdf'
    }]

    const articles = [{
      title: 'Difficult data made easy with Gravicus data lines.',
      date: 'September, 28, 2017',
      url: 'http://gravicus.com/difficult-data-made-easy/'
    }, {
      title: 'Privacy, PII and GDRP: The quest for the unknown.',
      date: 'September, 18, 2017',
      url: 'http://gravicus.com/privacy/'
    }]

    return (
      <div className="resources-page">
        <h1 className="resources-title">Osprey Resource Centre</h1>

        <div className="resources-container">
          <div className="resources-containerBox">
            <img className="resources-boxIconWhitepaper" src={ iconWhitepaper } alt="Icon whitepaper" />
            <header className="resources-boxHeader">
              <h1 className="resources-boxTitle">Whitepapers</h1>
            </header>

            { whitepapers.length > 0 &&
              whitepapers.map((item, index) => (
                <div key={ index } className="resources-item">
                  <Post content={ item } />
                </div>
              ))
            }
          </div>

          <div className="resources-containerBox">
            <img className="resources-boxIconWhitepaper" src={ iconWhitepaper } alt="Icon whitepaper" />
            <header className="resources-boxHeader">
              <h1 className="resources-boxTitle">Whitepapers</h1>
            </header>

            { whitepapers2.length > 0 &&
              whitepapers2.map((item, index) => (
                <div key={ index } className="resources-item">
                  <Post content={ item } />
                </div>
              ))
            }
          </div>

          <div className="resources-containerBox">
            <img className="resources-boxIconBlog" src={ iconBlog } alt="Icon blog" />
            <header className="resources-boxHeader">
              <h1 className="resources-boxTitle">From our Blog</h1>
            </header>
            { articles.length > 0 &&
              articles.map((item, index) => (
                <div key={ index } className="resources-item">
                  <Post content={ item } />
                </div>
              ))
            }

            <div className="resources-boxButton">
              <a href="https://insights.gravicus.com/blog" target="_blank" rel="noopener noreferrer">
                <Button>Read more</Button>
              </a>
            </div>
          </div>
        </div>

        <div className="resources-areasBox">
          <header className="resources-areasHeader">
            <h1 className="resources-areasTitle">Areas of Risk</h1>
          </header>

          <div className="resources-content">
            <div className="resources-block">
              <Area
                description='Risks related to a sensitive market (i.e. elderly, children, etc.) and/or sensitive data (i.e. health, finance).'
                icon='Sensitivity'
                title='Sensitivity (SEN)'
              />

              <Area
                description='Risks related to compliance with external standards, policies, laws, etc.'
                icon='Compliance'
                title='Compliance (C)'
              />

              <Area
                description='Risks related to transparency in the areas of notice/user messaging and choice/consent.'
                icon='Transparency'
                title='Transparency (T)'
              />

              <Area
                description='Risks related to security of data and data flows.'
                icon='Security'
                title='Security (SEC)'
              />
            </div>

            <div className="resources-block">
              <Area
                description='Risks related to transfer of information across national borders.'
                icon='Transborder'
                title='Trans-border data flow (TB)'
              />

              <Area
                description='Risks related to control of the data lifecycle (i.e., collection, usage, quality, and/or retention).'
                icon='DataControl'
                title='Data control (DC)'
              />

              <Area
                description='Risks related to sharing data with third parties.'
                icon='DataSharing'
                title='Data sharing (DS)'
              />
            </div>
          </div>

        </div>
      </div>
    )
  }
}


export default Resources