import React from "react";
import {Button, Input, Modal, Checkbox, Empty, Tooltip, Select, DatePicker} from 'antd'
import './BusinessProcessPage.scss';
import {
    apiEditBusinessProcessComponents,
    getBusinessProcessWithComponents, getBusinessProcessWithComponentsId,
    getSourceMappingListSimple
} from "../../api/SmartBox";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import HeaderProcessing from './HeaderProcessing'
import CustomNotificationManager from "../Common/CustomNotificationManager";
import {customConfirm} from "../../utils/AssetRegister/CustomConfirm";
const { Option } = Select;

class BusinessProcessBuilder extends React.Component {
    state = {
        formBusinessBuilder: {
            // connectorsSelect: [{
            //     valueDataSourceSelect: null,
            // }],
            connectorsSelect:[],
            proposedCategories: [],
            listHeaderComponents: []
        },
        visibleDataSource: false,
        listOptionDataSource: [{key: 1, name: "value1"},
            {key: 2, name: "value2"}],
        listConnectors: [],
        dataSource: [],
        targetKeys: [],
        actionProvidedDraggable: {},
        onHoverBackIcon: false,
        onHoverAddDataSource: false,
        onHoverRemoveDataSource: {},
        onHoverAddCategories: false,
        onHoverRemoveCategories: {},
        onHoverAddActivities: false,
        dataConnectLocationList: [{id: 1, name: 'example1'}, {id: 2, name: 'example2'}]

    }
    componentDidMount() {
       this.onGetSourceMappingListSimple()
    }

    onGetSourceMappingListSimple = () => {
        getSourceMappingListSimple(this.props.clientId).then((response) => {
            this.setState({
                listConnectors: response.data ? response.data : [],
            }, () => {
                this.getDataSource()
            })
        })
    }

    onGetBusinessProcessWithComponents = () => {
        const { selectBusiness } = this.props
        const { formBusinessBuilder, dataSource } = this.state
        const connectorsSelectGet = []
        const listHeaderComponentsGet = []
        const queries =[]
        getBusinessProcessWithComponentsId(selectBusiness.id).then((response) => {
            dataSource && dataSource.length > 0 && dataSource.forEach((item) => {
                if (response.data.dataSourceList && response.data.dataSourceList.length > 0 && response.data.dataSourceList.includes(item.sourceMappingId)) {
                    connectorsSelectGet.push({
                        key: item.key,
                        nameSelect: item.title,
                        sourceMappingId: item.sourceMappingId,
                        valueDataSourceSelect: item.description,
                        sourceMappingDataHoldingKind: item.sourceMappingDataHoldingKind,
                    })
                    item.disabledStatus = true
                } else {
                    item.disabledStatus = false
                }
            })
            response.data && response.data.businessProcessComponentDTOS && response.data.businessProcessComponentDTOS.length > 0 && response.data.businessProcessComponentDTOS.forEach((item2) => {
                listHeaderComponentsGet.push({
                    header: item2.componentHeader,
                    key: item2.componentPosition,
                    id: item2.componentType,
                    valueInput: item2.componentText,
                })
            })

            response.data && response.data.businessProcessQueries && response.data.businessProcessQueries.length >0 && response.data.businessProcessQueries.forEach(e=>{
                queries.push({
                    id: e.id,
                    query: e.query,
                    key:e.id,
                })
            })

            const uniqueListHeaderComponentsGet = listHeaderComponentsGet && listHeaderComponentsGet.length > 0 && listHeaderComponentsGet.filter((a, i) => listHeaderComponentsGet.findIndex((s) => a.key === s.key) === i)
            this.setState({
                formBusinessBuilder: {...this.state.formBusinessBuilder, connectorsSelect: connectorsSelectGet, listHeaderComponents: uniqueListHeaderComponentsGet ? uniqueListHeaderComponentsGet : [], proposedCategories: queries }
            })
        })
    }
    getDataSource = () => {
        const { listConnectors } = this.state
        const targetKeysDataSource = []
        const targetKeys = []
        const dataSource = []
        listConnectors && listConnectors.length > 0 && listConnectors.forEach((item, index) => {
            const connectorList = []
            const connectorListUpdate = []
            const connectorDataList = []
            const connectorDataListUpdate = []
            item.connectorList &&  item.connectorList.length > 0 &&  item.connectorList.forEach((item2) => {
                connectorList.push(item2.connectorProvider)
                connectorListUpdate.push({
                    name: item2.connectorProvider,
                    id: item2.connectorId,
                    type: 'connectorList'
                })
            })
            item.dataLocationList &&  item.dataLocationList.length > 0 && item.dataLocationList.forEach((item2) => {
                connectorDataList.push(item2.text)
                connectorDataListUpdate.push({
                    name: item2.text,
                    id: item2.id,
                    type: 'dataLocationList'
                })
            })
            const data = {
                key: index.toString(),
                //title: item.sourceMappingDataHolding && item.dataHoldingInfoList.length > 0 && item.dataHoldingInfoList[0].text ? item.dataHoldingInfoList[0].text : '',
                title: item.sourceMappingDataHolding ? item.sourceMappingDataHolding : '',
                description: item.dataHoldingInfoList && item.dataHoldingInfoList.length > 0 && item.dataHoldingInfoList[0].text ? item.dataHoldingInfoList[0].text : '',
                dataSourceConnector: connectorList.concat(connectorDataList),
                dataSourceConnectorUpdate: connectorListUpdate.concat(connectorDataListUpdate),
                sourceMappingId: item.sourceMappingId,
                disabledStatus: false,
                sourceMappingDataHoldingKind: item.sourceMappingDataHoldingKind,
                //chosen: Math.random() * 2 > 1,
            }
            dataSource.push(data)
        })
        this.setState({ dataSource, targetKeys }, () => {
            this.onGetBusinessProcessWithComponents()
        })
    }

    onAddRemoveDataSource = (e, arg, index, item) => {
        const { dataSource } = this.state
        this.setState({
            visibleDataSource: true
        })
        if (arg === 'add') {
            const settings = this.state.formBusinessBuilder
            const { connectorsSelect } = settings
            connectorsSelect.push({
                valueDataSourceSelect: null,
                key: connectorsSelect.length
            })
            this.setState({
                formBusinessBuilder: {
                    ...settings,
                    connectorsSelect
                },
                hoverBtnAddConnector:false,
            })
        } else {
            const settings = this.state.formBusinessBuilder
            let { connectorsSelect } = settings
            dataSource && dataSource.length > 0 && dataSource.forEach((item2) => {
                if (item.sourceMappingId === item2.sourceMappingId) {
                    item2.disabledStatus = false
                }

            })
            connectorsSelect = connectorsSelect.filter((sPermission, key) => sPermission.key !== item.key)
            connectorsSelect && connectorsSelect.length > 0 && connectorsSelect.forEach((item, index) => {
                item.key = index
            })
            this.setState({
                formBusinessBuilder: {
                    ...settings,
                    connectorsSelect
                },
            })
        }
    }

    onAddRemoveProposedCategories = (e, arg, index, item) => {
        if (arg === 'add') {
            const settings = this.state.formBusinessBuilder
            const { proposedCategories } = settings
            proposedCategories.push({
                query: null,
                id: null,
                key: proposedCategories.length
            })
            this.setState({
                formBusinessBuilder: {
                    ...settings,
                    proposedCategories
                },
            })
        } else {
            const settings = this.state.formBusinessBuilder
            let { proposedCategories } = settings

            // if(item.id !== null){
            //     proposedCategories = proposedCategories.filter((sPermission, key) => sPermission.id === null ||  sPermission.id !== item.id)
            //     proposedCategories && proposedCategories.length > 0 && proposedCategories.forEach((item, index) => {
            //         item.key = index
            //     })
            // }else{
            //     proposedCategories = proposedCategories.filter((sPermission, key) => sPermission.key !== item.key)
            //     proposedCategories && proposedCategories.length > 0 && proposedCategories.forEach((item, index) => {
            //         item.key = index
            //     })
            // }
            proposedCategories = proposedCategories.filter((sPermission, key) => sPermission.key !== item.key)
                proposedCategories && proposedCategories.length > 0 && proposedCategories.forEach((item, index) => {
                   item.key = index
                })

            this.setState({
                formBusinessBuilder: {
                    ...settings,
                    proposedCategories
                },
            })
        }
    }

    addDisabled = (e) => {
        e.preventDefault()
    }

    onChangeSelectDataSource = (e, key) => {
        const { listOptionDataSource, dataSource } = this.state
        const settings = this.state.formBusinessBuilder
        const { connectorsSelect } = settings
        dataSource && dataSource.length > 0 && dataSource.forEach((item2) => {
            if (e === item2.key) {
                connectorsSelect[key].nameSelect = item2.title
                connectorsSelect[key].valueDataSourceSelect = item2.title
                connectorsSelect[key].key = item2.key
                connectorsSelect[key].sourceMappingId = item2.sourceMappingId
                connectorsSelect[key].disabledStatus = true
                connectorsSelect[key].sourceMappingDataHoldingKind = item2.sourceMappingDataHoldingKind
                item2.disabledStatus = true
            } else {
                connectorsSelect[key].disabledStatus = false
                item2.disabledStatus = false
            }
            connectorsSelect && connectorsSelect.length > 0 && connectorsSelect.forEach((elem) => {
                if (elem.nameSelect === item2.title && item2.title !== connectorsSelect[key].nameSelect ) {
                    item2.disabledStatus = true
                }
            })
        })
        this.setState({
            formBusinessBuilder: {
                ...settings,
                connectorsSelect
            },
            dataSource
        })
    }
    onChangeInputProposed = (e, key) => {
        const { listOptionDataSource, dataSource, formBusinessBuilder } = this.state
        const settings = this.state.formBusinessBuilder
        const { proposedCategories } = settings
        proposedCategories && proposedCategories.length > 0 && proposedCategories.forEach((item2) => {
            // if (key === item2.key) {
            //     proposedCategories[key].query = e.target.value
            // }
            if (proposedCategories[key] && proposedCategories[key].query && key === item2.key ) {
                proposedCategories[key].query = e.target.value
            } else {
                proposedCategories[key] = {
                    id: null,
                    query: e.target.value,
                    key: key,
                }
            }
        })
        this.setState({
            formBusinessBuilder: {
                ...settings,
                proposedCategories
            },
        })
    }
    onBackPage = () => {
        this.props.onVisibleBusinessProcessBuilder(false)
    }
    handleDropComponent = (droppedItem) => {
        const { formBusinessBuilder } = this.state
        const { listHeaderComponents } = formBusinessBuilder
        if (!droppedItem.destination) return
        const updatedList = [...listHeaderComponents];
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // updatedList && updatedList.length > 0 && updatedList.forEach((item, index) => {
        //     item.key = index
        // })
        this.setState({
            formBusinessBuilder: {...this.state.formBusinessBuilder, listHeaderComponents: updatedList },
            isBtnSaveChangeDisabled:false,
        })
    }
    onProvidedDraggable = (provided) => {
        return {...provided.draggableProps}
    }
    onStateProvidedDraggable = (state) => {
        this.setState({
            actionProvidedDraggable: state
        })
    }
    onLeaveProvidedDraggable = (state) => {
        this.setState({
            actionProvidedDraggable: {}
        })
    }
    onCLickAddHeaderComponent = () => {
        const { formBusinessBuilder } = this.state
        const { listHeaderComponents } = formBusinessBuilder
        const listHeader = []
        listHeader.push({ id: 'header', key: listHeaderComponents.length, name: 'Header'})
        this.setState({
            formBusinessBuilder: {...this.state.formBusinessBuilder,
                listHeaderComponents: listHeaderComponents.concat(listHeader) }
        })
    }
    onGenerateComponentsHeader = (component, index, snapshot , onStateProvidedDraggable, onLeaveProvidedDraggable, onChangeForm) => {
        return (
            <HeaderProcessing component={ component } snapshot={ snapshot } onStateProvidedDraggable={ onStateProvidedDraggable }
                              onLeaveProvidedDraggable={ onLeaveProvidedDraggable } onChangeForm={ onChangeForm } showConfirmDeleteElement={this.showConfirmDeleteElement}/>
        )
    }
    onChangeForm = (form, component) => {
        const { formBusinessBuilder } = this.state
        const { listHeaderComponents } = formBusinessBuilder
        listHeaderComponents && listHeaderComponents.length > 0 &&  listHeaderComponents.forEach((list) => {
            if (list.key === component.key) {
                list.header = form.header
                list.valueInput = form.valueInput
            }
        })
        this.setState({
            formBusinessBuilder: {...this.state.formBusinessBuilder, listHeaderComponents}
        })
    }

    onEditSavedBusinessActivities = () => {
        const { formBusinessBuilder } = this.state
        const { selectBusiness } = this.props
        const dataSourceList = []
        const businessProcessComponent = []
        const businessProcessQueries  = []
        formBusinessBuilder && formBusinessBuilder.connectorsSelect && formBusinessBuilder.connectorsSelect.length > 0 && formBusinessBuilder.connectorsSelect.forEach((item) => {
            dataSourceList.push(item.sourceMappingId)
        })
        formBusinessBuilder && formBusinessBuilder.listHeaderComponents && formBusinessBuilder.listHeaderComponents.length > 0 && formBusinessBuilder.listHeaderComponents.forEach((item2) => {
            businessProcessComponent.push({
                componentHeader: item2.header,
                componentText: item2.valueInput ? item2.valueInput : null,
                componentType: item2.id,
                componentPosition: item2.key,
            })
        })

        formBusinessBuilder && formBusinessBuilder.proposedCategories && formBusinessBuilder.proposedCategories.length > 0 && formBusinessBuilder.proposedCategories.forEach( e => {
            if ( e.query !== null && e.query !== '') {
                businessProcessQueries.push({
                    id : e.id,
                    query: e.query
                })
             }
        })
        const editBusinessProcessComponentsDTO = {
            businessProcessId: selectBusiness.id,
            dataSourceList: dataSourceList,
            businessProcessComponentDTOS: businessProcessComponent,
            businessProcessQueries : businessProcessQueries
        }
        apiEditBusinessProcessComponents(editBusinessProcessComponentsDTO).then((response) => {
            if (response.data.title && response.data.title.toLowerCase() === 'success') {
                CustomNotificationManager.success(response.data.message, 'Success')
            } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
                CustomNotificationManager.info(response.data.message, 'Info')
            }
        })
    }
    showConfirmDeleteElement = (e, component) => {
        customConfirm({
            title: <span className="icon-v14-delete"></span>,
            content: `Delete header?`,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            mask: true,
            maskClosable: false,
            closeHaveLogic: false,
            className:'asset-register-modal modal-delete-connector',
            centered: true,
            cancelClassName: 'cancel-button',
            confirmClassName: 'confirm-button',
            confirmAction: () => {
                this.onActionDeleteComponent(e, component)
            }
        })
    }
    onActionDeleteComponent = (e, component) => {
        const { formBusinessBuilder } = this.state
        const { listHeaderComponents } = formBusinessBuilder
        if (listHeaderComponents && listHeaderComponents.length > 0) {
            let filtersForm = []
            filtersForm = listHeaderComponents.filter((elem) => elem.key !== component.key)

            this.setState({
                formBusinessBuilder: {...this.state.formBusinessBuilder, listHeaderComponents: filtersForm},
            })
        }
    }
    onHoverAction = (e, field, index) => {
        switch (field) {
            case 'backIcon':
                this.setState({
                    onHoverBackIcon: true,
                })
                break;
            case 'addDataSource' :
                this.setState({
                   onHoverAddDataSource: true,
                })
                break;
            case 'removeDataSource':
                this.setState({
                    onHoverRemoveDataSource: index,
                })
                break;
            case 'addProposedCategories':
                this.setState({
                   onHoverAddCategories:true,
                })
                break;
            case 'removeProposedCategories':
                this.setState({
                    onHoverRemoveCategories: index,
                })
                break;
            case 'addActivities':
                this.setState({
                    onHoverAddActivities: true,
                })
                break;
            default: break;
        }
    }
    onLeaveAction = () => {
        this.setState({
            onHoverBackIcon: false,
            onHoverAddDataSource: false,
            onHoverRemoveDataSource:{},
            onHoverAddCategories:false,
            onHoverRemoveCategories: false,
            onHoverAddActivities: false,
        })
    }
    onCancelBusinessProcessActivities = () => {
        this.props.onVisibleBusinessProcessBuilder(false)
        this.setState({
            formBusinessBuilder: {
                connectorsSelect: [{
                    valueDataSourceSelect: null,
                }],
                proposedCategories: [],
                listHeaderComponents: []
            }
        })
    }
    render() {
        const { activePageBack, visibleDataSource, formBusinessBuilder, listOptionDataSource, dataSource, targetKeys, onHoverBackIcon,
        onHoverAddDataSource, onHoverRemoveDataSource, onHoverAddCategories, onHoverRemoveCategories, onHoverAddActivities, dataConnectLocationList } = this.state
        const { selectBusiness } = this.props
        const { RangePicker } = DatePicker;
        return (
            <div className="business-process-builder">
                <div className="business-process-builder-header">
                   <span onClick={() => this.onBackPage()}
                         className={ onHoverBackIcon ? 'icon-v21-back-active' : 'icon-v21-back-1'}
                         onMouseEnter={(e) => this.onHoverAction(e, 'backIcon')}
                         onMouseLeave={() => this.onLeaveAction()}
                   ></span>
                   <span className="first-name">{selectBusiness.businessProcessName ? selectBusiness.businessProcessName : ''}</span>
                   <span className="last-name">{selectBusiness.businessProcessDetail ? selectBusiness.businessProcessDetail : ''}</span>
                </div>
                <div className="buttons-action-business-process">
                    <Button className="action-cancel-business-process"
                    onClick={(e) => this.onCancelBusinessProcessActivities(e)}>
                        Cancel
                    </Button>
                    <Button className="action-save-business-process"
                            onClick={ (e) => this.onEditSavedBusinessActivities(e) }
                    >
                        Save
                    </Button>
                </div>
                <div className="content-business-builder">
                    <div className="component-builder">
                        <div className="component-header">
                          <span className="name-component">Data source</span>
                          <span onClick={(e) => this.onAddRemoveDataSource(e, 'add')}
                                className={ onHoverAddDataSource ? "icon-v17-add-active" : "icon-v17-add"}
                                onMouseEnter={(e) => this.onHoverAction(e, 'addDataSource')}
                                onMouseLeave={() => this.onLeaveAction()}

                          ></span>
                        </div>
                        { formBusinessBuilder.connectorsSelect && formBusinessBuilder.connectorsSelect.length > 0 && formBusinessBuilder.connectorsSelect.map((item, index) => {
                            return (
                                <div className="content-data-source" key={index}>
                                    <div className="content-select-data-source">
                                        <Select
                                            placeholder="Select"
                                            value={item.nameSelect ? item.nameSelect : 'choose'}
                                            dropdownClassName={'select-data-source-dropdown'}
                                            onChange={(e) => this.onChangeSelectDataSource(e, index)}>
                                            {
                                                dataSource && dataSource.length > 0 && dataSource.map((option, index2) => {
                                                    return (
                                                        <Option disabled={option.disabledStatus} value={option.key} key={index2}>{option.title}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                        <span onClick={(e) => this.onAddRemoveDataSource(e, 'remove', index, item)}
                                              className={ onHoverRemoveDataSource === index ? "icon-v16-minus-active" : "icon-v16-minus"}
                                              onMouseEnter={(e) => this.onHoverAction(e, 'removeDataSource', index)}
                                              onMouseLeave={() => this.onLeaveAction()}
                                        ></span>
                                    </div>
                                    <div className="content-connectors-location">
                                    { item.sourceMappingDataHoldingKind && item.sourceMappingDataHoldingKind.length > 0 && item.sourceMappingDataHoldingKind.map((elem, index) => {
                                        return (
                                            <span key={index} className="name-connectors">{elem}</span>
                                        )
                                    })

                                    }
                                    </div>
                                    {/*<div className="content-result-select-data-source">*/}
                                    {/*    <div className="content-one">*/}
                                    {/*        <span className="content-title">*/}
                                    {/*            <span className="title1">HR personnel system</span>*/}
                                    {/*            <span className="title2"> - Microsoft 365 OneDrive</span>*/}
                                    {/*        </span>*/}
                                    {/*        <span className="content-option">*/}
                                    {/*            <Input/>*/}
                                    {/*            <span className="icon-v17-add"></span>*/}
                                    {/*        </span>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="content-two">*/}
                                    {/*        <span className="content-title">*/}
                                    {/*            <span className="title1">Entire Email tenant access</span>*/}
                                    {/*            <span className="title2"> - Microsoft 365 Exchange</span>*/}
                                    {/*        </span>*/}
                                    {/*        <span className="content-option">*/}
                                    {/*             <RangePicker*/}
                                    {/*                          className='date-picker'*/}
                                    {/*                          defaultValue={[]}*/}
                                    {/*                          dropdownClassName="date-picker-up"/>*/}
                                    {/*             <Select/>*/}
                                    {/*        </span>*/}
                                    {/*    </div>*/}

                                    {/*</div>*/}
                                </div>
                            )
                        })
                        }
                    </div>
                    <div className="component-builder">
                        <div className="component-header">
                            <span className="name-component">Proposed categories of personal data for search queries</span>
                            { (formBusinessBuilder.proposedCategories && formBusinessBuilder.proposedCategories.length === 0 || formBusinessBuilder.proposedCategories.length > 0 &&
                            formBusinessBuilder.proposedCategories[formBusinessBuilder.proposedCategories.length - 1] && formBusinessBuilder.proposedCategories[formBusinessBuilder.proposedCategories.length - 1].query !== null &&
                            formBusinessBuilder.proposedCategories[formBusinessBuilder.proposedCategories.length - 1].query !== '')  ?

                                <span onClick={(e) => this.onAddRemoveProposedCategories(e, 'add')}
                                      className={ onHoverAddCategories ? "icon-v17-add-active" : "icon-v17-add"}
                                      onMouseEnter={(e) => this.onHoverAction(e, 'addProposedCategories')}
                                      onMouseLeave={() => this.onLeaveAction()}
                                ></span> :
                                <span className={"icon-v17-add"} onClick={(e) => this.addDisabled(e)}></span>
                            }

                        </div>
                        { formBusinessBuilder.proposedCategories && formBusinessBuilder.proposedCategories.length > 0 && formBusinessBuilder.proposedCategories.map((item, index) => {
                        return (
                            <div className="content-proposed-categories" key={index}>
                               <Input onChange={(e) => this.onChangeInputProposed(e, index)}
                                      value={ item.query }
                                      className="input-proposed"/>
                                <span onClick={(e) => this.onAddRemoveProposedCategories(e, 'remove', index, item)}
                                      className={ onHoverRemoveCategories === index ? "icon-v16-minus-active" :"icon-v16-minus"}
                                      onMouseEnter={(e) => this.onHoverAction(e, 'removeProposedCategories', index)}
                                      onMouseLeave={() => this.onLeaveAction()}
                                ></span>
                            </div>
                        )
                        })
                        }
                    </div>
                    <div className="component-builder">
                        <div className="component-header">
                            <span className="name-component">Processing activities</span>
                            <span onClick={(e) => this.onCLickAddHeaderComponent(e)}
                                  className={ onHoverAddActivities ? 'icon-v17-add-active' : "icon-v17-add"}
                                  onMouseEnter={(e) => this.onHoverAction(e, 'addActivities')}
                                  onMouseLeave={() => this.onLeaveAction()}
                            ></span>
                        </div>
                        <div className="content-header-components">
                            <DragDropContext onDragEnd={this.handleDropComponent}>
                                <Droppable droppableId="list-container">
                                    {(provided) => (
                                        <div
                                            className="list-container"
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {
                                                formBusinessBuilder.listHeaderComponents && formBusinessBuilder.listHeaderComponents.length > 0 && formBusinessBuilder.listHeaderComponents.map((component, index) => {
                                                    return (
                                                        <Draggable key={component.key} draggableId={`${component.key}`} index={index}>
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    id={index}
                                                                    className="item-container"
                                                                    ref={provided.innerRef}
                                                                    {...provided.dragHandleProps}
                                                                    {...this.onProvidedDraggable(provided)}
                                                                >
                                                                    {/*{...actionProvidedDraggable.key === component.key && {...this.onProvidedDraggable(provided)}}*/}
                                                                    {
                                                                        this.onGenerateComponentsHeader(component, index, snapshot ,this.onStateProvidedDraggable, this.onLeaveProvidedDraggable,
                                                                        this.onChangeForm)
                                                                    }
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    )
                                                })
                                            }
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BusinessProcessBuilder;