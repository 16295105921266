import React from 'react'
import './SemiCircle.css'
import { notification } from 'antd'

const duration= 10
const durationError= 0

class CustomNotificationManager extends React.Component {
  static success = (message = 'Success', title = 'Success', options = null) => {
    notification.destroy()
    if (options) {
      notification.config(options)
    } else {
      notification.config({
        duration: duration
      })
    }
    notification.success({
      message: title,
      description: message,
      className: 'nt-success'
    })
  }

  static info = (message = 'Info', title = 'Info', options = null) => {
    notification.destroy()
    if (options) {
      notification.config(options)
    } else {
      notification.config({
        duration: duration
      })
    }
    notification.info({
      message: title,
      description: message
    })
  }

  static warning = (message = 'Warning', title = 'Warning', options = null) => {
    notification.destroy()
    if (options) {
      notification.config(options)
    } else {
      notification.config({
        duration: duration
      })
    }
    notification.warning({
      message: title,
      description: message
    })
  }

  static error = (message = '', title = 'Error', options = null) => {
    notification.destroy()
    if (options) {
      notification.config(options)
    } else {
      notification.config({
        duration: durationError
      })
    }
    message = (typeof(message) === 'string') ? `${ message } ` : message
    notification.error({
      message: `${ title } `,
      description: message,
      className: 'nt-error'
    })
  }

  static cleanBuffer = () => {
    notification.destroy()
  }
}

export default CustomNotificationManager