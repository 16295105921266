import React, { Component } from 'react'
import CustomNotificationManager from '../../components/Common/CustomNotificationManager'
import { Icon } from 'antd'
import PropTypes from 'prop-types'
import './/FieldName.scss'

/**
 * <FieldName field="name" onChangeOptions={ this.onChangeOptions } onSelectOption={ this.onSelectOption }/>
 * const option = [
 *   { 'key': 'ASSET', 'custom': false },
 *   ...
 * ]
 */
export class FieldName extends Component {
  wrapperRef = {}

  constructor(props) {
    super(props)
    this.state = {
      data: [],
      newValue: '',
      selected: '',
      display: false
    }

    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidMount() {
    let options = []
    if (this.props.options) {
      options = this.props.options
    }
    this.setState({
      options: options,
      selected: this.props.defaultValue || ''
    })
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.wrapperRef && this.wrapperRef.contains(event.target)) {
    } else {
      this.setState({
        display: false
      })
    }
  }

  toggleComponent = (param) => {
    const show = param ? param : !this.state.display
    this.setState({ display: show })
  }

  onHandleRemove(i, item) {
    const data = this.props.options
    data.splice(i, 1)
    this.setState({ data: data })
    this.props.onChangeOptions(data, item, 'remove')
  }

  onHandleChange(e) {
    this.setState({ newValue: e.target.value })
  }

  onHandleAdd() {
    const { newValue } = this.state
    const data = this.props.options
    if (this.isValid(newValue)) {
      const temp = {
        'name': newValue,
        'custom': true
      }
      data.push(temp)
      this.setState({
        data: data,
        newValue: ''
      })
      this.props.onChangeOptions(data, temp, 'add')
    }
  }

  onHandleClick(value) {
    this.toggleComponent(false)
    this.props.onSelectOption(value)
    this.setState({
      selected: value
    })
  }

  isValid(text) {
    let response = true
    if (text.trim().length === 0) {
      CustomNotificationManager.warning('You should add an option.', 'Empty field')
      response = false
    } else if (this.state.data.indexOf(text) > -1) {
      CustomNotificationManager.warning('This option already exist.', 'Already exist')
      response = false
    }
    return response
  }

  render() {
    const { display, selected, newValue } = this.state
    const headIcon = (display) ? 'up' : 'down'
    return (
      <div className="fields-container" onMouseEnter={() => this.props.disableDragging()} ref={this.setWrapperRef}>
        <div className="fields-container__head" onClick={ () => this.toggleComponent() }>
          <input type="text" placeholder='FIELD NAME' value={ selected } className="fields-container__head__input" readOnly/>
          <Icon className="fields-container__head__icon" type={ headIcon } />
        </div>
        { display &&
          <div className="fields-container__popup">
            { this.props.options &&
            this.props.options.map((item, i) => {
              return (
                <div className="item" key={ i }>
                  <span className="item__option-text" onClick={ () => this.onHandleClick(item.name) }>{ item.name }</span>
                  { item.custom &&
                    <i className="minus" onClick={ () => this.onHandleRemove(i, item) }/>
                  }
                </div>
              )
            })
            }
            <div className="item">
              <input type="text" placeholder="ADD NAME" value={ newValue } onChange={ (e) => this.onHandleChange(e) }/>
              <span className="add" onClick={ () => this.onHandleAdd() }/>
            </div>
          </div>
        }
      </div>
    )
  }
}

FieldName.propTypes = {
  options: PropTypes.array.isRequired,
  onChangeOptions: PropTypes.func.isRequired,
  onSelectOption: PropTypes.func.isRequired,
  disableDragging: PropTypes.func.isRequired,
  defaultValue: PropTypes.string.isRequired
}

export default FieldName