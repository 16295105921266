import React from 'react'
import './annotations.scss'
import { Table, Input, Form } from 'antd'
import { saveAnnotationColor, saveAnnotationColorAsGodAdmin, removeAnnotationColor } from '../../../../api/Sar'
import { connect } from 'react-redux'
import { CompactPicker } from 'react-color'
import CustomNotificationManager from '../../../Common/CustomNotificationManager'
import Swal from 'sweetalert2'

const EditableContext = React.createContext()
const { TextArea } = Input

class EditableCell extends React.Component {
    getInput = () => {
      if (this.props.inputType === 'textarea') {
        return <TextArea autosize={{ minRows: 3, maxRows: 4 }} />
      }
      return <Input />
    };

    renderCell = ({ getFieldDecorator }) => {
      const {
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
      } = this.props
      return (
        <td {...restProps}>
          {editing ? (
            <Form.Item style={{ margin: 0 }}>
              {getFieldDecorator(dataIndex, {
                rules: [
                  {
                    required: true,
                    message: `Please Input ${ title }!`,
                  },
                ],
                initialValue: record[dataIndex],
              })(this.getInput())}
            </Form.Item>
          ) : (
            children
          )}
        </td>
      )
    };

    render() {
      return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    }
}

class AnnotationsTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      editingKey: '',
      displayColorPicker: [],
      rowSelectedId: 0,
      lastSelectedColor: '#000'
    }
    this.columns = [
      {
        title: 'Annotation Category',
        dataIndex: 'category',
        width: '90%',
        editable: false,
        align: 'left',
        render: (text, record) => {
          return text.toUpperCase()
        }
      },
      {
        title: 'Color',
        dataIndex: 'color',
        width: '5%',
        editable: false,
        render: (text, record) => {
          const backgroundColor = record.color.replace('0.3', '1')
          return (
            <div>
              <div className="color_input" onClick={ () => this.pickerHandleOpen(record.id) } >
                <div className="color_input__value" style={{ backgroundColor: backgroundColor }} />
              </div>
              { this.state.displayColorPicker[record.id] ? <div className= "color_picker_popup">
                <div className = "color_picker_popup__cover" onClick={ () => this.pickerHandleClose(record) }/>
                <CompactPicker
                  colors = {['rgba(107, 91, 149, 1)', 'rgba(254, 178, 54, 1)', 'rgba(214, 65, 97, 1)',
                    'rgba(255, 123, 37, 1)', 'rgba(75, 195, 193, 1)', 'rgba(194, 194, 71, 1)', 'rgba(181, 231, 160, 1)',
                    'rgba(191, 108, 64, 1)', 'rgba(62, 68, 68, 1)', 'rgba(130, 183, 75, 1)', 'rgba(64, 93, 39, 1)',
                    'rgba(193, 148, 106, 1)', 'rgba(60, 136, 180, 1)', 'rgba(200, 100, 91, 1)', 'rgba(238, 162, 154, 1)',
                    'rgba(201, 76, 76, 1)', 'rgba(124, 108, 6, 1)', 'rgba(15, 195, 71, 1)', 'rgba(231, 82, 114, 1)',
                    'rgba(238, 140, 54, 1)', 'rgba(92, 212, 209, 1)', 'rgba(211, 211, 88, 1)', 'rgba(198, 248, 177, 1)',
                    'rgba(192, 125, 81, 1)' ]}
                  disableAlpha= {true}
                  color={ record.color }
                  onSwatchHover ={ this.pickerHandleChange }
                  onChange = { () => this.pickerHandleCloseAndSave(record) }
                />
              </div> : null }
            </div>
          )
        }
      }
    ]

    const adminColumn = {
      title: 'Action',
      dataIndex: 'action',
      width: '5%',
      editable: false,
      render: (text, record) => {
        return (
          <div>
            <button className="delete-button" onClick={ () => this.deleteAnnotationCategory(record) }>Delete</button>
          </div>
        )
      }
    }
    if (this.props.godAdminMode) {
      this.columns.push(adminColumn)
    }
  }

  deleteAnnotationCategory = (record) => {
    Swal.fire({
      text: `Are you sure that you want to delete ${ record.category }`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        removeAnnotationColor(record.tag).then((response) => {
          if (response.status === 200) {
            this.props.removeAnnotation(record.id)
          }
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
              console.log(error)
          }
        })
      }
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.annotations !== this.props.annotations) {
      const modifiedData = this.props.annotations
      if (modifiedData && modifiedData.length > 0) {
        modifiedData.map((annotation, key) => {
          modifiedData[key].key = annotation.id
          return true
        })
      }
  
      this.setState({
        data: this.props.annotations
      })
    }
  }
  
  isEditing = (record) => record.id === this.state.editingKey;
    cancel = () => {
      this.setState({ editingKey: '' })
    };

    save(record) {
      record.clientId = this.props.clientId
      const key = record.id
      if (key) {
        if (this.props.godAdminMode) {
          record.clientId = null
          record.defaultColor = 1
          saveAnnotationColorAsGodAdmin(record).then(() => {

          }).catch((error) => {
            if (error?.response?.status === 504) {
              CustomNotificationManager.cleanBuffer('warning', '')
            }else{
                console.log(error)
            }
          })
        } else {
          record.defaultColor = 0
          saveAnnotationColor(record).then(() => {

          }).catch((error) => {
            if (error?.response?.status === 504) {
              CustomNotificationManager.cleanBuffer('warning', '')
            }else{
              console.log(error)
            }
          })
        }
      } else {
        record.defaultColor = 0
        saveAnnotationColor(record).then(() => {

        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
        })
      }
      CustomNotificationManager.success(`You successfully changed the color for the ${ record.category } category`, 'Color changed')
    }

    edit(key) {
      this.setState({ editingKey: key })
    }

    pickerHandleOpen = (id) => {
      const newVal = this.state.displayColorPicker
      newVal[id] = !Boolean(this.state.displayColorPicker[id])
      this.setState({
        displayColorPicker: newVal,
        rowSelectedId: id
      })
    }

    pickerHandleClose = (record) => {
      const newVal = this.state.displayColorPicker
      newVal[record.id] = false
      this.setState({ displayColorPicker: newVal, lastSelectedColor: '' })
    };

    pickerHandleCloseAndSave = (record) => {
      const { rowSelectedId, data, lastSelectedColor } = this.state
      const newVal = data
      for (let i = 0; i<newVal.length; i++) {
        if (newVal[i].id === rowSelectedId) {
          newVal[i].color = lastSelectedColor
        }
      }
      const newValalue = this.state.displayColorPicker
      newValalue[record.id] = false
      record.color.a = 0.3
      record.color = this.convertRgbaToString(record.color)
      this.setState(
        { data: newVal, lastSelectedColor: '', displayColorPicker: newValalue },
        () => this.save(record)
      )
    };

    pickerHandleChange = (color) => {
      this.setState({ lastSelectedColor: color.rgb })
    };

    convertRgbaToString = ({ r, g, b, a }) => {
      return `rgba(${ r }, ${ g }, ${ b }, ${ a })`
    }

    render() {
      const components = {
        body: {
          cell: EditableCell,
        },
      }

      const columns = this.columns.map((col) => {
        if (!col.editable) {
          return col
        }
        return {
          ...col,
          onCell: (record) => ({
            record,
            inputType: col.dataIndex === 'details' ? 'textarea' : 'text',
            dataIndex: col.dataIndex,
            title: col.title,
            editing: this.isEditing(record),
          }),
        }
      })
      return (
        <EditableContext.Provider value={this.props.form}>
          <Table
            components={ components }
            dataSource={ this.state.data }
            columns={ columns }
            rowClassName= "editable-row"
            pagination={ false }
            className= "annotation-color"
          />
        </EditableContext.Provider>
      )
    }
}

const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription ? state.subscriptionReducer.subscription.subscription.client.id : null,
  godAdminMode: state.admin.godAdminMode
})

AnnotationsTable = connect(mapStateToProps)(AnnotationsTable)
export default AnnotationsTable = Form.create()(AnnotationsTable)