import React from 'react'
import './Clutter.scss'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import ClutterTable from './ClutterTable'
import { Tooltip } from 'antd'
class Clutter extends React.Component {
    
    render() {
        return (
            <div className="clutter-page">
                <div className="clutter-page__content">
                    <div className="header-clutter">
                        <span className="title-header">Remove unnecessary threads and folders</span>
                        <Tooltip overlayClassName="help-message-locations" title={<div className="content-help-message-locations">
                            <span className="text-information">Clutting process allows to remove empty folders and any thread data from emails such that you don't need to review the same emails over, and over.</span>
                            <span className="more-info-link" onClick={ () => window.open('https://support.smartbox.ai/support/solutions/articles/47001235836-clutter', '_blank') }>More info.</span>
                            </div>}>
                            <span className="icon-v14-question"></span>
                        </Tooltip>
                    </div>
                        <div className="content-clutter-page">
                            <ClutterTable folder={ this.props.folder } activeTab={ this.props.activeTab }/>
                        </div>
                </div>
            </div>
        )

    }
}

Clutter.propTypes = {
    folder: PropTypes.object,
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id
})
export default compose(
    connect(mapStateToProps),
)(Clutter)