import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import './ClutterPage.scss'
import EmptyFoldersTable from "./EmptyFoldersTable"
import {Tooltip} from "antd";

class EmptyFoldersPage extends React.Component {
    state = {
        visibleDuplicatesList: false,
        selectFileBox: {}
    }
    componentDidMount() {
    }

    render() {
        return (
            <div className="new-clutter-page">
                <div className="new-clutter-page__content">
                    <EmptyFoldersTable folder={ this.props.folder } onGetSelectFilesEmptyFolder={this.props.onGetSelectFilesEmptyFolder}
                                       checkStatusClutter={this.props.checkStatusClutter}
                                       onGetSearchEmptyFolder={this.props.onGetSearchEmptyFolder}
                                       onGetSelectAllEmptyFolder={this.props.onGetSelectAllEmptyFolder}
                                       onBackFilesEmptyFolder={this.props.onBackFilesEmptyFolder}
                    />
                </div>
            </div>
        )

    }
}

EmptyFoldersPage.propTypes = {
    folder: PropTypes.object,
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id
})
export default compose(
    connect(mapStateToProps)
)(EmptyFoldersPage)