import React from 'react'
import PropTypes from 'prop-types'
import './UploadProgressWindow.scss'
import { Progress, Tooltip } from 'antd'
import { objectWithChildArraysToFormData } from '../../../utils/FormDataBuilder'
import WebWorker from './workers/WebWorker'
import worker from './workers/file-names.worker'
import CustomNotificationManager from '../../Common/CustomNotificationManager'
import { getFilesBeforeUploadDTO } from '../../../api/SmartBox'
import {connect} from "react-redux";
import {
    setSmartBoxGenerateUniqueId,
    setSmartBoxShowUploadNavigation
} from "../../../actions/smartBoxActions";

class FileProgressDataMapping extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        const { files } = this.props
        let progressConnect = 0
        if (Object.keys(files) && Object.keys(files).length > 0) {
            progressConnect = ((files.nrOfFilesExtracted / files.totalFilesNumber)*100).toFixed(2)
        }
        setTimeout(() => {

        }, 5000)
        return (
            <div className="progress-upload-files__content">
                <div>{files.uploadType ? `${files.uploadType} | Total files: ${files.totalFilesNumber}` : ''}</div>
                <div className="file-names">
                    <Progress percent={ progressConnect > 0 ? progressConnect : 0 } size="small"/>
                </div>
            </div>
        )
    }
}

FileProgressDataMapping.propTypes = {
}
const mapStateToProps = (state) => ({
})
export default connect(mapStateToProps, { })(FileProgressDataMapping)