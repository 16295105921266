import React from 'react'
import PropTypes from 'prop-types'

import TaxonomyItem from './TaxonomyItem'

import './TaxonomyList.css'

const TaxonomyList = ({ taxonomies }) => {
  if (taxonomies.length === 0) return (
    <p className="taxonomyList__empty">No results</p>
  )
  return (
    <div id="taxonomy-list" className="taxonomyList__wrapperTable">
      { taxonomies.map((taxonomy, index) => (
        <TaxonomyItem taxonomy={ taxonomy } key={ taxonomy.id }/>
      ))}
    </div>
  )
}

TaxonomyList.propTypes = {
  taxonomies: PropTypes.array.isRequired
}

export default TaxonomyList