import React from 'react'
import { Route, Navigate, Outlet } from 'react-router-dom'
import { connect } from 'react-redux'
import CustomNotificationManager from '../components/Common/CustomNotificationManager'

const SarPrivateRouter = ({ component: Component, ...rest }) => {
    if (rest.isLoggedIn) {
      const found = rest.sarVisible
      if (found) {
        return <Outlet {...rest}/>
      }
      // CustomNotificationManager.info('Access restricted', 'Unauthorized')
      return <Navigate to="/smart-box/management"/>
    }
    return <Navigate to="/osp/login"/>
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.userReducer.isLogged,
  sarVisible: state.applications.sarVisible
})

export default connect(mapStateToProps, {})(SarPrivateRouter)