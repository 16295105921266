import React, { Component } from 'react'
import { Modal, Spin, Upload, Icon } from 'antd'
import {
  apiExternFileUpload,
  //apiExternFileUploadDraft,
  getPreviewDocByThumbnailId,
  apiGetIdUploadFile, apiExternFileSaveDraft, getCheckIdVerificationUploadStatus
} from '../../../api/Sar'
import CustomNotificationManager from '../../Common/CustomNotificationManager'
import './ExternFileUpload.scss'
import PDFViewer from '../../../utils/PDFViewer'
import PDFJSBackend from '../../../utils/backends/PDFJs'
import { generateBlobFromBase64 } from '../../../utils/GenerateBlob'
import Loading from '../../Common/Loading'
import swal from 'sweetalert2'
import {connect} from "react-redux";
import axios from "axios";
import {ConcurrencyManager} from "axios-concurrency";
import _ from "lodash";
import FormData from "form-data";
import withRouter from '../../../private-routers/withRouter'
import cookie from "react-cookies";
const isLocalhost = () => {
  return window.location.hostname === 'localhost'
}
const host = isLocalhost() ? 'http://localhost:8080' : ''
class ExternFileUpload extends Component {

  constructor(props) {
    super(props)
    this.state = {
      title: 'Your Subject Access Request ID verification',
      textForm: 'In accordance with GDPR guidelines and privacy laws, to help us verify you we need one form of photo identification and one proof of address. All documents are\n' +
          'held securely in this unique URL and can only be viewed by you and the administrator working on your request',
      fileList: [],
      previewImage: '',
      previewVisible: false,
      uploadFileExtern: false,
      externFileId: '',
      fileId: null,
      sarId: null,
      editMode: false,
      url: '',
      loading: false,
      loadingSend: false,
      sar: {},
      checkCompletedFileUpload: false,
      checkEndRequest: false,
      responseDraft: {},
      draftListResponse: [],
      filesIdUpload: [],
      checkStatusUpload: null,
      setIntervalUpload: 0,
      fileSelect: {},
      filesSelectUid: []
    }
    this.windowDocument = null
  }

  componentDidMount() {
    if (this.props.params.id) {
      apiGetIdUploadFile(this.props.params.id).then((response) => {
        this.setState({
          uploadFileExtern: true,
          sar: response.data,
          checkCompletedFileUpload: response.data.completed
        })
      }

      ).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    }
    this.axiosClone = axios.create({ headers: this.props.smartBoxAxiosHeaders })
    this.manager = ConcurrencyManager(this.axiosClone, this.MAX_CONCURRENT_REQUESTS)
    cookie.remove('checkIdVerificationUpload', { path: '/' })
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_.isEmpty(this.props.smartBoxAxiosHeaders)) {
      this.axiosClone = axios.create({ headers: this.props.smartBoxAxiosHeaders })
      this.manager = ConcurrencyManager(this.axiosClone, this.MAX_CONCURRENT_REQUESTS)
    }
  }
    handleSubmitForm = () => {
      this.setState({
        loadingSend: true
      })
      const { sar, draftListResponse } = this.state
      //const fileList = this.state.fileList.map((file) => file.id)
      if (this.state.uploadFileExtern && sar) {
        //const draftSarDTO = Object.assign({}, draftListResponse)
        apiExternFileSaveDraft(draftListResponse).then((response) => {
          const idsFile = []
          if (response.data && response.data.length > 0) {
            response.data.forEach((file) => {
              idsFile.push(file.thumbnail.id)
            })
          }
           this.setState({
             filesIdUpload: idsFile
           }, () => {
             apiExternFileUpload(idsFile, sar.encodedId)
                 .then((response) => {
                   const answerResponse = response.data
                   if (answerResponse !== 'The SAR is already closed, and cannot be completed anymore') {
                     CustomNotificationManager.success('Documents successfully uploaded.', 'Success')
                     this.setState({
                       uploadFileExtern: false,
                       loadingSend: false
                     })
                     setTimeout(function() {
                       this.props.navigate(-1) || window.close()
                     }, 1000)
                   } else {
                     CustomNotificationManager.info('The SAR is already closed, and cannot be completed anymore.')
                     this.setState({
                       checkEndRequest: true
                     })
                     setTimeout(function() {
                       this.props.navigate(-1) || window.close()
                     }, 4000)
                   }

                 }).catch((error) => {
               if (error?.response?.status === 504) {
                 CustomNotificationManager.cleanBuffer('warning', '')
               }else{
                console.log(error)
              }
             })
           })
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
        })
      } else {
        return false
      }
    };

    /**
     *
     * @param file
     * @returns {boolean} return false to prevent default request {@link https://ant.design/components/upload/#onChange}
     */
    customUploadDraft = (file, file2) => {
      const { sar } = this.state
      const maxSizeFile = file.size / 1024 / 1024 < 5
      const listUid = []
      file2 && file2.length > 0 && file2.forEach((list) => {
        listUid.push(list.uid)
      })
        this.setState({
          fileSelect: file,
          filesSelectUid: listUid
        })
      if (sar && sar.editMode) {
        if (maxSizeFile) {
          this.handleFileChange(file, listUid)
        } else {
          CustomNotificationManager.error('File must smaller than 5MB!', 'Error')
        }
      } else {
        return false
      }

      return false
    };
    onGetStatusUpload = (file, listUid) => {
      const { setIntervalUpload} = this.state
      getCheckIdVerificationUploadStatus(listUid).then((response) => {
        if (response.status === 200) {
          this.setState({
            checkStatusUpload: response.data
          })
          if (response.data === true) {
            this.setIntervalUploadStatus(response.data)
            this.setState({setIntervalUpload})
            cookie.save('checkIdVerificationUpload', 'start', {path: '/'})
          } else {
            if (response.data && response.data.length > 0) {
              const listThumbnail = []
              response.data.forEach((thumb) => {
                thumb.thumbnail.doc = thumb.proofIdentityDocumentsEntity.doc
                thumb.thumbnail.fileType = thumb.proofIdentityDocumentsEntity.fileType
                thumb.thumbnail.newThumnbailUpdate = thumb.expandedThumbnail
                listThumbnail.push(thumb.thumbnail)
              })
              this.setState({
                    //fileList: [...this.state.fileList, listThumbnail],
                fileList: this.state.fileList.concat(listThumbnail),
                    //draftListResponse: [...this.state.draftListResponse, response.data],
                    draftListResponse: this.state.draftListResponse.concat(response.data),
                    responseDraft: response.data
                  }
              )
              cookie.remove('checkIdVerificationUpload', { path: '/' })
            }
            clearInterval(setIntervalUpload)
            this.setState({setIntervalUpload})
          }
        }
      })
    }
  setIntervalUploadStatus = (response) => {
    const {  checkStatusUpload } = this.state
    let { setIntervalUpload } = this.state
    if (setIntervalUpload === 0) {
      if (checkStatusUpload === true || response === true) {
        setIntervalUpload = setInterval(this.callBackUploadStatus, 1000)
        this.setState({
          setIntervalUpload
        })
      } else {
        clearInterval(setIntervalUpload)
      }
    }
  }
  callBackUploadStatus = () => {
    const { checkStatusUpload, setIntervalUpload, filesSelectUid } = this.state
    const itemIdVerification = cookie.load('checkIdVerificationUpload', { path: '/' })
    if (checkStatusUpload === true && itemIdVerification === 'start') {
      getCheckIdVerificationUploadStatus(filesSelectUid).then((response) => {
        if (response.status === 200) {
          this.setState({
            checkStatusUpload: response.data
          })
          if (response.data === true) {
            this.setState({setIntervalUpload})
            cookie.save('checkIdVerificationUpload', 'start', {path: '/'})
          } else {
            if (response.data && response.data.length > 0) {
              const listThumbnail = []
              response.data.forEach((thumb) => {
                thumb.thumbnail.doc = thumb.proofIdentityDocumentsEntity.doc
                thumb.thumbnail.fileType = thumb.proofIdentityDocumentsEntity.fileType
                thumb.thumbnail.newThumnbailUpdate = thumb.expandedThumbnail
                listThumbnail.push(thumb.thumbnail)
              })
              this.setState({
                    fileList: this.state.fileList.concat(listThumbnail),
                    draftListResponse: this.state.draftListResponse.concat(response.data),
                    responseDraft: response.data
                  }
              )
              cookie.remove('checkIdVerificationUpload', { path: '/' })
            }
            //clearInterval(setIntervalUpload)
            this.setState({setIntervalUpload})
          }
        }
      })
    } else {
      //clearInterval(setIntervalUpload)
    }
  }
  objectFileUploadToFormData = (obj, form, namespace) => {
    const fd = form || new FormData()
    let formKey
    for (const property in obj) {
      if (obj.hasOwnProperty(property)) {
        if (namespace) {
          formKey = `${ namespace }[${ property }]`
        } else {
          formKey = property
        }
        if (obj[property] === 'object') {
          this.objectFileUploadToFormData(obj[property], fd, /*formKey */!isNaN(Number(property)) ? formKey : property)
        } else {
          // if it's a string or a File object
          fd.append(formKey, obj[property])
        }
      }
    }
    return fd
  }
  uploadExternalFiles = (data, sarId) => {
    const obj = new FormData()
    obj.append('file', data)
    obj.append('sarId', sarId)
    obj.append('uid', data.uid)
    return this.axiosClone({
          method: 'post',
          url: `${ host }/osprey-0.1.0/v1/proof-id/draft`,
          data: obj,
          //onUploadProgress: config
        }
    ).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

    handleFileChange(file, listUid) {
      this.uploadExternalFiles(file, this.state.sar.id)
      this.onGetStatusUpload(file, listUid)
      //this.onGetStatusUpload(file, listUid)
      //this.objectFileUploadToFormData(file)
      // apiExternFileUploadDraft(file, this.state.sar.id)
      // //apiExternFileUploadDraft(this.objectFileUploadToFormData(file), config)
      //   .then((response) => {
      //     // this.setState({
      //     //   fileList: [...this.state.fileList, response.data.thumbnail],
      //     //       draftListResponse: [...this.state.draftListResponse, response.data],
      //     //       responseDraft: response.data
      //     // }
      //     // )
      //     this.onGetStatusUpload(file, listUid)
      //     return false
      //   }).catch((error) => {
      //   if (error.response.status === 504) {
      //     CustomNotificationManager.cleanBuffer('warning', '')
      //   }
      // })
    }

    handleCancel = () => {
      this.setState({ previewVisible: false })
    }
    handlePreview = (file) => {
      const { sar } = this.state
      if (sar) {
        this.setState({
          previewVisible: true,
          loading: true
        }, () => {
          if (file) {
          switch (file.fileType) {
            case 'image/png':
            case 'image/jpg':
            case 'image/jpeg':
              const imageUrl = `data:${file.type};base64, ${file.thumbnailBytes}`
              this.setState({
                renderingBlock: <img alt="example" style={{width: '100%'}} src={imageUrl}/>,
                loading: false
              })
              break
            case 'application/pdf':
              if (file.doc) {
                this.windowDocument = generateBlobFromBase64(file.doc)
                this.setState({
                  renderingBlock: <PDFViewer backend={PDFJSBackend} src={this.windowDocument} toolbar={0}/>,
                  loading: false
                })
              } else if (file.newThumnbailUpdate) {
              const codeThumbnail = file.newThumnbailUpdate.substr(8, file.newThumnbailUpdate.length)
              this.windowDocument = generateBlobFromBase64(codeThumbnail)
              this.setState({
                renderingBlock: <PDFViewer backend={PDFJSBackend} src={this.windowDocument} toolbar={0}/>,
                loading: false
              })
            }
              // const doc = file.thumbnailBytes
              // this.setState({
              //   renderingBlock: <PDFViewer backend={PDFJSBackend} src={generateBlobFromBase64(doc)}/>,
              //   loading: false
              // })
              break
            default:
              break
          }
        }
          // getPreviewDocByThumbnailId(file.id).then((response) => {
          //   switch (response.data.fileType) {
          //     case 'image/png':
          //     case 'image/jpg':
          //     case 'image/jpeg':
          //       const imageUrl = `data:${ response.data.fileType };base64, ${ response.data.doc }`
          //       this.setState({
          //         renderingBlock: <img alt="example" style={{ width: '100%' }} src={imageUrl}/>,
          //         loading: false
          //       })
          //       break
          //     case 'application/pdf':
          //       const { doc } = response.data
          //       this.setState({
          //         renderingBlock: <PDFViewer backend={PDFJSBackend} src={ generateBlobFromBase64(doc) }/>,
          //         loading: false
          //       })
          //       break
          //     default: break
          //   }
          // }).catch((error) => {
          //   if (error.response.status === 504) {
          //     CustomNotificationManager.cleanBuffer('warning', '')
          //   }
          // })
        })

      } else {
        return false
      }

    };
    onClearAllFileClicked(e) {
      e.preventDefault()
      swal.fire({
        text: 'Do you want to clear all files?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3DD8DB',
        cancelButtonColor: '#D33D33',
        confirmButtonText: 'Yes, clear files'
      }).then((result) => {
        if (result.value) {
          this.clearFilesList()
        } else {
          CustomNotificationManager.info('Action cancelled', 'Aborted')
        }
      })
    }
    clearFilesList = () => {
      this.setState({
        fileList: [],
      })
    }

    onRemoveFileClicked = (file) => {
      swal.fire({
        text: 'Do you want to delete this file?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3DD8DB',
        cancelButtonColor: '#D33D33',
        confirmButtonText: 'Yes, remove file'
      }).then((result) => {
        if (result.value) {
          //this.onRemoveFile(file)
          this.onRemoveFileUpdate(file)
        } else {
          CustomNotificationManager.info('Action cancelled', 'Aborted')
        }
      })
    }

   onRemoveFileUpdate = (file) => {
      const { fileList } = this.state
     let fileListRemoved = []
     if (fileList && fileList.length > 0) {
       fileListRemoved = fileList.filter((list) => list.uid !== file.uid)
     }
     this.setState({
       fileList: fileListRemoved
     })
    }
    onRemoveFile = (file) => {
      this.setState((state) => {
        const index = state.fileList.indexOf(file)
        const newFileList = state.fileList.slice()
        newFileList.splice(index, 1)
        return {
          fileList: newFileList,
        }
      })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      const { sar, checkCompletedFileUpload } = this.state
      const editMode = sar ? sar.editMode : false
      if (!editMode) {
        this.renderNotificationMessageExternFile()
      }
      if (checkCompletedFileUpload) {
        this.renderNotificationMessageExternFileClosed()
      }
    }

    renderNotificationMessageExternFile() {
      CustomNotificationManager.warning('This form has already been completed.')
      setTimeout(function() {
        window.close()
      }, 4000)
    }
    renderNotificationMessageExternFileClosed() {
      CustomNotificationManager.warning('The sar was closed.')
      setTimeout(function() {
        window.close()
      }, 4000)
    }
    render() {
      const { title, fileList, previewVisible, renderingBlock, loading, textForm, loadingSend, sar, checkCompletedFileUpload, checkEndRequest, checkStatusUpload } = this.state
      const editMode = sar ? sar.editMode : false
      const uploadButton = (
        <div>
          <p className="ant-upload-drag-icon">
            <Icon type="inbox"/>
          </p>
          <p className="ant-upload-text">Upload</p>

        </div>
      )
      return (
        <div className="sa_page_extern">
          <div className="externFile-page">
            { checkCompletedFileUpload || checkEndRequest ?
              <div className="externFile-box extern-file-upload-completed">
                <span className="file-upload-completed">The sar was closed.</span>
              </div> :
              !editMode ? <div className="externFile-box extern-file-upload-completed">
                <span className="file-upload-completed">This form has already been completed.</span>
              </div> :
                <div className="externFile-box">
                  <header className="externFile-header">
                    { sar &&
                  <div className="externFile-header__top">
                    {/*<h1 className="externFile-title"> {`${ title } ${ sar.forename ? sar.forename : '' } ${ sar.surname ? sar.surname : '' }`} </h1>*/ }
                    <h1 className="externFile-title"> { title } </h1>
                  </div>
                    }
                  </header>
                  <div className="externFile-textForm">
                    <h1 className="externFile-text extern-file-content"> { textForm } </h1>
                  </div>
                  <div className="file-upload-container">
                    <div><h1>Add each document separately</h1></div>
                    <Upload
                      accept={ '.pdf, .png, .jpeg, .jpg' }
                      listType="picture-card"
                      fileList={ fileList }
                      multiple={ true }
                      beforeUpload={ this.customUploadDraft }
                      onPreview={ this.handlePreview }
                      onRemove={ this.onRemoveFileClicked }
                      disabled={ !editMode }
                    >
                      { fileList.length >= 10 ? null : uploadButton }
                    </Upload>
                    <Modal visible={ previewVisible } footer={ null } onCancel={ this.handleCancel }
                      className="pdf-modal">
                      <div className="custom-modal-body">
                        { loading ? <Spin spinning={ loading } indicator={ <div className="checkout-loading"><Loading/>
                        </div> }/> : renderingBlock }
                      </div>
                    </Modal>
                  </div>
                  <footer className="row buttons">
                    <div className="col-xs-4">
                      <button
                        className={ !editMode ? 'button-blue button-blue-extern-disabled' : 'button-blue' }
                        onClick={ (e) => this.onClearAllFileClicked(e) } disabled={ !editMode }
                      >
                      Discard changes
                      </button>
                    </div>
                    <div className="col-xs-offset-4 col-xs-4">
                      {
                        loadingSend ? <Spin className="float-right" spinning={ loadingSend }
                          indicator={ <div className="checkout-loading"><Loading/></div> }/> :
                          <button
                            className={ !editMode || fileList.length === 0 ? 'button-red-fill-disabled' : 'button-red-fill' }
                            onClick={ this.handleSubmitForm } disabled={ !editMode || fileList.length === 0 }>Send
                          document</button>
                      }
                    </div>
                  </footer>
                </div>
            }
          </div>
        </div>
      )
    }
}
ExternFileUpload = withRouter(ExternFileUpload)
const mapStateToProps = (state) => ({
  smartBoxAxiosHeaders: state.smartBox.smartBoxAxiosHeaders,
})
//export default ExternFileUpload
export default connect(mapStateToProps, {  })(ExternFileUpload)