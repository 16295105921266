import React from 'react'
import PropTypes from 'prop-types'

import './DocumentPagination.css'

class DocumentPagination extends React.Component {

  componentDidMount = () => {
  }

  render = () => {
    const { pagination } = this.props

    return (
      <div>
        <span className="backOffice-documentList-pagination">Showing {pagination.offset + 1} to {pagination.offset + pagination.length} of {pagination.total}</span>
        { (pagination.offset + pagination.length) < pagination.total
          ? <span onClick={ this.props.changeOffset.bind(this, (pagination.offset + pagination.length)) } className="backOffice-documentList-pagination-next">Next</span>
          : <span/>
        }
        { pagination.offset > 0
          ? <span onClick={ this.props.changeOffset.bind(this, (pagination.offset - pagination.length)) } className="backOffice-documentList-pagination-previous">Previous</span>
          : <span/>
        }
      </div>
    )
  }
}

DocumentPagination.propTypes = {
  pagination: PropTypes.object.isRequired,
  changeOffset: PropTypes.func.isRequired
}

export default DocumentPagination