export const updateQuestionsOrder = (questions) => {
  return questions.map((question, index) => (
    {
      ...question,
      order: index + 1
    }
  ))
}

export const toggleAllAccordion = (questions, expand) => {
  const newAccordion = {}
  questions.forEach((question) => (
    newAccordion[question.order] = expand === 'all'
  ))
  return newAccordion
}

export const toggleInvalidAccordion = (questions) => {
  const newAccordion = {}
  questions.forEach((question) => (
    newAccordion[question.order] = !question.isValid
  ))
  return newAccordion
}