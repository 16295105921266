import PropTypes from 'prop-types'
import React from 'react'
import './DashboardFilters.scss'

class DashboardFilters extends React.Component {
  state = {
    expanded: false,
    byTerm: '',
    byDisplay: '',
    bySort: 'publicationDate',
    classNameFind: 'questionnaire-filter__input-find',
    classNameFindFocus: 'questionnaire-filter__input-find-on-focus'
  }

  updateFilter = (e, field) => {
    this.setState({
      [field]: e.target.value
    }, () => {
      this.props.filterQuestionnaires(this.state)
    })
  }

  render = () => {
    const { byTerm, classNameFind, classNameFindFocus } = this.state
    return (
      <div className="questionnaireFilter__wrapper">
        <div className="questionnaireFilter__button">
          <label>Find</label>
          <input
            type='text'
            className={ byTerm === '' ? (classNameFind):(classNameFindFocus) }
            onChange={ (e) => { this.updateFilter(e, 'byTerm') }}></input>
        </div>

        <div className="questionnaireFilter__select" id="questionnaireFilter">
          <label>Display</label>
          <select onChange={ (e) => { this.updateFilter(e, 'byDisplay') }}>
            <option value="">All questionnaires</option>
            <option value="active">Active only</option>
            <option value="inactive">Inactive only</option>
            <option value="global">Global only</option>
            <option value="pia">PIA only</option>
            <option value="dpia">DPIA only</option>
            <option value="dataController">Data Controller only</option>
            <option value="dataProcessor">Data Processor only</option>
          </select>
        </div>

        <div className="questionnaireFilter__select" id="questionnaireFilter">
          <label>Sort</label>
          <select onChange={ (e) => { this.updateFilter(e, 'bySort') }}>
            <option value="publicationDate">Last published</option>
            <option value="creationDate">Last created</option>
          </select>
        </div>
      </div>
    )
  }
}

DashboardFilters.propTypes = {
  filterQuestionnaires: PropTypes.func.isRequired
}

export default DashboardFilters