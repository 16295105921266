import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'

import Button from '../Common/Button'
import AlertList from './Alert/AlertList'
import Filters from './Alert/Filters'
import Loading from '../Common/Loading'
import Preview from './Files/Preview'

import { debounce } from '../../utils'

import WithAlerts from './HOC/WithAlerts'
import WithCollections from './HOC/WithCollections'

import './Alert.css'

class Alert extends React.Component {
  constructor() {
    super()

    this.state = {
      accordion: {},
      fileToView: {},
      isModalOpened: false,
      selectedCollection: '',
      selectedStatusLevel: ''
    }

    this.filterByTerm = debounce(this.filterByTerm.bind(this), 1000)
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
    
    const collectionId = this.props.params.id
    this.setState({ selectedCollection: collectionId ? collectionId : '' })

    if (collectionId) this.props.getAlertsForCollection({ id: collectionId })
    else this.props.getAlerts()
  }

  componentWillUnmount = () => {
    this.props.resetAlerts()
  }

  toggleAccordion = (accordion, index) => {
    this.setState({
      accordion: {
        ...accordion,
        [index]: !accordion[index]
      }
    })
  }

  expandAll = () => {
    const newAccordion = [...Array(this.props.totalAlerts)]
      .reduce((acc, item, index) => ({
        ...acc,
        [index]: true
      }), {})
    
    this.setState({ accordion: newAccordion })
  }

  filterByTerm = (event) => {
  }

  filterBy = (field, event) => {
    this.setState({ [field]: event.target.value }, () => {
      const filter = this.getPropertiesToFilter()
      const collection = filter.collection
      const level = filter.level
      this.props.resetAlerts()
        .then(() => this.props.getAlertsForCollection(collection, level))
    })
  }

  getPropertiesToFilter = () => {
    let filter = {}
    if (this.state.selectedCollection !== '') {
      const collectionId = this.state.selectedCollection

      filter = Object.assign(filter, {
        collection: {
          id: collectionId,
          name: this.getCollectionName(parseInt(collectionId, 10))
        }
      })
    }

    if (this.state.selectedStatusLevel !== '') {
      filter = Object.assign(filter, {
        level: this.state.selectedStatusLevel
      })
    }

    return filter
  }

  getCollectionName = (collectionId) => {
    const collection = this.props.collections.find((item) => item.id === collectionId)
    return collection ? collection.name : ''
  }
  
  closeModal = () => this.setState({ isModalOpened: false })

  showModal = (file) => {
    this.setState({ fileToView: file, isModalOpened: true })
  }

  render = () => {
    return (
      <div className="privacyAlert__wrapper">
        { this.state.isModalOpened &&
          <Preview file={ this.state.fileToView } onClose={ this.closeModal } />
        }

        <Filters
          expandAll={ this.expandAll }
          filterBy={ this.filterBy }
          filterByTerm={ this.filterByTerm } 
          selectedCollection={ this.state.selectedCollection } />
        
        <div className="privacyAlert__box">
          <header className="privacyAlert__boxHeader">
            <div>
              <h1 className="privacyAlert__boxTitle">Alerts</h1>
              { !this.props.isFetchingAlerts &&
                <span className="privacyAlert__boxTitleTotal">
                  <strong>{ this.props.totalAlerts }</strong> results matching your filters
                </span>
              }
              <p className="privacyAlert__boxText">
                Alerts leverage collection rules to find and notify you of potentially insecure data.
              </p>
            </div>
            <div className="privacyAlert__boxButton">
              <Button>Export CSV</Button>
            </div>
          </header>
        </div>

        { this.props.isFetchingAlerts
          ? <div className="privacyAlert__loading"><Loading /></div>
          : <AlertList
            accordion={ this.state.accordion }
            alerts={ this.props.alerts }
            showModal={ this.showModal }
            toggle={ this.toggleAccordion }
          />
        }
      </div>
    )
  }
}

Alert.propTypes = {
  alerts: PropTypes.array.isRequired,
  getAlerts: PropTypes.func.isRequired,
  isFetchingAlerts: PropTypes.bool.isRequired,
  resetAlerts: PropTypes.func.isRequired,
  totalAlerts: PropTypes.number.isRequired
}

export default compose(
  WithAlerts,
  WithCollections
  )(Alert)