import React from 'react'
import PropTypes from 'prop-types'
import {
  apiPrivacyGetDataModels,
  apiPrivacyGetFacetValues
} from '../api/Privacy'
import { sortValuesByField } from '../utils'

const DataModelsContext = React.createContext()

class DataModelsProvider extends React.Component {
  state = {
    dataModels: [],
    facetValues: [],
    isFetchingDataModels: true
  }

  getDataModels = () => {
    if (this.state.dataModels.length > 0) return
    
    apiPrivacyGetDataModels()
    .then((response) => {
      const dataModels = response.data.value.datamodels.datamodel[0].metadata.field
      const sortedDataModels = sortValuesByField(dataModels, 'name')

      this.setState({
        dataModels: sortedDataModels,
        isFetchingDataModels: false
      })
    })
      
  }

  getFacetValues = () => {
    apiPrivacyGetFacetValues()
    .then((response) => {
      if (response.status === 200) {
        const facetValues = response.data.value.facets.facet
        this.setState({ facetValues })
      }
    })
  }

  render = () => {
    return (
      <DataModelsContext.Provider value={{
        dataModels: this.state.dataModels,
        facetValues: this.state.facetValues,
        getDataModels: this.getDataModels,
        getFacetValues: this.getFacetValues,
        isFetchingDataModels: this.state.isFetchingDataModels
      }}>
        { this.props.children }
      </DataModelsContext.Provider>
    )
  }
}

DataModelsProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export default DataModelsProvider
export const DataModelsConsumer = DataModelsContext.Consumer