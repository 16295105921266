import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Link, Navigate } from 'react-router-dom'
import { compose } from 'redux'
import * as actions from '../../../actions/messageActions'
import { apiDpiaFetchQuestions, apiDpiaReportDetail } from '../../../api/Dpia'
import logoDpia from '../../../assets/logo-dpia-black.png'
import withRouter from '../../../private-routers/withRouter'
import { userIsUser } from '../../../utils'
import Area from '../../Common/Area'
import Button from '../../Common/Button'
import ErrorPage from '../../Common/ErrorPage'
import Loading from '../../Common/Loading'
import Message from '../../Common/Message'
import './Detail.scss'

export class Detail extends React.Component {
  constructor() {
    super()

    this.state = {
      questions: {
        isLoading: true,
        list: []
      },
      report: {
        isLoading: true,
        error: null
      }
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)

    this.fetchReport()
    this.fetchQuestions()
  }

  componentWillUnmount() {
    this.props.hideMessage()
  }

  fetchQuestions() {
    const idToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId
    const data = { id_token: idToken }
    const { assessmentId } = this.props.params

    apiDpiaFetchQuestions(data, assessmentId)
    .then((response) => {
      this.setState({
        questions: {
          ...this.state.questions,
          isLoading: false,
          list: this.setAnswerOnQuestions(response.data)
        }
      })
    })
  }

  setAnswerOnQuestions(questions) {
    return questions.map((question) => {
      const newOptions = question.options.map((option) => (
        {
          ...option,
          isSelected: question.selectedOptions.find((selectedOption) => (selectedOption === option.id)) !== undefined,
        }
      ))

      return {
        ...question,
        options: newOptions
      }
    })
  }

  fetchReport() {
    const idToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId
    const data = { id_token: idToken }
    const { assessmentId } = this.props.params

    apiDpiaReportDetail(data, assessmentId)
      .then((response) => {
        if (response.data) {
          this.setState({
            report: {
              ...this.state.report,
              ...response.data,
              isLoading: false,
              error: null
            }
          })
        }
      }).catch((error) => {
        this.setState({
          report: {
            isLoading: false,
            error: error.response
          }
        })
      })
  }

  renderQuestions(questions) {
    return questions.map((question) => (
      <div key={ question.id } className="reportDetail-question">
        <h1>{ question.order}. { question.title }</h1>
        <h2>{ question.description }</h2>

        { question.state === 'QUESTIONNAIRE_SKIPPED' &&
          <p>Not applicable</p>
        }

        { question.state !== 'QUESTIONNAIRE_SKIPPED' &&
          question.type === 'YN' &&
          this.renderQuestionYN(question.options)
        }

        { question.state !== 'QUESTIONNAIRE_SKIPPED' &&
          question.type === 'Checkbox' &&
          this.renderQuestionCheckbox(question)
        }

        { question.state !== 'QUESTIONNAIRE_SKIPPED' &&
          question.type === 'Radio' &&
          this.renderQuestionRadio(question)
        }
      </div>
    ))
  }

  renderQuestionYN(options) {
    const item = options.filter((option) => option.isSelected)
    if (item.length > 0) {
      return (
        <p className="reportDetail-answerYN">{item[0].title }</p>
      )
    }
  }

  renderQuestionCheckbox(question) {
    const { options } = question
    const groups = this.groupBy(options, (option) => option.displaySection)

    return (
      <div>
        { Object.keys(groups).map((group) => {
          if (group !== 'null') {
            return (
              <div key={ group }>
                <h2 className="reportDetail-group">{ group }</h2>
                <div>
                  { groups[group].map((option) => (
                    <div key={ option.id }>
                      <div>
                        { option.isSelected &&
                          option.type === 'OTHER' &&
                          <div>
                            <p className="reportDetail-answerAlternativeSelected">{ option.title }</p>
                            <p className="reportDetail-otherOptionText">{ question.otherOptionTexts[option.id] }</p>
                          </div>
                        }

                        { option.isSelected &&
                          option.type !== 'OTHER' &&
                          <div>
                            <p className="reportDetail-answerAlternativeSelected">{ option.title }</p>
                            { option.message &&
                              <p className="reportDetail-otherOptionText">{ option.message }</p>
                            }
                          </div>
                        }

                        { !option.isSelected &&
                          <p className="reportDetail-answerAlternative">{ option.title }</p>
                        }
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )
          }
          
          return (
            <div key={ group }>
              <div>
                { groups[group].map((option) => (
                  <div key={ option.id }>
                    <div>
                      { option.isSelected &&
                        option.type === 'OTHER' &&
                        <div>
                          <p className="reportDetail-answerAlternativeSelected">{ option.title }</p>
                          <p className="reportDetail-otherOptionText">{ question.otherOptionTexts[option.id] }</p>
                        </div>
                      }

                      { option.isSelected &&
                        option.type !== 'OTHER' &&
                        <div>
                          <p className="reportDetail-answerAlternativeSelected">{ option.title }</p>
                          { option.message &&
                            <p className="reportDetail-otherOptionText">{ option.message }</p>
                          }
                        </div>
                      }

                      { !option.isSelected &&
                        <p className="reportDetail-answerAlternative">{ option.title }</p>
                      }
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )
        }) }
      </div>
    )
  }

  // Group the checkboxes
  // We are using the "displaySection" of the option when calling this function
  groupBy(list, funcProp) {
    return list.reduce((acc, val) => {
      (acc[funcProp(val)] = acc[funcProp(val)] || []).push(val)
      return acc
    }, {})
  }

  renderQuestionRadio(question) {
    return question.options.map((option) => {
      if (option.isSelected && option.type === 'OTHER') {
        return (
          <div>
            <p className="reportDetail-answerAlternativeSelected">{ option.title }</p>
            <p className="reportDetail-otherOptionText">{ question.otherOptionTexts[option.id] }</p>
          </div>
        )
      }

      if (option.isSelected) {
        return (
          <p key={ option.id } className="reportDetail-answerAlternativeSelected">{ option.title }</p>
        )
      }
      return (
        <p key={ option.id } className="reportDetail-answerAlternative">{ option.title }</p>
      )
    })
  }

  backButtonUrl(process) {
    if (process.status === 'INACTIVE') return '/dpia/dashboard/archived'
    return '/dpia/dashboard'
  }

  render() {
    const { messageArea } = this.props
    const { report, questions } = this.state
    const isAdmin = !userIsUser(this.props.user)
    if (!isAdmin) return (
      <Navigate to="/dpia/dashboard" />
    )

    return (
      <div className="reportDetail-page">
        { !report.isLoading &&
          !report.error &&
          <Link to={ this.backButtonUrl(report.assessmentProcess)} className="reportDetail-link">Back to DPIA dashboard screen</Link>
        }

        {
          report.error &&
          <ErrorPage error={ report.error } />
        }

        {
          (report.isLoading || questions.isLoading) &&
          !report.error &&
          <div className="reportDetail-box">
            <div className="reportDetail__loading">
              <Loading />
            </div>
          </div>
        }

        { !report.isLoading &&
          !report.error &&
          !questions.isLoading &&
          <div className="reportDetail-box">
            <div className="reportDetail-logoContainer">
              <img className="reportDetail-logo" src={ logoDpia } alt="Osprey DPIA logo" />
              <div className="reportDetail-printButton">
                <Button theme="green" onClick={ window.print }>
                  Print
                </Button>
              </div>
            </div>
            <header className="reportDetail-header">
              <h1 className="reportDetail-title">{ report.assessmentProcess.name }</h1>
              <h1>{ moment(report.assessmentSummary.completionDate).format('MMMM YYYY') }</h1>
            </header>
              <div>
                <h1>Completed by:</h1>
                <h2>{ this.props.location.state.name }</h2>
              </div>
            <div className="reportDetail-section">
              { messageArea.visible &&
                <Message
                  hideMessageAction={ this.props.hideMessage }
                  status={ messageArea.status }
                  text={ messageArea.text } />
              }

              { !messageArea.visible &&
              <div>
                <div className="reportDetail-areasBox">
                  <header className="reportDetail-sectionHeader">
                    <h1 className="reportDetail-sectionTitle">Privacy risk indicators</h1>
                    <h2 className="reportDetail-sectionSubtitle">Here are your privacy indicators:</h2>
                  </header>

                  <div className="reportDetail-content">
                    <div className="reportDetail-block">
                      <Area
                        description='Risks related to a sensitive market (i.e. elderly, children, etc.) and/or sensitive data (i.e. health, finance).'
                        icon='Sensitivity'
                        title='Sensitivity (SEN)'
                      />

                      <Area
                        description='Risks related to compliance with external standards, policies, laws, etc.'
                        icon='Compliance'
                        title='Compliance (C)'
                      />

                      <Area
                        description='Risks related to transparency in the areas of notice/user messaging and choice/consent.'
                        icon='Transparency'
                        title='Transparency (T)'
                      />

                      <Area
                        description='Risks related to security of data and data flows.'
                        icon='Security'
                        title='Security (SEC)'
                      />
                    </div>

                    <div className="reportDetail-block">
                      <Area
                        description='Risks related to transfer of information across national borders.'
                        icon='Transborder'
                        title='Trans-border data flow (TB)'
                      />

                      <Area
                        description='Risks related to control of the data lifecycle (i.e., collection, usage, quality, and/or retention).'
                        icon='DataControl'
                        title='Data control (DC)'
                      />

                      <Area
                        description='Risks related to sharing data with third parties.'
                        icon='DataSharing'
                        title='Data sharing (DS)'
                      />
                    </div>
                  </div>
                </div>

                <div>
                  { this.renderQuestions(questions.list) }
                </div>
              </div>
              }
            </div>
          </div>
        }
      </div>
    )
  }
}

const stateMap = (state) => ({
  messageArea: state.messageAreaReducer.messageArea,
  user: state.userReducer.user
})

const dispatchMap = (dispatch) => ({
  hideMessage: () => dispatch(actions.hideMessage()),
  showMessage: (status, text) => dispatch(actions.showMessage({ status, text }))
})

Detail = withRouter(Detail)

Detail.propTypes = {
  hideMessage: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  messageArea: PropTypes.object.isRequired,
  showMessage: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
}

export default compose(connect(stateMap, dispatchMap))(Detail)