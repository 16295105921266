import React from 'react'
import './Clutter.scss'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import {Button, Checkbox, Table} from "antd";
import {
    apiGetBoxClutterStatus,
    getClutterEmails,
    getListClutter
} from "../../../../api/SmartBox";
import {customConfirm} from "../../../../utils/AssetRegister/CustomConfirm";
import EmptyFoldersPage from "./ClutterPage/EmptyFoldersPage"
import EmailThreadsPage from "./ClutterPage/EmailThreadsPage"
import CustomNotificationManager from "../../../Common/CustomNotificationManager";
import cookie from "react-cookies";
const { Column } = Table
class ClutterTable extends React.Component {
    state = {
        listTableClutter: [],
        selectedRowKeys: [],
        filesSelect: [],
        checkSelectAll: false,
        valueSelectedAll: false,
        filesSelectName: [],
        setIntervalClutter: 0,
        checkStatusClutter: '',
        loadingStateDeclutterBtn: false,
        setIntervalListClutter: 0,
        answerListClutter: '',
        onSelectActionClutter: {},
        filesSelectEmptyFiles: [],
        searchWord: null,
        selectAll: false,
        declutterEmailsFirstPageHashList: [],
        declutterEmailsSecondPageHashList: []
    }
    componentDidMount() {
      this.onGetClutterStatus()
        cookie.remove('checkListClutter', { path: '/' })
        cookie.remove('checkListNearFileBox', { path: '/' })
        cookie.remove('checkListDuplicateFileBox', { path: '/' })
        cookie.remove('checkDeduplicationStatus', { path: '/' })
        if (this.props.activeTab === 'clutter') {
            this.setState({
                onSelectActionClutter: {}
            })
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.activeTab !== this.props.activeTab && this.props.activeTab === 'clutter') {
            cookie.remove('checkListNearFileBox', { path: '/' })
            cookie.remove('checkListDuplicateFileBox', { path: '/' })
            cookie.remove('checkDeduplicationStatus', { path: '/' })
            this.onGetClutterStatus()
        }
        if (prevProps.activeTab !== this.props.activeTab && this.props.activeTab !== 'clutter') {
            this.setState({
                onSelectActionClutter: {},
                filesSelect: [],
                selectedRowKeys: [],
                checkSelectAll: false,
            })
        }
    }
    onGetClutterStatus = () => {
        const { setIntervalClutter } = this.state
        const { folder } = this.props
        apiGetBoxClutterStatus(folder.id).then((response) => {
            if (response.status === 200) {
                this.setState({
                    checkStatusClutter: response.data,
                    loadingStateDeclutterBtn:false,
                })
                if (response.data === 'In Progress') {
                    this.setIntervalClutterStatus()
                } else if (response.data === 'Finished') {
                    clearInterval(setIntervalClutter)
                    this.setState({setIntervalClutter, loadingStateDeclutterBtn:false})
                    this.getClutterListTable()
                }
            }
        })
    }

    setIntervalClutterStatus = () => {
        const { checkStatusClutter } = this.state
        let { setIntervalClutter } = this.state
        if (checkStatusClutter === 'In Progress') {
            setIntervalClutter = setInterval(this.callBackClutterStatus, 1000)
            this.setState({
                setIntervalClutter
            })
        } else {
            clearInterval(setIntervalClutter)
            this.setState({
                loadingStateDeclutterBtn:false,
            })
        }
    }
    callBackClutterStatus = () => {
        const { setIntervalClutter, checkStatusClutter } = this.state
        const { folder } = this.props
        if (checkStatusClutter === 'In Progress') {
            apiGetBoxClutterStatus(folder.id).then((response) => {
                if (response.data === 'Finished') {
                    this.getClutterListTable()
                    clearInterval(setIntervalClutter)
                    this.setState({ setIntervalClutter })
                }  else {
                    this.setState({ setIntervalClutter })
                }
                this.setState({
                    checkStatusClutter: response.data
                })
            })
        } else if (checkStatusClutter === 'In Progress') {
            clearInterval(setIntervalClutter)
            this.setState({ setIntervalClutter })
        }
    }
    getClutterListTable = () => {
        const { setIntervalListClutter } = this.state
        const { folder } = this.props
        const listClutter = []
        let parentFolderName = ''
        if (typeof folder.relativePath !== 'undefined') {
            parentFolderName = folder.relativePath.split('/')[1]
        }
        const indexDestinationParent = folder && folder.path ? folder.path.indexOf(parentFolderName) : ''
        const newDestinationParent = folder && folder.path ? folder.path.substr(0, indexDestinationParent) : ''
        const rootPathParent = `${ newDestinationParent }${ parentFolderName }`
        const requestClutterFileBoxDTO = {
            boxPath: rootPathParent,
        }
        getListClutter(requestClutterFileBoxDTO).then((response) => {
            cookie.save('checkListClutter', rootPathParent, {path: '/'})
            this.setState({
                answerListClutter: response.data
            })
            if(response.data.title && response.data.title.toLowerCase() === 'info') {
                CustomNotificationManager.info(response.data.message, 'Info')
                this.setIntervalGetListClutter()
            } else {
                Object.keys(response.data) && Object.keys(response.data).length > 0 && Object.keys(response.data).map((list, index) => {
                    listClutter.push({
                        nameClutter: list,
                        listClutter: response.data[list],
                        key: index
                    })
                    return true
                })
                if (Object.keys(response.data) && Object.keys(response.data).length > 0) {
                    clearInterval(setIntervalListClutter)
                    this.setState({
                        setIntervalListClutter
                    })
                }
                const listClutterFilter = listClutter && listClutter.length > 0 && listClutter.filter((el) => el.listClutter.length !== 0)
                this.setState({
                    listTableClutter: listClutterFilter,
                })
            }
        })
    }

    setIntervalGetListClutter = () => {
        let { setIntervalListClutter } = this.state
        const { answerListClutter } = this.state
        if (setIntervalListClutter === 0) {
            if (answerListClutter.title && answerListClutter.title === 'Info') {
                setIntervalListClutter = setInterval(this.onCallBackGetListClutter, 5000)
                this.setState({
                    setIntervalListClutter
                })
            } else {
                clearInterval(setIntervalListClutter)
            }
        }
    }

    onCallBackGetListClutter = () => {
        const { answerListClutter } = this.state
        const { folder } = this.props
        const listClutter = []
        let parentFolderName = ''
        if (typeof folder.relativePath !== 'undefined') {
            parentFolderName = folder.relativePath.split('/')[1]
        }
        const indexDestinationParent = folder && folder.path ? folder.path.indexOf(parentFolderName) : ''
        const newDestinationParent = folder && folder.path ? folder.path.substr(0, indexDestinationParent) : ''
        const rootPathParent = `${ newDestinationParent }${ parentFolderName }`
        const requestClutterFileBoxDTO = {
            boxPath: rootPathParent,
        }
        const itemListClutter = cookie.load('checkListClutter', { path: '/' })
        if (answerListClutter.title && answerListClutter.title === 'Info' && (rootPathParent && itemListClutter === rootPathParent)) {
            getListClutter(requestClutterFileBoxDTO).then((response) => {
                this.setState({
                    answerListClutter: response.data
                })
                if(response.data.title && response.data.title.toLowerCase() === 'info') {

                } else {
                    Object.keys(response.data) && Object.keys(response.data).length > 0 && Object.keys(response.data).map((list, index) => {
                        listClutter.push({
                            nameClutter: list,
                            listClutter: response.data[list],
                            key: index
                        })
                        return true
                    })
                    if (Object.keys(response.data) && Object.keys(response.data).length > 0) {
                        cookie.remove('checkListClutter', { path: '/' })
                        const listClutterFilter = listClutter && listClutter.length > 0 && listClutter.filter((el) => el.listClutter.length !== 0)
                        this.setState({
                            listTableClutter: listClutterFilter,
                        })
                    }
                }
            })
        }
    }
    onSelectClutter = (selectedRowKeys, filesSelect, e) => {
        const { listTableClutter } = this.state
        const nameSelect = []
        filesSelect && filesSelect.length > 0 && filesSelect.forEach((file) => {
            nameSelect.push(file.nameClutter)
        })
        this.setState({
            filesSelectName: nameSelect
        })
        this.setState({
            selectedRowKeys: selectedRowKeys,
            filesSelect: filesSelect })
        if (filesSelect && filesSelect.length > 0) {
            this.setState({
                valueSelectedAll: true
            })
        } else {
            this.setState({
                valueSelectedAll: false
            })
        }
        if (listTableClutter.length > selectedRowKeys.length) {
            this.setState({
                checkSelectAll: false
            })
        }
    }
    onSelectedRowClutter = (e, record) => {
        this.setState({
            onSelectActionClutter: record
        })
    }
    autoSelectedAll = (e) => {
        const { listTableClutter } = this.state
        const selectAllFiles = []
        const selectedAllKeys = []
        if (e.target.checked) {
            listTableClutter && listTableClutter.length > 0 && listTableClutter.forEach((file, index) => {
                file.key = index
                selectedAllKeys.push(index)
                selectAllFiles.push(file)
            })
          this.setState({
              selectedRowKeys: selectedAllKeys,
              checkSelectAll: true,
              filesSelect: selectAllFiles
          })
        } else {
            this.setState({
                selectedRowKeys: [],
                checkSelectAll: false,
                filesSelect: []
            })
        }
        if (listTableClutter && listTableClutter.length > 0) {
            this.setState({
                valueSelectedAll: e.target.checked
            })
        } else {
            this.setState({
                valueSelectedAll: false
            })
        }
    }
    handleDeclutter = (e) => {
        e.preventDefault()
        customConfirm({
            title: <span className="icon-v21-clean"></span>,
            content: 'Are you sure you want to declutter?',
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
            mask: true,
            maskClosable: false,
            closeHaveLogic: false,
            className: 'asset-register-modal modal-delete-duplicate confirm-declutter',
            centered: true,
            cancelClassName: 'cancel-button',
            confirmClassName: 'confirm-button',
            confirmAction: () => {
                this.onActionDeclutter()
            }
        })
    }
    onActionDeclutter = () => {
        const { folder } = this.props
        const { checkSelectAll, filesSelectName, filesSelectEmptyFiles, searchWord, selectAll, declutterEmailsFirstPageHashList, declutterEmailsSecondPageHashList } = this.state
        let parentFolderName = ''
        if (typeof folder.relativePath !== 'undefined') {
            parentFolderName = folder.relativePath.split('/')[1]
        }
        const indexDestinationParent = folder && folder.path ? folder.path.indexOf(parentFolderName) : ''
        const newDestinationParent = folder && folder.path ? folder.path.substr(0, indexDestinationParent) : ''
        const rootPathParent = `${ newDestinationParent }${ parentFolderName }`
        const dtoDeclutter = {
            boxPath: rootPathParent,
            declutterFoldersIds: filesSelectEmptyFiles && filesSelectEmptyFiles.length > 0 ? filesSelectEmptyFiles : null,
            declutterEmailsFirstPageHashList: declutterEmailsFirstPageHashList && declutterEmailsFirstPageHashList.length > 0 ? declutterEmailsFirstPageHashList : null,
            declutterEmailsSecondPageHashList: declutterEmailsSecondPageHashList && declutterEmailsSecondPageHashList.length > 0 ? declutterEmailsSecondPageHashList : null,
            searchWord: searchWord,
            selectAll: selectAll,
            declutterEmails: checkSelectAll ? true : filesSelectName && filesSelectName.length > 0 && filesSelectName.includes('emailThreadingItems') ? true : false,
            declutterFolders: checkSelectAll ? true : filesSelectName && filesSelectName.length > 0 &&  filesSelectName.includes('emptyFolderDTOS') ? true : false,
        }
        getClutterEmails(dtoDeclutter).then(() => {
            this.setState({
                loadingStateDeclutterBtn:true,
                selectedRowKeys: [],
            })

            this.setState({
                listTableClutter: []
            }, () => {
                this.onGetClutterStatus()
            })
        })
    }

    onGetSelectFilesEmptyFolder = (filesSelect) => {
        let filesSelectEmptyFiles = []
        if (filesSelect && filesSelect.length > 0) {
            filesSelect.forEach((item) => {
                filesSelectEmptyFiles.push(item.folderId)
            })
        } else {
            filesSelectEmptyFiles = []
        }
        this.setState({
            filesSelectEmptyFiles: filesSelectEmptyFiles
        })
    }
    onGetSearchEmptyFolder = (search) => {
       this.setState({
           searchWord: search
       })
    }
    onGetSelectAllEmptyFolder = (selectAll) => {
        this.setState({
            selectAll: selectAll
        })
    }
    onGetSelectAllEmailThreads = (selectAll) => {
        this.setState({
            selectAll: selectAll
        })
    }
    onGetDeclutterEmailsFirstPageHashList = (declutterEmailsFirstPageHashList) => {
       this.setState({
           declutterEmailsFirstPageHashList: declutterEmailsFirstPageHashList
       })
    }
    onGetDeclutterEmailsSecondPageHashList = (declutterEmailsSecondPageHashList) => {
        this.setState({
            declutterEmailsSecondPageHashList: declutterEmailsSecondPageHashList
        })
    }
    onBackFilesEmptyFolder = (status) => {
       if (status){
           this.setState({
               onSelectActionClutter: {}
           })
       }
    }
    render() {
        const { listTableClutter, selectedRowKeys, filesSelect, checkSelectAll, valueSelectedAll, checkStatusClutter, loadingStateDeclutterBtn,
            onSelectActionClutter, filesSelectEmptyFiles, selectAll, declutterEmailsFirstPageHashList,  declutterEmailsSecondPageHashList} = this.state
        const { folder } = this.props
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectClutter,
        }
        return (
            <React.Fragment>
                { onSelectActionClutter && onSelectActionClutter.nameClutter === 'emptyFolderDTOS' ?
                    <div className="clutter-table">
                        <div className="clutter-table__content">
                            <div className="content-actions-declutter">
                                <div className="button-auto-go">
                                    <Button className={loadingStateDeclutterBtn ? "button-loading" : "button-blue-go"}
                                            onClick={(e) => this.handleDeclutter(e)}
                                            disabled={
                                                (filesSelectEmptyFiles.length === 0 || checkStatusClutter === 'In Progress') &&
                                                (selectAll === false || checkStatusClutter === 'In Progress') &&
                                                (selectedRowKeys.length === 0 || !valueSelectedAll || listTableClutter.length === 0 || checkStatusClutter === 'In Progress')}
                                            loading={loadingStateDeclutterBtn}>
                                        Declutter
                                    </Button>
                                </div>
                            </div>
                          <EmptyFoldersPage folder={ folder } onGetSelectFilesEmptyFolder={ this.onGetSelectFilesEmptyFolder}
                                            onGetSearchEmptyFolder = {this.onGetSearchEmptyFolder}
                                            onGetSelectAllEmptyFolder = {this.onGetSelectAllEmptyFolder}
                                            onBackFilesEmptyFolder = {this.onBackFilesEmptyFolder}
                                            checkStatusClutter={ checkStatusClutter }/>
                    </div>
                    </div> :
                    onSelectActionClutter && onSelectActionClutter.nameClutter === 'emailThreadingItems' ?
                        <div className="clutter-table">
                            <div className="clutter-table__content">
                                <div className="content-actions-declutter">
                                    <div className="button-auto-go">
                                        <Button className={loadingStateDeclutterBtn ? "button-loading" : "button-blue-go"}
                                                onClick={(e) => this.handleDeclutter(e)}
                                                disabled={
                                                    (filesSelectEmptyFiles.length === 0 || checkStatusClutter === 'In Progress') &&
                                                    (declutterEmailsFirstPageHashList.length === 0 || checkStatusClutter === 'In Progress') &&
                                                    (declutterEmailsSecondPageHashList.length === 0 || checkStatusClutter === 'In Progress') &&
                                                    (selectAll === false || checkStatusClutter === 'In Progress') &&
                                                    (selectedRowKeys.length === 0 || !valueSelectedAll || listTableClutter.length === 0 || checkStatusClutter === 'In Progress')}
                                                loading={loadingStateDeclutterBtn}>
                                            Declutter
                                        </Button>
                                    </div>
                                </div>
                                <EmailThreadsPage folder={ folder } onGetSelectFilesEmptyFolder={ this.onGetSelectFilesEmptyFolder}
                                                  onGetSearchEmptyFolder = {this.onGetSearchEmptyFolder}
                                                  onGetSelectAllEmailThreads = {this.onGetSelectAllEmailThreads}
                                                  onGetDeclutterEmailsFirstPageHashList = {this.onGetDeclutterEmailsFirstPageHashList}
                                                  onGetDeclutterEmailsSecondPageHashList = {this.onGetDeclutterEmailsSecondPageHashList}
                                                  onBackFilesEmptyFolder = {this.onBackFilesEmptyFolder}
                                                  checkStatusClutter={ checkStatusClutter }/>
                            </div>
                        </div>
                    :
                    <div className="clutter-table">
                        <div className="clutter-table__content">
                            <div className="content-actions-declutter">
                                <div className="button-auto-go">
                                    <Button className={loadingStateDeclutterBtn ? "button-loading" : "button-blue-go"}
                                            onClick={(e) => this.handleDeclutter(e)}
                                            disabled={selectedRowKeys.length === 0 || !valueSelectedAll || listTableClutter.length === 0 || checkStatusClutter === 'In Progress'}
                                            loading={loadingStateDeclutterBtn}>
                                        Declutter
                                    </Button>
                                </div>
                            </div>
                            <div className="clutter-table-header">
                                <div className="content-select-all-clutter">
                                    <div
                                        className={listTableClutter && listTableClutter.length > 0 ? '' : filesSelect.length === 0 ? 'checkbox-auto-disabled' : ''}>
                                        <div
                                            className={checkSelectAll ? "checkbox-auto" : filesSelect.length === 2 ? 'checkbox-auto' : !checkSelectAll && selectedRowKeys.length > 0 ? 'select-non-checkbox' : 'checkbox-auto'}>
                                            <Checkbox
                                                checked={filesSelect.length === 0 ? false : filesSelect.length === 2 ? true : true}
                                                className="select-deduplicate-checkbox"
                                                disabled={checkStatusClutter === 'In Progress' || listTableClutter.length === 0}
                                                onChange={(e) => this.autoSelectedAll(e)}></Checkbox>
                                        </div>
                                    </div>
                                    <div className="select-auto">
                                        <span
                                            className={typeof listTableClutter === 'undefined' || listTableClutter.length === 0 ? 'option-select-all-disabled' : ''}>Select all</span>
                                    </div>
                                </div>
                            </div>

                            <div className="table-clutter-content">
                                <Table className="table-clutter-files"
                                       dataSource={listTableClutter}
                                       pagination={false}
                                       showHeader={false}
                                       loading={checkStatusClutter === 'In Progress'}
                                       rowSelection={rowSelection}
                                       onRow={(record, rowIndex) => {
                                           return {
                                               onClick: (e) => this.onSelectedRowClutter(e, record)
                                           }
                                       }}>
                                    <Column className="column-name-clutter" title="Name" dataIndex="nameClutter"
                                            key="nameClutter" render={(text, record) => {
                                        return (
                                            <span className="name-duplicate-content">
                                        <span
                                            className="text-style">{text === 'emailThreadingItems' ? 'Email threads' : text === 'emptyFolderDTOS' ? 'Empty folders' : ''}</span>
                                    </span>
                                        )
                                    }}/>
                                    <Column className="total-files" title="Total duplicates"
                                            dataIndex="numberOfDuplicates" key="numberOfDuplicates"
                                            render={(text, render) => {
                                                return (
                                                    <span className="total-files-content">
                                    <span className="number-duplicate"><span
                                        className="nr-dupl">{render.listClutter.length}</span></span>
                                   </span>
                                                )
                                            }}/>
                                </Table>
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        )

    }
}

ClutterTable.propTypes = {
    folder: PropTypes.object,
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id
})
export default compose(
    connect(mapStateToProps),
)(ClutterTable)