import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import Loading from '../../Common/Loading'

import './ConnectorTable.css'

class ConnectorTable extends React.Component {
  state = { isMenuOpened: false }

  toggleMenu = () => {
    this.setState({ isMenuOpened: !this.state.isMenuOpened })
  }

  render = () => {
    const { connector } = this.props

    return (
      <tr className="connectorTable__row">
        <td style={{ textAlign: 'left' }}><strong>{ connector.name }</strong></td>
        <td style={{ textAlign: 'center' }}>
          { connector.isFetchingInfo
            ? <div className="connectorTable__loading"><Loading /></div>
            : `${ Math.round(connector.docSize/1024/1024) } MB`
          }
        </td>
        <td style={{ textAlign: 'center' }}>
          { connector.isFetchingInfo
            ? <div className="connectorTable__loading"><Loading /></div>
            : connector.filesCounter
          }
        </td>
        <td
          className="connectorTable__actions"
          style={{ textAlign: 'center' }}
          onClick={ this.toggleMenu } >
          ...
          { this.state.isMenuOpened &&
            <ul className="connectorTable__menu">
              <li className="connectorTable__menu-item">
                <span onClick={ this.props.actionRescan.bind(this, connector.id) }>Rescan</span>
              </li>
              <li className="connectorTable__menu-item">
                <Link to={ `/privacy/connector/${ connector.id }` }>
                  Edit
                </Link>
              </li>
            </ul>
          }
        </td>
      </tr>
    )
  }
}

ConnectorTable.propTypes = {
  actionRescan: PropTypes.func.isRequired,
  connector: PropTypes.object.isRequired
}

export default ConnectorTable