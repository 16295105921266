import React from 'react'

import './EditInPlace.css'

class EditInPlace extends React.Component {
  state = {
    initialValue: '',
    isEditing: false,
    value: ''
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.value !== this.state.value) {
      this.setState({ initialValue: nextProps.value, value: nextProps.value })
    }
  }

  showInput = () => {
    this.setState({ isEditing: true })
  }

  pressedKey = (event) => {
    const pressedKey = event.keyCode

    // 'esc' key
    if (pressedKey === 27) {
      return this.setState({ isEditing: false, value: this.state.initialValue })
    }
    
    // 'enter' key
    if (pressedKey === 13) {
      this.setState({ isEditing: false })
      this.save()
    }
  }

  handleInput = (event) => {
    this.setState({ value: event.target.value })
  }

  save = () => {
    this.setState({ isEditing: false })
    this.props.save(this.state.value)
  }

  render() {
    return (
      <div className="editInPlace__wrapper">
        { this.state.isEditing
          ? <input
            autoFocus
            className="editInPlace__input"
            type="text"
            value={ this.state.value }
            onBlur={ this.save }
            onChange={ this.handleInput }
            onKeyUp={ this.pressedKey } />
          : <span onClick={ this.showInput }>
            { this.state.value }
          </span>
        }
      </div>
    )
  }
}

export default EditInPlace