import React from 'react'
import { apiGetSmartBoxMetadata } from '../../../api/SmartBox'
import { apiGetSarMetadata } from '../../../api/Sar'
import CustomNotificationManager from "../../Common/CustomNotificationManager";

class MetadataPage extends React.Component {
  constructor() {
    super()
    this.state = {
      listMetadata: []
    }
  }

  componentDidMount() {
    this.updateDatesMetadata()
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.isNextDocumentId !== this.props.isNextDocumentId) {
      this.updateDatesMetadata()
    }
  }
  updateDatesMetadata() {
    if (this.props.isSmartBoxApplication) {
      const getMetadataDTO = {
        docId: this.props.documentId
      }
      apiGetSmartBoxMetadata(getMetadataDTO).then((response) => {
        this.setState({
          listMetadata: response.data
        })
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
            console.log(error)
        }
      })
    }
    else {
      apiGetSarMetadata(this.props.documentId).then((response) => {
        this.setState({
          listMetadata: response.data
        })
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
            console.log(error)
        }
      })
    }
  }
  render() {
    const { listMetadata } = this.state
    return (
      <div className="metadata-page">
        {/*<div className="title-metadata">METADATA</div>*/}
        { listMetadata &&
          <div className="contain-categories">
            <div className="title-categories">{listMetadata.title ? `Title: ${ listMetadata.title }` : 'Title: -'}</div>
            {/*<div className="title-categories">{listMetadata.subject ? `Subject: ${ listMetadata.subject }` : ''}</div>*/}
            {/*{ listMetadata.tags && listMetadata.tags.length > 0 ?*/}
            {/*  <React.Fragment>*/}
            {/*    { listMetadata.tags.map((tag) => {*/}
            {/*      return (*/}
            {/*        <div className="title-categories">{`Tags: ${ tag }`}</div>*/}
            {/*      )*/}
            {/*    })*/}
            {/*    }*/}
            {/*  </React.Fragment> : <div className="title-categories">Tags: -</div>*/}
            {/*}*/}

            {/*{ listMetadata.categories && listMetadata.categories.length > 0 ?*/}
            {/*  <React.Fragment>*/}
            {/*    { listMetadata.categories.map((category) => {*/}
            {/*      return (*/}
            {/*        <div className="title-categories">{`Categories: ${ category }`}</div>*/}
            {/*      )*/}
            {/*    })*/}
            {/*    }*/}
            {/*  </React.Fragment> : <div className="title-categories">Categories: -</div>*/}
            {/*}*/}
            <div className="title-categories">{listMetadata && listMetadata.dateCreated ? `Date created: ${ listMetadata.dateCreated }` : 'Date created: -'}</div>
            <div className="title-categories">{listMetadata && listMetadata.dateModified ? `Date modified: ${ listMetadata.dateModified }` : 'Date modified: -'}</div>
            <div className="title-categories">{listMetadata && listMetadata.dateUpload ? `Date uploaded: ${ listMetadata.dateUpload }` : 'Date uploaded: -'}</div>
            {/*{ listMetadata.authors && listMetadata.authors.length > 0 ?*/}
            {/*  <React.Fragment>*/}
            {/*    {*/}
            {/*      listMetadata.authors.map((author) => {*/}
            {/*        return (*/}
            {/*          <div className="title-categories">{`Authors: ${ author }`}</div>*/}
            {/*        )*/}
            {/*      })*/}
            {/*    }*/}
            {/*  </React.Fragment> : <div className="title-categories">Authors: -</div>*/}
            {/*}*/}
            {/*<div className="title-categories">{listMetadata.availableOffline ? `Availability: ${ listMetadata.availableOffline }` : 'Availability: -'}</div>*/}
            <div className="title-categories size-content">{listMetadata && listMetadata.size ? `Size: ${ Number(listMetadata.size).toFixed(2) } MB` : 'Size: -'}</div>
            <div className="title-categories">{listMetadata.pages ? `Pages: ${ listMetadata.pages }` : 'Pages: -'}</div>
            {/*<div className="title-categories">{listMetadata.namesInTotal ? `Found Names in total: ${ listMetadata.namesInTotal }` : 'Found Names in total: -'}</div>*/}
            {/*<div className="title-categories">{listMetadata.totalRedaction ? `Total redactions: ${ listMetadata.totalRedaction }` : 'Total redactions: -'}</div>*/}
            {/*<div className="title-categories">{listMetadata.totalAnnotations ? `Total annotations: ${ listMetadata.totalAnnotations }` : 'Total annotations: -'}</div>*/}

          </div>
        }
      </div>
    )
  }
}

export default MetadataPage