import { updateQuestionsOrder } from './Utils'

const addDeletedQuestions = (questions, deletedQuestions, index) => {
  const removedQuestion = questions[index]
  if (!removedQuestion.id) return deletedQuestions
  return [
    ...deletedQuestions,
    removedQuestion.id
  ]
}

export default ({ questions, deletedQuestions, index }) => {
  const newQuestionsList = [...questions]
  const newDeletedQuestions = addDeletedQuestions(questions, deletedQuestions, index)

  if (newQuestionsList.length === 1) return { questions: newQuestionsList, deletedQuestions: newDeletedQuestions }

  newQuestionsList.splice(index, 1)
  return {
    questions: updateQuestionsOrder(newQuestionsList),
    deletedQuestions: newDeletedQuestions
  }
}