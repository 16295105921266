import React from 'react'
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import PropTypes from 'prop-types'

class LineChart extends React.Component {
  render() {
    return (
      <div className="w-100">
        <Chart type='line'
          data={
            {
              labels: this.props.lineChartStateLabels,
              datasets: [
                {
                  label: 'Value',
                  fill: false,
                  lineTension: 0.3,
                  backgroundColor: 'rgba(75,192,192,1)',
                  borderColor: '#d8d8d8',
                  borderWidth: 7,
                  data: this.props.lineChartStateValues,
                  pointBackgroundColor: '#ffffff',
                  pointBorderColor: '#4bd46a',
                  pointBorderWidth: 6,
                  pointRadius: 8,
                  pointHoverBackgroundColor: '#4bd46a',
                  pointHoverBorderColor: '#4bd46a',
                  pointHoverBorderWidth: 6,
                  pointHoverRadius: 8
                }
              ]
            }
          }
          height={ 400 }
          options={{
            animation: {
              duration: 0
            },
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
            },
            scales: {
              x: {
                beginAtZero: true,
                display:false,
              },
              y: {
                beginAtZero: true,
              }
            }
          }}
        />
      </div>
    )
  }
}

LineChart.propTypes = {
  lineChartStateLabels: PropTypes.array.isRequired,
  lineChartStateValues: PropTypes.array.isRequired
}

export default LineChart