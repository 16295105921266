import React from 'react'
import {
  apiSarSecurelySendEmail,
  getSarDocumentsThumbnailId,
  getSarDocumentsSaved,
  apiSarCheckSendDocuments
} from '../../../../api/Sar'
import CustomNotificationManager from '../../../Common/CustomNotificationManager'
import {Radio, Table, Checkbox, Spin, Tooltip, Steps, Button, Icon} from 'antd'
import Loading from '../../../Common/Loading'
import imgJpeg from '../../../../assets/empty_jpeg.png'
import imgPdf from '../../../../assets/empty_pdf.png'
import PaginationData from './PaginationData'
import moment from 'moment'
import imgFolderSmallParent from '../../../../assets/smart-folder-medium-parent.svg'
import { connect } from 'react-redux'
import {setSmartBoxNotSarTracker} from "../../../../actions/smartBoxActions";
const { Step } = Steps
class SendSecurelyStep extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      sendEmailNotification: true,
      visibleContent: false,
      fileList: [],
      valueSelectedBox: '',
      totalDocuments: 0,
      totalPagesFiles: 0,
      totalSizeFiles: 0,
      selectedLetter: 'all',
      totalPages: 1,
      amountPerPage: 10,
      newFileList: [],
      checkEmailSendFeedback: false,
      loadingEndRequest: false,
      responseCheckDocuments: false,
      setIntervalDocuments: 0,
      emptyDescriptionTable: false,
      pageNumber: 1
    }
  }

  lettersCategory = ['All', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
  componentDidMount() {
    //const lastSarStepCompleted = this.props.reviewStep ? this.props.reviewStep.completed : false
    const { sar } = this.props
    const lastSarStepCompleted = this.props.searchFindStep ? this.props.searchFindStep.completed : false
    if (lastSarStepCompleted) {
      //this.fetchImages(this.props.sar.id)
      //this.fetchSarDocumentsSaved(this.props.sar.id)

      this.sendFetchSarDocumentSaved()

      // getMessageForStepSeven(this.props.sar.id).then((response) => {
      //   this.setState({
      //     messageTotalDocuments: response.data.totalDocuments,
      //     messageRedactedDocuments: response.data.redactedDocuments
      //   })
      // })
    }
    if (this.props.sendStep && !this.props.sendStep.completed && this.props.sar.feedbackLinkVisited && this.props.sar.feedbackLinkVisited.length > 0) {
      let idLinkVisited = []
      const notLinkVisited = [{
        nameActionEvent: `Request download link visited by subject`,
        descriptionActionEvent: `${ this.props.sar.forename }`,
        timesEvent: moment(this.props.sar.feedbackLinkVisited[0]).format('DD/MM/YYYY HH:mm:ss'),
        status: 'update-sar'
      }]
      idLinkVisited = notLinkVisited.concat(this.props.smartBoxNotSarTracker)
      const uniqueVerificationVisited = idLinkVisited && idLinkVisited.length > 0 && idLinkVisited.filter((a, i) => idLinkVisited.findIndex((s) => a.nameActionEvent === s.nameActionEvent) === i)
      this.props.setSmartBoxNotSarTracker(uniqueVerificationVisited)
    }
    if (sar.feedbackSubmitted && sar.feedbackSubmitted.length > 0 && this.props.sendStep && !this.props.sendStep.completed && !this.props.sendStep.documentsFound) {
      let dataDeleted = []
      const notDataDeleted = [{
        nameActionEvent: `Subject requested data is deleted`,
        descriptionActionEvent: `${ this.props.sar.forename }`,
        timesEvent: moment(sar.feedbackSubmitted[0]).format('DD/MM/YYYY HH:mm:ss'),
        status: 'update-sar'
      }]
      dataDeleted = notDataDeleted.concat(this.props.smartBoxNotSarTracker)
      const uniqueDataDeleted = dataDeleted && dataDeleted.length > 0 && dataDeleted.filter((a, i) => dataDeleted.findIndex((s) => a.nameActionEvent === s.nameActionEvent) === i)
      this.props.setSmartBoxNotSarTracker(uniqueDataDeleted)
    }
     if (sar.feedbackSubmitted && sar.feedbackSubmitted.length > 0 && this.props.sendStep && !this.props.sendStep.completed && this.props.sendStep.documentsFound) {
      let dataKeep = []
      const notDataKeep = [{
        nameActionEvent: `Subject requested data is kept`,
        descriptionActionEvent: `${ this.props.sar.forename }`,
        timesEvent: moment(sar.feedbackSubmitted[0]).format('DD/MM/YYYY HH:mm:ss'),
        status: 'update-sar'
      }]
      dataKeep = notDataKeep.concat(this.props.smartBoxNotSarTracker)
      const uniqueDataKeep = dataKeep && dataKeep.length > 0 && dataKeep.filter((a, i) => dataKeep.findIndex((s) => a.nameActionEvent === s.nameActionEvent) === i)
      this.props.setSmartBoxNotSarTracker(uniqueDataKeep)
     }
  }
  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   if (prevProps !== this.props) {
  //     this.fetchImages(this.props.sar.id)
  //   }
  // }

  columns = [
    {
      title: 'Files',
      dataIndex: 'name',
      key: 'name',
      className: 'column-name',
      render: (text, record) => {

        const newRelativeName = record.name ? record.name.substr(0, record.name.length - `.${ record.originalType }`.length) : ''
        return (
          <div className='content-name-column'>
            { record.thumbUrl ? <Tooltip placement="right" overlayClassName="preview-file-data" title={<img alt={record.thumbUrl} src={ record.thumbUrl }></img>}><img alt={record.thumbUrl} src={ record.thumbUrl }></img></Tooltip> : <span className="icon-v10-extension-pdf"></span> }
            <span className="relative-name">{ newRelativeName }</span>
          </div>
        )
      }
    },
    {
      title: '',
      dataIndex: 'totalPages',
      key: 'totalPages',
      className: 'column-total-pages',
      render: (text, record) => {
        return (
          <div className="content-total-pages-column">
            <span>{ record.totalPages > 1 ? `${ record.totalPages } pages` : record.totalPages === 0 ? `${ record.totalPages } pages` : `${ record.totalPages } page` }</span>
          </div>
        )
      }
    },
    {
      title: '',
      dataIndex: 'size',
      key: 'size',
      className: 'column-size',
      render: (text, record) => {
        return (
          <div className="content-size-column">
            <span>{ record.size ? `${ record.size } KB` : '0 KB' }</span>
          </div>
        )
      }
    }
  ]
  getImage = (type) => {
    switch (type) {
      case 'png':
      case 'jpg':
      case 'jpeg':
        return imgJpeg
      case 'pdf':
        return imgPdf
      default:
        return imgJpeg
    }
  }
  sendFetchSarDocumentSaved = () => {
    const { setIntervalDocuments, responseCheckDocuments } = this.state
    const { valueAttachBoxId } = this.props
    const checkDocumentsNamesAndSizeDTO = {
      boxId: this.props.sar.boxId ? this.props.sar.boxId : valueAttachBoxId.toString(),
      sarId: this.props.sar.id
    }
    apiSarCheckSendDocuments(checkDocumentsNamesAndSizeDTO).then((response) => {
      if (response.status === 200) {
        this.setState({
          responseCheckDocuments: response.data,
          emptyDescriptionTable: response.data,
        })
        if (response.data === false && !responseCheckDocuments) {
          CustomNotificationManager.info('Files are being imported in background! Please wait...')
          this.setIntervalCheckDocumentsSend()
        } else {
          this.fetchSarDocumentsSaved()
          clearInterval(setIntervalDocuments)
          this.setState({setIntervalDocuments})
        }
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  setIntervalCheckDocumentsSend = () => {
    let { setIntervalDocuments, responseCheckDocuments } = this.state
    if (setIntervalDocuments === 0) {
      if (!responseCheckDocuments) {
        setIntervalDocuments = setInterval(this.callBackCheckDocuments, 5000)
        this.setState({
          setIntervalDocuments
        })
      } else {
        clearInterval(setIntervalDocuments)
      }
    }
  }
  callBackCheckDocuments = () => {
    const { setIntervalDocuments, responseCheckDocuments } = this.state
    const { valueAttachBoxId } = this.props
    const checkDocumentsNamesAndSizeDTO = {
      boxId: this.props.sar.boxId ? this.props.sar.boxId : valueAttachBoxId.toString(),
      sarId: this.props.sar.id
    }
    if (!responseCheckDocuments) {
      apiSarCheckSendDocuments(checkDocumentsNamesAndSizeDTO).then((response) => {
        if (response.status === 200) {
          this.setState({
            responseCheckDocuments: response.data,
            emptyDescriptionTable: response.data,
          })
          if (response.data) {
            this.fetchSarDocumentsSaved()
            clearInterval(setIntervalDocuments)
            this.setState({ setIntervalDocuments })
          } else {
            this.setState({setIntervalDocuments})
          }
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    } else if (responseCheckDocuments) {
      clearInterval(setIntervalDocuments)
      this.setState({ setIntervalDocuments })
    }
  }
  async fetchSarDocumentsSaved() {
    const { sar } = this.props
    const { amountPerPage, pageNumber } = this.state
    const checkDocumentsNamesAndSizeDTO = {
      sarId: sar.id,
      boxId: Number(sar.boxId),
      pageRows: amountPerPage,
      pageNr: pageNumber,
    }
    const response = await getSarDocumentsSaved(checkDocumentsNamesAndSizeDTO)
    this.setState({
        fileList: response.data.documents,
        newFileList: response.data.documents,
        totalPages: response.data.totalDocuments,
        totalDocuments: response.data.totalDocuments,
        totalSizeFiles: response.data.totalSize,
        totalPagesFiles: response.data.totalPages,
    })
  }
  async fetchImages(id) {
    const response = await getSarDocumentsThumbnailId(id)
    this.setState({
      fileList: response.data,
      newFileList: response.data,
      totalPages: response.data.length
    })
  }

  onChangeNotification = () => {
    // this.setState({
    //   sendEmailNotification: !this.state.sendEmailNotification
    // })
  }

  handleSendSecurely = (status) => {
    let { sendEmailNotification } = this.state
    const { valueAttachBoxId } = this.props
    sendEmailNotification = status === 'send-email' ? true : false
    const data = {
      id: this.props.sar.id,
      boxId: this.props.sar.boxId ? this.props.sar.boxId : valueAttachBoxId.toString(),
      //sendEmailNotification: this.state.sendEmailNotification
      sendEmailNotification: sendEmailNotification,
      endRequest: status === 'end-request' ? true : false
    }
    this.setState({
      loading: (status === 'send-email' || status === 'complete-request') ? true : false,
      loadingEndRequest: status === 'end-request' ? true : false
    }, () => {
      apiSarSecurelySendEmail(data)
        .then((response) => {
          this.props.refreshData(response.data)
          if (response.data.big_zip_path) {
            // const messageInfo = <span>Exported path and filenames exceed 256 characters and Windows default unpacker may not be able to open it.<span className="more-info-link-notification" onClick={ () => window.open('https://support.smartbox.ai/support/solutions/articles/47001222170',"_blank" )}> More info </span></span>
            // CustomNotificationManager.info(messageInfo, 'Info')
            // CustomNotificationManager.info('Exported path and filenames exceed 256 characters and Windows default unpacker will not be able to open it. Please use a different program under Windows to open it.', 'Info')
          } else {
            CustomNotificationManager.success('Email sent to subject.', 'Success')
          }
          this.setState({
            loading: false
          })
          if (status === 'send-email' && sendEmailNotification) {
            this.setState({
              checkEmailSendFeedback: true
            })
          } else {
            this.setState({
              checkEmailSendFeedback: false
            })
          }
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
      })
    })

  }
  onClickVisibleContent = () => {
    this.setState({
      visibleContent: !this.state.visibleContent
    })
  }
  generateDate(date) {
    return moment(date).format('DD.MM.YYYY')
  }
  onClickNextStep = () => {
    const { current, completeStep } = this.props
    if (!completeStep) {
      this.props.nextStep(current)
    } else {
      this.props.nextStep(current + 1)
    }
  }
  onClickBackStep = () => {
    const { current } = this.props
    this.props.beckStep(current - 1)
  }
  customDot = (dot, { index, status }) => {
    switch (index) {
      case 0: {
        return <span className={ status === 'process' ? 'icon-v10-radio-button-tick' : status === 'finish' ? 'icon-v10-radio-button-tick' : 'icon-v10-radio-button-off' }></span>
      }
      case 1: {
        return <span className={ status === 'process' ? 'icon-v10-radio-button-tick' : status === 'finish' ? 'icon-v10-radio-button-tick' : 'icon-v10-radio-button-off' }></span>
      }
      case 2: {
        return <span className={ status === 'process' ? 'icon-v10-radio-button-tick' : status === 'finish' ? 'icon-v10-radio-button-tick' : 'icon-v10-radio-button-off' }></span>
      }
      default:
        return <span></span>
    }
  }
  generateClassSteps = (step, status, state) => {
    switch (step) {
      case 'status-link':
        if (state === 'finish') {
          return 'status-link status-step-finish'
        }
        return 'status-link'

      case 'status-link-visited':
        if (state === 'finish') {
          return 'status-link-visited status-step-finish'
        }
        return 'status-link-visited'

      case 'status-submitting-data':
        if (state === 'finish') {
          return 'status-submitting-data status-step-finish'
        }
        return 'status-submitting-data'

      default :
        return 'status'
    }

  }
  generateDescriptionSteps = (step, current) => {
    const { sar } = this.props
    const { feedbackLinkVisited, feedbackSubmitted, sendSecurelySent } = sar
    switch (step) {
      case 'status-link':
        if (sendSecurelySent && sendSecurelySent.length > 0) {
          return <React.Fragment>
            {
              sendSecurelySent.map((date, i) => <span className="status-finish-info"
                key={i}>{this.generateDate(date)}</span>)
            }
          </React.Fragment>
        }
        return <span className="status-pending">Pending</span>

      case 'status-link-visited':
        if (feedbackLinkVisited && feedbackLinkVisited.length > 0) {
          return <React.Fragment>
            {
              feedbackLinkVisited.map((date, i) => <span className="status-finish-info"
                key={i}>{this.generateDate(date)}</span>)
            }
          </React.Fragment>
        }
        return <span className="status-pending">Pending</span>

      case 'status-submitting-data':
        if (feedbackSubmitted && feedbackSubmitted.length > 0) {
          return <React.Fragment>
            {
              feedbackSubmitted.map((date, i) => <span className="status-finish-info"
                key={i}>{this.generateDate(date)}</span>)
            }
          </React.Fragment>
        }
        return <span className="status-pending">Pending</span>

      default :
        return 0
    }
  }
  onPageChanged = (data) => {
    const { currentPage, pageLimit } = data
    this.setState({
      amountPerPage: pageLimit,
      pageNumber: currentPage
    }, () => {
      this.fetchSarDocumentsSaved()
    })
  }
  searchAfterFirstLetter = (letter) => {
    const { newFileList } = this.state
    const resultSearch = []
    newFileList && newFileList.length > 0 && newFileList.forEach((file) => {
      const letterNameFile = file.name.toLowerCase()
      if (letter === 'all') {
        this.setState({
          fileList: newFileList,
          totalPages: newFileList.length,
          amountPerPage: 10

        })
      } else if (letterNameFile && letterNameFile.indexOf(letter) === 0) {
        resultSearch.push(file)
        this.setState({
          fileList: resultSearch,
          totalPages: resultSearch.length,
          amountPerPage: 10
        })
      } else {
        this.setState({
          fileList: [],
          totalPages: 0,
          amountPerPage: 10
        })
      }
    })
    if (resultSearch && resultSearch.length > 0) {
      this.setState({
        fileList: resultSearch,
        totalPages: resultSearch.length
      })
    }
  }

  onChangeLetter = (e) => {
    this.searchAfterFirstLetter(e.target.value)
    this.setState({
      selectedLetter: e.target.value
    })
  }
  onCheckNumberPages() {
    const { fileList } = this.state
    const totalNumberPages = []
    fileList && fileList.length > 0 && fileList.forEach((file) => {
      totalNumberPages.push(file.totalPages)
    })
    return totalNumberPages && totalNumberPages.length > 0 ? totalNumberPages.reduce((a, b) => a + b, 0) : 0
  }

  onCheckSizeFiles() {
    const { fileList } = this.state
    const totalSizeFiles = []
    fileList && fileList.length > 0 && fileList.forEach((file) => {
      totalSizeFiles.push(file.size)
    })
    return totalSizeFiles && totalSizeFiles.length > 0 ? totalSizeFiles.reduce((a, b) => a + b, 0) : 0
  }

  render() {
    const { loading, fileList, valueSelectedBox,
      totalDocuments, totalPagesFiles, totalSizeFiles, selectedLetter, totalPages, amountPerPage, sendEmailNotification, checkEmailSendFeedback, loadingEndRequest,
      emptyDescriptionTable} = this.state
    const { sar, valueAttachBox, searchFindStep, completeStep } = this.props
    //const { searchFindStep } = this.props
    //const { sendStep } = this.props
    const lastSarStepCompleted = searchFindStep ? searchFindStep.completed : false
    const { feedbackLinkVisited, feedbackSubmitted, sendSecurelySent } = sar
    let statusSent
    let statusLinkVisited
    let statusSubmittingData
    if (sendSecurelySent && sendSecurelySent.length > 0) {
      statusSent = 'finish'
    } else {
      statusSent = 'wait'
    }
    if (feedbackLinkVisited && feedbackLinkVisited.length > 0) {
      statusLinkVisited = 'finish'
    } else {
      statusLinkVisited = 'wait'
    }
    if (feedbackSubmitted && feedbackSubmitted.length > 0) {
      statusSubmittingData = 'finish'
    } else {
      statusSubmittingData = 'wait'
    }
    // if (feedbackEmailOpened && feedbackEmailOpened.length > 0) {
    //   emailStatus = 'OPENED'
    // } else {
    //   emailStatus = 'SENT'
    // }

    // let emailLinkOpened
    // if (feedbackLinkVisited && feedbackLinkVisited.length > 0) {
    //   emailLinkOpened = 'VISITED'
    // } else {
    //   emailLinkOpened = 'PENDING'
    // }
    //
    // let linkSubmitted
    // if (feedbackSubmitted && feedbackSubmitted.length > 0) {
    //   linkSubmitted = 'SUBMITTED'
    // } else {
    //   linkSubmitted = 'PENDING'
    // }
    // let linkApproved
    // if (feedbackSubmitted && feedbackSubmitted.length > 0) {
    //   linkApproved = 'APPROVED'
    // } else {
    //   linkApproved = 'PENDING'
    // }
    // const isLinkFromEmail = feedbackEmailOpened || feedbackLinkVisited || feedbackSubmitted

    return (
      <React.Fragment>
        <div className="tracker-header-steps">
          <div className="contain-name-back-step">
            <div className="back-step back-step-hover">
              <span onClick={this.onClickBackStep} className="icon-v10-back"></span>
            </div>
            <div className="tracker-fullname-contain">
              <span className="tracker-surname">{`${ sar.forename } ${ sar.surname }`}</span>
            </div>
          </div>
          <div className="attach-box-contain">
            { (valueAttachBox || this.props.sar.boxName) ?
              <img alt={ valueSelectedBox } src={ imgFolderSmallParent }/>:
              <span className="icon-v10-smart-folder-medium-parent-2"></span>
            }
            <span className="attach-box disabled-attach-box">
              { valueAttachBox ? valueAttachBox : this.props.sar.boxName ? this.props.sar.boxName : 'Attach a box' }
            </span>
          </div>
          <div className="contain-save-last-step">
            <div className="save-close-contain">
              <span className="icon-v10-save"></span>
              <span className="save-and-close">
             Save & Close
              </span>
            </div>
            <div className={!completeStep ? 'next-step next-step-disabled' : 'next-step next-step-hover' }>
              <span onClick={this.onClickNextStep} className="icon-v10-forth"></span>
            </div>
          </div>
        </div>
        {/*<div className="tracker-surname-contain">*/}
        {/*  <span className="tracker-surname">{`${ sar.forename } ${ sar.surname }`}</span>*/}
        {/*</div>*/}
        <div className="tracker-box tracker-box-send" style={{ marginTop: 0 }}>
          <div className="tracker-form">
            <div className="tracker-step-header">
              <div className="tracker-step-header__top">
                <span className="tracker-step-header__top__step">Send data
                <Tooltip overlayClassName="help-message-locations" title={<div className="content-help-message-locations">
                  <span className="text-information">Securely disclose the subject’s data via weblink.</span>
                  <span className="more-info-link" onClick={ () => window.open('https://support.smartbox.ai/support/solutions/articles/47001174700', '_blank') }> More info. </span>
                </div>}>
                    <span className="icon-v14-question"></span>
                  </Tooltip></span>
              </div>
              {/*{ (feedbackSubmitted && !feedbackSubmitted.length > 0 && lastSarStepCompleted) &&*/}
              {/*  <div className="message-previous">*/}
              {/*    <span>Need to make some changes? Return to the previous step.</span>*/}
              {/*  </div>*/}
              {/*}*/}
            </div>
            {/*{lastSarStepCompleted &&*/}
            {/*<div className="tracker-acknowledge-info">*/}
            {/*  <div className="content-pending">*/}
            {/*    <span className={linkApproved === 'APPROVED' ? 'email-sent' : 'email-opened'}>{linkApproved}</span>*/}
            {/*  </div>*/}
            {/*  {isLinkFromEmail &&*/}
            {/*  <div className="content-email-status">*/}
            {/*    <span className="info-email-status">Email Status</span>*/}
            {/*    <span className="contain-email-status">*/}
            {/*      <span*/}
            {/*        className={emailStatus === 'OPENED' ? 'email-opened' : 'email-sent'}>{emailStatus}</span>*/}
            {/*      {*/}
            {/*        emailStatus !== 'OPENED' &&*/}
            {/*        <Icon type="check" className="icon-check"/>*/}
            {/*      }*/}
            {/*    </span>*/}
            {/*  </div>*/}
            {/*  }*/}
            {/*  <div className="content-link-status">*/}
            {/*    <span className="info-link-status">Form Link Status</span>*/}
            {/*    <span className="contain-email-status">*/}
            {/*      <span*/}
            {/*        className={emailLinkOpened === 'VISITED' ? 'email-sent' : 'email-opened'}>{emailLinkOpened}</span>*/}
            {/*      {emailLinkOpened === 'VISITED' &&*/}
            {/*      <Icon type="check" className="icon-check-two"/>*/}
            {/*      }*/}
            {/*    </span>*/}
            {/*  </div>*/}
            {/*  <div className="content-data-status">*/}
            {/*    <span className="info-data-status">Form Data Status</span>*/}
            {/*    <span className="contain-data-status">*/}
            {/*      <span className={linkSubmitted === 'SUBMITTED' ? 'email-sent' : 'email-opened'}>{linkSubmitted}</span>*/}
            {/*      {linkSubmitted === 'SUBMITTED' &&*/}
            {/*      <Icon type="check" className="icon-check"/>*/}
            {/*      }*/}
            {/*    </span>*/}
            {/*  </div>*/}
            {/*  <div className="tracker-content-icons">*/}
            {/*    <span className="tracker-icons-visible-content" onClick={() => this.onClickVisibleContent()}>*/}
            {/*      <i className={this.state.visibleContent ? 'fa fa-caret-down' : 'fa fa-caret-up'}></i>*/}
            {/*    </span>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*}*/}

            {/*{ this.state.visibleContent && lastSarStepCompleted &&*/}
            {/*<div className="tracker-acknowledge-info-content">*/}
            {/*  <div className="display-email-status">*/}
            {/*    { feedbackEmailOpened &&*/}
            {/*    <React.Fragment>*/}
            {/*      {*/}
            {/*        feedbackEmailOpened.map((date, i) => <span*/}
            {/*          key={i}>{this.generateDate(date)}</span>)*/}
            {/*      }*/}
            {/*    </React.Fragment>*/}
            {/*    }*/}
            {/*  </div>*/}
            {/*  <div className="display-form-link-status">*/}
            {/*    {feedbackLinkVisited &&*/}
            {/*    <React.Fragment>*/}
            {/*      {*/}
            {/*        feedbackLinkVisited.map((date, i) => <span*/}
            {/*          key={i}>{this.generateDate(date)}</span>)*/}
            {/*      }*/}
            {/*    </React.Fragment>*/}
            {/*    }*/}
            {/*  </div>*/}
            {/*  <div className="display-form-data-status">*/}
            {/*    {*/}
            {/*      feedbackSubmitted.map((date, i) => <span*/}
            {/*        key={i}>{this.generateDate(date)}</span>)*/}
            {/*    }*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*}*/}
            { lastSarStepCompleted &&
            <div className="tracker-email-info-steps">
              <Steps progressDot={ this.customDot }>
                <Step title="Sent" description={ this.generateDescriptionSteps('status-link', 0) }
                  className={ this.generateClassSteps('status-link', 0, statusSent) } status={ statusSent }/>
                <Step title="Link visited" description={ this.generateDescriptionSteps('status-link-visited', 1) }
                  className={ this.generateClassSteps('status-link-visited', 1, statusLinkVisited) } status={ statusLinkVisited }/>
                <Step title="Submitting data" description={ this.generateDescriptionSteps('status-submitting-data', 2) }
                  className={ this.generateClassSteps('status-submitting-data', 2, statusSubmittingData) } status={ statusSubmittingData }/>
              </Steps>
            </div>
            }
            {/*<div className="content-message-documents">*/}
            {/*  <p><span className="send-securely-message">{messageTotalDocuments}</span></p>*/}
            {/*  <p><span className="send-securely-message">{messageRedactedDocuments}</span></p>*/}
            {/*</div>*/}
            <div className="tracker-step-content">
              { lastSarStepCompleted &&
                <React.Fragment>
                  <PaginationData totalRecords={ totalPages } pageLimit={ amountPerPage }
                    onPageChanged={ this.onPageChanged } fileList={ fileList }/>
                  <Radio.Group className="letters" value={ selectedLetter } onChange={ (e) => this.onChangeLetter(e) }>
                    { this.lettersCategory.map((item) => {
                      return <Radio.Button key={ item } value={ item.toLowerCase() }>{ item }</Radio.Button>
                    })
                    }
                  </Radio.Group>
                  <Table
                    dataSource={ fileList }
                    columns={ this.columns }
                    rowKey={ (record) => record.id }
                    className={ !emptyDescriptionTable ? "send-securely-table" : '' }
                    pagination={ false }>

                  </Table>
                  <div className="content-dates-files">
                    <span>{ `Total documents ${ totalDocuments }` }</span>
                    <span className="space-dates"> | </span>
                    <span>{ `Total pages ${ totalPagesFiles }` }</span>
                    <span className="space-dates"> | </span>
                    <span>{ `Total ${ totalSizeFiles } KB` }</span>
                  </div>
                </React.Fragment>
              }
              {/*<React.Fragment>*/}
              {/*  { fileList && fileList.length > 0 && fileList.map((file) => {*/}
              {/*    return (*/}
              {/*      <div key={file.id} className="table-documents">*/}
              {/*        <div className="image-document">*/}
              {/*          <img src={ this.getImage(file.originalType) } alt=""/>*/}
              {/*        </div>*/}
              {/*        <div className="name-document">*/}
              {/*          {file.name.substr(0, file.name.indexOf('.'))}*/}
              {/*        </div>*/}
              {/*        <div className="number-page">*/}
              {/*          {file.totalPages > 1 ? `${ file.totalPages } pages` : `${ file.totalPages } page`}*/}
              {/*        </div>*/}
              {/*        <div className="size-document">*/}
              {/*          {`${ file.size } KB`}*/}
              {/*        </div>*/}
              {/*        <div className="icon-document">*/}
              {/*          {*/}
              {/*            file.editType === 'FINALIZED' ?*/}
              {/*              <span className="icon-v2-radio-button redact-icon"></span> :*/}
              {/*              <span className="icon-v2-analysed-b- redact-icon-false"></span>*/}
              {/*          }*/}
              {/*        </div>*/}

              {/*      </div>*/}
              {/*    )*/}
              {/*  })*/}
              {/*  }*/}
              {/*</React.Fragment>*/}
              {/*{sendStep && !sendStep.completed &&*/}
              { sar.lastStep === 'SEND_SECURELY' &&
                <React.Fragment>
                  { (feedbackSubmitted && feedbackSubmitted.length > 0) ?
                    <React.Fragment>
                      <div className="tracker-content-button content-button-request-complete">
                        {
                          loading ? <Spin className="float-right" spinning={ loading }
                            indicator={ <div className="checkout-loading"><Loading/></div> }/> :
                            <button className="button-blue-light float-right" onClick={() => this.handleSendSecurely('complete-request') }>COMPLETE
                              REQUEST</button>
                        }
                      </div>
                    </React.Fragment> :
                    <React.Fragment>
                      { !feedbackSubmitted.length > 0 &&
                    <div className="tracker-content-links full_width">
                      <div className="tracker-form-link">
                        <Checkbox
                          //disabled={ checkEmailSendFeedback === true }
                          disabled={ true } defaultChecked={ sendEmailNotification } className="send-form" onChange={ this.onChangeNotification }>Send Email
                          Notification</Checkbox>
                      </div>
                    </div>
                      }
                      { !feedbackSubmitted.length > 0 &&
                        <div className={ sendSecurelySent && sendSecurelySent.length > 0 ? 'tracker-content-buttons-send': 'tracker-content-buttons-send tracker-content-buttons-securley' }>
                          { sendSecurelySent && sendSecurelySent.length > 0 &&
                            <div className="content-button-end-request">
                              { loadingEndRequest ? <Spin spinning={ loadingEndRequest }
                                indicator={ <div
                                  className="checkout-loading spin-end-request"><Loading/>
                                </div> }/> :
                                <Button className='button-end-request'
                                  onClick={ () => this.handleSendSecurely('end-request') }>
                                  END REQUEST
                                </Button>
                              }
                            </div>
                          }
                          <div className="tracker-content-button">
                            {
                              loading ? <div className={'button-send-data-loading-wrapper'}> <Spin className="float-right" spinning={ loading }
                                                    indicator={ <Icon type="loading" style={{ fontSize: 20, color:"#FFFFFF"}} spin /> }/>
                                    <button  className={'button-blue-light float-right button-send-data-loading-state'}>SEND DATA</button>
                              </div> :
                                <Button disabled={ checkEmailSendFeedback === true || emptyDescriptionTable === false } className={ emptyDescriptionTable === false ? 'button-blue-send-data float-right send-data-disabled' : checkEmailSendFeedback === true && emptyDescriptionTable === false ? 'button-blue-send-data float-right send-data-disabled' : 'button-blue-send-data float-right' }
                                  onClick={() => this.handleSendSecurely('send-email') }>SEND DATA</Button>
                            }
                          </div>
                        </div>
                      }
                    </React.Fragment>
                  }
                </React.Fragment>
              }
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id,
  smartBoxNotSarTracker: state.smartBox.smartBoxNotSarTracker,
})
SendSecurelyStep = connect(mapStateToProps, {setSmartBoxNotSarTracker})(SendSecurelyStep)
export default SendSecurelyStep