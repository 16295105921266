import axios from 'axios'

export const apiOspreyGetDataLines = (host) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/getCrawlers`,
    data: {
      sessionID: token
    }
  })
}

export const apiOspreyGetDataLineInfo = (host, dataLineId) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/crawlerGetStatus`,
    data: { 
      sessionID: token,
      processID: `${ dataLineId }`
    }
  })
}

export const apiOspreyStartDataLine = (host, dataLineId) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/crawlerStart`,
    data: { 
      sessionID: token,
      crawlerID: dataLineId
    }
  })
}

export const apiOspreyStopDataLine = (host, dataLineId) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/crawlerStop`,
    data: { 
      sessionID: token,
      crawlerID: dataLineId
    }
  })
}

export const apiOspreyGetCrawlerProcessorTemplateConfigurations = (host) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/getCrawlerProcessorTemplateConfigurations`,
    data: { 
      sessionID: token
    }
  })
}

export const apiOspreyGetCrawlerConfiguration = (host, dataLineId) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/getCrawlerConfiguration`,
    data: { 
      sessionID: token,
      crawlerID: dataLineId
    }
  })
}
