import React from 'react'
import { Tabs } from 'antd'
import Access from './Access'
import DataTopics from './DataTopics'
import Analytics from './Analytics'
import RegularExpressions from './RegularExpressions'
import './FolderSettings.scss'
const { TabPane } = Tabs

class FolderSettingsTab extends React.Component {
  render() {
    return (
      <div className="file-settings-tab">
        <Tabs type="card" className="child-tabs">
          <TabPane tab="Access" key="1">
            <Access />
          </TabPane>
          <TabPane tab="Data Topics" key="2">
            <DataTopics />
          </TabPane>
          <TabPane tab="Analytics" key="3">
            <Analytics />
          </TabPane>
          <TabPane tab="Regular Expressions" key="4">
            <RegularExpressions folder={ this.props.folder }/>
          </TabPane>
        </Tabs>
      </div>
    )
  }
}

export default FolderSettingsTab