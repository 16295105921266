import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Link, Navigate } from 'react-router-dom'
import { compose } from 'redux'
import swal from 'sweetalert2'
import { apiFetchQuestionnaire, apiFetchQuestionnaireVersion, apiQuestionnaireCreate, apiQuestionnaireCreateVersion, apiQuestionnairePublish, apiQuestionnaireUpdate } from '../../../api/Questionnaire'
import { accountIsPaid, cloneObject, scrollToElement, userIsSuperAdmin } from '../../../utils'
import Button from '../../Common/Button'
import Page403 from '../../Common/ErrorPage/Page403'
import InputValidator from '../../Common/InputValidator'
import Loading from '../../Common/Loading'
import { actionAttributes } from './Helpers/ActionsAttributes'
import { changesChecker } from './Helpers/ChangesChecker'
import { denormalizeFromAPI, denormalizeQuestionnaireFromAPI, denormalizeToAPI } from './Helpers/Denormalizer'
import { validate } from './Helpers/Validator'
import './QuestionnaireForm.scss'
import { filteredQuestions } from './Questions/Helpers/Filter'
import { toggleInvalidAccordion } from './Questions/Helpers/Utils'
import './TeamsForm.scss'

class QuestionnaireForm extends React.Component {
  constructor() {
    super()

    this.state = this.defaultState

    this.updateQuestionsList = this.updateQuestionsList.bind(this)
    this.updateQuestion = this.updateQuestion.bind(this)
    this.filterQuestions = this.filterQuestions.bind(this)
    this.toggleAccordion = this.toggleAccordion.bind(this)
  }

  get methods() {
    return {
      createNewDraftQuestionnaire: this.createNewDraftQuestionnaire.bind(this),
      createNewQuestionnaireAndPublish: this.createNewQuestionnaireAndPublish.bind(this),
      createNewVersionAndPublish: this.createNewVersionAndPublish.bind(this),
      updateDraft: this.updateDraft.bind(this),
      publish: this.publish.bind(this)
    }
  }

  get defaultState() {
    return {
      isLoading: false,
      isSaving: false,
      originalState: {},
      questionsAccordion: {},
      errors: {},
      isEditMode: false,
      description: '',
      industry: '',
      deletedQuestions: [],
      questions: [],
      allQuestions: [],
      title: '',
      state: '',
      version: '',
      NavigateToDashboard: false,
      type: 'DATA_CONTROLLER_DPIA',
    }
  }

  get urlParams() {
    return {
      questionnaireId: this.props.params.questionnaireId,
      versionId: this.props.params.versionId
    }
  }

  get disableInput() {
    return this.state.isEditMode || this.state.state === 'PUBLISHED'
  }

  get disabledButton() {
    return this.state.state === 'PUBLISHED' || this.state.isSaving
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const { questionnaireId, versionId } = this.urlParams
    if (questionnaireId) {
      this.setState({ isLoading: true, isEditMode: true })
      this.fetchQuestionnaire(questionnaireId)
      this.fetchQuestionnaireVersion(questionnaireId, versionId)
    }
  }

  componentWillUnmount() {
    this.setState(this.defaultState)
  }

  fetchQuestionnaire(questionnaireId) {
    apiFetchQuestionnaire(questionnaireId)
    .then((response) => {
      const questionnaire = denormalizeQuestionnaireFromAPI(response.data)
      const values = { ...this.state, ...questionnaire }
      const { originalState, ...initialValues } = values

      this.setState({ ...this.state, ...questionnaire, originalState: cloneObject(initialValues) })
    })
    
  }

  fetchQuestionnaireVersion(questionnaireId, versionId) {
    apiFetchQuestionnaireVersion(questionnaireId, versionId)
    .then((response) => {
      const questionnaire = denormalizeFromAPI(response.data)
      const values = {
        ...this.state,
        questions: questionnaire.questions,
        allQuestions: questionnaire.questions,
        state: questionnaire.state,
        version: questionnaire.version
      }
      const { originalState, ...initialValues } = values

      this.setState({ ...values, originalState: cloneObject(initialValues), isLoading: false })
    })
  }

  updateValue(event, field) {
    this.setState({
      [field]: event.target.value
    })
  }

  alertAttributes(data, questionnaire, action) {
    let hasChanges = false
    // Needs to check these changes just if isEditMode is true
    if (this.state.isEditMode) {
      const originalData = denormalizeToAPI(this.state.originalState)
      hasChanges = changesChecker(data, originalData)
    }
    return actionAttributes(questionnaire, hasChanges, action, this.methods)
  }

  buttonClicked(event, action) {
    event.preventDefault()

    // check if is a publish state trying to save a draft
    if (this.state.state === 'PUBLISHED' && action === 'draft') return

    const questionnaire = validate(this.state)
    this.setState({ ...questionnaire }, () => {
      if (questionnaire.isValid) {
        const data = denormalizeToAPI(this.state)
        const nextAction = this.alertAttributes(data, questionnaire, action)

        swal.fire({
          text: nextAction.title,
          type: 'warning',
          showCancelButton: nextAction.action !== 'noAction',
          confirmButtonColor: '#3DD8DB',
          cancelButtonColor: '#D33D33',
          confirmButtonText: nextAction.action !== 'noAction' ? 'Yes!' : 'Ok'
        }).then((result) => {
          if (result.value && nextAction.method) {
            this.setState({ isSaving: true })
            nextAction.method(data)
          }
        })
      } else {
        const questionsAccordion = toggleInvalidAccordion(this.state.questions)
        this.setState({ questionsAccordion, isSaving: false }, () => {
          scrollToElement('.errorMessages__list, .inputValidator__invalidWrapper')
        })
      }
    })
  }

  createNewQuestionnaireAndPublish(data) {
    apiQuestionnaireCreate(data)
    .then((response) => {
      const questionnaireId = response.data[0]
      const versionId = response.data[1]
      this.publish(true, questionnaireId, versionId)
    })
  }

  createNewDraftQuestionnaire(data) {
    apiQuestionnaireCreate(data)
    .then(() => {
      this.setState({ NavigateToDashboard: true, isSaving: false })
    })
  }

  updateDraft(data) {
    const { questionnaireId, versionId } = this.urlParams

    apiQuestionnaireUpdate(data, questionnaireId, versionId)
    .then(() => {
      this.setState({ NavigateToDashboard: true, isSaving: false })
    })
  }

  createNewVersionAndPublish() {
    const questionnaireId = this.props.params.questionnaireId
    const data = denormalizeToAPI(this.state)
    Object.assign(data, { parentVersion: this.state.version })

    apiQuestionnaireCreateVersion(data, questionnaireId)
      .then((response) => {
        const versionId = response.data
        this.publish(true, questionnaireId, versionId)
      })
      .catch((e) => {
        if (e?.response?.data === 'QUESTIONNAIRE_VERSIONS_NO_LIMIT_REACHED') {
          swal.fire({
            text: 'You cannot publish more than 10 versions',
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3DD8DB',
            confirmButtonText: 'Ok'
          })
        }else{
          console.log(e)
        }
        this.setState({ isSaving: false })
      })
  }

  publish(NavigateToDashboard, questionnaireId = this.props.params.questionnaireId, versionId=this.props.params.versionId) {
    const data = denormalizeToAPI(this.state)

    apiQuestionnairePublish(data, questionnaireId, versionId)
      .then((response) => {
        this.setState({ NavigateToDashboard, isSaving: false })
      })
      .catch((e) => {
        this.setState({ isSaving: false })
      })
  }

  updateQuestionsList({ questions, deletedQuestions = this.state.deletedQuestions }) {
    this.setState({
      questions,
      deletedQuestions,
      allQuestions: questions
    })
  }

  updateQuestion(index, question, callback) {
    const newQuestions = [...this.state.questions]
    newQuestions[index] = question
    this.setState({
      questions: newQuestions,
      allQuestions: newQuestions
    }, callback)
  }

  filterQuestions(filterAttributes) {
    const params = {
      ...filterAttributes,
      allQuestions: this.state.allQuestions,
      deletedQuestions: this.state.deletedQuestions
    }

    this.setState({
      ...filteredQuestions(params)
    })
  }

  formattedTitle(isEditMode, title, state, version) {
    if (!isEditMode) return 'New Team'
    if (state !== 'DRAFT') return `V${ version } - ${ title }`
    return `(Draft) V${ version } - ${ title }`
  }

  toggleAccordion(index) {
    this.setState({
      questionsAccordion: {
        ...this.state.questionsAccordion,
        [index]: !this.state.questionsAccordion[index]
      }
    })
  }

  render() {
    const {
      description,
      errors,
      isEditMode,
      state,
      title,
      version,
      NavigateToDashboard,
      isLoading,
      isSaving
    } = this.state

    const currentUserIsSuperAdmin = userIsSuperAdmin(this.props.user)
    const currentAccountIsPaid = accountIsPaid(this.props.subscription)

    if (!currentAccountIsPaid && !currentUserIsSuperAdmin) {
      return (
        <div className="newQuestionnaire__page">
          <Page403 />
        </div>
      )
    }

    return (
      <div className="newQuestionnaire__page">
        { NavigateToDashboard &&
          <Navigate to="/dpia/questionnaires" />
        }

        { isLoading &&
          <div className="newQuestionnaire__loading">
            <Loading />
          </div>
        }

        { !isLoading &&
          <React.Fragment>
            <div className="newQuestionnaire__section">
              <header className="newQuestionnaire__header m-3">
                <h1 className="newQuestionnaire__title">
                  { this.formattedTitle(isEditMode, title, state, version) }
                </h1>
                <div className="newQuestionnaire__submitButtons">
                  { isSaving &&
                    <div className="newQuestionnaire__loading">
                      <Loading />
                    </div>
                  }

                  <div className={ this.disabledButton ? 'newQuestionnaire__disabledButton' : ''}>
                    <Button
                      theme={ this.disabledButton ? 'gray' : 'green' }
                      onClick={ (e) => this.buttonClicked(e, 'draft') }>

                      Save Changes
                    </Button>
                  </div>

                </div>
              </header>

              <form className="newQuestionnaire__form">
                <div className="newQuestionnaire__formGroup">
                  <div className="float-left m-3">
                    <label htmlFor="title">Display name</label>
                    <input
                      className="title1"
                      value={ title }
                      disabled={ this.disableInput }
                      onChange={ (e) => this.updateValue(e, 'title') }
                    />
                  </div>
                  <div className="float-left m-3">
                    <label htmlFor="title" >Unique identifier</label>
                    <InputValidator errors={ errors } field="title">
                      <input
                        className="title1"
                        value={ title }
                        disabled={ this.disableInput }
                        onChange={ (e) => this.updateValue(e, 'title') }
                      />
                    </InputValidator>
                  </div>
                </div>
                <div className="newQuestionnaire__formGroup descriptionStyle m-3">
                  <label htmlFor="description">Description</label>
                  <input
                    className="description1"
                    value={ description }
                    onChange={ (e) => this.updateValue(e, 'description') }
                  />
                </div>
                <div className="newQuestionnaire__formGroup styleUploadImage ">
                  <div className="">
                    <img className="teamIconImage" src={ 'https://s.gravatar.com/avatar/53dc26bb6b2652bdf9150a3994a9ef2c' } alt="" />
                  </div>
                  <div className="">
                    <label htmlFor="description">Upload a logo or icon</label>
                    <Button
                      className="buttonBrowse"
                      onClick={ (e) => this.buttonClicked(e, 'draft') }>

                      BROWSE...
                    </Button>
                  </div>
                </div>

                <div className={ this.disabledButton ? 'newQuestionnaire__disabledButton' : ''}>
                  <footer className="userSettings-footer">
                    <div>
                      <Link className="userSettings-footerLink" to="/osp/dashboard">Discard changes</Link>
                    </div>
                    <div className="newQuestionnaire__submitButtons">
                      { isSaving &&
                        <div className="newQuestionnaire__loading">
                          <Loading />
                        </div>
                      }

                      <div className={ this.disabledButton ? 'newQuestionnaire__disabledButton' : ''}>
                        <Button
                          theme={ this.disabledButton ? 'gray' : 'green' }
                          onClick={ (e) => this.buttonClicked(e, 'draft') }>

                          Save Changes
                        </Button>
                      </div>

                    </div>
                  </footer>
                </div>

              </form>
            </div>
          </React.Fragment>
        }
      </div>
    )
  }
}

const stateMap = (state) => ({
  subscription: state.subscriptionReducer.subscription
})

QuestionnaireForm.propTypes = {
  subscription: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default compose(
  connect(stateMap, null),
)(QuestionnaireForm)
