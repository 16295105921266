import PropTypes from 'prop-types'
import React from 'react'
import './Accordion.scss'

const Accordion = ({ content, header, opened }) => (
  <div className="accordion-wrapper">
    <div>
      { header }
    </div>

    { opened &&
      content
    }
  </div>
)

Accordion.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.object
  ]).isRequired,
  header: PropTypes.element.isRequired,
  opened: PropTypes.bool,
}

Accordion.defaultProps = {
  opened: false
}

export default Accordion