import React from 'react'
import PropTypes from 'prop-types'
import {
  apiPrivacyGetDatasetForFolder,
  apiPrivacyGetDirectories,
  apiPrivacyGetFolders,
  apiPrivacyGetFilesFromFolders,
  apiPrivacyGetLastDateModifiedForFolder,
  apiPrivacyGetInformationForFiles,
  apiPrivacyGetObjects
} from '../api/Privacy'
import * as utils from '../utils/Files'

const FilesContext = React.createContext()

class FilesProvider extends React.Component {
  state = this.defaultState

  get defaultState() {
    return {
      fileToShowPreview: {
        isLoadingFileInformation: true
      },
      filterQueries: [],
      folders: [],
      isFetchingFolders: true,
      totalDirectories: 0,
      totalObjects: 0
    }
  }

  resetFiles = () => {
    this.setState(this.defaultState)
  }

  getObjects = (filter) => {
    apiPrivacyGetObjects(filter)
      .then((response) => {
        this.setState({ totalObjects: response.data.pagination.total })
      })
  }

  getDirectories = (filter) => {
    apiPrivacyGetDirectories(filter)
      .then((response) => {
        const totalDirectories = response.data.value.stats.field.value.count
        this.setState({ totalDirectories })
      })
  }

  getFolders = (page = 0, filterQueries = []) => {
    this.setState({ filterQueries }, () => {
      apiPrivacyGetFolders(page, filterQueries)
        .then((response) => {
          const { facet } = response.data.value.facets
          const folders = utils.mapFacetsToFolders(facet.values.value)
          this.setState({ folders, isFetchingFolders: false })
        
          for (const folder of folders) {
            this.getDatasetForFolder(folder.query)
            this.getLastDateModifiedForFolder(folder.query)
            this.getFilesFromFolders(0, folder.query)
          }
        })
    })
  }

  getDatasetForFolder = (query) => {
    apiPrivacyGetDatasetForFolder(query)
      .then((response) => {
        const dataset = response.data.value.facets.facet.values.value.name
        const folders = utils.putDatasetOnFolder(this.state.folders, query, dataset)
        this.setState({ folders })
      })
  }

  getLastDateModifiedForFolder = (query) => {
    apiPrivacyGetLastDateModifiedForFolder(query)
      .then((response) => {
        const dateModified = response.data.value.stats.field.value.nonpivotstats.doc_date_modified.min
        const folders = utils.putDateModifiedOnFolder(this.state.folders, query, dateModified)
        this.setState({ folders })
      })
  }

  getFilesFromFolders = (page, query) => {
    apiPrivacyGetFilesFromFolders(page, query, this.state.filterQueries)
      .then((response) => {
        const newFolders = utils.putFilesOnFolders(this.state.folders, query, response.data)
        this.setState({ folders: newFolders })
      })
  }

  updateFolder = (name, informationToUpdate) => {
    const newFolders = utils.updateFolderWithInformation(this.state.folders, name, informationToUpdate)
    this.setState({ folders: newFolders })
  }

  getInformationForFiles = (id, categoryID) => {
    this.setState({ fileToShowPreview: { isLoadingFileInformation: true } })

    apiPrivacyGetInformationForFiles(id, categoryID)
      .then((response) => {
        if (response.data.status === 'Success') {
          const file = response.data.value
          this.setState({ fileToShowPreview: {
            ...file,
            isLoadingFileInformation: false
          } })
        }
      })
  }

  changePagination = (page, filter) => {
    this.setState({
      folders: [],
      isFetchingFolders: true
    }, () => this.getFolders(page - 1, filter))
  }

  render = () => {
    return (
      <FilesContext.Provider value={{
        changePaginationForFiles: this.changePagination,
        fileToShowPreview: this.state.fileToShowPreview,
        folders: this.state.folders,
        getDirectories: this.getDirectories,
        getFilesFromFolders: this.getFilesFromFolders,
        getFolders: this.getFolders,
        getInformationForFiles: this.getInformationForFiles,
        getObjects: this.getObjects,
        isFetchingFolders: this.state.isFetchingFolders,
        resetFiles: this.resetFiles,
        totalDirectories: this.state.totalDirectories,
        totalObjects: this.state.totalObjects,
        updateFolder: this.updateFolder
      }}>
        { this.props.children }
      </FilesContext.Provider>
    )
  }
}

FilesProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export default FilesProvider
export const FilesConsumer = FilesContext.Consumer