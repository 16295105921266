import React, { Component } from 'react'
import CustomNotificationManager from '../../components/Common/CustomNotificationManager'
import './/InputOptions.scss'
import PropTypes from 'prop-types'
import { Popover } from 'antd'

/**
 * <InputOptions iconsBefore= { true } options= { options } setInputOptions= {this.setInputOptions} />
 * for options
 * ['HR', 'IT', 'Team1'] for iconsBefore= { true }
 * ['Cloud', 'OnSide', 'Local'] for iconsBefore= { false }
 */
export class InputOptions extends Component {

  defaultIcons = [
    'icon-user-circle-b',
    'icon-users-g',
    'empty-icon'
  ]
  
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      newValue: '',
      visible: false
    }
  }

  componentDidMount() {
    this.setState({
      data: this.props.options
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.options !== this.props.options) {
      this.setState({
        data: this.props.options,
        newValue: ''
      })
    }
  }

  onHandleRemove(i, item) {
    const { data } = this.state
    data.splice(i, 1)
    this.setState({ data: data })
    this.props.setInputOptions(data, item)
  }

  onHandleChange(e) {
    this.setState({ newValue: e.target.value })
  }

  onHandleAdd() {
    const { data, newValue } = this.state
    const newOption = {
      name: newValue,
      custom: true
    }
    
    if (this.props.iconsBefore) {
      newOption.icon = 'empty-icon'
    }
    
    if (this.isValid(newValue)) {
      data.push(newOption)
      this.setState({
        data: data,
        newValue: ''
      }, () => this.props.setInputOptions(this.state.data))
    }
  }

  isValid(text) {
    let response = true
    if (text.length === 0) {
      CustomNotificationManager.warning('You should add an option.', 'Empty field')
      response = false
    } else if (this.state.data.indexOf(text) > -1) {
      CustomNotificationManager.warning('This option already exist.', 'Already exist')
      response = false
    }
    return response
  }
  
  showIconModal = (item, icon) => {
    const index = this.state.data.findIndex((x) => x === item)
    item.icon = icon
    this.setState({
      data: [
        ...this.state.data.slice(0, index),
        Object.assign({}, this.state.data[index], item),
        ...this.state.data.slice(index + 1)
      ]
      
    }, () => this.props.setInputOptions(this.state.data))
    
  }
  
  hide = (key) => {
    this.setState({
      visible: {
        ...this.state.visible, [key]: false
      }
    })
  }
  
  handleVisibleChange = (visible, key) => {
    this.setState({
      visible: {
        ...this.state.visible, [key]: visible
      }
    })
  }
  
  generatePopoverContent = (item, key) => {
    return (
      <div className="popconfirm-content">
        <i className="fa fa-times" onClick={() => this.hide(key)} style={{ cursor: 'pointer' }} />
        <div className="popconfirm-content__body">
          {
            this.defaultIcons.map((icon, i) => <span key={i} className={icon} onClick={() => this.showIconModal(item, icon)} style={{ cursor: 'pointer' }} />)
          }
        </div>
      </div>
    )
  }
  
  render() {
    const { data, newValue } = this.state
    return (
      <React.Fragment>
        <div className="options-container">
          { data &&
          data.map((item, i) => {
            return (
              <div className="item" key={ i }>
                { this.props.iconsBefore &&
                <Popover
                  content={this.generatePopoverContent(item, i)}
                  trigger="click"
                  className="icons-popover"
                  visible={this.state.visible[i]}
                  onVisibleChange={(visible) => this.handleVisibleChange(visible, i)}
                >
                  <button type="primary" style={{
                    fontSize: '17px',
                    marginRight: '5px'
                  }} className={ item.icon || 'empty-icon' } />
                </Popover>
                }
                <span className="item__option-text">{ item.name }</span>
                { item.custom &&
                  <i className="minus" onClick={() => this.onHandleRemove(i, item)}/>
                }
              </div>
            )
          })
          }
        </div>
        <div className="options-input">
          <input type="text" value={newValue} onChange={ (e) => this.onHandleChange(e) }/>
          <span className="add" onClick={ () => this.onHandleAdd() }/>
        </div>
      </React.Fragment>
    )
  }
}

InputOptions.propTypes = {
  iconsBefore: PropTypes.bool.isRequired,
  options: PropTypes.array.isRequired,
  setInputOptions: PropTypes.func.isRequired
}

export default InputOptions