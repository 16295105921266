import React from 'react'
import PropTypes from 'prop-types'
import Button from '../../Common/Button'
import { processesTemplateItems } from '../../../utils/BusinessProcess'
import './ButtonAddBusinessProcess.scss'
import withRouter from '../../../private-routers/withRouter'

export class ButtonAddBusinessProcess extends React.Component {
  constructor() {
    super()

    this.state = {
      selectedValue: ''
    }

    this.buttonClicked = this.buttonClicked.bind(this)
    this.updateValue = this.updateValue.bind(this)
  }

  updateValue(e) {
    this.setState({
      selectedValue: e.target.value
    })
  }

  buttonClicked() {
    this.props.navigate('/dpia/new')
  }

  renderOptions(options) {
    return (
      options.map((item, index) => (
        <option
          key={ index }
          value={ item.title }>{ item.title }
        </option>
      ))
    )
  }

  render() {
    const { title } = this.props
    const { selectedValue } = this.state

    const items = processesTemplateItems

    return (

      <div className="dpiaHelp__box">
        <header className="dpiaHelp__box-header">
          <h2>{ title }</h2>
        </header>
        <div className="limitedFreeAccount-container">
          <p className="buttonAddBusinessProcess-text">Select one of our common business processes to begin</p>
          <div className="buttonAddBusinessProcess-formGroup">
            <select value={ selectedValue } onChange={ this.updateValue }>
              <option value="">Choose...</option>
              { Object.keys(items).map((group) => (
                <optgroup key={ group } label={ group }>
                  { this.renderOptions(items[group]) }
                </optgroup>
              ))}
            </select>
          </div>
          <p className="buttonAddBusinessProcess-alterText">or simply click the button to capture your own business process</p>
          <div className="buttonAddBusinessProcess-button">
            <Button theme="green" onClick={ this.buttonClicked }>
              + Add new business process
            </Button>
          </div>
        </div>
      </div>

    )
  }
}

ButtonAddBusinessProcess = withRouter(ButtonAddBusinessProcess)

ButtonAddBusinessProcess.propTypes = {
  title: PropTypes.string.isRequired
}

export default ButtonAddBusinessProcess