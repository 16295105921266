export const mappedTopicsWithInitialInformation = (response) => {
  if (!response.data) return []
  if (!response.data.value) return []
  if (!response.data.value.clusters) return []
  if (!response.data.value.clusters.cluster) return []

  return response.data.value.clusters.cluster.map((topic) => ({
    files: [],
    hasMoreFilesToShow: false,
    id: topic.id,
    isFetchingFiles: true,
    isFetchingMoreFiles: false,
    weight: topic.weight ? topic.weight : 0,
    maxValue: topic.term ? topic.term[0].value : 0,
    title: topic.term ? topic.term[0].name : '',
    terms: topic.term ? topic.term.slice(1) : []
  }))
}

export const generateLetterList = (topics) => {
  const letters = { a: { disabled: true }, b: { disabled: true }, c: { disabled: true }, d: { disabled: true }, e: { disabled: true }, f: { disabled: true }, g: { disabled: true }, h: { disabled: true }, i: { disabled: true }, j: { disabled: true }, k: { disabled: true }, l: { disabled: true }, m: { disabled: true }, n: { disabled: true }, o: { disabled: true }, p: { disabled: true }, q: { disabled: true }, r: { disabled: true }, s: { disabled: true }, t: { disabled: true }, u: { disabled: true }, v: { disabled: true }, w: { disabled: true }, x: { disabled: true }, y: { disabled: true }, z: { disabled: true } }
  for (const topic of topics) {
    const letter = topic.title[0]
    letters[letter] = { disabled: false }
  }
  return letters
}

export const putFilesOnTopic = (response, topics, id) => {
  return topics.map((topic) => {
    if (topic.id === id) {
      const newDocumentList = topic.files.concat(response.data.documentList)

      return {
        ...topic,
        files: newDocumentList,
        hasMoreFilesToShow: newDocumentList.length < response.data.pagination.total,
        isFetchingFiles: false,
        isFetchingMoreFiles: false
      }
    }
    return topic
  })
}