import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Tooltip, Icon } from 'antd'
import PropTypes from 'prop-types'
import CustomNotificationManager from '../../../Common/CustomNotificationManager'

import { apiUserChangePassword } from '../../../../api/User'
import * as actions from '../../../../actions/messageActions'
import Loading from '../../../Common/Loading'
import PageTitle from '../../../Common/PageTitle'

import { getErrorMessage } from '../../../../utils'
import '../../../../style/common.scss'
import '../UserSettings/UserSettings.scss'
import withRouter from '../../../../private-routers/withRouter'

export class UserSettingsChangePassword extends Component {
  constructor() {
    super()
    
    this.state = {
      currentPassword: '',
      isSending: false,
      newPassword: '',
      retypePassword: '',
      conditionsPassword: [],
      conditionsRepeatPassword: [],
      newPasswordValid: false,
      retypePasswordValid: false,
    }

    this.saveChanges = this.saveChanges.bind(this)
  }

  componentWillUnmount() {
    this.props.hideMessage()
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.updatePasswprdStrengthCheckers(true, true, true, true, 8, '')
    this.updateRepeatPasswordStrengthCheckers('')
  }

  updatePasswprdStrengthCheckers = (upper, lower, number, special, minLength, value) => {
    const conditionsPassword = []
    if (upper) {
      conditionsPassword[0] = { res: false, msg: 'at least one uppercase letter', type: 'upper' }
      if (/[A-Z]/.test(value)) {
        conditionsPassword[0]['res']= true
      }
    }
    if (lower) {
      conditionsPassword[1] = { res: false, msg: 'at least one lowercase letter', type: 'lower' }
      if (/[a-z]/.test(value)) {
        conditionsPassword[1]['res']= true
      }
    }
    if (number) {
      conditionsPassword[2] = { res: false, msg: 'at least one number letter', type: 'number' }
      if (/[0-9]/.test(value)) {
        conditionsPassword[2]['res']= true
      }
    }
    if (special) {
      conditionsPassword[3] = { res: false, msg: 'at least one special letter', type: 'special' }
      if (/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(value)) {
        conditionsPassword[3]['res']= true
      }
    }
    if (minLength > 0) {
      conditionsPassword[4] = { res: false, msg: `at least ${ minLength } characters`, type: 'minLength' }
      if (value.length >= minLength) {
        conditionsPassword[4]['res'] = true
      }
    }

    let newPasswordValid = true
    if (conditionsPassword && conditionsPassword.length) {
      conditionsPassword.forEach((item) => {
        if (item.res === false) {
          newPasswordValid = false
        }
      })
    }
    this.setState({ conditionsPassword, newPasswordValid })
  }

  updateRepeatPasswordStrengthCheckers = (value) => {
    const conditionsRepeatPassword = []
    const { newPassword } = this.state
    conditionsRepeatPassword[0] = { res: false, msg: 'to not be empty' }
    if (value.length) {
      conditionsRepeatPassword[0]['res'] = true
    }
    conditionsRepeatPassword[1] = { res: false, msg: 'to be equal' }
    if (value === newPassword) {
      conditionsRepeatPassword[1]['res'] = true
    }
    let retypePasswordValid = true
    if (conditionsRepeatPassword && conditionsRepeatPassword.length) {
      conditionsRepeatPassword.forEach((item) => {
        if (item.res === false) {
          retypePasswordValid = false
        }
      })
    }
    this.setState({ conditionsRepeatPassword, retypePasswordValid })
  }

  updateValue(field, e) {
    if (field === 'newPassword') {
      this.updatePasswprdStrengthCheckers(true, true, true, true, 8, e.target.value)
    }
    if (field === 'retypePassword') {
      this.updateRepeatPasswordStrengthCheckers(e.target.value)
    }
    this.setState({
      [field]: e.target.value
    }, () => this.validateField(field))
  }

  validateField(field) {
    if (field === 'newPassword' || field === 'retypePassword') {
      const { newPassword, retypePassword } = this.state
      if (newPassword === retypePassword) {
        return this.props.hideMessage()
      }
    }
  }

  validateForm() {
    const { newPassword, retypePassword } = this.state
    let response = true
    if (newPassword !== retypePassword) {
      CustomNotificationManager.error('The passwords are not equal.', 'Error')
      response = false
      this.setState({ isSending: false }, () => { return response })
      return response
    }
    return response
  }
  saveChanges(e) {

    const { newPasswordValid, retypePasswordValid } = this.state
    e.preventDefault()
    if (newPasswordValid && retypePasswordValid) {
      this.changePassword()
    }
  }

  changePassword() {
    this.setState({
      isSending: true
    })
    this.props.hideMessage()

    const data = {
      email: this.props.location.state.email,
      currentPassword: this.state.currentPassword,
      password: this.state.newPassword
    }
    if (this.validateForm()) {
      apiUserChangePassword(data)
        .then((response) => {
          if (response.data.success) {
            this.setState({
              isSending: false,
              currentPassword: '',
              newPassword: '',
              retypePassword: ''
            })
            CustomNotificationManager.success('Your password has been successfully updated.', 'Successfully updated')          

          }
        }).catch((e) => {
          const showError = getErrorMessage(e)[0] === 'Wrong email or password.' ? 'Wrond passowrd.' : getErrorMessage(e)
          CustomNotificationManager.error(showError, 'Error')
          this.setState({
            isSending: false
          })
        })
    }
  }

  renderPasswordConditions = (field) => {
    let conditions = this.state.conditionsPassword
    if (field === 'repeatPassword') {
      conditions = this.state.conditionsRepeatPassword
    }
    return (
      <div className="tooltip-wrapper">
        <p className="tooltip-wrapper__title">
          { field === 'repeatPassword' ? 'This field must' : 'The password must contain' }
        </p>
        { conditions && conditions.length > 0 && conditions.map((item, index) => {
          return (
            <p key={index} className={item.res ? 'tooltip-wrapper__item-green' : 'tooltip-wrapper__item-red' }>
              <Icon type={ item.res ? 'check-circle' : 'close-circle'} />
              { item.msg }
            </p>
          )
        }) }
      </div>
    )
  }

  render() {
    const { currentPassword, isSending, newPassword, retypePassword, newPasswordValid, retypePasswordValid } = this.state

    return (
      <div className="userSettings-page">
        <div className="userSettings-box">
          <PageTitle title="Osprey Settings" />
          
          <form autoComplete="off">
            <div className="userSettings-section">
              <header>
                <h1 className="userSettings-sectionTitlePassword">Change Password</h1>
              </header>

              <div className="userSettings-container">
                <div className="userSettings-formGroupSide">
                  <label htmlFor="current">Current password</label>
                  <input
                    id="current"
                    type="password"
                    value={this.state.currentPassword}
                    onChange={ this.updateValue.bind(this, 'currentPassword') } />
                </div>

                <div className="userSettings-formGroupSide">
                  <label htmlFor="new">New password</label>
                  <Tooltip trigger={['focus', 'hover']} title={ this.renderPasswordConditions('password')} overlayClassName="tooltip-over" >
                    <input
                      id="new"
                      type="password"
                      value={this.state.newPassword}
                      onChange={ this.updateValue.bind(this, 'newPassword') }
                    />
                  </Tooltip>
                </div>

                <div className="userSettings-formGroupSide">
                  <label htmlFor="retype">Retype password</label>
                  <Tooltip trigger={['focus', 'hover']} title={ this.renderPasswordConditions('repeatPassword')} overlayClassName="tooltip-over" >
                    <input
                      id="retype"
                      type="password"
                      value={this.state.retypePassword}
                      onChange={ this.updateValue.bind(this, 'retypePassword') }
                    />
                  </Tooltip>
                </div>
              </div>
            </div>

            <footer className="userSettings-footer">
              <Link className="userSettings-footerLink" to="/osp/user/settings">Back to Osprey settings</Link>
              { isSending &&
                <Loading />
              }
              { !isSending &&
                <input
                  className="userSettings-footerButton"
                  disabled={ currentPassword === '' || newPassword === '' || retypePassword === '' || !newPasswordValid || !retypePasswordValid || newPassword !== retypePassword }
                  type="submit"
                  value="Save changes"
                  onClick={ this.saveChanges } />
              }
            </footer>
          </form>
        </div>
      </div>
    )
  }
}

const stateMap = (state) => ({
  messageArea: state.messageAreaReducer.messageArea
})
UserSettingsChangePassword = withRouter(UserSettingsChangePassword)
const dispatchMap = (dispatch) => ({
  hideMessage: () => dispatch(actions.hideMessage()),
  showMessage: (status, text) => dispatch(actions.showMessage({ status, text }))
})

UserSettingsChangePassword.propTypes = {
  hideMessage: PropTypes.func.isRequired,
  messageArea: PropTypes.object.isRequired,
  showMessage: PropTypes.func.isRequired
}

export default compose(connect(stateMap, dispatchMap))(UserSettingsChangePassword)
