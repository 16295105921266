import React from 'react'
import {  Navigate, Outlet } from 'react-router-dom'
import { connect } from 'react-redux'
import CustomNotificationManager from '../components/Common/CustomNotificationManager'

const PrivateRoute = (props) => {
    if (props.isLoggedIn) {
      const found = props.roles.includes(props.userRoles[props.domain])
      if (found) {
        return <Outlet />
      }
      // CustomNotificationManager.info('Access restricted', 'Unauthorized')
      return <Navigate to="/smart-box/management"/>
    }
    return <Navigate to="/osp/login"/>
  }

const mapStateToProps = (state) => ({
  isLoggedIn: state.userReducer.isLogged,
  userRoles: state.userReducer.user.user_metadata.roles
})

export default connect(mapStateToProps, {})(PrivateRoute)