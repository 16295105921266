import React from 'react'
import PropTypes from 'prop-types'

import Button from '../../Common/Button'
import Loading from '../../Common/Loading'
import Locations from './Locations'
import Modal from '../../Common/Modal'
import Terms from './Terms'

import WithTopics from '../HOC/WithTopics'

import './ReviewTopic.css'

class ReviewTopic extends React.Component {
  state = { page: 0, openedStatus: 'split' }
  
  componentDidMount = () => {
    this.props.getLocationsForTopic(this.props.modal.id, 0)
  }

  changeStatus = (value) => {
    this.setState({ openedStatus: value })
  }

  findTopic = (id) => {
    const topic = this.props.allTopics
      .filter((topic) => topic.id === id)
   
    return topic.length > 0 ? topic[0] : {
      files: [], maxValue: 0, title: '', terms: []
    }
  }

  showMore = () => {
    this.setState({ page: this.state.page + 1 },
      () => {
        const { id } = this.props.modal
        this.props.updateTopic(id, { isFetchingMoreFiles: true })
        this.props.getLocationsForTopic(id, this.state.page)
      })
  }

  onClose = () => {
    this.props.updateTopic(this.props.modal.id, {
      isFetchingFiles: true, files: []
    })
    this.props.onClose()
  }

  render = () => {
    const { openedStatus } = this.state
    const topic = this.findTopic(this.props.modal.id)

    return (
      <Modal hideModal={ this.onClose }>
        <div className="reviewTopic__header">
          <h1 className="reviewTopic__header-title">{ topic.title }</h1>
        </div>
        <div className={ `reviewTopic__content reviewTopic__status-${ openedStatus }` }>
          <div className="reviewTopic__content-left">
            <Terms maxValue={ topic.maxValue } terms={ topic.terms } />
          </div>
          <div className="reviewTopic__content-toggle">
            <ul>
              <li
                className={ openedStatus === 'right' ? 'reviewTopic__content-toggle-item-active' : 'reviewTopic__content-toggle-item' }
                onClick={ this.changeStatus.bind(this, 'right') }
              >&gt;</li>
              <li
                className={ openedStatus === 'split' ? 'reviewTopic__content-toggle-item-active' : 'reviewTopic__content-toggle-item' }
                onClick={ this.changeStatus.bind(this, 'split') }
              >|</li>
              <li
                className={ openedStatus === 'left' ? 'reviewTopic__content-toggle-item-active' : 'reviewTopic__content-toggle-item' }
                onClick={ this.changeStatus.bind(this, 'left') }
              >&lt;</li>
            </ul>
          </div>
          <div className="reviewTopic__content-right">
            { topic.isFetchingFiles &&
              <div className="reviewTopic__content--empty"><Loading /></div>
            }

            { (!topic.isFetchingFiles && topic.files.length === 0)
              ? <div className="reviewTopic__content--empty">No files</div>
              : <div>
                <Locations files={ topic.files } openFilePreview={ this.props.openFilePreview } />
                <div className="reviewTopic__content--action">
                  { topic.isFetchingMoreFiles &&
                    <Loading />
                  }
                  { topic.hasMoreFilesToShow &&
                    !topic.isFetchingMoreFiles &&
                    <Button onClick={ this.showMore }>Show more files</Button>
                  }
                </div>
              </div>
            }
          </div>
        </div>
      </Modal>
    )
  }
}

ReviewTopic.propTypes = {
  modal: PropTypes.shape({
    id: PropTypes.string.isRequired
  }),
  onClose: PropTypes.func.isRequired,
  updateTopic: PropTypes.func.isRequired
}

export default WithTopics(ReviewTopic)