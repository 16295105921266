import React from 'react'
import PropTypes from 'prop-types'
import { Tree, Button, Select } from 'antd'
import {
  renderTreeSearchImages
} from '../../../utils/SmartBox/SmartBoxMethods'
import {
  getSmartBoxScanAnnotations, apiSmartBoxTreeForPicture, apiSmartBoxTreeForPicturePage
} from '../../../api/SmartBox'
import { getAnnotationColorsByClientId, getScanAnnotations } from '../../../api/Sar'
import './SearchImagesContent.scss'
import '../Tracker/Tracker.scss'
import './AiAnnotationsSidebar.scss'
//import ReviewRedact from './ReviewRedact'
import swal from 'sweetalert2'
import CustomNotificationManager from '../../Common/CustomNotificationManager'
import ReviewSpellImg from "./ReviewSpellImg";

const { Option } = Select

class SearchImagesContent extends React.Component {

  state = {
    displayColorPicker: [],
    categoriesColors: {},
    categoriesVisible: {},
    searchLoading: false,
    redactedKeys: [],
    pageRectangleMap: {},
    pageCategories: '',
    treeData: [],
    expandedKeys: [ 'select-all' ],
    autoExpandParent: true,
    checkedKeys: [],
    selectedKeys: [],
    pageNumberSelect: '',
    valueRadioSelect: '',
    visibleSearchButton: false,
    activeKey: 'searchImages',
    searchButtonClicked: false
  }
  componentDidMount() {
    if (this.props.isDocumentReady) {
      this.renderCategoriesSections()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.isDocumentReady !== this.props.isDocumentReady) {
      this.renderCategoriesSections()
    }
    if (prevProps.isNextDocumentId !== this.props.isNextDocumentId) {
      this.setState({
        treeData: []
      })
      this.OnSearchTreeForPictureUpdate(this.props.isNextDocumentId.id)
    }
  }
  OnSearchTreeForPictureUpdate = (documentId) => {
    if (this.props.isSmartBoxApplication) {
      apiSmartBoxTreeForPicture(documentId).then((response) => {
        if (response.data[0].children.length !== 0) {
          this.setState({
            treeData: response.data[0].children
          })
        } else {
          CustomNotificationManager.info('No images found', 'Empty')
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
            console.log(error)
        }
      })
    }
  }
  fetchSmartBoxTreeForPicture = () => {
    const { documentId } = this.props
    if (this.props.isSmartBoxApplication) {
      apiSmartBoxTreeForPicture(documentId).then((response) => {
        if (response.data[0].children.length !== 0) {
          this.setState({
            treeData: response.data
          })
        } else {
          CustomNotificationManager.info('No images found', 'Empty')
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
            console.log(error)
        }
      })
    }
  }
  fetchSmartBoxTreeForPicturPage = () => {
    const { pageNumberSelect } = this.state
    const { documentId } = this.props
    const data = {
      id: documentId,
      page: pageNumberSelect
    }
    if (this.props.isSmartBoxApplication) {
      apiSmartBoxTreeForPicturePage(data).then((response) => {
        if (response.data[0].children.length !== 0) {
          this.setState({
            treeData: response.data
          })
        } else {
          CustomNotificationManager.info('No images found', 'Empty')
          this.setState({
            treeData: [],
            checkedKeys: []
          })
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
            console.log(error)
        }
      })
    }
  }
  fetchData = async(docId, clientId, userId) => {
    let response1
    if (this.props.isSmartBoxApplication) {
      response1 = await getSmartBoxScanAnnotations(docId)
    } else {
      response1 = await getScanAnnotations(docId)
    }
    const response2 = await getAnnotationColorsByClientId(clientId, userId)
    this.setState({
      docAnnotations: response1.data,
      docAnnotationsColors: response2.data,
      categoriesColors: {
        names: response2.data.filter((annColor) => annColor.category === 'names')[0].color,
        locations: response2.data.filter((annColor) => annColor.category === 'locations')[0].color,
        numbers: response2.data.filter((annColor) => annColor.category === 'numbers')[0].color,
        time: response2.data.filter((annColor) => annColor.category === 'time')[0].color,
        addresses: response2.data.filter((annColor) => annColor.category === 'addresses')[0].color,
        identifiers: response2.data.filter((annColor) => annColor.category === 'identifiers')[0].color,
        organisations: response2.data.filter((annColor) => annColor.category === 'organisations')[0].color
      }
    }, () => {
      this.renderCategoriesSections()
    })
  }
  OnSearchTreeForPicture = () => {
    const { documentId } = this.props
    if (this.props.isSmartBoxApplication) {
      apiSmartBoxTreeForPicture(documentId).then((response) => {
        if (response.data[0].children.length !== 0) {
          this.setState({
            treeData: response.data[0].children
          })
        } else {
          CustomNotificationManager.info('No images found', 'Empty')
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
            console.log(error)
        }
      })
    }
  }
  renderCategoriesSections = () => {
    const { treeData } = { ...this.state }
    let { expandedKeys } = { ...this.state }
    let keys = []

    if (treeData) {
      treeData.map((item) => {
        keys = item.key
        return true
      })
      this.setState({
        treeData,
        expandedKeys: [ ...expandedKeys, ...keys ],
        autoExpandParent: false
      })
    }
    for (const category of Object.keys(treeData)) {
      expandedKeys = category
      keys = category
      return keys
    }
    this.setState({
      treeData,
      expandedKeys: [ ...expandedKeys, ...keys ],
      autoExpandParent: false
    })
  }

  expandHideCategory = (e, key, action) => {
    e.stopPropagation()
    let { expandedKeys } = { ...this.state }
    if (action === 'add') {
      expandedKeys.push(key)
    } else {
      expandedKeys = expandedKeys.filter((eKey) => eKey !== key)
    }
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    })
  }

  addNewCategory = (e) => {
    e.stopPropagation()
    this.setState({
      addNewCategory: !this.state.addNewCategory,
      inputValue: ''
    })
  }

  findElementByClassAndAddMarkPulse(selectedKeys, node) {
    let { expandedKeys } = { ...this.state }
    if (node.props.dataRef.identifier === 'top-parent' || node.props.dataRef.identifier === 'category-parent') {
      if (!expandedKeys.includes(node.props.dataRef.key)) {
        expandedKeys.push(node.props['data-key'])
        this.setState({
          expandedKeys,
          autoExpandParent: false
        })
      } else {
        expandedKeys = expandedKeys.filter((eKey) => eKey !== node.props['data-key'])
        this.setState({
          expandedKeys,
          autoExpandParent: false
        })
      }
    } else {
      this.setState({
        selectedWord: selectedKeys && selectedKeys.length > 0 ? selectedKeys[0] : ''
      }, () => {
        if (this.state.selectedWord) {
          const { backgroundColor, pageNumber } = node.props.dataRef
          const oldElement = document.querySelector('#matched-word--current')
          if (oldElement) {
            oldElement.removeAttribute('id')
          }
          if (+pageNumber !== +this.props.pageNumber) {
            this.props.changePage(+pageNumber)
          }
          const element = document.getElementsByClassName(this.state.selectedWord)
          if (element && element.length > 0) {
            element[0].id = 'matched-word--current'
            element[0].style.borderColor = backgroundColor.replace('0.3', '1')
            element[0].scrollIntoView(false)
          }
        } else {
          const element = document.querySelector('#matched-word--current')
          element.removeAttribute('id')
        }
      })
    }
  }

  onExpand = (expandedKeys) => {
    this.setState({
      expandedKeys,
      autoExpandParent: true,
    })
  };

  onSelect = (selectedKeys, info) => {
    this.setState({ selectedKeys })
  }

  onCheck = (checkedKeys, { checkedNodes }) => {
    const { canvasData } = this.props
    Object.keys(canvasData).map((page) => {
      canvasData[page].map((annotation) => {
        const convertedAnnotation = this.isJson(annotation) ? JSON.parse(annotation) : annotation
        checkedKeys.forEach((check) => {
          if (convertedAnnotation.pagePointSum === check) {
            this.props.highlightRectangle(convertedAnnotation, annotation, page)
          }
          return true
        })
        return true
      })
      return true
    })
    this.setState({
      checkedKeys,
      checkedKeysForDelete: checkedNodes.map((node) => node.props.dataRef).filter((obj) => obj.identifier === 'category-parent'),
      checkedKeysForDeleteChildren: checkedNodes.map((node) => node.props.dataRef).filter((obj) => obj.identifier === 'c'),
      checkedKeysObjectRefs: checkedNodes.map((node) => node.props.dataRef).filter((obj) => obj.key)
    })
  };

  removeSearchImage = () => {
    const { checkedKeysForDelete, treeData, checkedKeysForDeleteChildren } = { ...this.state }

    for (const checkedKey of checkedKeysForDelete.map((cKey) => cKey.key)) {
      treeData[0].children = treeData[0].children.filter((child) => child.key !== checkedKey)
      this.setState({
        treeData,
        checkedKeysForDelete: []
      })
    }

    for (const checkedKey of checkedKeysForDeleteChildren.map((cKey) => cKey.key)) {
      for (const children of treeData[0].children) {
        children.children = children.children.filter((child) => child.key !== checkedKey)
        this.setState({
          treeData,
          checkedKeysForDeleteChildren: []
        })
      }
    }
  }

  expandHideCategory = (e, key, action) => {
    e.stopPropagation()
    let { expandedKeys } = { ...this.state }
    if (action === 'add') {
      expandedKeys.push(key)
    } else {
      expandedKeys = expandedKeys.filter((eKey) => eKey !== key)
    }
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    })
  }
  onChangeSelect = (e) => {
    const { valueRadioSelect } = this.state
    if (e !== '' && valueRadioSelect === 'redact-page') {
      this.setState({
        pageNumberSelect: e,
        visibleSearchButton: true
      })
    } else {
      this.setState({
        visibleSearchButton: false,
        pageNumberSelect: ''
      })
    }
  }

  onChangeRadioGroup = (e) => {
    if (e.target.value === 'redact-document') {
      this.setState({
        valueRadioSelect: e.target.value,
        pageNumberSelect: '',
        visibleSearchButton: true
      })
    } else {
      this.setState({
        valueRadioSelect: e.target.value,
        visibleSearchButton: false
      })
    }
  }

  onClickSearch = () => {
    const { valueRadioSelect } = this.state
    //const { pageNumberSelect } = this.state
    if (valueRadioSelect === 'redact-document') {
      this.fetchSmartBoxTreeForPicture()
      //this.props.highlightRectangle(true, true, 1)
      this.setState({
        pageNumberSelect: '',
        visibleSearchButton: false,
        searchButtonClicked: true
      })
    } else {
      this.fetchSmartBoxTreeForPicturPage()
      //this.props.highlightRectangle(true, true, pageNumberSelect)
      this.setState({
        visibleSearchButton: false,
        searchButtonClicked: true
      })
    }
  }

  setSearchButtonClicked = (value) => {
    this.setState({searchButtonClicked: value})
  }
  drawMatchedWords = (classList) => {
    const textMessageRedact = classList && classList.length === 1 ? `Redact ${ classList.length } selected?` : `Redact all ${ classList.length } selected?`
    swal.fire({
      text: 'Redact all selected?',
      html: `  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 19 25">
                  <g fill="none" fillRule="evenodd">
                    <path stroke=" #00D3D8" strokeWidth="4" d="M18 24V7.215L11.716 1H1v23h17z"/>
                    <path stroke="#00D3D8"  strokeWidth="4" d="M11 1v7h7"/>
                    <path fill="#00D3D8" d="M5 12h9v3H5zM5 17h6v3H5z"/>
                  </g>
                </svg>
          <h3 class="swall-redact-title">${ textMessageRedact }</h3>`,
      showCancelButton: true,
      confirmButtonColor: '#3DD8DB',
      cancelButtonColor: '#3DD8DB',
      confirmButtonText: 'REDACT',
      customClass: {
        container: 'redact-swall-wr'
      }
    }).then((result) => {
      if (result.value) {
        this.setState({
          redactedKeys: new Set([ ...this.state.redactedKeys, ...classList ])
        }, () => this.props.drawAllMatchedWordsByClassList(classList))
      } else {
        CustomNotificationManager.info('Action cancelled', 'Aborted')
      }
    })
  }
  isJson(str) {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }

  render() {
    const { expandedKeys, checkedKeys, redactedKeys, treeData } = { ...this.state }
    const { canvasData, totalPages } = this.props

    const selectPageImage = []
    for (let i = 1; i <= totalPages; i++) {
      selectPageImage.push(i)
    }

    canvasData && Object.keys(canvasData).map((page) => {
      canvasData[page] && canvasData[page].map((item, key) => {
        const itemDecoded = this.isJson(item) ? JSON.parse(item) : item
        //const filterKey = checkedKeys.filter((obj) => obj === itemDecoded.pagePointSum)
        checkedKeys.forEach((kk) => {
          if (itemDecoded.pagePointSum === kk) {
          }
        })
        return true
      })
      return true
    })
    return (
      treeData && expandedKeys ?
        <div className="search-images">
          <div className="search-images-container">
            <div className="header-action-ai-results">
              <div className="content-option-ai">
                <span className="icon-v17-redaction-hover"></span>
                <Select
                    placeholder="Show all"
                    className='custom-option-review'
                >
                  <Option className="item-options-review" key={1} value="review-all">
                    show all
                  </Option>
                  <Option className="item-options-review" key={2} value="review-redacted">
                    redacted
                  </Option>
                  <Option className="item-options-review" key={3} value="review-annotated">
                    annotated
                  </Option>
                </Select>
              </div>
              {treeData && treeData.length > 0 ?
                  <ReviewSpellImg
                      //checkedKeysObjectRefs={checkedKeysObjectRefs}
                      pageNumber={this.props.pageNumber}
                      changePage={this.props.changePage}/>
                  :
                  <div className="search-images-container__button-search-images">
                    <Button className="search-button button-green"
                        //disabled={ !visibleSearchButton}
                            onClick={this.OnSearchTreeForPicture}
                    >Search</Button>
                  </div>
              }
            </div>
            {/*<div className="search-images-container__title-images">SEARCH IMAGES</div>*/}
            {/*<div className="search-images-container__select-contain">*/}
            {/*  <Radio.Group onChange={ this.onChangeRadioGroup }>*/}
            {/*    <Radio value="redact-document"> Document</Radio>*/}
            {/*    <Radio value="redact-page"> Page </Radio>*/}
            {/*  </Radio.Group>*/}
            {/*  <Select onChange={ (e) => this.onChangeSelect(e) } value={ pageNumberSelect } className="select-page-image">*/}
            {/*    {*/}
            {/*      selectPageImage && selectPageImage.map((numberPage, i) => (*/}
            {/*        <Option className="items-options" value={ numberPage } key={ numberPage }>*/}
            {/*          { numberPage }*/}
            {/*        </Option>*/}
            {/*      ))*/}
            {/*    }*/}
            {/*  </Select>*/}
            {/*</div>*/}
            {/*<div className="search-images-container__button-search-images">*/}
            {/*  <Button className="search-button button-green"*/}
            {/*    disabled={ !visibleSearchButton}*/}
            {/*    onClick={this.onClickSearch }*/}
            {/*  >SEARCH</Button>*/}
            {/*</div>*/}

            <div className="search-images-container__content-search-images ai-tags-scroll image-tags-scroll">
              <Tree
                checkable
                onExpand={this.onExpand}
                defaultExpandAll={ true }
                expandedKeys={ expandedKeys }
                defaultExpandedKeys={ expandedKeys }
                autoExpandParent={this.state.autoExpandParent}
                onCheck={ this.onCheck }
                checkedKeys={ checkedKeys }
                onSelect={(selectedKeys, { node }) => this.findElementByClassAndAddMarkPulse(selectedKeys, node)}
                selectedKeys={this.state.selectedKeys}

              >
                { renderTreeSearchImages(
                    treeData,
                  expandedKeys,
                  this.expandHideCategory,
                  this.removeSearchImage,
                  redactedKeys) }
              </Tree>
            </div>
          </div>
          {/*<ReviewRedact*/}
          {/*  pageNumber={ pageNumber }*/}
          {/*  isDocumentReady={isDocumentReady}*/}
          {/*  //checkedKeysObjectRefs={ checkedKeysObjectRefs }*/}
          {/*  changePage={ changePage }*/}
          {/*  drawAllMatchedWordsByClassList={ this.drawMatchedWords }*/}
          {/*  activeKey = { activeKey }*/}
          {/*  checkedKeys = { checkedKeys }*/}
          {/*  treeData = { treeData }*/}
          {/*  documentId = { this.props.documentId }*/}
          {/*  searchButtonClicked = { searchButtonClicked }*/}
          {/*  setSearchButtonClicked = {this.setSearchButtonClicked }*/}
          {/*/>*/}
        </div>
        : 'loading...'
    )
  }
}

SearchImagesContent.propTypes = {
  highlightRectangle: PropTypes.func.isRequired,
  isDocumentReady: PropTypes.bool.isRequired
}

export default SearchImagesContent