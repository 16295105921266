import React from 'react'
import { connect } from 'react-redux'
import { Button, Tabs } from 'antd'

import './SmartBoxFolderSettings.scss'
import FolderRedactionTab from './FolderRedaction/FolderRedactionTab'
import FolderInsightsTab from './FolderInsightsTab'
import FolderSettingsTab from './FolderSettings/FolderSettingsTab'
import { getSmartBoxFileById } from '../../../../api/SmartBox'
import ExploreData from './ExploreData'
import { setSmartBoxExploreDataVisibility } from '../../../../actions/smartBoxActions'
import {Navigate} from "react-router-dom";
import CustomNotificationManager from "../../../Common/CustomNotificationManager";
import withRouter from '../../../../private-routers/withRouter'

class SmartBoxFolderSettings extends React.Component {
  state = {
    folder: {},
    foldrId: null,
    activeKey: '1'
  }
  
  componentDidMount() {
    this.fetchFolderInfo()
  }
  
  fetchFolderInfo = () => {
    this.setState({
      folderId: this.props.params.id
    }, () => {
      getSmartBoxFileById(this.state.folderId).then((response) => {
        if (response.status === 200) {
          if (!response.data.path && !response.data.smartDocument) {
            CustomNotificationManager.error('The current folder no longer exists or you do not have access to its settings', 'Error')
            const path = `/smart-box`
            this.props.navigate(path)
          }
          this.setState({
            folder: response.data.smartDocument
          })
        }
      }).catch((error) => {
        if (error?.response?.status === 500 && error?.response?.data?.detail === `You don't have access to this folder`) {
          CustomNotificationManager.error(`You don't have access to this folder`, 'Error')
          this.props.navigate('/smart-box')
        }else if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
      })
    })
  }
  
  onChange = (activeKey) => this.setState({ activeKey: activeKey })
  
  render() {
    const { folder, activeKey } = this.state
    const { smartBoxExportDataVisible } = this.props
    const { TabPane } = Tabs
    if (!folder) {
      const path = `/smart-box`
      return <Navigate to={ path } />
    }
    return (
      !smartBoxExportDataVisible ? <div className="smart-box-folder-settings_container">
        <div className="smart-box-folder-settings_header">
          <span className="folder-name">{ folder.name }</span>
          <Button onClick={ () => this.props.navigate(-1) }>Back</Button>
        </div>
        <div
          className={ activeKey === '1' ? 'smart-box-folder-settings_content smart-box-folder-settings_box-next' : activeKey === '3' ? 'smart-box-folder-settings_content smart-box-folder-settings_box-last' : 'smart-box-folder-settings_content' }>
          <Tabs type="card" className="parent-tabs" defaultActiveKey={ activeKey } onChange={ this.onChange }>
            <TabPane tab="Folder Settings" key="1">
              <FolderSettingsTab folder={ folder }/>
            </TabPane>
            <TabPane tab="Folder Insights" key="2">
              <FolderInsightsTab folder={ folder }/>
            </TabPane>
            <TabPane tab="Folder Redaction" key="3">
              <FolderRedactionTab folder={ folder } fetchFolderInfo={ this.fetchFolderInfo }/>
            </TabPane>
          </Tabs>
        </div>
      </div>
        :
        <ExploreData folder={ folder }
          hideExploreDataComponent={ () => this.props.setSmartBoxExploreDataVisibility(false) }/>
    )
  }
}

SmartBoxFolderSettings = withRouter(SmartBoxFolderSettings)
const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id,
  smartBoxExportDataVisible: state.smartBox.smartBoxExportDataVisible
})

export default connect(mapStateToProps, { setSmartBoxExploreDataVisibility })(SmartBoxFolderSettings)