import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { compose } from 'redux'
import swal from 'sweetalert2'
import * as manageGroupsActions from '../../../actions/manageGroupsActions'
import * as actions from '../../../actions/messageActions'
import { apiDpiaArchiveProcess, apiDpiaFetchProcess, apiDpiaProcessSave } from '../../../api/Dpia'
import { apiQuestionnairesList } from '../../../api/Questionnaire'
import { apiTeamFetchTeamsList } from '../../../api/Team'
import { apiUserFetchUsersListMinimized } from '../../../api/User'
import { userIsAdmin, userIsSuperAdmin } from '../../../utils'
import CustomNotificationManager from '../../Common/CustomNotificationManager'
import * as utilsBP from '../../../utils/BusinessProcess'
import Button from '../../Common/Button'
import ErrorPage from '../../Common/ErrorPage'
import '../../Common/FontAwesome.min.css'
import Loading from '../../Common/Loading'
import Message from '../../Common/Message'
import PageTitle from '../../Common/PageTitle'
import { findTemplateVersions, groupTemplate, templateSelected } from '../Dashboard/Helpers/Templates'
import '../../../style/common.scss'
import './BusinessProcessForm.scss'
import OptionNo from './Options/OptionNo'
import OptionYes from './Options/OptionYes'
import RepeatOption from './Options/RepeatOption'
import { Select } from 'antd'
import withRouter from '../../../private-routers/withRouter'
const { Option } = Select
export class BusinessProcessForm extends Component {
  _isMounted = false

  constructor() {
    super()

    this.state = {
      ...this.defaultState,
      templateVersions: [],
      PIATemplates: [],
      PIATemplateVersions: [],
      DPIATemplates: [],
      DPIATemplateVersions: [],
      allTemplates: [],
      teamsList: [],
      usersList: [],
      selectUserDisabled: false,
      selectTeamDisabled: false,
      piaTemplateId: '',
      selectedBusinessProcess: ''
    }
    this.changeQuestionGroupType = this.changeQuestionGroupType.bind(this)
  }

  get defaultState() {
    return {
      currentUrl: '',
      description: '',
      error: null,
      isEditMode: false,
      isSending: false,
      title: '',
      options: ['yes', 'no'],
      periods: ['Monthly', '3 months', '6 months', '12 months'],
      periodSelected: 'Monthly',
      processId: null,
      questionGroupTypes: [
        {
          value: 'DATA_CONTROLLER_DPIA',
          selected: true,
          title: 'I\'m a Data Controller',
          description: 'You <span>determine</span> the purposes and means of the processing of personal data.'
        },
        {
          value: 'DATA_PROCESSOR_DPIA',
          selected: false,
          title: 'I\'m a Data Processor',
          description: 'You process personal data <span>on behalf</span> of another party.'
        },
        {
          value: '',
          selected: false,
          title: 'I don\'t know',
          description: 'That\'s okay, we\'ll use a default setting.'
        }
      ],
      NavigateToArchiveDashboard: false,
      NavigateToDashboard: false,
      repeatSelected: 'no',
      selectedBusinessProcess: '',
      showRepeat: false,
      userSelected: '',
      teamSelected: '',
      PIATemplateSelected: '',
      DPIATemplateSelected: '',
      crtDPIATemplateId: '',
      piaTemplateId: ''
    }
  }

  componentDidMount() {
    this._isMounted = true
    window.scroll(0, 0)
    this.fetchUsersList()

    const initialValue = this.state.selectedBusinessProcess
    // When we have the `processId` on the url, we're on the edit mode.

    if (this.props.params.processId) {
      if (this._isMounted) {
        this.setState({ isEditMode: true })
      }
      this.getBusinessProcessInfo(this.props.params.processId)
    } else {
      this.fetchTemplates()
    }
    this.fetchTeams()
    if (this._isMounted) {
      this.setState({
        description: utilsBP.getDescription(initialValue),
        selectedBusinessProcess: initialValue,
        title: initialValue,
        currentUrl: this.props.location.pathname
      })
    }
  }

  fetchTemplates() {
    apiQuestionnairesList('&enabled=true&withActiveVersionsOnly=true')
      .then((response) => {
        if (this._isMounted) {
          this.setState({ allTemplates: response.data })
        }
        const templates = groupTemplate(this.state.allTemplates)
        if (this.state.isEditMode) {
          const selectedGroup = this.state.questionGroupTypes.find((questionGroup) => (questionGroup.selected === true))
          const { crtDPIATemplateId, piaTemplateId } = this.state
          const withSelectedTemplates = findTemplateVersions(templates, crtDPIATemplateId, piaTemplateId, selectedGroup)
          if (this._isMounted) {
            this.setState({ ...withSelectedTemplates })
          }
        } else {
          this.changeTemplates()
        }
      })
  }

  fetchTeams() {
    apiTeamFetchTeamsList()
      .then((response) => {
        const teams = []
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].enabled) {
            teams[teams.length] = response.data[i]
          }
        }
        const teamsTemp = teams
        for (let i = 0; i < teams.length; i++) {
          if ((teams[i].members !== null || typeof teams[i].members !== 'undefined') && teams[i].members.length) {
            for (let j = 0; j < teams[i].members.length; j++) {
              if (teams[i].members[j].id === this.props.user.id && teams[i].members[j].enabled === false) {
                teamsTemp.splice(i, 1)
              }
            }
          }
        }
        this.setState({
          teamsList: teamsTemp,
        })
      })
  }

  changeTemplates() {
    const { crtDPIATemplateId, piaTemplateId, allTemplates } = this.state

    const templates = groupTemplate(allTemplates)

    const PIAGlobal = templates.PIATemplates.find((template) => template.isGlobal) || {}
    const PIATemplateSelect = templates.PIATemplates.find((template) => template.versions.find((version) => (version.id === piaTemplateId))) || {}

    const DPIAGlobalController = templates.DPIAControllerTemplates.find((template) => template.isGlobal) || templates.DPIAControllerTemplates[0]
    const DPIATemplateSelect = templates.DPIAControllerTemplates.find((template) => template.versions.find((version) => (version.id === crtDPIATemplateId))) || {}

    const DPIAGlobalProcessor = templates.DPIAProcessorTemplates.find((template) => template.isGlobal) || templates.DPIAProcessorTemplates[0]
    const DPIAProcessorTemplateSelect = templates.DPIAProcessorTemplates.find((template) => template.versions.find((version) => (version.id === crtDPIATemplateId))) || {}

    const selectedGroup = this.state.questionGroupTypes.find((questionGroup) => (questionGroup.selected === true))

    this.setState({ PIATemplates: templates.PIATemplates, templateVersions: templates.templateVersions })
    this.selectedTemplate(PIATemplateSelect.id || PIAGlobal.id, 'PIATemplateSelected')
    if (selectedGroup.value === '') {
      this.setState({ DPIATemplates: templates.DPIAControllerTemplates })
      this.selectedTemplate(DPIATemplateSelect.id || DPIAGlobalController.id, 'DPIATemplateSelected')
    }

    if (DPIAGlobalController && selectedGroup.value === 'DATA_CONTROLLER_DPIA') {
      this.setState({ DPIATemplates: templates.DPIAControllerTemplates })
      this.selectedTemplate(DPIATemplateSelect.id || DPIAGlobalController.id, 'DPIATemplateSelected')
    }

    if (selectedGroup.value === 'DATA_PROCESSOR_DPIA') {
      this.setState({ DPIATemplates: templates.DPIAProcessorTemplates })
      this.selectedTemplate(DPIAProcessorTemplateSelect.id || DPIAGlobalProcessor.id, 'DPIATemplateSelected')
    }
  }

  componentDidUpdate() {
    if (this.state.currentUrl === '') return false

    if (this.props.location.pathname !== this.state.currentUrl) {
      this.resetState()
    }
  }

  resetState() {
    this.setState(this.defaultState)
  }

  componentWillUnmount() {
    this._isMounted = false
    this.setState({selectedBusinessProcess : ''})
    this.props.hideMessage()
  }

  getBusinessProcessInfo(processId) {
    const idToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId
    const data = {
      id_token: idToken
    }
    apiDpiaFetchProcess(data, processId)
      .then((response) => {
        if (response.data) {
          const { data } = response
          if (this._isMounted) {
            this.setState({
              questionGroupTypes: this.selectedQuestionGroup(data.dpiaQuestionnaireType),
              description: data.description || '',
              error: null,
              periodSelected: this.fillPeriod(data),
              processId: data.id,
              repeatSelected: data.reoccurring ? 'yes' : 'no',
              status: data.status,
              title: data.title,
              crtDPIATemplateId: data.crtDPIATemplateId,
              piaTemplateId: data.piaTemplateId,
              userSelected: data.assignedToUserID,
              teamSelected: data.assignedToTeamID,
              selectUserDisabled: !!data.assignedToTeamID,
              selectTeamDisabled: data.assignedToUserID && !data.assignedToTeamID
            }, () => {
              this.fetchTemplates()
            })
          }
        }
      }).catch((error) => {
        this.setState({
          ...this.state,
          error: error.response
        })
      })
  }

  fillPeriod(data) {
    const { frequencyCount } = data
    const frequency = {
      1: 'Monthly',
      3: '3 months',
      6: '6 months',
      12: '12 months'
    }

    return frequency[frequencyCount]
  }

  fetchUsersList() {
    apiUserFetchUsersListMinimized()
      .then((response) => {
        this.setState({
          usersList: response.data,
        })
      }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  updateValue(e, field) {
    let value
    switch (field) {
      case 'teamSelected' :
        value = e
        break
      case 'userSelected' :
        value = e
        break
      default:
        value = e.target.value
    }

    if (field === 'teamSelected') {
      if (value) {
        this.setState({
          selectUserDisabled: true
        })
      } else {
        this.setState({
          selectUserDisabled: false
        })
      }
    }
    if (field === 'userSelected') {
      if (value) {
        this.setState({
          selectTeamDisabled: true
        })
      } else {
        this.setState({
          selectTeamDisabled: false
        })
      }
    }
    this.setState({
      [field]: value
    })

    if (field === 'selectedBusinessProcess') {
      value = e.target.value
      this.setState({
        description: utilsBP.getDescription(value),
        title: value
      })
    } else {
      this.setState({
        selectedBusinessProcess: ''
      })
    }
  }

  selectedTemplate(templateId, field) {
    this.setState({
      ...templateSelected(templateId, field, this.state.templateVersions)
    })
  }

  changeQuestionGroupType(event) {
    const { value } = event.target
    this.selectQuestionGroup(value)
    const selectedGroup = this.state.questionGroupTypes.find((questionGroup) => (questionGroup.selected === true))

    if (selectedGroup.value !== value) {
      this.setState({ crtDPIATemplateId: '' })
    }
  }

  selectQuestionGroup(questionGroupSelected) {
    const questionGroupTypes = this.selectedQuestionGroup(questionGroupSelected)

    this.setState({ questionGroupTypes }, () => {
      if (!this.state.isEditMode) this.changeTemplates()
    })
  }

  selectedQuestionGroup(questionGroupSelected) {
    return this.state.questionGroupTypes.map((questionGroup) => {
      return {
        ...questionGroup,
        selected: questionGroup.value === questionGroupSelected
      }
    })
  }

  getName(user) {
    const name = user.user_metadata.name || user.name

    if (!name) return ''

    const [firstName, ] = name.split(' ')
    return firstName
  }

  saveChanges(e, isEditMode) {
    e.preventDefault()
    this.setState({
      isSending: true
    })

    const {
      description,
      title,
      userSelected,
      teamSelected,
      piaTemplateId,
      crtDPIATemplateId
    } = this.state

    const idToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId
    const selectedGroup = this.state.questionGroupTypes.filter((questionGroup) => (questionGroup.selected === true && questionGroup.value !== ''))[0]

    let data = {
      assignedToUserID: userSelected,
      assignedToTeamID: teamSelected,
      description,
      id_token: idToken,
      title,
      piaTemplateId,
      crtDPIATemplateId
    }

    if (selectedGroup) {
      data.dpiaQuestionnaireType = selectedGroup.value
    }

    let url = null
    if (isEditMode) {
      const processId = this.props.params.processId
      url = `/dpia-0.1.0/2.0/businessProcesses/${ processId }?action=UPDATE`
    } else {
      url = '/dpia-0.1.0/2.0/businessProcesses?action=CREATE'
    }
    const descriptionField = this.state.description
    data = this.includeFrequencyField(data)
    if (descriptionField === '' || descriptionField === null) {
      this.setState({
        isSending: false
      })
      CustomNotificationManager.error('The description is empty. Please complete the field', 'Error')
    } else {
      apiDpiaProcessSave(data, url)
        .then(() => {
          this.setState({
            NavigateToDashboard: true
          })
        }).catch((error) => {
          this.setState({
            isSending: false
          })
          const bpLimit = 'Subscription limit reached upgrade plan to create more'
          if (error.response) {
            if (error?.response?.status === 500) {
              if ((error.response?.data.error_description) && (error.response?.data?.error_description.indexOf(bpLimit) > -1)) {
                const errorMessage = (
                  <div>
                    { userIsSuperAdmin(this.props.user) ? (
                      <p>Subscription limit reached.</p>
                      // <p>Subscription limit reached. To create more BP
                      //   <a className="ant-notification-notice-description__link" href="/osp/user/my_plan"> please upgrade
                      //   your plan</a>
                      // </p>
                    ) : (
                      <p> { bpLimit }</p>
                    )
                    }
                  </div>
                )
                CustomNotificationManager.error(errorMessage, 'Error')
              }
            }else{
              console.log(error)
            }
          }
        })
    }

  }

  includeFrequencyField(data) {
    const { periodSelected, repeatSelected } = this.state

    if (repeatSelected === 'no') {
      return {
        ...data,
        reoccurring: false
      }
    }

    const frequency = {
      'Monthly': '1',
      '3 months': '3',
      '6 months': '6',
      '12 months': '12'
    }

    return {
      ...data,
      reoccurring: true,
      frequencyCount: frequency[periodSelected],
      reoccurringFrequency: 'monthly'
    }
  }

  renderOptions(options) {
    return (
      options.map((item, index) => (
        <option
          key={ index }
          value={ item.title }>{ item.title }
        </option>
      ))
    )
  }

  checkProcessesTemplates(items) {
    let response = false
    Object.keys(items).forEach(function(key) {
      if (items[key].length) {
        response = true
      }
    })
    return response
  }

  confirmedActionToArchive(processId) {
    const idToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId
    const data = {
      id_token: idToken,
      userId: this.props.user.id
    }

    this.setState({
      isSending: true
    })

    apiDpiaArchiveProcess(data, processId)
      .then(() => {
        this.setState({
          isSending: false,
          NavigateToArchiveDashboard: true
        })
      })
  }

  archiveProcessClicked(e) {
    e.preventDefault()
    swal.fire({
      text: 'This process will be archived so that your reports will still be accessible.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3DD8DB',
      cancelButtonColor: '#D33D33',
      confirmButtonText: 'Yes, archive it!'
    }).then((result) => {
      if (result.value) {
        this.confirmedActionToArchive(this.props.params.processId)
        CustomNotificationManager.success('Saved successfully', 'Success')
      } else {
        CustomNotificationManager.info('Action cancelled', 'Aborted')
      }
    })
  }

  radioClass({ selected }, isEditMode) {
    if (isEditMode && selected) {
      return 'dpiaNew__radio-label--checkedDisabled'
    } else if (!isEditMode && selected) {
      return 'dpiaNew__radio-label--checked'
    } else if (isEditMode && !selected) {
      return 'dpiaNew__radio-label--disabled'
    }

    return 'dpiaNew__radio-label'
  }

  radioClassTop({ selected }, isEditMode) {
    if (isEditMode && selected) {
      return 'dpiaNew__radio-wrapper checkedDisabled'
    } else if (!isEditMode && selected) {
      return 'dpiaNew__radio-wrapper checked'
    } else if (isEditMode && !selected) {
      return 'dpiaNew__radio-wrapper disabled'
    }

    return 'dpiaNew__radio-wrapper'
  }

  render() {
    const { messageArea, subscription } = this.props

    const {
      description,
      error,
      isEditMode,
      isSending,
      options,
      questionGroupTypes,
      NavigateToArchiveDashboard,
      NavigateToDashboard,
      repeatSelected,
      selectedBusinessProcess,
      status,
      title,
      userSelected,
      teamSelected,
      PIATemplates,
      DPIATemplates,
      PIATemplateSelected,
      DPIATemplateSelected,
    } = this.state
    let { teamsList, usersList } = { ...this.state }
    const items = utilsBP.processesTemplateItems
    this.checkProcessesTemplates(items)

    let myDisabled = true
    if ((selectedBusinessProcess !== '' || (title !== '')) && ((teamSelected) || (userSelected))) {
      myDisabled = false
    }
    
    //add default value to arrays
    teamsList = [...[{ id: '', name: '--Select--' }], ...teamsList]
    usersList = [...[{ label: '--Select--', value: '' }], ...usersList]
    return (
      <div className="dpiaNew-page">
        { NavigateToArchiveDashboard &&
        <Navigate to="/dpia/dashboard/archived"/>
        }

        { NavigateToDashboard &&
        <Navigate to="/dpia/dashboard"/>
        }

        {
          error &&
          <ErrorPage error={ error }/>
        }

        {
          !error &&
          <div className="dpiaNew-box">
            <form>
              { isEditMode ? (<PageTitle title="Edit Business Process"/>) : (
                <PageTitle title="Add new  Business Process"/>) }

              <div className="dpiaNew-btn-top">
                { !isSending &&
                <div className="pull-right">
                  { isEditMode &&
                  !messageArea.visible &&
                  <div className="dpiaNew-first-btn">
                    <Button
                      disabled={ status === 'INACTIVE' || (!userIsAdmin(this.props.user) && !userIsSuperAdmin(this.props.user)) }
                      onClick={ (e) => this.archiveProcessClicked(e) }>
                      Archive
                    </Button>
                  </div>

                  }
                  <div className="pull-right">
                    { !messageArea.visible &&
                    <input
                      disabled={ myDisabled || status === 'INACTIVE' || (!userIsAdmin(this.props.user) && !userIsSuperAdmin(this.props.user)) }
                      type="submit"
                      value="Save &amp; close"
                      onClick={ (e) => this.saveChanges(e, isEditMode) }/>
                    }

                    { messageArea.visible &&
                    <Message
                      hideMessageAction={ () => this.props.hideMessage() }
                      status={ messageArea.status }
                      text={ messageArea.text }/>
                    }
                  </div>
                </div>
                }
                { isSending &&
                <div className="pull-right mr20">
                  <Loading/>
                </div>
                }
                <div className="clearfix"/>

              </div>
              <div className="dpiaNew-section">
                <header>
                  <h1 className="dpiaNew-sectionTitle">Enter a Business Process title and description</h1>
                  <h2 className="dpiaNew-sectionSubtitle">This can help better classify your data privacy process for
                    sorting and filling</h2>
                </header>

                <div className="dpiaNew-formGroup">
                  <label htmlFor="process">Select from our pre-defined Business Processes</label>
                  <select
                    id="process"
                    disabled={ status === 'INACTIVE' }
                    value={ selectedBusinessProcess }
                    onChange={ (e) => this.updateValue(e, 'selectedBusinessProcess') }
                  >
                    <option value="">Select</option>
                    { Object.keys(items).map((group) => (
                      <optgroup key={ group } label={ group }>
                        { this.renderOptions(items[group]) }
                      </optgroup>
                    )) }
                  </select>
                </div>

                <p className="dpiaNew-sectionSubtitle">Or enter your own business process</p>

                <div className="dpiaNew-formGroup">
                  <label htmlFor="name">Name</label>
                  <input
                    id="name"
                    disabled={ status === 'INACTIVE' }
                    type="text"
                    value={ title }
                    onChange={ (e) => this.updateValue(e, 'title') }
                  />
                </div>

                <div className="dpiaNew-formGroup">
                  <label htmlFor="description">Description</label>
                  <textarea
                    id="description"
                    disabled={ status === 'INACTIVE' }
                    value={ description }
                    onChange={ (e) => this.updateValue(e, 'description') }
                    required
                  >
                  </textarea>
                </div>
              </div>

              <div className="dpiaNew-section">
                <div className="dpiaNew-formGroup">
                  { questionGroupTypes.map((group, index) => (
                    <div className="dpiaNew-radioGroup" key={ `questionGroup-${ index }` }>
                      <div className={ this.radioClassTop(group) }>
                        <input
                          checked={ group.selected }
                          id={ `questionGroup-${ index }` }
                          name="answer"
                          type="radio"
                          value={ group.value }
                          onClick={ this.changeQuestionGroupType }
                          onChange={ () => {
                          } }/>
                        <label
                          className={ this.radioClass(group, false) }
                          htmlFor={ `questionGroup-${ index }` }>
                        </label>
                      </div>
                      <div className="dpiaNew__radio-info">
                        <h4>{ group.title }</h4>
                        <div dangerouslySetInnerHTML={ { __html: group.description } }/>
                      </div>
                    </div>
                  )) }
                </div>
              </div>

              <div className="dpiaNew-section">
                <header>
                  <h1 className="dpiaNew-sectionTitle">Assign to Team or User</h1>
                  <h2 className="dpiaNew-sectionSubtitle">Assign this Business Process DPIA to a team or user</h2>
                </header>

                <div className="dpiaNew-sectionSideBySide">
                  <div className="dpiaNew-formGroupSideBySide">
                    <label htmlFor="piaTemplate">Choose a Team</label>
                    {
                      isEditMode &&
                      <Select
                        name={ teamSelected }
                        disabled={ this.state.selectTeamDisabled }
                        type="text"
                        value={ this.state.teamSelected || '' }
                        showSearch
                        optionFilterProp="name"
                        onChange={ (e) => this.updateValue(e, 'teamSelected') }
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {teamsList.map((team, key) => <Option value={team.id} key={key}>{team.name}</Option>)}
                      </Select>
                    }
                    { !isEditMode &&
                    <Select
                      name={ teamSelected }
                      disabled={ this.state.selectTeamDisabled }
                      type="text"
                      showSearch
                      optionFilterProp="name"
                      onChange={ (e) => this.updateValue(e, 'teamSelected') }
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {teamsList.map((team, key) => <Option value={team.id} key={key}>{team.name}</Option>)}
                    </Select>
                    }
                  </div>
                  <div className="dpiaNew-formGroupSideBySide">
                    <label htmlFor="piaTemplate">or select a specific User</label>

                    {
                      isEditMode &&
                      <Select
                        name={ userSelected }
                        value={ this.state.userSelected || '' }
                        disabled={ this.state.selectUserDisabled }
                        type="text"
                        onChange={ (e) => this.updateValue(e, 'userSelected') }
                        showSearch
                        optionFilterProp="label"
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {usersList.map((user, key) => <Option value={user.value} key={key}>{user.label}</Option>)}
                      </Select>
                    }
                    {
                      !isEditMode &&
                      <Select
                        name={ userSelected }
                        disabled={ this.state.selectUserDisabled }
                        type="text"
                        onChange={ (e) => this.updateValue(e, 'userSelected') }
                        showSearch
                        optionFilterProp="label"
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {usersList.map((team, key) => <Option value={team.value} key={key}>{team.label}</Option>)}
                      </Select>
                    }
                  </div>
                </div>
              </div>
              {
                subscription.unlimitedPlan &&
                <div className="dpiaNew-section">
                  <header>
                    <h1 className="dpiaNew-sectionTitle">Use Global Template(s)</h1>
                    <h2 className="dpiaNew-sectionSubtitle">Assign this Business Process to a pre-defined PIA and/or
                      DPIA template.</h2>
                  </header>
                  <div className="dpiaNew-sectionSideBySide">
                    <div className="dpiaNew-formGroupSideBySide">
                      <label htmlFor="piaTemplate">PIA Template</label>
                      <select
                        disabled={ PIATemplates.length === 0 }
                        id="piaTemplate"
                        value={ PIATemplateSelected }
                        onChange={ (e) => this.selectedTemplate(e.target.value, 'PIATemplateSelected') }>
                        { PIATemplates.length > 0 &&
                        PIATemplates.map((template) => (
                          <option key={ template.id } value={ template.id }>
                            { template.title }
                          </option>
                        ))
                        }
                      </select>
                    </div>
                    <div className="dpiaNew-formGroupSideBySide">
                      <label htmlFor="dpiaTemplate">DPIA Template</label>
                      <select
                        disabled={ DPIATemplates.length === 0 }
                        id="dpiaTemplate"
                        value={ DPIATemplateSelected }
                        onChange={ (e) => this.selectedTemplate(e.target.value, 'DPIATemplateSelected') }>
                        { DPIATemplates.length > 0 &&
                        DPIATemplates.map((template) => (
                          <option key={ template.id } value={ template.id }>
                            { template.title }
                          </option>
                        ))
                        }
                      </select>
                    </div>
                  </div>
                </div>
              }

              <div className="dpiaNew-section">
                <header>
                  <h1 className="dpiaNew-sectionTitle">Choose your reoccurring schedule</h1>
                  <h2 className="dpiaNew-sectionSubtitle">When does this DPIA start, end and does it repeat?</h2>
                </header>

                <div>
                  <p className="dpiaNew-text">Does it repeat?</p>
                  <div className="dpiaNew-radioGroup pull-left mr30">
                    { options.map((option) => (
                      <RepeatOption
                        key={ option }
                        item={ option }
                        repeatSelected={ this.state.repeatSelected }
                        updateValueForRepeat={ (value) => this.updateValue(value, 'repeatSelected') }
                      />
                    ))
                    }
                  </div>
                  <div className="pull-left">
                    <OptionNo/>
                  </div>
                  <div className="clearfix"/>
                  { repeatSelected === 'yes' &&
                  <OptionYes
                    periods={ this.state.periods }
                    periodSelected={ this.state.periodSelected }
                    updateValueForPeriod={ (value) => this.updateValue(value, 'periodSelected') }
                  />
                  }

                </div>
              </div>

              <footer className="dpiaNew-footer">
                { !isSending &&
                <div className="pull-right">
                  { isEditMode &&
                  !messageArea.visible &&
                  <div className="dpiaNew-first-btn">
                    <Button
                      disabled={ status === 'INACTIVE' || (!userIsAdmin(this.props.user) && !userIsSuperAdmin(this.props.user)) }
                      onClick={ (e) => this.archiveProcessClicked(e) }>
                      Archive
                    </Button>
                  </div>
                  }

                  <div className="pull-right">
                    { !messageArea.visible &&
                    <input
                      disabled={ myDisabled || status === 'INACTIVE' || (!userIsAdmin(this.props.user) && !userIsSuperAdmin(this.props.user)) }
                      type="submit"
                      value="Save &amp; close"
                      onClick={ (e) => this.saveChanges(e, isEditMode) }/>
                    }

                    { messageArea.visible &&
                    <Message
                      hideMessageAction={ () => this.props.hideMessage() }
                      status={ messageArea.status }
                      text={ messageArea.text }/>
                    }
                  </div>
                </div>
                }
                { isSending &&
                <div className="pull-right mr20">
                  <Loading/>
                </div>
                }
                <div className="clearfix"></div>
              </footer>
            </form>
          </div>
        }
      </div>
    )
  }
}
BusinessProcessForm = withRouter(BusinessProcessForm)
const stateMap = (state) => ({
  messageArea: state.messageAreaReducer.messageArea,
  subscription: state.subscriptionReducer.subscription,
  totalProcessCreated: state.subscriptionReducer.subscription.totalProcessCreated,
  user: state.userReducer.user,
  users: state.manageGroupsReducer.manageGroups.list
})

const dispatchMap = (dispatch) => ({
  fetchList: (token) => dispatch(manageGroupsActions.fetchList(token)),
  hideMessage: () => dispatch(actions.hideMessage()),
  showMessage: (status, text) => dispatch(actions.showMessage({ status, text })),
})

BusinessProcessForm.propTypes = {
  fetchList: PropTypes.func.isRequired,
  hideMessage: PropTypes.func.isRequired,
  messageArea: PropTypes.object.isRequired,
  subscription: PropTypes.object.isRequired,
  showMessage: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
}

export default compose(connect(stateMap, dispatchMap))(BusinessProcessForm)