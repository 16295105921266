import axios from 'axios'

export const apiPrivacyGetAlerts = (host) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/proxy/getCategoryChildren`,
    data: {
      sessionID: token,
      categoryID: '25'
    }
  })
}

export const apiPrivacyGetTotalFilesForAlertLevels = (host, alerts, taxonomy) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/proxy/getDataModelQuery`,
    data: {
      sessionID: token,
      datamodel: 'documents',
      query: '*:*', 
      fieldNames: ['doc_size'],
      filterQueries: [`${ taxonomy }: (${ alerts.join(' OR ') })`],
      offset: 0,
      length: 10,
      sortField: '',
      ascending: true
    }
  })
}

export const apiPrivacyAddAlert = (host, categoryID) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/proxy/addCategory`,
    data: {
      sessionID: token,
      parentID: categoryID,
      categoryName: 'New Alert'
    }
  })
}

export const apiPrivacyGetAlertUsingId = (host, id) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/proxy/getCategory`,
    data: {
      sessionID: token,
      categoryID: id
    }
  })
}