import React from 'react'
import { Navigate } from 'react-router-dom'

import Button from '../../Common/Button'
import Loading from '../../Common/Loading'

import { apiPrivacyAddCollection } from '../../../api/Privacy'
import WithCollections from '../HOC/WithCollections'

class ButtonAddCollection extends React.Component {
  constructor() {
    super()

    this.state = {
      isCreatingCollection: false,
      redirect: {
        value: false,
        id: null
      }
    }
  }

  newCollection = () => {
    this.setState({ isCreatingCollection: true })
    
    apiPrivacyAddCollection()
    .then((response) => {
      const collection = response.data.value.category
      const newCollections = [...this.props.collections, {
        ...collection,
        isFetchingInfo: true,
        isFetchingTotalAlerts: true,
        totalAlerts: { low: 0, medium: 0, high: 0 }
      }]
      this.props.updateCollections(newCollections)
      this.setState({
        redirect: {
          value: true,
          id: collection.id
        }
      })
    })
  }

  render = () => {
    return (
      <div>
        { this.state.redirect.value &&
          <Navigate to={ `/privacy/collection/edit/${ this.state.redirect.id }` } />
        }

        { this.state.isCreatingCollection
          ? <Loading />
          : <div className="privacyCollection__boxButton">
            <Button onClick={ this.newCollection }>New collection</Button>
          </div>
        }
      </div>
    )
  }
}

export default WithCollections(ButtonAddCollection)