import React from 'react'
import ManageAssetRegister from './components/MannageAssetRegister'
import './Settings.scss'
import Register from './components/Register'
import { connect } from 'react-redux'
import { deleteRegister, getAllRegistersByClientId } from '../../../api/AssetRegister'
import { Skeleton } from 'antd'
class Settings extends React.Component {
  state = {
    isImportExportActive: false,
    registers: [],
    registerToEdit: {},
    addRegisterOpened: false,
    loadingRegisters: true
  }

  componentDidMount() {
    if (this.props.clientId) {
      this.fetchData()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.clientId !== this.props.clientId) {
      this.fetchData()
    }
  }

  fetchData = () => {
    this.setState({
      loadingRegisters: true
    }, () => {
      getAllRegistersByClientId(this.props.clientId).then((res) => {
        this.setState({
          registers: res.data,
          loadingRegisters: false,
          registerToEdit: {}
        })
      })
    })
  }

  editRegister = (register) => {
    this.setState({
      registerToEdit: register,
      addRegisterOpened: true
    })
  }

  deleteRegister = (register) => {
    deleteRegister(register.id).then((res) => {
      if (res) {
        this.setState({
          registers: this.state.registers.filter((reg) => reg.id !== register.id)
        })
      }
    })
  }

  setImportExportActive = (value) => {
    this.setState({
      isImportExportActive: value,
      registerToEdit: {}
    })
  }

  setRegisterOpenedToFalse = () => {
    this.setState({
      addRegisterOpened: false,
      registerToEdit: {}
    })
  }

  render() {
    const { isImportExportActive, registers, registerToEdit, addRegisterOpened, loadingRegisters } = this.state
    return (
      <div className="settings-page">
        <ManageAssetRegister
          setImportExportActive={ this.setImportExportActive }
          fetchData={ this.fetchData }
          registerToEdit={ registerToEdit }
          addRegisterOpened={ addRegisterOpened }
          setRegisterOpenedToFalse={ this.setRegisterOpenedToFalse }
        />
        {
          loadingRegisters ?
            <Skeleton active />
            :
            registers && registers.map((register, key) =>
              <Register editRegister={ this.editRegister }
                deleteRegister={ this.deleteRegister }
                register={ register } key={ key }
                isImportExportActive={ isImportExportActive }/>)
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id
})

export default connect(mapStateToProps)(Settings)