export const newNavigationObject = [
    {
        key: 'smart-box-main',
        title: 'Smartbox.ai',
        url: '/smart-box/management',
        applicationpermission: 'isSmartBoxVisible',
    },
    {
      key: 'workflow-app',
      title: 'Workflow APP',
      url: '#',
      // redux workflow visible
      applicationpermission: 'isWorkflowAppVisible',
      children: [
        {
          key: 'dashboard-workflow',
          title: 'Dashboard',
          url: '/sar/workflow/workflow-dashboard',
          parentkey: 'sar',
          permissions: 'all'
        },
        {
          key: 'workflow-archive',
          title: 'Archive',
          url: '/sar/workflow/workflow-archive',
          parentkey: 'sar',
          permissions: 'all'
        },
        {
          key: 'workflow-templates',
          title: 'Templates',
          url: '/sar/workflow/templates',
          parentkey: 'sar',
          permissions: 'all',
            children: [
                {
                    key: 'forms-workflow',
                    title: 'Forms',
                    url: '/sar/workflow/forms',
                    parentkey: 'workflow-templates',
                    permissions: 'all',
                    lastsubmenu: 'true',
                },
                {
                    key: 'email-templates',
                    title: 'Emails',
                    url: '/sar/workflow/templates',
                    parentkey: 'workflow-templates',
                    permissions: 'all',
                    lastsubmenu: 'true',
                },
                // {
                //     key: 'builder-workflow',
                //     title: 'Workflows builder',
                //     url: '/sar/workflow/builder',
                //     parentkey: 'workflow-templates',
                //     permissions: 'all',
                //     lastsubmenu: 'true',
                // },
                {
                    key: 'template-workflows',
                    title: 'Workflows',
                    url: '/sar/workflow/template-workflow',
                    parentkey: 'workflow-templates',
                    permissions: 'all',
                    lastsubmenu: 'true',
                },
            ]
        },
        // {
        //     key: 'data-source',
        //     title: 'Data source',
        //     url: '/sar/workflow/data-source',
        //     parentkey: 'sar',
        //     permissions: 'all',
        //     children: [
        //         {
        //             key: 'source-ropa',
        //             title: 'ROPA',
        //             url: '/sar/workflow/data-source',
        //             parentkey: 'data-source',
        //             permissions: 'all',
        //             lastsubmenu: 'true',
        //         },
        //     ]
        // },
      ]
    },
    {
      key: 'data-mapping-app',
      title: 'Data sources',
      url: '#',
      applicationpermission: 'isDataMappingAppVisible',
      children: [
        {
            key: 'business-processes',
            title: 'Business processes',
            url: '/data-mapping/business-process',
            parentkey: 'sar',
            permissions: [
                {
                    application: 'osprey',
                    permission: 'admin'
                },
                {
                    application: 'osprey',
                    permission: 'super_admin'
                },
            ],
        },
        {
          key: 'data-sources',
          title: 'Source mapping',
          url: '/data-mapping/source-mapping',
          parentkey: 'sar',
            permissions: [
                {
                    application: 'osprey',
                    permission: 'admin'
                },
                {
                    application: 'osprey',
                    permission: 'super_admin'
                },
            ],
        },
        {
          key: 'connectors',
          title: 'Connectors',
          url: '/data-mapping/connectors',
          parentkey: 'sar',
          permissions: [
              {
                  application: 'osprey',
                  permission: 'super_admin'
              },
              {
                  application: 'osprey',
                  permission: 'admin'
              },

          ]
        },
          {
              key: 'apiConnector',
              title: 'Access tokens',
              url: '/data-mapping/api-connector',
              parentkey: 'sar',
              permissions: [
                  {
                      application: 'osprey',
                      permission: 'super_admin'
                  },

              ]
          },
      ]
    },
    {
      key: 'sar',
      title: 'Request Tracker',
      url: '/sar/dashboard',
      applicationpermission: 'isSarVisible',
      children: [
        {
            key: 'sar-dashboard',
            title: 'Dashboard',
            url: '/sar/dashboard',
            parentkey: 'sar',
            permissions: 'all'
        },
        {
            key: 'sar-new-tracker',
            title: 'New Request',
            url: '/sar/tracker-page',
            parentkey: 'sar',
            permissions: 'all'
        },
        {
          key: 'sar-archive',
          title: 'Archive',
          url: '/sar/archive',
          permissions: 'all',
          // permissions: [
          //     {
          //         application: 'osprey',
          //         permission: 'admin'
          //     },
          //     {
          //         application: 'osprey',
          //         permission: 'super_admin'
          //     },
          // ],
        }
      ]
    },
    {
        key: 'home',
        title: 'User management',
        url: '/osp/invitations',
        applicationpermission: 'home',
        permissions: [
            {
                application: 'osprey',
                permission: 'admin'
            },
            {
                application: 'osprey',
                permission: 'super_admin'
            },
        ],
        children: [
            {
                key: 'home-invite-user',
                title: 'Invite User',
                url: '/osp/invitations',
                permissions: [
                    {
                        application: 'osprey',
                        permission: 'admin'
                    },
                    {
                        application: 'osprey',
                        permission: 'super_admin'
                    },
                ],
            },
            {
                key: 'home-invite-tenant',
                title: 'Create Tenant',
                url: '/osp/multitenancy-invitations',
                applicationpermission: 'isAdditionalEnvironmentsVisible',
                permissions: [
                  {
                    application: 'osprey',
                    permission: 'admin'
                  },
                  {
                    application: 'osprey',
                    permission: 'super_admin'
                  }
                  // OLD PERMISSION AND CONFIGURATION FOR Invite Tenant WAS: 
                  // {
                  //   application: 'multitenancy',
                  //   permission: 'admin'
                  // },
                  //  -------------
                ],
            },
            {
                key: 'home-manage-users',
                title: 'Manage Users',
                url: '/osp/manage/users',
                permissions: [
                    {
                        application: 'osprey',
                        permission: 'admin'
                    },
                    {
                        application: 'osprey',
                        permission: 'super_admin'
                    },
                ],
            },
            
        ]
    },
    {
      key: 'assess',
      title: 'Assessments',
      url: '/dpia/dashboard',
      applicationpermission: 'isDpiaVisible',
      children: [
        {
          key: 'assess-dashboard',
          title: 'Dashboard',
          url: '/dpia/dashboard',
          parentkey: 'assess',
          permissions: 'all'
        },
        {
          key: 'assess-new-process',
          title: 'New Process',
          url: '/dpia/new',
          parentkey: 'assess',
          permissions: [
            {
              application: 'dpia',
              permission: 'admin'
            },
            {
              application: 'dpia',
              permission: 'super_admin'
            },
          ]
        },
        {
          key: 'assess-pia-assess-builder',
          title: 'PIA/Assess Builder',
          url: '/dpia/questionnaires',
          parentkey: 'assess',
          permissions: [
            {
              application: 'dpia',
              permission: 'admin'
            },
            {
              application: 'dpia',
              permission: 'super_admin'
            },
          ]
        },
        {
          key: 'assess-about-assess',
          title: 'About Assess',
          url: '/dpia/about',
          parentkey: 'assess',
          permissions: 'all'
        },
        {
          key: 'assess-help-support',
          title: 'Help & Support',
          url: '/dpia/help',
          parentkey: 'assess',
          permissions: 'all'
        },
        {
          key: 'assess-archive',
          title: 'Archive',
          url: '/dpia/dashboard/archive',
          parentkey: 'assess',
          permissions: 'all'
        }
      ]
    },
    {
        key: 'main-settings',
        title: 'Settings',
        url: '/company/settings',
        applicationpermission: 'home',
        children: [
          {
            key: 'smart-box-company-settings',
            title: 'My company',
            url: '/company/settings',
            parentkey: 'main-settings',
              //permissions: 'all',
            permissions: [
              // {
              //   application: 'osprey',
              //   permission: 'admin'
              // },
              {
                application: 'osprey',
                permission: 'super_admin'
              },
            ]
          },
          {
            key: 'asset-register-settings',
            title: 'Asset Register',
            url: '/asset-register/settings',
            applicationpermission: 'isAssetRegisterVisible',
            parentkey: 'main-settings',
            permissions: 'all'
          },
          {
            key: 'doc-locker-settings',
            title: 'Doc Locker',
            url: '/doc-locker/settings',
            applicationpermission: 'isDocLockerVisible',
            parentkey: 'main-settings',
            permissions: 'all'
          },
          {
            key: 'smart-box',
            title: 'Smartbox.ai',
            url: '/sar/settings/annotation-management',
            applicationpermission: 'isSarVisible',
            parentkey: 'main-settings',
            permissions: 'all',
            children: [
              {
                key: 'sar-annotation-management',
                title: 'Annotations',
                url: '/sar/settings/annotation-management',
                parentkey: 'smart-box',
                lastsubmenu: 'true',
                permissions: [
                  {
                    application: 'osprey',
                    permission: 'admin'
                  },
                  {
                    application: 'osprey',
                    permission: 'super_admin'
                  },
                ]
              },
                {
                    key: 'smart-box-tags',
                    title: 'Document Tags',
                    url: '/sar/settings/tags',
                    parentkey: 'smart-box',
                    permissions: [
                        {
                            application: 'osprey',
                            permission: 'super_admin'
                        },
                    ],
                    lastsubmenu: 'true',
                },
              {
                key: 'smart-box-reg-ex',
                title: 'Regular Expressions',
                url: '/smart-box/regular-expressions/settings',
                parentkey: 'smart-box',
                permissions: 'all',
                lastsubmenu: 'true',
              },
              {
                key: 'smart-box-business-term-lists',
                title: 'Dictionaries',
                url: '/smart-box/business-term-lists/settings',
                parentkey: 'smart-box',
                permissions: 'all',
                lastsubmenu: 'true',
              },
              {
                key: 'sar-email-templates',
                title: 'Email Templates',
                url: '/sar/settings/template-email',
                parentkey: 'smart-box',
                lastsubmenu: 'true',
                permissions: [
                  {
                    application: 'osprey',
                    permission: 'admin'
                  },
                  {
                    application: 'osprey',
                    permission: 'super_admin'
                  },
                ]
              },
            ]
          }
        ]
  },
  {
    key: 'asset-register',
    title: 'Asset Register',
    url: '/asset-register/management',
    applicationpermission: 'isAssetRegisterVisible',
    children: [ 
      {
        key: 'asset-register-asset-register',
        title: 'Management',
        url: '/asset-register/management',
        parentkey: 'asset-register',
        permissions: 'all'
      }
    ]
  }
]


//PERMISION TYPES

// UNUSED ROUTES

// {
//     key: 'home-manage-teams',
//     title: 'Manage Teams',
//     url: '/osp/manage/teams',
//     applicationpermission: 'home',
//     permissions: [
//         {
//             application: 'osprey',
//             permission: 'admin'
//         },
//         {
//             application: 'osprey',
//             permission: 'super_admin'
//         },
//     ],
//     children: []
// },
