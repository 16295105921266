import React from 'react'
import {Button, Select, Modal, Table, Input, DatePicker} from 'antd'
import { apiFetchClients, apiGlobalResetMfa } from '../../../api/Admin'
import '../Admin.scss'
import './TokenManagement.scss'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import swal from 'sweetalert2'
import { emailRegex, getErrorMessage } from '../../../utils'
import CustomNotificationManager from "../../Common/CustomNotificationManager";
import Switcher from "../../Common/Switcher";
import {
    apiDeleteToken, apiEditToken,
    apiExternalCreateToken, apiExternalDeleteToken,
    apiExternalEditToken,
    apiExternalGetAvailableToken,
    apiExternalGetTenantList, apiGetTokenApiList, apiGiveTokenAccessToApi
} from "../../../api/SmartBox";
import moment from "moment";
import {customConfirm} from "../../../utils/AssetRegister/CustomConfirm";

const Option = Select.Option
const { Column } = Table

class AddEditTokenManagement extends React.Component {
    state = {
        formToken: {
          inputEmail: '',
          selectOption: '',
            selectTenant: '',
            valueAllowAccess: true,
            valueExpirationDate: '',
            key: 1
        },
        optionSelect: [
            {name: 'option1'},
            {name: 'option2'},
        ],
        listTenant: [],
        hoverEdit: {},
        hoverAdd: false,
        visibleModalToken: false,
        formTokenModal: {
            selectOption: '',
            valueHandleModal: true,
            inputModal: ''
        },
        listAvailableToken: [],
        getTokenList: [],
        hoverDelete: {},
        selectEditToken: {},
        statusSaveModal: null,
        formTokenModalEdit: {},
    }
    componentDidMount() {
        if (this.props.statusEditableToken === 'edit' && Object.keys(this.props.selectManagementToken) && Object.keys(this.props.selectManagementToken).length > 0) {
            this.setState({
                formToken: {...this.state.formToken, inputEmail: this.props.selectManagementToken.email,
                    valueExpirationDate: this.props.selectManagementToken.expirationDate,
                    selectTenant: this.props.selectManagementToken.tenant,
                    valueAllowAccess: this.props.selectManagementToken.tokenIsActive,
                    tokenKey: this.props.selectManagementToken.tokenKey,
                    tenantID: this.props.selectManagementToken.tenantID,
                    key: this.props.selectManagementToken.key  }
            }, () => {
                this.onGetTokenApiList(this.props.selectManagementToken.tokenKey)
            })
        }

        this.onGetExternalGetTenantList()
        this.onGetExternalGetAvailableToken()
    }

    onGetTokenApiList = (tokenKey) => {
        apiGetTokenApiList(tokenKey).then((response) => {
            this.setState({
                getTokenList: response.data
            })
        })
    }
    onGetExternalGetAvailableToken = () => {
        apiExternalGetAvailableToken().then((response) => {
            this.setState({
                listAvailableToken: response.data
            })
        })
    }
    onGetExternalGetTenantList = () => {
        apiExternalGetTenantList().then((response) => {
            this.setState({
                listTenant: response.data
            })
        })
    }
    onBackPageTableToken = () => {
        this.props.onBackTableToken(false)
    }
    onInputEmailToken = (e, status) => {
        this.setState({
            formToken: {...this.state.formToken, inputEmail: e.target.value}
        })
    }
    onSelectOption = (e) => {
        this.setState({
            formToken: {...this.state.formToken, selectOption: e}
        })
    }
    onSelectTenant = (e) => {
        this.setState({
            formToken: {...this.state.formToken, selectTenant: e}
        })
    }
    onInputAllToken = (e, status) => {
        switch (status) {
            // case 'one':
            //     this.setState({
            //         formToken: {...this.state.formToken, inputTokenOne: e.target.value}
            //     })
            //     break
            case 'two':
                this.setState({
                    formToken: {...this.state.formToken, inputTokenTwo: e.target.value}
                })
                break
            case 'three':
                this.setState({
                    formToken: {...this.state.formToken, inputTokenThree: e.target.value}
                })
                break
            case 'four':
                this.setState({
                    formToken: {...this.state.formToken, inputTokenFour: e.target.value}
                })
                break
            default: break
        }
    }
    isFormValid() {
        const { formToken } = this.state

        // Validates if email has a valid format.
        if (!emailRegex.test(formToken.inputEmail)) {
            CustomNotificationManager.info('Your email does not have a valid format.', 'Info')

            return false
        }

        return true
    }
    onSaveEditToken = () => {
        const { formToken } = this.state
        if (!this.isFormValid()) return
        if (this.props.statusEditableToken === 'add') {
            const createExternalTokenDTO = {
                email: formToken.inputEmail,
                tokenIsActive: formToken.valueAllowAccess,
                tokenExpirationDate: formToken.valueExpirationDate,
                clientId: formToken.selectTenant,
            }
            apiExternalCreateToken(createExternalTokenDTO).then((response) => {
                if (response.data.title && response.data.title.toLowerCase() === 'success') {
                    CustomNotificationManager.success(response.data.message, 'Success')
                } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
                    CustomNotificationManager.info(response.data.message, 'Info')
                }
            })
        } else {
            const editExternalTokenDTO = {
                tokenKey: formToken.tokenKey,
                tokenIsActive: formToken.valueAllowAccess,
                tokenExpirationDate: formToken.valueExpirationDate,
                clientId: formToken.tenantID,
            }
            apiExternalEditToken(editExternalTokenDTO).then((response) => {
                if (response.data.title && response.data.title.toLowerCase() === 'success') {
                    CustomNotificationManager.success(response.data.message, 'Success')
                } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
                    CustomNotificationManager.info(response.data.message, 'Info')
                }
            })
        }
        this.props.onGetSaveEditToken(formToken)
    }
    onCancelActionToken = () => {
         this.props.onGetCancelToken()
    }
    handleChangeActiveAllowAccess(status) {
        this.setState({
            formToken: {...this.state.formToken, valueAllowAccess: status}
        })
    }
    onChangeExpirationDate = (date, dateString) => {
        //console.log(date, dateString);
        this.setState({
            formToken: {...this.state.formToken, valueExpirationDate: dateString}
        })
    }
    onHoverAddToken = (e, status, item) => {
        switch (status) {
            case 'add':
                this.setState({
                    hoverAdd: true
                })
                break
            case 'edit':
                this.setState({
                    hoverEdit: item
                })
                break
            case 'delete':
                this.setState({
                    hoverDelete: item
                })
                break
            default: break
        }
    }
    onLeaveAddToken = () => {
        this.setState({
            hoverAdd:false,
            hoverEdit:false,
            hoverDelete: {},
        })
    }
    onEditToken = (e, status, record) => {
      this.setState({
          visibleModalToken: true,
          selectEditToken: {},
          statusSaveModal: 'edit',
          formTokenModalEdit: record,
          formTokenModal: {...this.state.formTokenModal, selectOption: record.apiCode, valueHandleModal: record.allowAccess, inputModal: record.nrOfQueriesAllowed,
              apitokenId: record.apitokenId, apiCode: record.apiCode}
      })
    }
    onCancelModal = () => {
        this.setState({
            visibleModalToken: false,
            formTokenModal: {
                selectOption: '',
                valueHandleModal: true,
                inputModal: ''
            }
        })
    }
    onAddToken = (e, status, record) => {
        this.setState({
            visibleModalToken: true,
            statusSaveModal: 'add',
        })
    }

    onSaveToken = (e, status, record) => {
        const { formTokenModal, statusSaveModal, formTokenModalEdit } = this.state
        const { selectManagementToken } = this.props
        if (statusSaveModal === 'add') {
            const createTokenAPIiPermissionDTO = {
                apiCode: formTokenModal.selectOption,
                allowAccess: formTokenModal.valueHandleModal,
                nrOfQueriesAllowed: formTokenModal.inputModal,
                tokenKey: selectManagementToken.tokenKey,
            }
            apiGiveTokenAccessToApi(createTokenAPIiPermissionDTO).then((response) => {
                if (response.data.title && response.data.title.toLowerCase() === 'success') {
                    CustomNotificationManager.success(response.data.message, 'Success')
                } else if (response.data.title && response.data.title.toLowerCase() === 'info') {
                    CustomNotificationManager.info(response.data.message, 'Info')
                }
                this.setState({
                    visibleModalToken: false
                })
                this.onGetTokenApiList(this.props.selectManagementToken.tokenKey)
            })
        } else if (statusSaveModal === 'edit') {
            const editTokenAPIiPermissionDTO = {
                apiCode: formTokenModal.selectOption,
                allowAccess: formTokenModal.valueHandleModal,
                nrOfQueriesAllowed: formTokenModal.inputModal,
                apiTokenId: formTokenModal.apitokenId,
            }
            apiEditToken(editTokenAPIiPermissionDTO).then((response) => {
                if (response.data.title && response.data.title.toLowerCase() === 'success') {
                    CustomNotificationManager.success(response.data.message, 'Success')
                } else if (response.data.title && response.data.title.toLowerCase() === 'info') {
                    CustomNotificationManager.info(response.data.message, 'Info')
                }
                this.setState({
                    visibleModalToken: false,
                })
                this.onGetTokenApiList(this.props.selectManagementToken.tokenKey)
            })
        }
    }
    onSelectOptionsModal = (e) => {
        this.setState({
            formTokenModal: {...this.state.formTokenModal, selectOption: e}
        })
    }
    handleAccessModal(status) {
        this.setState({
            formTokenModal: {...this.state.formTokenModal, valueHandleModal: status}
        })
    }
    onInputModal = (e) => {
        const value = e.target.value;
        const reg = /^-?[0-9]*(\.[0-9]*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
            this.setState({
                formTokenModal: {...this.state.formTokenModal, inputModal: e.target.value}
            })
        }
    }
    onActionDeleteTokenAccess = (e, status, record) => {
        customConfirm({
            title: <span className="icon-v14-delete"></span>,
            content: `Delete token?`,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            mask: true,
            maskClosable: false,
            closeHaveLogic: false,
            className: 'asset-register-modal modal-delete-connector',
            centered: true,
            cancelClassName: 'cancel-button',
            confirmClassName: 'confirm-button',
            confirmAction: () => {
                this.onDeleteToken(e, status, record)
            }
        })
    }
    onDeleteToken = (e, status, record) => {
        let tokenAPIIds = []
        tokenAPIIds.push(record.apitokenId)
        apiDeleteToken(tokenAPIIds).then((response) => {
            if (response.data.title && response.data.title.toLowerCase() === 'success') {
                CustomNotificationManager.success(response.data.message, 'Success')
            } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
                CustomNotificationManager.info(response.data.message, 'Info')
            }
            this.onGetTokenApiList(this.props.selectManagementToken.tokenKey)
        })
    }
    render() {
        const { formToken, optionSelect, listTenant, hoverEdit, visibleModalToken, hoverAdd, formTokenModal, listAvailableToken, getTokenList,
            hoverDelete } = this.state
        const { tokenListTable, selectManagementToken } = this.props
        return (
            <div className="token-management-editable">
             <div className="header-editable">
              <span className="icon-v17-back-1" onClick={() => this.onBackPageTableToken()}></span>
                 <div className="action-header-token">
                     <Button className="button-cancel-token"
                     onClick={(e) => this.onCancelActionToken(e)}>Cancel</Button>
                         <Button className="button-save-token"
                                 disabled={formToken.selectTenant === '' || formToken.valueExpirationDate === ''}
                                 onClick={(e) => this.onSaveEditToken(e)}
                         >Save</Button>
                 </div>
             </div>
                <div className="content-editable">
                    <Input className="input-email"
                           readOnly={this.props.statusEditableToken === 'edit' ? true : false}
                           value={formToken.inputEmail ? formToken.inputEmail : ''}
                           placeholder="Email"
                    onChange={(e) => this.onInputEmailToken(e, 'email')}/>
                    {/*<Select className="select-token"*/}
                    {/*        placeholder="Select token"*/}
                    {/*        onChange={(e) => this.onSelectOption(e)}*/}
                    {/*        dropdownStyle={{border:'1px solid #eda41e'}}>*/}
                    {/*    { optionSelect && optionSelect.length > 0 && optionSelect.map((item, index) => {*/}
                    {/*        return (*/}
                    {/*            <Option key={ index } value={item.name}>{item.name}</Option>*/}
                    {/*        )*/}
                    {/*    })*/}

                    {/*    }*/}
                    {/*</Select>*/}
                     <div className="content-allow-access">
                         <span className="title-allow-access">Allow access</span>
                         <Switcher
                             active={ formToken.valueAllowAccess }
                             updateActive={ () => this.handleChangeActiveAllowAccess(!formToken.valueAllowAccess)
                             }
                         />
                     </div>
                    <div className="content-expiration-date">
                        <span className="title-expiration-date">Expiration date</span>
                        <DatePicker
                            placeholder={ formToken.valueExpirationDate ? moment(formToken.valueExpirationDate).format('YYYY-MM-DD HH:mm') : 'select date'}
                            format="YYYY-MM-DD"
                            onChange={this.onChangeExpirationDate} />
                    </div>
                    <Select className="select-token"
                            placeholder="Select tenant"
                            onChange={(e) => this.onSelectTenant(e)}
                            value={ formToken.selectTenant ? formToken.selectTenant : 'select tenant'}
                            dropdownStyle={{border:'1px solid #eda41e'}}>
                        { listTenant && listTenant.length > 0 && listTenant.map((item, index) => {
                            return (
                                <Option key={ index } value={item.clientId}>{item.domain}</Option>
                            )
                        })

                        }
                    </Select>
                    {/*<Input className="input-email"*/}
                    {/*       onChange={(e) => this.onInputAllToken(e, 'one')}*/}
                    {/*       value={formToken.inputTokenOne ? formToken.inputTokenOne : ''}*/}
                    {/*       placeholder="TEXT 1"/>*/}
                    {/*<Input className="input-email"*/}
                    {/*       onChange={(e) => this.onInputAllToken(e, 'two')}*/}
                    {/*       value={formToken.inputTokenTwo ? formToken.inputTokenTwo : ''}*/}
                    {/*       placeholder="TEXT 2"/>*/}
                    {/*<Input className="input-email"*/}
                    {/*       onChange={(e) => this.onInputAllToken(e, 'three')}*/}
                    {/*       value={formToken.inputTokenThree ? formToken.inputTokenThree : ''}*/}
                    {/*       placeholder="TEXT 3"/>*/}
                    {/*<Input className="input-email"*/}
                    {/*       onChange={(e) => this.onInputAllToken(e, 'four')}*/}
                    {/*       value={formToken.inputTokenFour ? formToken.inputTokenFour : ''}*/}
                    {/*       placeholder="TEXT 4"/>*/}
                </div>
                {
                    this.props.statusEditableToken === 'edit' &&
                    <div className="header-token">
                       <span className={hoverAdd ? "icon-v17-add-active" : "icon-v17-add"}
                             onMouseEnter={(e) => this.onHoverAddToken(e, 'add')}
                             onMouseLeave={() => this.onLeaveAddToken()}
                             onClick={(e) => this.onAddToken(e, 'add')}></span>
                    </div>
                }
                {
                    this.props.statusEditableToken === 'edit' &&
                        <div className="content-edit-table">
                            <Table
                                dataSource={getTokenList}
                                pagination={false}
                                className="custom-table new-custom-table">
                                <Column title='API'
                                        dataIndex="apiname" key="apiname" render={(text, record) => {
                                    return (<span> {text} </span>)
                                }}/>
                                <Column title='Active'
                                        dataIndex="allowAccess" key="allowAccess" render={(text, record) => {
                                    return (<span> {text ? 'true' : 'false'}</span>)
                                }}/>
                                <Column title='Current Token Quotas'
                                        dataIndex="nrOfQueriesScheduled" key="nrOfQueriesScheduled" render={(text, record) => {
                                    return (<span> {text}</span>)
                                }}/>
                                <Column title='Token Quotas'
                                        dataIndex="nrOfQueriesAllowed" key="nrOfQueriesAllowed" render={(text, record) => {
                                    return (<span> {text}</span>)
                                }}/>
                                <Column title='Access Granted By'
                                        dataIndex="userAccessApprove" key="userAccessApprove" render={(text, record) => {
                                    return (<span> {text}</span>)
                                }}/>
                                <Column title='Access Revoked By'
                                        dataIndex="userAccessRevoke" key="userAccessRevoke" render={(text, record) => {
                                    return (<span> {text}</span>)
                                }}/>
                                <Column title='Access Granted Date'
                                        dataIndex="accessApprovedDate" key="accessApprovedDate" render={(text, record) => {
                                    return (<span> {text}</span>)
                                }}/>
                                <Column title='Access Revoked Date'
                                        dataIndex="accessRevokedDate" key="accessRevokedDate" render={(text, record) => {
                                    return (<span> {text}</span>)
                                }}/>
                                <Column title='action'
                                        dataIndex="edit" key="edit" render={(text, record) => {
                                    return (<span className={ hoverEdit.apitokenId === record.apitokenId ? "icon-v21-edit-active" : 'icon-v21-edit'}
                                                  onMouseEnter={(e) => this.onHoverAddToken(e, 'edit', record)}
                                                  onMouseLeave={() => this.onLeaveAddToken()}
                                                  onClick={(e) => this.onEditToken(e, 'edit', record)}></span>)
                                }}/>
                                <Column title='action'
                                        dataIndex="delete" key="delete" render={(text, record) => {
                                    return (<span className={ hoverDelete.apitokenId === record.apitokenId ? "icon-v19-delete-active" : 'icon-v19-delete'}
                                                  onMouseEnter={(e) => this.onHoverAddToken(e, 'delete', record)}
                                                  onMouseLeave={() => this.onLeaveAddToken()}
                                                  onClick={(e) => this.onActionDeleteTokenAccess(e, 'delete', record)}></span>)
                                }}/>
                            </Table>
                        </div>
                }
                <Modal visible={ visibleModalToken } title="Management API Access Tokens" className="modal-token" footer={ false } onCancel={ this.onCancelModal }>
                    <div className="content-editable-modal">
                        <div className="content-option-modal-token">
                        <span className="title-option">Allow Token Access to:</span>
                        <Select className="select-token"
                                placeholder="Select tenant"
                                onChange={(e) => this.onSelectOptionsModal(e)}
                                value={ formTokenModal.selectOption ? formTokenModal.selectOption : 'Select'}
                                dropdownStyle={{border:'1px solid #eda41e'}}>
                            { listAvailableToken && listAvailableToken.length > 0 && listAvailableToken.map((item, index) => {
                                return (
                                    <Option key={ index } value={item.apicode}>{item.apiname}</Option>
                                )
                            })

                            }
                        </Select>
                        </div>
                        <div className="content-allow-access">
                            <span className="title-allow-access">Enable/Disable API Access</span>
                            <Switcher
                                active={ formTokenModal.valueHandleModal }
                                updateActive={ () => this.handleAccessModal(!formTokenModal.valueHandleModal)
                                }
                            />
                        </div>
                        <div className="content-option-modal-token">
                        <span className="title-option">API Token quotas ( 0 = infinite queries number )</span>
                        <Input className="input-email"
                               //readOnly={this.props.statusEditableToken === 'edit' ? true : false}
                               value={formTokenModal.inputModal ? formTokenModal.inputModal : ''}
                               placeholder="Input"
                               onChange={(e) => this.onInputModal(e)}/>
                        </div>
                        <div className="content-action-token">
                            <Button className="action-cancel" onClick={(e) => this.onCancelModal(e) }>Cancel</Button>
                            <Button className="action-save" onClick={(e) => this.onSaveToken(e, 'edit')}>Save</Button>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

const stateMap = (state) => ({
    user: state.userReducer.user
})

AddEditTokenManagement.propTypes = {
    user: PropTypes.object.isRequired
}

export default connect(stateMap)(AddEditTokenManagement)