import React from 'react'
import { Select } from 'antd'
import PropTypes from 'prop-types'
import { getAllColumnNames } from '../../../../api/DocLocker';

const { Option } = Select

class FindLibrary extends React.Component {
  state = {
    data: {
      searchedColumn: '',
      searchedValue: ''
    },
    buttonDisabled: true
  }

  componentDidMount() {
    getAllColumnNames(this.props.clientId).then((res) => {
      if (res.data) {
        this.setState({
          columnNames: res.data
        })
      }
    })
  }

  onChange = (key, value) => {
    this.setState({
      data: {
        ...this.state.data, [key]: value
      }
    }, () => this.validateFields())
  }
  
  validateFields = () => {
    const { data } = this.state
    if (data.searchedValue && data.searchedColumn) {
      this.setState({
        buttonDisabled: false
      })
    } else {
      this.setState({
        buttonDisabled: true
      })
    }
  }
  
  render() {
    const { buttonDisabled, data, columnNames } = this.state
    return (
      <div className="find-compliance-document">
        <div className="find-compliance-document__search-section">
          <div className="find-compliance-document__search-section__select-section">
            <Select placeholder="Search By..." onChange={(value) => this.onChange('searchedColumn', value)}
              className="custom-select">
              {
                columnNames && columnNames.length > 0 && columnNames.map((columnName, key) => <Option value={columnName} key={key}>{columnName}</Option>)
              }
            </Select>
          </div>
          <div className="find-compliance-document__search-section__input-section">
            <input onChange={(e) => this.onChange('searchedValue', e.target.value)} placeholder="Search..."/>
          </div>
        </div>
        <div className="find-compliance-document__buttons-section">
          <button className="button-green" disabled={buttonDisabled}
            onClick={() => this.props.findLibraries(data)}>SEARCH
          </button>
        </div>
        {
          this.props.documentsFound &&
          <div className="find-compliance-document__result-section">
            {this.props.documentsFoundCount && this.props.documentsFoundCount > 0 ?
              (this.props.documentsFoundCount > 1 ? `Found ${ this.props.documentsFoundCount } documents` : `Found
              ${ this.props.documentsFoundCount } document`) : 'No Results'}
          </div>
        }
      
      </div>
    )
  }
}

FindLibrary.propTypes = {
  documentsFoundCount: PropTypes.number.isRequired,
  documentsFound: PropTypes.bool.isRequired,
  findLibraries: PropTypes.func.isRequired
}
export default FindLibrary