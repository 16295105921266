import PropTypes from 'prop-types'
import React from 'react'
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc'
import AddNewOptionBuilder from './Helpers/AddNewOptionBuilder'
import { defineDisplaySection, updateOptionsOrder } from './Helpers/OptionsUtils'
import RemoveOption from './Helpers/RemoveOption'
import QuestionOptionForm from './QuestionOptionForm'
import './QuestionOptionsList.scss'
import { Tooltip } from 'antd'

const SortableItem = SortableElement(({ option, optionIndex, question, questions, removeOption, updateOption }) =>
  <QuestionOptionForm
    index={ optionIndex }
    option={ option }
    question={ question }
    questions={ questions }
    removeOption={ removeOption }
    updateOption={ updateOption }
  />
)

const SortableList = SortableContainer(({ options, question, questions, removeOption, updateOption }) =>
  <ul>
    { options.map((option, index) => 
      <SortableItem
        key={`item-${ index }`}
        index={ index }
        optionIndex={ index }
        option={ option }
        question={ question }
        questions={ questions }
        removeOption={ removeOption }
        updateOption={ updateOption }
      />
    )}
  </ul>
)

const hasTheOptionType = (question, optionSubType) => {
  return question.options.filter((option) => (option.subType === optionSubType)).length > 0
}

class QuestionOptionsList extends React.Component {
  constructor() {
    super()

    this.removeOption = this.removeOption.bind(this)
    this.updateOption = this.updateOption.bind(this)
    this.onSortEnd = this.onSortEnd.bind(this)
  }

  onSortEnd({ oldIndex, newIndex }) {
    let options = [...this.props.question.options]
    options = arrayMove(options, oldIndex, newIndex)
    options = updateOptionsOrder(options)
    options = defineDisplaySection(options)
    
    const newQuestion = {
      ...this.props.question,
      options
    }
    
    this.props.updateQuestion(this.props.questionIndex, newQuestion)
  }

  updateOption(index, option) {
    let newOptions = this.props.question.options
    newOptions[index] = option
    newOptions = defineDisplaySection(newOptions)

    const newQuestion = {
      ...this.props.question,
      options: newOptions
    }
    this.props.updateQuestion(this.props.questionIndex, newQuestion)
  }

  removeOption(optionIndex, option = {}) {
    const newQuestion = RemoveOption(this.props.question, optionIndex)
    this.props.updateQuestion(this.props.questionIndex, newQuestion)
  }

  removeOptionByType(optionType) {
    const allOptions = this.props.question.options.map((option, index) => ({ index, subType: option.subType }))
    const optionIndex = allOptions.find((option) => (option.subType === optionType)).index
    this.removeOption(optionIndex)
  }

  addDivider(options) {
    this.props.buildOptions(AddNewOptionBuilder, options)
  }

  addIdontKnow(options) {
    this.setState({ hasIdontKnow: true })
    this.props.buildOptions(AddNewOptionBuilder, options)
  }

  removeIdontKnow() {
    this.setState({ hasIdontKnow: false })
    this.removeOptionByType('I_DONT_KNOW')
  }

  addNoneOfAbove(options) {
    this.setState({ hasNoneOfAbove: true })
    this.props.buildOptions(AddNewOptionBuilder, options)
  }

  removeNoneOfAbove() {
    this.setState({ hasNoneOfAbove: false })
    this.removeOptionByType('NONE_OF_THE_ABOVE')
  }

  addTextInputOther(options) {
    this.setState({ hasOtherText: true })
    this.props.buildOptions(AddNewOptionBuilder, options)
  }

  removeTextInputOther() {
    this.setState({ hasOtherText: false })
    this.removeOptionByType('OTHER')
  }

  render() {
    const {
      question,
      questions,
      buildOptions
    } = this.props

    const hasIdontKnow = hasTheOptionType(question, 'I_DONT_KNOW')
    const hasNoneOfAbove = hasTheOptionType(question, 'NONE_OF_THE_ABOVE')
    const hasOtherText = hasTheOptionType(question, 'OTHER')

    const {
      type,
      options
    } = question

    return (
      <div>
        <div className="questionnaireOptionsList__optionsHeader">
          <div className="questionnaireOptionsList__headerTitle">
            <Tooltip title="Add multiple options to your question, you can also add dividers to separate groups of options">
              <span>answer options</span>
            </Tooltip>
          </div>

          <div className="questionnaireOptionsList__headerMessage">
            <Tooltip title="Add a custom message or footnote to an option">
              <span>msg</span>
            </Tooltip>
          </div>

          <div className="questionnaireOptionsList__headerScore">
            <Tooltip title="If this option is selected, this number is then added to the final calculated score">
              <span >score</span>
            </Tooltip>
          </div>

          <div 
            className={ question.type === 'YN' ? 'questionnaireOptionsList__headerJumpSmall' : 'questionnaireOptionsList__headerJump' }>
            <Tooltip title="If this option is selected, you can choose to jump to another question ahead of the next">
              <span>if selected, jump to</span>
            </Tooltip>
          </div>
        </div>
        <div id="questionnaireOptionsList__sotableList">
          <SortableList
            options={ options }
            question={ question }
            questions={ questions }
            removeOption={ this.removeOption }
            updateOption={ this.updateOption }
            onSortEnd={ this.onSortEnd }
            distance={ 2 }
          />
        </div>

        <div className="questionnaireOptionsList__actionsWrapper">
          {
            type !== 'YN' &&
            <React.Fragment>
              <div 
                className="questionnaireOptionsList__actionItem"
                onClick={() => { buildOptions(AddNewOptionBuilder, { question }) }}>
                <span> + Add another option </span>
              </div>

              <div 
                className="questionnaireOptionsList__actionItem"
                onClick={() => { this.addDivider({ question, subType: 'DIVIDER' }) }}>
                <span> + Add answer divider </span>
              </div>
              {
                !hasNoneOfAbove &&
                <div 
                  className="questionnaireOptionsList__actionItem"
                  onClick={() => { this.addNoneOfAbove({ question, subType: 'NONE_OF_THE_ABOVE' }) }}>
                  <span> + Add none of the above option </span>
                </div>
              }

              {
                hasNoneOfAbove &&
                <div 
                  className="questionnaireOptionsList__actionItemGray"
                  onClick={() => { this.removeNoneOfAbove() }}>
                  <span> - Remove none of the above option </span>
                </div>
              }

              {
                hasOtherText &&
                <div 
                  className="questionnaireOptionsList__actionItemGray"
                  onClick={() => { this.removeTextInputOther() }}>
                  <span> - Remove other text input option </span>
                </div>
              }

              {
                !hasOtherText &&
                <div
                  className="questionnaireOptionsList__actionItem"
                  onClick={() => { this.addTextInputOther({ question, subType: 'OTHER' }) }}>
                  <span> + Add other text input option </span>
                </div>
              }
            </React.Fragment>
          }

          {
            type === 'YN' &&
            <React.Fragment>
              {
                hasIdontKnow === false &&
                <div
                  className="questionnaireOptionsList__actionItem"
                  onClick={() => { this.addIdontKnow({ question, subType: 'I_DONT_KNOW' }) }}>
                  <span>+ Add I don't know Option</span>
                </div>
              }

              {
                hasIdontKnow &&
                <div
                  className="questionnaireOptionsList__actionItemGray"
                  onClick={() => { this.removeIdontKnow() }}>
                  <span>- Remove I don't know Option</span>
                </div>
              }
            </React.Fragment>
          }
        </div>
      </div>
    )
  }
}

QuestionOptionsList.propTypes = {
  question: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired,
  buildOptions: PropTypes.func.isRequired
}

export default QuestionOptionsList