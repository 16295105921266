import React from 'react'

class TrackVisibility extends React.Component {
  ref = React.createRef()

  componentDidMount() {
    const observer = new IntersectionObserver(
      ([ entry ]) => {
        if (entry.intersectionRatio === 0.1) {
          this.props.onVisible()
        } else if (entry.intersectionRatio === 0.5) {
          this.props.onVisible()
        } else if (entry.intersectionRatio === 1) {
          this.props.onVisible()
        }
      }, {
        root: null,
        rootMargin: '0px',
        threshold: [0.1, 0.5, 1.0]
      })

    if (this.ref.current) {
      observer.observe(this.ref.current)
    }
  }

  render() {
    return <div ref={this.ref}>{this.props.children}</div>
  }
}

export default TrackVisibility