import React from 'react'
import { Checkbox, Select } from 'antd'
import { apiUserFetchUsersListMinimized } from '../../../../../api/User'
import { apiTeamFetchTeamsListSmall } from '../../../../../api/Team'
import { apiSaveSharingPermissions, apiGetSharingPermissions } from '../../../../../api/SmartBox'
import CustomNotificationManager from '../../../../Common/CustomNotificationManager'
import { connect } from 'react-redux'
import Switcher from '../../../../Common/Switcher'
import {setSmartBoxNotGiveAccessBox, setSmartBoxSelectedDocuments} from '../../../../../actions/smartBoxActions'
import moment from "moment";
const { Option } = Select
//const RadioGroup = Radio.Group
class Access extends React.Component {
  state = {
    formData: {
      sharingAndPermissions: [
        {
          userName: null
        },
        {
          userName: null
        },
        {
          userName: null
        }
      ]
    },
    checkedKeys: [],
    usersList: [],
    teamsList: [],
    valueShareFolder: true,
    valueVisibleAllUser: true,
    extractUserSelected: [],
    newListUsersSaved: [],
    stateAccessSelectUsers: false,
    stateVisibleAllUsers: false,
    folderOwnerEmail: '',
    valueVisibleDownload: true,
    formDataDownload: {
      sharingAndPermissionsDownload: [
        {
          userName: null
        },
        {
          userName: null
        },
        {
          userName: null
        }
      ]
    },
    checkedKeysDownload: [],
    userSelectedDownloadAccess: [],
    checkVisibleUserDownload: false,
    userListDownload: [],
    newListUsersSavedDownload: [],
    stateVisibleToDownload: false,
    valueConfigurationBox: '',
    valueAutomatedDeduplication: true,
    activeVisibleAdd: false,
    activeVisibleRemove: false,
    activePermissionAdd: false,
    activePermissionRemove: false,
    boxVisibleTargetValue: false,
    downloadVisibleTargetValue: false,
    visibleArrowSelectUser: [],
    visibleToEdit: false

  }
  
  componentDidMount() {
    //this.fetchTeams()
    setTimeout(() => {
      this.fetchUsersList()
      this.onGetSharingPermissions()
      this.setState({
        valueConfigurationBox: this.props.folder.boxConfigurationType
      })
    }, 200)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props && this.props.folderId !== prevProps.folderId) {
      this.onGetSharingPermissions()
      this.fetchUsersList()
    }
    if (prevProps !== this.props && this.props.smartBoxSecurityConfiguration === 'security-settings') {
      this.setState({
        valueConfigurationBox: this.props.folder.boxConfigurationType
      })
    }
  }
  onGetSharingPermissions = () => {
    const { folderId, idFolder } = this.props
    const newUsers = []
    const newUsersDownload = []
    if (folderId) {
      const idGet = folderId ? folderId : idFolder
      apiGetSharingPermissions(idGet).then((response) => {
        const responseList = response.data.users
        const shareFolder = response.data.shareFolder
        const visibleAllUsers = response.data.visibleAllUsers
        const folderOwner = response.data.folderOwner
        const responseListDownload = response.data.usersAccessDownload
        const visibleToDownload = response.data.visibleToDownload
        const autoDeduping = response.data.autoDeduping
        const visibleToEdit = response.data.visibleToEdit
        this.setState({
          visibleToEdit: visibleToEdit
        })
        let listSortUsers = []
        let listSortDownload = []
        responseList && Object.keys(responseList).forEach((key) => {
          newUsers.push({
            userId: key,
            userName: responseList[key]
          })
        })
        responseListDownload && Object.keys(responseListDownload).forEach((key) => {
          newUsersDownload.push({
            userId: key,
            userName: responseListDownload[key]
          })
        })
        listSortUsers = newUsers && newUsers.length > 0 ? newUsers.sort((userA, userB) => (userA.userName.localeCompare(userB.userName))) : ''
        listSortDownload = newUsersDownload && newUsersDownload.length > 0 ? newUsersDownload.sort((userA, userB) => (userA.userName.localeCompare(userB.userName))) : ''
        if (shareFolder === 'false') {
          this.setState({
            valueVisibleDownload: true
          })
        } else {
          this.setState({
            valueVisibleDownload: visibleToDownload
          })
        }
        this.setState({
          newListUsersSaved: listSortUsers,
          folderOwnerEmail: folderOwner,
          newVisibleAllUser: (visibleAllUsers === 'true'),
          newListUsersSavedDownload: listSortDownload
        })
        if (shareFolder !== null || visibleAllUsers !== null) {
          if (shareFolder === 'false') {
            this.setState({
              valueShareFolder: (shareFolder === 'true'),
              valueVisibleAllUser: true
            })
          } else {
            this.setState({
              valueShareFolder: (shareFolder === 'true'),
              valueVisibleAllUser: (visibleAllUsers === 'true')
            })
          }
        }
        if (visibleAllUsers === null) {
          this.setState({
            valueVisibleAllUser: true
          })
        }
        if (shareFolder === null) {
          this.setState({
            valueShareFolder: true
          })
        }
        if (autoDeduping === 'false') {
          this.setState({
            valueAutomatedDeduplication: (autoDeduping === 'true')
          })
        } else {
          this.setState({
            valueAutomatedDeduplication: (autoDeduping === 'true')
          })
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        } else {
          console.log(error)
        }
      })
    }
  }
  updateSettingSelect = (e, key, checkedKeys) => {
    const visibleArrowSelectUser = []
    visibleArrowSelectUser.push(key)
    this.setState({
      visibleArrowSelectUser: visibleArrowSelectUser
    })
  }
  onClearValue = (key, e, position) => {
  }
  updateSetting(key, e, position) {
    const settings = this.state.formData
    if (typeof e === 'undefined') {
      this.setState({ boxVisibleTargetValue: true })
    } else {
      this.setState({ boxVisibleTargetValue: false })
    }
    const { extractUserSelected, newListUsersSaved } = { ...this.state }
    const usersSelected = []
    const finalUserSelected = []
    if (e) {
      usersSelected.push(e)
    }
    this.setState({
      extractUserSelected: extractUserSelected.concat(usersSelected)
    })
    finalUserSelected.concat(usersSelected)
    if (newListUsersSaved && newListUsersSaved.length > 0) {
      if (key === 'userName') {
        newListUsersSaved[position][key] = e
      } else {
        newListUsersSaved[position][key] = false
      }
    } else if (key === 'userName') {
      const { sharingAndPermissions } = settings
      sharingAndPermissions[position][key] = e
      this.setState({
        formData: {
          ...settings,
          sharingAndPermissions
        }
      })
      if (e) {
        this.setState({
          stateAccessSelectUsers: false
        })
      }
    } else {
      const newValue = settings[key] === false
      this.setState({
        formData: {
          ...settings,
          [key]: newValue
        }
      })
    }
    this.onSaveSharingPermissions(e)
  }
  
  setCheckedKeys = (checkedKeys) => {
    const { boxVisibleTargetValue} = this.state
    this.setState({
      boxVisibleTargetValue: false
    },() => {
      if (!boxVisibleTargetValue) {
        this.setState({ checkedKeys })
      }
    })
  }

  fetchUsersList() {
    apiUserFetchUsersListMinimized()
      .then((response) => {
        this.setState({
          usersList: response.data,
          userListDownload: response.data
        })
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
            console.log(error)
        }
    })
  }
  
  fetchTeams() {
    apiTeamFetchTeamsListSmall()
      .then((response) => {
        this.setState({
          teamsList: response.data,
        })
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
            console.log(error)
        }
    })
  }
  
  removeItem = () => {
    const settings = this.state.formData
    let { sharingAndPermissions } = settings
    let { newListUsersSaved } = this.state
    if (newListUsersSaved && newListUsersSaved.length > 0) {
      newListUsersSaved = newListUsersSaved.filter((sPermission, key) => !this.state.checkedKeys.includes(key))
      this.setState({
        newListUsersSaved: [...newListUsersSaved],
        checkedKeys: []
      }, () => this.onSaveSharingPermissions())
    } else {
      sharingAndPermissions = sharingAndPermissions.filter((sPermission, key) => !this.state.checkedKeys.includes(key))
      this.setState({
        formData: {
          ...settings,
          sharingAndPermissions
        },
        checkedKeys: []
      }, () => this.onSaveSharingPermissions())
    }
  }
  
  addItem = () => {
    const settings = this.state.formData
    const { sharingAndPermissions } = settings
    const { newListUsersSaved } = this.state
    if (newListUsersSaved && newListUsersSaved.length > 0) {
      newListUsersSaved.push({
        userName: null
      })
      this.setState({
        newListUsersSaved: [...newListUsersSaved]
      })
    } else {
      sharingAndPermissions.push({
        userName: null
      })
      this.setState({
        formData: {
          ...settings,
          sharingAndPermissions
        }
      })
    }
  }

  onSaveSharingPermissions = (key) => {
    const { valueShareFolder, formData, usersList, newListUsersSaved, formDataDownload, userListDownload, newListUsersSavedDownload, valueConfigurationBox } = this.state
    const { folder } = this.props
    let { valueVisibleAllUser, valueVisibleDownload } = this.state
    let listSelectedUsers = []
    let listSelectedUsersDownload = []
    if (newListUsersSaved && newListUsersSaved.length > 0) {
      if (valueShareFolder === false) {
        listSelectedUsers = []
        valueVisibleAllUser = null
      } else if (valueShareFolder === true && valueVisibleAllUser === true) {
        listSelectedUsers = []
      } else {
        usersList && usersList.length > 0 && usersList.forEach((user) => {
          newListUsersSaved.forEach((value) => {
            if (value.userName === user.label) {
              listSelectedUsers.push(user.value)
            }
          })
        })
      }
    } else if (formData) {
      if (valueShareFolder === false) {
        listSelectedUsers = []
        valueVisibleAllUser = null
      } else if (valueShareFolder === true && valueVisibleAllUser === true) {
        listSelectedUsers = []
      } else {
        usersList && usersList.length > 0 && usersList.forEach((user) => {
          formData.sharingAndPermissions && formData.sharingAndPermissions.length > 0 && formData.sharingAndPermissions.forEach((value) => {
            if (user.label === value.userName) {
              listSelectedUsers.push(user.value)
            }
          })
        })
      }
    }

    if (newListUsersSavedDownload && newListUsersSavedDownload.length > 0) {
      if (valueShareFolder === false) {
        valueVisibleDownload = null
        listSelectedUsersDownload = []
      } else if (valueShareFolder === true && valueVisibleDownload === true) {
        listSelectedUsersDownload = []
      } else {
        userListDownload && userListDownload.length > 0 && userListDownload.forEach((user) => {
          newListUsersSavedDownload.forEach((value) => {
            if (user.label === value.userName) {
              listSelectedUsersDownload.push(user.value)
            }
          })
        })
      }
    } else if (formDataDownload) {
      if (valueShareFolder === false) {
        valueVisibleDownload = null
        listSelectedUsersDownload = []
      } else if (valueShareFolder === true && valueVisibleDownload === true) {
        listSelectedUsersDownload = []
      } else {
        userListDownload && userListDownload.length > 0 && userListDownload.forEach((user) => {
          formDataDownload.sharingAndPermissionsDownload && formDataDownload.sharingAndPermissionsDownload.length > 0 && formDataDownload.sharingAndPermissionsDownload.forEach((value) => {
            if (user.label === value.userName) {
              listSelectedUsersDownload.push(user.value)
            }
          })
        })
      }
    }

    if (listSelectedUsersDownload.length === 0) {
      this.setState({
        checkVisibleUserDownload: true
      })
    } else {
      this.setState({
        checkVisibleUserDownload: false
      })
    }
    const data = {
      path: folder.path,
      users: listSelectedUsers,
      shareFolder: valueShareFolder,
      visibleAllUsers: valueVisibleAllUser,
      visibleToDownload: valueVisibleDownload,
      usersAccessDownload: listSelectedUsersDownload,
      boxConfigurationType: valueConfigurationBox,
      //autoDeduping: valueAutomatedDeduplication

    }
    // if (valueShareFolder === true && valueVisibleAllUser === false && listSelectedUsers.length === 0) {
    //   this.setState({
    //     stateAccessSelectUsers: true,
    //     stateVisibleAllUsers: false,
    //     checkVisibleUserDownload: false
    //   })
    // } else
      if (valueShareFolder === true && valueVisibleAllUser === null) {
      this.setState({
        stateVisibleAllUsers: true,
        stateAccessSelectUsers: false,
        checkVisibleUserDownload: false
      })
    }
    // else if (valueShareFolder === true && valueVisibleDownload === false && listSelectedUsersDownload.length === 0) {
    //   this.setState({
    //     checkVisibleUserDownload: true,
    //     stateVisibleToDownload: false
    //   })
    // }
    else if (valueShareFolder === true && valueVisibleDownload === null) {
      this.setState({
        stateVisibleToDownload: true,
        stateVisibleAllUsers: true,
        stateAccessSelectUsers: false,
      })
    } else {
      apiSaveSharingPermissions(data).then((response) => {
        if (response.status === 200) {
          //CustomNotificationManager.success('Your options are now saved.', 'Success')
          if (valueVisibleAllUser || key === this.props.user.email) {
            const notAccessBox = {
              nameActionEvent: 'Access to Box granted',
              descriptionActionEvent: `You have been given access to ${folder.name}`,
              timesEvent: moment().format('DD/MM/YYYY HH:mm:ss'),
              status: 'access-avatar',
              nameBox: folder.name
            }
            let notificationAccessBox = this.props.smartBoxNotGiveAccessBox.concat(notAccessBox)
            let listSortNotifications = []
            if (notificationAccessBox && notificationAccessBox.length > 0) {
              listSortNotifications = notificationAccessBox.sort((a, b) => {
                return new Date(a.timeUpdate).getTime() -
                    new Date(b.timeUpdate).getTime()
              }).reverse()
            }
            const uniqueAccessBox = listSortNotifications && listSortNotifications.length > 0 && listSortNotifications.filter((a, i) => listSortNotifications.findIndex((s) => s.nameBox === a.nameBox) === i)
            this.props.setSmartBoxNotGiveAccessBox(uniqueAccessBox)
          }
        }
        this.setState({
          stateAccessSelectUsers: false,
          stateVisibleAllUsers: false,
          checkVisibleUserDownload: false
        })
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
            console.log(error)
        }
      })
    }
  }
  updateSettingDownload = (key, e, position) => {
    const { userSelectedDownloadAccess, newListUsersSavedDownload } = { ...this.state }
    const settings = this.state.formDataDownload
    if (typeof e === 'undefined') {
      this.setState({ downloadVisibleTargetValue: true })
    } else {
      this.setState({ downloadVisibleTargetValue: false })
    }
    if (typeof e === 'undefined') {
      this.setState({
        checkedKeysDownload: []
      })
    }
    const usersSelected = []
    if (e) {
      usersSelected.push(e)
    }
    this.setState({
      userSelectedDownloadAccess: userSelectedDownloadAccess.concat(usersSelected)
    })
    if (newListUsersSavedDownload && newListUsersSavedDownload.length > 0) {
      if (key === 'userName') {
        newListUsersSavedDownload[position][key] = e
      } else {
        newListUsersSavedDownload[position][key] = false
      }
    } else if (key === 'userName') {
      const { sharingAndPermissionsDownload } = settings
      sharingAndPermissionsDownload[position][key] = e
      this.setState({
        formDataDownload: {
          ...settings,
          sharingAndPermissionsDownload
        }
      })
      if (e) {
        this.setState({
          checkVisibleUserDownload: false
        })
      }
    } else {
      const newValue = settings[key] === false
      this.setState({
        formDataDownload: {
          ...settings,
          [key]: newValue
        }
      })
    }
    this.onSaveSharingPermissions()
  }
  setCheckedKeysDownload = (checkedKeys) => {
    const { downloadVisibleTargetValue } = this.state
    this.setState({
      downloadVisibleTargetValue: false
    },() => {
      if (!downloadVisibleTargetValue) {
        this.setState({checkedKeysDownload: checkedKeys })
      }
    })
  }
  addItemDownload = () => {
    const settings = this.state.formDataDownload
    const { sharingAndPermissionsDownload } = settings
    const { newListUsersSavedDownload } = this.state
    if (newListUsersSavedDownload && newListUsersSavedDownload.length > 0) {
      newListUsersSavedDownload.push({
        userName: null
      })
      this.setState({
        newListUsersSavedDownload: [...newListUsersSavedDownload]
      })
    } else {
      sharingAndPermissionsDownload.push({
        userName: null
      })
      this.setState({
        formDataDownload: {
          ...settings,
          sharingAndPermissionsDownload
        }
      })
    }
  }
  removeItemDownload = () => {
    const settings = this.state.formDataDownload
    let { newListUsersSavedDownload } = this.state
    let { sharingAndPermissionsDownload } = settings
    if (newListUsersSavedDownload && newListUsersSavedDownload.length > 0) {
      newListUsersSavedDownload = newListUsersSavedDownload ? newListUsersSavedDownload.filter((sPermission, key) => !this.state.checkedKeysDownload.includes(key)) : ''
      this.setState({
        newListUsersSavedDownload: [...newListUsersSavedDownload],
        checkedKeysDownload: []
      }, () => this.onSaveSharingPermissions())
    } else {
      sharingAndPermissionsDownload = sharingAndPermissionsDownload && sharingAndPermissionsDownload.length > 0 ? sharingAndPermissionsDownload.filter((sPermission, key) => !this.state.checkedKeysDownload.includes(key)) : ''
      this.setState({
        formDataDownload: {
          ...settings,
          sharingAndPermissionsDownload
        },
        checkedKeysDownload: []
      }, () => this.onSaveSharingPermissions())
    }
  }
  handleChangeActiveShareFolder(status) {
    this.setState({
      valueShareFolder: status
    }, () => this.onSaveSharingPermissions())
  }
  handleChangeActiveShareFolderVisibleUser(status) {
    if (status) {
      this.setState({
        checkedKeys: [],
      })
    }
    this.setState({
      valueVisibleAllUser: status
    }, () => this.onSaveSharingPermissions())
  }
  handleChangeActiveVisibleDownload(status) {
    const { visibleToEdit } = this.state
    if (visibleToEdit !== false) {
      if (status) {
        this.setState({
          checkedKeysDownload: []
        })
      }
      this.setState({
        valueVisibleDownload: status
      }, () => this.onSaveSharingPermissions())
    }
  }
  onChangeConfigurationBox(e) {
    this.setState({
      valueConfigurationBox: e.target.value
    })
  }
  handleChangeAutomatedDeduplication(status) {
    this.setState({
      valueAutomatedDeduplication: status
    },() => this.onSaveSharingPermissions())
  }
  onActiveAddRemove = (e, status) => {
    switch (status) {
      case 'visible-user' :
        this.setState({
          activeVisibleAdd: true
        })
            break
      case 'visible-user-remove' :
        this.setState({
          activeVisibleRemove: true
        })
        break
      case 'add-user-download':
        this.setState({
          activePermissionAdd: true
        })
        break
      case 'remove-user-download':
        this.setState({
          activePermissionRemove: true
        })
        break
      default: break
    }
  }
  onInactiveAddRemove() {
    this.setState({
      activeVisibleAdd: false,
      activeVisibleRemove: false,
      activePermissionAdd: false,
      activePermissionRemove: false
    })
  }
  render() {
    const { formData,
      checkedKeys,
      valueShareFolder,
      valueVisibleAllUser,
      newListUsersSaved,
      folderOwnerEmail,
      valueVisibleDownload,
      formDataDownload,
      checkedKeysDownload,
      newListUsersSavedDownload,
      activeVisibleAdd,
      activeVisibleRemove,
      activePermissionAdd,
      activePermissionRemove,
      visibleToEdit } = this.state
    let { usersList, userListDownload } = this.state
    let { disabled } = this.props
    if(typeof disabled === 'undefined') {
      disabled = false
    }
    let displaySelected = []
    if (newListUsersSaved && newListUsersSaved.length > 0) {
      displaySelected = newListUsersSaved
    } else {
      displaySelected = formData.sharingAndPermissions
    }
    let displayUsersSelected = []
    if (newListUsersSavedDownload && newListUsersSavedDownload.length > 0) {
      displayUsersSelected = newListUsersSavedDownload
    } else {
      displayUsersSelected = formDataDownload.sharingAndPermissionsDownload
    }

    if (newListUsersSaved && newListUsersSaved.length > 0) {
      newListUsersSaved && newListUsersSaved.forEach((form) => {
        usersList = usersList && usersList.length > 0 ? usersList.filter((user) => user.label !== form.userName) : ''
      })
    } else {
      formData && formData.sharingAndPermissions && formData.sharingAndPermissions.length > 0 && formData.sharingAndPermissions.forEach((form) => {
        usersList = usersList && usersList.length > 0 ? usersList.filter((user) => user.label !== form.userName) : ''
      })
    }
    if (newListUsersSavedDownload && newListUsersSavedDownload.length > 0) {
      newListUsersSavedDownload && newListUsersSavedDownload.forEach((form) => {
        userListDownload = userListDownload && userListDownload.length > 0 ? userListDownload.filter((user) => user.label !== form.userName) : ''
        if (newListUsersSaved && newListUsersSaved.length > 0) {
          newListUsersSaved && newListUsersSaved.forEach((formUsers) => {
            userListDownload = userListDownload && userListDownload.length > 0 ? userListDownload.filter((user) => user.label !== formUsers.userName) : ''
          })
        } else {
          formData && formData.sharingAndPermissions && formData.sharingAndPermissions.length > 0 && formData.sharingAndPermissions.forEach((formUsers) => {
            userListDownload = userListDownload && userListDownload.length > 0 ? userListDownload.filter((user) => user.label !== formUsers.userName) : ''
          })
        }
      })
    } else {
      formDataDownload && formDataDownload.sharingAndPermissionsDownload && formDataDownload.sharingAndPermissionsDownload.length > 0 && formDataDownload.sharingAndPermissionsDownload.forEach((formDownload) => {
        userListDownload = userListDownload && userListDownload.length > 0 ? userListDownload.filter((user) => user.label !== formDownload.userName) : ''
        if (newListUsersSaved && newListUsersSaved.length > 0) {
          newListUsersSaved && newListUsersSaved.forEach((formUsers) => {
            userListDownload = userListDownload && userListDownload.length > 0 ? userListDownload.filter((user) => user.label !== formUsers.userName) : ''
          })
        } else {
          formData && formData.sharingAndPermissions && formData.sharingAndPermissions.length > 0 && formData.sharingAndPermissions.forEach((formUsers) => {
            userListDownload = userListDownload && userListDownload.length > 0 ? userListDownload.filter((user) => user.label !== formUsers.userName) : ''
          })
        }
      })
    }
    return (
      <div className="access-tab">
        <div className="content-visibility-folder">
          <div className="title-folder-visibility">Box owner</div>
          <div className="content-functionality-visibility-folder">{ folderOwnerEmail ? `${ folderOwnerEmail }` : '' }</div>
        </div>

        {/*<div className="content-visibility-folder">*/}
        {/*  <div className="title-folder-visibility">Box configuration</div>*/}
        {/*  <div className="content-functionality-visibility-folder content-radio-configuration">*/}
        {/*    <div className="row">*/}
        {/*      <div className="col-md-3">*/}
        {/*        <Radio.Group value={ valueConfigurationBox } onChange={ (e) => disabled ? {} : this.onChangeConfigurationBox(e) }>*/}
        {/*          <Radio value="defaultConfiguration"><span><span className="title-radio">Default configuration</span>*/}
        {/*                      <span className="descriptions-radio">Quick start to process your data, setup any conditions later</span></span></Radio>*/}
        {/*          <Radio value="advanced configuration"><span><span className="title-radio">Advanced configuration</span>*/}
        {/*                      <span*/}
        {/*                        className="descriptions-radio">Setup your box, guided step by step.</span></span></Radio>*/}
        {/*        </Radio.Group>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}

        <React.Fragment>
          {/*<div className="content-visibility-folder">*/}
          {/*  <div className="title-folder-visibility">Automated deduplication</div>*/}
          {/*  <div className="content-functionality-visibility-folder">*/}
          {/*    /!*<div className="access-tab__share-folder-container">*!/*/}
          {/*      <div className="row" style={{marginBottom:'-30px'}}>*/}
          {/*        <div className="col-md-3 left-container">*/}
          {/*          <div className="content-radio-share-folder">*/}
          {/*            <div className="teams switcher-select-option switcher-select-option-duplicate">*/}
          {/*              <Switcher*/}
          {/*                  active={ valueAutomatedDeduplication }*/}
          {/*                  updateActive={ () =>*/}
          {/*                      disabled ? {} : this.handleChangeAutomatedDeduplication(!valueAutomatedDeduplication)*/}
          {/*                  }*/}
          {/*              />*/}
          {/*            </div>*/}
          {/*          </div>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    /!*</div>*!/*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="content-share-folder">
          <div className="row" style={{marginTop: '40px'}}>
            <div className="left-container">
              <p className="title-users">Share Box</p>
            </div>
            <div className="right-container">
              <div className="content-radio-share-folder update-share-folder">
                <div className="teams switcher-select-option">
                  <Switcher
                      active={ valueShareFolder && !disabled }
                      updateActive={ () =>
                          disabled ? {} : this.handleChangeActiveShareFolder(!valueShareFolder)
                      }
                  />
                </div>
              </div>
            </div>
          </div>
          </div>
          <div className="content-visibility-folder">
            {valueShareFolder === true &&
            <div className="title-folder-visibility">Box visible to all users</div>
            }
            <div className="content-functionality-visibility-folder">
              {/*<div className="access-tab__share-folder-container">*/}
              {/*  <div className="row">*/}
              {/*    <div className="col-md-3 left-container">*/}
              {/*      <p className="title-users">Share Folder</p>*/}
              {/*    </div>*/}
              {/*    <div className="col-md-9 right-container">*/}
              {/*      <div className="content-radio-share-folder">*/}
              {/*        <div className="teams switcher-select-option">*/}
              {/*          <Switcher*/}
              {/*            active={ valueShareFolder && !disabled }*/}
              {/*            updateActive={ () =>*/}
              {/*              disabled ? {} : this.handleChangeActiveShareFolder(!valueShareFolder)*/}
              {/*            }*/}
              {/*          />*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}

              {/*<div className="access-tab__share-folder-container">*/}
                { valueShareFolder === true &&
                <div className="row">
                  {/*<div className="col-md-3 left-container">*/}
                  {/*  <p className="title-users">Visible to all users</p>*/}
                  {/*</div>*/}
                  <div className="col-md-9 right-container">
                    {/*{ stateVisibleAllUsers &&*/}
                    {/*<span style={ { color: 'red' } }>You must select an option.</span>*/}
                    {/*}*/}
                    <div className="content-radio-share-folder update-share-folder">
                      <div className="teams switcher-select-option">
                        <Switcher
                          active={ valueVisibleAllUser && !disabled }
                          updateActive={ () =>
                            disabled ? {} : this.handleChangeActiveShareFolderVisibleUser(!valueVisibleAllUser)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                }
              {/*</div>*/}

              <div className="access-tab__share-and-permissions-container">
                {/*{ valueShareFolder === false ? '' : valueShareFolder === true && valueVisibleAllUser === true ? '' : valueShareFolder === true && valueVisibleAllUser === null ? '' :*/}
                { valueShareFolder &&
                <div className="row">
                    <div className="col-md-3 left-container">
                      <p className={ !valueShareFolder || (valueShareFolder && valueVisibleAllUser) ? 'title-visible-only-users title-visible-only-users-disabled' : 'title-visible-only-users' }>Box only visible for:</p>
                    </div>
                    <div className="col-md-12 right-container right-container-update">
                      {/*{*/}
                      {/*  stateAccessSelectUsers &&*/}
                      {/*  <span className="display-selected-empty" style={ { color: 'red', marginLeft: '31px' } }>You must select at least one user to share the folder with.</span>*/}
                      {/*}*/}
                      <Checkbox.Group onChange={ (e) => disabled ? {} : this.setCheckedKeys(e) } value={ checkedKeys }>
                        { displaySelected.map((permission, key) => <Checkbox
                          key={ key } value={ key }
                          disabled={ !valueShareFolder || (valueShareFolder && valueVisibleAllUser) }>
                          <Select
                              value={ permission.userName || 'Select User' }
                            // value={ <span className="select-visible-only-users"><span>{ permission.userName || 'Select User' }</span>
                            //   <span className={visibleArrowSelectUser && visibleArrowSelectUser.length > 0 && visibleArrowSelectUser.includes(key) ? 'icon-v17-arrow-medium arrow-visible-only-users arrow-medium-active' : 'icon-v17-arrow-medium arrow-visible-only-users'}></span></span> }
                            //defaultValue="Select user"
                            key={ key }
                            disabled={ !valueShareFolder || (valueShareFolder && valueVisibleAllUser) || disabled }
                            type="text"
                            onChange={ (e) => disabled ? {} : this.updateSetting('userName', e, key) }
                            onClear={ (e) => this.onClearValue(e)}
                            onFocus={ (e) => this.updateSettingSelect(e, key, checkedKeys) }
                            showSearch
                            allowClear
                            optionFilterProp="label"
                            className="user-team-select"
                            dropdownClassName="user-team-select-dropdown"
                            filterOption={ (input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            { usersList && usersList.length > 0 && usersList.map((user, key1) => <Option
                              value={ user.label } key={ key1 }>{ user.label }</Option>) }
                          </Select>
                        </Checkbox>) }
                      </Checkbox.Group>
                      <div className="action-buttons-wrapper">
                        <div
                          className={ !valueShareFolder ? 'action-buttons-wrapper--float-right content-disabled' : 'action-buttons-wrapper--float-right' }>
                          <button className={ checkedKeys.length === 0 ? 'icon-v16-minus' : activeVisibleRemove ? 'icon-v16-minus-active' : 'icon-v16-minus' } disabled={ disabled || (checkedKeys && !checkedKeys.length > 0) }
                                  onClick={ this.removeItem } onMouseEnter={(e) => this.onActiveAddRemove(e, 'visible-user-remove')}
                                  onMouseLeave={() => this.onInactiveAddRemove()}/>
                          <button disabled={ !valueShareFolder || (valueShareFolder && valueVisibleAllUser) }
                                  className={ !valueShareFolder || (valueShareFolder && valueVisibleAllUser) ? 'icon-v16-add colour-disabled' :
                                      activeVisibleAdd ? 'icon-v16-add-active' : 'icon-v16-add' }
                                  onClick={ this.addItem } onMouseEnter={(e) => this.onActiveAddRemove(e, 'visible-user')}
                                  onMouseLeave={() => this.onInactiveAddRemove()}/>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                {/*// }*/}
              </div>
            </div>
          </div>
          <div className="content-visibility-folder">
            { valueShareFolder &&
            <div className="title-folder-visibility">Allow downloads</div>
            }
            { valueShareFolder &&
            <div className={ visibleToEdit === false ? "content-functionality-visibility-folder disabled-visibility" : 'content-functionality-visibility-folder' }>
              {/*<div className="access-tab__share-folder-container">*/}
                <div className="row">
                  {/*<div className="col-md-3 left-container">*/}
                  {/*  <p className="title-users">Allow downloads to all users</p>*/}
                  {/*</div>*/}
                  <div className="col-md-9 right-container">
                    {/*{*/}
                    {/*  stateVisibleToDownload &&*/}
                    {/*  <span style={ { color: 'red' } }>You must select an option.</span>*/}
                    {/*}*/}
                    <div className="content-radio-share-folder update-share-folder">
                      <div className="teams switcher-select-option">
                        <Switcher
                          active={ valueVisibleDownload && !disabled }
                          updateActive={ () =>
                            disabled ? {} : this.handleChangeActiveVisibleDownload(!valueVisibleDownload)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              {/*</div>*/}
              <div className="access-tab__share-and-permissions-container">
                  <div className="row">
                    <div className="col-md-3 left-container">
                      <p className={valueVisibleDownload === true || valueVisibleDownload === null ? 'title-selected-users title-selected-users-disabled' : 'title-selected-users' }>Only allow downloads for:</p>
                    </div>
                    <div className="col-md-12 right-container right-container-update">
                      {/*{*/}
                      {/*  checkVisibleUserDownload &&*/}
                      {/*  <span className="display-selected-empty" style={ { color: 'red', marginLeft: '31px' } }>You must select at least one user.</span>*/}
                      {/*}*/}
                      <Checkbox.Group onChange={ (e) => disabled ? {} : this.setCheckedKeysDownload(e) } value={ checkedKeysDownload }>
                        { displayUsersSelected && displayUsersSelected.length > 0 && displayUsersSelected.map((permission, key) =>
                          <Checkbox
                            key={ key } value={ key }
                            disabled={ visibleToEdit === false || valueVisibleDownload === true || valueVisibleDownload === null}>
                            <Select
                                value={ permission.userName || 'Select User' }
                              // value={<span className="download-visible-users"><span>{ permission.userName || 'Select User' }</span>
                              //   <span className={'icon-v17-arrow-medium arrow-visible-download-users'}></span></span>}
                              key={ key }
                              type="text"
                              onChange={ (e) => disabled ? {} : this.updateSettingDownload('userName', e, key) }
                              showSearch
                              showArrow
                              allowClear
                              optionFilterProp="label"
                              className="user-team-select"
                              dropdownClassName="user-team-select-dropdown"
                              disabled={ visibleToEdit === false || valueVisibleDownload === true || valueVisibleDownload === null }
                              filterOption={ (input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              { userListDownload && userListDownload.length > 0 && userListDownload.map((user, key2) =>
                                <Option value={ user.label } key={ key2 }>{ user.label }</Option>) }
                            </Select>
                          </Checkbox>) }

                      </Checkbox.Group>
                      <div className="action-buttons-wrapper">
                        <div className="action-buttons-wrapper--float-right">
                          <button className={checkedKeysDownload.length === 0 ? 'icon-v16-minus' : activePermissionRemove ? 'icon-v16-minus-active' : 'icon-v16-minus' }
                                  disabled={ disabled || (checkedKeysDownload && !checkedKeysDownload.length > 0) }
                                  onClick={ disabled ? {} : this.removeItemDownload }
                                  onMouseEnter={(e) => this.onActiveAddRemove(e, 'remove-user-download')}
                                  onMouseLeave={() => this.onInactiveAddRemove()}/>
                          <button className={ activePermissionAdd ? 'icon-v16-add-active' : 'icon-v16-add' } disabled={ valueVisibleDownload === true || valueVisibleDownload === null } onClick={ this.addItemDownload }
                                  onMouseEnter={(e) => this.onActiveAddRemove(e, 'add-user-download')}
                                  onMouseLeave={() => this.onInactiveAddRemove()}/>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
            }
          </div>
        </React.Fragment>
        <div className="access-tab__buttons-section-container">
          {/*<Button className="new-button-confirm-action-save" disabled={ disabled } onClick={ this.onSaveSharingPermissions }> SAVE </Button>*/}
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  smartBoxSelectedDocumentId: state.smartBox.smartBoxSelectedDocumentId,
  smartBoxSecurityConfiguration: state.smartBox.smartBoxSecurityConfiguration,
  smartBoxNotGiveAccessBox: state.smartBox.smartBoxNotGiveAccessBox
})

export default connect(mapStateToProps,
  { setSmartBoxSelectedDocuments, setSmartBoxNotGiveAccessBox
})(Access)