import axios from 'axios'
const isLocalhost = () => {
  return window.location.hostname === 'localhost'
}
const host = isLocalhost() ? 'http://localhost:8080' : ''

export const apiViewPlan = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/subscription/all-plans`
  })
}