import axios from 'axios'
import {fetchClientData} from "../actions/adminActions";

const isLocalhost = () => {
  return window.location.hostname === 'localhost'
}
const host = isLocalhost() ? 'http://localhost:8080' : ''

export const apiFetchClients = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/admin/clients-data`,
    data: data
  })
}

export const apiUpdateClientById = (id, formValues) => {
  return axios({
    method: 'put',
    url: `${ host }/osprey-0.1.0/v1/admin/client-data/update`,
    data: { key: id, ...formValues}
  })
}

export const apiCreateClient = (formValues) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/admin/create-client`,
    data: formValues
  })
}

export const apiGetAllEmailTemplate = (id) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/god-admin/email-template/${ id }`,
  })
}

export const apiFetchUsers = (data) => {
  data.clientId = null
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/admin/user-data/god-admin-filter-list`,
    data: data
  })
}
export const getClientNames = () => {
    return axios({
        method: 'get',
        url: `${ host }/osprey-0.1.0/v1/admin/user-data/get-simplified-clients`,
    })
}

export const getRolesByApplication = (applicationName) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/admin/user-roles/${ applicationName }`,
  })
}

export const apiUpdateUser = (formValues) => {
  return axios({
    method: 'put',
    url: `${ host }/osprey-0.1.0/v1/admin/user-data`,
    data: formValues
  })
}


export const apiUpdateClientSubscriptionPlan = (data) => {
  return axios({
    method: 'put',
    url: `${ host }/osprey-0.1.0/v1/subscription/update-client-plan`,
    data
  })
}

export const getSystemVariableForAdminUrl = (formValues) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/admin/sistem-value`,
    data: formValues
  })
}

export const sendUniqueLinkForSuperAdmin = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/admin/client-super-admin`,
    data: data
  })
}

export const sendUniqueLinkForGravicusAdmin = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/admin/gravicus-admin`,
    data: data
  })
}

export const switchUser = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/admin/switch-user`,
    data: data
  })
}
export const apiInviteTenant = (formValues) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/admin/invite-tenant`,
    data: formValues
  })
}
export const apiInviteTenantGoodAdmin = (formValues) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/admin/invite-tenant-god-admin`,
    data: formValues
  })
}
export const apiGlobalResetMfa = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/admin/global-reset-mfa`,
    data: data
  })
}
export const apiResetMfaUserAdmin = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/resetMfaAdmin`,
    data
  })
}
export const getClientDataList = () => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/admin/clients-data-list`,
  })
}

export const apiGetClientData= (clientId) => {
  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/v1/admin/client-data/${ clientId }`,
  })
}

export const apiGodAdminFilterListByClient = (data) => {
  data.clientId = null
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/admin/user-data/god-admin-filter-list-by-client`,
    data: data
  })
}

export const apiSendRequestTrackerInlineCustomer = (data) => {
  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/sar/send-request-tracker-inline-form-to-customer`,
    data
  })
}