import React from 'react'
import './MultiSelect.scss'

class MultiSelect extends React.Component {
  constructor() {
    super()

    this.state = { opened: false }
    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  setWrapperRef(node) {
    this.wrapperRef = node
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ opened: false })
    }
  }

  openSelect() {
    this.setState({ opened: !this.state.opened })
  }

  render() {
    const { placeHolder, items, index } = this.props
    const selectedOnes = items.filter((item) => item.selected).map((item) => item.label)

    return (
      <dl ref={ this.setWrapperRef } className={ this.state.opened? 'multiSelectOpened' : 'multiselect' }> 
        <dt onClick={ () => this.openSelect() }>
          {
            selectedOnes.length === 0 &&
            <span>{ placeHolder || 'Select' }</span>
          }
          {
            selectedOnes.length > 0 &&
            <span>{ selectedOnes.join(', ') }</span>
          }
        </dt>

        <dd>
          <div className="multiSelect__list">
            { items.map((item) => (
              <div key={ `${ index }-${ item.value }` } className="multiSelect__checkboxGroup">
                <div className="multiSelect__checkboxWrapper">
                  <label htmlFor={ `${ index }-${ item.value }` } className={ item.selected ? 'multiSelector__labelSelected' : 'multiSelector__label'} />
                  <input id={ `${ index }-${ item.value }` } checked={ item.selected } type="checkbox" onChange={ (e) => { this.props.selectItem(item.value, e.target.checked) }} />
                </div>
                <span
                  onClick={ (e) => { this.props.selectItem(item.value, !item.selected) }}
                >{ item.label }</span>
              </div>
            ))}
          </div>
        </dd>
      </dl>
    )
  }
}

export default MultiSelect