import React from 'react'
import PropTypes from 'prop-types'

const DropdownView = (props) => {
  return (
    <div className="dropdown-view">
      {
        props.options.map((option, key) =>
          <div className="dropdown-view__item" key={key}>
            <span className="dropdown-view__item__name">{option.name}</span>
          </div>
        )
      }
    </div>
  )
}

DropdownView.propTypes = {
  options: PropTypes.array.isRequired
}

export default DropdownView