import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import '../ExternalFeedbackWorkflowTicketForm.scss'
import {Input, Select, Steps} from "antd";
import {NameTemplateFormExternal} from "./NameTemplateFormExternal";
const { Option } = Select;
const { Step } = Steps;
const { TextArea } = Input

export class ShortTextTemplateFormExternal extends Component {

    state = {
        characterCount:0,

    }
    onChangeNameAndDetails = (e, key) => {
        const { component } = this.props
        switch (key) {
            case 'short_text':
                this.props.onChangeNameAndDetails(e, key, component)
                this.setState({
                    characterCount:e.target.value.length
                })
                break
            default:
                break
        }
    }
    render() {
        const {characterCount} = this.state
        const { formerEmployee, component } = this.props
        return (
            <div className="full-name-former">
                <span className="header-former header-former-address">{component.header && component.mandatory ? `${component.header}*` :
                    component.header ? component.header : 'Short Text'}</span>
                <div className="input-header-component">
                            <span className="input-content">
                                <TextArea className="input-form-name"
                                          onChange={(e) => this.onChangeNameAndDetails(e, 'short_text')}
                                          maxLength={60}
                                          value={ component.shortTextValue }/>
                        <div className='shortText-character-count'>
                            <p>{characterCount}/60</p>
                        </div>
                            </span>
                </div>
            </div>
        )
    }
}


export default connect()(ShortTextTemplateFormExternal)