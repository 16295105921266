import Loading from '../../../../Common/Loading'
import Switcher from '../../../../Common/Switcher'
import { DropdownButton, Dropdown} from 'react-bootstrap'
import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

const  ManagerUsersTable = ({ isLoading, usersList, currentAccountIsPaid, user= {}, currentUsersIsAdmin, 
  updateRole, handleRemoveUser, resendInvite, showTeamsModal, toggleOpen, openedMenuId, ...props }) => {
  let navigate = useNavigate()


  const splitLongEmail = (longEmail) => {
    const temp = longEmail.length>41 ? (longEmail.split('@')) : (longEmail.email)
    return `${ temp[0] }\n@${ temp[1] }`
  }

  const padTo2Digits = (num) => {
    return num.toString().padStart(2, '0');
  }

  const getGMTTime = (date2) => {
    const date = new Date(date2)
    return [
      padTo2Digits(date.getUTCHours()),
      padTo2Digits(date.getUTCMinutes()),
    ].join(':');
  }
  
  const formatDate = (oldDate) => {
    if (typeof oldDate === 'undefined' || oldDate === null || oldDate === '') {
      return false
    }
    const old = moment(oldDate)
    const isCurrentDate = old.isSame(moment(), 'day')
    const isYesterday = old.isSame(moment().subtract(1, 'days'), 'day')
    let extension = 'th'
    const getExactDay = new Date(oldDate).getDate()
    if (getExactDay.toString() === '1') {
      extension = 'st'
    } else if (getExactDay.toString() ==='2') {
      extension = 'nd'
    } else if (getExactDay.toString() ==='3' || getExactDay.toString() ==='03') {
      extension = 'rd'
    } else {
      extension = 'th'
    }
  
    if (isCurrentDate) {
      // const temp = old.format('HH:mm')
      // return temp.concat(' - Today')
      const getTimeCurrentDate = getGMTTime(oldDate)
      return `${getTimeCurrentDate }` + ' - Today'
    }
    if (isYesterday) {
      // const temp = old.format('HH:mm')
      // return temp.concat(' - Yesterday')
      const getTimeYesterday = getGMTTime(oldDate)
      return `${getTimeYesterday }` + ' - Yesterday'
    }
    return old.format('MMM').concat(', ').concat(old.format('DD').concat(extension))
  }
  return (
    <div className="manageUsers-table">
      <div className="manageGroups-tableContainerSecond">
        {isLoading ? <div className="manageGroups-loading"><Loading /></div> 
        :
        <table className="manageGroups-table">
          <thead>
            <tr>
              <th width="12%" className="manageGroups-tableTitle width-nf text-center" id="button-tableContent">Admin / User</th>
              <th width="17%" className="manageGroups-tableTitle" id="name-tableContent">Name</th>
              <th width="27%" className="manageGroups-tableTitle" id="email-tableContent">Email Address</th>
              <th width="16%" className="manageGroups-tableTitle" id="team-tableContent">Team</th>
              <th width="8%" className="manageGroups-tableTitle" id="mfa-tableContent">MFA</th>
              <th width="12%" className="manageGroups-tableTitle" id="login-tableContent">Last Login</th>
              <th width="4%" className="manageGroups-tableTitle">Disable</th>
              <th width="5%" className="manageGroups-tableTitle text-center">Edit</th>
            </tr>
          </thead>
          <tbody>
            { (!usersList || !usersList.length) &&
              <tr>
                <td colSpan={7} className="no_user_wrapper">
                  <h1>No users found</h1>
                </td>
              </tr>
            }
            { usersList &&
              usersList.length &&
              usersList.map((user, index) => {
                let isAdmin = false
                
                if (user.user.user_metadata.roles.osprey === 'admin' || user.user.user_metadata.roles.osprey === 'super_admin') {
                  isAdmin = user.user.user_metadata.roles.osprey
                }
                const lastLogin = formatDate(user.user.lastLoginString)
                if (user.user && currentAccountIsPaid) {
                 // console.log(user.user.last_login,'lastLogin')
                  return (
                    <tr key={ user.user.id ? user.user.id : index }>
                      <td className="manageGroups-tableContent" id="button-tableContent">
                        <div className={ isAdmin === 'super_admin' ? 'manageGroups-sliderCheckedSadmin not-allowed' : (isAdmin === 'admin'? 'manageGroups-sliderChecked' : 'manageGroups-slider') } onClick={ updateRole.bind(this, user) } >
                          {isAdmin !== 'super_admin' &&
                          <input
                            checked={ isAdmin }
                            id={ `slider-${ user.user.user_id }` }
                            name="check"
                            type="checkbox"
                            value="None"
                            disabled={ isAdmin === 'super_admin' && this.confirmRolesUser ===false }
                            onChange={ updateRole.bind(this, user.user) } />
                          &&
                          <label
                            className={ isAdmin ? 'manageGroups-sliderButtonChecked' : 'manageGroups-sliderButton' }
                            htmlFor={ `slider-${ user.user.user_id }` } />
                          }
                        </div>
                      </td>
                      <td className="manageGroups-tableContent" id="name-tableContent">
                        { `${ user.user.user_metadata.name ? user.user.user_metadata.name : '' } ${ user.user.user_metadata.nickname ? user.user.user_metadata.nickname : '' }` || user.user.name }
                      </td>
                      <td className="manageGroups-tableContent" id="email-tableContent">
                        {
                          <p key={user.user + index} className="styleEmail"> { user.user.email.length>41 ? splitLongEmail(user.user.email) : user.user.email } </p>
                        }
                      </td>
                      <td className="manageGroups-tableContent" id="team-tableContent">
                        <div>
                          {typeof user.teamsInformation !== 'undefined' && user.teamsInformation.length>0 && user.teamsInformation.map((item) => { return (<p key={item.name + index}> { item.name } </p>) }) }
                        </div>
                      </td>
                      <td className="manageGroups-tableContent" id="mfa-tableContent">
                        { user.user.multi_factor_authentication ? 'Yes' : 'No' }
                      </td>
                      <td className="manageGroups-tableContent" id="login-tableContent">
                        { lastLogin &&
                        <div>
                          <i className="icon_logged float-left" />
                          <span className="float-left ml-2 mto-5">{ lastLogin }</span>
                        </div>
                        }
                        { !lastLogin &&
                        <div>
                          <i className="icon_logged_x float-left" />
                          <span className="float-left ml-2 mto-5 content-resend"><button className="resend-manage-user" onClick={ () => resendInvite(user.user.email, user.user.id) }>RESEND</button></span>
                        </div>
                        }
                      </td>
                      <td className="manageGroups-tableContent">
                        {isAdmin !== 'super_admin' &&
                        <div className={ currentUsersIsAdmin ? 'small-switcherForAdmin' : 'small-switcher' }>
                          <Switcher
                            active={ !user.user.blocked }
                            updateActive={ (e) => { handleRemoveUser(user.user.user_id) } }
                          />
                        </div>
                        }
                      </td>
                      <td className={`manageGroups-tableContent actionsDotsCell ${openedMenuId === user.user.id ? "show" : ""}`} style={{ textAlign: 'right' }}>
                        <DropdownButton
                          title={ <div className="ellipsis-v">...</div> }
                          id={`dropdown-${ user.user.id }`}
                          onClick={(event) => toggleOpen(event, user.user.id) }
                        >
                          <Dropdown.Item onClick={ () => { navigate(`/osp/manage/users/edit/${ user.user.id }`)} } >Edit Profile</Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => showTeamsModal(e, user.user) }
                            onSelect={ () => {} }>Assign Team</Dropdown.Item>
                          <Dropdown.Item onClick={ () => { navigate(`/osp/manage/users/${ user.user.id }/reviewactivityuser`) } }>Review Activity</Dropdown.Item>
                        </DropdownButton>
                      </td>
                    </tr>
                  )
                }
                return null
              })
            }
            <tr><td colSpan={6} /></tr>
          </tbody>
        </table>}
      </div>
    </div>
  )
}
const stateMap = (state) => ({
  user: state.userReducer.user
})
export default connect(stateMap)(ManagerUsersTable)