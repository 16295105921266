import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import {Link, Navigate} from 'react-router-dom'
import { compose } from 'redux'
import CustomNotificationManager from '../../../Common/CustomNotificationManager'
import * as messageActions from '../../../../actions/messageActions'
import * as userActions from '../../../../actions/userActions'
import * as smartBoxActions from '../../../../actions/smartBoxActions'
import {
  apiUserUpdate,
  apiResetMfaUser,
  apiDisabledMfaUser,
  apiUserChangePassword,
  apiUserFetchInfo
} from '../../../../api/User'
import {emailRegex, getErrorMessage} from '../../../../utils'
import Loading from '../../../Common/Loading'
import Message from '../../../Common/Message'
import PageTitle from '../../../Common/PageTitle'
import '../../../../style/common.scss'
import './UserSettings.scss'
import {Checkbox, Button, Tooltip, Icon, Spin} from 'antd'
import ImageUploader from "react-images-upload";
import {apiGetImage} from "../../../../api/Image";
import axios from "axios";
import _ from "lodash";
import {ConcurrencyManager} from "axios-concurrency";
import FormData from "form-data";
import {receiveUserInfo} from "../../../../actions/userActions";
import {setImageProfileSettings} from "../../../../actions/smartBoxActions";
import smartBoxReducer from "../../../../reducers/smartBoxReducer";
const isLocalhost = () => {
  return window.location.hostname === 'localhost'
}
const host = isLocalhost() ? 'http://localhost:8080' : ''
export class UserSettings extends React.PureComponent {
  constructor() {
    super()
    
    this.state = {
      department: '',
      emailAddress: '',
      emailFrequency: '',
      isLoading: true,
      isSending: false,
      name: '',
      position: '',
      popupTime: 3000,
      valueMultiFactorAuthentication: false,
      visibleSectionScanApp: false,
      imageScan: '',
      valueDigitPasscode: '',
      secret: '',
      valueMultiFactorDisabled: false,
      activeMultiFactor: true,
      statusDisabledMfaUser: '',
      valueDigitPasscodeDisabled: '',
      disabledSaveChange: false,
      visibleFactorRender: false,
      imageUpload: '',
      showPicture: '',
      firstName: '',
      lastName: '',
      middleName: '',
      nickname: '',
      middle_name: '',
      currentPassword: '',
      newPassword: '',
      retypePassword: '',
      conditionsPassword: [],
      conditionsRepeatPassword: [],
      newPasswordValid: false,
      retypePasswordValid: false,
      responseChangePassword: '',
      loadingDiscardChanges: false

    }

    this.saveChanges = this.saveChanges.bind(this)
    this.onDrop = this.onDrop.bind(this)
    this.state.pictureImg = []
    this.imageUpload = React.createRef()
  }

  componentWillUnmount() {
    this.props.hideMessage()
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.axiosClone = axios.create({ headers: this.props.smartBoxAxiosHeaders })
    this.manager = ConcurrencyManager(this.axiosClone, this.MAX_CONCURRENT_REQUESTS)
    this.initializeFields()
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_.isEmpty(this.props.smartBoxAxiosHeaders)) {
      this.axiosClone = axios.create({ headers: this.props.smartBoxAxiosHeaders })
      this.manager = ConcurrencyManager(this.axiosClone, this.MAX_CONCURRENT_REQUESTS)
    }
  }
  initializeFields() {
    const { user, subscription } = this.props
      const type = 'user'
        apiGetImage(this.props.user.id, type).then((response) => {
          this.setState({
            showPicture: response.data
          })
        })
    if (subscription.subscription.client.multiple_factor_authentication === false) {
      this.setState({
        disabledSaveChange: true
      })
    }
    this.setState({
      department: user.user_metadata.department,
      emailAddress: user.email,
      emailFrequency: user.user_metadata.emailFrequency,
      isLoading: false,
      name: user.user_metadata.name || user.name,
      nickname: user.user_metadata.nickname || user.nickname,
      middle_name: user.middle_name ? user.middle_name : '',
      position: user.user_metadata.position,
      valueMultiFactorAuthentication: user.multi_factor_authentication,
      valueMultiFactorDisabled: subscription.subscription.client.multiple_factor_authentication,
      visibleFactorRender: user.multi_factor_authentication ? true : false,
      activeMultiFactor: true,
    })
  }

  updateValue(field, e) {
    if (field === 'newPassword') {
      this.updatePasswprdStrengthCheckers(true, true, true, true, 8, e.target.value)
    }
    if (field === 'retypePassword') {
      this.updateRepeatPasswordStrengthCheckers(e.target.value)
    }
    this.setState({
      [field]: e.target.value
    }, () => this.validateField(field))
  }
  validateField(field) {
    if (field === 'newPassword' || field === 'retypePassword') {
      const { newPassword, retypePassword } = this.state
      if (newPassword === retypePassword) {
        return this.props.hideMessage()
      }
    }
  }
  saveChanges(e) {
    e.preventDefault()
    if (!this.isFormValid()) return

    this.updateUser()
    //this.changePassword()
  }

  isFormValid() {
    const { emailAddress } = this.state

    // Validates if email has a valid format.
    if (!emailRegex.test(emailAddress)) {
      CustomNotificationManager.error('Your email does not have a valid format', 'Error')
      return false
    }

    return true
  }
  updateNewUser = () => {
    const { valueMultiFactorAuthentication, valueMultiFactorDisabled, newPasswordValid, retypePasswordValid } = this.state
    let data = {}
    if (valueMultiFactorDisabled === false) {
      data = {
        userId: this.props.user.user_id,
        email: this.state.emailAddress,
        multi_factor_authentication: valueMultiFactorAuthentication,
        userMetadata: {
          department: this.state.department,
          emailFrequency: this.state.emailFrequency,
          name: this.state.name,
          nickname: this.state.nickname,
          middleName: this.state.middle_name,
          position: this.state.position
        },
        multipartFile: this.state.pictures ? this.state.pictures[0] : null,
      }
    } else {
      data = {
        userId: this.props.user.user_id,
        email: this.state.emailAddress,
        userMetadata: {
          department: this.state.department,
          emailFrequency: this.state.emailFrequency,
          name: this.state.name,
          nickname: this.state.nickname,
          middleName: this.state.middle_name,
          position: this.state.position
        },
        multipartFile: this.state.pictures ? this.state.pictures[0] : null,
      }
    }
    this.updateUserSettings(data, valueMultiFactorDisabled, valueMultiFactorAuthentication).then((response) => {
     // receiveUserInfo()
      if (response.data === 'Success') {
        this.props.fetchUserInfo()
        this.setState({
          isSending: false
        })
        CustomNotificationManager.success('Your data has been successfully updated', 'Successfully updated')
        setTimeout(
            function() {
              this.props.hideMessage()
              this.setState({
                readOnly: false
              })
            }
                .bind(this),
            this.state.popupTime
        )

        this.props.updateUserInfo({
          department: this.state.department,
          email: this.state.emailAddress,
          emailFrequency: this.state.emailFrequency,
          name: this.state.name,
          position: this.state.position,
          nickname: this.state.nickname,
          middle_name: this.state.middle_name,
        })
      }
      if (response.data.success) {
        this.setState({
          isSending: false
        })
        CustomNotificationManager.success('Your data has been successfully updated', 'Successfully updated')
        setTimeout(
            function() {
              this.props.hideMessage()
              this.setState({
                readOnly: false
              })
            }
                .bind(this),
            this.state.popupTime
        )

        this.props.updateUserInfo({
          department: this.state.department,
          email: this.state.emailAddress,
          emailFrequency: this.state.emailFrequency,
          name: this.state.name,
          position: this.state.position,
          nickname: this.state.nickname,
          middle_name: this.state.middle_name,
        })
      }
    }).catch((e) => {
      this.setState({
        isSending: false
      })
    })
  }
  updateUser() {
    const { valueMultiFactorAuthentication, valueMultiFactorDisabled, newPasswordValid, retypePasswordValid, currentPassword, newPassword, retypePassword } = this.state
    this.setState({
      isSending: true
    })
    let data = {}
    this.props.hideMessage()
    
    // if (newPasswordValid && retypePasswordValid) {
    //   this.changePassword()
    // } else if (this.state.responseChangePassword !== 'Invalid current or new password') {
    //   this.updateNewUser()
    // }
    if (currentPassword || newPassword || retypePassword) {
      this.changePassword()
    } else {
      this.updateNewUser()
    }

    // apiUserUpdate(data)
    //   .then((response) => {
    //     if (response.data.success) {
    //       this.setState({
    //         isSending: false
    //       })
    //       CustomNotificationManager.success('Your data has been successfully updated', 'Successfully updated')
    //       setTimeout(
    //         function() {
    //           this.props.hideMessage()
    //           this.setState({
    //             readOnly: false
    //           })
    //         }
    //           .bind(this),
    //         this.state.popupTime
    //       )
    //
    //       this.props.updateUserInfo({
    //         department: this.state.department,
    //         email: this.state.emailAddress,
    //         emailFrequency: this.state.emailFrequency,
    //         name: this.state.name,
    //         position: this.state.position,
    //         nickname: this.state.nickname,
    //         middle_name: this.state.middle_name,
    //       })
    //     }
    //   }).catch((e) => {
    //     this.setState({
    //       isSending: false
    //     })
    //   })
  }
  updateUserSettings = (data, valueMultiFactorDisabled, valueMultiFactorAuthentication) => {
    const obj = new FormData()
    let objData = {}
    // obj.append('userId', data.userId)
    // obj.append('email', data.email)
    // if (valueMultiFactorDisabled === false) {
    //   obj.append('multi_factor_authentication', valueMultiFactorAuthentication)
    // }
    // obj.append('userMetadata[department]', data.userMetadata.department)
    // obj.append('userMetadata[emailFrequency]', data.userMetadata.emailFrequency)
    // obj.append('userMetadata[name]', data.userMetadata.name)
    // obj.append('userMetadata[nickname]', data.userMetadata.nickname)
    // obj.append('userMetadata[middleName]', data.userMetadata.middleName)
    // obj.append('userMetadata[position]', data.userMetadata.position)
    // if (this.state.pictures) {
    //   obj.append('multipartFile', data.multipartFile)
    // }
    //obj.append('uid', data.uid)
    if (valueMultiFactorDisabled === false && !this.state.pictures) {
      objData = {
        userId:  data.userId,
        email:  data.email,
        'userMetadata[department]':  data.userMetadata.department,
        'userMetadata[emailFrequency]': data.userMetadata.emailFrequency,
        'userMetadata[name]': data.userMetadata.name,
        'userMetadata[nickname]': data.userMetadata.nickname,
        'userMetadata[middleName]': data.userMetadata.middleName,
        'userMetadata[position]': data.userMetadata.position,
        'multi_factor_authentication': valueMultiFactorAuthentication,
      }
    } else if (valueMultiFactorDisabled === false && this.state.pictures) {
      objData = {
        userId:  data.userId,
        email:  data.email,
        'userMetadata[department]':  data.userMetadata.department,
        'userMetadata[emailFrequency]': data.userMetadata.emailFrequency,
        'userMetadata[name]': data.userMetadata.name,
        'userMetadata[nickname]': data.userMetadata.nickname,
        'userMetadata[middleName]': data.userMetadata.middleName,
        'userMetadata[position]': data.userMetadata.position,
        'multi_factor_authentication': valueMultiFactorAuthentication,
        'multipartFile': data.multipartFile,
      }
    } else if (this.state.pictures) {
      objData = {
        userId:  data.userId,
        email:  data.email,
        'userMetadata[department]':  data.userMetadata.department,
        'userMetadata[emailFrequency]': data.userMetadata.emailFrequency,
        'userMetadata[name]': data.userMetadata.name,
        'userMetadata[nickname]': data.userMetadata.nickname,
        'userMetadata[middleName]': data.userMetadata.middleName,
        'userMetadata[position]': data.userMetadata.position,
        'multipartFile': data.multipartFile,
      }
    } else {
      objData = {
        userId:  data.userId,
        email:  data.email,
        'userMetadata[department]':  data.userMetadata.department,
        'userMetadata[emailFrequency]': data.userMetadata.emailFrequency,
        'userMetadata[name]': data.userMetadata.name,
        'userMetadata[nickname]': data.userMetadata.nickname,
        'userMetadata[middleName]': data.userMetadata.middleName,
        'userMetadata[position]': data.userMetadata.position,
      }
    }
    const boundary = '----WebKitFormBoundary7MA4YWxkTrZu0gW';
    const body = `--${boundary}\r\nContent-Disposition: form-data; name="key1"\r\n\r\nvalue1\r\n--${boundary}\r\nContent-Disposition: form-data; name="key2"\r\n\r\nvalue2\r\n--${boundary}--`;
    return this.axiosClone({
          method: 'post',
          url: `${ host }/osprey-0.1.0/v1/user/update`,
          data: objData,
          body: body,
          headers: {
            'Content-Type': `multipart/form-data; boundary=${boundary}`
          },
        }
    ).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
      this.setState({
        isSending: false
      })
    })
  }
  onChangeMultiFactor = (e) => {
    this.setState({
      valueMultiFactorAuthentication: e.target.checked,
      visibleFactorRender: false
    })
    if (e.target.checked === false) {
      this.setState({
        activeMultiFactor: true,
        visibleSectionScanApp: false,
        disabledSaveChange: true,
      })
    } else {
      this.setState({
        activeMultiFactor: false,
        disabledSaveChange: false
      })
    }
  }
  onResetMFA = () => {
    const { user } = this.props
    const resetMfaUserDTO = {
      userId: user.id
    }
    apiResetMfaUser(resetMfaUserDTO).then((response) => {
      if (response.data.title && response.data.title.toLowerCase() === 'success') {
        CustomNotificationManager.success(response.data.message, 'Success')
      } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
        CustomNotificationManager.info(response.data.message, 'Info')
      }
      this.setState({
        secret: response.data.secret,
        imageScan: response.data.qrCodeImage
      })
    })
    this.setState({
      visibleSectionScanApp: true,
      activeMultiFactor: false
    })
  }
  updateDigitPasscodeDisabled(e) {
    this.setState({
      valueDigitPasscodeDisabled: e.target.value
    })
  }
  updateDigitPasscode(e) {
    this.setState({
      valueDigitPasscode: e.target.value
    })
  }
  doLoginAuthentication(e) {
    const { valueDigitPasscode, secret } = this.state
    const { user } = this.props
    const resetMfaUserDTO = {
      userId: user.id,
      code: valueDigitPasscode,
      secret: secret
    }
    apiResetMfaUser(resetMfaUserDTO).then((response) => {
      if (response.data.title && response.data.title.toLowerCase() === 'success') {
        CustomNotificationManager.success(response.data.message, 'Success')
      } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
        CustomNotificationManager.info(response.data.message, 'Info')
      }
    }).catch((e) => {
      if (e?.response?.data) {
        CustomNotificationManager.info(`${ e.response.data }`, 'Cancelled')
      }else{
        console.log(e)
      }
    })
  }
  doLoginAuthenticationDisabled(e) {
    const { valueDigitPasscodeDisabled } = this.state
    const { user } = this.props
    const disableMfaUser = {
      userId: user.id,
      code: valueDigitPasscodeDisabled
    }
    apiDisabledMfaUser(disableMfaUser).then((response) => {
      if (response.data.title && response.data.title.toLowerCase() === 'success') {
        CustomNotificationManager.success(response.data.message, 'Success')
      } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
        CustomNotificationManager.info(response.data.message, 'Info')
      }
      this.setState({
        statusDisabledMfaUser: response.data,
        valueMultiFactorAuthentication: false,
        disabledSaveChange: false
      })
    }).catch((e) => {
      if (e?.response?.data) {
        CustomNotificationManager.info(`${ e.response.data }`, 'Cancelled')
      }else{
        console.log(e)
      }
      this.setState({
        valueMultiFactorAuthentication: true
      })
    })
  }
  onDrop(pictures, done) {
    if (this.imageUpload &&
        (Object.keys(pictures).length === 0 ||
        this.imageUpload.state.notAcceptedFileSize?.length ||
        this.imageUpload.state.notAcceptedFileType?.length
      )){

      // buildImageErrorNotification(this.imageUpload)

      this.imageUpload.setState({
        notAcceptedFileType: [],
        notAcceptedFileSize: []
      })

    } else {
      this.setState({
        pictures: this.state.pictureImg.concat(pictures),
        showPicture: done })

      if (done.length > 1) {
        this.setState({
          showPicture: done[done.length -1]
        })
      }
    }
  }
  
  renderPasswordConditions = (field) => {
    const { conditionsPassword, conditionsRepeatPassword} = this.state
    let conditions = conditionsPassword
    if (field === 'repeatPassword') {
      conditions = conditionsRepeatPassword
    }
    return (
        <div className="tooltip-wrapper">
          <p className="tooltip-wrapper__title">
            { field === 'repeatPassword' ? 'This field must' : 'The password must contain' }
          </p>
          { conditions && conditions.length > 0 && conditions.map((item, index) => {
            return (
                <p key={index} className={item.res ? 'tooltip-wrapper__item-green' : 'tooltip-wrapper__item-red' }>
                  <Icon type={ item.res ? 'check-circle' : 'close-circle'} />
                  { item.msg }
                </p>
            )
          }) }
        </div>
    )
  }
  updateRepeatPasswordStrengthCheckers = (value) => {
    const conditionsRepeatPassword = []
    const { newPassword } = this.state
    conditionsRepeatPassword[0] = { res: false, msg: 'to not be empty' }
    if (value.length) {
      conditionsRepeatPassword[0]['res'] = true
    }
    conditionsRepeatPassword[1] = { res: false, msg: 'to be equal' }
    if (value === newPassword) {
      conditionsRepeatPassword[1]['res'] = true
    }
    let retypePasswordValid = true
    if (conditionsRepeatPassword && conditionsRepeatPassword.length) {
      conditionsRepeatPassword.forEach((item) => {
        if (item.res === false) {
          retypePasswordValid = false
        }
      })
    }
    this.setState({ conditionsRepeatPassword, retypePasswordValid })
  }
  updatePasswprdStrengthCheckers = (upper, lower, number, special, minLength, value) => {
    const conditionsPassword = []
    if (upper) {
      conditionsPassword[0] = { res: false, msg: 'at least one uppercase letter', type: 'upper' }
      if (/[A-Z]/.test(value)) {
        conditionsPassword[0]['res']= true
      }
    }
    if (lower) {
      conditionsPassword[1] = { res: false, msg: 'at least one lowercase letter', type: 'lower' }
      if (/[a-z]/.test(value)) {
        conditionsPassword[1]['res']= true
      }
    }
    if (number) {
      conditionsPassword[2] = { res: false, msg: 'at least one number letter', type: 'number' }
      if (/[0-9]/.test(value)) {
        conditionsPassword[2]['res']= true
      }
    }
    if (special) {
      conditionsPassword[3] = { res: false, msg: 'at least one special letter', type: 'special' }
      if (/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(value)) {
        conditionsPassword[3]['res']= true
      }
    }
    if (minLength > 0) {
      conditionsPassword[4] = { res: false, msg: `at least ${ minLength } characters`, type: 'minLength' }
      if (value.length >= minLength) {
        conditionsPassword[4]['res'] = true
      }
    }

    let newPasswordValid = true
    if (conditionsPassword && conditionsPassword.length) {
      conditionsPassword.forEach((item) => {
        if (item.res === false) {
          newPasswordValid = false
        }
      })
    }
    this.setState({ conditionsPassword, newPasswordValid })
  }
  validateForm() {
    const { newPassword, retypePassword } = this.state
    let response = true
    if (newPassword !== retypePassword) {
      CustomNotificationManager.error('The passwords are not equal.', 'Error')
      response = false
      this.setState({}, () => { return response })
      return response
    }
    return response
  }
  changePassword = () => {
    const { currentPassword, newPassword, emailAddress } = this.state
    this.setState({
      isSending: true
    })
    this.props.hideMessage()

    const data = {
          email: emailAddress,
      currentPassword: currentPassword,
      password: newPassword
    }
    //if (this.validateForm()) {
      apiUserChangePassword(data)
          .then((response) => {
            this.setState({
              responseChangePassword: response.data
            })
            if (response.data === 'Invalid current or new password') {
              CustomNotificationManager.info('Invalid current or new password.')
              this.setState({
                isSending:false
              })
            } else if (response.data === 'Current & new password are the same') {
              CustomNotificationManager.info('Current & new password are the same.')
            } else {
              this.setState({
                isSending: false,
                currentPassword: '',
                newPassword: '',
                retypePassword: ''
              })
              CustomNotificationManager.success('Your password has been successfully updated.', 'Successfully updated')
              this.updateNewUser()
            }
          }).catch((e) => {
        const showError = getErrorMessage(e)[0] === 'Wrong email or password.' ? 'Wrond passowrd.' : getErrorMessage(e)
        CustomNotificationManager.error(showError, 'Error')
        this.setState({
          isSending: false
        })
      })
    //}
  }
  onClickDiscardChanges = () => {
    //this.props.navigate('/smart-box/management')
    this.setState({
      loadingDiscardChanges: true
    }, () => {
      window.location.replace('/smart-box/management')
      this.setState({
        loadingDiscardChanges: false
    })
    })
  }
  render() {
    const { department, emailAddress, isLoading, isSending, name, position, valueMultiFactorAuthentication, visibleSectionScanApp,
      imageScan, valueDigitPasscode, valueMultiFactorDisabled, activeMultiFactor,
      showPicture, middle_name, nickname, currentPassword, newPassword, retypePassword, loadingDiscardChanges } = this.state
    const { messageArea } = this.props
    return (
      <div className="userSettings-page userSettings-page-update">
        <div className="userSettings-box">
          <PageTitle title="Profile" />

          { isLoading &&
            <div className="userSettings-loading">
              <Loading />
            </div>
          }
        
          { !isLoading &&
            <form>
              <div className="userSettings-section userSettings-section-update">
                {/*<header>*/}
                {/*  <h1 className="userSettings-sectionTitle">About You</h1>*/}
                {/*  <h2 className="userSettings-sectionSubtitle">By letting us know your name we can make our support much more personal.</h2>*/}
                {/*</header>*/}

                <div className="userSettings-formGroup">
                  <label htmlFor="name">First name</label>
                  <input
                    id="name"
                    type="text"
                    value={ name }
                    disabled={ true }
                    onChange={ this.updateValue.bind(this, 'name') } />
                </div>
                <div className="userSettings-formGroup">
                  <label htmlFor="name">Middle name (optional)</label>
                  <input
                      id="middle_name"
                      type="text"
                      value={ middle_name }
                      disabled={ true }
                      onChange={ this.updateValue.bind(this, 'middle_name') } />
                </div>
                <div className="userSettings-formGroup">
                  <label htmlFor="name">Last name</label>
                  <input
                      id="nickname"
                      type="text"
                      value={ nickname }
                      disabled={ true }
                      onChange={ this.updateValue.bind(this, 'nickname') } />
                </div>
              </div>

              <div className="userSettings-section">
                {/*<header>*/}
                {/*  <h1 className="userSettings-sectionTitle">Company Details</h1>*/}
                {/*  <h2 className="userSettings-sectionSubtitle">Tell us a little about what you do at work.</h2>*/}
                {/*</header>*/}

                <div className="userSettings-container">
                  <div className="userSettings-formGroupSide">
                    <label htmlFor="position">Position</label>
                    <input
                      id="position"
                      type="text"
                      value={ position ? position : ''}
                      disabled={ true }
                      onChange={ this.updateValue.bind(this, 'position') } />
                  </div>
                  
                  <div className="userSettings-formGroupSide">
                    <label htmlFor="department">Department</label>
                    <input
                      id="department"
                      type="text"
                      value={ department ? department : ''}
                      disabled={ true }
                      onChange={ this.updateValue.bind(this, 'department') } />
                  </div>
                  <div className="userSettings-formGroupSide">
                    <label htmlFor="department">Email</label>
                    <input
                        id="email"
                        type="text"
                        value={ emailAddress }
                        disabled={ true }
                        onChange={ this.updateValue.bind(this, 'emailAdress') } />
                  </div>
                </div>
              </div>

              <div>
                <h4 className="upload-title"><span className="styleSpan1">Profile image</span></h4>
                <div className="newQuestionnaire__formGroup styleUploadImage ">
                  <div className="float-left m-3 ">
                    <img id="field_picture" className="teamIconImage " src={ showPicture } alt="" />
                  </div>
                  <div className="float-left m-3">
                    <ImageUploader
                        className="fileUpload"
                        withIcon={false}
                        label=''
                        labelStyle="styleLabelImageUpload"
                        buttonText='Choose image'
                        buttonClassName="buttonClassChange"
                        onChange={this.onDrop}
                        imgExtension={['.jpg', '.png', '.jpeg']}
                        maxFileSize={3145728}
                        withPreview={false}
                        imgPreview={false}
                        singleImage={true}
                        fileSizeError= "File size is too big"
                        errorStyle={{ color: 'blue', fontSize:'20px' }}
                        ref={(ref) => this.imageUpload = ref}
                    />
                  </div>
                </div>
              </div>
              {/*<div className="userSettings-section">*/}
              {/*  <header>*/}
              {/*    <h1 className="userSettings-sectionTitle">Email Settings</h1>*/}
              {/*    /!*<h2 className="userSettings-sectionSubtitle">Change your email address and email notification settings.</h2>*!/*/}
              {/*  </header>*/}

              {/*  <div className="userSettings-formGroup">*/}
              {/*    /!*<label htmlFor="email">Email address</label>*!/*/}
              {/*    <input*/}
              {/*      id="email"*/}
              {/*      type="text"*/}
              {/*      value={ emailAddress }*/}
              {/*      onChange={ this.updateValue.bind(this, 'emailAdress') } />*/}
              {/*  </div>*/}

              {/*  <div className="userSettings-formGroup">*/}
              {/*    <label htmlFor="email-frequency">Email summary frequency</label>*/}
              {/*    <select id="email-frequency" onChange={ this.updateValue.bind(this, 'emailFrequency') } value={ emailFrequency || '' }>*/}
              {/*      <option value="">choose...</option>*/}
              {/*      <option value="only urgent reminders">only urgent reminders</option>*/}
              {/*      <option value="all reminders">all reminders</option>*/}
              {/*    </select>*/}
              {/*  </div>*/}

              {/*  <footer>*/}
              {/*    <h2 className="userSettings-sectionSubtitle">Our email reports provide a summary of your account status and pending tasks.</h2>*/}
              {/*  </footer>*/}
              {/*</div>*/}

              <div className="userSettings-section">
                <header>
                  <h1 className="userSettings-sectionTitle userSettings-sectionTitle-password">Change Password</h1>
                </header>

                <div className="userSettings-container userSettings-container-password">
                  <div className="userSettings-formGroupSide">
                    <label htmlFor="current">Current password</label>
                    <input
                        id="current"
                        type="password"
                        value={ currentPassword }
                        onChange={ this.updateValue.bind(this, 'currentPassword') } />
                  </div>

                  <div className="userSettings-formGroupSide">
                    <label htmlFor="new">New password</label>
                    <Tooltip trigger={['focus', 'hover']} title={ this.renderPasswordConditions('password')} overlayClassName="tooltip-over" >
                      <input
                          id="new"
                          type="password"
                          value={ newPassword }
                          onChange={ this.updateValue.bind(this, 'newPassword') }
                      />
                    </Tooltip>
                  </div>

                  <div className="userSettings-formGroupSide">
                    <label htmlFor="retype">Retype password</label>
                    <Tooltip trigger={['focus', 'hover']} title={ this.renderPasswordConditions('repeatPassword')} overlayClassName="tooltip-over" >
                      <input
                          id="retype"
                          type="password"
                          value={ retypePassword }
                          onChange={ this.updateValue.bind(this, 'retypePassword') }
                      />
                    </Tooltip>
                  </div>
                </div>

                {/*<Link className="userSettings-sectionButton" to={{*/}
                {/*  pathname: '/osp/user/settings/change-password',*/}
                {/*  state: { email: emailAddress }*/}
                {/*}}>I want to change my password</Link>*/}
              </div>
              <div className="userSettings-section">
                <header>
                  <h1 className="userSettings-sectionTitle">Multi Factor Authentication</h1>
                </header>
                <div className="content-multi-factor-authentication">
                  <div className="checkbox-multi-factor">
                    <Checkbox checked={ valueMultiFactorDisabled === true ? valueMultiFactorDisabled : valueMultiFactorAuthentication } onChange={ this.onChangeMultiFactor }
                      disabled={ valueMultiFactorDisabled === true }
                      className="send-form" ><span><span className="name-factor">Multi Factor Authentication</span>
                        <Tooltip overlayClassName="information-app-user-settings" title={ <div>
                          <div className="header-authentication-info">
                            <div className="authentication-info"><span className="icon-v12-question"></span><span className="text-header">Authentication App (TOTP)</span></div>
                            <div><span className="text-close">X</span></div>
                          </div>
                          <div className="content-authentication-info">
                            <span><span className="text-information">To enable app-based two factor authentication (2FA) using an authentication app like</span>
                              <span className="text-link" onClick={ () => window.open('https://support.google.com/accounts/answer/1066447?co=GENIE.Platform%3DAndroid&hl=en', '_blank') }> Google Autenticator </span><span className="text-information">or</span>
                              <span className="text-link" onClick={ () => window.open('https://authy.com/download/', '_blank') }> Authy </span>
                              <span className="text-information">, click enable ans scan the QR code with the app on your phone.</span></span>
                          </div>
                          <div>
                            <span className="text-link-footer" onClick={ () => window.open('https://en.wikipedia.org/wiki/Time-based_One-Time_Password', '_blank') }> How does TOTP work? </span>
                          </div>
                        </div> }>
                          <span className="icon-v12-question"></span>
                        </Tooltip>
                      </span></Checkbox>
                  </div>
                  { (valueMultiFactorAuthentication || valueMultiFactorDisabled) &&
                    <div className="content-button-reset-MFA">
                      <Button className="button-blue-reset" onClick={ this.onResetMFA }>
                        Reset MFA
                      </Button>
                    </div>
                  }
                </div>
                { visibleSectionScanApp && !activeMultiFactor ?
                  <div className="content-app-user-settings">
                    <div className="content-app content-app-user">
                      <div className="content-app-text-scan">
                        <span className="text-app">1. Open your authentication app.</span>
                        <Tooltip overlayClassName="information-app-user-settings" title={ <div>
                          <div className="header-authentication-info">
                            <div className="authentication-info"><span className="icon-v12-question"></span><span className="text-header">Authentication App (TOTP)</span></div>
                            <div><span className="text-close">X</span></div>
                          </div>
                          <div className="content-authentication-info">
                            <span><span className="text-information">To enable app-based two factor authentication (2FA) using an authentication app like</span>
                              <span className="text-link" onClick={ () => window.open('https://support.google.com/accounts/answer/1066447?co=GENIE.Platform%3DAndroid&hl=en', '_blank') }> Google Autenticator </span><span className="text-information">or</span>
                              <span className="text-link" onClick={ () => window.open('https://authy.com/download/', '_blank') }> Authy </span>
                              <span className="text-information">, click enable ans scan the QR code with the app on your phone.</span></span>
                          </div>
                          <div>
                            <span className="text-link-footer" onClick={ () => window.open('https://en.wikipedia.org/wiki/Time-based_One-Time_Password', '_blank') }> How does TOTP work? </span>
                          </div>
                        </div> }>
                          <span className="icon-v12-question image-help"></span>
                        </Tooltip>
                      </div>
                      <div className="content-app-QRscan">
                        <span className="text-app">2. Scan this QR Code with the app.</span>
                      </div>
                      <div className="content-image-scan">
                        <img src={ imageScan } alt={ imageScan } className="image-scan-display"/>
                      </div>
                      <div className="content-enter-passcode">
                        <span className="text-app">3. Enter the 6-digit passcode from the in authentication app </span>
                        {/*<span className="text-app text-app2">authentication app</span>*/}
                      </div>
                      <div>
                        <span className="text-app text-app-digit">6-digit passcode</span>
                        <input className="input-authentication"
                          value={ valueDigitPasscode }
                          onChange={ (e) => this.updateDigitPasscode(e) }/>
                      </div>
                      <div className="loginPage-footer new-loginPage-footer login-user">
                        <Button
                          id="submit"
                          value="Authenticate"
                          className="button-blue-authentication"
                          disabled={ valueDigitPasscode === '' }
                          onClick={(e) => this.doLoginAuthentication(e) }>
                          Authenticate
                        </Button>
                      </div>
                    </div>
                  </div> :
                  // !visibleFactorRender && !visibleSectionScanApp && activeMultiFactor && !valueMultiFactorAuthentication && !valueMultiFactorDisabled ?
                  //   <div className="content-app-user-settings">
                  //     <div className="content-app content-app-user">
                  //       <div>
                  //         <span className="text-app text-app-digit">6-digit passcode</span>
                  //         <input className="input-authentication"
                  //           value={ valueDigitPasscodeDisabled }
                  //           onChange={ (e) => this.updateDigitPasscodeDisabled(e) }/>
                  //       </div>
                  //       <div className="loginPage-footer new-loginPage-footer login-user">
                  //         <Button
                  //           id="submit"
                  //           value="Authenticate"
                  //           className="button-blue-authentication"
                  //           disabled={ valueDigitPasscodeDisabled === '' }
                  //           onClick={(e) => this.doLoginAuthenticationDisabled(e)}>
                  //         Authenticate
                  //         </Button>
                  //       </div>
                  //     </div>
                  //   </div> :
                    <div></div>
                }
              </div>

              <footer className="userSettings-footer">
                {/*<Link className="userSettings-footerLink" to="/smart-box/management">Discard changes</Link>*/}
                <Button loading={ loadingDiscardChanges } onClick={(e) => this.onClickDiscardChanges(e)} className="userSettings-footerLink">Discard changes</Button>

                { !isSending &&
                  <div className="userSettings-footerLink-content">
                    { !messageArea.visible &&
                      <input
                        className="userSettings-footerButton"
                        disabled={ name === '' || emailAddress === '' }
                        type="submit"
                        value="Save changes"
                        onClick={ this.saveChanges } />
                    }

                    { messageArea.visible &&
                      <div className="userSettings-message">
                        <Message
                          hideMessageAction={ this.props.hideMessage }
                          status={ messageArea.status }
                          text={ messageArea.text } />
                      </div>
                    }
                  </div>

                }
                { isSending && !messageArea.visible &&
                  <div className={'loading-btn-wrapper'}>
                    <input
                        className="userSettings-footerButton loading-state"
                        type="submit"
                        value="Save changes"/>
                    <Spin className="save-changes-spinner" spinning={ isSending }
                          indicator={ <Icon type="loading" style={{ fontSize: 16, color:"#red"}} spin />}/>
                  </div>
                }
                {/*{ isSending &&*/}
                {/*  <Loading />*/}
                {/*}*/}
              </footer>
            </form>
          }
        </div>
      </div>
    )
  }
}

const stateMap = (state) => ({
  messageArea: state.messageAreaReducer.messageArea,
  user: state.userReducer.user,
  subscription: state.subscriptionReducer.subscription,
  smartBoxAxiosHeaders: state.smartBox.smartBoxAxiosHeaders,

})

const dispatchMap = (dispatch) => ({
  hideMessage: () => dispatch(messageActions.hideMessage()),
  showMessage: (status, text) => dispatch(messageActions.showMessage({ status, text })),
  updateUserInfo: (user) => dispatch(userActions.updateUserInfo(user)),
  fetchUserInfo: (user) => dispatch(userActions.fetchUserInfo(user)),
})

UserSettings.propTypes = {
  hideMessage: PropTypes.func.isRequired,
  messageArea: PropTypes.object.isRequired,
  showMessage: PropTypes.func.isRequired,
  updateUserInfo: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  subscription: PropTypes.object.isRequired,
}
export default compose(connect(stateMap, dispatchMap))(UserSettings)
