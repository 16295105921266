export const hasAlerts = (response) => {
  return (response.data &&
    response.data.value &&
    response.data.value.categories &&
    response.data.value.categories.category)
}

export const mappedAlertsWithInformation = (alerts, collection = {}) => {
  return alerts.map((item) => ({
    collection: collection.name ? collection.name : '',
    conflicts: (item.params && item.params.fq && item.params.fq.length) > 0 ? item.params.fq.slice(1) : [],
    files: [],
    id: item.id,
    isFetchingInfo: true,
    level: (item.properties && item.properties.level) ? item.properties.level : '',
    name: item.name ? item.name : ''
  }))
}

export const putFilesOnAlert = (response, alerts, id) => {
  return alerts.map((alert) => {
    if (alert.id === id) {
      const newDocumentList = alert.files.concat(response.data.documentList)

      return {
        ...alert,
        isFetchingInfo: false,
        files: newDocumentList,
        hasMoreFilesToShow: newDocumentList.length < response.data.pagination.total,
        isFetchingMoreFiles: false,
        totalObjects: response.data.pagination.total
      }
    }
    return alert
  })
}

export const findAlert = (alerts, id) => {
  const alert = alerts.filter((alert) => alert.id === id)
  return alert.length > 0 ? alert[0] : null
}
