import React from 'react'
import PropTypes from 'prop-types'

import '../Checkbox.css'

const Completed = ({ groups }) => (
  <div>
    { Object.keys(groups).map((group) => (
      <ul key={ group }>
        { group !== '__null' &&
          <h2 className="questionTypeCheckbox-title"><span>{ group.replace('__', '') }</span></h2>
        }
        <ul>
          { groups[group].map((option) => (
            <li key={ option.id }>
              <div className="questionTypeCheckbox-item">
                <div className="questionTypeCheckbox-group">
                  <div className="questionTypeCheckbox-checkboxDisabled">
                    <input
                      checked={ option.isSelected }
                      disabled={ true }
                      id={ option.id }
                      type="checkbox" />
                    <label
                      className={ getLabelClass(option.isSelected) }
                      htmlFor={ option.id }></label>
                  </div>
                  <span className="questionTypeCheckbox-optionTitle">{ option.title }</span>
                </div>
                {
                  option.isSelected &&
                  option.message &&
                  <span className="questionTypeCheckbox-optionMessage">{ option.message }</span>
                }
                { option.type === 'OTHER' &&
                  <textarea
                    className="questionTypeCheckbox-textarea"
                    disabled={ true }
                    value={ option.otherOptionText || '' }
                  ></textarea>
                }
              </div>
            </li>
          ))}
        </ul>
      </ul>
    ))
    }
  </div>
)

const getLabelClass = (isSelected) => {
  return isSelected ? 'questionTypeCheckbox-labelCheckedDisabled' : 'questionTypeCheckbox-labelDisabled'
}

Completed.propTypes = {
  groups: PropTypes.object.isRequired
}

export default Completed
