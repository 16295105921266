import React from 'react'
import { Table, Tooltip, Button } from 'antd'
import moment from 'moment'
import '../Admin.scss'
import Switch from 'antd/es/switch'
import Icon from 'antd/es/icon'
import {apiSendRequestTrackerInlineCustomer, switchUser} from '../../../api/Admin';
import withRouter from '../../../private-routers/withRouter'
import swal from "sweetalert2";
import CustomNotificationManager from "../../Common/CustomNotificationManager";
const { Column } = Table

class RequestTrackerUserTable extends React.Component {
    state= {
        pageNumber: 1,
        sorterColumnUser: '',
        sorterNameColumnUser: '',
        selectColumnUser: {},
        selectUser: {}
    }

    cancel = () => {
        this.setState({ editingKey: '' })
    };

    onPageChange = (page) => {
        this.cancel()

        this.setState({
            pageNumber: page
        }, () => { this.props.changePage(page) })
    }

    showTotalPages = (total) => {
        const { pageNumber } = this.state
        return (
            <div className="total-elements">
        <span className="pagination__box__header__total">
          <strong>{total}</strong> results
        </span>
                <span className="pagination__box__header__text">
              Page {pageNumber} of {Math.ceil(total / 10)}
        </span>
            </div>
        )
    }
    switchUser(record) {
        switchUser(record.key).then((response) => {
            const idToken = response.data
            // const accessToken = response.data
            var secondsToExp = Math.floor(new Date().getTime() / 1000);
            secondsToExp += (60*60*8)
            var tokenData = {'tokenId': idToken, 'expiresAt': secondsToExp}
            localStorage.setItem('tokenData', JSON.stringify(tokenData))
            // localStorage.setItem('accessToken', accessToken)
            window.location.reload()
        }).catch((err) =>{
        })
        return undefined;
    }
    onSorterColumnsUser = (e, column, sorter) => {
        const selectCol = {
            column: column,
            sorter: sorter
        }
        this.setState({
            sorterColumnUser: sorter,
            sorterNameColumnUser: column,
            selectColumnUser: selectCol
        })
        this.props.onSorterUserData(sorter, column)
    }
    onCLickSendRequest = (e, record) => {
        e.preventDefault()
        swal.fire({
            text: 'Send request?',
            //type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3DD8DB',
            cancelButtonColor: '#D33D33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                this.actionSendRequest(e, record)
            } else {
                this.setState({
                    selectUser: {}
                })
            }
        })
    }
    actionSendRequest = (e, record) => {
        const { tenantSelect } = this.props
        const isLocalhost = () => {
            return window.location.hostname === 'localhost'
        }
        const host = isLocalhost() ? 'http://localhost:8080' : ''
        const sendInlineRequestTrackerFormInputDTO = {
            userId: record.key,
            clientId: tenantSelect.key,
            host: window.location.origin === 'http://localhost:3000' ? 'http://localhost:8080' : window.location.origin
        }
        apiSendRequestTrackerInlineCustomer(sendInlineRequestTrackerFormInputDTO).then((response) => {
            if(response.data.title.toLowerCase() === 'success') {
                CustomNotificationManager.success(response.data.message, 'Success')
            } else if(response.data.title.toLowerCase() === 'info') {
                CustomNotificationManager.info(response.data.message, 'Info')
            }
        })
      this.setState({
          selectUser: record
      })
    }
    render() {
        const { users, tenantSelect } = this.props
        const { selectColumnUser, selectUser } = this.state
        return (
            <Table dataSource={users} className="custom-table custom-table-client"
                   scroll={{ x: 1500 }}
                   pagination={{
                       onChange: this.onPageChange,
                       pageSize: 10,
                       showTotal: this.showTotalPages,
                       className: 'annotation-pagination',
                       showLessItems: true,
                       total: this.props.totalElements,
                   }}>
                <Column title= { <span className="content-header-column"><span>Full Name</span>
                 <span className="content-buttons-sorter">
                      <span className={ selectColumnUser && selectColumnUser.column === 'name' && selectColumnUser.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                            onClick={(e) => this.onSorterColumnsUser(e, 'name', 'asc')}></span>
                     <span className={ selectColumnUser && selectColumnUser.column === 'name' && selectColumnUser.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                           onClick={(e) => this.onSorterColumnsUser(e, 'name', 'desc')}></span>
                 </span>
                </span>}
                        className="content-full-name-table" dataIndex="fullName" key="fullName" fixed="left" width={150} render={(text, record) => {
                    return (<span> {`${ text ? text : '' }`} </span>)
                }}/>
                <Column
                    title= { <span className="content-header-column"><span>Osprey role</span>
                 <span className="content-buttons-sorter">
                      <span className={ selectColumnUser && selectColumnUser.column === 'osprey-role' && selectColumnUser.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                            onClick={(e) => this.onSorterColumnsUser(e, 'osprey-role', 'asc')}></span>
                     <span className={ selectColumnUser && selectColumnUser.column === 'osprey-role' && selectColumnUser.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                           onClick={(e) => this.onSorterColumnsUser(e, 'osprey-role', 'desc')}></span>
                 </span>
                </span>}
                    dataIndex="ospreyRole" key="ospreyRole" width={100} render={(text) => {
                    return (<span className={text !== 'user' ? 'role-admin': ''}> {text} </span>)
                }}
                />
                <Column
                    title= { <span className="content-header-column"><span>Dpia role</span>
                 <span className="content-buttons-sorter">
                      <span className={ selectColumnUser && selectColumnUser.column === 'dpia-role' && selectColumnUser.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                            onClick={(e) => this.onSorterColumnsUser(e, 'dpia-role', 'asc')}></span>
                     <span className={ selectColumnUser && selectColumnUser.column === 'dpia-role' && selectColumnUser.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                           onClick={(e) => this.onSorterColumnsUser(e, 'dpia-role', 'desc')}></span>
                 </span>
                </span>}
                    dataIndex="dpiaRole" key="dpiaRole" width={100} render={(text) => {
                    return (<span className={text !== 'user' ? 'role-admin': ''}> {text} </span>)
                }}
                />
                <Column
                    title= { <span className="content-header-column"><span>Email</span>
                 <span className="content-buttons-sorter">
                      <span className={ selectColumnUser && selectColumnUser.column === 'email' && selectColumnUser.sorter === 'asc' ? "icon-v16-arrow-bold rotate-arrow active-sort" : "icon-v16-arrow-bold rotate-arrow" }
                            onClick={(e) => this.onSorterColumnsUser(e, 'email', 'asc')}></span>
                     <span className={ selectColumnUser && selectColumnUser.column === 'email' && selectColumnUser.sorter === 'desc' ? "icon-v16-arrow-bold active-sort" : "icon-v16-arrow-bold" }
                           onClick={(e) => this.onSorterColumnsUser(e, 'email', 'desc')}></span>
                 </span>
                </span>}
                    dataIndex="email" key="email" width={200}/>
                <Column
                    className="content-send-table"
                    title= { <span className="content-header-column"> </span> }
                    dataIndex="key" key="key" width={200} render={(text, record) => {
                        return (
                            <Button onClick={(e) => this.onCLickSendRequest(e, record)} className="send-request">Send</Button>
                        )
                }}/>
            </Table>
        )
    }
}

export default withRouter(RequestTrackerUserTable)