import { isLocalhost } from '../utils'

import * as Authentication from './OspreyAnalytics/Authentication'
import * as Categories from './OspreyAnalytics/Categories'
import * as DataModels from './OspreyAnalytics/DataModels'
import * as DataLines from './OspreyAnalytics/DataLines'
import * as Index from './OspreyAnalytics/Index'
import * as System from './OspreyAnalytics/System'
import * as Annotators from './OspreyAnalytics/Annotators'

const host = isLocalhost() ? 'http://localhost:8080' : ''

export const apiOspreyAuthenticate = () => Authentication.apiOspreyAuthenticate(host)
export const apiOspreyFetchToken = () => Authentication.apiOspreyFetchToken(host)
export const apiOspreyGetMsgraphUrl = () => Authentication.apiOspreyGetMsgraphUrl(host)

export const apiOspreyGetCategoryChildren = (...args) => Categories.apiOspreyGetCategoryChildren(host, ...args)
export const apiOspreyGetCategory = (...args) => Categories.apiOspreyGetCategory(host, ...args)
export const apiOspreyRenameCategory = (...args) => Categories.apiOspreyRenameCategory(host, ...args)
export const apiOspreyGetDocumentsForCategory = (...args) => Categories.apiOspreyGetDocumentsForCategory(host, ...args)
export const apiOspreyGetDocumentForCategory = (...args) => Categories.apiOspreyGetDocumentForCategory(host, ...args)
export const apiOspreyDeleteCategory = (...args) => Categories.apiOspreyDeleteCategory(host, ...args)
export const apiOspreyCreateCategory = (...args) => Categories.apiOspreyCreateCategory(host, ...args)
export const apiOspreySaveCategory = (...args) => Categories.apiOspreySaveCategory(host, ...args)
export const apiOspreyTrainCategory = (...args) => Categories.apiOspreyTrainCategory(host, ...args)
export const apiOspreyCategoryGetProcessData = (...args) => Categories.apiOspreyCategoryGetProcessData(host, ...args)
export const apiOspreyCategoryCluster = (...args) => Categories.apiOspreyCategoryCluster(host, ...args)
export const apiOspreyRemoveDocumentFromCategoryID = (...args) => Categories.apiOspreyRemoveDocumentFromCategoryID(host, ...args)
export const apiOspreyAddDocumentToCategoryID = (...args) => Categories.apiOspreyAddDocumentToCategoryID(host, ...args)
export const apiOspreySetCategoryProperty = (...args) => Categories.apiOspreySetCategoryProperty(host, ...args)

export const apiOspreyGetDataModelTaxonomies = (...args) => Categories.apiOspreyGetDataModelTaxonomies(host, ...args)
export const apiOspreyRenameTaxonomy = (...args) => Categories.apiOspreyRenameTaxonomy(host, ...args)

export const apiOspreyGetDataLines = () => DataLines.apiOspreyGetDataLines(host)
export const apiOspreyGetDataLineInfo = (...args) => DataLines.apiOspreyGetDataLineInfo(host, ...args)
export const apiOspreyStartDataLine = (...args) => DataLines.apiOspreyStartDataLine(host, ...args)
export const apiOspreyStopDataLine = (...args) => DataLines.apiOspreyStopDataLine(host, ...args)
export const apiOspreyGetCrawlerProcessorTemplateConfigurations = () => DataLines.apiOspreyGetCrawlerProcessorTemplateConfigurations(host)
export const apiOspreyGetCrawlerConfiguration = (...args) => DataLines.apiOspreyGetCrawlerConfiguration(host, ...args)

export const apiOspreyGetDataModels = () => DataModels.apiOspreyGetDataModels(host)
export const apiOspreyGetDataModelQuery = (...args) => DataModels.apiOspreyGetDataModelQuery(host, ...args)
export const apiOspreyGetDataModelStats = (...args) => DataModels.apiOspreyGetDataModelStats(host, ...args)
export const apiOspreyGetDataModelDocument = (...args) => DataModels.apiOspreyGetDataModelDocument(host, ...args)
export const apiOspreyGetDataModelFacetValues = (...args) => DataModels.apiOspreyGetDataModelFacetValues(host, ...args)
export const apiOspreyAddMetadataBlacklist = (...args) => DataModels.apiOspreyAddMetadataBlacklist(host, ...args)
export const apiOspreyGetMetadataBlacklist = (...args) => DataModels.apiOspreyGetMetadataBlacklist(host, ...args)
export const apiOspreyGetTrainingBlacklist = (...args) => DataModels.apiOspreyGetTrainingBlacklist(host, ...args)
export const apiOspreyAddTrainingBlacklist = (...args) => DataModels.apiOspreyAddTrainingBlacklist(host, ...args)

export const apiOspreyDeleteQuery = (...args) => Index.apiOspreyDeleteQuery(host, ...args)
export const apiOspreyFlush = () => Index.apiOspreyFlush(host)

export const apiOspreyAddAutoAnnotateProcess = (...args) => Annotators.addAutoAnnotateProcess(host, ...args)
export const apiOspreyRemoveAutoAnnotateProcess = (...args) => Annotators.removeAutoAnnotateProcess(host, ...args)
export const apiOspreyRunCategoryAnnotator = (...args) => Annotators.runCategoryAnnotator(host, ...args)

export const apiOspreyGetSystemPropertyValue = (...args) => System.apiOspreyGetSystemPropertyValue(host, ...args)
export const apiOspreySetSystemPropertyValue = (...args) => System.apiOspreySetSystemPropertyValue(host, ...args)

export const apiOspreyTerminateProcess = (...args) => System.apiOspreyTerminateProcess(host, ...args)
