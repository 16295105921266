import React from 'react'
import { renderTreeNodesFolderRedaction } from '../../../../utils/SmartBox/SmartBoxMethods'
import { Tree } from 'antd'
import { detailedTreeSettings } from '../../../../api/SmartBox'
import { connect } from 'react-redux'
import { setSmartBoxFetchTreeData } from '../../../../actions/smartBoxActions'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import CustomNotificationManager from "../../../Common/CustomNotificationManager";

class SearchTree extends React.Component {
  
  state = {
    treeData: [],
    expandedKeys: ['select_all'],
    childrenKeysList: [],
    autoExpandParent: true,
    checkedKeysObjectRefs: [],
    checkedKeys: [],
    lastCategoryVisited: '',
    terms: [],
    annotationsSelected: [],
    allKeysChildren: [],
    selectedTerms: [],
    treeDisabled: false
  }

  componentDidMount() {
    this.fetchData()

    const { tab } = this.props
    if (tab === 'folder-insights') {
      this.fetchDataInsights()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { tab } = this.props
    if (tab === 'folder-redaction') {
      if (prevProps !== this.props && this.props.treeData.length && Object.keys(this.props.nephewData).length) {
        this.fetchData()
      }
    }
    if (prevProps !== this.props && tab === 'folder-insights') {
      this.fetchDataInsights()
    }
  }

  fetchDataInsights = () => {
    const { expandedKeys, checkedKeys } = this.state
    const { tab } = this.props
    if (tab === 'folder-insights') {
      this.setState({
        treeData: this.props.treeForSettings
      }, () => {
        let allKeys = []
        const checkKeyParents = []
        const expandKeysAction = []
        this.state.treeData.forEach((treeChild) => {
          expandKeysAction.push(treeChild.key)
          treeChild.children && treeChild.children.map((children) => {
            if (children.selected === true) {
              expandKeysAction.push(children.key)
              checkKeyParents.push(children.key)
            }
            children && children.children.map((child) => {
              if (child.selected === true) {
                checkKeyParents.push(child.key)
                expandKeysAction.push(child.key)
              }
              return true
            })
            return true
          })
        })

        this.setState({
          expandedKeys: expandedKeys.concat(expandKeysAction),
          checkedKeys: checkedKeys.concat(checkKeyParents)
        })

        this.state.treeData && this.state.treeData.length > 0 && this.state.treeData.forEach((treeChild) => {
          const treeChildren = treeChild && treeChild.children && treeChild.children.length > 0 ? treeChild.children.map((child) => child.children) : []
          allKeys = [...allKeys, ...[].concat.apply([], treeChildren).map((grandChild) => grandChild.key)]
        })

        if (this.props.tab === 'folder-redaction') {
          this.props.setAllTags(allKeys)
        }
        this.props.setSmartBoxFetchTreeData(false)
      })
    }
  }

  fetchData = () => {
    const { checkedKeys } = this.state
    let { childrenKeysList } = this.state
    const { tab } = this.props
    if (this.props.treeData && this.props.treeData.length) {
      this.setState({
        treeData: this.props.treeData
      }, () => {
        let allKeys = []
        const checkKeyParents = []
        const expandKeysAction = []
        this.state.treeData.forEach((treeChild) => {
          expandKeysAction.push(treeChild.key)
          treeChild.children && treeChild.children.map((children) => {
            if (children.selected === true) {
              expandKeysAction.push(children.key)
              checkKeyParents.push(children.key)
            }
            children && children.children.map((child) => {
              if (child.selected === true) {
                checkKeyParents.push(child.key)
                expandKeysAction.push(child.key)
              }
              if (child.children && child.children.length) {
                child.children.forEach((value) => {
                  if (value.selected === true) {
                    expandKeysAction.push(value.key)
                  }
                })
              }
              return true
            })
            return true
          })
        })

        childrenKeysList = this.extractChildrenKeysList()

        this.setState({
          checkedKeys: checkedKeys.concat(checkKeyParents),
          childrenKeysList
        }, () => {
          if (tab === 'folder-redaction') {
            this.addExpendedChildren()
          }
        })

        this.state.treeData && this.state.treeData.length > 0 && this.state.treeData.forEach((treeChild) => {
          const treeChildren = treeChild && treeChild.children && treeChild.children.length > 0 ? treeChild.children.map((child) => child.children) : []
          allKeys = [ ...allKeys, ...[].concat.apply([], treeChildren).map((grandChild) => grandChild.key)]
        })

        if (this.props.tab === 'folder-redaction') {
          this.props.setAllTags(allKeys)
        }
        this.props.setSmartBoxFetchTreeData(false)
      })
    }
  }

  extractChildrenKeysList = () => {
    const { treeData } = this.state
    const response = []
    if (treeData && treeData.length) {
      treeData.forEach((base) => {
        if (base.children && base.children.length) {
          base.children.forEach((parent) => {
            if (parent.children && parent.children.length) {
              parent.children.forEach((child) => {
                response.push(child.key)
              })
            }
          })
        }
      })
    }
    return response
  }

  addExpendedChildren = () => {
    const { childrenKeysList } = this.state
    let { checkedKeys } = this.state
    const { redactionMode } = this.props
    const allNephew = []
    if (Object.keys(this.props.nephewData).length) {
      childrenKeysList.forEach((item) => {
        const newData = []
        if (this.props.nephewData.hasOwnProperty(item)) {
          if (this.props.nephewData[item].length) {
            this.props.nephewData[item].forEach((value) => {
              allNephew.push(`${ item }_${ value.text }`)
              if (value.selected) {
                checkedKeys.push(`${ item }_${ value.text }`)
              }
              newData.push({
                title: value.text,
                key: `${ item }_${ value.text }`,
                identifier: 'category-nephew',
                categoryParent: null,
                folderCount: 0,
                documentCount: 0,
                found: value.count,
                selected: value.selected
              })
            })
          }
        }
        const newTreeData = this.updateTreeData(item, this.state.treeData, newData)
        if (allNephew.length) {
          this.props.setAllNephew(allNephew)
        }

        if (redactionMode === 'clear-all') {
          checkedKeys = []
        }
        this.setState({
          treeData: newTreeData,
          checkedKeys
        })
      })
    }
  }

  getNephewList = (item, seeAll) => {
    const { tab, selectedRedact } = this.props
    const data = {
      folderPath: this.props.folder.path,
      field: item.key,
      seeAll: seeAll,
      selectedRedact: tab === 'folder-redaction' ? selectedRedact : null
    }
    detailedTreeSettings(data).then((response) => {
      if (response.status === 200) {
        const data=[]
        const selectedTerms= []
        for (const key in response.data) {
          const selected = response.data[key].selected ? response.data[key].selected : item.selected
          data.push({ title: response.data[key].text, key: response.data[key].text, identifier: 'category-nephew', categoryParent: null, folderCount: 0, documentCount: 0, found: response.data[key].count, selected: selected })
        }
        const newTreeData = this.updateTreeData(item.key, this.state.treeData, data)
        data && data.map((term) => {
          if (term.selected === true) {
            selectedTerms.push(term.key)
          }
          return true
        })

        this.setState({
          treeData: newTreeData,
          terms: data,
          // checkedKeys: checkedKeys.concat(selectedTerms)
        })
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  updateTreeData = (key, node, data) => {
    const currentNodesCopy = [ ...node ]
    for (let i = 0; i < node.length; i++) {
      if (currentNodesCopy[i].key === key) {
        currentNodesCopy[i].children = data
      } else if (currentNodesCopy[i].children && currentNodesCopy[i].children.length) {
        currentNodesCopy[i].children = this.updateTreeData(key, currentNodesCopy[i].children, data)
      }
    }
    return currentNodesCopy
  }

  setLastCategoryVisited = (e, key, action) => {
    this.setState({ lastCategoryVisited: action === 'add' ? key : '' })
  }

  expandHideCategory = (e, item, action) => {
    e.stopPropagation()
    const key = item.key
    let { expandedKeys } = { ...this.state }
    this.props.setActionExpandParent(action)
    if (action === 'add') {
      this.props.setTreeData(e, item)
      expandedKeys.push(key)
    } else {
      expandedKeys = expandedKeys.filter((eKey) => eKey !== key)
    }
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    }, () => {
      this.setLastCategoryVisited(e, key, action)
    })
  }

  expandHideNephewCategory = (e, item, action, seeAll) => {
    const key = item.key
    e.stopPropagation()
    let { expandedKeys } = { ...this.state }
    if (action === 'add') {
      this.setLastCategoryVisited(e, key, action)
      expandedKeys.push(key)
      this.getNephewList(item, seeAll)
    } else {
      expandedKeys = expandedKeys.filter((eKey) => eKey !== key)
    }
    this.setState({
      expandedKeys,
      autoExpandParent: false
    })
  }

  expandAllParent = (e, item) => {
    const { treeData } = this.state
    e.stopPropagation()
    this.expandHideCategory(e, item, 'add')
    treeData.map((element) => {
      if (element.children && element.children.length> 0) {
        this.expandHideCategory(e, element, 'add')
      }
      return true
    })
  }
  
  onCheck = (checkedKeys, { checkedNodes }) => {
    const { terms, allKeysChildren, treeData } = this.state
    const termsSelected = []
    this.props.sendSaveKeys(checkedKeys)
    this.props.viewTreeDataAnalytic(treeData)
    terms && terms.map((ter) => {
      allKeysChildren.push(ter.title)
      checkedKeys && checkedKeys.map((check) => {
        if (ter.key === check) {
          termsSelected.push(ter.title)
        }
        return true
      })
      return true
    })
    treeData && treeData.length > 0 && treeData.forEach((item) => {
      if (item.title === 'Images') {
        if (item.found === -1 || item.folderCount === -1 || item.documentCount === -1) {
          checkedKeys = checkedKeys.filter((keysItem) => keysItem !== item.key)
        }
      }
    })
    checkedKeys && checkedKeys.length > 0 && checkedKeys.forEach((checkKeys) => {
      if (checkKeys === 'select_all') {
        treeData && treeData.length > 0 && treeData.forEach((item) => {
          if (item.title === 'Images') {
            if (item.found === -1 || item.folderCount === -1 || item.documentCount === -1) {
              checkedKeys = checkedKeys.filter((keysItem) => keysItem !== 'select_all')
            }
          }
        })
      }
    })
    this.props.viewChildren(checkedKeys, allKeysChildren)
    this.setState({
      checkedKeys,
      checkedKeysObjectRefs: checkedNodes.map((node) => node.props.dataRef).filter((obj) => obj.identifier === 'category-child')
    }, () => {
      this.props.setAnnotationsForRedact(checkedKeys)
    })
  }
  
  expandHideRow = ({ props }) => {
    const { dataRef } = props
    let { expandedKeys } = { ...this.state }
    const { folderInfoLoaded } = { ...this.state }
    if (expandedKeys.includes(dataRef.key)) {
      expandedKeys = expandedKeys.filter((eKey) => eKey !== dataRef.key)
    } else {
      expandedKeys.push(dataRef.key)
    }
    this.setState({
      expandedKeys,
      autoExpandParent: false
    }, () => {
      if (this.props.tab === 'folder-insights' && !folderInfoLoaded) {
        this.setState({
          folderInfoLoaded: true
        })
      }
    })
  }

  onExpand = (expandedKeys) => {
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    })
  }

  render() {
    const { autoExpandParent, lastCategoryVisited, expandedKeys, treeData, checkedKeys } = this.state
    const { tab, currentActiveParent, redactingStep, submitedSelectedRedact } = this.props
    let myTreeData = []
    if (tab === 'folder-redaction') {
      myTreeData.push({
        title: 'Select all',
        key: 'select_all',
        identifier: 'top',
        categoryParent: null,
        categoryGrandParent: null,
        folderCount: 0,
        documentCount: 0,
        found: 0,
        children: treeData
      })
    } else {
      myTreeData = treeData
    }
    if ((submitedSelectedRedact && treeData.length && tab === 'folder-redaction') || (treeData.length && tab === 'folder-insights')) {
      return (
        <Tree
          disabled={redactingStep === 1 && checkedKeys && checkedKeys.length ? true : false}
          checkable={tab !== 'folder-insights'}
          expandedKeys={expandedKeys}
          onExpand={this.onExpand}
          defaultExpandedKeys={expandedKeys}
          autoExpandParent={autoExpandParent}
          defaultExpandAll={true}
          onCheck={this.onCheck}
          checkedKeys={checkedKeys}
          selectable={false}
          className={tab === 'folder-insights' ? 'folder-insights-tree new-folder-insights-tree' : ''}
          onSelect={(selectedKeys, { node }) => {
            this.expandHideRow(node)
          }}
        >
          {renderTreeNodesFolderRedaction(
            myTreeData, expandedKeys,
            this.expandHideCategory,
            this.expandHideNephewCategory,
            this.props.setTreeData,
            currentActiveParent,
            lastCategoryVisited,
            this.expandAllParent,
            tab,
            treeData,
            submitedSelectedRedact)
          }
        </Tree>
      )
    } 
    return null
    
  }
}

SearchTree.propTypes = {
  treeData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  nephewData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  setAllNephew: PropTypes.func,
  selectedRedact: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  treeForSettings: PropTypes.array
}

const mapStateToProps = (state) => ({
  smartBoxFetchTreeData: state.smartBox.smartBoxFetchTreeData
})

export default compose(
  connect(mapStateToProps, { setSmartBoxFetchTreeData }),
)(SearchTree)