import React from 'react'
import {Button, Modal, Radio, Input, Tooltip} from 'antd'
import './NewBoxCreate.scss'
import imgFolderParent from '../../../../assets/images/Box negative 80x80.png'
import NewBoxAiPii from './NewBoxAiPii'
import NewBoxCustom from './NewBoxCustom'
import NewBoxConfiguration from './NewBoxConfiguration'
import NewBoxCustom2 from './NewBoxCustom2'
class NewBoxCreate extends React.Component {

  constructor(props) {
    super(props)
    this.state= {
      valueConfigurationBox: 'default configuration',
      valueNewBox: '',
      displayPiiAi: false,
      displaySetupCustom: false,
      displaySetupCustom2: false,
      displaySetupConfiguration: false,
      checkedKeysPii: [],
      listFinallyCreateBox: [],
      listAddNewBox: [],
      statusCreateAndNext: '',
      listNewBoxBusinessTerms: [],
      listNewBoxRegExp: [],
      selectedListRegExp: [],
      colorRegular: '',
      colorTerms: '',
      manualTermsList: [],
      selectedListManualTerms: [],
      sharingPermissionsInputNewDto: {},
      sharingPermissionsConfiguration: {},
      activePage: '',
      statusBackAiPii: false,
      statusBackBusiness: false,
      statusBackRegEx: false,
      loadingCancelCreate: false,
      loadingCreateBox: false,
      formData: {
        sharingAndPermissions: []
      },
      formDataDownload: {
        sharingAndPermissionsDownload: []
      },
    }
  }

  onChangeConfigurationBox(e) {
    this.setState({
      valueConfigurationBox: e.target.value
    })
  }
  onChangeNewBoxInput(e) {
    this.setState({
      valueNewBox: e.target.value
    })
  }
  onCreateBoxOrNextPage = (e, status) => {
    const { valueConfigurationBox, valueNewBox } = this.state
    e.stopPropagation()
    e.preventDefault()
    this.setState({
      loadingCreateBox: true
    }, () => {
      this.setState({
        statusCreateAndNext: status,
        loadingCreateBox: false
      })
      if (valueConfigurationBox === 'default configuration' && status === 'new-box') {
        this.props.onAddNewBox(valueNewBox)
        this.props.statusCancelModalNewBox(false)
      } else if (valueConfigurationBox === 'advanced configuration' && status === 'new-box') {
        this.setState({
          displayPiiAi: true
        })
      } else if (status === 'new-box-pii') {
        this.setState({
          displaySetupCustom: true,
          displaySetupCustom2: false,
          displayPiiAi: false
        })
      } else if (status === 'new-box-custom') {
        this.setState({
          displaySetupCustom: false,
          displayPiiAi: false,
          displaySetupCustom2: true,
          displaySetupConfiguration: false
        })
      } else if (status === 'new-box-custom-two') {
        this.setState({
          displaySetupCustom: false,
          displaySetupCustom2: false,
          displayPiiAi: false,
          displaySetupConfiguration: true
        })
      } else if (status === 'new-box-configuration') {
        //this.onCreateBoxFinally()
        //this.onGetValuesConfiguration()
        //this.props.onAddNewBox(valueNewBox)
      }
    })
  }
  onPressEnterCreateNewBox = (e, status) => {
    const { valueConfigurationBox, valueNewBox } = this.state
    if (valueConfigurationBox === 'default configuration' && status === 'new-box' && valueNewBox !== '') {
      this.props.onAddNewBox(valueNewBox)
      this.props.statusCancelModalNewBox(false)
    }
  }
  handleCancelModal = () => {
    this.props.statusCancelModalNewBox(false)
  }
   handleCancelBackPage = (e, status) => {
    this.setState({
      loadingCancelCreate: true
    }, () => {
      switch (status) {
        case 'cancel-new-box':
          this.props.statusCancelModalNewBox(false)
          break
        case 'back-ai':
          this.setState({
            displaySetupCustom: false,
            displayPiiAi: false,
            displaySetupConfiguration: false,
            displaySetupCustom2: false,
            loadingCancelCreate: false,
          })
          break
        case 'back-custom':
          this.setState({
            displaySetupCustom: false,
            displayPiiAi: true,
            displaySetupCustom2: false,
            displaySetupConfiguration: false,
            loadingCancelCreate: false,
            statusBackAiPii: true
          })
          break
        case 'back-custom-two':
          this.setState({
            displaySetupCustom: true,
            displayPiiAi: false,
            displaySetupConfiguration: false,
            displaySetupCustom2: false,
            loadingCancelCreate: false,
            statusBackBusiness: true,
            statusBackAiPii: true
          })
          break
        case 'back-configuration':
          this.setState({
            displaySetupCustom: false,
            displayPiiAi: false,
            displaySetupConfiguration: false,
            loadingCancelCreate: false,
            displaySetupCustom2: true,
            statusBackRegEx: true,
            statusBackAiPii: true
          })
          break
        default: break
        this.setState({
          loadingCancelCreate: false
        })
      }
    })
   }
   onCreateBoxFinally = () => {
     const { listFinallyCreateBox, valueConfigurationBox, valueNewBox, checkedKeysPii, statusCreateAndNext, listNewBoxBusinessTerms, listNewBoxRegExp,
       selectedListRegExp, colorRegular, colorTerms, manualTermsList, selectedListManualTerms, sharingPermissionsInputNewDto } = this.state
     listFinallyCreateBox.push({
       valueNewBox: valueNewBox,
       valueConfigurationBox: valueConfigurationBox,
       checkedKeysPii: checkedKeysPii,
       statusCreateAndNext: statusCreateAndNext,
       listNewBoxBusinessTerms: listNewBoxBusinessTerms,
       listNewBoxRegExp: listNewBoxRegExp,
       selectedListRegExp: selectedListRegExp,
       colorRegular: colorRegular,
       colorTerms: colorTerms,
       manualTermsList: manualTermsList,
       selectedListManualTerms: selectedListManualTerms,
       sharingPermissionsInputNewDto: sharingPermissionsInputNewDto
     })
     this.props.onAddNewBoxValuesFinally(listFinallyCreateBox)
     this.setState({
       listFinallyCreateBox
     }, () => {
       //this.props.onAddNewBoxValuesFinally(listFinallyCreateBox)
     })

   }
  onGetCheckKeysAiPii = (checkedKeys) => {
    this.setState({
      checkedKeysPii: checkedKeys
    })
  }
  onGetValuesCustom = (checkedBusiness, colorTerms, manualTermsList, selectedListManualTerms) => {
    this.setState({
      listNewBoxBusinessTerms: checkedBusiness,
      colorTerms: colorTerms,
      manualTermsList: manualTermsList,
      selectedListManualTerms: selectedListManualTerms
    })
  }
  onGetValuesCustomTwo = (regexList, selectedList, colorRegular) => {
    this.setState({
      listNewBoxRegExp: regexList,
      selectedListRegExp: selectedList,
      colorRegular: colorRegular,
    })
  }
  onGetValuesConfiguration = (sharingPermissionsInputNewDto) => {
    const { statusCreateAndNext } = this.state
    //this.onCreateBoxFinally()
    this.setState({
      sharingPermissionsInputNewDto: sharingPermissionsInputNewDto
    }, () => {
      this.onCreateBoxUpdate()
      // setTimeout(() => {
      //   //this.onCreateBoxFinally()
      // }, 2000);
    })
  }
  onCreateBoxUpdate() {
    const { listFinallyCreateBox, valueConfigurationBox, valueNewBox, checkedKeysPii, statusCreateAndNext, listNewBoxBusinessTerms, listNewBoxRegExp,
      selectedListRegExp, colorRegular, colorTerms, manualTermsList, selectedListManualTerms, sharingPermissionsInputNewDto } = this.state
    const listFinallyCreateBox2 = []
    listFinallyCreateBox2.push({
      valueNewBox: valueNewBox,
      valueConfigurationBox: valueConfigurationBox,
      checkedKeysPii: checkedKeysPii,
      statusCreateAndNext: statusCreateAndNext,
      listNewBoxBusinessTerms: listNewBoxBusinessTerms,
      listNewBoxRegExp: listNewBoxRegExp,
      selectedListRegExp: selectedListRegExp,
      colorRegular: colorRegular,
      colorTerms: colorTerms,
      manualTermsList: manualTermsList,
      selectedListManualTerms: selectedListManualTerms,
      sharingPermissionsInputNewDto: sharingPermissionsInputNewDto
    })
    this.props.onAddNewBoxValuesFinally(listFinallyCreateBox2)
    this.props.statusCancelModalNewBox(false)
}
  onBackValuesConfiguration = (sharingPermissionsConfiguration) => {
    this.setState({
      sharingPermissionsConfiguration: sharingPermissionsConfiguration
    })
  }
  onSavedGeneral = (formData, formDataDownload) => {
    this.setState({
      formData: formData,
      formDataDownload: formDataDownload,
    })
  }
  onCloseModal = (e) => {
    e.preventDefault()
    this.props.statusCancelModalNewBox(false)
  }
  render() {
    const { valueConfigurationBox, valueNewBox, displayPiiAi, displaySetupCustom, displaySetupConfiguration, checkedKeysPii, listNewBoxRegExp, selectedListRegExp,
      colorRegular, manualTermsList, selectedListManualTerms, colorTerms, sharingPermissionsConfiguration, displaySetupCustom2,
      statusBackAiPii, statusBackBusiness, statusBackRegEx, loadingCancelCreate, loadingCreateBox, formData, formDataDownload} = this.state
    return (
      <Modal
        title={ <div className="update-header-modal-new-box"><div className="content-header-modal-new-box"><img src={imgFolderParent} alt={imgFolderParent}/><span className="title-header">{ valueNewBox ? valueNewBox : 'New Box' }</span></div>
        <span className="icon-v18-close-black" onClick={(e) => this.onCloseModal(e)}></span></div> }
        visible={true}
        onCancel={this.handleCancelModal}
        footer={ null }
        closable={false}
        style={{width: '790px'}}
        className={ displayPiiAi || displaySetupCustom || displaySetupCustom2 || displaySetupConfiguration ? 'new-box-modal-settings new-box-modal-settings2' : 'new-box-modal-settings' }
      >
        <div className='settings-modal-new-box'>
          { !displayPiiAi && !displaySetupCustom && !displaySetupConfiguration && !displaySetupCustom2 ?
            <div className="box-setup-new-box">
              <div className="box-setup-new-box__content-new-box">
                <div className="content-box-option">
                  <div className="content-box-name">
                    <div className="title-box-name">Box name</div>
                    <div className="content-input-new-box">
                      <div className="row">
                        <div className="col-md-3">
                          <Input autoFocus={ true } value={ valueNewBox } onChange={ (e) => this.onChangeNewBoxInput(e) }
                            onPressEnter={ (e) => this.onPressEnterCreateNewBox(e, 'new-box') } placeholder="New Box Name"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="content-box-configuration">
                    <div className="title-box-configuration">Box configuration
                      <Tooltip overlayClassName="help-message-locations" title={<div className="content-help-message-locations">
                        <span className="text-information">Use default Box settings or configure them manually.</span>
                        <span className="more-info-link" onClick={ () => window.open('https://support.smartbox.ai/support/solutions/articles/47001174212-create-a-box', '_blank') }> More info. </span>
                      </div>}>
                        <span className="icon-v14-question"></span>
                      </Tooltip>
                    </div>
                    <div className="content-radio-configuration">
                      <div className="row">
                        <div className="col-md-3">
                          <Radio.Group value={ valueConfigurationBox } onChange={ (e) => this.onChangeConfigurationBox(e) }>
                            <Radio value="default configuration"><span><span className="title-radio">Default configuration</span>
                              {/*<span className="descriptions-radio">Quick start to process your data, setup any conditions later</span>*/}
                            </span></Radio>
                            <Radio value="advanced configuration"><span><span className="title-radio">Advanced configuration</span>
                              {/*<span*/}
                              {/*  className="descriptions-radio">Setup your box, guided step by step.</span>*/}
                            </span></Radio>
                          </Radio.Group>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-buttons-action-new-box">
                <Button loading={loadingCancelCreate} className="action-cancel-new-box" onClick={(e) => this.handleCancelBackPage(e, 'cancel-new-box') }>
                  Cancel
                </Button>
                <Button loading={ loadingCreateBox } className="action-create-new-box" onClick={ (e) => this.onCreateBoxOrNextPage(e, 'new-box') }
                  disabled={ valueConfigurationBox === '' || valueNewBox === '' }
                >
                  { valueConfigurationBox === 'advanced configuration' ? 'Next' : 'Create' }
                </Button>
              </div>
            </div> : !displaySetupCustom && !displaySetupConfiguration && !displaySetupCustom2 ?
              <NewBoxAiPii onCreateBoxOrNextPage = { this.onCreateBoxOrNextPage }
                handleCancelBackPage = { this.handleCancelBackPage }
                onGetCheckKeysAiPii = { this.onGetCheckKeysAiPii }
                checkedKeysPii = { checkedKeysPii }
                           statusBackAiPii = { statusBackAiPii }
                           activePage = 'ai-pii'/> :
              !displaySetupConfiguration && !displaySetupCustom2 ?
                <NewBoxCustom onCreateBoxOrNextPage = { this.onCreateBoxOrNextPage }
                  handleCancelBackPage = { this.handleCancelBackPage }
                  onGetValuesCustom = { this.onGetValuesCustom }
                  listNewBoxRegExp = { listNewBoxRegExp }
                  selectedListRegExp = { selectedListRegExp }
                  colorRegular = { colorRegular }
                  manualTermsList = { manualTermsList }
                  selectedListManualTerms = { selectedListManualTerms }
                  colorTerms = { colorTerms }
                              statusBackBusiness = { statusBackBusiness }
                              activePage = 'custom'/> :
                  !displaySetupCustom && !displaySetupCustom && !displaySetupConfiguration ?
                      <NewBoxCustom2 onCreateBoxOrNextPage = { this.onCreateBoxOrNextPage }
                                    handleCancelBackPage = { this.handleCancelBackPage }
                                    //onGetValuesCustom = { this.onGetValuesCustom }
                                     onGetValuesCustomTwo = { this.onGetValuesCustomTwo }
                                    listNewBoxRegExp = { listNewBoxRegExp }
                                    selectedListRegExp = { selectedListRegExp }
                                    colorRegular = { colorRegular }
                                    manualTermsList = { manualTermsList }
                                    selectedListManualTerms = { selectedListManualTerms }
                                    colorTerms = { colorTerms }
                                     statusBackRegEx = { statusBackRegEx }
                                     activePage = 'custom2'/> :
                <NewBoxConfiguration
                  handleCancelBackPage = { this.handleCancelBackPage }
                  onCreateBoxOrNextPage = { this.onCreateBoxOrNextPage }
                  onGetValuesConfiguration = { this.onGetValuesConfiguration }
                  onBackValuesConfiguration={ this.onBackValuesConfiguration }
                  onSavedGeneral={ this.onSavedGeneral }
                  sharingPermissionsConfiguration={ sharingPermissionsConfiguration }
                  formData={ formData }
                  formDataDownload={ formDataDownload }
                  activePage = 'configuration'/>
          }
        </div>
      </Modal>
    )
  }
}

NewBoxCreate.propTypes = {
}

export default NewBoxCreate