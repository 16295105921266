export const categories = {
  addresses: [
    {
      tagName: 'Email',
      tag: 'doc_annotate_address_email'
    },
    {
      tagName: 'Address url',
      tag: 'doc_annotate_address_url'
    },
    {
      tagName: 'Phone',
      tag: 'doc_annotate_address_phone'
    },
    {
      tagName: 'Postcode',
      tag: 'doc_annotate_address_postcode'
    },
    {
      tagName: 'Address complete',
      tag: 'doc_annotate_address_complete'
    },
    {
      tagName: 'Addresses ip',
      tag: 'doc_annotate_address_ip'
    }
  ],
  names: [
    {
      tagName: 'Female names',
      tag: 'doc_annotate_person_female'
    },
    {
      tagName: 'Male names',
      tag: 'doc_annotate_person_male'
    },
    {
      tagName: 'Person',
      tag: 'doc_annotate_person'
    }
  ],
  identifiers: [
    {
      tagName: 'Identifier',
      tag: 'doc_annotate_identifier'
    },
    {
      tagName: 'Passwords',
      tag: 'doc_annotate_password'
    }
  ],
  locations: [
    {
      tagName: 'Countries',
      tag: 'doc_annotate_location_country'
    },
    {
      tagName: 'Cities',
      tag: 'doc_annotate_location_city'
    },
    {
      tagName: 'Regions',
      tag: 'doc_annotate_location_region'
    },
    {
      tagName: 'Airport',
      tag: 'doc_annotate_location_airport'
    },
    {
      tagName: 'Country abbrev',
      tag: 'doc_annotate_location_country_abbrev'
    },
    {
      tagName: 'Location county',
      tag: 'doc_annotate_location_county'
    },
    {
      tagName: 'Location province',
      tag: 'doc_annotate_location_province'
    },
    {
      tagName: 'Location other',
      tag: 'doc_annotate_location_other'
    },
    {
      tagName: 'Location pre',
      tag: 'doc_annotate_location_pre'
    }
  ],
  numbers: [
    {
      tagName: 'Money',
      tag: 'doc_annotate_money'
    },
    {
      tagName: 'Percents',
      tag: 'doc_annotate_percent'
    },
    {
      tagName: 'Social Security Numbers',
      tag: 'doc_annotate_ssn'
    },
    {
      tagName: 'Credit Cards',
      tag: 'doc_annotate_creditcard'
    }
  ],
  organisations: [
    {
      tagName: 'Company',
      tag: 'doc_annotate_organization_company'
    },
    {
      tagName: 'Department',
      tag: 'doc_annotate_organization_department'
    },
    {
      tagName: 'Government',
      tag: 'doc_annotate_organization_government'
    },
    {
      tagName: 'Newspaper',
      tag: 'doc_annotate_organization_newspaper'
    },
    {
      tagName: 'University',
      tag: 'doc_annotate_organization_university'
    },
    {
      tagName: 'Team',
      tag: 'doc_annotate_organization_team'
    }
  ],
  time: [
    {
      tagName: 'Dates',
      tag: 'doc_annotate_date'
    }
  ],
}