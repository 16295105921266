import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import '../FormEmployeeWorkflow.scss'
import {Input, Select, Steps} from "antd";
const { Option } = Select;
const { Step } = Steps;
const { TextArea } = Input

export class LongTextTemplateForm extends Component {

    state = {

    }
    onChangeNameAndDetails = (e, key) => {
        const { component } = this.props
        switch (key) {
            case 'long_text':
                this.props.onChangeNameAndDetails(e, key, component)
                break
            default:
                break
        }
    }
    render() {
        const { formerEmployee, component, stepTicketActive } = this.props
        return (
            <div className="full-name-former">
                <span className="header-former header-former-address">{component.header && component.mandatory ? `${component.header}*` :
                    component.header ? component.header : 'Long Text'}</span>
                <span className="input-content">
                    <TextArea className="input-form-name long-text-input"
                              onChange={(e) => this.onChangeNameAndDetails(e, 'long_text')}
                              rows={4}
                              disabled={stepTicketActive.blockFormEdit === true}
                              value={ component.longTextValue }/>
                </span>
            </div>
        )
    }
}


LongTextTemplateForm.propTypes = {
    folder: PropTypes.object,
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id
})
export default compose(
    connect(mapStateToProps),
)(LongTextTemplateForm)