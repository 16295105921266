let isValid = true

export const validate = (questionnaire) => {
  isValid = true
  const questionnaireWithError = { ...questionnaire, errors: {} }
  
  blankValidator(questionnaireWithError, 'title')
  questionsListSizeValidator(questionnaireWithError, 'questions', 2)

  questionnaireWithError.questions = validateQuestions(questionnaireWithError)

  return { ...questionnaireWithError, isValid }
}

const validateQuestions = (questionnaire) => {
  return questionnaire.questions.map((question) => {
    question = { ...question, isValid: true, errors: {} }

    validateQuestion(question, questionnaire)
    question.options = validateOptions(question)
    question.isValid = Object.keys(question.errors).length === 0 && 
      question.options.filter((option) => !option.isValid).length === 0

    return question
  })
}

const validateQuestion = (question, questionnaire) => {
  findDuplication(question, 'title', questionnaire.questions)
  blankValidator(question, 'title')
  blankValidator(question, 'weight')
  blankValidator(question, 'type')
  floatValidator(question, 'weight', 'can be between 0 to 5')
  greaterThanValidator(question, 'weight', 5)
  lessThanValidator(question, 'weight', 0)
  riskIndicatorEmptyValidator(question, 'riskIndicators', questionnaire)

  return question
}

const validateOptions = (question) => {
  return question.options.map((option) => {
    option = { ...option, isValid: true, errors: {} }

    blankValidator(option, 'title')
    findDuplication(option, 'title', question.options)

    if (option.subType !== 'DIVIDER') {
      blankValidator(option, 'score')
      floatValidator(option, 'score', 'can be between 0 to 1')
      greaterThanValidator(option, 'score', 1)
      lessThanValidator(option, 'score', 0)
    }

    option.isValid = Object.keys(option.errors).length === 0
    return option
  })
}

const riskIndicatorEmptyValidator = (object, field, questionnaire) => {
  if (questionnaire.type === 'PIA') return
  const selectedOnes = object.riskIndicators.filter((item) => item.selected)
  
  if (selectedOnes.length === 0) {
    isValid = false
    object.errors[field] = object.errors[field] || []
    object.errors[field].push('You must at least select one risk indicator')
  }
}

const questionsListSizeValidator = (object, field, minimum) => {
  if (object[field].length < minimum) {
    isValid = false
    object.errors[field] = object.errors[field] || []
    object.errors[field].push(`Your questionnaire must have at least ${ minimum } questions`)
  }
}

const floatValidator = (object, field, message = 'must be a number') => {
  const parsed = Number.parseFloat(object[field], 10)

  if (Number.isNaN(parsed)) {
    isValid = false
    object.errors[field] = object.errors[field] || []
    object.errors[field].push(`${ field } ${ message }`)
  }
}

const blankValidator = (object, field) => {
  if (typeof(object[field]) === 'string' && object[field].trim() === '') {
    isValid = false
    object.errors[field] = object.errors[field] || []
    object.errors[field].push(`${ field } cannot be blank`)
  }
}

const greaterThanValidator = (object, field, conditional) => {
  if (Number.parseFloat(object[field]) > conditional) {
    isValid = false
    object.errors[field] = object.errors[field] || []
    object.errors[field].push(`${ field } cannot be greater than ${ conditional }`)
  }
}

const lessThanValidator = (object, field, conditional) => {
  if (Number.parseFloat(object[field]) < conditional) {
    isValid = false
    object.errors[field] = object.errors[field] || []
    object.errors[field].push(`${ field } cannot be less than ${ conditional }`)
  }
}

const findDuplication = (object, field, collection) => {
  if (object[field].trim() === '') return
  const duplicateds = collection.filter((item) => item[field] === object[field])
  if (duplicateds.length > 1) {
    isValid = false
    object.errors[field] = object.errors[field] || []
    object.errors[field].push(`${ field } '${ object[field] }' has already been used`)
  }
}