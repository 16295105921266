import React from "react";
import {connect} from "react-redux";
import '../BuilderWorkflow.scss';
import {Input, Checkbox, Select} from "antd";
import {apiGetBusinessProcessList} from "../../../../../api/SmartBox";
const { Option } = Select

class BusinessProcessBuilder extends React.Component {
    state = {
        businessBuilder: {
            valueSelect: '',
        },
        hoverDelete: false,
        listEmails: []
    }
    componentDidMount() {
        apiGetBusinessProcessList().then((response) => {
            this.setState({
                listEmails: response.data
            })
        })
    }

    onHoverActionComponent = () => {
        this.setState({
            hoverDelete: true
        })
    }
    onLeaveActionComponent = () => {
        this.setState({
            hoverDelete: false
        })
    }
    onSelectBuilder = (e) => {
        const { listEmails } = this.state
        let componentName = ''
        listEmails && listEmails.length > 0 && listEmails.forEach((list) => {
            if (list.id === e) {
                componentName = list.businessProcessName
            }
        })
        this.setState({
            businessBuilder: { ...this.state.businessBuilder, valueSelect: e, componentName: componentName, idComponent: e},
        }, () => {
            this.props.onChangeBuilder(this.state.businessBuilder, this.props.component)
            this.props.onSelectIdBusinessProcess(e)
        })
    }
    render() {
        const { listEmails, hoverDelete, businessBuilder } = this.state
        const { component, onStateProvidedDraggable } = this.props
        return (
            <div className="component-form">
                <div className="component-content">
                    <div className="header-component">
                        <span className="header-title">Business process</span>
                    </div>
                    <div className="select-content">
                          <span onClick={ (e) => this.props.showConfirmDeleteSection(e, component) }
                              className={hoverDelete ? 'icon-v19-delete-active' : "icon-v19-delete" }
                              onMouseEnter={ (e) => this.onHoverActionComponent(e)}
                              onMouseLeave={() => this.onLeaveActionComponent()}></span>
                        <Select className="select-form"
                                onChange={(e) => this.onSelectBuilder(e)}
                                dropdownStyle={{border:'1px solid #eda41e'}}
                                value={ businessBuilder.valueSelect ? businessBuilder.valueSelect : component.componentName ? component.componentName : 'Select'}
                                placeholder="Select">
                            { listEmails && listEmails.length > 0 && listEmails.map((item,index) => {
                                return  (<Option className="options-select-connector" value={ item.id } key={index}>
                                    { item.businessProcessName }
                                </Option>)
                            })}
                        </Select>
                    </div>
                </div>
                <div className="component-move">
                    <span onMouseEnter={() => this.props.onStateProvidedDraggable(component)} className="icon-v211-grid-view-1"></span>
                </div>
            </div>
        )
    }
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(BusinessProcessBuilder)