import React from "react";
import './SourceMappingPage.scss';
import SourceMappingTable from "./SourceMappingTable";

class SourceMappingPage extends React.Component {
  render(){
    return (
      <div className="source-mapping-page">
        <div className="source-mapping-page__content">
          <div className="source-mapping-header">
            <span className="title-header">Source maps</span>
          </div>
          <div className="content-source-mapping-page">
            <SourceMappingTable folder={this.props.folder}/>
          </div>
        </div>
      </div>
    )
  }
}

export default SourceMappingPage;