import React from 'react'
import PropTypes from 'prop-types'

import CollectionCard from './CollectionCard'
import CollectionTable from './CollectionTable'

import './CollectionList.css'

const CollectionList = ({ chosenView, collections }) => {
  if (collections.length === 0) return (
    <p className="collectionList__empty">No results</p>
  )

  if (chosenView === 'cards') {
    return (
      <div id="collection-list" className="collectionList__wrapperCard">
        { collections.map((collection) => (
          <CollectionCard collection={ collection } key={ collection.id } />
        ))}
      </div>
    )
  }

  return (
    <div id="collection-list" className="collectionList__wrapperTable">
      <table>
        <thead>
          <tr>
            <th className="collectionList__cellTitle">Collection title</th>
            <th className="collectionList__cellHigh">
              High
            </th>
            <th className="collectionList__cellMedium">
              Caution
            </th>
            <th className="collectionList__cellLow">
              Low
            </th>
            <th style={{ textAlign: 'center' }}>Objects</th>
            <th style={{ textAlign: 'center' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          { collections.map((collection, index) => (
            <CollectionTable collection={ collection } key={ index } />
          ))}
        </tbody>
      </table>
    </div>
  )
}

CollectionList.propTypes = {
  chosenView: PropTypes.string.isRequired,
  collections: PropTypes.array.isRequired
}

export default CollectionList