import React from 'react'
import { Link } from 'react-router-dom'

// import Button from '../../Common/Button'

import './BoxOspreyDpia.scss'

class BoxOspreyDpia extends React.Component {
  state = { content: [] }

  render() {
    const { content } = this.state

    return (
      <div className="boxDpia__wrapper">
        <header className="boxDpia__header">
          <h1>PIA/DPIA Assessments</h1>
          <p>Our flagship app, v2 is online now!</p>
        </header>

        <div className="boxDpia__content">

          { content.length === 0 &&
          <div className="boxDpia__content--body">
            <p>Carry out structured, repeatable Impact Assessments and automatically create compliance ready reports.</p>
          </div>
          }

          { content.length > 0 &&
          <div className="boxDpia__content--body">
            <ul className="boxDpia__content--list">
              <li className="boxDpia__content--item">PIA - Human Resources<Link to="/">icon</Link></li>
              <li className="boxDpia__content--item">PIA - Human Resources<Link to="/">icon</Link></li>
              <li className="boxDpia__content--item">PIA - Human Resources<Link to="/">icon</Link></li>
            </ul>
          </div>
          }
        </div>
      </div>
    )
  }
}

export default BoxOspreyDpia