import PropTypes from 'prop-types'
import React from 'react'
import { groupBy } from '../../../../../utils'
import './Checkbox.css'

class Checkbox extends React.Component {
  render() {
    const { question } = this.props
    const groups = groupBy(question.options, (option) => option.displaySection)

    return (
      <div className="questionnaireGlobalCheckbox__wrapper">
        <h1 className="questionnaireGlobalCheckbox__title">{ question.order }. { question.title }</h1>

        { Object.keys(groups).map((group) => (
          <ul key={ group }>
            { group !== '__null' &&
              <h2 className="questionTypeCheckbox-title"><span>{ group.replace('__', '') }</span></h2>
            }
            <ul>
              { groups[group].map((option) => (
                <li key={ option.id }>
                  <div className="questionTypeCheckbox-item">
                    <div className="questionTypeCheckbox-group">
                      <div className="questionTypeCheckbox-checkboxDisabled">
                        <input
                          disabled={ true }
                          id={ option.id }
                          type="checkbox" />
                        <label
                          className="questionTypeCheckbox-labelDisabled"
                          htmlFor={ option.id }></label>
                      </div>
                      <span className="questionTypeCheckbox-optionTitle">{ option.title }</span>
                    </div>
                    { option.type === 'OTHER' &&
                      <textarea
                        className="questionTypeCheckbox-textarea"
                        disabled={ true }
                      ></textarea>
                    }
                  </div>
                </li>
              ))}
            </ul>
          </ul>
        ))
        }
      </div>
    )
  }
}

Checkbox.propTypes = {
  question: PropTypes.object.isRequired
}

export default Checkbox
