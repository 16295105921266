import React from 'react'
import { Select } from 'antd'
import AddEditAssetRegister from './AddEditAssetRegister'
import PropTypes from 'prop-types'

const { Option } = Select

class AddAsset extends React.Component {

  constructor() {
    super()
    this.state = {
      selectedRegister: '',
      registerTupeData: [],
      selectedRegisterObj: {}
    }
  }

  componentDidMount() {
    if (this.props.allRegisters) {
      const registerTupeData = this.getRegisterTypeData(this.props.allRegisters)
      this.setState({ registerTupeData: registerTupeData })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      const registerTupeData = this.getRegisterTypeData(this.props.allRegisters)
      this.setState({ registerTupeData: registerTupeData })
    }
  }

  getRegisterTypeData = (allRegisters) => {
    const response = []
    for (let i=0; i<allRegisters.length; i++) {
      response.push({ label: allRegisters[i].library.name, value: allRegisters[i].library.id })
    }
    return response
  }

  handleChange = (e) => {
    const { allRegisters } = this.props
    for (let i=0; i<allRegisters.length; i++) {
      if (allRegisters[i].library.id === e) {
        this.setState({ selectedRegister: e, selectedRegisterObj: allRegisters[i] })
      }
    }
  }

  render() {
    const { selectedRegister, selectedRegisterObj, registerTupeData  } = this.state

    return (
      <div className="add-asset-page">
        <div className="add-asset-content-r">
          <div className="asset-type">
            <span className="add-asset-title">REGISTER TYPE</span>
            { registerTupeData &&
              <Select placeholder="Choose Predefined Register" onChange={ (e) => this.handleChange(e) }>
                { registerTupeData && registerTupeData.length > 0 && registerTupeData.map((val, index) => {
                  return <Option value={ val.value } key={ index }>{ val.label }</Option>
                }) }
              </Select>
            }
          </div>
          { selectedRegister &&
            <div className= 'w-100'>
              <AddEditAssetRegister action="add" selectedRegisterObj= { selectedRegisterObj } submitForm={ this.props.submitForm } onClickCloseFormAdd={ this.props.onClickCloseFormAdd }/>
            </div>
          }
          { !selectedRegister &&
            <div className= 'contain-button' >
              <span className='button-blue float-right disabledSpan'>ADD</span>
            </div>
          }
        </div>
      </div>
    )
  }
}

AddAsset.propTypes = {
  allRegisters: PropTypes.array.isRequired,
}

export default AddAsset