import React from "react";
import {Button, Input, Modal, Checkbox, Empty} from 'antd'
import './BusinessProcessPage.scss';
import {
    apiBusinessProcessCreate,
    apiBusinessProcessDelete,
    apiBusinessProcessEdit,
    apiGetBusinessProcessList
} from "../../api/SmartBox";
import CustomNotificationManager from "../Common/CustomNotificationManager";
import {customConfirm} from "../../utils/AssetRegister/CustomConfirm";

class BusinessProcessTable extends React.Component {
    state = {
        visibleModal: false,
        formModal: {
            processName: '',
            detail: '',
        },
        listBusinessProcess: [],
        modalAction: 'create',
        selectedLists: [],
        hoverEditBusinessProcess: false,
        hoverDeleteBusinessProcess: false,
        hoverAddBusinessProcess: false,
    }
    componentDidMount() {
      this.onGetBusinessProcess()
    }

    onGetBusinessProcess = () => {
        apiGetBusinessProcessList().then((response) => {
           this.setState({
               listBusinessProcess: response.data
           })
        })
    }
    handleCancel = () => {
      this.setState({
          visibleModal: false,
      })
    }
    updateValue = (e, field) => {
       if (field === 'name') {
          this.setState({
              formModal: {...this.state.formModal, processName: e.target.value }
          })
       } else {
           this.setState({
               formModal: {...this.state.formModal, detail: e.target.value }
           })
       }
    }
    onClickVisibleModal = () => {
        this.setState({
            visibleModal: true,
            formModal: {
                processName: '',
                detail: '',
            },
            modalAction: 'create'
        })
    }

    onActionEdit = () => {
        const { selectedLists, listBusinessProcess } = this.state
        listBusinessProcess && listBusinessProcess.length > 0 && listBusinessProcess.forEach((item) => {
            if (selectedLists.includes(item.id)) {
              this.setState({
                  formModal: {
                      processName: item.businessProcessName,
                      detail: item.businessProcessDetail,
                      id: item.id
                  }
              })
            }
        })
       this.setState({
           modalAction: 'edit',
           visibleModal: true,
       })
    }
    onCreateBusinessProcess = () => {
        const { formModal } = this.state
      const createBusinessProcessRequestDTO = {
          businessProcessName: formModal.processName ? formModal.processName : null,
          businessProcessDetail: formModal.detail ? formModal.detail : null,
      }
        apiBusinessProcessCreate(createBusinessProcessRequestDTO).then((response) => {
            if(response.data.title.toLowerCase() === 'success') {
                CustomNotificationManager.success(response.data.message, 'Success')
            } else if(response.data.title.toLowerCase() === 'info') {
                CustomNotificationManager.info(response.data.message, 'Info')
            }
            this.setState({
                visibleModal: false,
                formModal: {
                    processName: '',
                    detail: '',
                }
            }, () => {
                this.onGetBusinessProcess()
            })
        })
    }
    onEditBusinessProcess = () => {
        const { selectedLists, formModal } = this.state
        const createBusinessProcessRequestDTO = {
            businessProcessName: formModal.processName ? formModal.processName : null,
            businessProcessDetail: formModal.detail ? formModal.detail : null,
            businessProcessId: formModal.id ? formModal.id : null,
        }
        apiBusinessProcessEdit(createBusinessProcessRequestDTO).then((response) => {
            if(response.data.title.toLowerCase() === 'success') {
                CustomNotificationManager.success(response.data.message, 'Success')
            } else if(response.data.title.toLowerCase() === 'info') {
                CustomNotificationManager.info(response.data.message, 'Info')
            }
            this.setState({
                visibleModal: false,
                selectedLists: [],
            }, () => {
                this.onGetBusinessProcess()
            })
        })
    }
    addOrRemoveSelectedLists = (e, id) => {
        e.stopPropagation()
        let { selectedLists } = this.state

        if(e.target.checked) {
            selectedLists.push(id)
        } else {
            selectedLists = selectedLists.filter(item => item !== id)
        }
        this.setState({selectedLists})
    }
    showConfirmDeleteProcess = (e) => {
        const {selectedLists} = this.state
        customConfirm({
            title: <span className="icon-v14-delete"></span>,
            content: `Delete business ${selectedLists.length > 1 ? 'processes' : 'process'} ?`,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            mask: true,
            maskClosable: false,
            closeHaveLogic: false,
            className: 'asset-register-modal modal-delete-connector',
            centered: true,
            cancelClassName: 'cancel-button',
            confirmClassName: 'confirm-button',
            confirmAction: () => {
                this.onDeleteBusiness(e)
            }
        })
    }

    onDeleteBusiness = () => {
        const { selectedLists } = this.state
        apiBusinessProcessDelete(selectedLists).then((response) => {
            if (response.data.title && response.data.title.toLowerCase() === 'success') {
                CustomNotificationManager.success(response.data.message, 'Success')
            } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
                CustomNotificationManager.info(response.data.message, 'Info')
            }
            this.setState({
                selectedLists: []
            }, () => {
                this.onGetBusinessProcess()
            })
        })
    }
    onHoverAction = (e, status) => {
        switch (status) {
            case 'edit':
                this.setState({
                    hoverEditBusinessProcess: true
                })
                break
            case 'delete':
                this.setState({
                    hoverDeleteBusinessProcess: true
                })
                break
            case 'add':
                this.setState({
                    hoverAddBusinessProcess: true
                })
                break
            default: break
        }
    }
    onLeaveAction = () => {
        this.setState({
            hoverEditBusinessProcess: false,
            hoverDeleteBusinessProcess: false,
            hoverAddBusinessProcess: false
        })
    }
    onClickVisibleBusinessProcess = (E, item) => {
        this.props.onVisibleBusinessProcessBuilder(true, item)
    }
    render(){
        const { visibleModal, formModal, listBusinessProcess, modalAction, selectedLists, hoverEditBusinessProcess, hoverDeleteBusinessProcess, hoverAddBusinessProcess } = this.state
        const { client } = this.props
        return (
            <div className="business-process-table">
             <div className="content-buttons-actions">
                 <button onClick={ (e) => this.onActionEdit(e) }
                         className={ hoverEditBusinessProcess ? "icon-v17-edit-active action-edit" : "icon-v17-edit action-edit"}
                         disabled={ selectedLists.length === 0 || selectedLists.length > 1 }
                         onMouseEnter={(e) => this.onHoverAction(e,'edit')}
                         onMouseLeave={() => this.onLeaveAction()}
                 ></button>
                 <button disabled={ selectedLists.length === 0 }
                         onClick={(e) => this.showConfirmDeleteProcess(e)}
                         className={ hoverDeleteBusinessProcess? "icon-v17-delete-active action-delete" :"icon-v17-delete action-delete"}
                         onMouseEnter={(e)=> this.onHoverAction(e,'delete')}
                         onMouseLeave={() => this.onLeaveAction()}
                 ></button>
                 <button onClick={(e) => this.onClickVisibleModal(e)}
                         className={ hoverAddBusinessProcess ? "icon-v17-add-active" :"icon-v17-add"}
                         onMouseEnter={(e) => this.onHoverAction(e,'add')}
                         onMouseLeave={() => this.onLeaveAction()}
                 ></button>
             </div>
                <div className="content-table-business-process">
                    { listBusinessProcess && listBusinessProcess.length > 0 ?
                        <div className="list-process">
                            { listBusinessProcess.map((item, index) => {
                                return (
                                    <div key={index} className="row-list-process">
                                        <span className="content-checkbox">
                                             <Checkbox checked={selectedLists.includes(item.id) ? true : false} onChange={(e) => this.addOrRemoveSelectedLists(e, item.id)}></Checkbox>
                                        </span>
                                        <span className="content-name" onClick={(e) => this.onClickVisibleBusinessProcess(e, item)}>
                                            {/*<Checkbox checked={selectedLists.includes(item.id) ? true : false} onChange={(e) => this.addOrRemoveSelectedLists(e, item.id)}></Checkbox>*/}
                                            <span className="name-first">{item.businessProcessName}</span>
                                            <span className="name-last">{item.businessProcessDetail}</span>
                                        </span>
                                        <i onClick={(e) => this.onClickVisibleBusinessProcess(e, item)} className="icon-v14-arrow-bold"></i>
                                    </div>
                                )
                            })

                            }
                        </div>
                        :
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        // <div className="content-empty">
                        //     <Icon type="inbox"/>
                        //     <span className="no-data">No data</span>
                        // </div>
                    }
                </div>
                <Modal className="new-process-modal" title={ <span className="content-header-modal"><span>
                    { modalAction === 'create' ? 'New process' : 'Edit process' }</span></span> }
                visible={visibleModal}
                       footer={ null } closable={ false }>
                    <div className="new-process-section">
                        <form className="new-process-form">
                            <div className="process-modal-line">
                                <label>Process name *</label>
                                <Input value={formModal.processName ? formModal.processName : ''} onChange={(e) => this.updateValue(e, 'name')}
                                       />
                            </div>
                            <div className="process-modal-line process-detail-line">
                                <label>Detail</label>
                                <Input value={formModal.detail ? formModal.detail : ''} onChange={(e) => this.updateValue(e, 'detail')}
                                />
                            </div>
                            <div className="content-buttons-modal">
                                <Button onClick={(e) => this.handleCancel(e)} className='cancel-action'>Cancel</Button>
                                { modalAction === 'create' ?
                                    <Button type="primary" className="create-process"
                                            onClick={(e) => this.onCreateBusinessProcess(e)}
                                            disabled={formModal.processName === ''}>Create</Button> :
                                    <Button type="primary" className="create-process"
                                            onClick={(e) => this.onEditBusinessProcess(e)}
                                            disabled={formModal.processName === ''}>Edit</Button>
                                }
                            </div>
                        </form>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default BusinessProcessTable;