import React from 'react'
import PropTypes from 'prop-types'

import Button from '../../../Common/Button'
import Loading from '../../../Common/Loading'
import Message from '../../../Common/Message'

import './CompleteBox.scss'

const CompleteBox = ({ isSending, messageArea, onClick, hideMessage }) => (
  <div className="boxComplete-wrapper">
    <div className="boxComplete-content">
      <h1 className="boxComplete-title">
        This assessment has been completely answered.<br />
        Last chance to review.
      </h1>
      <h2 className="boxComplete-text">Once your assessment has been submitted it will be set as locked and no further edits can be made. Are you sure you are ready to submit?</h2>

      { !isSending &&
        <Button className="boxComplete__button" onClick={ onClick }>
          Submit as completed
        </Button>
      }

      { isSending &&
        <div className="boxComplete-loading">
          <Loading />
        </div>
      }

      { messageArea.visible &&
        <div className="boxComplete-message">
          <Message
            hideMessageAction={ hideMessage }
            status={ messageArea.status }
            text={ messageArea.text } />
        </div>
      }
    </div>
  </div>
)

CompleteBox.propTypes = {
  hideMessage: PropTypes.func.isRequired,
  isSending: PropTypes.bool.isRequired,
  messageArea: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
}

export default CompleteBox