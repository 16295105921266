import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import Loading from '../../Common/Loading'
import Modal from '../../Common/Modal'

import WithFiles from '../HOC/WithFiles'

import { formatContentWithHighlight } from '../../../utils/Files'

import '../../../highlight.css'
import './Preview.css'

class Preview extends React.PureComponent {
  componentDidMount = () => {
    const { selectedAlert, selectedCollection } = this.props
    const { ID } = this.props.file

    let categoryID = '25'
    if (selectedCollection && selectedCollection !== '') categoryID = selectedCollection
    if (selectedAlert && selectedAlert !== '') categoryID = selectedAlert
    
    this.props.getInformationForFiles(ID, categoryID)
  }
  
  render = () => {
    const { fileToShowPreview } = this.props

    if (fileToShowPreview.isLoadingFileInformation) return (
      <Modal hideModal={ this.props.onClose }>
        <div className="filePreview__loading-container">
          <Loading />
        </div>
      </Modal>
    )

    return (
      <Modal hideModal={ this.props.onClose }>
        <div className="filePreview__header">
          <div className="filePreview__header-connectorName">
            <h2>Data Set</h2>
            <span>{ fileToShowPreview.metadata.doc_datasource_name }</span>
          </div>

          <div className="filePreview__header-location">
            <h2>Location</h2>
            <span>{ fileToShowPreview.metadata.doc_id }</span>
          </div>

          <div className="filePreview__header-lastModified">
            <h2>Last Modified</h2>
            <span>{ moment(fileToShowPreview.metadata.doc_date_modified).fromNow() }</span>
          </div>
        </div>

        <div className="filePreview__body">
          <p dangerouslySetInnerHTML={{
            __html: formatContentWithHighlight(fileToShowPreview.content)
          }}></p>
        </div>
      </Modal>

    )
  }
}

Preview.propTypes = {
  fileToShowPreview: PropTypes.object.isRequired,
  selectedAlert: PropTypes.string,
  selectedCollection: PropTypes.string,
  onClose: PropTypes.func.isRequired
}

export default WithFiles(Preview)