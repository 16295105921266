import axios from 'axios'

export const apiPrivacyAddNewCrawler = (host, serverID, crawlerConfig) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/addNewCrawler`,
    data: {
      sessionID: token, serverID, crawlerConfig
    }
  })
}

export const apiPrivacyGetConnectors = (host) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/getCrawlers`,
    data: { sessionID: token }
  })
}

export const apiPrivacyGetConnectorConfiguration = (host, crawlerId) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/getCrawlerConfiguration`,
    data: {
      sessionID: token,
      crawlerID: crawlerId
    }
  })
}

export const apiPrivacyGetConnectorObjects = (host, crawlerId) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/getDataModelStats`,
    data: { 
      sessionID: token,
      datamodel: 'documents', 
      queryString: '*:*', 
      pivotFields: ['doc_datasource_name', 'doc_type'],
      fieldNames: ['doc_size'],
      filterQueries: [`doc_crawlerid: ${ crawlerId }`],
      facetOffsets: [0, 0],
      facetLengths: [100, 100]
    }
  })
}

export const apiPrivacyCrawlerStart = (host, crawlerID) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/crawlerStart`,
    data: { 
      sessionID: token,
      crawlerID: `${ crawlerID }`
    }
  })
}

export const apiPrivacyCrawlerGetStatus = (host, crawlerID) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/crawlerGetStatus`,
    data: { 
      sessionID: token,
      processID: `${ crawlerID }`
    }
  })
}

export const apiPrivacyDeleteCrawler = (host, crawlerID) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/deleteCrawler`,
    data: { 
      sessionID: token,
      crawlerID: `${ crawlerID }`
    }
  })
}

export const apiPrivacyGetServers = (host) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/getServers`,
    data: {
      sessionID: token
    }
  })
}

