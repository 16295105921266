import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import CategoryList from './CategoryList'

import './CategoryItem.css'
import { apiOspreyGetCategoryChildren } from '../../../../api/OspreyAnalytics.js'
  
class CategoryItem extends React.Component {
  
  state = { 
    children: [],
    expanded: false
  }

  getChildren = (category) => {
    if (this.state.expanded === false)
    {
      apiOspreyGetCategoryChildren(category.id)
        .then((response) => {
          if (response.data.value.categories.category)
          {
            const val = response.data.value.categories.category

            if (val instanceof Array)
            {
              this.setState({
                children: response.data.value.categories.category
              })
            }
            else
            {
              this.setState({
                children: [response.data.value.categories.category]
              })
            }
          }
        })
        .catch((error) => {
          console.log(error, 'error')
        })
      this.setState({
        expanded: true
      })
    }
    else
    {
      this.setState({
        children: [],
        expanded: false
      })
    }
  }

  render = () => {
    const { category } = this.props

    return (
      <div>
        <div>
          <div>
            { this.state.expanded
              ? <div onClick={ this.getChildren.bind(this, category) } className="folder_open" />
              : <div onClick={ this.getChildren.bind(this, category) } className="folder_closed" />
            }
            <div className="folder_name"><Link to={`/admin/category/${ category.id }`}>{ category.name }</Link></div>
          </div>
        </div>
        <div>
          { this.state.expanded && this.state.children && this.state.children.length > 0
            ? <CategoryList categories={ this.state.children } />
            : <div/>
          }
        </div>
      </div>
      
    )
  }
}

CategoryItem.propTypes = {
  category: PropTypes.object.isRequired
}

export default CategoryItem