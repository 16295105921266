//import imgFolder from '../../assets/smart-folder-big.png'
//import imgFolderOpen from '../../assets/smart-folder-open.png'
import Mark from 'mark.js'
import { Popover, Tree, Icon } from 'antd'
import React from 'react'
import { CompactPicker } from 'react-color'
import { Link } from 'react-router-dom'
import imgGeneral from '../../assets/empty_file.png'
//import imgFolderSmallParent from '../../assets/smart-folder-medium-parent.svg'
import imgFolderNewParent from '../../assets/images/Box 80x80.png'
import imgFolderNewParentEmpty from '../../assets/box/box_empty_80x80.png'
import imgFolderNewParentProcessing from '../../assets/box/box_processing_80x80.png'
import imgFolderNewParentSmall from '../../assets/box/box_20x20.png'
import imgFolderNewParentEmptySmall from '../../assets/box/box_empty_20x20.png'
import imgFolderNewParentProcessingSmall from '../../assets/box/box_processing_20x20.png'

import imgFolderNewChildren from '../../assets/images/Folder 80x80.png'
import imgFolderNewChildrenOpened from '../../assets/images/Folder open 80x80.png'
import imgFolderNewEmpty from '../../assets/images/Folder empty 80x80.png'

//import imgFolderSmallOpenParent from '../../assets/smart-folder-open-paret.svg'
import imgFolderSmallOpenParentSmall from '../../assets/smart-folder-open-paret-small.svg'
import { categories } from '../AiTags'
import SmartBoxSettingsMenu from '../../components/SmartBox/SmartBox/Settings/SmartBoxSettingsMenu'

const { TreeNode } = Tree

const LIMIT_FILE_UPLOAD = 5000000000

export const timerCheckCanvas = 0
global.arEl = []
global.arOptions = []
global.items = {}
global.finalItems = {}
global.itemsToBeMarked = 0
global.markedTimes = 0

export const getImageSrc = (type, parent, opened, name, isEmpty, hasError, smallIcons = false, numberOfFiles) => {
  if (type) {
    switch (type.toUpperCase()) {
      case 'JPEG':
        return {
          font: 'icon-v3-jpeg',
          color: '#beccd3'
        }
      case 'JPG':
        return {
          font: 'icon-v3-jpg',
          color: '#beccd3'
        }
      case 'PNG':
        return {
          font: 'icon-v3-png',
          color: '#beccd3'
        }
      case 'DOC':
        return {
          font: 'icon-v3-doc',
          color: '#7ad8f9'
        }
      case 'ODT':
        return {
          font: 'icon-v3-odt',
          color: '#c2ecff'
        }
      case 'DOCX':
        return {
          font: 'icon-v3-docx',
          color: '#7ad8f9'
        }
      case 'ODP':
        return {
          font: 'icon-v3-odp',
          color: '#ffeeb3'
        }
      case 'TXT':
        return {
          font: 'icon-v3-txt',
          color: '#7ad8f9'
        }
      case 'XLS':
        return {
          font: 'icon-v3-xls',
          color: '#b0f5be'
        }
      case 'XLR':
        return {
          font: 'icon-v3-xls',
          color: '#b0f5be'
        }
      case 'XLSX':
        return {
          font: 'icon-v3-xlsx',
          color: '#b0f5be'
        }
      case 'PDF':
        return {
          font: 'icon-v3-pdf',
          color: '#ff9e9e'
        }
      case 'PPT':
        return {
          font: 'icon-v3-ppt',
          color: '#ffd38c'
        }
      case 'PPTX':
        return {
          font: 'icon-v3-ppt',
          color: '#ffd38c'
        }
      case 'CSV':
        return {
          font: 'icon-v3-csv',
          color: '#7879ab'
        }
      case 'ZIP':
        return {
          font: 'icon-v3-zip',
          color: '#ff9e9e'
        }
      case 'PST':
        return {
          font: 'icon-v3-pst',
          color: '#7879ab'
        }
      case 'CSS':
        return {
          font: 'icon-v3-css',
          color: '#fcb0e5'
        }
      case 'PHP':
        return {
          font: 'icon-v3-php',
          color: '#fcb0e5'
        }
      case 'HTML':
        return {
          font: 'icon-v3-html',
          color: '#fcb0e5'
        }
      case 'XHTML':
        return {
          font: 'icon-v3-xhtml',
          color: '#fcb0e5'
        }
      case 'XML':
        return {
          font: 'icon-v3-xml',
          color: '#fcb0e5'
        }
      case 'JFIF':
        return {
          font: 'icon-v3-jfif',
          color: '#beccd3'
        }
      case 'JP2':
        return {
          font: 'icon-v3-jp2',
          color: '#beccd3'
        }
      case 'PSD':
        return {
          font: 'icon-v3-psd',
          color: '#e6e6e6'
        }
      case 'GIF':
        return {
          font: 'icon-v3-gif',
          color: '#e6e6e6'
        }
      case 'TIFF':
        return {
          font: 'icon-v3-tiff',
          color: '#e6e6e6'
        }
      case 'TIF':
        return {
          font: 'icon-v3-tif',
          color: '#e6e6e6'
        }
      case 'ODS':
        return {
          font: 'icon-v3-ods',
          color: '#b0f5be'
        }
      case 'PPS':
        return {
          font: 'icon-v3-pps',
          color: '#ffd38c'
        }
      case 'KEY':
        return {
          font: 'icon-v3-key',
          color: '#ffeeb3'
        }
      case 'RTF':
        return {
          font: 'icon-v3-rtf',
          color: '#c2ecff'
        }
      case 'TEX':
        return {
          font: 'icon-v3-tex',
          color: '#c2ecff'
        }
      case 'SVG':
        return {
          font: 'icon-v3-svg',
          color: '#d4c2ff'
        }
      case 'AI':
        return {
          font: 'icon-v3-ai',
          color: '#d4c2ff'
        }
      case 'EPS':
        return {
          font: 'icon-v3-eps',
          color: '#d4c2ff'
        }
      case 'AEP':
        return {
          font: 'icon-v3-aep',
          color: '#94bfff'
        }
      case 'MOV':
        return {
          font: 'icon-v3-mov',
          color: '#94bfff'
        }
      case 'MP4':
        return {
          font: 'icon-v3-mov',
          color: '#94bfff'
        }
      case 'MPG':
        return {
          font: 'icon-v3-mpg',
          color: '#94bfff'
        }
      case 'MPEG':
        return {
          font: 'icon-v3-mpeg',
          color: '#94bfff'
        }
      case 'AVI':
        return {
          font: 'icon-v3-avi',
          color: '#94bfff'
        }
      case 'RAR':
        return {
          font: 'icon-v3-rar',
          color: '#ff9e9e'
        }
      case 'AIF':
        return {
          font: 'icon-v3-aif',
          color: '#666666'
        }
      case 'CDA':
        return {
          font: 'icon-v3-cda',
          color: '#666666'
        }
      case 'MID':
        return {
          font: 'icon-v3-mid',
          color: '#666666'
        }
      case 'MIDI':
        return {
          font: 'icon-v3-midi',
          color: '#666666'
        }
      case 'MP3':
        return {
          font: 'icon-v3-mp3',
          color: '#666666'
        }
      case 'MPA':
        return {
          font: 'icon-v3-mpa',
          color: '#666666'
        }
      case 'OGG':
        return {
          font: 'icon-v3-ogg',
          color: '#666666'
        }
      case 'WAV':
        return {
          font: 'icon-v3-wav',
          color: '#666666'
        }
      case 'WMA':
        return {
          font: 'icon-v3-wma',
          color: '#666666'
        }
      case 'WPL':
        return {
          font: 'icon-v3-wpl',
          color: '#666666'
        }
      case 'FNT':
        return {
          font: 'icon-v3-fnt',
          color: '#666666'
        }
      case 'FON':
        return {
          font: 'icon-v3-fon',
          color: '#666666'
        }
      case 'OTF':
        return {
          font: 'icon-v3-otf',
          color: '#666666'
        }
      case 'TTF':
        return {
          font: 'icon-v3-ttf',
          color: '#666666'
        }
      case 'PS':
        return {
          font: 'icon-v3-ps',
          color: '#666666'
        }
      case 'DIRECTORY':
        if (parent) {
          /*if (opened) {
            return imgFolderSmallOpenParent
          }*/
          if(numberOfFiles === 0) {
            return smallIcons ? imgFolderNewParentEmptySmall : imgFolderNewParentEmpty
          }
          if(hasError) {
            return smallIcons ? imgFolderNewParentProcessingSmall : imgFolderNewParentProcessing
          }
          return smallIcons ? imgFolderNewParentSmall : imgFolderNewParent
        } else if (opened) {
          //return imgFolderOpen
          return imgFolderNewChildrenOpened
        }
        //return imgFolder
        return numberOfFiles === 0 ? imgFolderNewEmpty : imgFolderNewChildren
      default :
        return {
          font: 'icon-v3-ogg',
          color: 'black'
        }
    }
  }
  return imgGeneral
}

export const getImageWidth = (parent, opened, isRoot = false) => {
  if (parent && isRoot) {
    if (opened) {
      return 20
    }
    return 20
  } else if (opened) {
    return 20
  }
  return 20
}
export const deleteFolderOrFile = (file, files) => {
  for (let i = 0; i < files.length; i++) {
    if (files[i].id === file.id) {
      files.splice(i, 1)
      break
    }
  }
  return files
}

export const buildMarkWordsOnDocumentCopy = (sortListWord) => { let j = 0
  sortListWord.forEach((el) => {
    const parentClassName = el.landscape ? '#div-to-print-landscape' : '#div-to-print-portrait'
    const keyName = el.word.replace(/\W/g, 'a')
    const options = {
      'className': `${ el.category } doc_annotate`,
      'ignoreJoiners': true,
      'element': 'span',
      'separateWordSearch': false,
      'acrossElements': false,
      'wildcards': 'withSpaces',
      // "filter": function(element, term, counter){
      //   // if(term === "06/06/2023,16:38" && counter > 0){ // "something" was already marked more than three times
      //   //   return false; // stop (limit)
      //   // } else {
      //   //   return true; // continue
      //   // }
      // },
      'accuracy': {'value': 'exactly', 'limiters': [' @',',',', ',' .','. ',':',';','/>','"',"'","`",'<','>','[',']','(',')','’','’s','’ ','’s ']},
      each: (element) => {
        j++
        const parent = element.parentElement.closest(parentClassName)
        element.classList.add(`${ keyName }-${ j }`)
        if (parent) {
          element.setAttribute('data-page-nr', parent.getAttribute('data-pageid'))
        }
        if (element.parentElement && element.classList.value === element.parentElement.classList.value) {
          element.style.background = 'transparent'
        } else {
          element.style.background = el.backgroundColor
        }
        const items = global.items
        const item = {
          key: `${ keyName }-${ j }`,
          index: j,
          pageNumber: parent ? parent.getAttribute('data-pageid') : '',
          name: el.word,
          title: `${ el.word }`,
          isSearchedWord: true,
          children: [],
          backgroundColor: el.backgroundColor
        }
        if (typeof items[el.category] === 'undefined') {
          items[el.category] = {}
        }
        if (typeof items[el.category][el.subcategory] === 'undefined') {
          items[el.category][el.subcategory] = {}
        }
        if (typeof items[el.category][el.subcategory][keyName] === 'undefined') {
          items[el.category][el.subcategory][keyName] = []
        }
        items[el.category][el.subcategory][keyName].push(item)
        global.items = items
      },
      done: () => {
        const items = global.items
        const finalItems = global.finalItems
        global.markedTimes++

        if (typeof items[el.category] !== 'undefined' && typeof items[el.category][el.subcategory] !== 'undefined' && Object.keys(items[el.category][el.subcategory]).length > 0) {

          if (typeof finalItems[el.category] === 'undefined') {
            finalItems[el.category] = {}
          }
          if (typeof finalItems[el.category][el.subcategory] === 'undefined') {
            finalItems[el.category][el.subcategory] = {}
          }
          Object.keys(items[el.category][el.subcategory]).forEach((key) => {

            if (Object.keys(items[el.category][el.subcategory][key]).length > 0) {
              const { index, pageNumber, title, name } = items[el.category][el.subcategory][key][0]

              if (typeof finalItems[el.category][el.subcategory][key] === 'undefined') {
                finalItems[el.category][el.subcategory][key] = {
                  key: `${ key }-found-parent-all`,
                  index,
                  pageNumber,
                  name,
                  title,
                  children: [],
                  backgroundColor: el.backgroundColor
                }
              }
              items[el.category][el.subcategory][key].forEach((item) => {
                if (finalItems[el.category][el.subcategory][key].children.findIndex((child) => child.key === item.key) === -1) {
                  finalItems[el.category][el.subcategory][key].children.push(item)
                }
              })
            } else {
              finalItems[el.category][el.subcategory][key] = items[el.category][el.subcategory][key][0]
            }
          })
        }
        global.finalItems = finalItems
      }
    }
    global.arEl.push(el)
    global.arOptions.push(options)
  })

  global.itemsToBeMarked += sortListWord.length
}

export const buildMarkWordsOnDocument = (docAnnotation, category, backgroundColor, landscape, subcategory) => { let j = 0
  const parentClassName = landscape ? '#div-to-print-landscape' : '#div-to-print-portrait'
  docAnnotation.forEach((el) => {
    const keyName = el.replace(/\W/g, 'a')
    const options = {
      'className': `${ category } doc_annotate`,
      'ignoreJoiners': true,
      'element': 'span',
      'separateWordSearch': false,
      'acrossElements': false,
      'wildcards': 'withSpaces',
      // "filter": function(element, term, counter){
      //   // if(term === "06/06/2023,16:38" && counter > 0){ // "something" was already marked more than three times
      //   //   return false; // stop (limit)
      //   // } else {
      //   //   return true; // continue
      //   // }
      // },
      'accuracy': {'value': 'exactly', 'limiters': [' @',',',', ',' .','. ',':',';','/>','"',"'","`",'<','>','[',']','(',')','’','’s','’ ','’s ']},
      each: (element) => {
        j++
        const parent = element.parentElement.closest(parentClassName)
        element.classList.add(`${ keyName }-${ j }`)
        if (parent) {
          element.setAttribute('data-page-nr', parent.getAttribute('data-pageid'))
        }
        if (element.parentElement && element.classList.value === element.parentElement.classList.value) {
          element.style.background = 'transparent'
        } else {
          element.style.background = backgroundColor
        }
        const items = global.items
        const item = {
          key: `${ keyName }-${ j }`,
          index: j,
          pageNumber: parent ? parent.getAttribute('data-pageid') : '',
          name: el,
          title: `${ el }`,
          isSearchedWord: true,
          children: [],
          backgroundColor
        }
        if (typeof items[category] === 'undefined') {
          items[category] = {}
        }
        if (typeof items[category][subcategory] === 'undefined') {
          items[category][subcategory] = {}
        }
        if (typeof items[category][subcategory][keyName] === 'undefined') {
          items[category][subcategory][keyName] = []
        }
        items[category][subcategory][keyName].push(item)
        global.items = items
      },
      done: () => {
        const items = global.items
        const finalItems = global.finalItems
        global.markedTimes++

        if (typeof items[category] !== 'undefined' && typeof items[category][subcategory] !== 'undefined' && Object.keys(items[category][subcategory]).length > 0) {

          if (typeof finalItems[category] === 'undefined') {
            finalItems[category] = {}
          }
          if (typeof finalItems[category][subcategory] === 'undefined') {
            finalItems[category][subcategory] = {}
          }
          Object.keys(items[category][subcategory]).forEach((key) => {

            if (Object.keys(items[category][subcategory][key]).length > 0) {
              const { index, pageNumber, title, name } = items[category][subcategory][key][0]

              if (typeof finalItems[category][subcategory][key] === 'undefined') {
                finalItems[category][subcategory][key] = {
                  key: `${ key }-found-parent-all`,
                  index,
                  pageNumber,
                  name,
                  title,
                  children: [],
                  backgroundColor
                }
              }
              items[category][subcategory][key].forEach((item) => {
                if (finalItems[category][subcategory][key].children.findIndex((child) => child.key === item.key) === -1) {
                  finalItems[category][subcategory][key].children.push(item)
                }
              })
            } else {
              finalItems[category][subcategory][key] = items[category][subcategory][key][0]
            }
          })
        }
        global.finalItems = finalItems
      }
    }
    global.arEl.push(el)
    global.arOptions.push(options)
  })

  global.itemsToBeMarked += docAnnotation.length
}

export const markWordsOnDocumentForCategoryALL = (categoriesColors, docAnnotations, landscape, categories) => {
  const ddd = {
    word: null,
    category: null,
    backgroundColor: null,
    landscape: null,
    subcategory: null,

  }
  const listWords = []
  let sortListWordUnique = []
  for (const objectKey of categoriesColors) {
    for (const scanItem2 of categories) {
      //scanItem2.colour = objectKey.colour
    for (const scanItem of scanItem2.list.map((item) => item.tag)) {
      for (const docAnnot of docAnnotations.map((item) => item)) {
      if (docAnnot.name.toLowerCase() === scanItem.toLowerCase() && Array.isArray(docAnnot.list)) {
        //scanItem2.colour = objectKey.colour
        for (const word of docAnnot.list) {
          listWords.push({
            word: word,
            wordCategory: `${word}-${scanItem2.name}`,
            category: scanItem2.name,
            backgroundColor: objectKey.name === scanItem2.name ? objectKey.colour : objectKey.colour,
            landscape: landscape,
            subcategory: scanItem,
          })
        }
      }
    }
    }
  }
  }
  let sortListWord = listWords.sort((a, b) => b.word.length - a.word.length)
  if (sortListWord && sortListWord.length > 0) {
    sortListWordUnique = sortListWord && sortListWord.length > 0 && sortListWord.filter((a, i) => sortListWord.findIndex((s) => a.wordCategory === s.wordCategory) === i)
  }
  //buildMarkWordsOnDocument(docAnnotations[scanItem], objectKey, categoriesColors[objectKey], landscape, scanItem)
  sortListWordUnique && sortListWordUnique.length > 0 && sortListWordUnique.forEach((item2) => {
    categoriesColors && categoriesColors.length > 0 && categoriesColors.forEach((item3) => {
      if (item3.categoryName === 'business') {
        if (item2.category.toLowerCase() === item3.name.toLowerCase()) {
          item2.backgroundColor = item3.colour
        }
      }
      if (item3.categoryName === 'regular') {
        if (item2.category.toLowerCase() === item3.name.toLowerCase()) {
          item2.backgroundColor = item3.colour
        }
      }
    })
  })

  buildMarkWordsOnDocumentALL(sortListWordUnique)
  if (document.querySelector('#pdf-page')) {
    const arEl = global.arEl
    const arOptions = global.arOptions
    if (typeof arEl !== 'undefined' && arEl.length > 0) {
      for (let i=0; i<arEl.length; i++) {
        const instance = new Mark(document.querySelector('#pdf-page'))
        instance.mark(arEl[i].word, arOptions[i])
        if (arOptions[i].className.includes('location')) {
          arOptions[i].className = 'location doc_annotation ml-2'
        }
      }
      global.arOptions = []
      global.arEl = []
    }
  }
}

export const buildMarkWordsOnDocumentALL = (sortListWord) => { let j = 0
  sortListWord.forEach((el) => {
    const parentClassName = el.landscape ? '#div-to-print-landscape' : '#div-to-print-portrait'
    const keyName = el.word.replace(/\W/g, 'a')
    const options = {
      'className': `${ el.category } doc_annotate`,
      'ignoreJoiners': true,
      'element': 'span',
      'separateWordSearch': false,
      'acrossElements': false,
      'wildcards': 'withSpaces',
      'accuracy': {'value': 'exactly', 'limiters': [' @',',',', ',' .','. ',':',';','/>','"',"'","`",'<','>','[',']','(',')','’','’s','’ ','’s ','-','.','@','{','}','$','&','!','?','~','#','%','^','*','+','_','=','|','\\','/']},
      each: (element) => {
        j++
        const parent = element.parentElement.closest(parentClassName)
        element.classList.add(`${ keyName }-${ j }`)
        if (parent) {
          element.setAttribute('data-page-nr', parent.getAttribute('data-pageid'))
        }
        if (element.parentElement && element.classList.value === element.parentElement.classList.value) {
          element.style.background = 'transparent'
        } else {
          element.style.background = el.backgroundColor
          //element.style.background = 'rgba(77, 100, 113, 1)'
        }
        const items = global.items
        const item = {
          key: `${ keyName }-${ j }`,
          key2: `${ keyName }-${ j }`,
          index: j,
          pageNumber: parent ? parent.getAttribute('data-pageid') : '',
          name: el.word,
          title: `${ el.word }`,
          isSearchedWord: true,
          children: [],
          backgroundColor: el.backgroundColor
        }
        if (typeof items[el.category] === 'undefined') {
          items[el.category] = {}
        }
        if (typeof items[el.category][el.subcategory] === 'undefined') {
          items[el.category][el.subcategory] = {}
        }
        if (typeof items[el.category][el.subcategory][keyName] === 'undefined') {
          items[el.category][el.subcategory][keyName] = []
        }
        items[el.category][el.subcategory][keyName].push(item)
        global.items = items
      },
      done: () => {
        const items = global.items
        const finalItems = global.finalItems
        global.markedTimes++

        if (typeof items[el.category] !== 'undefined' && typeof items[el.category][el.subcategory] !== 'undefined' && Object.keys(items[el.category][el.subcategory]).length > 0) {

          if (typeof finalItems[el.category] === 'undefined') {
            finalItems[el.category] = {}
          }
          if (typeof finalItems[el.category][el.subcategory] === 'undefined') {
            finalItems[el.category][el.subcategory] = {}
          }
          Object.keys(items[el.category][el.subcategory]).forEach((key) => {

            if (Object.keys(items[el.category][el.subcategory][key]).length > 0) {
              const { index, pageNumber, title, name } = items[el.category][el.subcategory][key][0]

              if (typeof finalItems[el.category][el.subcategory][key] === 'undefined') {
                finalItems[el.category][el.subcategory][key] = {
                  key: `${ key }-found-parent-all`,
                  index,
                  pageNumber,
                  name,
                  title,
                  children: [],
                  backgroundColor: el.backgroundColor
                }
              }
              items[el.category][el.subcategory][key].forEach((item) => {
                if (finalItems[el.category][el.subcategory][key].children.findIndex((child) => child.key === item.key) === -1) {
                  finalItems[el.category][el.subcategory][key].children.push(item)
                }
              })
            } else {
              finalItems[el.category][el.subcategory][key] = items[el.category][el.subcategory][key][0]
            }
          })
        }
        global.finalItems = finalItems
      }
    }
    global.arEl.push(el)
    global.arOptions.push(options)
  })

  global.itemsToBeMarked += sortListWord.length
}


export const markWordsOnDocumentForCategoryCopy = (categoriesColors, docAnnotations, landscape, categories) => {
  const ddd = {
    word: null,
    category: null,
    backgroundColor: null,
    landscape: null,
    subcategory: null,

  }
  const listWords = []
  for (const objectKey of Object.keys(categoriesColors)) {
    for (const scanItem of categories[objectKey].map((item) => item.tag)) {
      if (docAnnotations[scanItem] && Array.isArray(docAnnotations[scanItem])) {
        for (const word of docAnnotations[scanItem]) {
          listWords.push({
            word: word,
            category: objectKey,
            backgroundColor: categoriesColors[objectKey],
            landscape: landscape,
            subcategory: scanItem,
          })
        }
      }
    }
  }
  const sortListWord = listWords.sort((a, b) => b.word.length - a.word.length)
  //buildMarkWordsOnDocument(docAnnotations[scanItem], objectKey, categoriesColors[objectKey], landscape, scanItem)
  buildMarkWordsOnDocumentCopy(sortListWord)
  if (document.querySelector('#pdf-page')) {
    const arEl = global.arEl
    const arOptions = global.arOptions
    if (typeof arEl !== 'undefined' && arEl.length > 0) {
      for (let i=0; i<arEl.length; i++) {
        const instance = new Mark(document.querySelector('#pdf-page'))
        instance.mark(arEl[i].word, arOptions[i])
        if (arOptions[i].className.includes('location')) {
          arOptions[i].className = 'location doc_annotation ml-2'
        }
      }
      global.arOptions = []
      global.arEl = []
    }
  }
}
export const markWordsOnDocumentForCategory = (category, backgroundColor, docAnnotations, landscape, categories) => {

  for (const scanItem of categories[category].map((item) => item.tag)) {
    if (docAnnotations[scanItem] && Array.isArray(docAnnotations[scanItem])) {
      buildMarkWordsOnDocument(docAnnotations[scanItem], category, backgroundColor, landscape, scanItem)
    }
  }

  if (document.querySelector('#pdf-page')) {
    const arEl = global.arEl
    const arOptions = global.arOptions
    if (typeof arEl !== 'undefined' && arEl.length > 0) {
      for (let i=0; i<arEl.length; i++) {
        const instance = new Mark(document.querySelector('#pdf-page'))
        instance.mark(arEl[i], arOptions[i])
        if (arOptions[i].className.includes('location')) {
          arOptions[i].className = 'location doc_annotation ml-2'
        }
      }
      global.arOptions = []
      global.arEl = []
    }
  }
}

export const generateHtmlForSecurityScanResultALL = (category, backgroundColor, docAnnotations, landscape, categories, docAnnotationCategories, categoryName,
                                                     isOcr, xeroxUpload) => {
  const result = {
    key: category,
    title: category,
    identifier: 'category-parent',
    backgroundColor,
    children: [],
    categoryName: categoryName,
    categoryNameUpdate: `doc_annotate_${category}`
  }
  result.children = []
  const expandedSubCategoryParent = []
  const finalItems = global.finalItems
  for (const scanItem2 of categories) {
  for (const scanItem of scanItem2.list.map((item) => item.tag)) {
    for (const docAnnot of docAnnotations.map((item) => item)) {
    if (docAnnot.name.toLowerCase() === scanItem.toLowerCase() && Array.isArray(docAnnot.list)) {

      const finalItemsCategory = []
      /////////////1111111111
      if (result.title.toLowerCase() === scanItem2.name.toLowerCase() && isOcr) {
        let title = scanItem.replace('doc_annotate_', '')
        title = title.replace('_', ' ')
        const mainCategory = {
          key: title,
          title,
          identifier: 'subcategory-parent',
          backgroundColor,
          children: finalItemsCategory,
          categoryNameUpdate: scanItem
        }
        expandedSubCategoryParent.push(title)
        result.children.push(mainCategory)
      } else if (result.title.toLowerCase() === scanItem2.name.toLowerCase() && xeroxUpload) {
        let title = scanItem.replace('doc_annotate_', '')
        title = title.replace('_', ' ')
        const mainCategory = {
          key: title,
          title,
          identifier: 'subcategory-parent',
          backgroundColor,
          children: finalItemsCategory
        }
        expandedSubCategoryParent.push(title)
        result.children.push(mainCategory)
      } else if (typeof finalItems[category] !== 'undefined' && typeof finalItems[category][scanItem] !== 'undefined') {
        Object.keys(finalItems[category][scanItem]).forEach((key) => {
          finalItemsCategory.push(finalItems[category][scanItem][key])
        })
        let title = scanItem.replace('doc_annotate_', '')
        title = title.replace('_', ' ')
        const mainCategory = {
          key: title,
          title,
          identifier: 'subcategory-parent',
          backgroundColor,
          children: finalItemsCategory
        }
        expandedSubCategoryParent.push(title)
        result.children.push(mainCategory)
      }
      docAnnotationCategories.push(scanItem)
      // let title = scanItem.replace('doc_annotate_', '')
      // title = title.replace('_', ' ')
      // const mainCategory = {
      //   key: title,
      //   title,
      //   identifier: 'subcategory-parent',
      //   backgroundColor,
      //   children: finalItemsCategory
      // }
      // expandedSubCategoryParent.push(title)
      // result.children.push(mainCategory)
    }
  }
  }
}
  return { result, expandedSubCategoryParent }
}

export const generateHtmlForSecurityScanResult = (category, backgroundColor, docAnnotations, landscape, categories, docAnnotationCategories) => {
  const result = {
    key: category,
    title: category,
    identifier: 'category-parent',
    backgroundColor,
    children: []
  }
  result.children = []
  const expandedSubCategoryParent = []
  const finalItems = global.finalItems
  for (const scanItem of categories[category].map((item) => item.tag)) {
    if (docAnnotations[scanItem] && Array.isArray(docAnnotations[scanItem])) {
      const finalItemsCategory = []
      if (typeof finalItems[category] !== 'undefined' && typeof finalItems[category][scanItem] !== 'undefined') {
        Object.keys(finalItems[category][scanItem]).forEach((key) => {
          finalItemsCategory.push(finalItems[category][scanItem][key])
        })
      }
      docAnnotationCategories.push(scanItem)
      let title = scanItem.replace('doc_annotate_', '')
      title = title.replace('_', ' ')
      // if (title === 'ssn') {
      //   title = 'Social Security Numbers'
      // }
      // if (title === 'password') {
      //   title = 'Passwords'
      // }
      // if (title === 'creditcard') {
      //   title = 'Credit Cards'
      // }
      const mainCategory = {
        key: title,
        title,
        identifier: 'subcategory-parent',
        backgroundColor,
        children: finalItemsCategory //getAllAiChildren(docAnnotations[scanItem], category, backgroundColor, landscape)
      }
      expandedSubCategoryParent.push(title)
      result.children.push(mainCategory)
    }
  }

  //generate checkbox from state
  return { result, expandedSubCategoryParent }
}

export const generateHtmlForSecurityScanResultForManualTab = (category, backgroundColor) => {
  const result = {
    key: category,
    title: category,
    identifier: 'category-parent',
    backgroundColor,
    children: [],
    searchExecuted: false
  }

  //generate checkbox from state
  return result
}

export const getAllAiChildren = (docAnnotation, category, backgroundColor, landscape) => {
  let j = 0
  const finalItems = []
  const parentClassName = landscape ? '#div-to-print-landscape' : '#div-to-print-portrait'
  docAnnotation.forEach((el) => {
    const items = []
    const keyName = el.replace(/\W/g, 'a')
    const options = {
      'className': `${ category } doc_annotate`,
      'ignoreJoiners': true,
      'element': 'span',
      'acrossElements': true,
      'separateWordSearch': false,
      'wildcards': 'withSpaces',
      each: (element) => {
        j++
        const parent = element.parentElement.closest(parentClassName)
        //add to element the class ${ keyName }-${ j }
        const item = {
          key: `${ keyName }-${ j }`,
          index: j,
          pageNumber: parent ? parent.getAttribute('data-pageid') : '',
          name: el,
          title: `${ el }`,
          isSearchedWord: true,
          children: [],
          backgroundColor
        }
        element.classList.add(`${ keyName }-${ j }`)
        element.setAttribute('data-page-nr', item.pageNumber)
        items.push(item)
        if (element.parentElement && element.classList.value === element.parentElement.classList.value) {
          element.style.background = 'transparent'
        } else {
          element.style.background = backgroundColor
        }
      }
    }

    const instance = new Mark(document.querySelector('#pdf-page'))
    instance.mark(el, options)
    if (items.length > 0) {
      const { index, pageNumber, name, title } = items[0]
      const keyName = el.replace(/\W/g, 'a')
      const childrenObject = {
        key: `${ keyName }-found-parent-all`,
        index,
        pageNumber,
        name,
        title: title,
        children: [],
        backgroundColor
      }
      items.forEach((item, key) => childrenObject.children.push(item))
      finalItems.push(childrenObject)
    }
    else {
      finalItems.push(items[0])
    }
  })
  return finalItems
}

const getListChildren = (item) => {
  const response = []
  if (item.children) {
    item.children.forEach((value) => {
      if (value.children && value.children.length) {
        value.children.forEach((val) => {
          response.push(val.key)
        })
      }
    })
  }
  return response
}

// const generateStateRedactedTitle = (item, redactedKeys, checkedKeys) => {
//   let checkListKeys = []
//   checkListKeys = new Set([...checkedKeys])
//   if (checkListKeys && checkListKeys.size > 0) {
//     if (item && item.children && item.children.length > 0) {
//       const childKeysRedact = item.children.map((child) => child.key)
//       const redactedFoundItemsKeysLength = childKeysRedact.filter((child) => checkListKeys.has(child)).length
//       return <span className={ item.children.length === redactedFoundItemsKeysLength ? 'icon-redacted' : '' }>{ item.children.length === redactedFoundItemsKeysLength ? 'R' : '' }</span>
//     }
//
//     return <span className={checkListKeys.has(item.key) ? 'icon-redacted' : '' }>{ checkListKeys.has(item.key) ? 'R' : ''}</span>
//
//   } else
//   if (checkedKeys && checkedKeys.length > 0 && redactedKeys && redactedKeys.size > 0) {
//     if (item && item.children && item.children.length > 0) {
//       const childKeys = item.children.map((child) => child.key)
//       const redactedFoundItemsLength = childKeys.filter((child) => redactedKeys.has(child)).length
//
//       return <span className={ item.children.length === redactedFoundItemsLength ? 'icon-redacted' : '' }>{ item.children.length === redactedFoundItemsLength ? 'R' : '' }</span>
//     }
//     return <span className={ redactedKeys.has(item.key) ? 'icon-redacted' : '' }>{ redactedKeys.has(item.key) ? 'R' : ''}</span>
//   }
//
//   return <span className=""></span>
// }

// const generateStateParent = (item, redactedKeys, checkedKeys) => {
//
//   const children = getListChildren(item)
//   if (checkedKeys && checkedKeys.length > 0) {
//     let redactedParent = true
//     if (children.length) {
//       children.forEach((item) => {
//         if (!checkedKeys.includes(item)) {
//           redactedParent = false
//         }
//       })
//     } else {
//       redactedParent = false
//     }
//     return <span className={ redactedParent ? 'icon-redacted' : 'icon-non-redacted' }>{ redactedParent ? 'R' : ''}</span>
//   }
//   return <span className="icon-non-redacted"></span>
// }
// const generateTopParentStatusRedactedStandard = (item, redactedKeys, checkedKeys, redactedDisplayHover, onCheckSelectedTree) => {
//   if (redactedDisplayHover.key === item.key) {
//     return  <span onClick={(e) => onCheckSelectedTree(e)} className='icon-v17-redaction-hover'></span>
//   }
//   return  <span className='icon-v17-redaction-hover space-empty'></span>
// }
export const stopPropagationTopSelect = (e) => {
  e.stopPropagation()
  e.preventDefault()
}
export const generateTitle = (item,
  categoriesColors,
  expandedKeys,
  expandHideCategory,
  docAnnotationsColors,
  categoriesVisible,
  toggleColorPicker,
  showHideCategory,
  changeColorPicker,
  closeAndSaveColorPicker,
  displayColorPicker,
  redactedKeys,
  collapseAnnotateSelectModal,
  checkedKeys,
  selectedChildrenBold,
  isOcr,
  onMouseEnterItemsTree,
  redactedDisplayHover,
  onSelectFindElementTree,
  onCheckCategoryDisabled,
  onCheckSelectedTree,
  showVisibleCategory, statusFilterReview, keysSavedOcr,
                              alreadyRedactedKeys, xeroxUpload, keysSavedXerox, lockSmartViewChanges) => {
  switch (item.identifier) {
    case 'top-parent': {
      return<div className="update-content-top-parent" onMouseEnter={(e) => onMouseEnterItemsTree(e, item)}>
      {/*  { !item.isOcr &&*/}
      {/*  generateTopParentStatusRedactedStandard(item, redactedKeys, checkedKeys, redactedDisplayHover, onCheckSelectedTree)*/}
      {/*  }*/}
      {/*  <div className="top-parent" onClick={(e) => stopPropagationTopSelect(e)}>*/}
      {/*    <span>{ item.title }</span>*/}
      {/*  /!*<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" className="anticon-message" onClick={ (e) => collapseAnnotateSelectModal(e, true) }>*!/*/}
      {/*  /!*  <g fill="none" fillRule="evenodd" stroke="#222B38" strokeWidth="1.452">*!/*/}
      {/*  /!*    <path strokeLinecap="round" strokeLinejoin="round" d="M15.55 3.044H3.03c-.711 0-1.288.595-1.288 1.329v5.822c0 .734.577 1.328 1.289 1.328h1.578v4.296l3.512-4.296h7.43c.711 0 1.288-.594 1.288-1.328V4.373c0-.734-.577-1.329-1.289-1.329z"/>*!/*/}
      {/*  /!*    <path strokeLinecap="square" d="M5.516 6.097h8.129M5.516 8.71h5.226"/>*!/*/}
      {/*  /!*  </g>*!/*/}
      {/*  /!*</svg>*!/*/}
      {/*</div>*/}
      </div>
    }
    case 'category-parent':
      return (
          <React.Fragment>
            { item && item.children && item.children.length > 0 ?
              <div className="asset-panel"
                   style={{backgroundColor: `${categoriesColors ? categoriesColors[item.key] : 'rgba(1,1,1,1)'}`}}>
                <div className="asset-panel__left-container"
                     onMouseEnter={(e) => onMouseEnterItemsTree(e, item)}>
                  {!item.isOcr && !isOcr ?
                  generateParentStatusRedactedStandard(item, redactedKeys, checkedKeys, redactedDisplayHover, onCheckSelectedTree, lockSmartViewChanges) :
                      generateParentStatusRedactedStandardOcr(item, redactedKeys, checkedKeys, redactedDisplayHover, onCheckSelectedTree, lockSmartViewChanges)
                  }
                  <span onClick={(e) => onCheckCategoryDisabled(e)}
                        className={categoriesVisible[item.key] ?
                            "asset-panel__left-container__category-name asset-panel__left-container__update-category-name" :
                            "asset-panel__left-container__category-name asset-panel__left-container__update-category-name-invisible" }>{item.title}</span>
                  {expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ?
                      <span className={ categoriesVisible[item.key] ? "icon-expand-category icon-v21-arrow-bold" : "icon-expand-category-visible" }type="down" aria-hidden="true"
                            style={{width: '20px'}}
                            onClick={(e) => expandHideCategory(e, item.key, 'remove')}/> :
                      <span className={ categoriesVisible[item.key] ? "icon-expand-category icon-v21-arrow-bold rotate-arrow-bold" : "icon-expand-category-visible" } type="up" aria-hidden="true"
                            style={{width: '20px'}}
                            onClick={(e) => expandHideCategory(e, item.key, 'add')}/>
                  }
                </div>
                {generateExtraIcons(item.key, docAnnotationsColors, categoriesVisible, toggleColorPicker, showHideCategory, changeColorPicker, closeAndSaveColorPicker, displayColorPicker, showVisibleCategory, item)}
              </div> :
                <div className="asset-panel asset-panel-no-found">
                  <div className="asset-panel__left-container asset-panel-no-found__left-container-no-found">
                       <span
                             className="asset-panel__left-container__category-name asset-panel-no-found__left-container-no-found__category-name-no-found">{item.title}
                       </span>
                  </div>
                </div>
            }
          </React.Fragment>
      )
    case 'subcategory-parent':
      return (
        <div className="asset-panel">
          <div className="asset-panel__left-container"
               onMouseEnter={(e) => onMouseEnterItemsTree(e, item)}>
            { item.children.length === 0 &&
              <React.Fragment>
                {/*{ !item.isOcr &&*/}
                {/*generateParentStatusRedactedStandard(item, redactedKeys, checkedKeys, redactedDisplayHover, onCheckSelectedTree)*/}
                {/*}*/}
                <span className="asset-panel__left-container__subcategory-name size-subcategory-name" onClick={(e) => stopPropagation(e) }>{
                  item.title === 'ssn' ? 'Social Security Numbers' : item.title === 'password' ? 'Passwords' : item.title === 'creditcard' ?
                      'Credit Cards' : item.title
                }</span>
                {/*{ generateStateParent(item, redactedKeys, checkedKeys) }*/}
              </React.Fragment>
            }
            { item.children.length > 0 &&
              <React.Fragment>
                {/*{ !item.isOcr &&*/}
                {/*generateParentStatusRedactedStandard(item, redactedKeys, checkedKeys, redactedDisplayHover, onCheckSelectedTree)*/}
                {/*}*/}
                <span onClick={(e) => onCheckCategoryDisabled(e)} className="asset-panel__left-container__subcategory-name size-subcategory-name">{
                  item.title === 'ssn' ? 'Social Security Numbers' : item.title === 'password' ? 'Passwords' : item.title === 'creditcard' ?
                      'Credit Cards' : item.title
                }</span>
                {/*{ generateStateParent(item, redactedKeys, checkedKeys) }*/}
              </React.Fragment>
            }
          </div>
        </div>
      )
    default :
      return (
          <React.Fragment>
            { (statusFilterReview === 'review-all' || statusFilterReview === 'review-annotated') ?
        <div className="asset-panel"
          style={ { fontWeight: expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ? '400' : 'space-empty' } }>
            <div className="asset-panel__left-container"
                onMouseEnter={(e) => onMouseEnterItemsTree(e, item)}>
            { isOcr ?
                generateStatusRedactedStandardOcr(item, redactedKeys, checkedKeys, redactedDisplayHover, onCheckSelectedTree, keysSavedOcr, isOcr, alreadyRedactedKeys, lockSmartViewChanges) :
                xeroxUpload ?
                    generateStatusRedactedStandardXerox(item, redactedKeys, checkedKeys, redactedDisplayHover, onCheckSelectedTree, keysSavedXerox, xeroxUpload, alreadyRedactedKeys, lockSmartViewChanges) :
            generateStatusRedactedStandard(item, redactedKeys, checkedKeys, redactedDisplayHover, onCheckSelectedTree, keysSavedOcr, isOcr, lockSmartViewChanges)
            }
            <span
                className="asset-panel__left-container__child-name"
                onClick={(e) => onSelectFindElementTree(e, item)}
            >
              {_generateLastChildName(item, redactedKeys, selectedChildrenBold, expandedKeys, expandHideCategory, onSelectFindElementTree)}
              {/*{ !item.isOcr &&*/}
              {/*  generateStateRedactedTitle(item, redactedKeys, checkedKeys)*/}
              {/*}*/}
              {!(item.children && item.children.length > 0) &&
              <span className="child-page-number">{item.isOcr ? '' : xeroxUpload ? '' : `p.${item.pageNumber}`}</span>
              }
              {/*{!(item.children && item.children.length > 0 && item.isOcr) &&*/}
              {/*<span className="child-page-number">{ item.pageNumber === '0' ? '1' : '' }</span>*/}
              {/*}*/}
              {item.children.length > 0 &&
              <span className="child-names-length" style={{fontSize: '12px'}}>{item.children.length}</span>
              }
            </span>
            {/*{ item.children.length > 0 &&*/}
            {/*  <React.Fragment>*/}
            {/*    <span style={ { fontSize: '12px' } }>{ item.children.length }</span>*/}
            {/*    { expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ?*/}
            {/*      <Icon type="down" aria-hidden="true" style={ { width: '20px', height: '12px', fontSize: '12px' } }*/}
            {/*        onClick={ (e) => expandHideCategory(e, item.key, 'remove') }/> :*/}
            {/*      <Icon type="up" aria-hidden="true" style={ { width: '20px', height: '12px', fontSize: '12px' } }*/}
            {/*        onClick={ (e) => expandHideCategory(e, item.key, 'add') }/>*/}
            {/*    }*/}
            {/*  </React.Fragment>*/}
            {/*}*/}
          </div>
        </div> :
              <React.Fragment>
                { statusFilterReview === 'review-redacted' && item.status === 1 &&
                <div className="asset-panel"
                     style={ { fontWeight: expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ? '400' : 'space-empty' } }>
                  <div className="asset-panel__left-container"
                       onMouseEnter={(e) => onMouseEnterItemsTree(e, item)}>
                    { isOcr ?
                        generateStatusRedactedStandardOcr(item, redactedKeys, checkedKeys, redactedDisplayHover, onCheckSelectedTree, keysSavedOcr, isOcr, alreadyRedactedKeys, lockSmartViewChanges) :
                        xeroxUpload ?
                            generateStatusRedactedStandardXerox(item, redactedKeys, checkedKeys, redactedDisplayHover, onCheckSelectedTree, keysSavedXerox, xeroxUpload, alreadyRedactedKeys, lockSmartViewChanges) :
                        generateStatusRedactedStandard(item, redactedKeys, checkedKeys, redactedDisplayHover, onCheckSelectedTree, keysSavedOcr, isOcr, lockSmartViewChanges)
                    }
                    <span
                        className="asset-panel__left-container__child-name"
                        onClick={(e) => onSelectFindElementTree(e, item)}
                    >
                 { _generateLastChildName(item, redactedKeys, selectedChildrenBold, expandedKeys, expandHideCategory, onSelectFindElementTree)}
                      {!(item.children && item.children.length > 0) &&
                      <span className="child-page-number">{item.isOcr ? '' : xeroxUpload ? '' : `p.${item.pageNumber}`}</span>
                      }
                      {item.children.length > 0 &&
                      <span className="child-names-length" style={{fontSize: '12px'}}>{item.children.length}</span>
                      }
                 </span>
                  </div>
                </div>
                }
              </React.Fragment>
            }
          </React.Fragment>
      )
  }
}
const generateParentStatusRedactedStandard = (item, redactedKeys, checkedKeys, redactedDisplayHover, onCheckSelectedTree, lockSmartViewChanges) => {
  const children = getListChildren(item)
  if (redactedDisplayHover.key === item.key) {
    return  <span onClick={(e) => onCheckSelectedTree(e, item)} className='icon-v17-redaction-hover'></span>
  }
  if (item.identifier === 'category-parent' && item.redactAllChild && item.children.length !== 0) {
    return <span onClick={(e) => onCheckSelectedTree(e, item)} className='icon-v17-redaction'></span>
  }
  if (checkedKeys && checkedKeys.length > 0) {
    let redactedParent = true
    if (children.length) {
      children.forEach((item2) => {
        if (!checkedKeys.includes(item2)) {
          redactedParent = false
        }
      })
    } else {
      redactedParent = false
    }
    return <span onClick={(e) => onCheckSelectedTree(e, item)} className={ redactedParent ? 'icon-v17-redaction' : 'icon-v17-redaction-hover space-empty' }></span>
  }
  return  <span onClick={(e) => onCheckSelectedTree(e, item)} className='icon-v17-redaction-hover space-empty'></span>
}
const generateParentStatusRedactedStandardOcr = (item, redactedKeys, checkedKeys, redactedDisplayHover, onCheckSelectedTree) => {
  const children = getListChildren(item)
  if (redactedDisplayHover.key === item.key) {
    return  <span onClick={(e) => onCheckSelectedTree(e, item)} className='icon-v17-redaction-hover'></span>
  }
  if (item.identifier === 'category-parent' && item.redactAllChild && item.children.length !== 0) {
    return <span onClick={(e) => onCheckSelectedTree(e, item)} className='icon-v17-redaction'></span>
  }
  if (checkedKeys && checkedKeys.length > 0) {
    let redactedParent = true
    if (children.length) {
      children.forEach((item2) => {
        if (!checkedKeys.includes(item2)) {
          redactedParent = false
        }
      })
    } else {
      redactedParent = false
    }
    return <span onClick={(e) => onCheckSelectedTree(e, item)} className={ redactedParent ? 'icon-v17-redaction' : 'icon-v17-redaction-hover space-empty' }></span>
  }
  return  <span className='icon-v17-redaction-hover space-empty'></span>
}
const generateStatusRedactedStandard = (item, redactedKeys, checkedKeys, redactedDisplayHover, onCheckSelectedTree, keysSavedOcr, isOcr) => {
  let checkListKeys = []
  checkListKeys = new Set([...checkedKeys])
  if (redactedDisplayHover.key === item.key) {
    return  <span onClick={(e) => onCheckSelectedTree(e, item)} className='icon-v17-redaction-hover'></span>
  } else if (isOcr && keysSavedOcr && keysSavedOcr.length > 0) {
    return <span onClick={(e) => onCheckSelectedTree(e, item)} className={keysSavedOcr.includes(item.key.toUpperCase()) ? 'icon-v17-redaction' : 'icon-v17-redaction-hover space-empty' }></span>
  } else if (checkListKeys && checkListKeys.size > 0) {
    if (item && item.children && item.children.length > 0) {
      const childKeysRedact = item.children.map((child) => child.key)
      const redactedFoundItemsKeysLength = childKeysRedact.filter((child) => checkListKeys.has(child)).length
      return <span onClick={(e) => onCheckSelectedTree(e, item)} className={ item.children.length === redactedFoundItemsKeysLength ? 'icon-v17-redaction' : 'icon-v17-redaction-hover space-empty' }></span>
    }
    return <span onClick={(e) => onCheckSelectedTree(e, item)} className={checkListKeys.has(item.key) ? 'icon-v17-redaction' : 'icon-v17-redaction-hover space-empty' }></span>
  }
  if (checkedKeys && checkedKeys.length > 0 && redactedKeys && redactedKeys.size > 0) {
    if (item && item.children && item.children.length > 0) {
      const childKeys = item.children.map((child) => child.key)
      const redactedFoundItemsLength = childKeys.filter((child) => redactedKeys.has(child)).length

      return <span className={ item.children.length === redactedFoundItemsLength ? 'icon-v17-redaction' : 'icon-v17-redaction-hover space-empty' }></span>
    }
    return <span onClick={(e) => onCheckSelectedTree(e, item)} className={ redactedKeys.has(item.key) ? 'icon-v17-redaction' : 'icon-v17-redaction-hover space-empty' }></span>
  }
  return  <span className='icon-v17-redaction-hover space-empty'></span>
}

const generateStatusRedactedStandardOcr = (item, redactedKeys, checkedKeys, redactedDisplayHover, onCheckSelectedTree, keysSavedOcr, isOcr, alreadyRedactedKeys) => {
  let checkListKeys = []
  checkListKeys = new Set([...checkedKeys])
  if (isOcr) {
    if (redactedDisplayHover.key === item.key) {
      return <span onClick={(e) => onCheckSelectedTree(e, item)} className='icon-v17-redaction-hover'></span>
    } else
      if (isOcr && keysSavedOcr && keysSavedOcr.length > 0 && alreadyRedactedKeys.standard && alreadyRedactedKeys.standard.length > 0) {
      return <span onClick={(e) => onCheckSelectedTree(e, item)}
                   className={keysSavedOcr.includes(item.key.toUpperCase()) ? 'icon-v17-redaction' : 'icon-v17-redaction-hover space-empty'}></span>
    } else
      if (alreadyRedactedKeys.standard && alreadyRedactedKeys.standard.length > 0) {
      return <span onClick={(e) => onCheckSelectedTree(e, item)}
                   className={alreadyRedactedKeys.standard.includes(`${ item.key.toLowerCase() }-1`) ? 'icon-v17-redaction' : 'icon-v17-redaction-hover space-empty'}></span>
    }
    else
      if (alreadyRedactedKeys.standard && alreadyRedactedKeys.standard.length > 0 && checkListKeys && checkListKeys.size > 0 && keysSavedOcr && keysSavedOcr.length > 0) {
      if (item && item.children && item.children.length > 0) {
        const childKeysRedact = item.children.map((child) => child.key)
        const redactedFoundItemsKeysLength = childKeysRedact.filter((child) => checkListKeys.has(child)).length
        return <span onClick={(e) => onCheckSelectedTree(e, item)}
                     className={item.children.length === redactedFoundItemsKeysLength ? 'icon-v17-redaction' : 'icon-v17-redaction-hover space-empty'}></span>
      }
      return <span onClick={(e) => onCheckSelectedTree(e, item)}
                   className={checkListKeys.has(item.key) ? 'icon-v17-redaction' : 'icon-v17-redaction-hover space-empty'}></span>
    }
    return <span className='icon-v17-redaction-hover space-empty'></span>
  }
}

const generateStatusRedactedStandardXerox = (item, redactedKeys, checkedKeys, redactedDisplayHover, onCheckSelectedTree, keysSavedXerox, xeroxUpload, alreadyRedactedKeys) => {
  let checkListKeys = []
  checkListKeys = new Set([...checkedKeys])
  if (xeroxUpload) {
    if (redactedDisplayHover.key === item.key) {
      return <span onClick={(e) => onCheckSelectedTree(e, item)} className='icon-v17-redaction-hover'></span>
    } else
    if (xeroxUpload && keysSavedXerox && keysSavedXerox.length > 0 && alreadyRedactedKeys.standard && alreadyRedactedKeys.standard.length > 0) {
      return <span onClick={(e) => onCheckSelectedTree(e, item)}
                   className={keysSavedXerox.includes(item.key.toUpperCase()) ? 'icon-v17-redaction' : 'icon-v17-redaction-hover space-empty'}></span>
    } else
    if (alreadyRedactedKeys.standard && alreadyRedactedKeys.standard.length > 0) {
      return <span onClick={(e) => onCheckSelectedTree(e, item)}
                   className={alreadyRedactedKeys.standard.includes(`${ item.key.toLowerCase() }-1`) ? 'icon-v17-redaction' : 'icon-v17-redaction-hover space-empty'}></span>
    }
    else
    if (alreadyRedactedKeys.standard && alreadyRedactedKeys.standard.length > 0 && checkListKeys && checkListKeys.size > 0 && keysSavedXerox && keysSavedXerox.length > 0) {
      if (item && item.children && item.children.length > 0) {
        const childKeysRedact = item.children.map((child) => child.key)
        const redactedFoundItemsKeysLength = childKeysRedact.filter((child) => checkListKeys.has(child)).length
        return <span onClick={(e) => onCheckSelectedTree(e, item)}
                     className={item.children.length === redactedFoundItemsKeysLength ? 'icon-v17-redaction' : 'icon-v17-redaction-hover space-empty'}></span>
      }
      return <span onClick={(e) => onCheckSelectedTree(e, item)}
                   className={checkListKeys.has(item.key) ? 'icon-v17-redaction' : 'icon-v17-redaction-hover space-empty'}></span>
    }
    return <span className='icon-v17-redaction-hover space-empty'></span>
  }
}

export const stopPropagation = (e) => {
  e.stopPropagation()
  e.preventDefault()
}

const generateLastChildImages = (item, redactedKeys) => {
  if (redactedKeys && redactedKeys.size > 0) {
    if (item.children && item.children.length > 0) {
      const childKeys = item.children.map((child) => child.key)
      const redactedFoundItemsLength = childKeys.filter((child) => redactedKeys.has(child)).length
      return <span
        className={ item.children.length === redactedFoundItemsLength ? 'asset-panel__left-container__child-name--line-through' : '' }>{ item.title }</span>
    }
    return <span
      className={ redactedKeys.has(item.key) ? 'asset-panel__left-container__child-name--line-through' : '' }>{ item.title }</span>
  }
  return <span className=''>{ item.title }</span>
}

export const generateTitleTreeImages = (
  item,
  expandedKeys,
  expandHideCategory,
  removeSearchImage,
  redactedKeys
) => {
  switch (item.identifier) {
    // case 'top-parent': {
    //   return <div className="top-parent">
    //     <span className="title-parent-tree">Select all</span>
    //     <span className="left-container">
    //       <button className="icon-delete-b" onClick={ removeSearchImage }/>
    //     </span>
    //   </div>
    // }
    case 'category-parent': {
      return <div className="asset-panel">
        <div className="asset-panel__left-container">
          <span onClick={ expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ?
            (e) => expandHideCategory(e, item.key, 'remove') :
            (e) => expandHideCategory(e, item.key, 'add')
          }
          className="asset-panel__left-container__category-name">{ item.title }</span>
          { expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ?
            <Icon type="down" aria-hidden="true" style={ { width: '20px', height: '15px' } }
              onClick={ (e) => expandHideCategory(e, item.key, 'remove') }/> :
            <Icon type="up" aria-hidden="true" style={ { width: '20px', height: '15px' } }
              onClick={ (e) => expandHideCategory(e, item.key, 'add') }/>
          }
        </div>
      </div>
    }
    default :
      return (
        <div className="asset-panel">
          <div className="asset-panel__children-image">
            <span className="icon-v2-image"/>
            <span className="asset-panel__children-image__children-image-name">{ generateLastChildImages(item, redactedKeys) }</span>
          </div>
        </div>
      )
  }
}
export const renderTreeSearchImages = (
  treeData,
  expandedKeys,
  expandHideCategory,
  removeSearchImage,
  redactedKeys,
) =>
  treeData && treeData.map((item, i) => {
    if (item.children !== null) {
      if (item.identifier === 'category-parent') {
        return (
          <TreeNode title={generateTitleTreeImages(item, expandedKeys, expandHideCategory, removeSearchImage, redactedKeys)}
            key={item.key}
            data-key={ item.key }
            dataRef={ item }
            selectable={ false }
            className={ `${ item.identifier === 'category-parent' ? 'category-tree-node' : '' } ${ item.identifier === 'top-parent' ? 'disabled-expand-icon' : '' }` }>
            { renderTreeSearchImages(item.children, expandedKeys, expandHideCategory, removeSearchImage, redactedKeys) }
          </TreeNode>
        )
      }
      return (
        <TreeNode title={generateTitleTreeImages(item, expandedKeys, expandHideCategory, removeSearchImage, redactedKeys)}
          key={item.key}
          data-key={ item.key }
          dataRef={ item }
          selectable={ true }
          className={ `${ item.identifier === 'category-parent' ? 'category-tree-node' : '' } ${ item.identifier === 'top-parent' ? 'disabled-expand-icon' : '' }` }>
          { renderTreeSearchImages(item.children, expandedKeys, expandHideCategory, removeSearchImage, redactedKeys) }
        </TreeNode>
      )
    }
    return <TreeNode key={item.key}
      title={generateTitleTreeImages(item, expandedKeys, expandHideCategory, removeSearchImage, redactedKeys)}
      data-key={ item.key }
      dataRef={item}
      selectable={ false }
      className={ `${ item.identifier === 'category-parent' ? 'category-tree-node' : '' } ${ item.identifier === 'top-parent' ? 'disabled-expand-icon' : '' }` }/>
  })

const generateLastChildSpell = (item, redactedKeys, selectedChildrenBold) => {

  if (redactedKeys && redactedKeys.size > 0) {
    if (item.children && item.children.length > 0) {
      const childKeys = item.children.map((child) => child.key)
      const redactedFoundItemsLength = childKeys.filter((child) => redactedKeys.has(child)).length
      return <span
        className={ item.children.length === redactedFoundItemsLength ? 'asset-panel__left-container__child-name--line-through child-size' : 'child-size' }>{ item.title }</span>
    }
    return <span
      className={ redactedKeys.has(item.key) ? 'asset-panel__left-container__child-name--line-through child-size' : 'child-size' }>{ item.title }</span>
  }
  return <span
    className={ selectedChildrenBold.includes(item.key) ? 'child-size child-click-bold' : 'child-size' }>{ item.title }</span>
}

export const generateTitleSpellChecker = (
  item,
  expandedKeys,
  expandHideCategory,
  onDeleteCategory,
  redactedKeys,
  selectedChildrenBold) => {
  switch (item.identifier) {
    // case 'top-parent': {
    //   return <div className="top-parent">
    //     <span className="title-parent-tree">{ item.title }</span>
    //     <span className="left-container">
    //       <button className="icon-delete-b" onClick={ onDeleteCategory }
    //       />
    //     </span>
    //   </div>
    // }
    case 'category-parent': {
      return <div className="asset-panel"onClick={ expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ?
        (e) => expandHideCategory(e, item.key, 'remove') :
        (e) => expandHideCategory(e, item.key, 'add')
      }
      >
        <div className="asset-panel__left-container asset-panel__left-container-spell">
          <span className="asset-panel__left-container__category-name category-name-spell">{ `Page ${ item.page }` }</span>
          { expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ?
            <Icon type="down" aria-hidden="true" style={ { width: '20px', height: '15px' } }
              onClick={ (e) => expandHideCategory(e, item.key, 'remove') }/> :
            <Icon type="up" aria-hidden="true" style={ { width: '20px', height: '15px' } }
              onClick={ (e) => expandHideCategory(e, item.key, 'add') }/>
          }
        </div>
      </div>
    }
    default :
      return (
        <div className="asset-panel">
          <div className="asset-panel__children-image">
            <span className="asset-panel__children-image__children-image-name children-spell">{ generateLastChildSpell(item, redactedKeys, selectedChildrenBold) }</span>
          </div>
        </div>
      )
  }
}

export const renderTreeSpellChecker = (
  treeData,
  expandedKeys,
  expandHideCategory,
  onDeleteCategory,
  redactedKeys,
  selectedChildrenBold) =>
  treeData && treeData.map((item, i) => {
    if (item.children !== null) {
      if (item.identifier === 'category-parent') {
        return (
          <TreeNode
            title={ generateTitleSpellChecker(item, expandedKeys, expandHideCategory, onDeleteCategory, redactedKeys, selectedChildrenBold) }
            key={ item.key }
            dataRef={ item }
            data-key={ item.key }
            selectable={ false }
            className={ `${ item.identifier === 'category-parent' ? 'category-tree-node' : '' } ${ item.identifier === 'top-parent' ? 'disabled-expand-icon' : '' }` }
          >
            { renderTreeSpellChecker(item.children, expandedKeys, expandHideCategory, onDeleteCategory, redactedKeys, selectedChildrenBold)}
          </TreeNode>
        )
      }
      return (
        <TreeNode
          title={ generateTitleSpellChecker(item, expandedKeys, expandHideCategory, onDeleteCategory, redactedKeys, selectedChildrenBold) }
          key={ item.key }
          dataRef={ item }
          data-key={ item.key }
          selectable={ true }
          className={ `${ item.identifier === 'category-parent' ? 'category-tree-node' : '' } ${ item.identifier === 'top-parent' ? 'disabled-expand-icon' : '' }` }
        >
          { renderTreeSpellChecker(item.children, expandedKeys, expandHideCategory, onDeleteCategory, redactedKeys, selectedChildrenBold)}
        </TreeNode>
      )
    }
    return (
      <TreeNode
        title={ generateTitleSpellChecker(item, expandedKeys, expandHideCategory, onDeleteCategory, redactedKeys, selectedChildrenBold) }
        key={ item.key }
        dataRef={ item }
        data-key={ item.key }
        selectable={ false }
        className={ `${ item.identifier === 'category-parent' ? 'category-tree-node' : '' } ${ item.identifier === 'top-parent' ? 'disabled-expand-icon' : '' }` }
      >
      </TreeNode>
    )
  })
const generateChildHistory = (item, onSelectFindElementTreeOnSearch) => {

  return <div onClick={(e) => onSelectFindElementTreeOnSearch(e, item)} className="children-history-contain">
    <span className="children-history">{ item.name }</span>
     <span>{`p.${item.pageNumber}`}</span>
     </div>
}
export const generateExtraShowHistory = (item, showHideCategoryHistory, categoriesVisibleSearch, onMouseEnterVisible, onMouseLeaveVisible, visibleIconShow) => {
  return (
      <div className="content-visible-category"
        >
        { visibleIconShow === item.key &&
          <React.Fragment>
            { categoriesVisibleSearch.includes(item.key) ?
                <span onClick={(e) => showHideCategoryHistory(e, item, true)} className="icon-v12-unvisible"></span> :
                <span className="icon-v12-visible" onClick={(e) => showHideCategoryHistory(e, item, false)}></span>
            }
          </React.Fragment>
        }
      </div>
  )
}
const statusRedactedHistory = (item, visibleRedactedStatus, onRedactAiSearch, checkedKeys) => {
  if (visibleRedactedStatus === item.key) {
    return (
        <span onClick={(e) => onRedactAiSearch(e, item)} className='icon-v17-redaction-hover'></span>
    )
  } else if (item.redacted) {
    return (
        <span onClick={(e) => onRedactAiSearch(e, item)} className='icon-v17-redaction'></span>
    )
  } else if (checkedKeys.includes(item.key)) {
    return (
        <span onClick={(e) => onRedactAiSearch(e, item)} className='icon-v17-redaction'></span>
    )
  } else return (
    <span className="non-redacted-search"></span>
  )
}
const statusRedactedHistoryItem = (item, visibleRedactedStatusChild, onRedactAiSearch, redactedListKeysItems, checkedKeys, redactedListKeys) => {
  if (visibleRedactedStatusChild === item.key) {
    return (
        <span onClick={(e) => onRedactAiSearch(e, item)} className='icon-v17-redaction-hover'></span>
    )
  } else if (redactedListKeysItems && redactedListKeysItems.length && redactedListKeysItems.includes(item.key)) {
    return (
        <span onClick={(e) => onRedactAiSearch(e, item)} className='icon-v17-redaction'></span>
    )
  } else if (checkedKeys && checkedKeys.length && checkedKeys.includes(item.key)) {
    return (
        <span onClick={(e) => onRedactAiSearch(e, item)} className='icon-v17-redaction'></span>
    )
  }  else if (redactedListKeys && redactedListKeys.length && redactedListKeys.includes(item.key)) {
    return (
        <span onClick={(e) => onRedactAiSearch(e, item)} className='icon-v17-redaction'></span>
    )
  } else if (item.redacted) {
    return (
        <span onClick={(e) => onRedactAiSearch(e, item)} className='icon-v17-redaction'></span>
    )
  } else return (
      <span className="non-redacted-search"></span>
  )
}
export const generateTitleSearchHistory = (
    item,
    expandedKeys,
    expandHideCategory,
    onSelectFindElementTreeOnSearch,
    showHideCategoryHistory,
    categoriesVisibleSearch, onMouseEnterVisible, onMouseLeaveVisible, visibleIconShow, onMouseEnterRedacted, onMouseLeaveRedacted, visibleRedactedStatus, onRedactAiSearch,
    visibleRedactedStatusChild, onMouseEnterRedactedChild, onMouseLeaveRedactedChild, redactedListKeysItems, onDefaultCategory, checkedKeys, redactedListKeys) => {
  switch (item.identifier) {
    case 'category-parent': {
      return <div className="asset-panel-history-parent"
                  onMouseEnter={() => onMouseEnterVisible(item)}
                  onMouseLeave={() => onMouseLeaveVisible()}
                  onClick={ (e) => onDefaultCategory(e)}
                  // onClick={ expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ?
                  //     (e) => expandHideCategory(e, item.key, 'remove') :
                  //     (e) => expandHideCategory(e, item.key, 'add')
                  // }
      >
        <div className="asset-panel-history-parent__container-search"
             onMouseEnter={() => onMouseEnterRedacted(item)}
             onMouseLeave={() => onMouseLeaveRedacted()}
        onClick={ (e) => onDefaultCategory(e)}>
          { statusRedactedHistory(item, visibleRedactedStatus, onRedactAiSearch, checkedKeys) }
          <span className="parent-search-history">{ item.name }</span>
          { expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ?
              <Icon type="down" aria-hidden="true" style={ { width: '20px', height: '15px' } }
                    onClick={ (e) => expandHideCategory(e, item.key, 'remove') }/> :
              <Icon type="up" aria-hidden="true" style={ { width: '20px', height: '15px' } }
                    onClick={ (e) => expandHideCategory(e, item.key, 'add') }/>
          }
        </div>
        { generateExtraShowHistory(item, showHideCategoryHistory, categoriesVisibleSearch, onMouseEnterVisible, onMouseLeaveVisible, visibleIconShow) }
      </div>
    }
    default :
      return (
          <div className="asset-panel-history"
               onMouseEnter={() => onMouseEnterRedactedChild(item)}
               onClick={ (e) => stopPropagationHistoryItem(e)}
               onMouseLeave={() => onMouseLeaveRedactedChild()}>
            { statusRedactedHistoryItem(item, visibleRedactedStatusChild, onRedactAiSearch, redactedListKeysItems, checkedKeys, redactedListKeys) }
              { generateChildHistory(item, onSelectFindElementTreeOnSearch) }
          </div>
      )
  }
}
export const stopPropagationHistoryItem = (e) => {
  e.stopPropagation()
  e.preventDefault()
}
export const renderTreeSearchHistory = (
    treeData,
    expandedKeys,
    expandHideCategory,
    onSelectFindElementTreeOnSearch,
    showHideCategoryHistory,
    categoriesVisibleSearch,
    onMouseEnterVisible,
    onMouseLeaveVisible,
    visibleIconShow, onMouseEnterRedacted, onMouseLeaveRedacted, visibleRedactedStatus, onRedactAiSearch,
    visibleRedactedStatusChild, onMouseEnterRedactedChild, onMouseLeaveRedactedChild, redactedListKeysItems, onDefaultCategory,
    checkedKeys, redactedListKeys) =>
    treeData && treeData.length > 0 && treeData.map((item, i) => {
      if (item.children !== null) {
        if (item.identifier === 'category-parent') {
          return (
            <TreeNode
                title={generateTitleSearchHistory(item, expandedKeys, expandHideCategory, onSelectFindElementTreeOnSearch, showHideCategoryHistory, categoriesVisibleSearch,
                    onMouseEnterVisible, onMouseLeaveVisible, visibleIconShow, onMouseEnterRedacted, onMouseLeaveRedacted, visibleRedactedStatus, onRedactAiSearch,
                    visibleRedactedStatusChild, onMouseEnterRedactedChild, onMouseLeaveRedactedChild, redactedListKeysItems, onDefaultCategory, checkedKeys, redactedListKeys)}
                key={item.key}
                dataRef={item}
                data-key={item.key}
                selectable={false}
                className="category-tree-node-parent"
            >
              {renderTreeSearchHistory(item.children, expandedKeys, expandHideCategory, onSelectFindElementTreeOnSearch, showHideCategoryHistory, categoriesVisibleSearch,
                  onMouseEnterVisible, onMouseLeaveVisible, visibleIconShow, onMouseEnterRedacted, onMouseLeaveRedacted, visibleRedactedStatus, onRedactAiSearch,
                  visibleRedactedStatusChild, onMouseEnterRedactedChild, onMouseLeaveRedactedChild, redactedListKeysItems, onDefaultCategory, checkedKeys, redactedListKeys)}
            </TreeNode>
          )
        }
        return (
            <TreeNode
                title={generateTitleSearchHistory(item, expandedKeys, expandHideCategory, onSelectFindElementTreeOnSearch, showHideCategoryHistory, categoriesVisibleSearch,
                    onMouseEnterVisible, onMouseLeaveVisible, visibleIconShow, onMouseEnterRedacted, onMouseLeaveRedacted, visibleRedactedStatus, onRedactAiSearch,
                    visibleRedactedStatusChild, onMouseEnterRedactedChild, onMouseLeaveRedactedChild, redactedListKeysItems, onDefaultCategory, checkedKeys, redactedListKeys)}
                key={item.key}
                dataRef={item}
                data-key={item.key}
                selectable={false}
                className="category-tree-node-child"
            >
              { renderTreeSearchHistory(item.children, expandedKeys, expandHideCategory, onSelectFindElementTreeOnSearch, showHideCategoryHistory, categoriesVisibleSearch,
                  onMouseEnterVisible, onMouseLeaveVisible, visibleIconShow, onMouseEnterRedacted, onMouseLeaveRedacted, visibleRedactedStatus, onRedactAiSearch,
                  visibleRedactedStatusChild, onMouseEnterRedactedChild, onMouseLeaveRedactedChild, redactedListKeysItems, onDefaultCategory, checkedKeys, redactedListKeys) }
            </TreeNode>
        )
      }
      return true
    })
export const renderTreeNodes = (data,
  categoriesColors,
  expandedKeys,
  expandHideCategory,
  docAnnotationsColors,
  categoriesVisible,
  toggleColorPicker,
  showHideCategory,
  changeColorPicker,
  closeAndSaveColorPicker,
  displayColorPicker,
  redactedKeys,
  collapseAnnotateSelectModal,
  checkedKeys,
  selectedChildrenBold,
  isOcr, onMouseEnterItemsTree, redactedDisplayHover,
  onSelectFindElementTree, onCheckCategoryDisabled, onCheckSelectedTree, showVisibleCategory, statusFilterReview, keysSavedOcr, alreadyRedactedKeys,
                                xeroxUpload, keysSavedXerox, lockSmartViewChanges) =>
// eslint-disable-next-line array-callback-return
  data && data.map((item) => {
    if (item) {
      if (item.children && item.children.length > 0) {
        if (item.key.includes('found-parent-all') || item.identifier === 'category-parent' || item.identifier === 'top-parent') {
          return (
            <TreeNode
              title={ generateTitle(item,
                categoriesColors,
                expandedKeys,
                expandHideCategory,
                docAnnotationsColors,
                categoriesVisible,
                toggleColorPicker,
                showHideCategory,
                changeColorPicker,
                closeAndSaveColorPicker,
                displayColorPicker,
                redactedKeys,
                collapseAnnotateSelectModal,
                checkedKeys,
                selectedChildrenBold,
                isOcr,
                  onMouseEnterItemsTree,
                  redactedDisplayHover,
                  onSelectFindElementTree,
                  onCheckCategoryDisabled,
                  onCheckSelectedTree,
                  showVisibleCategory,
                  statusFilterReview,
                  keysSavedOcr,
                  alreadyRedactedKeys,
                  xeroxUpload,
                  keysSavedXerox,
                  lockSmartViewChanges) }
              key={ item.key } data-key={ item.key } dataRef={ item }
              selectable={ false }
              //icon={({ selected }) => (selected ? <span onClick={(e) => onClickIconItem(e)} className="icon-v17-redaction"/> : <span onClick={(e) => onClickIconItem(e)} className="icon-v17-redaction-active"/> )}
              className={ `${ item.identifier === 'category-parent' ? 'category-tree-node' : '' } ${ item.key === 'select-all' ? 'disabled-expand-icon' : '' }` }>
              { renderTreeNodes(
                item.children,
                categoriesColors,
                expandedKeys,
                expandHideCategory,
                docAnnotationsColors,
                categoriesVisible,
                toggleColorPicker,
                showHideCategory,
                changeColorPicker,
                closeAndSaveColorPicker,
                displayColorPicker,
                redactedKeys,
                collapseAnnotateSelectModal,
                checkedKeys,
                selectedChildrenBold,
                isOcr,
                  onMouseEnterItemsTree,
                  redactedDisplayHover,
                  onSelectFindElementTree,
                  onCheckCategoryDisabled,
                  onCheckSelectedTree,
                  showVisibleCategory,
                  statusFilterReview,
                  keysSavedOcr,
                  alreadyRedactedKeys,
                  xeroxUpload,
                  keysSavedXerox,
                  lockSmartViewChanges
              ) }
            </TreeNode>
          )
        }
        return (
          <TreeNode
            title={ generateTitle(item,
              categoriesColors,
              expandedKeys,
              expandHideCategory,
              docAnnotationsColors,
              categoriesVisible,
              toggleColorPicker,
              showHideCategory,
              changeColorPicker,
              closeAndSaveColorPicker,
              displayColorPicker,
              redactedKeys,
              collapseAnnotateSelectModal,
              checkedKeys,
              selectedChildrenBold,
              isOcr,
                onMouseEnterItemsTree,
                redactedDisplayHover,
                onSelectFindElementTree,
                onCheckCategoryDisabled,
                onCheckSelectedTree,
                showVisibleCategory,
                statusFilterReview,
                keysSavedOcr,
                alreadyRedactedKeys,
                xeroxUpload,
                keysSavedXerox,
                lockSmartViewChanges) }
            key={ item.key } data-key={ item.key } dataRef={ item }
            selectable={ false }
            //icon={({ selected }) => (selected ? <span onClick={(e) => onClickIconItem(e)} className="icon-v17-redaction"/> : <span onClick={(e) => onClickIconItem(e)} className="icon-v17-redaction-active"/> )}
            className={ `${ item.identifier === 'category-parent' ? 'category-tree-node' : '' } ${ item.key === 'select-all' ? 'disabled-expand-icon' : '' }` }>
            { renderTreeNodes(
              item.children,
              categoriesColors,
              expandedKeys,
              expandHideCategory,
              docAnnotationsColors,
              categoriesVisible,
              toggleColorPicker,
              showHideCategory,
              changeColorPicker,
              closeAndSaveColorPicker,
              displayColorPicker,
              redactedKeys,
              collapseAnnotateSelectModal,
              checkedKeys,
              selectedChildrenBold,
              isOcr,
                onMouseEnterItemsTree,
                redactedDisplayHover,
                onSelectFindElementTree,
                onCheckCategoryDisabled,
                onCheckSelectedTree,
                showVisibleCategory,
                statusFilterReview,
                keysSavedOcr,
                alreadyRedactedKeys,
                xeroxUpload,
                keysSavedXerox,
                lockSmartViewChanges
            ) }
          </TreeNode>
        )
      }
      if (statusFilterReview === 'review-redacted' && item.status === 1) {
        return <TreeNode key={ item.key }
                         title={ generateTitle(item,
                             categoriesColors,
                             expandedKeys,
                             expandHideCategory,
                             docAnnotationsColors,
                             categoriesVisible,
                             toggleColorPicker,
                             showHideCategory,
                             changeColorPicker,
                             closeAndSaveColorPicker,
                             displayColorPicker,
                             redactedKeys,
                             collapseAnnotateSelectModal,
                             checkedKeys,
                             selectedChildrenBold,
                             isOcr,
                             onMouseEnterItemsTree,
                             redactedDisplayHover,
                             onSelectFindElementTree,
                             onCheckCategoryDisabled,
                             onCheckSelectedTree,
                             showVisibleCategory,
                             statusFilterReview,
                             keysSavedOcr,
                             alreadyRedactedKeys,
                             xeroxUpload,
                             keysSavedXerox,
                             lockSmartViewChanges) }
                         data-key={ item.key } dataRef={ item }
                         selectable={ false }
            //icon={({ selected }) => (selected ? <span onClick={(e) => onClickIconItem(e)} className="icon-v17-redaction"/> : <span onClick={(e) => onClickIconItem(e)} className="icon-v17-redaction-active"/> )}
                         className={ `${ item.identifier === 'category-parent' ? 'category-tree-node' : '' } ${ item.key === 'select-all' ? 'disabled-expand-icon' : '' }` }
        />
      } else if (statusFilterReview === 'review-all' || statusFilterReview === 'review-annotated') {
        return <TreeNode key={ item.key }
                         title={ generateTitle(item,
                             categoriesColors,
                             expandedKeys,
                             expandHideCategory,
                             docAnnotationsColors,
                             categoriesVisible,
                             toggleColorPicker,
                             showHideCategory,
                             changeColorPicker,
                             closeAndSaveColorPicker,
                             displayColorPicker,
                             redactedKeys,
                             collapseAnnotateSelectModal,
                             checkedKeys,
                             selectedChildrenBold,
                             isOcr,
                             onMouseEnterItemsTree,
                             redactedDisplayHover,
                             onSelectFindElementTree,
                             onCheckCategoryDisabled,
                             onCheckSelectedTree,
                             showVisibleCategory,
                             statusFilterReview,
                             keysSavedOcr,
                             alreadyRedactedKeys,
                             xeroxUpload,
                             keysSavedXerox,
                             lockSmartViewChanges) }
                         data-key={ item.key } dataRef={ item }
                         selectable={ false }
            //icon={({ selected }) => (selected ? <span onClick={(e) => onClickIconItem(e)} className="icon-v17-redaction"/> : <span onClick={(e) => onClickIconItem(e)} className="icon-v17-redaction-active"/> )}
                         className={ `${ item.identifier === 'category-parent' ? 'category-tree-node' : '' } ${ item.key === 'select-all' ? 'disabled-expand-icon' : '' }` }
        />
      }
    }
  })

export const generateExtraIcons = (key, docAnnotationsColors, categoriesVisible, toggleColorPicker, showHideCategory, changeColorPicker, closeAndSaveColorPicker, displayColorPicker, showVisibleCategory, item) => {
  let record = ''
  for (let i = 0; i < docAnnotationsColors.length; i++) {
    if (docAnnotationsColors[i].category === key) {
      record = docAnnotationsColors[i]
    }
  }
  return (
    <div className="asset-panel__icons">
      {/*<span className="icon-colourpalette-b" aria-hidden="true"*/}
      {/*  onClick={ (event) => toggleColorPicker(key, !displayColorPicker[key], event) }/>*/}
          { categoriesVisible[key] ?
              <React.Fragment>
                { showVisibleCategory.key === key ?
                  <span className="icon-v12-visible" aria-hidden="true"
                        onClick={(e) => showHideCategory(e, key, false, item)}/> :
                    <React.Fragment></React.Fragment>
                }
              </React.Fragment> :
              <span className="icon-v12-unvisible" aria-hidden="true"
                    onClick={(e) => showHideCategory(e, key, true, item)}/>
            }

      { displayColorPicker[key] ? <Popover
        trigger="click"
        placement="right"
        overlayClassName="custom-color-picker-popover"
        content={
          <CompactPicker
            colors={ [ 'rgba(107, 91, 149, 1)', 'rgba(254, 178, 54, 1)', 'rgba(214, 65, 97, 1)',
              'rgba(255, 123, 37, 1)', 'rgba(75, 195, 193, 1)', 'rgba(194, 194, 71, 1)', 'rgba(181, 231, 160, 1)',
              'rgba(191, 108, 64, 1)', 'rgba(62, 68, 68, 1)', 'rgba(130, 183, 75, 1)', 'rgba(64, 93, 39, 1)',
              'rgba(193, 148, 106, 1)', 'rgba(60, 136, 180, 1)', 'rgba(200, 100, 91, 1)', 'rgba(238, 162, 154, 1)',
              'rgba(201, 76, 76, 1)', 'rgba(124, 108, 6, 1)', 'rgba(15, 195, 71, 1)', 'rgba(231, 82, 114, 1)',
              'rgba(238, 140, 54, 1)', 'rgba(92, 212, 209, 1)', 'rgba(211, 211, 88, 1)', 'rgba(198, 248, 177, 1)',
              'rgba(192, 125, 81, 1)', 'rgba(77, 100, 113, 1)', 'rgba(237, 226, 220, 1)', 'rgba(132, 206, 235, 1)' ] }
            disableAlpha={ true }
            color={ record.color }
            onSwatchHover={ changeColorPicker }
            onChange={ (color, e) => closeAndSaveColorPicker(record, key, e) }
          />
        }
        visible={displayColorPicker[key]}
        onVisibleChange={() => toggleColorPicker(key, false)}
      />: null }
    </div>
  )
}

const generateLastChidBusiness = (item, redactedKeys, selectedChildrenBold, expandedKeys, expandHideCategory, isOcr) => {
  if (redactedKeys && redactedKeys.size > 0) {
    if (item.children && item.children.length > 0) {
      const childKeys = item.children.map((child) => child.key)
      const redactedFoundItemsLength = childKeys.filter((child) => redactedKeys.has(child)).length
      return <span>
        <span
        className={ item.children.length === redactedFoundItemsLength ? 'asset-panel__left-container__child-name--line-through' : '' }>{ item.title }</span>
      { item.children.length > 0 &&
      <React.Fragment>
        { expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ?
            <Icon type="down" aria-hidden="true" style={ { width: '20px', height: '12px', fontSize: '12px' } }
                  onClick={ (e) => expandHideCategory(e, item.key, 'remove') }/> :
            <Icon type="up" aria-hidden="true" style={ { width: '20px', height: '12px', fontSize: '12px' } }
                  onClick={ (e) => expandHideCategory(e, item.key, 'add') }/>
        }
      </React.Fragment>
      }
      </span>
    }
    return <span>
      <span
      className={ redactedKeys.has(item.key) ? 'asset-panel__left-container__child-name--line-through' : '' }>{ item.title }</span>
      { item.children.length > 0 &&
      <React.Fragment>
        { expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ?
            <Icon type="down" aria-hidden="true" style={ { width: '20px', height: '12px', fontSize: '12px' } }
                  onClick={ (e) => expandHideCategory(e, item.key, 'remove') }/> :
            <Icon type="up" aria-hidden="true" style={ { width: '20px', height: '12px', fontSize: '12px' } }
                  onClick={ (e) => expandHideCategory(e, item.key, 'add') }/>
        }
      </React.Fragment>
      }
      </span>
  }
  return <span>
    <span className={ !isOcr && selectedChildrenBold && selectedChildrenBold.includes(item.key) ? 'child-name' : '' }>{ item.title }</span>
    { item.children.length > 0 &&
    <React.Fragment>
      { expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ?
          <Icon type="down" aria-hidden="true" style={ { width: '20px', height: '12px', fontSize: '12px' } }
                onClick={ (e) => expandHideCategory(e, item.key, 'remove') }/> :
          <Icon type="up" aria-hidden="true" style={ { width: '20px', height: '12px', fontSize: '12px' } }
                onClick={ (e) => expandHideCategory(e, item.key, 'add') }/>
      }
    </React.Fragment>
    }
    </span>
}
const statusRedactedBusiness = (item, itemSelectRedacted, onCheckSelectedTreeRedacted, checkedKeys, treeSaved) => {
  //let savedCheckParent = ''
  if (item.children.length > 0) {
    Object.keys(treeSaved) && Object.keys(treeSaved).length > 0 && Object.keys(treeSaved).forEach((element) => {
      if (element.toUpperCase() === item.key.substring(0, item.key.lastIndexOf('-found-parent-all')).toUpperCase()) {
        if (item.children.length === treeSaved[element]) {
          item.redacted = true
          //savedCheckParent = item.name
        }
      }
    })
  }
  if (item.children.length > 0 && item.redacted === true) {
    return <span onClick={ (e) => onCheckSelectedTreeRedacted(e) } className='icon-v17-redaction'></span>
  } else
  if (checkedKeys.includes(item.key.toLowerCase())) {
    return <span onClick={ (e) => onCheckSelectedTreeRedacted(e) } className='icon-v17-redaction'></span>
  }
  if (item.key === itemSelectRedacted.key) {
    return <span onClick={ (e) => onCheckSelectedTreeRedacted(e) } className='icon-v17-redaction-hover'></span>
  }
  return <span className='icon-v17-redaction-hover empty-status'></span>
}
const categoryParentDictionary = (item) => {
  let checkChild = true
  if (item.children && item.children.length > 0) {
    item.children.forEach((child) => {
       if (child === undefined) {
         checkChild = false
         return <span></span>
       }
    })
  }
  return <span>{ checkChild ? item.title : item.title }</span>
}
export const generateTitleBusiness = (
  item,
  expandedKeys,
  expandHideCategory,
  redactedKeys,
  categoryColours,
  removeCategoryBusiness,
  selectedChildrenBold,
  checkedKeys,
  disableRemoveCategory,
  onDisplayRedaction,
  itemSelectRedacted,
  onLeaveMouseRedaction,
  onCheckSelectedTreeRedacted,
  onFindElementTreeBusiness,
  onDefaultCategoryTop,
  treeSaved,
  isOcr,
  xeroxUpload
) => {
  switch (item.identifier) {
    case 'top-parent': {
      return <div className="top-parent">
        <span className="title-parent-tree">Select all</span>
        <span className="left-container">
          <button className="icon-delete-b"
            onClick={ removeCategoryBusiness }
            disabled={ !(item.children && item.children.length > 0 && checkedKeys.length > 0 && disableRemoveCategory) }/>
        </span>
      </div>
    }
    case 'category-parent': {
      return <React.Fragment>{ item.children && item.children.length > 0 && <div onClick={(e) => onDefaultCategoryTop(e)} className="asset-panel"
        //style={ { borderBottom: `1.5px solid ${ categoryColours ? categoryColours[item.key].replace('0.3', '1') : 'rgba(1,1,1,1)' }` } }
      >
        <div className="asset-panel__left-container container-category-reg">
          { item.children && item.children.length > 0 ?
          <React.Fragment>
            <span
                //     onClick={ expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ?
                //   (e) => expandHideCategory(e, item.key, 'remove') :
                //   (e) => expandHideCategory(e, item.key, 'add')
                // }
                className="asset-panel__left-container__category-name update-category-name">{ categoryParentDictionary(item) }</span>
          {/*{ expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ?*/}
          {/*  <Icon type="down" aria-hidden="true" style={ { width: '20px', height: '15px' } }*/}
          {/*    onClick={ (e) => expandHideCategory(e, item.key, 'remove') }/> :*/}
          {/*  <Icon type="up" aria-hidden="true" style={ { width: '20px', height: '15px' } }*/}
          {/*    onClick={ (e) => expandHideCategory(e, item.key, 'add') }/>*/}
          {/*}*/}
            </React.Fragment> :
              <React.Fragment>
            <span
                className="asset-panel__left-container__category-name update-category-name">{ categoryParentDictionary(item) }</span>
              </React.Fragment>
          }
        </div>
      </div> }</React.Fragment>
    }
    default :
      return (
        <div className="asset-panel">
          <div className={ item.children.length > 0 ? 'asset-panel__children-image update-parent-children' : 'asset-panel__children-image' }
               onMouseEnter={(e) => onDisplayRedaction(e, item)}
               onMouseLeave={ onLeaveMouseRedaction }>
            {
              statusRedactedBusiness(item, itemSelectRedacted, onCheckSelectedTreeRedacted, checkedKeys, treeSaved)
            }
            <span onClick={(e) => onFindElementTreeBusiness(e, item)} className={ !(item.children && item.children.length > 0) ?
                'asset-panel__children-image__children-image-name update-name-parent content-child' : 'asset-panel__children-image__children-image-name update-name-parent' }>
              { generateLastChidBusiness(item, redactedKeys, selectedChildrenBold, expandedKeys, expandHideCategory, isOcr) }
              { item.children && item.children.length > 0 &&
              <React.Fragment>
              { expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ?
                  <span className="icon-expand-category icon-v21-arrow-bold"
                        onClick={ (e) => expandHideCategory(e, item.key, 'remove') }/> :
                  <span className="icon-expand-category icon-v21-arrow-bold rotate-arrow-bold"
                        onClick={ (e) => expandHideCategory(e, item.key, 'add') }/>
              }
              </React.Fragment>
              }
              { isOcr ?
                  <React.Fragment>
                    <span className="total-child"></span>
                  </React.Fragment> :
                  xeroxUpload ?
                      <React.Fragment>
                        <span className="total-child"></span>
                      </React.Fragment> :
                <React.Fragment>
                  {!(item.children && item.children.length > 0) &&
                  <span className="total-child">p.{item.pageNumber}</span>}
                </React.Fragment>
              }
            </span>
            { item.children.length > 0 &&
            <React.Fragment>
              {/*{ expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ?*/}
              {/*  <Icon type="down" aria-hidden="true" style={ { width: '20px', height: '12px', fontSize: '12px' } }*/}
              {/*    onClick={ (e) => expandHideCategory(e, item.key, 'remove') }/> :*/}
              {/*  <Icon type="up" aria-hidden="true" style={ { width: '20px', height: '12px', fontSize: '12px' } }*/}
              {/*    onClick={ (e) => expandHideCategory(e, item.key, 'add') }/>*/}
              {/*}*/}
              <span onClick={(e) => onFindElementTreeBusiness(e, item)} className="total-child">{ item.children.length }</span>
            </React.Fragment>
            }
          </div>
        </div>
      )
  }
}

export const renderTreeBusiness = (
  treeData,
  expandedKeys,
  expandHideCategory,
  redactedKeys,
  categoryColours,
  removeCategoryBusiness,
  selectedChildrenBold,
  checkedKeys,
  disableRemoveCategory,
  onDisplayRedaction,
  itemSelectRedacted,
  onLeaveMouseRedaction,
  onCheckSelectedTreeRedacted,
  onFindElementTreeBusiness,
  onDefaultCategoryTop,
  treeSaved,
  isOcr,
  xeroxUpload
) =>
  treeData && treeData.map((item, i) => {
    if (item !== null && item !== undefined && item.children !== null && item.key !== undefined) {
      if (item.identifier === 'top-parent') {
        return (
          <TreeNode title={generateTitleBusiness(item, expandedKeys, expandHideCategory, redactedKeys, categoryColours, removeCategoryBusiness,
              selectedChildrenBold, checkedKeys, disableRemoveCategory, onDisplayRedaction, itemSelectRedacted, onLeaveMouseRedaction,
              onCheckSelectedTreeRedacted, onFindElementTreeBusiness, onDefaultCategoryTop, treeSaved, isOcr, xeroxUpload)}
            key={item.key}
            data-key={ item.key }
            dataRef={ item }
            selectable={ false }
            className={ `${ item.identifier === 'category-parent' ? 'category-tree-node' : '' } ${ item.identifier === 'top-parent' ? 'disabled-expand-icon' : '' }` }>
            { renderTreeBusiness(item.children, expandedKeys, expandHideCategory, redactedKeys, categoryColours, removeCategoryBusiness,
                selectedChildrenBold, checkedKeys, disableRemoveCategory, onDisplayRedaction, itemSelectRedacted, onLeaveMouseRedaction,
                onCheckSelectedTreeRedacted, onFindElementTreeBusiness, onDefaultCategoryTop, treeSaved, isOcr, xeroxUpload) }
          </TreeNode>
        )
      }
      return (
        <TreeNode title={generateTitleBusiness(item, expandedKeys, expandHideCategory, redactedKeys, categoryColours, removeCategoryBusiness,
            selectedChildrenBold, checkedKeys, disableRemoveCategory, onDisplayRedaction, itemSelectRedacted, onLeaveMouseRedaction,
            onCheckSelectedTreeRedacted, onFindElementTreeBusiness, onDefaultCategoryTop, treeSaved, isOcr, xeroxUpload)}
          key={item.key}
          data-key={ item.key }
          dataRef={ item }
          selectable={ false }
          className={ `${ item.identifier === 'category-parent' && item.children.length === 0 ? 'category-tree-node-empty' : '' } ${ item.identifier === 'category-parent' ? 'category-tree-node' : '' } ${ item.identifier === 'top-parent' ? 'disabled-expand-icon' : '' }` }>
          { renderTreeBusiness(item.children, expandedKeys, expandHideCategory, redactedKeys, categoryColours, removeCategoryBusiness,
              selectedChildrenBold, checkedKeys, disableRemoveCategory, onDisplayRedaction, itemSelectRedacted, onLeaveMouseRedaction,
              onCheckSelectedTreeRedacted, onFindElementTreeBusiness, onDefaultCategoryTop, treeSaved, isOcr, xeroxUpload) }
        </TreeNode>
      )
    }
    if (item !== null && item !== undefined) {
      return <TreeNode key={item.key}
        title={generateTitleBusiness(item, expandedKeys, expandHideCategory, redactedKeys, categoryColours, removeCategoryBusiness,
            selectedChildrenBold, checkedKeys, disableRemoveCategory, onDisplayRedaction, itemSelectRedacted, onLeaveMouseRedaction,
            onCheckSelectedTreeRedacted, onFindElementTreeBusiness, onDefaultCategoryTop, treeSaved, isOcr, xeroxUpload)}
        data-key={ item.key }
        dataRef={item}
        selectable={ false }
        className={ `${ item.identifier === 'category-parent' && item.children.length === 0 ? 'category-tree-node-empty' : '' } ${ item.identifier === 'category-parent' ? 'category-tree-node' : '' } ${ item.identifier === 'top-parent' ? 'disabled-expand-icon' : '' }` }/>
    }
    return true
  })

export const getChildrenBusiness = (docAnnotation, category, backgroundColor, landscape) => {
  let j = 0
  const finalItems = []
  const parentClassName = landscape ? '#div-to-print-landscape' : '#div-to-print-portrait'
  docAnnotation.forEach((el) => {
    const items = []
    const keyName = el.replace(/\W/g, 'a')
    // const instance2 = new Mark(document.querySelector('#pdf-page'))
    // instance2.mark(el, options)
    const options = {
      'className': `${ category } doc_annotate`,
      'ignoreJoiners': true,
      'element': 'span',
      'separateWordSearch': true,
      'acrossElements': true,
      'wildcards': 'withSpaces',
      // "filter": function(element, term, counter){
      //   if(term === "06/06/2023,16:38" && counter > 0){ // "something" was already marked more than three times
      //     return false; // stop (limit)
      //   } else {
      //     return true; // continue
      //   }
      // },
      'accuracy': {'value': 'partially', 'limiters': [' @',', ',' .','. ',':',';','/>','"',"'","`",'<','>','[',']','(',')','-','.','@','{','}','$','&','!','?','~','#','%','^','*','+','_','=','|','\\','/']},
      //'accuracy': {'value': 'partially', 'limiters': []},
      each: (element) => {
        j++
        const parent = element.parentElement.closest(parentClassName)
        //add to element the class ${ keyName }-${ j }
        const item = {
          key: `${ keyName }-${ j }`,
          index: j,
          pageNumber: parent ? parent.getAttribute('data-pageid') : '',
          name: el,
          title: `${ el }`,
          isSearchedWord: true,
          children: [],
          backgroundColor
        }
        element.classList.add(`${ keyName }-${ j }`)
        element.setAttribute('data-page-nr', item.pageNumber)
        items.push(item)
        if (element.parentElement && element.classList.value === element.parentElement.classList.value) {
          element.style.background = 'transparent'
        } else {
          element.style.background = backgroundColor
        }
      }
    }

    const instance = new Mark(document.querySelector('#pdf-page'))
    instance.mark(el, options)
    const filterItems = items.filter((a, i) => items.findIndex((s) => a.name === s.name) === i)
    if (items.length > 0) {
      const { index, pageNumber, name, title } = items[0]
      const keyName = el.replace(/\W/g, 'a')
      const childrenObject = {
        key: `${ keyName }-found-parent-all`,
        identifier: 'children-parent',
        index,
        pageNumber,
        name,
        title: title,
        children: [],
        backgroundColor
      }
      items.forEach((item, key) => childrenObject.children.push(item))
      finalItems.push(childrenObject)
    }
    // else {
    //   finalItems.push(items[0])
    // }
  })
  return finalItems
}

export const generateHtmlForBusiness = (category, backgroundColor, docAnnotations, landscape, newCategoryBusiness) => {
  backgroundColor = backgroundColor ? backgroundColor : 'rgba(254, 178, 54, 0.3)';
  const resultCategoryBusiness = {
    key: category,
    title: category,
    identifier: 'category-parent',
    backgroundColor,
    children: []
  }
  const expandedSubCategoryParent = []
  for (const scanItem of newCategoryBusiness) {
    if (docAnnotations[scanItem] && Array.isArray(docAnnotations[scanItem])) {
      let title = scanItem.replace('doc_annotate_', '')
      let newTitle = ''
      title = title.replace('_', ' ')
      if (category) {
        newTitle = category.replace('_', ' ')
      }
      if ((category.toLowerCase() === title.toLowerCase()) || (newTitle.toLowerCase() === title.toLowerCase())) {
        resultCategoryBusiness.children = getChildrenBusiness(docAnnotations[scanItem], category, backgroundColor, landscape)
        resultCategoryBusiness.children && resultCategoryBusiness.children.length > 0 && resultCategoryBusiness.children.forEach((item) => {
          if (item === undefined) {
            resultCategoryBusiness.children = []
          }
        })
      }
      expandedSubCategoryParent.push(title)
    }
  }
  return { resultCategoryBusiness, expandedSubCategoryParent }
}

const generateLastChildReg = (item, redactedKeys, selectedChildrenBold, expandedKeys, expandHideCategory) => {
  if (redactedKeys && redactedKeys.size > 0) {
    if (item.children && item.children.length > 0) {
      const childKeys = item.children.map((child) => child.key)
      const redactedFoundItemsLength = childKeys.filter((child) => redactedKeys.has(child)).length
      return<span><span
        className={ item.children.length === redactedFoundItemsLength ? 'asset-panel__left-container__child-name--line-through' : '' }>{ item.title }</span>
      { item.children.length > 0 &&
      <React.Fragment>
        { expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ?
            <Icon type="down" aria-hidden="true" style={ { width: '20px', height: '12px', fontSize: '12px' } }
                  onClick={ (e) => expandHideCategory(e, item.key, 'remove') }/> :
            <Icon type="up" aria-hidden="true" style={ { width: '20px', height: '12px', fontSize: '12px' } }
                  onClick={ (e) => expandHideCategory(e, item.key, 'add') }/>
        }
      </React.Fragment>
      }
      </span>
    }
    return <span><span
      className={ redactedKeys.has(item.key) ? 'asset-panel__left-container__child-name--line-through' : '' }>{ item.title }</span>
      { item.children.length > 0 &&
      <React.Fragment>
        { expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ?
            <Icon type="down" aria-hidden="true" style={ { width: '20px', height: '12px', fontSize: '12px' } }
                  onClick={ (e) => expandHideCategory(e, item.key, 'remove') }/> :
            <Icon type="up" aria-hidden="true" style={ { width: '20px', height: '12px', fontSize: '12px' } }
                  onClick={ (e) => expandHideCategory(e, item.key, 'add') }/>
        }
      </React.Fragment>
      }
    </span>
  }
  return <span><span className={ selectedChildrenBold && selectedChildrenBold.includes(item.key) ? 'child-name' : '' }>{ item.title }</span>
    { item.children.length > 0 &&
    <React.Fragment>
      { expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ?
          <Icon type="down" aria-hidden="true" style={ { width: '20px', height: '12px', fontSize: '12px' } }
                onClick={ (e) => expandHideCategory(e, item.key, 'remove') }/> :
          <Icon type="up" aria-hidden="true" style={ { width: '20px', height: '12px', fontSize: '12px' } }
                onClick={ (e) => expandHideCategory(e, item.key, 'add') }/>
      }
    </React.Fragment>
    }
    </span>
}
export const stopPropagationRegEx = (e) => {
  e.stopPropagation()
  e.preventDefault()
}
const statusRedactedRegEx = (item, selectedItemRegEx, onCheckSelectedTreeRedactedReg, checkedKeys, treeSavedRegEx) => {
  //let savedCheckParent = ''
  let savedCheckParentKeys = []
  if (item.children.length > 0) {
    Object.keys(treeSavedRegEx) && Object.keys(treeSavedRegEx).length > 0 && Object.keys(treeSavedRegEx).forEach((element) => {
      if (element.toUpperCase() === item.key.substring(0, item.key.lastIndexOf('-found-parent-all')).toUpperCase()) {
        if (item.children.length === treeSavedRegEx[element]) {
          item.redacted = true
          //savedCheckParent = item.name
          savedCheckParentKeys.push(element)
        }
      }
    })
  }
  if (item.children.length > 0 && item.redacted === true && checkedKeys.length > 0) {
    return <span onClick={ (e) => onCheckSelectedTreeRedactedReg(e) } className='icon-v17-redaction'></span>
  } else if (checkedKeys.includes(item.key)) {
    return <span onClick={() => onCheckSelectedTreeRedactedReg()} className='icon-v17-redaction'></span>
  }
  if (selectedItemRegEx.key === item.key) {
    return <span onClick={() => onCheckSelectedTreeRedactedReg()} className='icon-v17-redaction-hover'></span>
  }
  return <span className='icon-v17-redaction-hover empty-redaction'></span>
}
export const generateTitleTreeReg = (
  item,
  expandedKeys,
  expandHideCategory,
  redactedKeys,
  colorCategories,
  removeCategory,
  checkedKeys,
  selectedChildrenBold,
  disableRemoveCategory,
  onHoverItemRegEx,
  selectedItemRegEx,
  onCheckSelectedTreeRedactedReg,
  onFindElementTreeRegEx,
  isOcr, treeSavedRegEx, onLeaveItemRegEx, xeroxUpload
) => {
  switch (item.identifier) {
    // case 'top-parent': {
    //   return <div className="top-parent">
    //     <span className="title-parent-tree">Select all</span>
    //     <span className="left-container">
    //       <button className="icon-delete-b"
    //         onClick={ removeCategory }
    //         disabled={ !(item.children && item.children.length > 0 && checkedKeys.length > 0 && disableRemoveCategory) }/>
    //     </span>
    //   </div>
    // }
    case 'category-parent': {
      return <React.Fragment>
        { item.children && item.children.length > 0 &&
          <div className="asset-panel" onClick={(e) => stopPropagationRegEx(e)}
              // style={ { borderBottom: `1.5px solid ${ colorCategories ? colorCategories[item.key].replace('0.3', '1') : 'rgba(1,1,1,1)' }` } }
          >
            {item.children && item.children.length > 0 &&
            <div className="asset-panel__left-container container-category-reg">
          <span
              //     onClick={ expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ?
              //   (e) => expandHideCategory(e, item.key, 'remove') :
              //   (e) => expandHideCategory(e, item.key, 'add')
              // }
              className="asset-panel__left-container__category-name update-category-name-top">{item.title}</span>
              {/*{ expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ?*/}
              {/*  <Icon type="down" aria-hidden="true" style={ { width: '20px', height: '15px' } }*/}
              {/*    onClick={ (e) => expandHideCategory(e, item.key, 'remove') }/> :*/}
              {/*  <Icon type="up" aria-hidden="true" style={ { width: '20px', height: '15px' } }*/}
              {/*    onClick={ (e) => expandHideCategory(e, item.key, 'add') }/>*/}
              {/*}*/}
            </div>
            }
          </div>
        }
      </React.Fragment>
    }
    default :
      return (
        <div className="asset-panel">
          <div className="asset-panel__children-image" onMouseEnter={(e) => onHoverItemRegEx(e, item)}
          onMouseLeave={() => onLeaveItemRegEx()}>
            { statusRedactedRegEx(item, selectedItemRegEx, onCheckSelectedTreeRedactedReg, checkedKeys, treeSavedRegEx) }
            <span className={ item.children.length > 0 ? 'asset-panel__children-image__children-image-name update-children-name position-parent':
                'asset-panel__children-image__children-image-name update-children-name position-children'}
                 onClick={(e) => onFindElementTreeRegEx(e, item)}>
              { generateLastChildReg(item, redactedKeys, selectedChildrenBold, expandedKeys, expandHideCategory, treeSavedRegEx) }
              { item.children && item.children.length > 0 &&
                <React.Fragment>
                  {expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ?
                      <span className="icon-expand-category icon-v21-arrow-bold"
                            onClick={(e) => expandHideCategory(e, item.key, 'remove')}/> :
                      <span className="icon-expand-category icon-v21-arrow-bold rotate-arrow-bold"
                            onClick={(e) => expandHideCategory(e, item.key, 'add')}/>
                  }
                </React.Fragment>
              }
              { isOcr ?
                  <React.Fragment>
                    <span></span>
                  </React.Fragment> :
                  xeroxUpload ?
                      <React.Fragment>
                        <span></span>
                      </React.Fragment> :
                <React.Fragment>
                  {!(item.children && item.children.length > 0) &&
                  <span>p.{item.pageNumber}</span>}
                </React.Fragment>
              }
            </span>
            { item.children.length > 0 &&
            <React.Fragment>
              <span onClick={(e) => onFindElementTreeRegEx(e, item)} className="number-size">{ item.children.length }</span>
              {/*{ expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ?*/}
              {/*  <Icon type="down" aria-hidden="true" style={ { width: '20px', height: '12px', fontSize: '12px' } }*/}
              {/*    onClick={ (e) => expandHideCategory(e, item.key, 'remove') }/> :*/}
              {/*  <Icon type="up" aria-hidden="true" style={ { width: '20px', height: '12px', fontSize: '12px' } }*/}
              {/*    onClick={ (e) => expandHideCategory(e, item.key, 'add') }/>*/}
              {/*}*/}
            </React.Fragment>
            }
          </div>
        </div>
      )
  }
}
export const renderTreeSearchRegEX = (
  treeData,
  expandedKeys,
  expandHideCategory,
  redactedKeys,
  colorCategories,
  removeCategory,
  checkedKeys,
  selectedChildrenBold,
  disableRemoveCategory,
  onHoverItemRegEx,
  selectedItemRegEx,
  onCheckSelectedTreeRedactedReg,
  onFindElementTreeRegEx,
  isOcr,
  treeSavedRegEx,
  onLeaveItemRegEx,
  xeroxUpload
) =>
  treeData && treeData.map((item, i) => {
    if (item !== null && item !== undefined && item.children.length > 0 && item.children !== null && item.key !== undefined) {
      if (item.identifier === 'top-parent') {
        return (
          <TreeNode title={generateTitleTreeReg(item, expandedKeys, expandHideCategory, redactedKeys, colorCategories, removeCategory,
              checkedKeys, selectedChildrenBold, disableRemoveCategory, onHoverItemRegEx, selectedItemRegEx, onCheckSelectedTreeRedactedReg,
              onFindElementTreeRegEx, isOcr, treeSavedRegEx, onLeaveItemRegEx, xeroxUpload)}
            key={item.key}
            data-key={ item.key }
            dataRef={ item }
            selectable={ false }
            className={ `${ item.identifier === 'category-parent' ? 'category-tree-node' : '' } ${ item.identifier === 'top-parent' ? 'disabled-expand-icon' : '' }` }>
            { renderTreeSearchRegEX(item.children, expandedKeys, expandHideCategory, redactedKeys, colorCategories, removeCategory, checkedKeys,
                selectedChildrenBold, disableRemoveCategory, onHoverItemRegEx, selectedItemRegEx, onCheckSelectedTreeRedactedReg, onFindElementTreeRegEx, isOcr
                , treeSavedRegEx, onLeaveItemRegEx, xeroxUpload) }
          </TreeNode>
        )
      }
      return (
        <TreeNode title={generateTitleTreeReg(item, expandedKeys, expandHideCategory, redactedKeys, colorCategories, removeCategory, checkedKeys,
            selectedChildrenBold, disableRemoveCategory, onHoverItemRegEx, selectedItemRegEx, onCheckSelectedTreeRedactedReg, onFindElementTreeRegEx, isOcr
            , treeSavedRegEx, onLeaveItemRegEx, xeroxUpload)}
          key={item.key}
          data-key={ item.key }
          dataRef={ item }
          selectable={ false }
          className={ `${ item.identifier === 'category-parent' ? 'category-tree-node' : '' } ${ item.identifier === 'top-parent' ? 'disabled-expand-icon' : '' }` }>
          { renderTreeSearchRegEX(item.children, expandedKeys, expandHideCategory, redactedKeys, colorCategories, removeCategory, checkedKeys,
              selectedChildrenBold, disableRemoveCategory, onHoverItemRegEx, selectedItemRegEx, onCheckSelectedTreeRedactedReg, onFindElementTreeRegEx, isOcr
              , treeSavedRegEx, onLeaveItemRegEx, xeroxUpload) }
        </TreeNode>
      )
    }
    if (item !== null && item !== undefined) {
      return <TreeNode key={ item.key }
        title={ generateTitleTreeReg(item, expandedKeys, expandHideCategory, redactedKeys, colorCategories, removeCategory, checkedKeys,
            selectedChildrenBold, disableRemoveCategory, onHoverItemRegEx, selectedItemRegEx, onCheckSelectedTreeRedactedReg, onFindElementTreeRegEx, isOcr
            , treeSavedRegEx, onLeaveItemRegEx, xeroxUpload) }
        data-key={ item.key }
        dataRef={ item }
        selectable={ false }
        className={ `${ item.identifier === 'category-parent' && item.children.length === 0 ? 'category-tree-node-empty' : '' } ${ item.identifier === 'category-parent' ? 'category-tree-node' : '' } ${ item.identifier === 'top-parent' ? 'disabled-expand-icon' : '' }` }/>
    }
    return true
  })

export const generateHtmlForRegularExpressions = (category, backgroundColor, docAnnotations, landscape, newCategoryRegEx) => {
  const expandedSubCategoryParent = []
  backgroundColor = backgroundColor ? backgroundColor : 'rgba(228, 200, 1, 0.3)';
  const resultCategoryRegEx = {
    key: category,
    title: category,
    identifier: 'category-parent',
    backgroundColor,
    children: []
  }
  for (const scanItem of newCategoryRegEx) {
    if (docAnnotations[scanItem] && Array.isArray(docAnnotations[scanItem])) {
      let title = scanItem.replace('doc_annotate_', '')
      let newTitle = ''
      title = title.replace('_', ' ')
      if (category) {
        newTitle = category.replace('_', ' ')
      }
      if ((category.toLowerCase() === title.toLowerCase()) || (newTitle.toLowerCase() === title.toLowerCase())) {
        resultCategoryRegEx.children = getChildrenRegularExpressions(docAnnotations[scanItem], category, backgroundColor, landscape)
        resultCategoryRegEx.children && resultCategoryRegEx.children.length > 0 && resultCategoryRegEx.children.forEach((item) => {
          if (item === undefined) {
            resultCategoryRegEx.children = []
          }
        })
      }
      expandedSubCategoryParent.push(title)
    }
  }
  return { resultCategoryRegEx, expandedSubCategoryParent }
}

export const getChildrenRegularExpressions = (docAnnotation, category, backgroundColor, landscape) => {
  let j = 0
  const finalItems = []
  const parentClassName = landscape ? '#div-to-print-landscape' : '#div-to-print-portrait'
  docAnnotation.forEach((el) => {
    const items = []
    const keyName = el.replace(/\W/g, 'a')
    const options = {
      'className': `${ category } doc_annotate`,
      'ignoreJoiners': true,
      'element': 'span',
      'acrossElements': false,
      'separateWordSearch': false,
      'wildcards': 'withSpaces',
      //'accuracy': {'value': 'exactly', 'limiters': [' @',',',', ',' .','. ',':',';','/>','"',"'","`",'<','>','[',']','(',')']},
      'accuracy': {'value': 'exactly', 'limiters': [' @',',',', ',' .','. ',':',';','/>','"',"'","`",'<','>','[',']','(',')','-','.','@','{','}','$','&','!','?','~','#','%','^','*','+','_','=','|','\\','/']},
      each: (element) => {
        j++
        const parent = element.parentElement.closest(parentClassName)
        //add to element the class ${ keyName }-${ j }
        const item = {
          key: `${ keyName }-${ j }`,
          index: j,
          identifier: 'children',
          pageNumber: parent ? parent.getAttribute('data-pageid') : '',
          name: el,
          title: `${ el }`,
          isSearchedWord: true,
          children: [],
          backgroundColor
        }
        element.classList.add(`${ keyName }-${ j }`)
        element.setAttribute('data-page-nr', item.pageNumber)
        items.push(item)
        if (element.parentElement && element.classList.value === element.parentElement.classList.value) {
          element.style.background = 'transparent'
        } else {
          element.style.background = backgroundColor
        }
      }
    }

    const instance = new Mark(document.querySelector('#pdf-page'))
    instance.mark(el, options)
    if (items.length > 0) {
      const { index, pageNumber, name, title } = items[0]
      const keyName = el.replace(/\W/g, 'a')
      const childrenObject = {
        key: `${ keyName }-found-parent-all`,
        identifier: 'children-parent',
        index,
        pageNumber,
        name,
        title: title,
        children: [],
        backgroundColor
      }
      items.forEach((item, key) => childrenObject.children.push(item))
      finalItems.push(childrenObject)
    }
    else {
      finalItems.push(items[0])
    }
  })
  return finalItems
}

export const renderTreeNodesForManualTab = (
  data,
  categoriesColors,
  expandedKeys,
  expandHideCategory,
  categoriesVisible,
  toggleColorPicker,
  showHideCategory,
  closeAndSaveColorPicker,
  displayColorPicker,
  addNewCategory,
  removeCategory,
  searchWordsByCategories,
  checkedKeys,
  redactedKeys,
  selectedChildrenBold,
  disableRemoveChildren,
  onDisplayMouseRedaction,
  itemHoverRedaction,
  onLeaveMouseRedaction,
  onCheckSelectedTreeManual,
  onSelectFindElementTree) =>
// eslint-disable-next-line array-callback-return
  data.map((item) => {
    if (item) {
      if (item.children && item.children.length > 0) {
        if (item.key.includes('found-parent-all')) {
          return (
            <TreeNode
              title={ generateTitleForManualTab(item,
                categoriesColors,
                expandedKeys,
                expandHideCategory,
                categoriesVisible,
                toggleColorPicker,
                showHideCategory,
                closeAndSaveColorPicker,
                displayColorPicker,
                addNewCategory,
                removeCategory,
                searchWordsByCategories,
                checkedKeys,
                redactedKeys,
                selectedChildrenBold,
                disableRemoveChildren,
                  onDisplayMouseRedaction,
                  itemHoverRedaction,
                  onLeaveMouseRedaction,
                  onCheckSelectedTreeManual,
                  onSelectFindElementTree) }
              key={ item.key } data-key={ item.key } dataRef={ item }
              selectable={ false }
              className={ `${ item.identifier === 'category-parent' ? 'category-tree-node' : '' }` }
              //className={ `${ item.identifier === 'category-parent' ? 'category-tree-node' : '' } ${ item.key === 'select-all' ? 'disabled-expand-icon' : '' }` }
            >
              { renderTreeNodesForManualTab(
                item.children,
                categoriesColors,
                expandedKeys,
                expandHideCategory,
                categoriesVisible,
                toggleColorPicker,
                showHideCategory,
                closeAndSaveColorPicker,
                displayColorPicker,
                addNewCategory,
                removeCategory,
                searchWordsByCategories,
                checkedKeys,
                redactedKeys,
                selectedChildrenBold,
                disableRemoveChildren,
                  onDisplayMouseRedaction,
                  itemHoverRedaction,
                  onLeaveMouseRedaction,
                  onCheckSelectedTreeManual,
                  onSelectFindElementTree
              ) }
            </TreeNode>
          )
        }
        return (
          <TreeNode
            title={ generateTitleForManualTab(
              item,
              categoriesColors,
              expandedKeys,
              expandHideCategory,
              categoriesVisible,
              toggleColorPicker,
              showHideCategory,
              closeAndSaveColorPicker,
              displayColorPicker,
              addNewCategory,
              removeCategory,
              searchWordsByCategories,
              checkedKeys,
              redactedKeys,
              selectedChildrenBold,
              disableRemoveChildren,
                onDisplayMouseRedaction,
                itemHoverRedaction,
                onLeaveMouseRedaction,
                onCheckSelectedTreeManual,
                onSelectFindElementTree) }
            key={ item.key } data-key={ item.key } dataRef={ item }
            className={ `${ item.identifier === 'category-parent' ? 'category-tree-node' : '' }` }
            //className={ `${ item.identifier === 'category-parent' ? 'category-tree-node' : '' } ${ item.key === 'select-all' ? 'disabled-expand-icon' : '' }` }
            selectable={ false }>
            { renderTreeNodesForManualTab(
              item.children,
              categoriesColors,
              expandedKeys,
              expandHideCategory,
              categoriesVisible,
              toggleColorPicker,
              showHideCategory,
              closeAndSaveColorPicker,
              displayColorPicker,
              addNewCategory,
              removeCategory,
              searchWordsByCategories,
              checkedKeys,
              redactedKeys,
              selectedChildrenBold,
              disableRemoveChildren,
                onDisplayMouseRedaction,
                itemHoverRedaction,
                onLeaveMouseRedaction,
                onCheckSelectedTreeManual,
                onSelectFindElementTree
            ) }
          </TreeNode>
        )
      }
      // else if (item.key === 'select-all') {
      //   return <TreeNode
      //     title={ generateTitleForManualTab(
      //       item,
      //       categoriesColors,
      //       expandedKeys,
      //       expandHideCategory,
      //       categoriesVisible,
      //       toggleColorPicker,
      //       showHideCategory,
      //       closeAndSaveColorPicker,
      //       displayColorPicker,
      //       addNewCategory,
      //       removeCategory,
      //       searchWordsByCategories,
      //       checkedKeys,
      //       redactedKeys,
      //       selectedChildrenBold,
      //       disableRemoveChildren,
      //         onDisplayMouseRedaction,
      //         itemHoverRedaction,
      //         onLeaveMouseRedaction,
      //         onCheckSelectedTreeManual) }
      //     key={ item.key } data-key={ item.key } dataRef={ item }
      //     className="disabled-expand-icon" selectable={ false }
      //     disableCheckbox={ !(item.children && item.children.length > 0) }>
      //     { renderTreeNodesForManualTab(
      //       item.children,
      //       categoriesColors,
      //       expandedKeys,
      //       expandHideCategory,
      //       categoriesVisible,
      //       toggleColorPicker,
      //       showHideCategory,
      //       closeAndSaveColorPicker,
      //       displayColorPicker,
      //       addNewCategory,
      //       removeCategory,
      //       searchWordsByCategories,
      //       checkedKeys,
      //       redactedKeys,
      //       selectedChildrenBold,
      //       disableRemoveChildren,
      //         onDisplayMouseRedaction,
      //         itemHoverRedaction,
      //         onLeaveMouseRedaction,
      //         onCheckSelectedTreeManual
      //     ) }
      //   </TreeNode>
      // }
      else if (item.identifier === 'category-parent') {
        return <TreeNode key={ item.key }
          title={ generateTitleForManualTab(
            item,
            categoriesColors,
            expandedKeys,
            expandHideCategory,
            categoriesVisible,
            toggleColorPicker,
            showHideCategory,
            closeAndSaveColorPicker,
            displayColorPicker,
            addNewCategory,
            removeCategory,
            searchWordsByCategories,
            checkedKeys,
            redactedKeys,
            selectedChildrenBold,
            disableRemoveChildren,
              onDisplayMouseRedaction,
              itemHoverRedaction,
              onLeaveMouseRedaction,
              onCheckSelectedTreeManual,
              onSelectFindElementTree) }
          data-key={ item.key }
          dataRef={ item }
          selectable={ false }
          className="category-tree-node"
        />
      }
      return <TreeNode key={ item.key }
        title={ generateTitleForManualTab(
          item,
          categoriesColors,
          expandedKeys,
          expandHideCategory,
          categoriesVisible,
          toggleColorPicker,
          showHideCategory,
          closeAndSaveColorPicker,
          displayColorPicker,
          addNewCategory,
          removeCategory,
          searchWordsByCategories,
          checkedKeys,
          redactedKeys,
          selectedChildrenBold,
          disableRemoveChildren,
            onDisplayMouseRedaction,
            itemHoverRedaction,
            onLeaveMouseRedaction,
            onCheckSelectedTreeManual,
            onSelectFindElementTree) }
        data-key={ item.key } dataRef={ item }
        selectable={ false }
      />
    }
  })
 const generateStatusRedactedManualTag = (item, itemHoverRedaction, onCheckSelectedTreeManual, checkedKeys) => {

     if (itemHoverRedaction.key === item.key) {
       return <span onClick={() => onCheckSelectedTreeManual()} className='icon-v17-redaction-hover'></span>
     }
     if (checkedKeys.includes(item.key)) {
        return <span onClick={() => onCheckSelectedTreeManual()} className="icon-v17-redaction"></span>
     }
     return <span className='icon-v17-redaction-hover redaction-empty'></span>
 }
export const stopPropagationManualTag = (e) => {
  e.stopPropagation()
  e.preventDefault()
}
export const lengthChild = (item) => {
    if (item.children && item.children.length > 0) {
       return <span>{ item.children.length }</span>
    }
}
export const generateTitleForManualTab = (
  item,
  categoriesColors,
  expandedKeys,
  expandHideCategory,
  categoriesVisible,
  toggleColorPicker,
  showHideCategory,
  closeAndSaveColorPicker,
  displayColorPicker,
  addNewCategory,
  removeCategory,
  searchWordsByCategories,
  checkedKeys,
  redactedKeys,
  selectedChildrenBold,
  disableRemoveChildren,
  onDisplayMouseRedaction,
  itemHoverRedaction,
  onLeaveMouseRedaction,
  onCheckSelectedTreeManual,
  onSelectFindElementTree) => {
  switch (item.identifier) {
    // case 'top-parent': {
    //   return <div className="top-parent"
    //               onMouseEnter={(e) => onDisplayMouseRedaction(e, item)}
    //               onMouseLeave={onLeaveMouseRedaction}
    //   >
    //     <span>
    //     {
    //       generateStatusRedactedManualTag(item, itemHoverRedaction, onCheckSelectedTreeManual, checkedKeys)
    //     }
    //     <span onClick={(e) => stopPropagationManualTag(e)}>{ item.title }</span>
    //     </span>
    //     <span className="left-container">
    //       <button className="icon-search-b" onClick={ searchWordsByCategories }
    //         disabled={ !(item.children && item.children.length > 0 && checkedKeys.length > 0) }/>
    //       <button className="icon-delete-b" onClick={ removeCategory }
    //         disabled={ !(item.children && item.children.length > 0 && checkedKeys.length > 0 && disableRemoveChildren) }/>
    //       <span onClick={ addNewCategory }><span className="icon-add-b"/>Add</span>
    //     </span>
    //   </div>
    // }
    case 'category-parent':
      return (
        <div className="asset-panel"
          style={ { borderBottom: `1.5px solid ${ categoriesColors && categoriesColors[item.key] ? categoriesColors[item.key].replace('0.3', '1') : 'rgba(1,1,1,1)' }` } }>
          <div className="asset-panel__left-container" onMouseEnter={(e) => onDisplayMouseRedaction(e, item)}
          onMouseLeave={onLeaveMouseRedaction}>
            {
              generateStatusRedactedManualTag(item, itemHoverRedaction, onCheckSelectedTreeManual, checkedKeys)
            }
            <span onClick={(e) => stopPropagationManualTag(e)}
              className="asset-panel__left-container__category-name">{ item.title }</span>
            { expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ?
              <Icon type="down" aria-hidden="true" style={ { width: '20px', height: '15px' } }
                onClick={ (e) => expandHideCategory(e, item.key, 'remove') }/> :
              <Icon type="up" aria-hidden="true" style={ { width: '20px', height: '15px' } }
                onClick={ (e) => expandHideCategory(e, item.key, 'add') }/>
            }
          </div>
          {/*{ _generateExtraIconsForManualTab(item.key, item.backgroundColor, categoriesVisible, toggleColorPicker, showHideCategory, closeAndSaveColorPicker, displayColorPicker) }*/}
        </div>
      )
    default :
      return (
        <div className="asset-panel">
          <div className="asset-panel__left-container" onMouseEnter={(e) => onDisplayMouseRedaction(e, item)}
               onMouseLeave={onLeaveMouseRedaction}>
            {
              generateStatusRedactedManualTag(item, itemHoverRedaction, onCheckSelectedTreeManual, checkedKeys)
            }
            <span onClick={(e) => onSelectFindElementTree(e, item)}
              className="asset-panel__left-container__child-name">
              { _generateLastChildNameManual(item, redactedKeys, selectedChildrenBold, expandedKeys, expandHideCategory) }
              { (item.children && item.children.length > 0) &&
              <span className="size-child">{ lengthChild(item) } </span>
              }
              { !(item.children && item.children.length > 0) &&
              <span className="size-child" onClick={(e) => onSelectFindElementTree(e, item)}>p.{ item.pageNumber }</span> }
            </span>
            {/*{ item.children.length > 0 &&*/}
            {/*  <React.Fragment>*/}
            {/*    <span style={ { fontSize: '12px' } }>{ item.children.length }</span>*/}
            {/*    { expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ?*/}
            {/*      <Icon type="down" aria-hidden="true" style={ { width: '20px', height: '12px', fontSize: '12px' } }*/}
            {/*        onClick={ (e) => expandHideCategory(e, item.key, 'remove') }/> :*/}
            {/*      <Icon type="up" aria-hidden="true" style={ { width: '20px', height: '12px', fontSize: '12px' } }*/}
            {/*        onClick={ (e) => expandHideCategory(e, item.key, 'add') }/>*/}
            {/*    }*/}
            {/*  </React.Fragment>*/}
            {/*}*/}
          </div>
        </div>
      )
  }
}

const _generateLastChildNameManual = (item, redactedKeys, selectedChildrenBold, expandedKeys, expandHideCategory) => {
  if (redactedKeys && redactedKeys.size > 0) {
    if (item && item.children && item.children.length > 0) {
      const childKeys = item.children.map((child) => child.key)
      const redactedFoundItemsLength = childKeys.filter((child) => redactedKeys.has(child)).length
      return <span
          className={item.children.length === 0 && item.children.length === redactedFoundItemsLength ? 'asset-panel__left-container__child-name--line-through child-size update-child-name padding-title' :
              item.children.length === redactedFoundItemsLength ? 'asset-panel__left-container__child-name--line-through child-size update-child-name' :
                  'child-size update-child-name' }>{ item.title }
        { item.children.length > 0 &&
        <React.Fragment>
          { expandedKeys && expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ?
              <Icon type="down" aria-hidden="true" style={ { width: '20px', height: '12px', fontSize: '12px' } }
                    onClick={ (e) => expandHideCategory(e, item.key, 'remove') }/> :
              <Icon type="up" aria-hidden="true" style={ { width: '20px', height: '12px', fontSize: '12px' } }
                    onClick={ (e) => expandHideCategory(e, item.key, 'add') }/>
          }
        </React.Fragment>
        }
      </span>
    }
    return <span
        className={ item.children.length === 0 && redactedKeys.has(item.key) ? 'asset-panel__left-container__child-name--line-through child-size update-child-name padding-title' :
            redactedKeys.has(item.key) ? 'asset-panel__left-container__child-name--line-through child-size update-child-name' :
                'child-size update-child-name' }>{ item.title }
      { item.children.length > 0 &&
      <React.Fragment>
        { expandedKeys && expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ?
            <Icon type="down" aria-hidden="true" style={ { width: '20px', height: '12px', fontSize: '12px' } }
                  onClick={ (e) => expandHideCategory(e, item.key, 'remove') }/> :
            <Icon type="up" aria-hidden="true" style={ { width: '20px', height: '12px', fontSize: '12px' } }
                  onClick={ (e) => expandHideCategory(e, item.key, 'add') }/>
        }
      </React.Fragment>
      }
    </span>
  }
  return <span className={ item.children.length === 0 && selectedChildrenBold && selectedChildrenBold.includes(item.key) ? 'asset-panel__left-container__child-name--line-through child-size on-click-bold update-child-name padding-title' :
      selectedChildrenBold && selectedChildrenBold.includes(item.key) ? 'asset-panel__left-container__child-name--line-through child-size on-click-bold update-child-name' :
          item.children.length === 0 ? 'asset-panel__left-container__child-name--line-through child-size update-child-name padding-title' :
              'asset-panel__left-container__child-name--line-through child-size update-child-name' }>{ item.title }
    { item.children.length > 0 &&
    <React.Fragment>
      {/*<span className="child-names-length" style={ { fontSize: '12px' } }>{ item.children.length }</span>*/}
      { expandedKeys && expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ?
          <Icon type="down" aria-hidden="true" style={ { width: '20px', height: '12px', fontSize: '12px' } }
                onClick={ (e) => expandHideCategory(e, item.key, 'remove') }/> :
          <Icon type="up" aria-hidden="true" style={ { width: '20px', height: '12px', fontSize: '12px' } }
                onClick={ (e) => expandHideCategory(e, item.key, 'add') }/>
      }
    </React.Fragment>
    }
  </span>
}

const _generateLastChildName = (item, redactedKeys, selectedChildrenBold, expandedKeys, expandHideCategory, onSelectFindElementTree) => {
  if (redactedKeys && redactedKeys.size > 0) {
    if (item && item.children && item.children.length > 0) {
      const childKeys = item.children.map((child) => child.key)
      const redactedFoundItemsLength = childKeys.filter((child) => redactedKeys.has(child)).length
      return <span
        className={item.children.length === 0 && item.children.length === redactedFoundItemsLength ? 'asset-panel__left-container__child-name--line-through child-size update-child-name padding-title' :
          item.children.length === redactedFoundItemsLength ? 'asset-panel__left-container__child-name--line-through child-size update-child-name' :
            'child-size update-child-name' }
             onClick={(e) => onSelectFindElementTree(e, item)}>{ item.title }
        { item.children.length > 0 &&
        <React.Fragment>
          {/*<span style={ { fontSize: '12px' } }>{ item.children.length }</span>*/}
          { expandedKeys && expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ?
              <span className="icon-expand-category icon-v21-arrow-bold"
                    onClick={ (e) => expandHideCategory(e, item.key, 'remove') }/> :
              <span className="icon-expand-category icon-v21-arrow-bold rotate-arrow-bold"
                    onClick={ (e) => expandHideCategory(e, item.key, 'add') }/>
          }
        </React.Fragment>
        }</span>
    }
    return <span
      className={ item.children.length === 0 && redactedKeys.has(item.key) ? 'asset-panel__left-container__child-name--line-through child-size update-child-name padding-title' :
        redactedKeys.has(item.key) ? 'asset-panel__left-container__child-name--line-through child-size update-child-name' :
          'child-size update-child-name child-non-redacted' }
      onClick={(e) => onSelectFindElementTree(e, item)}>{ item.title }
      { item.children.length > 0 &&
      <React.Fragment>
        {/*<span style={ { fontSize: '12px' } }>{ item.children.length }</span>*/}
        { expandedKeys && expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ?
            <span className="icon-expand-category icon-v21-arrow-bold"
                  onClick={ (e) => expandHideCategory(e, item.key, 'remove') }/> :
            <span className="icon-expand-category icon-v21-arrow-bold rotate-arrow-bold"
                  onClick={ (e) => expandHideCategory(e, item.key, 'add') }/>
        }
      </React.Fragment>
      }</span>
  }
  return <span className={ item.children.length === 0 && selectedChildrenBold && selectedChildrenBold.includes(item.key) ? 'asset-panel__left-container__child-name--line-through child-size on-click-bold update-child-name padding-title' :
    selectedChildrenBold && selectedChildrenBold.includes(item.key) ? 'asset-panel__left-container__child-name--line-through child-size on-click-bold update-child-name' :
        item.children.length === 0 ? 'asset-panel__left-container__child-name--line-through child-size update-child-name padding-title' :
      'asset-panel__left-container__child-name--line-through child-size update-child-name' }
               onClick={(e) => onSelectFindElementTree(e, item)}>{ item.title }
    { item.children.length > 0 &&
    <React.Fragment>
      {/*<span className="child-names-length" style={ { fontSize: '12px' } }>{ item.children.length }</span>*/}
      { expandedKeys && expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ?
          <span className="icon-expand-category icon-v21-arrow-bold"
                onClick={ (e) => expandHideCategory(e, item.key, 'remove') }/> :
          <span className="icon-expand-category icon-v21-arrow-bold rotate-arrow-bold"
                onClick={ (e) => expandHideCategory(e, item.key, 'add') }/>
      }
    </React.Fragment>
    }
  </span>
}

// const _generateExtraIconsForManualTab = (key, color, categoriesVisible, toggleColorPicker, showHideCategory, closeAndSaveColorPicker, displayColorPicker) => {
//   return (
//     <div className="asset-panel__icons">
//       <span className="icon-colourpalette-b" aria-hidden="true"
//         onClick={ (event) => toggleColorPicker(key, true, event) }/>
//       { categoriesVisible[key] ?
//         <span className="icon-eye-open-b" aria-hidden="true"
//           onClick={ (e) => showHideCategory(e, key, false) }/> :
//         <span className="icon-eye-closed-b" aria-hidden="true"
//           onClick={ (e) => showHideCategory(e, key, true) }/>
//       }
//       { displayColorPicker[key] ? <Popover
//         trigger="click"
//         placement="right"
//         overlayClassName="custom-color-picker-popover"
//         content={
//           <CompactPicker
//             colors={ [ 'rgba(107, 91, 149, 1)', 'rgba(254, 178, 54, 1)', 'rgba(214, 65, 97, 1)',
//               'rgba(255, 123, 37, 1)', 'rgba(75, 195, 193, 1)', 'rgba(194, 194, 71, 1)', 'rgba(181, 231, 160, 1)',
//               'rgba(191, 108, 64, 1)', 'rgba(62, 68, 68, 1)', 'rgba(130, 183, 75, 1)', 'rgba(64, 93, 39, 1)',
//               'rgba(193, 148, 106, 1)', 'rgba(60, 136, 180, 1)', 'rgba(200, 100, 91, 1)', 'rgba(238, 162, 154, 1)',
//               'rgba(201, 76, 76, 1)', 'rgba(124, 108, 6, 1)', 'rgba(15, 195, 71, 1)', 'rgba(231, 82, 114, 1)',
//               'rgba(238, 140, 54, 1)', 'rgba(92, 212, 209, 1)', 'rgba(211, 211, 88, 1)', 'rgba(198, 248, 177, 1)',
//               'rgba(192, 125, 81, 1)', 'rgba(77, 100, 113, 1)', 'rgba(237, 226, 220, 1)', 'rgba(132, 206, 235, 1)' ] }
//             disableAlpha={ true }
//             color={ color }
//             onChange={ (color, e) => closeAndSaveColorPicker(color, key, e) }
//           />
//         }
//         visible={displayColorPicker[key]}
//         onVisibleChange={() => toggleColorPicker(key, false)}
//       />: null }
//     </div>
//   )
// }

const generateNephewKey = (treeData, nephewKey) => {
  let response = nephewKey
  if (treeData && treeData.length) {
    treeData.forEach((item) => {
      if (item.title === 'Analytic Results') {
        if (item.children && item.children.length) {
          item.children.forEach((value) => {
            if (value.children && value.children.length) {
              value.children.forEach((val) => {
                const prefix = val.key
                if (val.children && val.children.length) {
                  val.children.forEach((it) => {
                    if (it.title === nephewKey) {
                      response = `${ prefix }_${ nephewKey }`
                    }
                  })
                }
              })
            }
          })
        }
      }
    })
  }
  return response
}

export const renderTreeNodesFolderRedaction = (data, expandedKeys, expandHideCategory, expandHideNephewCategory, setTreeData, currentActiveParent= 0, lastCategoryVisited= 0, expandAllParent, tab, treeData, submittedSelectedRedact) =>
  // eslint-disable-next-line array-callback-return
  data.map((item) => {
    if (item) {
      if (item.children && item.children.length > 0) {
        if (item.identifier === 'category-parent') {
          return (
            <TreeNode
              title={tab === 'folder-redaction' ?
                generateTitleFolderRedaction(item, expandedKeys, expandHideCategory, expandHideNephewCategory, setTreeData, currentActiveParent, lastCategoryVisited, expandAllParent, submittedSelectedRedact) :
                generateTitleDataInsight(item, expandedKeys, expandHideCategory, expandHideNephewCategory, setTreeData, currentActiveParent, lastCategoryVisited, expandAllParent)
              }
              key={item.key}
              data-key={item.key}
              dataRef={item}
              selectable={true}
              className={_generateParentClassName(item.identifier)}>
              {renderTreeNodesFolderRedaction(
                item.children,
                expandedKeys,
                expandHideCategory,
                expandHideNephewCategory,
                setTreeData,
                currentActiveParent,
                lastCategoryVisited,
                expandAllParent,
                tab,
                treeData,
                submittedSelectedRedact
              )}
            </TreeNode>
          )
        }
        return (
          <TreeNode
            title={tab === 'folder-redaction' ?
              generateTitleFolderRedaction(item, expandedKeys, expandHideCategory, expandHideNephewCategory, setTreeData, currentActiveParent, lastCategoryVisited, expandAllParent, submittedSelectedRedact) :
              generateTitleDataInsight(item, expandedKeys, expandHideCategory, expandHideNephewCategory, setTreeData, currentActiveParent, lastCategoryVisited, expandAllParent)
            }
            key={item.key} data-key={item.key} dataRef={item}
            selectable={true}
            className={_generateParentClassName(item.identifier)}>
            {renderTreeNodesFolderRedaction(
              item.children,
              expandedKeys,
              expandHideCategory,
              expandHideNephewCategory,
              setTreeData,
              currentActiveParent,
              lastCategoryVisited,
              expandAllParent,
              tab,
              treeData,
              submittedSelectedRedact
            )}
          </TreeNode>
        )
      } else if (item.identifier === 'category-child') {
        return <TreeNode key={item.key}
          title={tab === 'folder-redaction' ?
            generateTitleFolderRedaction(item, expandedKeys, expandHideCategory, expandHideNephewCategory, setTreeData, currentActiveParent, lastCategoryVisited, expandAllParent, submittedSelectedRedact) :
            generateTitleDataInsight(item, expandedKeys, expandHideCategory, expandHideNephewCategory, setTreeData, currentActiveParent, lastCategoryVisited, expandAllParent)
          }
          data-key={item.key} dataRef={item}
          checkable={true}
          className={_generateParentClassName(item.identifier)}
        />
      } else if (item.identifier === 'category-nephew') {
        const nephewKey = generateNephewKey(treeData, item.key)
        return <TreeNode key={nephewKey}
          title={tab === 'folder-redaction' ?
            generateTitleFolderRedaction(item, expandedKeys, expandHideCategory, expandHideNephewCategory, setTreeData, currentActiveParent, lastCategoryVisited, expandAllParent, submittedSelectedRedact) :
            generateTitleDataInsight(item, expandedKeys, expandHideCategory, expandHideNephewCategory, setTreeData, currentActiveParent, lastCategoryVisited, expandAllParent)
          }
          data-key={nephewKey} dataRef={item}
          checkable={true}
          className={_generateParentClassName(item.identifier)}
        />
      }
      return <TreeNode key={item.key}
        title={tab === 'folder-redaction' ?
          generateTitleFolderRedaction(item, expandedKeys, expandHideCategory, expandHideNephewCategory, setTreeData, currentActiveParent, lastCategoryVisited, expandAllParent, submittedSelectedRedact) :
          generateTitleDataInsight(item, expandedKeys, expandHideCategory, expandHideNephewCategory, setTreeData, currentActiveParent, lastCategoryVisited, expandAllParent)
        }
        data-key={item.key}
        dataRef={item}
        selectable={true}
        className={_generateParentClassName(item.identifier)}
      />
    }
  })

const _generateParentClassName = (identifier) => {
  switch (identifier) {
    case 'category-parent':
      return 'category-tree-node'
    case 'top-parent':
      return 'top-parent-tree-node'
    case 'category-child':
      return 'child-tree-node'
    case 'category-name-parent':
      return 'category-name-parent-tree-node'
    default:
      return ''
  }
}

const _generateAnalyticsParentClassName = (identifier) => {
  switch (identifier) {
    case 'top-parent':
      return 'category-top-tree-node'
    case 'category-parent':
      return 'category-parent-tree-node update-category-parent-tree-node'
    case 'category-child':
      return 'category-child-tree-node category-child2-tree-node'
    default:
      return ''
  }
}
const _generateAnalyticsCategoryClass= (identifier) => {
  switch (identifier) {
    case 'top-parent':
      return 'category-top-tree-node'
    case 'category-parent':
      return 'category-parent-tree-node category-parent2-tree-node'
    case 'category-child':
      return 'category-child-tree-node category-child2-tree-node'
    default:
      return ''
  }
}

const generateTitleFolderRedaction = (item, expandedKeys, expandHideCategory, expandHideNephewCategory, setTreeData, currentActiveParent, lastCategoryVisited, expandAllParent, submittedSelectedRedact) => {
  switch (item.identifier) {
    case 'top': {
      const rowAction = expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ? 'remove' : 'add'
      const children = item.children.length < 10 ? item.children.length : 10
      return (
        <React.Fragment>
          <div className={ `top ${ lastCategoryVisited === item.key ? 'active-parent': '' }` } onClick={ (e) => expandHideCategory(e, item, rowAction) }>
            <span className="top__name">{ item.title }</span>
            <span className="top__found" onClick={ (e) => expandAllParent(e, item) }>Expand all of the top { children } results </span>
            <span className="top__arrow">
              { expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ?
                <Icon type="down" aria-hidden="true" style={ { width: '20px', height: '15px' } }
                  onClick={ (e) => expandHideCategory(e, item, rowAction) }/> :
                <Icon type="up" aria-hidden="true" style={ { width: '20px', height: '15px' } }
                  onClick={ (e) => expandHideCategory(e, item, rowAction) }/>
              }
            </span>
          </div>
          <span className="top__bottom-border"/>
        </React.Fragment>
      )
    }
    case 'top-parent': {
      const rowAction = expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ? 'remove' : 'add'
      let redactedFieldBase = item.selected ? true : false
      redactedFieldBase = submittedSelectedRedact === 2 ? !redactedFieldBase : redactedFieldBase
      return (
        <React.Fragment>
          <div className={ `tp ${ lastCategoryVisited === item.key ? 'active-parent': '' }` } onClick={ (e) => expandHideCategory(e, item, rowAction) }>
            <span className="tp__name">{ item.title === 'Business Terms' ? 'Dictionaries' : item.title }</span>
            <span className="tp__found">{(item.title === 'Images' && item.found === -1) ? 'calculating...' : item.found ? `${ item.found } total found` : '0 total found' }</span>
            <span className="tp__count">{(item.title === 'Images' && item.folderCount === -1) ? 'calculating...' : (item.folderCount === 0 || item.folderCount > 1 ? `${ item.folderCount } folders` : item.folderCount === 1 ? `${ item.folderCount } folder` : '0 folders') }</span>
            <span className="tp__count">{(item.title === 'Images' && item.documentCount === -1) ? 'calculating...' : (item.documentCount === 0 || item.documentCount > 1 ? `${ item.documentCount } documents` : item.documentCount === 1 ? `${ item.documentCount } documents` : '0 documents') }</span>
            <span className="tp__count"><span className={redactedFieldBase ? 'redact-label-fill' : 'redact-label'}>{ redactedFieldBase ? 'Redacted' : '' }</span></span>
            <span className="tp__arrow">
              { expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ?
                <Icon type="down" aria-hidden="true" style={ { width: '20px', height: '15px' } }
                  onClick={ (e) => expandHideCategory(e, item, rowAction) }/> :
                <Icon type="up" aria-hidden="true" style={ { width: '20px', height: '15px' } }
                  onClick={ (e) => expandHideCategory(e, item, rowAction) }/>
              }
            </span>
          </div>
          <span className="tp__bottom-border"/>
        </React.Fragment>
      )
    }
    case 'category-parent':
      const rowAction = expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ? 'remove' : 'add'
      let redactedFieldParent = item.selected ? true : false
      redactedFieldParent = submittedSelectedRedact === 2 ? !redactedFieldParent : redactedFieldParent
      return (
        <div className={ `cp ${ lastCategoryVisited === item.key ? 'active-parent': '' }` } onClick={ (e) => expandHideCategory(e, item, rowAction) }>
          <span className="cp__name">{ item.title }</span>
          <span className="cp__found">{ item.children ? `${ item.found } total found` : '0 total found' } </span>
          <span className="cp__found">{ item.children && (item.folderCount === 0 || item.folderCount > 1) ? `${ item.folderCount } folders` : item.children && item.folderCount === 1 ? `${ item.folderCount } folder` : '0 folders' } </span>
          <span className="cp__found">{ item.children && (item.documentCount === 0 || item.documentCount > 1) ? `${ item.documentCount } documents` : item.children && item.documentCount === 1 ? `${ item.documentCount } document` : '0 documents' }</span>
          <span className="cp__found"><span className={ redactedFieldParent ? 'redact-label-fill' : 'redact-label'}>{ redactedFieldParent ? 'Redacted' : '' }</span></span>
          { item.children && item.children.length > 0 &&
            <React.Fragment>
              <span className="cp__arrow">
                { expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ?
                  <Icon type="down" aria-hidden="true" style={ { width: '20px', height: '15px' } }
                    onClick={ (e) => expandHideCategory(e, item, rowAction) }/> :
                  <Icon type="up" aria-hidden="true" style={ { width: '20px', height: '15px' } }
                    onClick={ (e) => expandHideCategory(e, item, rowAction) }/>
                }
              </span>
            </React.Fragment>
          }
        </div>
      )
    case 'category-child':
      const rowActionForNephew = expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ? 'remove' : 'add'
      let redactedFieldChild = item.selected ? true : false
      redactedFieldChild = submittedSelectedRedact === 2 ? !redactedFieldChild : redactedFieldChild
      return (
        <div className={ `ch ${ lastCategoryVisited === item.key ? 'active-parent': '' }` } onClick={ (e) => expandHideNephewCategory(e, item, rowActionForNephew, false) }>
          <span className="ch__name">{ item.title }</span>
          <span className="ch__found">{ item.found ? `${ item.found } total found` : '0 total found' }</span>
          <span className="ch__found ch__folder">{ item.folderCount === 0 || item.folderCount > 1 ? `${ item.folderCount } folders` : item.folderCount === 1 ? `${ item.folderCount } folder` : '0 folders' }</span>
          <span className="ch__found">{ item.documentCount === 0 || item.documentCount > 1 ? `${ item.documentCount } documents` : item.documentCount === 1 ? `${ item.documentCount } document` : '0 documents' }</span>
          <span className="ch__found"><span className={redactedFieldChild ? 'redact-label-fill ch-redacted' : 'redact-label ch-redacted'}>{ redactedFieldChild ? 'Redacted' : '' }</span></span>
          { item.children.length >9 &&
            <div className="ch__btn-wrapper">
              { item.children.length === 10 &&
              <button className="show-all-btn" onClick={ (e) => expandHideNephewCategory(e, item, 'add', true) }> Show all </button>
              }
              { item.children.length > 10 &&
              <button className="show-all-btn" onClick={ (e) => expandHideNephewCategory(e, item, 'add', false) }> Top 10  </button>
              }
            </div>
          }
          <span></span>
        </div>
      )
    default :
      let redactedFieldNephew = item.selected ? true : false
      redactedFieldNephew = submittedSelectedRedact === 2 ? !redactedFieldNephew : redactedFieldNephew
      return (
        <div className="np">
          <span className="np__name">{ item.title }</span>
          <span className="np__found">
            { item.found &&
             <React.Fragment>
               <span className="np__found__number">{ item.found }</span>
               <span className="np__found__text"> total found</span>
             </React.Fragment>
            }
          </span>
          <span className="np__redacted"><span className={redactedFieldNephew ? 'redact-label-fill' : 'redact-label'}>{ redactedFieldNephew ? 'Redacted' : '' }</span></span>
          <span></span>
        </div>
      )
  }
}

const generateTitleDataInsight = (item, expandedKeys, expandHideCategory, expandHideNephewCategory, setTreeData, currentActiveParent, lastCategoryVisited, expandAllParent) => {
  switch (item.identifier) {
    case 'top-parent': {
      const rowAction = expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ? 'remove' : 'add'
      return (
        <React.Fragment>
          <div className={ `top-parent ${ lastCategoryVisited === item.key ? 'active-parent': '' }` } onClick={ (e) => expandHideCategory(e, item, rowAction) }>
            <span className="top-parent__parent-name">{ item.title }</span>
            <span className="top-parent__parent-found">{(item.title === 'Images' && item.found === -1) ? 'calculating...' : item.found ? `${ item.found } total found` : '0 total found' }</span>
            <span className="top-parent__parent-folder-count">{ ((item.title === 'Images' && item.folderCount === -1) ? 'calculating...' : item.folderCount === 0 || item.folderCount > 1 ? `${ item.folderCount } folders` : item.folderCount === 1 ? `${ item.folderCount } folder` : '0 folders') }</span>
            <span className="top-parent__parent-document-count">{ ((item.title === 'Images' && item.documentCount === -1) ? 'calculating...' : item.documentCount === 0 || item.documentCount > 1 ? `${ item.documentCount } documents` : item.documentCount === 1 ? `${ item.documentCount } documents` : '0 documents') }</span>
            <span className="top-parent__parent-arrow category-parent__arrow2">
              { expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ?
                <Icon type="down" aria-hidden="true" style={ { width: '20px', height: '15px' } }
                  onClick={ (e) => expandHideCategory(e, item, rowAction) }/> :
                <Icon type="up" aria-hidden="true" style={ { width: '20px', height: '15px' } }
                  onClick={ (e) => expandHideCategory(e, item, rowAction) }/>
              }
            </span>
          </div>
          <span className="top-parent__bottom-border"/>
        </React.Fragment>
      )
    }
    case 'category-parent':
      const rowAction = expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ? 'remove' : 'add'
      return (
        <div className={ `category-parent ${ lastCategoryVisited === item.key ? 'active-parent': '' }` } onClick={ (e) => expandHideCategory(e, item, rowAction) }>
          <span className="category-parent__space-before"></span>
          <span className="category-parent__name">{ item.title }</span>
          <span className="category-parent__found">{ item.children ? `${ item.found } total found` : '0 total found' } </span>
          <span className="category-parent__found">{ item.children && (item.folderCount === 0 || item.folderCount > 1) ? `${ item.folderCount } folders` : item.children && item.folderCount === 1 ? `${ item.folderCount } folder` : '0 folders' } </span>
          <span className="category-parent__found">{ item.children && (item.documentCount === 0 || item.documentCount > 1) ? `${ item.documentCount } documents` : item.children && item.documentCount === 1 ? `${ item.documentCount } document` : '0 documents' } </span>
          { item.children && item.children.length > 0 &&
          <React.Fragment>
            <span className="category-parent__arrow category-parent__arrow2">
              { expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ?
                <Icon type="down" aria-hidden="true" style={ { width: '20px', height: '15px' } }
                  onClick={ (e) => expandHideCategory(e, item, rowAction) }/> :
                <Icon type="up" aria-hidden="true" style={ { width: '20px', height: '15px' } }
                  onClick={ (e) => expandHideCategory(e, item, rowAction) }/>
              }
            </span>
            <span className="category-parent__space-after"></span>
          </React.Fragment>
          }
          { (!item.children || item.children.length === 0) &&
          <React.Fragment>
            <span className="category-parent__space-after"></span>
            <span className="category-parent__space-after"></span>
          </React.Fragment>
          }
        </div>
      )
    case 'category-child':
      const rowActionForNephew = expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ? 'remove' : 'add'
      return (
        <div className={ `child ${ lastCategoryVisited === item.key ? 'active-parent': '' }` } onClick={ (e) => expandHideNephewCategory(e, item, rowActionForNephew, false) }>
          <span></span>
          <span className="child__name">{ item.title }</span>
          <span className="child__found">{ item.found ? `${ item.found } total found` : '0 total found' }</span>
          <span className="child__found">{ item.folderCount === 0 || item.folderCount > 1 ? `${ item.folderCount } folders` : item.folderCount === 1 ? `${ item.folderCount } folder` : '0 folders' }</span>
          <span className="child__found">{ item.documentCount === 0 || item.documentCount > 1 ? `${ item.documentCount } documents` : item.documentCount === 1 ? `${ item.documentCount } document` : '0 documents' }</span>
          { item.children.length >9 &&
          <div className="child__btn-wrapper">
            { item.children.length === 10 &&
            <button className="show-all-btn" onClick={ (e) => expandHideNephewCategory(e, item, 'add', true) }> Show all </button>
            }
            { item.children.length > 10 &&
            <button className="show-all-btn" onClick={ (e) => expandHideNephewCategory(e, item, 'add', false) }> Top 10  </button>
            }
          </div>
          }
          <span></span>
        </div>
      )
    default :
      return (
        <div className="nephew">
          <span></span>
          <span className="nephew__name">{ item.title }</span>
          <span className="np__found">
            { item.found &&
            <React.Fragment>
              <span className="np__found__number">{ item.found }</span>
              <span className="np__found__text"> total found</span>
            </React.Fragment>
            }
          </span>
        </div>
      )
  }
}
const _generateNewBoxPiiClassName = (identifier) => {
  switch (identifier) {
    case 'category-parent':
      return 'category-parent-tree-node'
    case 'category-child':
      return 'category-child-tree-node category-child2-tree-node'
    default:
      return ''
  }
}
const _generateNewBoxCategoryClass = (identifier) => {
  switch (identifier) {
    case 'category-parent':
      return 'category-parent-tree-node category-parent2-tree-node'
    case 'category-child':
      return 'category-child-tree-node category-child2-tree-node'
    default:
      return ''
  }
}
const generateTitlePiiNewBox = (item, expandedKeys, expandHideCategory, checkedKeys, categoryVisited) => {
  if (item.identifier === 'category-parent') {
    return (
      <div style={{ background: item.color }} className={ categoryVisited === item.key ? 'category-parent category-parent-expand' : 'category-parent' }>
        <span className={ categoryVisited === item.key ? 'category-parent__name category-parent-expand__name' : 'category-parent__name' }>
          { item.title }
        </span>
        <span className="category-parent__arrow">
          { expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ?
              <span className="icon-v21-arrow-bold" type="down" aria-hidden="true" style={ { width: '20px', height: '15px' } }
                    onClick={ (e) => expandHideCategory(e, item.key, 'remove') }/> :
              <span className="icon-v21-arrow-bold rotate-arrow" type="up" aria-hidden="true" style={ { width: '20px', height: '15px' } }
                    onClick={ (e) => expandHideCategory(e, item.key, 'add') }/>
            // <Icon type="down" aria-hidden="true" style={ { width: '20px', height: '15px' } }
            //   onClick={ (e) => expandHideCategory(e, item.key, 'remove') }/> :
            // <Icon type="up" aria-hidden="true" style={ { width: '20px', height: '15px' } }
            //   onClick={ (e) => expandHideCategory(e, item.key, 'add') }/>
          }
        </span>
      </div>
    )
  }
  return (
    <div className="category-child">
      <span className="category-child__name">{ item.title }</span>
    </div>
  )
}
export const renderTreePiiNewBox = (data, expandedKeys, expandHideCategory, checkedKeys, categoryVisited) =>
  data.map((item) => {
    if(item) {
      if (item.children && item.children.length > 0) {
        if (item.identifier === 'category-parent') {
          return (
              <TreeNode
                  title={generateTitlePiiNewBox(item, expandedKeys, expandHideCategory, checkedKeys, categoryVisited)}
                  key={item.key}
                  data-key={item.key}
                  dataRef={item}
                  selectable={true}
                  className={expandedKeys.includes(item.key) ? `${_generateNewBoxPiiClassName(item.identifier)} ${_generateNewBoxCategoryClass(item.identifier)}` : `${_generateNewBoxPiiClassName(item.identifier)}`}>
                {renderTreePiiNewBox(
                    item.children,
                )}
              </TreeNode>
          )
        }
      }
      return (
          <TreeNode
              title={generateTitlePiiNewBox(item, expandedKeys, expandHideCategory, checkedKeys, categoryVisited)}
              key={item.key}
              data-key={item.key}
              dataRef={item}
              selectable={true}
              className={_generateNewBoxPiiClassName(item.identifier)}/>
      )
    }
    return null
  })
export const generatePiiAnalyticsTree = () => {
  const treeChildren = []
  for (const category in categories) {
    const child = {
      title: category.charAt(0).toUpperCase() + category.slice(1),
      key: category,
      identifier: 'category-parent',
      children: categories[category].map((catChild) => {
        if (catChild.tagName !== 'Female names' && catChild.tagName !== 'Male names') {
          return {
            title: catChild.tagName,
            key: catChild.tag,
            identifier: 'category-child'
          }
        }
        return null
      })
    }
    treeChildren.push(child)
  }
  return treeChildren
}
export const generateAnalyticsTree = () => {
  const treeChildren = []
  for (const category in categories) {
    const child = {
      title: category.charAt(0).toUpperCase() + category.slice(1),
      key: category,
      identifier: 'category-parent',
      children: categories[category].map((catChild) => {
        return {
          title: catChild.tagName,
          key: catChild.tag,
          identifier: 'category-child'
        }
      })
    }
    treeChildren.push(child)
  }
  return treeChildren
}
const _generateAnalyticsParentStyle = (item) => {
  switch (item.identifier) {
    case 'category-parent':
      return `${ item.color }`
    default:
      return '#ffffff'
  }
}
export const renderTreeNodesAnalytics = (data, expandedKeys, expandHideCategory, checkedKeys, checkKeysForNewFolder, categoryVisited, disabled) =>
  data.map((item) => {
    if(item) {
      if (item.children && item.children.length > 0) {
        if (item.identifier === 'top-parent') {
          return (
              <TreeNode
                  title={generateTitleAnalytics(item, expandedKeys, expandHideCategory, checkedKeys, checkKeysForNewFolder, categoryVisited, disabled)}
                  key={item.key} data-key={item.key} dataRef={item}
                  selectable={false}
                  //style={{background: _generateAnalyticsParentStyle(item)}}
                  className={_generateAnalyticsParentClassName(item.identifier)}>
                {renderTreeNodesAnalytics(
                    item.children,
                    expandedKeys,
                    expandHideCategory,
                    checkedKeys,
                    checkKeysForNewFolder,
                    categoryVisited,
                    disabled
                )}
              </TreeNode>
          )
        }
        return (
            <TreeNode
                title={generateTitleAnalytics(item, expandedKeys, expandHideCategory, checkedKeys, checkKeysForNewFolder, categoryVisited, disabled)}
                key={item.key} data-key={item.key} dataRef={item}
                selectable={true}
                style={{background: expandedKeys.includes(item.key) ? '' : _generateAnalyticsParentStyle(item)}}
                className={expandedKeys.includes(item.key) ? `${_generateAnalyticsParentClassName(item.identifier)} ${_generateAnalyticsCategoryClass(item.identifier)}` : _generateAnalyticsParentClassName(item.identifier)}>
              {renderTreeNodesAnalytics(
                  item.children,
                  expandedKeys,
                  expandHideCategory,
                  checkedKeys,
                  checkKeysForNewFolder,
                  categoryVisited,
                  disabled
              )}
            </TreeNode>
        )
      }
      return <TreeNode key={item.key}
                       title={generateTitleAnalytics(item, expandedKeys, expandHideCategory, checkedKeys, checkKeysForNewFolder, categoryVisited, disabled)}
                       data-key={item.key} dataRef={item}
                       selectable={true}
                       className={_generateAnalyticsParentClassName(item.identifier)}
      />
    }
    return null
  })

const generateTitleAnalytics = (item, expandedKeys, expandHideCategory, checkedKeys, checkKeysForNewFolder, categoryVisited, disabled) => {
  let checkTopParent = ''
  checkedKeys && checkedKeys.length && checkedKeys.map((check) => {
    if (check === 'doc_annotate_select_all') {
      checkTopParent = check
    }
    return true
  })
  if (item.identifier === 'top-parent') {
    return (
      <div className="category-top">
        <span className="category-top__name">{ checkTopParent === 'doc_annotate_select_all' ? 'Clear All' : checkKeysForNewFolder ? 'Clear All' : 'Select All' }</span>
      </div>
    )
  } else
  if (item.identifier === 'category-parent') {
    return (
      <div style={{background: expandedKeys.includes(item.key) ? `${item.color}` : ''}} className={ categoryVisited === item.key ? 'category-parent category-parent-expand' : 'category-parent' }>
        <span className={ categoryVisited === item.key ? 'category-parent__name category-parent-expand__name' : 'category-parent__name' }>
          { item.title }
        </span>
        <span className={ disabled  ? `category-parent__arrow disabled` : `category-parent__arrow`}>
          { expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ?
            <span type="down" aria-hidden="true" style={ { width: '20px', height: '15px' } } className="icon-v17-arrow-medium open-arrow"
              onClick={ (e) => disabled ? {} : expandHideCategory(e, item.key, 'remove') }/> :
            <span type="up" aria-hidden="true" style={ { width: '20px', height: '15px' } } className="icon-v17-arrow-medium close-arrow"
              onClick={ (e) => disabled ? {} : expandHideCategory(e, item.key, 'add') }/>
          }
        </span>
      </div>
    )
  }
  return (
    <div className="category-child"
      style={ { fontWeight: expandedKeys.findIndex((eKey) => eKey === item.key) > -1 ? '400' : '' } }>
      <span className="category-child__name">{ item.title }</span>
    </div>
  )
}

export const generateFileForLink = (folderId, paths, requestPath) => {
  let fileIdForLink = paths[requestPath]
  if (!fileIdForLink) {
    fileIdForLink = folderId
  }
  return fileIdForLink
}

export const generateBreadCrumb = (path, paths, depth, toggleFilePreview, folder, displaySettingsMenu = true, activePage='', smartBoxMenuOpen = false) => {
  if (path) {
    const fileId = (Object.keys(paths).length) ? paths[Object.keys(paths)[0]] : (folder.id ? folder.id : '')
    const pathArray = path.split('/')
    let breadCrumb = []
    let requestPath = ''
    let j = 0
    for (let i = 0; i < pathArray.length; i++) {
      if (pathArray[i]) {
        requestPath = `${ requestPath }/${ pathArray[i] }`
        const fileIdForLink = generateFileForLink(folder.id, paths, requestPath)

        breadCrumb = [ ...breadCrumb, [<span key={ j++ }>&nbsp;&nbsp;&nbsp;</span>, <span key={ j++ } className="arrow">&rsaquo;</span>, <span key={ j++ }>&nbsp;&nbsp;&nbsp;</span>]]

        if (path === requestPath) {
          breadCrumb.push(<img key={ j++ } src={ imgFolderSmallOpenParentSmall } alt={ path } />)
        }
        breadCrumb.push(<span key={ j++ }>&nbsp;&nbsp;</span>)
        breadCrumb.push(<Link key={ j++ } to={ { pathname: `/smart-box/folder/${ fileIdForLink }` } }>{ pathArray[i] }</Link>)

        if (path === requestPath && displaySettingsMenu && !depth) {
          breadCrumb = [...breadCrumb, [<span key={ j++ } className="arrow">&rsaquo;</span>, <span key={ j++ }>&nbsp;&nbsp;&nbsp;</span>, <span key={ j++ }><SmartBoxSettingsMenu folder={folder} fileId={fileId} toggleFilePreview={ () => toggleFilePreview(true, folder) } activePage={ activePage } smartBoxMenuOpen={ smartBoxMenuOpen }></SmartBoxSettingsMenu></span>]]
        }
      }
    }
    return breadCrumb
  }
  return ''
}

export const checkIfFolderIsPstOrZip = (chosenFiles) => {
  const extensions = ['zip', 'pst']
  let hasPstOrZip = false
  if (chosenFiles.length) {
    chosenFiles.forEach((item) => {
      const extension = item.name.split('.').pop().toLowerCase()
      if (extensions.includes(extension)) {
        hasPstOrZip = true
      }
    })
  }
  return hasPstOrZip
}

export const checkIfFileHasSizeIsBig = (chosenFiles) => {
  let result = false
  if (chosenFiles.length) {
    chosenFiles.forEach((item) => {
      if (item.size > LIMIT_FILE_UPLOAD) {
        result = true
      }
    })
  }
  return result
}

export const removeDuplicatedIdInArray = (list) => {
  const response = []
  if (list && list.length) {
    list.forEach((item) => {
      if (!response.includes(item)) {
        response.push(item)
      }
    })
  }
  return response
}

export const extractDepth = (documentPath, isDirectory = true) => {
  let response = 0
  const needle = 'smartBox_'
  if (documentPath && documentPath.length) {
    const index = documentPath.indexOf(needle)
    const sufix = documentPath.substring(index)
    const temp = sufix.split('/').length
    if (temp > 1) {
      response = temp - 2
    }
  }
  return response
}

export const extractDepthForTree = (documentPath, isDirectory = true) => {
  let response = 0
  if (documentPath && documentPath.length) {
    const temp = documentPath.split('/')
    if(!temp.includes('home')){
      response = temp.length - 1
    }
  }
  return response

}

export const generatePiiTree = () => {
  const treeChildren = []
  for (const category in categories) {
    const child = {
      title: category.charAt(0).toUpperCase() + category.slice(1),
      key: category,
      identifier: 'category-parent',
      children: categories[category].map((catChild) => {
        if (typeof catChild.tagName !== 'undefined' && catChild.tagName !== 'Female names' && catChild.tagName !== 'Male names') {
          return {
            title: catChild.tagName,
            key: catChild.tag,
            identifier: 'category-child'
          }
        }
        return null
      })
    }
    treeChildren.push(child)
  }
  return treeChildren
}