import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import Button from '../../Common/Button'

import './DoNotNeedPia.css'

const DoNotNeedPia = ({ process }) => (
  <div className="piaAlertDoNotNeedPia__wrapper">
    <h1 className="piaAlertDoNotNeedPia__title">Based on your answers you <ins>do not require a DPIA</ins> from this Business Process.</h1>
    <h2 className="piaAlertDoNotNeedPia__subTitle">
      Your Business Process
      &nbsp;
      <strong>{ process.title }</strong>
      &nbsp;
      has been marked as <strong>non-sensitive</strong>
    </h2>
    <div className="piaAlertDoNotNeedPia__buttons">
      <Link to="/dpia/dashboard">
        <Button theme="green">
          Back to Dashboard
        </Button>
      </Link>
    </div>
  </div>
)
  
DoNotNeedPia.propTypes = {
  process: PropTypes.object.isRequired
}

export default DoNotNeedPia