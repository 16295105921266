import React from 'react'
import PropTypes from 'prop-types'

import Loading from '../../Common/Loading'

import './AlertInfo.css'

const AlertInfo = ({ alert, countFolders }) => {
  if (alert.isFetchingInfo) return (
    <div className="alertInfo__loading">
      <Loading />
    </div>
  )

  return (
    <React.Fragment>
      <div className="alertInfo__item">Objects<br />
        <strong>{ alert.totalObjects }</strong>
      </div>
      <div className="alertInfo__item">Folders<br />
        <strong>{ countFolders }</strong>
      </div>
    </React.Fragment>
  )
}

AlertInfo.propTypes = {
  alert: PropTypes.object.isRequired,
  countFolders: PropTypes.number.isRequired
}

export default AlertInfo