import React from 'react'
import { Link } from 'react-router-dom'

// import Button from '../../Common/Button'

import './BoxOspreyBuilder.scss'

class BoxOspreyBuilder extends React.Component {
  state = { content: [] }

  render() {
    const { content } = this.state

    return (
      <div className="boxBuilder__wrapper">
        <header className="boxBuilder__header">
          <h1>PIA/DPIA Builder</h1>
          <p>New! Questionnaire builder</p>
        </header>

        <div className="boxBuilder__content">

          { content.length === 0 &&
          <div className="boxBuilder__content--body">
            <p>Create custom Impact Assessments with ease. Available now exclusively for <strong>Unlimited Subscription</strong> holders.</p>
          </div>
          }

          { content.length > 0 &&
          <div className="boxBuilder__content--body">
            <ul className="boxBuilder__content--list">
              <li className="boxBuilder__content--item">
                PIA - Custom HR
                <div className="boxBuilder__content--info">
                  version 3
                  <Link to="/">icon</Link>
                </div>
              </li>
              <li className="boxBuilder__content--item">
                PIA - Custom HR
                <div className="boxBuilder__content--info">
                  version 3
                  <Link to="/">icon</Link>
                </div>
              </li>
              <li className="boxBuilder__content--item">
                PIA - Custom HR
                <div className="boxBuilder__content--info">
                  version 3
                  <Link to="/">icon</Link>
                </div>
              </li>
            </ul>
          </div>
          }
        </div>
      </div>
    )
  }
}

export default BoxOspreyBuilder