import PropTypes from 'prop-types'
import React from 'react'
import Button from '../../../Common/Button'
import Modal from '../../../Common/Modal'
import '../../../Common/Modal.scss'
import './UserTeamsModal.scss'

const UserTeamsModal = ({ hideTeamsModal, user, teamsList, addTeamsToUser, updateChkValues, chkTeams, chkTeamsInit }) => {
  const teams = []
  /* remove this once we receive assigned team in users' list api call */
  for (const i of Object.keys(teamsList)) {
    let found = false
    const team = teamsList[i]
    for (const j of Object.keys(team.members)) {
      const member = team.members[j]
      if (member.userId === user.user_id) {
        found = true
      }
    }
    if (team.createdByUserId === user.user_id) {
      found = true
    }
    if (!found) {
      teams[teams.length] = team
    }
  }
  const teamSelect = []
  teams.map((item) => {
    teamSelect.push({ value: item.id.toString(), name: item.name })
    return true
  })
  const numberOfSelectedTeams = Object.keys(chkTeamsInit).length
  return (
    <Modal hideModal={ hideTeamsModal } id="modal-User" className="modal-containerUser modalUser">
      <div id="modalContent" className="modal--content">
        <h1 className="modal-title">Assign team to user: <b>{ user.name}</b></h1>
        { teamsList.length === numberOfSelectedTeams && 
        <div><br/><br/>This user belongs already to all teams.
          <span className="modal-close-new" onClick={ hideTeamsModal }></span>
          <div className="mt-2">
            <Button
              className="butCancel"
              id="butCancelModal"
              theme="dark"
              onClick={ hideTeamsModal }>
              CANCEL
            </Button>
          </div>
        </div>
        }
        { teamsList.length > 0 && teamsList.length !== numberOfSelectedTeams && 
        <div>
          <header id="modalH" className="modalHeader no-brd-bot">
            <h2 className="manageGroups-sectionSubtitle">Select one or multiple teams at a time </h2>
            <span className="modal-close-new" onClick={ hideTeamsModal }></span>
          </header>
          <form className="teamUsers__form">
            <div className="manageGroups-tableContainer-newUser assign-team-to-user">
              <table className="manageGroups-table-add-users" >
                <thead>
                  <tr>
                    <th className="titleTable1">SELECT</th>
                    <th className="titleTable2">NAME</th>
                  </tr>
                </thead>
                <tbody>
                  { teamsList.map((item) => {
                    let labelClasses = (typeof chkTeams[item.id] !== 'undefined' && chkTeams[item.id]) ? 'checkboxLabelChecked' : 'checkboxLabel'
                    labelClasses += (typeof chkTeamsInit[item.id] !== 'undefined' && chkTeamsInit[item.id]) ? ' not_allowed' : ' ' 
                    return (
                      <tr key={ item.id }>
                        <td>
                          <div className="checkbox-area">
                            <input
                              type="checkbox"
                              id={`chk_${ item.id }`}
                              onChange={ (e) => { updateChkValues(item.id, e.target.checked) } } 
                              disabled={ typeof chkTeamsInit[item.id] !== 'undefined' && chkTeamsInit[item.id] ? 'disabled' : '' }
                            />
                            <label htmlFor={`chk_${ item.id }`}
                              className={ labelClasses }></label>
                          </div>
                        </td>
                        <td>
                          <p className="styleName"> { item.name } </p>
                        </td>
                      </tr>
                    )
                  })
                  }
                </tbody>
              </table>
            </div>
            <div>
              <footer className="userSettings-footer assign-team-to-user-footer">
                <div>
                  <Button
                    theme="green"
                    onClick={ addTeamsToUser }>
                    ASSIGN TO TEAM
                  </Button>
                </div>
                <div>
                  <Button
                    className="butCancel"
                    theme="dark"
                    onClick={ hideTeamsModal }>
                    CANCEL
                  </Button>
                </div>
              </footer>
            </div>
          </form>
        </div>
        }
      </div>
    </Modal>
  )
}

UserTeamsModal.propTypes = {
  hideTeamsModal: PropTypes.func.isRequired
}

export default UserTeamsModal