import axios from 'axios'

export const apiOspreyDeleteQuery = (host, query) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/indexDeleteQuery`,
    data: {
      sessionID: token,
      indexID: '1',
      query: query
    }
  })
}

export const apiOspreyFlush = (host) => {
  const token = sessionStorage.getItem('gravicus_auth_token')

  return axios({
    method: 'post',
    url: `${ host }/osprey-0.1.0/v1/master/indexFlush`,
    data: {
      sessionID: token,
      indexID: '1'
    }
  })
}