import React from 'react'
import PropTypes from 'prop-types'

const RadioView = (props) => {
  return (
    <div className="radio-view">
      {
        props.options.map((option, key) =>
          <div className="radio-view__item" key={key}>
            <span className="radio-view__item__check" />
            <span className="radio-view__item__name">{option.name}</span>
          </div>
        )
      }
    </div>
  )
}

RadioView.propTypes = {
  options: PropTypes.array.isRequired
}

export default RadioView