import PropTypes from 'prop-types'
import React from 'react'
import ImageUploader from 'react-images-upload'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import swal from 'sweetalert2'
import CustomNotificationManager from '../../../Common/CustomNotificationManager'
import { apiFetchQuestionnaire, apiFetchQuestionnaireVersion, apiQuestionnaireCreate, apiQuestionnaireCreateVersion, apiQuestionnairePublish, apiQuestionnaireUpdate } from '../../../../api/Questionnaire'
import { apiUserCreate } from '../../../../api/User'
import imgAddTeam from '../../../../assets/icon-add-circle.png'
import imgDelTeam from '../../../../assets/icon-del-circle.png'
import { accountIsPaid, cloneObject, scrollToElement, userIsSuperAdmin } from '../../../../utils'
import Button from '../../../Common/Button'
import Page403 from '../../../Common/ErrorPage/Page403'
import Loading from '../../../Common/Loading'
import Message from '../../../Common/Message'
import { actionAttributes } from '../../../Dpia/Questionnaires/Helpers/ActionsAttributes'
import { changesChecker } from '../../../Dpia/Questionnaires/Helpers/ChangesChecker'
import { denormalizeFromAPI, denormalizeQuestionnaireFromAPI, denormalizeToAPI } from '../../../Dpia/Questionnaires/Helpers/Denormalizer'
import { validate } from '../../../Dpia/Questionnaires/Helpers/Validator'
import { filteredQuestions } from '../../../Dpia/Questionnaires/Questions/Helpers/Filter'
import { toggleInvalidAccordion } from '../../../Dpia/Questionnaires/Questions/Helpers/Utils'
import '../../../../style/common.scss'
import './NewUserForm.scss'

class NewUserForm extends React.Component {
  constructor() {
    super()

    this.state = this.defaultState
    this.state.unique = ''
    this.state.email = ''
    this.state.firstname = ''
    this.state.position = ''

    this.updateQuestionsList = this.updateQuestionsList.bind(this)
    this.updateQuestion = this.updateQuestion.bind(this)
    this.filterQuestions = this.filterQuestions.bind(this)
    this.toggleAccordion = this.toggleAccordion.bind(this)
    this.handleSubmitForm = this.handleSubmitForm.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  get methods() {
    return {
      createNewDraftQuestionnaire: this.createNewDraftQuestionnaire.bind(this),
      createNewQuestionnaireAndPublish: this.createNewQuestionnaireAndPublish.bind(this),
      createNewVersionAndPublish: this.createNewVersionAndPublish.bind(this),
      updateDraft: this.updateDraft.bind(this),
      publish: this.publish.bind(this)
    }
  }

  get defaultState() {
    return {
      isLoading: false,
      isSaving: false,
      originalState: {},
      questionsAccordion: {},
      errors: {},
      isEditMode: false,
      description: '',
      industry: '',
      deletedQuestions: [],
      questions: [],
      allQuestions: [],
      title: '',
      state: '',
      version: '',
      redirectToDashboard: false,
      type: 'DATA_CONTROLLER_DPIA',
      picture: '',
    }
  }

  get urlParams() {
    return {
      questionnaireId: this.props.params.questionnaireId,
      versionId: this.props.params.versionId
    }
  }

  get disableInput() {
    return this.state.isEditMode || this.state.state === 'PUBLISHED'
  }

  get disabledButton() {
    return this.state.state === 'PUBLISHED' || this.state.isSaving
  }

  handleChange(event, field) {
    if (field === 'firstname') {
      this.setState({ firstname: event.target.value })
    } else if (field === 'emailAddress') {
      this.setState({ lastname: event.target.value })
    } else if (field === 'companyName') {
      this.setState({ emailAddress: event.target.value })
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)

    const { questionnaireId, versionId } = this.urlParams

    this.setState({ picture: '', nrTeams: 0 })
    if (questionnaireId) {
      this.setState({ isLoading: true, isEditMode: true })
      this.fetchQuestionnaire(questionnaireId)
      this.fetchQuestionnaireVersion(questionnaireId, versionId)
    }
  }

  handleSubmitForm(event) {
    const metadata = {
      agreeTermsOfService: true,
      companyName: 'neurosoftware',
      companySize: '1-10',
      industry: '',
      isAccountActivated: true,
      name: this.state.firstName,
      receiveMarketingInfo: true,
      roles: []
    }
    const parameters = {
      name: this.state.firstname,
      nickname: this.state.lastname,
      email: this.state.email,
      email_verified: false,
      identifies: [],
      last_ip: '107001',
      last_login: '',
      logins_count: 0,
      picture: '',
      updated_at: '',
      user_id: '',
      user_metadata: metadata,
      companyName: this.state.companyName,
      created_at: new Date().toLocaleString()
    }

    apiUserCreate(parameters)
      .then(() => {
        this.setState({
          //const id = this.props.params.teamId
          isLoading: false,
        })
        this.props.navigate('/osp/manage/users')
      })
    //this.props.navigate('/osp/manage/teams')
  }

  componentWillUnmount() {
    this.setState(this.defaultState)
  }

  fetchQuestionnaire(questionnaireId) {
    apiFetchQuestionnaire(questionnaireId)
    .then((response) => {
      const questionnaire = denormalizeQuestionnaireFromAPI(response.data)
      const values = { ...this.state, ...questionnaire }
      const { originalState, ...initialValues } = values

      this.setState({ ...this.state, ...questionnaire, originalState: cloneObject(initialValues) })
    })
  }

  fetchQuestionnaireVersion(questionnaireId, versionId) {
    apiFetchQuestionnaireVersion(questionnaireId, versionId)
    .then((response) => {
      const questionnaire = denormalizeFromAPI(response.data)
      const values = {
        ...this.state,
        questions: questionnaire.questions,
        allQuestions: questionnaire.questions,
        state: questionnaire.state,
        version: questionnaire.version
      }
      const { originalState, ...initialValues } = values

      this.setState({ ...values, originalState: cloneObject(initialValues), isLoading: false })
    })
  }

  updateValue(field, e) {
    if (field === 'nrTeams') {
      this.setState({ nrTeams: this.state.nrTeams + 1 })
    } else if (field === 'removeTeams') {
      this.setState({ nrTeams: this.state.nrTeams - 1 })
    } else {
      this.setState({
        [field]: e.target.value
      }, () => this.validateField(field))
    }
  }

  validateField(field) {
    if (field === 'newPassword' || field === 'retypePassword') {
      const { newPassword, retypePassword } = this.state

      if (newPassword === retypePassword) {
        return this.props.hideMessage()
      }
      CustomNotificationManager.error('The passwords are not equal.', 'Error')
    }
  }

  alertAttributes(data, questionnaire, action) {
    let hasChanges = false
    // Needs to check these changes just if isEditMode is true
    if (this.state.isEditMode) {
      const originalData = denormalizeToAPI(this.state.originalState)
      hasChanges = changesChecker(data, originalData)
    }
    return actionAttributes(questionnaire, hasChanges, action, this.methods)
  }

  buttonClicked(event, action) {
    event.preventDefault()

    // check if is a publish state trying to save a draft
    if (this.state.state === 'PUBLISHED' && action === 'draft') return

    const questionnaire = validate(this.state)
    this.setState({ ...questionnaire }, () => {
      if (questionnaire.isValid) {
        const data = denormalizeToAPI(this.state)
        const nextAction = this.alertAttributes(data, questionnaire, action)

        swal.fire({
          text: nextAction.title,
          type: 'warning',
          showCancelButton: nextAction.action !== 'noAction',
          confirmButtonColor: '#3DD8DB',
          cancelButtonColor: '#D33D33',
          confirmButtonText: nextAction.action !== 'noAction' ? 'Yes!' : 'Ok'
        }).then((result) => {
          if (result.value && nextAction.method) {
            this.setState({ isSaving: true })
            nextAction.method(data)
          }
        })
      } else {
        const questionsAccordion = toggleInvalidAccordion(this.state.questions)
        this.setState({ questionsAccordion, isSaving: false }, () => {
          scrollToElement('.errorMessages__list, .inputValidator__invalidWrapper')
        })
      }
    })
  }

  createNewQuestionnaireAndPublish(data) {
    apiQuestionnaireCreate(data)
    .then((response) => {
      const questionnaireId = response.data[0]
      const versionId = response.data[1]
      this.publish(true, questionnaireId, versionId)
    })
    .catch((e) => {
      this.setState({ isSaving: false })
    })
  }

  createNewDraftQuestionnaire(data) {
    apiQuestionnaireCreate(data)
    .then(() => {
      this.setState({ redirectToDashboard: true, isSaving: false })
    })
    .catch((e) => {
      this.setState({ isSaving: false })
    })
  }

  updateDraft(data) {
    const { questionnaireId, versionId } = this.urlParams

    apiQuestionnaireUpdate(data, questionnaireId, versionId)
    .then(() => {
      this.setState({ redirectToDashboard: true, isSaving: false })
    })
    .catch((e) => {
      this.setState({ isSaving: false })
      console.error(e)
    })
  }

  createNewVersionAndPublish() {
    const questionnaireId = this.props.params.questionnaireId
    const data = denormalizeToAPI(this.state)
    Object.assign(data, { parentVersion: this.state.version })

    apiQuestionnaireCreateVersion(data, questionnaireId)
      .then((response) => {
        const versionId = response.data
        this.publish(true, questionnaireId, versionId)
      })
      .catch((e) => {
        if (e?.response?.data === 'QUESTIONNAIRE_VERSIONS_NO_LIMIT_REACHED') {
          swal.fire({
            text: 'You cannot publish more than 10 versions',
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3DD8DB',
            confirmButtonText: 'Ok'
          })
        }else{
          console.log(e)
        }
        this.setState({ isSaving: false })
      })
  }

  publish(redirectToDashboard, questionnaireId = this.props.params.questionnaireId, versionId=this.props.params.versionId) {
    const data = denormalizeToAPI(this.state)

    apiQuestionnairePublish(data, questionnaireId, versionId)
      .then((response) => {
        this.setState({ redirectToDashboard, isSaving: false })
      })
      .catch((e) => {
        this.setState({ isSaving: false })
      })
  }

  updateQuestionsList({ questions, deletedQuestions = this.state.deletedQuestions }) {
    this.setState({
      questions,
      deletedQuestions,
      allQuestions: questions
    })
  }

  updateQuestion(index, question, callback) {
    const newQuestions = [...this.state.questions]
    newQuestions[index] = question
    this.setState({
      questions: newQuestions,
      allQuestions: newQuestions
    }, callback)
  }

  filterQuestions(filterAttributes) {
    const params = {
      ...filterAttributes,
      allQuestions: this.state.allQuestions,
      deletedQuestions: this.state.deletedQuestions
    }

    this.setState({
      ...filteredQuestions(params)
    })
  }

  formattedTitle(isEditMode, title, state, version) {
    if (!isEditMode) return 'New Team'
    if (state !== 'DRAFT') return `V${ version } - ${ title }`
    return `(Draft) V${ version } - ${ title }`
  }

  toggleAccordion(index) {
    this.setState({
      questionsAccordion: {
        ...this.state.questionsAccordion,
        [index]: !this.state.questionsAccordion[index]
      }
    })
  }

  renderTeamsDropdowns() {
    const { nrTeams } = this.state

    const steps = []
    for (let i=0; i<nrTeams; i++) {
      steps.push(
        <div className="teams-select">
          <div className="select teams-select mb-0">
            <select></select>
          </div>
          <img
            src={ imgDelTeam }
            alt=""
            className="inline-icon"
            onClick={ this.updateValue.bind(this, 'removeTeams') }
          />
        </div>)
    }

    return steps
  }

  render() {
    const isSending = false
    const messageArea = { visible: false }
    const currentUserIsSuperAdmin = userIsSuperAdmin(this.props.user)
    const currentAccountIsPaid = accountIsPaid(this.props.subscription)
    const {
      isLoading,
      picture
    } = this.state

    if (!currentAccountIsPaid && !currentUserIsSuperAdmin) {
      return (
        <div className="newQuestionnaire__page">
          <Page403 />
        </div>
      )
    }

    return (
      <div className="editUser__page">
        { isLoading &&
          <div className="newQuestionnaire__loading">
            <Loading />
          </div>
        }

        { !isLoading &&
          <React.Fragment>
            <div className="newQuestionnaire__section">
              <header className="newQuestionnaire__header">
                <h1 className="editUser__title">
                  Add New Profile:
                </h1>
                <div className="newQuestionnaire__submitButtons">
                  { isSending &&
                    <div className="newQuestionnaire__loading">
                      <Loading />
                    </div>
                  }

                  <div className={ this.disabledButton ? 'newQuestionnaire__disabledButton' : ''}>
                    <Button
                      theme={ this.disabledButton ? 'gray' : 'green' }
                      onClick={ this.handleSubmitForm }>
                      Save Changes
                    </Button>
                  </div>
                </div>
              </header>

              <form className="editUser__form">
                <div className="cont">
                  <div className="row">
                    <div className="col-md-3">
                      <label htmlFor="firstname" className="mb-1">First name</label>
                      <input
                        id="firstname"
                        type="text"
                        className="input_field"
                        onChange={ (e) => this.handleChange(e, 'firstname') }
                      />
                    </div>
                    <div className="col-md-3">
                      <label htmlFor="lastname" className="mb-1">Last name</label>
                      <input
                        id="lastname"
                        type="text"
                        className="input_field"
                        disabled={ this.disableInput }
                        onChange={ this.updateValue.bind(this, 'lastname') }
                      />
                    </div>
                  </div>
                  <div className="styleUploadImage ">
                    <div className="float-left mt-3">
                      <img className="teamIconImage" src={ picture } alt="" />
                    </div>
                    <div className="float-left ml-4 mt-4">
                      <label>Upload a photo</label>
                      {/* <ImageUploader
                        className="fileUpload"
                        withIcon={false}
                        label=''
                        labelStyle="styleLabelImageUpload"
                        buttonText='BROWSE...'
                        buttonClassName="buttonClassChange"
                        onChange={this.onDrop}
                        imgExtension={['.jpg', '.gif', '.png', '.gif']}
                        maxFileSize={3145728}
                      /> */}
                    </div>
                  </div>
                </div>
                <div className="cont">
                  <div className="row">
                    <div className="col-12">
                      <h2 className="subtitle">
                        Company Details
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <label htmlFor="position" className="mb-1">Position</label>
                      <input
                        id="position"
                        type="text"
                        className="input_field"
                        onChange={ this.updateValue.bind(this, 'position') }
                      />
                    </div>
                    <div className="col-md-3">
                      <label htmlFor="department" className="mb-1">Department</label>
                      <input
                        id="department"
                        type="text"
                        className="input_field"
                        onChange={ this.updateValue.bind(this, 'department') }
                      />
                    </div>
                    <div className="col-md-3">
                      <label htmlFor="companyName" className="mb-1">Company Name</label>
                      <input
                        id="companyName"
                        type="text"
                        className="input_field"
                        onChange={ this.updateValue.bind(this, 'companyName') }
                      />
                    </div>
                  </div>
                </div>
                <div className="cont">
                  <div className="row">
                    <div className="col-md-12 mt-2">
                      <h2 className="subtitle">Team(s)</h2>
                      { this.renderTeamsDropdowns() }

                      <img src={ imgAddTeam } alt="" className="inline-icon ml-0"
                        onClick={ this.updateValue.bind(this, 'nrTeams') }
                      />
                    </div>
                  </div>
                </div>
                <div className="cont">
                  <div className="row">
                    <div className="col-md-12 mt-2 mb-2">
                      <label htmlFor="email" className="mb-1">Email address</label><br/>
                      <input
                        id="email"
                        type="text"
                        className="input_field input_field_email_address"
                        readOnly={ true }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mt-2">
                      <div className="select select_summary_frequency">
                        <label className="mb-1" htmlFor="email-frequency">Email summary frequency</label>
                        <select id="email-frequency" onChange={ this.updateValue.bind(this, 'emailFrequency') }>
                          <option value="">choose...</option>
                          <option value="only urgent reminders">only urgent reminders</option>
                          <option value="all reminders">all reminders</option>
                        </select>
                        <br />
                        <div className="text-gray">Our email reports provide a summary of your account status and any pending tasks.</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cont">
                  <div className="row">
                    <div className="col-12">
                      <h2 className="subtitle">
                        Change Password
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <label htmlFor="currentPassword" className="mb-1">Current password</label>
                      <input
                        id="currentPassword"
                        type="password"
                        className="input_field"
                        onChange={ this.updateValue.bind(this, 'currentPassword') }
                      />
                    </div>
                    <div className="col-md-3">
                      <label htmlFor="newPassword" className="mb-1">New password</label>
                      <input
                        id="newPassword"
                        type="password"
                        className="input_field"
                        onChange={ this.updateValue.bind(this, 'newPassword') }
                      />
                    </div>
                    <div className="col-md-3">
                      <label htmlFor="retypePassword" className="mb-1">Retype password</label>
                      <input
                        id="retypePassword"
                        type="password"
                        className="input_field"
                        onChange={ this.updateValue.bind(this, 'retypePassword') }
                      />
                    </div>
                  </div>
                </div>

                <footer className="userSettings-footer">
                  <Link className="userSettings-footerLink" to="/osp/dashboard">Discard changes</Link>
                  { !isSending &&
                  <div>
                    { !messageArea.visible &&
                    <input
                      className="userSettings-footerButton"
                      type="submit"
                      value="Save changes"
                      onClick={ this.saveChanges } />
                    }

                    { messageArea.visible &&
                    <div className="userSettings-message">
                      <Message
                        hideMessageAction={ this.props.hideMessage }
                        status={ messageArea.status }
                        text={ messageArea.text } />
                    </div>
                    }
                  </div>
                  }
                  { isSending &&
                  <Loading />
                  }
                </footer>
              </form>
            </div>
          </React.Fragment>
        }
      </div>
    )
  }
}

const stateMap = (state) => ({
  subscription: state.subscriptionReducer.subscription
})

NewUserForm.propTypes = {
  subscription: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default compose(
  connect(stateMap, null),
)(NewUserForm)
