import React from 'react'
import AdditionalFieldsContainer from '../../../Common/AssetDocLockerComponents/AdditionalFieldsContainer'
import ColumnsContainer from '../../../Common/AssetDocLockerComponents/ColumnsContainer'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { saveLibrary, updateLibrary } from '../../../../api/DocLocker'
import _ from 'lodash'
import HistoryUpdateContainer from './HistoryUpdateContainer'

class AddLibrary extends React.Component {
  state = {
    library: {
      name: '',
      clientId: 0,
      historyUpdate: [
        {
          name: 'HISTORY NOTE',
          domId: 1,
          required: true,
          additional: false,
          columnOrder: 1,
          history: true,
          type: 'TEXTAREA',
          options: []
        },
        {
          name: 'UPDATE FREQUENCY',
          domId: 2,
          required: true,
          additional: false,
          columnOrder: 2,
          history: true,
          type: 'RADIO',
          options: [
            {
              name: 'Weekly',
              custom: false
            },
            {
              name: 'Every Month',
              custom: false
            },
            {
              name: 'Every 6 Months',
              custom: false
            }
          ]
        }
      ],
      additionalFields: [
        {
          name: '',
          domId: 1,
          required: false,
          additional: true,
          columnOrder: 1,
          type: 'NULL',
          options: [],
          history: false
        }
      ],
      columns: [
        {
          name: '',
          domId: 1,
          required: false,
          additional: false,
          columnOrder: 1,
          type: 'NULL',
          options: [],
          history: false
        },
        {
          name: '',
          domId: 2,
          required: false,
          additional: false,
          columnOrder: 2,
          type: 'NULL',
          options: [],
          history: false
        },
        {
          name: '',
          domId: 3,
          required: false,
          additional: false,
          columnOrder: 3,
          type: 'NULL',
          options: [],
          history: false
        },
        {
          name: '',
          domId: 4,
          required: false,
          additional: false,
          columnOrder: 4,
          type: 'NULL',
          options: [],
          history: false
        },
        {
          name: '',
          domId: 5,
          required: false,
          additional: false,
          columnOrder: 5,
          type: 'NULL',
          options: [],
          history: false
        },
        {
          name: '',
          domId: 6,
          required: false,
          additional: false,
          columnOrder: 6,
          type: 'NULL',
          options: [],
          history: false
        }
      ]
    },
    columnsToDelete: [],
    optionsToDelete: []
  }

  componentDidMount() {
    if (!_.isEmpty(this.props.library)) {
      this.setState({
        library: this.props.library
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.library !== this.props.library && !_.isEmpty(this.props.library)) {
      this.setState({
        library: this.props.library
      })
    }
  }

  updateAdditionalFields = (newFieldsArray, columnToRemove, optionToRemove) => {
    const { columnsToDelete, optionsToDelete } = this.state
    if (columnToRemove) {
      columnsToDelete.push(columnToRemove)
    }
    if (optionToRemove) {
      optionsToDelete.push(optionToRemove)
    }

    this.setState({
      library: {
        ...this.state.library, additionalFields: newFieldsArray, columnsToDelete, optionsToDelete
      }
    })
  }

  setLibraryName = (name) => {
    this.setState({
      library: {
        ...this.state.library, name: name
      }
    })
  }

  updateColumns = (newColumnsArray, columnToRemove, optionToRemove) => {
    const { columnsToDelete, optionsToDelete } = this.state
    if (columnToRemove) {
      columnsToDelete.push(columnToRemove)
    }
    if (optionToRemove) {
      optionsToDelete.push(optionToRemove)
    }

    this.setState({
      library: {
        ...this.state.library, columns: newColumnsArray, columnsToDelete, optionsToDelete
      }
    })
  }

  updateHistoryUpdate = (newHistoryUpdateArray, columnToRemove, optionToRemove) => {
    const { columnsToDelete, optionsToDelete } = this.state

    if (optionToRemove) {
      optionsToDelete.push(optionToRemove)
    }

    if (columnToRemove) {
      columnsToDelete.push(columnToRemove)
    }

    this.setState({
      library: {
        ...this.state.library, historyUpdate: newHistoryUpdateArray, columnsToDelete, optionsToDelete
      }
    })
  }

  saveLibrary = () => {
    const { library } = this.state
    library.clientId = this.props.clientId
    for (let i = 0; i < library.columns.length; i++) {
      library.columns[i].columnOrder = i + 1
    }
    for (let i = 0; i < library.additionalFields.length; i++) {
      library.additionalFields[i].columnOrder = i + 1
    }
    for (let i = 0; i < library.historyUpdate.length; i++) {
      library.historyUpdate[i].columnOrder = i + 1
    }

    library.columns = [...library.columns, ...library.additionalFields, ...library.historyUpdate]
  
    library.columnsToDelete = this.state.columnsToDelete.filter((column) => column.id)
    library.optionsToDelete = this.state.optionsToDelete.filter((option) => option.id)
    
    if (library.id) {
      updateLibrary(library, library.id).then((res) => {
        if (res) {
          this.props.closeAddEdit()
        }
      })
    } else {
      saveLibrary(library).then((res) => {
        if (res) {
          this.props.closeAddEdit()
        }
      })
    }
  }

  componentWillUnmount() {
    this.setState({
      library: {}
    })
  }

  render() {
    const { library } = this.state
    return (
      !_.isEmpty(library) ? <div className="add-library">
        <div className="add-library__input-section">
          <label htmlFor="name">LIBRARY NAME</label>
          <input type="text" placeholder="Library name" onChange={ (e) => this.setLibraryName(e.target.value) } value={library.name}/>
        </div>
        <div className="add-library__columns-section">
          <ColumnsContainer columns={library.columns} updateItems={this.updateColumns} clientId={this.props.clientId} application="doc-locker" updateMode={ this.props.updateMode }/>
        </div>
        <div className="add-library__history-update-section">
          <HistoryUpdateContainer historyUpdate={library.historyUpdate} updateItems={this.updateHistoryUpdate} updateMode={ this.props.updateMode }/>
        </div>
        <div className="add-library__fields-section">
          <AdditionalFieldsContainer additionalFields={library.additionalFields} updateItems={ this.updateAdditionalFields } updateMode={ this.props.updateMode } application="doc-locker"/>
        </div>
        <div className="add-library__buttons-section">
          <button className="button-green cancel-button" onClick={this.props.closeAddEdit}>CANCEL</button>
          <button className="button-green" onClick={() => this.saveLibrary()}>{library.id ? 'UPDATE' : 'SAVE'}</button>
        </div>
      </div>
        : ''
    )
  }
}

AddLibrary.propTypes = {
  library: PropTypes.object,
  closeAddEdit: PropTypes.func.isRequired,
  clientId: PropTypes.number,
  user: PropTypes.object,
  updateMode: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id,
  user: state.userReducer.user
})

export default connect(mapStateToProps)(AddLibrary)