import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import './ConnectorItem.css'

const ConnectorItem = ({ connector }) => (
  <div className="connectorItem-wrapper">
    <div>Logo</div>
    <Link to='/privacy/connector/new'>
      <div>Click to add { connector.name }</div>
    </Link>
  </div>
)

ConnectorItem.propTypes = {
  connector: PropTypes.object.isRequired
}

export default ConnectorItem