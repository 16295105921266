import React from 'react'

import './AddKeywords.css'

class AddKeywords extends React.Component {
  setKeywordRule = (event) => {
    const index = event.target.name.replace('keyword-rule-', '')
    const newKeywords = [...this.props.keywords]
    newKeywords[index] = {
      ...this.props.keywords[index],
      rule: event.target.value
    }
    this.props.updateKeywords(newKeywords)
  }

  setKeywordValue = (event) => {
    const index = event.target.name.replace('keyword-value-', '')
    const newKeywords = [...this.props.keywords]
    newKeywords[index] = {
      ...this.props.keywords[index],
      value: event.target.value
    }
    this.props.updateKeywords(newKeywords)
  }

  render = () => {
    return (
      <React.Fragment>
        { this.props.keywords.map((field, index) => (
          <div className="addKeywords__params" key={ index }>
            <div className="addKeywords__formGroup addKeywords__select">
              <select
                name={ `keyword-rule-${ index }` }
                value={ field.rule }
                onChange={ this.setKeywordRule }>
                <option value="">Choose...</option>
                <option value="include">Include</option>
                <option value="exclude">Exclude</option>
              </select>
            </div>
            <div className="addKeywords__formGroup addKeywords__value">
              <input
                disabled={ field.rule === '' }
                name={ `keyword-value-${ index }` }
                type='text'
                value={ field.value }
                onChange={ this.setKeywordValue } />
            </div>
          </div>
        ))}
      </React.Fragment>
    )
  }
}

export default AddKeywords