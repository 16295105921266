import React from 'react'
import { generatePiiAnalyticsTree, renderTreePiiNewBox
} from '../../../../utils/SmartBox/SmartBoxMethods'
import './NewBoxCreate.scss'
import { Button, Tree, Tooltip } from 'antd'
import {getAnnotationColorsByClientId, getAnnotationColorsCategoryByClientId} from "../../../../api/Sar";
import {compose} from "redux";
import {connect} from "react-redux";
import CustomNotificationManager from "../../../Common/CustomNotificationManager";
import {setSmartBoxNewBox} from "../../../../actions/smartBoxActions";
class NewBoxAiPii extends React.Component {

  constructor(props) {
    super(props)
    this.state= {
      categoriesTreePii: [],
      checkedKeys: [],
      //expandedKeys: ['doc_annotate_select_all'],
      expandedKeys: [],
      categoryVisited: '',
      annotationsCategoriesColours: [],
      checkedKeysPii: [],
      statusOnCheckPii: false,
      autoExpandParent: true
    }
  }
  componentDidMount() {
    let { categoriesTreePii } = this.state
    categoriesTreePii = generatePiiAnalyticsTree()
    const listSortCategoriesTree = categoriesTreePii && categoriesTreePii.length > 0 ? categoriesTreePii.sort((userA, userB) => (userA.title.localeCompare(userB.title))) : ''

    const checkedKeys = []
    const newCheckedKeys = []
    listSortCategoriesTree.forEach(item => {
      item.children && item.children.length > 0 && item.children.forEach((check) => {
        if (check && typeof check !== "undefined") {
          checkedKeys.push(check.key)
          newCheckedKeys.push(check.key)
        }
      })
    })
    this.setState({
      categoriesTreePii: listSortCategoriesTree,
      checkedKeysPii: this.props.smartBoxNewBox.keysPii && this.props.smartBoxNewBox.keysPii.length > 0 ? this.props.smartBoxNewBox.keysPii :
          this.props.statusBackAiPii && this.props.smartBoxNewBox.statusPii ? this.props.smartBoxNewBox.keysPii : newCheckedKeys,
      checkedKeys: this.props.smartBoxNewBox.keysPii && this.props.smartBoxNewBox.keysPii.length > 0 ? this.props.smartBoxNewBox.keysPii :
          this.props.statusBackAiPii && this.props.smartBoxNewBox.statusPii ? this.props.smartBoxNewBox.keysPii : checkedKeys
    }, () => {
      //this.fetchAnnotationColorsCategories(this.props.clientId, listSortCategoriesTree)
      this.getAnnotateColorPii(this.props.clientId, this.props.userId, categoriesTreePii)
    })
  }

  componentDidUpdate(prevProps, prevState) {
     if (prevProps !== this.props && this.props.smartBoxNewBox && this.props.smartBoxNewBox.keysPii && this.props.smartBoxNewBox.keysPii.length > 0) {
        // this.setState({
        //   checkedKeysPii: this.props.smartBoxNewBox.keysPii,
        //   checkedKeys: this.props.smartBoxNewBox.keysPii,
        // })
     }
  }

  getAnnotateColorPii = (clientId, userId, categoriesTree) => {
    getAnnotationColorsByClientId(clientId, userId).then((response) => {
      response.data && response.data.length > 0 && response.data.forEach((color) => {
        categoriesTree && categoriesTree.length > 0 && categoriesTree.forEach((cat) => {
          if (color.category === cat.key) {
            cat.color = color.color
          }

        })
      })
      this.setState({
        annotationsCategoriesColours: response.data,
      })
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  fetchAnnotationColorsCategories(clientId, categoriesTree) {
    //const { categoriesTree } = this.state
    getAnnotationColorsCategoryByClientId(clientId).then(({ data }) => {
      data && data.length > 0 && data.forEach((item) => {
        categoriesTree && categoriesTree.length > 0 && categoriesTree.forEach((cat) => {
          if (item.category === cat.key) {
            cat.color = item.color
          }

        })
      })
      this.setState({
        annotationsCategoriesColours: data,
      })
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  onCheck = (checkedKeys, { checkedNodes }) => {
    const { categoriesTreePii } = this.state
    let newCheckedKeys = []
    newCheckedKeys = checkedKeys.filter((redChild) => !categoriesTreePii.map((red) => red.key).includes(redChild))
    this.props.setSmartBoxNewBox(newCheckedKeys, this.props.smartBoxNewBox.checkedBusiness, this.props.smartBoxNewBox.selectRegexList, true,
        this.props.smartBoxNewBox.statusBusiness, this.props.smartBoxNewBox.statusRegEx )
    this.setState({
      checkedKeys,
      checkedKeysPii: newCheckedKeys,
      checkedKeysObjectRefs: checkedNodes.map((node) => node.props.dataRef).filter((obj) => obj.identifier === 'category-child').map((obj) => obj.key),
      checkKeysForNewFolder: false,
      statusOnCheckPii: true
    })
  }
  expandHideRow = ({ props }) => {
    const { dataRef } = props
    let { expandedKeys } = { ...this.state }
    if (expandedKeys.includes(dataRef.key)) {
      expandedKeys = expandedKeys.filter((eKey) => eKey !== dataRef.key)
    } else {
      expandedKeys.push(dataRef.key)
    }
    this.setState({
      expandedKeys,
      autoExpandParent: false
    })
  }
  expandCategoryVisited = (e, key, action) => {
    this.setState({
      categoryVisited: action === 'add' ? key : ''
    })
  }
  expandHideCategory = (e, key, action) => {
    e.stopPropagation()
    let { expandedKeys } = { ...this.state }
    if (action === 'add') {
      expandedKeys.push(key)
    } else {
      expandedKeys = expandedKeys.filter((eKey) => eKey !== key)
    }
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    }, () => this.expandCategoryVisited(e, key, action))
  }
  onCreateBoxOrNextPage = (e, status) => {
    const { checkedKeysPii } = this.state
     this.props.onCreateBoxOrNextPage(e, status)
     this.props.onGetCheckKeysAiPii(checkedKeysPii)
  }
  handleBackAi = (e, status) => {
    const { checkedKeysPii } = this.state
     this.props.handleCancelBackPage(e, status)
     this.props.onGetCheckKeysAiPii(checkedKeysPii)
  }
  render() {
    const { categoriesTreePii, checkedKeys, expandedKeys, categoryVisited, autoExpandParent } = this.state
    return (
      <div className='box-setup-pii'>
        <div className="header-box-AI">
          {/*<span className="title-ai">*/}
          {/*    AI*/}
          {/*</span>*/}
          {/*<span className="description-ai">*/}
          {/*    Setup conditions the AI will particular look for.*/}
          {/*</span>*/}
          <div className="top-label">AI configuration
            <Tooltip overlayClassName="help-message-locations" title={<div className="content-help-message-locations">
              <span className="text-information-header">AI Search</span>
              <div>
                <span className="text-information">Select categories and terms to include in your search.</span>
                <span className="more-info-link" onClick={ () => window.open( /*'https://support.smartbox.ai/support/solutions/articles/47001190571-applying-box-settings'*/
                   "https://support.smartbox.ai/support/solutions/articles/47001174215" , '_blank') }> More info. </span>
              </div>
            </div>}>
              <span className="icon-v14-question"></span>
            </Tooltip></div>
        </div>
        <div className="update-content-pii-tree">
          <span className="title-pii-configuration">PII</span>
        <div className="content-pii-tree">
          {categoriesTreePii && categoriesTreePii.length > 0 &&
          <Tree
              checkable
              selectable={false}
              defaultExpandedKeys={ expandedKeys }
              className={'box-tree'}
              autoExpandParent={ autoExpandParent }
              checkedKeys={checkedKeys}
              onCheck={this.onCheck}
              defaultExpandAll={true}
              expandedKeys={expandedKeys}
              onSelect={(selectedKeys, {node}) => {
                this.expandHideRow(node)
              }}
          >
            {
              renderTreePiiNewBox(categoriesTreePii, expandedKeys, this.expandHideCategory, checkedKeys, categoryVisited)
            }
          </Tree>
          }
        </div>
        </div>
        <div className="footer-buttons-action-new-box">
          <Button className="action-cancel-new-box" onClick={ (e) => this.handleBackAi(e, 'back-ai')}>
            Back
          </Button>
          <div className="statistics-active-new-box">
            <span className={ this.props.activePage === 'ai-pii' ? 'dot-new-box dot-new-box active-dot-new-box' : "dot-new-box" }></span>
            <span className="dot-new-box"></span>
            <span className="dot-new-box"></span>
            <span className="dot-new-box"></span>
          </div>
          <Button className="action-create-new-box" onClick={ (e) => this.onCreateBoxOrNextPage(e, 'new-box-pii') }
          >
            Next
          </Button>
        </div>
      </div>
    )
  }
}

NewBoxAiPii.propTypes = {
}
const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id,
  userId: state.userReducer.user.id,
  smartBoxNewBox: state.smartBox.smartBoxNewBox,
})
export default compose(
    connect(mapStateToProps, {
      setSmartBoxNewBox
    }),
)(NewBoxAiPii)