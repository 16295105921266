import React from 'react'
import { Link, Navigate } from 'react-router-dom'
import './SmartBoxFolderDataCollector.scss'
import HeaderNavigationBox from '../../../Common/HeaderNavigationBox'

import {
    setSmartBoxDirectoryNote,
    setSmartBoxFilesTable, setSmartBoxSelectedDocuments,
    setSmartBoxToggleFilePreview, setSmartBoxAnalysisPercent
} from '../../../../actions/smartBoxActions'
import { compose } from 'redux'
import { connect } from 'react-redux'
import withRouter from '../../../../private-routers/withRouter'
import {
    apiCollectorPageGetCollectorPageData,
    apiCollectorPageGetCollectorPageUploadHistoryData,
    apiCollectorPageSaveCollectorPageData,
    apiGetBusinessProcessList,
    apiGetConnectorList,
    apiGetDataCollectorPageDataWorkflowTicket,
    apiGetSharePointSiteFolderList,
    apiGetSharePointSiteList,
    getSmartBoxFileById
} from "../../../../api/SmartBox";
import CustomNotificationManager from "../../../Common/CustomNotificationManager";
import {Input, Select, Upload, Icon, Button, Checkbox, DatePicker, Collapse, Modal} from 'antd'
import Switcher from "../../../Common/Switcher";
import moment from "moment";
import axios from "axios";
import {ConcurrencyManager} from "axios-concurrency";
import _ from "lodash";
const { Option } = Select
const { Panel } = Collapse
const isLocalhost = () => {
    return window.location.hostname === 'localhost'
}
const host = isLocalhost() ? 'http://localhost:8080' : ''
class SmartBoxFolderDataCollector extends React.Component {
    state = {
        folder: {},
        valueShareBusiness: true,
        dataConnectorsList: [],
        formDataCollector: {
            connectorsSelect: [
                {
                    valueConnectorSelect: null,
                    listInputsOnedrive: [{
                        folderName: null,
                        selected: false,
                        key: 0
                    }],
                    listDateExchange: [{
                        dateTime: [],
                        startDates: null,
                        endDates: null,
                        selectEmailAccount: null,
                        selected: false,
                        key: 0
                    }],
                    listSharePoint: [{
                        siteNameSharePoint: null,
                        folderNameSiteSharePoint: null,
                        dataSelectSiteFolderListSharePoint: null,
                        dataSelectSiteListSharePoint: [],
                        selected: false,
                    }]
                }
            ],
            valueConnectorSelect: null
        },
        hoverAddConnector: false,
        listBusinessProcess: [],
        valueSelectBusinessProcess: '',
        dataSources: [],
        businessProcessQueries: [],
        disabledActionCollect: false,
        disabledActionCollectDate: false,
        disabledBusinessProcessQueries: false,
        visibleDataUploadFrom: false,
        fileList: [],
        nameFileUpload: '',
        valueInputDesktop: '',
        formOtherDataSourcesSelect: {},
        dataSourcesSelect: {},
        disabledOptionShareBusiness: false,
        selectConnectorValue: null,
        inputStorageLocationValue: '',
    }

    componentDidMount() {
        this.fetchFolderInfo()
        this.onGetConnectorList()
        this.onGetBusinessProcess()
        this.onCollectorPageGetCollectorPageUploadHistoryData()
        //this.callGetDataCollectorDataAPI()
        this.onCollectorPageGetCollectorPageData(null, null, 'get' )
        if (this.props.smartBoxBaseDataTree && this.props.smartBoxBaseDataTree.length > 0) {
            return true
        }
        this.axiosClone = axios.create({ headers: this.props.smartBoxAxiosHeaders })
        this.manager = ConcurrencyManager(this.axiosClone, this.MAX_CONCURRENT_REQUESTS)
    }

    componentWillUnmount() {

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEmpty(this.props.smartBoxAxiosHeaders)) {
            this.axiosClone = axios.create({ headers: this.props.smartBoxAxiosHeaders })
            this.manager = ConcurrencyManager(this.axiosClone, this.MAX_CONCURRENT_REQUESTS)
        }
    }
    onCollectorPageGetCollectorPageUploadHistoryData = () => {
        apiCollectorPageGetCollectorPageUploadHistoryData(this.props.params.id).then((response) => {
            if (response.data.title && response.data.title.toLowerCase() === 'success') {
                CustomNotificationManager.success(response.data.message, 'Success')
            } else if (response.data.title && response.data.title.toLowerCase() === 'info') {
                CustomNotificationManager.info(response.data.message, 'Info')
            }
            this.setState({
                dataUploadHistory: response.data && response.data.length > 0 ? response.data : []
            })
        })
    }
    callGetDataCollectorDataAPI = () => {

            apiGetDataCollectorPageDataWorkflowTicket(483, 134906).then(response => {
                if(response !== null && response.data !== null){
                    let indexing = 0
                    response.data.dataSources && response.data.dataSources.length > 0 && response.data.dataSources.forEach((item) => {
                        const connectorDataSourcesUpdate = []
                        item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2, index2) => {
                            if (item2.folderNames && item2.folderNames.length > 0) {
                                item2.folderNames.forEach((item3, index3) => {
                                    //item.connectorDataSources.splice(item.connectorDataSources.length + 1, 0, itemOnedrive)
                                    connectorDataSourcesUpdate.push({
                                        connectorId: item2.connectorId,
                                        connectorName: item2.connectorName,
                                        connectorType: item2.connectorType,
                                        selected: item2.selected,
                                        folderNames: item3,
                                        visibleSelected: index3 === 0 ? true : false,
                                    })
                                })
                            } else if (item2.startDates && item2.startDates.length > 0 && item2.endDates && item2.endDates.length > 0) {
                                item2.startDates.forEach((dateStart, indexStart) => {
                                    const dateStart1 = dateStart.split(" ")[0]
                                    const dateEnd1 = item2.endDates[indexStart].split(" ")[0]
                                    const [day, month, year] = dateStart1.split('-')
                                    const [day1, month1, year1] = dateEnd1.split('-')
                                    const formatDateStart = `${year}-${month}-${day}`
                                    const formatDateEnd = `${year1}-${month1}-${day1}`

                                    connectorDataSourcesUpdate.push({
                                        connectorId: item2.connectorId,
                                        connectorName: item2.connectorName,
                                        connectorType: item2.connectorType,
                                        selected: item2.selected,
                                        folderNames: item2.folderNames,
                                        startDates: formatDateStart,
                                        endDates: formatDateEnd,
                                        dateTime: [formatDateStart, formatDateEnd],
                                        visibleSelected: indexStart === 0 ? true : false,
                                    })
                                })
                            } else if (item2.siteAndFolderDataCollectorDTOList && item2.siteAndFolderDataCollectorDTOList.length > 0) {
                                item2.siteAndFolderDataCollectorDTOList.forEach((shareSite2, indexShare2) => {
                                    connectorDataSourcesUpdate.push({
                                        connectorId: item2.connectorId,
                                        connectorName: item2.connectorName,
                                        connectorType: item2.connectorType,
                                        selected: item2.selected,
                                        folderNames: item2.folderNames,
                                        startDates: item2.startDates,
                                        endDates: item2.endDates,
                                        dateTime: [],
                                        visibleSelected: indexShare2 === 0 ? true : false,
                                        siteIdSharePoint: shareSite2.siteId,
                                        siteNameSharePoint: shareSite2.siteName,
                                        folderNameSiteSharePoint: shareSite2.folderName,
                                    })
                                })
                            } else {
                                connectorDataSourcesUpdate.push({
                                    connectorId: item2.connectorId,
                                    connectorName: item2.connectorName,
                                    connectorType: item2.connectorType,
                                    selected: item2.selected,
                                    folderNames: item2.folderNames,
                                    startDates: item2.startDates,
                                    endDates: item2.endDates,
                                    dateTime: [],
                                    visibleSelected: true,
                                })
                            }
                            //}
                        })
                        item.connectorDataSources = connectorDataSourcesUpdate
                        item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2, index2) => {
                            indexing = indexing + 1
                            // item2.selected = false
                            item2.selected = item2.selected
                            item2.index = indexing
                            item2.key = indexing

                        })
                    })
                    response.data.businessProcessQueries && response.data.businessProcessQueries.length > 0 && response.data.businessProcessQueries.forEach((item, index) => {
                        // item.selected = false
                        item.selected = item.selected
                        item.key = index
                    })
                    this.setState({
                        dataSources : response.data.dataSources,
                        businessProcessQueries : response.data.businessProcessQueries ? response.data.businessProcessQueries : [],
                        businessProcessId : response.data.businessProcessId,
                    })
                    if(response.data.businessProcessName !== null){
                        this.setState({
                            businessProcessName : response.data.businessProcessName,
                        })
                    }

                }

            })
    }

    onGetBusinessProcess = () => {
        apiGetBusinessProcessList().then((response) => {
            this.setState({
                listBusinessProcess: response.data
            }, () => {
                const { businessProcessId } = this.state
                let businessProcessName = null
                response.data && response.data.length > 0 && response.data.forEach((item) => {
                    if (businessProcessId === item.id) {
                        businessProcessName = item.businessProcessName
                    }
                })
                this.setState({
                    valueSelectBusinessProcess: businessProcessName
                })
            })
        })
    }
    onGetConnectorList = () => {
        apiGetConnectorList(this.props.clientId).then((response) => {
            response.data && response.data.length > 0 && response.data.forEach((item) => {
                item.active = true
            })
            this.setState({
                dataConnectorsList: response.data ? response.data : []
            })
        })
    }

    fetchFolderInfo = () => {
        this.setState({
            folderId: this.props.params.id
        }, () => {
            getSmartBoxFileById(this.state.folderId).then((response) => {
                if (response.status === 200) {
                    if (!response.data.path && !response.data.smartDocument) {
                        CustomNotificationManager.error('The current folder no longer exists or you do not have access to its settings', 'Error')
                        const path = '/smart-box'
                        this.props.navigate(path)
                    }
                    const folderData = response.data.smartDocument
                    const newIndexParent = folderData.relativePath.substr(1).indexOf('/')
                    const parentName = folderData.relativePath.substr(1, newIndexParent)
                    if(folderData.relativePath.substr(1).indexOf('/') !== -1) {
                        this.props.setSmartBoxAnalysisPercent(100)
                    }
                    let idParentBase = ''
                    this.props.smartBoxBaseDataTree.forEach((tree) => {
                        if (tree.name === parentName) {
                            idParentBase = tree.id
                        }
                    })
                    this.setState({
                        folder: folderData,
                        paths: response.data.path,
                        parentNameBox: parentName,
                        idParentBase: idParentBase
                    })
                }
            }).catch((error) => {
                if (error?.response?.status === 500 && error?.response?.data?.detail === 'You don\'t have access to this folder') {
                    CustomNotificationManager.error('You don\'t have access to this folder', 'Error')
                    this.props.navigate('/smart-box')
                }else if (error?.response?.status === 504) {
                    CustomNotificationManager.cleanBuffer('warning', '')
                }else{
                    console.log(error)
                }
            })
        })
    }
    handleChangeActiveShareBusinessDisabled(e) {

    }
    handleChangeActiveShareBusiness(status) {
        const { valueShareBusinessResponse } = this.state
        this.setState({
            valueShareBusiness: status,
            dataSources: []
        }, () => {
            if (!status) {
                const { dataSources } = this.state
                const dataSources2 = []
                const otherDataSources2 = []
                dataSources2.push({
                    connectorDataSources: [{
                        connectorId: null,
                        visibleConnectorsSelected: true,
                        key: 1
                    }],
                    otherDataSources: [{
                        dataLocation: null,
                        dataLocationPath: null,
                        dataLocationId: null,
                        visibleSelected: true,
                        key: 1
                    }]
                })
                this.setState({
                    dataSources: dataSources2,
                    fileSelectUpload: [],
                    formOtherDataSourcesSelect: {},
                    nameFileUpload: '',
                    valueInputDesktop: ''
                }, () => {
                    if (status === valueShareBusinessResponse) {
                        //this.onCollectorPageGetCollectorPageData(null, null, 'get2' )
                    }
                })
                if (status === valueShareBusinessResponse) {
                    this.onCollectorPageGetCollectorPageData(null, null, 'get2' )
                }
            } else {
                this.setState({
                    fileSelectUpload: [],
                    formOtherDataSourcesSelect: {},
                    nameFileUpload: '',
                    valueInputDesktop: ''
                })
                if (status === valueShareBusinessResponse) {
                    this.onCollectorPageGetCollectorPageData(null, null, 'get2' )
                }
            }
        })
    }
    onChangeSelectConnector = (e, position, key, indexConnect) => {
        const { dataConnectorsList, dataSources } = this.state
        let itemSource = {}
        dataSources && dataSources.length > 0 && dataSources.forEach((item, index) => {
            let indexInput = 0
            dataConnectorsList && dataConnectorsList.length > 0 && dataConnectorsList.forEach((form) => {
               if (e === form.connectorId) {
                   itemSource = {
                       connectorId: form.connectorId,
                       connectorName: form.connectorName,
                       connectorType: form.connectorProvider,
                       folderNames: null,
                       startDates: null,
                       endDates: null,
                       folderNameSiteSharePoint: null,
                       selected: false,
                       visibleSelected: true,
                       dateTime: [],
                       index: item.connectorDataSources.length + 1,
                       key: item.connectorDataSources.length + 1,

                   }
                   item.connectorDataSources[indexConnect].connectorId = form.connectorId
                   item.connectorDataSources[indexConnect].connectorName = form.connectorName
                   item.connectorDataSources[indexConnect].connectorType = form.connectorProvider
                   item.connectorDataSources[indexConnect].folderNames = null
                   item.connectorDataSources[indexConnect].startDates = null
                   item.connectorDataSources[indexConnect].endDates = null
                   item.connectorDataSources[indexConnect].selected = false
                   item.connectorDataSources[indexConnect].visibleSelected = true
                   item.connectorDataSources[indexConnect].visibleConnectorsSelected = true
                   item.connectorDataSources[indexConnect].dateTime = []
                   item.connectorDataSources[indexConnect].dataSelectSiteListSharePoint = []
                   item.connectorDataSources[indexConnect].dataSelectSiteFolderListSharePoint = []
                   item.connectorDataSources[indexConnect].index = item.connectorDataSources.length + 1
                   item.connectorDataSources[indexConnect].key = item.connectorDataSources.length + 1
                   this.onChangeSelectSite(e, key, item.connectorDataSources[indexConnect], indexConnect)
               }
            })

            //item.connectorDataSources.splice(indexConnect, 0, itemSource)
            item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2, index2) => {
                item2.index = index2 + 1
                item2.key = index2 + 1
            })
        })
        this.setState({
            dataSources,
            selectConnectorValue: e
        })
    }

    onChangeSelectSite = (e, key, form1, indexConnect) => {
        const { dataSources } = this.state
        const getSharePointSitesDTO = {
            connectorId: form1.connectorId
        }
        if (form1 && form1.connectorType && form1.connectorType === 'Microsoft 365 SharePoint') {
            apiGetSharePointSiteList(getSharePointSitesDTO).then((response) => {
                this.setState({
                    dataSelectSiteListSharePoint : response.data && response.data.sharePointSiteDTOList ? response.data.sharePointSiteDTOList : []
                })
                dataSources && dataSources.length > 0 && dataSources.forEach((item, indexData) => {
                    item.connectorDataSources[indexConnect].dataSelectSiteListSharePoint = response.data && response.data.sharePointSiteDTOList ? response.data.sharePointSiteDTOList : []
                })
                this.setState({
                    dataSources
                })
            })
        }
    }

    onChangeSelectSiteForFolder = (e, form1, key) => {
        const { dataSources } = this.state
        const getSharePointSiteFolders = {
            connectorId: form1.connectorId,
            siteId: e
        }
        apiGetSharePointSiteFolderList(getSharePointSiteFolders).then((response) => {
            dataSources && dataSources.length > 0 && dataSources.forEach((item) => {
                item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2, index) => {
                    if (key === index && item2.connectorType === 'Microsoft 365 SharePoint') {
                        item2.siteIdSharePoint = e
                        item2.dataSelectSiteListSharePoint && item2.dataSelectSiteListSharePoint.length > 0 && item2.dataSelectSiteListSharePoint.forEach((elem) => {
                            if (elem.siteId === e) {
                                item2.siteNameSharePoint = elem.siteName
                                item2.folderNameSiteSharePoint = null
                                item2.dataSelectSiteFolderListSharePoint = response.data && response.data.sharePointSiteListItemDTOS ? response.data.sharePointSiteListItemDTOS : []
                            }
                        })
                    }
                })
            })
            this.setState({
                dataSources
            }, () => {
                //this.onValidateDataCollect(dataSources)
            })
        })
    }
    onChangeSelectFolder2Site = (e, form1, key) => {
        const { dataSources } = this.state
        dataSources && dataSources.length > 0 && dataSources.forEach((item) => {
            item.connectorDataSources[key].folderNameSiteSharePoint = e
            item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2, index) => {
                if (key === index && item2.connectorType === 'Microsoft 365 SharePoint') {
                    item2.folderNameSiteSharePoint = e
                }
            })
        })
        this.setState({
            dataSources
        })
    }
    addNewSelectConnector = (e, arg, index, indexConnect) => {
        const {dataSources } = this.state
        if(arg === 'INCREMENT') {
            dataSources && dataSources.length > 0 && dataSources.forEach((item, index) => {
                item.connectorDataSources.push({
                    connectorId: null,
                    visibleConnectorsSelected: true
                })
            })
            this.setState({
                dataSources
            })
        } else {
            dataSources && dataSources.length > 0 && dataSources.forEach((item, index) => {
                item.connectorDataSources = item.connectorDataSources.filter((sPermission, key) => key !== indexConnect)
            })
            this.setState({
                dataSources
            })
        }

    }
    onHoverDataCollector = (status, item) => {
     switch (status){
         case 'addConnector' :
             this.setState({
                 hoverAddConnector: true
             })
             break
         default: break
     }
    }
    onLeaverDataCollector = () => {
        this.setState({
            hoverAddConnector: false
        })
    }
    onSelectBusinessProcess = (e) => {
        const { listBusinessProcess, valueShareBusiness } = this.state
        let businessProcessId = null
        listBusinessProcess && listBusinessProcess.length > 0 && listBusinessProcess.forEach((item) => {
             if (e === item.businessProcessName) {
                 businessProcessId = item.id
             }
        })
        this.setState({
            valueSelectBusinessProcess: e,
            businessProcessId: businessProcessId,
        })
        this.onCollectorPageGetCollectorPageData(businessProcessId, valueShareBusiness, 'select' )
    }
    onCollectorPageGetCollectorPageData = (businessProcessId, valueShareBusiness, status ) => {
        let getCollectorPageDataRequestDTO = {}
        if (status === 'select') {
            getCollectorPageDataRequestDTO = {
                businessProcessId: businessProcessId,
                businessProcessActive: valueShareBusiness,
                boxId: this.props.params.id
            }
        } else {
            getCollectorPageDataRequestDTO = {
                boxId: this.props.params.id
            }
        }
        this.setState({
            disabledOptionShareBusiness : true
        }, () => {
            apiCollectorPageGetCollectorPageData(getCollectorPageDataRequestDTO).then((response) => {
                const { listBusinessProcess } = this.state
                if(response !== null && response.data !== null){
                    //const connectorDataSourcesUpdate = []
                    const duplicates = []
                    const uniqueElements = new Set()
                    let indexing = 0
                    response.data.dataSources && response.data.dataSources.length > 0 && response.data.dataSources.forEach((item) => {
                        const connectorDataSourcesUpdate = []
                        item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2, index2) => {
                            if (item2.folderNames && item2.folderNames.length > 0) {
                                item2.folderNames.forEach((item3, index3) => {
                                    //item.connectorDataSources.splice(item.connectorDataSources.length + 1, 0, itemOnedrive)
                                    connectorDataSourcesUpdate.push({
                                        connectorId: item2.connectorId,
                                        connectorName: item2.connectorName,
                                        connectorType: item2.connectorType,
                                        selected: item2.selected,
                                        folderNames: item3,
                                        visibleSelected: index3 === 0 ? true : false,
                                        visibleConnectorsSelected: index3 === 0 ? true : false,
                                    })
                                })
                            } else if (item2.startDates && item2.startDates.length > 0 && item2.endDates && item2.endDates.length > 0) {
                                item2.startDates.forEach((dateStart, indexStart) => {
                                    const dateStart1 = dateStart.split(" ")[0]
                                    const dateEnd1 = item2.endDates[indexStart].split(" ")[0]
                                    const [day, month, year] = dateStart1.split('-')
                                    const [day1, month1, year1] = dateEnd1.split('-')
                                    const formatDateStart = `${year}-${month}-${day}`
                                    const formatDateEnd = `${year1}-${month1}-${day1}`

                                    connectorDataSourcesUpdate.push({
                                        connectorId: item2.connectorId,
                                        connectorName: item2.connectorName,
                                        connectorType: item2.connectorType,
                                        selected: item2.selected,
                                        folderNames: item2.folderNames,
                                        startDates: formatDateStart,
                                        endDates: formatDateEnd,
                                        dateTime: [formatDateStart, formatDateEnd],
                                        visibleSelected: indexStart === 0 ? true : false,
                                        visibleConnectorsSelected: indexStart === 0 ? true : false,
                                    })
                                })
                            } else if (item2.siteAndFolderDataCollectorDTOList && item2.siteAndFolderDataCollectorDTOList.length > 0) {
                                item2.siteAndFolderDataCollectorDTOList.forEach((shareSite2, indexShare2) => {
                                    connectorDataSourcesUpdate.push({
                                        connectorId: item2.connectorId,
                                        connectorName: item2.connectorName,
                                        connectorType: item2.connectorType,
                                        selected: item2.selected,
                                        folderNames: item2.folderNames,
                                        startDates: item2.startDates,
                                        endDates: item2.endDates,
                                        dateTime: [],
                                        visibleSelected: indexShare2 === 0 ? true : false,
                                        visibleConnectorsSelected: indexShare2 === 0 ? true : false,
                                        siteIdSharePoint: shareSite2.siteId,
                                        siteNameSharePoint: shareSite2.siteName,
                                        folderNameSiteSharePoint: shareSite2.folderName,
                                    })
                                })
                            } else {
                                connectorDataSourcesUpdate.push({
                                    connectorId: item2.connectorId,
                                    connectorName: item2.connectorName,
                                    connectorType: item2.connectorType,
                                    selected: item2.selected,
                                    folderNames: item2.folderNames,
                                    startDates: item2.startDates,
                                    endDates: item2.endDates,
                                    dateTime: [],
                                    visibleSelected: true,
                                    visibleConnectorsSelected: true,
                                })
                            }
                            //}
                        })
                        item.connectorDataSources = connectorDataSourcesUpdate
                        item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2, index2) => {
                            indexing = indexing + 1
                            // item2.selected = false
                            item2.selected = item2.selected
                            item2.index = indexing
                            item2.key = indexing

                        })
                    })
                    response.data.businessProcessQueries && response.data.businessProcessQueries.length > 0 && response.data.businessProcessQueries.forEach((item, index) => {
                        item.selected = item.selected
                        item.key = index
                    })
                    if (status === 'get') {
                        this.setState({
                            valueShareBusiness : response.data.businessProcessActive === null ? false : response.data.businessProcessActive
                        })
                    }
                    if (status !== 'select') {
                        this.setState({
                            valueShareBusinessResponse : response.data.businessProcessActive === null ? false : response.data.businessProcessActive,
                        })
                    }
                    response.data.dataSources && response.data.dataSources.length > 0 && response.data.dataSources.forEach((item) => {
                        item && item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2) => {
                            if (item2.connectorId) {
                                this.setState({
                                    selectConnectorValue: item2.connectorId
                                })
                            }
                        })
                        item && item.otherDataSources && item.otherDataSources.length > 0 && item.otherDataSources.forEach((item3) => {
                            if (item3.dataLocation) {
                                this.setState({
                                    inputStorageLocationValue: item3.dataLocation
                                })
                            }
                        })
                    })
                    this.setState({
                        dataSources : response.data.dataSources,
                        businessProcessQueries : response.data.businessProcessQueries ? response.data.businessProcessQueries : [],
                        businessProcessId : response.data.businessProcessId,
                        //valueShareBusinessResponse : response.data.businessProcessActive,
                    }, () => {
                        // this.onValidateDataCollect(response.data.dataSources)
                        this.onGetSharePointSiteList(response.data.dataSources)
                        // this.onValidateBusinessProcessQueries()
                        let businessProcessName = null
                        listBusinessProcess && listBusinessProcess.length > 0 && listBusinessProcess.forEach((item) => {
                            if (response.data.businessProcessId === item.id) {
                                businessProcessName = item.businessProcessName
                            }
                        })
                        this.setState({
                            valueSelectBusinessProcess: businessProcessName
                        })
                        if (response.data && response.data.businessProcessActive === null) {
                            const dataSources2 = []
                            dataSources2.push({
                                connectorDataSources: [{
                                    connectorId: null,
                                    visibleConnectorsSelected: true,
                                    key: 1
                                }],
                                otherDataSources: [{
                                    dataLocation: null,
                                    dataLocationPath: null,
                                    dataLocationId: null,
                                    visibleSelected: true,
                                    key: 1
                                }]
                            })
                            this.setState({
                                dataSources: dataSources2
                            })
                        }
                    })
                    if(response.data.businessProcessName !== null){
                        this.setState({
                            businessProcessName : response.data.businessProcessName,
                        })
                    }

                }
                this.setState({
                    disabledOptionShareBusiness: false
                })
            })
        })
    }
    onGetSharePointSiteList = (dataSources) => {
        dataSources && dataSources.length > 0 && dataSources.forEach((item) => {
            if (item.connectorDataSources && item.connectorDataSources.length > 0) {
                item.connectorDataSources.forEach((item2) => {
                    if (item2.connectorType === 'Microsoft 365 SharePoint') {
                        const getSharePointSitesDTO = {
                            connectorId: item2.connectorId
                        }
                        apiGetSharePointSiteList(getSharePointSitesDTO).then((response2) => {
                            item2.dataSelectSiteListSharePoint = response2.data && response2.data.sharePointSiteDTOList ? response2.data.sharePointSiteDTOList : []
                            this.setState({
                                dataSources
                            })
                        })
                    }
                })
            }
        })
    }
    onAddRemoveInputsFolderName = (e, arg, form, index) => {
        const { dataSources } = this.state
        if(arg === 'INCREMENT') {
            dataSources && dataSources.length > 0 && dataSources.forEach((item, indexData) => {
                //if (item.connectorId === item.connectorId) {
                    let indexInput = 0
                    const itemSource = {
                        connectorId: form.connectorId,
                        connectorName: form.connectorName,
                        connectorType: form.connectorType,
                        folderNames: null,
                        visibleConnectorsSelected: false,
                        index: item.connectorDataSources.length + 1,
                        key: item.connectorDataSources.length + 1,

                    }
                    item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2, index2) => {
                        if (form.connectorName === item2.connectorName && item2.connectorType === 'Microsoft 365 Onedrive') {
                            indexInput = index2 + 1
                        }
                    })
                    item.connectorDataSources.splice(indexInput, 0, itemSource)
                    item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2, index2) => {
                        item2.index = index2 + 1
                        item2.key = index2 + 1
                    })
                //}
            })
            this.setState({
                dataSources
            })
        } else {
            dataSources && dataSources.length > 0 && dataSources.forEach((item, indexData) => {
                item.connectorDataSources = item.connectorDataSources.filter((sPermission, key) => key !== index)
            })
            this.setState({
                dataSources
            })
        }

    }

    onAddRemoveDateExchange = (e, arg, form, index) => {
        const { dataSources } = this.state

        if(arg === 'INCREMENT') {
            dataSources && dataSources.length > 0 && dataSources.forEach((item, indexData) => {
                let indexInput = 0
                const itemSource = {
                    connectorId: form.connectorId,
                    connectorName: form.connectorName,
                    connectorType: form.connectorType,
                    visibleConnectorsSelected: false,
                    index: item.connectorDataSources.length + 1,
                    key: item.connectorDataSources.length + 1,
                    dateTime: [],
                    startDates: null,
                    endDates: null,
                    selectEmailAccount: null,
                    selected: false,

                }
                item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2, index2) => {
                    if (form.connectorName === item2.connectorName && item2.connectorType === 'Microsoft 365 Exchange') {
                        indexInput = index2 + 1
                    }
                })
                item.connectorDataSources.splice(indexInput, 0, itemSource)
                item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2, index2) => {
                    item2.index = index2 + 1
                    item2.key = index2 + 1
                })
            })
            this.setState({
                dataSources
            })
        } else {
            dataSources && dataSources.length > 0 && dataSources.forEach((item, indexData) => {
                item.connectorDataSources = item.connectorDataSources.filter((sPermission, key) => key !== index)
            })
            this.setState({
                dataSources
            })
        }
    }

    onAddRemoveSharePoint = (e, arg, form, index) => {
        const { dataSources } = this.state

        if(arg === 'INCREMENT') {
            dataSources && dataSources.length > 0 && dataSources.forEach((item, indexData) => {
                let indexInput = 0
                const itemSource = {
                    connectorId: form.connectorId,
                    connectorName: form.connectorName,
                    connectorType: form.connectorType,
                    visibleConnectorsSelected: false,
                    index: item.connectorDataSources.length + 1,
                    key: item.connectorDataSources.length + 1,
                    dateTime: [],
                    startDates: null,
                    endDates: null,
                    selectEmailAccount: null,
                    selected: false,
                    dataSelectSiteListSharePoint: form.dataSelectSiteListSharePoint,
                    dataSelectSiteFolderListSharePoint: form.dataSelectSiteFolderListSharePoint,

                }
                item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2, index2) => {
                    if (form.connectorName === item2.connectorName && item2.connectorType === 'Microsoft 365 SharePoint') {
                        indexInput = index2 + 1
                    }
                })
                item.connectorDataSources.splice(indexInput, 0, itemSource)
                item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2, index2) => {
                    item2.index = index2 + 1
                    item2.key = index2 + 1
                })
            })
            this.setState({
                dataSources
            })
        } else {
            dataSources && dataSources.length > 0 && dataSources.forEach((item, indexData) => {
                item.connectorDataSources = item.connectorDataSources.filter((sPermission, key) => key !== index)
            })
            this.setState({
                dataSources
            })
        }
    }

    onInputFolderName = (e, form, index) => {
        const { dataSources } = this.state
        dataSources && dataSources.length > 0 && dataSources.forEach((item, indexData) => {
            item.connectorDataSources[index].folderNames = e.target.value
        })
        this.setState({
            dataSources
        })
    }
    onChangeCheckBoxInputsOnedrive = (e, form, index) => {
        const { dataSources } = this.state
        dataSources && dataSources.length > 0 && dataSources.forEach((item, indexData) => {
            item.connectorDataSources[index].selected = e.target.checked
        })
        this.setState({
            dataSources
        })
    }
    onChangeCheckBoxDateExchange = (e, form, index) => {
        const { dataSources } = this.state
        dataSources && dataSources.length > 0 && dataSources.forEach((item, indexData) => {
            item.connectorDataSources[index].selected = e.target.checked
        })
        this.setState({
            dataSources
        })
    }

    onChangeCheckBoxSharePoint = (e, form, index) => {
        const { dataSources } = this.state
        dataSources && dataSources.length > 0 && dataSources.forEach((item, indexData) => {
            item.connectorDataSources[index].selected = e.target.checked
        })
        this.setState({
            dataSources
        })
    }
    selectDate = (date, dateString, index, form) => {
        const { dataSources } = this.state
        dataSources && dataSources.length > 0 && dataSources.forEach((item, indexData) => {
            item.connectorDataSources[index].dateTime = dateString
            item.connectorDataSources[index].dateTime2 = date
            item.connectorDataSources[index].startDates = dateString[0]
            item.connectorDataSources[index].endDates = dateString[1]
        })
        this.setState({
            dataSources
        })
    }
    onChangeSelectEmailExchange = (e, form, index) => {
        const {dataSources } = this.state
        dataSources && dataSources.length > 0 && dataSources.forEach((item, indexData) => {
            item.connectorDataSources[index].selectEmailAccount = e
        })
        this.setState({
            dataSources
        })
    }

    onChangeCheckboxDetailsHr = (e, form1, sourceMappingName) => {
        e.stopPropagation()
        const { dataSources } = this.state
        if(e.target.checked) {
            dataSources && dataSources.length > 0 && dataSources.forEach((item) => {
                item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2) => {
                    if (form1.connectorId === item2.connectorId && sourceMappingName === item.sourceMappingName) {
                        item2.selected = e.target.checked
                    }
                })
            })
        } else {
            dataSources && dataSources.length > 0 && dataSources.forEach((item) => {
                item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2) => {
                    if (form1.connectorId === item2.connectorId && sourceMappingName === item.sourceMappingName) {
                        item2.selected = e.target.checked
                    }
                })
            })
        }
        this.setState({
            dataSources
        })
    }

    onInputContactDetails = (e, key, form1) => {
        const { dataSources } = this.state
        const settingsOnedrive = this.state.dataSources
        const { connectorDataSources } = settingsOnedrive
        dataSources && dataSources.length > 0 && dataSources.forEach((item) => {
            item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2) => {
                if ((form1.index && form1.index === item2.index && item2.connectorType === 'Microsoft 365 Onedrive')) {
                    item2.valueInputFolder = e.target.value
                    item2.folderNames = e.target.value
                }
            })
        })
        this.setState({
            dataSources
        })
    }

    onCLickAddRemoveContactDetailsHr = (e, status, index, form1, data) => {
        const { dataSources } = this.state
        if (status === 'add') {
            dataSources && dataSources.length > 0 && dataSources.forEach((item, index) => {
                if (item.sourceMappingId === data.sourceMappingId) {
                    let indexInput = 0
                    const itemSource = {
                        connectorId: form1.connectorId,
                        connectorName: form1.connectorName,
                        connectorType: form1.connectorType,
                        folderNames: null,
                        index: item.connectorDataSources.length + 1,
                        key: item.connectorDataSources.length + 1

                    }
                    item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2, index2) => {
                        if (form1.connectorName === item2.connectorName && item2.connectorType === 'Microsoft 365 Onedrive') {
                            indexInput = index2 + 1
                        }
                    })
                    item.connectorDataSources.splice(indexInput, 0, itemSource)
                    item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2, index2) => {
                        item2.index = index2 + 1
                        item2.key = index2 + 1
                    })
                }
            })

            this.setState({
                dataSources
            })
        } else {
            dataSources && dataSources.length > 0 && dataSources.forEach((item) => {
                if (item.sourceMappingId === data.sourceMappingId) {
                    item.connectorDataSources = item.connectorDataSources.filter((sPermission, key) => sPermission.index !== form1.index)
                }
                item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2, index2) => {
                    item2.key = index2 + 1
                    item2.index = index2 + 1
                })
            })
            this.setState({
                dataSources
            })
        }
    }
    onHoverAction = (field, val, sourceMappingIdVal) => {
        switch (field) {
            case 'add-folder-name':
                this.setState({
                    onHoverAddFolderName: val,
                    hoverIconPlusMsOneDrive: sourceMappingIdVal,
                })
                break;
            case 'remove-folder-name':
                this.setState({
                    onHoverRemoveFolderName: val,
                    hoverIconMinusMsOneDrive: sourceMappingIdVal,
                })
                break;
            case 'add-detail-email':
                this.setState({
                    onHoverAddDetailEmail: val,
                    hoverIconPlusMsExchange: sourceMappingIdVal,
                })
                break;
            case 'remove-detail-email':
                this.setState({
                    onHoverRemoveDetailEmail: val,
                    hoverIconMinusMsExchange: sourceMappingIdVal,
                })
                break;
            case 'add-bank-detail':
                this.setState({
                    onHoverAddBankDetail: true,
                })
                break;
            case 'remove-bank-detail':
                this.setState({
                    onRemoveBankDetail: val,
                })
                break;
            case 'add-search-queries':
                this.setState({
                    onHoverAddSearchQueries: true,
                })
                break;
            case 'remove-search-queries':
                this.setState({
                    onHoverRemoveSearchQueries: val,
                })

        }
    }
    onLeaveAction = () => {
        this.setState({
            onHoverAddFolderName: {},
            hoverIconPlusMsOneDrive:{},
            onHoverRemoveFolderName: {},
            hoverIconMinusMsOneDrive: {},
            onHoverAddDetailEmail: {},
            hoverIconPlusMsExchange: {},
            onHoverRemoveDetailEmail: {},
            hoverIconMinusMsExchange: {},
            onHoverAddBankDetail: false,
            onRemoveBankDetail: {},
            onHoverAddSearchQueries: false,
            onHoverRemoveSearchQueries: {},
        })
    }

    selectDateCollection = (date, dateString, index, form1) => {
        const { dataSources } = this.state
        dataSources && dataSources.length > 0 && dataSources.forEach((item) => {
            item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2) => {
                if ((form1.index && form1.index === item2.index && item2.connectorType === 'Microsoft 365 Exchange')) {
                    item2.dateTime = dateString
                    item2.dateTime2 = date
                    item2.startDates = date[0]
                    item2.endDates = date[1]
                }
            })
        })
        this.setState({
            dataSources
        })
    }
    onChangeSelectContactDetails = (e, key, form1) => {
        const { dataSources } = this.state
        dataSources && dataSources.length > 0 && dataSources.forEach((item) => {
            item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2) => {
                if (form1.connectorId === item2.connectorId && item2.connectorType === 'Microsoft 365 Exchange') {
                    item2.selectEmailAccount = e
                }
            })
        })
        this.setState({
            dataSources
        })
    }

    onCLickAddRemoveContactDetailsTenant = (e, status, index, form1, data) => {
        const { dataSources } = this.state
        if (status === 'add') {
            dataSources && dataSources.length > 0 && dataSources.forEach((item, index) => {
                if (item.sourceMappingId === data.sourceMappingId) {
                    let indexLocation = 0
                    const itemLocation = {
                        connectorId: form1.connectorId,
                        key: item.connectorDataSources.length + 1,
                        index: item.connectorDataSources.length + 1,
                        id: 'emailTenantAccess',
                        connectorName: form1.connectorName,
                        connectorType: form1.connectorType,
                        selected: false,
                        firstNameCheckbox: 'Entire Email tenant access',
                        lastNameCheckbox: '- Microsoft 365 Exchange',
                        //connectorType: 'Microsoft 365 Exchange',
                        selectEmailAccount: '',
                        dateTime: [],
                        folderNames: null

                    }
                    item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2, index2) => {
                        if (form1.connectorName === item2.connectorName && item2.connectorType === 'Microsoft 365 Exchange') {
                            indexLocation = index2 + 1
                        }
                    })
                    item.connectorDataSources.splice(indexLocation, 0, itemLocation)

                    item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2, index2) => {
                        item2.index = index2 + 1
                        item2.key = index2 + 1
                    })
                }
            })
            this.setState({
                dataSources
            })
        } else {
            dataSources && dataSources.length > 0 && dataSources.forEach((item) => {
                if (item.sourceMappingId === data.sourceMappingId) {
                    item.connectorDataSources = item.connectorDataSources.filter((sPermission, key) => sPermission.index !== form1.index)
                }
                item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2, index2) => {
                    item2.key = index2 + 1
                    item2.index = index2 + 1
                })
            })
            this.setState({
                dataSources
            })
        }
    }

    onChangeSelectSite2 = (e, key, form1) => {
        const { dataSources } = this.state
        const getSharePointSiteFolders = {
            connectorId: form1.connectorId,
            siteId: e
        }
        apiGetSharePointSiteFolderList(getSharePointSiteFolders).then((response) => {
            dataSources && dataSources.length > 0 && dataSources.forEach((item) => {
                item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2, index) => {
                    if (key === index && item2.connectorType === 'Microsoft 365 SharePoint') {
                        item2.siteIdSharePoint = e
                        item2.dataSelectSiteListSharePoint && item2.dataSelectSiteListSharePoint.length > 0 && item2.dataSelectSiteListSharePoint.forEach((elem) => {
                            if (elem.siteId === e) {
                                item2.siteNameSharePoint = elem.siteName
                                item2.folderNameSiteSharePoint = null
                                item2.dataSelectSiteFolderListSharePoint = response.data && response.data.sharePointSiteListItemDTOS ? response.data.sharePointSiteListItemDTOS : []
                            }
                        })
                    }
                })
            })
            this.setState({
                dataSources
            }, () => {
                //this.onValidateDataCollect(dataSources)
            })
        })
    }
    onChangeSelectFolder2 = (e, key, form1) => {
        const { dataSources } = this.state
        dataSources && dataSources.length > 0 && dataSources.forEach((item) => {
            item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2, index) => {
                if (key === index && item2.connectorType === 'Microsoft 365 SharePoint') {
                    item2.folderNameSiteSharePoint = e
                }
            })
        })
        this.setState({
            dataSources
        })
    }

    onCLickAddRemoveSharePoints = (e, status, index, form1, data) => {
        const { dataSources } = this.state
        if (status === 'add') {
            dataSources && dataSources.length > 0 && dataSources.forEach((item, index) => {
                if (item.sourceMappingId === data.sourceMappingId) {
                    let indexInput = 0
                    const itemSource = {
                        connectorId: form1.connectorId,
                        connectorName: form1.connectorName,
                        connectorType: form1.connectorType,
                        folderNames: null,
                        siteIdSharePoint: null,
                        siteNameSharePoint: null,
                        folderNameSiteSharePoint: null,
                        index: item.connectorDataSources.length + 1,
                        key: item.connectorDataSources.length + 1,
                        dataSelectSiteListSharePoint: form1.dataSelectSiteListSharePoint,
                        dataSelectSiteFolderListSharePoint: form1.dataSelectSiteFolderListSharePoint,
                        selected: form1.selected,

                    }
                    item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2, index2) => {
                        if (form1.connectorName === item2.connectorName && item2.connectorType === 'Microsoft 365 SharePoint') {
                            indexInput = index2 + 1
                        }
                    })
                    item.connectorDataSources.splice(indexInput, 0, itemSource)
                    item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2, index2) => {
                        item2.index = index2 + 1
                        item2.key = index2 + 1
                    })
                }
            })

            this.setState({
                dataSources
            })
        } else {
            dataSources && dataSources.length > 0 && dataSources.forEach((item) => {
                if (item.sourceMappingId === data.sourceMappingId) {
                    item.connectorDataSources = item.connectorDataSources.filter((sPermission, key) => sPermission.index !== form1.index)
                }
                item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2, index2) => {
                    item2.key = index2 + 1
                    item2.index = index2 + 1
                })
            })
            this.setState({
                dataSources
            })
        }
    }
    callback = (key) => {
        console.log(key);
    }
    onCLickAddRemoveSearchQueries = (e, status, index, form1) => {
        let { businessProcessQueries } = this.state
        if (status === 'add') {
            businessProcessQueries.push({
                key: businessProcessQueries.length,
                id: null,
                selected: false,
                query: 'Subject',
                nameCheckbox: 'Subject',
                value: '',
                valueInputFolder: ''})
            this.setState({
                businessProcessQueries
            })
        } else {
            businessProcessQueries = businessProcessQueries.filter((sPermission, key) => sPermission.key !== form1.key)
            businessProcessQueries && businessProcessQueries.length > 0 && businessProcessQueries.forEach((item, index) => {
                item.key = index
            })
            this.setState({
                businessProcessQueries
            })
        }
    }
    onChangeCheckboxSearchQueries = (e, form1, index) => {
        const { businessProcessQueries } = this.state
        e.stopPropagation()
        if(e.target.checked) {
            businessProcessQueries && businessProcessQueries.length > 0 && businessProcessQueries.forEach((item) => {
                if (index === item.key) {
                    item.selected = e.target.checked
                }
            })
        } else {
            businessProcessQueries && businessProcessQueries.length > 0 && businessProcessQueries.forEach((item) => {
                if (index === item.key) {
                    item.selected = e.target.checked
                }
            })
        }

        businessProcessQueries && businessProcessQueries.length > 0 && businessProcessQueries.forEach((item, index) => {
            item.key = index
        })
        this.setState({
            businessProcessQueries
        }, () => {

        })
    }

    onInputSearchQueries = (e, key, form1) => {
        const { businessProcessQueries } = this.state
        businessProcessQueries && businessProcessQueries.length > 0 && businessProcessQueries.forEach((item) => {
            if ((form1.id !== null && form1.id === item.id) || (form1.key !== null && form1.key === item.key)) {
                item.value = e.target.value
                item.valueInputQueries = e.target.value
            }
        })

        this.setState({
            businessProcessQueries
        }, () => {
            //this.onValidateBusinessProcessQueries()
        })
    }

    onSaveCollect = () => {
        const {businessProcessId, dataSources, businessProcessQueries, valueShareBusiness, fileSelectUpload, valueInputDesktop} = this.state
        const saveDataCollectorDataSourceOptions = []
        const saveDataCollectorOptionsQueries = []

        dataSources && dataSources.length > 0 && dataSources.forEach((item) => {
            const connectorOptions = []
            const dataLocationOptionDTOS = []
            item.connectorDataSources && item.connectorDataSources.length > 0 && item.connectorDataSources.forEach((item2) => {
                if (item2.connectorId) {
                    connectorOptions.push({
                        connectorId: item2.connectorId ? item2.connectorId : null,
                        selected: item2.selected,
                        folderName: item2.folderNames ? item2.folderNames : null,
                        startDate: item2.dateTime && item2.dateTime.length > 0 ? item2.dateTime[0] : null,
                        endDate: item2.dateTime && item2.dateTime.length > 0 ? item2.dateTime[1] : null,
                        siteIdSharePoint: item2.siteIdSharePoint ? item2.siteIdSharePoint : null,
                        siteNameSharePoint: item2.siteNameSharePoint ? item2.siteNameSharePoint : null,
                        folderNameSiteSharePoint: item2.folderNameSiteSharePoint ? item2.folderNameSiteSharePoint : null,
                    })
                }
            })
            item.connectorOptions = connectorOptions
            item.otherDataSources && item.otherDataSources.length > 0 && item.otherDataSources.forEach((item2) => {
                dataLocationOptionDTOS.push({
                    dataLocationId: item2.dataLocationId ? item2.dataLocationId : null,
                    dataLocationPath: item2.valueInputDesktop ? item2.valueInputDesktop : item2.dataLocationPath ? item2.dataLocationPath : null,
                    dataLocation: item2.dataLocation ? item2.dataLocation : null,
                })
            })
            item.dataLocationOptionDTOS = dataLocationOptionDTOS
        })
        dataSources && dataSources.length > 0 && dataSources.forEach((item) => {
            saveDataCollectorDataSourceOptions.push({
                sourceMappingId: item.sourceMappingId,
                connectorOptions: item.connectorOptions,
                dataLocationOptionDTOS: item.dataLocationOptionDTOS,
            })
        })

        businessProcessQueries && businessProcessQueries.length > 0 && businessProcessQueries.forEach((item) => {
            saveDataCollectorOptionsQueries.push({
                idQuery: item.id ? item.id : null,
                queryName: item.query,
                selected: item.selected,
                queryValue: item.value ? item.value : null,
            })
        })
        let saveDataCollectorOptionsWorkflowTicketDTO = {}
        if (valueShareBusiness) {
            saveDataCollectorOptionsWorkflowTicketDTO = {
                businessProcessId: businessProcessId,
                businessProcessActive: valueShareBusiness,
                boxId: this.props.params.id,
                saveDataCollectorDataSourceOptions: saveDataCollectorDataSourceOptions,
                saveDataCollectorOptionsQueries: saveDataCollectorOptionsQueries,
            }
        } else {
            saveDataCollectorOptionsWorkflowTicketDTO = {
                businessProcessActive: valueShareBusiness,
                boxId: this.props.params.id,
                saveDataCollectorDataSourceOptions: saveDataCollectorDataSourceOptions,
                saveDataCollectorOptionsQueries: saveDataCollectorOptionsQueries,
            }
        }

        apiCollectorPageSaveCollectorPageData(saveDataCollectorOptionsWorkflowTicketDTO).then((response) => {
            if (response.data.title && response.data.title.toLowerCase() === 'success') {
                CustomNotificationManager.success(response.data.message, 'Success')
                if (fileSelectUpload) {
                    this.onSendUploadFile(response.data.uploadHistoryId)
                }
            } else if (response.data.title && response.data.title.toLowerCase() === 'info') {
                CustomNotificationManager.info(response.data.message, 'Info')
            }
            this.onCollectorPageGetCollectorPageUploadHistoryData()
        })
    }

    onInputStorageLocation = (e, form, indexConnect) => {
        const { dataSources } = this.state
        dataSources && dataSources.length > 0 && dataSources.forEach((item, indexData) => {
            item.otherDataSources[indexConnect].dataLocation = e.target.value
        })
        this.setState({
            dataSources,
            inputStorageLocationValue: e.target.value
        })
    }
    onAddRemoveStorageLocation= (e, arg, form, index) => {
        const { dataSources } = this.state
        if(arg === 'INCREMENT') {
            dataSources && dataSources.length > 0 && dataSources.forEach((item, indexData) => {
                let indexInput = 0
                const itemSource = {
                    dataLocation: null,
                    dataLocationId: null,
                    dataLocationPath: null,
                    visibleSelected: false,
                    index: item.connectorDataSources.length + 1,
                    key: item.connectorDataSources.length + 1,

                }
                item.otherDataSources && item.otherDataSources.length > 0 && item.otherDataSources.forEach((item2, index2) => {
                    if (form.dataLocation === item2.dataLocation) {
                        //indexInput = index2 + 1
                    }
                })
                indexInput = item.otherDataSources.length + 1
                item.otherDataSources.splice(indexInput, 0, itemSource)
                item.otherDataSources && item.otherDataSources.length > 0 && item.otherDataSources.forEach((item2, index2) => {
                    item2.index = index2 + 1
                    item2.key = index2 + 1
                })
            })
            this.setState({
                dataSources
            })
        } else {
            dataSources && dataSources.length > 0 && dataSources.forEach((item, indexData) => {
                item.otherDataSources = item.otherDataSources.filter((sPermission, key) => key !== index)
            })
            this.setState({
                dataSources
            })
        }
    }

    onInputDesktop = (e) => {
        const { dataSources, formOtherDataSourcesSelect } = this.state
        dataSources && dataSources.length > 0 && dataSources.forEach((elem) => {
            elem.otherDataSources && elem.otherDataSources.length > 0 && elem.otherDataSources.map((form2, index) => {
                if (formOtherDataSourcesSelect.dataLocation === form2.dataLocation) {
                    form2.valueInputDesktop = e.target.value
                }
            })
        })
       this.setState({
           valueInputDesktop: e.target.value
       })
    }
    onImportModal = () => {

    }
    onCancelModalVisible = () => {
       this.setState({
           visibleDataUploadFrom: false
       })
    }
    onOpenModalDataUploadDisabled = () => {
        return true
    }
    onOpenModalDataUploadFrom = (e, form2, indexOther, data) => {
        form2.indexOther = indexOther
        this.setState({
            visibleDataUploadFrom: !this.state.visibleDataUploadFrom,
            formOtherDataSourcesSelect: form2,
            dataSourcesSelect: form2,
        })
    }
    customUploadDraft = (file, file2) => {
        const { dataSources, formOtherDataSourcesSelect, valueShareBusiness } = this.state
        const maxSizeFile = file.size / 1024 / 1024 < 5
        let nameFile = ''
        const listUid = []
        const listUid2 = []
        file2 && file2.length > 0 && file2.forEach((list) => {
            listUid.push(list.uid)
            nameFile = list.name
            listUid2.push({
                uid: list.uid,
                name: list.name,
                lastModified: list.lastModified,
                type: list.type,
                size: list.size,
            })
            dataSources && dataSources.length > 0 && dataSources.forEach((elem) => {
                elem.otherDataSources && elem.otherDataSources.length > 0 && elem.otherDataSources.map((form2, index) => {
                    if (formOtherDataSourcesSelect.dataLocation === form2.dataLocation || formOtherDataSourcesSelect.indexOther === index) {
                        form2.nameUpload = list.name
                    }
                })
            })
        })
        dataSources && dataSources.length > 0 && dataSources.forEach((elem) => {
            elem.otherDataSources && elem.otherDataSources.length > 0 && elem.otherDataSources.map((form2, index) => {
                if (formOtherDataSourcesSelect.dataLocation === form2.dataLocation || formOtherDataSourcesSelect.indexOther === index) {
                    form2.fileSelectUpload = file,
                    form2.dataSourceName = elem.sourceMappingName
                }
            })
        })
        this.setState({
            fileList: listUid2,
            fileSelectUpload: file,
            nameFileUpload: nameFile,
            dataSources
        }, () => {
            this.setState({
                visibleDataUploadFrom: false
            })
        })
        return false
    }
    onSendUploadFile = (uploadHistoryId) => {
        const { fileSelectUpload, folder, valueShareBusiness, dataSources } = this.state

        if (valueShareBusiness) {
            dataSources && dataSources.length > 0 && dataSources.forEach((elem) => {
                elem.otherDataSources && elem.otherDataSources.length > 0 && elem.otherDataSources.map((form2, index) => {
                    if (form2.fileSelectUpload) {
                        let result = '';
                        const characters = '0123456789';
                        const charactersLength = characters.length;
                        let counter = 0;
                        while (counter < 3) {
                            result += characters.charAt(Math.floor(Math.random() * charactersLength));
                            counter += 1;
                        }

                        let nameBox = ''
                        if (folder && typeof folder.relativePath !== 'undefined') {
                            nameBox = folder.relativePath.split('/')[1]
                        }

                        let objData = {}
                        objData = {
                            uploadId: result,
                            path: nameBox,
                            files: form2.fileSelectUpload,
                            dataLocationPath: form2.valueInputDesktop,
                            dataSourceName: form2.dataSourceName ? form2.dataSourceName : 'data sources',
                            fileInfoList: null,
                            uploadHistoryId: uploadHistoryId ? uploadHistoryId : null,
                        }

                        const boundary = '----WebKitFormBoundary7MA4YWxkTrZu0gW';
                        const body = `--${boundary}\r\nContent-Disposition: form-data; name="key1"\r\n\r\nvalue1\r\n--${boundary}\r\nContent-Disposition: form-data; name="key2"\r\n\r\nvalue2\r\n--${boundary}--`;
                        return this.axiosClone({
                                method: 'post',
                                url: `${host}/osprey-0.1.0/v1/collectorPage/upload-collector-page-data`,
                                data: objData,
                                body: body,
                                headers: {
                                    'Content-Type': `multipart/form-data; boundary=${boundary}`
                                },
                                //onUploadProgress: config
                            }
                        ).catch((error) => {
                            if (error?.response?.status === 504) {
                                CustomNotificationManager.cleanBuffer('warning', '')
                            } else {
                                console.log(error)
                            }
                        })
                    }
                })
            })
        } else {
            dataSources && dataSources.length > 0 && dataSources.forEach((elem) => {
                elem.otherDataSources && elem.otherDataSources.length > 0 && elem.otherDataSources.map((form2, index) => {
                    if (form2.fileSelectUpload) {
                        let result = '';
                        const characters = '0123456789';
                        const charactersLength = characters.length;
                        let counter = 0;
                        while (counter < 3) {
                            result += characters.charAt(Math.floor(Math.random() * charactersLength));
                            counter += 1;
                        }

                        let nameBox = ''
                        if (folder && typeof folder.relativePath !== 'undefined') {
                            nameBox = folder.relativePath.split('/')[1]
                        }

                        let objData = {}
                        objData = {
                            uploadId: result,
                            path: nameBox,
                            files: form2.fileSelectUpload,
                            dataLocationPath: form2.valueInputDesktop,
                            dataSourceName: 'data sources',
                            fileInfoList: null,
                            uploadHistoryId: uploadHistoryId ? uploadHistoryId : null,
                        }

                        const boundary = '----WebKitFormBoundary7MA4YWxkTrZu0gW';
                        const body = `--${boundary}\r\nContent-Disposition: form-data; name="key1"\r\n\r\nvalue1\r\n--${boundary}\r\nContent-Disposition: form-data; name="key2"\r\n\r\nvalue2\r\n--${boundary}--`;
                        return this.axiosClone({
                                method: 'post',
                                url: `${host}/osprey-0.1.0/v1/collectorPage/upload-collector-page-data`,
                                data: objData,
                                body: body,
                                headers: {
                                    'Content-Type': `multipart/form-data; boundary=${boundary}`
                                },
                                //onUploadProgress: config
                            }
                        ).catch((error) => {
                            if (error?.response?.status === 504) {
                                CustomNotificationManager.cleanBuffer('warning', '')
                            } else {
                                console.log(error)
                            }
                        })
                    }
                })
            })
        }

    }
    render() {
        const { folder, valueShareBusiness, hoverAddConnector, listBusinessProcess, valueSelectBusinessProcess, dataSources,
            hoverIconPlusMsExchange, hoverIconPlusMsOneDrive, hoverIconMinusMsExchange, hoverIconMinusMsOneDrive, onHoverAddFolderName, onHoverRemoveFolderName, onHoverAddDetailEmail, onHoverRemoveDetailEmail,
            onHoverAddBankDetail, onRemoveBankDetail, onHoverAddSearchQueries, onHoverRemoveSearchQueries, businessProcessQueries, disabledActionCollect, disabledActionCollectDate,
            disabledBusinessProcessQueries, businessProcessId, dataUploadHistory, visibleDataUploadFrom, fileList, nameFileUpload, fileSelectUpload, valueInputDesktop,
            formOtherDataSourcesSelect, disabledOptionShareBusiness, selectConnectorValue, inputStorageLocationValue } = this.state
        let { dataConnectorsList } = this.state
        const { RangePicker } = DatePicker;
        if (!folder) {
            const path = '/smart-box'
            return <Navigate to={ path } />
        }
        // console.log('dataSources ------ Render', dataSources)
        // console.log('fileSelectUpload ------ Render', fileSelectUpload)
        // console.log('formOtherDataSourcesSelect ------ Render', formOtherDataSourcesSelect)
         //console.log('businessProcessQueries ------ Render', businessProcessQueries)
         //console.log('valueShareBusiness ------ Render', valueShareBusiness)

        return (
                <div className="smart-box-folder-data-collector">
                    <div className="smart-box-folder-data-collector_header mt-5">
                        <HeaderNavigationBox
                            folderParent={ folder }
                            clientId={ this.props.clientId }
                            basePath={ folder.name }
                            boxIdBasePath={ folder.id }
                            activeItem = { 'data-collector' }
                        />
                    </div>
                    <div className="smart-box-folder-data-collector_content">
                        <div className="page-data-collector">
                            <div className="header-data-collector">
                                <span className="title-data-collector">Data Collector</span>
                            </div>

                            <div className="content-data-collector">
                                <div className="data-collector-contain">
                                   <div className={valueShareBusiness ? "contain-data-business contain-data-business-active" : 'contain-data-business' }>
                                       <div className="data-business-option">
                                           <span className={valueShareBusiness ? "icon-v23-company icon-v23-company-active" : 'icon-v23-company' }></span>
                                           <Select  placeholder="Select business process" className={ valueShareBusiness ? "select-business" : 'select-business-disabled' }
                                                    value={ valueSelectBusinessProcess ? valueSelectBusinessProcess : 'Select business process' }
                                                    disabled={valueShareBusiness === false}
                                           onSelect={(e) => this.onSelectBusinessProcess(e)}>
                                               {
                                                   listBusinessProcess && listBusinessProcess.length > 0 && listBusinessProcess.map((business, index) => {
                                                       return (
                                                           <Option key={index} value={business.businessProcessName}>{business.businessProcessName}</Option>
                                                       )
                                                   })
                                               }
                                           </Select>
                                       </div>
                                       <div className={ disabledOptionShareBusiness ? "switcher-select-option-business switcher-select-option-business-disabled " : "switcher-select-option-business" }>
                                           <Switcher
                                               active={ valueShareBusiness }
                                               updateActive={ disabledOptionShareBusiness ? () => this.handleChangeActiveShareBusinessDisabled() : () =>
                                                   this.handleChangeActiveShareBusiness(!valueShareBusiness)
                                               }
                                           />
                                       </div>
                                   </div>

                                    { valueShareBusiness ?
                                        <React.Fragment>
                                        <div className="date-source">
                                            <span className="icon-v22-db-storage"></span>
                                            <div className="content-date-source">
                                                <div className="date-source-header">
                                                    <span className="name-data-source">Data source</span>
                                                </div>

                                                <div>
                                                    { dataSources && dataSources.length > 0 && dataSources.map((data, index2) => {
                                                        return (
                                                            <div className="contact-details" key={index2}>
                                                                <span className="contact-details-name">{data.sourceMappingName}</span>
                                                                { data.connectorDataSources && data.connectorDataSources.length > 0 && data.connectorDataSources.map((form1, index) => {

                                                                    return (
                                                                        <div className="contact-details-contain" key={`key-${index}`}>
                                                                            {form1.visibleSelected ?
                                                                                <div className="checkbox-contain">
                                                                                    <Checkbox checked={form1.selected}
                                                                                              onChange={(e) => this.onChangeCheckboxDetailsHr(e, form1, data.sourceMappingName)}>{
                                                                                        <span className="value-checkbox"><span
                                                                                            className="first-value-checkbox">{form1.connectorName}</span><span
                                                                                            className="last-value-checkbox">{` - ${form1.connectorType}`}</span></span>}</Checkbox>
                                                                                </div> :
                                                                                <div className="checkbox-contain">
                                                                                </div>
                                                                            }
                                                                            { form1.connectorType && form1.connectorType === 'Microsoft 365 Onedrive' &&
                                                                            <div className="option-contact-details">
                                                                                <Input value={ form1.folderNames } onChange={(e) => this.onInputContactDetails(e, index, form1)} className="input-folder-name" placeholder={'Folder name'}></Input>
                                                                                { form1.visibleSelected &&
                                                                                <span
                                                                                    onClick={(e) => this.onCLickAddRemoveContactDetailsHr(e, 'add', index, form1, data)}
                                                                                    className={onHoverAddFolderName === index && hoverIconPlusMsOneDrive === data.sourceMappingId  ? 'icon-v22-add-active' : "icon-v22-add"}
                                                                                    onMouseEnter={() => this.onHoverAction('add-folder-name', index, data.sourceMappingId)}
                                                                                    onMouseLeave={() => this.onLeaveAction()}
                                                                                ></span>
                                                                                }
                                                                                { !form1.visibleSelected &&
                                                                                <span
                                                                                    onClick={(e) => this.onCLickAddRemoveContactDetailsHr(e, 'remove', index, form1, data)}
                                                                                    className={ onHoverRemoveFolderName === index && hoverIconMinusMsOneDrive === data.sourceMappingId ? "icon-v22-minus-active" :"icon-v22-minus"}
                                                                                    onMouseEnter={() => this.onHoverAction('remove-folder-name', index, data.sourceMappingId)}
                                                                                    onMouseLeave={() => this.onLeaveAction()}></span>
                                                                                }
                                                                            </div>
                                                                            }

                                                                            { form1.connectorType && form1.connectorType === 'Microsoft 365 Exchange' &&
                                                                            <div className="option-contact-details">
                                                                                <RangePicker onChange={(date,dateString) => this.selectDateCollection(date,dateString, index, form1)}
                                                                                             className='date-picker'
                                                                                             format="YYYY-MM-DD"
                                                                                             value={form1.startDates && form1.endDates ? [moment(form1.startDates), moment(form1.endDates)] : []}
                                                                                             dropdownClassName="date-picker-up"/>
                                                                                <Select onChange={(e) => this.onChangeSelectContactDetails(e, index, form1)}
                                                                                        placeholder="select-details-email"
                                                                                        value={form1.selectEmailAccount}
                                                                                        dropdownClassName={'dropdown-select-details-email'}
                                                                                        className="select-details-email">
                                                                                    <Option value="All Email accounts">{'All email accounts'}</Option>
                                                                                </Select>
                                                                                { form1.visibleSelected &&
                                                                                <span
                                                                                    onClick={(e) => this.onCLickAddRemoveContactDetailsTenant(e, 'add', index, form1, data)}
                                                                                    className={ onHoverAddDetailEmail === index && hoverIconPlusMsExchange === data.sourceMappingId ? "icon-v22-add-active" : "icon-v22-add"}
                                                                                    onMouseEnter={() => this.onHoverAction('add-detail-email', index, data.sourceMappingId)}
                                                                                    onMouseLeave={() => this.onLeaveAction()}
                                                                                ></span>
                                                                                }
                                                                                { !form1.visibleSelected &&
                                                                                <span
                                                                                    onClick={(e) => this.onCLickAddRemoveContactDetailsTenant(e, 'remove', index, form1, data)}
                                                                                    className={ onHoverRemoveDetailEmail === index && hoverIconMinusMsExchange === data.sourceMappingId ? 'icon-v22-minus-active' :"icon-v22-minus"}
                                                                                    onMouseEnter={() => this.onHoverAction('remove-detail-email', index, data.sourceMappingId)}
                                                                                    onMouseLeave={() => this.onLeaveAction()}
                                                                                ></span>
                                                                                }
                                                                            </div>
                                                                            }

                                                                            { form1.connectorType && form1.connectorType === 'Microsoft 365 SharePoint' &&
                                                                            <div className="option-contact-details">
                                                                                <Select onChange={(e) => this.onChangeSelectSite2(e, index, form1)}
                                                                                        placeholder="Site"
                                                                                        value={form1.siteNameSharePoint ? form1.siteNameSharePoint : 'Select SharePoint Site'}
                                                                                        dropdownClassName={'dropdown-select-details-email'}
                                                                                        className="select-details-email">
                                                                                    { form1.dataSelectSiteListSharePoint && form1.dataSelectSiteListSharePoint.length > 0 && form1.dataSelectSiteListSharePoint.map((site) => {
                                                                                        return (
                                                                                            <Option key={site.siteId} value={site.siteId}>{site.siteName}</Option>
                                                                                        )
                                                                                    })
                                                                                    }
                                                                                </Select>
                                                                                <Select onChange={(e) => this.onChangeSelectFolder2(e, index, form1)}
                                                                                        placeholder="Folder"
                                                                                        value={form1.folderNameSiteSharePoint ? form1.folderNameSiteSharePoint : 'Select Folder'}
                                                                                        dropdownClassName={'dropdown-select-details-email'}
                                                                                        className="select-details-email">
                                                                                    { form1.dataSelectSiteFolderListSharePoint && form1.dataSelectSiteFolderListSharePoint.length > 0 && form1.dataSelectSiteFolderListSharePoint.map((folder) => {
                                                                                        return (
                                                                                            <Option key={folder.folderId} value={folder.folderName}>{folder.folderName}</Option>
                                                                                        )
                                                                                    })
                                                                                    }
                                                                                </Select>
                                                                                { form1.visibleSelected &&
                                                                                <span
                                                                                    onClick={(e) => this.onCLickAddRemoveSharePoints(e, 'add', index, form1, data)}
                                                                                    className={onHoverAddFolderName === index && hoverIconPlusMsOneDrive === data.sourceMappingId  ? 'icon-v22-add-active' : "icon-v22-add"}
                                                                                    onMouseEnter={() => this.onHoverAction('add-folder-name', index, data.sourceMappingId)}
                                                                                    onMouseLeave={() => this.onLeaveAction()}
                                                                                ></span>
                                                                                }
                                                                                { !form1.visibleSelected &&
                                                                                <span
                                                                                    onClick={(e) => this.onCLickAddRemoveSharePoints(e, 'remove', index, form1, data)}
                                                                                    className={ onHoverRemoveFolderName === index && hoverIconMinusMsOneDrive === data.sourceMappingId ? "icon-v22-minus-active" :"icon-v22-minus"}
                                                                                    onMouseEnter={() => this.onHoverAction('remove-folder-name', index, data.sourceMappingId)}
                                                                                    onMouseLeave={() => this.onLeaveAction()}></span>
                                                                                }
                                                                            </div>
                                                                            }

                                                                        </div>

                                                                    )

                                                                })
                                                                }

                                                                {
                                                                    data.otherDataSources && data.otherDataSources.length > 0 && data.otherDataSources.map((form2, index) => {

                                                                        return (
                                                                            <div className="finance-payroll-system" key={`key-${index}`}>
                                                                                <span className="name-finance-payroll">{ form2.dataLocation }</span>
                                                                                <span onClick={(e) => this.onOpenModalDataUploadFrom(e, form2, index, data)} className="icon-v22-upload-1"></span>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }

                                                            </div>
                                                        )
                                                      })
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                            <div className="date-source">
                                                <span className="icon-v22-search-b"></span>
                                                <div className="content-date-source">
                                                    <div className="date-source-header">
                                                        <span className="name-data-source">Search queries</span>
                                                        <span  onClick={(e) => this.onCLickAddRemoveSearchQueries(e, 'add')}
                                                               className={ onHoverAddSearchQueries ? "icon-v22-add-active" : "icon-v22-add"}
                                                               onMouseEnter={() => this.onHoverAction('add-search-queries')}
                                                               onMouseLeave={() => this.onLeaveAction()}
                                                        ></span>
                                                    </div>

                                                    { businessProcessQueries && businessProcessQueries.length > 0 &&
                                                    <div className="contact-details">
                                                        { businessProcessQueries && businessProcessQueries.length > 0 && businessProcessQueries.map((form1, index) => {
                                                            return (
                                                                <div className="contact-details-contain" key={`key-${index}`}>
                                                                    <div className="checkbox-contain">
                                                                        <Checkbox checked={form1.selected} onChange={(e) => this.onChangeCheckboxSearchQueries(e, form1, index)} >{ <span className="value-checkbox"><span className="first-value-checkbox">{form1.query}</span></span> }</Checkbox>
                                                                    </div>
                                                                    <div className="option-contact-details">
                                                                        <Input value={ form1.value } onChange={(e) => this.onInputSearchQueries(e, index, form1)}
                                                                               className={ !form1.id ? "input-folder-name input-folder-name-remove" : 'input-folder-name'}></Input>
                                                                        { !form1.id &&
                                                                        <span
                                                                            onClick={(e) => this.onCLickAddRemoveSearchQueries(e, 'remove', index, form1)}
                                                                            className={ onHoverRemoveSearchQueries === index ? "icon-v22-minus-active" : "icon-v22-minus"}
                                                                            onMouseEnter={() => this.onHoverAction('remove-search-queries', index)}
                                                                            onMouseLeave={() => this.onLeaveAction()}
                                                                        ></span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                    }

                                                </div>
                                            </div>

                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <div className="contain-manual-connectors">
                                                <span className="icon-v23-upload-1"></span>
                                                <div className="manual-connectors">
                                                    <div className="header-connectors">
                                                        <span className="title-connectors">Manual upload</span>
                                                    </div>

                                                    <div className="contain-manual-upload">
                                                        {/*<span className="icon-v22-db-storage"></span>*/}
                                                        { dataSources && dataSources.length > 0 && dataSources.map((data, index) => {
                                                            return (
                                                                <div className="manual-upload-describe">
                                                                    {/*<div className="header-manual-upload">*/}
                                                                    {/*    <span className="title-manual-upload">Manual Upload</span>*/}
                                                                    {/*    <span className='icon-v23-add'></span>*/}
                                                                    {/*</div>*/}
                                                                    { data.otherDataSources && data.otherDataSources.length > 0 && data.otherDataSources.map((other, indexOther) => {
                                                                        return (
                                                                            <div className="manual-upload-content">
                                                                                {/*<Upload>*/}
                                                                                {/*    <Button>*/}
                                                                                {/*        <Icon type="upload"/>*/}
                                                                                {/*    </Button>*/}
                                                                                {/*</Upload>*/}
                                                                                <span onClick={other.dataLocation ? (e) => this.onOpenModalDataUploadFrom(e, other, indexOther) :
                                                                                    (e) => this.onOpenModalDataUploadDisabled(e)}
                                                                                      className={ other.dataLocation ? "icon-v22-upload-1" : 'icon-v22-upload-1 icon-v22-upload-1-disabled' }></span>
                                                                                <Input placeholder="Describe storage you upload data from"
                                                                                       value={other.dataLocation}
                                                                                       onChange={(e) => this.onInputStorageLocation(e, other, indexOther)}
                                                                                       className="describe-input"/>
                                                                                {/*{other.visibleSelected ?*/}
                                                                                {/*    <span className="icon-v23-add"*/}
                                                                                {/*          onClick={(e) => this.onAddRemoveStorageLocation(e, 'INCREMENT', other, indexOther)}></span> :*/}
                                                                                {/*    <span className="icon-v23-minus"*/}
                                                                                {/*          onClick={(e) => this.onAddRemoveStorageLocation(e, 'DECREMENT', other, indexOther)}></span>*/}
                                                                                {/*}*/}
                                                                            </div>
                                                                        )
                                                                    })
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="contain-manual-connectors">
                                                <span className="icon-v23-connector"></span>
                                                <div className="manual-connectors">
                                                    <div className="header-connectors">
                                                        <span className="title-connectors">Connectors</span>
                                                    </div>
                                                    {dataSources && dataSources.length > 0 && dataSources.map((data, index) => {
                                                        return (
                                                            <div className="connectors-content">
                                                                { data.connectorDataSources && data.connectorDataSources.length > 0 && data.connectorDataSources.map((form, indexConnect) => {

                                                                    return (
                                                                        <React.Fragment>
                                                                            { form.visibleConnectorsSelected &&
                                                                                <div className="select-connectors-contain">
                                                                                    <Select placeholder="Connectors"
                                                                                            className="select-connectors"
                                                                                            value={form.connectorType}
                                                                                            onChange={(e) => this.onChangeSelectConnector(e, 'valueConnectorSelect', index, indexConnect)}>
                                                                                        {dataConnectorsList && dataConnectorsList.length > 0 && dataConnectorsList.map((connect, index2) => {
                                                                                            return (<Option
                                                                                                className="options-select-connector"
                                                                                                value={connect.connectorId}
                                                                                                key={index2}
                                                                                                disabled={connect.active === false}
                                                                                            >
                                                                                                {`${connect.connectorProvider} - ${connect.connectorName}`}
                                                                                            </Option>)
                                                                                        })}
                                                                                    </Select>
                                                                                    {/*{ indexConnect === 0 &&*/}
                                                                                    {/*<span*/}
                                                                                    {/*    className={hoverAddConnector ? 'icon-v23-add-active' : 'icon-v23-add'}*/}
                                                                                    {/*    onMouseEnter={(e) => this.onHoverDataCollector('addConnector', null)}*/}
                                                                                    {/*    onMouseLeave={(e) => this.onLeaverDataCollector()}*/}
                                                                                    {/*    onClick={(e) => this.addNewSelectConnector(e, 'INCREMENT')}></span>*/}
                                                                                    {/*}*/}
                                                                                    {/*{indexConnect !== 0 && data.connectorDataSources.length > 1 &&*/}
                                                                                    {/*<button*/}
                                                                                    {/*    onClick={(e) => this.addNewSelectConnector(e, 'DECREMENT', index, indexConnect)}*/}
                                                                                    {/*    className={'icon-v23-minus'}*/}
                                                                                    {/*/>*/}
                                                                                    {/*}*/}
                                                                                </div>
                                                                            }
                                                                            { form.connectorType && form.connectorType === 'Microsoft 365 Onedrive' &&
                                                                            <div className="connectors-content-details">
                                                                                {/*{form.listInputsOnedrive && form.listInputsOnedrive.length > 0 && form.listInputsOnedrive.map((onedrive, index3) => {*/}
                                                                                {/*    return (*/}
                                                                                        <div className="content-details-options">
                                                                                            <Checkbox
                                                                                                checked={form.selected}
                                                                                                onChange={(e) => this.onChangeCheckBoxInputsOnedrive(e, form, indexConnect)}></Checkbox>
                                                                                            <Input className="input-folder-name"
                                                                                                   value={form.folderNames}
                                                                                                   placeholder="Insert folder name"
                                                                                                   onChange={(e) => this.onInputFolderName(e, form, indexConnect)}></Input>
                                                                                            {/*{form.visibleSelected ?*/}
                                                                                            {/*    <span className="icon-v23-add"*/}
                                                                                            {/*          onClick={(e) => this.onAddRemoveInputsFolderName(e, 'INCREMENT', form, indexConnect)}></span> :*/}
                                                                                            {/*    <span className="icon-v23-minus"*/}
                                                                                            {/*          onClick={(e) => this.onAddRemoveInputsFolderName(e, 'DECREMENT', form, indexConnect)}></span>*/}
                                                                                            {/*}*/}
                                                                                        </div>
                                                                                    {/*)*/}
                                                                                {/*})*/}

                                                                                {/*}*/}
                                                                            </div>
                                                                            }

                                                                            { form.connectorType && form.connectorType === 'Microsoft 365 Exchange' &&
                                                                            <div className="connectors-content-details">
                                                                                <div className="content-details-options">
                                                                                    <Checkbox
                                                                                        checked={form.selected}
                                                                                        onChange={(e) => this.onChangeCheckBoxDateExchange(e, form, indexConnect)}></Checkbox>
                                                                                    <RangePicker
                                                                                        onChange={(date, dateString) => this.selectDate(date, dateString, indexConnect, form)}
                                                                                        className='date-picker'
                                                                                        format="YYYY-MM-DD"
                                                                                        value={form.startDates && form.endDates ? [moment(form.startDates), moment(form.endDates)] : []}
                                                                                        dropdownClassName="date-picker-up"/>
                                                                                    <Select
                                                                                        onChange={(e) => this.onChangeSelectEmailExchange(e, form, indexConnect)}
                                                                                        placeholder="select-details-email"
                                                                                        //value={form1.selectEmailAccount}
                                                                                        dropdownClassName={'dropdown-select-details-email'}
                                                                                        className="select-details-email">
                                                                                        <Option
                                                                                            value="All Email accounts">{'All email accounts'}</Option>
                                                                                    </Select>
                                                                                    {/*{form.visibleSelected ?*/}
                                                                                    {/*    <span className="icon-v23-add"*/}
                                                                                    {/*          onClick={(e) => this.onAddRemoveDateExchange(e, 'INCREMENT', form, indexConnect)}></span> :*/}
                                                                                    {/*    <span className="icon-v23-minus"*/}
                                                                                    {/*          onClick={(e) => this.onAddRemoveDateExchange(e, 'DECREMENT', form, indexConnect)}></span>*/}
                                                                                    {/*}*/}
                                                                                </div>
                                                                            </div>
                                                                            }

                                                                            { form.connectorType && form.connectorType === 'Microsoft 365 SharePoint' &&
                                                                            <div className="connectors-content-details">
                                                                                <div className="content-details-options">
                                                                                    <Checkbox
                                                                                        checked={form.selected}
                                                                                        onChange={(e) => this.onChangeCheckBoxSharePoint(e, form, indexConnect)}></Checkbox>
                                                                                    <Select
                                                                                        onChange={(e) => this.onChangeSelectSiteForFolder(e, form, indexConnect)}
                                                                                        placeholder="Select SharePoint Site"
                                                                                        //value={form1.selectEmailAccount}
                                                                                        dropdownClassName={'dropdown-select-details-email'}
                                                                                        className="select-details-email select-details-share-site">
                                                                                        {form.dataSelectSiteListSharePoint && form.dataSelectSiteListSharePoint.length > 0 && form.dataSelectSiteListSharePoint.map((site) => {
                                                                                            return (
                                                                                                <Option key={site.siteId}
                                                                                                        value={site.siteId}>{site.siteName}</Option>
                                                                                            )
                                                                                        })
                                                                                        }
                                                                                    </Select>
                                                                                    <Select
                                                                                        onChange={(e) => this.onChangeSelectFolder2Site(e, form, indexConnect)}
                                                                                        placeholder="Select Folder"
                                                                                        //value={form1.selectEmailAccount}
                                                                                        dropdownClassName={'dropdown-select-details-email'}
                                                                                        className="select-details-email select-details-share-folder">
                                                                                        { form.dataSelectSiteFolderListSharePoint && form.dataSelectSiteFolderListSharePoint.length > 0 && form.dataSelectSiteFolderListSharePoint.map((folder) => {
                                                                                            return (
                                                                                                <Option key={folder.folderId} value={folder.folderName}>{folder.folderName}</Option>
                                                                                            )
                                                                                        })
                                                                                        }
                                                                                    </Select>
                                                                                    {/*{form.visibleSelected ?*/}
                                                                                    {/*    <span className="icon-v23-add"*/}
                                                                                    {/*          onClick={(e) => this.onAddRemoveSharePoint(e, 'INCREMENT', form, indexConnect)}></span> :*/}
                                                                                    {/*    <span className="icon-v23-minus"*/}
                                                                                    {/*          onClick={(e) => this.onAddRemoveSharePoint(e, 'DECREMENT', form, indexConnect)}></span>*/}
                                                                                    {/*}*/}
                                                                                </div>
                                                                            </div>
                                                                            }

                                                                        </React.Fragment>
                                                                    )
                                                                })
                                                                }
                                                            </div>
                                                        )
                                                       })
                                                    }
                                                </div>
                                            </div>

                                            {/*<div className="contain-manual-upload">*/}
                                            {/*    /!*<span className="icon-v22-db-storage"></span>*!/*/}
                                            {/*    { dataSources && dataSources.length > 0 && dataSources.map((data, index) => {*/}
                                            {/*        return (*/}
                                            {/*            <div className="manual-upload-describe">*/}
                                            {/*                /!*<div className="header-manual-upload">*!/*/}
                                            {/*                /!*    <span className="title-manual-upload">Manual Upload</span>*!/*/}
                                            {/*                /!*    <span className='icon-v23-add'></span>*!/*/}
                                            {/*                /!*</div>*!/*/}
                                            {/*                { data.otherDataSources && data.otherDataSources.length > 0 && data.otherDataSources.map((other, indexOther) => {*/}
                                            {/*                    return (*/}
                                            {/*                <div className="manual-upload-content">*/}
                                            {/*                    /!*<Upload>*!/*/}
                                            {/*                    /!*    <Button>*!/*/}
                                            {/*                    /!*        <Icon type="upload"/>*!/*/}
                                            {/*                    /!*    </Button>*!/*/}
                                            {/*                    /!*</Upload>*!/*/}
                                            {/*                    <span onClick={other.dataLocation ? (e) => this.onOpenModalDataUploadFrom(e, other, indexOther) :*/}
                                            {/*                        (e) => this.onOpenModalDataUploadDisabled(e)}*/}
                                            {/*                          className={ other.dataLocation ? "icon-v22-upload-1" : 'icon-v22-upload-1 icon-v22-upload-1-disabled' }></span>*/}
                                            {/*                    <Input placeholder="Storages / locations"*/}
                                            {/*                           value={other.dataLocation}*/}
                                            {/*                           onChange={(e) => this.onInputStorageLocation(e, other, indexOther)}*/}
                                            {/*                           className="describe-input"/>*/}
                                            {/*                    {other.visibleSelected ?*/}
                                            {/*                        <span className="icon-v23-add"*/}
                                            {/*                              onClick={(e) => this.onAddRemoveStorageLocation(e, 'INCREMENT', other, indexOther)}></span> :*/}
                                            {/*                        <span className="icon-v23-minus"*/}
                                            {/*                              onClick={(e) => this.onAddRemoveStorageLocation(e, 'DECREMENT', other, indexOther)}></span>*/}
                                            {/*                    }*/}
                                            {/*                </div>*/}
                                            {/*                    )*/}
                                            {/*                })*/}
                                            {/*                }*/}
                                            {/*            </div>*/}
                                            {/*        )*/}
                                            {/*     })*/}
                                            {/*    }*/}
                                            {/*</div>*/}

                                            {/*<div className="contain-manual-connectors">*/}
                                            {/*    <span className="icon-v23-db-storage"></span>*/}
                                            {/*    <div className="manual-connectors">*/}
                                            {/*        <div className="header-connectors">*/}
                                            {/*            <span className="title-connectors">Data sources</span>*/}
                                            {/*            <span*/}
                                            {/*                className={hoverAddConnector ? 'icon-v23-add-active' : 'icon-v23-add'}*/}
                                            {/*                onMouseEnter={(e) => this.onHoverDataCollector('addConnector', null)}*/}
                                            {/*                onMouseLeave={(e) => this.onLeaverDataCollector()}*/}
                                            {/*                onClick={(e) => this.addNewSelectConnector(e, 'INCREMENT')}></span>*/}
                                            {/*        </div>*/}
                                            {/*        {formDataCollector && formDataCollector.connectorsSelect && formDataCollector.connectorsSelect.length > 0 && formDataCollector.connectorsSelect.map((form, index) => {*/}
                                            {/*            return (*/}
                                            {/*                <div className="connectors-content">*/}
                                            {/*                    <div className="select-connectors-contain">*/}
                                            {/*                        <Select placeholder="Select"*/}
                                            {/*                                className="select-connectors"*/}
                                            {/*                                onChange={(e) => this.onChangeSelectConnector(e, 'valueConnectorSelect', index)}>*/}
                                            {/*                            {dataConnectorsList && dataConnectorsList.length > 0 && dataConnectorsList.map((connect, index2) => {*/}
                                            {/*                                return (<Option*/}
                                            {/*                                    className="options-select-connector"*/}
                                            {/*                                    value={connect.connectorId}*/}
                                            {/*                                    key={index2}*/}
                                            {/*                                    disabled={connect.active === false}*/}
                                            {/*                                >*/}
                                            {/*                                    {`${connect.connectorProvider} - ${connect.connectorName}`}*/}
                                            {/*                                </Option>)*/}
                                            {/*                            })}*/}
                                            {/*                        </Select>*/}
                                            {/*                        {index !== 0 && formDataCollector.connectorsSelect.length > 1 &&*/}
                                            {/*                        <button*/}
                                            {/*                            onClick={(e) => this.addNewSelectConnector(e, 'DECREMENT', index)}*/}
                                            {/*                            className={'icon-v23-minus'}*/}
                                            {/*                        />*/}
                                            {/*                        }*/}
                                            {/*                    </div>*/}
                                            {/*                    {form.nameConnect && form.nameConnect === 'Microsoft 365 Onedrive' &&*/}
                                            {/*                    <div className="connectors-content-details">*/}
                                            {/*                        {form.listInputsOnedrive && form.listInputsOnedrive.length > 0 && form.listInputsOnedrive.map((onedrive, index3) => {*/}
                                            {/*                            return (*/}
                                            {/*                                <div className="content-details-options">*/}
                                            {/*                                    <Checkbox*/}
                                            {/*                                        checked={onedrive.selected}*/}
                                            {/*                                        onChange={(e) => this.onChangeCheckBoxInputsOnedrive(e, form, index3)}></Checkbox>*/}
                                            {/*                                    <Input className="input-folder-name"*/}
                                            {/*                                           value={onedrive.folderName}*/}
                                            {/*                                           onChange={(e) => this.onInputFolderName(e, form, index3)}></Input>*/}
                                            {/*                                    {index3 === 0 ?*/}
                                            {/*                                        <span className="icon-v23-add"*/}
                                            {/*                                              onClick={(e) => this.onAddRemoveInputsFolderName(e, 'INCREMENT', form, index3)}></span> :*/}
                                            {/*                                        <span className="icon-v23-minus"*/}
                                            {/*                                              onClick={(e) => this.onAddRemoveInputsFolderName(e, 'DECREMENT', form, index3)}></span>*/}
                                            {/*                                    }*/}
                                            {/*                                </div>*/}
                                            {/*                            )*/}
                                            {/*                        })*/}

                                            {/*                        }*/}
                                            {/*                    </div>*/}
                                            {/*                    }*/}
                                            {/*                    {form.nameConnect && form.nameConnect === 'Microsoft 365 Exchange' &&*/}
                                            {/*                    <div className="connectors-content-details">*/}
                                            {/*                        {form.listDateExchange && form.listDateExchange.length > 0 && form.listDateExchange.map((exchange, index3) => {*/}
                                            {/*                            return (*/}
                                            {/*                                <div className="content-details-options">*/}
                                            {/*                                    <Checkbox*/}
                                            {/*                                        checked={exchange.selected}*/}
                                            {/*                                        onChange={(e) => this.onChangeCheckBoxDateExchange(e, form, index3)}></Checkbox>*/}
                                            {/*                                    <RangePicker*/}
                                            {/*                                        onChange={(date, dateString) => this.selectDate(date, dateString, index3, form)}*/}
                                            {/*                                        className='date-picker'*/}
                                            {/*                                        format="YYYY-MM-DD"*/}
                                            {/*                                        value={exchange.startDates && exchange.endDates ? [moment(exchange.startDates), moment(exchange.endDates)] : []}*/}
                                            {/*                                        dropdownClassName="date-picker-up"/>*/}
                                            {/*                                    <Select*/}
                                            {/*                                        onChange={(e) => this.onChangeSelectEmailExchange(e, form, index3)}*/}
                                            {/*                                        placeholder="select-details-email"*/}
                                            {/*                                        //value={form1.selectEmailAccount}*/}
                                            {/*                                        dropdownClassName={'dropdown-select-details-email'}*/}
                                            {/*                                        className="select-details-email">*/}
                                            {/*                                        <Option*/}
                                            {/*                                            value="All Email accounts">{'All email accounts'}</Option>*/}
                                            {/*                                    </Select>*/}
                                            {/*                                    {index3 === 0 ?*/}
                                            {/*                                        <span className="icon-v23-add"*/}
                                            {/*                                              onClick={(e) => this.onAddRemoveDateExchange(e, 'INCREMENT', form, index3)}></span> :*/}
                                            {/*                                        <span className="icon-v23-minus"*/}
                                            {/*                                              onClick={(e) => this.onAddRemoveDateExchange(e, 'DECREMENT', form, index3)}></span>*/}
                                            {/*                                    }*/}
                                            {/*                                </div>*/}
                                            {/*                            )*/}
                                            {/*                        })*/}

                                            {/*                        }*/}
                                            {/*                    </div>*/}
                                            {/*                    }*/}

                                            {/*                    {form.nameConnect && form.nameConnect === 'Microsoft 365 SharePoint' &&*/}
                                            {/*                    <div className="connectors-content-details">*/}
                                            {/*                        {form.listSharePoint && form.listSharePoint.length > 0 && form.listSharePoint.map((share, index3) => {*/}
                                            {/*                            return (*/}
                                            {/*                                <div className="content-details-options">*/}
                                            {/*                                    <Checkbox*/}
                                            {/*                                        checked={share.selected}*/}
                                            {/*                                        onChange={(e) => this.onChangeCheckBoxSharePoint(e, form, index3)}></Checkbox>*/}
                                            {/*                                    <Select*/}
                                            {/*                                        onChange={(e) => this.onChangeSelectEmailExchange(e, form, index3)}*/}
                                            {/*                                        placeholder="Select SharePoint Site"*/}
                                            {/*                                        //value={form1.selectEmailAccount}*/}
                                            {/*                                        dropdownClassName={'dropdown-select-details-email'}*/}
                                            {/*                                        className="select-details-email select-details-share-site">*/}
                                            {/*                                        {share.dataSelectSiteListSharePoint && share.dataSelectSiteListSharePoint.length > 0 && share.dataSelectSiteListSharePoint.map((site) => {*/}
                                            {/*                                            return (*/}
                                            {/*                                                <Option key={site.siteId}*/}
                                            {/*                                                        value={site.siteId}>{site.siteName}</Option>*/}
                                            {/*                                            )*/}
                                            {/*                                        })*/}
                                            {/*                                        }*/}
                                            {/*                                    </Select>*/}
                                            {/*                                    <Select*/}
                                            {/*                                        onChange={(e) => this.onChangeSelectEmailExchange(e, form, index3)}*/}
                                            {/*                                        placeholder="Select Folder"*/}
                                            {/*                                        //value={form1.selectEmailAccount}*/}
                                            {/*                                        dropdownClassName={'dropdown-select-details-email'}*/}
                                            {/*                                        className="select-details-email select-details-share-folder">*/}
                                            {/*                                        <Option*/}
                                            {/*                                            value="All Email accounts">{'All email accounts'}</Option>*/}
                                            {/*                                    </Select>*/}
                                            {/*                                    {index3 === 0 ?*/}
                                            {/*                                        <span className="icon-v23-add"*/}
                                            {/*                                              onClick={(e) => this.onAddRemoveDateExchange(e, 'INCREMENT', form, index3)}></span> :*/}
                                            {/*                                        <span className="icon-v23-minus"*/}
                                            {/*                                              onClick={(e) => this.onAddRemoveDateExchange(e, 'DECREMENT', form, index3)}></span>*/}
                                            {/*                                    }*/}
                                            {/*                                </div>*/}
                                            {/*                            )*/}
                                            {/*                        })*/}

                                            {/*                        }*/}
                                            {/*                    </div>*/}
                                            {/*                    }*/}

                                            {/*                </div>*/}
                                            {/*            )*/}
                                            {/*        })*/}
                                            {/*        }*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}

                                            <div className="date-source">
                                                <span className="icon-v22-search-b"></span>
                                                <div className="content-date-source">
                                                    <div className="date-source-header">
                                                        <span className="name-data-source">Search queries</span>
                                                        <span  onClick={(e) => this.onCLickAddRemoveSearchQueries(e, 'add')}
                                                               className={ onHoverAddSearchQueries ? "icon-v22-add-active" : "icon-v22-add"}
                                                               onMouseEnter={() => this.onHoverAction('add-search-queries')}
                                                               onMouseLeave={() => this.onLeaveAction()}
                                                        ></span>
                                                    </div>

                                                    { businessProcessQueries && businessProcessQueries.length > 0 &&
                                                    <div className="contact-details">
                                                        { businessProcessQueries && businessProcessQueries.length > 0 && businessProcessQueries.map((form1, index) => {
                                                            return (
                                                                <div className="contact-details-contain" key={`key-${index}`}>
                                                                    <div className="checkbox-contain">
                                                                        <Checkbox checked={form1.selected} onChange={(e) => this.onChangeCheckboxSearchQueries(e, form1, index)} >{ <span className="value-checkbox"><span className="first-value-checkbox">{form1.query}</span></span> }</Checkbox>
                                                                    </div>
                                                                    <div className="option-contact-details">
                                                                        <Input value={ form1.value } onChange={(e) => this.onInputSearchQueries(e, index, form1)}
                                                                               className={ !form1.id ? "input-folder-name input-folder-name-remove" : 'input-folder-name'}></Input>
                                                                        { !form1.id &&
                                                                        <span
                                                                            onClick={(e) => this.onCLickAddRemoveSearchQueries(e, 'remove', index, form1)}
                                                                            className={ onHoverRemoveSearchQueries === index ? "icon-v22-minus-active" : "icon-v22-minus"}
                                                                            onMouseEnter={() => this.onHoverAction('remove-search-queries', index)}
                                                                            onMouseLeave={() => this.onLeaveAction()}
                                                                        ></span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                    }

                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }

                                    <div className="content-action-collect">
                                        <Button
                                                disabled={(valueShareBusiness === false && (selectConnectorValue === null && inputStorageLocationValue === ''))}
                                                onClick={() => this.onSaveCollect()}
                                                className='button-collect'>Collect</Button>
                                    </div>

                                </div>
                            </div>

                            <div className="import-data-collector">
                               <div className="header-import-data-collector">
                                   <span className="title-import">Import</span>
                               </div>
                                <div className="content-import-data-collector">
                                    { dataUploadHistory && dataUploadHistory.length > 0 && dataUploadHistory.map((dataUpload, indexUpload) => {
                                        return (
                                            <Collapse onChange={(key) => this.callback(key)}>
                                                <Panel className="header-import-collapse"
                                                       header={<span className="header-import-content"><span>{dataUpload.uploadDate}</span><span>{dataUpload.userName}</span></span>}
                                                       key={indexUpload}>
                                                    {
                                                        dataUpload.dataSourceResponseDTOS && dataUpload.dataSourceResponseDTOS.length > 0 && dataUpload.dataSourceResponseDTOS.map((dataUpload2, indexUpload2) => {
                                                            return (
                                                                <Collapse
                                                                    //defaultActiveKey="1"
                                                                >
                                                                    <Panel className="subheader-import-collapse" header={dataUpload2.dataSourceName} key={indexUpload2}>
                                                                        {/*<p className="paragraf-text">{text}</p>*/}
                                                                        { dataUpload2.dataSourceItemResponseDTOS && dataUpload2.dataSourceItemResponseDTOS.length > 0 && dataUpload2.dataSourceItemResponseDTOS.map((dataUpload3, indexUpload3) => {
                                                                            return (
                                                                                <span className="source-items">
                                                                                     <span className="data-source-collapse">
                                                                                         <span className="data-source-item-name">{dataUpload3.dataSourceItemName }</span>
                                                                                         <span className="data-source-item-type">{` - ${dataUpload3.dataSourceItemType}`}</span>
                                                                                     </span>
                                                                                    <span className="file-upload-number">
                                                                                        {dataUpload3.dataSourceDateRange ? `${dataUpload3.dataSourceDateRange}` : ''}
                                                                                    </span>
                                                                                    <span className="file-upload-number">
                                                                                        {`${dataUpload3.filesUploadedNumber} files`}
                                                                                    </span>
                                                                                </span>
                                                                            )
                                                                        })

                                                                        }
                                                                    </Panel>
                                                                </Collapse>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        dataUpload.searchQueries && dataUpload.searchQueries.length > 0 &&
                                                        <Collapse
                                                            //defaultActiveKey="1"
                                                        >
                                                            <Panel className="subheader-import-collapse" header="Search queries" key={indexUpload}>
                                                                { dataUpload.searchQueries.map((dataQueries, indexQueries) => {
                                                                    return (
                                                                        <span className="source-items-queries">
                                                                            {dataQueries}
                                                                                </span>
                                                                    )
                                                                })

                                                                }
                                                            </Panel>
                                                        </Collapse>
                                                    }
                                                </Panel>
                                            </Collapse>
                                        )
                                    })

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal className="data-upload-from-modal" title={<div className="header-connector-modal"><span>{'Data upload from'}</span></div>}
                           visible={ visibleDataUploadFrom } footer={null} closable={ false }>
                        <div className="content-data-upload-from">
                            <div className="data-upload-from-text-field">
                                <Input className="input-token-name"
                                       placeholder={"Desktop"}
                                       value={formOtherDataSourcesSelect.valueInputDesktop ? formOtherDataSourcesSelect.valueInputDesktop :
                                           formOtherDataSourcesSelect.nameUpload ? formOtherDataSourcesSelect.nameUpload : "" }
                                       onChange={(e) => this.onInputDesktop(e)}></Input>
                            </div>
                            <div className="data-upload-from-modal-buttons">
                                <Button onClick={ () => this.onCancelModalVisible() }>Cancel</Button>
                                <Upload
                                    fileList={ fileList }
                                    accept={ '.pdf, .png, .jpeg, .jpg' }
                                    beforeUpload={ this.customUploadDraft }
                                    multiple={ false }
                                >
                                    <Button type="primary" onClick={() => this.onImportModal()}
                                    >{'Import'}</Button>
                                </Upload>
                                {/*<Button type="primary" onClick={() => this.onImportModal()}*/}
                                {/*        >{'Import'}</Button>*/}
                            </div>
                        </div>

                    </Modal>
                </div>
        )
    }
}

SmartBoxFolderDataCollector = withRouter(SmartBoxFolderDataCollector)

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id,
    smartBoxFilesTable: state.smartBox.smartBoxFilesTable,
    user: state.userReducer.user,
    smartBoxBaseDataTree: state.smartBox.smartBoxBaseDataTree,
    smartBoxSelectedDocumentId: state.smartBox.smartBoxSelectedDocumentId,
    smartBoxVisibleAddNote: state.smartBox.smartBoxVisibleAddNote,
    smartBoxAxiosHeaders: state.smartBox.smartBoxAxiosHeaders,
})

export default compose(
    connect(mapStateToProps, {
        setSmartBoxFilesTable,
        // setSmartBoxBaseDataTree,
        setSmartBoxToggleFilePreview,
        setSmartBoxSelectedDocuments,
        setSmartBoxDirectoryNote,
        setSmartBoxAnalysisPercent
    }),
)(SmartBoxFolderDataCollector)