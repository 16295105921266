import React from 'react'
import Page403 from './ErrorPage/Page403'
import Page404 from './ErrorPage/Page404'
import Page500 from './ErrorPage/Page500'

class ErrorPage extends React.Component {
  render() {
    const { error } = this.props

    if (error.status === 404) return (
      <Page404 />
    )

    if (error.status === 403) return (
      <Page403 />
    )

    return (<Page500 />)
  }
}

export default ErrorPage