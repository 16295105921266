import React from "react";
import {connect} from "react-redux";
import './ComponentsForm.scss';
import { Input, Checkbox, Col} from "antd";

class DropdownCategoriesForm extends React.Component {
    state = {
        listDropdown: [
            // {key: 1, option: 'Type option 1' },
            // {key: 2, option: 'Type option 2' },
            // {key: 3, option: 'Type option 3' },
            // {key: 4, option: 'Type option 4' },
        ],
        statusOtherOption: false,
        dropdownForm: {
            valuesDropdown: [],
            queryForSearch: false,
            mandatoryCheck: false,
            options: []
        },
        hoverDelete: false,
        indexOption: 0
    }
    componentDidMount() {
        //this.props.onChangeForm(this.state.dropdownForm, this.props.component)
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props && this.props.checkStartSaved) {
            this.props.onChangeForm(this.state.dropdownForm, this.props.component)
        }
    }
    onChangeDropdown = (checkedValues) => {
        this.setState({
            dropdownForm: { ...this.state.dropdownForm, valuesDropdown: checkedValues },
        })
    }
    addOptionDropdown = () => {
        this.setState({
            statusOtherOption: true
        })
    }
    displayInputOtherOptionDropdown = () => {
        const { listDropdown, indexOption } = this.state
        const listOption = []
        listDropdown && listDropdown.length > 0 && listDropdown.forEach((list) => {
            listOption.push(list.option)
        })
        if (listDropdown && listDropdown.length > 0 && !listOption.includes('Other')) {
            this.setState({
                listDropdown: [...this.state.listDropdown, {
                    key: indexOption,
                    option: 'Other'
                }],
                dropdownForm: { ...this.state.dropdownForm, options:  [...this.state.dropdownForm.options, {
                        key: indexOption,
                        option: 'Other'
                    }] },
                indexOption: indexOption + 1
            }, () => {
                this.props.onChangeForm(this.state.dropdownForm, this.props.component)
            })
        } else if (listDropdown && listDropdown.length === 0) {
            this.setState({
                listDropdown: [...this.state.listDropdown, {
                    key: indexOption,
                    option: 'Other'
                }],
                dropdownForm: { ...this.state.dropdownForm, options:  [...this.state.dropdownForm.options, {
                        key: indexOption,
                        option: 'Other'
                    }] },
                indexOption: indexOption + 1
            }, () => {
                this.props.onChangeForm(this.state.dropdownForm, this.props.component)
            })
        }
    }
    addOtherOptionDropdown = (e) => {
        const { listDropdown, indexOption } = this.state
        this.setState({
            listDropdown: [...this.state.listDropdown, {
                key: indexOption,
                option: e.target.value
            }],
            dropdownForm: { ...this.state.dropdownForm, options:  [...this.state.dropdownForm.options, {
                    key: indexOption,
                    option: e.target.value
                }] },
            statusOtherOption: false,
            indexOption: indexOption + 1
        }, () => {
            this.props.onChangeForm(this.state.dropdownForm, this.props.component)
        })
    }
    onChangeCheckbox = (e, key) => {
        switch (key) {
            case 'query':
                this.setState({
                    dropdownForm: { ...this.state.dropdownForm, queryForSearch: e.target.checked },
                }, () => {
                    this.props.onChangeForm(this.state.dropdownForm, this.props.component)
                })
                break
            case 'mandatory':
                this.setState({
                    dropdownForm: { ...this.state.dropdownForm, mandatoryCheck: e.target.checked },
                }, () => {
                    this.props.onChangeForm(this.state.dropdownForm, this.props.component)
                })
                break
            default:
                break
        }
    }
    onHoverActionComponent = () => {
        this.setState({
            hoverDelete: true
        })
    }
    onLeaveActionComponent = () => {
        this.setState({
            hoverDelete: false
        })
    }
    render() {
        const { listDropdown, statusOtherOption, dropdownForm, hoverDelete } = this.state
        const { component, defaultTemplate } = this.props
        return (
            <div className={ this.props.snapshot.isDragging ? "component-form dragging " : "component-form"}>
                <div className="component-delete">
                    <button onClick={ (e) => this.props.showConfirmDeleteFormElement(e, component) }
                          className={hoverDelete ? 'icon-v19-delete-active' : "icon-v19-delete" }
                            disabled={defaultTemplate}
                          onMouseEnter={ (e) => this.onHoverActionComponent(e)}
                          onMouseLeave={() => this.onLeaveActionComponent()}></button>
                </div>
                <div className="component-content">
                    <div className="header-component">
                        <span className="header-title">{ component.titleCategories }</span>
                        <span className="header-checkbox">
                           {/*<Checkbox onChange={(e) => this.onChangeCheckbox(e, 'query')}*/}
                           {/*          checked={ dropdownForm.queryForSearch }>Query for search</Checkbox>*/}
                           <Checkbox onChange={(e) => this.onChangeCheckbox(e, 'mandatory')}
                                     disabled={defaultTemplate}
                                     checked={ dropdownForm.mandatoryCheck }>Mandatory</Checkbox>
                        </span>
                    </div>
                    <div className="dropdown-content">
                        <Checkbox.Group style={{ width: '100%' }} onChange={this.onChangeDropdown} value={ dropdownForm.valuesDropdown } >
                            { listDropdown && listDropdown.length > 0 && listDropdown.map((list, index) => {
                                return (
                                    <Col key={ index }>
                                    <Checkbox key={ index } value={ list.option }>{ list.option }</Checkbox>
                                    </Col>
                                )
                            })

                            }
                        </Checkbox.Group>
                        {
                            statusOtherOption &&
                            <Input className="input-other-option" onPressEnter={ (e) => this.addOtherOptionDropdown(e)} />
                        }
                        <span className="action-options-add">
                            <span className="add-option" onClick={(e) => this.addOptionDropdown(e)}>Add Option</span>
                            <span className="add-other-option" onClick={(e) => this.displayInputOtherOptionDropdown(e)}>Add "Other" Option</span>
                        </span>
                    </div>
                </div>
                <div className="component-move">
                    <span onMouseEnter={() => this.props.onStateProvidedDraggable(component)} className="icon-v211-grid-view-1"></span>
                </div>
            </div>
        )
    }
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(DropdownCategoriesForm)