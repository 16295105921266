import React from 'react'
import { Card } from './Card'
import { getStatistics } from '../../../api/Sar'
import CustomNotificationManager from "../../Common/CustomNotificationManager";

function CardList() {
  const [cardData, setCardData] = React.useState(null)
  React.useEffect(() => {
    getStatistics().then((response) => {
      if (response.status === 200 && response.data) {
        const cardData = response.data
        cardData.averageNoOfDocuments = response.data.averageNumberOfDocuments
        setCardData(cardData)
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }, [])
  return (
    <React.Fragment>
      { cardData &&
        <div className= "card-line">
          <Card title="Total requests" icon="icon-v10-request-form" valueNumber={ +cardData.totalRequests } />
          <Card title="Open requests" icon="icon-v10-process" valueNumber={ +cardData.totalOpenRequests } />
          <Card title="Average processing time" icon="icon-v10-time" valueNumber={ cardData.averageProcessTime ? cardData.averageProcessTime : 0 } valueDetails={ `Quickest time: ${ cardData.quickestTime ? cardData.quickestTime : '23h' } `}/>
          <Card title="Average no. of documents" icon="icon-v10-documents" valueNumber={ +cardData.averageNumberOfDocuments } />
        </div>
      }
    </React.Fragment>

  )
}

export default CardList