import React from 'react'
import { Link, Navigate } from 'react-router-dom'
import { compose } from 'redux'
import PropTypes from 'prop-types'

import AccessControl from './Form/AccessControl'
import AddKeywords from './Form/AddKeywords'
import AddPii from './Form/AddPii'
import AddObjectTypes from './Form/AddObjectTypes'
import AlertInfo from './AlertCreate/AlertInfo'
import ButtonsArea from './Form/ButtonsArea'
import CollectionsList from './Form/CollectionsList'
import EditInPlace from './Form/EditInPlace'
import FormDateSpan from './Form/FormDateSpan'
import Loading from '../Common/Loading'
import StatusLevel from './Form/StatusLevel'
import {
  apiPrivacyChangeCategoryParent,
  apiPrivacyDeleteCategory,
  apiPrivacyRenameCategory,
  apiPrivacyGetCountFolder,
  apiPrivacySaveCategory,
  apiPrivacySetCategoryProperty
} from '../../api/Privacy'
import {
  extractCountFolder,
  initialFilterQuery,
  mountAccessControlFilter,
  mountKeywordFilter,
  mountSelectFilter,
  unmountAccessControlFilter,
  unmountDateFilter,
  unmountSelectFilter,
  unmountKeywordFilter
} from '../../utils'
import { findAlert } from '../../utils/Alerts'

import WithAlerts from './HOC/WithAlerts'
import WithDataModels from './HOC/WithDataModels'
import './AlertCreate.css'

class AlertCreate extends React.Component {
  state = {
    accessControl: [],
    countFolders: '--',
    alert: {},
    alertfilterQuery: '',
    alertId: null,
    hasError: false,
    initFields: false,
    isDeleting: false,
    isFetchingFolders: true,
    isSaving: false,
    keywords: [{ rule: '', value: '' }],
    objectTypes: [{ rule: '', kind: '', value: '' }],
    pii: [{ rule: '', kind: '', value: '' }],
    selectedCollection: '',
    selectedStatusLevel: '',
    selectedValueFrom: '*',
    selectedValueTo: 'NOW'
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)    
    this.setState({
      alertId: parseInt(this.props.params.id, 10),
      initFields: true
    }, () => this.props.getAlertUsingId(this.state.alertId))
  }

  componentWillReceiveProps = () => this.initFields()

  componentWillUnmount = () => this.props.resetAlerts()

  initFields = () => {
    if (this.state.initFields &&
        !this.props.isFetchingAlerts > 0 &&
        this.props.dataModels.length > 0) {
      const { alertId } = this.state
      const alertToEdit = findAlert(this.props.alerts, alertId)

      if (!alertToEdit) return this.setState({ hasError: true })

      this.fillAlert(alertToEdit)
    }
  }

  fillAlert = (alertToEdit) => {
    const { selectedValueFrom, selectedValueTo } = unmountDateFilter(alertToEdit)
    this.setState({
      accessControl: unmountAccessControlFilter(alertToEdit),
      alert: alertToEdit,
      alertfilterQuery: initialFilterQuery(alertToEdit),
      initFields: false,
      keywords: unmountKeywordFilter(alertToEdit),
      objectTypes: unmountSelectFilter(alertToEdit, 'Basic', this.props.dataModels),
      pii: unmountSelectFilter(alertToEdit, 'PII', this.props.dataModels),
      selectedCollection: `${ alertToEdit.parentid }`,
      selectedStatusLevel: alertToEdit.properties.level ? alertToEdit.properties.level : '', 
      selectedValueFrom,
      selectedValueTo
    }, this.getFolderInformationForAlert)
  }

  getFolderInformationForAlert = () => this.getCountFolder()

  getCountFolder = () => {
    this.setState({ isFetchingFolders: true })
    
    const facetnames = ['doc_folder_path']
    const { query, filterQueries } = this.getFilters()

    apiPrivacyGetCountFolder({ facetnames, query, filterQueries })
    .then((response) => {
      this.setState({
        countFolders: extractCountFolder(response),
        isFetchingFolders: false
      })
    })
  }

  addAnother = (field) => {
    if (field === 'objectType') return this.setState({
      objectTypes: [...this.state.objectTypes, { rule: '', kind: '', value: '' }]
    })
    if (field === 'pii') return this.setState({
      pii: [...this.state.pii, { rule: '', kind: '', value: '' }]
    })
    if (field === 'keyword') return this.setState({
      keywords: [...this.state.keywords, { rule: '', value: '' }]
    })
  }

  selectedAccessControl = (newAccess) => this.setState({ accessControl: newAccess })

  updateObjectTypes = (objectTypes) => this.setState({ objectTypes })

  updatePii = (pii) => this.setState({ pii })

  updateKeywords = (keywords) => this.setState({ keywords })

  handleInputChange = (event) => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({ [name]: value })

    // const shouldUpdateFolders = ['selectedCollection']
    // if (shouldUpdateFolders.includes(name)) {
    //   this.getCountFolder()
    // }
  }

  selectedRangeDate = (field, event) => {
    this.setState({ [field]: event.target.value })
    this.getCountFolder()
  }

  save = () => {
    this.setState({ isSaving: true })
    const { query, filterQueries } = this.getFilters()

    this.changeCategoryParent()
    .then(() => {
      this.changeStatusLevel()
        .then(() => {
          apiPrivacySaveCategory(query, filterQueries, this.state.alertId)
            .then((response) => {
              if (response.data.status === 'Success') {
                this.props.resetAlerts()
                  .then(() => {
                    this.setState({ isSaving: false, redirectToAlerts: true })
                  })
              }
            })
        })
    })
  }

  changeCategoryParent() {
    return new Promise((resolve, reject) => {
      const parentID = this.state.selectedCollection
      
      // Only change if the selected collection is different than the old one.
      if (this.state.alert.parentid === parseInt(parentID, 10)) resolve()

      const categoryID = this.state.alertId
      apiPrivacyChangeCategoryParent(categoryID, parentID)
      .then((response) => {
        if (response.data.status === 'Success') {
          resolve()
        }
      })
    })
  }

  changeStatusLevel() {
    return new Promise((resolve, reject) => {
      const level = this.state.selectedStatusLevel ? this.state.selectedStatusLevel : 'medium'
      const alertLevel = (this.state.alert.properties && this.state.alert.properties.level)
        ? this.state.alert.properties.level
        : ''
      
      // Only change if the selected level is different than the old one.
      if (alertLevel === level) resolve()

      const categoryID = this.state.alertId
      apiPrivacySetCategoryProperty(categoryID, 'level', level)
        .then((response) => {
          if (response.data.status === 'Success') {
            resolve()
          }
        })
    })
  }

  getFilters() {
    const pii = mountSelectFilter(this.state.pii)
    const objects = mountSelectFilter(this.state.objectTypes)
    const query = mountKeywordFilter(this.state.keywords)
    const access = mountAccessControlFilter(this.state.accessControl)
    let date = ''
    if (this.state.selectedValueFrom !== '*' && this.state.selectedValueTo !== 'NOW') {
      date = `doc_date_modified:[${ this.state.selectedValueFrom } TO ${ this.state.selectedValueTo }]`
    }
    const filterQueries = [
      this.state.alertfilterQuery,
      ...pii,
      ...objects,
      access,
      date
    ].filter((item) => item !== '')

    return { query, filterQueries }
  }

  delete = () => {
    this.setState({ isDeleting: true })
    const { alertId } = this.state
    
    apiPrivacyDeleteCategory(alertId)
    .then((response) => {
      if (response.data.status === 'Success') {
        this.setState({ redirectToAlerts: true })
      }
    })
  }

  renameAlert = (value) => {
    const { alertId } = this.state
    
    apiPrivacyRenameCategory(alertId, value)
    .then((response) => {
      if (response.data.status === 'Success') {
        this.setState({
          alert: { ...this.state.alert, name: value }
        })
      }
    })
  }

  render = () => {
    if (this.state.redirectToAlerts) return (
      <Navigate to="/privacy/alerts" />
    )

    if (this.props.isFetchingAlerts) return (
      <div className="privacyAlertCreate__page">
        <div className="privacyAlertCreate__loading"><Loading /></div>
      </div>
    )

    if (this.state.hasError) return (
      <div className="privacyAlertCreate__page">
        <div className="privacyAlertCreate__error">
          <p>This alert does not exist.</p>
        </div>
      </div>
    )

    return (
      <div className="privacyAlertCreate__page">
        <Link className="privacyAlertCreate__link" to="/privacy/alerts">Back to alerts screen</Link>

        <div className="privacyAlertCreate__box">
          <header className="privacyAlertCreate__boxHeader">
            <div>
              <h1 className="privacyAlertCreate__boxTitle">
                <EditInPlace
                  value={ this.state.alert.name }
                  save={ this.renameAlert } />
              </h1>
              <p className="privacyAlertCreate__boxText">
                Adjust your alert settings.
              </p>
            </div>
            <ButtonsArea
              delete={ this.delete }
              entity="Alert"
              isDeleting={ this.state.isDeleting }
              isSaving={ this.state.isSaving }
              save={ this.save } />
          </header>

          <div className="privacyAlertCreate__content">
            <div className="privacyAlertCreate__form">
              <div className="privacyAlertCreate__formFields">
                <div className="privacyAlertCreate__formGroup">
                  <label>Collection</label>
                  <CollectionsList
                    value={ this.state.selectedCollection }
                    onChange={ this.handleInputChange } />
                </div>
              </div>

              <div className="privacyAlertCreate__formFields">
                <div className="privacyAlertCreate__formGroup">
                  <label>Status level</label>
                  <StatusLevel
                    value={ this.state.selectedStatusLevel }
                    onChange={ this.handleInputChange } />
                    
                </div>
              </div>

              <FormDateSpan
                selectedValueFrom={ this.state.selectedValueFrom }
                selectedValueTo={ this.state.selectedValueTo }
                selectedRangeDate={ this.selectedRangeDate } />

              <div className="privacyAlertCreate__formFields">
                <div className="privacyAlertCreate__formGroup">
                  <label>Access control</label>
                  <AccessControl
                    values={ this.state.accessControl }
                    selectedAccessControl={ this.selectedAccessControl } />
                </div>
              </div>

              <div className="privacyAlertCreate__formFields">
                <div className="privacyAlertCreate__formGroup">
                  <label>Object types</label>
                  <AddObjectTypes
                    facetValues={ this.props.facetValues }
                    objectTypes={ this.state.objectTypes }
                    updateObjectTypes={ this.updateObjectTypes } />
                  
                  <button
                    className="privacyAlertCreate__addButton"
                    onClick={ this.addAnother.bind(this, 'objectType') }>
                    Add another parameter
                  </button>
                </div>
              </div>

              <div className="privacyAlertCreate__formFields">
                <div className="privacyAlertCreate__formGroup">
                  <label>PII</label>
                  <AddPii
                    facetValues={ this.props.facetValues }
                    pii={ this.state.pii }
                    updatePii={ this.updatePii } />
                  
                  <button
                    className="privacyAlertCreate__addButton"
                    onClick={ this.addAnother.bind(this, 'pii') }>
                    Add another parameter
                  </button>
                </div>
              </div>

            </div>
            <div className="privacyAlertCreate__scan">
              <div className="privacyAlertCreate__scanHeader">
                <div className="privacyAlertCreate__scanHeaderWrapper">
                  { this.state.isFetchingFolders
                    ? <div className="privacyAlertCreate__loading-container">
                      <Loading />
                    </div>
                    : <AlertInfo
                      alert={ this.state.alert }
                      countFolders={ this.state.countFolders } />
                  }
                </div>
              </div>

              <button 
                className="privacyAlertCreate__addButton"
                onClick={ this.addAnother.bind(this, 'keyword') } >
                Add another filter
              </button>

              <AddKeywords
                keywords={ this.state.keywords }
                updateKeywords={ this.updateKeywords } />
            </div>

          </div>

          <div className="privacyAlertCreate__footer">
            <ButtonsArea
              delete={ this.delete }
              entity="Alert"
              isDeleting={ this.state.isDeleting }
              isSaving={ this.state.isSaving }
              save={ this.save } />
          </div>
        </div>
      </div>
    )
  }
}

AlertCreate.propTypes = {
  alerts: PropTypes.array.isRequired,
  isFetchingAlerts: PropTypes.bool.isRequired,
  match: PropTypes.object,
}

export default compose(
  WithAlerts,
  WithDataModels,
)(AlertCreate)