import React from 'react'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import PropTypes from 'prop-types'

import BoxAddNewDataset from './BoxAddNewDataset'
import ButtonAddCollection from './Form/ButtonAddCollection'
import CollectionList from './Dashboard/CollectionList'
import ConnectorList from './Dashboard/ConnectorList'
import Loading from '../Common/Loading'
import TopicList from './Dashboard/TopicList'

import { sortValuesByField } from '../../utils'

import './Dashboard.css'

import WithCollections from './HOC/WithCollections'
import WithConnectors from './HOC/WithConnectors'
import WithTopics from './HOC/WithTopics'

export class Dashboard extends React.Component {
  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  render = () => {
    return (
      <div className="privacyDashboard__page">
        <div className="privacyDashboard__box">
          <header className="privacyDashboard__boxHeader">
            <div>
              <h1 className="privacyDashboard__boxTitle">
                <Link to="/privacy/collections">Your Data Collections</Link>
              </h1>
              { this.props.totalCollections > 0 &&
                <span className="privacyDashboard__boxTitleTotal">{ this.props.totalCollections } total</span>
              }
              <p className="privacyDashboard__boxText">
                Groups of your data that match against pre-defined keyword lists and file security.
              </p>
            </div>
            <ButtonAddCollection />
          </header>

          { this.props.isFetchingCollections
            ? <div className="privacyDashboard__loading"><Loading /></div>
            : <CollectionList collections={ this.props.collections.slice(0, 4) } />
          }

        </div>

        <div className="privacyDashboard__box">
          <header className="privacyDashboard__boxHeader">
            <div>
              <h1 className="privacyDashboard__boxTitle">
                <Link to="/privacy/topics">Topics</Link>
              </h1>
              { this.props.totalTopics > 0 &&
                <span className="privacyDashboard__boxTitleTotal">{ this.props.totalTopics } total</span>
              }
              <p className="privacyDashboard__boxText">
                Groups of your data that match against patterns found in your objects keywords. 
              </p>
            </div>
          </header>

          { this.props.isFetchingTopics
            ? <div className="privacyDashboard__loading"><Loading /></div>
            : <TopicList topics={ sortValuesByField(this.props.allTopics, 'weight').reverse().slice(0, 4) } />
          }
        </div>

        <BoxAddNewDataset />

        <div className="privacyDashboard__footer">
          <div className="privacyDashboard__boxFooter">
            <header className="privacyDashboard__boxFooterHeader">
              <h1 className="privacyDashboard__boxFooterTitle">
                <Link to="/privacy/connectors/manage">Data Sources</Link>
              </h1>
              <span className="privacyDashboard__boxTitleTotal">
                { this.props.connectors.length } total
              </span>
            </header>
              
            { this.props.isFetchingConnectors
              ? <div className="privacyDashboard__loading"><Loading /></div>
              : <ConnectorList connectors={ this.props.connectors.slice(0, 4) } />
            }
          </div>
        </div>
      </div>
    )
  }
}

Dashboard.propTypes = {
  allTopics: PropTypes.array.isRequired,
  collections: PropTypes.array.isRequired,
  connectors: PropTypes.array.isRequired,
  isFetchingCollections: PropTypes.bool.isRequired,
  isFetchingConnectors: PropTypes.bool.isRequired,
  isFetchingTopics: PropTypes.bool.isRequired,
  totalCollections: PropTypes.number.isRequired,
  totalTopics: PropTypes.number.isRequired,
}

export default compose(
  WithCollections,
  WithConnectors,
  WithTopics
)(Dashboard)