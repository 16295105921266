import React from "react";
import {Input, Modal, Select, Checkbox, Button, DatePicker} from 'antd'
import './NewTicketSettingsModal.scss'
import moment from 'moment'
import {
    apiCheckIfRequesterIsMandatoryTicket,
    apiCreateWorkflowTicket, apiEditWorkflowTicket,
    getExternalApiTokenListId,
    getTicketWorkflowAvailableUserList,
    getWorkflowTemplateList
} from "../../../../../api/SmartBox";
import withRouter from "../../../../../private-routers/withRouter";
import {connect} from "react-redux";
import {setSarPageLimit} from "../../../../../actions/sarActions";
import {DashboardWorkflow} from "./DashboardWorkflow";
import CustomNotificationManager from "../../../../Common/CustomNotificationManager";
const { Option } = Select;
const { TextArea } = Input

class NewTicketSettingsPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            //listOptionTimeFrame: ['30 days', '90 days', 'none'],
            listOptionTimeFrame: [
                // {name: '30 days', nameId: 30},
                // {name: '90 days', nameId: 90},
                {name: 'Time frame', nameId: 1},
                {name: 'No time frame', nameId: 99999},
            ],
            hoverIconDate:false,
            listOptionsTicketOwner:[],
            listOptionsWorkflow:[],
            loadingCancelModal:false,
            loadingOpenTicket:false,
            valueSelectTimeFrame: 1,
            valueSelectTicketOwner:'',
            valueTicketName:'',
            valueSelectWorkflow: '',
            valueFullName:'',
            valueEmail:'',
            valuePhone:'',
            valueRequestDetails:'',
            checkedRequestor:false,
            formNewTickets: {
                valueTicketName:'',
                valueSelectWorkflow:'',
                valueFullName:'',
                valueEmail:'',
                valuePhone:'',
                valueRequestDetails:'',
                checkedRequestor:false,
                valueSelectTimeFrame: 1,
                valueSelectTicketOwner:'',
                dateString: null,
            },
            statusOpenTicket: false,
            datePickerIsOpen: false,
            dateString: null,
            newDateString: null,
            rangePickerIsOpen: false,
            dateSelect: [],
            dateSelectNew: [],
            datePickerSelect: null,
            dateTime: [],
            dateDays: 0,
            startDates: null,
            endDates: null,
            checkedRequestorDetails: false
        }
    }

    componentDidMount() {
        console.log('25Iul')
        this.onGetWorkflowTemplateList()
        this.onGetTicketWorkflowAvailableUserList()
        this.onCheckIfRequesterIsMandatoryTicket()
        if (this.props.statusModalNewTickets === 'edit') {
            if (Object.keys(this.props.selectEditTickets) && Object.keys(this.props.selectEditTickets).length > 0) {
                const dateTime = []
                let diff = null
                if (this.props.selectEditTickets.ticketTimeFrame !== 99999 && this.props.selectEditTickets.creationDate && this.props.selectEditTickets.ticketClosingDate) {
                    dateTime.push(this.props.selectEditTickets.creationDate)
                    dateTime.push(this.props.selectEditTickets.ticketClosingDate)
                    const start = moment(this.props.selectEditTickets.creationDate)
                    const end = moment(this.props.selectEditTickets.ticketClosingDate)
                    diff = end.diff(start, 'days')
                    this.setState({
                        startDates: this.props.selectEditTickets.creationDate,
                        endDates: this.props.selectEditTickets.ticketClosingDate
                    })
                }
                this.setState({
                    valueSelectTicketOwner: this.props.selectEditTickets.ownerId,
                    valueTicketName: this.props.selectEditTickets.ticketName,
                    valueSelectWorkflow: this.props.selectEditTickets.workflowId,
                    checkedRequestor: this.props.selectEditTickets.ticketRequestorActive,
                    valueFullName: this.props.selectEditTickets.ticketRequestorName,
                    valueEmail: this.props.selectEditTickets.ticketRequestorEmail,
                    valuePhone: this.props.selectEditTickets.ticketRequestorPhone,
                    valueRequestDetails: this.props.selectEditTickets.ticketRequestorDetails,
                    valueSelectTimeFrame: this.props.selectEditTickets.ticketTimeFrame === 99999 ? 99999 : 1,
                    ticketId: this.props.selectEditTickets.ticketId,
                    dateTime: dateTime,
                    dateSelect: dateTime,
                    dateDays: diff,
                    formNewTickets: {...this.state.formNewTickets,
                        valueSelectTicketOwner: this.props.selectEditTickets.ownerId,
                        valueTicketName: this.props.selectEditTickets.ticketName,
                        valueSelectWorkflow: this.props.selectEditTickets.workflowId,
                        checkedRequestor: this.props.selectEditTickets.ticketRequestorActive,
                        valueFullName: this.props.selectEditTickets.ticketRequestorName,
                        valueEmail: this.props.selectEditTickets.ticketRequestorEmail,
                        valuePhone: this.props.selectEditTickets.ticketRequestorPhone,
                        valueRequestDetails: this.props.selectEditTickets.ticketRequestorDetails,
                        valueSelectTimeFrame: this.props.selectEditTickets.ticketTimeFrame === 99999 ? 99999 : 1,
                        ticketId: this.props.selectEditTickets.ticketId,
                        dateTime: dateTime,
                        dateSelect: dateTime,
                        dateDays: diff,
                    }
                })
            }
        }
    }

    onCheckIfRequesterIsMandatoryTicket = () => {
        if (this.props.statusModalNewTickets === 'edit') {
            apiCheckIfRequesterIsMandatoryTicket(this.props.selectEditTickets.workflowId).then((response) => {
                this.setState({
                    checkedRequestor: response.data,
                    checkedRequestorDetails: response.data,
                    formNewTickets: {...this.state.formNewTickets, checkedRequestor: response.data}
                })
            })
        }
    }
    onGetWorkflowTemplateList = () => {
        getWorkflowTemplateList(this.props.clientId).then((response) => {
            this.setState({
                listOptionsWorkflow: response.data
            })
        })
    }

    onGetTicketWorkflowAvailableUserList = () => {
        getTicketWorkflowAvailableUserList().then((response) => {
            this.setState({
                listOptionsTicketOwner: response.data
            })
        })
    }
    handleCloseModal = () => {
        if (this.props.onCloseNewTickets) {
            this.props.onCloseNewTickets(false)
        }
        if (this.props.displayTicketCreationForm) {
            this.props.displayTicketCreationForm(false)
        }
    }

    handleCancelModal = () => {
        if (this.props.onCloseNewTickets) {
            this.props.onCloseNewTickets(false)
        }
        this.setState({
            loadingCancelModal: true,
        }, () => {
            if (this.props.displayTicketCreationForm) {
                this.props.displayTicketCreationForm(false)
            }
            this.setState({
                loadingCancelModal: false
            })
        })

    }

    onHoverIconDate = () => {
        this.setState({
            hoverIconDate:true,
        })
    }
    onLeaveIconDate = () => {
        this.setState({
            hoverIconDate:false,
        })
    }
    onCheckIfRequesterIsMandatoryTicketSelect = (workflowId) => {
            apiCheckIfRequesterIsMandatoryTicket(workflowId).then((response) => {
                this.setState({
                    checkedRequestor: response.data,
                    checkedRequestorDetails: response.data,
                    formNewTickets: {...this.state.formNewTickets, checkedRequestor: response.data}
                })
            })
    }
    onChangeSelect = (e, arg) => {
        switch (arg) {
            case 'timeFrame' :
                this.setState({
                    valueSelectTimeFrame: e,
                    formNewTickets: {...this.state.formNewTickets, valueSelectTimeFrame: e}
                })
                break
            case 'ticketOwner':
                this.setState({
                    valueSelectTicketOwner: e,
                    formNewTickets: {...this.state.formNewTickets, valueSelectTicketOwner: e}
                })
                break
            case 'workflow' :
                this.setState({
                    valueSelectWorkflow: e,
                    formNewTickets: {...this.state.formNewTickets, valueSelectWorkflow: e}
                })
                this.onCheckIfRequesterIsMandatoryTicketSelect(e)
                break
            default:
                break
        }
    }

    onChangeInput = (e, arg,) => {
        switch (arg) {
            case 'ticketName':
                this.setState({
                    valueTicketName: e.target.value,
                    formNewTickets: {...this.state.formNewTickets, valueTicketName: e.target.value}
                })
                break
            case 'fullName':
                this.setState({
                    valueFullName: e.target.value,
                    formNewTickets: {...this.state.formNewTickets, valueFullName: e.target.value}
                })
                break
            case 'email':
                this.setState({
                    valueEmail: e.target.value,
                    formNewTickets: {...this.state.formNewTickets, valueEmail: e.target.value}
                })
                break
            case 'phone':
                this.setState({
                    valuePhone: e.target.value,
                    formNewTickets: {...this.state.formNewTickets, valuePhone: e.target.value}
                })
                break
            case 'details':
                this.setState({
                    valueRequestDetails: e.target.value,
                    formNewTickets: {...this.state.formNewTickets, valueRequestDetails: e.target.value}
                })
                break
            default:
                break
        }
    }

    toggleChecked = (e) => {
        const { checkedRequestorDetails } = this.state
        if (!checkedRequestorDetails) {
            this.setState({
                checkedRequestor: e.target.checked,
                formNewTickets: {...this.state.formNewTickets, checkedRequestor: e.target.checked}
            })
        }
    }
    toggleCheckedDisabled = (e) => {
        return true
    }

    onOpenWorkflowTicket = () => {
        const { statusModalNewTickets, selectEditTickets } = this.props
        const { formNewTickets, statusOpenTicket, dateDays, valueSelectTimeFrame  } = this.state
        if (statusModalNewTickets === 'new') {
            const createWorkflowTicketDTO = {
                ticketName: formNewTickets.valueTicketName,
                ticketRequestorActive: formNewTickets.checkedRequestor,
                //ticketTimeFrame: formNewTickets.valueSelectTimeFrame,
                ticketTimeFrame: valueSelectTimeFrame === 99999 ? 99999 : dateDays,
                ownerId: formNewTickets.valueSelectTicketOwner,
                workflowId: formNewTickets.valueSelectWorkflow,
                ticketRequestorName: formNewTickets.valueFullName,
                ticketRequestorEmail: formNewTickets.valueEmail,
                ticketRequestorPhone: formNewTickets.valuePhone,
                ticketRequestorDetails: formNewTickets.valueRequestDetails,
                creationDate: formNewTickets.newDateString ? formNewTickets.newDateString : selectEditTickets && selectEditTickets.creationDate ? selectEditTickets.creationDate : moment().format('YYYY.MM.DD') ? moment().format('YYYY.MM.DD') : null,
            }
            this.setState({
                statusOpenTicket: true,
                loadingOpenTicket: true,
            }, () => {
                apiCreateWorkflowTicket(createWorkflowTicketDTO).then((response) => {
                    if (this.props.status === 'add') {
                        this.props.onGetTicketWorkflowListDashboard()
                        this.props.onCloseNewTickets(false)
                    }
                    if (response.data.title && response.data.title.toLowerCase() === 'success') {
                        CustomNotificationManager.success(response.data.message, 'Success')
                    } else if (response.data.title && response.data.title.toLowerCase() === 'info') {
                        CustomNotificationManager.info(response.data.message, 'Info')
                    }
                    this.setState({
                        statusOpenTicket: false,
                        loadingOpenTicket: false,
                    })
                }).catch((error) => {
                    if (error?.response?.status === 400) {
                        CustomNotificationManager.cleanBuffer('warning', '')
                    }else{
                        console.log(error)
                    }
                    this.setState({
                        loadingOpenTicket: false
                    })
                })
            })
        } else if (statusModalNewTickets === 'edit') {
            const createWorkflowTicketDTO = {
                ticketId: this.props.selectEditTickets.ticketId,
                ticketName: formNewTickets.valueTicketName,
                ticketRequestorActive: formNewTickets.checkedRequestor,
                ticketTimeFrame: valueSelectTimeFrame === 99999 ? 99999 : dateDays,
                ownerId: formNewTickets.valueSelectTicketOwner,
                workflowId: formNewTickets.valueSelectWorkflow,
                ticketRequestorName: formNewTickets.valueFullName,
                ticketRequestorEmail: formNewTickets.valueEmail,
                ticketRequestorPhone: formNewTickets.valuePhone,
                ticketRequestorDetails: formNewTickets.valueRequestDetails,
                creationDate: formNewTickets.newDateString ? formNewTickets.newDateString : selectEditTickets && selectEditTickets.creationDate ? selectEditTickets.creationDate : null,
            }
            this.setState({
                statusOpenTicket: true
            }, () => {
                apiEditWorkflowTicket(createWorkflowTicketDTO).then((response) => {
                    if (this.props.status === 'add') {
                        this.props.onGetTicketWorkflowListDashboard()
                        this.props.onCloseNewTickets(false)
                    }
                    if (response.data.title && response.data.title.toLowerCase() === 'success') {
                        CustomNotificationManager.success(response.data.message, 'Success')
                    } else if (response.data.title && response.data.title.toLowerCase() === 'info') {
                        CustomNotificationManager.info(response.data.message, 'Info')
                    }
                    this.setState({
                        statusOpenTicket: false
                    })
                }).catch((error) => {
                    if (error?.response?.status === 400) {
                        CustomNotificationManager.cleanBuffer('warning', '')
                    }else{
                        console.log(error)
                    }
                })
            })
        }
    }
    renderDatePicker = () => {
        this.setState({
            datePickerIsOpen: !this.state.datePickerIsOpen,
        });
    }
    renderRangePicker = () => {
        this.setState({
            rangePickerIsOpen: !this.state.rangePickerIsOpen,
        });
    }
    onChangeDatePicker = (date, dateString) =>  {
        let newDateString = null
        const [year, month, day] = dateString.split('-')
        newDateString = `${year}.${month}.${day}`
        let newFormatDate = `${day}.${month}.${year}`
        this.setState({
            datePickerIsOpen: !this.state.datePickerIsOpen,
            dateString: dateString,
            newDateString: newDateString,
            datePickerSelect: newFormatDate,
            formNewTickets: {...this.state.formNewTickets, dateString: dateString, newDateString: newDateString }
        });
    }
    onSearchTicketOwner = (e) => {
        const {listOptionsTicketOwner} = this.state

        if (e !== '') {
             let newList = listOptionsTicketOwner.filter( item => item.name.toLowerCase().includes(e.toLowerCase()))
            this.setState({
                listOptionsTicketOwner: newList
            })
        } else {
           this.onGetTicketWorkflowAvailableUserList()
        }
    }
    selectDate = (date, dateString) => {
        const { dataSources } = this.state
        let newDateString = null
        let diff = 0
        if (dateString && dateString.length > 0) {
            if (moment(dateString[0]) > moment()) {
                dateString[0] = moment().format('YYYY-MM-DD')
                const start = moment().format('YYYY-MM-DD')
                const end = moment(dateString[1])
                diff = end.diff(start, 'days')
                const [year, month, day] = dateString[0].split('-')
                newDateString = `${year}.${month}.${day}`
            } else {
                const start = moment(dateString[0])
                const end = moment(dateString[1])
                diff = end.diff(start, 'days')
                const [year, month, day] = dateString[0].split('-')
                newDateString = `${year}.${month}.${day}`
            }

            this.setState({
                startDates: dateString[0],
                endDates: dateString[1]
            })
        }
        this.setState({
            rangePickerIsOpen: !this.state.rangePickerIsOpen,
            dateSelect: dateString,
            //dateSelectNew: newDateString,
            //dateTime: date,
            dateDays: diff,
            dateString: dateString && dateString.length > 0 ? dateString[0] : null,
            newDateString: newDateString,
            formNewTickets: {...this.state.formNewTickets, dateString: dateString && dateString.length > 0 ? dateString[0] : null, newDateString: newDateString }
        });
    }
    onRenderExtraFooter = () => {
        const { dateDays, startDates, endDates } = this.state
        return (
            <div className="render-extra-footer">
                <span className="time-frame">Time frame:</span>
                <span className="content-total-days">
                    <span className={startDates && endDates ? "remove-day" : 'remove-day-disabled' }
                    onClick={() => this.onCLickRemoveDays()}>{'<'}</span>
                    <span className="total-days">{ dateDays === null ? '0 days' : `${dateDays} days`}</span>
                    <span className="add-day"
                          onClick={() => this.onCLickAddDays(dateDays)}
                    >{'>'}</span>
                </span>
            </div>
        )
    }
    onCLickAddDays = (day) => {
        const { startDates, endDates } = this.state
        const dateSelectUpdate = []
        let diff = 0
        if (startDates && endDates) {
            const days = day + 1
            let endDatesUpdate = moment(endDates)
            const endDatesUpdate111 = moment(endDates).add(1, 'days').format('YYYY.MM.DD')
            const date1 = new Date(endDatesUpdate)
            this.setState({
                //dateDays: day + 1
            }, () => {
                endDatesUpdate = moment(endDates).add(1, 'days').format('YYYY-MM-DD')
                dateSelectUpdate.push(startDates)
                dateSelectUpdate.push(endDatesUpdate)
                const start = moment(dateSelectUpdate[0])
                const end = moment(dateSelectUpdate[1])
                diff = end.diff(start, 'days')
                this.setState({
                    endDates: endDatesUpdate,
                    dateSelect: dateSelectUpdate,
                    dateTime: dateSelectUpdate,
                    dateDays: diff,
                })
            })
        } else {
             this.setState({
                 startDates: moment().format('YYYY-MM-DD'),
                 endDates: moment().format('YYYY-MM-DD'),
             })
        }
    }

    onCLickRemoveDays = (day) => {
        const { startDates, endDates } = this.state
        const dateSelectUpdate = []
        let diff = 0
        if (startDates && endDates) {
            const days = day + 1
            let endDatesUpdate = moment(endDates)
            this.setState({
                //dateDays: day + 1
            }, () => {
                if (moment(startDates) < moment(endDates)) {
                    endDatesUpdate = moment(endDates).subtract(1, 'days').format('YYYY-MM-DD')
                    dateSelectUpdate.push(startDates)
                    dateSelectUpdate.push(endDatesUpdate)
                    const start = moment(dateSelectUpdate[0])
                    const end = moment(dateSelectUpdate[1])
                    diff = end.diff(start, 'days')
                    this.setState({
                        endDates: endDatesUpdate,
                        dateSelect: dateSelectUpdate,
                        dateTime: dateSelectUpdate,
                        dateDays: diff,
                    })
                }
            })
        }
    }
    render () {
        const {listOptionTimeFrame, hoverIconDate, listOptionsTicketOwner, listOptionsWorkflow, loadingCancelModal, loadingOpenTicket, valueSelectTicketOwner,
            valueTicketName, valueSelectWorkflow, valueFullName, valueEmail, valueRequestDetails, checkedRequestor, valueSelectTimeFrame,formNewTickets,
            statusOpenTicket, dateString, newDateString, dateSelect, dateDays, startDates, endDates, dateTime, valuePhone, checkedRequestorDetails } = this.state
        const { statusModalNewTickets, selectEditTickets } = this.props
        const { RangePicker } = DatePicker;
        let date = ''
        if (selectEditTickets && selectEditTickets.creationDate) {
            const [year, month, day] = selectEditTickets.creationDate.split('.')
            date = `${day}.${month}.${year}`
        } else {
             date = moment().format('DD.MM.YYYY')
        }
        const dateFormat = 'DD.MM.YYYY';
        console.log('valueSelectTimeFrame --- RENDER', valueSelectTimeFrame)
        console.log('listOptionTimeFrame --- RENDER', listOptionTimeFrame)
        return (
            <div className='ticket-log-page'>
                <div className="ticket-log-page-content">
                    <div className="ticket-log-page-header">
                            <span className='header-content-ticket-log-modal'>
                             <span className="header-title">Ticket log</span>
                             <span className="icon-v14-close-black" onClick={() => this.handleCloseModal()}></span>
                       </span>
                    </div>
                    <div className='ticket-log-page-contain'>
                    <div className='ticket-log-modal-body-content-headline'>
                        <div className='process-time'>
                            {/*<span className='modal-content-headlines update'>Time frame:</span>*/}
                            <Select
                                value={valueSelectTimeFrame === 1 ? 'Time frame *' : valueSelectTimeFrame}
                                onChange={(e) => this.onChangeSelect(e, 'timeFrame')}
                                dropdownClassName={'select-per-page-tickets'}
                            >
                                { listOptionTimeFrame && listOptionTimeFrame.length > 0 && listOptionTimeFrame.map((item, index) => {
                                    return (<Option className="options-select-connector" value={item.nameId} key={index}>
                                        {item.name}
                                    </Option>)
                                })}
                            </Select>
                        </div>
                        { valueSelectTimeFrame === 99999 ?
                            <div className='date-request'>
                                <div className={'opening-date'}>
                                <span className={hoverIconDate ? 'icon-v211-date-active' : 'icon-v211-date'}
                                      onMouseEnter={() => this.onHoverIconDate()}
                                      onMouseLeave={() => this.onLeaveIconDate()}
                                      onClick={() => this.renderDatePicker()}
                                ></span>
                                    <span className='modal-content-headlines'>Opening: </span>
                                    {/*<span className='modal-content-body-data'>{date}</span>*/}
                                    { this.state.datePickerIsOpen &&
                                        <DatePicker open={this.state.datePickerIsOpen}
                                                    onChange={this.onChangeDatePicker}
                                                    className={'date-picker-ticket'}
                                                    dropdownClassName={'opening-date-ticket'}
                                                    //format={"DD.MM.YYYY"}
                                                    defaultValue={moment(`${date}`, dateFormat)}
                                        />
                                    }
                                    <span className='selected-opening-date'>{ this.state.datePickerSelect && this.state.datePickerSelect !== null ? this.state.datePickerSelect : selectEditTickets.creationDate ? date : moment().format('DD.MM.YYYY')}</span>
                                </div>

                            </div> :
                            <div className='date-request date-request-range-picker'>
                                <div className={'opening-date'}>
                                <span className={hoverIconDate ? 'icon-v211-date-active' : 'icon-v211-date'}
                                      onMouseEnter={() => this.onHoverIconDate()}
                                      onMouseLeave={() => this.onLeaveIconDate()}
                                      onClick={() => this.renderRangePicker()}
                                ></span>
                                    { this.state.rangePickerIsOpen &&
                                    <RangePicker onChange={(date, dateString) => this.selectDate(date, dateString)}
                                                 open={this.state.rangePickerIsOpen}
                                                 className='date-picker date-picker-new-tickets'
                                                 format="YYYY-MM-DD"
                                                 value={startDates && endDates ? [moment(startDates), moment(endDates)] : []}
                                        //value={form1.startDates && form1.endDates ? [moment(form1.startDates), moment(form1.endDates)] : []}
                                                 renderExtraFooter={() => this.onRenderExtraFooter()}
                                                 dropdownClassName="date-picker-tickets"/>
                                    }
                                    <div className={dateSelect.length === 0 ? "content-range-picker-empty content-range-picker": 'content-range-picker content-range-picker-full' }>
                                        <span className="opening-date-contain">
                                             <span className='content-headlines-date content-headlines-date-start'>Opening: </span>
                                            <span className="start-date-empty">{dateSelect && dateSelect.length > 0 ? `${moment(dateSelect[0]).format('DD.MM.YYYY')}` : `${moment().format('DD.MM.YYYY')}` }</span>
                                        </span>
                                        <span className="space-date">{'>'}</span>
                                        <span className="opening-date-contain">
                                             <span className='content-headlines-date'>Closing: </span>
                                            <span className="start-date">{dateSelect && dateSelect.length > 0 ? `${moment(dateSelect[1]).format('DD.MM.YYYY')}` : 'End date' }</span>
                                        </span>
                                        <span className="space-date">{'='}</span>
                                        <span className="number-days-empty">{ dateDays === null ? '0 days' : `${dateDays} days`}</span>
                                    </div>
                                </div>

                            </div>
                        }
                    </div>
                    <div className='wrapper-form-fields'>
                        <span className='modal-content-headlines'>Ticket owner*</span>
                        <Select
                            showSearch
                            showArrow={false}
                            filterOption={false}
                            value={formNewTickets.valueSelectTicketOwner ? formNewTickets.valueSelectTicketOwner : 'Select'}
                            placeholder="Select"
                            className='select-form-fields'
                            dropdownStyle={{border:'1px solid #eda41e'}}
                            onChange={(e) => this.onChangeSelect(e, 'ticketOwner')}
                            onSearch={ (e) => this.onSearchTicketOwner(e)}
                        >
                            { listOptionsTicketOwner && listOptionsTicketOwner.length > 0 && listOptionsTicketOwner.map((item, index) => {
                                return (<Option className="options-select-connector" value={item.value} key={index}>
                                    {item.name ? `${item.name} | ${item.label}` : item.label}
                                </Option>)
                            })}
                        </Select>
                    </div>
                    <div className='wrapper-form-fields'>
                        <span className='modal-content-headlines'>Ticket name*</span>
                        <Input
                            className='text-form-fields'
                            value={valueTicketName}
                            onChange={(e) => this.onChangeInput(e, 'ticketName')}
                        />
                    </div>
                    <div className='wrapper-form-fields'>
                        <span className='modal-content-headlines'>Workflow*</span>
                        <Select
                            value={valueSelectWorkflow ? valueSelectWorkflow : 'Select'}
                            placeholder="Select"
                            className='select-form-fields'
                            dropdownStyle={{border:'1px solid #eda41e'}}
                            onChange={(e) => this.onChangeSelect(e, 'workflow')}
                        >
                            { listOptionsWorkflow && listOptionsWorkflow.length > 0 && listOptionsWorkflow.map((item, index) => {
                                return (<Option className="options-select-connector" value={item.workflowId} key={index}>
                                    {item.workflowName}
                                </Option>)
                            })}
                        </Select>
                    </div>
                    <div className='wrapper-form-fields wrapper-form-fields-with-checkbox'>
                        <span className="header-checkbox">
                            <Checkbox
                                checked={checkedRequestor}
                                disabled={ checkedRequestorDetails === true }
                                onChange={checkedRequestorDetails ? (e) => this.toggleCheckedDisabled(e) : (e) => this.toggleChecked(e)}
                                className='modal-content-headlines'>
                                Requester details
                            </Checkbox>
                            </span>
                    </div>
                    <div className='wrapper-form-fields'>
                        <span className={checkedRequestor === false ? 'modal-content-headlines modal-content-headlines-disabled' : 'modal-content-headlines' }>Full name*</span>
                        <Input
                            className='text-form-fields'
                            value={formNewTickets.valueFullName}
                            disabled={ checkedRequestor === false}
                            onChange={(e) => this.onChangeInput(e, 'fullName')}
                        />
                    </div>
                        <div className="wrapper-form-fields-two">
                    <div className='wrapper-form-fields'>
                        <span className={checkedRequestor === false ? 'modal-content-headlines modal-content-headlines-disabled' : 'modal-content-headlines' }>Email address*</span>
                        <Input className='text-form-fields'
                               disabled={ checkedRequestor === false}
                               value={formNewTickets.valueEmail}
                               onChange={(e) => this.onChangeInput(e,'email')}
                        />
                    </div>
                        <div className='wrapper-form-fields wrapper-form-fields-phone'>
                            <span className={checkedRequestor === false ? 'modal-content-headlines modal-content-headlines-disabled' : 'modal-content-headlines' }>Phone number*</span>
                            <Input className='text-form-fields'
                                   disabled={ checkedRequestor === false}
                                   value={formNewTickets.valuePhone}
                                   onChange={(e) => this.onChangeInput(e,'phone')}
                            />
                        </div>
                        </div>
                    <div className='wrapper-form-fields'>
                        <span className={checkedRequestor === false ? 'modal-content-headlines modal-content-headlines-disabled' : 'modal-content-headlines' }>Request details*</span>
                        <TextArea
                            className='request-details'
                            rows={4}
                            disabled={ checkedRequestor === false}
                            value={formNewTickets.valueRequestDetails}
                            onChange={(e) => this.onChangeInput(e, 'details') }
                        />
                    </div>
                    <div className='ticket-log-modal-footer'>
                        <Button
                            onClick={() => this.handleCancelModal()}
                            loading={loadingCancelModal}
                            className={loadingCancelModal ? 'button-cancel-action loading' : 'button-cancel-action'}>Cancel
                        </Button>
                        <Button
                            disabled={(valueSelectTimeFrame !== 99999 && dateSelect.length === 0) || statusOpenTicket === true || valueSelectTicketOwner === '' || valueTicketName === '' || valueSelectWorkflow === ''
                            || (checkedRequestor === true && (!formNewTickets.valueFullName || !formNewTickets.valueEmail || !formNewTickets.valuePhone || !formNewTickets.valueRequestDetails))}
                            loading={loadingOpenTicket}
                            onClick={ () => this.onOpenWorkflowTicket() }
                            className={loadingOpenTicket ? 'button-open-ticket-action button-loading' :'button-open-ticket-action'}>
                            {statusModalNewTickets === 'edit' ? 'Okay' : 'Open'}</Button>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}

NewTicketSettingsPage = withRouter(NewTicketSettingsPage)

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id,
    sarPageLimit: state.sar.sarPageLimit
})

export default connect(mapStateToProps,{
})(NewTicketSettingsPage)