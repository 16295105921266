import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import '../ExternalFeedbackWorkflowTicketForm.scss'
import {Input, Select, Steps} from "antd";
import {NameTemplateFormExternal} from "./NameTemplateFormExternal";
const { Option } = Select;
const { Step } = Steps;

export class HeaderTemplateFormExternal extends Component {

    state = {

    }
    onChangeNameAndDetails = (e, key) => {
        switch (key) {
            case 'email':
                this.props.onChangeNameAndDetails(e, key)
                break
            default:
                break
        }
    }
    render() {
        const { formerEmployee, component } = this.props
        return (
            <div className="full-name-former full-name-former-update">
                <span className="header-former header-former-address header-former-name">{component.header ? component.header : 'Header'}</span>
                <span className="subheader-title">{component.subheader ? component.subheader : 'Subheader'}</span>
            </div>
        )
    }
}


export default connect()(HeaderTemplateFormExternal)