import React from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import Button from '../../Common/Button'
import Loading from '../../Common/Loading'

import './ButtonsArea.css'

class ButtonsArea extends React.Component {
  showConfirmation = () => {
    swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      html: `<p style="font-size: 14px; margin: 10px 0; text-align: center;">Are you sure that you want to delete this ${ this.props.entity.toLowerCase() }?</p>`,
      title: `Delete ${ this.props.entity }`,
      type: 'question',
      padding: 50
    }).then((result) => {
      if (result.value) this.props.delete()
    })
  }

  render = () => {
    return (
      <div className="buttonsArea__wrapper">
        { (this.props.isSaving || this.props.isDeleting)
          ? <Loading />
          : <div>
            <Button theme="red" onClick={ this.showConfirmation }>
              Delete { this.props.entity }
            </Button>
            <Button theme="green" onClick={ this.props.save }>
              Save { this.props.entity }
            </Button>
          </div>
        }
      </div>
    )
  }
}

ButtonsArea.propTypes = {
  delete: PropTypes.func.isRequired,
  entity: PropTypes.string.isRequired,
  save: PropTypes.func.isRequired
}

export default ButtonsArea