import React from 'react'
import PropTypes from 'prop-types'
import '../YN.css'

class Active extends React.Component {
  constructor() {
    super()
    this.handleKey = this.handleKey.bind(this)
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKey)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKey)
  }

  handleKey(e) {
    const pressedKey = e.keyCode

    // Only keys 1, 2 and 3.
    if (pressedKey >= 49 && pressedKey <= 51) {
      const values = {
        49: 1,
        50: 2,
        51: 3
      }
      const selectedOption = this.props.question.options.filter((item) => item.order === values[pressedKey])

      // We need this because number 3 is for the option "I don't know" and there aren't
      // so many questions with this.
      if (selectedOption.length > 0) {
        this.props.onChange(selectedOption[0].title, selectedOption[0])
      }
    }
  }

  getClassName(value) {
    const classes = {
      'yes': 'yes',
      'no': 'no',
      'i don\'t know': 'idontknow'
    }
    return `questionTypeYN-${ classes[value.toLocaleLowerCase()] }`
  }

  getClassNameChecked(option) {
    const classes = {
      'yes': 'labelYes',
      'no': 'labelNo',
      'i don\'t know': 'labelIdontknow'
    }
    const value = classes[option.title.toLocaleLowerCase()]

    return option.isSelected ? `questionTypeYN-${ value }Checked` : `questionTypeYN-${ value }`
  }

  onChange(option, e) {
    this.props.onChange(e.target.value, option)
  }

  render() {
    const { question, responseText, showResponse } = this.props

    return (
      <div>
        <div className="questionTypeYN-group">
          { question.options.map((option) => (
            <div
              className={ this.getClassName(option.title) }
              key={ option.id }>
              <input
                checked={ option.isSelected }
                id={ option.id }
                name="answer"
                type="radio"
                value={ option.title }
                onChange={ this.onChange.bind(this, option) } />
              <label
                className={ this.getClassNameChecked(option) }
                htmlFor={ option.id }>
                { option.title }
              </label>
            </div>  
          ))
          }
        </div>
    
        { showResponse &&
          <Response text={ responseText } />
        }
      </div>
    )
  }
}

const Response = ({ text }) => (
  <div>
    <h2 className="questionTypeYN-messageTitle">Response</h2>
    <p className="questionTypeYN-messageText">{ text }</p>
  </div>
)

Active.propTypes = {
  onChange: PropTypes.func,
  question: PropTypes.object.isRequired,
  responseText: PropTypes.string,
  showResponse: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
}

export default Active
