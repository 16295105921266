import React from 'react'
import { Select, Modal, Table, Button, Checkbox } from 'antd'
import UserManagementTable from './UserManagementTable'
import { apiFetchUsers, getClientDataList, getClientNames } from '../../../api/Admin'
import Spin from 'antd/es/spin'
import Icon from 'antd/es/icon'
import { emailRegex, getErrorMessage } from '../../../utils'
import CustomNotificationManager from '../../Common/CustomNotificationManager'
import { apiUserInvite } from '../../../api/User'
import { connect } from 'react-redux'
import moment from 'moment'

const Option = Select.Option
const { Column } = Table

class UsersManagement extends React.Component {
  state = {
    filter: {
      name: '',
      status: null,
      pageNumber: 1,
      amountPerPage: 10,
      role: null,
      clientId: 0,
      sortBy: null,
      sortOrder: null
    },
    fetching: false,
    clientNames: null,
    classNameFind: 'page-filter__header--filter-container__filter--search-inactive',
    classNameFindFocus: 'page-filter__header--filter-container__filter--search-active',
    usersList: null,
    filteredOptions: [],
    selectedClient: '',
    isSending: false,
    validEmail: false,
    visibleModalTenant: false,
    companyList: [],
    selectedCompanyRow: {},
    disabledSelect: false,
    assignNewTenant: false

  }

  componentDidMount() {
    this.fetchUserData()
    this.fetchClientNames()
    this.fetchClientDataList()
  }
  fetchClientDataList = () => {
    getClientDataList().then((response) => {
      this.setState({
        companyList: response.data
      })
    })
  }
  fetchUserData() {
    const { filter } = this.state
    const data = {
      amountPerPage: filter.amountPerPage,
      //clientId: filter.clientId ? filter.clientId : this.props.clientId,
      name: filter.name,
      pageNumber: filter.pageNumber,
      role: filter.role,
      status: filter.status,
      sortBy: filter.sortBy,
      sortOrder: filter.sortOrder,
      clientIdTenant: filter.clientId ? filter.clientId : this.props.clientId,
    }
    apiFetchUsers(data).then((res) => {
      this.setState({
        usersList: res.data.userlist,
        totalElements: res.data.totalElements
      })
      this.setState(
        { state: this.state }
      )
    })

  }

  async fetchClientNames() {
    await getClientNames().then((response) => {
      this.setState({
        clientNames: response.data,
        filteredOptions: response.data
      })
    })
  }

  toggleModal = (e, visible) => {
    this.setState({
      modalVisible: visible,
    })
  };

  handleOk = (e) => {
    this.setState({
      modalVisible: false,
    })
  }

  handleCancel = (e) => {
    this.setState({
      modalVisible: false,
    })
  }

  updateValue(e) {
    const validEmail = emailRegex.test(e.target.value.trim())
    this.setState({
      emailAddress: e.target.value.trim(),
      validEmail
    })
  }

  saveChanges(e) {
    e.preventDefault()
    if (!this.isFormValid()) return
    this.setState({
      isSending: true
    }, () => this.inviteUser())
  }

  isFormValid() {
    const { emailAddress } = this.state
    if (!emailRegex.test(emailAddress)) {
      CustomNotificationManager.error('Your email does not have a valid format.', 'Error')

      return false
    }
    return true
  }

  inviteUser = () => {
    //const currentUserEmailDomain = this.props.user.email.split('@')[1]
    const { emailAddress, selectedCompanyRow, assignNewTenant } = this.state
    //const messageErrors = `You cannot invite a user whose email is on other domain. Please try an e-mail address with ending like @${ currentUserEmailDomain }`
    const data = { email: emailAddress, client_id: selectedCompanyRow.key, assignNewTenant: assignNewTenant }
    // if (emailAddress.split('@')[1] === currentUserEmailDomain) {
    apiUserInvite(data).then(() => {
      this.setState({
        isSending: false
      })
      this.toggleModal(false)
      CustomNotificationManager.success('Your invite has been sent. Why not invite someone else?', 'Invited')
    }).catch((e) => {
      const errorMessage = getErrorMessage(e) ? getErrorMessage(e) : e.response.data.error_description
      let errorTitle = 'Error'
      let errorText= ''
      if (errorMessage[0].trim() === 'Insufficient Access Rights') {
        const currentUserEmail = this.props.user.email
        const currentUserDomain = currentUserEmail.split('@')[1]
        errorText= `Please insert an email with same domain as yours ( @${ currentUserDomain } )`
        CustomNotificationManager.error(errorText, errorTitle)
      }
      if (errorMessage[1].trim() === 'User with this email already exist') {
        errorTitle= 'User already exist'
        errorText= errorMessage[1].trim()
        CustomNotificationManager.error(errorText, errorTitle)
      }
      if (!errorMessage.includes('server error')) {
        CustomNotificationManager.error(errorMessage, errorTitle)
      }
      this.setState({
        isSending: false
      })
    })
    // } else {
    //   CustomNotificationManager.error(messageErrors)
    //   this.setState({
    //     isSending: false
    //   })
    // }
  }

  updateFilters(e, type) {
    switch (type) {
      case 'name':
        this.setState({ filter: { ...this.state.filter, name: e.target.value } }, () => {
          this.fetchUserData()
        })
        break
      case 'status':
        this.setState({ filter: { ...this.state.filter, status: e } }, () => {
          this.fetchUserData()
        })
        break
      case 'clientId':
        this.setState({ filter: { ...this.state.filter, clientId: e } }, () => {
          this.fetchUserData()
        })
        break
      case 'role':
        this.setState({ filter: { ...this.state.filter, role: e } }, () => {
          this.fetchUserData()
        })
        break
      default :
        return
    }

  }

  onFilter = (value) => {
    const filteredOptions = value.length > 0 ?
      this.state.clientNames
        .filter((clients) =>
          clients.companyName
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()))
      : this.state.clientNames

    this.setState({
      filteredOptions: filteredOptions,
      fetching: true
    })
  }

  handleClientSearch = (value) => {
    this.setState({
      fetching: false,
    })
    this.onFilter(value)
  };
  changePage = (page) => {
    this.setState({ filter: { ...this.state.filter, pageNumber: page } },
      () => this.fetchUserData(this.state.filter))
  }
  onChangeCompany = (e) => {
    e.preventDefault()
    this.setState({
      visibleModalTenant: true
    })
  }
  rowClassSelected = (record) => {
    const { selectedCompanyRow } = this.state
    if (record.key === selectedCompanyRow.key) {
      return 'row-selected-table'
    }
  }
  handleCancelModal = () => {
    this.setState({
      visibleModalTenant: false,
      disabledSelect: false,
      selectedCompanyRow: {}
    })
  }
  onClickRowSelected = (e, record) => {
    this.setState({
      selectedCompanyRow: record,
      disabledSelect: true
    })
  }
  saveChangesInvite = () => {
    this.setState({
      visibleModalTenant: false
    })
  }
  onChangeAssignMewTenant = (e) => {
    this.setState({
      assignNewTenant: e.target.checked
    })
  }
  onSorterUserData = (sorter, column) => {
    this.setState({ filter: { ...this.state.filter, sortBy: column, sortOrder: sorter } },
        () => this.fetchUserData(this.state.filter)
    )
  }
  render() {
    const { classNameFindFocus, classNameFind, usersList, totalElements, filteredOptions, fetching, validEmail, isSending, selectedCompanyRow, disabledSelect, visibleModalTenant, companyList, assignNewTenant } = this.state
    const { name, status } = this.state.filter
    return (
      <div className="page-container">
        <div className="page-filter">
          <div className="page-filter__header">
            <div className="page-filter__header--filter-container">
              <div className="page-filter__header--filter-container__filter">
                <label>Find</label>
                <input className={name ? (classNameFindFocus) : (classNameFind)}
                  onChange={(e) => this.updateFilters(e, 'name')}
                  onBlur={(e) => this.updateFilters(e, 'name')}/>
              </div>
              <div className="page-filter__header--filter-container__select">
                <label>Status</label>
                <Select id="questionnaireFilter__teams"
                  onChange={(e) => this.updateFilters(e, 'status')}
                  value={status}>
                  <Option value={null}>View All</Option>
                  <Option value="true">ACTIVE</Option>
                  <Option value="false">INACTIVE</Option>
                </Select>
              </div>
              <div className="page-filter__header--filter-container__select">
                <label>Role</label>
                <Select id="questionnaireFilter__teams"
                  onChange={(e) => this.updateFilters(e, 'role')}
                  value={status}>
                  <Option value={null}>View All</Option>
                  <Option value="god_admin">God Admin</Option>
                  <Option value="super_admin">Super Admin</Option>
                  <Option value="admin">Admin</Option>
                  <Option value="user">User</Option>
                </Select>
              </div>
              <div className="page-filter__header--filter-container__select">
                <label>Client</label>
                <Select id="questionnaireFilter__teams"
                  // onChange={(e) => this.updateFilters(e, 'clientId')}
                  showSearch
                  placeholder="Select client"
                  defaultActiveFirstOption={false}
                  filterOption={false}
                  showArrow={false}
                  onChange={(e) => this.updateFilters(e, 'clientId')}
                  notFoundContent={fetching ? <Spin size="small" /> : null}
                  onSearch={this.handleClientSearch.bind(this)}
                >
                  {filteredOptions && filteredOptions.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.companyName}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="buttons-section justify-content-end">
              <div className="button-green-invite" onClick={ (event) => this.toggleModal(event, true) }>
                <Icon type="plus-circle" className="mr-2"/>
                INVITE USER
              </div>
            </div>
          </div>
        </div>
        <div className="page-content">
          <UserManagementTable users={usersList} changePage={this.changePage} totalElements={totalElements} onSorterUserData={ this.onSorterUserData }/>
        </div>
        <Modal 	className="invite-tenant-modal" title="Invite user" visible={this.state.modalVisible} onOk={this.handleOk} onCancel={this.handleCancel} closable={ false } footer={null} >
          <div className="invite-user-section">
            <header>
              <h1 className="invite-user-section-title">Quick invite</h1>
              <h2 className="invite-user-section-subtitle">Drop in an email and get into the system asap!</h2>
            </header>
            <form className="invite-user-form">
              <div className="invite-user-form-group">
                <label htmlFor="email">Email address</label>
                <input
                  id="email"
                  type="text"
                  onChange={ (e) => this.updateValue(e) } />
              </div>
              <input
                className="invite-user-submit"
                type="submit"
                value="Send invite"
                disabled={ (!assignNewTenant && !selectedCompanyRow.name) || isSending || !validEmail }
                onClick={ (e) => this.saveChanges(e) }
              />
            </form>
            <div className="content-change-company-admin">
              <Checkbox checked={ assignNewTenant } onChange={ this.onChangeAssignMewTenant }
                className="send-form" ><span className="name-factor">Assign user to a new tenant</span>
              </Checkbox>
            </div>
            { selectedCompanyRow && selectedCompanyRow.name &&
              <div className="content-details-company">
                <span>{ `Company: ${ selectedCompanyRow.name }` }</span>
                <span>{ `Domain: ${ selectedCompanyRow.domain }` }</span>
              </div>
            }
            <div className="content-change-company-admin">
              <span className="title-change">Choose user company</span>
              <button onClick={ (e) => this.onChangeCompany(e) } className="button-green button-blue-reset button-blue-change-company" > Assign company </button>
            </div>
          </div>
        </Modal>
        <Modal
          title="Please select a tenant"
          visible={visibleModalTenant}
          onCancel={this.handleCancelModal}
          footer={ null }
          className="user-invite-tenant-company"
        >
          <div className="invite-tenant-company-content">
            <div className="client-list-data">
              <Table className="custom-table new-custom-table"
                dataSource={ companyList }
                pagination={ false }
                rowClassName={(record) => this.rowClassSelected(record) }
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (e) => this.onClickRowSelected(e, record)
                  }
                }}>
                <Column title="Company Name" dataIndex="name" key="name"/>
                <Column title="Status" dataIndex="isActive" key="isActive" render={(text) => {
                  return (<span>{text ? 'Active' : 'Inactive'}</span>)
                }} />
                <Column title="Joined" dataIndex="joined" key="joined" render={(text) => {
                  return (<span>{moment(text).format('DD/MM/YYYY')}</span>)
                }}/>
                <Column title="Domain" dataIndex="domain" key="domain"/>
                <Column title="Subscription" dataIndex="planName" key="planName" align="center"/>
              </Table>
            </div>
            <div className="footer-buttons-action-invite">
              <Button className="action-cancel-invite" onClick={(e) => this.handleCancelModal(e) }>
                Cancel
              </Button>
              <Button disabled={ disabledSelect === false } className="action-invite" onClick={ this.saveChangesInvite }
              >
                Select
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

const stateMap = (state) => ({
  user: state.userReducer.user,
  clientId: state.subscriptionReducer.subscription.subscription.client.id
})

export default connect(stateMap)(UsersManagement)