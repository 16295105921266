import React from 'react'
import './Option.scss'

class OptionNo extends React.Component {
  render() {
    return (
      <div className="newOption-option">
        <p className="newOption-text">Non-repeating DPIA is not recommended</p>
        <p className="newOption-text-small">Osprey strongly suggests a repeating DPIA to ensure constistency in your privacy standards</p>
      </div>
    )
  }
}

export default OptionNo