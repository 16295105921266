import React from 'react'

import './Locations.css'

const Locations = ({ files, openFilePreview }) => (
  <div>
    <p className="locations__text">Object locations</p>
    <ul id="location-list">
      { files.map((file, index) => (
        <li
          className="locations__list-item"
          key={ `file-${ index }` }
          onClick={ openFilePreview.bind(this, file) }>{ file.metadata.doc_id }</li>
      ))}
    </ul>
  </div>
)

export default Locations