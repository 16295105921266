import {
  apiDpiaFetchActiveQuestion,
  apiDpiaFetchAssessment,
  apiDpiaFetchQuestions,
  apiDpiaQuestionSave,
  apiDpiaQuestionSkip,
  apiDpiaFetchProcess
} from '../api/Dpia'

import {
  apiUserFetchInfoByUserId
} from '../api/User'
import * as types from './actionTypes'

export function receivedAssessmentError(error) {
  return { type: types.RECEIVED_ASSESSMENT_ERROR, error }
}

export function receivedAssessment(data) {
  return { type: types.RECEIVED_ASSESSMENT, assessment: data }
}

export function receivedProcess(data) {
  return { type: types.RECEIVED_PROCESS, process: data }
}

export function calculateProgress() {
  return { type: types.QUESTIONS_CALCULATE_PROGRESS }
}

export function hideResponse() {
  return { type: types.ANSWER_HIDE_RESPONSE }
}

export function receiveActiveQuestion(data) {
  return { type: types.RECEIVE_ACTIVE_QUESTION, question: data }
}

export function receivedAssignedUser(data) {
  return { type: types.RECEIVED_ASSIGNED_USER, user: data }
}

export function receiveQuestions(data) {
  return { type: types.RECEIVE_QUESTIONS, data }
}

export function setAssessmentId(data) {
  return { type: types.QUESTIONS_SET_ASSESSMENT_ID, assessmentId: data }
}

export function showResponse(data) {
  return { type: types.ANSWER_SHOW_RESPONSE, text: data }
}

export function updateQuestions(question, status) {
  return { type: types.QUESTIONS_UPDATE_QUESTION, question, status }
}

export function updateValueForCheckbox(value, option) {
  return { type: types.ANSWER_UPDATE_VALUE_FOR_CHECKBOX, data: {
    value,
    option
  } }
}

export function updateValueForOther(value, option) {
  return { type: types.ANSWER_UPDATE_VALUE_FOR_OTHER, data: {
    value,
    option
  } }
}

export function updateValueForRadio(value, option, answer) {
  return { type: types.ANSWER_UPDATE_VALUE_FOR_RADIO, data: {
    value,
    option,
    answer
  } }
}

export function setCurrentAssignedUser() {
  return { type: types.SET_CURRENT_USER_AS_ASSIGNED }
}

export function setToLoadingQuestionsScreen() {
  return { type: types.SET_TO_LOADING_QUESTIONS_SCREEN }
}

export function setToLoadingPiaResultScreen() {
  return { type: types.SET_TO_LOADING_PIA_RESULT_SCREEN }
}

export function fetchProcess(processId) {
  return (dispatch) => {
    const tokenId = JSON.parse(localStorage.getItem('tokenData'))?.tokenId
    const data = { id_token: tokenId }

  apiDpiaFetchProcess(data, processId)
    .then((response) => {
      dispatch(receivedProcess(response.data))
      if (response.data.assignedToUserID) {
        dispatch(fetchAssinedUser(response.data.assignedToUserID))
      } else {
        dispatch(setCurrentAssignedUser())
      }
    })
  }
}

export function fetchAssinedUser(userId) {
  return (dispatch) => {
    apiUserFetchInfoByUserId(userId)
    .then((response) => {
      dispatch(receivedAssignedUser(response.data))
    })
  }
}

export function fetchAssessment(assessmentId) {
  return (dispatch) => {
    const tokenId = JSON.parse(localStorage.getItem('tokenData'))?.tokenId
    const data = { id_token: tokenId }

    apiDpiaFetchAssessment(data, assessmentId)
    .then((response) => {
      dispatch(receivedAssessment(response.data))
      dispatch(fetchProcess(response.data.belongsToBusinessProcess))
      dispatch(calculateProgress())
    })
  }
}

export function confirmAnswer(assessmentId, activeQuestion, questions) {
  return (dispatch) => {
    const tokenId = JSON.parse(localStorage.getItem('tokenData'))?.tokenId
    const selectedOptions = activeQuestion.options.filter((option) => (option.isSelected))
    const otherOptions = activeQuestion.options.filter((option) => (option.type === 'OTHER'))
    const data = {
      id_token: tokenId,
      selectedOptions: selectedOptions.map((selectedOption) => (selectedOption.id)),
      otherOptionTexts: {}
    }

    otherOptions.forEach((otherOption) => {
      data.otherOptionTexts[otherOption.id] = otherOption.otherOptionText
    })

    apiDpiaQuestionSave(assessmentId, activeQuestion.id, data)
    .then((response) => {
      dispatch(updateQuestions(activeQuestion, 'SUBMITTED'))

      const skipQuestionIds = needToSkipQuestions(activeQuestion)
      const questionIds = questions.map((question) => (question.id))

      if (skipQuestionIds.length > 0) {
        skipQuestionIds.forEach((item) => {
          dispatch(updateQuestions({
            id: item,
            options: activeQuestion.options
          }, 'QUESTIONNAIRE_SKIPPED'))
        })

        // Skip to next question on the flow
        const lastId = skipQuestionIds[skipQuestionIds.length - 1]
        const currentQuestionIndex = questionIds.indexOf(lastId)
        const nextQuestionIndex = currentQuestionIndex + 1
        const nextQuestionId = questionIds[nextQuestionIndex]

        if (nextQuestionId === undefined) {
          dispatch(calculateProgress())
        } else {
          dispatch(fetchActiveQuestion(assessmentId, nextQuestionId))
        }
      } else {
        const currentQuestionIndex = questionIds.indexOf(activeQuestion.id)
        const nextQuestionIndex = currentQuestionIndex + 1
        const nextQuestionId = questionIds[nextQuestionIndex]
        const undoSkippedOption = needToUndoTheSkippedQuestions(activeQuestion)

        if (undoSkippedOption) {
          dispatch(undoSkippedQuestions(activeQuestion, undoSkippedOption))
        }

        if (nextQuestionId) {
          dispatch(fetchActiveQuestion(assessmentId, nextQuestionId))
        }
      }
      dispatch(calculateProgress())
    })
  }
}

export function undoSkippedQuestions(question, questionIds) {
  return { type: types.UNDO_SKIPPED_QUESTIONS, question, questionIds }
}

export function skipAnswer(assessmentId, activeQuestion, questions) {
  return (dispatch) => {
    const  tokenId = JSON.parse(localStorage.getItem('tokenData'))?.tokenId
    const selectedOptions = activeQuestion.options.filter((option) => (option.isSelected))
    const otherOptions = activeQuestion.options.filter((option) => (option.otherOption))
    const data = {
      id_token: tokenId,
      selectedOptions: selectedOptions.map((selectedOption) => (selectedOption.id)),
      otherOptionTexts: {}
    }
    otherOptions.forEach((otherOption) => {
      data.otherOptionTexts[otherOption.id] = otherOption.otherOptionText
    })

    apiDpiaQuestionSkip(assessmentId, activeQuestion.id, data)
      .then((response) => {
        dispatch(updateQuestions(activeQuestion, 'USER_SKIPPED'))

        const questionIds = questions.map((question) => (question.id))
        const currentQuestionIndex = questionIds.indexOf(activeQuestion.id)
        const nextQuestionIndex = currentQuestionIndex + 1
        const nextQuestionId = questionIds[nextQuestionIndex]
        const undoSkippedOption = skippedQuestionsForQuestion(activeQuestion)
        
        if (nextQuestionId) {
          dispatch(fetchActiveQuestion(assessmentId, nextQuestionId))
        }

        if (undoSkippedOption) {
          dispatch(undoSkippedQuestions(activeQuestion, undoSkippedOption))
        }

        dispatch(calculateProgress())
      })
  }
}

export function fetchQuestions(assessmentId) {
  return (dispatch) => {
    const tokenId = JSON.parse(localStorage.getItem('tokenData'))?.tokenId
    const data = {
      id_token: tokenId
    }

    apiDpiaFetchQuestions(data, assessmentId)
    .then((response) => {
      const questions = response.data
      dispatch(receiveQuestions({ questions }))
      dispatch(fetchActiveQuestion(assessmentId, questions[0].id))
    })
  }
}

export function fetchActiveQuestion(assessmentId, questionId) {
  return (dispatch) => {
    const tokenId = JSON.parse(localStorage.getItem('tokenData'))?.tokenId
    const data = {
      id_token: tokenId
    }

    apiDpiaFetchActiveQuestion(data, assessmentId, questionId)
    .then((response) => {
      dispatch(receiveActiveQuestion(response.data))
      dispatch(hasToShowResponse(response.data))
    })
  }
}

function hasToShowResponse(question) {
  return (dispatch) => {
    dispatch(hideResponse())

    const valueSelected = question.options.filter((option) => (
      question.selectedOptions.indexOf(option.id) !== -1
    ))

    if (valueSelected.length > 0 && valueSelected[0].message) {
      dispatch(showResponse(valueSelected[0].message))
    }
  }
}

function needToSkipQuestions(answer) {
  const isSelected = answer.options.filter((option) => option.isSelected)
  return isSelected[0].skipQuestions
}

function needToUndoTheSkippedQuestions(question) {
  const skippedOption = question.options.find((option) => !option.isSelected)
  if (!skippedOption) return
  if (skippedOption.skipQuestions.length === 0) return
  return skippedOption.skipQuestions
}

function skippedQuestionsForQuestion(question) {
  const skippedOptions = question.options.map((option) => (option.skipQuestions))
  return [].concat(...skippedOptions)
}