import React from 'react'
import { Button, Select, Modal } from 'antd'
import { apiFetchClients, apiGlobalResetMfa } from '../../../api/Admin'
import '../Admin.scss'
import './RequestTrackerManagement.scss'
import RequestTrackerTable from './RequestTrackerTable'
import RequestTrackerUser from './RequestTrackerUser'
import { emailRegex, getErrorMessage } from '../../../utils'
import CustomNotificationManager from '../../Common/CustomNotificationManager'
import { apiUserMultitenancySignUp } from '../../../api/User'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import swal from 'sweetalert2'

const Option = Select.Option

class RequestTrackerManagement extends React.Component {
    state = {
        filter: {
            name: '',
            status: null,
            subscription: null,
            pageNumber: 1,
            amountPerPage: 10,
            sortBy: null,
            sortOrder: null
        },
        classNameFind: 'page-filter__header--filter-container__filter--search-inactive',
        classNameFindFocus: 'page-filter__header--filter-container__filter--search-active',
        clients: [],
        modalVisible: false,
        isSending: false,
        validEmail: false,
        activePageRequestUser: false,
        tenantSelect: {}
    }

    componentDidMount() {
        this.fetchClientsData()
    }

    async fetchClientsData() {
        const response = await apiFetchClients(this.state.filter)

        this.setState({
            clients: response.data.list,
            totalElements: response.data.totalElements
        })
    }

    toggleModal = (e, visible) => {
        this.setState({
            modalVisible: visible,
        })
    };

    handleOk = (e) => {
        this.setState({
            modalVisible: false,
        })
    }

    handleCancel = (e) => {
        this.setState({
            modalVisible: false,
        })
    }

    updateValue(e) {
        const validEmail = emailRegex.test(e.target.value.trim())
        this.setState({
            emailAddress: e.target.value.trim(),
            validEmail
        })
    }

    saveChanges(e) {
        e.preventDefault()
        if (!this.isFormValid()) return
        this.setState({
            isSending: true
        }, () => this.inviteUser())
    }

    isFormValid() {
        const { emailAddress } = this.state
        if (!emailRegex.test(emailAddress)) {
            CustomNotificationManager.error('Your email does not have a valid format.', 'Error')
            return false
        }
        return true
    }

    inviteUser() {
        const { emailAddress } = this.state
        const currentUserEmailDomain = this.props.user.email.split('@')[1]
        const messageErrors = `You cannot invite a user whose email is on same domain. Please try an e-mail address with other ending than @${ currentUserEmailDomain }`
        const data = this.state.emailAddress
        const inviteTenantDTO = {
            email: data
        }
        if (emailAddress.split('@')[1] !== currentUserEmailDomain) {
            apiUserMultitenancySignUp(inviteTenantDTO)
                .then(() => {
                    this.setState({
                        isSending: false
                    })
                    this.toggleModal(false)
                    CustomNotificationManager.success('Your invite has been sent. Why not invite someone else?', 'Invited')
                }).catch((e) => {
                let errorMessage = getErrorMessage(e)
                if (errorMessage[0].trim() === 'Insufficient Access Rights') {
                    errorMessage = errorMessage[0].trim()
                }
                CustomNotificationManager.error(errorMessage, 'Error')
                this.setState({
                    isSending: false
                })
            })
        } else {
            CustomNotificationManager.error(messageErrors)
            this.setState({
                isSending: false
            })
        }
    }

    updateFilters(e, type) {
        switch (type) {
            case 'name':
                this.setState({ filter: { ...this.state.filter, name: e.target.value } },
                    () => {
                        const searchText = this.state.filter.name
                        if (searchText !== '' && searchText.length > 2) {
                            const timeout = null
                            clearTimeout(timeout)
                            setTimeout(function() {
                                this.fetchClientsData()
                            }.bind(this), 1000)
                        } else if (searchText === '') {
                            this.fetchClientsData()
                        }
                    })
                break
            case 'status':
                this.setState({ filter: { ...this.state.filter, status: e } },
                    () => this.fetchClientsData(this.state.filter))
                break
            case 'subscription':
                this.setState({ filter: { ...this.state.filter, subscription: e } },
                    () => this.fetchClientsData(this.state.filter))
                break
            default :
                return
        }
    }

    changePage = (page) => {
        this.setState({ filter: { ...this.state.filter, pageNumber: page } },
            () => this.fetchClientsData(this.state.filter))
    }
    onResetMfaGlobalClicked = (e, clientId) => {
        e.preventDefault()
        swal.fire({
            text: 'Please confirm Multi Factor Authentication reset for all users that this company have.',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3DD8DB',
            cancelButtonColor: '#D33D33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.value) {
                this.onGetResetGlobalMfa(e, clientId)
            } else {
                CustomNotificationManager.info('Action cancelled', 'Aborted')
            }
        })
    }
    onGetResetGlobalMfa = (e, clientId) => {
        e.preventDefault()
        const globalResetMfaDTO = {
            clientId: clientId
        }
        apiGlobalResetMfa(globalResetMfaDTO).then((response) => {
            CustomNotificationManager.info(`${ response.data }`)
        })
    }
    onSorterTenantData = (sorter, column) => {
        this.setState({ filter: { ...this.state.filter, sortBy: column, sortOrder: sorter } },
            () => this.fetchClientsData(this.state.filter)
        )
    }
    onVisibleRequestTrackerUser = (status, record) => {
       this.setState({
           activePageRequestUser: status,
           tenantSelect: record
       })
    }

    render() {
        const { classNameFindFocus, classNameFind, clients, totalElements, validEmail, isSending, activePageRequestUser, tenantSelect } = this.state
        const { name, status, subscription } = this.state.filter
        return (
            <React.Fragment>
                { activePageRequestUser ?
                    <RequestTrackerUser onVisibleRequestTrackerUser={this.onVisibleRequestTrackerUser} tenantSelect={ tenantSelect }/>
                    :
                    <div className="page-container">
                        <div className="page-filter">
                            <div className="page-filter__header">
                                <div className="page-filter__header--filter-container">
                                    <div className="page-filter__header--filter-container__filter">
                                        <label>Find</label>
                                        <input className={name ? (classNameFindFocus) : (classNameFind)}
                                               onChange={(e) => this.updateFilters(e, 'name')}
                                               onBlur={(e) => this.updateFilters(e, 'name')}/>
                                    </div>
                                    <div className="page-filter__header--filter-container__select">
                                        <label>Status</label>
                                        <Select id="questionnaireFilter__teams"
                                                onChange={(e) => this.updateFilters(e, 'status')}
                                                value={status}>
                                            <Option value={null}>View All</Option>
                                            <Option value="ACTIVE">ACTIVE</Option>
                                            <Option value="INACTIVE">INACTIVE</Option>
                                        </Select>
                                    </div>
                                    <div className="page-filter__header--filter-container__select">
                                        <label>Subscription</label>
                                        <Select id="questionnaireFilter__teams"
                                                onChange={(e) => this.updateFilters(e, 'subscription')}
                                                value={subscription}>
                                            {/*//todo get clients from api and render them bellow*/}
                                            <Option value={null}>View All</Option>
                                            <Option value={1}>FREE</Option>
                                            <Option value={2}>STARTER</Option>
                                            <Option value={3}>PROFESSIONAL</Option>
                                            <Option value={4}>ENTERPRISE</Option>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="page-content">
                            <RequestTrackerTable clients={clients} changePage={this.changePage}
                                                 totalElements={totalElements}
                                                 onResetMfaGlobalClicked={this.onResetMfaGlobalClicked}
                                                 onSorterTenantData={this.onSorterTenantData}
                                                 onVisibleRequestTrackerUser={this.onVisibleRequestTrackerUser}/>
                        </div>
                        <Modal className="invite-tenant-modal" title="Invite tenant" visible={this.state.modalVisible}
                               onOk={this.handleOk} onCancel={this.handleCancel} closable={false} footer={null}>
                            <div className="invite-user-section">
                                <header>
                                    <h1 className="invite-user-section-title">Quick invite</h1>
                                    <h2 className="invite-user-section-subtitle">Please insert an email!</h2>
                                </header>
                                <form className="invite-user-form">
                                    <div className="invite-user-form-group">
                                        <label htmlFor="email">Email address</label>
                                        <input
                                            id="email"
                                            type="text"
                                            onChange={(e) => this.updateValue(e)}/>
                                    </div>
                                    <input
                                        className="invite-user-submit"
                                        type="submit"
                                        value="Send invite"
                                        disabled={isSending || !validEmail}
                                        onClick={(e) => this.saveChanges(e)}
                                    />
                                </form>
                            </div>
                        </Modal>
                    </div>
                }
            </React.Fragment>
        )
    }
}

const stateMap = (state) => ({
    user: state.userReducer.user
})

RequestTrackerManagement.propTypes = {
    user: PropTypes.object.isRequired
}

export default connect(stateMap)(RequestTrackerManagement)