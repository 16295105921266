import PropTypes from 'prop-types'
import React from 'react'
import ImageUploader from 'react-images-upload'
import CustomNotificationManager from '../../../Common/CustomNotificationManager'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import cookie from 'react-cookies'
import * as messageActions from '../../../../actions/messageActions'
import { apiTeamFetchTeamsListSmall } from '../../../../api/Team'
import { apiUserFetchInfo, apiUserFetchInfoByUserId, apiUserUpdate, apiResetMfaAdmin } from '../../../../api/User'
import { apiGetImage } from '../../../../api/Image'
//import imgAddTeam from '../../../../assets/icon-add-circle.png'
import imgDelTeam from '../../../../assets/icon-del-circle.png'
import { logout, emailRegex, hasDuplicates } from '../../../../utils'
//import Button from '../../../Common/Button'
import Loading from '../../../Common/Loading'
import Message from '../../../Common/Message'
import '../../../../style/common.scss'
import './EditUserForm.scss'
import { buildImageErrorNotification } from '../../../../utils/ImageErrorsBuilder'
import { Switch, Input, Select, Button } from 'antd'
import swal from 'sweetalert2'
import withRouter from '../../../../private-routers/withRouter'
import FormData from "form-data";
import axios from "axios";
import {ConcurrencyManager} from "axios-concurrency";
import _ from "lodash";
import * as userActions from "../../../../actions/userActions";
const { Option } = Select
const isLocalhost = () => {
  return window.location.hostname === 'localhost'
}
const host = isLocalhost() ? 'http://localhost:8080' : ''
class EditUserForm extends React.Component {
  constructor() {
    super()

    // this.state = this.defaultState
    this.state = {
      ...this.defaulState,
      activeUser: '',
      isLoading: false,
      isSaving: false,
      isSending: false,
      emailAddress: '',
      emailFrequency: '',
      firstname: '',
      lastname: '',
      userrole: '',
      position: '',
      department: '',
      companyName: '',
      picture: '',
      showPicture: '',
      nrTeams: 0,
      initialTeamList: [],
      teamList: [],
      usedTeamList: [],
      nickname: '',
      email: '',
      selectTeam: '',
      selectDeleteTeam: [],
      selectedTeams: '',
      allTeamsCopy: [],
      readOnly: false,
      popupTime: 3000,
      viewUserRole: '',
      name: '',
      shareholder: [{ name: '' }],
      selectedTeamObj: [],
      availableTeams: [],
      imageUpload: '',
      teamsDrops: [],
      activeUserRoleOsprey: 'user',
      activeUserRoleDpia: 'user',
      alreadyUsedTeams: [],
      teamsError: '',
      valueMFAEditUser: true,
      middle_name: '',
      visibleOptionsRole: false,
      teamsErrorStatus: false,
      loadingDiscardChanges: false
    }
    this.saveChanges = this.saveChanges.bind(this)
    this.state.pictureImg = []
    this.onDrop = this.onDrop.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.imageUpload = React.createRef()

  }

  handleChange(event, field) {

    switch (field) {
      case 'field_firstname':
        this.setState({ firstname: event.target.value })
        break
      case 'field_lastname':
        this.setState({ lastname: event.target.value })
        break
      case 'field_userrole':
        this.setState({ userrole: event.target.value })
        break
      case 'field_middle':
        this.setState({ middle_name: event.target.value })
        break
      case 'field_email':
        this.setState({ emailAddress: event.target.value })
        break
      case 'field_companyName':
        this.setState({ companyName: event.target.value })
        break
      case 'field_position':
        this.setState({ position: event.target.value })
        break
      case 'field_department':
        this.setState({ department: event.target.value })
        break
      case 'field-frequency':
        this.setState({ emailFrequency: event.target.value })
        break
      case 'field_picture':
        this.setState({ picture: event.target.value })
        break
      case 'nrTeams':
        const temp = this.state.initialTeamList
        temp.push({ 'id': this.state.teamList[0].id, 'name': this.state.teamList[0].name })
        this.setState({
          nrTeams: this.state.nrTeams + 1,
          initialTeamList: temp
        }, () => this.renderTeamsDropdowns())
        break
      default:
        break
    }
  }

  updateValue(field, e) {
    this.setState({
      [field]: e.target.value
    })
  }

  removeTeam(i) {
    const temp = this.state.teamsDrops
    const newInitialTeamList = JSON.parse(JSON.stringify(this.state.initialTeamList))
    newInitialTeamList.splice(i, 1)
    this.setState({ teamsDrops: temp, nrTeams: this.state.nrTeams - 1, initialTeamList: newInitialTeamList }, () => {
      this.renderTeamsDropdowns()
    })
  }

  onDrop(pictures, done) {
    if (this.imageUpload &&
      (Object.keys(pictures).length === 0 ||
        this.imageUpload.state?.notAcceptedFileSize?.length ||
        this.imageUpload.state?.notAcceptedFileType?.length
      ))
    {
      // buildImageErrorNotification(this.imageUpload)

      this.imageUpload.setState({
        notAcceptedFileType: [],
        notAcceptedFileSize: []
      })

    } else {
      this.setState({
        pictures: this.state.pictureImg.concat(pictures),
        showPicture: done
      })
      if (done.length > 1) {
        this.setState({
          showPicture: done[done.length -1]
        })
      }
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const id = this.props.params.userId
    this.fetchUser(id)
    this.axiosClone = axios.create({ headers: this.props.smartBoxAxiosHeaders })
    this.manager = ConcurrencyManager(this.axiosClone, this.MAX_CONCURRENT_REQUESTS)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_.isEmpty(this.props.smartBoxAxiosHeaders)) {
      this.axiosClone = axios.create({ headers: this.props.smartBoxAxiosHeaders })
      this.manager = ConcurrencyManager(this.axiosClone, this.MAX_CONCURRENT_REQUESTS)
    }
  }

  fetchUser(data) {
    if (this.props.user.id === data) {
      //if is current user
      apiUserFetchInfo()
        .then((response) => {
          this.initializeFields(response.data)
          this.setState({
            valueMFAEditUser: response.data.user.multi_factor_authentication,
            activeUser: response.data.user,
            activeUserRoleOsprey: response.data.user.user_metadata.roles.osprey,
            activeUserRoleDpia: response.data.user.user_metadata.roles.dpia
          })
        })
    } else {
      // is onother user
      apiUserFetchInfoByUserId(data)
        .then((response) => {
          this.initializeFields(response.data)
          this.setState({
            valueMFAEditUser: response.data.user.multi_factor_authentication,
            activeUser: response.data.user,
            activeUserRoleOsprey: response.data.user.user_metadata.roles.osprey,
            activeUserRoleDpia: response.data.user.user_metadata.roles.dpia
          })
        })
    }
  }
  initializeFields(data) {
    //IIFE function call
    (async function generatePicture() {
      const type = 'user'
      if (data&& data.user) {
        const response = await apiGetImage(data.user.id, type)
        this.setState({
          showPicture: response.data
        })
      }
    }.bind(this))()

    let alreadyUsedTeams = []
    const initialTeamList = data.listTeams.length ? data.listTeams : []
    if (initialTeamList.length) {
      initialTeamList.forEach((item) => {
        if (!initialTeamList.includes(item.id)) {
          alreadyUsedTeams = [ ...alreadyUsedTeams, item.id]
        }
      })
    }

    this.setState({
      isLoading: false,
      emailAddress: typeof data.user.email !== 'undefined' ? data.user.email : '',
      middle_name: typeof data.user.middle_name !== 'undefined' ? data.user.middle_name : '',
      firstname: typeof data.user.user_metadata.name !== 'undefined' ? data.user.user_metadata.name : '',
      lastname: typeof data.user.user_metadata.nickname !== 'undefined' ? data.user.user_metadata.nickname : '',
      position: typeof data.user.user_metadata.position !== 'undefined' ? data.user.user_metadata.position : '',
      department: typeof data.user.user_metadata.department !== 'undefined' ? data.user.user_metadata.department : '',
      companyName: typeof data.user.user_metadata.companyName !== 'undefined' ? data.user.user_metadata.companyName : '',
      emailFrequency: typeof data.user.user_metadata.emailFrequency !== 'undefined' ? data.user.user_metadata.emailFrequency : '',
      userrole: typeof data.user.user_metadata.roles.osprey !== 'undefined' ? data.user.user_metadata.roles.osprey : '',
      initialTeamList,
      nrTeams: data.listTeams.length,
      alreadyUsedTeams
    }, () => this.fetchTeamList())
  }

  fetchTeamList() {
    apiTeamFetchTeamsListSmall()
      .then((response) => {
        this.setState({
          teamList: response.data,
          allTeamsCopy: response.data
        }, () => this.renderTeamsDropdowns())
      }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  saveChanges(e) {
    e.preventDefault()
    // if (this.isFormValid()) {
    //   this.updateUser()
    // }
    this.updateUser()

  }

  isFormValid() {
    if (!emailRegex.test(this.state.emailAddress)) {
      CustomNotificationManager.error('Your email does not have a valid format', 'Error')
      return false
    }
    if (this.state.firstname.length <= 0 && this.state.lastname.length <= 0) {
      CustomNotificationManager.error('User should have firstname or lastname', 'Error')
      return false
    }
    if (this.hasDuplicates(this.state.initialTeamList)) {
      CustomNotificationManager.error('You should not select a team many times', 'Error')
      return false
    }
    return true
  }

  hasDuplicates(arr) {
    let response = false
    const temp = []
    arr.forEach(function(value) {
      if (temp.indexOf(value.name) === -1) {
        temp.push(value.name)
      } else {
        response = true
      }
    })
    return response
  }
  updateEditUser = (data, userId) => {
    const obj = new FormData()
    let objData = {}
    // obj.append('userId', userId)
    // obj.append('email', data.email)
    // obj.append('name', data.name)
    // obj.append('middleName', data.middleName)
    // obj.append('nickname', data.nickname)
    // obj.append('listTeams', data.listTeams)
    // obj.append('userMetadata[emailFrequency]', data.userMetadata.emailFrequency)
    // obj.append('userMetadata[name]', data.userMetadata.name)
    // obj.append('userMetadata[nickname]', data.userMetadata.nickname)
    // obj.append('userMetadata[position]', data.userMetadata.position)
    // obj.append('userMetadata[department]', data.userMetadata.department)
    // obj.append('userMetadata[companyName]', data.userMetadata.companyName)
    // obj.append('roles[osprey]', data.userMetadata.roles.osprey)
    // obj.append('roles[dpia]', data.userMetadata.roles.dpia)
    // if (this.state.pictures) {
    //   obj.append('multipartFile', data.multipartFile)
    // }
    // obj.append('imageFromServer', data.imageFromServer)
    // obj.append('multi_factor_authentication', data.multi_factor_authentication)

    if (this.state.pictures) {
      objData = {
        userId:  userId,
        email:  data.email,
        name:  data.name,
        middleName: data.middleName,
        nickname: data.nickname,
        listTeams: data.listTeams,
        'userMetadata[department]':  data.userMetadata.department,
        'userMetadata[emailFrequency]': data.userMetadata.emailFrequency,
        'userMetadata[name]': data.userMetadata.name,
        'userMetadata[nickname]': data.userMetadata.nickname,
        'userMetadata[position]': data.userMetadata.position,
        'userMetadata[companyName]': data.userMetadata.companyName,
        'roles[osprey]': data.userMetadata.roles.osprey,
        'roles[dpia]': data.userMetadata.roles.dpia,
        'imageFromServer': data.imageFromServer,
        'multi_factor_authentication': data.multi_factor_authentication,
        'multipartFile': data.multipartFile,
      }
    } else {
      objData = {
        userId:  userId,
        email:  data.email,
        name:  data.name,
        middleName:  data.middleName,
        nickname:  data.nickname,
        listTeams:  data.listTeams,
        'userMetadata[department]':  data.userMetadata.department,
        'userMetadata[emailFrequency]': data.userMetadata.emailFrequency,
        'userMetadata[name]': data.userMetadata.name,
        'userMetadata[nickname]': data.userMetadata.nickname,
        'userMetadata[position]': data.userMetadata.position,
        'userMetadata[companyName]': data.userMetadata.companyName,
        'roles[osprey]': data.userMetadata.roles.osprey,
        'roles[dpia]': data.userMetadata.roles.dpia,
        'imageFromServer': data.imageFromServer,
        'multi_factor_authentication': data.multi_factor_authentication,
      }
    }
    const boundary = '----WebKitFormBoundary7MA4YWxkTrZu0gW';
    const body = `--${boundary}\r\nContent-Disposition: form-data; name="key1"\r\n\r\nvalue1\r\n--${boundary}\r\nContent-Disposition: form-data; name="key2"\r\n\r\nvalue2\r\n--${boundary}--`;
    return this.axiosClone({
          method: 'post',
          url: `${ host }/osprey-0.1.0/v1/user/update`,
          data: objData,
          body: body,
          headers: {
            'Content-Type': `multipart/form-data; boundary=${boundary}`
          },
        }
    ).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
      this.setState({
        isSending: false
      })
    })
  }
  updateUser() {
    this.setState({
      isSending: true
    })
    this.props.hideMessage()
    const userId = `auth0|${ this.props.params.userId }`
    const data = {
      userId: userId,
      email: this.state.emailAddress,
      name: this.state.firstname,
      middleName: this.state.middle_name,
      nickname: this.state.lastname,
      listTeams: this.state.initialTeamList.map((team) => team.id),
      userMetadata: {
        emailFrequency: this.state.emailFrequency,
        name: this.state.firstname,
        nickname: this.state.lastname,
        position: this.state.position,
        department: this.state.department,
        companyName: this.state.companyName,
        roles: {
          osprey: this.state.activeUserRoleOsprey,
          dpia: this.state.activeUserRoleDpia
        }
      },
      multipartFile: this.state.pictures ? this.state.pictures[0] : null,
      imageFromServer: !!this.state.showPicture,
      multi_factor_authentication: this.state.valueMFAEditUser
    }
    this.updateEditUser(data, userId).then((response) => {
      if (response.data == 'Success') {
        this.setState({
          isSending: false,
          readOnly: true
        }, () => {
          this.props.fetchUserInfo()
        })
        CustomNotificationManager.success('Your data has been successfully updated.', 'Successfully updated')
        this.props.navigate('/osp/manage/users')
        this.forceUpdate()
        setTimeout(
            function() {
              this.props.hideMessage()
              this.setState({
                readOnly: false
              })
            }
                .bind(this),
            this.state.popupTime
        )
      }
    }).catch((error) => {
      const errorMessage = 'Too Many Requests'
        if (error?.response?.status === 400) {
          if (typeof error.response?.data.error !== 'undefined' && error.response?.data.error.indexOf(errorMessage) > 0) {
            cookie.save('logout_reason', errorMessage, { path: '/', maxAge: 60*60 })
            const redirectUrl = `${ window.location.origin }/osp/login`
            logout(redirectUrl)
          }
        }else{
          console.log(error)
        }
      this.setState({
        isSending: false
      })
    })
    // apiUserUpdate(data)
    //   .then((response) => {
    //     if (response.data.success) {
    //       this.setState({
    //         isSending: false,
    //         readOnly: true
    //       })
    //       CustomNotificationManager.success('Your data has been successfully updated.', 'Successfully updated')
    //       this.props.navigate('/osp/manage/users')
    //       this.forceUpdate()
    //       setTimeout(
    //         function() {
    //           this.props.hideMessage()
    //           this.setState({
    //             readOnly: false
    //           })
    //         }
    //           .bind(this),
    //         this.state.popupTime
    //       )
    //     }
    //   }).catch((e) => {
    //     const errorMessage = 'Too Many Requests'
    //     if (typeof e.response !== 'undefined') {
    //       if (e.response.status === 400) {
    //         if (typeof e.response.data.error !== 'undefined' && e.response.data.error.indexOf(errorMessage) > 0) {
    //           cookie.save('logout_reason', errorMessage, { path: '/', maxAge: 60*60 })
    //           const redirectUrl = `${ window.location.origin }/osp/login`
    //           logout(redirectUrl)
    //         }
    //       }
    //     }
    //     this.setState({
    //       isSending: false
    //     })
    //   })
  }

  updateUsedTeam(e) {
    const index = e.nativeEvent.target.selectedIndex
    const initialTeamList = this.state.initialTeamList
    const selectedSelector = e.target.getAttribute('id')
    const selectedTeamValue = parseInt(e.target.value)
    const selectedTeamName = e.nativeEvent.target[index].text
    if (typeof selectedTeamName !== 'undefined' && selectedTeamName !== '') {
      initialTeamList[selectedSelector] = { 'id': selectedTeamValue, 'name': selectedTeamName }
    }
    if (initialTeamList && initialTeamList.length) {
      let alreadyUsedTeams = []
      initialTeamList.forEach((item) => {
        if (!initialTeamList.includes(item.id)) {
          alreadyUsedTeams = [ ...alreadyUsedTeams, item.id]
        }
      })
      this.setState({ alreadyUsedTeams })
    }
    this.setState({ initialTeamList })
  }
  onDropdownVisibleRole = (e) => {
    this.setState({
      visibleOptionsRole: e
    })
  }
  updateRole(category, e) {
    if (category === 'osprey') {
      //const newRole = (this.state.activeUserRoleOsprey === 'user') ? 'admin' : 'user'
      this.setState({
        activeUserRoleOsprey: e
      })
    } else {
      const newRole = (this.state.activeUserRoleDpia === 'user') ? 'admin' : 'user'
      this.setState({
        activeUserRoleDpia: newRole
      })
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.initialTeamList.length && hasDuplicates(state.initialTeamList)) {
      return state.teamsError = 'You should not select a team many times',
          state.teamsErrorStatus = true
    } else {
      return state.teamsError = '',
          state.teamsErrorStatus = false
    }
  }

  renderTeamsDropdowns() {
    const { nrTeams, initialTeamList, teamList } = this.state

    let tempList = []
    for (let i = 0; i < nrTeams; i++) {
      tempList = tempList.concat([
        <div className="teams-select" key={i}>
          <div className="select teams-select mb-0">
            <select
              onChange={(e) => this.updateUsedTeam(e)}
              id={i}
              key={i}
              defaultValue={initialTeamList[i].id}
            >
              {teamList.map((team) => <option key={team.id} value={team.id || ''}>{team.name}</option>)}
            </select>
          </div>
          <img
            src={imgDelTeam}
            alt=""
            className="inline-icon"
            onClick={() => this.removeTeam(i)}
          />
        </div>
      ])
    }

    this.setState({ teamsDrops: tempList })
    this.forceUpdate()
  }
  onResetMfaClicked = (e) => {
    e.preventDefault()
    swal.fire({
      text: 'Please confirm reset of the Multi Factor Authentication account!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3DD8DB',
      cancelButtonColor: '#D33D33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        this.onResetMFA()
      } else {
        CustomNotificationManager.info('Action cancelled', 'Aborted')
      }
    })
  }
  onResetMFA = () => {
    const resetMfaAdminDTO = {
      userId: this.props.params.userId
    }
    apiResetMfaAdmin(resetMfaAdminDTO).then((response) => {
      if (response.data.title && response.data.title.toLowerCase() === 'success') {
        CustomNotificationManager.success(response.data.message, 'Success')
      } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
        CustomNotificationManager.info(response.data.message, 'Info')
      }
      // if (response.data === 'The MFA was reset successfully!' && response.status === 200) {
      //   CustomNotificationManager.success('The MFA was reset successfully!', 'Success')
      // }
    })
  }
  onChangeEditUserMfa = (valueMFAEditUser) => {
    this.setState({ valueMFAEditUser: valueMFAEditUser })
  }
  onDiscardChangesEditUser = () => {
    this.setState({
      loadingDiscardChanges: true
    }, () => {
      window.location.replace('/osp/manage/users')
      this.setState({
        loadingDiscardChanges: false
      })
    })
  }
  render() {
    const { isLoading, isSending, firstname, lastname, position, department, emailAddress, showPicture, readOnly,
       activeUser, activeUserRoleOsprey, teamsError, valueMFAEditUser, middle_name,
      visibleOptionsRole, teamsErrorStatus, loadingDiscardChanges } = this.state

    const { messageArea } = this.props
    // let viewUserRole
    // if (userrole === 'super_admin')
    // {
    //   viewUserRole = 'Super Admin'
    // }
    // else if (userrole === 'admin')
    // {
    //   viewUserRole = 'Admin'
    // }
    // else if (userrole === 'user')
    // {
    //   viewUserRole = 'User'
    // }
  //  console.log(isSending,'isSending')
    return (
      <div className="editUser__page editUser-update">
        { isLoading &&
          <div className="newQuestionnaire__loading">
            <Loading />
          </div>
        }

        { !isLoading &&
          <React.Fragment>
            <div className="newQuestionnaire__section-update">
              <header className="newQuestionnaire__header">
                <div className="newQuestionnaire__submitButtons editProfile__submitButtons">

                  {/*{ isSending &&*/}
                  {/*  <div className="newQuestionnaire__loading">*/}
                  {/*    <Loading />*/}
                  {/*  </div>*/}
                  {/*}*/}

                  {/*{ !messageArea.visible &&*/}
                  {/*<div className={ this.disabledButton ? 'newQuestionnaire__disabledButton' : ''}>*/}
                  {/*  <Button*/}
                  {/*    theme={ this.disabledButton ? 'gray' : 'green' }*/}
                  {/*    onClick={ this.saveChanges }>*/}
                  {/*    Save Changes*/}
                  {/*  </Button>*/}
                  {/*</div>*/}
                  {/*}*/}
                  <Link to="/osp/manage/users" className="pagination__nav fa fa-angle-left">
                    <div className="goBack">

                    </div>
                  </Link>

                  { messageArea.visible &&
                      <div className="userSettings-message">
                        <Message
                          hideMessageAction={ this.props.hideMessage }
                          status={ messageArea.status }
                          text={ messageArea.text } />
                      </div>
                  }
                </div>
                <h1 className="editUser__title">
                  Edit Profile: <b>{firstname} {lastname}</b>
                </h1>
              </header>

              <form className="editUser__form">
                <div className="cont">
                  <div className="row">
                    <div className="col-md-3-update">
                      <label htmlFor="field_firstname " className="mb-1">First name</label>
                      <Input
                        id="field_firstname "
                        type="text"
                        className="input_field_edit_user"
                        value= { firstname }
                        readOnly = { readOnly }
                        onChange={ (e) => this.handleChange(e, 'field_firstname') }
                      />
                    </div>
                    <div className="col-md-3-update">
                      <label htmlFor="userrole" className="mb-1">Middle name (optional)</label>
                      <Input
                          id="field_middle"
                          type="text"
                          className="input_field_edit_user"
                          placeholder={ middle_name }
                          readOnly = { readOnly }
                          onChange={ (e) => this.handleChange(e, 'field_middle') }
                      />
                    </div>
                    <div className="col-md-3-update">
                      <label htmlFor="lastname" className="mb-1">Last name</label>
                      <Input
                        id="field_lastname"
                        type="text"
                        className="input_field_edit_user"
                        value={ lastname }
                        readOnly = { readOnly }
                        onChange={ (e) => this.handleChange(e, 'field_lastname') }
                      />
                    </div>
                    {/*<div className="col-md-3">*/}
                    {/*  <label htmlFor="userrole" className="mb-1">User role</label>*/}
                    {/*  <input*/}
                    {/*    id="field_userrole"*/}
                    {/*    type="text"*/}
                    {/*    className="input_field_edit_user"*/}
                    {/*    placeholder={ viewUserRole }*/}
                    {/*    readOnly = { true }*/}
                    {/*    onChange={ (e) => this.handleChange(e, 'field_userrole') }*/}
                    {/*  />*/}
                    {/*</div>*/}
                  </div>

                </div>
                <div className="cont">
                  {/*<div className="row">*/}
                  {/*  <div className="col-12">*/}
                  {/*    <h2 className="subtitle">*/}
                  {/*      Company Details*/}
                  {/*    </h2>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  <div className="row">
                    <div className="col-md-3-update">
                      <label htmlFor="field_position" className="mb-1">Position</label>
                      <Input
                        id="field_position"
                        type="text"
                        className="input_field_edit_user"
                        value={ position ? position : '' }
                        readOnly = { readOnly }
                        onChange={ (e) => this.handleChange(e, 'field_position') }
                      />
                    </div>
                    <div className="col-md-3-update">
                      <label htmlFor="field_department" className="mb-1">Department</label>
                      <Input
                        id="field_department"
                        type="text"
                        className="input_field_edit_user"
                        value={ department ? department : '' }
                        readOnly = { readOnly }
                        onChange={ (e) => this.handleChange(e, 'field_department') }
                      />
                    </div>

                    <div className="col-md-3-update">
                      <label htmlFor="field_department" className="mb-1">Email address</label>
                      <Input
                          id="field_email"
                          type="text"
                          className="input_field_edit_user input_field_email_address"
                          value={ emailAddress }
                          readOnly={ true }
                      />
                    </div>

                    {/*<div className="col-md-3">*/}
                    {/*  <label htmlFor="field_companyName" className="mb-1">Company Name</label>*/}
                    {/*  <input*/}
                    {/*    id="field_companyName"*/}
                    {/*    type="text"*/}
                    {/*    className="input_field_edit_user"*/}
                    {/*    value={ companyName }*/}
                    {/*    readOnly = { readOnly }*/}
                    {/*    onChange={ (e) => this.handleChange(e, 'field_companyName') }*/}
                    {/*  />*/}
                    {/*</div>*/}
                  </div>
                </div>

                <div>
                  <h4 className="upload-title"><span className="styleSpan1">Profile image</span></h4>
                  <div className="newQuestionnaire__formGroup styleUploadImage ">
                    <div className="float-left m-3 ">
                      <img id="field_picture" className="teamIconImage " src={ showPicture } alt="" />
                    </div>
                    <div className="float-left m-3">
                      {/*<h4 className="upload-title"><span className="styleSpan1">Upload a photo</span></h4>*/}
                      <ImageUploader
                          className="fileUpload"
                          withIcon={false}
                          label=''
                          labelStyle="styleLabelImageUpload"
                          buttonText='Choose image'
                          buttonClassName="buttonClassChange"
                          onChange={this.onDrop}
                          imgExtension={['.jpg', '.png', '.jpeg']}
                          maxFileSize={3145728}
                          withPreview={false}
                          imgPreview={false}
                          singleImage={true}
                          errorStyle={{ color: 'blue', fontSize:'20px' }}
                          ref={(ref) => this.imageUpload = ref}
                      />
                    </div>
                  </div>
                </div>

                {/*<div className="cont">*/}
                {/*  <div className="row">*/}
                {/*    <div className="col-md-12">*/}
                {/*      <h2 className="subtitle">Team(s)</h2>*/}
                {/*      { teamsDrops.map((teamDrop) => teamDrop) }*/}
                {/*      { initialTeamList.length < teamList.length &&*/}
                {/*        <React.Fragment>*/}
                {/*          <img*/}
                {/*            src={imgAddTeam}*/}
                {/*            alt="" className="inline-icon ml-0"*/}
                {/*            onClick={(e) => this.handleChange(e, 'nrTeams')}*/}
                {/*          />*/}
                {/*        </React.Fragment>*/}
                {/*      }*/}
                {/*      { (teamsError) ? <div className="text-gray"> {teamsError} </div> : '' }*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
                <div className="cont">

                  {/*<div className="row">*/}
                  {/*  <div className="col-md-12 mt-2 mb-2">*/}
                  {/*    <h2 className="subtitle">Email address</h2>*/}
                  {/*    /!*<label htmlFor="field_email" className="mb-1">Email address</label><br/>*!/*/}
                  {/*    <input*/}
                  {/*      id="field_email"*/}
                  {/*      type="text"*/}
                  {/*      className="input_field_edit_user input_field_email_address"*/}
                  {/*      value={ emailAddress }*/}
                  {/*      readOnly={ true }*/}
                  {/*    />*/}
                  {/*  </div>*/}
                  {/*</div>*/}

                  {/*<div className="row">*/}
                  {/*  <div className="col-md-12 mt-2">*/}
                  {/*    <div className="select select_summary_frequency">*/}
                  {/*      <label className="mb-1" htmlFor="email-frequency">Email summary frequency</label>*/}
                  {/*      <select*/}
                  {/*        id="field-frequency"*/}
                  {/*        readOnly = { readOnly }*/}
                  {/*        onChange={ (e) => this.handleChange(e, 'field-frequency') }*/}
                  {/*        value={ emailFrequency || '' }>*/}
                  {/*        <option value="">choose...</option>*/}
                  {/*        <option value="only urgent reminders">only urgent reminders</option>*/}
                  {/*        <option value="all reminders">all reminders</option>*/}
                  {/*      </select>*/}
                  {/*      <br />*/}
                  {/*      <div className="text-gray">Our email reports provide a summary of your account status and any pending tasks.</div>*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </div>
                {
                  activeUser &&
                  <div className="cont cont-user-role">
                    <div className="row">
                      <div className="col-md-12">
                        {/*<h2 className="subtitle">User Role</h2>*/}
                        {/*<div className="row">*/}
                        {/*  <div className="col-md-6">*/}
                        {/*    /!*<label>Osprey</label>*!/*/}
                        {/*    <div*/}
                        {/*      className={activeUserRoleOsprey === 'super_admin' ? 'manageGroups-sliderCheckedSadmin not-allowed' : (activeUserRoleOsprey === 'admin' ? 'manageGroups-sliderChecked' : 'manageGroups-slider')}*/}
                        {/*      onClick={this.updateRole.bind(this, 'osprey')}>*/}
                        {/*      {userrole !== 'super_admin' &&*/}
                        {/*      <input*/}
                        {/*        checked={activeUserRoleOsprey}*/}
                        {/*        id={`slider-${ activeUser.user_id }`}*/}
                        {/*        name="check"*/}
                        {/*        type="checkbox"*/}
                        {/*        value="None"*/}
                        {/*        disabled={activeUserRoleOsprey === 'super_admin' && this.confirmRolesUser === false}*/}
                        {/*        onChange={this.updateRole.bind(this, 'osprey')}/>*/}
                        {/*      &&*/}
                        {/*      <label*/}
                        {/*        className={activeUserRoleOsprey === 'admin' ? 'manageGroups-sliderButtonChecked' : 'manageGroups-sliderButton'}*/}
                        {/*        htmlFor={`slider-${ activeUser.user_id }`}/>*/}
                        {/*      }*/}
                        {/*    </div>*/}
                        {/*  </div>*/}
                        {/*  <div className="col-md-6">*/}
                        {/*    <label>Dpia</label>*/}
                        {/*    <div*/}
                        {/*      className={activeUserRoleDpia === 'super_admin' ? 'manageGroups-sliderCheckedSadmin not-allowed' : (activeUserRoleDpia === 'admin' ? 'manageGroups-sliderChecked' : 'manageGroups-slider')}*/}
                        {/*      onClick={this.updateRole.bind(this, 'dpia')}>*/}
                        {/*      {userrole !== 'super_admin' &&*/}
                        {/*      <input*/}
                        {/*        checked={activeUserRoleDpia}*/}
                        {/*        id={`slider-${ activeUser.user_id }`}*/}
                        {/*        name="check"*/}
                        {/*        type="checkbox"*/}
                        {/*        value="None"*/}
                        {/*        disabled={activeUserRoleDpia === 'super_admin' && this.confirmRolesUser === false}*/}
                        {/*        onChange={this.updateRole.bind(this, 'dpia')}/>*/}
                        {/*      &&*/}
                        {/*      <label*/}
                        {/*        className={activeUserRoleDpia === 'admin' ? 'manageGroups-sliderButtonChecked' : 'manageGroups-sliderButton'}*/}
                        {/*        htmlFor={`slider-${ activeUser.user_id }`}/>*/}
                        {/*      }*/}
                        {/*    </div>*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                        <div className="row">
                          <div className="col-md-6">
                            <h2 className="subtitle">User Role</h2>
                            <div className="content-option-roles">
                              <Select
                                  id="field-frequency"
                                  readOnly={readOnly}
                                  className="select-role-user"
                                  dropdownClassName="dropdown-role-user"
                                  onChange={this.updateRole.bind(this, 'osprey')}
                                  disabled={ this.confirmRolesUser === false }
                                  showArrow={ false }
                                  onDropdownVisibleChange={(e) => this.onDropdownVisibleRole(e)}
                                  value={<span className="content-value-role"><span>{ activeUserRoleOsprey === 'super_admin' ? 'Owner' :
                                      activeUserRoleOsprey === 'admin' ? 'Admin' :
                                          activeUserRoleOsprey === 'user' ? 'User' : 'Owner' }</span>
                                    <span className={ visibleOptionsRole ? "icon-v19-arrow-medium icon-v19-arrow-medium-rotate" : 'icon-v19-arrow-medium' }></span></span>}>
                              {/*  <Option value="super_admin">Owner</Option>*/}
                                <Option value="admin">Admin</Option>
                                <Option value="user">User</Option>
                              </Select>
                              {/*{ userrole !== 'super_admin' ?*/}
                              {/*  <select*/}
                              {/*      id="field-frequency"*/}
                              {/*      readOnly={readOnly}*/}
                              {/*      onChange={this.updateRole.bind(this, 'osprey')}*/}
                              {/*      disabled={activeUserRoleOsprey === 'super_admin' && this.confirmRolesUser === false}*/}
                              {/*      value={activeUserRoleOsprey || ''}>*/}
                              {/*    <option value="admin">admin</option>*/}
                              {/*    <option value="user">user</option>*/}
                              {/*    <option value="super_admin">super_admin</option>*/}
                              {/*  </select> :*/}
                              {/*    <select*/}
                              {/*        id="field-frequency"*/}
                              {/*        readOnly={readOnly}*/}
                              {/*        onChange={this.updateRole.bind(this, 'osprey')}*/}
                              {/*        disabled={ true }*/}
                              {/*        value={activeUserRoleOsprey === 'super_admin' ? 'Owner' : activeUserRoleOsprey || ''}>*/}
                              {/*      <option value="super_admin">S-ADMIN</option>*/}
                              {/*    </select>*/}
                              {/*}*/}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                <div className="cont">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <label className="label-factor">Multi Factor Authentication</label>
                          <div className="switcher-option-user-mfa">
                            <Switch
                              checked={ valueMFAEditUser }
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              onChange={ () => this.onChangeEditUserMfa(!valueMFAEditUser) }
                            />
                            <div className="content-button-reset-MFA">
                              <Button disabled={ this.props.client.multiple_factor_authentication === true } className="button-blue-reset" onClick={(e) => this.onResetMfaClicked(e) }>
                                Reset MFA
                              </Button>
                            </div>
                          </div>
                        </div>
                        {/*<div className="col-md-6">*/}
                        {/*  <label></label>*/}
                        {/*  <div className="content-button-reset-MFA">*/}
                        {/*    <Button className="button-blue-reset" onClick={(e) => this.onResetMfaClicked(e) }>*/}
                        {/*      Reset MFA*/}
                        {/*    </Button>*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                      </div>
                    </div>
                  </div>
                </div>

                <footer className="userSettings-footer">
                  {/*<Link className="userSettings-footerLink" to="/osp/manage/users">Discard changes</Link>*/}
                  <Button loading={ loadingDiscardChanges } onClick={(e) => this.onDiscardChangesEditUser(e)} className="userSettings-footerLink">Discard changes</Button>

                  {/*{ !isSending &&*/}
                  <div>
                    { !messageArea.visible &&
                    
                      <Button
                        disabled={ teamsErrorStatus }
                        theme="editProfile"
                        className="button-editProfile"
                        type="primary"
                        loading={isSending === true }
                        onClick={ this.saveChanges }>
                         Save changes
                      </Button>
                    }

                    { messageArea.visible &&
                    <div className="userSettings-message">
                      <Message
                        hideMessageAction={ this.props.hideMessage }
                        status={ messageArea.status }
                        text={ messageArea.text } />
                    </div>
                    }
                  </div>
                  {/*}*/}
                  {/*{ isSending &&*/}
                  {/*<Loading />*/}
                  {/*}*/}
                </footer>
              </form>
            </div>
          </React.Fragment>
        }
      </div>
    )
  }
}

const stateMap = (state) => ({
  user: state.userReducer.user,
  messageArea: state.messageAreaReducer.messageArea,
  client: state.subscriptionReducer.subscription.subscription.client,
  smartBoxAxiosHeaders: state.smartBox.smartBoxAxiosHeaders,
})

const dispatchMap = (dispatch) => ({
  // fetchUser: (userId) => dispatch(actions.fetchUser(userId)),
  hideMessage: () => dispatch(messageActions.hideMessage()),
  showMessage: (status, text) => dispatch(messageActions.showMessage({ status, text })),
  fetchUserInfo: (user) => dispatch(userActions.fetchUserInfo(user)),
})
EditUserForm = withRouter(EditUserForm)

EditUserForm.propTypes = {
  // fetchUser: PropTypes.func.isRequired,
  hideMessage: PropTypes.func.isRequired,
  messageArea: PropTypes.object.isRequired,
  showMessage: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
}

export default compose(
  connect(stateMap, dispatchMap)
)(EditUserForm)