import React from 'react'
import PropTypes from 'prop-types'

import FolderItem from './FolderItem'

import './FolderList.css'

const FolderList = ({ accordion, folders, toggle, showModal }) => (
  <div>
    <div id="folder-list" className="file-folderList-wrapper">
      { folders.map((folder, index) => (
        <FolderItem
          accordion={ accordion }
          folder={ folder }
          key={ index }
          index={ index }
          toggleAccordion={ toggle }
          showModal={ showModal }
        />
      )) }
    </div>
  </div>
)

FolderList.propTypes = {
  accordion: PropTypes.object.isRequired,
  folders: PropTypes.array.isRequired,
  toggle: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired
}

export default FolderList