import React from 'react'
import { apiValidateAcknowledgement, apiSarAknowledgeResendEmail } from '../../../../api/Sar'
import CustomNotificationManager from '../../../../components/Common/CustomNotificationManager'
import moment from 'moment'
import { connect } from 'react-redux'
import {Spin, Checkbox, Input, Steps, Tooltip} from 'antd'
import Loading from '../../../Common/Loading'
import imgFolderSmallParent from '../../../../assets/smart-folder-medium-parent.svg'
const { Step } = Steps
class AcknowledgeStep extends React.Component {

  constructor() {
    super()
    this.state = {
      sarData: {},
      loading: false,
      loadingResend: false,
      visibleContent: false,
      sendEmailNotification: true,
      valueSelectedBox: '',
      checkOnFocusInput: '',
      startDateSent: '',
      validateDateFormat: false
    }
    this.validate = this.validate.bind(this)
    this.resendEmail = this.resendEmail.bind(this)
  }

  async validate() {
    this.setState({
      loading: true
    })
    const data = {
      sendEmailNotification: this.state.sendEmailNotification,
      id: this.props.sar.id
    }
    const response = await apiValidateAcknowledgement(data)
    this.props.refreshValues(response.data)
    response.data.sarSteps.forEach((data) => {
      if (data.status === 'ACKNOWLEDGE') {
        if (data.completed) {
          this.setState({
            loading: false
          })
          CustomNotificationManager.success('The subject\'s data is now confirmed.', 'Success')
        } else {
          CustomNotificationManager.error('Acknowledge step is not valid.', 'Error')
        }
      }
    })

  }
  componentDidMount() {
    this.setState({
      sarData: { ...this.props.sar, fullName: `${ this.props.sar.forename } ${ this.props.sar.surname ? this.props.sar.surname : '' }`, clientId: this.props.clientId }
    })
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      sarData: { ...nextProps.sar, fullName: `${ nextProps.sar.forename } ${ nextProps.sar.surname ? nextProps.sar.surname : '' }`, clientId: this.props.clientId }
    })
  }

  generateDate(date) {
    return moment(date).format('DD.MM.YYYY')
  }

  resendEmail(event) {
    event.preventDefault()
    const { startDateSent } = this.state
    const regValidateDate = /^\d{1,2}\.\d{1,2}\.\d{4}$/
    if (startDateSent !== '' && !startDateSent.match(regValidateDate)) {
      this.setState({
        validateDateFormat: true
      })
      return false
    }
    this.setState({
      loadingResend: true
    })
    apiSarAknowledgeResendEmail(this.state.sarData).then((response) => {
      this.props.refreshValues(response.data)
      this.setState({
        loadingResend: false
      })
      CustomNotificationManager.success('Email sent.', 'Success')
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  customDot = (dot, { index, status }) => {
    switch (index) {
      case 0: {
        return <span className={ status === 'process' ? 'icon-v10-radio-button-tick' : status === 'finish' ? 'icon-v10-radio-button-tick' : 'icon-v10-radio-button-off' }></span>
      }
      case 1: {
        return <span className={ status === 'process' ? 'icon-v10-radio-button-tick' : status === 'finish' ? 'icon-v10-radio-button-tick' : 'icon-v10-radio-button-off' }></span>
      }
      case 2: {
        return <span className={ status === 'process' ? 'icon-v10-radio-button-tick' : status === 'finish' ? 'icon-v10-radio-button-tick' : 'icon-v10-radio-button-off' }></span>
      }
      default:
        return <span></span>
    }
  }

  generateClassSteps = (step, status, state) => {
    switch (step) {
      case 'status-link':
        if (state === 'finish') {
          return 'status-link status-step-finish'
        } 
        return 'status-link'
        
      case 'status-link-visited':
        if (state === 'finish') {
          return 'status-link-visited status-step-finish'
        } 
        return 'status-link-visited'
        
      case 'status-submitting-data':
        if (state === 'finish') {
          return 'status-submitting-data status-step-finish'
        } 
        return 'status-submitting-data'
        
      default :
        return 'status'
    }
    
  }
  generateDescriptionSteps = (step, current) => {
    const { sar } = this.props
    const { acknowledgeSubmitted, linkVisited, acknowledgeSent } = sar
    switch (step) {
      case 'status-link':
        if (acknowledgeSent && acknowledgeSent.length > 0) {
          return <React.Fragment>
            {
              acknowledgeSent.map((date, i) => <span className="status-finish-info"
                key={i}>{this.generateDate(date)}</span>)
            }
          </React.Fragment>
        } 
        return <span className="status-pending">Pending</span>
        
      case 'status-link-visited':
        if (linkVisited && linkVisited.length > 0) {
          return <React.Fragment>
            {
              linkVisited.map((date, i) => <span className="status-finish-info"
                key={i}>{this.generateDate(date)}</span>)
            }
          </React.Fragment>
        } 
        return <span className="status-pending">Pending</span>
        
      case 'status-submitting-data':
        if (acknowledgeSubmitted && acknowledgeSubmitted.length > 0) {
          return <React.Fragment>
            {
              acknowledgeSubmitted.map((date, i) => <span className="status-finish-info"
                key={i}>{this.generateDate(date)}</span>)
            }
          </React.Fragment>
        } 
        return <span className="status-pending">Pending</span>
        
      default :
        return 0
    }
  }

  onClickVisibleContent = () => {
    this.setState({
      visibleContent: !this.state.visibleContent
    })
  }
  onChangeNotification = () => {
    this.setState({
      sendEmailNotification: !this.state.sendEmailNotification
    })
  }
  handleChange = (e, field) => {
    if (field === 'start_date') {
      const startDateSent = e.target.value
      if (startDateSent === '') {
        this.setState({
          sarData: { ...this.state.sarData, [field]: e.target.value },
          startDateSent: startDateSent
        })
      }
    } else {
      this.setState({
        sarData: { ...this.state.sarData, [field]: e.target.value }
      })
    }
    this.setState({
      validateDateFormat: false
    })
  }
  autoFocusInput(e, field) {
    this.setState({
      checkOnFocusInput: field
    })
  }
  onBlurInput() {
    this.setState({
      checkOnFocusInput: ''
    })
  }
  onClickNextStep = () => {
    const { current, checkidStep } = this.props
    if (!checkidStep) {
      this.props.nextStep(current)
    } else {
      this.props.nextStep(current + 1)
    }
  }
  onClickBackStep = () => {
    const { current } = this.props
    this.props.beckStep(current - 1)
  }

  render() {
    const { initialStep, acknowledgeStep, sar, valueAttachBox, checkidStep } = this.props
    const { loading,
      //loadingResend,
      valueSelectedBox,
      checkOnFocusInput,
      sarData,
      startDateSent,
      validateDateFormat,
      sendEmailNotification
    } = this.state
    const { acknowledgeSubmitted, linkVisited, acknowledgeSent } = sar
    let statusSent
    let statusLinkVisited
    let statusSubmittingData
    if (acknowledgeSent && acknowledgeSent.length > 0) {
      statusSent = 'finish'
    } else {
      statusSent = 'wait'
    }
    if (linkVisited && linkVisited.length > 0) {
      statusLinkVisited = 'finish'
    } else {
      statusLinkVisited = 'wait'
    }
    if (acknowledgeSubmitted && acknowledgeSubmitted.length > 0) {
      statusSubmittingData = 'finish'
    } else {
      statusSubmittingData = 'wait'
    }

    // let emailStatus
    // if (emailOpened && emailOpened.length > 0) {
    //   emailStatus = 'OPENED'
    // } else {
    //   emailStatus = 'SENT'
    // }
    //
    // let emailLinkOpened
    // if (linkVisited && linkVisited.length > 0) {
    //   emailLinkOpened = 'VISITED'
    // } else {
    //   emailLinkOpened = 'PENDING'
    // }
    //
    // let linkSubmitted
    // if (acknowledgeSubmitted && acknowledgeSubmitted.length > 0) {
    //   linkSubmitted = 'SUBMITTED'
    // } else {
    //   linkSubmitted = 'PENDING'
    // }

    //const isLinkFromEmail = emailOpened || acknowledgeSubmitted || linkVisited

    let lastSarStepCompleted
    initialStep ? lastSarStepCompleted = initialStep.completed : lastSarStepCompleted = false
    const signature = sar.signature ? sar.signature : 'No Signature'
    return (
      <React.Fragment>
        <div className="tracker-header-steps">
          <div className="contain-name-back-step">
            <div className="back-step back-step-hover">
              <span onClick={this.onClickBackStep} className="icon-v10-back"></span>
            </div>
            <div className="tracker-fullname-contain">
              <span className="tracker-surname">{`${ sar.forename } ${ sar.surname }`}</span>
            </div>
          </div>
          <div className="attach-box-contain">
            { (valueAttachBox || this.props.sar.boxName) ?
              <img alt={ valueSelectedBox } src={ imgFolderSmallParent }/>:
              <span className="icon-v10-smart-folder-medium-parent-2"></span>
            }
            <span className="attach-box disabled-attach-box">
              { valueAttachBox ? valueAttachBox : this.props.sar.boxName ? this.props.sar.boxName : 'Attach a box' }
            </span>
          </div>
          <div className="contain-save-last-step">
            <div className="save-close-contain">
              <span className="icon-v10-save"></span>
              <span className="save-and-close">
             Save & Close
              </span>
            </div>
            <div className={!checkidStep ? 'next-step next-step-disabled' : 'next-step next-step-hover'}>
              <span onClick={this.onClickNextStep} className="icon-v10-forth"></span>
            </div>
          </div>
        </div>
        {/*<div className="tracker-surname-contain">*/}
        {/*  <span className="tracker-surname">{`${ sar.forename } ${ sar.surname }`}</span>*/}
        {/*</div>*/}
        <div className="tracker-box adjust-tracker-box-acknowledge" style={{ marginTop: 0 }}>
          <div className="tracker-form">
            <header className="tracker-step-header">
              <div className="tracker-step-header__top">
                <h1 className="tracker-step-header__top__step">Acknowledge Subject Access Request
                  <Tooltip overlayClassName="help-message-locations" title={<div className="content-help-message-locations">
                    <span className="text-information">Confirm acknowledgement of the subject’s request.</span>
                    <span className="more-info-link" onClick={ () => window.open('https://support.smartbox.ai/support/solutions/articles/47001175223-acknowledgement', '_blank') }> More info. </span>
                  </div>}>
                    <span className="icon-v14-question"></span>
                  </Tooltip></h1>
              </div>
            </header>

            {/*<div className="tracker-acknowledge-info">*/}
            {/*  <div className="content-pending">*/}
            {/*    <span className={linkSubmitted === 'SUBMITTED' ? 'email-sent' : 'email-opened'}>{linkSubmitted}</span>*/}
            {/*  </div>*/}
            {/*  {isLinkFromEmail &&*/}
            {/*  <div className="content-email-status">*/}
            {/*    <span className="info-email-status">Email Status</span>*/}
            {/*    <span className="contain-email-status">*/}
            {/*      <span*/}
            {/*        className={emailStatus === 'OPENED' ? 'email-opened' : 'email-sent'}>{emailStatus}</span>*/}
            {/*      {*/}
            {/*        emailStatus !== 'OPENED' &&*/}
            {/*        <Icon type="check" className="icon-check"/>*/}
            {/*      }*/}
            {/*    </span>*/}
            {/*  </div>*/}
            {/*  }*/}
            {/*  <div className="content-link-status">*/}
            {/*    <span className="info-link-status">Form Link Status</span>*/}
            {/*    <span className="contain-email-status">*/}
            {/*      <span className={emailLinkOpened === 'VISITED' ? 'email-sent' : 'email-opened'}>{emailLinkOpened}</span>*/}
            {/*      { emailLinkOpened === 'VISITED' &&*/}
            {/*        <Icon type="check" className="icon-check-two"/>*/}
            {/*      }*/}
            {/*    </span>*/}
            {/*  </div>*/}
            {/*  <div className="content-data-status">*/}
            {/*    <span className="info-data-status">Form Data Status</span>*/}
            {/*    <span className="contain-data-status">*/}
            {/*      <span className={linkSubmitted === 'SUBMITTED' ? 'email-sent' : 'email-opened'}>{linkSubmitted}</span>*/}
            {/*      { linkSubmitted === 'SUBMITTED' &&*/}
            {/*      <Icon type="check" className="icon-check"/>*/}
            {/*      }*/}
            {/*    </span>*/}
            {/*  </div>*/}
            {/*  <div className="tracker-content-icons">*/}
            {/*    <span className="tracker-icons-visible-content" onClick={() => this.onClickVisibleContent()}>*/}
            {/*      <i className={ this.state.visibleContent ? 'fa fa-caret-down' : 'fa fa-caret-up' }></i>*/}
            {/*    </span>*/}
            {/*  </div>*/}
            {/*</div>*/}

            {/*{ this.state.visibleContent &&*/}
            {/*  <div className="tracker-acknowledge-info-content">*/}
            {/*    <div className="display-email-status">*/}
            {/*      { emailOpened &&*/}
            {/*        <React.Fragment>*/}
            {/*          {*/}
            {/*            emailOpened.map((date, i) => <span*/}
            {/*              key={i}>{this.generateDate(date)}</span>)*/}
            {/*          }*/}
            {/*        </React.Fragment>*/}
            {/*      }*/}
            {/*    </div>*/}
            {/*    <div className="display-form-link-status">*/}
            {/*      {linkVisited &&*/}
            {/*        <React.Fragment>*/}
            {/*          {*/}
            {/*            linkVisited.map((date, i) => <span*/}
            {/*              key={i}>{this.generateDate(date)}</span>)*/}
            {/*          }*/}
            {/*        </React.Fragment>*/}
            {/*      }*/}
            {/*    </div>*/}
            {/*    <div className="display-form-data-status">*/}
            {/*      {*/}
            {/*        acknowledgeSubmitted.map((date, i) => <span*/}
            {/*          key={i}>{this.generateDate(date)}</span>)*/}
            {/*      }*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*}*/}

            <div className="tracker-email-info-steps">
              <Steps progressDot={ this.customDot }>
                <Step status={ statusSent } title="Sent" description={this.generateDescriptionSteps('status-link', 0)} className={ this.generateClassSteps('status-link', 0, statusSent)} />
                <Step status={ statusLinkVisited } title="Link visited" description={this.generateDescriptionSteps('status-link-visited', 1)} className={ this.generateClassSteps('status-link-visited', 1, statusLinkVisited)}/>
                <Step status={ statusSubmittingData } title="Submitting data" description={this.generateDescriptionSteps('status-submitting-data', 2)} className={ this.generateClassSteps('status-submitting-data', 2, statusSubmittingData)} />
              </Steps>
            </div>
            <div className="tracker-step-content">
              <div className="tracker-form-group-fullname">
                <div className="tracker-form-group tracker-form-group-edit">
                  <label htmlFor="forename">Subject first name</label>
                  <Input onFocus={(e) => this.autoFocusInput(e, 'forename')} onBlur={(e) => this.onBlurInput(e)} id='forename' type="text"
                    value={sarData.forename || ''} onChange={(e) => this.handleChange(e, 'forename')} readOnly/>
                  <div className={ checkOnFocusInput === 'forename' ? 'edit-input-first-name edit-input-first-name-focus' : 'edit-input-first-name' }>
                    <span className="icon-v10-edit-b"></span>
                  </div>
                </div>
                <div className="tracker-form-group">
                  <label htmlFor="surname">Subject last name</label>
                  <Input onFocus={(e) => this.autoFocusInput(e, 'surname')} onBlur={(e) => this.onBlurInput(e)} id='surname' type="text"
                    value={sarData.surname || ''} onChange={(e) => this.handleChange(e, 'surname')} readOnly/>
                  <div className={checkOnFocusInput === 'surname' ? 'edit-input edit-input-focus' : 'edit-input'}>
                    <span className="icon-v10-edit-b"></span>
                  </div>
                </div>
              </div>
              <div className="tracker-form-group">
                <label htmlFor="subject_name">Subject job title</label>
                <Input onFocus={(e) => this.autoFocusInput(e, 'title')} onBlur={(e) => this.onBlurInput(e)} id='title' type="text"
                  value={sarData.title || ''} onChange={(e) => this.handleChange(e, 'title')} readOnly/>
                <div className={checkOnFocusInput === 'title' ? 'edit-input edit-input-focus' : 'edit-input'}>
                  <span className="icon-v10-edit-b"></span>
                </div>
              </div>
              <div className="tracker-form-group">
                <label htmlFor="email">Subject work email address</label>
                <Input onFocus={(e) => this.autoFocusInput(e, 'email')} onBlur={(e) => this.onBlurInput(e)} id='email' type="text"
                  value={sarData.email || ''} onChange={(e) => this.handleChange(e, 'email')} readOnly/>
                <div className={checkOnFocusInput === 'email' ? 'edit-input edit-input-focus' : 'edit-input'}>
                  <span className="icon-v10-edit-b"></span>
                </div>
              </div>
              <div className="tracker-form-group">
                <label htmlFor="address">Subject private email address</label>
                <Input onFocus={(e) => this.autoFocusInput(e, 'private_email')} onBlur={(e) => this.onBlurInput(e)} id='private_email' type="text"
                  value={sarData.private_email || ''} onChange={(e) => this.handleChange(e, 'private_email')} readOnly/>
                <div className={checkOnFocusInput === 'private_email' ? 'edit-input edit-input-focus' : 'edit-input'}>
                  <span className="icon-v10-edit-b"></span>
                </div>
              </div>
              <div className="tracker-form-group">
                <label htmlFor="telephone">Subject phone number</label>
                <Input onFocus={(e) => this.autoFocusInput(e, 'telephone')} onBlur={(e) => this.onBlurInput(e)} id='telephone' type="text"
                  value={sarData.telephone || ''} onChange={(e) => this.handleChange(e, 'telephone')} readOnly/>
                <div className={checkOnFocusInput === 'telephone' ? 'edit-input edit-input-focus' : 'edit-input'}>
                  <span className="icon-v10-edit-b"></span>
                </div>
              </div>
              <div className="tracker-form-group">
                <label htmlFor="textarea">Date requested</label>
                <Input onFocus={(e) => this.autoFocusInput(e, 'startDate')} onBlur={(e) => this.onBlurInput(e)} id='text' type="text"
                  onChange={(e) => this.handleChange(e, 'start_date')}
                  value={startDateSent !== '' ? startDateSent : sarData.start_date ? this.generateDate(sarData.start_date) : ''} className="text_area" rows="5" readOnly/>
                <div className={checkOnFocusInput === 'startDate' ? 'edit-input edit-input-focus' : 'edit-input'}>
                  <span className="icon-v10-edit-b"></span>
                </div>
                { validateDateFormat &&
                  <span>Invalid date format. Input valid date format MM.DD.YYYY</span>
                }
              </div>
              <div className="tracker-form-group">
                <label htmlFor="textarea">Notes</label>
                <Input onFocus={(e) => this.autoFocusInput(e, 'notes')} onBlur={(e) => this.onBlurInput(e)} id='textarea'
                  onChange={(e) => this.handleChange(e, 'adminNotes')}
                  value={sarData.adminNotes || ''} className="text_area" rows="5" readOnly/>
                <div className={checkOnFocusInput === 'notes' ? 'edit-input edit-input-focus' : 'edit-input'}>
                  <span className="icon-v10-edit-b"></span>
                </div>
              </div>
              <div className="tracker-form-group" id="signature_form">
                <label htmlFor="signature_image">Signature:</label>
                {signature.image &&
                < img id='signature_image' className="signature_image" src={signature.image || ''}
                  alt="signature"/>
                }
                {!signature.image &&
                signature
                }
              </div>
              {lastSarStepCompleted && (acknowledgeStep && !acknowledgeStep.completed) &&
              <div className="tracker-content-links full_width">
                <div className="tracker-form-link">
                  <Checkbox defaultChecked={ sendEmailNotification } className="send-form" onChange={this.onChangeNotification}>Send Email
                    Notification</Checkbox>
                </div>
              </div>
              }
              {lastSarStepCompleted && (acknowledgeStep && !acknowledgeStep.completed) &&
              <div className="tracker-form-group form-buttons-next button-acknowledge">
                {loading ? <Spin className="float-right" spinning={loading} indicator={<div className="checkout-loading"><Loading/></div>}/> :
                  <button className="button-blue-light float-right"
                          disabled={ sar.lastStep === 'ACKNOWLEDGE_UNCOMPLETED' }
                    onClick={this.validate}>ACKNOWLEDGE
                  </button>
                }
              </div>
              }
              {/*{ lastSarStepCompleted && isLinkFromEmail && !acknowledgeStep &&*/}
              {/*<div className="tracker-form-group form-buttons">*/}
              {/*  {*/}
              {/*    loadingResend ? <Spin className="float-right" spinning={ loadingResend}*/}
              {/*      indicator={<div className="checkout-loading"><Loading/></div>}/> :*/}
              {/*      <button className="button-blue-light float-right"*/}
              {/*        onClick={this.resendEmail}>Send Again*/}
              {/*      </button>*/}
              {/*  }*/}
              {/*</div>*/}
              {/*}*/}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id
})
AcknowledgeStep = connect(mapStateToProps)(AcknowledgeStep)

export default AcknowledgeStep