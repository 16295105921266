import * as React from "react";
import { Provider } from "react-redux";
import axios from "axios";
import RoutesV6 from "./Routes";
import ReactDOM from 'react-dom'
import AlertsContext from "./contexts/Alerts";
import CollectionsContext from "./contexts/Collections";
import ConnectorsContext from "./contexts/Connectors";
import DataModelsContext from "./contexts/DataModels";
import FilesContext from "./contexts/Files";
import TopicsContext from "./contexts/Topics";
import UploadProgressWindow from "./components/SmartBox/SmartBox/UploadProgressWindow";
import { createRoot } from "react-dom/client";
import { unregister } from "./registerServiceWorker";
import configureStore from "./store/configureStore";
import rootReducer from "./reducers/rootReducer";
import { catchErrorMessage, logout } from "./utils";

import "antd/dist/antd.css";
//import 'antd/dist/reset.css'
import "../src/style/common.scss";
import "./index.css";
import "./icons.css";
import "react-quill/dist/quill.snow.css";
import { userTokenRefresh } from "./api/User";

const store = configureStore(rootReducer);
axios.interceptors.request.use(
  function (config) {
    const dpiaToken = localStorage.getItem('dpiaToken');
    // const ospreyToken = localStorage.getItem('accessToken');
    const tokenData = JSON.parse(localStorage.getItem('tokenData'))?.tokenId
    let currentToken = tokenData
    

    if (config.url.includes("/dpia")) {
      currentToken = dpiaToken;
    }

    config.headers = {
      Accept: "application/json",
      Authorization: `Bearer ${currentToken}`,
      "Content-Type": "application/json",
    };

    if (config.url.includes("subject-access")) {
      config.headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    let checkTokenExp = JSON.parse(localStorage.getItem('tokenData'))?.expiresAt

    if(checkTokenExp && Date.now() / 1000 > checkTokenExp) {
      logout()
    }
    // if now time is bigger than expiration - 30 min than refresh
    if(checkTokenExp && Date.now() / 1000 > checkTokenExp - 1800){
       // Refresh token
      userTokenRefresh().then((response) => {
        if(response.data.access_token){
          var secondsToExp = Math.floor(new Date().getTime() / 1000);
          secondsToExp += (60*60*8) // 8 is the hours unit
          var tokenObject = {'tokenId': response.data.access_token, 'expiresAt': secondsToExp};
          localStorage.setItem('tokenData',JSON.stringify(tokenObject))
        }
      }).catch((err) => {
        logout()
      })
     
    }
    return response;
  },
  function (error) {
    catchErrorMessage(error);
    return Promise.reject(error);
  }
);
//const root = createRoot(document.getElementById("root"));
ReactDOM.render((
    <Provider store={ store }>
        <AlertsContext>
            <ConnectorsContext>
                <CollectionsContext>
                    <DataModelsContext>
                        <FilesContext>
                            <TopicsContext>
                                <RoutesV6 />
                            </TopicsContext>
                        </FilesContext>
                    </DataModelsContext>
                </CollectionsContext>
            </ConnectorsContext>
        </AlertsContext>
        <UploadProgressWindow />
    </Provider>
), document.getElementById('root'))
// root.render(
//   <Provider store={store}>
//       <AlertsContext>
//         <ConnectorsContext>
//           <CollectionsContext>
//             <DataModelsContext>
//               <FilesContext>
//                 <TopicsContext>
//                   <RoutesV6 />
//                 </TopicsContext>
//               </FilesContext>
//             </DataModelsContext>
//           </CollectionsContext>
//         </ConnectorsContext>
//       </AlertsContext>
//     <UploadProgressWindow />
//   </Provider>
// );
// register the serviceWorker
unregister();
