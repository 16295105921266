export const sortByPublication = (templates) => {
  const versions = [].concat(...templates.map((template) => template.versions)).sort((itemA, itemB) => (itemB.publicationDate - itemA.publicationDate))
  const sortedTemplates = []

  versions.forEach((version) => {
    const currentTemplate = templates.find((template) => template.id === version.belongsToQuestionnaire)
    
    if (sortedTemplates.length === 0) {
      sortedTemplates.push(currentTemplate)
    } else {
      const savedTemplate = sortedTemplates.find((template) => template.id === version.belongsToQuestionnaire)
      if (!savedTemplate) {
        sortedTemplates.push(currentTemplate)
      }
    }
  })

  return sortedTemplates
}