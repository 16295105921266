import PropTypes from 'prop-types'
import React from 'react'
import './Modal.scss'

const Modal = ({ children, className }) => (
  <div className= { className }>
   
    { children }
  </div>
)
Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}

export default Modal
