import React from 'react'
import PropTypes from 'prop-types'
import FindLibrary from './FindLibrary'
import AddComplianceDocument from './AddComplianceDocument'
import { findComplianceDocument } from '../../../../api/DocLocker'

class ManageComplianceDocumentLocker extends React.Component {
  state = {
    addComplianceActive: false,
    updateComplianceDocActive: false,
    findComplianceDocActive: false,
    deleteComplianceDocActive: false,
    documentsFound: false,
    documentsFoundCount: 0
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.addLibraryOpened !== this.props.addLibraryOpened && this.props.addLibraryOpened === true) {
      this.setState({
        addComplianceActive: true
      })
    }
  }

  closeAdd = () => {
    this.setState({
      addComplianceActive: false
    })
  }

  addComplianceActive = (addComplianceActive) => {
    this.setState({
      addComplianceActive: !addComplianceActive,
      updateComplianceDocActive: false,
      findComplianceDocActive: false,
      deleteComplianceDocActive: false,
      documentsFound: false
    }, () => {
      this.props.setTablesVisible(true)
      this.props.setDefaultTableStatus()
      this.props.setFoundLibraries([])
    })
  }

  setUpdateComplianceDocActive = (updateComplianceDocActive) => {
    this.setState({
      updateComplianceDocActive: !updateComplianceDocActive,
      deleteComplianceDocActive: false,
      addComplianceActive: false,
      findComplianceDocActive: false,
      documentsFound: false
    }, () => {
      this.props.setTablesVisible(true)
      this.props.setUpdateComplianceDocActive(!updateComplianceDocActive)
      this.props.setFoundLibraries([])
    })
  }

  setDeleteComplianceDocActive = (deleteComplianceDocActive) => {
    this.setState({
      deleteComplianceDocActive: !deleteComplianceDocActive,
      updateComplianceDocActive: false,
      addComplianceActive: false,
      findComplianceDocActive: false,
      documentsFound: false
    }, () => {
      this.props.setTablesVisible(true)
      this.props.setDeleteComplianceDocActive(!deleteComplianceDocActive)
      this.props.setFoundLibraries([])
    })
  }

  setFindComplianceDocActive = (findComplianceDocActive) => {
    this.setState({
      findComplianceDocActive: !findComplianceDocActive,
      deleteComplianceDocActive: false,
      updateComplianceDocActive: false,
      addComplianceActive: false,
      documentsFound: false
    }, () => {
      this.props.setFindComplianceDocActive(!findComplianceDocActive)
      if (this.state.findComplianceDocActive) {
        this.props.setTablesVisible(false)
      } else {
        this.props.setTablesVisible(true)
        this.props.setFoundLibraries([])
      }
    })
  }

  closeAllTabs = () => {
    this.setState({
      findComplianceDocActive: false,
      deleteComplianceDocActive: false,
      updateComplianceDocActive: false,
      addComplianceActive: false,
      documentsFound: false
    }, () => {
      this.props.setDefaultTableStatus()
      this.props.setTablesVisible(true)
      this.props.setFoundLibraries([])
    })
  }

  findLibraries = (data) => {
    findComplianceDocument(data).then((res) => {
      if (res.data && res.data.count > 0) {
        this.setState({
          documentsFound: true,
          documentsFoundCount: res.data.count
        }, () => {
          this.props.setFoundLibraries(res.data.list)
          this.props.setTablesVisible(true)
        })
      } else {
        this.setState({
          documentsFound: true,
          documentsFoundCount: 0
        }, () => {
          this.props.setTablesVisible(false)
        })
      }
    })
  }

  addNewValueToTable = (complianceDoc, library) => {
    this.setState({
      addComplianceActive: false,
    }, () => this.props.addNewValueToTable(complianceDoc, library))
  }

  render() {
    const { addComplianceActive, updateComplianceDocActive, deleteComplianceDocActive, findComplianceDocActive, documentsFoundCount, documentsFound } = this.state
    const { libraries } = this.props
    return (
      <div className="compliance-page__action-container">
        <div className="compliance-page__action-container__header">
          <div className="title" onClick={ this.closeAllTabs }>
            COMPLIANCE DOCUMENT LOCKER
          </div>
          <div className="actions">
            <div className="add-tab" onClick={ () => this.addComplianceActive(addComplianceActive) } style={
              addComplianceActive ? { color: 'black' } : {}
            }>
              <span className="icon-add-b"/>
              <span>Add Compliance <br/> Document</span>
              { addComplianceActive && <i className="up custom-caret"/> }
            </div>
            <div className="find-tab" onClick={ () => this.setFindComplianceDocActive(findComplianceDocActive) }
              style={
                findComplianceDocActive ? { color: 'black' } : {}
              }>
              <span className="icon-find-asset-b"/>
              <span>Find Compliance <br/> Document</span>
              { findComplianceDocActive && <i className="up custom-caret"/> }
            </div>
            <div className="update-tab" onClick={ () => this.setUpdateComplianceDocActive(updateComplianceDocActive) }
              style={
                updateComplianceDocActive ? { color: 'black' } : {}
              }>
              <span className="icon-update-b-1"/>
              <span>Update Compliance <br/> Document</span>
            </div>
            <div className="delete-tab" onClick={ () => this.setDeleteComplianceDocActive(deleteComplianceDocActive) }
              style={
                deleteComplianceDocActive ? { color: 'black' } : {}
              }>
              <span className="icon-delete-b"/>
              <span>Delete Compliance <br/> Document</span>
            </div>
          </div>
        </div>
        { addComplianceActive &&
        <div className="compliance-page__action-container__content">
          <AddComplianceDocument libraries={ libraries } addNewValueToTable={this.addNewValueToTable }/>
        </div>
        }
        {
          findComplianceDocActive &&
          <div className="compliance-page__action-container__content">
            <FindLibrary documentsFoundCount={ documentsFoundCount } documentsFound={ documentsFound }
              findLibraries={ this.findLibraries }/>
          </div>
        }
      </div>
    )
  }
}

ManageComplianceDocumentLocker.propTypes = {
  setDeleteComplianceDocActive: PropTypes.func.isRequired,
  setUpdateComplianceDocActive: PropTypes.func.isRequired,
  setFindComplianceDocActive: PropTypes.func.isRequired,
  addNewValueToTable: PropTypes.func.isRequired,
  setDefaultTableStatus: PropTypes.func.isRequired,
  setTablesVisible: PropTypes.func.isRequired,
  libraries: PropTypes.array.isRequired,
  setFoundLibraries: PropTypes.func.isRequired
}

export default ManageComplianceDocumentLocker