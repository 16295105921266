import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import './Overdue.scss'
import { Tooltip } from 'antd'

const seeQuestions = (assessmentId) => {
  this.props.navigate(`/dpia/assessment/${ assessmentId }`)
}

const overdueLabel = (assessment, answered, total) => {
  if (assessment.type === 'PIA_QUESTIONNAIRE') {
    return `Incomplete, ${ answered }/${ total }`
  }
  return `Incomplete, expired on ${ moment(assessment.validEndDate).format('MMMM Do') }`
}

const Overdue = ({ assessment, answered, total, processStatus, type }) => (
  <div className="overdueAssessment-wrapper">
    <div className="overdueAssessment-actionsContainer">
      <Tooltip title="DPIA Report">
        <span className="iconReportDisabled" />
      </Tooltip>
      <Tooltip title="Download Report">
        <span className="iconDownloadDisabled" />
      </Tooltip>
      <Tooltip title="Continue Active DPIA">
        <span className="iconPlay" onClick={ seeQuestions.bind(this, assessment.id) } />
      </Tooltip>
    </div>

    <div className="overdueAssessment-infoContainer">
      <div className="overdueAssessment-textContainer">
        <Link to={ `/dpia/assessment/${ assessment.id }`} className="piaActiveAssessment_link">
          { /*moment(assessment.validStartDate).format('MMMM YYYY')*/ }
          {type}
        </Link>
      </div>
      
      <div className="overdueAssessment-status">
        { processStatus !== 'INACTIVE' &&
          <Link to={ `/dpia/assessment/${ assessment.id }`} className="overdueAssessment-notCompleted">
            { overdueLabel(assessment, answered, total) }
          </Link>
        }

        { processStatus === 'INACTIVE' &&
          <span className="overdueAssessment-notCompleted">
            Terminated { moment(assessment.validStartDate).format('MMMM YYYY') }
          </span>
        }

        <div className="overdueAssessment-iconContainer">
          <Link to={ `/dpia/assessment/${ assessment.id }`}>
            <span className="iconWarning" />
          </Link>
        </div>
      </div>
    </div>
  </div>
)

Overdue.propTypes = {
  assessment: PropTypes.object.isRequired,
  answered: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  processStatus: PropTypes.string.isRequired
}

export default Overdue