import React from 'react'
import {Select} from "antd";
import {CompactPicker} from "react-color";
import {connect} from "react-redux";
import { getAnnotationColorsByClientId } from '../../../api/Sar'
import PropTypes from "prop-types";
import CustomNotificationManager from "../../Common/CustomNotificationManager";

class AnnotateSelectedRedaction extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      numberOfAnnotateSelected: 0,
      colorPikerDisplay: false,
      colorSelected: {'input': 'rgba(107, 91, 149, 1)', 'select': 'rgba(254, 178, 54, 1)'},
      annotateSelectBtnDisabled: true,
      annotationList: ['Section 36', 'Section 37', 'Section 38', 'Section 39', 'Section 40', 'Section 41', 'Section 42'],
      annotateLabel: '',
      annotateSelected: 'names',
      colorCategoriesData: []
    }
  }

  componentDidMount() {
    if (this.props && this.props.user && this.props.clientId) {
      getAnnotationColorsByClientId(this.props.clientId, this.props.user.id ).then( (response) => {
        if (response.status === 200) {
          this.setState({ colorCategoriesData: response.data })
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
      }else{
          console.log(error)
      }
      })
    }

  }

  convertRgbaToString = ({ r, g, b, a }) => {
    return `rgba(${ r }, ${ g }, ${ b }, ${ a })`
  }

  pressEscapeKey = (e) => {
    if (e.keyCode === 27) {
      this.setState({colorPikerDisplay: false})
    }
  }

  changeAnnotateLabel = (e) => {
    this.setState({ annotateLabel: e.target.value })
  }

  changeAnnotationSelected = (e) => this.setState({ annotateSelected: e })

  closeAndSaveColorPicker = (color, key) => {
    const { colorSelected } = this.state
    colorSelected[key]= this.convertRgbaToString(color.rgb)
    this.setState({ colorSelected, colorPikerDisplay: false })
  }

  toggleSimpleCustomColorPicker = (key) => {
    let { colorPikerDisplay } = this.state
    colorPikerDisplay = colorPikerDisplay === key ? false : key
    this.setState({ colorPikerDisplay })
  }

  annotateAction = () => {
    const { colorSelected, annotateLabel } = this.state
    const { itemsSelected } = this.props
    const seletedAnnotateForLabel = []
    itemsSelected.map( (item) => {
      let tag = ''
      try {
        tag = document.querySelectorAll(`.${ item }`)[0]
      }
      catch(err) {}
      if (tag) {
        if(/<\/?[a-z][\s\S]*>/i.test(tag.innerHTML)) {
          tag = tag.innerHTML
        }
        const newElement = document.createElement('span')
        newElement.innerText = annotateLabel
        newElement.style.color = colorSelected.input
        newElement.style.backgroundColor = colorSelected.select
        newElement.classList.add('annotate-label')
        if (tag.innerHTML) {
          tag.before(newElement)
          if (! seletedAnnotateForLabel.includes(tag.innerHTML)) {
            seletedAnnotateForLabel.push(tag.innerHTML)
          }
        }
      }
      return true
    })
    this.props.updateCanvasText(seletedAnnotateForLabel, annotateLabel, colorSelected.input, colorSelected.select)
  }

  renderSimpleCustomColorPicker = () => {
    const { colorSelected, colorPikerDisplay } = this.state
    let topDistance = 110
    if (colorPikerDisplay && ['input', 'select'].includes(colorPikerDisplay)) {
      let color = colorSelected.input
      if (colorPikerDisplay === 'select') {
        topDistance += 60
        color = colorSelected.select
      }
      return (
        <div className="simple-custom-color-picker" style={{ top: topDistance }}>
          <CompactPicker
            className="color-picker-a"
            colors={ [ 'rgba(107, 91, 149, 1)', 'rgba(254, 178, 54, 1)', 'rgba(214, 65, 97, 1)',
              'rgba(255, 123, 37, 1)', 'rgba(75, 195, 193, 1)', 'rgba(194, 194, 71, 1)', 'rgba(181, 231, 160, 1)',
              'rgba(191, 108, 64, 1)', 'rgba(62, 68, 68, 1)', 'rgba(130, 183, 75, 1)', 'rgba(64, 93, 39, 1)',
              'rgba(193, 148, 106, 1)', 'rgba(60, 136, 180, 1)', 'rgba(200, 100, 91, 1)', 'rgba(238, 162, 154, 1)',
              'rgba(201, 76, 76, 1)', 'rgba(124, 108, 6, 1)', 'rgba(15, 195, 71, 1)', 'rgba(231, 82, 114, 1)',
              'rgba(238, 140, 54, 1)', 'rgba(92, 212, 209, 1)', 'rgba(211, 211, 88, 1)', 'rgba(198, 248, 177, 1)',
              'rgba(192, 125, 81, 1)', 'rgba(77, 100, 113, 1)', 'rgba(237, 226, 220, 1)', 'rgba(132, 206, 235, 1)' ] }
            disableAlpha={ true }
            color={ color }
            onChange={ (color) => this.closeAndSaveColorPicker(color, colorPikerDisplay) }
          />
        </div>
      )
    }
    return false
  }

  render() {
    const { colorSelected, annotationList, annotateLabel } = this.state
    const { numberOfAnnotateSelected } = this.props
    let { annotateSelectBtnDisabled } = this.state
    const { Option } = Select
    if (1 && annotateLabel) {
      annotateSelectBtnDisabled = false
    }
    return (
      <div className="annotate" tabIndex="-1" onKeyDown={ (e) => this.pressEscapeKey(e) }>
        <div className="annotate__header">
          <span className="annotate__header__title">ANNOTTE SELECTED REDACTION</span>
          <span className="annotate__header__close" onClick={ (e) => this.props.collapseAnnotateSelectModal(e, false) }>X</span>
        </div>
        <div className="annotate__body">
          <div className="annotate__body__line">
            <div className="input-space" onChange={ (e) => this.changeAnnotateLabel(e) }>
              <input type="text"/>
            </div>
            <div className="button-space">
              <div className="button-space__circle" style={{ backgroundColor: colorSelected.input}}/>
              <div className="icon-colourpalette-b" onClick={ () => this.toggleSimpleCustomColorPicker('input')}/>
            </div>
          </div>
          <div className="annotate__body__line">
            <div className="select-space">
              <Select className="select-space__select" defaultValue={annotationList[0]} onChange={ (e)=> this.changeAnnotationSelected(e) }>
                {
                  annotationList.map((item) => {
                    return (<Option key={item.toLowerCase()} value={item.toLowerCase()}>{item}</Option>)
                  })
                }
              </Select>
            </div>
            <div className="button-space">
              <div className="button-space__circle" style={{ backgroundColor: colorSelected.select}}/>
              <div className="icon-colourpalette-b" onClick={ () => this.toggleSimpleCustomColorPicker('select') }/>
            </div>
          </div>
        </div>
        <div className="annotate__footer">
          <button
            className="finalize-button button-green annotate__footer__btn"
            onClick={ () => this.annotateAction() }
            disabled={annotateSelectBtnDisabled}
          >ANNOTATE</button>
          <p className="annotate__footer__number">{ numberOfAnnotateSelected } names selected</p>
        </div>
        { this.renderSimpleCustomColorPicker() }
      </div>
    )
  }
}

AnnotateSelectedRedaction.propTypes = {
  numberOfAnnotateSelected: PropTypes.number.isRequired,
  itemsSelected: PropTypes.array.isRequired,
  documentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  collapseAnnotateSelectModal: PropTypes.func.isRequired,
  updateCanvasText: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id,
  user: state.userReducer.user
})

export default connect(mapStateToProps)(AnnotateSelectedRedaction)