import React from 'react'
import PropTypes from 'prop-types'

import Progress from '../Progress'

import './QuestionsHeaderSmall.css'

const QuestionsHeaderSmall = ({ activeQuestion, fetchActiveQuestion, questionsResults, allQuestions, process, progress }) => (
  <div className="questionsHeaderSmall__wrapper">
    <div>
      Business Process:
      &nbsp;
      <strong>{ process.title }</strong>
    </div>

    { activeQuestion &&
      <Progress
        questionsResults={ questionsResults }
        activeQuestion={ activeQuestion }
        allQuestions={ allQuestions }
        progress={ progress }
        onClick={ (id) => fetchActiveQuestion(id) }
      />
    }
  </div>
)

QuestionsHeaderSmall.propTypes = {
  activeQuestion: PropTypes.object.isRequired,
  fetchActiveQuestion: PropTypes.func.isRequired,
  questionsResults: PropTypes.object.isRequired,
  allQuestions: PropTypes.array.isRequired,
  process: PropTypes.object.isRequired,
  progress: PropTypes.number.isRequired
}

export default QuestionsHeaderSmall