import React from 'react'
import './NewManualTabs.scss'
import {notification, Tree} from 'antd'
import {
  renderTreeBusiness,
  generateHtmlForBusiness
} from '../../../utils/SmartBox/SmartBoxMethods'
import {
  newGetBusinessTermWords,
  getBusinessCategoryColor,
  removeCategoryBusiness,
  showRedactedBusinessElementFromBulk,
  saveKeysRedactedBusiness,
  apiSmartBoxGetNewQueryStatusBusiness,
  apiSmartBoxSaveNewQueryStatusOcr,
  apiSmartBoxUpdateRedactionNumber,
  apiSmartBoxSaveXeroxRedactionSmartView
} from '../../../api/SmartBox'
import Mark from 'mark.js'
import _ from 'lodash'
import swal from 'sweetalert2'
import PropTypes from "prop-types";
import CustomNotificationManager from "../../Common/CustomNotificationManager";

class Business extends React.Component {
  state = {
    redactedKeys: [],
    expandedKeys: [ 'select-all' ],
    autoExpandParent: true,
    checkedKeys: [],
    unCheckedKeys:[],
    selectedKeys: [],
    categoryColours: {},
    newBusiness: [ {
      title: 'Select All',
      key: 'select-all',
      identifier: 'top-parent',
      children: []
    } ],
    docAnnotations: {},
    selectedChildrenBold: [],
    disableRemoveCategory: false,
    sendStatusOnClearAll: false,
    selectedPgNrBoldBusiness: [],
    itemSelectRedacted: {},
    checkedKeysSaved: [],
    newBusinessOcr: [ {
      title: 'Select All',
      key: 'select-all',
      identifier: 'top-parent',
      children: []
    } ],
    setIntervalCheckPdfCanvas: 0,
    responseCheckPdfCanvas: '',
  }

  componentDidMount() {
    if (this.props.isSmartBoxApplication) {
      //this.newFetchDataBusiness(this.props.idDocument)
      this.newFetchExpandedKeys(this.props.idDocument)
      //this.fetchDataBusiness(this.props.idDocument)
      this.props.onCheckedKeysObjectRefsBoxTerms([])
      // this.onDictionaryCheckedKeysOcr()
      // this.onBusinessCheckedKeys()
    }
    if (this.props.responseStatusQuery === 200) {
      //this.fetchDataBusiness(this.props.idDocument)
      //this.newFetchDataBusiness(this.props.idDocument)
    }
  }

  newFetchExpandedKeys = async(docId) => {
    const { docAnnotationsBusiness, categoryColoursBusiness, treeBusinessUpdate, docAnnotationsBusinessOcr, docAnnotationsBusinessXerox } = this.props
    this.props.onDocAnnotationsList(docAnnotationsBusiness)
    this.setState({
      docAnnotations: docAnnotationsBusiness,
      docAnnotationsRegExOcr: docAnnotationsBusiness
    })
    this.setState({
      docAnnotations: docAnnotationsBusiness,
      docAnnotationsOcr: docAnnotationsBusinessOcr,
      docAnnotationsBusinessXerox: docAnnotationsBusinessXerox,
      categoryColours: categoryColoursBusiness,
      newBusiness: treeBusinessUpdate,
      newBusinessOcr: treeBusinessUpdate,
    }, () => {
      this.renderCategoryExpandedKeys()
    })
  }

  renderCategoryExpandedKeys = () => {
    const { newBusiness, docAnnotations, categoryColours, newBusinessOcr } = { ...this.state }
    let { expandedKeys, checkedKeys } = { ...this.state }
    const { landscape, idDocument, treeBusinessUpdate } = this.props
    this.props.allChildrenTreeBusiness(treeBusinessUpdate)
    const checkedKeysUpdateBusiness = []
    if (newBusiness[0].children && newBusiness[0].children.length > 0 && this.props.alreadyRedactedKeys.business && this.props.alreadyRedactedKeys.business.length > 0 ) {
      newBusiness[0].children.forEach((children1) => {
        children1 && children1.children && children1.children.length > 0 && children1.children.forEach((children2) => {
          children2 && children2.children && children2.children.length > 0 && children2.children.forEach((children3) => {
            if ((this.props.alreadyRedactedKeys.business.includes(`${children3.name.toLowerCase()}-${children3.index}`)) ||
                this.props.alreadyRedactedKeys.business.includes(`${children3.name.toLowerCase()}-all`)) {
              checkedKeysUpdateBusiness.push(children3.key)
            }
          })
        })
      })
    }
    checkedKeys = checkedKeysUpdateBusiness
    this.setState({
      newBusiness: treeBusinessUpdate,
      newBusinessOcr: treeBusinessUpdate,
      autoExpandParent: false,
      expandedKeys: [ ...expandedKeys, ...Object.keys(categoryColours) ],
      checkedKeys
    })
  }


  fetchDataBusinessUpdate = async(docId) => {
    const { newBusiness } = this.state
    let response
    let response1
    //newBusiness[0].children = []
    if (this.props.isSmartBoxApplication) {
      response = await newGetBusinessTermWords(docId)
      response1 = await getBusinessCategoryColor(docId)
    }
    this.props.onDocAnnotationsList(response.data)
    if (response.data.message && response.data.title) {
      this.setState({
        docAnnotations: {},
        docAnnotationsRegExOcr: {},
      })
    } else {
      this.setState({
        docAnnotations: response.data,
        docAnnotationsRegExOcr: response.data,
      })
    }
    this.setState({
      //docAnnotations: response.data,
      //docAnnotations: { gravicus: ["gravicus"] },
      //docAnnotationsOcr: response.data,
      categoryColours: response1.data
      //categoryColours: { gravicus: "rgba(192, 125, 81, 0.3)" }
    }, () => {
        this.renderCategoryBusiness()
    })
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.isNextDocumentId !== this.props.isNextDocumentId) {
      //this.fetchDataBusinessUpdate(this.props.isNextDocumentId.id)
      //this.props.onCheckedKeysObjectRefsBoxTerms([])
    }
    if (prevProps.updateRedactAllStatus !== this.props.updateRedactAllStatus) {
      this.setState({
        checkedKeys: this.props.checkedKeysAllBusiness
      })
    }
    if (prevProps.checkedChildBusiness !== this.props.checkedChildBusiness && this.props.checkedChildBusiness.length > 0) {
      this.setState({
        checkedKeys: this.props.checkedChildBusiness
      })
    }
    if (prevProps !== this.props && this.props.removeAllDictionaries) {
      this.state.newBusiness && this.state.newBusiness[0].children.length > 0 && this.state.newBusiness[0].children.forEach((keyColour) => {
        keyColour && keyColour.children.length > 0 && keyColour.children.forEach((keyColour2) => {
          keyColour2.redacted = false
        })
      })
      this.setState({
        checkedKeys: []
      })
    }
    if (prevProps.alreadyRedactedKeys.business !== this.props.alreadyRedactedKeys.business) {
      this.onDictionaryCheckedKeysOcr()
      this.onBusinessCheckedKeys()
    }
    if (prevProps.responseStatusQuery !== this.props.responseStatusQuery && this.props.responseStatusQuery === 200) {
      //this.fetchDataBusiness(this.props.idDocument)
      //cthis.newFetchDataBusiness(this.props.idDocument)
    }

    // if (prevProps.isNextDocumentId.id !== this.props.isNextDocumentId.id) {
    //   this.newFetchDataBusiness(this.props.idDocument)
    // }
    if (prevProps.getAllChildrenBusiness !== this.props.getAllChildrenBusiness && !this.props.statusNextDocumentBusiness) {
      this.props.onGetStatusNextDocumentBusiness(false)
      this.newFetchDataBusiness(this.props.idDocument)
      ///this.newFetchDataBusiness(this.props.idDocument)
    }
    // if (prevProps.getAllChildrenBusiness !== this.props.getAllChildrenBusiness && this.props.statusNextDocumentBusiness) {
    //   this.props.onGetStatusNextDocumentBusiness(false)
    //   this.newFetchDataBusiness(this.props.idDocument)
    //   ///this.newFetchDataBusiness(this.props.idDocument)
    // }
    if (Object.keys(prevProps.docAnnotationsBusiness).length !== Object.keys(this.props.docAnnotationsBusiness).length && Object.keys(prevProps.docAnnotationsBusiness).length > 0) {
      //this.fetchDataBusiness(this.props.idDocument)
      this.newFetchDataBusiness(this.props.idDocument)
    }
  }

  onBusinessCheckedKeys = () => {
    if (!this.props.isOcr) {
      if (this.props.alreadyRedactedKeys.business && this.props.alreadyRedactedKeys.business.length > 0) {
        this.setState({
          checkedKeys: this.props.alreadyRedactedKeys.business
        })
      }
    }
  }
  onDictionaryCheckedKeysOcr = () => {
    if (this.props.isOcr) {
      const checkedKeysOcr = []
      this.props.alreadyRedactedKeys.business && this.props.alreadyRedactedKeys.business.length > 0 && this.props.alreadyRedactedKeys.business.forEach((regOcr) => {
        const newKeysOcr = regOcr.substr(0, regOcr.length - 2)
        checkedKeysOcr.push(newKeysOcr.toLowerCase())
      })
      this.setState({
        checkedKeys: checkedKeysOcr
      })
    }
  }
  fetchDataBusiness = async(docId) => {
    let response
    let response1
    if (this.props.isSmartBoxApplication) {
      response = await newGetBusinessTermWords(docId)
      response1 = await getBusinessCategoryColor(docId)
    }
    this.props.onDocAnnotationsList(response.data)
    if (response.data.message && response.data.title) {
      this.setState({
        docAnnotations: {},
        docAnnotationsRegExOcr: {},
      })
    } else {
      this.setState({
        docAnnotations: response.data,
        docAnnotationsRegExOcr: response.data,
      })
    }
    this.setState({
      //docAnnotations: response.data,
      docAnnotations: { silviu :['06/06/202316:38', '06/06/2023,16:38']},
      // docAnnotations: { gravicus: ["Ok great, I don’t think we need the file from you now as we are pretty sure we know the issue", 'gravicus',
      //   'about to move the session to tomorrow as we are still waiting confirmation from our technical compliance team about sharing the example file which is taking longer than'] },
      //docAnnotationsOcr: response.data,
      //categoryColours: response1.data
      categoryColours: { silviu: "rgba(192, 125, 81, 0.3)" }
    }, () => {
      // setTimeout(() => {
      //   this.renderCategoryBusiness()
      // }, 3000)
      this.renderCategoryBusiness()
    })
  }
  newFetchDataBusiness = async(docId) => {
    const { docAnnotationsBusiness, categoryColoursBusiness, treeBusinessUpdate, docAnnotationsBusinessOcr, docAnnotationsBusinessXerox } = this.props
    this.props.onDocAnnotationsList(docAnnotationsBusiness)
      this.setState({
        docAnnotations: docAnnotationsBusiness,
        docAnnotationsRegExOcr: docAnnotationsBusiness
      })
    this.setState({
      docAnnotations: docAnnotationsBusiness,
      docAnnotationsOcr: docAnnotationsBusinessOcr,
      docAnnotationsBusinessXerox: docAnnotationsBusinessXerox,
      //docAnnotations: { silviu :['06/06/202316:38', '06/06/2023,16:38']},
      categoryColours: categoryColoursBusiness,
      newBusiness: treeBusinessUpdate,
      newBusinessOcr: treeBusinessUpdate,
      //categoryColours: { silviu: "rgba(192, 125, 81, 0.3)" }
    }, () => {
      this.renderCategoryBusinessUpdate()
    })
  }

  renderCategoryBusinessUpdate = () => {
    const { newBusiness, docAnnotations, categoryColours, newBusinessOcr } = { ...this.state }
    let { expandedKeys, checkedKeys } = { ...this.state }
    const { landscape, idDocument, treeBusinessUpdate } = this.props
    // const newCategoryBusiness = []
    // for (const categoryParent of Object.keys(docAnnotations)) {
    //   newCategoryBusiness.push(categoryParent)
    // }

    this.fetchRedactedRegexElementFromBulk(idDocument, treeBusinessUpdate)
    this.props.allChildrenTreeBusiness(treeBusinessUpdate)
    // if (!this.props.isOcr) {
    //   this.props.onGetDictionary(treeBusinessUpdate)
    //   this.props.setTreeDataDict(treeBusinessUpdate)
    // }
    const checkedKeysUpdateBusiness = []
    if (newBusiness[0].children && newBusiness[0].children.length > 0 && this.props.alreadyRedactedKeys.business && this.props.alreadyRedactedKeys.business.length > 0 ) {
      newBusiness[0].children.forEach((children1) => {
        children1 && children1.children && children1.children.length > 0 && children1.children.forEach((children2) => {
          children2 && children2.children && children2.children.length > 0 && children2.children.forEach((children3) => {
            if ((this.props.alreadyRedactedKeys.business.includes(`${children3.name.toLowerCase()}-${children3.index}`)) ||
                this.props.alreadyRedactedKeys.business.includes(`${children3.name.toLowerCase()}-all`)) {
              checkedKeysUpdateBusiness.push(children3.key)
            }
          })
        })
      })
    }
    checkedKeys = checkedKeysUpdateBusiness
    this.setState({
      newBusiness: treeBusinessUpdate,
      newBusinessOcr: treeBusinessUpdate,
      autoExpandParent: false,
      expandedKeys: [ ...expandedKeys, ...Object.keys(categoryColours) ],
      checkedKeys
    }, () => {
      if (!this.props.isOcr) {
        this.props.onGetDictionary(newBusiness)
        this.props.setTreeDataDict(newBusiness)
        //this.onBackgroundDictionary(newBusiness)
      }
      if (this.props.isOcr) {
        //this.props.onGetDictionary(treeBusinessUpdate)
        //this.props.onGetDictionaryOcrUpdate(treeBusinessUpdate)
        this.checkDocAnnotationCategoryOcrBusiness()
      } else if (this.props.xeroxUpload) {
        this.checkDocAnnotationCategoryXeroxBusiness()
      }
    })
  }

  onBackgroundDictionary = (dictionaryList) => {
    //const { dictionaryList } = this.state
    //const { dictionaryList } = this.props
    dictionaryList && dictionaryList[0].children.length > 0 && dictionaryList[0].children.forEach((keyColour) => {
      keyColour && keyColour.children.length > 0 && keyColour.children.forEach((keyColour2) => {
        keyColour2 && keyColour2.children.length > 0 && keyColour2.children.forEach((keyColour3) => {
          // const aiAnnotations = document.querySelectorAll(`.${ keyColour3.key }`)
          if (keyColour3 && keyColour3.key) {
            const aiAnnotations = document.getElementsByClassName(keyColour3.key)
            for (const element of aiAnnotations) {
                element.style.background = keyColour3.backgroundColor
                //element.style.borderColor = keyColour3.backgroundColor ? keyColour3.backgroundColor.replace('0.3', '1') : keyColour3.backgroundColor

            }
          }
        })
      })
    })
  }

  fetchRedactedRegexElementFromBulk = (id, treeData) => {
    const { docAnnotations } = this.state
    const { idDocument } = this.props
    const data = {
      id: id,
      treeData: treeData
    }
    if (this.props.isSmartBoxApplication) {
      showRedactedBusinessElementFromBulk(data).then(() => {
        this.fetchDataNewQueryStatus(idDocument, docAnnotations)
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
            console.log(error)
        }
      })
    }
  }

  fetchDataNewQueryStatus = (id, docAnnotations) => {
    const { checkedKeys } = this.state
    const displaySaveKeys = []
    const docAnnotationsList = []
    let newDisplayKeysSaved = []
    Object.keys(docAnnotations) && Object.keys(docAnnotations).length > 0 && Object.keys(docAnnotations).forEach((doc) => {
      docAnnotationsList.push({
        businessTerm: doc,
        color: docAnnotations[doc],
      })
    })
    const data = {
      id: id,
      //docAnnotations: docAnnotations === '' ? {} : docAnnotations
      docAnnotations: null
    }
    if (this.props.isSmartBoxApplication) {
      if (docAnnotations.title && docAnnotations.title === 'Info') {
        // CustomNotificationManager.info('You are not advised to make redactions until box analytics report is ready!')
      } else {
        apiSmartBoxGetNewQueryStatusBusiness(data).then((response) => {
          newDisplayKeysSaved = response.data
          newDisplayKeysSaved && newDisplayKeysSaved.map((saveKey) => {
            displaySaveKeys.push(saveKey.key)
            return true
          })
          this.setState({
            checkedKeys: checkedKeys.concat(displaySaveKeys),
            checkedKeysSaved: checkedKeys.concat(displaySaveKeys)
          })
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            CustomNotificationManager.cleanBuffer('warning', '')
            console.log(error)
          }
        })
      }
    }
  }

  renderCategoryBusiness = () => {
    const { newBusiness, docAnnotations, categoryColours, newBusinessOcr, checkedKeys } = { ...this.state }
    let { expandedKeys } = { ...this.state }
    const { landscape, idDocument } = this.props
    const newCategoryBusiness = []
    for (const categoryParent of Object.keys(docAnnotations)) {
      newCategoryBusiness.push(categoryParent)
    }
    for (const categoryColor of Object.keys(categoryColours)) {
      const { resultCategoryBusiness, expandedSubCategoryParent } = generateHtmlForBusiness(categoryColor, categoryColours[categoryColor], docAnnotations, landscape, newCategoryBusiness)
      expandedKeys = [...expandedKeys, ...expandedSubCategoryParent]
      newBusiness[0].children.push(resultCategoryBusiness)
      newBusinessOcr[0].children.push(resultCategoryBusiness)
    }
    this.fetchRedactedRegexElementFromBulk(idDocument, newBusiness)
    this.props.allChildrenTreeBusiness(newBusiness)
    if (!this.props.isOcr) {
      this.props.onGetDictionary(newBusiness)
      this.props.setTreeDataDict(newBusiness)
    }
    this.setState({
      newBusiness,
      newBusinessOcr,
      autoExpandParent: false,
      expandedKeys: [ ...expandedKeys, ...Object.keys(categoryColours) ]
    }, () => {
      if (this.props.isOcr) {
         this.checkDocAnnotationCategoryOcrBusiness()
      }
    })
    // if (newCategoryBusiness && newCategoryBusiness.length === 0) {
    //   notification.info({
    //     message: `No Ai results found`,
    //     className: 'no-ai-results-found',
    //     duration: 3
    //   })
    // }
  }

  checkDocAnnotationCategoryOcrBusiness = () => {
    const { docAnnotationsOcr, newBusinessOcr } = this.state
    newBusinessOcr && newBusinessOcr[0].children && newBusinessOcr[0].children.length > 0 && newBusinessOcr[0].children.forEach((children1) => {
      if (docAnnotationsOcr && Object.keys(docAnnotationsOcr).length) {
        Object.keys(docAnnotationsOcr).forEach((category) => {
          let title = category && category.replace('doc_annotate_', '')
          title = title && title.replace('_', ' ')
          if (children1.key === title) {
            docAnnotationsOcr[category] && docAnnotationsOcr[category].length > 0 && docAnnotationsOcr[category].forEach((nameParent, index) => {
              const parentAllCategory = {
                backgroundColor: children1.backgroundColor,
                children: [],
                key: `${ nameParent }`,
                name: nameParent,
                index: index,
                title: nameParent,
                pageNumber: '1',
                isOcr: this.props.isOcr
              }
              children1.children.push(parentAllCategory)
            })
          }
        })
      }

    })
    this.props.onGetDictionary(newBusinessOcr)
    this.setState({
      newBusiness: newBusinessOcr
    })
  }

  checkDocAnnotationCategoryXeroxBusiness = () => {
    const { docAnnotationsBusinessXerox, newBusinessOcr } = this.state
    newBusinessOcr && newBusinessOcr[0].children && newBusinessOcr[0].children.length > 0 && newBusinessOcr[0].children.forEach((children1) => {
      if (docAnnotationsBusinessXerox && Object.keys(docAnnotationsBusinessXerox).length) {
        Object.keys(docAnnotationsBusinessXerox).forEach((category) => {
          let title = category && category.replace('doc_annotate_', '')
          title = title && title.replace('_', ' ')
          if (children1.key === title) {
            docAnnotationsBusinessXerox[category] && docAnnotationsBusinessXerox[category].length > 0 && docAnnotationsBusinessXerox[category].forEach((nameParent, index) => {
              const parentAllCategory = {
                backgroundColor: children1.backgroundColor,
                children: [],
                key: `${ nameParent }`,
                name: nameParent,
                index: index,
                title: nameParent,
                pageNumber: '1',
                isOcr: this.props.isOcr
              }
              children1.children.push(parentAllCategory)
            })
          }
        })
      }

    })
    this.props.onGetDictionary(newBusinessOcr)
    this.setState({
      newBusiness: newBusinessOcr
    }, () => {
      this.onBusinessCheckedKeysXerox()
    })
  }
  onBusinessCheckedKeysXerox = () => {
    const { newBusiness } = this.state
    if (this.props.xeroxUpload) {
      const checkedKeysXerox = []
      if (newBusiness[0].children && newBusiness[0].children.length > 0 && this.props.alreadyRedactedKeys.standard && this.props.alreadyRedactedKeys.standard.length > 0 ) {
        newBusiness[0].children.forEach((children1) => {
          children1 && children1.children && children1.children.length > 0 && children1.children.forEach((children2) => {
            if ((this.props.alreadyRedactedKeys.standard.includes(`${children2.name.toLowerCase()}-${children2.index}`)) ||
                this.props.alreadyRedactedKeys.standard.includes(`${children2.name.toLowerCase()}-all`)) {
              checkedKeysXerox.push(children2.key)
            }
          })
        })
      }
      this.setState({
        checkedKeys: checkedKeysXerox
      })
    }
  }
  onExpand = (expandedKeys) => {
    this.setState({
      expandedKeys,
      autoExpandParent: true,
    })
  };

  onSelect = (selectedKeys, info) => {
    this.setState({ selectedKeys })
  }

  extractLeafKeys = (node) => {
    const { unCheckedKeys } = this.state
    if (node.children && node.children.length) {
      node.children.forEach((item) => {
        this.extractLeafKeys(item.props)
      })
    } else {
      if (!unCheckedKeys.includes(node['data-key'])) {
        unCheckedKeys.push(node['data-key'])
      }
      this.setState({ unCheckedKeys })
    }
  }

  onCheck = (checkedKeys, { checkedNodes, checked, node }) => {
    const newFilterCategory = checkedNodes.map((node) => node.props.dataRef).filter((obj) => obj.identifier === 'category-parent')
    const { unCheckedKeys, sendStatusOnClearAll } = this.state

    if (this.props.isSmartBoxApplication) {
      this.props.checkOnClearAllBusiness(sendStatusOnClearAll)
    }

    if (!checked) {
      this.extractLeafKeys(node.props)
    } else {
      if (unCheckedKeys.length) {
        unCheckedKeys.forEach((item, index) => {
          if (checkedKeys.includes(item)) {
            unCheckedKeys.splice(index, 1)
          }
        })
      }
    }
    if (newFilterCategory.length > 0) {
      this.setState({
        disableRemoveCategory: true
      })
    } else {
      this.setState({
        disableRemoveCategory: false
      })
    }
    const nodesBusiness = checkedNodes.map((node) => node.props.dataRef).filter((obj) => obj.key)
    this.props.onCheckedKeysObjectRefsBoxTerms(nodesBusiness)
    this.setState({
      checkedKeys,
      unCheckedKeys,
      checkedKeysObjectRefs: checkedNodes.map((node) => node.props.dataRef).filter((obj) => obj.key),
      checkedKeysForDelete: checkedNodes.map((node) => node.props.dataRef).filter((obj) => obj.identifier === 'category-parent')
    })
  };

  onCheckDisableSelectAll = () => {
      return false
  }

  removeCategoryBusiness = (e) => {
    e.stopPropagation()
    const { expandedKeys, categoryColours, checkedKeysForDelete, newBusiness, checkedKeys } = { ...this.state }
    const newKeysDelete = []
    const newListNameRemove = []
    const keysDeleteBusiness = []
    let checkKeysDeleteFinally = []
    checkKeysDeleteFinally = checkedKeys
    checkedKeysForDelete && checkedKeysForDelete.forEach((check) => {
      keysDeleteBusiness.push(check.key)
      check && check.children.forEach((check2) => {
        keysDeleteBusiness.push(check2.key)
        check2 && check2.children.forEach((check3) => {
          keysDeleteBusiness.push(check3.key)
        })
      })
    })
    keysDeleteBusiness && keysDeleteBusiness.length > 0 && keysDeleteBusiness.forEach((newCheck) => {
      checkKeysDeleteFinally = checkKeysDeleteFinally.filter((check) => check !== newCheck)
    })
    for (const checkedKey of checkedKeysForDelete.map((cKey) => cKey.key)) {
      const aiAnnotations = document.querySelectorAll(`.${ checkedKey }`)
      for (const aiAnnotate of aiAnnotations) {
        aiAnnotate.style.background = 'transparent'
      }
      const options = {
        className: checkedKey
      }
      newKeysDelete.push(checkedKey)
      const instance = new Mark(document.querySelector('#pdf-page'))
      instance.unmark(options)
      newBusiness[0].children && newBusiness[0].children.map((check) => {
        if (check.key === checkedKey) {
          check.children && check.children.map((childrenKey) => {
            newListNameRemove.push(childrenKey.name)
            return true
          })
          return true
        }
        return true
      })

      newBusiness[0].children = newBusiness[0].children.filter((child) => child.key !== checkedKey)

      const data = {
        id: this.props.idDocument,
        listKeysDelete: newListNameRemove
      }

      if (this.props.isSmartBoxApplication) {
        removeCategoryBusiness(data).then(() => {
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
              console.log(error)
          }
        })
      }
      if (checkKeysDeleteFinally && checkKeysDeleteFinally.length === 1 && checkKeysDeleteFinally[0] === 'select-all') {
        this.setState({
          checkedKeys: [],
        })
      } else {
        this.setState({
          checkedKeys: checkKeysDeleteFinally,
        })
      }
      this.setState({
        categoryColours: _.omit(categoryColours, checkedKey),
        expandedKeys: expandedKeys.filter((eKey) => eKey !== checkedKey),
        autoExpandParent: false,
        newBusiness,
        checkedKeysForDelete: [],
        disableRemoveCategory: false
      })
    }
  }

  expandHideCategory = (e, key, action) => {
    e.stopPropagation()
    let { expandedKeys } = { ...this.state }
    if (action === 'add') {
      expandedKeys.push(key)
    } else {
      expandedKeys = expandedKeys.filter((eKey) => eKey !== key)
    }
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    })
  }

  findElementBusiness(selectedKeys, node) {
    let { expandedKeys } = { ...this.state }
    const checkNodeBusiness = []
    if (node && node.props.dataRef.key) {
      checkNodeBusiness.push(node.props.dataRef.key)
      this.setState({
        selectedChildrenBold: checkNodeBusiness,
        selectedPgNrBoldBusiness: checkNodeBusiness
      })
    }
    if (node.props.dataRef.key.includes('found-parent-all') || node.props.dataRef.identifier === 'category-parent') {
      if (!expandedKeys.includes(node.props.dataRef.key)) {
        expandedKeys.push(node.props['data-key'])
        this.setState({
          expandedKeys,
          autoExpandParent: false
        })
      } else {
        expandedKeys = expandedKeys.filter((eKey) => eKey !== node.props['data-key'])
        this.setState({
          expandedKeys,
          autoExpandParent: false
        })
      }
    } else {
      this.setState({
        selectedWord: selectedKeys && selectedKeys.length > 0 ? selectedKeys[0] : ''
      }, () => {
        if (this.state.selectedWord) {
          const { backgroundColor, pageNumber } = node.props.dataRef
          const oldElement = document.querySelector('#matched-word--current')
          if (oldElement) {
            oldElement.removeAttribute('id')
          }
          if (+pageNumber !== +this.props.pageNumber) {
            this.props.changePage(+pageNumber)
          }
          const element = document.getElementsByClassName(this.state.selectedWord)
          if (element && element.length > 0) {
            element[0].id = 'matched-word--current'
            element[0].style.borderColor = backgroundColor.replace('0.3', '1')
            element[0].scrollIntoView(false)
          }
        } else {
          const element = document.querySelector('#matched-word--current')
          element.removeAttribute('id')
        }
      })
    }
  }

  drawMatchedWords = (classList) => {
    const { newBusiness, checkedKeys, checkedKeysSaved } = this.state
    let { unCheckedKeys } = this.state
    const checkKeysRedacted = []
    const selectedKeysOcr = []
    this.props.onStatusRemoveDictionaries(false)
    if (this.props.isOcr) {
      this.state.newBusinessOcr && this.state.newBusinessOcr[0].children.length > 0 && this.state.newBusinessOcr[0].children.forEach((item) => {
        item && item.children && item.children.length > 0 && item.children.forEach((item2) => {
          if (item2 !== undefined) {
            checkedKeys && checkedKeys.forEach((key) => {
              if (item2.key === key) {
                selectedKeysOcr.push(item2.key)
              }
            })
          }
        })
      })
    }
    newBusiness && newBusiness[0].children.forEach((item) => {
      item && item.children.forEach((item2) => {
        item2 && item2.children.forEach((item3) => {
          classList && classList.forEach((check) => {
            if (item3.key === check) {
              checkKeysRedacted.push(check)
            }
            return true
          })
          return true
        })
        return true
      })
      return true
    })
    const keysRedacted = this.props.isOcr ? selectedKeysOcr : checkKeysRedacted
    const textMessageRedact = unCheckedKeys && unCheckedKeys.length > 0 ? `Remove ${ unCheckedKeys.length } selected redactions?` : keysRedacted && keysRedacted.length === 1 ? `Redact ${ keysRedacted.length } selected?` : `Redact all ${ keysRedacted.length } selected?`
    swal.fire({
      text: 'Redact all selected?',
      html: `  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 19 25">
                  <g fill="none" fillRule="evenodd">
                    <path stroke=" #00D3D8" strokeWidth="4" d="M18 24V7.215L11.716 1H1v23h17z"/>
                    <path stroke="#00D3D8"  strokeWidth="4" d="M11 1v7h7"/>
                    <path fill="#00D3D8" d="M5 12h9v3H5zM5 17h6v3H5z"/>
                  </g>
                </svg>
          <h3 class="swall-redact-title">${ textMessageRedact }</h3>`,
      showCancelButton: true,
      confirmButtonColor: '#3DD8DB',
      cancelButtonColor: '#3DD8DB',
      confirmButtonText: unCheckedKeys && unCheckedKeys.length > 0 ? 'Unredact' : 'REDACT',
      customClass: {
        container: 'redact-swall-wr'
      }
    }).then((result) => {
      if (result.value) {
        this.props.getStatusElementRedaction('elementDict')
        this.setState({
          checkedKeys,
          checkedKeysSaved: checkedKeys
        })
      } else {
        this.setState({
          checkedKeys: checkedKeysSaved
        })
      }
      if (result.value) {
        // this.props.toggleLoadingProcess(true, 'redact')
        if (this.props.isSmartBoxApplication && unCheckedKeys.length === 0) {
          this.onSaveKeysBusiness()
          this.onUpdateRedactionNumber(keysRedacted)
        }
        let existingRedactedKeys= new Set([...this.state.redactedKeys, ...classList])
        const newUnCheckedKeys = []
        if (unCheckedKeys && unCheckedKeys.length) {
          unCheckedKeys.forEach( (item) => {
            if (existingRedactedKeys.has(item)) {
              existingRedactedKeys.delete(item)
            }
            if (!classList.includes(item)) {
              newUnCheckedKeys.push(item)
            }
          })
        }
        this.setState({
          redactedKeys: existingRedactedKeys
        }, () => {
          this.props.drawAllMatchedWordsByClassList(classList, newUnCheckedKeys, 'dictionaries')
          this.setState({unCheckedKeys: []})
        })
      }
    })
  }
  onSaveKeysBusiness = () => {
    const { checkedKeys, newBusiness } = this.state
    const selectedKeys = []
    const selectedKeysOcrSaved = []
    if (this.props.isOcr) {
      this.state.newBusinessOcr && this.state.newBusinessOcr[0].children.length > 0 && this.state.newBusinessOcr[0].children.forEach((item) => {
        item && item.children && item.children.length > 0 && item.children.forEach((item2) => {
          if (item2 !== undefined) {
            checkedKeys && checkedKeys.forEach((key) => {
              if (item2.key === key) {
                selectedKeysOcrSaved.push(`${ item2.key }-1 => ${ item2.name } => ${ item2.pageNumber }`)
              }
            })
          }
        })
      })
    }
    newBusiness && newBusiness[0].children.forEach((item) => {
      item && item.children.forEach((item2) => {
        item2 && item2.children.forEach((item3) => {
          checkedKeys && checkedKeys.forEach((key) => {
            if (item3.key === key) {
              selectedKeys.push(`${ item3.key } => ${ item3.name } => ${ item3.pageNumber }`)
            }
            return true
          })
          return true
        })
        return true
      })
      return true
    })
    const data = {
      id: this.props.idDocument,
      listKeys: this.props.isOcr ? selectedKeysOcrSaved : selectedKeys
    }
    saveKeysRedactedBusiness(data).then(() => {

    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
          console.log(error)
      }
    })
    if (this.props.isOcr) {
      this.onKeysSavedOcrDictionary()
    }
    if (this.props.xeroxUpload) {
      this.onKeysSavedXeroxDictionary()
    }
  }
  onUpdateRedactionNumber = (classList) => {
    const data = {
      doc_id: this.props.idDocument,
      box_number_words_redacted: classList.length
    }
    apiSmartBoxUpdateRedactionNumber(data).then((response) => {

    })
  }
  onKeysSavedOcrDictionary = () => {
    const { checkedKeys } = this.state
    const keysOcr = []
    if (this.props.isOcr) {
      this.state.newBusinessOcr && this.state.newBusinessOcr[0].children.length > 0 && this.state.newBusinessOcr[0].children.forEach((item) => {
        item && item.children && item.children.length > 0 && item.children.forEach((item2) => {
          if (item2 !== undefined) {
            checkedKeys && checkedKeys.forEach((key) => {
              if (item2.key === key) {
                keysOcr.push(item2.key)
              }
            })
          }
        })
      })
    }
    const data = {
      idDocument: this.props.idDocument,
      keyWordOcrDictionary: keysOcr
    }
    apiSmartBoxSaveNewQueryStatusOcr(data).then((response) => {
      if (response.status === 200) {
        //this.props.fetchSmartBoxDataCanvasOcr()
        this.onCheckAllCanvasPoints()
      }

    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
          console.log(error)
      }
    })
  }
  onKeysSavedXeroxDictionary = () => {
    const { checkedKeys } = this.state
    const keysOcr = []
    if (this.props.isOcr) {
      this.state.newBusinessOcr && this.state.newBusinessOcr[0].children.length > 0 && this.state.newBusinessOcr[0].children.forEach((item) => {
        item && item.children && item.children.length > 0 && item.children.forEach((item2) => {
          if (item2 !== undefined) {
            checkedKeys && checkedKeys.forEach((key) => {
              if (item2.key === key) {
                keysOcr.push(item2.key)
              }
            })
          }
        })
      })
    }
    const data = {
      idDocument: this.props.idDocument,
      clientId: this.props.clientId,
      wordsToRedact: keysOcr,
    }
    apiSmartBoxSaveXeroxRedactionSmartView(data).then((response) => {
      if (response.status === 200) {
        //this.props.fetchSmartBoxDataCanvasOcr()
        //this.onCheckAllCanvasPoints()
        this.props.onGetCanvasPointsXeroxRedaction(response.data)
      }

    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  onCheckAllCanvasPoints = () => {
    const { setIntervalCheckPdfCanvas, responseCheckPdfCanvas} = this.state
    apiSmartBoxCheckAllCanvasPoints(this.props.idDocument).then((response) => {
      this.setState({
        responseCheckPdfCanvas: response.data
      })
      if(response.data === 'Processing') {
        this.setIntervalCheckAllCanvasPoints()
      } else {
        this.props.fetchSmartBoxDataCanvasOcr()
      }
    })
  }

  setIntervalCheckAllCanvasPoints = () => {
    const { responseCheckPdfCanvas} = this.state
    let { setIntervalCheckPdfCanvas} = this.state
    if (setIntervalCheckPdfCanvas === 0) {
      if (responseCheckPdfCanvas === 'Processing') {
        setIntervalCheckPdfCanvas = setInterval(this.callBackCheckAllCanvasPoints, 5000)
        this.setState({
          setIntervalCheckPdfCanvas
        })
      } else {
        clearInterval(setIntervalCheckPdfCanvas)
      }
    }
  }

  callBackCheckAllCanvasPoints = () => {
    const { setIntervalCheckPdfCanvas, responseCheckPdfCanvas} = this.state
    if(responseCheckPdfCanvas === 'Processing') {
      apiSmartBoxCheckAllCanvasPoints(this.props.idDocument).then((response) => {
        this.setState({
          responseCheckPdfCanvas: response.data
        })
        if (response.data === 'Finished') {
          this.props.fetchSmartBoxDataCanvasOcr()
        }
      })
    }
  }
  displayCheckKeysNonDeleteBusiness() {
    const { newListKeysBusiness } = this.props
    if (newListKeysBusiness) {
      return newListKeysBusiness
    }
  }

  onDisplayDifferenceColourOnActiveTab = () => {
    const { newBusiness } = this.state
    const { activeKeyTab, activeKey } = this.props
    newBusiness && newBusiness[0].children.map((keyColour) => {
      const aiAnnotations = document.querySelectorAll(`.${ keyColour.key }`)
      for (const aiAnnotate of aiAnnotations) {
        if (activeKeyTab === 'business' && activeKey === 'manualTab') {
          aiAnnotate.style.background = keyColour.backgroundColor
          aiAnnotate.style.borderColor = keyColour.backgroundColor ? keyColour.backgroundColor.replace('0.3', '1') : keyColour.backgroundColor
        } else {
          aiAnnotate.style.background = 'transparent'
          aiAnnotate.style.borderColor = 'initial'
        }
      }
      return true
    })
  }
  onRedactAiTag = () => {
    const { checkedKeysObjectRefs } = this.state
    //if (checkedKeysObjectRefs.length > 0) {
      const sortedByPageItems = checkedKeysObjectRefs.sort((a, b) => a.pageNumber > b.pageNumber ? 1 : -1)
      this.drawMatchedWords(sortedByPageItems.map((item) => item.key))
    //}
  }

  onFindElementTreeBusiness = (e, item) => {
    e.stopPropagation()
    let checkedKeysRef = []
    if (!this.props.isOcr) {
      if (item && item.children.length > 0) {
        item.children.forEach((item) => {
          checkedKeysRef.push(item)
        })
      } else {
        checkedKeysRef.push(item)
      }
      if (item && item.children && item.children.length > 0) {
        this.props.onCheckedKeysObjectRefsBoxTerms(checkedKeysRef)
      } else {
        this.findElementByClassChildren(item)
      }
      this.setState({
        selectedChildrenBold: item.key
      })
    }
  }

  findElementByClassChildren(className) {
    const element = document.getElementsByClassName(className.key)
    const oldElement = document.querySelector('#matched-word--current')
    if (className) {
      if (oldElement) {
        oldElement.removeAttribute('id')
      }
      if (+className.pageNumber !== +this.props.pageNumber) {
        this.props.changePage(+className.pageNumber)
      }
      if (element && element.length > 0) {
        element[0].id = 'matched-word--current'
        element[0].style.borderColor = className.backgroundColor.replace('0.3', '1')
        element[0].scrollIntoView(false)
      }
    } else {
      const element = document.querySelector('#matched-word--current')
      element.removeAttribute('id')
    }
  }

  onCheckSelectedTreeRedacted = () => {
    const { lockSmartViewChanges } = this.props

    if(!lockSmartViewChanges) {
      this.setState({
        disabledCheckBoxTree: true
      }, () => {
        this.onRedactAiTag()
      })
    }
  }
  onDisplayRedaction = (e, item) => {
    this.setState({
      itemSelectRedacted: item
    })
  }
  onLeaveMouseRedaction = () => {
    this.setState({
      itemSelectRedacted: {}
    })
  }
  onDefaultCategoryTop = (e) => {
    e.stopPropagation()
    return true
  }
  onShowVisibleCatDictionary = () => {
    const { newBusiness } = this.state
    const { showVisibleDictionary } = this.props
    newBusiness && newBusiness[0].children.length > 0 && newBusiness[0].children.forEach((keyColour) => {
      keyColour && keyColour.children.length > 0 && keyColour.children.forEach((keyColour2) => {
        keyColour2 && keyColour2.children.length > 0 && keyColour2.children.forEach((keyColour3) => {
         // const aiAnnotations = document.querySelectorAll(`.${ keyColour3.key }`)
          if (keyColour3 && keyColour3.key && showVisibleDictionary !== null) {
            const aiAnnotations = document.getElementsByClassName(keyColour3.key)
            for (const element of aiAnnotations) {
              if (showVisibleDictionary === false) {
                element.style.background = keyColour3.backgroundColor
                element.style.borderColor = keyColour3.backgroundColor ? keyColour3.backgroundColor.replace('0.3', '1') : keyColour3.backgroundColor
              } else if (showVisibleDictionary === true) {
                element.style.background = 'transparent'
                element.style.borderColor = 'initial'
              }
            }
          }
        })
      })
    })
  }
  render() {
    const { expandedKeys,
      redactedKeys,
      categoryColours,
      newBusiness,
      selectedChildrenBold,
      disableRemoveCategory,
      itemSelectRedacted, docAnnotations } = this.state
    let { checkedKeys } = this.state
    const { verificationOnClickClearAllBusiness, isOcr, getAllChildrenBusiness, xeroxUpload, lockSmartViewChanges } = this.props
    //this.onDisplayDifferenceColourOnActiveTab()
    if (verificationOnClickClearAllBusiness && this.props.isSmartBoxApplication) {
      checkedKeys = this.displayCheckKeysNonDeleteBusiness()
    }
    //this.onShowVisibleCatDictionary()
    const treeSaved = {}
    if (checkedKeys && checkedKeys.length > 0) {
      for (let element of checkedKeys) {
        element = element.substring(0, element.lastIndexOf('-'))
        if (treeSaved[element]) {
          treeSaved[element] += 1
        } else {
          treeSaved[element] = 1
        }
      }
    }
    return (
      <div className={ newBusiness[0].children && newBusiness[0].children.length > 0 ? "new-tabs-page update-list" : '' }>
        <div className="new-tab-container">
          {/*<div className="new-tab-container__title-tab">Business</div>*/}
          <div className="new-tab-container__content-tab image-tags-scroll ai-tags-scroll">
            <Tree
              checkable
              onExpand={ this.onExpand }
              defaultExpandAll={ true }
              expandedKeys={ expandedKeys }
              defaultExpandedKeys={ expandedKeys }
              autoExpandParent={ this.state.autoExpandParent }
              onCheck={ newBusiness && newBusiness[0].children.length === 0 ? this.onCheckDisableSelectAll : this.onCheck }
              checkedKeys={ checkedKeys }
              onSelect={ (selectedKeys, { node }) => this.findElementBusiness(selectedKeys, node) }
              selectedKeys={ this.state.selectedKeys }

            >
              { renderTreeBusiness(
                newBusiness[0].children,
                expandedKeys,
                this.expandHideCategory,
                redactedKeys,
                categoryColours,
                this.removeCategoryBusiness,
                selectedChildrenBold,
                checkedKeys,
                disableRemoveCategory,
                this.onDisplayRedaction,
                  itemSelectRedacted,
                this.onLeaveMouseRedaction,
              this.onCheckSelectedTreeRedacted,
              this.onFindElementTreeBusiness,
              this.onDefaultCategoryTop,
                  treeSaved,
                  isOcr,
                  xeroxUpload) }
            </Tree>
          </div>
        </div>
        {/*<ReviewRedact*/}
        {/*  isDocumentReady={ isDocumentReady }*/}
        {/*  changePage={ changePage }*/}
        {/*  checkedKeysObjectRefs={ checkedKeysObjectRefs }*/}
        {/*  pageNumber={ pageNumber }*/}
        {/*  drawAllMatchedWordsByClassList={ this.drawMatchedWords }*/}
        {/*/>*/}
      </div> 
    )
  }
}

Business.propTypes = {
  isDocumentReady: PropTypes.bool.isRequired,
  toggleLoadingProcess: PropTypes.func
}

export default Business