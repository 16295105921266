import React from 'react'
import { Field, reduxForm, submit } from 'redux-form'
import { apiSubjectAccessCreate, apiSubjectAccessSave } from '../../../api/Sar'
import { userIsSuperAdmin } from '../../../../src/utils'
import CustomNotificationManager from '../../Common/CustomNotificationManager'
import './Tracker.scss'
import { connect } from 'react-redux'
import {Spin, Checkbox, Form, Input, Tooltip, Icon} from 'antd'
import Loading from '../../Common/Loading'
import withRouter from '../../../private-routers/withRouter'

export class TrackerPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      title: 'New Subject Access Request',
      subject_name: '',
      notes: '',
      email: '',
      loading: false,
      displayTickyButton: false,
      sendEmailNotification: false,
      sendWebFormLink: false,
      checkedLink: true,
      checkedNotification: true,
      checkOnFocusInput: ''
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  saveDraftSar = (formValues) => {
    formValues.clientId = this.props.clientId
    this.setState({
      loading: true
    })
    apiSubjectAccessSave(formValues)
      .then(() => {
        this.setState({
          loading: false
        })
        CustomNotificationManager.success('Your data has been successfully saved.', 'Successfully saved')
        setTimeout(function() {
          pushToLinkDashboard()
        }, 1000)
        const pushToLinkDashboard = () => {
          this.props.navigate('/sar/dashboard')
        }
      }).catch((error) => {
        if (error?.response?.status === 504) {
          CustomNotificationManager.cleanBuffer('warning', '')
        }else{
          console.log(error)
        }
    })
  }

  onClickLinks = () => {
    this.setState({
      displayTickyButton: !this.state.displayTickyButton,
      sendEmailNotification: !this.state.sendEmailNotification,
      sendWebFormLink: !this.state.sendWebFormLink

    })
  }
    
  handleSubmit = (formValues) => {
    const { checkedLink, checkedNotification } = this.state
    this.setState({
      loading: true
    })
    const fullName = formValues.forename
    const values = fullName.split(' ')
    formValues.forename = values[0]
    formValues.surname = values[1] ? fullName.substr(fullName.indexOf(' ') + 1) : ''
    formValues.clientId = this.props.clientId
    formValues.sendEmailNotification = checkedNotification
    formValues.sendWebFormLink = checkedLink
    // if ((this.state.checkedLink === true && this.state.checkedNotification === true) ||
    //     (this.state.checkedLink === false && this.state.checkedNotification === false)) {
      apiSubjectAccessCreate(formValues)
          .then((response) => {
            CustomNotificationManager.success('The Subject Access Request is now created.', 'Successfully')
            setTimeout(function () {
              pushToLink()
            }, 1000)
            const pushToLink = () => {
              this.props.navigate(`/sar/tracker/${response.data.id}`)
            }
          })
          .catch((error) => {
            const bpLimit = 'Subscription limit reached upgrade plan to create more'
            if (error?.response) {
              if (error?.response?.status === 500) {
                if ((error.response.data.error_description) && (error.response.data.error_description.indexOf(bpLimit) > -1)) {
                  const errorMessage = (
                      <div>
                        {userIsSuperAdmin(this.props.user) ? (
                            <p>Subscription limit reached.</p>
                        ) : (
                            <p> {bpLimit}</p>
                        )
                        }
                      </div>
                  )
                  CustomNotificationManager.info(errorMessage, 'Info')
                }
              }
              if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
              }
            }else{
              console.log(error)
            }
            if (error.response && error.response.data && error.response.data.error_description && error.response.data.error_description === 'Subscription limit reached upgrade plan to create more') {
                this.setState({
                  loading: false
                })
            }
          })
    // }
  };

  renderError({ error, touched }) {
    if (touched && error) {
      return (
        <span className="error_container">
          <span className="error_item">{error}</span>
        </span>
      )
    }
  }
  autoFocusInput(e, input, label) {
    this.setState({
      checkOnFocusInput: label
    })
  }
  onBlurInput() {
    this.setState({
      checkOnFocusInput: ''
    })
  }
  renderInput = ({ input, label, type, placeholder, meta }) => {
    return (
      <div className="tracker-form-group full_width">
        <label htmlFor={ label }>{ label }</label>
        <Input { ...input } type={type} placeholder={ placeholder }/>
        <div className={ meta.active === true ? 'edit-input-form edit-input-form-focus' : 'edit-input-form' }>
          <span className="icon-v10-edit-b"></span>
        </div>
        {this.renderError(meta)}
      </div>
    )
  };

  renderSendEmail = ({ input, label }) => {
    return (
      <div className="tracker-form-link tracker-form-new-link">
        <label className="send-form" htmlFor={ label }>{ label }</label>
        <Checkbox className="float-left checked-send-email" { ...input } checked={this.state.checkedLink} onClick={this.onClickChecked}
        ></Checkbox>
      </div>
    )
  }
  renderSendNotification = ({ input, label }) => {
    return (
      <div className="tracker-form-link tracker-form-new-link">
        <label className="send-form" htmlFor={ label }>{ label }</label>
        <Checkbox className="float-left checked-send-email" { ...input } checked={this.state.checkedNotification} onClick={this.onClickCheckedNotification}
        ></Checkbox>
      </div>
    )
  }

  onClickChecked = () => {
    this.setState({
      checkedLink: !this.state.checkedLink
    })

  }
  onClickCheckedNotification = () => {
    this.setState({
      checkedNotification: !this.state.checkedNotification
    })

  }

  render() {
    const { loading } = this.state
    return (
      <div className="tracker-page new-tracker-page">
        <div className="tracker-box">
          <Form className="tracker-form" onSubmit={ this.props.handleSubmit(this.handleSubmit) }>
            <header className="tracker-step-header">
              <div className="tracker-step-header__top">
                <h1 className="tracker-step-header__top__step">Add new subject access request
                  <Tooltip overlayClassName="help-message-locations" title={<div className="content-help-message-locations">
                    <span className="text-information">Enter the subject’s details.</span>
                    <span className="more-info-link" onClick={ () => window.open('https://support.smartbox.ai/support/solutions/articles/47001174700', '_blank') }> More info. </span>
                  </div>}>
                    <span className="icon-v14-question"></span>
                  </Tooltip></h1>
              </div>
            </header>          
            <div className="tracker-step-content tracker-input">
              <Field type="text" name="forename" label="Subject Name *" component = { this.renderInput } />
              <Field type="text" name="title" label="Subject job title" component = { this.renderInput }/>
              <Field type="text" name="email" label="Subject email address *" component = { this.renderInput }/>
              {/*<Field type="text" name="privateEmail" label="Subject privat email address" component = { this.renderInput }/>*/}
              <Field type="text" name="telephone" label="Subject phone number" component = { this.renderInput }/>
              <Field type="text" name="dateRequest" label="Date requested" component = { this.renderInput }/>
              <Field type="text" name="adminNotes" label="Notes" component = { this.renderInput }/>
              <div className="tracker-content-links full_width">
                <Field type="text" name="sendWebFormLink" label="Send Webform Link" component = { this.renderSendEmail }/>
                <Field type="text" name="sendEmailNotification" label="Send Email Notification" component = { this.renderSendNotification }/>
              </div>

            </div>
            <div className="tracker-footer tracker-footer-update">
              {
                loading ?
                    <div>
                  <Spin className="float-right" spinning={loading}
                        indicator={<Icon type="loading" style={{ fontSize: 20, color:"#FFFFFF"}} spin />}/><button type="submit" className="float-right button-create-sar-loading ">Create SAR</button>
                </div>
                  :
                  <button
                  //     disabled={(this.state.checkedLink === true && this.state.checkedNotification === false) ||
                  // (this.state.checkedLink === false && this.state.checkedNotification === true)}
                          type="submit" className="button-blue-light float-right">Create SAR</button>
              }
            </div>
          </Form>
        </div>
      </div>
    )
  }
}
const validate = (formValues) => {
  const errors = {}
  if (!formValues.forename) {
    errors.forename = 'This field is required'
  } else if (formValues.forename.length < 3) {
    errors.forename = 'This field is too short'
  }
  if (!formValues.surname) {
    errors.surname = 'This field is required'
  } else if (formValues.surname.length < 3) {
    errors.surname = 'This field is too short'
  }

  if (!formValues.email) {
    errors.email = 'This field is required'
  }

  return errors
}

const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id,
  user: state.userReducer.user
})

TrackerPage = reduxForm({
  form: 'subject_access_form',
  validate,
  onSubmit: submit
})(TrackerPage)

TrackerPage = withRouter(TrackerPage)
export default connect(mapStateToProps)(TrackerPage)
