import PropTypes from 'prop-types'
import React, { Component } from 'react'
import './PaginationSmartBox.scss'
import {Input, Menu, Select, Tooltip, Dropdown, Radio, Checkbox, Icon, Button} from 'antd'
import { connect } from 'react-redux'
import {apiGetTagDocumentClient, getSuggestionsForAutocomplete, setFileStatus} from '../../api/SmartBox'
import {
  setSmartBoxCurrentFilterOption, setSmartBoxCurrentNewFilters, setSmartBoxCurrentNewFiltersSort,
  setSmartBoxCurrentSortOption, setSmartBoxFilterRefreshOpenedBox,
  setSmartBoxPageLimit, setSmartBoxSortSearch, setSmartBoxClickOutsideFilter, setStatusClearAllFiltersSmartbox,
  setSmartBoxSelectedKeys
} from '../../actions/smartBoxActions'
import { DropdownButton } from 'react-bootstrap'
import Dropdown2 from 'react-bootstrap/Dropdown';
import moment from 'moment'
import CustomNotificationManager from "./CustomNotificationManager";
import {components} from "react-select";
const { Option } = Select
export class PaginationSmartBox extends Component {

  state = {
    currentPage: 1,
    inputPage: '',
    isPrevDisabled: true,
    isNextDisabled: true,
    extendNavigation: false,
    showInsertPageNumber: false,
    filterOpen: false,
    pageLimit: 20,
    sort: 'creation date',
    filter: null,
    search: null,
    filterMenuVisible: false,
    value: '',
    dataAutocomplete: [],
    insideSearch: false,
    previewSearchTime: moment().unix(),
    wrapperRef: '',
    inputSearchText: '',
    onHoverFilter: false,
    onHoverDelete: false,
    onHoverStartActive: false,
    onHoverEndActive: false,
    onHoverShow: false,
    onHoverSelectAll: false,
    selectAllSelected: false,
    currentInputSearch: '',
    checkedStatus: [],
    checkedTag: [],
    valueSortBy: 'creation date',
    areFiltersApplied:false,
    onHoverReset: false,
    statusPaginationSmartBox: false,
    menuStatusVisible: false,
    onHoverStatus: false,
    // customerStatus: [
    //   { icon: 'icon-none', color: null, label: 'None', value: 'None', valueDisplay: '-'  },
    //   { icon: 'icon-v211-file-redacted', color: null, label: 'For Review', value: 'For Review', valueDisplay: '-'  },
    //   { icon: 'icon-v211-file-approved', color: null, label: 'Approved', value: 'Approved', valueDisplay: '-'  },
    //   { icon: 'icon-v211-mail', color: null, label: 'Sent', value: 'Sent', valueDisplay: '-' },
    //   { icon: 'icon-v211-db-clock', color: null, label: 'Awaiting decision', value: 'Awaiting decision', valueDisplay: '-' },
    //   { icon: null, color: '#F3CD03', label: 'Executive', value: 'Executive', valueDisplay: '-' },
    //   { icon: null, color: '#1CC500', label: 'Relevant', value: 'Relevant', valueDisplay: '-' },
    //   { icon: null, color: '#7D7D7D', label: 'Not relevant', value: 'Not relevant', valueDisplay: '-' },
    //   { icon: null, color: '#FF4242', label: 'Needs input', value: 'Needs input', valueDisplay: '-' },
    //   { icon: null, color: '#037EC2', label: 'Privileged', value: 'Privileged', valueDisplay: '-' },
    // ],
    customerStatus: [],
    defaultSelectedValue: '',
    defaultSelectedValueTag: '',
    isUpdateFolderStatusLoading: false,
    selectedColorFileStatus: '',
  }
  timer= 0

  constructor(props) {
    super(props)
    const { totalRecords, pageLimit } = this.props
    this.totalPages = Math.ceil(totalRecords / pageLimit)
  }

  componentDidMount() {
    // document.body.addEventListener('click', this.closeOptionsDropdown)
    //document.addEventListener('mousedown', this.handleClickOutside)
    if (this.props.smartBoxCurrentSortOption) {
      this.props.setSmartBoxCurrentSortOption(this.props.smartBoxCurrentSortOption)
      this.setState({
        sort: this.props.smartBoxCurrentSortOption,
        //valueSortBy: this.props.smartBoxCurrentSortOption,
      })
    }
    if (this.props.checkCloseSmartViewForPagination) {
      const totalPagesNumber = Math.ceil(this.props.totalRecords / this.props.pageLimit)
      const isPrevDisabled = this.props.currentPage === 1 ? true : false
      const isNextDisabled = this.props.currentPage === totalPagesNumber ? true : false
      this.setState({
        currentPage: this.props.currentPage,
        isPrevDisabled: isPrevDisabled,
        isNextDisabled: isNextDisabled
      })
    }
    if (this.props.smartBoxCurrentSortOption && this.props.sortSelectionCloseSmartview) {
      this.setState({
        sort: this.props.smartBoxCurrentSortOption,
        valueSortBy: this.props.smartBoxCurrentSortOption,
      })
    }
    this.props.setSmartBoxCurrentSortOption(this.state.sort)
    if (this.props.smartBoxHomeClicked) {
      this.props.setSmartBoxCurrentNewFilters({checkedStatus: [], checkedTag: []})
      this.props.setSmartBoxCurrentNewFiltersSort('creation date')
      this.setState({
        checkedStatus: [],
        checkedTag: [],
        //valueSortBy: 'creation date',
      })
    }
    if (this.props.sortSelectionCloseSmartview) {
      if (this.props.smartBoxFilterRefreshBox.search && this.props.smartBoxFilterRefreshBox.search !== null) {
        this.setState({
          inputSearchText: this.props.smartBoxFilterRefreshBox.search
        })
      }
        if (this.props.currentNewFilters.checkedStatus && this.props.currentNewFilters.checkedStatus.length > 0) {
          this.setState({
            checkedStatus: this.props.currentNewFilters.checkedStatus
          })
        }
      if (this.props.currentNewFilters.checkedTag && this.props.currentNewFilters.checkedTag.length > 0) {
        this.setState({
          checkedTag: this.props.currentNewFilters.checkedTag
        })
      }
    }
    this.getTagDocumentFilter()
  }
  // componentWillUnmount() {
    // document.body.removeEventListener('click', this.closeOptionsDropdown)
  // }

  componentDidUpdate(prevProps) {
    if (this.props.totalRecords !== prevProps.totalRecords && this.props.currentPage && this.props.totalRecords && this.props.pageLimit) {
      const totalPagesNumber = Math.ceil(this.props.totalRecords / this.props.pageLimit)
      const isPrevDisabled = this.props.currentPage === 1 ? true : false
      const isNextDisabled = this.props.currentPage === totalPagesNumber ? true : false
      this.setState({
        isPrevDisabled: isPrevDisabled,
        isNextDisabled: isNextDisabled,
        statusPaginationSmartBox: false
      })
      this.totalPages = totalPagesNumber
    }
    if (this.props.currentPage !== prevProps.currentPage && this.props.totalRecords && this.props.pageLimit) {
      const totPagesNumber = Math.ceil(this.props.totalRecords / this.props.pageLimit)
      const isPrevDisabled = this.props.currentPage === 1 ? true : false
      const isNextDisabled = this.props.currentPage === totPagesNumber ? true : false
      this.setState({
        isPrevDisabled: isPrevDisabled,
        isNextDisabled: isNextDisabled,
        statusPaginationSmartBox: false
      })
      this.totalPages = totPagesNumber
    }
    if (this.props !== prevProps && this.props.extendNavigation) {
      this.setState({ extendNavigation: this.props.extendNavigation })
    }
    if (this.props !== prevProps && this.props.currentPage) {
      if (this.props.setPreviousCurrentPage && this.props.setPreviousCurrentPage.statusPrevious === 'previous') {
        this.setState({ currentPage: this.props.setPreviousCurrentPage.previousPage,
          inputPage: this.props.setPreviousCurrentPage.previousPage
        })
      } else {
        this.setState({ currentPage: this.props.currentPage,
          inputPage: this.props.currentPage
        })
      }

    }
    if (this.props !== prevProps && this.props.listKeysFiles.length === 0) {
     this.setState({
       selectAllSelected: false
     })
    }
    if (this.props !== prevProps && this.props.smartBoxHomeClicked) {
      this.props.setSmartBoxCurrentNewFilters({checkedStatus: [], checkedTag: []})
      this.props.setSmartBoxCurrentNewFiltersSort('creation date')
      this.setState({
        checkedStatus: [],
        checkedTag: [],
        valueSortBy: 'creation date',
        areFiltersApplied:false,
      })
    }
    if (this.props !== prevProps && this.props.clickOutsideFiler) {
      this.setState({
        filterMenuVisible: false
      })
    }
    if (this.props.statusClearAllFiltersSmart !== prevProps.statusClearAllFiltersSmart && this.props.statusClearAllFiltersSmart) {
      this.setState({
        currentPage: 1,
        inputPage: 1
      })
    }
    if (this.props !== prevProps && this.state.menuStatusVisible && (this.props.listKeysFilesDeleted.length === 0 || this.props.listKeysFilesDeleted.length > 1)) {
        this.setState({ menuStatusVisible: false })
    }
  }

  getTagDocumentFilter = () => {
      apiGetTagDocumentClient(this.props.clientId).then((response) => {
        this.setState({
          customerStatus: response.data
        })
      })
  }

  getTagDocumentClient = () => {
    if (this.props.listKeysFilesDeleted.length > 0) {
      apiGetTagDocumentClient(this.props.clientId).then((response) => {
          this.setState({
            customerStatus: response.data
          })
      })
    }
  }
  closeOptionsDropdown = (e) => {
    if (typeof e.target.placeholder === 'undefined' || e.target.placeholder !== this.state.inputSearchText) {
      this.setState({ filterMenuVisible: false })
    }
  }

  handleClickOutside = (event) => {
    if (this.props.wrapperRef && this.props.wrapperRef.current && this.props.wrapperRef.current.contains(event.target)) {
      if (this.state.filterMenuVisible) {
        this.setState({ filterMenuVisible: false })
      }
    }
  }

  generateAutocompletedList = () => {
    const { search } = this.state
    const dataAutocomplete = []
    getSuggestionsForAutocomplete({ folderPath: this.props.folderPath, searchAutocomplete: search }).then((response) => {
      if (response.status === 200) {
        if (response.data) {
          if (Object.keys(response.data).length) {
            for (const item in response.data) {
              // dataAutocomplete.push(item + " " + response.data[item])
              dataAutocomplete.push(item)
            }
          }
          this.setState({ dataAutocomplete })
        }
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  goToInputPage = (page) => {
    const { totalRecords, pageLimit } = this.props
    if (isNaN(page)) {
      this.gotoPage(1)
    } else if (page < 1) {
      this.gotoPage(1)
    } else if (page > Math.ceil(totalRecords / pageLimit)) {
      this.gotoPage(Math.ceil(totalRecords / pageLimit))
    } else {
      this.gotoPage(page)
    }
  }

  gotoPage = (page) => {
    const { sort, search, filter, checkedStatus, checkedTag, valueSortBy } = this.state
    const { onPageChanged, pageLimit } = this.props
    const currentPage = Math.max(0, Math.min(page, this.totalPages))
    let paginationData = {}
    if (!this.props.root) {
      paginationData = {
        currentPage,
        pageLimit,
        sort,
        filter: this.props.smartBoxCurrentFilterOption === 'search' ? null : this.props.smartBoxCurrentFilterOption,
        search,
        checkedStatus,
        checkedTag,
        valueSortBy
      }
    } else {
      paginationData = {
        currentPage,
        pageLimit,
        sort,
        filter,
        search,
        checkedStatus,
        checkedTag,
        valueSortBy
      }
    }

    const isPrevDisabled = currentPage === 1 ? true: false
    const isNextDisabled = currentPage === this.totalPages
    const inputPage = currentPage
    this.setState({ currentPage, isPrevDisabled, isNextDisabled, inputPage }, () => onPageChanged(paginationData))
  }

  toggleFilter = (e) => {
    if (typeof e !== 'undefined') {
      e.stopPropagation()
    }
    this.setState({ filterMenuVisible: !this.state.filterMenuVisible })
  }
  toggleStatusMenu = (e) => {
    const { listKeysFilesDeleted } = this.props
    if (typeof e !== 'undefined') {
      e.stopPropagation()
    }
    if (listKeysFilesDeleted.length > 0) {
      this.getTagDocumentClient()
      this.setState({ menuStatusVisible: !this.state.menuStatusVisible })
    }
  }
  toggleInsideSearch = (open) => this.setState({ insideSearch: open })

  toggleSelectAll = () => {
    this.setState({
      selectAllSelected: !this.state.selectAllSelected
    }, () => {
      this.props.onSelectAll(this.state.selectAllSelected)
      this.props.setSmartBoxSelectedKeys([])
    })
  }
  clickOutsideFilterHandler = () => {
    const { insideSearch } = this.state
    if (this.state.filterMenuVisible && !insideSearch) {
      this.setState({ filterMenuVisible: false })
    }
  }

  handleMoveLeft = (e) => {
    e.preventDefault()
    this.gotoPage(this.state.currentPage - 1)
  }

  handleMoveRight = (e) => {
    e.preventDefault()
    this.gotoPage(this.state.currentPage + 1)
  }

  handleMovePage = (e, direction) => {
    const { isPrevDisabled, isNextDisabled, statusPaginationSmartBox } = this.state
    const { totalRecords, pageLimit, statusPagination, statusThumbnailForFiles, statusSearchApiBox, statusProgressBarData, statusFolderCountFiles } = this.props
    switch (direction) {
      case 'first':
        if (!isPrevDisabled && !statusPaginationSmartBox && statusPagination === 200 && statusSearchApiBox) {
          this.setState({
            statusPaginationSmartBox: true
          }, () => {
            window.scrollTo(0,0)
            this.gotoPage(1)
          })
        }

        break
      case 'previous':
        if (!isPrevDisabled && !statusPaginationSmartBox && statusPagination === 200 && statusSearchApiBox) {
          this.setState({
            statusPaginationSmartBox: true
          }, () => {
            window.scrollTo(0,0)
            this.gotoPage(this.state.currentPage - 1 ? this.state.currentPage - 1 : 1)
          })
        }

        break
      case 'next':

        if (!isNextDisabled && !statusPaginationSmartBox && statusPagination === 200 && statusSearchApiBox) {
          this.setState({
            statusPaginationSmartBox: true
          }, () => {
            window.scrollTo(0,0)
            this.gotoPage(this.state.currentPage + 1 > Math.ceil(totalRecords / pageLimit) ? Math.ceil(totalRecords / pageLimit) : this.state.currentPage + 1)
          })
        }

        break
      case 'last':
        if (!isNextDisabled && !statusPaginationSmartBox && statusPagination === 200 && statusSearchApiBox) {
          this.setState({
            statusPaginationSmartBox: true
          }, () => {
            window.scrollTo(0,0)
            this.gotoPage(Math.ceil(totalRecords/pageLimit))
          })
        }
        break
      default:
        this.setState({ showInsertPageNumber: true })
    }
  }

  handleClickFilterMenu = (event) => {
    const key = event.key
    let { currentPage, pageLimit, sort, search, filter } = this.state
    const oldFilter = filter
    //this.props.setSmartBoxFilterRefreshOpenedBox(key, key)
    switch (key) {
      case 'asc': sort = key;
      this.props.setSmartBoxFilterRefreshOpenedBox(filter, key, this.props.folderPath, search);
      this.props.setSmartBoxSortSearch(key);
      this.props.setSmartBoxCurrentFilterOption(null);
      currentPage = 1; break
      case 'desc': sort = key;
      this.props.setSmartBoxFilterRefreshOpenedBox(filter, key, this.props.folderPath, search);
        this.props.setSmartBoxSortSearch(key);
        this.props.setSmartBoxCurrentFilterOption(null);
      currentPage = 1; break
      case 'modified': sort = key;
      this.props.setSmartBoxFilterRefreshOpenedBox(filter, key, this.props.folderPath, search);
        this.props.setSmartBoxSortSearch(key);
        this.props.setSmartBoxCurrentFilterOption(null);
      currentPage = 1; break
      case 'creation': sort = key;
      this.props.setSmartBoxFilterRefreshOpenedBox(filter, key, this.props.folderPath, search);
        this.props.setSmartBoxSortSearch(key);
        this.props.setSmartBoxCurrentFilterOption(null);
      currentPage = 1; break
      default: filter = key;
          const filterSend = (['analysed', 'redacted', 'unredacted', 'finalised', 'approved', 'sent'].includes(filter) ) ? filter : ''
      this.props.setSmartBoxFilterRefreshOpenedBox(filterSend, sort, this.props.folderPath, search);
      if (!this.props.root) {
        this.props.setSmartBoxCurrentFilterOption(key)
      }
      currentPage = 1
    }

    // Deselect
    if (filter === oldFilter) {
      filter = null
    }
    const paginationData = {
      currentPage,
      pageLimit,
      sort,
      filter,
      search
    }
    if (key !== 'search') {
      this.toggleFilter()
      this.setState({ sort, filter }, () => {
        this.props.onPageChanged(paginationData)
        this.props.setSmartBoxCurrentSortOption(key)
        this.props.refreshDataLeftSort()
      })
    }
  }

  executeSearch = () => {
    const search = this.state.search ? this.state.search : null
    this.toggleFilter()
    this.handleFilterMenuSearch(search)
  }
  executeSearchOnchange = (e) => {
    if (e.target.value === '') {
      this.props.setSmartBoxFilterRefreshOpenedBox(null, null, null, null)
    }
    this.setState({
      currentInputSearch: e.target.value,
      inputSearchText: e.target.value
    })
  }
  executeAntdSearch = (e) => {
    this.setState({
      currentInputSearch: e
    })
    if (e) {
      this.toggleFilter()
      this.setState({ currentPage: 1 }, () => this.handleFilterMenuSearch(e))
    } else {
      e = null
      this.setState({ currentPage: 1 }, () => this.handleFilterMenuSearch(e))
      return
    }
  }

  handleSearchFilterChange = (e) => {
    clearTimeout(this.timer)
    if (e.length > 2) {
      this.timer = setTimeout(() => {
        this.generateAutocompletedList()
      }, 3000)
    }
    this.setState({ search: e })
  }

  handleFilterMenuSearch = (e) => {
    const { currentPage, pageLimit, sort, filter, checkedStatus, checkedTag, valueSortBy } = this.state
    const search = e
    const paginationData = {
      currentPage,
      pageLimit,
      sort,
      filter,
      search,
      checkedStatus,
      checkedTag,
      valueSortBy
    }
    this.toggleFilter()
    this.props.setSmartBoxFilterRefreshOpenedBox(filter, sort, this.props.folderPath, search);
    this.setState({ search, dataAutocomplete: [] }, () => this.props.onPageChanged(paginationData))
  }

  changePageLimit = (pageLimit) => {
    const { sort, search, filter, checkedStatus, checkedTag, valueSortBy } = this.state
    const { onPageChanged, totalRecords } = this.props
    const currentPage = 1
    const inputPage = 1

    this.totalPages = Math.ceil(totalRecords / pageLimit)
    const isNextDisabled = Math.ceil(totalRecords / pageLimit) === 1 ? true : false
    const paginationData = {
      currentPage,
      pageLimit,
      sort,
      search,
      filter,
      checkedStatus,
      checkedTag,
      valueSortBy
    }
    this.props.setSmartBoxPageLimit(pageLimit)
    this.setState({
      currentPage,
      inputPage,
      isPrevDisabled: true,
      isNextDisabled: isNextDisabled,
      pageLimit: pageLimit
    }, () => {
      this.props.setSmartBoxPageLimit(pageLimit)
      onPageChanged(paginationData)
    })
  }

  handleInputPageChange = (e) => this.setState({ inputPage: e.target.value })

  handleInputPageBlur = () => {
    const { inputPage } = this.state
    if (inputPage) {
      const paginationEnd = Math.ceil(this.props.totalRecords / this.props.pageLimit)
      if (inputPage > 0 && inputPage <= paginationEnd) {
        this.setState({
          statusPaginationSmartBox: true
        }, () => {
          window.scrollTo(0,0)
          this.gotoPage(inputPage)
        })
      } else {
        this.setState({
          statusPaginationSmartBox: true
        }, () => {
          window.scrollTo(0,0)
          this.gotoPage(1)
        })
        this.setState({ inputPage: 1 })
      }
    }
  }

  handleInputPageEnter = (e) => {
    if (e.key === 'Enter') {
      this.handleInputPageBlur()
    }
  }

  mockVal = (str, repeat = 1) => {
    return {
      value: str.repeat(repeat),
    }
  }

   onChangeRadioSortBy = (e) => {
     this.props.setSmartBoxCurrentSortOption(e.target.value)
     this.props.setSmartBoxCurrentNewFiltersSort(e.target.value)
     this.setState({
       valueSortBy: e.target.value,
       sort: e.target.value,
     }, () => {
       this.props.setSmartBoxCurrentSortOption(e.target.value)
       this.props.setSmartBoxCurrentNewFiltersSort(e.target.value)
       this.onNewRefreshFiltersPagination()
       if(this.state.sort !== 'creation date') {this.setState({
         areFiltersApplied:true
       })}
     })
   }

  onChangeCheckboxStatus = (checkedValues) => {
    const { checkedStatus, checkedTag } = this.state
    let { currentPage, pageLimit, sort, search, filter, valueSortBy } = this.state
    this.props.setSmartBoxCurrentNewFilters({checkedStatus: checkedValues, checkedTag: checkedTag})
    this.setState({
      checkedStatus: checkedValues,
    }, () => {
      const paginationFilter = {
        currentPage: checkedValues.length === 0 ? 1 : currentPage,
        pageLimit,
        sort,
        filter,
        search,
        checkedStatus: checkedValues,
        checkedTag,
        valueSortBy
      }
      this.props.onPageChanged(paginationFilter)
      if(checkedValues !== []) {
        this.setState({
          areFiltersApplied:true
        })
      }
      if(checkedValues.length === 0 && checkedTag.length === 0 && valueSortBy === 'creation date') {
        this.setState({
          areFiltersApplied:false,
        })
      }
      //this.onNewRefreshFiltersPagination()
      this.props.refreshDataLeftSort()
    })
  }

  onChangeCheckboxTag = (checkedValues) => {
    const { checkedTag, checkedStatus } = this.state
    let { currentPage, pageLimit, sort, search, filter, valueSortBy } = this.state
    this.props.setSmartBoxCurrentNewFilters({checkedStatus: checkedStatus, checkedTag: checkedValues})
    this.setState({
      checkedTag: checkedValues,
    }, () => {
      if(checkedTag !== []) {
        this.setState({
          areFiltersApplied:true
        })
      }
      const paginationData = {
        currentPage: checkedValues.length === 0 ? 1 : currentPage,
        pageLimit,
        sort,
        filter,
        search,
        checkedStatus,
        checkedTag: checkedValues,
        valueSortBy
      }
      // this.toggleFilter()
      this.props.onPageChanged(paginationData)
      if(checkedStatus.length === 0 && checkedValues.length === 0 && valueSortBy === 'creation date') {
        this.setState({
          areFiltersApplied:false,
        })
      }
      this.props.refreshDataLeftSort()
      //this.onNewRefreshFiltersPagination()
    })
  }

  onNewRefreshFiltersPagination = () => {
    let { currentPage, pageLimit, sort, search, filter, valueSortBy, checkedStatus, checkedTag } = this.state
    const paginationData = {
      currentPage,
      pageLimit,
      sort,
      filter,
      search,
      checkedStatus,
      checkedTag,
      valueSortBy
    }
      // this.toggleFilter()
        this.props.onPageChanged(paginationData)
    if(checkedStatus.length === 0 && checkedTag.length === 0 && valueSortBy === 'creation date') {
      this.setState({
        areFiltersApplied:false,
      })
    }
        //this.props.setSmartBoxCurrentSortOption(key)
        this.props.refreshDataLeftSort()
  }

  onClearFilters = () => {
    this.props.setSmartBoxCurrentNewFilters({checkedStatus: [], checkedTag: []})
    this.props.setSmartBoxCurrentNewFiltersSort('creation date')
    this.props.setSmartBoxFilterRefreshOpenedBox(null, null, null, null)
    this.props.setStatusClearAllFiltersSmartbox(true)
    this.setState({
      checkedStatus: [],
      checkedTag: [],
      valueSortBy: 'creation date',
      sort: 'creation date',
      inputSearchText: '',
      search: null,
      currentInputSearch: '',
      areFiltersApplied:false,
      currentPage: 1,
      inputPage: 1
    }, () => {
      this.props.setSmartBoxCurrentNewFilters({checkedStatus: [], checkedTag: []})
      this.props.setSmartBoxCurrentNewFiltersSort('creation date')
      this.props.setSmartBoxFilterRefreshOpenedBox(null, null, null, null)
      this.props.setStatusClearAllFiltersSmartbox(true)
      this.onNewRefreshFiltersPagination()
    })
  }
  renderFilterMenu = () => {
    const { inputSearchText, sort, checkedStatus, valueSortBy, checkedTag, currentInputSearch, customerStatus  } = this.state
    const { SubMenu } = Menu
    const { Search } = Input
    // this.props.setSmartBoxCurrentSortOption(sort)
    //this.props.setSmartBoxFilterRefreshOpenedBox(filter, sort)
    return (
      <Menu
        className={this.props.root ? "filter-menu filter-menu-box" : 'filter-menu filter-menu-box' }
        onClick={ (e) => this.handleClickFilterMenu(e) }
        style={{ width: 200 }}
        mode="vertical"
        selectedKeys={ [sort] }
        //defaultOpenKeys={['creation']}
        //defaultSelectedKeys={['creation']}
      >

        <Menu.Item key="search" className="search_input" onMouseEnter={ () => this.toggleInsideSearch(true) } onMouseLeave={ () => this.toggleInsideSearch(false) }>
          <Search allowClear
                  //placeholder={'Search'}
                  value={ currentInputSearch && currentInputSearch !== 'Search' ? currentInputSearch : inputSearchText && inputSearchText !== 'Search' ? inputSearchText : this.props.smartBoxFilterRefreshBox.search && this.props.smartBoxFilterRefreshBox.search !== null ? this.props.smartBoxFilterRefreshBox.search : '' }
              //defaultValue={ inputSearchText && inputSearchText !== 'Search' ? inputSearchText : this.props.smartBoxFilterRefreshBox.search && this.props.smartBoxFilterRefreshBox.search !== null ? this.props.smartBoxFilterRefreshBox.search : '' }
                  onSearch={ (e) => this.executeAntdSearch(e) }
                  onChange={ (e) => this.executeSearchOnchange(e) }/>
        </Menu.Item>
        <div className='sectionsNewFilter'>

          <div className='section-items-newFilter'>
            <div className='section-title'>Sort by</div>
            <Radio.Group value={ sort } onChange={this.onChangeRadioSortBy}>
              <Radio value='creation date'><span className='item-title'>Creation date</span></Radio>
              <Radio value='modified date'><span className='item-title'>Modified date</span></Radio>
              <Radio value='A-Z'><span className='item-title'>A-Z</span></Radio>
              <Radio value='Z-A'><span className='item-title'>Z-A</span></Radio>
            </Radio.Group>
        </div>

          <div className='section-items-newFilter'>
            <div className='section-title'>Status</div>
            <Checkbox.Group value={ checkedStatus } onChange={this.onChangeCheckboxStatus}>
              <Checkbox value='analysed'><span className='item-title'>Analysed</span></Checkbox>
              <Checkbox value='unanalysed'><span className='item-title'>In progress</span></Checkbox>
              { !this.props.root &&
              <Checkbox value='redacted'><span className='item-title'>Redacted</span></Checkbox>
              }
              { !this.props.root &&
              <Checkbox value='unredacted'><span className='item-title'>Unredacted</span></Checkbox>
              }
              { this.props.root &&
              <Checkbox value='bulk redacted'><span className='item-title'>Bulk redacted</span></Checkbox>
              }
              { this.props.root &&
              <Checkbox value='attached to sar'><span className='item-title'>Attached to SAR</span></Checkbox>
              }
            </Checkbox.Group>
          </div>

          <div className='section-items-newFilter'>
            <div className='section-title'>Tag</div>
            <Checkbox.Group value={ checkedTag } onChange={this.onChangeCheckboxTag}>
              { customerStatus && customerStatus.length > 0 && customerStatus.map((custom) => {
                return (
                    <Checkbox value={custom.tagName.toLowerCase()} key={custom.idTag}>
                      <span className="content-filter-items">
                        { custom.icon && custom.icon.includes('v211') ?
                            <span style={{backgroundColor: custom.color ? custom.color : 'icon-color'}}
                                  className={custom.icon ? custom.icon : 'icon-color'}></span> :
                            custom.color ?
                                <span style={{backgroundColor: custom.color ? custom.color : 'icon-color'}}
                                      className={custom.icon ? custom.icon : 'icon-color'}></span> :
                                <span><Icon type={`${custom.icon}`} /></span>
                        }
                      <span className='item-title'>{custom.tagName.length > 19 ? custom.tagName.substring(0, 13) + "..." : custom.tagName }</span>
                    </span></Checkbox>
                )
              })

              }
              {/*<Checkbox value='for review'><span className='item-title'>For Review</span></Checkbox>*/}
              {/*<Checkbox value='approved'><span className='item-title'>Approved</span></Checkbox>*/}
              {/*<Checkbox value='sent'><span className='item-title'>Sent</span></Checkbox>*/}
            </Checkbox.Group>
          </div>
        </div>
        <div className='clear-all-btn'>
          <span onClick={ () => this.onClearFilters() }>Clear all</span>
        </div>


          {/*{this.props.root &&*/}
          {/*<SubMenu*/}
          {/*    key="sub1"*/}
          {/*    title='Filter by status'*/}
          {/*    popupClassName="popup-position"*/}
          {/*    onTitleClick={({ key, domEvent}) => domEvent.stopPropagation()}*/}
          {/*>*/}
          {/*  <Menu.Item key="bulk">Bulk redacted</Menu.Item>*/}
          {/*  <Menu.Item key="unredacted">Unredacted</Menu.Item>*/}
          {/*  <Menu.Item key="attached">Attached to SAR</Menu.Item>*/}
          {/*  <Menu.Item key="review">For Review</Menu.Item>*/}
          {/*  <Menu.Item key="approved">Approved</Menu.Item>*/}
          {/*  <Menu.Item key="sent">Sent</Menu.Item>*/}
          {/*</SubMenu>*/}
          {/*}*/}

        {/*{ !this.props.root &&*/}
        {/*  <SubMenu*/}
        {/*    key="sub1"*/}
        {/*    title='Filter by status'*/}
        {/*    popupClassName="filter-submenu"*/}
        {/*    onTitleClick={({ key, domEvent}) => domEvent.stopPropagation()}*/}
        {/*  >*/}

        {/*    /!*<Menu.Item key="finalised">Finalised</Menu.Item>*!/*/}
        {/*    <Menu.Item key="approved">Approved</Menu.Item>*/}
        {/*    <Menu.Item key="sent">Sent</Menu.Item>*/}
        {/*  </SubMenu>*/}
        {/*}*/}
      </Menu>
    )
  }

  setStatus = (e) => {
    const { customerStatus } = this.state
    let selectTag = {}
    customerStatus && customerStatus.length > 0 && customerStatus.map((list) => {
      if (list.tagName === e) {
        selectTag = list
      }
    })
    this.setState({ selectedFileStatus: e, selectedColorFileStatus: e, defaultSelectedValue: e, defaultSelectedValueTag: selectTag })
  }
  generateIconsTitle = (item) => {
    switch (item) {
      case 'Redacted':
        return 'icon-v211-file-redacted'
        break
      case 'Approved':
        return 'icon-v211-file-approved'
        break
      case 'Sent':
        return 'icon-v211-mail'
        break
      case 'None':
        return 'icon-none'
        break
      case 'Awaiting decision':
        return 'icon-v211-db-clock'
        break
      default :
        return 'icon-color'
    }
  }

  updateFolderStatus = () => {
    const { defaultSelectedValue, customerStatus } = this.state
    const { listKeysFilesDeleted, filesTable } = this.props
    let nameSelected = ''
    let iconSelect = null
    let colorSelect = null
    let defaultSelectedValue2 = { }
    if (listKeysFilesDeleted && listKeysFilesDeleted.length > 0) {
      filesTable && filesTable.length > 0 && filesTable.forEach((list) => {
        if (listKeysFilesDeleted.includes(list.id)) {
          nameSelected = list.name
        }
      })
      customerStatus && customerStatus.length > 0 && customerStatus.forEach((list) => {
        if (defaultSelectedValue === list.tagName) {
          iconSelect = list.icon
          colorSelect = list.color
          defaultSelectedValue2 = list
        }
      })
      const parameters = {
        fileId: listKeysFilesDeleted,
        fileStatus: defaultSelectedValue.toLowerCase(),
        color: colorSelect,
        icon: iconSelect,
      }
      this.setState({
        isUpdateFolderStatusLoading: true,
      })
      setFileStatus(parameters).then((response) => {
        if (response.status === 200) {
          this.setState({
            isUpdateFolderStatusLoading: false,
            menuStatusVisible: false,
            defaultSelectedValue: '',
            defaultSelectedValueTag:'',
          })
          CustomNotificationManager.success(` Status for the ${nameSelected} was changed to ${defaultSelectedValue.toLowerCase() === 'redacted' ? 'For review' : defaultSelectedValue.toLowerCase()}.`, 'Status changed')
          //this.props.refreshStatus()
          this.props.onGetStatusTags(defaultSelectedValue2, listKeysFilesDeleted)
        }
      })
    }
  }
  generateIconsStatus = (item) => {
    switch (item.value) {
      case 'Redacted':
        return 'icon-v211-file-redacted'
        break
      case 'Approved':
        return 'icon-v211-file-approved'
        break
      case 'Sent':
        return 'icon-v211-mail'
        break
      case 'None':
        return 'icon-none'
        break
      case 'Awaiting decision':
        return 'icon-v211-db-clock'
        break
      default :
        return 'icon-color'
    }
  }

  generateColours = (item) => {
    switch (item) {
      case 'Executive':
        return '#F3CD03'
        break
      case 'Relevant':
        return '#1CC500'
        break
      case 'Not relevant':
        return '#7D7D7D'
        break
      case 'Needs input':
        return '#FF4242'
        break
      case 'Privileged':
        return '#037EC2'
        break
      default :
        return
    }
  }

  renderDropdownStatusInfo = () => {
    const { customerStatus, defaultSelectedValue, isUpdateFolderStatusLoading, selectedColorFileStatus, defaultSelectedValueTag } = this.state
    const { listKeysFilesDeleted } = this.props
    // const { Option } = components
    const IconOption = props => (
        <Option {...props}>
          <Icon type={props.data.icon} className="mr-1" />
          { props.data.label }
        </Option>
    )
    return (
        <React.Fragment>
          {customerStatus && customerStatus.length > 0 &&
          <div className="dropdown-status">
            <span className='title-status'>Status</span>
            {/*<Icon className="selected-icon"*/}
            {/*      type={defaultSelectedValue >= 0 ? customerStatus[defaultSelectedValue].icon : selectedColorFileStatus ? selectedColorFileStatus : ''}/>*/}
            <Select
                placeholder="Select"
                dropdownStyle={{border:'1px solid #eda41e',position:"fixed"}}
                dropdownMenuStyle={{overflowY:'auto', overflowX:'hidden',}}
                //value={ defaultSelectedValue }
                value={ <span className="content-value-status">
                  { defaultSelectedValueTag.icon && defaultSelectedValueTag.icon.includes('v211') ?
                      <span style={{backgroundColor: defaultSelectedValueTag.color}} className={defaultSelectedValueTag.icon}></span> :
                      defaultSelectedValueTag.color && defaultSelectedValueTag.color.includes('#') ?
                      <span style={{backgroundColor: defaultSelectedValueTag.color}} className={'icon-color'}></span> :
                          <span><Icon type={`${defaultSelectedValueTag.icon}`} /></span>
                  }
                  <span>{ defaultSelectedValue === '' ? 'Select' : defaultSelectedValue }</span></span> }
                className="custom-status-select"
                dropdownClassName="custom-status-select-options"
                //options={customerStatus}
                //components={{Option: IconOption}}
                onChange={(e) => this.setStatus(e)}>
                {
                  customerStatus.map((item, index) => {
                    return (
                        <Option className="item-options-status-new" key={index} value={item.tagName}>
                          <Tooltip title={item.tagName}>
                          { item.icon && item.icon.includes('v211') ?
                            <span style={{backgroundColor: item.color ? item.color : 'icon-color'}}
                                  className={item.icon ? item.icon : 'icon-color'}></span> :
                              item.color ?
                              <span style={{backgroundColor: item.color ? item.color : 'icon-color'}}
                                    className={item.icon ? item.icon : 'icon-color'}></span> :
                                  <span><Icon type={`${item.icon}`} /></span>
                          }
                          {item.tagName.length > 19 ? item.tagName.substring(0,14) + '...' : item.tagName }
                          </Tooltip>
                        </Option>
                    )
                  })
                }
            </Select>
            <Button className={isUpdateFolderStatusLoading ? "button-green-loading" : 'button-green'}
                    onClick={() => this.updateFolderStatus()}
                    disabled={ defaultSelectedValue === '' }
                    loading={isUpdateFolderStatusLoading}
            >Update</Button>
          </div>
          }
        </React.Fragment>
    )
  }
  onMouseEnterOnPanel(e, status) {
    this.props.setSmartBoxClickOutsideFilter(false)
    switch (status) {
      case 'filter' :
        this.setState({
          onHoverFilter: true
        })
        break
      case 'delete' :
        this.setState({
          onHoverDelete: true
        })
        break
      case 'start-active' :
        this.setState({
          onHoverStartActive: true,
        })
        break
      case 'end-active' :
        this.setState({
          onHoverEndActive: true
        })
        break
      case 'show' :
        this.setState({
          onHoverShow: true
        })
        break
      case 'select-all' :
        if (!this.state.selectAllSelected) {
          this.setState({
            onHoverSelectAll: true
          })
        }
        break
      case 'reset' :
        this.setState({
          onHoverReset: true
        })
      case 'status' :
        this.setState({
          onHoverStatus: true
        })
        break
      default: break
    }
  }
  onMouseLeaveOnPanel(e, status) {
    this.setState({
      onHoverFilter: false,
      onHoverDelete: false,
      onHoverStartActive: false,
      onHoverEndActive: false,
      onHoverShow: false,
      onHoverSelectAll: false,
      onHoverReset: false,
      onHoverStatus: false,
    })
  }
  getTotalNumberFiles = () => {
    const { filesTable, totalRecordsFinally } = this.props
    const { currentInputSearch } = this.state
    let numberFiles = 0
    const filesNumber = []
    filesTable && filesTable.length > 0 && filesTable.forEach((file) => {
        if (file.type !== 'DIRECTORY') {
          filesNumber.push(file)
        }
    })
    if (currentInputSearch) {
      numberFiles = totalRecordsFinally
    } else {
      numberFiles = filesNumber.length
    }
    return numberFiles
  }
  clickOutsideMenuFilter = (e) => {
    const { filterMenuVisible } = this.state
      this.setState({ filterMenuVisible: false })
  }
  onResetFilterBack = () => {
    this.props.setSmartBoxCurrentNewFilters({checkedStatus: [], checkedTag: []})
    this.props.setSmartBoxCurrentNewFiltersSort('creation date')
    this.props.setStatusClearAllFiltersSmartbox(true)
    this.setState({
      checkedStatus: [],
      checkedTag: [],
      valueSortBy: 'creation date',
      sort: 'creation date',
      inputSearchText: '',
      search: null,
      currentInputSearch: '',
      areFiltersApplied:false,
      currentPage: 1,
      inputPage: 1,
    }, () => {
      this.props.setSmartBoxCurrentNewFilters({checkedStatus: [], checkedTag: []})
      this.props.setSmartBoxCurrentNewFiltersSort('creation date')
      this.props.setStatusClearAllFiltersSmartbox(true)
      this.onNewRefreshFiltersPagination()
    })
  }
  render() {
    const { currentPage, isPrevDisabled, isNextDisabled, showInsertPageNumber, filterMenuVisible, inputPage, onHoverFilter, onHoverDelete, onHoverStartActive,
      onHoverEndActive, onHoverShow, onHoverSelectAll, selectAllSelected, areFiltersApplied, onHoverReset, statusPaginationSmartBox, currentInputSearch,
      menuStatusVisible, onHoverStatus, customerStatus } = this.state
    const { totalRecords, pageLimit, totalFolderOnRoot, totalRecordsFinally, root, statusPagination, statusFolderCountFiles, statusThumbnailForFiles ,
     statusSearchApiBox, statusProgressBarData, listKeysFilesDeleted } = this.props
    const { Search } = Input
    const { Option } = Select
    let totalFilesOnRoot = 0
    if (totalFolderOnRoot !== null) {
      totalFilesOnRoot = totalRecordsFinally - totalFolderOnRoot
    } else {
      totalFilesOnRoot = totalFolderOnRoot
    }
    if (!totalRecords || this.totalPages === 0) return null
    const paginationEnd = Math.ceil(totalRecords / pageLimit)
    let statusAllApis = null
    if (statusPagination === null && statusPaginationSmartBox && statusSearchApiBox === null) {
      statusAllApis = null
    } else if (statusPagination === 200 && statusSearchApiBox === 200 && !statusPaginationSmartBox) {
      statusAllApis = 200
    }
    return (
      <React.Fragment>
        { window.innerWidth < 600 ?
          <div className="filter-wrapper-contain">
            <div className="actionsDotsCell navigation-pagination-width">
              { this.props.currentNewFilters && ((this.props.currentNewFilters.checkedStatus && this.props.currentNewFilters.checkedStatus.length > 0) ||
                  (this.props.currentNewFilters.checkedTag && this.props.currentNewFilters.checkedTag.length > 0)) &&
              <div className="content-result-filter-root">
                <span className={ onHoverReset ? "icon-v14-refresh-active" : 'icon-v14-refresh-1' }
                      onClick={(e) => this.onResetFilterBack(e)}
                      onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'reset') }
                      onMouseLeave={ () => this.onMouseLeaveOnPanel() }></span>
                <span className="total-filter">{this.props.totalRecordsFinally}</span>
                <span className="name-results">Results</span>
              </div>
              }
              <DropdownButton
                className=""
                title={ <div className=""><span
                  className={ 'icon-v14-options-small-active' }></span></div> }
                nocaret="true"
                data-toggle="dropdown"
                id={ 'dropdown-1' }
                onClick={ (e) => {
                  e.stopPropagation()
                } }
                bsstyle="default">
                <Dropdown2.Item className="menu-left-item-directory">
                  <div className={ this.props.root ? 'filter-wrapper filter-wrapper-root' : 'filter-wrapper filter-wrapper-folder' } onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'filter') } onMouseLeave={ () => this.onMouseLeaveOnPanel() }>
                    <Dropdown overlay={ this.renderFilterMenu() } trigger={['hover']} onClick={ (event) => this.toggleFilter(event) } visible={ filterMenuVisible }>
                      <div>
                        <span className={ areFiltersApplied ? 'icon-v14-filter-dropdown update' :  onHoverFilter ? 'icon-v14-filter-dropdown-active' :'icon-v14-filter-dropdown' } onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'filter') }
                          onClick={ (e) => e.preventDefault() }/>
                        {/*<Icon type="down" onClick={ (e) => e.preventDefault() }/>*/}
                      </div>
                    </Dropdown>
                  </div>
                </Dropdown2.Item>
                <Dropdown2.Item className="menu-left-item-directory">
                  <div className='items-displayed-wrapper'>
                    <Select defaultValue={this.props.pageLimit} onChange={this.changePageLimit}
                    >
                      { this.props.options.map((option) => {
                        return <Option key={option} value={option}>{option}</Option>
                      })}
                    </Select>
                  </div>
                </Dropdown2.Item>
                <Dropdown2.Item className="menu-left-item-directory">
                  <div className="select-all-wrapper">
                    <span className={selectAllSelected ? 'icon-v14-select-all-selected' : (`icon-v14-select-all${ onHoverSelectAll ? '-active' : '' }`)}
                      onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'select-all') }
                      onMouseLeave={ () => this.onMouseLeaveOnPanel() }
                      onClick={ this.toggleSelectAll }>
                    </span>
                  </div>
                </Dropdown2.Item>
              </DropdownButton>
            </div>
            <div className="select-all-wrapper">
              <span onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'delete') }
                onMouseLeave={ () => this.onMouseLeaveOnPanel() }
                className={ this.props.listKeysFiles.length ? (!onHoverDelete ? 'icon-v14-delete hover-icon-delete' : 'icon-v14-delete-active') : 'icon-v14-delete disabled-icon' }
                onClick={ () => this.props.handleDeleteManyFile() }
              />
            </div>
          </div>
          :
          <div className="filter-wrapper-contain">
            <div className={ this.props.root ? 'filter-wrapper filter-wrapper-root' : 'filter-wrapper filter-wrapper-folder' } onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'filter') } onMouseLeave={ () => this.onMouseLeaveOnPanel() }>
              { this.props.currentNewFilters && ((this.props.currentNewFilters.checkedStatus && this.props.currentNewFilters.checkedStatus.length > 0) ||
                  (this.props.currentNewFilters.checkedTag && this.props.currentNewFilters.checkedTag.length > 0)) &&
              <div className="content-result-filter-root">
                <span className={ onHoverReset ? "icon-v14-refresh-active" : 'icon-v14-refresh-1' }
                      onClick={(e) => this.onResetFilterBack(e)}
                      onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'reset') }
                      onMouseLeave={ () => this.onMouseLeaveOnPanel() }></span>
                <span className="total-filter">{this.props.totalRecordsFinally}</span>
                <span className="name-results">Results</span>
              </div>
              }
              <Dropdown overlay={ this.renderFilterMenu() } trigger={['hover']} onClick={ (event) => this.toggleFilter(event) } visible={ filterMenuVisible }
                        overlayClassName={this.props.root ? "dropdown-filter-box" : 'dropdown-filter-file' }>
                <div>
                  <Tooltip title={'Filter'}>
                  <span className={ areFiltersApplied ? "icon-v14-filter-dropdown update" :  onHoverFilter ? 'icon-v14-filter-dropdown-active' :'icon-v14-filter-dropdown' } onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'filter') }
                    onClick={ (e) => e.preventDefault() }/>
                  </Tooltip>
                  {/*<Icon type="down" onClick={ (e) => e.preventDefault() }/>*/}
                </div>
              </Dropdown>
            </div>
            <div className='items-displayed-wrapper' onClick={() => this.clickOutsideMenuFilter()}>
              <div className={ `per-page icon-v14-show-${ this.props.pageLimit }${ onHoverShow ? '-active' : '' }` }
                onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'show') } onMouseLeave={ () => this.onMouseLeaveOnPanel() }>
                {/*Show*/}
                {/* left: 105px !important; */}
                <Select defaultValue={this.props.pageLimit} onChange={this.changePageLimit} style={{ left: this.props.smartBoxViewMode === 'list' ? '85px !important' : ''}}
                  dropdownClassName={`per-page-select${ root ? ` from-homepage ${!this.props.leftNavigationTreeShow}` : ` from-folder ${!this.props.leftNavigationTreeShow}` }`}>
                  { this.props.options.map((option) => {
                    return <Option key={option} value={option}>{option}</Option>
                  })}
                </Select>
              </div>
            </div>
            <div className="select-all-wrapper" onClick={() => this.clickOutsideMenuFilter()}>
              <span className={selectAllSelected ? 'icon-v14-select-all-selected' : (`icon-v14-select-all${ onHoverSelectAll ? '-active' : '' }`)}
                onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'select-all') }
                onMouseLeave={ () => this.onMouseLeaveOnPanel() }
                onClick={ this.toggleSelectAll }>
                {/*<Checkbox
                className={ this.props.smartBoxViewMode === 'list' ? 'disabled-icon' : '' }
                disabled= { this.props.smartBoxViewMode === 'list' ? true : false }
                checked={ this.props.checkKeysFiles }
                onChange={ this.props.onSelectAll }
              >
                Select all
              </Checkbox>*/}
              </span>
              <Tooltip title={'Delete'}>
              <span onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'delete') }
                onMouseLeave={ () => this.onMouseLeaveOnPanel() }
                className={ this.props.listKeysFiles.length ? (!onHoverDelete ? 'icon-v14-delete hover-icon-delete' : 'icon-v14-delete-active') : 'icon-v14-delete disabled-icon' }
                onClick={ () => this.props.handleDeleteManyFile() }
              />
              </Tooltip>

              <Dropdown overlay={ this.renderDropdownStatusInfo() } trigger={['hover']} onClick={ (event) => this.toggleStatusMenu(event) } visible={ menuStatusVisible }
                        overlayClassName={ "dropdown-status-box" }
                        disabled={listKeysFilesDeleted.length === 0}>
                <div>
                  <Tooltip title={'Tag'}>
                  <span className={
                      (listKeysFilesDeleted.length === 0) ? 'icon-v211-label-dropdown icon-v211-label-dropdown-disabled' :
                      (listKeysFilesDeleted.length > 0 && onHoverStatus) ? 'icon-v211-label-dropdown-active' :'icon-v211-label-dropdown' }
                        onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'status') }
                        onMouseLeave={ (e) => this.onMouseLeaveOnPanel(e, 'status') }
                        onClick={ (e) => e.preventDefault() }/>
                  </Tooltip>
                  {/*<Icon type="down" onClick={ (e) => e.preventDefault() }/>*/}
                </div>
              </Dropdown>

            </div>
          </div>
        }
        <div className='pagination-wrapper new-pagination-wrapper' onClick={() => this.clickOutsideMenuFilter()}>
          <div className="number-total-files-content">
            <span>{ totalFilesOnRoot === null ? '' : totalFilesOnRoot === 1 ? `${ totalFilesOnRoot } file` : this.getTotalNumberFiles() === 1 ? `${ this.getTotalNumberFiles() } file` :
                totalFilesOnRoot > 1 ? `${ totalFilesOnRoot } files` : this.getTotalNumberFiles() > 1 ? `${ this.getTotalNumberFiles() } files` : '' }</span>
          </div>
          <div className="custom-pagination-sb new-custom-pagination-sb">
            <div className="button-control">
              <Tooltip title="first" className={statusAllApis === null ? 'disabled' : (statusAllApis && isPrevDisabled ) ? 'disabled' : ''}>
                <span onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'start-active')}
                  onMouseLeave={ () => this.onMouseLeaveOnPanel() }
                  className={ (statusAllApis === null) ? 'icon-v14-start-1 disabled' : (statusAllApis && statusPaginationSmartBox) ? 'icon-v14-start-1 disabled' :
                      (statusAllApis && isPrevDisabled ) ? 'icon-v14-start-1 disabled' : onHoverStartActive ? 'icon-v14-start-active' : 'icon-v14-start-1' } onClick={ (e) => this.handleMovePage(e, 'first')}/>
              </Tooltip>
              <Tooltip title="previous" className={(statusAllApis === null) ? 'disabled' : (statusAllApis && isPrevDisabled) ? 'disabled' : ''}>
                <span className={(statusAllApis === null) ? 'icon-v14-back-1 disabled' : (statusAllApis && statusPaginationSmartBox) ? 'icon-v14-back-1 disabled' :
                    (statusAllApis && isPrevDisabled ) ? 'icon-v14-back-1 disabled' : 'icon-v21-back-active' } onClick={ (e) => this.handleMovePage(e, 'previous')}/>
              </Tooltip>
              { paginationEnd === 1 &&
              <span className='button-control__text button-control-content'>{currentPage} of {paginationEnd} </span>
              }
              { paginationEnd > 1 &&
              <span className='button-control__text button-control-content'>
                <input
                  type="number"
                  className="insert-page"
                  placeholder= { currentPage }
                  value= { inputPage }
                  onChange={ (e) => this.handleInputPageChange(e) }
                  onBlur={ () => this.handleInputPageBlur() }
                  onKeyDown={ (e) => this.handleInputPageEnter(e) }
                  min="1"
                  disabled={ statusPaginationSmartBox === true }
                  max={paginationEnd}
                />
                <span>of {paginationEnd}</span>
              </span>
              }
              <Tooltip title="next" className={(statusAllApis === null) ? 'disabled' : (statusAllApis && isNextDisabled ) ? 'disabled' : ''}>
                <span className={(statusAllApis === null) ? 'icon-v14-forth-1 disabled' : (statusAllApis && statusPaginationSmartBox) ? 'icon-v14-forth-1 disabled' :
                    (statusAllApis && isNextDisabled) ? 'icon-v14-forth-1 disabled' : 'icon-v21-forth-active' } onClick={ (e) => this.handleMovePage(e, 'next')}/>
              </Tooltip>
              <Tooltip title="last" className={(statusAllApis === null) ? 'disabled' : (statusAllApis && isNextDisabled) ? 'disabled' : ''}>
                <span onMouseLeave={ () => this.onMouseLeaveOnPanel() }
                  onMouseEnter={ (e) => this.onMouseEnterOnPanel(e, 'end-active')}
                  className={(statusAllApis === null) ? 'icon-v14-end-1 disabled' : (statusAllApis && statusPaginationSmartBox) ? 'icon-v14-end-1 disabled' :
                      (statusAllApis && isNextDisabled) ? 'icon-v14-end-1 disabled' : onHoverEndActive ? 'icon-v14-end-active' : 'icon-v14-end-1' } onClick={ (e) => this.handleMovePage(e, 'last')}/>
              </Tooltip>
            </div>
            { showInsertPageNumber &&
            <Search
              className='insert-page'
              placeholder={`Insert page number (max ${ Math.ceil(totalRecords / pageLimit) } )`}
              onSearch={(value) => this.goToInputPage(value)}
              style={{ width: 250 }}
            />
            }
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  smartBoxViewMode: state.smartBox.smartBoxViewMode,
  user: state.userReducer.user,
  smartBoxCurrentSortOption: state.smartBox.smartBoxCurrentSortOption,
  sortSelectionCloseSmartview: state.smartBox.sortSelectionCloseSmartview,
  smartBoxCurrentFilterOption: state.smartBox.smartBoxCurrentFilterOption,
  smartBoxFilterRefreshBox: state.smartBox.smartBoxFilterRefreshBox,
  leftNavigationTreeShow: state.smartBox.leftNavigationTreeShow,
  smartBoxHomeClicked: state.smartBox.smartBoxHomeClicked,
  currentNewFilters: state.smartBox.currentNewFilters,
  clickOutsideFiler: state.smartBox.clickOutsideFiler,
  statusClearAllFiltersSmart: state.smartBox.statusClearAllFiltersSmart,
  clientId: state.subscriptionReducer.subscription.subscription.client.id,
  setPreviousCurrentPage: state.smartBox.setPreviousCurrentPage,
})

PaginationSmartBox.propTypes = {
  totalRecords: PropTypes.number.isRequired,
  pageLimit: PropTypes.number,
  onPageChanged: PropTypes.func
}

export default connect(mapStateToProps,{
  setSmartBoxPageLimit, setSmartBoxCurrentSortOption, setSmartBoxFilterRefreshOpenedBox,
  setSmartBoxSortSearch, setSmartBoxCurrentFilterOption, setSmartBoxCurrentNewFilters, setSmartBoxCurrentNewFiltersSort,
  setSmartBoxClickOutsideFilter, setStatusClearAllFiltersSmartbox, setSmartBoxSelectedKeys
})(PaginationSmartBox)