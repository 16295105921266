export const normal = (order) => ({
  errors: {},
  disabled: false,
  jumpsToQuestion: '',
  hasMessage: false,
  goToQuestion: 'nextQuestion',
  message: '',
  order,
  score: 0,
  title: '',
  type: 'NORMAL',
  subType: 'NORMAL',
  displaySection: '',
  jumpsToEnd: false
})

export const yn = (type, order) => ({
  errors: {},
  disabled: true,
  jumpsToQuestion: '',
  goToQuestion: 'nextQuestion',
  hasMessage: false,
  message: '',
  order,
  score: 0,
  title: type,
  type: 'NORMAL',
  subType: 'NORMAL',
  displaySection: '',
  jumpsToEnd: false
})

export const noneOfTheAbove = (order) => ({
  errors: {},
  disabled: true,
  jumpsToQuestion: '',
  goToQuestion: 'nextQuestion',
  hasMessage: false,
  message: '',
  order,
  score: 0,
  title: 'None of the above',
  type: 'NONE_OF_THE_ABOVE',
  subType: 'NONE_OF_THE_ABOVE',
  displaySection: '',
  jumpsToEnd: false
})

export const otherTextInput = (order) => ({
  errors: {},
  disabled: true,
  jumpsToQuestion: '',
  goToQuestion: 'nextQuestion',
  hasMessage: false,
  message: '',
  order,
  score: 0,
  title: 'Other',
  type: 'OTHER',
  subType: 'OTHER',
  displaySection: '',
  jumpsToEnd: false
})

export const iDontKnow = (order) => ({
  errors: {},
  disabled: true,
  jumpsToQuestion: '',
  goToQuestion: 'nextQuestion',
  hasMessage: false,
  message: '',
  order,
  score: 0,
  title: 'I don\'t know',
  type: 'NORMAL',
  subType: 'I_DONT_KNOW',
  displaySection: '',
  jumpsToEnd: false
})

export const divider = (order, title = '') => ({
  errors: {},
  order,
  title,
  subType: 'DIVIDER'
})