import React from 'react'
import PropTypes from 'prop-types'

import './Pagination.css'
import '../../Common/FontAwesome.min.css'

class Pagination extends React.Component {
  renderActivePagePrevious = (activePage, pages) => {
    const previous = [...Array(activePage - 1)]
    if (previous.length < 3) return (
      previous.map((e, index) => {
        const page = index + 1
        return (
          <li
            className="pagination__item"
            key={ `page-${ page }` }
            onClick={ this.props.changePagination.bind(this, page) }>
            { page }
          </li>
        )
      })
    )

    const values = [activePage - 2, activePage - 1]
    return (
      <React.Fragment>
        <li className="pagination__ellipsis">...</li>
        { values.map((value) => (
          <li
            className="pagination__item"
            key={ `page-${ value }` }
            onClick={ this.props.changePagination.bind(this, value) }>
            { value }
          </li>
        )) }
      </React.Fragment>
    )
  }

  renderActivePage = (activePage) => {
    return (
      <li
        className="pagination__item--active"
        key={ `page-${ activePage }` }>
        { activePage }
      </li>
    )
  }

  renderActivePageNext = (activePage, pages) => {
    if (pages === 0) return null

    const next = [...Array(pages - activePage)]
    if (next.length < 3) return (
      next.map((e, index) => {
        const page = activePage + index + 1
        return (
          <li
            className="pagination__item"
            key={ `page-${ page }` }
            onClick={ this.props.changePagination.bind(this, page) }>
            { page }
          </li>
        )
      })
    )

    const values = [activePage + 1, activePage + 2]
    return (
      <React.Fragment>
        { values.map((value, index) => (
          <li
            className="pagination__item"
            key={ `page-${ value }` }
            onClick={ this.props.changePagination.bind(this, value) }>
            { value }
          </li>
        )) }
        <li className="pagination__ellipsis">...</li>
      </React.Fragment>
    )
  }

  render = () => {
    const { activePage, changePagination, pages } = this.props

    return (
      <ul className="pagination__wrapper">
        <PreviousPage activePage={ activePage } changePagination={ changePagination } />
        { this.renderActivePagePrevious(activePage, pages) }
        { this.renderActivePage(activePage, pages) }
        { this.renderActivePageNext(activePage, pages) }
        <NextPage
          activePage={ activePage }
          changePagination={ changePagination }
          pages={ pages } />
      </ul> 
    )
  }
}

const PreviousPage = ({ activePage, changePagination }) => {
  if (activePage === 1) return (
    <li className="pagination__nav--disabled fa fa-angle-left">
    </li>
  )

  return (
    <li
      className="pagination__nav fa fa-angle-left"
      onClick={ changePagination.bind(this, activePage - 1) }>
    </li>
  )
}

const NextPage = ({ activePage, changePagination, pages }) => {
  if (activePage === pages) return (
    <li className="pagination__nav--disabled fa fa-angle-right">
    </li>
  )

  return (
    <li
      className="pagination__nav fa fa-angle-right"
      onClick={ changePagination.bind(this, activePage + 1) }>
    </li>
  )
}

Pagination.propTypes = {
  activePage: PropTypes.number.isRequired,
  changePagination: PropTypes.func.isRequired,
  pages: PropTypes.number.isRequired
}

PreviousPage.propTypes = {
  activePage: PropTypes.number.isRequired,
  changePagination: PropTypes.func.isRequired
}

NextPage.propTypes = {
  activePage: PropTypes.number.isRequired,
  changePagination: PropTypes.func.isRequired,
  pages: PropTypes.number.isRequired
}

export default Pagination