import axios from 'axios'

const isLocalhost = () => {
  return window.location.hostname === 'localhost'
}
//const host = isLocalhost() ? 'https://staging.ospreysmart.com' : ''
const host = isLocalhost() ? 'http://localhost:8080' : ''
const dpiaHost = isLocalhost() ? 'http://localhost:8081' : ''

export const apiDpiaArchiveProcess = (data, processId) => {
  const dpiaToken = localStorage.getItem('dpiaToken')
  // const accessToken = localStorage.getItem('accessToken')
  const accessToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId

  Object.assign(data, { osprey_access_token: accessToken })

  return axios({
    method: 'post',
    url: `${ dpiaHost }/dpia-0.1.0/2.0/businessProcesses/${ processId }?action=ARCHIVE`,
    headers: {
      'Authorization': `Bearer ${ dpiaToken }`
    },
    data
  })
}

export const apiDpiaGetBusinessProcessByTeamID = (data, teamId) => {
  const dpiaToken = localStorage.getItem('dpiaToken')
  // const accessToken = localStorage.getItem('accessToken')
  const accessToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId


  Object.assign(data, { osprey_access_token: accessToken })
  return axios({
    method: 'post',
    url: `${ dpiaHost }/dpia-0.1.0/2.0/businessProcessesTeams/${ teamId }?action=GET`,
    headers: {
      'Authorization': `Bearer ${ dpiaToken }`
    },
  })
}

export const apiDpiaFetchToken = () => {
  return axios.post(`${ host }/osprey-0.1.0/v1/osprey/token-validate`, {})
}

export const apiDpiaQuestionSave = (asessmentId, questionId, data) => {
  const dpiaToken = localStorage.getItem('dpiaToken')
  // const accessToken = localStorage.getItem('accessToken')
  const accessToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId


  Object.assign(data, { osprey_access_token: accessToken })

  return axios({
    method: 'post',
    url: `${ dpiaHost }/dpia-0.1.0/2.0/assessments/${ asessmentId }/questions/${ questionId }?action=SUBMIT`,
    headers: {
      'Authorization': `Bearer ${ dpiaToken }`
    },
    data
  })
}

export const apiDpiaQuestionSkip = (asessmentId, questionId, data) => {
  const dpiaToken = localStorage.getItem('dpiaToken')
  // const accessToken = localStorage.getItem('accessToken')
  const accessToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId


  Object.assign(data, { osprey_access_token: accessToken })

  return axios({
    method: 'post',
    url: `${ dpiaHost }/dpia-0.1.0/2.0/assessments/${ asessmentId }/questions/${ questionId }?action=SKIP`,
    headers: {
      'Authorization': `Bearer ${ dpiaToken }`
    },
    data
  })
}

export const apiDpiaFetchAssessment = (data, assessmentId) => {
  const dpiaToken = localStorage.getItem('dpiaToken')
  // const accessToken = localStorage.getItem('accessToken')
  const accessToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId


  Object.assign(data, { osprey_access_token: accessToken })

  return axios({
    method: 'post',
    url: `${ dpiaHost }/dpia-0.1.0/2.0/assessments/${ assessmentId }?action=GET`,
    headers: {
      'Authorization': `Bearer ${ dpiaToken }`
    },
    data
  })
}

export const apiDpiaFetchQuestions = (data, assessmentId) => {
  const dpiaToken = localStorage.getItem('dpiaToken')
  // const accessToken = localStorage.getItem('accessToken')
  const accessToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId


  Object.assign(data, { osprey_access_token: accessToken })

  return axios({
    method: 'post',
    url: `${ dpiaHost }/dpia-0.1.0/2.0/assessments/${ assessmentId }/questions?action=GET`,
    headers: {
      'Authorization': `Bearer ${ dpiaToken }`
    },
    data
  })
}

export const apiDpiaFetchQuestionsCSV = (data, assessmentId) => {
  const dpiaToken = localStorage.getItem('dpiaToken')
  // const accessToken = localStorage.getItem('accessToken')
  const accessToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId


  Object.assign(data, { osprey_access_token: accessToken })

  return axios({
    method: 'post',
    url: `${ dpiaHost }/dpia-0.1.0/2.0/assessments/${ assessmentId }/questions.csv?action=GET&withoutQuestionnaireSkipped=true`,
    headers: {
      'Authorization': `Bearer ${ dpiaToken }`,
      'Accept': 'text/csv'
    },
    data
  })
}

export const apiDpiaFetchActiveQuestion = (data, assessmentId, questionId) => {
  const dpiaToken = localStorage.getItem('dpiaToken')
  // const accessToken = localStorage.getItem('accessToken')
  const accessToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId


  Object.assign(data, { osprey_access_token: accessToken })

  return axios({
    method: 'post',
    url: `${ dpiaHost }/dpia-0.1.0/2.0/assessments/${ assessmentId }/questions/${ questionId }?action=GET`,
    headers: {
      'Authorization': `Bearer ${ dpiaToken }`
    },
    data
  })
}

export const apiDpiaFetchProcessesList = (data) => {
  
  const dpiaToken = localStorage.getItem('dpiaToken')
  // const accessToken = localStorage.getItem('accessToken')
  const accessToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId

  Object.assign(data, { osprey_access_token: accessToken })
  return axios({
    method: 'post',
    url: `${ dpiaHost }/dpia-0.1.0/2.0/businessProcesses?action=GET&status=ACTIVE`,
    headers: {
      'Authorization': `Bearer ${ dpiaToken }`
    },
    data
  })
}

export const apiDpiaFetchGroupedProcessesList = (data) => {

  const dpiaToken = localStorage.getItem('dpiaToken')
  // const accessToken = localStorage.getItem('accessToken')
  const accessToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId

  Object.assign(data, { osprey_access_token: accessToken })
  return axios({
    method: 'post',
    url: `${ dpiaHost }/dpia-0.1.0/2.0/businessProcessesGrouped?action=GET&status=ACTIVE`,
    headers: {
      'Authorization': `Bearer ${ dpiaToken }`
    },
    data
  })
}

export const apiDpiaFetchArchivedProcessesList = (data) => {
  const dpiaToken = localStorage.getItem('dpiaToken')
  // const accessToken = localStorage.getItem('accessToken')
  const accessToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId

  Object.assign(data, { osprey_access_token: accessToken })

  return axios({
    method: 'post',
    url: `${ dpiaHost }/dpia-0.1.0/2.0/businessProcessesGrouped?action=GET&status=INACTIVE`,
    headers: {
      'Authorization': `Bearer ${ dpiaToken }`
    },
    data
  })
}

export const apiOspreyInviteeDetails = (inviteeId) => {
  // const accessToken = localStorage.getItem('accessToken')
  const accessToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId

  return axios({
    method: 'get',
    url: `${ host }/osprey-0.1.0/2.0/invitees/${ inviteeId }?allLevels=true`,
    headers: {
      'Authorization': `Bearer ${ accessToken }`
    }
  })
}

export const apiDpiaFetchProcess = (data, processId) => {
  const dpiaToken = localStorage.getItem('dpiaToken')
  // const accessToken = localStorage.getItem('accessToken')
  const accessToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId

  Object.assign(data, { osprey_access_token: accessToken })

  return axios({
    method: 'post',
    url: `${ dpiaHost }/dpia-0.1.0/2.0/businessProcesses/${ processId }?action=GET&withLastAssessmentTypeOnly=true`,
    headers: {
      'Authorization': `Bearer ${ dpiaToken }`
    },
    data
  })
}

export const apiDpiaProcessSave = (data, url) => {
  const dpiaToken = localStorage.getItem('dpiaToken')
  // const accessToken = localStorage.getItem('accessToken')
  const accessToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId

  Object.assign(data, { osprey_access_token: accessToken })

  url = `${ dpiaHost }${ url }`

  return axios({
    method: 'post',
    url,
    headers: {
      'Authorization': `Bearer ${ dpiaToken }`
    },
    data
  })
}

export const apiDpiaAssessmentSave = (data, id) => {
  const dpiaToken = localStorage.getItem('dpiaToken')
  // const accessToken = localStorage.getItem('accessToken')
  const accessToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId

  Object.assign(data, { osprey_access_token: accessToken })

  return axios({
    method: 'post',
    url: `${ dpiaHost }/dpia-0.1.0/2.0/assessments/${ id }/questions?action=SUBMIT`,
    headers: {
      'Authorization': `Bearer ${ dpiaToken }`
    },
    data
  })
}

export const apiDpiaReportDetail = (data, assessmentId) => {
  const dpiaToken = localStorage.getItem('dpiaToken')
  // const accessToken = localStorage.getItem('accessToken')
  const accessToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId

  data = {
    id_token: accessToken
  }

  Object.assign(data, { osprey_access_token: accessToken })
  return axios({
    method: 'post',
    url: `${ dpiaHost }/dpia-0.1.0/2.0/assessments/${ assessmentId }/risk-indicators-report?action=GET`,
    headers: {
      'Authorization': `Bearer ${ dpiaToken }`
    },
    data
  })
}

export const getBpList = (data) => {
  // const accessToken = localStorage.getItem('accessToken')
  const accessToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId

  const dpiaToken = localStorage.getItem('dpiaToken')
  Object.assign(data, { ospreyToken: accessToken })
  Object.assign(data, { dpiaToken: dpiaToken })
  Object.assign(data, { clientId: data['subscription']['subscription']['client']['id']})
  let plan = {
      name: data['subscription']['plan']['name'],
      id: data['subscription']['plan']['planId']
  };
    Object.assign(data, { plan: plan})
  // const data = objectToFormData(localData);
  return axios({
    method: 'post',
    url: `${ dpiaHost }/dpia-0.1.0/2.0/filter`,
    data
  })
}

export const apiDpiaFilterList = (data) => {
  return axios({
    method: 'post',
    url: `${ dpiaHost }/dpia-0.1.0/2.0/filter`,
    data
  })
}

export const apiNonSensitiveFilterList = (data) => {
  return axios({
    method: 'post',
    url: `${ dpiaHost }/dpia-0.1.0/2.0/filter`,
    data
  })
}

export const apiRiskIndicatorReport = (assessmentId) => {
  const dpiaToken = localStorage.getItem('dpiaToken')
  // const accessToken = localStorage.getItem('accessToken')
  const accessToken = JSON.parse(localStorage.getItem('tokenData'))?.tokenId

  const data = { id_token: accessToken }
  Object.assign(data, { osprey_access_token: accessToken })
  return axios({
    method: 'post',
    url: `${ dpiaHost }/dpia-0.1.0/2.0/assessments/${ assessmentId }/risk-indicators-report/pia?action=GET`,
    headers: {
      'Authorization': `Bearer ${ dpiaToken }`
    },
    data
  })
}

export const apiDoewnloadCSVPia = (clientId,clientName) => {
  return axios({
    method: 'get',
    url: `${ dpiaHost }/dpia-0.1.0/2.0/businessProcesses/${ clientId }/${clientName}/complete/bp`
  })
}
